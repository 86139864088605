import React, { useState, useEffect } from "react";
import {  Nav, Tab } from "react-bootstrap";

import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import { apiCall } from "../../rest/restApi";

import DownloadAllocationsList from "./DownloadAllocationsList";
import ViewAllocationsList from "./ViewAllocationsList";
import AgencySummary from "./AgencySummary";
const fieldInvestigationAgency = JSON.parse(localStorage.getItem("fieldInvestigationAgency"));

function AgencyFiFacilities(props) {
  const [agencyAllocations, setAgencyAllocations] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [noDataFound, setNoDataFound] = useState(null)
  useEffect(() => {
    setShowLoader(true);
    setAgencyAllocations([]);
    let requestPayload = null;
    if (props.location.state) {
      requestPayload = {
        agencyId: props.location.state && props.location.state.fieldInvestigationAgency.agencyId,
        organizationId: props.location.state && props.location.state.fieldInvestigationAgency.organizationId
      }

    } else if (fieldInvestigationAgency) {
      requestPayload = {
        agencyId: fieldInvestigationAgency.agencyId,
        organizationId: fieldInvestigationAgency.organizationId
      }
    } else {
      requestPayload = {}
    }

    nodeApi
      .postData(apiCall.getAgencyUploadData, requestPayload)
      .then((response) => {
        setShowLoader(false)
        if (response.success === true) {
          if (response.agencyAllocations && response.agencyAllocations.length > 0) {
            setNoDataFound(false)
            setAgencyAllocations(response.agencyAllocations)
          } else {
            setNoDataFound(true)
            setAgencyAllocations(response.agencyAllocations)
          }
        }
      })
      .catch((error) => {
        toaster("error", "Something Went Wrong, Please try again later");
      });

    //eslint-disable-next-line
  }, [])



  return (
    <div className="card">
      <div style={{ margin: "20px" }}>
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey="allocated">
            <Nav as="ul" className="nav-tabs-custom nav-justified">
              <Nav.Item as="li" key="0">
                <Nav.Link eventKey="allocated">
                  <b> Download Allocations </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="1">
                <Nav.Link eventKey="notAllocated">
                  <b> Agency Allocations </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="2">
                <Nav.Link eventKey="summary">
                  <b> Summary </b>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey="allocated" key="0">
                {/* <div style={{ display: "flex", justifyContent: "right" }}>
                  <div className="fi-al-body">
                    <Button className="upload-btn" variant="success btn-rounded" onClick={handleUploadFiReport}>
                      Upload FI Report
                    </Button>
                  </div>
                </div> */}
                <br/>
                <DownloadAllocationsList agencyAllocations={agencyAllocations} showLoader={showLoader} noDataFound={noDataFound} />
              </Tab.Pane>
              <Tab.Pane eventKey="notAllocated" key="1">
                <ViewAllocationsList />
              </Tab.Pane>
              <Tab.Pane eventKey="summary" key="2" mountOnEnter unmountOnExit>
                <AgencySummary />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      
    </div>
  );
}

export default AgencyFiFacilities;
