import React, { useState, useEffect } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button,Modal } from "react-bootstrap";
// eslint-disable-next-line
import ReactExport from "react-data-export";
import { LoadingOverlayComponent } from "../../CommonComponents/LoadingOverlay";
import { downloadAllocationsForAgencies, getStartAndEndDate } from "../utils";

import { agencyColumns } from "../TableColumns";
import MonthPicker from "../../CommonComponents/MonthPicker";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import DetailedAllocations from './DetailedAllocations';
import UploadFiReport from './UploadFiReport';
import ReactGA from "react-ga";

export default function ViewAllocationsList() {
    const [showLoader, setShowLoader] = useState(false);
    const [fiAllocations, setFiAllocations] = useState([]);
    const [detailedViewModal, setDetailedViewModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({})
    const [showUploadReportModal, setShowUploadReportModal] = useState(false)
    const [selectedAgency,setSelectedAgency]= useState({});
    // eslint-disable-next-line
    const ExcelFile = ReactExport.ExcelFile;
    // eslint-disable-next-line
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const { SearchBar } = Search;
    const userData = JSON.parse(localStorage.getItem("userDetails"));

    useEffect(() => {
        let requestPayload = {
            agencyId: userData.fieldInvestigationAgency.agencyId,
            organizationId: userData.fieldInvestigationAgency.organizationId,
            startDate: null,
            endDate: null
        }
        getAgencyAllocations(requestPayload)
        // eslint-disable-next-line  
    }, [])

    useEffect(() => {
        ReactGA.pageview('Agency Allocation List');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const handleUploadFiReport = (row) => {
        console.log(row)
        setSelectedAgency(row)
        setShowUploadReportModal(true);
    }

    const getAgencyAllocations = (requestPayload) => {
        setShowLoader(true);
        nodeApi
            .postData(apiCall.getFiAllocationsListForAgency, requestPayload)
            .then((response) => {
                setShowLoader(false);
                setFiAllocations(response.agencyAllocationsList);
            })
            .catch((error) => {
                setShowLoader(false);
                toaster("error", "Something went wrong. Please try again later.");
            });
    }

    const handleDetailedAllocation = (row, type, e) => {
        e.stopPropagation();
        setSelectedRow(row);
        setDetailedViewModal(true);
    };

    const options = {
        hideSizePerPage: true,
        paginationSize: 4,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        firstPageText: "First",
        prePageText: "Previous",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        disablePageTitle: true,
    };

    const setCustomDatesForGraph = (value) => {
        let formatDates = getStartAndEndDate(value)

        let requestPayload = {
            agencyId: userData.fieldInvestigationAgency.agencyId,
            organizationId: userData.fieldInvestigationAgency.organizationId,
            startDate: formatDates.startDate,
            endDate: formatDates.endDate
        };
        getAgencyAllocations(requestPayload)
    };
    return (
        <div>
            <div className="allocation-details-block">
                <ExcelFile
                    element={
                        <Button
                            className="me-2 agency-button"
                            variant="primary btn-rounded"
                        >
                            <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                        </Button>
                    }
                >
                    <ExcelSheet
                        dataSet={downloadAllocationsForAgencies(fiAllocations)}
                        name="Agencies"
                    />
                </ExcelFile>
            </div>
            <LoadingOverlayComponent showLoader={showLoader}>
                <div className="table-content">
                    <ToolkitProvider
                        keyField="id"
                        data={
                            fiAllocations
                        }
                        columns={
                            agencyColumns({ handleDetailedAllocation,handleUploadFiReport }, 'AGENCY_LOGIN',)
                        }
                        search
                    >
                        {(props) => (
                            <>
                                <div className="search-block">
                                    <SearchBar
                                        {...props.searchProps}
                                        placeholder={"Search By Name/Application No/Mobile"}
                                        className="search-bar"
                                    />
                                    <MonthPicker setCustomDatesForGraph={setCustomDatesForGraph} />
                                </div>
                                <div className="table-container">
                                    <BootstrapTable
                                        {...props.baseProps}
                                        pagination={paginationFactory(options)}
                                    />
                                </div>
                            </>
                        )}
                    </ToolkitProvider>
                    {/* {(noDataFoundForAgency || noDataFoundByMonth) && (
                        <div className="d-flex justify-content-center">
                            <img src={NO_DATA_FOUND} alt="" />
                        </div>
                    )} */}
                </div>
            </LoadingOverlayComponent>
            {detailedViewModal && <DetailedAllocations selectedRow={selectedRow}
                setDetailedViewModal={setDetailedViewModal} detailedViewModal={detailedViewModal} />}

            <Modal className="fade" size="md" show={showUploadReportModal}>
                <Modal.Header>
                    <Modal.Title>Upload FI Report</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setShowUploadReportModal(false)}></Button>
                </Modal.Header>
                <Modal.Body
                    style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
                >
                    <UploadFiReport setShowUploadReportModal={setShowUploadReportModal} selectedAgency={selectedAgency} getAgencyAllocations={getAgencyAllocations} />
                </Modal.Body>
            </Modal>
        </div>
    )
}
