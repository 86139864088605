import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FiAllocationColumns } from "../TableColumns";

export default function PreviewAgencyTable({ excelData,agency }) {
  return (
    <>
      <h5>Preview Allocations</h5>
      <div className="viewer">
        {excelData === null && <>No file selected</>}
        {excelData !== null && (
          <div className="table-responsive">
            {agency === 'Employees' ? <BootstrapTable
              data={excelData}
              keyField="id"
              columns={FiAllocationColumns({agency})}
            /> :<BootstrapTable
            data={excelData}
            keyField="id"
            columns={FiAllocationColumns({agency})}
          />}
          </div>
        )}
      </div>
    </>
  );
}
