import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import Select from "react-select";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { EpochToDate, secondsToHms } from "../CommonComponents/Helper";
// import SearchBar from "../CommonComponents/SearchBar";

function VehicleServiceCallSummary({ mobileNo }) {
  // var history = useHistory();
  const [callList, setCallList] = useState([]);
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [callAudioModal, setCallAudioModal] = useState(false);
  const [callDetails, setCallDetails] = useState(null);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  //   const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let listData = [];
//   var audioBasePath =
//     "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=";
  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData(start, end, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (start, end, pageNo) => {
    setIsLoading(true);
    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      //   searchKey: key,
      phoneNumber: "91" + mobileNo,
      startTime: start,
      endTime: end,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getVehicleServiceCallReport, pageNo, 10), body)
      .then((response) => {
        console.log(response);
        response?.content?.forEach((element) => {
          let data = {
            agent: element.agent,
            billedsec: element.billedsec,
            cmiuid: element.cmiuid,
            duration: element.duration ? secondsToHms(element.duration) : "-",
            filename: element.filename ? element.filename : "-",
            from: element.from ? element.from : "-",
            fromNumber: element.fromNumber ? element.fromNumber : "-",
            id: element.id,
            name: element.name ? element.name : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            rate: element.rate,
            record: element.record,
            time: element.time ? EpochToDate(element.time) : "-",
            to: element.to,
            toNumber: element.toNumber ? element.toNumber : "-",
            userId: element.userId,
          };
          listData.push(data);
        });
        setCallList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

  //   const handleClose = () => {
  //     setAddCpUserModal(false);
  //     loadData(0, searchKey);
  //   };

  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0].getFullYear(),
            value[0].getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86400);
    //   let start = ;
    // let end = Math.floor(new Date(dateStrings[1]).getTime() / 1000 + 86400);

    setStart(start);
    setEnd(end);
    loadData(start, end, 0);
  };

  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    // {
    //   text: "Agent Name",
    //   dataField: "agent",
    //   width: 130,
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     fontWeight: "bold",
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    // {
    //   text: "Customer Name",
    //   dataField: "name",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "From Number",
      dataField: "fromNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "To Number",
      dataField: "toNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Call Duration",
      dataField: "duration",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Call Initiated Time",
      dataField: "time",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Billed Sec",
      dataField: "billedsec",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex" >
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-play" style={{marginTop:'3px', marginLeft:'2px'}} />
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("row", row);
          setCallDetails(row);
          setCallAudioModal(true);
        },
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(start, end, selectedPage - 1);
  };

  //   const searchKeyword = (key) => {
  //     setSearchKey(key);
  //     loadData(0, key);
  //   };

  //   const transDownloadData = (key) => {
  //     setIsLoading(true);
  //     var payload = {
  //       endTime: endDate,
  //       startTime: startDate,
  //       organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //       searchKey: searchKey,
  //       status: selectedStatus,

  //       //   userId: "string",
  //     };

  //     moduleApi
  //       .postData(
  //         moduleApi.withPage(apiCall.getTransactionDetails, 0, 10),
  //         payload
  //       )
  //       .then((response) => {
  //         if (response) {
  //           if (response.totalElements > 5000) {
  //             setIsLoading(false);
  //             return swal({
  //               title: "Download Report",
  //               text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //               icon: "warning",
  //               buttons: true,
  //               dangerMode: true,
  //             }).then((willDelete) => {
  //               if (willDelete) {
  //                 setIsLoading(true);
  //                 getTransFullResponseData(
  //                   Math.floor(response.totalElements / 100)
  //                 );
  //               }
  //             });
  //           }

  //           getTransFullResponseData(Math.floor(response.totalElements / 100));
  //         } else {
  //           swal("Something Went Wrong, Please Try Later", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //   };

  //   async function getTransFullResponseData(size) {
  //     var tempList = [];
  //     var mgrListData = [];
  //     for (let i = 0; i <= size; i++) {
  //       var payload = {
  //         endTime: endDate,
  //         startTime: startDate,
  //         organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //         searchKey: searchKey,
  //         status: selectedStatus,
  //         //   userId: "string",
  //       };
  //       await moduleApi
  //         .postData(
  //           moduleApi.withPage(apiCall.getTransactionDetails, i, 100),
  //           payload
  //         )
  //         .then((response) => {
  //           tempList.push(...response.content);
  //         });
  //     }

  //     console.log("templist", tempList);

  //     tempList?.forEach((element) => {
  //       let data = {
  //         name: element.name ? element.name : "-",
  //         customerName: element.customerName ? element.customerName : "-",
  //         empId: element.empId ? element.empId : "-",
  //         loanNumber: element.loanNumber ? element.loanNumber : "-",
  //         subscriptionid: element.subscriptionid ? element.subscriptionid : "-",
  //         amountToBeDucted: element.amountToBeDucted
  //           ? getRupees(element.amountToBeDucted)
  //           : "-",
  //           amountDebitedTime: element.amountDebitedTime
  //               ? helper.EpochToDate(element.amountDebitedTime)
  //               : "-",
  //         mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
  //         frequency: element.frequency ? element.frequency : "-",
  //         recurringCount: element.recurringCount ? element.recurringCount : "-",
  //         state: element.state,
  //         dateOfDeduction: element.dateOfDeduction
  //           ? element.dateOfDeduction
  //           : "-",
  //         transactionAmount: element.transactionAmount
  //           ? getRupees(element.transactionAmount)
  //           : "-",
  //         trasactionid: element.trasactionid ? element.trasactionid : "-",
  //         notificationState: element.notificationState
  //           ? element.notificationState
  //           : "-",
  //         paymentState: element.paymentState ? element.paymentState : "-",
  //         providerReferenceId: element.providerReferenceId,
  //         payResponseCode: element.payResponseCode
  //           ? element.payResponseCode
  //           : "-",
  //       };
  //       mgrListData.push(data);
  //     });
  //     setTransList(mgrListData);

  //     // console.log("mgrList1", mgrListData);
  //   }

  //   const handleExport = () => {
  //     let mgrTable = [
  //       {
  //         // A: "Employee Id",
  //         // B: "Employee Name",
  //         A: "Customer Name",
  //         B: "Mobile No",
  //         C: "Loan No",
  //         D: "Amount",
  //         E: "Frequency",
  //         F: "Date Of Deduction",
  //         G: "Amount To Be Deducted",
  //         H: "Initiated Date & Time",
  //         I: "Payment Date & Time",
  //         J: "Payment Status",
  //         K: "Notification Status",
  //         L: "Pay Response Code",
  //         M: "Transaction Id",
  //       },
  //     ];

  //     transList?.forEach((row) => {
  //       mgrTable.push({
  //         // A: row.empId,
  //         // B: row.name,
  //         A: row.customerName,
  //         B: row.mobileNumber,
  //         C: row.loanNumber,
  //         D: row.transactionAmount,
  //         E: row.frequency,
  //         F: row.dateOfDeduction,
  //         G: row.amountToBeDucted,
  //         H: row.transactionIntiatedTime,
  //         I: row.amountDebitedTime,
  //         J: row.paymentState,
  //         K: row.notificationState,
  //         L: row.payResponseCode,
  //         M: row.trasactionid,
  //       });
  //     });

  //     const finalData = [...mgrTable];

  //     console.log("finaldata", finalData);

  //     //create a new workbook
  //     const wb = XLSX.utils.book_new();

  //     const sheet = XLSX.utils.json_to_sheet(finalData, {
  //       skipHeader: true,
  //     });

  //     console.log("sheet", sheet);

  //     XLSX.utils.book_append_sheet(wb, sheet, "Transactions");

  //     XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

  //     // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
  //     // worksheet["!cols"] = [ { wch: max_width } ];

  //     XLSX.writeFile(
  //       wb,
  //       "UPI_Transactions_" +
  //         helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //         ".xlsx"
  //     );
  //     ReactGA.event({
  //       category: "Transactions List Downlaod",
  //       action:
  //         userLoginDetails.autoFinanceOrganization.orgshortcode +
  //         "_UPI_Transactions_" +
  //         helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //         ".xlsx",
  //     });
  //   };

  return (
    <div >
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "20px",
          //   marginTop: "10px",
        }}
      >
        <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "10px",
            height: "38px",
            marginLeft: "10px",
            borderRadius: "3px",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #797979",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) => {
              value.value === "cm"
                ? setIsDateFilter(false)
                : setIsDateFilter(true);
            }}
            defaultValue={dateOptions[0]}
            options={dateOptions}
          />
        </div>
        {!isDateFilter && (
          <div
            style={{
              float: "right",
              // marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <Flatpickr
              id="customonthPicker"
              className="form-control d-block"
              style={{
                width: "150px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value, true);
              }}
              options={{
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          </div>
        )}
        {isDateFilter && (
          <div>
            <Flatpickr
              style={{
                width: "150px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              id="customDatePicker"
              className="form-control d-block"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDatesForGraph(value, false);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}

        {/* <Button
          style={{
            // margin: "5px 10px 10px  ",
            marginRight: "15px",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() => transDownloadData()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button> */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={callList}
              columns={columns}
            />
          </div>
          {callList?.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" show={callAudioModal}>
        <Modal.Header>
          <Modal.Title>Call Recording</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setCallAudioModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AudioPlayer
            autoPlay
            src={
              "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
              callDetails?.filename
            }
            onPlay={(e) => console.log("onPlay")}
            // other props here
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VehicleServiceCallSummary;
