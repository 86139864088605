import React, { useEffect, useState } from "react";
import { Button, Row, Col, Nav, Tab, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { Link } from "react-router-dom";
import Select from "react-select";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { getTokenInLocalStorage } from "../services/AuthService";
import SearchBar from "../CommonComponents/SearchBar";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import TextField from "../CommonComponents/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import SimpleCountdownTimer from "./SimpleCountdownTimer";
import { hasAccess } from "../services/AuthService";
import UploadPayments from "./UploadPayments";
import UploadedFilesList from "../UploadSchedule/UploadedFilesList";

const Subscriptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [skipModal, setSkipModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [deductionsDates, setDeductionsDates] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [transactionList, setTransactionList] = useState([]);
  const [skippedSubscriptionsList, setSkippedSubscriptionsList] = useState([]);
  const [skippedSubscriptionsHistoryList, setSkippedSubscriptionsHistoryList] =
    useState([]);
  const [subsList, setSubsList] = useState(null);
  const [transList, setTransList] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [addUpiMandateModal, setAddUpiMandateModal] = useState(false);
  const [loanModal, setLoanModal] = useState(false);
  const [timerModal, setTimerModal] = useState(false);
  const [selectedFrequencyType, setSelectedFrequencyType] = useState(null);
  const [selectedDateToDeductAmount, setSelectedDateToDeductAmount] =
    useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [upiAddress, setUpiAddress] = useState(null);
  const [upiAddressVerified, setUpiAddressVerified] = useState(false);
  // const [isTransSkipped, setIsTransSkipped] = useState(false);
  const [amountToBePaidModal, setAmountToBePaidModal] = useState(false);
  const [uploadMandateModal, setUploadMandateModal] = useState(false);
  const [selectedSubsStartDate, setSelectedSubsStartDate] = useState(0);

  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSubscriptionList(0, searchKey, selectedStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    subsList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsList]);

  useEffect(() => {
    transList !== null && transHandleExport();
    // setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transList]);

  const getSubscriptionList = (pageNo, key, status) => {
    let listData = [];
    setIsLoading(true);
    var payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchKey: key,
      //   userId: "string",
      status: status,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getSubscriptions, pageNo, 10),
        payload
      )
      .then((response) => {
        setCurrPage(pageNo);
        console.log("subscriptionResponse", response);
        response.content.forEach((element) => {
          let data = {
            id: element.id,
            name: element.name ? element.name : "-",
            userId: element.userId,
            createdTime: element.createdTime
              ? helper.EpochToDate(element.createdTime)
              : "-",
            updateTime: element.updateTime
              ? helper.EpochToDate(element.updateTime)
              : "-",
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            organizationId: element.organizationId,
            merchantSubscriptionId: element.merchantSubscriptionId,
            merchantUserId: element.merchantUserId,
            subscriptionName: element.subscriptionName,
            authWorkflowType: element.authWorkflowType,
            amountType: element.amountType,
            amount: element.amount ? getRupees(element.amount) : "-",
            amountToBeDeducted:
              element.latestUpdatedAmount === 0
                ? element.amountToBeDeducted
                  ? getRupees(element.amountToBeDeducted)
                  : "-"
                : getRupees(element.latestUpdatedAmount),
            dateToDeductAmount: element.dateToDeductAmount,
            frequency: element.frequency,
            ifsc: element.ifsc,
            utr: element.utr,
            maskedAccountNumber: element.maskedAccountNumber,
            recurringCount: element.recurringCount,
            description: element.description,
            mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
            subMerchantId: element.subMerchantId,
            phoneSubcriptionId: element.phoneSubcriptionId,
            subscriptionStartDate: element.subscriptionStartDate
              ? helper.EpochToOnlyDate(element.subscriptionStartDate)
              : "-",
            stateStartDate: element.stateStartDate,
            stateEndDate: element.stateEndDate,
            state: element.state,
          };
          listData.push(data);
        });
        setSubscriptionList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

  const amountToBePaidRegex = /^\d+(\.\d{1,2})?$/;
  const amountToBePaidValidate = Yup.object({
    amountToBeDeducted: Yup.string()
      .matches(amountToBePaidRegex, "Please Enter Valid Amount")
      .required("Amount To Be Deducted is required"),
  });

  const updateAmountToBePaid = (values) => {
    var payLoad = {
      amount: values.amountToBeDeducted * 100,
      // createdTime: 0,
      // empId: subscriptionDetails?.employeeId,
      // id: subscriptionDetails?.id,
      // name: subscriptionDetails?.subscriptionName,
      subscriptionId: subscriptionDetails?.phoneSubcriptionId,
      userId: subscriptionDetails?.userId,
    };

    moduleApi
      .postData(apiCall.updateAmountToBeDeducted, payLoad)
      .then((response) => {
        if (response) {
          if (response?.Message === "Amount Updated Successfully") {
            swal("Amount To Be Deducted Updated Successfully", {
              icon: "success",
            });
            setAmountToBePaidModal(false);
          } else if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getRupees = (value) => {
    return value / 100;
  };

  const getPaisa = (value) => {
    return value * 100;
  };

  const tenurePattern = /^(?:[1-9]|[1-3][0-9]|4[0-8])$/;
  const amountRegPattern = /^(?=.*[1-9])\d{1,}(\.\d{1,2})?$/;
  const phoneRegExp = "[1-9]{1}[0-9]{9}";

  const validate = Yup.object({
    amount: Yup.string()
      .required("Maximum Amount is required")
      .matches(amountRegPattern, "Please Enter a Valid Amount"),
    amountToBeDeducted: Yup.string()
      .required("Amount To Be Deducted is required")
      .matches(amountRegPattern, "Please Enter a Valid Amount"),
    merchantUserId: Yup.string().required("Loan Number is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile Number is not valid")
      .min(10, "Mobile Number should be 10 digits")
      .max(10, "Mobile Number should be 10 digits")
      .required("Mobile Number is required"),
    name: Yup.string().required("Name is required"),
    recurringCount: Yup.string()
      .required("Tenure(In Months) is required")
      .matches(tenurePattern, "Tenure(In months) should be between 1 to 48"),
  });

  const addSubscription = (values) => {
    if (selectedFrequencyType === null) {
      return swal("Please Select Frequency", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (selectedDateToDeductAmount === null) {
      return swal("Please select date to deduct amount", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (getPaisa(values.amountToBeDeducted) > getPaisa(values.amount)) {
      return swal(
        "Amount To Be Deducted Should Be Less Than Or Equal To Max Amount",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }

    if (getPaisa(values.amount) > 1500000) {
      return swal("Max Amount Should Be Less Than Or Equal To 15000", {
        icon: "error",
        dangerMode: true,
      });
    }
    const payLoad = {
      amount: getPaisa(values.amount),
      amountToBeDeducted: getPaisa(values.amountToBeDeducted),
      dateToDeductAmount: selectedDateToDeductAmount,
      frequency: selectedFrequencyType,
      merchantUserId: values.merchantUserId,
      mobileNumber: values.mobileNumber,
      name: values.name,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      recurringCount: values.recurringCount,
      userId: userLoginDetails.autoFinanceUser.userId,
      skipIntialTransaction: false,
      subscriptionStartDate: selectedSubsStartDate,
      vpaAddress: upiAddress,
    };

    moduleApi.postData(apiCall.createSubscription, payLoad).then((response) => {
      setAddUpiMandateModal(false);
      setUpiAddressVerified(false);
      setUpiAddress(null);
      setSelectedDateToDeductAmount(null);
      setSelectedFrequencyType(null);
      if (response) {
        if (response.message) {
          swal(response.message, {
            icon: "error",
          });
        } else if (response) {
          const body = {
            merchantUserId: response.merchantUserId,
            organizatiionId: response.organizationId,
            subscriptionId: response.phoneSubcriptionId,
            userId: response.userId,
            vpa: response.vpaAddress,
          };

          moduleApi
            .postData(apiCall.submitAuthRequestForSubcription, body)
            .then((response2) => {
              if (response2) {
                if (response2.message) {
                  swal(response2.message, {
                    icon: "error",
                  });
                } else if (response2) {
                  console.log();
                  setSubscriptionId(response?.phoneSubcriptionId);
                  getSubscriptionList(0, searchKey, selectedStatus);
                  setTimerModal(true);
                }
              } else {
                return swal("Something Went Wrong, Please Try Again Later", {
                  icon: "error",
                  dangerMode: true,
                });
              }
            });
        }
      } else {
        return swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const verifyUpiAddress = () => {
    const body = {
      vpaAddress: upiAddress,
    };

    moduleApi.postData(apiCall.checkVpaAddress, body).then((response) => {
      if (response) {
        if (response.status === 400) {
          swal("UPI Address Does Not Exist", {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          if (
            response?.message ===
            "Your request has been successfully completed."
          ) {
            setUpiAddressVerified(true);
          }
        }
      }
    });
  };

  const frequencyOptions = [{ label: "Monthly", value: "MONTHLY" }];

  // const dateToDeductAmountOptions = [
  //   { label: "5", value: "5" },
  //   { label: "10", value: "10" },
  //   { label: "20", value: "20" },
  // ];

  const subscriptionColumns = [
    {
      text: "Emp Name",
      dataField: "employeeName",
      width: 130,
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Id",
      dataField: "employeeId",
      width: 130,
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Loan No",
      dataField: "merchantUserId",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Loan No</span>
          <br></br>
          <span>Reference</span>
        </div>
      ),
      dataField: "loanNumber",
      align: "center",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "name",
      width: 130,
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",
      dataField: "mobileNumber",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Amount",
    //   dataField: "amount",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "EMI",
      dataField: "amountToBeDeducted",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Amount Type",
    //   dataField: "amountType",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },

    // {
    //   text: "Frequency",

    //   dataField: "frequency",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: (
        <div>
          <span>Tenure</span>
          <br></br>
          <span>(in Months)</span>
        </div>
      ),
      dataField: "recurringCount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Date Of</span>
          <br></br>
          <span>Deduction</span>
        </div>
      ),
      dataField: "dateToDeductAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "state",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.state === "CREATED") {
          return (
            <span className="badge badge-rounded badge-primary">Created</span>
          );
        } else if (row.state === "ACTIVE") {
          return (
            <span className="badge badge-rounded badge-success">Active</span>
          );
        } else if (row.state === "SUSPENDED") {
          return (
            <span className="badge badge-rounded badge-warning">Suspended</span>
          );
        } else if (row.state === "REVOKED") {
          return (
            <span className="badge badge-rounded badge-primary">Revoked</span>
          );
        } else if (row.state === "CANCELLED") {
          return (
            <span className="badge badge-rounded badge-danger">Cancelled</span>
          );
        } else if (row.state === "PAUSED") {
          return (
            <span className="badge badge-rounded badge-warning">Paused</span>
          );
        } else if (row.state === "EXPIRED") {
          return (
            <span className="badge badge-rounded badge-primary">Expired</span>
          );
        } else if (row.state === "FAILED") {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else if (row.state === "CANCEL_IN_PROGRESS") {
          return (
            <span className="badge badge-rounded badge-warning">
              Cancel in Progress
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "",
      dataField: "status",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSubscriptionDetails(row);
          getTransactionDetails(row.phoneSubcriptionId);
          getSkippedSubscriptions(row.phoneSubcriptionId);
          getSkippedSubscriptionsHistory(row.phoneSubcriptionId);
          setBasicModal(true);
        },
      },
    },

    {
      text: "",
      dataField: "status",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (_cell, row) => {
        if (row.state === "ACTIVE") {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fa fa-times-circle"></i>
              </Link>
            </div>
          );
        } else if (row.state === "FAILED") {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fas fa-trash" />
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.state === "ACTIVE") {
            swal({
              title: "Cancel",
              text: "Do you really want to cancel the subscription?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                cancelSubscription(row);
              }
            });
          } else if (row.state === "FAILED") {
            swal({
              title: "Delete",
              text: "Do you really want to Delete the subscription?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                deleteSubscription(row);
              }
            });
          }
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (_cell, row) => {
        if (row.state === "ACTIVE") {
          return (
            <div
              className="d-flex"
              onClick={() => {
                setSubscriptionDetails(row);
                setSkipModal(true);
              }}
            >
              <Link to="#" className="btn btn-primary shadow btn-xs sharp">
                <i className="fa fa-fast-forward"></i>
              </Link>
            </div>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const handleClose = () => {
    setTimerModal(false);
  };

  const handleCloseForUpload = () => {
    setUploadMandateModal(false);
  };

  const statusOptions = [
    { label: "All", value: "" },
    { label: "Created", value: "CREATED" },
    { label: "Active", value: "ACTIVE" },
    { label: "Suspended", value: "SUSPENDED" },
    { label: "Revoked", value: "REVOKED" },
    { label: "Cancelled", value: "CANCELLED" },
    { label: "Paused", value: "PAUSED" },
    { label: "Expired", value: "EXPIRED" },
    { label: "Failed", value: "FAILED" },
    { label: "Cancel in Progress", value: "CANCEL_IN_PROGRESS" },
  ];

  const searchKeyword = (key) => {
    setSearchKey(key);
    getSubscriptionList(0, key, selectedStatus);
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    getSubscriptionList(selectedPage - 1, searchKey, selectedStatus);
  };

  const getTransactionDetails = (subscriptionId) => {
    let listData = [];
    moduleApi
      .getData(apiCall.getTransactions + subscriptionId)
      .then((response) => {
        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response) {
            response?.forEach((element) => {
              let data = {
                id: element.id,
                merchantId: element.merchantId,
                merchantUserId: element.merchantUserId,
                subscriptionId: element.subscriptionId,
                transactionId: element.transactionId,
                amount: element.amount ? getRupees(element.amount) : "-",
                autoDebit: element.autoDebit,
                state: element.state,
                notificationState: element.notificationState
                  ? element.notificationState
                  : "-",
                utr: element.utr ? element.utr : "-",
                dateTime: element.dateTime
                  ? helper.EpochToDate(element.dateTime)
                  : "-",
                amountDebitedTime: element.amountDebitedTime
                  ? helper.EpochToDate(element.amountDebitedTime)
                  : "-",
                notificationId: element.notificationId,
                notifiedAt: element.notifiedAt,
                validAfter: element.validAfter,
                validUpto: element.validUpto,
                providerReferenceId: element.providerReferenceId,
                paymentState: element.paymentState ? element.paymentState : "-",
                payResponseCode: element.payResponseCode
                  ? element.payResponseCode
                  : "-",
              };
              listData.push(data);
            });
            setTransactionList(listData);
            // setTotalElements2(response.totalElements);
            // setIsLoading(false);
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  // const transDownloadData = () => {
  //   // setIsLoading(true);

  //   moduleApi
  //   .getData(apiCall.getTransactions + subscriptionDetails?.phoneSubcriptionId)
  //   .then((response) => {
  //       if (response) {
  //         if (response?.totalElements > 5000) {
  //           // setIsLoading(false);
  //           return swal({
  //             title: "Download Report",
  //             text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //             icon: "warning",
  //             buttons: true,
  //             dangerMode: true,
  //           }).then((willDelete) => {
  //             if (willDelete) {
  //               // setIsLoading(true);
  //               getTransFullResponseData(
  //                 Math.floor(response.totalElements / 100)
  //               );
  //             }
  //           });
  //         }

  //         getTransFullResponseData(Math.floor(response.totalElements / 100));
  //       } else {
  //         swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  // };

  async function getTransFullResponseData() {
    var mgrListData = [];
    // for (let i = 0; i <= size; i++) {

    await moduleApi
      .getData(
        apiCall.getTransactions + subscriptionDetails?.phoneSubcriptionId
      )
      .then((response) => {
        response.forEach((element) => {
          let data = {
            id: element.id,
            merchantId: element.merchantId,
            merchantUserId: element.merchantUserId,
            subscriptionId: element.subscriptionId,
            transactionId: element.transactionId,
            amount: element.amount ? getRupees(element.amount) : "-",
            autoDebit: element.autoDebit,
            state: element.state,
            notificationState: element.notificationState
              ? element.notificationState
              : "-",
            utr: element.utr ? element.utr : "-",
            dateTime: element.dateTime
              ? helper.EpochToDate(element.dateTime)
              : "-",
            amountDebitedTime: element.amountDebitedTime
              ? helper.EpochToDate(element.amountDebitedTime)
              : "-",
            notificationId: element.notificationId,
            notifiedAt: element.notifiedAt,
            validAfter: element.validAfter,
            validUpto: element.validUpto,
            providerReferenceId: element.providerReferenceId,
            paymentState: element.paymentState ? element.paymentState : "-",
            payResponseCode: element.payResponseCode
              ? element.payResponseCode
              : "-",
          };
          mgrListData.push(data);
        });
        setTransList(mgrListData);
      });

    // console.log("mgrList1", mgrListData);
  }

  const transHandleExport = () => {
    let mgrTable = [
      {
        A: "Subscription Id",
        B: "Loan No",
        C: "Amount",
        D: "UTR NO",
        E: "Initiated Date & Time",
        F: "Payment Date & Time",
        G: "Notification Status",
        H: "Pay Response Code",
        I: "Payment Status",
      },
    ];

    transList?.forEach((row) => {
      mgrTable.push({
        A: row.subscriptionId,
        B: row.merchantUserId,
        C: row.amount,
        D: row.utr,
        E: row.dateTime,
        F: row.amountDebitedTime,
        G: row.notificationState,
        H: row.payResponseCode,
        I: row.paymentState,
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Transactions");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      "Individual_Customer_Autopay_Transactions_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Individual_Customer_Autopay_Transactions_Download",
      action:
        userLoginDetails?.autoFinanceOrganization?.orgshortcode +
        "_Individual_Customer_Autopay_Transactions_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const subsDownloadData = () => {
    setIsLoading(true);
    var payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchKey: searchKey,
      //   userId: "string",
      status: selectedStatus,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getSubscriptions, 0, 10), payload)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getSubsFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getSubsFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getSubsFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        searchKey: searchKey,
        //   userId: "string",
        status: selectedStatus,
      };
      await moduleApi
        .postData(moduleApi.withPage(apiCall.getSubscriptions, i, 100), payload)
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        id: element.id,
        name: element.name ? element.name : "-",
        userId: element.userId,
        createdTime: element.createdTime
          ? helper.EpochToDate(element.createdTime)
          : "-",
        updateTime: element.updateTime
          ? helper.EpochToDate(element.updateTime)
          : "-",
        employeeId: element.employeeId ? element.employeeId : "-",
        loanNumber: element.loanNumber ? element.loanNumber : "-",
        employeeName: element.employeeName ? element.employeeName : "-",
        organizationId: element.organizationId,
        merchantSubscriptionId: element.merchantSubscriptionId,
        merchantUserId: element.merchantUserId,
        subscriptionName: element.subscriptionName,
        authWorkflowType: element.authWorkflowType,
        amountType: element.amountType,
        amount: element.amount ? getRupees(element.amount) : "-",
        amountToBeDeducted:
          element.latestUpdatedAmount === 0
            ? element.amountToBeDeducted
              ? getRupees(element.amountToBeDeducted)
              : "-"
            : getRupees(element.latestUpdatedAmount),
        dateToDeductAmount: element.dateToDeductAmount,
        frequency: element.frequency,
        recurringCount: element.recurringCount,
        description: element.description,
        mobileNumber: element.mobileNumber,
        subMerchantId: element.subMerchantId,
        phoneSubcriptionId: element.phoneSubcriptionId
          ? element.phoneSubcriptionId
          : "-",
        stateStartDate: element.stateStartDate,
        stateEndDate: element.stateEndDate,
        state: element.state,
        maskedAccountNumber: element.maskedAccountNumber
          ? element.maskedAccountNumber
          : "-",
        ifsc: element.ifsc ? element.ifsc : "-",
      };
      mgrListData.push(data);
    });
    setSubsList(mgrListData);

    // console.log("mgrList1", mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Subscription Id",
        B: "Employee Id",
        C: "Employee Name",
        D: "Customer Name",
        E: "Mobile No",
        F: "Loan No",
        G: "Loan No Reference",
        H: "Amount",
        I: "Frequency",
        J: "Date Of Deduction",
        K: "EMI",
        L: "Tenure(In Months)",
        M: "Status",
        N: "Created At",
        O: "Updated At",
        P: "Account No",
        Q: "IFSC Code",
      },
    ];

    subsList?.forEach((row) => {
      mgrTable.push({
        A: row.phoneSubcriptionId,
        B: row.employeeId,
        C: row.employeeName,
        D: row.name,
        E: row.mobileNumber,
        F: row.merchantUserId,
        G: row.loanNumber,
        H: row.amount,
        I: row.frequency,
        J: row.dateToDeductAmount,
        K: row.amountToBeDeducted,
        L: row.recurringCount,
        M: row.state,
        N: row.createdTime,
        O: row.updateTime,
        P: row.maskedAccountNumber,
        Q: row.ifsc,
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Subscriptions");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      "UPI_Subscriptions_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Subscriptions List Downloadd",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_UPI_Subscriptions_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const transactionColumns = [
    {
      text: "Subscription ID",
      dataField: "subscriptionId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan No",
      dataField: "merchantUserId",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Amount",
      dataField: "amount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "UTR No",
      dataField: "utr",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Initiated</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "amountDebitedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: (
    //     <div>
    //       <span>Payment</span>
    //       <br></br>
    //       <span>Status</span>
    //     </div>
    //   ),
    //   dataField: "paymentState",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },

    {
      text: (
        <div>
          <span>Notification</span>
          <br></br>
          <span>Status</span>
        </div>
      ),

      dataField: "notificationState",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.notificationState === "NOTIFIED") {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>Notified</span>
          );
        } else if (row.notificationState === "PENDING") {
          return (
            <span style={{ color: "orange", fontWeight: "bold" }}>Pending</span>
          );
        } else if (row.notificationState === "FAILED") {
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>Failed</span>
          );
        }
        if (row.notificationState) {
          return (
            <span style={{ color: "blue", fontWeight: "bold" }}>
              {row.notificationState}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Pay Response</span>
          <br></br>
          <span>Code</span>
        </div>
      ),
      dataField: "payResponseCode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.payResponseCode === "SUCCESS") {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>Success</span>
          );
        } else if (row.payResponseCode === "PENDING") {
          return (
            <span style={{ color: "orange", fontWeight: "bold" }}>Pending</span>
          );
        } else if (row.payResponseCode === "FAILED") {
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>Failed</span>
          );
        }
        if (row.payResponseCode) {
          return (
            <span style={{ color: "blue", fontWeight: "bold" }}>
              {row.payResponseCode}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    // {
    //   text: "Recurring Count",

    //   dataField: "recurringCount",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Payment Status",
      dataField: "state",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.paymentState === "COMPLETED") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else if (row.paymentState === "PENDING") {
          return (
            <span className="badge badge-rounded badge-warning">Pending</span>
          );
        } else if (row.paymentState === "FAILED") {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else {
          return "-";
        }
      },
    },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (_cell, row) => {
    //     if (row.state === "ACTIVE") {
    //       return (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-info shadow btn-xs sharp">
    //             <i className="fa fa-times-circle"></i>
    //           </Link>
    //         </div>
    //       );
    //     } else {
    //      return <></>
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       if (row.state === "ACTIVE") {
    //         cancelSubscription(row);
    //       }
    //       // setSubscriptionDetails(row)
    //       // getTransactionDetails(row.phoneSubcriptionId);
    //       // setBasicModal(true);
    //     },
    //   },
    // },
  ];

  const getAutopayDedutionDates = (organizationId) => {
    moduleApi
      .getData(apiCall.getAutopayDedutionDates + organizationId)
      .then((response) => {
        console.log("response", response);
        let dates = [];

        for (let i = 0; i < response.length; i++) {
          const result = {
            value: response[i],
            label: response[i],
          };
          dates.push(result);
        }
        setDeductionsDates(dates);
      });
  };

  const cancelSubscription = (row) => {
    console.log("row", row);
    let body = {
      id: row.id,
      merchantId: row.merchantId,
      merchantUserId: row.merchantUserId,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      subscriptionId: row.phoneSubcriptionId,
      userId: userLoginDetails.autoFinanceUser.userId,
    };

    moduleApi.postData(apiCall.cancelSubscription, body).then((response) => {
      console.log("response", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.Mesage === "Subscription Cancelled SuccessFully") {
        }
        swal("Subscription Cancelled Successfully!", {
          icon: "success",
        });
        getSubscriptionList(0, searchKey, selectedStatus);
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const deleteSubscription = (row) => {
    console.log("row", row);
    // let body = {
    //   id: row.id,
    //   merchantId: row.merchantId,
    //   merchantUserId: row.merchantUserId,
    //   organizationId: userLoginDetails.autoFinanceUser.organizationId,
    //   subscriptionId: row.phoneSubcriptionId,
    //   userId: userLoginDetails.autoFinanceUser.userId,
    // };

    moduleApi.getData(apiCall.deleteSubscription + row?.id).then((response) => {
      console.log("response", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.Mesage === "Subscription Deleted SuccessFully") {
        }
        swal("Subscription Deleted Successfully!", {
          icon: "success",
        });
        getSubscriptionList(0, searchKey, selectedStatus);
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const setStatusValue = (value) => {
    getSubscriptionList(0, searchKey, value);
    setSelectedStatus(value);
  };

  const getSubsList = () => {
    getSubscriptionList(0, searchKey, selectedStatus);
  };

  const skippedValuePattern = /^(?:[1-9]|[1-3][0-9]|4[0-7])$/;

  const skipValidate = Yup.object({
    skippedValue: Yup.string()
      .required("No.of Autopay Transactions to be Skipped is required")
      .matches(
        skippedValuePattern,
        "Please enter numeric value between 0 to 48"
      ),
  });

  const skipSubscriptions = (values) => {
    var payload = {
      skipAddedByUserId: userLoginDetails.autoFinanceUser.userId,
      subscriptionId: subscriptionDetails.phoneSubcriptionId,
      transactionToBeSkipped: values.skippedValue,
    };

    moduleApi.postData(apiCall.skipSubscription, payload).then((response) => {
      console.log("response", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.Message === "Skip Added SuccessFully") {
          setSkipModal(false);
          swal(
            "Autopay Transaction/s Skipped Successfully. Autopay for the Customer will resume after " +
              values.skippedValue +
              " Skipped Transaction/s.",
            {
              icon: "success",
            }
          );
          getSubscriptionList(0, searchKey, selectedStatus);
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const loanNoValidate = Yup.object({
    loanNo: Yup.string().required("Loan No Reference is required"),
  });

  const handleOk = (values) => {
    var payLoad = {
      id: subscriptionDetails?.id,
      loanNumber: values.loanNo,
    };
    moduleApi
      .postData(apiCall.editLoanNoinSubscriptions, payLoad)
      .then((response) => {
        if (response) {
          if (response?.Message === "Subscription Edited Successfully") {
            swal("Loan No Updated Successfully", {
              icon: "success",
            });
            getSubscriptionList(0, searchKey, selectedStatus);
            setLoanModal(false);
            setBasicModal(false);
          } else if (response?.message) {
            // toaster("error", response?.message);
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getSkippedSubscriptions = (subscriptionId) => {
    let listData = [];
    var payLoad = {
      subscriptionId: subscriptionId,
    };
    moduleApi
      .postData(apiCall.getSkippedSubscriptions, payLoad)
      .then((response) => {
        console.log("skippedResponse", response);
        response?.forEach((element) => {
          let data = {
            createdTime: element.createdTime
              ? helper.EpochToDate(element.createdTime)
              : "-",
            empId: element.empId ? element.empId : "-",
            name: element.name ? element.name : "-",
            skipAddedAtCreation:
              element.skipAddedAtCreation === true
                ? "Creation"
                : "Before Initiation",
            skipAddedByUserId: element.skipAddedByUserId
              ? element.skipAddedByUserId
              : "-",
            skipedCount: element.skipedCount ? element.skipedCount : 0,
            subscriptionId: element.subscriptionId
              ? element.subscriptionId
              : "-",
            transactionToBeSkipped: element.transactionToBeSkipped
              ? element.transactionToBeSkipped
              : 0,
          };
          listData.push(data);
        });
        setSkippedSubscriptionsList(listData);
      });
  };

  const skippedSubscriptionsColumns = [
    {
      text: "Emp ID",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "name",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Created</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Skipped</span>
          <br></br>
          <span>Count</span>
        </div>
      ),
      dataField: "skipedCount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Transactions to</span>
          <br></br>
          <span>be Skipped</span>
        </div>
      ),
      dataField: "transactionToBeSkipped",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Skipped During",
      dataField: "skipAddedAtCreation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  const getSkippedSubscriptionsHistory = (subscriptionId) => {
    let listData = [];
    var payLoad = {
      subscriptionId: subscriptionId,
    };
    moduleApi
      .postData(apiCall.getSkippedSubscriptionsHistory, payLoad)
      .then((response) => {
        console.log("skippedHistoryResponse", response);
        response?.forEach((element) => {
          let data = {
            empId: element.empId ? element.empId : "-",
            name: element.name ? element.name : "-",
            numberOfSkipsRemaining: element.numberOfSkipsRemaining
              ? element.numberOfSkipsRemaining
              : 0,
            skippedDate: element.skippedDate
              ? helper.EpochToDate(element.skippedDate)
              : "-",
            subscriptionId: element.subscriptionId
              ? element.subscriptionId
              : "-",
            skipAddedAtCreation:
              element.skipAddedAtCreation === true
                ? "Creation"
                : "Before Initiation",
            userId: element.userId ? element.userId : "-",
          };
          listData.push(data);
        });
        setSkippedSubscriptionsHistoryList(listData);
      });
  };

  const skippedSubscriptionsHistoryColumns = [
    {
      text: "Emp ID",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "name",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Transaction</span>
          <br></br>
          <span>Skipped Time</span>
        </div>
      ),
      dataField: "skippedDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>No of Skips</span>
          <br></br>
          <span>Remaining</span>
        </div>
      ),
      dataField: "numberOfSkipsRemaining",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Skipped During",
      dataField: "skipAddedAtCreation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: (
    //     <div>
    //       <span>Transactions to</span>
    //       <br></br>
    //       <span>be Skipped</span>
    //     </div>
    //   ),
    //   dataField: "transactionToBeSkipped",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
  ];

  return (
    <div className="card" style={{ padding: "10px" }}>
      <div className="custom-tab-1">
      <Tab.Container defaultActiveKey="subscriptions" key={'mainTabs'}>
        <Nav as="ul" className="nav-tabs-custom nav-justified">
          <Nav.Item as="li" key="0">
            <Nav.Link eventKey="subscriptions" onClick={()=>{
              getSubscriptionList(0, searchKey, '');
            }}>
              <b> Subscriptions</b>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="uploadedFiles">
              <b>Uploaded Files </b>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="pt-4" style={{ padding: "-10px" }}>
          <Tab.Pane eventKey="subscriptions" key="0" mountOnEnter unmountOnExit>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                marginTop: "10px",
                marginBottom: "5px",
                marginRight: "20px",
              }}
            >
              <SearchBar
                placeholder="Search by Loan No/Customer Name/Mobile No"
                searchKeyword={searchKeyword}
              />
              <div
                style={{
                  float: "right",
                  width: "162px",
                  marginRight: "20px",
                  height: "38px",
                  marginLeft: "15px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={false}
                  onChange={(value) => setStatusValue(value.value)}
                  defaultValue={statusOptions[0]}
                  options={statusOptions}
                />
              </div>

              <Button
                style={{
                  float: "right",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => {
                  getAutopayDedutionDates(
                    userLoginDetails.autoFinanceUser.organizationId
                  );
                  setAddUpiMandateModal(true);
                  setUpiAddressVerified(false);
                  setUpiAddress(null);
                  setSelectedDateToDeductAmount(null);
                  setSelectedFrequencyType(null);
                }}
              >
                <i
                  className="fa fa-plus-circle"
                  style={{ fontSize: "12px" }}
                ></i>
                &nbsp; Add UPI Mandate
              </Button>
              {hasAccess(100)&&
              <Button
                style={{
                  float: "right",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => {
                  setUploadMandateModal(true);
                }}
              >
                <i className="fa fa-upload" style={{ fontSize: "12px" }}></i>
                &nbsp; Bulk Update EMI Amt
              </Button>}

              <Button
                style={{
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => subsDownloadData()}
              >
                <i className="fa fa-download"></i>&nbsp;&nbsp;Download
              </Button>
            </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "5px",
                  marginRight: "20px",
                }}
              >
                <SearchBar
                  placeholder="Search by Loan No/Customer Name/Mobile No"
                  searchKeyword={searchKeyword}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginBottom:"15px",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>Status</label>
                  <div
                    style={{
                      width: "130px",
                      height: "38px",
                      marginLeft: "5px",
                      marginRight: "5px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      id="statusFilter"
                      isSearchable={false}
                      onChange={(value) => {
                        setStatusValue(value.value);
                      }}
                      defaultValue={statusOptions[0]}
                      options={statusOptions}
                    />
                  </div>
                </div>
                <Button
                  style={{
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                    marginRight: "10px",
                  }}
                  className="me-2"
                  variant="primary btn-rounded"
                  onClick={() => {
                    getAutopayDedutionDates(userLoginDetails.autoFinanceUser.organizationId);
                    setAddUpiMandateModal(true);
                    setUpiAddressVerified(false);
                    setUpiAddress(null);
                    setSelectedDateToDeductAmount(null);
                    setSelectedFrequencyType(null);
                  }}
                >
                  <i className="fa fa-plus-circle" style={{ fontSize: "12px" }}></i>&nbsp; Add UPI Mandate
                </Button>
                {hasAccess(100) && (
                  <Button
                    style={{
                      fontFamily:
                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      marginRight: "10px",
                    }}
                    className="me-2"
                    variant="primary btn-rounded"
                    onClick={() => {
                      setUploadMandateModal(true);
                    }}
                  >
                    <i className="fa fa-upload" style={{ fontSize: "12px" }}></i>&nbsp; Bulk Update EMI Amt
                  </Button>
                )}
                <Button
                  style={{
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                    marginRight: "15px",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => subsDownloadData()}
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                </Button>
              </div>

            <div style={{ margin: "10px" }}>
              <LoadingOverlay
                active={isLoading}
                // spinner
                text={
                  <p
                    style={{
                      color: "black",
                    }}
                  >
                    <div>
                      <Button
                        style={{
                          backgroundColor: "#FFFF",
                          color: "black",
                          borderColor: "white",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                        variant="primary"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="md"
                          variant="primary"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;&nbsp;Loading...
                      </Button>
                    </div>
                  </p>
                }
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(192,192,192,0.4)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "80px",
                    "& svg circle": {
                      stroke: "rgb(136, 108, 192)",
                    },
                  }),
                }}
              >
                <div
                  style={{
                    background: "white",
                    margin: "10px",
                    marginTop: "-5px",
                    overflowX: "auto",
                    overflowY: "auto",
                    maxHeight: "500px",
                  }}
                >
                  <BootstrapTable
                    keyField="id"
                    data={subscriptionList}
                    columns={subscriptionColumns}
                    onSelect={onPageChange}
                  />
                </div>
                {subscriptionList.length === 0 && (
                  <div className="d-flex justify-content-center">
                    <img src={NO_DATA_FOUND} alt="" />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                  }}
                >
                  <PaginationComponent
                    totalItems={totalElements}
                    pageSize={10}
                    defaultActivePage={currPage + 1}
                    onSelect={onPageChange}
                  />
                </div>
              </LoadingOverlay>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="uploadedFiles" key="1" mountOnEnter unmountOnExit>
            <UploadedFilesList allocationType={"paymentAmountUploadList"} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      </div>
      <Modal className="fade" show={basicModal} size="xl">
        <Modal.Header>
          <Modal.Title>Subscription Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: "0px",
            overflowY: "auto",
            maxHeight: "calc(120vh - 210px)",
          }}
        >
          <div className="custom-tab-1" style={{ margin: "20px" }}>
            <Tab.Container defaultActiveKey="subscriptionDetails" key={'subTabs'}>
              <Nav as="ul" className="nav-tabs-custom nav-justified">
                <Nav.Item as="li" key="0">
                  <Nav.Link eventKey="subscriptionDetails">
                    <b> Subscription Details </b>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item as="li" key="1">
                  <Nav.Link eventKey="skippedSubscriptions">
                    <b> Skipped Transactions</b>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item as="li" key="2">
                  <Nav.Link eventKey="subscriptionsHistory">
                    <b> Skipped Transactions History </b>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="subscriptionDetails" key="0" mountOnEnter>
                  <div>
                    <div
                      className="card"
                      style={{
                        padding: "10px",
                        backgroundColor: "rgb(243,240,249)",
                        marginTop: "7px",
                      }}
                    >
                      <Row>
                        <Col>
                          <div>
                            <br></br>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Emp Name
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.employeeName
                                  ? subscriptionDetails?.employeeName
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Emp Id
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.employeeId
                                  ? subscriptionDetails?.employeeId
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Customer Name
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.name
                                  ? subscriptionDetails?.name
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Mobile No
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.mobileNumber
                                  ? subscriptionDetails?.mobileNumber
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Loan No
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.merchantUserId
                                  ? subscriptionDetails?.merchantUserId
                                  : "-"}
                                {/* {subscriptionDetails?.state === "ACTIVE" &&
                                  hasAccess(57) && (
                                    <span
                                      style={{
                                        marginLeft: "30px",
                                      }}
                                      onClick={() => {
                                        setLoanModal(true);
                                      }}
                                    >
                                      <i
                                        className="fa fa-edit text-primary me-2"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  )} */}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Loan No Reference
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.loanNumber
                                  ? subscriptionDetails?.loanNumber
                                  : "-"}
                                {subscriptionDetails?.state === "ACTIVE" &&
                                  hasAccess(57) && (
                                    <span
                                      style={{
                                        marginLeft: "30px",
                                      }}
                                      onClick={() => {
                                        setLoanModal(true);
                                      }}
                                    >
                                      <i
                                        className="fa fa-edit text-primary me-2"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Subscription Id
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.phoneSubcriptionId
                                  ? subscriptionDetails?.phoneSubcriptionId
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Max Amount
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.amount
                                  ? subscriptionDetails?.amount
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Frequency
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.frequency
                                  ? subscriptionDetails?.frequency
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Date of Deduction
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.dateToDeductAmount
                                  ? subscriptionDetails?.dateToDeductAmount
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                EMI
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.amountToBeDeducted
                                  ? subscriptionDetails?.amountToBeDeducted
                                  : "-"}
                                {hasAccess(70) &&
                                  subscriptionDetails?.state === "ACTIVE" && (
                                    <span
                                      style={{
                                        marginLeft: "30px",
                                      }}
                                      onClick={() => {
                                        setAmountToBePaidModal(true);
                                      }}
                                    >
                                      <i
                                        className="fa fa-edit text-primary me-2"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Tenure(in Months)
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.recurringCount
                                  ? subscriptionDetails?.recurringCount
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Status
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.state
                                  ? subscriptionDetails?.state
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Created Date & Time
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.createdTime
                                  ? subscriptionDetails?.createdTime
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Updated Date & Time
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.updateTime
                                  ? subscriptionDetails?.updateTime
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Subscription Start Date
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.subscriptionStartDate
                                  ? subscriptionDetails?.subscriptionStartDate
                                  : "-"}
                              </td>
                            </tr>
                            {/* <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        UTR No
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {subscriptionDetails?.utr
                          ? subscriptionDetails?.utr
                          : "-"}
                      </td>
                    </tr> */}
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                IFSC Code
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.ifsc
                                  ? subscriptionDetails?.ifsc
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "30%",
                                }}
                              >
                                Account No
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {subscriptionDetails?.maskedAccountNumber
                                  ? subscriptionDetails?.maskedAccountNumber
                                  : "-"}
                              </td>
                            </tr>
                            {/* <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Payment Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {subscriptionDetails?.paymentState
                          ? subscriptionDetails?.paymentState
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Notification Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {subscriptionDetails?.notificationState
                          ? subscriptionDetails?.notificationState
                          : "-"}
                      </td>
                    </tr> */}
                            {/* <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Transaction Id
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {subscriptionDetails?.transactionId
                          ? subscriptionDetails?.transactionId
                          : "-"}
                      </td>
                    </tr> */}
                            <tr></tr>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {/* )} */}
                    <div
                      style={{
                        fontSize: "large",
                        fontWeight: "bold",
                        margin: "20px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Transaction Details</span>
                      <Button
                        style={{
                          marginRight: "15px",
                          float: "right",
                          fontFamily:
                            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                        }}
                        className="me-2"
                        variant="success btn-rounded"
                        onClick={() => getTransFullResponseData()}
                      >
                        <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                      </Button>
                    </div>
                    <div
                      style={{
                        background: "white",
                        margin: "10px",
                        marginTop: "-5px",
                        overflowX: "auto",
                        overflowY: "auto",
                        maxHeight: "400px",
                      }}
                    >
                      <BootstrapTable
                        keyField="id"
                        data={transactionList}
                        columns={transactionColumns}
                      />
                    </div>
                    {transactionList.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <img src={NO_DATA_FOUND} alt="" />
                      </div>
                    )}
                    {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "0px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements2}
                pageSize={10}
                defaultActivePage={currPage2 + 1}
                onSelect={onPageChange2}
              />
            </div> */}
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="skippedSubscriptions" key="1" mountOnEnter>
                  <div
                    style={{
                      background: "white",
                      margin: "10px",
                      marginTop: "-5px",
                      overflowX: "auto",
                      overflowY: "auto",
                      maxHeight: "400px",
                    }}
                  >
                    <BootstrapTable
                      keyField="id"
                      data={skippedSubscriptionsList}
                      columns={skippedSubscriptionsColumns}
                    />
                  </div>
                  {skippedSubscriptionsList.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey="subscriptionsHistory" key="2" mountOnEnter>
                  <div
                    style={{
                      background: "white",
                      margin: "10px",
                      marginTop: "-5px",
                      overflowX: "auto",
                      overflowY: "auto",
                      maxHeight: "400px",
                    }}
                  >
                    <BootstrapTable
                      keyField="id"
                      data={skippedSubscriptionsHistoryList}
                      columns={skippedSubscriptionsHistoryColumns}
                    />
                  </div>
                  {skippedSubscriptionsHistoryList.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={skipModal} size="sm">
        <Modal.Header>
          <Modal.Title>Skip Autopay Transaction</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setSkipModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                skippedValue: "",
              }}
              onSubmit={skipSubscriptions}
              validationSchema={skipValidate}
            >
              {({ formik, errors }) => (
                <div style={{ margin: "2px" }}>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="No.of Autopay Transactions to be Skipped"
                            name="skippedValue"
                            type="text"
                          />

                          <Button
                            style={{
                              float: "right",
                              marginTop: "10px",
                              marginLeft: "5px",
                            }}
                            className="me-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right", marginTop: "10px" }}
                            className="me-2"
                            variant="danger light"
                            onClick={() => {
                              setSkipModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={addUpiMandateModal}>
        <Modal.Header>
          <Modal.Title>Add UPI Mandate</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setAddUpiMandateModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                amount: "",
                amountToBeDeducted: "",
                dateToDeductAmount: "",
                frequency: "",
                merchantUserId: "",
                mobileNumber: "",
                name: "",
                organizationId: "",
                recurringCount: "",
                userId: "",
                skipTransaction: false,
                vpaAddress: "",
              }}
              onSubmit={addSubscription}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div style={{ margin: "2px" }}>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="Loan Number"
                            name="merchantUserId"
                            type="text"
                          />
                          <TextField
                            isAsterisk={true}
                            label="Name"
                            name="name"
                            type="text"
                          />
                          <TextField
                            isAsterisk={true}
                            label="Maximum Amount"
                            name="amount"
                            type="text"
                          />
                          <TextField
                            isAsterisk={true}
                            label="Amount To Be Deducted"
                            name="amountToBeDeducted"
                            type="text"
                          />
                          <TextField
                            isAsterisk={true}
                            label="Mobile Number"
                            name="mobileNumber"
                            maxlength="10"
                            type="text"
                          />
                          <TextField
                            isAsterisk={true}
                            label="Tenure(In Months)"
                            name="recurringCount"
                            type="text"
                          />
                          <div>
                            <label
                              className="control-label"
                              style={{ fontWeight: "bold" }}
                            >
                              UPI Address
                            </label>
                            <div style={{ display: "flex" }}>
                              <input
                                style={{
                                  width: "330px",
                                  height: "35px",
                                  borderRadius: "25px",
                                  borderColor: "#a0a0a0",
                                  borderWidth: "1px",
                                  boxShadow: "1px 1px 2px #3a353b",
                                  paddingLeft: "10px",
                                  // marginLeft: "10px",
                                }}
                                onChange={(e) => {
                                  setUpiAddress(e.target.value);
                                  setUpiAddressVerified(false);
                                  console.log("upiAddress", upiAddress);
                                }}
                              />
                              <div>
                                {upiAddressVerified === false && (
                                  <Button
                                    style={{
                                      float: "right",
                                      marginLeft: "15px",
                                    }}
                                    className="me-2"
                                    variant="primary btn-rounded"
                                    // classNamePrefix="select-box"
                                    onClick={() => verifyUpiAddress()}
                                  >
                                    Verify
                                  </Button>
                                )}
                                {upiAddressVerified === true && (
                                  <Button
                                    style={{
                                      float: "right",
                                      marginLeft: "10px",
                                    }}
                                    className="me-2"
                                    variant="success btn-rounded"
                                  >
                                    Verified
                                    <span className="ms-1 fa fa-check" />
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <label
                              className="control-label"
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                marginBottom: "10px",
                                width: "1000px",
                                marginTop: "10px",
                              }}
                            >
                              Frequency
                            </label>
                          </div>
                          <Select
                            placeholder="Select Frequency"
                            classNamePrefix="select-box"
                            style={{
                              float: "left",
                              display: "flex",
                              marginTop: "10px",
                              width: "500px",
                            }}
                            options={frequencyOptions}
                            onChange={(value) => {
                              setSelectedFrequencyType(value.value);
                            }}
                          />
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              width: "500px",
                              marginTop: "10px",
                            }}
                          >
                            Date To Deduct Amount
                          </label>

                          <Select
                            placeholder="Select Date to deduct amount"
                            classNamePrefix="select-box"
                            options={deductionsDates}
                            onChange={(value) => {
                              setSelectedDateToDeductAmount(value.value);
                            }}
                          />
                          <label
                            // className="control-label"
                            style={{ fontWeight: "bold", marginTop: "10px" }}
                          >
                            Subscription Start Date
                          </label>
                          <input
                            style={{
                              width: "410px",
                              // height: "90px",
                              borderRadius: "7px",
                              borderColor: "#a0a0a0",
                              borderWidth: "1px",
                              boxShadow: "1px 1px 2px #3a353b",
                              padding: "10px",
                              // marginLeft: "10px",
                              marginBottom: "10px",
                            }}
                            type="date"
                            min={new Date(
                              +new Date().setHours(0, 0, 0, 0) + 2 * 86400000
                            ).toLocaleDateString("fr-CA")}
                            // max={new Date().toISOString().split('T')[0]}
                            onChange={(e) => {
                              setSelectedSubsStartDate(
                                Math.floor(
                                  new Date(e.target.value).getTime() / 1000.0
                                )
                              );
                            }}
                          />
                          {/* <div
                            className="form-check form-switch"
                            style={{
                              marginTop: "10px",
                              marginRight: "15px",
                              display: "flex",
                              flexDirection: "row",
                              padding: "initial",
                            }}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckDefault"
                              style={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              Skip Next Payment
                            </label>
                            <input
                              style={{
                                width: "45px",
                                height: "20px",
                                marginLeft: "10px",
                                fontWeight: "bold",
                              }}
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              onChange={() => {
                                setIsTransSkipped(!isTransSkipped);
                              }}
                            />
                          </div> */}
                          {upiAddressVerified === true && (
                            <Button
                              style={{
                                float: "right",
                                marginTop: "10px",
                                marginLeft: "5px",
                              }}
                              className="me-2"
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                          <Button
                            style={{ float: "right", marginTop: "10px" }}
                            className="me-2"
                            variant="danger light"
                            onClick={() => {
                              setAddUpiMandateModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={timerModal} size="sm" centered>
        <Modal.Body>
          <SimpleCountdownTimer
            close={handleClose}
            subscriptionId={subscriptionId}
            getSubsList={getSubsList}
          />
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={loanModal}>
        <Modal.Header>
          <Modal.Title>Update Loan No Reference</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setLoanModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                loanNo: subscriptionDetails?.loanNumber,
              }}
              onSubmit={handleOk}
              validationSchema={loanNoValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="Loan No Reference"
                            name="loanNo"
                            type="text"
                          />
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => setLoanModal(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={amountToBePaidModal}>
        <Modal.Header>
          <Modal.Title>Update Amount To Be Deducted</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAmountToBePaidModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                amountToBeDeducted: subscriptionDetails?.amountToBeDeducted,
              }}
              onSubmit={updateAmountToBePaid}
              validationSchema={amountToBePaidValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="Amount To Be Deducted"
                            name="amountToBeDeducted"
                            type="text"
                          />
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => setAmountToBePaidModal(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={uploadMandateModal} size="xl">
        <Modal.Header>
          <Modal.Title>Bulk Update EMI Amt</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setUploadMandateModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <UploadPayments close={handleCloseForUpload} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Subscriptions;
