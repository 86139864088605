import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Modal } from "react-bootstrap";
// eslint-disable-next-line
import ReactExport from "react-data-export";
import Select from "react-select";
import NO_DATA_FOUND from "../../images/no-data-found.png";
import { employeeColumns, agencyColumns } from "../TableColumns";
// import { LoadingOverlayComponent } from "../../CommonComponents/LoadingOverlay";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import "../fiallocations.css";
import TransferAllocations from "./TransferAllocations";
import DetailedAllocationModal from "./DetailedAllocationModal";
import {
  // convertToLocalDateTime,
  // downloadAllocationsForAgencies,
  // downloadAllocationsForEmployees,
  formatDate,
  getStartAndEndDate,
} from "../utils";
import MonthPicker from "../../CommonComponents/MonthPicker";
import ReactGA from "react-ga";
import moment from "moment";
import { getTokenInLocalStorage, hasAccess } from "../../services/AuthService";
import FISubmittedDetails from "./FISubmittedDetails";
import EditFiDetails from "../EditFIDetails/EditFiDetails";
import * as XLSX from "xlsx";
import { EpochToDate } from "../../CommonComponents/Helper";

function AllocationDetails(props) {
  const {
    fiAllocationsOfEmp,
    fiAllocationsOfAgencies,
    type,
    getFiAllocationsOfEmployees,
    getFiAllocationsOfAgencies,
    setFiAllocationsOfEmp,
    setFiAllocationsOfAgencies,
    setNoDataFoundForAgency,
    setNoDataFoundForEmp,
    setIsLoading,
    clients,
  } = props;

  const [deleteIds, setDeleteIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [selectedAllocationStatus, setSelectedAllocationStatus] = useState("");
  const [selectedFiClient, setSelectedFiClient] = useState("");
  const [selectedFiStatus, setSelectedFiStatus] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [fiAllocationDetails, setFiAllocationDetails] = useState(null);
  const [agencyRemarks, setAgencyRemarks] = useState(null);
  const [transferModal, setTransferModal] = useState(false);
  const [detailedViewModal, setDetailedViewModal] = useState(false);
  const [fiDetailsModal, setFiDetailsModal] = useState(false);
  const [editFiDetailsModal, setEditFiDetailsModal] = useState(false);
  const [fullFiDetails, setFullFiDetails] = useState(false);
  const [validateStatus, setValidateStatus] = useState([]);
  const [start, setStart] = useState(
    moment().clone().startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(
    moment().clone().endOf("month").add(1, "days").format("YYYY-MM-DD")
  );
  const [selectedRow, setSelectedRow] = useState({});
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const { SearchBar } = Search;
  // eslint-disable-next-line
  const ExcelFile = ReactExport.ExcelFile;
  // eslint-disable-next-line
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  useEffect(() => {
    ReactGA.pageview("FI Allocation Details");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let userLoginDetails = getTokenInLocalStorage();

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setDeleteIds((deleteIds) => [...deleteIds, { id: row.id }]);
      setSelected((selected) => [...selected, row.id]);
      setValidateStatus((validateStatus) => [...validateStatus, row.status]);
      type === "EMPLOYEES"
        ? setSelectedUserId((selectedUserId) => [
            ...selectedUserId,
            row.emp_user_id,
          ])
        : setSelectedUserId((selectedUserId) => [
            ...selectedUserId,
            row.agency_id,
          ]);
    } else {
      setDeleteIds((prev) => prev.filter((data) => data.id !== row.id));
      setSelected((prev) => prev.filter((x) => x !== row.id));
      setValidateStatus((prev) => prev.filter((x) => x !== row.status));
      type === "EMPLOYEES"
        ? setSelectedUserId((prev) => prev.filter((x) => x !== row.emp_user_id))
        : setSelectedUserId((prev) => prev.filter((x) => x !== row.agency_id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      // eslint-disable-next-line
      rows.map((item) => {
        setDeleteIds((deleteIds) => [...deleteIds, { id: item.id }]);
        setSelected((selected) => [...selected, item.id]);
        setSelectedUserId((selectedUserId) => [
          ...selectedUserId,
          item.emp_user_id,
        ]);
      });
    } else {
      setDeleteIds([]);
      setSelected([]);
      setSelectedUserId([]);
    }
  };

  const statusOptions = [
    { label: "All", value: "" },
    { label: "Open", value: "Pending" },
    { label: "In Progress", value: "In Progress" },
    { label: "Closed", value: "Closed" },
  ];
  const fiStatusOptions = [
    { label: "All", value: "" },
    { label: "FI Submitted", value: "FI Submitted" },
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "Refer to Credit", value: "Refer to Credit" },
    { label: "Door Locked", value: "Door Locked" },
    { label: "Address Untraceable", value: "Untraceable" },
    { label: "Fraud", value: "Fraud" },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected: selected,
    hideSelectAll: true,
  };

  const options = {
    hideSizePerPage: true,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    disablePageTitle: true,
  };

  const handleDelete = () => {
    setIsLoading(true);
    if (validateStatus.length === 0) {
      setIsLoading(false);
      toaster("error", "Please select atleast one Allocation");
    } else if (validateStatus.includes("Closed", "Inprogress")) {
      setIsLoading(false);
      toaster("error", "Please select allocations which are in Open status");
    } else {
      setValidateStatus([]);
      let requestPayload = {
        id: deleteIds,
      };
      nodeApi
        .deleteDataFi(apiCall.fiAllocations, requestPayload)
        .then((response) => {
          if (response.success === true) {
            type === "EMPLOYEES"
              ? getFiAllocationsOfEmployees()
              : getFiAllocationsOfAgencies();
            setDeleteIds([]);
            setSelected([]);
            setIsLoading(false);
            toaster("success", "Deleted successfully");
          } else {
            setIsLoading(false);
            toaster("error", "Something went wrong. Please try again later.");
            setDeleteIds([]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toaster("error", "Something went wrong. Please try again later.");
          setDeleteIds([]);
          setValidateStatus([]);
        });
    }
  };

  const handleTransfer = () => {
    setIsLoading(true);
    if (validateStatus.length === 0 && selected?.length === 0) {
      setIsLoading(false);
      toaster("error", "Please select atleast one Allocation");
    } else if (validateStatus.includes("Closed")) {
      setIsLoading(false);
      toaster(
        "error",
        "Please select allocations which are in Open or InProgress status"
      );
    } else {
      setTransferModal(true);
      if (type === "EMPLOYEES") {
        let requestPayload = {
          organizationId: userData.autoFinanceUser.organizationId,
          reportingOfficerId:
            userData.autoFinanceUser.roleName === "MANAGER" ||
            userData.autoFinanceUser.roleName === "MANAGER_RA"
              ? userData.autoFinanceUser.userId
              : null,
          branchId: userData.autoFinanceBranchDetails.branchId,
          roleName: userData.autoFinanceUser.roleName,
        };
        nodeApi
          .postData(apiCall.getUsersByOrganization, requestPayload)
          .then((response) => {
            if (response.success === true) {
              if (response.users) {
                const result = response.users.map((item, index) => ({
                  value: item.user_id,
                  label: item.name + " - " + item.branch_name,
                }));
                let filtered = result.filter(function (item) {
                  return selectedUserId.indexOf(item.value) === -1;
                });
                filtered.sort(function (a, b) {
                  if (a.label < b.label) {
                    return -1;
                  }
                  return 0;
                });
                setUsersList(filtered);
                setSelected([]);
                setIsLoading(false);
              }
            }
          });
      } else {
        const organizationId = userData.autoFinanceUser.organizationId;
        nodeApi
          .getData(apiCall.agencies, "/" + organizationId)
          .then((response) => {
            if (response.success === true) {
              if (response.agencies) {
                let activeAgencies = [];
                activeAgencies = response.agencies.filter(
                  (item) => item.status === "ACTIVE"
                );
                if (activeAgencies.length > 0) {
                  const result = activeAgencies.map((item, index) => ({
                    value: item.agencyId,
                    label: item.agencyName,
                  }));

                  let filtered = result.filter(function (item) {
                    return selectedUserId.indexOf(item.value) === -1;
                  });
                  setUsersList(filtered);
                  setIsLoading(false);
                }
              }
            }
          });
      }
    }
  };

  const loadData = (allocationStatus, fiStatus, fiClient) => {
    setIsLoading(true);
    let requestPayload = {
      organizationId: userData.autoFinanceUser.organizationId,
      // roleName: userData.autoFinanceUser.roleName,
      startDate: start,
      endDate: end,
      // managerId:
      //   userData.autoFinanceUser.roleName === "MANAGER"
      //     ? userData.autoFinanceUser.userId
      //     : null,
      roleName: hasAccess(118) ? "ADMIN" : userData.autoFinanceUser.roleName,
      managerId: !hasAccess(118)
        ? userData.autoFinanceUser.roleName === "MANAGER" || "MANAGER_RA"
          ? userData.autoFinanceUser.userId
          : null
        : null,
      type: type === "EMPLOYEES" ? "EMPLOYEES" : "AGENCIES",
      fiStatus: fiStatus,
      allocationStatus: allocationStatus,
      client: fiClient,
    };
    nodeApi
      .postData(apiCall.filterFiAllocations, requestPayload)
      .then((response) => {
        if (response.success === true) {
          if (response.data && response.data.length === 0) {
            if (type === "EMPLOYEES") {
              setNoDataFoundForEmp(true);
            } else {
              setNoDataFoundForAgency(true);
            }
          } else {
            setNoDataFoundForEmp(false);
            setNoDataFoundForAgency(false);
          }
          let temp = hasAccess(118)
            ? response.data?.filter((x) => x.status === "In Progress")
            : response.data;
          type === "EMPLOYEES"
            ? setFiAllocationsOfEmp(temp)
            : setFiAllocationsOfAgencies(response.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  const getLoadData = () => {
    loadData(selectedAllocationStatus, selectedFiStatus, selectedFiClient);
  };

  const setCustomDatesForGraph = (value) => {
    setIsLoading(true);
    let formatDates = getStartAndEndDate(value);
    setStart(formatDates.startDate);
    setEnd(formatDates.endDate);
    let requestPayload = {
      organizationId: userData.autoFinanceUser.organizationId,
      // roleName: userData.autoFinanceUser.roleName,
      startDate: formatDates.startDate,
      endDate: formatDates.endDate,
      // managerId:
      //   userData.autoFinanceUser.roleName === "MANAGER"
      //     ? userData.autoFinanceUser.userId
      //     : null,
      roleName: hasAccess(118) ? "ADMIN" : userData.autoFinanceUser.roleName,
      managerId: !hasAccess(118)
        ? userData.autoFinanceUser.roleName === "MANAGER" || "MANAGER_RA"
          ? userData.autoFinanceUser.userId
          : null
        : null,
      type: type === "EMPLOYEES" ? "EMPLOYEES" : "AGENCIES",
      fiStatus: selectedFiStatus,
      allocationStatus: selectedAllocationStatus,
      client: selectedFiClient,
    };
    nodeApi
      .postData(apiCall.filterFiAllocations, requestPayload)
      .then((response) => {
        if (response.success === true) {
          if (response.data && response.data.length === 0) {
            if (type === "EMPLOYEES") {
              setNoDataFoundForEmp(true);
            } else {
              setNoDataFoundForAgency(true);
            }
          } else {
            setNoDataFoundForEmp(false);
            setNoDataFoundForAgency(false);
          }
          let temp = hasAccess(118)
            ? response.data?.filter((x) => x.status === "In Progress")
            : response.data;
          type === "EMPLOYEES"
            ? setFiAllocationsOfEmp(temp)
            : setFiAllocationsOfAgencies(response.data);
          setIsLoading(false);
        }
      });
  };

  const handleDetailedAllocation = (row, type, e) => {
    e.stopPropagation();
    setSelectedRow(row);
    setDetailedViewModal(true);
  };

  const handleFiEditClose = () => {
    setEditFiDetailsModal(false);
  };

  const handleEditFiDetails = (row, type, e) => {
    e.stopPropagation();
    setIsLoading(true);
    // setSelectedRow(row);
    // console.log(row);
    nodeApi
      .getData(apiCall.getFiAllocationPdfData + "/" + row.id)
      .then((response) => {
        // console.log(response);
        setFiAllocationDetails(
          response.allocationDetails?.length > 0
            ? response.allocationDetails[0]
            : response.allocationDetails
        );

        nodeApi
          .getData(apiCall.getFiAllocationPdfData + "/agencyRemarks/" + row.id)
          .then((response2) => {
            if (type === "EMPLOYEES" && response.allocationDetails.length > 0) {
              let visitId =
                response.allocationDetails &&
                response.allocationDetails[0].field_investigation_visits_id;
              if (visitId !== null) {
                nodeApi
                  .getData(
                    apiCall.getFiAllocationPdfData +
                      "/employeeVisits/" +
                      visitId
                  )
                  .then((response3) => {
                    var fullObject = {
                      ...row,
                      ...response?.allocationDetails[0],
                      ...response2.agencyRemarks[0],
                      ...response3.employeeVisits[0],
                    };
                    console.log(fullObject);
                    setFullFiDetails(fullObject);
                    setIsLoading(false);
                    setEditFiDetailsModal(true);
                  });
              }
            }
          })
          .catch((error) => {
            toaster(
              "error",
              "Something went wrong. Please try again later." + error
            );
          });
      });
    // nodeApi
    //   .getData(apiCall.getFiAllocationPdfData + "/agencyRemarks/" + row.id)
    //   .then((response2) => {
    //     setAgencyRemarks(
    //       response2.agencyRemarks?.length > 0
    //         ? response2.agencyRemarks[0]
    //         : response2.agencyRemarks
    //     );
    //   })
    //   .catch((error) => {
    //     toaster("error", "Something went wrong. Please try again later.");
    //   });
  };

  const handleFiAllocationDetails = (row, type, e) => {
    e.stopPropagation();
    setSelectedRow(row);
    nodeApi
      .getData(apiCall.getFiAllocationPdfData + "/" + row.id)
      .then((response) => {
        setFiAllocationDetails(
          response.allocationDetails?.length > 0
            ? response.allocationDetails[0]
            : response.allocationDetails
        );
        setFiDetailsModal(true);
        // nodeApi
        //   .getData(
        //     apiCall.getFiAllocationPdfData + "/agencyRemarks/" + row.id
        //   )
        //   .then((response2) => {
        //     setAgencyRemarks( response2.agencyRemarks?.length > 0
        //       ? response2.agencyRemarks[0]
        //       : response2.agencyRemarks);
        //     setFiAllocationDetails(
        //       response.allocationDetails?.length > 0
        //         ? response.allocationDetails[0]
        //         : response.allocationDetails
        //     );
        //     setFiDetailsModal(true);
        //   })
        //   .catch((error) => {
        //     toaster("error", "Something went wrong. Please try again later."+error);
        //   });
      });
    nodeApi
      .getData(apiCall.getFiAllocationPdfData + "/agencyRemarks/" + row.id)
      .then((response2) => {
        setAgencyRemarks(
          response2.agencyRemarks?.length > 0
            ? response2.agencyRemarks[0]
            : response2.agencyRemarks
        );
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
      });
  };

  const handleExport = () => {
    setIsLoading(true);
    let mgrTable = [
      {
        A: "App No",
        B: "Applicant Type",
        C: "Emp Name",
        D: "Emp Id",
        E: "Loan No",
        F: "Customer Name",
        G: "FI Type",
        H: "FI Client",
        I: "Product Name",
        J: "Assigned Date",
        K: "Due Date",
        L: "Allocated Status",
        M: "FI Status",
      },
    ];
    fiAllocationsOfEmp?.forEach((row) => {
      mgrTable.push({
        A: row.application_number ? row.application_number : "-",
        B: row.applicant_type ? row.applicant_type : "-",
        C: row.emp_name ? row.emp_name : "-",
        D: row.employee_id ? row.employee_id : "-",
        E: row.loan_number ? row.loan_number : "-",
        F: row.customer_name ? row.customer_name : "-",
        G: row.fi_type ? row.fi_type : "-",
        H: row.client ? row.client : "-",
        I: row.product_name ? row.product_name : "-",
        J: row.created_time ? EpochToDate(row.created_time) : "-",
        K: row.due_date ? formatDate(row.due_date) : "-",
        L: row.status ? row.status : "-",
        M: row.fi_status ? row.fi_status : "-",
      });
    });
    const finalData = [...mgrTable];
    // console.log("finaldata", finalData);
    //create a new workbook
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    // console.log("sheet", sheet);
    XLSX.utils.book_append_sheet(wb, sheet, "FI_Allocations");
    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "FI_Allocations_" + EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx"
    );
    ReactGA.event({
      category: "FI Allocations List Download",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_FI_Allocations" +
        EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
    setIsLoading(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: "12dvh" }}>
      <div className="allocation-details-block">
        {userLoginDetails.autoFinanceUser.roleName !== "MANAGER_RA" && (
          <Button
            className="me-2 transfer-btn"
            variant="primary btn-rounded"
            onClick={handleTransfer}
          >
            <i className="fas fa-external-link-alt" />
            &nbsp;&nbsp;Transfer
          </Button>
        )}
        {userLoginDetails.autoFinanceUser.roleName !== "MANAGER_RA" && (
          <Button
            className="me-2 transfer-btn"
            variant="danger btn-rounded"
            onClick={handleDelete}
          >
            <i className="fas fa-trash" />
            &nbsp;&nbsp;Delete
          </Button>
        )}
        <Button
          className="me-2 agency-button"
          variant="primary btn-rounded"
          onClick={() => handleExport()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button>
        {/* <ExcelFile
          element={
            <Button
              className="me-2 agency-button"
              variant="primary btn-rounded"
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Download
            </Button>
          }
        >
          <ExcelSheet
            dataSet={
              type === "EMPLOYEES"
                ? downloadAllocationsForEmployees(fiAllocationsOfEmp)
                : downloadAllocationsForAgencies(fiAllocationsOfAgencies)
            }
            name="Agencies"
          />
        </ExcelFile> */}
      </div>
      {/* <LoadingOverlayComponent showLoader={showLoader}> */}
      <div className="table-content">
        <ToolkitProvider
          keyField="id"
          data={
            type === "EMPLOYEES" ? fiAllocationsOfEmp : fiAllocationsOfAgencies
          }
          columns={
            type === "EMPLOYEES"
              ? employeeColumns({
                  handleDetailedAllocation,
                  handleFiAllocationDetails,
                  handleEditFiDetails,
                })
              : agencyColumns({ handleDetailedAllocation })
          }
          search
        >
          {(props) => (
            <>
              <div className="search-block">
                <SearchBar
                  style={{ width: "280px", marginTop: "15px" }}
                  {...props.searchProps}
                  placeholder={"Search By Name/App.No/Mobile/Emp ID"}
                  className="search-bar"
                />
                <div style={{ marginTop: "15px" }}>
                  <MonthPicker
                    setCustomDatesForGraph={setCustomDatesForGraph}
                  />
                </div>
                {!hasAccess(118) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      marginRight: "10px",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginLeft: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      FI Status
                    </label>
                    <div
                      style={{
                        float: "right",
                        width: "120px",
                        marginRight: "5px",
                        marginBottom: "20px",
                        marginLeft: "5px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => {
                          setSelectedFiStatus(value.value);
                          loadData(
                            selectedAllocationStatus,
                            value.value,
                            selectedFiClient
                          );
                        }}
                        defaultValue={{ label: "All", value: "" }}
                        options={fiStatusOptions}
                      />
                    </div>
                  </div>
                )}
                {!hasAccess(118) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      marginRight: "10px",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginLeft: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      Allocation Status
                    </label>
                    <div
                      style={{
                        float: "right",
                        width: "120px",
                        marginRight: "5px",
                        marginBottom: "20px",
                        marginLeft: "5px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => {
                          setSelectedAllocationStatus(value.value);
                          loadData(
                            value.value,
                            selectedFiStatus,
                            selectedFiClient
                          );
                        }}
                        defaultValue={{ label: "All", value: "" }}
                        options={statusOptions}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    marginRight: "10px",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "bold",
                      marginLeft: "10px",
                      marginBottom: "0px",
                    }}
                  >
                    FI Client
                  </label>
                  <div
                    style={{
                      float: "right",
                      width: "100px",
                      marginRight: "5px",
                      marginBottom: "20px",
                      marginLeft: "5px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      isSearchable={false}
                      onChange={(value) => {
                        setSelectedFiClient(value.value);
                        loadData(
                          selectedAllocationStatus,
                          selectedFiStatus,
                          value.value
                        );
                      }}
                      defaultValue={{ label: "All", value: "" }}
                      options={clients}
                    />
                  </div>
                </div>
              </div>
              <div className="table-container">
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  selectRow={selectRow}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
        {type === "EMPLOYEES"
          ? fiAllocationsOfEmp?.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )
          : fiAllocationsOfAgencies?.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
      </div>
      {/* </LoadingOverlayComponent> */}
      <TransferAllocations
        transferModal={transferModal}
        setTransferModal={setTransferModal}
        usersList={usersList}
        selectedUserId={selectedUserId}
        deleteIds={deleteIds}
        type={type}
        getFiAllocationsOfEmployees={getFiAllocationsOfEmployees}
        getFiAllocationsOfAgencies={getFiAllocationsOfAgencies}
        setDeleteIds={setDeleteIds}
      />
      {detailedViewModal ? (
        <DetailedAllocationModal
          type={type}
          selectedRow={selectedRow}
          detailedViewModal={detailedViewModal}
          setDetailedViewModal={setDetailedViewModal}
          getFiAllocationsOfEmployees={getFiAllocationsOfEmployees}
          getFiAllocationsOfAgencies={getFiAllocationsOfAgencies}
          loadData={getLoadData}
        />
      ) : (
        <></>
      )}
      <Modal className="fade" size="xl" show={fiDetailsModal}>
        <Modal.Header>
          <Modal.Title>FI Submitted Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setFiDetailsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <FISubmittedDetails
            allocationDetails={fiAllocationDetails}
            selectedRow={selectedRow}
            agencyRemarks={agencyRemarks}
            close={setFiDetailsModal}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="xl" show={editFiDetailsModal}>
        <Modal.Header>
          <Modal.Title>Edit FI Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditFiDetailsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          {/* <div>
            <iframe
              src="https://webdemo.clucloud.com/?fe=sales&og=cll&t1=additionaldetails&t2=form1&id=657293"
              title="salesJsonForm"
              width="1100"
              height="450"
            />
          </div> */}
          <EditFiDetails data={fullFiDetails} close={handleFiEditClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AllocationDetails;
