import React, { useEffect, useState } from "react";
import {
  getTokenInLocalStorage,
  hasAccess,
  hasAccessFromTeamPrivilege,
} from "../services/AuthService";
import { getMaskedMobileNo } from "../CommonComponents/Helper";
import { apiCall } from "../rest/restApi";
import * as moduleApi from "../rest/moduleApi";

export default function ProspectDetails({ prospectDetails, close }) {
  const userLoginDetails = getTokenInLocalStorage();
  const [additionalDetails, setAdditionalDetails] = useState(null);

  useEffect(() => {
    getAdditionalDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdditionalDetailsData = () => {
    var payload = {
      feature: "sales",
      org_short_code: userLoginDetails.autoFinanceOrganization.orgshortcode,
      data_id: prospectDetails?.id,
    };
    moduleApi
      .postDataByOrgIdForPayroll(apiCall.getJsonFormData, payload)
      .then((response) => {
        console.log(response);
        setAdditionalDetails(response?.data?.AdditionalDetails);
      });
  };

  // console.log(prospectDetails);
  return (
    <div
      className="card contact-bx item-content"
      style={{
        backgroundColor: "rgb(243,240,249)",
        //   overflowY: "auto",
        //   maxHeight: "400px",
        marginLeft: "15px",
        marginRight: "15px",
      }}
    >
      <div
        style={{
          marginRight: "20px",
          marginBottom: "10px",
          marginTop: "30px",
          marginLeft: "20px",
        }}
      >
        <>
          <tr></tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                textAlign: "left",
                verticalAlign: "middle",
                width: "30%",
              }}
            >
              Prospect Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              {prospectDetails?.customerName
                ? prospectDetails?.customerName
                : "-"}
            </td>
          </tr>
          {/* <tr>
          <td
            style={{
              fontWeight: "500",
              textAlign: "left",
verticalAlign:'middle'
            }}
          >
            Last Name
          </td>
          <td style={{verticalAlign:'middle'}}>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
          <td
            style={{
              fontWeight: "bold",
              textAlign: "left",
verticalAlign:'middle'
            }}
          >
            {prospectDetails?.lastName
              ? prospectDetails?.lastName
              : "-"}
          </td>
        </tr> */}
          <tr>
            <td
              style={{
                fontWeight: "500",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              Contact Number
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              {prospectDetails?.phoneNumber
                ? hasAccess(75) || hasAccessFromTeamPrivilege(75)
                  ? getMaskedMobileNo(prospectDetails?.phoneNumber)
                  : prospectDetails?.phoneNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              Email
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              {prospectDetails?.email ? prospectDetails?.email : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              City
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              {prospectDetails?.city ? prospectDetails?.city : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              State
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              {prospectDetails?.state ? prospectDetails?.state : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              Pin Code
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              {prospectDetails?.pinCode ? prospectDetails?.pinCode : "-"}
            </td>
          </tr>
          {additionalDetails?.TypeOfLoanOrProduct && (
            <>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                    verticalAlign: "middle",
                  }}
                >
                  Type Of Loan/Product
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    verticalAlign: "middle",
                  }}
                >
                  {additionalDetails?.TypeOfLoanOrProduct
                    ? additionalDetails?.TypeOfLoanOrProduct
                    : "-"}
                </td>
              </tr>
              {(additionalDetails?.TypeOfLoanOrProduct === "BT-HL + Topup" ||
                additionalDetails?.TypeOfLoanOrProduct === "BT-HL" ||
                additionalDetails?.TypeOfLoanOrProduct === "LAP" ||
                additionalDetails?.TypeOfLoanOrProduct === "New Home Loan" ||
                additionalDetails?.TypeOfLoanOrProduct === "New LAP" ||
                additionalDetails?.TypeOfLoanOrProduct === "Construction" ||
                additionalDetails?.TypeOfLoanOrProduct ===
                  "Plot + Construction") && (
                <>
                  {(additionalDetails?.TypeOfLoanOrProduct ===
                    "New Home Loan" ||
                    additionalDetails?.TypeOfLoanOrProduct === "New LAP" ||
                    additionalDetails?.TypeOfLoanOrProduct ===
                      "Plot + Construction" ||
                    additionalDetails?.TypeOfLoanOrProduct ===
                      "Construction") && (
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Loan Amount
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.LoanAmount
                            ? additionalDetails?.LoanAmount
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Have You Identified The Property
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.HaveYouIdentifiedTheProperty
                            ? additionalDetails?.HaveYouIdentifiedTheProperty
                            : "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      Estimated Property Value
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {additionalDetails?.EstimatedPropertyValue
                        ? additionalDetails?.EstimatedPropertyValue
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      Title Of Property
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {additionalDetails?.TitleOfProperty
                        ? additionalDetails?.TitleOfProperty
                        : "-"}
                    </td>
                  </tr>
                  {additionalDetails?.TitleOfProperty === "Others" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        Tilte Of Property Details
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {additionalDetails?.TilteOfPropertyDetails
                          ? additionalDetails?.TilteOfPropertyDetails
                          : "-"}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      Required Loan Tenure
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {additionalDetails?.RequiredLoanTenure
                        ? additionalDetails?.RequiredLoanTenure
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      Employment Type
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {additionalDetails?.EmploymentType
                        ? additionalDetails?.EmploymentType
                        : "-"}
                    </td>
                  </tr>
                  {additionalDetails?.EmploymentType === "Salaried" && (
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Type Of Salaried
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.TypeOfSalaried
                            ? additionalDetails?.TypeOfSalaried
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Salary Income Monthly(Individual/Household)
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.SalaryIncomeMonthly
                            ? additionalDetails?.SalaryIncomeMonthly
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Overall Experience
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.OverallExperience
                            ? additionalDetails?.OverallExperience
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Do You File Form 16?
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.DoYouFileForm16
                            ? additionalDetails?.DoYouFileForm16
                            : "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  {(additionalDetails?.EmploymentType ===
                    "Self Employed Professional" ||
                    additionalDetails?.EmploymentType === "Business Owner") && (
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Type Of Business
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.TypeOfBusiness
                            ? additionalDetails?.TypeOfBusiness
                            : "-"}
                        </td>
                      </tr>
                      {additionalDetails?.TypeOfBusiness === "Others" && (
                        <tr>
                          <td
                            style={{
                              fontWeight: "500",
                              textAlign: "left",
                              verticalAlign: "middle",
                            }}
                          >
                            Type Of Business Details
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              verticalAlign: "middle",
                            }}
                          >
                            {additionalDetails?.TypeOfBusinessDetails
                              ? additionalDetails?.TypeOfBusinessDetails
                              : "-"}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Details Of Business
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.DetailsOfBusiness
                            ? additionalDetails?.DetailsOfBusiness
                            : additionalDetails?.DetailsOfBusinessOthers
                            ? additionalDetails?.DetailsOfBusinessOthers
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Monthly Turnover
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.MonthlyTurnover
                            ? additionalDetails?.MonthlyTurnover
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Vintage
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.Vintage
                            ? additionalDetails?.Vintage
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          ITR/GST Availability
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.ITROrGSTAvailability
                            ? additionalDetails?.ITROrGSTAvailability
                            : "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      Any Other Oustanding Loan
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {additionalDetails?.AnyOtherOustandingLoan
                        ? additionalDetails?.AnyOtherOustandingLoan
                        : "-"}
                    </td>
                  </tr>
                  {additionalDetails?.AnyOtherOustandingLoan === "Yes" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        Monthly EMI
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {additionalDetails?.MonthlyEMI
                          ? additionalDetails?.MonthlyEMI
                          : "-"}
                      </td>
                    </tr>
                  )}

                  {(additionalDetails?.TypeOfLoanOrProduct ===
                    "BT-HL + Topup" ||
                    additionalDetails?.TypeOfLoanOrProduct === "BT-HL" ||
                    additionalDetails?.TypeOfLoanOrProduct === "LAP") && (
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Outstanding Loan Amount
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.OutstandingLoanAmount
                            ? additionalDetails?.OutstandingLoanAmount
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Lender Name
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.LenderName
                            ? additionalDetails?.LenderName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Current EMI
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.CurrentEMI
                            ? additionalDetails?.CurrentEMI
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Current ROI (in %)
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.CurrentROI
                            ? additionalDetails?.CurrentROI + " %"
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          Do You Require Top-Up
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {additionalDetails?.DoYouRequireTopUP
                            ? additionalDetails?.DoYouRequireTopUP
                            : "-"}
                        </td>
                      </tr>
                      {additionalDetails?.DoYouRequireTopUP === "Yes" && (
                        <tr>
                          <td
                            style={{
                              fontWeight: "500",
                              textAlign: "left",
                              verticalAlign: "middle",
                            }}
                          >
                            Top-Up Amount
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              verticalAlign: "middle",
                            }}
                          >
                            {additionalDetails?.TopUpAmount
                              ? additionalDetails?.TopUpAmount
                              : "-"}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      PAN Number
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {additionalDetails?.PANNumber
                        ? additionalDetails?.PANNumber
                        : "-"}
                    </td>
                  </tr>
                </>
              )}
              {(additionalDetails?.TypeOfLoanOrProduct === "Personal Loan" ||
                additionalDetails?.TypeOfLoanOrProduct === "Business Loan" ||
                additionalDetails?.TypeOfLoanOrProduct === "Equipment Loan" ||
                additionalDetails?.TypeOfLoanOrProduct === "Vehicle Loan") && (
                <tr>
                  <td
                    style={{
                      fontWeight: "500",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    Remarks
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    {additionalDetails?.TypeOfLoanOrProduct ===
                      "Personal Loan" ||
                    additionalDetails?.TypeOfLoanOrProduct === "Business Loan"
                      ? additionalDetails?.RemarksPersonalOrBusinessLoan
                      : additionalDetails?.TypeOfLoanOrProduct ===
                          "Equipment Loan" ||
                        additionalDetails?.TypeOfLoanOrProduct ===
                          "Vehicle Loan"
                      ? additionalDetails?.RemarksVehicleOrEquipmentLoan
                      : "-"}
                  </td>
                </tr>
              )}
              {additionalDetails?.TypeOfLoanOrProduct ===
                "Customer Reference" && (
                <tr>
                  <td
                    style={{
                      fontWeight: "500",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    Customer Reference Type
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    {additionalDetails?.CustomerReferenceType
                      ? additionalDetails?.CustomerReferenceType
                      : "-"}
                  </td>
                </tr>
              )}
              {additionalDetails?.TypeOfLoanOrProduct ===
                "Customer Reference" &&
                additionalDetails?.CustomerReferenceType ===
                  "Interested To Share A Lead" && (
                  <>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        New Lead Name
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {additionalDetails?.NewLeadName
                          ? additionalDetails?.NewLeadName
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        New Lead Contact Number
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {additionalDetails?.NewLeadContactNumber
                          ? additionalDetails?.NewLeadContactNumber
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        New Lead State
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {additionalDetails?.NewLeadState
                          ? additionalDetails?.NewLeadState
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        New Lead City
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {additionalDetails?.NewLeadCity
                          ? additionalDetails?.NewLeadCity
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        New Lead Pincode
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {additionalDetails?.NewLeadPincode
                          ? additionalDetails?.NewLeadPincode
                          : "-"}
                      </td>
                    </tr>
                  </>
                )}
              {additionalDetails?.TypeOfLoanOrProduct ===
                "Customer Reference" &&
                additionalDetails?.CustomerReferenceType === "Others" && (
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      Customer Reference Type Details
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {additionalDetails?.CustomerReferenceTypeDetails
                        ? additionalDetails?.CustomerReferenceTypeDetails
                        : "-"}
                    </td>
                  </tr>
                )}
              {additionalDetails?.TypeOfLoanOrProduct === "Others" && (
                <tr>
                  <td
                    style={{
                      fontWeight: "500",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    Type Of Loan/Product Details
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    {additionalDetails?.TypeOfLoanOrProductDetails
                      ? additionalDetails?.TypeOfLoanOrProductDetails
                      : "-"}
                  </td>
                </tr>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
}
// "AdditionalDetails": {
//             "AnyOtherOustandingLoan": "YES",
//             "City": "HYDERABAD",
//             "CurrentEMI": 15000,
//             "CurrentROI": 9,
//             "CustomerReferenceType": "Will Refer In Future",
//             "CustomerReferenceTypeDetails": "Details",
//             "DetailsOfBusiness": "Tea Stall",
//             "DetailsOfBusinessOthers": "Others",
//             "DoYouFileForm16": "Yes",
//             "DoYouRequireTopUP": "Yes",
//             "EmploymentType": "Salaried",
//             "EstimatedPropertyValue": 500000,
//             "HaveYouIdentifiedTheProperty": "Yes",
//             "ITROrGSTAvailability": "Yes",
//             "LenderName": "SBI",
//             "LoanAmount": 100000,
//             "MonthlyEMI": 100000,
//             "MonthlyTurnover": 100000,
//             "NewLeadCity": "HYDERABAD",
//             "NewLeadContactNumber": "7036478282",
//             "NewLeadName": "Nagesh",
//             "NewLeadPincode": "503212",
//             "NewLeadState": "Telanagana",
//             "OutstandingLoanAmount": 500000,
//             "OverallExperience": 1,
//             "PANNumber": "CVCVCVCVCV1",
//             "Pincode": "503212",
//             "Priority": "Hot",
//             "ProspectName": "Nagesh",
//             "ProspectNumber": "7036478282",
//             "RemarksPersonalOrBusinessLoan": "Not Applicable",
//             "RemarksVehicleOrEquipmentLoan": "Forward To Relavant Team ",
//             "RequiredLoanTenure": 180,
//             "SalaryIncomeMonthly": 2000,
//             "State": "TELANGANA",
//             "Status": "Prospecting",
//             "TilteOfPropertyDetails": "Nagesh",
//             "TitleOfProperty": "Society",
//             "TopUpAmount": 500000,
//             "TypeOfBusiness": "Trader",
//             "TypeOfBusinessDetails": "Tea Stall",
//             "TypeOfLoanOrProduct": "New home Loan",
//             "TypeOfLoanOrProductDetails": "Loan",
//             "TypeOfSalaried": "Private",
//             "Vintage": 2
//         }
