import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import defaultImage from "../images/defaultImage.png";
import location_image from "../images/location_image.png";
import { apiCall } from "../rest/restApi";
// import {  useHistory } from "react-router-dom";
import swal from "sweetalert";
import Select from "react-select";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "../CommonComponents/TextField";
import { SRLWrapper } from "simple-react-lightbox";
import * as moduleApi from "../rest/moduleApi";
import VisitLocationMap from "./VisitLocationMap";
import { EpochToDate, EpochToOnlyDate, secondsToHms } from "./Helper";
import { getTokenInLocalStorage } from "../services/AuthService";
import {getFileKeyName } from "../FieldInvManagement/utils";
import * as nodeApi from "../rest/nodeApi";

const VisitedPlaceDrawer = ({
  visitedPlacesDetailsList,
  closeDrawer,
  reLoad,
}) => {
  const [enable, setEnable] = useState(false);
  const [isMap, setIsMap] = useState(false);
  const [random] = useState(Math.floor(Math.random() * 100));
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [loanModal, setLoanModal] = useState(false);
  const [applicationNoModal, setApplicationNoModal] = useState(false);
  const [vehicleReleaseModal, setVehicleReleaseModal] = useState(false);
  const [amountPaidModal, setAmountPaidModal] = useState(false);
  const [playVideoModal, setPlayVideoModal] = useState(false);
  const [fiVideoUrl, setFiVideoUrl] = useState(false);
  const [selectedReleaseValue, setSelectedReleaseValue] = useState("EMI PAID");
  const [enteredAmount, setEnteredAmount] = useState(null);
  const [fiImageUrl, setFiImageUrl] = useState("");
  // var history = useHistory();

  useEffect(() => {
    setEnable(true);
  }, []);

  useEffect(() => {

    if(visitedPlacesDetailsList?.fieldInvestigationVisit){
      downloadFiImage();
    }
// eslint-disable-next-line
  },[]);

  let userLoginDetails = getTokenInLocalStorage();

  const validate = Yup.object({
    loanNo: Yup.string().required("Loan No is required"),
  });

  const validateApplicationNo = Yup.object({
    applicationNumber: Yup.string().required("Application No is required"),
  });

  // const amountPaidRegex= "^[1-9]\d*$";
  // eslint-disable-next-line
  const amountPaidRegex = /^\d+(\.\d{1,2})?$/;
  const amountPaidValidate = Yup.object({
    amountPaid: Yup.string()
      .matches(amountPaidRegex, "Please Enter Valid Amount")
      .required("Amount Paid is required"),
  });
  const handleOk = (values) => {
    var payLoad = {
      collectionId: visitedPlacesDetailsList.collectionVisit?.id,
      loanNumber: values.loanNo,
      visitId: visitedPlacesDetailsList.id,
    };
    moduleApi.postData(apiCall.updateLoanNo, payLoad).then((response) => {
      if (response) {
        if (response?.Message === "Loan number updated SucessFully") {
          swal("Loan No Updated Successfully", {
            icon: "success",
          });
          setLoanModal(false);
          closeDrawer(false);
          reLoad && reLoad();
        } else if (response?.message) {
          // toaster("error", response?.message);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const handleApplicatioNo = (values) => {
    var payLoad = {
      applicationNumber: values.applicationNumber,
      visitId: visitedPlacesDetailsList.id,
    };
    moduleApi
      .postData(apiCall.editApplicationNumber, payLoad)
      .then((response) => {
        if (response) {
          if (response?.Message === "Application Number Updated Successfully") {
            swal("Application Number Updated Successfully", {
              icon: "success",
            });
            setApplicationNoModal(false);
            closeDrawer(false);
            reLoad && reLoad();
          } else if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const updateAmountPaid = (values) => {
    console.log("amiunt", values.amountPaid > 100000);

    var payLoad = {
      collectionId: visitedPlacesDetailsList.collectionVisit?.id,
      amountPaidNow: values.amountPaid,
      loanNumber: visitedPlacesDetailsList.collectionVisit?.loanNumber,
      visitId: visitedPlacesDetailsList.id,
    };
    if (values.amountPaid > 100000) {
      return swal({
        title: "Amount",
        text: "Amount is greater than 1 lakh. Do you really want to continue?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          moduleApi
            .postData(apiCall.updateAmountPaid, payLoad)
            .then((response) => {
              if (response) {
                if (response?.Message === "Loan number updated SucessFully") {
                  swal("Amount Paid Updated Successfully", {
                    icon: "success",
                  });
                  setAmountPaidModal(false);
                  closeDrawer(false);
                  reLoad && reLoad();
                } else if (response?.message) {
                  swal(response?.message, {
                    icon: "error",
                    dangerMode: true,
                  });
                }
              } else {
                return swal("Something Went Wrong, Please Try Later", {
                  icon: "error",
                  dangerMode: true,
                });
              }
            });
        }
      });
    }
    moduleApi.postData(apiCall.updateAmountPaid, payLoad).then((response) => {
      if (response) {
        if (response?.Message === "Loan number updated SucessFully") {
          swal("Amount Paid Updated Successfully", {
            icon: "success",
          });
          setAmountPaidModal(false);
          closeDrawer(false);
          reLoad && reLoad();
        } else if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };
  const inventoryOptions = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: true,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const fiInventoryOptions = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const downloadFiImage = () => {

  if (visitedPlacesDetailsList?.fieldInvestigationVisit?.fiImage) {
    let requestPayload = {
      fileKeyName: getFileKeyName(visitedPlacesDetailsList?.fieldInvestigationVisit?.fiImage),
    };
    nodeApi
      .postData(apiCall.downloadFromS3, requestPayload)
      .then((response) => {
        setFiImageUrl(response.url);
      });
  }
};

  const releaseOptions = [
    { label: "EMI Paid", value: "EMI PAID" },
    { label: "Continuation", value: "CONTINUATION" },
    { label: "Auction/Direct Sold", value: "AUCTION/DIRECT SOLD" },
  ];

  const updateVehicleRelease = () => {
    if (enteredAmount === null || enteredAmount === "") {
      return swal("Please Enter Amount", {
        icon: "error",
        dangerMode: true,
      });
    }
    var payload = {
      vehicleReleasedByUserId: userLoginDetails.autoFinanceUser.userId,
      amountPaidToReleaseVehicle: enteredAmount,
      vehicleReleasedReason: selectedReleaseValue,
      visitId: visitedPlacesDetailsList.id,
    };
    moduleApi
      .postData(apiCall.updateVehicleRelease, payload)
      .then((response) => {
        if (response) {
          if (response?.Message === "Vehicle Released Successfully") {
            swal("Vehicle Released Successfully", {
              icon: "success",
            });
            setVehicleReleaseModal(false);
            closeDrawer(false);
            reLoad && reLoad();
          } else if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  return (
    <div className={`chatbox ${enable ? "active" : ""}`}>
      <div className="chatbox-close" onClick={() => closeDrawer(false)}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        }}
      >
        <h6
          style={{ color: "rgb(136,108,192)", marginLeft: "30px" }}
          className="fs-20 font-w600 my-1"
        >
          Visited Place Details
        </h6>
        <Button
          variant=""
          className="btn-close"
          style={{ marginRight: "40px" }}
          onClick={() => closeDrawer(false)}
        ></Button>
      </div>

      <div
        style={{ overflowY: "scroll", height: "100vh", overflowX: "hidden" }}
      >
        <Row>
          <Col>
            <div
              className="card contact-bx item-content"
              style={{
                backgroundColor: "rgb(243,240,249)",
                marginRight: "30px",
                marginLeft: "30px",
                marginBottom: "15px",
                marginTop: "10px",
              }}
            >
              <Row>
                <h6 className="fs-18 font-w600 my-1">Visit Details</h6>
                <div>
                  <Col
                    style={{
                      //  marginTop: "10px",
                      height: "140px",

                      //marginLeft: "20px",
                    }}
                  >
                    <SRLWrapper
                      options={visitedPlacesDetailsList?.fieldInvestigationVisit?.fiImage ? fiInventoryOptions:inventoryOptions}
                      style={{
                        height: "140px",
                        width: "140px",
                      }}
                    >
                      <a
                        href={
                          visitedPlacesDetailsList.image
                            ? visitedPlacesDetailsList.image +
                              "?originalImage=true"
                            :fiImageUrl!=="" ? fiImageUrl:defaultImage
                        }
                      >
                        <img
                          //src={defaultImage}
                          style={{
                            //  marginTop: "10px",
                            height: "140px",
                            width: "140px",
                            cursor: "pointer",
                            //marginLeft: "20px",
                          }}
                          //src={clulogo}
                          src={
                            visitedPlacesDetailsList.image
                              ? visitedPlacesDetailsList.image
                              :fiImageUrl!=="" ? fiImageUrl:defaultImage
                          }
                          alt=""
                          className="img-fluid rounded-circle"
                        />
                      </a>
                    </SRLWrapper>
                  </Col>
                  <Col>
                    <div className="card-body user-profile">
                      <div>
                        {visitedPlacesDetailsList.collectionVisit && (
                          <>
                            <tr></tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Name
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.collectionVisit
                                  .customerName
                                  ? visitedPlacesDetailsList.collectionVisit
                                      .customerName
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Contact Number
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.collectionVisit
                                  .contactNumber
                                  ? visitedPlacesDetailsList.collectionVisit
                                      .contactNumber
                                  : "-"}
                              </td>
                            </tr>
                            {visitedPlacesDetailsList?.collectionVisit
                              ?.alternateMobileNumber && (
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  New Mobile Number
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {visitedPlacesDetailsList?.collectionVisit
                                    ?.alternateMobileNumber
                                    ? visitedPlacesDetailsList?.collectionVisit
                                        ?.alternateMobileNumber
                                    : "-"}{" "}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Loan No
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.collectionVisit
                                  .loanNumber
                                  ? visitedPlacesDetailsList.collectionVisit
                                      .loanNumber
                                  : "-"}
                                {visitedPlacesDetailsList?.collectionVisit?.approvalStatus ==="PENDING" &&(
                                <span
                                  style={{
                                    marginLeft: "30px",
                                  }}
                                  onClick={() => {
                                    setLoanModal(true);
                                  }}
                                >
                                  <i
                                    className="fa fa-edit text-primary me-2"
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Status
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.collectionVisit.status
                                  ? visitedPlacesDetailsList.collectionVisit
                                      .status
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Sub Status
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.collectionVisit
                                  .subStatus
                                  ? visitedPlacesDetailsList.collectionVisit
                                      .subStatus
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Vehicle No
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList?.vehicleNumber
                                  ? visitedPlacesDetailsList?.vehicleNumber
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                AG Date
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList?.aggDate
                                  ? EpochToOnlyDate(
                                      visitedPlacesDetailsList?.aggDate
                                    )
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Due Date
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList?.dueDate
                                  ? EpochToOnlyDate(
                                      visitedPlacesDetailsList?.dueDate
                                    )
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Visit Date
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.dateTime
                                  ? visitedPlacesDetailsList.dateTime
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Time Difference B/W Prev Visit
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  verticalAlign: "middle",
                                }}
                              >
                                {visitedPlacesDetailsList.timeDiffOfPrevVisit
                                  ? secondsToHms(
                                      visitedPlacesDetailsList.timeDiffOfPrevVisit
                                    )
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Is Visit Done at Customer Address
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  overflowWrap: "anywhere",
                                }}
                              >
                                {visitedPlacesDetailsList.customerLatitude !== 0 && visitedPlacesDetailsList.customerLongitude !== 0?visitedPlacesDetailsList.visitNotDoneAtCustLocation ===
                                false
                                  ? "Yes"
                                  : "No":"NA"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Remarks
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  overflowWrap: "anywhere",
                                }}
                              >
                                {visitedPlacesDetailsList.comments
                                  ? visitedPlacesDetailsList.comments
                                  : "-"}
                              </td>
                            </tr>
                          </>
                        )}

                        {visitedPlacesDetailsList?.salesVisit && (
                          <>
                            <tr></tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Name
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.salesVisit
                                  .customerName
                                  ? visitedPlacesDetailsList.salesVisit
                                      .customerName
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Contact Number
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.salesVisit
                                  .contactNumber
                                  ? visitedPlacesDetailsList.salesVisit
                                      .contactNumber
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Product Name
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.salesVisit.productname
                                  ? visitedPlacesDetailsList.salesVisit
                                      .productname
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Visit Date
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.dateTime
                                  ? visitedPlacesDetailsList.dateTime
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Time Difference B/W Prev Visit
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  verticalAlign: "middle",
                                }}
                              >
                                {visitedPlacesDetailsList.timeDiffOfPrevVisit
                                  ? secondsToHms(
                                      visitedPlacesDetailsList.timeDiffOfPrevVisit
                                    )
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Remarks
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  overflowWrap: "anywhere",
                                }}
                              >
                                {visitedPlacesDetailsList.comments
                                  ? visitedPlacesDetailsList.comments
                                  : "-"}
                              </td>
                            </tr>
                          </>
                        )}

                        {visitedPlacesDetailsList?.fieldInvestigationVisit && (
                          <>
                            <tr></tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Name
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit.customerName
                                  ? visitedPlacesDetailsList
                                      .fieldInvestigationVisit.customerName
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Contact Number
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit.contactNumber
                                  ? visitedPlacesDetailsList
                                      .fieldInvestigationVisit.contactNumber
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Application No
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit?.applicationNo
                                  ? visitedPlacesDetailsList
                                      .fieldInvestigationVisit?.applicationNo
                                  : "-"}
                                <span
                                  style={{
                                    marginLeft: "30px",
                                  }}
                                  onClick={() => {
                                    setApplicationNoModal(true);
                                  }}
                                >
                                  <i
                                    className="fa fa-edit text-primary me-2"
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Status
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit.status
                                  ? visitedPlacesDetailsList
                                      .fieldInvestigationVisit.status
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Rejection Type
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit.rejectionType
                                  ? visitedPlacesDetailsList
                                      .fieldInvestigationVisit.rejectionType
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Loan Type
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit.loanType
                                  ? visitedPlacesDetailsList
                                      .fieldInvestigationVisit.loanType
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Loan Date
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit.loanDate
                                  ? EpochToOnlyDate(
                                      visitedPlacesDetailsList
                                        .fieldInvestigationVisit.loanDate
                                    )
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Visit Date
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList.dateTime
                                  ? visitedPlacesDetailsList.dateTime
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Time Difference B/W Prev Visit
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  verticalAlign: "middle",
                                }}
                              >
                                {visitedPlacesDetailsList.timeDiffOfPrevVisit
                                  ? secondsToHms(
                                      visitedPlacesDetailsList.timeDiffOfPrevVisit
                                    )
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Remarks
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  overflowWrap: "anywhere",
                                }}
                              >
                                {visitedPlacesDetailsList.comments
                                  ? visitedPlacesDetailsList.comments
                                  : "-"}
                              </td>
                            </tr>
                            {/* <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Positive Comments
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit.positiveComments
                                  ? visitedPlacesDetailsList
                                      .fieldInvestigationVisit.positiveComments
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "500", textAlign: "left" }}
                              >
                                Negative Comments
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {visitedPlacesDetailsList
                                  .fieldInvestigationVisit.negativeComments
                                  ? visitedPlacesDetailsList
                                      .fieldInvestigationVisit.negativeComments
                                  : "-"}
                              </td>
                            </tr> */}
                          </>
                        )}

                        {/* <tr>
                          <td style={{ fontWeight: "500", textAlign: "left" }}>
                            Dist B/W Cust addr & Visited Location
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              verticalAlign: "middle",
                            }}
                          >
                            {visitedPlacesDetailsList.distFromCustAddr
                              ? visitedPlacesDetailsList.distFromCustAddr
                              : "-"}
                          </td>
                        </tr> */}
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
        {visitedPlacesDetailsList?.collectionVisit?.status === "Paid" && (
          <Row>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  marginRight: "30px",
                  marginLeft: "30px",
                  marginBottom: "20px",
                  marginTop: "0px",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  <h6 className="fs-18 font-w600 my-1">Payment Details</h6>
                </div>
                <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "start",
                      }}
                    >
                      Payment Mode
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {visitedPlacesDetailsList.collectionVisit.paymentMode
                        ? visitedPlacesDetailsList.collectionVisit.paymentMode
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "start",
                      }}
                    >
                      Payment Towards
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {visitedPlacesDetailsList.collectionVisit.paymentTowards
                        ? visitedPlacesDetailsList.collectionVisit
                            .paymentTowards
                        : "-"}
                    </td>
                  </tr>
                  {visitedPlacesDetailsList?.collectionVisit?.subStatus ===
                    "Online Payment" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "start",
                        }}
                      >
                        Payment Date
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {visitedPlacesDetailsList?.collectionVisit?.paymentDate
                          ? EpochToDate(
                              visitedPlacesDetailsList?.collectionVisit
                                ?.paymentDate
                            )
                          : "-"}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.subStatus ===
                    "Online Payment" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "start",
                        }}
                      >
                        TransactionId
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit.transactionId
                          ? visitedPlacesDetailsList.collectionVisit
                              .transactionId
                          : "-"}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Total Amount Paid
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.collectionVisit.amountPaid
                        ? visitedPlacesDetailsList.collectionVisit.amountPaid
                        : "-"}
                      {
                        // visitedPlacesDetailsList.collectionVisit
                        //   .status !== "Customer Not Available" &&
                        //   visitedPlacesDetailsList.collectionVisit
                        //     .status !== "PTP" &&
                        visitedPlacesDetailsList.collectionVisit.status ===
                          "Paid" &&  visitedPlacesDetailsList?.collectionVisit?.approvalStatus ==="PENDING" && (
                          <span
                            style={{
                              marginLeft: "30px",
                            }}
                            onClick={() => {
                              setAmountPaidModal(true);
                            }}
                          >
                            <i
                              className="fa fa-edit text-primary me-2"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        )
                      }
                    </td>
                  </tr>
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "Paid" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Emi Paid
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .emiAmountPaidNow
                          ? visitedPlacesDetailsList.collectionVisit
                              .emiAmountPaidNow
                          : 0}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "Paid" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Late Payment Charges Paid
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .latePaymentChargesPaidNow
                          ? visitedPlacesDetailsList.collectionVisit
                              .latePaymentChargesPaidNow
                          : 0}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "Paid" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Bounce Charges Paid
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .bounceChargesPaidNow
                          ? visitedPlacesDetailsList.collectionVisit
                              .bounceChargesPaidNow
                          : 0}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "Paid" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Penalty Charges Paid
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .penaltyChargesPaidNow
                          ? visitedPlacesDetailsList.collectionVisit
                              .penaltyChargesPaidNow
                          : 0}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "Paid" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Other Charges Paid
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .otherChargesPaidNow
                          ? visitedPlacesDetailsList.collectionVisit
                              .otherChargesPaidNow
                          : 0}
                      </td>
                    </tr>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
        {visitedPlacesDetailsList?.collectionVisit?.status === "PTP" && (
          <Row>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  marginRight: "30px",
                  marginLeft: "30px",
                  marginBottom: "20px",
                  marginTop: "0px",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  <h6 className="fs-18 font-w600 my-1">PTP Details</h6>
                </div>
                <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "PTP" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",

                          textAlign: "start",
                        }}
                      >
                        PTP Amount
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit?.ptpAmount
                          ? visitedPlacesDetailsList.collectionVisit?.ptpAmount
                          : "-"}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "PTP" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",

                          textAlign: "start",
                        }}
                      >
                        Promised Payment Date
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit.toBePaidBy
                          ? EpochToOnlyDate(
                              visitedPlacesDetailsList.collectionVisit
                                .toBePaidBy
                            )
                          : "-"}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "PTP" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",

                          textAlign: "start",
                        }}
                      >
                        Promised Payment Slot
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit?.ptpSlot
                          ? visitedPlacesDetailsList.collectionVisit?.ptpSlot
                          : "-"}
                      </td>
                    </tr>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
        {visitedPlacesDetailsList?.collectionVisit?.status ===
          "Third Party" && (
          <Row>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  marginRight: "30px",
                  marginLeft: "30px",
                  marginBottom: "20px",
                  marginTop: "0px",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  <h6 className="fs-18 font-w600 my-1">Third Party Details</h6>
                </div>
                <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "Third Party" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",

                          textAlign: "start",
                        }}
                      >
                        Third Party Name
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit.thirdPartyName
                          ? visitedPlacesDetailsList.collectionVisit
                              .thirdPartyName
                          : "-"}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "Third Party" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",

                          textAlign: "start",
                        }}
                      >
                        Third Party Contact Number
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .thirdPartyPhoneNumber
                          ? visitedPlacesDetailsList.collectionVisit
                              .thirdPartyPhoneNumber
                          : "-"}
                      </td>
                    </tr>
                  )}
                  {visitedPlacesDetailsList?.collectionVisit?.status ===
                    "Third Party" && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",

                          textAlign: "start",
                        }}
                      >
                        Is Third Party Mobile Number Verified
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          ?.thirdPartyNumberValidated === true
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}

        {visitedPlacesDetailsList?.visitType === "Collections" &&
          visitedPlacesDetailsList?.collectionVisit?.collectionVisitImages
            .length !== 0 && (
            <div className="card-body pt-3">
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Collection Visit Images
              </span>
              <div className="profile-interest " style={{ margin: "10px" }}>
                <SRLWrapper options={options}>
                  <div className="row sp4">
                    {visitedPlacesDetailsList.collectionVisit?.collectionVisitImages.map(
                      (item) => (
                        <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                          <a
                            href={
                              item ? item + "?originalImage=true" : defaultImage
                            }
                          >
                            <img
                              style={{
                                cursor: "pointer",
                              }}
                              src={item ? item : defaultImage}
                              alt="profileImage"
                              className="img-fluid rounded"
                            />
                          </a>
                        </div>
                      )
                    )}
                  </div>
                </SRLWrapper>
              </div>
            </div>
          )}

        {visitedPlacesDetailsList?.collectionVisit?.status ===
          "Repossessed" && (
          <Row>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  marginRight: "30px",
                  marginLeft: "30px",
                  marginBottom: "30px",
                  marginTop: "0px",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "5px",
                    marginLeft: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:
                      visitedPlacesDetailsList.collectionVisit
                        .repossessedVehicleReleased === false
                        ? "space-between"
                        : "center",
                  }}
                >
                  <h6 className="fs-18 font-w600 my-1">Repossesed Details</h6>
                  {visitedPlacesDetailsList.collectionVisit
                    .repossessedVehicleReleased === false && (
                    <Button
                      style={{
                        marginLeft: "15px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      variant="primary btn-rounded"
                      onClick={() => {
                        setVehicleReleaseModal(true);
                      }}
                    >
                      Release Vehicle
                    </Button>
                  )}
                </div>

                <>
                  <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        Yard Name
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .repossedLocation
                          ? visitedPlacesDetailsList.collectionVisit
                              .repossedLocation
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        Is Vehicle Released
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .repossessedVehicleReleased === true
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        Vehicle Released Date & Time
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          ?.vehicleReleasedTime
                          ? EpochToDate(
                              visitedPlacesDetailsList.collectionVisit
                                ?.vehicleReleasedTime
                            )
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        Amount Paid For Vehicle Release
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .amountPaidToReleaseVehicle
                          ? visitedPlacesDetailsList.collectionVisit
                              .amountPaidToReleaseVehicle
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        Reason For Vehicle Release
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {visitedPlacesDetailsList.collectionVisit
                          .vehicleReleasedReason
                          ? visitedPlacesDetailsList.collectionVisit
                              .vehicleReleasedReason
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        Days B/W Repossessed and Released
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {
                          visitedPlacesDetailsList.collectionVisit
                            ?.daysBetweenRepossessedAndReleased
                        }
                      </td>
                    </tr>
                  </div>
                </>

                <div>
                  <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Inventory Scanned Image
                  </span>
                  <SRLWrapper
                    options={inventoryOptions}
                    style={{
                      height: "140px",
                      width: "140px",
                    }}
                  >
                    <a
                      href={
                        visitedPlacesDetailsList.collectionVisit?.invenotryScan
                          ? visitedPlacesDetailsList.collectionVisit
                              ?.invenotryScan + "?originalImage=true"
                          : defaultImage
                      }
                    >
                      <img
                        style={{
                          height: "140px",
                          width: "140px",
                          cursor: "pointer",
                        }}
                        src={
                          visitedPlacesDetailsList.collectionVisit
                            ?.invenotryScan
                            ? visitedPlacesDetailsList.collectionVisit
                                ?.invenotryScan
                            : defaultImage
                        }
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </a>
                  </SRLWrapper>
                </div>

                <div className="card-body pt-3">
                  <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Repossesed Images
                  </span>
                  <div className="profile-interest " style={{ margin: "10px" }}>
                    <SRLWrapper options={options}>
                      <div className="row sp4">
                        {visitedPlacesDetailsList.collectionVisit?.repossedImages.map(
                          (item) => (
                            <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                              <a
                                href={
                                  item
                                    ? item + "?originalImage=true"
                                    : defaultImage
                                }
                              >
                                <img
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  src={item ? item : defaultImage}
                                  alt="profileImage"
                                  className="img-fluid rounded"
                                />
                              </a>
                            </div>
                          )
                        )}
                      </div>
                    </SRLWrapper>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div
              className="card contact-bx item-content"
              style={{
                backgroundColor: "rgb(243,240,249)",
                marginRight: "30px",
                marginLeft: "30px",
                marginBottom: "20px",
                marginTop: "0px",
              }}
            >
              <div
                style={{
                  marginRight: "20px",
                  marginBottom: "10px",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              >
                <h6 className="fs-18 font-w600 my-1">Address Info</h6>
              </div>
              <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
              
                <tr>
                  <td
                    style={{
                      fontWeight: "500",
                      textAlign: "start",
                    }}
                  >
                    Visited Address Type
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    {visitedPlacesDetailsList.collectionVisit?.addressType
                      ? visitedPlacesDetailsList.collectionVisit?.addressType
                      :visitedPlacesDetailsList?.fiAllocation?.fiType ? visitedPlacesDetailsList?.fiAllocation?.fiType:"-"}
                  </td>
                </tr>
              
                {visitedPlacesDetailsList?.visitType === "Collections" && (
                  <tr>
                    <td style={{ fontWeight: "500", textAlign: "left", verticalAlign: "middle", }}>
                      Customer Latitude & Longitude
                    </td>
                    <td style={{ verticalAlign: "middle" }}>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {visitedPlacesDetailsList.customerLatitude !== 0 &&
                        visitedPlacesDetailsList.customerLongitude !== 0
                        ? visitedPlacesDetailsList.customerLatitude +
                        ", " +
                        visitedPlacesDetailsList.customerLongitude
                        : "-"}
                      {visitedPlacesDetailsList.customerLatitude !== 0 &&
                        visitedPlacesDetailsList.customerLongitude !== 0 && (
                          <span
                            style={{
                              color: "green",
                              margin: "0px 15px",
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() => {
                              setLatLng({
                                lat: visitedPlacesDetailsList.customerLatitude,
                                lng: visitedPlacesDetailsList.customerLongitude,
                              });
                              setIsMap(true);
                            }}
                          >
                            <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
                          </span>
                        )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ fontWeight: "500", textAlign: "left",verticalAlign: "middle", }}>
                    Visit Latitude & Longitude
                  </td>
                  <td style={{verticalAlign: "middle",}}>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    {visitedPlacesDetailsList.latitude !== 0 &&
                    visitedPlacesDetailsList.longitude !== 0
                      ? visitedPlacesDetailsList.latitude +
                        ", " +
                        visitedPlacesDetailsList.longitude
                      : "-"}
                       {visitedPlacesDetailsList.latitude !== 0 &&
                    visitedPlacesDetailsList.longitude !== 0 && (
                        <span
                          style={{
                            color: "green",
                            margin: "0px 15px",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            setLatLng({
                              lat: visitedPlacesDetailsList.latitude,
                              lng: visitedPlacesDetailsList.longitude,
                            });
                            setIsMap(true);
                          }}
                        >
                          <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
                        </span>
                      )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "500",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    Visit Address
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    <Row>
                      <Col>
                        {visitedPlacesDetailsList.address
                          ? visitedPlacesDetailsList.address
                          : "-"}{" "}
                      </Col>
                      <img
                        style={{
                          height: "40px",
                          width: "70px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setLatLng({
                            lat: visitedPlacesDetailsList.latitude,
                            lng: visitedPlacesDetailsList.longitude,
                          });
                          setIsMap(true);
                        }}
                        src={location_image}
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </Row>
                  </td>
                </tr>
                {visitedPlacesDetailsList?.fieldInvestigationVisit !== null && (
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      FI Address
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      <Row>
                        <Col>
                          {visitedPlacesDetailsList?.fiAllocation?.addressLine1 ? visitedPlacesDetailsList?.fiAllocation?.addressLine1 : visitedPlacesDetailsList.fieldInvestigationVisit
                            ?.customerAddress
                            ? visitedPlacesDetailsList.fieldInvestigationVisit
                                ?.customerAddress
                            : "-"}{" "}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}
                {visitedPlacesDetailsList?.fieldInvestigationVisit !== null && (
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      Is Visit Done From Allocations
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      <Row>
                        <Col>
                          {visitedPlacesDetailsList?.fiAllocationId
                            ? "Yes"
                            : "No"}{" "}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ fontWeight: "500", textAlign: "left" }}>
                    Is Customer Address Changed
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    {visitedPlacesDetailsList.customerLocationChanged === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                {visitedPlacesDetailsList?.collectionVisit?.newAddress && (
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      New Address
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      <Row>
                        <Col>
                          {visitedPlacesDetailsList?.collectionVisit?.newAddress
                            ? visitedPlacesDetailsList?.collectionVisit
                                ?.newAddress
                            : "-"}{" "}
                        </Col>
                        {/* <img
                        style={{
                          height: "40px",
                          width: "70px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsMap(true)}
                        src={location_image}
                        alt=""
                        className="img-fluid rounded-circle"
                      /> */}
                      </Row>
                    </td>
                  </tr>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="card contact-bx item-content"
              style={{
                backgroundColor: "rgb(243,240,249)",
                marginRight: "30px",
                marginLeft: "30px",
                marginBottom: "20px",
                marginTop: "0px",
              }}
            >
              <div
                style={{
                  marginRight: "20px",
                  marginBottom: "10px",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              >
                <h6 className="fs-18 font-w600 my-1">Executive Details</h6>
              </div>

              <>
                <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
                  <tr></tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Emp Id
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.employeeId
                        ? visitedPlacesDetailsList.employeeId
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Emp Name
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.name
                        ? visitedPlacesDetailsList.name
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Contact Number
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.mobileNumber
                        ? visitedPlacesDetailsList.mobileNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Email
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.email
                        ? visitedPlacesDetailsList.email
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Designation
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.companyDesignation
                        ? visitedPlacesDetailsList.companyDesignation
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Role Name
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.roleName
                        ? visitedPlacesDetailsList.roleName
                        : "-"}
                    </td>
                  </tr>
                </div>
              </>
            </div>
          </Col>
        </Row>

        {visitedPlacesDetailsList?.visitType === "FieldInvestigation" &&
          visitedPlacesDetailsList?.fieldInvestigationVisit
            ?.imagesPresignedUrls !== null &&
          visitedPlacesDetailsList?.fieldInvestigationVisit?.imagesPresignedUrls
            ?.length > 0 && (
            <Row>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    marginRight: "30px",
                    marginLeft: "30px",
                    marginBottom: "30px",
                    marginTop: "0px",
                  }}
                >
                  <div className="card-body pt-3">
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Field Investigation Images
                    </span>
                    <div
                      className="profile-interest "
                      style={{ margin: "10px" }}
                    >
                      <SRLWrapper options={options}>
                        <div className="row sp4">
                          {visitedPlacesDetailsList.fieldInvestigationVisit?.imagesPresignedUrls?.map(
                            (item) => (
                              <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                <a href={item ? item : defaultImage}>
                                  <img
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    src={item ? item : defaultImage}
                                    alt="profileImage"
                                    className="img-fluid rounded"
                                  />
                                </a>
                              </div>
                            )
                          )}
                        </div>
                      </SRLWrapper>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        {visitedPlacesDetailsList?.visitType === "FieldInvestigation" &&
          visitedPlacesDetailsList?.fieldInvestigationVisit
            ?.videoPresignedUrls !== null &&
          visitedPlacesDetailsList?.fieldInvestigationVisit?.videoPresignedUrls
            ?.length > 0 && (
            <Row>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    marginRight: "30px",
                    marginLeft: "30px",
                    marginBottom: "30px",
                    marginTop: "0px",
                  }}
                >
                  <div className="card-body pt-3">
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Field Investigation Videos
                    </span>
                    <div
                      className="profile-interest "
                      style={{ margin: "10px" }}
                    >
                      <div className="row sp4">
                        {visitedPlacesDetailsList.fieldInvestigationVisit?.videoPresignedUrls?.map(
                          (item) => (
                            <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                              <span
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() => {
                                  setFiVideoUrl(item);
                                  setPlayVideoModal(true);
                                }}
                              >
                                <i
                                  class="fas fa-play-circle"
                                  style={{
                                    fontSize: "40px",
                                    color: "mediumorchid",
                                  }}
                                ></i>
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
      </div>
      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Visit Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setLatLng({
                lat: 0,
                lng: 0,
              });
              setIsMap(false)}}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap
            latitide={latLng.lat}
            longitude={latLng.lng}
            key={random}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={vehicleReleaseModal}>
        <Modal.Header>
          <Modal.Title>Release Repossessed Vehicle</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setVehicleReleaseModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontWeight: "bold" }}>Reason</span>
            <div
              style={{
                float: "right",
                width: "400px",
                marginRight: "5px",
                height: "38px",
                marginBottom: "15px",
                borderRadius: "3px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) => setSelectedReleaseValue(value.value)}
                defaultValue={releaseOptions[0]}
                options={releaseOptions}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ fontWeight: "bold" }}>Amount</label>
              <input
                style={{
                  width: "400px",
                  height: "35px",
                  borderRadius: "7px",
                  // borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                  paddingLeft: "10px",
                  // marginLeft: "10px",
                }}
                step="0.01" // Allow input in increments of 0.01 (for decimal values)
                min="0" // Minimum value allowed (0 to prevent negative amounts)
                max="9999999999"
                type="number"
                placeholder="Enter Amount"
                onChange={(e) => {
                  setEnteredAmount(e.target.value);
                  console.log("amount", enteredAmount);
                }}
              />
            </div>
            <div>
              <Button
                style={{ float: "right" }}
                className="col-lg-2 m-2"
                type="submit"
                onClick={() => updateVehicleRelease()}
              >
                Submit
              </Button>
              <Button
                style={{ float: "right" }}
                className="col-lg-2 m-2 float-right"
                variant="danger light"
                onClick={() => setVehicleReleaseModal(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={loanModal}>
        <Modal.Header>
          <Modal.Title>Update Loan No.</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setLoanModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                loanNo: visitedPlacesDetailsList.collectionVisit?.loanNumber,
              }}
              onSubmit={handleOk}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="Loan No"
                            name="loanNo"
                            type="text"
                          />
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => setLoanModal(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={applicationNoModal}>
        <Modal.Header>
          <Modal.Title>Update Application No.</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setApplicationNoModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                visitId: visitedPlacesDetailsList.id,
                applicationNumber:
                  visitedPlacesDetailsList.fieldInvestigationVisit
                    ?.applicationNo,
              }}
              onSubmit={handleApplicatioNo}
              validationSchema={validateApplicationNo}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="Application No"
                            name="applicationNumber"
                            type="text"
                          />
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => setApplicationNoModal(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={playVideoModal}>
        <Modal.Header>
          <Modal.Title>Field Investgation Video</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setPlayVideoModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <video controls width="100%" height="100%">
              <source src={fiVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={amountPaidModal}>
        <Modal.Header>
          <Modal.Title>Update Amount Paid</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAmountPaidModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                amountPaid:
                  visitedPlacesDetailsList.collectionVisit?.amountPaid,
              }}
              onSubmit={updateAmountPaid}
              validationSchema={amountPaidValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="Amount Paid"
                            name="amountPaid"
                            type="text"
                          />
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => setAmountPaidModal(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default VisitedPlaceDrawer;
