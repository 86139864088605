import React, { useEffect, useState } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { Link } from "react-router-dom";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { getTokenInLocalStorage } from "../services/AuthService";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import SearchBar from "../CommonComponents/SearchBar";

const RetriedTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [transactionList, setTransactionList] = useState([]);
  const [transList, setTransList] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTransactionList(0,startDate, endDate,searchKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transList]);

  const getTransactionList = (pageNo,start, end,searchKeyy) => {
    let listData = [];
    setIsLoading(true);
    var payload = {
      endDate: end,
      startDate: start,
      searchkey:searchKeyy,
      transactionType:"Retried Transactions",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.gettransactionshistorybasedontransactiontype, pageNo, 10),
        payload
      )
      .then((response) => {
        // console.log("transactionResponse", response);
        response?.content?.forEach((element) => {
          let data = {
            name: element.name ? element.name : "-",
            customerName: element.customerName ? element.customerName : "-",
            empId: element.empId ? element.empId : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            subscriptionid: element.subscriptionid
              ? element.subscriptionid
              : "-",
            utr: element.utr ? element.utr : "-",
            amountToBeDucted: element.amountToBeDucted
              ? getRupees(element.amountToBeDucted)
              : "-",
            mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
            maxAmount: element.maxAmount ? getRupees(element.maxAmount) : "-",
            transactionIntiatedTime: element.transactionIntiatedTime
              ? helper.EpochToDate(element.transactionIntiatedTime)
              : "-",
            amountDebitedTime: element.amountDebitedTime
              ? helper.EpochToDate(element.amountDebitedTime)
              : "-",
            frequency: element.frequency ? element.frequency : "-",
            recurringCount: element.recurringCount
              ? element.recurringCount
              : "-",
            state: element.state,
            dateOfDeduction: element.dateOfDeduction
              ? element.dateOfDeduction
              : "-",
            transactionAmount: element.transactionAmount
              ? getRupees(element.transactionAmount)
              : "-",
            trasactionid: element.trasactionid ? element.trasactionid : "-",
            canReintiateTransaction: element.canReintiateTransaction
              ? element.canReintiateTransaction
              : "-",
            notificationState: element.notificationState
              ? element.notificationState
              : "-",
            paymentState: element.paymentState ? element.paymentState : "-",
            receiptNumber: element.receiptNumber,
            paymentDescription: element.paymentDescription ? element.paymentDescription : "-",
            providerReferenceId: element.providerReferenceId,
            payResponseCode: element.payResponseCode
              ? element.payResponseCode
              : "-",
            reinitiatedBy:element.reinitiatedBy ? element.reinitiatedBy:"-",
            reinitiatedByName:element.reinitiatedByName ? element.reinitiatedByName:"-",
            reinitiatedByEmpId:element.reinitiatedByEmpId ? element.reinitiatedByEmpId:"-"
          };
          listData.push(data);
        });
        setTransactionList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const getRupees = (value) => {
    return value / 100;
  };

  const transactionColumns = [
    {
      text: "Customer Name",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",

      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan No",

      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Amount",
      dataField: "transactionAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Frequency",

      dataField: "frequency",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "UTR No",

      dataField: "utr",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Reinitiated</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "transactionIntiatedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "amountDebitedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Notification</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "notificationState",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.notificationState === "NOTIFIED") {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>Notified</span>
          );
        } else if (row.notificationState === "PENDING") {
          return (
            <span style={{ color: "orange", fontWeight: "bold" }}>Pending</span>
          );
        } else if (row.notificationState === "FAILED") {
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>Failed</span>
          );
        }
        if (row.notificationState) {
          return (
            <span style={{ color: "blue", fontWeight: "bold" }}>
              {row.notificationState}
            </span>
          );
        } else {
          return "-";
        }
      },
    },

    {
      text: (
        <div>
          <span>Pay Response</span>
          <br></br>
          <span>Code</span>
        </div>
      ),
      dataField: "payResponseCode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.payResponseCode === "SUCCESS") {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>Success</span>
          );
        } else if (row.payResponseCode === "PENDING") {
          return (
            <span style={{ color: "orange", fontWeight: "bold" }}>Pending</span>
          );
        } else if (row.payResponseCode === "FAILED") {
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>Failed</span>
          );
        }
        if (row.payResponseCode) {
          return (
            <span style={{ color: "blue", fontWeight: "bold" }}>
              {row.payResponseCode}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Payment Description",
      dataField: "paymentDescription",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "paymentState",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.paymentState === "COMPLETED") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else if (row.paymentState === "PENDING") {
          return (
            <span className="badge badge-rounded badge-warning">Pending</span>
          );
        } else if (row.paymentState === "FAILED") {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Reinitiated By Name",
      dataField: "reinitiatedByName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Reinitiated By EmpId",
      dataField: "reinitiatedByEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setTransactionDetails(row);
          setBasicModal(true);
        },
      },
    },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (_cell, row) => {
    //     if (
    //       row.paymentState === "FAILED" &&
    //       userLoginDetails.autoFinanceUser.reIntiatePhonePeTransaction ===
    //         true &&
    //       row.canReintiateTransaction === true
    //     ) {
    //       return (
    //         <div className="d-flex">
    //           <Link
    //             to="#"
    //             className="btn btn-primary shadow btn-xs sharp"
    //             onClick={() => {
    //               setTransactionDetails(row);
    //               setBasicModal2(true);
    //             }}
    //           >
    //             <i
    //               className="fas fa-redo-alt"
    //               onClick={() => {
    //                 setTransactionDetails(row);
    //                 setBasicModal2(true);
    //               }}
    //             />
    //           </Link>
    //         </div>
    //       );
    //     } else {
    //       return <></>;
    //     }
    //   },
      

      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     setTransactionDetails(row);
      //     setBasicModal2(true);
      //     // if (row.paymentState === "FAILED") {
      //     //   swal({
      //     //     title: "Re-Initiate",
      //     //     text: "Do you really want to Re-Initiate the Transaction?",
      //     //     icon: "warning",
      //     //     buttons: true,
      //     //     dangerMode: true,
      //     //   }).then((willDelete) => {
      //     //     if (willDelete) {
      //     //       reInitiateTransaction(
      //     //         // row?.transactionAmount,
      //     //         row?.subscriptionid
      //     //       );
      //     //     }
      //     //   });
      //     // }
      //   },
      // },
  //  },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.receiptNumber === null) {
    //       return <></>;
    //     } else {
    //       return (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-success shadow btn-xs sharp">
    //             <i className="fa fa-download"></i>
    //           </Link>
    //         </div>
    //       );
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       window.open(
    //         moduleApi.getBasePath() + apiCall.getPaymentReceiptForUpiMandate + row.trasactionid
    //       );
    //     },
    //   },
    // },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.receiptNumber === null) {
    //       return <></>;
    //     } else {
    //       return (
    //         <div >
    //           <Link to="#" className="btn btn-danger shadow btn-xs sharp">
    //             <i className="fas fa-external-link-alt"></i>
    //           </Link>
    //         </div>
    //       );
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       swal({
    //         title: "Resend",
    //         text: "Do you really want to Resend Payment Receipt?",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //       }).then((willDelete) => {
    //         if (willDelete) {
    //           resendPaymentReceipt(row?.trasactionid);
    //         }
    //       });
    //     },
    //   },
    // },
  ];

  // const resendPaymentReceipt = (transId) => {
  //   moduleApi
  //     .getData(apiCall.resendPaymentReceiptForUpiMandate + transId)
  //     .then((response) => {
  //       if(response){
  //         if(response.Message==="Receipt Sent Successfully"){
  //           return swal("Receipt Sent Successfully", {
  //             icon:'success',
  //             dangerMode:false,
  //           })
  //         }else if(response.message){
  //           return swal(response?.message, {
  //             icon:'error',
  //             dangerMode:true,
  //           })
  //         }
  //       }
        
  //     });
  // };

  const searchKeyword = (key) => {
    setSearchKey(key);
    getTransactionList(0,startDate,endDate,key);
  };

  const transDownloadData = (key) => {
    setIsLoading(true);
    var payload = {
      endDate: endDate,
      startDate: startDate,
      transactionType:"Retried Transactions",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchkey:searchKey,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.gettransactionshistorybasedontransactiontype, 0, 10),
        payload
      )
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getTransFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getTransFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getTransFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        endDate: endDate,
        startDate: startDate,
        transactionType:"Retried Transactions",
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        searchkey:searchKey,
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.gettransactionshistorybasedontransactiontype, i, 100),
          payload
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        name: element.name ? element.name : "-",
        customerName: element.customerName ? element.customerName : "-",
        empId: element.empId ? element.empId : "-",
        loanNumber: element.loanNumber ? element.loanNumber : "-",
        subscriptionid: element.subscriptionid ? element.subscriptionid : "-",
        utr: element.utr ? element.utr : "-",
        amountToBeDucted: element.amountToBeDucted
          ? getRupees(element.amountToBeDucted)
          : "-",
        amountDebitedTime: element.amountDebitedTime
          ? helper.EpochToDate(element.amountDebitedTime)
          : "-",
        transactionIntiatedTime: element.transactionIntiatedTime
          ? helper.EpochToDate(element.transactionIntiatedTime)
          : "-",
        mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
        frequency: element.frequency ? element.frequency : "-",
        recurringCount: element.recurringCount ? element.recurringCount : "-",
        state: element.state,
        dateOfDeduction: element.dateOfDeduction
          ? element.dateOfDeduction
          : "-",
        transactionAmount: element.transactionAmount
          ? getRupees(element.transactionAmount)
          : "-",
        trasactionid: element.trasactionid ? element.trasactionid : "-",
        notificationState: element.notificationState
          ? element.notificationState
          : "-",
        paymentState: element.paymentState ? element.paymentState : "-",
        paymentDescription: element.paymentDescription ? element.paymentDescription : "-",
        providerReferenceId: element.providerReferenceId,
        payResponseCode: element.payResponseCode
          ? element.payResponseCode
          : "-",
        maskedAccountNumber: element.maskedAccountNumber?element.maskedAccountNumber:"-",
        ifsc:element.ifsc ? element.ifsc:"-",
        reinitiatedBy:element.reinitiatedBy ? element.reinitiatedBy:"-",
        reinitiatedByName:element.reinitiatedByName ? element.reinitiatedByName:"-",
        reinitiatedByEmpId:element.reinitiatedByEmpId ? element.reinitiatedByEmpId:"-"
      };
      mgrListData.push(data);
    });
    setTransList(mgrListData);

    // console.log("mgrList1", mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {
        // A: "Employee Id",
        // B: "Employee Name",
        A: "Customer Name",
        B: "Mobile No",
        C: "Loan No",
        D: "Amount",
        E: "Frequency",
        F: "UTR No",
        G: "Date Of Deduction",
        H: "Amount To Be Deducted",
        I: "Rinitiated Date & Time",
        J: "Payment Date & Time",
        K: "Payment Description",
        L: "Payment Status",
        M: "Notification Status",
        N: "Pay Response Code",
        O: "Transaction Id",
        P:"Account No",
        Q:"IFSC Code",
        R:"Reinitiated By Name",
        S:"Reinitiated By EmpId"
      },
    ];

    transList?.forEach((row) => {
      mgrTable.push({
        // A: row.empId,
        // B: row.name,
        A: row.customerName,
        B: row.mobileNumber,
        C: row.loanNumber,
        D: row.transactionAmount,
        E: row.frequency,
        F: row.utr,
        G: row.dateOfDeduction,
        H: row.amountToBeDucted,
        I: row.transactionIntiatedTime,
        J: row.amountDebitedTime,
        K: row.paymentDescription,
        L: row.paymentState,
        M: row.notificationState,
        N: row.payResponseCode,
        O: row.trasactionid,
        P:row.maskedAccountNumber,
        Q:row.ifsc,
        R:row.reinitiatedByName,
        S:row.reinitiatedByEmpId
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Transactions");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      "Reinitiated_Transactions_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Reinitiated Transactions List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Reinitiated_Transactions_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStartDate(start);
    setEndDate(end);
    getTransactionList(0,start, end,searchKey);
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    getTransactionList(
      selectedPage - 1,
      startDate,
      endDate,
      searchKey
    );
  };

 // const amountPaidRegex = /^\d+(\.\d{1,2})?$/;
  // const amountPaidValidate = Yup.object({
  //   amount: Yup.string()
  //     .matches(amountPaidRegex, "Please Enter Valid Amount")
  //     .required("Amount To Be Deducted is required"),
  // });

  // const reInitiateTransaction = (values) => {
  //   // console.log("A" + values.amount + "MA" + transactionDetails?.maxAmount);
  //   if (parseInt(values.amount) > parseInt(transactionDetails?.maxAmount)) {
  //     return swal("Amount Should Be Less Than Max Amount", {
  //       icon: "error",
  //       dangerMode: true,
  //     });
  //   }
  //   var payload = {
  //     amount: values.amount,
  //     organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //     subscriptionId: transactionDetails?.subscriptionid,
  //     userId: userLoginDetails.autoFinanceUser.userId,
  //   };

  //   swal({
  //     title: "Re-Initiate",
  //     text: "Do you really want to Re-Initiate the Transaction?",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       moduleApi
  //         .postData(apiCall.reInitiateTransaction, payload)
  //         .then((response) => {
  //           //Transaction Intiated Sucessfully
  //           console.log("response", response);
  //           if (response) {
  //             if (response?.message) {
  //               return swal(response?.message, {
  //                 icon: "error",
  //                 dangerMode: true,
  //               });
  //             } else if (
  //               response?.Message === "Transaction Intiated Sucessfully"
  //             ) {
  //             }
  //             swal("Transaction Initiated Successfully!", {
  //               icon: "success",
  //             });
  //             getTransactionList(
  //               0,
  //               startDate,
  //               endDate,
  //             );
  //             setBasicModal2(false);
  //           } else {
  //             return swal("Something Went Wrong, Please Try Later", {
  //               icon: "error",
  //               dangerMode: true,
  //             });
  //           }
  //         });
  //     }
  //   });
  // };

  // const getTransactionDetails = (subscriptionId) => {
  //   moduleApi
  //     .getData(apiCall.getTransactions + subscriptionId)
  //     .then((response) => {
  //       if (response) {
  //         if (response?.message) {
  //           return swal(response?.message, {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         } else if (response) {
  //           setTransactionList(response);
  //         }
  //       } else {
  //         return swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  // };

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginBottom: "5px",
        }}
      >
        <div
        style={{
          marginTop: "25px",
        }}
      >
        <SearchBar
          placeholder="Search by Loan No/Customer Name/Mobile No"
          searchKeyword={searchKeyword}
        />
        </div>
        {/* 
        <div
          style={{
            float: "right",
            width: "130px",
            marginRight: "10px",
            height: "38px",
            // marginBottom: "20px",
            marginLeft: "15px",
            borderRadius: "3px",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #797979",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) => setStatusValue(value.value)}
            defaultValue={statusOptions[0]}
            options={statusOptions}
          />
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <label style={{ fontWeight: "bold", marginLeft: "12px" }}>
            Select Range
          </label>
        <div
          style={{
            float: "right",
            // width: "120px",
            marginRight: "5px",
            // marginBottom: "20px",
            marginLeft: "15px",
          }}
        >
          <Flatpickr
            className="form-control d-block"
            style={{
              width: "180px",
              height: "40px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              textAlignLast: "center",
              // paddingLeft: "10px",
              // marginBottom: "10px",
              // float: "right",
            }}
            placeholder="Select Month"
            onChange={(value) => {
              setCustomDatesForGraph(value);
            }}
            options={{
              // mode: "range",
              // dateFormat: "d-M-y",
              defaultDate: new Date(),
              maxDate: new Date(),
              minDate: new Date().setMonth(new Date().getMonth() - 6),

              plugins: [
                new monthSelectPlugin({
                  shorthand: true, //defaults to false
                  dateFormat: "F Y", //defaults to "F Y"
                  altFormat: "F Y", //defaults to "F Y"
                  theme: "light", // defaults to "light"
                }),
              ],
            }}
          />
        </div>
        </div>
        <Button
          style={{
            // margin: "5px 10px 10px  ",
            marginRight: "15px",
            marginLeft: "10px",
            marginTop: "20px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() => transDownloadData()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button>
      </div>
      <div style={{ margin: "10px" }}>
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "-5px",
              overflowX: "auto",
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="id"
              data={transactionList}
              columns={transactionColumns}
              onSelect={onPageChange}
              // selectRow={selectRow}
              //   expandRow={expandRow}
            />
          </div>
          {transactionList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </LoadingOverlay>
      </div>

      <Modal className="fade" show={basicModal} size="md">
        <Modal.Header>
          <Modal.Title>Transaction Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div>
            {/* {transactionList.length === 0 ? (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            ) : ( */}
            <Row>
              <Col>
                <div>
                  <br></br>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Emp Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.name
                        ? transactionDetails?.name
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Emp Id
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.empId
                        ? transactionDetails?.empId
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Customer Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.customerName
                        ? transactionDetails?.customerName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Mobile No
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.mobileNumber
                        ? transactionDetails?.mobileNumber
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Subscription Id
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.subscriptionid
                        ? transactionDetails?.subscriptionid
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Frequency
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.frequency
                        ? transactionDetails?.frequency
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Date Of Deduction
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.dateOfDeduction
                        ? transactionDetails?.dateOfDeduction
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Amount
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.transactionAmount
                        ? transactionDetails?.transactionAmount
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Amount To be Deducted
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {transactionDetails?.amountToBeDucted
                        ? transactionDetails?.amountToBeDucted
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Initiated Date & Time
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {transactionDetails?.transactionIntiatedTime
                        ? transactionDetails?.transactionIntiatedTime
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Payment Date & Time
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {transactionDetails?.amountDebitedTime
                        ? transactionDetails?.amountDebitedTime
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Payment Status
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.paymentState
                        ? transactionDetails?.paymentState
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Payment Description
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.paymentDescription
                        ? transactionDetails?.paymentDescription
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Pay Response Code
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.payResponseCode
                        ? transactionDetails?.payResponseCode
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Notification Status
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.notificationState
                        ? transactionDetails?.notificationState
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Transaction Id
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.trasactionid
                        ? transactionDetails?.trasactionid
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Reinitiated By Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.reinitiatedByName
                        ? transactionDetails?.reinitiatedByName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Reinitiated By EmpId
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.reinitiatedByEmpId
                        ? transactionDetails?.reinitiatedByEmpId
                        : "-"}
                    </td>
                  </tr>
                  {/* <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Recurring Count
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.recurringCount
                        ? transactionDetails?.recurringCount
                        : "-"}
                    </td>
                  </tr> */}
                </div>
              </Col>
            </Row>
            {/* )} */}
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal className="fade" show={basicModal2} size="md">
        <Modal.Header>
          <Modal.Title>Re-Initiate Transaction</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal2(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "5px" }}>
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  textAlign: "left",
                  // width: "30%",
                }}
              >
                Max Amount
              </td>
              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {transactionDetails?.maxAmount
                  ? transactionDetails?.maxAmount
                  : "-"}
              </td>
            </tr>
            <div style={{ marginTop: "10px" }}>
              <Formik
                initialValues={{
                  amount: "",
                }}
                onSubmit={reInitiateTransaction}
                validationSchema={amountPaidValidate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div>
                            <TextField
                              isAsterisk={true}
                              label="Amount To Be Deducted"
                              name="amount"
                              type="text"
                            />
                          </div>
                          <div>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2"
                              type="submit"
                            >
                              Submit
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2 float-right"
                              variant="danger light"
                              onClick={() => setBasicModal2(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default RetriedTransactions;
