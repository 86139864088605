import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getDatabase, ref, onValue, update, remove } from "firebase/database";
import { EpochToDate } from "../CommonComponents/Helper";
import NO_DATA_FOUND from "../images/no-data-found.png";
import swal from "sweetalert";


const TelecallerNotifications = ({ closeDrawer }) => {
  const [enable, setEnable] = useState(false);
  const [notifications, setNotifications] = useState([]);

  var userData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    setEnable(true);
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNotifications = () => {
    // let listData = [];
    const db = getDatabase();
    const starCountRef = ref(db, "web_notifications/");
    console.log("starCountRef ", starCountRef);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("dataFromDB", data);
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          data[key].id = key; // Adding 'id' key with the same value as the object's key
        }
      }
      const uniqueObjectsList = Object.values(data)
      console.log(uniqueObjectsList);
      let listData = [];
      uniqueObjectsList?.forEach((item) => {
        if (item.userId === userData?.autoFinanceUser?.userId) {
          listData.push(item);
        }
      });
      console.log("telecaler", listData);
      const sortedMessages = listData.sort((a, b) => b.date - a.date);
      setNotifications(sortedMessages);
    });
  };

  const deleteRecord = (recordKey) => {
    const db = getDatabase();
    // Remove the record
    remove(ref(db,"web_notifications/" + recordKey))
    swal("Deleted Successfully", {
      icon: "success",
    })
    loadNotifications()
  };

  const updateRecord = (recordKey) => {
    const db = getDatabase();    
    // Update the record with new data
    update(ref(db, "web_notifications/" + recordKey), {
      seen: true
    });
    loadNotifications()
  };


  return (
    <div
      className={`chatbox ${enable ? "active" : ""}`}
      style={{
        backgroundColor: "rgb(243,240,249)",
        padding: "10px",
      }}
    >
      <div className="chatbox-close" onClick={() => closeDrawer(false)}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        }}
      >
        <h5
          style={{ color: "rgb(136,108,192)", marginLeft: "30px" }}
          className="fs-20 font-w600 my-1"
        >
          Notifications
        </h5>
        <Button
          variant=""
          className="btn-close"
          style={{ marginRight: "40px" }}
          onClick={() => closeDrawer(false)}
        ></Button>
      </div>
      <PerfectScrollbar
        className="widget-media dlab-scroll p-3 "
        style={{ overflowY: "auto", maxHeight: "580px" }}
      >
        <ul className="timeline">
          {notifications?.map((item) => {
            return (
              <li>
                <div className="card" style={{ padding: "15px", marginBottom:'10px' }}>
                  <div className="timeline-panel" style={{borderBottom:'0px', marginBottom:'0px', paddingBottom:'0px'}}>
                    <div
                      className={
                        item?.messageType === "ptp"
                          ? "media me-2 media-success"
                          : "media me-2 media-info"
                      }
                      style={{
                        alignSelf: "center",
                        height: "50px",
                        width: "50px",
                        fontSize:'15px'
                      }}
                    >
                      {item?.messageType === "ptp"
                        ? "PTP"
                        : item?.messageType === "CallBack"
                        ? "Call Back"
                        : item?.messageType}
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1">{item?.message}</h6>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <small>{EpochToDate(item?.date)}</small>
                        {item.seen === false ? (
                          <Button variant="outline-primary btn-xs" onClick={()=>{
                            
                            updateRecord(item.id)
                          }}>
                            Mark as Read
                          </Button>
                        ) : (
                          <Button variant="outline-danger btn-xs" onClick={()=>{
                            swal({
                              title: "Delete Notification",
                              text: "Are you sure, you want to Delete Notification?",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((ok)=>{
                              if(ok){
                                deleteRecord(item?.id)
                              }
                            })
                          }}>
                            <i className="fas fa-trash" />
                            &nbsp;&nbsp; Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        
        
      </PerfectScrollbar>
      {notifications?.length === 0 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "100px" }}
        >
          <img src={NO_DATA_FOUND} alt="" />
        </div>
      )}
      {/* <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
        {notifications?.map((item) => {
          return (
            <div className="card">
              <div style={{display:'flex', flexDirection:'row'}}>
              <div className="timeline-panel">
              <div className="media me-2 media-info">
                              {item?.messageType === "ptp"
                                ? "PTP"
                                : item?.messageType === "CallBack"
                                ? "CB"
                                : item?.messageType}
                            </div>
                            </div>
                <div>
              <h6 className="mb-1">{item?.message}</h6>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ color: "black", alignSelf: "self-end" }}>
                  {EpochToDate(item?.date)}
                </span>
                <span
                  style={{
                    fontSize: "small",
                    width: "100px",
                    fontWeight: "bold",
                  }}
                >
                  Mark as Read&nbsp;
                </span>
              </div>
              </div>
            </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default TelecallerNotifications;
