import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from 'react-ga'
import VisitLocationMap from "../CommonComponents/VisitLocationMap";


const ManagerAttendance = ({ userId }) => {
  const [attendanceList, setAttendanceList] = useState([]);
  const [attendanceDetailsList, setAttendanceDetailsList] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage] = useState(0);
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [isLoading, setIsLoading] = useState(true);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [isMap, setIsMap] = useState(false);
  var attendanceDetailsListData = [];
  var valIndex = 0;
  //   let start = 0,
  //     end = 0;
  // var today = new Date();
  // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
  // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
  useEffect(() => {
    ReactGA.pageview('Manager Attendance');
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setDateValue("Last 7 Days", 1);
    loadAttendanceData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
    return hDisplay + mDisplay + sDisplay;
  };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const getDistTravelledInKM = (distanceTravlled) => {
    if (distanceTravlled !== 0) {
      var km = distanceTravlled / 1000.0;
      return km.toFixed(0) + " km";
    } else {
      return "-";
    }
  };

  const loadAttendanceData = (pageNo) => {
    setIsLoading(true);
    var listData = [];
    let attendanceBody = {
      enddate: end,
      startdate: start,
      userId: userId,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeeAttendance, pageNo, 10),
        attendanceBody
      )
      .then((response) => {
        response.content.forEach((element) => {
          let data = {
            actualWorkingHours: element.actualWorkingHours,
            amountCollected: element.amountCollected,
            collectionCount: element.collectionCount,
            date: element.date,
            distanceTravlled: getDistTravelledInKM(element.distanceTravlled),
            employeeAttendenceStatus: element.employeeAttendenceStatus,
            employeeCheckInAndCheckouts: element.employeeCheckInAndCheckouts,
            expense: element.expense,
            id: element.id,
            lastCheckInTime: element.lastCheckInTime,
            lastCheckOutTime: element.lastCheckOutTime,
            loginTime:
              element.loginTime === 0
                ? "--"
                : convertEpochTimeToDate(element.loginTime),
            checkInAddress:
              element.checkInAddress ,
            checkInLatitude: element.checkInLatitude,
            checkInLongitude: element.checkInLongitude,
            logoutTime:
              element.logoutTime === 0
                ? "--"
                : convertEpochTimeToDate(element.logoutTime),
            checkOutAddress:
              element.checkOutAddress ,
            checkOutLatitude: element.checkOutLatitude,
            checkOutLongitude: element.checkOutLongitude,
            phoneModel: getPhoneModel(
              element.phoneMake ? element.phoneMake : "-",
              element.phoneModel ? element.phoneModel : "-",
              element.phoneOs ? element.phoneOs : "-"
            ),
            organizationId: element.organizationId,
            salesCount: element.salesCount,
            timeDifference:
              element.timeDifference === 0
                ? "00:00:00 H:M:S"
                : secondsToHms(element.timeDifference),
            userId: element.userId,
            visitCount:element.visitCount ? element.visitCount :0,
          };

          listData.push(data);
        });
        setAttendanceList(listData);
        setIsLoading(false);
        setTotalElements(response.totalElements);
      });
  };

  const getPhoneModel = (a, b, c) => {
    if (a !== "-" && b !== "-" && c !== "-") {
      return a + " , " + b + " , " + c;
    } else {
      return "-";
    }
  };

  const columns = [
    {
      text: "Date",
      dataField: "date",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Login Time",
      dataField: "loginTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: <div><span>First Check In</span><br></br><span>Location</span></div>,
      dataField: "checkInAddress",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return row.checkInAddress === null ||  row.checkInAddress === ''? (
          "-"
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ cursor: "pointer" }}>{row.checkInAddress}</p>

            <span
              style={{
                color: "green",
                margin: "0px 15px",
                cursor: "pointer",
                alignSelf: "center",
              }}
              onClick={() => {
                setLatLng({
                  lat: row.checkInLatitude,
                  lng: row.checkInLongitude,
                });
                setIsMap(true);
              }}
            >
              <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
            </span>
          </div>
        );
      },
    },
    {
      text: "Logout Time",
      dataField: "logoutTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: <div><span>Last Check Out</span><br></br><span>Location</span></div>,
      dataField: "checkOutAddress",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return row.checkOutAddress === null ||  row.checkOutAddress === '' ? (
          "-"
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ cursor: "pointer" }}>{row.checkOutAddress}</p>

            <span
              style={{
                color: "green",
                margin: "0px 15px",
                cursor: "pointer",
                alignSelf: "center",
              }}
              onClick={() => {
                setLatLng({
                  lat: row.checkOutLatitude,
                  lng: row.checkOutLongitude,
                });
                setIsMap(true);
              }}
            >
              <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
            </span>
          </div>
        );
      },
    },
    {
      text: "Status",
      dataField: "employeeAttendenceStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Working Hours",
      dataField: "timeDifference",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Phone Model",
      dataField: "phoneModel",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Count",
      dataField: "visitCount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: <div><span>Approx. Dist</span><br></br><span>Travelled(in km)</span></div>,
      dataField: "distanceTravlled",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          attendanceDetailsListData = [];
          row.employeeCheckInAndCheckouts.forEach((data) => {
            let detailsData = {
              checkInTime:
                data.checkInTime === 0
                  ? "--"
                  : convertEpochTimeToDate(data.checkInTime),
              checkOutTime:
                data.checkOutTime === 0
                  ? "--"
                  : convertEpochTimeToDate(data.checkOutTime),
              date: row.date,
            };
            attendanceDetailsListData.push(detailsData);
          });
          setAttendanceDetailsList(attendanceDetailsListData);

          setBasicModal(true);

          //   <Link  to="/executive-details">
          //         <i className="fa fa-trash"></i>
          //       </Link>
        },
      },
    },
  ];

  const DetailsColumns = [
    {
      text: "Date",
      dataField: "date",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Check In Time",
      dataField: "checkInTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Check Out Time",
      dataField: "checkOutTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];
  // const options1 = {
  //   custom: true,
  //   totalSize: attendanceList.length,
  //   sizePerPage: 10,
  //   hideSizePerPage: true,
  //   hidePageListOnlyOnePage: true,
  // };

  const options2 = {
    custom: true,
    totalSize: attendanceDetailsList.length,
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };

  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const setDateValue = (label, value) => {
    var date;

    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));
        setShowDate(false);
        return;
      case 2:
        var today = new Date();
        setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
        setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));
        setShowDate(false);
        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        return;
      case 4:
        setShowDate(true);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  function setCustomDates(dateStrings) {
    setStart(Math.floor(new Date(dateStrings[0]).getTime() / 1000));
    setEnd(Math.floor(new Date(dateStrings[1]).getTime() / 1000) + 86400);
  }

  const onPageChange = (selectedPage) => {
    loadAttendanceData(selectedPage - 1);
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        {showDate && (
          <div style={{ marginRight: "20px", float: "right" }}>
            <Flatpickr
              className="form-control d-block"
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: "10px",
          }}
        >
          <label style={{ fontWeight: "bold", marginBottom: "5px" }}>Select Range</label>
        <div
          style={{
            float: "right",
            width: "150px",
            // marginBottom: "20px",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) =>
              setDateValue(value.label, value.value, optionDates)
            }
            defaultValue={optionDates[valIndex]}
            options={optionDates}
          />
        </div>
        </div>
        <div
          style={{
            marginRight: "15px",
            marginTop: "20px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadAttendanceData(0)}
          >
            Submit
          </Button>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px", overflowX:'auto' }}>
        <div style={{overflow:'auto', maxHeight:'400px'}}>
          <BootstrapTable
            keyField="panNo"
            data={attendanceList}
            columns={columns}
          />
          </div>
          {attendanceList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <div>
        <Modal
          className="fade"
          show={basicModal}
          size={attendanceDetailsList.length > 0 ? "lg" : "md"}
        >
          <Modal.Header>
            <Modal.Title>Attendance Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setBasicModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {attendanceDetailsList.length > 0 ? (
              <div style={{ background: "white", margin: "10px" }}>
                <PaginationProvider pagination={paginationFactory(options2)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <div>
                      <BootstrapTable
                        keyField="panNo"
                        data={attendanceDetailsList}
                        columns={DetailsColumns}
                        {...paginationTableProps}
                      />
                      <div style={{ float: "right" }}>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  )}
                </PaginationProvider>
              </div>
            ) : (
              <div style={{ fontWeight: "bold", marginleft: "260px" }}>
                <span>No Attendance Details Found</span>
              </div>
            )}
          </Modal.Body>
          {/* <Modal.Footer>
          <Button onClick={() => setBasicModal(false)} variant="danger light">
            Cancel
          </Button>
          <Button variant="primary" onClick={() => setBasicModal(false)}>
            Ok
          </Button>
        </Modal.Footer> */}
        </Modal>

        <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal>
      </div>
    </div>
  );
};

export default ManagerAttendance;
