import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import {Field, Form, Formik } from "formik";
import swal from "sweetalert";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import SearchBar from "../CommonComponents/SearchBar";
import TextField from "../CommonComponents/TextField";
import Select from "react-select";


function Branches() {
  // var history = useHistory();
  const [executivesList, setExecutivesList] = useState([]);
  const [addBracnchModal, setAddBranchModal] = useState(false);
  const [editBranchModal, setEditBranchModal] = useState(false);
  const [branchDetails, setBranchDetails] = useState(null);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMainBranch, setIsMainBranch] = useState(false);
  const [branches, setBranches] = useState([]);
  const [states, setStates] = useState([]);
  const [error, setError] = useState(null);
  const [defaultState, setDefaultState] = useState([]);

  let listData = [];
  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData(0, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (pageNo, key) => {
    setIsLoading(true);
    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      pagination: true,
      searchKey: key,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getBranches, pageNo, 10), body)
      .then((response) => {
        console.log(response);
        response?.content?.forEach((element) => {
            let data = {
            branchId: element.branchId,
            branchName:element.branchName,
            organizationId:element.organizationId,
            branchLocation: element.branchLocation,
            mainBranchName: element.mainBranchName
              ? element.mainBranchName
              : "-",
            branchShortCode:element.branchShortCode?element.branchShortCode:"-",
            phoneNumber:element.phoneNumber?element.phoneNumber:"-",
            branchemail:element.branchemail?element.branchemail:"-",
            mainBranchId:element.mainBranchId,
            branchDeleted:element.branchDeleted,
            branchAddress:element.branchAddress?element.branchAddress:"-",
          };
          listData.push(data);
        });
        setExecutivesList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };


  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    const payLoad = {
      searchKey: "",
      organizationId: userData.autoFinanceUser.organizationId,
      pagination: false,
    };
    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
        branchName: item.branchName,
      }));
      setBranches(result);
      fetchData();
    // eslint-disable-next-line
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://countriesnow.space/api/v0.1/countries/states",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            country: "India",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch states");
      }
      const data = await response.json();
      console.log("resposse", data?.data?.states);
      const result = data?.data?.states.map((item) => ({
        value: item.name,
        label: item.name,
      }));
      setStates(result);
      console.log(error);
    } catch (error) {
      setError(error.message);
    }
  };

  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    {
      text: "Branch Name",
      dataField: "branchName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
     
    },
    {
      text: "Short Code",
      dataField: "branchShortCode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Email",
      dataField: "branchemail",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",
      dataField: "phoneNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Address",
      dataField: "branchAddress",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "State",
      dataField: "branchLocation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
        text: "Main Branch",
        dataField: "mainBranchName",
        align: "center",
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "rgb(243,240,249)",
          fontWeight: "bold",
          verticalAlign: "middle",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
        style: {
          borderColor: "white",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
      },

    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit" />
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("row", row);
          setBranchDetails(row);
          setDefaultState(row.branchLocation)
          setIsMainBranch(false);
          setEditBranchModal(true);
        },
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(selectedPage - 1, searchKey);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadData(0, key);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  // var namePattern = "^[a-zA-Z]";
  var nameRegex = "[a-zA-Z ]+$";

  const editProfileValidate = Yup.object({
    branchemail: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required"),
    branchName: Yup.string()
      .trim()
      .matches(nameRegex, "Please Enter a Valid Name")
      .required("Branch Name is required"),
    branchShortCode: Yup.string().required("Short Code is required"),
    branchLocation: Yup.string().required("State is required"),
    branchAddress:Yup.string().required("Address is required"),
    mainBranchId: Yup.string().when([], {
        is: () => isMainBranch === false,
        then: Yup.string().required("Main Branch is required"),
        otherwise: Yup.string().notRequired(),
    }),
  });

  const onSubmit = (values) => {
    const payLoad = {
        branchId:branchDetails?.branchId,
        branchName:values?.branchName,
        organizationId:branchDetails?.organizationId,
        branchLocation: values?.branchLocation,
        branchShortCode:values?.branchShortCode,
        phoneNumber:values?.phoneNumber,
        branchemail:values?.branchemail,
        mainBranchId:values?.mainBranchId,
        branchAddress:values?.branchAddress,
        mainBranch:isMainBranch,
    };
    moduleApi.postData(apiCall.updatebranchdetails, payLoad).then((response) => {
      if (response?.Message==="Branch Update Successfully") {
        setEditBranchModal(false);
        setIsMainBranch(false);
        swal(response?.Message, {
          icon: "success",
        }).then((willOk) => {
            if (willOk) {
              loadData(0, searchKey);
            }
          });
      } else if (response.message) {
       swal(response?.message, {
        icon: "error",
        dangerMode: true,
      });
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const addBranch = (values) => {
    const payLoad = {
        branchId:"",
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        branchName:values?.branchName,
        branchLocation: values?.branchLocation,
        branchShortCode:values?.branchShortCode,
        phoneNumber:values?.phoneNumber,
        branchemail:values?.branchemail,
        mainBranchId:values?.mainBranchId,
        branchAddress:values?.branchAddress,
        mainBranch:isMainBranch,
    };
    moduleApi.postData(apiCall.registerbranch, payLoad).then((response) => {
      if (response?.Message==="Branch Added Successfully") {
        setAddBranchModal(false);
        setIsMainBranch(false);
        swal(response?.Message, {
          icon: "success",
        }).then((willOk) => {
            if (willOk) {
              loadData(0, searchKey);
            }
          });
      } else if (response.message) {
       swal(response?.message, {
        icon: "error",
        dangerMode: true,
      });
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        {/* <input
          style={{
            width: "300px",
            height: "40px",
            borderRadius: "25px",
            borderColor: "#a0a0a0",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #3a353b",
            paddingLeft: "10px",
          }}
          placeholder="Search By Name/Branch/Mobile"
          onChange={(e) => searchKeyword(e.target.value.trim())}
        /> */}
        <SearchBar
          placeholder="Search By Branch/Short Code/Email/Mobile"
          searchKeyword={searchKeyword}
        />

        <Button
          style={{
            float: "right",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => setAddBranchModal(true)}
        >
          <b> + Add Branch </b>
        </Button>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={executivesList}
              columns={columns}
            />
          </div>
          {executivesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" show={addBracnchModal}>
        <Modal.Header>
          <Modal.Title>Add Branch</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddBranchModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
        <div>
            <Formik
              initialValues={{
                branchName:"",
                organizationId:"",
                branchLocation:"",
                mainBranchName: "",
                branchShortCode:"",
                phoneNumber:"",
                branchemail:"",
                mainBranchId:"",
                branchDeleted:"",
                branchAddress:"",
              }}
              onSubmit={addBranch}
              validationSchema={editProfileValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <TextField
                          isAsterisk={true}
                          label="Branch Name"
                          name="branchName"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Short Code"
                          name="branchShortCode"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Email"
                          name="branchemail"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Mobile Number"
                          name="phoneNumber"
                          maxlength="10"
                          type="text"
                        />

                      <TextField
                          isAsterisk={true}
                          label="Address"
                          name="branchAddress"
                          type="text"
                        />
                     <div>
                        <Field name="branchLocation">
                          {({ field, form }) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "5px",
                              }}
                            >
                              <label
                                className="control-label"
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  marginBottom: "10px",
                                }}
                              >
                                State
                              </label>
                              <Select
                                placeholder="Select State"
                                classNamePrefix="select-box"
                                options={states}
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    "branchLocation",
                                    selectedOption.value
                                  );
                                }}
                              />
                            </div>
                          )}
                        </Field>

                        {errors.branchLocation && (
                          <span className="error">{errors.branchLocation}</span>
                        )}
                      </div>
                        <div
                              className="form-check form-switch"
                              style={{
                                marginTop: "10px",
                                marginRight: "15px",
                                display: "flex",
                                flexDirection: "row",
                                padding: "initial",
                              }}
                            >
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckDefault"
                                style={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                Is Main Branch
                              </label>
                              <input
                                style={{
                                  width: "45px",
                                  height: "20px",
                                  marginLeft: "10px",
                                  fontWeight: "bold",
                                }}
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                  setIsMainBranch(!isMainBranch);
                                }}
                              />
                            </div>
    {isMainBranch===false && (
                             <Field name="mainBranchId">
                             {({ field, form }) => (
                               <div
                                 style={{
                                   display: "flex",
                                   flexDirection: "column",
                                   marginTop: "5px",
                                 }}
                               >
                                 <label
                                   className="control-label"
                                   style={{
                                     fontWeight: "bold",
                                     float: "left",
                                     marginBottom: "10px",
                                   }}
                                 >
                                  Main Branch
                                 </label>
                                 <Select
                                   placeholder="Select branch"
                                   classNamePrefix="select-box"
                                   options={branches}
                                   onChange={(selectedOption) => {
                                     form.setFieldValue(
                                       "mainBranchId",
                                       selectedOption.branchId
                                     );
                                   }}
                                 />
                               </div>
                             )}
                           </Field>
                            )}
                        <div style={{ marginTop: "10px" }}>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setAddBranchModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={editBranchModal}>
        <Modal.Header>
          <Modal.Title>Edit Branch</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setEditBranchModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                branchName:branchDetails?.branchName,
                organizationId:branchDetails?.organizationId,
                branchLocation:branchDetails?.branchLocation,
                mainBranchName: branchDetails?.mainBranchName,
                branchShortCode:branchDetails?.branchShortCode,
                phoneNumber:branchDetails?.phoneNumber,
                branchemail:branchDetails?.branchemail,
                mainBranchId:branchDetails?.mainBranchId,
                branchDeleted:branchDetails?.branchDeleted,
                branchAddress:branchDetails?.branchAddress,
              }}
              onSubmit={onSubmit}
              validationSchema={editProfileValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <TextField
                          isAsterisk={true}
                          label="Branch Name"
                          name="branchName"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Short Code"
                          name="branchShortCode"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Email"
                          name="branchemail"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Mobile Number"
                          name="phoneNumber"
                          maxlength="10"
                          type="text"
                        />

                      <TextField
                          isAsterisk={true}
                          label="Address"
                          name="branchAddress"
                          type="text"
                        />
                     <div>
                        <Field name="branchLocation">
                          {({ field, form }) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "5px",
                              }}
                            >
                              <label
                                className="control-label"
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  marginBottom: "10px",
                                }}
                              >
                                State
                              </label>
                              <Select
                                placeholder="Select State"
                                classNamePrefix="select-box"
                                options={states}
                                defaultValue={defaultState}
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    "branchLocation",
                                    selectedOption.value
                                  );
                                }}
                              />
                            </div>
                          )}
                        </Field>

                        {errors.branchLocation && (
                          <span className="error">{errors.branchLocation}</span>
                        )}
                      </div>
                        <div
                              className="form-check form-switch"
                              style={{
                                marginTop: "10px",
                                marginRight: "15px",
                                display: "flex",
                                flexDirection: "row",
                                padding: "initial",
                              }}
                            >
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckDefault"
                                style={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                Is Main Branch
                              </label>
                              <input
                                style={{
                                  width: "45px",
                                  height: "20px",
                                  marginLeft: "10px",
                                  fontWeight: "bold",
                                }}
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                  setIsMainBranch(!isMainBranch);
                                }}
                              />
                            </div>
    {isMainBranch===false && (
                             <Field name="mainBranchId">
                             {({ field, form }) => (
                               <div
                                 style={{
                                   display: "flex",
                                   flexDirection: "column",
                                   marginTop: "5px",
                                 }}
                               >
                                 <label
                                   className="control-label"
                                   style={{
                                     fontWeight: "bold",
                                     float: "left",
                                     marginBottom: "10px",
                                   }}
                                 >
                                  Main Branch
                                 </label>
                                 <Select
                                   placeholder="Select branch"
                                   classNamePrefix="select-box"
                                   options={branches}
                                   defaultValue={branchDetails.mainBranchId}
                                   onChange={(selectedOption) => {
                                     form.setFieldValue(
                                       "mainBranchId",
                                       selectedOption.branchId
                                     );
                                   }}
                                 />
                               </div>
                             )}
                           </Field>
                            )}
                        <div style={{ marginTop: "10px" }}>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setEditBranchModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Branches;
