import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import NO_DATA_FOUND from "../../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import ReactGA from "react-ga";
import CreateTemplate from "./CreateTemplate";
import { Link } from "react-router-dom";
import { getTokenInLocalStorage } from "../../services/AuthService";
import * as moduleApi from "../../rest/moduleApi";
import { apiCall } from "../../rest/restApi";
import {
  EpochToDate,
  convertEpochToTime3,
} from "../../CommonComponents/Helper";
import { Paper, Typography } from "@mui/material";

const Templates = () => {
  const [isCreateWhatsappTemplate, setIsCreateWhatsappTemplate] =
    useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(false);

  let userLoginDetails = getTokenInLocalStorage();

  const languages = [
    { value: "en", label: "English" },
    { value: "hi", label: "Hindi" },
    { value: "te", label: "Telugu" },
    { value: "ta", label: "Tamil" },
    // Add more languages as needed
  ];

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (pageNo) => {
    var listData = [];
    setIsLoading(true);
    var payload = {
      //   starttime: start,
      //   endtime: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      //   teamName: "",
      //   searchKey: searchKey,
      //   reportCategory: "DETAILED",
      //   reportingOfficerId: "",
      //   reportType: "SalesAllocationSummary",
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.getTemplates, pageNo, 10), payload)
      .then((response) => {
        setCurrPage(pageNo);

        response?.content?.forEach((element) => {
          let data = {
            allow_category_change: element.allow_category_change,
            body: element.body,
            bodyValue: element.body?.text ? element.body?.text : "-",
            buttons: element.buttons,
            category: element.category ? element.category : "-",
            faceBookTemplateId: element.faceBookTemplateId,
            footer: element.footer,
            footerValue: element.footer?.text ? element.footer?.text : "-",
            header: element.header,
            headerValue: element.header?.text ? element.header?.text : "-",
            id: element.id,
            language: element.language
              ? getLabelByValue(element?.language)
              : "-",
            organizationId: element.organizationId,
            status: element.status,
            templateName: element.templateName ? element.templateName : "-",
            createdTime: element.createdTime
              ? EpochToDate(element.createdTime)
              : "-",
          };
          listData.push(data);
        });
        console.log(listData);
        setTemplateList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    // loadSalesData(selectedPage - 1, searchKey, start, end);
    loadData(selectedPage - 1);
  };

  const getLabelByValue = (value) => {
    // Find the object in the languages array where the value matches the provided value
    const matchingObject = languages.find(
      (language) => language.value === value
    );

    // Check if a matching object is found
    if (matchingObject) {
      return matchingObject.label; // Return the label if found
    } else {
      return "Label not found"; // Return a message if no matching value is found
    }
  };

  const columns = [
    {
      text: "Template Name",
      dataField: "templateName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Language",
      dataField: "language",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Category",
      dataField: "category",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Header",
      dataField: "headerValue",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Body",
      dataField: "bodyValue",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Footer",
      dataField: "footerValue",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Buttons",
      dataField: "buttons",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.buttons.length === 0) {
          return "-";
        } else {
          return row.buttons.map((item) => item.text).join(" , ");
        }
      },
    },

    {
      text: "Created At",
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Updated At",
    //   dataField: "updatedTime",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log(row);
          setSelectedTemplate(row);
          setPreviewTemplate(true);
        },
      },
    },
  ];

  const handleClose = () => {
    loadData(0);
    setIsCreateWhatsappTemplate(false);
  };


  const replaceBodyString = (value, data ) => {
    let updatedString = value;
    // if (isHeader === false) {
      data?.forEach((obj, index) => {
        const placeholder = `{{${index + 1}}}`;
        updatedString = updatedString.replaceAll(
          placeholder,
          obj
        );
      });
    // } else {
    //     selectedTemplate?.header?.params?.forEach((obj, index) => {
    //     const placeholder = `{{${index + 1}}}`;
    //     updatedString = updatedString.replaceAll(
    //       placeholder,
    //       obj.variableValue
    //     );
    //   });
    // }
    return updatedString;
  };

  const getPreviewImage = (type) => {
    switch (type) {
      case "image":
        return "https://gs-upload.gupshup.io/whatsapp/preview_image.webp";
      case "video":
        return "https://gs-upload.gupshup.io/whatsapp/preview_video.webp";
      case "document":
        return "https://gs-upload.gupshup.io/whatsapp/preview_document.webp";
      case "location":
        return "https://gs-upload.gupshup.io/whatsapp/preview_location.webp";
      default:
        return "";
    }
  };

  return (
    <div className="card">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            margin: "10px",
            alignItems: "flex-end",
          }}
        >
          <Button
            style={{
              margin: "5px 5px ",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => setIsCreateWhatsappTemplate(true)}
          >
            Create Template
          </Button>
        </div>
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "5px",
              overflowX: "auto",
            }}
          >
            <div
              style={{
                overflow: "auto",
                maxHeight: "500px",
              }}
            >
              <BootstrapTable
                keyField="id"
                data={templateList}
                columns={columns}
              />
            </div>

            {templateList.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements}
                pageSize={10}
                defaultActivePage={currPage + 1}
                onSelect={onPageChange}
              />
            </div>
          </div>
        </LoadingOverlay>
      </div>
      <Modal className="fade" show={isCreateWhatsappTemplate} size="xl">
        <Modal.Header>
          <Modal.Title>Create Template</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsCreateWhatsappTemplate(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <CreateTemplate close={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={previewTemplate} size="md">
        <Modal.Header>
          <Modal.Title>Preview Template</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setPreviewTemplate(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Paper
            elevation={3}
            style={{
              padding: 16,
              backgroundColor: "#f1f2f6",
              borderRadius: "12px",
              margin:'-10px'
            }}
          >
            <Typography style={{ fontFamily: "poppins" }} variant="h6">
              Preview
            </Typography>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                // padding: "10px",
                fontFamily: "poppins",
              }}
            >
              <div style={{ padding: "10px" }}>
                {selectedTemplate?.header?.format?.toLowerCase() === "text" ? (
                  <Typography
                    style={{ fontFamily: "poppins" }}
                    variant="header"
                  >
                    {replaceBodyString(selectedTemplate.headerValue, selectedTemplate?.header?.params)}
                  </Typography>
                ) : (
                //   selectedTemplate === "media" &&
                //   selectedTemplate?.header && (
                    <img
                      style={{ width: "100%",borderRadius: "6px", }}
                      alt="Sample Media"
                      title={selectedTemplate?.header?.text}
                      src={getPreviewImage(selectedTemplate?.header?.format?.toLowerCase())}
                    ></img>
                //   )
                )
                }
                {/* <Typography style={{ fontFamily: "poppins" }} variant="subtitle1">
                {selectedTemplate.templateName}
              </Typography>
              <Typography style={{ fontFamily: "poppins" }} variant="subtitle1">
                {selectedTemplate.category}
              </Typography> */}
                {/* <Typography style={{ fontFamily: "poppins" }} variant="subtitle2">
                {selectedTemplate.templateLabel}
              </Typography>
              <Typography
                style={{ fontFamily: "poppins" }}
                variant="caption"
                display="block"
                gutterBottom
              >
                {
                  languages.find((lang) => lang.value === selectedTemplate.language)
                    ?.label
                }
              </Typography> */}

                <Typography
                  style={{
                    fontFamily: "poppins",
                    fontSize: "0.85rem",
                    color: "#3f3f3f",
                    overflowWrap: "break-word",
                    maxWidth: "100%",
                    maxHeight: "400px",
                    padding: "0px 0px 8px",
                    whiteSpace: "pre-line",
                    display: "-webkit-box",
                    fontWeight: "400",
                    marginTop: "10px",
                    overflowY: "auto",
                  }}
                  variant="body1"
                  gutterBottom
                >
                  {replaceBodyString(selectedTemplate.bodyValue, selectedTemplate?.body?.values)}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "poppins",
                    fontSize: "0.75rem",
                    color: "#5f6880",
                    overflowWrap: "break-word",
                    maxWidth: "100%",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    maxHeight: "220px",
                    padding: "0px 0px 8px",
                    wordBreak: "break-word",
                    whiteSpace: "pre-line",
                    display: "-webkit-box",
                    fontWeight: "500",
                    overflow: "hidden",
                  }}
                  variant="subtitle1"
                  gutterBottom
                >
                  {selectedTemplate.footerValue}
                </Typography>
                <div
                  style={{
                    textAlign: "end",
                    fontSize: "0.725rem",
                    color: "#5f6880",
                    fontFamily: "poppins",
                  }}
                >
                  {convertEpochToTime3(new Date().getTime() / 1000)}
                </div>
              </div>
              {selectedTemplate?.buttons?.map((item, index)=>{
                return (
              <div>
                {item?.type?.toLowerCase()==='phone_number' && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0.7rem 1rem",
                      textAlign: "center",
                      color: "#00a5f4",
                      backgroundColor: "#fefefe",
                      cursor: "pointer",
                      marginBottom: "0px",
                      borderRadius: "0px 0px 6px 6px",
                      wordBreak: "break-word",
                      borderTop: "0.36px solid #dbdbdb",
                      boxShadow: "0 1px 3px 0 #f4f4f4",
                      fontSize: "0.75rem",
                      verticalAlign: "middle",
                    }}
                  >
                    <img
                      src="https://gs-upload.gupshup.io/whatsapp/preview_call1.webp"
                      alt=""
                      style={{ marginRight: "10px", width: "1.5rem" }}
                    />
                    {item?.text}
                  </div>
                )}
                {item?.type?.toLowerCase()==="copy_offer_code" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0.7rem 1rem",
                      textAlign: "center",
                      color: "#00a5f4",
                      backgroundColor: "#fefefe",
                      cursor: "pointer",
                      marginBottom: "0px",
                      borderRadius: "0px 0px 6px 6px",
                      wordBreak: "break-word",
                      borderTop: "0.36px solid #dbdbdb",
                      boxShadow: "0 1px 3px 0 #f4f4f4",
                      fontSize: "0.75rem",
                      verticalAlign: "middle",
                    }}
                  >
                    <img
                      src="https://gs-upload.gupshup.io/whatsapp/copy_code.svg"
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    {item?.text}
                  </div>
                )}
                {item?.type?.toLowerCase()==='url'&& (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0.7rem 1rem",
                      textAlign: "center",
                      color: "#00a5f4",
                      backgroundColor: "#fefefe",
                      cursor: "pointer",
                      marginBottom: "0px",
                      borderRadius: "0px 0px 6px 6px",
                      wordBreak: "break-word",
                      borderTop: "0.36px solid #dbdbdb",
                      boxShadow: "0 1px 3px 0 #f4f4f4",
                      fontSize: "0.75rem",
                      verticalAlign: "middle",
                    }}
                  >
                    <img
                      src="https://gs-upload.gupshup.io/whatsapp/preview_call2.webp"
                      alt=""
                      style={{ marginRight: "10px", width: "1.5rem" }}
                    />
                    {item?.text}
                  </div>
                )}
                {item?.type?.toLowerCase()==="quick_reply" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0.7rem 1rem",
                      textAlign: "center",
                      color: "#00a5f4",
                      backgroundColor: "#fefefe",
                      cursor: "pointer",
                      marginBottom: "0px",
                      borderRadius: "0px 0px 6px 6px",
                      wordBreak: "break-word",
                      borderTop: "0.36px solid #dbdbdb",
                      boxShadow: "0 1px 3px 0 #f4f4f4",
                      fontSize: "0.75rem",
                      verticalAlign: "middle",
                    }}
                  >
                    <img
                      src="https://gs-upload.gupshup.io/whatsapp/reply.svg"
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    {item?.text}
                  </div>
                )}
              </div>)})}
            </div>
          </Paper>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Templates;
