import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { AgencyFiAllocList } from "../TableColumns";
import { LoadingOverlayComponent } from "../../CommonComponents/LoadingOverlay";
import NO_DATA_FOUND from "../../images/no-data-found.png";
import "../fiallocations.css";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import { getFileKeyName } from '../utils';

function DownloadAllocationsList({ agencyAllocations, showLoader,noDataFound }) {
  const options = {
    hideSizePerPage: true,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    disablePageTitle: true,
  };
  const download = (fileKeyName) => {
    let requestPayload = {
        fileKeyName:getFileKeyName(fileKeyName)
    }
    nodeApi
        .postData(apiCall.downloadFromS3, requestPayload)
        .then((response) => {
            window.open(response.url, '_self')
        })
  }
  return (
    <div className="table-content">
      <LoadingOverlayComponent showLoader={showLoader}>
        <ToolkitProvider
          keyField="id"
          data={
            agencyAllocations
          }
          columns={AgencyFiAllocList({download})}
          search
        >
          {(props) => (
            <div className="table-container">
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
              />
            </div>

          )}
        </ToolkitProvider>
        {noDataFound &&
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>}
      </LoadingOverlayComponent>
    </div>
  )
}

export default DownloadAllocationsList