import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import * as helper from "../CommonComponents/Helper";
import LoadImage from "../CommonComponents/LoadImage";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import AddExecutive from "../Executives/AddExecutive";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import SearchBar from "../CommonComponents/SearchBar";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from 'react-ga'

function ManagerExecutives({ userId, organizationId, isL1Manager }) {
  var history = useHistory();
  const [executivesList, setExecutivesList] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [addExecutiveModal, setAddExecutiveModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [isMap, setIsMap] = useState(false);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [isLoading, setIsLoading] = useState(false);

  let listData = [];
  useEffect(() => {
    ReactGA.pageview('Manager Executives');
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData(0, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (pageNo, key) => {
    setIsLoading(true);
    let agentBody;
    agentBody = {
      reportOfficerId: userId,
      searchKey: key,
      roleName: "EXECUTIVE",
      organizationId: organizationId,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getExecutives, pageNo, 10),
        agentBody
      )
      .then((response) => {
        let userIds = [];
        response.content?.forEach((element) => {
          userIds.push(element.autoFinanceUser.userId);
        });
        let payload = {
          organizationId: organizationId,
          userIds: userIds,
        };
        let m = new Map();
        moduleApi
          .postData(apiCall.getLiveLocations, payload)
          .then((response2) => {
            response2?.forEach((item) => {
              m.set(item?.userId, item);
            });

            response.content?.forEach((element) => {
              let data = {
                branchId: element.autoFinanceBranchDetails.branchId,
                branchLocation: element.autoFinanceBranchDetails.branchLocation,
                branchName: element.autoFinanceBranchDetails.branchName,
                branchemail: element.autoFinanceBranchDetails.branchemail,
                organizationId: element.autoFinanceBranchDetails.organizationId,
                phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
                addedByUserId: element.autoFinanceUser.addedByUserId,
                reportingOfficerIds:
                  element.autoFinanceUser.reportingOfficerIds,
                email: element.autoFinanceUser.email,
                employeeId: element.autoFinanceUser.employeeId,
                image: element.autoFinanceUser.image,
                mobileNumber: element.autoFinanceUser.mobileNumber,
                name: element.autoFinanceUser.name,
                passwordUpdated: element.autoFinanceUser.passwordUpdated,
                reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
                roleName: element.autoFinanceUser.roleName,
                status: element.autoFinanceUser.status,
                userId: element.autoFinanceUser.userId,
                designation: element.autoFinanceUser.companyDesignation,

                batteryPercentage: m.has(element.autoFinanceUser.userId)
                  ? m.get(element.autoFinanceUser.userId).batteryPercentage
                  : 0,
                gpsData: m.has(element.autoFinanceUser.userId)
                  ? m.get(element.autoFinanceUser.userId).gpsData
                  : null,
                lastUpdatedAt: m.has(element.autoFinanceUser.userId)
                  ? m.get(element.autoFinanceUser.userId).lastUpdatedAt
                  : 0,
              };
              listData.push(data);
            });
            setExecutivesList(listData);
            setTotalElements(response.totalElements);
            setIsLoading(false);
          });
      });
  };
  // const rowStyle = { backgroundColor: "white", borderColor: "white" };

  const onPageChange = (selectedPage) => {
    loadData(selectedPage - 1, searchKey);
  };
  const handleClose = () => {
    setAddExecutiveModal(false);
    loadData(0, searchKey);
  };
  const searchKeyword = (key) => {
    setSearchKey(key);
    loadData(0, key);
  };

  // const columns = [
  //   // {
  //   //     text: "Image",
  //   //     dataField: "image",
  //   //     width: 130,
  //   //     align: "center",
  //   //     headerAlign: "center",
  //   //     headerStyle: {
  //   //       backgroundColor: "rgb(243,240,249)",
  //   //       fontWeight: "bold",
  //   //       fontFamily:
  //   //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //   //     },
  //   //     formatter: (cell, row) => {
  //   //         if (row.image === "ACTIVE") {
  //   //           return (
  //   //             <></>
  //   //           );
  //   //         } else {
  //   //           return (
  //   //             <Media>
  //   //               <div style={{ marginTop: "30px" }} className="ms-3">
  //   //                 <img
  //   //                   // src= {(isImageComing === false) ? `data:image/png;base64,${currentFile}`: mainOBJ.userImage}
  //   //                   // src={!isImageGoing ? getImage : getImage}
  //   //                   src={executivesList.image
  //   //                     // isImageComing
  //   //                     //   ? `data:image/png;base64,${currentFile}`
  //   //                     //   : mainOBJ.userImage
  //   //                     //   ? mainOBJ.userImage
  //   //                     //   : `data:image/png;base64,${currentFile}`
  //   //                   }
  //   //                   // src={`data:image/png;base64,${currentFile}`}
  //   //                   alt=""
  //   //                   className="avatar-lg rounded-circle img-thumbnail"
  //   //                   // className = "rounded avatar-md" //square shape immage
  //   //                 />
  //   //               </div>

  //   //             </Media>
  //   //           );
  //   //         }
  //   //       },
  //   // },
  //   {
  //     text: "Name",
  //     dataField: "name",
  //     width: 130,
  //     align: "center",
  //     headerAlign: "center",
  //     headerStyle: {
  //       backgroundColor: "rgb(243,240,249)",
  //       fontWeight: "bold",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     style: {
  //       fontWeight: "bold",
  //       borderColor: "white",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     formatter: (col, row) => {
  //       return (
  //         <div
  //           className="d-flex align-items-center"
  //           style={{ marginLeft: "15px" }}
  //         >
  //           <LoadImage source={row.image} />
  //           {" "}
  //           <span className="w-space-no">{row.name}</span>
  //         </div>
  //       );
  //     },
  //   },

  //   {
  //     text: "Branch",
  //     dataField: "branchName",
  //     align: "center",
  //     headerAlign: "center",
  //     headerStyle: {
  //       backgroundColor: "rgb(243,240,249)",
  //       fontWeight: "bold",

  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     style: {
  //       borderColor: "white",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //   },
  //   {
  //     text: "Email",
  //     dataField: "email",
  //     align: "center",
  //     headerAlign: "center",
  //     headerStyle: {
  //       backgroundColor: "rgb(243,240,249)",
  //       fontWeight: "bold",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     style: {
  //       borderColor: "white",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //   },
  //   {
  //     text: "Mobile Number",

  //     dataField: "mobileNumber",
  //     align: "center",
  //     headerAlign: "center",
  //     headerStyle: {
  //       backgroundColor: "rgb(243,240,249)",
  //       fontWeight: "bold",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     style: {
  //       borderColor: "white",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //   },
  //   {
  //     text: "Status",
  //     dataField: "status",
  //     align: "center",
  //     headerAlign: "center",
  //     headerStyle: {
  //       backgroundColor: "rgb(243,240,249)",
  //       fontWeight: "bold",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     style: {
  //       borderColor: "white",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     formatter: (cell, row) => {
  //       if (row.status === "ACTIVE") {
  //         return (
  //           <span className="badge badge-rounded badge-success">Active</span>
  //         );
  //       } else {
  //         return (
  //           <span className="badge badge-rounded badge-danger">Inactive</span>
  //         );
  //       }
  //     },
  //   },
  //   {
  //     text: "",
  //     dataField: "status",
  //     align: "center",
  //     headerAlign: "center",
  //     headerStyle: {
  //       backgroundColor: "rgb(243,240,249)",
  //       fontWeight: "bold",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     style: {
  //       borderColor: "white",
  //       fontFamily:
  //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
  //     },
  //     formatter: (cell, row) => {
  //       if (row.status === "ACTIVE4") {
  //         return <></>;
  //       } else {
  //         return (
  //           <div className="d-flex">
  //             <Link to="#" className="btn btn-info shadow btn-xs sharp">
  //               <i className="fa fa-eye"></i>
  //             </Link>
  //           </div>
  //         );
  //       }
  //     },

  //     events: {
  //       onClick: (e, column, columnIndex, row, rowIndex) => {
  //         //setBasicModal(true);

  //         localStorage.setItem("executiveDetails", JSON.stringify(row));
  //         history.push("/manager-executive-details");
  //         // history.push("/executive-details");
  //         //   <Link  to="/executive-details">
  //         //         <i className="fa fa-trash"></i>
  //         //       </Link>
  //       },
  //     },
  //   },
  // ];

  const columns = [
    // {
    //     text: "Image",
    //     dataField: "image",
    //     width: 130,
    //     align: "center",
    //     headerAlign: "center",
    //     headerStyle: {
    //       backgroundColor: "rgb(243,240,249)",
    //       fontWeight: "bold",
    //       fontFamily:
    //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //     },
    //     formatter: (cell, row) => {
    //         if (row.image === "ACTIVE") {
    //           return (
    //             <></>
    //           );
    //         } else {
    //           return (
    //             <Media>
    //               <div style={{ marginTop: "30px" }} className="ms-3">
    //                 <img
    //                   // src= {(isImageComing === false) ? `data:image/png;base64,${currentFile}`: mainOBJ.userImage}
    //                   // src={!isImageGoing ? getImage : getImage}
    //                   src={executivesList.image
    //                     // isImageComing
    //                     //   ? `data:image/png;base64,${currentFile}`
    //                     //   : mainOBJ.userImage
    //                     //   ? mainOBJ.userImage
    //                     //   : `data:image/png;base64,${currentFile}`
    //                   }
    //                   // src={`data:image/png;base64,${currentFile}`}
    //                   alt=""
    //                   className="avatar-lg rounded-circle img-thumbnail"
    //                   // className = "rounded avatar-md" //square shape immage
    //                 />
    //               </div>

    //             </Media>
    //           );
    //         }
    //       },
    // },
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <LoadImage source={row.image} />{" "}
            <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
          </div>
        );
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Email",
    //   dataField: "email",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Mobile No",

      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",

      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Battery",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div>
            {row.batteryPercentage > 75 ? (
              <span style={{ color: "green", verticalAlign: "middle" }}>
                <i className="fas fa-battery-full fa-2x fa-rotate-0" />
              </span>
            ) : row.batteryPercentage > 30 ? (
              <span style={{ color: "orange", verticalAlign: "middle" }}>
                <i className="fas fa-battery-three-quarters fa-2x fa-rotate-0 " />
              </span>
            ) : row.batteryPercentage > 0 ? (
              <span style={{ color: "red", verticalAlign: "middle" }}>
                <i className="fas fa-battery-quarter fa-2x fa-rotate-0 " />
              </span>
            ) : (
              <></>
            )}
            <span
              style={{
                marginLeft: row.batteryPercentage === 0 ? "0px" : "3px",
              }}
            >
              {row.batteryPercentage === 0
                ? "N/A"
                : row.batteryPercentage + "%"}
            </span>
          </div>
        );
        // if (row.status === "ACTIVE") {
        //   return (
        //     <span className="badge badge-rounded badge-success">Active</span>
        //   );
        // } else {
        //   return (
        //     <span className="badge badge-rounded badge-danger">Inactive</span>
        //   );
        // }
      },
    },
    {
      text: "Last Updated",
      dataField: "lastUpdatedAt",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.gpsData) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p
                style={{ cursor: "pointer" }}
                title={helper.EpochToDate(row.gpsData.t)}
              >
                {helper.EpochToDate(row.gpsData.t)}
              </p>
              <span
                style={{
                  color: "green",
                  margin: "0px 15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setLatLng({ lat: row.gpsData.x, lng: row.gpsData.y });
                  setIsMap(true);
                }}
              >
                <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
              </span>
            </div>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      text: "Status",
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "ACTIVE") {
          return (
            <span className="badge badge-rounded badge-success">Active</span>
          );
        } else {
          return (
            <span className="badge badge-rounded badge-danger">Inactive</span>
          );
        }
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          row.isL1Manager = isL1Manager;
          localStorage.setItem("executiveDetails", JSON.stringify(row));
          history.push("/manager-executive-details");
        },
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "10px",
        }}
      >
        {/* <input style={{
          width: '300px', height: '35px', borderRadius: '25px', borderColor: '#a0a0a0',
          borderWidth: '1px', boxShadow: '1px 1px 2px #3a353b', paddingLeft: '10px'
        }} placeholder='Search Here' onChange={(e) => searchKeyword(e.target.value.trim())} /> */}
        <SearchBar
          placeholder="Search By Name/Branch/Mobile/Emp ID"
          searchKeyword={searchKeyword}
        />

        {/* <Button
          style={{
            float: "right",
          //  margin: "10px",
          // marginBottom:'10px',
             marginLeft: "20px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => setAddExecutiveModal(true)}
        >
         <b>+ Add Executive</b>
        </Button> */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
        <div style={{overflow:'auto', maxHeight:'400px'}}>
          <BootstrapTable
            keyField="panNo"
            data={executivesList}
            columns={columns}
          />
          </div>
          {executivesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Visit Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Clu</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>Do you really want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setBasicModal(false)} variant="danger light">
            Cancel
          </Button>
          <Button variant="primary" onClick={() => setBasicModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" show={addExecutiveModal}>
        <Modal.Header>
          <Modal.Title>Add Executive</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddExecutiveModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AddExecutive close={handleClose} />
          {/* <AddExecutiveByManager close={handleClose}/> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ManagerExecutives;
