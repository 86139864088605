

import React from "react";
import { ErrorMessage, useField,Field } from 'formik';
import './Action.css';
import PropTypes from 'prop-types';
// import { Field} from "formik";


function TextField({isAsterisk, isTextArea,label, ...props}) {
    const [field, meta] = useField(props);

    // var isAsterisk = false;
  return (
    <div className="mb-2 " style={{ margin:'0px'}}>
      {isAsterisk ? (<label  className = "control-label" htmlFor={field} style={{ fontWeight: "bold" , float: "left", marginBottom:'10px'}}>{label}</label>)
      :(<label htmlFor={field} style={{ fontWeight: "bold" , float: "left", marginBottom:'10px'}}>{label}</label>)}{" "}
     {isTextArea ? ( 
     <Field className={`form-control shadow-none`} style={{ marginTon: "25px", width: "100%" }} component="textarea"
     {...field} {...props}
     autoComplete="off"
     />)
       :
       ( <input 
        className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
        {...field} {...props}
        autoComplete="off"
         />)}
       <ErrorMessage component="div" className="error" name={field.name}/>
    </div>
  );
}
TextField.propTypes = {
  isAsterisk: PropTypes.any,
  isTextArea: PropTypes.any,
  label: PropTypes.any,
};

export default TextField;
