import * as React from "react";
import {
  HierarchicalTree,
  StackPanel,
  ImageElement,
  TextElement,
  SnapConstraints,
  PrintAndExport,
  DiagramComponent,
  Inject,
  DataBinding,
  DiagramTools,
  NodeConstraints,
} from "@syncfusion/ej2-react-diagrams";
import { DataManager } from "@syncfusion/ej2-data";
// import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";

// import { data } from "./overview-data";
import {
  ItemDirective,
  ItemsDirective,
  ToolbarComponent,
} from "@syncfusion/ej2-react-navigations";
let diagramInstance;
// let toolbarEditor;
// let checkBoxObj;
function SyncOrgHierarchy({ orgData }) {
  React.useEffect(() => {
    rendereComplete();
  }, []);
  function rendereComplete() {
    diagramInstance.fitToPage();
  }

  //Funtion to add the Template of the Node.
  function setNodeTemplate(obj, diagram) {
    let content = new StackPanel();
    content.id = obj.id + "_outerstack";
    content.orientation = "Horizontal";
    content.style.strokeColor = "gray";
    content.padding = { left: 5, right: 10, top: 5, bottom: 5 };
    let image = new ImageElement();
    image.width = 50;
    image.height = 50;
    image.style.strokeColor = "none";
    image.source = obj.data.ImageUrl;
    image.id = obj.id + "_pic";
    let innerStack = new StackPanel();
    innerStack.style.strokeColor = "none";
    innerStack.margin = { left: 5, right: 0, top: 0, bottom: 0 };
    innerStack.id = obj.id + "_innerstack";
    let text = new TextElement();
    text.content = obj.data.Name;
    text.style.color = "black";
    text.style.bold = true;
    text.style.strokeColor = "none";
    text.style.fill = "none";
    text.id = obj.id + "_text1";
    
    let desigText = new TextElement();
    desigText.margin = { left: 0, right: 0, top: 5, bottom: 0 };
    desigText.content = obj.data.Designation;
    desigText.style.color = "black";
    desigText.style.strokeColor = "none";
    desigText.style.fill = "none";
    desigText.style.textWrapping = "Wrap";
    desigText.id = obj.id + "_desig";
    let desigText2 = new TextElement();
    desigText2.margin = { left: 0, right: 0, top: 5, bottom: 0 };
    desigText2.content = obj.data.empId;
    desigText2.style.color = "black";
    desigText2.style.strokeColor = "none";
    desigText2.style.fill = "none";
    desigText2.style.textWrapping = "Wrap";
    desigText2.id = obj.id + "_desig2";
    innerStack.children = [text, desigText,desigText2];
    content.children = [image, innerStack];
    return content;
  }

  function nodeDefaults(obj, diagram) {
    obj.style = {
      fill: "#659be5",
      strokeColor: "none",
      color: "white",
      strokeWidth: 2,
    };
    obj.borderColor = "#3a6eb5";
    obj.backgroundColor = "#659be5";
    obj.shape.margin = { left: 5, right: 5, bottom: 5, top: 5 };
    obj.expandIcon = {
      height: 10,
      width: 10,
      shape: "Minus",
      fill: "lightgray",
      offset: { x: 0.5, y: 1 },
    };
    obj.expandIcon.verticalAlignment = "Auto";
    obj.expandIcon.margin = { left: 0, right: 0, top: 0, bottom: 0 };
    obj.collapseIcon.offset = { x: 0.5, y: 1 };
    obj.collapseIcon.verticalAlignment = "Auto";
    obj.collapseIcon.margin = { left: 0, right: 0, top: 0, bottom: 0 };
    obj.collapseIcon.height = 10;
    obj.collapseIcon.width = 10;
    obj.collapseIcon.padding.top = 5;
    obj.collapseIcon.borderRadius = "50%";
    obj.collapseIcon.shape = "Plus";
    obj.collapseIcon.fill = "lightgray";
    obj.constraints &= ~(
      NodeConstraints.Resize |
      NodeConstraints.Rotate |
      NodeConstraints.Drag
    );
    obj.constraints |= NodeConstraints.ReadOnly;
    return obj;
  }

  // function onItemClick(args) {
  //   let printOptions = {};
  //   switch (args.item.text) {
  //     case "Print":
  //       {
  //         printOptions.mode = "Data";
  //         printOptions.region = "PageSettings";
  //         printOptions.multiplePage = false;
  //         printOptions.margin = { left: 0, top: 0, bottom: 0, right: 0 };
  //         diagramInstance.print(printOptions);
  //       }
  //       break;
  //   }
  // }

  //   function checkboxTemplate() {
  //     return (<CheckBoxComponent id="checkBox" checked={false} ref={checkBox => (checkBoxObj = checkBox)} label="Multiple Page">

  // </CheckBoxComponent>);
  // }

  //Export the diagraming object based on the format.
  function onselect(args) {
    let exportOptions = {};
    switch (args.item.text) {
      case "JPG":
        exportOptions.format = args.item.text;
        break;
      case "PNG":
        exportOptions.format = args.item.text;
        break;
      case "SVG":
        exportOptions.format = args.item.text;
        break;
      default:
        return "";
    }
    exportOptions.mode = "Download";
    exportOptions.region = "PageSettings";
    exportOptions.multiplePage = false;
    exportOptions.fileName = "Export";
    exportOptions.margin = { left: 0, top: 0, bottom: 0, right: 0 };
    diagramInstance.exportDiagram(exportOptions);
  }

  // function onChange(args) {
  //   for (let node of diagramInstance.nodes) {
  //     if (args.checked) {
  //       node.expandIcon.shape = "Minus";
  //       node.collapseIcon.shape = "Plus";
  //     } else {
  //       node.expandIcon.shape = "None";
  //       node.collapseIcon.shape = "None";
  //     }
  //   }
  //   diagramInstance.dataBind();
  //   diagramInstance.doLayout();
  // }

  function tooledit(args) {
    let printOptions = {};
    // eslint-disable-next-line
    switch (args.item.tooltipText) {
      case "Zoom In":
        let zoomin = { type: "ZoomIn", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomin);
        break;
      case "Zoom Out":
        let zoomout = { type: "ZoomOut", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomout);
        break;
      case "Select":
        diagramInstance.clearSelection();
        diagramInstance.drawingObject = {};
        diagramInstance.tool =
          DiagramTools.SingleSelect | DiagramTools.MultipleSelect;
        break;
      case "Pan Tool":
        diagramInstance.tool = DiagramTools.ZoomPan;
        break;
      case "Reset":
        diagramInstance.reset();
        break;
      case "Fit To Page":
        diagramInstance.fitToPage();

        break;
      case "Bring Into View":
        if (diagramInstance.selectedItems.nodes.length > 0) {
          let bound = diagramInstance.selectedItems.nodes[0].wrapper.bounds;
          diagramInstance.bringIntoView(bound);
        }
        //   diagramInstance.bringIntoView({
        //     x: 700,
        //     y: 500,
        //     width: 100,
        //     height: 100
        // });
        break;
      case "Bring Into Center":
        if (diagramInstance.selectedItems.nodes.length > 0) {
          let bounds = diagramInstance.selectedItems.nodes[0].wrapper.bounds;
          diagramInstance.bringToCenter(bounds);
        }
        //   diagramInstance.bringToCenter({
        //     x: 700,
        //     y: 500,
        //     width: 100,
        //     height: 100
        // });
        break;
      case "Print":
        printOptions.mode = "Data";
        printOptions.region = "PageSettings";
        printOptions.multiplePage = false;
        printOptions.margin = { left: 0, top: 0, bottom: 0, right: 0 };
        diagramInstance.print(printOptions);

        break;
      // default:
      //   return "";
    }
  }

  function contentTemplate() {
    return (
      <DropDownButtonComponent
        items={items}
        iconCss="e-diagram-icons e-diagram-export"
        content="Export"
        select={onselect}
      ></DropDownButtonComponent>
    );
  }

  let items = [
    {
      text: "JPG",
    },
    {
      text: "PNG",
    },
    {
      text: "SVG",
    },
  ];

  function connectorDefaults(connector, diagram) {
    connector.targetDecorator.shape = "None";
    connector.type = "Orthogonal";
    connector.style.strokeColor = "#6d6d6d";
    connector.constraints = 0;
    connector.cornerRadius = 5;
    return connector;
  }
  // //update the orientation of the Layout.
  // function updatelayout(target, orientation) {
  //   diagramInstance.layout.orientation = orientation;
  //   diagramInstance.dataBind();
  //   diagramInstance.doLayout();
  //   target.classList.add('e-selected-style');
  // }
  return (
    <div className="control-pane">
      <div className="col-lg-12 control-section">
        <div className="content-wrapper" style={{ width: "100%" }}>
          <div>
            <ToolbarComponent
              // ref={(toolbar) => (toolbarEditor = toolbar)}
              id="toolbar_diagram"
              clicked={tooledit}
              // clicked={onItemClick}
            >
              <ItemsDirective>
                <ItemDirective
                  prefixIcon="e-icons e-zoom-in"
                  tooltipText="Zoom In"
                />
                <ItemDirective
                  prefixIcon="e-icons e-zoom-out"
                  tooltipText="Zoom Out"
                />
                <ItemDirective type="Separator" />
                <ItemDirective
                  prefixIcon="e-icons e-mouse-pointer"
                  tooltipText="Select"
                />
                <ItemDirective
                  prefixIcon="e-icons e-pan"
                  tooltipText="Pan Tool"
                />
                <ItemDirective type="Separator" />
                <ItemDirective
                  prefixIcon="e-icons e-reset"
                  tooltipText="Reset"
                />
                <ItemDirective
                  prefixIcon="e-icons e-zoom-to-fit"
                  tooltipText="Fit To Page"
                />
                {/* <ItemDirective type="Separator" />
                <ItemDirective
                  prefixIcon="e-icons e-bring-to-view"
                  tooltipText="Bring Into View"
                  // disabled={true}
                />
                <ItemDirective
                  prefixIcon="e-icons e-bring-to-center"
                  tooltipText="Bring Into Center"
                  // disabled={true}
                /> */}
                <ItemDirective
                  type="Input"
                  text="Export"
                  tooltipText="Export"
                  template={contentTemplate}
                ></ItemDirective>
                {/* <ItemDirective
                  type={"Button"}
                  text="Print"
                  tooltipText="Print"
                  prefixIcon="e-diagram-icons e-diagram-print"
                /> */}
                {/* <ItemDirective type={"Input"} template={checkboxTemplate} /> */}
              </ItemsDirective>
            </ToolbarComponent>
          </div>
          <div>
            <DiagramComponent
              id="diagram"
              ref={(diagram) => (diagramInstance = diagram)}
              width={"100%"}
              height={"590px"}
              snapSettings={{ constraints: SnapConstraints.None }} //Configrues organizational chart layout
              layout={{
                type: "OrganizationalChart",
                margin: { top: 20 },
                getLayoutInfo: (node, tree) => {
                  if (!tree.hasSubTree) {
                    tree.orientation = "Vertical";
                    tree.type = "Alternate";
                  }
                },
              }}
              // selectionChange={(args) => {
              //   if (args.state === "Changed") {
              //     let selectedItems = diagramInstance.selectedItems.nodes;
              //     if (selectedItems.length === 0) {
              //       toolbarEditor.items[9].disabled = true;
              //       toolbarEditor.items[10].disabled = true;
              //     }
              //     if (selectedItems.length === 1) {
              //       toolbarEditor.items[9].disabled = true;
              //       toolbarEditor.items[10].disabled = true;
              //     }
              //     if (selectedItems.length > 1) {
              //       toolbarEditor.items[9].disabled = true;
              //       toolbarEditor.items[10].disabled = true;
              //     }
              //   }
              // }}
              //Sets the parent and child relationship of DataSource.
              dataSourceSettings={{
                id: "Id",
                parentId: "ReportingPerson",
                dataSource: new DataManager(orgData),
              }} //Sets the default values of Node
              getNodeDefaults={(obj, diagram) => {
                // obj.height = 50;
                // obj.style = { fill: "transparent", strokeWidth: 2 };
                return nodeDefaults(obj, diagram);
                // return obj;
              }} //Sets the default values of connector
              // getConnectorDefaults={(connector, diagram) => {
              //   connector.targetDecorator.shape = "None";
              //   connector.type = "Orthogonal";
              //   return connector;
              // }}
              getConnectorDefaults={(connector, diagram) => {
                return connectorDefaults(connector, diagram);
              }}
              //customization of the node.
              setNodeTemplate={(obj, diagram) => {
                return setNodeTemplate(obj, diagram);
              }}
            >
              <Inject
                services={[DataBinding, HierarchicalTree, PrintAndExport]}
              />
            </DiagramComponent>
          </div>
          {/* <div className="row" style={{ paddingTop: "8px" }}>
            <CheckBoxComponent
              checked={false}
              label="Expandable"
              change={onChange.bind(this)}
            ></CheckBoxComponent>
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default SyncOrgHierarchy;
