import LoadImage from "../CommonComponents/LoadImage";

const headerStyle = {
    backgroundColor: "rgb(243,240,249)",
    fontWeight: "bold",
    fontFamily:
        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
}
const styleValue = {
    borderColor: "white",
    fontFamily:
        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
}
export const agencyTableColumns = ({ handleEdit, handleActivate, handleDeactivate }) => {
    // const csvPriceFormatter  = (cell, row) => {
    //     return `${row.name}: ${cell}                      `;
    // }
    return [{
        text: "Name",
        dataField: "agencyName",
        width: 130,
        align: "center",
        headerAlign: "center",
        headerStyle: headerStyle,
        style: {
            fontWeight: 'bold',
            borderColor: "white",
            fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
        formatter: (col, row) => {
            return (
                <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: "15px" }}
                >
                    <LoadImage source={row.image} />{" "}
                    <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.agencyName}</p>
                </div>
            );
        }
    },
    {
        text: "Contact No",
        dataField: "mobileNumber",
        align: "center",
        headerAlign: "center",
        headerStyle: headerStyle,
        style: styleValue
    },
    {
        text: "Email",
        dataField: "email",
        align: "center",
        headerAlign: "center",
        headerStyle: headerStyle,
        style: styleValue
    },
    {
        text: "Office Address",
        dataField: "officeAddress",
        align: "center",
        headerAlign: "center",
        headerStyle: headerStyle,
        style: styleValue,
        searchable: false
    },
    {
        text: "Status",
        dataField: "status",
        align: "center",
        headerAlign: "center",
        headerStyle: headerStyle,
        style: styleValue,
        searchable: false,
        formatter: (col, row) => {
            if (row.status === "ACTIVE") {
                return (
                    <span className="badge badge-rounded badge-success">Active</span>
                );
            } else {
                return (
                    <span className="badge badge-rounded badge-danger">Inactive</span>
                );
            }
        },
    },
    {
        text: "",
        dataField: "action",
        align: "center",
        headerAlign: "center",
        headerStyle: headerStyle,
        style: styleValue,
        csvExport: false,
        formatter: (cell, row) => {
            if (row.status === "ACTIVE4") {
                return <></>;
            } else {
                return (
                    <div className="d-flex">
                        <span><i class={row.status === 'ACTIVE' ? "fa fa-edit fa-2x text-primary me-2 " :"fa fa-edit fa-2x text-primary me-2 fa-edit-disabled"} style={{ cursor: 'pointer' }} onClick={handleEdit.bind(this, row)}></i></span>
                        <span>{row.status === 'ACTIVE' ? <i class="fa fa-2x fa-toggle-on me-2" style={{ cursor: 'pointer', color: 'green' }} onClick={handleDeactivate.bind(this, row)}></i>
                            : <i class="fa fa-2x fa-toggle-off  me-2" style={{ cursor: 'pointer', color: 'red' }} onClick={handleActivate.bind(this, row)}></i>}
                        </span>
                    </div>
                );
            }
        }
    }

    ]
} 