import { EpochToDate } from "../../CommonComponents/Helper";
import { hasAccess } from "../../services/AuthService";
// import { convertToLocalDateTime2 } from "../utils";

function OfficeFiAllocationPdfReport(props) {
  const styles = {
    headerText: {
      justifyContent: "center",
      backgroundColor: "rgb(255 227 139)",
      width: "100%",
      fontWeight: "bold",
      padding: 7,
      fontSize: "10px",
      textAlign: "center",
    },
    table: {
      width: "100%",
      border: "1px solid black",
    },

    td: {
      padding: 3,
      border: "1px solid black",
      fontSize: "9px",
      width: "25%",
      fontWeight: "bold",
    },
    tdValue: {
      padding: 3,
      border: "1px solid black",
      color: "blue",
      fontSize: "9px",
      width: "25%",
      fontWeight: "bold",
    },
    imageAlign: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
      //   paddingTop: 30,
    },
    headerLogo: {
      width: 50,
      height: 50,
      float: "right",
      marginBottom: 5,
      clear: "both",
    },
    footerLogo: {
      width: 30,
      height: 30,
      float: "right",
      marginBottom: 5,
      borderRadius: "50%",
      clear: "both",
    },
  };
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const {
    allocationsDetails,
    selectedRow,
    agencyRemarks,
    type,
    employeeVisitsData,
    imageOneUrl,
    imageTwoUrl,
    imageThreeUrl,
    imageFourUrl,
    imageFiveUrl,
    dedupeSignatureUrl,
  } = props;
  let data = allocationsDetails.length > 0 ? allocationsDetails[0] : null;
  console.log(data);
  let remarks = agencyRemarks.length > 0 ? agencyRemarks[0] : null;
  let employeeVisits =
    employeeVisitsData.length > 0 ? employeeVisitsData[0] : null;
  return (
    <div>
      {data !== null ? (
        <div id="pdf" style={{ display: "none" }}>
          <div className="container">
            <br />
            <div>
              <img
                src={userData.autoFinanceOrganization.organizationImage}
                style={styles.headerLogo}
                alt="logo"
              ></img>
            </div>
            <br />
            <hr style={{ marginTop: 40 }} />
            <table style={styles.table}>
              <tr>
              <td style={styles.headerText}>
                  <div style={{display:'flex', flexDirection:'column',rowGap:3}}>
                  <span>{userData.autoFinanceOrganization.organizationName}{" "}</span>
                  <span>{userData.autoFinanceOrganization.address}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={styles.headerText}>
                  Field Investigation report of Applicant Office for{" "}
                  {type === "EMPLOYEES"
                    ? selectedRow.client
                    : selectedRow.agency_name}
                </td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Application Number</td>
                <td style={styles.tdValue}>{selectedRow.application_number}</td>
                <td style={styles.td}>Client</td>
                <td style={styles.tdValue}>{selectedRow.client}</td>
              </tr>
              <tr>
                <td style={styles.td}>FI Type</td>
                <td style={styles.tdValue}>{selectedRow.fi_type}</td>
                <td style={styles.td}>Loan Type/Product Type</td>
                <td style={styles.tdValue}>
                  {selectedRow.product_type
                    ? selectedRow.product_type
                    : selectedRow.loan_type}
                </td>
              </tr>
              <tr>
                {/* <td style={styles.td}>Product Name</td>
                <td style={styles.tdValue}>{selectedRow.product_name} </td> */}
                <td style={styles.td}>CPV Verification Geo Limit</td>
                <td style={styles.tdValue} colSpan={3}>
                  {selectedRow.cpv_verification_geo_limit}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Client State</td>
                <td style={styles.tdValue}>{selectedRow.client_state}</td>
                <td style={styles.td}>Client Branch</td>
                <td style={styles.tdValue}>{selectedRow.client_branch}</td>
              </tr>
              <tr>
                <td style={styles.td}>Request Date & Time</td>
                <td style={styles.tdValue}>
                  {selectedRow.created_time
                    ? EpochToDate(selectedRow.created_time)
                    : ""}
                </td>
                <td style={styles.td}>DateTime Of Report </td>
                <td style={styles.tdValue}>
                  {data.date_of_report && data.date_of_report !== "0"
                    ? EpochToDate(data?.date_of_report)
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>DateTime Of Visit</td>
                <td style={styles.tdValue}>
                  {employeeVisits && employeeVisits.date_time
                    ? EpochToDate(employeeVisits.date_time)
                    : ""}
                </td>
                <td style={styles.td}>FI Submitted EMP ID/Name</td>
                <td style={styles.tdValue}>
                  {selectedRow.employee_id + " - " + selectedRow.emp_name}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Verifier's Name</td>
                <td style={styles.tdValue}>
                  {data.verifiers_emp_id
                    ? data.verifiers_emp_id + " - " + data.verifiers_name
                    : ""}
                </td>
                <td style={styles.td}>No. of Visits</td>
                <td style={styles.tdValue}>{data.no_of_visits}</td>
              </tr>
              <tr>
                <td style={styles.td}>Latitude</td>
                <td style={styles.tdValue}>
                  {employeeVisits && employeeVisits.latitude}
                </td>
                <td style={styles.td}>Longitude </td>
                <td style={styles.tdValue}>
                  {employeeVisits && employeeVisits.longitude}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Visit Address</td>
                <td style={styles.tdValue} colSpan={3}>
                  {hasAccess(104)
                    ? selectedRow.address_line1
                    : employeeVisits && employeeVisits.address}
                </td>

                {/* <td style={styles.td}></td>
                <td style={styles.tdValue}></td> */}
              </tr>
            </table>

            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>Primary Information</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Applicant's Name:</td>
                <td style={styles.tdValue}>{selectedRow.customer_name}</td>
                <td style={styles.td}>Applicant Type</td>
                <td style={styles.tdValue}>{data.applicant_type}</td>
              </tr>
              <tr>
                <td style={styles.td}>Office/Bussiness Address</td>
                <td style={styles.tdValue} colSpan={3}>
                  {selectedRow?.address_line1}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>City</td>
                <td style={styles.tdValue}>{selectedRow.city}</td>
                <td style={styles.td}>State</td>
                <td style={styles.tdValue}>{selectedRow.state}</td>
              </tr>
              <tr>
                <td style={styles.td}>Landmark</td>
                <td style={styles.tdValue}>{selectedRow.nearby_landmark}</td>
                <td style={styles.td}>Pin Code</td>
                <td style={styles.tdValue}>{selectedRow.postal_code}</td>
              </tr>
              <tr>
                <td style={styles.td}>Contact Number</td>
                <td style={styles.tdValue}>{selectedRow.contact_no}</td>
                <td style={styles.td}>Actual Address Verification Done</td>
                <td style={styles.tdValue}>{data.actual_address_verified}</td>
              </tr>

              <tr>
                <td style={styles.td}>Entry Allowed Inside the Office </td>
                <td style={styles.tdValue}>{data.office_entry_allowed}</td>
                <td style={styles.td}>Distance</td>
                <td style={styles.tdValue}>
                  {data.distance ? data.distance + "Kms" : data.distance}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Applicant Profile</td>
                <td style={styles.tdValue}>{data.applicant_profile}</td>
                <td style={styles.td}>Office/Business Name</td>
                <td style={styles.tdValue}>{selectedRow?.office_name}</td>
              </tr>
              <tr>
                <td style={styles.td}>Designation Of The Applicant</td>
                <td style={styles.tdValue}>
                  {selectedRow.customer_designation}
                </td>
                <td style={styles.td}>Applicant's Name Confirmed</td>
                <td style={styles.tdValue}>{data?.applicant_name_confirmed}</td>
              </tr>
              <tr>
                <td style={styles.td}>Applicant's Designation Confirmed</td>
                <td style={styles.tdValue}>
                  {data.applicant_designation_confirmed}
                </td>
                <td style={styles.td}>Contacted Person Name</td>
                <td style={styles.tdValue}>{data.contacted_person_name}</td>
              </tr>

              <tr>
                <td style={styles.td}>Designation Of Person Contacted</td>
                <td style={styles.tdValue}>
                  {data.designation_of_person_contacted}
                </td>
                <td style={styles.td}>Relationship with the applicant</td>
                <td style={styles.tdValue}>
                  {data.relationship_with_applicant}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>End User of the Product </td>
                <td style={styles.tdValue}>{data.enduser_of_product}</td>
                <td style={styles.td}>Is It Residence Cum Office</td>
                <td style={styles.tdValue}>
                  {data.is_it_residence_cum_office}
                </td>
              </tr>
              <tr>
                <td style={styles.td} colSpan={2}>
                  Any other loans? If Yes, pls provide details of product,
                  company, amount and EMI
                </td>
                {data.other_loans_info === "Yes" ? (
                  <td style={styles.tdValue} colSpan={2}>
                    {data.other_loans_product},{data.other_loans_loan_no},
                    {data.other_loans_amount}, {data.other_loans_emi}
                  </td>
                ) : (
                  <td style={styles.tdValue} colSpan={2}>
                    NA
                  </td>
                )}
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>
                  Employment /Bussiness Information
                </td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Type of Employment/Organization</td>
                <td style={styles.tdValue}>{data.type_of_org}</td>
                <td style={styles.td}>Business Constitution</td>
                <td style={styles.tdValue}>{data.business_constitution}</td>
              </tr>
              <tr>
                <td style={styles.td}>Nature Of Business/Job Description</td>
                <td style={styles.tdValue}>{data.nature_of_business}</td>
                <td style={styles.td}>No of Years in Employment/Business</td>
                <td style={styles.tdValue}>{data.no_of_years_emp}</td>
              </tr>
              <tr>
                <td style={styles.td}>
                  Total Employment/Bussiness Stability(in Years and Months)
                </td>
                <td style={styles.tdValue}>{data.total_employeement}</td>
                <td style={styles.td}>Net/Gross Monthly Income</td>
                <td style={styles.tdValue}>{data.gmi}</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>
                  Verifier's Inference On Office
                </td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Office Name/Bussiness Sign Board Seen</td>
                <td style={styles.tdValue}>{data.sign_board_seen}</td>
                <td style={styles.td}>Nearest Landmark</td>
                <td style={styles.tdValue}>{data.nearest_land_mark}</td>
              </tr>
              <tr>
                <td style={styles.td}>Area Locality</td>
                <td style={styles.tdValue}>{data.area_locality}</td>
                <td style={styles.td}>Locating Address</td>
                <td style={styles.tdValue}>{data.house_location}</td>
              </tr>
              <tr>
                <td style={styles.td}>Location Of Office</td>
                <td style={styles.tdValue}>{data.location_of_office}</td>
                <td style={styles.td}>Office Interior Condition</td>
                <td style={styles.tdValue}>{data.interior_condition}</td>
              </tr>
              <tr>
                <td style={styles.td}>Office Exterior Condition</td>
                <td style={styles.tdValue}>{data.exterior_condition}</td>
                <td style={styles.td}>Approx Area OF Office(in Sq.Ft)</td>
                <td style={styles.tdValue}>{data.residence_carpet_area}</td>
              </tr>
              <tr>
                <td style={styles.td}>Type Of Job</td>
                <td style={styles.tdValue}>{data.type_of_job}</td>
                <td style={styles.td}>Type Of Office</td>
                <td style={styles.tdValue}>{data.type_of_office}</td>

                {/* <td style={styles.td}>Area Around Office</td>
                <td style={styles.tdValue}>{data.area_around_office}</td> */}
              </tr>
              <tr>
                <td style={styles.td}>Visiting Card Obtained</td>
                <td style={styles.tdValue}>{data.visiting_card_obtaines}</td>
                <td style={styles.td}>BIZ Activity</td>
                <td style={styles.tdValue}>{data.biz_activity}</td>
              </tr>
              <tr>
                <td style={styles.td}>No.of Employees</td>
                <td style={styles.tdValue}>
                  {data.no_of_employees ? data.no_of_employees : ""}
                </td>
                <td style={styles.td}>
                  No.of Employees Actually Seen at Office
                </td>
                <td style={styles.tdValue}>
                  {data.employees_seen_count ? data.employees_seen_count : ""}
                </td>
              </tr>
              <tr></tr>

              <tr>
                <td style={styles.td}>Stock Seen</td>
                <td style={styles.tdValue}>{data.stock_seen}</td>
                <td style={styles.td}>Asset/Vehicle Seen at Office</td>
                <td style={styles.tdValue}>{data.vehicle_seen_at_residence}</td>
              </tr>

              <tr>
                <td style={styles.td}>List the Assets at Office</td>
                <td style={styles.tdValue}>{data.residence_assets}</td>
                <td style={styles.td}>
                  Affiliation Seen for any Political Party
                </td>
                <td style={styles.tdValue}>
                  {data.political_party_affilication}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Affiliation Political Party Remarks</td>
                <td style={styles.tdValue}>{data.political_remarks}</td>
                <td style={styles.td}>Caution Profile</td>
                <td style={styles.tdValue}>{data.caution_profile}</td>
              </tr>
              <tr>
                <td style={styles.td}>Caution Profile Remarks</td>
                <td style={styles.tdValue} colSpan={3}>
                  {data.caution_profile_remarks}
                </td>
              </tr>
            </table>

            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <br />
              <span
                style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
              >
                Powered By
              </span>{" "}
              <img
                src="../logo192.png"
                style={styles.footerLogo}
                alt="logo"
              ></img>
            </div>
          </div>
          <div className="container" id="remarks" style={{ height: 1000 }}>
            <br />
            <div>
              <img
                src={userData.autoFinanceOrganization.organizationImage}
                style={styles.headerLogo}
                alt="logo"
              ></img>
            </div>
            <hr style={{ marginTop: 60 }} />

            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>TPC/Neighbour Check</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>
                  Neighbour/ Third Party Reference 1 Name
                </td>
                <td style={styles.tdValue}>{data.neighbor_reference1}</td>
                <td style={styles.td}>
                  Neighbor/ Third Party Reference 2 Name
                </td>
                <td style={styles.tdValue}>{data.neighbor_reference2}</td>
              </tr>
              <tr>
                <td style={styles.td}>Neighbour/ThirdParty 1 Feedback</td>
                <td style={styles.tdValue}>
                  {data.neighbor_reference1_confirmation}
                </td>
                <td style={styles.td}>Neighbour/ThirdParty 2 Feedback</td>
                <td style={styles.tdValue}>
                  {data.neighbor_reference2_confirmation}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Neighbour/TP 1 check status-bar</td>
                <td style={styles.tdValue}>
                  {"Tpc colleague is " +
                    data.neighbor_reference1 +
                    " has confirmed the Applicant name and Working details"}
                </td>
                <td style={styles.td}>Neighbour/TP 2 check status-bar</td>
                <td style={styles.tdValue}>
                  {"Tpc colleague is " +
                    data.neighbor_reference2 +
                    " has confirmed the Applicant name and Working details"}
                </td>
              </tr>
            </table>
            {data.door_locked_status === "Yes" && (
              <table style={styles.table}>
                <tr>
                  <td style={styles.headerText}>
                    - IF Entry Denied / Door locked, then Third Party's
                    confirmation on -
                  </td>
                </tr>
              </table>
            )}
            {data.door_locked_status === "Yes" && (
              <table style={styles.table}>
                <tr>
                  <td style={styles.td}>
                    • App’s stay, Ownership status, Stability and Contacted
                    Person Number
                  </td>
                  <td style={styles.tdValue}>
                    {data.door_locked_status === "Yes"
                      ? data.neighbor_conf_address +
                        ", " +
                        data.neighbor_conf_ownership +
                        ", " +
                        data.neighbor_conf_years_stay +
                        ", " +
                        data.contacted_person_number
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td style={styles.td}>
                    {" "}
                    • Family members and App’s approx age
                  </td>
                  <td style={styles.tdValue}>
                    {data.door_locked_status === "Yes"
                      ? data.neighbor_conf_family_count +
                        ", " +
                        data.neighbor_conf_applicant_age +
                        " years"
                      : "NA"}
                  </td>
                </tr>
              </table>
            )}
            {data.calls_made_to_custoomer !== 0 && (
              <table style={styles.table}>
                <tr>
                  <td style={styles.headerText}>Address Untraceble</td>
                </tr>
              </table>
            )}
            {data.calls_made_to_custoomer !== 0 && (
              <table style={styles.table}>
                <tr>
                  <td style={styles.td}>No. Of Calls Made To Customer</td>
                  <td style={styles.tdValue} colSpan={3}>
                    {data.calls_made_to_custoomer
                      ? data.calls_made_to_custoomer
                      : ""}
                  </td>
                  {/* <td style={styles.td}>Remarks 1</td>
                <td style={styles.tdValue}>{remarks !== null && remarks.remarks_1}</td> */}
                </tr>
              </table>
            )}

            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>FI Agency's Remarks:</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Agency Remarks 1</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_1}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 2</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_2}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 3</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_3}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 4</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_4}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 5</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_5}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 6</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_6}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 7</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_7}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 8</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_8}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 9</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_9}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 10</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_10}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Video Url</td>
                <td style={styles.tdValue}>{data.video_1}</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>
                  {" "}
                  {data.dedupe_check_on_negative ||
                  data.dedupe_result ||
                  data.dedupe_negative_reason1 ||
                  data.dedupe_negative_reason2
                    ? "Dedupe Check"
                    : "FI Result"}
                </td>
              </tr>
            </table>
            <table style={styles.table}>
              {(data.dedupe_check_on_negative ||
                data.dedupe_result ||
                data.dedupe_negative_reason1 ||
                data.dedupe_negative_reason2) && (
                <>
                  <tr>
                    <td style={styles.td}>
                      Dedupe Check conducted on Negative Database
                    </td>
                    <td style={styles.tdValue}>
                      {data.dedupe_check_on_negative}
                    </td>
                    <td style={styles.td}>Dedupe Result</td>
                    <td style={styles.tdValue}>{data.dedupe_result}</td>
                  </tr>
                  <tr>
                    <td style={styles.td}>If Negative, Reason 1 Negative FI</td>
                    <td style={styles.tdValue}>
                      {data.dedupe_negative_reason1}
                    </td>
                    <td style={styles.td}>If Negative, Reason 2 Negative FI</td>
                    <td style={styles.tdValue}>
                      {data.dedupe_negative_reason2}
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td style={styles.td}>FI Result </td>
                <td style={styles.tdValue}>{data.dedupe_residence_result}</td>
                <td style={styles.td}>Remarks</td>
                <td style={styles.tdValue}>{data.dedupe_remarks}</td>
              </tr>
              <tr>
                <td style={styles.td}>Signature & Seal of Agency</td>
                <td style={styles.tdValue}>
                  {" "}
                  <img
                    crossorigin="*"
                    src={dedupeSignatureUrl}
                    alt="dedupe-signature"
                    style={styles.imageAlign}
                  />
                </td>
                <td style={styles.td}></td>
                <td style={styles.tdValue}></td>
              </tr>
            </table>
            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <br />
              <span
                style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
              >
                Powered By
              </span>{" "}
              <img
                src="../logo192.png"
                style={styles.footerLogo}
                alt="logo"
              ></img>
            </div>
          </div>

          {imageOneUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageOneUrl}
                alt="fi-one"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
          {imageTwoUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageTwoUrl}
                alt="fi-two"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
          {imageThreeUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageThreeUrl}
                alt="fi-three"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
          {imageFourUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageFourUrl}
                alt="fi-three"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
          {imageFiveUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageFiveUrl}
                alt="fi-three"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div id="pdf"></div>
      )}
    </div>
  );
}

export default OfficeFiAllocationPdfReport;
