import { Button, Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import swal from "sweetalert";
// eslint-disable-next-line
import ReactExport from "react-data-export";
import NO_DATA_FOUND from "../images/no-data-found.png";
import { agencyTableColumns } from "./AgencyTableColumns";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import AddAgency from "./AddAgency";
import { apiCall } from "../rest/restApi";
import * as nodeApi from "../rest/nodeApi";
import { toaster } from "../rest/apiUtils";
import "./agencies.css";
import ReactGA from "react-ga";

function Agencies() {
  const [addAgencyModal, setAddAgencyModal] = useState(false);
  const [agenciesList, setAgenciesList] = useState([]);
  const [editPopulateData, setEditPopulateData] = useState({});
  const [action, setAction] = useState("");
  const [noDataFound, setNoDataFound] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const { SearchBar } = Search;
  const options = {
    hideSizePerPage: true,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    disablePageTitle: true,
  };

  useEffect(() => {
    getAgenciesData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgenciesData = () => {
    var userLoginData = JSON.parse(localStorage.getItem("userDetails"));
    const organizationId =
      userLoginData.autoFinanceUser &&
      userLoginData.autoFinanceUser.organizationId;
    nodeApi
      .getData(apiCall.agencies, "/" + organizationId)
      .then((response) => {
        handleAgenciesResponse(response);
      })
      .catch((error) => {
        setShowLoader(false);
        setAgenciesList([]);
        setNoDataFound(true);
      });
  };

  const handleAgenciesResponse = (response) => {
    if (response.success === true) {
      if (response.agencies) {
        setAgenciesList(response.agencies);
        if (response.agencies.length > 0) {
          setNoDataFound(false);
          setShowLoader(false);
        } else {
          setNoDataFound(true);
          setShowLoader(false);
        }
      }
    } else {
      setShowLoader(false);
      setNoDataFound(true);
    }
  };

  const handleClose = () => {
    setAddAgencyModal(false);
  };

  const handleEdit = (row) => {
    setAction("EDIT");
    setEditPopulateData(row);
    setAddAgencyModal(true);
  };

  const handleActivate = (row) => {
    nodeApi
      .deleteData(apiCall.activateAgencies, row.agencyId)
      .then((response) => {
        getAgenciesData();
        toaster("success", "Successfully Activated");
      })
      .catch((error) => {
        toaster("error", "Something Went Wrong, Please try again later");
      });
  };

  const handleDeactivate = (row) => {
    nodeApi
      .getData(apiCall.agencies, "/allocations/" + row.agencyId)
      .then((response) => {
        if (response.allocations && response.allocations.length > 0) {
          if (response.allocations[0].count > 0) {
            swal({
              title: "Delete",
              text:
                "There are " +
                response.allocations[0].count +
                " Visit(s) pending with the Agency. The visits need to be Re-Uploaded and Assigned to the New Agency/Executives, for Further Action. Are you Sure to Delete/Inactive the Agency?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        } else {
          nodeApi
            .deleteData(apiCall.deactivateAgencies, row.agencyId)
            .then((response) => {
              getAgenciesData();
              toaster("success", "Successfully Deactivated");
            })
            .catch((error) => {
              toaster("error", "Something Went Wrong, Please try again later");
            });
        }
      })
      .catch((error) => {
        toaster("error", "Something Went Wrong, Please try again later");
      });
  };

  const handleAdd = () => {
    setAction("ADD");
    setAddAgencyModal(true);
  };

  const indication = (props) => {
    return props.searchText !== "" ? (
      <div className="d-flex justify-content-center">
        <img src={NO_DATA_FOUND} alt="" />
      </div>
    ) : (
      <></>
    );
  };
  // eslint-disable-next-line
  const ExcelFile = ReactExport.ExcelFile;
  // eslint-disable-next-line
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  let excelFormattedData = [];
  if (agenciesList.length > 0) {
    agenciesList.map((item) => {
      let obj = [
        { value: item.agencyName },
        { value: item.mobileNumber },
        { value: item.email },
        { value: item.officeAddress },
        { value: item.status },
      ];
      return excelFormattedData.push(obj);
    });
  }

  const agenciesData = [
    {
      columns: [
        {
          title: "Agency Name",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        }, //pixels width
        {
          title: "Contact No",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        }, //char width
        {
          title: "Email",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Office Address",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Status",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
      ],
      data: excelFormattedData,
    },
  ];

  return (
    <div className="card">
      <div className="agencies-body"></div>
      <LoadingOverlay
        active={showLoader}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div className="table-content">
          <ToolkitProvider
            keyField="id"
            data={agenciesList}
            columns={agencyTableColumns({
              handleEdit,
              handleActivate,
              handleDeactivate,
            })}
            search
          >
            {(props) => (
              <>
                <div className="agencies-body">
                  <SearchBar
                    {...props.searchProps}
                    placeholder={"Search By Name/Contact/Email"}
                    style={{
                      width: 300,
                      height: 40,
                      borderRadius: 25,
                      borderColor: "#a0a0a0",
                      borderWidth: 1,
                      boxShadow: "1px 1px 2px #3a353b",
                      paddingLeft: 10,
                      backgroundColor: "white",
                    }}
                  />
                  <Button
                    className="me-2 agency-button"
                    variant="primary btn-rounded"
                    onClick={handleAdd}
                  >
                    <b> + Add Agency</b>
                  </Button>

                  <div>
                    <ExcelFile
                      element={
                        <Button
                          className="me-2 agency-button"
                          variant="primary btn-rounded"
                        >
                          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                        </Button>
                      }
                    >
                      <ExcelSheet dataSet={agenciesData} name="Agencies" />
                    </ExcelFile>
                  </div>
                </div>
                <div className="table-container">
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                    noDataIndication={indication({ ...props.searchProps })}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
          {noDataFound === true && noDataFound !== null && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
        </div>
      </LoadingOverlay>
      <Modal className="fade" show={addAgencyModal}>
        <Modal.Header>
          <Modal.Title>
            {action === "ADD" ? "Add Agency" : "Edit Agency"}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddAgencyModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AddAgency
            close={handleClose}
            action={action}
            getAgenciesData={getAgenciesData}
            editPopulateData={editPopulateData}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Agencies;
