import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import callingReducer from './reducers/CallingReducer';
import MyAllocationReducer from './reducers/MyAllocationReducer';
import MySalesAllocationReducer from './reducers/MySalesAllocationReducer'
import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
		todoReducers,
	form: reduxFormReducer,	
	calls: callingReducer,
    myAllocations: MyAllocationReducer,
    mySalesAllocations:MySalesAllocationReducer
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
