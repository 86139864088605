import React, { useEffect, useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Button, Spinner } from "react-bootstrap";
import TextField from "../CommonComponents/TextField";
import { getTokenInLocalStorage } from "../services/AuthService";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
const AddSalesTeam = ({ close }) => {
  let userLoginDetails = getTokenInLocalStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [branchNames, setBranchNames] = useState(null);
  const [selectedExecutives, setSelectedExecutives] = useState([]);
  const [selectedExecutives2, setSelectedExecutives2] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedManagers2, setSelectedManagers2] = useState([]);
  const [selectedManagers_Ra, setSelectedManagers_Ra] = useState([]);
  const [selectedManagers_Ra2, setSelectedManagers_Ra2] = useState([]);
  const [executives, setExecutives] = useState([]);
  const [managers, setManagers] = useState([]);
  const [managers_Ra, setManagers_Ra] = useState([]);

  useEffect(() => {
    getBranches();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getUsers();
    // getUsers("MANAGER");
    // getUsers("MANAGER_RA");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchNames !== null]);

  // const userOptions = [
  //   { label: "Managers", value: "MANAGER" },
  //   { label: "Executives", value: "EXECUTIVE" },
  // ];

  const initialValues = {
    teamName: "",
    teamType: "",
    role: "",
    callCount: '',
    description: "",
  };

  const getBranches = () => {
    const body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
    };
    moduleApi.postData(apiCall.getBranches, body).then((response) => {
      const result = response.map((item) => ({
        branchId: item.branchId,
        branchName: item.branchName,
      }));
      setBranchNames(result);
    });
  };

  const getUsers = () => {
    const payLoad = {
      branchId: "",
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      roleName: "MANAGER",
      status: "ACTIVE",
    };
    moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
      const payLoad = {
        branchId: "",
        organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
        roleName: "EXECUTIVE",
        status: "ACTIVE",
      };
      moduleApi.postData(apiCall.usersList, payLoad).then((response2) => {
        const payLoad = {
          branchId: "",
          organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
          roleName: "MANAGER_RA",
          status: "ACTIVE",
        };
        moduleApi.postData(apiCall.usersList, payLoad).then((response3) => {
          const result = response.map((item) => ({
            value: item.userId,
            label: item.name + " - " + getBranch(item.branchId),
            branchId: item.branchId,
          }));
          const result2 = response2.map((item) => ({
            value: item.userId,
            label: item.name + " - " + getBranch(item.branchId),
            branchId: item.branchId,
          }));
          const result3 = response3.map((item) => ({
            value: item.userId,
            label: item.name + " - " + getBranch(item.branchId),
            branchId: item.branchId,
          }));
          // if (role === "MANAGER") {
          setManagers(result);
          // } else if (role === "EXECUTIVE") {
          setExecutives(result2);
          // }else {
          setManagers_Ra(result3);
          // }
        });
      });
    });
  };

  const getBranch = (branchId) => {
    for (let i = 0; i < branchNames?.length; i++) {
      if (branchNames[i].branchId === branchId) {
        // console.log("branchName", branchNames[i].branchName);
        return branchNames[i].branchName;
      }
    }
  };

  const callCountRegex = /^\d+(\.\d+)?$/

  const validationSchema = Yup.object({
    teamName: Yup.string().required("Team Name is required"),
    teamType: Yup.string().trim().required("Team Type is required"),
    callCount: Yup.string().matches(callCountRegex, "Call Count is not valid").required("Call Count is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = (values) => {
    // console.log(opportunityData);
    setIsLoading(true)
    if (
      selectedManagers_Ra?.length === 0
    ) {
      setIsLoading(false)
      return swal(
        "Please Select Atleast One Team Member in Telecallers",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }
    var payload = {
      description: values.description,
      callCount: values.callCount,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      teamName: values.teamName,
      teamType: values.teamType,
      userIds: selectedExecutives.concat(selectedManagers, selectedManagers_Ra),
    };
    console.log(payload);
    moduleApi.postData(apiCall.addSalesTeams, payload).then((response) => {
      // console.log("addTeamResponse", response);
      if (response) {
        if (response?.message) {
          setIsLoading(false)
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          close();
          setIsLoading(false)
          return swal("Team Added Successfully", {
            icon: "success",
            dangerMode: false,
          });
        }
      } else {
        setIsLoading(false)
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const getSelectedMgrs2 = (list, role) => {
    let MgrsList = [];
    let MgrsList2 = [];
    for (let i = 0; i < list?.length; i++) {
      MgrsList.push(list[i]);
      MgrsList2.push(list[i].value);
    }
    if (role === "executive") {
      setSelectedExecutives(MgrsList2);
      setSelectedExecutives2(MgrsList);
    } else if (role === "manager") {
      setSelectedManagers(MgrsList2);
      setSelectedManagers2(MgrsList);
    } else {
      setSelectedManagers_Ra(MgrsList2);
      setSelectedManagers_Ra2(MgrsList);
    }
  };

  const teamTypeOptions = [
    { label: "Collections", value: "Collections" },
    { label: "Sales", value: "Sales" },
  ];

  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue, errors }) => (
          <Form>
            <TextField
              isAsterisk={true}
              label="Team Name"
              name="teamName"
              type="text"
            />
            <Field name="teamType">
              {({ field, form }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "5px",
                  }}
                >
                  <label
                    className="control-label"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      marginBottom: "10px",
                    }}
                  >
                    Team Type
                  </label>
                  <Select
                    placeholder="Select Team Type"
                    classNamePrefix="select-box"
                    options={teamTypeOptions}
                    onChange={(selectedOption) => {
                      form.setFieldValue(
                        "teamType",
                        selectedOption.value
                      );
                    }}
                  />
                </div>
              )}
            </Field>
            {errors.teamType && (
              <span className="error">{errors.teamType}</span>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              <label
                //className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                Managers
              </label>
              <Select
                id="managers"
                name="managers"
                options={managers}
                isMulti
                value={selectedManagers2}
                onChange={(list) => {
                  getSelectedMgrs2(list, "manager");
                }}
                placeholder="Select Managers"
              />
              <ErrorMessage name="role" component="div" className="error" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                //className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginTop: "5px",
                }}
              >
                Executives
              </label>
              <Select
                placeholder="Select Executives"
                classNamePrefix="select-box"
                options={executives}
                isMulti
                //   defaultValue={defaultMgr2}
                value={selectedExecutives2}
                //   components={components}
                onChange={(list) => {
                  getSelectedMgrs2(list, "executive");
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginTop: "5px",
                }}
              >
                Telecallers
              </label>
              <Select
                placeholder="Select Telecallers"
                classNamePrefix="select-box"
                options={managers_Ra}
                isMulti
                //   defaultValue={defaultMgr2}
                value={selectedManagers_Ra2}
                //   components={components}
                onChange={(list) => {
                  getSelectedMgrs2(list, "telecaller");
                }}
              />
            </div>

            {/* Description */}
            <TextField
              isAsterisk={true}
              label="Call Count"
              name="callCount"
              type="text"
            />
            <TextField
              isAsterisk={true}
              label="Description"
              name="description"
              type="text"
            />

            <div>
              <Button style={{ float: "right" }} className="me-2" type="submit">
                Submit
              </Button>
              <Button
                style={{ float: "right" }}
                className="me-2"
                variant="danger light"
                onClick={close}
              >
                Close
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </LoadingOverlay>
  );
};

export default AddSalesTeam;
