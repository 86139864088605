import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import defaultImage from "../../../images/defaultImage.png";
import * as moduleApi from "../../../rest/moduleApi";
import { apiCall } from "../../../rest/restApi";
import LogoutPage from "./Logout";
import PIOPIY from "piopiyjs";

import { hasAccess } from "../../../services/AuthService";
import { camelize } from "../../../DB/utils";
import { EpochToDate, sendDataToDB } from "../../../CommonComponents/Helper";
// import { getApp, getApps, initializeApp } from "firebase/app";
import { getToken, onMessage } from "firebase/messaging";
import { CustomToaster } from "../../../rest/apiUtils";
import { getDatabase, ref, child, get, onValue } from "firebase/database";
import TelecallerNotifications from "../../../TelecallerNotifications/TelecallerNotifications";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setTimerCount,
  setIsProgressiveDialing,
  setIsTimerCounting,
} from "../../../store/actions/CallingActions";
import {
  StartTelecalling,
  Logout,
  // CallTransfer,
  // StartCall,
} from "../../../CommonComponents/TelecallingMethods";
import Whatsapp from "../../../Whatsapp/Whatsapp";
import { database, firebaseVapidKey, messaging } from "../../../CommonComponents/Firestore";
// import { doc, getDoc } from "firebase/firestore";
// import firestore from "../../../CommonComponents/Firestore";
// import {
//   collection,
//   onSnapshot,
// } from "@firebase/firestore";
// import { useCollectionData } from "react-firebase-hooks/firestore";

const Header = ({ onNote }) => {
  var piopiy = new PIOPIY({
    name: "Display Name",
    debug: false,
    autoplay: true,
    ringTime: 60,
  });
  const [organisationList, setOrganisationList] = useState([]);
  const [whatsappModal, setWhatsappModal] = useState(false);
  const [organisationFilterIndex, setOrganisationFilterIndex] = useState(0);
  const [timerModal, setTimerModal] = useState(
    JSON.parse(localStorage.getItem("selectedAgentStatus"))?.value !== "online"
      ? true
      : false
  );
  const [isSubStatus, setIsSubStatus] = useState(
    JSON.parse(localStorage.getItem("selectedAgentStatus"))?.value !== "online"
      ? true
      : false
  );

  const [groupBreakTypes, setGroupBreakTypes] = useState([]);
  const [toggle, setToggle] = useState("");

  const [selectedStatus, setSelectedStatus] = useState(
    JSON.parse(localStorage.getItem("selectedAgentStatus"))?.value !== "online"
      ? { label: "Break", value: "break" }
      : JSON.parse(localStorage.getItem("selectedAgentStatus"))
  );
  const [selectedSubStatus, setSelectedSubStatus] = useState(
    JSON.parse(localStorage.getItem("selectedAgentStatus"))?.value === "break"
      ? null
      : JSON.parse(localStorage.getItem("selectedAgentStatus"))
  );
  const [isOptionsDisabled, setIsOptionsDisabled] = useState(false);
  const [notifications, setNotifications] = useState([]);

  var userData = JSON.parse(localStorage.getItem("userDetails"));
  var history = useHistory();
  const userInfo = localStorage.getItem("userDetails");
  const parsedUserInfo = JSON.parse(userInfo);

  const {
    isProgressiveDialing,
    isSalesProgressiveDialing,
    timercount,
    isTimerCounting,
    isTestCall,
    callType,
  } = useSelector((state) => state.calls);
  const dispatch = useDispatch();

  // const app = initializeApp(moduleApi.firebaseConfig);
  // const app = !getApps().length
  //   ? initializeApp(moduleApi.firebaseConfig)
  //   : getApp();
  // const messaging = getMessaging(app);
  const initialState =
    JSON.parse(localStorage.getItem("selectedAgentStatus"))?.value === "online"
      ? { seconds: 0, minutes: 0, hours: 0, isRunning: false }
      : JSON.parse(localStorage.getItem("timerState")) || {
          seconds: 0,
          minutes: 0,
          hours: 0,
          isRunning: false,
        };

  const [seconds, setSeconds] = useState(initialState.seconds);
  const [minutes, setMinutes] = useState(initialState.minutes);
  const [hours, setHours] = useState(initialState.hours);
  const [isRunning, setIsRunning] = useState(initialState.isRunning);
  // const query = collection(firestore, "whatsapp/7036820121/messages");
  //   const [docs, loading, error] = useCollectionData(query);

  useEffect(() => {
    let interval;
    if (
      hasAccess(42) &&
      userData?.autoFinanceUser?.teleCmiUserId !== null &&
      userData?.autoFinanceUser?.teleCmiPassword !== null &&
      userData?.autoFinanceUser?.teleCmiUserId !== "" &&
      userData?.autoFinanceUser?.teleCmiPassword !== ""
    ) {
      if (isRunning) {
        interval = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds === 59) {
              setMinutes((prevMinutes) => {
                if (prevMinutes === 59) {
                  setHours((prevHours) => prevHours + 1);
                  return 0;
                }
                return prevMinutes + 1;
              });
              return 0;
            }
            return prevSeconds + 1;
          });
        }, 1000);
      } else {
        clearInterval(interval);
      }
    }

    localStorage.setItem(
      "timerState",
      JSON.stringify({ seconds, minutes, hours, isRunning })
    );

    return () => clearInterval(interval); // Clean up the interval on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, seconds, minutes, hours]);

  useEffect(() => {
    userData.isSuperAdmin && getAllOrganisations();
    // getBreakTypes();
    if (
      hasAccess(42) &&
      userData?.autoFinanceUser?.teleCmiUserId !== null &&
      userData?.autoFinanceUser?.teleCmiPassword !== null &&
      userData?.autoFinanceUser?.teleCmiUserId !== "" &&
      userData?.autoFinanceUser?.teleCmiPassword !== "" &&
      isRunning === false
    ) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          initializeFirebase();
        }
      });
      setIsSubStatus(false);
      setSelectedSubStatus(null);
      setSelectedStatus({
        label: "Online",
        value: "online",
      });

      StartTelecalling(
        piopiy,
        dispatch,
        userData?.autoFinanceUser?.teleCmiUserId,
        userData?.autoFinanceUser?.teleCmiPassword,
        isTestCall,
        callType
      );
      setIsOptionsDisabled(false);
      localStorage.setItem(
        "selectedAgentStatus",
        JSON.stringify({
          label: "Online",
          value: "online",
        })
      );
      statusUpdate("online");
      updateTodayCallsCount();
      getBreakTypes();
      loadNotifications();
    } else if (
      hasAccess(42) &&
      userData?.autoFinanceUser?.teleCmiUserId !== null &&
      userData?.autoFinanceUser?.teleCmiPassword !== null &&
      userData?.autoFinanceUser?.teleCmiUserId !== "" &&
      userData?.autoFinanceUser?.teleCmiPassword !== "" &&
      isRunning === true
    ) {
      getBreakTypes();
    }

    return () => {
      setGroupBreakTypes([]);
      setNotifications([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // Check if service worker is supported
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.ready.then(registration => {
  //       // Send message to service worker to start the timer
  //       registration.active.postMessage({ command: 'startTimer' });

  //       // Listen for messages from service worker
  //       navigator.serviceWorker.addEventListener('message', event => {
  //         const { remainingTime } = event.data;
  //         dispatch(setTimerCount(remainingTime));
  //       });
  //     });
  //   }

  //   // Cleanup function
  //   return () => {
  //     // Send message to service worker to stop the timer
  //     if ('serviceWorker' in navigator) {
  //       navigator.serviceWorker.ready.then(registration => {
  //         registration.active.postMessage({ command: 'stopTimer' });
  //       });
  //     }
  //   };
  // }, []);

  useEffect(() => {
    let timer;
    if (isTimerCounting) {
      if (timercount > 0) {
        let temp = timercount;
        timer = setInterval(() => {
          dispatch(setTimerCount(temp - 1));
        }, 1000);
      } else {
        dispatch(setIsTimerCounting(false));
      }
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timercount, isTimerCounting]);

  const resetTimer = () => {
    setSeconds(0);
    setMinutes(0);
    setHours(0);
    setIsRunning(false);
    localStorage.removeItem("timerState");
  };

  const updateTodayCallsCount = () => {
    const dbRef = ref(database);
    get(
      child(
        dbRef,
        "agentLiveStatus/" + userData?.autoFinanceUser?.organizationId
      )
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          // console.log(snapshot.val());
          const uniqueObjectsList = Object.values(snapshot.val()).reduce(
            (accumulator, currentValue) => {
              const userId = currentValue.userId;
              if (!accumulator.some((obj) => obj.userId === userId)) {
                accumulator.push(currentValue);
              }
              return accumulator;
            },
            []
          );
          // console.log("temp", uniqueObjectsList);
          uniqueObjectsList.forEach((element) => {
            if (userData?.autoFinanceUser?.userId === element.userId) {
              let currentDayStart = Math.floor(
                new Date(new Date().setHours(0, 0, 0)).getTime() / 1000
              );
              let currentDayEnd = Math.floor(
                new Date(new Date().setHours(23, 59, 59)).getTime() / 1000
              );

              if (
                element.createdTime > currentDayStart &&
                element.createdTime < currentDayEnd
              ) {
                localStorage.setItem(
                  "todayCallsCount",
                  parseInt(
                    element.todayCallsCount ? element.todayCallsCount : 0
                  )
                );
                sendDataToDB(
                  "Idle",
                  parseInt(
                    element.todayCallsCount ? element.todayCallsCount : 0
                  )
                );
              } else {
                localStorage.setItem("todayCallsCount", parseInt(0));
                sendDataToDB("Idle", parseInt(0));
              }
            }
          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const getFirestoreData = async () => {
  //   // console.log(firestore);
  //   onSnapshot(collection(firestore, "whatsapp/7036820121/messages"), (snapshot) => {
  //     console.log(snapshot.docs.map((doc) => doc.data()));
  //   });
  //   // const temp = await GetWhatsappList()
  //   // console.log(temp);

  //   // console.log(docs);
  //   // const temp = await GetWhatsappList("whatsapp");
  //   // console.log(temp);

  //   // const q = query(collection(firestore, "whatsapp", "7036820121", "messages"));
  //   // console.log(q);
  //   // const temp =await getDocs(q);
  //   // let contactsList = [];
  //   // const temp = await GetWhatsappList("whatsapp");
  //   // temp.forEach((doc) => {
  //   //   console.log(doc.id, " => ", doc.data());

  //   //   var dataTemp = {
  //   //     id: doc.id,
  //   //     data: doc.data(),
  //   //   };
  //   //   contactsList.push(dataTemp);
  //   // });
  //   // // contactsList?.forEach((doc) => {
  //   //   const temp2 = await GetWhatsappList("whatsapp/" + contactsList[0]?.id + "/messages");
  //   //   temp2.forEach((doc2) => {
  //   //     console.log(doc2.id, " => ", doc2.data());
  //   //   });
  //   // });

  //   // const docRef = doc(firestore, "whatsapp", "7036820121");
  //   // const docSnap = await getDoc(docRef);

  //   // if (docSnap.exists()) {
  //   //   console.log("Document data:", docSnap.data());
  //   // } else {
  //   //   // docSnap.data() will be undefined in this case
  //   //   console.log("No such document!");
  //   // }

  //   // onSnapshot(collection(firestore, "whatsapp"), (snapshot) => {
  //   //   console.log(snapshot.docs.map((doc) => doc.data()));
  //   // });
  // };
  const formatTime = (time) => (time < 10 ? `0${time}` : time);

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 5 ? name.filter((n, i) => i > 0) : name;

  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  const getAllOrganisations = () => {
    moduleApi.getData(apiCall.getAllOrganisations).then((response) => {
      const result = response.map((item, index) => ({
        value: item.orgshortcode,
        label: item.organizationName,
        index: index,
      }));

      setOrganisationList(result);

      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          ...parsedUserInfo,
          organistaionFilterIndex: parsedUserInfo.organistaionFilterIndex
            ? parsedUserInfo.organistaionFilterIndex
            : 0,
        })
      );
      setOrganisationFilterIndex(
        parsedUserInfo.organistaionFilterIndex
          ? parsedUserInfo.organistaionFilterIndex
          : 0
      );
    });
  };

  const setOrganisationValue = (value) => {
    getOrganisationData(value.value, value.index);
    setOrganisationFilterIndex(value.index);
  };

  const getOrganisationData = (key, index) => {
    moduleApi.getData(apiCall.getOrganisationData + key).then((response) => {
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          ...parsedUserInfo,
          autoFinanceBranchDetails: response.autoFinanceBranchDetails,
          autoFinanceOrganization: response.autoFinanceOrganization,
          autoFinanceUser: response.autoFinanceUser,
          rolePrivileges: response.rolePrivileges,
          organistaionFilterIndex: index,
          isSuperAdmin: true,
        })
      );
      history.push("/home");
      window.location.reload();
    });
  };

  const getBreakTypes = () => {
    setGroupBreakTypes([]);
    moduleApi
      .postData(
        apiCall.getBreakTypes + userData?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        // console.log("response", response);
        // setBreakTypes(response)
        let temp1List = [];
        let temp2List = [];

        response.forEach((element) => {
          if (element.breakType === "Productive") {
            var temp1 = {
              value: element.breakName,
              label: element.breakName,
              isDisabled: isOptionsDisabled,
            };
            temp1List.push(temp1);
          } else if (element.breakType === "NonProductive") {
            var temp2 = {
              value: element.breakName,
              label: element.breakName,
              isDisabled: isOptionsDisabled,
            };
            temp2List.push(temp2);
          }
        });

        // console.log(temp1List);
        // console.log(temp2List);
        var groupTemp = [
          // {
          //   label: "Online",
          //   value: "online",
          // },
          {
            label: "Productive",
            options: temp1List,
          },
          {
            label: "Non-Productive",
            options: temp2List,
          },
        ];
        setGroupBreakTypes(groupTemp);
      });
  };

  let statusOptions = [
    { label: "Online", value: "online" },
    { label: "Break", value: "break" },
  ];

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
    </div>
  );

  const statusUpdate = (value) => {
    var payLoad = {
      breakName: value === "online" ? undefined : value,
      status: value === "online" ? "Online" : "Break",
      // organizationId: userData.autoFinanceUser.organizationId,
      userId: userData.autoFinanceUser.userId,
    };
    // console.log(payLoad);
    moduleApi
      .postData(apiCall.updateAgentLatestStatus, payLoad)
      .then((response) => {
        // console.log(" status response", response);
      });
  };

  const setStatusOptions = (value) => {
    if (value !== "online") {
      // logout();
      setIsSubStatus(true);

      if (value !== "break") {
        resetTimer();
        setTimerModal(true);
        setIsRunning(true);
        setIsOptionsDisabled(true);
        statusUpdate(value);
        Logout(piopiy, dispatch);
        sendDataToDB(camelize(value), localStorage.getItem("todayCallsCount"));
        // loadAgentStatus();
      } else {
        setIsOptionsDisabled(false);
      }
    } else {
      // resetTimer();
      logoutFromTelecmi(piopiy,
        dispatch)
      setTimerModal(false);
      // toggleTimer();
      setIsRunning(false);
      localStorage.removeItem("timerState");
      setIsSubStatus(false);
      setSelectedSubStatus(null);
      setIsOptionsDisabled(true);
      StartTelecalling(
        piopiy,
        dispatch,
        userData?.autoFinanceUser?.teleCmiUserId,
        userData?.autoFinanceUser?.teleCmiPassword,
        isTestCall,
        callType
      );
      sendDataToDB("Idle", localStorage.getItem("todayCallsCount"));
      statusUpdate(value);
    }
  };

  const sendNotificationToPtpPage = () => {
    history.push("/ptp/my-allocations");
  };

  const initializeFirebase = (response) => {
    if (
      hasAccess(42) &&
      userData?.autoFinanceUser?.teleCmiUserId !== null &&
      userData?.autoFinanceUser?.teleCmiPassword !== null &&
      userData?.autoFinanceUser?.teleCmiUserId !== "" &&
      userData?.autoFinanceUser?.teleCmiPassword !== ""
    ) {
      getToken(messaging, {
        vapidKey: firebaseVapidKey,
      }).then((currentToken) => {
        // console.log("token", currentToken);
        var payload = {
          fcmToken: currentToken,
          userId: userData?.autoFinanceUser?.userId,
        };
        moduleApi
          .postData(apiCall.updateFcmToken, payload)
          .then((response2) => {
            console.log("tokenResponse", response2);
          });
      });
    }

    onMessage(messaging, (payload) => {
      // console.log("Message received. ", payload);
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: "/logo192.png", // This will only work when the webpage is opened. If you always want to show an image you should fetch it via URL.
        tag: "custom-notification",
      };
      CustomToaster(
        "dark",
        notificationTitle,
        notificationOptions.body,
        sendNotificationToPtpPage
      );
    });
  };

  const closeDrawer = () => {
    setToggle(false);
  };

  const loadNotifications = () => {
    // let listData = [];
    setNotifications([]);
    const db = getDatabase();
    const starCountRef = ref(db, "web_notifications/");
    // console.log("starCountRef ", starCountRef);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot?.val();
      // console.log("dataFromDB", data);
      if (data) {
        const uniqueObjectsList = Object?.values(data);
        // console.log(uniqueObjectsList);
        let listData = [];
        uniqueObjectsList?.forEach((item) => {
          if (
            item.userId === userData?.autoFinanceUser?.userId &&
            item.seen === false
          ) {
            listData.push(item);
          }
        });
        // console.log(listData);
        const sortedMessages = listData.sort((a, b) => b.date - a.date);
        setNotifications(sortedMessages);
      }
    });
  };

  const logoutFromTelecmi = () => {
    Logout(piopiy, dispatch);
  };

  const handleCloseForWhatsapp = () => {
    setWhatsappModal(false);
  };

  return (
    <div className="header border-bottom">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.length > 0
                  ? (finalName[0] === "investigation" || finalName[0] === "fi" ) && finalName.join(" ") !== "fi clients"
                    ? "Field Investigation Allocation"
                    : finalName.join(" ").length === 0
                    ? "Dashboard"
                    : finalName.join(" ") === "dashboard dark"
                    ? "Dashboard"
                    : finalName.join(" ") === "upi mandate summary"
                    ? "UPI Mandate Summary"
                    : finalName.join(" ") === "fi clients"
                    ? "FI Clients"
                    : finalName.join(" ")
                  : "Dashboard"}
              </div>
            </div>

            <ul className="navbar-nav header-right main-notification">
              {/* <div
                style={{
                  float: "right",
                  width: "120px",
                  height: "38px",
                  margin: "auto",

                  marginRight: "20px",
                  // marginBottom: "20px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    setStatusOptions(value.value);
                    setSelectedStatus({
                      label: value.label,
                      value: value.value,
                    });
                    localStorage.removeItem("selectedAgentStatus");
                    localStorage.setItem(
                      "selectedAgentStatus",
                      JSON.stringify({
                        label: value.label,
                        value: value.value,
                      })
                    );
                  }}
                  // defaultValue={statusOptions[0]}
                  value={selectedStatus}
                  options={statusOptions}
                />
              </div> */}
              {userData.autoFinanceUser &&
              (userData.autoFinanceUser.roleName === "SUPERADMIN" ||
                userData.isSuperAdmin === true) ? (
                <div
                  style={{
                    backgroundColor: "white",
                    height: "40px",
                    width: "200px",
                    border: "solid",
                    borderRadius: "3px",
                    margin: "auto",
                    marginRight: "20px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      setOrganisationValue(value);
                    }}
                    value={organisationList[organisationFilterIndex]}
                    options={organisationList}
                  />
                </div>
              ) : (
                <> </>
              )}
              {/* {hasAccess(42) &&
                userData?.autoFinanceUser?.teleCmiUserId !== null &&
                userData?.autoFinanceUser?.teleCmiPassword !== null &&
                userData?.autoFinanceUser?.teleCmiUserId !== "" &&
                userData?.autoFinanceUser?.teleCmiPassword !== "" &&
                 ( */}
              {hasAccess(83) && (
                <div
                  style={{
                    textAlign: "center",
                    alignSelf: "center",
                    fontSize: "larger",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // getFirestoreData();
                    // setWhatsappModal(true);
                    // var history = use
                    // console.log(window.location.href.split('/'));
                    // window.open("http://localhost:3000/whatsapp")
                    window.open(window.location.href + "/whatsapp");
                  }}
                >
                  <img
                    src="/sidebar/whatsapp.png"
                    style={{ width: "40px", height: "40px" }}
                    alt=""
                  />
                </div>
              )}
              {/* <Button
                  variant="primary btn-rounded"
                  className="me-2"
                  onClick={() => {
                    // piopiy.transfer('4441')
                    CallTransfer(piopiy, '4441')
                    // callingEventsInstance.transferCall();
                    // setCallTransferModal(true);
                  }}
                >
                  Transfer
                </Button>
              <Button
                  variant="primary btn-rounded"
                  className="me-2"
                  onClick={() => {
                    // piopiy.transfer('4441')
                    StartCall(
                      piopiy,
                      dispatch,
                      '7036820121',
                      false,
                      '46681',
                      null,
                      "Collections"
                    );
                    // callingEventsInstance.transferCall();
                    // setCallTransferModal(true);
                  }}
                >
                  call
                </Button> */}

              {/* )} */}
              {hasAccess(42) &&
                userData?.autoFinanceUser?.teleCmiUserId !== null &&
                userData?.autoFinanceUser?.teleCmiPassword !== null &&
                userData?.autoFinanceUser?.teleCmiUserId !== "" &&
                userData?.autoFinanceUser?.teleCmiPassword !== "" &&
                (isProgressiveDialing === true ||
                  isSalesProgressiveDialing === true) &&
                isTimerCounting && (
                  <div
                    style={{
                      textAlign: "center",
                      alignSelf: "center",
                      fontSize: "larger",
                      marginRight: "30px",
                      padding: "10px",
                      borderRadius: "15px",
                      backgroundColor: "#54ef54",
                    }}
                  >
                    {" "}
                    <span>
                      Next Call Starts in <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        {timercount + " Secs"}
                      </span>
                    </span>
                  </div>
                )}
              {hasAccess(42) &&
                userData?.autoFinanceUser?.teleCmiUserId !== null &&
                userData?.autoFinanceUser?.teleCmiPassword !== null &&
                userData?.autoFinanceUser?.teleCmiUserId !== "" &&
                userData?.autoFinanceUser?.teleCmiPassword !== "" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <label style={{ fontWeight: "bold", marginTop: "5px" }}>
                      Status
                    </label>
                    <div
                      style={{
                        backgroundColor: "white",
                        height: "38px",
                        width: "150px",
                        // border: "solid",
                        borderRadius: "3px",
                        // margin: "auto",
                        marginRight: "20px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        // defaultValue={colourOptions[1]}
                        options={statusOptions}
                        // components={{ Group }}
                        onChange={(value) => {
                          setStatusOptions(value.value);
                          setSelectedStatus({
                            label: value.label,
                            value: value.value,
                          });
                          if (value.value === "online") {
                            localStorage.removeItem("selectedAgentStatus");
                            localStorage.setItem(
                              "selectedAgentStatus",
                              JSON.stringify({
                                label: value.label,
                                value: value.value,
                              })
                            );
                          }
                        }}
                        value={selectedStatus}
                      />
                    </div>
                  </div>
                )}

              {hasAccess(42) &&
                userData?.autoFinanceUser?.teleCmiUserId !== null &&
                userData?.autoFinanceUser?.teleCmiPassword !== null &&
                userData?.autoFinanceUser?.teleCmiUserId !== "" &&
                userData?.autoFinanceUser?.teleCmiPassword !== "" &&
                isSubStatus === true && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "bold", marginTop: "5px" }}>
                      Break Type
                    </label>
                    <div
                      style={{
                        backgroundColor: "white",
                        height: "38px",
                        width: "150px",
                        // border: "solid",
                        borderRadius: "3px",
                        // margin: "auto",
                        marginRight: "20px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        // defaultValue={colourOptions[1]}
                        options={groupBreakTypes}
                        // components={{ Group }}
                        isOptionDisabled={(option) =>
                          isOptionsDisabled === true
                        }
                        formatGroupLabel={formatGroupLabel}
                        onChange={(value) => {
                          dispatch(setIsProgressiveDialing(false));
                          setStatusOptions(value.value);
                          setSelectedSubStatus({
                            label: value.label,
                            value: value.value,
                          });

                          localStorage.removeItem("selectedAgentStatus");
                          localStorage.setItem(
                            "selectedAgentStatus",
                            JSON.stringify({
                              label: value.label,
                              value: value.value,
                            })
                          );
                        }}
                        // defaultValue={statusOptions[0]}
                        value={selectedSubStatus}
                      />
                    </div>
                  </div>
                )}
              {hasAccess(42) &&
                userData?.autoFinanceUser?.teleCmiUserId !== null &&
                userData?.autoFinanceUser?.teleCmiPassword !== null &&
                userData?.autoFinanceUser?.teleCmiUserId !== "" &&
                userData?.autoFinanceUser?.teleCmiPassword !== "" && (
                  <Dropdown
                    as="li"
                    className="nav-item dropdown notification_dropdown "
                  >
                    <Dropdown.Toggle
                      className="nav-link i-false c-pointer"
                      variant=""
                      as="a"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.3333 19.8333H23.1187C23.2568 19.4597 23.3295 19.065 23.3333 18.6666V12.8333C23.3294 10.7663 22.6402 8.75902 21.3735 7.12565C20.1068 5.49228 18.3343 4.32508 16.3333 3.80679V3.49996C16.3333 2.88112 16.0875 2.28763 15.6499 1.85004C15.2123 1.41246 14.6188 1.16663 14 1.16663C13.3812 1.16663 12.7877 1.41246 12.3501 1.85004C11.9125 2.28763 11.6667 2.88112 11.6667 3.49996V3.80679C9.66574 4.32508 7.89317 5.49228 6.6265 7.12565C5.35983 8.75902 4.67058 10.7663 4.66667 12.8333V18.6666C4.67053 19.065 4.74316 19.4597 4.88133 19.8333H4.66667C4.35725 19.8333 4.0605 19.9562 3.84171 20.175C3.62292 20.3938 3.5 20.6905 3.5 21C3.5 21.3094 3.62292 21.6061 3.84171 21.8249C4.0605 22.0437 4.35725 22.1666 4.66667 22.1666H23.3333C23.6428 22.1666 23.9395 22.0437 24.1583 21.8249C24.3771 21.6061 24.5 21.3094 24.5 21C24.5 20.6905 24.3771 20.3938 24.1583 20.175C23.9395 19.9562 23.6428 19.8333 23.3333 19.8333Z"
                          fill="#717579"
                        />
                        <path
                          d="M9.9819 24.5C10.3863 25.2088 10.971 25.7981 11.6766 26.2079C12.3823 26.6178 13.1838 26.8337 13.9999 26.8337C14.816 26.8337 15.6175 26.6178 16.3232 26.2079C17.0288 25.7981 17.6135 25.2088 18.0179 24.5H9.9819Z"
                          fill="#717579"
                        />
                      </svg>
                      {notifications?.length > 0 && (
                        <span
                          className="badge light text-white bg-danger rounded-circle"
                          style={{
                            top: "2px",
                            right: "-2px",
                            paddingTop: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {notifications?.length}
                        </span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="right"
                      className="mt-2 dropdown-menu dropdown-menu-end"
                    >
                      <PerfectScrollbar
                        className="widget-media dlab-scroll p-3 "
                        style={{ overflowY: "auto", maxHeight: "380px" }}
                      >
                        <ul className="timeline">
                          {notifications?.map((item, index) => {
                            return (
                              <li key={index}>
                                <div className="timeline-panel">
                                  <div
                                    className={
                                      item?.messageType === "ptp"
                                        ? "media me-2 media-success"
                                        : "media me-2 media-info"
                                    }
                                    style={{ alignSelf: "center" }}
                                  >
                                    {item?.messageType === "ptp"
                                      ? "PTP"
                                      : item?.messageType === "CallBack"
                                      ? "CB"
                                      : item?.messageType}
                                  </div>
                                  <div className="media-body">
                                    <h6 className="mb-1">{item?.message}</h6>
                                    <small className="d-block">
                                      {EpochToDate(item?.date)}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                          {notifications?.length === 0 && (
                            <span
                              style={{ fontWeight: "bold", marginLeft: "5px" }}
                            >
                              No Unread Notifications Found
                            </span>
                          )}
                        </ul>
                        <div
                          className="ps__rail-x"
                          style={{ left: 0, bottom: 0 }}
                        >
                          <div
                            className="ps__thumb-x"
                            tabIndex={0}
                            style={{ left: 0, width: 0 }}
                          />
                        </div>
                        <div
                          className="ps__rail-y"
                          style={{ top: 0, right: 0 }}
                        >
                          <div
                            className="ps__thumb-y"
                            tabIndex={0}
                            style={{ top: 0, height: 0 }}
                          />
                        </div>
                      </PerfectScrollbar>
                      <Link
                        className="all-notification"
                        to="#"
                        onClick={() => {
                          setToggle("chatbox");
                        }}
                      >
                        See all notifications <i className="ti-arrow-right" />
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              <li className="nav-item ">
                <div>
                  <span style={{ fontWeight: "bold" }}>
                    {userData.isSuperAdmin
                      ? "Support"
                      : userData.autoFinanceUser &&
                        userData.autoFinanceUser.name}
                    {userData.fieldInvestigationAgency &&
                      userData.fieldInvestigationAgency.name}
                  </span>
                  <br />
                  {userData.fieldInvestigationAgency ? (
                    <span style={{ fontSize: "smaller" }}>
                      {userData.fieldInvestigationAgency && "Agency"}
                    </span>
                  ) : (
                    <span style={{ fontSize: "smaller" }}>
                      {userData.autoFinanceUser &&
                        userData.autoFinanceUser.companyDesignation}
                    </span>
                  )}
                  <br />
                  {hasAccess(42) &&
                    userData?.autoFinanceUser?.teleCmiUserId !== null &&
                    userData?.autoFinanceUser?.teleCmiPassword !== null &&
                    userData?.autoFinanceUser?.teleCmiUserId !== "" &&
                    userData?.autoFinanceUser?.teleCmiPassword !== "" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "left",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              JSON.parse(
                                localStorage.getItem("selectedAgentStatus")
                              )?.label === "Online"
                                ? "#09BD3C"
                                : "orange",
                            height: "10px",
                            width: "10px",
                            borderRadius: "50%",
                            marginTop: "3px",
                          }}
                        ></div>
                        <span style={{ fontWeight: "bold", marginLeft: "3px" }}>
                          {
                            JSON.parse(
                              localStorage.getItem("selectedAgentStatus")
                            )?.label
                          }
                        </span>
                      </div>
                    )}
                  {/* <div style={{float:'right'}}><i className="fa fa-duotone fa-clock fa-lg" style={{color:'#886cc0'}}></i> Last LoggedIn : <b>08-12-2022 09:00:00</b></div>  */}
                </div>
              </li>
              <Dropdown
                as="li"
                className="nav-item dropdown header-profile m-0"
              >
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  role="button"
                  data-toggle="dropdown"
                >
                  {/* <div className="profile-k">
                    <span style={{ background: '#d0a7fe' }}>{userData.autoFinanceUser.name.substring(0,1).toUpperCase()}</span>
                  </div> */}

                  <img
                    src={
                      userData.autoFinanceUser && userData.autoFinanceUser.image
                        ? userData.autoFinanceUser.image
                        : defaultImage
                    }
                    style={{ width: "40px", height: "40px" }}
                    alt=""
                  />
                  {/* <div className="header-info ms-3">
						<span className="font-w600 ">Hi,<b>William</b></span>
						<small className="text-end font-w400">william@example.com</small>
						</div> */}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end"
                >
                  {/* {hasAccess(42) &&
                    userData?.autoFinanceUser?.teleCmiUserId !== null &&
                    userData?.autoFinanceUser?.teleCmiPassword !== null &&
                    userData?.autoFinanceUser?.teleCmiUserId !== "" &&
                    userData?.autoFinanceUser?.teleCmiPassword !== "" && (
                      <div>
                        <Link to="#" className="dropdown-item ai-icon">
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                            onClick={() => {
                              setStatusOptions("online");
                              setSelectedStatus({
                                label: "Online",
                                value: "Online",
                              });
                              localStorage.removeItem("selectedAgentStatus");
                              localStorage.setItem(
                                "selectedAgentStatus",
                                JSON.stringify({
                                  label: "Online",
                                  value: "online",
                                })
                              );
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "green",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                marginTop: "3px",
                                marginLeft: "3px",
                                marginRight: "8px",
                              }}
                            ></div>
                            <span className="ms-2">Online </span>
                          </div>
                        </Link>
                        <Link to="#" className="dropdown-item ai-icon">
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                            onClick={() => {
                              setStatusOptions("break");
                              setSelectedStatus({
                                label: "Break",
                                value: "break",
                              });
                              localStorage.removeItem("selectedAgentStatus");
                              localStorage.setItem(
                                "selectedAgentStatus",
                                JSON.stringify({
                                  label: "Break",
                                  value: "break",
                                })
                              );
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "orange",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                marginLeft: "3px",
                                marginTop: "3px",
                                marginRight: "9px",
                              }}
                            ></div>
                            <span className="ms-2">Break </span>
                          </div>
                        </Link>
                        <hr></hr>
                      </div>
                    )} */}
                  {userData.isSuperAdmin !== true && (
                    <Link to="/app-profile" className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary me-1"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ms-2">Profile </span>
                    </Link>
                  )}
                  {/* <Link to="/email-inbox" className="dropdown-item ai-icon">
                  <Link to="/email-inbox" className="dropdown-item ai-icon">
                    <svg
                      id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success me-1" width={18}
                      height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2}
                      strokeLinecap="round" strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                    <span className="ms-2">Inbox </span>
                  </Link> */}
                  <LogoutPage logout={logoutFromTelecmi} />
                </Dropdown.Menu>
              </Dropdown>

              {/* <li className="nav-item header-profile">
					<Link to={"#"} className="nav-link" role="button" data-bs-toggle="dropdown">
						<img src={profile} width="20" alt=""/>
					</Link>
				</li> */}
            </ul>
          </div>
        </nav>
      </div>
      {hasAccess(42) &&
        userData?.autoFinanceUser?.teleCmiUserId !== null &&
        userData?.autoFinanceUser?.teleCmiPassword !== null &&
        userData?.autoFinanceUser?.teleCmiUserId !== "" &&
        userData?.autoFinanceUser?.teleCmiPassword !== "" &&
        isRunning === true && (
          <Modal className="fade" show={timerModal} size="sm" centered>
            <Modal.Body>
              <div>
                <div>
                  <h4 style={{ textAlignLast: "center", marginBottom: "15px" }}>
                    {" "}
                    Break -{" "}
                    {
                      JSON.parse(localStorage.getItem("selectedAgentStatus"))
                        ?.label
                    }
                  </h4>
                  <h6 style={{ textAlignLast: "center" }}> Break Time</h6>
                  <h2 style={{ textAlignLast: "center", marginBottom: "15px" }}>
                    {formatTime(hours)}:{formatTime(minutes)}:
                    {formatTime(seconds)}
                  </h2>
                  <div style={{ textAlignLast: "center", marginTop: "15px" }}>
                    <Button
                      style={{
                        marginRight: "15px",
                        textAlignLast: "center",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="success btn-rounded"
                      onClick={() => {
                        setIsSubStatus(false);
                        setSelectedSubStatus(null);
                        setIsOptionsDisabled(true);
                        StartTelecalling(
                          piopiy,
                          dispatch,
                          userData?.autoFinanceUser?.teleCmiUserId,
                          userData?.autoFinanceUser?.teleCmiPassword,
                          isTestCall,
                          callType
                        );
                        sendDataToDB(
                          "Idle",
                          localStorage.getItem("todayCallsCount")
                        );
                        statusUpdate("online");
                        setSelectedStatus({
                          label: "Online",
                          value: "online",
                        });
                        localStorage.setItem(
                          "selectedAgentStatus",
                          JSON.stringify({
                            label: "Online",
                            value: "online",
                          })
                        );
                        resetTimer();
                        // toggleTimer();
                        setIsRunning(false);
                        setTimerModal(false);
                      }}
                    >
                      &nbsp;&nbsp;Go Online
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      <Modal className="fade" show={whatsappModal} size="fullscreen">
        {/* <Modal.Header>
          <Modal.Title>{userData?.autoFinanceOrganization?.organizationName}</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setWhatsappModal(false)}
          ></Button>
        </Modal.Header> */}
        <Modal.Body style={{ padding: "1px" }}>
          <div>
            <Whatsapp close={handleCloseForWhatsapp} />
          </div>
        </Modal.Body>
      </Modal>
      {toggle === "chatbox" && (
        <TelecallerNotifications closeDrawer={closeDrawer} />
      )}
    </div>
  );
};

export default Header;
