import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import {
  getTokenInLocalStorage,
} from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import "react-h5-audio-player/lib/styles.css";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
// import SearchBar from "../CommonComponents/SearchBar";

function SmsHistory({ mobileNo,loanNumber }) {

  const [smsHistoryList, setSmsHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transList, setTransList] = useState(null);

  let listData = [];

  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview("Call History");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transList]);

  const loadData = () => {
    setIsLoading(true);
    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      mobileNumber: mobileNo,
      loanNumber: loanNumber,
    };

    moduleApi
      .postData(
        apiCall.getsmshistory,
        body
      )
      .then((response) => {
        // console.log(response);
        response?.forEach((element) => {
          let data = {
            id: element.id,
            organizationId: element.organizationId,
            message: element.message ? element.message : "-",
            mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
            url: element.url ? element.url : "-",
            messageSent: element.messageSent,
            reponse: element.reponse,
            gupshupMessageId: element.gupshupMessageId ? element.gupshupMessageId : "-",
            gupshuMessageStatus: element.gupshuMessageStatus ? element.gupshuMessageStatus : "-",
            timestamp: element.timestamp ? convertEpochTimeToDate(element.timestamp) : "-",
            templateId: element.templateId ? element.templateId : "-",
            errorCode: element.errorCode ? element.errorCode : "-",
            cause: element.cause ? element.cause : "-",
            noOfFrags: element.noOfFrags ? element.noOfFrags : "-",
            paymentLinkSent: element.paymentLinkSent,
            paymentLinkTransactionId: element.paymentLinkTransactionId ? element.paymentLinkTransactionId : "-",
            paymentLinkStatus: element.paymentLinkStatus ? element.paymentLinkStatus : "-",
            customerName: element.customerName ? element.customerName : "-",
            templateName: element.templateName ? element.templateName : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
          };
          listData.push(data);
        });
        setSmsHistoryList(listData);
        setIsLoading(false);
      });
  };

  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    {
      text: "SMS Template Name",
      dataField: "templateName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "To Number",
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan Number",
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Delivery Status",
      dataField: "gupshuMessageStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Payment Link Sent",
      dataField: "paymentLinkSent",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {

        return row.paymentLinkSent ? "Yes" : "No"

      },
    },
    {
      text: "Payment Status",
      dataField: "paymentLinkStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "SMS Sent Date Time",
      dataField: "timestamp",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  const transDownloadData = () => {
    setIsLoading(true);

    getTransFullResponseData();
    // let body = {
    //   organizationId: userLoginDetails.autoFinanceUser.organizationId,
    //   mobileNumber: mobileNo,
    // };

    // moduleApi.postData(apiCall.getsmshistory,body)
    //   .then((response) => {
    //     if (response) {
    //       getTransFullResponseData();
    //     } else {
    //       swal("Something Went Wrong, Please Try Later", {
    //         icon: "error",
    //         dangerMode: true,
    //       });
    //     }
    //   });
  };


  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime)* 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  async function getTransFullResponseData() {
    var tempList = [];
    var mgrListData = [];

    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      mobileNumber: mobileNo,
    };
    await moduleApi
      .postData(
        apiCall.getsmshistory,
        body
      )
      .then((response) => {
        tempList.push(...response);
      });


    // console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        id: element.id,
        organizationId: element.organizationId,
        message: element.message ? element.message : "-",
        mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
        url: element.url ? element.url : "-",
        messageSent: element.messageSent,
        reponse: element.reponse,
        gupshupMessageId: element.gupshupMessageId ? element.gupshupMessageId : "-",
        gupshuMessageStatus: element.gupshuMessageStatus ? element.gupshuMessageStatus : "-",
        timestamp: element.timestamp ? convertEpochTimeToDate(element.timestamp) : "-",
        templateId: element.templateId ? element.templateId : "-",
        errorCode: element.errorCode ? element.errorCode : "-",
        cause: element.cause ? element.cause : "-",
        noOfFrags: element.noOfFrags ? element.noOfFrags : "-",
        paymentLinkSent: element.paymentLinkSent,
        paymentLinkTransactionId: element.paymentLinkTransactionId ? element.paymentLinkTransactionId : "-",
        paymentLinkStatus: element.paymentLinkStatus ? element.paymentLinkStatus : "-",
        customerName: element.customerName ? element.customerName : "-",
        templateName: element.templateName ? element.templateName : "-",
        loanNumber: element.loanNumber ? element.loanNumber : "-",
      };
      mgrListData.push(data);
    });
    setTransList(mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "SMS Template Name",
        B: "To Number",
        C: "Customer Name",
        D: "Loan Number",
        E: "Delivery Status",
        F: "Payment Link Sent",
        G: "Payment Status",
        H: "SMS Sent Date Time",
      },
    ];

    transList?.forEach((row) => {
      mgrTable.push({
        A: row.templateName,
        B: row.mobileNumber,
        C: row.customerName,
        D: row.loanNumber,
        E: row.gupshuMessageStatus,
        F: row.paymentLinkSent ? "Yes" : "No",
        G: row.paymentLinkStatus,
        H: row.timestamp,
      });
    });

    const finalData = [...mgrTable];

    // console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    // console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "SMS History");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      mobileNo +
      "_SMS_History_" +
      helper.EpochToDate(Math.floor(new Date() / 1000)) +
      ".xlsx"
    );
    ReactGA.event({
      category: "SMS History List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_SMS_History_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          flexDirection: "row",
          alignItems: "center",
          marginRight: "20px",
        }}
      >
        <Button
          style={{
            marginRight: "15px",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() => transDownloadData()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={smsHistoryList}
              columns={columns}
            />
          </div>
          {smsHistoryList?.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default SmsHistory;
