import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
// import { CallingProvider } from "./CommonComponents/CallingContext";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getMessaging, getToken, onMessage} from "firebase/messaging";
import swDev from "../src/swDev";
// import { CallingMethodsProvider } from "./CommonComponents/CallingMethods2";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// QA-Firebase
// const firebaseConfig = {
//   apiKey: "AIzaSyB8atYr2xkwGkmqfhSkui9FHk6TLybPs4Y",
//   authDomain: "telecalling-qa.firebaseapp.com",
//   databaseURL: "https://telecalling-qa-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "telecalling-qa",
//   storageBucket: "telecalling-qa.appspot.com",
//   messagingSenderId: "918263194810",
//   appId: "1:918263194810:web:b3d99ba9f89a3cfcfb4e83",
//   measurementId: "G-HG128X9HWW"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
// getToken(messaging, {vapidKey: "BLLCSLlrdofwQ0ypidfpA8knFpzqHxh-R_kkTmMlOAnbE3jP6IPKOtlXU1iEjUeqOfJEjlEyX1ZjBI8la-C8xnA"}).then((currentToken) => {
//   if (currentToken) {
//     console.log('token', currentToken);
//     // Send the token to your server and update the UI if necessary
//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   // ...
// });

// // eslint-disable-next-line
// const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SimpleReactLightbox>
        <BrowserRouter>
          <ThemeContext>
            {/* <CallingProvider> */}
            {/* <CallingMethodsProvider> */}
              <App />
            {/* </CallingMethodsProvider> */}
            {/* </CallingProvider> */}
          </ThemeContext>
        </BrowserRouter>
      </SimpleReactLightbox>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();

swDev();
