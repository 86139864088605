import React, { useEffect, useState } from "react";
import SearchBar from "../CommonComponents/SearchBar";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess, isAdmin } from "../services/AuthService";
import { Button, Modal, Tab, Nav } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import VisitDetailsHistory from "./VisitDetailsHistory";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { EpochToDate } from "../CommonComponents/Helper";
// import { makeCall } from "../CommonComponents/Helper";
import CustomerDetails from "./CustomerDetails";
import CallSummary from "./CallSummary";
import Select from "react-select";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import NonVisitedPaymentsHistory from "./NonVisitedPaymentsHistory";
import SmsHistory from "./SmsHistory";
import WhatsAppHistory from "./WhatsAppHistory";
// import swal from "sweetalert";
// import PIOPIY from "piopiyjs";
// import { useCalling } from "../CommonComponents/CallingContext";

const CustomerMgmt2 = ({ visitType, open, close }) => {
  // var piopiy = new PIOPIY({
  //   name: "CLU",
  //   debug: false,
  //   autoplay: true,
  //   ringTime: 60,
  // });

  // const { startCall, openModal } = useCalling();

  // var piopiy = JSON.parse(localStorage.getItem('callDetails'))
  var history = useHistory();
  const [visitedPlacesList, setVisitedPlacesList] = useState([]);
  const [fieldInvstgtnList, setFieldInvstgtnList] = useState([]);
  const [visitedPlacesDetailsList, setVisitedPlacesDetailsList] = useState([]);
  // const [basicModal, setBasicModal] = useState(false);
  //   const [showDate, setShowDate] = useState(false);
  //   const [visitType, setVisitType] = useState("All");
  //   const [status, setStatus] = useState("All");
  //   const [collectionStatuses, setCollectionStatuses] = useState([]);
  //   const [collectionSubStatuses, setCollectionSubStatuses] = useState([]);
  //   const [collectionSubType, setCollectionSubType] = useState("");

  //   const [selectedCollectionSubType, setSelectedCollectionSubType] = useState({
  //     label: "All",
  //     value: "",
  //   });
  //   const [isSubStatus, setIsSubStatus] = useState(false);
  // const [collectionType, setCollectionType] = useState("");
  // eslint-disable-next-line no-unused-vars
  //   const [collectionTypeIndex, setCollectionTypeIndex] = useState(0);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [toggle, setToggle] = useState(false);
  //   const [start, setStart] = useState(
  //     Math.floor(
  //       new Date(
  //         new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
  //       ).getTime() / 1000
  //     )
  //   );
  //   const [end, setEnd] = useState(
  //     Math.floor(
  //       new Date(
  //         new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
  //       ).getTime() / 1000
  //     )
  //   );
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isFieldInvstgn, setIsFieldInvstgn] = useState(false);
  const [isSalesVisit, setSalesVisit] = useState(false);
  //   const [dropDownOptions, setDropDownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  // const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(null);
  const [selectedFIStatus, setSelectedFIStatus] = useState(null);
  const [customersDownloadList, setCustomersDownloadList] = useState(null);
  const [collectionStatuses, setCollectionStatuses] = useState([]);
  const [collectionSubStatuses, setCollectionSubStatuses] = useState([]);
  const [collectionType, setCollectionType] = useState("");
  const [collectionSubType, setCollectionSubType] = useState("");
  const [selectedCollectionSubType, setSelectedCollectionSubType] = useState({
    label: "All",
    value: "",
  });
  const [isSubStatus, setIsSubStatus] = useState(false);

  useEffect(() => {
    ReactGA.pageview("Customer Managemet - " + visitType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadVistedPlacesData(
      0,
      visitType,
      searchKey,
      // selectedApprovalStatus,
      selectedFIStatus,
      collectionType,
      collectionSubType
    );
    visitType === "Collections" && getCollectionDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVistedPlacesData = (
    pageNo,
    visitType,
    key,
    fiStatus,
    status,
    subStatus
  ) => {
    // if (visitType !== "Sales" &&  visitType !== "FieldInvestigation") {
    //   setFlag(true);
    //   // if (visitType === "All" || visitType ===  "Collections") {
    //   //   setFlag(true);
    //   // } else {
    //   //   setFlag(false);
    //   // }
    // } else {
    //   setFlag(false);
    // }
    // setIsLoading(true);
    open()
    var VisitedPlacesBody = {
      //   endTime: end,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
      //   roleName: "ADMIN",
      //   startTime: start,
      // status: visitType === "Collections" ? status : undefined,
      //   userId: "",
      visitType: visitType,
      searchkey: key,
      status:
        visitType === "Collections"
          ? status
          : isFieldInvstgn
          ? fiStatus
          : undefined,
      subStatus: visitType === "Collections" ? subStatus : undefined,
    };

    // var cpNameVisitedPlacesBody = {
    //   endTime: end,
    //   organizationId: loggedInUser.autoFinanceOrganization.mainOrganizationId,
    //   // reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
    //   startTime: start,
    //   // userId: isSwitch ? loggedInUser.autoFinanceUser.userId : "",
    //   visitType: "Collections",
    //   status: status,
    //   searchkey: key,
    //   cpName: loggedInUser.autoFinanceOrganization.cpName,
    //   // subStatus: collectionSubType,
    // };

    // module
    // moduleApi
    //   .postData(
    //     moduleApi.withPage(
    //       loggedInUser.autoFinanceUser.roleName !== "CP_USER"
    //         ? apiCall.getCustomerMgmtList
    //         : apiCall.getCustomerDetailsBasedOnCpName,
    //       pageNo,
    //       10
    //     ),
    //     loggedInUser.autoFinanceUser.roleName !== "CP_USER"
    //       ? VisitedPlacesBody
    //       : cpNameVisitedPlacesBody
    //   )
    //   .then((response) => {
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCustomerMgmtList, pageNo, 10),
        VisitedPlacesBody
      )
      .then((response) => {
        setCurrPage(pageNo);
        var listData = [];
        if (visitType === "FieldInvestigation") {
          response?.content?.forEach((element) => {
            let data = {
              userModel: element.userModel,
              id: element.id,
              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,

              organizationId: element.organisationId,
              latitude: element.latitude,
              longitude: element.longitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: EpochToDate(element.dateTime),
              reportingOfficerId: element.reportingOfficerId,
              fieldInvestigationVisit: element.fieldInvestigationVisit,
            };
            listData.push(data);
          });
          setFieldInvstgtnList(listData);
          setIsFieldInvstgn(true);
          // setIsLoading(false);
          close()
        } else if (visitType === "Sales") {
          response?.content?.forEach((element) => {
            let data = {
              id: element.id,
              userId: element.userId,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,

              organizationId: element.organisationId,
              status: element.status,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: EpochToDate(element.dateTime),
              timeSpent: element.timeSpent,
              reportingOfficerId: element.reportingOfficerId,
              salesVisit: element.salesVisit,
              collectionVisit: element.collectionVisit,
              fieldInvestigationVisit: element.fieldInvestigationVisit,

              userModel: element.userModel,
              // gpsData: m.has(element.userModel.userId) ? m.get(element.use.userId).gpsData : null,
              // eslint-disable-next-line no-dupe-keys
              status: element.collectionVisit
                ? element.collectionVisit.status
                : element.salesVisit
                ? "-"
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.status
                : "",
              loanNumber: element.collectionVisit
                ? element.collectionVisit.loanNumber
                : "-",
              subStatus: element.collectionVisit
                ? element.collectionVisit.subStatus === null ||
                  element.collectionVisit.subStatus === ""
                  ? "-"
                  : element.collectionVisit.subStatus
                : "-",

              customerName: element.collectionVisit
                ? element.collectionVisit.customerName
                : element.salesVisit
                ? element.salesVisit.customerName
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.customerName
                : "",
              contactNumber: element.collectionVisit
                ? element.collectionVisit.contactNumber
                : element.salesVisit
                ? element.salesVisit.contactNumber
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : "",
            };
            listData.push(data);
          });
          setVisitedPlacesList(listData);
          setIsFieldInvstgn(false);
          setSalesVisit(true);
          setIsLoading(false);
          close()
        } else {
          response?.content?.forEach((element) => {
            let data = {
              id: element.id,
              userId: element.userId,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,

              organizationId: element.organisationId,
              status: element.status,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: EpochToDate(element.dateTime),
              timeSpent: element.timeSpent,
              reportingOfficerId: element.reportingOfficerId,
              salesVisit: element.salesVisit,
              collectionVisit: element.collectionVisit,
              fieldInvestigationVisit: element.fieldInvestigationVisit,

              userModel: element.userModel,
              // gpsData: m.has(element.userModel.userId) ? m.get(element.use.userId).gpsData : null,
              // eslint-disable-next-line no-dupe-keys
              status: element.collectionVisit
                ? element.collectionVisit.status
                : element.salesVisit
                ? "-"
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.status
                : "",
              loanNumber: element.collectionVisit
                ? element.collectionVisit.loanNumber
                : "-",
              subStatus: element.collectionVisit
                ? element.collectionVisit.subStatus === null ||
                  element.collectionVisit.subStatus === ""
                  ? "-"
                  : element.collectionVisit.subStatus
                : "-",

              customerName: element.collectionVisit
                ? element.collectionVisit.customerName
                : element.salesVisit
                ? element.salesVisit.customerName
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.customerName
                : "",
              contactNumber: element.collectionVisit
                ? element.collectionVisit.contactNumber
                : element.salesVisit
                ? element.salesVisit.contactNumber
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : "",
            };
            listData.push(data);
          });
          setVisitedPlacesList(listData);
          setIsFieldInvstgn(false);
          setSalesVisit(false);
          setIsLoading(false);
          close()
        }
        setTotalElements(response?.totalElements);
      });
  };

  const onPageChange = (selectedPage) => {
    loadVistedPlacesData(
      selectedPage - 1,
      visitType,
      searchKey,
      selectedFIStatus,
      collectionType,
      collectionSubType
    );
  };

  // const approvalStatusOptions = [
  //   { label: "ALL", value: "" },
  //   { label: "Pending", value: "PENDING" },
  //   { label: "Approved", value: "APPROVED" },
  //   { label: "Rejected", value: "REJECTED" },
  // ];

  const fiStatusOptions = [
    { label: "ALL", value: "" },
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "FI Submitted", value: "FI Submitted" },
    { label: "Door Locked", value: "Door Locked" },
    { label: "Refer To Credit", value: "Refer To Credit" },
    { label: "Submitted", value: "Submitted" },
  ];

  // const callOptionTemplate = (row) => {
  //   return (
  //     <div>
  //       <Link
  //         to="#"
  //         className="btn btn-success shadow btn-xs sharp"
  //         onClick={() => {
  //           // helper.makeCall(props?.contactNumber1);
  //           console.log("onGoing", localStorage.getItem("isCallInProgress"));
  //           if (localStorage.getItem("isCallInProgress") === "false") {
  //             startCall(row?.contactNumber, false, row?.collectionVisit?.id);
  //             // piopiy.call("91" + row?.contactNumber);
  //             // setCallMobileNo(props?.contactNumber1);
  //           } else {
  //             openModal();
  //           }
  //         }}
  //       >
  //         <i className="fa fa-phone fa-1x fa-rotate-90" />
  //       </Link>
  //     </div>
  //   );
  // };

  useEffect(() => {
    customersDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersDownloadList]);

  const getCollectionDropdownOptions = () => {
    var payload = {
      organizationId:
        loggedInUser.autoFinanceUser.roleName !== "CP_USER"
          ? loggedInUser.autoFinanceUser.organizationId
          : loggedInUser.autoFinanceOrganization.mainOrganizationId,
    };

    moduleApi
      .postData(apiCall.getCollectionsStatus, payload)
      .then((response) => {
        const result = response.map((item) => ({
          label: item,
          value: item,
        }));
        result.unshift({ label: "All", value: "" });
        // console.log('result', result);

        setCollectionStatuses(result);
      });
  };

  const setCollectionTypeValue = (value) => {
    setCollectionType(value.value);
  };

  const getSubStatusCollectionOptions = (subStatus) => {
    var payload = {
      organizationId:
        loggedInUser.autoFinanceUser.roleName !== "CP_USER"
          ? loggedInUser.autoFinanceUser.organizationId
          : loggedInUser.autoFinanceOrganization.mainOrganizationId,
      status: subStatus,
    };

    moduleApi
      .postData(apiCall.getCollectionsStatus, payload)
      .then((response) => {
        const result = response.map((item) => ({
          label: item,
          value: item,
        }));
        result.unshift({ label: "All", value: "" });
        if (response.length > 0 && subStatus !== "") {
          setCollectionSubType("");
          setSelectedCollectionSubType({ label: "All", value: "" });
          setIsSubStatus(true);
        } else {
          setCollectionSubType("");
          setSelectedCollectionSubType({ label: "All", value: "" });
          setIsSubStatus(false);
        }
        // console.log('result', result);

        setCollectionSubStatuses(result);
      });
  };

  const customerListDownloadData = (
    visitType,
    key,
    approvalStatus,
    fiStatus,
    status,
    subStatus
  ) => {
    setIsLoading(true);
    var payload = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
      visitType: visitType,
      searchkey: key,
      approvalStatus: isFieldInvstgn ? approvalStatus : undefined,
      status:
        visitType === "Collections"
          ? status
          : isFieldInvstgn
          ? fiStatus
          : undefined,
      subStatus: visitType === "Collections" ? subStatus : undefined,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getCustomerMgmtList, 0, 10), payload)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getCustomersFullResponseData(
                  Math.floor(response.totalElements / 400),
                  visitType,
                  key,
                  fiStatus
                );
              }
            });
          }

          getCustomersFullResponseData(
            Math.floor(response.totalElements / 400),
            visitType,
            key,
            fiStatus,
            status,
            subStatus
          );
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getCustomersFullResponseData(
    size,
    visitType,
    key,
    approvalStatus,
    fiStatus,
    status,
    subStatus
  ) {
    var tempList = [];
    var custListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        organizationId: loggedInUser.autoFinanceUser.organizationId,
        reportingOfficerId: isAdmin()
          ? ""
          : loggedInUser.autoFinanceUser.userId,
        visitType: visitType,
        searchkey: key,
        approvalStatus: isFieldInvstgn ? approvalStatus : undefined,
        status:
          visitType === "Collections"
            ? status
            : isFieldInvstgn
            ? fiStatus
            : undefined,
        subStatus: visitType === "Collections" ? subStatus : undefined,
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getCustomerMgmtList, i, 400),
          payload
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        id: element.id,
        userId: element.userId,
        name: element.userModel.name,
        mobileNumber: element.userModel.mobileNumber,
        email: element.userModel.email,
        UserImage: element.userModel.image,
        branchId: element.userModel.branchId,
        roleName: element.userModel.roleName,
        addedByUserId: element.userModel.addedByUserId,
        employeeId: element.userModel.employeeId,
        userStatus: element.userModel.status,
        passwordUpdated: element.userModel.passwordUpdated,
        organizationId: element.organisationId,
        comments: element.comments,
        latitude: element.latitude,
        longitude: element.longitude,
        address: element.address,
        visitType: element.visitType,
        image: element.image,
        dateTime: EpochToDate(element.dateTime),
        timeSpent: element.timeSpent,
        reportingOfficerId: element.reportingOfficerId,
        salesVisit: element.salesVisit,
        collectionVisit: element.collectionVisit,
        fieldInvestigationVisit: element.fieldInvestigationVisit,
        userModel: element.userModel,
        status: element.collectionVisit
          ? element.collectionVisit.status
          : element.salesVisit
          ? "-"
          : element.fieldInvestigationVisit
          ? element.fieldInvestigationVisit.status
          : "",
        loanNumber: element.collectionVisit
          ? element.collectionVisit.loanNumber
          : "-",
        subStatus: element.collectionVisit
          ? element.collectionVisit.subStatus === null ||
            element.collectionVisit.subStatus === ""
            ? "-"
            : element.collectionVisit.subStatus
          : "-",

        customerName: element.collectionVisit
          ? element.collectionVisit.customerName
          : element.salesVisit
          ? element.salesVisit.customerName
          : element.fieldInvestigationVisit
          ? element.fieldInvestigationVisit.customerName
          : "",
        contactNumber: element.collectionVisit
          ? element.collectionVisit.contactNumber
          : element.salesVisit
          ? element.salesVisit.contactNumber
          : element.fieldInvestigationVisit
          ? element.fieldInvestigationVisit.contactNumber
          : "",
        applicationNo: element?.fieldInvestigationVisit?.applicationNo
          ? element.fieldInvestigationVisit.applicationNo
          : "-",
        approvalStatus: element?.fieldInvestigationVisit?.approvalStatus
          ? element.fieldInvestigationVisit.approvalStatus
          : "-",
        rejectionType: element?.fieldInvestigationVisit?.rejectionType
          ? element.fieldInvestigationVisit.rejectionType
          : "-",
      };
      custListData.push(data);
    });
    setCustomersDownloadList(custListData);
  }

  const handleExport = () => {
    let collectionsTable = [
      {
        A: "Customer",
        B: "Contact No",
        C: "Type",
        D: "Loan Number",
        E: "Status",
        F: "Sub Status",
        G: "Last Visited Date",
        H: "Last Visited By",
      },
    ];

    let salesTable = [
      {
        A: "Customer",
        B: "Contact No",
        C: "Type",
        D: "Last Visited Date",
        E: "Last Visited By",
      },
    ];

    let fieldInvestigationTable = [
      {
        A: "Employee",
        B: "Customer",
        C: "Application Number",
        D: "Date",
        E: "Status",
        F: "Rejection Reason",
      },
    ];

    if (visitType === "Sales") {
      customersDownloadList?.forEach((row) => {
        salesTable.push({
          A: row.customerName,
          B: row.contactNumber,
          C: row.visitType,
          D: row.dateTime,
          E: row.name,
        });
      });
    } else if (visitType === "FieldInvestigation") {
      customersDownloadList?.forEach((row) => {
        fieldInvestigationTable.push({
          A: row.name,
          B: row.customerName,
          C: row.applicationNo,
          D: row.dateTime,
          E: row.status,
          F: row.rejectionType,
        });
      });
    } else {
      customersDownloadList?.forEach((row) => {
        collectionsTable.push({
          A: row.customerName,
          B: row.contactNumber,
          C: row.visitType,
          D: row.loanNumber,
          E: row.status,
          F: row.subStatus,
          G: row.dateTime,
          H: row.name,
        });
      });
    }
    const finalData =
      visitType === "Sales"
        ? [...salesTable]
        : visitType === "FieldInvestigation"
        ? [...fieldInvestigationTable]
        : [...collectionsTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "customerDetails");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Customer_Details" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Customer List Downlaod",
      action:
        loggedInUser.autoFinanceOrganization.orgshortcode +
        "_Customers_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const columns = [
    {
      text: "Customer",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Contact No",
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      // formatter: (cell, row) => {
      //   if (row.contactNumber) {
      //     return (
      //       <div style={{ display: "flex", justifyContent: "center" }}>
      //         <p
      //           title={row.contactNumber}
      //           style={{
      //             cursor: "pointer",
      //             verticalAlign: "middle",
      //             marginRight: "5px",
      //           }}
      //         >
      //           {row.contactNumber}
      //         </p>
      //         {hasAccess(42) &&
      //           loggedInUser?.autoFinanceUser?.teleCmiUserId !== null &&
      //           loggedInUser?.autoFinanceUser?.teleCmiPassword !== null &&
      //           loggedInUser?.autoFinanceUser?.teleCmiUserId !== "" &&
      //           loggedInUser?.autoFinanceUser?.teleCmiPassword !== "" &&
      //           callOptionTemplate(
      //             row
      //           )
      //           // <Link
      //           //   to="#"
      //           //   className="btn btn-success shadow btn-xs sharp"
      //           //   onClick={() => {
      //           //     // makeCall(row?.contactNumber);
      //           //     console.log('onGoing', isOnGoingCall);
      //           //     // if (isCallRejected === true) {
      //           //     //   console.log('test');
      //           //     //   startCall(row?.contactNumber, false);
      //           //     //   // piopiy.call("91" + row?.contactNumber);
      //           //     //   // setCallMobileNo(row?.contactNumber);
      //           //     // } else {
      //           //     //   openModal();
      //           //     // }
      //           //     if (isOnGoingCall === false) {
      //           //       startCall(row?.contactNumber, false);
      //           //       // piopiy.call("91" + row?.contactNumber);
      //           //       // setCallMobileNo(props?.contactNumber1);
      //           //     } else {
      //           //       openModal();
      //           //     }

      //           //     // setCallAudioModal(true);
      //           //     // setIsMobileField(false);
      //           //     // setIsCallAnswered(true);
      //           //     // setIsCallRejected(false);
      //           //   }}
      //           // >
      //           //   <i className="fa fa-phone fa-1x fa-rotate-90" />
      //           // </Link>
      //         }
      //       </div>
      //     );
      //   } else {
      //     return <>-</>;
      //   }
      // },
    },

    {
      text: "Type",
      dataField: "visitType",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return <span>{row.visitType}</span>;
      },
    },
    {
      text: "Loan Number",
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return <span>{row.status}</span>;
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      // formatter: (cell, row) => {
      //   return (
      //     <span>
      //       {row.status === "PTP"
      //         ? row.status + "(" + row.collectionVisit.paymentDateBy + ")"
      //         : row.status}
      //     </span>
      //   );
      // },
    },
    {
      text: "Last Visited Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Last Visited By",
      dataField: "",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          // <div className="d-flex">
          // {/* <Link to="#" className="btn btn-success shadow btn-xs sharp"> */}
          // {/* <i className="fa fa-eye"></i> */}
          <span style={{ color: "blueviolet", cursor: "pointer" }}>
            {row.name}
          </span>
          // {/* </Link> */}
          // </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let path = "/executive-details";
          path += "/";
          path += row.userModel.userId;
          // eslint-disable-next-line no-unused-vars
          path += "/";

          moduleApi.postData(apiCall.getUser + row.userId).then((element) => {
            // var user=response.autoFinanceUser;

            var user = {
              branchId: element.autoFinanceBranchDetails?.branchId,
              branchLocation: element.autoFinanceBranchDetails?.branchLocation,
              branchName: element.autoFinanceBranchDetails?.branchName,
              branchemail: element.autoFinanceBranchDetails?.branchemail,
              organizationId: element.autoFinanceBranchDetails?.organizationId,
              phoneNumber: element.autoFinanceBranchDetails?.phoneNumber,
              addedByUserId: element.autoFinanceUser.addedByUserId,
              reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
              email: element.autoFinanceUser.email,
              employeeId: element.autoFinanceUser.employeeId,
              image: element.autoFinanceUser.image,
              mobileNumber: element.autoFinanceUser.mobileNumber,
              name: element.autoFinanceUser.name,
              passwordUpdated: element.autoFinanceUser.passwordUpdated,
              reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
              roleName: element.autoFinanceUser.roleName,
              status: element.autoFinanceUser.status,
              userId: element.autoFinanceUser.userId,
              designation: element.autoFinanceUser.companyDesignation,
            };

            let body = {
              organizationId: element.autoFinanceBranchDetails?.organizationId,
              userIds: [row.userId],
            };

            moduleApi
              .postData(apiCall.getLiveLocations, body)
              .then((response2) => {
                response2?.forEach((item) => {
                  user.batteryPercentage = item.batteryPercentage;
                  user.gpsData = item.gpsData;
                  user.lastUpdatedAt = item.lastUpdatedAt;
                });
                localStorage.setItem("executiveDetails", JSON.stringify(user));
                localStorage.setItem("prevUrl", "customers");
                history.push("/executive-details");
              });

            // console.log(response);
            //   setManagerData(response);
          });
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          // setBasicModal(true);
          setToggle(true);
        },
      },
    },
  ];

  const salesVisitColumns = [
    {
      text: "Customer",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Contact No",
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Type",
      dataField: "visitType",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return <span>{row.visitType}</span>;
      },
    },
    {
      text: "Last Visited Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Last Visited By",
      dataField: "",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          // <div className="d-flex">
          // {/* <Link to="#" className="btn btn-success shadow btn-xs sharp"> */}
          // {/* <i className="fa fa-eye"></i> */}
          <span style={{ color: "blueviolet", cursor: "pointer" }}>
            {row.name}
          </span>
          // {/* </Link> */}
          // </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let path = "/executive-details";
          path += "/";
          path += row.userModel.userId;
          // eslint-disable-next-line no-unused-vars
          path += "/";

          moduleApi.postData(apiCall.getUser + row.userId).then((element) => {
            // var user=response.autoFinanceUser;

            var user = {
              branchId: element.autoFinanceBranchDetails?.branchId,
              branchLocation: element.autoFinanceBranchDetails?.branchLocation,
              branchName: element.autoFinanceBranchDetails?.branchName,
              branchemail: element.autoFinanceBranchDetails?.branchemail,
              organizationId: element.autoFinanceBranchDetails?.organizationId,
              phoneNumber: element.autoFinanceBranchDetails?.phoneNumber,
              addedByUserId: element.autoFinanceUser.addedByUserId,
              reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
              email: element.autoFinanceUser.email,
              employeeId: element.autoFinanceUser.employeeId,
              image: element.autoFinanceUser.image,
              mobileNumber: element.autoFinanceUser.mobileNumber,
              name: element.autoFinanceUser.name,
              passwordUpdated: element.autoFinanceUser.passwordUpdated,
              reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
              roleName: element.autoFinanceUser.roleName,
              status: element.autoFinanceUser.status,
              userId: element.autoFinanceUser.userId,
              designation: element.autoFinanceUser.companyDesignation,
            };

            let body = {
              organizationId: element.autoFinanceBranchDetails?.organizationId,
              userIds: [row.userId],
            };

            moduleApi
              .postData(apiCall.getLiveLocations, body)
              .then((response2) => {
                response2?.forEach((item) => {
                  user.batteryPercentage = item.batteryPercentage;
                  user.gpsData = item.gpsData;
                  user.lastUpdatedAt = item.lastUpdatedAt;
                });
                localStorage.setItem("executiveDetails", JSON.stringify(user));
                localStorage.setItem("prevUrl", "customers");
                history.push("/executive-details");
              });

            // console.log(response);
            //   setManagerData(response);
          });
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          // setBasicModal(true);
          setToggle(true);
        },
      },
    },
  ];

  const fieldInvstgncolumns = [
    {
      text: "Employee",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.customerName
          ? row.fieldInvestigationVisit.customerName
          : "-";
      },
    },
    {
      text: "Application Number",
      dataField: "applicationNo",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.applicationNo
          ? row.fieldInvestigationVisit.applicationNo
          : "-";
      },
    },
    {
      text: "Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <span
            style={{
              fontWeight: "bold",
              color:
                row.fieldInvestigationVisit?.status === "Positive"
                  ? "green"
                  : "red",
            }}
          >
            {row.fieldInvestigationVisit?.status}
          </span>
        );
      },
    },
    // {
    //   text: (
    //     <div>
    //       <span>Approval</span>
    //       <br></br>
    //       <span>Status</span>
    //     </div>
    //   ),
    //   dataField: "approvalStatus",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.fieldInvestigationVisit?.approvalStatus === "APPROVED") {
    //       return (
    //         <span className="badge badge-rounded badge-success">Approved</span>
    //       );
    //     } else if (row.fieldInvestigationVisit?.approvalStatus === "REJECTED") {
    //       return (
    //         <span className="badge badge-rounded badge-danger">Rejected</span>
    //       );
    //     } else if (row.fieldInvestigationVisit?.approvalStatus === "PENDING") {
    //       return (
    //         <span
    //           className="badge badge-rounded badge-warning"
    //           style={{ color: "black" }}
    //         >
    //           Pending
    //         </span>
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    // {
    //   text: "Rejection Reason",
    //   dataField: "rejectionType",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (col, row) => {
    //     return row.fieldInvestigationVisit?.rejectionType
    //       ? row.fieldInvestigationVisit.rejectionType
    //       : "-";
    //   },
    // },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-success shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          console.log("customerDetailsssss", visitedPlacesDetailsList);
          // setBasicModal(true);
          setToggle(true);
        },
      },
    },
  ];

  const getMobileNumberForCallHistory = () => {
    if (visitedPlacesDetailsList) {
      if (visitedPlacesDetailsList.collectionVisit) {
        return visitedPlacesDetailsList.collectionVisit?.contactNumber;
      } else if (visitedPlacesDetailsList.salesVisit) {
        return visitedPlacesDetailsList.salesVisit?.contactNumber;
      } else if (visitedPlacesDetailsList.fieldInvestigationVisit) {
        return visitedPlacesDetailsList.fieldInvestigationVisit?.contactNumber;
      }
    }
  };

  const handleClose = () => {
    setToggle(false);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadVistedPlacesData(
      0,
      visitType,
      key,
      selectedFIStatus,
      collectionType,
      collectionSubType
    );
  };

  // const setApprovalStatusValue = (value) => {
  //   setSelectedApprovalStatus(value);
  //   loadVistedPlacesData(0, visitType, searchKey, value, selectedFIStatus);
  // };

  const setFIStatusValue = (value) => {
    setSelectedFIStatus(value);
    loadVistedPlacesData(0, visitType, searchKey, value);
  };

  const reloadVisitData = () => {
    loadVistedPlacesData(
      currPage,
      visitType,
      searchKey,
      selectedFIStatus,
      collectionType,
      collectionSubType
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "10px",
          marginRight: "20px",
        }}
      >
        <SearchBar
          placeholder="Search By Name/Contact/Loan No"
          searchKeyword={searchKeyword}
        />
        {/* {hasAccess(42) && (
          <div
            style={{
              //float: "right",
              //margin: "10px",
              // height:'10px',
              // width:'35px',
              marginBottom: "5px",
              marginRight: "5px",
              marginLeft: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
          >
            <Button
              className="me-2"
              variant="success btn-rounded"
              onClick={() => {
                // piopiy.call('917036478282')
                // setCallAudioModal(true);
                openModal();
              }}
            >
              <i className="fa fa-phone fa-1x fa-rotate-90" />
              &nbsp;Call
            </Button>
          </div>
        )} */}
        {visitType === "Collections" && (
          <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            marginRight: "10px",
          }}
        >
          <label style={{ fontWeight: "bold",marginLeft:"10px"}}>
            Status
          </label>
          <div
            style={{
              float: "right",
              width: "150px",
              marginLeft: "10px",
              marginBottom: "20px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                loadVistedPlacesData(
                  0,
                  visitType,
                  searchKey,
                  selectedFIStatus,
                  value.value,
                  collectionSubType
                );
                setCollectionTypeValue(value);

                getSubStatusCollectionOptions(value.value);
              }}
              defaultValue={{ label: "All", value: "" }}
              options={collectionStatuses}
              id="collectiontype"
            />
          </div>
          </div>
        )}

        {visitType === "Collections" && isSubStatus === true && (
          <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            marginRight: "10px",
          }}
        >
          <label style={{ fontWeight: "bold" }}>
           Sub Status
          </label>
          <div
            style={{
              float: "right",
              width: "150px",
              marginBottom: "20px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                loadVistedPlacesData(
                  0,
                  visitType,
                  searchKey,
                  selectedFIStatus,
                  collectionType,
                  value.value
                );
                setCollectionSubType(value.value);
                setSelectedCollectionSubType(value);
              }}
              defaultValue={{ label: "All", value: "" }}
              value={selectedCollectionSubType}
              options={collectionSubStatuses}
              // id="collectiontype"
            />
          </div>
          </div>
        )}
        {isFieldInvstgn && (

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              marginRight: "10px",

            }}
          >
            <label style={{ fontWeight: "bold" }}>
              Status
            </label>
          <div
            style={{
              float: "right",
              width: "150px",
              height: "38px",
              marginLeft: "10px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
              marginBottom: "20px",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                setFIStatusValue(value.value);
              }}
              defaultValue={{ label: "All", value: "" }}
              options={fiStatusOptions}
            />
          </div>
          </div>
        )}
        {/* {isFieldInvstgn && (
          <div
            style={{
              float: "right",
              width: "150px",
              height:'38px',
              marginRight: "20px",
              marginLeft: "20px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                setApprovalStatusValue(value.value);
              }}
              defaultValue={{ label: "All", value: "" }}
              options={approvalStatusOptions}
            />
          </div>
        )} */}
        <Button
          style={{
            marginRight: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() =>
            customerListDownloadData(
              visitType,
              searchKey,
              selectedFIStatus,
              collectionType,
              collectionSubType
            )
          }
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "-5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo3"
              data={
                isSalesVisit
                  ? visitedPlacesList
                  : isFieldInvstgn
                  ? fieldInvstgtnList
                  : visitedPlacesList
              }
              columns={
                isSalesVisit
                  ? salesVisitColumns
                  : isFieldInvstgn
                  ? fieldInvstgncolumns
                  : columns
              }
            />
          </div>
          {/* {isSalesVisit ? (
            <div
              style={{
                overflow: "auto",
                maxHeight: "500px",
              }}
            >
              <BootstrapTable
                keyField="panNo3"
                data={visitedPlacesList}
                columns={salesVisitColumns}
              />
            </div>
          ) : (
            <div
              style={{
                overflow: "auto",
                maxHeight: "500px",
              }}
            >
              {isFieldInvstgn ? (
                <BootstrapTable
                  keyField="panNo2"
                  data={fieldInvstgtnList}
                  columns={fieldInvstgncolumns}
                />
              ) : (
                <BootstrapTable
                  keyField="panNo"
                  data={visitedPlacesList}
                  columns={columns}
                />
              )}
            </div>
          )} */}
          {fieldInvstgtnList.length === 0 && visitedPlacesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" show={toggle} size="xl">
        <Modal.Header>
          <Modal.Title>Customer Information</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setToggle(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
        >
          <div>
            <div className="custom-tab-1">
              <Tab.Container defaultActiveKey={"dashboard"}>
                <Nav as="ul" className="nav-tabs-custom nav-justified">
                  <Nav.Item as="li" key="0">
                    <Nav.Link eventKey="dashboard">
                      <b> Details </b>
                    </Nav.Link>
                  </Nav.Item>
                  {/* {visitedPlacesDetailsList?.visitType === "Collections" ? ( */}
                  <Nav.Item as="li" key="1">
                    <Nav.Link eventKey="history">
                      <b>Visit History </b>
                    </Nav.Link>
                  </Nav.Item>
                  {visitType==="Collections" &&(
                  <Nav.Item as="li" key="3">
                    <Nav.Link eventKey="nonVisitedPaymentsHistory">
                      <b>Non Visited Payments History </b>
                    </Nav.Link>
                  </Nav.Item>
  )}
                  {/* ) : null} */}
                  {hasAccess(42) && (
                    <Nav.Item as="li" key="2">
                      <Nav.Link eventKey="callOverview">
                        <b> Call History </b>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item as="li" key="4">
                      <Nav.Link eventKey="smsHistory">
                        <b> SMS History </b>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" key="5">
                      <Nav.Link eventKey="whatsAppHistory">
                        <b> WhatsApp History </b>
                      </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="pt-4">
                  <Tab.Pane eventKey="dashboard" key="0">
                    <CustomerDetails
                      visitedPlacesDetailsList={visitedPlacesDetailsList}
                      reloadVisitData={reloadVisitData}
                      close={handleClose}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="history" key="1">
                    <VisitDetailsHistory
                      loanNumber={
                        visitedPlacesDetailsList?.collectionVisit?.loanNumber
                      }
                      visitType={visitedPlacesDetailsList?.visitType}
                      custName={
                        visitedPlacesDetailsList?.salesVisit?.customerName
                      }
                      custNumber={
                        visitedPlacesDetailsList?.salesVisit?.contactNumber
                      }
                      applicationNo={
                        visitedPlacesDetailsList?.fieldInvestigationVisit
                          ?.applicationNo
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="nonVisitedPaymentsHistory" key="3">
                    <NonVisitedPaymentsHistory
                      loanNumber={
                        visitedPlacesDetailsList?.collectionVisit?.loanNumber
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="callOverview" key="2">
                    <CallSummary
                      mobileNo={getMobileNumberForCallHistory()}
                      loanNumber={
                        visitedPlacesDetailsList?.collectionVisit?.loanNumber
                      }
                    />
                     </Tab.Pane>
                    <Tab.Pane eventKey="smsHistory" key="4">
                    <SmsHistory
                      mobileNo={getMobileNumberForCallHistory()}
                      loanNumber={
                        visitedPlacesDetailsList?.collectionVisit?.loanNumber ? visitedPlacesDetailsList?.collectionVisit?.loanNumber :undefined
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="whatsAppHistory" key="5">
                    <WhatsAppHistory
                      mobileNo={getMobileNumberForCallHistory()}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal className="fade" show={callAudioModal} size="md">
        <Modal.Header>
          <Modal.Title>Manual Call</Modal.Title>
          {isCallRejected === true && (
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                closeModal();
                // setCallAudioModal(false);
                setCallMobileNo(null);
                // setIsOnGoingCall(false);
              }}
            ></Button>
          )}
          {isCallRejected === false && (
            <Button
              variant=""
              // className="btn-minus"
              onClick={() => {
                minimizeModal();
              }}
            >
              <div
                style={{
                  // marginBottom: "5px",
                  borderRadius: "50%",
                  border: "2px solid #25A6E7",
                  boxShadow: "1px 1px 2px #25A6E7",
                  backgroundColor: "#25A6E7",
                  padding: "10px",
                  height: "10px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i class="fa fa-minus" style={{ color: "white" }}></i>
              </div>
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          <Row style={{ justifyContent: "center" }}>
            {isMobileField === true && (
              <label style={{ fontWeight: "bold", textAlign: "center" }}>
                Mobile No
              </label>
            )}
            {isMobileField === true && (
              <input
                style={{
                  width: "300px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  // marginLeft: "10px",
                }}
                type="number"
                minLength={0}
                maxLength={9999999999}
                placeholder="Enter Mobile No"
                onChange={(e) => {
                  setCallMobileNo(e.target.value);
                }}
              />
            )}

            {isMobileField === true &&
              (callMobileNo?.length < 10 || callMobileNo?.length > 10) && (
                <span style={{ marginTop: "5px",  textAlignLast:'center' }} className="error">
                  Mobile No is Invalid
                </span>
              )}
            {isMobileField === true && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => {
                    // setCallAudioModal(false);
                    closeModal();
                    setCallMobileNo(null);
                    // setIsOnGoingCall(false);
                    // setIsMobileField(true);
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => {
                    if (callMobileNo !== null) {
                      if (callMobileNo?.length === 10) {
                        // makeCall(callMobileNo);
                        // setCallAudioModal(false);
                        // piopiy.call("91" + callMobileNo);
                        startCall(callMobileNo, true);
                        // setIsMobileField(false);
                        // setIsCallAnswered(true);
                        // setIsCallRejected(false);
                      } else if (
                        callMobileNo?.length < 10 ||
                        callMobileNo?.length > 10
                      ) {
                        return swal("Please Enter Valid Mobile No", {
                          icon: "error",
                          dangerMode: true,
                        });
                      }
                    } else if (callMobileNo === null) {
                      return swal("Please Enter Mobile No", {
                        icon: "error",
                        dangerMode: true,
                      });
                    }
                  }}
                >
                  <i className="fa fa-phone fa-1x fa-rotate-90" /> Call
                </Button>
              </div>
            )}
            {isCallAnswered === true && (
              <span style={{ textAlignLast: "center" }}>
                Calling to{" "}
                <span style={{ fontWeight: "bold" }}>{callMobileNo}</span>...{" "}
              </span>
            )}
            {isCallAnswered === true && (
              <img
                src="/sidebar/phone.gif"
                alt=""
                style={{
                  width: "100px",
                  height: "75px",
                  // marginLeft: "60px",
                  marginTop: "20px",
                }}
              />
            )}
            {isCallAnswered === true && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop:'40px' }}>
                <div
                  onClick={() => {
                    endCall();
                    // piopiy.terminate();
                    // setIsOnGoingCall(false);
                    setCallMobileNo(null);
                    // setIsHold(false);
                    // setIsMute(false);
                    // setIsMobileField(true);
                    // setIsCallRejected(true);
                  }}
                  style={{
                    marginBottom: "5px",
                    borderRadius: "50%",
                    border: "2px solid red",
                    boxShadow: "1px 1px 2px #008000",
                    backgroundColor: "red",
                    padding: "10px",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fa fa-phone fa-2x fa-rotate-270"
                    style={{
                      color: "white",
                    }}
                  />
                </div>
                <span style={{ fontWeight: "bold" }}>
                  End
                </span>
              </div>
            )}
            {isOnGoingCall === true && (
              <span style={{ marginBottom: "10px", textAlignLast:'center' }}>
                Call with{" "}
                <span style={{ fontWeight: "bold" }}>{callMobileNo}</span> is in
                progress...
              </span>
            )}
            {isOnGoingCall === true && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {isHold === true && (
                    <div
                      onClick={() => {
                        unHoldCall();
                        // piopiy.unHold();
                        // setIsHold(false);
                        // setIsCallRejected(false);
                        console.log("hold---false");
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid grey",
                        boxShadow: "1px 1px 2px grey",
                        backgroundColor: "grey",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-pause fa-2x "
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  )}
                  {isHold === false && (
                    <div
                      onClick={() => {
                        holdCall();
                        // piopiy.hold();
                        // setIsHold(true);
                        // setIsCallRejected(false);
                        console.log("hold---true");
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid black",
                        boxShadow: "1px 1px 2px black",
                        backgroundColor: "white",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-pause fa-2x "
                        style={{
                          color: "black",
                        }}
                      />
                    </div>
                  )}
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    Hold
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    onClick={() => {
                      endCall();
                      // piopiy.terminate();
                      // setIsOnGoingCall(false);
                      setCallMobileNo(null);
                      // setIsHold(false);
                      // setIsMute(false);
                      // setIsMobileField(true);
                      // setIsCallRejected(true);
                    }}
                    style={{
                      marginBottom: "5px",
                      borderRadius: "50%",
                      border: "2px solid red",
                      boxShadow: "1px 1px 2px #008000",
                      backgroundColor: "red",
                      padding: "10px",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-phone fa-2x fa-rotate-270"
                      style={{
                        color: "white",
                      }}
                    />
                  </div>
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    End
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {isMute === true && (
                    <div
                      onClick={() => {
                        unmuteCall();
                        // piopiy.unMute();
                        // setIsMute(false);
                        // setIsCallRejected(false);
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid grey",
                        boxShadow: "1px 1px 2px grey",
                        backgroundColor: "grey",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-microphone-slash fa-2x"
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  )}
                  {isMute === false && (
                    <div
                      onClick={() => {
                        muteCall();
                        // piopiy.mute();
                        // setIsMute(true);
                        // setIsCallRejected(false);
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid black",
                        boxShadow: "1px 1px 2px black",
                        backgroundColor: "white",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-microphone-slash fa-2x"
                        style={{
                          color: "black",
                        }}
                      />
                    </div>
                  )}
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    Mute
                  </span>
                </div>
              </div>
            )}
          </Row>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default CustomerMgmt2;
