import React, { useEffect, useState } from "react";
// import SearchBar from "../CommonComponents/SearchBar";
import { Button, Modal, Spinner } from "react-bootstrap";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import ReactGA from "react-ga";
// import Select from "react-select";
// import swal from "sweetalert";
import { Link } from "react-router-dom";
import { getTokenInLocalStorage } from "../services/AuthService";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import AddSalesTeam from "./AddSalesTeam";
import EditSalesTeam from "./EditSalesTeam";
import { EpochToDate } from "../CommonComponents/Helper";
// import Flatpickr from "react-flatpickr";
// import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
// import "flatpickr/dist/plugins/monthSelect/style.css";
// import * as XLSX from "xlsx";
// import * as helper from "../CommonComponents/Helper";

const SalesTeams = () => {
  const [salesTeamsList, setSalesTeamsList] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [teamDetails, setTeamDetails] = useState(null);
  const [addSalesTeamModal, setAddSalesTeamModal] = useState(false);
  const [editSalesTeamModal, setEditSalesTeamModal] = useState(false);
  const [salesTeamRowDetails, setSalesTeamRowDetails] = useState(null);
  const [salesTeamDetailsModal, setSalesTeamDetailsModal] = useState(false);

  //   const [searchKey, setSearchKey] = useState("");
  //   const [leadsDownloadList, setLeadsDownloadList] = useState(null);
  //   const [start, setStart] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
  //       1000
  //   );
  //   const [end, setEnd] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
  //       1000 +
  //       86399
  //   );
  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview("Sales Teams");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {
  //     leadsDownloadList !== null && handleExport();
  //     setIsLoading(false);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [leadsDownloadList]);

  useEffect(() => {
    loadSalesData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const loadSalesData = (pageNo, searchKey,) => {
    var listData = [];
    setIsLoading(true);
    var payload = {
      //   starttime: start,
      //   endtime: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      teamName: "",
      //   searchKey: searchKey,
      //   reportCategory: "DETAILED",
      //   reportingOfficerId: "",
      //   reportType: "SalesAllocationSummary",
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.getSalesTeams, pageNo, 10), payload)
      .then((response) => {
        setCurrPage(pageNo);

        response?.content?.forEach((element) => {
          let data = {
            description: element.description ? element.description : "-",
            id: element.id ? element.id : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            teamName: element.teamName ? element.teamName : "-",
            teamType:element.teamType ? element.teamType :"-",
            callCount: element.callCount ? element.callCount : 0,
            createdTime: element.createdTime
              ? EpochToDate(element.createdTime)
              : "-",
            updatedTime: element.updatedTime
              ? EpochToDate(element.updatedTime)
              : "-",
            userData: element.userData,
            userIds: element.userIds,
          };
          listData.push(data);
        });
        console.log(listData);
        setSalesTeamsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    // loadSalesData(selectedPage - 1, searchKey, start, end);
    loadSalesData(selectedPage - 1);
  };

  const salesLeadsColumns = [
    {
      text: "Team Name",
      dataField: "teamName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Team Type",
      dataField: "teamType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Calls Count",
      dataField: "callCount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Description",
      dataField: "description",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Created At",
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Updated At",
      dataField: "updatedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          //getTeamMembersDetails(row);
          setSalesTeamRowDetails(row);
          setEditSalesTeamModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log(row);
          getTeamMembersDetails(row);
        },
      },
    },
  ];

  const handleCloseForAddOpportunity = () => {
    loadSalesData(currPage);
    setAddSalesTeamModal(false);
  };

  const handleCloseForEditTeams = () => {
    loadSalesData(currPage);
    setEditSalesTeamModal(false);
  };

  const getTeamMembersDetails = (data) => {
    let listData = [];
    data?.userData?.forEach((element) => {
      let data = {
        description: element.description ? element.description : "-",
        id: element.id ? element.id : "-",
        organizationId: element.organizationId ? element.organizationId : "-",
        mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
        userData: element.userDate,
        userIds: element.userIds,
        branchId: element.branchId ? element.branchId : "-",
        branchName: element.branchName ? element.branchName : "-",
        companyDesignation: element.companyDesignation
          ? element.companyDesignation
          : "-",
        email: element.email ? element.email : "-",
        empId: element.empId ? element.empId : "-",
        image: element.image ? element.image : "-",
        imageUploaded: element.imageUploaded,
        name: element.name ? element.name : "-",
        reportingOfficerId: element.reportingOfficerId
          ? element.reportingOfficerId
          : "-",
        reportingOfficerName: element.reportingOfficerName
          ? element.reportingOfficerName
          : "-",
        roleName: element.roleName ? element.roleName : "-",
        trackingEnabled: element.trackingEnabled,
        userId: element.userId ? element.userId : "-",
      };
      listData.push(data);
    });
    console.log(listData);
    setTeamDetails(listData);
    setSalesTeamDetailsModal(true);
  };

  const teamMembersColumns = [
    // {
    //   text: "Team Name",
    //   dataField: "teamName",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Emp Id",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Emp Name",
      dataField: "name",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Designation",
      dataField: "companyDesignation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Contact</span>
          <br></br>
          <span>Number</span>
        </div>
      ),
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Email",
      dataField: "email",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Reporting</span>
          <br></br>
          <span>Officer</span>
        </div>
      ),
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  //   const leadsDownloadData = () => {
  //     setIsLoading(true);

  //     var payload = {
  //       starttime: start,
  //       endtime: end,
  //       organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
  //       searchKey: searchKey,
  //       reportCategory: "DETAILED",
  //       reportingOfficerId: "",
  //       reportType: "SalesAllocationSummary",
  //     };

  //     moduleApi
  //       .postData(moduleApi.withPage(apiCall.reports, 0, 10), payload)
  //       .then((response) => {
  //         if (response) {
  //           if (response.totalElements > 5000) {
  //             setIsLoading(false);
  //             return swal({
  //               title: "Download Report",
  //               text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //               icon: "warning",
  //               buttons: true,
  //               dangerMode: true,
  //             }).then((willDelete) => {
  //               if (willDelete) {
  //                 setIsLoading(true);
  //                 getLeadsFullResponseData(
  //                   Math.floor(response.totalElements / 200)
  //                 );
  //               }
  //             });
  //           }

  //           getLeadsFullResponseData(Math.floor(response.totalElements / 200));
  //         } else {
  //           swal("Something Went Wrong, Please Try Later", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //     // }
  //   };

  //   async function getLeadsFullResponseData(size) {
  //     var tempList = [];
  //     var mgrListData = [];

  //     for (let i = 0; i <= size; i++) {
  //       var payload = {
  //         starttime: start,
  //         endtime: end,
  //         organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
  //         searchKey: searchKey,
  //         reportCategory: "DETAILED",
  //         reportingOfficerId: "",
  //         reportType: "SalesAllocationSummary",
  //       };
  //       await moduleApi
  //         .postData(moduleApi.withPage(apiCall.reports, i, 200), payload)
  //         .then((response) => {
  //           tempList.push(...response?.content);
  //         });
  //     }

  //     // console.log("templist", tempList);
  //     tempList?.forEach((element) => {
  //       let data = {
  //         userId: element.userId,
  //         empId: element.empId ? element.empId : "-",
  //         empName: element.empName ? element.empName : "-",
  //         totalAllocations: element.totalAllocations
  //           ? element.totalAllocations
  //           : 0,
  //         attempted: element.attempted ? element.attempted : 0,
  //         notAttempted: element.notAttempted ? element.notAttempted : 0,
  //         open: element.open ? element.open : 0,
  //         inProgress: element.inProgress ? element.inProgress : 0,
  //         completed: element.completed ? element.completed : 0,
  //         totalCalls: element.totalCalls ? element.totalCalls : 0,
  //       };
  //       mgrListData.push(data);
  //     });
  //     setLeadsDownloadList(mgrListData);
  //     // }
  //   }

  //   const handleExport = () => {
  //     let mgrTable = [
  //       {
  //         A: "Emp Id",
  //         B: "Emp Name",
  //         C: "Total Allocations",
  //         D: "Attempted",
  //         E: "Not Attempted",
  //         F: "Total Calls",
  //         G: "Open",
  //         H: "In Progress",
  //         I: "Completed",
  //       },
  //     ];
  //     leadsDownloadList?.forEach((row) => {
  //       mgrTable.push({
  //         A: row.empId,
  //         B: row.empName,
  //         C: row.totalAllocations,
  //         D: row.attempted,
  //         E: row.notAttempted,
  //         F: row.totalCalls,
  //         G: row.open,
  //         H: row.inProgress,
  //         I: row.completed,
  //       });
  //     });
  //     const finalData = [...mgrTable];
  //     // console.log("finaldata", finalData);
  //     //create a new workbook
  //     const wb = XLSX.utils.book_new();
  //     const sheet = XLSX.utils.json_to_sheet(finalData, {
  //       skipHeader: true,
  //     });
  //     // console.log("sheet", sheet);
  //     XLSX.utils.book_append_sheet(wb, sheet, "Allocations_Summary");
  //     XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

  //     XLSX.writeFile(
  //       wb,
  //       "Sales_Allocations_Summary_" +
  //         helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //         ".xlsx"
  //     );
  //     ReactGA.event({
  //       category: "Sales Allocations Summary List Download",
  //       action:
  //         userLoginDetails.autoFinanceOrganization.orgshortcode +
  //         "_Sales_Allocations_Summary" +
  //         helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //         ".xlsx",
  //     });
  //   };

  //   const searchKeyword = (key) => {
  //     setSearchKey(key);
  //     loadSalesData(0, key, start, end);
  //   };

  //   const setCustomDatesForGraph = (value1) => {
  //     var start = value1[0].getTime() / 1000;
  //     var end =
  //       new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
  //         1000 +
  //       86399;

  //     setStart(start);
  //     setEnd(end);
  //     loadSalesData(0, searchKey, start, end);
  //   };

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginTop: "10px",
          marginBottom: "5px",
          marginRight: "15px",
        }}
      >
        {/* <SearchBar
          placeholder="Search By Emp Id/Emp Name"
          searchKeyword={searchKeyword}
        />
        <div
          style={{
            float: "right",
            // margin: "10px",
            marginLeft: "10px",
            marginRight: "10px",
            // pointerEvents:
            //   isProgressiveDialing === true && "none",
          }}
        >
          <Flatpickr
            className="form-control d-block"
            style={{
              width: "170px",
              height: "40px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              textAlignLast: "center",
            }}
            placeholder="Select Month"
            onChange={(value) => {
              setCustomDatesForGraph(value);
            }}
            options={{
              defaultDate: new Date(),
              maxDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 2,
                0
              ),
              minDate: new Date().setMonth(new Date().getMonth() - 6),

              plugins: [
                new monthSelectPlugin({
                  shorthand: true, //defaults to false
                  dateFormat: "F Y", //defaults to "F Y"
                  altFormat: "F Y", //defaults to "F Y"
                  theme: "light", // defaults to "light"
                }),
              ],
            }}
          />
        </div>
        <div>
          <Button
            style={{
              float: "right",
              //   margin: "10px",
              marginRight: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="success btn-rounded"
            onClick={() => leadsDownloadData()}
          >
            <i className="fa fa-download"></i>
            &nbsp;&nbsp;Download
          </Button>
        </div> */}
        <div>
          <Button
            style={{
              float: "right",
              //   margin: "10px",
              marginRight: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => setAddSalesTeamModal(true)}
          >
            <i className="fa fa-plus"></i>
            &nbsp;&nbsp;Add Team
          </Button>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="id"
              data={salesTeamsList}
              columns={salesLeadsColumns}
            />
          </div>

          {salesTeamsList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" size="md" show={addSalesTeamModal}>
        <Modal.Header>
          <Modal.Title>Add Team</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddSalesTeamModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <AddSalesTeam close={handleCloseForAddOpportunity} />
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" size="md" show={editSalesTeamModal}>
        <Modal.Header>
          <Modal.Title>Edit Team</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditSalesTeamModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
           
          <EditSalesTeam 
          salesTeamRowDetails={salesTeamRowDetails}
          close={handleCloseForEditTeams} />

          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" size="lg" show={salesTeamDetailsModal}>
        <Modal.Header>
          <Modal.Title>Team Members</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setSalesTeamDetailsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <div
              style={{
                overflow: "auto",
                maxHeight: "500px",
              }}
            >
              <BootstrapTable
                keyField="id"
                data={teamDetails}
                columns={teamMembersColumns}
              />
            </div>

            {teamDetails?.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SalesTeams;
