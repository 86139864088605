import { Form, Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
// import { Link } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import TextField from "../../CommonComponents/TextField";
import clulogo from "../../images/circlelogo.png";
import whatsapp from "../../images/whatsapp.svg";
// import google from "../../images/google.svg";
import loginbg from "../../images/pic1.png";
// import { toaster } from "../../rest/apiUtils";
import * as moduleApi from "../../rest/moduleApi";
import { apiCall } from "../../rest/restApi";
import {
  loadingToggleAction,
  loginAction,
  otpLessLoginAction,
  setTeamSelectionModal,
  setSelectedTeam,
  loginConfirmedAction,
} from "../../store/actions/AuthActions";
import swal from "sweetalert";
import VisitLocationMap from "../../CommonComponents/VisitLocationMap";
import "./style.css";
import { secondsToHms } from "../../CommonComponents/Helper";
import { getTokenInLocalStorage } from "../../services/AuthService";
// import OtpLessLogin from "./OtpLessLogin";
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { CustomToaster } from "../../rest/apiUtils";
// import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [isForgot, setIsForgot] = useState(false);
  const [otpLessModal, setOtpLessModal] = useState(false);
  // const [teamSelectionModal, setTeamSelectionModal] = useState(false);
  const [isLocationPage, setIsLocationPage] = useState(false);
  const [isOtpPage, setIsOtpPage] = useState(true);
  const [updatedUsername, setUpdatedUsername] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isOrgId, setIsOrgId] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [custId, setCustId] = useState(null);
  const [address, setAddress] = useState(null);
  const [orgList, setOrgList] = useState([]);
  // const [teamList, setTeamList] = useState([]);
  const [selectedOrgName, setSelectedOrgName] = useState(null);
  // const [selectedTeamName, setSelectedTeamName] = useState(null);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [updatedLatLng, setUpdatedLatLng] = useState({ lat: 0, lng: 0 });
  const [timer, setTimer] = useState(180);
  const [isRunning, setIsRunning] = useState(false);
  const dispatch = useDispatch();
  
  const {teamSelectionModal, teamList, selectedTeam} = useSelector((state) => state.auth);


  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval); // Stop the timer when it reaches 0
            setIsRunning(false);
            return 180; // Reset the timer to 180 seconds
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  // var history = useHistory()

  useEffect(() => {
    // var url = "https://demo.clucloud.com";
    var url = window.location.href;
    var url2 = url.split("//", 2);
    var url3 = url2[1].split(".");
    if (url3[1] === "clucloud") {
      if (url3[0] !== "web" && url3[0] !== "demo" && url3[0] !== "webapp" && url3[0] !== "qatest" && url3[0] !== "uat")
        setOrgId(url3[0].toUpperCase());
      else {
        setIsOrgId(true);
      }
    } else {
      setIsOrgId(true);
    }
  }, []);

  // const sendNotificationToPtpPage = () => {
  //   history.push("/ptp/my-allocations");
  // };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://otpless.com/auth.js";
    script.id = "otplessIdScript";
    document.body.appendChild(script);

    window.otpless = (otplessUser) => {
      // alert(JSON.stringify(otplessUser));
      otpLessLogin(otplessUser?.token);
    };
    return () => {
      console.log("home is unmounting");
      const divid = document.getElementById("otplessIdScript");
      document.body.removeChild(divid);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (isOrgId) {
      if (orgId === "") {
        errorObj.orgId = "Organization ID is Required";
        error = true;
      }
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));
    dispatch(loginAction(orgId, email, password, props.history));
  }

  const otpLessLogin = (token) => {
    var payload = {
      data: {
        token: token,
      },
    };
    moduleApi.postData(apiCall.loginWithOtpLess, payload).then((response) => {
      console.log(response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          if (response?.length === 1) {
            dispatch(loadingToggleAction(true));
            dispatch(otpLessLoginAction(response[0], props.history));
          } else if (response?.length > 1) {
            const result = response?.map((item) => ({
              value: item,
              label: item?.autoFinanceOrganization?.organizationName,
            }));
            setOrgList(result);
            setOtpLessModal(true);
          } else if (response?.length === 0) {
            return swal("User Does Not Exist In Clu", {
              icon: "error",
              dangerMode: true,
            });
          }
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const otpValidate = Yup.object({
    otp: Yup.number().required("OTP is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string().required("Confirm Password is required"),
  });

  const emailValidate = Yup.object({
    username: Yup.string().required("Username is required"),
    orgId: Yup.string().required("Organization Id is required"),
  });

  const forgotPasswordHandleOk = (values) => {
    const payLoad = {
      // aceestoken: "string",
      // // otp: "string",
      // password: "string",
      username: values.username,
      orgshortcode: values.orgId,
    };
    moduleApi.postData(apiCall.forgotPassword, payLoad).then((response) => {
      if (response) {
        if (response.Message === "Otp Sent") {
          setUpdatedUsername(values);
          setIsOtpPage(false);
          swal("Otp Sent Successfully", {
            icon: "success",
          });
        } else if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const resendOtp = () => {
    const payLoad = {
      username: updatedUsername.username,
      orgshortcode: updatedUsername.orgId,
    };
    moduleApi.postData(apiCall.forgotPassword, payLoad).then((response) => {
      if (response?.Message === "Otp Sent") {
        setIsRunning(true)
        swal("Otp Sent Successfully", {
          icon: "success",
        });
      } else if (response?.message) {
        swal(response?.message, {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const verifyForgotPasswordHandleOk = (values) => {
    if (values.newPassword !== values.confirmPassword) {
      return swal("New Password & Confirm Password should be same", {
        icon: "error",
        dangerMode: true,
      });
    }
    const payLoad = {
      // aceestoken: "string",
      otp: values.otp,
      password: values.newPassword,
      username: updatedUsername.username,
      orgshortcode: updatedUsername.orgId,
    };
    moduleApi
      .postData(apiCall.verifyForgotPassword, payLoad)
      .then((response) => {
        if (response) {
          if (response.Message === "Password Updated") {
            handleForgotPage();

            swal(
              "Password Updated Successfully, Please login with new credentials",
              {
                icon: "success",
              }
            );
          } else if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const handleForgotPage = () => {
    setIsForgot(false);
    setIsOtpPage(true);
    setIsRunning(false)
  };

  const getLocationCoordinates = () => {
    if (custId === null) {
      return swal("Please Enter Customer ID", {
        icon: "error",
        dangerMode: true,
      });
    } else if (address === null) {
      var custBody = {
        id: custId,
      };
      moduleApi.postData(apiCall.getCustAddress, custBody).then((response) => {
        if (response) {
          if (response.message) {
            return swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            setLatLng({
              lat: response.latitude,
              lng: response.longitude,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
    } else {
      var body = {
        id: custId,
        address: address,
      };
      moduleApi.postData(apiCall.getcoordinates, body).then((response) => {
        if (response) {
          if (response.message) {
            return swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            setLatLng(response.Location);
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
        console.log("response", parseFloat(response.Location.lat));
      });
    }
  };

  const getLocationDetails = (details) => {
    console.log("locdetails", details);
    setUpdatedLatLng(details);
  };

  const saveLocationCoordinates = () => {
    if (custId === null) {
      return swal("Please Enter Customer ID", {
        icon: "error",
        dangerMode: true,
      });
    }
    var body = {
      id: custId,
      address: address,
      latitude: updatedLatLng.lat !== 0 ? updatedLatLng.lat : latLng.lat,
      longitude: updatedLatLng.lng !== 0 ? updatedLatLng.lng : latLng.lng,
    };
    console.log("body", body);
    moduleApi.postData(apiCall.saveCustAddress, body).then((response) => {
      if (response) {
        if (response.message) {
          return swal(response.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          swal("Address Saved Successfully", {
            icon: "success",
          });
          setIsLocationPage(false);
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  // const getFirebaseToken = () => {
  //   const firebaseConfig = {
  //     apiKey: "AIzaSyB8atYr2xkwGkmqfhSkui9FHk6TLybPs4Y",
  //     authDomain: "telecalling-qa.firebaseapp.com",
  //     databaseURL:
  //       "https://telecalling-qa-default-rtdb.asia-southeast1.firebasedatabase.app",
  //     projectId: "telecalling-qa",
  //     storageBucket: "telecalling-qa.appspot.com",
  //     messagingSenderId: "918263194810",
  //     appId: "1:918263194810:web:b3d99ba9f89a3cfcfb4e83",
  //     measurementId: "G-HG128X9HWW",
  //   };

  //   // Initialize Firebase
  //   const app = initializeApp(firebaseConfig);
  //   const messaging = getMessaging(app);
  //   getToken(messaging, {
  //     vapidKey:
  //       "BLLCSLlrdofwQ0ypidfpA8knFpzqHxh-R_kkTmMlOAnbE3jP6IPKOtlXU1iEjUeqOfJEjlEyX1ZjBI8la-C8xnA",
  //   })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         console.log("token", currentToken);
  //         return currentToken;
  //         // Send the token to your server and update the UI if necessary
  //         // ...
  //       } else {
  //         // Show permission request UI
  //         console.log(
  //           "No registration token available. Request permission to generate one."
  //         );
  //         // ...
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("An error occurred while retrieving token. ", err);
  //       // ...
  //     });

  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     // ...
  //   });
  // };

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <Modal className="fade" show={isLocationPage}>
        <Modal.Header>
          <Modal.Title>Plot Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setIsLocationPage(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
              padding: "5px",
            }}
          >
            <label className="mb-1">
              <strong>Customer Id</strong>
            </label>
            <input
              type="text"
              className="form-control"
              // value={orgId}
              onChange={(e) => setCustId(e.target.value)}
              placeholder="Enter Customer ID"
            />
            <label className="mb-1" style={{ marginTop: "10px" }}>
              <strong>Address</strong>
            </label>
            <input
              type="text"
              className="form-control"
              // value={orgId}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter Address"
            />

            <button
              // type="submit"
              style={{ marginTop: "10px" }}
              className="btn btn-primary btn-block"
              onClick={() => {
                getLocationCoordinates();
              }}
            >
              Submit
            </button>

            <div style={{ marginTop: "10px" }}>
              <VisitLocationMap
                latitide={parseFloat(latLng.lat)}
                longitude={parseFloat(latLng.lng)}
                getLocationDetails={getLocationDetails}
              />
            </div>
            <div>
              <button
                // type="submit"
                style={{
                  marginTop: "10px",
                  backgroundColor: "#63C02A",
                  borderColor: "#63C02A",
                }}
                className="btn btn-primary btn-block"
                onClick={() => {
                  saveLocationCoordinates();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div>
            {/* <img src={logo} alt="" /> */}
            <img
              src={clulogo}
              alt=""
              style={{ height: "130px", marginBottom: "15px" }}
            />
          </div>
          <h3 className="mb-2">Welcome back!</h3>
          {/* <p>User Experience & Interface Design <br />Strategy SaaS Solutions</p> */}
        </div>
        <div
          className="aside-image"
          style={{ backgroundImage: "url(" + loginbg + ")" }}
        ></div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form   form-validation">
                  {props.errorMessage && (
                    <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                      {props.errorMessage}
                    </div>
                  )}
                  {props.successMessage && (
                    <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                      {props.successMessage}
                    </div>
                  )}
                  <form onSubmit={onLogin} className="form-validate">
                    <h3 className="text-center mb-4 text-black">
                      Sign in your account
                    </h3>
                    {isOrgId && (
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Organization ID</strong>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={orgId}
                            onChange={(e) => setOrgId(e.target.value.trim())}
                            placeholder="Enter Organization ID"
                          />
                        </div>
                        {errors.orgId && (
                          <div className="text-danger fs-12">
                            {errors.orgId}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="val-email">
                        <strong>Email (or) Mobile No (or) Emp ID</strong>
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value.trim())}
                          placeholder="Enter Email/Mobile No/Emp ID"
                        />
                      </div>
                      {errors.email && (
                        <div className="text-danger fs-12">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-1">
                        <strong>Password</strong>
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type={!passwordShown ? "password" : "text"}
                          className="form-control"
                          value={password}
                          placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value.trim())}
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "17px",
                          }}
                        >
                          <i
                            style={{ cursor: "pointer" }}
                            onClick={togglePasswordVisiblity}
                            className={
                              !passwordShown ? "far fa-eye" : "far fa-eye-slash"
                            }
                          />
                        </div>
                      </div>
                      {errors.password && (
                        <div className="text-danger fs-12">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                      <div className="form-group mb-3">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsForgot(true)}
                        >
                          Forgot Password ?
                        </span>
                      </div>
                      <Modal className="fade" show={isForgot}>
                        <Modal.Header>
                          <Modal.Title>Forgot Password</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={handleForgotPage}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {isOtpPage ? (
                            <div>
                              <Formik
                                initialValues={{
                                  username: "",
                                  orgId: "",
                                }}
                                onSubmit={forgotPasswordHandleOk}
                                validationSchema={emailValidate}
                              >
                                {({ formik, errors }) => (
                                  <div>
                                    <Row>
                                      <Col lg="12">
                                        <Form>
                                          <div>
                                            <TextField
                                              isAsterisk={true}
                                              label="User Name"
                                              name="username"
                                              type="text"
                                              placeholder="Enter Email ID/Mobile Number"
                                            />
                                            <TextField
                                              isAsterisk={true}
                                              label="Organization Id"
                                              name="orgId"
                                              type="text"
                                              placeholder="Enter Organization Id"
                                            />
                                          </div>
                                          <div>
                                            <Button
                                              style={{ float: "right" }}
                                              className="col-lg-2 m-2"
                                              type="submit"
                                            >
                                              Submit
                                            </Button>
                                            <Button
                                              style={{ float: "right" }}
                                              className="col-lg-2 m-2 float-right"
                                              variant="danger light"
                                              onClick={() => setIsForgot(false)}
                                            >
                                              Close
                                            </Button>
                                          </div>
                                        </Form>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </Formik>
                            </div>
                          ) : (
                            <div>
                              <Formik
                                initialValues={{
                                  otp: "",
                                  newPassword: "",
                                  confirmPassword: "",
                                }}
                                onSubmit={verifyForgotPasswordHandleOk}
                                validationSchema={otpValidate}
                              >
                                {({ formik, errors }) => (
                                  <div>
                                    <Row>
                                      <Col lg="12">
                                        <Form>
                                          <div>
                                          {/* <TextField
                                              isAsterisk={true}
                                              label="OTP"
                                              name="otp"
                                              type="number"
                                              placeholder="Enter OTP"
                                            />
                                          <TextField
                                              isAsterisk={true}
                                              label="New Password"
                                              name="newPassword"
                                              type="text"
                                              placeholder="Enter New Password"
                                            />
                                          <TextField
                                              isAsterisk={true}
                                              label="Confirm Password"
                                              name="confirmPassword"
                                              type="text"
                                              placeholder="Enter Confirm Password"
                                            /> */}
                                            <label
                                              className="control-label"
                                              style={{
                                                fontWeight: "bold",
                                                float: "left",
                                                marginBottom: "5px",
                                                marginTop: "5px",
                                              }}
                                            >
                                              OTP
                                            </label>
                                            <Field
                                              type="number"
                                              name="otp"
                                              className={`form-control`}
                                            />
                                            <ErrorMessage
                                              component="div"
                                              className="error"
                                              name="otp"
                                            />
                                            {/* <TextField
                                              isAsterisk={true}
                                              label="OTP"
                                              name="otp"
                                              type="number"
                                            /> */}
                                            <label
                                              className="control-label"
                                              style={{
                                                fontWeight: "bold",
                                                float: "left",
                                                marginBottom: "5px",
                                                marginTop: "5px",
                                              }}
                                            >
                                              New Password
                                            </label>
                                            <Field
                                              type="text"
                                              name="newPassword"
                                              className={`form-control`}
                                            />
                                            <ErrorMessage
                                              component="div"
                                              className="error"
                                              name="newPassword"
                                            />

                                            {/* <TextField
                                              isAsterisk={true}
                                              label="New Password"
                                              name="newPassword"
                                              type="password"
                                            /> */}

                                            <label
                                              className="control-label"
                                              style={{
                                                fontWeight: "bold",
                                                float: "left",
                                                marginBottom: "5px",
                                                marginTop: "5px",
                                              }}
                                            >
                                              Confirm Password
                                            </label>
                                            <Field
                                              type="text"
                                              name="confirmPassword"
                                              className={`form-control`}
                                            />
                                            <ErrorMessage
                                              component="div"
                                              className="error"
                                              name="confirmPassword"
                                            />
                                            {/* <TextField
                                              isAsterisk={true}
                                              label="Confirm Password"
                                              name="confirmPassword"
                                              type="password"
                                            /> */}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              flexDirection: "column",
                                            }}
                                          >
                                            {isRunning===false?
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                                color: "red",
                                                display: "flex",
                                                justifyContent: "end",
                                              }}
                                            >
                                              <span onClick={resendOtp}>
                                                Resend OTP
                                              </span>
                                            </div>:<div
                                              style={{
                                                
                                                color: "black",
                                                display: "flex",
                                                justifyContent: "end",
                                              }}
                                            >
                                              <span >
                                                Resend OTP in {secondsToHms(timer)}
                                              </span></div>}
                                            <div style={{ marginTop: "10px" }}>
                                              <Button
                                                style={{
                                                  float: "right",
                                                  marginLeft: "10px",
                                                }}
                                                //className="col-lg-2 m-2"
                                                type="submit"
                                              >
                                                Submit
                                              </Button>
                                              <Button
                                                style={{ float: "right" }}
                                                // className="col-lg-2 m-2 float-right"
                                                variant="danger light"
                                                onClick={handleForgotPage}
                                              >
                                                Close
                                              </Button>
                                            </div>
                                          </div>
                                        </Form>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </Formik>
                            </div>
                          )}
                        </Modal.Body>
                      </Modal>
                    </div>
                    <div className="text-center form-group mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Sign In
                      </button>

                      {/* <button
                        // type="submit"
                        style={{ marginTop: "10px" }}
                        className="btn btn-primary btn-block"
                        onClick={() => {
                          setCustId(null);
                          setAddress(null);
                          setIsLocationPage(true);
                        }}
                      >
                       Plot Location
                      </button> */}
                      <div style={{ marginTop: "5px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "nowrap",
                            width: "100%",
                          }}
                        >
                          <hr
                            className="w-100"
                            style={{
                              background: "rgb(207, 214, 221)",
                              border: "0.5px solid rgb(23, 25, 26)",
                            }}
                          />
                          <p
                            className="semi-bold"
                            style={{
                              fontSize: 12,
                              color: "rgb(39, 46, 53)",
                              padding: "0px 10px",
                              whiteSpace: "nowrap",
                              marginTop: "12px",
                            }}
                          >
                            OR
                          </p>
                          <hr
                            className="w-100"
                            style={{
                              background: "rgb(207, 214, 221)",
                              border: "0.5px solid rgb(23, 25, 26)",
                            }}
                          />
                        </div>
                        {/* <p
                          className="bold"
                          style={{
                            fontSize: 12,
                            color: "rgb(39, 46, 53)",
                            padding: "0px 10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Login With
                        </p> */}
                        <div
                          id="otpless"
                          custom="true"
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            flexWrap: "wrap",
                            borderRadius: 15,
                            gap: 15,
                            overflow: "hidden",
                            transition: "all 0.25s ease-in-out 0s",
                            backgroundColor: "white",
                          }}
                        >
                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // padding: "5px 28px",
                              borderRadius: 15,
                              position: "relative",
                              color: "rgb(74, 84, 94)",
                              cursor: "pointer",
                              flexWrap: "nowrap",
                              gap: 8,
                              width: "100%",
                              opacity: 1,
                              transition: "all 0.25s ease-in-out 0s",
                              border: "1px solid rgb(158, 168, 179)",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={whatsapp}
                              alt=""
                              loading="lazy"
                              style={{ height: 30 }}
                            />
                            <p
                              className="semi-bold no-wrap"
                              style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                marginTop: "13px",
                              }}
                            >
                              Continue with WhatsApp
                            </p>
                          </div>
                        </div>

                        {/* <div
                          id="otpless"
                          custom="true"
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            flexWrap: "wrap",
                            gap: 15,
                            overflow: "hidden",
                            transition: "all 0.25s ease-in-out 0s",
                          }}
                        >
                          <div
                            // id="otpless"
                            // custom="true"
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 5,
                              borderRadius: 50,
                              position: "relative",
                              color: "rgb(74, 84, 94)",
                              cursor: "pointer",
                              flexWrap: "nowrap",
                              gap: 8,
                              width: "4.0vw",
                              opacity: 1,
                              transition: "all 0.25s ease-in-out 0s",
                              border: "1px solid rgb(158, 168, 179)",
                              background: "transparent",
                              height: "4.0vw",
                            }}
                            // onClick={()=>{
                            //   setOtpLessModal(true)
                            // }}
                          >
                            <img
                              src={whatsapp}
                              alt=""
                              loading="lazy"
                              style={{
                                width: "70%",
                                transition: "all 0.25s ease-in-out 0s",
                              }}
                            />
                          </div>
                          <div
                          
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 8,
                              borderRadius: 50,
                              position: "relative",
                              color: "rgb(74, 84, 94)",
                              cursor: "pointer",
                              flexWrap: "nowrap",
                              gap: 8,
                              width: "4.0vw",
                              opacity: 1,
                              transition: "all 0.25s ease-in-out 0s",
                              border: "1px solid rgb(158, 168, 179)",
                              background: "transparent",
                              height: "4.0vw",
                            }}
                     
                          >
                            <img
                              src={google}
                              alt=""
                              loading="lazy"
                              style={{
                                width: "70%",
                                transition: "all 0.25s ease-in-out 0s",
                              }}
                            />
                          </div>
                        </div> */}
                        <Modal className="fade" show={otpLessModal}>
                          <Modal.Header>
                            <Modal.Title>Organization Selection</Modal.Title>
                            <Button
                              variant=""
                              className="btn-close"
                              onClick={() => setOtpLessModal(false)}
                            ></Button>
                          </Modal.Header>
                          <Modal.Body>
                            <div style={{ margin: "10px" }}>
                              <Row>
                                <Col>
                                  <div
                                    style={{
                                      marginBottom: "5px",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label>
                                      <h6>Organization Name</h6>
                                    </label>
                                    <div
                                      style={{
                                        float: "right",
                                        width: "250px",
                                        height: "38px",
                                        borderRadius: "3px",
                                        borderWidth: "1px",
                                        boxShadow: "1px 1px 2px #797979",
                                      }}
                                    >
                                      <Select
                                        isSearchable={false}
                                        onChange={(value) => {
                                          setSelectedOrgName(value.value);
                                        }}
                                        placeholder="Select Organization Name"
                                        // value={executivesList[executiveFilterIndex]}
                                        options={orgList}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col>
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                    style={{
                                      marginTop: 35 + "px",
                                      marginLeft: "15px",
                                    }}
                                    onClick={() => {
                                      if (selectedOrgName !== null) {
                                        dispatch(loadingToggleAction(true));
                                        dispatch(
                                          otpLessLoginAction(
                                            selectedOrgName,
                                            props.history
                                          )
                                        );
                                        setOtpLessModal(false);
                                      } else {
                                        return swal(
                                          "Please Select Organization Name",
                                          {
                                            icon: "error",
                                            dangerMode: true,
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    Submit
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </Modal.Body>
                        </Modal>
                        <Modal className="fade" show={teamSelectionModal}>
                          <Modal.Header>
                            <Modal.Title>Team Selection</Modal.Title>
                            {/* <Button
                              variant=""
                              className="btn-close"
                              onClick={() => dispatch(setTeamSelectionModal(false))}
                            ></Button> */}
                          </Modal.Header>
                          <Modal.Body>
                            <div>
                              <Row>
                                <Col>
                                  <div
                                    style={{
                                      marginTop:'-10px',
                                      // marginBottom: "5px",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label>
                                      <h6>Team Name</h6>
                                    </label>
                                    <div
                                      style={{
                                        float: "right",
                                        width: "250px",
                                        height: "38px",
                                        borderRadius: "3px",
                                        borderWidth: "1px",
                                        boxShadow: "1px 1px 2px #797979",
                                      }}
                                    >
                                      <Select
                                        isSearchable={false}
                                        onChange={(value) => {
                                          dispatch(setSelectedTeam(value.value));
                                        }}
                                        placeholder="Select Team Name"
                                        // value={executivesList[executiveFilterIndex]}
                                        options={teamList}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col>
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                    style={{
                                      marginTop: 25 + "px",
                                      marginLeft: "15px",
                                    }}
                                    onClick={() => {
                                      var temp = getTokenInLocalStorage();
                                      temp.selectedTeam= selectedTeam
                                      localStorage.setItem('userDetails', JSON.stringify(temp));
                                      dispatch(loginConfirmedAction(temp));
                                      props.history.push("/home")
                                      dispatch(setTeamSelectionModal(false))
                                    }}
                                  >
                                    Submit
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
