import * as XLSX from "xlsx";
import { EpochToOnlyDate } from "../CommonComponents/Helper";
import { toaster } from "../rest/apiUtils";
import moment from "moment";
// import { camelize } from "../DB/utils";
import { getTokenInLocalStorage } from "../services/AuthService";

var userLoginDetails = getTokenInLocalStorage();

const getLineNo = (i) => {
  return (i += 2);
};

export function ExcelDateToJSDate(serial, type) {
  if (moment(serial, "DD/MM/YYYY", true).isValid()) {
    return serial;
  } else if (moment(serial, "DD-MM-YYYY", true).isValid()) {
    return serial;
  } else {
    var utc_days = Math.floor(serial - 25569);
    if (isNaN(utc_days)) {
      return false;
    } else {
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      var monthValue = date_info.getMonth() + 1;
      let month = monthValue < 10 ? "0" + monthValue : monthValue;
      if (isNaN(date_info.getDate())) {
        return false;
      } else {
        let dateVal =
          date_info.getDate() < 10
            ? "0" + date_info.getDate()
            : date_info.getDate();
        if (type === "DATE_CHECK") {
          return date_info.getFullYear() + "-" + month + "-" + dateVal;
        } else {
          return dateVal + "-" + month + "-" + date_info.getFullYear();
        }
      }
    }
  }
}

export function formatDate(dueDate) {
  let formattedDate = "";
  if (
    moment(dueDate, "DD/MM/YYYY", true).isValid() ||
    moment(dueDate, "DD-MM-YYYY", true).isValid()
  ) {
    if (typeof dueDate === "string") {
      var formattedDays;
      var intervalStart = dueDate + " 23:59:59";
      if (dueDate.includes("-")) {
        formattedDays = intervalStart.split(" ")[0].split("-");
      } else if (dueDate.includes("/")) {
        formattedDays = intervalStart.split(" ")[0].split("/");
      }
      var formattedTime = intervalStart.split(" ")[1].split(":");
      var epochStart =
        new Date(
          formattedDays[2],
          formattedDays[1] - 1,
          formattedDays[0],
          formattedTime[0],
          formattedTime[1],
          formattedTime[2],
          0
        ).getTime() / 1000;
      formattedDate = EpochToOnlyDate(epochStart);
    } else {
      formattedDate = EpochToOnlyDate(
        new Date(Date.UTC(0, 0, dueDate - 1)) / 1000
      );
    }
  }
  return formattedDate;
}

export function specificDateFormat(dateVal) {
  let dateSplit = dateVal.split("-");
  return dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
}

export function customizeDateTime(dateVal) {
  let dateSplit = specificDateFormat(dateVal.substring(0, 10));
  let timeVal = dateVal.substring(11, 19);
  return dateSplit + " " + timeVal;
}

export function getStartAndEndDate(value) {
  let month = value[0].getMonth() + 1;
  let year = value[0].getFullYear();
  let nextMonth;
  let nextYear;
  let startDate;
  let endDate;
  if (month < 12) {
    nextMonth = month + 1;
    startDate = year + "-" + month + "-01";
    endDate = year + "-" + nextMonth + "-01";
  } else {
    nextMonth = "01";
    nextYear = year + 1;
    startDate = year + "-" + month + "-01";
    endDate = nextYear + "-" + nextMonth + "-01";
  }
  return { startDate: startDate, endDate: endDate };
}

export function convertToLocalDateTime(
  dateVal = "2022-01-07T23:34:21.017Z",
  type
) {
  let d = new Date(Date.parse(dateVal));
  let dateSplit = d.toString().split(" ");
  var months_arr = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  if (type === "ONLY_DATE") {
    return dateSplit[1] + " " + dateSplit[2] + "," + dateSplit[3];
  } else if (type === "DATE_MM_DD_YYYY") {
    return dateSplit[2] + "-" + months_arr[d.getMonth()] + "-" + dateSplit[3];
  } else if (type === "DATE_YYYY_MM_DD") {
    return dateSplit[3] + "-" + months_arr[d.getMonth()] + "-" + dateSplit[2];
  } else {
    return (
      dateSplit[1] +
      " " +
      dateSplit[2] +
      " " +
      dateSplit[3] +
      "," +
      dateSplit[4]
    );
  }
}

export function convertToLocalDateTime2(
  dateVal = "2022-01-07T23:34:21.017Z",
  type
) {
  // let d = new Date(Date.parse(dateVal));
  let dateSplit = dateVal?.toString().split("T");
  // var months_arr = [
  //   "01",
  //   "02",
  //   "03",
  //   "04",
  //   "05",
  //   "06",
  //   "07",
  //   "08",
  //   "09",
  //   "10",
  //   "11",
  //   "12",
  // ];
  if (type === "ONLY_DATE") {
    return dateSplit[1] + " " + dateSplit[2] + "," + dateSplit[3];
  } else if (type === "DATE_MM_DD_YYYY") {
    // return dateSplit[2] + "-" + months_arr[d.getMonth()] + "-" + dateSplit[3];
  } else if (type === "DATE_YYYY_MM_DD") {
    // return dateSplit[3] + "-" + months_arr[d.getMonth()] + "-" + dateSplit[2];
  } else {
    // console.log('dateSplit--', dateSplit, "type", type);
    return (
      dateSplit[0] + " , " + dateSplit[1].split(".")[0]
      // " " +
      // dateSplit[3] +
      // "," +
      // dateSplit[4]
    );
  }
}

function isDuplicate(entry, arr, fiType) {
  // eslint-disable-next-line
  return arr.some(
    (x) =>
      entry.applicationNo !== "" &&
      entry.applicantType === x.applicantType &&
      entry.applicationNo === x.applicationNo &&
      entry.fieldInvestigationType?.toLowerCase().replace(/\s+/g, "") ===
        x.fieldInvestigationType?.toLowerCase().replace(/\s+/g, "")
  );
}

// function validateFiType=()=>{

// }

function validatePhoneNo(inputtxt) {
  var phoneno = /^\d{10}$/;
  if (inputtxt.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}

function checkDueDate(dueDate) {
  let formattedDueDate = ExcelDateToJSDate(dueDate, "DATE_CHECK");
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  if (new Date(formattedDueDate) < now) {
    return false;
  } else {
    return true;
  }
  //     var ToDate = new Date();
  //     console.log(new Date(formattedDueDate).getTime())
  //     console.log(ToDate.getTime())
  //   if (new Date(formattedDueDate).getTime() <= ToDate.getTime()) {

  //     return false;
  //   }
  // return true;
}

export const getCurrentDateInDDMMYYYYFormat = () => {
  let currentDate = new Date();

  // Extract day, month, and year
  let day = currentDate.getDate().toString().padStart(2, "0");
  let month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Note: January is 0
  let year = currentDate.getFullYear().toString();

  // Format the date as "DDMMYYYY"
  let formattedDate = day + month + year;
  return formattedDate;
};

// const getAddedNumber = (tempNumber, count, countToBeAdd) => {
//   // let tempNumber = '000000';
//   if (count !== null && count !== 0 && count !== undefined) {
//     let tempValue = parseInt(tempNumber, 10);
//     // Add the count
//     let resultValue = tempValue + count;
//     // Convert the result back to a string with leading zeros
//     let resultString = resultValue.toString().padStart(tempNumber.length, "0");
//     //  let temp =  parseInt(tempNumber)+parseInt(count)
//     return resultString;
//   } else {
//     if (countToBeAdd !== null && countToBeAdd !== 0 && countToBeAdd !== undefined) {
//       let tempValue2 = parseInt(tempNumber, 10);
//       // Add the count
//       let resultValue2 = tempValue2 + countToBeAdd;
//       // Convert the result back to a string with leading zeros
//       let resultString2 = resultValue2.toString().padStart(tempNumber.length, "0");
//       //  let temp =  parseInt(tempNumber)+parseInt(count)
//       return resultString2;
//   }}
// };

// const getLatestApplicationNo = (type, count, countToBeAdd) => {
//   if (type.toLowerCase() === "office") {
//     return (
//       "FI_OFF_" +
//       // getCurrentDateInDDMMYYYYFormat() +
//       // "_" +
//       getAddedNumber("000000", count ? parseInt(count) + countToBeAdd : 0, countToBeAdd)
//     );
//   } else {
//     return (
//       "FI_RESI_" +
//       // getCurrentDateInDDMMYYYYFormat() +
//       // "_" +
//       getAddedNumber("000000", count ? parseInt(count) + countToBeAdd : 0, countToBeAdd)
//     );
//   }
// };

export const validateAndStrctureUploadedData = async (
  uploadedData,
  userId,
  assignTo,
  agency,
  agencyName,
  usersList,
  fiAllocationsOfEmp,
  fiAllocationsOfAgencies,
  // latestAppicationNoCount,
  fiType,
  handleCloseLoading,
  fiClients
) => {
  let requestPayload = [];
  let excelData = [];
  // eslint-disable-next-line
  uploadedData.map((item, i) => {
    let obj = {
      applicationNo: item.applicationNo
        ? item.applicationNo.toString().trim()
        : "",
      client: item.client ? item.client.toString().trim() : "",
      client_branch: item.client_branch
        ? item.client_branch.toString().trim()
        : "",
      client_state: item.client_state
        ? item.client_state.toString().trim()
        : "",
      cpv_verification_geo_limit: item.cpv_verification_geo_limit
        ? item.cpv_verification_geo_limit.toString().trim()
        : "",
      applicantType: item.applicantType
        ? item.applicantType.toString().trim()
        : "",
      employeeName: item.employeeName
        ? item.employeeName.toString().trim()
        : "",
      employeeId: item.employeeId ? item.employeeId.toString().trim() : "",
      fieldInvestigationType: item.fieldInvestigationType
        ? item.fieldInvestigationType.toString().trim()
        : "",
      productType: item.productType ? item.productType?.toString().trim() : "",
      // productName: item.productName ? item.productName.toString().trim() : "",
      customerName: item.customerName
        ? item.customerName.toString().trim()
        : "",
      co_applicant_name: item.co_applicant_name
        ? item.co_applicant_name.toString().trim()
        : "",
      customerFatherName: item.customerFatherName
        ? item.customerFatherName.toString().trim()
        : "",
      city: item.city ? item.city.toString().trim() : "",
      addressLineOne: item.addressLineOne
        ? item.addressLineOne.toString().trim()
        : "",
      dueDate: item.dueDate ? item.dueDate.toString().trim() : "",
      nearByLandmark: item.nearByLandmark
        ? item.nearByLandmark.toString().trim()
        : "",
      postalCode: item.postalCode ? item.postalCode.toString().trim() : "",
      state: item.state ? item.state.toString().trim() : "",
      contactNo: item.contactNo ? item.contactNo.toString().trim() : "",
      office_name: item.office_name ? item.office_name.toString().trim() : "",
      customer_designation: item.customer_designation
        ? item.customer_designation.toString().trim()
        : "",
    };
    excelData.push(obj);
  });

  let newArray = [];
  for (const entry of excelData) {
    if (!isDuplicate(entry, newArray, fiType)) {
      newArray.push(entry);
    }
  }

  if (newArray.length !== excelData.length) {
    handleCloseLoading();
    return toaster(
      "error",
      "Duplicate Allocation No or Applicant Type in Excel"
    );
  }

  // let tempCount = 1;

  for (let i = 0; i < excelData?.length; i++) {
    // if (
    //   !excelData[i].applicationNo ||
    //   excelData[i].applicationNo.toString().trim() === ""
    // ) {
    //   excelData[i].applicationNo = getLatestApplicationNo(
    //     fiType,
    //     latestAppicationNoCount,
    //     tempCount
    //   );
    //   tempCount++;
    //   // return toaster(
    //   //   "error",
    //   //   "Application Number is missing at Line Number " + getLineNo(i)
    //   // );
    // }
    if (
      !excelData[i].applicantType ||
      excelData[i].applicantType.toString().trim() === ""
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Applicant Type is missing at Line Number " + getLineNo(i)
      );
    } else if (
      !excelData[i].contactNo ||
      excelData[i].contactNo.toString().trim() === ""
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Contact Number is missing at Line Number " + getLineNo(i)
      );
    } else if (
      (!excelData[i].employeeId ||
        excelData[i].employeeId.toString().trim() === "") &&
      assignTo !== "Agency"
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Employee ID is missing at Line Number " + getLineNo(i)
      );
    }
    // else if (
    //   !excelData[i].fieldInvestigationType ||
    //   excelData[i].fieldInvestigationType.toString().trim() === ""
    // ) {
    //   return toaster(
    //     "error",
    //     "Field Investigation Type is missing at Line Number " + getLineNo(i)
    //   );
    // }
    // else if (
    //   !excelData[i].productType ||
    //   excelData[i].productType.toString().trim() === ""
    // ) {
    //   return toaster(
    //     "error",
    //     "Product Type is missing at Line Number " + getLineNo(i)
    //   );
    // }
    // else if (
    //   !excelData[i].productName ||
    //   excelData[i].productName.toString().trim() === ""
    // ) {
    //   return toaster(
    //     "error",
    //     "Product Name is missing at Line Number " + getLineNo(i)
    //   );
    // }
    else if (
      !excelData[i].customerName ||
      excelData[i].customerName.toString().trim() === ""
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Customer Name is missing at Line Number " + getLineNo(i)
      );
    } else if (
      !excelData[i].addressLineOne ||
      excelData[i].addressLineOne.toString().trim() === ""
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Customer Address Line 1 is missing at Line Number " + getLineNo(i)
      );
    } else if (
      excelData[i].contactNo &&
      excelData[i].contactNo !== "" &&
      !validatePhoneNo(excelData[i].contactNo)
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Contact Number is invalid at Line Number " + getLineNo(i)
      );
    } else if (
      fiType.toString().trim().toLowerCase() === "office" &&
      !excelData[i].office_name &&
      excelData[i].office_name === ""
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Office Name is missing at Line Number " + getLineNo(i)
      );
    }
    // else if (
    //   fiType.toString().trim().toLowerCase() ===
    //     "office" &&
    //   !excelData[i].customer_designation &&
    //   excelData[i].customer_designation === ""
    // ) {
    //   return toaster(
    //     "error",
    //     "Customer Designation is missing at Line Number " + getLineNo(i)
    //   );
    // }
    else if (excelData[i].contactNo.length < 10) {
      handleCloseLoading();
      return toaster(
        "error",
        "Contact Number is less or more than 10 digits at Line Number  " +
          getLineNo(i)
      );
    }
    // else if (
    //   fiType.toString().trim().toLowerCase() !==
    //     "residence" &&
    //     fiType.toString().trim().toLowerCase() !==
    //     "office"
    // ) {
    //   return toaster(
    //     "error",
    //     "FI Type is invalid at Line Number " + getLineNo(i)
    //   );
    // }
    else if (excelData[i].dueDate === "") {
      handleCloseLoading();
      return toaster(
        "error",
        "Due date is missing at Line Number " + getLineNo(i)
      );
    } else if (
      excelData[i].dueDate &&
      ExcelDateToJSDate(excelData[i].dueDate) === false
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Due date is invalid at Line Number " + getLineNo(i)
      );
    } else if (
      excelData[i].dueDate !== "" &&
      !checkDueDate(excelData[i].dueDate)
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Due date must be future or today's date at Line Number " + getLineNo(i)
      );
    } else if (
      excelData[i].client !== "" &&
      fiClients.some(
        (item) =>
          item?.label?.toUpperCase()?.replace(/\s+/g, "") ===
          excelData[i].client?.toUpperCase()?.replace(/\s+/g, "")
      ) === false
    ) {
      // var isClientValid = fiClients.some(item => item?.label?.toLowerCase() === excelData[i].client?.toLowerCase())
      // if (isClientValid===false) {
      handleCloseLoading();
      return toaster(
        "error",
        "Client is Invalid at Line Number " + getLineNo(i)
      );
      // }
    } else if (
      excelData[i].applicantType &&
      excelData[i].applicantType.toString().trim().toLowerCase() !==
        "applicant" &&
      excelData[i].applicantType.toString().trim().toLowerCase() !==
        "co-applicant" &&
      excelData[i].applicantType.toString().trim().toLowerCase() !==
        "connector" &&
      excelData[i].applicantType.toString().trim().toLowerCase() !== "dsa" &&
      excelData[i].applicantType.toString().trim().toLowerCase() !== "guarantor"
    ) {
      handleCloseLoading();
      return toaster(
        "error",
        "Applicant Type is invalid at Line Number " + getLineNo(i)
      );
    } else {
      let empUserId = [];
      let invalidUsers = [];
      let inactiveUsers = [];
      let duplicateEmpAllocations = [];
      let duplicateAgencyAllocations = [];

      if (assignTo === "Employee") {
        duplicateEmpAllocations = fiAllocationsOfEmp.filter(
          (item) =>
            item.application_number ===
              excelData[i].applicationNo?.toString().trim() &&
            item.applicant_type ===
              excelData[i].applicantType.toString().trim() &&
            item.fi_type?.toLowerCase().replace(/\s+/g, "") ===
              excelData[i]?.fieldInvestigationType
                ?.toString()
                .trim()
                ?.toLowerCase()
                .replace(/\s+/g, "") &&
            item.status !== "Closed"
        );
        empUserId = usersList.filter(
          (item) =>
            item.employee_id === uploadedData[i].employeeId.toString().trim()
        );
        invalidUsers = usersList.filter(
          (item) =>
            item.employee_id === uploadedData[i].employeeId.toString().trim() &&
            (item.role_name === "MANAGER_RA" || item.role_name === "ADMIN")
        );
        inactiveUsers = usersList.filter(
          (item) =>
            item.employee_id === uploadedData[i].employeeId.toString().trim() &&
            item.status === "INACTIVE"
        );
      }

      if (assignTo === "Agency") {
        duplicateAgencyAllocations = fiAllocationsOfAgencies.filter(
          (item) =>
            item.application_number ===
              excelData[i].applicationNo.toString().trim() &&
            item.applicant_type ===
              excelData[i].applicantType.toString().trim() &&
            item.fi_type ===
              excelData[i].fieldInvestigationType.toString().trim() &&
            item.status !== "Closed"
        );
      }
      if (
        duplicateEmpAllocations.length > 0 ||
        duplicateAgencyAllocations.length > 0
      ) {
        handleCloseLoading();
        return toaster(
          "error",
          "An allocation is already done, for Application Number at Line Number " +
            getLineNo(i)
        );
      }
      if (invalidUsers.length > 0) {
        handleCloseLoading();
        return toaster(
          "error",
          "Allocations are not allowed for Read-only Managers/Admin at Line Number " +
            getLineNo(i)
        );
      }
      if (inactiveUsers.length > 0) {
        handleCloseLoading();
        return toaster(
          "error",
          "Employee Id is inactive at Line Number " + getLineNo(i)
        );
      } else if (empUserId.length === 0 && assignTo !== "Agency") {
        handleCloseLoading();
        return toaster(
          "error",
          "Employee ID doesn't exists at Line Number " + getLineNo(i)
        );
      } else {
        let obj = {
          emp_user_id:
            empUserId && empUserId.length > 0 ? empUserId[0].user_id : null,
          emp_name: excelData[i].employeeName
            ? excelData[i].employeeName.trim()
            : null,
          customer_name: excelData[i].customerName
            ? excelData[i].customerName.trim()
            : null,
          client: excelData[i].client
            ? // ? excelData[i].client.trim()?.toUpperCase()
              getFiClientName(fiClients, excelData[i].client.trim())
            : null,
          client_branch: excelData[i].client_branch
            ? excelData[i].client_branch.trim()
            : null,
          client_state: excelData[i].client_state
            ? excelData[i].client_state.trim()
            : null,
          cpv_verification_geo_limit: excelData[i].cpv_verification_geo_limit
            ? excelData[i].cpv_verification_geo_limit.trim()
            : null,
          co_applicant_name: excelData[i].co_applicant_name
            ? excelData[i].co_applicant_name.trim()
            : null,
          office_name: excelData[i].office_name
            ? excelData[i].office_name.trim()
            : null,
          customer_designation: excelData[i].customer_designation
            ? excelData[i].customer_designation.trim()
            : null,
          application_number: excelData[i].applicationNo
            ? excelData[i].applicationNo.toString().trim()
            : null,
          contact_no: excelData[i].contactNo
            ? excelData[i].contactNo.toString().trim()
            : null,
          due_date: excelData[i].dueDate
            ? ExcelDateToJSDate(excelData[i].dueDate)
            : null,
          manager_emp_id:
            assignTo === "Manager" ? excelData[i].employeeId.trim() : null,
          manager_name:
            assignTo === "Manager" ? excelData[i].employeeName.trim() : null,
          agency_id: assignTo === "Agency" ? agency.trim() : null,
          agency_name: assignTo === "Agency" ? agencyName.trim() : null,
          fi_type: excelData[i].fieldInvestigationType
            ? getReadableFormat(excelData[i].fieldInvestigationType.trim())
            : null,
          product_type: excelData[i].productType
            ? excelData[i].productType.trim()
            : null,
          // product_name: excelData[i].productName
          //   ? excelData[i].productName.trim()
          //   : null,
          father_name: excelData[i].customerFatherName
            ? excelData[i].customerFatherName.trim()
            : null,
          address_line1: excelData[i].addressLineOne
            ? excelData[i].addressLineOne.trim()
            : null,
          address_line2: null,
          city: excelData[i].city ? excelData[i].city.trim() : null,
          state: excelData[i].state ? excelData[i].state.trim() : null,
          country: null,
          postal_code: excelData[i].postalCode
            ? excelData[i].postalCode.toString().trim()
            : null,
          nearby_landmark: excelData[i].nearByLandmark
            ? excelData[i].nearByLandmark.trim()
            : null,
          status: "Pending",
          created_by: userId ? userId : null,
          fi_status: "Pending",
          applicant_type: excelData[i].applicantType
            ? excelData[i].applicantType.trim()
            : null,
          organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
        };
        requestPayload.push(obj);
      }
    }
  }
  // console.log(requestPayload);
  return requestPayload;
};

const getFiClientName = (data, fiClient) => {
  const matchedItem = data?.find((item) => {
    const normalizedLabel = item?.label?.toUpperCase()?.replace(/\s+/g, "");
    const normalizedFiClient = fiClient?.toUpperCase()?.replace(/\s+/g, "");
    return normalizedLabel === normalizedFiClient;
});

if (matchedItem) {
    console.log(true);
    return matchedItem.label;
} else {
    console.log(false);
}
};

const temp = [
  "otheroffice",
  "currentoffice",
  "permanentresidence",
  "currentresidence",
];

// Define a mapping for readable formats
const readableFormats = {
  otheroffice: "Other Office",
  currentoffice: "Current Office",
  permanentresidence: "Permanent Residence",
  currentresidence: "Current Residence",
};

// Function to normalize the input and map to readable format
function getReadableFormat(fiType) {
  // Normalize the input
  const normalizedType = fiType?.toLowerCase().replace(/\s+/g, "");

  // Check if the normalized input is in the temp array
  if (temp.includes(normalizedType)) {
    return readableFormats[normalizedType];
  } else {
    return "Invalid Type"; // Handle cases where the type is not found
  }
}

export const downloadAllocationsForEmployees = (fiAllocationList) => {
  let excelFormattedData = [];
  if (fiAllocationList.length > 0) {
    fiAllocationList.map((item) => {
      let assignedDate = item.created_at;
      let substrDate = assignedDate.substr(0, 10);
      let dateArray = substrDate.split("-");
      let updatedDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
      let formattedAssignedDate = formatDate(updatedDate);
      let obj = [
        { value: item.application_number },
        { value: item.client },
        { value: item.client_branch },
        { value: item.client_state },
        { value: item.cpv_verification_geo_limit },
        { value: item.emp_name != null ? item.emp_name : "" },
        { value: item.employee_id != null ? item.employee_id : "" },
        { value: item.fi_type },
        { value: item.product_type },
        // { value: item.product_name },
        { value: item.customer_name },
        { value: item.co_applicant_name },
        { value: item.contact_no },
        { value: item.office_name },
        { value: item.customer_designation },
        { value: item.address_line1 },
        { value: item.city },
        { value: item.state },
        { value: formattedAssignedDate },
        { value: formatDate(item.due_date) },
        { value: item.status },
        { value: item.fi_status },
      ];
      return excelFormattedData.push(obj);
    });
  }

  const downloadAllocationsData = [
    {
      columns: [
        {
          title: "Application Number",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Employee Name",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Employee ID",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "FI Type",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Product Type",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        // {
        //   title: "Product Name",
        //   width: { wch: 30 },
        //   style: {
        //     fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
        //   },
        // },
        {
          title: "Customer Name",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        }, //char width
        {
          title: "Contact Number",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Address Line1",
          width: { wch: 40 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "City",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        }, //pixels width
        {
          title: "State",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        }, //char width
        {
          title: "Assigned Date",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Due Date",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Allocation Status",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "FI Status",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
      ],
      data: excelFormattedData,
    },
  ];
  return downloadAllocationsData;
};

export const downloadAllocationsForAgencies = (fiAllocationList) => {
  let excelFormattedData = [];
  if (fiAllocationList.length > 0) {
    fiAllocationList.map((item) => {
      let assignedDate = item.created_at;
      let substrDate = assignedDate.substr(0, 10);
      let dateArray = substrDate.split("-");
      let updatedDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
      let formattedAssignedDate = formatDate(updatedDate);
      let obj = [
        { value: item.application_number },
        { value: item.agency_name != null ? item.agency_name : "" },
        { value: item.fi_type },
        { value: item.product_type },
        { value: item.product_name },
        { value: item.customer_name },
        { value: item.contact_no },
        { value: item.address_line1 },
        { value: item.city },
        { value: item.state },
        { value: formattedAssignedDate },
        { value: formatDate(item.due_date) },
        { value: item.status },
        { value: item.fi_status },
      ];
      return excelFormattedData.push(obj);
    });
  }

  const downloadAllocationsData = [
    {
      columns: [
        {
          title: "Application Number",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Agency Name",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "FI Type",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Product Type",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Product Name",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Customer Name",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Contact Number",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Address Line1",
          width: { wch: 40 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "City",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "State",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Assigned Date",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Due Date",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Allocation Status",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "FI Status",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
      ],
      data: excelFormattedData,
    },
  ];
  return downloadAllocationsData;
};

export const downloadAgencyLoginSummaryAllocations = (fiAllocationList) => {
  let excelFormattedData = [];
  if (fiAllocationList.length > 0) {
    fiAllocationList.map((item) => {
      let obj = [
        { value: item.agency_name !== null ? item.agency_name : "" },
        { value: item.totalcount },
        { value: item.pendingcount },
        { value: item.closedcount },
      ];
      return excelFormattedData.push(obj);
    });
  }

  const downloadAllocationsData = [
    {
      columns: [
        {
          title: "Agency Name",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Total Allocations",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Open",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Closed",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
      ],
      data: excelFormattedData,
    },
  ];
  return downloadAllocationsData;
};

export const downloadAgencySummaryAllocations = (fiAllocationList, type) => {
  let excelFormattedData = [];
  if (fiAllocationList.length > 0) {
    fiAllocationList.map((item) => {
      let obj = [
        { value: item.agency_name !== null ? item.agency_name : "" },
        { value: item.totalcount },
        { value: item.pendingcount },
        { value: item.inprogresscount },
        { value: item.closedcount },
      ];
      return excelFormattedData.push(obj);
    });
  }

  const downloadAllocationsData = [
    {
      columns: [
        {
          title: "Agency Name",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Total Allocations",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Open",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "In Progress",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Closed",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
      ],
      data: excelFormattedData,
    },
  ];
  return downloadAllocationsData;
};

export const downloadEmployeeSummaryAllocations = (fiAllocationList) => {
  let excelFormattedData = [];
  if (fiAllocationList.length > 0) {
    fiAllocationList.map((item) => {
      let obj = [
        { value: item.manager_name !== null ? item.manager_name : "" },
        { value: item.manager_emp_id !== null ? item.manager_emp_id : "" },
        { value: item.totalcount },
        { value: item.pendingcount },
        { value: item.inprogresscount },
        { value: item.closedcount },
      ];
      return excelFormattedData.push(obj);
    });
  }

  const downloadAllocationsData = [
    {
      columns: [
        {
          title: "Employee Name",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Employee ID",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Total Allocations",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Open",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "In Progress",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Closed",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
      ],
      data: excelFormattedData,
    },
  ];
  return downloadAllocationsData;
};

export const downloadDetailedSummaryAllocations = (fiAllocationList) => {
  let excelFormattedData = [];
  if (fiAllocationList.length > 0) {
    fiAllocationList.map((item) => {
      let obj = [
        { value: item.emp_name !== null ? item.emp_name : "" },
        { value: item.employee_id !== null ? item.employee_id : "" },
        { value: item.totalcount },
        { value: item.pendingcount },
        { value: item.inprogresscount },
        { value: item.closedcount },
      ];
      return excelFormattedData.push(obj);
    });
  }

  const downloadAllocationsData = [
    {
      columns: [
        {
          title: "Employee Name",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Employee ID",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Total Allocations",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Open",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "In Progress",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
        {
          title: "Closed",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
      ],
      data: excelFormattedData,
    },
  ];
  return downloadAllocationsData;
};

const getHeaderRow = (sheet) => {
  var headers = [];
  var range = XLSX.utils.decode_range(sheet["!ref"]);
  var C,
    R = range.s.r;
  for (C = range.s.c; C <= range.e.c; ++C) {
    var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
    var hdr = "UNKNOWN " + C;
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
    headers.push(hdr.trim());
  }
  return headers;
};

export const getFilteredExcelData = (
  excelFile,
  setExcelData,
  assignTo,
  fiType,
  setIsSubmit
) => {
  const workbook = XLSX.read(excelFile, { type: "buffer" });
  const worksheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[worksheetName];
  let headers = getHeaderRow(worksheet);
  let filteredHeaders = headers.filter((item) => item.indexOf("UNKNOWN"));

  let target =
    assignTo === "Agency"
      ? excelHeadersAgencies
      : fiType.toLowerCase() === "office"
      ? excelOfficeHeadersEmployees
      : excelHeadersEmployees;

  let found = (filteredHeaders, target) =>
    target.every((v) => filteredHeaders.includes(v.trim()));
  if (
    found(filteredHeaders, target) &&
    filteredHeaders.length === target.length
  ) {
    const uploadedData = XLSX.utils.sheet_to_json(worksheet, {
      defval: "",
    });
    const skipHeaderData = XLSX.utils.json_to_sheet(uploadedData, {
      skipHeader: true,
    });
    const allocationsExcelData = XLSX.utils.sheet_to_json(skipHeaderData, {
      header:
        assignTo === "Agency"
          ? headerTitlesAgencies
          : fiType === "office"
          ? officeHeaderTitlesEmployees
          : headerTitlesEmployees,
    });
    let filteredData = [];
    if (assignTo === "Agency") {
      filteredData = allocationsExcelData.filter(
        (x) =>
          !(
            x.applicationNo === "" &&
            x.applicantType === "" &&
            // x.fieldInvestigationType === "" &&
            x.productName === "" &&
            x.customerName === "" &&
            x.customerFatherName === "" &&
            x.contactNo === "" &&
            x.addressLineOne === "" &&
            x.city === "" &&
            x.state === "" &&
            x.postalCode === "" &&
            x.nearByLandmark === "" &&
            isEmptyOrSpaces(x.dueDate) &&
            isEmptyOrSpaces(x.productType)
          )
      );
    } else {
      if (fiType === "office") {
        filteredData = allocationsExcelData.filter(
          (x) =>
            !(
              x.applicationNo === "" &&
              x.client === "" &&
              x.client_branch === "" &&
              x.client_state === "" &&
              x.cpv_verification_geo_limit === "" &&
              x.office_name === "" &&
              x.customer_designation === "" &&
              x.applicantType === "" &&
              x.employeeId === "" &&
              x.employeeName === "" &&
              x.fieldInvestigationType === "" &&
              // x.productName === "" &&
              x.customerName === "" &&
              x.contactNo === "" &&
              x.addressLineOne === "" &&
              x.city === "" &&
              x.state === "" &&
              x.postalCode === "" &&
              x.nearByLandmark === "" &&
              isEmptyOrSpaces(x.dueDate) &&
              isEmptyOrSpaces(x.productType)
            )
        );
      } else {
        filteredData = allocationsExcelData.filter(
          (x) =>
            !(
              x.applicationNo === "" &&
              x.client === "" &&
              x.client_branch === "" &&
              x.client_state === "" &&
              x.cpv_verification_geo_limit === "" &&
              x.co_applicant_name === "" &&
              x.applicantType === "" &&
              x.employeeId === "" &&
              x.employeeName === "" &&
              x.fieldInvestigationType === "" &&
              // x.productName === "" &&
              x.customerName === "" &&
              x.customerFatherName === "" &&
              x.contactNo === "" &&
              x.addressLineOne === "" &&
              x.city === "" &&
              x.state === "" &&
              x.postalCode === "" &&
              x.nearByLandmark === "" &&
              isEmptyOrSpaces(x.dueDate) &&
              isEmptyOrSpaces(x.productType)
            )
        );
      }
      validateData(allocationsExcelData, fiType);
    }
    // console.log('filterredData',filteredData);
    setExcelData(filteredData);
    setIsSubmit(true);
  } else {
    setExcelData([]);
    setIsSubmit(false);
    return toaster("error", "Incorrect Template");
  }
};

const validTypes = {
  office: ["otheroffice", "currentoffice"],
  residence: ["permanentresidence", "currentresidence"],
};

function normalizeString(str) {
  return str.toLowerCase().replace(/\s/g, "");
}

function validateData(data, fitype) {
  const invalidIndices = [];

  data.forEach((item, index) => {
    const normalizedFieldType = normalizeString(item.fieldInvestigationType);
    let isValid = false;

    if (fitype === "office") {
      isValid = validTypes.office.includes(normalizedFieldType);
    } else if (fitype === "residence") {
      isValid = validTypes.residence.includes(normalizedFieldType);
    } else {
      console.error(`Invalid fitype provided at index ${index}`);
      return;
    }

    if (!isValid) {
      invalidIndices.push(index + 2);
    }
  });

  if (invalidIndices.length > 0) {
    return toaster(
      "error",
      `Invalid FI Type at Line Number: ${invalidIndices.join(", ")}`
    );
  } else {
    console.log("All Fi Types values are valid.");
  }
}

function isEmptyOrSpaces(str) {
  return str === null || str.match(/^ *$/) !== null;
}

export const getFileKeyName = (imageUrl) => {
  let imageSplit = imageUrl.split("/");
  let fileKeyName = "";
  if (imageSplit[3] !== undefined && imageSplit[4] !== undefined) {
    fileKeyName = imageSplit[3] + "/" + imageSplit[4];
  } else {
    fileKeyName = imageUrl;
  }
  return fileKeyName;
};

export const getFileExtension = (fileName) => {
  var ext = fileName?.split(".");
  ext = "." + ext[ext?.length - 1];
  return ext;
};

export const officeHeaderTitlesEmployees = [
  "applicationNo",
  "client",
  "client_branch",
  "client_state",
  "cpv_verification_geo_limit",
  "applicantType",
  "fieldInvestigationType",
  "employeeName",
  "employeeId",
  "productType",
  // "productName",
  "customerName",
  // "co_applicant_name",
  // "customerFatherName",
  "contactNo",
  "office_name",
  "customer_designation",
  "addressLineOne",
  "city",
  "state",
  "postalCode",
  "nearByLandmark",
  "dueDate",
];
export const headerTitlesEmployees = [
  "applicationNo",
  "client",
  "client_branch",
  "client_state",
  "cpv_verification_geo_limit",
  "applicantType",
  "fieldInvestigationType",
  "employeeName",
  "employeeId",
  "productType",
  // "productName",
  "customerName",
  "co_applicant_name",
  "customerFatherName",
  "contactNo",
  // "office_name",
  // "customer_designation",
  "addressLineOne",
  "city",
  "state",
  "postalCode",
  "nearByLandmark",
  "dueDate",
];

export const excelOfficeHeadersEmployees = [
  "Application No",
  "Client",
  "Client Branch",
  "Client State",
  "CPV Verification Geo Limit",
  "Applicant Type",
  "FI Type",
  "Employee Name",
  "Employee ID",
  "Product Type",
  // "Product Name",
  "Customer Name",
  "Contact No",
  "Office Name",
  "Customer Designation",
  "Address Line 1",
  "City",
  "State",
  "Postal Code",
  "Near By Landmark",
  "Due Date",
];
export const excelHeadersEmployees = [
  "Application No",
  "Client",
  "Client Branch",
  "Client State",
  "CPV Verification Geo Limit",
  "Applicant Type",
  "FI Type",
  "Employee Name",
  "Employee ID",
  "Product Type",
  // "Product Name",
  "Customer Name",
  "Co-Applicant Name",
  "Customer Father/Spouse Name",
  "Contact No",
  "Address Line 1",
  "City",
  "State",
  "Postal Code",
  "Near By Landmark",
  "Due Date",
];

export const headerTitlesAgencies = [
  "applicationNo",
  "applicantType",
  "fieldInvestigationType",
  "productType",
  // "productName",
  "customerName",
  "customerFatherName",
  "contactNo",
  "addressLineOne",
  "city",
  "state",
  "postalCode",
  "nearByLandmark",
  "dueDate",
];

export const excelHeadersAgencies = [
  "Application No",
  "Applicant Type",
  "Field Investigation Type",
  "Product Type",
  "Product Name",
  "Customer Name",
  "Customer Father's Name",
  "Contact No",
  "Address Line 1",
  "City",
  "State",
  "Postal Code",
  "Near By Landmark",
  "Due Date",
];

export const isDirectReportingOfficerOrNot = (userId) => {
  if (
    userLoginDetails?.autoFinanceUser?.subOrdinateIds?.length > 0 &&
    userLoginDetails?.autoFinanceUser?.subOrdinateIds.includes(userId)
  ) {
    return true;
  } else {
    return false;
  }
};

export const removeSpaces = (str) => {
  if (str !== null && str !== undefined && str !== "" && str?.length > 0) {
    return str.replace(/\s/g, "");
    // \s matches any whitespace character, g flag replaces all occurrences
  } else {
    return str;
  }
};

const convertEpochToDateTime = (epochTime) => {
  var months_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date_str = "";
  var date = new Date(parseInt(epochTime) * 1000);
  var day = date.getDate();
  var month = months_arr[date.getMonth()];
  var year = date.getFullYear();
  var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  var am_pm = h >= 12 ? "PM" : "AM";
  h = h > 12 ? h - 12 : h;
  date_str =
    month + "_" + day + "_" + year + "_" + h + "_" + m + "_" + s + "_" + am_pm;
  return date_str;
};

export const getFileNameForSignatureUpload = () => {
  return (
    userLoginDetails?.autoFinanceOrganization?.orgshortcode +
    "_FI_Signature_" +
    convertEpochToDateTime(Math.floor(new Date() / 1000))
  );
};
export const getFileNameForExcelFileUpload = (fiType) => {
  return (
    userLoginDetails?.autoFinanceOrganization?.orgshortcode +
    "_FI_" +
    fiType +
    "_" +
    convertEpochToDateTime(Math.floor(new Date() / 1000))
  );
};
