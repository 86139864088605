import { useEffect, useState } from "react";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { EpochToDate } from './../CommonComponents/Helper'
import PaginationComponent from "react-reactstrap-pagination";
import {  Card } from "react-bootstrap";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";

const CollectionDetails = () => {
    var history = useHistory();
    const [loggedInUser, setloggedInUser] = useState(JSON.parse(localStorage.getItem("userDetails")));
    const [obj, setObj] = useState(null)
    const [totalElements, setTotalElements] = useState(1);
    const [data, setData] = useState([])
    const [toggle, setToggle] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        var userData = JSON.parse(localStorage.getItem("userDetails"));
        setloggedInUser(userData)
        var collectionDetails = JSON.parse(localStorage.getItem("collectionDetails"));
        setObj(collectionDetails)
        loadData(collectionDetails.start, collectionDetails.end, collectionDetails.branchId, collectionDetails.visitType, collectionDetails.visitStatus, userData, 0)
    }, [])

    const columns = [
        {
            text: "Customer Name",
            dataField: "customerName",
            width: 130,
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                // fontWeight: "bold",
                borderColor: "white",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: "Customer Mobile No",
            dataField: "collectionsContactNumber",
            width: 130,
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                // fontWeight: "bold",
                borderColor: "white",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },

        {
            text: "Loan No",
            dataField: "loanNumber",
            width: 130,
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                // fontWeight: "bold",
                borderColor: "white",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },

        {
            text: "Email",
            dataField: "email",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",

                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: "Visited Date",
            dataField: "dateTime",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",

                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },

        {
            text: "",
            dataField: "id",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                return (
                    <div className="d-flex">
                        <Link to="#" className="btn btn-success shadow btn-xs sharp">
                            <i className="fa fa-eye"></i>
                        </Link>
                    </div>
                );
            },

            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setSelectedRow(row);
                    setToggle(true);
                },
            },
        },
    ];

    const onPageChange = (selectedPage) => {
        loadData(obj.start, obj.end, obj.branchId, obj.visitType, obj.visitStatus, loggedInUser, selectedPage - 1)
    };

    const loadData = (start, end, branchId, visitType, visitStatus, userData, pageNo) => {
        setIsLoading(true)
        var VisitedPlacesBody = {
            endTime: end,
            organizationId: userData.autoFinanceUser.organizationId,
            branchId: branchId,
            reportingOfficerId: userData.autoFinanceUser.roleName === 'MANAGER' ? userData.autoFinanceUser.userId : '',
            startTime: start,
            visitType: visitType,
            status: visitStatus,
        };
        moduleApi
            .postData(
                moduleApi.withPage(apiCall.getEmployeeVisits, pageNo, 10),
                VisitedPlacesBody
            )
            .then((response) => {
                var listData = [];
                response.content.forEach((element) => {
                    let resp = {
                        id: element.id,
                        userId: element.userId,
                        userModel: element.userModel,

                        name: element.userModel.name,
                        mobileNumber: element.userModel.mobileNumber,
                        email: element.userModel.email,
                        UserImage: element.userModel.image,
                        branchId: element.userModel.branchId,
                        roleName: element.userModel.roleName,
                        addedByUserId: element.userModel.addedByUserId,
                        employeeId: element.userModel.employeeId,
                        userStatus: element.userModel.status,
                        passwordUpdated: element.userModel.passwordUpdated,

                        organizationId: element.organisationId,
                        status: element.status,
                        comments: element.comments,
                        latitude: element.latitude,
                        longitude: element.longitude,
                        address: element.address,
                        visitType: element.visitType,
                        image: element.image,
                        dateTime: EpochToDate(element.dateTime),
                        timeSpent: element.timeSpent,
                        reportingOfficerId: element.reportingOfficerId,
                        salesVisit: element.salesVisit,
                        salesContactNumber: element.salesVisit?.contactNumber,
                        collectionVisit: element.collectionVisit,
                        loanNumber: element.collectionVisit?.loanNumber,
                        collectionsContactNumber: element.collectionVisit?.contactNumber,
                        customerName: element.collectionVisit
                            ? element.collectionVisit.customerName
                            : element.salesVisit.customerName,
                    }
                    listData.push(resp);
                })
                setData(listData)
                setTotalElements(response.totalElements);
                setIsLoading(false)
            })
    }
    return <LoadingOverlay active={isLoading} 
    // spinner
    text={<p style={{
        color:'black'
      }}><div>
      <Button
        style={{
          backgroundColor: "#FFFF",
          color: "black",
          borderColor: "white",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        variant="primary"
        disabled
      >
        <Spinner
          as="span"
          animation="border"
          size="md"
          variant="primary"
          role="status"
          aria-hidden="true"
        />
        &nbsp;&nbsp;Loading...
      </Button>
    </div></p>}
        styles={{
            overlay: (base) => ({
                ...base,
                background: 'rgba(192,192,192,0.4)'
            }),
            spinner: (base) => ({
                ...base,
                width: '80px',
                '& svg circle': {
                    stroke: 'rgb(136, 108, 192)'
                }
            })
        }}>
        <Card>
            <Card.Header style={{ background: 'gray' }}>
                <span style={{ color: 'white',cursor:'pointer' }} onClick={() => history.push("/dashboard")}>
                    <i className="fa fa-arrow-left" />
                </span>
                <span style={{ color: 'white', marginLeft: 'auto', fontWeight: '600' }}> Status : {obj && obj.visitStatus}</span>
            </Card.Header>
            <Card.Body>
                <BootstrapTable keyField="loanNumber" data={data} columns={columns} />
            </Card.Body>
            <Card.Footer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <PaginationComponent
                        totalItems={totalElements}
                        pageSize={10}
                        onSelect={onPageChange}
                    />
                </div>

            </Card.Footer>
            {toggle && (
                <VisitedPlaceDrawer
                    visitedPlacesDetailsList={selectedRow}
                    closeDrawer={() => setToggle(false)}
                // reLoad={() => reLoad(currPage)}
                />
            )}
        </Card>
    </LoadingOverlay>
}

export default CollectionDetails;