import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal } from "react-bootstrap";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { isAdmin } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import swal from "sweetalert";
import { EpochToDate, EpochToOnlyDate } from "../CommonComponents/Helper";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import LoanNumberDetails from "../RequestPayments/LoanNumberDetails";
import CollectionVisitDetails from "../UploadSchedule/CollectionVisitDetails";
import Select from "react-select";

const TargetDueCount = ({
  targetDueType,
  userDetails,
  totalTargetDueCount,
  isDefault,
  handleAllocationsColumns,
}) => {
  //  var history = useHistory();

  const [data, setData] = useState([]);
  const [currPage] = useState(0);
  const [totalElements, setTotalElements] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currPage2, setCurrPage2] = useState(0);
  const [customersList, setCustomersList] = useState([]);
  const [totalElements2, setTotalElements2] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [allocationDetails, setAllocationDetails] = useState({});
  const [loanNoDetailsModal, setLoanNoDetailsModal] = useState(false);
  const [modalForCustomerIntensity, setModalForCustomerIntensity] = useState(false);
  const [isCurrentMonthData, setIsCurrentMonthData] = useState(false);
  const [defaultDate, setDefaultDate] = useState("Today");
  const [branches, setBranches] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState({ label: "All", value: "" });
  const [start] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    1000
  );
  const [end] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    1000 +
    86399
  );

  useEffect(() => {

    loadData(0, isCurrentMonthData, "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    const payLoad = {
      searchKey: "",
      organizationId: userDetails?.autoFinanceUser?.organizationId,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
        branchName: item.branchName,
      }));

      result.unshift({ label: "All", value: "" });
      setBranches(result);
    });

    // eslint-disable-next-line
  }, []);


  const loadData = (pageNo, isCurrMonth, branchName) => {
    setIsLoading(true);
    setData([]);
    let array = [];

    // var status = targetDueType.replace(/-/g, ' ');

    var payLoad = {
      organizationId: userDetails?.autoFinanceUser?.organizationId,
      reportingOfficerId: !isAdmin() ? userDetails?.autoFinanceUser?.userId : "",
      status: targetDueType,
      currentMonth: isCurrMonth,
      branchName: branchName,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.gettargetduesummarycount, pageNo, 10),
        payLoad
      )
      .then((response) => {

        response?.content.forEach((element) => {
          let data = {
            empName: element.empName,
            targetDueCount: element.targetDueCount,
            userId: element.userId,
            designation: element.designation,
            branchId: element.branchId,
            branchName: element.branchName,
          };
          array.push(data);

        });

        setData(array);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const executiveColumns = [

    {
      text: "Employee Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Target Due Count",
      dataField: "targetDueCount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedUserId(row.userId);
          getCustomersList(0, row.userId);
        },
      },
    },
  ];

  const dateOptions = [
    { label: "Today", value: "Today" },
    { label: "Current Month", value: "Current Month" },
  ];

  const setSelectedValue = (value) => {

    if (value === "Current Month") {
      setDefaultDate("Current Month");
      setIsCurrentMonthData(true);
      loadData(0, true, selectedBranch.value);
    } else {
      setDefaultDate("Today");
      setIsCurrentMonthData(false);
      loadData(0, false, selectedBranch.value);
    }

  };

  const getCustomersList = (
    pageNo, userId
  ) => {
    let listData2 = [];
    setIsLoading(true);
    var status = targetDueType.replace(/-/g, ' ');
    let payload = {
      organizationId: userDetails.autoFinanceUser.organizationId,
      userId: userId,
      status: status,
      currentMonth: isCurrentMonthData,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getAllocationsByTargetDue, pageNo, 10),
        payload
      )
      .then((response) => {
        setCurrPage2(pageNo);
        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
          // console.log("response", response);
          response?.content?.forEach((element) => {
            let data = {
              addressLine2: element.addressLine2,
              ag_Date: element.ag_Date,
              aggDate: element.aggDate ? element.aggDate : 0,
              amountPaid: element.amountPaid,
              amountPaidNow: element.amountPaidNow,
              amountToBePaid: element.amountToBePaid,
              assetDesc: element.assetDesc,
              assignedMobileNumber: element.assignedMobileNumber,
              assignedName: element.assignedName,
              bounceCharges: element.bounceCharges,
              branchId: element.branchId,
              chassisNumber: element.chassisNumber,
              comments: element.comments,
              contactNumber: element.contactNumber1,
              zone: element.zone,
              division: element.division,
              state: element.state,
              unit: element.unit,
              customerBranch: element.customerBranch,
              groupName: element.groupName,
              loName: element.loName,
              spouseName: element.spouseName,
              cpLoanNo: element.cpLoanNo,
              centerName: element.centerName,
              lastCollectedAmount: element.lastCollectedAmount,
              dpdDays: element.dpdDays,
              maturityDate: element.maturityDate,
              lastVisitDate: element.lastVisitDate,
              lastPtpDate: element.lastPtpDate,
              residenceLatitude: element.residenceLatitude,
              residenceLongitude: element.residenceLongitude,
              contactNumber2: element.contactNumber2,
              cpName: element.cpName,
              closingDate: element.closingDate
                ? EpochToOnlyDate(element.closingDate)
                : "-",
              openingDate: element.openingDate
                ? EpochToOnlyDate(element.openingDate)
                : "-",
              targetDate: element.targetDate
                ? EpochToOnlyDate(element.targetDate)
                : "-",
              installmentDate: element.installmentDate
                ? EpochToDate(element.installmentDate)
                : "-",
              currentBucket: element.currentBucketName,
              customerIntensinty: element.customerIntensinty,
              lenderId: element.lenderId,
              lenderName: element.lenderName,
              lenderAddress: element.promoterAddress,
              locLink1: element.locLink1,
              locLink2: element.locLink2,
              borrowerAddress: element.borrowerAddress,
              current_EMI_Month: element.currentEmiMonthNumber,
              customerName: element.customerName,
              priority: element.priority ? element.priority : "-",
              dateTime:
                element.dateTime !== 0
                  ? convertEpochTimeToDate(element.dateTime)
                  : "-",
              lastPaidDate: element.lastPaidDate
                ? convertEpochTimeToDate(element.lastPaidDate)
                : "-",
              dueAmount: element.dueAmount,
              dueDate:
                element.dueDate !== 0 ? EpochToOnlyDate(element.dueDate) : "-",
              expiredDate: element.expiredDate
                ? EpochToOnlyDate(element.expiredDate)
                : "-",
              dueDay: element.dueDay,
              emiAmount: element.emiAmount,
              emiOutstanding: element.emiOutstanding,
              engineNumber: element.engineNumber,
              employeeId: element.executiveEmployeeId
                ? element.executiveEmployeeId
                : element.managerEmployeeId,
              executiveMobileNumber: element.executiveMobileNumber,
              executiveName: element.executiveName,
              guarantorMobile: element.guarantorMobile,
              guarantorName: element.guarantorName,
              id: element.id,
              image: element.image,
              latePaymentCharges: element.latePaymentCharges,
              latitude: element.latitude,
              loanAmount: element.loanAmount,
              loanNumber: element.loanNumber,
              location: element.location,
              longitude: element.longitude,
              managerEmployeeId: element.managerEmployeeId,
              managerName: element.managerName,
              nearByLandmark: element.nearByLandmark,
              netOtherAmount: element.netOtherAmount,
              net_GDI_Amount: element.net_GDI_Amount,
              net_HL_Amount: element.net_HL_Amount,
              organizationId: element.organizationId,
              permanentAddress: element.permanentAddress,
              permanentCity: element.permanentCity,
              permanentState: element.permanentState,
              permanentZipcode: element.permanentZipcode,
              principalOutstanding: element.principalOutstanding,
              productName: element.productName,
              referenceMobile1: element.referenceMobile1,
              referenceMobile2: element.referenceMobile2,
              referenceName1: element.referenceName1,
              referenceName2: element.referenceName2,
              registrationNumber: element.registrationNumber,
              remarks: element.remarks,
              reportingOfficerId: element.reportingOfficerId,
              address: element.residenceAddress,
              residenceCity: element.residenceCity,
              residenceState: element.residenceState,
              residenceZipcode: element.residenceZipcode,
              status: element.status,
              subStatus: element?.subStatus,
              teamLeaderEmployeeId: element.teamLeaderEmployeeId,
              teamLeaderName: element.teamLeaderName,
              temporaryRegistrationNumber: element.temporaryRegistrationNumber,
              tenure: element.tenure,
              teleCallerName: element.teleCallerName
                ? element.teleCallerName
                : "-",
              teleCallerEmpId: element.teleCallerEmpId
                ? element.teleCallerEmpId
                : "-",
              teleCallerUserId: element.teleCallerUserId,
              totalPenaltyCharges: element.totalPenaltyCharges,
              typeOfVisit: element.typeOfVisit,
              thirdPartyPhoneNumber: element.thirdPartyPhoneNumber,
              thirdPartyName: element.thirdPartyName,
              updatedDate:
                element.updatedDate !== 0
                  ? convertEpochTimeToDate(element.updatedDate)
                  : "-",
              lastVisitedAddress: element.lastVisitedAddress,
              lastVisitedAddressLat: element.lastVisitedAddressLat
                ? element.lastVisitedAddressLat
                : 0,
              lastVisitedAddressLong: element.lastVisitedAddressLong
                ? element.lastVisitedAddressLong
                : 0,
              lastVisitedAddress2: element.lastVisitedAddress2,
              lastVisitedAddress2Lat: element.lastVisitedAddress2Lat
                ? element.lastVisitedAddress2Lat
                : 0,
              lastVisitedAddress2Long: element.lastVisitedAddress2Long
                ? element.lastVisitedAddress2Long
                : 0,
              userId: element.userId,
              vehicleType: element.vehicleType,
              visitStatus: element.visitStatus,
              emiAmountPaid: element.emiAmountPaid ? element.emiAmountPaid : 0,
              emiAmountToBePaid: element.emiAmountToBePaid
                ? element.emiAmountToBePaid
                : 0,
              bounceChargesPaid: element.bounceChargesPaid
                ? element.bounceChargesPaid
                : 0,
              bounceChargesToBePaid: element.bounceChargesToBePaid
                ? element.bounceChargesToBePaid
                : 0,
              latePaymentChargesPaid: element.latePaymentChargesPaid
                ? element.latePaymentChargesPaid
                : 0,
              latePaymentChargesToBePaid: element.latePaymentChargesToBePaid
                ? element.latePaymentChargesToBePaid
                : 0,
              penaltyChargesPaid: element.penaltyChargesPaid
                ? element.penaltyChargesPaid
                : 0,
              penaltyChargesToBePaid: element.penaltyChargesToBePaid
                ? element.penaltyChargesToBePaid
                : 0,
              otherChargesPaid: element.otherChargesPaid
                ? element.otherChargesPaid
                : 0,
              otherChargesToBePaid: element.otherChargesToBePaid
                ? element.otherChargesToBePaid
                : 0,
            };
            listData2.push(data);
          });
          setCustomersList(listData2);
          setTotalElements2(response?.totalElements);
          setIsLoading(false);
          handleAllocationsColumns(true);
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const handleLoanNumberDetailsModal = () => {
    setLoanNoDetailsModal(false);
    getCustomersList(
      currPage2,
      selectedUserId
    );
  };

  const handleClose1 = () => {
    setModalForCustomerIntensity(false);
  };

  const customerColumns = [
    {
      text: "Emp Id",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile",
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Priority",
      dataField: "priority",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan No",
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
            }}
            onClick={() => {
              setAllocationDetails(row);
              setLoanNoDetailsModal(true);
            }}
          >
            {row.loanNumber}
          </span>
        </div>
      ),
    },
    {
      text: "Amount Paid",
      dataField: "amountPaid",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Current Due</span>
          <br></br>
          <span>Amount</span>
        </div>
      ),
      dataField: "amountToBePaid",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total Due</span>
          <br></br>
          <span>Amount</span>
        </div>
      ),
      dataField: "dueAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Due Date",
      dataField: "dueDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Target Date",
      dataField: "targetDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: " Residence Address",
      dataField: "address",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Assigned</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Opening</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "openingDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Closing</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "closingDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Intensity</span>
        </div>
      ),
      dataField: "customerIntensinty",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => {
              setcustomerIntensity(row);
            }}
          >
            {row.customerIntensinty}
          </span>
        </div>
      ),
    },
    {
      text: (
        <div>
          <span>Last visited</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "updatedDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Visit</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "visitStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.visitStatus === "Partially Paid") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "PTP") {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "Paid") {
          return (
            <span className="badge badge-rounded badge-success">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "Customer Not Available") {
          return (
            <span className="badge badge-rounded badge-danger">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "" || row.visitStatus === null) {
          return <span>-</span>;
        } else {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.visitStatus}
            </span>
          );
        }
      },
    },
    {
      text: (
        <div>
          <span>Sub</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (
          row.subStatus === "Cash" ||
          row.subStatus === "Bank Transfer" ||
          row.subStatus === "Already Settled" ||
          row.subStatus === "UPI"
        ) {
          return (
            <span
              className="badge badge-rounded badge-success"
            // style={{ color: "black" }}
            >
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "PTP") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "Paid") {
          return (
            <span className="badge badge-rounded badge-success">
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "Customer Not Available") {
          return (
            <span className="badge badge-rounded badge-danger">
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "" || row.subStatus === null) {
          return <span>-</span>;
        } else {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.subStatus}
            </span>
          );
        }
      },
    },

    {
      text: (
        <div>
          <span>Allocation</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "OPEN") {
          return (
            <span className="badge badge-rounded badge-primary">Open</span>
          );
        } else if (row.status === "COMPLETED") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              In Progress
            </span>
          );
        }
      },
    },
    {
      text: (
        <div>
          <span>TeleCaller</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "teleCallerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>TeleCaller</span>
          <br></br>
          <span>Emp Id</span>
        </div>
      ),
      dataField: "teleCallerEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  const onPageChange2 = (selectedPage) => {
    setCurrPage2(selectedPage - 1);
    getCustomersList(
      selectedPage - 1, selectedUserId
    );
  };

  const setcustomerIntensity = (row) => {
    setAllocationDetails(row);
    setModalForCustomerIntensity(true);
  };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const onPageChange = (selectedPage) => {
    // console.log(selectedPage - 1, mgrId, isDefault);
    loadData(selectedPage - 1, isCurrentMonthData, selectedBranch.value);
  };


  const setBranchValue = (value) => {

    setSelectedBranch(value);
    loadData(0, isCurrentMonthData, value.value);
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div>
          {!isDefault ? (

            <div style={{ background: "white", display: "flex", flexDirection: "column", margin: "10px", overflowX: "auto" }}>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  marginBottom: "5px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>
                    Select Branch
                  </label>
                  <div style={{ width: "150px", alignSelf: "flex-end", marginRight: "5px" }}>
                    <Select
                      id="branchFilter"
                      onChange={(value) => {
                        setBranchValue(value);
                      }}
                      defaultValue={selectedBranch}
                      options={branches}
                    />
                  </div>
                </div>
                {(targetDueType === "Targets Achieved" || targetDueType === "Targets Attempted") && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                    }}
                  >
                    <label style={{ fontWeight: "bold" }}>
                      Select Range
                    </label>
                    <div style={{ width: "150px", alignSelf: "flex-end" }}>
                      <Select
                        id="dateFilterForTargetAchieved"
                        isSearchable={false}
                        onChange={(value) => {
                          setSelectedValue(value.value);
                        }}
                        defaultValue={{ label: defaultDate, value: defaultDate }}
                        options={dateOptions}
                      />
                    </div>
                  </div>
                )}

              </div>
              <div style={{ overflow: "auto", maxHeight: "400px" }}>
                {/* {totalTargetDueCount >= 1 ? (
                  <div
                    style={{
                      float: 'right',
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "30px",
                      marginTop: "10px",
                      marginBottom: '5px'
                    }}
                  >
                    <b>Total Count: &nbsp;{totalTargetDueCount}</b>
                  </div>
                ) : null} */}
                {data.length >= 1 ? (
                  <BootstrapTable
                    keyField="id"
                    data={data}
                    columns={executiveColumns}
                    id="targetDueCount"
                  />
                ) : null}
              </div>
              {data.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              ) : null}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PaginationComponent
                  totalItems={totalElements}
                  pageSize={10}
                  defaultActivePage={currPage + 1}
                  onSelect={onPageChange}
                />
              </div>
            </div>
          ) : (
            <div style={{ background: "white", margin: "10px", overflowX: "auto" }}>
              <div style={{ overflow: "auto", maxHeight: "400px" }}>
                <BootstrapTable
                  keyField="panNo"
                  data={customersList}
                  columns={customerColumns}
                />
              </div>
              {customersList.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "0px",
                }}
              >
                <PaginationComponent
                  totalItems={totalElements2}
                  pageSize={10}
                  defaultActivePage={currPage2 + 1}
                  onSelect={onPageChange2}
                />
              </div>
            </div>
          )}
        </div>
      </LoadingOverlay>
      <Modal className="fade" size="xl" show={loanNoDetailsModal}>
        <Modal.Header>
          <Modal.Title>Allocation Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setLoanNoDetailsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <LoanNumberDetails
            allocationDetails={allocationDetails}
            close={handleLoanNumberDetailsModal}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="xl" show={modalForCustomerIntensity}>
        <Modal.Header>
          <Modal.Title>
            <b>Customer Visit Details</b>
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => handleClose1()}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(120vh - 210px)",
            overflowY: "auto",
            padding: "10px",
          }}
        >
          <CollectionVisitDetails
            startDate={start}
            endDate={end}
            visitDetails={allocationDetails}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TargetDueCount;
