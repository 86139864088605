import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
// import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess, isAdmin } from "../services/AuthService";
import CustomerDetails from "./CustomerDetails";
import { Nav, Tab } from "react-bootstrap";
import VisitDetailsHistory from "./VisitDetailsHistory";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import SearchBar from "../CommonComponents/SearchBar";
import ReactGA from "react-ga";
import CallSummary from "./CallSummary";
import swal from "sweetalert";
import { makeCall } from "../CommonComponents/Helper";

const CustomerMgmt = () => {
  var history = useHistory();

  const [visitedPlacesList, setVisitedPlacesList] = useState([]);
  const [fieldInvstgtnList, setFieldInvstgtnList] = useState([]);
  const [visitedPlacesDetailsList, setVisitedPlacesDetailsList] = useState([]);
  // const [basicModal, setBasicModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [visitType, setVisitType] = useState("All");
  const [status, setStatus] = useState("All");
  const [collectionStatuses, setCollectionStatuses] = useState([]);
  const [collectionSubStatuses, setCollectionSubStatuses] = useState([]);
  const [collectionSubType, setCollectionSubType] = useState("");
  const [callAudioModal, setCallAudioModal] = useState(false);
  const [callMobileNo, setCallMobileNo] = useState(null);

  const [selectedCollectionSubType, setSelectedCollectionSubType] = useState({
    label: "All",
    value: "",
  });
  const [isSubStatus, setIsSubStatus] = useState(false);
  // const [collectionType, setCollectionType] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [collectionTypeIndex, setCollectionTypeIndex] = useState(0);
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [toggle, setToggle] = useState("");
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isFieldInvstgn, setIsFieldInvstgn] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // const [flag, setFlag] = useState(true);

  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("userDetails"));
    setloggedInUser(userData);
    setDateValue("Last 7 Days", 1);
    loadVistedPlacesData(0, searchKey);
    addDropDownOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVistedPlacesData = (pageNo, key) => {
    // if (visitType !== "Sales" &&  visitType !== "FieldInvestigation") {
    //   setFlag(true);
    //   // if (visitType === "All" || visitType ===  "Collections") {
    //   //   setFlag(true);
    //   // } else {
    //   //   setFlag(false);
    //   // }
    // } else {
    //   setFlag(false);
    // }
    setIsLoading(true);
    var VisitedPlacesBody = {
      endTime: end,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
      roleName: "ADMIN",
      startTime: start,
      status: status,
      userId: "",
      visitType: visitType,
      searchkey: key,
      subStatus: collectionSubType,
    };

    var cpNameVisitedPlacesBody = {
      endTime: end,
      organizationId: loggedInUser.autoFinanceOrganization.mainOrganizationId,
      // reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
      startTime: start,
      // userId: isSwitch ? loggedInUser.autoFinanceUser.userId : "",
      visitType: "Collections",
      status: status,
      searchkey: key,
      cpName: loggedInUser.autoFinanceOrganization.cpName,
      // subStatus: collectionSubType,
    };

    // module
    moduleApi
      .postData(
        moduleApi.withPage(
          loggedInUser.autoFinanceUser.roleName !== "CP_USER"
            ? apiCall.getCustomerMgmtList
            : apiCall.getCustomerDetailsBasedOnCpName,
          pageNo,
          10
        ),
        loggedInUser.autoFinanceUser.roleName !== "CP_USER"
          ? VisitedPlacesBody
          : cpNameVisitedPlacesBody
      )
      .then((response) => {
        // let userIds = new Set();

        // response.content.forEach((element) => {
        //   userIds.add(element.userModel.userId)
        // })
        // let m = new Map()
        // moduleApi.postData(apiCall.getLiveLocations, userIds)
        //   .then((response2) => {
        //     response2.forEach((item) => {
        //       m.set(item.userId, item);
        //     })
        //     response.content.forEach((element) => {
        //       let data = {
        //         branchId: element.autoFinanceBranchDetails.branchId,
        //         branchLocation: element.autoFinanceBranchDetails.branchLocation,
        //         branchName: element.autoFinanceBranchDetails.branchName,
        //         branchemail: element.autoFinanceBranchDetails.branchemail,
        //         organizationId: element.autoFinanceBranchDetails.organizationId,
        //         phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
        //         addedByUserId: element.autoFinanceUser.addedByUserId,
        //         reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
        //         email: element.autoFinanceUser.email,
        //         employeeId: element.autoFinanceUser.employeeId,
        //         image: element.autoFinanceUser.image,
        //         mobileNumber: element.autoFinanceUser.mobileNumber,
        //         name: element.autoFinanceUser.name,
        //         passwordUpdated: element.autoFinanceUser.passwordUpdated,
        //         reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
        //         roleName: element.autoFinanceUser.roleName,
        //         status: element.autoFinanceUser.status,
        //         userId: element.autoFinanceUser.userId,
        //         designation: element.autoFinanceUser.companyDesignation,
        //         batteryPercentage: m.has(element.autoFinanceUser.userId) ? m.get(element.autoFinanceUser.userId).batteryPercentage : 0,
        //         gpsData: m.has(element.autoFinanceUser.userId) ? m.get(element.autoFinanceUser.userId).gpsData : null,
        //         lastUpdatedAt: m.has(element.autoFinanceUser.userId) ? m.get(element.autoFinanceUser.userId).lastUpdatedAt : 0,
        //       };
        //       listData.push(data);
        //     });
        //     setManagersList(listData);
        //     setTotalElements(response.totalElements);
        //   })

        setCurrPage(pageNo);
        var listData = [];
        if (visitType !== "FieldInvestigation") {
          response?.content?.forEach((element) => {
            let data = {
              id: element.id,
              userId: element.userId,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,

              organizationId: element.organisationId,
              status: element.status,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: convertEpochTimeToDate(element.dateTime),
              timeSpent: element.timeSpent,
              reportingOfficerId: element.reportingOfficerId,
              salesVisit: element.salesVisit,
              collectionVisit: element.collectionVisit,
              fieldInvestigationVisit: element.fieldInvestigationVisit,

              userModel: element.userModel,
              // gpsData: m.has(element.userModel.userId) ? m.get(element.use.userId).gpsData : null,
              // eslint-disable-next-line no-dupe-keys
              status: element.collectionVisit
                ? element.collectionVisit.status
                : element.salesVisit
                ? "-"
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.status
                : "",
              loanNumber: element.collectionVisit
                ? element.collectionVisit.loanNumber
                : "-",
              subStatus: element.collectionVisit
                ? element.collectionVisit.subStatus === null ||
                  element.collectionVisit.subStatus === ""
                  ? "-"
                  : element.collectionVisit.subStatus
                : "-",

              customerName: element.collectionVisit
                ? element.collectionVisit.customerName
                : element.salesVisit
                ? element.salesVisit.customerName
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.customerName
                : "",
              contactNumber: element.collectionVisit
                ? element.collectionVisit.contactNumber
                : element.salesVisit
                ? element.salesVisit.contactNumber
                : element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : "",
            };
            listData.push(data);
          });
          setVisitedPlacesList(listData);
          setIsFieldInvstgn(false);
          setIsLoading(false);
        } else {
          response?.content?.forEach((element) => {
            let data = {
              userModel: element.userModel,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,

              organizationId: element.organisationId,
              latitude: element.latitude,
              longitude: element.longitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: convertEpochTimeToDate(element.dateTime),
              reportingOfficerId: element.reportingOfficerId,
              fieldInvestigationVisit: element.fieldInvestigationVisit,
            };
            listData.push(data);
          });
          setFieldInvstgtnList(listData);
          setIsFieldInvstgn(true);
          setIsLoading(false);
        }
        setTotalElements(response?.totalElements);
      });
  };

  const onPageChange = (selectedPage) => {
    loadVistedPlacesData(selectedPage - 1, searchKey);
  };

  const getSubStatusCollectionOptions = (subStatus) => {
    var payload = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      status: subStatus,
    };

    moduleApi
      .postData(apiCall.getCollectionsStatus, payload)
      .then((response) => {
        const result = response.map((item) => ({
          label: item,
          value: item,
        }));
        result.unshift({ label: "All", value: "" });
        if (response.length > 0) {
          setCollectionSubType("");
          setSelectedCollectionSubType({ label: "All", value: "" });
          setIsSubStatus(true);
        } else {
          setCollectionSubType("");
          setSelectedCollectionSubType({ label: "All", value: "" });
          setIsSubStatus(false);
        }
        // console.log('result', result);

        setCollectionSubStatuses(result);
      });
  };

  // const collectionValues = [
  //   { label: "All", value: "All", index: 0 },
  //   {
  //     label: "Customer Not Available",
  //     value: "Customer Not Available",
  //     index: 1,
  //   },
  //   { label: "Paid", value: "Paid", index: 2 },
  //   { label: "PTP", value: "PTP", index: 3 },
  //   // { label: "Partially Paid", value: "Partially Paid" , index: 4},
  //   { label: "RTP", value: "RTP", index: 5 },
  //   { label: "Repossessed", value: "Repossessed", index: 6 },
  //   { label: "Vacant", value: "Vacant", index: 7 },
  //   { label: "ThirdParty", value: "ThirdParty", index: 8 },
  // ];

  const getCollectionDropdownOptions = () => {
    var payload = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
    };

    moduleApi
      .postData(apiCall.getCollectionsStatus, payload)
      .then((response) => {
        const result = response.map((item, index) => ({
          label: item,
          value: item,
          index: index + 1,
        }));
        result.unshift({ label: "All", value: "All", index: 0 });
        console.log("result", result);

        setCollectionStatuses(result);
      });
  };

  //   const setCollectionTypeValue = (value) => {
  //     setCollectionType(value.value);
  //     // loadData(0, mgrId, value.value, isDefault);
  //     setCollectionTypeIndex(value.index);
  //   };

  const setCollectionValue = (value) => {
    setStatus("");
    setStatus(value.value);
    // switch (value) {
    //     case 0:
    //         setVisitType("All");
    //         return;
    //     case 1:
    //         setVisitType("Paid");
    //         break;
    //     case 2:
    //         setVisitType("PTP");
    //         break;
    //     case 3:
    //         setVisitType("Customer Not Available");
    //         break;
    //     default:
    //         console.log("default 4 ", showDate);
    // }
  };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };
  const columns = [
    {
      text: "Customer",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Contact No",
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.contactNumber) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p
                title={row.contactNumber}
                style={{ cursor: "pointer", verticalAlign: "middle", marginRight:'5px' }}
              >
                {row.contactNumber}
              </p>
              {hasAccess(42) && (
                <Link
                to="#"
                className="btn btn-success shadow btn-xs sharp"
                onClick={() => {
                  makeCall(row?.contactNumber);
                }}
              >
                <i className="fa fa-phone fa-1x fa-rotate-90" />
              </Link>
              )}
            </div>
          );
        } else {
          return <>-</>;
        }
      },
    },

    {
      text: "Type",
      dataField: "visitType",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return <span>{row.visitType}</span>;
      },
    },
    {
      text: "Loan Number",
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return <span>{row.status}</span>;
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      // formatter: (cell, row) => {
      //   return (
      //     <span>
      //       {row.status === "PTP"
      //         ? row.status + "(" + row.collectionVisit.paymentDateBy + ")"
      //         : row.status}
      //     </span>
      //   );
      // },
    },
    {
      text: "Last Visited Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Last Visited By",
      dataField: "",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          // <div className="d-flex">
          // {/* <Link to="#" className="btn btn-success shadow btn-xs sharp"> */}
          // {/* <i className="fa fa-eye"></i> */}
          <span style={{ color: "blueviolet", cursor: "pointer" }}>
            {row.name}
          </span>
          // {/* </Link> */}
          // </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let path = "/executive-details";
          path += "/";
          path += row.userModel.userId;
          // eslint-disable-next-line no-unused-vars
          path += "/";

          moduleApi.postData(apiCall.getUser + row.userId).then((element) => {
            // var user=response.autoFinanceUser;

            var user = {
              branchId: element.autoFinanceBranchDetails?.branchId,
              branchLocation: element.autoFinanceBranchDetails?.branchLocation,
              branchName: element.autoFinanceBranchDetails?.branchName,
              branchemail: element.autoFinanceBranchDetails?.branchemail,
              organizationId: element.autoFinanceBranchDetails?.organizationId,
              phoneNumber: element.autoFinanceBranchDetails?.phoneNumber,
              addedByUserId: element.autoFinanceUser.addedByUserId,
              reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
              email: element.autoFinanceUser.email,
              employeeId: element.autoFinanceUser.employeeId,
              image: element.autoFinanceUser.image,
              mobileNumber: element.autoFinanceUser.mobileNumber,
              name: element.autoFinanceUser.name,
              passwordUpdated: element.autoFinanceUser.passwordUpdated,
              reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
              roleName: element.autoFinanceUser.roleName,
              status: element.autoFinanceUser.status,
              userId: element.autoFinanceUser.userId,
              designation: element.autoFinanceUser.companyDesignation,
            };

            let body = {
              organizationId: element.autoFinanceBranchDetails?.organizationId,
              userIds: [row.userId],
            };

            moduleApi
              .postData(apiCall.getLiveLocations, body)
              .then((response2) => {
                response2?.forEach((item) => {
                  user.batteryPercentage = item.batteryPercentage;
                  user.gpsData = item.gpsData;
                  user.lastUpdatedAt = item.lastUpdatedAt;
                });
                localStorage.setItem("executiveDetails", JSON.stringify(user));
                localStorage.setItem("prevUrl", "customers");
                history.push("executive-details");
              });

            // console.log(response);
            //   setManagerData(response);
          });
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          // setBasicModal(true);
          setToggle("chatbox");
        },
      },
    },
  ];

  const fieldInvstgncolumns = [
    {
      text: "Employee",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.customerName
          ? row.fieldInvestigationVisit.customerName
          : "-";
      },
    },
    {
      text: "Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <span
            style={{
              fontWeight: "bold",
              color:
                row.fieldInvestigationVisit?.status === "Positive"
                  ? "green"
                  : "red",
            }}
          >
            {row.fieldInvestigationVisit?.status}
          </span>
        );
      },
    },
    {
      text: "Rejection Type",
      dataField: "rejectionType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.rejectionType
          ? row.fieldInvestigationVisit.rejectionType
          : "-";
      },
    },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-success shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          // setBasicModal(true);
          setToggle("chatbox");
        },
      },
    },
  ];

  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const addDropDownOptions = () => {
    var opts = [];
    opts.push({ label: "All", value: 3 });
    hasAccess(3) && opts.push({ label: "Sales", value: 1 });
    hasAccess(4) && opts.push({ label: "Collections", value: 2 });
    hasAccess(5) && opts.push({ label: "Field Investigation", value: 4 });
    setDropDownOptions(opts);
  };

  const setVisitValue = (label, value) => {
    setVisitType("");
    switch (value) {
      case 1:
        setVisitType("Sales");
        return;
      case 2:
        getCollectionDropdownOptions();
        setVisitType("Collections");
        break;
      case 3:
        setVisitType("All");
        break;
      case 4:
        setVisitType("FieldInvestigation");
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  const setDateValue = (label, value) => {
    var date;

    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        // loadVistedPlacesData();
        return;
      case 2:
        var today = new Date();

        // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
        setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;
      case 4:
        setShowDate(true);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  const getMobileNumberForCallHistory = () => {
    if (visitedPlacesDetailsList) {
      if (visitedPlacesDetailsList.collectionVisit) {
        return visitedPlacesDetailsList.collectionVisit?.contactNumber;
      } else if (visitedPlacesDetailsList.salesVisit) {
        return visitedPlacesDetailsList.salesVisit?.contactNumber;
      } else if (visitedPlacesDetailsList.fieldInvestigationVisit) {
        return visitedPlacesDetailsList.fieldInvestigationVisit?.contactNumber;
      }
    }
  };

  function setCustomDates(dateStrings) {
    if (dateStrings.length > 1) {
      // start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
      // end = Math.floor(new Date(dateStrings[1]).getTime() / 1000);
      setStart(Math.floor(dateStrings[0].getTime() / 1000));
      setEnd(Math.floor(dateStrings[1].getTime() / 1000) + 86400);
    }
    //loadVistedPlacesData();
  }

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadVistedPlacesData(0, key);
  };

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        {/* <input
          style={{
            width: "280px",
            height: "40px",
            borderRadius: "25px",
            borderColor: "#a0a0a0",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #3a353b",
            paddingLeft: "10px",
            marginRight:'5px'
          }}
          placeholder= "Search By Name/Contact/Loan No"
          onChange={(e) => searchKeyword(e.target.value.trim())}
        /> */}
        <SearchBar
          placeholder="Search By Name/Contact/Loan No"
          searchKeyword={searchKeyword}
        />
        {loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
          <div
            style={{
              float: "right",
              width: "120px",
              marginRight: "5px",
              marginBottom: "10px",
              marginLeft: "5px",
              // borderRadius: "3px",
              // borderWidth: "1px",
              // boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) =>
                setVisitValue(value.label, value.value, dropDownOptions)
              }
              defaultValue={{ label: "All", value: 3 }}
              options={dropDownOptions}
            />
          </div>
        )}
        {(visitType === "Collections" ||
          loggedInUser.autoFinanceUser.roleName === "CP_USER") && (
          <div
            style={{
              float: "right",
              width: "90px",
              marginRight: "5px",
              marginBottom: "10px",
              marginLeft: "5px",
              // borderRadius: "3px",
              // borderWidth: "1px",
              // boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                setCollectionValue(value);
                loggedInUser.autoFinanceUser.roleName !== "CP_USER" &&
                  getSubStatusCollectionOptions(value.value);
              }}
              defaultValue={
                collectionStatuses.length > 0
                  ? collectionStatuses[collectionTypeIndex]
                  : { label: "All", value: "All", index: 0 }
              }
              options={collectionStatuses}
            />
          </div>
        )}

        {visitType === "Collections" && isSubStatus && (
          <div
            style={{
              float: "right",
              width: "100px",
              marginRight: "5px",
              // marginLeft: "10px",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                setCollectionSubType(value.value);
                setSelectedCollectionSubType(value);
              }}
              defaultValue={{ label: "All", value: "" }}
              options={collectionSubStatuses}
              value={selectedCollectionSubType}
            />
          </div>
        )}
        <div
          style={{
            float: "right",
            width: "110px",
            marginRight: "5px",
            marginBottom: "10px",
            // borderRadius: "3px",
            // borderWidth: "1px",
            // boxShadow: "1px 1px 2px #797979",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) =>
              setDateValue(value.label, value.value, optionDates)
            }
            defaultValue={optionDates[0]}
            options={optionDates}
          />
        </div>
        {showDate && (
          <div style={{ marginRight: "10px", float: "right", width: "150px" }}>
            <Flatpickr
              className="form-control d-block"
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}
        <div
          style={{
            //float: "right",
            //margin: "10px",
            // marginRight: "5px",
            marginBottom: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadVistedPlacesData(0, searchKey)}
          >
            Submit
          </Button>
        </div>
        {hasAccess(42) && (
          <div
            style={{
              //float: "right",
              //margin: "10px",
              // height:'10px',
              // width:'35px',
              marginBottom: "5px",
              marginRight: "5px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
          >
            <Button
              className="me-2"
              variant="success btn-rounded"
              onClick={() => setCallAudioModal(true)}
            >
              <i className="fa fa-phone fa-1x fa-rotate-90" />
              &nbsp;Call
            </Button>
          </div>
        )}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "-5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            {isFieldInvstgn ? (
              <BootstrapTable
                keyField="panNo"
                data={fieldInvstgtnList}
                columns={fieldInvstgncolumns}
              />
            ) : (
              <BootstrapTable
                keyField="panNo"
                data={visitedPlacesList}
                columns={columns}
              />
            )}
          </div>

          {fieldInvstgtnList.length === 0 && visitedPlacesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      {toggle === "chatbox" && (
        <Modal className="fade" show={toggle === "chatbox"} size="xl">
          <Modal.Header>
            <Modal.Title>Customer Information</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setToggle(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body
            style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
          >
            <div>
              <div className="custom-tab-1">
                <Tab.Container defaultActiveKey={"dashboard"}>
                  <Nav as="ul" className="nav-tabs-custom nav-justified">
                    <Nav.Item as="li" key="0">
                      <Nav.Link eventKey="dashboard">
                        <b> Details </b>
                      </Nav.Link>
                    </Nav.Item>
                    {visitedPlacesDetailsList?.visitType === "Collections" ? (
                      <Nav.Item as="li" key="1">
                        <Nav.Link eventKey="history">
                          <b> History </b>
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {hasAccess(42) && (
                      <Nav.Item as="li" key="2">
                        <Nav.Link eventKey="callOverview">
                          <b> Call History </b>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                  <Tab.Content className="pt-4">
                    <Tab.Pane eventKey="dashboard" key="0">
                      <CustomerDetails
                        visitedPlacesDetailsList={visitedPlacesDetailsList}
                      />
                    </Tab.Pane>
                    {visitedPlacesDetailsList?.visitType === "Collections" ? (
                      <Tab.Pane eventKey="history" key="1">
                        <VisitDetailsHistory
                          loanNumber={
                            visitedPlacesDetailsList.collectionVisit?.loanNumber
                          }
                        />
                      </Tab.Pane>
                    ) : null}
                    <Tab.Pane eventKey="callOverview" key="2">
                      <CallSummary mobileNo={getMobileNumberForCallHistory()} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Modal className="fade" show={callAudioModal} size="sm">
        <Modal.Header>
          <Modal.Title>Manual Call</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setCallAudioModal(false);
              setCallMobileNo(null);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <label style={{ fontWeight: "bold" }}>Mobile No</label>
            <input
              style={{
                width: "220px",
                height: "35px",
                borderRadius: "25px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                paddingLeft: "10px",
                marginLeft: "10px",
              }}
              type="number"
              minLength={0}
              maxLength={9999999999}
              placeholder="Enter Mobile No"
              onChange={(e) => {
                // const numericRegex = /^[0-9]{0,10}$/;

                // If the input value matches the numeric regex, update the state
                // if (e.target.value?.length === 10) {
                setCallMobileNo(e.target.value);
                console.log("callMobile", callMobileNo);
                // }
              }}
            />
            {(callMobileNo?.length < 10 || callMobileNo?.length > 10) && (
              <span style={{ marginTop: "5px" }} className="error">
                Mobile No is Invalid
              </span>
            )}
            <div style={{ marginTop: "5px" }}>
              <div>
                <Button
                  style={{ float: "right", width: "70px" }}
                  className="col-lg-2 m-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => {
                    if (callMobileNo !== null) {
                      if (callMobileNo?.length === 10) {
                        makeCall(callMobileNo);
                        setCallAudioModal(false);
                      } else if (
                        callMobileNo?.length < 10 ||
                        callMobileNo?.length > 10
                      ) {
                        return swal("Please Enter Valid Mobile No", {
                          icon: "error",
                          dangerMode: true,
                        });
                      }
                    } else if (callMobileNo === null) {
                      return swal("Please Enter Mobile No", {
                        icon: "error",
                        dangerMode: true,
                      });
                    }
                  }}
                >
                  <i className="fa fa-phone fa-1x fa-rotate-90" /> Call
                </Button>
                <Button
                  style={{ float: "right", width: "70px" }}
                  className="col-lg-2 m-2 float-right"
                  variant="danger light"
                  onClick={() => {
                    setCallAudioModal(false);
                    setCallMobileNo(null);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerMgmt;
