import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
// import { toaster } from "../../src/rest/apiUtils";
import TextField from "../CommonComponents/TextField";
import defaultImage from "../images/defaultImage.png";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import EmployeeLeave from "./EmployeeLeave";
import swal from "sweetalert";
import { EpochToOnlyDate } from "../CommonComponents/Helper";
import { hasAccess } from "../services/AuthService";

// import LeavesChart from "./LeavesChart"

function EmployeeLeaveDashboard({ userId, reportingOfficerId, flag }) {
  const [leaveCountResponse, setLeaveCountResponse] = useState([]);
  const [managerData, setManagerData] = useState([]);

  const [reportingModal, setReportingModal] = useState(false);
  const [showHoliday, setShowHoliday] = useState(false);
  const [dayTypeIndex, setDayTypeIndex] = useState(0);
  const [isFullDay, setIsFullDay] = useState(true);

  const [selectedDayType, setSelectedDayType] = useState("");
  const [value, onChange] = useState(new Date());
  const [onLoad] = useState([]);
  const [addLeaveModal, setAddLeaveModal] = useState(false);
  const [leaveStatus, setLeaveStatus] = useState("");
  const [leaveHistoryModal, setLeaveHistoryModal] = useState(false);
  const [leaveType, setLeaveType] = useState("PERSONAL");
  const [updateLeaveCountModal, setUpdateLeaveCountModal] = useState(false);
  const [holidaysList, setHolidaysList] = useState([]);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [holidayObj, setHolidayObj] = useState({});
  const handleReportingStructureClose = () => setReportingModal(false);

  useEffect(() => {
    getLeaveBalance();
    getManagerDetails();
    getAllHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const leaveTypes = [
    { label: "Personal Leave", value: "PERSONAL" },
    { label: "Sick Leave", value: "SICK" },
    { label: "Vacation Leave", value: "VACATION" },
  ];

  const dayTypes = [
    { label: "First Half", value: 1 },
    { label: "Second Half", value: 2 },
  ];

  const setDayTypeValue = (value, dayTypes) => {
    for (let i = 0; i < dayTypes.length; i++) {
      if (dayTypes[i].label === value) {
        setDayTypeIndex(i);
        setSelectedDayType(value);
      }
    }
  };
  const getAllHolidays = () => {
    moduleApi
      .postData(
        apiCall.getHolidays + loggedInUser.autoFinanceUser.organizationId,
        null
      )
      .then((response) => {
        setHolidaysList(response);
      });
  };

  // const setLeaveTypeValue = (value, leaveTypes) => {
  //   for (let i = 0; i < leaveTypes.length; i++) {
  //     if (leaveTypes[i].label === value) {
  //       setLeaveTypeIndex(i);
  //       setSelectedLeaveType(value);
  //     }
  //   }
  // };

  const leavePattern = /^(?:\d+|\d*\.\d*5)$/;

  const updateLeaveCountValidate = Yup.object({
    sickLeave: Yup.string()
      .required("Sick Leave  is required")
      .matches(leavePattern, "please enter a valid number"),
    personleave: Yup.string()
      .required("Personal Leave is required")
      .matches(leavePattern, "please enter a valid number"),
    vacationLeave: Yup.string()
      .required("Vacation Leave is required")
      .matches(leavePattern, "please enter a valid number"),
  });

  const updateLeaveCount = (values) => {
    var payload = {
      sickLeave: values.sickLeave,
      personleave: values.personleave,
      vacationLeave: values.vacationLeave,
      userId: userId,
    };
    moduleApi.postData(apiCall.editLeaveCounts, payload).then((response) => {
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          swal("Leave Requested Successfully", {
            icon: "success",
          });
          setUpdateLeaveCountModal(false);
          getLeaveBalance();
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const handleSubmit = (values) => {
    if (new Date(values.fromDate).getTime() > new Date(values.toDate).getTime())
      return swal("From date should be less than To Date", {
        icon: "error",
        dangerMode: true,
      });
    var payload = {
      appliedTime: 0,
      branchId: loggedInUser.autoFinanceUser.branchId,
      fromDate: new Date(values.fromDate).getTime() / 1000,
      fromDateHalfDay: selectedDayType === "First Half" ? true : false,
      id: 0,
      lastUpdateTime: 0,
      leaveStatus: "PENDING",
      leaveType: leaveType,
      numberOfDays: 0,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reason: values.reason,
      // reportingOfficerId: string,
      toDate: new Date(values.toDate).getTime() / 1000,
      toDateHalfDay: selectedDayType === "Second Half" ? true : false,
      userId: userId,
    };
    moduleApi.postData(apiCall.applyLeave, payload).then((response) => {
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          swal("Leave Requested Successfully", {
            icon: "success",
          });
          setAddLeaveModal(false);
          getLeaveBalance();
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  var config = {
    series: [
      leaveCountResponse.personleave,
      leaveCountResponse.sickLeave,
      leaveCountResponse.vacationLeave,
    ],
    options: {
      chart: {
        type: "donut",
        height: 300,
      },
      labels: ["Personal Leaves", "Sick Leaves", "Vacation Leaves"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      colors: ["var(--primary)", "#61CFF1", "#FFDA7C", "#E477FF"],
      legend: {
        position: "bottom",
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 1800,
          options: {
            chart: {
              height: 200,
            },
          },
        },
        {
          breakpoint: 1800,
          options: {
            chart: {
              height: 200,
            },
          },
        },
      ],
    },
  };

  const getLeaveBalance = () => {
    moduleApi.getData(apiCall.getLeavesCount + userId).then((response) => {
      setLeaveCountResponse(response);
    });
  };

  const validate = Yup.object({
    fromDate: Yup.string().required("From Date is required"),
    toDate: Yup.string().required("To Date is required"),
    reason: Yup.string().required("Reason For Leave is required"),
  });

  const isTileDisabled = (activeStartDate, date, view) => {
    for (var i = 0; i < onLoad.length; i++) {
      if (
        new Date(onLoad[i].date).getTime() ===
        new Date(activeStartDate.date).getTime()
      ) {
        return true;
      } else if (
        activeStartDate.date.getDay() === 0 ||
        activeStartDate.date.getDay() === 6
      ) {
        return false;
      }
    }
    return false;
  };

  const getManagerDetails = () => {
    moduleApi
      .postData(apiCall.getUser + reportingOfficerId)
      .then((response) => {
        setManagerData(response);
      });
  };

  const onHolidayClick = (date) => {
    console.log(date.getDate());
    var d = date;
    var found = false;
    for (let index = 0; index < holidaysList.length; index++) {
      const element = holidaysList[index];
      d = new Date(element.date * 1000);
      if (
        d.getFullYear() === date.getFullYear() &&
        d.getMonth() === date.getMonth() &&
        d.getDate() === date.getDate()
      ) {
        found = true;
        setHolidayObj(element);
        break;
      }
    }
    found && setShowHoliday(true);
  };

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Row>
        <Col>
          <div
            className="card contact-bx item-content"
            style={{
              backgroundColor: "rgb(243,240,249)",
            }}
          >
            <div className="card-header border-0"></div>

            <div>
              <Row lg="12">
                <Col lg="6">
                  <Row>
                    <Col>
                      <div className="mb-3 mt-4" style={{ marginLeft: "25px" }}>
                        {" "}
                        <div id="chart">
                          <ReactApexChart
                            options={config.options}
                            series={config.series}
                            type="donut"
                            height={300}
                          />
                        </div>
                        {/* <h4
                          style={{
                            fontSize: "13px",
                            fontWeight: "normal",
                            color: "#6a6a6a",
                          }}
                        >
                          Total Leave :
                        </h4> */}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div
                        className="mb-3 mt-4"
                        style={{ marginLeft: "25px", textAlignLast: "end" }}
                      >
                        <h4
                          style={{
                            fontSize: "13px",
                            fontWeight: "normal",
                            color: "#6a6a6a",
                          }}
                        >
                          Availed :
                        </h4>
                      </div>
                    </Col>
                    <Col>
                      <div
                        className="mb-3 mt-4"
                        style={{ marginLeft: "25px", textAlignLast: "start" }}
                      >
                        <h4
                          style={{
                            fontSize: "13px",
                            // fontWeight: "normal",
                            fontWeight: "bold",
                          }}
                        >
                          {leaveCountResponse.availedLeavesCount}{" "}
                          {leaveCountResponse.availedLeavesCount < 2
                            ? "day"
                            : "days"}
                        </h4>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        className="mb-3 mt-4"
                        style={{ marginLeft: "25px", textAlignLast: "end" }}
                      >
                        <h4
                          style={{
                            fontSize: "13px",
                            fontWeight: "normal",
                            color: "#6a6a6a",
                          }}
                        >
                          Total :
                        </h4>
                      </div>
                    </Col>
                    <Col>
                      <div
                        className="mb-3 mt-4"
                        style={{ marginLeft: "25px", textAlignLast: "start" }}
                      >
                        <h4
                          style={{
                            fontSize: "13px",
                            // fontWeight: "normal",
                            fontWeight: "bold",
                          }}
                        >
                          {leaveCountResponse.totalCount} days
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg="6">
                  <div className="mb-3 mt-4">
                    <h4
                      style={{
                        fontSize: "13px",
                        fontWeight: "normal",
                        color: "#6a6a6a",
                        textAlignLast: "start",
                      }}
                    >
                      Reporting To
                    </h4>
                    <h4
                      className="fs-14 font-w600"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        textAlignLast: "start",
                      }}
                    >
                      {managerData.autoFinanceUser?.name
                        ? managerData.autoFinanceUser?.name
                        : "-"}
                    </h4>
                  </div>
                  <div className="mb-3 mt-4">
                    <h4
                      className="fs-14 font-w600"
                      style={{ fontSize: "16px", textAlignLast: "start" }}
                    >
                      LEAVE BALANCE
                    </h4>
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    {/* <div
                        className="d-flex align-items-center justify-content-between mb-4"
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          backgroundColor: "white",
                          height: "30px",
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#FF86B1",
                            width: "5px",
                            left: "15px",
                            zIndex: "5",
                            height: "30px",
                          }}
                        ></div>
                        <span style={{ marginLeft: "20px" }}>
                          Leaves Without Pay
                        </span>
                        <span
                          className="fs-18 font-w600"
                          style={{ marginRight: "10px" }}
                        >
                          2
                        </span>
                      </div> */}
                    <div
                      className="d-flex align-items-center justify-content-between  mb-4"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: "white",
                        height: "30px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#886CC0",
                          width: "5px",
                          left: "15px",
                          zIndex: "5",
                          height: "30px",
                        }}
                      ></div>
                      <span style={{ marginLeft: "20px" }}>
                        Personal Leaves
                      </span>
                      <span
                        className="fs-18 font-w600"
                        style={{ marginRight: "10px" }}
                      >
                        {leaveCountResponse.personleave
                          ? leaveCountResponse.personleave
                          : "-"}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between  mb-4"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: "white",
                        height: "30px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#61CFF1",
                          width: "5px",
                          left: "15px",
                          zIndex: "5",
                          height: "30px",
                        }}
                      ></div>
                      <span style={{ marginLeft: "20px" }}>Sick Leaves</span>

                      <span
                        className="fs-18 font-w600"
                        style={{ marginRight: "10px" }}
                      >
                        {leaveCountResponse.sickLeave
                          ? leaveCountResponse.sickLeave
                          : "-"}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between  mb-4"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: "white",
                        height: "30px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#FFDA7C",
                          width: "5px",
                          left: "15px",
                          zIndex: "5",
                          height: "30px",
                        }}
                      ></div>
                      <span style={{ marginLeft: "20px" }}>
                        Vacation Leaves
                      </span>
                      <span
                        className="fs-18 font-w600"
                        style={{ marginRight: "10px" }}
                      >
                        {leaveCountResponse.vacationLeave
                          ? leaveCountResponse.vacationLeave
                          : "-"}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              <div style={{ marginBottom: "10px" }}>
                {flag && (
                  <div style={{ boxSizing: "border-box" }}>
                    <Button
                      color="primary"
                      backgroundColor="#fff"
                      border="1px solid #cfcfcf"
                      type="button"
                      style={{
                        marginRight: "20px",
                        marginBottom: "20px",
                        float: "right",
                        display: "flex",
                      }}
                      onClick={() => setAddLeaveModal(true)}
                    >
                      Apply Leave
                    </Button>
                  </div>
                )}
                {
                // loggedInser.isSuperAdmin !== true && 
                hasAccess(33) && (
                  <div>
                    <Button
                      color="#000"
                      type="button"
                      style={{
                        marginRight: "20px",
                        marginBottom: "20px",
                        float: "right",
                        display: "flex",
                        border: "1px solid #cfcfcf",
                      }}
                      onClick={() => setUpdateLeaveCountModal(true)}
                    >
                      Update Leave Count
                    </Button>
                  </div>
                )}

                <div>
                  {/* <Button
                      color="#000"
                      type="button"
                      style={{
                        marginRight: "20px",
                        marginBottom: "20px",
                        float: "right",
                        display: "flex",
                        border: "1px solid #cfcfcf",
                      }}
                      onClick={()=>setReportingModal(true)}
                    >
                      Reporting Structure
                    </Button> */}
                  <Button
                    color="#000"
                    type="button"
                    style={{
                      marginRight: "20px",
                      marginBottom: "20px",
                      float: "right",
                      display: "flex",
                      border: "1px solid #cfcfcf",
                    }}
                    onClick={() => setLeaveHistoryModal(true)}
                  >
                    Leave History
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col lg="4">
          <div
            className="card contact-bx item-content"
            style={{
              backgroundColor: "rgb(243,240,249)",
              height: "100px",
            }}
          >
            <Row style={{ display: "flex", margin: "20px" }}>
              <Col>
                <Row>
                  <span
                    style={{
                      color: "#6a6a6a",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    PENDING
                  </span>
                </Row>
                <Row>
                  <span
                    style={{
                      marginLeft: "25px",
                      color: "#db9500",

                      paddingTop: "5px",
                      fontSize: "18px",
                      cursor: "pointer",
                      textAlignLast: "left",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setLeaveStatus("PENDING");
                      setLeaveHistoryModal(true);
                    }}
                  >
                    {leaveCountResponse.pendingRequestCount >= 0
                      ? leaveCountResponse.pendingRequestCount
                      : "-"}
                  </span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span
                    style={{
                      color: "#6a6a6a",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    APPROVED
                  </span>
                </Row>
                <Row>
                  <span
                    style={{
                      marginLeft: "25px",
                      color: "#1a871a",
                      paddingTop: "5px",
                      fontSize: "18px",
                      cursor: "pointer",
                      textAlignLast: "left",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setLeaveStatus("ACCEPTED");
                      setLeaveHistoryModal(true);
                    }}
                  >
                    {leaveCountResponse.acceptedRequestCount >= 0
                      ? leaveCountResponse.acceptedRequestCount
                      : "-"}
                  </span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span
                    style={{
                      color: "#6a6a6a",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    REJECTED
                  </span>
                </Row>
                <Row>
                  <span
                    style={{
                      marginLeft: "25px",
                      color: "#d40f0f",
                      paddingTop: "5px",
                      fontSize: "18px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      textAlignLast: "left",
                    }}
                    onClick={() => {
                      setLeaveStatus("REJECTED");
                      setLeaveHistoryModal(true);
                    }}
                  >
                    {leaveCountResponse.rejectedRequestCount >= 0
                      ? leaveCountResponse.rejectedRequestCount
                      : "-"}
                  </span>
                </Row>
              </Col>
            </Row>
          </div>

          <div>
            <div>
              <Calendar
                onChange={onChange}
                value={value}
                // tileDisabled={({ activeStartDate, date, view }) => {date.getDay() === 0 || date.getDay() === 6}}
                tileDisabled={(activeStartDate, date, view) =>
                  isTileDisabled(activeStartDate, date, view)
                }
                onClickDay={(value, event) => onHolidayClick(value)}
                tileClassName={({ activeStartDate, date, view }) => {
                  if (view === "month") {
                    var d = new Date();
                    var found = false;
                    for (let index = 0; index < holidaysList.length; index++) {
                      const element = holidaysList[index];
                      d = new Date(element.date * 1000);
                      if (
                        d.getFullYear() === date.getFullYear() &&
                        d.getMonth() === date.getMonth() &&
                        d.getDate() === date.getDate()
                      ) {
                        found = true;
                        break;
                      }
                    }
                    return found ? "calendar-date-color" : null;
                  }
                }}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Modal className="fade" show={showHoliday}>
        <Modal.Header>
          <Modal.Title>Holiday</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowHoliday(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>{EpochToOnlyDate(holidayObj.date)}</span>
            <div className="card" style={{ backgroundColor: "whitesmoke" }}>
              <div
                style={{
                  marginTop: "15px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgb(9 147 125)",
                    position: "absolute",
                    width: "5px",
                    left: "15px",
                    zIndex: "5",
                    height: "35px",
                  }}
                ></div>
                <div className="media ai-icon">
                  <div
                    className="profile-photo"
                    style={{ marginLeft: "15px", marginRight: "15px" }}
                  ></div>
                  <div className="media-body">
                    <p
                      className="mb-1"
                      style={{ fontWeight: "700", fontSize: "14px" }}
                    >
                      {holidayObj.holidayName}
                    </p>
                    {/* <span
                      className="mb-0"
                      style={{ color: "#6a6a6a", fontSize: "12px" }}
                    >
                      {holidayObj.holidayDescription}
                    </span> */}
                    {/* <span className="badge badge-primary">+3.5%</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={reportingModal}>
        <Modal.Header>
          <Modal.Title>Reporting Structure</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={handleReportingStructureClose}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>Reporting Manager (Level 1)</span>
            <div className="card" style={{ backgroundColor: "whitesmoke" }}>
              <div
                style={{
                  marginTop: "15px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgb(3, 252, 211)",
                    position: "absolute",
                    width: "5px",
                    left: "15px",
                    zIndex: "5",
                    height: "40px",
                    marginTop: "5px",
                  }}
                ></div>
                <div className="media ai-icon">
                  <div
                    className="profile-photo"
                    style={{ marginLeft: "15px", marginRight: "15px" }}
                  >
                    <img
                      src={defaultImage}
                      height={50}
                      width={50}
                      className="img-fluid rounded-circle"
                      alt="profile"
                    />
                  </div>
                  <div className="media-body">
                    <p
                      className="mb-1"
                      style={{ fontWeight: "700", fontSize: "14px" }}
                    >
                      Tom
                    </p>
                    <span
                      className="mb-0"
                      style={{ color: "#6a6a6a", fontSize: "12px" }}
                    >
                      tom@gmail.com
                    </span>
                    {/* <span className="badge badge-primary">+3.5%</span> */}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: "transparent" }}>
              <span style={{ marginBottom: "5px" }}>Self</span>
              <div className="card" style={{ backgroundColor: "whitesmoke" }}>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(51, 153, 51)",
                      position: "absolute",
                      width: "5px",
                      left: "15px",
                      zIndex: "5",
                      height: "40px",
                      marginTop: "5px",
                    }}
                  ></div>
                  <div className="media ai-icon">
                    <div
                      className="profile-photo"
                      style={{ marginLeft: "15px", marginRight: "15px" }}
                    >
                      <img
                        src={defaultImage}
                        height={50}
                        width={50}
                        className="img-fluid rounded-circle"
                        alt="profile"
                      />
                    </div>
                    <div className="media-body">
                      <p
                        className="mb-1"
                        style={{ fontWeight: "700", fontSize: "14px" }}
                      >
                        John
                      </p>
                      <h4
                        className="mb-0"
                        style={{ color: "#6a6a6a", fontSize: "12px" }}
                      >
                        john@gmail.com
                      </h4>
                      {/* <span className="badge badge-primary">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Button
              style={{
                float: "right",
                marginRight: "20px",
              }}
              type="submit"
              color="primary"
              onClick={handleReportingStructureClose}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={leaveHistoryModal} size="lg">
        <Modal.Header>
          <Modal.Title>Leave History</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setLeaveHistoryModal(false);
              setLeaveStatus("");
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <EmployeeLeave userId={userId} status={leaveStatus} />
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={addLeaveModal}>
        <Modal.Header>
          <Modal.Title>Apply Leave</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setAddLeaveModal(false);
              setIsFullDay(true);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                fromDate: "",
                toDate: "",
                reason: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="From Date"
                            name="fromDate"
                            type="date"
                          />

                          <TextField
                            isAsterisk={true}
                            label="To Date"
                            name="toDate"
                            type="date"
                          />

                          <Field name="leaveType">
                            {({ field, form }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Leave Type
                                </label>
                                <Select
                                  placeholder="Select leave type"
                                  classNamePrefix="select-box"
                                  options={leaveTypes}
                                  defaultValue={leaveTypes[0]}
                                  onChange={(selectedOption) => {
                                    // setLeaveTypeValue(selectedOption);
                                    setLeaveType(selectedOption.value);
                                    // form.setFieldValue(
                                    //   "leaveType",
                                    //   selectedOption.label
                                    // );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                          {/* {errors.leaveType && (
                              <span className="error">{errors.leaveType}</span>
                            )} */}
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Reason for leave"
                              name="reason"
                              type="text"
                            />
                          </div>

                          <div
                            className="form-check form-switch form-switch-md mb-3"
                            style={{ display: "inline-flex" }}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="customSwitchsizemd"
                              style={{ fontWeight: "bold" }}
                            >
                              Full Day
                            </label>
                            <input
                              style={{ width: "40px", marginLeft: "15px" }}
                              type="checkbox"
                              checked={isFullDay}
                              className="form-check-input"
                              id="customSwitchsizemd"
                              onChange={(value) => setIsFullDay(!isFullDay)}
                            />
                          </div>

                          {!isFullDay && (
                            <div style={{ marginBottom: "20px" }}>
                              <Select
                                label="Select Type"
                                options={dayTypes}
                                onChange={(value) =>
                                  setDayTypeValue(value.label, dayTypes)
                                }
                                value={dayTypes[dayTypeIndex]}
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          {/* <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button> */}
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setAddLeaveModal(false);
                              setIsFullDay(true);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={updateLeaveCountModal}>
        <Modal.Header>
          <Modal.Title>Update Leave Count</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setUpdateLeaveCountModal(false);
              setIsFullDay(true);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                sickLeave: leaveCountResponse.sickLeave,
                personleave: leaveCountResponse.personleave,
                vacationLeave: leaveCountResponse.vacationLeave,
              }}
              onSubmit={updateLeaveCount}
              validationSchema={updateLeaveCountValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div style={{ marginTop: "10px" }}>
                          <TextField
                            isAsterisk={true}
                            label="Sick Leave"
                            name="sickLeave"
                            type="text"
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <TextField
                            isAsterisk={true}
                            label="Personal Leave"
                            name="personleave"
                            type="text"
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <TextField
                            isAsterisk={true}
                            label="Vacation Leave"
                            name="vacationLeave"
                            type="text"
                          />
                        </div>

                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setUpdateLeaveCountModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EmployeeLeaveDashboard;
