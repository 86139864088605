import { getTokenInLocalStorage } from "../services/AuthService";

const userData= getTokenInLocalStorage();
let orgshortcode= userData?.autoFinanceOrganization.orgshortcode;

export const AnalyticsConfig = {
    'highriskcount':'analytics/'+orgshortcode+'/highriskcount.csv',
    'ptpcount':'analytics/'+orgshortcode+'/ptpcount.csv',
    'repeathighriskcount':'analytics/'+orgshortcode+'/repeathighriskcount.csv',
    'repeatptpcount':'analytics/'+orgshortcode+'/repeatptpcount.csv',

}