export const SET_SALES_ALLOCATIONS_LIST = "SET_SALES_ALLOCATIONS_LIST";
export const SET_IS_CALL_NOT_ACTIVE = "SET_IS_CALL_NOT_ACTIVE";
export const SET_SALES_CALLS_COUNT = "SET_SALES_CALLS_COUNT";

export function setSalesAllocationsList(payload) {
    return {
      type: SET_SALES_ALLOCATIONS_LIST,
      payload,
    };
  }
  
  export function setIsCallNotActive(payload) {
    return {
      type: SET_IS_CALL_NOT_ACTIVE,
      payload,
    };
  }
  export function setSalesCallsCount(payload) {
    return {
      type: SET_SALES_CALLS_COUNT,
      payload,
    };
  }