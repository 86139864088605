import React from "react"
import * as Yup from "yup"
import { Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import 'react-toastify/dist/ReactToastify.css';
import TextField from "../../CommonComponents/TextField";
import { Field } from "formik";
import Select from "react-select";



// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


const EmploymentInfo = ({ key, mainOBJ, onChange, errors }) => {
let businessConstitutionOptions=[
    { label: "Proprietor", value: "Proprietor" },
    { label: "Partnership", value: "Partnership" },
    { label: "Pvt Ltd Co", value: "Pvt Ltd Co" },
    { label: "Govt Enterprise", value: "Govt Enterprise" },
]

let employmentYearsOptions=[
    {label:1, value:1},
    {label:2, value:2},
    {label:3, value:3},
    {label:4, value:4},
    {label:5, value:5},
    {label:6, value:6},
    {label:7, value:7},
    {label:8, value:8},
    {label:9, value:9},
    {label:10, value:10},
    {label:11, value:11},
    {label:12, value:12},
    {label:13, value:13},
    {label:14, value:14},
    {label:15, value:15},
    {label:16, value:16},
    {label:17, value:17},
    {label:18, value:18},
    {label:19, value:19},
    {label:20, value:20},
    {label:21, value:21},
    {label:22, value:22},
    {label:23, value:23},
    {label:24, value:24},
    {label:25, value:25},
    {label:26, value:26},
    {label:27, value:27},
    {label:28, value:28},
    {label:29, value:29},
    {label:30, value:30}
]

  return (
    <div style={{ margin: "15px", maxHeight:'400px', overflowY:'auto' }}>
      <Row>
        <Col>
          <TextField isAsterisk={true} label="Type of Employment/Organization" name="type_of_org" type="text" />
          {/* <TextField isAsterisk={true} label="Business Constitution" name="business_constitution" type="text" /> */}
          <Field name="business_constitution">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
    //   className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Business Constitution
    </label>
    <Select
      placeholder="Select Business Constitution"
      classNamePrefix="select-box"
      options={businessConstitutionOptions}
      defaultValue={{
        label: mainOBJ?.business_constitution,
        value: mainOBJ?.business_constitution,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "business_constitution",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.business_constitution && (
<span className="error">{errors.business_constitution}</span>
)}
          <TextField isAsterisk={true} label="Nature of Business/Job Description" name="nature_of_business" type="text" />
        
        </Col>
        <Col> 
        {/* <TextField isAsterisk={true} label="No. of Years in Employment/Business" name="no_of_years_emp" type="text" /> */}
        <Field name="no_of_years_emp">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      No. of Years in Employment/Business
    </label>
    <Select
      placeholder="Select No. of Years in Employment/Business"
      classNamePrefix="select-box"
      options={employmentYearsOptions}
      defaultValue={{
        label: mainOBJ?.no_of_years_emp,
        value: mainOBJ?.no_of_years_emp,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "no_of_years_emp",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.no_of_years_emp && (
<span className="error">{errors.no_of_years_emp}</span>
)}
        <TextField isAsterisk={true} label="Total Employment/Bussiness Stability (In Years and Months)" name="total_employeement" type="text" />
        <TextField isAsterisk={true} label="Net/Gross Monthly Income" name="gmi" type="text" />
       
        </Col>
      </Row>
    </div>
  )
}

EmploymentInfo.label = "Employment/Business Info"
EmploymentInfo.initialValues = {
  type_of_org: "",
  business_constitution: "",
  nature_of_business: "",
  no_of_years_emp: "",
  total_employeement: "",
  gmi: "", 
}

EmploymentInfo.validationSchema = Yup.object({
  type_of_org: Yup.string().required("Type of Employment/Organization is required"),
//   business_constitution: Yup.string().required("Business Constitution is required"),
  nature_of_business: Yup.string().required("Nature of Business/Job Description is required"),
  no_of_years_emp: Yup.string().required("No. of Years in Employment/Business is required"),
  total_employeement: Yup.string().required("Total Employment/Bussiness Stability (In Years and Months) is required"),
  gmi: Yup.string().required("Net/Gross Monthly Income is required"),


  
  
})
EmploymentInfo.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
}

export default EmploymentInfo