import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
// import Select from "react-select";
// import * as moduleApi from "../rest/moduleApi";
import * as moduleApi2 from "../rest/moduleApi2";
import { apiCall } from "../rest/restApi";
// import { isAdmin } from "../services/AuthService";
// import { toaster } from "../rest/apiUtils";
// import { EpochToOnlyDate } from "../CommonComponents/Helper";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga";

const UploadPayrollExcel = ({ close, data }) => {
  const [excelFile, setExcelFile] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [binaryExcelFileData, setBinaryExcelFileData] = useState(null);
  const [fileName, setFileName] = useState(null);

  // const [executivesList, setExecutivesList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  // const [selectedExecutive, setSelectedExecutive] = useState('');
  const [excelFileError, setExcelFileError] = useState(null);

  // submit
  const [excelData, setExcelData] = useState(null);
  // it will contain array of objects

  // var userData = JSON.parse(localStorage.getItem("userDetails"));

  // useEffect(() => {
  //   loadExecutives();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // const phoneRegExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  // handle File
  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  var userData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    ReactGA.pageview("Run Payroll Page");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    console.log("UploadedFileSize", selectedFile.size / 1024 ** 2);
    if (selectedFile.size / 1024 ** 2 >= 5) {
      return swal("Please Upload a File Less than 5 MB", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
          var bytes = new Uint8Array(reader.result);
          console.log("result", bytes);
          setExcelFile(e.target.result);
          setBinaryExcelFileData(bytes);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("plz select your file");
    }
  };

  const headerTitles = [
    "empName",
    "designation",
    "basicPay",
    "hra",
    "specialAllowance",
    "totalEarnings",
    "providentFund",
    "professionalTax",
    "totalDeductions",
    "netPay",
  ];

  const handleSubmit = (e) => {
    setExcelData(null);
    // setIsLoading(true);
    setLoader(true);
    console.log("excel", excelFile);

    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
      const data2 = XLSX.utils.json_to_sheet(data, { skipHeader: true });
      const data3 = XLSX.utils.sheet_to_json(data2, { header: headerTitles });

      console.log("data", data3);
      //       for (let i = 0; i < data3.length; i++) {
      //         // console.log('date'+i, new Date('03-06-22').getTime() );
      // //         var intervalStart="13-11-2014 16:22:00"
      // //         var formattedDays = intervalStart.split(" ")[0].split("-");
      // // var formattedTime = intervalStart.split(" ")[1].split(":");
      // // var epochStart = new Date(formattedDays[2], formattedDays[1] - 1,formattedDays[0],formattedTime[0],formattedTime[1],formattedTime[2],0).getTime()/1000;
      // // console.log('date3', epochStart);
      //         if(data3[i].dueDate!=='')
      //         data3[i].dueDate =EpochToOnlyDate(
      //           new Date(Date.UTC(0, 0, data3[i].dueDate - 1)) / 1000);
      //       }

      // for (let i = 0; i < data3.length; i++) {
      //   if (data3[i].dueDate !== "")
      //     if (typeof data3[i].dueDate === "string") {
      //       var formattedDays;
      //       var intervalStart = data3[i].dueDate + " 23:59:59";
      //       if (data3[i].dueDate.includes("-")) {
      //         formattedDays = intervalStart.split(" ")[0].split("-");
      //       } else if (data3[i].dueDate.includes("/")) {
      //         formattedDays = intervalStart.split(" ")[0].split("/");
      //       }
      //       var formattedTime = intervalStart.split(" ")[1].split(":");
      //       var epochStart =
      //         new Date(
      //           formattedDays[2],
      //           formattedDays[1] - 1,
      //           formattedDays[0],
      //           formattedTime[0],
      //           formattedTime[1],
      //           formattedTime[2],
      //           0
      //         ).getTime() / 1000;
      //       data3[i].dueDate = EpochToOnlyDate(epochStart);
      //     } else {
      //       data3[i].dueDate = EpochToOnlyDate(
      //         new Date(Date.UTC(0, 0, data3[i].dueDate - 1)) / 1000
      //       );
      //     }
      // }
      setExcelData(data3);
    } else {
      setExcelData(null);
    }
    setIsLoading(false);
    setLoader(false);
  };

  const sendExcelFileToS3 = () => {
    if (fileName === null) {
      return swal("Please Enter File Name", {
        icon: "error",
        dangerMode: true,
      });
    }
    // let url =
    // apiCall.payrollUploadBaseUrl + "?action=getPresignedUrlForPayRollFileUpload";
    let payload = {
      organizationId: userData.autoFinanceUser.organizationId,
      uploadedUserId: userData.autoFinanceUser.userId,
      fileName: fileName,
      status: "PENDING",
    };
    // moduleApi.postDataByOrgIdForPayroll(url, payload).then((response) => {
      moduleApi2
      .postData(apiCall.getPreSignedUrlForUplaodPayrollFile, payload)
      .then((response) => {
      console.log("preSignedUrl", response);
      if (response) {
        if (response?.message === "Service Unavailable") {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try again", {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.message) {
          setIsLoading(false);
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.presignedUrl) {
          moduleApi2
            .putDataByName(response?.presignedUrl, binaryExcelFileData)
            .then((response) => {
              //Service Unavailable
              console.log("Payroll Upload", response);
              setIsLoading(false);
              data();
              close();
              swal(
                "Please Check the Status of the " +
                  payload.fileName +
                  " File in Uploaded Files Tab",
                {
                  icon: "success",
                }
              );
            });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div className="container">
          <div className="form">
            <form
              className="form-group"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Row>
                {/* <Col>
                      <div style={{ marginBottom: "5px" }}>
                        <label>
                          <h6>Select Executive</h6>
                        </label>
                        <Select
                          isSearchable={false}
                          onChange={(value) => {
                            setSelectedExecutive(value);
                          }}
                          // value={executivesList[executiveFilterIndex]}
                          options={executivesList}
                        />
                      </div>
                    </Col> */}
                <Col>
                  <label>
                    <h6>Upload Excel file</h6>
                  </label>
                  <br></br>
                  <input
                    style={{ padding: "11px" }}
                    type="file"
                    className="form-control"
                    onChange={handleFile}
                    required
                  ></input>
                  {excelFileError && (
                    <div
                      className="text-danger"
                      style={{ marginTop: 5 + "px" }}
                    >
                      {excelFileError}
                    </div>
                  )}
                </Col>
                <Col>
                  <label>
                    <h6>File Name</h6>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="fileName"
                    onChange={(e) => {
                      setFileName(e.target.value);
                    }}
                    style={{ padding: "11px" }}
                  />
                </Col>
                <Col>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      // if (excelFile !== null) {
                        excelFile !== null && setIsSubmit(true); 
                      //   if (fileName === null) {
                      //     return swal("Please Enter File Name", {
                      //       icon: "error",
                      //       dangerMode: true,
                      //     });
                      //   } else {
                      //     setIsSubmit(true);
                      //   }
                      // }
                    }}
                    style={{ marginTop: 35 + "px" }}
                  >
                    {loader && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        variant="light"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;&nbsp;Preview
                  </button>
                  {isSubmit && (
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                      onClick={() => {
                        sendExcelFileToS3();
                      }}
                    >
                      Submit
                    </button>
                  )}
                </Col>
              </Row>
            </form>
          </div>

          <br></br>
          <hr></hr>

          {/* view file section */}
          <h5>View Excel file</h5>
          <div className="viewer">
            {excelData === null && <>No file selected</>}
            {excelData !== null && (
              <div className="table-responsive">
                <table className="table">
                  <thead
                    style={{
                      backgroundColor: "rgb(243,240,249)",
                      top: "1px",
                      position: "sticky",
                    }}
                  >
                    <tr style={{ textAlign: "center" }}>
                      <th scope="col">Emp Name</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Basic Pay</th>
                      <th scope="col">HRA</th>
                      <th scope="col">Special Allowance</th>
                      <th scope="col">Total Earnings</th>
                      <th scope="col">Provident Fund</th>
                      <th scope="col">Professional Tax</th>
                      <th scope="col">Total Deductions</th>
                      <th scope="col">Net Pay</th>
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.map((individualExcelData) => (
                      <tr
                        style={{ textAlign: "center", fontWeight: "normal" }}
                        key={individualExcelData.empName}
                      >
                        <td>{individualExcelData.empName}</td>
                        <td>{individualExcelData.designation}</td>
                        <td>{individualExcelData.basicPay}</td>
                        <td>{individualExcelData.hra}</td>
                        <td>{individualExcelData.specialAllowance}</td>
                        <td>{individualExcelData.totalEarnings}</td>
                        <td>{individualExcelData.providentFund}</td>
                        <td>{individualExcelData.professionalTax}</td>
                        <td>{individualExcelData.totalDeductions}</td>
                        <td>{individualExcelData.netPay}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default UploadPayrollExcel;
