import React, { useState, useEffect } from "react";
import { getFileExtension, getFileKeyName } from "../utils";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import imageNotFound from "../../images/imageNotFound.jpg";
import { LoadingOverlayComponent } from "../../CommonComponents/LoadingOverlay";
import { Button } from "react-bootstrap";
import axios from "axios";
import { SRLWrapper } from "simple-react-lightbox";
import swal from "sweetalert";

const EditImages2 = ({ key, mainOBJ, onChange, errors }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveButton, setIsSaveButton] = useState(false);
  const [images, setImages] = useState([]);
  const [tempImages, setTempImages] = useState([]);
  const [tempImageFiles, setTempImageFiles] = useState([]);
  //   const [imageOneUrl, setImageOneUrl] = useState("");
  //   const [imageTwoUrl, setImageTwoUrl] = useState("");
  //   const [imageThreeUrl, setImageThreeUrl] = useState("");
  //   const [imageFourUrl, setImageFourUrl] = useState("");
  //   const [imageFiveUrl, setImageFiveUrl] = useState("");
  const maxImages = 5;

  // Fetch images from the server
  useEffect(() => {
    // const fetchImages = async () => {
    //   try {
    //     const response = await axios.get('https://api.example.com/images');
    //     setImages(response.data);
    //   } catch (error) {
    //     console.error('Error fetching images:', error);
    //   }
    // };

    // fetchImages();
    downloadImages(mainOBJ);
    getTempImagesUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadImages = async (allocationDetails) => {
    setIsLoading(true);
    // if (allocationDetails.dedupe_signature_image_url) {
    //   let requestPayload = {
    //     fileKeyName: getFileKeyName(
    //       allocationDetails.dedupe_signature_image_url
    //     ),
    //   };
    //   nodeApi
    //     .postData(apiCall.downloadFromS3, requestPayload)
    //     .then((response) => {
    //       setDedupeSignatureUrl(response.url);
    //     });
    // }
    let tempImages = [...images];

    for (let index = 0; index < maxImages; index++) {
      if (allocationDetails["image_" + (index + 1)] !== "") {
        let requestPayload = {
          fileKeyName: getFileKeyName(
            allocationDetails["image_" + (index + 1)]
          ),
        };
        const response = await nodeApi.postData(
          apiCall.downloadFromS3,
          requestPayload
        );
        tempImages[index] = response?.url;
        console.log(tempImages);
        // .then((response) => {
        //   //   setImageOneUrl(response.url);
        //   //   saveTempImagesInImages(response?.url, 0)

        //   tempImages[index] = response?.url;
        //   console.log(tempImages);
        // //   setImages(tempImages);
        // });
      } else {
        // let tempImages = [...images];
        tempImages[index] = "";
        console.log(tempImages);
        //   setImages(tempImages);
      }
    }
    setImages(tempImages);

    // if (allocationDetails.image_1) {
    //   let requestPayload = {
    //     fileKeyName: getFileKeyName(allocationDetails.image_1),
    //   };
    //   nodeApi
    //     .postData(apiCall.downloadFromS3, requestPayload)
    //     .then((response) => {
    //       //   setImageOneUrl(response.url);
    //       //   saveTempImagesInImages(response?.url, 0)
    //       let tempImages = [...images];
    //       tempImages[0] = response?.url;
    //       console.log(tempImages);
    //       setImages(tempImages);
    //     });
    // } else {
    //   // saveTempImagesInImages('', 0)
    //   let tempImages = [...images];
    //   tempImages[0] = "";
    //   console.log(tempImages);
    //   setImages(tempImages);
    // }
    // if (allocationDetails.image_2) {
    //   let requestPayload = {
    //     fileKeyName: getFileKeyName(allocationDetails.image_2),
    //   };
    //   nodeApi
    //     .postData(apiCall.downloadFromS3, requestPayload)
    //     .then((response) => {
    //       //   setImageTwoUrl(response.url);
    //       //   setImages((image) => [...image, response?.url]);
    //       // saveTempImagesInImages(response?.url, 1)
    //       let tempImages = [...images];
    //       tempImages[1] = response?.url;
    //       console.log(tempImages);
    //       setImages(tempImages);
    //     });
    // } else {
    //   // saveTempImagesInImages('', 1)
    //   let tempImages = [...images];
    //   tempImages[1] = "";
    //   console.log(tempImages);
    //   setImages(tempImages);
    // }
    // if (allocationDetails.image_3) {
    //   let requestPayload = {
    //     fileKeyName: getFileKeyName(allocationDetails.image_3),
    //   };
    //   nodeApi
    //     .postData(apiCall.downloadFromS3, requestPayload)
    //     .then((response) => {
    //       //   setImageThreeUrl(response.url);
    //       //   setImages((image) => [...image, response?.url]);
    //       saveTempImagesInImages(response?.url, 2);
    //     });
    // } else {
    //   // setImages((image)=>[...image, ''])
    //   saveTempImagesInImages("", 2);
    // }
    // if (allocationDetails.image_4) {
    //   let requestPayload = {
    //     fileKeyName: getFileKeyName(allocationDetails.image_4),
    //   };
    //   nodeApi
    //     .postData(apiCall.downloadFromS3, requestPayload)
    //     .then((response) => {
    //       //   setImageFourUrl(response.url);
    //       //   setImages((image) => [...image, response?.url]);
    //       saveTempImagesInImages(response?.url, 3);
    //     });
    // } else {
    //   // setImages((image)=>[...image, ''])
    //   saveTempImagesInImages("", 3);
    // }
    // if (allocationDetails.image_5) {
    //   let requestPayload = {
    //     fileKeyName: getFileKeyName(allocationDetails.image_5),
    //   };
    //   nodeApi
    //     .postData(apiCall.downloadFromS3, requestPayload)
    //     .then((response) => {
    //       //   setImageFiveUrl(response.url);
    //       //   setImages((image) => [...image, response?.url]);
    //       saveTempImagesInImages(response?.url, 4);
    //     });
    // } else {
    //   // setImages((image)=>[...image, ''])
    //   saveTempImagesInImages("", 4);
    // }
    setIsLoading(false);
  };

  const getTempImagesUrls = () => {
    let temp = [];
    let tempFiles = [];
    for (let index = 0; index < maxImages; index++) {
      if (mainOBJ["image_" + (index + 1)] !== "") {
        temp.push(mainOBJ["image_" + (index + 1)]);
      } else {
        temp[index] = "";
      }
    }
    console.log(temp);
    console.log(images);

    temp.forEach((item, index) => {
      if (item !== "") {
        tempFiles[index] = "";
      }
    });

    setTempImageFiles(tempFiles);
    setTempImages(temp);
    // setImages(temp);
  };

  // Handle image deletion
  const handleDelete = (index) => {
    const updatedImages = [...images];
    updatedImages[index] = "";
    const updatedImageFiles = [...tempImageFiles];
    updatedImageFiles[index] = "";
    const updatedImageUrls = [...tempImages];
    updatedImageUrls[index] = "";
    console.log("updatedImages", updatedImages);
    console.log("updatedImageFiles", updatedImageFiles);
    console.log("updatedTempImages", updatedImageUrls);
    setImages(updatedImages);
    setTempImageFiles(updatedImageFiles);
    setTempImages(updatedImageUrls);
    setIsSaveButton(true);
    swal("Image Deleted Successfully", {
      icon: "success",
      dangerMode: false,
    });
  };

  // Handle image upload
  const handleUpload = (event, index) => {
    const file = event.target.files[0];
    console.log('file',file);
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        return swal("Invalid file type. Only JPG and  PNG are allowed.", {
          icon: "error",
          dangerMode: true,
        });
      }
      const updatedImages = [...images];
      const updatedFiles = [...tempImageFiles];
      if (index !== undefined) {
        // Re-upload existing image
        updatedImages[index] = URL.createObjectURL(file);
        updatedFiles[index] = file;
      } else {
        // Add new image
        updatedImages.push(URL.createObjectURL(file));
        updatedFiles.push(file);
      }

      console.log("updatedImages", updatedImages);
      console.log("updatedFiles", updatedFiles);
      setTempImageFiles(updatedFiles);
      setImages(updatedImages);
      setIsSaveButton(true);
    }
  };

  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const saveImages = async () => {
    if (images.some((element) => element !== "") === false) {
      return swal(
        "Atleast One Image is Mandatory. Please Upload a image and then Save.",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }
    let updatedTempImages = [...tempImages];
    for (let index = 0; index < tempImageFiles?.length; index++) {
      if (tempImageFiles[index] !== "" && tempImageFiles[index] !== undefined) {
        setIsLoading(true);
        let uploadImagePayload = {
          fileKeyName:
            "image" +
            Date.now() +
            getFileExtension(tempImageFiles[index]?.name),
          type: "IMAGE",
        };
        // console.log("imagepayload_" + index, uploadImagePayload);
        const response = await nodeApi.postData(
          apiCall.getImageUrl,
          uploadImagePayload
        );
        //   .then((response) => {
        const url = response.imageUrls.fileUploadURL;
        const res = await axios({
          method: "PUT",
          url: url,
          data: tempImageFiles[index],
          headers: { "Content-Type": "image/png,image/jpeg" },
        });
        // .then((res) => {
        if (res.config && res.config.url) {
          
          // updatedTempImages[index] = getFileKeyName(filePath)+getFileExtension(tempImageFiles[index]?.name);
          updatedTempImages[index] = response.imageUrls.fileKeyName;
          console.log(index, updatedTempImages);
          setTempImages(updatedTempImages);
          setIsSaveButton(false);
        //   updateImageKeys(updatedTempImages);
          mainOBJ[`image_${index + 1}`] = updatedTempImages[index] || "";
          console.log(mainOBJ);
          setIsLoading(false);
        }
        // });
        //   });
      } 
      else {
        // let updatedTempImages2 = [...tempImages];
        // console.log(updatedTempImages2);
        // updateImageKeys(updatedTempImages);
            for (let i = 0; i < 5; i++) {
      mainOBJ[`image_${i + 1}`] = updatedTempImages[i] || "";
    }
        setIsSaveButton(false);
      }
      console.log('after',mainOBJ);
      
    }
    setTempImageFiles(['','','','',''])
  };

//   const updateImageKeys = (imageArray) => {
//     // console.log("before---", mainOBJ);
//     for (let i = 0; i < 5; i++) {
//       mainOBJ[`image_${i + 1}`] = imageArray[i] || "";
//     }
//     // console.log("after---", mainOBJ);
//   };

  return (
    <LoadingOverlayComponent showLoader={isLoading}>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "20px",
            justifyContent: "center",
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {image !== "" ? (
                <SRLWrapper options={options1}>
                  <img
                    src={image}
                    alt={`img-${index}`}
                    style={{
                      height: "150px",
                      width: "155px",
                      borderRadius: "5px",
                    }}
                  />
                </SRLWrapper>
              ) : (
                <img
                  src={imageNotFound}
                  alt={`img-${index}`}
                  style={{
                    height: "150px",
                    width: "155px",
                    borderRadius: "5px",
                  }}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {/* <Button onClick={() => handleDelete(index)}>Delete</Button> */}
                {image !== "" && (
                  <Button
                    style={{
                      float: "right",
                      margin: "10px",
                      marginRight: "15px",
                      fontFamily:
                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                    }}
                    className="me-2"
                    variant="danger light btn-rounded"
                    onClick={() => {
                      swal({
                        title: "Delete Image",
                        text: "Image Will Be Permanently Deleted. Are You Sure, You Want To Delete This Image?",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          handleDelete(index);
                        }
                      });
                    }}
                  >
                    <i className="fas fa-trash" />
                  </Button>
                )}
                <Button
                  style={{
                    float: "right",
                    margin: "10px",
                    marginRight: "15px",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="primary light btn-rounded"
                  //   onChange={(event) => handleUpload(event, index)}
                >
                  <label>
                    <i className="fas fa-upload" />
                    <input
                      type="file"
                      key={Date.now()}
                      style={{ display: "none" }}
                      onChange={(event) => handleUpload(event, index)}
                    />
                  </label>
                </Button>
                {/* <input
                  type="file"
                  
                  onChange={(event) => handleUpload(event, index)}
                /> */}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "20px",
            justifyContent: "center",
          }}
        >
          {images.length < maxImages && (
            <div style={{ textAlignLast: "center" }}>
              <Button className="me-2" variant="primary light btn-rounded">
                <label style={{ verticalAlign: "sub", marginTop: "5px" }}>
                  <i className="fa fa-plus"></i>&nbsp; Add Image
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                </label>
              </Button>
            </div>
          )}
          {isSaveButton === true && (
            <div style={{ textAlignLast: "center" }}>
              {" "}
              <Button
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => saveImages()}
              >
                Save Images
              </Button>
            </div>
          )}
        </div>
      </div>
    </LoadingOverlayComponent>
  );
};

EditImages2.label = "Uploaded Images";

export default EditImages2;
