import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoadingOverlay from "react-loading-overlay";
import * as moduleApi from "../rest/moduleApi";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import { apiCall } from "../rest/restApi";
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import swal from "sweetalert";
import * as helper from "../CommonComponents/Helper";

const FieldInvestigationGraph = ({ startDate, endDate, month }) => {
  const [data, setData] = useState(null);
  // const [showDate, setShowDate] = useState(false);
  //   const [CollectionGraphData, setCollectionGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    loadFieldInvestigationGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fiGraphToolTip = (series, seriesIndex, dataPointIndex, w, data2) => {
    let data = [];
    series.forEach((item) => {
      data.push(item[dataPointIndex]);
    });
    let statusKeys = [];
    let values = [];
    let tooltipdata =
      '<div style="margin-left:7px;margin-right:7px;margin-top:7px;font-size: 11px"> <ul>' +
      "<li ><b>Date</b> : " +
      w.config.xaxis.categories[dataPointIndex] +
      "</li>";
    for (let value of Object.keys(data2)) {
      statusKeys.push(value);
    }

    for (let value of Object.values(data2)) {
      values.push(value);
    }
    // console.log("props", statusKeys, values);
    for (let i = 0; i < series.length; i++) {
      tooltipdata =
        tooltipdata +
        '<li style="margin-left:7px;margin-right:7px;margin-bottom:3px;font-size: 11px" ><b>' +
        statusKeys[i] +
        "</b> : " +
        values[i][dataPointIndex] +
        "</li>" +
        "</ul></div>";
    }
    return tooltipdata;
  };

  // const fiGraphToolTip = (series, seriesIndex, dataPointIndex, w, data2) => {
  //   let data = [];
  //   series.forEach((item) => {
  //     data.push(item[dataPointIndex]);
  //   });
  //   console.log("props", data);
  //   return (
  //     '<div style="margin:7px;font-size: 11px"> <ul>' +
  //     "<li ><b>Date</b> : " +
  //     w.config.xaxis.categories[dataPointIndex] +
  //     "</li>" +
  //     "<li><b>FI Submitted</b> : " +
  //     data[0] +
  //     " </li>" +
  //     "<li><b>Positive</b> : " +
  //     data[1] +
  //     " </li>" +
  //     "</li>" +
  //     "<li><b>Negative</b> : " +
  //     data[2] +
  //     "</li>" +
  //     "</li>" +
  //     "<li><b>Door Locked</b> : " +
  //     data[3] +
  //     "</li>" +
  //     "<li><b>Refer to Credit</b> : " +
  //     data[4] +
  //     "</li>" +
  //     "</ul></div>"
  //   );
  // };

  const getPoints = (data) => {
    // console.log("dddd", data);
    var dataArr = [];
    for (let index = 0; index < data?.date.length; index++) {
      var ele = {
        x: data?.date[index],
        y: data?.totalCount[index],
        marker: {
          size: 0,
          fillColor: "#fff",
          strokeColor: "red",
          radius: 2,
          cssClass: "apexcharts-custom-class",
        },
        label: {
          borderColor: "#FF4560",
          offsetY: 0,
          style: {
            color: "#fff",
            background: "#FF4560",
          },
          text: shortenAmount(data?.totalCount[index]) + "",
        },
      };
      dataArr.push(ele);
    }
    return dataArr;
  };

  const shortenAmount = (value) => {
    if (value <= 0) return "";
    let val = Math.abs(value);
    if (val >= 10 ** 3 && val < 10 ** 6) {
      val = (val / 1000).toFixed(0) + " K";
    } else if (val >= 10 ** 6) {
      val = (val / 1000000).toFixed(0) + " M";
    } else {
      // eslint-disable-next-line no-self-assign
      val = val;
    }
    return val;
  };

  const loadFieldInvestigationGraph = () => {
    setIsLoading(true);
    var payload = {
      endDate: endDate,
      organizationid: userLoginDetails?.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? ""
        : userLoginDetails?.autoFinanceUser &&
          userLoginDetails?.autoFinanceUser.userId,
      roleName: "ADMIN",
      startDate: startDate,
      userId: "",
      reportType: month ? "month" : "",
    };
    moduleApi
      .postData(apiCall.fieldInvestigationGraph, payload)
      .then((response) => {
        // console.log("Newresponse", response);
        if (response) {
          if (response.message) {
            return swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            //   setCollectionGraphData(response);
            //   console.log("graph", graphData);

            let temp1 = {};
            const uniqueKeys = new Set();

            response?.forEach((obj) => {
              if (obj?.fiCounts !== null) {
                Object.keys(obj?.fiCounts).forEach((key) => {
                  uniqueKeys.add(key);
                });
              }
            });

            let statusKeys = Array.from(uniqueKeys);

            for (let i = 0; i < statusKeys.length; i++) {
              temp1[statusKeys[i]] = [];
            }

            var bArr = {
              date: [],
              totalCount: [],
              // doorLockedCount: [],
              // fiCount: [],
              // fiSubmittedCount: [],
              // negativeCount: [],
              // positiveCount: [],
              // referToCreditCount: [],
            };

            var s = startDate;
            var e = endDate;

            if (month) {
              var i = 0;
              while (i < 6) {
                var d = new Date();
                d.setDate(1);
                d.setHours(0, 0, 0, 0);
                d.setMonth(d.getMonth() - 6 + i);
                bArr.date.push(convertEpochTimeToDate(d.getTime() / 1000, 6));
                bArr.totalCount.push(0);
                for (let i = 0; i < Object.keys(temp1).length; i++) {
                  temp1[statusKeys[i]].push(0);
                }
                // bArr.doorLockedCount.push(0);
                // bArr.fiCount.push(0);
                // bArr.fiSubmittedCount.push(0);
                // bArr.negativeCount.push(0);
                // bArr.positiveCount.push(0);
                // bArr.referToCreditCount.push(0);
                i++;
              }
            } else {
              while (e > s) {
                bArr.date.push(convertEpochTimeToDate(s));
                bArr.totalCount.push(0);
                for (let i = 0; i < Object.keys(temp1).length; i++) {
                  temp1[statusKeys[i]].push(0);
                }
                // bArr.doorLockedCount.push(0);
                // bArr.fiCount.push(0);
                // bArr.fiSubmittedCount.push(0);
                // bArr.negativeCount.push(0);
                // bArr.positiveCount.push(0);
                // bArr.referToCreditCount.push(0);
                s = s + 86400;
              }
            }
            if (response.length > 0) {
              for (const iterator of response) {
                var position = 0;
                while (position < bArr.date.length) {
                  if (
                    bArr.date[position] ===
                    convertEpochTimeToDate(iterator.date, bArr.date.length)
                  ) {
                    bArr.totalCount[position] = iterator.totalCount;
                    for (let i = 0; i < Object.keys(temp1).length; i++) {
                      temp1[statusKeys[i]][position] = iterator?.fiCounts[
                        statusKeys[i]
                      ]
                        ? iterator.fiCounts[statusKeys[i]]
                        : 0;
                    }
                    // bArr.doorLockedCount[position] = iterator.doorLockedCount;
                    // bArr.fiCount[position] = iterator.fiCount;
                    // bArr.fiSubmittedCount[position] = iterator.fiSubmittedCount;
                    // bArr.negativeCount[position] = iterator.negativeCount;
                    // bArr.positiveCount[position] = iterator.positiveCount;
                    // bArr.referToCreditCount[position] =
                    //   iterator.referToCreditCount;

                    position++;
                    break;
                  } else if (
                    new Date(bArr.date[position]).getTime() / 1000 <
                    iterator.date
                  ) {
                    position++;
                  } else {
                    position++;
                  }
                }
              }
            }

            setData({
              series: getDynamicData(temp1),
              // series: [
              //   {
              //     name: "FI Submitted",
              //     data: bArr.fiSubmittedCount,
              //   },
              //   {
              //     name: "Positive",
              //     data: bArr.positiveCount,
              //   },
              //   {
              //     name: "Negative",
              //     data: bArr.negativeCount,
              //   },
              //   {
              //     name: "Door Locked",
              //     data: bArr.doorLockedCount,
              //   },
              //   {
              //     name: "Refer To Credit",
              //     data: bArr.referToCreditCount,
              //   },
              // ],
              options: {
                chart: {
                  type: "bar",
                  stacked: true,
                  height: 230,
                  toolbar: {
                    show: true,
                    tools: {
                      download: true,
                      selection: true,
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true,
                    },
                    export: {
                      csv: {
                        filename: "Field Investigation Trend",
                        columnDelimiter: ",",
                        headerCategory: "Date",
                        headerValue: "value",
                        dateFormatter(timestamp) {
                          return new Date(timestamp).toDateString();
                        },
                      },
                      svg: {
                        filename: bArr.graphName
                          ? bArr.graphName +
                            "_" +
                            helper.EpochToDate(Math.floor(new Date() / 1000))
                          : "Preview Graph",
                      },
                      png: {
                        filename: bArr.graphName
                          ? bArr.graphName +
                            "_" +
                            helper.EpochToDate(Math.floor(new Date() / 1000))
                          : "Preview Graph",
                      },
                    },
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: response.length > 3 ? "90%" : "70%",

                    dataLabels: {
                      position: "center",
                    },
                  },
                },
                // colors: [
                //   "#09BD3C",
                //   "#3349FF",
                //   "#FC2E53",
                //   "#CE7EF0",
                //   "#F4D03F",
                //   "#FA8072",
                //   "#40E0D0",
                // ],
                colors: [
                  function ({ value, seriesIndex, w }) {
                    switch (w.config.series[seriesIndex].name) {
                      case "FI Submitted":
                        return "#09BD3C";
                      case "Positive":
                        return "#66CDAA";
                      case "Negative":
                        return "#FC2E53";
                      case "Door Locked":
                        return "#757575";
                      case "Refer to Credit":
                        return "#728FCE";
                      default:
                        return "#3349FF";
                    }
                  },
                ],
                legend: {
                  show: true,
                  showForSingleSeries: true,
                  showForNullSeries: true,
                  showForZeroSeries: true,
                  position: "top",
                  horizontalAlign: "left",
                  onItemHover: {
                    highlightDataSeries: true,
                  },
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                noData: {
                  text: "No Data Found",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    color: "#000000",
                    fontSize: "14px",
                    fontFamily: "Helvetica",
                  },
                },
                dataLabels: {
                  textAnchor: "middle",

                  enabled: true,
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "13px",
                    colors: ["#000000"],
                  },
                  formatter: function (value, opt) {
                    if (value <= 0) return "";
                    let val = Math.abs(value);
                    if (val >= 10 ** 3 && val < 10 ** 6) {
                      val = (val / 1000).toFixed(0) + " K";
                    } else if (val >= 10 ** 6) {
                      val = (val / 1000000).toFixed(0) + " M";
                    } else {
                      // eslint-disable-next-line no-self-assign
                      val = val;
                    }
                    return val;
                  },
                },
                annotations: {
                  points: getPoints(bArr),
                },
                stroke: {
                  show: false,
                },
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
                xaxis: {
                  show: true,
                  labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: true,
                    // formatter: (val) => xAxisLabel(props, val), // just formats date to hh:mm format
                  },
                  categories: bArr.date,
                  //   formatter: function(value){
                  //     return convertEpochTimeToDate(value, bArr?.date?.length )
                  //   }
                },
                tooltip: {
                  custom: function ({
                    series,
                    seriesIndex,
                    dataPointIndex,
                    w,
                  }) {
                    return fiGraphToolTip(
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                      temp1
                    );
                  },
                  theme: "dark",
                },
                // eslint-disable-next-line no-dupe-keys
                //   legend: {
                //     position: "top",
                //     offsetY: 0,
                //     horizontalAlign: "right",
                //   },
              },
            });
            setIsLoading(false);
          }
        } else {
          return swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getDynamicData = (seriesData) => {
    let statusKeys = [];
    let values = [];
    let graphData = [];
    for (let value of Object.keys(seriesData)) {
      statusKeys.push(value);
    }

    for (let value of Object.values(seriesData)) {
      values.push(value);
    }

    for (let i = 0; i < Object.keys(seriesData).length; i++) {
      let result = {
        name: statusKeys[i].toString(),
        data: values[i],
      };
      graphData.push(result);
    }

    return graphData;
  };

  function convertEpochTimeToDate(epochTime, len) {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime * 1000));
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    if (len === 6) return month + " " + year;
    date_str = month + " " + day + "_" + year;
    return date_str;
  }
  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div id="chart" className="line-chart-style bar-chart">
        {data?.options && (
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="bar"
            height={400}
          />
        )}
      </div>
    </LoadingOverlay>
  );
};

export default FieldInvestigationGraph;
