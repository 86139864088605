import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { Field, Form, Formik } from "formik";
import { Col, Row } from "reactstrap";
import TextField from "../CommonComponents/TextField";
import * as Yup from "yup";
import swal from "sweetalert";

function EmployeeLeave({ userId, status }) {
  const [leavesList, setLeavesList] = useState([]);
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [leaveDetailsModal, setLeaveDetailsModal] = useState(false);
  const [leaveEditModal, setLeaveEditModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [addLeaveModal, setAddLeaveModal] = useState(false);
  const [isFullDay, setIsFullDay] = useState(true);
  const [leaveType, setLeaveType] = useState("PERSONAL");
  const [dayTypeIndex, setDayTypeIndex] = useState(0);
  const [selectedDayType, setSelectedDayType] = useState("");
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [totalElements, setTotalElements] = useState(1);
  const [currPage] = useState(0);

  var valIndex = 0;
  const leaveTypes = [
    { label: "Personal Leave", value: "PERSONAL" },
    { label: "Sick Leave", value: "SICK" },
    { label: "Vacation Leave", value: "VACATION" },
  ];
  const defaultLeaveType = {
    label: leaveDetails.leaveType,
    value: leaveDetails.leaveType,
  };
  const dayTypes = [
    { label: "First Half", value: 1 },
    { label: "Second Half", value: 2 },
  ];
  const setDayTypeValue = (value, dayTypes) => {
    for (let i = 0; i < dayTypes.length; i++) {
      if (dayTypes[i].label === value) {
        setDayTypeIndex(i);
        setSelectedDayType(value);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("userDetails"));
    setloggedInUser(userData);
    setDateValue("Last 7 Days", 1);
    loadLeavesListData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageNo = 0;
  const handleSubmit = (values) => {
    console.log(values);
    console.log(defaultLeaveType);
    const payLoad = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      fromDate: new Date(values.fromDate).getTime() / 1000,
      fromDateHalfDay: selectedDayType === "First Half" ? true : false,
      id: leaveDetails.id,
      leaveStatus: "PENDING",
      leaveType: leaveType,
      reason: values.reason,
      toDate: new Date(values.toDate).getTime() / 1000,
      toDateHalfDay: selectedDayType === "Second Half" ? true : false,
      userId: leaveDetails.userId,
    };
    console.log("payload", payLoad);
    moduleApi
      .postData(moduleApi.withPage(apiCall.editLeaves, pageNo, 10), payLoad)
      .then((response) => {
        console.log("response", response);

        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            swal("Leaves Updated Successfully!", {
              icon: "success",
            });
            loadLeavesListData(0);
            setLeaveEditModal(false);
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };
  const validate = Yup.object({
    fromDate: Yup.string().required("From Date is required"),
    toDate: Yup.string().required("To Date is required"),
    reason: Yup.string().required("Reason For Leave is required"),
  });

  const loadLeavesListData = (pageNo) => {
    setIsLoading(true);
    var LeavesListBody = {
      //  branchId : branchId,
      endDate: end,
      leaveStatus: status ? status : undefined,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      // reportingOfficerId:
      //   loggedInUser.autoFinanceUser.roleName === "MANAGER"
      //     ? loggedInUser.autoFinanceUser.userId
      //     : "",
      //  reportingOfficerId: userId,
      startDate: start,
      userId: userId,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getLeaveRequestsList, pageNo, 10),
        LeavesListBody
      )
      .then((response) => {
        var listData = [];

        response.content.forEach((element) => {
          let data = {
            appliedTime: convertEpochTimeToDate(element.appliedTime),
            branchId: element.branchId,
            fromDate: convertEpochTimeToDate(element.fromDate),
            fromDateHalfDay: element.fromDateHalfDay,
            id: element.id,
            lastUpdateTime: element.lastUpdateTime,
            leaveStatus: element.leaveStatus,
            leaveType: element.leaveType,
            numberOfDays: element.numberOfDays < 0 ? "0" : element.numberOfDays,
            organizationId: element.organizationId,
            reasonForreject: element.reasonForreject,
            reason: element.reason,
            reportingOfficerId: element.reportingOfficerId,
            toDate: convertEpochTimeToDate(element.toDate),
            toDateHalfDay: element.toDateHalfDay,
            userId: element.userId,
            fromToDates: fromToDates(
              convertEpochTimeToDate(element.fromDate),
              convertEpochTimeToDate(element.toDate)
            ),

            userModel: element.userModel,

            addedByUserId: element.userModel.addedByUserId,
            companyDesignation: element.userModel.companyDesignation,
            email: element.userModel.email,
            employeeId: element.userModel.employeeId,
            image: element.userModel.image,
            mobileNumber: element.userModel.mobileNumber,
            name: element.userModel.name,
            passwordUpdated: element.userModel.passwordUpdated,
            reportingOfficerIds: element.userModel.reportingOfficerIds,
            roleName: element.userModel.roleName,
            status: element.userModel.status,
            subOrdinateIds: element.userModel.subOrdinateIds,
            trackingEnabled: element.userModel.trackingEnabled,
          };
          listData.push(data);
        });
        setLeavesList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    loadLeavesListData(selectedPage - 1);
  };

  const columns = [
    {
      text: "Employee ID",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Leave Dates",
      dataField: "fromToDates",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Days",
      dataField: "numberOfDays",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Leave Type",
      dataField: "leaveType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Leave Applied Date",
      dataField: "appliedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.leaveStatus === "ACCEPTED") {
          return (
            <span className="badge badge-success">
              Approved
              <span className="ms-1 fa fa-check" />
            </span>
          );
        } else if (row.leaveStatus === "PENDING") {
          return (
            <span className="badge badge-warning">
              Pending
              <span className="ms-1 fas fa-stream" />
            </span>
          );
        } else if (row.leaveStatus === "REJECTED") {
          return (
            <span className="badge badge-danger">
              Rejected
              <span className="ms-1 fa fa-ban" />
            </span>
          );
        }
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // localStorage.setItem('executiveVisitedDetails', JSON.stringify(row))
          //setVisitedPlacesDetailsList(JSON.parse(localStorage.getItem('executiveVisitedDetails')));
          setLeaveDetails(row);
          setLeaveDetailsModal(true);
          //setToggle("chatbox");

          //   <Link  to="/executive-details">
          //         <i className="fa fa-trash"></i>
          //       </Link>
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.leaveStatus !== "PENDING") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // localStorage.setItem('executiveVisitedDetails', JSON.stringify(row))
          //setVisitedPlacesDetailsList(JSON.parse(localStorage.getItem('executiveVisitedDetails')));
          setLeaveDetails(row);
          setLeaveType(row.leaveType);
          setLeaveEditModal(true);
          //setToggle("chatbox");

          //   <Link  to="/executive-details">
          //         <i className="fa fa-trash"></i>
          //       </Link>
        },
      },
    },
  ];

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    // var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    // var m =
    //   date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    // var s =
    //   date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    // var am_pm = h >= 12 ? "PM" : "AM";
    // h = h > 12 ? h - 12 : h;
    date_str = month + " " + day + ", " + year;
    //   +
    //   "," +
    //   " " +
    //   h +
    //   ":" +
    //   m +
    //   ":" +
    //   s +
    //   " " +
    //   am_pm;
    return date_str;
  };

  const fromToDates = (date1, date2) => {
    if (date1 === date2) {
      return date1;
    } else {
      return date1 + " - " + date2;
    }
  };

  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const setDateValue = (label, value) => {
    var date;

    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        // loadVistedPlacesData();
        return;
      case 2:
        var today = new Date();

        // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
        setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;
      case 4:
        setShowDate(true);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  function setCustomDates(dateStrings) {
    if (dateStrings.length > 1) {
      // start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
      // end = Math.floor(new Date(dateStrings[1]).getTime() / 1000);
      setStart(Math.floor(dateStrings[0].getTime() / 1000));
      setEnd(Math.floor(dateStrings[1].getTime() / 1000) + 86400);
    }
    //loadVistedPlacesData();
  }

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        {showDate && (
          <div style={{ marginRight: "20px", float: "right" }}>
            <Flatpickr
              className="form-control d-block"
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}
        {/* <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) =>
                setVisitValue(value.label, value.value, visitTypeOptions)
              }
              defaultValue={visitTypeOptions[valIndex]}
              options={visitTypeOptions}
            />
          </div> */}
        <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) =>
              setDateValue(value.label, value.value, optionDates)
            }
            defaultValue={optionDates[valIndex]}
            options={optionDates}
          />
        </div>
        <div
          style={{
            //float: "right",
            //margin: "10px",
            marginRight: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadLeavesListData(0)}
          >
            Submit
          </Button>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px", marginTop: "-5px" }}>
          <div style={{ overflow: "auto", maxHeight: "400px" }}>
            <BootstrapTable
              keyField="panNo"
              data={leavesList}
              columns={columns}
            />
          </div>
          {leavesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" show={leaveEditModal}>
        <Modal.Header>
          <Modal.Title>Edit Leave</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setLeaveEditModal(false);
              setIsFullDay(true);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                fromDate: "",
                toDate: "",
                reason: leaveDetails.reason,
              }}
              onSubmit={handleSubmit}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="From Date"
                            name="fromDate"
                            type="date"
                          />

                          <TextField
                            isAsterisk={true}
                            label="To Date"
                            name="toDate"
                            type="date"
                          />

                          <Field name="leaveType">
                            {({ field, form }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Leave Type
                                </label>
                                <Select
                                  placeholder="Select leave type"
                                  classNamePrefix="select-box"
                                  options={leaveTypes}
                                  defaultValue={defaultLeaveType}
                                  onChange={(selectedOption) => {
                                    // setLeaveTypeValue(selectedOption);
                                    setLeaveType(selectedOption.value);
                                    // form.setFieldValue(
                                    //   "leaveType",
                                    //   selectedOption.label
                                    // );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                          {/* {errors.leaveType && (
                              <span className="error">{errors.leaveType}</span>
                            )} */}
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Reason for leave"
                              name="reason"
                              type="text"
                            />
                          </div>

                          <div
                            className="form-check form-switch form-switch-md mb-3"
                            style={{ display: "inline-flex" }}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="customSwitchsizemd"
                              style={{ fontWeight: "bold" }}
                            >
                              Full Day
                            </label>
                            <input
                              style={{ width: "40px", marginLeft: "15px" }}
                              type="checkbox"
                              checked={isFullDay}
                              className="form-check-input"
                              id="customSwitchsizemd"
                              onChange={(value) => setIsFullDay(!isFullDay)}
                            />
                          </div>

                          {!isFullDay && (
                            <div style={{ marginBottom: "20px" }}>
                              <Select
                                label="Select Type"
                                options={dayTypes}
                                onChange={(value) =>
                                  setDayTypeValue(value.label, dayTypes)
                                }
                                value={dayTypes[dayTypeIndex]}
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          {/* <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button> */}
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setLeaveEditModal(false);
                              setIsFullDay(true);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <div>
        <Modal className="fade" show={leaveDetailsModal}>
          <Modal.Header>
            <Modal.Title>Leave Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setLeaveDetailsModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
              <tr></tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Name
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.name ? leaveDetails.name : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Mobile
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.mobileNumber ? leaveDetails.mobileNumber : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Employee ID
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.employeeId ? leaveDetails.employeeId : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Email
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.email ? leaveDetails.email : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Designation
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.userModel?.companyDesignation
                    ? leaveDetails.userModel?.companyDesignation
                    : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Leave Applied Date
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.appliedTime ? leaveDetails.appliedTime : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Leave Type
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.leaveType ? leaveDetails.leaveType : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  From Date
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.fromDate ? leaveDetails.fromDate : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  To Date
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.toDate ? leaveDetails.toDate : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Reason for leave
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.reason ? leaveDetails.reason : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Rejected Reason
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.reasonForreject
                    ? leaveDetails.reasonForreject
                    : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Status
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    color:
                      leaveDetails.leaveStatus === "ACCEPTED"
                        ? "green"
                        : leaveDetails.leaveStatus === "REJECTED"
                        ? "red"
                        : "orange",
                    textAlign: "left",
                  }}
                >
                  {leaveDetails.leaveStatus ? leaveDetails.leaveStatus : "-"}
                </td>
              </tr>
              {leaveDetails.leaveStatus === "PENDING" && (
                <div>
                  {/* <Button
                      style={{
                        float: "left",
                        marginTop: "20px",
                        marginRight: "25px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="success btn-rounded"
                        onClick={() => updateLeaveRequest(leaveDetails, "ACCEPTED")}
                    >
                      Accept
                    </Button>
                    <Button
                      style={{
                        float: "left",
                        marginTop: "20px",
                        marginRight: "15px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="danger btn-rounded"
                      onClick={() => updateLeaveRequest(leaveDetails, "REJECTED")}
                      >
                      Reject
                    </Button> */}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EmployeeLeave;

// import React, { useState, useEffect } from "react";
// // import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
// import { Button, Modal } from "react-bootstrap";
// import { Formik, Form } from "formik";
// // import TextFieldDroupDown from "../commonComponents/TextFieldDroupDown";
// // import TextField from "../commonComponents/TextField"
// import * as moduleApi from "../rest/moduleApi";
// import * as Yup from "yup";
// // import BootstrapTable from "react-bootstrap-table-next";
// // import paginationFactory from "react-bootstrap-table2-paginator";
// // import VisibilityIcon from "@mui/icons-material/Visibility";
// import defaultImage from "../images/defaultImage.png";
// import "react-calendar/dist/Calendar.css";
// import Calendar from "react-calendar";
// import moment from "moment";
// import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
// // import LeavesChart from "./LeavesChart"

// function ManagerLeaveManagement() {
//   let userData = localStorage.getItem("userDetails");
//   var userDetails = JSON.parse(userData);
//   let designation = userDetails.autoFinanceUser.companyDesignation;
//   let isreportingOfficer = userDetails.autoFinanceUser.reportingOfficerId;
//   const [BasicTab, setBasicTab] = useState("0");

//   const [modal, setModal] = useState(false);
//   const [reportingModal, setReportingModal] = useState(false);
//   const [leaveTypeIndex, setLeaveTypeIndex] = useState("");
//   const [selectedLeaveType, setSelectedLeaveType] = useState("");
//   const [value, onChange] = useState(new Date());
//   const [onLoad, setOnLoad] = useState([]);

//   const [selectYear, setSelectYear] = useState();
//   let comingData = [];
//   let comingListData = [];

//   const handleShow = () => setModal(true);
//   const handleClose = () => setModal(false);

//   const handleReportingStructureShow = () => setReportingModal(true);
//   const handleReportingStructureClose = () => setReportingModal(false);

//   const toggle2 = () => {
//     handleClose();
//   };

//   const toggle = () => {
//     handleReportingStructureClose();
//   };

//   useEffect(() => {
//     // getAllHolidays();
//   }, []);

//   const leaveTypes = [
//     { label: "Leave Without Pay", value: 1 },
//     { label: "Personal Leave", value: 2 },
//     { label: "Sick Leave", value: 3 },
//     { label: "Vacation Leave", value: 4 },
//     { label: "Paternity Leave", value: 5 },
//     { label: "Maternity Leave", value: 6 },
//   ];

//   const setLeaveTypeValue = (value, leaveTypes) => {
//     for (let i = 0; i < leaveTypes.length; i++) {
//       if (leaveTypes[i].label === value) {
//         setLeaveTypeIndex(i);
//         setSelectedLeaveType(value);
//       }
//     }
//   };

//   const columns = [
//     {
//       dataField: "name",
//       text: "Name",
//       align: "center",
//       headerAlign: "center",
//       headerStyle: {
//         backgroundColor: "rgb(200,207,205)",
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//     },
//     {
//       dataField: "panNo",
//       text: "PAN Number",
//       align: "center",
//       headerAlign: "center",
//       headerStyle: {
//         backgroundColor: "rgb(200,207,205)",
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//     },
//     {
//       dataField: "aadharNo",
//       text: "Aadhar Number",
//       align: "center",
//       headerAlign: "center",
//       headerStyle: {
//         backgroundColor: "rgb(200,207,205)",
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//     },
//     {
//       dataField: "emailId",
//       text: "Email",
//       align: "center",
//       headerAlign: "center",
//       headerStyle: {
//         backgroundColor: "rgb(200,207,205)",
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//     },
//     {
//       dataField: "mobileNumber",
//       text: "Mobile Number",
//       align: "center",
//       headerAlign: "center",
//       headerStyle: {
//         backgroundColor: "rgb(200,207,205)",
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//     },
//     {
//       dataField: "onboardStatus",
//       text: "Onboard Status",
//       align: "center",
//       headerAlign: "center",
//       headerStyle: {
//         backgroundColor: "rgb(200,207,205)",
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//       style: {
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//       formatter: (cellContent, row) => {
//         if (row.status === "ACCEPTED") {
//           console.log("if", row.status);
//           return (
//             <Button
//               type="button"
//               color="info"
//               className="btn-sm btn-rounded"
//               // className="btn btn-primary btn-sm "
//             >
//               {row.onboardStatus}
//             </Button>
//           );
//         } else if (row.status === "PROCESSING") {
//           console.log("else if", row.status);
//           return (
//             <Button
//               type="button"
//               color="warning"
//               className="btn-sm btn-rounded"
//               // className="btn btn-primary btn-sm "
//             >
//               {row.onboardStatus}
//             </Button>
//           );
//         } else if (row.status === "New") {
//           console.log("else if", row.status);
//           return (
//             <Button
//               type="button"
//               color="primary"
//               className="btn-sm btn-rounded"
//               // className="btn btn-primary btn-sm "
//             >
//               {row.onboardStatus}
//             </Button>
//           );
//         } else if (row.status === "Closed") {
//           console.log(" else if", row.status);
//           return (
//             <Button
//               type="button"
//               color="success"
//               className="btn-sm btn-rounded"
//               // className="btn btn-primary btn-sm "
//             >
//               {row.onboardStatus}
//             </Button>
//           );
//         } else {
//           console.log("else", row.status);
//           return (
//             <Button
//               type="button"
//               color="secondary"
//               className="btn-sm btn-rounded"
//               // className="btn btn-primary btn-sm "
//             >
//               {row.onboardStatus}
//             </Button>
//           );
//         }
//       },
//     },
//     {
//       text: "Details",
//       key: "details",
//       dataField: "details",
//       align: "center",
//       headerAlign: "center",
//       headerStyle: {
//         backgroundColor: "rgb(200,207,205)",
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//       style: {
//         fontFamily:
//           " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
//       },
//       formatter: (cell, row) => {
//         if (row.mobileNumber === "Nsew") {
//           return <></>;
//         } else {
//           return <i className="fa fa-eye"></i>;
//         }
//       },

//       events: {
//         onClick: (e, column, columnIndex, row, rowIndex) => {
//           // rowEvents(row)
//         },
//       },
//     },
//   ];

//   const validate = Yup.object({
//     fromDate: Yup.string().required("From Date is required"),
//     toDate: Yup.string().required("To Date is required"),
//     reasonForLeave: Yup.string().required("Reason For Leave is required"),
//   });

//   const handleSubmit = () => {
//     console.log("leaveType values");
//   };

//   const getAllHolidays = () => {
//     moduleApi.getData("holidays/getholidays/", selectYear).then((response) => {
//       formatResponseData(response?.holidayWithDesc);
//     });
//   };

//   const convertDate = (date) => {
//     var listUpdatedDate;
//     var updatedDates = new Date(date * 1000);
//     var updatedMonth = updatedDates.getMonth() + 1;
//     return (listUpdatedDate =
//       updatedDates.getDate() +
//       "-" +
//       updatedMonth +
//       "-" +
//       updatedDates.getFullYear());
//   };

//   const formatResponseData = (response) => {
//     var description = "";
//     var date = "";
//     var uId = 0;
//     comingData = response;
//     console.log("list response");
//     console.log(comingData);
//     if (comingData)
//       comingData.forEach((element) => {
//         if (element.holidayWithDesc !== null) {
//           description = element.description === null ? "" : element.description;
//           date = element.date !== null ? convertDate(element.date) : "";

//           uId = element.id !== 0 ? element.id : 0;
//         } else {
//           description = "";
//           date = "";
//           uId = 0;
//         }

//         let data = {
//           description: description,
//           date: date,
//           uId: uId,
//         };
//         comingListData.push(data);
//         console.log("list data");
//         console.log(data);
//       });
//     setOnLoad(comingListData);
//   };

//   const isTileDisabled = (activeStartDate, date, view) => {
//     if (onLoad.length === 0) {
//       getAllHolidays();
//     }

//     // console.log("isTileDisabled", activeStartDate.date.getDay());

//     for (var i = 0; i < onLoad.length; i++) {
//       // console.log("************************", moment(new Date(onLoad[i].date)).format("DD/MM/YYYY"), moment(new Date(activeStartDate.date)).format("DD/MM/YYYY"), new Date("13-01-2022"));
//       console.log(
//         "***********",
//         new Date(onLoad[i].date).getTime(),
//         new Date(activeStartDate.date).getTime(),
//         new Date("13-01-2022")
//       );

//       if (
//         new Date(onLoad[i].date).getTime() ===
//         new Date(activeStartDate.date).getTime()
//       ) {
//         console.log(
//           "epoch date",
//           new Date(onLoad[i].date),
//           new Date(activeStartDate.date)
//         );
//         return true;
//       } else if (
//         activeStartDate.date.getDay() === 0 ||
//         activeStartDate.date.getDay() === 6
//       ) {
//         return false;
//       }
//     }
//     return false;
//   };

//   return (
//     <div style={{ marginTop: "20px", marginBottom: "20px" }}>
//       <Row>
//         <Col lg="8">
//         <div
//                   className="card contact-bx item-content"
//                   style={{
//                     backgroundColor: "rgb(243,240,249)",
//                     marginLeft: "150px",
//                     marginRight: "150px",
//                   }}
//                 >
//                   <div className="card-header border-0"></div>
//                   <div className="card-body user-profile">
//                     <div>
//                       <h6 className="fs-18 font-w600 my-1">Manager Details</h6>
//                     </div>

//                   </div>
//                 </div>
//           {/* <Card
//             style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", margin: "20px" }}
//           >
//             <CardTitle
//               style={{
//                 fontSize: "16px",
//                 fontWeight: "bold",
//                 marginLeft: "20px",
//                 marginTop: "10px",
//               }}
//             >
//               My Leave
//             </CardTitle>
//             <Row lg="12">
//               <Col lg="6">
//                 <CardBody>
//                   <Row>
//                     <Col>
//                       <div className="mb-3 mt-4" style={{ marginLeft: "25px" }}>
//                         <h4
//                           style={{
//                             fontSize: "13px",
//                             fontWeight: "normal",
//                             color: "#6a6a6a",
//                           }}
//                         >
//                           Total Leave :
//                         </h4>
//                       </div>
//                     </Col>
//                     <Col>
//                       <div className="mb-3 mt-4" style={{ marginLeft: "25px" }}>
//                         <h4
//                           style={{
//                             fontSize: "13px",
//                             fontWeight: "normal",
//                             fontWeight: "bold",
//                           }}
//                         >
//                           0 days
//                         </h4>
//                       </div>
//                     </Col>
//                   </Row>

//                   <Row>
//                     <Col>
//                       <div className="mb-3 mt-4" style={{ marginLeft: "25px" }}>
//                         <h4
//                           style={{
//                             fontSize: "13px",
//                             fontWeight: "normal",
//                             color: "#6a6a6a",
//                           }}
//                         >
//                           Availed :
//                         </h4>
//                       </div>
//                     </Col>
//                     <Col>
//                       <div className="mb-3 mt-4" style={{ marginLeft: "25px" }}>
//                         <h4
//                           style={{
//                             fontSize: "13px",
//                             fontWeight: "normal",
//                             fontWeight: "bold",
//                           }}
//                         >
//                           0 day
//                         </h4>
//                       </div>
//                     </Col>
//                   </Row>
//                 </CardBody>
//               </Col>
//               <Col lg="6">
//                 <div className="mb-3 mt-4">
//                   <h4
//                     style={{
//                       fontSize: "13px",
//                       fontWeight: "normal",
//                       color: "#6a6a6a",
//                     }}
//                   >
//                     Reporting To
//                   </h4>
//                   <h4
//                     className="fs-14 font-w600"
//                     style={{ fontSize: "14px", fontWeight: "bold" }}
//                   >
//                     Pranith Kumar Kosuna
//                   </h4>
//                 </div>
//                 <div className="mb-3 mt-4">
//                   <h4 className="fs-14 font-w600" style={{ fontSize: "14px" }}>
//                     LEAVE BALANCE
//                   </h4>
//                 </div>
//                 <div style={{ marginRight: "20px" }}>
//                   <div
//                     className="d-flex align-items-center justify-content-between mb-4"
//                     style={{
//                       marginTop: "5px",
//                       marginBottom: "5px",
//                       backgroundColor: "#f5f5f5",
//                       height: "20px",
//                       display: "block",
//                     }}
//                   >
//                     <div
//                       style={{
//                         backgroundColor: "rgb(228, 119, 255)",
//                         width: "5px",
//                         left: "15px",
//                         zIndex: "5",
//                         height: "20px",
//                       }}
//                     ></div>
//                     <span style={{ marginLeft: "20px" }}>
//                       Leave Without Pay
//                     </span>
//                     <span
//                       className="fs-18 font-w600"
//                       style={{ marginRight: "10px" }}
//                     >
//                       1
//                     </span>
//                   </div>
//                   <div
//                     className="d-flex align-items-center justify-content-between  mb-4"
//                     style={{
//                       marginTop: "5px",
//                       marginBottom: "5px",
//                       backgroundColor: "#f5f5f5",
//                       height: "20px",
//                     }}
//                   >
//                     <div
//                       style={{
//                         backgroundColor: "rgb(228, 119, 255)",
//                         width: "5px",
//                         left: "15px",
//                         zIndex: "5",
//                         height: "20px",
//                       }}
//                     ></div>
//                     <span style={{ marginLeft: "20px" }}>Personal Leave</span>
//                     <span
//                       className="fs-18 font-w600"
//                       style={{ marginRight: "10px" }}
//                     >
//                       2
//                     </span>
//                   </div>
//                   <div
//                     className="d-flex align-items-center justify-content-between  mb-4"
//                     style={{
//                       marginTop: "5px",
//                       marginBottom: "5px",
//                       backgroundColor: "#f5f5f5",
//                       height: "20px",
//                     }}
//                   >
//                     <div
//                       style={{
//                         backgroundColor: "rgb(228, 119, 255)",
//                         width: "5px",
//                         left: "15px",
//                         zIndex: "5",
//                         height: "20px",
//                       }}
//                     ></div>
//                     <span style={{ marginLeft: "20px" }}>Sick Leave</span>

//                     <span
//                       className="fs-18 font-w600"
//                       style={{ marginRight: "10px" }}
//                     >
//                       3
//                     </span>
//                   </div>
//                   <div
//                     className="d-flex align-items-center justify-content-between  mb-4"
//                     style={{
//                       marginTop: "5px",
//                       marginBottom: "5px",
//                       backgroundColor: "#f5f5f5",
//                       height: "20px",
//                     }}
//                   >
//                     <div
//                       style={{
//                         backgroundColor: "rgb(228, 119, 255)",
//                         width: "5px",
//                         left: "15px",
//                         zIndex: "5",
//                         height: "20px",
//                       }}
//                     ></div>
//                     <span style={{ marginLeft: "20px" }}>Vacation Leave</span>
//                     <span
//                       className="fs-18 font-w600"
//                       style={{ marginRight: "10px" }}
//                     >
//                       4
//                     </span>
//                   </div>
//                 </div>
//               </Col>
//             </Row>

//             <div>
//               <div style={{ boxSizing: "border-box" }}>
//                 <Button
//                   color="primary"
//                   backgroundColor="#fff"
//                   border="1px solid #cfcfcf"
//                   type="button"
//                   style={{
//                     marginRight: "20px",
//                     marginBottom: "20px",
//                     float: "right",
//                     display: "flex",
//                   }}
//                   onClick={handleShow}
//                 >
//                   Apply Leave
//                 </Button>
//               </div>

//               <div>
//                 <Button
//                   color="#000"
//                   type="button"
//                   style={{
//                     marginRight: "20px",
//                     marginBottom: "20px",
//                     float: "right",
//                     display: "flex",
//                     border: "1px solid #cfcfcf",
//                   }}
//                   onClick={handleReportingStructureShow}
//                 >
//                   Reporting Structure
//                 </Button>
//               </div>
//             </div>
//           </Card> */}
//         </Col>

//         {/* about images */}
//         <Col lg="4">
//           <Card
//             style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", margin: "20px" }}
//           >
//             <CardTitle
//               style={{
//                 fontSize: "16px",
//                 fontWeight: "bold",
//                 marginLeft: "20px",
//                 marginTop: "10px",
//               }}
//             >
//               Leave History
//             </CardTitle>
//             <CardBody>
//               <Col style={{ display: "flex" }}>
//                 <Col>
//                   <Row>
//                     <span style={{ color: "#6a6a6a" }}>PENDING</span>
//                   </Row>
//                   <Row>
//                     <span
//                       style={{
//                         marginLeft: "25px",
//                         color: "#db9500",
//                         textDecoration: "underline",
//                         paddingTop: "5px",
//                         fontSize: "18px",
//                         cursor: "pointer",
//                       }}
//                     >
//                       0
//                     </span>
//                   </Row>
//                 </Col>
//                 <Col>
//                   <Row>
//                     <span style={{ color: "#6a6a6a" }}>APPROVED</span>
//                   </Row>
//                   <Row>
//                     <span
//                       style={{
//                         marginLeft: "25px",
//                         color: "#1a871a",
//                         paddingTop: "5px",
//                         fontSize: "18px",
//                         cursor: "pointer",
//                       }}
//                     >
//                       0
//                     </span>
//                   </Row>
//                 </Col>
//                 <Col>
//                   <Row>
//                     <span style={{ color: "#6a6a6a" }}>REJECTED</span>
//                   </Row>
//                   <Row>
//                     <span
//                       style={{
//                         marginLeft: "25px",
//                         color: "#d40f0f",
//                         paddingTop: "5px",
//                         fontSize: "18px",
//                         cursor: "pointer",
//                       }}
//                     >
//                       0
//                     </span>
//                   </Row>
//                 </Col>
//               </Col>
//             </CardBody>
//           </Card>

//           <Card
//             style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", margin: "20px" }}
//           >
//             <CardTitle
//               style={{
//                 fontSize: "16px",
//                 fontWeight: "bold",
//                 marginLeft: "20px",
//                 marginTop: "10px",
//               }}
//             >
//               Leave Calendar
//             </CardTitle>

//             <CardBody>
//               <div>
//                 <Calendar
//                   onChange={onChange}
//                   value={value}
//                   // tileDisabled={({ activeStartDate, date, view }) => {date.getDay() === 0 || date.getDay() === 6}}
//                   tileDisabled={(activeStartDate, date, view) =>
//                     isTileDisabled(activeStartDate, date, view)
//                   }
//                   tileClassName={({ activeStartDate, date, view }) => {
//                     if (view === "month") {
//                       // console.log(activeStartDate, date, view);
//                       return "Hello";
//                     }
//                   }}
//                 />
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>

//     </div>
//   );
// }

// export default ManagerLeaveManagement;
