import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import Select from "react-select";
import Spinner from 'react-bootstrap/Spinner';
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { Field, Form, Formik } from "formik";
import { Col,Row } from "react-bootstrap";
// import defaultImage from "../images/defaultImage.png";
import swal from "sweetalert";
import TextField from "../CommonComponents/TextField";
import * as Yup from "yup";
import { SRLWrapper } from "simple-react-lightbox";
import defaultImage from "../images/defaultImage.png";
import ReactGA from "react-ga";


function EmployeeExpense({ status }) {
  const [expenseList, setExpenseList] = useState([]);
  const [expenseDetails, setExpenseDetails] = useState([]);
  const [expenseDetailsModal, setExpenseDetailsModal] = useState(false);
  const [expenseEditModal, setExpenseEditModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  const [selectedExpenseType, setSelectedExpenseType] = useState("");
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [totalElements, setTotalElements] = useState(1);
  const [currPage] = useState(0);
  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };
  var valIndex = 0;
  var isFilePicked = "";
  const expenseTypeOptions = [
    { label: "Travel", value: "Travel" },
    { label: "Food", value: "Food" },
    { label: "Fuel", value: "Fuel" },
    { label: "Others", value: "Others" },
    // { label: "Last 30 Days", value: 3 },
    // { label: "Custom", value: 4 },
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("userDetails"));
    setloggedInUser(userData);
    setDateValue("Last 7 Days", 1);
    loadExpenseData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview('Employee Expense');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editExpense = (values) => {
     const payLoad={
        comments: values.comments,
        expenseCost: values.expenseAmount,
        expenseStatus: "PENDING",
        expenseType: selectedExpenseType?selectedExpenseType:expenseDetails.expenseType,
        id: expenseDetails.id,
        images: [currentFile],
     }
     moduleApi
     .postData(
       moduleApi.withPage(apiCall.updateExpenseRequest, pageNo, 10),
       payLoad
     )
     .then((response) => {
      console.log("response",response);
      setExpenseEditModal(false);
      setCurrentImage(null);
      if(response){
      swal("Expense Updated Successfully", {
        icon: "success",
      });
    }
      loadExpenseData(0);
      
     })
  }

  const handleChange1 = (e) => {
    let files = e.target.files;
    console.log("files", e.target.files);
    if (
      (files[0].type === "image/jpg") |
      (files[0].type === "image/jpeg") |
      (files[0].type === "image/png") |
      (files[0].type === "image/JPEG") |
      (files[0].type === "image/JPG") |
      (files[0].type === "image/PNG")
    ) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setCurrentImage(reader.result);
        });
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
          let fileInfo = {
            base64: reader.result,
          };
          var basedata = fileInfo.base64.split(",");
          isFilePicked = basedata[1];

          setCurrentFile(isFilePicked);
        }; // reader.onload
      } // for
    } else {
      e.target.files = null;
      swal("Please Select image Format Only", {
        icon: "error",
        dangerMode: true,
      });
    }
  };
  const pageNo=0;
  const loadExpenseData = (pageNo) => {
    setIsLoading(true);
    var ExpenseListBody = {
      //  branchId : branchId,
      endtime: end,
      leaveStatus: status ? status : undefined,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      // reportingOfficerId:
      //   loggedInUser.autoFinanceUser.roleName === "MANAGER"
      //     ? loggedInUser.autoFinanceUser.userId
      //     : "",
      //  reportingOfficerId: userId,
      starttime: start,
      // userId: userId,
      userId:loggedInUser.autoFinanceUser.userId
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getExpenseList, pageNo, 10),
        ExpenseListBody
      )
      .then((response) => {
        var listData = [];

        response.content.forEach((element) => {
          let data = {
            branchId: element.branchId,
            comments: element.comments?element.comments:'-',
            expenseCost: element.expenseCost?element.expenseCost:'-',
            expenseStatus: element.expenseStatus,
            expenseType: element.expenseType?element.expenseType:'-',
            expensesAddedTime: element.expensesAddedTime?convertEpochTimeToDate(
              element.expensesAddedTime
            ):'-',
            id: element.id,
            reasonForReject: element.reasonForReject?element.reasonForReject:'-',
            images: element.images,
            imagesCount: element.imagesCount,
            lastUpdatedTime: element.lastUpdatedTime,
            organizationId: element.organizationId,
            userId: element.userId,
            userModel: element.userModel,

            addedByUserId: element.userModel.addedByUserId,
            companyDesignation: element.userModel.companyDesignation,
            email: element.userModel.email,
            employeeId: element.userModel.employeeId,
            image: element.userModel.image,
            mobileNumber: element.userModel.mobileNumber,
            name: element.userModel.name,
            passwordUpdated: element.userModel.passwordUpdated,
            reportingOfficerId: element.userModel.reportingOfficerId,
            reportingOfficerIds: element.userModel.reportingOfficerIds,
            roleName: element.userModel.roleName,
            status: element.userModel.status,
            subOrdinateIds: element.userModel.subOrdinateIds,
            subOrdinateManagersIds: element.userModel.subOrdinateManagersIds,
            trackingEnabled: element.userModel.trackingEnabled,
            adminApproved: element.adminApproved,
            managerApproved: element.managerApproved,

          };
          listData.push(data);
        });
        setExpenseList(listData);
        setTotalElements(response.totalElements)
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    loadExpenseData(selectedPage - 1);
  };

  const columns = [

    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },


    {
      text: "Employee ID",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Expense Type",
      dataField: "expenseType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Date&Time",
      dataField: "expensesAddedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    
    {
      text: "Expense",
      dataField: "expenseCost",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Manager</span>
          <br></br>
          <span>Approval</span>
        </div>
      ),
      dataField: "expenseStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.managerApproved === true) {
          return (
            <span className="badge badge-success">
              Approved
              <span className="ms-1 fa fa-check" />
            </span>
          );
        } else if (row.expenseStatus === "REJECTED") {
          return (
            <span className="badge badge-danger">
              Rejected
              <span className="ms-1 fa fa-ban" />
            </span>
          );
        } else if (row.managerApproved === false) {
          return (
            <span className="badge badge-warning">
              Pending
              <span className="ms-1 fas fa-stream" />
            </span>
          );
        }
      },
    },

    {
      text: (
        <div>
          <span>Admin</span>
          <br></br>
          <span>Approval</span>
        </div>
      ),
      dataField: "expenseStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.expenseStatus === "ACCEPTED") {
          return (
            <span className="badge badge-success">
              Approved
              <span className="ms-1 fa fa-check" />
            </span>
          );
        } else if (row.expenseStatus === "PENDING") {
          return (
            <span className="badge badge-warning">
              Pending
              <span className="ms-1 fas fa-stream" />
            </span>
          );
        } else if (row.expenseStatus === "REJECTED") {
          return (
            <span className="badge badge-danger">
              Rejected
              <span className="ms-1 fa fa-ban" />
            </span>
          );
        }
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // localStorage.setItem('executiveVisitedDetails', JSON.stringify(row))
          //setVisitedPlacesDetailsList(JSON.parse(localStorage.getItem('executiveVisitedDetails')));
          setExpenseDetails(row);
          setExpenseDetailsModal(true);
          //setToggle("chatbox");

          //   <Link  to="/executive-details">
          //         <i className="fa fa-trash"></i>
          //       </Link>
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.expenseStatus !=="PENDING") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // localStorage.setItem('executiveVisitedDetails', JSON.stringify(row))
          //setVisitedPlacesDetailsList(JSON.parse(localStorage.getItem('executiveVisitedDetails')));
          setExpenseDetails(row);
          setExpenseEditModal(true);
          //setToggle("chatbox");

          //   <Link  to="/executive-details">
          //         <i className="fa fa-trash"></i>
          //       </Link>
        },
      },
    },
  ];

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };


  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const setDateValue = (label, value) => {
    var date;

    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        // loadVistedPlacesData();
        return;
      case 2:
        var today = new Date();

        // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
        setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;
      case 4:
        setShowDate(true);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  function setCustomDates(dateStrings) {
    if (dateStrings.length > 1) {
      // start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
      // end = Math.floor(new Date(dateStrings[1]).getTime() / 1000);
      setStart(Math.floor(dateStrings[0].getTime() / 1000));
      setEnd(Math.floor(dateStrings[1].getTime() / 1000) + 86400);
    }
    //loadVistedPlacesData();
  }
  const amountRegex = /^\d+(\.\d{1,2})?$/;
  const validate = Yup.object({
    expenseAmount: Yup.string()
      .matches(amountRegex, "Expense Amount is invalid")
      .required("Expense Amount is required"),
    comments: Yup.string().required("Remarks is required"),
  });
  const defaultexpenseType={label:expenseDetails.expenseType, value:expenseDetails.expenseType}

  return (
    <div >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        {showDate && (
          <div style={{ marginRight: "20px", float: "right" }}>
            <Flatpickr
              className="form-control d-block"
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}
        {/* <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) =>
                setVisitValue(value.label, value.value, visitTypeOptions)
              }
              defaultValue={visitTypeOptions[valIndex]}
              options={visitTypeOptions}
            />
          </div> */}
        <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) =>
              setDateValue(value.label, value.value, optionDates)
            }
            defaultValue={optionDates[valIndex]}
            options={optionDates}
          />
        </div>
        <div
          style={{
            //float: "right",
            //margin: "10px",
            marginRight: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadExpenseData(0)}
          >
            Submit
          </Button>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px", marginTop: "-5px" }}>
          <div style={{overflow:'auto', maxHeight:'400px'}}>
          <BootstrapTable
            keyField="panNo"
            data={expenseList}
            columns={columns}
          />
          </div>
          {expenseList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "left" }}>
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <div>
        <Modal className="fade" show={expenseEditModal}>
          <Modal.Header>
            <Modal.Title>Edit Expense</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setExpenseEditModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
          <div style={{ margin: "10px" }}>
              <Formik
                initialValues={{
                  expenseAmount: expenseDetails.expenseCost,
                  expenseType: expenseDetails.expenseType,
                  comments: expenseDetails.comments,
                }}
                onSubmit={editExpense}
                validationSchema={validate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div>
                            <Field>
                              {({ field, form }) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={
                                      currentImage ? currentImage : expenseDetails.images
                                    }
                                    // defaultValue={expenseList.images}
                                    className="img-fluid rounded-circle"
                                    alt="profile"
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "50px",
                                      height: "100px",
                                      width: "100px",
                                      alignSelf: "center",
                                    }}
                                  />
                                  <input
                                    style={{
                                      marginLeft: "150px",
                                      marginBottom: "15px",
                                      marginTop: "10px",
                                    }}
                                    type="file"
                                    accept="image/*"
                                    // multiple
                                    onChange={(e) => handleChange1(e)}
                                  />
                                  <label
                                    className="control-label"
                                    style={{
                                      fontWeight: "bold",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Expense Type
                                  </label>
                                  <div style={{ marginBottom: "10px" }}>
                                    <Select
                                      placeholder="Select Expense Type"
                                      classNamePrefix="select-box"
                                      options={expenseTypeOptions}
                                       defaultValue={defaultexpenseType}
                                      onChange={(value) => {
                                        setSelectedExpenseType(value.value);
                                      }}
                                    />
                                  </div>
                                  <TextField
                                    isAsterisk={true}
                                    label="Expense Amount"
                                    name="expenseAmount"
                                    type="text"
                                  />
                                  <TextField
                                    isAsterisk={true}
                                    label="Remarks"
                                    name="comments"
                                    type="text"
                                  />
                                </div>
                              )}
                            </Field>
                          </div>
                          <div>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2"
                              type="submit"
                            >
                              Submit
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2 float-right"
                              variant="danger light"
                              onClick={() => {
                                setCurrentImage(null);
                                setExpenseEditModal(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
            </Modal.Body>
            </Modal>
            </div>

      <div>
        <Modal className="fade" show={expenseDetailsModal}>
          <Modal.Header>
            <Modal.Title>Expense Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setExpenseDetailsModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
              <tr></tr>
              <div style={{width: "640px",marginLeft:"100px",marginBottom:"20px"}}>
              <SRLWrapper options={options1}>
                <img
                  style={{
                    height: "100px",
                    width:"100px",
                    marginRight: "100px",
                    cursor: "pointer",
                  }}
                  src={
                    expenseDetails.images
                      ?expenseDetails.images
                      : defaultImage
                  }
                  alt=""
                  className="img-fluid rounded-circle"
                />
              </SRLWrapper>
              </div>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Name
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.name ? expenseDetails.name : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Mobile
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.mobileNumber ? expenseDetails.mobileNumber : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Employee ID
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.employeeId ? expenseDetails.employeeId : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Email
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.email ? expenseDetails.email : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Designation
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.userModel?.companyDesignation
                    ? expenseDetails.userModel?.companyDesignation
                    : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Expense Type
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.expenseType ? expenseDetails.expenseType : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                 Date&Time
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.expensesAddedTime ? expenseDetails.expensesAddedTime : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Remarks
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.comments ? expenseDetails.comments : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Rejected Reason
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.reasonForReject ? expenseDetails.reasonForReject : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Expense
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.expenseCost ? expenseDetails.expenseCost : "-"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Status
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    color:
                    expenseDetails.expenseStatus === "ACCEPTED"
                        ? "green"
                        : expenseDetails.expenseStatus === "REJECTED"
                        ? "red"
                        : "orange",
                    textAlign: "left",
                  }}
                >
                  {expenseDetails.expenseStatus ? expenseDetails.expenseStatus : "-"}
                </td>
              </tr>
              {expenseDetails.expenseStatus === "PENDING" && (
                <div>
                  {/* <Button
                      style={{
                        float: "left",
                        marginTop: "20px",
                        marginRight: "25px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="success btn-rounded"
                        onClick={() => updateLeaveRequest(leaveDetails, "ACCEPTED")}
                    >
                      Accept
                    </Button>
                    <Button
                      style={{
                        float: "left",
                        marginTop: "20px",
                        marginRight: "15px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="danger btn-rounded"
                      onClick={() => updateLeaveRequest(leaveDetails, "REJECTED")}
                      >
                      Reject
                    </Button> */}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EmployeeExpense;