import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const PtpList = () => {
  const [loggedInUser, setloggedInUser] = useState([]);
  const [visitObj, setVisitObj] = useState({});
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [totalElements, setTotalElements] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);

    let path = window.location.pathname;

    path = path.split("/");

    var ptpStatus=path[path.length - 1];
    var end = path[path.length - 2];
    var start = path[path.length - 3];
    var userId = path[path.length - 4];

    var obj = {
      userId: userId,
      start: start,
      end: end,
      ptpStatus:ptpStatus
    };

    setVisitObj(obj);

    loadVistedPlacesData(
      userId,
      start,
      end,
      userData,
      0,
      ptpStatus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Mobile No",
      dataField:
        visitObj.visitType === "Sales"
          ? "salesContactNumber"
          : "collectionsContactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text:"Loan Number",
      dataField: "loanNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Visited By",
      dataField: "name",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visited Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Under Manager",
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (row.collectionVisit?.status === "Partially Paid") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Customer Not Available") {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "") {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.status}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (
            row.collectionVisit?.subStatus === "Cash" ||
            row.collectionVisit?.subStatus === "Bank Transfer" ||
            row.collectionVisit?.subStatus === "Already Settled" ||
            row.collectionVisit?.subStatus === "UPI"
          ) {
            return (
              <span
                className="badge badge-rounded badge-success"
                // style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "Customer Not Available"
          ) {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "" ||
            row.collectionVisit?.subStatus === null
          ) {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.subStatus}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-success shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedRow(row);
          setToggle(true);
        },
      },
    },
  ];


  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const loadVistedPlacesData = (
    userId,
    start,
    end,
    userDetails,
    pageNo,
    ptpStatus
  ) => {

    var status=ptpStatus.replace(/-/g, '');

    var visitedPlacesBody = {
      endTime: end,
      userId: userId,
      startTime: start,
      organizationId: userDetails.autoFinanceUser.organizationId,
      searchkey:"",
      status:status
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeePtpVisits, pageNo, 10),
        visitedPlacesBody
      )
      .then((response) => {
        var listData = [];
        setCurrPage(pageNo);
          response.content.forEach((element) => {
            let data = {
              id: element.id,
              userId: element.userId,
              userModel: element.userModel,
              aggDate: element.aggDate,
              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,
              customerLocationChanged: element.customerLocationChanged,
              organizationId: element.organisationId,
              status: element.status,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: convertEpochTimeToDate(element.dateTime),
              timeSpent: element.timeSpent,
              timeDiffOfPrevVisit: element.timeDiffOfPrevVisit,
              agDate: element.agDate,
              dueDate: element.dueDate,
              vehicleNumber: element.vehicleNumber,
              reportingOfficerId: element.reportingOfficerId,
              reportingOfficerName: element.reportingOfficerName,
              salesVisit: element.salesVisit,
              salesContactNumber: element.salesVisit?.contactNumber,
              collectionVisit: element.collectionVisit,
              loanNumber: element.collectionVisit?.loanNumber,
              collectionsContactNumber: element.collectionVisit?.contactNumber,
              customerName: element.collectionVisit
                ? element.collectionVisit.customerName
                : element.salesVisit.customerName,
            };
            listData.push(data);
          });
          setData(listData);
        setTotalElements(response.totalElements);
      });
  };
  const onPageChange = (selectedPage) => {
    loadVistedPlacesData(
      visitObj.userId,
      visitObj.start,
      visitObj.end,
      visitObj.visitType,
      loggedInUser,
      selectedPage - 1,
      visitObj.ptpStatus,
      visitObj.reportingOfficerId,
      visitObj.status
    );
  };

  const reLoad = (pageNo) => {
    loadVistedPlacesData(
      visitObj.userId,
      visitObj.start,
      visitObj.end,
      visitObj.visitType,
      loggedInUser,
      pageNo,
      visitObj.ptpStatus,
      visitObj.reportingOfficerId,
      visitObj.status
    );
  };

  return (
    <Card>
      <Card.Body>
       
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable keyField="userId" data={data} columns={columns} />
          </div>
    
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PaginationComponent
            totalItems={totalElements}
            pageSize={10}
            onSelect={onPageChange}
          />
        </div>
      </Card.Body>
      {toggle && (
        <VisitedPlaceDrawer
          visitedPlacesDetailsList={selectedRow}
          closeDrawer={() => setToggle(false)}
          reLoad={() => reLoad(currPage)}
        />
      )}
    </Card>
  );
};

export default PtpList;
