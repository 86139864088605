import React, { useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import {Nav, Tab } from "react-bootstrap";
import {
  getTokenInLocalStorage,
  hasAccess,
  isAdmin,
} from "../services/AuthService";
import { apiCall } from "../rest/restApi";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
// import { toaster } from "../rest/apiUtils";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import ReactGA from "react-ga";
import swal from "sweetalert";
import { EpochToDate } from "../CommonComponents/Helper";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import EmployeeLedger from "./EmployeeLedger";
import SearchBar from "../CommonComponents/SearchBar";
import Select from "react-select";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";

const CashDeposition = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [basicModal3, setBasicModal3] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [totalElements2, setTotalElements2] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [currPage2, setCurrPage2] = useState(0);
  const [toggle, setToggle] = useState("");
  const [rejectionReason, setRejectionReason] = useState(null);
  const [cashDepositionList, setCashDepositionList] = useState([]);
  const [cashDepositVisits, setCashDepositVisits] = useState([]);
  const [cashDepositionDetails, setCashDepositionDetails] = useState(null);
  const [cashDepositVisitDetails, setCashDepositVisitDetails] = useState([]);
  const [slctdCashDepositId, setSlctdCashDepositId] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [cashDepositStatus, setcashDepositStatus] = useState("");
  const [cashDepositDownloadList, setCashDepositDownloadList] = useState(null);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview("Cash Deposition");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCashDepositionList(start, end, 0,searchKey,cashDepositStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCashDepositionList = (start, end, pageNo,key,status) => {
    let listData = [];
    setIsLoading(true);
    let payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      searchKey:key,
      cashDepositStatus:status,
      startTime: start,
      endTime: end,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCashDepositions, pageNo, 10),
        payload
      )
      .then((response) => {
        response.content.forEach((element) => {
          let data = {
            id: element.id,
            organizationId: element.organizationId,
            userId: element.userId,
            userModel: element.UserModel,
            visitIds: element.visitIds,
            cashDepositStatus: element.cashDepositStatus,
            empName: element?.userModel.name ? element?.userModel.name : "-",
            empId: element?.userModel.employeeId
              ? element?.userModel.employeeId
              : "-",
            bankName: element.bankName ? element.bankName : "-",
            reasonForRejection: element.reasonForRejection
              ? element.reasonForRejection
              : "-",
            bankCode: element.bankCode ? element.bankCode : "-",
            utrNumber: element.utrNumber ? element.utrNumber : "-",
            numberOfCustomers: element.numberOfCustomers
              ? element.numberOfCustomers
              : "-",
            amountDeposited: element.amountDeposited
              ? element.amountDeposited
              : "-",
            mappedWithVisits: element.mappedWithVisits
              ? element.mappedWithVisits
              : "-",
            depositionId: element.depositionId ? element.depositionId : "-",
            dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
            images: element.images,
            imagesCount: element.images,
          };
          listData.push(data);
        });
        setCashDepositionList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

 const cashDepositsDownloadData=()=>{

  setIsLoading(true);
  var payload = {
    organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      searchKey:searchKey,
      cashDepositStatus:cashDepositStatus,
      startTime: start,
      endTime: end,
  };
  moduleApi
    .postData(
      moduleApi.withPage(apiCall.getCashDepositions, 0, 10),
      payload
    )
    .then((response) => {
      if (response) {
        if (response.totalElements > 5000) {
          setIsLoading(false);
          return swal({
            title: "Download Report",
            text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willContinue) => {
            if (willContinue) {
              setIsLoading(true);
              getCashDepositsFullResponseData(
                Math.floor(response.totalElements / 100)
              );
            }
          });
        }

        getCashDepositsFullResponseData(Math.floor(response.totalElements / 100));
      } else {
        swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });

 }

 async function getCashDepositsFullResponseData(size) {
  var tempList = [];
  var cashDepositListData = [];
  for (let i = 0; i <= size; i++) {
    var payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      searchKey:searchKey,
      cashDepositStatus:cashDepositStatus,
      startTime: start,
      endTime: end,
    };
    await moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCashDepositions, i, 100),
        payload
      )
      .then((response) => {
        tempList.push(...response.content);
      });
  }

  console.log("templist", tempList);

  tempList?.forEach((element) => {
    let data = {
      id: element.id,
      organizationId: element.organizationId,
      userId: element.userId,
      userModel: element.UserModel,
      visitIds: element.visitIds,
      cashDepositStatus: element.cashDepositStatus,
      empName: element?.userModel.name ? element?.userModel.name : "-",
      empId: element?.userModel.employeeId
        ? element?.userModel.employeeId
        : "-",
      bankName: element.bankName ? element.bankName : "-",
      reasonForRejection: element.reasonForRejection
        ? element.reasonForRejection
        : "-",
      bankCode: element.bankCode ? element.bankCode : "-",
      utrNumber: element.utrNumber ? element.utrNumber : "-",
      numberOfCustomers: element.numberOfCustomers
        ? element.numberOfCustomers
        : "-",
      amountDeposited: element.amountDeposited
        ? element.amountDeposited
        : "-",
      mappedWithVisits: element.mappedWithVisits
        ? element.mappedWithVisits
        : "-",
      depositionId: element.depositionId ? element.depositionId : "-",
      dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
      images: element.images,
      imagesCount: element.images,
    };
    cashDepositListData.push(data);
  });
  setCashDepositDownloadList(cashDepositListData);
}


useEffect(() => {
  cashDepositDownloadList !== null && handleExport();
  setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [cashDepositDownloadList]);

const handleExport = () => {
  let cashDepositTable = [
    {
      A: "Emp Id",
      B: "Emp Name",
      C: "Bank Name",
      D: "Bank Code",
      E: "UTR No",
      F: "Amount Deposited",
      G: "Status",
      H: "No Of Cust Receipts",
    },
  ];

  cashDepositDownloadList?.forEach((row) => {

    cashDepositTable.push({
      A: row.empId,
      B: row.empName,
      C: row.bankName,
      D: row.bankCode,
      E: row.utrNumber,
      F: row.amountDeposited,
      G: row.cashDepositStatus,
      H: row.numberOfCustomers,
    });
  });

  const finalData = [...cashDepositTable];

  console.log("finaldata", finalData);

  //create a new workbook
  const wb = XLSX.utils.book_new();

  const sheet = XLSX.utils.json_to_sheet(finalData, {
    skipHeader: true,
  });

  console.log("sheet", sheet);

  XLSX.utils.book_append_sheet(wb, sheet, "Cash Deposits");

  XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

  XLSX.writeFile(
    wb,
    "Cash_Deposits_" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx"
  );
  ReactGA.event({
    category: "Employee Ledger List Downlaod",
    action:
      userLoginDetails.autoFinanceOrganization.orgshortcode +
      "_Cash_Deposits_" +
      helper.EpochToDate(Math.floor(new Date() / 1000)) +
      ".xlsx",
  });
};

  const searchKeyword = (key) => {
    setSearchKey(key);
    getCashDepositionList(
      start,
      end,
      0,
    key,
    cashDepositStatus

    );
  };

  const setCashDepositStatusValue = (value) => {
    setcashDepositStatus(value);
    getCashDepositionList(
     start,
     end,
     0,
     searchKey,
     value
    );
  };

  const cashDepositStatusOptions = [
    { label: "All", value: "" },
    { label: "Mapped", value: "MAPPED" },
    { label: "Deposited", value: "DEPOSITED" },
    { label: "Rejected", value: "REJECTED" },
  ];

  const Columns = [
    {
      text: "Emp Id",
      dataField: "empId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Bank Name",
      dataField: "bankName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Bank Code",
      dataField: "bankCode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "UTR No",
      dataField: "utrNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Amount</span>
          <br></br>
          <span>Deposited</span>
        </div>
      ),
      //   text: "Amount Deposited",
      dataField: "amountDeposited",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "cashDepositStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.cashDepositStatus === "DEPOSITED") {
          return <span className="badge badge-success">Deposited</span>;
        } else if (row.cashDepositStatus === "MAPPED") {
          return (
            <span className="badge badge-warning" style={{ color: "black" }}>
              Mapped
            </span>
          );
        } else if (row.cashDepositStatus === "REJECTED") {
          return <span className="badge badge-danger">Rejected</span>;
        } else if (row.cashDepositStatus === "UNMAPPED") {
          return <span className="badge badge-danger">Unmapped</span>;
        } else {
          return "-";
        }
      },
    },
    // {
    //   text: "Is Mapped",
    //   dataField: "mappedWithVisits",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },

    // },
    {
      //   text: " Receipts",
      text: (
        <div>
          <span>No Of Cust</span>
          <br></br>
          <span>Receipts</span>
        </div>
      ),

      dataField: "numberOfCustomers",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          //  getCashDepositVisits(row.cashDepositedId, 0)
          setCashDepositionDetails(row);
          setBasicModal2(true);
        },
      },
    },

    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (
          row.cashDepositStatus === "MAPPED" ||
          row.cashDepositStatus === "DEPOSITED"
        ) {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fas fa-chevron-right"></i>
              </Link>
            </div>
          );
        } else {
          return <></>;
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          getCashDepositVisits(row.id, 0);
          setSlctdCashDepositId(row.id);
          // setBasicModal(true);
        },
      },
    },
  ];

  const visitColumns = [
    {
      text: "Executive",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Type",
    //   dataField: "visitType",
    //   width: 130,
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     fontWeight: "bold",
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile No</span>
        </div>
      ),
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Loan No",
      dataField: "loanNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Amount",
      dataField: "amountPaid",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visited Date",
      dataField: "dateTime",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Visit Under</span>
          <br></br>
          <span>Manager</span>
        </div>
      ),
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (row.collectionVisit?.status === "Partially Paid") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Customer Not Available") {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "") {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.status}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setCashDepositVisitDetails(row);
          setBasicModal(false);
          setToggle("chatbox");
        },
      },
    },
  ];

  const getCashDepositVisits = (id, pageNo) => {
    let listData = [];
    setIsLoading2(true);
    var payload = {
      cashDepositedId: id,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCashDepositVisits, pageNo, 10),
        payload
      )
      .then((response) => {
        console.log("cashVisitsRespopnse", response);
        response?.content?.forEach((element) => {
          let data = {
              id:element.id,
              userId: element.userId,
              agDate: element.agDate,
              aggDate: element.aggDate,
              dueDate: element.dueDate,
              vehicleNumber: element.vehicleNumber,
              userModel: element.userModel,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              companyDesignation: element.userModel.companyDesignation,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,

              organizationId: element.organisationId,
              status: element.status,
              subStatus: element.subStatus,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              customerLatitude:element.customerLatitude,
              customerLongitude:element.customerLongitude,
              address: element.address,
              customerLocationChanged: element.customerLocationChanged,
              visitType: element.visitType,
              image: element.image,
              visitNotDoneAtCustLocation: element.visitNotDoneAtCustLocation,
              dateTime: helper.EpochToDate(element.dateTime),
              timeSpent: element.timeSpent,
              timeDiffOfPrevVisit: element.timeDiffOfPrevVisit,
              reportingOfficerId: element.reportingOfficerId,
              reportingOfficerName: element.reportingOfficerName,
              salesVisit: element.salesVisit,
              distFromCustAddr: getDistTravelledInKM(element.distFromCustAddr),
              contactNumber: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.contactNumber
                  : "--"
                : element.salesVisit
                ? element.salesVisit.contactNumber
                : "--",

              collectionVisit: element.collectionVisit,
              receiptNumber: element.collectionVisit?.receiptNumber
                ? element.collectionVisit?.receiptNumber
                : null,
              loanNumber: element.collectionVisit
                ? element.collectionVisit.loanNumber
                : "-",
              collectionsContactNumber: element.collectionVisit?.contactNumber,
              invenotryScan: element.collectionVisit?.invenotryScan,
              repossedImages: element.collectionVisit?.repossedImages,
              repossedLocation: element.collectionVisit?.repossedLocation,
              customerName: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.customerName
                : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.customerName
                  : "--"
                : element.salesVisit
                ? element.salesVisit.customerName
                : "--",
              visitDoneFromVisitManagement:element.visitDoneFromVisitManagement,
              fiAllocationId:element.fiAllocationId,
              fieldInvestigationVisit: element.fieldInvestigationVisit,
              fiAllocation:element.fiAllocation,
              fiCompleteDetail:element.fiCompleteDetail,
          };
          listData.push(data);
        });
        setCashDepositVisits(listData);
        setTotalElements2(response?.totalElements);
        setBasicModal(true);
        setIsLoading2(false);
      });
  };

  
  const getDistTravelledInKM = (distFromCustAddr) => {
    if (distFromCustAddr !== 0) {
      var km = distFromCustAddr / 1000.0;
      return km.toFixed(0) + " km";
    } else {
      return "-";
    }
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    getCashDepositionList(start, end, selectedPage - 1,searchKey,cashDepositStatus);
  };

  const onPageChange2 = (selectedPage) => {
    setCurrPage2(selectedPage - 1);
    getCashDepositVisits(slctdCashDepositId, selectedPage - 1);
  };

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStart(start);
    setEnd(end);
    getCashDepositionList(start, end, 0,searchKey,cashDepositStatus);
  };

  const options1 = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const updateCashDeposit = (cashDepositionDetails, status) => {
    console.log("status", status);
    if (status === "REJECTED" && rejectionReason === null) {
      return swal("Please Enter Rejection Reason", {
        icon: "error",
        dangerMode: true,
      });
    }

    var body = {
      cashDepositStatus: status,
      id: cashDepositionDetails.id,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      reasonForRejection: rejectionReason,
      approvedByuserId:userLoginDetails?.autoFinanceUser?.userId,
    };

    moduleApi.postData(apiCall.updateCashDeposit, body).then((response) => {
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.Message === "Cash Deposit Updated Successfully") {
          setBasicModal2(false);
          setBasicModal3(false);
          getCashDepositionList(start, end, 0,searchKey,cashDepositStatus);
          swal("Cash Deposition Status Updated Successfully", {
            icon: "success",
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  return (
    <div className="card">
         <div>
        <div style={{ margin: "20px" }}>
          <div className="custom-tab-1">
            <Tab.Container
              defaultActiveKey="cashDepositions"
            >
              <Nav as="ul" className="nav-tabs-custom nav-justified">
                <Nav.Item as="li" key="0">
                  <Nav.Link eventKey="cashDepositions">
                    <b> Cash Depositions </b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key="1">
              <Nav.Link eventKey="employeeLedger">
                <b> Employee Ledger </b>
              </Nav.Link>
              </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="cashDepositions" key="0" mountOnEnter>
                <div style={{ marginTop: "10px" }}>
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
            {/* <div
              style={{
                float: "left",
                marginLeft:"20px",
                display: "flex",
              }}
            >
              <SearchBar
                placeholder="Search by Emp Id/Emp Name/Bank Name/Bank Code/UTR No"
                searchKeyword={searchKeyword}
              />
            </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              marginBottom: "10px",
              marginRight: "20px",
            }}
          >
                 <div
                  style={{
                    float: "right",
                    width: "170px",
                    height: "38px",
                    marginRight: "10px",
                    borderRadius: "3px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    id="cashDepositStatusFilter"
                    isSearchable={false}
                    onChange={(value) => {
                      setCashDepositStatusValue(value.value);
                    }}
                    defaultValue={{ label: "All", value: "" }}
                    options={cashDepositStatusOptions}
                  />
                </div>

            <Flatpickr
              className="form-control d-block"
              style={{
                float: "right",
                width: "170px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value);
              }}
              options={{
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
              <Button
                    style={{
                      float:"right",
                      marginRight: "5px",
                      marginLeft: "7px",
                      marginBottom: "5px",
                      fontFamily:
                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                    }}
                    className="me-2"
                    variant="success btn-rounded"
                    onClick={() => cashDepositsDownloadData()}
                  >
                    <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                  </Button>
          </div> */}
          
          <div
  style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    // marginBottom: "5px",
    marginLeft: "20px",
    marginRight: "20px",
  }}
>
  <div
    style={{
      float: "left",
      marginRight: "5px",
      display: "flex",
      marginTop: "5px",
    }}
  >
    <SearchBar
      placeholder="Search by Emp Id/Emp Name/Bank Name/Bank Code/UTR No"
      searchKeyword={searchKeyword}
    />
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginRight: "5px",
      marginBottom:"15px",
    }}
  >
    <label style={{ fontWeight: "bold" }}>Status</label>
    <div
      style={{
        width: "130px",
        height: "38px",
        marginLeft: "5px",
        marginRight: "5px",
        borderRadius: "3px",
        borderWidth: "1px",
        boxShadow: "1px 1px 2px #797979",
      }}
    >
      <Select
        id="statusFilter"
        isSearchable={false}
        onChange={(value) => {
          setCashDepositStatusValue(value.value);
        }}
        defaultValue={cashDepositStatusOptions[0]}
        options={cashDepositStatusOptions}
      />
    </div>
  </div>
  <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            marginRight: "5px",
            marginBottom:"15px",
          }}
        >
          <label style={{ fontWeight: "bold"}}>
            Select Month
          </label>
    <Flatpickr
      className="form-control d-block"
      style={{
        width: "170px",
        height: "40px",
        borderColor: "#a0a0a0",
        borderWidth: "1px",
        boxShadow: "1px 1px 2px #3a353b",
        textAlignLast: "center",
      }}
      placeholder="Select Month"
      onChange={(value) => {
        setCustomDatesForGraph(value);
      }}
      options={{
        defaultDate: new Date(),
        maxDate: new Date(),
        minDate: new Date().setMonth(new Date().getMonth() - 6),

        plugins: [
          new monthSelectPlugin({
            shorthand: true, //defaults to false
            dateFormat: "F Y", //defaults to "F Y"
            altFormat: "F Y", //defaults to "F Y"
            theme: "light", // defaults to "light"
          }),
        ],
      }}
    />
  </div>
  <Button
    style={{
      marginRight: "5px",
      marginTop: "5px",
      fontFamily:
        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    }}
    className="me-2"
    variant="success btn-rounded"
    onClick={() => cashDepositsDownloadData()}
  >
    <i className="fa fa-download"></i>&nbsp;&nbsp;Download
  </Button>
</div>

          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "-5px",
            }}
          >
            <div
              style={{ overflow: "auto", maxHeight: "400px", width: "100%" }}
            >
              <BootstrapTable
                keyField="id"
                data={cashDepositionList}
                columns={Columns}
                // selectRow={selectRow}
              />
            </div>
          </div>
          {cashDepositionList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "20px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </LoadingOverlay>
        {toggle === "chatbox" && (
          <VisitedPlaceDrawer
            visitedPlacesDetailsList={cashDepositVisitDetails}
            closeDrawer={() => setToggle(false)}
            // reLoad={() => loadVistedPlacesData(currPage, searchKey)}
          />
        )}
      </div>

      <Modal className="fade" size="xl" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Cash Deposition Visits</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}></Modal.Body>
        <div>
          <LoadingOverlay
            active={isLoading2}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            {/* <div
            style={{
              float: "right",
              margin: "10px",
            }}
          >
            <Flatpickr
              className="form-control d-block"
              style={{
                width: "170px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value);
              }}
              options={{
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          </div> */}
            {/* <Button
            style={{
              margin: "10px",
              // marginRight: "15px",
              float: "right",
              // marginLeft: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="success btn-rounded"
            onClick={() => {
              if (selectedIds.length === 0) {
                return swal("Please select atleast 1 customer", {
                  icon: "warning",
                  dangerMode: true,
                });
              } else {
                setBasicModal(true);
              }
            }}
          >
            <i className="fas fa-external-link-alt"></i>&nbsp;&nbsp;Send Payment
            Links
          </Button> */}

            <div
              style={{
                background: "white",
                margin: "10px",
                marginTop: "-5px",
              }}
            >
              <div
                style={{ overflow: "auto", maxHeight: "400px", width: "100%" }}
              >
                <BootstrapTable
                  keyField="id"
                  data={cashDepositVisits}
                  columns={visitColumns}
                  // selectRow={selectRow}
                />
              </div>
            </div>
            {cashDepositVisits.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "20px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements2}
                pageSize={10}
                defaultActivePage={currPage2 + 1}
                onSelect={onPageChange2}
              />
            </div>
          </LoadingOverlay>
        </div>
      </Modal>

      <Modal className="fade" size="md" show={basicModal2}>
        <Modal.Header>
          <Modal.Title>Cash Deposition Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal2(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}></Modal.Body>
        <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
          {/* <tr></tr> */}
          <tr>
            <SRLWrapper
              options={options1}
              style={{
                height: "140px",
                width: "140px",
              }}
            >
              <img
                style={{
                  height: "70px",
                  width: "80px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                src={cashDepositionDetails?.images}
                alt=""
                className="img-fluid rounded-circle"
              />
            </SRLWrapper>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Emp Id
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.empId
                ? cashDepositionDetails?.empId
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Emp Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.empName
                ? cashDepositionDetails?.empName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Bank Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.bankName
                ? cashDepositionDetails?.bankName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Bank Code
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.bankCode
                ? cashDepositionDetails?.bankCode
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              UTR No
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.utrNumber
                ? cashDepositionDetails?.utrNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Amount Deposited
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.amountDeposited
                ? cashDepositionDetails?.amountDeposited
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              No of Customers
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.numberOfCustomers
                ? cashDepositionDetails?.numberOfCustomers
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Cash Deposition Id
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.depositionId
                ? cashDepositionDetails?.depositionId
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Date & Time
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {cashDepositionDetails?.dateTime
                ? cashDepositionDetails?.dateTime
                : "-"}
            </td>
          </tr>
          {cashDepositionDetails?.cashDepositStatus === "REJECTED" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Rejection Reason
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {cashDepositionDetails?.reasonForRejection
                  ? cashDepositionDetails?.reasonForRejection
                  : "-"}
              </td>
            </tr>
          )}
          {hasAccess(34) &&
            cashDepositionDetails?.cashDepositStatus === "MAPPED" && (
              <div>
                <Button
                  style={{
                    float: "left",
                    marginTop: "10px",
                    marginRight: "25px",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() =>
                    updateCashDeposit(cashDepositionDetails, "DEPOSITED")
                  }
                >
                  Approve
                </Button>
                <Button
                  style={{
                    float: "left",
                    marginTop: "10px",
                    marginRight: "15px",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="danger btn-rounded"
                  onClick={() => {
                    setRejectionReason(null);
                    setBasicModal3(true);
                  }}
                >
                  Reject
                </Button>
              </div>
            )}
        </div>
      </Modal>

      <Modal className="fade" show={basicModal3} size="sm">
        <Modal.Header>
          <Modal.Title>Add Rejection Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal3(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
              <input
                style={{
                  width: "220px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter Reason"
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                  console.log("rejectionReason", rejectionReason);
                }}
              />
              <div style={{ marginTop: "5px" }}>
                <div>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2"
                    type="submit"
                    onClick={() =>
                      updateCashDeposit(cashDepositionDetails, "REJECTED")
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2 float-right"
                    variant="danger light"
                    onClick={() => setBasicModal3(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

                </Tab.Pane>
                <Tab.Pane eventKey="employeeLedger" key="1" mountOnEnter>
                  <EmployeeLedger />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
       </div>
      
    </div>
  );
};

export default CashDeposition;
