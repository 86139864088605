import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { EpochToOnlyDate } from "../CommonComponents/Helper";
import Select from "react-select";

const PtpVisits = ({ userId, organisationId }) => {
  const [visitedPlacesList, setVisitedPlacesList] = useState([]);
  const [visitedPlacesDetailsList, setVisitedPlacesDetailsList] = useState([]);
  const [selectedPtpSlot, setSelectedPtpSlot] = useState("");
  // const [basicModal, setBasicModal] = useState(false);
  const [toggle, setToggle] = useState("");
  // const [start, setStart] = useState(
  //   Math.floor(
  //     new Date(
  //       new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
  //     ).getTime() / 1000
  //   )
  // );
  // const [end, setEnd] = useState(
  //   Math.floor(
  //     new Date(
  //       new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
  //     ).getTime() / 1000
  //   )
  // );
  const [startDate, setStartDate] = useState(new Date());
  const [totalElements, setTotalElements] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // var datesArray = [];
  // // var listData = [];
  // let visitDetailsListData = [];
  // var executiveData = JSON.parse(localStorage.getItem("executiveDetails"));
  //var today = new Date();

  //   var start = Math.floor(today.setHours(0, 0, 0, 0) - 6 / 1000),
  //     end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);

  //var visitType = "Sales";
  useEffect(() => {
    ReactGA.pageview("Executive Visits Page");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadVistedPlacesData(0, startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getExecutiveId = () => {
  //   var execData = localStorage.getItem("executiveDetails");
  //   if (!execData) return;
  //   const parsedJSON = JSON.parse(execData);
  //   return parsedJSON.userId;
  // };

  const loadVistedPlacesData = (pageNo, startDate,ptpSlot) => {

    var visitedPlacesBody = {
      endTime: Math.round(startDate.setHours(23, 59, 59, 0) / 1000),
      organizationId: organisationId,
      startTime: Math.round(startDate.setHours(0, 0, 0, 0) / 1000),
      userId: userId,
      ptpSlot:ptpSlot
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeePtpVisits, pageNo, 10),
        visitedPlacesBody
      )
      .then((response) => {
        var listData = [];
        setCurrPage(pageNo);
        response?.content?.forEach((element) => {
          let data = {
            id: element.id,
            userId: element.userId,
            userModel: element.userModel,

            name: element.userModel.name,
            mobileNumber: element.userModel.mobileNumber,
            email: element.userModel.email,
            UserImage: element.userModel.image,
            branchId: element.userModel.branchId,
            roleName: element.userModel.roleName,
            addedByUserId: element.userModel.addedByUserId,
            employeeId: element.userModel.employeeId,
            userStatus: element.userModel.status,
            passwordUpdated: element.userModel.passwordUpdated,

            organizationId: element.organisationId,
            status: element.status,
            subStatus: element.subStatus,
            comments: element.comments,
            latitude: element.latitude,
            longitude: element.longitude,
            customerLatitude:element.customerLatitude,
            customerLongitude:element.customerLongitude,
            address: element.address,
            visitType: element.visitType,
            image: element.image,
            dateTime: convertEpochTimeToDate(element.dateTime),
            toBePaidBy: EpochToOnlyDate(element.collectionVisit.toBePaidBy),
            ptpSlot: element?.collectionVisit?.ptpSlot,
            timeSpent: element.timeSpent,
            reportingOfficerId: element.reportingOfficerId,
            salesVisit: element.salesVisit,
            collectionVisit: element.collectionVisit,
            fieldInvestigationVisit: element.fieldInvestigationVisit,
            contactNumber: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit.contactNumber
              : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.contactNumber
                  : "-"
                : element.salesVisit
                  ? element.salesVisit.contactNumber
                  : "-",
            productName: element.salesVisit
              ? element.salesVisit.productname
              : "-",
            loanNumber: element.collectionVisit
              ? element.collectionVisit.loanNumber
              : "-",
              ptpAmount: element.collectionVisit.ptpAmount
              ? element.collectionVisit.ptpAmount
              : 0,
            collectionsContactNumber: element.collectionVisit?.contactNumber,
            customerName: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit.customerName
              : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.customerName
                  : "--"
                : element.salesVisit
                  ? element.salesVisit.customerName
                  : "-",
          };
          listData.push(data);
        });
        setVisitedPlacesList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);

      });
  };

  const onPageChange = (selectedPage) => {
    loadVistedPlacesData(selectedPage - 1,startDate,selectedPtpSlot);
  };

  const handleChange = (date) => {
    setStartDate(date);
    loadVistedPlacesData(0, date,selectedPtpSlot);
  };

  const setPtpSlotValue = (value) => {
    setSelectedPtpSlot(value);
    loadVistedPlacesData(0, startDate,value);
  };

  // const secondsToHms = (d) => {
  //   d = Number(d);
  //   var h = Math.floor(d / 3600);
  //   var m = Math.floor((d % 3600) / 60);
  //   var s = Math.floor((d % 3600) % 60);
  //   var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
  //   var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
  //   var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  //   return hDisplay + mDisplay + sDisplay;
  // };

  const ptpSlotOptions = [
    { label: "All", value: "" },
    { label: "6AM", value: "6AM" },
    { label: "7AM", value: "7AM" },
    { label: "8AM", value: "8AM" },
    { label: "9AM", value: "9AM" },
    { label: "10AM", value: "10AM" },
    { label: "11AM", value: "11AM" },
    { label: "12PM", value: "12PM" },
    { label: "1PM", value: "1PM" },
    { label: "2PM", value: "2PM" },
    { label: "3PM", value: "3PM" },
    { label: "4PM", value: "4PM" },
    { label: "5PM", value: "5PM" },
    { label: "6PM", value: "6PM" },
    { label: "7PM", value: "7PM" },
    { label: "8PM", value: "8PM" },
    { label: "9PM", value: "9PM" },


  ];

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };
  const columns = [
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Customer Mobile",
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Loan No",
      dataField: "loanNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "PTP Date",
      dataField: "toBePaidBy",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "PTP Slot",
      dataField: "ptpSlot",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "PTP Amount",
      dataField: "ptpAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          setToggle("chatbox");
        },
      },
    },
  ];

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <div
           style={{
             display: "flex",
             flexDirection: "column",
             alignItems: "left",
           }}
         >
           <label style={{ fontWeight: "bold" }}>
            PTP Slot
           </label>
        <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "20px",
            // marginBottom: "20px",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) => {
              setPtpSlotValue(value.value);
            }}
            defaultValue={{ label: "All", value: "All" }}
            options={ptpSlotOptions}
          />
        </div>
        </div>
        <div
           style={{
             display: "flex",
             flexDirection: "column",
             alignItems: "left",
           }}
         >
           <label style={{ fontWeight: "bold" }}>
            Select Date
           </label>
        <div style={{ marginRight: "20px", float: "right" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              label=""
              clearable
              format="dd/MM/yyyy"
              //disablePast
              value={startDate}
              onChange={handleChange}
            />
          </MuiPickersUtilsProvider>
        </div>
        </div>
      </div>

      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div style={{ overflow: "auto", maxHeight: "400px" }}>

            <BootstrapTable
              keyField="panNo"
              data={visitedPlacesList}
              columns={columns}
            />

          </div>
          {visitedPlacesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      {toggle === "chatbox" && (
        <VisitedPlaceDrawer
          visitedPlacesDetailsList={visitedPlacesDetailsList}
          closeDrawer={() => setToggle(false)}
          reLoad={() => loadVistedPlacesData(currPage,startDate,selectedPtpSlot)}
        />
      )}

      <div>
        {/* <Modal className="fade" show={basicModal} size="lg">
          <Modal.Header>
            <Modal.Title>Visited Place Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setBasicModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{ backgroundColor: "rgb(243,240,249)" }}
                >
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <h6 className="fs-18 font-w600 my-1">Visit Details</h6>
                  </div>

                  <div className="card-body user-profile">
                    <img
                      //src={defaultImage}
                      style={{ height: "90px" }}
                      src={
                        visitedPlacesDetailsList.image
                          ? visitedPlacesDetailsList.image
                          : defaultImage
                      }
                      alt=""
                      className="img-fluid rounded-circle"
                    />

                    <div className="media-body user-meta-info">
                      <h6 className="fs-18 font-w600 my-1">
                        {visitedPlacesDetailsList.name ? visitedPlacesDetailsList.name : "-"}
                      </h6>
                      <h6 className="fs-16 font-w400 my-1">
                        {visitedPlacesDetailsList.dateTime
                          ? visitedPlacesDetailsList.dateTime
                          : "-"}
                      </h6>
                      <h6 className="fs-16 font-w400 my-1">
                        {visitedPlacesDetailsList.comments
                          ? visitedPlacesDetailsList.comments
                          : "-"}
                      </h6>
                      <h6 className="fs-16 font-w400 my-1">
                        {visitedPlacesDetailsList.status
                          ? visitedPlacesDetailsList.status
                          : "-"}
                      </h6>
                      <h6 className="fs-16 font-w400 my-1">
                        {visitedPlacesDetailsList.address
                          ? visitedPlacesDetailsList.address
                          : "-"}
                      </h6>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{ backgroundColor: "rgb(243,240,249)" }}
                >
                  <div
                    style={{
                      marginRight: '20px',
                      marginBottom: "10px",
                      marginTop: '5px',
                      marginLeft: '10px'
                    }}
                  >
                    <h6 className="fs-18 font-w600 my-1 " style={{ float: 'left' }}>Executive Details</h6>
                  </div>
                  <table style={{ marginLeft: '10px' }}>
                    <>
                      <tr></tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.name
                            ? visitedPlacesDetailsList.name
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Mobile</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.mobileNumber
                            ? visitedPlacesDetailsList.mobileNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Email</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.email
                            ? visitedPlacesDetailsList.email
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Role Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.roleName
                            ? visitedPlacesDetailsList.roleName
                            : "-"}
                        </td>
                      </tr>
                    </>
                  </table>

                  <div
                    style={{
                      marginRight: '20px',
                      marginBottom: "10px",
                      marginTop: '40px',
                      marginLeft: '10px'
                    }}
                  >

                    <h6 className="fs-18 font-w600 my-1" style={{float:'left'}}>Customer Details</h6>
                  </div>

                  {visitedPlacesDetailsList.collectionVisit && <table style={{ marginBottom: "15px", marginLeft: '10px' }}>
                    <>
                      <tr></tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.collectionVisit.customerName
                            ? visitedPlacesDetailsList.collectionVisit.customerName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Mobile</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.collectionVisit.contactNumber
                            ? visitedPlacesDetailsList.collectionVisit.contactNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Status</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.status
                            ? visitedPlacesDetailsList.status
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Amount Paid</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.collectionVisit.amountPaid
                            ? visitedPlacesDetailsList.collectionVisit.amountPaid
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Payment Date By</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.collectionVisit.paymentDateBy
                            ? visitedPlacesDetailsList.collectionVisit.paymentDateBy
                            : "-"}
                        </td>
                      </tr>


                    </>
                  </table>}

                  {visitedPlacesDetailsList.salesVisit && <table style={{ marginBottom: "15px", marginLeft: '10px' }}>
                    <>
                      <tr></tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.salesVisit.customerName
                            ? visitedPlacesDetailsList.salesVisit.customerName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Mobile</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.salesVisit.contactNumber
                            ? visitedPlacesDetailsList.salesVisit.contactNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Product Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.salesVisit.productname
                            ? visitedPlacesDetailsList.salesVisit.productname
                            : "-"}
                        </td>
                      </tr>
                    </>
                  </table>}
                </div>
              </Col>
            </Row>
            
          </Modal.Body>
        </Modal> */}
      </div>
    </div>
  );
};

export default PtpVisits;
