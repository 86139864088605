import {
  formatError,
  getTokenInLocalStorage,
  hasAccess,
  removeTokens,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import * as moduleApi from "../../rest/moduleApi";
import { apiCall } from "../../rest/restApi";
import swal from "sweetalert";
import ReactGA from "react-ga";
import { sendDataToDB } from "../../CommonComponents/Helper";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const AGENT_LIVE_STATUSES_ACTION =
  "[Agent Live Status Action] Agent Live Status action";
export const SET_TEAM_SELECTION_MODAL = "SET_TEAM_SELECTION_MODAL";
export const SET_TEAM_LIST = "SET_TEAM_LIST";
export const SET_SELECTED_TEAM = "SET_SELECTED_TEAM";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  var userLoginDetails = getTokenInLocalStorage();
  var payLoad = {
    breakName: undefined,
    status: "Offline",
    // organizationId: userData.autoFinanceUser.organizationId,
    userId: userLoginDetails?.autoFinanceUser?.userId,
  };
  console.log(payLoad);

  if (
    hasAccess(42) &&
    userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
    userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
    userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
    userLoginDetails?.autoFinanceUser?.teleCmiPassword !== ""
  ) {
    moduleApi
      .postData(apiCall.updateAgentLatestStatus, payLoad)
      .then((response) => {
        console.log(" status response", response);
      });
    sendDataToDB("Offline", localStorage.getItem("todayCallsCount"));
  }

  localStorage.removeItem("userDetails");
  localStorage.removeItem("fieldInvestigationAgency");
  removeTokens();
  history.push("/login");
  userLoginDetails?.autoFinanceUser?.roleName === "SERVICE_EXECUTIVE" &&
    window.location.reload();
  return {
    type: LOGOUT_ACTION,
  };
}

const getAllOrganisations = (history, dispatch) => {
  moduleApi.getData(apiCall.getAllOrganisations).then((response) => {
    //   const result = response.map((item, index) => ({
    //     value: item.orgshortcode,
    //     label: item.organizationName,
    //     index: index
    //   })
    //   );

    //   setOrganisationList(result);
    moduleApi
      .getData(apiCall.getOrganisationData + response[0].orgshortcode)
      .then((response) => {
        console.log("Organisationdata", response);
        const userInfo = localStorage.getItem("userDetails");
        const parsedUserInfo = JSON.parse(userInfo);
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            ...parsedUserInfo,
            autoFinanceBranchDetails: response.autoFinanceBranchDetails,
            autoFinanceOrganization: response.autoFinanceOrganization,
            autoFinanceUser: response.autoFinanceUser,
            rolePrivileges: response.rolePrivileges,
            isSuperAdmin: true,
          })
        );
        dispatch(
          loginConfirmedAction(JSON.parse(localStorage.getItem("userDetails")))
        );
        // window.location.reload();
        history.push("/home");
        // window.location.reload();
      });
  });
};

export function loginAction(orgId, email, password, history) {
  const loginData = {
    orgshortcode: orgId.toUpperCase(),
    userName: email,
    passWord: password,
    // pushNotificationToken: firebaseToken,
    webPushNotificationToken: true,
  };
  return (dispatch) => {
    moduleApi
      .loginPostData(apiCall.loginWithOrgId, loginData)
      .then((response) => {
        if (response?.status === 400 || response?.status === 430) {
          ReactGA.event({
            category: "Login Error",
            action: response?.message,
          });
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
        if (
          response?.autoFinanceUser &&
          response?.autoFinanceUser?.roleName === "EXECUTIVE"
        ) {
          ReactGA.event({
            category: "Unauthorized Login",
            action: response?.message,
          });
          swal("Unauthorized Login", {
            icon: "error",
            dangerMode: true,
          });
          return;
        }
        // if (!(response.autoFinanceUser.roleName === 'MANAGER') && !(response.autoFinanceUser.roleName === 'ADMIN') && !(response.autoFinanceUser.roleName === 'SUPERADMIN')) {
        //     toaster('error', 'Unauthorized Login')
        //     return;
        // }
        // runLogoutTimer(
        ReactGA.event({
          category: "Successful Login",
          action:
            "Org: " +
            response?.autoFinanceOrganization?.orgshortcode +
            ", Name: " +
            response?.autoFinanceUser?.name +
            ",  RoleName: " +
            response?.autoFinanceUser?.roleName,
        });
        saveTokenInLocalStorage(response);

        if (
          response?.autoFinanceUser &&
          response?.autoFinanceUser?.roleName === "SUPERADMIN"
        ) {
          getAllOrganisations(history, dispatch);
        } else if (response?.fieldInvestigationAgency) {
          dispatch(loginConfirmedAction(response));
          localStorage.setItem(
            "fieldInvestigationAgency",
            JSON.stringify(response?.fieldInvestigationAgency)
          );
          history.push({
            pathname: "/fi-allocations",
            state: {
              fieldInvestigationAgency: response?.fieldInvestigationAgency,
            },
          });
        } else {
          if (
            // hasAccess(42) &&
            response?.rolePrivileges[0]?.privilageIds.includes(42) &&
            response?.autoFinanceUser?.teleCmiUserId !== null &&
            response?.autoFinanceUser?.teleCmiPassword !== null &&
            response?.autoFinanceUser?.teleCmiUserId !== "" &&
            response?.autoFinanceUser?.teleCmiPassword !== ""
          ) {
            if (response?.teams?.length > 0) {
              const result = response?.teams?.map((item) => ({
                value: item,
                label: item?.teamName,
              }));
              dispatch(setTeamList(result));
              dispatch(setTeamSelectionModal(true));
            } else {
              var temp = getTokenInLocalStorage();
              temp.selectedTeam = response.teams[0];
              var privilegeTemp = [
                ...response?.rolePrivileges[0]?.privilageIds,
                response?.teams[0]?.privilages,
              ];
              temp.rolePrivileges[0].privilageIds = privilegeTemp;
              localStorage.setItem("userDetails", JSON.stringify(temp));
              dispatch(loginConfirmedAction(temp));
              response?.autoFinanceUser?.roleName !== "CP_USER"
                ? response?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE"
                  ? history.push("/home")
                  : history.push("/vehicle-sales")
                : history.push("/visits");
              (response?.autoFinanceUser?.roleName === "CP_USER" ||
                response?.autoFinanceUser?.roleName === "SERVICE_EXECUTIVE") &&
                //  || response?.autoFinanceUser?.roleName === "MANAGER_RA"
                window.location.reload();
            }
          } else {
            dispatch(loginConfirmedAction(response));
            response?.autoFinanceUser?.roleName !== "CP_USER"
              ? response?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE"
                ? history.push("/home")
                : history.push("/vehicle-sales")
              : history.push("/visits");
            (response?.autoFinanceUser?.roleName === "CP_USER" ||
              response?.autoFinanceUser?.roleName === "SERVICE_EXECUTIVE") &&
              //  || response?.autoFinanceUser?.roleName === "MANAGER_RA"
              window.location.reload();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        // const errorMessage = formatError(error);
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function otpLessLoginAction(response, history) {
  return (dispatch) => {
    if (
      response?.autoFinanceUser &&
      response?.autoFinanceUser?.roleName === "EXECUTIVE"
    ) {
      swal("Unauthorized Login", {
        icon: "error",
        dangerMode: true,
      });
      return;
    }
    ReactGA.event({
      category: "Successful Login with OTP Less",
      action:
        "Org: " +
        response?.autoFinanceOrganization?.orgshortcode +
        ", Name: " +
        response?.autoFinanceUser?.name +
        ",  RoleName: " +
        response?.autoFinanceUser?.roleName,
    });
    saveTokenInLocalStorage(response);
    if (
      response?.autoFinanceUser &&
      response?.autoFinanceUser?.roleName === "SUPERADMIN"
    ) {
      getAllOrganisations(history, dispatch);
    } else if (response?.fieldInvestigationAgency) {
      dispatch(loginConfirmedAction(response));
      localStorage.setItem(
        "fieldInvestigationAgency",
        JSON.stringify(response?.fieldInvestigationAgency)
      );
      history.push({
        pathname: "/fi-allocations",
        state: {
          fieldInvestigationAgency: response?.fieldInvestigationAgency,
        },
      });
    } else {
      dispatch(loginConfirmedAction(response));
      response?.autoFinanceUser?.roleName !== "CP_USER"
        ? response?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE"
          ? history.push("/home")
          : history.push("/vehicle-sales")
        : history.push("/visits");
      (response?.autoFinanceUser?.roleName === "CP_USER" ||
        response?.autoFinanceUser?.roleName === "SERVICE_EXECUTIVE") &&
        //  || response?.autoFinanceUser?.roleName === "MANAGER_RA"
        window.location.reload();
    }
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function setTeamSelectionModal(value) {
  return {
    type: SET_TEAM_SELECTION_MODAL,
    payload: value,
  };
}

export function setTeamList(value) {
  return {
    type: SET_TEAM_LIST,
    payload: value,
  };
}

export function setSelectedTeam(value) {
  return {
    type: SET_SELECTED_TEAM,
    payload: value,
  };
}

export function agentLiveStatusAction(data) {
  return {
    type: AGENT_LIVE_STATUSES_ACTION,
    payload: data,
  };
}
