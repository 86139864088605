import React, { useState, useCallback, useEffect } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import SelectField from "../../CommonComponents/SelectField";
import { Form, Formik } from "formik";
import swal from "sweetalert";
import PreviewTable from "./PreviewTable";
import "../fiallocations.css";
import * as nodeApi from "../../rest/nodeApi";
import * as moduleApi from "../../rest/moduleApi";
import * as moduleApi2 from "../../rest/moduleApi2";
import { toaster } from "../../rest/apiUtils";
import { apiCall } from "../../rest/restApi";
import {
  validateAndStrctureUploadedData,
  getFilteredExcelData,
  getFileNameForExcelFileUpload
  // getCurrentDateInDDMMYYYYFormat,
} from "../utils";
import axios from "axios";
import PreviewAgencyTable from "./PreviewAgencyTable";
import ReactGA from "react-ga";
import LoadingOverlay from "react-loading-overlay";
import { camelize } from "../../DB/utils";

function UploadFiAllocations({
  close,
  getFiAllocationsOfEmployees,
  getFiAllocationsOfAgencies,
  fiAllocationsOfEmp,
  fiAllocationsOfAgencies,
  fiClients,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [fullExcelFile, setFullExcelFile] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [agenciesList, setAgenciesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [agencyName, setAgencyName] = useState(null);
  const [agencyEmail, setAgencyEmail] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [assignToValue, setAssignToValue] = useState(null);
  const [fiTypeValue, setfiTypeValue] = useState(null);
  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  let userData = JSON.parse(localStorage.getItem("userDetails"));
  let roleName = userData.autoFinanceUser.roleName;
  let assignToOptions = [];

  if (roleName === "ADMIN") {
    assignToOptions = [
      { label: "Agency", value: "Agency" },
      { label: "Employee", value: "Employee" },
      // { label: "Executive", value: "Executive" },
    ];
  } else if (roleName === "MANAGER") {
    assignToOptions = [
      { label: "Employee", value: "Employee" },
      // { label: "Executive", value: "Executive" },
    ];
  }

  useEffect(() => {
    ReactGA.pageview("Field Investigation Upload");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllUsers();
    getAllAgencies();
    // eslint-disable-next-line
  }, []);

  const getAllAgencies = () => {
    var userLoginData = JSON.parse(localStorage.getItem("userDetails"));
    const organizationId = userLoginData.autoFinanceUser.organizationId;
    nodeApi.getData(apiCall.agencies, "/" + organizationId).then((response) => {
      if (response.success === true) {
        if (response.agencies) {
          let agencies = [];
          let activeAgencies = [];
          activeAgencies = response.agencies.filter(
            (item) => item.status === "ACTIVE"
          );
          if (activeAgencies.length > 0) {
            // eslint-disable-next-line
            activeAgencies.map((item) => {
              // console.log(item)
              let obj = {
                label: item.agencyName,
                value: item.agencyId,
                email: item.email,
              };
              agencies.push(obj);
            });
            setAgenciesList(agencies);
          }
        }
      }
    });
  };

  const getAllUsers = () => {
    let requestPayload = {
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: userData.autoFinanceUser.roleName,
    };
    nodeApi
      .postData(apiCall.getUsersForAdmin, requestPayload)
      .then((response) => {
        if (response.success === true) {
          if (response.users) {
            setUsersList(response.users);
          }
        }
      });
  };

  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    setUploadedFile(selectedFile);
    if (selectedFile) {
      setFileName(selectedFile.name);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setFullExcelFile(selectedFile);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("Please select file");
    }
  };

  let fiTypeOptions = [
    { label: "Residence", value: "residence" },
    { label: "Office", value: "office" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.assignTo == null) {
      setError({
        assignTo: {
          errorMsg: "Please Select Assign To",
        },
      });
    } else if (form.fiType == null) {
      setError({
        fiType: {
          errorMsg: "Please Select FI Type",
        },
      });
    } else if (form.assignTo === "Agency" && form.agency === null) {
      setError({
        agency: {
          errorMsg: "Please Select Agency",
        },
      });
    } else {
      setError({
        assignTo: {
          errorMsg: "",
        },
        agency: {
          errorMsg: "",
        },
      });
      if (
        excelFile !== null &&
        assignToValue !== null &&
        fiTypeValue !== null
      ) {
        getFilteredExcelData(
          excelFile,
          setExcelData,
          form.assignTo,
          fiTypeValue,
          setIsSubmit
        );
      }
    }
  };

  const uploadFileToS3Bucket = () => {
    let uploadImagePayload = {
      fileKeyName: fileName,
      type: "EXCEL",
    };
    nodeApi
      .postData(apiCall.getImageUrl, uploadImagePayload)
      .then((response) => {
        const url = response.imageUrls.fileUploadURL;
        const keyName = response.imageUrls.fileKeyName;
        axios({
          method: "PUT",
          url: url,
          data: uploadedFile,
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        })
          .then((res) => {
            // let filePath = res.config.url.split('?')[0]
            let agencyUploadPayload = {
              agencyId: form.agency,
              organizationId: userData.autoFinanceUser.organizationId,
              fileName: fileName,
              fileUrl: keyName,
            };
            nodeApi
              .postData(apiCall.saveAgencyUploadData, agencyUploadPayload)
              .then((response) => {})
              .catch((error) => {
                toaster(
                  "error",
                  "Something Went Wrong, Please try again later"
                );
              });
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  const handleCloseLoading = () => {
    setIsLoading(false);
  };

  const uploadDailyScheduleData = async () => {
    // let temp= []
    // excelData?.forEach(element => {
    //   if(element?.applicationNo===''){
    //     temp.push(element)
    //   }
    // });
    // console.log(temp?.length);
    //     var payload ={
    //       date:getCurrentDateInDDMMYYYYFormat(),
    //       orgId: userData?.autoFinanceOrganization?.organizationId
    //     }

    // let countResponse = await nodeApi.postData(apiCall.getLatestApplicationNoCount, payload )
    setIsLoading(true);
    validateAndStrctureUploadedData(
      excelData,
      userData.autoFinanceUser.userId,
      form.assignTo,
      form.agency,
      agencyName,
      usersList,
      fiAllocationsOfEmp,
      fiAllocationsOfAgencies,
      // countResponse?.applicationNo[0]?.count,
      fiTypeValue,
      handleCloseLoading,
      fiClients
    ).then((data) => {
      // console.log(data);
      let organizationName = userData.autoFinanceOrganization.organizationName;
      let emailContent1 = "Greetings " + agencyName + ",";
      // eslint-disable-next-line
      let emailContent2 =
        "<br/>" +
        "Subject : " +
        organizationName +
        ", has upload a file with the Field Investigations, for your action.";
      let emailContent3 =
        organizationName +
        `, has uploaded a file with Field Investigations to be performed 
    for their customers. To Download the file please log into the below mentioned web app (URL) with your registered Email ID and Password.
      You can download the file and also Upload the completed Field Investigation Reports in the same web page.`;
      // eslint-disable-next-line
      let emailContent4 =
        " <br/><br/> Web App URL: <a href=" +
        "https://web.clucloud.com/login" +
        "> https://web.clucloud.com/login</a>";
      let emailMessage =
        emailContent1 +
        "<br/>" +
        emailContent2 +
        "<br/><br/>" +
        emailContent3 +
        emailContent4;

      let emailRequestPayload = {
        bccEmails: [],
        ccEmails: [],
        emailMessage: emailMessage,
        emailSubject:
          "IMP: Need Attention!!  -  Field Investigations File for your Action.",
        emailTo: agencyEmail,
      };

      if (excelData === null || (excelData && excelData.length === 0)) {
        return toaster("error", "No allocations are found in excel file");
      }
      console.log(data);
      if (data && data.length > 0) {
        if (form.agency) {
          uploadFileToS3Bucket();
        }
        nodeApi
          .postData(apiCall.fiAllocations, data)
          .then((response) => {
            if (response.statusCode === 200 && response.success === true) {
              // toaster("success", response.message);
              var payload = {
                action: camelize(fiTypeValue),
                fileName: getFileNameForExcelFileUpload(camelize(fiTypeValue)),
                orgShortCode: userData?.autoFinanceOrganization?.orgshortcode,
                organizationId: userData?.autoFinanceUser?.organizationId,
                userId: userData?.autoFinanceUser?.userId,
                numberOfRecordProcessed:data?.length,
                allocationType: "fiAllocation",
              };
              console.log("payload", payload);
              moduleApi2
                .postData(
                  apiCall.getPreSignedUrlForUplaodCollectionFile,
                  payload
                )
                .then((fullExcelResponse) => {
                  moduleApi2
                    .putDataByNameForExcel(
                      fullExcelResponse?.generatedUrlForExcel,
                      fullExcelFile
                    )
                    .then((response) => {
                      //Service Unavailable
                      console.log("bulk Upload2", response);

                      swal("Uploaded Successfully", {
                        icon: "success",
                      });
                    });
                });
              if (form.agency) {
                // nodeApi.postData(apiCall.sendEmail, emailRequestPayload).then((response) => {
                //   console.log(response)
                // })
                moduleApi
                  .postEmailData(apiCall.sendEmail, emailRequestPayload)
                  .then((response) => {
                    console.log(response);
                  });
              }
              close();
              getFiAllocationsOfEmployees();
              getFiAllocationsOfAgencies();
              setIsLoading(false);
              setFullExcelFile(null)
            } else {
              toaster("error", response.message);
              close();
            }
          })
          .catch((error) => {
            toaster("error", "Something Went Wrong, Please try again later");
          });
      }
    });
  };

  const [form, setForm] = useState({
    assignTo: null,
    agency: null,
  });

  const onValidate = (value, name) => {
    setError((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };

  const [error, setError] = useState({
    assignTo: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    fiType: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    agency: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
  });

  const onHandleChange = useCallback((value, name, data) => {
    if (name === "agency" && value === null) {
      setError({
        agency: {
          errorMsg: "Please Select Agency",
        },
      });
    } else if (name === "assignTo" && value === null) {
      setError({
        assignTo: {
          errorMsg: "Please Select Assign To",
        },
      });
    } else if (name === "fiType" && value === null) {
      setError({
        fiType: {
          errorMsg: "Please Select FI Type",
        },
      });
    } else {
      setError({
        assignTo: {
          errorMsg: "",
        },
        fiType: {
          errorMsg: "",
        },
        agency: {
          errorMsg: "",
        },
      });
    }
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsSubmit(false);
    if (name === "assignTo") {
      setAssignToValue(value);
    }
    if (name === "fiType") {
      setfiTypeValue(value);
    }
    if (data && name === "agency") {
      setAgencyName(data.label);
      setAgencyEmail(data.email);
    }
  }, []);

  return (
    <div className="custom-tab-1">
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div className="container">
          <div className="form">
            <Formik>
              {({ formik, errors }) => (
                <Form
                  className="form-group"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col>
                      <label>
                        <h6>Upload Excel file</h6>
                      </label>
                      <br></br>
                      <input
                        type="file"
                        className="form-control file-upload-field"
                        onChange={handleFile}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        required
                      ></input>
                      {excelFileError && (
                        <div className="text-danger excel-file-error">
                          {excelFileError}
                        </div>
                      )}
                    </Col>
                    <Col>
                      <div className="margin-bt-5">
                        <label>
                          <h6>Assign To</h6>
                        </label>
                        <SelectField
                          name="assignTo"
                          value={form.assignTo}
                          options={assignToOptions}
                          onChangeFunc={onHandleChange}
                          {...error.assignTo}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="margin-bt-5">
                        <label>
                          <h6>FI Type</h6>
                        </label>
                        <SelectField
                          name="fiType"
                          value={form.fiType}
                          options={fiTypeOptions}
                          onChangeFunc={onHandleChange}
                          {...error.fiType}
                        />
                      </div>
                    </Col>
                    {form.assignTo === "Agency" && (
                      <Col>
                        <div className="margin-bt-5">
                          <label>
                            <h6>Select Agency</h6>
                          </label>
                          <SelectField
                            name="agency"
                            value={form.agency}
                            options={agenciesList}
                            onChangeFunc={onHandleChange}
                            {...error.agency}
                          />
                        </div>
                      </Col>
                    )}
                    <Col>
                      <button
                        className="btn btn-primary margin-top-25"
                        onClick={() => {
                          if (
                            excelFile !== null &&
                            assignToValue !== null &&
                            fiTypeValue !== null
                          )
                            setIsSubmit(true);
                        }}
                      >
                        Preview
                      </button>
                      {isSubmit &&
                        Form.assignTo !== null &&
                        Form.fiType !== null && (
                          <button
                            type="submit"
                            className="btn btn-success btn-submit-custom"
                            onClick={() => {
                              uploadDailyScheduleData();
                            }}
                          >
                            Submit
                          </button>
                        )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
          <br></br>
          <hr></hr>
          {assignToValue === "Employee" ? (
            <PreviewTable
              excelData={excelData}
              agency={assignToValue}
              fiType={fiTypeValue}
            />
          ) : (
            <PreviewAgencyTable excelData={excelData} agency={assignToValue} />
          )}
          <br />
          <br />
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default UploadFiAllocations;
