import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import Select from "react-select";
import defaultImage from "../images/defaultImage.png";
import { SRLWrapper } from "simple-react-lightbox";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess, isAdmin } from "../services/AuthService";
// import { toaster } from "../../src/rest/apiUtils";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import ReactGA from "react-ga";
import TextField from "../CommonComponents/TextField";
import EmployeeExpense from "./EmployeeExpense";
import SearchBar from "../CommonComponents/SearchBar";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";

const ExpenseManagement = () => {
  const [expenseList, setExpenseList] = useState([]);
  const [expenseDownloadList, setExpenseDownloadList] = useState(null);
  const [expenseDetailsList, setexpenseDetailsList] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  const [selectedExpenseType, setSelectedExpenseType] = useState(null);
  const [expenseModal, setExpenseModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [expenseHistoryModal, setExpenseHistoryModal] = useState(false);
  const [expenseStatus, setExpenseStatus] = useState("");
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [expenseApproval, setExpenseApproval] = useState(false);
  const [expenseApprovalForAdmin, setExpenseApprovalForAdmin] = useState(false);
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("userDetails"));
    setloggedInUser(userData);
    setDateValue("Last 7 Days", 1);
    loadExpenseData(0, searchKey);
    // accepttheapprove();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview("Expense-Management");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    expenseDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseDownloadList]);

  var isFilePicked = "";

  const loadExpenseData = (pageNo, key) => {
    setIsLoading(true);
    var payload = {
      endtime: end,
      starttime: start,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? undefined
        : loggedInUser.autoFinanceUser.userId,
      searchKey: key,
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.getExpenseList, pageNo, 10), payload)
      .then((response) => {
        console.log("expenseResponse", response);
        setCurrPage(pageNo);
        var listData = [];

        response?.content?.forEach((element) => {
          let data = {
            branchId: element.branchId,
            comments: element.comments?element.comments:'-',
            expenseCost: element.expenseCost?element.expenseCost:'-',
            expenseStatus: element.expenseStatus,
            expenseType: element.expenseType?element.expenseType:'-',
            expensesAddedTime: element.expensesAddedTime?convertEpochTimeToDate(
              element.expensesAddedTime
            ):'-',
            id: element.id,
            reasonForReject: element.reasonForReject,
            images: element.images,
            imagesCount: element.imagesCount,
            lastUpdatedTime: element.lastUpdatedTime,
            organizationId: element.organizationId,
            userId: element.userId,
            userModel: element.userModel,

            addedByUserId: element.userModel.addedByUserId,
            companyDesignation: element.userModel.companyDesignation,
            email: element.userModel.email,
            employeeId: element.userModel.employeeId,
            image: element.userModel.image,
            mobileNumber: element.userModel.mobileNumber,
            name: element.userModel.name,
            passwordUpdated: element.userModel.passwordUpdated,
            reportingOfficerId: element.userModel.reportingOfficerId,
            reportingOfficerIds: element.userModel.reportingOfficerIds,
            roleName: element.userModel.roleName,
            status: element.userModel.status,
            subOrdinateIds: element.userModel.subOrdinateIds,
            subOrdinateManagersIds: element.userModel.subOrdinateManagersIds,
            trackingEnabled: element.userModel.trackingEnabled,
            adminApproved: element.adminApproved,
            managerApproved: element.managerApproved,
          };
          listData.push(data);
        });

        setExpenseList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

  const expenseDownloadData = (key) => {
    setIsLoading(true);
    var payload = {
      endtime: end,
      starttime: start,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? undefined
        : loggedInUser.autoFinanceUser.userId,
      searchKey: key,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getExpenseList, 0, 10), payload)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getExpenseFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getExpenseFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getExpenseFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        endtime: end,
        starttime: start,
        organizationId: loggedInUser.autoFinanceUser.organizationId,
        reportingOfficerId: isAdmin()
          ? undefined
          : loggedInUser.autoFinanceUser.userId,
        searchKey: searchKey,
      };
      await moduleApi
        .postData(moduleApi.withPage(apiCall.getExpenseList, i, 100), payload)
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        branchId: element.branchId,
        comments: element.comments ? element.comments : "-",
        expenseCost: element.expenseCost ? element.expenseCost : "-",
        expenseStatus: element.expenseStatus ? element.expenseStatus : "-",
        expenseType: element.expenseType ? element.expenseType : "-",
        expensesAddedTime: element.expensesAddedTime
          ? convertEpochTimeToDate(element.expensesAddedTime)
          : "-",
        adminApprovedTime: element.adminApprovedTime
          ? convertEpochTimeToDate(element.adminApprovedTime)
          : "-",
        mangerApprovedTime: element.mangerApprovedTime
          ? convertEpochTimeToDate(element.mangerApprovedTime)
          : "-",
        id: element.id,
        reasonForReject: element.reasonForReject
          ? element.reasonForReject
          : "-",
        images: element.images,
        imagesCount: element.imagesCount,
        lastUpdatedTime: element.lastUpdatedTime,
        organizationId: element.organizationId,
        userId: element.userId,
        userModel: element.userModel,

        addedByUserId: element.userModel.addedByUserId,
        companyDesignation: element.userModel.companyDesignation,
        email: element.userModel.email,
        employeeId: element.userModel.employeeId
          ? element.userModel.employeeId
          : "-",
        image: element.userModel.image,
        mobileNumber: element.userModel.mobileNumber,
        name: element.userModel?.name ? element.userModel.name : "-",
        passwordUpdated: element.userModel.passwordUpdated,
        reportingOfficerId: element.userModel.reportingOfficerId,
        reportingOfficerIds: element.userModel.reportingOfficerIds,
        roleName: element.userModel.roleName,
        status: element.userModel.status,
        subOrdinateIds: element.userModel.subOrdinateIds,
        subOrdinateManagersIds: element.userModel.subOrdinateManagersIds,
        trackingEnabled: element.userModel.trackingEnabled,
        adminApproved: element.expenseStatus
          ? element.expenseStatus === "ACCEPTED"
            ? "Approved"
            : element.expenseStatus === "REJECTED"
            ? "Rejected"
            : element.expenseStatus === "PENDING"
            ? "Pending"
            : "-"
          : "-",
        managerApproved:
          element.managerApproved === true
            ? "Approved"
            : element.expenseStatus === "REJECTED"
            ? "Rejected"
            : element.managerApproved === false
            ? "Pending"
            : "-",
        // : "-",
      };
      mgrListData.push(data);
    });
    setExpenseDownloadList(mgrListData);

    // console.log("mgrList1", mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {
        // A: "Employee Id",
        // B: "Employee Name",
        A: "Emp Name",
        B: "Emp Id",
        C: "Expense Type",
        D: "Applied Date & Time",
        E: "Expense Amount",
        F: "Manager Approval",
        G: "Manager Approved/Rejected Date&Time",
        H: "Admin Approval",
        I: "Admin Approved/Rejected Date&Time",
        // J: "Pay Response Code",
        // K: "Transaction Id",
      },
    ];

    expenseDownloadList?.forEach((row) => {
      mgrTable.push({
        // A: row.empId,
        // B: row.name,
        A: row.name,
        B: row.employeeId,
        C: row.expenseType,
        D: row.expensesAddedTime,
        E: row.expenseCost,
        F: row.managerApproved,
        G: row.mangerApprovedTime,
        H: row.adminApproved,
        I: row.adminApprovedTime,
        // J: row.payResponseCode,
        // K: row.trasactionid,
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Expenses");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      "Expenses_" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx"
    );
    ReactGA.event({
      category: "Expenses List Downlaod",
      action:
        loggedInUser.autoFinanceOrganization.orgshortcode +
        "_Expenses_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const accepttheapprove = (row) => {
    if (
      loggedInUser.autoFinanceUser.roleName === "MANAGER" &&
      row.managerApproved === false
    ) {
      console.log("true");
      setExpenseApproval(true);
    } else if (
      loggedInUser.autoFinanceUser.roleName === "ADMIN" &&
      row.managerApproved === true &&
      row.adminApproved === false
    ) {
      console.log("false");
      setExpenseApprovalForAdmin(true);
    } else if (
      loggedInUser.autoFinanceUser.roleName === "MANAGER" &&
      row.managerApproved === true &&
      row.adminApproved === false
    ) {
      setExpenseApproval(false);
    } else if (
      loggedInUser.autoFinanceUser.roleName === "ADMIN" &&
      row.reportingOfficerId === loggedInUser.autoFinanceUser.userId &&
      row.adminApproved === false
    ) {
      setExpenseApproval(true);
      setExpenseApprovalForAdmin(true);
    }
  };
  const updateExpenseRequest = (expenseDetails, status) => {
    console.log("status", status);
    if (status === "REJECTED" && rejectionReason === null) {
      return swal("Please Enter Rejection Reason", {
        icon: "error",
        dangerMode: true,
      });
    }

    var body = {
      expenseStatus: status,
      id: expenseDetails.id,
      branchId: expenseDetails.branchId,
      comments: expenseDetails.comments,
      reasonForReject: rejectionReason,
      expenseCost: expenseDetails.expenseCost,
      userId: expenseDetails.userId,
      organizationId: expenseDetails.organizationId,
      managerApproved: expenseApproval && status === "PENDING" ? true : false,
      adminApproved:
        expenseApprovalForAdmin && status === "PENDING" ? true : false,
      expenseType: expenseDetails.expenseType,
    };

    moduleApi.postData(apiCall.updateExpenseRequest, body).then((response) => {
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setBasicModal(false);
          setBasicModal2(false);
          loadExpenseData(0);
          swal("Expense Status Updated Successfully", {
            icon: "success",
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const onPageChange = (selectedPage) => {
    loadExpenseData(selectedPage - 1, searchKey);
  };

  const columns = [
    {
      text: "Emp Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Expense</span>
          <br></br>
          <span>Type</span>
        </div>
      ),
      dataField: "expenseType",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Applied</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "expensesAddedTime",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Expense",
      dataField: "expenseCost",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Manager</span>
          <br></br>
          <span>Approval</span>
        </div>
      ),
      dataField: "expenseStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.managerApproved === true) {
          return (
            <span className="badge badge-success">
              Approved
              <span className="ms-1 fa fa-check" />
            </span>
          );
        } else if (row.expenseStatus === "REJECTED") {
          return (
            <span className="badge badge-danger">
              Rejected
              <span className="ms-1 fa fa-ban" />
            </span>
          );
        } else if (row.managerApproved === false) {
          return (
            <span className="badge badge-warning">
              Pending
              <span className="ms-1 fas fa-stream" />
            </span>
          );
        }
      },
    },

    {
      text: (
        <div>
          <span>Admin</span>
          <br></br>
          <span>Approval</span>
        </div>
      ),
      dataField: "expenseStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.expenseStatus === "ACCEPTED") {
          return (
            <span className="badge badge-success">
              Approved
              <span className="ms-1 fa fa-check" />
            </span>
          );
        } else if (row.expenseStatus === "PENDING") {
          return (
            <span className="badge badge-warning">
              Pending
              <span className="ms-1 fas fa-stream" />
            </span>
          );
        } else if (row.expenseStatus === "REJECTED") {
          return (
            <span className="badge badge-danger">
              Rejected
              <span className="ms-1 fa fa-ban" />
            </span>
          );
        }
      },
    },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setexpenseDetailsList(row);
          accepttheapprove(row);
          setBasicModal(true);

          //   setToggle("chatbox");
        },
      },
    },
  ];

  const handleChange1 = (e) => {
    let files = e.target.files;
    console.log("files", e.target.files);
    if (
      (files[0].type === "image/jpg") |
      (files[0].type === "image/jpeg") |
      (files[0].type === "image/png") |
      (files[0].type === "image/JPEG") |
      (files[0].type === "image/JPG") |
      (files[0].type === "image/PNG")
    ) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setCurrentImage(reader.result);
        });
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
          let fileInfo = {
            base64: reader.result,
          };
          var basedata = fileInfo.base64.split(",");
          isFilePicked = basedata[1];

          setCurrentFile(isFilePicked);
        }; // reader.onload
      } // for
    } else {
      e.target.files = null;
      swal("Please Select image Format Only", {
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const expenseTypeOptions = [
    { label: "Travel", value: "Travel" },
    { label: "Food", value: "Food" },
    { label: "Fuel", value: "Fuel" },
    { label: "Others", value: "Others" },
    // { label: "Last 30 Days", value: 3 },
    // { label: "Custom", value: 4 },
  ];

  const addExpense = (values) => {
    if (currentFile === null) {
      return swal("Please Add Expense Image", {
        icon: "error",
        dangerMode: true,
      });
    }
    if (selectedExpenseType === null) {
      return swal("Please Add Expense Type", {
        icon: "error",
        dangerMode: true,
      });
    }
    var payload = {
      comments: values.comments,
      expenseCost: values.expenseAmount,
      expenseType: selectedExpenseType,
      images: [currentFile ? currentFile : <></>],
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      userId: loggedInUser.autoFinanceUser.userId,
    };

    moduleApi.postData(apiCall.addExpense, payload).then((response) => {
      console.log("addExpenseReponse", response);
      if (response) {
        setExpenseModal(false);
        setCurrentImage(null);
        setCurrentFile(null);
        swal("Expense Added Successfully", {
          icon: "success",
        });
      }
    });
  };
  // eslint-disable-next-line
  const amountRegex = /^\d+(\.\d{1,2})?$/;
  const validate = Yup.object({
    expenseAmount: Yup.string()
      .matches(amountRegex, "Expense Amount is invalid")
      .required("Expense Amount is required"),
    comments: Yup.string().required("Remarks is required"),
  });

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const setDateValue = (label, value) => {
    var date;
    console.log(value, "value");
    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        // loadExpenseData();
        return;
      case 2:
        var today = new Date();

        // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
        setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));

        setShowDate(false);
        //loadExpenseData();
        return;

      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        //loadExpenseData();
        return;
      case 4:
        setShowDate(true);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  function setCustomDates(dateStrings) {
    if (dateStrings.length > 1) {
      // start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
      // end = Math.floor(new Date(dateStrings[1]).getTime() / 1000);
      setStart(Math.floor(dateStrings[0].getTime() / 1000));
      setEnd(Math.floor(dateStrings[1].getTime() / 1000) + 86400);
    }
    //loadExpenseData();
  }

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadExpenseData(0, key);
  };

  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };
  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Modal className="fade" show={expenseModal}>
          <Modal.Header>
            <Modal.Title>Add Expense</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setCurrentImage(null);
                setExpenseModal(false);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ paddingTop: "0px" }}>
            <div style={{ margin: "10px" }}>
              <Formik
                initialValues={{
                  expenseAmount: "",
                  expenseType: "",
                  comments: "",
                }}
                onSubmit={addExpense}
                validationSchema={validate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div>
                            <Field>
                              {({ field, form }) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={
                                      currentImage ? currentImage : defaultImage
                                    }
                                    className="img-fluid rounded-circle"
                                    alt="profile"
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "50px",
                                      height: "100px",
                                      width: "100px",
                                      alignSelf: "center",
                                    }}
                                  />
                                  <input
                                    style={{
                                      marginLeft: "150px",
                                      marginBottom: "15px",
                                      marginTop: "10px",
                                    }}
                                    type="file"
                                    accept="image/*"
                                    // multiple
                                    onChange={(e) => handleChange1(e)}
                                  />
                                  <label
                                    className="control-label"
                                    style={{
                                      fontWeight: "bold",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Expense Type
                                  </label>
                                  <div style={{ marginBottom: "10px" }}>
                                    <Select
                                      placeholder="Select Expense Type"
                                      classNamePrefix="select-box"
                                      options={expenseTypeOptions}
                                      onChange={(value) => {
                                        setSelectedExpenseType(value.value);
                                      }}
                                    />
                                  </div>
                                  <TextField
                                    isAsterisk={true}
                                    label="Expense Amount"
                                    name="expenseAmount"
                                    type="text"
                                  />
                                  <TextField
                                    isAsterisk={true}
                                    label="Remarks"
                                    name="comments"
                                    type="text"
                                  />
                                </div>
                              )}
                            </Field>
                          </div>
                          <div>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2"
                              type="submit"
                            >
                              Submit
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2 float-right"
                              variant="danger light"
                              onClick={() => {
                                setCurrentImage(null);
                                setExpenseModal(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
        {/* <input
        style={{
          width: "260px",
          height: "37px",
          borderRadius: "25px",
          borderColor: "#a0a0a0",
          borderWidth: "1px",
          boxShadow: "1px 1px 2px #3a353b",
          paddingLeft: "10px",
          marginRight:'10px'
        }}
        placeholder="Search Here...."
        onChange={(e) => 
          searchKeyword(e.target.value.trim())
        }
      /> */}
        <SearchBar
          placeholder="Search By Name/Emp ID"
          searchKeyword={searchKeyword}
        />
        {showDate && (
          <div
            style={{ marginRight: "15px", float: "right", marginLeft: "10px" }}
          >
            <Flatpickr
              className="form-control d-block"
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}

        <div
          style={{
            float: "right",
            width: "120px",
            marginRight: "20px",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) =>
              setDateValue(value.label, value.value, optionDates)
            }
            defaultValue={optionDates[0]}
            options={optionDates}
          />
        </div>

        <div
          style={{
            //float: "right",
            //margin: "10px",
            marginRight: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadExpenseData(0, searchKey)}
          >
            Submit
          </Button>
        </div>
        <div
          style={{
            //float: "right",
            //margin: "10px",
            marginRight: "5px",
            marginBottom: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            style={{
              // margin: "5px 10px 10px  ",
              // marginRight: "15px",
              // marginLeft: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="success btn-rounded"
            onClick={() => expenseDownloadData()}
          >
            <i className="fa fa-download"></i>&nbsp;&nbsp;Download
          </Button>
        </div>
        {loggedInUser.autoFinanceUser?.roleName === "MANAGER" && (
          <div
            style={{
              //float: "right",
              //margin: "10px",
              marginRight: "5px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
          >
            <Button
              style={{
                float: "right",
                // margin: "10px",
                marginBottom: "20px",
                // marginTop:'10px',
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => setExpenseHistoryModal(true)}
            >
              Expense History
            </Button>
          </div>
        )}
        <Modal className="fade" show={expenseHistoryModal} size="lg">
          <Modal.Header>
            <Modal.Title>Expense History</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setExpenseHistoryModal(false);
                setExpenseStatus("");
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <EmployeeExpense status={expenseStatus} />
          </Modal.Body>
        </Modal>
        {loggedInUser.autoFinanceUser?.roleName === "MANAGER" && (
          <Button
            style={{
              float: "right",
              // margin: "10px",
              marginBottom: "20px",
              // marginTop:'10px',
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => setExpenseModal(true)}
          >
            <b> + Add Expense </b>
          </Button>
        )}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px", marginTop: "-5px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={expenseList}
              columns={columns}
            />
          </div>
          {expenseList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" show={basicModal} size="lg">
        <Modal.Header>
          <Modal.Title>Expense Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
              setExpenseApprovalForAdmin(false);
              setExpenseApproval(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "20px" }}>
            <Row>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Expense Details</span>
                    <tr>
                      <SRLWrapper
                        options={options1}
                        style={{
                          height: "140px",
                          width: "140px",
                        }}
                      >
                        <img
                          style={{
                            height: "70px",
                            width: "80px",
                            cursor: "pointer",
                          }}
                          src={expenseDetailsList.images}
                          alt=""
                          className="img-fluid rounded-circle"
                        />
                      </SRLWrapper>
                    </tr>
                    <br></br>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.name
                          ? expenseDetailsList.name
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Expense Cost
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        <Row>
                          <Col>
                            {expenseDetailsList.expenseCost
                              ? expenseDetailsList.expenseCost
                              : "-"}{" "}
                          </Col>
                        </Row>
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Remarks
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.comments
                          ? expenseDetailsList.comments
                          : "-"}
                      </td>
                    </tr>
                    {expenseDetailsList.expenseStatus === "REJECTED" && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Rejection Reason
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            verticalAlign: "middle",
                          }}
                        >
                          {expenseDetailsList.reasonForReject
                            ? expenseDetailsList.reasonForReject
                            : "-"}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Expense Type
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.expenseType
                          ? expenseDetailsList.expenseType
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          color:
                            expenseDetailsList.expenseStatus === "ACCEPTED"
                              ? "green"
                              : expenseDetailsList.expenseStatus === "REJECTED"
                              ? "red"
                              : "orange",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.expenseStatus
                          ? expenseDetailsList.expenseStatus
                          : "-"}
                      </td>
                    </tr>
                    {expenseDetailsList.expenseStatus === "PENDING" &&
                      (expenseApproval === true ||
                        expenseApprovalForAdmin === true) &&
                      hasAccess(19) && (
                        <div>
                          <Button
                            style={{
                              float: "left",
                              marginTop: "10px",
                              marginRight: "25px",
                              fontFamily:
                                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                            }}
                            className="me-2"
                            variant="success btn-rounded"
                            onClick={() =>
                              updateExpenseRequest(
                                expenseDetailsList,
                                "PENDING"
                              )
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            style={{
                              float: "left",
                              marginTop: "10px",
                              marginRight: "15px",
                              fontFamily:
                                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                            }}
                            className="me-2"
                            variant="danger btn-rounded"
                            onClick={() => {
                              // updateExpenseRequest(expenseDetailsList, "REJECTED")
                              setRejectionReason(null);
                              setBasicModal2(true);
                            }}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
              </Col>

              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Executive Details
                    </span>
                    {/* <tr>
                      <SRLWrapper
                      options={options1}
                      style={{
                        height: "140px",
                        width: "140px",
                      }}
                    >
                        <img
                            style={{
                              height: "70px",
                              width: "80px",
                              cursor: "pointer",

                            }}
                              src={expenseDetailsList.image}
                            alt=""
                            className="img-fluid rounded-circle"
                          />
                          </SRLWrapper>
                          </tr> */}
                    <br></br>
                    <br></br>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.name
                          ? expenseDetailsList.name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Employee ID
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.employeeId
                          ? expenseDetailsList.employeeId
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Mobile
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.mobileNumber
                          ? expenseDetailsList.mobileNumber
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Email
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.email
                          ? expenseDetailsList.email
                          : "-"}
                      </td>
                    </tr>
                    <tr></tr>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal2} size="sm">
        <Modal.Header>
          <Modal.Title>Add Rejection Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal2(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
              <input
                style={{
                  width: "220px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter Reason"
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                  console.log("rejectionReason", rejectionReason);
                }}
              />
              <div style={{ marginTop: "5px" }}>
                <div>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2"
                    type="submit"
                    onClick={() =>
                      updateExpenseRequest(expenseDetailsList, "REJECTED")
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2 float-right"
                    variant="danger light"
                    onClick={() => setBasicModal2(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      {/* {toggle === "chatbox" && <VisitedPlaceDrawer expenseDetailsList={expenseDetailsList} closeDrawer={() => setToggle(false)} reLoad={() => loadExpenseData(currPage, searchKey)} />} */}
    </div>
  );
};

export default ExpenseManagement;
