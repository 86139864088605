import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
  Card,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { SRLWrapper } from "simple-react-lightbox";
// import { Link } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import EmployeeLeave from "../CommonComponents/EmployeeLeave";
import * as helper from "../CommonComponents/Helper";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import Attendance from "../Executives/Attendance";
import MyMapComponent from "../Executives/MyMapComponent";
import VisitedPlaces from "../Executives/VisitedPlaces";
import defaultImage from "../images/defaultImage.png";
import location_image from "../images/location_image.png";
// import { toaster } from "../rest/apiUtils";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess } from "../services/AuthService";
import Flatpickr from "react-flatpickr";
import ReactApexChart from "react-apexcharts";
import ExpenseDetails from "../Executives/ExpenseDetails";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";

function ManagerExecutiveDetails() {
  const [updateMgrModal, setUpdateMgrModal] = useState(false);
  const [managers, setManagers] = useState([]);
  const [loggedInUser, setloggedInUser] = useState([]);
  const [defaultMgr, setDefaultMgr] = useState([]);
  const [latlongsArr, setLatlongsArr] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [random, setRandom] = useState(Math.floor(Math.random() * 100));
  const [mutiManagers, setMutiManagers] = useState([]);
  const [isMap, setIsMap] = useState(false);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [showDate, setShowDate] = useState(false);
  const [metrics, setMetrics] = useState({
    collectionCount: 0,
    ptpCount: 0,
    paidCount: 0,
    feildInvestigationCount: 0,
    salesCount: 0,
    customerNotAvailableCount: 0,
  });

  var valIndex = 0;
  var start, end;
  var L1ManagerDetails = JSON.parse(localStorage.getItem("L1ManagerDetails"));

  
useEffect(() => {
  ReactGA.pageview('Manager Executive Details');
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const getUserVisitsCount = (start, end) => {
    var payLoad = {
      enddate: end,
      startdate: start,
      userId: executiveData.userId,
    };

    console.log("payload", payLoad);

    moduleApi.postData(apiCall.getuservisitscount, payLoad).then((response) => {
      setMetrics(response);
    });
  };

  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const setDateValue = (label, value) => {
    var date;

    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        setShowDate(false);

        getUserVisitsCount(start, end);

        return;
      case 2:
        var today = new Date();
        start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setShowDate(false);
        getUserVisitsCount(start, end);

        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 30)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);

        setShowDate(false);

        getUserVisitsCount(start, end);

        return;
      case 4:
        setShowDate(true);

        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  function setCustomDates(dateStrings) {
    if (dateStrings.length > 1) {
      start = Math.floor(dateStrings[0].getTime() / 1000);
      end = Math.floor(dateStrings[1].getTime() / 1000) + 86400;
    }
    getUserVisitsCount(start, end);
  }

  var config = {
    series: [
      metrics.salesCount,
      metrics.paidCount,
      metrics.ptpCount,
      metrics.customerNotAvailableCount,
      metrics.feildInvestigationCount,
    ],
    options: {
      chart: {
        type: "pie",
        width: 200,
      },
      labels: [
        "Sales",
        "Paid",
        "PTP",
        "Customer Not Available",
        "Field Investigation",
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          // console.log("val", val, opts, opts.w.config.series);
          return opts.w.config.series[opts.seriesIndex];
        },
      },
      // customScale: 1,
      stroke: {
        width: 0,
      },
      // green skyblue lightyellow lightpink
      // colors: ["#99cc00", "#61CFF1", "#999966", "#FFDA7C", "#E477FF", "#669999", "#e60073"],
      colors: ["#61CFF1", "#ff8000", "#E477FF", "#669999", "#FFDA7C"],
      plotOptions: {
        pie: {
          labels: {
            show: true,
          },
        },
      },
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              height: 380,
              width: 380,
            },
            legend: {
              position: "right",
              // horizontalAlign: 'center',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "black",
                fontWeight: "bold",
              },
            },
          },
        },
      ],
    },
  };

  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const [initVal, setInitVal] = useState({
    reportingOfficerIds: [],
    organizationId: "",
    subordinateId: "",
  });
  var history = useHistory();

  var executiveData = JSON.parse(localStorage.getItem("executiveDetails"));
  var managerDetails = JSON.parse(localStorage.getItem("managerDetails"));

  const [branches, setBranches] = useState([]);
  const [transferModal, setTransferModal] = useState(false);
  const [defaultReportingOfficer, setDefaultReportingOfficer] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState({
    value: "",
    label: "",
    branchId: "",
  });

  const [initialVal] = useState({
    branchId: executiveData.branchId,
    organizationId: "",
    subordinateId: "",
  });

  var defaultbranch = {
    value: executiveData.userId,
    label: executiveData.branchName,
    branchId: executiveData.branchId,
  };
  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);
    var payLoad = {
      branchId: "",
      organizationId: userData.autoFinanceUser.organizationId,
      reportOfficerId: "",
      roleName: "MANAGER",
      searchKey: "",
    };
    moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
      const mgrs = response;
      const size = mgrs.length;
      let managers = [];
      let defMgrs = [];
      for (let index = 0; index < size; index++) {
        const element = mgrs[index];
        const ele = {
          value: element.userId,
          label: element.name,
          branchId: element.branchId,
          isFixed:
          executiveData?.reportingOfficerId === element.userId ? true : false,
        };
        executiveData?.reportingOfficerIds?.find(
          (element) => element === ele.value
        ) && defMgrs.push(ele);
        managers.push(ele);
      }
      setInitVal({
        organizationId: executiveData.organizationId,
        subordinateId: executiveData.userId,
        reportingOfficerIds: executiveData.reportingOfficerIds,
      });
      setDefaultMgr(defMgrs);
      setValue(orderOptions(defMgrs));
      setManagers(managers);
    });
    loadManagers();
    handleChange(new Date());

    const body = {
      searchKey: "",
      organizationId: userData.autoFinanceUser.organizationId,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, body).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
      }));
      setBranches(result);
    });
    var date;
    date = [
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ),
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ),
    ];
    var start = Math.floor(date[0].getTime() / 1000);
    var end = Math.floor(date[1].getTime() / 1000);

    getUserVisitsCount(start, end);

     //Direct Reporting Officer
     moduleApi
     .postData(apiCall.getUser + executiveData.reportingOfficerId)
     .then((response) => {
       setDefaultReportingOfficer(response?.autoFinanceUser.name);
     });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadManagers = () => {
    var executiveDetails = JSON.parse(localStorage.getItem("executiveDetails"));
    var mgrIDs = executiveDetails.reportingOfficerIds;

    moduleApi.postData(apiCall.getMultipleUsers, mgrIDs).then((response) => {
      const result = response.map((item) => ({
        userId: item.autoFinanceUser.userId,
        name: item.autoFinanceUser.name,
        email: item.autoFinanceUser.email,
        contact: item.autoFinanceUser.mobileNumber,
        empId: item.autoFinanceUser.employeeId,
        branch: item.autoFinanceBranchDetails.branchName,
        image: item.autoFinanceUser.image,
        status: item.autoFinanceUser.status,
        designation: item.autoFinanceUser.companyDesignation,
      }));

      setMutiManagers(result);
    });
  };

  const getManagerIds = (list) => {
    let ids = [];
    const mgrs = list ? list : [];
    const size = mgrs.length;
    for (let index = 0; index < size; index++) {
      const element = mgrs[index];
      ids.push(element.value);
    }
    return ids;
  };

  const updateManagers = (values) => {
    moduleApi
      .postData(apiCall.updateReportingOfficer, values)
      .then((response) => {
        setUpdateMgrModal(false);
        swal("Successfully Updated", {
          icon: "success",
        })
        history.push("manager");
      });
  };

  const orderOptions = (values) => {
    return (
      values &&
      values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed))
    );
  };

  const [value, setValue] = useState(orderOptions([...managers]));

  const onChange = (value, { action, removedValue }, form) => {
    let temp = [];

    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          temp.push(removedValue);
          form.setFieldValue("reportingOfficerIds", getManagerIds(temp));
          swal("You Cannot Delete default Reporting Officer", {
            icon: "error",
            dangerMode: true,
          });
                    return;
        } else {
          form.setFieldValue("reportingOfficerIds", getManagerIds(value));
        }
        break;
      case "clear":
        value = managers.filter((v) => v.isFixed);
        form.setFieldValue("reportingOfficerIds", getManagerIds(value));
        swal("You Cannot Delete default Reporting Officer", {
          icon: "error",
          dangerMode: true,
        });
                break;
      case "select-option":
        form.setFieldValue("reportingOfficerIds", getManagerIds(value));
        break;
      default:
        break;
    }
    value = orderOptions(value);
    setValue(value);
  };

  const updateBranch = () => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    const payLoad = {
      newBranchId: selectedBranch.branchId,
      oldBranchId: executiveData.branchId,
      organizationId: executiveData.organizationId,
      transferedByUserId: userData.autoFinanceUser.userId,
      userId: executiveData.userId,
    };

    moduleApi.postData(apiCall.updateBranch, payLoad).then((response) => {
      swal("Transferred Successfully", {
        icon: "success",
      })
      setTransferModal(false);
      history.push("/manager");
    });
  };

  const validate = Yup.object({
    reportingOfficerIds: Yup.array().min(1, "Atleast 1 manager is required"),
  });

  const handleChange = (date) => {
    setStartDate(date);
    getData(date);
  };

  const getData = (start) => {
    var executiveDetails = JSON.parse(localStorage.getItem("executiveDetails"));
    var obj = {
      endTime: Math.round(start.setHours(23, 59, 59, 0) / 1000),
      userId: executiveDetails.userId,
      startTime: Math.round(start.setHours(0, 0, 0, 0) / 1000),
      organizationId: executiveDetails.organizationId,
    };
    moduleApi.postData(apiCall.getLocations, obj).then((res) => {
      var arr = [];
      setLatlongsArr([]);
      for (let index = 0; index < res.length; index++) {
        var latlngObj = [];
        for (let i = 0; i < res[index].gpsLocationDatas.length; i++) {
          var obj1 = {
            lat: res[index].gpsLocationDatas[i].x,
            lng: res[index].gpsLocationDatas[i].y,
          };
          latlngObj.push(obj1);
        }
        if (latlngObj.length > 0) {
          arr.push(latlngObj);
        }
      }
      setLatlongsArr(arr);
      setRandom(Math.floor(Math.random() * 1000));
    });
  };

  return (
    <div className="card" style={{ backgroundColor: "white" }}>
      <div style={{ marginRight: "30px" }}>
        {hasAccess(27) && (
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="danger btn-rounded"
            onClick={() =>
              //setDeleteModal(true)

              swal({
                title: "Delete",
                text: "Are you sure, you want to delete(Inactive) executive?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  var payLoad = {
                    organizationId: loggedInUser.autoFinanceUser.organizationId,
                    relivedByUserId: loggedInUser.autoFinanceUser.userId,
                    userId: executiveData.userId,
                  };
                  moduleApi
                    .postData(apiCall.deleteExecutive, payLoad)
                    .then((response) => {
                 
                      if (response?.Message === "User Relived Suceesfully") {
                        swal(executiveData.name, "Deleted successfully!", {
                          icon: "success",
                        });
                        history.push("/manager");
                      } else if (response?.message) {
                        // toaster("error", response?.message);
                        swal(executiveData.name, response?.message, {
                          icon: "error",
                          dangerMode: true,
                        });
                      } else {
                        swal("Something Went Wrong, Please Try Again Later", {
                          icon: "error",
                          dangerMode: true,
                        });
                      }
                    });
                }
              })
            }
          >
            Delete
          </Button>
        )}
        {hasAccess(22) && (
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => setUpdateMgrModal(true)}
          >
            Update Managers
          </Button>
        )}

        <Button
          style={{
            float: "left",
            margin: "10px",
            marginLeft: "20px",
            marginTop: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() =>
            executiveData.isL1Manager === true
              ? history.push("/l1-manager-details")
              : history.push("/manager-details")
          }
        >
          <i className="fa fa-arrow-left" />
        </Button>
        <span
          style={{
            float: "left",
            marginLeft: "10px",
            margin: "10px",
            marginTop: "16px",
            fontSize: "18px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            fontWeight: "bold",
          }}
        >
          {managerDetails.name ? managerDetails.name : "-"} /{" "}
          {executiveData.isL1Manager &&
            (L1ManagerDetails.name ? L1ManagerDetails.name : "-")}
          {executiveData.isL1Manager && "/ "}
          {executiveData.name ? executiveData.name : "-"}
        </span>
      </div>
      <div style={{ margin: "20px" }}>
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey="basicInfo">
            <Nav as="ul" className="nav-tabs-custom nav-justified">
              <Nav.Item as="li" key="0">
                <Nav.Link eventKey="basicInfo">
                  <b> Basic Info </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="1">
                <Nav.Link eventKey="attendance">
                  <b> Attendance </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="2">
                <Nav.Link eventKey="visitedPlaces">
                  <b> Visits </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="3">
                <Nav.Link eventKey="trackAttendance">
                  <b> Track Attendance </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="4">
                <Nav.Link eventKey="leave">
                  <b> Leaves </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="5">
                <Nav.Link eventKey="expense">
                  <b> Expense </b>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey="basicInfo" key="0">
                <div>
                  <div
                    className="card contact-bx item-content"
                    style={{
                      backgroundColor: "rgb(243,240,249)",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                  >
                    <div className="card-header border-0"></div>
                    <div className="card-body user-profile">
                      <div style={{ textAlign: "left" }}>
                        <h6 className="fs-20 font-w600 my-1">
                          Executive Details
                        </h6>
                      </div>
                      <Row>
                        <Col>
                          <table style={{ marginBottom: "15px" }}>
                            <>
                              <tr></tr>
                              <tr>
                                <td
                                  colSpan={2}
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <SRLWrapper options={options1}>
                                    <img
                                      style={{
                                        height: "100px",
                                        marginRight: "100px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                      src={
                                        executiveData.image
                                          ? executiveData.image
                                          : defaultImage
                                      }
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </SRLWrapper>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Employee ID
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveData.employeeId
                                    ? executiveData.employeeId
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Name
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveData.name
                                    ? executiveData.name
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Branch Name
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  <div>
                                    {executiveData.branchName
                                      ? executiveData.branchName
                                      : "-"}
                                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <i
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      className="fa fa-edit"
                                      onClick={() => setTransferModal(true)}
                                    /> */}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                }}
                              >
                                Direct Reporting Officer
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {defaultReportingOfficer
                                  ? defaultReportingOfficer
                                  : "-"}
                              </td>
                            </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Mobile
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveData.mobileNumber
                                    ? executiveData.mobileNumber
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Email
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveData.email
                                    ? executiveData.email
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Designation
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveData.designation
                                    ? executiveData.designation
                                    : "-"}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Status
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      executiveData.status === "ACTIVE"
                                        ? "green"
                                        : "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveData.status
                                    ? executiveData.status
                                    : "-"}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Battery Status
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  <div>
                                    {executiveData.batteryPercentage > 75 ? (
                                      <span
                                        style={{
                                          color: "green",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <i className="fas fa-battery-full fa-2x fa-rotate-0" />
                                      </span>
                                    ) : executiveData.batteryPercentage > 30 ? (
                                      <span
                                        style={{
                                          color: "orange",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <i className="fas fa-battery-three-quarters fa-2x fa-rotate-0 " />
                                      </span>
                                    ) : executiveData.batteryPercentage > 0 ? (
                                      <span
                                        style={{
                                          color: "red",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <i className="fas fa-battery-quarter fa-2x fa-rotate-0 " />
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <span
                                      style={{
                                        marginLeft:
                                          executiveData.batteryPercentage === 0
                                            ? "0px"
                                            : "3px",
                                      }}
                                    >
                                      {executiveData.batteryPercentage === 0
                                        ? "N/A"
                                        : executiveData.batteryPercentage + "%"}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Last updated Time
                                </td>
                                <td style={{ verticalAlign: "top" }}>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveData.gpsData ? (
                                    <p
                                      style={{
                                        cursor: "pointer",
                                        verticalAlign: "middle",
                                      }}
                                      title={helper.EpochToDate(
                                        executiveData.gpsData.t
                                      )}
                                    >
                                      {helper.EpochToDate(
                                        executiveData.gpsData.t
                                      )}
                                    </p>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Last Updated Location
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveData.gpsData ? (
                                    <img
                                      style={{
                                        height: "40px",
                                        width: "40px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setLatLng({
                                          lat: executiveData.gpsData.x,
                                          lng: executiveData.gpsData.y,
                                        });
                                        setIsMap(true);
                                      }}
                                      src={location_image}
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                              </tr>
                            </>
                          </table>
                        </Col>
                        <Col>
                          <Card
                            style={{ marginLeft: "50px", marginBottom: "50px" }}
                          >
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "end",
                                  marginBottom: "5px",
                                  marginTop: "20px",
                                }}
                              >
                                {showDate && (
                                  <div
                                    style={{
                                      marginRight: "10px",
                                      float: "right",
                                    }}
                                  >
                                    <Flatpickr
                                      className="form-control d-block"
                                      // placeholder="dd M,yyyy"
                                      placeholder="Start & End Date"
                                      onChange={(value) => {
                                        setCustomDates(value);
                                      }}
                                      options={{
                                        mode: "range",
                                        dateFormat: "d-m-y",
                                        maxDate: new Date(),
                                      }}
                                    />
                                  </div>
                                )}

                                <div
                                  style={{
                                    float: "right",
                                    width: "150px",
                                    marginRight: "10px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Select
                                    isSearchable={false}
                                    onChange={(value) =>
                                      setDateValue(
                                        value.label,
                                        value.value,
                                        optionDates
                                      )
                                    }
                                    defaultValue={optionDates[valIndex]}
                                    options={optionDates}
                                  />
                                </div>
                              </div>

                              {metrics.salesCount > 0 ||
                              metrics.collectionCount > 0 ||
                              metrics.feildInvestigationCount > 0 ? (
                                <div
                                  style={{
                                    // marginLeft: "50px",
                                    textAlign: "initial",
                                  }}
                                >
                                  <ReactApexChart
                                    // options={data.options}
                                    // series={data.series}
                                    options={config.options}
                                    series={config.series}
                                    type="pie"
                                    height={600}
                                    width={600}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    textAlign: "center",
                                    margin: "80px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <h5 style={{ fontSize: "15px" }}>
                                    No Data Found in Given Range
                                  </h5>
                                </div>
                              )}
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <h4>Reporting Officer Details</h4>
                  <Table responsive hover bordered>
                    <thead style={{ backgroundColor: "#F3F0F9" }}>
                      <tr>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Employee ID
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Name
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Email
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Contact
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Branch
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Designation
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mutiManagers.map((item) => {
                        return (
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={defaultImage}
                                  className="rounded-lg me-2"
                                  width="24"
                                  alt=""
                                />{" "}
                                <span className="w-space-no">{item.empId}</span>
                              </div>{" "}
                            </td>
                            {/* <td style={{ width: '150px', fontSize: '15px' }}>{item.empId}</td> */}
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.name}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.email}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.contact}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.branch}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.designation}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {mutiManagers.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                </div>
                {/* <Row>
                  <Col>
                    <div
                      className="card contact-bx item-content"
                      style={{ backgroundColor: "rgb(243,240,249)" }}
                    >
                      <div className="card-header border-0"></div>
                      <div className="card-body user-profile">
                        <img
                          //src={defaultImage}
                          style={{ height: "100px" }}
                          src={
                            executiveData.image
                              ? executiveData.image
                              : defaultImage
                          }
                          alt=""
                          className="img-fluid rounded-circle"
                        />

                        <div className="media-body user-meta-info">
                          <h6 className="fs-18 font-w600 my-1">
                            {executiveData.name ? executiveData.name : "-"}
                          </h6>
                          <h6 className="fs-16 font-w400 my-1">
                            {executiveData.mobileNumber
                              ? executiveData.mobileNumber
                              : "-"}
                          </h6>
                          <h6 className="fs-16 font-w400 my-1">
                            {executiveData.email ? executiveData.email : "-"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card contact-bx item-content"
                      style={{ backgroundColor: "rgb(243,240,249)" }}
                    >
                      <div className="card-body user-profile">
                        <div className="media-body user-meta-info">
                          <div
                            style={{
                              marginRight: "20px",
                              marginBottom: "15px",
                              marginTop: "10px",
                            }}
                          >
                            <h6 className="fs-18 font-w600 my-1">
                              Manager Details
                            </h6>
                          </div>
                          <table
                            style={{ marginBottom: "15px", marginLeft: "70px" }}
                          >
                            <>
                              <tr></tr>
                              <tr>
                                <td style={{ fontWeight: "500" }}>
                                  Branch Name
                                </td>
                                <td>&nbsp;:&nbsp; </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {executiveData.branchName
                                    ? executiveData.branchName
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "500" }}>Mobile</td>
                                <td>&nbsp;:&nbsp; </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {executiveData.phoneNumber
                                    ? executiveData.phoneNumber
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "500" }}>Email</td>
                                <td>&nbsp;:&nbsp; </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {executiveData.branchemail
                                    ? executiveData.branchemail
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "500" }}>Address</td>
                                <td>&nbsp;:&nbsp; </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {executiveData.branchLocation
                                    ? executiveData.branchLocation
                                    : "-"}
                                </td>
                              </tr>
                            </>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </Tab.Pane>
              <Tab.Pane eventKey="attendance" key="1">
                <Attendance userId={executiveData.userId} />
              </Tab.Pane>
              <Tab.Pane eventKey="visitedPlaces" key="2">
                <VisitedPlaces
                  userId={executiveData.userId}
                  organisationId={executiveData.organizationId}
                  reportingOfficerId={executiveData.reportingOfficerId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="trackAttendance" key="3">
                {/* <div style={{display:'flex'}}> */}
                <div
                  style={{
                    width: "200px",
                    marginLeft: "auto",
                    paddingLeft: "10px",
                    border: "1px solid",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      label=""
                      clearable
                      format="dd/MM/yyyy"
                      disableFuture
                      value={startDate}
                      onChange={handleChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <br></br>
                <MyMapComponent latlongsArr={latlongsArr} key={random} />
                {/* </div> */}
              </Tab.Pane>
              <Tab.Pane eventKey="leave" key="4">
                <EmployeeLeave userId={executiveData.userId} />
              </Tab.Pane>
              <Tab.Pane eventKey="expense" key="5" mountOnEnter>
                <ExpenseDetails
                  userId={executiveData.userId}
                  branchId={executiveData.branchId}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Visit Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={updateMgrModal}>
        <Modal.Header>
          <Modal.Title>Update Managers</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setValue(defaultMgr);
              setUpdateMgrModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              enableReinitialize
              initialValues={initVal}
              onSubmit={updateManagers}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <Field name="reportingOfficerIds">
                          {({ field, form }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Manager
                                </label>
                                <Select
                                  placeholder="Select a manager"
                                  classNamePrefix="select-box"
                                  options={managers}
                                  isMulti
                                  value={value}
                                  // isClearable={value && value.some(v => !v.isFixed)}
                                  defaultValue={defaultMgr}
                                  // onChange={(list) => {
                                  //   form.setFieldValue(
                                  //     "reportingOfficerIds",
                                  //     getManagerIds(list),

                                  //   )
                                  //   // onChange()
                                  // }}
                                  onChange={(
                                    value,
                                    { action, removedValue }
                                  ) => {
                                    onChange(
                                      value,
                                      { action, removedValue },
                                      form
                                    );
                                    // form.setFieldValue(
                                    //   "reportingOfficerIds",
                                    //   getManagerIds(value)
                                    // );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                          {errors.reportingOfficerIds && (
                            <span className="error">
                              {errors.reportingOfficerIds}
                            </span>
                          )}

                          <div>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2"
                              type="submit"
                            >
                              Submit
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2 float-right"
                              variant="danger light"
                              onClick={() => {
                                setValue(defaultMgr);
                                setUpdateMgrModal(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={transferModal}>
        <Modal.Header>
          <Modal.Title>Transfer Branch</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setTransferModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              enableReinitialize
              initialValues={initialVal}
              onSubmit={updateBranch}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <Field name="branchId">
                            {({ field, form }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Branch
                                </label>
                                <Select
                                  placeholder="Select a branch"
                                  classNamePrefix="select-box"
                                  options={branches}
                                  defaultValue={defaultbranch}
                                  onChange={(value) => {
                                    setSelectedBranch({
                                      value: value.value,
                                      label: value.label,
                                      branchId: value.branchId,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                          {errors.branchId && (
                            <span className="error">{errors.branchId}</span>
                          )}
                          <div style={{ marginTop: "10px" }}>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2"
                              type="submit"
                            >
                              Submit
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2 float-right"
                              variant="danger light"
                              onClick={() => setTransferModal(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ManagerExecutiveDetails;
