import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoadingOverlay from "react-loading-overlay";
import * as moduleApi from "../rest/moduleApi";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import { apiCall } from "../rest/restApi";
import { getTokenInLocalStorage } from "../services/AuthService";
import swal from "sweetalert";
import * as helper from "../CommonComponents/Helper";

const BranchwiseCollectionGraph = ({
  startDate,
  endDate,
  paymentMode,
  status2,
}) => {
  const [data, setData] = useState(null);
  // const [showDate, setShowDate] = useState(false);
  //   const [CollectionGraphData, setCollectionGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    loadBranchWiseCollectionGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const branchWiseToolTip = (
    series,
    seriesIndex,
    dataPointIndex,
    w,
    data2,
    bArr
  ) => {
    let data = [];
    series.forEach((item) => {
      data.push(item[dataPointIndex]);
    });
    let keys = [];
    let values = [];
    let tooltipdata =
      '<div style="margin-left:7px;margin-right:7px;margin-top:7px;font-size: 11px"> <ul>' +
      "<li ><b>Branch</b> : " +
      w.config.xaxis.categories[dataPointIndex] +
      "</li>" +
      "<li ><b>Total" +
      (status2 === "amounts" ? " Amount" : " Count") +
      "</b> : " +
      (status2 === "amounts"
        ? " ₹ " + bArr?.amountCollected[dataPointIndex]
        : bArr?.totalCount[dataPointIndex]) +
      "</li>";
    for (let value of Object.keys(data2)) {
      keys.push(value);
    }

    for (let value of Object.values(data2)) {
      values.push(value);
    }
    // console.log("props", keys, values);
    for (let i = 0; i < series.length; i++) {
      tooltipdata =
        tooltipdata +
        '<li style="margin-left:7px;margin-right:7px;margin-bottom:3px;font-size: 11px" ><b>' +
        keys[i] +
        "</b> : " +
        values[i][dataPointIndex] +
        "</li>" +
        "</ul></div>";
    }
    return tooltipdata;
  };

  // const ToolTip = (series, seriesIndex, dataPointIndex, w, data2) => {
  //   let data = [];
  //   series.forEach((item) => {
  //     data.push(item[dataPointIndex]);
  //   });
  //   console.log("props", data);
  //   return (
  //       '<div style="margin:7px;font-size: 11px"> <ul>' +
  //       "<li ><b>Branch</b> : " +
  //       w.config.xaxis.categories[dataPointIndex] +
  //       "</li>" +
  //       "<li><b>Paid</b> : " +
  //       data[0] +
  //       " </li>" +
  //       "<li><b>Collected Amount</b> : " +
  //       "₹ "+shortenAmount(data2.amountCollected[dataPointIndex]) +
  //       " </li>" +
  //       "<li><b>PTP</b> : " +
  //       data[1] +
  //       " </li>" +
  //       "</li>" +
  //       "<li><b>RTP</b> : " +
  //       data[2] +
  //       "</li>" +
  //       "</li>" +
  //       "<li><b>Repossesed</b> : " +
  //       data[3] +
  //       "</li>" +
  //       "<li><b>Vacant</b> : " +
  //       data[4] +
  //       "</li>" +
  //       "<li><b>Third Party</b> : " +
  //       data[5] +
  //       "</li>" +
  //       "<li><b>Customer Not Available</b> : " +
  //       data[6] +
  //       "</li>" +
  //       "</ul></div>"
  //     );
  // };

  const getPoints = (data) => {
    // console.log("dddd", data);
    var dataArr = [];
    for (let index = 0; index < data.branchName.length; index++) {
      var ele = {
        x: data.branchName[index],
        y:
          paymentMode !== "Paid"
            ? data?.totalCount[index]
            : status2 === "counts"
            ? data?.totalCount[index]
            : status2 === "amounts"
            ? data?.amountCollected[index]
            : data?.totalCount[index],
        marker: {
          size: 0,
          fillColor: "#fff",
          strokeColor: "red",
          radius: 2,
          cssClass: "apexcharts-custom-class",
        },
        label: {
          borderColor: "#FF4560",
          offsetY: 0,
          style: {
            color: "#fff",
            background: "#FF4560",
          },
          text:
            shortenAmount(
              paymentMode !== "Paid"
                ? data?.totalCount[index]
                : status2 === "counts"
                ? data?.totalCount[index]
                : status2 === "amounts"
                ? data?.amountCollected[index]
                : data?.totalCount[index]
            ) + "",
        },
      };
      dataArr.push(ele);
    }
    return dataArr;
  };

  const shortenAmount = (value) => {
    if (value <= 0) return "";
    let val = Math.abs(value);
    if (val >= 10 ** 3 && val < 10 ** 6) {
      val = (val / 1000).toFixed(0) + " K";
    } else if (val >= 10 ** 6) {
      val = (val / 1000000).toFixed(0) + " M";
    } else {
      // eslint-disable-next-line no-self-assign
      val = val;
    }
    return val;
  };

  const loadBranchWiseCollectionGraph = () => {
    setIsLoading(true);
    var collectionbranchPayLoad = {
      endDate: endDate,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportType: "Collections",
      startDate: startDate,
      status: paymentMode === "" ? "BranchWiseColllections" : paymentMode,
      paymentMode: paymentMode === "Paid" ? "Paid" : "",
      subStatus: paymentMode === "" ? "" : paymentMode,
    };
    moduleApi
      .postData(apiCall.branchWiseCount, collectionbranchPayLoad)
      .then((response) => {
        // console.log("branchWiseCollectionsResponse", response);
        if (response) {
          if (response.message) {
            return swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            // let keys = [];
            // let values = [];
            let temp1 = {};
            let paymentModesTemp = {};
            let paymentModeAmountsTemp = {};
            let subStatusTemp = {};

            const uniqueKeys = new Set();
            const uniqueKeys2 = new Set();
            const uniqueKeys3 = new Set();
            const uniqueKeys4 = new Set();

            // if (response[0]?.statusCounts !== null) {
            // for (let value of Object.keys(response[0]?.statusCounts)) {
            //   keys.push(value);
            // }

            response.forEach((obj) => {
              if (obj?.statusCounts !== null) {
                Object.keys(obj?.statusCounts).forEach((key) => {
                  uniqueKeys.add(key);
                });
              }
            });
            response?.forEach((obj) => {
              if (obj?.paymentModeCounts !== null) {
                Object.keys(obj?.paymentModeCounts).forEach((key) => {
                  uniqueKeys2.add(key);
                });
              }
            });
            response?.forEach((obj) => {
              if (obj?.paymentModeAndAmount !== null) {
                Object.keys(obj?.paymentModeAndAmount).forEach((key) => {
                  uniqueKeys3.add(key);
                });
              }
            });
            response?.forEach((obj) => {
              if (obj?.subStatusCounts !== null) {
                Object.keys(obj?.subStatusCounts).forEach((key) => {
                  uniqueKeys4.add(key);
                });
              }
            });

            let keys = Array.from(uniqueKeys);
            let paymentModesKeys = Array.from(uniqueKeys2);
            let paymentModeAmountsKeys = Array.from(uniqueKeys3);
            let subStatuskeys = Array.from(uniqueKeys4);

            for (let i = 0; i < keys.length; i++) {
              temp1[keys[i]] = [];
            }
            for (let i = 0; i < paymentModesKeys.length; i++) {
              paymentModesTemp[paymentModesKeys[i]] = [];
            }
            for (let i = 0; i < paymentModeAmountsKeys.length; i++) {
              paymentModeAmountsTemp[paymentModeAmountsKeys[i]] = [];
            }
            for (let i = 0; i < subStatuskeys.length; i++) {
              subStatusTemp[subStatuskeys[i]] = [];
            }
            // }

            // console.log("temp1", temp1);
            var bArr = {
              //   bankTransferCount: [],
              //   cashCount: [],
              //   collectRequestCount: [],
              amountCollected: [],
              //   collectionCount: [],
              // customerNotAvailbleCount: [],
              branchName: [],
              // otherCount: [],
              // paidCount: [],
              //   amountCollectedInBankTransfer: [],
              //   amountCollectedInCash: [],
              //   amountCollectedInCollectRequest: [],
              //   amountCollectedInOtherCount: [],
              //   amountCollectedInQrCode: [],
              // ptpCount: [],
              //   qrCodeCount: [],
              // repossessedCount: [],
              // rtpCount: [],
              // thirdPartyCount: [],
              // count: [],
              // vacantCount: [],
              totalCount: [],
            };
            // eslint-disable-next-line array-callback-return
            response?.map((item) => {
              bArr.branchName.push(item.branchName);
              // bArr.count.push(item.count);
              bArr.amountCollected.push(item.amountCollected.toFixed(0));
              // bArr.customerNotAvailbleCount.push(item.customerNotAvailbleCount);
              // bArr.otherCount.push(item.otherCount);
              for (let i = 0; i < Object.keys(temp1).length; i++) {
                temp1[keys[i]].push(
                  item?.statusCounts[keys[i]] !== undefined
                    ? item?.statusCounts[keys[i]]
                    : 0
                );
              }
              for (let i = 0; i < Object.keys(paymentModesTemp).length; i++) {
                paymentModesTemp[paymentModesKeys[i]].push(
                  item?.paymentModeCounts[paymentModesKeys[i]] !== undefined
                    ? item?.paymentModeCounts[paymentModesKeys[i]]
                    : 0
                );
              }
              for (
                let i = 0;
                i < Object.keys(paymentModeAmountsTemp).length;
                i++
              ) {
                paymentModeAmountsTemp[paymentModeAmountsKeys[i]].push(
                  item?.paymentModeAndAmount[paymentModeAmountsKeys[i]] !==
                    undefined
                    ? item?.paymentModeAndAmount[paymentModeAmountsKeys[i]]
                    : 0
                );
              }
              for (let i = 0; i < Object.keys(subStatusTemp).length; i++) {
                subStatusTemp[subStatuskeys[i]].push(
                  item?.subStatusCounts[subStatuskeys[i]] !== undefined
                    ? item?.subStatusCounts[subStatuskeys[i]]
                    : 0
                );
              }
              // bArr.paidCount.push(item.paidCount);
              // bArr.ptpCount.push(item.ptpCount);
              // bArr.repossessedCount.push(item.repossessedCount);
              // bArr.rtpCount.push(item.rtpCount);
              // bArr.thirdPartyCount.push(item.thirdPartyCount);
              // bArr.vacantCount.push(item.vacantCount);
              bArr.totalCount.push(item.totalCount);
            });

            // var s = startDate;
            // var e = endDate;

            // if (month) {
            //   var i = 0;
            //   while (i < 6) {
            //     var d = new Date();
            //     d.setDate(1);
            //     d.setHours(0, 0, 0, 0);
            //     d.setMonth(d.getMonth() - 6 + i);
            //     bArr.date.push();
            //     bArr.bankTransferCount.push(0);
            //     bArr.cashCount.push(0);
            //     bArr.collectRequestCount.push(0);
            //     bArr.collectedAmount.push(0);
            //     bArr.collectionCount.push(0);
            //     bArr.customerNotAvailbleCount.push(0);
            //     bArr.otherCount.push(0);
            //     bArr.paidCount.push(0);
            //     bArr.amountCollectedInBankTransfer.push(0);
            //     bArr.amountCollectedInCollectRequest.push(0);
            //     bArr.amountCollectedInCash.push(0);
            //     bArr.amountCollectedInOtherCount.push(0);
            //     bArr.amountCollectedInQrCode.push(0);
            //     bArr.ptpCount.push(0);
            //     bArr.qrCodeCount.push(0);
            //     bArr.repossessedCount.push(0);
            //     bArr.rtpCount.push(0);
            //     bArr.thirdParty.push(0);
            //     bArr.totalCount.push(0);
            //     bArr.vacantCount.push(0);
            //     i++;
            //   }
            // } else {
            //   while (e > s) {
            //     bArr.date.push(convertEpochTimeToDate(s));
            //     bArr.bankTransferCount.push(0);
            //     bArr.cashCount.push(0);
            //     bArr.collectRequestCount.push(0);
            //     bArr.collectedAmount.push(0);
            //     bArr.collectionCount.push(0);
            //     bArr.customerNotAvailbleCount.push(0);
            //     bArr.otherCount.push(0);
            //     bArr.paidCount.push(0);
            //     bArr.amountCollectedInBankTransfer.push(0);
            //     bArr.amountCollectedInCollectRequest.push(0);
            //     bArr.amountCollectedInCash.push(0);
            //     bArr.amountCollectedInOtherCount.push(0);
            //     bArr.amountCollectedInQrCode.push(0);
            //     bArr.ptpCount.push(0);
            //     bArr.qrCodeCount.push(0);
            //     bArr.repossessedCount.push(0);
            //     bArr.rtpCount.push(0);
            //     bArr.thirdParty.push(0);
            //     bArr.totalCount.push(0);
            //     bArr.vacantCount.push(0);
            //     s = s + 86400;
            //   }
            // }
            // if (response.length > 0) {
            //   for (const iterator of response) {
            //     var position = 0;
            //     while (position < bArr.date.length) {
            //       if (
            //         bArr.date[position] ===
            //         convertEpochTimeToDate(iterator.date, bArr.date.length)
            //       ) {
            //         bArr.bankTransferCount[position] =
            //           iterator.bankTransferCount;
            //         bArr.cashCount[position] = iterator.cashCount;
            //         bArr.collectRequestCount[position] =
            //           iterator.collectRequestCount;
            //         bArr.collectedAmount[position] =
            //           iterator.collectedAmount.toFixed(0);
            //         bArr.collectionCount[position] = iterator.collectionCount;
            //         bArr.customerNotAvailbleCount[position] =
            //           iterator.customerNotAvailbleCount;
            //         bArr.otherCount[position] = iterator.otherCount;
            //         bArr.paidCount[position] = iterator.paidCount;
            //         bArr.amountCollectedInBankTransfer[position] =
            //           iterator.amountCollectedInBankTransfer.toFixed(0);
            //         bArr.amountCollectedInCollectRequest[position] =
            //           iterator.amountCollectedInCollectRequest.toFixed(0);
            //         bArr.amountCollectedInCash[position] =
            //           iterator.amountCollectedInCash.toFixed(0);
            //         bArr.amountCollectedInOtherCount[position] =
            //           iterator.amountCollectedInOtherCount.toFixed(0);
            //         bArr.amountCollectedInQrCode[position] =
            //           iterator.amountCollectedInQrCode.toFixed(0);
            //         bArr.ptpCount[position] = iterator.ptpCount;
            //         bArr.qrCodeCount[position] = iterator.qrCodeCount;
            //         bArr.repossessedCount[position] = iterator.repossessedCount;
            //         bArr.rtpCount[position] = iterator.rtpCount;
            //         bArr.thirdParty[position] = iterator.thirdParty;
            //         bArr.totalCount[position] = iterator.totalCount;
            //         bArr.vacantCount[position] = iterator.vacantCount;
            //         position++;
            //         break;
            //       } else if (
            //         new Date(bArr.date[position]).getTime() / 1000 <
            //         iterator.date
            //       ) {
            //         position++;
            //       } else break;
            //     }
            //   }
            // }

            // console.log("bArr", bArr);
            // console.log("statusCounts", temp1);
            // console.log("paymentModeCounts", paymentModesTemp);
            // console.log("paymentModeAmountCounts", paymentModeAmountsTemp);
            // console.log("subStatusCounts", subStatusTemp);
            // console.log(
            //   "1) paymentMode - ",
            //   paymentMode,
            //   "2) status2 - ",
            //   status2
            // );
            if (paymentMode === "") {
              setData({
                series: getDynamicData(temp1),
                // series: [
                //   {
                //     name: "Paid",
                //     data: bArr.paidCount,
                //   },
                //   {
                //     name: "PTP",
                //     data: bArr.ptpCount,
                //   },
                //   {
                //     name: "RTP",
                //     data: bArr.rtpCount,
                //   },
                //   {
                //     name: "Repossesed",
                //     data: bArr.repossessedCount,
                //   },
                //   {
                //     name: "Vacant",
                //     data: bArr.vacantCount,
                //   },
                //   {
                //     name: "Third Party",
                //     data: bArr.thirdParty,
                //   },
                //   {
                //     name: "Customer Not Available",
                //     data: bArr.customerNotAvailbleCount,
                //   },
                // ],
                options: {
                  chart: {
                    type: "bar",
                    stacked: true,
                    height: 230,
                    toolbar: {
                      show: true,
                      tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                      },
                      export: {
                        csv: {
                          filename: "Branch Wise Collections Trend",
                          columnDelimiter: ',',
                          headerCategory: 'Branch',
                          headerValue: 'value',
                          dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                          }
                        },
                        svg: {
                          filename: bArr.graphName?bArr.graphName+'_'+helper.EpochToDate(Math.floor(new Date() / 1000)):'Preview Graph',
                        },
                        png: {
                          filename: bArr.graphName?bArr.graphName+'_'+helper.EpochToDate(Math.floor(new Date() / 1000)):'Preview Graph',
                        }
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: response.length > 3 ? "90%" : "20%",

                      dataLabels: {
                        position: "center",
                      },
                    },
                  },
                  // colors: [
                  //   "#09BD3C",
                  //   "#3349FF",
                  //   "#FC2E53",
                  //   "#A569BD",
                  //   "#138D75",
                  //   "#7F8C8D",
                  //   "#F4D03F",
                  // ],
                  colors: [
                    function ({ value, seriesIndex, w }) {
                      switch (w.config.series[seriesIndex].name) {
                        case "Paid":
                          return "#09BD3C";
                        case "PTP":
                          return "#728FCE";
                        case "Customer Not Available":
                          return "#F9966B";
                        case "Needs Attention":
                          return "#FFF380";
                        case "Third Party":
                          return "#D462FF";
                        case "Repossessed":
                          return "#757575";
                        case "Already Settled":
                          return "#7BCCB5";
                        case "Payment Failed":
                          return "#FC2E53";
                        case "Death Case":
                          return "#FC2E53";
                        case "Legal Case":
                          return "#A52A2A";
                        case "Contactable":
                          return "#F5A70A";
                        case "Not Contactable":
                          return "#F4ED0F";
                        default:
                          return "#3349FF";
                      }
                    },
                  ],
                  legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "top",
                    horizontalAlign: "left",
                    onItemHover: {
                      highlightDataSeries: true,
                    },
                    onItemClick: {
                      toggleDataSeries: false,
                    },
                  },
                  noData: {
                    text: "No Data Found",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: "#000000",
                      fontSize: "14px",
                      fontFamily: "Helvetica",
                    },
                  },
                  dataLabels: {
                    textAnchor: "middle",

                    enabled: true,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: "13px",
                      colors: ["#000000"],
                    },
                    formatter: function (value, opt) {
                      if (value <= 0) return "";
                      let val = Math.abs(value);
                      if (val >= 10 ** 3 && val < 10 ** 6) {
                        val = (val / 1000).toFixed(0) + " K";
                      } else if (val >= 10 ** 6) {
                        val = (val / 1000000).toFixed(0) + " M";
                      } else {
                        // eslint-disable-next-line no-self-assign
                        val = val;
                      }
                      return val;
                    },
                  },
                  annotations: {
                    points: getPoints(bArr),
                  },
                  stroke: {
                    show: false,
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  xaxis: {
                    show: true,
                    labels: {
                      show: true,
                      rotate: -45,
                      rotateAlways: true,
                      // formatter: (val) => xAxisLabel(props, val), // just formats date to hh:mm format
                    },
                    categories: bArr.branchName,
                    //   formatter: function(value){
                    //     return convertEpochTimeToDate(value, bArr?.date?.length )
                    //   }
                  },
                  tooltip: {
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      return branchWiseToolTip(
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                        temp1,
                        bArr
                      );
                    },
                    theme: "dark",
                  },
                  // eslint-disable-next-line no-dupe-keys
                  //   legend: {
                  //     position: "top",
                  //     offsetY: 0,
                  //     horizontalAlign: "right",
                  //   },
                },
              });
              setIsLoading(false);
            } else if (status2 === "counts") {
              setData({
                series: getDynamicData(paymentModesTemp),
                // series: [
                //   {
                //     name: "Cash",
                //     data: bArr.cashCount,
                //   },
                //   {
                //     name: "Bank Transfer",
                //     data: bArr.bankTransferCount,
                //   },
                //   {
                //     name: "QR Code",
                //     data: bArr.qrCodeCount,
                //   },
                //   {
                //     name: "UPI Payment",
                //     data: bArr.collectRequestCount,
                //   },
                //   {
                //     name: "Others",
                //     data: bArr.otherCount,
                //   },
                //   // {
                //   //   name: "Vacant",
                //   //   data: bArr.vacantCount,
                //   // },
                //   // {
                //   //   name: "Third Party",
                //   //   data: bArr.thirdParty,
                //   // },
                //   // {
                //   //   name: "Customer Not Available",
                //   //   data: bArr.customerNotAvailbleCount,
                //   // },
                // ],
                options: {
                  chart: {
                    type: "bar",
                    stacked: true,
                    height: 230,
                    toolbar: {
                      show: true,
                      tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                      },
                      export: {
                        csv: {
                          filename: "Branch Wise Collections Trend",
                          columnDelimiter: ',',
                          headerCategory: 'Branch',
                          headerValue: 'value',
                          dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                          }
                        },
                        svg: {
                          filename: bArr.graphName?bArr.graphName+'_'+helper.EpochToDate(Math.floor(new Date() / 1000)):'Preview Graph',
                        },
                        png: {
                          filename: bArr.graphName?bArr.graphName+'_'+helper.EpochToDate(Math.floor(new Date() / 1000)):'Preview Graph',
                        }
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: response.length > 3 ? "90%" : "20%",

                      dataLabels: {
                        position: "center",
                      },
                    },
                  },
                  colors: [
                    "#09BD3C",
                    "#3349FF",
                    "#F4D03F",
                    "#A569BD",
                    "#138D75",
                    "#922B21",
                    "#FC2E53",
                  ],
                  legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "top",
                    horizontalAlign: "left",
                    onItemHover: {
                      highlightDataSeries: true,
                    },
                    onItemClick: {
                      toggleDataSeries: false,
                    },
                  },
                  noData: {
                    text: "No Data Found",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: "#000000",
                      fontSize: "14px",
                      fontFamily: "Helvetica",
                    },
                  },
                  dataLabels: {
                    textAnchor: "middle",
                    horizontal: false,
                    enabled: true,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: "13px",
                      colors: ["#000000"],
                    },
                    formatter: function (value, opt, index) {
                      if (value <= 0) return "";
                      let val = Math.abs(value);
                      if (val >= 10 ** 3 && val < 10 ** 6) {
                        val = (val / 1000).toFixed(0) + " K";
                      } else if (val >= 10 ** 6) {
                        val = (val / 1000000).toFixed(0) + " M";
                      } else {
                        // eslint-disable-next-line no-self-assign
                        val = val;
                      }
                      // return val + "-  ₹ "+ shortenAmount(bArr.collectedAmount[opt.dataPointIndex].toFixed(0));
                      return val;
                    },
                  },
                  annotations: {
                    points: getPoints(bArr),
                  },
                  stroke: {
                    show: false,
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  xaxis: {
                    show: true,
                    labels: {
                      show: true,
                      rotate: -45,
                      rotateAlways: true,
                      // formatter: (val) => xAxisLabel(props, val), // just formats date to hh:mm format
                    },
                    categories: bArr.branchName,
                    //   formatter: function(value){
                    //     return convertEpochTimeToDate(value, bArr?.date?.length )
                    //   }
                  },
                  tooltip: {
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      return branchWiseToolTip(
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                        paymentModesTemp,
                        bArr
                      );
                    },
                    theme: "dark",
                  },
                  // eslint-disable-next-line no-dupe-keys
                  //   legend: {
                  //     position: "top",
                  //     offsetY: 0,
                  //     horizontalAlign: "right",
                  //   },
                },
              });
              setIsLoading(false);
            } else if (status2 === "amounts") {
              setData({
                series: getDynamicData(paymentModeAmountsTemp),
                // series: [
                //   {
                //     name: "Cash",
                //     data: bArr.amountCollectedInCash,
                //   },
                //   {
                //     name: "Bank Transfer",
                //     data: bArr.amountCollectedInBankTransfer,
                //   },
                //   {
                //     name: "QR Code",
                //     data: bArr.amountCollectedInQrCode,
                //   },
                //   {
                //     name: "UPI Payment",
                //     data: bArr.amountCollectedInCollectRequest,
                //   },
                //   {
                //     name: "Others",
                //     data: bArr.amountCollectedInOtherCount,
                //   },
                // ],
                options: {
                  chart: {
                    type: "bar",
                    stacked: true,
                    height: 230,
                    toolbar: {
                      show: true,
                      tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                      },
                      export: {
                        csv: {
                          filename: "Branch Wise Collections Trend",
                          columnDelimiter: ',',
                          headerCategory: 'Branch',
                          headerValue: 'value',
                          dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                          }
                        },
                        svg: {
                          filename: bArr.graphName?bArr.graphName+'_'+helper.EpochToDate(Math.floor(new Date() / 1000)):'Preview Graph',
                        },
                        png: {
                          filename: bArr.graphName?bArr.graphName+'_'+helper.EpochToDate(Math.floor(new Date() / 1000)):'Preview Graph',
                        }
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: response.length > 3 ? "90%" : "20%",

                      dataLabels: {
                        position: "center",
                      },
                    },
                  },
                  colors: [
                    "#09BD3C",
                    "#3349FF",
                    "#F4D03F",
                    "#A569BD",
                    "#138D75",
                    "#922B21",
                    "#FC2E53",
                  ],
                  legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "top",
                    horizontalAlign: "left",
                    onItemHover: {
                      highlightDataSeries: true,
                    },
                    onItemClick: {
                      toggleDataSeries: false,
                    },
                  },
                  noData: {
                    text: "No Data Found",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: "#000000",
                      fontSize: "14px",
                      fontFamily: "Helvetica",
                    },
                  },
                  dataLabels: {
                    textAnchor: "middle",
                    horizontal: false,
                    enabled: true,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: "13px",
                      colors: ["#000000"],
                    },
                    formatter: function (value, opt, index) {
                      if (value <= 0) return "";
                      let val = Math.abs(value);
                      if (val >= 10 ** 3 && val < 10 ** 6) {
                        val = (val / 1000).toFixed(0) + " K";
                      } else if (val >= 10 ** 6) {
                        val = (val / 1000000).toFixed(0) + " M";
                      } else {
                        // eslint-disable-next-line no-self-assign
                        val = val;
                      }
                      // return val + "-  ₹ "+ shortenAmount(bArr.collectedAmount[opt.dataPointIndex].toFixed(0));
                      return val;
                    },
                  },
                  annotations: {
                    points: getPoints(bArr),
                  },
                  stroke: {
                    show: false,
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  xaxis: {
                    show: true,
                    labels: {
                      show: true,
                      rotate: -45,
                      rotateAlways: true,
                      // formatter: (val) => xAxisLabel(props, val), // just formats date to hh:mm format
                    },
                    categories: bArr.branchName,
                    //   formatter: function(value){
                    //     return convertEpochTimeToDate(value, bArr?.date?.length )
                    //   }
                  },
                  tooltip: {
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      return branchWiseToolTip(
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                        paymentModeAmountsTemp,
                        bArr
                      );
                    },
                    theme: "dark",
                  },
                  // eslint-disable-next-line no-dupe-keys
                  //   legend: {
                  //     position: "top",
                  //     offsetY: 0,
                  //     horizontalAlign: "right",
                  //   },
                },
              });
              setIsLoading(false);
            } else {
              setData({
                series: getDynamicData(subStatusTemp),
                // series: [
                //   {
                //     name: "Paid",
                //     data: bArr.paidCount,
                //   },
                //   {
                //     name: "PTP",
                //     data: bArr.ptpCount,
                //   },
                //   {
                //     name: "RTP",
                //     data: bArr.rtpCount,
                //   },
                //   {
                //     name: "Repossesed",
                //     data: bArr.repossessedCount,
                //   },
                //   {
                //     name: "Vacant",
                //     data: bArr.vacantCount,
                //   },
                //   {
                //     name: "Third Party",
                //     data: bArr.thirdParty,
                //   },
                //   {
                //     name: "Customer Not Available",
                //     data: bArr.customerNotAvailbleCount,
                //   },
                // ],
                options: {
                  chart: {
                    type: "bar",
                    stacked: true,
                    height: 230,
                    toolbar: {
                      show: true,
                      tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                      },
                      export: {
                        csv: {
                          filename: "Branch Wise Collections Trend",
                          columnDelimiter: ',',
                          headerCategory: 'Branch',
                          headerValue: 'value',
                          dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                          }
                        },
                        svg: {
                          filename: bArr.graphName?bArr.graphName+'_'+helper.EpochToDate(Math.floor(new Date() / 1000)):'Preview Graph',
                        },
                        png: {
                          filename: bArr.graphName?bArr.graphName+'_'+helper.EpochToDate(Math.floor(new Date() / 1000)):'Preview Graph',
                        }
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: response.length > 3 ? "90%" : "20%",

                      dataLabels: {
                        position: "center",
                      },
                    },
                  },
                  colors: [
                    "#09BD3C",
                    "#3349FF",
                    "#FC2E53",
                    "#A569BD",
                    "#138D75",
                    "#7F8C8D",
                    "#F4D03F",
                  ],
                  legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "top",
                    horizontalAlign: "left",
                    onItemHover: {
                      highlightDataSeries: true,
                    },
                    onItemClick: {
                      toggleDataSeries: false,
                    },
                  },
                  noData: {
                    text: "No Data Found",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: "#000000",
                      fontSize: "14px",
                      fontFamily: "Helvetica",
                    },
                  },
                  dataLabels: {
                    textAnchor: "middle",

                    enabled: true,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: "13px",
                      colors: ["#000000"],
                    },
                    formatter: function (value, opt) {
                      if (value <= 0) return "";
                      let val = Math.abs(value);
                      if (val >= 10 ** 3 && val < 10 ** 6) {
                        val = (val / 1000).toFixed(0) + " K";
                      } else if (val >= 10 ** 6) {
                        val = (val / 1000000).toFixed(0) + " M";
                      } else {
                        // eslint-disable-next-line no-self-assign
                        val = val;
                      }
                      return val;
                    },
                  },
                  annotations: {
                    points: getPoints(bArr),
                  },
                  stroke: {
                    show: false,
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  xaxis: {
                    show: true,
                    labels: {
                      show: true,
                      rotate: -45,
                      rotateAlways: true,
                      // formatter: (val) => xAxisLabel(props, val), // just formats date to hh:mm format
                    },
                    categories: bArr.branchName,
                    //   formatter: function(value){
                    //     return convertEpochTimeToDate(value, bArr?.date?.length )
                    //   }
                  },
                  tooltip: {
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      return branchWiseToolTip(
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                        subStatusTemp,
                        bArr
                      );
                    },
                    theme: "dark",
                  },
                  // eslint-disable-next-line no-dupe-keys
                  //   legend: {
                  //     position: "top",
                  //     offsetY: 0,
                  //     horizontalAlign: "right",
                  //   },
                },
              });
              setIsLoading(false);
            }
            // setData({
            //   series: getDynamicData(temp1),
            //   // series: [
            //   //   {
            //   //     name: "Paid",
            //   //     data: bArr.paidCount,
            //   //   },
            //   //   {
            //   //     name: "PTP",
            //   //     data: bArr.ptpCount,
            //   //   },
            //   //   {
            //   //     name: "RTP",
            //   //     data: bArr.rtpCount,
            //   //   },
            //   //   {
            //   //     name: "Repossesed",
            //   //     data: bArr.repossessedCount,
            //   //   },
            //   //   {
            //   //     name: "Vacant",
            //   //     data: bArr.vacantCount,
            //   //   },
            //   //   {
            //   //     name: "Third Party",
            //   //     data: bArr.thirdPartyCount,
            //   //   },
            //   //   {
            //   //     name: "Customer Not Available",
            //   //     data: bArr.customerNotAvailbleCount,
            //   //   },
            //   // ],
            //   options: {
            //     chart: {
            //       type: "bar",
            //       stacked: true,
            //       height: 230,
            //       toolbar: {
            //         show: false,
            //       },
            //     },
            //     plotOptions: {
            //       bar: {
            //         horizontal: false,
            //         columnWidth: response.length > 3 ? "60%" : "30%",

            //         dataLabels: {
            //           position: "center",
            //         },
            //       },
            //     },
            //     colors: [
            //       "#09BD3C",
            //       "#3349FF",
            //       "#FC2E53",
            //       "#A569BD",
            //       "#138D75",
            //       "#7F8C8D",
            //       "#F4D03F",
            //     ],
            //     legend: {
            //       show: true,
            //       showForSingleSeries: true,
            //       showForNullSeries: true,
            //       showForZeroSeries: true,
            //       position: "top",
            //       horizontalAlign: "left",
            //       onItemHover: {
            //         highlightDataSeries: true,
            //       },
            //       onItemClick: {
            //         toggleDataSeries: false,
            //       },
            //     },
            //     noData: {
            //       text: "No Data Found",
            //       align: "center",
            //       verticalAlign: "middle",
            //       offsetX: 0,
            //       offsetY: 0,
            //       style: {
            //         color: "#000000",
            //         fontSize: "14px",
            //         fontFamily: "Helvetica",
            //       },
            //     },
            //     dataLabels: {
            //       textAnchor: "middle",

            //       enabled: true,
            //       offsetX: 0,
            //       offsetY: 0,
            //       style: {
            //         fontSize: "13px",
            //         colors: ["#000000"],
            //       },
            //       formatter: function (value, opt) {
            //         if (value <= 0) return "";
            //         let val = Math.abs(value);
            //         if (val >= 10 ** 3 && val < 10 ** 6) {
            //           val = (val / 1000).toFixed(0) + " K";
            //         } else if (val >= 10 ** 6) {
            //           val = (val / 1000000).toFixed(0) + " M";
            //         } else {
            //           // eslint-disable-next-line no-self-assign
            //           val = val;
            //         }
            //         return val;
            //       },
            //     },
            //     annotations: {
            //       points: getPoints(bArr),
            //     },
            //     stroke: {
            //       show: false,
            //     },
            //     yaxis: {
            //       lines: {
            //         show: false,
            //       },
            //     },
            //     xaxis: {
            //       show: true,
            //       labels: {
            //         show: true,
            //         rotate: -45,
            //         rotateAlways: true,
            //         // formatter: (val) => xAxisLabel(props, val), // just formats date to hh:mm format
            //       },
            //       categories: bArr.branchName,
            //       //   formatter: function(value){
            //       //     return convertEpochTimeToDate(value, bArr?.date?.length )
            //       //   }
            //     },
            //     tooltip: {
            //       custom: function ({
            //         series,
            //         seriesIndex,
            //         dataPointIndex,
            //         w,
            //       }) {
            //         return branchWiseToolTip(
            //           series,
            //           seriesIndex,
            //           dataPointIndex,
            //           w,
            //           temp1
            //         );
            //       },
            //       theme: "dark",
            //     },
            //     // eslint-disable-next-line no-dupe-keys
            //     //   legend: {
            //     //     position: "top",
            //     //     offsetY: 0,
            //     //     horizontalAlign: "right",
            //     //   },
            //   },
            // });
            // setIsLoading(false);
          }
        }
      });
  };

  const getDynamicData = (seriesData) => {
    let keys = [];
    let values = [];
    let graphData = [];
    for (let value of Object.keys(seriesData)) {
      keys.push(value);
    }

    for (let value of Object.values(seriesData)) {
      values.push(value);
    }

    for (let i = 0; i < Object.keys(seriesData).length; i++) {
      let result = {
        name: keys[i].toString(),
        data: values[i],
      };
      graphData.push(result);
    }
    // console.log("graphData", graphData);

    return graphData;
  };

  //   function convertEpochTimeToDate(epochTime, len) {
  //     var months_arr = [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ];
  //     var date_str = "";
  //     var date = new Date(parseInt(epochTime * 1000));
  //     var day = date.getDate();
  //     var month = months_arr[date.getMonth()];
  //     var year = date.getFullYear();
  //     if (len === 6) return month + " " + year;
  //     date_str = month + " " + day + "," + year;
  //     return date_str;
  //   }
  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div id="chart" className="line-chart-style bar-chart">
        {data?.options && (
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="bar"
            height={400}
          />
        )}
      </div>
    </LoadingOverlay>
  );
};

export default BranchwiseCollectionGraph;
