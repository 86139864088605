import { getApp, getApps, initializeApp } from "firebase/app"; 
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getMessaging} from "firebase/messaging";

//Vastu
// export const firebaseVapidKey =
//   "BMdXm0B2jXsvYtdrvQwyWsrdBOV_OApwzHMFloL996jlpMrBXGzr5X9f7Q52gR9QzBW0fVh7nA_1Y6z2CAzoFVA";
// const firebaseConfig = {
//   apiKey: "AIzaSyDPexqP_7LP4QklF8ZPdAsVV4HHgVr8-_c",
//   authDomain: "clu-cloud-f0b93.firebaseapp.com",
//   databaseURL: "https://clu-cloud-f0b93-default-rtdb.firebaseio.com",
//   projectId: "clu-cloud-f0b93",
//   storageBucket: "clu-cloud-f0b93.appspot.com",
//   messagingSenderId: "115074287360",
//   appId: "1:115074287360:web:d0879cde75edd0306dd5e5",
//   measurementId: "G-WT2MBG922D",
// };

//QA
// export const firebaseVapidKey =
//   "BLLCSLlrdofwQ0ypidfpA8knFpzqHxh-R_kkTmMlOAnbE3jP6IPKOtlXU1iEjUeqOfJEjlEyX1ZjBI8la-C8xnA";
// const firebaseConfig = {
//     apiKey: "AIzaSyB8atYr2xkwGkmqfhSkui9FHk6TLybPs4Y",
//     authDomain: "telecalling-qa.firebaseapp.com",
//     databaseURL:
//       "https://telecalling-qa-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "telecalling-qa",
//     storageBucket: "telecalling-qa.appspot.com",
//     messagingSenderId: "918263194810",
//     appId: "1:918263194810:web:b3d99ba9f89a3cfcfb4e83",
//     measurementId: "G-HG128X9HWW",
//   };
  
  //Prod
  export const firebaseVapidKey = "BCMh95OwYC2f2NEiokGhZQX_SILHB8nb_c0v_6sR5X5ScJFokgtYGAfG5Ap0Vqt10jnGo5B-A_D5bE04DDMNLKw"
  const firebaseConfig = {
    apiKey: "AIzaSyBoVWUmadPQg52h6iql2ru-_OPzVpvT2Z8",
    authDomain: "telecalling-prod-65201.firebaseapp.com",
    projectId: "telecalling-prod-65201",
    databaseURL: "https://telecalling-prod-65201-default-rtdb.asia-southeast1.firebasedatabase.app",
    storageBucket: "telecalling-prod-65201.appspot.com",
    messagingSenderId: "1075727068041",
    appId: "1:1075727068041:web:7b15801b733d8d55ed0f58",
    // measurementId: "G-HG128X9HWW"
  };
  
  // Initialize Firebase
  const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
  const firestore = getFirestore(app);
  export const database = getDatabase(app);
  export const messaging = getMessaging(app);
 
export default firestore;