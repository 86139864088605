import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import TextField from "../CommonComponents/TextField";
// import { toaster } from "../rest/apiUtils";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";
import ReactGA from "react-ga";

const AddManager = ({ close }) => {
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const phoneRegExp = "[1-9]{1}[0-9]{9}";
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var namePattern = "^[a-zA-Z]";
  var nameRegex = "[a-zA-Z ]+$";
  var specialCharacterEmployeeIdPattern = "^[a-zA-Z0-9]+$";
  const [loggedInUser, setloggedInUser] = useState([]);
  const [addNewBranch, setAdddNewBranch] = useState(false);
  const [branches, setBranches] = useState([]);
  const [managers, setManagers] = useState([]);
  const [states, setStates] = useState([]);
  const [error, setError] = useState(null);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    ReactGA.pageview("Add Manager");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);

    const payLoad = {
      searchKey: "",
      organizationId: userData.autoFinanceUser.organizationId,
      pagination: false,
    };
    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
        branchName: item.branchName,
      }));
      setBranches(result);
    });

         //Designations List
         moduleApi
         .getData(
           apiCall.getDesignations + userData.autoFinanceUser.organizationId
         )
         .then((response) => {
           let designation = [];
           for (let i = 0; i < response.length; i++) {
             const result = {
               value: response[i],
               label: response[i],
             };
             designation.push(result);
           }
           setDesignations(designation);
         });
    

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    const payLoad = {
      branchId: "",
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: "ADMIN_MANAGER",
    };
    moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.userId,
        label: item.name + " - " + getBranch(item.branchId),
        branchId: item.branchId,
      }));
      setManagers(result);
    });

    // eslint-disable-next-line
  }, [branches]);

  const getBranch = (branchId) => {
    for (let i = 0; i < branches?.length; i++) {
      if (branches[i].branchId === branchId) {
        console.log("branchName", branches[i].branchName);
        return branches[i].branchName;
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://countriesnow.space/api/v0.1/countries/states",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            country: "India",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch states");
      }
      const data = await response.json();
      console.log("resposse", data?.data?.states);
      const result = data?.data?.states.map((item) => ({
        value: item.name,
        label: item.name,
      }));
      setStates(result);
      console.log(error);
    } catch (error) {
      setError(error.message);
    }
  };

  const validate = Yup.object({
    email: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required"),
    employeeId: Yup.string()
      .matches(
        specialCharacterEmployeeIdPattern,
        "Please Enter Valid a Employee ID"
      )
      .required("Manager ID is required"),
    name: Yup.string()
      .trim()
      .matches(nameRegex, "Please Enter Valid Name")
      .required("Manager Name is required"),
    branchLocation: Yup.string().when([], {
      is: () => addNewBranch,
      then: Yup.string()
        // .matches(namePattern, "Please Enter Valid Address")
        .required("State is required"),
      otherwise: Yup.string().notRequired(),
    }),
    branchName: Yup.string().when([], {
      is: () => addNewBranch,
      then: Yup.string()
        .matches(namePattern, "Please Enter Valid Branch Name")
        .required("Branch is required"),
      otherwise: Yup.string().notRequired(),
    }),
    companyDesignation: Yup.string()
      // .matches(namePattern, "Please Enter a Valid Designation")
      .required("Designation is required"),
    reportingOfficerId: Yup.string().required("Reporting Officer is required"),
    branchId: Yup.string().when([], {
      is: () => !addNewBranch,
      then: Yup.string().required("Branch is required"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const handleOk = (values) => {
    const existingBranchPayLoad = {
      addedByUserId: loggedInUser.autoFinanceUser.userId,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      email: values.email,
      employeeId: values.employeeId,
      mobileNumber: values.mobileNumber,
      name: values.name,
      area: values.area,
      branchId: values.branchId,
      reportingOfficerId: values.reportingOfficerId,
      companyDesignation: values.companyDesignation,
      image: "",
      roleName: "MANAGER",
    };

    const newBranchPayLoad = {
      autoFinanceUser: {
        addedByUserId: loggedInUser.autoFinanceUser.userId,
        organizationId: loggedInUser.autoFinanceUser.organizationId,
        email: values.email,
        employeeId: values.employeeId,
        mobileNumber: values.mobileNumber,
        name: values.name,
        reportingOfficerId: values.reportingOfficerId,
        companyDesignation: values.companyDesignation,
        roleName: "MANAGER",
      },
      branchLocation: values.branchLocation,
      branchName: values.branchName,
      area: values.area,
      reportingOfficerId: values.reportingOfficerId,
      branchemail: values.email,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      phoneNumber: values.mobileNumber,
    };

    console.log("pay", existingBranchPayLoad);

    moduleApi
      .postData(
        addNewBranch ? apiCall.saveManager : apiCall.saveExecutive,
        addNewBranch ? newBranchPayLoad : existingBranchPayLoad
      )
      .then((response) => {
        if (response) {
          if (
            response?.Message === "User Added SuccessFully" ||
            response?.Message === "Branch Added Successfully"
          ) {
            close();
            // toaster('success', 'Successfully Added')
            swal("Successfully Added", {
              icon: "success",
            });
          } else if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  // const onBranchTypeChange = (type) => {
  //     switch (type) {
  //         case 1:
  //             setAdddNewBranch(true)
  //             break;
  //         default:
  //             setAdddNewBranch(false)
  //             break;
  //     }
  // }

  return (
    <div>
      <Formik
        initialValues={{
          addedByUserId: "",
          branchId: "",
          email: "",
          employeeId: "",
          image: "",
          mobileNumber: "",
          name: "",
          area: "",
          organizationId: "",
          reportingOfficerId: "",
          roleName: "EXECUTIVE",
          branchLocation: "",
          branchName: "",
          companyDesignation: "",
        }}
        onSubmit={handleOk}
        validationSchema={validate}
      >
        {({ formik, errors }) => (
          <div>
            <Row>
              <Col lg="12">
                <Form>
                  <div>
                    <div style={{ display: "flex", height: "40px" }}>
                      <div
                        class="form-check"
                        onClick={() => setAdddNewBranch(false)}
                        style={{ margin: "0px 20px" }}
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={!addNewBranch}
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Existing Branch
                        </label>
                      </div>
                      <div
                        class="form-check"
                        onClick={() => setAdddNewBranch(true)}
                        style={{ margin: "0px 20px" }}
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={addNewBranch}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          New Branch
                        </label>
                      </div>
                    </div>
                    {!addNewBranch ? (
                      <Field name="branchId">
                        {({ field, form }) => (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label
                              className="control-label"
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                marginBottom: "10px",
                              }}
                            >
                              Branch
                            </label>
                            <Select
                              placeholder="Select existing branch"
                              classNamePrefix="select-box"
                              options={branches}
                              onChange={(selectedOption) => {
                                form.setFieldValue(
                                  "branchId",
                                  selectedOption.branchId
                                );
                              }}
                            />
                          </div>
                        )}
                      </Field>
                    ) : (
                      <TextField
                        isAsterisk={true}
                        label="New Branch"
                        name="branchName"
                        type="text"
                      />
                    )}
                    {!addNewBranch && errors.branchId && (
                      <span className="error">{errors.branchId}</span>
                    )}
                    {addNewBranch && (
                      // <TextField
                      //   isAsterisk={true}
                      //   label="State"
                      //   name="branchLocation"
                      //   type="text"
                      // />
                      <div>
                        <Field name="branchLocation">
                          {({ field, form }) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "5px",
                              }}
                            >
                              <label
                                className="control-label"
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  marginBottom: "10px",
                                }}
                              >
                                State
                              </label>
                              <Select
                                placeholder="Select State"
                                classNamePrefix="select-box"
                                options={states}
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    "branchLocation",
                                    selectedOption.value
                                  );
                                }}
                              />
                            </div>
                          )}
                        </Field>

                        {errors.branchLocation && (
                          <span className="error">{errors.branchLocation}</span>
                        )}
                      </div>
                    )}

                    <Field name="reportingOfficerId">
                      {({ field, form }) => (
                        <div
                          style={{ display: "flex", flexDirection: "column",marginBottom: "5px", }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "5px",
                              marginTop: "5px",
                            }}
                          >
                            Reporting Officer
                          </label>
                          <Select
                            placeholder="Select Reporting Officer"
                            classNamePrefix="select-box"
                            options={managers}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "reportingOfficerId",
                                selectedOption.value
                              );
                              form.setFieldValue(
                                "reportingOfficerId",
                                selectedOption.value
                              );
                              // getBranchName(selectedOption.branchId)
                            }}
                          />
                        </div>
                      )}
                    </Field>
                    {errors.reportingOfficerId && (
                      <span className="error">{errors.reportingOfficerId}</span>
                    )}

<TextField
                      isAsterisk={false}
                      label="Area"
                      name="area"
                      type="text"
                    />

                    <TextField
                      isAsterisk={true}
                      label="Manager Name"
                      name="name"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Employee ID"
                      name="employeeId"
                      type="text"
                    />
                    <Field name="companyDesignation">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Designation
                          </label>
                          <Select
                            placeholder="Select designation"
                            classNamePrefix="select-box"
                            options={designations}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "companyDesignation",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>
                    {errors.companyDesignation && (
                      <span className="error">{errors.companyDesignation}</span>
                    )}
                    <TextField
                      isAsterisk={true}
                      label="Mobile No"
                      name="mobileNumber"
                      maxlength="10"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Email ID"
                      name="email"
                      type="text"
                    />
                  </div>
                  <div>
                    <Button
                      style={{ float: "right" }}
                      className="col-lg-2 m-2"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="col-lg-2 m-2 float-right"
                      variant="danger light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddManager;
