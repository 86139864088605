import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CannedReportsConfig } from "../CommonComponents/CannedReportsConfig";
import { CSVLink } from "react-csv";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "react-loading-overlay";
import NO_DATA_FOUND from "../images/no-data-found.png";

// import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import { CsvToHtmlTable } from "react-csv-to-table";
import Papa from "papaparse";
import "./Canned.css";
import Select from "react-select";
import * as nodeApi from "../rest/nodeApi";
// import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import ReactGA from "react-ga";

// import DataTable from 'react-data-table-component';

const CannedReports = () => {
  const [csvData, setCsvData] = useState("");
  // const [tableDataItems, setTableDataItems] = useState([]);
  const [dataIndex, setDataIndex] = useState(0);
  const [showDate, setShowDate] = useState(false);
  const [isTable, setIsTable] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isHeading, setIsHeading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDataSrc, setSelectedDataSrc] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [isMonth, setIsMonth] = useState(false);
  const [isCustomMonth, setIsCustomMonth] = useState(false);
  const [customMonths, setCustomMonths] = useState(null);
  const [startDate, setStartDate] = useState(
    0
    // Math.floor(
    //   new Date(
    //     new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
    //   ).getTime() / 1000
    // )
  );
  const [endDate, setEndDate] = useState(
    0
    // Math.floor(
    //   new Date(
    //     new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
    //   ).getTime() / 1000
    // )
  );

  // const columns = useMemo(
  // 	() => [

  // 		{
  // 			name: 'Employee Name',
  // 			selector: row => row.name,
  // 			sortable: true,
  // 			grow: 2,
  // 		},
  // 		{
  // 			name: 'Branch',
  // 			selector: row => row.branch_name,
  // 			sortable: true,
  // 		},
  // 		{
  // 			name: 'Role Name',
  // 			selector: row => row.role_name,
  // 			sortable: true,
  // 			right: true,
  // 		},
  // 		{
  // 			name: 'Distance Travelled',
  // 			selector: row => row.distance_travelled,
  // 			sortable: true,
  // 			right: true,
  // 		},
  // 		{
  // 			name: 'Total Login Time',
  // 			selector: row => row.total_login_time,
  // 			sortable: true,
  // 			right: true,
  // 		},
  // 		{
  // 			name: 'Total Check-Ins',
  // 			selector: row => row.total_checkins,
  // 			sortable: true,
  // 			right: true,
  // 		},

  // 	],
  // 	[],
  // );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCustomMonths();
    // getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionDates = [
    { label: "Today", value: 1 },
    { label: "Last 7 Days", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  // const monthOptions = [
  //   { label: "January", value: "January" },
  //   { label: "February", value: "February" },
  //   { label: "March", value: "March" },
  //   { label: "April", value: "April" },
  //   { label: "May", value: "May" },
  //   { label: "June", value: "June" },
  //   { label: "July", value: "July" },
  //   { label: "August", value: "August" },
  //   { label: "September", value: "September" },
  //   { label: "October", value: "October" },
  //   { label: "November", value: "November" },
  //   { label: "December", value: "December" },
  // ];

  const monthOptions2 = [
    { label: "All", value: 1 },
    { label: "Current Month", value: 2 },
    { label: "Last 3 Months", value: 3 },
    { label: "Custom Month", value: 4 },
  ];

  const getLastMonths = (n) => {
    var m = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var last_n_months = [];
    var d = new Date();
    for (var i = 0; i < n; i++) {
      last_n_months[i] = m[d.getMonth()];
      d.setMonth(d.getMonth() - 1);
    }
    return last_n_months;
  };

  const getMonthOptions = (label, value) => {
    console.log("value", value);

    switch (value) {
      // All
      case 1:
        setSelectedMonth(getLastMonths(4));
        setIsCustomMonth(false);

        return;

      // Current Month
      case 2:
        setSelectedMonth(getLastMonths(1));
        setIsCustomMonth(false);

        return;

      //Last 3 Months
      case 3:
        let temp = getLastMonths(4);
        temp.splice(0, 1);
        console.log("months", temp);
        setSelectedMonth(temp);
        setIsCustomMonth(false);
        return;

      // Custom Month
      case 4:
        setIsCustomMonth(true);
        break;
      default:
    }
  };

  const getCustomMonths = () => {
    let temp = getLastMonths(4);
    temp.splice(0, 1);
    console.log("months", temp);
    const result = temp.map((ele) => ({
      label: ele,
      value: ele,
    }));
    setCustomMonths(result);
  };

  const DataSourceOptions = [
    {
      label: "Employee Performance By Visits",
      value: CannedReportsConfig.employeeperformance,
    },
    {
      label: "Employee Performance By Collected Amount",
      value: CannedReportsConfig.employeeperformanceamount,
    },
    {
      label: "Number of Check-In Counts",
      value: CannedReportsConfig.nocheckins,
    },
    {
      label: "Unique Customer Visit Counts",
      value: CannedReportsConfig.uniquecustomervisits,
    },
    {
      label: "Customer Not Available Counts",
      value: CannedReportsConfig.customernotavailable,
    },
  ];

  function camelize(str) {
    const a = str
      .toLowerCase()
      .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? " " + c.toUpperCase() : ""));
    return a.substring(0, 1).toUpperCase() + a.substring(1);
  }

  //   const setGraphDates = (value) => {
  //     var date;
  //     var start, end;

  //     switch (value) {
  //       case "1":
  //         date = [
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0)
  //           ),
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate())).setHours(
  //               23,
  //               59,
  //               59
  //             )
  //           ),
  //         ];
  //         start = Math.floor(date[0].getTime() / 1000);
  //         end = Math.floor(date[1].getTime() / 1000);
  //         console.log("start", start);
  //         console.log("end", end);
  //         setStartDate(start);
  //         setEndDate(end);
  //         getUrlData( start, end);
  //         return;

  //       case "2":
  //         //last 7 days
  //         date = [
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
  //               0,
  //               0,
  //               0
  //             )
  //           ),
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate())).setHours(
  //               23,
  //               59,
  //               59
  //             )
  //           ),
  //         ];
  //         start = Math.floor(date[0].getTime() / 1000);
  //         end = Math.floor(date[1].getTime() / 1000);
  //         console.log("start", start);
  //         console.log("end", end);
  //         setStartDate(start);
  //         setEndDate(end);
  //         getUrlData( start, end);
  //         return;
  //       case "3":
  //         //Last month Logic
  //         // eslint-disable-next-line no-redeclare
  //         date = [
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
  //               0,
  //               0,
  //               0
  //             )
  //           ),
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate())).setHours(
  //               23,
  //               59,
  //               59
  //             )
  //           ),
  //         ];
  //         start = Math.floor(date[0].getTime() / 1000);
  //         end = Math.floor(date[1].getTime() / 1000);
  //         console.log("start", start);
  //         console.log("end", end);
  //         setStartDate(start);
  //         setEndDate(end);
  //         getUrlData(start, end);
  //         break;
  //       case "4":
  //         //last 90 days
  //         // eslint-disable-next-line no-redeclare
  //         date = [
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate() - 89)).setHours(
  //               0,
  //               0,
  //               0
  //             )
  //           ),
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate())).setHours(
  //               23,
  //               59,
  //               59
  //             )
  //           ),
  //         ];
  //         start = Math.floor(date[0].getTime() / 1000);
  //         end = Math.floor(date[1].getTime() / 1000);
  //         console.log("start", start);
  //         console.log("end", end);
  //         setStartDate(start);
  //         setEndDate(end);
  //         getUrlData( start, end);
  //         break;
  //       default:
  //       // console.log("end");
  //     }
  //   };

  const setDateValue = (label, value) => {
    console.log("value", value);
    var date;
    var start, end;
    switch (value) {
      case 1:
        var today = new Date();
        start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStartDate(start);
        setEndDate(end);
        setShowDate(false);
        setIsCustomMonth(false);
        return;

      case 2:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);

        setStartDate(start);
        setEndDate(end);
        setShowDate(false);
        setIsCustomMonth(false);
        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 30)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        setStartDate(start);
        setEndDate(end);
        setShowDate(false);
        setIsCustomMonth(false);
        return;
      case 4:
        setShowDate(true);
        setIsCustomMonth(false);
        break;
      default:
    }
  };

  function setCustomDates(dateStrings) {
    if (!dateStrings[1]) return;
    setStartDate(Math.floor(new Date(dateStrings[0]).getTime() / 1000));
    setEndDate(Math.floor(new Date(dateStrings[1]).getTime() / 1000) + 86399);
    setIsCustomMonth(false);
  }

  async function getUrlData(start, end) {
    if (selectedDataSrc === null) {
      return swal("Please Select Data", {
        icon: "error",
        dangerMode: true,
      });
    }
    setIsLoading(true);
    let temp = [];
    // let temp2 = [];
    var body = {
      fileName: selectedDataSrc?.value,
    };

    console.log("body", body);

    nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
      // console.log("presignedurlresponse", response);

      Papa.parse(response.url, {
        download: true,
        // header:true,
        complete: function (results) {
          console.log("csvdata", results.data);
          // setTableDataItems(results.data)
          let j;
          let k;
          let monthIndex;
          // let yearIndex;
          if (
            results.data[0].includes("orgshortcode") ||
            results.data[0].includes("Orgshortcode")
          ) {
            for (let i = 0; i < results.data[0].length; i++) {
              if (
                results.data[0][i].includes("orgshortcode") ||
                results.data[0][i].includes("Orgshortcode")
              ) {
                k = i;
              }
            }
          }
          results.data.forEach((data, index) => {
            data.splice(k, 1);
          });

          if (
            results.data[0].includes("Month") ||
            results.data[0].includes("month")
          ) {
            for (let i = 0; i < results.data[0].length; i++) {
              if (results.data[0][i].toLowerCase() === "month") {
                monthIndex = i;
              }
            }
            // for (let i = 0; i < results.data[0].length; i++) {
            //   if (results.data[0][i].toLowerCase() === "year") {
            //     yearIndex = i;
            //   }
            // }

            results.data.forEach((data, index) => {
              // var currentDate = new Date();
              // var currentYear = currentDate.getFullYear();
              // console.log("year1" + currentYear);
              // console.log("year2" + data[yearIndex]);
              // console.log(
              //   "year3" + parseInt(data[yearIndex]?.trim()) ===
              //     parseInt(currentYear)
              // );

              if (
                selectedMonth?.includes(data[monthIndex]?.trim())
                // &&
                // currentYear === data[yearIndex]?.trim()
              ) {
                const trimmedData = data?.map((ele) => {
                  return ele.trim();
                });
                temp.push(trimmedData);
              }

              // temp?.forEach((item) => {
              //   if (currentYear === item[yearIndex]?.trim()) {
              //     temp2.push(item);
              //   }
              // });
             
            });
          } else if (
            results.data[0].includes("date") ||
            results.data[0].includes("Date") ||
            results.data[0].includes("due_date") ||
            results.data[0].includes("date_time") ||
            results.data[0].includes("Visit Date")
          ) {
            for (let i = 0; i < results.data[0].length; i++) {
              if (
                results.data[0][i].toLowerCase() === "date" ||
                results.data[0][i] === "due_date" ||
                results.data[0][i] === "date_time" ||
                results.data[0][i] === "Visit Date"
              ) {
                j = i;
              }
            }

            results.data.forEach((data, index) => {
              if (
                (new Date(data[j]) / 1000 >= start || data[j] >= start) &&
                (new Date(data[j]) / 1000 <= end || data[j] <= end)
              ) {
                temp.push(data);
              }
            });
          }
          var csvHearders = [];
          results.data[0].forEach((header) => {
            csvHearders.push(camelize(header));
          });
          temp.unshift(csvHearders);
          console.log("temp1111", temp);
          setDataIndex(temp.length);
          let myArray = temp.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b[j]) - new Date(a[j]);
          });
          let csv = Papa.unparse(myArray);
          console.log("unparse", csv);
          let csv2 = Papa.parse(csv, {
            header: true,
          });
          console.log("csv2", csv2);

          setCsvData(csv);
          setIsDownload(true);
          setIsTable(true);
          setIsLoading(false);
          setIsHeading(true);
        },
      });
    });
  }

  const checkIsMonthOrNot = (fileName) => {
    var body = {
      fileName: fileName,
    };

    console.log("body", body);

    nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
      // console.log("presignedurlresponse", response);

      Papa.parse(response.url, {
        download: true,
        // header:true,
        complete: function (results) {
          console.log("csvdata", results.data);
          // setTableDataItems(results.data)

          if (
            results.data[0].includes("Month") ||
            results.data[0].includes("month")
          ) {
            setIsMonth(true);
            setShowDate(false);
          } else {
            setIsMonth(false);
            setIsCustomMonth(false);
          }
        },
      });
    });
  };

  // const getPresentMonth = () => {
  //   const d = new Date();
  //   let month = d.getMonth();
  //   return month;
  // };

  //   const downloadCsvFiledata = (filePath) => {
  //     var body = {
  //       fileName: filePath,
  //     };
  //     let temp = [];

  //     nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
  //       Papa.parse(response.url, {
  //         download: true,
  //         complete: function (results) {
  //           console.log("csvdata", results.data);
  //           let j;
  //           if (
  //             results.data[0].includes("date") ||
  //             results.data[0].includes("due_date") ||
  //             // results.data[0].includes("date_time") ||
  //             results.data[0].includes("visit_date")
  //           ) {
  //             for (let i = 0; i < results.data[0].length; i++) {
  //               if (
  //                 results.data[0][i] === "date" ||
  //                 results.data[0][i] === "due_date" ||
  //                 // results.data[0][i] === "date_time" ||
  //                 results.data[0][i] === "visit_date"
  //               ) {
  //                 j = i;
  //               }
  //             }
  //           }
  //           results.data.forEach((data, index) => {
  //             if (
  //               new Date(data[j]) / 1000 >= startDate &&
  //               new Date(data[j]) / 1000 <= endDate
  //             ) {
  //               temp.push(data);
  //             }
  //           });
  //           var csvHearders = [];
  //           results.data[0].forEach((header) => {
  //             csvHearders.push(camelize(header));
  //           });
  //           temp.unshift(csvHearders);
  //           console.log("temp1111", temp);
  //           let csv = Papa.unparse(temp);
  //           console.log("unparse", csv);
  //         //   setCsvData(csv);
  //         },
  //       });

  //       // var aLink = document.createElement("a");
  //       // var evt = document.createEvent("HTMLEvents");
  //       // evt.initEvent("click");
  //       // aLink.download = fileName;
  //       // aLink.href = response?.url;
  //       // aLink.dispatchEvent(evt);
  //       // aLink.click();
  //     });
  //   };

  //   const getTableData = () => {
  //     var body = {
  //       fileName: "analytics/CLU/highriskcount.csv",
  //     };

  //     nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
  //       Papa.parse(response.url, {
  //         // header:  true,
  //         download: true,
  //         complete: function (results) {
  //           let csv = Papa.unparse(results.data);
  //           console.log("csvtable", csv);
  //           setCsvData(csv);
  //         },
  //       });
  //     });
  //   };

  return (
    <div>
      <div className="card">
        <div>
          {isHeading && (
            <div
              style={{
                float: "left",
                margin: "30px",
                fontWeight: "bold",
                fontSize: "medium",
                maxWidth:'220px',
                verticalAlign:'middle',
                whiteSpace: 'pre-wrap', 
                overflowWrap: 'break-word'
              }}
            >
              {selectedDataSrc?.label}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              verticalAlign:'miiddle',
              float: "right",
              margin: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
              }}
            >
              <label
                style={{
                  // marginLeft: "10px",
                  marginBottom: "0px",
                  fontWeight: "600",
                  // marginTop: "5px",
                }}
              >
                Data
              </label>
              <div
                style={{
                  // width: "150px",
                  // marginLeft: "15px",
                  // marginRight: "10px",
                  borderRadius: "3px",
                  //   marginRight:'10px',
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  style={{
                    borderRadius: "3px",
                    //   marginRight:'10px',
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                  isSearchable={false}
                  onChange={(value) => {
                    setIsDownload(false);
                    setSelectedDataSrc({
                      label: value.label,
                      value: value.value,
                    });
                    checkIsMonthOrNot(value.value);
                  }}
                  options={DataSourceOptions}
                />
              </div>
            </div>
            {!isMonth && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    marginLeft: "15px",
                    marginBottom: "0px",
                    fontWeight: "600",
                  }}
                >
                  Select Range
                </label>
                <div
                  style={{
                    width: "150px",
                    marginLeft: "15px",
                    marginRight: "10px",
                    borderRadius: "3px",
                    //   marginRight:'10px',
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      setIsDownload(false);
                      setDateValue(value.label, value.value);
                    }}
                    // defaultValue={optionDates[1]}
                    options={optionDates}
                  />
                </div>
              </div>
            )}
            {isMonth && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    marginLeft: "15px",
                    marginBottom: "0px",
                    fontWeight: "600",
                  }}
                >
                  Select Month
                </label>
                <div
                  style={{
                    width: "150px",
                    marginLeft: "15px",
                    marginRight: "10px",
                    borderRadius: "3px",
                    //   marginRight:'10px',
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    // value={selectedMonth}
                    onChange={(value) => {
                      setIsDownload(false);
                      // setSelectedMonth(value.label, value.value);
                      getMonthOptions(value.label, value.value);
                    }}
                    // defaultValue={monthOptions[getPresentMonth()]}
                    options={monthOptions2}
                  />
                </div>
              </div>
            )}

            {isCustomMonth && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    marginLeft: "15px",
                    marginBottom: "0px",
                    fontWeight: "600",
                  }}
                >
                  Select Custom Month
                </label>
                <div
                  style={{
                    width: "150px",
                    marginLeft: "15px",
                    marginRight: "10px",
                    borderRadius: "3px",
                    //   marginRight:'10px',
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    // value={selectedMonth}
                    onChange={(value) => {
                      // setIsDownload(false);
                      // setSelectedMonth(value.label, value.value);
                      setIsDownload(false);
                      setSelectedMonth([value.value]);
                    }}
                    // defaultValue={monthOptions[getPresentMonth()]}
                    options={customMonths}
                  />
                </div>
              </div>
            )}

            {showDate && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Flatpickr
                  style={{
                    backgroundColor: "white",
                    height: "40px",
                    width: "160px",
                    textAlign: "center",
                    color: "rgb(115 115 115)",
                    border: "solid",
                    borderRadius: "3px",
                    marginRight: "10px",
                    marginTop: "10px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                  // placeholder="dd M,yyyy"
                  placeholder="Pick Custom Date"
                  onChange={(value) => {
                    setIsDownload(false);
                    setCustomDates(value);
                  }}
                  options={{
                    mode: "range",
                    dateFormat: "d-M-y",
                    maxDate: new Date(),
                    minDate: new Date(
                      new Date().setDate(new Date().getDate() - 90)
                    ),
                  }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-end",
              }}
            >
              <Button
                style={{
                  margin: "5px 5px ",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => {
                  if (selectedDataSrc === null) {
                    return swal("Please Select Data", {
                      icon: "error",
                      dangerMode: true,
                    });
                  }
                  if (isMonth) {
                    if (selectedMonth === null) {
                      return swal("Please Select Month", {
                        icon: "error",
                        dangerMode: true,
                      });
                    }
                  } else {
                    if (startDate === 0 && endDate === 0) {
                      return swal("Please Select Range", {
                        icon: "error",
                        dangerMode: true,
                      });
                    }
                  }
                  getUrlData(startDate, endDate);
                }}
              >
                Submit
              </Button>
            </div>

            {/* {hasAccess(21) && ( */}
            {isDownload && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                  marginTop: "6px",
                }}
              >
                <CSVLink
                  data={csvData}
                  filename={selectedDataSrc?.label}
                  className="btn btn-success  add-group-btn"
                >
                  <i className="fa fa-download"></i>
                  &nbsp;&nbsp;Download
                </CSVLink>
                {/* <ExcelExportHelper data={attendanceData} reportCategory={reportCategory} reportType={type} /> */}
              </div>
            )}
            {/* )} */}
          </div>
        </div>
      </div>
      {/* <label
              style={{
                marginLeft: "10px",
                marginBottom: "0px",
                fontWeight: "600",
                marginTop: "5px",
              }}
            >
              View Data
            </label> */}

      {/* <DataTable
			title="Desserts"
			data={tableDataItems}
			columns={columns}
      pagination
			// onSelectedRowsChange={handleChange}
		/> */}
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        {isTable && (
          <div className="card">
            <div
              style={{
                // width: "auto",
                overflow: "auto",
                maxHeight: "400px",
                margin: "20px",
              }}
            >
              <CsvToHtmlTable
                data={csvData}
                csvDelimiter=","
                tableClassName="table table-bordered table-hover"
              />
            </div>
            {console.log("csvLength", csvData.length)}
            {dataIndex === 1 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default CannedReports;
