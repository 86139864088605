import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
// import { Form, Formik } from "formik";
// import swal from "sweetalert";
// import * as Yup from "yup";
import { Link } from "react-router-dom";
// import LoadImage from "../CommonComponents/LoadImage";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import {
  getTokenInLocalStorage,
  hasAccess,
  hasAccessFromTeamPrivilege,
} from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import {
  EpochToDate,
  EpochToOnlyDate,
  getMaskedMobileNo,
} from "../CommonComponents/Helper";
// import PIOPIY from "piopiyjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallAudioModal,
  setIsModalMinimised,
} from "../store/actions/CallingActions";
import { StartCall } from "../CommonComponents/TelecallingMethods";
// import SearchBar from "../CommonComponents/SearchBar";
// import { useCalling } from "../CommonComponents/CallingContext";
import Select from "react-select";
// import CallingEventHandlers from "../CommonComponents/CallingMethods";
// import { setCallAudioModal, setIsModalMinimised } from "../store/actions/CallingActions";
// import { useDispatch, useSelector } from "react-redux";

function SalesCallBackList() {
  // var history = useHistory();
  const [executivesList, setExecutivesList] = useState([]);
  const [remarksModal, setRemarksModal] = useState(false);
  //   const [editCpUserModal, setEditCpUserModal] = useState(false);
  const [remarksDetails, setRemarksDetails] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  //   const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);

  // const { isOnGoingCall, startCall, openModal, isMyAllocationsCalled } = useCalling();
  let listData = [];
  let userLoginDetails = getTokenInLocalStorage();

  const dispatch = useDispatch();
  const {piopiy,
    isCallActive,
    isIncomingCall, isOnGoingCall, isProgressiveDialing } = useSelector(
    (state) => state.calls
  );

  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview("Sales Call Backs List");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData(0, selectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   loadData(currPage, selectedOption);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isMyAllocationsCalled]);

  const loadData = (pageNo, todaysData) => {
    setIsLoading(true);
    let body = {
      userId:
        todaysData === "" ? "" : userLoginDetails?.autoFinanceUser?.userId,
      organizationId:
        todaysData === ""
          ? userLoginDetails?.autoFinanceUser?.organizationId
          : "",
      todaysData: todaysData === "" ? true : todaysData,
      //   organizationId: userLoginDetails.organizationId,
      //   searchKey: key,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCallBackRemarksForSales, pageNo, 10),
        body
      )
      .then((response) => {
        // console.log(response);
        setCurrPage(pageNo);
        response?.content?.forEach((element) => {
          let data = {
            allocationId: element.allocationId ? element?.allocationId : "-",
            allocationStatus: element.allocationStatus
              ? element?.allocationStatus
              : "-",
            amountPaid: element.amountPaid ? element?.amountPaid : "-",
            callId: element.callId ? element?.callId : "-",
            customerName: element.customerName ? element?.customerName : "-",
            // callStatus: element.callStatus ? element?.callStatus : "-",
            // teleCallerEmpId: element.teleCallerEmpId ? element?.teleCallerEmpId : "-",
            // teleCallerName: element.teleCallerName ? element?.teleCallerName : "-",
            customerNumber: element.customerNumber
              ? element?.customerNumber
              : "-",
            dailingTime: element.dailingTime ? element?.dailingTime : "-",
            dateTime: element.dateTime
              ? EpochToOnlyDate(element?.dateTime)
              : "-",
            dipositionTime: element.dipositionTime
              ? element?.dipositionTime
              : "-",
            holdTime: element.holdTime ? element?.holdTime : "-",
            id: element.id ? element?.id : "-",
            paymentDate: element.paymentDate
              ? EpochToOnlyDate(element?.paymentDate)
              : "-",
            paymentMode: element.paymentMode ? element?.paymentMode : "-",
            paymentTowards: element.paymentTowards
              ? element?.paymentTowards
              : "-",
            ptpAmount: element.ptpAmount ? element?.ptpAmount : "-",
            callBackSlot: element.callBackSlot ? element?.callBackSlot : "-",
            remarkAddedBy: element.remarkAddedBy ? element?.remarkAddedBy : "-",
            remarks: element.remarks ? element?.remarks : "-",
            remarksType: element.remarksType ? element?.remarksType : "-",
            ringingTime: element.ringingTime ? element?.ringingTime : "-",
            status: element.status ? element?.status : "-",
            subStatus: element.subStatus ? element?.subStatus : "-",
            talkTime: element.talkTime ? element?.talkTime : "-",
            teleCallerEmpId: element.teleCallerEmpId
              ? element.teleCallerEmpId
              : "-",
            teleCallerName: element.teleCallerName
              ? element.teleCallerName
              : "-",
            callBackDate: element?.callBackDate
              ? EpochToOnlyDate(element?.callBackDate)
              : "-",
            transactionId: element.transactionId ? element?.transactionId : "-",
            userId: element.userId ? element?.userId : "-",
          };
          listData.push(data);
        });
        setExecutivesList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  //   const handleClose = () => {
  //     setAddCpUserModal(false);
  //     loadData(0, searchKey);
  //   };

  //   let path = window.location.pathname;
  //   path = path.split("/");
  //   // eslint-disable-next-line no-unused-vars
  //   path = path[path.length - 1];

  const columns = [
    {
      text: "Prospect Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Prospect Number",
      dataField: "customerNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.customerNumber) {
          return (
            // <div
            //   style={{
            //     display: "flex",
            //     flexDirection: "row",
            //     justifyContent: "center",
            //   }}
            // >
            //   <span>{row?.customerNumber}</span>
            //   {hasAccess(42) &&
            //     userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
            //     userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
            //     userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
            //     userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
            callOptionTemplate(row)
          );
          //     </div>
          //   );
        } else {
          return <>-</>;
        }
      },
    },
    {
      text: "Call Back Date",
      dataField: "callBackDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Call Back Slot",
      dataField: "callBackSlot",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Id",
      dataField: "teleCallerEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "teleCallerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "PTP Amount",
    //   dataField: "ptpAmount",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    // {
    //   text: "Mobile No",
    //   dataField: "mobileNumber",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },

    {
      text: "Remarks",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div
              className="d-flex"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // console.log("row", row);
          //   setRemarksDetails(row);
          moduleApi
            .postData(
              apiCall.getSalesAllocationsDispositions + row?.allocationId
            )
            .then((response) => {
              // console.log("response", response);
              if (response) {
                setRemarksDetails(response);
                setRemarksModal(true);
              }
            });
        },
      },
    },
  ];

  const callOptionTemplate = (row) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            marginTop: "5px",
          }}
        >
          {row.customerNumber!=='-'
            ? hasAccess(75) || hasAccessFromTeamPrivilege(75)
              ? getMaskedMobileNo(row.customerNumber)
              : row.customerNumber
            : row.customerNumber}
        </span>
        {hasAccess(42) &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
          isProgressiveDialing === false &&
          row?.customerNumber !== "-" && (
            <Link
              style={{ marginLeft: "10px" }}
              to="#"
              // className="btn btn-primary shadow btn-xs sharp"
              onClick={() => {
                // helper.makeCall(props?.contactNumber1);
                if (isOnGoingCall === false && isIncomingCall===false) {
                  // var piopiy = new PIOPIY({
                  //   name: "CLU",
                  //   debug: false,
                  //   autoplay: true,
                  //   ringTime: 60,
                  // });
                  StartCall(
                    piopiy,
                    dispatch,
                    row?.customerNumber,
                    false,
                    row?.allocationId,
                    row,
                    "Sales",
                    isCallActive
                  );
                  // piopiy.call("91" + row?.contactNumber);
                  // setCallMobileNo(props?.contactNumber1);
                } else {
                  dispatch(setCallAudioModal(true));
                  dispatch(setIsModalMinimised(false));
                }
              }}
            >
              {/* <i className="fa fa-phone fa-1x fa-rotate-90" /> */}
              <div className="media-body user-meta-info">
                <ul>
                  <li>
                    <Link
                      to={"#"}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <i
                        className="fas fa-phone-alt"
                        style={{ fontSize: "smaller" }}
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <img
                          src="/sidebar/callIcon.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        /> */}
            </Link>
          )}
      </div>
    );
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(selectedPage - 1, selectedOption);
  };

  const dropdownOptions = [
    { label: "Now", value: false },
    { label: "Today", value: true },
    { label: "All", value: "" },
  ];

  //   const searchKeyword = (key) => {
  //     setSearchKey(key);
  //     loadData(0, key);
  //   };

  //   const phoneRegExp =
  //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  //   var emailPattern =
  //     /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  //   // var namePattern = "^[a-zA-Z]";
  //   var nameRegex = "[a-zA-Z ]+$";

  //   const editProfileValidate = Yup.object({
  //     email: Yup.string()
  //       .matches(emailPattern, "Email is invalid")
  //       .required("Email is required"),
  //     mobileNumber: Yup.string()
  //       .matches(phoneRegExp, "Mobile number is not valid")
  //       .min(10, "Mobile number should be 10 digits")
  //       .max(10, "Mobile number should be 10 digits")
  //       .required("Mobile number is required"),
  //     name: Yup.string()
  //       .trim()
  //       .matches(nameRegex, "Please Enter a Valid Name")
  //       .required("Name is required"),
  //     empId: Yup.string().required("Emp ID is required"),
  //   });

  //   const onSubmit = (values) => {
  //     const payLoad = {
  //       branchId: cpUserDetails?.branchId,
  //       companyDesignation: null,
  //       email: values.email,
  //       employeeId: values.empId,
  //       // image: currentFile ? currentFile : cpUserDetails?.image,
  //       mobileNumber: values.mobileNumber,
  //       name: values.name,
  //       organizationId: cpUserDetails?.organizationId,
  //       passwordUpdated: cpUserDetails?.passwordUpdated,
  //       reportingOfficerIds: cpUserDetails?.reportingOfficerIds,
  //       roleName: cpUserDetails?.roleName,
  //       status: cpUserDetails?.status,
  //       userId: cpUserDetails?.userId,
  //     };
  //     moduleApi.postData(apiCall.updateUserData, payLoad).then((response) => {
  //       if (response?.message) {
  //         swal(response?.message, {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       } else if (response) {
  //         setEditCpUserModal(false);

  //         swal("Successfully Updated", {
  //           icon: "success",
  //         }).then((willOk) => {
  //           if (willOk) {
  //             loadData(0, searchKey);
  //           }
  //         });
  //       } else {
  //         return swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  //   };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        {/* <input
          style={{
            width: "300px",
            height: "40px",
            borderRadius: "25px",
            borderColor: "#a0a0a0",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #3a353b",
            paddingLeft: "10px",
          }}
          placeholder="Search By Name/Branch/Mobile"
          onChange={(e) => searchKeyword(e.target.value.trim())}
        /> */}
        {/* <SearchBar
          placeholder="Search By Name/Emp ID/Email/Mobile"
          searchKeyword={searchKeyword}
        />

        <Button
          style={{
            float: "right",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => setAddCpUserModal(true)}
        >
          <b> + Add CP User </b>
        </Button> */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                height: "38px",
                width: "150px",
                // border: "solid",
                borderRadius: "3px",
                // margin: "auto",
                marginRight: "20px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                defaultValue={dropdownOptions[0]}
                options={dropdownOptions}
                onChange={(value) => {
                  loadData(0, value.value);
                  setSelectedOption(value.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
              marginBottom: "15px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={executivesList}
              columns={columns}
            />
          </div>
          {executivesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" show={remarksModal} size="lg">
        <Modal.Header>
          <Modal.Title>Remarks</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setRemarksModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "15px",
              width: "100%",
            }}
          >
            {remarksDetails?.length > 0 ? (
              <div className="table-responsive">
                <table className="table">
                  <thead
                    style={{
                      backgroundColor: "rgb(243,240,249)",
                      top: "1px",
                      position: "sticky",
                    }}
                  >
                    <tr style={{ textAlign: "center" }}>
                      <th scope="col">Remarks</th>
                      <th scope="col">Remarks Added By</th>
                      <th scope="col">Call Status</th>
                      <th scope="col">Status</th>
                      <th scope="col">Sub Status</th>
                      <th scope="col">Call Back Date</th>
                      <th scope="col">Call Back Slot</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {remarksDetails?.map((item) => (
                      <tr
                        style={{
                          textAlign: "center",
                          fontWeight: "normal",
                        }}
                        key={item.id}
                      >
                        <td>{item.remarks}</td>
                        <td>{item.remarkAddedBy ? item.remarkAddedBy : "-"}</td>
                        <td>{item.remarksType ? item.remarksType : "-"}</td>
                        <td>{item.status ? item.status : "-"}</td>
                        <td>{item.subStatus ? item.subStatus : "-"}</td>
                        <td>
                          {item.callBackDate
                            ? EpochToOnlyDate(item.callBackDate)
                            : "-"}
                        </td>
                        <td>{item.callBackSlot ? item.callBackSlot : "-"}</td>
                        {item.createdTime ? (
                          <td>{EpochToDate(item.createdTime)}</td>
                        ) : (
                          <td>-</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <span style={{ textAlignLast: "center" }}>
                No Previous Remarks Found
              </span>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal className="fade" show={editCpUserModal}>
        <Modal.Header>
          <Modal.Title>Edit CP User</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setEditCpUserModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                email: cpUserDetails?.email,
                image: "",
                mobileNumber: cpUserDetails?.mobileNumber,
                name: cpUserDetails?.name,
                organizationId: "",
                reportingOfficerId: "",
                roleName: cpUserDetails?.roleName,
                empId: cpUserDetails?.employeeId,
                branchLocation: "",
                branchName: "",
              }}
              onSubmit={onSubmit}
              validationSchema={editProfileValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <TextField
                          isAsterisk={true}
                          label="Name"
                          name="name"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Emp ID"
                          name="empId"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Email"
                          name="email"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Mobile Number"
                          name="mobileNumber"
                          type="text"
                        />

                        <div style={{ marginTop: "10px" }}>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setEditCpUserModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default SalesCallBackList;
