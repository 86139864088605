export const apiCall = {
  login: "userlogin/login",
  updatemobilenumber: "userlogin/updatemobilenumber",
  updateemail: "userlogin/updateemail",
  verifyupdateemail: "userlogin/verifyupdateemail",
  forgotPassword: "userlogin/sendotpforforgotpassword",
  verifyForgotPassword: "userlogin/ressetpasswordwithotp",
  changePassword: "userlogin/resetpassword",
  mobileOtpVerification: "userlogin/verifyotpformobilenumberupdate",
  loginWithOrgId: "userlogin/loginwithorgid",
  loginWithOtpLess: "userlogin/loginwithotpless",
  getuservisitscount: "location/getuservisitcounts",
  resetPasswordForParticularUser: "users/resetpasswordfortheuser",
  updateFcmToken: "userlogin/updatefcmToken",

  getAllOrganisations: "userlogin/getallorganizations",
  getOrganisationData: "userlogin/getorganizationadata/",
  registerautofinanceUser: "userregistration/registerautofinancerganization",
  updateautofinancerganization:"userregistration/updateautofinancerganization",
  getallorganizations: "userlogin/getallorganizations",

  getUser: "users/getuser/",
  getExecutives: "users/getusers",
  getBranches: "users/getbranches",
 getRolesBasedOnOrg:"users/getRolesBasedOnOrg/",
 getPrivilagesBasedOnUser:"users/getPrivilagesBasedOnUser/",
 getPrivilages:"users/getPrivilages",
 updateprivilages:"users/updateprivilages",
 updatePersonBasedPrivilages:"users/updatePersonBasedPrivilages",
  getBranchDetails: "users/getbranch/",
  getRoleNames: "users/getroles/",
  getFiClientNames: "executivedailyscheduleddata/getFiCLientNames",
  getDesignations: "users/getdesignations/",
  getMultipleUsers: "users/getuserswithids",
  usersList: "users/getusersminidata",
  addFiClientName: "executivedailyscheduleddata/addFilClientName",
  updateReportingOfficer: "users/updatereportingofficer",
  updatedirectreportingofficer:"users/updatedirectreportingofficer",
  enableordisablegeofencing: "employeeattendence/enableordisablegeofencing",
  updatePrivilegeForSubscription: "users/updateprivilagetocreatesubscription",
  updateprivilagetoeditsubscriptionloannumber:"users/updateprivilagetoeditsubscriptionloannumber",
  updateManagersUnderRa: "users/updatemanagersunderra",
  deleteExecutive: "users/reliveemployee",
  updateBranch: "users/transferemployee",
  updateUserData: "users/updateuserdetails",
  transferUser: "users/transferuser",
  transferSubordinates: "users/transfersubordinates",
  getCollectionsStatus: "users/getcollectionstatus",
  getdespositonstatus: "users/getdespositonstatus",
  getCpUsers: "users/getcpusers",
  getCpUsersForDropdown: "users/getcpnamebasedonoganization/",
  saveCpUser: "userregistration/registercpuser",
  getOrgBasedOnCpName: "userregistration/getorganizationbasedoncpmane",
  registerbranch: "userregistration/registermainorsubbranch",
  updatebranchdetails: "userregistration/updatebranchdetails",
  updateUserLanguages: "users/editUserLanguages",
  getUserLanguages: "users/getuserLanguages",
  getUsersBasedOnLanguage: "users/getuserbasedonlanguage",
  getCompanyDesignation:"users/getCompanyDesignation",
  addCompanyDesignation:"users/addCompanyDesignation",
  updateCompanyDesignation:"users/updateCompanyDesignation",
  deleteCompanyDesignation:"users/deleteCompanyDesignation",
  addprivilege:"users/addprivilege",
  

  saveExecutive: "userregistration/registerautofinanceuser",
  saveManager:
    "userregistration/registerorupdatebranchdetails?isnewbranch=true",
  updateautofinanceuserdata: "userregistration/updateautofinanceuserdata",

  dashboardCount: "alerts/getdashoboardcounts",
  salesAndCollectionGraph: "alerts/getsaleandcollectioncountforgraphs",
  collectionGraph: "alerts/getcollectioncountsforgraphs",
  fieldInvestigationGraph: "alerts/getficountforgraphs",
  fiGraph: "alerts/getficountforgraphs",
  paymentsGraph: "phonepe/getpaymentrequestcount",
  branchWiseCount: "alerts/getbranchwisecount",
  getGraphsMetaData: "alerts/getgraphsmetadata",
  saveGraphsMetaData: "alerts/savegraphsmetadata",
  deleteGraphMetaData: "alerts/deletegraphmetadata",
  getCallCounts: "getCallCountForUser",
  getCollectionCallCountForUser:"getCollectionCallCountForUser",
  getEmpAtdnceBasedOnCrntDayStatus: "employeeattendence/getemployeesattendenceforcurrentdaybasedonstatus",
  getLoginCounts: "employeeattendence/getEmployeeCheckinCount",
  getCollectionAllocationCounts: "alerts/getCollectionAllocationCounts",
  getMultipleVisitsToCustomerCounts: "executivedailyscheduleddata/getMultipleVisistToCustomerCounts",
  saveFeedback: "customer/savefeedback",
  getPreSignedUrl: "preSignedUrl",

  getPaymentReceipt : "receipt/getpaymentreceipt/",
  resendPaymentReceipt:"receipt/resendpaymentreceipt/",
  getPaymentReceiptForUpiMandate : "receipt/getpaymentreceiptforuoimandate/",
  resendPaymentReceiptForUpiMandate:"receipt/resendpaymentreceiptforupimandate/",
  getPaymentReceiptForPayments : "receipt/getpaymentreceiptwithreceiptnumber/",
  resendPaymentReceiptForPayments:"receipt/resendpaymentreceiptwithreceiptnumber/",

  getVisits: "location/getemployeevisits",
  getEmployeeVisits: "location/getemployeevisits",
  getEmployeePtpVisits: "location/getemployeeptpvisits",
  getEmployeePtpCount: "location/getemployeeptpcount",
  getEmployeeVisitsByVisitId: "location/getemployeevisitbyid/",
  getEmployeeVisitsCount: "location/getemployeevisitscount",
  getEmployeePtpSummaryCount: "location/getptpsummarycount",
  getLocations: "location/getLocationPaths",
  getLiveLocations: "location/getLiveLocationWithUserids",
  trackingAccess: "location/updatetrackingstatus",
  updateLoanNo: "location/updateloannumber",
  editApplicationNumber:"location/editApplicationNumber",
  updateAmountPaid: "location/updateamountpaid",
  updateVehicleRelease: "location/releasereposessedvehicle",
  updateFiApprovalStatus: "location/updateFiApprovalStatus",
  getCustomerVisitHistory: "location/getcustomervisithistory/",
  getCollectionStatusCount: "location/getcollectionstauscounts/",
  getCustomerMgmtList: "location/getcustomerdetails",
  getemployeevisitscountwithtotalcount:
    "location/getemployeevisitscountwithtotalcount",
  getCustomerSummary: "location/getcustomerSummary",
  getCustomerSummaryCount: "location/getcustomerSummaryCount",
  getVisitsBasedOnCpName: "location/getemployeevisitsbasedoncpname",
  getCustomerDetailsBasedOnCpName: "location/getcustomerdetailsbasedoncpname",
  getemployeevisitsfromcollectionallocations:"location/getemployeevisitsfromcollectionallocations",
  getCollectionAllocationsBasedOnCpName:
    "executivedailyscheduleddata/getcollectionAllocationdatabasedoncpname",
  getcurrentbucketnames:"executivedailyscheduleddata/getcurrentbucketnames/",
  getcurrentbucketnamesinuppercase:"executivedailyscheduleddata/getcurrentbucketnamesinuppercase/",
  getproductnames:"executivedailyscheduleddata/getproductnames/",
  makeCall: "customer/callcustomer",
  editCustomerDetails: "customer/editcustomerdetails",
  sendCustomerPaymentLink: "customer/sendpaymentlink",
  sendCustomerBulkPaymentLinkLots: "executivedailyscheduleddata/savePaymentLinkLots",
  getCustomerBulkPaymentLinkLots: "executivedailyscheduleddata/getRequestPaymentLinkLotList",
  getTargetDueCounts:"executivedailyscheduleddata/getTargetDueCounts",
  gettargetduesummarycount:"executivedailyscheduleddata/gettargetduesummarycount",
  getAllocationsByTargetDue:"executivedailyscheduleddata/getAllocationsByTargetDue",
  addRemarksForAllocation:
    "saveCallEndedDeposition",
  updateRotateNumbers:
    "updatenumbers?organizationId=",
  getdailyscheduleDataBasedOnFilters:
    "executivedailyscheduleddata/getDailySechduleDataBasedOnFileters",
  getCollectionAllocationForRequestPayments:
    "executivedailyscheduleddata/getCollectionAllocationForRequestPayments",
  getAllocationsFullData:
    "executivedailyscheduleddata/getDailySechduleDataBasedForCallers",
  getAllocationRemarks: "getAllocationDispositions/",
  getAllCampaigns: "executivedailyscheduleddata/getallocationcampaignnames/",
  getApportionConfiguration:"executivedailyscheduleddata/getApportionConfiguration",
  updateApportionsConfiguration:"executivedailyscheduleddata/updateApportionsConfiguration",
  getPaymentLinksByAllocation:
    "executivedailyscheduleddata/getPaymentLinksByAllocation/",
  getCallReports: "customer/getCallReports",
  callInitiated: "saveCallIntiatedDeposition",
  getIncomingCallLoanNumbers: "executivedailyscheduleddata/getallocationsbycustnameornumber",
  updateAgentStatus: "telecmi/updateAgentStatus",
  addLead: "leads/addLead",
  getSourceChannelListForCalling: "getsourcechannelslist",
  getAdditionalPhoneNumbers: "executivedailyscheduleddata/getAdditionPhoneNUmbersForLoanNumber/",

  getEmployeeAttendance: "employeeattendence/getemployeeattendence",
  getLeaveRequestsList: "employeeattendence/getemployeeleaverequest",
  updateLeaveRequest: "employeeattendence/updateleaverequest",

  addSourceChannel:"addsourcechannels",
  getSourceChannels:"getsourcechannels",
  getSalesAllocations:"getsalesallocations",
  getSalesAllocationsDispositions:"getSalesAllocationDispositions/",
  callInitiatedForSales: "saveCallIntiatedDepositionforsales",
  addRemarksForAllocationForSales:
    "saveCallEndedDepositionforsales",
  assignAllocationToTelecaller:"assignsaleallocationtotelecaller",
  getCountsWithPaymentModeOrPaymentChannel:"getCountsWithPaymentModeOrPaymentChannel",
  updatesaleallocation:"updatesaleallocation",
  getCallBackRemarksForSales:"getcallbackremarksforsales",
  getSalesDataBasedOnFilters:
    "getsalesallocationsbasedonfilters",
  getAllSalesAllocations:
    "getsalesallocationsbasedonfiltersforcallers",
  getSalesAllocationsBasedOnId:
    "getSaleDataWithId",
  getCallReportForSales:
    "customer/getCallReportsForSales",

  sendMessage: "msg/sendmessage",
  createTemplate: "msg/createWhatsAppTemplates",
  getTemplates: "msg/getwhatsapptemplate",
  getPreSignedUrlForTemplateUpload: "msg/getpresignedurlforfileuploadfortemplate",
  getHValueForFileUpload: "msg/gethvalueforfileupload",

  getSalesLeads:'leads/getLeads',
  getSalesOpportunities:'leads/getOpportunites',
  getOpportunityStages:'leads/getOpportunityStages/',
  getOpportunityTypes:'leads/getOpportunityType/',
  getActivityTypes:'leads/getActivityType/',
  addOpportunity:'leads/addOpportunity',
  addAdditionalDetails:'leads/addAdditionalDetailsForLead',
  updateOpportunity:'leads/updateOpportunity',
  addActivity:'leads/addActivity',
  updateLead:'leads/updateLead',
  getLeadStatus:'leads/getLeadStatus/',
  getTypeOfLoans:'leads/getTypeOfLoans/',
  getStatesCitiesPincode:'users/getStatesCitiesPinCode',
  getLeadDetails:'leads/getLeadDetails/',
  getLeadHistory:'leads/getLeadHistories/',
  getSalesTeams:'team/getTeams',
  addSalesTeams:'team/addteam',
  updateSalesTeams:'team/updateTeam',
  getTeamNames:'team/getTeamNames',
  getTeamUsers:'team/getTeamUsers',

  reports: "reports/getreport",
  getEmployeePerformanceMetrics:"reports/getEmployeePerformanceMetrics",
  getReportsBaseedOnCpName: "reports/getreportbasedoncpname",
  getScheduledReports: "reports/getsechdulerequest/",
  addScheduledReport: "reports/addreportschedule",
  deleteScheduledReport: "reports/deleteschedulerequest",

  applyLeave: "employeeattendence/applyleaverequest",
  getLeavesCount: "employeeattendence/getemployeeleavecount/",
  getHolidays: "employeeattendence/gethloidays/",
  saveHoliday: "employeeattendence/saveholiday",
  updateHoliday: "employeeattendence/updateholidaylist",
  editLeaveCounts: "employeeattendence/editLeaveCounts",
  addLeaveCountByDesignation:
    "employeeattendence/addleaveaccrualsbydesignation",

  getExpenseList: "expense/getexpense",
  updateExpenseRequest: "expense/updateexpense",
  addExpense: "expense/addexpense",
  editLeaves: "employeeattendence/editleaves",
  getOrgHierarchyData: "users/getOrgnaizationHirarchy/",

  uploadDailyScheduleData:
    "executivedailyscheduleddata/uploaddailyscheduleddata",
  uploadTelecallerData: "executivedailyscheduleddata/allocatetotelecaller",
  getCustomersList: "executivedailyscheduleddata/getuploadedschedulefilelist",
  transferCustomers: "executivedailyscheduleddata/transfercustomerdetails",
  deleteCustomers: "executivedailyscheduleddata/deletecustomerdetails",
  getWorkUploadCounts:
    "executivedailyscheduleddata/getscheduledcollectionandsalescount",
  getAllocatedExecutives: "executivedailyscheduleddata/getexecutives",
  downloadAllAllocations:
    "executivedailyscheduleddata/downloadallocationsfile/",
  getemployeevisits: "location/getemployeevisits",
  getBucketNames: "executivedailyscheduleddata/getBucketName",
  getPrioritiesBasedOnOrgId: "executivedailyscheduleddata/getallocationpriorities/",
  getAllocationSummaryForCpUsers:
    "executivedailyscheduleddata/getscheduledcollectionandsalescount",
  getPtpAllocations: "executivedailyscheduleddata/getPtpAllocations",
  getCallBacks: "executivedailyscheduleddata/getcallbackremarks",
  updateAllocationMobileNumber: "executivedailyscheduleddata/updateAllocationMobileNumber",
  getWorkflowStatus: "executivedailyscheduleddata/getworkflowstatus",
  getAllocationDetailsById: "executivedailyscheduleddata/getscheduledatawithid",
  getCpnames:"executivedailyscheduleddata/getCpnames",
  addCpName:"executivedailyscheduleddata/addCpName",
  updateCpName:"executivedailyscheduleddata/updateCpName",
  deleteCpName:"executivedailyscheduleddata/deleteCpName",

  getSubscriptions: "phonepe/getsubscriptionrequests",
  getTransactions: "phonepe/gettransactionlist/",
  getTransactionDetails: "phonepe/transactiondetails",
  gettransactionshistorybasedontransactiontype:"phonepe/gettransactionshistorybasedontransactiontype",
  updatePaymentStatus: "location/updatepaymentstatus",
  cancelSubscription: "phonepe/cancelsubscription",
  deleteSubscription: "phonepe/deletesubscriptionrequest/",
  reInitiateTransaction: "phonepe/reintiateautopaytransaction",
  createSubscription: "phonepe/createsubcription",
  checkVpaAddress: "phonepe/checkVpaAddress",
  submitAuthRequestForSubcription: "phonepe/submitAuthRequestForSubcription",
  getAutopayDedutionDates: "phonepe/getautopaydedutiondates/",
  getSubscriptionDetails: "phonepe/getsubcriptiondetails/",
  getPaymentTransactions: "location/getvisitsbypaymentmode",
  getNonVisitedPayments:"getNonVisitedPayments",
  getNonVisitedPaymentsByLoanNumber:"getNonVisitedPaymentsByLoanNumber",
  getAllPayments:"getAllPayments",
  updateNonVisitedpaymentstatus:"updateNonVisitedpaymentstatus",
  getRequestPayments: "customer/getcutomerlatesttransactions",
  sendBulkPaymentLinks: "customer/sendbulkpaymentlinks",
  getPayResponseCode: "phonepe/getpayreponsecode",
  getSubscriptionCountsWithStatus:"phonepe/getSubscriptionCountsWithStatus",
  getCashDepositions: "location/getcashdeposits",
  getEmployeeLedgerDetails:"location/getEmployeeLedgerDetails",
  getCashDepositVisits: "location/getcashvisits",
  updateCashDeposit: "location/updatecashdeposit",
  skipSubscription: "phonepe/skiptransactions",
  editLoanNoinSubscriptions: "phonepe/editsubscription",
  getSkippedSubscriptions: "phonepe/getSkipRequests",
  getSkippedSubscriptionsHistory: "phonepe/getSkipedHistory",
  updateAmountToBeDeducted: "phonepe/updateamounttobededucted",

  getIncentives:"incentivemanagement/getIncentives",
  getIncentiveswithuserStatus:"incentivemanagement/getIncentiveswithuserStatus",
  inActivateIncentive:"incentivemanagement/inActivateIncentive",
  updateIncentiveApprovalStatus:"incentivemanagement/updateIncentiveApprovalStatus",
  updateIncentivesApprovalStatusForUser:"incentivemanagement/updateIncentivesApprovalStatusForUser",
  updateIncentivesApprovalStatusInFinalPayout:"incentivemanagement/updateIncentivesApprovalStatusInFinalPayout",
  markIncentiveAmountAsCredited:"incentivemanagement/markIncentiveAmountAsCredited",
  markAmountAsCreditInFinalPayout:"incentivemanagement/markAmountAsCreditInFinalPayout",
  addIncentive:"incentivemanagement/addIncentive",
  updateIncentive:"incentivemanagement/updateIncentive",
  cloneIncentive:"incentivemanagement/cloneIncentive",
  getIncentivesCountForDashboard:"incentivemanagement/getIncentivesCountForDashboard",
  getIncentivesListForDashboards:"incentivemanagement/getIncentivesListForDashboards",
  getFinalPayOutByEmployeeDetails:"incentivemanagement/getFinalPayOutByEmployeeDetails",

  //Telecmi
  getBreakTypes: "telecmi/getbreaktypes?orgid=",
  updateAgentLatestStatus: "saveagentStatus",
  getAgentLatestStatus: "getAgentStatus",

  //Service_Executive

  getVehicleSaleData: "vehcilesaledata/getvehiclesaledata",
  addVehicleSaleData: "vehcilesaledata/addvehiclesaledata",
  getVehicleDescriptionsBasedOnOrgId: "vehcilesaledata/getvehicledescriptions/",
  getVehicleServiceData: "vehicleService/getvehicleservicedata",
  addVehicleServiceData: "vehicleService/addservice",
  getVehicleServiceStatuses: "vehicleService/getvehicleservicestatus/",
  getVehicleServiceTypes: "vehicleService/getvehicleservicetypes/",
  getVehicleServiceCallStatus: "vehicleService/getvehicleservicecallstatus/",
  getVehicleAppointmentStatus: "vehicleService/getvehicleserviceappointstatus/",
  getVehicleServiceCallGatePass:
    "vehicleService/getvehicleservicecallgatepass/",
  updateVehicleServiceData: "vehicleService/updatevehicleservicedata",
  makeCallInVehicleService: "vehicleService/callcustomer",
  getVehicleServiceCallReport: "vehicleService/getCallReports",

  /* get and save Location details */

  getcoordinates: "geoloaction/getcoordinatesfromaddress",
  getCustAddress: "geoloaction/getCustomerAddress",
  saveCustAddress: "geoloaction/savecutomeraddress",

  /* AGENCIES API */

  agencies: "agencies",
  createAgencies: "create/agencies",
  activateAgencies: "agencies/activate",
  deactivateAgencies: "agencies/deactivate",
  validateAgencies: "agencies/validate/",

  /* Fi Allocation API */
  fiAllocations: "fiallocations",
  getFiAllocationsOfEmployees: "fiallocations/employees",
  getFiAllocationsOfAgencies: "fiallocations/agencies",
  getUsersByOrganization: "fiallocations/users",
  filterFiAllocations: "fiallocations/filter",
  transferFiAllocations: "fiallocations/transfer",
  getFiAllocationsSummary: "fiallocations/summary",
  getFiAllocationsDetailedSummary: "fiallocations/detailedSummary",
  approveRejectAllocation: "fiallocations/submitAllocation",
  getFiAllocationPdfData: "fiallocations/pdf",
  getUsersForAdmin: "fiallocations/usersforAdmin",
  editLoanNumber: "executivedailyscheduleddata/editfiallocation",
  getLatestApplicationNoCount: "fiallocations/getLatestApplicationNoCount",

  getImageUrl: "fiallocations/image/upload",

  /*FI Agency Login related API's */
  saveAgencyUploadData: "fiallocations/agency/upload",
  getAgencyUploadData: "fiallocations/agency",
  saveFiAllocationsPdfReport: "fiallocations/save/report",
  updateFiAllocationDetails: "fiallocations/details/update",
  sendEmail: "mock/sendMail",
  getFiAllocationsListForAgency: "fiallocations/view",
  getFiAllocationsAgencySummary: "fiallocations/agency/summary",
  downloadFromS3: "fiallocations/image/download",

  //new Lambda Api's
  getBulkUploadFilesList: "allocation/getallocationfilestatus",
  getPreSignedUrlForDownloadBulkUploadFile:
    "allocation/generatepresignedurlfordownloading",
  getPreSignedUrlForUplaodCollectionFile:
    "allocation/getpresignedurlforfileupload",

  getPreSignedUrlForDownloadPayslip: "payroll/downloadPaySlip",
  getPayrollData: "payroll/getPayRollData",
  getPayrollUploadFilesList: "payroll/getPayRollFileUploadList",
  getPreSignedUrlForUplaodPayrollFile: "payroll/getPresignedUrlForFileUpload",
  getPreSignedUrlForUplaodPaymentsFile:"bulk/getpresignedurlforfileuploadformandate",
  getMandateUploadedFileList:"bulk/getBulkMandateFileUpdateStatus",
  getPresignedUrlForMandateFileDownload:"bulk/generatepresignedurlfordownloading",

  uploadOnBoardUsers: "onboarding/onboardusers/",
  
  getmessagetemplates:"mock/getmessagetemplates",
  savemessagetemplates:"mock/savemessagetemplates",
  getallassetpurposes:"mock/getallassetpurposes",
  updatemessagetemplates:"mock/updatemessagetemplates",
  deletemessagetemplates:"mock/deletemessagetemplates",

  getsmshistory:"sms/getsmshistory",

  getWhatMessages:"msg/getWhatMessages",

  //JSON Form API
  //QA
  getJsonFormData:"https://0tihhkepgg.execute-api.ap-south-1.amazonaws.com/default/get_json_form_stored_data",

  //Vastu
  // getJsonFormData:"https://uosumbndt7.execute-api.ap-south-1.amazonaws.com/default/get_json_form_stored_data",

  //Lambda Api's

  // Bulk Upload

  // QA Base Url
  bulkUploadBaseUrl:
    "https://9ylmyoqrai.execute-api.ap-south-1.amazonaws.com/default/bulk_upload",

  payrollUploadBaseUrl:
    "https://16ydqqpwz7.execute-api.ap-south-1.amazonaws.com/default/payroll",

  // Prod Base Url
  // bulkUploadBaseUrl :"https://65vveckfzb.execute-api.ap-south-1.amazonaws.com/default/bulk_upload",

  // payrollUploadBaseUrl:
  //   "https://k0ndi2no21.execute-api.ap-south-1.amazonaws.com/default/clu_pay_roll",
};
