import React, { useEffect, useState } from "react";
import { getTokenInLocalStorage } from "../../src/services/AuthService";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import * as helper from '../CommonComponents/Helper';
import { Button, Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";


const GeneralManagers = () => {
  var history = useHistory();

  const [managersList, setManagersList] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [searchKey, setSearchKey] = useState('')

  // const [searchKey] = useState('')
  // var searchKey='';
  const [isMap, setIsMap] = useState(false);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 })
  const [isLoading, setIsLoading] = useState(true);


  let userLoginDetails = getTokenInLocalStorage();
  let listData = [];
  var managerData = JSON.parse(localStorage.getItem("managerDetails"));
  useEffect(() => {
    loadData(0, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const loadData = (pageNo, key) => {
    setIsLoading(true)

    let managerBody = {
      roleName: "MANAGER",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportOfficerId: managerData.userId,
      searchKey: key,
      status:"ACTIVE"
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getExecutives, pageNo, 10),
        managerBody
      ).then((response) => {
        let userIds = [];
        response.content.forEach((element) => {
          userIds.push(element.autoFinanceUser.userId)
        })
        let payload={
          organizationId: userLoginDetails.autoFinanceUser.organizationId,
          userIds:userIds
        }
        let m = new Map()
        moduleApi.postData(apiCall.getLiveLocations, payload)
          .then((response2) => {
            response2?.forEach((item) => {
              m.set(item?.userId, item);
            })
            response.content.forEach((element) => {
              let data = {
                branchId: element.autoFinanceBranchDetails.branchId,
                branchLocation: element.autoFinanceBranchDetails.branchLocation,
                branchName: element.autoFinanceBranchDetails.branchName,
                branchemail: element.autoFinanceBranchDetails.branchemail,
                organizationId: element.autoFinanceBranchDetails.organizationId,
                phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
                addedByUserId: element.autoFinanceUser.addedByUserId,
                reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
                email: element.autoFinanceUser.email,
                employeeId: element.autoFinanceUser.employeeId,
                image: element.autoFinanceUser.image,
                mobileNumber: element.autoFinanceUser.mobileNumber,
                name: element.autoFinanceUser.name,
                passwordUpdated: element.autoFinanceUser.passwordUpdated,
                reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
                roleName: element.autoFinanceUser.roleName,
                status: element.autoFinanceUser.status,
                userId: element.autoFinanceUser.userId,
                designation: element.autoFinanceUser.companyDesignation,
                batteryPercentage: m.has(element.autoFinanceUser.userId) ? m.get(element.autoFinanceUser.userId).batteryPercentage : 0,
                gpsData: m.has(element.autoFinanceUser.userId) ? m.get(element.autoFinanceUser.userId).gpsData : null,
                lastUpdatedAt: m.has(element.autoFinanceUser.userId) ? m.get(element.autoFinanceUser.userId).lastUpdatedAt : 0,
              };
              listData.push(data);
            });
            setManagersList(listData);
            setTotalElements(response.totalElements);
            setIsLoading(false)
          })
      });
  };
  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1)
    loadData(selectedPage - 1, searchKey);
  };


  const columns = [
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            {/* <LoadImage source={row.image} /> */}
            {" "}
            <p style={{ marginBottom: '0px', textAlign: 'left' }}>{row.name}</p>
          </div>
        );
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Email",
    //   dataField: "email",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Mobile No",
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",

      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Battery",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return <div>
          {row.batteryPercentage > 75 ?
            <span style={{ color: 'green', verticalAlign: 'middle' }}>
              <i className="fas fa-battery-full fa-2x fa-rotate-0" />
            </span> :
            row.batteryPercentage > 30 ?
              <span style={{ color: 'orange', verticalAlign: 'middle' }}>
                <i className="fas fa-battery-three-quarters fa-2x fa-rotate-0 " />
              </span> :
              row.batteryPercentage > 0 ?
                <span style={{ color: 'red', verticalAlign: 'middle' }}>
                  <i className="fas fa-battery-quarter fa-2x fa-rotate-0 " />
                </span> : <></>
          }
          <span style={{ marginLeft: row.batteryPercentage === 0 ? '0px' : '3px' }}>{row.batteryPercentage === 0 ? "N/A" : row.batteryPercentage + '%'}</span>
        </div >
      },
    },
    {
      text: "Last Updated",
      dataField: "lastUpdatedAt",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.gpsData) {
          return <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{ cursor: 'pointer' }} title={helper.EpochToDate(row.gpsData.t)}>
              {helper.EpochToDate(row.gpsData.t)}
            </p>
            <span style={{ color: 'green', margin: '0px 15px', cursor: 'pointer' }} onClick={() => {
              setLatLng({ lat: row.gpsData.x, lng: row.gpsData.y })
              setIsMap(true)
            }}>
              <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
            </span>
          </div>
        }
        else {
          return <>-</>
        }
      }
    },
    {
      text: "Status",
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "ACTIVE") {
          return (
            <span className="badge badge-rounded badge-success">Active</span>
          );
        } else {
          return (
            <span className="badge badge-rounded badge-danger">Inactive</span>
          );
        }
      }
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {


        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          localStorage.setItem("L1ManagerDetails", JSON.stringify(row));
          history.push("/l1-manager-details");
        },
      },
    },
  ];
  return (
<LoadingOverlay
        active={isLoading}
        // spinner
        text={<p style={{
          color:'black'
        }}><div>
        <Button style={{backgroundColor:'#FFFF',color:'black', borderColor:'white',fontWeight:'bold', fontSize:'16px'}} variant="primary" disabled>
    <Spinner
      as="span"
      animation="border"
      size="md"
      variant='primary'
      role="status"
      aria-hidden="true"
    />
     &nbsp;&nbsp;Loading...
  </Button>
  </div></p>}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
    <div>
     

      <div style={{ background: "white", margin: "10px" }}>
      <div style={{overflow:'auto', maxHeight:'400px'}}>
        <BootstrapTable
          keyField="name"
          data={managersList}
          columns={columns}
        />
        </div>
        {managersList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
        <div style={{ display: 'flex', justifyContent: 'center', margin: totalElements === 0 ? '90px 0px 5px 0px' : '0px' }}>
          <PaginationComponent
            totalItems={totalElements}
            pageSize={10}
            defaultActivePage={currPage + 1}
            onSelect={onPageChange}
          />
        </div>
      </div>
      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Last Updated Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal>
    </div>
    </LoadingOverlay>
  )
}

export default GeneralManagers