import React, { useState, useEffect } from "react";
import { Button, Nav, Tab } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
// import * as moduleApi from "../rest/moduleApi";
// import PaginationComponent from "react-reactstrap-pagination";
// import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
// import PIOPIY from "piopiyjs";
import { getTokenInLocalStorage } from "../services/AuthService";
// import { apiCall } from "../rest/restApi";
// import Tooltip from "react-bootstrap/Tooltip";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Flatpickr from "react-flatpickr";
// import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
// import { Form, Formik } from "formik";
// import * as Yup from "yup";
// import Select from "react-select";
// import { Link } from "react-router-dom";
// import TextField from "../CommonComponents/TextField";
// import ReactGA from "react-ga";
import { hasAccess } from "../services/AuthService";
import swal from "sweetalert";
// import * as XLSX from "xlsx";
// import * as helper from "../CommonComponents/Helper";
// import ReactDataGrid from "@inovua/reactdatagrid-community";
// import "@inovua/reactdatagrid-community/index.css";
// import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
// import { EpochToDate } from "../CommonComponents/Helper";
// import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
// import { Query } from "@syncfusion/ej2-data";
// import { DataUtil } from "@syncfusion/ej2-data";
// import { DataManager, ODataAdaptor } from "@syncfusion/ej2-data";
// import {
//   GridComponent,
//   ColumnsDirective,
//   ColumnDirective,
//   Page,
//   Sort,
//   Filter,
//   Inject,
// } from "@syncfusion/ej2-react-grids";
// import { getValue } from "@syncfusion/ej2-base";
// import CallSummary from "../CustomerManagement/CallSummary";
// import LoanNumberDetails from "../RequestPayments/LoanNumberDetails";
// import AddRemarks from "../RequestPayments/AddRemarks";
// import { useCalling } from "../CommonComponents/CallingContext";
import PtpNotificationsList from "../PtpNotificationsList/PtpNotificationsList";
import CallBackList from "../CallBackList/CallBackList";
import "./MyAllocationStyle.css";
import NewMyAllocations2 from "./NewMyAllocations2";
import { StartCall } from "../CommonComponents/TelecallingMethods";
import {
  setCallAudioModal,
  setIsModalMinimised,
} from "../store/actions/CallingActions";
import { useDispatch, useSelector } from "react-redux";

// const gridStyle = { minHeight: 500 };
// let gridInstance;
// const filterSettings = { type: "Menu" };
// const format = { type: "datetime", format: "M/d/y hh:mm a" };
// const filterType = [
//   { text: "Menu", value: "Menu" },
//   { text: "Checkbox", value: "CheckBox" },
//   { text: "Excel", value: "Excel" },
// ];
// const fields = { text: "text", value: "value" };
// const defaultFilterValue = [
//   { name: "customerName", operator: "startsWith", type: "string", value: "" },
//   { name: "contactNumber1", operator: "startsWith", type: "string", value: "" },
//   {
//     name: "latestPaymentLinkStatus",
//     operator: "startsWith",
//     type: "string",
//     value: "",
//   },
//   { name: "branchName", operator: "startsWith", type: "string", value: "" },
//   { name: "loanNumber", operator: "startsWith", type: "string", value: "" },
//   {
//     name: "dueAmount",
//     operator: "gte",
//     type: "number",
//     value: 0,
//   },
// ];
// const emptyText = (
//   <div className="d-flex justify-content-center">
//     <img src={NO_DATA_FOUND} alt="" />
//   </div>
// );
// const defaultSortInfo = { name: "customerName", type: "string", dir: 1 };
function MyAllocations() {
  // let gridInstance;
  // const {
  //   isOnGoingCall,
  //   startCall,
  //   // reqPaymentsList,
  //   // setReqPaymentsList,
  //   openModal,
  //   campaignName,
  //   setCampaignName,
  //   isMyAllocationsCalled,
  //   isProgressiveDialing,
  //   continueDialing,
  //   updateProgressiveDialing,
  // } = useCalling();
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  // const [basicModalForPaymentLinks, setBasicModalForPaymentLinks] =
  //   useState(false);
  // const [paymentLinksByAllocationId, setPaymentLinksByAllocationId] = useState(
  //   []
  // );
  // const [addRemarksBasicModal, setAddRemarksBasicModal] = useState(false);
  // const [callReportModal, setCallReportModal] = useState(false);
  // const [mobileNo, setMobileNo] = useState(null);
  // const [allocationDetails, setAllocationDetails] = useState([]);
  // const [allocationDetailsModal, setAllocationDetailsModal] = useState(false);
  // const [basicModalForSendPaymentLink, setBasicModalForSendPaymentLink] =
  //   useState(false);
  // const [remarks, setRemarks] = useState([]);
  // const [isMinutesSelected, setIsMinutesSelected] = useState(true);
  // const [isHoursSelected, setIsHoursSelected] = useState(false);
  // const [isDaysSelected, setIsDaysSelected] = useState(false);
  // const [totalElements, setTotalElements] = useState(1);
  // const [currPage, setCurrPage] = useState(0);
  // const [reqPaymentsList, setReqPaymentsList] = useState([]);
  // const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);
  // const [addRemarksAllocationId, setAddRemarksAllocationId] = useState("");
  // const [dataForSendCustomerLink, setDataForSendcustomerLink] = useState({});
  // const [campaigns, setCampaigns] = useState([]);
  // const [selectedValue, setSelectedValue] = useState(false);
  // const [selectedStatus, setSelectedStatus] = useState("");
  // const [sortInfo, setSortInfo] = useState([
  //   {
  //     direction: "ASC",
  //     key: "customerName",
  //   },
  // ]);

  // const [object, setObj] = useState([]);

  // const [loggedInUser] = useState(
  //   JSON.parse(localStorage.getItem("userDetails"))
  // );
  // // const [searchKey, setSearchKey] = useState("");
  // const [start, setStart] = useState(
  //   new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
  //     1000
  // );
  // const [end, setEnd] = useState(
  //   new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
  //     1000 +
  //     86399
  // );
  const [activeKey, setActiveKey] = useState("myAllocations");

  let userLoginDetails = getTokenInLocalStorage();
  const { piopiy,
    isCallActive,
    isIncomingCall,isOnGoingCall, isProgressiveDialing } = useSelector(
    (state) => state.calls
  );
  const dispatch = useDispatch();
  // function actionBegin(args) {
  //   let listData = [];
  //   var obj = {};
  //   if (args.requestType === "filtering") {
  //     if (args.action === "filter") {
  //       console.log(args);

  //       obj.key = args.currentFilterObject.field;
  //       obj.value = args.currentFilterObject.value;
  //       obj.operator = args.currentFilterObject.operator.toUpperCase();
  //       obj.fieldType =
  //         args.currentFilterObject.field === "dueAmount" ||
  //         args.currentFilterObject.field === "dueDay" ||
  //         args.currentFilterObject.field === "amountPaid" ||
  //         args.currentFilterObject.field === "amountToBePaid"
  //           ? "NUMBER"
  //           : "STRING";

  //       listData.push(obj);
  //       console.log("obj", listData);

  //       let filterValue = JSON.parse(localStorage.getItem("filterValue"));
  //       if (filterValue.length > 0) {
  //         for (let i = 0; i < filterValue?.length; i++) {
  //           if (filterValue[i].key !== obj.key) {
  //             filterValue.push(obj);
  //             break;
  //           } else {
  //             filterValue[i] = obj;
  //             break;
  //           }
  //         }
  //       } else {
  //         filterValue.push(obj);
  //       }
  //       localStorage.setItem("filterValue", JSON.stringify(filterValue));
  //       setObj(filterValue);
  //       getDailyScheduleDateForFilters(
  //         filterValue,
  //         sortInfo,
  //         start,
  //         end,
  //         0,
  //         selectedValue,
  //         selectedStatus,
  //         campaignName
  //       );
  //     } else if (args.action === "clearFilter") {
  //       console.log(args);
  //       let filterValue = JSON.parse(localStorage.getItem("filterValue"));
  //       filterValue.map((u) =>
  //         u.key !== args.currentFilterObject.properties.field
  //           ? u
  //           : filterValue.splice(filterValue.indexOf(u), 1)
  //       );
  //       localStorage.setItem("filterValue", JSON.stringify(filterValue));
  //       setObj(filterValue);
  //       getDailyScheduleDateForFilters(
  //         filterValue,
  //         sortInfo,
  //         start,
  //         end,
  //         0,
  //         selectedValue,
  //         selectedStatus,
  //         campaignName
  //       );
  //     }
  //   }
  //   if (args.requestType === "sorting") {
  //     console.log("args", args);
  //     let listData1 = [];
  //     let sortObj = {
  //       direction: args.direction === "Ascending" ? "ASC" : "DESC",
  //       key: args.columnName
  //         ? args.columnName
  //         : gridInstance.getColumnByIndex(
  //             +args.target.closest("th").getAttribute("data-colindex")
  //           ).field,
  //     };
  //     listData1.push(sortObj);
  //     console.log("listData1", listData1);
  //     console.log(
  //       gridInstance.getColumnByIndex(
  //         +args.target.closest("th").getAttribute("data-colindex")
  //       ).field
  //     );
  //     setSortInfo(listData1);
  //     getDailyScheduleDateForFilters(
  //       object,
  //       listData1,
  //       start,
  //       end,
  //       0,
  //       selectedValue,
  //       selectedStatus,
  //       campaignName
  //     );
  //   }
  // }

  // const getAllCampaigns = () => {
  //   moduleApi
  //     .getData(
  //       apiCall.getAllCampaigns +
  //         userLoginDetails.autoFinanceUser.organizationId
  //     )
  //     .then((response) => {
  //       console.log("response", response);
  //       const result = response.map((item, index) => ({
  //         value: item,
  //         label: item,
  //         index: index,
  //       }));
  //       result.unshift({ label: "All", value: "" });
  //       setCampaigns(result);
  //     });
  // };

  // const allocationStatusTemplate = (props) => {
  //   return (
  //     <div>
  //       {" "}
  //       {props.status === "OPEN" ? (
  //         <span className="badge badge-rounded badge-primary">Open</span>
  //       ) : props.status === "INPROGRESS" ? (
  //         <span
  //           className="badge badge-rounded badge-warning"
  //           style={{ color: "black" }}
  //         >
  //           In Progress
  //         </span>
  //       ) : props.status === "COMPLETED" ? (
  //         <span className="badge badge-rounded badge-success">Completed</span>
  //       ) : (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.status}
  //         </span>
  //       )}
  //     </div>
  //   );
  // };

  // const visitStatusTemplate = (props) => {
  //   return (
  //     <div>
  //       {" "}
  //       {props.visitStatus === "PTP" ? (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.visitStatus}
  //         </span>
  //       ) : props.visitStatus === "Paid" ? (
  //         <span className="badge badge-rounded badge-success">
  //           {props.visitStatus}
  //         </span>
  //       ) : props.visitStatus === "Customer Not Available" ? (
  //         <span className="badge badge-rounded badge-danger">
  //           {props.visitStatus}
  //         </span>
  //       ) : props.visitStatus === "" || props.visitStatus === null ? (
  //         <span>-</span>
  //       ) : (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.visitStatus}
  //         </span>
  //       )}
  //     </div>
  //   );
  // };

  // const subStatusTemplate = (props) => {
  //   return (
  //     <div>
  //       {" "}
  //       {props.subStatus === "Cash" ||
  //       props.subStatus === "Bank Transfer" ||
  //       props.subStatus === "Already Settled" ||
  //       props.subStatus === "UPI" ? (
  //         <span className="badge badge-rounded badge-success">
  //           {props.subStatus}
  //         </span>
  //       ) : props.subStatus === "Paid" ? (
  //         <span className="badge badge-rounded badge-success">
  //           {props.subStatus}
  //         </span>
  //       ) : props.subStatus === "Customer Not Available" ? (
  //         <span className="badge badge-rounded badge-danger">
  //           {props.subStatus}
  //         </span>
  //       ) : props.subStatus === "" || props.subStatus === null ? (
  //         <span>-</span>
  //       ) : (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.subStatus}
  //         </span>
  //       )}
  //     </div>
  //   );
  // };
  // const noOfPaymentLinksTemplate = (props) => {
  //   return (
  //     <div>
  //       <span
  //         style={{
  //           color: "blue",
  //           textDecoration: "underline",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => hyperlinkPaymentLink(props?.id)}
  //       >
  //         {props.noOfpaymentLinkSent}
  //       </span>
  //     </div>
  //   );
  // };
  // const addRemarksTemplate = (props) => {
  //   return (
  //     <div>
  //       <Link
  //         to="#"
  //         className="btn btn-primary shadow btn-xs sharp"
  //         onClick={() => {
  //           addRemarksIconModal(props?.id);
  //         }}
  //       >
  //         <i className="fa fa-plus"></i>
  //       </Link>
  //     </div>
  //   );
  // };
  // const sendPaymentLinkTemplate = (props) => {
  //   return (
  //     <div>
  //       <OverlayTrigger
  //         placement="bottom"
  //         delay={{ show: 250, hide: 400 }}
  //         overlay={<Tooltip>Send Payment Link</Tooltip>}
  //       >
  //         <span
  //           style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
  //           onClick={() => {
  //             sendCustomerBasicModal(props);
  //           }}
  //         >
  //           <i className="fa fa-paper-plane" style={{ color: "green" }}></i>
  //           &nbsp; Send
  //         </span>
  //       </OverlayTrigger>
  //     </div>
  //   );
  // };
  // const callOptionTemplate = (props) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "row",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <span>{props.contactNumber1}</span>
  //       {hasAccess(42) &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
  //         isProgressiveDialing === false && (
  //           <Link
  //             style={{ marginLeft: "10px" }}
  //             to="#"
  //             onClick={() => {
  //               if (isOnGoingCall === false) {
  //                 startCall(props?.contactNumber1, false, props?.id, props, "Collections");
  //               } else {
  //                 openModal();
  //               }
  //             }}
  //           >
  //             <div className="media-body user-meta-info">
  //               <ul>
  //                 <li>
  //                   <Link
  //                     to={"#"}
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       height: "30px",
  //                       width: "30px",
  //                     }}
  //                   >
  //                     <i
  //                       className="fas fa-phone-alt"
  //                       style={{ fontSize: "smaller" }}
  //                     ></i>
  //                   </Link>
  //                 </li>
  //               </ul>
  //             </div>
  //           </Link>
  //         )}
  //     </div>
  //   );
  // };
  // const callHistoryTemplate = (props) => {
  //   return (
  //     <div
  //       className="d-flex"
  //       style={{ justifyContent: "space-around" }}
  //       onClick={() => {
  //         var temp = {
  //           mobileNo: props.contactNumber1,
  //           loanNumber: props.loanNumber,
  //         };
  //         setMobileNo(temp);
  //         setCallReportModal(true);
  //       }}
  //     >
  //       <Link to="#" className="btn btn-info shadow btn-xs sharp">
  //         <i className="fa fa-eye"></i>
  //       </Link>
  //     </div>
  //   );
  // };

  // const getAllocationDetails = (row) => {
  //   return (
  //     <div>
  //       <span
  //         style={{
  //           color: "blue",
  //           textDecoration: "underline",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => {
  //           setAllocationDetails(row);
  //           setAllocationDetailsModal(true);
  //           console.log(allocationDetails);
  //         }}
  //       >
  //         {row.loanNumber}
  //       </span>
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   paymentsDownloadList !== null && handleExport();
  //   setIsLoading(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentsDownloadList]);

  // useEffect(() => {
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     currPage,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isMyAllocationsCalled]);

  // useEffect(() => {
  //   ReactGA.pageview("My Allocations");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    let path = window.location.pathname;

    path = path.split("/");

    var tempActiveKey = path[path.length - 2];
    tempActiveKey !== "ptp"
      ? setActiveKey("myAllocations")
      : setActiveKey("ptp");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   let path = window.location.pathname;

  //   path = path.split("/");

  //   var tempActiveKey = path[path.length - 2];

  //   tempActiveKey !== "ptp"
  //     ? setActiveKey("myAllocations")
  //     : setActiveKey("ptp");

  //   localStorage.setItem("filterValue", JSON.stringify([]));
  //   console.log("sortInfo", sortInfo);
  //   getAllCampaigns();
  //   updateProgressiveDialing(false)
  //   setCampaignName('')
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     0,
  //     selectedValue,
  //     selectedStatus,
  //     ''
  //   );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const renderSendButton = (data) => {
  //   return (
  //     <OverlayTrigger
  //       placement="bottom"
  //       delay={{ show: 250, hide: 400 }}
  //       overlay={<Tooltip>Send Payment Link</Tooltip>}
  //     >
  //       <span
  //         style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
  //         onClick={() => {
  //           sendCustomerBasicModal();
  //         }}
  //       >
  //         <i className="fa fa-paper-plane" style={{ color: "green" }}></i>&nbsp;
  //         Send
  //       </span>
  //     </OverlayTrigger>
  // <Tooltip>
  //   <span
  //     style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
  //     onClick={() => {
  //       sendCustomerBasicModal();
  //     }}
  //   >
  //     <i className="fa fa-paper-plane" style={{ color: "green" }}></i>&nbsp;
  //     Send
  //   </span>
  // </Tooltip>
  //   );
  // };

  // const sendCustomerBasicModal = (data) => {
  //   setBasicModalForSendPaymentLink(true);
  //   setDataForSendcustomerLink(data);
  // };

  // const sendCustomerPaymentLink = (values) => {
  //   // console.log(values.minutes, values.hours, values.days);
  //   var minsPayload = {
  //     allocationId: dataForSendCustomerLink?.id,
  //     amount: values.dueAmount * 100,
  //     expiryTimeInSeconds: values.minutes * 60,
  //     organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     phoneNumber: values.mobileNumber,
  //     remarks: values.remarks,
  //   };
  //   var hoursPayload = {
  //     allocationId: dataForSendCustomerLink?.id,
  //     amount: values.dueAmount * 100,
  //     expiryTimeInSeconds: values.hours * 60 * 60,
  //     organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     phoneNumber: values.mobileNumber,
  //     remarks: values.remarks,
  //   };
  //   var daysPayload = {
  //     allocationId: dataForSendCustomerLink?.id,
  //     amount: values.dueAmount * 100,
  //     expiryTimeInSeconds: values.days * 24 * 60 * 60,
  //     organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     phoneNumber: values.mobileNumber,
  //     remarks: values.remarks,
  //   };

  //   var customPaymentLinkPayload = {
  //     allocationId: dataForSendCustomerLink?.id,
  //     amount: values.dueAmount * 100,
  //     // expiryTimeInSeconds: values.days * 24 * 60 * 60,
  //     customPayLink: values.paymentLink,
  //     organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     phoneNumber: values.mobileNumber,
  //     remarks: values.remarks,
  //   };
  //   moduleApi
  //     .postData(
  //       apiCall.sendCustomerPaymentLink,
  //       hasAccess(52)
  //         ? isMinutesSelected === true
  //           ? minsPayload
  //           : isDaysSelected === false
  //           ? hoursPayload
  //           : daysPayload
  //         : hasAccess(51) && customPaymentLinkPayload
  //     )
  //     .then((response) => {
  //       // console.log("response", response);
  //       setBasicModalForSendPaymentLink(false);
  //       if (response) {
  //         if (response?.Message === "Payment Link Request Sent Sucessfully") {
  //           return swal("Payment Link Request Sent Successfully", {
  //             icon: "success",
  //           }).then((willDelete) => {
  //             if (willDelete) {
  //               getDailyScheduleDateForFilters(
  //                 object,
  //                 sortInfo,
  //                 start,
  //                 end,
  //                 0,
  //                 selectedValue,
  //                 selectedStatus,
  //                 campaignName
  //               );
  //             }
  //           });
  //         } else if (response.message) {
  //           return swal(response.message, {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       } else {
  //         return swal("Something Went Wrong, Please try again", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  // };
  // const addRemarksIconModal = (value) => {
  //   setAddRemarksBasicModal(true);

  //   console.log("value", value);
  //   setAddRemarksAllocationId(value);
  //   moduleApi
  //     .postData(apiCall.getAllocationRemarks + value)
  //     .then((response) => {
  //       console.log("response", response);
  //       if (response) {
  //         setRemarks(response);
  //       }
  //     });
  // };
  // const hyperlinkPaymentLink = (value) => {
  //   setBasicModalForPaymentLinks(true);

  //   moduleApi
  //     .getData(apiCall.getPaymentLinksByAllocation + value)
  //     .then((response) => {
  //       console.log("response", response);
  //       if (response) {
  //         setPaymentLinksByAllocationId(response);
  //       }
  //     });
  // };

  // const closeForSendPaymentLink = () => {
  //   setBasicModalForSendPaymentLink(false);
  // };

  // const handleCloseForRemarks = () => {
  //   setAddRemarksBasicModal(false);
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     0,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  // };

  // const callBackMethod = handleCloseForRemarks();

  // const handleRemarksChange = (event) => {
  //   setInputRemarks(event.target.value);
  // };
  // const handleCallStatusChange = (value) => {
  //   setSelectedCallStatus(value.value);
  // };

  // const addRemarksforcustomer = () => {
  //   if (selectedCallStatus === "") {
  //     return swal("Please Select Call Status", {
  //       icon: "error",
  //       dangerMode: true,
  //     });
  //   }
  //   if (inputRemarks === "") {
  //     return swal("Please Enter Remarks", {
  //       icon: "error",
  //       dangerMode: true,
  //     });
  //   }
  //   // let id = localStorage.getItem("allocationId");
  //   const payload = {
  //     allocationId: addRemarksAllocationId,
  //     remarkAddedBy: "",
  //     remarks: inputRemarks,
  //     remarksType: selectedCallStatus,
  //     userId: loggedInUser.autoFinanceUser.userId,
  //   };
  //   moduleApi
  //     .postData(apiCall.addRemarksForAllocation, payload)
  //     .then((response) => {
  //       // console.log("response", response);
  //       setAddRemarksBasicModal(false);
  //       if (response) {
  //         return swal("Remarks Added Successfully", {
  //           icon: "success",
  //         }).then((willDelete) => {
  //           if (willDelete) {
  //             getDailyScheduleDateForFilters(object, sortInfo, start, end, 0);
  //           }
  //         });
  //       }
  //     });
  // };
  // const submitRequestPaymentEditDetails = (values) => {
  //   const payload = {
  //     allocationId: dataForEditcustomer?.id,
  //     amount: values.dueAmount,
  //     mobileNumber: values.mobileNumber,
  //     organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     remarks: values.remarks,
  //   };
  //   moduleApi
  //     .postData(apiCall.editCustomerDetails, payload)

  //     .then((response) => {
  //       // console.log("response", response);
  //       setBasicModalForEdit(false);
  //       if (response) {
  //         if (response?.Message === "Edited Successfully") {
  //           return swal("Updated Successfully", {
  //             icon: "success",
  //             dangerMode: false,
  //           }).then((willDelete) => {
  //             if (willDelete) {
  //               getDailyScheduleDateForFilters(
  //                 filterValue,
  //                 sortInfo,
  //                 start,
  //                 end,
  //                 0
  //               );
  //             }
  //           });
  //         }
  //       }
  //     });
  // };
  // const getDailyScheduleDateForFilters = (
  //   filterchange,
  //   sortInfo,
  //   start,
  //   end,
  //   pageNo,
  //   isTelecallerAllocations,
  //   status,
  //   campaign
  // ) => {
  //   let listData1 = [];
  //   console.log(filterchange, sortInfo);
  //   // console.log("sortInfo", sortInfo);
  //   // console.log("filterValue", filterchange);
  //   setIsLoading(true);
  //   const payload = {
  //     startTime: start,
  //     endTime: end,
  //     filters: filterchange,
  //     organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     teleCallerUserId:
  //       isTelecallerAllocations === false
  //         ? loggedInUser.autoFinanceUser.userId
  //         : "",
  //     telecallerAllocations: isTelecallerAllocations,
  //     status: status,
  //     campaignName: campaign,
  //     page: pageNo,
  //     size: 10,
  //     sorts: sortInfo,
  //   };
  //   moduleApi
  //     .postData(apiCall.getdailyscheduleDataBasedOnFilters, payload)

  //     .then((response) => {
  //       setCurrPage(pageNo);
  //       response?.content?.forEach((element) => {
  //         let data = {
  //           id: element.id,
  //           customerName: element.customerName ? element.customerName : "-",
  //           dueAmount: element.dueAmount ? element.dueAmount : 0,
  //           contactNumber1: element.contactNumber1
  //             ? element.contactNumber1
  //             : "-",
  //           loanNumber: element.loanNumber ? element.loanNumber : "-",
  //           campaignCallIntiated: element.campaignCallIntiated,
  //           latestPaymentLinkId: element.latestPaymentLinkId
  //             ? element.latestPaymentLinkId
  //             : "-",
  //           latestPaymentLinkStatus: element.latestPaymentLinkStatus
  //             ? element.latestPaymentLinkStatus
  //             : "-",
  //           noOfpaymentLinkSent: element.noOfLinkSent
  //             ? element.noOfLinkSent
  //             : 0,
  //           status: element.status ? element.status : "-",
  //           priority: element.priority ? element.priority : "-",
  //           totalCalls: element.totalCalls ? element.totalCalls : 0,
  //           callsToday: element.callsToday ? element.callsToday : 0,
  //           visitStatus: element.visitStatus,
  //           subStatus: element.subStatus,
  //           currentBucketName: element.currentBucketName
  //             ? element.currentBucketName
  //             : "-",
  //           remarks: element.remarks ? element.remarks : "-",
  //           branchName: element.branchName ? element.branchName : "-",
  //           amountPaid: element.amountPaid ? element.amountPaid : 0,
  //           addressLine2: element.addressLine2,
  //           aggDate: element.aggDate ? helper.EpochToOnlyDate(element.aggDate) : "-",
  //           installmentDate: element.installmentDate
  //             ? EpochToDate(element.installmentDate)
  //             : "-",
  //           amountPaidNow: element.amountPaidNow,
  //           amountToBePaid: element.amountToBePaid,
  //           assetDesc: element.assetDesc,
  //           assignedMobileNumber: element.assignedMobileNumber,
  //           assignedName: element.assignedName,
  //           bounceCharges: element.bounceCharges,
  //           branchId: element.branchId,
  //           chassisNumber: element.chassisNumber,
  //           comments: element.comments,
  //           contactNumber: element.contactNumber1,
  //           contactNumber2: element.contactNumber2,
  //           cpName: element.cpName,
  //           currentBucket: element.currentBucketName,
  //           customerIntensinty: element.customerIntensinty,
  //           lenderId: element.lenderId,
  //           lenderName: element.lenderName,
  //           lenderAddress: element.promoterAddress,
  //           locLink1: element.locLink1,
  //           locLink2: element.locLink2,
  //           borrowerAddress: element.borrowerAddress,
  //           current_EMI_Month: element.currentEmiMonthNumber,
  //           dateTime:
  //             element.dateTime !== 0 ? EpochToDate(element.dateTime) : "-",
  //           lastPaidDate:
  //             element.lastPaidDate !== 0
  //               ? EpochToDate(element.lastPaidDate)
  //               : "-",
  //           expiredDate:
  //             element.expiredDate !== 0
  //               ? helper.EpochToOnlyDate(element.expiredDate)
  //               : "-",
  //           dueDate:
  //             element.dueDate !== 0
  //               ? helper.EpochToOnlyDate(element.dueDate)
  //               : "-",
  //           dueDay: element.dueDay ? element.dueDay : "-",
  //           emiAmount: element.emiAmount,
  //           emiOutstanding: element.emiOutstanding,
  //           engineNumber: element.engineNumber,
  //           employeeId: element.executiveEmployeeId
  //             ? element.executiveEmployeeId
  //             : element.managerEmployeeId,
  //           executiveMobileNumber: element.executiveMobileNumber,
  //           executiveName: element.executiveName,
  //           guarantorMobile: element.guarantorMobile,
  //           guarantorName: element.guarantorName,
  //           image: element.image,
  //           latePaymentCharges: element.latePaymentCharges,
  //           latitude: element.latitude,
  //           loanAmount: element.loanAmount,
  //           location: element.location,
  //           longitude: element.longitude,
  //           managerEmployeeId: element.managerEmployeeId,
  //           managerName: element.managerName,
  //           nearByLandmark: element.nearByLandmark,
  //           netOtherAmount: element.netOtherAmount,
  //           net_GDI_Amount: element.net_GDI_Amount,
  //           net_HL_Amount: element.net_HL_Amount,
  //           organizationId: element.organizationId,
  //           permanentAddress: element.permanentAddress,
  //           permanentCity: element.permanentCity,
  //           permanentState: element.permanentState,
  //           permanentZipcode: element.permanentZipcode,
  //           principalOutstanding: element.principalOutstanding,
  //           productName: element.productName,
  //           referenceMobile1: element.referenceMobile1,
  //           referenceMobile2: element.referenceMobile2,
  //           referenceName1: element.referenceName1,
  //           referenceName2: element.referenceName2,
  //           registrationNumber: element.registrationNumber,
  //           reportingOfficerId: element.reportingOfficerId,
  //           address: element.residenceAddress,
  //           residenceCity: element.residenceCity,
  //           residenceState: element.residenceState,
  //           residenceZipcode: element.residenceZipcode,
  //           teamLeaderEmployeeId: element.teamLeaderEmployeeId,
  //           teamLeaderName: element.teamLeaderName,
  //           teleCallerEmpId: element.teleCallerEmpId
  //             ? element.teleCallerEmpId
  //             : "-",
  //           teleCallerName: element.teleCallerName
  //             ? element.teleCallerName
  //             : "-",
  //           temporaryRegistrationNumber: element.temporaryRegistrationNumber,
  //           tenure: element.tenure,
  //           totalPenaltyCharges: element.totalPenaltyCharges,
  //           typeOfVisit: element.typeOfVisit,
  //           thirdPartyPhoneNumber: element.thirdPartyPhoneNumber,
  //           thirdPartyName: element.thirdPartyName,
  //           updatedDate:
  //             element.updatedDate !== 0
  //               ? EpochToDate(element.updatedDate)
  //               : "-",
  //           lastCallUpdateTime:
  //             element.lastCallUpdateTime !== 0
  //               ? EpochToDate(element.lastCallUpdateTime)
  //               : "-",
  //           userId: element.userId,
  //           vehicleType: element.vehicleType,
  //           emiAmountPaid: element.emiAmountPaid ? element.emiAmountPaid : 0,
  //           emiAmountToBePaid: element.emiAmountToBePaid
  //             ? element.emiAmountToBePaid
  //             : 0,
  //           bounceChargesPaid: element.bounceChargesPaid
  //             ? element.bounceChargesPaid
  //             : 0,
  //           bounceChargesToBePaid: element.bounceChargesToBePaid
  //             ? element.bounceChargesToBePaid
  //             : 0,
  //           latePaymentChargesPaid: element.latePaymentChargesPaid
  //             ? element.latePaymentChargesPaid
  //             : 0,
  //           latePaymentChargesToBePaid: element.latePaymentChargesToBePaid
  //             ? element.latePaymentChargesToBePaid
  //             : 0,
  //           penaltyChargesPaid: element.penaltyChargesPaid
  //             ? element.penaltyChargesPaid
  //             : 0,
  //           penaltyChargesToBePaid: element.penaltyChargesToBePaid
  //             ? element.penaltyChargesToBePaid
  //             : 0,
  //           otherChargesPaid: element.otherChargesPaid
  //             ? element.otherChargesPaid
  //             : 0,
  //           otherChargesToBePaid: element.otherChargesToBePaid
  //             ? element.otherChargesToBePaid
  //             : 0,
  //         };
  //         listData1.push(data);
  //       });
  //       setReqPaymentsList(listData1);
  //       setTotalElements(response?.totalElements);
  //       setIsLoading(false);
  //     });
  //   // }
  // };
  // const onPageChange = (selectedPage) => {
  //   setCurrPage(selectedPage - 1);
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     selectedPage - 1,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  // };

  // useEffect(() => {
  //   console.log("right");
  //   if (isProgressiveDialing === true) progressiveDialing();
  //   // eslint-disable-next-line
  // }, [isProgressiveDialing]);

  // useEffect(() => {
  //   console.log("left");
  //   if (isProgressiveDialing === true) progressiveDialing();
  //   // eslint-disable-next-line
  // }, [continueDialing]);

  // const progressiveDialing = () => {
  //   console.log("calling started");
  //   if (isProgressiveDialing === true) {
  //     if (reqPaymentsList?.length > 0) {
  //       if (reqPaymentsList[0]?.campaignCallIntiated === false) {
  //         startCall(
  //           reqPaymentsList[0]?.contactNumber1,
  //           false,
  //           reqPaymentsList[0]?.id,
  //           reqPaymentsList[0] ? reqPaymentsList[0] : null,
  //           "Collections"
  //         );
  //       } else {
  //         updateProgressiveDialing(false);
  //         return swal("All The Customers Have Been Called For This Campaign", {
  //           icon: "warning",
  //           dangerMode: false,
  //         });
  //       }
  //     }
  //     // else {
  //     //   updateProgressiveDialing(false);
  //     //   return swal("There Are No Allocations, Please Check", {
  //     //     icon: "error",
  //     //     dangerMode: true,
  //     //   });
  //     // }
  //   }
  // };

  // const setCustomDatesForGraph = (value1) => {
  //   var start = value1[0].getTime() / 1000;
  //   var end =
  //     new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
  //       1000 +
  //     86399;

  //   setStart(start);
  //   setEnd(end);
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     0,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  // };

  // const minutesPattern = /^(0?[1-9]|[1-5][0-9]|60)$/;
  // const hoursPattern = /^(1?[1-9]|2[0-4])$/;
  // const daysPattern = /^(0?[1-9]|[1-2][0-9]|30)$/;

  // const validate = Yup.object({
  //   minutes: Yup.string().when([], {
  //     is: () => isMinutesSelected && hasAccess(52),
  //     then: Yup.string()
  //       .matches(minutesPattern, "Please enter a number between 1 and 60")
  //       .required("Number of Minutes is required"),
  //     otherwise: Yup.string().notRequired(),
  //   }),
  //   hours: Yup.string().when([], {
  //     is: () => isHoursSelected && hasAccess(52),
  //     then: Yup.string()
  //       .matches(hoursPattern, "Please enter a number between 1 and 24")
  //       .required("Number of Hours is required"),
  //     otherwise: Yup.string().notRequired(),
  //   }),
  //   days: Yup.string().when([], {
  //     is: () => isDaysSelected && hasAccess(52),
  //     then: Yup.string()
  //       .matches(daysPattern, "Please enter a number between 1 and 30")
  //       .required("Number of Days is required"),
  //     otherwise: Yup.string().notRequired(),
  //   }),
  //   mobileNumber: Yup.string().required("Mobile Number is required"),
  //   paymentLink: Yup.string().when([], {
  //     is: () => hasAccess(51),
  //     then: Yup.string().required("Payment Link is required"),
  //     otherwise: Yup.string().notRequired(),
  //   }),
  //   remarks: Yup.string().required("Remarks is required"),
  //   dueAmount: Yup.string().required("Due Amount is required"),
  // });

  // const paymentsDownloadData = () => {
  //   setIsLoading(true);

  //   const payload = {
  //     startTime: start,
  //     endTime: end,
  //     filters: object,
  //     organizationId: loggedInUser.autoFinanceUser?.organizationId,
  //     teleCallerUserId:
  //       selectedValue === false ? loggedInUser.autoFinanceUser.userId : "",
  //     telecallerAllocations: selectedValue,
  //     status: selectedStatus,
  //     campaignName: campaignName,
  //     page: 0,
  //     size: 10,
  //     sorts: sortInfo,
  //   };

  //   moduleApi
  //     .postData(apiCall.getdailyscheduleDataBasedOnFilters, payload)
  //     .then((response) => {
  //       if (response) {
  //         if (response.totalElements > 5000) {
  //           setIsLoading(false);
  //           return swal({
  //             title: "Download Report",
  //             text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //             icon: "warning",
  //             buttons: true,
  //             dangerMode: true,
  //           }).then((willDelete) => {
  //             if (willDelete) {
  //               setIsLoading(true);
  //               getPaymentsFullResponseData(
  //                 Math.floor(response.totalElements / 200)
  //               );
  //             }
  //           });
  //         }

  //         getPaymentsFullResponseData(Math.floor(response.totalElements / 200));
  //       } else {
  //         swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  //   // }
  // };

  // async function getPaymentsFullResponseData(size) {
  //   var tempList = [];
  //   var mgrListData = [];

  //   for (let i = 0; i <= size; i++) {
  //     const payload = {
  //       startTime: start,
  //       endTime: end,
  //       filters: object,

  //       organizationId: loggedInUser.autoFinanceUser?.organizationId,
  //       teleCallerUserId:
  //         selectedValue === false ? loggedInUser.autoFinanceUser.userId : "",
  //       telecallerAllocations: selectedValue,
  //       campaignName: campaignName,
  //       status: selectedStatus,
  //       page: i,
  //       size: 200,
  //       sorts: sortInfo,
  //     };
  //     await moduleApi
  //       .postData(apiCall.getdailyscheduleDataBasedOnFilters, payload)
  //       .then((response) => {
  //         tempList.push(...response?.content);
  //       });
  //   }

  //   // console.log("templist", tempList);
  //   tempList?.forEach((element) => {
  //     let data = {
  //       id: element.id,
  //       customerName: element.customerName ? element.customerName : "-",
  //       aggDate: element.aggDate ? EpochToDate(element.aggDate) : "-",
  //       dueAmount: element.dueAmount ? element.dueAmount : "-",
  //       amountToBePaid: element.amountToBePaid ? element.amountToBePaid : "-",
  //       contactNumber1: element.contactNumber1 ? element.contactNumber1 : "-",
  //       loanNumber: element.loanNumber ? element.loanNumber : "-",
  //       cpName: element.cpName ? element.cpName : "-",
  //       latestPaymentLinkId: element.latestPaymentLinkId
  //         ? element.latestPaymentLinkId
  //         : "-",
  //       status: element.status ? element.status : "-",
  //       noOfpaymentLinkSent: element.noOfLinkSent ? element.noOfLinkSent : 0,
  //       currentBucketName: element.currentBucketName
  //         ? element.currentBucketName
  //         : "-",
  //       remarks: element.remarks ? element.remarks : "-",
  //       dueDay: element.dueDay ? element.dueDay : "-",
  //       totalCalls: element.totalCalls ? element.totalCalls : 0,
  //       callsToday: element.callsToday ? element.callsToday : 0,
  //       branchName: element.branchName ? element.branchName : "-",
  //       teleCallerEmpId: element.teleCallerEmpId
  //         ? element.teleCallerEmpId
  //         : "-",
  //       teleCallerName: element.teleCallerName ? element.teleCallerName : "-",
  //       amountPaid: element.amountPaid ? element.amountPaid : 0,
  //       visitStatus: element.visitStatus ? element.visitStatus : "-",
  //       subStatus: element.subStatus ? element.subStatus : "-",
  //       updatedDate:
  //         element.updatedDate !== 0 ? EpochToDate(element.updatedDate) : "-",
  //       lastPaidDate:
  //         element.lastPaidDate !== 0 ? EpochToDate(element.lastPaidDate) : "-",
  //       lastCallUpdateTime:
  //         element.lastCallUpdateTime !== 0
  //           ? EpochToDate(element.lastCallUpdateTime)
  //           : "-",
  //     };
  //     mgrListData.push(data);
  //   });
  //   setPaymentsDownloadList(mgrListData);
  //   // }
  // }

  // const handleExport = () => {
  //   let mgrTable = [
  //     {
  //       A: "Customer Name",
  //       B: "Customer Number",
  //       C: "CP Name",
  //       D: "Loan Number",
  //       E: "Today Calls",
  //       F: "Total Calls",
  //       G: "Last Called Date & Time",
  //       H: "Last Paid",
  //       I: "Due Day",
  //       J: "AG Date",
  //       K: "Emp Id",
  //       L: "Emp Name",
  //       M: "Branch",
  //       N: "Current Bucket",
  //       O: "Amount Paid",
  //       P: "Current Due Amount",
  //       Q: "Total Due Amount",
  //       R: "Visit Status",
  //       S: "Sub Status",
  //       T: "Allocation Status",
  //       U: "No Of Payment Links Sent",
  //       V: "Last Visited Date & Time",
  //     },
  //   ];
  //   paymentsDownloadList?.forEach((row) => {
  //     mgrTable.push({
  //       A: row.customerName,
  //       B: row.contactNumber1,
  //       C: row.cpName,
  //       D: row.loanNumber,
  //       E: row.callsToday,
  //       F: row.totalCalls,
  //       G: row.lastCallUpdateTime,
  //       H: row.lastPaidDate,
  //       I: row.dueDay,
  //       J: row.aggDate,
  //       K: row.teleCallerEmpId,
  //       L: row.teleCallerName,
  //       M: row.branchName,
  //       N: row.currentBucketName,
  //       O: row.amountPaid,
  //       P: row.amountToBePaid,
  //       Q: row.dueAmount,
  //       R: row.visitStatus,
  //       S: row.subStatus,
  //       T: row.status,
  //       U: row.noOfpaymentLinkSent,
  //       V: row.updatedDate,

  //       // I: row.latestPaymentLinkId,
  //     });
  //   });
  //   const finalData = [...mgrTable];
  //   // console.log("finaldata", finalData);
  //   //create a new workbook
  //   const wb = XLSX.utils.book_new();
  //   const sheet = XLSX.utils.json_to_sheet(finalData, {
  //     skipHeader: true,
  //   });
  //   // console.log("sheet", sheet);
  //   XLSX.utils.book_append_sheet(
  //     wb,
  //     sheet,
  //     selectedValue === false ? "My_Allocations_" : "All_Allocations"
  //   );
  //   XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

  //   XLSX.writeFile(
  //     wb,
  //     (campaignName === "" ? "" : campaignName + "_") +
  //       (selectedValue === false ? "My_Allocations_" : "All_Allocations_") +
  //       helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //       ".xlsx"
  //   );
  //   ReactGA.event({
  //     category: "My_Allocations List Download",
  //     action:
  //       userLoginDetails.autoFinanceOrganization.orgshortcode +
  //       (campaignName === "" ? "" : campaignName + "_") +
  //       (selectedValue === false ? "_My_Allocations_" : "_All_Allocations_") +
  //       helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //       ".xlsx",
  //   });
  // };

  // const handleLoanNumberDetailsModal = () => {
  //   setAllocationDetailsModal(false);
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     0,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  // };

  // const sendBulkPaymentLinks = (values) => {
  //   var minsPayload = {
  //     allocationIds: selectedIds,
  //     organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //     sendForAllAllocations: isAllIdsSelected,
  //     timeOut: values.minutes * 60,
  //   };
  //   var hoursPayload = {
  //     allocationIds: selectedIds,
  //     organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //     sendForAllAllocations: isAllIdsSelected,
  //     timeOut: values.hours * 60 * 60,
  //   };
  //   var daysPayload = {
  //     allocationIds: selectedIds,
  //     organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //     sendForAllAllocations: isAllIdsSelected,
  //     timeOut: values.days * 24 * 60 * 60,
  //   };
  //   console.log(
  //     "payload ",
  //     isMinutesSelected === true
  //       ? minsPayload
  //       : isDaysSelected === false
  //       ? hoursPayload
  //       : daysPayload
  //   );
  //   moduleApi
  //     .postData(
  //       apiCall.sendBulkPaymentLinks,
  //       isMinutesSelected === true
  //         ? minsPayload
  //         : isDaysSelected === false
  //         ? hoursPayload
  //         : daysPayload
  //     )
  //     .then((response) => {
  //       console.log("bulkSMSResponse", response);
  //       // Bulk Payment Link Request Saved Sucessfully
  //       if (response) {
  //         if (response?.message) {
  //           return swal(response?.message, {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         } else if (
  //           response?.Message === "Bulk Payment Link Request Saved Sucessfully"
  //         ) {
  //           setBasicModal(false);
  //           setSelectedIds([]);
  //           setIsAllIdsSelected(false);
  //           getRequestPaymentsData(
  //             start,
  //             end,
  //             0,
  //             selectedValue,
  //             value,
  //             selectedLinkStatus
  //           );
  //           return swal("Bulk Payment Link Request Sent Successfully", {
  //             icon: "success",
  //           });
  //         }
  //       } else {
  //         return swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  // };

  // const optionDates = [
  //   { label: "All Allocations", value: true },
  //   { label: "My Allocations", value: false },
  // ];

  // const statusOptions = [
  //   { label: "All", value: "" },
  //   { label: "Open", value: "OPEN" },
  //   { label: "In Progress", value: "INPROGRESS" },
  //   { label: "Completed", value: "COMPLETED" },
  // ];

  // // const handleChange = (event) => {
  // //   const inputValue = event.target.value;
  // //   // Check if input value is a whole number
  // //   if (/^\d+$/.test(inputValue)) {
  // //     setErrorMessage(false);
  // //     setValue(inputValue);
  // //     getRequestPaymentsData(
  // //       start,
  // //       end,
  // //       0,
  // //       selectedValue,
  // //       inputValue,
  // //       selectedLinkStatus
  // //     );
  // //   } else {
  // //     setErrorMessage(true);
  // //   }
  // // };

  // // const getHeaderTemplate=(value)=>{
  // //   return (
  // //     <div style={{backgroundColor: "rgb(243,240,249)",}}>
  // //       {value}
  // //     </div>
  // //   )
  // // }

  // // let selIndex = [];
  // // // let grid;
  // // const settings = { type: "Multiple" };
  // const rowDataBound = (args) => {
  //   // console.log(args);
  //   if (getValue("campaignCallIntiated", args.data) === true) {
  //     // selIndex.push(parseInt(args.row.getAttribute("aria-rowindex"), 0) - 1);
  //     args.row.classList.add("below-30");
  //   }
  // };
  // const dataBound = () => {
  //   if (gridInstance && selIndex.length) {
  //     gridInstance.selectRows(selIndex);
  //     selIndex = [];
  //   }
  // };

  return (
    <LoadingOverlay
      active={isLoading2}
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div>
        <div className="card" style={{ padding: "10px" }}>
          <div className="custom-tab-1">
            <Tab.Container
              defaultActiveKey="myAllocations"
              activeKey={activeKey}
            >
              <Nav as="ul" className="nav-tabs-custom nav-justified">
                <Nav.Item as="li" key="0">
                  <Nav.Link
                    eventKey="myAllocations"
                    onClick={() => {
                      setActiveKey("myAllocations");
                    }}
                  >
                    <b> My Allocations </b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key="1">
                  <Nav.Link
                    eventKey="ptp"
                    onClick={() => {
                      setActiveKey("ptp");
                    }}
                  >
                    <b> PTP </b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key="2">
                  <Nav.Link
                    eventKey="callback"
                    onClick={() => {
                      setActiveKey("callback");
                    }}
                  >
                    <b> Call Backs </b>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane
                  eventKey="myAllocations"
                  key="0"
                  mountOnEnter
                  unmountOnExit
                >
                  <NewMyAllocations2 />
                </Tab.Pane>

                <Tab.Pane eventKey="ptp" key="1" mountOnEnter unmountOnExit>
                  <PtpNotificationsList />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="callback"
                  key="2"
                  mountOnEnter
                  unmountOnExit
                >
                  <CallBackList />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>

          {/* <Modal className="fade" show={basicModalForPaymentLinks} size="lg">
            <Modal.Header>
              <Modal.Title>Payment Link Details</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => {
                  setBasicModalForPaymentLinks(false);
                  getDailyScheduleDateForFilters(
                    object,
                    sortInfo,
                    start,
                    end,
                    0,
                    selectedValue,
                    selectedStatus,
                    campaignName
                  );
                }}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              {paymentLinksByAllocationId?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table">
                    <thead
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        top: "1px",
                        position: "sticky",
                      }}
                    >
                      <tr style={{ textAlign: "center" }}>
                        <th scope="col">Paid Amount</th>
                        <th scope="col">Mobile Number</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Link Sent Date</th>
                        <th scope="col">Payment Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentLinksByAllocationId.map((item) => (
                        <tr
                          style={{ textAlign: "center", fontWeight: "normal" }}
                          key={item.id}
                        >
                          {item.amount ? (
                            <td>{item.amount / 100}</td>
                          ) : (
                            <td>-</td>
                          )}
                          {item.customerMobileNumber ? (
                            <td>{item.customerMobileNumber}</td>
                          ) : (
                            <td>-</td>
                          )}
                          {item.remarks ? <td>{item.remarks}</td> : <td>-</td>}
                          {item.createdTime ? (
                            <td>{helper.EpochToDate(item.createdTime)}</td>
                          ) : (
                            <td>-</td>
                          )}
                          {item.paymentStatus === "PAYMENT_ERROR" ? (
                            <td>
                              {" "}
                              <span className="badge badge-rounded badge-danger">
                                {item.paymentStatus}
                              </span>
                            </td>
                          ) : item.paymentStatus === "TRANSACTION_NOT_FOUND" ? (
                            <td>
                              {" "}
                              <span className="badge badge-rounded badge-warning">
                                {item.paymentStatus}
                              </span>
                            </td>
                          ) : item.paymentStatus === "PAYMENT_SUCCESS" ? (
                            <td>
                              {" "}
                              <span className="badge badge-rounded badge-success">
                                {item.paymentStatus}
                              </span>
                            </td>
                          ) : item.paymentStatus === "INTERNAL_SERVER_ERROR" ? (
                            <td>
                              <span className="badge badge-rounded badge-primary">
                                {item.paymentStatus}
                              </span>
                            </td>
                          ) : item.paymentStatus === "UPI_BACKBONE_ERROR" ? (
                            <td>
                              <span className="badge badge-rounded badge-danger">
                                {item.paymentStatus}
                              </span>
                            </td>
                          ) : item.paymentStatus === "REQUEST_TIMEOUT" ? (
                            <td>
                              <span className="badge badge-rounded badge-warning">
                                {item.paymentStatus}
                              </span>
                            </td>
                          ) : item.paymentStatus ===
                            "REQUEST_CANCEL_BY_REQUESTER" ? (
                            <td>
                              <span className="badge badge-rounded badge-primary">
                                {item.paymentStatus}
                              </span>
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <span style={{ textAlignLast: "center" }}>No Data Found</span>
              )}
            </Modal.Body>
          </Modal> */}

          {/* <Modal className="fade" show={basicModalForEdit}>
        <Modal.Header>
          <Modal.Title>Edit Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModalForEdit(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                mobileNumber: dataForEditcustomer?.contactNumber1,
                remarks: dataForEditcustomer?.remarks,
                dueAmount: dataForEditcustomer?.dueAmount,
              }}
              onSubmit={submitRequestPaymentEditDetails}
              validationSchema={validateForEdit}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <div>
                            <TextField
                              isAsterisk={true}
                              label="Mobile Number"
                              name="mobileNumber"
                              type="number"
                            />
                            <TextField
                              isAsterisk={true}
                              label="Due Amount"
                              name="dueAmount"
                              type="number"
                            />
                            <TextField
                              isAsterisk={true}
                              label="Remarks"
                              name="remarks"
                              type="text"
                            />
                          </div>
                          <div>
                            <Button
                              className="col-lg-2 m-2 float-right px-3"
                              type="submit"
                            >
                              Update
                            </Button>
                            <Button
                              className="col-lg-2 m-2 float-right px-3"
                              variant="danger light"
                              onClick={close}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal> */}

          {/* <Modal className="fade" show={basicModalForSendPaymentLink}>
            <Modal.Header>
              <Modal.Title>Send Customer Payment Link</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setBasicModalForSendPaymentLink(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Formik
                  initialValues={{
                    minutes: "",
                    hours: "",
                    days: "",
                    mobileNumber: dataForSendCustomerLink?.contactNumber1,
                    remarks: "",
                    paymentLink: "",
                    dueAmount: dataForSendCustomerLink?.dueAmount,
                  }}
                  onSubmit={sendCustomerPaymentLink}
                  validationSchema={validate}
                >
                  {({ formik, errors }) => (
                    <div>
                      <Row>
                        <Col lg="12">
                          <Form>
                            <div>
                              <div>
                                <TextField
                                  isAsterisk={true}
                                  label="Mobile Number"
                                  name="mobileNumber"
                                  type="number"
                                />
                                <TextField
                                  isAsterisk={true}
                                  label="Due Amount"
                                  name="dueAmount"
                                  type="number"
                                />
                                <TextField
                                  isAsterisk={true}
                                  label="Remarks"
                                  name="remarks"
                                  type="text"
                                />
                                {hasAccess(52) && (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        height: "40px",
                                      }}
                                    >
                                      <div
                                        class="form-check"
                                        onClick={() => {
                                          setIsMinutesSelected(true);
                                          setIsHoursSelected(false);
                                          setIsDaysSelected(false);
                                        }}
                                        style={{ margin: "0px 20px" }}
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked={isMinutesSelected}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexRadioDefault1"
                                        >
                                          Minutes
                                        </label>
                                      </div>
                                      <div
                                        class="form-check"
                                        onClick={() => {
                                          setIsMinutesSelected(false);
                                          setIsHoursSelected(true);
                                          setIsDaysSelected(false);
                                        }}
                                        style={{ margin: "0px 20px" }}
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault2"
                                          checked={isHoursSelected}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexRadioDefault2"
                                        >
                                          Hours
                                        </label>
                                      </div>
                                      <div
                                        class="form-check"
                                        onClick={() => {
                                          setIsMinutesSelected(false);
                                          setIsHoursSelected(false);
                                          setIsDaysSelected(true);
                                        }}
                                        style={{ margin: "0px 20px" }}
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault3"
                                          checked={isDaysSelected}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexRadioDefault3"
                                        >
                                          Days
                                        </label>
                                      </div>
                                    </div>
                                    {isMinutesSelected ? (
                                      <div>
                                        <TextField
                                          isAsterisk={true}
                                          label="Number Of Minutes"
                                          name="minutes"
                                          type="text"
                                        />
                                        <span style={{ fontSize: "smaller" }}>
                                          Must be a number between 1 and 60.
                                        </span>
                                      </div>
                                    ) : isDaysSelected === false ? (
                                      <div>
                                        <TextField
                                          isAsterisk={true}
                                          label="Number of Hours"
                                          name="hours"
                                          type="text"
                                        />
                                        <span style={{ fontSize: "smaller" }}>
                                          Must be a number between 1 and 24.
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        <TextField
                                          isAsterisk={true}
                                          label="Number of Days"
                                          name="days"
                                          type="text"
                                        />
                                        <span style={{ fontSize: "smaller" }}>
                                          Must be a number between 1 and 30.
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {hasAccess(51) && (
                                  <TextField
                                    isAsterisk={true}
                                    label="Payment Link"
                                    name="paymentLink"
                                    type="text"
                                  />
                                )}
                              </div>
                              <div>
                                <Button
                                  className="col-lg-2 m-2 float-right px-3"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                                <Button
                                  className="col-lg-2 m-2 float-right px-3"
                                  variant="danger light"
                                  onClick={closeForSendPaymentLink}
                                >
                                  Close
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Formik>
              </div>
            </Modal.Body>
          </Modal> */}

          {/* <Modal className="fade" show={callAudioModal} size="md">
        <Modal.Header>
          <Modal.Title>Manual Call</Modal.Title>
          {isCallRejected === true && (
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                closeModal();
                // setCallAudioModal(false);
                setCallMobileNo(null);
                // setIsOnGoingCall(false);
              }}
            ></Button>
          )}
          {isCallRejected === false && (
            <Button
              variant=""
              // className="btn-minus"
              onClick={() => {
                minimizeModal();
              }}
            >
              <div
                style={{
                  // marginBottom: "5px",
                  borderRadius: "50%",
                  border: "2px solid #25A6E7",
                  boxShadow: "1px 1px 2px #25A6E7",
                  backgroundColor: "#25A6E7",
                  padding: "10px",
                  height: "10px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i class="fa fa-minus" style={{ color: "white" }}></i>
              </div>
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          <Row style={{ justifyContent: "center" }}>
            {isMobileField === true && (
              <label style={{ fontWeight: "bold", textAlign: "center" }}>
                Mobile No
              </label>
            )}
            {isMobileField === true && (
              <input
                style={{
                  width: "300px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  // marginLeft: "10px",
                }}
                type="number"
                minLength={0}
                maxLength={9999999999}
                placeholder="Enter Mobile No"
                onChange={(e) => {
                  setCallMobileNo(e.target.value);
                }}
              />
            )}

            {isMobileField === true &&
              (callMobileNo?.length < 10 || callMobileNo?.length > 10) && (
                <span style={{ marginTop: "5px" }} className="error">
                  Mobile No is Invalid
                </span>
              )}
            {isMobileField === true && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => {
                    // setCallAudioModal(false);
                    closeModal();
                    setCallMobileNo(null);
                    // setIsOnGoingCall(false);
                    // setIsMobileField(true);
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => {
                    if (callMobileNo !== null) {
                      if (callMobileNo?.length === 10) {
                        // makeCall(callMobileNo);
                        // setCallAudioModal(false);
                        // piopiy.call("91" + callMobileNo);
                        startCall(callMobileNo, true);
                        // setIsMobileField(false);
                        // setIsCallAnswered(true);
                        // setIsCallRejected(false);
                      } else if (
                        callMobileNo?.length < 10 ||
                        callMobileNo?.length > 10
                      ) {
                        return swal("Please Enter Valid Mobile No", {
                          icon: "error",
                          dangerMode: true,
                        });
                      }
                    } else if (callMobileNo === null) {
                      return swal("Please Enter Mobile No", {
                        icon: "error",
                        dangerMode: true,
                      });
                    }
                  }}
                >
                  <i className="fa fa-phone fa-1x fa-rotate-90" /> Call
                </Button>
              </div>
            )}
            {isCallAnswered === true && (
              <span style={{ textAlignLast: "center" }}>
                Calling to{" "}
                <span style={{ fontWeight: "bold" }}>{callMobileNo}</span>...{" "}
              </span>
            )}
            {isCallAnswered === true && (
              <img
                src="/sidebar/phone.gif"
                alt=""
                style={{
                  width: "100px",
                  height: "75px",
                  // marginLeft: "60px",
                  marginTop: "20px",
                }}
              />
            )}
            {isCallAnswered === true && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop:'40px' }}>
                <div
                  onClick={() => {
                    endCall();
                    // piopiy.terminate();
                    // setIsOnGoingCall(false);
                    setCallMobileNo(null);
                    // setIsHold(false);
                    // setIsMute(false);
                    // setIsMobileField(true);
                    // setIsCallRejected(true);
                  }}
                  style={{
                    marginBottom: "5px",
                    borderRadius: "50%",
                    border: "2px solid red",
                    boxShadow: "1px 1px 2px #008000",
                    backgroundColor: "red",
                    padding: "10px",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fa fa-phone fa-2x fa-rotate-270"
                    style={{
                      color: "white",
                    }}
                  />
                </div>
                <span style={{ fontWeight: "bold" }}>
                  End
                </span>
              </div>
            )}
            {isOnGoingCall === true && (
              <span style={{ marginBottom: "10px", textAlignLast: "center" }}>
                Call with{" "}
                <span style={{ fontWeight: "bold" }}>{callMobileNo}</span> is in
                progress...
              </span>
            )}
            {isOnGoingCall === true && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {isHold === true && (
                    <div
                      onClick={() => {
                        unHoldCall();
                        // piopiy.unHold();
                        // setIsHold(false);
                        // setIsCallRejected(false);
                        console.log("hold---false");
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid grey",
                        boxShadow: "1px 1px 2px grey",
                        backgroundColor: "grey",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-pause fa-2x "
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  )}
                  {isHold === false && (
                    <div
                      onClick={() => {
                        holdCall();
                        // piopiy.hold();
                        // setIsHold(true);
                        // setIsCallRejected(false);
                        console.log("hold---true");
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid black",
                        boxShadow: "1px 1px 2px black",
                        backgroundColor: "white",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-pause fa-2x "
                        style={{
                          color: "black",
                        }}
                      />
                    </div>
                  )}
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    Hold
                  </span>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    onClick={() => {
                      endCall();
                      // piopiy.terminate();
                      // setIsOnGoingCall(false);
                      setCallMobileNo(null);
                      // setIsHold(false);
                      // setIsMute(false);
                      // setIsMobileField(true);
                      // setIsCallRejected(true);
                    }}
                    style={{
                      marginBottom: "5px",
                      borderRadius: "50%",
                      border: "2px solid red",
                      boxShadow: "1px 1px 2px #008000",
                      backgroundColor: "red",
                      padding: "10px",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-phone fa-2x fa-rotate-270"
                      style={{
                        color: "white",
                      }}
                    />
                  </div>
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    End
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {isMute === true && (
                    <div
                      onClick={() => {
                        unmuteCall();
                        // piopiy.unMute();
                        // setIsMute(false);
                        // setIsCallRejected(false);
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid grey",
                        boxShadow: "1px 1px 2px grey",
                        backgroundColor: "grey",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-microphone-slash fa-2x"
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  )}
                  {isMute === false && (
                    <div
                      onClick={() => {
                        muteCall();
                        // piopiy.mute();
                        // setIsMute(true);
                        // setIsCallRejected(false);
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid black",
                        boxShadow: "1px 1px 2px black",
                        backgroundColor: "white",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-microphone-slash fa-2x"
                        style={{
                          color: "black",
                        }}
                      />
                    </div>
                  )}
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    Mute
                  </span>
                </div>
              </div>
            )}
          </Row>
        </Modal.Body>
      </Modal> */}

          {/* <Modal className="fade" show={addRemarksBasicModal} size="lg">
            <Modal.Header>
              <Modal.Title>Add Remarks</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setAddRemarksBasicModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <AddRemarks
                  allocationId={addRemarksAllocationId}
                  callId={null}
                  campaignName={campaignName}
                  close={handleCloseForRemarks}
                  type={"Collections"}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                    width: "100%",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>Previous Remarks</label>
                  {remarks?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead
                          style={{
                            backgroundColor: "rgb(243,240,249)",
                            top: "1px",
                            position: "sticky",
                          }}
                        >
                          <tr style={{ textAlign: "center" }}>
                            <th scope="col">Remarks</th>
                            <th scope="col">Remarks Added By</th>
                            <th scope="col">Call Status</th>
                            <th scope="col">Status</th>
                            <th scope="col">Sub Status</th>
                            <th scope="col">Amount Paid</th>
                            <th scope="col">Transaction Id</th>
                            <th scope="col">PTP Date</th>
                            <th scope="col">PTP Slot</th>
                            <th scope="col">PTP Amount</th>
                            <th scope="col">Call Back Date</th>
                            <th scope="col">Call Back Slot</th>
                            <th scope="col">Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {remarks?.map((item) => (
                            <tr
                              style={{
                                textAlign: "center",
                                fontWeight: "normal",
                              }}
                              key={item.id}
                            >
                              <td>{item.remarks}</td>
                              <td>
                                {item.remarkAddedBy ? item.remarkAddedBy : "-"}
                              </td>
                              <td>
                                {item.remarksType ? item.remarksType : "-"}
                              </td>
                              <td>{item.status ? item.status : "-"}</td>
                              <td>{item.subStatus ? item.subStatus : "-"}</td>
                              <td>{item.amountPaid ? item.amountPaid : "-"}</td>
                              <td>
                                {item.transactionId ? item.transactionId : "-"}
                              </td>
                              <td>
                                {item.toBePaidBy
                                  ? EpochToDate(item.toBePaidBy)
                                  : "-"}
                              </td>
                              <td>{item.ptpSlot ? item.ptpSlot : "-"}</td>
                              <td>{item.ptpAmount ? item.ptpAmount : "-"}</td>
                              <td>
                                {item.callBackDate
                                  ? EpochToDate(item.callBackDate)
                                  : "-"}
                              </td>
                              <td>
                                {item.callBackSlot ? item.callBackSlot : "-"}
                              </td>
                              {item.createdTime ? (
                                <td>{EpochToDate(item.createdTime)}</td>
                              ) : (
                                <td>-</td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                   
                    </div>
                  ) : (
                    <span style={{ textAlignLast: "center" }}>
                      No Previous Remarks Found
                    </span>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className="fade" show={callReportModal} size="lg">
            <Modal.Header>
              <Modal.Title>Call History</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setCallReportModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <CallSummary
                mobileNo={mobileNo?.mobileNo}
                loanNumber={mobileNo?.loanNumber}
              />
            </Modal.Body>
          </Modal>

          <Modal className="fade" size="lg" show={allocationDetailsModal}>
            <Modal.Header>
              <Modal.Title>Allocation Details</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => {
                  setAllocationDetailsModal(false);
                }}
              ></Button>
            </Modal.Header>
            <Modal.Body
              style={{
                paddingTop: "0px",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            ></Modal.Body>
            <LoanNumberDetails
              allocationDetails={allocationDetails}
              close={handleLoanNumberDetailsModal}
            />
          </Modal> */}

          {/* <Modal className="fade" size="md" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Send Bulk SMS</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setIsMinutesSelected(true);
              setIsHoursSelected(false);
              setIsDaysSelected(false);
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div>
            <Formik
              initialValues={{
                minutes: "",
                hours: "",
                days: "",
              }}
              onSubmit={sendBulkPaymentLinks}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <div style={{ display: "flex", height: "40px" }}>
                            <div
                              class="form-check"
                              onClick={() => {
                                setIsMinutesSelected(true);
                                setIsHoursSelected(false);
                                setIsDaysSelected(false);
                              }}
                              style={{ margin: "0px 20px" }}
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked={isMinutesSelected}
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Minutes
                              </label>
                            </div>
                            <div
                              class="form-check"
                              onClick={() => {
                                setIsMinutesSelected(false);
                                setIsHoursSelected(true);
                                setIsDaysSelected(false);
                              }}
                              style={{ margin: "0px 20px" }}
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                checked={isHoursSelected}
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Hours
                              </label>
                            </div>
                            <div
                              class="form-check"
                              onClick={() => {
                                setIsMinutesSelected(false);
                                setIsHoursSelected(false);
                                setIsDaysSelected(true);
                              }}
                              style={{ margin: "0px 20px" }}
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault3"
                                checked={isDaysSelected}
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault3"
                              >
                                Days
                              </label>
                            </div>
                          </div>
                          {isMinutesSelected ? (
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Number Of Minutes"
                                name="minutes"
                                type="text"
                              />
                              <span style={{ fontSize: "smaller" }}>
                                Must be a number between 1 and 60.
                              </span>
                            </div>
                          ) : isDaysSelected === false ? (
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Number of Hours"
                                name="hours"
                                type="text"
                              />
                              <span style={{ fontSize: "smaller" }}>
                                Must be a number between 1 and 24.
                              </span>
                            </div>
                          ) : (
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Number of Days"
                                name="days"
                                type="text"
                              />
                              <span style={{ fontSize: "smaller" }}>
                                Must be a number between 1 and 30.
                              </span>
                            </div>
                          )}
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            // className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right", marginRight: "10px" }}
                            // className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setIsMinutesSelected(true);
                              setIsHoursSelected(false);
                              setIsDaysSelected(false);
                              setBasicModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
            {/* <form onSubmit={sendBulkPaymentLinks}>
              <div style={{display:'flex', flexDirection:'column'}}>
                <label>
                  <input
                    type="radio"
                    value="minutes"
                    checked={isMinutesSelected}
                    onChange={(e) => {
                      setTimeOption(e.target.value);
                    }}
                  />
                  Minutes
                </label>
                <label>
                  <input
                  style={{marginTop:'5px'}}
                    type="radio"
                    value="hours"
                    checked={!isMinutesSelected}
                    onChange={(e) => {
                        setTimeOption(e.target.value);
                    }}
                  />
                  Hours
                </label>
              </div>
              {isMinutesSelected ? (
                <div style={{display:'flex', flexDirection:'column'}}>
                  <label>
                    Minutes:
                    <input
                      style={{
                        width: "220px",
                        height: "35px",
                        borderRadius: "25px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #3a353b",
                        paddingLeft: "10px",
                        marginLeft: "10px",
                      }}
                      placeholder="Enter Minutes"
                      type="number"
                    //   value={minutes}
                      onChange={(e) => {
                        setMinutes(e.target.value);
                      }}
                      max="1440"
                    />
                  </label>
                </div>
              ) : (
                <div>
                  <label>
                    Hours:
                    <input
                      style={{
                        width: "220px",
                        height: "35px",
                        borderRadius: "25px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #3a353b",
                        paddingLeft: "10px",
                        marginLeft: "10px",
                      }}
                      placeholder="Enter Hours"
                      type="number"
                    //   value={hours}
                      onChange={(e) => {
                        setHours(e.target.value);
                      }}
                      max="24"
                    />
                  </label>
                </div>
              )}
              <Button
                style={{ float: "right", width: "70px" }}
                className="col-lg-2 m-2"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{ float: "right", width: "70px" }}
                className="col-lg-2 m-2 float-right"
                variant="danger light"
                onClick={() => setBasicModal(false)}
              >
                Close
              </Button>
            </form> */}
          {/* </div>
        </Modal.Body>
      </Modal> */}
        </div>
        <div>
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary light"
            onClick={() => {
              setIsLoading2(true);
              var imageAddr =
                "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/sample3.jpg";
              var downloadSize = 7300000; //bytes
              var startTime, endTime;
              var download = new Image();
              download.onload = function () {
                endTime = new Date().getTime();
                showResults();
              };

              startTime = new Date().getTime();
              var cacheBuster = "?nnn=" + startTime;
              download.src = imageAddr + cacheBuster;

              function showResults() {
                var duration = (endTime - startTime) / 1000;
                var bitsLoaded = downloadSize * 8;
                var speedBps = (bitsLoaded / duration).toFixed(2);
                var speedKbps = (speedBps / 1024).toFixed(2);
                var speedMbps = (speedKbps / 1024).toFixed(2);
                setIsLoading2(false);
                return swal(
                  "Minimum Expected Internet Speed For Calling is 9 Mbps.Your Internet Connection Speed is " +
                    speedMbps +
                    " Mbps",
                  {
                    icon: "info",
                    dangerMode: false,
                  }
                );
              }
            }}
          >
            <i class="fas fa-tachometer-alt"></i>&nbsp;&nbsp;Check Network Speed
          </Button>
          {hasAccess(42) &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
            isProgressiveDialing === false && (
              <Button
                style={{
                  float: "right",
                  margin: "10px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success light"
                onClick={() => {
                  if (isOnGoingCall === false && isIncomingCall===false) {
                    // var piopiy = new PIOPIY({
                    //   name: "CLU",
                    //   debug: false,
                    //   autoplay: true,
                    //   ringTime: 60,
                    // });
                    StartCall(
                      piopiy,
                      dispatch,
                      userLoginDetails?.autoFinanceUser?.mobileNumber,
                      false,
                      null,
                      null,
                      isCallActive
                    );
                    // piopiy.call("91" + row?.contactNumber);
                    // setCallMobileNo(props?.contactNumber1);
                  } else {
                    dispatch(setCallAudioModal(true));
                    dispatch(setIsModalMinimised(false));
                  }
                }}
              >
                <i className="fa fa-phone fa-1x fa-rotate-90" />
                &nbsp;&nbsp;Call Me
              </Button>
            )}
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default MyAllocations;
