import React from "react"
import * as Yup from "yup"
import { Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import 'react-toastify/dist/ReactToastify.css';
import TextField from "../../CommonComponents/TextField";
import { Field } from "formik";
import Select from "react-select";




// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


const TpcCheck = ({ key, mainOBJ, onChange, errors }) => {
    let confirmationOptions = [
        { label: "Positive", value: "Positive" },
        { label: "Negative", value: "Negative" },
      ];

  return (
    <div style={{ margin: "15px" }}>
      <Row>
        <Col>
          <TextField isAsterisk={true} label="Neighbour/Third Party Reference 1 Name" name="neighbor_reference1" type="text" />
          {/* <TextField isAsterisk={true} label="Neighbour/ThirdParty 1 Feedback" name="neighbor_reference1_confirmation" type="text" /> */}
          <Field name="neighbor_reference1_confirmation">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Neighbour/ThirdParty 1 Feedback
    </label>
    <Select
      placeholder="Select Neighbour/ThirdParty 1 Feedback"
      classNamePrefix="select-box"
      options={confirmationOptions}
      defaultValue={{
        label: mainOBJ?.neighbor_reference1_confirmation,
        value: mainOBJ?.neighbor_reference1_confirmation,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "neighbor_reference1_confirmation",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.neighbor_reference1_confirmation && (
<span className="error">{errors.neighbor_reference1_confirmation}</span>
)}
        </Col>
        <Col> 
          <TextField isAsterisk={true} label="Neighbour/Third Party Reference 2 Name" name="neighbor_reference2" type="text" />
          {/* <TextField isAsterisk={true} label="Neighbour/ThirdParty 2 Feedback" name="neighbor_reference2_confirmation" type="text" /> */}
          <Field name="neighbor_reference2_confirmation">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Neighbour/ThirdParty 2 Feedback
    </label>
    <Select
      placeholder="Select Neighbour/ThirdParty 2 Feedback"
      classNamePrefix="select-box"
      options={confirmationOptions}
      defaultValue={{
        label: mainOBJ?.neighbor_reference2_confirmation,
        value: mainOBJ?.neighbor_reference2_confirmation,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "neighbor_reference2_confirmation",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.neighbor_reference2_confirmation && (
<span className="error">{errors.neighbor_reference2_confirmation}</span>
)}
        </Col>
      </Row>
    </div>
  )
}

TpcCheck.label = "TPC/Neighbour Check"
TpcCheck.initialValues = {
  neighbor_reference1: "",
  neighbor_reference1_confirmation: "",
  neighbor_reference2: "",
  neighbor_reference2_confirmation: "",
 
 
}

TpcCheck.validationSchema = Yup.object({
  neighbor_reference1: Yup.string().required("Neighbour/Third Party Reference 1 Name is required"),
  neighbor_reference1_confirmation: Yup.string().required("Neighbour/ThirdParty 1 Feedback is required"),
  neighbor_reference2: Yup.string().required("Neighbour/Third Party Reference 2 Name is required"),
  neighbor_reference2_confirmation: Yup.string().required("Neighbour/ThirdParty 2 Feedback is required"),
  
})
TpcCheck.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
}

export default TpcCheck