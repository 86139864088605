import React from "react";
import { Nav, Tab } from "react-bootstrap";
import RequestPayments from "./NewRequestPayments";
import PaymentLinksSentLots from "./PaymentLinksSentLots";

const MainRequestPayments = () => {
//   let userDetails = getTokenInLocalStorage();
  return (
    <div className="card" style={{ padding: "10px" }}>
      
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="requestPayments">
          <Nav as="ul" className="nav-tabs-custom nav-justified">
            <Nav.Item as="li" key="0">
              <Nav.Link eventKey="requestPayments">
                <b> Request Payments </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="1">
              <Nav.Link eventKey="lots">
                <b> Payment Lots </b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="requestPayments" key="0" mountOnEnter unmountOnExit>
                <RequestPayments/>
            </Tab.Pane>

            <Tab.Pane eventKey="lots" key="1" mountOnEnter unmountOnExit>
                <PaymentLinksSentLots/>
            </Tab.Pane>
           
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default MainRequestPayments;
