import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { isAdmin } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";

const VisitsCount = ({
  startDate,
  endDate,
  visitType,
  userDetails,
  handleManagerColumns,
  isDefault,
}) => {
  var history = useHistory();

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data1, setData1] = useState([]);
  const [data3, setData3] = useState([]);
  const [currPage] = useState(0);
  const [totalElements, setTotalElements] = useState(1);
  const [mgrId, setMgrId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [show,setShow]=useState(false)

  useEffect(() => {
    if (isDefault) return;

    if (
      isAdmin() ||
      userDetails.autoFinanceUser.subOrdinateManagersIds.length > 0
    ) {
      setMgrId("");
      loadData(0, "", isDefault);
      return;
    }

    setMgrId(userDetails.autoFinanceUser.userId);
    loadData(0, userDetails.autoFinanceUser.userId, isDefault);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefault]);

  const columns = [
    {
      text: "Managers",
      dataField: "employeeName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Count",
      dataField: "count",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setMgrId(row.userId);
          loadData(0, row.userId, true);
          handleManagerColumns(true);
        },
      },
    },
  ];
  const managerColumns = [
    {
      text: "Managers",
      dataField: "employeeName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Count",
      dataField: "count",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let path = "/home";
          path += "/";
          path += row.userId;
          path += "/";
          path += startDate;
          path += "/";
          path += endDate;
          path += "/";
          path += mgrId;
          path += "/";
          path += "All";
          path += "/";
          path += "All";
          path += "/";
          path += visitType;

          history.push(path);

          // setMgrId(row.userId)
          // loadData(0, row.userId)
          // loadVistedPlacesData(row.userId)
        },
      },
    },
  ];

  const executiveColumns = [
    // {
    //     text: "Image",
    //     dataField: "image",
    //     width: 130,
    //     align: "center",
    //     headerAlign: "center",
    //     headerStyle: {
    //       backgroundColor: "rgb(243,240,249)",
    //       fontWeight: "bold",
    //       fontFamily:
    //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //     },
    //     formatter: (cell, row) => {
    //         if (row.image === "ACTIVE") {
    //           return (
    //             <></>
    //           );
    //         } else {
    //           return (
    //             <Media>
    //               <div style={{ marginTop: "30px" }} className="ms-3">
    //                 <img
    //                   // src= {(isImageComing === false) ? `data:image/png;base64,${currentFile}`: mainOBJ.userImage}
    //                   // src={!isImageGoing ? getImage : getImage}
    //                   src={executivesList.image
    //                     // isImageComing
    //                     //   ? `data:image/png;base64,${currentFile}`
    //                     //   : mainOBJ.userImage
    //                     //   ? mainOBJ.userImage
    //                     //   : `data:image/png;base64,${currentFile}`
    //                   }
    //                   // src={`data:image/png;base64,${currentFile}`}
    //                   alt=""
    //                   className="avatar-lg rounded-circle img-thumbnail"
    //                   // className = "rounded avatar-md" //square shape immage
    //                 />
    //               </div>

    //             </Media>
    //           );
    //         }
    //       },
    // },

    {
      text: "Executives",
      dataField: "employeeName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Count",
      dataField: "count",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let path = "/home";
          path += "/";
          path += row.userId;
          path += "/";
          path += startDate;
          path += "/";
          path += endDate;
          path += "/";
          path += mgrId;
          path += "/";
          path += "All";
          path += "/";
          path += "All";
          path += "/";
          path += visitType;

          history.push(path);

          // setMgrId(row.userId)
          // loadData(0, row.userId)
          // loadVistedPlacesData(row.userId)
        },
      },
    },
  ];

  const loadData = (pageNo, userId, isLevelChanged) => {
    // console.log(pageNo, userId, isLevelChanged, "params");
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    setShow(false)
    let listData = [];
    let listData1 = [];
    let array = [];
    let array1 = [];
    var payLoad = {
      endTime: endDate,
      organizationId: userDetails?.autoFinanceUser?.organizationId,
      reportingOfficerId:
        isLevelChanged ||
        userDetails?.autoFinanceUser?.subOrdinateManagersIds?.length === 0
          ? userId
          : "",
      roleName: userDetails?.autoFinanceUser?.roleName,
      startTime: startDate,
      userId: !isAdmin() ? userDetails?.autoFinanceUser?.userId : "",
      visitType: visitType,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeeVisitsCount, pageNo, 10),
        payLoad
      )
      .then((response) => {
        if (isLevelChanged) {
          setShow(isLevelChanged)
          response?.content.forEach((element) => {
            if (element.roleName === "MANAGER" || element.roleName === "MANAGER_RA") {
              let data = {
                employeeName: element.employeeName,
                count: element.count,
                userId: element.userId,
                branchName:element.branchName,
                designation: element.companyDesignation

              };
              listData.push(data);
              // console.log(listData, "listData");
            } else {
              let data1 = {
                employeeName: element.employeeName,
                count: element.count,
                userId: element.userId,
                branchName:element.branchName,
                designation: element.companyDesignation

              };
              listData1.push(data1);
              // console.log(listData1, "listData1");
            }
          });
        } else {
          response?.content.forEach((element) => {
            if (element.roleName === "MANAGER" || element.roleName === "MANAGER_RA") {
              let data2 = {
                employeeName: element.employeeName,
                count: element.count,
                userId: element.userId,
                branchName:element.branchName,
                designation: element.companyDesignation

              };
              array.push(data2);
            } else {
              let data3 = {
                employeeName: element.employeeName,
                count: element.count,
                userId: element.userId,
                branchName:element.branchName,
                designation: element.companyDesignation

              };
              array1.push(data3);
            }
          });
        }
        setData(listData);
        setData1(listData1);
        setData2(array);
        setData3(array1);
        setTotalElements(response?.totalElements);
        moduleApi
          .postData(apiCall.getemployeevisitscountwithtotalcount, payLoad)
          .then((response) => {
            if (response) setTotalCount(response.totalCount);
          });
        // setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    // console.log(selectedPage - 1, mgrId, isDefault);
    loadData(selectedPage - 1, mgrId, isDefault);
  };

  return (
    <div>
      {totalCount >= 1 ? (
                <div
                  style={{
                    float:'right',
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "30px",
                    marginTop: "10px",
                    marginBottom:'5px'
                  }}
                >
                  <b>Total Visit Count: &nbsp;{totalCount}</b>
                </div>
              ) : null}
      {data.length >= 1 ? (
        <BootstrapTable 
        keyField="count" 
        data={data} 
        columns={managerColumns} 
        id="managercolumnvisitcount"
        />
      ) : null}
      {data1.length >=0 && show ? (
        <BootstrapTable
          keyField="employeeName"
          data={data1}
          columns={executiveColumns}
          id="executivecolumnvisitcount"
        />
      ) : null}
      {data2.length >= 1 ? (
        <BootstrapTable
          keyField="count"
          data={data2}
          columns={
            isDefault ||
            (!isAdmin() &&
              userDetails.autoFinanceUser.subOrdinateManagersIds.length === 0)
              ? managerColumns
              : columns
          }
          id="subordinatemanagervisitcount"
        />
      ) : null}
      {data3.length >= 1 ? (
        <BootstrapTable
          keyField="count"
          data={data3}
          columns={executiveColumns}
          id="subordinateexecutivevisitcount"
        />
      ) : null}
      {data2.length === 0 && data3.length === 0 && !show? (
        <div >
          <img src={NO_DATA_FOUND} alt="" />
        </div>
      ):null}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <PaginationComponent
          totalItems={totalElements}
          pageSize={10}
          defaultActivePage={currPage + 1}
          onSelect={onPageChange}
        />
      </div>
    </div>
  );
};

export default VisitsCount;
