import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { logout } from '../../src/TicketComponent/RaiseTicket';
import { removeTokens } from "../services/AuthService";
import swal from "sweetalert";
import { EpochToDate } from "../CommonComponents/Helper";
import "./toastStyle.css";
import clulogo from "../images/circlelogo.png";

toast.configure();

export async function handleResponse(response) {
  let errMsg = "";
  if (response.ok) {
    if (response.headers.get("Content-Disposition")) {
      // var header = response.headers.get("content-disposition");
      // var fileName = header.slice(22, header.length - 1);
      let blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "text";
      a.href = url;
      a.download =
        "Collection_Allocations_" +
        EpochToDate(Math.floor(new Date() / 1000)) +
        ".csv";
      // document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      // toaster('success', "File Downloaded")
      swal("File Downloaded", {
        icon: "success",
      });

      return null;
    } else {
      return response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      });
    }
  }

  if (response.status === 401) {
    removeTokens();
    // toaster('error', 'Session Expired. Please Login Again')
    swal("Session Expired. Please Login Again", {
      icon: "error",
      dangerMode: true,
    });
    window.location.reload(true);
  }

  if (response.status === 633) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 640) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 636) {
    errMsg = "Appraisal Not Ready For Validation";
    toast.error(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
  if (response.message) {
    toast.success(
      <div style={{ fontWeight: "bold" }}>{response.message}</div>,
      { position: toast.POSITION.TOP_CENTER }
    );
  }
  if (response.Message) {
    toast.success(
      <div style={{ fontWeight: "bold" }}>{response.Message}</div>,
      { position: toast.POSITION.TOP_CENTER }
    );
  }

  if (response.status === 700) {
    let rep = await response.text();
    rep = JSON.parse(rep);
    return prepareClientResponse(700, rep.errDesc.substr(0, 300));
  }
  if (response.status === 611) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 200) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 603) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 602) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 601) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 609) {
    // alert('You have dependencies please delete');
    errMsg = "User Data Not Found";
    return prepareClientResponse(609, errMsg);
  }

  if (response.status === 604) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 619) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    errMsg = resp.message;
    toast.error(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
    return prepareClientResponse(604, errMsg);
  }
  if (response.status === 621) {
    // alert('You have dependencies please delete');
    let resp = await response.text();
    resp = JSON.parse(resp);
    errMsg = resp.message;
    toast.error(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
    // return prepareClientResponse(604, errMsg);
  }

  if (response.status === 623) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 605) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 612) {
    // alert('You have dependencies please delete');
    errMsg = "Employee-Id already exsists";
    toast.error(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
    // return prepareClientResponse(605, errMsg);
  }

  if (response.status === 500) {
    // let resp = await response.text();
    // eslint-disable-next-line no-unused-vars
    // resp = JSON.parse(resp);
    // return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 607) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 606) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 638) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 627) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 637) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 608) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 654) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 620) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 613) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 615) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 653) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 614) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 647) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 610) {
    errMsg = "Columns did not match the expected criteria";
    toast.warning(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  if (response.status === 632) {
    let resp = await response.text();

    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 628) {
    let resp = await response.text();

    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 625) {
    let resp = await response.text();

    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 440) {
    // let resp = await response.text();
    // resp = JSON.parse(resp);
    //    return logout(resp);
    // return <logoutFun resp={resp} />;
  }
  if (response.status === 400 || response.status === 430) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    // toaster('error', resp.message)
    // return Promise.reject(resp)
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
}

export async function handleResponseForExcelDownload(response) {
  let errMsg = "";
  if (response.ok) {
    if (response.headers.get("Content-Type")) {
      // var header = response.headers;
      // console.log('headerrrkl', header);
      // var fileName = header.slice(22, header.length - 1);
      var fileName = localStorage.getItem("fileName");

      let blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "text";
      a.href = url;
      a.download =
        fileName + "_" + EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx";
      // document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      // toaster('success', "File Downloaded")
      swal("File Downloaded", {
        icon: "success",
      });

      return null;
    } else {
      return response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      });
    }
  }

  if (response.status === 401) {
    removeTokens();
    // toaster('error', 'Session Expired. Please Login Again')
    swal("Session Expired. Please Login Again", {
      icon: "error",
      dangerMode: true,
    });
    window.location.reload(true);
  }

  if (response.status === 633) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 640) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 636) {
    errMsg = "Appraisal Not Ready For Validation";
    toast.error(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
  if (response.message) {
    toast.success(
      <div style={{ fontWeight: "bold" }}>{response.message}</div>,
      { position: toast.POSITION.TOP_CENTER }
    );
  }
  if (response.Message) {
    toast.success(
      <div style={{ fontWeight: "bold" }}>{response.Message}</div>,
      { position: toast.POSITION.TOP_CENTER }
    );
  }

  if (response.status === 700) {
    let rep = await response.text();
    rep = JSON.parse(rep);
    return prepareClientResponse(700, rep.errDesc.substr(0, 300));
  }
  if (response.status === 611) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 200) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 603) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 602) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 609) {
    // alert('You have dependencies please delete');
    errMsg = "User Data Not Found";
    return prepareClientResponse(609, errMsg);
  }

  if (response.status === 604) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 619) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    errMsg = resp.message;
    toast.error(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
    return prepareClientResponse(604, errMsg);
  }
  if (response.status === 621) {
    // alert('You have dependencies please delete');
    let resp = await response.text();
    resp = JSON.parse(resp);
    errMsg = resp.message;
    toast.error(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
    // return prepareClientResponse(604, errMsg);
  }

  if (response.status === 623) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 605) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 612) {
    // alert('You have dependencies please delete');
    errMsg = "Employee-Id already exsists";
    toast.error(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
    // return prepareClientResponse(605, errMsg);
  }

  if (response.status === 500) {
    // let resp = await response.text();
    // eslint-disable-next-line no-unused-vars
    // resp = JSON.parse(resp);
    // return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 607) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 606) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 638) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 627) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 637) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 608) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 654) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 620) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 613) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 615) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 653) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 614) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 647) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 610) {
    errMsg = "Columns did not match the expected criteria";
    toast.warning(<div style={{ fontWeight: "bold" }}>{errMsg}</div>, {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  if (response.status === 632) {
    let resp = await response.text();

    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
  if (response.status === 628) {
    let resp = await response.text();

    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 625) {
    let resp = await response.text();

    resp = JSON.parse(resp);
    return prepareClientResponse(resp.errrorCode, resp.message);
  }

  if (response.status === 440) {
    // let resp = await response.text();
    // resp = JSON.parse(resp);
    //    return logout(resp);
    // return <logoutFun resp={resp} />;
  }
  if (response.status === 400 || response.status === 430) {
    let resp = await response.text();
    resp = JSON.parse(resp);
    // toaster('error', resp.message)
    // return Promise.reject(resp)
    return prepareClientResponse(resp.errrorCode, resp.message);
  }
}

const prepareClientResponse = (status, message) => {
  return {
    error: true,
    status,
    message,
  };
};

export function toaster(type, message) {
  const styles = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  if (type === "error")
    toast.error(<div style={{ fontWeight: "bold" }}>{message}</div>, styles);
  else if (type === "success")
    toast.success(<div style={{ fontWeight: "bold" }}>{message}</div>, styles);
  else toast.warn(<div style={{ fontWeight: "bold" }}>{message}</div>, styles);
}

export function CustomToaster(type, title, body, history) {
  // var history = useHistory()
  const styles = {
    position: "bottom-right",
    // className: "toast-message",
    autoClose: 10000,
    closeButton:true,
    hideProgressBar: true,
    onClick: () => history(),
    pauseOnHover: false,
    draggable: false,
  };

  if (type === "dark")
    toast.info(
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          src={clulogo}
          alt=""
          className="rounded-circle"
          style={{
            height: "40px",
            width: "40px",
            alignSelf: "center",
            marginRight: "5px",
            marginLeft: "5px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "20px", margin: "5px" }}>
            {" "}
            <h4 className="fs-20 font-w700 mb-0" style={{ color: "white" }}>
              {title}
            </h4>
          </span>
          <span style={{ fontSize: "15px", margin: "5px" }}>
            <h4 className="fs-16 font-w600 mt-0" style={{ color: "white" }}>
              {body}
            </h4>
          </span>
        </div>
      </div>,
      styles
    );
  else if (type === "error")
    toast.error(<div style={{ fontWeight: "bold" }}>{body}</div>, styles);
  else if (type === "success")
    toast.success(<div style={{ fontWeight: "bold" }}>{body}</div>, styles);
  else toast.warn(<div style={{ fontWeight: "bold" }}>{body}</div>, styles);
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.log(error);
  console.error("API call failed. " + error);
  throw error;
}
