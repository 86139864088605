import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import {
  getTokenInLocalStorage,
} from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import "react-h5-audio-player/lib/styles.css";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
// import SearchBar from "../CommonComponents/SearchBar";

function WhatsAppHistory({ mobileNo }) {

  const [smsHistoryList, setSmsHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transList, setTransList] = useState(null);

  let listData = [];

  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview("WhatsApp History");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transList]);

  const loadData = () => {
    setIsLoading(true);
    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      phoneNumber: mobileNo,
    };

    moduleApi
      .postData(
        apiCall.getWhatMessages,
        body
      )
      .then((response) => {
        // console.log(response);
        response?.forEach((element) => {
          let data = {
            app: element.app ? element.app : "-",
            empId: element.empId ? element.empId : "-",
            empName: element.empName ? element.empName : "-",
            messageId: element.messageId ? element.messageId : "-",
            messageStatus: element.messageStatus ? element.messageStatus : "-",
            message_type: element.message_type ? element.message_type : "-",
            name: element.name ? element.name : "-",
            paymentStatus: element.paymentStatus ? element.paymentStatus : "-",
            timestamp: element.timestamp ? convertEpochTimeToDate(element.timestamp) : "-",
            transactionId: element.transactionId,
            type: element.type ? element.type : "-",
            version: element.version,
            templateName: element.templateName ? element.templateName : "-",
            phoneNumber: mobileNo,
          };
          listData.push(data);
        });
        setSmsHistoryList(listData);
        setIsLoading(false);
      });
  };

  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    {
      text: "WhatsApp Template Name",
      dataField: "templateName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "name",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "To Number",
      dataField: "phoneNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Message Type",
      dataField: "message_type",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {

        if (row.message_type === "message_sent") {
          return "Sent";
        } else {
          return "Recieved";
        }
      }
    },
    {
      text: "Status",
      dataField: "messageStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {

        if (row.message_type === "message_sent") {
          if (
            row.messageStatus === 1
          ) {
            return (
              <span className="badge badge-rounded badge-success">Sent</span>
            );
          } else if (row.messageStatus === 2) {
            return (
              <span className="badge badge-rounded badge-success">Delivered</span>
            );
          } else if (row.messageStatus === 3) {
            return (
              <span className="badge badge-rounded badge-success">Read</span>
            );
          } else if (
            row.messageStatus === -1
          ) {
            return (
              <span className="badge badge-rounded badge-danger">Failed</span>
            );
          } else {
            return (
              <span className="badge badge-rounded badge-warning">Pending</span>
            );
          }
        } else {
          return "NA";
        }
      },
    },
    {
      text: "Payment Link Sent",
      dataField: "paymentLinkSent",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {

        return row?.transactionId ? "Yes" : "No"

      },
    },
    {
      text: "Payment Status",
      dataField: "paymentStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "WhatsApp Sent Date Time",
      dataField: "timestamp",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  const transDownloadData = () => {
    setIsLoading(true);

    getTransFullResponseData();
    // let body = {
    //   organizationId: userLoginDetails.autoFinanceUser.organizationId,
    //   mobileNumber: mobileNo,
    // };

    // moduleApi.postData(apiCall.getsmshistory,body)
    //   .then((response) => {
    //     if (response) {
    //       getTransFullResponseData();
    //     } else {
    //       swal("Something Went Wrong, Please Try Later", {
    //         icon: "error",
    //         dangerMode: true,
    //       });
    //     }
    //   });
  };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime));
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  async function getTransFullResponseData() {
    var tempList = [];
    var mgrListData = [];

    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      phoneNumber: mobileNo,
    };
    await moduleApi
      .postData(
        apiCall.getWhatMessages,
        body
      )
      .then((response) => {
        tempList.push(...response);
      });

    tempList?.forEach((element) => {
      let data = {
        app: element.app ? element.app : "-",
        empId: element.empId ? element.empId : "-",
        empName: element.empName ? element.empName : "-",
        messageId: element.messageId ? element.messageId : "-",
        messageStatus: element.messageStatus ? element.messageStatus : "-",
        message_type: element.message_type ? element.message_type : "-",
        name: element.name ? element.name : "-",
        paymentStatus: element.paymentStatus ? element.paymentStatus : "-",
        timestamp: element.timestamp ? convertEpochTimeToDate(element.timestamp) : "-",
        transactionId: element.transactionId,
        type: element.type ? element.type : "-",
        version: element.version,
        templateName: element.templateName ? element.templateName : "-",
        phoneNumber: mobileNo,
      };
      mgrListData.push(data);
    });
    setTransList(mgrListData);
  }

  const getMessageStatus = (messageStatus) => {

    if (messageStatus === -1) {
      return "Failed";
    } else if (messageStatus === 1) {
      return "Sent";
    } else if (messageStatus === 2) {
      return "Delivered";
    } else if (messageStatus === 3) {
      return "Read";
    } else {
      return "Pending";
    }

  };

  const handleExport = () => {
    let mgrTable = [
      {
        A: "WhatsApp Template Name",
        B: "Customer Name",
        C: "To Number",
        D:"Message Type",
        E: "Status",
        F: "Payment Link Sent",
        G: "Payment Status",
        H: "WhatsApp Sent Date Time",
      },
    ];

    transList?.forEach((row) => {
      mgrTable.push({
        A: row.templateName,
        B: row.name,
        C: mobileNo,
        D:row.message_type === "message_sent" ? "Sent" :"Recieved",
        E: row.message_type === "message_sent" ? getMessageStatus(row.messageStatus) : "NA",
        F: row.transactionId ? "Yes" : "No",
        G: row.paymentStatus,
        H: row.timestamp,
      });
    });

    const finalData = [...mgrTable];

    // console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    // console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "SMS History");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      mobileNo +
      "_WhatsApp_History_" +
      helper.EpochToDate(Math.floor(new Date() / 1000)) +
      ".xlsx"
    );
    ReactGA.event({
      category: "WhatsApp History List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_WhatsApp_History_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          flexDirection: "row",
          alignItems: "center",
          marginRight: "20px",
        }}
      >
        <Button
          style={{
            marginRight: "15px",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() => transDownloadData()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={smsHistoryList}
              columns={columns}
            />
          </div>
          {smsHistoryList?.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default WhatsAppHistory;
