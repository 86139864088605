import React, { useEffect, useState } from "react";
import {  OverlayTrigger, Tooltip } from "react-bootstrap";

const Compliance = () => {

    const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        var rowList = [];
        for (var j = 1; j <= 5; j++) {
            rowList.push({ id: j, name: 'User ' + j })
        }
        setRows(rowList);

        var lst = [];
        for (var i = 0; i < 30; i++) {
            lst.push({ id: i })
        }
        setData(lst);
    }, []);



    return <div className="card">
        <div class='card_header'>
            <div>DatePicker goes here</div>
            <div style={{ display: 'flex' }}>
                <div style={{ minHeight: '12px', minWidth: '12px', backgroundColor: 'lightgray', margin: '3px' }}>                </div>
                <span>Holiday</span>
                <div style={{ minHeight: '12px', minWidth: '12px', backgroundColor: 'green', margin: '3px' }}>                                            </div>
                <span>Present</span>
                <div style={{ minHeight: '12px', minWidth: '12px', backgroundColor: 'red', margin: '3px' }}>                                            </div>
                <span>On Leave</span>
            </div>
            <div>
                <input type="text" placeholder="Search.."></input>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th class="td">Name</th>
                    <th class="td" colSpan={2}>Fill 1</th>
                    <th class="td" colSpan={2}>Fill 2</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row, idx) => (
                    <tr>
                        <td style={{ textAlign: 'center', border: '1px solid lightgrey', borderCollapse: 'collapse' }}>{row.name}</td>
                        <td class="td">
                            {
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {data.map((data, i) => (
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 50, hide: 100 }}
                                            overlay={<Tooltip id="button-tooltip-2">{data.id}</Tooltip>}
                                        >
                                            <div key={i} style={{ minHeight: '12px', minWidth: '12px', backgroundColor: i % 2 === 0 ? 'lightgray' : 'green', margin: '3px' }}>
                                            </div>
                                        </OverlayTrigger>

                                    ))}
                                </div>
                            }
                        </td>
                        <td class="td">Edit</td>
                        <td class="td">
                            {
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {data.map((data, i) => (
                                        <div key={i} data-toggle="tooltip" title={i} style={{ minHeight: '12px', minWidth: '12px', backgroundColor: i % 2 === 0 ? 'grey' : 'red', margin: '3px' }}>
                                        </div>
                                    ))}
                                </div>
                            }
                        </td >
                        <td class="td">Edit</td>
                    </tr>
                ))}
            </tbody>
        </table>

    </div>

}
export default Compliance;