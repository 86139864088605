import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { SRLWrapper } from "simple-react-lightbox";
import * as Yup from "yup";
import EmployeeLeave from "../CommonComponents/EmployeeLeave";
import * as helper from "../CommonComponents/Helper";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import Attendance from "../Executives/Attendance";
import MyMapComponent from "../Executives/MyMapComponent";
import defaultImage from "../images/defaultImage.png";
import location_image from "../images/location_image.png";
// import { toaster } from "../rest/apiUtils";
import swal from "sweetalert";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import ManagerExecutives from "./ManagerExecutives";
import ManagerVisitedPlaces from "./ManagerVisitedPlaces";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";


const L1ManagerDetails = () => {
  const [latlongsArr, setLatlongsArr] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [random, setRandom] = useState(Math.floor(Math.random() * 100));
  const [isMap, setIsMap] = useState(false);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [transferModal, setTransferModal] = useState(false);

  const [branches, setBranches] = useState([]);
  const [mutiManagers, setMutiManagers] = useState([]);
  const [defaultReportingOfficer, setDefaultReportingOfficer] = useState(null);

  var L1ManagerDetails = JSON.parse(localStorage.getItem("L1ManagerDetails"));

  var managerDetails = JSON.parse(localStorage.getItem("managerDetails"));
  var history = useHistory();

  const [selectedBranch, setSelectedBranch] = useState({
    value: L1ManagerDetails.userId,
    label: L1ManagerDetails.branchName,
    branchId: L1ManagerDetails.branchId,
  });

  var defaultbranch = {
    value: L1ManagerDetails.userId,
    label: L1ManagerDetails.branchName,
    branchId: L1ManagerDetails.branchId,
  };

  const validate = Yup.object({
    branchId: Yup.string().required("Branch is required"),
  });

  const [initVal] = useState({
    branchId: L1ManagerDetails.branchId,
    organizationId: "",
    subordinateId: "",
  });

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    handleChange(new Date());
    loadManagers();

    const payLoad = {
      searchKey: "",
      organizationId: userData.autoFinanceUser.organizationId,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
      }));
      setBranches(result);
    });

    //Direct Reporting Officer
    moduleApi
      .postData(apiCall.getUser + L1ManagerDetails.reportingOfficerId)
      .then((response) => {
        setDefaultReportingOfficer(response?.autoFinanceUser.name);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadManagers = () => {
    var managerDetails = JSON.parse(localStorage.getItem("L1ManagerDetails"));
    var mgrIDs = managerDetails.reportingOfficerIds;
    if (mgrIDs.length === 0) return;

    moduleApi.postData(apiCall.getMultipleUsers, mgrIDs).then((response) => {
      const result = response.map((item) => ({
        userId: item.autoFinanceUser.userId,
        name: item.autoFinanceUser.name,
        email: item.autoFinanceUser.email,
        contact: item.autoFinanceUser.mobileNumber,
        empId: item.autoFinanceUser.employeeId,
        branch: item.autoFinanceBranchDetails?.branchName,
        image: item.autoFinanceUser.image,
        status: item.autoFinanceUser.status,
        designation: item.autoFinanceUser.companyDesignation,
      }));

      setMutiManagers(result);
    });
  };

  const updateBranch = () => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    const payLoad = {
      newBranchId: selectedBranch.branchId,
      oldBranchId: L1ManagerDetails.branchId,
      organizationId: L1ManagerDetails.organizationId,
      transferedByUserId: userData.autoFinanceUser.userId,
      userId: L1ManagerDetails.userId,
    };

    moduleApi.postData(apiCall.updateBranch, payLoad).then((response) => {
      swal("Transferred Successfully", {
        icon: "success",
      });
      setTransferModal(false);
      history.push("/manager-details");
    });
  };
  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const handleChange = (date) => {
    setStartDate(date);
    getData(date);
  };

  const getData = (start) => {
    var obj = {
      endTime: Math.round(start.setHours(23, 59, 59, 0) / 1000),
      userId: L1ManagerDetails.userId,
      startTime: Math.round(start.setHours(0, 0, 0, 0) / 1000),
      organizationId: L1ManagerDetails.organizationId,
    };
    moduleApi.postData(apiCall.getLocations, obj).then((res) => {
      var arr = [];
      setLatlongsArr([]);
      for (let index = 0; index < res.length; index++) {
        var latlngObj = [];
        for (let i = 0; i < res[index].gpsLocationDatas.length; i++) {
          var obj1 = {
            lat: res[index].gpsLocationDatas[i].x,
            lng: res[index].gpsLocationDatas[i].y,
          };
          latlngObj.push(obj1);
        }
        if (latlngObj.length > 0) {
          arr.push(latlngObj);
        }
      }
      setLatlongsArr(arr);
      setRandom(Math.floor(Math.random() * 1000));
    });
  };

  return (
    <div className="card" style={{ backgroundColor: "white" }}>
      <div style={{ marginRight: "30px" }}>
        {/* 
      {userData.autoFinanceUser.roleName!=="MANAGER_RA"&&
      <Button
        style={{
          float: "right",
          margin: "10px",
          marginRight: "15px",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        }}
        className="me-2"
        variant="danger btn-rounded"
        onClick={() =>

          swal({
            title: "Delete",
            text: "Are you sure, you want to delete(Inactive) executive?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              var payLoad = {
                organizationId: loggedInUser.autoFinanceUser.organizationId,
                relivedByUserId: loggedInUser.autoFinanceUser.userId,
                userId: L1ManagerDetails.userId
              };
              moduleApi.postData(apiCall.deleteExecutive, payLoad).then((response) => {
                swal(L1ManagerDetails.name, "Deleted successfully!", {
                  icon: "success",
                });
                history.push("/manager")
              });
            }
          })
        }
      >
        Delete
      </Button>}
      {userData.autoFinanceUser.roleName!=="MANAGER_RA"&&
      <Button
        style={{
          float: "right",
          margin: "10px",
          marginRight: "15px",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        }}
        className="me-2"
        variant="primary btn-rounded"
        onClick={() => setUpdateMgrModal(true)}
      >
        Update Managers
      </Button>} */}

        <Button
          style={{
            float: "left",
            margin: "10px",
            marginLeft: "20px",
            marginTop: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => history.push("/manager-details")}
        >
          <i className="fa fa-arrow-left" />
        </Button>
        <span
          style={{
            float: "left",
            marginLeft: "10px",
            margin: "10px",
            marginTop: "16px",
            fontSize: "18px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            fontWeight: "bold",
          }}
        >
          {managerDetails.name ? managerDetails.name : "-"} /{" "}
          {L1ManagerDetails.name ? L1ManagerDetails.name : "-"}
        </span>
      </div>
      <div style={{ margin: "20px" }}>
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey="basicInfo">
            <Nav as="ul" className="nav-tabs-custom nav-justified">
              <Nav.Item as="li" key="0">
                <Nav.Link eventKey="basicInfo">
                  <b> Basic Info </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="1">
                <Nav.Link eventKey="executives">
                  <b> Executives </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="2">
                <Nav.Link eventKey="attendance">
                  <b> Attendance </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="3">
                <Nav.Link eventKey="visitedPlaces">
                  <b> Visits </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="4">
                <Nav.Link eventKey="trackAttendance">
                  <b> Track Attendance </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="5">
                <Nav.Link eventKey="leave">
                  <b> Leaves </b>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey="basicInfo" key="0">
                <div>
                  <div
                    className="card contact-bx item-content"
                    style={{
                      backgroundColor: "rgb(243,240,249)",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                  >
                    <div className="card-header border-0"></div>
                    <div className="card-body user-profile">
                      <div style={{ textAlign: "left" }}>
                        <h6 className="fs-20 font-w600 my-1">
                          Manager Details
                        </h6>
                      </div>
                      <Row>
                        <Col>
                          <table style={{ marginBottom: "15px" }}>
                            <>
                              <tr></tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Employee ID
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {L1ManagerDetails.employeeId
                                    ? L1ManagerDetails.employeeId
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Name
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {L1ManagerDetails.name
                                    ? L1ManagerDetails.name
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Branch Name
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  <div>
                                    {L1ManagerDetails.branchName
                                      ? L1ManagerDetails.branchName
                                      : "-"}
                                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <i
                                      style={{ color: "blue", cursor: "pointer" }}
                                      className="fa fa-edit"
                                      onClick={() => setTransferModal(true)}
                                    /> */}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Direct Reporting Officer
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {defaultReportingOfficer
                                    ? defaultReportingOfficer
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Mobile
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {L1ManagerDetails.mobileNumber
                                    ? L1ManagerDetails.mobileNumber
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Email
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {L1ManagerDetails.email
                                    ? L1ManagerDetails.email
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Designation
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {L1ManagerDetails.designation
                                    ? L1ManagerDetails.designation
                                    : "-"}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Status
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      L1ManagerDetails.status === "ACTIVE"
                                        ? "green"
                                        : "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {L1ManagerDetails.status
                                    ? L1ManagerDetails.status
                                    : "-"}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Battery Status
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  <div>
                                    {L1ManagerDetails.batteryPercentage > 75 ? (
                                      <span
                                        style={{
                                          color: "green",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <i className="fas fa-battery-full fa-2x fa-rotate-0" />
                                      </span>
                                    ) : L1ManagerDetails.batteryPercentage >
                                      30 ? (
                                      <span
                                        style={{
                                          color: "orange",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <i className="fas fa-battery-three-quarters fa-2x fa-rotate-0 " />
                                      </span>
                                    ) : L1ManagerDetails.batteryPercentage >
                                      0 ? (
                                      <span
                                        style={{
                                          color: "red",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <i className="fas fa-battery-quarter fa-2x fa-rotate-0 " />
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <span
                                      style={{
                                        marginLeft:
                                          L1ManagerDetails.batteryPercentage ===
                                          0
                                            ? "0px"
                                            : "3px",
                                      }}
                                    >
                                      {L1ManagerDetails.batteryPercentage === 0
                                        ? "N/A"
                                        : L1ManagerDetails.batteryPercentage +
                                          "%"}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Last updated Time
                                </td>
                                <td style={{ verticalAlign: "top" }}>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {L1ManagerDetails.gpsData ? (
                                    <p
                                      style={{
                                        cursor: "pointer",
                                        verticalAlign: "middle",
                                      }}
                                      title={helper.EpochToDate(
                                        L1ManagerDetails.gpsData.t
                                      )}
                                    >
                                      {helper.EpochToDate(
                                        L1ManagerDetails.gpsData.t
                                      )}
                                    </p>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Last Updated Location
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {L1ManagerDetails.gpsData ? (
                                    <img
                                      style={{
                                        height: "40px",
                                        width: "40px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setLatLng({
                                          lat: L1ManagerDetails.gpsData.x,
                                          lng: L1ManagerDetails.gpsData.y,
                                        });
                                        setIsMap(true);
                                      }}
                                      src={location_image}
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                              </tr>
                            </>
                          </table>
                        </Col>
                        <Col>
                          <SRLWrapper options={options1}>
                            <img
                              style={{
                                height: "100px",
                                marginRight: "100px",
                                cursor: "pointer",
                              }}
                              src={
                                L1ManagerDetails.image
                                  ? L1ManagerDetails.image
                                  : defaultImage
                              }
                              alt=""
                              className="img-fluid rounded-circle"
                            />
                          </SRLWrapper>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <h4>Reporting Officer Details</h4>
                  <Table responsive hover bordered>
                    <thead style={{ backgroundColor: "#F3F0F9" }}>
                      <tr>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Employee ID
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Name
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Email
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Contact
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Branch
                        </th>
                        <th style={{ width: "150px", fontSize: "15px" }}>
                          Designation
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mutiManagers.map((item) => {
                        return (
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={defaultImage}
                                  className="rounded-lg me-2"
                                  width="24"
                                  alt=""
                                />{" "}
                                <span className="w-space-no">{item.empId}</span>
                              </div>{" "}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.name}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.email}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.contact}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.branch}
                            </td>
                            <td style={{ width: "150px", fontSize: "15px" }}>
                              {item.designation}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {mutiManagers.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="executives" key="1">
                <ManagerExecutives
                  userId={L1ManagerDetails.userId}
                  organizationId={L1ManagerDetails.organizationId}
                  isL1Manager={true}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="attendance" key="2">
                <Attendance userId={L1ManagerDetails.userId} />
              </Tab.Pane>
              <Tab.Pane eventKey="visitedPlaces" key="3">
                <ManagerVisitedPlaces
                  userId={L1ManagerDetails.userId}
                  organisationId={L1ManagerDetails.organizationId}
                  reportingOfficerId={L1ManagerDetails.reportingOfficerId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="trackAttendance" key="4">
                <div
                  style={{
                    width: "200px",
                    marginLeft: "auto",
                    paddingLeft: "10px",
                    border: "1px solid",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      label=""
                      clearable
                      format="dd/MM/yyyy"
                      disableFuture
                      value={startDate}
                      onChange={handleChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <br></br>
                <MyMapComponent latlongsArr={latlongsArr} key={random} />
              </Tab.Pane>
              <Tab.Pane eventKey="leave" key="5">
                {/* <EmployeeLeaveDashboard
                  userId={L1ManagerDetails.userId}
                  reportingOfficerId={L1ManagerDetails.reportingOfficerId}
                /> */}
                <EmployeeLeave userId={L1ManagerDetails.userId} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Last Updated Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal>
      {/* <Modal className="fade" show={updateMgrModal}>
      <Modal.Header>
        <Modal.Title>Transfer Executive</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => setUpdateMgrModal(false)}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            enableReinitialize
            initialValues={initVal}
            onSubmit={updateManagers}
            validationSchema={validate}
          >
            {({ formik, errors }) => (
              <div>
                <Row>
                  <Col lg="12">
                    <Form>
                      <div>
                        <Field name="reportingOfficerIds">
                          {({ field, form }) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label
                                className="control-label"
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  marginBottom: "10px",
                                }}
                              >
                                Manager
                              </label>
                              <Select
                                placeholder="Select a manager"
                                classNamePrefix="select-box"
                                options={managers}
                                isMulti
                                defaultValue={defaultMgr}
                                onChange={(list) => {
                                  form.setFieldValue(
                                    "reportingOfficerIds",
                                    getManagerIds(list)
                                  );

                                }}
                              />
                            </div>
                          )}
                        </Field>
                        {errors.reportingOfficerIds && (
                          <span className="error">{errors.reportingOfficerIds}</span>
                        )}

                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => setUpdateMgrModal(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            )}
          </Formik>
        </div>
      </Modal.Body>

    </Modal> */}
      <Modal className="fade" show={transferModal}>
        <Modal.Header>
          <Modal.Title>Transfer Branch</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setTransferModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              enableReinitialize
              initialValues={initVal}
              onSubmit={updateBranch}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <Field name="branchId">
                            {({ field, form }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Branch
                                </label>
                                <Select
                                  placeholder="Select a branch"
                                  classNamePrefix="select-box"
                                  options={branches}
                                  defaultValue={defaultbranch}
                                  onChange={(value) => {
                                    setSelectedBranch({
                                      value: value.value,
                                      label: value.label,
                                      branchId: value.branchId,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                          {errors.branchId && (
                            <span className="error">{errors.branchId}</span>
                          )}
                          <div style={{ marginTop: "10px" }}>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2"
                              type="submit"
                            >
                              Submit
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2 float-right"
                              variant="danger light"
                              onClick={() => setTransferModal(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default L1ManagerDetails;
