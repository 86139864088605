import React, { useState, useEffect } from "react";
// import axios from "axios";
import { TextField, Autocomplete } from "@mui/material";
import { debounce } from "@mui/material/utils";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { getTokenInLocalStorage } from "../services/AuthService";
import {
  setAllocationDetails,
  setAllocationId,
  setSearchedLoanNoValue,
  setSelectedLoanDropDownValue,
} from "../store/actions/CallingActions";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
// import debounce from "lodash.debounce";

const DynamicAutocomplete = () => {
  const userLoginDetails = getTokenInLocalStorage();
  const { searchedLoanNoValue } = useSelector((state) => state.calls);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  async function loadData(key) {
    try {
      //   setAllocationsList([]);
      // var start =
      //   new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      //   1000;
      var end =
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getTime() /
          1000 +
        86399;
      const payload = {
        startTime: 0,
        endTime: end,
        filters: [],
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        teleCallerUserId:
          key === "" ? userLoginDetails.autoFinanceUser.userId : "",
        telecallerAllocations: false,
        searchKey: key,
        status: "",
        campaignName: "",
        sorts: [],
      };
      const response = await moduleApi.postData(
        userLoginDetails?.selectedTeam?.teamType === "Sales"
          ? apiCall.getAllSalesAllocations
          : apiCall.getAllocationsFullData,
        payload
      );
      setOptions(response);
      console.log("formatted", response);
      setIsLoading(false);
    } catch (error) {
      // Handle errors gracefully
      console.error("Error fetching data:", error);
      // Dispatch appropriate error actions if needed
    } finally {
      //   dispatch(setIsLoading(false));
    }
  }

  // Debounced function to fetch options
  const debouncedFetchOptions = debounce((value) => {
    if (value) {
      loadData(value);
    } else {
      setOptions([]);
    }
  }, 2000); // 2000ms debounce

  useEffect(() => {
    debouncedFetchOptions(inputValue);
    // eslint-disable-next-line
  }, [inputValue]);

  const handleInputChange = (event, newValue) => {
    setIsLoading(true);
    setInputValue(newValue);
    dispatch(setSearchedLoanNoValue(newValue));
    // console.log(event);
    // console.log(newValue);
  };
  const handleChange = (event, value) => {
    // setInputValue(newValue);
    console.log(value);
    localStorage.setItem("allocationId", value.id);
    // let temp =  userLoginDetails?.selectedTeam?.teamType === "Sales"
    // ? value?.phoneNumber
    // : value?.loanNumber
    // dispatch(setSearchedLoanNoValue(value));
    dispatch(setAllocationId(value.id));
    dispatch(setAllocationDetails(value));
    dispatch(setSelectedLoanDropDownValue(null));
    // console.log(newValue);
  };

  return (
    <div
      style={{
        borderRadius: "5px",
        borderWidth: "1px",
        boxShadow: "1px 1px 2px #797979",
      }}
    >
      <Autocomplete
        // value={searchedLoanNoValue}
        freeSolo
        options={options}
        noOptionsText={
          isLoading
            ? "Loading..."
            : userLoginDetails?.selectedTeam?.teamType === "Sales"
            ? "No Mobile Numbers Found"
            : "No Loan Numbers Found"
        }
        getOptionLabel={(option) =>
          userLoginDetails?.selectedTeam?.teamType === "Sales"
            ? option.phoneNumber
            : option.loanNumber
        }
        inputValue={searchedLoanNoValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              // searchedLoanNoValue === null
              //   ? 
                userLoginDetails?.selectedTeam?.teamType === "Sales"
                  ? "Search By Mobile Number"
                  : "Search By Loan Number"
                // : userLoginDetails?.selectedTeam?.teamType === "Sales"
                // ? searchedLoanNoValue?.phoneNumber
                // : searchedLoanNoValue?.loanNumber
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading && inputValue !== "" ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default DynamicAutocomplete;
