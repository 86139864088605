
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { SRLWrapper } from "simple-react-lightbox";
import * as Yup from "yup";
import TextField from "../../../../CommonComponents/TextField";
import defaultImage from "../../../../images/defaultImage.png";
import * as moduleApi from "../../../../rest/moduleApi";
import * as nodeApi from "../../../../rest/nodeApi";
import { apiCall } from "../../../../rest/restApi";
import swal from "sweetalert";
import AddAgency from "../../../../Agencies/AddAgency";


function AgencyProfile() {
    const [editProfile, setEditProfile] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [editPopulateData, setEditPopulateData] = useState({});
    const fieldInvestigationAgency = JSON.parse(localStorage.getItem("fieldInvestigationAgency"));

    useEffect(() => {
        getAgenciesData()
        //eslint-disable-next-line
    }, [])

    const getAgenciesData = () => {
        const organizationId = fieldInvestigationAgency.organizationId;
        let agencyId = fieldInvestigationAgency.agencyId;
        nodeApi
            .getData(apiCall.agencies, "/" + organizationId)
            .then((response) => {
                //eslint-disable-next-line
                response.agencies.map(item => {
                    if (item.agencyId === agencyId) {
                        setEditPopulateData(item)
                    }
                })
            })
            .catch((error) => {
            });
    };

    const changePasswordValidate = Yup.object({
        oldPassword: Yup.string().required("Old Password is required"),
        newPassword: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required"),
    });

    const changePasswordHandleOk = (values) => {
        if (values.newPassword !== values.confirmPassword) {
            return swal("New Password and Confirm Password Should Be Same", {
                icon: "error",
                dangerMode: true,
            });
        }
        if (values.oldPassword === values.newPassword) {
            return swal("Old Password and New Password Should Not Be Same", {
                icon: "error",
                dangerMode: true,
            });
        }
        const payLoad = {
            newPassword: values.newPassword,
            oldPassword: values.oldPassword,
            userId: fieldInvestigationAgency.agencyId,
        };
        moduleApi.postData(apiCall.changePassword, payLoad).then((response) => {
            if (response.Message === "Password Updated") {
                setChangePassword(false);
                swal("Password Updated Successfully, Please login with new credentials", {
                    icon: "success",
                });
            }
        });
    };

    const options1 = {
        settings: {},
        caption: {
            showCaption: false,
        },
        buttons: {
            showAutoplayButton: false,
            showDownloadButton: false,
            showNextButton: false,
            showPrevButton: false,
            showThumbnailsButton: false,
            showFullscreenButton: false,
        },
        thumbnails: {
            showThumbnails: false,
        },
    };
    console.log(editPopulateData)
    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                            <div className="photo-content ">
                                <div className="cover-photo rounded"></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                    <SRLWrapper options={options1}>
                                        <img
                                            src={
                                                defaultImage
                                            }
                                            style={{
                                                cursor: "pointer",
                                                height: "100px",
                                                width: "100px",
                                                borderRadius: "50px",
                                            }}
                                            alt="profile"
                                        />
                                    </SRLWrapper>
                                </div>
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2">
                                        <h4 className="text-primary mb-0">
                                            {editPopulateData && editPopulateData.agencyName}
                                        </h4>
                                        <p>{editPopulateData && editPopulateData.officeAddress}</p>
                                    </div>
                                    <div className="profile-email px-2 pt-2">
                                        <h4 className="text-muted mb-0">
                                            {editPopulateData && editPopulateData.email}
                                        </h4>
                                        <p>{editPopulateData && editPopulateData.mobileNumber}</p>
                                    </div>
                                    <div className="ms-auto" style={{ float: "right" }}>
                                        <Button
                                            style={{
                                                float: "right",
                                                margin: "10px",
                                                fontFamily:
                                                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                            }}
                                            className="me-2"
                                            variant="primary btn-rounded"
                                            onClick={() => setChangePassword(true)}
                                        >
                                            <i className="fa fa-key" /> Change Password
                                        </Button>
                                        <Button
                                            style={{
                                                float: "right",
                                                margin: "10px",
                                                fontFamily:
                                                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                            }}
                                            className="me-2"
                                            variant="primary btn-rounded"
                                            onClick={() => setEditProfile(true)}
                                        >
                                            <i className="fa fa-edit" /> Edit
                                        </Button>
                                    </div>

                                    <Modal className="fade" show={editProfile}>
                                        <Modal.Header>
                                            <Modal.Title>Edit Profile</Modal.Title>
                                            <Button
                                                variant=""
                                                className="btn-close"
                                                onClick={() => {
                                                    setEditProfile(false);
                                                }}
                                            ></Button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <AddAgency editPopulateData={editPopulateData}
                                                agencyProfile={true}
                                                close={() => setEditProfile(false)}
                                                getAgenciesData={getAgenciesData}
                                            />
                                        </Modal.Body>
                                    </Modal>

                                    <Modal className="fade" show={changePassword}>
                                        <Modal.Header>
                                            <Modal.Title>Change Password</Modal.Title>
                                            <Button
                                                variant=""
                                                className="btn-close"
                                                onClick={() => setChangePassword(false)}
                                            ></Button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <Formik
                                                    initialValues={{
                                                        oldPassword: "",
                                                        newPassword: "",
                                                        confirmPassword: "",
                                                        organizationId: "",
                                                        reportingOfficerId: "",
                                                    }}
                                                    onSubmit={changePasswordHandleOk}
                                                    validationSchema={changePasswordValidate}
                                                >
                                                    {({ formik, errors }) => (
                                                        <div>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <Form>
                                                                        <div>
                                                                            <TextField
                                                                                isAsterisk={true}
                                                                                label="Old Password"
                                                                                name="oldPassword"
                                                                                type="password"
                                                                            />
                                                                            <TextField
                                                                                isAsterisk={true}
                                                                                label="New Password"
                                                                                name="newPassword"
                                                                                type="password"
                                                                            />
                                                                            <TextField
                                                                                isAsterisk={true}
                                                                                label="Confirm Password"
                                                                                name="confirmPassword"
                                                                                type="password"
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                style={{ float: "right" }}
                                                                                className="col-lg-2 m-2"
                                                                                type="submit"
                                                                            >
                                                                                Submit
                                                                            </Button>
                                                                            <Button
                                                                                style={{ float: "right" }}
                                                                                className="col-lg-2 m-2 float-right"
                                                                                variant="danger light"
                                                                                onClick={() => setChangePassword(false)}
                                                                            >
                                                                                Close
                                                                            </Button>
                                                                        </div>
                                                                    </Form>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </Formik>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default AgencyProfile