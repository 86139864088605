import React from "react";
import Avatar from "./Avatar";
import {
  convertEpochToDate2,
  convertEpochToTime3,
} from "../../CommonComponents/Helper";
// import defaultImage from "../../images/defaultImage.png";
// import defaultUser from '../assets/defaultUser.svg'
// import { collection, onSnapshot } from '@firebase/firestore'
// import firestore from '../../CommonComponents/Firestore'

export default function ContactBox({ contact, setContactSelected, messages }) {
  // const [contactsLst, setContactsList] = useState([])

  // useEffect(() => {
  //     getContactsList()
  //     // return () => clearInterval(timer);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  // const maxTs = Math.max(...messages.map((m) => m.date.getTime()))
  // const lastMsg = messages.find((m) => m.date.getTime() === maxTs)

  // function truncate(text, length) {
  //   return text.length > length ? `${text.substring(0, length)} ...` : text;
  // }

  var user = {
    name:
      contact?.name !== null &&
      contact?.name !== "" &&
      contact?.name !== undefined &&
      contact?.name !== "-"
        ? contact?.name
        : contact?.payload?.sender?.dial_code,
    // avatar: defaultUser,
  };

  const getLatestMsgTime = (time) => {
    let currentDayStart = Math.floor(
      new Date(new Date().setHours(0, 0, 0)).getTime()
    );
    if (currentDayStart > time) {
      return convertEpochToDate2(time / 1000);
    } else {
      return convertEpochToTime3(time / 1000);
    }
  };

  return (
    <div
      className="contact-box"
      onClick={() => {
        console.log(contact);
        setContactSelected(contact);
      }}
    >
      <Avatar user={user} showName={false} />
      <div className="right-section">
        <div className="contact-box-header">
          <h3 className="avatar-title">
            {contact?.name !== null &&
            contact?.name !== "" &&
            contact?.name !== undefined &&
            contact?.name !== "-"
              ? contact?.name
              : contact?.payload?.sender?.dial_code}
          </h3>
          <span className="time-mark">
            {getLatestMsgTime(contact?.timestamp)}
          </span>
        </div>
        <div className="last-msg">
          {/* <img src={doubleCheck} alt="" className="icon-small" /> */}
          {/* {contact?.message_type === "message_sent" &&  (
            <svg
              className="icon-small"
              style={{ color: "blue", marginRight: "2px" }}
              viewBox="0 0 18 18"
              height="18"
              width="18"
              preserveAspectRatio="xMidYMid meet"
              class=""
              version="1.1"
              x="0px"
              y="0px"
              enable-background="new 0 0 18 18"
            >
              <path
                fill="currentColor"
                d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"
              ></path>
            </svg>
          )} */}
          {contact?.message_type === "message_sent" &&
          (contact?.messageStatus === 3 || contact?.messageStatus === 2) ? (
            <svg
              // className="icon-small"
              style={{
                color:
                  contact?.messageStatus === 3 ? "rgb(4 123 245)" : "#99a3a9",
                marginRight: "3px",
                // marginLeft: "4px",
              }}
              viewBox="0 0 18 18"
              height="17"
              width="17"
              preserveAspectRatio="xMidYMid meet"
              class=""
              version="1.1"
              x="0px"
              y="0px"
              enable-background="new 0 0 18 18"
            >
              <path
                fill="currentColor"
                d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"
              ></path>
            </svg>
          ) : contact?.message_type === "message_sent" &&
            contact?.messageStatus === 1 ? (
            <svg
              viewBox="0 0 12 11"
              style={{
                color: "#99a3a9",
                marginRight: "4px",
                // marginLeft: "4px",
              }}
              height="13"
              width="13"
              preserveAspectRatio="xMidYMid meet"
              class=""
              fill="none"
            >
              <title>msg-check</title>
              <path
                d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z"
                fill="currentcolor"
              ></path>
            </svg>
          ) : contact?.message_type === "message_sent" &&
            contact?.messageStatus === 0 ? (
            <svg
              style={{
                color: "#99a3a9",
                marginRight: "3px",
                // marginLeft: "4px",
              }}
              viewBox="0 0 16 15"
              width="17"
              preserveAspectRatio="xMidYMid meet"
              class=""
              version="1.1"
              x="0px"
              y="0px"
              enable-background="new 0 0 16 15"
            >
              <title>msg-time</title>
              <path
                fill="currentColor"
                d="M9.75,7.713H8.244V5.359c0-0.276-0.224-0.5-0.5-0.5H7.65c-0.276,0-0.5,0.224-0.5,0.5v2.947 c0,0.276,0.224,0.5,0.5,0.5h0.094c0.001,0,0.002-0.001,0.003-0.001S7.749,8.807,7.75,8.807h2c0.276,0,0.5-0.224,0.5-0.5V8.213 C10.25,7.937,10.026,7.713,9.75,7.713z M9.75,2.45h-3.5c-1.82,0-3.3,1.48-3.3,3.3v3.5c0,1.82,1.48,3.3,3.3,3.3h3.5 c1.82,0,3.3-1.48,3.3-3.3v-3.5C13.05,3.93,11.57,2.45,9.75,2.45z M11.75,9.25c0,1.105-0.895,2-2,2h-3.5c-1.104,0-2-0.895-2-2v-3.5 c0-1.104,0.896-2,2-2h3.5c1.105,0,2,0.896,2,2V9.25z"
              ></path>
            </svg>
          ) : (
            contact?.message_type === "message_sent" &&
            contact?.messageStatus === -1 && (
              <svg
                style={{
                  marginRight: "3px",
                  // marginLeft: "4px",
                }}
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 152.000000 152.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,152.000000) scale(0.100000,-0.100000)"
                  fill="red"
                  stroke="none"
                >
                  <path
                    d="M660 1511 c-412 -58 -701 -433 -650 -843 38 -308 252 -554 556 -639
  100 -28 276 -31 374 -6 90 23 233 96 306 156 113 91 203 234 246 388 30 109
  30 277 0 386 -40 143 -133 294 -230 375 -99 82 -224 147 -332 171 -66 14 -207
  21 -270 12z m175 -364 l27 -22 -12 -140 c-32 -404 -28 -379 -51 -397 -12 -10
  -29 -18 -39 -18 -10 0 -27 8 -39 18 -18 15 -23 37 -35 162 -8 80 -18 197 -22
  261 l-7 116 28 21 c39 31 114 30 150 -1z m-12 -668 c25 -30 22 -75 -8 -104
  -65 -66 -168 14 -124 96 23 43 99 47 132 8z"
                  />
                </g>
              </svg>
            )
          )}
          <span className="text">
            {contact?.payload?.type === "text" ? (
              contact?.payload?.payload?.text
            ) : contact?.payload?.type === "image" ? (
              <span>
                <i className="fa fa-camera" style={{ color: "#85969f" }}></i>{" "}
                &nbsp;Photo
              </span>
            ) : contact?.payload?.type === "video" ? (
              <span>
                {/* <i className="fa fa-video-camera" style={{ color: "#85969f" }}></i>{" "} */}
                <svg
                style={{marginBottom:'2px'}}
                  viewBox="0 0 16 20"
                  height="20"
                  width="16"
                  preserveAspectRatio="xMidYMid meet"
                  class=""
                  version="1.1"
                  x="0px"
                  y="0px"
                  enable-background="new 0 0 16 20"
                >
                  <title>status-video</title>
                  <path
                    fill="currentColor"
                    d="M15.243,5.868l-3.48,3.091v-2.27c0-0.657-0.532-1.189-1.189-1.189H1.945 c-0.657,0-1.189,0.532-1.189,1.189v7.138c0,0.657,0.532,1.189,1.189,1.189h8.629c0.657,0,1.189-0.532,1.189-1.189v-2.299l3.48,3.09 V5.868z"
                  ></path>
                </svg>
                &nbsp;Video
              </span>
            ) : (
              <span>
                <i
                  className="fa fa-microphone"
                  style={{ color: "#85969f" }}
                ></i>{" "}
                &nbsp;Audio
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
