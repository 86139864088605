import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { getTokenInLocalStorage } from "../services/AuthService";
import swal from "sweetalert";
// import { getApp, getApps, initializeApp } from "firebase/app";

import { ref, set, push } from "firebase/database";
import { setSalesCallsCount } from "../store/actions/MySalesAllocationAction";
import { collection, getDocs, query } from "@firebase/firestore";
import firestore, { database } from "./Firestore";

let userLoginDetails = getTokenInLocalStorage();

//Vastu
// const firebaseConfig = {
//   apiKey: "AIzaSyDPexqP_7LP4QklF8ZPdAsVV4HHgVr8-_c",
//   authDomain: "clu-cloud-f0b93.firebaseapp.com",
//   databaseURL: "https://clu-cloud-f0b93-default-rtdb.firebaseio.com",
//   projectId: "clu-cloud-f0b93",
//   storageBucket: "clu-cloud-f0b93.appspot.com",
//   messagingSenderId: "115074287360",
//   appId: "1:115074287360:web:d0879cde75edd0306dd5e5",
//   measurementId: "G-WT2MBG922D",
// };

//QA
// const firebaseConfig = {
//   apiKey: "AIzaSyB8atYr2xkwGkmqfhSkui9FHk6TLybPs4Y",
//   authDomain: "telecalling-qa.firebaseapp.com",
//   databaseURL:
//     "https://telecalling-qa-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "telecalling-qa",
//   storageBucket: "telecalling-qa.appspot.com",
//   messagingSenderId: "918263194810",
//   appId: "1:918263194810:web:b3d99ba9f89a3cfcfb4e83",
//   measurementId: "G-HG128X9HWW",
// };

//Prod
// const firebaseConfig = {
//   apiKey: "AIzaSyBoVWUmadPQg52h6iql2ru-_OPzVpvT2Z8",
//   authDomain: "telecalling-prod-65201.firebaseapp.com",
//   projectId: "telecalling-prod-65201",
//   databaseURL: "https://telecalling-prod-65201-default-rtdb.asia-southeast1.firebasedatabase.app",
//   storageBucket: "telecalling-prod-65201.appspot.com",
//   messagingSenderId: "1075727068041",
//   appId: "1:1075727068041:web:7b15801b733d8d55ed0f58",
//   // measurementId: "G-HG128X9HWW"
// };

// Initialize Firebase
// initializeApp(firebaseConfig);
// !getApps().length ? initializeApp(firebaseConfig) : getApp();
export function EpochToDate(epoch) {
  return convertEpochToDateTime(epoch);
}

export function EpochToTime(epoch) {
  return convertEpochToTime(epoch);
}

export function EpochToOnlyDate(epoch) {
  return convertEpochToDate(epoch);
}
// export function fn(targetTimestamp) {
//     let currentDate = new Date();
//     let currentTimeInms = currentDate.getTime();
//     let targetDate = new Date(targetTimestamp);
//     let targetTimeInms = targetDate.getTime();
//     let elapsed = Math.floor((currentTimeInms - targetTimeInms) / 1000);
//     if (elapsed < 1) {
//         return '0s';
//     }
//     if (elapsed < 60) { //< 60 sec
//         return `${elapsed}s`;
//     }
//     if (elapsed < 3600) { //< 60 minutes
//         return `${Math.floor(elapsed / (60))}m`;
//     }
//     if (elapsed < 86400) { //< 24 hours
//         return `${Math.floor(elapsed / (3600))}h`;
//     }
//     if (elapsed < 604800) { //<7 days
//         return `${Math.floor(elapsed / (86400))}d`;
//     }
//     if (elapsed < 2628000) { //<1 month
//         return `${targetDate.getDate()} ${MonthNames[targetDate.getMonth()]}`;
//     }
//     return `${targetDate.getDate()} ${MonthNames[targetDate.getMonth()]} ${targetDate.getFullYear()}`;
// }
export function relativeDays(timestamp) {
  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const aDay = 1000 * 60 * 60 * 24;
  const diffInDays = Math.round((timestamp - Date.now()) / aDay);
  if (diffInDays === -0) return convertEpochToTime(timestamp / 1000);
  return rtf.format(diffInDays, "day");
}

export function formatDate(date) {
  if (date === null && date === "-") {
    return "yyyy-MM-dd";
  }
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export async function GetWhatsappList(path) {
  // const query = collection(firestore, path);
  // const [docs, loading, error] = await useCollectionData(query);
  const q = query(collection(firestore, path));
  // console.log(q);
  const temp = await getDocs(q);
  // let temp =[]
  // onSnapshot(collection(firestore, "whatsapp/7036820121/messages"), (snapshot) => {
  //   console.log('helper---',snapshot.docs.map((doc) => doc.data()));
  //   snapshot.docs.map((doc) => temp.push(doc.data()))
  // });
  return temp;
}

export function sendDataToDB(status, count) {
  // console.log("status", status, "count", count);
  let userData = getTokenInLocalStorage();
  var telecmiUserId = userData?.autoFinanceUser?.teleCmiUserId.split("_");
  if (userData?.autoFinanceOrganization?.enableLiveDashboard === true) {
    // const db = getDatabase();
    const db = database;
    set(
      ref(
        db,
        "agentLiveStatus/" +
          userData?.autoFinanceUser?.organizationId +
          "/" +
          userData?.autoFinanceUser?.userId
      ),
      {
        orgId: userData?.autoFinanceUser?.organizationId,
        userId: userData?.autoFinanceUser?.userId,
        empId: userData?.autoFinanceUser?.employeeId,
        empName: userData?.autoFinanceUser?.name,
        status: status,
        todayCallsCount: count ? parseInt(count) : 0,
        createdTime: Math.trunc(new Date() / 1000),
        telecmiUserId: telecmiUserId[0],
      }
    );
  }
}

export function sendErrorDataToDB(message) {
  // console.log("status", status, "count", count);
  let userData = getTokenInLocalStorage();
  var telecmiUserId = userData?.autoFinanceUser?.teleCmiUserId.split("_");
  if (userData?.autoFinanceOrganization?.enableLiveDashboard === true) {
    // const db = getDatabase();
    const db = database;
    push(
      ref(
        db,
        "errorMessages/" +
          userData?.autoFinanceUser?.organizationId 
          +
          "/" +
          userData?.autoFinanceUser?.userId
      ), 
      {
        orgId: userData?.autoFinanceUser?.organizationId,
        userId: userData?.autoFinanceUser?.userId,
        orgShortCode: userData?.autoFinanceOrganization?.orgshortcode,
        empId: userData?.autoFinanceUser?.employeeId,
        empName: userData?.autoFinanceUser?.name,
        errorMessage: message,
        // todayCallsCount: count ? parseInt(count) : 0,
        createdTime: Math.trunc(new Date() / 1000),
        dateTime: EpochToDate(Math.trunc(new Date() / 1000)),
        telecmiUserId: telecmiUserId[0],
      }
    );
  }
}

export function SalesCallsCountData(dispatch){
  // var dispatch = useDispatch()
  let userLoginDetails = getTokenInLocalStorage();
  var payload = {
    organizationId: userLoginDetails.autoFinanceUser.organizationId,
    teamId: userLoginDetails?.selectedTeam?.id,
    userId: userLoginDetails.autoFinanceUser.userId,
  };
  moduleApi.postData(apiCall.getCallCounts, payload).then((response) => {
    dispatch(setSalesCallsCount(response));
  });
}

// export function tempGetData(orgId) {
//   try {
//     const dbRef = ref(getDatabase());
//     get(child(dbRef, "agentLiveStatus/" + orgId))
//       .then((snapshot) => {
//         if (snapshot.exists()) {
//           console.log(snapshot.val());
//           return snapshot.val();
//         } else {
//           console.log("No data available");
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   } catch (error) {
//     console.error(error);
//     return undefined;
//   }
//   console.log("END!");
// }

// export function getDataFromFirebaseDB(orgId) {
//   const db = getDatabase();
//   const starCountRef = ref(db, "agentLiveStatus/" + orgId);
//   console.log("starCountRef ", starCountRef);
//   onValue(starCountRef, (snapshot) => {
//     const data = snapshot.val();
//     // updateStarCount(postElement, data);
//     console.log("dataFromDB", data);
//     return data;
//   });
// }

export function getMaskedMobileNo(number) {
  // Check if the input is a valid mobile number
  if (!/^\d{10}$/.test(number)) {
    return "Invalid mobile number";
  }

  // Masking logic
  var maskedNumber = "xxxxxx" + number.slice(-4);
  return maskedNumber;
}

const convertEpochToTime = (epochTime) => {
  // var months_arr = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  // ];
  // console.log(epochTime);
  var date_str = "";
  var date = new Date(parseInt(epochTime) * 1000);
  // var day = date.getDate();
  // var month = months_arr[date.getMonth()];
  // var year = date.getFullYear();
  var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  var am_pm = h >= 12 ? "PM" : "AM";
  h = h > 12 ? h - 12 : h;
  date_str = h + ":" + m + ":" + s + " " + am_pm;
  return date_str;
};
export const convertEpochToTime3 = (epochTime) => {
  // var months_arr = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  // ];
  // console.log(epochTime);
  var date_str = "";
  var date = new Date(parseInt(epochTime) * 1000);
  // var day = date.getDate();
  // var month = months_arr[date.getMonth()];
  // var year = date.getFullYear();
  var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  // var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  var am_pm = h >= 12 ? "PM" : "AM";
  h = h > 12 ? h - 12 : h;
  date_str = h + ":" + m + " " + am_pm;
  return date_str;
};

export function convertEpochToTime2(epochTime) {
  // console.log(epochTime);
  var date_str = "";
  var date = new Date(parseInt(epochTime) * 1000);
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var tempMonth = date.getMonth() + 1;
  var month = tempMonth < 10 ? "0" + tempMonth : tempMonth;
  var year = date.getFullYear();
  var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  date_str = day + "-" + month + "-" + year + " " + h + ":" + m + ":" + s + " ";
  return date_str;
}

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);
  var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
}

export function secondsToHms2(seconds) {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedTime = `${String(hrs).padStart(2, "0")}:${String(
    mins
  ).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  return formattedTime;
}

export function makeCall(mobileNumber) {
  var payload = {
    customerPhoneNumber: "91" + mobileNumber,
    organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
    userId: userLoginDetails?.autoFinanceUser?.userId,
  };

  moduleApi.postData(apiCall.makeCall, payload).then((response) => {
    if (response) {
      if (response?.msg === "Call initiated") {
        return swal("Call Initiated Successfully", {
          icon: "success",
          dangerMode: false,
        });
      } else {
        return swal(response?.msg, {
          icon: "error",
          dangerMode: true,
        });
      }
    } else {
      return swal("Something Went Wrong, Please Try Later", {
        icon: "error",
        dangerMode: true,
      });
    }
  });
}

export function getPriority(value) {
  if (value === 1) {
    return "Hot";
  } else if (value === 2) {
    return "Warm";
  } else {
    return "Cold";
  }
}

const convertEpochToDateTime = (epochTime) => {
  var months_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date_str = "";
  var date = new Date(parseInt(epochTime) * 1000);
  var day = date.getDate();
  var month = months_arr[date.getMonth()];
  var year = date.getFullYear();
  var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  var am_pm = h >= 12 ? "PM" : "AM";
  h = h > 12 ? h - 12 : h;
  date_str =
    month +
    " " +
    day +
    "," +
    year +
    "," +
    " " +
    h +
    ":" +
    m +
    ":" +
    s +
    " " +
    am_pm;
  return date_str;
};

const convertEpochToDate = (epochTime) => {
  var months_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date_str = "";
  var date = new Date(parseInt(epochTime) * 1000);
  var day = date.getDate();
  var month = months_arr[date.getMonth()];
  var year = date.getFullYear();
  date_str = month + " " + day + "," + year;

  return date_str;
};

export const convertEpochToDate2 = (epochTime) => {
  var date_str = "";
  var date = new Date(parseInt(epochTime) * 1000);
  var day = date.getDate();
  var month = date.getMonth();
  var year = date.getFullYear();
  date_str = day + "/" + month + "/" + year;

  return date_str;
};
