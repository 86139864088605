import React, { useEffect, useState } from "react";
import { Button, Modal} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import { getTokenInLocalStorage } from "../../src/services/AuthService";
import * as helper from "../CommonComponents/Helper";
import LoadImage from "../CommonComponents/LoadImage";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import AddManager from "./AddManager";
import TrackingAccess from "./TrackingAccess";
import "./_manager.css";
import { hasAccess } from "../services/AuthService";
import LoadingOverlay from "react-loading-overlay";
import NO_DATA_FOUND from "../images/no-data-found.png";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import ReactGA from "react-ga";
import SearchBar from "../CommonComponents/SearchBar";

function Managers() {
  var history = useHistory();
  const [managersList, setManagersList] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [trackingModal, setTrackingModal] = useState(false);
  const [addManagerModal, setAddManagerModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [isMap, setIsMap] = useState(false);
  // eslint-disable-next-line
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [mgrList, setMgrList] = useState(null);
  const [reportingOfficers, setReportingOfficers] = useState([]);

  let listData = [];
  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData(0, searchKey);
    getReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mgrList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line
  }, [mgrList]);

  const loadData = (pageNo, key) => {
    setIsLoading(true);
    let managerBody = {
      roleName: "MANAGER",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportOfficerId:
        userLoginDetails.autoFinanceUser.roleName !== "ADMIN"
          ? userLoginDetails.autoFinanceUser.userId
          : "",
      searchKey: key,
      status: "ACTIVE",
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getExecutives, pageNo, 10),
        managerBody
      )
      .then((response) => {
        setCurrPage(pageNo);
        // let userIds = [];
        // response.content.forEach((element) => {
        //   userIds.push(element.autoFinanceUser.userId);
        // });
        // let payload = {
        //   organizationId: userLoginDetails.autoFinanceUser.organizationId,
        //   userIds: userIds,
        // };
        // let m = new Map();
        // moduleApi
        //   .postData(apiCall.getLiveLocations, payload)
        //   .then((response2) => {
        //     response2?.forEach((item) => {
        //       m.set(item?.userId, item);
        //     });
        if (response?.content?.length > 0) {
        let userIds = [];
        response?.content.forEach((element) => {
          userIds?.push(element?.autoFinanceUser?.reportingOfficerId);
        });
        let m = new Map();
        moduleApi.postData(apiCall.getMultipleUsers, userIds).then((response2) => {
          response2?.forEach((item) => {
            m.set(item?.autoFinanceUser?.userId, item);
          });
        response.content.forEach((element) => {
          let data = {
            branchId: element.autoFinanceBranchDetails.branchId,
            branchLocation: element.autoFinanceBranchDetails.branchLocation,
            branchName: element.autoFinanceBranchDetails.branchName,
            branchemail: element.autoFinanceBranchDetails.branchemail,
            organizationId: element.autoFinanceBranchDetails.organizationId,
            phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
            addedByUserId: element.autoFinanceUser.addedByUserId,
            reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
            email: element.autoFinanceUser.email,
            employeeId: element.autoFinanceUser.employeeId,
            image: element.autoFinanceUser.image,
            mobileNumber: element.autoFinanceUser.mobileNumber,
            name: element.autoFinanceUser.name,
            passwordUpdated: element.autoFinanceUser.passwordUpdated,
            reportingOfficerId:element.autoFinanceUser.reportingOfficerId,
            reportingOfficerName: m.has(element.autoFinanceUser.reportingOfficerId)
                ? m.get(element.autoFinanceUser.reportingOfficerId).autoFinanceUser.name
                : "-",
            roleName: element.autoFinanceUser.roleName,
            status: element.autoFinanceUser.status,
            userId: element.autoFinanceUser.userId,
            designation: element.autoFinanceUser.companyDesignation,
            // batteryPercentage: m.has(element.autoFinanceUser.userId)
            //   ? m.get(element.autoFinanceUser.userId).batteryPercentage
            //   : 0,
            // gpsData: m.has(element.autoFinanceUser.userId)
            //   ? m.get(element.autoFinanceUser.userId).gpsData
            //   : null,
            // lastUpdatedAt: m.has(element.autoFinanceUser.userId)
            //   ? m.get(element.autoFinanceUser.userId).lastUpdatedAt
            //   : 0,
            // locationPermissionEnabled: m.has(element.autoFinanceUser.userId)
            //   ? m.get(element.autoFinanceUser.userId)
            //       .locationPermissionEnabled
            //   : "-",
            // locationPermission: m.has(element.autoFinanceUser.userId)
            //   ? m.get(element.autoFinanceUser.userId).locationPermission
            //   : "-",
            geofenceEnabled: element.autoFinanceUser.geofenceEnabled,
            privilageIds: element.autoFinanceUser.privilageIds,
          };
          listData.push(data);
        });
       
        console.log(listData);
        setManagersList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
        });
      }else{
        setManagersList([]);
        setTotalElements(0);
        setIsLoading(false);
      }
      });
  };
  const onPageChange = (selectedPage) => {
    // setCurrPage(selectedPage - 1);
    loadData(selectedPage - 1, searchKey);
  };
  const handleClose = () => {
    setAddManagerModal(false);
    setCurrPage(0);
    loadData(0, searchKey);
  };

  const columns = [
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <LoadImage source={row.image} />{" "}
            <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
          </div>
        );
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Email",
    //   dataField: "email",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Mobile No",
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",

      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Reporting</span>
          <br></br>
          <span>Officer</span>
        </div>
      ),
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    // {
    //   text: "Battery",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     return (
    //       <div>
    //         {row.batteryPercentage > 75 ? (
    //           <span style={{ color: "green", verticalAlign: "middle" }}>
    //             <i className="fas fa-battery-full fa-2x fa-rotate-0" />
    //           </span>
    //         ) : row.batteryPercentage > 30 ? (
    //           <span style={{ color: "orange", verticalAlign: "middle" }}>
    //             <i className="fas fa-battery-three-quarters fa-2x fa-rotate-0 " />
    //           </span>
    //         ) : row.batteryPercentage > 0 ? (
    //           <span style={{ color: "red", verticalAlign: "middle" }}>
    //             <i className="fas fa-battery-quarter fa-2x fa-rotate-0 " />
    //           </span>
    //         ) : (
    //           <></>
    //         )}
    //         <span
    //           style={{
    //             marginLeft: row.batteryPercentage === 0 ? "0px" : "3px",
    //           }}
    //         >
    //           {row.batteryPercentage === 0
    //             ? "N/A"
    //             : row.batteryPercentage + "%"}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   text: (
    //     <div>
    //       <span>Last Updated</span>
    //       <br></br>
    //       <span>Location</span>
    //     </div>
    //   ),
    //   dataField: "lastUpdatedAt",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.gpsData) {
    //       return (
    //         <div style={{ display: "flex", justifyContent: "center" }}>
    //           <p
    //             style={{ cursor: "pointer" }}
    //             title={helper.EpochToDate(row.gpsData.t)}
    //           >
    //             {helper.EpochToDate(row.gpsData.t)}
    //           </p>
    //           <span
    //             style={{
    //               color: "green",
    //               margin: "0px 15px",
    //               cursor: "pointer",
    //               alignSelf: "center",
    //             }}
    //             onClick={() => {
    //               setLatLng({ lat: row.gpsData.x, lng: row.gpsData.y });
    //               setIsMap(true);
    //             }}
    //           >
    //             <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
    //           </span>
    //         </div>
    //       );
    //     } else {
    //       return <>-</>;
    //     }
    //   },
    // },
    // {
    //   text: (
    //     <div>
    //       <span>Location</span>
    //       <br></br>
    //       <span>Permissions</span>
    //     </div>
    //   ),
    //   dataField: "locationPermission",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (col, row) => {
    //     if (row.locationPermission === 0) {
    //       return <span>N/A</span>;
    //     } else if (row.locationPermission === 1) {
    //       return (
    //         <span className="badge badge-rounded badge-success">
    //           Always Allow
    //         </span>
    //       );
    //     } else if (row.locationPermission === 2) {
    //       return (
    //         <span className="badge badge-rounded badge-primary">
    //           While In Use
    //         </span>
    //       );
    //     } else if (row.locationPermission === 3) {
    //       return (
    //         <span className="badge badge-rounded badge-danger">Denied</span>
    //       );
    //     } else if (row.locationPermission === 4) {
    //       return (
    //         <span className="badge badge-rounded badge-danger">
    //           Always Denied
    //         </span>
    //       );
    //     } else if (row.locationPermission === 5) {
    //       return (
    //         <span className="badge badge-rounded badge-primary">
    //           Ask Every Time When Come
    //         </span>
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    // {
    //   text: "Status",
    //   dataField: "mobileNumber",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (col, row) => {
    //     if (row.status === "ACTIVE") {
    //       return (
    //         <span className="badge badge-rounded badge-success">Active</span>
    //       );
    //     } else {
    //       return (
    //         <span className="badge badge-rounded badge-danger">Inactive</span>
    //       );
    //     }
    //   },
    // },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          localStorage.setItem("managerDetails", JSON.stringify(row));
          localStorage.setItem("prevUrl", "managers");
          history.push("/manager-details");
        },
      },
    },
  ];
  const searchKeyword = (key) => {
    setSearchKey(key);
    loadData(0, key);
  };

  const mgrDownloadData = () => {
    // setIsLoading(true)
    let managerBody = {
      roleName: "MANAGER",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportOfficerId:
        userLoginDetails.autoFinanceUser.roleName !== "ADMIN"
          ? userLoginDetails.autoFinanceUser.userId
          : "",
      searchKey: searchKey,
      status: "ACTIVE",
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.getExecutives, 0, 10), managerBody)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            // setIsLoading3(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                // setIsLoading3(true);
                getMgrFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getMgrFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getMgrFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      let managerBody = {
        roleName: "MANAGER",
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        reportOfficerId:
          userLoginDetails.autoFinanceUser.roleName !== "ADMIN"
            ? userLoginDetails.autoFinanceUser.userId
            : "",
        searchKey: searchKey,
        status: "ACTIVE",
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getExecutives, i, 100),
          managerBody
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    // console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        branchId: element.autoFinanceBranchDetails.branchId,
        branchLocation: element.autoFinanceBranchDetails.branchLocation,
        branchName: element.autoFinanceBranchDetails.branchName,
        branchemail: element.autoFinanceBranchDetails.branchemail,
        organizationId: element.autoFinanceBranchDetails.organizationId,
        phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
        addedByUserId: element.autoFinanceUser.addedByUserId,
        reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
        email: element.autoFinanceUser.email,
        employeeId: element.autoFinanceUser.employeeId,
        image: element.autoFinanceUser.image,
        mobileNumber: element.autoFinanceUser.mobileNumber,
        name: element.autoFinanceUser.name,
        passwordUpdated: element.autoFinanceUser.passwordUpdated,
        reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
        roleName: element.autoFinanceUser.roleName,
        status: element.autoFinanceUser.status,
        userId: element.autoFinanceUser.userId,
        designation: element.autoFinanceUser.companyDesignation,
        directReportOfcr: getDirectReportOfcr(
          element.autoFinanceUser.reportingOfficerId
        ),
        // batteryPercentage: m.has(element.autoFinanceUser.userId)
        //   ? m.get(element.autoFinanceUser.userId).batteryPercentage
        //   : 0,
        // gpsData: m.has(element.autoFinanceUser.userId)
        //   ? m.get(element.autoFinanceUser.userId).gpsData
        //   : null,
        // lastUpdatedAt: m.has(element.autoFinanceUser.userId)
        //   ? m.get(element.autoFinanceUser.userId).lastUpdatedAt
        //   : 0,
      };
      mgrListData.push(data);
    });
    setMgrList(mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Branch",
        D: "Mobile No",
        E: "Designation",
        F: "Email",
        G: "Status",
        H: "Direct Reporting Officer",
      },
    ];

    mgrList?.forEach((row) => {
      mgrTable.push({
        A: row.employeeId,
        B: row.name,
        C: row.branchName,
        D: row.mobileNumber,
        E: row.designation,
        F: row.email,
        G: row.status,
        H: row.directReportOfcr,
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Managers");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      "Clu_Managers_Report_" +
        // new Date().toLocaleString("en-US") +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );

    ReactGA.event({
      category: "Managers List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Managers_Report_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const getDirectReportOfcr = (id) => {
    for (let i = 0; i < reportingOfficers.length; i++) {
      if (id === reportingOfficers[i].userId) {
        // console.log('sd',reportingOfficers[i].name);
        return reportingOfficers[i].name;
      }
    }
  };

  const getReportList = () => {
    var managersPayLoad3 = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      roleName: "ADMIN_MANAGER",
    };

    moduleApi.postData(apiCall.usersList, managersPayLoad3).then((response) => {
      // console.log("response", response);
      const result = response?.map((item) => ({
        userId: item.userId,
        name: item.name,
      }));
      setReportingOfficers(result);
    });
  };

  return (
    <div className="card">
      <div
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        {/* <input
          style={{
            width: "300px",
            height: "40px",
            borderRadius: "25px",
            borderColor: "#a0a0a0",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #3a353b",
            paddingLeft: "10px",
          }}
          placeholder="Search By Name/Branch/Mobile"
          onChange={(e) => searchKeyword(e.target.value.trim())}
        /> */}
        <SearchBar
          placeholder="Search By Name/Branch/Mobile/Emp ID"
          searchKeyword={searchKeyword}
        />

        {
        // userLoginDetails.isSuperAdmin !== true && 
        hasAccess(24) && (
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => setAddManagerModal(true)}
          >
            <b> + Add Manager</b>
          </Button>
        )}
        {
        // userLoginDetails.isSuperAdmin !== true && 
        hasAccess(16) && (
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="dark btn-rounded"
            onClick={() => setTrackingModal(true)}
          >
            <b>Enable Tracking</b>
          </Button>
        )}
        <Button
          style={{
            float: "right",
            margin: "10px",
            marginRight: "15px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() => mgrDownloadData()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px", overflowX: "auto" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={managersList}
              columns={columns}
            />
          </div>
          {managersList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Visit Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Clu</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>Do you really want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setBasicModal(false)} variant="danger light">
            Cancel
          </Button>
          <Button variant="primary" onClick={() => setBasicModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="fade" show={addManagerModal}>
        <Modal.Header>
          <Modal.Title>Add Manager</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddManagerModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AddManager close={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={trackingModal} size="lg">
        <Modal.Header>
          <Modal.Title>Tracking Access</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setTrackingModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <TrackingAccess close={() => setTrackingModal(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Managers;
