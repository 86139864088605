import React, { useEffect, useState } from "react";
// import * as moduleApi from "../rest/moduleApi";
import * as moduleApi2 from "../rest/moduleApi2";
import Flatpickr from "react-flatpickr";
import { Button } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import swal from "sweetalert";
import { apiCall } from "../rest/restApi";


function Payroll({ userId, organizationId }) {
  const [payrolllist, setPayrolllist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let listData = [];
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );
  useEffect(() => {
    loadPayroll(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadPayroll = (start, end) => {
    setIsLoading(true);
    // let url = apiCall.payrollUploadBaseUrl + "?action=getPayRollData";
    let payload = {
      // url: apiCall.bulkUploadBaseUrl,
      // action: "preSignedUrlStatusOrData",
      userId: userId,
      endDate: end,
      organizationId: organizationId,
      // presignedUrlForFileDownload: true,
      // limit: 10,
      // pageSize: pageNo,
      startDate: start,
    };
    moduleApi2
      .postData(
        apiCall.getPayrollData,
        payload
      )
      .then((response) => {
        console.log("response", response);
        response.data?.content?.forEach((element) => {
          let data = {
            id: element.id,
            empName: element.empName,
            userId: element.userId,
            organizationId: element.organizationId,
            basicPay: element.basicPay,
            hra: element.hra,
            specialAllowance: element.specialAllowance,
            providentFund: element.providentFund,
            professionalTax: element.professionalTax,
            netPay: element.netPay,
            totalEarnings: element.totalEarnings,
            totalDeductions: element.totalDeductions,
            monthWithYear: element.monthWithYear,
            designation: element.designation,
            empId: element.empId,
          };
          listData.push(data);
          console.log("listdata", listData);
        });
        console.log("listdata1", listData);
        setPayrolllist(listData);
        setIsLoading(false);
      });
  };
  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStartDate(start);
    setEndDate(end);
    loadPayroll(start, end);
  };
  const DownloadPayroll = () => {
    setIsLoading(true);
    const payload = {
      id: payrolllist[0]?.id,
      userId: userId,
    };
    moduleApi2
      .postData(apiCall.getPreSignedUrlForDownloadPayslip, payload)
      .then((response) => {
        if (response) {
          if (response?.message) {
            setIsLoading(false);
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.presignedUrl) {
            // moduleApi
            //   .getDownloadedFile(response?.data?.presignedUrl)
            //   .then((response) => {
            //     //Service Unavailable
            //     console.log("payroll download", response);
            //     setIsLoading2(false);
            //   });
            setIsLoading(false);
            window.open(response?.presignedUrl);
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try again", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div>
      
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginBottom: "5px",
            marginRight: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <label style={{ fontWeight: "bold" }}>
              Select Month
            </label>
          <Flatpickr
            className="form-control d-block"
            style={{
              width: "180px",
              height: "40px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              textAlignLast: "center",
            }}
            placeholder="Select Month"
            onChange={(value) => {
              setCustomDatesForGraph(value);
            }}
            options={{
              defaultDate: new Date(),
              maxDate: new Date(),
              minDate: new Date().setMonth(new Date().getMonth() - 6),

              plugins: [
                new monthSelectPlugin({
                  shorthand: true, //defaults to false
                  dateFormat: "F Y", //defaults to "F Y"
                  altFormat: "F Y", //defaults to "F Y"
                  theme: "light", // defaults to "light"
                }),
              ],
            }}
          />
        </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <Row>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                }}
              >
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <h6 className="fs-20 font-w600 my-1">Employee Details</h6>
                </div>
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "30px",
                    marginLeft: "20px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.empName ? payrolllist[0].empName : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Employee Id
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.empId ? payrolllist[0].empId : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Designation
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.designation
                          ? payrolllist[0].designation
                          : "-"}
                      </td>
                    </tr>
                  </>
                </div>
              </div>
            </Col>

            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                }}
              >
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <h6 className="fs-20 font-w600 my-1">Earnings</h6>
                </div>
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "15px",
                    marginLeft: "20px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Basic Pay
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.basicPay
                          ? payrolllist[0].basicPay
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Hra
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.hra ? payrolllist[0]?.hra : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Special Allowance
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.specialAllowance
                          ? payrolllist[0]?.specialAllowance
                          : "-"}
                      </td>
                    </tr>
                    {/* <hr></hr> */}
                    <tr>
                      <td
                        style={{
                          fontWeight: "700",
                          textAlign: "left",
                        }}
                      >
                        Total Earnings
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "700",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.totalEarnings
                          ? payrolllist[0]?.totalEarnings
                          : "-"}
                      </td>
                    </tr>
                  </>
                </div>
                {/* :null} */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                }}
              >
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <h6 className="fs-20 font-w600 my-1">Deductions</h6>
                </div>
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Professional Tax
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.professionalTax
                          ? payrolllist[0].professionalTax
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Provident Fund
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.providentFund
                          ? payrolllist[0]?.providentFund
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "700",
                          textAlign: "left",
                        }}
                      >
                        Total Deductions
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "700",
                          textAlign: "left",
                        }}
                      >
                        {payrolllist[0]?.totalDeductions
                          ? payrolllist[0]?.totalDeductions
                          : "-"}
                      </td>
                    </tr>
                  </>
                </div>
              </div>
            </Col>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  height: "3.5rem",
                }}
              >
                <div
                  style={{
                    margin: "12px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "700",
                          fontSize: "medium",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Net Pay
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "700",
                          textAlign: "left",
                          fontSize: "medium",
                        }}
                      >
                        {payrolllist[0]?.netPay ? payrolllist[0].netPay : "-"}
                      </td>
                    </tr>
                  </>
                </div>
                {/* :null} */}
              </div>
            </Col>
          </Row>
        </div>
        {payrolllist.length > 0 ? (
          <div>
            <Button
              className="me-2"
              variant="success"
              style={{ marginLeft: "40%" }}
              onClick={() => DownloadPayroll()}
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Download
            </Button>
          </div>
        ) : null}
      </div>
    </LoadingOverlay>
  );
}

export default Payroll;
