import React from "react";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import TextField from "../../CommonComponents/TextField";
import { Field } from "formik";
import Select from "react-select";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const EntryDeniedInfo = ({ key, mainOBJ, onChange, errors }) => {
  let applicantAgeOptions = [
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
    { label: "32", value: "32" },
    { label: "33", value: "33" },
    { label: "34", value: "34" },
    { label: "35", value: "35" },
    { label: "36", value: "36" },
    { label: "37", value: "37" },
    { label: "38", value: "38" },
    { label: "39", value: "39" },
    { label: "40", value: "40" },
    { label: "41", value: "41" },
    { label: "42", value: "42" },
    { label: "43", value: "43" },
    { label: "44", value: "44" },
    { label: "45", value: "45" },
    { label: "46", value: "46" },
    { label: "47", value: "47" },
    { label: "48", value: "48" },
    { label: "49", value: "49" },
    { label: "50", value: "50" },
    { label: "51", value: "51" },
    { label: "52", value: "52" },
    { label: "53", value: "53" },
    { label: "54", value: "54" },
    { label: "55", value: "55" },
    { label: "56", value: "56" },
    { label: "57", value: "57" },
    { label: "58", value: "58" },
    { label: "59", value: "59" },
    { label: "60", value: "60" },
    { label: "61", value: "61" },
    { label: "62", value: "62" },
    { label: "63", value: "63" },
    { label: "64", value: "64" },
    { label: "65", value: "65" },
  ];

  let familyMembersOptions = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
  ];

  let ownershipOptions = [
    { label: "Own House", value: "Own House" },
    { label: "Rent", value: "Rent" },
    { label: "Not Aware", value: "Not Aware" },
  ];

  return (
    <div style={{ margin: "15px", maxHeight: "400px", overflowY: "auto" }}>
      <Row>
        <Col>
          <TextField
            label="Contacted Person Name"
            name="contacted_person_name"
            type="text"
          />
          <TextField
            //
            label="Contact Number"
            name="contacted_person_number"
            type="text"
          />
          <TextField
            //
            label="Relation With The Applicant"
            name="relationship_with_applicant"
            type="text"
          />

          <Field name="neighbor_conf_applicant_age">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Applicant's Age
                </label>
                <Select
                  placeholder="Select Applicant's Age"
                  classNamePrefix="select-box"
                  options={applicantAgeOptions}
                  defaultValue={{
                    label: mainOBJ?.neighbor_conf_applicant_age,
                    value: mainOBJ?.neighbor_conf_applicant_age,
                  }}
                  // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
                  onChange={(value) => {
                    form.setFieldValue(
                      "neighbor_conf_applicant_age",
                      value.value
                    );
                  }}
                />
              </div>
            )}
          </Field>
          {errors.neighbor_conf_applicant_age && (
            <span className="error">{errors.neighbor_conf_applicant_age}</span>
          )}
          <Field name="neighbor_conf_family_count">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Family Members
                </label>
                <Select
                  placeholder="Select Family Members"
                  classNamePrefix="select-box"
                  options={familyMembersOptions}
                  defaultValue={{
                    label: mainOBJ?.neighbor_conf_family_count,
                    value: mainOBJ?.neighbor_conf_family_count,
                  }}
                  // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
                  onChange={(value) => {
                    form.setFieldValue(
                      "neighbor_conf_family_count",
                      value.value
                    );
                  }}
                />
              </div>
            )}
          </Field>
          {errors.neighbor_conf_family_count && (
            <span className="error">{errors.neighbor_conf_family_count}</span>
          )}
          <Field name="neighbor_conf_ownership">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Ownership
                </label>
                <Select
                  placeholder="Select Ownership"
                  classNamePrefix="select-box"
                  options={ownershipOptions}
                  defaultValue={{
                    label: mainOBJ?.neighbor_conf_ownership,
                    value: mainOBJ?.neighbor_conf_ownership,
                  }}
                  onChange={(value) => {
                    form.setFieldValue("neighbor_conf_ownership", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.neighbor_conf_ownership && (
            <span className="error">{errors.neighbor_conf_ownership}</span>
          )}
          <TextField label="Address" name="neighbor_conf_address" type="text" />
          <TextField
            label="Years Of Stay"
            name="neighbor_conf_years_stay"
            type="text"
          />
          <TextField label="Remarks 1" name="remarks_1" type="text" />
        </Col>
        <Col>
          <TextField label="Remarks 2" name="remarks_2" type="text" />
          <TextField label="Remarks 3" name="remarks_3" type="text" />
          <TextField label="Remarks 4" name="remarks_4" type="text" />
          <TextField label="Remarks 5" name="remarks_5" type="text" />
          <TextField label="Remarks 6" name="remarks_6" type="text" />
          <TextField label="Remarks 7" name="remarks_7" type="text" />
          <TextField label="Remarks 8" name="remarks_8" type="text" />
          <TextField label="Remarks 9" name="remarks_9" type="text" />
          <TextField label="Remarks 10" name="remarks_10" type="text" />
        </Col>
      </Row>
    </div>
  );
};

EntryDeniedInfo.label = "Entry Denied/Door locked";
EntryDeniedInfo.initialValues = {
  contacted_person_name: "",
  contacted_person_number: "",
  relationship_with_applicant: "",
  neighbor_conf_applicant_age: "",
  neighbor_conf_family_count: "",
  neighbor_conf_ownership: "",
  neighbor_conf_address: "",
  neighbor_conf_years_stay: "",
  remarks_1: "",
  remarks_2: "",
  remarks_3: "",
  remarks_4: "",
  remarks_5: "",
  remarks_6: "",
  remarks_7: "",
  remarks_8: "",
  remarks_9: "",
  remarks_10: "",
};

EntryDeniedInfo.validationSchema = Yup.object({
  // contacted_person_name: Yup.string().required("Contacted Person Name is required"),
  // contact_no: Yup.string().required("Contact Number is required"),
  // relationship_with_applicant: Yup.string().required("Relationship with the Applicant is required"),
  // applicant_age: Yup.string().required("Applicant's Age is required"),
  // family_members_count: Yup.string().required("Family Members is required"),
  // residence_ownership: Yup.string().required("Ownership is required"),
  // city_stay_inyears: Yup.string().required("Years Of Stay is required"),
  // remarks_1: Yup.string().required("Remarks 1 is required"),
});
EntryDeniedInfo.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
};

export default EntryDeniedInfo;
