import React, { useEffect, useState } from "react";
// import OrgChart from "./OrgService";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
// import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import defaultImage from "../images/defaultImage.png";
import ReactGA from "react-ga";
import SyncOrgHierarchy from "./SyncOrgHierarchy";

const OrgHierarchy = () => {
  const [orgData, setOrgData] = useState([]);
  const [updatedKey, setUpdatedKey] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    getOrgData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrgData = () => {
    setIsLoading(true);
    moduleApi
      .postData(
        apiCall.getOrgHierarchyData +
          userLoginDetails.autoFinanceUser.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const flatData = extractUserData(response);
        console.log("flatData", flatData);
        setOrgData(flatData);
        setUpdatedKey(Math.floor(Math.random() * 100000));
        setIsLoading(false);
      });
  };

  const extractUserData = (data) => {
    const extractedData = [];

    data.forEach((item) => {
      if (item.userMiniData) {
        // if (item.userMiniData !== undefined) {
        // const {
        //   userId: id,
        //   reportingOfficerId: pid,
        //   name,
        //   image: Img,
        //   companyDesignation: title,
        //   empId,
        //   email
        // } = item.userMiniData;
        // const img = Img ? Img : defaultImage;
        // extractedData.push({ id, pid, name, img, title, empId, email });

        const {
          userId: Id,
          reportingOfficerId: ReportingPerson,
          name: Name,
          image: img,
          companyDesignation: Designation,
          empId,
          // email
        } = item.userMiniData;
        const ImageUrl = img ? img : defaultImage;
        const isExpand = false;
        const RatingColor = "#68C2DE";
        extractedData.push({
          Id,
          Name,
          Designation,
          ImageUrl,
          isExpand,
          RatingColor,
          ReportingPerson,
          empId
        });
      }
      

      if (item.subOrdinateUsers && item.subOrdinateUsers.length > 0) {
        // if (
        //   item.subOrdinateUsers !== undefined &&
        //   item.userMiniData?.userId !== undefined
        // ) {
        extractedData.push(
          ...extractUserData(item.subOrdinateUsers, item.userMiniData?.userId)
        );
        // }
      }
    });

    // let temp = [];

    // for (let i = 0; i < 400; i++) {
    //   if (extractedData[i] !== undefined) {
    //     temp.push(extractedData[i]);
    //   }
    // }
    // return temp
    // console.log("extractedData", extractedData);
    return extractedData;
  };

  //   const flatData = extractUserData(data);
  //   console.log(flatData);

  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div
        className="card"
        style={{padding: "20px" }}
      >
        {/* <button
        onClick={() => {
          getOrgData();
        }}
      >
        click me
      </button> */}
        {/* <OrgChart
          nodes={orgData}
          key={updatedKey}
          // nodes={[
          //   {
          //     id: 1,
          //     name: "Denny Curtis",
          //     title: "CEO",
          //     img: "https://cdn.balkan.app/shared/2.jpg",
          //   },
          //   {
          //     id: 2,
          //     pid: 1,
          //     name: "Ashley Barnett",
          //     title: "Sales Manager",
          //     img: "https://cdn.balkan.app/shared/3.jpg",
          //   },
          //   {
          //     id: 3,
          //     pid: 1,
          //     name: "Caden Ellison",
          //     title: "Dev Manager",
          //     img: "https://cdn.balkan.app/shared/4.jpg",
          //   },
          //   {
          //     id: 4,
          //     pid: 2,
          //     name: "Elliot Patel",
          //     title: "Sales",
          //     img: "https://cdn.balkan.app/shared/5.jpg",
          //   },
          //   {
          //     id: 5,
          //     pid: 2,
          //     name: "Lynn Hussain",
          //     title: "Sales",
          //     img: "https://cdn.balkan.app/shared/6.jpg",
          //   },
          //   {
          //     id: 6,
          //     pid: 3,
          //     name: "Tanner May",
          //     title: "Developer",
          //     img: "https://cdn.balkan.app/shared/7.jpg",
          //   },
          //   {
          //     id: 7,
          //     pid: 3,
          //     name: "Fran Parsons",
          //     title: "Developer",
          //     img: "https://cdn.balkan.app/shared/8.jpg",
          //   },
          //   {
          //     id: 8,
          //     pid: 2,
          //     name: "Elliot Patel",
          //     title: "Sales",
          //     img: "https://cdn.balkan.app/shared/5.jpg",
          //   },
          //   {
          //     id: 9,
          //     pid: 2,
          //     name: "Lynn Hussain",
          //     title: "Sales",
          //     img: "https://cdn.balkan.app/shared/6.jpg",
          //   },
          //   {
          //     id: 10,
          //     pid: 3,
          //     name: "Tanner May",
          //     title: "Developer",
          //     img: "https://cdn.balkan.app/shared/7.jpg",
          //   },
          //   {
          //     id: 11,
          //     pid: 3,
          //     name: "Fran Parsons",
          //     title: "Developer",
          //     img: "https://cdn.balkan.app/shared/8.jpg",
          //   },
          //   {
          //     id: 12,
          //     pid: 2,
          //     name: "Elliot Patel",
          //     title: "Sales",
          //     img: "https://cdn.balkan.app/shared/5.jpg",
          //   },
          //   {
          //     id: 13,
          //     pid: 2,
          //     name: "Lynn Hussain",
          //     title: "Sales",
          //     img: "https://cdn.balkan.app/shared/6.jpg",
          //   },
          //   {
          //     id: 14,
          //     pid: 3,
          //     name: "Tanner May",
          //     title: "Developer",
          //     img: "https://cdn.balkan.app/shared/7.jpg",
          //   },
          //   {
          //     id: 15,
          //     pid: 3,
          //     name: "Fran Parsons",
          //     title: "Developer",
          //     img: "https://cdn.balkan.app/shared/8.jpg",
          //   },
          //   {
          //     id: 16,
          //     pid: 2,
          //     name: "Elliot Patel",
          //     title: "Sales",
          //     img: "https://cdn.balkan.app/shared/5.jpg",
          //   },
          //   {
          //     id: 17,
          //     pid: 2,
          //     name: "Lynn Hussain",
          //     title: "Sales",
          //     img: "https://cdn.balkan.app/shared/6.jpg",
          //   },
          //   {
          //     id: 18,
          //     pid: 3,
          //     name: "Tanner May",
          //     title: "Developer",
          //     img: "https://cdn.balkan.app/shared/7.jpg",
          //   },
          //   {
          //     id: 19,
          //     pid: 3,
          //     name: "Fran Parsons",
          //     title: "Developer",
          //     img: "https://cdn.balkan.app/shared/8.jpg",
          //   },
          // ]}
        /> */}
        <SyncOrgHierarchy orgData={orgData} key={updatedKey}/>
      </div>
    </LoadingOverlay>
  );
};

export default OrgHierarchy;
