/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { Button, Modal, Row, Col, Spinner } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";

import swal from "sweetalert";
import * as moduleApi from "../../../rest/moduleApi";
import { apiCall } from "../../../rest/restApi";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  getTokenInLocalStorage,
  hasAccess,
  hasAccessFromTeamPrivilege,
  hasAccessToAgency,
  isAdmin,
} from "../../../services/AuthService";
// import { useCalling } from "../../../CommonComponents/CallingContext";
import { ref, onValue } from "firebase/database";
// import { getApp, getApps, initializeApp } from "firebase/app";
// import CallingEventHandlers from "../../../CommonComponents/CallingMethods";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallRemarksModal,
  setCallAudioModal,
  setIsModalMinimised,
  setIsMyAllocationsCalled,
  setContinueeDialing,
  setTimerCount,
  setIsTimerCounting,
  setAllocationId,
  setSalesContinueeDialing,
  // setIsAddSaleLeadButton,
  setAllocationDetails,
  setIsAddSaleLeadButton,
  setIsCallActive,
  setIsIncomingCall,
  setIsIncomingLoanNoDropdown,
  setSelectedLoanDropDownValue,
  setSearchedLoanNoValue,
  // setIsIncomingLoanNoDropdown,
} from "../../../store/actions/CallingActions";
import AddRemarks from "../../../RequestPayments/AddRemarks";
// import PIOPIY from "piopiyjs";
import {
  AnswerCall,
  // CallTransfer,
  EndCall,
  HoldCall,
  MuteCall,
  UnHoldCall,
  UnmuteCall,
} from "../../../CommonComponents/TelecallingMethods";
import {
  EpochToDate,
  EpochToOnlyDate,
  getMaskedMobileNo,
} from "../../../CommonComponents/Helper";
import LoanNumberDetails from "../../../RequestPayments/LoanNumberDetails";
import { setAllocationDetailsModal } from "../../../store/actions/MyAllocationActions";
import ProspectDetails from "../../../SalesAllocations/ProspectDetails";
import TextField from "../../../CommonComponents/TextField";
import { Field, Form, Formik } from "formik";
import MuiAutoComplete from "../../../CommonComponents/MuiAutoComplete";
import { database } from "../../../CommonComponents/Firestore";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  // var userData = JSON.parse(localStorage.getItem("userDetails"));

  // const [piopiy, setPiopiy] = useState(null);

  // useEffect(() => {
  //   console.log("******* Inside useEffect ********");

  //   const userId = userData?.autoFinanceUser?.teleCmiUserId;
  //   const password = userData?.autoFinanceUser?.teleCmiPassword;

  //   console.log("Calling login function");

  //   var piopiyInstance = new PIOPIY({
  //     name: "clu",
  //     debug: false,
  //     autoplay: true,
  //     ringTime: 60,
  //   });

  //   piopiyInstance.login(userId, password, "sbcind.telecmi.com");
  //   console.log("******** piopiy instance ***********",piopiyInstance);
  //   piopiyInstance.on("login", () => {
  //     setPiopiy(piopiyInstance);
  //   });
  // }, []);
  // var piopiy = new PIOPIY({
  //   name: "clu",
  //   debug: false,
  //   autoplay: true,
  //   ringTime: 60,
  // });

  //Vastu
  // const firebaseConfig = {
  //   apiKey: "AIzaSyDPexqP_7LP4QklF8ZPdAsVV4HHgVr8-_c",
  //   authDomain: "clu-cloud-f0b93.firebaseapp.com",
  //   databaseURL: "https://clu-cloud-f0b93-default-rtdb.firebaseio.com",
  //   projectId: "clu-cloud-f0b93",
  //   storageBucket: "clu-cloud-f0b93.appspot.com",
  //   messagingSenderId: "115074287360",
  //   appId: "1:115074287360:web:d0879cde75edd0306dd5e5",
  //   measurementId: "G-WT2MBG922D",
  // };

  //QA
  // const firebaseConfig = {
  //   apiKey: "AIzaSyB8atYr2xkwGkmqfhSkui9FHk6TLybPs4Y",
  //   authDomain: "telecalling-qa.firebaseapp.com",
  //   databaseURL:
  //     "https://telecalling-qa-default-rtdb.asia-southeast1.firebasedatabase.app",
  //   projectId: "telecalling-qa",
  //   storageBucket: "telecalling-qa.appspot.com",
  //   messagingSenderId: "918263194810",
  //   appId: "1:918263194810:web:b3d99ba9f89a3cfcfb4e83",
  //   measurementId: "G-HG128X9HWW",
  // };

  //Prod
  //  const firebaseConfig = {
  //   apiKey: "AIzaSyBoVWUmadPQg52h6iql2ru-_OPzVpvT2Z8",
  //   authDomain: "telecalling-prod-65201.firebaseapp.com",
  //   projectId: "telecalling-prod-65201",
  //   databaseURL: "https://telecalling-prod-65201-default-rtdb.asia-southeast1.firebasedatabase.app",
  //   storageBucket: "telecalling-prod-65201.appspot.com",
  //   messagingSenderId: "1075727068041",
  //   appId: "1:1075727068041:web:7b15801b733d8d55ed0f58",
  //   // measurementId: "G-HG128X9HWW"
  // };

  // Initialize Firebase
  // initializeApp(firebaseConfig);
  // !getApps().length ? initializeApp(firebaseConfig) : getApp();

  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const {
    isCallAnswered,
    isCallRejected,
    isHold,
    isMute,
    isOnGoingCall,
    isRinging,
    callAudioModal,
    webRtcMobile,
    allocationId,
    callId,
    dailingTime,
    ringingTime,
    talkTime,
    holdTime,
    dispoTime,
    isProgressiveDialing,
    campaignName,
    callRemarksModal,
    type,
    allocationDetails,
    callStatusText,
    modalHeaderText,
    isModalMinimised,
    callTransfer,
    continueDialing,
    salesContinueDialing,
    isMyAllocationsCalled,
    callTransferModal,
    callStatus,
    isIncomingCall,
    incomingLoanNumbers,
    // selectedIncomingLoanNo,
    selectedLoanDropDownValue,
    isAddSaleLeadButton,
    isIncomingLoanNoDropdown,
    piopiy,
    callType,
    // isCallActive,
    // setCallTransferModal,
  } = useSelector((state) => state.calls);

  const [ozontelModal, setOzontelModal] = useState(false);
  const [languageCallersList, setLanguageCallersList] = useState(false);
  const [selectedTelecallerId, setSelectedTelecallerId] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [isAddLeadLoading, setIsAddLeadLoading] = useState(false);

  // eslint-disable-next-line
  const [callMobileNo, setCallMobileNo] = useState(null);
  // eslint-disable-next-line
  const [selectedAgentStatus, setSelectedAgentStatus] = useState(
    JSON.parse(localStorage.getItem("selectedAgentStatus"))
  );
  const [isHovered, setIsHovered] = useState(false);
  const [allocDetails, setAllocDetails] = useState({});
  const [loanDetailsModal, setLoanDetailsModal] = useState(false);
  const [sourcingChannels, setSourcingChannels] = useState([]);
  const [prospectDetailsModal, setProspectDetailsModal] = useState(false);
  const [addSaleLeadModal, setAddSaleLeadModal] = useState(false);
  const [addAdditionalDetailsModal, setAddAdditionalDetailsModal] =
    useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [pinCodes, setPinCodes] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedPinCode, setSelectedPinCode] = useState(null);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();

  var userData = JSON.parse(localStorage.getItem("userDetails"));

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function CloseMinimizeModal() {
    dispatch(setIsModalMinimised(false));
  }

  function HandleClose() {
    dispatch(setCallRemarksModal(false));
  }

  function HandleMyAllocations() {
    dispatch(setIsMyAllocationsCalled(!isMyAllocationsCalled));
  }

  function ContinueProgressiveDialing() {
    if (type === "Collections") {
      dispatch(setContinueeDialing(continueDialing + 1));
    } else {
      dispatch(setSalesContinueeDialing(salesContinueDialing + 1));
    }
  }

  function StartCountdownTimer() {
    dispatch(setTimerCount(10)); // Reset the countdown to 10 seconds
    dispatch(setIsTimerCounting(true));
  }

  function MakeAllocationIdNull() {
    dispatch(setAllocationId(null));
    setLoanDetailsModal(false);
    dispatch(setAllocationDetailsModal(false));
  }

  const languages = [
    { label: "Telugu", value: "Telugu" },
    { label: "Hindi", value: "Hindi" },
    { label: "English", value: "English" },
    { label: "Tamil", value: "Tamil" },
    { label: "Marathi", value: "Marathi" },
    { label: "Urdu", value: "Urdu" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Kannada", value: "Kannada" },
    { label: "Odia", value: "Odia" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Bengali", value: "Bengali" },
  ];

  const getUsersForLanguages = (language) => {
    // setIsTransferLoading(true);
    var payload = {
      language: language,
      // languages: ["string"],
      organizationId: userData?.autoFinanceUser?.organizationId,
      userId: userData?.autoFinanceUser?.userId,
    };
    moduleApi
      .postData(apiCall.getUsersBasedOnLanguage, payload)
      .then((response) => {
        // console.log("response", response);
        const db = database;
        const starCountRef = ref(
          db,
          "agentLiveStatus/" + userData?.autoFinanceUser?.organizationId
        );
        // console.log("starCountRef ", starCountRef);
        onValue(starCountRef, (snapshot) => {
          const data = snapshot.val();
          // updateStarCount(postElement, data);
          // console.log("dataFromDB", data);
          var tempData = Object.values(data).filter(
            (item) => response?.includes(item.userId) && item.status === "Idle"
          );
          // console.log("after", tempData);
          const result = tempData?.map((item) => ({
            value: item.telecmiUserId,
            label: item.empName + " - " + item.empId + " - " + item.status,
          }));
          setLanguageCallersList(result);
          // setIsTransferLoading(false);
          return data;
        });
      });
  };

  const getAllocationDetailsBasedOnId = (id) => {
    var payload = {
      id: id,
      organizationId: userData?.autoFinanceUser?.organizationId,
    };
    moduleApi
      .postData(apiCall.getAllocationDetailsById, payload)
      .then((element) => {
        var data = {
          id: element.id,
          customerName: element.customerName ? element.customerName : "-",
          dueAmount: element.dueAmount ? element.dueAmount : 0,
          contactNumber1: element.contactNumber1 ? element.contactNumber1 : "-",
          loanNumber: element.loanNumber ? element.loanNumber : "-",
          campaignCallIntiated: element.campaignCallIntiated,
          latestPaymentLinkId: element.latestPaymentLinkId
            ? element.latestPaymentLinkId
            : "-",
          latestPaymentLinkStatus: element.latestPaymentLinkStatus
            ? element.latestPaymentLinkStatus
            : "-",
          noOfpaymentLinkSent: element.noOfLinkSent ? element.noOfLinkSent : 0,
          status: element.status ? element.status : "-",
          totalCalls: element.totalCalls ? element.totalCalls : 0,
          callsToday: element.callsToday ? element.callsToday : 0,
          visitStatus: element.visitStatus,
          subStatus: element.subStatus,
          currentBucketName: element.currentBucketName
            ? element.currentBucketName
            : "-",
          remarks: element.remarks ? element.remarks : "-",
          branchName: element.branchName ? element.branchName : "-",
          amountPaid: element.amountPaid ? element.amountPaid : 0,
          addressLine2: element.addressLine2,
          aggDate: element.aggDate,
          installmentDate: element.installmentDate
            ? EpochToDate(element.installmentDate)
            : "-",
          amountPaidNow: element.amountPaidNow,
          amountToBePaid: element.amountToBePaid,
          assetDesc: element.assetDesc,
          assignedMobileNumber: element.assignedMobileNumber,
          assignedName: element.assignedName,
          bounceCharges: element.bounceCharges,
          branchId: element.branchId,
          chassisNumber: element.chassisNumber,
          comments: element.comments,
          contactNumber: element.contactNumber1,
          contactNumber2: element.contactNumber2,
          cpName: element.cpName,
          currentBucket: element.currentBucketName,
          customerIntensinty: element.customerIntensinty,
          lenderId: element.lenderId,
          lenderName: element.lenderName,
          lenderAddress: element.promoterAddress,
          locLink1: element.locLink1,
          locLink2: element.locLink2,
          borrowerAddress: element.borrowerAddress,
          current_EMI_Month: element.currentEmiMonthNumber,
          dateTime:
            element.dateTime !== 0 ? EpochToDate(element.dateTime) : "-",
          lastPaidDate:
            element.lastPaidDate !== 0
              ? EpochToDate(element.lastPaidDate)
              : "-",
          expiredDate:
            element.expiredDate !== 0
              ? EpochToOnlyDate(element.expiredDate)
              : "-",
          dueDate:
            element.dueDate !== 0 ? EpochToOnlyDate(element.dueDate) : "-",
          dueDay: element.dueDay ? element.dueDay : "-",
          emiAmount: element.emiAmount,
          emiOutstanding: element.emiOutstanding,
          engineNumber: element.engineNumber,
          employeeId: element.executiveEmployeeId
            ? element.executiveEmployeeId
            : element.managerEmployeeId,
          executiveMobileNumber: element.executiveMobileNumber,
          executiveName: element.executiveName,
          guarantorMobile: element.guarantorMobile,
          guarantorName: element.guarantorName,
          image: element.image,
          latePaymentCharges: element.latePaymentCharges,
          latitude: element.latitude,
          loanAmount: element.loanAmount,
          location: element.location,
          longitude: element.longitude,
          managerEmployeeId: element.managerEmployeeId,
          managerName: element.managerName,
          nearByLandmark: element.nearByLandmark,
          netOtherAmount: element.netOtherAmount,
          net_GDI_Amount: element.net_GDI_Amount,
          net_HL_Amount: element.net_HL_Amount,
          organizationId: element.organizationId,
          permanentAddress: element.permanentAddress,
          permanentCity: element.permanentCity,
          permanentState: element.permanentState,
          permanentZipcode: element.permanentZipcode,
          principalOutstanding: element.principalOutstanding,
          productName: element.productName,
          referenceMobile1: element.referenceMobile1,
          referenceMobile2: element.referenceMobile2,
          referenceName1: element.referenceName1,
          referenceName2: element.referenceName2,
          registrationNumber: element.registrationNumber,
          reportingOfficerId: element.reportingOfficerId,
          address: element.residenceAddress,
          residenceCity: element.residenceCity,
          residenceState: element.residenceState,
          residenceZipcode: element.residenceZipcode,
          teamLeaderEmployeeId: element.teamLeaderEmployeeId,
          teamLeaderName: element.teamLeaderName,
          teleCallerEmpId: element.teleCallerEmpId
            ? element.teleCallerEmpId
            : "-",
          teleCallerName: element.teleCallerName ? element.teleCallerName : "-",
          temporaryRegistrationNumber: element.temporaryRegistrationNumber,
          tenure: element.tenure,
          totalPenaltyCharges: element.totalPenaltyCharges,
          typeOfVisit: element.typeOfVisit,
          thirdPartyPhoneNumber: element.thirdPartyPhoneNumber,
          thirdPartyName: element.thirdPartyName,
          updatedDate:
            element.updatedDate !== 0 ? EpochToDate(element.updatedDate) : "-",
          lastCallUpdateTime:
            element.lastCallUpdateTime !== 0
              ? EpochToDate(element.lastCallUpdateTime)
              : "-",
          userId: element.userId,
          vehicleType: element.vehicleType,
          emiAmountPaid: element.emiAmountPaid ? element.emiAmountPaid : 0,
          emiAmountToBePaid: element.emiAmountToBePaid
            ? element.emiAmountToBePaid
            : 0,
          bounceChargesPaid: element.bounceChargesPaid
            ? element.bounceChargesPaid
            : 0,
          bounceChargesToBePaid: element.bounceChargesToBePaid
            ? element.bounceChargesToBePaid
            : 0,
          priority: element.priority ? element.priority : "-",
          latePaymentChargesPaid: element.latePaymentChargesPaid
            ? element.latePaymentChargesPaid
            : 0,
          latePaymentChargesToBePaid: element.latePaymentChargesToBePaid
            ? element.latePaymentChargesToBePaid
            : 0,
          penaltyChargesPaid: element.penaltyChargesPaid
            ? element.penaltyChargesPaid
            : 0,
          penaltyChargesToBePaid: element.penaltyChargesToBePaid
            ? element.penaltyChargesToBePaid
            : 0,
          otherChargesPaid: element.otherChargesPaid
            ? element.otherChargesPaid
            : 0,
          otherChargesToBePaid: element.otherChargesToBePaid
            ? element.otherChargesToBePaid
            : 0,
        };
        setAllocDetails(data);
        dispatch(setCallAudioModal(false));
        dispatch(setIsModalMinimised(true));
        setLoanDetailsModal(true);
      });
  };

  const handleLoanNumberDetailsModal = () => {
    setLoanDetailsModal(false);
  };

  const getSourceChannels = () => {
    var payload = {
      organizationId: userData.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(apiCall.getSourceChannelListForCalling, payload)
      .then((response) => {
        // console.log("response", response);
        const result = response?.map((element) => ({
          label: element?.sourceChannel,
          value: element?.sourceChannel,
          data: element,
        }));

        setSourcingChannels(result);
      });
  };

  const handleProspectDetailsModal = () => {
    setProspectDetailsModal(false);
  };

  const validationSchema = Yup.object().shape({
    customerName: Yup.string()
      .required("Customer Name is required")
      .min(2, "Customer Name must be at least 2 characters"),
    mobileNo: Yup.string()
      .required("Mobile No is required")
      .matches(/^[0-9]{10}$/, "Mobile No must be 10 digits"),
    channel: Yup.string().required("Sourcing Channel is required"),

    state: Yup.string().when([], {
      is: () => selectedState === "Others",
      then: Yup.string().required("State is required"),
      otherwise: Yup.string().notRequired(),
    }),
    city: Yup.string().when([], {
      is: () => selectedState === "Others",
      then: Yup.string().required("City is required"),
      otherwise: Yup.string().notRequired(),
    }),

    pinCode: Yup.string().when([], {
      is: () => selectedState === "Others",
      then: Yup.string()
        .required("PinCode is required")
        .matches(/^[0-9]{6}$/, "Pincode must be 6 digits"),
      otherwise: Yup.string().notRequired(),
    }),

    purposeOfcall: Yup.string().required("Purpose of Call is required"),
  });

  const handleAddSaleLeadSubmit = (values) => {
    setIsAddLeadLoading(true);

    if (selectedState === null) {
      setIsAddLeadLoading(false);
      return swal("Please Enter State", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (selectedState !== "Others" && selectedCity === null) {
      setIsAddLeadLoading(false);
      return swal("Please Enter City", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (selectedState !== "Others" && selectedPinCode === null) {
      setIsAddLeadLoading(false);
      return swal("Please Enter Pin Code", {
        icon: "error",
        dangerMode: true,
      });
    }

    var payload = {
      channel: values?.channel,
      city: selectedState !== "Others" ? selectedCity : values?.city,
      customerName: values?.customerName,
      organizationId: userData.autoFinanceUser.organizationId,
      phoneNumber: values?.mobileNo,
      pinCode: selectedState !== "Others" ? selectedPinCode : values?.pinCode,
      purposeOfcall: values?.purposeOfcall,
      state: selectedState !== "Others" ? selectedState : values?.state,
      userId: userData.autoFinanceUser.userId,
    };

    moduleApi.postData(apiCall.addLead, payload).then((response) => {
      console.log(response);
      if (response) {
        if (response?.message) {
          setIsAddLeadLoading(false);
          return swal(response?.message, {
            icon: "error",
            dangerMode: "true",
          });
        } else {
          localStorage.setItem("allocationId", response?.id);
          dispatch(setAllocationId(response?.id));
          dispatch(setAllocationDetails(response));
          dispatch(setIsAddSaleLeadButton(false));
          setSelectedCity(null);
          setSelectedPinCode(null);
          setSelectedState(null);
          setAddSaleLeadModal(false);
          setIsAddLeadLoading(false);
        }
      } else {
        setIsAddLeadLoading(false);
        return swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  // function getDataFromFirebaseDB() {
  //   const db = getDatabase();
  //   const starCountRef = ref(
  //     db,
  //     "agentLiveStatus/" + userData?.autoFinanceUser?.organizationId
  //   );
  //   console.log("starCountRef ", starCountRef);
  //   onValue(starCountRef, (snapshot) => {
  //     const data = snapshot.val();
  //     // updateStarCount(postElement, data);
  //     console.log("dataFromDB", data);
  //     return data;
  //   });
  // }

  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  /// Active menu
  let home = ["home"],
    // testCall = ["test-call"],
    // testCall2 = ["test-call2"],
    dashboard = ["custom-dashboards"],
    upiMandates = ["subscriptions", "transactions", "upi-mandate-summary"],
    userManagement = [
      "executives",
      "executive-details",
      "managers",
      "manager-details",
      "manager-executive-details",
      "cp-users",
      "fi-clients",
      "service-executives",
      "org-hierarchy",
      "read-only-users-[Incharges]",
      "read-only-user-details",
      "inactive-users",
      "inactive-details",
    ],
    paymentsMgmt = ["payments-history", "cash-depositions", "request-payments"],
    sales = ["vehicle-sales", "vehicle-services"],
    campiagnMgmt = ["workflow"],
    customerMgmt = ["visits", "cash-deposition", "customers"],
    incentiveMgmt = ["visits"],
    requestPayments = ["request-payments"],
    vehicleSaleData = ["vehicle-sales"],
    vehicleServiceData = ["vehicle-services"],
    cashDeposition = ["cash-depositions"],
    subscriptions = ["subscriptions"],
    transactions = ["transactions"],
    upiMandateSummary = ["upi-mandate-summary"],
    payments = ["payments-history"],
    salesMgmt = ["opportunities", "potential-leads", "sales-teams"],
    salesCrm = ["opportunities"],
    salesLeads = ["potential-leads"],
    salesTeams = ["teams"],
    manager = ["managers", "manager-details", "manager-executive-details"],
    executive = ["executives", "executive-details"],
    cpUsers = ["cp-users"],
    // fiClients = ["fi-clients"],
    branches = ["branches"],
    designations = ["designations"],
    agency = ["agencies"],
    visit = ["visits"],
    onboarding = ["onboarding"],
    expense = ["expenses"],
    // workflow = ["work-flow"],
    report = ["reports"],
    callMgmt = ["live-dashboard", "collections-report", "sales-report"],
    callReports = ["collections-report"],
    salesReports = ["sales-report"],
    callDashboard = ["live-dashboard"],
    cannedReport = ["preBuilt-reports"],
    // dashboard2 = ["dashboard"],
    leave = ["leaves"],
    orghierarchy = ["org-hierarchy"],
    payroll_for_executives = ["/payroll-[Beta]"],
    orgAssets = ["org-assets"],
    customer = ["customers"],
    incentives = ["incentives"],
    reporteeIncentives = ["reportee-incentives"],
    finalPayoutDetails = ["final-payout-details"],
    serviceExecutives = ["service-executives"],
    inactive = ["inactive-users", "inactive-details"],
    managerRA = ["read-only-users-[Incharges]", "read-only-user-details"],
    //uplaod = ["collections-allocation"],
    workAllocations = [
      "field-investigation",
      "collections",
      "myCollections",
      "sales",
      "sales-allocations",
      "PTP",
    ],
    fiallocations = ["field-investigation"],
    colletionallocations = ["collections"],
    workflow = ["workflow"],
    myAllocations = ["allocations/my-allocations"],
    salesAllocations = ["sales"],
    mySalesAllocations = ["sales-allocations"],
    // ptpList = ["PTP"],
    administration = [
      "branches",
      "designations",
      "leaves",
      "expenses",
      "payroll-[Beta]",
      "org-assets",
    ],
    whatsapp = ["templates"],
    template = ["template"],
    analytics = ["custom-dashboards", "preBuilt-reports"],
    agencyAllocations = ["fi-allocations"];

  const getStatesCitiesPincode = (value, type, value2) => {
    var payload = {
      city: type === "city" ? value : undefined,
      organizationId: userData?.autoFinanceUser?.organizationId,
      state:
        type === "state" || type === "city"
          ? value2 !== ""
            ? value2
            : value
          : undefined,
    };
    moduleApi
      .postData(apiCall.getStatesCitiesPincode, payload)
      .then((response) => {
        const result = response?.map((item) => ({
          value: item,
          label: item,
        }));
        if (type === "state") {
          setCities(result);
        } else if (type === "") {
          result.push({ label: "Others", value: "Others" });
          setStates(result);
        } else if (type === "city") {
          setPinCodes(result);
        }
      });
  };

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          {hasAccessToAgency() ? (
            <li
              className={`${
                agencyAllocations.includes(path) ? "mm-active" : ""
              }`}
            >
              <Link to="/fi-allocations" className="ai-icon">
                &nbsp;
                <img
                  src="/sidebar/allocations.png"
                  alt="sidebar"
                  style={{ width: 25, height: 25, marginRight: 10 }}
                />
                <span className="nav-text">&nbsp;&nbsp;FI Allocations</span>
              </Link>
            </li>
          ) : (
            <>
              {userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                userData?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE" && (
                  <li className={`${home.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/home" className="ai-icon">
                      <img
                        src="/sidebar/home.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">Home</span>
                    </Link>
                  </li>
                )}
              {/* {userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                userData?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE" && (
                  <li className={`${testCall.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/test-call" className="ai-icon">
                      <img
                        src="/sidebar/home.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">Test Call</span>
                    </Link>
                  </li>
                )} */}
              {/* {userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                userData?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE" && (
                  <li
                    className={`${testCall2.includes(path) ? "mm-active" : ""}`}
                  >
                    <Link to="/test-call2" className="ai-icon">
                      <img
                        src="/sidebar/home.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">Test Call 2</span>
                    </Link>
                  </li>
                )} */}
              {hasAccess(53) && (
                <li className={`${callMgmt.includes(path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow ai-icon" to="#">
                    <img
                      src="/sidebar/callMgmt.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Call Mgmt</span>
                  </Link>
                  <ul>
                    {userData?.autoFinanceOrganization?.enableLiveDashboard ===
                      true &&
                      hasAccess(66) && (
                        <li
                          className={`${
                            callDashboard.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/live-dashboard" className="ai-icon">
                            <img
                              src="/sidebar/callDashboard.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">
                              &nbsp;&nbsp;Live Dashboard
                            </span>
                          </Link>
                        </li>
                      )}
                    {hasAccess(65) && (
                      <li
                        className={`${
                          callReports.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/collections-report" className="ai-icon">
                          &nbsp;
                          <img
                            src="/sidebar/callReport.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;Collections Report
                          </span>
                        </Link>
                      </li>
                    )}
                    {hasAccess(64) && (
                      <li
                        className={`${
                          salesReports.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/sales-report" className="ai-icon">
                          &nbsp;
                          <img
                            src="/sidebar/saleReport.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;Sales Report
                          </span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {userData?.autoFinanceUser?.roleName === "SERVICE_EXECUTIVE" && (
                <li className={`${sales.includes(path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow ai-icon" to="#">
                    <img
                      src="/sidebar/vehicleServices.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Vehicle Services</span>
                  </Link>
                  <ul>
                    <li
                      className={`${
                        vehicleSaleData.includes(path) ? "mm-active" : ""
                      }`}
                    >
                      <Link to="/vehicle-sales" className="ai-icon">
                        <img
                          src="/sidebar/vehicleSaleData.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">
                          &nbsp;&nbsp;Vehicle Sales
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`${
                        vehicleServiceData.includes(path) ? "mm-active" : ""
                      }`}
                    >
                      <Link to="/vehicle-services" className="ai-icon">
                        <img
                          src="/sidebar/vehicleServiceData.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">
                          &nbsp;&nbsp;Vehicle Services
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {hasAccess(47) && (
                <li
                  className={`${analytics.includes(path) ? "mm-active" : ""}`}
                >
                  <Link className="has-arrow ai-icon" to="#">
                    <img
                      src="/sidebar/analytics_icon.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Analytics</span>
                  </Link>
                  <ul>
                    {/* {hasAccess(1) && ( */}
                    <li
                      className={`${
                        dashboard.includes(path) ? "mm-active" : ""
                      }`}
                    >
                      <Link to="/custom-dashboards" className="ai-icon">
                        <img
                          src="/sidebar/dashboard.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">
                          &nbsp;&nbsp;Custom Dashboards
                        </span>
                      </Link>
                    </li>
                    {/* )} */}
                    {/* {hasAccess(2) && ( */}
                    <li
                      className={`${
                        cannedReport.includes(path) ? "mm-active" : ""
                      }`}
                    >
                      <Link to="/PreBuilt-reports" className="ai-icon">
                        <img
                          src="/sidebar/canned_reports.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">
                          &nbsp;&nbsp;PreBuilt Reports
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={`${
                        dashboard2.includes(path) ? "mm-active" : ""
                      }`}
                    >
                      <Link to="/Dashboard" className="ai-icon">
                        <img
                          src="/sidebar/canned_reports.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">
                          &nbsp;&nbsp;Dashboard
                        </span>
                      </Link>
                    </li> */}
                    {/* )} */}
                  </ul>
                </li>
              )}
              {/* {isAdmin() && (
                <li
                  className={`${dashboard.includes(path) ? "mm-active" : ""}`}
                >
                  <Link to="/dashboard" className="ai-icon">
                    <img
                      src="/sidebar/analytics_icon.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Dashboard</span>
                  </Link>
                </li>
              )} */}
              {userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                userData?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE" && (
                  <li
                    id="userMgmt"
                    className={`${
                      userManagement.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    <Link id="userMgmt1" className="has-arrow ai-icon" to="#">
                      {/* <i className="fas fa-user"></i> */}
                      <img
                        src="/sidebar/userManagement.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">Employee Mgmt&nbsp;</span>
                    </Link>
                    <ul>
                      {hasAccess(11) && (
                        <li
                          className={`${
                            manager.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link
                            id="managers"
                            to="/managers"
                            className="ai-icon"
                          >
                            <img
                              src="/sidebar/manager.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span id="managers1" className="nav-text">
                              &nbsp;Managers
                            </span>
                          </Link>
                        </li>
                      )}

                      {/* {hasAccess(10) && ( */}
                      <li
                        className={`${
                          executive.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link
                          id="executives"
                          to="/executives"
                          className="ai-icon"
                        >
                          <img
                            src="/sidebar/executives.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span id="executives1" className="nav-text">
                            {" "}
                            Executives
                          </span>
                        </Link>
                      </li>
                      {/* )} */}
                      {userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                        userData?.autoFinanceUser?.roleName !==
                          "SERVICE_EXECUTIVE" &&
                        hasAccess(44) && (
                          <li
                            className={`${
                              cpUsers.includes(path) ? "mm-active" : ""
                            }`}
                          >
                            <Link
                              id="cpUsers"
                              to="/cp-users"
                              className="ai-icon"
                            >
                              <img
                                src="/sidebar/cpUsers.png"
                                alt="sidebar"
                                style={{
                                  width: 25,
                                  height: 25,
                                  marginRight: 10,
                                }}
                              />
                              <span id="cpUsers1" className="nav-text">
                                {" "}
                                CP Users
                              </span>
                            </Link>
                          </li>
                        )}
                      {userData?.autoFinanceUser?.roleName !== "MANAGER_RA" &&
                        userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                        userData?.autoFinanceUser?.roleName !==
                          "SERVICE_EXECUTIVE" && (
                          <li
                            className={`${
                              inactive.includes(path) ? "mm-active" : ""
                            }`}
                          >
                            <Link
                              id="inactiveUsers"
                              to="/inactive-users"
                              className="ai-icon"
                            >
                              <i
                                className="fas fa-users-slash"
                                style={{ color: "#886CC0" }}
                              ></i>
                              <span id="inactiveUsers1" className="nav-text">
                                InActive Users
                              </span>
                            </Link>
                          </li>
                        )}
                      {userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                        userData?.autoFinanceUser?.roleName !==
                          "SERVICE_EXECUTIVE" &&
                        hasAccess(45) && (
                          <li
                            className={`${
                              managerRA.includes(path) ? "mm-active" : ""
                            }`}
                          >
                            <Link
                              id="readonlyUsers"
                              to="/read-only-users-[Incharges]"
                              className="ai-icon"
                            >
                              <img
                                src="/sidebar/managerRaUsers.png"
                                alt="sidebar"
                                style={{
                                  width: 25,
                                  height: 25,
                                  marginRight: 10,
                                }}
                              />
                              <span id="readonlyUsers1" className="nav-text">
                                Read Only Users
                              </span>
                            </Link>
                          </li>
                        )}
                      {userData?.autoFinanceUser?.roleName !==
                        "SERVICE_EXECUTIVE" &&
                        userData?.autoFinanceUser?.roleName === "ADMIN" &&
                        hasAccess(43) && (
                          <li
                            className={`${
                              serviceExecutives.includes(path)
                                ? "mm-active"
                                : ""
                            }`}
                          >
                            <Link to="/service-executives" className="ai-icon">
                              <img
                                src="/sidebar/serviceExecutives.png"
                                alt="sidebar"
                                style={{
                                  width: 25,
                                  height: 25,
                                  marginRight: 10,
                                }}
                              />
                              <span className="nav-text">
                                Service Executives
                              </span>
                            </Link>
                          </li>
                        )}
                      {hasAccess(40) && (
                        <li
                          className={`${
                            orghierarchy.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link
                            id="orgHierarchy"
                            to="/org-hierarchy"
                            className="ai-icon"
                          >
                            <img
                              src="/sidebar/hierarchy.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span id="orgHierarchy1" className="nav-text">
                              &nbsp;&nbsp;Org Hierarchy
                            </span>
                          </Link>
                        </li>
                      )}
                      {hasAccess(76) && (
                        <li
                          className={`${
                            salesTeams.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/teams" className="ai-icon">
                            <img
                              src="/sidebar/salesTeams.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">&nbsp;&nbsp;Teams</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

              {/* {(hasAccess(3) || hasAccess(4) || hasAccess(5)) && (
                <li className={`${visit.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/visits" className="ai-icon">
                    <img
                      src="/sidebar/visits.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Visits</span>
                  </Link>
                </li>
              )} */}
              {userData.autoFinanceUser &&
                (userData.autoFinanceUser.roleName === "SUPERADMIN" ||
                  userData.isSuperAdmin === true) && (
                  <li
                    className={`${
                      onboarding.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/onboarding" className="ai-icon">
                      <img
                        src="/sidebar/onboarding.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">OnBoarding</span>
                    </Link>
                  </li>
                )}

              {hasAccess(30) && (
                // {isAdmin() && (
                <li
                  className={`${upiMandates.includes(path) ? "mm-active" : ""}`}
                >
                  <Link className="has-arrow ai-icon" to="#">
                    {/* <i className="fas fa-user"></i> */}
                    <img
                      src="/sidebar/upiMandate.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">UPI Mandates</span>
                  </Link>
                  <ul>
                    {/* {hasAccess(1) && ( */}
                    <li
                      className={`${
                        subscriptions.includes(path) ? "mm-active" : ""
                      }`}
                    >
                      <Link to="/subscriptions" className="ai-icon">
                        {/* <i className="fa fa-money-bill-alt"></i> */}
                        <img
                          src="/sidebar/subscription.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">
                          &nbsp;&nbsp;Subscriptions
                        </span>
                      </Link>
                    </li>
                    {/* )} */}
                    {/* {hasAccess(1) && ( */}
                    <li
                      className={`${
                        transactions.includes(path) ? "mm-active" : ""
                      }`}
                    >
                      <Link to="/transactions" className="ai-icon">
                        {/* <i className="fa fa-money-bill-alt"></i> */}
                        <img
                          src="/sidebar/transaction.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">
                          &nbsp;&nbsp;Transactions
                        </span>
                      </Link>
                    </li>
                    {hasAccess(102) && (
                      <li
                        className={`${
                          upiMandateSummary.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/upi-mandate-summary" className="ai-icon">
                          {/* <i className="fa fa-money-bill-alt"></i> */}
                          <img
                            src="/sidebar/upiMandateSummary.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;UPI Mandate Summary
                          </span>
                        </Link>
                      </li>
                    )}
                    {/* {hasAccess(1) && ( */}

                    {/* )} */}
                  </ul>
                </li>
              )}

              {(hasAccess(36) || hasAccess(37)) && (
                <li
                  className={`${
                    paymentsMgmt.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link className="has-arrow ai-icon" to="#">
                    {/* <i className="fas fa-user"></i> */}
                    <img
                      src="/sidebar/payments.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Payments</span>
                  </Link>
                  <ul>
                    {hasAccess(37) && (
                      <li
                        className={`${
                          payments.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/payments-history" className="ai-icon">
                          <img
                            src="/sidebar/paymentsHistory.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;Payments History
                          </span>
                        </Link>
                      </li>
                    )}
                    {hasAccess(36) && (
                      <li
                        className={`${
                          cashDeposition.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/cash-depositions" className="ai-icon">
                          <img
                            src="/sidebar/cashDeposition.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;Cash Depositions
                          </span>
                        </Link>
                      </li>
                    )}
                    {hasAccess(48) && (
                      <li
                        className={`${
                          requestPayments.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/request-payments" className="ai-icon">
                          <img
                            src="/sidebar/requestPayment.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;Request Payments
                          </span>
                        </Link>
                      </li>
                    )}
                    {/* )} */}
                  </ul>
                </li>
              )}
              {(hasAccess(79) || hasAccess(77)) && (
                <li
                  className={`${salesMgmt.includes(path) ? "mm-active" : ""}`}
                >
                  <Link className="has-arrow ai-icon" to="#">
                    <img
                      src="/sidebar/salesMgmt.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Lead Mgmt</span>
                  </Link>
                  <ul>
                    {hasAccess(79) && (
                      <li
                        className={`${
                          salesCrm.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/opportunities" className="ai-icon">
                          <img
                            src="/sidebar/opportunities.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;Opportunities
                          </span>
                        </Link>
                      </li>
                    )}
                    {hasAccess(77) && (
                      <li
                        className={`${
                          salesLeads.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/potential-leads" className="ai-icon">
                          <img
                            src="/sidebar/salesLeads.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;Potential Leads
                          </span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {userData?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE" && (
                <li
                  className={`${
                    workAllocations.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link className="has-arrow ai-icon" to="#">
                    {/* <i className="fas fa-home"></i> */}
                    <img
                      src="/sidebar/allocations.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Work Allocations</span>
                  </Link>
                  <ul>
                    {userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                      hasAccess(80) && (
                        <li
                          className={`${
                            fiallocations.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/field-investigation" className="ai-icon">
                            {/* <i className="fa fa-clipboard"></i> */}
                            <img
                              src="/sidebar/visits.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">
                              Field investigation
                            </span>
                          </Link>
                        </li>
                      )}
                    {hasAccess(81) && (
                      <li
                        className={`${
                          colletionallocations.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/collections" className="ai-icon">
                          {/* <i className="fa fa-money-bill-alt"></i> */}
                          <img
                            src="/sidebar/collector.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">Collections</span>
                        </Link>
                      </li>
                    )}

                    {hasAccess(49) && (
                      <li
                        className={`${
                          myAllocations.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link
                          to="/allocations/my-allocations"
                          className="ai-icon"
                        >
                          {/* <i className="fa fa-money-bill-alt"></i> */}
                          <img
                            src="/sidebar/myAllocations.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">My Allocations</span>
                        </Link>
                      </li>
                    )}
                    {hasAccess(63) && (
                      <li
                        className={`${
                          salesAllocations.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/sales" className="ai-icon">
                          {/* <i className="fa fa-money-bill-alt"></i> */}
                          <img
                            src="/sidebar/salesAllocations2.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">Sales</span>
                        </Link>
                      </li>
                    )}
                    {hasAccess(62) && (
                      <li
                        className={`${
                          mySalesAllocations.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/sales-allocations" className="ai-icon">
                          {/* <i className="fa fa-money-bill-alt"></i> */}
                          <img
                            src="/sidebar/mySales.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">Sales Allocations</span>
                        </Link>
                      </li>
                    )}
                    {/* {hasAccess(49) && ( */}
                    {/* <li
                      className={`${ptpList.includes(path) ? "mm-active" : ""}`}
                    >
                      <Link to="/PTP" className="ai-icon">
                        <img
                          src="/sidebar/myAllocations.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">PTP</span>
                      </Link>
                    </li> */}
                    {/* )} */}
                    {/* <li><Link className={`${workAllocations.includes(path) ? "mm-active" : ""}`} to="/field-investigation-allocation"> FI Allocations</Link></li>
                  <li><Link className={`${workAllocations.includes(path) ? "mm-active" : ""}`} to="/collections-allocation">Collections Allocations</Link></li> */}
                  </ul>
                </li>
              )}

              {userData?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE" &&
                hasAccess(70) && (
                  <li
                    className={`${
                      incentiveMgmt.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    <Link className="has-arrow ai-icon" to="#">
                      <img
                        src="/sidebar/incentiveMgmt.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">Incentive Mgmt</span>
                    </Link>
                    <ul>
                      <li
                        className={`${
                          incentives.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/incentives" className="ai-icon">
                          <img
                            src="/sidebar/incentives.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">Incentives</span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          reporteeIncentives.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/reportee-incentives" className="ai-icon">
                          <img
                            src="/sidebar/ReporteeIncentives.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">Reportee Incentives</span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          finalPayoutDetails.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/final-payout-details" className="ai-icon">
                          <img
                            src="/sidebar/FinalPayout.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">Final Payout</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
              {userData?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE" && (
                <li
                  className={`${
                    customerMgmt.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link className="has-arrow ai-icon" to="#">
                    <img
                      src="/sidebar/customer.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Customer Mgmt</span>
                  </Link>
                  <ul>
                    {(hasAccess(3) || hasAccess(4) || hasAccess(5)) && (
                      <li
                        className={`${visit.includes(path) ? "mm-active" : ""}`}
                      >
                        <Link to="/visits" className="ai-icon">
                          {/* <i className="fa fa-eye"></i> */}
                          <img
                            src="/sidebar/visits.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">Visits</span>
                        </Link>
                      </li>
                    )}

                    {hasAccess(18) && (
                      <li
                        className={`${
                          customer.includes(path) ? "mm-active" : ""
                        }`}
                      >
                        <Link to="/customers" className="ai-icon">
                          {/* <i className="fa fa-users"></i> */}
                          <img
                            src="/sidebar/customerDetails.png"
                            alt="sidebar"
                            style={{ width: 25, height: 25, marginRight: 10 }}
                          />
                          <span className="nav-text">Customer Details</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {userData?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE" &&
                userData?.autoFinanceUser?.roleName === "ADMIN" && (
                  <li
                    className={`${
                      campiagnMgmt.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    <Link className="has-arrow ai-icon" to="#">
                      {/* <i className="fas fa-home"></i> */}
                      <img
                        src="/sidebar/campaign2.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">Campaign Mgmt</span>
                    </Link>
                    <ul>
                      {userData?.autoFinanceUser?.roleName === "ADMIN" && (
                        <li
                          className={`${
                            workflow.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/workflow" className="ai-icon">
                            {/* <i className="fa fa-money-bill-alt"></i> */}
                            <img
                              src="/sidebar/workflow.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">Workflow</span>
                          </Link>
                        </li>
                      )}
                      {hasAccess(49) && (
                        <li
                          className={`${
                            myAllocations.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link
                            to="/allocations/my-allocations"
                            className="ai-icon"
                          >
                            {/* <i className="fa fa-money-bill-alt"></i> */}
                            <img
                              src="/sidebar/myAllocations.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">My Allocations</span>
                          </Link>
                        </li>
                      )}
                      {/* {hasAccess(49) && ( */}
                      {/* <li
                      className={`${ptpList.includes(path) ? "mm-active" : ""}`}
                    >
                      <Link to="/PTP" className="ai-icon">
                        <img
                          src="/sidebar/myAllocations.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        />
                        <span className="nav-text">PTP</span>
                      </Link>
                    </li> */}
                      {/* )} */}
                      {/* <li><Link className={`${workAllocations.includes(path) ? "mm-active" : ""}`} to="/field-investigation-allocation"> FI Allocations</Link></li>
                  <li><Link className={`${workAllocations.includes(path) ? "mm-active" : ""}`} to="/collections-allocation">Collections Allocations</Link></li> */}
                    </ul>
                  </li>
                )}
              {userData?.autoFinanceUser?.roleName !== "CP_USER" &&
                (hasAccess(1) || hasAccess(2)) && (
                  <li
                    className={`${
                      administration.includes(path) ? "mm-active" : ""
                    }`}
                  >
                    <Link className="has-arrow ai-icon" to="#">
                      <img
                        src="/sidebar/administrator.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">Administration</span>
                    </Link>
                    <ul>
                      {/* {hasAccess(1) && (
                        <li
                          className={`${
                            workflow.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/work-flow" className="ai-icon">
                            <img
                              src="/sidebar/expenses.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">
                              &nbsp;&nbsp;Work Flow
                            </span>
                          </Link>
                        </li>
                      )} */}
                      {/* {hasAccess(1) && (
                        <li
                          className={`${
                            workflow.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/work-flow" className="ai-icon">
                            <img
                              src="/sidebar/expenses.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">
                              &nbsp;&nbsp;Work Flow
                            </span>
                          </Link>
                        </li>
                      )} */}
                      {hasAccess(46) && (
                        <li
                          className={`${
                            branches.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/branches" className="ai-icon">
                            <img
                              src="/sidebar/branches.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text"> Branches</span>
                          </Link>
                        </li>
                      )}
                      {hasAccess(84) && (
                        <li
                          className={`${
                            designations.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/designations" className="ai-icon">
                            <img
                              src="/sidebar/designations.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text"> Designations</span>
                          </Link>
                        </li>
                      )}

                      {hasAccess(1) && (
                        <li
                          className={`${
                            expense.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/expenses" className="ai-icon">
                            <img
                              src="/sidebar/expenses.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">
                              &nbsp;&nbsp;Expenses
                            </span>
                          </Link>
                        </li>
                      )}
                      {hasAccess(2) && (
                        <li
                          className={`${
                            leave.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/leaves" className="ai-icon">
                            <img
                              src="/sidebar/exit.png"
                              alt="sidebar"
                              style={{ width: 25, height: 25, marginRight: 10 }}
                            />
                            <span className="nav-text">&nbsp;&nbsp;Leaves</span>
                          </Link>
                        </li>
                      )}
                      {/* {hasAccess(2) && ( */}
                      {/* {isAdmin() && ( */}
                      <li
                        className={`${
                          payroll_for_executives.includes(path)
                            ? "mm-active"
                            : ""
                        }`}
                      >
                        <Link to="/payroll-[Beta]" className="ai-icon">
                          <img
                            src="/sidebar/payroll.png"
                            alt="sidebar"
                            style={{ width: 30, height: 30, marginRight: 5 }}
                          />
                          <span className="nav-text">
                            &nbsp;&nbsp;Payroll
                            <sup>
                              {" "}
                              <b>[ Beta ]</b>
                            </sup>
                          </span>
                        </Link>
                      </li>
                      {/* )} */}
                      {hasAccess(56) && (
                        <li
                          className={`${
                            orgAssets.includes(path) ? "mm-active" : ""
                          }`}
                        >
                          <Link to="/org-assets" className="ai-icon">
                            <img
                              src="/sidebar/assets.png"
                              alt="sidebar"
                              style={{ width: 30, height: 30, marginRight: 5 }}
                            />
                            <span className="nav-text">
                              &nbsp;&nbsp;Org Assets
                            </span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              {/* {hasAccess(18) && (
                <li className={`${customer.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/customers" className="ai-icon">
                    <img
                      src="/sidebar/customer.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Customers</span>
                  </Link>
                </li>
              )} */}
              <li className={`${whatsapp.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  <img
                    src="/sidebar/whatsapp.png"
                    alt="sidebar"
                    style={{ width: 25, height: 25, marginRight: 10 }}
                  />
                  <span className="nav-text">Whatsapp</span>
                </Link>
                <ul>
                  {/* {hasAccess(7) && ( */}
                  <li
                    className={`${template.includes(path) ? "mm-active" : ""}`}
                  >
                    <Link to="/templates" className="ai-icon">
                      &nbsp;
                      <img
                        src="/sidebar/createTemplate.png"
                        alt="sidebar"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                      />
                      <span className="nav-text">&nbsp;&nbsp;Templates</span>
                    </Link>
                  </li>
                  {/* )} */}
                </ul>
              </li>
              {isAdmin() && (
                <li className={`${agency.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/agencies" className="ai-icon">
                    <img
                      src="/sidebar/agency.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">Agencies</span>
                  </Link>
                </li>
              )}

              {hasAccess(7) && (
                <li className={`${report.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/reports" className="ai-icon">
                    &nbsp;
                    <img
                      src="/sidebar/report.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">&nbsp;&nbsp;Reports</span>
                  </Link>
                </li>
              )}
              {/* {hasAccess(53) && (
                <li
                  className={`${callReports.includes(path) ? "mm-active" : ""}`}
                >
                  <Link to="/call-reports" className="ai-icon">
                    &nbsp;
                    <img
                      src="/sidebar/callReport.png"
                      alt="sidebar"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <span className="nav-text">&nbsp;&nbsp;Call Reports</span>
                  </Link>
                </li>
              )} */}
            </>
          )}

          {/* <li className={`${compliance.includes(path) ? "mm-active" : ""}`}>
            <Link to="/compliance" className="ai-icon" >
            &nbsp;
              <i class="fa fa-exclamation-triangle" ></i>
              <span className="nav-text"> Compliance Status</span>
            </Link>
          </li> */}

          {/* <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-home"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul >
              <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard Light</Link></li>
              <li><Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark"> Dashboard Dark</Link></li>
              <li><Link className={`${path === "project" ? "mm-active" : ""}`} to="/project">Project</Link></li>
              <li><Link className={`${path === "contacts" ? "mm-active" : ""}`} to="/contacts"> Contacts</Link></li>
              <li><Link className={`${path === "kanban" ? "mm-active" : ""}`} to="/kanban">Kanban</Link></li>
              <li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li>
              <li><Link className={`${path === "calendar" ? "mm-active" : ""}`} to="/calendar">Calendar</Link></li>
              <li><Link className={`${path === "messages" ? "mm-active" : ""}`} to="/messages"> Messages</Link></li>
            </ul>
          </li>

          <li className={`${app.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-info-circle"></i>
              <span className="nav-text">Apps</span>
            </Link>
            <ul >
              <li><Link className={`${path === "app-profile" ? "mm-active" : ""}`} to="/app-profile">Profile</Link></li>
              <li><Link className={`${path === "post-details" ? "mm-active" : ""}`} to="/post-details">Post Details</Link></li>
              <li className={`${email.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Email</Link>
                <ul className={`${email.includes(path) ? "mm-show" : ""}`}>
                  <li><Link className={`${path === "email-compose" ? "mm-active" : ""}`} to="/email-compose">Compose</Link></li>
                  <li><Link className={`${path === "email-inbox" ? "mm-active" : ""}`} to="/email-inbox">Inbox</Link></li>
                  <li><Link className={`${path === "email-read" ? "mm-active" : ""}`} to="/email-read">Read</Link></li>
                </ul>
              </li>
              <li><Link className={`${path === "app-calender" ? "mm-active" : ""}`} to="/app-calender">Calendar</Link></li>
              <li className={`${shop.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Shop</Link>
                <ul className={`${shop.includes(path) ? "mm-show" : ""}`}>
                  <li><Link className={`${path === "ecom-product-grid" ? "mm-active" : ""}`} to="/ecom-product-grid">Product Grid</Link></li>
                  <li><Link className={`${path === "ecom-product-list" ? "mm-active" : ""}`} to="/ecom-product-list">Product List</Link></li>
                  <li><Link className={`${path === "ecom-product-detail" ? "mm-active" : ""}`} to="/ecom-product-detail">Product Details</Link></li>
                  <li><Link className={`${path === "ecom-product-order" ? "mm-active" : ""}`} to="/ecom-product-order">Order</Link></li>
                  <li><Link className={`${path === "ecom-checkout" ? "mm-active" : ""}`} to="/ecom-checkout">Checkout</Link></li>
                  <li><Link className={`${path === "ecom-invoice" ? "mm-active" : ""}`} to="/ecom-invoice">Invoice</Link></li>
                  <li><Link className={`${path === "ecom-customers" ? "mm-active" : ""}`} to="/ecom-customers">Customers</Link></li>
                </ul>
              </li>
            </ul>
          </li>
          <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-chart-line"></i>
              <span className="nav-text">Charts</span>
            </Link>
            <ul >
              <li><Link className={`${path === "chart-rechart" ? "mm-active" : ""}`} to="/chart-rechart">RechartJs</Link></li>
              <li><Link className={`${path === "chart-chartjs" ? "mm-active" : ""}`} to="/chart-chartjs">Chartjs</Link></li>
              <li><Link className={`${path === "chart-chartist" ? "mm-active" : ""}`} to="/chart-chartist">Chartist</Link></li>
              <li><Link className={`${path === "chart-sparkline" ? "mm-active" : ""}`} to="/chart-sparkline">Sparkline</Link></li>
              <li><Link className={`${path === "chart-apexchart" ? "mm-active" : ""}`} to="/chart-apexchart" >Apexchart</Link></li>
            </ul>
          </li>
          <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fab fa-bootstrap"></i>
              <span className="nav-text">Bootstrap</span>
            </Link>
            <ul >
              <li><Link className={`${path === "ui-accordion" ? "mm-active" : ""}`} to="/ui-accordion">Accordion</Link></li>
              <li><Link className={`${path === "ui-alert" ? "mm-active" : ""}`} to="/ui-alert"> Alert</Link></li>
              <li><Link className={`${path === "ui-badge" ? "mm-active" : ""}`} to="/ui-badge">Badge</Link></li>
              <li><Link className={`${path === "ui-button" ? "mm-active" : ""}`} to="/ui-button">Button</Link></li>
              <li><Link className={`${path === "ui-modal" ? "mm-active" : ""}`} to="/ui-modal">Modal</Link></li>
              <li><Link className={`${path === "ui-button-group" ? "mm-active" : ""}`} to="/ui-button-group">Button Group</Link></li>
              <li><Link className={`${path === "ui-list-group" ? "mm-active" : ""}`} to="/ui-list-group" >List Group</Link></li>
              <li><Link className={`${path === "ui-card" ? "mm-active" : ""}`} to="/ui-card">Cards</Link></li>
              <li><Link className={`${path === "ui-carousel" ? "mm-active" : ""}`} to="/ui-carousel">Carousel</Link></li>
              <li><Link className={`${path === "ui-dropdown" ? "mm-active" : ""}`} to="/ui-dropdown">Dropdown</Link></li>
              <li><Link className={`${path === "ui-popover" ? "mm-active" : ""}`} to="/ui-popover">Popover</Link></li>
              <li><Link className={`${path === "ui-progressbar" ? "mm-active" : ""}`} to="/ui-progressbar">Progressbar</Link></li>
              <li><Link className={`${path === "ui-tab" ? "mm-active" : ""}`} to="/ui-tab">Tab</Link></li>
              <li><Link className={`${path === "ui-typography" ? "mm-active" : ""}`} to="/ui-typography">Typography</Link></li>
              <li><Link className={`${path === "ui-pagination" ? "mm-active" : ""}`} to="/ui-pagination">Pagination</Link></li>
              <li><Link className={`${path === "ui-grid" ? "mm-active" : ""}`} to="/ui-grid">Grid</Link></li>
            </ul>
          </li>
          <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-heart"></i><span className="nav-text">Plugins</span>
            </Link>
            <ul >
              <li><Link className={`${path === "uc-select2" ? "mm-active" : ""}`} to="/uc-select2">Select 2</Link></li>
              <li><Link className={`${path === "uc-nestable" ? "mm-active" : ""}`} to="/uc-nestable">Nestedable</Link></li>
              <li><Link className={`${path === "uc-noui-slider" ? "mm-active" : ""}`} to="/uc-noui-slider">Noui Slider</Link></li>
              <li><Link className={`${path === "uc-sweetalert" ? "mm-active" : ""}`} to="/uc-sweetalert">Sweet Alert</Link></li>
              <li><Link className={`${path === "uc-toastr" ? "mm-active" : ""}`} to="/uc-toastr">Toastr</Link></li>
              <li><Link className={`${path === "map-jqvmap" ? "mm-active" : ""}`} to="/map-jqvmap">Jqv Map</Link></li>
              <li><Link className={`${path === "uc-lightgallery" ? "mm-active" : ""}`} to="/uc-lightgallery">Light Gallery</Link></li>
            </ul>
          </li>
          <li className={`${redux.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-087-stop"></i><span className="nav-text">Redux</span>
            </Link>
            <ul>
              <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/todo">Todo</Link></li>
              <li><Link className={`${path === "redux-form" ? "mm-active" : ""}`} to="/redux-form">Redux Form</Link></li>
              <li><Link className={`${path === "redux-wizard" ? "mm-active" : ""}`} to="/redux-wizard">Redux Wizard</Link></li>
            </ul>
          </li>
          <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
            <Link to="widget-basic" className="ai-icon" >
              <i className="fas fa-user-check"></i>
              <span className="nav-text">Widget</span>
            </Link>
          </li>
          <li className={`${forms.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-file-alt"></i>
              <span className="nav-text forms">Forms</span>
            </Link>
            <ul >
              <li><Link className={`${path === "form-element" ? "mm-active" : ""}`} to="/form-element">Form Elements</Link></li>
              <li><Link className={`${path === "form-wizard" ? "mm-active" : ""}`} to="/form-wizard"> Wizard</Link></li>
              <li>
                <Link className={`${path === "form-editor-summernote" ? "mm-active" : ""}`} to="/form-editor-summernote">
                  Summernote
                </Link>
              </li>
              <li><Link className={`${path === "form-pickers" ? "mm-active" : ""}`} to="/form-pickers">Pickers</Link></li>
              <li>
                <Link className={`${path === "form-validation-jquery" ? "mm-active" : ""}`} to="/form-validation-jquery">
                  Form Validate
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${table.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" ><i className="fas fa-table"></i><span className="nav-text">Table</span></Link>
            <ul>
              <li>
                <Link className={`${path === "table-filtering" ? "mm-active" : ""}`} to="/table-filtering">
                  Table Filtering
                </Link>
              </li>
              <li>
                <Link className={`${path === "table-sorting" ? "mm-active" : ""}`} to="/table-sorting">
                  Table Sorting
                </Link>
              </li>
              <li>
                <Link className={`${path === "table-bootstrap-basic" ? "mm-active" : ""}`} to="/table-bootstrap-basic">
                  Bootstrap
                </Link>
              </li>
              <li>
                <Link className={`${path === "table-datatable-basic" ? "mm-active" : ""}`} to="/table-datatable-basic">
                  Datatable
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-clone"></i>
              <span className="nav-text">Pages</span>
            </Link>
            <ul >
              <li className={`${error.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="#" >Error</Link>
                <ul>
                  <li><Link className={`${path === "page-error-400" ? "mm-active" : ""}`} to="/page-error-400">Error 400</Link></li>
                  <li><Link className={`${path === "page-error-403" ? "mm-active" : ""}`} to="/page-error-403">Error 403</Link></li>
                  <li><Link className={`${path === "page-error-404" ? "mm-active" : ""}`} to="/page-error-404">Error 404</Link></li>
                  <li><Link className={`${path === "page-error-500" ? "mm-active" : ""}`} to="/page-error-500">Error 500</Link></li>
                  <li><Link className={`${path === "page-error-503" ? "mm-active" : ""}`} to="/page-error-503">Error 503</Link></li>
                </ul>
              </li>
              <li><Link className={`${path === "page-lock-screen" ? "mm-active" : ""}`} to="/page-lock-screen">Lock Screen</Link></li>
            </ul>
          </li> */}
        </MM>
        {/*<div className="side-bar-profile">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="side-bar-profile-img">
              <img src={user} alt="" />
            </div>
            <div className="profile-info1">
              <h4 className="fs-18 font-w500">Levi Siregar</h4>
              <span>leviregar@mail.com</span>
            </div>
            <div className="profile-button">
              <i className="fas fa-caret-down scale5 text-light"></i>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-2 progress-info">
            <span className="fs-12"><i className="fas fa-star text-orange me-2"></i>Task Progress</span>
            <span className="fs-12">20/45</span>
          </div>
          <div className="progress default-progress">
            <div className="progress-bar bg-gradientf progress-animated" style={{ width: "45%", height: "10px" }} role="progressbar">
              <span className="sr-only">45% Complete</span>
            </div>
          </div>
        </div>
         <div className="copyright">
          <p><strong>Fillow Saas Admin</strong> © 2021 All Rights Reserved</p>
          <p className="fs-12">Made with <span className="heart"></span> by DexignLabs</p>
        </div> */}
      </PerfectScrollbar>

      <div
        className="copyright"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        {/* <button onClick={()=>setDemoTheme(1,'ltr')}>Change Theme</button>
        <button onClick={()=>setDemoTheme(2,'ltr')}>Change Theme</button>
        <button onClick={()=>setDemoTheme(3,'ltr')}>Change Theme</button>
        <button onClick={()=>setDemoTheme(4,'ltr')}>Change Theme</button>
        <button onClick={()=>setDemoTheme(5,'ltr')}>Change Theme</button>
        <button onClick={()=>setDemoTheme(6,'ltr')}>Change Theme</button> */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            overflowWrap: "anywhere",
          }}
        >
          {/* <img src={ragava_fin_logo} alt="" style={{ height: "130px", width: "130px" }} /> */}
          {getTokenInLocalStorage()?.autoFinanceOrganization
            .organizationImage && (
            <img
              src={
                getTokenInLocalStorage()?.autoFinanceOrganization
                  .organizationImage
              }
              alt=""
              style={{ height: 80, width: 80, borderRadius: "50%" }}
            />
          )}
          {getTokenInLocalStorage()?.autoFinanceOrganization
            .organizationName && (
            <h6
              style={{
                color: "black",
                fontSize: 14,
                alignSelf: "center",
                marginLeft: 5,
              }}
            >
              {
                getTokenInLocalStorage()?.autoFinanceOrganization
                  .organizationName
              }
            </h6>
          )}
        </div>
        {/* <div>
          {userData?.autoFinanceUser?.roleName !== "CP_USER" && (
            <div
              style={{
                float: "right",
                position: "absolute",
                zIndex: 1,

                marginLeft: window.innerWidth === 2049 ? "115px" : "90px",

                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
            >
              <button
                className=""
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  setOzontelModal(true);
                  console.log("kk", window.innerWidth);
                }}
                style={{
                  borderColor: "green",
                  borderWidth: "1px",
                  marginBottom: "10px",
                  borderRadius: "20px",
                  outline: "none",
                  boxShadow: "1px 1px 2px #008000",
                  backgroundColor: isHovered ? "green" : "white",
                  padding: "10px",
                  // height: "50px",
                  // width: "100px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: isHovered ? "white" : "black",
                      fontWeight: "600",
                    }}
                  >
                    Login
                  </span>
                  <i
                    className="fa fa-phone fa-1x fa-rotate-90"
                    style={{
                      color: isHovered ? "white" : "green",
                      marginLeft: "5px",
                    }}
                  />
                </div>
              </button>
            </div>
          )}
        </div> */}
        {/* <Button
                  variant="primary btn-rounded"
                  className="me-2"
                  onClick={() => {
                   AnswerCall(piopiy, dispatch)
                  }}
                >
                  Answer
                </Button> */}
        {hasAccess(42) &&
          userData?.autoFinanceUser?.teleCmiUserId !== null &&
          userData?.autoFinanceUser?.teleCmiPassword !== null &&
          userData?.autoFinanceUser?.teleCmiUserId !== "" &&
          userData?.autoFinanceUser?.teleCmiPassword !== "" &&
          isModalMinimised === true && (
            <div>
              {userData?.autoFinanceUser?.roleName !== "CP_USER" && (
                <div
                  style={{
                    float: "right",
                    position: "absolute",
                    zIndex: 1,

                    marginLeft: window.innerWidth === 2049 ? "145px" : "120px",

                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                >
                  <button
                    className=""
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      dispatch(setCallAudioModal(true));
                      dispatch(setIsModalMinimised(false));
                      handleMouseLeave();
                      // console.log("kk", window.innerWidth);
                    }}
                    style={{
                      borderColor: "green",
                      borderWidth: "1px",
                      marginBottom: "10px",
                      borderRadius: "20px",
                      outline: "none",
                      boxShadow: "1px 1px 2px #008000",
                      backgroundColor: isHovered ? "green" : "white",
                      padding: "10px",
                      // height: "50px",
                      // width: "100px",
                    }}
                  >
                    <div
                      style={
                        // isOnGoingCall === false
                        //   ? { display: "flex", flexDirection: "row" }
                        //   :
                        {
                          display: "flex",
                          flexDirection: "row",
                          width: "135px",
                        }
                      }
                    >
                      <span
                        style={{
                          color: isHovered ? "white" : "black",
                          fontWeight: "600",
                        }}
                      >
                        {/* {isOnGoingCall === false
                          ? "Call"
                          :  */}
                        {isModalMinimised === true && "Call is in Progress"}
                      </span>
                      <i
                        className="fa fa-phone fa-1x fa-rotate-90"
                        style={{
                          color: isHovered ? "white" : "green",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                  </button>
                </div>
              )}
            </div>
          )}

        <Modal className="fade" show={callAudioModal} size="md">
          <Modal.Header
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Modal.Title>{modalHeaderText} Call</Modal.Title>
            <div style={{ display: "flex" }}>
              {/* <div
                style={{
                  float: "right",
                  width: "120px",
                  height: "38px",
                  marginRight: "20px",
                  // marginBottom: "20px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    setStatusOptions(value.value);
                    setSelectedAgentStatus({
                      label: value.label,
                      value: value.value,
                    });
                    localStorage.removeItem("selectedAgentStatus");
                    localStorage.setItem(
                      "selectedAgentStatus",
                      JSON.stringify({
                        label: value.label,
                        value: value.value,
                      })
                    );
                  }}
                  // defaultValue={statusOptions[0]}
                  value={selectedAgentStatus}
                  options={statusOptions}
                />
              </div> */}
              {isCallRejected === true && (
                <Button
                  variant=""
                  className="btn-close"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    // CloseModal();
                    dispatch(setCallAudioModal(false));
                    setCallMobileNo(null);
                    // setIsOnGoingCall(false);
                  }}
                ></Button>
              )}
              {/* {isCallActive === true && callType === "outgoing" && (
                <Button
                  variant="primary btn-rounded"
                  className="me-2"
                  onClick={() => {
                    // piopiy.transfer('4441')
                    CallTransfer(piopiy, "4441");
                    // callingEventsInstance.transferCall();
                    // setCallTransferModal(true);
                  }}
                >
                  Transfer
                </Button>
              )} */}
              {/* {isOnGoingCall === true && ( */}

              {/* )} */}
              {isCallRejected === false && (
                <Button
                  variant=""
                  // className="btn-minus"
                  onClick={() => {
                    // MinimizeModal();
                    dispatch(setCallAudioModal(false));
                    dispatch(setIsModalMinimised(true));
                  }}
                >
                  <div
                    style={{
                      // marginBottom: "5px",
                      borderRadius: "50%",
                      border: "2px solid #25A6E7",
                      boxShadow: "1px 1px 2px #25A6E7",
                      backgroundColor: "#25A6E7",
                      padding: "10px",
                      height: "10px",
                      width: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i className="fa fa-minus" style={{ color: "white" }}></i>
                  </div>
                </Button>
              )}
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ justifyContent: "center" }}>
              {/* {isMobileField === true && (
                <label style={{ fontWeight: "bold", textAlign: "center" }}>
                  Mobile No
                </label>
              )}
              {isMobileField === true && (
                <input
                  style={{
                    width: "300px",
                    height: "35px",
                    borderRadius: "25px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    paddingLeft: "10px",
                    // marginLeft: "10px",
                  }}
                  type="number"
                  minLength={0}
                  maxLength={9999999999}
                  placeholder="Enter Mobile No"
                  onChange={(e) => {
                    setCallMobileNo(e.target.value);
                  }}
                />
              )}

              {isMobileField === true &&
                (callMobileNo?.length < 10 || callMobileNo?.length > 10) && (
                  <span
                    style={{ marginTop: "5px", textAlignLast: "center" }}
                    className="error"
                  >
                    Mobile No is Invalid
                  </span>
                )}
              {isMobileField === true && (
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{ float: "right" }}
                    className="me-2"
                    variant="danger light btn-rounded"
                    onClick={() => {
                      // setCallAudioModal(false);
                      closeModal();
                      setCallMobileNo(null);
                      // setIsOnGoingCall(false);
                      // setIsMobileField(true);
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    style={{ float: "right" }}
                    className="me-2"
                    variant="success btn-rounded"
                    type="submit"
                    onClick={() => {
                      if (callMobileNo !== null) {
                        if (callMobileNo?.length === 10) {
                          // makeCall(callMobileNo);
                          // setCallAudioModal(false);
                          // piopiy.call("91" + callMobileNo);
                          startCall(callMobileNo, true);
                          // setIsMobileField(false);
                          // setIsCallAnswered(true);
                          // setIsCallRejected(false);
                        } else if (
                          callMobileNo?.length < 10 ||
                          callMobileNo?.length > 10
                        ) {
                          return swal("Please Enter Valid Mobile No", {
                            icon: "error",
                            dangerMode: true,
                          });
                        }
                      } else if (callMobileNo === null) {
                        return swal("Please Enter Mobile No", {
                          icon: "error",
                          dangerMode: true,
                        });
                      }
                    }}
                  >
                    <i className="fa fa-phone fa-1x fa-rotate-90" /> Call
                  </Button>
                </div>
              )} */}
              {isIncomingLoanNoDropdown === true && callType === "incoming" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <label style={{ fontWeight: "bold", marginTop: "5px" }}>
                    Loan Number
                  </label>
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "38px",
                      width: "300px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      options={incomingLoanNumbers}
                      onChange={(value) => {
                        localStorage.setItem("allocationId", value.value);
                        dispatch(setAllocationId(value.value));
                        dispatch(setAllocationDetails(value.data));
                        dispatch(
                          setSelectedLoanDropDownValue({
                            label: value.label,
                            value: value.value,
                          })
                        );
                        dispatch(setSearchedLoanNoValue(""));
                      }}
                      placeholder="Select Loan Number..."
                      value={selectedLoanDropDownValue}
                    />
                  </div>
                </div>
              )}

              {isIncomingLoanNoDropdown === true && callType === "incoming" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "nowrap",
                    width: "50%",
                  }}
                >
                  <hr
                    className="w-75"
                    style={{
                      background: "rgb(207, 214, 221)",
                      border: "0.5px solid rgb(23, 25, 26)",
                    }}
                  />
                  <p
                    className="semi-bold"
                    style={{
                      fontSize: 12,
                      color: "rgb(39, 46, 53)",
                      padding: "0px 10px",
                      whiteSpace: "nowrap",
                      marginTop: "12px",
                    }}
                  >
                    OR
                  </p>
                  <hr
                    className="w-75"
                    style={{
                      background: "rgb(207, 214, 221)",
                      border: "0.5px solid rgb(23, 25, 26)",
                    }}
                  />
                </div>
              )}

              {isOnGoingCall === true &&
                isRinging === false &&
                callType === "incoming" && (
                  <div
                    style={{
                      width: "75%",
                      marginBottom: "15px",
                      height: "40px",
                    }}
                  >
                    <MuiAutoComplete />
                  </div>
                )}

              {type === "Sales" &&
                isOnGoingCall === true &&
                isRinging === false &&
                isAddSaleLeadButton === true &&
                callType === "incoming" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "nowrap",
                      width: "50%",
                    }}
                  >
                    <hr
                      className="w-75"
                      style={{
                        background: "rgb(207, 214, 221)",
                        border: "0.5px solid rgb(23, 25, 26)",
                      }}
                    />
                    <p
                      className="semi-bold"
                      style={{
                        fontSize: 12,
                        color: "rgb(39, 46, 53)",
                        padding: "0px 10px",
                        whiteSpace: "nowrap",
                        marginTop: "12px",
                      }}
                    >
                      OR
                    </p>
                    <hr
                      className="w-75"
                      style={{
                        background: "rgb(207, 214, 221)",
                        border: "0.5px solid rgb(23, 25, 26)",
                      }}
                    />
                  </div>
                )}

              {type === "Sales" &&
                isAddSaleLeadButton === true &&
                callType === "incoming" && (
                  <div style={{ textAlignLast: "center", marginBottom: "5px" }}>
                    <Button
                      variant="primary btn-rounded"
                      className="me-2"
                      onClick={() => {
                        getSourceChannels();
                        getStatesCitiesPincode("", "", "");
                        setAddSaleLeadModal(true);
                      }}
                    >
                      Add Sale lead
                    </Button>
                  </div>
                )}
              {type === "Sales" &&
                isOnGoingCall === true &&
                isRinging === false &&
                allocationDetails?.id && (
                  <div
                    style={{
                      textAlignLast: "center",
                      marginBottom: "5px",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="primary btn-rounded"
                      className="me-2"
                      onClick={() => {
                        setAddAdditionalDetailsModal(true);
                      }}
                    >
                      Add Additional Details
                    </Button>
                  </div>
                )}

              <div style={{ textAlignLast: "center", marginBottom: "5px" }}>
                {allocationDetails?.customerName && (
                  <span>
                    Customer Name:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {allocationDetails?.customerName
                        ? allocationDetails?.customerName
                        : "-"}
                      &nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                )}
                <br></br>
                {allocationDetails?.loanNumber && (
                  <span>
                    Loan Number:{" "}
                    <span
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        getAllocationDetailsBasedOnId(allocationDetails?.id);
                      }}
                    >
                      {allocationDetails?.loanNumber
                        ? allocationDetails?.loanNumber
                        : "-"}
                    </span>
                  </span>
                )}
                {type === "Sales" && allocationDetails?.phoneNumber && (
                  <span>
                    Prospect Number:{" "}
                    <span
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        dispatch(setCallAudioModal(false));
                        dispatch(setIsModalMinimised(true));
                        setProspectDetailsModal(true);
                      }}
                    >
                      {allocationDetails?.phoneNumber
                        ? hasAccess(75) || hasAccessFromTeamPrivilege(75)
                          ? getMaskedMobileNo(allocationDetails?.phoneNumber)
                          : allocationDetails?.phoneNumber
                        : "-"}
                    </span>
                  </span>
                )}
              </div>

              {isIncomingCall === true && callType === "incoming" && (
                <span
                  style={{
                    marginBottom: "10px",
                    textAlignLast: "center",
                    fontWeight: "bold",
                  }}
                >
                  {webRtcMobile} {" Calling..."}
                </span>
              )}
              {isIncomingCall === true && callType === "incoming" && (
                <img
                  src="/sidebar/incoming.gif"
                  alt=""
                  style={{
                    width: "110px",
                    height: "85px",
                    // marginLeft: "60px",
                    marginTop: "20px",
                  }}
                />
              )}
              {isIncomingCall === true && callType === "incoming" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "40px",
                    }}
                  >
                    <div
                      onClick={() => {
                        // callingEventsInstance.endCall();
                        // piopiy.terminate();
                        EndCall(piopiy, dispatch);
                        dispatch(setIsCallActive(false));
                        dispatch(setIsIncomingCall(false));
                        dispatch(setIsIncomingLoanNoDropdown(false));
                        // setIsOnGoingCall(false);
                        // setCallMobileNo(null);
                        // setIsHold(false);
                        // setIsMute(false);
                        // setIsMobileField(true);
                        // setIsCallRejected(true);
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid red",
                        boxShadow: "1px 1px 2px #008000",
                        backgroundColor: "red",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <i
                        className="fa fa-phone fa-2x fa-rotate-by"
                        style={{
                          color: "white",
                          rotate: "225deg",
                        }}
                      />
                    </div>
                    <span style={{ fontWeight: "bold" }}>Decline</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "40px",
                    }}
                  >
                    <div
                      onClick={() => {
                        AnswerCall(piopiy, dispatch);
                        // callingEventsInstance.endCall();
                        // piopiy.terminate();
                        // setIsOnGoingCall(false);
                        // setCallMobileNo(null);
                        // setIsHold(false);
                        // setIsMute(false);
                        // setIsMobileField(true);
                        // setIsCallRejected(true);
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid #0beb13",
                        boxShadow: "1px 1px 2px #0beb13",
                        backgroundColor: "#0beb13",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <i
                        className="fa fa-phone fa-2x fa-rotate-90"
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                    <span style={{ fontWeight: "bold" }}>Answer</span>
                  </div>
                </div>
              )}

              {isCallAnswered === true && (
                <span style={{ textAlignLast: "center" }}>
                  {callStatusText} to{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {type === "Sales"
                      ? hasAccess(75) || hasAccessFromTeamPrivilege(75)
                        ? getMaskedMobileNo(webRtcMobile)
                        : webRtcMobile
                      : webRtcMobile}
                  </span>
                  ...{" "}
                </span>
              )}

              {isCallAnswered === true && (
                <img
                  src="/sidebar/phone.gif"
                  alt=""
                  style={{
                    width: "100px",
                    height: "75px",
                    // marginLeft: "60px",
                    marginTop: "20px",
                  }}
                />
              )}

              {isCallAnswered === true && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "40px",
                  }}
                >
                  <div
                    onClick={() => {
                      // callingEventsInstance.endCall();
                      EndCall(piopiy, dispatch);
                      // piopiy.terminate();
                      // setIsOnGoingCall(false);
                      setCallMobileNo(null);
                      // setIsHold(false);
                      // setIsMute(false);
                      // setIsMobileField(true);
                      // setIsCallRejected(true);
                    }}
                    style={{
                      marginBottom: "5px",
                      borderRadius: "50%",
                      border: "2px solid red",
                      boxShadow: "1px 1px 2px #008000",
                      backgroundColor: "red",
                      padding: "10px",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className="fa fa-phone fa-2x fa-rotate-by"
                      style={{
                        color: "white",
                        rotate: "225deg",
                      }}
                    />
                  </div>
                  <span style={{ fontWeight: "bold" }}>End</span>
                </div>
              )}
              {isOnGoingCall === true && isRinging === false && (
                <span style={{ marginBottom: "10px", textAlignLast: "center" }}>
                  Call with{" "}
                  <span style={{ fontWeight: "bold" }}>{webRtcMobile}</span> is
                  in progress...
                </span>
              )}
              {isOnGoingCall === true && isRinging === false && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {isHold === true && (
                      <div
                        onClick={() => {
                          // callingEventsInstance.unHoldCall();
                          UnHoldCall(piopiy, dispatch);
                          // piopiy.unHold();
                          // setIsHold(false);
                          // setIsCallRejected(false);
                          // console.log("hold---false");
                        }}
                        style={{
                          marginBottom: "5px",
                          borderRadius: "50%",
                          border: "2px solid grey",
                          boxShadow: "1px 1px 2px grey",
                          backgroundColor: "grey",
                          padding: "10px",
                          height: "50px",
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa fa-pause fa-2x "
                          style={{
                            color: "white",
                          }}
                        />
                      </div>
                    )}
                    {isHold === false && (
                      <div
                        onClick={() => {
                          // callingEventsInstance.holdCall();
                          HoldCall(piopiy, dispatch);
                          // piopiy.hold();
                          // setIsHold(true);
                          // setIsCallRejected(false);
                          // console.log("hold---true");
                        }}
                        style={{
                          marginBottom: "5px",
                          borderRadius: "50%",
                          border: "2px solid black",
                          boxShadow: "1px 1px 2px black",
                          backgroundColor: "white",
                          padding: "10px",
                          height: "50px",
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa fa-pause fa-2x "
                          style={{
                            color: "black",
                          }}
                        />
                      </div>
                    )}
                    <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                      Hold
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      onClick={() => {
                        // callingEventsInstance.endCall();
                        EndCall(piopiy, dispatch);
                        // piopiy.terminate();
                        // setIsOnGoingCall(false);
                        setCallMobileNo(null);
                        // setIsHold(false);
                        // setIsMute(false);
                        // setIsMobileField(true);
                        // setIsCallRejected(true);
                      }}
                      style={{
                        marginBottom: "5px",
                        borderRadius: "50%",
                        border: "2px solid red",
                        boxShadow: "1px 1px 2px #008000",
                        backgroundColor: "red",
                        padding: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <i
                        className="fa fa-phone fa-2x fa-rotate-by"
                        style={{
                          color: "white",
                          rotate: "225deg",
                        }}
                      />
                    </div>
                    <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                      End
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {isMute === true && (
                      <div
                        onClick={() => {
                          // callingEventsInstance.unmuteCall();
                          UnmuteCall(piopiy, dispatch);
                          // piopiy.unMute();
                          // setIsMute(false);
                          // setIsCallRejected(false);
                        }}
                        style={{
                          marginBottom: "5px",
                          borderRadius: "50%",
                          border: "2px solid grey",
                          boxShadow: "1px 1px 2px grey",
                          backgroundColor: "grey",
                          padding: "10px",
                          height: "50px",
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa fa-microphone-slash fa-2x"
                          style={{
                            color: "white",
                          }}
                        />
                      </div>
                    )}
                    {isMute === false && (
                      <div
                        onClick={() => {
                          // callingEventsInstance.muteCall();
                          MuteCall(piopiy, dispatch);
                          // piopiy.mute();
                          // setIsMute(true);
                          // setIsCallRejected(false);
                        }}
                        style={{
                          marginBottom: "5px",
                          borderRadius: "50%",
                          border: "2px solid black",
                          boxShadow: "1px 1px 2px black",
                          backgroundColor: "white",
                          padding: "10px",
                          height: "50px",
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa fa-microphone-slash fa-2x"
                          style={{
                            color: "black",
                          }}
                        />
                      </div>
                    )}
                    <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                      Mute
                    </span>
                  </div>
                </div>
              )}
            </Row>
          </Modal.Body>
        </Modal>

        {/* <Modal className="fade" size="md" show={true}>
          <Modal.Header>
            <Modal.Title>Incoming Call</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setOzontelModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            <Row style={{ justifyContent: "center" }}>
              <div style={{ textAlignLast: "center", marginBottom: "5px" }}>
                <span style={{ textAlignLast: "center" }}>
                  Call from
                  <span style={{ fontWeight: "bold" }}>
                    {webRtcMobile}
                  </span>...{" "}
                </span>
              </div>
              <img
                src="/sidebar/incomingCall.gif"
                alt=""
                style={{
                  width: "100px",
                  height: "75px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginTop: "30px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    // onClick={() => {
                    //   endCall();
                    //   // piopiy.terminate();
                    //   // setIsOnGoingCall(false);
                    //   setCallMobileNo(null);
                    //   // setIsHold(false);
                    //   // setIsMute(false);
                    //   // setIsMobileField(true);
                    //   // setIsCallRejected(true);
                    // }}
                    style={{
                      marginBottom: "5px",
                      borderRadius: "50%",
                      border: "2px solid #09bd3c",
                      boxShadow: "1px 1px 2px #008000",
                      backgroundColor: "#09bd3c",
                      padding: "10px",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fas fa-phone-alt"
                      style={{
                        color: "white",
                        fontSize: "large",
                      }}
                    />
                  </div>
                  <span style={{ marginLeft: "3px", fontWeight: "bold" }}>
                    Accept
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    // onClick={() => {
                    //   endCall();
                    //   // piopiy.terminate();
                    //   // setIsOnGoingCall(false);
                    //   setCallMobileNo(null);
                    //   // setIsHold(false);
                    //   // setIsMute(false);
                    //   // setIsMobileField(true);
                    //   // setIsCallRejected(true);
                    // }}
                    style={{
                      marginBottom: "5px",
                      borderRadius: "50%",
                      border: "2px solid red",
                      boxShadow: "1px 1px 2px #008000",
                      backgroundColor: "red",
                      padding: "10px",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-phone fa-2x fa-rotate-270"
                      style={{
                        color: "white",
                      }}
                    />
                  </div>
                  <span style={{ marginLeft: "3px", fontWeight: "bold" }}>
                    Decline
                  </span>
                </div>
              </div>
            </Row>
          </Modal.Body>
        </Modal> */}

        <Modal className="fade" size="lg" show={callTransferModal}>
          <Modal.Header>
            <Modal.Title>Call Transfer</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              // onClick={() => setCallTransferModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            {/* <LoadingOverlay
              active={isTransferLoading}
              // spinner
              text={
                <p
                  style={{
                    color: "black",
                  }}
                >
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#FFFF",
                        color: "black",
                        borderColor: "white",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      variant="primary"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        variant="primary"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading...
                    </Button>
                  </div>
                </p>
              }
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(192,192,192,0.4)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "80px",
                  "& svg circle": {
                    stroke: "rgb(136, 108, 192)",
                  },
                }),
              }}
            > */}
            <div>
              <Row>
                <Col>
                  <div
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>
                      <h6>Language</h6>
                    </label>
                    <div
                      style={{
                        float: "right",
                        // width: "150px",
                        height: "38px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => {
                          setSelectedLanguage(value.value);
                          getUsersForLanguages(value.value);
                        }}
                        // value={executivesList[executiveFilterIndex]}
                        options={languages}
                      />
                    </div>
                  </div>
                </Col>

                <Col>
                  <div
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>
                      <h6>Employee</h6>
                    </label>
                    <div
                      style={{
                        float: "right",
                        // width: "150px",
                        height: "38px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => {
                          setSelectedTelecallerId(value.value);
                        }}
                        options={languageCallersList}
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  {/* {isSubmit && ( */}
                  <button
                    type="submit"
                    className="btn btn-success"
                    style={{ marginTop: "35px", marginLeft: "15px" }}
                    onClick={() => {
                      if (selectedLanguage === "") {
                        return swal("Please Select Language", {
                          icon: "error",
                          dangerMode: true,
                        });
                      } else if (selectedTelecallerId === "") {
                        return swal("Please Select Employee", {
                          icon: "error",
                          dangerMode: true,
                        });
                      } else {
                        callTransfer(selectedTelecallerId);
                        // setCallTransferModal(false)
                        return swal("Call Transferred Successsfully", {
                          icon: "success",
                          dangerMode: false,
                        });
                      }
                    }}
                  >
                    Transfer
                  </button>
                  {/* )} */}
                </Col>
              </Row>
            </div>
            {/* </LoadingOverlay> */}
          </Modal.Body>
        </Modal>

        <Modal className="fade" show={callRemarksModal} size="lg">
          <Modal.Header>
            <Modal.Title>Add Remarks</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddRemarks
              allocationId={allocationId}
              callId={callId}
              close={HandleClose}
              dailingTime={dailingTime}
              ringingTime={ringingTime}
              talkTime={talkTime}
              holdTime={holdTime}
              dispoTime={dispoTime}
              handleMyAllocations={HandleMyAllocations}
              isProgressiveDialing={isProgressiveDialing}
              continueDialing={ContinueProgressiveDialing}
              closeMinimizeModal={CloseMinimizeModal}
              campaignName={campaignName}
              startCountdownTimer={StartCountdownTimer}
              type={type}
              makeAllocationIdNull={MakeAllocationIdNull}
              callStatus={callStatus}
            />
          </Modal.Body>
        </Modal>

        {/* <Modal className="fade" size="md" show={true}>
          <Modal.Header>
            <Modal.Title>Incoming Call</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setOzontelModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            <Row style={{ justifyContent: "center" }}>
              <div style={{ textAlignLast: "center", marginBottom: "5px" }}>
                <span style={{ textAlignLast: "center" }}>
                  Call from
                  <span style={{ fontWeight: "bold" }}>
                    {webRtcMobile}
                  </span>...{" "}
                </span>
              </div>
              <img
                src="/sidebar/incomingCall.gif"
                alt=""
                style={{
                  width: "100px",
                  height: "75px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginTop: "30px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    // onClick={() => {
                    //   endCall();
                    //   // piopiy.terminate();
                    //   // setIsOnGoingCall(false);
                    //   setCallMobileNo(null);
                    //   // setIsHold(false);
                    //   // setIsMute(false);
                    //   // setIsMobileField(true);
                    //   // setIsCallRejected(true);
                    // }}
                    style={{
                      marginBottom: "5px",
                      borderRadius: "50%",
                      border: "2px solid #09bd3c",
                      boxShadow: "1px 1px 2px #008000",
                      backgroundColor: "#09bd3c",
                      padding: "10px",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fas fa-phone-alt"
                      style={{
                        color: "white",
                        fontSize: "large",
                      }}
                    />
                  </div>
                  <span style={{ marginLeft: "3px", fontWeight: "bold" }}>
                    Accept
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    // onClick={() => {
                    //   endCall();
                    //   // piopiy.terminate();
                    //   // setIsOnGoingCall(false);
                    //   setCallMobileNo(null);
                    //   // setIsHold(false);
                    //   // setIsMute(false);
                    //   // setIsMobileField(true);
                    //   // setIsCallRejected(true);
                    // }}
                    style={{
                      marginBottom: "5px",
                      borderRadius: "50%",
                      border: "2px solid red",
                      boxShadow: "1px 1px 2px #008000",
                      backgroundColor: "red",
                      padding: "10px",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-phone fa-2x fa-rotate-270"
                      style={{
                        color: "white",
                      }}
                    />
                  </div>
                  <span style={{ marginLeft: "3px", fontWeight: "bold" }}>
                    Decline
                  </span>
                </div>
              </div>
            </Row>
          </Modal.Body>
        </Modal> */}

        <Modal className="fade" size="lg" show={callTransferModal}>
          <Modal.Header>
            <Modal.Title>Call Transfer</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              // onClick={() => setCallTransferModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            {/* <LoadingOverlay
              active={isTransferLoading}
              // spinner
              text={
                <p
                  style={{
                    color: "black",
                  }}
                >
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#FFFF",
                        color: "black",
                        borderColor: "white",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      variant="primary"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        variant="primary"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading...
                    </Button>
                  </div>
                </p>
              }
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(192,192,192,0.4)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "80px",
                  "& svg circle": {
                    stroke: "rgb(136, 108, 192)",
                  },
                }),
              }}
            > */}
            <div>
              <Row>
                <Col>
                  <div
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>
                      <h6>Language</h6>
                    </label>
                    <div
                      style={{
                        float: "right",
                        // width: "150px",
                        height: "38px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => {
                          setSelectedLanguage(value.value);
                          getUsersForLanguages(value.value);
                        }}
                        // value={executivesList[executiveFilterIndex]}
                        options={languages}
                      />
                    </div>
                  </div>
                </Col>

                <Col>
                  <div
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>
                      <h6>Employee</h6>
                    </label>
                    <div
                      style={{
                        float: "right",
                        // width: "150px",
                        height: "38px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => {
                          setSelectedTelecallerId(value.value);
                        }}
                        options={languageCallersList}
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  {/* {isSubmit && ( */}
                  <button
                    type="submit"
                    className="btn btn-success"
                    style={{ marginTop: "35px", marginLeft: "15px" }}
                    onClick={() => {
                      if (selectedLanguage === "") {
                        return swal("Please Select Language", {
                          icon: "error",
                          dangerMode: true,
                        });
                      } else if (selectedTelecallerId === "") {
                        return swal("Please Select Employee", {
                          icon: "error",
                          dangerMode: true,
                        });
                      } else {
                        callTransfer(selectedTelecallerId);
                        // setCallTransferModal(false)
                        return swal("Call Transferred Successsfully", {
                          icon: "success",
                          dangerMode: false,
                        });
                      }
                    }}
                  >
                    Transfer
                  </button>
                  {/* )} */}
                </Col>
              </Row>
            </div>
            {/* </LoadingOverlay> */}
          </Modal.Body>
        </Modal>

        {/* <Modal className="fade" show={callRemarksModal} size="lg">
          <Modal.Header>
            <Modal.Title>Add Remarks</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddRemarks
              allocationId={allocationId}
              callId={callId}
              close={HandleClose}
              dailingTime={dailingTime}
              ringingTime={ringingTime}
              talkTime={talkTime}
              holdTime={holdTime}
              dispoTime={dispoTime}
              handleMyAllocations={HandleMyAllocations}
              isProgressiveDialing={isProgressiveDialing}
              continueDialing={ContinueProgressiveDialing}
              closeMinimizeModal={CloseMinimizeModal}
              campaignName={campaignName}
              startCountdownTimer={StartCountdownTimer}
              type={type}
              makeAllocationIdNull={MakeAllocationIdNull}
            />
          </Modal.Body>
        </Modal> */}

        <div className="brand-title"></div>
        <Modal className="fade" size="xl" show={ozontelModal}>
          <Modal.Header>
            <Modal.Title>Login</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setOzontelModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            <div style={{ marginBottom: "5px" }}>
              <div>
                <iframe
                  src="https://in-ccaas.ozonetel.com/toolbar_widget/index.html?width=340#dashboard-agent"
                  title="ozontel"
                  width="1100"
                  height="450"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal className="fade" size="xl" show={loanDetailsModal}>
          <Modal.Header>
            <Modal.Title>Allocation Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setLoanDetailsModal(false);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body
            style={{
              paddingTop: "0px",
              maxHeight: "400px",
              overflowY: "auto",
            }}
          ></Modal.Body>
          <LoanNumberDetails
            allocationDetails={allocDetails}
            close={handleLoanNumberDetailsModal}
          />
        </Modal>
        <Modal className="fade" size="lg" show={prospectDetailsModal}>
          <Modal.Header>
            <Modal.Title>Prospect Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setProspectDetailsModal(false);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body
            style={{
              paddingTop: "0px",
              maxHeight: "400px",
              overflowY: "auto",
            }}
          ></Modal.Body>
          <ProspectDetails
            prospectDetails={allocationDetails}
            close={handleProspectDetailsModal}
          />
        </Modal>
        <Modal className="fade" size="md" show={addAdditionalDetailsModal}>
          <Modal.Header>
            <Modal.Title>Add Additional Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setAddAdditionalDetailsModal(false);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body
            style={{
              paddingTop: "0px",
              maxHeight: "400px",
              overflowY: "auto",
            }}
          ></Modal.Body>
          <div>
            <iframe
              style={{ width: "-webkit-fill-available", marginTop: "-15px" }}
              src={
                "https://webdemo.clucloud.com/?fe=sales&og=" +
                userData?.autoFinanceOrganization?.orgshortcode +
                "&t1=additionaldetails&t2=form1&id=" +
                allocationDetails?.id
              }
              title="salesJsonForm"
              // width="1100"
              height="450"
            />
          </div>
        </Modal>
        <Modal className="fade" size="md" show={addSaleLeadModal}>
          <Modal.Header>
            <Modal.Title>Add Sale Lead</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setAddSaleLeadModal(false);
                setSelectedCity(null);
                setSelectedPinCode(null);
                setSelectedState(null);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body
            style={{
              paddingTop: "0px",
            }}
          >
            <LoadingOverlay
              active={isAddLeadLoading}
              // spinner
              text={
                <p
                  style={{
                    color: "black",
                  }}
                >
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#FFFF",
                        color: "black",
                        borderColor: "white",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      variant="primary"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        variant="primary"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading...
                    </Button>
                  </div>
                </p>
              }
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(192,192,192,0.4)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "80px",
                  "& svg circle": {
                    stroke: "rgb(136, 108, 192)",
                  },
                }),
              }}
            >
              <div style={{ padding: "10px", maxHeight: "450px" }}>
                <Formik
                  initialValues={{
                    customerName: "",
                    mobileNo: webRtcMobile?.slice(2),
                    channel: "",
                    state: "",
                    city: "",
                    pinCode: "",
                    purposeOfcall: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleAddSaleLeadSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div>
                        <TextField
                          isAsterisk={true}
                          label="Customer Name"
                          name="customerName"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          maxlength="10"
                          label="Mobile No"
                          name="mobileNo"
                          type="text"
                        />
                        <Field name="channel">
                          {({ field, form }) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <label
                                className="control-label"
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  marginBottom: "10px",
                                }}
                              >
                                Sourcing Channel
                              </label>
                              <Select
                                placeholder="Select Sourcing Channel"
                                options={sourcingChannels}
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    "channel",
                                    selectedOption.value
                                  );
                                }}
                              />
                            </div>
                          )}
                        </Field>

                        {errors.channel && (
                          <span className="error">{errors.channel}</span>
                        )}
                        <TextField
                          isAsterisk={true}
                          label="Purpose Of Call"
                          name="purposeOfcall"
                          type="text"
                        />

                        <Field name="state">
                          {({ field, form }) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <label
                                className="control-label"
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  marginBottom: "10px",
                                }}
                              >
                                State
                              </label>
                              <Select
                                placeholder="Select State..."
                                options={states}
                                defaultValue={
                                  selectedState !== null
                                    ? {
                                        label: selectedState,
                                        value: selectedState,
                                      }
                                    : null
                                }
                                value={
                                  selectedState !== null
                                    ? {
                                        label: selectedState,
                                        value: selectedState,
                                      }
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  getStatesCitiesPincode(
                                    selectedOption.value,
                                    "state",
                                    ""
                                  );
                                  setSelectedCity(null);
                                  setSelectedPinCode(null);
                                  setSelectedState(selectedOption.value);
                                }}
                              />
                            </div>
                          )}
                        </Field>

                        {/* {errors.state && (
                          <span className="error">{errors.state}</span>
                        )} */}

                        {selectedState === "Others" && (
                          <TextField
                            isAsterisk={false}
                            label=""
                            name="state"
                            placeholder="Please Enter State..."
                            type="text"
                          />
                        )}

                        {selectedState !== "Others" && (
                          <Field name="city">
                            {({ field, form }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                  }}
                                >
                                  City
                                </label>
                                <Select
                                  placeholder="Select City..."
                                  options={cities}
                                  defaultValue={
                                    selectedCity !== null
                                      ? {
                                          label: selectedCity,
                                          value: selectedCity,
                                        }
                                      : null
                                  }
                                  value={
                                    selectedCity !== null
                                      ? {
                                          label: selectedCity,
                                          value: selectedCity,
                                        }
                                      : null
                                  }
                                  onChange={(value) => {
                                    getStatesCitiesPincode(
                                      value.value,
                                      "city",
                                      selectedState
                                    );
                                    setSelectedPinCode(null);
                                    setSelectedCity(value.value);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        )}
                        {/* {errors.city && (
                          <span className="error">{errors.city}</span>
                        )} */}

                        {selectedState === "Others" && (
                          <TextField
                            isAsterisk={false}
                            label="City"
                            name="city"
                            type="text"
                          />
                        )}
                        {selectedState !== "Others" && (
                          <Field name="pinCode">
                            {({ field, form }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Pin Code
                                </label>
                                <Select
                                  placeholder="Select Pin Code"
                                  options={pinCodes}
                                  defaultValue={
                                    selectedPinCode !== null
                                      ? {
                                          label: selectedPinCode,
                                          value: selectedPinCode,
                                        }
                                      : null
                                  }
                                  value={
                                    selectedPinCode !== null
                                      ? {
                                          label: selectedPinCode,
                                          value: selectedPinCode,
                                        }
                                      : null
                                  }
                                  onChange={(value) => {
                                    setSelectedPinCode(value.value);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        )}

                        {/* {errors.pinCode && (
                          <span className="error">{errors.pinCode}</span>
                        )} */}

                        {selectedState === "Others" && (
                          <TextField
                            isAsterisk={false}
                            label="Pin Code"
                            name="pinCode"
                            type="text"
                          />
                        )}
                      </div>

                      <Button style={{ float: "right" }} type="submit">
                        Submit
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </LoadingOverlay>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default SideBar;
