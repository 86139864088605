// src/components/ValidatedTextField.js
import React, { useState } from "react";
import { TextField, FormHelperText } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles'

const MuiTextField = ({
  label,
  value,
  onChange,
  description,
  validators = [],
  errorMessages = [],
  ...props
}) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [touched, setTouched] = useState(false);

  const defaultColor = "#ff0000";
  const hoverColor = "#0000ff";
  const focusColor = "#00ff00";

  const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor,
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
  });

  const handleChange = (event) => {
    const { value } = event.target;
    onChange(event);

    // Only validate if the field has been touched
    if (touched) {
      validate(value);
    }
  };

  const handleBlur = () => {
    setTouched(true);
    validate(value);
  };

  const validate = (value) => {
    for (let i = 0; i < validators.length; i++) {
      if (!validators[i](value)) {
        setError(true);
        setHelperText(errorMessages[i]);
        return;
      }
    }
    setError(false);
    setHelperText("");
  };

  return (
    <ThemeProvider theme={theme}>
      <TextField
        label={label}
        InputLabelProps='fdjjjjjjj'
        value={value}
        type="text"
        variant="outlined"
        margin="none"
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        helperText={helperText}
        {...props}
      />
      <FormHelperText style={{marginTop:'-5px', marginLeft:'10px'}} id="component-helper-text">{description}</FormHelperText>
    </ThemeProvider>
  );
};

export default MuiTextField;
