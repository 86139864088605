import React from "react"
import * as Yup from "yup"
import { Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import 'react-toastify/dist/ReactToastify.css';
import TextField from "../../CommonComponents/TextField";




// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


const UntraceableInfo = ({ key, mainOBJ, onChange, errors }) => {


  return (
    <div style={{ margin: "15px", maxHeight:'400px', overflowY:'auto' }}>
      <Row>
        <Col>
          <TextField isAsterisk={true} label="No. of Calls Made to Customer" name="calls_made_to_custoomer" type="text" />
          <TextField isAsterisk={true} label="Remarks 1" name="remarks_1" type="text" />
          <TextField label="Remarks 2" name="remarks_2" type="text" />
          <TextField label="Remarks 3" name="remarks_3" type="text" />
          <TextField label="Remarks 4" name="remarks_4" type="text" />
          <TextField label="Remarks 5" name="remarks_5" type="text" />    
        </Col>
        <Col> 
        <TextField label="Remarks 6" name="remarks_6" type="text" />
        <TextField label="Remarks 7" name="remarks_7" type="text" />
        <TextField label="Remarks 8" name="remarks_8" type="text" />
        <TextField label="Remarks 9" name="remarks_9" type="text" />
        <TextField label="Remarks 10" name="remarks_10" type="text" />
       
        </Col>
      </Row>
    </div>
  )
}

UntraceableInfo.label = "Address Untraceable"
UntraceableInfo.initialValues = {
    calls_made_to_custoomer: "",
    remarks_1: "",
    remarks_2: "",
    remarks_3: "",
    remarks_4: "",
    remarks_5: "",
    remarks_6: "",
    remarks_7: "",
    remarks_8: "",
    remarks_9: "",
    remarks_10: "", 
}

UntraceableInfo.validationSchema = Yup.object({
    calls_made_to_custoomer: Yup.string().required("No. of Calls Made to Customer is required"),
    remarks_1: Yup.string().required("Remarks 1 is required"),
 
})
UntraceableInfo.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
}

export default UntraceableInfo