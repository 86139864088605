import React, { useState, useEffect } from "react";
import { Col, Row, Tab, Nav, Button, Modal } from "react-bootstrap";
import * as XLSX from "xlsx";
// import { EpochToOnlyDate } from "../CommonComponents/Helper";
import Spinner from "react-bootstrap/Spinner";
import swal from "sweetalert";
import * as moduleApi from "../rest/moduleApi";
import * as moduleApi2 from "../rest/moduleApi2";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import AddRolesAndPrivilage from "./AddRolesAndPrivilage";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
function AddBranch() {
  let orgDetails;
  const [excelData, setExcelData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [excelFileError, setExcelFileError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [branchList, setbranchList] = useState([]);
  const [orgList, setloggedInOrg] = useState("");
  const [orgId, setOrgId] = useState("");
  // const [show,setShow]=useState(false);
  var history = useHistory();

  const headerTitles = [
    "empID",
    "empName",
    "location",
    "designation",
    "emailID",
    "contactNo",
    "reportingMngEmpID",
    "reportingMngEmpName",
  ];
  const branchColumns = [
    {
      text: "Branch Name",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch Location",
      dataField: "branchLocation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch Email ID",
      dataField: "branchemail",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",
      dataField: "phoneNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  useEffect(() => {
    var orgData = localStorage.getItem("organization-details");
    console.log("orgData", orgData);
    setloggedInOrg(orgData);
    // eslint-disable-next-line
    orgDetails = localStorage.getItem("organizationId");
    console.log("orgId", orgDetails);
    setOrgId(orgDetails);
    loadData(orgDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadData = (EmpOrgId) => {
    const payLoad = {
      organizationId: EmpOrgId,
      pagination: true,
      searchKey: "",
    };
    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      console.log("response", response);
      var listData = [];

      response?.content?.forEach((element) => {
        let data = {
          branchName: element.branchName,
          branchLocation: element.branchLocation,
          branchemail: element.branchemail,
          phoneNumber: element.phoneNumber,
        };
        listData.push(data);
      });
      setbranchList(listData);
      console.log(listData);
    });
  };
  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    console.log("UploadedFileSize", selectedFile.size / 1024 ** 2);
    if (selectedFile.size / 1024 ** 2 >= 5) {
      return swal("Please Upload a File Less than 5 MB", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsBinaryString(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);

          console.log(e.target.result, "result");
          var data = btoa(e.target.result);
          setExcelFile(data);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("plz select your file");
    }
  };
  const handleSubmit = (e) => {
    setExcelData(null);
    console.log(e.target.files, "files");
    // setIsLoading(true);
    setLoader(true);
    console.log("excel", excelFile);

    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "base64" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
      const data2 = XLSX.utils.json_to_sheet(data, { skipHeader: true });
      const data3 = XLSX.utils.sheet_to_json(data2, { header: headerTitles });

      console.log("data", data3);
      setExcelData(data3);
    } else {
      setExcelData(null);
    }
    // setIsLoading(false);
    setLoader(false);
  };

  const uploadDailyScheduleData = () => {
    // moduleApi
    //   .postDataByName(
    //     "https://28j8i2iwug.execute-api.ap-south-1.amazonaws.com/default/onboarding",
    //     orgId,
    //     excelFile
    //   )
    //   .then((response) => {
    // var payload = {
    //   fileName: fileName,
    //   orgShortCode: userData?.autoFinanceOrganization?.orgshortcode,
    //   organizationId: userData?.autoFinanceUser?.organizationId,
    // };
    // console.log('payload', payload);
    moduleApi2
      .postData(apiCall.uploadOnBoardUsers + orgId, excelFile)
      .then((response) => {
        console.log("response", response);
        setExcelFile(null);
        setExcelData(null);
        setIsSubmit(false);
        setBasicModal2(false);
        if (response) {
          if (response?.type === "Created") {
            swal("Employee Added Successfully", {
              icon: "success",
            });
            // setShow(true);
            loadData(orgId);
          } else {
            swal(response.message, {
              icon: "error",
            });
          }
        } else {
          swal("Something went wrong, please try again later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };
  return (
    <div className="card">
      <div className="custom-tab-1">
        <div style={{ margin: "10px" }}>
          <Button
            style={{
              float: "left",
              margin: "10px",
              marginLeft: "20px",
              marginTop: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => history.push("/onboarding")}
          >
            <i className="fa fa-arrow-left" />
          </Button>
          <span
            style={{
              float: "left",
              marginLeft: "10px",
              margin: "10px",
              marginTop: "16px",
              fontSize: "18px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              fontWeight: "bold",
            }}
          >
            {orgList ? orgList : "-"}
          </span>
        </div>
        <div style={{ marginTop: "70px" }}>
          <Tab.Container defaultActiveKey="basicInfo">
            <Nav as="ul" className="nav-tabs-custom nav-justified">
              <Nav.Item as="li" key="0">
                <Nav.Link eventKey="basicInfo">
                  <b> Upload Onboarding Request </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="1">
                <Nav.Link eventKey="attendance">
                  <b> Add Roles & Privileges </b>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey="basicInfo" key="0" mountOnEnter>
                <div
                  // className="card contact-bx item-content"
                  style={{
                    // backgroundColor: "rgb(243,240,249)",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  <Button
                    className=""
                    variant="success btn-rounded"
                    style={{
                      backgroundColor: "#218a3f",
                      borderColor: "#218a3f",
                      borderRadius: "5px",
                      float: "right",
                      margin: "10px",
                      height: "35px",
                      width: "170px",
                      marginLeft: "800px",
                    }}
                    onClick={() => {
                      setBasicModal2(true);
                    }}
                  >
                    Onboarding Employees
                  </Button>
                  <Modal className="fade" size="xl" show={basicModal2}>
                    <Modal.Header>
                      <Modal.Title>Upload Branch/Employee Details</Modal.Title>
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setBasicModal2(false)}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body
                      style={{
                        maxHeight: "calc(120vh - 210px)",
                        overflowY: "auto",
                      }}
                    >
                      <div className="container">
                        <div className="form">
                          <form
                            className="form-group"
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <Row>
                              <Col>
                                <label>
                                  <h6>Upload Excel file</h6>
                                </label>
                                <br></br>
                                <input
                                  style={{ padding: "11px" }}
                                  type="file"
                                  className="form-control"
                                  onChange={handleFile}
                                  required
                                ></input>
                                {excelFileError && (
                                  <div
                                    className="text-danger"
                                    style={{ marginTop: 5 + "px" }}
                                  >
                                    {excelFileError}
                                  </div>
                                )}
                              </Col>
                              <Col>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    if (excelFile !== null) setIsSubmit(true);
                                  }}
                                  style={{ marginTop: 35 + "px" }}
                                >
                                  {loader && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      variant="light"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                  &nbsp;&nbsp;Preview
                                </button>
                                {isSubmit && (
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                    style={{
                                      marginTop: 35 + "px",
                                      marginLeft: "15px",
                                    }}
                                    onClick={() => {
                                      uploadDailyScheduleData();
                                    }}
                                  >
                                    Submit
                                  </button>
                                )}
                              </Col>
                            </Row>
                          </form>
                        </div>

                        <br></br>
                        <hr></hr>

                        {/* view file section */}
                        <h5>View Excel file</h5>
                        <div className="viewer">
                          {excelData === null && <>No file selected</>}
                          {excelData !== null && (
                            <div className="table-responsive">
                              <table className="table">
                                <thead
                                  style={{
                                    backgroundColor: "rgb(243,240,249)",
                                    top: "1px",
                                    position: "sticky",
                                  }}
                                >
                                  <tr style={{ textAlign: "center" }}>
                                    <th scope="col">EMP ID</th>
                                    <th scope="col">EMP Name</th>
                                    <th scope="col">LOCATION</th>
                                    <th scope="col">DESIGNATION</th>
                                    <th scope="col">EMAIL ID</th>
                                    <th scope="col">CONTACT NO</th>
                                    <th scope="col">REPORTING MNG EMP ID</th>
                                    <th scope="col">REPORTING MNG EMP NAME</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {excelData.map((individualExcelData) => (
                                    <tr
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "normal",
                                      }}
                                      key={
                                        individualExcelData.executiveEmployeeId
                                      }
                                    >
                                      <td>{individualExcelData.empID}</td>
                                      <td>{individualExcelData.empName}</td>
                                      <td>{individualExcelData.location}</td>
                                      <td>{individualExcelData.designation}</td>
                                      <td>{individualExcelData.emailID}</td>
                                      <td>{individualExcelData.contactNo}</td>
                                      <td>
                                        {individualExcelData.reportingMngEmpID}
                                      </td>
                                      <td>
                                        {
                                          individualExcelData.reportingMngEmpName
                                        }
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <BootstrapTable
                    keyField="id"
                    data={branchList}
                    columns={branchColumns}
                  />
                  {branchList < 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="attendance" key="1" mountOnEnter>
                <AddRolesAndPrivilage />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}

export default AddBranch;
