import React from "react";
import {
  GoogleMap,
  Marker,
  Polyline, withGoogleMap, withScriptjs
} from "react-google-maps";
import { compose, withProps } from "recompose";
import marker_blue from "../../src/images/marker_blue.png";
import marker_red from "../../src/images/marker_red.png";


const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyChqvJDsjaqXtn-tjeMBa-Y67xqz4mtuWE",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
  //   {props.date && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
  // </GoogleMap>

  const getDefaultCenter=()=>{
    if(props.latlongsArr.length>0){
     return props.latlongsArr[0][0]
    }
    return { lat: 17.385, lng: 78.4867 }
  }



  return (<div >
    <GoogleMap key={props.key} defaultZoom={13} defaultCenter={getDefaultCenter()}  >
      {
      // props.latlongsArr.length > 0 &&
        props.latlongsArr.map((ele, index) => (
          <>
            <Marker
              position={ele[0]}
              // eslint-disable-next-line no-native-reassign
              icon={(URL = marker_blue)}
              style={{ height: "50px", width: "50px" }}
            />
            <Polyline path={ele} options={{ strokeColor: `#${Math.floor(Math.random()*16777215).toString(16)}`}} />
            <Marker
              position={ele[ele.length - 1]}
              // eslint-disable-next-line no-native-reassign
              icon={(URL = marker_red)}
              style={{ height: "50px", width: "50px" }}
            />
          </>
        ))}
    </GoogleMap>

  </div>)
});

<MyMapComponent isMarkerShown date />;

export default MyMapComponent;