import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { getTokenInLocalStorage, hasAccess } from "../services/AuthService";
import Summary from "./ViewAllocations/Summary";
import UploadFiAllocations from "./UploadAllocations/UploadFiAllocations";
import { apiCall } from "../rest/restApi";
import * as nodeApi from "../rest/nodeApi";
import * as moduleApi from "../rest/moduleApi";
import "./fiallocations.css";
import AllocationDetails from "./ViewAllocations/AllocationDetails";
import { convertToLocalDateTime } from "./utils";
import ReactGA from "react-ga";
import swal from "sweetalert";
import Select from "react-select";
import { LoadingOverlayComponent } from "../CommonComponents/LoadingOverlay";
import UploadedFilesList from "../UploadSchedule/UploadedFilesList";
import FiClients from "../FiClients/FiClients";

function FieldInvestigationAllocations() {
  const [basicModal, setBasicModal] = useState(false);
  const [fiAllocationsOfEmp, setFiAllocationsOfEmp] = useState([]);
  const [fiAllocationsOfAgencies, setFiAllocationsOfAgencies] = useState([]);
  const [noDataFoundForEmp, setNoDataFoundForEmp] = useState(null);
  const [noDataFoundForAgency, setNoDataFoundForAgency] = useState(null);
  const [templateDownloadModal, setTemplateDownloadModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [fiClients, setFiClients] = useState([]);
  const [addClientModal, setAddClientModal] = useState(false);
  const [client, setClient] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [removeSelection, setRemoveSelection] = useState(false);
  let userLoginDetails = getTokenInLocalStorage();
  var userLoginData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFiAllocationsOfEmployees();
    getFiAllocationsOfAgencies();
    getFiClients();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setBasicModal(false);
  };

  const addClient = () => {
    setIsLoading(true);
    if (client === null) {
      setIsLoading(false);
      return swal("Please Enter Client Name", {
        icon: "error",
        dangerMode: true,
      });
    }else{
      if (client.trim().length===0) {
        setIsLoading(false);
        return swal("Please enter valid Client Name", {
          icon: "error",
          dangerMode: true,
        });
      }
    }

    if (client.length > 50) {
      setIsLoading(false);
      return swal("Client Should be of max 50 characters", {
        icon: "error",
        dangerMode: true,
      });
    }

    var payload = {
      addedByUserId: userLoginDetails?.autoFinanceUser?.userId,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      client: client?.toUpperCase(),
    };
    moduleApi.postData(apiCall.addFiClientName, payload).then((response) => {

      if (response) {
        if (response?.message) {
          setIsLoading(false);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setIsLoading(false);
          getFiClients();
          setAddClientModal(false);
          return swal("Client Added Successfully", {
            icon: "success",
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const fileTypesList = [
    { label: "Residence FI Template", value: "residence" },
    { label: "Office FI Template", value: "office" },
  ];

  const downloadAllocationsTemplate = () => {
    let residenceTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Field_Allocation_Residence_Executive_Template.xlsx";
    let officeTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Field_Allocation_Office_Executive_Template.xlsx";

    const fileUrl =
      selectedTemplate === "office" ? officeTemplateUrl : residenceTemplateUrl;

    // Create an anchor element
    const anchor = document.createElement("a");

    // Set the href and download attributes
    anchor.href = fileUrl;
    anchor.setAttribute(
      "download",
      (selectedTemplate === "office" ? "Office" : "Residence") +
        "_Template.xlsx"
    ); // Set the desired file name

    // Programmatically trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor element from the DOM (optional)
    anchor.remove();
    setSelectedTemplate(null);
    setTemplateDownloadModal(false);
  };

  const getFiClients = () => {
    var body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      //   searchKey: key,
    };
    moduleApi.postData(apiCall.getFiClientNames, body).then((response) => {
      const result = response?.map((item, index) => ({
        value: item.client,
        label: item.client,
        index: index,
      }));
      result.unshift({ label: "All", value: "" });

      setFiClients(result);
    });
  };

  const getFiAllocationsOfEmployees = () => {
    setRemoveSelection(true);
    if (userLoginData) {
      var date = new Date();
      var startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      let requestPayload = {
        organizationId: userLoginData.autoFinanceUser.organizationId,
        roleName: hasAccess(118)
          ? "ADMIN"
          : userLoginData.autoFinanceUser.roleName,
        managerId: !hasAccess(118)
          ? userLoginData.autoFinanceUser.roleName === "MANAGER" || "MANAGER_RA"
            ? userLoginData.autoFinanceUser.userId
            : null
          : null,
        startDate: convertToLocalDateTime(startDate, "DATE_YYYY_MM_DD"),
        endDate: convertToLocalDateTime(endDate, "DATE_YYYY_MM_DD"),
      };
      nodeApi
        .postData(apiCall.getFiAllocationsOfEmployees, requestPayload)
        .then((response) => {
          if (response.success === true) {
            if (response.fiAllocations) {
              let temp = hasAccess(118)
                ? response.fiAllocations?.filter(
                    (x) => x.status === "In Progress"
                  )
                : response.fiAllocations;
              setFiAllocationsOfEmp(temp);
              if (response.fiAllocations.length > 0) {
                setNoDataFoundForEmp(false);
                // setShowLoader(false);
                setIsLoading(false);
              } else {
                setNoDataFoundForEmp(true);
                setShowLoader(false);
                setIsLoading(false);
              }
            }
          } else {
            setShowLoader(false);
            setIsLoading(false);
            setNoDataFoundForEmp(true);
          }
        })
        .catch((error) => {
          setShowLoader(false);
          setIsLoading(false);
          setFiAllocationsOfEmp([]);
          setNoDataFoundForEmp(true);
        });
    }
  };

  const getFiAllocationsOfAgencies = () => {
    var date = new Date();
    var startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let requestPayload = {
      organizationId: userLoginData.autoFinanceUser.organizationId,
      roleName: userLoginData.autoFinanceUser.roleName,
      startDate: convertToLocalDateTime(startDate, "DATE_YYYY_MM_DD"),
      endDate: convertToLocalDateTime(endDate, "DATE_YYYY_MM_DD"),
    };
    nodeApi
      .postData(apiCall.getFiAllocationsOfAgencies, requestPayload)
      .then((response) => {
        if (response.success === true) {
          if (response.fiAllocations) {
            setFiAllocationsOfAgencies(response.fiAllocations);
            if (response.fiAllocations.length > 0) {
              setNoDataFoundForAgency(false);
              setShowLoader(false);
            } else {
              setNoDataFoundForAgency(true);
              setShowLoader(false);
            }
          }
        } else {
          setShowLoader(false);
          setNoDataFoundForAgency(true);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        setFiAllocationsOfAgencies([]);
        setNoDataFoundForAgency(true);
      });
  };

  return (
    <div className="card">
      <div style={{ display: "flex", justifyContent: "right" }}>
        <div className="fi-al-body">
          <Button
            // className="upload-btn"
            variant="primary btn-rounded"
            onClick={() => {
              setClient(null);
            setAddClientModal(true)
            }}
          >
            <i className="fa fa-plus"></i>&nbsp;&nbsp;Add FI Client
          </Button>
        </div>
        <div className="fi-al-body">
          <Button
            // className="upload-btn"
            variant="primary btn-rounded"
            onClick={() => {
              setTemplateDownloadModal(true);
            }}
          >
            <i className="fa fa-download"></i>&nbsp;&nbsp;Download Template
          </Button>
        </div>
        <div className="fi-al-body">
          {(userLoginDetails.autoFinanceUser.roleName === "ADMIN" ||
            userLoginDetails.autoFinanceUser.roleName === "MANAGER_RA" ||
            userLoginDetails.autoFinanceUser.roleName === "MANAGER") && (
            <Button
              className="upload-btn"
              variant="success btn-rounded"
              onClick={() => {
                setBasicModal(true);
              }}
            >
              <i class="fa fa-upload"></i>&nbsp;&nbsp;Upload FI Schedule
            </Button>
          )}
        </div>
      </div>
      <Modal className="fade" size="md" show={addClientModal}>
        <Modal.Header>
          <Modal.Title>Add Client</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddClientModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>Client Name</h6>
                </label>
                <input
                  id="clientName"
                  className="form-control"
                  type="text"
                  placeholder="Enter Client Name"
                  name="clientName"
                  onChange={(e) => {
                    setClient(e.target.value);
                  }}
                  style={{ padding: "11px" }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => addClient()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => setAddClientModal(false)}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="md" show={templateDownloadModal}>
        <Modal.Header>
          <Modal.Title>Download Template</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setTemplateDownloadModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <Col>
                <div
                  style={{
                    marginBottom: "5px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>
                    <h6>Template Type</h6>
                  </label>
                  <div
                    style={{
                      float: "right",
                      width: "250px",
                      height: "38px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      isSearchable={false}
                      onChange={(value) => {
                        setSelectedTemplate(value.value);
                      }}
                      placeholder="Select Template Type"
                      // value={executivesList[executiveFilterIndex]}
                      options={fileTypesList}
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <button
                  type="submit"
                  className="btn btn-success"
                  style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                  onClick={() => {
                    if (selectedTemplate !== null) {
                      downloadAllocationsTemplate();
                    } else {
                      return swal("Please Select Template Type", {
                        icon: "error",
                        dangerMode: true,
                      });
                    }
                  }}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="xl" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Upload FI Allocations</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => handleClose()}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
        >
          <UploadFiAllocations
            close={handleClose}
            getFiAllocationsOfEmployees={getFiAllocationsOfEmployees}
            fiAllocationsOfEmp={fiAllocationsOfEmp}
            fiAllocationsOfAgencies={fiAllocationsOfAgencies}
            getFiAllocationsOfAgencies={getFiAllocationsOfAgencies}
            fiClients={fiClients}
          />
        </Modal.Body>
      </Modal>
      <div style={{ margin: "20px" }}>
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey="allocated">
            <Nav as="ul" className="nav-tabs-custom nav-justified">
              <Nav.Item as="li" key="0">
                <Nav.Link eventKey="allocated">
                  <b> Employee Allocations </b>
                </Nav.Link>
              </Nav.Item>
              {userLoginDetails.autoFinanceUser.roleName === "ADMIN" && (
                <Nav.Item as="li" key="1">
                  <Nav.Link eventKey="notAllocated">
                    <b> Agency Allocations </b>
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item as="li" key="2">
                <Nav.Link eventKey="summary">
                  <b> Summary </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="3">
                <Nav.Link eventKey="uploadedFiles">
                  <b> Uploaded Files </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="4">
                <Nav.Link eventKey="fiClients">
                  <b> FI Clients </b>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey="allocated" key="0">
                <LoadingOverlayComponent showLoader={isLoading}>
                  <AllocationDetails
                    type="EMPLOYEES"
                    fiAllocationsOfEmp={fiAllocationsOfEmp}
                    removeSelection={removeSelection}
                    getFiAllocationsOfEmployees={getFiAllocationsOfEmployees}
                    setFiAllocationsOfEmp={setFiAllocationsOfEmp}
                    showLoader={showLoader}
                    setIsLoading={setIsLoading}
                    noDataFoundForEmp={noDataFoundForEmp}
                    setNoDataFoundForAgency={setNoDataFoundForAgency}
                    setNoDataFoundForEmp={setNoDataFoundForEmp}
                    clients={fiClients}
                  />
                </LoadingOverlayComponent>
              </Tab.Pane>
              {userLoginDetails.autoFinanceUser.roleName === "ADMIN" && (
                <Tab.Pane eventKey="notAllocated" key="1">
                  <AllocationDetails
                    type="AGENCIES"
                    fiAllocationsOfAgencies={fiAllocationsOfAgencies}
                    showLoader={showLoader}
                    noDataFoundForAgency={noDataFoundForAgency}
                    setFiAllocationsOfAgencies={setFiAllocationsOfAgencies}
                    getFiAllocationsOfAgencies={getFiAllocationsOfAgencies}
                    setNoDataFoundForAgency={setNoDataFoundForAgency}
                    setNoDataFoundForEmp={setNoDataFoundForEmp}
                  />
                </Tab.Pane>
              )}
              <Tab.Pane eventKey="summary" key="2" mountOnEnter unmountOnExit>
                <Summary />
              </Tab.Pane>
              <Tab.Pane eventKey="uploadedFiles" key="3" mountOnEnter unmountOnExit>
                <UploadedFilesList allocationType={'fiAllocation'} />
              </Tab.Pane>
              <Tab.Pane eventKey="fiClients" key="4" mountOnEnter unmountOnExit>
                <FiClients/>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}

export default FieldInvestigationAllocations;
