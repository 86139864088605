import { useSelector } from "react-redux";

export const SET_CALL_AUDIO_MODAL = "SET_CALL_AUDIO_MODAL";
export const SET_CALL_TRANSFER_MODAL = "SET_CALL_TRANSFER_MODAL";
export const SET_IS_MODAL_OPEN = "SET_IS_MODAL_OPEN";
export const SET_IS_TEST_CALL = "SET_IS_TEST_CALL";
export const SET_IS_CALL_ACTIVE = "SET_IS_CALL_ACTIVE";
export const SET_IS_MUTE = "SET_IS_MUTE";
export const SET_IS_HOLD = "SET_IS_HOLD";
export const SET_IS_MOBILE_FIELD = "SET_IS_MOBILE_FIELD";
export const SET_IS_ONGOING_CALL = "SET_IS_ONGOING_CALL";
export const SET_IS_RINGING = "SET_IS_RINGING";
export const SET_IS_CALL_ANSWERED = "SET_IS_CALL_ANSWERED";
export const SET_IS_CALL_REJECTED = "SET_IS_CALL_REJECTED";
export const SET_IS_MODAL_MINIMISED = "SET_IS_MODAL_MINIMISED";
export const SET_WEBRTC_MOBILE = "SET_WEBRTC_MOBILE";
export const SET_ALLOCATION_ID = "SET_ALLOCATION_ID";
export const SET_ALLOCATION_DETAILS = "SET_ALLOCATION_DETAILS";
export const SET_IS_MY_ALLOCATIONS_CALLED = "SET_IS_MY_ALLOCATIONS_CALLED";
export const SET_CALL_ID = "SET_CALL_ID";
export const SET_TYPE = "SET_TYPE";
export const SET_CALL_REMARKS_MODAL = "SET_CALL_REMARKS_MODAL";
export const SET_MODAL_HEADER_TEXT = "SET_MODAL_HEADER_TEXT";
export const SET_CALL_STATUS_TEXT = "SET_CALL_STATUS_TEXT";
export const SET_DAILING_TIME = "SET_DAILING_TIME";
export const SET_RINGING_TIME = "SET_RINGING_TIME";
export const SET_TALK_TIME = "SET_TALK_TIME";
export const SET_HOLD_TIME = "SET_HOLD_TIME";
export const SET_DISPO_TIME = "SET_DISPO_TIME";
export const SET_REQ_PAYMENTS_LIST = "SET_REQ_PAYMENTS_LIST";
export const SET_IS_ONLINE = "SET_IS_ONLINE";
export const SET_SELECTED_AGENT_STATUS = "SET_SELECTED_AGENT_STATUS";
export const SET_IS_PROGRESSIVE_DIALING = "SET_IS_PROGRESSIVE_DIALING";
export const SET_IS_SALES_PROGRESSIVE_DIALING = "SET_IS_SALES_PROGRESSIVE_DIALING";
export const SET_CONTINUE_DIALING = "SET_CONTINUE_DIALING";
export const SET_SALES_CONTINUE_DIALING = "SET_SALES_CONTINUE_DIALING";
export const SET_CAMPAIGN_NAME = "SET_CAMPAIGN_NAME";
export const SET_TIMER_COUNT = "SET_TIMER_COUNT";
export const SET_IS_TIMER_COUNTING = "SET_IS_TIMER_COUNTING";
export const SET_CALL_STATUS = "SET_CALL_STATUS";
export const SET_MODAL = "SET_MODAL";
export const SET_PIOPIY = "SET_PIOPIY";
export const SET_IS_INCOMING_CALL = "SET_IS_INCOMING_CALL";
export const SET_IS_ADD_SALE_LEAD_BUTTON = "SET_IS_ADD_SALE_LEAD_BUTTON";
export const SET_INCOMING_LOAN_NUMBERS = "SET_INCOMING_LOAN_NUMBERS";
export const SET_IS_INCOMING_LOAN_NO_DROPDOWN = "SET_IS_INCOMING_LOAN_NO_DROPDOWN";
export const SET_CALL_TYPE = "SET_CALL_TYPE";
export const SET_SELECTED_LOAN_DROP_DOWN_VALUE = "SET_SET_SELECTED_LOAN_DROP_DOWN_VALUECALL_TYPE";
export const SET_SEARCHED_LOAN_NO_VALUE = "SET_SEARCHED_LOAN_NO_VALUE";

export function setPiopiy(payload) {
  return {
    type: SET_PIOPIY,
    payload,
  };
}
export function setModal(payload) {
  return {
    type: SET_MODAL,
    payload,
  };
}
export function setCallAudioModal(payload) {
  return {
    type: SET_CALL_AUDIO_MODAL,
    payload,
  };
}

export function setCallTransferModal(payload) {
  return {
    type: SET_CALL_TRANSFER_MODAL,
    payload,
  };
}

export function setIsModalOpen(payload) {
  return {
    type: SET_IS_MODAL_OPEN,
    payload,
  };
}

export function setIsTestCall(payload) {
  return {
    type: SET_IS_TEST_CALL,
    payload,
  };
}

export function setIsCallActive(payload) {
  return {
    type: SET_IS_CALL_ACTIVE,
    payload,
  };
}

export function setIsMute(payload) {
  return {
    type: SET_IS_MUTE,
    payload,
  };
}

export function setIsHold(payload) {
  return {
    type: SET_IS_HOLD,
    payload,
  };
}

export function setIsMobileField(payload) {
  return {
    type: SET_IS_MOBILE_FIELD,
    payload,
  };
}

export function setIsOnGoingCall(payload) {
  return {
    type: SET_IS_ONGOING_CALL,
    payload,
  };
}

export function setIsRinging(payload) {
  return {
    type: SET_IS_RINGING,
    payload,
  };
}

export function setIsCallAnswered(payload) {
  return {
    type: SET_IS_CALL_ANSWERED,
    payload,
  };
}

export function setIsCallRejected(payload) {
  return {
    type: SET_IS_CALL_REJECTED,
    payload,
  };
}

export function setIsModalMinimised(payload) {
  return {
    type: SET_IS_MODAL_MINIMISED,
    payload,
  };
}

export function setWebRtcMobile(payload) {
  return {
    type: SET_WEBRTC_MOBILE,
    payload,
  };
}

export function setAllocationId(payload) {
  return {
    type: SET_ALLOCATION_ID,
    payload,
  };
}

export function setAllocationDetails(payload) {
  return {
    type: SET_ALLOCATION_DETAILS,
    payload,
  };
}

export function setIsMyAllocationsCalled(payload) {
  return {
    type: SET_IS_MY_ALLOCATIONS_CALLED,
    payload,
  };
}

export function setCallId(payload) {
  return {
    type: SET_CALL_ID,
    payload,
  };
}

export function setType(payload) {
  return {
    type: SET_TYPE,
    payload,
  };
}

export function setCallRemarksModal(payload) {
  return {
    type: SET_CALL_REMARKS_MODAL,
    payload,
  };
}

export function setModalHeaderText(payload) {
  return {
    type: SET_MODAL_HEADER_TEXT,
    payload,
  };
}

export function setCallStatusText(payload) {
  return {
    type: SET_CALL_STATUS_TEXT,
    payload,
  };
}

export function setDailingTime(payload) {
  return {
    type: SET_DAILING_TIME,
    payload,
  };
}

export function setRingingTime(payload) {
  return {
    type: SET_RINGING_TIME,
    payload,
  };
}

export function setTalkTime(payload) {
  return {
    type: SET_TALK_TIME,
    payload,
  };
}

export function setHoldTime(payload) {
  return {
    type: SET_HOLD_TIME,
    payload,
  };
}

export function setDispoTime(payload) {
  return {
    type: SET_DISPO_TIME,
    payload,
  };
}

export function setReqPaymentsList(payload) {
  return {
    type: SET_REQ_PAYMENTS_LIST,
    payload,
  };
}

export function setIsOnline(payload) {
  return {
    type: SET_IS_ONLINE,
    payload,
  };
}

export function setSelectedAgentStatus(payload) {
  return {
    type: SET_SELECTED_AGENT_STATUS,
    payload,
  };
}

export function setIsProgressiveDialing(payload) {
  return {
    type: SET_IS_PROGRESSIVE_DIALING,
    payload,
  };
}

export function setContinueeDialing(payload) {
  return {
    type: SET_CONTINUE_DIALING,
    payload,
  };
}
export function setIsSalesProgressiveDialing(payload) {
  return {
    type: SET_IS_SALES_PROGRESSIVE_DIALING,
    payload,
  };
}

export function setSalesContinueeDialing(payload) {
  return {
    type: SET_SALES_CONTINUE_DIALING,
    payload,
  };
}

export function setCampaignName(payload) {
  return {
    type: SET_CAMPAIGN_NAME,
    payload,
  };
}

export function setTimerCount(payload) {
  return {
    type: SET_TIMER_COUNT,
    payload,
  };
}

export function setIsTimerCounting(payload) {
  return {
    type: SET_IS_TIMER_COUNTING,
    payload,
  };
}

export function setCallStatus(payload) {
  return {
    type: SET_CALL_STATUS,
    payload,
  };
}
export function setIsIncomingCall(payload) {
  return {
    type: SET_IS_INCOMING_CALL,
    payload,
  };
}
export function setIncomingLoanNumbers(payload) {
  return {
    type: SET_INCOMING_LOAN_NUMBERS,
    payload,
  };
}
export function setIsAddSaleLeadButton(payload) {
  return {
    type: SET_IS_ADD_SALE_LEAD_BUTTON,
    payload,
  };
}
export function setIsIncomingLoanNoDropdown(payload) {
  return {
    type: SET_IS_INCOMING_LOAN_NO_DROPDOWN,
    payload,
  };
}
export function setCallType(payload) {
  return {
    type: SET_CALL_TYPE,
    payload,
  };
}

export function setSelectedLoanDropDownValue(payload) {
  return {
    type: SET_SELECTED_LOAN_DROP_DOWN_VALUE,
    payload,
  };
}

export function setSearchedLoanNoValue(payload) {
  return {
    type: SET_SEARCHED_LOAN_NO_VALUE,
    payload,
  };
}

export function openModal() {
  setCallAudioModal(true);
  setIsModalMinimised(false);
}

export function closeModal() {
  setCallAudioModal(false);
}

export function minimizeModal() {
    setCallAudioModal(false);
    setIsModalMinimised(true);
}

export function closeMinimizeModal() {
  setIsModalMinimised(false);
}

export function handleClose() {
  setCallRemarksModal(false);
}

export function HandleMyAllocations() {
  const { isMyAllocationsCalled } = useSelector((state) => state.calls);
  setIsMyAllocationsCalled(!isMyAllocationsCalled);
}

export function updateProgressiveDialing(value) {
  setIsProgressiveDialing(value);
}

export function ContinueProgressiveDialing() {
  const { continueDialing } = useSelector((state) => state.calls);
  setContinueeDialing(continueDialing + 1);
}

export function startCountdownTimer() {
  setTimerCount(10); // Reset the countdown to 10 seconds
  setIsTimerCounting(true);
}

export function makeAllocationIdNull() {
  setAllocationId(null);
}
