import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import swal from "sweetalert";
import * as helper from "../CommonComponents/Helper";


const PaymentSummaryDetails = ({ transactionType, status, startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  // const [basicModal2, setBasicModal2] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState(null);
  // const [selectedApprovalStatus, setSelectedApprovalStatus] = useState("");
  // const [rejectionReason, setRejectionReason] = useState(null);

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaymentsList(
      currPage,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPaymentsList = (pageNo) => {
    let listData = [];
    setIsLoading(true);
    var payload = {
      endTime: endDate,
      startTime: startDate,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchkey: "",
      paymentMode: transactionType === "Payment Mode" ? status : "",
      paymentChannel: transactionType === "Payment Channel" ? status : "",
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getAllPayments, pageNo, 10),
        payload
      )
      .then((response) => {
        setPaymentsList([]);
        console.log("PaymentResponse", response);
        response?.content?.forEach((element) => {
          let data = {
            id: element?.id,
            collectedById: element.collectedById ? element.collectedById : "-",
            collectedByName: element.collectedByName
              ? element.collectedByName
              : "-",
            customerName: element.customerName ? element.customerName : "-",
            contactNumber: element.contactNumber ? element.contactNumber : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            paymentStatus: element.paymentStatus ? element.paymentStatus : "-",
            totalAmount: element.totalAmount ? element.totalAmount : 0,
            createdTime: element.createdTime ? element.createdTime : 0,
            paymentDate: element?.paymentChannel === "Tele Callers" && element?.paymentMode === "Cash"
              ? helper.EpochToOnlyDate(element.createdTime)
              : element?.paymentChannel === "Tele Callers"
                ? helper.EpochToOnlyDate(element.paymentDate) : element?.paymentDate
                  ? helper.EpochToDate(element.paymentDate)
                  : "-",
            emiAmount: element.emiAmount ? element.emiAmount : 0,
            latePaymentCharges: element.latePaymentCharges
              ? element.latePaymentCharges
              : 0,
            bounceCharges: element.bounceCharges ? element.bounceCharges : 0,
            penaltyCharges: element.penaltyCharges ? element.penaltyCharges : 0,
            otherCharges: element.otherCharges ? element.otherCharges : 0,
            approvalStatus: element.approvalStatus
              ? element.approvalStatus
              : "-",
            approvedByEmpId: element.approvedByEmpId
              ? element.approvedByEmpId
              : "-",
            approvedByEmpName: element.approvedByEmpName
              ? element.approvedByEmpName
              : "-",
            approvedTime: element.approvedTime
              ? helper.EpochToDate(element.approvedTime)
              : "-",
            paymentRejectionReason: element.paymentRejectionReason
              ? element.paymentRejectionReason
              : "-",
            receiptNumber: element?.receiptNumber
              ? element?.receiptNumber
              : null,
            transactionId: element.transactionId ? element.transactionId : "-",
            payResponseCode: element.payResponseCode
              ? element.payResponseCode
              : "-",
            subscrptionId: element.subscrptionId ? element.subscrptionId : "-",
            utrNumber: element.utrNumber ? element.utrNumber : "-",
            paymentMode: element?.paymentMode ? element.paymentMode === "Payment Link" ? "Bulk request payment" : element.paymentMode : "-",
            paymentChannel: element.paymentChannel
              ? element.paymentChannel
              : "-",
          };
          listData.push(data);
        });
        setCurrPage(pageNo);
        setPaymentsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const paymentColumns = [
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile No</span>
        </div>
      ),
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Loan</span>
          <br></br>
          <span>Number</span>
        </div>
      ),
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "paymentDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>EMI Amount</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "emiAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Late Payment</span>
          <br></br>
          <span>Charges Paid</span>
        </div>
      ),
      dataField: "latePaymentCharges",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Bounce Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "bounceCharges",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Penalty Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "penaltyCharges",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Other Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "otherCharges",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total Amount</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "totalAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (
          row.paymentStatus === "PAYMENT_SUCCESS" ||
          row.paymentStatus === "COMPLETED" ||
          row.payResponseCode === "SUCCESS" ||
          row.paymentMode === "Cash" ||
          row.paymentMode === "UPI" ||
          row.paymentMode === "Bank Transfer" ||
          row.paymentChannel === "Tele Callers"
        ) {
          return (<span style={{ color: '#0000FF' }}>{row.totalAmount}</span>);
        } else {
          return (<span style={{ color: 'red' }}>{row.totalAmount}</span>);
        }
      }
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Mode</span>
        </div>
      ),
      dataField: "paymentMode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "paymentStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (
          row.paymentStatus === "PAYMENT_SUCCESS" ||
          row.paymentStatus === "COMPLETED" ||
          row.payResponseCode === "SUCCESS" ||
          row.paymentStatus === "Success" ||
          row.paymentMode === "Cash" ||
          row.paymentMode === "UPI" ||
          row.paymentMode === "Bank Transfer" ||
          row.paymentChannel === "Tele Callers"
        ) {
          return (
            <span className="badge badge-rounded badge-success">Success</span>
          );
        } else if (row.paymentStatus === "PAYMENT_CANCELLED") {
          return (
            <span className="badge badge-rounded badge-danger">Cancelled</span>
          );
        } else if (
          row.paymentStatus === "PAYMENT_ERROR" ||
          row.paymentStatus === "FAILED"
        ) {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else if (
          row.paymentStatus === "PENDING") {
          return (
            <span className="badge badge-rounded badge-warning">Pending</span>
          );
        } else if (row.paymentStatus === "TRANSACTION_NOT_FOUND") {
          return (
            <span className="badge badge-rounded badge-danger">
              Transaction Not Found
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Channel</span>
        </div>
      ),
      dataField: "paymentChannel",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Approval</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "approvalStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.approvalStatus === "APPROVED") {
          return (
            <span className="badge badge-rounded badge-success">Approved</span>
          );
        } else if (row.approvalStatus === "REJECTED") {
          return (
            <span className="badge badge-rounded badge-danger">Rejected</span>
          );
        } else if (row.approvalStatus === "PENDING") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              Pending
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Approved</span>
          <br></br>
          <span>By</span>
        </div>
      ),
      dataField: "approvedByEmpName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Approved</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "approvedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Collected By</span>
          <br></br>
          <span>Id</span>
        </div>
      ),
      dataField: "collectedById",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Approve /</span>
          <br></br>
          <span>Reject</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setPaymentDetails(row);
          setBasicModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.receiptNumber === null) {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-download"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.receiptNumber !== null) {
            window.open(
              moduleApi.getBasePath() +
              apiCall.getPaymentReceiptForPayments +
              row.receiptNumber
            );
          }
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.receiptNumber === null) {
          return <></>;
        } else {
          return (
            <div>
              <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fas fa-external-link-alt"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.receiptNumber !== null) {
            swal({
              title: "Resend",
              text: "Do you really want to Resend Payment Receipt?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                resendPaymentReceipt(row?.receiptNumber);
              }
            });
          }
        },
      },
    },
  ];

  const resendPaymentReceipt = (receiptNumber) => {
    moduleApi
      .getData(apiCall.resendPaymentReceiptForPayments + receiptNumber)
      .then((response) => {
        if (response) {
          if (response.Message === "Receipt Sent Successfully") {
            return swal("Receipt Sent Successfully", {
              icon: "success",
              dangerMode: false,
            });
          } else if (response.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        }
      });
  };

  // const approvalStatusOptions = [
  //   { label: "All", value: "" },
  //   { label: "Pending", value: "PENDING" },
  //   { label: "Approved", value: "APPROVED" },
  //   { label: "Rejected", value: "REJECTED" },
  // ];

  // const setApprovalStatusValue = (value) => {
  //   getPaymentsList(0, searchKey, startDate, endDate, selectedStatus, value);
  //   setSelectedApprovalStatus(value);
  // };


  //    const getPaymentStatus=(row)=>{
  // if (
  //           row.paymentStatus === "PAYMENT_SUCCESS" ||
  //           row.payResponseCode === "SUCCESS" ||
  //           row.paymentMode === "Cash" ||
  //           row.paymentMode === "UPI" ||
  //           row.paymentMode === "Bank Transfer"
  //         ) {
  //           return (
  //             "Success"
  //           );
  //         } else if (row.paymentStatus === "PAYMENT_CANCELLED") {
  //           return (
  //             "Cancelled"
  //           );
  //         } else if (
  //           row.paymentStatus === "PAYMENT_ERROR" ||
  //           row.paymentStatus === "FAILED"
  //         ) {
  //           return (
  //             "Failed"
  //           );
  //         } else if (row.paymentStatus === "TRANSACTION_NOT_FOUND") {
  //           return (

  //               "Transaction Not Found"
  //           );
  //         } else {
  //           return "-";
  //         }
  //    }

  const onPageChange = (selectedPage) => {
    console.log("pageNo", selectedPage - 1);
    setCurrPage(selectedPage - 1);
    getPaymentsList(
      selectedPage - 1,
    );
  };

  // const updateApprovalStatus = (paymentDetails, status) => {
  //   console.log("status", status);
  //   if (status === "REJECTED" && rejectionReason === null) {
  //     return swal("Please Enter Rejection Reason", {
  //       icon: "error",
  //       dangerMode: true,
  //     });
  //   }

  //   var body = {
  //     approvalStatus: status,
  //     paymentApprovedBy: userLoginDetails?.autoFinanceUser?.userId,
  //     paymentRejectionReason: rejectionReason,
  //     id: paymentDetails?.id,
  //     paymentMode: selectedStatus,
  //   };

  //   moduleApi
  //     .postData(apiCall.updateNonVisitedpaymentstatus, body)
  //     .then((response) => {
  //       if (response) {
  //         if (response?.message) {
  //           return swal(response?.message, {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         } else if (
  //           response?.Message === "Payment Status Updated Successfully"
  //         ) {
  //           setBasicModal(false);
  //           setBasicModal2(false);
  //           getPaymentsList(
  //             currPage,
  //             searchKey,
  //             startDate,
  //             endDate,
  //             selectedStatus,
  //             // selectedCpName,
  //             selectedApprovalStatus
  //           );
  //           return swal("Updated Successfully", {
  //             icon: "success",
  //           });
  //         }
  //       } else {
  //         return swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  // };

  return (
    <div className="card">
      <div className="custom-tab-1">
        <div style={{ margin: "10px" }}>
          <LoadingOverlay
            active={isLoading}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            <div
              style={{
                background: "white",
                margin: "10px",
                marginTop: "-5px",
                overflowX: "auto",
                overflowY: "auto",
                maxHeight: "500px",
              }}
            >
              <BootstrapTable
                keyField="id"
                data={paymentsList}
                columns={paymentColumns}
                onSelect={onPageChange}
              />
            </div>
            {paymentsList.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements}
                pageSize={10}
                defaultActivePage={currPage + 1}
                onSelect={onPageChange}
              />
            </div>
          </LoadingOverlay>
        </div>
      </div>

      <Modal className="fade" size="md" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Payment Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}></Modal.Body>
        <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Customer Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.customerName
                ? paymentDetails?.customerName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Customer Mobile No
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.contactNumber
                ? paymentDetails?.contactNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Loan Number
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.loanNumber ? paymentDetails?.loanNumber : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Date
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentDate ? paymentDetails?.paymentDate : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Total Amount Paid
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.totalAmount ? paymentDetails?.totalAmount : 0}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Mode
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentMode ? paymentDetails?.paymentMode : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentStatus === "PAYMENT_SUCCESS" ||
                paymentDetails?.paymentMode === "Cash" ||
                paymentDetails?.paymentMode === "UPI" ||
                paymentDetails?.paymentMode === "Bank Transfer"
                ? "Success"
                : paymentDetails?.paymentStatus}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvalStatus
                ? paymentDetails?.approvalStatus
                : "-"}
            </td>
          </tr>
          {paymentDetails?.approvalStatus === "REJECTED" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Rejection Reason
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {paymentDetails?.paymentRejectionReason
                  ? paymentDetails?.paymentRejectionReason
                  : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approved By
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvedByEmpName
                ? paymentDetails?.approvedByEmpName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Date
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvedTime
                ? paymentDetails?.approvedTime
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Collected By ID
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.collectedById
                ? paymentDetails?.collectedById
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Collected By Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.collectedByName
                ? paymentDetails?.collectedByName
                : "-"}
            </td>
          </tr>
          {paymentDetails?.paymentMode === "UPI Mandates" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                subscrptionId Id
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {paymentDetails?.subscrptionId
                  ? paymentDetails?.subscrptionId
                  : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Utr Number
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.utrNumber ? paymentDetails?.utrNumber : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Transaction Id
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.transactionId
                ? paymentDetails?.transactionId
                : "-"}
            </td>
          </tr>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentSummaryDetails;
