import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Table } from "react-bootstrap";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import * as helper from "../CommonComponents/Helper";
import PaginationComponent from "react-reactstrap-pagination";
import * as XLSX from "xlsx";
import Flatpickr from "react-flatpickr";
import { SRLWrapper } from "simple-react-lightbox";
import ReactGA from "react-ga";
import {
  getTokenInLocalStorage,
  hasAccess,
  isAdmin,
} from "../services/AuthService";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

// import { CustomToaster } from "../rest/apiUtils";

const CallReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [callAudioModal, setCallAudioModal] = useState(false);
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [paymentReceiptModal, setPaymentReceiptModal] = useState(false);
  const [paymentReceiptImage, setPaymentReceiptImage] = useState(null);
  const [callDetails, setCallDetails] = useState(null);
  const [totalElements, setTotalElements] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [enteredLoanNumber, setEnteredLoanNumber] = useState(null);
  const [detailedCallReportList, setDetailedCallReportList] = useState([]);
  const [callIntensityReportList, setCallIntensityReportList] = useState([]);
  const [paymentStatusSummaryList, setPaymentStatusSummaryList] = useState([]);
  const [summaryCallReportList, setSummaryCallReportList] = useState([]);
  const [CallDispositionReportList, setCallDispositionReportList] = useState(
    []
  );
  const [summaryReportData, setSummaryReportData] = useState(null);
  const [dispositionReportData, setDispositionReportData] = useState(null);
  const [detailedReportData, setDetailedReportData] = useState(null);
  const [intensityReportData, setIntensityReportData] = useState(null);
  const [paymentStatusSummaryReportData, setPaymentStatusSummaryReportData] =
    useState(null);
  const [reportCategory, setReportCategory] = useState("CallReport");
  const [selectedCallsType, setSelectedCallsType] = useState("");
  const [isDetailedReport, setIsDetailedReport] = useState("CallReport");
  const [keys, setKeys] = useState([]);
  const [dispositionKeys, setDispositionKeys] = useState([]);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [start2, setStart2] = useState(0);
  const [end2, setEnd2] = useState(0);
  let userLoginDetails = getTokenInLocalStorage();

  // useEffect(() => {
  //   startServiceWorkerTimer()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const startServiceWorkerTimer = () => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.controller?.postMessage({ type: 'START_TIMER' });
  //   }
  // };

  // // Add an event listener to listen for messages from the Service Worker
  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     if (event.data && event.data.type === 'TIMER_TICK') {
  //       // Handle the timer tick event, call your method here
  //       // Your method in the src folder can be invoked here or trigger any action you need
  //       console.log('Timer tick occurred.');
  //     }
  //   };

  //   navigator.serviceWorker.addEventListener('message', handleMessage);

  //   return () => {
  //     navigator.serviceWorker.removeEventListener('message', handleMessage);
  //   };
  // }, []);

  useEffect(() => {
    ReactGA.pageview("Call Reports");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (detailedReportData !== null ||
      summaryReportData !== null ||
      paymentStatusSummaryReportData !== null ||
      intensityReportData !== null ||
      dispositionReportData !== null) &&
      handleExport();
    setIsLoading(false);
    // eslint-disable-next-line
  }, [
    detailedReportData,
    summaryReportData,
    dispositionReportData,
    paymentStatusSummaryReportData,
    intensityReportData,
  ]);

  const onSubmit = (start, end, curPage) => {
    if (isDateFilter === false) {
      if (!start || !end) {
        swal("Please Select Date Range", {
          icon: "error",
          dangerMode: true,
        });
        return;
      }
    } else {
      if (!start2 || !end2) {
        swal("Please Select Month", {
          icon: "error",
          dangerMode: true,
        });
        return;
      }
    }
    setIsLoading(true);
    setIsDetailedReport(reportCategory);

    const payLoad = {
      endtime: isDateFilter === false ? end : end2,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportCategory: "DETAILED",
      reportingOfficerId: !isAdmin()
        ? userLoginDetails.autoFinanceUser.userId
        : "",
      reportType: reportCategory,
      starttime: isDateFilter === false ? start : start2,
      searchKey: enteredLoanNumber,
      callsType: selectedCallsType,
    };

    setCurrPage(curPage);
    moduleApi
      .postData(moduleApi.withPage(apiCall.reports, curPage, 10), payLoad)
      .then((response) => {
        if (reportCategory === "CallReport") {
          setSummaryCallReportList([]);
          setCallDispositionReportList([]);
          setDetailedCallReportList(response?.content);
        } else if (reportCategory === "CallAllocationReport") {
          setSummaryCallReportList([]);
          setCallDispositionReportList([]);
          setDetailedCallReportList([]);
          setPaymentStatusSummaryList(response?.content);
        } else if (reportCategory === "CallReportSummaryByDay") {
          setCallDispositionReportList([]);
          setDetailedCallReportList([]);
          const transformedArray = [];
          response?.content?.forEach((item) => {
            const transformedObject = {};
            transformedObject["Emp Id"] = item.agentId;
            transformedObject["Emp Name"] = item.empName;
            transformedObject["Emp Status"] = item.empStatus;
            transformedObject["Date"] = item?.date
              ? helper.EpochToOnlyDate(item?.date)
              : "-";
            transformedObject["Total Login"] = item.totalLoginTime
              ? helper.secondsToHms(item.totalLoginTime)
              : 0;
            // transformedObject["TOS"] = item

            // Iterate through the nested objects dynamically
            Object.keys(item.breakNameWithTimes).forEach((breakCategory) => {
              Object.keys(item.breakNameWithTimes[breakCategory]).forEach(
                (breakName) => {
                  transformedObject[breakName] = item.breakNameWithTimes[
                    breakCategory
                  ][breakName]
                    ? helper.secondsToHms(
                        item.breakNameWithTimes[breakCategory][breakName]
                      )
                    : 0;
                }
              );
            });
            // transformedObject["Dailing Time"] = item.dailingTime
            //   ? helper.secondsToHms(item.dailingTime)
            //   : 0;
            transformedObject["Ringing Time"] = item.ringingTime
              ? helper.secondsToHms(item.ringingTime)
              : 0;
            transformedObject["Talk Time"] = item.talkTime
              ? helper.secondsToHms(item.talkTime)
              : 0;
            transformedObject["Hold Time"] = item.holdTime
              ? helper.secondsToHms(item.holdTime)
              : 0;
            transformedObject["Disposition Time"] = item.dispoTime
              ? helper.secondsToHms(item.dispoTime)
              : 0;
            transformedObject["Total Productive Time"] = item.totalProdTime
              ? helper.secondsToHms(item.totalProdTime)
              : 0;
            transformedObject["Idle Time"] = item.idelTime
              ? helper.secondsToHms(item.idelTime)
              : 0;
            transformedObject["Productivity Percentage"] =
              item.productivity + " %";

            // transformedObject["Productivity Percentage"] =
            //   item.productivity + " %";
            transformedObject["Avg Talk Time"] = item.avgTalkTime
              ? helper.secondsToHms(item.avgTalkTime)
              : 0;
            transformedObject["Avg Disposition Time"] = item.avgDispoTime
              ? helper.secondsToHms(item.avgDispoTime)
              : 0;
            transformedObject["Avg Call Duration"] = item.avgCallDuration
              ? helper.secondsToHms(item.avgCallDuration)
              : 0;
            transformedObject["First Check In"] = item.fisrtLoginTime
              ? helper.EpochToDate(item.fisrtLoginTime)
              : "-";
            transformedObject["Last Check Out"] = item.lastLogOutTime
              ? helper.EpochToDate(item.lastLogOutTime)
              : "-";
            transformedArray.push(transformedObject);
          });
          // console.log(transformedArray);
          const uniqueKeys = new Set();
          // const insideUniqueKeys = new Set();

          transformedArray?.forEach((obj, index) => {
            // if (type !== "Repossessed") {
            if (obj !== null) {
              // console.log("index", index);
              Object.keys(obj).forEach((key) => {
                uniqueKeys.add(key);
              });
            }
          });

          let keys = Array.from(uniqueKeys);
          // console.log("others", keys);
          // response?.content?.forEach((obj, index) => {
          //   // if (type !== "Repossessed") {
          //   for (let i = 0; i < keys?.length; i++) {
          //     if (
          //       obj?.breakNameWithTimes !== null &&
          //       obj?.breakNameWithTimes[keys[i]] !== null
          //     ) {
          //       console.log("index", index);
          //       Object.keys(obj?.breakNameWithTimes[keys[i]]).forEach((key) => {
          //         insideUniqueKeys.add(key);
          //       });
          //     }
          //   }
          // });

          // let insideKeys = Array.from(insideUniqueKeys);
          // console.log("insideKeys", insideKeys);
          setKeys(keys);
          setSummaryCallReportList(transformedArray);
        } else if (
          reportCategory === "CallIntensityByEmployee" ||
          reportCategory === "CallIntensityByBranch"
        ) {
          setCallDispositionReportList([]);
          setDetailedCallReportList([]);
          setSummaryCallReportList([]);
          setPaymentStatusSummaryList([]);
          // const transformedArray = [];
          // response?.content?.forEach((item) => {
          //   const transformedObject = {};
          //   transformedObject["Emp Id"] = item.agentId;
          //   transformedObject["Emp Name"] = item.empName;
          //   transformedObject["Branch"] = item.branchName;
          //   transformedObject["State"] = item?.branchLocation;
          //   transformedObject["Allocation Count"] = item?.allocationCount;
          //   transformedObject["Not Attempted Count"] = item?.nonAttemptedCount;
          //   transformedObject["Paid"] = item?.paidCountFor0Calls;
          //   transformedObject["UnPaid"] = item?.unPaidCountFor0Calls;
          //   transformedObject["Paid"] = item?.paidCountFor1t03Calls;
          //   transformedObject["UnPaid"] = item?.unPaidCountFor1to3Calls;
          //   transformedObject["Paid"] = item?.paidCountFor4to10Calls;
          //   transformedObject["UnPaid"] = item?.unPaidCountFor4to10Calls;
          //   transformedObject["Paid"] = item?.paidCountFor11to25Calls;
          //   transformedObject["UnPaid"] = item?.unPaidCountFor11to25Calls;
          //   transformedObject["Paid"] = item?.paidCountForAbove25Calls;
          //   transformedObject["UnPaid"] = item?.unPaidCountForAbove25Calls;
          //   transformedObject["Total Count"] = item?.totalCount;
          //   transformedArray.push(transformedObject);
          // });
          // console.log(transformedArray);
          // if (reportCategory === "CallIntensityByEmployee") {
          //   for (let i = 0; i < transformedArray?.length; i++) {
          //     delete transformedArray[i]["Branch"];
          //     delete transformedArray[i]["State"];
          //   }
          // } else if (reportCategory === "CallIntensityByBranch") {
          //   for (let i = 0; i < transformedArray?.length; i++) {
          //     delete transformedArray[i]["Emp Id"];
          //     delete transformedArray[i]["Emp Name"];
          //   }
          // }
          // const uniqueKeys = new Set();
          // // const insideUniqueKeys = new Set();

          // transformedArray?.forEach((obj, index) => {
          //   // if (type !== "Repossessed") {
          //   if (obj !== null) {
          //     console.log("index", index);
          //     Object.keys(obj).forEach((key) => {
          //       uniqueKeys.add(key);
          //     });
          //   }
          // });

          // let keys = Array.from(uniqueKeys);
          // setIntensityKeys(keys);
          setCallIntensityReportList(response?.content);
        } else {
          setSummaryCallReportList([]);
          setDetailedCallReportList([]);
          const transformedArray = [];
          response?.content?.forEach((item) => {
            const transformedObject = {};
            transformedObject["Date"] = item?.date
              ? helper.EpochToOnlyDate(item?.date)
              : "-";
            transformedObject["Loan Number"] = item?.loanNumber;
            transformedObject["Emp Id"] = item?.employeeId;
            transformedObject["Emp Name"] = item?.empName;
            transformedObject["Emp Status"] = item?.empStatus;
            transformedObject[
              selectedCallsType === ""
                ? "Total Calls"
                : selectedCallsType === "Outgoing Calls"
                ? "Total Outgoing Calls"
                : selectedCallsType === "Incoming Calls" &&
                  "Total Incoming Calls"
            ] =
              item?.noDisposition +
              item?.callConnected +
              item?.callNotConnected;
            transformedObject["Incoming Calls"] = item?.incomingCallsCount;
            transformedObject["Outgoing Calls"] = item?.outGoingCallsCount;
            transformedObject["Disposition Not Entered"] = item?.noDisposition;
            transformedObject["Call Connected"] = item?.callConnected;
            transformedObject["Call Not Connected"] = item?.callNotConnected;
            // Iterate through the nested objects dynamically
            Object.keys(item?.statusCounts).forEach((statusType) => {
              Object.keys(item?.statusCounts[statusType]).forEach((status) => {
                transformedObject[status] = item?.statusCounts[statusType][
                  status
                ]
                  ? item?.statusCounts[statusType][status]
                  : 0;
              });
            });

            transformedArray.push(transformedObject);
          });
          if (reportCategory === "CallDipositionSummary") {
            for (let i = 0; i < transformedArray?.length; i++) {
              delete transformedArray[i]["Loan Number"];
              delete transformedArray[i]["Emp Id"];
              delete transformedArray[i]["Emp Name"];
              delete transformedArray[i]["Emp Status"];
              // if (selectedCallsType !== "") {
              delete transformedArray[i]["Incoming Calls"];
              delete transformedArray[i]["Outgoing Calls"];
              // }
            }
          } else if (reportCategory === "CallDispositionSummaryByLoanNumber") {
            for (let i = 0; i < transformedArray?.length; i++) {
              delete transformedArray[i]["Date"];
              delete transformedArray[i]["Emp Id"];
              delete transformedArray[i]["Emp Name"];
              delete transformedArray[i]["Emp Status"];
            }
          } else if (reportCategory === "CallDispositionSummaryByAgent") {
            for (let i = 0; i < transformedArray?.length; i++) {
              delete transformedArray[i]["Date"];
              delete transformedArray[i]["Loan Number"];
              // if (selectedCallsType !== "") {
              delete transformedArray[i]["Incoming Calls"];
              delete transformedArray[i]["Outgoing Calls"];
              // }
            }
          }
          // console.log(transformedArray);
          const uniqueKeys = new Set();

          transformedArray?.forEach((obj, index) => {
            if (obj !== null) {
              // console.log("index", index);
              Object.keys(obj).forEach((key) => {
                uniqueKeys.add(key);
              });
            }
          });

          let keys = Array.from(uniqueKeys);
          setDispositionKeys(keys);
          setCallDispositionReportList(transformedArray);
        }

        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  function setCustomDates(dateStrings) {
    if (!dateStrings[1]) return;
    setStart(Math.floor(new Date(dateStrings[0]).getTime() / 1000));
    setEnd(Math.floor(new Date(dateStrings[1]).getTime() / 1000) + 86399);
  }
  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStart2(start);
    setEnd2(end);

    //   let start = ;
    // let end = Math.floor(new Date(dateStrings[1]).getTime() / 1000 + 86400);
  };

  const onPageChange = (selectedPage) => {
    onSubmit(start, end, selectedPage - 1);
  };

  const callReportDownloadData = (start, end) => {
    if (isDateFilter === false) {
      if (!start || !end) {
        swal("Please Select Date Range", {
          icon: "error",
          dangerMode: true,
        });
        return;
      }
    } else {
      if (!start2 || !end2) {
        swal("Please Select Month", {
          icon: "error",
          dangerMode: true,
        });
        return;
      }
    }
    setIsLoading(true);
    setIsDetailedReport(reportCategory);

    const payLoad = {
      endtime: isDateFilter === false ? end : end2,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportCategory: "DETAILED",
      reportingOfficerId: !isAdmin()
        ? userLoginDetails.autoFinanceUser.userId
        : "",
      reportType: reportCategory,
      starttime: isDateFilter === false ? start : start2,
      searchKey: enteredLoanNumber,
      callsType: selectedCallsType,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.reports, 0, 10), payLoad)
      .then((response) => {
        if (response) {
          if (response?.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getCallReportFullResponseData(
                  Math.floor(response.totalElements / 200)
                );
              }
            });
          }

          getCallReportFullResponseData(
            Math.floor(response.totalElements / 200)
          );
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getCallReportFullResponseData(size) {
    var tempList = [];
    var detailedListData = [];
    var paymentStatusSummaryListData = [];
    var intensityListData = [];
    for (let i = 0; i <= size; i++) {
      const payLoad = {
        endtime: isDateFilter === false ? end : end2,
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        reportCategory: "DETAILED",
        reportingOfficerId: !isAdmin()
          ? userLoginDetails.autoFinanceUser.userId
          : "",
        reportType: reportCategory,
        starttime: isDateFilter === false ? start : start2,
        searchKey: enteredLoanNumber,
        callsType: selectedCallsType,
      };
      await moduleApi
        .postData(moduleApi.withPage(apiCall.reports, i, 200), payLoad)
        .then((response) => {
          if (reportCategory === "CallReport") {
            tempList.push(...response?.content);
          } else {
            tempList.push(...response?.content);
          }
        });
    }

    // console.log("templist", tempList);
    if (reportCategory === "CallReport") {
      tempList?.forEach((element) => {
        let data = {
          agentId: element.autoFinanceUser?.employeeId
            ? element.autoFinanceUser?.employeeId
            : "-",
          agentName: element.autoFinanceUser?.name
            ? element.autoFinanceUser?.name
            : "-",
          agentStatus: element.autoFinanceUser?.status
            ? element.autoFinanceUser?.status
            : "-",
          allocationId: element.allocationId ? element.allocationId : "-",
          allocationStatus: element.allocationStatus
            ? element.allocationStatus
            : "-",
          amountPaid: element.amountPaid ? element.amountPaid : 0,
          branchName: element.branchName ? element.branchName : "-",
          callDuration: element.callDuration
            ? helper.secondsToHms2(element.callDuration)
            : "-",
          callId: element.callId ? element.callId : "-",
          callIntiatedTime: element.createdTime
            ? helper.EpochToDate(element.createdTime)
            : "-",
          cpName: element.cpName ? element.cpName : "-",
          campaignName: element.campaignName ? element.campaignName : "-",
          createdAt: element.createdTime ? element.createdTime : "-",
          currentDue: element.currentDue ? element.currentDue : "-",
          dueAmount: element.dueAmount ? element.dueAmount : "-",
          customerName: element.customerName ? element.customerName : "-",
          id: element.id ? element.id : "-",
          image: element?.imageUploaded === true ? element.image : "-",
          direction: element.callDirection
            ? element.callDirection === "outbound"
              ? "OutBound"
              : "InBound"
            : "-",
          hangup_reason: element?.hangUpReason
            ? getHangUpReason(element?.hangUpReason)
            : "-",
          remarks: element?.remarks ? element?.remarks : "-",
          remarksType: element?.callStatus ? element?.callStatus : "-",
          transactionId: element?.transactionId ? element?.transactionId : "-",
          paymentTowards: element?.paymentTowards
            ? element?.paymentTowards
            : "-",
          paymentDate: element?.paymentDate
            ? helper.EpochToOnlyDate(element?.paymentDate)
            : "-",
          toBePaidBy: element?.toBePaidBy
            ? helper.EpochToOnlyDate(element?.toBePaidBy)
            : "-",
          ptpSlot: element?.ptpSlot ? element?.ptpSlot : "-",
          callBackDate: element?.callBackDate
            ? helper.EpochToOnlyDate(element?.callBackDate)
            : "-",
          callBackSlot: element?.callBackSlot ? element?.callBackSlot : "-",
          ptpAmount: element?.ptpAmount ? element?.ptpAmount : 0,
          paymentMode: element?.paymentMode ? element?.paymentMode : "-",
          ringTimetaken: element?.ringTimetaken
            ? helper.secondsToHms(element?.ringTimetaken)
            : 0,
          holdTimetaken: element?.holdTimetaken
            ? helper.secondsToHms(element?.holdTimetaken)
            : 0,
          talkTimetaken: element?.talkTimetaken
            ? helper.secondsToHms(element?.talkTimetaken)
            : 0,
          dispTimetaken: element?.dispTimetaken
            ? helper.secondsToHms(element?.dispTimetaken)
            : 0,

          lastVisitStatus:
            element?.callStatus === "Call Connected"
              ? element.status
                ? element.status
                : "-"
              : "-",
          loanNumber: element.loanNumber ? element.loanNumber : "-",
          organizationId: element.organizationId ? element.organizationId : "-",
          paymentLinkStatus: element.paymentLinkStatus === true ? "Yes" : "No",
          subStatus:
            element?.callStatus === "Call Connected"
              ? element.subStatus
                ? element.subStatus
                : "-"
              : "-",
          callStatus: element.callStatus ? element.callStatus : "-",
          teleCmiCdr: element.teleCmiCdr,
          teleCmiCallStatus:
            element?.callStatus === "Call Not Connected"
              ? element.subStatus
                ? element.subStatus
                : "-"
              : "-",
          teleCmiCdrId: element.teleCmiCdrId ? element.teleCmiCdrId : "-",
          toNumber: element.phoneNumber ? element.phoneNumber : "-",
          fromNumber: element.fromNumber ? element.fromNumber : "-",
          customerNumber: element.customerNumber ? element.customerNumber : "-",
          updatedAt: element.updatedAt
            ? helper.EpochToDate(element.updatedAt)
            : "-",
        };
        detailedListData.push(data);
      });
      setDetailedReportData(detailedListData);
    } else if (reportCategory === "CallAllocationReport") {
      tempList?.forEach((element) => {
        let data = {
          loanNumber: element?.loanNumber ? element?.loanNumber : "-",
          bucketName: element?.bucketName ? element?.bucketName : "-",
          cpName: element?.cpName ? element?.cpName : "-",
          pos: element?.pos ? element?.pos : 0,
          emiAmount: element?.emiAmount ? element?.emiAmount : 0,
          emiAmountPaid: element?.emiAmountPaid ? element?.emiAmountPaid : 0,
          state: element?.state ? element?.state : "-",
          branch: element?.branch ? element?.branch : "-",
          contactability: element?.contactability
            ? element?.contactability
            : "-",
          status: element?.status ? element?.status : "-",
          subStatus: element?.subStatus ? element?.subStatus : "-",
          remarks: element?.remarks ? element?.remarks : "-",
          paymentStatus: element?.paymentStatus ? element?.paymentStatus : "-",
          lenderPaymentStatus: element?.lenderPaymentStatus
            ? element?.lenderPaymentStatus
            : "-",
          intensity: element?.intensity ? element?.intensity : 0,
          paidCount: element?.paidCount ? element?.paidCount : 0,
          paidResolution: element?.paidResolution ? element?.paidResolution : 0,
        };
        paymentStatusSummaryListData.push(data);
      });
      setPaymentStatusSummaryReportData(paymentStatusSummaryListData);
    } else if (
      reportCategory === "CallIntensityByEmployee" ||
      reportCategory === "CallIntensityByBranch"
    ) {
      tempList?.forEach((element) => {
        let data = {
          allocationCount: element.allocationCount
            ? element.allocationCount
            : 0,
          branchId: element.branchId ? element.branchId : "-",
          branchLocation: element.branchLocation ? element.branchLocation : "-",
          branchName: element.branchName ? element.branchName : "-",
          empId: element.empId ? element.empId : "-",
          empName: element.empName ? element.empName : "-",
          nonAttemptedCount: element.nonAttemptedCount
            ? element.nonAttemptedCount
            : 0,
          paidCountFor0Calls: element.paidCountFor0Calls
            ? element.paidCountFor0Calls
            : 0,
          paidCountFor1t03Calls: element.paidCountFor1t03Calls
            ? element.paidCountFor1t03Calls
            : 0,
          paidCountFor4to10Calls: element.paidCountFor4to10Calls
            ? element.paidCountFor4to10Calls
            : 0,
          paidCountFor11to25Calls: element.paidCountFor11to25Calls
            ? element.paidCountFor11to25Calls
            : 0,
          paidCountForAbove25Calls: element.paidCountForAbove25Calls
            ? element.paidCountForAbove25Calls
            : 0,
          totalCount: element.totalCount ? element.totalCount : 0,
          totalCalls: element.totalCalls ? element.totalCalls : 0,
          unPaidCountFor0Calls: element.unPaidCountFor0Calls
            ? element.unPaidCountFor0Calls
            : 0,
          unPaidCountFor1to3Calls: element.unPaidCountFor1to3Calls
            ? element.unPaidCountFor1to3Calls
            : 0,
          unPaidCountFor4to10Calls: element.unPaidCountFor4to10Calls
            ? element.unPaidCountFor4to10Calls
            : 0,
          unPaidCountFor11to25Calls: element.unPaidCountFor11to25Calls
            ? element.unPaidCountFor11to25Calls
            : 0,
          unPaidCountForAbove25Calls: element.unPaidCountForAbove25Calls
            ? element.unPaidCountForAbove25Calls
            : 0,
          userId: element.userId ? element.userId : "-",
        };
        intensityListData.push(data);
      });
      setIntensityReportData(intensityListData);
    } else if (reportCategory === "CallReportSummaryByDay") {
      const transformedArray = [];
      tempList?.forEach((item) => {
        const transformedObject = {};
        transformedObject["Emp Id"] = item.agentId;
        transformedObject["Emp Name"] = item.empName;
        transformedObject["Emp Status"] = item.empStatus;
        transformedObject["Date"] = item?.date
          ? helper.EpochToOnlyDate(item?.date)
          : "-";
        transformedObject["Total Login"] = item.totalLoginTime
          ? helper.secondsToHms(item.totalLoginTime)
          : 0;

        // Iterate through the nested objects dynamically
        Object.keys(item.breakNameWithTimes).forEach((breakCategory) => {
          Object.keys(item.breakNameWithTimes[breakCategory]).forEach(
            (breakName) => {
              transformedObject[breakName] = item.breakNameWithTimes[
                breakCategory
              ][breakName]
                ? helper.secondsToHms(
                    item.breakNameWithTimes[breakCategory][breakName]
                  )
                : 0;
            }
          );
        });
        // transformedObject["Dailing Time"] = item.dailingTime
        //   ? helper.secondsToHms(item.dailingTime)
        //   : 0;
        transformedObject["Ringing Time"] = item.ringingTime
          ? helper.secondsToHms(item.ringingTime)
          : 0;
        transformedObject["Talk Time"] = item.talkTime
          ? helper.secondsToHms(item.talkTime)
          : 0;
        transformedObject["Hold Time"] = item.holdTime
          ? helper.secondsToHms(item.holdTime)
          : 0;
        transformedObject["Disposition Time"] = item.dispoTime
          ? helper.secondsToHms(item.dispoTime)
          : 0;
        transformedObject["Total Productive Time"] = item.totalProdTime
          ? helper.secondsToHms(item.totalProdTime)
          : 0;
        transformedObject["Idle Time"] = item.idelTime
          ? helper.secondsToHms(item.idelTime)
          : 0;
        transformedObject["Avg Talk Time"] = item.avgTalkTime
          ? helper.secondsToHms(item.avgTalkTime)
          : 0;
        transformedObject["Avg Disposition Time"] = item.avgDispoTime
          ? helper.secondsToHms(item.avgDispoTime)
          : 0;
        transformedObject["Avg Call Duration"] = item.avgCallDuration
          ? helper.secondsToHms(item.avgCallDuration)
          : 0;
        transformedObject["First Check In"] = item.fisrtLoginTime
          ? helper.EpochToDate(item.fisrtLoginTime)
          : "-";
        transformedObject["Last Check Out"] = item.lastLogOutTime
          ? helper.EpochToDate(item.lastLogOutTime)
          : "-";
        // transformedObject["Productivity"] = item.productivity + " %";
        transformedObject["Productivity Percentage"] = item.productivity + " %";

        transformedArray.push(transformedObject);
      });
      // console.log(transformedArray);
      // const uniqueKeys = new Set();

      // transformedArray?.forEach((obj, index) => {
      //   if (obj !== null) {
      //     console.log("index", index);
      //     Object.keys(obj).forEach((key) => {
      //       uniqueKeys.add(key);
      //     });
      //   }
      // });

      // let keys = Array.from(uniqueKeys);

      // setKeys(keys);
      setSummaryReportData(transformedArray);
    } else {
      const transformedArray = [];
      tempList?.forEach((item) => {
        const transformedObject = {};
        transformedObject["Date"] = item?.date
          ? helper.EpochToOnlyDate(item?.date)
          : "-";
        transformedObject["Loan Number"] = item?.loanNumber;
        transformedObject["Emp Id"] = item?.employeeId;
        transformedObject["Emp Name"] = item?.empName;
        transformedObject["Emp Status"] = item?.empStatus;
        transformedObject[
          selectedCallsType === ""
            ? "Total Calls"
            : selectedCallsType === "Outgoing Calls"
            ? "Total Outgoing Calls"
            : selectedCallsType === "Incoming Calls" && "Total Incoming Calls"
        ] = item?.noDisposition + item?.callConnected + item?.callNotConnected;
        transformedObject["Incoming Calls"] = item?.incomingCallsCount;
        transformedObject["Outgoing Calls"] = item?.outGoingCallsCount;
        transformedObject["Disposition Not Entered"] = item?.noDisposition;
        transformedObject["Call Connected"] = item?.callConnected;
        transformedObject["Call Not Connected"] = item?.callNotConnected;

        // Iterate through the nested objects dynamically
        Object.keys(item?.statusCounts).forEach((statusType) => {
          Object.keys(item?.statusCounts[statusType]).forEach((status) => {
            transformedObject[status] = item?.statusCounts[statusType][status]
              ? item?.statusCounts[statusType][status]
              : 0;
          });
        });

        transformedArray.push(transformedObject);
      });
      if (reportCategory === "CallDipositionSummary") {
        for (let i = 0; i < transformedArray?.length; i++) {
          delete transformedArray[i]["Loan Number"];
          delete transformedArray[i]["Emp Id"];
          delete transformedArray[i]["Emp Name"];
          delete transformedArray[i]["Emp Status"];
          // if (selectedCallsType !== "") {
          delete transformedArray[i]["Incoming Calls"];
          delete transformedArray[i]["Outgoing Calls"];
          // }
        }
      } else if (reportCategory === "CallDispositionSummaryByLoanNumber") {
        for (let i = 0; i < transformedArray?.length; i++) {
          delete transformedArray[i]["Date"];
          delete transformedArray[i]["Emp Id"];
          delete transformedArray[i]["Emp Name"];
          delete transformedArray[i]["Emp Status"];
        }
      } else if (reportCategory === "CallDispositionSummaryByAgent") {
        for (let i = 0; i < transformedArray?.length; i++) {
          delete transformedArray[i]["Date"];
          delete transformedArray[i]["Loan Number"];
          // if (selectedCallsType !== "") {
          delete transformedArray[i]["Incoming Calls"];
          delete transformedArray[i]["Outgoing Calls"];
          // }
        }
      }
      // console.log(transformedArray);
      // const uniqueKeys = new Set();

      // transformedArray?.forEach((obj, index) => {
      //   if (obj !== null) {
      //     console.log("index", index);
      //     Object.keys(obj).forEach((key) => {
      //       uniqueKeys.add(key);
      //     });
      //   }
      // });

      // let keys = Array.from(uniqueKeys);

      // setDispositionKeys(keys);
      setDispositionReportData(transformedArray);
    }
  }

  const handleExport = () => {
    let detailedTable = [
      {
        A: "Emp ID",
        B: "Emp Name",
        C: "Emp Status",
        D: "CP Name",
        E: "Campaign",
        F: "Branch",
        G: "Loan Number",
        H: "Customer Name",
        I: "Customer Number",
        J: "From Number",
        K: "To Number",
        L: "Call Direction",
        M: "Call Status",
        N: "Call Sub Status",
        O: "Call Initiated At",
        P: "Call Duration",
        Q: "Ringing Time",
        R: "Talk Time",
        S: "Hold Time",
        T: "Diposition Time",
        U: "Hang Up Reason",
        V: "Remarks",
        W: "Amount Paid",
        X: "Current Due",
        Y: "Total Due",
        Z: "Payment Link Sent Status",
        AA: "Allocation Status",
        AB: "Status",
        AC: "Sub Status",
        AD: "Payment Mode",
        AE: "Payment Towards",
        AF: "Payment Date",
        AG: "Transaction Id",
        AH: "Promised Payment Date",
        AI: "Promised Time Slot",
        AJ: "Call Back Date",
        AK: "Call Back Time Slot",
        AL: "PTP Amount",
        AM: "Payment Receipt",
      },
    ];
    let callIntensityTable = [
      {
        A:
          reportCategory === "CallIntensityByEmployee"
            ? "Emp ID"
            : reportCategory === "CallIntensityByBranch"
            ? "Branch"
            : "",
        B:
          reportCategory === "CallIntensityByEmployee"
            ? "Emp Name"
            : reportCategory === "CallIntensityByBranch"
            ? "State"
            : "",
        C: "Allocation Count",
        D: "Not Attempted Count",
        E: "Total Calls",
        F: "0 Paid Count",
        G: "0 UnPaid Count",
        H: "1 to 3 Paid Count",
        I: "1 to 3 UnPaid Count",
        J: "4 to 10 Paid Count",
        K: "4 to 10 UnPaid Count",
        L: "11 to 25 Paid Count",
        M: "11 to 25 UnPaid Count",
        N: "25+ Paid Count",
        O: "25+ UnPaid Count",
        P: "Total Count",
      },
    ];
    let paymentStatusSummaryTable = [
      {
        A: "Loan Number",
        B: "CP Name",
        C: "Current Bucket",
        D: "POS",
        E: "EMI Amount",
        F: "State",
        G: "Branch",
        H: "Contactibility",
        I: "Dispostion Status",
        J: "Dispostion Sub Status",
        K: "Remarks",
        L: "Intensity",
        M:
          userLoginDetails?.autoFinanceOrganization?.orgshortcode +
          " Payment Status",
        N: "Lender Payment Status",
        O: "EMI Amount Paid",
        P: "Paid Count",
        Q: "POS Resolution",
      },
    ];

    if (reportCategory === "CallReport") {
      detailedReportData?.forEach((row) => {
        detailedTable.push({
          A: row.agentId,
          B: row.agentName,
          C: row.agentStatus,
          D: row.cpName,
          E: row.campaignName,
          F: row.branchName,
          G: row.loanNumber,
          H: row.customerName,
          I: row.customerNumber,
          J: row.fromNumber,
          K: row.toNumber,
          L: row.direction,
          M: row.callStatus,
          N: row.teleCmiCallStatus,
          O: row.callIntiatedTime,
          P: row.callDuration,
          Q: row.ringTimetaken,
          R: row.talkTimetaken,
          S: row.holdTimetaken,
          T: row.dispTimetaken,
          U: row.hangup_reason,
          V: row.remarks,
          W: row.amountPaid,
          X: row.currentDue,
          Y: row.dueAmount,
          Z: row.paymentLinkStatus,
          AA: row.allocationStatus,
          AB: row.lastVisitStatus,
          AC: row.subStatus,
          AD: row.paymentMode,
          AE: row.paymentTowards,
          AF: row.paymentDate,
          AG: row.transactionId,
          AH: row.toBePaidBy,
          AI: row.ptpSlot,
          AJ: row.callBackDate,
          AK: row.callBackSlot,
          AL: row.ptpAmount,
          AM: row.image,
        });
      });
    }
    if (
      reportCategory === "CallIntensityByEmployee" ||
      reportCategory === "CallIntensityByBranch"
    ) {
      intensityReportData?.forEach((row) => {
        callIntensityTable.push({
          A:
            reportCategory === "CallIntensityByEmployee"
              ? row.empId
              : reportCategory === "CallIntensityByBranch"
              ? row.branchName
              : "",
          B:
            reportCategory === "CallIntensityByEmployee"
              ? row.empName
              : reportCategory === "CallIntensityByBranch"
              ? row.branchLocation
              : "",
          C: row.allocationCount,
          D: row.nonAttemptedCount,
          E: row.totalCalls,
          F: row.paidCountFor0Calls,
          G: row.unPaidCountFor0Calls,
          H: row.paidCountFor1t03Calls,
          I: row.unPaidCountFor1to3Calls,
          J: row.paidCountFor4to10Calls,
          K: row.unPaidCountFor4to10Calls,
          L: row.paidCountFor11to25Calls,
          M: row.unPaidCountFor11to25Calls,
          N: row.paidCountForAbove25Calls,
          O: row.unPaidCountForAbove25Calls,
          P: row.totalCount,
        });
      });
    }

    if (reportCategory === "CallAllocationReport") {
      paymentStatusSummaryReportData?.forEach((row) => {
        paymentStatusSummaryTable.push({
          A: row.loanNumber,
          B: row.cpName,
          C: row.bucketName,
          D: row.pos,
          E: row.emiAmount,
          F: row.state,
          G: row.branch,
          H: row.contactability,
          I: row.status,
          J: row.subStatus,
          K: row.remarks,
          L: row.intensity,
          M: row.paymentStatus,
          N: row.lenderPaymentStatus,
          O: row.emiAmountPaid,
          P: row.paidCount,
          Q: row.paidResolution,
        });
      });
    }

    const finalData =
      reportCategory === "CallReport"
        ? [...detailedTable]
        : reportCategory === "CallAllocationReport"
        ? [...paymentStatusSummaryTable]
        : reportCategory === "CallIntensityByEmployee" ||
          reportCategory === "CallIntensityByBranch"
        ? [...callIntensityTable]
        : reportCategory === "CallReportSummaryByDay"
        ? summaryReportData
        : dispositionReportData;

    // console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet =
      reportCategory === "CallReport" ||
      reportCategory === "CallAllocationReport" ||
      reportCategory === "CallIntensityByBranch" ||
      reportCategory === "CallIntensityByEmployee"
        ? XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
          })
        : XLSX.utils.json_to_sheet(finalData);

    // console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Call_Report");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Clu_" +
        (reportCategory === "CallReport"
          ? "Detailed"
          : reportCategory === "CallAllocationReport"
          ? "Payment_Status_Summary"
          : reportCategory === "CallReportSummaryByDay"
          ? "Agent_Summary_By_Day"
          : reportCategory === "CallDipositionSummary"
          ? "Call_Disposition_Summary"
          : reportCategory === "CallIntensityByEmployee"
          ? "Call_Intensity_By_Employee"
          : reportCategory === "CallIntensityByBranch"
          ? "Call_Intensity_By_Branch"
          : reportCategory === "CallDispositionSummaryByLoanNumber"
          ? "Call_Disposition_Summary_By_Loan_No"
          : "Call_Disposition_Summary_By_Emp") +
        "_Call_Report_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category:
        (reportCategory === "CallReport"
          ? "Detailed"
          : reportCategory === "CallAllocationReport"
          ? "Payment_Status_Summary"
          : reportCategory === "CallReportSummaryByDay"
          ? "Agent_Summary_By_Day"
          : reportCategory === "CallDipositionSummary"
          ? "Call_Disposition_Summary"
          : reportCategory === "CallIntensityByEmployee"
          ? "Call_Intensity_By_Employee"
          : reportCategory === "CallIntensityByBranch"
          ? "Call_Intensity_By_Branch"
          : reportCategory === "CallDispositionSummaryByLoanNumber"
          ? "Call_Disposition_Summary_By_Loan_No"
          : "Call_Disposition_Summary_By_Emp") + " Call Report Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_" +
        (reportCategory === "CallReport"
          ? "Detailed"
          : reportCategory === "CallReportSummaryByDay"
          ? "Agent_Summary_By_Day"
          : reportCategory === "CallDipositionSummary"
          ? "Call_Disposition_Summary"
          : reportCategory === "CallDispositionSummaryByLoanNumber"
          ? "Call_Disposition_Summary_By_Loan_No"
          : "Call_Disposition_Summary_By_Emp") +
        "_Call_Report_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const getHangUpReason = (value) => {
    if (value === "sent_reject") {
      return "Receiver Rejected";
    } else if (value === "recv_cancel") {
      return "Caller Rejected";
    } else if (value === "sent_bye") {
      return "Receiver Hung Up";
    } else if (value === "recv_bye") {
      return "Caller Hung Up";
    }
  };

  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: true,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const handleDownload = (mp3FileUrl, fileName) => {
    setIsLoading(true);
    // Create an XHR request to fetch the audio file
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element and trigger a click to start the download
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName + ".mp3"; // You can customize the downloaded file name
      document.body.appendChild(a);
      a.click();
      // Clean up the temporary link and revoke the object URL to release resources
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    xhr.open("GET", mp3FileUrl);
    xhr.send();
    setIsLoading(false);
    ReactGA.event({
      category: "Call Audio Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_" +
        fileName +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  // const sendNotificationToPtpPage = () => {
  //   history.push("/ptp/my-allocations");
  // };

  return (
    <div className="card">
      <div style={{ marginTop: "10px" }}>
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            {(reportCategory === "CallReport" ||
              reportCategory === "CallDispositionSummaryByLoanNumber") && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    marginLeft: "10px",
                    marginBottom: "0px",
                    fontWeight: "600",
                    marginTop: "5px",
                  }}
                >
                  Loan Number
                </label>
                <input
                  style={{
                    width: "230px",
                    height: "35px",
                    borderRadius: "25px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    paddingLeft: "10px",
                    marginRight: "10px",
                    marginTop: "5px",
                  }}
                  placeholder="Enter Loan Number"
                  onChange={(e) => {
                    setEnteredLoanNumber(e.target.value.trim());
                    // console.log("loanNumber", enteredLoanNumber);
                  }}
                />
              </div>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  marginLeft: "10px",
                  marginBottom: "0px",
                  fontWeight: "600",
                  marginTop: "5px",
                }}
              >
                Report Type
              </label>
              <select
                name="cars"
                id="reporttype"
                style={{
                  backgroundColor: "white",
                  height: "40px",
                  width: "250px",
                  color: "rgb(115 115 115)",
                  border: "solid",
                  textAlign: "center",
                  borderRadius: "3px",
                  margin: "5px 5px ",
                  borderWidth: "1px",
                  //   paddingRight:'10px',
                  boxShadow: "1px 1px 2px #797979",
                }}
                onChange={(e) => {
                  setReportCategory(e.target.value);
                  if (
                    e.target.value !== "CallReport" &&
                    e.target.value !== "CallDispositionSummaryByLoanNumber"
                  ) {
                    setEnteredLoanNumber(null);
                  }
                  if (
                    e.target.value === "CallIntensityByEmployee" ||
                    e.target.value === "CallIntensityByBranch"
                  ) {
                    setIsDateFilter(true);
                    setStart(0);
                    setEnd(0);
                  } else {
                    setIsDateFilter(false);
                    setStart2(0);
                    setEnd2(0);
                  }
                }}
              >
                <option value="CallReport" label="Detailed" />
                <option
                  value="CallReportSummaryByDay"
                  label="Agent Summary By Day"
                />
                <option
                  value="CallDipositionSummary"
                  label="Call Disposition Summary"
                />
                <option
                  value="CallDispositionSummaryByLoanNumber"
                  label="Disposition Summary By Loan No"
                />
                <option
                  value="CallDispositionSummaryByAgent"
                  label="Disposition Summary By Agent"
                />
                <option
                  value="CallAllocationReport"
                  label="Payment Status Summary"
                />
                <option
                  value="CallIntensityByEmployee"
                  label="Call Intensity By Employee"
                />
                <option
                  value="CallIntensityByBranch"
                  label="Call Intensity By Branch"
                />
              </select>
            </div>

            {hasAccess(116) &&
              (reportCategory === "CallDipositionSummary" ||
                reportCategory === "CallDispositionSummaryByAgent") && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{
                      marginLeft: "10px",
                      marginBottom: "0px",
                      fontWeight: "600",
                      marginTop: "5px",
                    }}
                  >
                    Calls Type
                  </label>
                  <select
                    id="callstype"
                    style={{
                      backgroundColor: "white",
                      height: "40px",
                      width: "150px",
                      color: "rgb(115 115 115)",
                      border: "solid",
                      textAlign: "center",
                      borderRadius: "3px",
                      margin: "5px 5px ",
                      borderWidth: "1px",
                      //   paddingRight:'10px',
                      boxShadow: "1px 1px 2px #797979",
                    }}
                    onChange={(e) => {
                      setSelectedCallsType(e.target.value);
                    }}
                  >
                    <option value="" label="All" />
                    <option value="Incoming Calls" label="Incoming Calls" />
                    <option value="Outgoing Calls" label="Outgoing Calls" />
                  </select>
                </div>
              )}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  marginLeft: "10px",
                  marginBottom: "0px",
                  fontWeight: "600",
                  marginTop: "5px",
                }}
              >
                {isDateFilter === false ? "Date Range" : "Month"}
              </label>
              {isDateFilter === false && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                  }}
                >
                  <Flatpickr
                    style={{
                      backgroundColor: "white",
                      height: "40px",
                      width: "160px",
                      textAlign: "center",
                      color: "rgb(115 115 115)",
                      border: "solid",
                      borderRadius: "3px",
                      margin: "5px 5px ",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                    // placeholder="dd M,yyyy"
                    placeholder="Pick Custom Date"
                    onChange={(value) => {
                      setCustomDates(value);
                    }}
                    options={{
                      mode: "range",
                      dateFormat: "d-M-y",
                      maxDate: new Date(),
                      minDate: new Date().setMonth(new Date().getMonth() - 6),
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "flex-end",
                    }}
                  ></div>
                </div>
              )}
              {isDateFilter === true && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                  }}
                >
                  <Flatpickr
                    className="form-control d-block"
                    style={{
                      width: "180px",
                      height: "40px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      textAlignLast: "center",
                      borderRadius: "5px",
                      marginTop: "5px",
                    }}
                    placeholder="Select Month"
                    onChange={(value) => {
                      setCustomDatesForGraph(value);
                    }}
                    options={{
                      // mode: "range",
                      // dateFormat: "d-M-y",
                      // defaultDate: new Date(),
                      maxDate: new Date(),
                      minDate: new Date().setMonth(new Date().getMonth() - 6),

                      plugins: [
                        new monthSelectPlugin({
                          shorthand: true, //defaults to false
                          dateFormat: "F Y", //defaults to "F Y"
                          altFormat: "F Y", //defaults to "F Y"
                          theme: "light", // defaults to "light"
                        }),
                      ],
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-end",
              }}
            >
              <Button
                style={{
                  margin: "5px 5px ",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => onSubmit(start, end, 0)}
                id="submitbtnreports"
              >
                Submit
              </Button>
            </div>

            {/* {hasAccess(21) && ( */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-end",
              }}
            >
              <Button
                style={{
                  margin: "5px 5px ",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => {
                  //  onSubmit(startDate, endDate, 0, false)
                  callReportDownloadData(start, end);
                }}
              >
                <i className="fa fa-download"></i>
                &nbsp;&nbsp;Download
              </Button>
            </div>
            {/* )} */}

            {/* )} */}
          </div>
          {/* <button
            onClick={() => {
              CustomToaster(
                "dark",
                "Testing",
                "This is Test Message",
                sendNotificationToPtpPage
              );
            }}
          >
            click
          </button> */}
          <div style={{ margin: "0px 20px" }}>
            <Table responsive hover bordered>
              <thead
                style={{
                  backgroundColor: "#F3F0F9",
                  top: "1px",
                  position: "sticky",
                }}
              >
                {isDetailedReport === "CallReport" ? (
                  <tr
                    style={{
                      verticalAlign: "middle",
                      textAlignLast: "center",
                    }}
                  >
                    <th>Emp Id</th>
                    <th>Emp Name</th>
                    <th>Emp Status</th>
                    <th>CP Name</th>
                    <th>Campaign</th>
                    <th>Branch</th>
                    <th>Loan Number</th>
                    <th>Customer Name</th>

                    <th>Customer Number</th>

                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>From</Col>
                        <Col style={{ textAlignLast: "center" }}>Number</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>To</Col>
                        <Col style={{ textAlignLast: "center" }}>Number</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Call</Col>
                        <Col style={{ textAlignLast: "center" }}>Direction</Col>
                      </Row>
                    </th>

                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Call</Col>
                        <Col style={{ textAlignLast: "center" }}>Status</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Call</Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Sub Status
                        </Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Call</Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Initiated At
                        </Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Call</Col>
                        <Col style={{ textAlignLast: "center" }}>Duration</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Ringing</Col>
                        <Col style={{ textAlignLast: "center" }}>Time</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Talk</Col>
                        <Col style={{ textAlignLast: "center" }}>Time</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Hold</Col>
                        <Col style={{ textAlignLast: "center" }}>Time</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Disposition
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>Time</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>HangUp</Col>
                        <Col style={{ textAlignLast: "center" }}>Reason</Col>
                      </Row>
                    </th>
                    <th>Remarks</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Amount</Col>
                        <Col style={{ textAlignLast: "center" }}>Paid</Col>
                      </Row>
                    </th>
                    <th>Current Due</th>
                    <th>Total Due</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Payment Link
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Sent Status
                        </Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Allocation
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>Status</Col>
                      </Row>
                    </th>
                    <th>Status</th>
                    <th>Sub Status</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Payment</Col>
                        <Col style={{ textAlignLast: "center" }}>Mode</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Payment</Col>
                        <Col style={{ textAlignLast: "center" }}>Towards</Col>
                      </Row>
                    </th>

                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Payment</Col>
                        <Col style={{ textAlignLast: "center" }}>Date</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Transaction
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>Id</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Promised</Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Payment Date
                        </Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Promised</Col>
                        <Col style={{ textAlignLast: "center" }}>Time Slot</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Call Back</Col>
                        <Col style={{ textAlignLast: "center" }}>Date</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Call Back</Col>
                        <Col style={{ textAlignLast: "center" }}>Time Slot</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>PTP</Col>
                        <Col style={{ textAlignLast: "center" }}>Amount</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Payment</Col>
                        <Col style={{ textAlignLast: "center" }}>Receipt</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Play</Col>
                        <Col style={{ textAlignLast: "center" }}>Audio</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Download</Col>
                        <Col style={{ textAlignLast: "center" }}>Audio</Col>
                      </Row>
                    </th>
                  </tr>
                ) : isDetailedReport === "CallAllocationReport" ? (
                  <tr
                    style={{
                      verticalAlign: "middle",
                      textAlignLast: "center",
                    }}
                  >
                    <th>Loan Number</th>
                    <th>CP Name</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Current</Col>
                        <Col style={{ textAlignLast: "center" }}>Bucket</Col>
                      </Row>
                    </th>
                    <th>POS</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>EMI</Col>
                        <Col style={{ textAlignLast: "center" }}>Amount</Col>
                      </Row>
                    </th>
                    <th>State</th>
                    <th>Branch</th>
                    <th>Contactibility</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Disposition
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>Status</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Disposition
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Sub Status
                        </Col>
                      </Row>
                    </th>
                    <th>Remarks</th>
                    <th>Intensity</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          {
                            userLoginDetails?.autoFinanceOrganization
                              ?.orgshortcode
                          }{" "}
                          Payment
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>Status</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Lender</Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Payment Status
                        </Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>EMI</Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Amount Paid
                        </Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Paid</Col>
                        <Col style={{ textAlignLast: "center" }}>Count</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>POS</Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Resolution
                        </Col>
                      </Row>
                    </th>
                  </tr>
                ) : isDetailedReport === "CallReportSummaryByDay" ? (
                  <tr style={{ textAlign: "center" }}>
                    {keys.map((key) => {
                      return <th>{key}</th>;
                    })}
                  </tr>
                ) : isDetailedReport === "CallIntensityByEmployee" ||
                  isDetailedReport === "CallIntensityByBranch" ? (
                  <>
                    <tr style={{ textAlign: "center" }}>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th colSpan="2">0</th>
                      <th colSpan="2">1 to 3</th>
                      <th colSpan="2">4 to 10</th>
                      <th colSpan="2">11 to 25</th>
                      <th colSpan="2">25+</th>
                      <th></th>
                    </tr>
                    <tr
                      style={{
                        verticalAlign: "middle",
                        textAlignLast: "center",
                      }}
                    >
                      <th>
                        {isDetailedReport === "CallIntensityByEmployee"
                          ? "Emp Id"
                          : "Branch"}
                      </th>
                      <th>
                        {isDetailedReport === "CallIntensityByEmployee"
                          ? "Emp Name"
                          : "State"}
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Allocation
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Count</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Not Attempted
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Count</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Total</Col>
                          <Col style={{ textAlignLast: "center" }}>Calls</Col>
                        </Row>
                      </th>
                      <th>Paid</th>
                      <th>UnPaid</th>
                      <th>Paid</th>
                      <th>UnPaid</th>
                      <th>Paid</th>
                      <th>UnPaid</th>
                      <th>Paid</th>
                      <th>UnPaid</th>
                      <th>Paid</th>
                      <th>UnPaid</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Total</Col>
                          <Col style={{ textAlignLast: "center" }}>Count</Col>
                        </Row>
                      </th>
                    </tr>
                  </>
                ) : (
                  <tr style={{ textAlign: "center" }}>
                    {dispositionKeys.map((key) => {
                      return <th>{key}</th>;
                    })}
                  </tr>
                )}
              </thead>
              <tbody style={{ textAlignLast: "center" }}>
                {isDetailedReport === "CallReport"
                  ? detailedCallReportList?.map((ele) => {
                      return (
                        <tr style={{ textAlignLast: "center" }}>
                          <td>
                            {ele?.autoFinanceUser?.employeeId
                              ? ele?.autoFinanceUser?.employeeId
                              : "-"}
                          </td>
                          <td>
                            {ele?.autoFinanceUser?.name
                              ? ele?.autoFinanceUser?.name
                              : "-"}
                          </td>
                          <td>
                            {ele?.autoFinanceUser?.status
                              ? ele?.autoFinanceUser?.status
                              : "-"}
                          </td>
                          <td>{ele.cpName ? ele.cpName : "-"}</td>
                          <td>{ele.campaignName ? ele.campaignName : "-"}</td>
                          <td>{ele.branchName ? ele.branchName : "-"}</td>
                          <td>{ele.loanNumber ? ele.loanNumber : "-"}</td>
                          <td>{ele.customerName ? ele.customerName : "-"}</td>
                          <td>
                            {ele.customerNumber ? ele.customerNumber : "-"}
                          </td>
                          <td>{ele.fromNumber ? ele.fromNumber : "-"}</td>
                          <td>{ele.phoneNumber ? ele.phoneNumber : "-"}</td>
                          <td>
                            {ele?.callDirection
                              ? ele?.callDirection === "outbound"
                                ? "OutBound"
                                : "InBound"
                              : "-"}
                          </td>
                          <td>{ele?.callStatus ? ele?.callStatus : "-"}</td>
                          <td>
                            {ele?.callStatus === "Call Not Connected"
                              ? ele?.subStatus
                              : "-"}
                          </td>
                          <td>
                            {ele.createdTime
                              ? helper.EpochToDate(ele.createdTime)
                              : "-"}
                          </td>
                          <td>
                            {ele.callDuration
                              ? helper.secondsToHms2(ele.callDuration)
                              : "-"}
                          </td>
                          <td>
                            {ele?.ringTimetaken
                              ? helper.secondsToHms(ele?.ringTimetaken)
                              : 0}
                          </td>
                          <td>
                            {ele?.talkTimetaken
                              ? helper.secondsToHms(ele?.talkTimetaken)
                              : 0}
                          </td>
                          <td>
                            {ele?.holdTimetaken
                              ? helper.secondsToHms(ele?.holdTimetaken)
                              : 0}
                          </td>
                          <td>
                            {ele?.dispTimetaken
                              ? helper.secondsToHms(ele?.dispTimetaken)
                              : 0}
                          </td>
                          <td>
                            {ele?.hangUpReason
                              ? getHangUpReason(ele?.hangUpReason)
                              : "-"}
                          </td>
                          <td>{ele?.remarks ? ele?.remarks : "-"}</td>

                          <td>{ele.amountPaid ? ele.amountPaid : 0}</td>
                          <td>{ele.currentDue ? ele.currentDue : "-"}</td>
                          <td>{ele.dueAmount ? ele.dueAmount : "-"}</td>
                          <td>
                            {ele.paymentLinkStatus === true ? "Yes" : "No"}
                          </td>
                          <td>
                            {ele.allocationStatus ? ele.allocationStatus : "-"}
                          </td>
                          <td>
                            {ele?.callStatus === "Call Connected"
                              ? ele.status
                                ? ele.status
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {ele?.callStatus === "Call Connected"
                              ? ele.subStatus
                                ? ele.subStatus
                                : "-"
                              : "-"}
                          </td>
                          <td>{ele?.paymentMode ? ele?.paymentMode : "-"}</td>
                          <td>
                            {ele?.paymentTowards ? ele?.paymentTowards : "-"}
                          </td>
                          <td>
                            {ele?.paymentDate
                              ? helper.EpochToOnlyDate(ele?.paymentDate)
                              : "-"}
                          </td>
                          <td>
                            {ele?.transactionId ? ele?.transactionId : "-"}
                          </td>
                          <td>
                            {ele?.toBePaidBy
                              ? helper.EpochToOnlyDate(ele?.toBePaidBy)
                              : "-"}
                          </td>
                          <td>{ele?.ptpSlot ? ele?.ptpSlot : "-"}</td>
                          <td>
                            {ele?.callBackDate
                              ? helper.EpochToOnlyDate(ele?.callBackDate)
                              : "-"}
                          </td>
                          <td>{ele?.callBackSlot ? ele?.callBackSlot : "-"}</td>
                          <td>{ele?.ptpAmount ? ele?.ptpAmount : 0}</td>
                          <td>
                            {ele?.imageUploaded === true ? (
                              <span
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() => {
                                  setPaymentReceiptImage(ele?.image);
                                  setPaymentReceiptModal(true);
                                }}
                              >
                                view
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {ele.fileName !== null && ele.fileName !== "" ? (
                              <div
                                // className="d-flex"
                                style={{}}
                                onClick={() => {
                                  setCallDetails(ele);
                                  setCallAudioModal(true);
                                }}
                              >
                                <Link
                                  to="#"
                                  className="btn btn-info shadow btn-xs sharp"
                                >
                                  <i
                                    className="fa fa-play"
                                    style={{
                                      marginTop: "3px",
                                      marginLeft: "2px",
                                    }}
                                  />
                                </Link>
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {ele?.fileName !== null && ele.fileName !== "" ? (
                              <div
                                onClick={() => {
                                  handleDownload(
                                    "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
                                      ele?.fileName,
                                    ele?.phoneNumber +
                                      "_" +
                                      helper.EpochToDate(
                                        Math.floor(new Date() / 1000)
                                      )
                                  );
                                }}
                              >
                                <Link
                                  to="#"
                                  className="btn btn-success shadow btn-xs sharp"
                                >
                                  <i className="fa fa-download" />
                                </Link>
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : isDetailedReport === "CallAllocationReport"
                  ? paymentStatusSummaryList?.map((ele) => {
                      return (
                        <tr style={{ textAlignLast: "center" }}>
                          <td>{ele?.loanNumber ? ele?.loanNumber : "-"}</td>
                          <td>{ele?.cpName ? ele?.cpName : "-"}</td>
                          <td>{ele?.bucketName ? ele?.bucketName : "-"}</td>
                          <td>{ele?.pos ? ele?.pos : 0}</td>
                          <td>{ele?.emiAmount ? ele?.emiAmount : 0}</td>
                          <td>{ele?.state ? ele?.state : "-"}</td>
                          <td>{ele?.branch ? ele?.branch : "-"}</td>
                          <td>
                            {ele?.contactability ? ele?.contactability : "-"}
                          </td>
                          <td>{ele?.status ? ele?.status : "-"}</td>
                          <td>{ele?.subStatus ? ele?.subStatus : "-"}</td>
                          <td>{ele?.remarks ? ele?.remarks : "-"}</td>
                          <td>{ele?.intensity ? ele?.intensity : 0}</td>
                          <td>
                            {ele?.paymentStatus ? ele?.paymentStatus : "-"}
                          </td>
                          <td>
                            {ele?.lenderPaymentStatus
                              ? ele?.lenderPaymentStatus
                              : "-"}
                          </td>
                          <td>{ele?.emiAmountPaid ? ele?.emiAmountPaid : 0}</td>
                          <td>{ele?.paidCount ? ele?.paidCount : 0}</td>
                          <td>
                            {ele?.paidResolution ? ele?.paidResolution : 0}
                          </td>
                        </tr>
                      );
                    })
                  : isDetailedReport === "CallReportSummaryByDay"
                  ? summaryCallReportList?.map((ele) => {
                      return (
                        <tr>
                          {keys?.map((key) => {
                            return <td>{ele[key]}</td>;
                          })}
                        </tr>
                      );
                    })
                  : isDetailedReport === "CallIntensityByEmployee" ||
                    isDetailedReport === "CallIntensityByBranch"
                  ? callIntensityReportList?.map((ele) => {
                      return (
                        <tr>
                          <td>
                            {isDetailedReport === "CallIntensityByEmployee"
                              ? ele.empId
                                ? ele.empId
                                : "-"
                              : ele.branchName
                              ? ele.branchName
                              : "-"}
                          </td>
                          <td>
                            {isDetailedReport === "CallIntensityByEmployee"
                              ? ele.empName
                                ? ele.empName
                                : "-"
                              : ele.branchLocation
                              ? ele.branchLocation
                              : "-"}
                          </td>
                          <td>
                            {ele.allocationCount ? ele.allocationCount : 0}
                          </td>
                          <td>
                            {ele.nonAttemptedCount ? ele.nonAttemptedCount : 0}
                          </td>
                          <td>{ele.totalCalls ? ele.totalCalls : 0}</td>
                          <td>
                            {ele.paidCountFor0Calls
                              ? ele.paidCountFor0Calls
                              : 0}
                          </td>
                          <td>
                            {ele.unPaidCountFor0Calls
                              ? ele.unPaidCountFor0Calls
                              : 0}
                          </td>
                          <td>
                            {ele.paidCountFor1t03Calls
                              ? ele.paidCountFor1t03Calls
                              : 0}
                          </td>
                          <td>
                            {ele.unPaidCountFor1to3Calls
                              ? ele.unPaidCountFor1to3Calls
                              : 0}
                          </td>
                          <td>
                            {ele.paidCountFor4to10Calls
                              ? ele.paidCountFor4to10Calls
                              : 0}
                          </td>
                          <td>
                            {ele.unPaidCountFor4to10Calls
                              ? ele.unPaidCountFor4to10Calls
                              : 0}
                          </td>
                          <td>
                            {ele.paidCountFor11to25Calls
                              ? ele.paidCountFor11to25Calls
                              : 0}
                          </td>
                          <td>
                            {ele.unPaidCountFor11to25Calls
                              ? ele.unPaidCountFor11to25Calls
                              : 0}
                          </td>
                          <td>
                            {ele.paidCountForAbove25Calls
                              ? ele.paidCountForAbove25Calls
                              : 0}
                          </td>
                          <td>
                            {ele.unPaidCountForAbove25Calls
                              ? ele.unPaidCountForAbove25Calls
                              : 0}
                          </td>
                          <td>{ele.totalCount ? ele.totalCount : 0}</td>
                        </tr>
                      );
                    })
                  : CallDispositionReportList?.map((ele) => {
                      return (
                        <tr>
                          {dispositionKeys?.map((key) => {
                            return <td>{ele[key]}</td>;
                          })}
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </div>
          {detailedCallReportList?.length === 0 &&
            summaryCallReportList?.length === 0 &&
            paymentStatusSummaryList?.length === 0 &&
            callIntensityReportList?.length === 0 &&
            CallDispositionReportList?.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
          <div
            style={{
              alignSelf: "center",
              margin:
                totalElements === 0
                  ? "90px 0px 20px 20px"
                  : "20px 0px 20px 20px",
            }}
          >
            {/* {isDetailedReport ? ( */}
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </LoadingOverlay>
      </div>
      <Modal className="fade" show={callAudioModal}>
        <Modal.Header>
          <Modal.Title>Call Recording</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setCallAudioModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AudioPlayer
            autoPlay
            src={
              "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
              callDetails?.fileName
            }
            onPlay={(e) => console.log("onPlay")}
            // other props here
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={paymentReceiptModal}>
        <Modal.Header>
          <Modal.Title>Payment Receipt</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setPaymentReceiptModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlignLast: "center" }}>
            <SRLWrapper options={options1}>
              <img
                style={{ height: "200px", width: "200px" }}
                src={paymentReceiptImage}
                alt=""
              />
            </SRLWrapper>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CallReport;
