import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoadingOverlay from "react-loading-overlay";
import * as moduleApi from "../rest/moduleApi";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Flatpickr from "react-flatpickr";
import Spinner from "react-bootstrap/Spinner";
import { apiCall } from "../rest/restApi";
import { Button, Nav, Tab, Modal } from "react-bootstrap";
import Select from "react-select"
import { getTokenInLocalStorage } from "../services/AuthService";
import RetriedTransactions from "./RetriedTransactions";
import UPIMandateSummaryDetails from "./UPIMandateSummaryDetails";

const UPIMandateSummary = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("Subscriptions");
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  // const [clickedData, setClickedData] = useState("Something");

  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    1000 +
    86399
  );
  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    loadGraph(start, end, selectedPaymentType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
      1000 +
      86400;
    setStart(start);
    setEnd(end);
    loadGraph(start, end, selectedPaymentType);
  };

  const paymentTypes = [
    { label: "Subscriptions", value: "Subscriptions" },
    { label: "Payment Status", value: "Payment State" },
    // { label: "Transaction Error Code", value: "Transaction Error Code" },
    { label: "Transaction Status", value: "Transaction Status" },
    { label: "Retried Transactions", value: "Retried Transactions" },
    { label: "Retried Transactions Status", value: "Retried Transactions Status" },
  ];

  const setUpiMandateType = (value) => {

    if (value === "Subscriptions") {
      setShowDateFilter(false);
    } else {
      setShowDateFilter(true);
    }

    setSelectedPaymentType(value);
    loadGraph(start, end, value);

  };

  const loadGraph = (start, end, paymentType) => {
    setIsLoading(true)
    let payload = {
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      startDate: start,
      status: paymentType
    };
    moduleApi.postData(apiCall.getSubscriptionCountsWithStatus, payload).then((response) => {

      let keys = [];
      let values = [];
      for (let value of Object.keys(response.statusCounts)) {
        keys.push(value);
      }

      for (let value of Object.values(response.statusCounts)) {
        values.push(value);
      }
      setData({
        series: values,

        options: {
          chart: {
            width: 380,
            type: "pie",

            events: {
              dataPointSelection: (event, chartContext, config) => {

                const { dataPointIndex } = config;
                const clickedLabel = keys[dataPointIndex];
                setPaymentStatus(clickedLabel);

                setBasicModal(true);
              }
            }
          },

          // events: {
          //   dataPointSelection: (event, chartContext, config) => {
          //     // Get the data from the clicked slice
          //     const seriesIndex  = config;
          //     const clickedValue = data.series[seriesIndex];
          //     setClickedData(clickedValue);

          //     // Open the modal
          //     setBasicModal(true);
          //   }
          // },

          dataLabels: {
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex]
            },
          },
          noData: {
            text: "No Data Found",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#000000",
              fontSize: "20px",
              fontFamily: "Helvetica",
            },
          },
          labels: keys,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      });
      setIsLoading(false)
    });
  };

  // const generateColors = (data) => {
  //   return data?.options?.labels?.map((item) => {

  //     if (item === 'ACTIVE') {
  //       return 'green'; // Set green color for active status
  //     }else {
  //     // Auto-generate remaining colors
  //     return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  //     }
  //   });
  // };

  // const chartOptions = {
  //   ...data?.options,
  //   colors: generateColors(data),
  // };

  return (
    <div className="card" style={{ padding: "10px" }}>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="upiMandateSummary" key={'mainTabs'}>
          <Nav as="ul" className="nav-tabs-custom nav-justified">
            <Nav.Item as="li" key="0">
              <Nav.Link eventKey="upiMandateSummary" onClick={() => {
                setShowDateFilter(false);
                loadGraph(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
                  1000, new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
                  1000 +
                86399, "Subscriptions");
              }}>
                <b> UPI Mandate Summary</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="1">
              <Nav.Link eventKey="retriedTransactionsHistory">
                <b>Retried Transactions History </b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4" style={{ padding: "-10px" }}>
            <Tab.Pane eventKey="upiMandateSummary" key="0" mountOnEnter unmountOnExit>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} >
                  <div
                    id="selectFilter"
                    style={{
                      // float: "right",
                      width: "140px",
                      marginRight: "20px",
                      height: "38px",
                      marginLeft: "15px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      isSearchable={false}
                      onChange={(value) => {
                        setUpiMandateType(value.value);
                      }}
                      defaultValue={paymentTypes[0]}
                      options={paymentTypes}
                    />
                  </div>
                  {showDateFilter && (
                    <Flatpickr
                      id="date"
                      className="form-control d-block"
                      style={{
                        width: "180px",
                        height: "40px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #3a353b",
                        textAlignLast: "center",
                      }}
                      placeholder="Select Month"
                      onChange={(value) => {
                        setCustomDatesForGraph(value);
                      }}
                      options={{
                        defaultDate: new Date(),
                        maxDate: new Date(),
                        minDate: new Date().setMonth(new Date().getMonth() - 6),

                        plugins: [
                          new monthSelectPlugin({
                            shorthand: true, //defaults to false
                            dateFormat: "F Y", //defaults to "F Y"
                            altFormat: "F Y", //defaults to "F Y"
                            theme: "light", // defaults to "light"
                          }),
                        ],
                      }}
                    />
                  )}
                </div>
                {/* </div> */}
                <div>
                  <LoadingOverlay
                    active={isLoading}
                    // spinner
                    text={
                      <p
                        style={{
                          color: "black",
                        }}
                      >
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#FFFF",
                              color: "black",
                              borderColor: "white",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                            variant="primary"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="md"
                              variant="primary"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp;&nbsp;Loading...
                          </Button>
                        </div>
                      </p>
                    }
                    styles={{
                      overlay: (base) => ({
                        ...base,
                        background: "rgba(192,192,192,0.4)",
                      }),
                      spinner: (base) => ({
                        ...base,
                        width: "80px",
                        "& svg circle": {
                          stroke: "rgb(136, 108, 192)",
                        },
                      }),
                    }}
                  >
                    <div style={{ margin: "10px" }}>
                      {data?.options && (
                        <ReactApexChart
                          options={data.options}
                          series={data.series}
                          type="pie"
                          height={350}
                        // onClick={(index) => {   
                        // console.log("indexxxx",index);           
                        // setPaymentStatus(index.label);
                        // setBasicModal(true)}}
                        />
                      )}
                      <Modal className="fade" show={basicModal} size="xl">
                        <Modal.Header>
                          <Modal.Title>{selectedPaymentType==="Subscriptions" ? "Subscriptions Details":selectedPaymentType==="Payment State" ? "Transaction Details Based On (Payment Status)" :"Transaction Details Based On (" + selectedPaymentType +")"}</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => {
                              setBasicModal(false);
                            }}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body style={{ paddingTop: "0px" }}>

                          <UPIMandateSummaryDetails
                            transactionType={selectedPaymentType}
                            status={paymentStatus}
                            startDate={start}
                            endDate={end}
                            />

                        </Modal.Body>
                      </Modal>
                    </div>
                  </LoadingOverlay>
                </div>

              </div>

            </Tab.Pane>
            <Tab.Pane eventKey="retriedTransactionsHistory" key="1" mountOnEnter unmountOnExit>
              <RetriedTransactions />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default UPIMandateSummary;
