// export default function swDev() {
//   let swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
//   navigator.serviceWorker.register(swUrl).then((response) => {
//     console.warn("response", response);
//   });
// }

export default function swDev() {
    let swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
    if ("serviceWorker" in navigator) {
      try {
        const registration = navigator.serviceWorker.register(swUrl, {
          scope: "/",
        });
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
        }
      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    }
}

// const registerServiceWorker = async () => {
//     let swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
//     if ("serviceWorker" in navigator) {
//       try {
//         const registration = await navigator.serviceWorker.register(swUrl, {
//           scope: "/",
//         });
//         if (registration.installing) {
//           console.log("Service worker installing");
//         } else if (registration.waiting) {
//           console.log("Service worker installed");
//         } else if (registration.active) {
//           console.log("Service worker active");
//         }
//       } catch (error) {
//         console.error(`Registration failed with ${error}`);
//       }
//     }
//   };

//   export default registerServiceWorker()