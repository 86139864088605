import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import * as helper from "../CommonComponents/Helper";
import LoadImage from "../CommonComponents/LoadImage";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import {
  getTokenInLocalStorage,
  hasAccess,
  isAdmin,
} from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import * as XLSX from "xlsx";
import swal from "sweetalert";
// import { toaster } from "../rest/apiUtils";
import SearchBar from "../CommonComponents/SearchBar";
import AddExecutive from "../Executives/AddExecutive";

function ManagerRAUsers() {
  var history = useHistory();
  const [executivesList, setExecutivesList] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [addExecutiveModal, setAddExecutiveModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [isMap, setIsMap] = useState(false);
  // eslint-disable-next-line
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [exeList, setExeList] = useState(null);
  const [reportingOfficers, setReportingOfficers] = useState([]);

  let listData = [];
  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    loadData(0, searchKey);
    getReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    exeList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exeList]);

  const loadData = (pageNo, key) => {
    setIsLoading(true);
    // setCurrPage(0)
    let agentBody;
    agentBody = {
      roleName: "MANAGER_RA",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportOfficerId: !isAdmin()
        ? userLoginDetails.autoFinanceUser.userId
        : "",
      searchKey: key,
      status: "ACTIVE",
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getExecutives, pageNo, 10),
        agentBody
      )
      .then((response) => {
        setCurrPage(pageNo);
        // let userIds = [];
        // response.content.forEach((element) => {
        //   userIds.push(element.autoFinanceUser.userId);
        // });
        // let m = new Map();
        // let payload = {
        //   organizationId: userLoginDetails.autoFinanceUser.organizationId,
        //   userIds: userIds,
        // };
        // moduleApi
        //   .postData(apiCall.getLiveLocations, payload)
        //   .then((response2) => {
        //     response2?.forEach((item) => {
        //       m.set(item?.userId, item);
        //     });
        if (response?.content?.length > 0) {
        let userIds = [];
        response?.content.forEach((element) => {
          userIds?.push(element?.autoFinanceUser?.reportingOfficerId);
        });
        let m = new Map();
        moduleApi.postData(apiCall.getMultipleUsers, userIds).then((response2) => {
          response2?.forEach((item) => {
            m.set(item?.autoFinanceUser?.userId, item);
          });

            response.content.forEach((element) => {
              let data = {
                branchId: element.autoFinanceBranchDetails.branchId,
                branchLocation: element.autoFinanceBranchDetails.branchLocation,
                branchName: element.autoFinanceBranchDetails.branchName,
                branchemail: element.autoFinanceBranchDetails.branchemail,
                organizationId: element.autoFinanceBranchDetails.organizationId,
                phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
                addedByUserId: element.autoFinanceUser.addedByUserId,
                reportingOfficerIds:
                  element.autoFinanceUser.reportingOfficerIds,
                  managerIdsUnderRa:
                  element.autoFinanceUser.managerIdsUnderRa,
                email: element.autoFinanceUser.email,
                employeeId: element.autoFinanceUser.employeeId,
                image: element.autoFinanceUser.image,
                mobileNumber: element.autoFinanceUser.mobileNumber,
                name: element.autoFinanceUser.name,
                passwordUpdated: element.autoFinanceUser.passwordUpdated,
                reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
                reportingOfficerName: m.has(element.autoFinanceUser.reportingOfficerId)
                ? m.get(element.autoFinanceUser.reportingOfficerId).autoFinanceUser.name
                : "-",
                roleName: element.autoFinanceUser.roleName,
                status: element.autoFinanceUser.status,
                userId: element.autoFinanceUser.userId,
                designation: element.autoFinanceUser.companyDesignation,
                privilageIds: element.autoFinanceUser.privilageIds,


                // batteryPercentage: m.has(element.autoFinanceUser.userId)
                //   ? m.get(element.autoFinanceUser.userId).batteryPercentage
                //   : 0,
                // gpsData: m.has(element.autoFinanceUser.userId)
                //   ? m.get(element.autoFinanceUser.userId).gpsData
                //   : null,
                // lastUpdatedAt: m.has(element.autoFinanceUser.userId)
                //   ? m.get(element.autoFinanceUser.userId).lastUpdatedAt
                //   : 0,
                // locationPermissionEnabled: m.has(element.autoFinanceUser.userId)
                //   ? m.get(element.autoFinanceUser.userId)
                //       .locationPermissionEnabled
                //   : "-",
                // locationPermission: m.has(element.autoFinanceUser.userId)
                //   ? m.get(element.autoFinanceUser.userId).locationPermission
                //   : "-",
              };
              listData.push(data);
            });
            setExecutivesList(listData);

            setTotalElements(response.totalElements);
            setIsLoading(false);
          });
        }else{
          setExecutivesList([]);

            setTotalElements(0);
            setIsLoading(false);
        }
      });
  };

  const handleClose = () => {
    setAddExecutiveModal(false);
    loadData(0, searchKey);
  };
  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    // {
    //     text: "Image",
    //     dataField: "image",
    //     width: 130,
    //     align: "center",
    //     headerAlign: "center",
    //     headerStyle: {
    //       backgroundColor: "rgb(243,240,249)",
    //       fontWeight: "bold",
    //       fontFamily:
    //         " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //     },
    //     formatter: (cell, row) => {
    //         if (row.image === "ACTIVE") {
    //           return (
    //             <></>
    //           );
    //         } else {
    //           return (
    //             <Media>
    //               <div style={{ marginTop: "30px" }} className="ms-3">
    //                 <img
    //                   // src= {(isImageComing === false) ? `data:image/png;base64,${currentFile}`: mainOBJ.userImage}
    //                   // src={!isImageGoing ? getImage : getImage}
    //                   src={executivesList.image
    //                     // isImageComing
    //                     //   ? `data:image/png;base64,${currentFile}`
    //                     //   : mainOBJ.userImage
    //                     //   ? mainOBJ.userImage
    //                     //   : `data:image/png;base64,${currentFile}`
    //                   }
    //                   // src={`data:image/png;base64,${currentFile}`}
    //                   alt=""
    //                   className="avatar-lg rounded-circle img-thumbnail"
    //                   // className = "rounded avatar-md" //square shape immage
    //                 />
    //               </div>

    //             </Media>
    //           );
    //         }
    //       },
    // },
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <LoadImage source={row.image} />{" "}
            <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
          </div>
        );
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Email",
    //   dataField: "email",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Mobile No",

      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",

      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Reporting</span>
          <br></br>
          <span>Officer</span>
        </div>
      ),
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    // {
    //   text: "Battery",
    //   dataField: "status2",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign:'middle',
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     return (
    //       <div>
    //         {row.batteryPercentage > 75 ? (
    //           <span style={{ color: "green", verticalAlign: "middle" }}>
    //             <i className="fas fa-battery-full fa-2x fa-rotate-0" />
    //           </span>
    //         ) : row.batteryPercentage > 30 ? (
    //           <span style={{ color: "orange", verticalAlign: "middle" }}>
    //             <i className="fas fa-battery-three-quarters fa-2x fa-rotate-0 " />
    //           </span>
    //         ) : row.batteryPercentage > 0 ? (
    //           <span style={{ color: "red", verticalAlign: "middle" }}>
    //             <i className="fas fa-battery-quarter fa-2x fa-rotate-0 " />
    //           </span>
    //         ) : (
    //           <></>
    //         )}
    //         <span
    //           style={{
    //             marginLeft: row.batteryPercentage === 0 ? "0px" : "3px",
    //           }}
    //         >
    //           {row.batteryPercentage === 0
    //             ? "N/A"
    //             : row.batteryPercentage + "%"}
    //         </span>
    //       </div>
    //     );
    //     // if (row.status === "ACTIVE") {
    //     //   return (
    //     //     <span className="badge badge-rounded badge-success">Active</span>
    //     //   );
    //     // } else {
    //     //   return (
    //     //     <span className="badge badge-rounded badge-danger">Inactive</span>
    //     //   );
    //     // }
    //   },
    // },
    // {
    //   text: <div><span>Last Updated</span><br></br><span>Location</span></div>,
    //   dataField: "lastUpdatedAt",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign:'middle',
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.gpsData) {
    //       return (
    //         <div style={{ display: "flex", justifyContent: "center" }}>
    //           <p
    //             style={{ cursor: "pointer" }}
    //             title={helper.EpochToDate(row.gpsData.t)}
    //           >
    //             {helper.EpochToDate(row.gpsData.t)}
    //           </p>
    //           <span
    //             style={{
    //               color: "green",
    //               margin: "0px 15px",
    //               cursor: "pointer",
    //               alignSelf:'center'
    //             }}
    //             onClick={() => {
    //               setLatLng({ lat: row.gpsData.x, lng: row.gpsData.y });
    //               setIsMap(true);
    //             }}
    //           >
    //             <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
    //           </span>
    //         </div>
    //       );
    //     } else {
    //       return <>-</>;
    //     }
    //   },
    // },
    // {
    //   text: <div><span>Location</span><br></br><span>Permissions</span></div>,
    //   dataField: "locationPermission",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign:'middle',
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (col, row) => {
    //     if (row.locationPermission === 0) {
    //       return <span>N/A</span>;
    //     } else if (row.locationPermission === 1) {
    //       return (
    //         <span className="badge badge-rounded badge-success">
    //           Always Allow
    //         </span>
    //       );
    //     } else if (row.locationPermission === 2) {
    //       return (
    //         <span className="badge badge-rounded badge-primary">
    //           While In Use
    //         </span>
    //       );
    //     } else if (row.locationPermission === 3) {
    //       return (
    //         <span className="badge badge-rounded badge-danger">Denied</span>
    //       );
    //     } else if (row.locationPermission === 4) {
    //       return (
    //         <span className="badge badge-rounded badge-danger">
    //           Always Denied
    //         </span>
    //       );
    //     } else if (row.locationPermission === 5) {
    //       return (
    //         <span className="badge badge-rounded badge-primary">
    //           Ask Every Time When Come
    //         </span>
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    // {
    //   text: "Status",
    //   dataField: "mobileNumber2",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign:'middle',
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (col, row) => {
    //     if (row.status === "ACTIVE") {
    //       return (
    //         <span className="badge badge-rounded badge-success">Active</span>
    //       );
    //     } else {
    //       return (
    //         <span className="badge badge-rounded badge-danger">Inactive</span>
    //       );
    //     }
    //   },
    // },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          localStorage.setItem("managerDetails", JSON.stringify(row));
          localStorage.setItem("prevUrl", "read-only-users-[Incharges]");
          history.push("/read-only-user-details");
        },
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    // setCurrPage(selectedPage - 1);
    loadData(selectedPage - 1, searchKey);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadData(0, key);
  };

  const exeDownloadData = () => {
    setIsLoading(true);
    let agentBody;
    agentBody = {
      roleName: "MANAGER_RA",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportOfficerId: !isAdmin()
        ? userLoginDetails.autoFinanceUser.userId
        : "",
      searchKey: searchKey,
      status: "ACTIVE",
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getExecutives, 0, 10), agentBody)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getExeFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getExeFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getExeFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      let managerBody = {
        roleName: "MANAGER_RA",
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        reportOfficerId:
          userLoginDetails.autoFinanceUser.roleName !== "ADMIN"
            ? userLoginDetails.autoFinanceUser.userId
            : "",
        searchKey: searchKey,
        status: "ACTIVE",
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getExecutives, i, 100),
          managerBody
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        branchId: element.autoFinanceBranchDetails.branchId,
        branchLocation: element.autoFinanceBranchDetails.branchLocation,
        branchName: element.autoFinanceBranchDetails.branchName,
        branchemail: element.autoFinanceBranchDetails.branchemail,
        organizationId: element.autoFinanceBranchDetails.organizationId,
        phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
        addedByUserId: element.autoFinanceUser.addedByUserId,
        reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
        email: element.autoFinanceUser.email,
        employeeId: element.autoFinanceUser.employeeId,
        image: element.autoFinanceUser.image,
        mobileNumber: element.autoFinanceUser.mobileNumber,
        name: element.autoFinanceUser.name,
        passwordUpdated: element.autoFinanceUser.passwordUpdated,
        reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
        roleName: element.autoFinanceUser.roleName,
        status: element.autoFinanceUser.status,
        userId: element.autoFinanceUser.userId,
        designation: element.autoFinanceUser.companyDesignation,
        directReportOfcr: getDirectReportOfcr(
          element.autoFinanceUser.reportingOfficerId
        ),
      };
      mgrListData.push(data);
    });
    setExeList(mgrListData);

    // console.log("mgrList1", mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Branch",
        D: "Mobile No",
        E: "Designation",
        F: "Email",
        G: "Status",
        H: "Direct Reporting Officer",
      },
    ];

    exeList?.forEach((row) => {
      mgrTable.push({
        A: row.employeeId,
        B: row.name,
        C: row.branchName,
        D: row.mobileNumber,
        E: row.designation,
        F: row.email,
        G: row.status,
        H: row.directReportOfcr,
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Read_Only_Users");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      "Clu_Read_Only_Users_Report_" +
        //  new Date().toLocaleString("en-US") +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Read_Only_Users List Downlaod",
      action:
      userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Read_Only_Users_Report_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const getDirectReportOfcr = (id) => {
    for (let i = 0; i < reportingOfficers.length; i++) {
      if (id === reportingOfficers[i].userId) {
        // console.log('sd',reportingOfficers[i].name);
        return reportingOfficers[i].name;
      }
    }
  };

  const getReportList = () => {
    var managersPayLoad3 = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      roleName: "ADMIN_MANAGER",
    };

    moduleApi.postData(apiCall.usersList, managersPayLoad3).then((response) => {
      // console.log("response", response);
      const result = response?.map((item) => ({
        userId: item.userId,
        name: item.name,
      }));
      setReportingOfficers(result);
    });
  };

  return (
    <div className="card">
      <div
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        {/* <input
          style={{
            width: "300px",
            height: "40px",
            borderRadius: "25px",
            borderColor: "#a0a0a0",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #3a353b",
            paddingLeft: "10px",
          }}
          placeholder="Search By Name/Branch/Mobile"
          onChange={(e) => {
            
clearInterval(timer)
           timer= setTimeout(() => {
              searchKeyword(e.target.value.trim());
            }, 2000);
            
          }}
        /> */}
        <SearchBar
          placeholder="Search By Name/Branch/Mobile/Emp ID"
          searchKeyword={searchKeyword}
        />
        {
        // userLoginDetails.isSuperAdmin !== true && 
        hasAccess(26) && (
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => setAddExecutiveModal(true)}
          >
            <b> + Add Read Only User </b>
          </Button>
        )}
        <Button
          style={{
            float: "right",
            margin: "10px",
            marginRight: "15px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() => exeDownloadData()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px", overflowX: "auto" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              remote
              keyField="panNo"
              data={executivesList}
              // loading=
              columns={columns}
              // overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } }) }

              // rowStyle={rowStyle}
              // pagination={paginationFactory()}
            />
          </div>
          {executivesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Last Updated Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Clu</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>Do you really want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setBasicModal(false)} variant="danger light">
            Cancel
          </Button>
          <Button variant="primary" onClick={() => setBasicModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" show={addExecutiveModal}>
        <Modal.Header>
          <Modal.Title>Add Read Only User</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddExecutiveModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AddExecutive close={handleClose} role='MANAGER_RA' />
        </Modal.Body>
      </Modal>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   loginData: state,
// });

export default ManagerRAUsers;
