import { compose, withProps } from "recompose";
import React, {useState} from "react";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import marker_red from "../../src/images/marker_img.png";

const VisitLocationMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyChqvJDsjaqXtn-tjeMBa-Y67xqz4mtuWE",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
  //   {props.date && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
  // </GoogleMap>
  // eslint-disable-next-line
  const [latLng, setLatLng] = useState({ lat: props.latitide, lng: props.longitude });


 


  return (
    <div>
      <GoogleMap
        key={props.key}
        defaultZoom={13}
        // onClick={ev => {
        //   console.log("latitide = ", ev.latLng.lat());
        //   console.log("longitude = ", ev.latLng.lng());
        //   setLatLng({
        //     lat: ev.latLng.lat(),
        //     lng: ev.latLng.lng(),
        //   });
        //   props.getLocationDetails({
        //     lat: ev.latLng.lat(),
        //     lng: ev.latLng.lng(),
        //   })
        // //  return <Marker
        // //   position={{ lat: ev.latLng.lat(), lng: ev.latLng.lng() }}
        // //   // eslint-disable-next-line no-native-reassign
        // //   icon={(URL = marker_red)}
        // //   style={{ height: "10px", width: "10px" }}
        // // />

        // }}
        defaultCenter={{
          lat: props.latitide ? props.latitide : 17.385,
          lng: props.longitude ? props.longitude : 78.4867,
        }}
      >
        <Marker
          position={{ lat: latLng.lat?latLng.lat:props.latitide, lng: latLng.lng?latLng.lng: props.longitude}}
          // eslint-disable-next-line no-native-reassign
          icon={(URL = marker_red)}
          style={{ height: "10px", width: "10px" }}
        />
        {/* <i className="fas fa-street-view fa-2x fa-rotate-0" /> */}

      </GoogleMap>
    </div>
  );
});

<VisitLocationMap isMarkerShown date />;

export default VisitLocationMap;
