import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Flatpickr from "react-flatpickr";
import Spinner from "react-bootstrap/Spinner";
import { Button, Card } from "react-bootstrap";
import { apiCall } from "../rest/restApi";
import ReactGA from "react-ga";
import * as helper from "../CommonComponents/Helper";
import { getTokenInLocalStorage } from "../services/AuthService";

const PaymentsGraph2 = () => {
  const [data, setData] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [month, setMonth] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [startDate, setStartDate] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [endDate, setEndDate] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    // var d = new Date();
    // d.setDate(0); //sets d to the last day of the previous month
    // d.setHours(0, 0, 0, 0);
    // var eDate = d.getTime();
    // d.setDate(1); //sets d the the first day of that month
    // d.setHours(0, 0, 0, 0);
    // var sDate = d.getTime();
    // var start = Math.floor(sDate / 1000);
    // var end = Math.floor(eDate / 1000);
    // // this.setState({...this.state,history:this.props.history})
    // loadCollectionGraph(start, end, false)
    var start, end;
    var date;
    date = [
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ),
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ),
    ];
    start = Math.floor(date[0].getTime() / 1000);
    end = Math.floor(date[1].getTime() / 1000);
    loadCollectionGraph(start, end, false, selectedStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toolTip = (series, seriesIndex, dataPointIndex, w, data2) => {
    let data = [];
    series.forEach((item) => {
      data.push(item[dataPointIndex]);
    });
    // console.log("props", w.config.xaxis.categories[dataPointIndex]);
    return (
      '<div style="margin:7px;font-size: 11px"> <ul>' +
      "<li ><b>Date</b> : " +
      w.config.xaxis.categories[dataPointIndex] +
      "</li>" +
      "<li><b>Payment Request Sent</b> : " +
      data2?.noOfRequestSent[dataPointIndex] +
      " </li>" +
      "<li><b>Payment Successful</b> : " +
      data[0] +
      " </li>" +
      "</li>" +
      "<li><b>Payment Failed</b> : " +
      data[1] +
      "</li>" +
      "</li>" +
      "<li><b>Payment Cancelled</b> : " +
      data[2] +
      "</li>" +
      "</ul></div>"
    );
  };

  const getPoints = (data) => {
    // console.log("dddd", data);
    var dataArr = [];
    for (let index = 0; index < data.date.length; index++) {
      var ele = {
        x: data.date[index],
        y: data?.noOfRequestSent[index],
        marker: {
          size: 0,
          fillColor: "#fff",
          strokeColor: "red",
          radius: 2,
          cssClass: "apexcharts-custom-class",
        },
        label: {
          borderColor: "#FF4560",
          offsetY: 0,
          style: {
            color: "#fff",
            background: "#FF4560",
          },
          text: shortenAmount(data.noOfRequestSent[index]) + "",
        },
      };
      dataArr.push(ele);
    }
    return dataArr;
  };

  const shortenAmount = (value) => {
    if (value <= 0) return "";
    let val = Math.abs(value);
    if (val >= 10 ** 3 && val < 10 ** 6) {
      val = (val / 1000).toFixed(0) + " K";
    } else if (val >= 10 ** 6) {
      val = (val / 1000000).toFixed(0) + " M";
    } else {
      // eslint-disable-next-line no-self-assign
      val = val;
    }
    return val;
  };
  const setStatusValue = (value) => {
    loadCollectionGraph(
      startDate,
      endDate,
      month === true ? true : false,
      value
    );
    setSelectedStatus(value);
  };

  const loadCollectionGraph = (startDate, endDate, month, status) => {
    setData({ ...data, isLoading: true });
    var userDetails = JSON.parse(localStorage.getItem("userDetails"));
    var body = {
      endDate: endDate,
      organizationId: userDetails.autoFinanceUser.organizationId,
      startDate: startDate,
      reportType: month ? "month" : "",
      userId: userDetails.autoFinanceUser.userId,
      paymnetType: status,
    };
    moduleApi.postData(apiCall.paymentsGraph, body).then((res) => {
      var bArr = {
        amountCollected: [],
        date: [],
        noOfPaymentCancelled: [],
        noOfPaymentFailed: [],
        noOfPaymentPendiing: [],
        noOfPaymentSucess: [],
        noOfRequestSent: [],
        organizationId: [],
      };

      var s = startDate;
      var e = endDate;

      if (month) {
        var i = 0;
        while (i < 6) {
          var d = new Date();
          d.setDate(1);
          d.setHours(0, 0, 0, 0);
          d.setMonth(d.getMonth() - 6 + i);
          bArr.date.push(convertEpochTimeToDate(d.getTime() / 1000, 6));
          bArr.noOfPaymentSucess.push(0);
          bArr.noOfPaymentFailed.push(0);
          bArr.noOfPaymentCancelled.push(0);
          bArr.noOfRequestSent.push(0);
          i++;
        }
      } else {
        while (e > s) {
          bArr.date.push(convertEpochTimeToDate(s));
          bArr.noOfPaymentSucess.push(0);
          bArr.noOfPaymentFailed.push(0);
          bArr.noOfPaymentCancelled.push(0);
          bArr.noOfRequestSent.push(0);
          s = s + 86400;
        }
      }

      for (const iterator of res) {
        var position = 0;
        while (position < bArr.date.length) {
          if (
            bArr.date[position] ===
            convertEpochTimeToDate(iterator.date, bArr.date.length)
          ) {
            bArr.noOfPaymentSucess[position] = iterator.noOfPaymentSucess;
            bArr.noOfPaymentFailed[position] = iterator.noOfPaymentFailed;
            bArr.noOfPaymentCancelled[position] = iterator.noOfPaymentCancelled;
            bArr.noOfRequestSent[position] = iterator.noOfRequestSent;
            position++;
            break;
          } else if (
            new Date(bArr.date[position]).getTime() / 1000 <
            iterator.date
          ) {
            position++;
          } else {
            position++;
          };
        }
      }

      // console.log('barr', bArr);

      // eslint-disable-next-line array-callback-return
      //   res.map((item) => {
      //     bArr.amountCollected.push(
      //       item.amountCollected === null ? "-" : item.amountCollected
      //     );
      //     bArr.date.push(convertEpochTimeToDate(item.date));
      //     bArr.noOfPaymentCancelled.push(item.noOfPaymentCancelled);
      //     bArr.noOfPaymentFailed.push(item.noOfPaymentFailed);
      //     bArr.noOfPaymentPendiing.push(item.noOfPaymentPendiing);
      //     bArr.noOfPaymentSucess.push(item.noOfPaymentSucess);
      //     bArr.noOfRequestSent.push(item.noOfRequestSent);
      //     bArr.organizationId.push(item.organizationId);
      //   });
      setData({
        series: [
          {
            name: "Payment Successful",
            data: bArr.noOfPaymentSucess,
          },
          {
            name: "Payment Failed",
            data: bArr.noOfPaymentFailed,
          },
          // {
          //     name: 'No Pay',
          //     data: bArr.noPayCount
          // },
          {
            name: "Payment Cancelled",
            data: bArr.noOfPaymentCancelled,
          },
        ],
        options: {
          chart: {
            type: "bar",
            stacked: true,
            height: 230,
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
              },
              export: {
                csv: {
                  filename: "UPI Payments Trends",
                  columnDelimiter: ",",
                  headerCategory: "Date",
                  headerValue: "value",
                  dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString();
                  },
                },
                svg: {
                  filename: bArr.graphName
                    ? bArr.graphName +
                      "_" +
                      helper.EpochToDate(Math.floor(new Date() / 1000))
                    : "Preview Graph",
                },
                png: {
                  filename: bArr.graphName
                    ? bArr.graphName +
                      "_" +
                      helper.EpochToDate(Math.floor(new Date() / 1000))
                    : "Preview Graph",
                },
              },
            },
            //   events: {
            //     click: function (chartContext, options, config) {
            //       if (config.dataPointIndex < 0 || config.seriesIndex < 0)
            //         return;
            //       const collDetails = {
            //         start: startDate,
            //         end: endDate,
            //         branchId: bArr.branchId[config.dataPointIndex],
            //         visitType: "Collections",
            //         visitStatus: config.globals.seriesNames[config.seriesIndex],
            //       };

            //       localStorage.setItem(
            //         "collectionDetails",
            //         JSON.stringify(collDetails)
            //       );
            //       localStorage.setItem("prevUrl", "dashboard");
            //       history.push("collection-details");
            //       // console.log(startDate + '---' + endDate + '----' + config.dataPointIndex + '---' + config.seriesIndex + '---' + config.globals.seriesNames[config.seriesIndex] + '====' + config.globals.labels[config.dataPointIndex] + '---' + bArr.branchId[config.dataPointIndex]);
            //     },
            //   },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: res.length > 3 ? "90%" : "20%",

              dataLabels: {
                position: "center",
              },
            },
          },
          colors: ["#09BD3C", "#FC2E53", "#3349FF"],
          legend: {
            show: true,
            showForSingleSeries: true,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "top",
            horizontalAlign: "left",
            onItemHover: {
              highlightDataSeries: true,
            },
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          noData: {
            text: "No Data Found",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#000000",
              fontSize: "14px",
              fontFamily: "Helvetica",
            },
          },
          dataLabels: {
            textAnchor: "middle",

            enabled: true,
            offsetX: 0,
            offsetY: 0,
            style: {
              fontSize: "13px",
              colors: ["#000000"],
            },
            formatter: function (value, opt) {
              if (value <= 0) return "";
              let val = Math.abs(value);
              if (val >= 10 ** 3 && val < 10 ** 6) {
                val = (val / 1000).toFixed(0) + " K";
              } else if (val >= 10 ** 6) {
                val = (val / 1000000).toFixed(0) + " M";
              } else {
                // eslint-disable-next-line no-self-assign
                val = val;
              }
              return val;
            },
          },
          annotations: {
            points: getPoints(bArr),
          },
          stroke: {
            show: false,
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            show: true,
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: true,
              // formatter: (val) => xAxisLabel(props, val), // just formats date to hh:mm format
            },
            categories: bArr.date,
            //   formatter: function(value){
            //     return convertEpochTimeToDate(value, bArr?.date?.length )
            //   }
          },
          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return toolTip(series, seriesIndex, dataPointIndex, w, bArr);
            },
            theme: "dark",
          },
          // eslint-disable-next-line no-dupe-keys
          //   legend: {
          //     position: "top",
          //     offsetY: 0,
          //     horizontalAlign: "right",
          //   },
        },
        startDate: startDate,
        endDate: endDate,
        isLoading: false,
      });
    });
  };

  //   const setGraphDates = (label, value) => {
  //     var date;
  //     var start, end;

  //     switch (value) {
  //       case 1:
  //         date = [
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
  //               0,
  //               0,
  //               0
  //             )
  //           ),
  //           new Date(
  //             new Date(new Date().setDate(new Date().getDate())).setHours(
  //               23,
  //               59,
  //               59
  //             )
  //           ),
  //         ];
  //         start = Math.floor(date[0].getTime() / 1000);
  //         end = Math.floor(date[1].getTime() / 1000);
  //         // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } })
  //         loadCollectionGraph(start, end, false);
  //         return;
  //       case 2:
  //         var cd = new Date();
  //         var lastWeekLastDay = new Date(cd.setDate(cd.getDate() - cd.getDay()));
  //         var lastWeekFirstDay = new Date(
  //           cd.setDate(cd.getDate() - cd.getDay() - 6)
  //         );

  //         date = [
  //           new Date(lastWeekFirstDay.setHours(0, 0, 0)),
  //           new Date(lastWeekLastDay.setHours(23, 59, 59)),
  //         ];

  //         start = Math.floor(date[0].getTime() / 1000);
  //         end = Math.floor(date[1].getTime() / 1000);
  //         // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } })
  //         loadCollectionGraph(start, end, false);

  //         return;
  //       case 3:
  //         //This Month
  //         var d = new Date();
  //         d.setDate(1); //sets d the the first day of that month
  //         d.setHours(0, 0, 0, 0);
  //         var stDate = d.getTime();
  //         var curEndDate = new Date(
  //           new Date(new Date().setDate(new Date().getDate())).setHours(
  //             23,
  //             59,
  //             59
  //           )
  //         ).getTime();

  //         start = Math.floor(stDate / 1000);
  //         end = Math.floor(curEndDate / 1000);
  //         // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } })
  //         loadCollectionGraph(start, end, false);

  //         return;
  //       case 4:
  //         //Last month Logic
  //         // eslint-disable-next-line no-redeclare
  //         var d = new Date();
  //         d.setDate(0); //sets d to the last day of the previous month
  //         d.setHours(0, 0, 0, 0);
  //         var eDate = d.getTime();
  //         d.setDate(1); //sets d the the first day of that month
  //         d.setHours(0, 0, 0, 0);
  //         var sDate = d.getTime();
  //         start = Math.floor(sDate / 1000);
  //         end = Math.floor(eDate / 1000);
  //         // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } })
  //         loadCollectionGraph(start, end, false);
  //         break;
  //       case 5:
  //         // eslint-disable-next-line no-redeclare
  //         var d = new Date();
  //         d.setDate(1);
  //         d.setHours(0, 0, 0, 0);
  //         end = d.getTime() / 1000;
  //         d.setMonth(d.getMonth() - 6);
  //         start = d.getTime() / 1000;
  //         // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } })
  //         loadCollectionGraph(start, end, false);
  //         break;
  //       default:
  //     }
  //   };

  const chartDateOptions = [
    { label: "Last 7 Days", value: 1 },
    // { label: "Last Week", value: 2 },
    { label: "Current Month", value: 3 },
    { label: "Last Month", value: 4 },
    { label: "Last 6 Months", value: 5 },
    { label: "Custom Month", value: 6 },
  ];
  const statusOptions = [
    { label: "All", value: "All" },
    { label: "QR Code", value: "QR Code" },
    { label: "UPI Payment Request", value: "Collect Request" },
    { label: "Bulk Payment Request", value: "Payment Links" },
  ];

  const setGraphDatesForPayments = (label, value) => {
    var date;
    var start, end;

    switch (value) {
      case 1:
        setShowDate(false);
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        // console.log("selectedStatus", selectedStatus);
        loadCollectionGraph(start, end, false, selectedStatus);
        setStartDate(start);
        setEndDate(end);
        setMonth(false);
        ReactGA.event({
          category: "UPI Payments Graph Last 7 Days",
          action:
            userLoginDetails.autoFinanceOrganization.orgshortcode +
            "_UPI Payments Graph Last 7 Days_" +
            helper.EpochToDate(Math.floor(new Date() / 1000)) +
            ".xlsx",
        });

        // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        // setIsMonthInProgress(false);
        // // loadGraph(start, end, loggedInUser, false);
        // onGraphTypeChange(
        //   currentLabelValue.label,
        //   currentLabelValue.value,
        //   start,
        //   end,
        //   loggedInUser,
        //   false
        // );

        return;
      case 2:
        setShowDate(false);
        var cd = new Date();
        var lastWeekLastDay = new Date(cd.setDate(cd.getDate() - cd.getDay()));
        var lastWeekFirstDay = new Date(
          cd.setDate(cd.getDate() - cd.getDay() - 6)
        );

        date = [
          new Date(lastWeekFirstDay.setHours(0, 0, 0)),
          new Date(lastWeekLastDay.setHours(23, 59, 59)),
        ];

        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        loadCollectionGraph(start, end, false, selectedStatus);
        setStartDate(start);
        setEndDate(end);
        setMonth(false);
        // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        // setIsMonthInProgress(false);
        // // loadGraph(start, end, loggedInUser, false);
        // onGraphTypeChange(
        //   currentLabelValue.label,
        //   currentLabelValue.value,
        //   start,
        //   end,
        //   loggedInUser,
        //   false
        // );

        return;
      case 3:
        setShowDate(false);
        //This Month
        var d = new Date();
        d.setDate(1); //sets d the the first day of that month
        d.setHours(0, 0, 0, 0);
        var stDate = d.getTime();
        var curEndDate = new Date(
          new Date(new Date().setDate(new Date().getDate())).setHours(
            23,
            59,
            59
          )
        ).getTime();

        start = Math.floor(stDate / 1000);
        end = Math.floor(curEndDate / 1000);
        loadCollectionGraph(start, end, false, selectedStatus);
        setStartDate(start);
        setEndDate(end);
        setMonth(false);
        ReactGA.event({
          category: "UPI Payments Graph Current Month",
          action:
            userLoginDetails.autoFinanceOrganization.orgshortcode +
            "_UPI Payments Graph Current Month_" +
            helper.EpochToDate(Math.floor(new Date() / 1000)) +
            ".xlsx",
        });

        // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        // setIsMonthInProgress(false);
        // // loadGraph(start, end, loggedInUser, false);
        // onGraphTypeChange(
        //   currentLabelValue.label,
        //   currentLabelValue.value,
        //   start,
        //   end,
        //   loggedInUser,
        //   false
        // );

        return;
      case 4:
        setShowDate(false);
        //Last month Logic
        // eslint-disable-next-line no-redeclare
        var d = new Date();
        d.setDate(0); //sets d to the last day of the previous month
        d.setHours(0, 0, 0, 0);
        var eDate = d.getTime();
        d.setDate(1); //sets d the the first day of that month
        d.setHours(0, 0, 0, 0);
        var sDate = d.getTime();
        start = Math.floor(sDate / 1000);
        end = Math.floor(eDate / 1000) + 86400;
        loadCollectionGraph(start, end, false, selectedStatus);
        setStartDate(start);
        setEndDate(end);
        setMonth(false);
        ReactGA.event({
          category: "UPI Payments Graph Last Month",
          action:
            userLoginDetails.autoFinanceOrganization.orgshortcode +
            "_UPI Payments Graph Last Month_" +
            helper.EpochToDate(Math.floor(new Date() / 1000)) +
            ".xlsx",
        });

        // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        // setIsMonthInProgress(false);

        // // loadGraph(start, end, loggedInUser, false);
        // onGraphTypeChange(
        //   currentLabelValue.label,
        //   currentLabelValue.value,
        //   start,
        //   end,
        //   loggedInUser,
        //   false
        // );
        break;
      case 5:
        setShowDate(false);
        // eslint-disable-next-line no-redeclare
        var d = new Date();
        d.setDate(1);
        d.setHours(0, 0, 0, 0);
        end = d.getTime() / 1000;
        d.setMonth(d.getMonth() - 6);
        start = d.getTime() / 1000;
        loadCollectionGraph(start, end, true, selectedStatus);
        setStartDate(start);
        setEndDate(end);
        setMonth(true);
        ReactGA.event({
          category: "UPI Payments Graph Last 6 Months",
          action:
            userLoginDetails.autoFinanceOrganization.orgshortcode +
            "_UPI Payments Graph Last 6 Months_" +
            helper.EpochToDate(Math.floor(new Date() / 1000)) +
            ".xlsx",
        });

        // setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        // setIsMonthInProgress(true);
        // // loadGraph(start, end, loggedInUser, true);
        // onGraphTypeChange(
        //   currentLabelValue.label,
        //   currentLabelValue.value,
        //   start,
        //   end,
        //   loggedInUser,
        //   true
        // );
        break;
      case 6:
        setShowDate(true);
        setMonth(false);
        break;
      default:
    }
  };

  const setCustomDatesForPaymentsGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
        1000 +
      86400;
    loadCollectionGraph(start, end, false, selectedStatus);
    ReactGA.event({
      category: "UPI Payments Graph Custom Month",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_UPI Payments Graph Custom Month_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
    // console.log(
    //   "date",
    //   value[0].getTime() / 1000,
    //   "jjj",
    //   new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
    //     1000 +
    //     86400
    // );
  };

  function convertEpochTimeToDate(epochTime, len) {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime * 1000));
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    if (len === 6) return month + " " + year;
    date_str = month + " " + day + "_" + year;
    return date_str;
  }

  return data ? (
    <LoadingOverlay
      active={data?.isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div id="chart" className="line-chart-style bar-chart">
        <Card>
          <Card.Header>
            <h4 className="card-title" id="paymentsGraphHeaderText">UPI Payments Trend</h4>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "140px",
                  marginRight: "25px",
                }}
              >
                <span>Payment Mode</span>
                <div
                id="paymentModeOptions"
                  style={{
                    borderRadius: "3px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => setStatusValue(value.value)}
                    defaultValue={statusOptions[0]}
                    options={statusOptions}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "130px",
                }}
              >
                <span>Select Range</span>
                <div
                id="paymentsDateRange"
                  style={{
                    borderRadius: "3px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) =>
                      setGraphDatesForPayments(value.label, value.value)
                    }
                    defaultValue={chartDateOptions[0]}
                    options={chartDateOptions}
                  />
                </div>
              </div>

              {showDate && (
                <div
                id="paymentsCustomOption"
                  style={{
                    textAlignLast: "center",
                    marginTop: "15px",
                    marginLeft: "10px",
                  }}
                >
                  <Flatpickr
                    style={{
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                    className="form-control d-block"
                    placeholder="Select Month"
                    onChange={(value) => {
                      setCustomDatesForPaymentsGraph(value);
                    }}
                    options={{
                      // mode: "range",
                      // dateFormat: "d-M-y",
                      maxDate: new Date(),
                      minDate: new Date().setMonth(new Date().getMonth() - 6),

                      plugins: [
                        new monthSelectPlugin({
                          shorthand: true, //defaults to false
                          dateFormat: "F Y", //defaults to "F Y"
                          altFormat: "F Y", //defaults to "F Y"
                          theme: "light", // defaults to "light"
                        }),
                      ],
                    }}
                  />
                </div>
              )}
            </div>
          </Card.Header>
          {/* <div style={{ width: "150px" }}>
          <span>Select Range</span>
          <Select
            isSearchable={false}
            onChange={(value) => setGraphDatesForPayments(value.label, value.value)}
            defaultValue={chartDateOptions[0]}
            options={chartDateOptions}
          />
        </div>
        {showDate && (
                  <div
                    style={{
                      textAlignLast: "center",
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                  >
                    <Flatpickr
                      style={{
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                      className="form-control d-block"
                      placeholder="Select Month"
                      onChange={(value) => {
                        setCustomDatesForPaymentsGraph(value);
                      }}
                      options={{
                        // mode: "range",
                        // dateFormat: "d-M-y",
                        maxDate: new Date(),
                        minDate: new Date().setMonth(new Date().getMonth() - 6),

                        plugins: [
                          new monthSelectPlugin({
                            shorthand: true, //defaults to false
                            dateFormat: "F Y", //defaults to "F Y"
                            altFormat: "F Y", //defaults to "F Y"
                            theme: "light", // defaults to "light"
                          }),
                        ],
                      }}
                    />
                  </div>
                )} */}
          <Card.Body style={{ paddingTop: "2px" }}>
            {data?.options && (
              <ReactApexChart
                options={data.options}
                series={data.series}
                type="bar"
                height={400}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </LoadingOverlay>
  ) : (
    <></>
  );
};

export default PaymentsGraph2;
