import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import Select from "react-select";
import swal from "sweetalert";
// import * as nodeApi from "../rest/nodeApi";
import * as Yup from "yup";
// import { DEFAULT_IMAGE } from "../../src/CommonComponents/DefaultImage";
// import TextField from "../../src/CommonComponents/TextField";
// import { toaster } from "../../src/rest/apiUtils";
import * as helper from "../CommonComponents/Helper";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import MyMapComponent from "../Executives/MyMapComponent";
import defaultImage from "../images/defaultImage.png";
import location_image from "../images/location_image.png";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import ManagerAttendance from "./ManagerAttendance";
import ManagerExecutives from "./ManagerExecutives";
import ManagerVisitedPlaces from "./ManagerVisitedPlaces";
import EmployeeLeaveDashboard from "../CommonComponents/EmployeeLeaveDashboard";
import { hasAccess, isAdmin } from "../services/AuthService";
// import RA_Managers from "./RA_Managers";
// import RAManagers from "./RA_Managers";
import GeneralManagers from "./GeneralManagers";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import Payroll from "../CommonComponents/Payroll";
import EmployeePerformanceMetrics from "../Executives/EmployeePerformanceMetrics";
import PtpVisits from "../Executives/PtpVisits";

function ManagerDetails() {
  const [loggedInUser, setloggedInUser] = useState([]);
  // const [setIsImageComing] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [latlongsArr, setLatlongsArr] = useState([]);
  const [random, setRandom] = useState(Math.floor(Math.random() * 100));
  const [isPreview, setIsPreview] = useState(false);
  const [allTransferModal, setAllTransferModal] = useState(false);
  const [updateReportingOfficerModal, setUpdateReportingOfficerModal] = useState(false);
  const [directReportingOfficers, setDirectReportingOfficers] = useState([]);
  const [selectedDirectReportingOfficer, setSelectedDirectReportingOfficer] = useState(null);
  const [selectedTransferMgr, setSelectedTransferMgr] = useState(null);
  const [reportingOfficers, setReportingOfficers] = useState([]);
  const [selectedTransferExe, setSelectedTransferExe] = useState(null);
  const [allAllocationsModal, setAllAllocationsModal] = useState(false);
  const [updateMgrRAModal, setUpdateMgrRAModal] = useState(false);
  const [AllBranchexecutives, setAllBranchExecutives] = useState([]);
  const [gpsData, setGpsData] = useState(null);
  // const [branchNames, setBranchNames] = useState(null);

  var history = useHistory();

  const [editProfile, setEditProfile] = useState(false);
  // const [otpVerification, setOtpVerification] = useState(false);
  // const [emailOtpVerification, setEmailOtpVerification] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  // const [isMobile, setIsMobile] = useState(false);
  // const [newMobile, setNewMobile] = useState("");
  // const [newEmail, setNewEmail] = useState("");
  // const [isEmail, setIsEmail] = useState(false);
  const [managerRAInitVal, setManagerRAInitVal] = useState({
    managerIdsUnderRa: [],
    userId: "",
  });
  const [isMap, setIsMap] = useState(false);
  const [isLocationDetails, setIsLocationDetails] = useState(false);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [mutiManagers, setMutiManagers] = useState([]);
  const [rAManagers, setRAManagers] = useState([]);
  const [defaultRAMgr, setDefaultRAMgr] = useState([]);
  const [defaultReportingOfficer, setDefaultReportingOfficer] = useState(null);

  var isFilePicked = "";
  var managerData = JSON.parse(localStorage.getItem("managerDetails"));
  var userData = JSON.parse(localStorage.getItem("userDetails"));
  const [transferModal, setTransferModal] = useState(false);
  const [branches, setBranches] = useState([]);

  const [selectedBranch, setSelectedBranch] = useState({
    value: managerData.userId,
    label: managerData.branchName,
    branchId: managerData.branchId,
  });

  const [initVal] = useState({
    branchId: managerData.branchId,
    organizationId: "",
    subordinateId: "",
  });

  var defaultbranch = {
    value: managerData.userId,
    label: managerData.branchName,
    branchId: managerData.branchId,
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);
    handleChange(new Date());
    loadManagers();
    // loadGpsData();
    const payLoad = {
      searchKey: "",
      organizationId: userData.autoFinanceUser.organizationId,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
      }));
      setBranches(result);
    });

    //Direct Reporting Officer
    moduleApi
      .postData(apiCall.getUser + managerData?.reportingOfficerId)
      .then((response) => {
        setDefaultReportingOfficer(response?.autoFinanceUser?.name);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var managersPayLoad3 = {
      organizationId: userData.autoFinanceUser.organizationId,
      // roleName: "MANAGER",
      roleName: "ADMIN_MANAGER",
      // status:'ACTIVE'
    };
    // eslint-disable-next-line

    moduleApi.postData(apiCall.usersList, managersPayLoad3).then((response) => {
      console.log("response", response);
      const result = response?.map((item) => ({
        value: item.userId,
        reportingOfficerId: item.reportingOfficerId,
        label: item.name + " - " + getBranch(item.branchId),
      }));
      let temp = result.filter(
        (x) => x?.reportingOfficerId !== managerData?.userId
      );
      setReportingOfficers(
        temp.filter((x) => x?.value !== managerData?.userId)
      );
    });

    var payLoad = {
      branchId: "",
      organizationId: userData.autoFinanceUser.organizationId,
      reportOfficerId: "",
      roleName: "MANAGER",
      searchKey: "",
    };
    moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
      const mgrs = response;
      const size = mgrs.length;
      let managers = [];
      let defMgrs = [];
      for (let index = 0; index < size; index++) {
        const element = mgrs[index];
        const ele = {
          value: element.userId,
          label: element.name + " - " + getBranch(element.branchId),
          branchId: element.branchId,
          // isFixed:
          //   managerData?.reportingOfficerId === element.userId ? true : false,
        };
        managerData?.managerIdsUnderRa?.find(
          (element) => element === ele.value
        ) && defMgrs.push(ele);
        managers.push(ele);
      }

      setManagerRAInitVal({
        userId: managerData.userId,
        managerIdsUnderRa: managerData.managerIdsUnderRa,
      });
      setDefaultRAMgr(defMgrs);
      setValue(orderOptions(defMgrs));
      setRAManagers(managers);
    });
    // eslint-disable-next-line
  }, [branches]);

  const loadManagers = () => {
    var managerDetails = JSON.parse(localStorage.getItem("managerDetails"));
    var mgrIDs = managerDetails.reportingOfficerIds;
    if (mgrIDs.length === 0) return;

    moduleApi.postData(apiCall.getMultipleUsers, mgrIDs).then((response) => {
      const result = response.map((item) => ({
        userId: item.autoFinanceUser.userId,
        name: item.autoFinanceUser.name,
        email: item.autoFinanceUser.email,
        contact: item.autoFinanceUser.mobileNumber,
        empId: item.autoFinanceUser.employeeId,
        branch: item.autoFinanceBranchDetails?.branchName,
        image: item.autoFinanceUser.image,
        status: item.autoFinanceUser.status,
        designation: item.autoFinanceUser.companyDesignation,
      }));

      setMutiManagers(result);
    });
  };

  const loadGpsData = () => {
    let payload = {
      organizationId: userData.autoFinanceUser.organizationId,
      userIds: [managerData?.userId],
    };
    // let m = new Map();
    moduleApi.postData(apiCall.getLiveLocations, payload).then((response2) => {
      console.log("gpsdata2", response2);
      if (response2) {
        setGpsData(response2[0]);
        setIsLocationDetails(true);
      }
      // response2?.forEach((item) => {
      //   m.set(item?.userId, item);
      // });
    });
  };

  const validate = Yup.object({
    branchId: Yup.string().required("Branch is required"),
  });

  const updateBranch = () => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    const payLoad = {
      newBranchId: selectedBranch.branchId,
      oldBranchId: managerData.branchId,
      organizationId: managerData.organizationId,
      transferedByUserId: userData.autoFinanceUser.userId,
      userId: managerData.userId,
    };

    moduleApi.postData(apiCall.updateBranch, payLoad).then((response) => {
      swal("Transferred Successfully", {
        icon: "success",
      });
      setTransferModal(false);
      history.push("/managers");
    });
  };

  const handleChange = (date) => {
    setStartDate(date);
    getData(date);
  };

  const getData = (start) => {
    var managerDetails = JSON.parse(localStorage.getItem("managerDetails"));
    var obj = {
      endTime: Math.round(start.setHours(23, 59, 59, 0) / 1000),
      userId: managerDetails.userId,
      startTime: Math.round(start.setHours(0, 0, 0, 0) / 1000),
      organizationId: managerDetails.organizationId,
    };
    moduleApi.postData(apiCall.getLocations, obj).then((res) => {
      var arr = [];
      setLatlongsArr([]);
      for (let index = 0; index < res.length; index++) {
        var latlngObj = [];
        for (let i = 0; i < res[index].gpsLocationDatas.length; i++) {
          var obj1 = {
            lat: res[index].gpsLocationDatas[i].x,
            lng: res[index].gpsLocationDatas[i].y,
          };
          latlngObj.push(obj1);
        }
        if (latlngObj.length > 0) {
          arr.push(latlngObj);
        }
      }
      setLatlongsArr(arr);
      setRandom(Math.floor(Math.random() * 1000));
    });
  };

  //Edit Profile
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var nameRegex = "[a-zA-Z ]+$";
  // var namePattern = "^[a-zA-Z]";
  const editProfileValidate = Yup.object({
    email: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required"),
    name: Yup.string()
      .trim()
      .matches(nameRegex, "Please Enter Valid Name")
      .required("Name is required"),
    // companyDesignation: Yup.string()
    // .matches(namePattern, "Please Enter a Valid Designation")
    // .required("Company Designation is required")
    // .nullable(true),
  });
  const editProfileHandleOk = (values) => {
    // const payLoad = {
    //   addedByUserId: managerData.addedByUserId,
    //   branchId: managerData.branchId,
    //   email: newEmail === "" ? managerData.email : newEmail,
    //   employeeId: managerData.employeeId,
    //   image: isFilePicked === "" ? currentFile : isFilePicked,
    //   mobileNumber: newMobile === "" ? managerData.mobileNumber : newMobile,
    //   name: values.name,
    //   organizationId: managerData.organizationId,
    //   passwordUpdated: managerData.passwordUpdated,
    //   companyDesignation:null,
    //   // reportingOfficerId: userData.autoFinanceUser.userId,
    //   roleName: managerData.roleName,
    //   status: managerData.status,
    //   userId: managerData.userId,
    // };
    const payLoad = {
      branchId: managerData.branchId,
      companyDesignation: "",
      email: values.email,
      employeeId: managerData.employeeId,
      image: currentFile ? currentFile : managerData.image,
      mobileNumber: values.mobileNumber,
      name: values.name,
      organizationId: managerData.organizationId,
      passwordUpdated: managerData.passwordUpdated,
      reportingOfficerIds: managerData.reportingOfficerIds,
      roleName: managerData.roleName,
      status: managerData.status,
      userId: managerData.userId,
    };
    moduleApi.postData(apiCall.updateUserData, payLoad).then((response) => {
      if (response?.message) {
        swal(response?.message, {
          icon: "error",
          dangerMode: true,
        });
      } else if (response) {
        setEditProfile(false);
        swal("Successfully Updated", {
          icon: "success",
        }).then((willOk) => {
          if (willOk) {
            managerData.name = response.name;
            managerData.mobileNumber = response.mobileNumber;
            managerData.image = response.image
              ? response.image
              : managerData.image;
            // executiveData.designation = response.companyDesignation;
            managerData.email = response.email;
            localStorage.setItem("managerDetails", JSON.stringify(managerData));
            window.location.reload();
            setIsPreview(false);
          }
        });
        // toaster("success", "Successfully Updated");
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };
  // const otpValidate = Yup.object({
  //   otp: Yup.number().required("OTP is required"),
  //   // .min(4, "OTP should be 4 digits")
  //   // .max(4, "OTP should be 4 digits"),
  // });
  // const mobileOtpVerificationHandleOk = (values) => {
  //   const payLoad = {
  //     newMobileNumber: newMobile,
  //     oldMobileNumber: managerData.mobileNumber,
  //     otp: values.otp,
  //     userId: managerData.userId,
  //   };
  //   moduleApi
  //     .putData(apiCall.mobileOtpVerification, payLoad)
  //     .then((response) => {
  //       if (response.Message === "MobileNumber Updated") {
  //         setOtpVerification(false);
  //         setIsMobile(false);
  //         managerData.mobileNumber = newMobile;
  //         localStorage.setItem("managerDetails", JSON.stringify(managerData));
  //         toaster("success", "Manager Mobile Number Updated Successfully");
  //       }
  //       //
  //       //
  //     });
  // };
  // const emailOtpVerificationHandleOk = (values) => {
  //   const payLoad = {
  //     newEmail: newEmail,
  //     oldEmail: managerData.email,
  //     otp: values.otp,
  //     userId: managerData.userId,
  //   };
  //   moduleApi.putData(apiCall.verifyupdateemail, payLoad).then((response) => {
  //     if (response.Message === "Email Updated") {
  //       setEmailOtpVerification(false);
  //       managerData.email = newEmail;
  //       localStorage.setItem("managerDetails", JSON.stringify(managerData));
  //       toaster("success", "Manager Email Updated Successfully");
  //     }
  //   });
  // };
  const handleChange1 = (e) => {
    setIsPreview(true);
    let files = e.target.files;
    if (
      (files[0].type === "image/jpg") |
      (files[0].type === "image/jpeg") |
      (files[0].type === "image/png") |
      (files[0].type === "image/JPEG") |
      (files[0].type === "image/JPG") |
      (files[0].type === "image/PNG")
    ) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setCurrentImage(reader.result);
        });
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
          let fileInfo = {
            base64: reader.result,
          };
          var basedata = fileInfo.base64.split(",");
          isFilePicked = basedata[1];
          managerData.image = isFilePicked;
          setCurrentFile(isFilePicked);
        }; // reader.onload
      } // for
    } else {
      e.target.files = null;
      // toaster("error", "Please Select image Format Only");
      swal("Please Select image Format Only", {
        icon: "error",
        dangerMode: true,
      });
    }
  };

  // const handleMobile = (e) => {
  //   if (e.target.value.length === 10) {
  //     setNewMobile(e.target.value);
  //     if (e.target.value !== managerData.mobileNumber) {
  //       setIsMobile(true);
  //     } else {
  //       setIsMobile(false);
  //       toaster(
  //         "warning",
  //         "Old Mobile Number and New Mobile Number Should not be Same"
  //       );
  //     }
  //   }
  // };
  // const handleMobile1 = (isModalOpen) => {
  //   // setOtpVerification(true);
  //   var obj = {
  //     newMobileNumber: newMobile,
  //     oldMobileNumber: managerData.mobileNumber,
  //     userId: managerData.userId,
  //   };
  //   moduleApi.postData(apiCall.updatemobilenumber, obj).then((res) => {
  //     if (res.Message === "Otp Sent")
  //       if (!isModalOpen) {
  //         setOtpVerification(true);
  //       }
  //     toaster("success", "OTP sent sucessfully");
  //   });
  // };
  // const handleEmail1 = (e) => {
  //   if (e.target.value !== managerData.email) {
  //     setIsEmail(true);
  //     setNewEmail(e.target.value);
  //   } else {
  //     setIsEmail(false);
  //     toaster("warning", "Old Email and New Email Should not be Same");
  //   }
  // };
  // const handleEmail = () => {
  //   const payLoad = {
  //     newEmail: newEmail,
  //     oldEmail: managerData.email,
  //     userId: managerData.userId,
  //   };
  //   moduleApi.putData(apiCall.updateemail, payLoad).then((response) => {
  //     if (response.Message === "Otp Sent") {
  //       setEmailOtpVerification(true);
  //       toaster("success", "OTP Sent Successfully");
  //     }
  //   });
  // };

  const loadReportingOfficersData = () => {

    var payLoad = {
      organizationId:
        userData.autoFinanceUser.organizationId,
      roleName: "ADMIN_MANAGER",
    };
    // eslint-disable-next-line

    moduleApi
      .postData(apiCall.usersList, payLoad)
      .then((response) => {
        const result = response?.map((item) => ({
          value: item.userId,
          label:
            item.name +
            " - " +
            getBranch(item.branchId),
        }));

        setDirectReportingOfficers(
          result.filter(
            (x) => x?.value !== managerData?.userId
          )
        );
      });

      moduleApi
      .postData(apiCall.getUser + managerData.reportingOfficerId)
      .then((response) => {

        const defaultReporingOfficerrr = {
          value:response?.autoFinanceUser.userId,label:response?.autoFinanceUser?.name+" - " +getBranch(response?.autoFinanceUser?.branchId),
        };

        setSelectedDirectReportingOfficer(defaultReporingOfficerrr);
        
        });
  };

  const updateDirectReportingOfficer = (directReportingOfficer,label) => {

    let name = label.split('-')[0];

    var payLoad={
      organizationId:userData.autoFinanceUser.organizationId,
      subordinateId:managerData.userId,
      directReportingOfficerId:directReportingOfficer
    };

    moduleApi
      .postData(apiCall.updatedirectreportingofficer, payLoad)
      .then((response) => {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {

          setDefaultReportingOfficer(name);
          managerData.reportingOfficerId = response.reportingOfficerId;
          managerData.reportingOfficerIds=response.reportingOfficerIds;
          localStorage.setItem(
            "managerDetails",
            JSON.stringify(managerData)
          );
          loadManagers();
          swal("Successfully Updated", {
            icon: "success",
          })
          setUpdateReportingOfficerModal(false);
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const getBranch = (branchId) => {
    for (let i = 0; i < branches?.length; i++) {
      if (branches[i].branchId === branchId) {
        return branches[i].value;
      }
    }
  };

  const getAllTransferSubordinates = () => {
    let payload = {
      newUserId: selectedTransferMgr.value,
      oldUserId: managerData.userId,
      organizationId: userData.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(apiCall.transferSubordinates, payload)
      .then((response) => {
        if (response) {
          if (response.Message === "Users Transferred Successfully") {
            swal("Transferred Successfully!", {
              icon: "success",
            });
          } else if (response.message) {
            swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getAllTransferAllocations = () => {
    let payload = {
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: userData.autoFinanceUser.roleName,
      transferAll: true,
      transferByUserId: userData.autoFinanceUser.userId,
      transferFromExecutiveId: managerData.userId,
      transferToExecutiveId: selectedTransferExe.value,
      transferToReportingOfficerId: !isAdmin()
        ? userData.autoFinanceUser.userId
        : undefined,
      visitIds: [],
    };
    moduleApi.postData(apiCall.transferCustomers, payload).then((response) => {
      if (response) {
        if (response.Message === "Transfered Successfully") {
          swal("Allocations Transferred Successfully!", {
            icon: "success",
          });
          setAllAllocationsModal(false);
        } else if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  // const loadManagersUnderRA = () => {
  //   var managerDetails = JSON.parse(localStorage.getItem("managerDetails"));
  //   var mgrIDs = managerDetails?.managerIdsUnderRa;
  //   if (mgrIDs?.length === 0) return;

  //   moduleApi.postData(apiCall.getMultipleUsers, mgrIDs).then((response) => {
  //     const result = response?.map((item) => ({
  //       userId: item.autoFinanceUser.userId,
  //       name: item.autoFinanceUser.name,
  //     }));

  //     setRAManagers(result);
  //   });
  // };

  // useEffect(() => {
  //   var userLoginData = localStorage.getItem("userDetails");
  //   if (!userLoginData) return;
  //   var userData = JSON.parse(userLoginData);
  //   var payLoad = {
  //     branchId: "",
  //     organizationId: userData.autoFinanceUser.organizationId,
  //     reportOfficerId: "",
  //     roleName: "MANAGER",
  //     searchKey: "",
  //   };
  //   moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
  //     const mgrs = response;
  //     const size = mgrs.length;
  //     let managers = [];
  //     let defMgrs = [];
  //     for (let index = 0; index < size; index++) {
  //       const element = mgrs[index];
  //       const ele = {
  //         value: element.userId,
  //         label: element.name + " - " + getBranch(element.branchId),
  //         branchId: element.branchId,
  //         isFixed:
  //           executiveData?.reportingOfficerId === element.userId ? true : false,
  //       };
  //       executiveData?.reportingOfficerIds?.find(
  //         (element) => element === ele.value
  //       ) && defMgrs.push(ele);
  //       managers.push(ele);
  //     }

  //     setInitVal({
  //       organizationId: executiveData.organizationId,
  //       subordinateId: executiveData.userId,
  //       reportingOfficerIds: executiveData.reportingOfficerIds,
  //     });
  //     setdef(defMgrs);
  //     setValue(orderOptions(defMgrs));
  //     setRAManagers(managers);
  //   });

  //   // eslint-disable-next-line
  // }, [branchNames]);

  const managerRaValidate = Yup.object({
    managerIdsUnderRa: Yup.array().min(1, "Atleast 1 manager is required"),
  });

  const orderOptions = (values) => {
    return (
      values &&
      values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed))
    );
  };

  const [value, setValue] = useState(orderOptions([...rAManagers]));
  const getManagerIds = (list) => {
    let ids = [];
    const mgrs = list ? list : [];
    const size = mgrs.length;
    for (let index = 0; index < size; index++) {
      const element = mgrs[index];
      ids.push(element.value);
    }
    return ids;
  };

  const onChange = (value, { action, removedValue }, form) => {
    let temp = [];

    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          temp.push(removedValue);
          form.setFieldValue("managerIdsUnderRa", getManagerIds(temp));
          swal("You Cannot InActive default Manager", {
            icon: "error",
            dangerMode: true,
          });
          return;
        } else {
          form.setFieldValue("managerIdsUnderRa", getManagerIds(value));
        }
        break;
      case "clear":
        value = rAManagers.filter((v) => v.isFixed);
        form.setFieldValue("managerIdsUnderRa", getManagerIds(value));
        swal("You Cannot InActive default Manager", {
          icon: "error",
          dangerMode: true,
        });
        break;
      case "select-option":
        form.setFieldValue("managerIdsUnderRa", getManagerIds(value));
        break;
      default:
        break;
    }
    value = orderOptions(value);
    setValue(value);
  };

  const updateManagers = (values) => {
    console.log("value", values.managerIdsUnderRa);
    // let temp = [];
    // for (let i = 0; i < value?.length; i++) {
    //   temp.push(value[i].value);
    // }
    // for (let i = 0; i < temp?.length; i++) {
    //   let temp2 = temp[i];
    //   // let rIds= values?.reportingOfficerIds[i]
    //   for (let j = 0; j < values.reportingOfficerIds.length; j++) {
    //     if (temp2 === values?.reportingOfficerIds[j]) {
    //       temp = temp.filter((x) => x !== temp2);
    //     }
    //   }
    // }

    // console.log("temp", temp);
    // if (temp.length>0) {
    //   values.reportingOfficerIds.push(temp);
    // }
    // console.log("repofc", values.reportingOfficerIds);
    moduleApi
      .postData(apiCall.updateManagersUnderRa, values)
      .then((response) => {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.Message === "UpdatedSucessFully") {
          setUpdateMgrRAModal(false);
          return swal("Successfully Updated", {
            icon: "success",
          });
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  return (
    <div className="card" style={{ backgroundColor: "white" }}>
      <div style={{ marginRight: "30px" }}>
        {
        // userData.isSuperAdmin !== true &&
        hasAccess(25) &&
        managerData.status === "ACTIVE" ? (
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="danger btn-rounded"
            onClick={() =>
              swal({
                title: "InActive",
                text: "Are you sure, you want to InActive manager?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  var payLoad = {
                    organizationId: loggedInUser.autoFinanceUser.organizationId,
                    relivedByUserId: loggedInUser.autoFinanceUser.userId,
                    userId: managerData.userId,
                  };
                  moduleApi
                    .postData(apiCall.deleteExecutive, payLoad)
                    .then((response) => {
                      if (response) {
                        if (response.Message === "User Relived Suceesfully") {
                          swal(managerData.name, "InActive successfully!", {
                            icon: "success",
                          });
                          history.push("/managers");
                        } else if (
                          response?.message ===
                          "Managers/Executives are associated with the Manager. Please reassign them to a different Manager before Transfer."
                        ) {
                          // toaster("error", response?.message);
                          return swal({
                            title: "Re-Assign",
                            text: "Managers/Executives are associated with the Manager. Please reassign them to a different Manager before Inactivating. Do you want to reassign all the executives or managers to a new manager?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              setAllTransferModal(true);
                            }
                          });
                        } else if (
                          response?.message ===
                          "User has Visits allocated, Please reassign them to a different user before Inactivating"
                        ) {
                          // toaster("error", response?.message);
                          return swal({
                            title: "Re-Assign",
                            text: "Executive has Visits allocated, Please reassign them to a different Executive. Do you want to reassign all the allocations to a new executive?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              var executivesPayLoad2 = {
                                organizationId:
                                  userData.autoFinanceUser.organizationId,
                                roleName: "EXECUTIVE",
                              };
                              // eslint-disable-next-line

                              // let requestPayload = {
                              //   organizationId: userData.autoFinanceUser.organizationId,
                              //   reportingOfficerId:
                              //     userData.autoFinanceUser.roleName === "MANAGER" ||
                              //       userData.autoFinanceUser.roleName === "MANAGER_RA"
                              //       ? userData.autoFinanceUser.userId
                              //       : null,
                              //   branchId:userData.autoFinanceBranchDetails.branchId,
                              //   roleName: userData.autoFinanceUser.roleName,
                              // };
                              // nodeApi
                              //   .postData(apiCall.getUsersByOrganization, requestPayload)
                              //   .then((response) => {
                              //     if (response.success === true) {
                              //       if (response.users) {
                              //         const result = response.users.map((item, index) => ({
                              //           value: item.user_id,
                              //           label: item.name + " - " + item.branch_name,
                              //         }));
                              //         let filtered = result.filter(function (item) {
                              //           return managerData?.userId.indexOf(item.value) === -1;
                              //         });
                              //         filtered.sort(function (a, b) {
                              //           if (a.label < b.label) { return -1; }
                              //           return 0;
                              //         })
                              //         setAllBranchExecutives(filtered);
                              //       }
                              //     }
                              //   });

                              moduleApi
                                .postData(apiCall.usersList, executivesPayLoad2)
                                .then((response) => {
                                  const result = response?.map((item) => ({
                                    value: item.userId,
                                    label:
                                      item.name +
                                      " - " +
                                      getBranch(item.branchId),
                                  }));

                                  setAllBranchExecutives(
                                    result.filter(
                                      (x) => x?.value !== managerData?.userId
                                    )
                                  );
                                });
                              setAllAllocationsModal(true);
                            }
                          });
                        } else if (response?.message) {
                          // toaster('error',response?.message )
                          swal(managerData.name, response?.message, {
                            icon: "error",
                            dangerMode: true,
                          });
                        }
                      } else {
                        swal("Something Went Wrong, Please Try Later", {
                          icon: "error",
                          dangerMode: true,
                        });
                      }
                    });
                }
              })
            }
          >
            <i className="fas fa-trash" />
            &nbsp;&nbsp;InActive
          </Button>
        ) : (
          // userData.isSuperAdmin !== true &&
          hasAccess(15) && (
            <Button
              style={{
                float: "right",
                margin: "10px",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="success btn-rounded"
              onClick={() =>
                swal({
                  title: "Activate",
                  text: "Do you really want to activate manager?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    var payLoad = {
                      organizationId:
                        loggedInUser.autoFinanceUser.organizationId,
                      relivedByUserId: loggedInUser.autoFinanceUser.userId,
                      userId: managerData.userId,
                    };
                    moduleApi
                      .postData("users/reactivateemployee", payLoad)
                      .then((response) => {
                        swal(
                          managerData.name,
                          "Manager Status Changed to Active Successfully!",
                          {
                            icon: "success",
                          }
                        );
                        history.push("/managers");
                      });
                  }
                })
              }
            >
              Activate
            </Button>
          )
        )}
        {
        // userData.isSuperAdmin !== true &&
          hasAccess(14) &&
          managerData?.roleName !== "MANAGER_RA" && (
            <Button
              style={{
                float: "right",
                marginTop: "10px",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => {
                let path;
                if (managerData.roleName === "MANAGER_RA") {
                  path = "/managerRA";
                  path += "/transfer";
                } else {
                  path = "/managers";
                  path += "/transfer";
                }

                history.push(path);
                // setEditProfile(true);
              }}
            >
              <i className="fas fa-external-link-alt" />
              &nbsp;&nbsp;Transfer
            </Button>
          )}
        {
        // userData?.isSuperAdmin !== true &&
          managerData?.roleName === "MANAGER_RA" && (
            <Button
              style={{
                float: "right",
                margin: "10px",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => {
                // loadManagersUnderRA();
                setUpdateMgrRAModal(true);
              }}
            >
              Assign Managers
            </Button>
          )}

        {
        // userData.isSuperAdmin !== true && 
        hasAccess(38) && (
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => {
              swal({
                title: "Reset Password",
                text:
                  "Do you really want to reset password for the " +
                  managerData.name,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  var payLoad = {
                    organizationId: loggedInUser.autoFinanceUser.organizationId,
                    resetDoneByUserId: loggedInUser.autoFinanceUser.userId,
                    userId: managerData.userId,
                  };
                  moduleApi
                    .postData(apiCall.resetPasswordForParticularUser, payLoad)
                    .then((response) => {
                      if (response?.Message === "Reset Done") {
                        swal(managerData.name, "Password Reset Successfully!", {
                          icon: "success",
                        });
                        // history.push("/manager");
                      } else if (response?.message) {
                        // toaster("error", response?.message);
                        swal(managerData.name, response?.message, {
                          icon: "error",
                          dangerMode: true,
                        });
                      } else {
                        swal("Something Went Wrong, Please Try Again Later", {
                          icon: "error",
                          dangerMode: true,
                        });
                      }
                    });
                }
              });
            }}
          >
            <i className="fas fa-redo-alt" />
            &nbsp;&nbsp;Reset Password
          </Button>
        )}

        {
        // userData.isSuperAdmin !== true && 
        hasAccess(24) && (
          <Button
            style={{
              float: "right",
              marginTop: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => {
              setEditProfile(true);
            }}
          >
            <i className="fa fa-edit" />
            &nbsp;&nbsp;Edit
          </Button>
        )}

        <Button
          style={{
            float: "left",
            margin: "10px",
            marginLeft: "20px",
            marginTop: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => history.push(localStorage.getItem("prevUrl"))}
        >
          <span>
            <i className="fa fa-arrow-left" />
          </span>
        </Button>
        <span
          style={{
            float: "left",
            marginLeft: "10px",
            margin: "10px",
            marginTop: "16px",
            fontSize: "18px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            fontWeight: "bold",
          }}
        >
          {managerData.name ? managerData.name : "-"}
        </span>
      </div>
      <div style={{ margin: "20px" }}>
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey="basicInfo">
            <Nav as="ul" className="nav-tabs-custom nav-justified">
              <Nav.Item as="li" key="0">
                <Nav.Link eventKey="basicInfo">
                  <b> Basic Info </b>
                </Nav.Link>
              </Nav.Item>
              {isAdmin() ||
              userData.autoFinanceUser.subOrdinateManagersIds.length > 0 ? (
                <Nav.Item as="li" key="6">
                  <Nav.Link eventKey="managers">
                    <b> Managers </b>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <></>
              )}
              <Nav.Item as="li" key="1">
                <Nav.Link eventKey="executives">
                  <b> Executives </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="2">
                <Nav.Link eventKey="visitedPlaces">
                  <b> Visits </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="9">
                <Nav.Link eventKey="ptp">
                  <b> PTP Calender </b>
                </Nav.Link>
              </Nav.Item>
              {hasAccess(13) && (
                <Nav.Item as="li" key="3">
                  <Nav.Link eventKey="managerAttendance">
                    <b> Attendance </b>
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item as="li" key="8">
                  <Nav.Link eventKey="employeePerformanceMetrics">
                    <b> Employee Performance </b>
                  </Nav.Link>
                </Nav.Item>
              {hasAccess(8) && (
                <Nav.Item as="li" key="4">
                  <Nav.Link eventKey="track">
                    <b> Track </b>
                  </Nav.Link>
                </Nav.Item>
              )}
              {hasAccess(2) && (
                <Nav.Item as="li" key="5">
                  <Nav.Link eventKey="leave">
                    <b> Leaves </b>
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item as="li" key="7">
                <Nav.Link eventKey="payroll">
                  <b> Payroll </b>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey="basicInfo" key="0" mountOnEnter>
                <div>
                  <div
                    className="card contact-bx item-content"
                    style={{
                      backgroundColor: "rgb(243,240,249)",
                      marginLeft: "150px",
                      marginRight: "150px",
                    }}
                  >
                    <div className="card-header border-0"></div>
                    <div className="card-body user-profile">
                      <div>
                        <h6 className="fs-18 font-w600 my-1">
                          Manager Details
                        </h6>
                      </div>
                      <Row>
                        <Col>
                          <table style={{ marginBottom: "15px" }}>
                            <>
                              <tr></tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Employee ID
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {managerData.employeeId
                                    ? managerData.employeeId
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Name
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {managerData.name ? managerData.name : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Branch Name
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  <div>
                                    {managerData.branchName
                                      ? managerData.branchName
                                      : "-"}
                                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {hasAccess(15) && (
                                      <i
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        className="fa fa-edit"
                                        onClick={() => setTransferModal(true)}
                                      />
                                    )} */}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Direct Reporting Officer
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {defaultReportingOfficer
                                    ? defaultReportingOfficer
                                    : "-"}<span
                                    style={{
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => {
                                      loadReportingOfficersData();
                                      setUpdateReportingOfficerModal(true);
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit text-primary me-2"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Mobile
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {managerData.mobileNumber
                                    ? managerData.mobileNumber
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Email
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {managerData.email ? managerData.email : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Designation
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {managerData.designation
                                    ? managerData.designation
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Status
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      managerData.status === "ACTIVE"
                                        ? "green"
                                        : "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {managerData.status
                                    ? managerData.status
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Address
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {managerData.branchLocation
                                    ? managerData.branchLocation
                                    : "-"}
                                </td>
                              </tr>
                              {/* <tr>&nbsp;</tr> */}
                              <tr style={{ marginTop: "5px" }}>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Show Location Details
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td className="form-check form-switch">
                                  <input
                                    style={{
                                      width: "45px",
                                      height: "20px",
                                    }}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    checked={isLocationDetails}
                                    onChange={() => {
                                      isLocationDetails===false && loadGpsData();
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                               <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Enable Geofencing
                                </td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td className="form-check form-switch">
                                  <input
                                    style={{
                                      width: "45px",
                                      height: "20px",

                                    }}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    checked={managerData.geofenceEnabled}
                                    onChange={() => {
                                      swal({
                                        title: "GeoFence",
                                        text: "Are you sure, you want to update Geo-Fence",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willUdate) => {
                                        if (willUdate) {
                                          var payLoad = {
                                            organizationId: loggedInUser.autoFinanceUser.organizationId,
                                            userId: managerData.userId,
                                            enableGeofence: !managerData.geofenceEnabled,
                                          };
                                          moduleApi
                                            .postData(apiCall.enableordisablegeofencing, payLoad)
                                            .then((response) => {
                                              if (response?.Message === "Geo fencing Updated Successfully") {
                                                managerData.geofenceEnabled = !managerData.geofenceEnabled
                                                localStorage.setItem(
                                                  "managerDetails",
                                                  JSON.stringify(managerData)
                                                );
                                                swal("Geo fencing Updated Successfully!", {
                                                  icon: "success",
                                                }).then((ok) => {
                                                  if (ok) {
                                                    window.location.reload();
                                                  }
                                                })
                                              } else if (response?.Message) {

                                                swal(response?.message, {
                                                  icon: "error",
                                                  dangerMode: true,
                                                });

                                              } else {
                                                swal("Something Went Wrong, Please Try Again Later", {
                                                  icon: "error",
                                                  dangerMode: true,
                                                });
                                              }
                                            });
                                        }
                                      })
                                    }
                                    }
                                  />
                                </td>
                            </tr>
                            &nbsp;
                            {hasAccess(55) && (
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  {(managerData?.privilageIds?.includes(
                                    30
                                  ) === true
                                    ? "Disable"
                                    : "Enable") + " Add Subscription Privilege"}
                                </td>
                                <td style={{verticalAlign:'middle'}}>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td className="form-check form-switch" style={{marginTop:'5px'}}>
                                  <input
                                    style={{
                                      width: "45px",
                                      height: "20px",
                                    }}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    checked={managerData?.privilageIds?.includes(
                                      30
                                    )}
                                    onChange={() => {
                                      swal({
                                        title:
                                          "Update Add Subscription Privilege",
                                        text: "Are you sure, you want to update privilege for adding subscriptions",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willUdate) => {
                                        if (willUdate) {
                                          var payLoad = {
                                            // organizationId: loggedInUser.autoFinanceUser.organizationId,
                                            userId: managerData.userId,
                                            add: !managerData?.privilageIds?.includes(
                                              30
                                            ),
                                          };
                                          moduleApi
                                            .postData(
                                              apiCall.updatePrivilegeForSubscription,
                                              payLoad
                                            )
                                            .then((response) => {
                                              if (
                                                response?.Message ===
                                                "Privilage Updated Sucessfully"
                                              ) {
                                                // executiveData.privilageIds =
                                                managerData?.privilageIds?.includes(
                                                  30
                                                ) === false
                                                  ? managerData?.privilageIds.push(
                                                      30
                                                    )
                                                  : managerData?.privilageIds?.splice(
                                                    managerData?.privilageIds.indexOf(
                                                        30
                                                      ),
                                                      1
                                                    );
                                                localStorage.setItem(
                                                  "managerDetails",
                                                  JSON.stringify(managerData)
                                                );
                                                swal(
                                                  "Privilege To Add Subscriptions Has Been " +
                                                    (!managerData?.privilageIds?.includes(
                                                      30
                                                    )
                                                    ? "Disabled"
                                                    : "Enabled") +
                                                    " For " +
                                                    managerData?.name,
                                                  {
                                                    icon: "success",
                                                  }
                                                ).then((ok) => {
                                                  if (ok) {
                                                    window.location.reload();
                                                  }
                                                });
                                              } else if (response?.Message) {
                                                swal(response?.message, {
                                                  icon: "error",
                                                  dangerMode: true,
                                                });
                                              } else {
                                                swal(
                                                  "Something Went Wrong, Please Try Again Later",
                                                  {
                                                    icon: "error",
                                                    dangerMode: true,
                                                  }
                                                );
                                              }
                                            });
                                        }
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            )}
                           &nbsp;
    {hasAccess(58) && (
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  {(managerData?.privilageIds?.includes(
                                    57
                                  ) === true
                                    ? "Disable"
                                    : "Enable") + "Loan Number Edit Privilege For Subscription"}
                                </td>
                                <td style={{verticalAlign:'middle'}}>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                <td className="form-check form-switch" style={{marginTop:'5px'}}>
                                  <input
                                    style={{
                                      width: "45px",
                                      height: "20px",
                                    }}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    checked={managerData?.privilageIds?.includes(
                                      57
                                    )}
                                    onChange={() => {
                                      swal({
                                        title:
                                          "Update Loan Number Edit Privilege For Subscription",
                                        text: "Are you sure, you want to update  Loan Number Edit Privilege For Subscription",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willUdate) => {
                                        if (willUdate) {
                                          var payLoad = {
                                            // organizationId: loggedInUser.autoFinanceUser.organizationId,
                                            userId: managerData.userId,
                                            add: !managerData?.privilageIds?.includes(
                                              57
                                            ),
                                          };
                                          moduleApi
                                            .postData(
                                              apiCall.updateprivilagetoeditsubscriptionloannumber,
                                              payLoad
                                            )
                                            .then((response) => {
                                              if (
                                                response?.Message ===
                                                "Privilage Updated Sucessfully"
                                              ) {
                                                // executiveData.privilageIds =
                                                managerData?.privilageIds?.includes(
                                                  57
                                                ) === false
                                                  ? managerData?.privilageIds.push(
                                                      57
                                                    )
                                                  : managerData?.privilageIds?.splice(
                                                    managerData?.privilageIds.indexOf(
                                                        57
                                                      ),
                                                      1
                                                    );
                                                localStorage.setItem(
                                                  "managerDetails",
                                                  JSON.stringify(managerData)
                                                );
                                                swal(
                                                  "Privilege To Edit Loan Number For Subscriptions Has Been " +
                                                    (!managerData?.privilageIds?.includes(
                                                      57
                                                    )
                                                    ? "Disabled"
                                                    : "Enabled") +
                                                    " For " +
                                                    managerData?.name,
                                                  {
                                                    icon: "success",
                                                  }
                                                ).then((ok) => {
                                                  if (ok) {
                                                    window.location.reload();
                                                  }
                                                });
                                              } else if (response?.Message) {
                                                swal(response?.message, {
                                                  icon: "error",
                                                  dangerMode: true,
                                                });
                                              } else {
                                                swal(
                                                  "Something Went Wrong, Please Try Again Later",
                                                  {
                                                    icon: "error",
                                                    dangerMode: true,
                                                  }
                                                );
                                              }
                                            });
                                        }
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            )}
                              {/* <tr>
                              <div
                                className="form-check form-switch"
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "initial",
                                }}
                              >
                                <td>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckDefault"
                                    style={{ fontWeight: "500",fontSize: "13px" , textAlign: "left"}}
                                  >
                                    Enable Geofencing
                                  </label>
                                </td>
                                &nbsp;&nbsp;&nbsp;:
                                <td>
                                  <input
                                    style={{
                                      width: "45px",
                                      height: "20px",
                                      marginLeft: "10px",
                                      fontWeight: "bold",
                                    }}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    checked={managerData.geofenceEnabled}
                                    onChange={() => {
                                      swal({
                                        title: "GeoFence",
                                        text: "Are you sure, you want to update Geo-Fence",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willUdate) => {
                                        if (willUdate) {
                                          var payLoad = {
                                            organizationId: loggedInUser.autoFinanceUser.organizationId,
                                            userId: managerData.userId,
                                            enableGeofence: !managerData.geofenceEnabled,
                                          };
                                          moduleApi
                                            .postData(apiCall.enableordisablegeofencing, payLoad)
                                            .then((response) => {
                                              if (response?.Message === "Geo fencing Updated Successfully") {
                                                managerData.geofenceEnabled = !managerData.geofenceEnabled
                                                localStorage.setItem(
                                                  "managerDetails",
                                                  JSON.stringify(managerData)
                                                );
                                                swal("Geo fencing Updated Successfully!", {
                                                  icon: "success",
                                                }).then((ok) => {
                                                  if (ok) {
                                                    window.location.reload();
                                                  }
                                                })
                                              } else if (response?.Message) {

                                                swal(response?.message, {
                                                  icon: "error",
                                                  dangerMode: true,
                                                });

                                              } else {
                                                swal("Something Went Wrong, Please Try Again Later", {
                                                  icon: "error",
                                                  dangerMode: true,
                                                });
                                              }
                                            });
                                        }
                                      })
                                    }
                                    }
                                  />
                                </td>
                              </div>
                            </tr> */}
                            </>
                          </table>
                        </Col>
                        <Col>
                          <SRLWrapper options={options1}>
                            <img
                              //src={defaultImage}
                              style={{
                                height: "100px",
                                marginRight: "100px",
                                cursor: "pointer",
                                width: "100px",
                                borderRadius: "50px",
                              }}
                              src={
                                managerData.image
                                  ? managerData.image
                                  : defaultImage
                              }
                              alt=""
                            />
                          </SRLWrapper>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {isLocationDetails && (
                    <div
                      className="card contact-bx item-content"
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        marginLeft: "150px",
                        marginRight: "150px",
                        padding: "20px",
                      }}
                    >
                      <div>
                        <h6 className="fs-18 font-w600 my-1">
                          Location Details
                        </h6>
                      </div>
                      <Row>
                        <Col>
                          <table>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                }}
                              >
                                Battery Status
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                <div>
                                  {console.log("battery", gpsData)}
                                  {gpsData?.batteryPercentage > 75 ? (
                                    <span
                                      style={{
                                        color: "green",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i className="fas fa-battery-full fa-2x fa-rotate-0" />
                                    </span>
                                  ) : gpsData?.batteryPercentage > 30 ? (
                                    <span
                                      style={{
                                        color: "orange",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i className="fas fa-battery-three-quarters fa-2x fa-rotate-0 " />
                                    </span>
                                  ) : gpsData?.batteryPercentage > 0 ? (
                                    <span
                                      style={{
                                        color: "red",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i className="fas fa-battery-quarter fa-2x fa-rotate-0 " />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  <span
                                    style={{
                                      marginLeft:
                                        gpsData?.batteryPercentage === 0 ||
                                        gpsData?.batteryPercentage === undefined
                                          ? "0px"
                                          : "3px",
                                    }}
                                  >
                                    {gpsData?.batteryPercentage === 0 ||
                                    gpsData?.batteryPercentage === undefined
                                      ? "N/A"
                                      : gpsData?.batteryPercentage + "%"}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                  verticalAlign: "top",
                                }}
                              >
                                Last updated Time
                              </td>
                              <td style={{ verticalAlign: "top" }}>
                                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {gpsData?.gpsData ? (
                                  <p
                                    style={{
                                      cursor: "pointer",
                                      verticalAlign: "middle",
                                    }}
                                    title={helper.EpochToDate(
                                      gpsData?.gpsData.t
                                    )}
                                  >
                                    {helper.EpochToDate(gpsData?.gpsData.t)}
                                  </p>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "left",
                                }}
                              >
                                Last Updated Location
                              </td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {gpsData?.gpsData ? (
                                  <img
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setLatLng({
                                        lat: gpsData?.gpsData.x,
                                        lng: gpsData?.gpsData.y,
                                      });
                                      setIsMap(true);
                                    }}
                                    src={location_image}
                                    alt=""
                                    className="img-fluid rounded-circle"
                                  />
                                ) : (
                                  <>-</>
                                )}
                              </td>
                            </tr>
                          </table>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <div>
                    <h4>Reporting Officer Details</h4>
                    <Table responsive hover bordered>
                      <thead style={{ backgroundColor: "#F3F0F9" }}>
                        <tr>
                          <th style={{ width: "150px", fontSize: "15px" }}>
                            Employee ID
                          </th>
                          <th style={{ width: "150px", fontSize: "15px" }}>
                            Name
                          </th>
                          <th style={{ width: "150px", fontSize: "15px" }}>
                            Email
                          </th>
                          <th style={{ width: "150px", fontSize: "15px" }}>
                            Contact
                          </th>
                          <th style={{ width: "150px", fontSize: "15px" }}>
                            Branch
                          </th>
                          <th style={{ width: "150px", fontSize: "15px" }}>
                            Designation
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {mutiManagers.map((item) => {
                          return (
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={item.image ? item.image : defaultImage}
                                    className="rounded-lg me-2"
                                    width="24"
                                    alt=""
                                  />{" "}
                                  <span className="w-space-no">
                                    {item.empId}
                                  </span>
                                </div>{" "}
                              </td>
                              {/* <td style={{ width: '150px', fontSize: '15px' }}>{item.empId}</td> */}
                              <td style={{ width: "150px", fontSize: "15px" }}>
                                {item.name}
                              </td>
                              <td style={{ width: "150px", fontSize: "15px" }}>
                                {item.email}
                              </td>
                              <td style={{ width: "150px", fontSize: "15px" }}>
                                {item.contact}
                              </td>
                              <td style={{ width: "150px", fontSize: "15px" }}>
                                {item.branch}
                              </td>
                              <td style={{ width: "150px", fontSize: "15px" }}>
                                {item.designation}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {mutiManagers.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <img src={NO_DATA_FOUND} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="executives" key="1" mountOnEnter>
                <ManagerExecutives
                  userId={managerData.userId}
                  organizationId={managerData.organizationId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="visitedPlaces" key="2" mountOnEnter>
                <ManagerVisitedPlaces userId={managerData.userId} />
              </Tab.Pane>
              <Tab.Pane eventKey="ptp" key="9" mountOnEnter>
                <PtpVisits
                  userId={managerData.userId}
                  organisationId={managerData.organizationId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="managerAttendance" key="3" mountOnEnter>
                <ManagerAttendance userId={managerData.userId} />
              </Tab.Pane>
              <Tab.Pane eventKey="employeePerformanceMetrics" key="8" mountOnEnter>
                <EmployeePerformanceMetrics userId={managerData.userId} />
              </Tab.Pane>
              <Tab.Pane eventKey="track" key="4" mountOnEnter>
                <div
                  style={{
                    width: "200px",
                    marginLeft: "auto",
                    paddingLeft: "10px",
                    border: "1px solid",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      label=""
                      clearable
                      format="dd/MM/yyyy"
                      disableFuture
                      value={startDate}
                      onChange={handleChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <br></br>
                <MyMapComponent latlongsArr={latlongsArr} key={random} />
              </Tab.Pane>
              <Tab.Pane eventKey="leave" key="5" mountOnEnter>
                <EmployeeLeaveDashboard
                  userId={managerData.userId}
                  reportingOfficerId={managerData.reportingOfficerId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="managers" key="6">
                <GeneralManagers />
              </Tab.Pane>
              <Tab.Pane eventKey="payroll" key="7" mountOnEnter>
                <Payroll
                  userId={managerData.userId}
                  organizationId={managerData.organizationId}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <Modal className="fade" show={isMap}>
          <Modal.Header>
            <Modal.Title>Visit Location</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setIsMap(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
          </Modal.Body>
        </Modal>

        <Modal className="fade" show={editProfile}>
          <Modal.Header>
            <Modal.Title>Edit Manager Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setIsPreview(false);
                setEditProfile(false);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Formik
                initialValues={{
                  email: managerData.email,
                  image: "",
                  mobileNumber: managerData.mobileNumber,
                  name: managerData.name,
                  organizationId: "",
                  reportingOfficerId: "",
                  roleName: managerData.roleName,
                  // designation: executiveData.designation,
                  branchLocation: "",
                  branchName: "",
                }}
                onSubmit={editProfileHandleOk}
                validationSchema={editProfileValidate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {isPreview ? (
                              <img
                                src={currentImage ? currentImage : defaultImage}
                                className="img-fluid rounded-circle"
                                alt="profile"
                                style={{
                                  cursor: "pointer",
                                  borderRadius: "50px",
                                  height: "100px",
                                  width: "100px",
                                }}
                              />
                            ) : (
                              <img
                                // src={currentImage}
                                src={
                                  managerData.image
                                    ? managerData.image
                                    : defaultImage
                                }
                                className="img-fluid rounded-circle"
                                alt="profile"
                                style={{
                                  cursor: "pointer",
                                  borderRadius: "50px",
                                  height: "100px",
                                  width: "100px",
                                }}
                              />
                            )}
                            <input
                              style={{
                                marginLeft: "150px",
                                marginBottom: "5px",
                                marginTop: "10px",
                              }}
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleChange1(e)}
                            />
                          </div>
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "5px",
                              marginTop: "5px",
                            }}
                          >
                            Name
                          </label>
                          <Field
                            type="text"
                            name="name"
                            className={`form-control`}
                          />
                          <ErrorMessage
                            component="div"
                            className="error"
                            name="name"
                          />
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "5px",
                              marginTop: "5px",
                            }}
                          >
                            Mobile
                          </label>
                          <Field
                            type="text"
                            name="mobileNumber"
                            className={`form-control`}
                          />
                          <ErrorMessage
                            component="div"
                            className="error"
                            name="mobileNumber"
                          />
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "5px",
                              marginTop: "5px",
                            }}
                          >
                            Email ID
                          </label>
                          <Field
                            type="text"
                            name="email"
                            className={`form-control`}
                          />
                          <ErrorMessage
                            component="div"
                            className="error"
                            name="email"
                          />
                          {/* <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "5px",
                              marginTop: "5px",
                            }}
                          >
                            Company Designation
                          </label>
                          <Field
                            type="text"
                            name="designation"
                            className={`form-control`}
                          />
                          <ErrorMessage
                            component="div"
                            className="error"
                            name="designation"
                          /> */}
                          <div style={{ marginTop: "10px" }}>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2"
                              type="submit"
                            >
                              Update
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2 float-right"
                              variant="danger light"
                              onClick={() => {
                                setIsPreview(false);
                                setEditProfile(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>

        {/* <Modal className="fade" show={editProfile}>
          <Modal.Header>
            <Modal.Title>Edit Manager Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setEditProfile(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Formik
                initialValues={{
                  email: managerData.email,
                  image: "",
                  mobileNumber: managerData.mobileNumber,
                  name: managerData.name,
                  organizationId: "",
                  reportingOfficerId: "",
                  roleName: managerData.roleName,
                  branchLocation: "",
                  branchName: "",
                  // companyDesignation:managerData.designation,
                }}
                onSubmit={editProfileHandleOk}
                validationSchema={editProfileValidate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div>
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  managerData.image
                                    ? managerData.image
                                    : defaultImage
                                }
                                alt="profile"
                                style={{
                                  cursor: "pointer",
                                  borderRadius: "50px",
                                  height: "100px",
                                  width: "100px",
                                }}
                              />
                              <input
                                style={{
                                  marginLeft: "150px",
                                  marginBottom: "5px",
                                  marginTop: "10px",
                                }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleChange1(e)}
                              />
                            </div>
                            <label
                              className="control-label"
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                marginBottom: "10px",
                              }}
                            >
                              Name
                            </label>
                            <Field
                              type="text"
                              name="name"
                              className={`form-control`}
                            />
                            <ErrorMessage
                              component="div"
                              className="error"
                              name="name"
                            />
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                  height: "100",
                                  width: "100",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Mobile Number
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  defaultValue={managerData.mobileNumber}
                                  onChange={(e) => handleMobile(e)}
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "12px",
                                    right: "35px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleMobile1(false)}
                                  >
                                    {isMobile ? "Save" : ""}
                                  </span>
                                </div>
                              </div>
                              <ErrorMessage
                                component="div"
                                className="error"
                                name="mobileNumber"
                              />
                            </div>
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                  height: "100",
                                  width: "100",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Email ID
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={managerData.email}
                                  onChange={(e) => handleEmail1(e)}
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "10px",
                                    right: "17px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleEmail()}
                                  >
                                    {isEmail ? "Save" : ""}
                                  </span>
                                </div>
                              </div>
                              <ErrorMessage
                                component="div"
                                className="error"
                                name="email"
                              />
                            </div>
                            
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2"
                              type="submit"
                            >
                              Update
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              className="col-lg-2 m-2 float-right"
                              variant="danger light"
                              onClick={() => setEditProfile(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal> */}

        {/* <Modal size="sm" className="fade" show={otpVerification}>
          <Modal.Header>
            <Modal.Title>Mobile OTP Verification</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setOtpVerification(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div>
              <Formik
                initialValues={{
                  otp: "",
                }}
                onSubmit={mobileOtpVerificationHandleOk}
                validationSchema={otpValidate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div>
                            <TextField
                              isAsterisk={true}
                              label="OTP"
                              name="otp"
                              type="number"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                cursor: "pointer",
                                color: "red",
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <span onClick={() => handleMobile1(true)}>
                                Resend OTP
                              </span>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <Button
                                style={{
                                  float: "right",
                                  marginLeft: "10px",
                                }}
                                //className="col-lg-2 m-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                // className="col-lg-2 m-2 float-right"
                                variant="danger light"
                                onClick={() => setOtpVerification(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
        <Modal size="sm" className="fade" show={emailOtpVerification}>
          <Modal.Header>
            <Modal.Title>Email OTP Verification</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setEmailOtpVerification(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div>
              <Formik
                initialValues={{
                  otp: "",
                }}
                onSubmit={emailOtpVerificationHandleOk}
                validationSchema={otpValidate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div>
                            <TextField
                              isAsterisk={true}
                              label="OTP"
                              name="otp"
                              type="number"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                cursor: "pointer",
                                color: "red",
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <span onClick={() => handleEmail()}>
                                Resend OTP
                              </span>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <Button
                                style={{
                                  float: "right",
                                  marginLeft: "10px",
                                }}
                                type="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                variant="danger light"
                                onClick={() => setEmailOtpVerification(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal> */}
        <Modal className="fade" show={transferModal}>
          <Modal.Header>
            <Modal.Title>Transfer Branch</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setTransferModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Formik
                enableReinitialize
                initialValues={initVal}
                onSubmit={updateBranch}
                validationSchema={validate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div>
                            <Field name="branchId">
                              {({ field, form }) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label
                                    className="control-label"
                                    style={{
                                      fontWeight: "bold",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Branch
                                  </label>
                                  <Select
                                    placeholder="Select a branch"
                                    classNamePrefix="select-box"
                                    options={branches}
                                    defaultValue={defaultbranch}
                                    onChange={(value) => {
                                      setSelectedBranch({
                                        value: value.value,
                                        label: value.label,
                                        branchId: value.branchId,
                                      });
                                      form.setFieldValue(
                                        "branchId",
                                        value.branchId
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </Field>
                            {errors.branchId && (
                              <span className="error">{errors.branchId}</span>
                            )}
                            <div style={{ marginTop: "10px" }}>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2 float-right"
                                variant="danger light"
                                onClick={() => setTransferModal(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>

        <Modal className="fade" show={allTransferModal}>
          <Modal.Header>
            <Modal.Title>Transfer</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setAllTransferModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginTop: "5px",
                  }}
                >
                  Reporting Officer
                </label>
                <Select
                  placeholder="Select Reporting Officer"
                  classNamePrefix="select-box"
                  options={reportingOfficers}
                  // defaultValue={defaultMgr}
                  // value={selectedManagers}
                  onChange={(value) => {
                    console.log("value", value);
                    setSelectedTransferMgr({
                      value: value.value,
                      label: value.label,
                    });
                  }}
                />
              </div>
              <Button
                style={{
                  float: "right",
                  marginTop: "15px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => {
                  if (selectedTransferMgr === null) {
                    return swal("Please Select Reporting officer", {
                      icon: "error",
                      dangerMode: true,
                    });
                  } else {
                    setAllTransferModal(false);
                    swal({
                      title: "Re-Assign",
                      text: "Are you sure, you want to reassign all the executives or managers to the new selected manager?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        getAllTransferSubordinates();

                        // history.push("/executive");
                      }
                    });
                  }
                }}
              >
                Submit
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal className="fade" show={updateMgrRAModal}>
          <Modal.Header>
            <Modal.Title>Assign Managers</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                // setValue(defaultMgr);
                setUpdateMgrRAModal(false);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Formik
                enableReinitialize
                initialValues={managerRAInitVal}
                onSubmit={updateManagers}
                validationSchema={managerRaValidate}
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <div>
                            <Field name="managerIdsUnderRa">
                              {({ field, form }) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label
                                    className="control-label"
                                    style={{
                                      fontWeight: "bold",
                                      float: "left",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Assign Managers
                                  </label>
                                  <Select
                                    placeholder="Select a manager"
                                    classNamePrefix="select-box"
                                    options={rAManagers}
                                    isMulti
                                    value={value}
                                    // isClearable={value && value.some(v => !v.isFixed)}
                                    defaultValue={defaultRAMgr}
                                    // onChange={(list) => {
                                    //   form.setFieldValue(
                                    //     "reportingOfficerIds",
                                    //     getManagerIds(list),

                                    //   )
                                    //   // onChange()
                                    // }}
                                    onChange={(
                                      value,
                                      { action, removedValue }
                                    ) => {
                                      onChange(
                                        value,
                                        { action, removedValue },
                                        form
                                      );
                                      // form.setFieldValue(
                                      //   "reportingOfficerIds",
                                      //   getManagerIds(value)
                                      // );
                                    }}
                                  />
                                </div>
                              )}
                            </Field>
                            {errors.managerIdsUnderRa && (
                              <span className="error">
                                {errors.managerIdsUnderRa}
                              </span>
                            )}

                            <div>
                              <Button
                                style={{ float: "right" }}
                                className="me-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                className="me-2"
                                variant="danger light"
                                onClick={() => {
                                  // setValue(defaultMgr);
                                  setUpdateMgrRAModal(false);
                                }}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>

        <Modal className="fade" show={allAllocationsModal}>
          <Modal.Header>
            <Modal.Title>Transfer Allocations</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setAllAllocationsModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginTop: "5px",
                  }}
                >
                  Executives
                </label>
                <Select
                  placeholder="Select Executive"
                  classNamePrefix="select-box"
                  options={AllBranchexecutives}
                  // defaultValue={defaultMgr}
                  // value={selectedManagers}
                  onChange={(value) => {
                    console.log("value", value);
                    setSelectedTransferExe({
                      value: value.value,
                      label: value.label,
                    });
                  }}
                />
              </div>
              <Button
                style={{
                  float: "right",
                  marginTop: "15px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => {
                  if (selectedTransferExe === null) {
                    return swal("Please Select Executive", {
                      icon: "error",
                      dangerMode: true,
                    });
                    // toaster("error", "Please Select Reporting officer");
                  } else {
                    // setAllTransferModal(false);
                    swal({
                      title: "Re-Assign",
                      text: "Are you sure, you want to reassign all the allocations to the selected new user?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        getAllTransferAllocations();

                        // history.push("/executive");
                      }
                    });
                  }
                }}
              >
                Submit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal className="fade" show={updateReportingOfficerModal}>
        <Modal.Header>
          <Modal.Title>Update Reporting Officer</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setUpdateReportingOfficerModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginTop: "5px",
                }}
              >
                Direct Reporting Officer
              </label>
              <Select
                placeholder="Select Direct Reporting Officer"
                classNamePrefix="select-box"
                options={directReportingOfficers}
                // defaultValue={defaultMgr}
                value={selectedDirectReportingOfficer}
                onChange={(value) => {
                  setSelectedDirectReportingOfficer({
                    value: value.value,
                    label: value.label,
                  });
                }}
              />
            </div>
            <Button
              style={{
                float: "right",
                marginTop: "15px",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => {  
                updateDirectReportingOfficer(selectedDirectReportingOfficer.value,selectedDirectReportingOfficer.label);
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    </div>
  );
}

export default ManagerDetails;
