import React, { useState, useEffect } from "react";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";

function SimpleCountdownTimer({ close, subscriptionId, getSubsList }) {
  const [timer, setTimer] = useState(8 * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          close();
          swal("Subscription Setup is in progress", {
            icon: "warning",
          });
          getSubsList()
          // Stop the timer
          return 0;
        }

        if (prevTimer % 60 === 0) {
          // Call your method here
          getSubscriptionDetails();
          console.log("One minute has passed");
        }

        return prevTimer - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const minutes = Math.floor(timer / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (timer % 60).toString().padStart(2, "0");

  const getSubscriptionDetails = () => {
    moduleApi
      .getData(apiCall.getSubscriptionDetails + subscriptionId)
      .then((response) => {
        console.log("timerResponse", response);
        if (response?.state === "ACTIVE") {
          close();
          getSubsList()
          swal("Subscription Created Successfully", {
            icon: "success",
          });
        }else if(response?.state === "FAILED"){
          close();
          getSubsList()
          swal("Subscription Setup is Failed", {
            icon: "error",
            dangerMode:true
          });
        }
      });
  };

  return (
    <div>
      <h4>Collect request sent.</h4>
      <h6>
        This page will expire in 8:00 mins Please accept the request in the UPI
        App to setup the Subscription
      </h6>
      <h2 style={{ textAlignLast: "center" }}>{`${minutes}:${seconds}`}</h2>
    </div>
  );
}

export default SimpleCountdownTimer;
