import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    SET_TEAM_SELECTION_MODAL,
    SET_SELECTED_TEAM,
    SET_TEAM_LIST,
    AGENT_LIVE_STATUSES_ACTION
} from '../actions/AuthActions';

const initialState = {
    auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    teamSelectionModal:false,
    teamList:[],
    selectedTeam:null
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    if (action.type === SET_TEAM_SELECTION_MODAL) {
        return {
            ...state,
            teamSelectionModal: action.payload,
        };
    }
    if (action.type === SET_SELECTED_TEAM) {
        return {
            ...state,
            selectedTeam: action.payload,
        };
    }
    if (action.type === SET_TEAM_LIST) {
        return {
            ...state,
            teamList: action.payload,
        };
    }
    if (action.type === AGENT_LIVE_STATUSES_ACTION) {
        return {
            ...state,
            auth: action.data,
            errorMessage: '',
            successMessage: '',
            showLoading: false,
        };
    }
    return state;
}

    
