import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
// import Select from "react-select";
// import { Link } from "react-router-dom";
import NO_DATA_FOUND from "../images/no-data-found.png";
// import PaginationComponent from "react-reactstrap-pagination";
import { Button, Nav, Tab } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import { apiCall } from "../rest/restApi";
import * as moduleApi from "../rest/moduleApi";
// import * as helper from "../CommonComponents/Helper";
// import { toaster } from "../rest/apiUtils";
// import ReactApexChart from "react-apexcharts";
// import Select from "react-select";
import swal from "sweetalert";
// import Flatpickr from "react-flatpickr";
// import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
// import * as XLSX from "xlsx";
import ReactGA from "react-ga";

const WorkflowStatus = () => {
  //   var valIndex = 0;
  //   const [totalElements, setTotalElements] = useState(1);
  //   const [currPage, setCurrPage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [managersCountsData, setManagersCountsData] = useState([]);
  //   const [isDateFilter, setIsDateFilter] = useState(false);
  //   const [mgrData, setMgrData] = useState(null);
  // eslint-disable-next-line
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  // eslint-disable-next-line
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  var userData = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview("Workflow Details");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWorkflowList(0, start, end);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {
  //     mgrData !== null && handleExport();
  //     setIsLoading(false);
  //     // eslint-disable-next-line
  //   }, [mgrData]);

  const getWorkflowList = (pageNo, start, end) => {
    // setIsLoading(true);
    let listData = [];
    var payload = {
      //   endDate: end,
      organizationId: userData.autoFinanceUser.organizationId,
      //   reportingOfficerId:
      //     userData.autoFinanceUser.roleName === "MANAGER" ||
      //     userData.autoFinanceUser.roleName === "MANAGER_RA"
      //       ? userData.autoFinanceUser.userId
      //       : undefined,
      //   roleName: userData.autoFinanceUser.roleName,
      //   startDate: start,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getWorkflowStatus, pageNo, 10),
        payload
      )
      .then((response) => {
        if (response) {
          console.log("countsresponse", response);
          response?.forEach((element) => {
            let data = {
              amount: element.amount ? element.amount : 0,
              bucketName: element.bucketName ? element.bucketName : "-",
              customerName: element.customerName ? element.customerName : "-",
              executiveEmpId: element.executiveEmpId
                ? element.executiveEmpId
                : "-",
              executiveEmpName: element.executiveEmpName
                ? element.executiveEmpName
                : "-",
              hasWhatsApp: element.hasWhatsApp === true ? "Yes" : "No",
              id: element.id ? element.id : "-",
              latestPaymentLink: element.latestPaymentLink
                ? element.latestPaymentLink
                : 0,
              loanNumber: element.loanNumber ? element.loanNumber : "-",
              numberOfpaymentLinkSent: element.numberOfpaymentLinkSent
                ? element.numberOfpaymentLinkSent
                : "-",
              paymentStatus: element.paymentStatus
                ? element.paymentStatus
                : "-",
              phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
              selectedLanguage: element.selectedLanguage
                ? element.selectedLanguage
                : "-",
              telecallerEmpId: element.telecallerEmpId
                ? element.telecallerEmpId
                : "-",
              telecallerEmpName: element.telecallerEmpName
                ? element.telecallerEmpName
                : "-",
              workFlowStatusDetails: element.WorkFlowStatusDetails,
              exceptionOccured: element.workFlowStatusDetails.exceptionOccured
                ? element.workFlowStatusDetails.exceptionOccured
                : "-",
              //   id: element.workFlowStatusDetails.id?element.workFlowStatusDetails.id:'-',
              workFlowExecutionId: element.workFlowStatusDetails
                .workFlowExecutionId
                ? element.workFlowStatusDetails.workFlowExecutionId
                : "-",
              workFlowStatus: element.workFlowStatusDetails.workFlowStatus
                ? element.workFlowStatusDetails.workFlowStatus
                : "-",
              workFlowStepName: element.workFlowStatusDetails.workFlowStepName
                ? element.workFlowStatusDetails.workFlowStepName
                : "-",
            };
            listData.push(data);
          });
          setManagersCountsData(listData);
          setIsLoading(false);
          //   setTotalElements(response?.totalElements);
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  //   const onPageChange = (selectedPage) => {
  //     setCurrPage(selectedPage - 1);
  //     getWorkflowList(selectedPage - 1, start, end);
  //   };
  const columns = [
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan Number",

      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Bucket Name",
      dataField: "bucketName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile Number",
      dataField: "phoneNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Amount",
      dataField: "amount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>WorkFlow</span>
          <br></br>
          <span>Step Name</span>
        </div>
      ),
      dataField: "workFlowStepName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>WorkFlow</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "workFlowStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.workFlowStatus === "Open") {
          return (
            <span className="badge badge-rounded badge-primary">Open</span>
          );
        } else if (row.workFlowStatus === "Completed") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else if (row.workFlowStatus === "InProgress") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              In Progress
            </span>
          );
        } else if (row.workFlowStatus === "-") {
          return "-";
        } else if (row.workFlowStatus === "Pending") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black", backgroundColor: "yellow" }}
            >
              {row.workFlowStatus}
            </span>
          );
        } else if (row.workFlowStatus) {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.workFlowStatus}
            </span>
          );
        }
      },
    },
    {
      text: "Has Whatsapp",
      dataField: "hasWhatsApp",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Selected</span>
          <br></br>
          <span>Language</span>
        </div>
      ),
      dataField: "selectedLanguage",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "paymentStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.paymentStatus === "Received") {
          return (
            <span className="badge badge-rounded badge-success">Received</span>
          );
        } else if (row.paymentStatus === "Not Recived") {
          return (
            <span className="badge badge-rounded badge-danger">
              Not Received
            </span>
          );
        } else if (row.paymentStatus === "-") {
          return "-";
        } else if (row.paymentStatus) {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.paymentStatus}
            </span>
          );
        }
      },
    },
    {
      text: (
        <div>
          <span>No of Payments</span>
          <br></br>
          <span>Link Sent</span>
        </div>
      ),
      dataField: "latestPaymentLink",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Latest Payment Link",
      dataField: "latestPaymentLink",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>TeleCaller</span>
          <br></br>
          <span>Emp Id</span>
        </div>
      ),
      dataField: "telecallerEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>TeleCaller</span>
          <br></br>
          <span>Emp Name</span>
        </div>
      ),
      dataField: "telecallerEmpName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Id",
      dataField: "executiveEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "executiveEmpName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Workflow</span>
          <br></br>
          <span>Execution Id</span>
        </div>
      ),
      dataField: "workFlowExecutionId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.status === "ACTIVE4") {
    //       return <></>;
    //     } else {
    //       return !isExecutive ? (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-info shadow btn-xs sharp">
    //             <i className="fa fa-eye"></i>
    //           </Link>
    //         </div>
    //       ) : (
    //         <></>
    //       );
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       if (!isExecutive) {
    //         setReportingOfcrId(row.userId);
    //         setIsExecutive(true);
    //         setBasicModal2(true);
    //       }
    //     },
    //   },
    // },
  ];

  //   const dateOptions = [
  //     { label: "Custom Month", value: "cm" },
  //     { label: "Custom Dates", value: "cd" },
  //   ];

  //   const setCustomDatesForGraph = (value, isMonth) => {
  //     var start =
  //       isMonth === true
  //         ? value[0].getTime() / 1000
  //         : Math.floor(new Date(value[0]).getTime() / 1000);
  //     var end =
  //       isMonth === true
  //         ? new Date(
  //             value[0].getFullYear(),
  //             value[0].getMonth() + 1,
  //             0
  //           ).getTime() /
  //             1000 +
  //           86399
  //         : Math.floor(new Date(value[1]).getTime() / 1000 + 86399);
  //     setStart(start);
  //     setEnd(end);
  //     getWorkflowList(0, start, end);
  //   };

  //   const mgrSummaryDownload = () => {
  //     setIsLoading(true);
  //     var payload = {
  //       endDate: end,
  //       organizationid: userData.autoFinanceUser.organizationId,
  //       reportingOfficerId:
  //         userData.autoFinanceUser.roleName === "MANAGER" ||
  //         userData.autoFinanceUser.roleName === "MANAGER_RA"
  //           ? userData.autoFinanceUser.userId
  //           : undefined,
  //       roleName: userData.autoFinanceUser.roleName,
  //       startDate: start,
  //     };

  //     let cpUsersPayload = {
  //       cpName: userData.autoFinanceOrganization.cpName,
  //       endDate: end,
  //       organizationid: userData.autoFinanceOrganization.mainOrganizationId,
  //       roleName: userData.autoFinanceUser.roleName,
  //       startDate: start,
  //     };

  //     moduleApi
  //       .postData(
  //         moduleApi.withPage(
  //           userData.autoFinanceUser.roleName !== "CP_USER"
  //             ? apiCall.getWorkUploadCounts
  //             : apiCall.getAllocationSummaryForCpUsers,
  //           0,
  //           10
  //         ),
  //         userData.autoFinanceUser.roleName !== "CP_USER"
  //           ? payload
  //           : cpUsersPayload
  //       )
  //       .then((response) => {
  //         console.log("response", response.content);
  //         if (response) {
  //           if (response.totalElements > 5000) {
  //             setIsLoading(false);
  //             return swal({
  //               title: "Download Report",
  //               text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //               icon: "warning",
  //               buttons: true,
  //               dangerMode: true,
  //             }).then((willDelete) => {
  //               if (willDelete) {
  //                 setIsLoading(true);
  //                 getMgrFullResponseData(
  //                   Math.floor(response.totalElements / 200)
  //                 );
  //               }
  //             });
  //           }

  //           getMgrFullResponseData(Math.floor(response.totalElements / 200));
  //         } else {
  //           swal("Something Went Wrong, Please Try Later", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //   };

  //   async function getMgrFullResponseData(size) {
  //     var tempList = [];
  //     var mgrListData = [];

  //     for (let i = 0; i <= size; i++) {
  //       var payload = {
  //         endDate: end,
  //         organizationid: userData.autoFinanceUser.organizationId,
  //         reportingOfficerId:
  //           userData.autoFinanceUser.roleName === "MANAGER" ||
  //           userData.autoFinanceUser.roleName === "MANAGER_RA"
  //             ? userData.autoFinanceUser.userId
  //             : undefined,
  //         roleName: userData.autoFinanceUser.roleName,
  //         startDate: start,
  //       };

  //       let cpUsersPayload = {
  //         cpName: userData.autoFinanceOrganization.cpName,
  //         endDate: end,
  //         organizationid: userData.autoFinanceOrganization.mainOrganizationId,
  //         roleName: userData.autoFinanceUser.roleName,
  //         startDate: start,
  //       };

  //       await moduleApi
  //         .postData(
  //           moduleApi.withPage(
  //             userData.autoFinanceUser.roleName !== "CP_USER"
  //               ? apiCall.getWorkUploadCounts
  //               : apiCall.getAllocationSummaryForCpUsers,
  //             i,
  //             200
  //           ),
  //           userData.autoFinanceUser.roleName !== "CP_USER"
  //             ? payload
  //             : cpUsersPayload
  //         )
  //         .then((response) => {
  //           tempList.push(...response.content);
  //         });
  //     }
  //     console.log("forloop", tempList);

  //     tempList?.forEach((element) => {
  //       let data = {
  //         collectionVisitCount: element.collectionVisitCount,
  //         completed: element.completed,
  //         customerNotAvailable: element.customerNotAvailable,
  //         userId: element.userId,
  //         employeeId: element.executiveEmployeeId,
  //         mobileNumber: element.executiveMobileNumber,
  //         resolutionPercentage: element.resolutionPercentage
  //           ? element.resolutionPercentage.toFixed(0) + " %"
  //           : "0%",
  //         name: element.executiveName,
  //         uniqueVisitForCustomer: element.uniqueVisitForCustomer
  //           ? element.uniqueVisitForCustomer
  //           : "0",
  //         inprogress: element.inprogress,
  //         open: element.open,
  //         paid: element.paid,
  //         partial: element.partial,
  //         ptp: element.ptp,
  //         reportingOfficerId: element.reportingOfficerId,
  //         salesVisitCount: element.salesVisitCount,
  //         totalAllocationCounts: element.totalAllocationCounts,
  //       };
  //       mgrListData.push(data);
  //     });

  //     setMgrData(mgrListData);
  //   }

  //   const handleExport = () => {
  //     let mgrTable = [
  //       {
  //         A: "Employee Id",
  //         B: "Employee Name",
  //         C: "Total Allocated",
  //         D: "Open",
  //         E: "In Progress",
  //         F: "Completed",
  //         G: "Resolution Percentage",
  //         H: "Unique Visits For Customer",
  //       },
  //     ];

  //     mgrData?.forEach((row) => {
  //       mgrTable.push({
  //         A: row.employeeId,
  //         B: row.name,
  //         C: row.totalAllocationCounts,
  //         D: row.open,
  //         E: row.inprogress,
  //         F: row.completed,
  //         G: row.resolutionPercentage,
  //         H: row.uniqueVisitForCustomer,
  //       });
  //     });

  //     const finalData = [...mgrTable];

  //     console.log("finaldata", finalData);

  //     //create a new workbook
  //     const wb = XLSX.utils.book_new();

  //     const sheet = XLSX.utils.json_to_sheet(finalData, {
  //       skipHeader: true,
  //     });

  //     console.log("sheet", sheet);

  //     XLSX.utils.book_append_sheet(wb, sheet, "Counts");

  //     XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

  //     // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
  //     // worksheet["!cols"] = [ { wch: max_width } ];

  //     XLSX.writeFile(
  //       wb,
  //       "Clu_" +
  //         "Workflow_Data" +
  //         "_Summary_Counts_Report_" +
  //         new Date().toLocaleString("en-US") +
  //         ".xlsx"
  //     );

  //     ReactGA.event({
  //       category: "Summary Counts Report Downlaod",
  //       action:
  //         userData.autoFinanceOrganization.orgshortcode +
  //         "_" +
  //         "Workflow_Data" +
  //         "_Summary_Counts_Report_" +
  //         helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //         ".xlsx",
  //     });
  //   };

  return (
    <div className="card">
      <div style={{marginTop:'10px'}}>
      <Tab.Container defaultActiveKey="workflowChart">
        <Nav as="ul" className="nav-tabs-custom nav-justified">
          <Nav.Item as="li" key="0">
            <Nav.Link eventKey="workflowChart">
              <b> WorkFlow </b>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="workflowlist">
              <b> WorkFlow Data </b>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="pt-4">
          <Tab.Pane eventKey="workflowChart" key="0" mountOnEnter unmountOnExit>
            <div>
              <iframe
                title="Localhost Content"
                src="https://40e2-49-43-224-59.ngrok-free.app" // Replace with your local server URL
                style={{ width: "100%", height: "500px" }}
              />
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="workflowlist" key="1" mountOnEnter unmountOnExit>
            <div style={{ marginTop: "15px" }}>
              <LoadingOverlay
                active={isLoading}
                // spinner
                text={
                  <p
                    style={{
                      color: "black",
                    }}
                  >
                    <div>
                      <Button
                        style={{
                          backgroundColor: "#FFFF",
                          color: "black",
                          borderColor: "white",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                        variant="primary"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="md"
                          variant="primary"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;&nbsp;Loading...
                      </Button>
                    </div>
                  </p>
                }
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(192,192,192,0.4)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "80px",
                    "& svg circle": {
                      stroke: "rgb(136, 108, 192)",
                    },
                  }),
                }}
              >
                <div>
                  {/* <span>Select Custom Month</span> */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* <div
              style={{
                float: "right",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: "6px",
                marginRight: "5px",
              }}
            >
              <div
                style={{
                  float: "right",
                  width: "150px",
                  marginRight: "10px",
                  height: "38px",
                  marginLeft: "10px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    value.value === "cm"
                      ? setIsDateFilter(false)
                      : setIsDateFilter(true);
                  }}
                  defaultValue={dateOptions[0]}
                  options={dateOptions}
                />
              </div>
              {!isDateFilter && (
                <div
                  style={{
                    float: "right",
                    marginRight: "5px",
                  }}
                >
                  <Flatpickr
                    id="customonthPicker2"
                    className="form-control d-block"
                    style={{
                      width: "150px",
                      height: "40px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      textAlignLast: "center",
                    }}
                    placeholder="Select Month"
                    onChange={(value) => {
                      setCustomDatesForGraph(value, true);
                    }}
                    options={{
                      defaultDate: new Date(),
                      maxDate: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 2,
                        0
                      ),
                      minDate: new Date().setMonth(new Date().getMonth() - 6),

                      plugins: [
                        new monthSelectPlugin({
                          shorthand: true, //defaults to false
                          dateFormat: "F Y", //defaults to "F Y"
                          altFormat: "F Y", //defaults to "F Y"
                          theme: "light", // defaults to "light"
                        }),
                      ],
                    }}
                  />
                </div>
              )}
              {isDateFilter && (
                <div
                  style={{
                    float: "right",
                    marginRight: "5px",

                  }}
                >
                  <Flatpickr
                    style={{
                      width: "150px",
                      height: "40px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      textAlignLast: "center",
                    }}
                    id="customDatePicker2"
                    className="form-control d-block"
                    placeholder="Start & End Date"
                    onChange={(value) => {
                      setCustomDatesForGraph(value, false);
                    }}
                    options={{
                      mode: "range",
                      dateFormat: "d-M-y",
                      maxDate: new Date(),
                    }}
                  />
                </div>
              )}
              <Button
                style={{
                  margin: "5px 10px 10px  ",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => mgrSummaryDownload()}
              >
                <i className="fa fa-download"></i>&nbsp;&nbsp;Download
              </Button>
            </div> */}

                    <div
                      style={{
                        background: "white",
                        margin: "10px",
                        marginTop: "-5px",
                        overflowX: "auto",
                      }}
                    >
                      <div style={{ overflow: "auto", maxHeight: "500px" }}>
                        <BootstrapTable
                          keyField="panNo"
                          data={managersCountsData}
                          columns={columns}
                        />
                      </div>
                    </div>
                  </div>
                  {managersCountsData.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                  {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements}
                pageSize={10}
                defaultActivePage={currPage + 1}
                onSelect={onPageChange}
              />
            </div> */}
                </div>
              </LoadingOverlay>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      </div>
    </div>
  );
};

export default WorkflowStatus;
