import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import "flatpickr/dist/themes/material_green.css";
import { apiCall } from "../rest/restApi";
// import { toaster } from "../../src/rest/apiUtils";
import * as Yup from "yup";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { Link } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
// import defaultImage from "../images/defaultImage.png";
import PaginationComponent from "react-reactstrap-pagination";
// import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import TextField from "../CommonComponents/TextField";
import { Field, Form, Formik } from "formik";
import EmployeeLeaveDashboard from "../CommonComponents/EmployeeLeaveDashboard";
import Holidays from "./Holidays";
import { hasAccess, isAdmin } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import swal from "sweetalert";
import SearchBar from "../CommonComponents/SearchBar";
import ReactGA from "react-ga";

const LeaveManagement = () => {
  const [leavesList, setLeavesList] = useState([]);
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [leaveDetailsModal, setLeaveDetailsModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [addLeaveModal, setAddLeaveModal] = useState(false);
  const [leaveType, setLeaveType] = useState("Leave Without Pay");
  const [searchKey, setSearchKey] = useState("");
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [totalElements, setTotalElements] = useState(1);
  const [basicModal2, setBasicModal2] = useState(false);
  const [addLeaveCountsModal, setAddLeaveCountsModal] = useState(false);
  const [currPage] = useState(0);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  var valIndex = 0;

  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("userDetails"));
    setloggedInUser(userData);
    setDateValue("Last 7 Days", 1);
    loadLeavesListData(0, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadLeavesListData = (pageNo, key) => {
    setIsLoading(true);
    var LeavesListBody = {
      //   branchId : loggedInUser.autoFinanceUser.branchId,
      endDate: end,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
      startDate: start,
      searchKey: key,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getLeaveRequestsList, pageNo, 10),
        LeavesListBody
      )
      .then((response) => {
        var listData = [];

        response.content.forEach((element) => {
          let data = {
            appliedTime: convertEpochTimeToDate(element.appliedTime),
            branchId: element.branchId,
            fromDate: convertEpochTimeToDate(element.fromDate),
            fromDateHalfDay: element.fromDateHalfDay,
            id: element.id,
            reasonForreject: element.reasonForreject,
            lastUpdateTime: element.lastUpdateTime,
            leaveStatus: element.leaveStatus,
            leaveType: element.leaveType,
            numberOfDays: element.numberOfDays < 0 ? "0" : element.numberOfDays,
            organizationId: element.organizationId,
            reason: element.reason,
            reportingOfficerId: element.reportingOfficerId,
            toDate: convertEpochTimeToDate(element.toDate),
            toDateHalfDay: element.toDateHalfDay,
            userId: element.userId,
            fromToDates: fromToDates(
              convertEpochTimeToDate(element.fromDate),
              convertEpochTimeToDate(element.toDate)
            ),

            userModel: element.userModel,

            addedByUserId: element.userModel.addedByUserId,
            companyDesignation: element.userModel.companyDesignation,
            email: element.userModel.email,
            employeeId: element.userModel.employeeId,
            image: element.userModel.image,
            mobileNumber: element.userModel.mobileNumber,
            name: element.userModel.name,
            passwordUpdated: element.userModel.passwordUpdated,
            reportingOfficerIds: element.userModel.reportingOfficerIds,
            roleName: element.userModel.roleName,
            status: element.userModel.status,
            subOrdinateIds: element.userModel.subOrdinateIds,
            trackingEnabled: element.userModel.trackingEnabled,
          };
          listData.push(data);
        });
        setLeavesList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    loadLeavesListData(selectedPage - 1, searchKey);
  };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    // var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    // var m =
    //   date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    // var s =
    //   date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    // var am_pm = h >= 12 ? "PM" : "AM";
    // h = h > 12 ? h - 12 : h;
    date_str = month + " " + day + ", " + year;
    //   +
    //   "," +
    //   " " +
    //   h +
    //   ":" +
    //   m +
    //   ":" +
    //   s +
    //   " " +
    //   am_pm;
    return date_str;
  };

  const fromToDates = (date1, date2) => {
    if (date1 === date2) {
      return date1;
    } else {
      return date1 + " - " + date2;
    }
  };
  const columns = [
    {
      text: "Emp ID",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Leave Dates",
      dataField: "fromToDates",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Days",
      dataField: "numberOfDays",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Leave Type",
      dataField: "leaveType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Leave Applied</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "appliedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.leaveStatus === "ACCEPTED") {
          return (
            <span className="badge badge-success">
              Approved
              <span className="ms-1 fa fa-check" />
            </span>
          );
        } else if (row.leaveStatus === "PENDING") {
          return (
            <span className="badge badge-warning">
              Pending
              <span className="ms-1 fas fa-stream" />
            </span>
          );
        } else if (row.leaveStatus === "REJECTED") {
          return (
            <span className="badge badge-danger">
              Rejected
              <span className="ms-1 fa fa-ban" />
            </span>
          );
        }
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // localStorage.setItem('executiveVisitedDetails', JSON.stringify(row))
          //setVisitedPlacesDetailsList(JSON.parse(localStorage.getItem('executiveVisitedDetails')));
          setLeaveDetails(row);
          setLeaveDetailsModal(true);
          //setToggle("chatbox");

          //   <Link  to="/executive-details">
          //         <i className="fa fa-trash"></i>
          //       </Link>
        },
      },
    },
  ];

  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const setDateValue = (label, value) => {
    var date;

    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        // loadVistedPlacesData();
        return;
      case 2:
        var today = new Date();

        // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
        setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;

      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;
      case 4:
        setShowDate(true);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  function setCustomDates(dateStrings) {
    if (dateStrings.length > 1) {
      // start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
      // end = Math.floor(new Date(dateStrings[1]).getTime() / 1000);
      setStart(Math.floor(dateStrings[0].getTime() / 1000));
      setEnd(Math.floor(dateStrings[1].getTime() / 1000) + 86400);
    }
    //loadVistedPlacesData();
  }

  const updateLeaveRequest = (leaveData, status) => {
    if (status === "REJECTED" && rejectionReason === null) {
      return swal("Please Enter Rejection Reason", {
        icon: "error",
        dangerMode: true,
      });
    }
    var body = {
      leaveRequestId: leaveData.id,
      leaveStatus: status,
      reasonForreject: rejectionReason,
      userId: leaveData.userId,
    };

    moduleApi.postData(apiCall.updateLeaveRequest, body).then((response) => {
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setLeaveDetailsModal(false);
          setBasicModal2(false);
          swal("Leave Status Updated Successfully", {
            icon: "success",
          });
          loadLeavesListData(0);
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const leaveTypes = [
    { label: "Leave Without Pay", value: 1 },
    { label: "Personal Leave", value: 2 },
    { label: "Sick Leave", value: 3 },
    { label: "Vacation Leave", value: 4 },
    { label: "Paternity Leave", value: 5 },
    { label: "Maternity Leave", value: 6 },
  ];

  const validate = Yup.object({
    fromDate: Yup.date().required("From Date is required"),
    toDate: Yup.date().required("To Date is required"),
    reason: Yup.string().required("Reason for leave is required"),
  });

  const setLeaveTypeValue = (value) => {
    setLeaveType(value.label);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadLeavesListData(0, key);
  };

  const handleOk = (values) => {
    var payload = {
      appliedTime: 0,
      branchId: loggedInUser.autoFinanceUser.branchId,
      fromDate: new Date(values.fromDate).getTime() / 1000,
      fromDateHalfDay: true,
      id: 0,
      lastUpdateTime: 0,
      leaveStatus: "PENDING",
      leaveType: leaveType,
      numberOfDays: 0,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reason: values.reason,
      // reportingOfficerId: string,
      toDate: new Date(values.toDate).getTime() / 1000,
      toDateHalfDay: true,
      userId: loggedInUser.autoFinanceUser.userId,
    };

    moduleApi.postData(apiCall.applyLeave, payload).then((response) => {
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          swal("Leave Requested Successfully", {
            icon: "success",
          });
          setAddLeaveModal(true);
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const getDesignationsList = () => {
    //Designations List
    moduleApi
      .getData(
        apiCall.getDesignations + loggedInUser.autoFinanceUser.organizationId
      )
      .then((response) => {
        let designation = [];
        for (let i = 0; i < response.length; i++) {
          const result = {
            value: response[i],
            label: response[i],
          };
          designation.push(result);
        }
        setDesignations(designation);
      });
  };

  const leavePattern = /^(?:\d+|\d*\.\d*5)$/;

  const addLeaveCountValidate = Yup.object({
    sickLeave: Yup.string()
      .required("Sick Leaves  is required")
      .matches(leavePattern, "please enter a valid number"),
    personleave: Yup.string()
      .required("Personal Leaves is required")
      .matches(leavePattern, "please enter a valid number"),
    vacationLeave: Yup.string()
      .required("Vacation Leaves is required")
      .matches(leavePattern, "please enter a valid number"),
  });

  const addLeaveCounts = (values) => {
    var payload = {
      designations: [selectedDesignation],
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      personalLeaveAccrual: values.personalLeave,
      sickLeaveAccrual: values.sickLeave,
      vacationLeaveAccrual: values.vacationLeave,
    };

    moduleApi
      .postData(apiCall.addLeaveCountByDesignation, payload)
      .then((response) => {
        console.log("addLeavesResponse", response);
        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.Message === "Leave Count added successfully") {
            setAddLeaveCountsModal(false);
            return swal("Leave Counts Added Successfully", {
              icon: "success",
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  return (
    <div className="card">
      <div style={{ margin: "20px" }}>
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey={isAdmin() ? "reports" : "dashboard"}>
            <Nav as="ul" className="nav-tabs-custom nav-justified">
              {!isAdmin() && (
                <Nav.Item as="li" key="0">
                  <Nav.Link eventKey="dashboard">
                    <b> Dashboard </b>
                  </Nav.Link>
                </Nav.Item>
              )}
              {loggedInUser.autoFinanceUser.roleName !== "MANAGER_RA" && (
                <Nav.Item as="li" key="1">
                  <Nav.Link eventKey="reports">
                    <b> Leave Approvals </b>
                  </Nav.Link>
                </Nav.Item>
              )}
              {isAdmin() && (
                <Nav.Item as="li" key="2">
                  <Nav.Link eventKey="holidays">
                    <b> Holidays </b>
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content className="pt-4">
              {!isAdmin() && (
                <Tab.Pane eventKey="dashboard" key="0" mountOnEnter>
                  <EmployeeLeaveDashboard
                    userId={loggedInUser.autoFinanceUser.userId}
                    reportingOfficerId={
                      loggedInUser.autoFinanceUser.reportingOfficerId
                    }
                    flag={true}
                  />
                </Tab.Pane>
              )}

              <Tab.Pane eventKey="reports" key="1" mountOnEnter>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                      marginTop: "20px",
                      marginBottom: "5px",
                    }}
                  >
                    <SearchBar
                      placeholder="Search By Name/Emp ID"
                      searchKeyword={searchKeyword}
                    />
                    {showDate && (
                      <div
                        style={{
                          marginRight: "10px",
                          float: "right",
                          marginLeft: "10px",
                        }}
                      >
                        <Flatpickr
                          className="form-control d-block"
                          // placeholder="dd M,yyyy"
                          placeholder="Start & End Date"
                          onChange={(value) => {
                            setCustomDates(value);
                          }}
                          options={{
                            mode: "range",
                            dateFormat: "d-M-y",
                            maxDate: new Date(),
                          }}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        float: "right",
                        width: "150px",
                        marginRight: "20px",
                        marginBottom: "20px",
                        marginLeft: "10px",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) =>
                          setDateValue(value.label, value.value, optionDates)
                        }
                        defaultValue={optionDates[valIndex]}
                        options={optionDates}
                      />
                    </div>
                    <div
                      style={{
                        //float: "right",
                        //margin: "10px",
                        marginRight: "5px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                    >
                      <Button
                        className="me-2"
                        variant="primary btn-rounded"
                        onClick={() => loadLeavesListData(0, searchKey)}
                      >
                        Submit
                      </Button>
                    </div>
                    {
                    // loggedInUser.isSuperAdmin !== true && 
                    hasAccess(33) && (
                      <div
                        style={{
                          //float: "right",
                          //margin: "10px",
                          marginRight: "5px",
                          fontFamily:
                            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                        }}
                      >
                        <Button
                          className="me-2"
                          variant="primary btn-rounded"
                          onClick={() => {
                            getDesignationsList();
                            setAddLeaveCountsModal(true);
                          }}
                        >
                          <i
                            className="fa fa-plus-circle"
                            style={{ fontSize: "12px" }}
                          ></i>
                          &nbsp;Add Leaves
                        </Button>
                      </div>
                    )}
                  </div>
                  <LoadingOverlay
                    active={isLoading}
                    // spinner
                    text={
                      <p
                        style={{
                          color: "black",
                        }}
                      >
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#FFFF",
                              color: "black",
                              borderColor: "white",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                            variant="primary"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="md"
                              variant="primary"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp;&nbsp;Loading...
                          </Button>
                        </div>
                      </p>
                    }
                    styles={{
                      overlay: (base) => ({
                        ...base,
                        background: "rgba(192,192,192,0.4)",
                      }),
                      spinner: (base) => ({
                        ...base,
                        width: "80px",
                        "& svg circle": {
                          stroke: "rgb(136, 108, 192)",
                        },
                      }),
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        margin: "10px",
                        marginTop: "-5px",
                      }}
                    >
                      <div
                        style={{
                          overflow: "auto",
                          maxHeight: "500px",
                        }}
                      >
                        <BootstrapTable
                          keyField="panNo"
                          data={leavesList}
                          columns={columns}
                        />
                      </div>
                      {leavesList.length === 0 && (
                        <div className="d-flex justify-content-center">
                          <img src={NO_DATA_FOUND} alt="" />
                        </div>
                      )}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <PaginationComponent
                          totalItems={totalElements}
                          pageSize={10}
                          defaultActivePage={currPage + 1}
                          onSelect={onPageChange}
                        />
                      </div>
                    </div>
                  </LoadingOverlay>
                  <div>
                    <Modal className="fade" show={basicModal2} size="sm">
                      <Modal.Header>
                        <Modal.Title>Add Rejection Reason</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setBasicModal2(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body style={{ paddingTop: "0px" }}>
                        <div style={{ margin: "10px" }}>
                          <Row>
                            <label style={{ fontWeight: "bold" }}>
                              Reason For Rejection
                            </label>
                            <input
                              style={{
                                width: "220px",
                                height: "35px",
                                borderRadius: "25px",
                                borderColor: "#a0a0a0",
                                borderWidth: "1px",
                                boxShadow: "1px 1px 2px #3a353b",
                                paddingLeft: "10px",
                                marginLeft: "10px",
                              }}
                              placeholder="Enter Reason"
                              onChange={(e) => {
                                setRejectionReason(e.target.value);
                                console.log("rejectionReason", rejectionReason);
                              }}
                            />
                            <div style={{ marginTop: "5px" }}>
                              <div>
                                <Button
                                  style={{ float: "right", width: "70px" }}
                                  className="col-lg-2 m-2"
                                  type="submit"
                                  onClick={() =>
                                    updateLeaveRequest(leaveDetails, "REJECTED")
                                  }
                                >
                                  Submit
                                </Button>
                                <Button
                                  style={{ float: "right", width: "70px" }}
                                  className="col-lg-2 m-2 float-right"
                                  variant="danger light"
                                  onClick={() => setBasicModal2(false)}
                                >
                                  Close
                                </Button>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal
                      className="fade"
                      show={addLeaveCountsModal}
                      size="md"
                    >
                      <Modal.Header>
                        <Modal.Title>Add Leaves</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setAddLeaveCountsModal(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body style={{ paddingTop: "0px" }}>
                        <div style={{ margin: "10px" }}>
                          <Formik
                            initialValues={{
                              sickLeave: "",
                              personalleave: "",
                              vacationLeave: "",
                            }}
                            onSubmit={addLeaveCounts}
                            validationSchema={addLeaveCountValidate}
                          >
                            {({ formik, errors }) => (
                              <div>
                                <Row>
                                  <Col lg="12">
                                    <Form>
                                      {/* <div style={{ marginTop: "10px" }}> */}
                                      <TextField
                                        isAsterisk={true}
                                        label="Sick Leaves"
                                        name="sickLeave"
                                        type="text"
                                      />
                                      {/* </div> */}
                                      {/* <div style={{ marginTop: "10px" }}> */}
                                      <TextField
                                        isAsterisk={true}
                                        label="Personal Leaves"
                                        name="personleave"
                                        type="text"
                                      />
                                      {/* </div> */}
                                      {/* <div style={{ marginTop: "10px" }}> */}
                                      <TextField
                                        isAsterisk={true}
                                        label="Vacation Leaves"
                                        name="vacationLeave"
                                        type="text"
                                      />
                                      {/* </div> */}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label
                                          className="control-label"
                                          style={{
                                            fontWeight: "bold",
                                            float: "left",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          Designation
                                        </label>
                                        <div style={{ marginBottom: "10px" }}>
                                          <Select
                                            placeholder="Select Designation"
                                            classNamePrefix="select-box"
                                            options={designations}
                                            onChange={(value) => {
                                              setSelectedDesignation(
                                                value.value
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <Button
                                          style={{ float: "right" }}
                                          className="col-lg-2 m-2"
                                          type="submit"
                                        >
                                          Submit
                                        </Button>
                                        <Button
                                          style={{ float: "right" }}
                                          className="col-lg-2 m-2 float-right"
                                          variant="danger light"
                                          onClick={() => {
                                            setAddLeaveCountsModal(false);
                                          }}
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Formik>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal className="fade" show={addLeaveModal}>
                      <Modal.Header>
                        <Modal.Title>Request Leave</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setAddLeaveModal(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <Formik
                            initialValues={{
                              fromDate: "",
                              toDate: "",
                              reason: "",
                            }}
                            onSubmit={handleOk}
                            validationSchema={validate}
                          >
                            {({ formik, errors }) => (
                              <div>
                                <Row>
                                  <Col lg="12">
                                    <Form>
                                      <div>
                                        <TextField
                                          isAsterisk={true}
                                          label="From Date"
                                          name="fromDate"
                                          type="date"
                                        />

                                        <TextField
                                          isAsterisk={true}
                                          label="To Date"
                                          name="toDate"
                                          type="date"
                                        />

                                        <Field name="leaveType">
                                          {({ field, form }) => (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <label
                                                className="control-label"
                                                style={{
                                                  fontWeight: "bold",
                                                  float: "left",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                Leave Type
                                              </label>
                                              <Select
                                                placeholder="Select leave type"
                                                classNamePrefix="select-box"
                                                options={leaveTypes}
                                                defaultValue={leaveTypes[0]}
                                                onChange={(selectedOption) => {
                                                  setLeaveTypeValue(
                                                    selectedOption
                                                  );
                                                  // form.setFieldValue(
                                                  //   "leaveType",
                                                  //   selectedOption.label
                                                  // );
                                                }}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                        {/* {errors.leaveType && (
                              <span className="error">{errors.leaveType}</span>
                            )} */}
                                        <div style={{ marginTop: "10px" }}>
                                          <TextField
                                            isAsterisk={true}
                                            label="Reason for leave"
                                            name="reason"
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <Button
                                          style={{ float: "right" }}
                                          className="col-lg-2 m-2"
                                          type="submit"
                                        >
                                          Submit
                                        </Button>
                                        <Button
                                          style={{ float: "right" }}
                                          className="col-lg-2 m-2 float-right"
                                          variant="danger light"
                                          onClick={() =>
                                            setAddLeaveModal(false)
                                          }
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Formik>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <Modal className="fade" show={leaveDetailsModal}>
                      <Modal.Header>
                        <Modal.Title>Leave Details</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setLeaveDetailsModal(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        <div
                          style={{ marginLeft: "20px", marginBottom: "20px" }}
                        >
                          <tr></tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Name
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.name ? leaveDetails.name : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Mobile
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.mobileNumber
                                ? leaveDetails.mobileNumber
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Employee ID
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.employeeId
                                ? leaveDetails.employeeId
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Email
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.email ? leaveDetails.email : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Designation
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.userModel?.companyDesignation
                                ? leaveDetails.userModel?.companyDesignation
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Leave Applied Date
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.appliedTime
                                ? leaveDetails.appliedTime
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Leave Type
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.leaveType
                                ? leaveDetails.leaveType
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              From Date
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.fromDate
                                ? leaveDetails.fromDate
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              To Date
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.toDate ? leaveDetails.toDate : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Reason for leave
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.reason ? leaveDetails.reason : "-"}
                            </td>
                          </tr>
                          {leaveDetails.leaveStatus === "REJECTED" && (
                            <tr>
                              <td
                                style={{
                                  fontWeight: "500",
                                  float: "left",
                                  textAlign: "left",
                                }}
                              >
                                Reason for Rejection
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  textAlign: "left",
                                  verticalAlign: "middle",
                                }}
                              >
                                {leaveDetails.reasonForreject
                                  ? leaveDetails.reasonForreject
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              Status
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                color:
                                  leaveDetails.leaveStatus === "ACCEPTED"
                                    ? "green"
                                    : leaveDetails.leaveStatus === "REJECTED"
                                    ? "red"
                                    : "orange",
                                textAlign: "left",
                              }}
                            >
                              {leaveDetails.leaveStatus
                                ? leaveDetails.leaveStatus
                                : "-"}
                            </td>
                          </tr>
                          {leaveDetails.leaveStatus === "PENDING" &&
                            hasAccess(20) && (
                              <div>
                                <Button
                                  style={{
                                    float: "left",
                                    marginTop: "20px",
                                    marginRight: "25px",
                                    fontFamily:
                                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                  }}
                                  className="me-2"
                                  variant="success btn-rounded"
                                  onClick={() =>
                                    updateLeaveRequest(leaveDetails, "ACCEPTED")
                                  }
                                >
                                  Accept
                                </Button>
                                <Button
                                  style={{
                                    float: "left",
                                    marginTop: "20px",
                                    marginRight: "15px",
                                    fontFamily:
                                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                  }}
                                  className="me-2"
                                  variant="danger btn-rounded"
                                  onClick={() => {
                                    setRejectionReason(null);
                                    setBasicModal2(true);
                                  }}
                                >
                                  Reject
                                </Button>
                              </div>
                            )}
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="holidays" key="2">
                <Holidays />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default LeaveManagement;
