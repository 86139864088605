import React, { useEffect, useState } from "react";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import PaginationComponent from "react-reactstrap-pagination";
import ReactGA from "react-ga";
import { Button, Spinner } from "react-bootstrap";
import { getTokenInLocalStorage } from "../services/AuthService";
import { EpochToDate, secondsToHms } from "../CommonComponents/Helper";

const PaymentLinksSentLots = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [requestLotsList, setRequestLotsList] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );
  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview("Payment Request/s Lots List");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData(0, start, end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (pageNo, start, end) => {
    var listData = [];
    setIsLoading(true)
    var payload = {
      startDate: start,
      endDate: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      //   searchKey: searchKey,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCustomerBulkPaymentLinkLots, pageNo, 10),
        payload
      )
      .then((response) => {
        setCurrPage(pageNo);
        // console.log("response", response);
        response?.content?.forEach((element) => {
          let data = {
            allocationIds: element?.allocationIds,
            amountCollected: element?.amountCollected
              ? element?.amountCollected
              : 0,
            channels: element?.channels,
            id: element?.id,
            lotName: element?.lotName ? element?.lotName : "-",
            organizationId: element?.organizationId,
            sendForAllAllocations: element?.sendForAllAllocations,
            status: element?.status,
            timeOut: element?.timeOut?secondsToHms(element?.timeOut):0,
            totalNoOfLinksGenerated: element?.totalNoOfLinksGenerated,
            totalNoOfLinksNotGenerated: element?.totalNoOfLinksNotGenerated,
            paymentFailed: element?.paymentFailed,
            paymentPending: element?.paymentPending,
            paymentSuccess: element?.paymentSuccess,
            totalNoOfPaymentLinksSent: element?.totalNoOfPaymentLinksSent,
            createdTime: element?.createdTime
              ? EpochToDate(element?.createdTime)
              : "-",
            updatedTime: element?.updatedTime
              ? EpochToDate(element?.updatedTime)
              : "-",
          };
          listData.push(data);
        });
        setRequestLotsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const setCustomDatesForGraph = (value1) => {
    var start = value1[0].getTime() / 1000;
    var end =
      new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStart(start);
    setEnd(end);
    loadData(0,start, end,);
    // setErrorMessage(false);
  };

  const columns = [
    {
      text: "Lot Name",
      dataField: "lotName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Amount</span>
          <br></br>
          <span>Collected</span>
        </div>
      ),
      dataField: "amountCollected",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Pending</span>
        </div>
      ),
      dataField: "paymentPending",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Received</span>
        </div>
      ),
      dataField: "paymentSuccess",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: (
    //     <div>
    //       <span>Payment</span>
    //       <br></br>
    //       <span>Failed</span>
    //     </div>
    //   ),
    //   dataField: "paymentFailed",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: (
        <div>
          <span>No of Links</span>
          <br></br>
          <span>Generated</span>
        </div>
      ),
      dataField: "totalNoOfLinksGenerated",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>No of Links</span>
          <br></br>
          <span>Not Generated</span>
        </div>
      ),
      dataField: "totalNoOfLinksNotGenerated",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
   
    // {
    //   text: (
    //     <div>
    //       <span>Total Links</span>
    //       <br></br>
    //       <span>Sent</span>
    //     </div>
    //   ),
    //   dataField: "totalNoOfPaymentLinksSent",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "COMPLETED") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else if (row.status === "PROCESSING") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black", backgroundColor: "yellow" }}
            >
              Processing
            </span>
          );
        } else if (row.status === "PENDING") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              Pending
            </span>
          );
        }
        //  else if (row.status === "PENDING") {
        //   return (
        //     <span className="badge badge-rounded badge-warning">Pending</span>
        //   );
        // }
         else if (row.status === "FAILED") {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Expiry Time",
      dataField: "timeOut",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created At",
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    loadData(selectedPage - 1, start, end);
  };

  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
        <div style={{display:'flex',flexDirection:'column', marginTop:'-15px'}}>
        <div
            style={{ display: "flex", flexDirection: "row", float: "right", justifyContent:'flex-end' }}
          >
            <div
              style={{
                float: "right",
                margin: "10px",
              }}
          >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
              <label style={{ fontWeight: "bold", marginLeft: "5px" }}>
                Select Month
              </label>
              <Flatpickr
                className="form-control d-block"
                style={{
                  width: "170px",
                  height: "40px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  textAlignLast: "center",
                }}
                placeholder="Select Month"
                onChange={(value) => {
                  setCustomDatesForGraph(value);
                }}
                options={{
                  defaultDate: new Date(),
                  maxDate: new Date(),
                  minDate: new Date().setMonth(new Date().getMonth() - 6),

                  plugins: [
                    new monthSelectPlugin({
                      shorthand: true, //defaults to false
                      dateFormat: "F Y", //defaults to "F Y"
                      altFormat: "F Y", //defaults to "F Y"
                      theme: "light", // defaults to "light"
                    }),
                  ],
                }}
              />
              </div>
            </div>
            
          </div>
      <div
        style={{
          background: "white",
          margin: "10px",
          marginTop: "-5px",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            overflow: "auto",
            maxHeight: "500px",
          }}
        >
          <BootstrapTable
            keyField="id"
            data={requestLotsList}
            columns={columns}
          />
        </div>
        {requestLotsList.length === 0 && (
          <div className="d-flex justify-content-center">
            <img src={NO_DATA_FOUND} alt="" />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
          }}
        >
          <PaginationComponent
            totalItems={totalElements}
            pageSize={10}
            defaultActivePage={currPage + 1}
            onSelect={onPageChange}
          />
        </div>
      </div>
      </div>
    </LoadingOverlay>
  );
};

export default PaymentLinksSentLots;
