import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import * as moduleApi from "../rest/moduleApi";
import Spinner from "react-bootstrap/Spinner";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import swal from "sweetalert";

function AddRolesAndPrivilage() {
  const [roleNames, setRoleNames] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line
  // const [loggedInUser, setLoggedInUser] = useState();
  useEffect(() => {
    // eslint-disable-next-line
    let orgDetails = localStorage.getItem("organizationId");
    setOrgId(orgDetails);
    loadData(orgDetails);

    // var userLoginData = localStorage.getItem("userDetails");
    // if (!userLoginData) return;
    // var userData = JSON.parse(userLoginData);
    // setLoggedInUser(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (organizationId) => {
    let listData = [];
    let listData2 = [];
    let listData3 = [];
    let listData4 = [];
    let listData5 = [];
    let temp = [];
    setIsLoading(true);
    moduleApi
      .getDataById(
        "https://1im45diyn7.execute-api.ap-south-1.amazonaws.com/roleprivs",
        organizationId,
        "getRoleAndPrivsIncludeUnassigned"
      )
      .then((response) => {
        console.log("response", response);

        response?.EXECUTIVE?.forEach((element) => {
          const data = {
            name: element.privilageName,
          };
          listData.push(data);
        });

        response?.EXECUTIVE?.forEach((element) => {
          const data = {
            assigned: element.assigned,
          };
          listData2.push(data);
        });

        response?.ADMIN?.forEach((element) => {
          const data = {
            assigned: element.assigned,
          };
          listData3.push(data);
        });

        response?.MANAGER?.forEach((element) => {
          const data = {
            assigned: element.assigned,
          };
          listData4.push(data);
        });

        response?.ADMIN?.forEach((element) => {
          const data = {
            id: element.id,
          };
          listData5.push(data);
        });

        console.log("privilege", listData);
        console.log("Executive", listData2);
        console.log("Admin", listData3);
        console.log("Manager", listData4);
        console.log("id", listData5);

        getUpdatedArray(listData, listData3, listData4, listData2, listData5);
        console.log("temp", temp);
        setIsLoading(false);
      });
  };

  const getUpdatedPrivilegeIds = (roleName) => {
    var temp = [];
    roleNames.forEach((item) => {
      if (item[roleName] === true)
        temp.push({
          id: item.id,
        });
    });
    console.log("roleTemp", temp);
    return temp;
  };

  const updatePrivileges = () => {
    var payload = {
      organizationId: orgId,
      rolePrives: [
        {
          name: "ADMIN",
          autofinancePrivilage: getUpdatedPrivilegeIds("admin"),
        },
        {
          name: "MANAGER",
          autofinancePrivilage: getUpdatedPrivilegeIds("manager"),
        },
        {
          name: "EXECUTIVE",
          autofinancePrivilage: getUpdatedPrivilegeIds("executive"),
        },
      ],
    };

    console.log("payload", payload);

    moduleApi
      .postDataByBody(
        "https://5g85z50isb.execute-api.ap-south-1.amazonaws.com/updaterole",
        payload
      )
      .then((response) => {
        console.log("updateResponse", response);
        swal("Privileges Updated Successfully!", {
          icon: "success",
        }).then((yes) => {
          if (yes) {
            loadData(orgId);
          }
        });
      });
  };

  const getUpdatedArray = (first, second, third, fourth, fifth) => {
    for (let i = 0; i < second.length; i++) {
      first[i].admin = second[i].assigned;
      first[i].manager = third[i].assigned;
      first[i].executive = fourth[i].assigned;
      first[i].id = fifth[i].id;
    }
    console.log("formattedArray", first);
    setRoleNames(first);
  };
  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div style={{ margin: "30px 30px" }}>
        <div className="table-responsive">
          <table className="table">
            <thead
              style={{
                backgroundColor: "rgb(243,240,249)",
                top: "1px",
                position: "sticky",
              }}
            >
              <tr
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "small",
                }}
              >
                <td style={{ width: "300px" }}>Privilege</td>
                <td>Admin</td>
                <td>Manager</td>
                <td>Executive</td>
              </tr>
            </thead>
            <tbody>
              {roleNames.map((user, index) => (
                <tr style={{ textAlign: "center", fontWeight: "normal" }}>
                  <td>{user.name}</td>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={index}
                      required
                      onChange={() => {
                        let temp = [...roleNames];
                        temp[index].admin = !roleNames[index].admin;
                        setRoleNames(temp);
                      }}
                      checked={user.admin}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={index}
                      required
                      onChange={() => {
                        let temp = [...roleNames];
                        temp[index].manager = !roleNames[index].manager;
                        setRoleNames(temp);
                      }}
                      checked={user.manager}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={index}
                      required
                      onChange={() => {
                        let temp = [...roleNames];
                        temp[index].executive = !roleNames[index].executive;
                        setRoleNames(temp);
                      }}
                      checked={user.executive}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            {/* )})} */}
          </table>
          {roleNames.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
        </div>

        <div>
          <Button
            style={{ float: "right", margin: "5px" }}
            className="col-lg-2 m-2"
            type="submit"
            onClick={() => {
              updatePrivileges();
              console.log("roleNames", roleNames);
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default AddRolesAndPrivilage;
