import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
// import Select from "react-select";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import {
  getTokenInLocalStorage,
  hasAccess,
  hasAccessFromTeamPrivilege,
} from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import * as helper from "../CommonComponents/Helper";
import { EpochToDate, secondsToHms } from "../CommonComponents/Helper";
// import SearchBar from "../CommonComponents/SearchBar";

function CallSummary({ mobileNo, loanNumber, type }) {
  // var history = useHistory();
  const [callList, setCallList] = useState([]);
  // const [isDateFilter, setIsDateFilter] = useState(false);
  const [callAudioModal, setCallAudioModal] = useState(false);
  const [callDetails, setCallDetails] = useState(null);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  //   const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transList, setTransList] = useState(null);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let listData = [];
  //   var audioBasePath =
  //     "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=";
  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview("Call History");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData(start, end, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transList]);

  const loadData = (start, end, pageNo) => {
    setIsLoading(true);
    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      //   searchKey: key,
      phoneNumber: mobileNo,
      loanNumber: type === "Sales" ? undefined : loanNumber,
      startTime: start,
      endTime: end,
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          type === "Sales"
            ? apiCall.getCallReportForSales
            : apiCall.getCallReports,
          pageNo,
          10
        ),
        body
      )
      .then((response) => {
        // console.log(response);
        setCurrPage(pageNo);
        response?.content?.forEach((element) => {
          let data = {
            agent: element.agent,
            billedsec: element.billedSec,
            cmiuid: element.cmiuid,
            duration: element.callDuration
              ? secondsToHms(element.callDuration)
              : "-",
            filename: element.fileName ? element.fileName : "-",
            from: element.from ? element.from : "-",
            fromNumber: element.fromNumber ? element.fromNumber : "-",
            id: element.id,
            name: element.name ? element.name : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            rate: element.rate,
            record: element.record,
            time: element.createdTime ? EpochToDate(element.createdTime) : "-",
            to: element.to,
            toNumber: element.phoneNumber ? element.phoneNumber : "-",
            userId: element.userId,
            teleCallerName:element.teleCallerName ? element.teleCallerName:"-",
            teleCallerEmpId:element.teleCallerEmpId ? element.teleCallerEmpId:"-",
            callStatus:element.callStatus ? element.callStatus :"-",
            status:element.status ? element.status :"-",
            subStatus:element.subStatus ? element.subStatus :"-",
            remarks:element.remarks ? element.remarks:"-"
          };
          listData.push(data);
        });
        setCallList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  //   const handleClose = () => {
  //     setAddCpUserModal(false);
  //     loadData(0, searchKey);
  //   };

  // const dateOptions = [
  //   { label: "Custom Month", value: "cm" },
  //   { label: "Custom Dates", value: "cd" },
  // ];

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0].getFullYear(),
            value[0].getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86400);
    //   let start = ;
    // let end = Math.floor(new Date(dateStrings[1]).getTime() / 1000 + 86400);

    setStart(start);
    setEnd(end);
    loadData(start, end, 0);
  };

  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    // {
    //   text: "Agent Name",
    //   dataField: "agent",
    //   width: 130,
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     fontWeight: "bold",
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    // {
    //   text: "Customer Name",
    //   dataField: "name",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Tele Caller Name",
      dataField: "teleCallerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Tele Caller Emp ID",
      dataField: "teleCallerEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "From Number",
      dataField: "fromNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "To Number",
      dataField: "toNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.toNumber) {
          if (hasAccess(75) || hasAccessFromTeamPrivilege(75)) {
            return helper.getMaskedMobileNo(row.toNumber.slice(-10));
          } else {
            return row.toNumber;
          }
        }
      },
    },
    {
      text: "Call Duration",
      dataField: "duration",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Call Status",
      dataField: "callStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Call Initiated Time",
      dataField: "time",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Billed Sec",
      dataField: "billedsec",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else if (row?.filename !== "-") {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i
                  className="fa fa-play"
                  style={{ marginTop: "3px", marginLeft: "2px" }}
                />
              </Link>
            </div>
          );
        } else {
          return "-";
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // console.log("row", row);
          setCallDetails(row);
          setCallAudioModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else if (row?.filename !== "-") {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-download" />
              </Link>
            </div>
          );
        } else {
          return "-";
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // console.log("row", row);
          handleDownload(
            "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
              row?.filename,
            (hasAccess(75) || hasAccessFromTeamPrivilege(75)
              ? helper.getMaskedMobileNo(row.toNumber.slice(-10))
              : row.toNumber) +
              "_" +
              EpochToDate(Math.floor(new Date() / 1000))
          );
        },
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(start, end, selectedPage - 1);
  };

  const handleDownload = (mp3FileUrl, fileName) => {
    setIsLoading(true);
    // Create an XHR request to fetch the audio file
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element and trigger a click to start the download
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName + ".mp3"; // You can customize the downloaded file name
      document.body.appendChild(a);
      a.click();
      // Clean up the temporary link and revoke the object URL to release resources
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    xhr.open("GET", mp3FileUrl);
    xhr.send();
    setIsLoading(false);
    ReactGA.event({
      category: "Call Audio Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_" +
        fileName +
        EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  //   const searchKeyword = (key) => {
  //     setSearchKey(key);
  //     loadData(0, key);
  //   };

  const transDownloadData = (key) => {
    setIsLoading(true);
    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      //   searchKey: key,
      loanNumber: type === "Sales" ? undefined : loanNumber,
      phoneNumber: mobileNo,
      startTime: start,
      endTime: end,
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          type === "Sales"
            ? apiCall.getCallReportForSales
            : apiCall.getCallReports,
          0,
          10
        ),
        body
      )
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getTransFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getTransFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getTransFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      let body = {
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        //   searchKey: key,
        loanNumber: type === "Sales" ? undefined : loanNumber,
        phoneNumber: mobileNo,
        startTime: start,
        endTime: end,
      };
      await moduleApi
        .postData(
          moduleApi.withPage(
            type === "Sales"
              ? apiCall.getCallReportForSales
              : apiCall.getCallReports,
            0,
            100
          ),
          body
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    // console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        agent: element.agent,
        billedsec: element.billedSec,
        cmiuid: element.cmiuid,
        duration: element.callDuration
          ? secondsToHms(element.callDuration)
          : "-",
        filename: element.fileName ? element.fileName : "-",
        from: element.from ? element.from : "-",
        fromNumber: element.fromNumber ? element.fromNumber : "-",
        id: element.id,
        name: element.name ? element.name : "-",
        organizationId: element.organizationId ? element.organizationId : "-",
        rate: element.rate,
        record: element.record,
        time: element.createdTime ? EpochToDate(element.createdTime) : "-",
        to: element.to,
        toNumber: element.phoneNumber ? element.phoneNumber : "-",
        userId: element.userId,
        teleCallerName:element.teleCallerName ? element.teleCallerName:"-",
        teleCallerEmpId:element.teleCallerEmpId ? element.teleCallerEmpId:"-",
        callStatus:element.callStatus ? element.callStatus :"-",
        status:element.status ? element.status :"-",
        subStatus:element.subStatus ? element.subStatus :"-",
        remarks:element.remarks ? element.remarks:"-"
      };
      mgrListData.push(data);
    });
    setTransList(mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {

        A:"Tele Caller Name",
        B:"Tele Caller Emp ID",
        C: "From Number",
        D: "To Number",
        E: "Call Duration",
        F:"Call Status",
        G:"Status",
        H:"Sub Status",
        I: "Call Initiated Time",
        J: "Billed Sec",
      },
    ];

    transList?.forEach((row) => {
      mgrTable.push({
        A: row.teleCallerName,
        B: row.teleCallerEmpId,
        C: row.fromNumber,
        D:
          hasAccess(75) || hasAccessFromTeamPrivilege(75)
            ? helper.getMaskedMobileNo(row.toNumber.slice(-10))
            : row.toNumber,
        E: row.duration,
        F:row.callStatus,
        G:row.status,
        H:row.subStatus,
        I: row.time,
        J: row.billedsec,
      });
    });

    const finalData = [...mgrTable];

    // console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    // console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Call History");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      (hasAccess(75) || hasAccessFromTeamPrivilege(75)
        ? helper.getMaskedMobileNo(mobileNo.slice(-10))
        : mobileNo) +
        "_Call_History_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Call History List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Call_History_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          flexDirection: "row",
          alignItems: "center",
          marginRight: "20px",
          //   marginTop: "10px",
        }}
      >
        {/* <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "10px",
            height: "38px",
            marginLeft: "10px",
            borderRadius: "3px",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #797979",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) => {
              value.value === "cm"
                ? setIsDateFilter(false)
                : setIsDateFilter(true);
            }}
            defaultValue={dateOptions[0]}
            options={dateOptions}
          />
        </div> */}
        {/* {!isDateFilter && ( */}
        <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
              <label style={{ fontWeight: "bold", marginLeft: "5px" }}>
                Select Month
              </label>
          <Flatpickr
            id="customonthPicker"
            className="form-control d-block"
            style={{
              width: "150px",
              height: "40px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              textAlignLast: "center",
            }}
            placeholder="Select Month"
            onChange={(value) => {
              setCustomDatesForGraph(value, true);
            }}
            options={{
              defaultDate: new Date(),
              maxDate: new Date(),
              minDate: new Date().setMonth(new Date().getMonth() - 6),

              plugins: [
                new monthSelectPlugin({
                  shorthand: true, //defaults to false
                  dateFormat: "F Y", //defaults to "F Y"
                  altFormat: "F Y", //defaults to "F Y"
                  theme: "light", // defaults to "light"
                }),
              ],
            }}
          />
        </div>
        <Button
          style={{
            marginRight: "15px",
            marginLeft: "10px",
            marginTop: "20px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() => transDownloadData()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button>
        {/* )} */}
        {/* {isDateFilter && (
          <div>
            <Flatpickr
              style={{
                width: "150px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              id="customDatePicker"
              className="form-control d-block"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDatesForGraph(value, false);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )} */}

        {/* <Button
          style={{
            // margin: "5px 10px 10px  ",
            marginRight: "15px",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          onClick={() => transDownloadData()}
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button> */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={callList}
              columns={columns}
            />
          </div>
          {callList?.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "10px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" show={callAudioModal}>
        <Modal.Header>
          <Modal.Title>Call Recording</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setCallAudioModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AudioPlayer
            autoPlay
            src={
              "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
              callDetails?.filename
            }
            onPlay={(e) => console.log("onPlay")}
            // other props here
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CallSummary;
