import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import SearchBar from "../CommonComponents/SearchBar";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { isAdmin } from "../services/AuthService";

const SelectExecutives = ({ list, onOk, close, setTitle, slctdID, iactvUsrs }) => {


  const [loggedInUser, setLoggedInUser] = useState();
  const [users, setUsers] = useState([]);
  const [overAll, setOverAll] = useState([]);
  const [selectedIDS, setSelectedIDS] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedID, setSelectedID] = useState(1);
  const [activeUsers, setActiveUsers] = useState(iactvUsrs);

  const [prevIDS, setPrevIDS] = useState(list);

  useEffect(() => {

    // console.log(iactvUsrs);

    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setLoggedInUser(userData);
    onChange(slctdID + "", userData, true);
    // loadData("EXECUTIVE", userData, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (role, userData, isInitialLoad, isAll, isActiveUsers) => {
    // console.log(role);
    !isInitialLoad && setPrevIDS([]);

    setPrevIDS([]);
    setSelectedIDS([]);

    const payLoad = {
      branchId: "",
      organizationId: userData.autoFinanceUser.organizationId,
      reportOfficerId: isAdmin() ? "" : userData.autoFinanceUser.userId,
      roleName: isAll === true ? '' : role,
      status: isActiveUsers === true ? "ACTIVE" : "ALL",
    };

    moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
      const result = response.map((item) => ({
        userId: item.userId,
        name: item.name,
        empId: item.empId,
        email: item.email,
        checked: prevIDS.find((element) => element === item.userId)
          ? true
          : false,
      }));
      if (
        role === "MANAGER" &&
        (userData.autoFinanceUser.roleName === "MANAGER" ||
          userData.autoFinanceUser.roleName === "MANAGER_RA")
      ) {
        const result2 = {
          userId: userData.autoFinanceUser.userId,
          name: userData.autoFinanceUser.name,
          empId: userData.autoFinanceUser.employeeId,
          email: userData.autoFinanceUser.email,
          checked: prevIDS.find(
            (element) => element === userData.autoFinanceUser.userId
          )
            ? true
            : false,
        };
        result.push(result2);
      }
      setUsers(result);
      setOverAll(result);

      isInitialLoad ? setSelectedIDS(prevIDS) : setSelectedIDS([]);
    });
  };

  const setActiveUsersValue = (value) => {
    setIsSelectedAll(false);
    setPrevIDS([]);
    setSelectedIDS([]);
    setActiveUsers(value);
    var roleName = selectedID === "1" ? "EXECUTIVE" : "MANAGER";
    loadData(roleName, loggedInUser, false, false, value);

  };

  const loadBranchWise = (userData, isInitialLoad) => {
    !isInitialLoad && setPrevIDS([]);

    const payLoad = {
      searchKey: "",
      organizationId: userData.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin() ? "" : userData.autoFinanceUser.userId,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response.map((item) => ({
        userId: item.branchId,
        name: item.branchName,
        email: null,
        checked: prevIDS.find((element) => element === item.branchId)
          ? true
          : false,
      }));
      setUsers(result);
      setOverAll(result);
      isInitialLoad ? setSelectedIDS(prevIDS) : setSelectedIDS([]);
    });
  };

  const loadDesignationWise = (userData, isInitialLoad) => {
    !isInitialLoad && setPrevIDS([]);

    moduleApi
      .getData(
        apiCall.getDesignations + userData.autoFinanceUser.organizationId
      )
      .then((response) => {
        const result = response.map((item) => ({
          userId: item,
          name: item,
          email: null,
          checked: prevIDS.find((element) => element === item) ? true : false,
        }));
        setUsers(result);
        setOverAll(result);
        isInitialLoad ? setSelectedIDS(prevIDS) : setSelectedIDS([]);
      });
  };

  const loadCpUsers = (userData, isInitialLoad) => {
    !isInitialLoad && setPrevIDS([]);

    moduleApi
      .getData(
        apiCall.getCpUsersForDropdown +
        userData?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        const result = response?.map((item) => ({
          userId: item,
          name: item,
          checked: prevIDS.find((element) => element === item) ? true : false,
        }));
        setUsers(result);
        setOverAll(result);
        isInitialLoad ? setSelectedIDS(prevIDS) : setSelectedIDS([]);
      });
  };

  const addAll = () => {
    var checkedList = [];
    const res = [...overAll];
    res.forEach((item) => {
      item.checked = true;
      checkedList.push(item.userId);
    });
    setUsers(res);
    setOverAll(res);
    setSelectedIDS(checkedList);
  };
  const removeAll = () => {
    const res = [...overAll];
    res.forEach((item) => {
      item.checked = false;
    });
    setUsers(res);
    setOverAll(res);
    setSelectedIDS([]);
  };

  const onSelectAll = (v) => {
    if (v === "All") {
      if (isSelectedAll) {
        removeAll();
      } else {
        addAll();
      }
      setIsSelectedAll(!isSelectedAll);
      return;
    }

    const found = selectedIDS.find((element) => element === v);
    var checkedList = [...selectedIDS];
    if (found) {
      for (var i = 0; i < checkedList.length; i++) {
        if (checkedList[i] === v) {
          checkedList.splice(i, 1);
        }
      }
    } else checkedList.push(v);

    setSelectedIDS(checkedList);
    const res = [...overAll];

    res.forEach((item) => {
      if (item.userId === v) {
        item.checked = !item.checked;
      }
    });

    if (checkedList.length === res.length) setIsSelectedAll(true);
    else setIsSelectedAll(false);

    setUsers(res);
    setOverAll(res);
  };

  const onSubmit = () => {
    // console.log("checkedlist", selectedID, "SID ", selectedIDS);

    onOk(selectedIDS, selectedID, activeUsers);
    close();
    setTitle("Executives");
  };

  const searchKeyword = (key) => {
    const res = [...overAll];
    const searchString = key;

    let matchingStrings = [];

    res.forEach((list) => {
      if (list?.name?.toLowerCase().search(searchString.toLowerCase()) > -1) {
        matchingStrings.push(list);
      } else if (
        list?.empId?.toLowerCase().search(searchString.toLowerCase()) > -1
      ) {
        matchingStrings.push(list);
      }
    });
    setUsers(matchingStrings);
  };

  const onChange = (id, userData, isInitialLoad) => {
    if (id === "1") {
      setTitle("Executives");
    } else if (id === "2") {
      setTitle("Managers");
    } else if (id === "3") {
      setTitle("Branch");
    } else if (id === "4") {
      setTitle("Designation");
    } else if (id === '0') {
      setTitle('')
    }
    setSelectedID(id);
    setIsSelectedAll(false);
    // eslint-disable-next-line default-case
    switch (id) {
      case "0":
        loadData("", userData, isInitialLoad, true, activeUsers);
        break;
      case "1":
        loadData("EXECUTIVE", userData, isInitialLoad, false, activeUsers);
        break;
      case "2":
        loadData("MANAGER", userData, isInitialLoad, false, activeUsers);
        break;
      case "3":
        loadBranchWise(userData, isInitialLoad);
        break;
      case "4":
        loadDesignationWise(userData, isInitialLoad);
        break;
      case "5":
        loadCpUsers(userData, isInitialLoad);
        break;
    }
  };

  return (
    <>
      <div style={{ display: "flex", margin: "10px 0px" }}>
        {/* <input
          style={{
            width: "300px",
            height: "35px",
            borderRadius: "25px",
            borderColor: "#a0a0a0",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #3a353b",
            paddingLeft: "10px",
            marginLeft: "10px",
          }}
          placeholder="Search Here"
          onChange={(e) => searchKeyword(e.target.value.trim())}
        /> */}
        <SearchBar
          placeholder="Search Here"
          searchKeyword={searchKeyword}
          id="searchselectexec"
        />
        {(selectedID === "1" || selectedID === "2") && (
          <div
            class="form-check"
            onClick={() => setActiveUsersValue(true)}
            style={{ margin: "0px 20px" }}
          >
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={activeUsers}
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Active Users
            </label>
          </div>
        )}

        {(selectedID === "1" || selectedID === "2") && (
          <div
            class="form-check"
            onClick={() => setActiveUsersValue(false)}
            style={{ margin: "0px 20px" }}
          >
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={!activeUsers}
            />
            <label class="form-check-label" for="flexRadioDefault1">
              All Users
            </label>
          </div>
        )}

        {loggedInUser && (
          // (loggedInUser.autoFinanceUser.roleName === "ADMIN" ||
          //   loggedInUser.autoFinanceUser.subOrdinateManagersIds.length > 0)
          <div style={{ marginLeft: "auto" }}>
            <label
              style={{
                marginLeft: "10px",
                marginBottom: "0px",
                fontWeight: "600",
                marginTop: "5px",
              }}
            >
              Select By
            </label>
            <select
              style={{
                backgroundColor: "white",
                height: "40px",
                width: "150px",
                color: "rgb(115 115 115)",
                border: "solid",
                textAlign: "center",
                borderRadius: "3px",
                margin: "5px 10px 10px 10px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
              onChange={(e) => onChange(e.target.value, loggedInUser, false)}
              defaultValue={slctdID}
              id="selectedby"
            >
              {loggedInUser.autoFinanceUser.roleName !== "ADMIN" && (
                <option value={0} label="All" />
              )}
              <option value={1} label="Executives" />
              <option value={2} label="Managers" />
              {/* {loggedInUser.autoFinanceUser.roleName === "ADMIN" && ( */}
              <option value={3} label="Branch" />
              {/* )} */}
              {loggedInUser.autoFinanceUser.roleName === "ADMIN" && (
                <option value={4} label="Designation" />
              )}
              {loggedInUser.autoFinanceUser.roleName === "ADMIN" && (
                <option value={5} label="CP Names" />
              )}
            </select>
          </div>
        )}
      </div>
      <div>
        <div style={{ padding: "0px 10px" }}>
          <input
            type="checkbox"
            className="form-check-input"
            id="All"
            required
            onChange={() => onSelectAll("All")}
            checked={isSelectedAll}
          />
          <label className="form-check-label" htmlFor="All">
            Select All
          </label>
        </div>
        <div style={{ height: "350px", overflowY: "scroll" }}>
          <Table>
            {users.map((user, index) => {
              return (
                <tr>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 10px",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={user.userId}
                      required
                      onChange={() => onSelectAll(user.userId)}
                      checked={user.checked}
                    />
                    <label
                      style={{ marginBottom: "0px" }}
                      htmlFor={user.userId}
                    >
                      {user.name}
                    </label>
                  </td>
                  <td style={{ padding: "5px 10px" }}>{user.empId}</td>
                  <td style={{ padding: "5px 10px" }}>{user.email}</td>
                </tr>
              );
            })}
          </Table>
        </div>
      </div>
      <div>
        <Button
          style={{ float: "right" }}
          className="col-lg-2 m-2"
          type="submit"
          onClick={onSubmit}
          id="submitbtnselectexec"
        >
          Submit
        </Button>
        <Button
          style={{ float: "right" }}
          className="col-lg-2 m-2 float-right"
          variant="danger light"
          onClick={close}
          id="closebtnselectexec"
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default SelectExecutives;
