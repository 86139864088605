import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import TextField from "../CommonComponents/TextField";
import { Button } from "react-bootstrap";
import { getTokenInLocalStorage } from "../services/AuthService";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";

// const opportunityTypes = [
//   { label: "New Customer", value: "new_customer" },
//   { label: "Upsell", value: "upsell" },
//   { label: "Renewal", value: "renewal" },
//   { label: "Cross-sell", value: "cross_sell" },
// ];

// const opportunityStages = [
//   { label: "Prospecting", value: "prospecting" },
//   { label: "Qualification", value: "qualification" },
//   { label: "Proposal", value: "proposal" },
//   { label: "Negotiation", value: "negotiation" },
//   { label: "Closed Won", value: "closed_won" },
//   { label: "Closed Lost", value: "closed_lost" },
// ];

const AddOpportunity = ({ leadDetails, close }) => {
  let userLoginDetails = getTokenInLocalStorage();
  const [opportunityStages, setOpportunityStages] = useState([]);
  const [teamNames, setTeamNames] = useState([]);
  const [opportunityTypes, setOpportunityTypes] = useState([]);
  // const [branchNames, setBranchNames] = useState(null);
  const [assignees, setAssingees] = useState([]);

  useEffect(() => {
    getOpportunityStages();
    getOpportunityTypes();
    // getBranches();
    getTeamNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOpportunityStages = () => {
    moduleApi
      .getData(
        apiCall.getOpportunityStages +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        const result = response?.map((item) => ({
          value: item,
          label: item,
        }));
        setOpportunityStages(result);
      });
  };
  const getOpportunityTypes = () => {
    moduleApi
      .getData(
        apiCall.getOpportunityTypes +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        const result = response?.map((item) => ({
          value: item,
          label: item,
        }));
        setOpportunityTypes(result);
      });
  };

  // const getBranches = () => {
  //   const body = {
  //     organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //   };
  //   moduleApi.postData(apiCall.getBranches, body).then((response) => {
  //     const result = response.map((item) => ({
  //       branchId: item.branchId,
  //       branchName: item.branchName,
  //     }));
  //     setBranchNames(result);
  //   });
  // };

  const getUsers = (teamName) => {
    const payLoad = {
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      teamName: teamName,
    };
    moduleApi.postData(apiCall.getTeamUsers, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.userId,
        label: item.name + " - " + item.branchName,
        empId: item.empId,
      }));
      setAssingees(result);
    });
  };

  // const getBranch = (branchId) => {
  //   for (let i = 0; i < branchNames?.length; i++) {
  //     if (branchNames[i].branchId === branchId) {
  //       // console.log("branchName", branchNames[i].branchName);
  //       return branchNames[i].branchName;
  //     }
  //   }
  // };

  const numberRegex = /^(100|[1-9][0-9]?)$/;

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required("Amount is required"),
    // closingDate: Yup.date().required("Closing Date is required"),
    opportunityType: Yup.string().required("Opportunity Type is required"),
    opportunityStage: Yup.string().required("Opportunity Stage is required"),
    probability: Yup.string().matches(
      numberRegex,
      "Probability must be in between 1 to 100"
    ),
    // .required("Probability is required"),
    teamName: Yup.string().required("Team is required"),
    assignee: Yup.string().required("Assignee is required"),
  });

  const handleSubmit = (values) => {
    // console.log(values);
    let payload = {
      amount: values.amount,
      closingDate: Math.floor(new Date(values.closingDate).getTime() / 1000.0),
      leadId: leadDetails?.id,
      opportunityStage: values.opportunityStage,
      //   opportunityStageId: 0,
      opportunityType: values.opportunityType,
      //   opportunityTypeId: 0,
      probability: values.probability,
      createdByUserId: userLoginDetails?.autoFinanceUser?.userId,
      teamName: values.teamName,
      userId: values.assignee,
    };
    // console.log(payload);
    moduleApi.postData(apiCall.addOpportunity, payload).then((response) => {
      // console.log("addOpportunityResponse", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          close();
          return swal("Opportunity Added Successfully", {
            icon: "success",
            dangerMode: false,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
    // You can perform submission logic here
  };

  // const userOptions = [
  //   { label: "Managers", value: "MANAGER" },
  //   { label: "Executives", value: "EXECUTIVE" },
  // ];

  const getTeamNames = () => {
    var payload = {
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
    };
    moduleApi.postData(apiCall.getTeamNames, payload).then((response) => {
      const result = response?.map((item) => ({
        value: item,
        label: item,
      }));
      setTeamNames(result);
    });
  };

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "480px",
      }}
    >
      <Formik
        initialValues={{
          amount: "",
          closingDate: "",
          opportunityType: "",
          opportunityStage: "",
          probability: "",
          teamName: "",
          assignee: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                Opportunity Type
              </label>
              <Select
                id="opportunityType"
                name="opportunityType"
                options={opportunityTypes}
                value={opportunityTypes?.find(
                  (option) => option.value === values.opportunityType
                )}
                onChange={(option) =>
                  setFieldValue("opportunityType", option.value)
                }
                placeholder="Select Opportunity Type"
              />
              <ErrorMessage
                name="opportunityType"
                component="div"
                className="error"
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                Opportunity Stage
              </label>
              <Select
                id="opportunityStage"
                name="opportunityStage"
                options={opportunityStages}
                value={opportunityStages?.find(
                  (option) => option.value === values.opportunityStage
                )}
                onChange={(option) =>
                  setFieldValue("opportunityStage", option.value)
                }
                placeholder="Select Opportunity Stage"
              />
              <ErrorMessage
                name="opportunityStage"
                component="div"
                className="error"
              />
            </div>
            <TextField
              // isAsterisk={true}
              label="Closing Date"
              name="closingDate"
              type="date"
              min={new Date().toLocaleDateString("en-CA")}
            />
            {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "5px",
            }}
          >
            <label
              className="control-label"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Closing Date
            </label>
            <input
              style={{
                // width: "450px",
                // height: "90px",
                borderRadius: "15px",
                borderColor: "#a0a0a0",
                borderWidth: "0.5px",
                // boxShadow: "1px 1px 2px #3a353b",
                padding: "10px",
                // marginLeft: "10px",
                marginBottom: "10px",
              }}
              type="date"
              name="closingDate"
              min={new Date().toLocaleDateString("en-CA") + 1}
              // max={new Date().toISOString().split('T')[0]}
              // onChange={(e) => {
              //   setSelectedCallBackDate(
              //     Math.floor(new Date(e.target.value).getTime() / 1000.0)
              //   );
              // }}
            />
             <ErrorMessage
              name="closingDate"
              component="div"
              className="error"
            />
          </div> */}

            <TextField
              // isAsterisk={true}
              label="Probability"
              name="probability"
              type="text"
            />

            <TextField
              isAsterisk={true}
              label="Amount"
              name="amount"
              type="number"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                Team
              </label>
              <Select
                id="teamName"
                name="teamName"
                options={teamNames}
                value={teamNames?.find(
                  (option) => option.value === values.teamName
                )}
                onChange={(option) => {
                  getUsers(option.value);
                  setFieldValue("teamName", option.value);
                }}
                placeholder="Select Team"
              />
              <ErrorMessage name="teamName" component="div" className="error" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                Assignee
              </label>
              <Select
                id="assignee"
                name="assignee"
                options={assignees}
                value={assignees?.find(
                  (option) => option.value === values.assignee
                )}
                onChange={(option) => {
                  setFieldValue("assignee", option.value);
                }}
                placeholder="Select Assignee"
              />
              <ErrorMessage name="assignee" component="div" className="error" />
            </div>

            <div style={{ float: "right", marginTop: "10px" }}>
              <Button style={{ float: "right" }} className="me-2" type="submit">
                Submit
              </Button>
              <Button
                style={{ float: "right" }}
                className="me-2"
                variant="danger light"
                onClick={close}
              >
                Close
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddOpportunity;
