import React, { useState, useEffect } from "react";
import { Button, Row, Modal } from "react-bootstrap";
import swal from "sweetalert";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import Select from "react-select";
import { getTokenInLocalStorage } from "../services/AuthService";
import ReactGA from "react-ga";

const RequestDataSource = ({ close }) => {
  const [feedbackDataSource, setFeedbackDataSource] = useState(null);
  const [priorityValue, setPriorityValue] = useState(null);
  const [feedBackModal, setFeedBackModal] = useState(false);

  const userData = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview("Request Data Source");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const priorityOptns = [
    { label: "Immediate", value: "immediate" },
    { label: "Next Week", value: "nextWeek" },
    { label: "In Two Weeks", value: "inTwoWeek" },
  ];

  const saveFeedbackDataSource = () => {
    if (feedbackDataSource === null) {
      return swal("Please Enter Data Source", {
        icon: "error",
        dangerMode: true,
      });
    } else if (priorityValue === null) {
      return swal("Please Select Priority", {
        icon: "error",
        dangerMode: true,
      });
    }
    var body = {
      dataSourceName: feedbackDataSource,
      message: "",
      priority: priorityValue,
      organizationId: userData.autoFinanceOrganization.organizationId,
      userId: userData.autoFinanceUser.userId,
    };
    moduleApi.postData(apiCall.saveFeedback, body).then((response) => {
      console.log("response", response);
      if (response) {
        if (response.message) {
          swal(response.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          setFeedBackModal(false);
          swal(
            "Your Request for a New Data Source has been registered and we will get back to you Soon",
            {
              icon: "success",
            }
          );
        }
      } else {
        swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };
  return (
    <div>
      <div
        style={{
          marginLeft: "40px",
          alignSelf: "center",
        }}
      >
        <Button
          style={{ marginRight: "10px" }}
          className="add-graph-btn"
          variant="primary btn-rounded"
          onClick={() => {
            setFeedBackModal(true);
          }}
          type="submit"
        >
          <i class="fa fa-comments"></i> <b>Request a Data Source</b>
        </Button>
      </div>
      <div>
        <Modal className="fade" show={feedBackModal} size="md">
          <Modal.Header>
            <Modal.Title>Request a Data Source</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setFeedBackModal(false);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ paddingTop: "10px" }}>
            {/* <RequestDataSource close={setFeedBackModal} /> */}

            <Row>
              <label style={{ fontWeight: "bold" }}>
                Describe your new data source request (in english)
              </label>
              <input
                style={{
                  width: "450px",
                  height: "35px",
                  borderRadius: "7px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter..."
                onChange={(e) => {
                  setFeedbackDataSource(e.target.value);
                  console.log("feedbackDataSource", feedbackDataSource);
                }}
              />
              <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                By when do you need it ?
              </label>

              <div
              // style={{
              //   float: "right",
              //   width: "150px",
              //   marginRight: "20px",
              //   marginBottom: "20px",
              // }}
              >
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    setPriorityValue(value.value);
                    console.log(priorityValue);
                  }}
                  // defaultValue={optionDates[valIndex]}
                  options={priorityOptns}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <div>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2"
                    type="submit"
                    onClick={() => saveFeedbackDataSource()}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2 float-right"
                    variant="danger light"
                    onClick={() => setFeedBackModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default RequestDataSource;
