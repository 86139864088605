import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoadingOverlay from "react-loading-overlay";
import * as moduleApi from "../rest/moduleApi";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Flatpickr from "react-flatpickr";
import Spinner from "react-bootstrap/Spinner";
import { apiCall } from "../rest/restApi";
import { Button } from "react-bootstrap";
import Select from "react-select"
import { getTokenInLocalStorage } from "../services/AuthService";

const PaymentFailuresData = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");


  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );
  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    loadGraph(start, end, selectedPaymentType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
        1000 +
      86400;
    setStart(start);
    setEnd(end);
    loadGraph(start, end, selectedPaymentType);
  };

  const paymentTypes = [
    { label: "All", value: "" },
    { label: "QR Code", value: "QrCode" },
    { label: "UPI Payment Requests", value: "CollectRequest" },
    { label: "Bulk Payment Requests", value: "paymentLinK" },
  ];

  const loadGraph = (start, end, paymentType) => {
    setIsLoading(true)
    let payload = {
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      startDate: start,
      paymentType:paymentType
    };
    moduleApi.postData(apiCall.getPayResponseCode, payload).then((response) => {
      console.log("payresponsecode", response);
      let keys = [];
      let values = [];
      for (let value of Object.keys(response)) {
        keys.push(value);
      }

      for (let value of Object.values(response)) {
        values.push(value);
      }
      setData({
        series: values,
        
        options: {
          chart: {
            width: 380,
            type: "pie",
          },
          dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            },
          },
          noData: {
            text: "No Data Found",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#000000",
              fontSize: "20px",
              fontFamily: "Helvetica",
            },
          },
          labels: keys,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      });
      setIsLoading(false)
    });
  };

  return (
    <div>
      {/* <div
        style={{
          float: "right",
          textAlignLast: "center",
          marginTop: "15px",
          marginLeft: "10px",
        }}
      > */}
      <div
          style={{
            float: "right",
            width: "140px",
            marginRight: "20px",
            height: "38px",
            marginLeft: "15px",
            borderRadius: "3px",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #797979",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) => {
              loadGraph(start, end, value.value)
              setSelectedPaymentType(value.value)}}
            defaultValue={paymentTypes[0]}
            options={paymentTypes}
          />
        </div>
      <Flatpickr
        className="form-control d-block"
        style={{
          width: "180px",
          height: "40px",
          borderColor: "#a0a0a0",
          borderWidth: "1px",
          boxShadow: "1px 1px 2px #3a353b",
          textAlignLast: "center",
        }}
        placeholder="Select Month"
        onChange={(value) => {
          setCustomDatesForGraph(value);
        }}
        options={{
          defaultDate: new Date(),
          maxDate: new Date(),
          minDate: new Date().setMonth(new Date().getMonth() - 6),

          plugins: [
            new monthSelectPlugin({
              shorthand: true, //defaults to false
              dateFormat: "F Y", //defaults to "F Y"
              altFormat: "F Y", //defaults to "F Y"
              theme: "light", // defaults to "light"
            }),
          ],
        }}
      />
      {/* </div> */}
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ margin: "10px" }}>
          {data?.options && (
            <ReactApexChart
              options={data.options}
              series={data.series}
              type="pie"
              height={350}
            />
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default PaymentFailuresData;
