import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Table } from "react-bootstrap";
import swal from "sweetalert";
import * as moduleApi from "../rest/moduleApi";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import ReactGA from "react-ga";
import { Form, Formik } from "formik";
import TextField from "../CommonComponents/TextField";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import AddAdmin from "./AddAdmin";
import SearchBar from "../CommonComponents/SearchBar";

function UpdatePrivilages() {
  let orgDetails;
  const [orgList, setloggedInOrg] = useState("");
  const [rolesList, setRolesList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [roleAndPrivilageDetails, setRoleAndPrivilageDetails] = useState("");
  const [empDetails, setEmpDetails] = useState("");
  const [selectedIDS, setSelectedIDS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [overAll, setOverAll] = useState([]);
  const [privilagesList, setPrivilagesList] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [updateUserPrivilegesModal, setUpdateUserPrivilegesModal] = useState(false);
  const [privilegesDownloadList, setprivilegesDownloadList] = useState(null);
  const [apportionsDetails, setApportionsDetails] = useState("");
  const [updateApportionsModal, setUpdateApportionsModal] = useState(false);
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );

  var history = useHistory();

  const countPattern = /^\d+$/;
  const percentagePattern = /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?)$/;

  const updateApportionsValidate = Yup.object({
    emiAmountProirity: Yup.string()
      .required("EMI Amount Proirity is required")
      .matches(countPattern, "please enter a valid number"),
    latePaymentChargesProirity: Yup.string()
      .required("Late Payment Charges Proirity is required")
      .matches(countPattern, "please enter a valid number"),
    bounceChargesProirity: Yup.string()
      .required("Bounce Charges Proirity is required")
      .matches(countPattern, "please enter a valid number"),
    emiPercentage: Yup.string()
      .required("EMI Percentage is required")
      .matches(percentagePattern, "please enter a valid percentage"),
    latePaymentChargesPercentage: Yup.string()
      .required("Late Payment Charges Percentage is required")
      .matches(percentagePattern, "please enter a valid percentage"),
    bounceChargesPercentage: Yup.string()
      .required("Bounce Charges Percentage is required")
      .matches(percentagePattern, "please enter a valid percentage"),
  });


  const roleColumns = [
    {
      text: "Role Name",
      dataField: "roleName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedIDS(row.privilegeIds);
          setRoleAndPrivilageDetails(row);
          console.log("rowDetailsss", row);
          setBasicModal(true);
        },
      },
    },
  ];

  const empColumns = [
    {
      text: "Emp Name",
      dataField: "name",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Id",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Email Id",
      dataField: "email",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          getUserPrivileges(row.userId);
          setEmpDetails(row);
          setUpdateUserPrivilegesModal(true);
        },
      },
    },
  ];

  useEffect(() => {
    var orgData = localStorage.getItem("organization-details");
    setloggedInOrg(orgData);
    // eslint-disable-next-line
    orgDetails = localStorage.getItem("organizationId");
    loadPrivilagesData();
    setOrgId(orgDetails);
    loadData(orgDetails);
    loadEmployeesData(orgDetails);
    loadAppotionsData(orgDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (EmpOrgId) => {
    moduleApi.getData(apiCall.getRolesBasedOnOrg, EmpOrgId).then((response) => {
      console.log("response", response);
      var listData = [];
      response?.forEach((element) => {
        let data = {
          id: element.id,
          roleName: element.roleName,
          organizationId: element.organizationId,
          privilegeIds: element.privilegeIds
        };
        listData.push(data);
      });
      setRolesList(listData);
      console.log(listData);
    });
  };

  const loadEmployeesData = (EmpOrgId) => {

    var payLoad = {
      organizationId: EmpOrgId,
      status: "ACTIVE"
    };

    moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
      var listData = [];
      response?.forEach((element) => {
        let data = {
          userId: element.userId,
          roleName: element.roleName ? element.roleName : "-",
          organizationId: element.organizationId,
          branchId: element.branchId ? element.branchId : "-",
          name: element.name ? element.name : "-",
          email: element.email ? element.email : "-",
          empId: element.empId ? element.empId : "-",
        };
        listData.push(data);
      });
      setEmployeesList(listData);
    });
  };

  const loadPrivilagesData = () => {

    moduleApi.getUserData(apiCall.getPrivilages).then((response) => {
      var listData = [];

      response?.forEach((element) => {
        let data = {
          id: element.id,
          privilageName: element.privilageName ? element.privilageName : "-",
          privilageGroup: element.privilageGroup ? element.privilageGroup : "-",
          privilegeMainGroup: element.privilegeMainGroup ? element.privilegeMainGroup : "-",
          purpose: element.purpose ? element.purpose : "-",
          affects: element.affects ? element.affects : "-",
        };
        listData.push(data);
      });
      setPrivilagesList(listData);
    });
  };

  const loadAppotionsData = (EmpOrgId) => {

    var payLoad = {
      organizationId: EmpOrgId,
    };

    moduleApi.postData(apiCall.getApportionConfiguration, payLoad).then((response) => {

      let data = {
        organizationId: response.organizationId ? response.organizationId : EmpOrgId,
        emiAmountProirity: response.emiAmountProirity ? response.emiAmountProirity : 0,
        latePaymentChargesProirity: response.latePaymentChargesProirity ? response.latePaymentChargesProirity : 0,
        bounceChargesProirity: response.bounceChargesProirity ? response.bounceChargesProirity : 0,
        penaltyChargesProirity: response.penaltyChargesProirity ? response.penaltyChargesProirity : 0,
        otherChargesProirity: response.otherChargesProirity ? response.otherChargesProirity : 0,
        emiPercentage: response.emiPercentage ? response.emiPercentage : 0,
        latePaymentChargesPercentage: response.latePaymentChargesPercentage ? response.latePaymentChargesPercentage : 0,
        bounceChargesPercentage: response.bounceChargesPercentage ? response.bounceChargesPercentage : 0,
        penaltyChargesPercentage: response.penaltyChargesPercentage ? response.penaltyChargesPercentage : 0,
        otherChargesPercentage: response.otherChargesPercentage ? response.otherChargesPercentage : 0,
      };

      setApportionsDetails(data);
      console.log("apportionDetailsss", data);
    });
  };

  const updateApportionsDetails = (values) => {

    var payload = {

      organizationId: apportionsDetails.organizationId,
      lastUpdateByUserId: loggedInUser?.autoFinanceUser?.userId,
      emiAmountProirity: values.emiAmountProirity,
      latePaymentChargesProirity: values.latePaymentChargesProirity,
      bounceChargesProirity: values.bounceChargesProirity,
      penaltyChargesProirity: values.penaltyChargesProirity,
      otherChargesProirity: values.otherChargesProirity,
      emiPercentage: values.emiPercentage,
      latePaymentChargesPercentage: values.latePaymentChargesPercentage,
      bounceChargesPercentage: values.bounceChargesPercentage,
      penaltyChargesPercentage: values.penaltyChargesPercentage,
      otherChargesPercentage: values.otherChargesPercentage,

    };
    moduleApi.postData(apiCall.updateApportionsConfiguration, payload).then((response) => {
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          swal("Apportions Updated Successfully", {
            icon: "success",
          });
          loadAppotionsData(response.organizationId);
          setUpdateApportionsModal(false);
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const getUserPrivileges = (userId) => {

    moduleApi.getData(apiCall.getPrivilagesBasedOnUser, userId).then((response) => {
      setSelectedIDS(response);
    });
  };

  const onSubmit = () => {

    setIsLoading(true);

    var body = {
      id: roleAndPrivilageDetails.id,
      organizationId: orgId,
      privilegeIds: selectedIDS,
      roleName: roleAndPrivilageDetails.roleName,
    };

    moduleApi
      .postData(apiCall.updateprivilages, body)
      .then((response) => {
        if (response) {
          if (response?.message) {
            setIsLoading(false);
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response) {
            loadData(orgId);
            setBasicModal(false);
            setIsLoading(false);
            swal("Privileges Updated Successfully", {
              icon: "success",
            });
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });

  };

  const updateUserBasedPrivileges = () => {

    setIsLoading(true);

    var body = {
      userId: empDetails.userId,
      privialageIds: selectedIDS
    };

    moduleApi
      .postData(apiCall.updatePersonBasedPrivilages, body)
      .then((response) => {
        if (response) {
          if (response?.message) {
            setIsLoading(false);
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response) {
            setUpdateUserPrivilegesModal(false);
            setIsLoading(false);
            swal("Privileges Updated Successfully", {
              icon: "success",
            });
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });

  };

  const onSelectAll = (v) => {
    // if (v === "All") {
    //   if (isSelectedAll) {
    //     removeAll();
    //   } else {
    //     addAll();
    //   }
    //   setIsSelectedAll(!isSelectedAll);
    //   return;
    // }

    const found = selectedIDS.find((element) => element === v);
    var checkedList = [...selectedIDS];
    if (found) {
      for (var i = 0; i < checkedList.length; i++) {
        if (checkedList[i] === v) {
          checkedList.splice(i, 1);
        }
      }
    } else checkedList.push(v);

    setSelectedIDS(checkedList);
    const res = [...overAll];

    res.forEach((item) => {
      if (item.id === v) {
        item.checked = !item.checked;
      }
    });

    // if (checkedList.length === res.length) setIsSelectedAll(true);
    // else setIsSelectedAll(false);

    setOverAll(res);
  };

  // const addAll = () => {
  //   var checkedList = [];
  //   const res = [...overAll];
  //   res.forEach((item) => {
  //     item.checked = true;
  //     checkedList.push(item.id);
  //   });
  //   setOverAll(res);
  //   setSelectedIDS(checkedList);
  // };

  // const removeAll = () => {
  //   const res = [...overAll];
  //   res.forEach((item) => {
  //     item.checked = false;
  //   });
  //   setOverAll(res);
  //   setSelectedIDS([]);
  // };

  const groupedPrivilages = privilagesList.reduce((acc, privilege) => {
    const { privilageGroup } = privilege;
    if (!acc[privilageGroup]) {
      acc[privilageGroup] = [];
    }
    acc[privilageGroup].push(privilege);
    return acc;
  }, {});

  const sortedGroups = Object.keys(groupedPrivilages).sort((a, b) => {
    if (a === "Others") return 1;
    if (b === "Others") return -1;
    return a.localeCompare(b);
  });

  useEffect(() => {
    privilegesDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilegesDownloadList]);

  const userPrivilegesDownloadData = () => {

    setIsLoading(true);
    getPrivilegesFullResponseData();
    // moduleApi.getUserData(apiCall.getPrivilages)
    //   .then((response) => {
    //     if (response) {
    //       getPrivilegesFullResponseData();
    //     } else {
    //       swal("Something Went Wrong, Please Try Later", {
    //         icon: "error",
    //         dangerMode: true,
    //       });
    //     }
    //   });
  };

  async function getPrivilegesFullResponseData() {
    var tempList = [];
    var privilegesListData = [];
    
      await moduleApi.getUserData(apiCall.getPrivilages)
        .then((response) => {
          tempList.push(...response);
        });
    

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {

        id: element.id,
        privilageName: element.privilageName ? element.privilageName : "-",
        privilageGroup: element.privilageGroup ? element.privilageGroup : "-",
        privilegeMainGroup: element.privilegeMainGroup ? element.privilegeMainGroup : "-",
        purpose: element.purpose ? element.purpose : "-",
        affects: element.affects ? element.affects : "-",

      };
      privilegesListData.push(data);
    });
    setprivilegesDownloadList(privilegesListData);
  };

  const handleExport = () => {

    let userPrivilegesTable = [
      {
        A:"Privilege Id",
        B: "Privilege Name",
        C: "Privilege Group",
        D: "Privilege Main Group",
        E: "Affects",
        F: "Is Applicable"
      },
    ];

    privilegesDownloadList?.forEach((row) => {
      userPrivilegesTable.push({
        A:row.id,
        B: row.privilageName,
        C: row.privilageGroup,
        D: row.privilegeMainGroup,
        E: row.affects,
        F: selectedIDS.includes(row.id) ? "Yes" : "No"
      });
    });

    const finalData = [...userPrivilegesTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Privileges Details");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      updateUserPrivilegesModal === true ? empDetails.empId +
        "_Privileges_Details" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx" : roleAndPrivilageDetails.roleName +
        "_Privileges_Details" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx"
    );
    ReactGA.event({
      category: "Privileges List Downlaod",
      action:
        empDetails.empId +
        "_Privilege_Details_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const searchKeyword = (key) => {

    if(key !==""){
    const res = [...employeesList];
    const searchString = key;

    let matchingStrings = [];

    res.forEach((list) => {
      if (list?.name?.toLowerCase().search(searchString.toLowerCase()) > -1) {
        matchingStrings.push(list);
      } else if (
        list?.empId?.toLowerCase().search(searchString.toLowerCase()) > -1
      ) {
        matchingStrings.push(list);
      }else if (list?.email?.toLowerCase().search(searchString.toLowerCase()) > -1) {
        matchingStrings.push(list);
      }
    });
    setEmployeesList(matchingStrings);
  }else{
    loadEmployeesData(orgId);
  }
  };

  const handleClose = () => {
    setAddAdminModal(false);
    loadEmployeesData(orgId);
  };

  return (
    <div className="card">
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >

        <div className="custom-tab-1">
          <div
            style={{ display: "flex", justifyContent: "end", marginRight: "10px", marginTop: "10px" }}
          >
            <Button
              style={{
                float: "right",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => {
                setUpdateApportionsModal(true)
              }}
            >
              <b> Update Apportions </b>
            </Button>
          </div>
          <div style={{ margin: "10px" }}>
            <Button
              style={{
                float: "left",
                margin: "10px",
                marginLeft: "20px",
                marginTop: "10px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => history.push("/onboarding")}
            >
              <i className="fa fa-arrow-left" />
            </Button>
            <span
              style={{
                float: "left",
                marginLeft: "10px",
                margin: "10px",
                marginTop: "16px",
                fontSize: "18px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                fontWeight: "bold",
              }}
            >
              {orgList ? orgList : "-"}
            </span>
          </div>
          <div style={{ marginTop: "70px" }}>
            <Tab.Container defaultActiveKey="roles">
              <Nav as="ul" className="nav-tabs-custom nav-justified">
                <Nav.Item as="li" key="0">
                  <Nav.Link eventKey="roles">
                    <b> Roles </b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key="1">
                  <Nav.Link eventKey="users">
                    <b> Employees</b>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="roles" key="0" mountOnEnter>
                  <div
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <BootstrapTable
                      keyField="id"
                      data={rolesList}
                      columns={roleColumns}
                    />
                    {rolesList.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <img src={NO_DATA_FOUND} alt="" />
                      </div>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="users" key="1" mountOnEnter>
                <div
        style={{ display: "flex", justifyContent: "end", alignItems: "center",marginRight:"20px" }}
      >
        <SearchBar
          placeholder="Search By Name/Emp Id/Email Id"
          searchKeyword={searchKeyword}
          id="searchselectemployees"
        />
        <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => setAddAdminModal(true)}
          >
            <b> + Add Admin</b>
          </Button>
          </div>
                  <div
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <BootstrapTable
                      keyField="id"
                      data={employeesList}
                      columns={empColumns}
                    />
                    {employeesList.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <img src={NO_DATA_FOUND} alt="" />
                      </div>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" show={basicModal} size="lg">
        <Modal.Header>
          <Modal.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "5px",
                marginRight: "20px",
              }}
            >
              Update Privileges For {" "}
              {
                <span style={{ fontWeight: "bold" }}>
                  {roleAndPrivilageDetails.roleName}
                </span>
              }
              <div style={{ marginLeft: "300px" }}>

                <Button
                  style={{
                    marginRight: "10px",
                    marginLeft: "7px",
                    marginBottom: "5px",
                    alignSelf: "flex-end",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => userPrivilegesDownloadData()}
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                </Button>
              </div>
            </div>
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          {/* <div style={{ height: "350px", overflowY: "scroll" }}>
            <Table>
              {privilagesList.map((element) => {
                const isChecked = selectedIDS.includes(element.id);
                return (
                  <tr>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={element.id}
                        required
                        onChange={() => onSelectAll(element.id)}
                        checked={isChecked}
                      />
                      <label
                        style={{ marginBottom: "0px" }}
                        htmlFor={element.id}
                      >
                        {element.privilageName}
                      </label>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </div> */}
          <div style={{ height: '350px', overflowY: 'scroll' }}>
            <Table>
              {sortedGroups.map((group) => (
                <React.Fragment key={group}>
                  <tr>
                    <th
                      colSpan="2"
                      style={{ background: '#f5f5f5', padding: '10px 0', textAlign: 'left' }}
                    >
                      {group}
                    </th>
                  </tr>
                  {groupedPrivilages[group].map((element) => {
                    const isChecked = selectedIDS.includes(element.id);
                    return (
                      <tr key={element.id}>
                        <td
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '5px 10px',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={element.id}
                            required
                            onChange={() => onSelectAll(element.id)}
                            checked={isChecked}
                          />
                          <label
                            style={{ marginBottom: '0px' }}
                            htmlFor={element.id}
                          >
                            {element.privilageName + "("+ element.id + ")"}
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              ))}
            </Table>
          </div>
          <div>
            <Button
              style={{ float: "right" }}
              className="col-lg-2 m-2"
              type="submit"
              onClick={onSubmit}
              id="submitbtnselectexec"
            >
              Submit
            </Button>
            <Button
              style={{ float: "right" }}
              className="col-lg-2 m-2 float-right"
              variant="danger light"
              onClick={() => setBasicModal(false)}
              id="closebtnselectexec"
            >
              Close
            </Button>
          </div>

          {/* <AddRolesAndPrivilage /> */}
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={updateUserPrivilegesModal} size="lg">
        <Modal.Header>
          <Modal.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "5px",
                overflowX: "auto"
              }}
            >
              Update Privileges For {" "}
              {
                <span style={{ fontWeight: "bold" }}>
                  {empDetails.name}
                  {" "} {"(" + empDetails.empId + ")"}
                </span>
              }
              <div style={{ marginLeft: "270px" }}>
                <Button
                  style={{
                    marginRight: "5px",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => userPrivilegesDownloadData()}
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                </Button>
              </div>
            </div>
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setUpdateUserPrivilegesModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ height: '350px', overflow: 'auto', display: "flex", margin: "10px 0px" }}>
            <Table>
              {sortedGroups.map((group) => (
                <React.Fragment key={group}>
                  <tr>
                    <th
                      colSpan="3"
                      style={{ background: '#f5f5f5', padding: '10px 0', textAlign: 'left' }}
                    >
                      {group}
                    </th>
                  </tr>
                  {groupedPrivilages[group].map((element) => {
                    const isChecked = selectedIDS.includes(element.id);
                    return (
                      <tr key={element.id}>
                        <td
                          style={{

                            padding: '5px 10px',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={element.id}
                            required
                            onChange={() => onSelectAll(element.id)}
                            checked={isChecked}
                          />
                          <label
                            style={{ marginBottom: '0px' }}
                            htmlFor={element.id}
                          >
                            {element.privilageName + "("+ element.id + ")"}
                          </label>
                        </td>
                        <td style={{ padding: "5px 10px" }}>{element.privilegeMainGroup}</td>
                        <td style={{ padding: "5px 10px" }}>{element.affects}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              ))}
            </Table>
          </div>
          <div>
            <Button
              style={{ float: "right" }}
              className="col-lg-2 m-2"
              type="submit"
              onClick={updateUserBasedPrivileges}
              id="submitbtnselectexec"
            >
              Submit
            </Button>
            <Button
              style={{ float: "right" }}
              className="col-lg-2 m-2 float-right"
              variant="danger light"
              onClick={() => setUpdateUserPrivilegesModal(false)}
              id="closebtnselectexec"
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal className="fade" show={updateApportionsModal}>
        <Modal.Header>
          <Modal.Title>Update Apportions</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setUpdateApportionsModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                emiAmountProirity: apportionsDetails.emiAmountProirity,
                latePaymentChargesProirity: apportionsDetails.latePaymentChargesProirity,
                bounceChargesProirity: apportionsDetails.bounceChargesProirity,
                penaltyChargesProirity: apportionsDetails.penaltyChargesProirity,
                otherChargesProirity: apportionsDetails.otherChargesProirity,
                emiPercentage: apportionsDetails.emiPercentage,
                latePaymentChargesPercentage: apportionsDetails.latePaymentChargesPercentage,
                bounceChargesPercentage: apportionsDetails.bounceChargesPercentage,
                penaltyChargesPercentage: apportionsDetails.penaltyChargesPercentage,
                otherChargesPercentage: apportionsDetails.otherChargesPercentage,
              }}
              onSubmit={updateApportionsDetails}
              validationSchema={updateApportionsValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            columnGap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="EMI Amount Priority"
                              name="emiAmountProirity"
                              type="text"
                            />
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="EMI Percentage"
                              name="emiPercentage"
                              type="text"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            columnGap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Late Payment Charges Proirity"
                              name="latePaymentChargesProirity"
                              type="text"
                            />
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Late Payment Charges Percentage"
                              name="latePaymentChargesPercentage"
                              type="text"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            columnGap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Bounce Charges Proirity"
                              name="bounceChargesProirity"
                              type="text"
                            />
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Bounce Charges Percentage"
                              name="bounceChargesPercentage"
                              type="text"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            columnGap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Penalty Charges Proirity"
                              name="penaltyChargesProirity"
                              type="text"
                            />
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Penalty Charges Percentage"
                              name="penaltyChargesPercentage"
                              type="text"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            columnGap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Other Charges Proirity"
                              name="otherChargesProirity"
                              type="text"
                            />
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              isAsterisk={true}
                              label="Other Charges Percentage"
                              name="otherChargesPercentage"
                              type="text"
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setUpdateApportionsModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal> */}
  <Modal className="fade" show={updateApportionsModal}>
  <Modal.Header>
    <Modal.Title>Update Apportions</Modal.Title>
    <Button
      variant=""
      className="btn-close"
      onClick={() => {
        setUpdateApportionsModal(false);
      }}
    ></Button>
  </Modal.Header>
  <Modal.Body>
    <Formik
      initialValues={{
        emiAmountProirity: apportionsDetails.emiAmountProirity,
        latePaymentChargesProirity: apportionsDetails.latePaymentChargesProirity,
        bounceChargesProirity: apportionsDetails.bounceChargesProirity,
        penaltyChargesProirity: apportionsDetails.penaltyChargesProirity,
        otherChargesProirity: apportionsDetails.otherChargesProirity,
        emiPercentage: apportionsDetails.emiPercentage,
        latePaymentChargesPercentage: apportionsDetails.latePaymentChargesPercentage,
        bounceChargesPercentage: apportionsDetails.bounceChargesPercentage,
        penaltyChargesPercentage: apportionsDetails.penaltyChargesPercentage,
        otherChargesPercentage: apportionsDetails.otherChargesPercentage,
      }}
      onSubmit={updateApportionsDetails}
      validationSchema={updateApportionsValidate}
    >
      {({ errors }) => (
        <Form>
          <Row>
            <Col lg="12">
              <div style={{ marginBottom: "20px" }}>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="EMI Amount Priority"
                    name="emiAmountProirity"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="EMI Percentage"
                    name="emiPercentage"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <hr />
              <div style={{ marginBottom: "20px" }}>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="Late Payment Charges Priority"
                    name="latePaymentChargesProirity"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="Late Payment Charges Percentage"
                    name="latePaymentChargesPercentage"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <hr />
              <div style={{ marginBottom: "20px" }}>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="Bounce Charges Priority"
                    name="bounceChargesProirity"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="Bounce Charges Percentage"
                    name="bounceChargesPercentage"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <hr />
              <div style={{ marginBottom: "20px" }}>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="Penalty Charges Priority"
                    name="penaltyChargesProirity"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="Penalty Charges Percentage"
                    name="penaltyChargesPercentage"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <hr />
              <div style={{ marginBottom: "20px" }}>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="Other Charges Priority"
                    name="otherChargesProirity"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <TextField
                    isAsterisk={true}
                    label="Other Charges Percentage"
                    name="otherChargesPercentage"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <hr />
              <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setUpdateApportionsModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  </Modal.Body>
      </Modal>
<Modal className="fade" show={addAdminModal}>
        <Modal.Header>
          <Modal.Title>Add Admin</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddAdminModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AddAdmin close={handleClose}  orgId={orgId} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpdatePrivilages;
