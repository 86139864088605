import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SRLWrapper } from "simple-react-lightbox";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess } from "../services/AuthService";
// import { toaster } from "../../src/rest/apiUtils";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import swal from "sweetalert";
import ReactGA from "react-ga";

const ExpenseDetails = ({
  userId,
  branchId
}) => {
  const [expenseList, setExpenseList] = useState([]);
  const [expenseDetailsList, setexpenseDetailsList] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [expenseApproval, setExpenseApproval] = useState(false);
  const [expenseApprovalForAdmin, setExpenseApprovalForAdmin] = useState(false);

  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("userDetails"));
    setloggedInUser(userData);
    setDateValue("Last 7 Days", 1);
    loadExpenseData(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    ReactGA.pageview('Expenses');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loadExpenseData = (pageNo) => {
    setIsLoading(true);
    var payload = {
      endtime: end,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      starttime: start,
      branchId:branchId,
      userId: userId,

      //   searchKey: key,
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.getExpenseList, pageNo, 10), payload)
      .then((response) => {
        console.log("expenseResponse", response.content);
        setCurrPage(pageNo);
        var listData = [];

        response?.content?.forEach((element) => {
          let data = {
            branchId: element.branchId,
            comments: element.comments?element.comments:'-',
            expenseCost: element.expenseCost?element.expenseCost:'-',
            expenseStatus: element.expenseStatus,
            expenseType: element.expenseType?element.expenseType:'-',
            expensesAddedTime: element.expensesAddedTime?convertEpochTimeToDate(
              element.expensesAddedTime
            ):'-',
            id: element.id,
            images: element.images,
            imagesCount: element.imagesCount,
            lastUpdatedTime: element.lastUpdatedTime,
            organizationId: element.organizationId,
            userId: element.userId,
            userModel: element.userModel,

            addedByUserId: element.userModel.addedByUserId,
            companyDesignation: element.userModel.companyDesignation,
            email: element.userModel.email,
            employeeId: element.userModel.employeeId,
            image: element.userModel.image,
            mobileNumber: element.userModel.mobileNumber,
            name: element.userModel.name,
            passwordUpdated: element.userModel.passwordUpdated,
            reportingOfficerId: element.userModel.reportingOfficerId,
            reportingOfficerIds: element.userModel.reportingOfficerIds,
            roleName: element.userModel.roleName,
            status: element.userModel.status,
            subOrdinateIds: element.userModel.subOrdinateIds,
            subOrdinateManagersIds: element.userModel.subOrdinateManagersIds,
            trackingEnabled: element.userModel.trackingEnabled,
            adminApproved: element.adminApproved,
            managerApproved: element.managerApproved,
          };
          listData.push(data);
        });

        setExpenseList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

  const accepttheapprove = (row) => {
    if (
      loggedInUser.autoFinanceUser.roleName === "MANAGER" &&
      row.managerApproved === false
    ) {
      console.log("true");
      setExpenseApproval(true);
    } else if (
      loggedInUser.autoFinanceUser.roleName === "ADMIN" &&
      row.managerApproved === true &&
      row.adminApproved === false
    ) {
      console.log("false");
      // setExpenseApproval(true);
      setExpenseApprovalForAdmin(true);
    } else if (
      loggedInUser.autoFinanceUser.roleName === "MANAGER" &&
      row.managerApproved === true &&
      row.adminApproved === false
    ) {
      setExpenseApproval(false);
    } else if (
      loggedInUser.autoFinanceUser.roleName === "ADMIN" &&
      row.reportingOfficerId === loggedInUser.autoFinanceUser.userId &&
      row.adminApproved === false
    ) {
      setExpenseApproval(true);
      setExpenseApprovalForAdmin(true);
    }
  };

  const updateExpenseRequest = (expenseDetails, status) => {
    // var body = {
    //   leaveRequestId: leaveData.id,
    //   leaveStatus: status,
    //   userId: leaveData.userId,
    // };
    // console.log("managerApproved: " +expenseApproval, "adminApproved:" +
    // expenseApprovalForAdmin);
    var body = {
      expenseStatus: status,
      id: expenseDetails.id,
      branchId: expenseDetails.branchId,
      comments: expenseDetails.comments,
      expenseCost: expenseDetails.expenseCost,
      userId: expenseDetails.userId,
      organizationId: expenseDetails.organizationId,
      managerApproved: expenseApproval && expenseDetails?.expenseStatus === "PENDING" ? true : false,
      adminApproved:
        expenseApprovalForAdmin && expenseDetails?.expenseStatus === "PENDING" ? true : false,
    };

    moduleApi.postData(apiCall.updateExpenseRequest, body).then((response) => {
      setBasicModal(false);
      setBasicModal2(false);
      loadExpenseData(0);
      swal("Expense Status Updated", {
        icon: "success",
      })
    });
  };

  const onPageChange = (selectedPage) => {
    loadExpenseData(selectedPage - 1);
  };

  const columns = [
    {
      text: "Employee ID",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Expense Type",
      dataField: "expenseType",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Date & Time",
      dataField: "expensesAddedTime",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Expense",
      dataField: "expenseCost",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Manager</span>
          <br></br>
          <span>Approval</span>
        </div>
      ),
      dataField: "expenseStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.managerApproved === true) {
          return (
            <span className="badge badge-success">
              Approved
              <span className="ms-1 fa fa-check" />
            </span>
          );
        } else if (row.expenseStatus === "REJECTED") {
          return (
            <span className="badge badge-danger">
              Rejected
              <span className="ms-1 fa fa-ban" />
            </span>
          );
        } else if (row.managerApproved === false) {
          return (
            <span className="badge badge-warning">
              Pending
              <span className="ms-1 fas fa-stream" />
            </span>
          );
        }
      },
    },

    {
      text: (
        <div>
          <span>Admin</span>
          <br></br>
          <span>Approval</span>
        </div>
      ),
      dataField: "expenseStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.expenseStatus === "ACCEPTED") {
          return (
            <span className="badge badge-success">
              Approved
              <span className="ms-1 fa fa-check" />
            </span>
          );
        } else if (row.expenseStatus === "PENDING") {
          return (
            <span className="badge badge-warning">
              Pending
              <span className="ms-1 fas fa-stream" />
            </span>
          );
        } else if (row.expenseStatus === "REJECTED") {
          return (
            <span className="badge badge-danger">
              Rejected
              <span className="ms-1 fa fa-ban" />
            </span>
          );
        }
      },
    },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setexpenseDetailsList(row);
          accepttheapprove(row)
          setBasicModal(true);
          //   setToggle("chatbox");
        },
      },
    },
  ];

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const setDateValue = (label, value) => {
    var date;
    console.log(value, "value");
    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        // loadExpenseData();
        return;
      case 2:
        var today = new Date();

        // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
        setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));

        setShowDate(false);
        //loadExpenseData();
        return;

      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        //loadExpenseData();
        return;
      case 4:
        setShowDate(true);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  function setCustomDates(dateStrings) {
    if (dateStrings.length > 1) {
      // start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
      // end = Math.floor(new Date(dateStrings[1]).getTime() / 1000);
      setStart(Math.floor(dateStrings[0].getTime() / 1000));
      setEnd(Math.floor(dateStrings[1].getTime() / 1000) + 86400);
    }
    //loadExpenseData();
  }

  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };
  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginBottom: "5px",
        }}
      >
        {/* <input
        style={{
          width: "260px",
          height: "37px",
          borderRadius: "25px",
          borderColor: "#a0a0a0",
          borderWidth: "1px",
          boxShadow: "1px 1px 2px #3a353b",
          paddingLeft: "10px",
          marginRight:'10px'
        }}
        placeholder="Search Here...."
        onChange={(e) => 
          searchKeyword(e.target.value.trim())
        }
      /> */}
        {showDate && (
          <div style={{ marginRight: "15px", float: "right",marginTop: "20px" }}>
            <Flatpickr
              className="form-control d-block"
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}

        {/* <div
        style={{
          float: "right",
          width: "150px",
          marginRight: "15px",
          marginBottom: "20px",
        }}
      >
        <Select
          isSearchable={false}
          onChange={(value) =>
            setVisitValue(value.label, value.value, dropDownOptions)
          }
          defaultValue={{ label: "All", value: 3 }}
          options={dropDownOptions}
        />
      </div> */}

        {/* After selecting collection need to show dropdown */}
        {/* {showCollectionValue && (<div
        style={{
          float: "right",
          width: "150px",
          marginRight: "20px",
          marginBottom: "20px",
        }}
      >
        <Select
          isSearchable={false}
          onChange={(value) =>
            setCollectionTypeValue(value)
          }
          defaultValue={{ label: "All", value: 1 }}
          options={collectionValues}
        />
      </div>)} */}
         <div
           style={{
             display: "flex",
             flexDirection: "column",
             alignItems: "left",
           }}
         >
           <label style={{ fontWeight: "bold" }}>
            Select Range
           </label>
        <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "20px",
            marginBottom: "10px",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) =>
              setDateValue(value.label, value.value, optionDates)
            }
            defaultValue={optionDates[0]}
            options={optionDates}
          />
        </div>
        </div>

        <div
          style={{
            //float: "right",
            //margin: "10px",
            marginTop: "20px",
            marginRight: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadExpenseData(0)}
          >
            Submit
          </Button>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={<p style={{
          color:'black'
        }}><div>
        <Button style={{backgroundColor:'#FFFF',color:'black', borderColor:'white',fontWeight:'bold', fontSize:'16px'}} variant="primary" disabled>
    <Spinner
      as="span"
      animation="border"
      size="md"
      variant='primary'
      role="status"
      aria-hidden="true"
    />
     &nbsp;&nbsp;Loading...
  </Button>
  </div></p>}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px", marginTop: "-5px" }}>
        <div style={{overflow:'auto', maxHeight:'400px'}}>
          <BootstrapTable
            keyField="panNo"
            data={expenseList}
            columns={columns}
          />
          </div>
          {expenseList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" show={basicModal} size="lg">
        <Modal.Header>
          <Modal.Title>Expense Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "20px" }}>
            <Row>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Expense Details</span>
                    <tr>
                      <SRLWrapper
                        options={options1}
                        style={{
                          height: "140px",
                          width: "140px",
                        }}
                      >
                        <img
                          style={{
                            height: "70px",
                            width: "80px",
                            cursor: "pointer",
                          }}
                          src={expenseDetailsList.images}
                          alt=""
                          className="img-fluid rounded-circle"
                        />
                      </SRLWrapper>
                    </tr>
                    <br></br>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.name
                          ? expenseDetailsList.name
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Expense Cost
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        <Row>
                          <Col>
                            {expenseDetailsList.expenseCost
                              ? expenseDetailsList.expenseCost
                              : "-"}{" "}
                          </Col>
                        </Row>
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Comments
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.comments
                          ? expenseDetailsList.comments
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Expense Type
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.expenseType
                          ? expenseDetailsList.expenseType
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          color:
                            expenseDetailsList.expenseStatus === "ACCEPTED"
                              ? "green"
                              : expenseDetailsList.expenseStatus === "REJECTED"
                              ? "red"
                              : "orange",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.expenseStatus
                          ? expenseDetailsList.expenseStatus
                          : "-"}
                      </td>
                    </tr>
                    {expenseDetailsList.expenseStatus === "PENDING" &&
                    expenseDetailsList?.managerApproved===true &&
                      hasAccess(19) && (
                        <div>
                          <Button
                            style={{
                              float: "left",
                              marginTop: "10px",
                              marginRight: "25px",
                              fontFamily:
                                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                            }}
                            className="me-2"
                            variant="success btn-rounded"
                            onClick={() =>
                              updateExpenseRequest(
                                expenseDetailsList,
                                "ACCEPTED"
                              )
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            style={{
                              float: "left",
                              marginTop: "10px",
                              marginRight: "15px",
                              fontFamily:
                                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                            }}
                            className="me-2"
                            variant="danger btn-rounded"
                            onClick={() =>
                              // updateExpenseRequest(expenseDetailsList, "REJECTED")
                              setBasicModal2(true)
                            }
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
              </Col>

              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Executive Details
                    </span>
                    {/* <tr>
                      <SRLWrapper
                      options={options1}
                      style={{
                        height: "140px",
                        width: "140px",
                      }}
                    >
                        <img
                            style={{
                              height: "70px",
                              width: "80px",
                              cursor: "pointer",

                            }}
                              src={expenseDetailsList.image}
                            alt=""
                            className="img-fluid rounded-circle"
                          />
                          </SRLWrapper>
                          </tr> */}
                    <br></br>
                    <br></br>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.name
                          ? expenseDetailsList.name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Employee ID
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.employeeId
                          ? expenseDetailsList.employeeId
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Mobile
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.mobileNumber
                          ? expenseDetailsList.mobileNumber
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Email
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {expenseDetailsList.email
                          ? expenseDetailsList.email
                          : "-"}
                      </td>
                    </tr>
                    <tr></tr>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal2} size="sm">
        <Modal.Header>
          <Modal.Title>Add Rejection Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal2(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
              <input
                style={{
                  width: "220px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter Reason"
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                  console.log("rejectionReason", rejectionReason);
                }}
              />
              <div style={{ marginTop: "5px" }}>
                <div>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2"
                    type="submit"
                    onClick={() =>
                      updateExpenseRequest(expenseDetailsList, "REJECTED")
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2 float-right"
                    variant="danger light"
                    onClick={() => setBasicModal2(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      {/* {toggle === "chatbox" && <VisitedPlaceDrawer expenseDetailsList={expenseDetailsList} closeDrawer={() => setToggle(false)} reLoad={() => loadExpenseData(currPage, searchKey)} />} */}
    </div>
  );
};

export default ExpenseDetails;
