import React, { useState, useCallback } from "react";
import { Form, Formik } from "formik";
import TextField from "../../CommonComponents/TextField";
import Select from "../../CommonComponents/SelectField";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import axios from "axios";
import { getTokenInLocalStorage } from "../../services/AuthService";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import { getFileNameForSignatureUpload } from "../utils";
import { LoadingOverlayComponent } from "../../CommonComponents/LoadingOverlay";

function ApprovalForm(props) {
  const {
    // getFiAllocationsOfEmployees,
    getFiAllocationsOfAgencies,
    setDetailedViewModal,
    type,
    selectedRow,
    allocationsDetails,
    setShowApprovalForm,
    loadData,
  } = props;
  const [signatureFile, setSignatureFile] = useState("");
  const [signatureFileName, setSignatureFileName] = useState("");
  const [excelFileError, setExcelFileError] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [isDedupeCheck, setIsDedupeCheck] = useState(null);
  const [checkIsDedupeCheck, setCheckIsDedupeCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    action: null,
  });

  const initialValues = {
    remarks: "",
    action: "",
  };

  const userLoginDetails = getTokenInLocalStorage();

  const getImageFromUrl = (url) => {
    fetch(url)
      .then((res) => res.blob())
      .then((myBlob) => {
        const myFile = new File(
          [myBlob],
          userLoginDetails?.autoFinanceOrganization?.orgshortcode +
            "_Signature.jpg",
          { type: myBlob.type }
        );
        // console.log("myFile", myFile);
        setSignatureFile(myFile);
        setSignatureFileName(
          userLoginDetails?.autoFinanceOrganization?.orgshortcode +
            "_FI_Approval_Signature.jpg"
        );
        // return myFile;
      });
  };

  const fileType = ["image/png", "image/jpeg"];

  const dedeupeCheckOnNegativeOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const dedupeResultOptions = [
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
  ];

  const fiResultOptions = [
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "Refer to Credit", value: "Refer to Credit" },
    { label: "Fraud", value: "Fraud" },
    // { label: "Door Locked", value: "Door Locked" },
  ];

  const validate = Yup.object({
    // remarkss: Yup.string().required("Remarks is required"),
  });

  const uploadFileToS3Bucket = (values) => {
    if (
      form.dedupeResult?.toLowerCase() === "negative" &&
      (values.negativeReason1 === null || values.negativeReason1 === undefined)
    ) {
      return toaster("error", "Please Enter Negative FI Reason 1");
    } else if (uploadType === null) {
      return toaster("error", "Please Select Upload Signature Type");
    }
    if (
      form.dedupeResult?.toLowerCase() === "negative" &&
      values.negativeReason1?.length > 255
    ) {
      return swal(
        "The Negative Reason 1 Provided is too long, Please enter less than 255 characters",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    } else if (
      form.dedupeResult?.toLowerCase() === "negative" &&
      values.negativeReason2?.length > 255
    ) {
      return swal(
        "The Negative Reason 2 Provided is too long, Please enter less than 255 characters",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }
    // setIsLoading(true);
    let uploadImagePayload = {
      fileKeyName: signatureFileName,
      type: "IMAGE",
    };
    console.log(signatureFile);
    console.log(signatureFileName);
    nodeApi
      .postData(apiCall.getImageUrl, uploadImagePayload)
      .then((response) => {
        const url = response.imageUrls.fileUploadURL;
        axios({
          method: "PUT",
          url: url,
          data: signatureFile ? signatureFile : getImageFromUrl(),
          headers: { "Content-Type": "image/png,image/jpeg" },
        })
          .then((res) => {
            if (res.config && res.config.url) {
              let filePath = res.config.url.split("?")[0];
              let fieldInvVisitId =
                allocationsDetails && allocationsDetails[0]
                  ? allocationsDetails[0].field_investigation_visits_id
                  : null;
              let requestPayload = {
                id: selectedRow.id,
                fieldInvestigationsVisitId: fieldInvVisitId,
                dedeupeCheckOnNegative: form.dedupeCheck,
                dedupeResult: form.dedupeResult,
                dedupeFiResult: form.fiResult,
                dedupeNegativeReasonOne:
                  form.dedupeResult?.toLowerCase() === "negative"
                    ? values.negativeReason1
                    : null,
                dedupeNegativeReasonTwo:
                  form.dedupeResult?.toLowerCase() === "negative"
                    ? values.negativeReason2
                    : null,
                dedupeRemarks: values.remarks,
                dedupeSignatureUrl: filePath,
                verifiersUserId: userLoginDetails?.autoFinanceUser.userId,
              };
              console.log(requestPayload);
              nodeApi
                .updateFiData(apiCall.approveRejectAllocation, requestPayload)
                .then((response) => {
                  if (response.success === true) {
                    type === "EMPLOYEES"
                      ? loadData()
                      : getFiAllocationsOfAgencies();
                      setCheckIsDedupeCheck(false)
                      setIsDedupeCheck(null)
                    // type==="EMPLOYEES"&& loadData()
                    // toaster("success", "Submitted successfully");
                    swal("Submitted Successfully!", {
                      icon: "success",
                    });
                    setDetailedViewModal(false);
                  } else {
                    swal("Something Went Wrong, Please Try Later", {
                      icon: "error",
                      dangerMode: true,
                    });
                    setDetailedViewModal(false);
                  }
                  setIsLoading(false);
                })
                .catch((error) => {
                  swal("Something Went Wrong, Please Try Later", {
                    icon: "error",
                    dangerMode: true,
                  });
                  setIsLoading(false);
                  setDetailedViewModal(false);
                });
            } else {
              setIsLoading(false);
              toaster("error", "Unable to upload image.Please try again later");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      });
  };

  const handleSubmit = (values) => {
    if (isDedupeCheck === null) {
      return setCheckIsDedupeCheck(true);
    } else if (isDedupeCheck === true && form.dedupeCheck == null) {
      setError({
        dedupeCheck: {
          errorMsg: "Please select Dedupe Check",
        },
      });
    } else if (isDedupeCheck === true && form.dedupeResult == null) {
      setError({
        dedupeResult: {
          errorMsg: "Please select Dedupe Result",
        },
      });
    } else if (form.fiResult == null) {
      setError({
        fiResult: {
          errorMsg: "Please select FI Result",
        },
      });
    } else {
      setError({
        dedupeCheck: {
          errorMsg: "",
        },
        dedupeResult: {
          errorMsg: "",
        },
        fiResult: {
          errorMsg: "",
        },
      });

      uploadFileToS3Bucket(values);
    }
  };

  const onValidate = (value, name) => {
    setError((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };

  const [error, setError] = useState({
    action: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
  });

  const onHandleChange = useCallback((value, name) => {
    if (name === "dedupeCheck" && value === null) {
      setError({
        dedupeCheck: {
          errorMsg: "Please select Dedupe Check",
        },
      });
    }
    if (name === "dedupeResult" && value === null) {
      setError({
        dedupeResult: {
          errorMsg: "Please select Dedupe Result",
        },
      });
    }
    if (name === "fiResult" && value === null) {
      setError({
        fiResult: {
          errorMsg: "Please select FI Result",
        },
      });
    } else {
      setError({
        dedupeCheck: {
          errorMsg: "",
        },
        dedupeResult: {
          errorMsg: "",
        },
        fiResult: {
          errorMsg: "",
        },
      });
    }
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleFile = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && fileType.includes(selectedFile.type)) {
      setSignatureFile(selectedFile);
      setSignatureFileName(getFileNameForSignatureUpload());
    } else {
      setExcelFileError("Please upload only jpeg/png images");
    }
  };

  return (
    <LoadingOverlayComponent showLoader={isLoading}>
      <div>
        <h4
          style={{ marginTop: "-40px", marginLeft: "15px", fontWeight: "bold" }}
        >
          FI Approval
        </h4>
        <div className="form">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validate}
          >
            {({ formik, errors, setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col>
                    <label>
                      <h7 style={{ fontWeight: "bold" }}>Dedupe Check</h7>
                    </label>
                    <br></br>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "5px",
                      }}
                    >
                      <div
                        // className="form-check"
                        onClick={() => {}}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          height: "40px",
                          width: "61px",
                          marginRight: "30px",
                        }}
                      >
                        <input
                          // className="form-check-input"
                          type="radio"
                          name="flexRadioDefault3"
                          id="flexRadioDefault3"
                          onChange={() => {
                            setIsDedupeCheck(true);
                            setCheckIsDedupeCheck(false);
                          }}
                          style={{
                            cursor: "pointer",
                            height: "15px",
                            width: "20px",
                          }}
                        />
                        <label
                          // className="form-check-label"
                          style={{
                            marginTop: "6px",
                            marginLeft: "5px",
                            fontSize: "larger",
                          }}
                          for="flexRadioDefault3"
                        >
                          Yes
                        </label>
                      </div>
                      <div
                        // className="form-check"
                        onClick={() => {}}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          height: "40px",
                          width: "45px",
                          // marginRight:'30px'
                        }}
                      >
                        <input
                          // className="form-check-input"
                          type="radio"
                          name="flexRadioDefault3"
                          id="flexRadioDefault3"
                          onChange={() => {
                            setIsDedupeCheck(false);
                            setCheckIsDedupeCheck(false);
                            const updatedJson = { ...form };
                            delete updatedJson.dedupeCheck;
                            delete updatedJson.dedupeResult;
                            setForm(updatedJson);
                          }}
                          style={{
                            cursor: "pointer",
                            height: "15px",
                            width: "20px",
                          }}
                        />
                        <label
                          // className="form-check-label"
                          style={{
                            marginTop: "6px",
                            marginLeft: "5px",
                            fontSize: "larger",
                          }}
                          for="flexRadioDefault3"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    {checkIsDedupeCheck && (
                      <span className="error">Please Select Dedupe Check</span>
                    )}
                  </Col>
                </Row>
                {isDedupeCheck === true && (
                  <Row>
                    <Col>
                      <label>
                        <h7 style={{ fontWeight: "bold" }}>
                          Dedupe Check conducted on Negative Database
                        </h7>
                      </label>
                      <Select
                        name="dedupeCheck"
                        value={form.dedupeCheck}
                        options={dedeupeCheckOnNegativeOptions}
                        onChangeFunc={onHandleChange}
                        {...error.dedupeCheck}
                      />
                    </Col>
                    <Col>
                      <label>
                        <h7 style={{ fontWeight: "bold" }}>Dedupe Result</h7>
                      </label>
                      <Select
                        name="dedupeResult"
                        value={form.dedupeResult}
                        options={dedupeResultOptions}
                        onChange={(value) => {
                          if (value.value !== "negative") {
                            setFieldValue("negativeReason1", "");
                            setFieldValue("negativeReason2", "");
                            values.negativeReason1 = "";
                            values.negativeReason2 = "";
                          }
                        }}
                        onChangeFunc={onHandleChange}
                        {...error.dedupeResult}
                      />
                    </Col>
                  </Row>
                )}
                {form.dedupeResult === "Negative" && isDedupeCheck === true && (
                  <Row>
                    <Col>
                      <TextField
                        name="negativeReason1"
                        isAsterisk={true}
                        type="text"
                        label="Negative FI Reason 1"
                        style={{ height: 40 }}
                        {...error.negativeReason1}
                      />
                    </Col>
                    <Col>
                      <TextField
                        name="negativeReason2"
                        type="text"
                        label="Negative FI Reason 2"
                        style={{ height: 40 }}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <h7 style={{ fontWeight: "bold" }}>FI Result</h7>
                    <Select
                      name="fiResult"
                      value={form.fiResult}
                      options={fiResultOptions}
                      onChangeFunc={onHandleChange}
                      {...error.fiResult}
                    />
                  </Col>
                  <Col>
                    <TextField
                      name="remarks"
                      type="text"
                      label="Remarks"
                      style={{ height: 40 }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>
                      <h7 style={{ fontWeight: "bold" }}>
                        Upload Signature Type
                      </h7>
                    </label>
                    <br></br>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        // className="form-check"
                        onClick={() => {}}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          height: "40px",
                          width: "61px",
                          marginRight: "30px",
                        }}
                      >
                        <input
                          // className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          onChange={() => setUploadType("manual")}
                          style={{
                            cursor: "pointer",
                            height: "15px",
                            width: "20px",
                          }}
                        />
                        <label
                          // className="form-check-label"
                          style={{
                            marginTop: "6px",
                            marginLeft: "5px",
                            fontSize: "larger",
                          }}
                          for="flexRadioDefault1"
                        >
                          Manual
                        </label>
                      </div>
                      <div
                        // className="form-check"
                        onClick={() => {}}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          height: "40px",
                          width: "45px",
                          // marginRight:'30px'
                        }}
                      >
                        <input
                          // className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          onChange={() => {
                            setSignatureFile(null);
                            getImageFromUrl(
                              userLoginDetails?.autoFinanceOrganization
                                ?.sealAndSignature
                            );
                            setUploadType("auto");
                          }}
                          style={{
                            cursor: "pointer",
                            height: "15px",
                            width: "20px",
                          }}
                        />
                        <label
                          // className="form-check-label"
                          style={{
                            marginTop: "6px",
                            marginLeft: "5px",
                            fontSize: "larger",
                          }}
                          for="flexRadioDefault1"
                        >
                          Auto
                        </label>
                      </div>
                    </div>
                  </Col>
                  {uploadType === "manual" && (
                    <Col>
                      <label>
                        <h7 style={{ fontWeight: "bold" }}>Upload Signature</h7>
                      </label>
                      <br></br>
                      <input
                        type="file"
                        className="form-control file-upload-field"
                        accept="image/png, image/gif, image/jpeg"
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        onChange={handleFile}
                        required
                      ></input>
                      {excelFileError && (
                        <div className="text-danger excel-file-error">
                          {excelFileError}
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    {/* <div style={{float:'right'}}>
                    <button
                      type="submit"
                      className="btn btn-success approval-btn"
                    >
                      Submit
                    </button>

                    <button
                      style={{ marginRight: 10, marginTop:'25px' }}
                      className="btn btn-danger"
                      onClick={() => setShowApprovalForm(false)}
                    >
                      Cancel
                    </button>
                  </div> */}
                    <Button
                      style={{ float: "right" }}
                      className="col-lg-2 m-2"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="col-lg-2 m-2 float-right"
                      variant="danger light"
                      onClick={() => setShowApprovalForm()}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </LoadingOverlayComponent>
  );
}

export default ApprovalForm;
