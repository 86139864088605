import React, { useState } from "react";
import { Button, Nav, Spinner, Tab } from "react-bootstrap";
import CustomerMgmt2 from "./CustomerMgmt2";
import { getTokenInLocalStorage } from "../services/AuthService";
import LoadingOverlay from "react-loading-overlay";

const MainCustMgmt = () => {
  const [isLoading, setIsLoading] = useState(false);

  let userDetails = getTokenInLocalStorage();

const getLoadingOn =()=>{
  setIsLoading(true)
}
const getLoadingOff =()=>{
  setIsLoading(false)
}

  return (
    <div className="card" style={{ padding: "10px" }}>
      {userDetails?.autoFinanceOrganization?.orgshortcode?.toLowerCase()==='cll'&&
      <div
        style={{
          marginRight: "20px",
          marginBottom: "10px",
          float: "right",
        }}
      >
        <Button
          className=""
          variant="primary btn-rounded"
          style={{
            borderRadius: "5px",
            float: "right",
          }}
          onClick={() => {
            window.open(
              "https://paymentsqaapp.clucloud.com/?orgId=" +
                userDetails?.autoFinanceOrganization?.orgshortcode
            );
          }}
        >
          <i className="fas fa-external-link-alt" />
          &nbsp; Request Payment
        </Button>
      </div>}
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="collections">
          <Nav as="ul" className="nav-tabs-custom nav-justified">
            <Nav.Item as="li" key="0">
              <Nav.Link eventKey="sales">
                <b> Sales </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="1">
              <Nav.Link eventKey="collections">
                <b> Collections </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="2">
              <Nav.Link eventKey="fieldInvestigation">
                <b> Field Investigations </b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="sales" key="0" mountOnEnter unmountOnExit>
              <CustomerMgmt2 visitType={"Sales"} open={getLoadingOn} close={getLoadingOff} />
            </Tab.Pane>

            <Tab.Pane eventKey="collections" key="1" mountOnEnter unmountOnExit>
              <CustomerMgmt2 visitType={"Collections"} open={getLoadingOn} close={getLoadingOff} />
            </Tab.Pane>
            <Tab.Pane
              eventKey="fieldInvestigation"
              key="2"
              mountOnEnter
              unmountOnExit
            >
              <CustomerMgmt2 visitType={"FieldInvestigation"} open={getLoadingOn} close={getLoadingOff}/>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      </LoadingOverlay>
    </div>
  );
};

export default MainCustMgmt;
