import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Form, Formik } from "formik";
import swal from "sweetalert";
import * as Yup from "yup";
import Select from "react-select";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import SearchBar from "../CommonComponents/SearchBar";
import TextField from "../CommonComponents/TextField";
import { EpochToOnlyDate, formatDate } from "../CommonComponents/Helper";
import AddVehicleServiceData from "./AddVehicleServiceData";
import VehicleServiceCallSummary from "./VehicleServiceCallSummary";

function VehicleServiceData() {
  // var history = useHistory();
  const [vehicleServiceList, setVehicleServiceList] = useState([]);
  const [vehicleServiceDetails, setVehicleServiceDetails] = useState(null);
  const [addVehicleServiceDataModal, setAddVehicleServiceDataModal] =
    useState(false);
  const [vehicleServiceTypes, setVehicleServiceTypes] = useState(null);
  const [vehicleSubServiceTypes, setVehicleSubServiceTypes] = useState(null);
  const [vehicleServiceCallStatuses, setVehicleServiceCallStatuses] =
    useState(null);
  const [vehicleAppointmentStatuses, setVehicleAppointmentStatuses] =
    useState(null);
  const [vehicleServiceGatePasses, setVehicleServiceGatePasses] =
    useState(null);
  const [vehicleServiceStatuses, setVehicleServiceStatuses] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    serviceType: vehicleServiceDetails?.serviceType,
    subServiceType: vehicleServiceDetails?.subServiceType,
    callStatus: vehicleServiceDetails?.callStatus,
    appointmentStatus: vehicleServiceDetails?.appointmentStatus,
    gatePass: vehicleServiceDetails?.gatePass,
    serviceStatus:vehicleServiceDetails?.serviceStatus
  });
//   const [dropdownErrors, setDropdownErrors] = useState({
//     serviceType: false,
//     subServiceType: false,
//     callStatus: false,
//     appointmentStatus: false,
//     gatePass: false,
//   });
  const [addCpUserModal, setAddCpUserModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [callReportModal, setCallReportModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let listData = [];
  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData(0, start, end, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vehicleServiceDetails) {
      selectedStatus.serviceType = vehicleServiceDetails?.serviceType;
      selectedStatus.subServiceType = vehicleServiceDetails?.subServiceType;
      selectedStatus.callStatus = vehicleServiceDetails?.callStatus;
      selectedStatus.appointmentStatus =
        vehicleServiceDetails?.appointmentStatus;
      selectedStatus.gatePass = vehicleServiceDetails?.gatePass;
      selectedStatus.serviceStatus = vehicleServiceDetails?.serviceStatus;
      setSelectedStatus({ ...selectedStatus });
    }
    // eslint-disable-next-line
  }, [vehicleServiceDetails]);

  const loadData = (pageNo, start, end, key) => {
    setIsLoading(true);
    let body = {
      endTime: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      startTime: start,
      pagenation: true,
      searchKey: key,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getVehicleServiceData, pageNo, 10),
        body
      )
      .then((response) => {
        console.log(response);
        response?.content?.forEach((element) => {
          let data = {
            appointmentDate: element.appointmentDate
              ? EpochToOnlyDate(element.appointmentDate)
              : "-",
            appointmentStatus: element.appointmentStatus
              ? element.appointmentStatus
              : "-",
            appointmnetSlot: element.appointmnetSlot
              ? element.appointmnetSlot
              : "-",
            callStatus: element.callStatus ? element.callStatus : "-",
            createdAt: element.createdAt ? element.createdAt : "-",
            gatePass: element.gatePass ? element.gatePass : "-",
            id: element.id ? element.id : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            remarks: element.remarks ? element.remarks : "-",
            serviceStatus: element.serviceStatus ? element.serviceStatus : "-",
            serviceType: element.serviceType ? element.serviceType : "-",
            subServiceType: element.subServiceType
              ? element.subServiceType
              : "-",
            toBeServiced: element.toBeServiced
              ? EpochToOnlyDate(element.toBeServiced)
              : "-",
            updatedAt: element.updatedAt ? element.updatedAt : "-",
            userId: element.userId ? element.userId : "-",
            vehicleSaleData: element.vehicleSaleData,
            branch: element?.vehicleSaleData?.branch
              ? element?.vehicleSaleData?.branch
              : "-",
            chasisNo: element?.vehicleSaleData?.chasisNo
              ? element?.vehicleSaleData?.chasisNo
              : "-",
            cpName: element?.vehicleSaleData?.cpName
              ? element?.vehicleSaleData?.cpName
              : "-",
            customerName: element?.vehicleSaleData?.customerName
              ? element?.vehicleSaleData?.customerName
              : "-",
            customerPhoneNumber: element?.vehicleSaleData?.customerPhoneNumber
              ? element?.vehicleSaleData?.customerPhoneNumber
              : "-",
            dateOfSale: element?.vehicleSaleData?.dateOfSale
              ? EpochToOnlyDate(element?.vehicleSaleData?.dateOfSale)
              : "-",
            engineNo: element?.vehicleSaleData?.engineNo
              ? element?.vehicleSaleData?.engineNo
              : "-",
            financier: element?.vehicleSaleData?.financier
              ? element?.vehicleSaleData?.financier
              : "-",
            // id: element?.vehicleSaleData?.id?element?.vehicleSaleData?.id:'-',
            slNo: element?.vehicleSaleData?.slNo
              ? element?.vehicleSaleData?.slNo
              : "-",
            vehicleDescription: element?.vehicleSaleData?.vehicleDescription
              ? element?.vehicleSaleData?.vehicleDescription
              : "-",
            vehicleNumber: element?.vehicleSaleData?.vehicleNumber
              ? element?.vehicleSaleData?.vehicleNumber
              : "-",
          };
          listData.push(data);
        });
        setVehicleServiceList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  //   const handleClose = () => {
  //     setAddCpUserModal(false);
  //     loadData(0, searchKey);
  //   };

  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    {
      text: "SL No",
      dataField: "slNo",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branch",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile</span>
        </div>
      ),
      dataField: "customerPhoneNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.customerPhoneNumber) {
          return (
            <div style={{ display: "flex", justifyContent: "center", verticalAlign:'middle' }}>
              <p
                title={row.customerPhoneNumber}
                style={{
                  cursor: "pointer",
                  verticalAlign: "middle",
                  marginRight: "5px",
                }}
              >
                {row.customerPhoneNumber}
              </p>
              {/* {hasAccess(42) && ( */}
              <Link
                to="#"
                className="btn btn-success shadow btn-xs sharp"
                onClick={() => {
                  makeCall(row?.customerPhoneNumber);
                }}
              >
                <i className="fa fa-phone fa-1x fa-rotate-90" />
              </Link>
              {/* )} */}
            </div>
          );
        } else {
          return <>-</>;
        }
      },
    },

    {
      text: "Engine No",
      dataField: "engineNo",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Chassis No",
      dataField: "chasisNo",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Date of Sale",
      dataField: "dateOfSale",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Service Type",
      dataField: "serviceType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Sub-Service</span>
          <br></br>
          <span>Type</span>
        </div>
      ),
      dataField: "subServiceType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "TR/Reg No",
      dataField: "vehicleNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Call Status",
      dataField: "callStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Appointment</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "appointmentDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Appointment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "appointmentStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Service</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "serviceStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Gate</span>
          <br></br>
          <span>Pass</span>
        </div>
      ),
      dataField: "gatePass",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>To Be</span>
          <br></br>
          <span>Serviced</span>
        </div>
      ),
      dataField: "toBeServiced",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Remarks",
      dataField: "remarks",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Call</span>
          <br></br>
          <span>History</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex" style={{ justifyContent: "space-around" }}>
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("row", row);
          setVehicleServiceDetails(row);
          setCallReportModal(true);
        },
      },
    },

    {
      text: "Edit",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex" style={{ justifyContent: "space-around" }}>
              <Link to="#" className="btn btn-primary shadow btn-xs sharp">
                <i className="fa fa-edit" />
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("row", row);
          getVehicleServiceTypes();
          getVehicleServiceCallStatus();
          getVehicleServiceStatus();
          getVehicleAppointmentStatus();
          getVehicleServiceGatePass();
          setVehicleServiceDetails(row);
          setEditModal(true);
        },
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(selectedPage - 1, start, end, searchKey);
  };

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0].getFullYear(),
            value[0].getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86400);
    //   let start = ;
    // let end = Math.floor(new Date(dateStrings[1]).getTime() / 1000 + 86400);

    setStart(start);
    setEnd(end);
    loadData(0, start, end, searchKey);
  };

  const handleClose = () => {
    setAddVehicleServiceDataModal(false);
    loadData(0, start, end, searchKey);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadData(0, start, end, key);
  };

  const getVehicleServiceTypes = () => {
    moduleApi
      .postData(
        apiCall.getVehicleServiceTypes +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.serviceType,
          label: item.serviceType,
        }));
        const result2 = response?.map((item) => ({
          value: item.subServiceType,
          label: item.subServiceType,
        }));

        setVehicleServiceTypes(result);
        setVehicleSubServiceTypes(result2);
      });
  };

  const getVehicleServiceCallStatus = () => {
    moduleApi
      .postData(
        apiCall.getVehicleServiceCallStatus +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.callStatus,
          label: item.callStatus,
        }));

        setVehicleServiceCallStatuses(result);
      });
  };

  const getVehicleAppointmentStatus = () => {
    moduleApi
      .postData(
        apiCall.getVehicleAppointmentStatus +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.appointmentStatus,
          label: item.appointmentStatus,
        }));

        setVehicleAppointmentStatuses(result);
      });
  };

  const getVehicleServiceStatus = () => {
    moduleApi
      .postData(
        apiCall.getVehicleServiceStatuses +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.serviceStatus,
          label: item.serviceStatus,
        }));

        setVehicleServiceStatuses(result);
      });
  };

  const getVehicleServiceGatePass = () => {
    moduleApi
      .postData(
        apiCall.getVehicleServiceCallGatePass +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.gatePass,
          label: item.gatePass,
        }));

        setVehicleServiceGatePasses(result);
      });
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validate = Yup.object({
    slNo: Yup.string().trim().required("SL No is required"),
    branch: Yup.string().trim().required("Branch is required"),
    customerName: Yup.string().trim().required("Customer Name is required"),
    customerPhoneNumber: Yup.string()
      .trim()
      .matches(phoneRegExp, "Customer Mobile is not valid")
      .min(10, "Customer Mobile should be 10 digits")
      .max(10, "Customer Mobile should be 10 digits")
      .required("Customer Mobile is required"),
    engineNo: Yup.string().trim().required("Engine No is required"),
    chasisNo: Yup.string().trim().required("Chassis No is required"),
    dateOfSale: Yup.date().required("Date Of Sale is required"),
    // serviceType: Yup.string().required("Service Type is required"),
    // subServiceType: Yup.string().required("Sub-Service Type is required"),
    // callStatus: Yup.string().required("Call Status is required"),
    appointmentDate: Yup.date().required("Appointment Date is required"),
    // appointmentStatus: Yup.string().required("Appointment Status is required"),
    // serviceStatus: Yup.string().required("Service Status is required"),
    // gatePass: Yup.string().required("Gate Pass is required"),
    toBeServiced: Yup.date().required("To Be Serviced is required"),
    remarks: Yup.string().trim().required("Remarks is required"),
  });

  const onSubmit = (values) => {
    console.log('vehikx', selectedStatus);
    // if (
    //   selectedStatus.serviceType === "-" ||
    //   selectedStatus.serviceType === undefined
    // ) {
    //   dropdownErrors.serviceType = true;
    //   return setDropdownErrors({ ...dropdownErrors });
    // } else if (
    //   selectedStatus.subServiceType === "-" ||
    //   selectedStatus.subServiceType === undefined
    // ) {
    //   dropdownErrors.subServiceType = true;
    //   return setDropdownErrors({ ...dropdownErrors });
    // } else if (
    //   selectedStatus.callStatus === "-" ||
    //   selectedStatus.callStatus === undefined
    // ) {
    //   dropdownErrors.callStatus = true;
    //   return setDropdownErrors({ ...dropdownErrors });
    // } else if (
    //   selectedStatus.appointmentStatus === "-" ||
    //   selectedStatus.appointmentStatus === undefined
    // ) {
    //   dropdownErrors.appointmentStatus = true;
    //   return setDropdownErrors({ ...dropdownErrors });
    // } else if (
    //   selectedStatus.serviceStatus === "-" ||
    //   selectedStatus.serviceStatus === undefined
    // ) {
    //   dropdownErrors.serviceStatus = true;
    //   return setDropdownErrors({ ...dropdownErrors });
    // } else if (
    //   selectedStatus.gatePass === "-" ||
    //   selectedStatus.gatePass === undefined
    // ) {
    //   dropdownErrors.gatePass = true;
    //   return setDropdownErrors({ ...dropdownErrors });
    // }

    const params = {
      appointmentDate: Math.floor(
        new Date(values.appointmentDate).getTime() / 1000.0
      ),
      appointmentStatus: selectedStatus.appointmentStatus,
      callStatus: selectedStatus.callStatus,
      gatePass: selectedStatus.gatePass,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      remarks: values.remarks,
      id: vehicleServiceDetails?.id,
      serviceStatus: selectedStatus.serviceStatus,
      serviceType: selectedStatus.serviceType,
      subServiceType: selectedStatus.subServiceType,
      toBeServiced: Math.floor(
        new Date(values.toBeServiced).getTime() / 1000.0
      ),
      userId: userLoginDetails?.autoFinanceUser?.userId,
    };

    console.log("params", params);
    moduleApi
      .postData(apiCall.updateVehicleServiceData, params)
      .then((response) => {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setEditModal(false);

          swal("Successfully Updated", {
            icon: "success",
          }).then((willOk) => {
            if (willOk) {
              loadData(0, start, end);
            }
          });
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const updateSelectedOption = (name, value) => {
    selectedStatus[name] = value;
    setSelectedStatus({ ...selectedStatus });
    // dropdownErrors[name] = false;
    // setDropdownErrors({ ...dropdownErrors });
  };

  function makeCall(mobileNumber) {
    var payload = {
      customerPhoneNumber: "91" + mobileNumber,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      userId: userLoginDetails?.autoFinanceUser?.userId,
    };

    moduleApi
      .postData(apiCall.makeCallInVehicleService, payload)
      .then((response) => {
        if (response) {
          if (response?.msg === "Call initiated") {
            return swal("Call Initiated Successfully", {
              icon: "success",
              dangerMode: false,
            });
          } else {
            return swal(response?.msg, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  }

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        {/* <input
          style={{
            width: "300px",
            height: "40px",
            borderRadius: "25px",
            borderColor: "#a0a0a0",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #3a353b",
            paddingLeft: "10px",
          }}
          placeholder="Search By Name/Branch/Mobile"
          onChange={(e) => searchKeyword(e.target.value.trim())}
        /> */}
        <SearchBar
          placeholder="Search By Engine No/Chassis No/Vehicle No"
          searchKeyword={searchKeyword}
        />
        <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "10px",
            height: "38px",
            marginLeft: "10px",
            borderRadius: "3px",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #797979",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) => {
              value.value === "cm"
                ? setIsDateFilter(false)
                : setIsDateFilter(true);
            }}
            defaultValue={dateOptions[0]}
            options={dateOptions}
          />
        </div>
        {!isDateFilter && (
          <div
            style={{
              float: "right",
              // marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <Flatpickr
              id="customonthPicker"
              className="form-control d-block"
              style={{
                width: "150px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value, true);
              }}
              options={{
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          </div>
        )}
        {isDateFilter && (
          <div>
            <Flatpickr
              style={{
                width: "150px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              id="customDatePicker"
              className="form-control d-block"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDatesForGraph(value, false);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}

        <Button
          style={{
            float: "right",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => setAddVehicleServiceDataModal(true)}
        >
          <b> + Add Vehicle Service </b>
        </Button>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={vehicleServiceList}
              columns={columns}
            />
          </div>
          {vehicleServiceList?.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" show={addCpUserModal}>
        <Modal.Header>
          <Modal.Title>Add CP User</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddCpUserModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>{/* <AddCpUser close={handleClose} /> */}</Modal.Body>
      </Modal>

      <Modal className="fade" show={editModal}>
        <Modal.Header>
          <Modal.Title>Edit Vehicle Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setEditModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            <Formik
              initialValues={{
                slNo: vehicleServiceDetails?.slNo,
                branch: vehicleServiceDetails?.branch,
                customerName: vehicleServiceDetails?.customerName,
                customerPhoneNumber: vehicleServiceDetails?.customerPhoneNumber,
                engineNo: vehicleServiceDetails?.engineNo,
                chasisNo: vehicleServiceDetails?.chasisNo,
                dateOfSale:
                  vehicleServiceDetails?.dateOfSale !== "-"
                    ? formatDate(vehicleServiceDetails?.dateOfSale)
                    : "",
                appointmentDate:
                  vehicleServiceDetails?.appointmentDate !== "-"
                    ? formatDate(vehicleServiceDetails?.appointmentDate)
                    : "",
                toBeServiced:
                  vehicleServiceDetails?.toBeServiced !== "-"
                    ? formatDate(vehicleServiceDetails?.toBeServiced)
                    : "",
                remarks:
                  vehicleServiceDetails?.remarks !== "-"
                    ? vehicleServiceDetails?.remarks
                    : "",
              }}
              onSubmit={onSubmit}
              validationSchema={validate}
              enableReinitialize
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <TextField
                          isAsterisk={true}
                          label="SL No"
                          name="slNo"
                          type="text"
                          disabled
                        />
                        <TextField
                          isAsterisk={true}
                          label="Branch"
                          name="branch"
                          type="text"
                          disabled
                        />
                        <TextField
                          isAsterisk={true}
                          label="Customer Name"
                          name="customerName"
                          type="text"
                          disabled
                        />
                        <TextField
                          isAsterisk={true}
                          label="Customer Mobile"
                          name="customerPhoneNumber"
                          type="text"
                          disabled
                        />
                        <TextField
                          isAsterisk={true}
                          label="Engine No"
                          name="engineNo"
                          type="text"
                          disabled
                        />
                        <TextField
                          isAsterisk={true}
                          label="Chassis No"
                          name="chasisNo"
                          type="text"
                          disabled
                        />
                        <TextField
                          isAsterisk={true}
                          label="Date Of Sale"
                          name="dateOfSale"
                          type="date"
                          disabled
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Service Type
                          </label>
                          <Select
                            placeholder="Select Service Type"
                            classNamePrefix="select-box"
                            options={vehicleServiceTypes}
                            defaultValue={
                              vehicleServiceDetails?.serviceType !== "-" &&
                              vehicleServiceDetails?.serviceType !== undefined &&
                              vehicleServiceDetails?.serviceType !== null
                                ? {
                                    label: vehicleServiceDetails?.serviceType,
                                    value: vehicleServiceDetails?.serviceType,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              updateSelectedOption(
                                "serviceType",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                        {/* {dropdownErrors.serviceType === true && (
                          <span className="error">
                            Service Type is required
                          </span>
                        )} */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Sub-Service Type
                          </label>
                          <Select
                            placeholder="Select Sub-Service Type"
                            classNamePrefix="select-box"
                            options={vehicleSubServiceTypes}
                            defaultValue={
                                vehicleServiceDetails?.subServiceType !== "-" &&
                                vehicleServiceDetails?.subServiceType !== undefined &&
                                vehicleServiceDetails?.subServiceType !== null
                                ? {
                                    label: vehicleServiceDetails?.subServiceType,
                                    value: vehicleServiceDetails?.subServiceType,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              updateSelectedOption(
                                "subServiceType",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                        {/* {dropdownErrors.subServiceType === true && (
                          <span className="error">
                            Sub-Service Type is required
                          </span>
                        )} */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Call Status
                          </label>
                          <Select
                            placeholder="Select Call Status"
                            classNamePrefix="select-box"
                            options={vehicleServiceCallStatuses}
                            defaultValue={
                                vehicleServiceDetails?.callStatus !== "-" &&
                                vehicleServiceDetails?.callStatus !== undefined
                                ? {
                                    label: vehicleServiceDetails?.callStatus,
                                    value: vehicleServiceDetails?.callStatus,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              updateSelectedOption(
                                "callStatus",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                        {/* {dropdownErrors.callStatus === true && (
                          <span className="error">Call Status is required</span>
                        )} */}

                        <TextField
                          isAsterisk={true}
                          label="Appointment Date"
                          name="appointmentDate"
                          type="date"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Appointment Status
                          </label>
                          <Select
                            placeholder="Select Appointment Status"
                            classNamePrefix="select-box"
                            options={vehicleAppointmentStatuses}
                            defaultValue={
                                vehicleServiceDetails?.appointmentStatus !== "-" &&
                                vehicleServiceDetails?.appointmentStatus !== undefined &&
                                vehicleServiceDetails?.appointmentStatus !== null
                                ? {
                                    label: vehicleServiceDetails?.appointmentStatus,
                                    value: vehicleServiceDetails?.appointmentStatus,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              updateSelectedOption(
                                "appointmentStatus",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                        {/* {dropdownErrors.appointmentStatus === true && (
                          <span className="error">
                            Appointment Status is required
                          </span>
                        )} */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Service Status
                          </label>
                          <Select
                            placeholder="Select Service Status"
                            classNamePrefix="select-box"
                            options={vehicleServiceStatuses}
                            defaultValue={
                              vehicleServiceDetails?.serviceStatus !== "-" &&
                              vehicleServiceDetails?.serviceStatus !== undefined &&
                              vehicleServiceDetails?.serviceStatus !== null
                                ? {
                                    label: vehicleServiceDetails?.serviceStatus,
                                    value: vehicleServiceDetails?.serviceStatus,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              updateSelectedOption(
                                "serviceStatus",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                        {/* {dropdownErrors.serviceStatus === true && (
                          <span className="error">
                            Service Status is required
                          </span>
                        )} */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Gate Pass
                          </label>
                          {console.log(vehicleServiceDetails?.gatePass)}
                          <Select
                            placeholder="Select Gate Pass"
                            classNamePrefix="select-box"
                            options={vehicleServiceGatePasses}
                            defaultValue={
                              vehicleServiceDetails?.gatePass !== "-" &&
                              vehicleServiceDetails?.gatePass !== undefined &&
                              vehicleServiceDetails?.gatePass !== null
                                ? {
                                    label: vehicleServiceDetails?.gatePass,
                                    value: vehicleServiceDetails?.gatePass,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              updateSelectedOption(
                                "gatePass",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                        {/* {dropdownErrors.gatePass === true && (
                          <span className="error">Gate Pass is required</span>
                        )} */}
                        <TextField
                          isAsterisk={true}
                          label="To Be Serviced"
                          name="toBeServiced"
                          type="date"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Remarks"
                          name="remarks"
                          type="text"
                        />
                        <div style={{ marginTop: "10px" }}>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setEditModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={addVehicleServiceDataModal}>
        <Modal.Header>
          <Modal.Title>Add Vehicle Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddVehicleServiceDataModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AddVehicleServiceData close={handleClose} />
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={callReportModal} size="lg">
        <Modal.Header>
          <Modal.Title>Call History</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setCallReportModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VehicleServiceCallSummary
            mobileNo={vehicleServiceDetails?.customerPhoneNumber}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VehicleServiceData;
