import React, { useEffect, useState } from "react";
// import SearchBar from "../CommonComponents/SearchBar";
import {
  Button,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
// import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import ReactGA from "react-ga";
import Select from "react-select";
// import swal from "sweetalert";
import { Link } from "react-router-dom";
import { getTokenInLocalStorage, hasAccess } from "../services/AuthService";
// import SalesUpload from "./SalesUpload";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
// import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import AddOpportunity from "./AddOpportunity";
import swal from "sweetalert";
// import { EpochToDate } from "../CommonComponents/Helper";
// import Flatpickr from "react-flatpickr";
// import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
// import "flatpickr/dist/plugins/monthSelect/style.css";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import { StartCall } from "../CommonComponents/TelecallingMethods";
// import PIOPIY from "piopiyjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallAudioModal,
  setIsModalMinimised,
} from "../store/actions/CallingActions";
// import AddAdditionalDetails from "./AddAdditionalDetails";

const SalesLeads = () => {
  const [salesLeadsList, setSalesLeadsList] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [leadDetails, setLeadDetails] = useState(null);
  const [addOpportunityModal, setAddOpportunityModal] = useState(false);
  const [addAdditionalDetailsModal, setAddAdditionalDetailsModal] = useState(false);
  const [editLeadModal, setEditLeadModal] = useState(false);
  const [leadHistory, setLeadHistory] = useState([]);
  const [leadHistoryModal, setLeadHistoryModal] = useState(false);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(null);
  const [leadStatuses, setLeadStatuses] = useState([]);
  //   const [searchKey, setSearchKey] = useState("");
  const [leadsDownloadList, setLeadsDownloadList] = useState(null);
  //   const [start, setStart] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
  //       1000
  //   );
  //   const [end, setEnd] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
  //       1000 +
  //       86399
  //   );
  let userLoginDetails = getTokenInLocalStorage();
  const dispatch = useDispatch();
  const {  piopiy,
    isCallActive,
    isIncomingCall,isOnGoingCall, isProgressiveDialing } = useSelector(
    (state) => state.calls
  );

  useEffect(() => {
    ReactGA.pageview("Sales Leads");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    leadsDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadsDownloadList]);

  useEffect(() => {
    // loadSalesData(0, searchKey, start, end);
    loadSalesData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSalesData = (pageNo, searchKey, start, end) => {
    var listData = [];
    setIsLoading(true);
    var payload = {
      //   starttime: start,
      //   endtime: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      //   searchKey: searchKey,
      //   reportCategory: "DETAILED",
      //   reportingOfficerId: "",
      //   reportType: "SalesAllocationSummary",
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.getSalesLeads, pageNo, 10), payload)
      .then((response) => {
        setCurrPage(pageNo);
        console.log("response", response);
        response?.content?.forEach((element) => {
          let data = {
            channel: element.channel ? element.channel : "-",
            companyName: element.companyName ? element.companyName : "-",
            email: element.email ? element.email : "-",
            city: element.city ? element.city : "-",
            state: element.state ? element.state : "-",
            pinCode: element.pinCode ? element.pinCode : "-",
            firstName: element.firstName ? element.firstName : "-",
            customerName: element.customerName ? element.customerName : "-",
            id: element.id ? element.id : "-",
            lastName: element.lastName ? element.lastName : "-",
            leadChannelId: element.leadChannelId ? element.leadChannelId : "-",
            leadRate: element.leadRate ? getPriority(element.leadRate) : "-",
            leadStatus: element.leadStatus ? element.leadStatus : "-",
            leadStatusId: element.leadStatusId ? element.leadStatusId : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
          };
          listData.push(data);
        });
        setSalesLeadsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    // loadSalesData(selectedPage - 1, searchKey, start, end);
    loadSalesData(selectedPage - 1);
  };

  // const handleAddAdditionalDetailsModal=()=>{
  //   loadSalesData(currPage);
  //   setAddAdditionalDetailsModal(false)
  // }

  const getPriority = (value) => {
    if (value === 1) {
      return "Hot";
    } else if (value === 2) {
      return "Warm";
    } else {
      return "Cold";
    }
  };

  const salesLeadsColumns = [
    {
      text: "Channel",
      dataField: "channel",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Prospect Name",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Last Name",
    //   dataField: "lastName",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },

    {
      text: "Mobile Number",
      dataField: "phoneNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.phoneNumber) {
          return callOptionTemplate(row);
        } else {
          return <>-</>;
        }
      },
    },

    // {
    //   text: 'Id',
    //   dataField: 'id',
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerStyle: {
    //     backgroundColor: 'rgb(243,240,249)',
    //     fontWeight: 'bold',
    //     verticalAlign: 'middle',
    //     fontFamily: '-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji'
    //   },
    //   style: {
    //     borderColor: 'white',
    //     fontFamily: '-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji'
    //   }
    // },

    // {
    //   text: 'Lead Channel Id',
    //   dataField: 'leadChannelId',
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerStyle: {
    //     backgroundColor: 'rgb(243,240,249)',
    //     fontWeight: 'bold',
    //     verticalAlign: 'middle',
    //     fontFamily: '-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji'
    //   },
    //   style: {
    //     borderColor: 'white',
    //     fontFamily: '-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji'
    //   }
    // },
    {
      text: "Priority",
      dataField: "leadRate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.leadRate !== "-") {
          return (
            <span
              className={getLeadTypeClassName(row.leadRate)}
              style={{ verticalAlign: "middle" }}
            >
              {row.leadRate}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Status",
      dataField: "leadStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.leadStatus !== "-") {
          return (
            <span
              className={getLeadStatusClassName(row.leadStatus)}
              style={{ verticalAlign: "middle" }}
            >
              {row.leadStatus}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Email",
      dataField: "email",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "City",
      dataField: "city",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "State",
      dataField: "state",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Pin Code",
      dataField: "pinCode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    // {
    //   text: "Company Name",
    //   dataField: "companyName",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "",
      dataField: "addAdditionalDetails",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Add Additional Details</Tooltip>}
            >
              <div className="d-flex">

                <Button
                  style={{
                    float: "right",
                    height: "30px",
                    width: "30px",
                    padding: "7px",
                    borderColor: "primary",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="outline-primary btn-rounded"
                  onClick={() => {
                    setLeadDetails(row);
                    setAddAdditionalDetailsModal(true);
                  }}
                >
                  <i class="fa fa-plus" style={{ fontSize: "small" }}></i>
                </Button>
              </div>
            </OverlayTrigger>
          );
        }
      },
    },
    
    {
      text: "",
      dataField: "leadHistory",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Lead History</Tooltip>}
            >
              <div className="d-flex">
                {/* <ButtonComponent
                style={{ height: "30px", width: "30px" }}
                cssClass="e-flat e-round e-outline"
                isPrimary
                onClick={() => {
                  setLeadDetails(row)
                  setEditLeadModal(true);
                }}
              >
                <i class="fa fa-edit"></i>
              </ButtonComponent> */}
                <Button
                  style={{
                    float: "right",
                    height: "30px",
                    width: "30px",
                    padding: "7px",
                    borderColor: "primary",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="outline-primary btn-rounded"
                  onClick={() => {
                    setLeadDetails(row);
                    getLeadHistory(row)
                    setLeadHistoryModal(true);
                  }}
                >
                  <i class="fa fa-eye" style={{ fontSize: "small" }}></i>
                </Button>
              </div>
            </OverlayTrigger>
          );
        }
      },
    },
    {
      text: "",
      dataField: "edit",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Edit Lead Status</Tooltip>}
            >
              <div className="d-flex">
                {/* <ButtonComponent
                style={{ height: "30px", width: "30px" }}
                cssClass="e-flat e-round e-outline"
                isPrimary
                onClick={() => {
                  setLeadDetails(row)
                  setEditLeadModal(true);
                }}
              >
                <i class="fa fa-edit"></i>
              </ButtonComponent> */}
                <Button
                  style={{
                    float: "right",
                    height: "30px",
                    width: "30px",
                    padding: "7px",
                    borderColor: "primary",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="outline-primary btn-rounded"
                  onClick={() => {
                    setLeadDetails(row);
                    getLeadStatuses();
                    setEditLeadModal(true);
                  }}
                >
                  <i class="fa fa-edit" style={{ fontSize: "small" }}></i>
                </Button>
              </div>
            </OverlayTrigger>
          );
        }
      },
    },
    {
      text: "",
      dataField: "addOpportunity",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Add Opportunity</Tooltip>}
            >
              <div className="d-flex">
                {/* <ButtonComponent
                style={{ height: "30px", width: "30px" }}
                cssClass="e-flat e-round e-outline"
                isPrimary
                onClick={() => {
                  setLeadDetails(row)
                  setAddOpportunityModal(true);
                }}
              >
                <i class="fa fa-plus"></i>
              </ButtonComponent> */}
                <Button
                  style={{
                    float: "right",
                    height: "30px",
                    width: "30px",
                    padding: "7px",
                    borderColor: "primary",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="primary btn-rounded"
                  onClick={() => {
                    setLeadDetails(row);
                    setAddOpportunityModal(true);
                  }}
                >
                  <i class="fa fa-plus" style={{ fontSize: "small" }}></i>
                </Button>
              </div>
            </OverlayTrigger>
          );
        }
      },
    },
  ];

  const getLeadTypeClassName = (leadType) => {
    if (leadType === "Hot") {
      return "badge badge-rounded badge-danger";
    } else if (leadType === "Warm") {
      return "badge badge-rounded badge-warning";
    } else if (leadType === "Cold") {
      return "badge badge-rounded badge-success";
    } else {
      return "badge badge-rounded badge-primary";
    }
  };
  const getLeadStatusClassName = (Type) => {
    if (
      Type === "Disqualified" ||
      Type === "Inactive Customer" ||
      Type === "Not a Target" ||
      Type === "Nurture"
    ) {
      return "badge badge-rounded badge-danger";
    } else if (Type === "Prospect") {
      return "badge badge-rounded badge-warning";
    } else if (Type === "Opportunity Created") {
      return "badge badge-rounded badge-success";
    } else {
      return "badge badge-rounded badge-primary";
    }
  };

  const handleCloseForAddOpportunity = () => {
    loadSalesData(currPage);
    setAddOpportunityModal(false);
  };

  const leadHistoryColumns = [
    {
      text: (
        <div>
          <span>Update</span>
          <br></br>
          <span>Type</span>
        </div>
      ),
      dataField: "updateType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Previous</span>
          <br></br>
          <span>Value</span>
        </div>
      ),
      dataField: "previousValue",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Updated</span>
          <br></br>
          <span>Value</span>
        </div>
      ),
      dataField: "updateValue",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Updated by</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Updated by</span>
          <br></br>
          <span> EMP ID</span>
        </div>
      ),
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Updated</span>
          <br></br>
          <span>At</span>
        </div>
      ),
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.status === "ACTIVE4") {
    //       return <></>;
    //     } else if (row?.filename !== "-") {
    //       return (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-info shadow btn-xs sharp">
    //             <i
    //               className="fa fa-play"
    //               style={{ marginTop: "3px", marginLeft: "2px" }}
    //             />
    //           </Link>
    //         </div>
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       // console.log("row", row);
    //       setCallDetails(row);
    //       setCallAudioModal(true);
    //     },
    //   },
    // },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.status === "ACTIVE4") {
    //       return <></>;
    //     } else if (row?.filename !== "-") {
    //       return (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-success shadow btn-xs sharp">
    //             <i className="fa fa-download" />
    //           </Link>
    //         </div>
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       // console.log("row", row);
    //       handleDownload(
    //         "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
    //           row?.filename,
    //         row?.toNumber + "_" + EpochToDate(Math.floor(new Date() / 1000))
    //       );
    //     },
    //   },
    // },
  ];

  const getLeadHistory = (row) => {
    moduleApi.getData(apiCall.getLeadHistory + row.id).then((response) => {
      const result = response?.map((element) => ({
        id: element.id ? element.id : 0,
        opportunityId: element.opportunityId ? element.opportunityId : 0,
        userId: element.userId ? element.userId : "-",
        empId: element.empId ? element.empId : "-",
        empName: element.empName ? element.empName : "-",
        leadId: element.leadId ? element.leadId : "-",
        leadStatusId: element.leadStatusId ? element.leadStatusId : "-",
        createdTime: element.createdTime
          ? helper.EpochToDate(element.createdTime)
          : "-",
        // updatedTime: element.updatedTime
        //   ? helper.EpochToDate(element.updatedTime)
        //   : "-",
        previousValue: element.previousValue ? element.previousValue : "-",
        updateType: element.updateType ? element.updateType : "-",
        updateValue: element.updateValue ? element.updateValue : "-",
      }));
      // console.log(result);
      setLeadHistory(result);
    })
  };

  const getLeadStatuses = () => {
    moduleApi
      .getData(
        apiCall.getLeadStatus +
        userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        const result = response.map((item) => ({
          value: item,
          label: item,
        }));
        setLeadStatuses(result);
      });
  };

  const updateleadStatus = () => {
    var payload = {
      id: leadDetails?.id,
      leadStatus:
        selectedLeadStatus !== null
          ? selectedLeadStatus
          : leadDetails?.leadStatus,
    };
    moduleApi.postData(apiCall.updateLead, payload).then((response) => {
      console.log("response", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          loadSalesData(currPage);
          setEditLeadModal(false);
          return swal("Lead Status Updated Successfully", {
            icon: "success",
            dangerMode: false,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const callOptionTemplate = (row) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            marginTop: "5px",
          }}
        >
          {row?.phoneNumber}
        </span>
        {hasAccess(42) &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
          isProgressiveDialing === false && (
            <Link
              style={{ marginLeft: "10px" }}
              to="#"
              // className="btn btn-primary shadow btn-xs sharp"
              onClick={() => {
                // helper.makeCall(props?.contactNumber1);
                if (isOnGoingCall === false && isIncomingCall===false) {
                  // var piopiy = new PIOPIY({
                  //   name: "CLU",
                  //   debug: false,
                  //   autoplay: true,
                  //   ringTime: 60,
                  // });
                  StartCall(
                    piopiy,
                    dispatch,
                    row?.phoneNumber,
                    false,
                    row?.id,
                    row,
                    "Sales",
                    isCallActive
                  );
                  // piopiy.call("91" + row?.contactNumber);
                  // setCallMobileNo(props?.contactNumber1);
                } else {
                  dispatch(setCallAudioModal(true));
                  dispatch(setIsModalMinimised(false));
                }
              }}
            >
              {/* <i className="fa fa-phone fa-1x fa-rotate-90" /> */}
              <div className="media-body user-meta-info">
                <ul>
                  <li>
                    <Link
                      to={"#"}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <i
                        className="fas fa-phone-alt"
                        style={{ fontSize: "smaller" }}
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <img
                          src="/sidebar/callIcon.png"
                          alt="sidebar"
                          style={{ width: 25, height: 25, marginRight: 10 }}
                        /> */}
            </Link>
          )}
      </div>
    );
  };

  const leadsDownloadData = () => {
    setIsLoading(true);
    var payload = {
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.getSalesLeads, 0, 10), payload)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getLeadsFullResponseData(
                  Math.floor(response.totalElements / 200)
                );
              }
            });
          }

          getLeadsFullResponseData(Math.floor(response.totalElements / 200));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
    // }
  };

  async function getLeadsFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];

    for (let i = 0; i <= size; i++) {
      var payload = {
        organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      };
      await moduleApi
        .postData(moduleApi.withPage(apiCall.getSalesLeads, i, 200), payload)
        .then((response) => {
          tempList.push(...response?.content);
        });
    }

    // console.log("templist", tempList);
    tempList?.forEach((element) => {
      let data = {
        channel: element.channel ? element.channel : "-",
        companyName: element.companyName ? element.companyName : "-",
        email: element.email ? element.email : "-",
        city: element.city ? element.city : "-",
        state: element.state ? element.state : "-",
        pinCode: element.pinCode ? element.pinCode : "-",
        firstName: element.firstName ? element.firstName : "-",
        customerName: element.customerName ? element.customerName : "-",
        id: element.id ? element.id : "-",
        lastName: element.lastName ? element.lastName : "-",
        leadChannelId: element.leadChannelId ? element.leadChannelId : "-",
        leadRate: element.leadRate ? getPriority(element.leadRate) : "-",
        leadStatus: element.leadStatus ? element.leadStatus : "-",
        leadStatusId: element.leadStatusId ? element.leadStatusId : "-",
        organizationId: element.organizationId ? element.organizationId : "-",
        phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
      };
      mgrListData.push(data);
    });
    // console.log("templist", mgrListData);
    setLeadsDownloadList(mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Channel",
        B: "Prospect Name",
        C: "Mobile Number",
        D: "Priority",
        E: "Status",
        F: "Email",
        G: "City",
        H: "State",
        I: "Pin Code",
      },
    ];
    leadsDownloadList?.forEach((row) => {
      mgrTable.push({
        A: row.channel,
        B: row.customerName,
        C: row.phoneNumber,
        D: row.leadRate,
        E: row.leadStatus,
        F: row.email,
        G: row.city,
        H: row.state,
        I: row.pinCode,
      });
    });
    const finalData = [...mgrTable];
    // console.log("finaldata", finalData);
    //create a new workbook
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    // console.log("sheet", sheet);
    XLSX.utils.book_append_sheet(wb, sheet, "Interested_Leads");
    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Interested_Leads_" +
      helper.EpochToDate(Math.floor(new Date() / 1000)) +
      ".xlsx"
    );
    ReactGA.event({
      category: "Interested Leads List Download",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Interested_Leads_List" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  //   const searchKeyword = (key) => {
  //     setSearchKey(key);
  //     loadSalesData(0, key, start, end);
  //   };

  //   const setCustomDatesForGraph = (value1) => {
  //     var start = value1[0].getTime() / 1000;
  //     var end =
  //       new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
  //         1000 +
  //       86399;

  //     setStart(start);
  //     setEnd(end);
  //     loadSalesData(0, searchKey, start, end);
  //   };

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          //   marginTop: "20px",
          marginBottom: "15px",
          marginRight: "15px",
        }}
      >
        {/* <SearchBar
          placeholder="Search By Emp Id/Emp Name"
          searchKeyword={searchKeyword}
        /> */}
        {/* <div
          style={{
            float: "right",
            // margin: "10px",
            marginLeft: "10px",
            marginRight: "10px",
            // pointerEvents:
            //   isProgressiveDialing === true && "none",
          }}
        >
          <Flatpickr
            className="form-control d-block"
            style={{
              width: "170px",
              height: "40px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              textAlignLast: "center",
            }}
            placeholder="Select Month"
            onChange={(value) => {
              setCustomDatesForGraph(value);
            }}
            options={{
              defaultDate: new Date(),
              maxDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 2,
                0
              ),
              minDate: new Date().setMonth(new Date().getMonth() - 6),

              plugins: [
                new monthSelectPlugin({
                  shorthand: true, //defaults to false
                  dateFormat: "F Y", //defaults to "F Y"
                  altFormat: "F Y", //defaults to "F Y"
                  theme: "light", // defaults to "light"
                }),
              ],
            }}
          />
        </div> */}
        <div>
          <Button
            style={{
              float: "right",
              //   margin: "10px",
              marginRight: "10px",
              marginTop: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="success btn-rounded"
            onClick={() => leadsDownloadData()}
          >
            <i className="fa fa-download"></i>
            &nbsp;&nbsp;Download
          </Button>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "-5px",
            // marginTop: "5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={salesLeadsList}
              columns={salesLeadsColumns}
            />
          </div>

          {salesLeadsList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" size="md" show={addAdditionalDetailsModal}>
        <Modal.Header>
          <Modal.Title>Add Additional Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddAdditionalDetailsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div>
          <div id="pdf">
            <iframe
            // id="pdf"
            // ref={iframeRef}
            style={{width:'-webkit-fill-available', marginTop:'-15px'}}
              src={"https://webdemo.clucloud.com/?fe=sales&og="+userLoginDetails?.autoFinanceOrganization?.orgshortcode+"&t1=additionaldetails&t2=form1&id="+leadDetails?.id}
              title="salesJsonForm"
              // width="1100"
              height="450"
            />
          </div> 
            {/* <AddAdditionalDetails leadDetails={leadDetails} close={handleAddAdditionalDetailsModal} /> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="md" show={addOpportunityModal}>
        <Modal.Header>
          <Modal.Title>Add Opportunity</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddOpportunityModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <AddOpportunity
              leadDetails={leadDetails}
              close={handleCloseForAddOpportunity}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="md" show={editLeadModal}>
        <Modal.Header>
          <Modal.Title>Edit Lead Status</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditLeadModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                Lead Status
              </label>
              <Select
                // id="assignee"
                // name="assignee"
                options={leadStatuses}
                defaultValue={{
                  label: leadDetails?.leadStatus,
                  value: leadDetails?.leadStatus,
                }}
                onChange={(option) => {
                  setSelectedLeadStatus(option.value);
                }}
                placeholder="Select Lead Status"
              />
            </div>
            <div style={{ float: "right", marginTop: "10px" }}>
              <Button
                style={{ float: "right" }}
                className="me-2"
                onClick={() => {
                  updateleadStatus();
                }}
              >
                Submit
              </Button>
              <Button
                style={{ float: "right" }}
                className="me-2"
                variant="danger light"
                onClick={() => {
                  setEditLeadModal(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="lg" show={leadHistoryModal}>
        <Modal.Header>
          <Modal.Title>Lead History</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setLeadHistoryModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "5px",
              overflowX: "auto",
            }}
          >
            <div
              style={{
                overflow: "auto",
                maxHeight: "500px",
              }}
            >
              <BootstrapTable
                keyField="id"
                data={leadHistory ? leadHistory : []}
                columns={leadHistoryColumns}
              />
            </div>

            {(leadHistory?.length === 0 ||
              leadHistory === null ||
              leadHistory === undefined) && (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SalesLeads;
