import React, { useEffect, useState } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import defaultImage from "../images/defaultImage.png";
// import { toaster } from "../rest/apiUtils";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import LoadingOverlay from "react-loading-overlay";
import swal from "sweetalert";
import "./Action.css";
import ReactGA from "react-ga";
import { isAdmin } from "../services/AuthService";
// const ValueContainer = ({ children, ...props }) => {
//     const { getValue, hasValue } = props;
//     const nbValues = getValue().length;
//     if (!hasValue) {
//       return (
//         <components.ValueContainer {...props}>
//           {children}
//         </components.ValueContainer>
//       );
//     }
//     return (
//       <components.ValueContainer {...props}>
//         {`${nbValues} items selected`}
//       </components.ValueContainer>
//     );
//   };

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children;
    const { length } = values;

    switch (length) {
      case 1:
        values = `${val(0)} selected`;
        break;
      case 2:
        values = `${val(0)} and ${val(1)} selected`;
        break;
      case 3:
        values = `${val(0)}, ${val(1)} and ${val(2)} selected`;
        break;
      default:
        const plural = values.length === 3 + 1 ? "" : "s";
        const otherCount = length - 3;
        values = `${val(0)}, ${val(1)}, ${val(
          2
        )} and ${otherCount} other${plural} selected`;
        break;
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const TransferUser = () => {
  var isFilePicked = "";
  var history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [isRoleChanged, setIsRoleChanged] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [branches, setBranches] = useState([]);
  const [roleNames, setRoleNames] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [managers, setManagers] = useState([]);
  const [reportingOfficers, setReportingOfficers] = useState([]);
  const [executives, setExecutives] = useState([]);
  const [AllBranchexecutives, setAllBranchExecutives] = useState([]);
  const [selectedExecutives, setSelectedExecutives] = useState(null);
  const [selectedManagers, setSelectedManagers] = useState(null);
  const [selectedManagers2, setSelectedManagers2] = useState(null);
  const [selectedMgrs, setSelectedMgrs] = useState(null);
  const [selectedMgrs2, setSelectedMgrs2] = useState(null);
  const [selectedExecs, setSelectedExecs] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedRoleName, setSelectedRoleName] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [enableForm, setEnableForm] = useState(false);
  const [allTransferModal, setAllTransferModal] = useState(false);
  const [allAllocationsModal, setAllAllocationsModal] = useState(false);
  const [selectedTransferMgr, setSelectedTransferMgr] = useState(null);
  const [selectedTransferExe, setSelectedTransferExe] = useState(null);
  const [branchNames, setBranchNames] = useState(null);
  const [initVal, setInitVal] = useState({
    name: "",
    mobileNumber: "",
    roleName: "",
    designation: "",
    branchName: "",
    image: "",
    email: "",
    reportingOfficerIds: [],
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //   setIsLoading(true)
    let path = window.location.pathname;
    console.log("path", path);
    path = path.split("/");

    var status = path[path.length - 2];
    var details = null;

    if (status === "managers" || status === "managerRA") {
      details = JSON.parse(localStorage.getItem("managerDetails"));
    } else {
      details = JSON.parse(localStorage.getItem("executiveDetails"));
    }
    setData(details);

    setSelectedRoleName(null);

    const branchbody = {
      organizationId: userData.autoFinanceUser.organizationId,
    };
    moduleApi.postData(apiCall.getBranches, branchbody).then((response) => {
      const result = response.map((item) => ({
        branchId: item.branchId,
        branchName: item.branchName,
      }));
      console.log("resuly", result);
      setBranchNames(result);
    });

    //Branches List
    const body = {
      searchKey: "",
      organizationId: userData.autoFinanceUser.organizationId,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, body).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
      }));
      setBranches(result);
    });

    //Default Managers based on default Manager
    var managersPayLoad2 = {
      organizationId: userData.autoFinanceUser.organizationId,
      reportOfficerId: details?.userId,
      roleName: "MANAGER",
      // status:'ACTIVE'
    };
    moduleApi.postData(apiCall.usersList, managersPayLoad2).then((response) => {
      console.log("response", response);
      const result = response?.map((item) => ({
        value: item.userId,
        label: item.name,
      }));
      const mgrs = response;
      const size = mgrs?.length;
      let managers = [];
      let defMgrs = [];
      for (let index = 0; index < size; index++) {
        const element = mgrs[index];
        const ele = {
          value: element.userId,
          label: element.name,
          branchId: element.branchId,
        };

        details?.reportingOfficerIds?.find(
          (element) => element === ele.value
        ) && defMgrs.push(ele);

        managers.push(ele);
      }

      setDefaultMgr2(result);
      setSelectedManagers2(result);
      // setManagers(managers);
    });

    //default reporting officer
    moduleApi
      .postData(apiCall.getUser + details?.reportingOfficerId)
      .then((response) => {
        const result = {
          value: response?.autoFinanceUser?.userId,
          label: response?.autoFinanceUser?.name,
        };
        setDefaultMgr(result);
        setSelectedManagers(result);
        setSelectedMgrs([result?.value]);
      });

    //Default Managers
    // var mgrBody = details?.reportingOfficerIds;

    // moduleApi.postData(apiCall.getMultipleUsers, mgrBody).then((response) => {
    //   const result = response?.map((item) => ({
    //     value: item.autoFinanceUser.userId,
    //     label: item.autoFinanceUser.name,
    //   }));
    //   setDefaultMgr(result);
    //   setSelectedManagers(result);
    // });

    //whole managers list for reporting officers field for manager role
    var managersPayLoad3 = {
      organizationId: userData.autoFinanceUser.organizationId,
      // roleName: "MANAGER",
      roleName: "ADMIN_MANAGER",
      // status:'ACTIVE'
    };
    // eslint-disable-next-line
    {
      (details?.roleName === "MANAGER" ||
        details?.roleName === "MANAGER_RA" ||
        details?.roleName === "EXECUTIVE") &&
        moduleApi
          .postData(apiCall.usersList, managersPayLoad3)
          .then((response) => {
            console.log("response", response);
            const result = response?.map((item) => ({
              value: item.userId,
              reportingOfficerId: item.reportingOfficerId,
              label: item.name + " - " + getBranch(item.branchId),
            }));
             let temp= result.filter((x) => x?.reportingOfficerId !== details?.userId)
            setReportingOfficers(
              temp.filter((x) => x?.value !== details?.userId)
            );
            // const mgrs = response;
            // const size = mgrs?.length;
            // let managers = [];
            // let defMgrs = [];
            // for (let index = 0; index < size; index++) {
            //   const element = mgrs[index];
            //   const ele = {
            //     value: element.userId,
            //     label: element.name,
            //     branchId: element.branchId,
            //   };

            //   details?.reportingOfficerIds?.find(
            //     (element) => element === ele.value
            //   ) && defMgrs.push(ele);

            //   managers.push(ele);
            // }

            // setDefaultMgr2(result);
            // setManagers(managers);
          });
    }

    //Default Managers List Based on default branch
    var managersPayLoad = {
      // branchId: details?.branchId,
      organizationId: userData.autoFinanceUser.organizationId,
      reportOfficerId: "",
      roleName: "MANAGER",
    };
    moduleApi.postData(apiCall.usersList, managersPayLoad).then((response) => {
      const mgrs = response;
      const size = mgrs.length;
      let managers = [];
      let defMgrs = [];
      for (let index = 0; index < size; index++) {
        const element = mgrs[index];
        const ele = {
          value: element.userId,
          label: element.name + " - " + getBranch(element.branchId),
          branchId: element.branchId,
        };

        details?.reportingOfficerIds?.find(
          (element) => element === ele.value
        ) && defMgrs.push(ele);

        managers.push(ele);
      }

      //   setDefaultMgr(defMgrs);
      //   setSelectedManagers(defMgrs);

      setManagers(
        managers.filter(
          (x) =>
            x?.value !== details?.userId &&
            x?.value !== details?.reportingOfficerId
        )
      );
      // setManagers(managers.filter((x) => x?.value !== details?.reportingOfficerId));
      // eslint-disable-next-line
      {
        details?.roleName === "EXECUTIVE" ? (
          setReportingOfficers(
            managers.filter((x) => x?.value !== details?.userId)
          )
        ) : (
          <></>
        );
      }
    });

    //Role Names List
    moduleApi
      .getData(apiCall.getRoleNames + userData.autoFinanceUser.organizationId)
      .then((response) => {
        let roles = [];

        for (let i = 0; i < response.length; i++) {
          const result = {
            value: response[i],
            label: response[i],
          };
          roles.push(result);
        }
        setRoleNames(roles);
      });

    //Designations List
    moduleApi
      .getData(
        apiCall.getDesignations + userData.autoFinanceUser.organizationId
      )
      .then((response) => {
        let designation = [];
        for (let i = 0; i < response.length; i++) {
          const result = {
            value: response[i],
            label: response[i],
          };
          designation.push(result);
        }
        setDesignations(designation);
      });

    //default executuves List
    var executivesPayLoad = {
      organizationId: userData.autoFinanceUser.organizationId,
      reportOfficerId: details?.userId,
      roleName: "EXECUTIVE",
    };
    // eslint-disable-next-line
    {
      (details?.roleName === "MANAGER" || details?.roleName === "MANAGER_RA") &&
        moduleApi
          .postData(apiCall.usersList, executivesPayLoad)
          .then((response) => {
            const result = response?.map((item) => ({
              value: item.userId,
              label: item.name + " - " + getBranch(item.branchId),
            }));
            setDefaultExecs(result);
            setSelectedExecutives(result);
            // setExecutives(result);
          });
    }

    setInitVal({
      name: details?.name,
      mobileNumber: details?.mobileNumber,
      roleName: details?.roleName,
      designation: details?.designation,
      branchName: details?.branchName,
      image: details?.image,
      email: details?.email,
      reportingOfficerIds: details?.reportingOfficerIds,
      //   executives:defaultExecs
    });

    setTimeout(() => {
      setEnableForm(true);
    }, 2000);
    // setEnableForm(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    // setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let path = window.location.pathname;
    console.log("path", path);
    path = path.split("/");

    var status = path[path.length - 2];
    var details = null;

    if (status === "manager" || status === "managerRA") {
      details = JSON.parse(localStorage.getItem("managerDetails"));
    } else {
      details = JSON.parse(localStorage.getItem("executiveDetails"));
    }
    var managersPayLoad3 = {
      organizationId: userData.autoFinanceUser.organizationId,
      // roleName: "MANAGER",
      roleName: "ADMIN_MANAGER",
      // status:'ACTIVE'
    };
    // eslint-disable-next-line
    {
      (details?.roleName === "MANAGER" ||
        details?.roleName === "MANAGER_RA" ||
        details?.roleName === "EXECUTIVE") &&
        moduleApi
          .postData(apiCall.usersList, managersPayLoad3)
          .then((response) => {
            const result = response?.map((item) => ({
              value: item.userId,
              label: item.name + " - " + getBranch(item.branchId),
            }));
            setReportingOfficers(
              result.filter((x) => x?.value !== details?.userId)
            );
          });
    }

    //Default Managers List Based on default branch
    var managersPayLoad = {
      // branchId: details?.branchId,
      organizationId: userData.autoFinanceUser.organizationId,
      reportOfficerId: "",
      roleName: "MANAGER",
    };
    moduleApi.postData(apiCall.usersList, managersPayLoad).then((response) => {
      const mgrs = response;
      const size = mgrs.length;
      let managers = [];
      let defMgrs = [];
      for (let index = 0; index < size; index++) {
        const element = mgrs[index];
        const ele = {
          value: element.userId,
          label: element.name + " - " + getBranch(element.branchId),
          branchId: element.branchId,
        };

        details?.reportingOfficerIds?.find(
          (element) => element === ele.value
        ) && defMgrs.push(ele);

        managers.push(ele);
      }

      //   setDefaultMgr(defMgrs);
      //   setSelectedManagers(defMgrs);

      setManagers(
        managers.filter(
          (x) =>
            x?.value !== details?.userId &&
            x?.value !== details?.reportingOfficerId
        )
      );
    });

    //Executives List
    var executivesPayLoad2 = {
      branchId: details?.branchId,
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: "EXECUTIVE",
    };
    // eslint-disable-next-line

    moduleApi
      .postData(apiCall.usersList, executivesPayLoad2)
      .then((response) => {
        const result = response?.map((item) => ({
          value: item.userId,
          label: item.name + " - " + getBranch(item.branchId),
        }));

        setExecutives(result.filter((x) => x?.value !== details?.userId));
      });
    // eslint-disable-next-line
  }, [branchNames]);

  const getBranch = (branchId) => {
    for (let i = 0; i < branchNames?.length; i++) {
      if (branchNames[i].branchId === branchId) {
        return branchNames[i].branchName;
      }
    }
  };

  var userData = JSON.parse(localStorage.getItem("userDetails"));

  const [defaultMgr, setDefaultMgr] = useState([]);
  const [defaultMgr2, setDefaultMgr2] = useState([]);
  const [defaultExecs, setDefaultExecs] = useState([]);

  var defaultbranch = {
    value: data?.userId,
    label: data?.branchName,
    branchId: data?.branchId,
  };

  var defaultRoleName = {
    value: data?.roleName,
    label: data?.roleName,
  };
  var defaultDesignation = {
    value: data?.designation,
    label: data?.designation,
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  //   var namePattern = "^[a-zA-Z]";
  var nameRegex = "[a-zA-Z ]+$";

  const editProfileValidate = Yup.object({
    email: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required"),
    name: Yup.string()
      .trim()
      .matches(nameRegex, "Please Enter a Valid Name")
      .required("Name is required"),
    // reportingOfficerIds: Yup.array().min(
    //   1,
    //   "Atleast 1 Reporting Officer is required"
    // ),
  });

  const onSubmit = (values) => {
    if (selectedMgrs?.length === 0 || selectedMgrs === null) {
      return swal("Reporting Officer should not be empty", {
        icon: "error",
        dangerMode: true,
      });
    }

    for (var i = 0; i < selectedMgrs?.length; i++) {
      var temp = selectedMgrs[i];
      for (var j = 0; j < selectedMgrs2?.length; j++) {
        if (temp === selectedMgrs2[j]) {
          return swal("Reporting Officer and Managers Should not be Same", {
            icon: "error",
            dangerMode: true,
          });
        }
      }
    }

    const payLoad = {
      branchId:
        selectedBranch === null ? data?.branchId : selectedBranch.branchId,
      companyDesignation:
        selectedDesignation === null
          ? data?.designation
          : selectedDesignation.value,
      email: values.email,
      employeeId: data?.employeeId,
      image: currentFile ? currentFile : data?.image,
      mobileNumber: values.mobileNumber,
      name: values.name,
      organizationId: data?.organizationId,
      passwordUpdated: data?.passwordUpdated,
      //   reportingOfficerIds:
      //     data?.roleName === "EXECUTIVE"
      //       ? selectedMgrs === null
      //         ? data?.reportingOfficerIds
      //         : selectedMgrs
      //       : [],
      reportingOfficerId:
        selectedManagers !== null ? selectedManagers.value : "",
      reportingOfficerIds: selectedMgrs !== null ? selectedMgrs : [],
      subOrdinateIds: selectedExecs !== null ? selectedExecs : [],
      // subOrdinateManagersIds: [],
      subOrdinateManagersIds: selectedMgrs2 !== null ? selectedMgrs2 : [],
      roleName:
        selectedRoleName === null ? data?.roleName : selectedRoleName.value,
      status: data?.status,
      userId: data?.userId,
    };
    console.log("payload", payLoad);
    moduleApi.postData(apiCall.transferUser, payLoad).then((response) => {
      if (
        response?.message ===
        "Managers/Executives are associated with the Manager. Please reassign them to a different Manager before Transfer."
      ) {
        // toaster("error", response?.message);
        return swal({
          title: "Re-Assign",
          text: "Managers/Executives are associated with the Manager. Please reassign them to a different Manager before Transfer. Do you want to reassign all the executives or managers to a new manager?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setAllTransferModal(true);
          }
        });
      } else if (
        response?.message ===
        "User has  Visits allocated, Please reassign them to a different user before Transfer"
      ) {
        // toaster("error", response?.message);
        return swal({
          title: "Re-Assign",
          text: "User has Visits allocated, Please reassign them to a different User before Transfer. Do you want to reassign all the allocations to a new user?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            var executivesPayLoad2 = {
              organizationId: userData.autoFinanceUser.organizationId,
              roleName: "MANAGER_EXECUTIVE",
            };
            // eslint-disable-next-line

            moduleApi
              .postData(apiCall.usersList, executivesPayLoad2)
              .then((response) => {
                const result = response?.map((item) => ({
                  value: item.userId,
                  label: item.name + " - " + getBranch(item.branchId),
                }));

                setAllBranchExecutives(
                  result.filter((x) => x?.value !== data?.userId)
                );
              });
            setAllAllocationsModal(true);
          }
        });
      } else if (response?.message) {
        swal(response?.message, {
          icon: "error",
          dangerMode: true,
        });
      } else if (response) {
        let path = window.location.pathname;
        path = path.split("/");
        var status = path[path.length - 2];
        history.push("/" + (status === "managerRA" ? "manager" : status));
        // toaster("success", "Transferred Successfully");
        swal("Transferred Successfully", {
          icon: "success",
        });
        setIsPreview(false);
        setCurrentFile(null);
      } else {
        // toaster("error", "Something Went Wrong, Please try again later");
        swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const handleChange1 = (e) => {
    setIsPreview(true);
    let files = e.target.files;
    if (
      (files[0].type === "image/jpg") |
      (files[0].type === "image/jpeg") |
      (files[0].type === "image/png") |
      (files[0].type === "image/JPEG") |
      (files[0].type === "image/JPG") |
      (files[0].type === "image/PNG")
    ) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setCurrentImage(reader.result);
        });
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
          let fileInfo = {
            base64: reader.result,
          };
          var basedata = fileInfo.base64.split(",");
          isFilePicked = basedata[1];
          data.image = isFilePicked;
          setCurrentFile(isFilePicked);
        }; // reader.onload
      } // for
    } else {
      e.target.files = null;
      swal("Please Select image Format Only", {
        icon: "error",
        dangerMode: true,
      });
    }
  };

  // const getManagerIds = (list) => {
  //   let ids = [];
  //   const mgrs = list ? list : [];
  //   const size = mgrs.length;
  //   for (let index = 0; index < size; index++) {
  //     const element = mgrs[index];
  //     ids.push(element.value);
  //   }
  //   return ids;
  // };

  const getSelectedMgrs = (value) => {
    let MgrsList = [];

    MgrsList.push(value.value);

    // setSelectedManagers(MgrsList);
    setSelectedMgrs(MgrsList);
    setSelectedManagers2(
      selectedManagers2.filter((x) => x?.value !== value.value)
    );
    setManagers(managers.filter((x) => x?.value !== value.value));
  };

  const getSelectedMgrs2 = (list) => {
    let MgrsList = [];
    let MgrsList2 = [];
    for (let i = 0; i < list?.length; i++) {
      MgrsList.push(list[i]);
      MgrsList2.push(list[i].value);
    }

    setSelectedManagers2(MgrsList);
    setSelectedMgrs2(MgrsList2);
  };
  const getSelectedExes = (list) => {
    let ExecsList = [];
    let ExecsList2 = [];
    for (let i = 0; i < list?.length; i++) {
      ExecsList.push(list[i]);
      ExecsList2.push(list[i].value);
    }

    setSelectedExecutives(ExecsList);
    setSelectedExecs(ExecsList2);
  };

  const getSelectedBranchMgrs = (branchId) => {
    setSelectedMgrs([]);
    setSelectedManagers([]);
    setSelectedManagers2([]);
    setSelectedExecutives([]);
    var managersPayLoad = {
      branchId: branchId,
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: "MANAGER",
    };
    moduleApi.postData(apiCall.usersList, managersPayLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.userId,
        label: item.name + " - " + getBranch(item.branchId),
      }));
      setManagers(result);
    });

    // eslint-disable-next-line
    // {
    //   data?.roleName === "EXECUTIVE" && !isManager&&
    //     moduleApi
    //       .postData(apiCall.usersList, managersPayLoad)
    //       .then((response) => {
    //         const result = response.map((item) => ({
    //           value: item.userId,
    //           label: item.name,
    //         }));
    //         setReportingOfficers(result);
    //       });
    // }

    var executivesPayLoad = {
      organizationId: userData.autoFinanceUser.organizationId,
      branchId: branchId,
      roleName: "EXECUTIVE",
    };
    // eslint-disable-next-line
    // {
    //   data?.roleName === "MANAGER" &&
    moduleApi
      .postData(apiCall.usersList, executivesPayLoad)
      .then((response) => {
        const result = response?.map((item) => ({
          value: item.userId,
          label: item.name + " - " + getBranch(item.branchId),
        }));

        setExecutives(result);
      });
    // }
  };

  const getReportOffcrsAfterRoleNameChange = (value) => {
    if (value.label !== "EXECUTIVE") {
      var managersPayLoad3 = {
        organizationId: userData.autoFinanceUser.organizationId,
        // roleName: "MANAGER",
        roleName: "ADMIN_MANAGER",
      };
      moduleApi
        .postData(apiCall.usersList, managersPayLoad3)
        .then((response) => {
          console.log("response", response);
          const result = response?.map((item) => ({
            value: item.userId,
            label: item.name + "-" + getBranch(item.branchId),
          }));
          setReportingOfficers(result.filter((x) => x?.value !== data?.userId));
        });
    } else {
      var managersPayLoad = {
        branchId:
          selectedBranch === null ? data?.branchId : selectedBranch.branchId,
        organizationId: userData.autoFinanceUser.organizationId,
        reportOfficerId: "",
        roleName: "MANAGER",
      };
      moduleApi
        .postData(apiCall.usersList, managersPayLoad)
        .then((response) => {
          const mgrs = response;
          const size = mgrs.length;
          let managers = [];
          for (let index = 0; index < size; index++) {
            const element = mgrs[index];
            const ele = {
              value: element.userId,
              label: element.name + "-" + getBranch(element.branchId),
              branchId: element.branchId,
            };
            managers.push(ele);
          }
          setReportingOfficers(
            managers.filter((x) => x?.value !== data?.userId)
          );
        });
    }
  };

  const components = { ValueContainer };

  const getAllTransferSubordinates = () => {
    let payload = {
      newUserId: selectedTransferMgr.value,
      oldUserId: data?.userId,
      organizationId: userData.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(apiCall.transferSubordinates, payload)
      .then((response) => {
        if (response) {
          if (response.Message === "Users Transferred Successfully") {
            swal("Transferred Successfully!", {
              icon: "success",
            });
          } else if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getAllTransferAllocations = () => {
    let payload = {
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: userData.autoFinanceUser.roleName,
      transferAll: true,
      transferByUserId: userData.autoFinanceUser.userId,
      transferFromExecutiveId: data?.userId,
      transferToExecutiveId: selectedTransferExe.value,
      transferToReportingOfficerId: !isAdmin()
        ? userData.autoFinanceUser.userId
        : undefined,
      visitIds: [],
    };
    moduleApi.postData(apiCall.transferCustomers, payload).then((response) => {
      if (response) {
        if (response.Message === "Transfered Successfully") {
          swal("Allocations Transferred Successfully!", {
            icon: "success",
          });
          setAllAllocationsModal(false);
        } else if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };
  return (
    <div className="card" style={{ backgroundColor: "white" }}>
      {/* <div  className="loader"></div> */}
      <div>
        <Button
          style={{
            float: "left",
            margin: "10px",
            marginLeft: "20px",
            marginTop: "10px",
            width: "40px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => {
            let path = window.location.pathname;
            path = path.split("/");
            var status = path[path.length - 2];
            history.push("/" + (status === "managerRA" ? "managers" : status));
          }}
        >
          <i className="fa fa-arrow-left" />
        </Button>
      </div>

      <div
        style={{
          float: "left",
          margin: "10px",
          marginLeft: "20px",
        }}
      >
        <LoadingOverlay
          active={isLoading}
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                    opacity: "o.5",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
                // strokeDasharray: 0,
              },
            }),
          }}
        >
          <div>
            {enableForm && (
              <Formik
                // initialValues={{
                //   email: data?.email,
                //   image: "",
                //   mobileNumber: data?.mobileNumber,
                //   name: data?.name,
                //   organizationId: "",
                //   reportingOfficerId: "",
                //   reportingOfficerIds: [],
                //   roleName: data?.roleName,
                //   designation: data?.designation,
                //   branchLocation: "",
                //   branchName: "",
                // }}
                initialValues={initVal}
                onSubmit={onSubmit}
                validationSchema={editProfileValidate}
                enableReinitialize
              >
                {({ formik, errors }) => (
                  <div>
                    <Row>
                      <Form>
                        <Row>
                          <Col>
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {isPreview ? (
                                <img
                                  src={
                                    currentImage ? currentImage : defaultImage
                                  }
                                  className="img-fluid rounded-circle"
                                  alt="profile"
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "50px",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                />
                              ) : (
                                <img
                                  // src={currentImage}
                                  src={data?.image ? data?.image : defaultImage}
                                  className="img-fluid rounded-circle"
                                  alt="profile"
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "50px",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                />
                              )}
                              <input
                                style={{
                                  marginLeft: "150px",
                                  marginBottom: "5px",
                                  marginTop: "10px",
                                }}
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleChange1(e)}
                              />
                            </div>
                            <label
                              className="control-label"
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                marginBottom: "5px",
                                marginTop: "5px",
                              }}
                            >
                              Name
                            </label>
                            <Field
                              type="text"
                              name="name"
                              className={`form-control`}
                              disabled
                            />
                            <ErrorMessage
                              component="div"
                              className="error"
                              name="name"
                            />
                            <label
                              className="control-label"
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                marginBottom: "5px",
                                marginTop: "5px",
                              }}
                            >
                              Mobile
                            </label>
                            <Field
                              type="text"
                              name="mobileNumber"
                              className={`form-control`}
                            />
                            <ErrorMessage
                              component="div"
                              className="error"
                              name="mobileNumber"
                            />
                            <label
                              className="control-label"
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                marginBottom: "5px",
                                marginTop: "5px",
                              }}
                            >
                              Email ID
                            </label>
                            <Field
                              type="text"
                              name="email"
                              className={`form-control`}
                            />
                            <ErrorMessage
                              component="div"
                              className="error"
                              name="email"
                            />
                          </Col>
                          <Col style={{ margin: "10px" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label
                                className="control-label"
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  marginTop: "5px",
                                }}
                              >
                                Branch
                              </label>
                              <Select
                                placeholder="Select a branch"
                                classNamePrefix="select-box"
                                options={branches}
                                defaultValue={defaultbranch}
                                onChange={(value) => {
                                  setSelectedBranch({
                                    value: value.value,
                                    label: value.label,
                                    branchId: value.branchId,
                                  });
                                  getSelectedBranchMgrs(value.branchId);
                                }}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label
                                className="control-label"
                                style={{
                                  fontWeight: "bold",
                                  float: "left",
                                  marginTop: "5px",
                                }}
                              >
                                Reporting Officer
                              </label>
                              <Select
                                placeholder="Select Reporting Officer"
                                classNamePrefix="select-box"
                                options={reportingOfficers}
                                defaultValue={defaultMgr}
                                value={selectedManagers}
                                onChange={(value) => {
                                  console.log("value", value);
                                  setSelectedManagers({
                                    value: value.value,
                                    label: value.label,
                                  });
                                  getSelectedMgrs(value);
                                }}
                              />
                            </div>

                            {/* <Field name="reportingOfficerIds">
                              {({ field, form }) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label
                                    className="control-label"
                                    style={{
                                      fontWeight: "bold",
                                      float: "left",
                                      marginTop: "5px",
                                    }}
                                  >
                                    Reporting Officer
                                  </label>
                                  <Select
                                    placeholder="Select Reporting Offiecr"
                                    classNamePrefix="select-box"
                                    options={reportingOfficers}
                                    
                                    defaultValue={defaultMgr}
                                    value={selectedManagers}
                                    
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        "reportingOfficerIds",
                                        value.value
                                      );
                                      getSelectedMgrs(value);
                                    }}
                                  />
                                </div>
                              )}
                            </Field>
                            {errors.reportingOfficerIds && (
                              <span className="error">
                                {errors.reportingOfficerIds}
                              </span>
                            )} */}
                            {(data?.roleName === "MANAGER" ||
                              data?.roleName === "MANAGER_RA" ||
                              isManager) && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginTop: "5px",
                                  }}
                                >
                                  Managers
                                </label>
                                <Select
                                  placeholder="Select Managers"
                                  classNamePrefix="select-box"
                                  options={managers}
                                  isMulti
                                  defaultValue={defaultMgr2}
                                  value={selectedManagers2}
                                  components={components}
                                  onChange={(list) => {
                                    getSelectedMgrs2(list);
                                  }}
                                />
                              </div>
                            )}
                            {(data?.roleName === "MANAGER" ||
                              data?.roleName === "MANAGER_RA" ||
                              isManager) && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  // className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    marginTop: "5px",
                                  }}
                                >
                                  Executives
                                </label>
                                <Select
                                  placeholder="Select Executives"
                                  classNamePrefix="select-box"
                                  isMulti
                                  components={components}
                                  options={executives}
                                  value={selectedExecutives}
                                  defaultValue={defaultExecs}
                                  onChange={(list) => {
                                    getSelectedExes(list);
                                  }}
                                />
                              </div>
                            )}

                            <div
                              className="form-check form-switch"
                              style={{
                                marginTop: "10px",
                                marginRight: "15px",
                                display: "flex",
                                flexDirection: "row",
                                padding: "initial",
                              }}
                            >
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckDefault"
                                style={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                Is Role Changed
                              </label>
                              <input
                                style={{
                                  width: "45px",
                                  height: "20px",
                                  marginLeft: "10px",
                                  fontWeight: "bold",
                                }}
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                  setIsRoleChanged(!isRoleChanged);
                                }}
                              />
                            </div>
                            {isRoleChanged && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    // marginBottom: "10px",
                                    marginTop: "5px",
                                  }}
                                >
                                  Role Name
                                </label>
                                <Select
                                  placeholder="Select Role Name"
                                  classNamePrefix="select-box"
                                  options={roleNames}
                                  defaultValue={defaultRoleName}
                                  onChange={(value) => {
                                    setSelectedRoleName(value);
                                    if (value.label !== "EXECUTIVE") {
                                      setIsManager(true);
                                    } else {
                                      setIsManager(false);
                                    }
                                    getReportOffcrsAfterRoleNameChange(value);
                                  }}
                                />
                              </div>
                            )}
                            {isRoleChanged && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="control-label"
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                    // marginBottom: "10px",
                                    marginTop: "5px",
                                  }}
                                >
                                  Designation
                                </label>
                                <Select
                                  placeholder="Select Designation"
                                  classNamePrefix="select-box"
                                  options={designations}
                                  defaultValue={defaultDesignation}
                                  onChange={(value) => {
                                    setSelectedDesignation(value);
                                  }}
                                />
                              </div>
                            )}
                            <div style={{ marginTop: "10px" }}>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  </div>
                )}
              </Formik>
            )}
          </div>
        </LoadingOverlay>
      </div>
      <Modal className="fade" show={allTransferModal}>
        <Modal.Header>
          <Modal.Title>Transfer</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAllTransferModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginTop: "5px",
                }}
              >
                Reporting Officer
              </label>
              <Select
                placeholder="Select Reporting Officer"
                classNamePrefix="select-box"
                options={reportingOfficers}
                // defaultValue={defaultMgr}
                // value={selectedManagers}
                onChange={(value) => {
                  console.log("value", value);
                  setSelectedTransferMgr({
                    value: value.value,
                    label: value.label,
                  });
                }}
              />
            </div>
            <Button
              style={{
                float: "right",
                marginTop: "15px",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => {
                if (selectedTransferMgr === null) {
                  return swal("Please Select Reporting officer", {
                    icon: "error",
                    dangerMode: true,
                  });
                  // toaster("error", "Please Select Reporting officer");
                } else {
                  setAllTransferModal(false);
                  swal({
                    title: "Re-Assign",
                    text: "Are you sure, you want to reassign all the executives or managers to the new selected manager?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      getAllTransferSubordinates();

                      // history.push("/executive");
                    }
                  });
                }
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={allAllocationsModal}>
        <Modal.Header>
          <Modal.Title>Transfer Allocations</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAllAllocationsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginTop: "5px",
                }}
              >
                Employees
              </label>
              <Select
                placeholder="Select Employee"
                classNamePrefix="select-box"
                options={AllBranchexecutives}
                // defaultValue={defaultMgr}
                // value={selectedManagers}
                onChange={(value) => {
                  console.log("value", value);
                  setSelectedTransferExe({
                    value: value.value,
                    label: value.label,
                  });
                }}
              />
            </div>
            <Button
              style={{
                float: "right",
                marginTop: "15px",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => {
                if (selectedTransferExe === null) {
                  return swal("Please Select Executive", {
                    icon: "error",
                    dangerMode: true,
                  });
                  // toaster("error", "Please Select Reporting officer");
                } else {
                  setAllTransferModal(false);
                  swal({
                    title: "Re-Assign",
                    text: "Are you sure, you want to reassign all the allocations to the selected new user?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      getAllTransferAllocations();

                      // history.push("/executive");
                    }
                  });
                }
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TransferUser;
