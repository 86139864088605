import React, { useContext } from "react";
/// React router dom
import { Redirect, Route, Switch } from "react-router-dom";
import Compliance from "../Compliance/Compliance";
import { ThemeContext } from "../context/ThemeContext";
import CustomerMgmt from "../CustomerManagement/CustomerMgmt";
import Dashboard from "../Dashboard/Dashboard";
import VisitsList from "../Dashboard/VisitsList";
import CollectionDetails from "../DB/CollectionDetails";
import DB from "../DB/DB";
import ExecutiveDetails from "../Executives/ExecutiveDetails";
import Executives from "../Executives/Executives";
import LeaveManagement from "../Leave Management/LeaveManagement";
import ManagerDetails from "../Managers/ManagerDetails";
import ManagerExecutiveDetails from "../Managers/ManagerExecutiveDetails";
import L1ManagerDetails from "../Managers/L1ManagerDetails";
import Managers from "../Managers/Managers";
import Report from "../Reports/Report";
import VisitManagement from "../Visit_Management/VisitManagement";
import ExpenseManagement from "../ExpenseManagement/ExpenseManagement";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";
import Setting from "./layouts/Setting";
import Login from "./pages/Login";
import "./step.css";
import UploadDailySchedule from "../UploadSchedule/UploadDailySchedule";
import InActiveExecutive from "../Executives/InActiveExecutive";
import InActiveDetails from "../Executives/InActiveDetails";
import TransferUser from "../CommonComponents/TransferUser";
import Agencies from "../Agencies/Agencies";
import FieldInvManagement from "../FieldInvManagement";
import CannedReports from "../CannedReports/CannedReports";
import "./index.css";
import "./chart.css";
import PayrollForExecutives from "../PayrollForExecutives/PayrollForExecutives";
import Subscriptions from "../UpiMandates/Subscriptions";
import Onboarding from "../onboarding/Onboarding";
import AddBranch from "../onboarding/AddBranch";
import UpdatePrivilages from "../onboarding/UpdatePrivilages";
import Transactions from "../UpiMandates/Transactions";
import Payments from "../UpiMandates/Payments";
// import RequestPayments from "../RequestPayments/RequestPayments";
// import NewRequestPayments from "../RequestPayments/NewRequestPayments";
import CashDeposition from "../CashDeposition/CashDeposition";
import CpUsers from "../CPNames/CpUsers";
import OrgHierarchy from "../OrgHierarchy/OrgHierarchy";
import VehicleSaleData from "../VehicleService/VehicleSaleData";
import VehicleServiceData from "../VehicleService/VehicleServiceData";
import ServiceExecutives from "../ServiceExecutives/ServiceExecutives";
import ManagerRAUsers from "../ManagerRA/ManagerRAUsers";
import WorkFlow2 from "./WorkFlow/WorkFlow2";
import SupersetDashboard from "../SupersetDashboard/SupersetDashboard";
import ManagerRaDetails from "../ManagerRA/ManagerRaDetails";
import MainCustMgmt from "../CustomerManagement/MainCustMgmt";
import Branches from "../Branches/Branches";
import Designations from "../Designations/Designations";
import MyAllocations from "../MyAllocations/MyAllocations";
import CallReport from "../Reports/CallReport";
import PtpList from "../Dashboard/PtpList";
import CallDashboard2 from "../CallDashboard/CallDashboard2";
import PtpNotificationsList from "../PtpNotificationsList/PtpNotificationsList";
import WorkflowStatus from "../WorkFlowStatus/WorkflowStatus";
import OrgAssets from "../OrgAssets/OrgAssets";
import SalesAllocations from "../SalesAllocations/SalesAllocations";
import MySalesAllocations from "../SalesAllocations/MySalesAllocations";
import SalesCallReport from "../SalesAllocations/SalesCallReport";
import MainIncentiveMgmt from "../Incentives/MainIncentiveMgmt";
import ReporteeIncentives from "../Incentives/ReporteeIncentives";
import SalesCRM from "../SalesLeads/SalesCRM";
import SalesLeads from "../SalesLeads/SalesLeads";
import SalesTeams from "../SalesLeads/SalesTeams";
import IncentiveMgmt from "../Incentives/IncentiveMgmt";
import EligibleEmployeesForIncentives from "../Incentives/EligibleEmployeesForIncentives";
import FinalPayoutDetails from "../Incentives/FinalPayoutDetails";
import MainRequestPayments from "../RequestPayments/MainRequestPayments";
import Whatsapp from "../Whatsapp/Whatsapp";
import Templates from "../Whatsapp/components/Templates";
import UPIMandateSummary from "../UpiMandates/UPIMandateSummary";
// import FiClients from "../FiClients/FiClients";
// import AllocationCountsVisitsList from "../Dashboard/AllocationCountsVisitsList";
const Markup = () => {
  var userData = JSON.parse(localStorage.getItem("userDetails"));
  const { menuToggle } = useContext(ThemeContext);

  const routes = [
    { url: "home", component: Dashboard },
    { url: "custom-dashboards", component: DB },
    { url: "managers", component: Managers },
    { url: "cp-users", component: CpUsers },
    // { url: "fi-clients", component: FiClients },
    { url: "manager-details", component: ManagerDetails },
    { url: "manager-executive-details", component: ManagerExecutiveDetails },
    { url: "executives", component: Executives },
    { url: "service-executives", component: ServiceExecutives },
    { url: "executive-details", component: ExecutiveDetails },
    { url: "reports", component: Report },
    { url: "visits", component: VisitManagement },
    { url: "expenses", component: ExpenseManagement },
    {
      url: "home/:id/:startDate/:endDate/:reportingOfficerId/:status/:subStatus/:visitType",
      component: VisitsList,
    },
    {
      url: "home/:id/:startDate/:endDate/:ptpStatus",
      component: PtpList,
    },
    { url: "compliance", component: Compliance },
    { url: "leaves", component: LeaveManagement },
    { url: "org-hierarchy", component: OrgHierarchy },
    { url: "work-flow", component: WorkFlow2 },
  { url: "workflow", component: WorkflowStatus },
    { url: "branches", component: Branches },
    { url: "designations", component: Designations },
    { url: "collections-report", component: CallReport },
    { url: "live-dashboard", component: CallDashboard2 },
    { url: ":tab/my-allocations", component: MyAllocations },
    { url: "sales", component: SalesAllocations },
    { url: "sales-allocations", component: MySalesAllocations },
    { url: "sales-report", component: SalesCallReport },
    { url: "PTP", component: PtpNotificationsList },
    {
      url: "customers",
      component:
        userData?.autoFinanceUser?.roleName !== "CP_USER"
          ? MainCustMgmt
          : CustomerMgmt,
    },
    { url: "collection-details", component: CollectionDetails },
    { url: "executive-details/:id", component: ExecutiveDetails },
    { url: "l1-manager-details", component: L1ManagerDetails },
    { url: "collections", component: UploadDailySchedule },
    { url: "inactive-users", component: InActiveExecutive },
    { url: "read-only-users-[Incharges]", component: ManagerRAUsers },
    { url: "read-only-user-details", component: ManagerRaDetails },
    { url: "inactive-details", component: InActiveDetails },
    { url: ":executive/transfer", component: TransferUser },
    { url: "agencies", component: Agencies },
    { url: "subscriptions", component: Subscriptions },
    { url: "transactions", component: Transactions },
    { url: "upi-mandate-summary", component: UPIMandateSummary },
    { url: "payments-history", component: Payments },
    { url: "vehicle-sales", component: VehicleSaleData },
    { url: "vehicle-services", component: VehicleServiceData },
    { url: "request-payments", component: MainRequestPayments },
    { url: "cash-depositions", component: CashDeposition },
    { url: "onboarding", component: Onboarding },
    { url: "onboarding-details", component: AddBranch },
    { url: "update-privilages", component: UpdatePrivilages },
    { url: "field-investigation", component: FieldInvManagement },
    { url: "preBuilt-reports", component: CannedReports },
    { url: "dashboard", component: SupersetDashboard },
    { url: "incentives", component: MainIncentiveMgmt },
    { url: "active-incentives", component: IncentiveMgmt },
    { url: "eligible-employees", component: EligibleEmployeesForIncentives },
    { url: "reportee-incentives", component: ReporteeIncentives },
    { url: "final-payout-details", component: FinalPayoutDetails },
    { url: "payroll-[Beta]", component: PayrollForExecutives },
    { url: "org-assets", component: OrgAssets },
    { url: "opportunities", component: SalesCRM },
    { url: "potential-leads", component: SalesLeads },
    { url: "teams", component: SalesTeams },
    { url: "templates", component: Templates },
    // { url: "Dashboard/visits", component: AllocationCountsVisitsList },
    // {url: ":anymodule/whatsapp",component: Whatsapp},
    /// Dashboard
    // { url: "*", component: Dashboard },
    // { url: "dashboard", component: Home },
    // { url: "dashboard-dark", component: DashboardDark },
    // { url: "project", component: Project },
    // { url: "contacts", component: Contacts },
    // { url: "kanban", component: Kanban },
    // { url: "task", component: Task },
    // { url: "calendar", component: DashboardCalendar },
    // { url: "messages", component: Messages },

    ///Demo
    // { url: "theme1", component: Theme1 },
    // { url: "theme2", component: Theme2 },
    // { url: "theme3", component: Theme3 },
    // { url: "theme4", component: Theme4 },
    // { url: "theme5", component: Theme5 },
    // { url: "theme6", component: Theme6 },

    /// Apps
    { url: "app-profile", component: AppProfile },
    // { url: "email-compose", component: Compose },
    // { url: "email-inbox", component: Inbox },
    // { url: "email-read", component: Read },
    // { url: "app-calender", component: Calendar },
    // { url: "post-details", component: PostDetails },

    /// Chart
    // { url: "chart-sparkline", component: SparklineChart },
    // { url: "chart-chartjs", component: ChartJs },
    // { url: "chart-chartist", component: Chartist },
    // { url: "chart-apexchart", component: ApexChart },
    // { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    // { url: "ui-alert", component: UiAlert },
    // { url: "ui-badge", component: UiBadge },
    // { url: "ui-button", component: UiButton },
    // { url: "ui-modal", component: UiModal },
    // { url: "ui-button-group", component: UiButtonGroup },
    // { url: "ui-accordion", component: UiAccordion },
    // { url: "ui-list-group", component: UiListGroup },
    // { url: "ui-media-object", component: UiMediaObject },
    // { url: "ui-card", component: UiCards },
    // { url: "ui-carousel", component: UiCarousel },
    // { url: "ui-dropdown", component: UiDropDown },
    // { url: "ui-popover", component: UiPopOver },
    // { url: "ui-progressbar", component: UiProgressBar },
    // { url: "ui-tab", component: UiTab },
    // { url: "ui-pagination", component: UiPagination },
    // { url: "ui-typography", component: UiTypography },
    // { url: "ui-grid", component: UiGrid },

    /// Plugin
    // { url: "uc-select2", component: Select2 },
    // { url: "uc-nestable", component: Nestable },
    // { url: "uc-noui-slider", component: MainNouiSlider },
    // { url: "uc-sweetalert", component: MainSweetAlert },
    // { url: "uc-toastr", component: Toastr },
    // { url: "map-jqvmap", component: JqvMap },
    // { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    // { url: "todo", component: Todo },
    // { url: "redux-form", component: ReduxForm },
    //   { url: "redux-wizard", component: WizardForm },

    /// Widget
    // { url: "widget-basic", component: Widget },

    /// Shop
    // { url: "ecom-product-grid", component: ProductGrid },
    // { url: "ecom-product-list", component: ProductList },
    // { url: "ecom-product-detail", component: ProductDetail },
    // { url: "ecom-product-order", component: ProductOrder },
    // { url: "ecom-checkout", component: Checkout },
    // { url: "ecom-invoice", component: Invoice },
    // { url: "ecom-product-detail", component: ProductDetail },
    // { url: "ecom-customers", component: Customers },

    /// Form
    // { url: "form-element", component: Element },
    // { url: "form-wizard", component: Wizard },
    // { url: "form-editor-summernote", component: SummerNote },
    // { url: "form-pickers", component: Pickers },
    // { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    // { url: 'table-filtering', component: FilteringTable },
    //   { url: 'table-sorting', component: SortingTable },
    //   { url: "table-datatable-basic", component: DataTable },
    //   { url: "table-bootstrap-basic", component: BootstrapTable },

    /// pages
    // { url: "page-register", component: Registration },
    // { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    // { url: "page-forgot-password", component: ForgotPassword },
    // { url: "page-error-400", component: Error400 },
    // { url: "page-error-403", component: Error403 },
    // { url: "page-error-404", component: Error404 },
    // { url: "page-error-500", component: Error500 },
    // { url: "page-error-503", component: Error503 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return path === "whatsapp" ? (
    <Whatsapp />
  ) : (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
              <Route render={() => <Redirect to={{ pathname: "/home" }} />} />
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
