import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { Button, Spinner } from "react-bootstrap";
// import VisitLocationMap from "../CommonComponents/VisitLocationMap";
// import { Button, Modal } from "react-bootstrap";

const RepeatVisitCount = ({ start, end, handleManagerColumns, isDefault }) => {
  var userDetails = getTokenInLocalStorage();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage2, setCurrPage2] = useState(0);
  const [totalElements2, setTotalElements2] = useState(1);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [selectedEmpDetails, setSelectedEmpDetails] = useState(null);
  // const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  // const [isMap, setIsMap] = useState(false);

  //   useEffect(() => {
  //     if (isDefault) return;

  //     if (
  //       isAdmin() ||
  //       userDetails.autoFinanceUser.subOrdinateManagersIds.length > 0
  //     ) {
  //       setMgrId("");
  //       loadData(0, "", isDefault);
  //       return;
  //     }

  //     setMgrId(userDetails.autoFinanceUser.userId);
  //     loadData(0, userDetails.autoFinanceUser.userId, isDefault);

  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [isDefault]);

  useEffect(() => {
    loadData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      text: "Emp Id",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Count",
      dataField: "count",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedEmpDetails(row);
          getCustomerDetailsList(0, row);
        },
      },
    },
  ];

  const loadData = (pageNo) => {
    // console.log(pageNo, userId, isLevelChanged, "params");
    setIsLoading(true);
    setData([]);
    let array = [];

    var payLoad = {
      endDate: end,
      startDate: start,
      organizationId: userDetails?.autoFinanceUser?.organizationId,
      reportingOfficerId: isAdmin() ? "" : userDetails?.autoFinanceUser.userId,
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          apiCall.getMultipleVisitsToCustomerCounts,
          pageNo,
          10
        ),
        payLoad
      )
      .then((response) => {
        setCurrPage(pageNo);
        response?.content?.forEach((element) => {
          let data = {
            allocationId: element?.allocationId,
            count: element?.count ? element?.count : 0,
            customerName: element?.customerName ? element?.customerName : "-",
            empId: element?.empId ? element?.empId : "-",
            empName: element?.empName ? element?.empName : "-",
            loanNumber: element?.loanNumber ? element?.loanNumber : "-",
            userId: element?.userId ? element?.userId : "-",
          };
          array.push(data);
        });

        setData(array);
        setTotalElements(response?.totalElements);
        setIsLoading(false);

        // setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    // console.log(selectedPage - 1, mgrId, isDefault);
    loadData(selectedPage - 1, start, end);
  };
  const onPageChange2 = (selectedPage) => {
    // console.log(selectedPage - 1, mgrId, isDefault);
    getCustomerDetailsList(selectedPage - 1, selectedEmpDetails);
  };

  const getCustomerDetailsList = (pageNo, row) => {
    setIsLoading(true);
    let array = [];

    var payLoad = {
      endDate: end,
      startDate: start,
      organizationId: userDetails?.autoFinanceUser?.organizationId,
      reportingOfficerId: isAdmin() ? "" : userDetails?.autoFinanceUser.userId,
      userId: row.userId,
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          apiCall.getMultipleVisitsToCustomerCounts,
          pageNo,
          10
        ),
        payLoad
      )
      .then((response) => {
        setCurrPage2(pageNo);
        response?.content?.forEach((element) => {
          let data = {
            allocationId: element?.allocationId,
            count: element?.count ? element?.count : 0,
            customerName: element?.customerName ? element?.customerName : "-",
            empId: element?.empId ? element?.empId : "-",
            empName: element?.empName ? element?.empName : "-",
            loanNumber: element?.loanNumber ? element?.loanNumber : "-",
          };
          array.push(data);
        });

        setCustomerDetails(array);
        setTotalElements2(response?.totalElements);
        setIsLoading(false);
        handleManagerColumns(true);

        // setIsLoading(false);
      });
  };

  const executiveColumns = [
    {
      text: "Emp Id",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan No",
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Count",
      dataField: "count",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  //   const setCustomDatesForGraphForCollectionCounts = (value) => {
  //     var start = value[0].getTime() / 1000;
  //     var end =
  //       new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
  //         1000 +
  //       86399;

  //     setStartDate(start);
  //     setEndDate(end);
  //     loadData(currPage, start, end);
  //   };

  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div>
        {!isDefault ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{ background: "white", margin: "10px", overflowX: "auto" }}
            >
              <div style={{ overflow: "auto", maxHeight: "400px" }}>
                {data.length >= 1 ? (
                  <BootstrapTable
                    keyField="id"
                    data={data}
                    columns={columns}
                    id="empDetails"
                  />
                ) : null}
              </div>
              {data.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              ) : null}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PaginationComponent
                  totalItems={totalElements}
                  pageSize={10}
                  defaultActivePage={currPage + 1}
                  onSelect={onPageChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{ background: "white", margin: "10px", overflowX: "auto" }}
            >
              <div style={{ overflow: "auto", maxHeight: "400px" }}>
                {data.length >= 1 ? (
                  <BootstrapTable
                    keyField="panNo"
                    data={customerDetails}
                    columns={executiveColumns}
                    id="customerdetails"
                  />
                ) : null}
              </div>
              {customerDetails.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              ) : null}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PaginationComponent
                  totalItems={totalElements2}
                  pageSize={10}
                  defaultActivePage={currPage2 + 1}
                  onSelect={onPageChange2}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default RepeatVisitCount;
