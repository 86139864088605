import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
// import Select from "react-select";
import { Link } from "react-router-dom";
import NO_DATA_FOUND from "../images/no-data-found.png";
import PaginationComponent from "react-reactstrap-pagination";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import { apiCall } from "../rest/restApi";
import * as moduleApi from "../rest/moduleApi";
// import * as helper from "../CommonComponents/Helper";
// import { toaster } from "../rest/apiUtils";
// import ReactApexChart from "react-apexcharts";
// import Select from "react-select";
import swal from "sweetalert";
// import Flatpickr from "react-flatpickr";
// import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
// import "flatpickr/dist/plugins/monthSelect/style.css";
// import * as XLSX from "xlsx";
import LoadImage from "../CommonComponents/LoadImage";
import ReactGA from "react-ga";

const DashboardAllocationSummaryList = ({
  start,
  end,
  handleManagerColumns,
  isDefault,
}) => {
  //   var valIndex = 0;
  const [totalElements, setTotalElements] = useState(1);
  const [totalElements2, setTotalElements2] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [currPage2, setCurrPage2] = useState(0);
  const [basicModal2, setBasicModal2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
//   const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
//   const [isExecutive, setIsExecutive] = useState(false);
  const [reportingOfcrId, setReportingOfcrId] = useState("");
  const [managersCountsData, setManagersCountsData] = useState([]);
  const [executivesCountsData, setExecutivesCountsData] = useState([]);
  //   const [isDateFilter, setIsDateFilter] = useState(false);
  //   const [mgrData, setMgrData] = useState(null);
  //   const [exeData, setExeData] = useState(null);
  //   const [start, setStart] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
  //       1000
  //   );
  //   const [end, setEnd] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
  //       1000 +
  //       86399
  //   );

  var userData = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview("Collection Summary");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (
    //   userData.autoFinanceUser.roleName === "MANAGER" ||
    //   userData.autoFinanceUser.roleName === "MANAGER_RA" ||
    //   userData.autoFinanceUser.roleName === "CP_USER"
    // ) {
    //   setIsExecutive(true);
    // }
    managerLoadData(0, start, end);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {
  //     (mgrData !== null || exeData !== null) && handleExport();
  //     setIsLoading2(false);
  //     setIsLoading3(false);
  //     // eslint-disable-next-line
  //   }, [mgrData, exeData]);

  const managerLoadData = (pageNo, start, end) => {
    // setIsLoading(true);
    let listData = [];
    var payload = {
      endDate: end,
      organizationid: userData.autoFinanceUser.organizationId,
      reportingOfficerId:
        userData.autoFinanceUser.roleName === "MANAGER" ||
        userData.autoFinanceUser.roleName === "MANAGER_RA"
          ? userData.autoFinanceUser.userId
          : undefined,
      roleName: userData.autoFinanceUser.roleName,
      startDate: start,
    };

    let cpUsersPayload = {
      cpName: userData.autoFinanceOrganization.cpName,
      endDate: end,
      organizationid: userData.autoFinanceOrganization.mainOrganizationId,
      roleName: userData.autoFinanceUser.roleName,
      startDate: start,
    };

    // moduleApi
    //   .postData(
    //     moduleApi.withPage(apiCall.getWorkUploadCounts, pageNo, 10),
    //     payload
    //   )
    //   .then((response) => {
    moduleApi
      .postData(
        moduleApi.withPage(
          userData.autoFinanceUser.roleName !== "CP_USER"
            ? apiCall.getWorkUploadCounts
            : apiCall.getAllocationSummaryForCpUsers,
          pageNo,
          10
        ),
        userData.autoFinanceUser.roleName !== "CP_USER"
          ? payload
          : cpUsersPayload
      )
      .then((response) => {
        if (response) {
          console.log("countsresponse", response);
          response?.content?.forEach((element) => {
            let data = {
              collectionVisitCount: element.collectionVisitCount,
              completed: element.completed,
              customerNotAvailable: element.customerNotAvailable,
              userId: element.userId,
              employeeId: element.executiveEmployeeId,
              mobileNumber: element.executiveMobileNumber,
              resolutionPercentage: element.resolutionPercentage
                ? element.resolutionPercentage.toFixed(0) + " %"
                : "0%",
              name: element.executiveName,
              uniqueVisitForCustomer: element.uniqueVisitForCustomer
                ? element.uniqueVisitForCustomer
                : "0",
              inprogress: element.inprogress,
              open: element.open,
              paid: element.paid,
              partial: element.partial,
              ptp: element.ptp,
              reportingOfficerId: element.reportingOfficerId,
              salesVisitCount: element.salesVisitCount,
              totalAllocationCounts: element.totalAllocationCounts,
            };
            listData.push(data);
          });
          setManagersCountsData(listData);
          setIsLoading(false);
          setTotalElements(response?.totalElements);
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    managerLoadData(selectedPage - 1, start, end);
  };
  const columns = [
    {
      text: "Emp Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <LoadImage source={row.image} />{" "}
            <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
          </div>
        );
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total</span>
          <br></br>
          <span>Allocated</span>
        </div>
      ),
      dataField: "totalAllocationCounts",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Paid",
    //   dataField: "paid",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    // {
    //   text: "Partially Paid",
    //   dataField: "partial",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    // {
    //   text: "PTP",

    //   dataField: "ptp",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    // {
    //   text: "Third Party",

    //   dataField: "thirdParty",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },

    // {
    //   text: "Customer Not Available ",

    //   dataField: "customerNotAvailable",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },

    {
      text: "Open",
      dataField: "open",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "In Progress",
      dataField: "inprogress",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Completed",
      dataField: "completed",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Resolution</span>
          <br></br>
          <span>Percentage</span>
        </div>
      ),
      dataField: "resolutionPercentage",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Unique Visits for</span>
          <br></br>
          <span>Customer</span>
        </div>
      ),
      dataField: "uniqueVisitForCustomer",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Completed",
    //   dataField: "completed",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return !isDefault && !(
            userData.autoFinanceUser.roleName === "MANAGER" ||
            userData.autoFinanceUser.roleName === "MANAGER_RA" ||
            userData.autoFinanceUser.roleName === "CP_USER"
          )  ? (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          ) : (
            <></>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (!isDefault && !(
            userData.autoFinanceUser.roleName === "MANAGER" ||
            userData.autoFinanceUser.roleName === "MANAGER_RA" ||
            userData.autoFinanceUser.roleName === "CP_USER"
          ) ) {
            localStorage.setItem(
              "isDashboardCollectionAllocationCardCalled",
              "false"
            );
            setReportingOfcrId(row.userId);
            executiveLoadData(0, row.userId, start, end);
            // setIsExecutive(true);
            // setBasicModal2(true);
          }
        },
      },
    },
  ];

  const executiveLoadData = (pageNo, reportingOfficerId, start, end) => {
    let listData = [];
    setIsLoading3(true);
    var payload = {
      endDate: end,
      organizationid: userData.autoFinanceUser.organizationId,
      reportingOfficerId: reportingOfficerId,
      roleName: userData.autoFinanceUser.roleName,
      startDate: start,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getWorkUploadCounts, pageNo, 10),
        payload
      )
      .then((response) => {
        console.log("executiveresponse", response);
        response?.content?.forEach((element) => {
          let data = {
            collectionVisitCount: element.collectionVisitCount,
            completed: element.completed,
            customerNotAvailable: element.customerNotAvailable,
            userId: element.employeeId,
            employeeId: element.executiveEmployeeId,
            mobileNumber: element.executiveMobileNumber,
            resolutionPercentage: element.resolutionPercentage
              ? element.resolutionPercentage.toFixed(0) + " %"
              : "0%",
            name: element.executiveName,
            uniqueVisitForCustomer: element.uniqueVisitForCustomer
              ? element.uniqueVisitForCustomer
              : "0",
            inprogress: element.inprogress,
            open: element.open,
            paid: element.paid,
            partial: element.partial,
            ptp: element.ptp,
            reportingOfficerId: element.reportingOfficerId,
            salesVisitCount: element.salesVisitCount,
            totalAllocationCounts: element.totalAllocationCounts,
          };
          listData.push(data);
        });
        setExecutivesCountsData(listData);
        setTotalElements2(response?.totalElements);
        handleManagerColumns(true);
      });
    setIsLoading3(false);
    // setIsLoading2(false);
  };

  const onPageChange2 = (selectedPage) => {
    setCurrPage2(selectedPage - 1);
    executiveLoadData(selectedPage - 1, reportingOfcrId, start, end);
  };

  //   const dateOptions = [
  //     { label: "Custom Month", value: "cm" },
  //     { label: "Custom Dates", value: "cd" },
  //   ];

  //   const setCustomDatesForGraph = (value, isMonth) => {
  //     var start =
  //       isMonth === true
  //         ? value[0].getTime() / 1000
  //         : Math.floor(new Date(value[0]).getTime() / 1000);
  //     var end =
  //       isMonth === true
  //         ? new Date(
  //             value[0].getFullYear(),
  //             value[0].getMonth() + 1,
  //             0
  //           ).getTime() /
  //             1000 +
  //           86399
  //         : Math.floor(new Date(value[1]).getTime() / 1000 + 86399);
  //     setStart(start);
  //     setEnd(end);
  //     managerLoadData(0, start, end);
  //   };

  //   const mgrSummaryDownload = () => {
  //     setIsLoading2(true);
  //     var payload = {
  //       endDate: end,
  //       organizationid: userData.autoFinanceUser.organizationId,
  //       reportingOfficerId:
  //         userData.autoFinanceUser.roleName === "MANAGER" ||
  //         userData.autoFinanceUser.roleName === "MANAGER_RA"
  //           ? userData.autoFinanceUser.userId
  //           : undefined,
  //       roleName: userData.autoFinanceUser.roleName,
  //       startDate: start,
  //     };

  //     let cpUsersPayload = {
  //       cpName: userData.autoFinanceOrganization.cpName,
  //       endDate: end,
  //       organizationid: userData.autoFinanceOrganization.mainOrganizationId,
  //       roleName: userData.autoFinanceUser.roleName,
  //       startDate: start,
  //     };

  //     moduleApi
  //       .postData(
  //         moduleApi.withPage(
  //           userData.autoFinanceUser.roleName !== "CP_USER"
  //             ? apiCall.getWorkUploadCounts
  //             : apiCall.getAllocationSummaryForCpUsers,
  //           0,
  //           10
  //         ),
  //         userData.autoFinanceUser.roleName !== "CP_USER"
  //           ? payload
  //           : cpUsersPayload
  //       )
  //       .then((response) => {
  //         console.log("response", response.content);
  //         if (response) {
  //           if (response.totalElements > 5000) {
  //             setIsLoading2(false);
  //             return swal({
  //               title: "Download Report",
  //               text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //               icon: "warning",
  //               buttons: true,
  //               dangerMode: true,
  //             }).then((willDelete) => {
  //               if (willDelete) {
  //                 setIsLoading2(true);
  //                 getMgrFullResponseData(
  //                   Math.floor(response.totalElements / 200)
  //                 );
  //               }
  //             });
  //           }

  //           getMgrFullResponseData(Math.floor(response.totalElements / 200));
  //         } else {
  //           swal("Something Went Wrong, Please Try Later", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //   };

  //   const exeSummaryDownload = () => {
  //     setIsLoading3(true);
  //     var payload = {
  //       endDate: end,
  //       organizationid: userData.autoFinanceUser.organizationId,
  //       reportingOfficerId: reportingOfcrId,
  //       roleName: userData.autoFinanceUser.roleName,
  //       startDate: start,
  //     };

  //     moduleApi
  //       .postData(moduleApi.withPage(apiCall.getWorkUploadCounts, 0, 10), payload)
  //       .then((response) => {
  //         console.log("response", response.content);
  //         if (response) {
  //           if (response.totalElements > 5000) {
  //             setIsLoading3(false);
  //             return swal({
  //               title: "Download Report",
  //               text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //               icon: "warning",
  //               buttons: true,
  //               dangerMode: true,
  //             }).then((willDelete) => {
  //               if (willDelete) {
  //                 setIsLoading3(true);
  //                 getExeFullResponseData(
  //                   Math.floor(response.totalElements / 200)
  //                 );
  //               }
  //             });
  //           }

  //           getExeFullResponseData(Math.floor(response.totalElements / 200));
  //         } else {
  //           swal("Something Went Wrong, Please Try Later", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //   };

  //   async function getMgrFullResponseData(size) {
  //     var tempList = [];
  //     var mgrListData = [];

  //     for (let i = 0; i <= size; i++) {
  //       var payload = {
  //         endDate: end,
  //         organizationid: userData.autoFinanceUser.organizationId,
  //         reportingOfficerId:
  //           userData.autoFinanceUser.roleName === "MANAGER" ||
  //           userData.autoFinanceUser.roleName === "MANAGER_RA"
  //             ? userData.autoFinanceUser.userId
  //             : undefined,
  //         roleName: userData.autoFinanceUser.roleName,
  //         startDate: start,
  //       };

  //       let cpUsersPayload = {
  //         cpName: userData.autoFinanceOrganization.cpName,
  //         endDate: end,
  //         organizationid: userData.autoFinanceOrganization.mainOrganizationId,
  //         roleName: userData.autoFinanceUser.roleName,
  //         startDate: start,
  //       };

  //       await moduleApi
  //         .postData(
  //           moduleApi.withPage(
  //             userData.autoFinanceUser.roleName !== "CP_USER"
  //               ? apiCall.getWorkUploadCounts
  //               : apiCall.getAllocationSummaryForCpUsers,
  //             i,
  //             200
  //           ),
  //           userData.autoFinanceUser.roleName !== "CP_USER"
  //             ? payload
  //             : cpUsersPayload
  //         )
  //         .then((response) => {
  //           tempList.push(...response.content);
  //         });
  //     }
  //     console.log("forloop", tempList);

  //     tempList?.forEach((element) => {
  //       let data = {
  //         collectionVisitCount: element.collectionVisitCount,
  //         completed: element.completed,
  //         customerNotAvailable: element.customerNotAvailable,
  //         userId: element.userId,
  //         employeeId: element.executiveEmployeeId,
  //         mobileNumber: element.executiveMobileNumber,
  //         resolutionPercentage: element.resolutionPercentage
  //           ? element.resolutionPercentage.toFixed(0) + " %"
  //           : "0%",
  //         name: element.executiveName,
  //         uniqueVisitForCustomer: element.uniqueVisitForCustomer
  //           ? element.uniqueVisitForCustomer
  //           : "0",
  //         inprogress: element.inprogress,
  //         open: element.open,
  //         paid: element.paid,
  //         partial: element.partial,
  //         ptp: element.ptp,
  //         reportingOfficerId: element.reportingOfficerId,
  //         salesVisitCount: element.salesVisitCount,
  //         totalAllocationCounts: element.totalAllocationCounts,
  //       };
  //       mgrListData.push(data);
  //     });
  //     if (
  //       userData.autoFinanceUser.roleName === "MANAGER" ||
  //       userData.autoFinanceUser.roleName === "MANAGER_RA" ||
  //       userData.autoFinanceUser.roleName === "CP_USER"
  //     ) {
  //       setExeData(mgrListData);
  //     } else if (userData.autoFinanceUser.roleName === "ADMIN") {
  //       setMgrData(mgrListData);
  //     }
  //   }

  //   async function getExeFullResponseData(size) {
  //     var tempList = [];
  //     var exeListData = [];

  //     for (let i = 0; i <= size; i++) {
  //       var payLoad = {
  //         endDate: end,
  //         organizationid: userData.autoFinanceUser.organizationId,
  //         reportingOfficerId: reportingOfcrId,
  //         roleName: userData.autoFinanceUser.roleName,
  //         startDate: start,
  //       };
  //       await moduleApi
  //         .postData(
  //           moduleApi.withPage(apiCall.getWorkUploadCounts, i, 200),
  //           payLoad
  //         )
  //         .then((response) => {
  //           tempList.push(...response.content);
  //         });
  //     }
  //     console.log("forloop", tempList);

  //     tempList?.forEach((element) => {
  //       let data = {
  //         collectionVisitCount: element.collectionVisitCount,
  //         completed: element.completed,
  //         customerNotAvailable: element.customerNotAvailable,
  //         userId: element.userId,
  //         employeeId: element.executiveEmployeeId,
  //         mobileNumber: element.executiveMobileNumber,
  //         resolutionPercentage: element.resolutionPercentage
  //           ? element.resolutionPercentage.toFixed(0) + " %"
  //           : "0%",
  //         name: element.executiveName,
  //         uniqueVisitForCustomer: element.uniqueVisitForCustomer
  //           ? element.uniqueVisitForCustomer
  //           : "0",
  //         inprogress: element.inprogress,
  //         open: element.open,
  //         paid: element.paid,
  //         partial: element.partial,
  //         ptp: element.ptp,
  //         reportingOfficerId: element.reportingOfficerId,
  //         salesVisitCount: element.salesVisitCount,
  //         totalAllocationCounts: element.totalAllocationCounts,
  //       };
  //       exeListData.push(data);
  //     });
  //     setExeData(exeListData);
  //   }

  //   const handleExport = () => {
  //     let mgrTable = [
  //       {
  //         A: "Employee Id",
  //         B: "Employee Name",
  //         C: "Total Allocated",
  //         D: "Open",
  //         E: "In Progress",
  //         F: "Completed",
  //         G: "Resolution Percentage",
  //         H: "Unique Visits For Customer",
  //       },
  //     ];

  //     !isExecutive
  //       ? mgrData?.forEach((row) => {
  //           mgrTable.push({
  //             A: row.employeeId,
  //             B: row.name,
  //             C: row.totalAllocationCounts,
  //             D: row.open,
  //             E: row.inprogress,
  //             F: row.completed,
  //             G: row.resolutionPercentage,
  //             H: row.uniqueVisitForCustomer,
  //           });
  //         })
  //       : exeData?.forEach((row) => {
  //           mgrTable.push({
  //             A: row.employeeId,
  //             B: row.name,
  //             C: row.totalAllocationCounts,
  //             D: row.open,
  //             E: row.inprogress,
  //             F: row.completed,
  //             G: row.resolutionPercentage,
  //             H: row.uniqueVisitForCustomer,
  //           });
  //         });

  //     const finalData = [...mgrTable];

  //     console.log("finaldata", finalData);

  //     //create a new workbook
  //     const wb = XLSX.utils.book_new();

  //     const sheet = XLSX.utils.json_to_sheet(finalData, {
  //       skipHeader: true,
  //     });

  //     console.log("sheet", sheet);

  //     XLSX.utils.book_append_sheet(wb, sheet, "Counts");

  //     XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

  //     // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
  //     // worksheet["!cols"] = [ { wch: max_width } ];

  //     XLSX.writeFile(
  //       wb,
  //       "Clu_" +
  //         (isExecutive ? "Executive" : "Manager") +
  //         "_Summary_Counts_Report_" +
  //         new Date().toLocaleString("en-US") +
  //         ".xlsx"
  //     );

  //     ReactGA.event({
  //       category: "Summary Counts Report Downlaod",
  //       action:
  //         userData.autoFinanceOrganization.orgshortcode +
  //         "_" +
  //         (isExecutive ? "Executive" : "Manager") +
  //         "_Summary_Counts_Report_" +
  //         helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //         ".xlsx",
  //     });
  //   };

  return (
    <div style={{marginTop:'20px'}}>
      {/* <LoadingOverlay
        active={isLoading2}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      > */}
        <div>
          <LoadingOverlay
            active={isLoading}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            <div>
              {!isDefault ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* <div
                style={{
                  float: "right",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: "6px",
                  marginRight: "5px",
                }}
              >
                <div
                    style={{
                      float: "right",
                      width: "150px",
                      marginRight: "10px",
                      height: "38px",
                      marginLeft: "10px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      isSearchable={false}
                      onChange={(value) => {
                        value.value === "cm"
                          ? setIsDateFilter(false)
                          : setIsDateFilter(true);
                      }}
                      defaultValue={dateOptions[0]}
                      options={dateOptions}
                    />
                  </div>
                  {!isDateFilter && (
                    <div
                      style={{
                        float: "right",
                        // width: "120px",
                        marginRight: "5px",
                        // marginBottom: "20px",
                        // marginLeft: "15px",
                      }}
                    >
                      <Flatpickr
                        id="customonthPicker2"
                        className="form-control d-block"
                        style={{
                          width: "150px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                        }}
                        placeholder="Select Month"
                        onChange={(value) => {
                          setCustomDatesForGraph(value, true);
                        }}
                        options={{
                          defaultDate: new Date(),
                          maxDate: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0),
                          minDate: new Date().setMonth(
                            new Date().getMonth() - 6
                          ),

                          plugins: [
                            new monthSelectPlugin({
                              shorthand: true, //defaults to false
                              dateFormat: "F Y", //defaults to "F Y"
                              altFormat: "F Y", //defaults to "F Y"
                              theme: "light", // defaults to "light"
                            }),
                          ],
                        }}
                      />
                    </div>
                  )}
                  {isDateFilter && (
                    <div
                      style={{
                        float: "right",
                        // width: "120px",
                        marginRight: "5px",
                        // marginBottom: "20px",
                        // marginLeft: "15px",
                      }}
                    >
                      <Flatpickr
                        style={{
                          width: "150px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                        }}
                        id="customDatePicker2"
                        className="form-control d-block"
                        placeholder="Start & End Date"
                        onChange={(value) => {
                          setCustomDatesForGraph(value, false);
                        }}
                        options={{
                          mode: "range",
                          dateFormat: "d-M-y",
                          maxDate: new Date(),
                        }}
                      />
                    </div>
                  )}
                <Button
                  style={{
                    margin: "5px 10px 10px  ",
                    marginRight: "15px",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => mgrSummaryDownload()}
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                </Button>
              </div> */}

                  <div
                    style={{
                      background: "white",
                      margin: "10px",
                      marginTop: "-5px",
                      overflowX: "auto",
                    }}
                  >
                    <div style={{ overflow: "auto", maxHeight: "400px" }}>
                      <BootstrapTable
                        keyField="panNo"
                        data={managersCountsData}
                        columns={columns}
                      />
                    </div>
                  </div>

                  {managersCountsData.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                    }}
                  >
                    <PaginationComponent
                      totalItems={totalElements}
                      pageSize={10}
                      defaultActivePage={currPage + 1}
                      onSelect={onPageChange}
                    />
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* <div
                style={{
                  float: "right",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  // marginBottom: "6px",
                  marginRight: "5px",
                }}
              >
                <Button
                  style={{
                    // margin: "5px 10px 10px  ",
                    marginRight: "15px",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => exeSummaryDownload()}
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                </Button>
              </div> */}
              
                  <div
                    style={{
                      background: "white",
                      margin: "10px",
                      marginTop: "5px",
                      overflowX: "auto",
                    }}
                  >
                    <h4>Executive Summary</h4>
                    <BootstrapTable
                      keyField="id"
                      data={executivesCountsData}
                      columns={columns}
                    />
                  </div>

                  {executivesCountsData.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "0px",
                    }}
                  >
                    <PaginationComponent
                      totalItems={totalElements2}
                      pageSize={10}
                      defaultActivePage={currPage2 + 1}
                      onSelect={onPageChange2}
                    />
                  </div>
                </div>
              )}
            </div>
          </LoadingOverlay>
        </div>
      {/* </LoadingOverlay> */}
      <Modal className="fade" size="xl" show={basicModal2}>
        <Modal.Header>
          <Modal.Title>Executive Counts</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
            //   setIsExecutive(false);
              setBasicModal2(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}>
          <LoadingOverlay
            active={isLoading3}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <div
                style={{
                  float: "right",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  // marginBottom: "6px",
                  marginRight: "5px",
                }}
              >
                <Button
                  style={{
                    // margin: "5px 10px 10px  ",
                    marginRight: "15px",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => exeSummaryDownload()}
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                </Button>
              </div> */}
              <div
                style={{
                  background: "white",
                  margin: "10px",
                  marginTop: "15px",
                  overflowX: "auto",
                }}
              >
                <BootstrapTable
                  keyField="id"
                  data={executivesCountsData}
                  columns={columns}
                />
              </div>
            </div>
            {executivesCountsData.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "0px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements2}
                pageSize={10}
                defaultActivePage={currPage2 + 1}
                onSelect={onPageChange2}
              />
            </div>
          </LoadingOverlay>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DashboardAllocationSummaryList;
