import { SET_SALES_ALLOCATIONS_LIST, SET_IS_CALL_NOT_ACTIVE, SET_SALES_CALLS_COUNT } from "../actions/MySalesAllocationAction";

const initialState = {
  salesAllocationsList: [],
  isCallNotActive:false,
  salesCallsCount:{
    callConnectedCount: 0,
    callNotConnectedCount: 0,
    callsNotAttemped: 0,
    callsToBeDone: 0,
    callswithNoDispositionCount: 0,
    totalCalls: 0,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SALES_ALLOCATIONS_LIST:
      return { ...state, salesAllocationsList: action.payload };
    case SET_IS_CALL_NOT_ACTIVE:
      return { ...state, isCallNotActive: action.payload };
    case SET_SALES_CALLS_COUNT:
      return { ...state, salesCallsCount: action.payload };
    default:
      return state;
  }
};

export default reducer;
