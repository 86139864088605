import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Modal } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
// eslint-disable-next-line
import ReactExport from "react-data-export";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { LoadingOverlayComponent } from "../../CommonComponents/LoadingOverlay";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import "../fiallocations.css";
import { detailedSummaryColumns, summaryColumns } from "../TableColumns";
import NO_DATA_FOUND from "../../images/no-data-found.png";
import ReactGA from "react-ga";
import {
  convertToLocalDateTime,
  downloadAgencySummaryAllocations,
  downloadDetailedSummaryAllocations,
  downloadEmployeeSummaryAllocations,
  getStartAndEndDate,
} from "../utils";
const Summary = () => {
  const [allocationsSummary, setAllocationsSummary] = useState([]);
  const [detailedAllocationsSummary, setDetailedAllocationsSummary] = useState(
    []
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Employee");
  const [filtered, setFiltered] = useState([]);
  const [isDateFilter, setIsDateFilter] = useState(false);
  // eslint-disable-next-line
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const [showDetailedLoader, setDetailedLoader] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [startDateInfo, setStartDateInfo] = useState("");
  const [endDateInfo, setEndDateInfo] = useState("");
  const { SearchBar } = Search;
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const options = {
    hideSizePerPage: true,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    disablePageTitle: true,
  };
  const summaryOptions = [
    { label: "Employee", value: 1 },
    { label: "Agency", value: 2 },
  ];
  const roleName = userData.autoFinanceUser.roleName;
  // eslint-disable-next-line
  const ExcelFile = ReactExport.ExcelFile;
  // eslint-disable-next-line
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  useEffect(() => {
    ReactGA.pageview("Field Investigation Summary");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var date = new Date();
    let start = new Date(date.getFullYear(), date.getMonth(), 1);
    let end = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let startDate = convertToLocalDateTime(start, "DATE_YYYY_MM_DD");
    let endDate = convertToLocalDateTime(end, "DATE_YYYY_MM_DD");
    setStartDateInfo(startDate);
    setEndDateInfo(endDate);
    let requestPayload = {
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: roleName,
      managerId:
        roleName === "MANAGER" || "MANAGER_RA"
          ? userData.autoFinanceUser.userId
          : "",
      startDate: startDate,
      endDate: endDate,
    };
    setShowLoader(true);
    nodeApi
      .postData(apiCall.getFiAllocationsSummary, requestPayload)
      .then((response) => {
        if (response.success === true) {
          setShowLoader(false);
          if (response.data) {
            setAllocationsSummary(response.data);
            if (response.data.length > 0) {
              setNoDataFound(false);
              setFiltered(
                response.data.filter((item) => item.manager_emp_id != null)
              );
            } else {
              setNoDataFound(true);
            }
          }
        } else {
          toaster("error", "Something went wrong. Please try again later.");
          setShowLoader(false);
        }
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
        setShowLoader(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleOptionChange = (value) => {
    setDefaultDate(new Date());
    if (value.label === "Employee") {
      setSelectedOption(value.label);
      let res = allocationsSummary.filter(
        (item) => item.manager_emp_id != null
      );
      setFiltered(res);
    } else if (value.label === "Agency") {
      setSelectedOption(value.label);
      let res = allocationsSummary.filter((item) => item.agency_id != null);
      setFiltered(res);
    }
  };

  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  const handleDetailedView = (managerId) => {
    setShowModal(true);
    setDetailedLoader(true);
    setDetailedAllocationsSummary([]);
    let requestPayload = {
      managerId: managerId,
      roleName: roleName,
      organizationId: userData.autoFinanceUser.organizationId,
      startDate: startDateInfo,
      endDate: endDateInfo,
    };
    nodeApi
      .postData(apiCall.getFiAllocationsDetailedSummary, requestPayload)
      .then((response) => {
        if (response.success === true) {
          setDetailedAllocationsSummary(response.allocations);
          setDetailedLoader(false);
        } else {
          toaster("error", "Something went wrong. Please try again later.");
          setDetailedLoader(false);
        }
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
        setDetailedLoader(false);
      });
  };

  const getEpochToDate=(epochTime)=> {
    // Convert epoch time to a Date object
    const date = new Date(epochTime * 1000);
    // Convert from seconds to milliseconds// Get year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    // Construct the formatted date string in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const setCustomDatesForGraph = (value) => {
    let formatDates = getStartAndEndDate(value);
    setStartDateInfo(formatDates.startDate);
    setEndDateInfo(formatDates.endDate);
    let requestPayload = {
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: roleName,
      managerId:
        roleName === "MANAGER" || "MANAGER_RA"
          ? userData.autoFinanceUser.userId
          : "",
      startDate: formatDates.startDate,
      endDate: formatDates.endDate,
    };
    nodeApi
      .postData(apiCall.getFiAllocationsSummary + "/filter", requestPayload)
      .then((response) => {
        if (response.success === true) {
          if (response.data) {
            if (response.data.length > 0) {
              if (selectedOption === "Employee") {
                setFiltered(
                  response.data.filter((item) => item.manager_emp_id != null)
                );
              } else if (selectedOption === "Agency") {
                setFiltered(
                  response.data.filter((item) => item.agency_id != null)
                );
              } else {
                setFiltered([]);
              }
            } else {
              setFiltered([]);
            }
          }
        } else {
          toaster("error", "Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
      });
  };

  const setCustomDatesForGraph2 = (value) => {
    var start = getEpochToDate(Math.floor(new Date(value[0]).getTime() / 1000));
    var end =getEpochToDate(Math.floor(new Date(value[1]).getTime() / 1000 + 86399));
    console.log('start-', start, 'end-', end);
    setStartDateInfo(start);
    setEndDateInfo(end);
    if(start!=='NaN-NaN-NaN'&& end!=='NaN-NaN-NaN'){
    let requestPayload = {
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: roleName,
      managerId:
        roleName === "MANAGER" || "MANAGER_RA"
          ? userData.autoFinanceUser.userId
          : "",
      startDate: start,
      endDate: end,
    };
    nodeApi
      .postData(apiCall.getFiAllocationsSummary + "/filter", requestPayload)
      .then((response) => {
        if (response.success === true) {
          if (response.data) {
            if (response.data.length > 0) {
              if (selectedOption === "Employee") {
                setFiltered(
                  response.data.filter((item) => item.manager_emp_id != null)
                );
              } else if (selectedOption === "Agency") {
                setFiltered(
                  response.data.filter((item) => item.agency_id != null)
                );
              } else {
                setFiltered([]);
              }
            } else {
              setFiltered([]);
            }
          }
        } else {
          toaster("error", "Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
      });}

  };
  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "right", marginBottom: 20 }}
      >
        {roleName === "ADMIN" && (
          <Select
            isSearchable={false}
            options={summaryOptions}
            className="select-dropdown"
            onChange={handleOptionChange}
            defaultValue={{ label: "Employee", value: 1 }}
          />
        )}
        <ExcelFile
          element={
            <Button
              className="me-2 agency-button"
              variant="primary btn-rounded"
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Download
            </Button>
          }
        >
          <ExcelSheet
            dataSet={
              selectedOption === "Employee"
                ? downloadEmployeeSummaryAllocations(filtered)
                : downloadAgencySummaryAllocations(filtered)
            }
            name="Allocation_Summary"
          />
        </ExcelFile>
      </div>
      <LoadingOverlayComponent showLoader={showLoader}>
        <div className="table-content">
          <ToolkitProvider
            keyField="id"
            data={filtered}
            columns={summaryColumns({ handleDetailedView }, selectedOption)}
            search
          >
            {(props) => (
              <>
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    marginTop: "-65px",
                  }}
                >
                  <SearchBar
                    {...props.searchProps}
                    placeholder={"Search By Employee ID / Employee Name"}
                    style={{
                      width: 300,
                      height: 40,
                      borderRadius: 25,
                      borderColor: "#a0a0a0",
                      borderWidth: 1,
                      boxShadow: "1px 1px 2px #3a353b",
                      paddingLeft: 10,
                      backgroundColor: "white",
                      marginRight: 10,
                    }}
                  />
                  <div
                    style={{
                      float: "right",
                      width: "150px",
                      marginRight: "10px",
                      height: "38px",
                      marginLeft: "10px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      isSearchable={false}
                      onChange={(value) => {
                        value.value === "cm"
                          ? setIsDateFilter(false)
                          : setIsDateFilter(true);
                      }}
                      defaultValue={dateOptions[0]}
                      options={dateOptions}
                    />
                  </div>
                  {!isDateFilter && (
                    <div
                      style={{
                        float: "right",
                        // width: "120px",
                        marginRight: "5px",
                        // marginBottom: "20px",
                        // marginLeft: "15px",
                      }}
                    >
                      <Flatpickr
                        id="customonthPicker2"
                        className="form-control d-block"
                        style={{
                          width: "150px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                        }}
                        placeholder="Select Month"
                        onChange={(value) => {
                          setCustomDatesForGraph(value)
                        }}
                        options={{
                          defaultDate: new Date(),
                          maxDate: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0),
                          minDate: new Date().setMonth(
                            new Date().getMonth() - 6
                          ),

                          plugins: [
                            new monthSelectPlugin({
                              shorthand: true, //defaults to false
                              dateFormat: "F Y", //defaults to "F Y"
                              altFormat: "F Y", //defaults to "F Y"
                              theme: "light", // defaults to "light"
                            }),
                          ],
                        }}
                      />
                    </div>
                  )}
                  {isDateFilter && (
                    <div
                      style={{
                        float: "right",
                        // width: "120px",
                        marginRight: "5px",
                        // marginBottom: "20px",
                        // marginLeft: "15px",
                      }}
                    >
                      <Flatpickr
                        style={{
                          width: "150px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                        }}
                        id="customDatePicker2"
                        className="form-control d-block"
                        placeholder="Start & End Date"
                        onChange={(value) => {
                          setCustomDatesForGraph2(value);
                        }}
                        options={{
                          mode: "range",
                          dateFormat: "d-M-y",
                          maxDate: new Date(),
                        }}
                      />
                    </div>
                  )}
                  {/* <Flatpickr
                    className="form-control d-block"
                    onChange={(value) => {
                      setCustomDatesForGraph(value);
                    }}
                    style={{
                      width: "140px",
                      height: "40px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      textAlign: "center",
                    }}
                    placeholder="Select Month"
                    options={{
                      defaultDate: defaultDate,
                      maxDate: new Date(),
                      minDate: new Date().setMonth(new Date().getMonth() - 6),
                      plugins: [
                        new monthSelectPlugin({
                          shorthand: true, //defaults to false
                          dateFormat: "F Y", //defaults to "F Y"
                          altFormat: "F Y", //defaults to "F Y"
                          theme: "light", // defaults to "light"
                        }),
                      ],
                    }}
                  /> */}
                </div>
                <div className="table-container">
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
          {noDataFound && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
        </div>
      </LoadingOverlayComponent>

      <Modal className="fade" size="xl" show={showModal}>
        <Modal.Header>
          <Modal.Title>Employees Count</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}>
          <LoadingOverlayComponent showLoader={showDetailedLoader}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  float: "right",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  // marginBottom: "6px",
                  marginRight: "5px",
                }}
              >
                <ExcelFile
                  element={
                    <Button
                      className="me-2 agency-button"
                      variant="primary btn-rounded"
                    >
                      <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                    </Button>
                  }
                >
                  <ExcelSheet
                    dataSet={downloadDetailedSummaryAllocations(
                      detailedAllocationsSummary
                    )}
                    name="Employees_Allocations"
                  />
                </ExcelFile>
              </div>
              <div
                style={{
                  background: "white",
                  margin: "10px",
                  marginTop: "15px",
                  overflowX: "auto",
                }}
              >
                {/* <BootstrapTable
                  keyField="id"
                  data={detailedAllocationsSummary}
                  columns={detailedSummaryColumns}
                /> */}
                <ToolkitProvider
                  keyField="id"
                  data={detailedAllocationsSummary}
                  columns={detailedSummaryColumns}
                  search
                >
                  {(props) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          position: "absolute",
                          marginTop: "-65px",
                        }}
                      >
                        <SearchBar
                          {...props.searchProps}
                          placeholder={"Search By Employee ID / Employee Name"}
                          style={{
                            width: 300,
                            height: 40,
                            borderRadius: 25,
                            borderColor: "#a0a0a0",
                            borderWidth: 1,
                            boxShadow: "1px 1px 2px #3a353b",
                            paddingLeft: 10,
                            backgroundColor: "white",
                            marginRight: 10,
                          }}
                        />
                      </div>
                      <div className="table-container">
                        <BootstrapTable
                          {...props.baseProps}
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </LoadingOverlayComponent>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Summary;
