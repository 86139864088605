import React from "react";
import { Nav, Tab } from "react-bootstrap";
// import { getTokenInLocalStorage } from "../services/AuthService";
import IncentiveMgmt from "./IncentiveMgmt";

const MainIncentiveMgmt = () => {
//   let userDetails = getTokenInLocalStorage();
  return (
    <div className="card" style={{ padding: "10px" }}>
      {/* {userDetails?.autoFinanceOrganization?.orgshortcode?.toLowerCase()==='cll'&&
      <div
        style={{
          marginRight: "20px",
          marginBottom: "10px",
          float: "right",
        }}
      >
        <Button
          className=""
          variant="primary btn-rounded"
          style={{
            borderRadius: "5px",
            float: "right",
          }}
          onClick={() => {
            window.open(
              "https://paymentsqaapp.clucloud.com/?orgId=" +
                userDetails?.autoFinanceOrganization?.orgshortcode
            );
          }}
        >
          <i className="fas fa-external-link-alt" />
          &nbsp; Request Payment
        </Button>
      </div>} */}
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="collections">
          <Nav as="ul" className="nav-tabs-custom nav-justified">
            <Nav.Item as="li" key="0">
              <Nav.Link eventKey="sales">
                <b> Sales </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="1">
              <Nav.Link eventKey="collections">
                <b> Collections </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="2">
              <Nav.Link eventKey="fieldInvestigation">
                <b> Field Investigations </b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="sales" key="0" mountOnEnter unmountOnExit>
              <IncentiveMgmt visitType={"Sales"} />
            </Tab.Pane>

            <Tab.Pane eventKey="collections" key="1" mountOnEnter unmountOnExit>
              <IncentiveMgmt visitType={"Collections"} isActiveIncentives={false}/>
            </Tab.Pane>
            <Tab.Pane
              eventKey="fieldInvestigation"
              key="2"
              mountOnEnter
              unmountOnExit
            >
              <IncentiveMgmt visitType={"FieldInvestigation"} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default MainIncentiveMgmt;
