import {
  SET_IS_LOADING,
  SET_IS_LOADING2,
  SET_BASIC_MODAL_FOR_PAYMENT_LINKS,
  SET_PAYMENT_LINKS_BY_ALLOCATION_ID,
  SET_ADD_REMARKS_BASIC_MODAL,
  SET_CALL_REPORT_MODAL,
  SET_MOBILE_NO,
  SET_ALLOCATION_DETAILS,
  SET_ALLOCATION_DETAILS_MODAL,
  SET_BASIC_MODAL_FOR_SEND_PAYMENT_LINK,
  SET_REMARKS,
  SET_IS_MINUTES_SELECTED,
  SET_IS_HOURS_SELECTED,
  SET_IS_DAYS_SELECTED,
  SET_TOTAL_ELEMENTS,
  SET_CURR_PAGE,
  SET_REQ_PAYMENTS_LIST,
  SET_PAYMENTS_DOWNLOAD_LIST,
  SET_ADD_REMARKS_ALLOCATION_ID,
  SET_DATA_FOR_SEND_CUSTOMER_LINK,
  SET_CAMPAIGNS,
  SET_SELECTED_VALUE,
  SET_SELECTED_STATUS,
  SET_SORT_INFO,
  SET_OBJECT,
  SET_ALLOCATIONS_LIST,
  SET_FILTERED_DATA,
  SET_TEMP_DATA,
  SET_SKIP_INDEX
} from "../actions/MyAllocationActions";

const initialState = {
  isLoading: false,
  isLoading2: false,
  basicModalForPaymentLinks: false,
  paymentLinksByAllocationId: [],
  addRemarksBasicModal: false,
  callReportModal: false,
  mobileNo: null,
  allocationDetails: [],
  allocationDetailsModal: false,
  basicModalForSendPaymentLink: false,
  remarks: [],
  isMinutesSelected: true,
  isHoursSelected: false,
  isDaysSelected: false,
  totalElements: 1,
  currPage: 0,
  reqPaymentsList: [],
  paymentsDownloadList: null,
  addRemarksAllocationId: "",
  dataForSendCustomerLink: {},
  campaigns: [],
  selectedValue: false,
  selectedStatus: "",
  sortInfo: [
    {
      direction: "ASC",
      key: "customerName",
    },
  ],
  object: [],
  allocationsList: [],
  filteredData: [],
  tempData: [],
  skipIndex: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_IS_LOADING2:
      return { ...state, isLoading2: action.payload };
    case SET_BASIC_MODAL_FOR_PAYMENT_LINKS:
      return { ...state, basicModalForPaymentLinks: action.payload };
    case SET_PAYMENT_LINKS_BY_ALLOCATION_ID:
      return { ...state, paymentLinksByAllocationId: action.payload };
    case SET_ADD_REMARKS_BASIC_MODAL:
      return { ...state, addRemarksBasicModal: action.payload };
    case SET_CALL_REPORT_MODAL:
      return { ...state, callReportModal: action.payload };
    case SET_MOBILE_NO:
      return { ...state, mobileNo: action.payload };
    case SET_ALLOCATION_DETAILS:
      return { ...state, allocationDetails: action.payload };
    case SET_ALLOCATION_DETAILS_MODAL:
      return { ...state, allocationDetailsModal: action.payload };
    case SET_BASIC_MODAL_FOR_SEND_PAYMENT_LINK:
      return { ...state, basicModalForSendPaymentLink: action.payload };
    case SET_REMARKS:
      return { ...state, remarks: action.payload };
    case SET_IS_MINUTES_SELECTED:
      return { ...state, isMinutesSelected: action.payload };
    case SET_IS_HOURS_SELECTED:
      return { ...state, isHoursSelected: action.payload };
    case SET_IS_DAYS_SELECTED:
      return { ...state, isDaysSelected: action.payload };
    case SET_TOTAL_ELEMENTS:
      return { ...state, totalElements: action.payload };
    case SET_CURR_PAGE:
      return { ...state, currPage: action.payload };
    case SET_REQ_PAYMENTS_LIST:
      return { ...state, reqPaymentsList: action.payload };
    case SET_PAYMENTS_DOWNLOAD_LIST:
      return { ...state, paymentsDownloadList: action.payload };
    case SET_ADD_REMARKS_ALLOCATION_ID:
      return { ...state, addRemarksAllocationId: action.payload };
    case SET_DATA_FOR_SEND_CUSTOMER_LINK:
      return { ...state, dataForSendCustomerLink: action.payload };
    case SET_CAMPAIGNS:
      return { ...state, campaigns: action.payload };
    case SET_SELECTED_VALUE:
      return { ...state, selectedValue: action.payload };
    case SET_SELECTED_STATUS:
      return { ...state, selectedStatus: action.payload };
    case SET_SORT_INFO:
      return { ...state, sortInfo: action.payload };
    case SET_OBJECT:
      return { ...state, object: action.payload };
    case SET_ALLOCATIONS_LIST:
      return { ...state, allocationsList: action.payload };
    case SET_FILTERED_DATA:
      return { ...state, filteredData: action.payload };
    case SET_TEMP_DATA:
      return { ...state, tempData: action.payload };
    case SET_SKIP_INDEX:
      return { ...state, skipIndex: action.payload };
    default:
      return state;
  }
};

export default reducer;
