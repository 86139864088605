import React, { useEffect, useState } from "react";
import { Row, Button } from "react-bootstrap";
import { camelize } from "../DB/utils";
import { ref,child, get } from "firebase/database";
import { database } from "../CommonComponents/Firestore";
import { secondsToHms } from "../CommonComponents/Helper";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess } from "../services/AuthService";
import swal from "sweetalert";

const CallDashboard2 = () => {
  const [agentStatuses, setAgentStatuses] = useState([]);
  // const [isFocused, setIsFocused] = useState(true);
  const [agentStatusCounts, setAgentStatusCounts] = useState([]);
  // const [isLiveCountUpdation, setIsLiveCountUpdation] = useState(false);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );

  useEffect(() => {
    loadAgentStatus();
    // setIsLiveCountUpdation(true);
    // return () => {
    //   setIsLiveCountUpdation(false);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const intervalId =
    //   isFocused === true && setInterval(loadAgentStatus, 10000);
    // const handleVisibilityChange = () => {
    //   if (document.visibilityState === "visible") {
    //     setIsFocused(true); // Start calling when tab becomes visible
    //   } else {
    //     setIsFocused(false); // Stop calling when tab becomes hidden
    //   }
    // };
    let intervalId = null;
    // if (isLiveCountUpdation === true) {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        intervalId = setInterval(loadAgentStatus, 10000);
      } else {
        clearInterval(intervalId);
      }
    });
    // }

    // document.addEventListener("visibilitychange", handleVisibilityChange); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => {
      clearInterval(intervalId);
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          intervalId = setInterval(loadAgentStatus, 10000);
        } else {
          clearInterval(intervalId);
        }
      });
      // document.addEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   let intervalId = null;
  //   const startCalling = () => {
  //     intervalId = setInterval(loadAgentStatus(), 10000); // Call method every 10 seconds
  //   };

  //   const stopCalling = () => {
  //     clearInterval(intervalId); // Clear interval
  //   };

  //   const handleFocus = () => {
  //     startCalling(); // Start calling when window is focused
  //   };

  //   const handleBlur = () => {
  //     stopCalling(); // Stop calling when window loses focus
  //   };

  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "visible") {
  //       startCalling(); // Start calling when tab becomes visible
  //     } else {
  //       stopCalling(); // Stop calling when tab becomes hidden
  //     }
  //   };

  //   window.addEventListener("focus", handleFocus);
  //   window.addEventListener("blur", handleBlur);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     window.removeEventListener("focus", handleFocus);
  //     window.removeEventListener("blur", handleBlur);
  //     document.addEventListener("visibilitychange", handleVisibilityChange);
  //     stopCalling(); // Stop interval when component unmounts
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const loadAgentStatus = () => {
    // let listData = [];
    // setAgentStatuses([]);
    // const db = getDatabase();
    // const starCountRef = ref(
    //   db,
    //   "agentLiveStatus/" + loggedInUser?.autoFinanceUser?.organizationId
    // );
    // // console.log("starCountRef ", starCountRef);
    // onValue(starCountRef, (snapshot) => {
    const dbRef = ref(database);
    get(
      child(
        dbRef,
        "agentLiveStatus/" + loggedInUser?.autoFinanceUser?.organizationId
      )
    ).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // console.log("dataFromDB", data);
        const uniqueObjectsList = Object.values(data).reduce(
          (accumulator, currentValue) => {
            const userId = currentValue.userId;
            if (!accumulator.some((obj) => obj.userId === userId)) {
              accumulator.push(currentValue);
            }
            return accumulator;
          },
          []
        );

        const statusCounts = {};

        uniqueObjectsList.forEach((item) => {
          const status = item.status;
          if (statusCounts.hasOwnProperty(status)) {
            statusCounts[status] += 1;
          } else {
            statusCounts[status] = 1;
          }
        });

        const statusCountsArray = Object.keys(statusCounts).map((status) => ({
          status: status,
          count: statusCounts[status],
        }));

        const currentTimeInSeconds = Math.floor(Date.now() / 1000);

        for (const item of uniqueObjectsList) {
          const createdTimeInSeconds = item.createdTime;
          const statusTime = currentTimeInSeconds - createdTimeInSeconds;
          item.statusTime = secondsToHms(statusTime);
        }

        var tempArray = {
          firstTableData: [],
          secondTableData: [],
        };

        const halfLength = Math.ceil(uniqueObjectsList?.length / 2);
        tempArray.firstTableData = uniqueObjectsList.slice(0, halfLength);
        tempArray.secondTableData = uniqueObjectsList.slice(halfLength);

        // If the length of data is less than 5, store all data in firstTableData
        if (uniqueObjectsList.length <= 5) {
          tempArray.firstTableData = uniqueObjectsList;
          tempArray.secondTableData = [];
        }

        // console.log(statusCountsArray);
        // console.log(uniqueObjectsList);
        setAgentStatusCounts(statusCountsArray);
        setAgentStatuses(tempArray);
      }
    });
  };

  const updateRotatingNumbers = () => {
    moduleApi
      .putData(
        apiCall.updateRotateNumbers +
          loggedInUser?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        // console.log(response);
        if (response) {
          if (response.message) {
            swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.Message === "Caller Ids Shuffeled suceesfuly") {
            swal("Rotation of Calling Numbers Is Completed", {
              icon: "success",
              dangerMode: false,
            });
          }
        } else {
          swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getColoredStatuses = (status) => {
    switch (status) {
      case "Idle":
        return "blue";
      case "Ringing":
        return "green";
      case "Dailing":
        return "green";
      case "Disposition":
        return "blue";
      case "Talking":
        return "green";
      case "Quality":
        return "brown";
      case "Offline":
        return "red";
      case "Online":
        return "green";

      default:
        return "black";
    }
  };

  return (
    <div>
      <Row style={{ marginLeft: "17px", marginTop: "-10px" }}>
        {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-end",
            }}
          > */}
        {hasAccess(61) && (
          <Button
            style={{
              width: "fit-content",
              // margin: "5px 5px ",
              // marginRight: "15px",
              marginBottom: "5px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => updateRotatingNumbers()}
            id="rotateNumbers"
          >
            <i className="fas fa-redo-alt" />
            &nbsp; Rotate Calling Numbers
          </Button>
        )}
        {/* </div> */}
        {agentStatusCounts?.map((item) => {
          return (
            <div
              style={{
                width: "fit-content",
                padding: "5px",
                marginRight: "10px",
                fontSize: "smaller",
                alignSelf: "center",
              }}
            >
              <span>
                <span
                  style={{
                    color: getColoredStatuses(camelize(item.status)),
                    fontWeight: "bold",
                  }}
                >
                  {item.status}
                </span>
                &nbsp;:&nbsp;
                <span style={{ fontWeight: "bold" }}> {item.count}</span>
              </span>
            </div>
          );
        })}
      </Row>
      <Row>
        <div
          className="card"
          style={{
            marginRight: "5px",
            padding: "5px",
            width: "fit-content",
            overflowY: "auto",
            maxHeight: "550px",
          }}
        >
          <ul style={{ listStyleType: "none", padding: 0 }}>
            <li
              style={{
                marginBottom: "5px",
                fontWeight: "bold",
                display: "flex",
                color: "#483285",
              }}
            >
              <span
                style={{
                  width: "100px",
                  textAlign: "center",
                }}
              >
                Emp Name
              </span>
              <span
                style={{
                  width: "100px",
                  textAlign: "center",
                }}
              >
                Emp Id
              </span>
              <span
                style={{
                  width: "100px",
                  textAlign: "center",
                }}
              >
                Status
              </span>
              <span
                style={{
                  width: "100px",
                  textAlign: "center",
                }}
              >
                Duration
              </span>
              <span
                style={{
                  width: "100px",
                  textAlign: "center",
                }}
              >
                Calls Today
              </span>
            </li>
            <hr style={{ margin: "5px" }}></hr>
            {agentStatuses?.firstTableData?.map((ele) => (
              <li key={ele.id} style={{ display: "flex", fontWeight: "bold" }}>
                <span
                  style={{
                    width: "100px",
                    verticalAlign: "super",
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  {ele.empName ? ele.empName : "-"}
                </span>
                <span
                  style={{
                    width: "100px",
                    verticalAlign: "super",
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  {ele.empId ? ele.empId : "-"}
                </span>
                <span
                  style={{
                    width: "100px",
                    verticalAlign: "super",
                    textAlign: "center",
                    alignSelf: "center",
                    color: getColoredStatuses(ele?.status),
                  }}
                >
                  {ele.status ? ele.status : "-"}
                </span>
                <span
                  style={{
                    width: "100px",
                    textAlign: "center",
                    alignSelf: "center",

                    fontSize: "smaller",
                  }}
                >
                  {ele.statusTime ? ele.statusTime : "-"}
                </span>
                <span
                  style={{
                    width: "100px",
                    verticalAlign: "super",
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  {ele.todayCallsCount ? ele.todayCallsCount : 0}
                </span>
              </li>
            ))}
          </ul>
        </div>
        {agentStatuses?.secondTableData?.length > 0 && (
          <div
            className="card"
            style={{
              marginLeft: "5px",
              padding: "5px",
              width: "fit-content",
              overflowY: "auto",
              maxHeight: "550px",
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li
                style={{
                  marginBottom: "5px",
                  fontWeight: "bold",
                  display: "flex",
                  color: "#483285",
                }}
              >
                <span
                  style={{
                    width: "100px",
                    textAlign: "center",
                  }}
                >
                  Emp Name
                </span>
                <span
                  style={{
                    width: "100px",
                    textAlign: "center",
                  }}
                >
                  Emp Id
                </span>
                <span
                  style={{
                    width: "100px",
                    textAlign: "center",
                  }}
                >
                  Status
                </span>
                <span
                  style={{
                    width: "100px",
                    textAlign: "center",
                  }}
                >
                  Duration
                </span>
                <span
                  style={{
                    width: "100px",
                    textAlign: "center",
                  }}
                >
                  Calls Today
                </span>
              </li>
              <hr style={{ margin: "5px" }}></hr>
              {agentStatuses?.secondTableData?.map((ele) => (
                <li
                  key={ele.id}
                  style={{ display: "flex", fontWeight: "bold" }}
                >
                  <span
                    style={{
                      width: "100px",
                      verticalAlign: "super",
                      textAlign: "center",
                      alignSelf: "center",
                    }}
                  >
                    {ele.empName ? ele.empName : "-"}
                  </span>
                  <span
                    style={{
                      width: "100px",
                      verticalAlign: "super",
                      textAlign: "center",
                      alignSelf: "center",
                    }}
                  >
                    {ele.empId ? ele.empId : "-"}
                  </span>
                  <span
                    style={{
                      width: "100px",
                      verticalAlign: "super",
                      textAlign: "center",
                      alignSelf: "center",
                      color: getColoredStatuses(ele?.status),
                    }}
                  >
                    {ele.status ? ele.status : "-"}
                  </span>
                  <span
                    style={{
                      width: "100px",
                      textAlign: "center",
                      alignSelf: "center",

                      fontSize: "smaller",
                    }}
                  >
                    {ele.statusTime ? ele.statusTime : "-"}
                  </span>
                  <span
                    style={{
                      width: "100px",
                      verticalAlign: "super",
                      textAlign: "center",
                      alignSelf: "center",
                    }}
                  >
                    {ele.todayCallsCount ? ele.todayCallsCount : 0}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Row>

      {/* <Row>
        <Col>
          <div className="card">
            <div style={{ margin: "10px 10px" }}>
              <h6>Users Live Status</h6>
              <Table responsive hover>
                <thead
                  style={{
                    backgroundColor: "#F3F0F9",
                    top: "1px",
                    position: "sticky",
                  }}
                >
                  <tr
                    style={{
                      verticalAlign: "middle",
                      textAlignLast: "center",
                      fontWeight: "bold",
                      fontSize: "9px",
                    }}
                  >
                    <td>Emp Name</td>
                    <td>Emp Id</td>
                    <td>Status</td>
                    <td>Duration</td>
                    <td>Calls Today</td>
                  </tr>
                </thead>
                <tbody style={{ textAlignLast: "center" }}>
                  {agentStatuses?.firstTableData?.map((ele) => {
                    return (
                      <tr style={{ textAlignLast: "center", fontSize: "10px" }}>
                        <td>{ele.empName ? ele.empName : "-"}</td>
                        <td>{ele.empId ? ele.empId : "-"}</td>

                        <td
                          style={{
                            color: getColoredStatuses(ele.status),
                            fontWeight: "bold",
                          }}
                        >
                          {ele.status ? camelize(ele.status) : "-"}
                        </td>
                        <td>{ele.statusTime ? ele.statusTime : "-"}</td>
                        <td>{ele.todayCallsCount ? ele.todayCallsCount : 0}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
        {agentStatuses?.secondTableData?.length > 0 && (
          <Col>
            <div className="card">
              <div style={{ margin: "10px 10px" }}>
                <Table responsive hover>
                  <thead
                    style={{
                      backgroundColor: "#F3F0F9",
                      top: "1px",
                      position: "sticky",
                    }}
                  >
                    <tr
                      style={{
                        verticalAlign: "middle",
                        textAlignLast: "center",
                        fontWeight: "bold",
                        fontSize: "9px",
                      }}
                    >
                      <td>Emp Name</td>
                      <td>Emp Id</td>
                      <td>Status</td>
                      <td>Duration</td>
                      <td>Calls Today</td>
                    </tr>
                  </thead>
                  <tbody style={{ textAlignLast: "center" }}>
                    {agentStatuses?.secondTableData?.map((ele) => {
                      return (
                        <tr
                          style={{ textAlignLast: "center", fontSize: "10px" }}
                        >
                          <td>{ele.empName ? ele.empName : "-"}</td>
                          <td>{ele.empId ? ele.empId : "-"}</td>

                          <td
                            style={{
                              color: getColoredStatuses(ele.status),
                              fontWeight: "bold",
                            }}
                          >
                            {ele.status ? camelize(ele.status) : "-"}
                          </td>
                          <td>{ele.statusTime ? ele.statusTime : "-"}</td>
                          <td>
                            {ele.todayCallsCount ? ele.todayCallsCount : 0}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        )}
        <Col>
          <div className="card">
            <div style={{ margin: "10px 20px" }}>
              <h3>Users Live Status Counts</h3>

              <Table responsive hover bordered>
                <thead
                  style={{
                    backgroundColor: "#F3F0F9",
                    top: "1px",
                    position: "sticky",
                  }}
                >
                  <tr
                    style={{
                      verticalAlign: "middle",
                      textAlignLast: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <td>Status</td>
                    <td>Count</td>
                  </tr>
                </thead>
                <tbody style={{ textAlignLast: "center" }}>
                  {agentStatusCounts?.map((ele) => {
                    return (
                      <tr style={{ textAlignLast: "center" }}>
                        <td
                          style={{
                            color: getColoredStatuses(camelize(ele.status)),
                            fontWeight: "bold",
                          }}
                        >
                          {ele.status ? camelize(ele.status) : "-"}
                        </td>
                        <td>{ele.count ? ele.count : "-"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row> */}
      <span style={{ fontSize: "10px", marginLeft: "17px" }}>
        <b>Note:</b> For Every 10 Seconds, Data will be Refreshed
      </span>
    </div>
  );
};

export default CallDashboard2;
