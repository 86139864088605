import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import ApprovalForm from "./ApprovalForm";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import FiAllocationPdfReport from "./FiAllocationPdfReport";
import html2pdf from "html2pdf.js";
import { convertToLocalDateTime, getFileKeyName } from "../utils";
import swal from "sweetalert";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "../../CommonComponents/TextField";
import * as moduleApi from "../../rest/moduleApi";
import LoadingOverlay from "react-loading-overlay";
import OfficeFiAllocationPdfReport from "./OfficeFiAllocationPdfReports";
import { EpochToDate } from "../../CommonComponents/Helper";
import { getTokenInLocalStorage } from "../../services/AuthService";

function DetailedAllocationModal(props) {
  const {
    detailedViewModal,
    setDetailedViewModal,
    selectedRow,
    type,
    getFiAllocationsOfEmployees,
    getFiAllocationsOfAgencies,
    loadData,
  } = props;
  const [allocationsDetails, setAllocationDetails] = useState([]);
  const [agencyRemarks, setAgencyRemarks] = useState([]);
  const [employeeVisitsData, setEmployeeVisitsData] = useState([]);
  const [dedupeSignatureUrl, setDedupeSignatureUrl] = useState("");
  const [imageOneUrl, setImageOneUrl] = useState("");
  const [imageTwoUrl, setImageTwoUrl] = useState("");
  const [imageThreeUrl, setImageThreeUrl] = useState("");
  const [imageFourUrl, setImageFourUrl] = useState("");
  const [imageFiveUrl, setImageFiveUrl] = useState("");
  const [showApprovalForm, setShowApprovalForm] = useState(false);
  const [loanModal, setLoanModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    nodeApi
      .getData(apiCall.getFiAllocationPdfData + "/" + selectedRow.id)
      .then((response) => {
        setAllocationDetails(response.allocationDetails);
        if (type === "EMPLOYEES" && response.allocationDetails.length > 0) {
          downloadImages(response.allocationDetails);
          let visitId =
            response.allocationDetails &&
            response.allocationDetails[0].field_investigation_visits_id;
          if (visitId !== null) {
            nodeApi
              .getData(
                apiCall.getFiAllocationPdfData + "/employeeVisits/" + visitId
              )
              .then((response) => {
                setEmployeeVisitsData(response.employeeVisits);
              })
              .catch((error) => {
                toaster(
                  "error",
                  "Something went wrong. Please try again later."
                );
              });
          }
        }
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
      });
    nodeApi
      .getData(
        apiCall.getFiAllocationPdfData + "/agencyRemarks/" + selectedRow.id
      )
      .then((response) => {
        setAgencyRemarks(response.agencyRemarks);
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
      });
    // eslint-disable-next-line
  }, []);

  const downloadImages = (allocationDetails) => {
    if (allocationDetails[0].dedupe_signature_image_url) {
      let requestPayload = {
        fileKeyName: getFileKeyName(
          allocationDetails[0].dedupe_signature_image_url
        ),
      };
      nodeApi
        .postData(apiCall.downloadFromS3, requestPayload)
        .then((response) => {
          setDedupeSignatureUrl(response.url);
        });
    }
    if (allocationDetails[0].image_1) {
      let requestPayload = {
        fileKeyName: getFileKeyName(allocationDetails[0].image_1),
      };
      nodeApi
        .postData(apiCall.downloadFromS3, requestPayload)
        .then((response) => {
          setImageOneUrl(response.url);
        });
    }
    if (allocationDetails[0].image_2) {
      let requestPayload = {
        fileKeyName: getFileKeyName(allocationDetails[0].image_2),
      };
      nodeApi
        .postData(apiCall.downloadFromS3, requestPayload)
        .then((response) => {
          setImageTwoUrl(response.url);
        });
    }
    if (allocationDetails[0].image_3) {
      let requestPayload = {
        fileKeyName: getFileKeyName(allocationDetails[0].image_3),
      };
      nodeApi
        .postData(apiCall.downloadFromS3, requestPayload)
        .then((response) => {
          setImageThreeUrl(response.url);
        });
    }
    if (allocationDetails[0].image_4) {
      let requestPayload = {
        fileKeyName: getFileKeyName(allocationDetails[0].image_4),
      };
      nodeApi
        .postData(apiCall.downloadFromS3, requestPayload)
        .then((response) => {
          setImageFourUrl(response.url);
        });
    }
    if (allocationDetails[0].image_5) {
      let requestPayload = {
        fileKeyName: getFileKeyName(allocationDetails[0].image_5),
      };
      nodeApi
        .postData(apiCall.downloadFromS3, requestPayload)
        .then((response) => {
          setImageFiveUrl(response.url);
        });
    }
  };
  const createPdf = async () => {
    setIsLoading(true);
    var element = document.getElementById("pdf").innerHTML;
    var opt = {
      margin: 1,
      filename:
      (selectedRow?.fi_type?.toString().toLowerCase() === "current office" || selectedRow?.fi_type?.toString().toLowerCase() === "other office")
          ? "Fi_Office_Allocation_Report_" +
            EpochToDate(Math.floor(new Date() / 1000)) +
            "_.pdf"
          : "Fi_Residence_Allocation_Report_" +
            EpochToDate(Math.floor(new Date() / 1000)) +
            "_.pdf",
      image: { type: ["jpeg", "png"], quality: 0.98 },
      html2canvas: { useCORS: true, scale: 1.4 },
      jsPDF: {
        unit: "pt",
        format: "letter",
        orientation: "portrait",
        compress: true,
      },
      pagebreak: { mode: "avoid-all", before: ["#remarks", "#images"] },
    };
    html2pdf()
      .set(opt)
      .from(element)
      .save()
      .then(() => setIsLoading(false));
    // setIsLoading(false);
  };

  const downloadReport = (reportUrl) => {
    let requestPayload = {
      fileKeyName: getFileKeyName(reportUrl),
    };
    nodeApi
      .postData(apiCall.downloadFromS3, requestPayload)
      .then((response) => {
        window.open(response.url, "_blank");
      });
  };

  const validate = Yup.object({
    loanNo: Yup.string().required("Loan No is required"),
  });

  const handleOk = (values) => {
    var payLoad = {
      id: selectedRow?.id,
      loanNumber: values.loanNo,
    };
    moduleApi.postData(apiCall.editLoanNumber, payLoad).then((response) => {
      if (response) {
        if (response?.Message === "Loan Number Edited Successfully") {
          swal("Loan No Updated Successfully", {
            icon: "success",
          });
          getFiAllocationsOfEmployees();
          loadData();
          setLoanModal(false);
          setDetailedViewModal(false);
        } else if (response?.message) {
          // toaster("error", response?.message);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const handleClose = () => {
    setDetailedViewModal(false);
  };

  const isDirectReportingOfficerOrNot = (userId) => {
    if (
      userLoginDetails?.autoFinanceUser?.subOrdinateIds?.length > 0 &&
      userLoginDetails?.autoFinanceUser?.subOrdinateIds.includes(userId)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal className="fade" size="lg" show={detailedViewModal}>
      <Modal.Header>
        <Modal.Title>
          {type === "EMPLOYEES"
            ? "Employee Allocation Details"
            : "Agency Allocation Details"}
        </Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => setDetailedViewModal(false)}
        ></Button>
      </Modal.Header>
      <Modal.Body
        className={
          selectedRow.fi_status === "FI Submitted"
            ? "fi-submitted-detail-modal"
            : "fi-detail-modal"
        }
      >
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          {selectedRow.status === "Closed" ? (
            allocationsDetails.length > 0 &&
            isDirectReportingOfficerOrNot(
              allocationsDetails[0]?.verifiers_user_id
            ) && (
              <>
                <button
                  className=" btn btn-primary btn-rounded test"
                  style={{
                    width: 120,
                    fontSize: "13px",
                    padding: 5,
                    float: "right",
                    color: "white",
                    marginRight: 20,
                  }}
                  onClick={() => setShowApprovalForm(true)}
                >
                  <i
                    className="fa fa-1x fa-edit"
                    style={{ marginRight: 5 }}
                  ></i>
                  Edit FI Result
                </button>
              </>
            )
          ) : (
            <></>
          )}
          <br />
          <br />
          <div className="container">
            {(((selectedRow.fi_status === "FI Submitted" || selectedRow.fi_status === "Door Locked" ||
                  selectedRow.fi_status === "Untraceable")&&
              ((userLoginDetails?.autoFinanceUser?.subOrdinateIds?.length > 0 &&
                userLoginDetails?.autoFinanceUser?.subOrdinateIds.includes(
                  selectedRow?.emp_user_id
                )) ||
                userLoginDetails?.autoFinanceUser?.roleName === "ADMIN")) ||
              showApprovalForm === true) &&
            allocationsDetails.length > 0 &&
            allocationsDetails[0].agency_pdf_report_url === null ? (
              <>
                <ApprovalForm
                  getFiAllocationsOfAgencies={getFiAllocationsOfAgencies}
                  getFiAllocationsOfEmployees={getFiAllocationsOfEmployees}
                  setDetailedViewModal={setDetailedViewModal}
                  selectedRow={selectedRow}
                  type={type}
                  allocationsDetails={allocationsDetails}
                  setShowApprovalForm={handleClose}
                  loadData={loadData}
                />
              </>
            ) : (
              <></>
            )}
            {(selectedRow.fi_status === "FI Submitted" ||
              selectedRow.status === "Closed") && <hr />}
            <Row>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                  }}
                >
                  <br />
                  {selectedRow.fi_status === "FI Submitted" ||
                  selectedRow.fi_status === "Positive" ||
                  selectedRow.fi_status === "Negative" ||
                  selectedRow.fi_status === "Refer to Credit" ||
                  selectedRow.fi_status === "Door Locked" ||
                  selectedRow.fi_status === "Fraud" ||
                  selectedRow.fi_status === "Untraceable" ? (
                    allocationsDetails.length > 0 &&
                    allocationsDetails[0].agency_pdf_report_url &&
                    allocationsDetails[0].agency_pdf_report_url !== null ? (
                      <div>
                        <button
                          className="me-2 btn btn-primary btn-rounded test"
                          style={{
                            width: 115,
                            fontSize: "13px",
                            padding: 5,
                            float: "right",
                            color: "white",
                          }}
                          onClick={downloadReport.bind(
                            this,
                            allocationsDetails[0].agency_pdf_report_url
                          )}
                        >
                          <i
                            className="fa fa-2x fa-file-pdf"
                            style={{ marginRight: 5 }}
                          ></i>
                          Download
                        </button>
                        <br />
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div>
                        <button
                          className="me-2 btn btn-primary btn-rounded"
                          style={{
                            width: 115,
                            fontSize: "13px",
                            padding: 5,
                            float: "right",
                            marginRight: 10,
                          }}
                          onClick={createPdf}
                        >
                          <i
                            className="fa fa-2x fa-file-pdf"
                            style={{ marginRight: 5 }}
                          ></i>
                          Download
                        </button>
                        <br />
                      </div>
                    )
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      margin: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr></tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "40%",
                          }}
                        >
                          Applicant Type
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.applicant_type}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "40%",
                          }}
                        >
                          Application No
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.application_number}
                        </td>
                      </tr>
                      {type === "EMPLOYEES" ? (
                        <>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "40%",
                              }}
                            >
                              Employee Name
                            </td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {selectedRow.emp_name}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "40%",
                              }}
                            >
                              Employee ID
                            </td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {selectedRow.employee_id}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "40%",
                              }}
                            >
                              Loan No
                            </td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {selectedRow.loan_number
                                ? selectedRow.loan_number
                                : "-"}
                              <span
                                style={{
                                  marginLeft: "30px",
                                }}
                                onClick={() => {
                                  setLoanModal(true);
                                }}
                              >
                                <i
                                  className="fa fa-edit text-primary me-2"
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </td>
                          </tr>{" "}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "40%",
                              }}
                            >
                              Agency Name
                            </td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {selectedRow.agency_name}
                            </td>
                          </tr>
                        </>
                      )}
                      {/* <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "40%",
                          }}
                        >
                          Product Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.product_name}
                        </td>
                      </tr> */}
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "40%",
                          }}
                        >
                          Product Type
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.product_type?selectedRow.product_type:'-'}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "40%",
                          }}
                        >
                          Customer Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.customer_name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.contact_no}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          FI Type
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.fi_type}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Address Line 1
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.address_line1}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          City
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.city}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          State
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.state}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Near by Landmark
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.nearby_landmark}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Postal Code
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.postal_code}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Customer Father's Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.father_name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Assigned Date
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {convertToLocalDateTime(
                            selectedRow.created_at,
                            "DATE_MM_DD_YYYY"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Due Date
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.due_date.substring(0, 10)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Allocation Status
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.status}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          FI Status
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.fi_status}
                        </td>
                      </tr>
                    </>
                  </div>
                </div>
              </Col>
            </Row>

            <Modal className="fade" show={loanModal}>
              <Modal.Header>
                <Modal.Title>Update Loan No.</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setLoanModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Formik
                    initialValues={{
                      loanNo: selectedRow?.loan_number,
                    }}
                    onSubmit={handleOk}
                    validationSchema={validate}
                  >
                    {({ formik, errors }) => (
                      <div>
                        <Row>
                          <Col lg="12">
                            <Form>
                              <div>
                                <TextField
                                  isAsterisk={true}
                                  label="Loan No"
                                  name="loanNo"
                                  type="text"
                                />
                              </div>
                              <div>
                                <Button
                                  style={{ float: "right" }}
                                  className="col-lg-2 m-2"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                                <Button
                                  style={{ float: "right" }}
                                  className="col-lg-2 m-2 float-right"
                                  variant="danger light"
                                  onClick={() => setLoanModal(false)}
                                >
                                  Close
                                </Button>
                              </div>
                            </Form>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          {(selectedRow?.fi_type?.toString().toLowerCase() === "current office" || selectedRow?.fi_type?.toString().toLowerCase() === "other office") ? (
            <OfficeFiAllocationPdfReport
              allocationsDetails={allocationsDetails}
              employeeVisitsData={employeeVisitsData}
              selectedRow={selectedRow}
              agencyRemarks={agencyRemarks}
              type={type}
              imageOneUrl={imageOneUrl}
              imageTwoUrl={imageTwoUrl}
              imageThreeUrl={imageThreeUrl}
              imageFourUrl={imageFourUrl}
              imageFiveUrl={imageFiveUrl}
              dedupeSignatureUrl={dedupeSignatureUrl}
            />
          ) : (
            <FiAllocationPdfReport
              allocationsDetails={allocationsDetails}
              employeeVisitsData={employeeVisitsData}
              selectedRow={selectedRow}
              agencyRemarks={agencyRemarks}
              type={type}
              imageOneUrl={imageOneUrl}
              imageTwoUrl={imageTwoUrl}
              imageThreeUrl={imageThreeUrl}
              imageFourUrl={imageFourUrl}
              imageFiveUrl={imageFiveUrl}
              dedupeSignatureUrl={dedupeSignatureUrl}
            />
          )}
        </LoadingOverlay>
      </Modal.Body>
    </Modal>
  );
}

export default DetailedAllocationModal;
