import React, { useEffect, useState } from "react";
import NO_DATA_FOUND from "../images/no-data-found.png";
import BootstrapTable from "react-bootstrap-table-next";
import { apiCall } from "../rest/restApi";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import PaginationComponent from "react-reactstrap-pagination";
import ReactGA from "react-ga";
import { getTokenInLocalStorage } from "../services/AuthService";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";


const CollectionVisitDetails = ({ startDate, endDate, visitDetails }) => {
  const [collectionVisits, setCollectionVisits] = useState([]);
  const [collectionVisitDetails, setCollectionVisitDetails] = useState(null);
  const [totalElements, setTotalElements] = useState(1);
  const [toggle, setToggle] = useState("");
  const [currPage, setCurrPage] = useState(0);
  // const [employeeVisitDetails,setEmployeeVisitDetails] =useState([]);

  useEffect(() => {
    ReactGA.pageview("Customer Intensity Details");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userLoginDetails = getTokenInLocalStorage();

  const visitsColumns = [
    {
      text: "Emp Id",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "employeeName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan No",
      dataField: "loanNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "CP Name",
      dataField: "cpName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Amount</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "amountPaid",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Contact</span>
          <br></br>
          <span>Number</span>
        </div>
      ),
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (row.collectionVisit?.status === "Partially Paid") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Customer Not Available") {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "") {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.status}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (
            row.collectionVisit?.subStatus === "Cash" ||
            row.collectionVisit?.subStatus === "Bank Transfer" ||
            row.collectionVisit?.subStatus === "Already Settled" ||
            row.collectionVisit?.subStatus === "UPI"
          ) {
            return (
              <span
                className="badge badge-rounded badge-success"
                // style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "Customer Not Available"
          ) {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "" ||
            row.collectionVisit?.subStatus === null
          ) {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.subStatus}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },

    // {
    //   text: "Current Due",
    //   dataField: "amountToBePaid",
    //   width: 130,
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     fontWeight: "bold",
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Mode</span>
        </div>
      ),
      dataField: "paymentMode",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Last Paid",
      dataField: "lastPaidTime",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    
    {
      text: (
        <div>
          <span>Promised</span>
          <br></br>
          <span>Payment Date</span>
        </div>
      ),
      dataField: "toBePaidBy",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Date",
      dataField: "dateTime",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>New Mobile</span>
          <br></br>
          <span>No</span>
        </div>
      ),
      dataField: "alternateMobileNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log('row', row);
          setCollectionVisitDetails(row);
          setToggle('chatbox');
        },
      },
    },
   
  ];
  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };
  useEffect(() => {
    loadData(0);
    console.log(startDate, endDate, visitDetails);
    setCollectionVisits([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadData = (pageNo) => {
    let listData = [];
    const payLoad = {
      endTime: endDate,
      exeDailyScheduledDataId: visitDetails.id,
      organizationId:
        userLoginDetails.autoFinanceUser.roleName !== "CP_USER"
          ? visitDetails.organizationId
          : userLoginDetails.autoFinanceOrganization.mainOrganizationId,
      startTime: startDate,
      userId: visitDetails.userId,
      visitType: "Collections",
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getemployeevisits, pageNo, 10),
        payLoad
      )
      .then((response) => {
        console.log("response", response.content);
        response.content.forEach((element) => {
          let data = {
            id: element.id,
            userId: element.userId,
            userModel: element.userModel,

            name: element.userModel.name,
            mobileNumber: element.userModel.mobileNumber,
            email: element.userModel.email,
            UserImage: element.userModel.image,
            branchId: element.userModel.branchId,
            roleName: element.userModel.roleName,
            addedByUserId: element.userModel.addedByUserId,
            employeeId: element.userModel.employeeId,
            employeeName: element.userModel.name,
            cpName: element.collectionVisit?.cpName?element.collectionVisit?.cpName:'-',
            amountPaid: element.collectionVisit?.amountPaid?element.collectionVisit?.amountPaid:'-',
            alternateMobileNumber: element.collectionVisit?.alternateMobileNumber?element.collectionVisit?.alternateMobileNumber:'-',
            userStatus: element.userModel.status,
            passwordUpdated: element.userModel.passwordUpdated,

            organizationId: element.organisationId,
            status: element.status,
            subStatus: element.subStatus,
            comments: element.comments,
            latitude: element.latitude,
            longitude: element.longitude,
            address: element.address,
            visitType: element.visitType,
            image: element.image,
            dateTime: convertEpochTimeToDate(element.dateTime),
            timeSpent: element.timeSpent,
            reportingOfficerId: element.reportingOfficerId,
            reportingOfficerName: element.reportingOfficerName,
            salesVisit: element.salesVisit,
            // distFromCustAddr: getDistTravelledInKM(element.distFromCustAddr),
            contactNumber: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit.contactNumber
              : element.collectionVisit
              ? element.collectionVisit
                ? element.collectionVisit.contactNumber
                : "--"
              : element.salesVisit
              ? element.salesVisit.contactNumber
              : "--",

            collectionVisit: element.collectionVisit,
            loanNumber: element.collectionVisit
              ? element.collectionVisit.loanNumber
              : "-",
                lastPaidTime:
              element.collectionVisit.lastPaidTime !== 0
                ? convertEpochTimeToDate(element.collectionVisit.lastPaidTime)
                : "-",
            // paymentDateBy:
            //   element.collectionVisit.paymentDateBy !== null
            //     ? convertEpochTimeToDate(element.collectionVisit.paymentDateBy)
            //     : "-",
            paymentMode: element.collectionVisit.paymentMode
              ? element.collectionVisit.paymentMode
              : "-",
            toBePaidBy:
              element.collectionVisit.toBePaidBy !== 0
                ? convertEpochTimeToDate(element.collectionVisit.toBePaidBy)
                : "-",
            collectionsContactNumber: element.collectionVisit?.contactNumber,
            invenotryScan: element.collectionVisit?.invenotryScan,
            repossedImages: element.collectionVisit?.repossedImages,
            repossedLocation: element.collectionVisit?.repossedLocation,
            customerName: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit.customerName
              : element.collectionVisit
              ? element.collectionVisit
                ? element.collectionVisit.customerName
                : "--"
              : element.salesVisit
              ? element.salesVisit.customerName
              : "--",
            fieldInvestigationVisit: element.fieldInvestigationVisit,
          };
          listData.push(data);
        });
        setCollectionVisits(listData);
        setTotalElements(response?.totalElements);
      });
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(selectedPage - 1);
  };

  return (
    <div>
      <div style={{ overflowX: "auto", overflowY: "auto" }}>
        <BootstrapTable
          keyField="id"
          data={collectionVisits}
          columns={visitsColumns}
        />
      </div>
      {collectionVisits.length === 0 && (
        <div className="d-flex justify-content-center">
          <img src={NO_DATA_FOUND} alt="" />
        </div>
      )}
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          marginBottom: "10px",
          justifyContent: "center",
          margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
        }}
      >
        <PaginationComponent
          totalItems={totalElements}
          pageSize={10}
          defaultActivePage={currPage + 1}
          onSelect={onPageChange}
        />
      </div>
      {toggle === "chatbox" && (
          <VisitedPlaceDrawer
            visitedPlacesDetailsList={collectionVisitDetails}
            closeDrawer={() => setToggle(false)}
            // reLoad={() => loadVistedPlacesData(currPage, searchKey)}
          />
        )}
    </div>
  );
};
export default CollectionVisitDetails;
