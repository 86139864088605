import React, { useEffect, useState } from "react";
import { Button,Row, Col, Modal} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { getTokenInLocalStorage } from "../services/AuthService";
import * as helper from "../CommonComponents/Helper";
import { Link } from "react-router-dom";

const UPIMandateSummaryDetails = ({transactionType, status,startDate,endDate}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [totalElements2, setTotalElements2] = useState(1);
  const [currPage2, setCurrPage2] = useState(0);
  const [subscriptionList, setSubscriptionList] = useState([]);
  // const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [transactionList, setTransactionList] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState(null);
  // const [searchKey, setSearchKey] = useState("");
  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if(transactionType==="Subscriptions"){
      setShowSubscriptions(true);
      getSubscriptionList(0);
    }else{
      getTransactionList(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubscriptionList = (pageNo) => {
    let listData = [];
    setIsLoading(true);
    var payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      status: status,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getSubscriptions, pageNo, 10),
        payload
      )
      .then((response) => {
        setCurrPage(pageNo);
        console.log("subscriptionResponse", response);
        response.content.forEach((element) => {
          let data = {
            id: element.id,
            name: element.name ? element.name : "-",
            userId: element.userId,
            createdTime: element.createdTime
              ? helper.EpochToDate(element.createdTime)
              : "-",
            updateTime: element.updateTime
              ? helper.EpochToDate(element.updateTime)
              : "-",
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            organizationId: element.organizationId,
            merchantSubscriptionId: element.merchantSubscriptionId,
            merchantUserId: element.merchantUserId,
            subscriptionName: element.subscriptionName,
            authWorkflowType: element.authWorkflowType,
            amountType: element.amountType,
            amount: element.amount ? getRupees(element.amount) : "-",
            amountToBeDeducted:
              element.latestUpdatedAmount === 0
                ? element.amountToBeDeducted
                  ? getRupees(element.amountToBeDeducted)
                  : "-"
                : getRupees(element.latestUpdatedAmount),
            dateToDeductAmount: element.dateToDeductAmount,
            frequency: element.frequency,
            ifsc: element.ifsc,
            utr: element.utr,
            maskedAccountNumber: element.maskedAccountNumber,
            recurringCount: element.recurringCount,
            description: element.description,
            mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
            subMerchantId: element.subMerchantId,
            phoneSubcriptionId: element.phoneSubcriptionId,
            subscriptionStartDate: element.subscriptionStartDate
              ? helper.EpochToOnlyDate(element.subscriptionStartDate)
              : "-",
            stateStartDate: element.stateStartDate,
            stateEndDate: element.stateEndDate,
            state: element.state,
          };
          listData.push(data);
        });
        setSubscriptionList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

  const getTransactionList = (pageNo) => {
    let listData = [];
    setIsLoading(true);
    var payload = {
      endDate: endDate,
      startDate: startDate,
      //searchkey:searchKeyy,
      transactionType:transactionType,
      status:status,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.gettransactionshistorybasedontransactiontype, pageNo, 10),
        payload
      )
      .then((response) => {
        // console.log("transactionResponse", response);
        response?.content?.forEach((element) => {
          let data = {
            name: element.name ? element.name : "-",
            customerName: element.customerName ? element.customerName : "-",
            empId: element.empId ? element.empId : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            subscriptionid: element.subscriptionid
              ? element.subscriptionid
              : "-",
            utr: element.utr ? element.utr : "-",
            amountToBeDucted: element.amountToBeDucted
              ? getRupees(element.amountToBeDucted)
              : "-",
            mobileNumber: element.mobileNumber ? element.mobileNumber : "-",
            maxAmount: element.maxAmount ? getRupees(element.maxAmount) : "-",
            transactionIntiatedTime: element.transactionIntiatedTime
              ? helper.EpochToDate(element.transactionIntiatedTime)
              : "-",
            amountDebitedTime: element.amountDebitedTime
              ? helper.EpochToDate(element.amountDebitedTime)
              : "-",
            frequency: element.frequency ? element.frequency : "-",
            recurringCount: element.recurringCount
              ? element.recurringCount
              : "-",
            state: element.state,
            dateOfDeduction: element.dateOfDeduction
              ? element.dateOfDeduction
              : "-",
            transactionAmount: element.transactionAmount
              ? getRupees(element.transactionAmount)
              : "-",
            trasactionid: element.trasactionid ? element.trasactionid : "-",
            canReintiateTransaction: element.canReintiateTransaction
              ? element.canReintiateTransaction
              : "-",
            notificationState: element.notificationState
              ? element.notificationState
              : "-",
            paymentState: element.paymentState ? element.paymentState : "-",
            receiptNumber: element.receiptNumber,
            paymentDescription: element.paymentDescription ? element.paymentDescription : "-",
            providerReferenceId: element.providerReferenceId,
            payResponseCode: element.payResponseCode
              ? element.payResponseCode
              : "-",
            reinitiatedBy:element.reinitiatedBy ? element.reinitiatedBy:"-",
            reinitiatedByName:element.reinitiatedByName ? element.reinitiatedByName:"-",
            reinitiatedByEmpId:element.reinitiatedByEmpId ? element.reinitiatedByEmpId:"-"
          };
          listData.push(data);
        });
        setTransactionList(listData);
        setTotalElements2(response?.totalElements);
        setIsLoading(false);
      });
  };

  const getRupees = (value) => {
    return value / 100;
  };

  // const getPaisa = (value) => {
  //   return value * 100;
  // };

  const subscriptionColumns = [
    {
      text: "Emp Name",
      dataField: "employeeName",
      width: 130,
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Id",
      dataField: "employeeId",
      width: 130,
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Loan No",
      dataField: "merchantUserId",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Loan No</span>
          <br></br>
          <span>Reference</span>
        </div>
      ),
      dataField: "loanNumber",
      align: "center",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "name",
      width: 130,
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",
      dataField: "mobileNumber",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Amount",
    //   dataField: "amount",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "EMI",
      dataField: "amountToBeDeducted",
      align: "left",
      headerAlign: "left",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Amount Type",
    //   dataField: "amountType",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },

    // {
    //   text: "Frequency",

    //   dataField: "frequency",
    //   align: "left",
    //   headerAlign: "left",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: (
        <div>
          <span>Tenure</span>
          <br></br>
          <span>(in Months)</span>
        </div>
      ),
      dataField: "recurringCount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Date Of</span>
          <br></br>
          <span>Deduction</span>
        </div>
      ),
      dataField: "dateToDeductAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "state",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.state === "CREATED") {
          return (
            <span className="badge badge-rounded badge-primary">Created</span>
          );
        } else if (row.state === "ACTIVE") {
          return (
            <span className="badge badge-rounded badge-success">Active</span>
          );
        } else if (row.state === "SUSPENDED") {
          return (
            <span className="badge badge-rounded badge-warning">Suspended</span>
          );
        } else if (row.state === "REVOKED") {
          return (
            <span className="badge badge-rounded badge-primary">Revoked</span>
          );
        } else if (row.state === "CANCELLED") {
          return (
            <span className="badge badge-rounded badge-danger">Cancelled</span>
          );
        } else if (row.state === "PAUSED") {
          return (
            <span className="badge badge-rounded badge-warning">Paused</span>
          );
        } else if (row.state === "EXPIRED") {
          return (
            <span className="badge badge-rounded badge-primary">Expired</span>
          );
        } else if (row.state === "FAILED") {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else if (row.state === "CANCEL_IN_PROGRESS") {
          return (
            <span className="badge badge-rounded badge-warning">
              Cancel in Progress
            </span>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    getSubscriptionList(selectedPage - 1);
  };

  const onPageChange2 = (selectedPage) => {
    setCurrPage2(selectedPage - 1);
    getTransactionList(selectedPage - 1);
  };

  // const getTransactionDetails = (subscriptionId) => {
  //   let listData = [];
  //   moduleApi
  //     .getData(apiCall.getTransactions + subscriptionId)
  //     .then((response) => {
  //       if (response) {
  //         if (response?.message) {
  //           return swal(response?.message, {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         } else if (response) {
  //           response?.forEach((element) => {
  //             let data = {
  //               id: element.id,
  //               merchantId: element.merchantId,
  //               merchantUserId: element.merchantUserId,
  //               subscriptionId: element.subscriptionId,
  //               transactionId: element.transactionId,
  //               amount: element.amount ? getRupees(element.amount) : "-",
  //               autoDebit: element.autoDebit,
  //               state: element.state,
  //               notificationState: element.notificationState
  //                 ? element.notificationState
  //                 : "-",
  //               utr: element.utr ? element.utr : "-",
  //               dateTime: element.dateTime
  //                 ? helper.EpochToDate(element.dateTime)
  //                 : "-",
  //               amountDebitedTime: element.amountDebitedTime
  //                 ? helper.EpochToDate(element.amountDebitedTime)
  //                 : "-",
  //               notificationId: element.notificationId,
  //               notifiedAt: element.notifiedAt,
  //               validAfter: element.validAfter,
  //               validUpto: element.validUpto,
  //               providerReferenceId: element.providerReferenceId,
  //               paymentState: element.paymentState ? element.paymentState : "-",
  //               payResponseCode: element.payResponseCode
  //                 ? element.payResponseCode
  //                 : "-",
  //             };
  //             listData.push(data);
  //           });
  //           setTransactionList(listData);
  //           // setTotalElements2(response.totalElements);
  //           // setIsLoading(false);
  //         }
  //       } else {
  //         return swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  // };

  const transactionColumns = [
    {
      text: "Customer Name",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",

      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan No",

      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Amount",
      dataField: "transactionAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Frequency",

      dataField: "frequency",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "UTR No",

      dataField: "utr",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Reinitiated</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "transactionIntiatedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "amountDebitedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Notification</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "notificationState",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.notificationState === "NOTIFIED") {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>Notified</span>
          );
        } else if (row.notificationState === "PENDING") {
          return (
            <span style={{ color: "orange", fontWeight: "bold" }}>Pending</span>
          );
        } else if (row.notificationState === "FAILED") {
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>Failed</span>
          );
        }
        if (row.notificationState) {
          return (
            <span style={{ color: "blue", fontWeight: "bold" }}>
              {row.notificationState}
            </span>
          );
        } else {
          return "-";
        }
      },
    },

    {
      text: (
        <div>
          <span>Pay Response</span>
          <br></br>
          <span>Code</span>
        </div>
      ),
      dataField: "payResponseCode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.payResponseCode === "SUCCESS") {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>Success</span>
          );
        } else if (row.payResponseCode === "PENDING") {
          return (
            <span style={{ color: "orange", fontWeight: "bold" }}>Pending</span>
          );
        } else if (row.payResponseCode === "FAILED") {
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>Failed</span>
          );
        }
        if (row.payResponseCode) {
          return (
            <span style={{ color: "blue", fontWeight: "bold" }}>
              {row.payResponseCode}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Payment Description",
      dataField: "paymentDescription",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "paymentState",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.paymentState === "COMPLETED") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else if (row.paymentState === "PENDING") {
          return (
            <span className="badge badge-rounded badge-warning">Pending</span>
          );
        } else if (row.paymentState === "FAILED") {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Reinitiated By Name",
      dataField: "reinitiatedByName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Reinitiated By EmpId",
      dataField: "reinitiatedByEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setTransactionDetails(row);
          setBasicModal(true);
        },
      },
    },
  ];

  return (
    <div style={{ padding: "10px" }}>

      <div style={{ margin: "10px" }}>
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div>
          {showSubscriptions ? (
            <div style={{ background: "white", margin: "10px", overflowX: "auto" }}>
              <div style={{ overflow: "auto", maxHeight: "400px" }}>
                {subscriptionList.length >= 1 ? (
                  <BootstrapTable
                    keyField="id"
                    data={subscriptionList}
                    columns={subscriptionColumns}
                    id="targetDueCount"
                  />
                ) : null}
              </div>
              {subscriptionList.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              ) : null}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PaginationComponent
                  totalItems={totalElements}
                  pageSize={10}
                  defaultActivePage={currPage + 1}
                  onSelect={onPageChange}
                />
              </div>
            </div>
          ) : (
            <div style={{ background: "white", margin: "10px", overflowX: "auto" }}>
              <div style={{ overflow: "auto", maxHeight: "400px" }}>
                <BootstrapTable
                  keyField="panNo"
                  data={transactionList}
                  columns={transactionColumns}
                />
              </div>
              {transactionList.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "0px",
                }}
              >
                <PaginationComponent
                  totalItems={totalElements2}
                  pageSize={10}
                  defaultActivePage={currPage2 + 1}
                  onSelect={onPageChange2}
                />
              </div>
            </div>
          )}
          </div>
        </LoadingOverlay>
      </div>
      <Modal className="fade" show={basicModal} size="md">
        <Modal.Header>
          <Modal.Title>Transaction Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div>
            {/* {transactionList.length === 0 ? (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            ) : ( */}
            <Row>
              <Col>
                <div>
                  <br></br>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Emp Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.name
                        ? transactionDetails?.name
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Emp Id
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.empId
                        ? transactionDetails?.empId
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Customer Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.customerName
                        ? transactionDetails?.customerName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Mobile No
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.mobileNumber
                        ? transactionDetails?.mobileNumber
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Subscription Id
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.subscriptionid
                        ? transactionDetails?.subscriptionid
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Frequency
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.frequency
                        ? transactionDetails?.frequency
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Date Of Deduction
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.dateOfDeduction
                        ? transactionDetails?.dateOfDeduction
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Amount
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.transactionAmount
                        ? transactionDetails?.transactionAmount
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Amount To be Deducted
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {transactionDetails?.amountToBeDucted
                        ? transactionDetails?.amountToBeDucted
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Initiated Date & Time
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {transactionDetails?.transactionIntiatedTime
                        ? transactionDetails?.transactionIntiatedTime
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Payment Date & Time
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {transactionDetails?.amountDebitedTime
                        ? transactionDetails?.amountDebitedTime
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Payment Status
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.paymentState
                        ? transactionDetails?.paymentState
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Payment Description
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.paymentDescription
                        ? transactionDetails?.paymentDescription
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Pay Response Code
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.payResponseCode
                        ? transactionDetails?.payResponseCode
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Notification Status
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.notificationState
                        ? transactionDetails?.notificationState
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Transaction Id
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.trasactionid
                        ? transactionDetails?.trasactionid
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Reinitiated By Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.reinitiatedByName
                        ? transactionDetails?.reinitiatedByName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Reinitiated By EmpId
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.reinitiatedByEmpId
                        ? transactionDetails?.reinitiatedByEmpId
                        : "-"}
                    </td>
                  </tr>
                  {/* <tr>
                    <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Recurring Count
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {transactionDetails?.recurringCount
                        ? transactionDetails?.recurringCount
                        : "-"}
                    </td>
                  </tr> */}
                </div>
              </Col>
            </Row>
            {/* )} */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UPIMandateSummaryDetails;
