import {
  SET_MODAL,
  SET_CALL_AUDIO_MODAL,
  SET_CALL_TRANSFER_MODAL,
  SET_IS_MODAL_OPEN,
  SET_IS_TEST_CALL,
  SET_IS_CALL_ACTIVE,
  SET_IS_MUTE,
  SET_IS_HOLD,
  SET_IS_MOBILE_FIELD,
  SET_IS_ONGOING_CALL,
  SET_IS_RINGING,
  SET_IS_CALL_ANSWERED,
  SET_IS_CALL_REJECTED,
  SET_IS_MODAL_MINIMISED,
  SET_WEBRTC_MOBILE,
  SET_ALLOCATION_ID,
  SET_ALLOCATION_DETAILS,
  SET_IS_MY_ALLOCATIONS_CALLED,
  SET_CALL_ID,
  SET_TYPE,
  SET_CALL_REMARKS_MODAL,
  SET_MODAL_HEADER_TEXT,
  SET_CALL_STATUS_TEXT,
  SET_DAILING_TIME,
  SET_RINGING_TIME,
  SET_TALK_TIME,
  SET_HOLD_TIME,
  SET_DISPO_TIME,
  SET_REQ_PAYMENTS_LIST,
  SET_IS_ONLINE,
  SET_SELECTED_AGENT_STATUS,
  SET_IS_PROGRESSIVE_DIALING,
  SET_CONTINUE_DIALING,
  SET_IS_SALES_PROGRESSIVE_DIALING,
  SET_SALES_CONTINUE_DIALING,
  SET_CAMPAIGN_NAME,
  SET_TIMER_COUNT,
  SET_IS_TIMER_COUNTING,
  SET_CALL_STATUS,
  SET_PIOPIY,
  SET_IS_INCOMING_CALL,
  SET_IS_ADD_SALE_LEAD_BUTTON,
  SET_INCOMING_LOAN_NUMBERS,
  SET_IS_INCOMING_LOAN_NO_DROPDOWN,
  SET_CALL_TYPE,
  SET_SELECTED_LOAN_DROP_DOWN_VALUE,
  SET_SEARCHED_LOAN_NO_VALUE
} from "../actions/CallingActions";

const initialState = {
  modal: false,
  callAudioModal: false,
  callTransferModal: false,
  isModalOpen: false,
  isTestCall: false,
  isCallActive: false,
  isMute: false,
  isHold: false,
  isMobileField: true,
  isOnGoingCall: false,
  isRinging: false,
  isCallAnswered: false,
  isCallRejected: true,
  isModalMinimised: false,
  webRtcMobile: null,
  allocationId: null,
  allocationDetails: null,
  isMyAllocationsCalled: false,
  callId: null,
  type: "Collections",
  callRemarksModal: false,
  modalHeaderText: "Manual",
  callStatusText: "Calling",
  dailingTime: 0,
  ringingTime: 0,
  talkTime: 0,
  holdTime: 0,
  dispoTime: 0,
  reqPaymentsList: [],
  isOnline:
    JSON.parse(localStorage.getItem("selectedAgentStatus"))?.value === "online"
      ? true
      : false,
  selectedAgentStatus: JSON.parse(localStorage.getItem("selectedAgentStatus")),
  isProgressiveDialing: false,
  continueDialing: 0,
  isSalesProgressiveDialing: false,
  salesContinueDialing: 0,
  campaignName: "",
  timercount: 0,
  isTimerCounting: false,
  callStatus: true,
  isIncomingCall: false,
  incomingLoanNumbers: [],
  isAddSaleLeadButton: null,
  isIncomingLoanNoDropdown: false,
  callType: null,
  selectedLoanDropDownValue: null,
  searchedLoanNoValue: '',
  piopiy: {
    name: "Display Name",
    debug: false,
    autoplay: true,
    ringTime: 60,
    isEnable:false
  }
};

// const callingReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_MODAL:
//       return { ...state, modal: action.payload };
//     default:
//       return state;
//   }
// };
const callingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PIOPIY:
      return { ...state, piopiy: action.payload };
    case SET_MODAL:
      return { ...state, modal: action.payload };
    case SET_CALL_AUDIO_MODAL:
      return { ...state, callAudioModal: action.payload };
    case SET_CALL_TRANSFER_MODAL:
      return { ...state, callTransferModal: action.payload };
    case SET_IS_MODAL_OPEN:
      return { ...state, isModalOpen: action.payload };
    case SET_IS_TEST_CALL:
      return { ...state, isTestCall: action.payload };
    case SET_IS_CALL_ACTIVE:
      return { ...state, isCallActive: action.payload };
    case SET_IS_MUTE:
      return { ...state, isMute: action.payload };
    case SET_IS_HOLD:
      return { ...state, isHold: action.payload };
    case SET_IS_MOBILE_FIELD:
      return { ...state, isMobileField: action.payload };
    case SET_IS_ONGOING_CALL:
      return { ...state, isOnGoingCall: action.payload };
    case SET_IS_RINGING:
      return { ...state, isRinging: action.payload };
    case SET_IS_CALL_ANSWERED:
      return { ...state, isCallAnswered: action.payload };
    case SET_IS_CALL_REJECTED:
      return { ...state, isCallRejected: action.payload };
    case SET_IS_MODAL_MINIMISED:
      return { ...state, isModalMinimised: action.payload };
    case SET_WEBRTC_MOBILE:
      return { ...state, webRtcMobile: action.payload };
    case SET_ALLOCATION_ID:
      return { ...state, allocationId: action.payload };
    case SET_ALLOCATION_DETAILS:
      return { ...state, allocationDetails: action.payload };
    case SET_IS_MY_ALLOCATIONS_CALLED:
      return { ...state, isMyAllocationsCalled: action.payload };
    case SET_CALL_ID:
      return { ...state, callId: action.payload };
    case SET_TYPE:
      return { ...state, type: action.payload };
    case SET_CALL_REMARKS_MODAL:
      return { ...state, callRemarksModal: action.payload };
    case SET_MODAL_HEADER_TEXT:
      return { ...state, modalHeaderText: action.payload };
    case SET_CALL_STATUS_TEXT:
      return { ...state, callStatusText: action.payload };
    case SET_DAILING_TIME:
      return { ...state, dailingTime: action.payload };
    case SET_RINGING_TIME:
      return { ...state, ringingTime: action.payload };
    case SET_TALK_TIME:
      return { ...state, talkTime: action.payload };
    case SET_HOLD_TIME:
      return { ...state, holdTime: action.payload };
    case SET_DISPO_TIME:
      return { ...state, dispoTime: action.payload };
    case SET_REQ_PAYMENTS_LIST:
      return { ...state, reqPaymentsList: action.payload };
    case SET_IS_ONLINE:
      return { ...state, isOnline: action.payload };
    case SET_SELECTED_AGENT_STATUS:
      return { ...state, selectedAgentStatus: action.payload };
    case SET_IS_PROGRESSIVE_DIALING:
      return { ...state, isProgressiveDialing: action.payload };
    case SET_CONTINUE_DIALING:
      return { ...state, continueDialing: action.payload };
    case SET_IS_SALES_PROGRESSIVE_DIALING:
      return { ...state, isSalesProgressiveDialing: action.payload };
    case SET_SALES_CONTINUE_DIALING:
      return { ...state, salesContinueDialing: action.payload };
    case SET_CAMPAIGN_NAME:
      return { ...state, campaignName: action.payload };
    case SET_TIMER_COUNT:
      return { ...state, timercount: action.payload };
    case SET_IS_TIMER_COUNTING:
      return { ...state, isTimerCounting: action.payload };
    case SET_CALL_STATUS:
      return { ...state, callStatus: action.payload };
    case SET_IS_INCOMING_CALL:
      return { ...state, isIncomingCall: action.payload };
    case SET_INCOMING_LOAN_NUMBERS:
      return { ...state, incomingLoanNumbers: action.payload };
    case SET_IS_ADD_SALE_LEAD_BUTTON:
      return { ...state, isAddSaleLeadButton: action.payload };
    case SET_IS_INCOMING_LOAN_NO_DROPDOWN:
      return { ...state, isIncomingLoanNoDropdown: action.payload };
    case SET_CALL_TYPE:
      return { ...state, callType: action.payload };
    case SET_SELECTED_LOAN_DROP_DOWN_VALUE:
      return { ...state, selectedLoanDropDownValue: action.payload };
    case SET_SEARCHED_LOAN_NO_VALUE:
      return { ...state, searchedLoanNoValue: action.payload };
    default:
      return state;
  }
};

export default callingReducer;
