import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import * as moduleApi2 from "../rest/moduleApi2";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import { apiCall } from "../rest/restApi";
// import { toaster } from "../rest/apiUtils";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import swal from "sweetalert";
import Select from "react-select";
// import SearchBar from "../CommonComponents/SearchBar";
import ReactGA from "react-ga";
import { EpochToDate } from "../CommonComponents/Helper";

const UploadedFilesList = ({ allocationType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorDescModal, setErrorDescModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [errorDescription, setErrorDescription] = useState(null);
  const [isDateFilter, setIsDateFilter] = useState(false);
  // eslint-disable-next-line
  const [currPage, setCurrPage] = useState(0);
  const [UploadedFileList, setUploadedFileList] = useState([]);
  // const [searchKey2, setSearchKey2] = useState("");
  // const [selectedValue, setSelectedValue] = useState("EXECUTIVE");
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview("Uploaded Files List");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // loadData(0, searchKey);
    UploadedList(start, end, 0);
    // loadExecutives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UploadedList = (start, end, pageNo) => {
    let listData = [];
    setIsLoading(true);
    // //QA
    // let ApiUrl = "https://9ylmyoqrai.execute-api.ap-south-1.amazonaws.com/default/bulk_upload";
    // //Prod
    // // let ApiUrl ="https://65vveckfzb.execute-api.ap-south-1.amazonaws.com/default/bulk_upload"
    let payload = {
      // url: apiCall.bulkUploadBaseUrl,
      // action: "preSignedUrlStatusOrData",
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      presignedUrlForFileDownload:
        allocationType !== "paymentAmountUploadList" ? true : undefined,
      // limit: 10,
      // pageSize: pageNo,
      startDate: start,
      allocationType:
        allocationType !== "paymentAmountUploadList"
          ? allocationType
          : undefined,
    };
    // let body = {
    //   url: apiCall.bulkUploadBaseUrl,
    //   action: "preSignedUrlStatusOrData",
    //   endDate: end,
    //   organizationId: userLoginDetails.autoFinanceUser.organizationId,
    //   limit: 10,
    //   pageSize: pageNo,
    //   startDate: start,
    // };

    // moduleApi
    //   .getUpoadedList(
    //     body.url,
    //     body.action,
    //     body.organizationId,
    //     body.endDate,
    //     body.limit,
    //     body.pageSize,
    //     body.startDate
    //   )
    //   .then((response) => {
    moduleApi2
      .postData(
        moduleApi2.withPage(
          allocationType !== "paymentAmountUploadList"
            ? apiCall.getBulkUploadFilesList
            : apiCall.getMandateUploadedFileList,
          pageNo,
          10
        ),
        payload
      )
      .then((response) => {
        setCurrPage(pageNo);
        console.log("uploadedFileListResponse", response);
        //message: "Service Unavailable"
        if (response?.message === "Service Unavailable") {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try again", {
            icon: "error",
            dangerMode: true,
          });
        } else {
          // response?.content?.forEach((element) => {
          response?.content?.forEach((element) => {
            let data = {
              bucketName: element.bucketName,
              fileName: element.fileName ? element.fileName : "-",
              allocationType: element.allocationType
                ? element.allocationType
                : "-",
              empName: element.empName ? element.empName : "-",
              empId: element.empId ? element.empId : "-",
              action: element.action ? element.action : "-",
              errorDescription: element.errorDescription
                ? element.errorDescription
                : "-",
              createdAt: element.createdAt,
              createdTime: element.createdTime
                ? EpochToDate(element.createdTime)
                : "-",
              expirationTime: element.expirationTime,
              generatedUrl: element.generatedUrl,
              procesingMessage: element.procesingMessage
                ? element.procesingMessage
                : "-",
              numberOfRecordProcessed: element.numberOfRecordProcessed
                ? element.numberOfRecordProcessed
                : 0,
              id: element.id,
              lastUpdatedAt: element.lastUpdatedAt,
              objectPath: element.objectPath,
              organizationId: element.organizationId,
              status: element.status,
            };

            listData.push(data);
          });
          setUploadedFileList(listData);
          setTotalElements(response?.totalElements);
          setIsLoading(false);
        }
      });
  };

  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  const columns = [
    {
      text: "File Name",
      dataField: "fileName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "File Type",
      dataField: "action",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.action === "UPDATE_ALLOCATIONS") {
          return "Collection Allocations";
        } else if (row.action === "DELETE_ALLOCATIONS") {
          return "Delete Allocations";
        } else if (row.action === "TRANSFER_ALLOCATIONS") {
          return "Transfer Allocations";
        } else if (row.action === "UPDATE_TELE_CALLER_ALLOCATIONS") {
          return "Telecaller Allocations";
        } else if (row.action === "UPDATE_PROIRITIES_ALLOCATIONS") {
          return "Allocate Priorities";
        } else if (row.action === "UPDATE_LENDER_PAYMENT_STATUS") {
          return "Lender Payment Status";
        } else if (row.action === "UPDATE_ALLOCATION_TO_CAMPAIGN") {
          return "Campaign Allocation";
        } else if (row.action === "UPDATE_ADDITIONAL_PHONE_NUMBERS") {
          return "Additional Phone Numbers";
        } else if (row.action === "UPDATE_EXTERNAL_PAYMENTS") {
          return "External Payments";
        } else if (row.action === "UPDATE_TARGET_DATE") {
          return "Update Target Date";
        } else if (row.action === "UPDATE_SALES_ALLOCATIONS") {
          return "Sales Allocation";
        } else if (row.action === "UPDATE_TELE_CALLER_SALES_ALLOCATIONS") {
          return "Sales Telecaller Allocation";
        } else if (row.action === "UPDATE_PROIRITIES_SALES_ALLOCATIONS") {
          return "Sales Allocation Priorities";
        } else if (
          row.action === "UPDATE_SALES_ALLOCATIONS_WITH_AUTO_ALLOCATION"
        ) {
          return "Sales Auto Allocation";
        } else if (
          row.action ===
          "UPDATE_SALES_INTRESTED_ALLOCATIONS_WITH_AUTO_ALLOCATION"
        ) {
          return "Sales Interested Auto Allocation";
        } else if (row.action === "UPDATE_AMOUNTS") {
          return "Update Mandate Amounts";
        } else if (row.action === "REPRESENTATION") {
          return "Representation";
        } else if (row.allocationType === "fiAllocation") {
          return "FI Allocation";
        } else {
          return "-";
        }
      },
    },
    {
      text: allocationType !== "fiAllocation" ? "" : "FI Type",
      dataField: allocationType !== "fiAllocation" ? "" : "action",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created At",
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Uploaded By</span>
          <br></br>
          <span>Emp Id</span>
        </div>
      ),
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Uploaded By</span>
          <br></br>
          <span>Emp Name</span>
        </div>
      ),
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      // text: (
      //   <div>
      //     <span>No of Records</span>
      //     <br></br>
      //     <span>Processed</span>
      //   </div>
      // ),
      // dataField: "numberOfRecordProcessed",
      text: (
        <div>
          <span>No of Records</span>
          <br></br>
          <span>Processed</span>
        </div>
      ),
      dataField: "numberOfRecordProcessed",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: allocationType === "fiAllocation" ? "" : "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (allocationType !== "fiAllocation") {
          if (row.status === "PROCESSED") {
            return (
              <span className="badge badge-rounded badge-success">
                Uploaded
              </span>
            );
          } else if (row.status === "PROCESSING") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black", backgroundColor: "yellow" }}
              >
                Processing
              </span>
            );
          } else if (row.status === "PARTIALLY_PROCESSED") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                Partially Uploaded
              </span>
            );
          } else if (row.status === "PENDING") {
            return (
              <span className="badge badge-rounded badge-warning">Pending</span>
            );
          } else if (row.status === "ERROR") {
            return (
              <span className="badge badge-rounded badge-danger">Failed</span>
            );
          } else {
            return "-";
          }
        }
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (allocationType !== "fiAllocation") {
          if (row.status === "ERROR" || row.status === "PARTIALLY_PROCESSED") {
            return (
              <div
                className="d-flex"
                onClick={() => {
                  var data = row?.procesingMessage.split("\\n");
                  console.log("data", data);
                  setErrorDescription(data);
                  setErrorDescModal(true);
                }}
              >
                <Link to="#" className="btn btn-info shadow btn-xs sharp">
                  <i className="fa fa-eye"></i>
                </Link>
              </div>
            );
          } else {
            return <></>;
          }
        }
      },

      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     var data = row?.procesingMessage.split("\\n");
      //     console.log("data", data);
      //     setErrorDescription(data);
      //     setErrorDescModal(true);
      //   },
      // },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-download"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          swal({
            title: "Download",
            text: "Do you really want to download the uploaded file?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              downlaodFile(row?.fileName);
            }
          });
        },
      },
    },
  ];

  const downlaodFile = (fileName) => {
    setIsLoading(true);
    // //QA
    // let ApiUrl = "https://9ylmyoqrai.execute-api.ap-south-1.amazonaws.com/default/bulk_upload";
    // //Prod
    // // let ApiUrl ="https://65vveckfzb.execute-api.ap-south-1.amazonaws.com/default/bulk_upload"
    // let url =
    //   apiCall.bulkUploadBaseUrl +
    //   "?action=preSignedUrlStatusOrData&download=true";

    // moduleApi
    //   .getDownloadedFilePreSignedUrl(
    //     url,
    //     userLoginDetails.autoFinanceUser.organizationId,
    //     fileName
    //   )
    //   .then((response) => {
    // // let ApiUrl ="https://65vveckfzb.execute-api.ap-south-1.amazonaws.com/default/bulk_upload"
    let payload = {
      // url: apiCall.bulkUploadBaseUrl,
      // action: "preSignedUrlStatusOrData",
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      presignedUrlForFileDownload: true,
      fileName: fileName,
      // limit: 10,
      // pageSize: pageNo,
      startDate: start,
    };
    moduleApi2
      .postData(
        allocationType !== "paymentAmountUploadList"
          ? apiCall.getPreSignedUrlForDownloadBulkUploadFile
          : apiCall.getPresignedUrlForMandateFileDownload,
        payload
      )
      .then((response) => {
        console.log("preSignedUrl", response);
        if (response) {
          if (response.generatedUrl) {
            localStorage.setItem("fileName", fileName);
            moduleApi
              .getDownloadedFile(response?.generatedUrl, fileName)
              .then((response2) => {
                //Service Unavailable
                console.log("bulk Upload download", response2);
                setIsLoading(false);
                localStorage.removeItem("fileName");
              });
          }
        }
      });
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    UploadedList(start, end, selectedPage - 1);
  };

  // const searchKeyword2 = (key) => {
  //   setSearchKey2(key);
  //   NonAllocatedLoadData(0, key);
  // };

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0].getFullYear(),
            value[0].getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86400);
    setStart(start);
    setEnd(end);
    UploadedList(start, end, 0);
  };

  return (
    <div>
      <div>
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          {/* <div
            style={{
              float: "right",
              // width: "120px",
              marginRight: "15px",
              marginBottom: "10px",
              marginLeft: "15px",
            }}
          >
            <Flatpickr
              className="form-control d-block"
              style={{
                width: "200px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
                // paddingLeft: "10px",
                // marginBottom: "10px",
                // float: "right",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value);
              }}
              options={{
                // mode: "range",
                // dateFormat: "d-M-y",
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          </div> */}

          {!isDateFilter && (
            <div
              style={{
                float: "right",
                // width: "120px",
                marginRight: "5px",
                // marginBottom: "20px",
                // marginLeft: "15px",
              }}
            >
              <Flatpickr
                id="customonthPicker"
                className="form-control d-block"
                style={{
                  width: "150px",
                  height: "40px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  textAlignLast: "center",
                }}
                placeholder="Select Month"
                onChange={(value) => {
                  setCustomDatesForGraph(value, true);
                }}
                options={{
                  defaultDate: new Date(),
                  maxDate: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    0
                  ),
                  minDate: new Date().setMonth(new Date().getMonth() - 6),

                  plugins: [
                    new monthSelectPlugin({
                      shorthand: true, //defaults to false
                      dateFormat: "F Y", //defaults to "F Y"
                      altFormat: "F Y", //defaults to "F Y"
                      theme: "light", // defaults to "light"
                    }),
                  ],
                }}
              />
            </div>
          )}
          {isDateFilter && (
            <div
              style={{
                float: "right",
                // width: "120px",
                marginRight: "5px",
                // marginBottom: "20px",
                // marginLeft: "15px",
              }}
            >
              <Flatpickr
                style={{
                  width: "205px",
                  height: "40px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  textAlignLast: "center",
                }}
                id="customDatePicker"
                className="form-control d-block"
                placeholder="Start & End Date"
                onChange={(value) => {
                  setCustomDatesForGraph(value, false);
                }}
                options={{
                  mode: "range",
                  dateFormat: "d-M-y",
                  maxDate: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    0
                  ),
                }}
              />
            </div>
          )}

          <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "10px",
              marginBottom: "10px",
              height: "38px",
              marginLeft: "10px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                value.value === "cm"
                  ? setIsDateFilter(false)
                  : setIsDateFilter(true);
              }}
              defaultValue={dateOptions[0]}
              options={dateOptions}
            />
          </div>

          {/* <input
            style={{
              width: "300px",
              height: "40px",
              borderRadius: "25px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              paddingLeft: "10px",
              marginBottom: "10px",
              float: "right",
            }}
            placeholder="Search By Name/Mobile"
            onChange={(e) => searchKeyword2(e.target.value.trim())}
          /> */}
          {/* <div style={{float:'right', marginBottom:'7px'}}>
            <SearchBar
              placeholder="Search By Name/Mobile/Email/Emp Id"
              searchKeyword={searchKeyword2}
            />
          </div> */}
          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "-5px",
            }}
          >
            <div
              style={{ overflow: "auto", maxHeight: "400px", width: "100%" }}
            >
              <BootstrapTable
                keyField="id"
                data={UploadedFileList}
                columns={columns}
                // selectRow={ selectRow }
              />
            </div>
          </div>
          {UploadedFileList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
          <Modal className="fade" size="xl" show={errorDescModal}>
            <Modal.Header>
              <Modal.Title>Error Description</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setErrorDescModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body style={{ padding: "15px" }}>
              {errorDescription?.map((element, index) => (
                <div>
                  <span>{index + 1 + ") " + element}</span>
                  <br></br>
                  <br></br>
                </div>
              ))}
            </Modal.Body>
          </Modal>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default UploadedFilesList;
