import React, { useState, useEffect } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import ReactGA from "react-ga";

function TransferAllocations(props) {
  const {
    transferModal,
    setTransferModal,
    usersList,
    selectedUserId,
    type,
    deleteIds,
    getFiAllocationsOfEmployees,
    setDeleteIds,
    getFiAllocationsOfAgencies
  } = props;
  const [selectedExecutive, setSelectedExecutive] = useState("");

  useEffect(() => {
    ReactGA.pageview('Transfer FI Allocations');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    let requestPayload = {};
    if (type === "EMPLOYEES") {
      let name = selectedExecutive.label;
      let transferName = name.split('-');
      requestPayload = {
        transferTo: selectedExecutive ? selectedExecutive.value : null,
        transferFrom: selectedUserId,
        transferToName: selectedExecutive ? transferName[0] : null,
        id: deleteIds,
        type: type,
      };
    } else {
      let name = selectedExecutive.label;
      let transferName = name.split('-');
      requestPayload = {
        transferTo: selectedExecutive ? selectedExecutive.value : null,
        transferFrom: selectedUserId,
        transferToName: selectedExecutive ? transferName[0]:null,
        id: deleteIds,
        type: type,
      };
    }

    nodeApi
      .updateFiData(apiCall.transferFiAllocations, requestPayload)
      .then((response) => {
        if (response.success === true) {
          toaster("success", "Transferred successfully");
          setTransferModal(false);
          setDeleteIds([]);
          type=== 'EMPLOYEES' ? getFiAllocationsOfEmployees() : getFiAllocationsOfAgencies();
        } else {
          toaster("error", "Something went wrong. Please try again later.");
          setTransferModal(false);
        }
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
        setTransferModal(false);
      });
  };

  return (
    <Modal className="fade" size="sm" show={transferModal}>
      <Modal.Header>
        <Modal.Title>Transfer Allocations</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => setTransferModal(false)}
        ></Button>
      </Modal.Header>
      <Modal.Body style={{ padding: "15px" }}>
        <div style={{ marginBottom: "5px" }}>
          <label style={{ marginBottom: "0px" }}>
            <h6>{type==="EMPLOYEES" ? "Select Employee" : "Select Agency"}</h6>
          </label>
          <Select
            isSearchable={true}
            options={usersList}
            onChange={(value) => {
              setSelectedExecutive(value);
            }}
          />
          <Row>
            <div>
              <Button
                style={{ float: "right", width: "70px" }}
                type="submit"
                className="col-lg-2 m-2"
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button
                style={{ float: "right", width: "70px" }}
                className="col-lg-2 m-2 float-right"
                variant="danger light"
                onClick={() => setTransferModal(false)}
              >
                Close
              </Button>
            </div>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TransferAllocations;
