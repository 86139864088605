import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import Select from "react-select";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from 'react-ga'

// import Executives from "./Executives";
// import { exec } from "apexcharts";

const ManagerVisitedPlaces = ({
  userId,
  organisationId,
  reportingOfficerId,
}) => {
  const [visitedPlacesList, setVisitedPlacesList] = useState([]);
  const [fieldInvstgtnList, setFieldInvstgtnList] = useState([]);
  const [visitedPlacesDetailsList, setVisitedPlacesDetailsList] = useState([]);
  // const [basicModal, setBasicModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [visitType, setVisitType] = useState("All");
  const [collectionStatuses, setCollectionStatuses] = useState([]);
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [toggle, setToggle] = useState("");
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [totalElements, setTotalElements] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [isFieldInvstgn, setIsFieldInvstgn] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [collectionType, setCollectionType] = useState("All");
  const [showCollectionValue, setShowCollectionValue] = useState(false);
  const [collectionSubStatuses, setCollectionSubStatuses] = useState([]);
  const [collectionSubType, setCollectionSubType] = useState("");
  const [selectedCollectionSubType, setSelectedCollectionSubType] = useState({
    label: "All",
    value: "",
  });
  const [isSubStatus, setIsSubStatus] = useState(false);
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // var datesArray = [];
  var valIndex = 0;

  useEffect(() => {
    ReactGA.pageview('Manager Visits');
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("userDetails"));
    setloggedInUser(userData);
    setDateValue("Last 7 Days", 1);
    loadVistedPlacesData(0);
    addDropDownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVistedPlacesData = (pageNo) => {
    if (visitType !== "Sales") {
      if (visitType === "All" || "Collections") {
        setFlag2(true);
      } else {
        setFlag2(false);
      }
    } else {
      setFlag2(false);
    }
    setIsLoading(true);
    var managerVisitedPlacesBody = {
      searchkey: '',
      endTime: end,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: "",
      startTime: start,
      userId: userId,
      visitType: visitType,
      status: flag ? (collectionType === "All" ? "" : collectionType) : "",
      subStatus: collectionSubType,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeeVisits, pageNo, 10),
        managerVisitedPlacesBody
      )
      .then((response) => {
        var listData = [];
        setCurrPage(pageNo);
        if (visitType !== "FieldInvestigation") {
          response?.content?.forEach((element) => {
            let data = {
              id: element.id,
              userId: element.userId,
              userModel: element.userModel,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,
              organizationId: element.organisationId,
              status: element.status,
              subStatus: element.subStatus,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              customerLatitude:element.customerLatitude,
              customerLongitude:element.customerLongitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: convertEpochTimeToDate(element.dateTime),
              timeSpent: element.timeSpent,
              reportingOfficerId: element.reportingOfficerId,
              salesVisit: element.salesVisit,
              contactNumber: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.contactNumber
                  : "--"
                : element.salesVisit
                ? element.salesVisit.contactNumber
                : "--",
              productName: element.salesVisit
                ? element.salesVisit.productname
                : "-",

              collectionVisit: element.collectionVisit,
              loanNumber: element.collectionVisit
                ? element.collectionVisit.loanNumber
                : "-",
              collectionsContactNumber: element.collectionVisit?.contactNumber,
              customerName: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.customerName
                : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.customerName
                  : "--"
                : element.salesVisit
                ? element.salesVisit.customerName
                : "--",
              fieldInvestigationVisit: element.fieldInvestigationVisit,
            };
            listData.push(data);
          });
          setVisitedPlacesList(listData);
          setIsFieldInvstgn(false);
          setTotalElements(response.totalElements);
          setIsLoading(false);
        } else {
          response?.content?.forEach((element) => {
            let data = {
              userModel: element.userModel,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,

              organizationId: element.organisationId,
              latitude: element.latitude,
              longitude: element.longitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: convertEpochTimeToDate(element.dateTime),
              reportingOfficerId: element.reportingOfficerId,
              fieldInvestigationVisit: element.fieldInvestigationVisit,
              contactNumber: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : "-",
              customerName: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.customerName
                : "-",
            };
            listData.push(data);
          });
          setFieldInvstgtnList(listData);
          setIsFieldInvstgn(true);
          setTotalElements(response.totalElements);
          setIsLoading(false);
        }
      });
  };

  const onPageChange = (selectedPage) => {
    loadVistedPlacesData(selectedPage - 1);
  };

  // const secondsToHms = (d) => {
  //   d = Number(d);
  //   var h = Math.floor(d / 3600);
  //   var m = Math.floor((d % 3600) / 60);
  //   var s = Math.floor((d % 3600) % 60);
  //   var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
  //   var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
  //   var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  //   return hDisplay + mDisplay + sDisplay;
  // };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };
  const columns = [
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Customer Mobile",
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: flag2 ? "Loan No" : "",
      dataField: flag2 ? "loanNumber" : "",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Type",
      dataField: "visitType",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Product",
      dataField: "productName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (row.collectionVisit?.status === "Partially Paid") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Customer Not Available") {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "") {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.status}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (
            row.collectionVisit?.subStatus === "Cash" ||
            row.collectionVisit?.subStatus === "Bank Transfer" ||
            row.collectionVisit?.subStatus === "Already Settled" ||
            row.collectionVisit?.subStatus === "UPI"
          ) {
            return (
              <span
                className="badge badge-rounded badge-success"
                // style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "Customer Not Available"
          ) {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "" ||row.collectionVisit?.subStatus === null ) {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.subStatus}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // localStorage.setItem('executiveVisitedDetails', JSON.stringify(row))
          //setVisitedPlacesDetailsList(JSON.parse(localStorage.getItem('executiveVisitedDetails')));
          setVisitedPlacesDetailsList(row);
          setToggle("chatbox");

          //   <Link  to="/executive-details">
          //         <i className="fa fa-trash"></i>
          //       </Link>
        },
      },
    },
  ];
  const fieldInvstgncolumns = [
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Mobile",
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.customerName
          ? row.fieldInvestigationVisit.customerName
          : "-";
      },
    },
    {
      text: "Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <span
            style={{
              fontWeight: "bold",
              color:
                row.fieldInvestigationVisit?.status === "Positive"
                  ? "green"
                  : "red",
            }}
          >
            {row.fieldInvestigationVisit?.status}
          </span>
        );
      },
    },
    {
      text: "Rejection Type",
      dataField: "rejectionType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.rejectionType
          ? row.fieldInvestigationVisit.rejectionType
          : "-";
      },
    },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-success shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          // setBasicModal(true);
          setToggle("chatbox");
        },
      },
    },
  ];

  const optionDates = [
    { label: "Last 7 Days", value: 1 },
    { label: "Today", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  // const collectionValues = [
  //   { label: "All", value: "All" },
  //   { label: "Customer Not Available", value: "Customer Not Available" },
  //   { label: "Paid", value: "Paid" },
  //   { label: "PTP", value: "PTP" },
  //   // { label: "Partially Paid", value: "Partially Paid" },
  //   { label: "RTP", value: "RTP" },
  //   { label: "Repossessed", value: "Repossessed" },
  //   { label: "Vacant", value: "Vacant" },
  //   { label: "ThirdParty", value: "ThirdParty" },
  // ];

  const getCollectionDropdownOptions = () => {
    var payload = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
    };

    moduleApi.postData(apiCall.getCollectionsStatus, payload).then((response) => {
      const result = response.map((item) => ({
        label: item,
        value: item,
      }));
      result.unshift({label:"All", value:"All"})
      // console.log('result', result);

      setCollectionStatuses(result);
    });
  };

  const setCollectionTypeValue = (value) => {
    setCollectionType(value.value);
  };

  const addDropDownOptions = () => {
    var opts = [];
    opts.push({ label: "All", value: 3 });
    hasAccess(3) && opts.push({ label: "Sales", value: 1 });
    hasAccess(4) && opts.push({ label: "Collections", value: 2 });
    hasAccess(5) && opts.push({ label: "Field Investigation", value: 4 });
    setDropDownOptions(opts);
  };

  const setVisitValue = (label, value) => {
    switch (value) {
      case 1:
        setVisitType("Sales");
        setShowCollectionValue(false);
        return;
      case 2:
        getCollectionDropdownOptions()
        setVisitType("Collections");
        setShowCollectionValue(true);
        setFlag(true);
        break;
      case 3:
        setVisitType("All");
        setShowCollectionValue(false);
        break;
      case 4:
        setVisitType("FieldInvestigation");
        setShowCollectionValue(false);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  const setDateValue = (label, value) => {
    var date;

    switch (value) {
      case 1:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        // start = Math.floor(date[0].getTime() / 1000);
        // end = Math.floor(date[1].getTime() / 1000);
        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        // loadVistedPlacesData();
        return;
      case 2:
        var today = new Date();

        // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
        setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));

        setShowDate(false);
        //loadVistedPlacesData();
        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];

        setStart(Math.floor(date[0].getTime() / 1000));
        setEnd(Math.floor(date[1].getTime() / 1000));

        setShowDate(false);
        return;
      case 4:
        setShowDate(true);
        break;
      default:
        console.log("default 4 ", showDate);
    }
  };

  function setCustomDates(dateStrings) {
    if (dateStrings.length > 1) {
      // start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
      // end = Math.floor(new Date(dateStrings[1]).getTime() / 1000);
      setStart(Math.floor(dateStrings[0].getTime() / 1000));
      setEnd(Math.floor(dateStrings[1].getTime() / 1000) + 86400);
    }
    //loadVistedPlacesData();
  }

  const getSubStatusCollectionOptions = (subStatus) => {
    var payload = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      status: subStatus,
    };

    moduleApi
      .postData(apiCall.getCollectionsStatus, payload)
      .then((response) => {
        const result = response.map((item) => ({
          label: item,
          value: item,
        }));
        result.unshift({ label: "All", value: "" });
        if(response.length>0){
          setCollectionSubType("");
          setSelectedCollectionSubType({ label: "All", value: "" });
          setIsSubStatus(true)
        }else{
          setCollectionSubType("");
          setSelectedCollectionSubType({ label: "All", value: "" });
          setIsSubStatus(false)
        }
        // console.log('result', result);

        setCollectionSubStatuses(result);
      });
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <label style={{ fontWeight: "bold" }}>
            Visit Type
          </label>
          <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "20px",
              // marginBottom: "20px",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) =>
                setVisitValue(value.label, value.value, dropDownOptions)
              }
              defaultValue={{ label: "All", value: 3 }}
              options={dropDownOptions}
            />
          </div>
        </div>
        {/* After selecting collection need to show dropdown */}
        {showCollectionValue && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <label style={{ fontWeight: "bold" }}>
              Status
            </label>
            <div
              style={{
                float: "right",
                width: "150px",
                marginRight: "20px",
                // marginBottom: "20px",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) => {
                  setCollectionTypeValue(value);
                  getSubStatusCollectionOptions(value.value);
                }}
                defaultValue={{ label: "All", value: "All" }}
                options={collectionStatuses}
              />
            </div>
          </div>
        )}

        {showCollectionValue && isSubStatus && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <label style={{ fontWeight: "bold" }}>
              Sub Status
            </label>
            <div
              style={{
                float: "right",
                width: "150px",
                marginRight: "20px",
                // marginBottom: "20px",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) => {
                  setCollectionSubType(value.value);
                  setSelectedCollectionSubType(value);
                }}
                defaultValue={{ label: "All", value: "All" }}
                options={collectionSubStatuses}
                value={selectedCollectionSubType}
              />
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <label style={{ fontWeight: "bold" }}>
            Select Range
          </label>
          <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "20px",
              // marginBottom: "20px",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) =>
                setDateValue(value.label, value.value, optionDates)
              }
              defaultValue={optionDates[valIndex]}
              options={optionDates}
            />
          </div>
        </div>
        {showDate && (
          <div style={{ marginRight: "20px", float: "right", marginTop: "20px" }}>
            <Flatpickr
              className="form-control d-block"
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )}
        <div
          style={{
            //float: "right",
            marginTop: "20px",
            marginRight: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadVistedPlacesData(0)}
          >
            Submit
          </Button>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            {" "}
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
        <div style={{overflow:'auto', maxHeight:'400px'}}>
          {isFieldInvstgn ? (
            <BootstrapTable
              keyField="panNo"
              data={fieldInvstgtnList}
              columns={fieldInvstgncolumns}
            />
          ) : (
            <BootstrapTable
              keyField="panNo"
              data={visitedPlacesList}
              columns={columns}
            />
          )}
          </div>
          {fieldInvstgtnList.length === 0 && visitedPlacesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      {toggle === "chatbox" && (
        <VisitedPlaceDrawer
          visitedPlacesDetailsList={visitedPlacesDetailsList}
          closeDrawer={() => setToggle(false)}
          reLoad={() => loadVistedPlacesData(currPage)}
        />
      )}
      <div>
        {/* <Modal className="fade" show={basicModal} size="lg">
          <Modal.Header>
            <Modal.Title>Visited Place Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setBasicModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{ backgroundColor: "rgb(243,240,249)" }}
                >
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <h6 className="fs-18 font-w600 my-1">Visit Details</h6>
                  </div>

                  <div className="card-body user-profile">
                    <img
                      //src={defaultImage}
                      style={{ height: "90px" }}
                      src={
                        visitedPlacesDetailsList.image
                          ? visitedPlacesDetailsList.image
                          : defaultImage
                      }
                      alt=""
                      className="img-fluid rounded-circle"
                    />

                    <div className="media-body user-meta-info">
                      <h6 className="fs-18 font-w600 my-1">
                        {visitedPlacesDetailsList.name ? visitedPlacesDetailsList.name : "-"}
                      </h6>
                      <h6 className="fs-16 font-w400 my-1">
                        {visitedPlacesDetailsList.dateTime
                          ? visitedPlacesDetailsList.dateTime
                          : "-"}
                      </h6>
                      <h6 className="fs-16 font-w400 my-1">
                        {visitedPlacesDetailsList.comments
                          ? visitedPlacesDetailsList.comments
                          : "-"}
                      </h6>
                      <h6 className="fs-16 font-w400 my-1">
                        {visitedPlacesDetailsList.status
                          ? visitedPlacesDetailsList.status
                          : "-"}
                      </h6>
                      <h6 className="fs-16 font-w400 my-1">
                        {visitedPlacesDetailsList.address
                          ? visitedPlacesDetailsList.address
                          : "-"}
                      </h6>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{ backgroundColor: "rgb(243,240,249)" }}
                >
                  <div
                    style={{
                      marginRight: '20px',
                      marginBottom: "10px",
                      marginTop: '5px',
                      marginLeft: '10px'
                    }}
                  >
                    <h6 className="fs-18 font-w600 my-1 " style={{ float: 'left' }}>Executive Details</h6>
                  </div>
                  <table style={{ marginLeft: '10px' }}>
                    <>
                      <tr></tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.name
                            ? visitedPlacesDetailsList.name
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Mobile</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.mobileNumber
                            ? visitedPlacesDetailsList.mobileNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Email</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.email
                            ? visitedPlacesDetailsList.email
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Role Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.roleName
                            ? visitedPlacesDetailsList.roleName
                            : "-"}
                        </td>
                      </tr>
                    </>
                  </table>

                  <div
                    style={{
                      marginRight: '20px',
                      marginBottom: "10px",
                      marginTop: '40px',
                      marginLeft: '10px'
                    }}
                  >

                    <h6 className="fs-18 font-w600 my-1" style={{float:'left'}}>Customer Details</h6>
                  </div>

                  {visitedPlacesDetailsList.collectionVisit && <table style={{ marginBottom: "15px", marginLeft: '10px' }}>
                    <>
                      <tr></tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.collectionVisit.customerName
                            ? visitedPlacesDetailsList.collectionVisit.customerName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Mobile</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.collectionVisit.contactNumber
                            ? visitedPlacesDetailsList.collectionVisit.contactNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Status</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.status
                            ? visitedPlacesDetailsList.status
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Amount Paid</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.collectionVisit.amountPaid
                            ? visitedPlacesDetailsList.collectionVisit.amountPaid
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Payment Date By</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.collectionVisit.paymentDateBy
                            ? visitedPlacesDetailsList.collectionVisit.paymentDateBy
                            : "-"}
                        </td>
                      </tr>


                    </>
                  </table>}

                  {visitedPlacesDetailsList.salesVisit && <table style={{ marginBottom: "15px", marginLeft: '10px' }}>
                    <>
                      <tr></tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.salesVisit.customerName
                            ? visitedPlacesDetailsList.salesVisit.customerName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Mobile</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.salesVisit.contactNumber
                            ? visitedPlacesDetailsList.salesVisit.contactNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "500", float: 'left' }}>Product Name</td>
                        <td>&nbsp;:&nbsp; </td>
                        <td style={{ fontWeight: "bold", float: 'left' }}>
                          {visitedPlacesDetailsList.salesVisit.productname
                            ? visitedPlacesDetailsList.salesVisit.productname
                            : "-"}
                        </td>
                      </tr>
                    </>
                  </table>}
                </div>
              </Col>
            </Row>
            
          </Modal.Body>
        </Modal> */}
      </div>
    </div>
  );
};

export default ManagerVisitedPlaces;
