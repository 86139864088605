import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { isAdmin } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";

const PtpCount = ({
  startDate,
  endDate,
  visitType,
  userDetails,
  isDefault,
  totalPtpCount
}) => {
  var history = useHistory();

  const [data, setData] = useState([]);
  const [currPage] = useState(0);
  const [totalElements, setTotalElements] = useState(1);
  const [mgrId, setMgrId] = useState(null);

  useEffect(() => {
    if (isDefault) return;

    if (
      isAdmin() ||
      userDetails.autoFinanceUser.subOrdinateManagersIds.length > 0
    ) {
      setMgrId("");
      loadData(0, "", isDefault);
      return;
    }

    setMgrId(userDetails.autoFinanceUser.userId);
    loadData(0, userDetails.autoFinanceUser.userId, isDefault);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefault]);

 
  const executiveColumns = [

    {
      text: "Employee Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
    text: "Branch",
    dataField: "branchName",
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "rgb(243,240,249)",
      fontWeight: "bold",
      fontFamily:
        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    },
    style: {
      borderColor: "white",
      fontFamily:
        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    },
  },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Count",
      dataField: "ptpCount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let path = "/home";
          path += "/";
          path += row.userId;
          path += "/";
          path += startDate;
          path += "/";
          path += endDate;
          path += "/";
          path += visitType;
          history.push(path);

          // setMgrId(row.userId)
          // loadData(0, row.userId)
          // loadVistedPlacesData(row.userId)
        },
      },
    },
  ];

  const loadData = (pageNo, userId, isLevelChanged) => {
    // console.log(pageNo, userId, isLevelChanged, "params");
    setData([]);
    let array = [];

    var status=visitType.replace(/-/g, '');

    var payLoad = {
      endDate: endDate,
      organizationId: userDetails?.autoFinanceUser?.organizationId,
      reportingOfficerId: !isAdmin() ? userDetails?.autoFinanceUser?.userId : "",
      startDate: startDate,
      status:status
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeePtpSummaryCount, pageNo, 10),
        payLoad
      )
      .then((response) => {
       
          response?.content.forEach((element) => {
              let data3 = {
                empName: element.empName,
                ptpCount: element.ptpCount,
                userId: element.userId,
                designation: element.designation,
                branchId:element.branchId,
                branchName:element.branchName,
              };
              array.push(data3);
            
          });
        
        setData(array);
        setTotalElements(response?.totalElements);
        
        // setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    // console.log(selectedPage - 1, mgrId, isDefault);
    loadData(selectedPage - 1, mgrId, isDefault);
  };

  return (
    <div>
       {totalPtpCount >= 1 ? (
                <div
                  style={{
                    float:'right',
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "30px",
                    marginTop: "10px",
                    marginBottom:'5px'
                  }}
                >
                  <b>Total Count: &nbsp;{totalPtpCount}</b>
                </div>
              ) : null}
      {data.length >= 1 ? (
        <BootstrapTable
          keyField="count"
          data={data}
          columns={executiveColumns}
          id="subordinateexecutivevisitcount"
        />
      ) : null}
      {data.length === 0 ? (
        <div>
          <img src={NO_DATA_FOUND} alt="" />
        </div>
      ) : null}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <PaginationComponent
          totalItems={totalElements}
          pageSize={10}
          defaultActivePage={currPage + 1}
          onSelect={onPageChange}
        />
      </div>
    </div>
  );
};

export default PtpCount;
