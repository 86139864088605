import swal from "sweetalert";
import { sendDataToDB, sendErrorDataToDB } from "./Helper";
import { ref, child, get } from "firebase/database";
import { database } from "./Firestore";
import { getTokenInLocalStorage, hasAccess } from "../services/AuthService";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { store } from "../store/store";
import {
  setModal,
  setAllocationId,
  setIsOnline,
  setRingingTime,
  setTalkTime,
  setDispoTime,
  setCallStatusText,
  setIsCallAnswered,
  setIsOnGoingCall,
  setIsMobileField,
  setIsCallRejected,
  setIsCallActive,
  setWebRtcMobile,
  setCallAudioModal,
  setModalHeaderText,
  setIsHold,
  setIsMute,
  setCallRemarksModal,
  setHoldTime,
  setCallId,
  setSelectedAgentStatus,
  setIsTestCall,
  setAllocationDetails,
  setType,
  setDailingTime,
  setIsRinging,
  setCallStatus,
  setIsModalMinimised,
  setIsIncomingCall,
  setIncomingLoanNumbers,
  setIsIncomingLoanNoDropdown,
  setIsAddSaleLeadButton,
  setPiopiy,
  setCallType,
  setSelectedLoanDropDownValue,
  setSearchedLoanNoValue,
} from "../store/actions/CallingActions";
import { setSkipIndex } from "../store/actions/MyAllocationActions";
import incomingCall from "../images/Audio/incoming_call.mp3";
// import { useSelector } from "react-redux";

let dailingTempSecs = 0;
let ringingTempSecs = 0;
let talkTempSecs = 0;
let holdTempSecs = 0;
let holdTempSecs2 = 0;
// eslint-disable-next-line
let tryingFlag = false;
// eslint-disable-next-line
let dailingFlag = false;
let ringingFlag = false;
let talkFlag = false;
let holdFlag = false;

// let userLoginDetails = getTokenInLocalStorage();
const audioRef = new Audio(incomingCall);
audioRef.loop = true;

export const StartTelecalling = (
  piopiy,
  dispatch,
  userId,
  password,
  isTestCall,
  callType,
  skipIndex
) => {
  piopiy.login(userId, password, "sbcind.telecmi.com");
  console.log("login Initiated..............");
  piopiy.on("login", (object) => {
    console.log(object);
    console.log("login successfully......");
    dispatch(setPiopiy(piopiy));
    dispatch(setIsOnline(true));
    localStorage.setItem("isCallInProgress", false);
    // const state= store.getState()
    // console.log(state.calls);
  });

  piopiy.on("logout", (object) => {
    console.log(object);
    console.log("logout successfully");
    dispatch(setIsOnline(false));
  });
  piopiy.on("loginFailed", (object) => {
    console.log(object);
    if (object.code === 401) {
      swal("Web Calling Login Failed - " + object.status, {
        icon: "error",
        dangerMode: true,
      });
    }
  });

  piopiy.on("error", (object) => {
    console.log(object);
    if (object.code === 408 || object.code === 486) {
      endedEvent(object, dispatch, audioRef);
    }
    console.log("Error from Telecmi", object.code, "--", object.status);
  });

  piopiy.on("ended", (object) => {
    console.log(object);
    endedEvent(object, dispatch, audioRef);
  });

  // piopiy.on("rejected", (object) => {
  //   console.log(object);
  // });

  piopiy.on("answered", (object) => {
    console.log(object);
    dispatch(setCallId(piopiy.getCallId()));
    answerEvent(object, dispatch, audioRef);
  });

  piopiy.on("hangup", (object) => {
    console.log(object);
    const state = store.getState();
    hasAccess(117) && stopAudio(audioRef);
    if (dailingFlag === true || talkFlag === true || ringingFlag === true) {
      sendDataToDB("Disposition", localStorage.getItem("todayCallsCount"));
      if (talkFlag === false && ringingFlag === true) {
        var temp1 = new Date().getTime();
        var secs1 = Math.trunc(temp1 / 1000) - ringingTempSecs;
        console.log("RingSecs", secs1);
        dispatch(setRingingTime(secs1));
        dispatch(setTalkTime(0));
        ringingFlag = false;
      }
      if (talkFlag === true) {
        var temp = new Date().getTime();
        var secs = Math.trunc(temp / 1000) - talkTempSecs;
        console.log("TalkSecs", secs);
        dispatch(setTalkTime(secs));
        talkFlag = false;
      }

      if (holdFlag === true) {
        var temp3 = new Date().getTime();
        var secs2 = Math.trunc(temp3 / 1000) - holdTempSecs;
        // eslint-disable-next-line
        holdTempSecs2 += secs2;
        // console.log("tempHoldSecs", secs);

        localStorage.setItem("holdTime", holdTempSecs2);
        localStorage.removeItem("holdStartTime");
        dispatch(setHoldTime(holdTempSecs2));
        holdFlag = false;
      }

      var temp2 = new Date().getTime();
      dispatch(setDispoTime(Math.trunc(temp2 / 1000)));
      dispatch(setIsCallAnswered(false));
      dispatch(setIsOnGoingCall(false));
      dispatch(setIsMobileField(true));
      dispatch(setIsHold(false));
      dispatch(setIsMute(false));
      dispatch(setIsCallRejected(true));
      // dispatch(setIsCallActive(false));
      dispatch(setSelectedLoanDropDownValue(null));
      dispatch(setSearchedLoanNoValue(""));
      dispatch(setIsIncomingLoanNoDropdown(false));
      dispatch(setIsModalMinimised(false));
      dispatch(setIsAddSaleLeadButton(false));
      dispatch(setCallStatusText("Calling"));
      if (localStorage.getItem("isModalOpen") === "true") {
        dispatch(setCallAudioModal(false));
        dispatch(setModal(false));
      } else if (localStorage.getItem("isModalOpen") === "false") {
        dispatch(setCallAudioModal(true));
      }
      dispatch(setModalHeaderText("Allocated"));
      dispatch(setWebRtcMobile(null));
      dispatch(setSkipIndex(skipIndex));
      localStorage.setItem("isCallInProgress", false);
      parseInt(localStorage.getItem("allocationId")) !== null &&
        parseInt(localStorage.getItem("allocationId")) !== "" &&
        swal("Call has been Ended", {
          icon: "success",
          timer: state?.calls?.allocationId !== null ? 0 : 3000,
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then((willOk) => {
          if (
            isNaN(parseInt(localStorage.getItem("allocationId"))) === false &&
            state?.calls?.allocationId !== null &&
            // isTestCall === false &&
            localStorage.getItem("type") !== "undefined" &&
            parseInt(localStorage.getItem("allocationId")) !== null &&
            parseInt(localStorage.getItem("allocationId")) !== ""
          ) {
            dispatch(setCallRemarksModal(true));
          } else {
            dispatch(setCallAudioModal(false));
          }
        });
    } else if (object.code === 200 && hasAccess(116)) {
      dispatch(setCallAudioModal(false));
      dispatch(setIsCallActive(false));
      dispatch(setIsIncomingCall(false));
      dispatch(setIsModalMinimised(false));
      dispatch(setIsIncomingLoanNoDropdown(false));
      dispatch(setSelectedLoanDropDownValue(null));
      dispatch(setSearchedLoanNoValue(""));
      swal("Call has been Ended", {
        icon: "success",
        timer: 3000,
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  });

  // piopiy.on("mediaFailed", (object) => {
  //   console.log(object);
  // });

  // piopiy.on("RTCStats", (object) => {
  //   console.log(object);
  // });
  if (hasAccess(116)) {
    piopiy.on("inComingCall", (object) => {
      console.log(object);
      console.log(piopiy.getCallId());
      const state = store.getState();
      if (state?.calls?.isCallActive === true) {
        piopiy.terminate();
      } else {
        hasAccess(117) && playAudio(audioRef);
        var temp = new Date().getTime();
        // eslint-disable-next-line
        dailingTempSecs = Math.trunc(temp / 1000);
        // eslint-disable-next-line
        dailingFlag = true;
        let userData = getTokenInLocalStorage();
        console.log("incomingCall ", object);
        dispatch(setCallAudioModal(true));
        dispatch(setCallType("incoming"));
        dispatch(setIsIncomingCall(true));
        // dispatch(setAllocationId(null));
        dispatch(setIsCallRejected(false));
        dispatch(setAllocationDetails(null));
        localStorage.setItem(
          "incomingCallMobileNo",
          object?.from + "-" + Math.floor(Date.now() / 1000)
        );
        localStorage.setItem("callType", "incoming");
        dispatch(setWebRtcMobile(object?.from));
        dispatch(setModalHeaderText("Incoming"));
        dispatch(setType(userData?.selectedTeam?.teamType));
        console.log(piopiy.getCallId());
      }
    });
  }

  piopiy.on("ringing", (object) => {
    console.log(object);
    console.log(piopiy.getCallId());
    const state = store.getState();
    if (!hasAccess(116) && object.type === "incoming") {
      piopiy.terminate();
    } else if (
      state?.calls?.isCallActive === true &&
      object.type === "incoming"
    ) {
      piopiy.terminate();
    }
    if (dailingFlag === true) {
      // console.log(piopiy.getCallId());
      // console.log(object);
      dispatch(setCallStatusText("Ringing"));
      sendDataToDB("Ringing", localStorage.getItem("todayCallsCount"));
      var temp = new Date().getTime();
      var secs = Math.trunc(temp / 1000) - dailingTempSecs;
      console.log("DailingSecs", secs);

      dispatch(setDailingTime(secs));

      var temp2 = new Date().getTime();
      // eslint-disable-next-line
      ringingTempSecs = Math.trunc(temp2 / 1000);
      ringingFlag = true;
      dailingFlag = false;
    }
  });

  // piopiy.on("dtmf", (object) => {
  //   console.log(object);
  // });

  piopiy.on("hold", (object) => {
    console.log(object);
    sendDataToDB("Hold", localStorage.getItem("todayCallsCount"));
    var temp = new Date().getTime();
    // eslint-disable-next-line
    holdTempSecs = Math.trunc(temp / 1000);
    localStorage.setItem("holdStartTime", Math.trunc(temp / 1000));
    holdFlag = true;
  });

  piopiy.on("unhold", (object) => {
    console.log(object);
    const holdStartTime = localStorage.getItem("holdStartTime");
    if (holdStartTime) {
      sendDataToDB("Talking", localStorage.getItem("todayCallsCount"));
      var temp = new Date().getTime();
      var secs = Math.trunc(temp / 1000) - holdTempSecs;
      // eslint-disable-next-line
      holdTempSecs2 += secs;
      console.log("tempHoldSecs", secs);

      localStorage.setItem("holdTime", holdTempSecs2);
      localStorage.removeItem("holdStartTime");
      dispatch(setHoldTime(holdTempSecs2));
      holdFlag = false;
    }
  });

  // piopiy.on("missed", (object) => {
  //   console.log(object);
  // });
  piopiy.on("trying", async (object) => {
    console.log("callid in trying event - ", piopiy.getCallId());
    console.log(object);
    let userLoginDetails = getTokenInLocalStorage();
    dispatch(setCallStatusText("Calling"));
    dispatch(setCallType("outgoing"));
    dispatch(setIsOnGoingCall(true));
    dispatch(setIsRinging(true));
    var temp = new Date().getTime();
    // eslint-disable-next-line
    dailingTempSecs = Math.trunc(temp / 1000);
    // eslint-disable-next-line
    dailingFlag = true;

    const dbRef = ref(database);
    await get(
      child(
        dbRef,
        "agentLiveStatus/" + userLoginDetails?.autoFinanceUser?.organizationId
      )
    )
      .then((snapshot) => {
        let temp = snapshot.exists();
        if (temp === true) {
          const uniqueObjectsList = Object.values(snapshot.val()).reduce(
            (accumulator, currentValue) => {
              const userId = currentValue.userId;
              if (!accumulator.some((obj) => obj.userId === userId)) {
                accumulator.push(currentValue);
              }
              return accumulator;
            },
            []
          );
          uniqueObjectsList.forEach((element) => {
            if (userLoginDetails?.autoFinanceUser?.userId === element.userId) {
              let currentDayStart = Math.floor(
                new Date(new Date().setHours(0, 0, 0)).getTime() / 1000
              );
              let currentDayEnd = Math.floor(
                new Date(new Date().setHours(23, 59, 59)).getTime() / 1000
              );

              if (
                element.createdTime > currentDayStart &&
                element.createdTime < currentDayEnd
              ) {
                if (piopiy.getCallId() !== localStorage.getItem("callId")) {
                  // var tempCount = localStorage.getItem("todayCallsCount");
                  localStorage.setItem(
                    "todayCallsCount",
                    parseInt(element.todayCallsCount) + 1
                  );
                  localStorage.setItem("callId", piopiy.getCallId());
                }
              } else {
                localStorage.setItem("todayCallsCount", parseInt(1));
              }
            }
          });
          sendDataToDB("Dailing", localStorage.getItem("todayCallsCount"));
        } else {
          sendErrorDataToDB(snapshot.exists());
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
        sendErrorDataToDB(error);
      });

    dispatch(setCallId(piopiy.getCallId()));
    CallInitiated(piopiy.getCallId());
  });

  // piopiy.on("callStream", (object) => {
  //   console.log(object);
  // });

  // piopiy.on("transfer", (object) => {
  //   console.log(object);
  // });

  // piopiy.on("merge", (object) => {
  //   console.log(object);
  // });
};

const answerEvent = (object, dispatch, audioRef) => {
  // dispatch(setCallStatusText("Talking"));
  const state = store.getState();
  sendDataToDB("Talking", localStorage.getItem("todayCallsCount"));
  dispatch(setCallStatus(false));
  dispatch(setIsIncomingCall(false));
  var type = localStorage.getItem("callType");
  type === "incoming" && GetIncomingCallLoanNumbers(dispatch);
  state?.calls?.callType === "incoming" &&
    hasAccess(117) &&
    stopAudio(audioRef);

  var temp = new Date().getTime();
  var tempSecs = ringingTempSecs !== 0 ? ringingTempSecs : 30;
  var secs = Math.trunc(temp / 1000) - tempSecs;
  console.log("RingSecs", secs);
  dispatch(setRingingTime(secs));
  ringingFlag = false;

  var temp2 = new Date().getTime();
  // eslint-disable-next-line
  talkTempSecs = Math.trunc(temp2 / 1000);
  talkFlag = true;
  dispatch(setIsCallAnswered(false));
  dispatch(setIsOnGoingCall(true));
  dispatch(setIsCallRejected(false));
  // dispatch(setIsCallActive(true));
  dispatch(setIsRinging(false));
  console.log("Call Answered");
};

const endedEvent = (object, dispatch, audioRef) => {
  const state = store.getState();
  hasAccess(117) && stopAudio(audioRef);
  if (talkFlag === true || ringingFlag === true) {
    sendDataToDB("Disposition", localStorage.getItem("todayCallsCount"));
    if (talkFlag === false && ringingFlag === true) {
      var temp1 = new Date().getTime();
      var tempSecs = ringingTempSecs !== 0 ? ringingTempSecs : 30;
      var secs1 = Math.trunc(temp1 / 1000) - tempSecs;
      console.log("RingSecs", secs1);
      dispatch(setRingingTime(secs1));
      dispatch(setTalkTime(0));
      ringingFlag = false;
    }
    if (talkFlag === true) {
      var temp = new Date().getTime();
      var secs = Math.trunc(temp / 1000) - talkTempSecs;
      console.log("TalkSecs", secs);
      dispatch(setTalkTime(secs));
      talkFlag = false;
    }

    if (holdFlag === true) {
      var temp3 = new Date().getTime();
      var secs3 = Math.trunc(temp3 / 1000) - holdTempSecs;
      // eslint-disable-next-line
      holdTempSecs2 += secs3;
      // console.log("tempHoldSecs", secs);

      localStorage.setItem("holdTime", holdTempSecs2);
      localStorage.removeItem("holdStartTime");
      dispatch(setHoldTime(holdTempSecs2));
      holdFlag = false;
    }

    var temp2 = new Date().getTime();

    dispatch(setDispoTime(Math.trunc(temp2 / 1000)));
    dispatch(setCallStatusText("Calling"));
    dispatch(setIsCallAnswered(false));
    dispatch(setIsOnGoingCall(false));
    dispatch(setIsMobileField(true));
    dispatch(setIsCallRejected(true));
    // dispatch(setIsCallActive(false));
    dispatch(setSelectedLoanDropDownValue(null));
    dispatch(setSearchedLoanNoValue(""));
    dispatch(setIsModalMinimised(false));
    dispatch(setWebRtcMobile(null));
    dispatch(setIsIncomingLoanNoDropdown(false));
    dispatch(setIsAddSaleLeadButton(false));
    if (localStorage.getItem("isModalOpen") === "true") {
      dispatch(setCallAudioModal(false));
      dispatch(setModal(false));
    } else if (
      localStorage.getItem("isModalOpen") === "false" &&
      parseInt(localStorage.getItem("allocationId")) !== null &&
      parseInt(localStorage.getItem("allocationId")) !== ""
    ) {
      dispatch(setCallAudioModal(true));
    }
    dispatch(setModalHeaderText("Allocated"));
    dispatch(setIsHold(false));
    dispatch(setIsMute(false));
    localStorage.setItem("isCallInProgress", false);

    parseInt(localStorage.getItem("allocationId")) !== null &&
      parseInt(localStorage.getItem("allocationId")) !== "" &&
      swal("Call has been Ended", {
        icon: "success",
        timer: state?.calls?.allocationId !== null ? 0 : 3000,
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then((willOk) => {
        if (
          isNaN(parseInt(localStorage.getItem("allocationId"))) === false &&
          state?.calls?.allocationId !== null &&
          // isTestCall !== true &&
          localStorage.getItem("type") !== "undefined" &&
          parseInt(localStorage.getItem("allocationId")) !== null &&
          parseInt(localStorage.getItem("allocationId")) !== ""
        ) {
          dispatch(setCallRemarksModal(true));
        } else {
          dispatch(setCallAudioModal(false));
        }
      });
  } else if (object.code === 200) {
    dispatch(setCallAudioModal(false));
    dispatch(setIsCallActive(false));
    dispatch(setIsIncomingCall(false));
    dispatch(setIsModalMinimised(false));
    dispatch(setIsIncomingLoanNoDropdown(false));
    dispatch(setSelectedLoanDropDownValue(null));
    dispatch(setSearchedLoanNoValue(""));
    swal("Call has been Ended", {
      icon: "success",
      timer: state?.calls?.allocationId !== null ? 0 : 3000,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  }
};

export const GetIncomingCallLoanNumbers = async (dispatch) => {
  let userData = getTokenInLocalStorage();
  let incomingMobileNo = localStorage
    .getItem("incomingCallMobileNo")
    .split("-");
  var mobileNo = parseInt(incomingMobileNo[0]?.slice(2));
  var payLoad = {
    contactNumber: mobileNo,
    teamType: userData?.selectedTeam?.teamType,
    // teamType: "Sales",
    organizationId: userData?.autoFinanceUser?.organizationId,
  };
  await moduleApi
    .postData(apiCall.getIncomingCallLoanNumbers, payLoad)
    .then((response) => {
      if (response?.length > 1 && payLoad.teamType === "Collections") {
        const result = response.map((item) => ({
          label: item.loanNumber + " - " + item.customerName,
          // payLoad.teamType === "Sales"
          //   ? item.customerName + " - " + item.phoneNumber
          //   : item.loanNumber + " - " + item.customerName,
          value: item.id,
          data: item,
        }));
        dispatch(setIncomingLoanNumbers(result));
        dispatch(setIsIncomingLoanNoDropdown(true));
      } else if (response?.length === 1) {
        dispatch(setAllocationId(response[0]?.id));
        dispatch(setAllocationDetails(response[0]));
        dispatch(setIsIncomingLoanNoDropdown(false));
      } else if (response.length === 0) {
        dispatch(setIsIncomingLoanNoDropdown(false));
        dispatch(setIsAddSaleLeadButton(true));
      }
      dispatch(setIsIncomingCall(false));
      payLoad.teamType === "Sales" && dispatch(setIsAddSaleLeadButton(true));

      // console.log("response", response);
    });
};

export const StartCall = (
  piopiy,
  dispatch,
  mobileNo,
  isOpen,
  allocationId,
  allocationDetails,
  type,
  isCallActive
) => {
  // if (isCallActive === true) {
  //   return swal("Already Call is in Progress, Unable to make another call", {
  //     icon: "error",
  //     dangerMode: true,
  //   });
  // }
  let incomingMobileNo = localStorage
    .getItem("incomingCallMobileNo")
    ?.split("-");
  if (incomingMobileNo) {
    if (
      incomingMobileNo[0]?.slice(2) === mobileNo &&
      Math.floor(Date.now() / 1000) - incomingMobileNo[1] < 300
    ) {
      return swal(
        "An incoming call was already made to this mobile number. Please try again after 5 minutes.",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }
  }
  dailingTempSecs = 0;
  ringingTempSecs = 0;
  talkTempSecs = 0;
  holdTempSecs = 0;
  holdTempSecs2 = 0;

  if (allocationId !== null && allocationId !== "") {
    dispatch(setIsTestCall(false));
    dispatch(setModalHeaderText("Allocated"));
  } else {
    dispatch(setModalHeaderText("Test"));
    dispatch(setIsTestCall(true));
  }
  dispatch(setType(type));
  dispatch(setAllocationId(allocationId));
  dispatch(setAllocationDetails(allocationDetails));
  localStorage.removeItem("holdTime");
  localStorage.setItem("type", type);
  localStorage.setItem("allocationId", allocationId);
  localStorage.setItem("calledNumber", mobileNo);
  localStorage.setItem("callType", "outgoing");
  dispatch(setWebRtcMobile(mobileNo));
  piopiy.call("91" + mobileNo);
  console.log("calledNumber", "91" + mobileNo);
  dispatch(setIsCallActive(true));

  if (isOpen === true) {
    localStorage.setItem("isModalOpen", false);
  } else {
    localStorage.setItem("isModalOpen", true);
  }

  dispatch(setIsCallAnswered(true));

  dispatch(setCallAudioModal(true));
  dispatch(setModal(true));
  dispatch(setIsMobileField(false));
  dispatch(setIsCallRejected(false));
  dispatch(setIsIncomingLoanNoDropdown(false));
  dispatch(setIsAddSaleLeadButton(false));
  localStorage.setItem("isCallInProgress", true);
  // eslint-disable-next-line
  tryingFlag = true;
};

export const EndCall = (piopiy, dispatch) => {
  piopiy.terminate();
  dispatch(setWebRtcMobile(null));
  if (localStorage.getItem("isModalOpen") === "true") {
    dispatch(setCallAudioModal(false));
  } else if (localStorage.getItem("isModalOpen") === "false") {
    dispatch(setCallAudioModal(true));
  }
  dispatch(setModalHeaderText("Manual"));
  dispatch(setIsOnGoingCall(false));
  dispatch(setIsHold(false));
  dispatch(setIsMute(false));
  dispatch(setIsMobileField(true));
  dispatch(setIsCallRejected(true));
  // dispatch(setIsCallActive(false));
  localStorage.setItem("isCallInProgress", false);
  // localStorage.removeItem("allocationId");
  localStorage.removeItem("calledNumber");
};

export const Logout = (piopiy, dispatch) => {
  piopiy.logout();
  sendDataToDB("Logged Out", localStorage.getItem("todayCallsCount"));
  dispatch(setIsOnline(false));
  dispatch(setWebRtcMobile(null));
  dispatch(setIsOnGoingCall(false));
  dispatch(setIsHold(false));
  dispatch(setIsMute(false));
  dispatch(setIsMobileField(true));
  dispatch(setIsCallRejected(true));
  dispatch(setIsCallActive(false));
  dispatch(
    setSelectedAgentStatus(
      JSON.parse(localStorage.getItem("selectedAgentStatus"))
    )
  );
  localStorage.setItem("isCallInProgress", false);
  localStorage.removeItem("allocationId");
};

export const AnswerCall = (piopiy, dispatch) => {
  piopiy.answer();
};
export const MuteCall = (piopiy, dispatch) => {
  piopiy.mute();
  dispatch(setIsMute(true));
  dispatch(setIsCallRejected(false));
};

export const UnmuteCall = (piopiy, dispatch) => {
  piopiy.unMute();
  dispatch(setIsMute(false));
  dispatch(setIsCallRejected(false));
};

export const HoldCall = (piopiy, dispatch) => {
  piopiy.hold();
  dispatch(setIsHold(true));
  dispatch(setIsCallRejected(false));
};

export const UnHoldCall = (piopiy, dispatch) => {
  piopiy.unHold();
  dispatch(setIsHold(false));
  dispatch(setIsCallRejected(false));
};

export const CallTransfer = (piopiy, number) => {
  console.log("number", number);
  console.log("before");
  console.log("piopiy", piopiy);
  piopiy.transfer(number);
  // piopiy.transfer('4441');
  console.log("After");
};

export const CallInitiated = (callId) => {
  let userData = getTokenInLocalStorage();
  var allocId = parseInt(localStorage.getItem("allocationId"));
  var mobileNo = parseInt(localStorage.getItem("calledNumber"));
  var type = localStorage.getItem("type");
  var payLoad = {
    allocationId: allocId,
    phoneNumber: mobileNo,
    callId: callId,
    userId: userData?.autoFinanceUser?.userId,
  };
  moduleApi
    .postData(
      type === "Collections"
        ? apiCall.callInitiated
        : apiCall.callInitiatedForSales,
      payLoad
    )
    .then((response) => {
      console.log("call initiated response", response);
    });
};

const playAudio = (audioRef) => {
  if (audioRef) {
    audioRef.currentTime = 0; // Reset the audio to the beginning
    audioRef.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  }
};

// Method to stop audio
const stopAudio = (audioRef) => {
  if (audioRef) {
    audioRef.pause();
    audioRef.currentTime = 0; // Reset the audio to the beginning
  }
};
