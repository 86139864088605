import React from "react";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import TextField from "../../CommonComponents/TextField";
import { Field } from "formik";
import Select from "react-select";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const ResidentalInfo = ({ key, mainOBJ, onChange, errors }) => {
  let yesOrNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  let customerCooperationOptions = [
    { label: "Polite", value: "Polite" },
    { label: "Rude", value: "Rude" },
  ];

  let ownershipOptions = [
    { label: "Own House", value: "Own House" },
    { label: "Rented House", value: "Rented House" },
    { label: "Company Quarters", value: "Company Quarters" },
    { label: "Parental", value: "Parental" },
    { label: "Relatives House", value: "Relatives House" },
    { label: "Others", value: "Others" },
  ];

  let areaLocalityOptions = [
    { label: "Normal", value: "Normal" },
    { label: "Negative", value: "Negative" },
    { label: "Slum", value: "Slum" },
    { label: "Community", value: "Community" },
    { label: "Dominated Area", value: "Dominated Area" },
  ];

  let houseApproachOptions = [
    { label: "Car", value: "Car" },
    { label: "Bike", value: "Bike" },
    { label: "Walk", value: "Walk" },
  ];

  let locatingAddressOptions = [
    { label: "Easy", value: "Easy" },
    { label: "Difficult", value: "Difficult" },
    { label: "Untraceble", value: "Untraceble" },
  ];

  let accomodationTypeOptions = [
    { label: "Bungalow", value: "Bungalow" },
    { label: "Flat", value: "Flat" },
    { label: "House", value: "House" },
    { label: "Temporary Shed", value: "Temporary Shed" },
    { label: "Chawl", value: "Chawl" },
  ];

  let constructionOfHouseOptions = [
    { label: "Pucca", value: "Pucca" },
    { label: "Semi Pucca", value: "Semi Pucca" },
  ];

  let houseConditionOptions = [
    { label: "Upper Posh", value: "Upper Posh" },
    { label: "Posh", value: "Posh" },
    { label: "Average", value: "Average" },
    { label: "Lower", value: "Lower" },
  ];
  let standardOfLivingOptions = [
    { label: "Good", value: "Good" },
    { label: "Average", value: "Average" },
    { label: "Lower", value: "Lower" },
  ];

  return (
    <div style={{ margin: "15px", maxHeight:'400px', overflowY:'auto' }}>
      <Row>
        <Col>
          {/* <TextField isAsterisk={true} label="Name verified on house Exterior" name="house_exterior_name_verification" type="text" />   */}
          <Field name="house_exterior_name_verification">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Name verified on house Exterior
                </label>
                <Select
                  placeholder="Select Name verified on house Exterior"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={mainOBJ?.house_exterior_name_verification?{
                    label: mainOBJ?.house_exterior_name_verification,
                    value: mainOBJ?.house_exterior_name_verification,
                  }:null}
                  
                  onChange={(value) => {
                    form.setFieldValue(
                      "house_exterior_name_verification",
                      value.value
                    );
                  }}
                />
              </div>
            )}
          </Field>
          {errors.house_exterior_name_verification && (
            <span className="error">
              {errors.house_exterior_name_verification}
            </span>
          )}
          {/* <TextField isAsterisk={true} label="How Co-operative was the Customer" name="customer_cooperation" type="text" />  */}
          <Field name="customer_cooperation">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  How Co-operative was the Customer
                </label>
                <Select
                  placeholder="Select How Co-operative was the Customer"
                  classNamePrefix="select-box"
                  options={customerCooperationOptions}
                  defaultValue={mainOBJ?.customer_cooperation?{
                    label: mainOBJ?.customer_cooperation,
                    value: mainOBJ?.customer_cooperation,
                  }:null}
                  
                  onChange={(value) => {
                    form.setFieldValue("customer_cooperation", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.customer_cooperation && (
            <span className="error">{errors.customer_cooperation}</span>
          )}
          {/* <TextField
            isAsterisk={true}
            label="Entry Allowed Inside the House"
            name="entry_allowed_inside_house"
            type="text"
          /> */}
          <Field name="entry_in_house">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Entry Allowed Inside the House
                </label>
                <Select
                  placeholder="Select Entry Allowed Inside the House"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={{
                    label: mainOBJ?.entry_in_house,
                    value: mainOBJ?.entry_in_house,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue(
                      "entry_in_house",
                      value.value
                    );
                  }}
                />
              </div>
            )}
          </Field>
          {errors.entry_in_house && (
            <span className="error">{errors.entry_in_house}</span>
          )}
          <TextField
            isAsterisk={true}
            label="No of years of stay in city"
            name="city_stay_inyears"
            type="text"
          />
          <TextField
            isAsterisk={true}
            label="Number of years of Stay at Residence"
            name="residence_stay_inyears"
            type="text"
          />
          <TextField
            // isAsterisk={true}
            label="Number of Months of Stay at Residence"
            name="residence_stay_inmonths"
            type="text"
          />
          {/* <TextField isAsterisk={true} label="Ownership" name="residence_ownership" type="text" /> */}
          <Field name="residence_ownership">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Ownership
                </label>
                <Select
                  placeholder="Select Ownership"
                  classNamePrefix="select-box"
                  options={ownershipOptions}
                  defaultValue={{
                    label: mainOBJ?.residence_ownership,
                    value: mainOBJ?.residence_ownership,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("residence_ownership", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.residence_ownership && (
            <span className="error">{errors.residence_ownership}</span>
          )}
          {/* <TextField isAsterisk={true} label="Ownership Others" name="residence_ownership" type="text" />    */}
          <TextField
            // isAsterisk={true}
            label="Rent Amount"
            name="rent_amount"
            type="text"
          />
          <TextField
            // isAsterisk={true}
            label="Permanent Address"
            name="address_line1"
            type="text"
          />
          {/* <TextField
            isAsterisk={true}
            label="Area Locality"
            name="area_locality"
            type="text"
          /> */}
          <Field name="area_locality">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Area Locality
                </label>
                <Select
                  placeholder="Select Area Locality"
                  classNamePrefix="select-box"
                  options={areaLocalityOptions}
                  defaultValue={{
                    label: mainOBJ?.area_locality,
                    value: mainOBJ?.area_locality,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("area_locality", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.area_locality && (
            <span className="error">{errors.area_locality}</span>
          )}
          {/* <TextField isAsterisk={true} label="Approach to House" name="house_approach" type="text" />   */}
          <Field name="house_approach">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Approach to House
                </label>
                <Select
                  placeholder="Select Approach to House"
                  classNamePrefix="select-box"
                  options={houseApproachOptions}
                  defaultValue={mainOBJ?.house_approach?{
                    label: mainOBJ?.house_approach,
                    value: mainOBJ?.house_approach,
                  }:null}
                  
                  onChange={(value) => {
                    form.setFieldValue("house_approach", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.house_approach && (
            <span className="error">{errors.house_approach}</span>
          )}
          {/* <TextField isAsterisk={true} label="Locating Address" name="house_location" type="text" />   */}
          <Field name="house_location">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Locating Address
                </label>
                <Select
                  placeholder="Select Locating Address"
                  classNamePrefix="select-box"
                  options={locatingAddressOptions}
                  defaultValue={{
                    label: mainOBJ?.house_location,
                    value: mainOBJ?.house_location,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("house_location", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.house_location && (
            <span className="error">{errors.house_location}</span>
          )}
          <TextField
            isAsterisk={true}
            label="Nearest Landmark"
            name="nearest_land_mark"
            type="text"
          />
        </Col>
        <Col>
          {/* <TextField isAsterisk={true} label="Type Of Accommodation" name="accommodation_type" type="text" /> */}
          <Field name="accommodation_type">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Type Of Accommodation
                </label>
                <Select
                  placeholder="Select Type Of Accommodation"
                  classNamePrefix="select-box"
                  options={accomodationTypeOptions}
                  defaultValue={{
                    label: mainOBJ?.accommodation_type,
                    value: mainOBJ?.accommodation_type,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("accommodation_type", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.accommodation_type && (
            <span className="error">{errors.accommodation_type}</span>
          )}
          {/* <TextField isAsterisk={true} label="Construction of House" name="construction_of_house" type="text" /> */}
          <Field name="construction_of_house">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Construction of House
                </label>
                <Select
                  placeholder="Select Construction of House"
                  classNamePrefix="select-box"
                  options={constructionOfHouseOptions}
                  defaultValue={{
                    label: mainOBJ?.construction_of_house,
                    value: mainOBJ?.construction_of_house,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("construction_of_house", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.construction_of_house && (
            <span className="error">{errors.construction_of_house}</span>
          )}
          <TextField
            // isAsterisk={true}
            label="Residence Carpet Area ( Sq Ft)"
            name="residence_carpet_area"
            type="text"
          />
          {/* <TextField isAsterisk={true} label="House Interior Condition" name="interior_condition" type="text" /> */}
          <Field name="interior_condition">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  House Interior Condition
                </label>
                <Select
                  placeholder="Select House Interior Condition"
                  classNamePrefix="select-box"
                  options={houseConditionOptions}
                  defaultValue={{
                    label: mainOBJ?.interior_condition,
                    value: mainOBJ?.interior_condition,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("interior_condition", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.interior_condition && (
            <span className="error">{errors.interior_condition}</span>
          )}
          {/* <TextField isAsterisk={true} label="House Exterior Condition" name="exterior_condition" type="text" /> */}
          <Field name="exterior_condition">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  House Exterior Condition
                </label>
                <Select
                  placeholder="Select House Exterior Condition"
                  classNamePrefix="select-box"
                  options={houseConditionOptions}
                  defaultValue={{
                    label: mainOBJ?.exterior_condition,
                    value: mainOBJ?.exterior_condition,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("exterior_condition", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.exterior_condition && (
            <span className="error">{errors.exterior_condition}</span>
          )}
          {/* <TextField isAsterisk={true} label="Neighbourhood (Locality)" name="locality" type="text" /> */}
          <Field name="locality">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Neighbourhood (Locality)
                </label>
                <Select
                  placeholder="Select Neighbourhood (Locality)"
                  classNamePrefix="select-box"
                  options={houseConditionOptions}
                  defaultValue={mainOBJ?.locality?{
                    label: mainOBJ?.locality,
                    value: mainOBJ?.locality,
                  }:null}
                  
                  onChange={(value) => {
                    form.setFieldValue("locality", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.locality && <span className="error">{errors.locality}</span>}
          {/* <TextField isAsterisk={true} label="Standard of Living" name="living_standard" type="text" /> */}
          <Field name="living_standard">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Standard of Living
                </label>
                <Select
                  placeholder="Select Standard of Living"
                  classNamePrefix="select-box"
                  options={standardOfLivingOptions}
                  defaultValue={{
                    label: mainOBJ?.living_standard,
                    value: mainOBJ?.living_standard,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("living_standard", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.living_standard && (
            <span className="error">{errors.living_standard}</span>
          )}
          {/* <TextField isAsterisk={true} label="Address & ID Proof Sighted" name="address_proof_sighted" type="text" /> */}
          <Field name="address_proof_sighted">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Address & ID Proof Sighted
                </label>
                <Select
                  placeholder="Select Address & ID Proof Sighted"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={mainOBJ?.address_proof_sighted?{
                    label: mainOBJ?.address_proof_sighted,
                    value: mainOBJ?.address_proof_sighted,
                  }:null}
                  
                  onChange={(value) => {
                    form.setFieldValue("address_proof_sighted", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.address_proof_sighted && (
            <span className="error">{errors.address_proof_sighted}</span>
          )}
          <TextField
            // isAsterisk={true}
            label="Type of Address & ID Proof"
            name="address_proof_type"
            type="text"
          />
          {/* <TextField isAsterisk={true} label="Asset/Vehicle Seen at Residence" name="residence_assets" type="text" /> */}
          <Field name="vehicle_seen_at_residence">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Asset/Vehicle Seen at Residence
                </label>
                <Select
                  placeholder="Select Asset/Vehicle Seen at Residence"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={{
                    label: mainOBJ?.vehicle_seen_at_residence,
                    value: mainOBJ?.vehicle_seen_at_residence,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("vehicle_seen_at_residence", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.vehicle_seen_at_residence && (
            <span className="error">{errors.vehicle_seen_at_residence}</span>
          )}
          <TextField label="List the Assets at Residence" name="residence_assets" type="text" />
          <Field name="political_party_affilication">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Affiliation Seen for any Political Party
                </label>
                <Select
                  placeholder="Select Affiliation Seen for any Political Party"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={{
                    label: mainOBJ?.political_party_affilication,
                    value: mainOBJ?.political_party_affilication,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue(
                      "political_party_affilication",
                      value.value
                    );
                  }}
                />
              </div>
            )}
          </Field>
          {errors.political_party_affilication && (
            <span className="error">{errors.political_party_affilication}</span>
          )}
          <TextField
            // isAsterisk={true}
            label="Affiliation Political Party Remarks"
            name="political_remarks"
            type="text"
          />
          {/* <TextField isAsterisk={true} label="Caution Profile" name="caution_profile" type="text" /> */}
          <Field name="caution_profile">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Caution Profile
                </label>
                <Select
                  placeholder="Select Caution Profile"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={mainOBJ?.caution_profile?{
                    label: mainOBJ?.caution_profile,
                    value: mainOBJ?.caution_profile,
                  }:null}
                  
                  onChange={(value) => {
                    form.setFieldValue("caution_profile", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.caution_profile && (
            <span className="error">{errors.caution_profile}</span>
          )}
          <TextField
            // isAsterisk={true}
            label="Caution Profile Remarks"
            name="caution_profile_remarks"
            type="text"
          />
        </Col>
      </Row>
    </div>
  );
};

ResidentalInfo.label = "Residential Info";
ResidentalInfo.initialValues = {
  house_exterior_name_verification: "",
  customer_cooperation: "",
  entry_allowed_inside_house: "",
  city_stay_inyears: "",
  residence_stay_inyears: "",
  residence_stay_inmonths: "",
  residence_ownership: "",
  rent_amount: "",
  area_locality: "",
  house_approach: "",
  house_location: "",
  nearest_land_mark: "",
  accommodation_type: "",
  construction_of_house: "",
  residence_carpet_area: "",
  interior_condition: "",
  exterior_condition: "",
  locality: "",
  living_standard: "",
  address_proof_sighted: "",
  address_proof_type: "",
  residence_assets: "",
  political_party_affilication: "",
  caution_profile: "",
  caution_profile_remarks: "",
};

ResidentalInfo.validationSchema = Yup.object({
  // house_exterior_name_verification: Yup.string().required(
  //   "Name verified on house Exterior is required"
  // ),
  customer_cooperation: Yup.string().required(
    "How Co-operative was the Customer is required"
  ),
  // entry_allowed_inside_house: Yup.string().required(
  //   "Entry Allowed Inside the House is required"
  // ),
  city_stay_inyears: Yup.string().required(
    "No of years of stay in city is required"
  ),
  residence_stay_inyears: Yup.string().required(
    "Number of years of Stay at Residence is required"
  ),
  // residence_stay_inmonths: Yup.string().required(
  //   "Number of Months of Stay at Residence is required"
  // ),
  residence_ownership: Yup.string().required("Ownership is required"),
  // rent_amount: Yup.string().required("Rent Amount is required"),
  area_locality: Yup.string().required("Area Locality is required"),
  // house_approach: Yup.string().required("Approach to House is required"),
  house_location: Yup.string().required("Locating Address is required"),
  nearest_land_mark: Yup.string().required("Nearest Landmark is required"),
  accommodation_type: Yup.string().nullable().required(
    "Type Of Accommodation is required"
  ),
  construction_of_house: Yup.string().required(
    "Construction of House is required"
  ),
  // residence_carpet_area: Yup.string().required(
  //   "Residence Carpet Area ( Sq Ft) is required"
  // ),
  // interior_condition: Yup.string().required(
  //   "House Interior Condition is required"
  // ),
  // exterior_condition: Yup.string().required(
  //   "House Exterior Condition is required"
  // ),
  locality: Yup.string().required("Neighbourhood (Locality) is required"),
  living_standard: Yup.string().required("Standard Of Living is required"),
  // address_proof_sighted: Yup.string().required(
  //   "Address & ID Proof Sighted is required"
  // ),
  vehicle_seen_at_residence: Yup.string().required(
    "Asset/Vehicle Seen at Residence is required"
  ),
  // residence_assets: Yup.string().required(
  //   "List the Assets at Residence is required"
  // ),
  political_party_affilication: Yup.string().required(
    "Asset/Vehicle Seen at Residence is required"
  ),
  caution_profile: Yup.string().required("Caution Profile is required"),
  caution_profile_remarks: Yup.string().required(
    "Caution Profile Remarks is required"
  ),
});
ResidentalInfo.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
};

export default ResidentalInfo;
