import { getTokenInLocalStorage } from "../services/AuthService";
import {
  handleResponse,
  handleError,
  handleResponseForExcelDownload,
} from "./apiUtils";
var userLoginDetails = getTokenInLocalStorage();

// console.log("login", userLoginDetails);
//DEMO QA
// const BASE_PATH = "https://qademo.clucloud.com/api/";
// const EMAIL_BASE_PATH = "https://qademo.clucloud.com/api/";

//Vastu
// const BASE_PATH =
//   userLoginDetails === null ||
//   userLoginDetails?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE"
//     ? "https://cluapi.vastucorp.com/api/"
//     : "http://localhost:9015/api";
// const EMAIL_BASE_PATH = "https://cluapi.vastucorp.com/api/";

// // Vastu
// export const firebaseVapidKey =
//   "BMdXm0B2jXsvYtdrvQwyWsrdBOV_OApwzHMFloL996jlpMrBXGzr5X9f7Q52gR9QzBW0fVh7nA_1Y6z2CAzoFVA";

// export const firebaseConfig = {
//   apiKey: "AIzaSyDPexqP_7LP4QklF8ZPdAsVV4HHgVr8-_c",
//   authDomain: "clu-cloud-f0b93.firebaseapp.com",
//   databaseURL: "https://clu-cloud-f0b93-default-rtdb.firebaseio.com",
//   projectId: "clu-cloud-f0b93",
//   storageBucket: "clu-cloud-f0b93.appspot.com",
//   messagingSenderId: "115074287360",
//   appId: "1:115074287360:web:d0879cde75edd0306dd5e5",
//   measurementId: "G-WT2MBG922D",
// };

//QA
// const BASE_PATH =
//   userLoginDetails === null ||
//   userLoginDetails?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE"
//     ? "https://qa.clucloud.com/api/"
//     : "http://localhost:9015/api";
// const EMAIL_BASE_PATH = "https://qa.clucloud.com/api/";

// QA
// export const firebaseVapidKey =
//   "BLLCSLlrdofwQ0ypidfpA8knFpzqHxh-R_kkTmMlOAnbE3jP6IPKOtlXU1iEjUeqOfJEjlEyX1ZjBI8la-C8xnA";

// export const firebaseConfig = {
//   apiKey: "AIzaSyB8atYr2xkwGkmqfhSkui9FHk6TLybPs4Y",
//   authDomain: "telecalling-qa.firebaseapp.com",
//   databaseURL:
//     "https://telecalling-qa-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "telecalling-qa",
//   storageBucket: "telecalling-qa.appspot.com",
//   messagingSenderId: "918263194810",
//   appId: "1:918263194810:web:b3d99ba9f89a3cfcfb4e83",
//   measurementId: "G-HG128X9HWW",
// };

//Prod
// export const firebaseVapidKey = "BCMh95OwYC2f2NEiokGhZQX_SILHB8nb_c0v_6sR5X5ScJFokgtYGAfG5Ap0Vqt10jnGo5B-A_D5bE04DDMNLKw"

// export const firebaseConfig = {
//       apiKey: "AIzaSyBoVWUmadPQg52h6iql2ru-_OPzVpvT2Z8",
//       authDomain: "telecalling-prod-65201.firebaseapp.com",
//       projectId: "telecalling-prod-65201",
//       databaseURL: "https://telecalling-prod-65201-default-rtdb.asia-southeast1.firebasedatabase.app",
//       storageBucket: "telecalling-prod-65201.appspot.com",
//       messagingSenderId: "1075727068041",
//       appId: "1:1075727068041:web:7b15801b733d8d55ed0f58",
//       // measurementId: "G-HG128X9HWW"
//     };

// const BASE_PATH =
//   userLoginDetails === null ||
//   userLoginDetails?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE"
//     ? "https://qa.clucloud.com/api/"
//     : "https://service.clucloud.com/api/";
// const EMAIL_BASE_PATH = "https://qa.clucloud.com/api/";

// //QA
// export const firebaseVapidKey =
//   "BLLCSLlrdofwQ0ypidfpA8knFpzqHxh-R_kkTmMlOAnbE3jP6IPKOtlXU1iEjUeqOfJEjlEyX1ZjBI8la-C8xnA";

// export const firebaseConfig = {
//   apiKey: "AIzaSyB8atYr2xkwGkmqfhSkui9FHk6TLybPs4Y",
//   authDomain: "telecalling-qa.firebaseapp.com",
//   databaseURL:
//     "https://telecalling-qa-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "telecalling-qa",
//   storageBucket: "telecalling-qa.appspot.com",
//   messagingSenderId: "918263194810",
//   appId: "1:918263194810:web:b3d99ba9f89a3cfcfb4e83",
//   measurementId: "G-HG128X9HWW",
// };

//Prod
// export const firebaseVapidKey = "BCMh95OwYC2f2NEiokGhZQX_SILHB8nb_c0v_6sR5X5ScJFokgtYGAfG5Ap0Vqt10jnGo5B-A_D5bE04DDMNLKw"

// export const firebaseConfig = {
//       apiKey: "AIzaSyBoVWUmadPQg52h6iql2ru-_OPzVpvT2Z8",
//       authDomain: "telecalling-prod-65201.firebaseapp.com",
//       projectId: "telecalling-prod-65201",
//       databaseURL: "https://telecalling-prod-65201-default-rtdb.asia-southeast1.firebasedatabase.app",
//       storageBucket: "telecalling-prod-65201.appspot.com",
//       messagingSenderId: "1075727068041",
//       appId: "1:1075727068041:web:7b15801b733d8d55ed0f58",
//       // measurementId: "G-HG128X9HWW"
//     };

const BASE_PATH =
  userLoginDetails === null ||
  userLoginDetails?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE"
    ? "https://clu.clucloud.com/api/"
    : "http://15.207.223.30:8014/api/";
const EMAIL_BASE_PATH = "https://clu.clucloud.com/api/";

const getRequestOptions = (method, body) => {
  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
      apikey: "834563271cd84fc12481fcf4ad2841a8",
    },
  };
  if (body) options = { ...options, body: JSON.stringify(body) };
  return options;
};
const getRequestOptionsForBulkUpload = (method, body) => {
  let options = {
    method: method,
    headers: {
      "Content-Type":
        // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      // "fileName":fileName
    },
  };
  if (body) options = { ...options, body: body };
  return options;
};

const getRequestOptionsForPayroll = (method, body) => {
  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (body) options = { ...options, body: JSON.stringify(body) };
  return options;
};

const getRequestOptionsForExcel = (method, file, myJSON) => {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("excelJson", myJSON);
  let options = {
    method: method,
    headers: { ...authHeader() },
  };
  if (file) options = { ...options, body: formData };
  return options;
};

const authHeader = () => {
  let user = JSON.parse(localStorage.getItem("userDetails"));
  if (user !== null) {
    if (user && user.jwtToken) {
      return { Authorization: "Bearer " + user.jwtToken };
    } else {
      return {};
    }
  }
};

/*
 * Generic method to get the data for all the list get endpoints
 */
export function getData(name, queryParams) {
  let url = `${BASE_PATH}${name}${queryParams ? queryParams : ""}`;
  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function getBasePath() {
  return BASE_PATH;
}

export function getUserData(name) {
  let url = `${BASE_PATH}${name}`;
  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function getDataById(name, queryParams, queryParams2) {
  let url = `${name}?organizationId=${queryParams}&action=${queryParams2}`;
  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

//Bulk Upload
export function getDataByOrgName(name, orgId, action, fileName) {
  let url = `${name}&organizationId=${orgId}&action=${action}&fileName=${fileName}`;
  return fetch(url, getRequestOptions("POST"))
    .then(handleResponse)
    .catch(handleError);
}

export function postDataByOrgIdForPayroll(name, body) {
  //let userId=`${BASE_PATH}${name}?id=${id}`;
  return fetch(`${name}`, getRequestOptionsForPayroll("POST", body))
    .then(handleResponse)
    .catch(handleError);
}

//Download Bulk Upload File
export function getDownloadedFilePreSignedUrl(name, orgId, fileName) {
  let url = `${name}&organizationId=${orgId}&fileName=${fileName}`;
  return fetch(url, getRequestOptions("POST"))
    .then(handleResponse)
    .catch(handleError);
}

export function getDownloadedFile(name) {
  let url = `${name}`;

  return fetch(url, getRequestOptionsForBulkUpload("GET"))
    .then(handleResponseForExcelDownload)
    .catch(handleError);
}

export function getUpoadedList(
  name,
  action,
  orgId,
  endDate,
  limit,
  pageSize,
  startDate
) {
  let url = `${name}?action=${action}&organizationId=${orgId}&endDate=${endDate}&limit=${limit}&pageSize=${pageSize}&startDate=${startDate}`;
  return fetch(url, getRequestOptions("POST"))
    .then(handleResponse)
    .catch(handleError);
}

export function getUserImage(name) {
  let url = `${name}`;
  return fetch(url, getRequestOptions("GET")).catch(handleError);
}

export function getDataWithQueryParam(name, queryParams) {
  let url = `${BASE_PATH}${name}/${queryParams ? queryParams : ""}`;

  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}
export function getDataQueryParam(name, queryParams) {
  let url = `${BASE_PATH}${name}?userid=${queryParams}`;

  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function getDataByID(name, id, quantity) {
  let url = `${BASE_PATH}${name}${id ? "/" + id : ""}${
    quantity ? "/" + quantity : ""
  }`;

  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}
export function getDataByUserID(name, id, quantity, userId) {
  let url = `${BASE_PATH}${name}${id ? "/" + id : ""}${
    quantity ? "/" + quantity : ""
  }${userId ? "/" + userId : ""}`;

  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function postDataByName(name, id, body) {
  //let userId=`${BASE_PATH}${name}?id=${id}`;
  return fetch(`${name}?organizationId=${id}`, getRequestOptions("POST", body))
    .then(handleResponse)
    .catch(handleError);
}

export function postDataByBody(name, body) {
  //let userId=`${BASE_PATH}${name}?id=${id}`;
  return fetch(`${name}`, getRequestOptions("POST", body))
    .then(handleResponse)
    .catch(handleError);
}

export function postData(name, body) {
  return fetch(`${BASE_PATH}${name}`, getRequestOptions("POST", body))
    .then(handleResponse)
    .catch(handleError);
}
export function postEmailData(name, body) {
  return fetch(`${EMAIL_BASE_PATH}${name}`, getRequestOptions("POST", body))
    .then(handleResponse)
    .catch(handleError);
}
export function loginPostData(name, body) {
  return fetch(`${BASE_PATH}${name}`, getRequestOptions("POST", body))
    .then(handleResponse)
    .catch(handleError);
}

export function postDataById(name, id, body) {
  //let userId=`${BASE_PATH}${name}?id=${id}`;
  return fetch(`${BASE_PATH}${name}?id=${id}`, getRequestOptions("POST", body))
    .then(handleResponse)
    .catch(handleError);
}
export function getDataByIDUsingHr(name, id, quantity) {
  let url = `${BASE_PATH}${name}${id ? "/" + id : ""}${
    quantity ? "?requestFor=" + quantity : ""
  }`;
  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function putData(name, body) {
  return fetch(`${BASE_PATH}${name}`, getRequestOptions("PUT", body))
    .then(handleResponse)
    .catch(handleError);
}

//Bulk Upload
export function putDataByName(name, body) {
  return fetch(`${name}`, getRequestOptionsForBulkUpload("PUT", body))
    .then(handleResponse)
    .catch(handleError);
}

export function deleteData(name, id) {
  return fetch(`${BASE_PATH}${name}/${id}`, getRequestOptions("DELETE"))
    .then(handleResponse)
    .catch(handleError);
}

export function exportData(name) {
  return fetch(`${BASE_PATH}${name}`, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function getDataForDownload(name) {
  return fetch(`${name}`, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function importData(name, file, myJSON) {
  return fetch(
    `${BASE_PATH}${name}`,
    getRequestOptionsForExcel("POST", file, myJSON)
  )
    .then(handleResponse)
    .catch(handleError);
}

export function withPage(api, page, size) {
  return api + "?page=" + page + "&size=" + size;
}
export function withPagination(api, isPageable, page, size) {
  return api + isPageable + "?page=" + page + "&size=" + size;
}
