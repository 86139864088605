import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import Select from "react-select";
// import * as moduleApi from "../rest/moduleApi";
import * as moduleApi2 from "../rest/moduleApi2";

import { apiCall } from "../rest/restApi";
// import { isAdmin } from "../services/AuthService";
// import { toaster } from "../rest/apiUtils";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga";

const Excel2 = ({ close, loadData }) => {
  const [data, setData] = useState([]);
  const [binaryExcelFileData, setBinaryExcelFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  // const [fieldDateFormats, setFieldDateFormats] = useState({
  //   agDate:'',
  //   openingDate:'',
  //   closingDate:'',
  //   dueDate:''
  // });

  var userData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    ReactGA.pageview("Collections Work Upload");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  // const handleFile = (e) => {
  //   let selectedFile = e.target.files[0];
  //   let reader = new FileReader();
  //   reader.readAsArrayBuffer(selectedFile);
  //   reader.onload = (e) => {
  //     var bytes = new Uint8Array(reader.result);
  //     console.log("result", bytes);
  //     setBinaryExcelFileData(bytes);
  //   };
  // };

  const handleFile = (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    console.log(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      setIsLoading(true);
      const binaryData = event.target.result;
      const workbook = XLSX.read(binaryData, {
        type: "binary",
        cellText: false,
        cellDates: true,
      });
      // Get the first worksheet
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      // Convert the worksheet to a CSV string with a pipe separator
      const csvString = XLSX.utils.sheet_to_csv(worksheet, {
        FS: "|",
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      // Convert the CSV string to a Blob object
      const csvBlob = new Blob([csvString], { type: "text/csv" });
      // Set the CSV data to state
      setBinaryExcelFileData(csvBlob);
      setIsLoading(false);
    };
    setIsLoading(false);
    reader.readAsBinaryString(file);
  };

  const onChange = (e) => {
    setBinaryExcelFileData(null);
    const file = e.target.files[0];
    console.log("fileName", file);
    if (file && fileType.includes(file.type)) {
      setIsLoading(true);
      handleFile(e);
      setExcelFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const binary = event.target.result;
        const workbook = readExcel(binary);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = sheetToArray(worksheet);
        const openingDateIndex = rows[0]?.findIndex((name) =>
          name?.toLowerCase().includes("opening date")
        );
        const closingDateIndex = rows[0]?.findIndex((name) =>
          name?.toLowerCase().includes("closing date")
        );
        const lastPaidDateIndex = rows[0]?.findIndex((name) =>
          name?.toLowerCase().includes("last paid date")
        );
        const installmentDateIndex = rows[0]?.findIndex((name) =>
          name?.toLowerCase().includes("installment date")
        );
        const dueDateIndex = rows[0]?.findIndex((name) =>
          name?.toLowerCase().includes("due date")
        );
        const agDateIndex = rows[0]?.findIndex((name) =>
          name?.toLowerCase().includes("ag date")
        );

        for (let i = 1; i < rows.length; i++) {
          const openingDate = rows[i][openingDateIndex]
            ? new Date((rows[i][openingDateIndex] - 25569) * 86400 * 1000)
            : "";
          const closingDate = rows[i][closingDateIndex]
            ? new Date((rows[i][closingDateIndex] - 25569) * 86400 * 1000)
            : "";
          const lastPaidDate = rows[i][lastPaidDateIndex]
            ? new Date((rows[i][lastPaidDateIndex] - 25569) * 86400 * 1000)
            : "";
          const installmentDate = rows[i][installmentDateIndex]
            ? new Date((rows[i][installmentDateIndex] - 25569) * 86400 * 1000)
            : "";
          const dueDate = rows[i][dueDateIndex]
            ? new Date((rows[i][dueDateIndex] - 25569) * 86400 * 1000)
            : "";
          const agDate = rows[i][agDateIndex]
            ? new Date((rows[i][agDateIndex] - 25569) * 86400 * 1000)
            : "";
          const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          };
          const formattedOpeningDate = openingDate
            ? openingDate.toLocaleDateString("en-IN", options)
            : "";
          const formattedClosingDate = closingDate
            ? closingDate.toLocaleDateString("en-IN", options)
            : "";
          const formattedLastPaidDate = lastPaidDate
            ? lastPaidDate.toLocaleDateString("en-IN", options)
            : "";
          const formattedInstallmentDate = installmentDate
            ? installmentDate.toLocaleDateString("en-IN", options)
            : "";
          const formattedDueDate = dueDate
            ? dueDate.toLocaleDateString("en-IN", options)
            : "";
          const formattedAgDate = agDate
            ? agDate.toLocaleDateString("en-IN", options)
            : "";

          rows[i][openingDateIndex] = formattedOpeningDate;
          rows[i][closingDateIndex] = formattedClosingDate;
          rows[i][lastPaidDateIndex] = formattedLastPaidDate;
          rows[i][installmentDateIndex] = formattedInstallmentDate;
          rows[i][dueDateIndex] = formattedDueDate;
          rows[i][agDateIndex] = formattedAgDate;
        }
        console.log("rows", rows);
        setData(rows);
        setIsLoading(false);
      };
      reader.readAsBinaryString(file);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return swal("Please Upload Excel Files Only", {
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const readExcel = (binary) => {
    const { length } = binary;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binary.charCodeAt(i) & 0xff;
    }
    return XLSX.read(bytes, { type: "array", cellStyles: true });
  };

  const sheetToArray = (sheet) => {
    const rows = [];
    const range = XLSX.utils.decode_range(sheet["!ref"]);
    for (let row = range.s.r; row <= range.e.r; row++) {
      const rowValues = [];
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = sheet[XLSX.utils.encode_cell({ r: row, c: col })];
        const cellValue = cell ? cell.v : undefined;
        // cell.w === undefined && console.log("Z", cell.z);
        // console.log('V', cell.v);
        // console.log("W", cell.w);
        rowValues.push(cellValue);
      }
      rows.push(rowValues);
    }
    return rows;
  };

  const renderTable = () => {
    if (data.length === 0) {
      return null;
    }
    const headers = data[0];
    const rows = data?.slice(1);
    return (
      <div className="table-responsive">
        {data && (
          <span style={{ margin: "5px" }}>
            <b>Note:</b> Only 10 records will be shown in the preview
          </span>
        )}
        <table className="table">
          <thead
            style={{
              backgroundColor: "rgb(243,240,249)",
              top: "1px",
              position: "sticky",
            }}
          >
            <tr style={{ textAlign: "center" }}>
              {headers?.map((header, index) => {
                return <th key={index}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {/* eslint-disable-next-line */}
            {rows?.map((row, index) => {
              if (index < 10) {
                return (
                  <tr
                    key={index}
                    style={{ textAlign: "center", fontWeight: "normal" }}
                  >
                    {row?.map((cell, cellIndex) => {
                      return <td key={cellIndex}>{cell}</td>;
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const sendExcelFileToS3 = () => {
    console.log("exceldata", binaryExcelFileData);
    if (selectedFileType === null) {
      return swal("Please Select File Type", {
        icon: "error",
        dangerMode: true,
      });
    }
    if (binaryExcelFileData === null) {
      return swal("Please Upload Excel File", {
        icon: "error",
        dangerMode: true,
      });
    }
    if (fileName === null) {
      return swal("Please Enter File Name", {
        icon: "error",
        dangerMode: true,
      });
    }
    setIsLoading(true);
    // let url =
    //   apiCall.bulkUploadBaseUrl +
    //   "?organizationName=" +
    //   userData.autoFinanceOrganization.organizationName;
    // let payload = {
    //   orgId: userData.autoFinanceUser.organizationId,
    //   // orgName: userData.autoFinanceOrganization.organizationName,
    //   action: "preSignedUrl",
    //   fileName: fileName,
    // };
    // moduleApi
    //   .getDataByOrgName(
    //     url,
    //     payload.orgId,
    //     // payload.orgName,
    //     payload.action,
    //     payload.fileName
    //   )
    //   .then((response) => {
    var payload = {
      action: selectedFileType?.value,
      fileName: fileName,
      orgShortCode: userData?.autoFinanceOrganization?.orgshortcode,
      organizationId: userData?.autoFinanceUser?.organizationId,
      userId: userData?.autoFinanceUser?.userId,
      allocationType: "Collections",
    };
    console.log("payload", payload);
    moduleApi2
      .postData(apiCall.getPreSignedUrlForUplaodCollectionFile, payload)
      .then((response) => {
        console.log("preSignedUrl", response);
        if (response) {
          if (response?.message === "Service Unavailable") {
            setIsLoading(false);
            return swal("Something Went Wrong, Please Try again", {
              icon: "error",
              dangerMode: true,
            });
          } else if (
            response?.message === "File name already exist in an organization"
          ) {
            setIsLoading(false);
            return swal("File name already exists, Please Check.", {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.generatedUrl) {
            moduleApi2
              .putDataByName(response?.generatedUrl, binaryExcelFileData)
              .then((response2) => {
                //Service Unavailable
                console.log("bulk Upload", response2);
                setIsLoading(false);
                close();
                loadData();
                sendDataToAnotherS3Bucket(response?.generatedUrlForExcel);
                // getActiveKey();
                swal(
                  "Please Check the Status of the " +
                    payload.fileName +
                    " File in Uploaded Files Tab",
                  {
                    icon: "success",
                  }
                );
              });
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const sendDataToAnotherS3Bucket = (url) => {
    moduleApi2.putDataByNameForExcel(url, excelFile).then((response) => {
      //Service Unavailable
      console.log("bulk Upload2", response);
    });
  };

  const fileTypesList = [
    { label: "Upload Collection Allocations", value: "UPDATE_ALLOCATIONS" },
    {
      label: "Upload Telecaller Allocations",
      value: "UPDATE_TELE_CALLER_ALLOCATIONS",
    },
    { label: "Delete Allocations", value: "DELETE_ALLOCATIONS" },
    { label: "Transfer Allocations", value: "TRANSFER_ALLOCATIONS" },
    { label: "Allocate Priorities", value: "UPDATE_PROIRITIES_ALLOCATIONS" },
    { label: "Lender Payment Status", value: "UPDATE_LENDER_PAYMENT_STATUS" },
    { label: "Campaign Allocation", value: "UPDATE_ALLOCATION_TO_CAMPAIGN" },
    {
      label: "Additional Phone Numbers",
      value: "UPDATE_ADDITIONAL_PHONE_NUMBERS",
    },
    {
      label: "External Payments",
      value: "UPDATE_EXTERNAL_PAYMENTS",
    },
    {
      label: "Update Target Date",
      value: "UPDATE_TARGET_DATE",
    },
  ];

  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div>
        <div>
          {/* <form
              className="form-group"
              autoComplete="off"
              // onSubmit={handleSubmit}
            > */}
          <Row>
            <Col>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>Type</h6>
                </label>
                <div
                  style={{
                    float: "right",
                    // width: "150px",
                    height: "38px",
                    borderRadius: "3px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      setSelectedFileType(value);
                    }}
                    // value={executivesList[executiveFilterIndex]}
                    options={fileTypesList}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <label>
                <h6>Upload Excel file</h6>
              </label>

              <input
                id="chooseFileInput"
                style={{ padding: "11px" }}
                className="form-control"
                type="file"
                onChange={onChange}
              />
              {/* {excelFileError && (
                    <div
                      className="text-danger"
                      style={{ marginTop: 5 + "px" }}
                    >
                      {excelFileError}
                    </div>
                  )} */}
            </Col>
            <Col>
              <label>
                <h6>File Name</h6>
              </label>
              <input
                id="fileNameInput"
                className="form-control"
                type="text"
                name="fileName"
                onChange={(e) => {
                  setFileName(e.target.value);
                }}
                style={{ padding: "11px" }}
              />
            </Col>
            <Col>
              {/* {isSubmit && ( */}
              <button
                id="uploadScheduleSubmitButton"
                type="submit"
                className="btn btn-success"
                style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                onClick={() => {
                  if (selectedFileType?.value === "DELETE_ALLOCATIONS") {
                    swal({
                      title: "Delete",
                      text: "Note: Only OPEN Allocations Will be Deleted. Are You Sure, You would like to Delete The Allocations?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        sendExcelFileToS3();
                      }
                    });
                  } else if (
                    selectedFileType?.value === "TRANSFER_ALLOCATIONS"
                  ) {
                    swal({
                      title: "Transfer",
                      text: "Note: Only OPEN and INPROGRESS Allocations will be Transferred. Are You Sure, You Would like to Transfer the Allocations?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        sendExcelFileToS3();
                      }
                    });
                  } else {
                    sendExcelFileToS3();
                  }
                }}
              >
                Submit
              </button>
              {/* )} */}
            </Col>
            {/* <Col>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      // excelFile !== null && setIsSubmit(true);
                      // close();
                      // getActiveKey();
                      if (excelFile !== null) {
                        // setIsSubmit(true);
                        if (fileName === null) {
                          return swal("Please Enter File Name", {
                            icon: "error",
                            dangerMode: true,
                          });
                        } else {
                          setIsSubmit(true);
                        }
                      }
                    }}
                    style={{ marginTop: 35 + "px" }}
                  >
                    {loader && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        variant="light"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;&nbsp;Preview
                  </button>
                  {isSubmit && (
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                      // onClick={() => {
                      //   uploadDailyScheduleData();
                      // }}
                    >
                      Submit
                    </button>
                  )}
                </Col> */}
          </Row>
          {/* </form> */}
        </div>
        <br></br>
        <hr></hr>
        {renderTable()}
      </div>
    </LoadingOverlay>
  );
};

export default Excel2;
