import React from "react";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import TextField from "../../CommonComponents/TextField";
import { Field } from "formik";
import Select from "react-select";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const AgencyRemarks = ({ key, mainOBJ, onChange, errors }) => {
  let visitOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ];

  return (
    <div style={{ margin: "15px", maxHeight: "400px", overflowY: "auto" }}>
      <Row>
        <Col>
          {/* <TextField isAsterisk={true} label="No. of Visits" name="no_of_visits" type="text" /> */}
          <Field name="no_of_visits">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  No. Of Visits
                </label>
                <Select
                  placeholder="Select No. Of Visits"
                  classNamePrefix="select-box"
                  options={visitOptions}
                  defaultValue={{
                    label: mainOBJ?.no_of_visits,
                    value: mainOBJ?.no_of_visits,
                  }}
                  // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
                  onChange={(value) => {
                    form.setFieldValue("no_of_visits", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.no_of_visits && (
            <span className="error">{errors.no_of_visits}</span>
          )}
          <TextField
            isAsterisk={true}
            label="Remarks 1"
            name="remarks_1"
            type="text"
          />
          <TextField label="Remarks 2" name="remarks_2" type="text" />
          <TextField label="Remarks 3" name="remarks_3" type="text" />
          <TextField label="Remarks 4" name="remarks_4" type="text" />
          <TextField label="Remarks 5" name="remarks_5" type="text" />
        </Col>
        <Col>
          <TextField label="Remarks 6" name="remarks_6" type="text" />
          <TextField label="Remarks 7" name="remarks_7" type="text" />
          <TextField label="Remarks 8" name="remarks_8" type="text" />
          <TextField label="Remarks 9" name="remarks_9" type="text" />
          <TextField label="Remarks 10" name="remarks_10" type="text" />
          <TextField label="Google Visit Address" name="address" type="text" disabled/>
        </Col>
      </Row>
    </div>
  );
};

AgencyRemarks.label = "FI Agency Remarks";
AgencyRemarks.initialValues = {
  no_of_visits: "",
  remarks_1: "",
  remarks_2: "",
  remarks_3: "",
  remarks_4: "",
  remarks_5: "",
  remarks_6: "",
  remarks_7: "",
  remarks_8: "",
  remarks_9: "",
  remarks_10: "",
};

AgencyRemarks.validationSchema = Yup.object({
  no_of_visits: Yup.string().required("No Of Visits is required"),
  remarks_1: Yup.string().required("Remarks 1 is required"),
  //   remarks_2: Yup.string().required("Remarks 2 is required"),
  //   remarks_3: Yup.string().required("Remarks 3 is required"),
  //   remarks_4: Yup.string().required("Remarks 4 is required"),
  //   remarks_5: Yup.string().required("Remarks 5 is required"),
  //   remarks_6: Yup.string().required("Remarks 6 is required"),
  //   remarks_7: Yup.string().required("Remarks 7 is required"),
  //   remarks_8: Yup.string().required("Remarks 8 is required"),
  //   remarks_9: Yup.string().required("Remarks 9 is required"),
  //   remarks_10: Yup.string().required("Remarks 10 is required"),
});
AgencyRemarks.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
};

export default AgencyRemarks;
