import loadable from "@loadable/component";
import "flatpickr/dist/themes/material_green.css";
import pMinDelay from "p-min-delay";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { Link } from "react-router-dom";
import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import VisitsCount from "./VisitsCount";
// import LoadingOverlay from "react-loading-overlay";
// import Spinner from "react-bootstrap/Spinner";
import { hasAccess, isAdmin } from "../services/AuthService";
import CollectionsCount from "./CollectionsCount";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import ReactGA from "react-ga";
// import swal from "sweetalert";
import PaymentsGraph2 from "./PaymentsGraph2";
import CollectionGraph from "./CollectionGraph";
import FieldInvestigationGraph from "./FieldInvestigationGraph";
import BranchwiseCollectionGraph from "./BranchwiseCollectionGraph";
import BranchwiseFIGraph from "./BranchwiseFIGraph";
import PtpCount from "./PtpCount";
import { secondsToHms } from "../CommonComponents/Helper";
import { useHistory } from "react-router-dom";
import LoginList from "./LoginList";
// import { getDataFromFirebaseDB } from "../CommonComponents/Helper";
// import { getDatabase, ref, onValue } from "firebase/database";
import { camelize } from "../DB/utils";
import MonthPicker from "../CommonComponents/MonthPicker";
import RepeatVisitCount from "./RepeatVisitCount";
import AllocationCountsVisitsList from "./AllocationCountsVisitsList";
import DashboardAllocationSummaryList from "./DashboardAllocationSummaryList";
import TargetDueCount from "./TargetDueCount";
// import { useCalling } from "../CommonComponents/CallingContext";

// const CSLineChart = loadable(() => pMinDelay(import("./LineChart"), 500));
const ApexBar2 = loadable(() => pMinDelay(import("./Bar2"), 500));

const Dashboard = () => {
  var history = useHistory();
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [showDate, setShowDate] = useState(false);
  const [showDate2, setShowDate2] = useState(false);
  // const [showDate3, setShowDate3] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [targetDueCountModal, setTargetDueCountModal] = useState(false);
  const [isCollectionGraph, setIsCollectionGraph] = useState(true);
  const [isFIGraph, setIsFIGraph] = useState(false);
  const [isBranchwiseCollectionGraph, setIsBranchwiseCollectionGraph] =
    useState(false);
  const [isBranchwiseFIGraph, setIsBranchwiseFIGraph] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [basicModal3, setBasicModal3] = useState(false);
  const [basicModal4, setBasicModal4] = useState(false);
  const [basicModal5, setBasicModal5] = useState(false);
  const [basicModal6, setBasicModal6] = useState(false);
  const [basicModal7, setBasicModal7] = useState(false);
  const [basicModal8, setBasicModal8] = useState(false);
  const [basicModal9, setBasicModal9] = useState(false);
  // const [graph, setGraph] = useState(true);
  const [paymentMode, setPaymentMode] = useState("");
  const [collectionStatuses, setCollectionStatuses] = useState([]);
  // const [collectionSubStatuses, setCollectionSubStatuses] = useState([]);
  // const [collectionSubType, setCollectionSubType] = useState("");
  // const [isSubStatus, setIsSubStatus] = useState(false);
  const [status2Value, setStatus2Value] = useState("");
  const [latestCollections, setLatestCollections] = useState([]);
  const [latestSales, setLatestSales] = useState([]);
  const [metrics, setMetrics] = useState({
    collectionVisitCount: 0,
    expenseRequestCount: 0,
    leaveRequestCount: 0,
    noOfKilometersTravelled: 0,
    salesVisitCount: 0,
  });
  const [callCounts, setCallCounts] = useState({
    callConnectedCount: 0,
    callNotConnectedCount: 0,
    callsNotAttemped: 0,
    callsToBeDone: 0,
    callswithNoDispositionCount: 0,
    totalCalls: 0,
  });
  const [loginCounts, setLoginCounts] = useState({
    checkInUsesCount: 0,
    checkedOutUsersCount: 0,
    yetToCheckInUsersCount: 0,
  });
  const [collectionAllocationCounts, setCollectionAllocationCounts] = useState({
    allocationCounts: 0,
    repeatVisitsCount: 0,
    visitFromAllocationsCount: 0,
  });
  const [loginCountStatus, setLoginCountStatus] = useState("");
  const [ptpMetrics, setPtpMetrics] = useState({
    ptpCount: 0,
  });
  const [targetDueMetrics, setTargetMetrics] = useState({
    targetDueToday: 0, targetDueTomorrow: 0, targePastDue: 0,targetDueAchieved:0,targetsAttempted:0,targetsNotAttempted:0,
  });
  const [ptpOverDueMetrics, setPtpOverDueMetrics] = useState({
    ptpCount: 0,
  });
  // const [lastMonthPtpOverDueMetrics, setLastMonthPtpOverDueMetrics] = useState({
  //   ptpCount: 0,
  // });
  const [ptpTomorrowMetrics, setPtpTomorrowMetrics] = useState({
    ptpCount: 0,
  });
  const [incentiveMetrics, setIncentiveMetrics] = useState({
    incentivesCount: 0,
    eligibleForCount: 0,
  });
  const [arr, setArr] = useState({ date: [], sales: [], collections: [] });
  const [salesBranchArr, setSalesBranchArr] = useState({
    branches: [],
    counts: [],
  });
  const [updatedKey, setUpdatedKey] = useState(1);
  const [dates, setDates] = useState({ start: 0, end: 0 });
  const [ptpDates, setPtpDates] = useState({ start: 0, end: 0 });
  const [ptpTomorrow, setPtpTomorrow] = useState({ start: 0, end: 0 });
  var currentMonthStartTime =
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    1000;
  var currentMonthEndTime =
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    1000 +
    86399;

  var lastMonthStartTime =
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime() /
    1000;
  var lastMonthEndTime =
    new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime() /
    1000 +
    86399;

  const [chartDates, setChartDates] = useState({ start: 0, end: 0 });
  const [graphType, setGraphType] = useState("Collections");
  const [title, setTitle] = useState();
  const [ptpTitle, setPtpTitle] = useState();
  const [targetDueTitle, setTargetDueTitle] = useState();
  const [isMonthInProgress, setIsMonthInProgress] = useState(false);
  const [currentLabelValue, setCurrentLabelValue] = useState({
    label: "",
    value: "",
  });

  const [isBackButton, setIsBackButton] = useState(false);
  const [chartTypesDropDown, setChartTypesDropDown] = useState([]);
  const [callTimeLineData, setCallTimeLineData] = useState([]);
  // const [agentStatuses, setAgentStatuses] = useState([]);
  // const [agentStatusCounts, setAgentStatusCounts] = useState([]);
  const [start, setStart] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [end, setEnd] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    1000
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    1000 +
    86399
  );

  // const {
  //   agentLiveStatuses
  // } = useCalling();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);
    const date = [
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0)
      ),
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ),
    ];

    const tomorrowsDate = [
      new Date(
        new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0)
      ),
      new Date(
        new Date(new Date().setDate(new Date().getDate() + 1)).setHours(
          23,
          59,
          59
        )
      ),
    ];
    // const start = Math.floor(currentDate.setHours(0, 0, 0, 0) / 1000);
    // const end = Math.floor(currentDate.setHours(23, 59, 59, 999) / 1000);
    var start = Math.floor(date[0].getTime() / 1000);
    var end = Math.floor(date[1].getTime() / 1000);

    var startDateTomorrow = Math.floor(tomorrowsDate[0].getTime() / 1000);
    var endDateTomorrow = Math.floor(tomorrowsDate[1].getTime() / 1000);

    setDates({ ...dates, ...{ start: start }, ...{ end: end } });
    setPtpDates({ ...ptpDates, ...{ start: start }, ...{ end: end } });
    setPtpTomorrow({
      ...ptpTomorrow,
      ...{ start: startDateTomorrow },
      ...{ end: endDateTomorrow },
    });

    hasAccess(54) && loadPtpCount(start, end, userData);
    hasAccess(54) && loadPtpCountForTomorrow(startDateTomorrow, endDateTomorrow, userData);
    hasAccess(54) && loadPtpOverDueCount(currentMonthStartTime, currentMonthEndTime, userData);

    hasAccess(101) && loadTargetDueCounts(userData);

    // loadCollectionGraph(start, end);
    var d = new Date();
    d.setDate(1); //sets d the the first day of that month
    d.setHours(0, 0, 0, 0);

    let date2 = [
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ),
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ),
    ];
    // start = Math.floor(date[0].getTime() / 1000);
    // end = Math.floor(date[1].getTime() / 1000);
    setChartDates({
      ...chartDates,
      ...{ start: Math.floor(date2[0].getTime() / 1000) },
      ...{ end: Math.floor(date2[1].getTime() / 1000) },
    });
    setCurrentLabelValue({ label: "Collections", value: "Collections" });
    loadGraph(
      Math.floor(date2[0].getTime() / 1000),
      Math.floor(date2[1].getTime() / 1000),
      userData,
      false
    );
    addChartTypesDropDown();
    // loadAgentStatus();
    getCollectionDropdownOptions();
    if (
      hasAccess(42) &&
      userData?.autoFinanceUser?.teleCmiUserId !== null &&
      userData?.autoFinanceUser?.teleCmiPassword !== null &&
      userData?.autoFinanceUser?.teleCmiUserId !== "" &&
      userData?.autoFinanceUser?.teleCmiPassword !== ""
    ) {
      getCallTimelineData();
      getCallCountsData();
    } else {
      loadDashBoardCount(start, end, userData);
      hasAccess(111) && loadLatestCollections(start, end, userData);
      hasAccess(112) && loadLatestSales(start, end, userData);
      hasAccess(70) && handleIncentiveCounts();
      getLoginCountsData();
      hasAccess(91) && getCollectionAllocationCountsData(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
        1000,
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getTime() /
        1000 +
        86399
      );
    }
    // return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(loadAgentStatus, 10000); // 10000 milliseconds = 10 seconds

  //   // Clear the interval when the component unmounts to avoid memory leaks
  //   return () => clearInterval(intervalId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const loadBranchWiseCollections = (
  //   startDate,
  //   endDate,
  //   userDetails,
  //   month
  // ) => {
  //   var collectionbranchPayLoad = {
  //     endDate: endDate,
  //     organizationId: userDetails.autoFinanceUser.organizationId,
  //     reportType: "Collections",
  //     startDate: startDate,
  //   };
  //   moduleApi
  //     .postData(apiCall.branchWiseCount, collectionbranchPayLoad)
  //     .then((res) => {
  //       var bArr = {
  //         branches: [],
  //         counts: [],
  //       };
  //       // eslint-disable-next-line array-callback-return
  //       res.map((item) => {
  //         bArr.branches.push(item.branchName);
  //         bArr.counts.push(item.count);
  //       });
  //       setBranchArr(bArr);
  //       setUpdatedKey(updatedKey + 1);
  //     });
  // };

  // const loadAgentStatus = () => {
  //   // let listData = [];
  //   setAgentStatuses([]);
  //   const db = getDatabase();
  //   const starCountRef = ref(
  //     db,
  //     "agentLiveStatus/" + loggedInUser?.autoFinanceUser?.organizationId
  //   );
  //   console.log("starCountRef ", starCountRef);
  //   onValue(starCountRef, (snapshot) => {
  //     const data = snapshot.val();
  //     // updateStarCount(postElement, data);
  //     console.log("dataFromDB", data);

  //     // for (const key in data) {
  //     //   if (data.hasOwnProperty(key)) {
  //     //     listData.push(data[key]);
  //     //   }
  //     // }
  //     // console.log("listData", listData);
  //     const uniqueObjectsList = Object.values(data).reduce(
  //       (accumulator, currentValue) => {
  //         const userId = currentValue.userId;
  //         if (!accumulator.some((obj) => obj.userId === userId)) {
  //           accumulator.push(currentValue);
  //         }
  //         return accumulator;
  //       },
  //       []
  //     );

  //     const statusCounts = {};

  //     uniqueObjectsList.forEach((item) => {
  //       const status = item.status;
  //       if (statusCounts.hasOwnProperty(status)) {
  //         statusCounts[status] += 1;
  //       } else {
  //         statusCounts[status] = 1;
  //       }
  //     });

  //     const statusCountsArray = Object.keys(statusCounts).map((status) => ({
  //       status: status,
  //       count: statusCounts[status],
  //     }));

  //     console.log(statusCountsArray);
  //     console.log(uniqueObjectsList);
  //     setAgentStatusCounts(statusCountsArray)
  //     setAgentStatuses(uniqueObjectsList);
  //   });
  // };

  // const getColoredStatuses=(status)=>{
  //   switch (status) {
  //     case 'Idle':
  //      return 'blue'
  //     case 'Ringing':
  //      return 'green'
  //     case 'Dailing':
  //      return 'green'
  //     case 'Disposition':
  //      return 'blue'
  //     case 'Talking':
  //      return 'green'
  //     case 'Quality':
  //      return 'brown'

  //     default:
  //       return 'black';
  //   }
  // }

  const getCallTimelineData = () => {
    var today = new Date();
    var start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
    var end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
    var payload = {
      endTime: end,
      startTime: start,
      userId: loggedInUser?.autoFinanceUser?.userId,
    };

    moduleApi
      .postData(apiCall.getAgentLatestStatus, payload)
      .then((response) => {
        // console.log(" latest status response", response);
        var temp = {
          break: [],
          nonBreak: [],
        };
        // response?.forEach((element) => {
        //   if(element?.status==="Break"){
        //     temp.break.push(element)
        //   }else{
        //     temp.nonBreak.push(element)
        //   }
        // });
        // for (let i = 1; i < temp?.break?.length; i++) {
        //   const currentDate = new Date(temp?.break[i-1]?.createdTime);
        //   const previousDate = new Date(temp?.break[i]?.createdTime);

        //   // Calculate the time gap in milliseconds
        //   const timeGap = currentDate - previousDate;

        //   // Add the timeGap property to the current object
        //   if (temp.break[i]) {
        //     temp.break[i].timeGap = timeGap;
        //   }        }
        // for (let i = 1; i < temp?.nonBreak?.length; i++) {
        //   const currentDate = new Date(temp?.nonBreak[i-1]?.createdTime);
        //   const previousDate = new Date(temp?.nonBreak[i]?.createdTime);

        //   // Calculate the time gap in milliseconds
        //   const timeGap = currentDate - previousDate;

        //   // Add the timeGap property to the current object
        //   if (temp.nonBreak[i]) {
        //     temp.nonBreak[i].timeGap = timeGap;
        //   }
        // }
        // console.log('temp', temp);
        // setCallTimeLineData(temp);
        if (response?.length > 0) {
          for (let i = 1; i < response.length; i++) {
            // Start from index 1 to compare with the previous object
            const currentDate = new Date(response[i - 1].createdTime);
            const previousDate = new Date(response[i].createdTime);

            // Calculate the time gap in milliseconds
            const timeGap = currentDate - previousDate;

            // Add the timeGap property to the current object
            response[i].timeGap = timeGap;
          }

          // Add the timeGap property to the first object (since there is no previous object)
          response[0].timeGap = 0;

          response?.forEach((element) => {
            if (element?.status === "Break") {
              temp.break.push(element);
            }
            temp.nonBreak.push(element);
          });

          temp.totalBreakTime = temp.break.reduce(
            (total, breakItem) => total + breakItem.timeGap,
            0
          );
          // console.log("timeLineResponse", temp);
          setCallTimeLineData(temp);
        }
      });
  };

  const loadBranchWiseSales = (startDate, endDate, userDetails, month) => {
    var salesBranchPayLoad = {
      endDate: endDate,
      organizationId: userDetails.autoFinanceUser.organizationId,
      reportType: "Sales",
      startDate: startDate,
    };
    moduleApi
      .postData(apiCall.branchWiseCount, salesBranchPayLoad)
      .then((res) => {
        var bArr = {
          branches: [],
          counts: [],
        };
        // eslint-disable-next-line array-callback-return
        res?.map((item) => {
          bArr.branches.push(item?.branchName);
          bArr.counts.push(item?.totalCount);
        });
        setSalesBranchArr(bArr);
        setUpdatedKey(updatedKey + 1);
      });
  };

  const loadGraph = (startDate, endDate, userDetails, month) => {
    var payLoad = {
      endDate: endDate,
      organizationid:
        userDetails.autoFinanceUser &&
        userDetails.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? ""
        : userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId,
      roleName:
        userDetails.autoFinanceUser && userDetails.autoFinanceUser.roleName,
      startDate: startDate,
      userId: "",
      reportType: month ? "month" : "",
    };

    var s = startDate;

    var chart = {
      date: [],
      sales: [],
      collections: [],
      collectedAmount: [],
    };

    if (month) {
      var i = 0;
      while (i < 6) {
        var d = new Date();
        d.setDate(1);
        d.setHours(0, 0, 0, 0);
        d.setMonth(d.getMonth() - 6 + i);
        chart.date.push(d.getTime());
        chart.sales.push(0);
        chart.collections.push(0);
        chart.collectedAmount.push(0);
        i++;
      }
    } else {
      while (endDate > s) {
        chart.date.push(s * 1000);
        chart.sales.push(0);
        chart.collections.push(0);
        chart.collectedAmount.push(0);
        s = s + 86400;
      }
      // console.log("chartData", chart);
    }

    moduleApi
      .postData(apiCall.salesAndCollectionGraph, payLoad)
      .then((response) => {
        if (!response) {
          return;
        }

        for (const iterator of response) {
          var position = 0;
          while (position < chart.date.length) {
            if (chart.date[position] === iterator.date * 1000) {
              chart.sales[position] = iterator.salesCount;
              chart.collections[position] = iterator.collectionCount;
              chart.collectedAmount[position] = iterator.collectedAmount;
              position++;
              break;
            } else if (chart.date[position] < iterator.date * 1000) {
              position++;
            } else break;
          }
        }
      })
      .then(() => {
        // console.log("chart", chart);
        setArr(chart);
        setUpdatedKey(Math.floor(Math.random() * 100000));
      });
  };

  const loadLatestCollections = (startDate, endDate, userDetails) => {
    var payLoad = {
      endTime: endDate,
      organizationId:
        userDetails.autoFinanceUser &&
        userDetails.autoFinanceUser.organizationId,
      reportingOfficerId: !isAdmin()
        ? userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId
        : "",
      startTime: startDate,
      userId: "",
      visitType: "Collections",
      searchkey: "",
    };

    moduleApi.postData(apiCall.getVisits, payLoad).then((response) => {
      setLatestCollections(response.content);
    });
  };
  const loadLatestSales = (startDate, endDate, userDetails) => {
    var payLoad = {
      endTime: endDate,
      organizationId:
        userDetails.autoFinanceUser &&
        userDetails.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? ""
        : userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId,
      startTime: startDate,
      userId: "",
      visitType: "Sales",
      searchkey: "",
    };

    moduleApi.postData(apiCall.getVisits, payLoad).then((response) => {
      setLatestSales(response.content);
    });
  };

  const optionDates = [
    { label: "Today", value: 1 },
    { label: "Last 7 Days", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];
  // const ptpDatesOption = [
  //   { label: "Today", value: 1 },
  //   { label: "Tomorrow", value: 2 },
  //   { label: "Next 7 Days", value: 3 },
  // ];
  // const statusOptions = [
  //   { label: "All", value: "" },
  //   { label: "Paid", value: "paid" },
  // ];
  const status2Options = [
    { label: "Counts", value: "counts" },
    { label: "Amounts", value: "amounts" },
  ];

  const chartDateOptions = [
    { label: "Last 7 Days", value: 1 },
    // { label: "Last Week", value: 2 },
    { label: "Current Month", value: 3 },
    { label: "Last Month", value: 4 },
    { label: "Last 6 Months", value: 5 },
    { label: "Custom Month", value: 6 },
  ];

  // const collectionsGraphOptions = [
  //   { label: "Line", value: 1 },
  //   { label: "Bar", value: 2 },
  // ];

  const addChartTypesDropDown = () => {
    var opts = [];
    hasAccess(4) && opts.push({ label: "Collections", value: "Collections" });
    hasAccess(5) &&
      opts.push({ label: "Field Investigation", value: "Field Investigation" });
    hasAccess(3) && opts.push({ label: "Sales", value: "Sales" });
    isAdmin() &&
      hasAccess(4) &&
      opts.push({ label: "Branch Wise Collections", value: "B/W Collections" });
    isAdmin() &&
      hasAccess(5) &&
      opts.push({
        label: "Branch Wise Field Investigation",
        value: "Branch Wise Field Investigation",
      });
    isAdmin() &&
      hasAccess(3) &&
      opts.push({ label: "Branch Wise Sales", value: "B/W Sales" });
    setChartTypesDropDown(opts);
  };

  // const setChartType = (value) => {
  //   if (value.value === 1) {
  //     setGraph(false);
  //   } else {
  //     setGraph(true);
  //   }
  // };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const setDateValue = (label, value) => {
    var date;
    var start, end;
    switch (value) {
      case 1:
        var today = new Date();
        start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);

        setDates({ ...dates, ...{ start: start }, ...{ end: end } });
        loadDashBoardCount(start, end, loggedInUser);
        loadLatestCollections(start, end, loggedInUser);
        setShowDate(false);
        return;
      case 2:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);

        setDates({ ...dates, ...{ start: start }, ...{ end: end } });
        loadDashBoardCount(start, end, loggedInUser);
        loadLatestCollections(start, end, loggedInUser);
        setShowDate(false);
        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        setDates({ ...dates, ...{ start: start }, ...{ end: end } });

        loadDashBoardCount(start, end, loggedInUser);
        loadLatestCollections(start, end, loggedInUser);
        setShowDate(false);
        return;
      case 4:
        setShowDate(true);
        break;
      default:
    }
  };

  // window.addEventListener("beforeunload", function (e) {
  //   e.preventDefault();
  //   var confirmationMessage = "Are You Sure, You Want to Go Offine ";
  //   (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  //   return alert(confirmationMessage); //Gecko + Webkit, Safari, Chrome etc.
  // });

  // window.onbeforeunload = confirmExit;
  // function confirmExit() {
  //   alert("confirm exit is being called");
  //   return false;
  // }

  // window.onbeforeunload=(event)=>{
  //   event.preventDefault()
  //   event.returnValue=''
  //   return ''
  // }

  // const setPtpDateValue = (label, value) => {
  //   var date;
  //   var start, end;
  //   switch (value) {
  //     case 1:
  //       var today = new Date();
  //       start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
  //       end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);

  //       setPtpDates({ ...ptpDates, ...{ start: start }, ...{ end: end } });
  //       loadPtpCount(start, end, loggedInUser);
  //       //setShowDate(false);
  //       return;
  //     case 2:
  //       date = [
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate() + 1)).setHours(
  //             0,
  //             0,
  //             0
  //           )
  //         ),
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate() + 1)).setHours(
  //             23,
  //             59,
  //             59
  //           )
  //         ),
  //       ];
  //       start = Math.floor(date[0].getTime() / 1000);
  //       end = Math.floor(date[1].getTime() / 1000);

  //       setPtpDates({ ...ptpDates, ...{ start: start }, ...{ end: end } });
  //       loadPtpCount(start, end, loggedInUser);
  //       setShowDate(false);
  //       return;
  //     case 3:
  //       date = [
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0)
  //         ),
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate() + 6)).setHours(
  //             23,
  //             59,
  //             59
  //           )
  //         ),
  //       ];
  //       start = Math.floor(date[0].getTime() / 1000);
  //       end = Math.floor(date[1].getTime() / 1000);

  //       setPtpDates({ ...ptpDates, ...{ start: start }, ...{ end: end } });
  //       loadPtpCount(start, end, loggedInUser);
  //       setShowDate(false);
  //       return;
  //     default:
  //   }
  // };

  const setGraphDates = (label, value) => {
    var date;
    var start, end;

    switch (value) {
      case 1:
        setShowDate2(false);
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        setStart(start);
        setEnd(end);
        // loadCollectionGraph(start, end);
        setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        setIsMonthInProgress(false);
        // loadGraph(start, end, loggedInUser, false);
        onGraphTypeChange(
          currentLabelValue.label,
          currentLabelValue.value,
          start,
          end,
          loggedInUser,
          false
        );

        return;
      case 2:
        setShowDate2(false);
        var cd = new Date();
        var lastWeekLastDay = new Date(cd.setDate(cd.getDate() - cd.getDay()));
        var lastWeekFirstDay = new Date(
          cd.setDate(cd.getDate() - cd.getDay() - 6)
        );

        date = [
          new Date(lastWeekFirstDay.setHours(0, 0, 0)),
          new Date(lastWeekLastDay.setHours(23, 59, 59)),
        ];

        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        setStart(start);
        setEnd(end);
        // loadCollectionGraph(start, end);
        setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        setIsMonthInProgress(false);
        // loadGraph(start, end, loggedInUser, false);
        onGraphTypeChange(
          currentLabelValue.label,
          currentLabelValue.value,
          start,
          end,
          loggedInUser,
          false
        );

        return;
      case 3:
        setShowDate2(false);
        //This Month
        var d = new Date();
        d.setDate(1); //sets d the the first day of that month
        d.setHours(0, 0, 0, 0);
        var stDate = d.getTime();
        var curEndDate = new Date(
          new Date(new Date().setDate(new Date().getDate())).setHours(
            23,
            59,
            59
          )
        ).getTime();

        start = Math.floor(stDate / 1000);
        end = Math.floor(curEndDate / 1000);
        setStart(start);
        setEnd(end);
        // loadCollectionGraph(start, end);
        setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        setIsMonthInProgress(false);
        // loadGraph(start, end, loggedInUser, false);
        onGraphTypeChange(
          currentLabelValue.label,
          currentLabelValue.value,
          start,
          end,
          loggedInUser,
          false
        );

        return;
      case 4:
        setShowDate2(false);
        //Last month Logic
        // eslint-disable-next-line no-redeclare
        var d = new Date();
        d.setDate(0); //sets d to the last day of the previous month
        d.setHours(0, 0, 0, 0);
        var eDate = d.getTime();
        d.setDate(1); //sets d the the first day of that month
        d.setHours(0, 0, 0, 0);
        var sDate = d.getTime();
        start = Math.floor(sDate / 1000);
        end = Math.floor(eDate / 1000) + 86400;
        setStart(start);
        setEnd(end);
        // loadCollectionGraph(start, end);
        setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        setIsMonthInProgress(false);

        // loadGraph(start, end, loggedInUser, false);
        onGraphTypeChange(
          currentLabelValue.label,
          currentLabelValue.value,
          start,
          end,
          loggedInUser,
          false
        );
        break;
      case 5:
        setShowDate2(false);
        // eslint-disable-next-line no-redeclare
        var d = new Date();
        d.setDate(1);
        d.setHours(0, 0, 0, 0);
        end = d.getTime() / 1000;
        d.setMonth(d.getMonth() - 6);
        start = d.getTime() / 1000;
        setStart(start);
        setEnd(end);
        // loadCollectionGraph(start, end);
        setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
        setIsMonthInProgress(true);
        // loadGraph(start, end, loggedInUser, true);
        onGraphTypeChange(
          currentLabelValue.label,
          currentLabelValue.value,
          start,
          end,
          loggedInUser,
          true
        );
        break;
      case 6:
        setShowDate2(true);
        break;
      default:
    }
  };

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
      1000 +
      86400;
    setStart(start);
    setEnd(end);
    setUpdatedKey(Math.floor(Math.random() * 100000));
    // loadCollectionGraph(start, end);
    setChartDates({ ...chartDates, ...{ start: start }, ...{ end: end } });
    // setIsMonthInProgress(true);
    onGraphTypeChange(
      currentLabelValue.label,
      currentLabelValue.value,
      start,
      end,
      loggedInUser,
      false
    );
    // console.log(
    //   "date",
    //   value[0].getTime() / 1000,
    //   "jjj",
    //   new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
    //     1000 +
    //     86400
    // );
  };

  function setCustomDates(dateStrings) {
    let start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
    let end = Math.floor(new Date(dateStrings[1]).getTime() / 1000 + 86400);
    setDates({ ...dates, ...{ start: start }, ...{ end: end } });
    loadDashBoardCount(start, end, loggedInUser);
  }

  const loadDashBoardCount = (startDate, endDate, loggedInUser) => {
    if (!startDate || !endDate) return;

    Submit(startDate, endDate, loggedInUser);
  };

  const loadPtpCount = (startDate, endDate, loggedInUser) => {
    if (!startDate || !endDate) return;

    hanlePtpCounts(startDate, endDate, loggedInUser);
  };

  const hanlePtpCounts = (startDate, endDate, userDetails) => {
    var payLoad = {
      endTime: endDate,
      organizationId:
        userDetails.autoFinanceUser &&
        userDetails.autoFinanceUser.organizationId,
      reportingOfficerId: !isAdmin()
        ? userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId
        : "",
      startTime: startDate,
      userId: "",
    };

    moduleApi
      .postData(apiCall.getEmployeePtpCount, payLoad)
      .then((response) => {
        response && setPtpMetrics(response);
      });
  };

  const loadTargetDueCounts = (userDetails) => {
    var payLoad = {
      organizationId:
        userDetails.autoFinanceUser &&
        userDetails.autoFinanceUser.organizationId,
      reportingOfficerId: !isAdmin()
        ? userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId
        : "",
    };

    moduleApi
      .postData(apiCall.getTargetDueCounts, payLoad)
      .then((response) => {
        response && setTargetMetrics(response);
      });
  };

  const loadPtpOverDueCount = (startDate, endDate, loggedInUser) => {
    hanlePtpOverDueCounts(startDate, endDate, loggedInUser);
    // hanleLastMonthPtpOverDueCounts(
    //   lastMonthStartTime,
    //   lastMonthEndTime,
    //   loggedInUser
    // );
  };

  const hanlePtpOverDueCounts = (startDate, endDate, userDetails) => {
    var payLoad = {
      endTime: endDate,
      organizationId:
        userDetails.autoFinanceUser &&
        userDetails.autoFinanceUser.organizationId,
      reportingOfficerId: !isAdmin()
        ? userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId
        : "",
      startTime: startDate,
      userId: "",
      status: "PTPOverDue",
    };

    moduleApi
      .postData(apiCall.getEmployeePtpCount, payLoad)
      .then((response) => {
        response && setPtpOverDueMetrics(response);
      });
  };

  // const hanleLastMonthPtpOverDueCounts = (startDate, endDate, userDetails) => {
  //   var payLoad = {
  //     endTime: endDate,
  //     organizationId:
  //       userDetails.autoFinanceUser &&
  //       userDetails.autoFinanceUser.organizationId,
  //     reportingOfficerId: !isAdmin()
  //       ? userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId
  //       : "",
  //     startTime: startDate,
  //     userId: "",
  //     status: "PTPOverDue",
  //   };

  //   moduleApi
  //     .postData(apiCall.getEmployeePtpCount, payLoad)
  //     .then((response) => {
  //       response && setLastMonthPtpOverDueMetrics(response);
  //     });
  // };

  const handleIncentiveCounts = () => {
    var payLoad = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? ""
        : hasAccess(74)
          ? ""
          : loggedInUser.autoFinanceUser.userId,
    };

    moduleApi
      .postData(apiCall.getIncentivesCountForDashboard, payLoad)
      .then((response) => {
        response && setIncentiveMetrics(response);
      });
  };

  const loadPtpCountForTomorrow = (startDate, endDate, loggedInUser) => {
    if (!startDate || !endDate) return;

    hanlePtpCountsForTomorrow(startDate, endDate, loggedInUser);
  };

  const hanlePtpCountsForTomorrow = (startDate, endDate, userDetails) => {
    var payLoad = {
      endTime: endDate,
      organizationId:
        userDetails.autoFinanceUser &&
        userDetails.autoFinanceUser.organizationId,
      reportingOfficerId: !isAdmin()
        ? userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId
        : "",
      startTime: startDate,
      userId: "",
    };

    moduleApi
      .postData(apiCall.getEmployeePtpCount, payLoad)
      .then((response) => {
        response && setPtpTomorrowMetrics(response);
      });
  };

  const Submit = (startDate, endDate, userDetails) => {
    var payLoad = {
      endDate: endDate,
      organizationid:
        userDetails.autoFinanceUser &&
        userDetails.autoFinanceUser.organizationId,
      reportingOfficerId:
        userDetails.autoFinanceUser && userDetails.autoFinanceUser.userId,
      roleName:
        userDetails.rolePrivileges[0] && userDetails.rolePrivileges[0].roleName,
      startDate: startDate,
      userId: "",
    };

    moduleApi.postData(apiCall.dashboardCount, payLoad).then((response) => {
      response && setMetrics(response);
    });
  };

  const onCardClick = (key) => {
    setTitle(key);

    var ptpTitlee = key.replace(/-/g, " ");

    setPtpTitle(ptpTitlee);

    if (key === "PTP-Due-Tomorrow") {
      setBasicModal4(true);
    } else if (key === "PTP-Due-Today") {
      setBasicModal3(true);
    } else if (key === "PTP-Overdue") {
      setBasicModal6(true);
    } else if (key === "Last-Month-PTP-Overdue") {
      setBasicModal6(true);
    } else {
      setBasicModal(true);
    }
  };

  const onTargetDueCardClick = (key) => {
    setTitle(key);

    var targetDueTitlee = key.replace(/-/g, " ");
    setTargetDueTitle(targetDueTitlee);
    setTargetDueCountModal(true);
  };

  const onCardClick2 = (key) => {
    setTitle(key);
    setBasicModal2(true);
  };

  const onGraphTypeChange = (label, value, startD, endD, user, isMonth) => {
    setCurrentLabelValue({ label: label, value: value });
    // eslint-disable-next-line default-case
    switch (label) {
      case "Collections":
        setIsCollectionGraph(true);
        setIsFIGraph(false);
        setIsBranchwiseCollectionGraph(false);
        setIsBranchwiseFIGraph(false);
        // loadGraph(startD, endD, user, isMonth);
        break;
      case "Field Investigation":
        // loadGraph(startD, endD, user, isMonth);
        setIsCollectionGraph(false);
        setIsFIGraph(true);
        setIsBranchwiseCollectionGraph(false);
        setIsBranchwiseFIGraph(false);
        setPaymentMode("");
        break;
      case "Sales":
        loadGraph(startD, endD, user, isMonth);
        setIsCollectionGraph(false);
        setIsFIGraph(false);
        setIsBranchwiseCollectionGraph(false);
        setIsBranchwiseFIGraph(false);
        setPaymentMode("");
        break;
      case "Branch Wise Collections":
        // loadBranchWiseCollections(startD, endD, user, "");
        setIsBranchwiseCollectionGraph(true);
        setIsCollectionGraph(false);
        setIsBranchwiseFIGraph(false);
        setIsFIGraph(false);
        // setPaymentMode("");
        break;
      case "Branch Wise Sales":
        loadBranchWiseSales(startD, endD, user, "");
        setIsCollectionGraph(false);
        setIsFIGraph(false);
        setIsBranchwiseCollectionGraph(false);
        setIsBranchwiseFIGraph(false);
        setPaymentMode("");
        break;
      case "Branch Wise Field Investigation":
        setIsBranchwiseCollectionGraph(false);
        setIsBranchwiseFIGraph(true);
        setIsCollectionGraph(false);
        setIsFIGraph(false);
        setPaymentMode("");
        break;
    }
    setGraphType(value);
  };

  const getCollectionDropdownOptions = () => {
    var payload = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
    };

    moduleApi
      .postData(apiCall.getCollectionsStatus, payload)
      .then((response) => {
        const result = response.map((item) => ({
          label: item,
          value: item,
        }));
        result.unshift({ label: "All", value: "" });

        // console.log("result", result);

        setCollectionStatuses(
          result.filter(
            (x) =>
              x?.value !== "Third Party" &&
              x?.value !== "Repossessed" &&
              x?.value !== "Already Settled"
          )
        );
      });
  };

  const handleManagerColumns = (value) => {
    setIsBackButton(value);
  };

  const handleAllocationsColumns = (value) => {
    setIsBackButton(value);
  };

  const handleClick = (value) => {
    setIsBackButton(value);
  };

  const getCallCountsData = () => {
    var payload = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      teamId: loggedInUser?.selectedTeam?.id,
      userId: loggedInUser.autoFinanceUser.userId,
    };
    moduleApi.postData(apiCall.getCallCounts, payload).then((response) => {
      setCallCounts(response);
    });
  };

  const getLoginCountsData = () => {
    var payload = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
    };
    moduleApi.postData(apiCall.getLoginCounts, payload).then((response) => {
      setLoginCounts(response);
    });
  };

  const getCollectionAllocationCountsData = (start, end) => {
    var payload = {
      endDate: end,
      startDate: start,
      organizationid: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
    };
    moduleApi
      .postData(apiCall.getCollectionAllocationCounts, payload)
      .then((response) => {
        setCollectionAllocationCounts(response);
      });
  };

  const setCustomDatesForGraphForCollectionCounts = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
      1000 +
      86399;

    setStartDate(start);
    setEndDate(end);
    getCollectionAllocationCountsData(start, end);
  };

  return (
    <div>
      {!(
        hasAccess(42) &&
        loggedInUser?.autoFinanceUser?.teleCmiUserId !== null &&
        loggedInUser?.autoFinanceUser?.teleCmiPassword !== null &&
        loggedInUser?.autoFinanceUser?.teleCmiUserId !== "" &&
        loggedInUser?.autoFinanceUser?.teleCmiPassword !== ""
      ) && (
          <>
            <div style={{ display: "flex", justifyContent: "end" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left"
              }}
            >
              <label style={{ fontWeight: "bold" }}>
                Select Range
              </label>
              <div
                style={{
                  width: "150px",
                  height: "38px",
                  marginRight: "20px",
                  borderRadius: "3px",
                  borderWidth: "0px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  id="dateSelection"
                  isSearchable={false}
                  onChange={(value) =>
                    setDateValue(value.label, value.value, optionDates)
                  }
                  defaultValue={optionDates[0]}
                  options={optionDates}
                />
              </div>
              </div>
              {showDate && (
                <div style={{ marginRight: "20px",marginTop:"20px"}}>
                  <Flatpickr
                    style={{
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                    id="customDatePicker"
                    className="form-control d-block"
                    placeholder="Start & End Date"
                    onChange={(value) => {
                      setCustomDates(value);
                    }}
                    options={{
                      mode: "range",
                      dateFormat: "d-M-y",
                      maxDate: new Date(),
                    }}
                  />
                </div>
              )}
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {hasAccess(3) && (
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
                  <div className="widget-stat card bg-blue">
                    <div
                      className="card-body  p-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => onCardClick("Sales")}
                    >
                      <div className="media">
                        <span className="me-3">
                          {/* <i className="flaticon-381-user-9"></i> */}
                          <img
                            src="/sidebar/sales.png"
                            alt=""
                            style={{ height: "45px" }}
                          />
                          {/* <i className="la la-users"></i> */}
                        </span>
                        <div className="media-body text-white text-end">
                          <p className="mb-1">Sales Visits</p>
                          <h3 className="text-white">
                            {metrics.salesVisitCount}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {hasAccess(4) && (
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6  p-3">
                  <div className="widget-stat card bg-success">
                    <div
                      className="card-body  p-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => onCardClick2("Collections")}
                    >
                      <div className="media">
                        <span className="me-3">
                          {/* <i className="flaticon-381-user-9"></i> */}
                          <img
                            src="/sidebar/collections.png"
                            alt=""
                            style={{ height: "45px" }}
                          />
                          {/* <i className="la la-exchange"></i> */}
                        </span>
                        <div className="media-body text-white text-end">
                          <p className="mb-1">Collection Visits</p>
                          <h3 className="text-white">
                            {metrics.collectionVisitCount}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {hasAccess(6) && (
          <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
            <div className="widget-stat card bg-primary">
              <div className="card-body p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="flaticon-381-calendar-1"></i>
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1">Kilometers Travelled</p>
                    <h3 className="text-white">
                      {metrics.noOfKilometersTravelled}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
              {hasAccess(5) && (
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
                  <div className="widget-stat card bg-primary">
                    <div
                      className="card-body p-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => onCardClick("FieldInvestigation")}
                    >
                      <div className="media">
                        <span className="me-3">
                          {/* <i className="flaticon-381-calendar-1"></i> */}
                          <img
                            src="/sidebar/fieldInvestigation.png"
                            alt=""
                            style={{ height: "45px", marginLeft: "4px" }}
                          />
                        </span>
                        <div className="media-body text-white text-end">
                          <p className="mb-1">Field Investigation Visits</p>
                          <h3 className="text-white">
                            {metrics.fieldInvestigation}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      {/* {hasAccess(54) && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div
            style={{
              width: "150px",
              height: "38px",
              marginRight: "20px",
              borderRadius: "3px",
              borderWidth: "0px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              id="ptpDateSelection"
              isSearchable={false}
              onChange={(value) =>
                setPtpDateValue(value.label, value.value, ptpDatesOption)
              }
              defaultValue={ptpDatesOption[0]}
              options={ptpDatesOption}
            />
          </div>
        </div>
      )} */}

      {hasAccess(42) &&
        hasAccess(78) &&
        loggedInUser?.autoFinanceUser?.teleCmiUserId !== null &&
        loggedInUser?.autoFinanceUser?.teleCmiPassword !== null &&
        loggedInUser?.autoFinanceUser?.teleCmiUserId !== "" &&
        loggedInUser?.autoFinanceUser?.teleCmiPassword !== "" && (
          <div className="card" style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">Call Counts</h4>
            </div>
            <div
              className="card-header border-0 pb-0"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
                <div className="widget-stat card bg-primary">
                  <div
                    className="card-body p-4"
                    style={{ cursor: "pointer" }}
                  // onClick={() => onCardClick("PTP-Overdue")}
                  >
                    <div className="media">
                      <span className="me-3">
                        <i className="fa fa-list"></i>
                      </span>
                      <div className="media-body text-white text-end">
                        <p style={{ fontWeight: "bold" }} className="mb-1">
                          Total Calls
                        </p>
                        <h3 className="text-white">{callCounts?.totalCalls}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
                <div className="widget-stat card bg-success">
                  <div
                    className="card-body p-4"
                    style={{ cursor: "pointer" }}
                  // onClick={() => onCardClick("PTP-Due-Today")}
                  >
                    <div className="media">
                      <span className="me-3">
                        <i className="fas fa-phone-alt"></i>
                      </span>
                      <div className="media-body text-white text-end">
                        <p style={{ fontWeight: "bold" }} className="mb-1">
                          Calls Connected
                        </p>
                        <h3 className="text-white">
                          {callCounts?.callConnectedCount}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
                <div className="widget-stat card bg-danger">
                  <div
                    className="card-body p-4"
                    style={{ cursor: "pointer" }}
                  // onClick={() => onCardClick("PTP-Due-Tomorrow")}
                  >
                    <div className="media">
                      <span className="me-3">
                        <i className="fas fa-phone-slash"></i>
                      </span>
                      <div className="media-body text-white text-end">
                        <p style={{ fontWeight: "bold" }} className="mb-1">
                          Calls Not Connected
                        </p>
                        <h3 className="text-white">
                          {callCounts?.callNotConnectedCount}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card-header border-0 pb-0"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-2">
                <div className="widget-stat card bg-info">
                  <div
                    className="card-body p-4"
                    style={{ cursor: "pointer" }}
                  // onClick={() => onCardClick("PTP-Due-Tomorrow")}
                  >
                    <div className="media">
                      <span className="me-3">
                        <i className="flaticon-381-phone-call"></i>
                      </span>
                      <div className="media-body text-white text-end">
                        <p style={{ fontWeight: "bold" }} className="mb-1">
                          Calls With No Dispostion
                        </p>
                        <h3 className="text-white">
                          {callCounts?.callswithNoDispositionCount}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-2">
                <div className="widget-stat card bg-blue">
                  <div
                    className="card-body p-4"
                    style={{ cursor: "pointer" }}
                  // onClick={() => onCardClick("PTP-Due-Tomorrow")}
                  >
                    <div className="media">
                      <span className="me-3">
                        <i className="fa fa-list"></i>
                      </span>
                      <div className="media-body text-white text-end">
                        <p style={{ fontWeight: "bold" }} className="mb-1">
                          Calls Not Attempted
                        </p>
                        <h3 className="text-white">
                          {callCounts?.callsNotAttemped}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
                {/* <div className="widget-stat card bg-dark">
                  <div
                    className="card-body p-4"
                    style={{ cursor: "pointer" }}
                    // onClick={() => onCardClick("PTP-Due-Tomorrow")}
                  >
                    <div className="media">
                      <span className="me-3">
                        <i className="flaticon-381-phone-call"></i>
                      </span>
                      <div className="media-body text-white text-end">
                        <p style={{ fontWeight: "bold" }} className="mb-1">
                          Calls To Be Done
                        </p>
                        <h3 className="text-white">
                          {callCounts?.callsToBeDone}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}

      {hasAccess(91) && (
        <div className="card" style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="card-header border-0 pb-0">
            <h4 className="card-title">Allocations</h4>
            <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left"
              }}
            >
              <label style={{ fontWeight: "bold",marginLeft:"5px" }}>
                Select Month
              </label>
              <MonthPicker
                setCustomDatesForGraph={setCustomDatesForGraphForCollectionCounts}
              />
            </div>
            </div>
          </div>
          <div
            className="card-header border-0 pb-0"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-orange">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    // localStorage.setItem(
                    //   "isDashboardCollectionAllocationCardCalled",
                    //   "true"
                    // );
                    setIsBackButton(false)
                    setBasicModal9(true);
                  }}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-user-9"></i>
                      {/* <img
                      src="/sidebar/checkedOut.png"
                      alt=""
                      style={{ height: "43px", marginLeft: "6px" }}
                    /> */}
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Allocation Counts
                      </p>
                      <h3 className="text-white">
                        {collectionAllocationCounts?.allocationCounts}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-primary">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsBackButton(false)
                    setBasicModal8(true);
                  }}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-user-9"></i>
                      {/* <img
                     src="/sidebar/visits.png"
                      alt=""
                      style={{ height: "40px" }}
                    /> */}
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Visit Counts
                      </p>
                      <h3 className="text-white">
                        {collectionAllocationCounts?.visitFromAllocationsCount}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-success">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsBackButton(false)
                    setBasicModal7(true);
                  }}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-repeat-1"></i>
                      {/* <img
                      src="/sidebar/checkedIn.png"
                      alt=""
                      style={{ height: "40px", marginRight: "5px" }}
                    /> */}
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Repeat Visit Counts
                      </p>
                      <h3 className="text-white">
                        {collectionAllocationCounts?.repeatVisitsCount}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {hasAccess(119) && (
      <div className="card" style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="card-header border-0 pb-0">
          <h4 className="card-title">Employee Check In Counts</h4>
        </div>
        <div
          className="card-header border-0 pb-0"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
            <div className="widget-stat card bg-primary">
              <div
                className="card-body p-4"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setLoginCountStatus("YET_TO_CHECK_IN");
                  setBasicModal5(true);
                }}
              >
                <div className="media">
                  <span className="me-3">
                    {/* <i className="flaticon-381-user-9"></i> */}
                    <img
                      src="/sidebar/notYetCheckedIn.png"
                      alt=""
                      style={{ height: "40px" }}
                    />
                  </span>
                  <div className="media-body text-white text-end">
                    <p style={{ fontWeight: "bold" }} className="mb-1">
                      Yet To Check In
                    </p>
                    <h3 className="text-white">
                      {loginCounts?.yetToCheckInUsersCount}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
            <div className="widget-stat card bg-success">
              <div
                className="card-body p-4"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setLoginCountStatus("CHECK_IN");
                  setBasicModal5(true);
                }}
              >
                <div className="media">
                  <span className="me-3">
                    {/* <i className="flaticon-381-user-9"></i> */}
                    <img
                      src="/sidebar/checkedIn.png"
                      alt=""
                      style={{ height: "40px", marginRight: "5px" }}
                    />
                  </span>
                  <div className="media-body text-white text-end">
                    <p style={{ fontWeight: "bold" }} className="mb-1">
                      Checked In Employees
                    </p>
                    <h3 className="text-white">
                      {loginCounts?.checkInUsesCount}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
            <div className="widget-stat card bg-danger">
              <div
                className="card-body p-4"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setLoginCountStatus("CHECK_OUT");
                  setBasicModal5(true);
                }}
              >
                <div className="media">
                  <span className="me-3">
                    {/* <i className="flaticon-381-user-9"></i> */}
                    <img
                      src="/sidebar/checkedOut.png"
                      alt=""
                      style={{ height: "43px", marginLeft: "6px" }}
                    />
                  </span>
                  <div className="media-body text-white text-end">
                    <p style={{ fontWeight: "bold" }} className="mb-1">
                      Checked Out Employees
                    </p>
                    <h3 className="text-white">
                      {loginCounts?.checkedOutUsersCount}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       )} 

      {hasAccess(54) && (
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="card-title">PTPs</h4>
          </div>
          <div
            className="card-header border-0 pb-0"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-danger">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onCardClick("PTP-Overdue")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-warning-1"></i>
                    </span>
                    <div className="media-body text-white">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        PTP Overdue-{ptpOverDueMetrics.ptpCount}
                      </p>
                      <h4 style={{ fontWeight: "10px" }} className="text-white">
                        ₹{ptpOverDueMetrics.ptpAmount}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-orange">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onCardClick("PTP-Due-Today")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-stopwatch"></i>
                    </span>
                    <div className="media-body text-white">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        PTP Due Today-{ptpMetrics.ptpCount}
                      </p>
                      <h4 className="text-white">₹{ptpMetrics.ptpAmount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-primary">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onCardClick("PTP-Due-Tomorrow")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-stopwatch"></i>
                    </span>
                    <div className="media-body text-white">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        PTP Due Tomorrow-{ptpTomorrowMetrics.ptpCount}
                      </p>
                      <h4 className="text-white">
                        ₹{ptpTomorrowMetrics.ptpAmount}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-dark">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onCardClick("Last-Month-PTP-Overdue")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-warning-1"></i>
                    </span>
                    <div className="media-body text-white">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Last Month Overdue-{lastMonthPtpOverDueMetrics.ptpCount}
                      </p>
                      <h4 className="text-white">
                        ₹{lastMonthPtpOverDueMetrics.ptpAmount}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}

      {hasAccess(101) && (
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="card-title">Target Dues</h4>
          </div>
          <div
            className="card-header border-0 pb-0"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-danger">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onTargetDueCardClick("Target-Past-Due")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-warning-1"></i>
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Target Past Due
                      </p>
                      <h3 className="text-white">
                        {targetDueMetrics?.targePastDue}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-orange">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onTargetDueCardClick("Target-Due-Today")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-stopwatch"></i>
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Target Due Today
                      </p>
                      <h3 className="text-white">
                        {targetDueMetrics?.targetDueToday}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-success">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onTargetDueCardClick("Targets-Achieved")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-stopwatch"></i>
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Targets Achieved
                      </p>
                      <h3 className="text-white">
                        {targetDueMetrics?.targetDueAchieved}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-primary">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onTargetDueCardClick("Targets-Attempted")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-stopwatch"></i>
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Targets Attempted
                      </p>
                      <h3 className="text-white">
                        {targetDueMetrics?.targetsAttempted}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3" style={{marginRight:"300px"}}>
              <div className="widget-stat card bg-dark">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onTargetDueCardClick("Targets-Not-Attempted")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="flaticon-381-stopwatch"></i>
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Targets Not Attempted
                      </p>
                      <h3 className="text-white">
                        {targetDueMetrics?.targetsNotAttempted}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                    
          </div>
        </div>
      )}

      {hasAccess(70) && (
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="card-title">Incentives</h4>
          </div>
          <div
            className="card-header border-0 pb-0"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3">
              <div className="widget-stat card bg-success">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/active-incentives")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="fas fa-coins"></i>
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        {" "}
                        Active Incentives
                      </p>
                      <h3 className="text-white">
                        {incentiveMetrics.incentivesCount}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 p-3"
              style={{ marginRight: "310px" }}
            >
              <div className="widget-stat card bg-blue">
                <div
                  className="card-body p-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/eligible-employees")}
                >
                  <div className="media">
                    <span className="me-3">
                      <i className="fas fa-user-check"></i>
                    </span>
                    <div className="media-body text-white text-end">
                      <p style={{ fontWeight: "bold" }} className="mb-1">
                        Eligible Employees{" "}
                      </p>
                      <h3 className="text-white">
                        {incentiveMetrics.eligibleForCount}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div>
        <iframe src="https://in-ccaas.ozonetel.com/toolbar_widget/index.html?width=340#dashboard-agent" width="1040" height="450"/>
      </div> */}
      {/* <Row>
        <Col>
          <div className="card">
            <div style={{ margin: "10px 20px" }}>
              <h3>Users Live Status</h3>

              <Table responsive hover bordered>
                <thead
                  style={{
                    backgroundColor: "#F3F0F9",
                    top: "1px",
                    position: "sticky",
                  }}
                >
                  <tr
                    style={{
                      verticalAlign: "middle",
                      textAlignLast: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <td>Emp Name</td>
                    <td>Emp Id</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody style={{ textAlignLast: "center" }}>
                  {agentStatuses?.map((ele) => {
                    return (
                      <tr style={{ textAlignLast: "center" }}>
                        <td>{ele.empName ? ele.empName : "-"}</td>
                        <td>{ele.empId ? ele.empId : "-"}</td>
                        <td style={{color: getColoredStatuses(camelize(ele.status)), fontWeight:'bold'}}>{ele.status ? camelize(ele.status) : "-"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
        <Col>
          <div className="card">
            <div style={{ margin: "10px 20px" }}>
              <h3>Users Live Status Counts</h3>

              <Table responsive hover bordered>
                <thead
                  style={{
                    backgroundColor: "#F3F0F9",
                    top: "1px",
                    position: "sticky",
                  }}
                >
                  <tr
                    style={{
                      verticalAlign: "middle",
                      textAlignLast: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <td>Status</td>
                    <td>Count</td>
                  </tr>
                </thead>
                <tbody style={{ textAlignLast: "center" }}>
                  {agentStatusCounts?.map((ele) => {
                    return (
                      <tr style={{ textAlignLast: "center" }}>
                        <td style={{color: getColoredStatuses(camelize(ele.status)), fontWeight:'bold'}}>{ele.status ? camelize(ele.status) : "-"}</td>
                        <td>{ele.count ? ele.count : "-"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Card>
          <Card.Header>
            <h4 className="card-title" id="collectionsGraphHeaderText">
              {graphType + " Trend"}
            </h4>
            <div
              style={{
                width: "230px",
              }}
            >
              <span>Type</span>
              <div
                id="graphTypeOptions"
                style={{
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={false}
                  onChange={(record) => {
                    onGraphTypeChange(
                      record.label,
                      record.value,
                      chartDates.start,
                      chartDates.end,
                      loggedInUser,
                      isMonthInProgress
                    );
                    setUpdatedKey(Math.floor(Math.random() * 100000));
                  }}
                  defaultValue={{ label: "Collections", value: "Collections" }}
                  options={chartTypesDropDown}
                />
              </div>
            </div>
            {(isCollectionGraph || isBranchwiseCollectionGraph) && (
              <div
                style={{
                  width: "120px",
                }}
              >
                <span>Status</span>
                <div
                  id="statusOptions"
                  style={{
                    borderRadius: "3px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  {/* <Select
                    isSearchable={false}
                    onChange={(record) => {
                      setPaymentMode(record.value);
                      setStatus2Value("");
                      setUpdatedKey(Math.floor(Math.random() * 100000));
                    }}
                    defaultValue={{ label: "All", value: "" }}
                    options={statusOptions}
                  /> */}
                  <Select
                    isSearchable={false}
                    onChange={(record) => {
                      setPaymentMode(record.value);
                      record.value === "Paid"
                        ? setStatus2Value("counts")
                        : setStatus2Value("");
                      setUpdatedKey(Math.floor(Math.random() * 100000));
                    }}
                    defaultValue={{ label: "All", value: "" }}
                    options={collectionStatuses}
                  />
                </div>
              </div>
            )}
            {(isCollectionGraph || isBranchwiseCollectionGraph) &&
              isBranchwiseFIGraph === false &&
              paymentMode === "Paid" && (
                <div
                  style={{
                    marginTop: "15px",
                    width: "120px",
                  }}
                >
                  <div
                    id="status2Options"
                    style={{
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      isSearchable={false}
                      onChange={(record) => {
                        setStatus2Value(record.value);
                        setUpdatedKey(Math.floor(Math.random() * 100000));
                      }}
                      defaultValue={{ label: "Counts", value: "counts" }}
                      options={status2Options}
                    />
                  </div>
                </div>
              )}
            <div
              style={{
                width: "150px",
              }}
            >
              <span>Select Range</span>
              <div
                id="collectionsDateRange"
                style={{
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    setGraphDates(value.label, value.value);
                    setUpdatedKey(Math.floor(Math.random() * 100000));
                  }}
                  defaultValue={chartDateOptions[0]}
                  options={chartDateOptions}
                />
              </div>
            </div>

            {showDate2 && (
              <div
                id="collectionsCustomOption"
                style={{
                  textAlignLast: "center",
                  alignSelf: "self-end",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                  borderRadius: "13px",
                }}
              >
                {/* <span>Select Custom Month</span> */}
                <Flatpickr
                  className="form-control d-block"
                  placeholder="Select Month"
                  onChange={(value) => {
                    setCustomDatesForGraph(value);
                  }}
                  options={{
                    // mode: "range",
                    // dateFormat: "d-M-y",
                    maxDate: new Date(),
                    minDate: new Date().setMonth(new Date().getMonth() - 6),

                    plugins: [
                      new monthSelectPlugin({
                        shorthand: true, //defaults to false
                        dateFormat: "F Y", //defaults to "F Y"
                        altFormat: "F Y", //defaults to "F Y"
                        theme: "light", // defaults to "light"
                      }),
                    ],
                  }}
                />
              </div>
            )}
            {/* {!isCollectionGraph && !isFIGraph && !isBranchwiseCollectionGraph && (
              <div
                style={{
                  width: "120px",
                }}
              >
                <span>Select Graph</span>
                <div
                  style={{
                    borderRadius: "3px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => setChartType(value)}
                    defaultValue={collectionsGraphOptions[1]}
                    options={collectionsGraphOptions}
                  />
                </div>
              </div>
            )} */}
          </Card.Header>
          <Card.Body style={{ paddingTop: "2px" }}>
            {/* {graph ? ( */}
            {!isCollectionGraph ? (
              !isFIGraph ? (
                !isBranchwiseCollectionGraph ? (
                  !isBranchwiseFIGraph ? (
                    <ApexBar2
                      chartDetails={arr}
                      // branchWiseCollectionDetails={branchArr}
                      branchWiseSalesDetails={salesBranchArr}
                      key={updatedKey}
                      chartType={graphType}
                    />
                  ) : (
                    <BranchwiseFIGraph
                      startDate={start}
                      endDate={end}
                      key={updatedKey}
                    />
                  )
                ) : (
                  <BranchwiseCollectionGraph
                    startDate={start}
                    endDate={end}
                    key={updatedKey}
                    paymentMode={paymentMode}
                    status2={status2Value}
                  />
                )
              ) : (
                <FieldInvestigationGraph
                  startDate={start}
                  endDate={end}
                  key={updatedKey}
                  month={isMonthInProgress}
                />
              )
            ) : (
              <CollectionGraph
                // graphData={CollectionGraphData}
                startDate={start}
                endDate={end}
                key={updatedKey}
                month={isMonthInProgress}
                paymentMode={paymentMode}
                status2={status2Value}
              />
            )}
            {/* ) : (
              <CSLineChart
                chartDetails={arr}
                // branchWiseCollectionDetails={branchArr}
                branchWiseSalesDetails={salesBranchArr}
                key={updatedKey}
                chartType={graphType}
              />
            )} */}
          </Card.Body>
        </Card>
        {/* </Col> */}

        {/* <Col xl={6} lg={6}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Sales</h4>
              <div
                style={{
                  width: "120px",
                }}
              >
                <span>Select Graph</span>
                <Select
                  isSearchable={false}
                  onChange={(value) => setSalesGraphValue(value)}
                  defaultValue={salesGraphOptions[0]}
                  options={salesGraphOptions}
                />
              </div>
            </Card.Header>
            <Card.Body style={{ paddingTop: "2px" }}>
              {isSalesGraph ? (
                <ApexBar2
                  chartDetails={arr}
                  key={updatedKey}
                  chartType={"Sales"}
                />
              ) : (
                <CSLineChart
                  chartDetails={arr}
                  key={updatedKey}
                  chartType={"Sales"}
                />

              )}
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
      {loggedInUser?.autoFinanceUser.roleName === "ADMIN" && <PaymentsGraph2 />}

      {/* <Row>
       

        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Sales</h4>
              <div
                style={{
                  width: "120px",
                }}
              >
                  <span>Select Graph</span>
                <Select
                  isSearchable={false}
                  onChange={(value) => setSalesGraphValue(value)}
                  defaultValue={salesGraphOptions[0]}
                  options={salesGraphOptions}
                />
              </div>
            </Card.Header>
            <Card.Body style={{ paddingTop: "2px" }}>
            {isSalesGraph ? (
                <ApexBar2
                chartDetails={arr}
                key={updatedKey}
                chartType={"Sales"}
              />
              ) : (
                <CSLineChart
                chartDetails={arr}
                key={updatedKey}
                chartType={"Sales"}
              />
                
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      <div className="row">
        {hasAccess(42) &&
          loggedInUser?.autoFinanceUser?.teleCmiUserId !== null &&
          loggedInUser?.autoFinanceUser?.teleCmiPassword !== null &&
          loggedInUser?.autoFinanceUser?.teleCmiUserId !== "" &&
          loggedInUser?.autoFinanceUser?.teleCmiPassword !== "" && (
            <div className="col-xl-4 col-xxl-6 col-lg-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Latest Break Timeline</h4>
                </div>
                <div className="card-body">
                  <h4>
                    Total Break Time -{" "}
                    {secondsToHms(callTimeLineData.totalBreakTime)}
                  </h4>
                  <PerfectScrollbar
                    style={{ height: "450px" }}
                    id="DZ_W_TimeLine"
                    className="widget-timeline dlab-scroll height370 ps ps--active-y"
                  >
                    <ul className="timeline">
                      {callTimeLineData?.break?.map((item) => {
                        return (
                          <li>
                            <div className="timeline-badge primary"></div>
                            {/* <Link
                            className="timeline-panel text-muted"
                            to="/widget-basic"
                          > */}
                            <div className="timeline-panel text-muted">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    alignSelf: "self-end",
                                  }}
                                >
                                  {convertEpochTimeToDate(item?.createdTime)}
                                </span>
                                <span
                                  className={
                                    item?.status === "Online"
                                      ? "badge badge-circle badge-success"
                                      : item?.status === "Offline"
                                        ? "badge badge-circle badge-danger"
                                        : "badge badge-circle badge-warning"
                                  }
                                  style={{
                                    color:
                                      item?.status === "Online" ||
                                        item?.status === "Offline"
                                        ? "white"
                                        : "black",
                                    fontSize: "small",
                                    width: "100px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.status}&nbsp;
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <span
                                  style={{
                                    color:
                                      item?.status === "Online"
                                        ? "green"
                                        : item?.status !== "Offline"
                                          ? "orange"
                                          : "red",
                                    fontSize: "small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.status}&nbsp;
                                </span>
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.status !== "Online" &&
                                    item?.status !== "Offline" &&
                                    ` - ${item?.breakName}`}
                                </span>
                              </div>
                              <h6 className="mb-0">
                                {`Time : ${item?.timeGap !== 0
                                    ? secondsToHms(item?.timeGap)
                                    : "-"
                                  }`}
                              </h6>
                            </div>
                            {/* </Link> */}
                          </li>
                        );
                      })}
                    </ul>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          )}
        {hasAccess(42) &&
          loggedInUser?.autoFinanceUser?.teleCmiUserId !== null &&
          loggedInUser?.autoFinanceUser?.teleCmiPassword !== null &&
          loggedInUser?.autoFinanceUser?.teleCmiUserId !== "" &&
          loggedInUser?.autoFinanceUser?.teleCmiPassword !== "" && (
            <div className="col-xl-4 col-xxl-6 col-lg-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Latest Calls Timeline</h4>
                </div>
                <div className="card-body">
                  <PerfectScrollbar
                    style={{ height: "450px" }}
                    id="DZ_W_TimeLine"
                    className="widget-timeline dlab-scroll height370 ps ps--active-y"
                  >
                    <ul className="timeline">
                      {callTimeLineData?.nonBreak?.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="timeline-badge primary"></div>
                            {/* <Link
                            className="timeline-panel text-muted"
                            to="/widget-basic"
                          > */}
                            <div className="timeline-panel text-muted">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    alignSelf: "self-end",
                                  }}
                                >
                                  {convertEpochTimeToDate(item?.createdTime)}
                                </span>
                                <span
                                  className={
                                    item?.status === "Online"
                                      ? "badge badge-circle badge-success"
                                      : item?.status === "Offline"
                                        ? "badge badge-circle badge-danger"
                                        : "badge badge-circle badge-warning"
                                  }
                                  style={{
                                    color:
                                      item?.status === "Online" ||
                                        item?.status === "Offline"
                                        ? "white"
                                        : "black",
                                    fontSize: "small",
                                    width: "100px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.status}&nbsp;
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <span
                                  style={{
                                    color:
                                      item?.status === "Online"
                                        ? "green"
                                        : item?.status !== "Offline"
                                          ? "orange"
                                          : "red",
                                    fontSize: "small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.status}&nbsp;
                                </span>
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.status !== "Online" &&
                                    item?.status !== "Offline" &&
                                    ` - ${item?.breakName}`}
                                </span>
                              </div>
                              <h6 className="mb-0">
                                {`Time Gap : ${item?.timeGap !== 0
                                    ? secondsToHms(item?.timeGap)
                                    : "-"
                                  }`}
                              </h6>
                            </div>
                            {/* </Link> */}
                          </li>
                        );
                      })}
                    </ul>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          )}
        {!(
          hasAccess(42) &&
          loggedInUser?.autoFinanceUser?.teleCmiUserId !== null &&
          loggedInUser?.autoFinanceUser?.teleCmiPassword !== null &&
          loggedInUser?.autoFinanceUser?.teleCmiUserId !== "" &&
          loggedInUser?.autoFinanceUser?.teleCmiPassword !== ""
        ) && (
            <>
              {hasAccess(111) && (
                <div className="col-xl-4 col-xxl-6 col-lg-6">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">Latest Collections Timeline</h4>
                    </div>
                    <div className="card-body">
                      <PerfectScrollbar
                        style={{ height: "450px" }}
                        id="DZ_W_TimeLine"
                        className="widget-timeline dlab-scroll height370 ps ps--active-y"
                      >
                        <ul className="timeline">
                          {latestCollections.map((item) => {
                            return (
                              <li>
                                <div className="timeline-badge primary"></div>
                                {/* <Link
                            className="timeline-panel text-muted"
                            to="/widget-basic"
                          > */}
                                <div className="timeline-panel text-muted">
                                  <span
                                    style={{
                                      color: "black",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {convertEpochTimeToDate(item.dateTime)}
                                    {item.collectionVisit.status === "Paid" ? (
                                      <span
                                        className="badge badge-circle badge-success"
                                        style={{ width: "100px" }}
                                      >
                                        {item.collectionVisit.status}
                                      </span>
                                    ) : item.collectionVisit.status === "PTP" ||
                                      item.collectionVisit.status ===
                                      "Payment Failed" ||
                                      item.collectionVisit.status ===
                                      "Payment Failed" ||
                                      item.collectionVisit.status ===
                                      "Customer Not Available" ||
                                      item.collectionVisit.status ===
                                      "Legal Case" ||
                                      item.collectionVisit.status ===
                                      "Not Contactable" ? (
                                      <span className="badge badge-circle badge-danger">
                                        {item.collectionVisit.status}
                                      </span>
                                    ) : (
                                      <span
                                        className="badge badge-circle badge-primary"
                                        style={{ width: "180px" }}
                                      >
                                        {item.collectionVisit.status}
                                      </span>
                                    )}{" "}
                                  </span>
                                  <h6 className="mb-0">
                                    {`${item.userModel.name} collected `}
                                    <strong className="text-primary">{`₹ ${item.collectionVisit.amountPaid === ""
                                        ? 0
                                        : item.collectionVisit.amountPaid
                                      }`}</strong>
                                    {` from ${item.collectionVisit.customerName} `}
                                  </h6>
                                </div>
                                {/* </Link> */}
                              </li>
                            );
                          })}
                        </ul>
                      </PerfectScrollbar>
                    </div>
                  </div>
                </div>
              )}

              {hasAccess(112) && (
                <div className="col-xl-4 col-xxl-6 col-lg-6">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">Latest Sales Timeline</h4>
                    </div>
                    <div className="card-body">
                      <PerfectScrollbar
                        style={{ height: "450px" }}
                        id="DZ_W_TimeLine"
                        className="widget-timeline dlab-scroll height370 ps ps--active-y"
                      >
                        <ul className="timeline">
                          {latestSales.map((item) => {
                            return (
                              <li>
                                <div className="timeline-badge primary"></div>
                                {/* <Link
                            className="timeline-panel text-muted"
                            to="/widget-basic"
                          > */}
                                <div className="timeline-panel text-muted">
                                  <span style={{ color: "black" }}>
                                    {convertEpochTimeToDate(item.dateTime)}
                                  </span>
                                  <h6 className="mb-0">
                                    {`${item.userModel.name} made a sales visit to`}
                                    {/* <strong className="text-primary">{`₹ ${item.salesVisit.id == ""
                              ? 0
                              : item.salesVisit.id
                              }`}</strong> */}
                                    {`  ${item.salesVisit.customerName}`}
                                  </h6>
                                </div>
                                {/* </Link> */}
                              </li>
                            );
                          })}
                        </ul>
                      </PerfectScrollbar>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
      </div>

      <Modal className="fade" show={basicModal} size="lg">
        <Modal.Header>
          <Modal.Title>
            {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            {title}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
              setIsBackButton(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <VisitsCount
            startDate={dates.start}
            endDate={dates.end}
            visitType={title}
            userDetails={loggedInUser}
            isDefault={isBackButton}
            handleManagerColumns={handleManagerColumns}
          />
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={basicModal2} size="lg">
        <Modal.Header>
          <Modal.Title>
            {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            {title}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal2(false);
              setIsBackButton(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <CollectionsCount
            startDate={dates.start}
            endDate={dates.end}
            visitType={title}
            userDetails={loggedInUser}
            isDefault={isBackButton}
            handleManagerColumns={handleManagerColumns}
          />
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={basicModal3} size="lg">
        <Modal.Header>
          <Modal.Title>
            {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            {ptpTitle}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal3(false);
              setIsBackButton(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <PtpCount
            startDate={ptpDates.start}
            endDate={ptpDates.end}
            visitType={title}
            userDetails={loggedInUser}
            isDefault={isBackButton}
            totalPtpCount={ptpMetrics.ptpCount}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal4} size="lg">
        <Modal.Header>
          <Modal.Title>
            {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            {ptpTitle}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal4(false);
              setIsBackButton(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <PtpCount
            startDate={ptpTomorrow.start}
            endDate={ptpTomorrow.end}
            visitType={title}
            userDetails={loggedInUser}
            isDefault={isBackButton}
            totalPtpCount={ptpTomorrowMetrics.ptpCount}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal6} size="lg">
        <Modal.Header>
          <Modal.Title>
            {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            {ptpTitle}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal6(false);
              setIsBackButton(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <PtpCount
            startDate={
              ptpTitle === "PTP Overdue"
                ? currentMonthStartTime
                : lastMonthStartTime
            }
            endDate={
              ptpTitle === "PTP Overdue"
                ? currentMonthEndTime
                : lastMonthEndTime
            }
            visitType={title}
            userDetails={loggedInUser}
            isDefault={isBackButton}
            totalPtpCount={
              ptpTitle === "PTP Overdue"
                && ptpOverDueMetrics.ptpCount
                // : lastMonthPtpOverDueMetrics.ptpCount
            }
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="fade"
        show={basicModal5}
        size={isBackButton === true ? "lg" : "xl"}
      >
        <Modal.Header>
          <Modal.Title>
            {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            {camelize(loginCountStatus) + " Details"}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal5(false);
              setIsBackButton(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <LoginList
            status={loginCountStatus}
            isDefault={isBackButton}
            handleManagerColumns={handleManagerColumns}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal7} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
                style={{ marginRight: "10px" }}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            Repeat Visit Counts
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal7(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <RepeatVisitCount
            end={endDate}
            start={startDate}
            isDefault={isBackButton}
            handleManagerColumns={handleManagerColumns}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal8} size={"xl"}>
        <Modal.Header>
          <Modal.Title>Visits</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal8(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <AllocationCountsVisitsList end={endDate} start={startDate} />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={basicModal9} size={"xl"}>
        <Modal.Header>
          <Modal.Title>
            {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
                style={{ marginRight: "10px" }}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            Allocations Summary
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal9(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <DashboardAllocationSummaryList
            end={endDate}
            start={startDate}
            isDefault={isBackButton}
            handleManagerColumns={handleManagerColumns}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={targetDueCountModal} size={isBackButton === false ? "lg" : "xl"}>
        <Modal.Header>
          <Modal.Title>
          {isBackButton && (
              <Button
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => handleClick(false)}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            )}
            {targetDueTitle}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setTargetDueCountModal(false);
              setIsBackButton(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <TargetDueCount
            targetDueType={targetDueTitle}
            userDetails={loggedInUser}
            totalTargetDueCount={targetDueTitle==="Target Past Due" ? targetDueMetrics.targePastDue:targetDueTitle==="Target Due Achieved" ? targetDueMetrics.targetDueAchieved : targetDueTitle==="Targets Attempted" ? targetDueMetrics.targetsAttempted : targetDueMetrics.targetDueToday}
            isDefault={isBackButton}
            handleAllocationsColumns={handleAllocationsColumns}    
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dashboard;
