/* Node API Integration */
import { handleResponse, handleError } from "./apiUtils";

/* ----LOCALHOST---- */ 
//const NODE_BASE_PATH="http://localhost:8080/";

/* ----QA OLD---- */
//const NODE_BASE_PATH = "https://ei9738t2h4.execute-api.ap-south-1.amazonaws.com/dev/";

/* ----QA NEW---- */
// const NODE_BASE_PATH = "https://nodeqa.clucloud.com/"

/* ----PRE PROD---- */
//const NODE_BASE_PATH = "https://ei9738t2h4.execute-api.ap-south-1.amazonaws.com/dev/";

/* ----PROD---- */
const NODE_BASE_PATH = "https://node.clucloud.com/";

const getRequestOptions = (method, body) => {
    let options = {
        method: method,
        headers: { "Content-Type": "application/json", ...authHeader() },
    };
    if (body) options = { ...options, body: JSON.stringify(body) };
    return options;
};

const authHeader = () => {
    let user = JSON.parse(localStorage.getItem("userDetails"));
    if (user !== null) {
        if (user && user.jwtToken) {
            return { Authorization: "Bearer " + user.jwtToken };
        } else {
            return {};
        }
    }
};

export function getData(name, queryParams) {
    let url = `${NODE_BASE_PATH}${name}${queryParams ? queryParams : ""}`;
    return fetch(url, getRequestOptions("GET"))
        .then(handleResponse)
        .catch(handleError);
}

export function postData(name, body) {
    return fetch(`${NODE_BASE_PATH}${name}`, getRequestOptions("POST", body))
        .then(handleResponse)
        .catch(handleError);
}

export function updateData(name, body, id) {
    return fetch(`${NODE_BASE_PATH}${name}/${id}`, getRequestOptions("PUT", body))
        .then(handleResponse)
        .catch(handleError);
}

export function deleteData(name, id) {
    return fetch(`${NODE_BASE_PATH}${name}/${id}`, getRequestOptions("PUT"))
        .then(handleResponse)
        .catch(handleError);
}

export function deleteDataFi(name, body) {
    return fetch(`${NODE_BASE_PATH}${name}`, getRequestOptions("DELETE", body))
        .then(handleResponse)
        .catch(handleError);
}

export function updateFiData(name, body) {
    return fetch(`${NODE_BASE_PATH}${name}`, getRequestOptions("PUT", body))
        .then(handleResponse)
        .catch(handleError);
}