import React, { useEffect, useState } from "react";
import SearchBar from "../CommonComponents/SearchBar";
import { Button, Nav, Tab, Modal, Row, Col, Spinner } from "react-bootstrap";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
// import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import ReactGA from "react-ga";
import Select from "react-select";
import swal from "sweetalert";
import { getTokenInLocalStorage } from "../services/AuthService";
import SalesUpload from "./SalesUpload";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { EpochToDate } from "../CommonComponents/Helper";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import UploadedFilesList from "../UploadSchedule/UploadedFilesList";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import SalesSummary from "./SalesSummary";

const SalesAllocations = () => {
  const [salesAllocationsList, setSalesAllocationsList] = useState([]);
  const [sourceChannelList, setSourceChannelList] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [totalElements2, setTotalElements2] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [currPage2, setCurrPage2] = useState(0);
  const [basicModal, setBasicModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [sourcingChannelModal, setSourcingChannelModal] = useState(false);
  const [templateDownloadModal, setTemplateDownloadModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [sourcingChannelName, setSourcingChannelName] = useState(null);
  const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );
  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paymentsDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDownloadList]);

  useEffect(() => {
    loadSalesData(0, searchKey, start, end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSalesData = (pageNo, searchKey, start, end) => {
    var listData = [];
    var payload = {
      startTime: start,
      endTime: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      searchKey: searchKey,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getSalesAllocations, pageNo, 10),
        payload
      )
      .then((response) => {
        setCurrPage(pageNo);
        // console.log("response", response);
        response?.content?.forEach((element) => {
          let data = {
            callDisposition: element.callDisposition
              ? element.callDisposition
              : "-",
            allocationStatus: element.allocationStatus
              ? element.allocationStatus
              : "-",
            callDispositionSubstatus: element.callDispositionSubstatus
              ? element.callDispositionSubstatus
              : "-",
            callStatus: element.callStatus ? element.callStatus : "-",
            city: element.city ? element.city : "-",
            priority: element.priority ? element.priority : "-",
            empId: element.empId ? element.empId : "-",
            empName: element.empName ? element.empName : "-",
            lastCallUpdateTime: element.lastCallUpdateTime
              ? element.lastCallUpdateTime
              : "-",
            callsToday: element.callsToday ? element.callsToday : 0,
            customerName: element.customerName ? element.customerName : "-",
            dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
            excelRowNumber: element.excelRowNumber
              ? element.excelRowNumber
              : "-",
            id: element.id ? element.id : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
            pinCode: element.pinCode ? element.pinCode : "-",
            sourceChannelId: element.sourceChannelId
              ? element.sourceChannelId
              : "-",
            sourcingChannel: element.sourcingChannel
              ? element.sourcingChannel
              : "-",
            state: element.state ? element.state : "-",
            telecallerEmpId: element.telecallerEmpId
              ? element.telecallerEmpId
              : "-",
            telecallerName: element.telecallerName
              ? element.telecallerName
              : "-",
            totalCalls: element.totalCalls ? element.totalCalls : 0,
            userId: element.userId ? element.userId : "-",
            validationFailed: element.validationFailed
              ? element.validationFailed
              : "-",
          };
          listData.push(data);
        });
        setSalesAllocationsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    loadSalesData(selectedPage - 1, searchKey, start, end);
  };
  const onPageChange2 = (selectedPage) => {
    getSourceChannels(selectedPage - 1);
  };

  const handleLoadData = () => {
    loadSalesData(0, searchKey, start, end);
  };

  const salesAllocationColumns = [
    {
      text: "Sourcing Channel",
      dataField: "sourcingChannel",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Priority",
      dataField: "priority",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Prospect Name",
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Prospect</span>
          <br></br>
          <span>Number</span>
        </div>
      ),
      dataField: "phoneNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Telecaller</span>
          <br></br>
          <span>Emp Id</span>
        </div>
      ),
      dataField: "telecallerEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Telecaller</span>
          <br></br>
          <span>Emp Name</span>
        </div>
      ),
      dataField: "telecallerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "City",
      dataField: "city",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "State",
      dataField: "state",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Pin Code",
      dataField: "pinCode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Call Status",
      dataField: "callStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Call Disposition</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "callDisposition",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Call Disposition</span>
          <br></br>
          <span>Sub Status</span>
        </div>
      ),
      dataField: "callDispositionSubstatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Allocation</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "allocationStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Assigned</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    // {
    //   text: "",
    //   dataField: "id",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   //   formatter: (cell, row) => {
    //   //     if (row.id === "ACTIVE4") {
    //   //       return <></>;
    //   //     } else {
    //   //       return (
    //   //         <div className="d-flex">
    //   //           <Link to="#" className="btn btn-success shadow btn-xs sharp">
    //   //             <i className="fa fa-eye"></i>
    //   //           </Link>
    //   //         </div>
    //   //       );
    //   //     }
    //   //   },

    //   //   events: {
    //   //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //   //       setVisitedPlacesDetailsList(row);
    //   //       // setBasicModal(true);
    //   //       setToggle(true);
    //   //     },
    //   //   },
    // },
  ];

  const getSourceChannels = (pageNo) => {
    var listData = [];
    var payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getSourceChannels, pageNo, 10),
        payload
      )
      .then((response) => {
        setCurrPage2(pageNo);
        // console.log("response", response);
        response?.content?.forEach((element) => {
          let data = {
            sourceChannel: element.sourceChannel ? element.sourceChannel : "-",
            createdTime: element.createdTime
              ? EpochToDate(element.createdTime)
              : "-",
            empName: element.empName ? element.empName : "-",
            empId: element.empId ? element.empId : "-",
            userId: element.userId ? element.userId : "-",
            id: element.id ? element.id : "-",
          };
          listData.push(data);
        });

        setSourceChannelList(listData);
        setTotalElements2(response?.totalElements);
      });
  };

  const sourcingChannelsColumns = [
    {
      text: "Sourcing Channel",
      dataField: "sourceChannel",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Created By</span>
          <br></br>
          <span>Emp Id</span>
        </div>
      ),
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Created By</span>
          <br></br>
          <span>Emp Name</span>
        </div>
      ),
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created At",
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  const paymentsDownloadData = () => {
    setIsLoading(true);

    var payload = {
      startTime: start,
      endTime: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      searchKey: searchKey,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getSalesAllocations, 0, 10), payload)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getPaymentsFullResponseData(
                  Math.floor(response.totalElements / 200)
                );
              }
            });
          }

          getPaymentsFullResponseData(Math.floor(response.totalElements / 200));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
    // }
  };

  async function getPaymentsFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];

    for (let i = 0; i <= size; i++) {
      var payload = {
        startTime: start,
        endTime: end,
        organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
        searchKey: searchKey,
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getSalesAllocations, i, 200),
          payload
        )
        .then((response) => {
          tempList.push(...response?.content);
        });
    }

    // console.log("templist", tempList);
    tempList?.forEach((element) => {
      let data = {
        callDisposition: element.callDisposition
          ? element.callDisposition
          : "-",
        allocationStatus: element.allocationStatus
          ? element.allocationStatus
          : "-",
        callDispositionSubstatus: element.callDispositionSubstatus
          ? element.callDispositionSubstatus
          : "-",
        callStatus: element.callStatus ? element.callStatus : "-",
        priority: element.priority ? element.priority : "-",
        city: element.city ? element.city : "-",
        empId: element.empId ? element.empId : "-",
        empName: element.empName ? element.empName : "-",
        lastCallUpdateTime: element.lastCallUpdateTime
          ? EpochToDate(element.lastCallUpdateTime)
          : "-",
        callsToday: element.callsToday ? element.callsToday : 0,
        customerName: element.customerName ? element.customerName : "-",
        dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
        excelRowNumber: element.excelRowNumber ? element.excelRowNumber : "-",
        id: element.id ? element.id : "-",
        organizationId: element.organizationId ? element.organizationId : "-",
        phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
        pinCode: element.pinCode ? element.pinCode : "-",
        sourceChannelId: element.sourceChannelId
          ? element.sourceChannelId
          : "-",
        sourcingChannel: element.sourcingChannel
          ? element.sourcingChannel
          : "-",
        state: element.state ? element.state : "-",
        telecallerEmpId: element.telecallerEmpId
          ? element.telecallerEmpId
          : "-",
        telecallerName: element.telecallerName ? element.telecallerName : "-",
        totalCalls: element.totalCalls ? element.totalCalls : 0,
        userId: element.userId ? element.userId : "-",
        validationFailed: element.validationFailed
          ? element.validationFailed
          : "-",
      };
      mgrListData.push(data);
    });
    setPaymentsDownloadList(mgrListData);
    // }
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Sourcing Channel",
        B: "Priority",
        C: "Prospect Name",
        D: "Prospect Number",
        E: "Telecaller Emp Id",
        F: "Telecaller Emp Name",
        G: "City",
        H: "State",
        I: "Pin Code",
        J: "Call Status",
        K: "Call Disposition Status",
        L: "Call Disposition Sub Status",
        M: "Allocation Status",
        N: "Assigned Date & Time",
      },
    ];
    paymentsDownloadList?.forEach((row) => {
      mgrTable.push({
        A: row.sourcingChannel,
        B: row.priority,
        C: row.customerName,
        D: row.phoneNumber,
        E: row.telecallerEmpId,
        F: row.telecallerName,
        G: row.city,
        H: row.state,
        I: row.pinCode,
        J: row.callStatus,
        K: row.callDisposition,
        L: row.callDispositionSubstatus,
        M: row.allocationStatus,
        N: row.dateTime,
      });
    });
    const finalData = [...mgrTable];
    // console.log("finaldata", finalData);
    //create a new workbook
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    // console.log("sheet", sheet);
    XLSX.utils.book_append_sheet(wb, sheet, "Sales_Allocations");
    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Sales_Allocations_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Sales List Download",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Sales_Allocations_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadSalesData(0, key, start, end);
  };

  const setCustomDatesForGraph = (value1) => {
    var start = value1[0].getTime() / 1000;
    var end =
      new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStart(start);
    setEnd(end);
    loadSalesData(0, searchKey, start, end);
  };

  const fileTypesList = [
    { label: "Sales Allocations", value: "UPDATE_SALES_ALLOCATIONS" },
    {
      label: "Sales Auto Allocations",
      value: "UPDATE_SALES_ALLOCATIONS_WITH_AUTO_ALLOCATION",
    },
    {
      label: "Sales Interested Auto Allocations",
      value: "UPDATE_SALES_INTRESTED_ALLOCATIONS_WITH_AUTO_ALLOCATION",
    },
    {
      label: "Sales Telecaller Allocations",
      value: "UPDATE_TELE_CALLER_SALES_ALLOCATIONS",
    },
    {
      label: "Sales Allocation Priorities",
      value: "UPDATE_PROIRITIES_SALES_ALLOCATIONS",
    },
  ];

  const downloadAllocationsTemplate = () => {
    let salesAllocationsTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Sales_Allocation_Template.xlsx";
    let salesAutoAllocationsTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Sales_Auto_Allocation_Template.xlsx";
    let salesInterestedAutoAllocationsTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Sales_Interested_Auto_Allocations_Template.xlsx";
    let salesTelecallerAllocationsTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Sales_Telecaller_Allocations_Template_file.xlsx";
    let salesTelecallerAllocationsPrioritiesTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Sales_Proirity_Template.xlsx";

    const fileUrl =
      selectedTemplate === "UPDATE_SALES_ALLOCATIONS"
        ? salesAllocationsTemplateUrl
        : selectedTemplate === "UPDATE_SALES_ALLOCATIONS_WITH_AUTO_ALLOCATION"
        ? salesAutoAllocationsTemplateUrl
        : selectedTemplate ===
          "UPDATE_SALES_INTRESTED_ALLOCATIONS_WITH_AUTO_ALLOCATION"
        ? salesInterestedAutoAllocationsTemplateUrl
        : selectedTemplate === "UPDATE_TELE_CALLER_SALES_ALLOCATIONS"
        ? salesTelecallerAllocationsTemplateUrl
        : salesTelecallerAllocationsPrioritiesTemplateUrl;

    // Create an anchor element
    const anchor = document.createElement("a");

    // Set the href and download attributes
    anchor.href = fileUrl;
    anchor.setAttribute(
      "download",
      (selectedTemplate === "UPDATE_SALES_ALLOCATIONS"
        ? "Sales_Allocation"
        : "Sales_Telecaller_Allocation") + "_Template.xlsx"
    ); // Set the desired file name

    // Programmatically trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor element from the DOM (optional)
    anchor.remove();
    setSelectedTemplate(null);
    setTemplateDownloadModal(false);
  };

  const handleClose = () => {
    setBasicModal(false);
  };

  const addSourceChannel = () => {
    var payload = {
      empId: userLoginDetails?.autoFinanceUser?.employeeId,
      empName: userLoginDetails?.autoFinanceUser?.name,
      userId: userLoginDetails?.autoFinanceUser?.userId,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      sourceChannel: sourcingChannelName,
    };
    moduleApi.postData(apiCall.addSourceChannel, payload).then((response) => {
      // console.log("response", response);
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.Message === "Source Channel Added") {
          setSourcingChannelModal(false);
          return swal("Sourcing Channel Added Successfully", {
            icon: "success",
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  return (
    <div className="card" style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginBottom: "15px",
        }}
      >
        <div
          style={{
            marginRight: "10px",
            float: "right",
          }}
        >
          {/* {userLoginDetails.autoFinanceUser.roleName !== "ADMIN" && ( */}
          <Button
            id="addSourcingChannel"
            className=""
            variant="primary btn-rounded"
            style={{
              borderRadius: "5px",
              float: "right",
            }}
            onClick={() => {
              setSourcingChannelModal(true);
            }}
          >
            <i class="fa fa-plus"></i>&nbsp; Add Sourcing Channel
          </Button>
          {/* )} */}
        </div>
        <div
          style={{
            marginRight: "10px",
            float: "right",
          }}
        >
          {/* {userLoginDetails.autoFinanceUser.roleName !== "ADMIN" && ( */}
          <Button
            id="salesAllocationsTemplate"
            className=""
            variant="success btn-rounded"
            style={{
              borderRadius: "5px",
              float: "right",
            }}
            onClick={() => {
              setTemplateDownloadModal(true);
            }}
          >
            <i class="fa fa-download"></i>&nbsp; Download Template
          </Button>
          {/* )} */}
        </div>

        <div
          style={{
            marginRight: "10px",
            float: "right",
          }}
        >
          {userLoginDetails.autoFinanceUser.roleName !== "MANAGER_RA" && (
            <Button
              id="uploadSalesAllocations"
              className=""
              variant="success btn-rounded"
              style={{
                backgroundColor: "#218a3f",
                borderColor: "#218a3f",
                borderRadius: "5px",
                float: "right",
              }}
              onClick={() => {
                setBasicModal(true);
              }}
            >
              <i class="fa fa-upload"></i>&nbsp; Upload Allocations
            </Button>
          )}
        </div>
      </div>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="allocations">
          <Nav as="ul" className="nav-tabs-custom nav-justified">
            <Nav.Item as="li" key="0">
              <Nav.Link
                eventKey="allocations"
                onClick={() => {
                  loadSalesData(
                    0,
                    "",
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1
                    ).getTime() / 1000,
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    ).getTime() /
                      1000 +
                      86399
                  );
                  setSearchKey("");
                  setStart(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1
                    ).getTime() / 1000
                  );
                  setEnd(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    ).getTime() /
                      1000 +
                      86399
                  );
                }}
              >
                <b> Allocations </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="1">
              <Nav.Link eventKey="summary">
                <b> Summary </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="2">
              <Nav.Link eventKey="uploadedFiles">
                <b> Uploaded Files </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="3">
              <Nav.Link
                eventKey="sourceChannels"
                onClick={() => {
                  getSourceChannels();
                }}
              >
                <b> Source Channels </b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="allocations" key="0" mountOnEnter unmountOnExit>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    //   marginTop: "20px",
                    marginBottom: "15px",
                    marginRight: "15px",
                  }}
                >
                  <SearchBar
                    placeholder="Source Channel/Name/Number/Emp Id/Emp Name"
                    searchKeyword={searchKeyword}
                  />
                  <div
                    style={{
                      float: "right",
                      // margin: "10px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      // pointerEvents:
                      //   isProgressiveDialing === true && "none",
                    }}
                  >
                    <Flatpickr
                      className="form-control d-block"
                      style={{
                        width: "170px",
                        height: "40px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #3a353b",
                        textAlignLast: "center",
                      }}
                      placeholder="Select Month"
                      onChange={(value) => {
                        setCustomDatesForGraph(value);
                      }}
                      options={{
                        defaultDate: new Date(),
                        maxDate: new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() + 2,
                          0
                        ),
                        minDate: new Date().setMonth(new Date().getMonth() - 6),

                        plugins: [
                          new monthSelectPlugin({
                            shorthand: true, //defaults to false
                            dateFormat: "F Y", //defaults to "F Y"
                            altFormat: "F Y", //defaults to "F Y"
                            theme: "light", // defaults to "light"
                          }),
                        ],
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      style={{
                        float: "right",
                        //   margin: "10px",
                        marginRight: "10px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="success btn-rounded"
                      onClick={() => paymentsDownloadData()}
                    >
                      <i className="fa fa-download"></i>
                      &nbsp;&nbsp;Download
                    </Button>
                  </div>

                  {/* <Button
                    style={{
                      marginRight: "10px",
                      marginLeft: "7px",
                      marginBottom: "5px",
                      fontFamily:
                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                    }}
                    className="me-2"
                    variant="success btn-rounded"
                    // onClick={() => customerListDownloadData(visitType, searchKey,selectedApprovalStatus,selectedFIStatus)}
                  >
                    <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                  </Button> */}
                </div>
                <LoadingOverlay
                  active={isLoading}
                  // spinner
                  text={
                    <p
                      style={{
                        color: "black",
                      }}
                    >
                      <div>
                        <Button
                          style={{
                            backgroundColor: "#FFFF",
                            color: "black",
                            borderColor: "white",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                          variant="primary"
                          disabled
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            variant="primary"
                            role="status"
                            aria-hidden="true"
                          />
                          &nbsp;&nbsp;Loading...
                        </Button>
                      </div>
                    </p>
                  }
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(192,192,192,0.4)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "80px",
                      "& svg circle": {
                        stroke: "rgb(136, 108, 192)",
                      },
                    }),
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      margin: "10px",
                      marginTop: "-5px",
                      overflowX: "auto",
                    }}
                  >
                    <div
                      style={{
                        overflow: "auto",
                        maxHeight: "500px",
                      }}
                    >
                      <BootstrapTable
                        keyField="panNo"
                        data={salesAllocationsList}
                        columns={salesAllocationColumns}
                      />
                    </div>

                    {salesAllocationsList.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <img src={NO_DATA_FOUND} alt="" />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin:
                          totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                      }}
                    >
                      <PaginationComponent
                        totalItems={totalElements}
                        pageSize={10}
                        defaultActivePage={currPage + 1}
                        onSelect={onPageChange}
                      />
                    </div>
                  </div>
                </LoadingOverlay>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="summary" key="1" mountOnEnter unmountOnExit>
              <SalesSummary />
            </Tab.Pane>
            <Tab.Pane
              eventKey="uploadedFiles"
              key="2"
              mountOnEnter
              unmountOnExit
            >
              <UploadedFilesList allocationType={"Sales"} />
            </Tab.Pane>
            <Tab.Pane
              eventKey="sourceChannels"
              key="3"
              mountOnEnter
              unmountOnExit
            >
              <div
                style={{
                  background: "white",
                  margin: "10px",
                  marginTop: "-5px",
                  overflowX: "auto",
                }}
              >
                <div
                  style={{
                    overflow: "auto",
                    maxHeight: "500px",
                  }}
                >
                  <BootstrapTable
                    keyField="id"
                    data={sourceChannelList}
                    columns={sourcingChannelsColumns}
                  />
                </div>
                {sourceChannelList.length === 0 && (
                  <div className="d-flex justify-content-center">
                    <img src={NO_DATA_FOUND} alt="" />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "0px",
                  }}
                >
                  <PaginationComponent
                    totalItems={totalElements2}
                    pageSize={10}
                    defaultActivePage={currPage2 + 1}
                    onSelect={onPageChange2}
                  />
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <Modal className="fade" size="md" show={templateDownloadModal}>
        <Modal.Header>
          <Modal.Title>Download Template</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setTemplateDownloadModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <Col>
                <div
                  style={{
                    marginBottom: "5px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>
                    <h6>Template Type</h6>
                  </label>
                  <div
                    style={{
                      float: "right",
                      width: "250px",
                      height: "38px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      isSearchable={false}
                      onChange={(value) => {
                        setSelectedTemplate(value.value);
                      }}
                      placeholder="Select Template Type"
                      // value={executivesList[executiveFilterIndex]}
                      options={fileTypesList}
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <button
                  type="submit"
                  className="btn btn-success"
                  style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                  onClick={() => {
                    if (selectedTemplate !== null) {
                      downloadAllocationsTemplate();
                    } else {
                      return swal("Please Select Template Type", {
                        icon: "error",
                        dangerMode: true,
                      });
                    }
                  }}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="md" show={sourcingChannelModal}>
        <Modal.Header>
          <Modal.Title>Add Sourcing Channel</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setSourcingChannelModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>Sourcing Channel</h6>
                </label>
                <input
                  id="sourcingChannelName"
                  className="form-control"
                  type="text"
                  placeholder="Enter Sourcing Channel"
                  name="sourcingChanelName"
                  onChange={(e) => {
                    setSourcingChannelName(e.target.value);
                  }}
                  style={{ padding: "11px" }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => addSourceChannel()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => setSourcingChannelModal(false)}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="xl" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Upload Allocations</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => handleClose()}
          ></Button>
        </Modal.Header>
        <Modal.Body
        // style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
        >
          <SalesUpload close={handleClose} loadData={handleLoadData} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SalesAllocations;
