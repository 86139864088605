import { Form, Formik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import TextField from "../CommonComponents/TextField";
// import { toaster } from "../rest/apiUtils";
import * as moduleApi from "../rest/moduleApi";
import swal from "sweetalert";
import { apiCall } from "../rest/restApi";
import ReactGA from "react-ga";
import { getTokenInLocalStorage } from "../services/AuthService";
import { EpochToDate, formatDate } from "../CommonComponents/Helper";

const AddVehicleServiceData = ({ close }) => {
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  //   const phoneRegExp = "[1-9]{1}[0-9]{9}";
  //   var emailPattern =
  //     /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const [vehicleServiceTypes, setVehicleServiceTypes] = useState(null);
  const [vehicleSubServiceTypes, setVehicleSubServiceTypes] = useState(null);
  const [vehicleServiceCallStatuses, setVehicleServiceCallStatuses] =
    useState(null);
  const [vehicleAppointmentStatuses, setVehicleAppointmentStatuses] =
    useState(null);
  const [vehicleServiceGatePasses, setVehicleServiceGatePasses] =
    useState(null);
  const [vehicleServiceStatuses, setVehicleServiceStatuses] = useState(null);
  const [vehicleNumbers, setVehicleNumbers] = useState(null);
  const [vehicleSaleData, setVehicleSaleData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    serviceType: "",
    subServiceType: "",
    callStatus: "",
    appointmentStatus: "",
    gatePass: "",
    serviceStatus: "",
  });
  const [singleVehicleSaleData, setSingleVehicleSaleData] = useState({
    id: 0,
    branch: "",
    chasisNo: "",
    cpName: "",
    customerName: "",
    customerPhoneNumber: "",
    dateOfSale: 0,
    engineNo: "",
    financier: "",
    slNo: "",
    vehicleNumber: "",
    vehicleDescription: "",
  });

  useEffect(() => {
    ReactGA.pageview("Add Vehicle Service Data");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    getVehicleServiceTypes();
    getVehicleServiceCallStatus();
    getVehicleServiceStatus();
    getVehicleAppointmentStatus();
    getVehicleServiceGatePass();
    getVehicleNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVehicleServiceTypes = () => {
    moduleApi
      .postData(
        apiCall.getVehicleServiceTypes +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.serviceType,
          label: item.serviceType,
        }));
        const result2 = response?.map((item) => ({
          value: item.subServiceType,
          label: item.subServiceType,
        }));

        setVehicleServiceTypes(result);
        setVehicleSubServiceTypes(result2);
      });
  };

  const getVehicleServiceCallStatus = () => {
    moduleApi
      .postData(
        apiCall.getVehicleServiceCallStatus +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.callStatus,
          label: item.callStatus,
        }));

        setVehicleServiceCallStatuses(result);
      });
  };

  const getVehicleAppointmentStatus = () => {
    moduleApi
      .postData(
        apiCall.getVehicleAppointmentStatus +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.appointmentStatus,
          label: item.appointmentStatus,
        }));

        setVehicleAppointmentStatuses(result);
      });
  };

  const getVehicleServiceStatus = () => {
    moduleApi
      .postData(
        apiCall.getVehicleServiceStatuses +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.serviceStatus,
          label: item.serviceStatus,
        }));

        setVehicleServiceStatuses(result);
      });
  };

  const getVehicleServiceGatePass = () => {
    moduleApi
      .postData(
        apiCall.getVehicleServiceCallGatePass +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item) => ({
          value: item.gatePass,
          label: item.gatePass,
        }));

        setVehicleServiceGatePasses(result);
      });
  };

  const getVehicleNumbers = () => {
    let body = {
      endDate: new Date().getTime() / 1000,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      startDate: 0,
      pagenation: false,
      //   searchKey: "string",
    };

    moduleApi.postData(apiCall.getVehicleSaleData, body).then((response) => {
      console.log("response", response);
      const result = response?.map((item) => ({
        value: item.id,
        label: item.vehicleNumber,
      }));

      setVehicleNumbers(result);
      setVehicleSaleData(response);
    });
  };

  const validate = Yup.object({
    // slNo: Yup.string().trim().required("SL No is required"),
    // branch: Yup.string().trim().required("Branch is required"),
    // customerName: Yup.string().trim().required("Customer Name is required"),
    // customerPhoneNumber: Yup.string()
    //   .trim()
    //   .matches(phoneRegExp, "Customer Mobile is not valid")
    //   .min(10, "Customer Mobile should be 10 digits")
    //   .max(10, "Customer Mobile should be 10 digits")
    //   .required("Customer Mobile is required"),
    // engineNo: Yup.string().trim().required("Engine No is required"),
    // chasisNo: Yup.string().trim().required("Chassis No is required"),
    // dateOfSale: Yup.date().required("Date Of Sale is required"),
    // vehicleNumber: Yup.string().required("Vehicle Number is required"),
    // serviceType: Yup.string().required("Service Type is required"),
    // subServiceType: Yup.string().required("Sub-Service Type is required"),
    // callStatus: Yup.string().required("Call Status is required"),
    appointmentDate: Yup.date().required("Appointment Date is required"),
    // appointmentStatus: Yup.string().required("Appointment Status is required"),
    // serviceStatus: Yup.string().required("Service Status is required"),
    // gatePass: Yup.string().required("Gate Pass is required"),
    toBeServiced: Yup.date().required("To Be Serviced is required"),
    remarks: Yup.string().trim().required("Remarks is required"),
  });

  const handleOk = (values) => {
    if (singleVehicleSaleData?.id === 0) {
      return swal("Please Select Vehicle Number", {
        icon: "error",
        dangerMode: true,
      });
    }
    const params = {
      appointmentDate: Math.floor(
        new Date(values.appointmentDate).getTime() / 1000.0
      ),
      appointmentStatus: values.appointmentStatus,
      // appointmnetSlot:   values.appointmnetSlot,
      callStatus: values.callStatus,
      // createdAt:         values.createdAt,
      gatePass: values.gatePass,
      // id:                values.id,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      remarks: values.remarks,
      serviceStatus: values.serviceStatus,
      serviceType: values.serviceType,
      subServiceType: values.subServiceType,
      toBeServiced: Math.floor(
        new Date(values.toBeServiced).getTime() / 1000.0
      ),
      // updatedAt:         values.updatedAt,
      userId: userLoginDetails?.autoFinanceUser?.userId,
      vehicleSaleData: {
        id: singleVehicleSaleData?.id,
      },
    };

    console.log("params", params);

    moduleApi
      .postData(apiCall.addVehicleServiceData, params)
      .then((response) => {
        console.log("saveCpUser", response);
        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            close();
            return swal("Added Successfully", {
              icon: "success",
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getSingleVehicleData = (id) => {
    vehicleSaleData.forEach((element) => {
      if (id === element.id) {
        singleVehicleSaleData.id = element.id;
        singleVehicleSaleData.slNo = element.slNo;
        singleVehicleSaleData.branch = element.branch;
        singleVehicleSaleData.customerName = element.customerName;
        singleVehicleSaleData.customerPhoneNumber = element.customerPhoneNumber;
        singleVehicleSaleData.engineNo = element.engineNo;
        singleVehicleSaleData.chasisNo = element.chasisNo;
        singleVehicleSaleData.dateOfSale = element.dateOfSale
          ? formatDate(EpochToDate(element.dateOfSale))
          : "";
        setSingleVehicleSaleData({ ...singleVehicleSaleData });
      }
    });
  };

  const updateSelectedOption = (name, value) => {
    selectedStatus[name] = value;
    setSelectedStatus({ ...selectedStatus });
  };

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "500px",
      }}
    >
      <Formik
        initialValues={{
          slNo: singleVehicleSaleData?.slNo,
          branch: singleVehicleSaleData?.branch,
          customerName: singleVehicleSaleData?.customerName,
          customerPhoneNumber: singleVehicleSaleData?.customerPhoneNumber,
          engineNo: singleVehicleSaleData?.engineNo,
          chasisNo: singleVehicleSaleData?.chasisNo,
          dateOfSale: singleVehicleSaleData?.dateOfSale,
          vehicleNumber: singleVehicleSaleData?.vehicleNumber,
          serviceType: selectedStatus.serviceType,
          subServiceType: selectedStatus.subServiceType,
          callStatus: selectedStatus.callStatus,
          appointmentDate: "",
          appointmentStatus: selectedStatus.appointmentStatus,
          serviceStatus: selectedStatus.serviceStatus,
          gatePass: selectedStatus.gatePass,
          toBeServiced: "",
          remarks: "",
        }}
        onSubmit={handleOk}
        validationSchema={validate}
        enableReinitialize
      >
        {({ formik, errors }) => (
          <div>
            <Row>
              <Col lg="12">
                <Form>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                    }}
                  >
                    <label
                      className="control-label"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Vehicle Number
                    </label>
                    <Select
                      placeholder="Select Vehicle Number"
                      classNamePrefix="select-box"
                      options={vehicleNumbers}
                      onChange={(selectedOption) => {
                        getSingleVehicleData(selectedOption.value);
                        // form.setFieldValue(
                        //   "vehicleNumber",
                        //   selectedOption.value
                        // );
                      }}
                    />
                  </div>

                  {/* {errors.vehicleNumber && (
                    <span className="error">{errors.vehicleNumber}</span>
                  )} */}
                  <div>
                    <TextField
                      isAsterisk={false}
                      label="SL No"
                      name="slNo"
                      type="text"
                      disabled
                    />
                    <TextField
                      isAsterisk={false}
                      label="Branch"
                      name="branch"
                      type="text"
                      disabled
                    />
                    <TextField
                      isAsterisk={false}
                      label="Customer Name"
                      name="customerName"
                      type="text"
                      disabled
                    />
                    <TextField
                      isAsterisk={false}
                      label="Customer Mobile"
                      name="customerPhoneNumber"
                      type="text"
                      disabled
                    />
                    <TextField
                      isAsterisk={false}
                      label="Engine No"
                      name="engineNo"
                      type="text"
                      disabled
                    />
                    <TextField
                      isAsterisk={false}
                      label="Chassis No"
                      name="chasisNo"
                      type="text"
                      disabled
                    />
                    <TextField
                      isAsterisk={false}
                      label="Date Of Sale"
                      name="dateOfSale"
                      type="date"
                      disabled
                    />

                    <Field name="serviceType">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            // className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Service Type
                          </label>
                          <Select
                            placeholder="Select Service Type"
                            classNamePrefix="select-box"
                            options={vehicleServiceTypes}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "serviceType",
                                selectedOption.value
                              );
                              updateSelectedOption(
                                "serviceType",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>

                    {errors.serviceType && (
                      <span className="error">{errors.serviceType}</span>
                    )}
                    <Field name="subServiceType">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            // className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Sub-Service Type
                          </label>
                          <Select
                            placeholder="Select Sub-Service Type"
                            classNamePrefix="select-box"
                            options={vehicleSubServiceTypes}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "subServiceType",
                                selectedOption.value
                              );
                              updateSelectedOption(
                                "subServiceType",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>

                    {errors.subServiceType && (
                      <span className="error">{errors.subServiceType}</span>
                    )}
                    <Field name="callStatus">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <label
                            // className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Call Status
                          </label>
                          <Select
                            placeholder="Select Call Status"
                            classNamePrefix="select-box"
                            options={vehicleServiceCallStatuses}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "callStatus",
                                selectedOption.value
                              );
                              updateSelectedOption(
                                "callStatus",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>

                    {errors.callStatus && (
                      <span className="error">{errors.callStatus}</span>
                    )}

                    <TextField
                      isAsterisk={true}
                      label="Appointment Date"
                      name="appointmentDate"
                      type="date"
                    />
                    <Field name="appointmentStatus">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            // className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Appointment Status
                          </label>
                          <Select
                            placeholder="Select Appointment Status"
                            classNamePrefix="select-box"
                            options={vehicleAppointmentStatuses}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "appointmentStatus",
                                selectedOption.value
                              );
                              updateSelectedOption(
                                "appointmentStatus",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>

                    {errors.appointmentStatus && (
                      <span className="error">{errors.appointmentStatus}</span>
                    )}
                    <Field name="serviceStatus">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            // className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Service Status
                          </label>
                          <Select
                            placeholder="Select Service Status"
                            classNamePrefix="select-box"
                            options={vehicleServiceStatuses}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "serviceStatus",
                                selectedOption.value
                              );
                              updateSelectedOption(
                                "serviceStatus",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>

                    {errors.serviceStatus && (
                      <span className="error">{errors.serviceStatus}</span>
                    )}
                    <Field name="gatePass">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <label
                            // className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              // marginBottom: "10px",
                            }}
                          >
                            Gate Pass
                          </label>
                          <Select
                            placeholder="Select Gate Pass"
                            classNamePrefix="select-box"
                            options={vehicleServiceGatePasses}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "gatePass",
                                selectedOption.value
                              );
                              updateSelectedOption(
                                "gatePass",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>

                    {errors.gatePass && (
                      <span className="error">{errors.gatePass}</span>
                    )}
                    <TextField
                      isAsterisk={true}
                      label="To Be Serviced"
                      name="toBeServiced"
                      type="date"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Remarks"
                      name="remarks"
                      type="text"
                    />
                    <div style={{ marginTop: "10px" }}>
                      <Button
                        style={{ float: "right" }}
                        className="col-lg-2 m-2"
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ float: "right" }}
                        className="col-lg-2 m-2 float-right"
                        variant="danger light"
                        onClick={() => {
                          close();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddVehicleServiceData;
