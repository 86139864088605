import * as d3 from "d3";

export function camelize(str) {
    const a = str
        .toLowerCase()
        .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? " " + c.toUpperCase() : ""));
    return a.substring(0, 1).toUpperCase() + a.substring(1);
}

export function structureLableNames(label) {
    switch (label) {
        case 'Branch Name':
            return 'Branch Name';
        case 'Visit Date':
            return 'Visit Date';
        case 'Visit Mo':
            return 'Visit Mo';
        case 'Visit Year':
            return 'Visit Year';
        case 'Total Visits':
            return 'Total Visits';
        case 'Thirdparty Count':
            return 'Thirdparty Count';
        case 'Rtp Count':
            return 'RTP Count';
        case 'Repossessed Count':
            return 'Repossessed Count';
        case 'Vacant Count':
              return 'Vacant Count';
        case 'Unique Customer Ptp Count':
            return 'Unique Customer PTP Count';
        case 'Ptp Count':
            return 'PTP Count'
        case 'Paid Count':
            return 'Paid Count'
        default:
            return ''
    }
}

export function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

export function getGroupedData(csvData, param1, param2, isDataFromServer) {
    if (param1.length === 1) {
        var gfg1 = d3.flatRollup(
          csvData,
          (v) =>
            d3.sum(v, (d) => d[isDataFromServer ? camelize(param1[0]) : param1[0].name]),
          (d) => d[isDataFromServer ? camelize(param2) : param2.name]
        );
        return gfg1;
      } else if (param1.length > 1) {
        var gfg2 = [];
        var refinedGroupedData = [];
        for (let i = 0; i < param1.length; i++) {
          gfg2.push(
            d3.flatRollup(
              csvData,
              (v) =>
                d3.sum(
                  v,
                  (d) => d[isDataFromServer ? camelize(param1[i]) : param1[i].name]
                ),
              (d) => d[isDataFromServer ? camelize(param2) : param2.name]
            )
          );
        }
        gfg2[0].forEach((data) => {
          refinedGroupedData.push([data[0]]);
        });
        gfg2.forEach((data) => {
          for (let i = 0; i < data.length; i++) {
            refinedGroupedData[i].push(data[i][1]);
          }
        });
        // console.log("refinded", refinedGroupedData);
        return refinedGroupedData;
      }
}