import React, { useEffect, useState } from "react";
import { Button, Row, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import Select from "react-select";
import { Link } from "react-router-dom";
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import SearchBar from "../CommonComponents/SearchBar";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

const NonVisitedPayments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState("");
  const [selectedPaymentChannel, setSelectedSelectedPaymentChannel] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaymentsList(
      currPage,
      searchKey,
      startDate,
      endDate,
      selectedStatus,
      selectedApprovalStatus,
      selectedPaymentChannel
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paymentsDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDownloadList]);

  const getPaymentsList = (pageNo, key, start, end, status, approvalStatus,paymentChannel) => {
    let listData = [];
    setIsLoading(true);
    var payload = {
      endTime: end,
      startTime: start,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchkey: key,
      paymentMode: status,
      approvalStatus: approvalStatus,
      paymentChannel:paymentChannel,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getNonVisitedPayments, pageNo, 10),
        payload
      )
      .then((response) => {
        setPaymentsList([]);
        console.log("PaymentResponse", response);
        response?.content?.forEach((element) => {
          let data = {
            id: element?.id,
            collectedById: element.collectedById ? element.collectedById : "-",
            collectedByName: element.collectedByName
              ? element.collectedByName
              : "-",
            customerName: element.customerName ? element.customerName : "-",
            contactNumber: element.contactNumber ? element.contactNumber : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            paymentStatus: element.paymentStatus ? element.paymentStatus : "-",
            totalAmount: element.totalAmount ? element.totalAmount : 0,
            paymentDate: element?.paymentChannel === "Tele Callers" && element?.paymentMode === "Cash"
              ? helper.EpochToOnlyDate(element.createdTime)
              : element?.paymentChannel === "Tele Callers"
                ? helper.EpochToOnlyDate(element.paymentDate) : element?.paymentDate
                  ? helper.EpochToDate(element.paymentDate)
                  : "-",
            emiAmount:
              status === "UPI Mandates"
                ? element.totalAmount
                : element.emiAmount
                ? element.emiAmount
                : 0,
            latePaymentCharges: element.latePaymentCharges
              ? element.latePaymentCharges
              : 0,
            bounceCharges: element.bounceCharges ? element.bounceCharges : 0,
            penaltyCharges: element.penaltyCharges ? element.penaltyCharges : 0,
            otherCharges: element.otherCharges ? element.otherCharges : 0,
            approvalStatus: element.approvalStatus
              ? element.approvalStatus
              : "-",
            approvedByEmpId: element.approvedByEmpId
              ? element.approvedByEmpId
              : "-",
            approvedByEmpName: element.approvedByEmpName
              ? element.approvedByEmpName
              : "-",
            approvedTime: element.approvedTime
              ? helper.EpochToDate(element.approvedTime)
              : "-",
            paymentRejectionReason: element.paymentRejectionReason
              ? element.paymentRejectionReason
              : "-",
            receiptNumber: element?.receiptNumber
              ? element?.receiptNumber
              : null,
            transactionId: element.transactionId ? element.transactionId : "-",
            payResponseCode: element.payResponseCode
              ? element.payResponseCode
              : "-",
            subscrptionId: element.subscrptionId ? element.subscrptionId : "-",
            utrNumber: element.utrNumber ? element.utrNumber : "-",
            paymentChannel: element.paymentChannel
              ? element.paymentChannel
              : "-",
            paymentMode: element?.paymentMode ? element.paymentMode: "-",
            };
          listData.push(data);
        });
        setCurrPage(pageNo);
        setPaymentsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const paymentColumns = [
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile No</span>
        </div>
      ),
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Loan</span>
          <br></br>
          <span>Number</span>
        </div>
      ),
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "paymentDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>EMI Amount</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "emiAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Late Payment</span>
          <br></br>
          <span>Charges Paid</span>
        </div>
      ),
      dataField: "latePaymentCharges",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Bounce Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "bounceCharges",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Penalty Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "penaltyCharges",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Other Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "otherCharges",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total Amount</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "totalAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (
          row.paymentStatus === "PAYMENT_SUCCESS" ||
          row.paymentStatus === "COMPLETED" ||
          row.payResponseCode === "SUCCESS" ||
          row.paymentStatus === "Success" ||
          row.paymentMode === "Cash" ||
          row.paymentMode === "UPI" ||
          row.paymentMode === "Bank Transfer"||
          row.paymentChannel==="Tele Callers"
        ) {
          return (<span style={{ color:'#0000FF'}}>{row.totalAmount}</span>);
        }else{
          return (<span style={{ color:'red'}}>{row.totalAmount}</span>);
        }
      }
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Mode</span>
        </div>
      ),
      dataField: "paymentMode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "paymentStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (
          row.paymentStatus === "PAYMENT_SUCCESS" ||
          row.paymentStatus === "COMPLETED" ||
          row.payResponseCode === "SUCCESS" ||
          row.paymentStatus === "Success" ||
          row.paymentMode === "Cash" ||
          row.paymentMode === "UPI" ||
          row.paymentMode === "Bank Transfer"||
          row.paymentChannel==="Tele Callers"
        ) {
          return (
            <span className="badge badge-rounded badge-success">Success</span>
          );
        } else if (row.paymentStatus === "PAYMENT_CANCELLED") {
          return (
            <span className="badge badge-rounded badge-danger">Cancelled</span>
          );
        } else if (
          row.paymentStatus === "PAYMENT_ERROR" ||
          row.paymentStatus === "FAILED"
        ) {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        }else if (
          row.paymentStatus === "PENDING") {
          return (
            <span className="badge badge-rounded badge-warning">Pending</span>
          );
        }else if (row.paymentStatus === "TRANSACTION_NOT_FOUND") {
          return (
            <span className="badge badge-rounded badge-danger">
              Transaction Not Found
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Channel</span>
        </div>
      ),
      dataField: "paymentChannel",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Approval</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "approvalStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.approvalStatus === "APPROVED") {
          return (
            <span className="badge badge-rounded badge-success">Approved</span>
          );
        } else if (row.approvalStatus === "REJECTED") {
          return (
            <span className="badge badge-rounded badge-danger">Rejected</span>
          );
        } else if (row.approvalStatus === "PENDING") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              Pending
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Approved</span>
          <br></br>
          <span>By</span>
        </div>
      ),
      dataField: "approvedByEmpName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Approved</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "approvedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Collected By</span>
          <br></br>
          <span>Id</span>
        </div>
      ),
      dataField: "collectedById",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Approve /</span>
          <br></br>
          <span>Reject</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setPaymentDetails(row);
          setBasicModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.receiptNumber === null) {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-download"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.receiptNumber !== null) {
            window.open(
              moduleApi.getBasePath() +
                apiCall.getPaymentReceiptForPayments +
                row.receiptNumber
            );
          }
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.receiptNumber === null) {
          return <></>;
        } else {
          return (
            <div>
              <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fas fa-external-link-alt"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.receiptNumber !== null) {
            swal({
              title: "Resend",
              text: "Do you really want to Resend Payment Receipt?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                resendPaymentReceipt(row?.receiptNumber);
              }
            });
          }
        },
      },
    },
  ];

  const resendPaymentReceipt = (receiptNumber) => {
    moduleApi
      .getData(apiCall.resendPaymentReceiptForPayments + receiptNumber)
      .then((response) => {
        if (response) {
          if (response.Message === "Receipt Sent Successfully") {
            return swal("Receipt Sent Successfully", {
              icon: "success",
              dangerMode: false,
            });
          } else if (response.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        }
      });
  };

  const statusOptions = [
    { label: "All", value: "" },
    { label: "UPI Payment Request", value: "UPI Payment Request" },
    { label: "Qr Code", value: "Qr Code" },
    { label: "UPI Auto Debit", value: "UPI Auto Debit" },
    { label: "Cash", value: "Cash" },
    { label: "Bank Transfer", value: "Bank Transfer" },
    { label: "UPI", value: "UPI" },
  ];

  const paymentChannelOptions = [
    { label: "All", value: "" },
    {label:"Resend Payment Request",value:"Resend Payment Request"},
    {label:"Customer Payment Request",value:"Customer Payment Request"},
    { label: "Tele Callers", value: "Tele Callers" },
    { label: "UPI Mandates", value: "UPI Mandates" },
    { label: "Bulk Payments Request", value: "Bulk Payments Request" },
    { label: "External Payments", value: "External Payments" },
  ];

  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  const approvalStatusOptions = [
    { label: "All", value: "" },
    { label: "Pending", value: "PENDING" },
    { label: "Approved", value: "APPROVED" },
    { label: "Rejected", value: "REJECTED" },
  ];

  const setStatusValue = (value) => {
    setSelectedStatus(value);
    getPaymentsList(
      0,
      searchKey,
      startDate,
      endDate,
      value,
      selectedApprovalStatus
    );
  };

  const setPaymentChannelValue = (value) => {
    setSelectedSelectedPaymentChannel(value);
    getPaymentsList(
      0,
      searchKey,
      startDate,
      endDate,
      selectedStatus,
      selectedApprovalStatus,
      value
    );
  };

  const setApprovalStatusValue = (value) => {
    getPaymentsList(0, searchKey, startDate, endDate, selectedStatus, value,selectedPaymentChannel);
    setSelectedApprovalStatus(value);
  };

  const paymentsDownloadData = (key) => {
    setIsLoading(true);
    var payload = {
      endTime: endDate,
      startTime: startDate,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchkey: searchKey,
      paymentMode: selectedStatus,
      approvalStatus: selectedApprovalStatus,
      paymentChannel:selectedPaymentChannel
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getNonVisitedPayments, 0, 10),
        payload
      )
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getPaymentsFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getPaymentsFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getPaymentsFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        endTime: endDate,
        startTime: startDate,
        reportingOfficerId: isAdmin()
          ? undefined
          : userLoginDetails.autoFinanceUser.userId,
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        searchkey: searchKey,
        paymentMode: selectedStatus,
        approvalStatus: selectedApprovalStatus,
        paymentChannel:selectedPaymentChannel
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getNonVisitedPayments, i, 100),
          payload
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        id: element?.id,
        collectedById: element.collectedById ? element.collectedById : "-",
        collectedByName: element.collectedByName
          ? element.collectedByName
          : "-",
        customerName: element.customerName ? element.customerName : "-",
        contactNumber: element.contactNumber ? element.contactNumber : "-",
        loanNumber: element.loanNumber ? element.loanNumber : "-",
        paymentStatus: getPaymentStatus(element),
        totalAmount: element.totalAmount ? element.totalAmount : 0,
        paymentDate: element?.paymentChannel === "Tele Callers" && element?.paymentMode === "Cash"
              ? helper.EpochToOnlyDate(element.createdTime)
              : element?.paymentChannel === "Tele Callers"
                ? helper.EpochToOnlyDate(element.paymentDate) : element?.paymentDate
                  ? helper.EpochToDate(element.paymentDate)
                  : "-",
        emiAmount:
          element?.paymentMode === "UPI Mandates"
            ? element.totalAmount
            : element.emiAmount
            ? element.emiAmount
            : 0,
        latePaymentCharges: element.latePaymentCharges
          ? element.latePaymentCharges
          : 0,
        bounceCharges: element.bounceCharges ? element.bounceCharges : 0,
        penaltyCharges: element.penaltyCharges ? element.penaltyCharges : 0,
        otherCharges: element.otherCharges ? element.otherCharges : 0,
        approvalStatus: element.approvalStatus ? element.approvalStatus : "-",
        approvedByEmpId: element.approvedByEmpId
          ? element.approvedByEmpId
          : "-",
        approvedByEmpName: element.approvedByEmpName
          ? element.approvedByEmpName
          : "-",
        approvedTime: element.approvedTime
          ? helper.EpochToDate(element.approvedTime)
          : "-",
        paymentRejectionReason: element.paymentRejectionReason
          ? element.paymentRejectionReason
          : "-",
        transactionId: element.transactionId ? element.transactionId : "-",
        payResponseCode: element.payResponseCode
          ? element.payResponseCode
          : "-",
        subscrptionId: element.subscrptionId ? element.subscrptionId : "-",
        utrNumber: element.utrNumber ? element.utrNumber : "-",
        paymentChannel: element.paymentChannel ? element.paymentChannel : "-",
        paymentMode: element?.paymentMode ? element.paymentMode: "-",
      };
      mgrListData.push(data);
    });
    setPaymentsDownloadList(mgrListData);
  }

  const getPaymentStatus=(row)=>{
    if (
      row.paymentStatus === "PAYMENT_SUCCESS" ||
      row.paymentStatus === "COMPLETED" ||
      row.payResponseCode === "SUCCESS" ||
      row.paymentMode === "Cash" ||
      row.paymentMode === "UPI" ||
      row.paymentMode === "Bank Transfer" ||
      row.paymentChannel === "Tele Callers"
            ) {
              return (
                "Success"
              );
            } else if (row.paymentStatus === "PAYMENT_CANCELLED") {
              return (
                "Cancelled"
              );
            } else if (
              row.paymentStatus === "PAYMENT_ERROR" ||
              row.paymentStatus === "FAILED"
            ) {
              return (
                "Failed"
              );
            }else if (
              row.paymentStatus === "PENDING") {
              return (
                "Pending"
              );
            } else if (row.paymentStatus === "TRANSACTION_NOT_FOUND") {
              return (
                
                  "Transaction Not Found"
              );
            }else if (row.paymentStatus) {
              return (
                 row.paymentStatus
              );
            }else {
              return "-";
            }
       }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Customer Name",
        B: "Customer Mobile No",
        C: "Loan No",
        D: "Payment Date",
        E: "EMI Amount Paid",
        F: "Late Payment Charges Paid",
        G: "Bounce Charges Paid",
        H: "Penalty Charges Paid",
        I: "Other Charges Paid",
        J: "Total Amount Paid",
        K:"Payment Mode",
        L: "Payment Status",
        M: "Payment Channel",
        N: "Approval Status",
        O: "Rejection Reason",
        P: "Approved By",
        Q: "Approved Date",
        R: "Collected By Id",
        S: "Collected By Name",
        T: "Transaction Id",
      },
    ];

    paymentsDownloadList?.forEach((row) => {
      mgrTable.push({
        A: row.customerName,
        B: row.contactNumber,
        C: row.loanNumber,
        D: row.paymentDate,
        E: row.emiAmount,
        F: row.latePaymentCharges,
        G: row.bounceCharges,
        H: row.penaltyCharges,
        I: row.otherCharges,
        J: row.totalAmount,
        K:row.paymentMode,
        L: row.paymentStatus,
        M: row.paymentChannel,
        N: row.approvalStatus,
        O: row.paymentRejectionReason,
        P: row.approvedByEmpName,
        Q: row.approvedTime,
        R: row.collectedById,
        S: row.collectedByName,
        T: row.transactionId,
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Non Visited Payments");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Non_Visited_Payments_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Non Visited Payments List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Non_Visited_Payments_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0].getFullYear(),
            value[0].getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86400);
    //   let start = ;
    // let end = Math.floor(new Date(dateStrings[1]).getTime() / 1000 + 86400);

    setStartDate(start);
    setEndDate(end);
    getPaymentsList(
      0,
      searchKey,
      start,
      end,
      selectedStatus,
      selectedApprovalStatus,
      selectedPaymentChannel
    );
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    getPaymentsList(
      0,
      key,
      startDate,
      endDate,
      selectedStatus,
      selectedApprovalStatus,
      selectedPaymentChannel
    );
  };

  const onPageChange = (selectedPage) => {
    console.log("pageNo", selectedPage - 1);
    setCurrPage(selectedPage - 1);
    getPaymentsList(
      selectedPage - 1,
      searchKey,
      startDate,
      endDate,
      selectedStatus,
      selectedApprovalStatus,
      selectedPaymentChannel
    );
  };

  const updateApprovalStatus = (paymentDetails, status) => {
    console.log("status", status);
    if (status === "REJECTED" && rejectionReason === null) {
      return swal("Please Enter Rejection Reason", {
        icon: "error",
        dangerMode: true,
      });
    }

    var body = {
      approvalStatus: status,
      paymentApprovedBy: userLoginDetails?.autoFinanceUser?.userId,
      paymentRejectionReason: rejectionReason,
      id: paymentDetails?.id,
      paymentMode: paymentDetails?.paymentMode,
    };

    moduleApi
      .postData(apiCall.updateNonVisitedpaymentstatus, body)
      .then((response) => {
        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (
            response?.Message === "Payment Status Updated Successfully"
          ) {
            setBasicModal(false);
            setBasicModal2(false);
            getPaymentsList(
              currPage,
              searchKey,
              startDate,
              endDate,
              selectedStatus,
              selectedApprovalStatus,
              selectedPaymentChannel
            );
            return swal("Updated Successfully", {
              icon: "success",
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  return (
    <div className="card">
      <div className="custom-tab-1">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            // marginTop: "10px",
            marginBottom: "5px",
            marginRight: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              // marginTop: "10px",
              marginBottom: "5px",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                float: "right",
                width: "150px",
                marginRight: "10px",
                height: "38px",
                marginLeft: "10px",
                borderRadius: "3px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) => {
                  value.value === "cm"
                    ? setIsDateFilter(false)
                    : setIsDateFilter(true);
                }}
                defaultValue={dateOptions[0]}
                options={dateOptions}
              />
            </div>
            {!isDateFilter && (
              <div
                style={{
                  float: "right",
                  // marginRight: "5px",
                  marginLeft: "5px",
                }}
              >
                <Flatpickr
                  id="nonVisitedCustomonthPicker"
                  className="form-control d-block"
                  style={{
                    width: "150px",
                    height: "40px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    textAlignLast: "center",
                  }}
                  placeholder="Select Month"
                  onChange={(value) => {
                    setCustomDatesForGraph(value, true);
                  }}
                  options={{
                    defaultDate: new Date(),
                    maxDate: new Date(),
                    minDate: new Date().setMonth(new Date().getMonth() - 6),

                    plugins: [
                      new monthSelectPlugin({
                        shorthand: true, //defaults to false
                        dateFormat: "F Y", //defaults to "F Y"
                        altFormat: "F Y", //defaults to "F Y"
                        theme: "light", // defaults to "light"
                      }),
                    ],
                  }}
                />
              </div>
            )}
            {isDateFilter && (
              <div>
                <Flatpickr
                  style={{
                    width: "200px",
                    height: "40px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    textAlignLast: "center",
                  }}
                  id="nonVisitedCustomDatePicker"
                  className="form-control d-block"
                  placeholder="Start & End Date"
                  onChange={(value) => {
                    setCustomDatesForGraph(value, false);
                  }}
                  options={{
                    mode: "range",
                    dateFormat: "d-M-y",
                    maxDate: new Date(),
                  }}
                />
              </div>
            )}

            <Button
              id="nonVisitedDownlaod"
              style={{
                marginRight: "10px",
                marginLeft: "7px",
                marginBottom: "5px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="success btn-rounded"
              onClick={() => paymentsDownloadData()}
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Download
            </Button>
          </div>
          <div style={{ marginTop: "5px", marginBottom: "5px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                float: "right",
                justifyContent: "center",
              }}
            >
              <label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                Payment Mode
              </label>
              <div
                style={{
                  float: "right",
                  width: "150px",
                  marginRight: "5px",
                  height: "38px",
                  marginLeft: "15px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  id="nonVisitedPaymentModes"
                  isSearchable={false}
                  onChange={(value) => setStatusValue(value.value)}
                  defaultValue={statusOptions[0]}
                  options={statusOptions}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                float: "right",
                justifyContent: "center",
              }}
            >
              <label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                Payment Channel
              </label>
              <div
                style={{
                  float: "right",
                  width: "150px",
                  marginRight: "5px",
                  height: "38px",
                  marginLeft: "15px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  id="paymentChannelFilter"
                  isSearchable={false}
                  onChange={(value) => setPaymentChannelValue(value.value)}
                  defaultValue={paymentChannelOptions[0]}
                  options={paymentChannelOptions}
                />
              </div>
            </div>
            {paymentDetails?.paymentMode !== "UPI Mandates" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  float: "right",
                  justifyContent: "center",
                }}
              >
                <label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                  Approval Status
                </label>
                <div
                  style={{
                    float: "right",
                    width: "170px",
                    marginRight: "5px",
                    height: "38px",
                    marginLeft: "5px",
                    borderRadius: "3px",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    id="nonVisitedApprovaStatusFilter"
                    isSearchable={false}
                    onChange={(value) => {
                      setApprovalStatusValue(value.value);
                    }}
                    defaultValue={{ label: "All", value: "" }}
                    options={approvalStatusOptions}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                float: "right",
                marginTop: "20px",
                marginRight: "5px",
              }}
            >
              <SearchBar
                id="nonVisitedSearch"
                placeholder="Search by Loan No/Customer Name/Mobile No"
                searchKeyword={searchKeyword}
              />
            </div>
          </div>
        </div>
        <div style={{ margin: "10px" }}>
          <LoadingOverlay
            active={isLoading}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            <div
              style={{
                background: "white",
                margin: "10px",
                marginTop: "-5px",
                overflowX: "auto",
                overflowY: "auto",
                maxHeight: "500px",
              }}
            >
              <BootstrapTable
                keyField="id"
                data={paymentsList}
                columns={paymentColumns}
                onSelect={onPageChange}
              />
            </div>
            {paymentsList.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements}
                pageSize={10}
                defaultActivePage={currPage + 1}
                onSelect={onPageChange}
              />
            </div>
          </LoadingOverlay>
        </div>
      </div>

      <Modal className="fade" size="md" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Payment Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}></Modal.Body>
        <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Customer Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.customerName
                ? paymentDetails?.customerName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Customer Mobile No
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.contactNumber
                ? paymentDetails?.contactNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Loan Number
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.loanNumber ? paymentDetails?.loanNumber : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Date
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentDate ? paymentDetails?.paymentDate : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Total Amount Paid
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.totalAmount ? paymentDetails?.totalAmount : 0}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Mode
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentMode ? paymentDetails?.paymentMode : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              { paymentDetails?.paymentStatus === "PAYMENT_SUCCESS" ||
                paymentDetails?.paymentStatus === "COMPLETED" ||
                paymentDetails?.payResponseCode === "SUCCESS" ||
                paymentDetails?.paymentMode === "Cash" ||
                paymentDetails?.paymentMode === "UPI" ||
                paymentDetails?.paymentMode === "Bank Transfer" ||
                paymentDetails?.paymentChannel === "Tele Callers"
                ? "Success"
                : paymentDetails?.paymentStatus ? paymentDetails?.paymentStatus :"-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvalStatus
                ? paymentDetails?.approvalStatus
                : "-"}
            </td>
          </tr>
          {paymentDetails?.approvalStatus === "REJECTED" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Rejection Reason
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {paymentDetails?.paymentRejectionReason
                  ? paymentDetails?.paymentRejectionReason
                  : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approved By
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvedByEmpName
                ? paymentDetails?.approvedByEmpName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Date
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvedTime
                ? paymentDetails?.approvedTime
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Collected By ID
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.collectedById
                ? paymentDetails?.collectedById
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Collected By Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.collectedByName
                ? paymentDetails?.collectedByName
                : "-"}
            </td>
          </tr>
          {paymentDetails?.paymentMode === "UPI Mandates" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                subscrptionId Id
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {paymentDetails?.subscrptionId
                  ? paymentDetails?.subscrptionId
                  : "-"}
              </td>
            </tr>
          )}
          {paymentDetails?.paymentMode === "UPI Mandates" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Utr Number
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {paymentDetails?.utrNumber ? paymentDetails?.utrNumber : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Transaction Id
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.transactionId
                ? paymentDetails?.transactionId
                : "-"}
            </td>
          </tr>

          {paymentDetails?.approvalStatus === "PENDING" && (
            <div>
              <Button
                style={{
                  float: "left",
                  marginTop: "10px",
                  marginRight: "25px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => updateApprovalStatus(paymentDetails, "APPROVED")}
              >
                Approve
              </Button>
              <Button
                style={{
                  float: "left",
                  marginTop: "10px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="danger btn-rounded"
                onClick={() => {
                  setRejectionReason(null);
                  setBasicModal2(true);
                }}
              >
                Reject
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal className="fade" show={basicModal2} size="sm">
        <Modal.Header>
          <Modal.Title>Add Rejection Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal2(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
              <input
                style={{
                  width: "220px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter Reason"
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                  console.log("rejectionReason", rejectionReason);
                }}
              />
              <div style={{ marginTop: "5px" }}>
                <div>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2"
                    type="submit"
                    onClick={() =>
                      updateApprovalStatus(paymentDetails, "REJECTED")
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2 float-right"
                    variant="danger light"
                    onClick={() => setBasicModal2(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NonVisitedPayments;
