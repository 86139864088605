import React, { useEffect } from "react";import {
  GoogleMap,
  Marker,
  Polyline, withGoogleMap, withScriptjs,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import marker_blue from "../../src/images/marker_blue.png";
import marker_red from "../../src/images/marker_red.png";
import marker_c from "../../src/images/marker_c.png";
import marker_s from "../../src/images/marker_s.png";
import marker_f from "../../src/images/marker_f.png";
import { EpochToTime } from "../CommonComponents/Helper"
import ReactGA from "react-ga";

const Track = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyChqvJDsjaqXtn-tjeMBa-Y67xqz4mtuWE",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
  //   {props.date && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
  // </GoogleMap>

  const getDefaultCenter = () => {
    if (props.visits.length > 0) {
      return props.visits[0]
    }

    if (props.latlongsArr.length > 0) {
      return props.latlongsArr[0][0]
    }
    return { lat: 17.385, lng: 78.4867 }
  }

  useEffect(() => {
    ReactGA.pageview('Tracking Page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const generateLabel = (e) => {
    switch (e.visitType) {
      case 'FieldInvestigation':
        return "Customer : " + e.fieldInvestigationVisit.customerName + "\n" +
          "Mobile No : " + e.fieldInvestigationVisit.contactNumber + "\n" +
          "Status : " + e.fieldInvestigationVisit.status + "\n" +
          "Time : " + EpochToTime(e.dateTime) + "\n" +
          "Visit Type : Field Investigation"

      case 'Collections':
        return "Customer : " + e.collectionVisit.customerName + "\n" +
          "Loan No : " + e.collectionVisit.loanNumber + "\n" +
          "Status : " + e.collectionVisit.status + "\n" +
          "Time : " + EpochToTime(e.dateTime) + "\n" +
          "Visit Type : Collection"
      case 'Sales':
        return "Customer : " + e.salesVisit.customerName + "\n" +
          "Mobile No : " + e.salesVisit.contactNumber + "\n" +
          "Time : " + EpochToTime(e.dateTime) + "\n" +
          "Visit Type : Sales"
      default:
        break;
    }
    return ""
  }

  const loadImage = (e) => {
    switch (e.visitType) {
      case 'FieldInvestigation':
        return marker_f;
      case 'Collections':
        return marker_c;
      case 'Sales':
        return marker_s;
      default:
        break;
    }
    return marker_f;
  }
  return (<div >
    <GoogleMap key={props.key} defaultZoom={13} defaultCenter={getDefaultCenter()}  >
      {
        props.latlongsArr.map((ele, index) => (
          <>
            <Marker
              position={ele[0]}
              // eslint-disable-next-line no-native-reassign
              icon={(URL = marker_blue)}
              style={{ height: "50px", width: "50px" }}
              title={"CheckIn Time : " + EpochToTime(ele[0].checkIn)}
            />
            <Polyline path={ele} options={{ strokeColor: `#${Math.floor(Math.random() * 16777215).toString(16)}` }} />
            <Marker
              position={ele[ele.length - 1]}
              // eslint-disable-next-line no-native-reassign
              icon={(URL = marker_red)}
              style={{ height: "50px", width: "50px" }}
              title={"CheckOut Time : " + EpochToTime(ele[ele.length - 1].checkOut)}

            />
          </>
        ))}
      {
        props.visits.map((e, i) => (
          <Marker
            position={e}
            // eslint-disable-next-line no-native-reassign
            icon={(URL = loadImage(e))}
            // style={{ height: "50px", width: "50px" }}            
            title={generateLabel(e)}
          />
        ))
      }
    </GoogleMap>

  </div>)
});

<Track isMarkerShown date />;

export default Track;