import React from "react";

const SearchBar = (props) => {
    let timer;

  return (
    <div>
      <input
        style={{
          width: "304px",
          height: "40px",
          borderRadius: "25px",
          borderColor: "#a0a0a0",
          borderWidth: "1px",
          boxShadow: "1px 1px 2px #3a353b",
          paddingLeft: "8px",
        }}
        placeholder={props.placeholder}
        onChange={(e) => {
          clearInterval(timer);
          timer = setTimeout(() => {
            props.searchKeyword(e.target.value.trim())
          }, 2000);
        }}
      />
    </div>
  );
};

export default SearchBar;
