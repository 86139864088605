import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoadingOverlay from "react-loading-overlay";
import * as moduleApi from "../rest/moduleApi";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Flatpickr from "react-flatpickr";
import Spinner from "react-bootstrap/Spinner";
import { apiCall } from "../rest/restApi";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select"
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import PaymentSummaryDetails from "./PaymentSummaryDetails";

const PaymentsSummary = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("Payment Mode");
  const [selectedDataType, setSelectedDataType] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  // const [clickedData, setClickedData] = useState("Something");

  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    1000 +
    86399
  );
  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    loadGraph(start, end, selectedPaymentType, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
      1000 +
      86400;
    setStart(start);
    setEnd(end);
    loadGraph(start, end, selectedPaymentType,selectedDataType);
  };

  const paymentTypes = [
    { label: "Payment Mode", value: "Payment Mode" },
    { label: "Payment Channel", value: "Payment Channel" },
  ];

  const dataTypeOptions= [
    { label: "Counts", value: false },
    { label: "Amounts", value: true },
  ];

  const setPaymentType = (value) => {

    setSelectedPaymentType(value);
    loadGraph(start, end, value,selectedDataType);

  };

  const setDataType = (value) => {

    setSelectedDataType(value);
    loadGraph(start, end, selectedPaymentType,value);

  };

  const loadGraph = (start, end, paymentType,isAmounts) => {
    setIsLoading(true)
    let payload = {
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      startDate: start,
      status: paymentType,
      amounts:isAmounts,
    };
    moduleApi.postData(apiCall.getCountsWithPaymentModeOrPaymentChannel, payload).then((response) => {

      let keys = [];
      let values = [];
      
      if (isAmounts === true) {

        for (let value of Object.keys(response?.statusAmounts)) {

          keys.push(value);

        }

        for (let value of Object.values(response?.statusAmounts)) {
          values.push(value);
        }
      } else {

        for (let value of Object.keys(response?.statusCounts)) {

          keys.push(value);

        }

        for (let value of Object.values(response?.statusCounts)) {
          values.push(value);
        }
      }
      setData({
        series: values,

        options: {
          chart: {
            width: 380,
            type: "pie",

            events: {
              dataPointSelection: (event, chartContext, config) => {

                const { dataPointIndex } = config;
                const clickedLabel = keys[dataPointIndex];

                setPaymentStatus(clickedLabel);
                setBasicModal(true);
              }
            }
          },

          // events: {
          //   dataPointSelection: (event, chartContext, config) => {
          //     // Get the data from the clicked slice
          //     const seriesIndex  = config;
          //     const clickedValue = data.series[seriesIndex];
          //     setClickedData(clickedValue);

          //     // Open the modal
          //     setBasicModal(true);
          //   }
          // },

          dataLabels: {
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex]
            },
          },
          noData: {
            text: "No Data Found",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#000000",
              fontSize: "20px",
              fontFamily: "Helvetica",
            },
          },
          labels: keys,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      });
      setIsLoading(false)
    });
  };

  return (
    <div className="card" style={{ padding: "10px" }}>
      <div className="custom-tab-1">
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} >
            <div
              id="selectFilter"
              style={{
                // float: "right",
                width: "140px",
                marginRight: "10px",
                height: "38px",
                marginLeft: "15px",
                borderRadius: "3px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) => {
                  setPaymentType(value.value);
                }}
                defaultValue={paymentTypes[0]}
                options={paymentTypes}
              />
            </div>
            <div
              id="selectFilter"
              style={{
                // float: "right",
                width: "140px",
                marginRight: "10px",
                height: "38px",
                borderRadius: "3px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) => {
                  setDataType(value.value);
                }}
                defaultValue={dataTypeOptions[0]}
                options={dataTypeOptions}
              />
            </div>
            <Flatpickr
              id="date"
              className="form-control d-block"
              style={{
                width: "180px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value);
              }}
              options={{
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          </div>
          {/* </div> */}
          <div>
            <LoadingOverlay
              active={isLoading}
              // spinner
              text={
                <p
                  style={{
                    color: "black",
                  }}
                >
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#FFFF",
                        color: "black",
                        borderColor: "white",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      variant="primary"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        variant="primary"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading...
                    </Button>
                  </div>
                </p>
              }
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(192,192,192,0.4)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "80px",
                  "& svg circle": {
                    stroke: "rgb(136, 108, 192)",
                  },
                }),
              }}
            >
              <div style={{ margin: "10px" }}>
                {data?.options && (
                  <ReactApexChart
                    options={data.options}
                    series={data.series}
                    type="pie"
                    height={350}
                  // onClick={(index) => {   
                  // console.log("indexxxx",index);           
                  // setPaymentStatus(index.label);
                  // setBasicModal(true)}}
                  />
                )}
                <Modal className="fade" show={basicModal} size="xl">
                  <Modal.Header>
                    <Modal.Title>Payment Details</Modal.Title>
                    <Button
                      variant=""
                      className="btn-close"
                      onClick={() => {
                        setBasicModal(false);
                      }}
                    ></Button>
                  </Modal.Header>
                  <Modal.Body style={{ paddingTop: "0px" }}>
                    <PaymentSummaryDetails
                      transactionType={selectedPaymentType}
                      status={paymentStatus}
                      startDate={start}
                      endDate={end}
                    />

                  </Modal.Body>
                </Modal>
              </div>
            </LoadingOverlay>
          </div>

        </div>

      </div>
    </div>
  );
};

export default PaymentsSummary;
