import React, { useEffect, useState } from "react";
import { Button, Nav, Tab, Row, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import Select from "react-select";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import SearchBar from "../CommonComponents/SearchBar";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import PaymentFailuresData from "./PaymentFailuresData";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import NonVisitedPayments from "./NonVisitedPayments";
import AllPayments from "./AllPayments";
import PaymentsSummary from "./PaymentsSummary";
import defaultImage from "../images/defaultImage.png";

const Payments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [toggle, setToggle] = useState("");
  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [cpNamesList, setCpNamesList] = useState(null);
  const [selectedCpName, setSelectedCpName] = useState("");
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [paymentVisitDetails, setPaymentVisitDetails] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    1000
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    1000 +
    86399
  );

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllCpNames();

    getPaymentsList(
      currPage,
      searchKey,
      startDate,
      endDate,
      selectedStatus,
      selectedCpName
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paymentsDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDownloadList]);

  const getPaymentsList = (
    pageNo,
    key,
    start,
    end,
    status,
    cpName,
    approvalStatus
  ) => {
    let listData = [];
    setIsLoading(true);
    var payload = {
      endTime: end,
      startTime: start,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchkey: key,
      paymentMode: status,
      cpName: cpName,
      approvalStatus: approvalStatus,

      //   userId: "string",
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getPaymentTransactions, pageNo, 10),
        payload
      )
      .then((response) => {
        console.log("PaymentResponse", response);
        response?.content?.forEach((element) => {
          let data = {
            amountPaid: element.amountPaid ? element.amountPaid : 0,
            collectedByID: element.collectedByID ? element.collectedByID : "-",
            collectedByName: element.collectedByName
              ? element.collectedByName
              : "-",
            customerName: element.customerName ? element.customerName : "-",
            customerNumber: element.customerNumber
              ? element.customerNumber
              : "-",
            cpName: element.cpName ? element.cpName : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            approvalStatus: element.approvalStatus
              ? element.approvalStatus
              : "-",
            approvedBy: element.approvedBy ? element.approvedBy : "-",
            approvedTime: element.approvedTime
              ? helper.EpochToDate(element.approvedTime)
              : "-",
            image: element.image ? element.image : "-",
            paymentTowards: element.paymentTowards
              ? element.paymentTowards
              : "-",
            receiptNumber: element?.receiptNumber
              ? element?.receiptNumber
              : null,
            subStatus: element.subStatus ? element.subStatus : "-",
            paymentRejectedReason: element.paymentRejectedReason
              ? element.paymentRejectedReason
              : "-",
            visitId: element.visitId ? element.visitId : "-",
            paymentDate: element.paymentDate
              ? helper.EpochToDate(element.paymentDate)
              : "-",
            paymentMode: element.paymentMode ? element.paymentMode : "-",
            paymentStatus: element.paymentStatus ? element.paymentStatus : "-",
            transactionId: element.transactionId ? element.transactionId : "-",
            visitDate: element?.visitDate
              ? helper.EpochToDate(element.visitDate)
              : "-",
            emiAmountPaidNow: element.emiAmountPaidNow
              ? element.emiAmountPaidNow
              : 0,
            latePaymentChargesPaidNow: element.latePaymentChargesPaidNow
              ? element.latePaymentChargesPaidNow
              : 0,
            bounceChargesPaidNow: element.bounceChargesPaidNow
              ? element.bounceChargesPaidNow
              : 0,
            penaltyChargesPaidNow: element.penaltyChargesPaidNow
              ? element.penaltyChargesPaidNow
              : 0,
            otherChargesPaidNow: element.otherChargesPaidNow
              ? element.otherChargesPaidNow
              : 0,
          };
          listData.push(data);
        });
        setCurrPage(pageNo);
        setPaymentsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const getAllCpNames = () => {
    moduleApi
      .getData(
        apiCall.getCpUsersForDropdown +
        userLoginDetails.autoFinanceUser.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response.map((item, index) => ({
          value: item,
          label: item,
          index: index,
        }));
        result.unshift({ label: "All", value: "" });
        setCpNamesList(result);
      });
  };

  //   const getRupees = (value) => {
  //     return value / 100;
  //   };

  const options1 = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const paymentColumns = [
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile No</span>
        </div>
      ),
      dataField: "customerNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>CP</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "cpName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Loan</span>
          <br></br>
          <span>Number</span>
        </div>
      ),
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "paymentDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Visit</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "visitDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>EMI Amount</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "emiAmountPaidNow",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Late Payment</span>
          <br></br>
          <span>Charges Paid</span>
        </div>
      ),
      dataField: "latePaymentChargesPaidNow",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Bounce Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "bounceChargesPaidNow",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Penalty Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "penaltyChargesPaidNow",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Other Charges</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "otherChargesPaidNow",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total Amount</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "amountPaid",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (
          row.paymentStatus === "PAYMENT_SUCCESS" ||
          row.payResponseCode === "SUCCESS" ||
          row.paymentMode === "Cash" ||
          row.paymentMode === "UPI" ||
          row.paymentMode === "Bank Transfer"
        ) {
          return (<span style={{ color:'#0000FF'}}>{row.amountPaid}</span>);
        }else{
          return (<span style={{ color:'red'}}>{row.amountPaid}</span>);
        }
      }
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Mode</span>
        </div>
      ),
      dataField: "paymentMode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Towards</span>
        </div>
      ),
      dataField: "paymentTowards",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "paymentStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (
          row.paymentStatus === "PAYMENT_SUCCESS" ||
          row.paymentMode === "Other" ||
          row.paymentMode === "Bank Transfer" ||
          row.paymentMode === "UPI"
        ) {
          return (
            <span className="badge badge-rounded badge-success">Success</span>
          );
        } else if (row.paymentMode === "Cash") {
          if (row.paymentStatus === "DEPOSITED") {
            return <span className="badge badge-success">Deposited</span>;
          } else if (row.paymentStatus === "MAPPED") {
            return (
              <span className="badge badge-warning" style={{ color: "black" }}>
                Mapped
              </span>
            );
          } else if (row.paymentStatus === "REJECTED") {
            return <span className="badge badge-danger">Rejected</span>;
          } else if (row.paymentStatus === "UNMAPPED") {
            return <span className="badge badge-danger">Unmapped</span>;
          } else {
            return "-";
          }
        } else if (row.paymentStatus === "PAYMENT_CANCELLED") {
          return (
            <span className="badge badge-rounded badge-danger">Cancelled</span>
          );
        } else if (
          row.paymentStatus === "PAYMENT_ERROR" ||
          row.paymentStatus === "FAILED"
        ) {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else if (row.paymentStatus === "TRANSACTION_NOT_FOUND") {
          return (
            <span className="badge badge-rounded badge-danger">
              Transaction Not Found
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Approval</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "approvalStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.approvalStatus === "APPROVED") {
          return (
            <span className="badge badge-rounded badge-success">Approved</span>
          );
        } else if (row.approvalStatus === "REJECTED") {
          return (
            <span className="badge badge-rounded badge-danger">Rejected</span>
          );
        } else if (row.approvalStatus === "PENDING") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              Pending
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Approved</span>
          <br></br>
          <span>By</span>
        </div>
      ),
      dataField: "approvedBy",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Approved</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "approvedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Collected By</span>
          <br></br>
          <span>Id</span>
        </div>
      ),
      dataField: "collectedByID",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: (
    //     <div>
    //       <span>Collected By</span>
    //       <br></br>
    //       <span>Name</span>
    //     </div>
    //   ),
    //   dataField: "collectedByName",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    // {
    //   text: "Transaction ID",
    //   dataField: "transactionId",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: (
        <div>
          <span>Approve /</span>
          <br></br>
          <span>Reject</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setPaymentDetails(row);
          setBasicModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        // if (
        //   row.cashDepositStatus === "MAPPED" ||
        //   row.cashDepositStatus === "DEPOSITED"
        // ) {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-primary shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
        // } else {
        //   return <></>;
        // }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          getPaymentVisits(row.visitId);
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.receiptNumber === null) {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-download"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.receiptNumber !== null) {
            window.open(
              moduleApi.getBasePath() +
              apiCall.getPaymentReceiptForPayments +
              row.receiptNumber
            );
          }
        },
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.receiptNumber === null) {
          return <></>;
        } else {
          return (
            <div>
              <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fas fa-external-link-alt"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.receiptNumber !== null) {
            swal({
              title: "Resend",
              text: "Do you really want to Resend Payment Receipt?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                resendPaymentReceipt(row?.receiptNumber);
              }
            });
          }
        },
      },
    },
  ];

  const resendPaymentReceipt = (receiptNumber) => {
    moduleApi
      .getData(apiCall.resendPaymentReceiptForPayments + receiptNumber)
      .then((response) => {
        if (response) {
          if (response.Message === "Receipt Sent Successfully") {
            return swal("Receipt Sent Successfully", {
              icon: "success",
              dangerMode: false,
            });
          } else if (response.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        }
      });
  };

  const statusOptions = [
    { label: "All", value: "All" },
    { label: "Bank Transfer", value: "Bank Transfer" },
    { label: "Cash", value: "Cash" },
    { label: "QR Code", value: "QR CODE" },
    { label: "UPI", value: "UPI" },
    { label: "UPI Payment Request", value: "COLLECT REQUEST" },
    { label: "Bulk Payment Request", value: "Payment Links" },
    { label: "Others", value: "Other" },
  ];
  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  const approvalStatusOptions = [
    { label: "All", value: "" },
    { label: "Pending", value: "PENDING" },
    { label: "Approved", value: "APPROVED" },
    { label: "Rejected", value: "REJECTED" },
  ];

  const setStatusValue = (value) => {
    getPaymentsList(
      0,
      searchKey,
      startDate,
      endDate,
      value,
      selectedCpName,
      selectedApprovalStatus
    );
    setSelectedStatus(value);
  };

  const setCpNameValue = (value) => {
    getPaymentsList(
      0,
      searchKey,
      startDate,
      endDate,
      selectedStatus,
      value,
      selectedApprovalStatus
    );
    setSelectedCpName(value);
  };

  const setApprovalStatusValue = (value) => {
    getPaymentsList(
      0,
      searchKey,
      startDate,
      endDate,
      selectedStatus,
      selectedCpName,
      value
    );
    setSelectedApprovalStatus(value);
  };

  const getPaymentVisits = (id) => {
    moduleApi
      .getData(apiCall.getEmployeeVisitsByVisitId + id)
      .then((element) => {
        console.log("paymentVisitRespopnse", element);
        let data = {
          id: element.id,
              userId: element.userId,
              agDate: element.agDate,
              aggDate: element.aggDate,
              dueDate: element.dueDate,
              vehicleNumber: element.vehicleNumber,
              userModel: element.userModel,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              companyDesignation: element.userModel.companyDesignation,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,

              organizationId: element.organisationId,
              status: element.status,
              subStatus: element.subStatus,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              customerLatitude:element.customerLatitude,
              customerLongitude:element.customerLongitude,
              address: element.address,
              customerLocationChanged: element.customerLocationChanged,
              visitType: element.visitType,
              image: element.image,
              visitNotDoneAtCustLocation: element.visitNotDoneAtCustLocation,
              dateTime: helper.EpochToDate(element.dateTime),
              timeSpent: element.timeSpent,
              timeDiffOfPrevVisit: element.timeDiffOfPrevVisit,
              reportingOfficerId: element.reportingOfficerId,
              reportingOfficerName: element.reportingOfficerName,
              salesVisit: element.salesVisit,
              distFromCustAddr: getDistTravelledInKM(element.distFromCustAddr),
              contactNumber: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.contactNumber
                  : "--"
                : element.salesVisit
                ? element.salesVisit.contactNumber
                : "--",

              collectionVisit: element.collectionVisit,
              receiptNumber: element.collectionVisit?.receiptNumber
                ? element.collectionVisit?.receiptNumber
                : null,
              loanNumber: element.collectionVisit
                ? element.collectionVisit.loanNumber
                : "-",
              collectionsContactNumber: element.collectionVisit?.contactNumber,
              invenotryScan: element.collectionVisit?.invenotryScan,
              repossedImages: element.collectionVisit?.repossedImages,
              repossedLocation: element.collectionVisit?.repossedLocation,
              customerName: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.customerName
                : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.customerName
                  : "--"
                : element.salesVisit
                ? element.salesVisit.customerName
                : "--",
              visitDoneFromVisitManagement:element.visitDoneFromVisitManagement,
              fiAllocationId:element.fiAllocationId,
              fieldInvestigationVisit: element.fieldInvestigationVisit,
              fiAllocation:element.fiAllocation,
              fiCompleteDetail:element.fiCompleteDetail,
        };

        setPaymentVisitDetails(data);
        setToggle("chatbox");
      });
  };

  const getDistTravelledInKM = (distFromCustAddr) => {
    if (distFromCustAddr !== 0) {
      var km = distFromCustAddr / 1000.0;
      return km.toFixed(0) + " km";
    } else {
      return "-";
    }
  };

  const paymentsDownloadData = () => {
    setIsLoading(true);
    var payload = {
      endTime: endDate,
      startTime: startDate,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchkey: searchKey,
      paymentMode: selectedStatus,
      cpName: selectedCpName,
      approvalStatus: selectedApprovalStatus,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getPaymentTransactions, 0, 10),
        payload
      )
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getPaymentsFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getPaymentsFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getPaymentsFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        endTime: endDate,
        startTime: startDate,
        reportingOfficerId: isAdmin()
          ? undefined
          : userLoginDetails.autoFinanceUser.userId,
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        searchkey: searchKey,
        paymentMode: selectedStatus,
        cpName: selectedCpName,
        approvalStatus: selectedApprovalStatus,
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getPaymentTransactions, i, 100),
          payload
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        amountPaid: element.amountPaid ? element.amountPaid : 0,
        collectedByID: element.collectedByID ? element.collectedByID : "-",
        collectedByName: element.collectedByName
          ? element.collectedByName
          : "-",
        customerName: element.customerName ? element.customerName : "-",
        customerNumber: element?.customerNumber ? element.customerNumber : "-",
        loanNumber: element?.loanNumber ? element.loanNumber : "-",
        paymentDate: element?.paymentDate
          ? helper.EpochToDate(element.paymentDate)
          : "-",
        paymentMode: element?.paymentMode ? element.paymentMode : "-",
        approvalStatus: element.approvalStatus ? element.approvalStatus : "-",
        approvedBy: element.approvedBy ? element.approvedBy : "-",
        approvedTime: element.approvedTime
          ? helper.EpochToDate(element.approvedTime)
          : "-",
        image: element.image ? element.image : "-",
        cpName: element.cpName ? element.cpName : "-",
        paymentTowards: element.paymentTowards ? element.paymentTowards : "-",
        paymentRejectedReason: element.paymentRejectedReason
          ? element.paymentRejectedReason
          : "-",
        subStatus: element.subStatus ? element.subStatus : "-",
        paymentStatus:
          element?.paymentStatus === "PAYMENT_SUCCESS" ||
            element?.paymentMode === "Other" ||
            element?.paymentMode === "Bank Transfer" ||
            element?.paymentMode === "UPI"
            ? "Success"
            : element?.paymentStatus === "PAYMENT_CANCELLED"
              ? "Cancelled"
              : element?.paymentStatus === "PAYMENT_ERROR"
                ? "Failed"
                : element?.paymentStatus === "TRANSACTION_NOT_FOUND"
                  ? "Transaction Not Found"
                  : element?.paymentStatus === null || element?.paymentStatus === ""
                    ? "-"
                    : element?.paymentStatus,
        transactionId: element.transactionId ? element.transactionId : "-",
        visitDate: element?.visitDate
          ? helper.EpochToDate(element.visitDate)
          : "-",
        emiAmountPaidNow: element.emiAmountPaidNow
          ? element.emiAmountPaidNow
          : 0,
        latePaymentChargesPaidNow: element.latePaymentChargesPaidNow
          ? element.latePaymentChargesPaidNow
          : 0,
        bounceChargesPaidNow: element.bounceChargesPaidNow
          ? element.bounceChargesPaidNow
          : 0,
        penaltyChargesPaidNow: element.penaltyChargesPaidNow
          ? element.penaltyChargesPaidNow
          : 0,
        otherChargesPaidNow: element.otherChargesPaidNow
          ? element.otherChargesPaidNow
          : 0,
      };
      mgrListData.push(data);
    });
    setPaymentsDownloadList(mgrListData);
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Customer Name",
        B: "Customer Mobile No",
        C: "CP Name",
        D: "Loan No",
        E: "Payment Date",
        F: "Visit Date",
        G: "EMI Amount Paid",
        H: "Late Payment Charges Paid",
        I: "Bounce Charges Paid",
        J: "Penalty Charges Paid",
        K: "Other Charges Paid",
        L: "Total Amount Paid",
        M: "Payment Mode",
        N: "Payment Towards",
        O: "Payment Status",
        P: "Sub Status",
        Q: "Approval Status",
        R: "Rejection Reason",
        S: "Approved By",
        T: "Approved Date",
        U: "Collected By Id",
        V: "Collected By Name",
        W: "Transaction Id",
      },
    ];

    paymentsDownloadList?.forEach((row) => {
      mgrTable.push({
        A: row.customerName,
        B: row.customerNumber,
        C: row.cpName,
        D: row.loanNumber,
        E: row.paymentDate,
        F: row.visitDate,
        G: row.emiAmountPaidNow,
        H: row.latePaymentChargesPaidNow,
        I: row.bounceChargesPaidNow,
        J: row.penaltyChargesPaidNow,
        K: row.otherChargesPaidNow,
        L: row.amountPaid,
        M: row.paymentMode,
        N: row.paymentTowards,
        O: row.paymentStatus,
        P: row.subStatus,
        Q: row.approvalStatus,
        R: row.paymentRejectedReason,
        S: row.approvedBy,
        T: row.approvedTime,
        U: row.collectedByID,
        V: row.collectedByName,
        W: row.transactionId,
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Visited Payments");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Visited_Payments_" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx"
    );
    ReactGA.event({
      category: "Visited Payments List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Payments_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
          value[0].getFullYear(),
          value[0].getMonth() + 1,
          0
        ).getTime() /
        1000 +
        86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86400);
    //   let start = ;
    // let end = Math.floor(new Date(dateStrings[1]).getTime() / 1000 + 86400);

    setStartDate(start);
    setEndDate(end);
    getPaymentsList(0, searchKey, start, end, selectedStatus);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    getPaymentsList(
      0,
      key,
      startDate,
      endDate,
      selectedStatus,
      selectedCpName,
      selectedApprovalStatus
    );
  };

  const onPageChange = (selectedPage) => {
    console.log("pageNo", selectedPage - 1);
    setCurrPage(selectedPage - 1);
    getPaymentsList(
      selectedPage - 1,
      searchKey,
      startDate,
      endDate,
      selectedStatus,
      selectedCpName,
      selectedApprovalStatus
    );
  };

  const updateApprovalStatus = (paymentDetails, status) => {
    console.log("status", status);
    if (status === "REJECTED" && rejectionReason === null) {
      return swal("Please Enter Rejection Reason", {
        icon: "error",
        dangerMode: true,
      });
    }

    var body = {
      approvalStatus: status,
      paymentApprovedBy: userLoginDetails?.autoFinanceUser?.userId,
      paymentRejectedReason: rejectionReason,
      visitId: paymentDetails?.visitId,
    };

    moduleApi.postData(apiCall.updatePaymentStatus, body).then((response) => {
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.Message === "Payment Updated Successfully") {
          setBasicModal(false);
          setBasicModal2(false);
          getPaymentsList(
            currPage,
            searchKey,
            startDate,
            endDate,
            selectedStatus,
            selectedCpName,
            selectedApprovalStatus
          );
          return swal("Updated Successfully", {
            icon: "success",
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  return (
    <div className="card">
      <div className="custom-tab-1" style={{ margin: "10px" }}>
        <Tab.Container defaultActiveKey="allPayments">
          <Nav as="ul" className="nav-tabs-custom nav-justified">
            <Nav.Item as="li" key="0">
              <Nav.Link eventKey="allPayments">
                <b> All Payments</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="1">
              <Nav.Link eventKey="payments">
                <b>Visited Payments </b>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" key="2">
              <Nav.Link eventKey="nonVisitedPayments">
                <b> Non Visited Payments</b>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" key="4">
              <Nav.Link eventKey="paymentSummary">
                <b>Payments Summary </b>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" key="3">
              <Nav.Link eventKey="summary">
                <b> UPI Payments Summary </b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
          <Tab.Pane eventKey="allPayments" key="0" mountOnEnter>
            <AllPayments />
          </Tab.Pane>
            <Tab.Pane eventKey="payments" key="1" mountOnEnter>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  // marginTop: "10px",
                  marginBottom: "5px",
                  marginRight: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    // marginTop: "10px",
                    marginBottom: "5px",
                    marginRight: "20px",
                  }}
                >
                  <div
                    style={{
                      float: "right",
                      width: "150px",
                      marginRight: "10px",
                      height: "38px",
                      marginLeft: "10px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      isSearchable={false}
                      onChange={(value) => {
                        value.value === "cm"
                          ? setIsDateFilter(false)
                          : setIsDateFilter(true);
                      }}
                      defaultValue={dateOptions[0]}
                      options={dateOptions}
                    />
                  </div>
                  {!isDateFilter && (
                    <div
                      style={{
                        float: "right",
                        // marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      <Flatpickr
                        id="customonthPicker"
                        className="form-control d-block"
                        style={{
                          width: "150px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                        }}
                        placeholder="Select Month"
                        onChange={(value) => {
                          setCustomDatesForGraph(value, true);
                        }}
                        options={{
                          defaultDate: new Date(),
                          maxDate: new Date(),
                          minDate: new Date().setMonth(
                            new Date().getMonth() - 6
                          ),

                          plugins: [
                            new monthSelectPlugin({
                              shorthand: true, //defaults to false
                              dateFormat: "F Y", //defaults to "F Y"
                              altFormat: "F Y", //defaults to "F Y"
                              theme: "light", // defaults to "light"
                            }),
                          ],
                        }}
                      />
                    </div>
                  )}
                  {isDateFilter && (
                    <div>
                      <Flatpickr
                        style={{
                          width: "200px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                        }}
                        id="customDatePicker"
                        className="form-control d-block"
                        placeholder="Start & End Date"
                        onChange={(value) => {
                          setCustomDatesForGraph(value, false);
                        }}
                        options={{
                          mode: "range",
                          dateFormat: "d-M-y",
                          maxDate: new Date(),
                        }}
                      />
                    </div>
                  )}

                  {/* <div
                    style={{
                      float: "right",
                      marginRight: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    <Flatpickr
                      id="customonthPicker"
                      className="form-control d-block"
                      style={{
                        width: "150px",
                        height: "40px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #3a353b",
                        textAlignLast: "center",
                      }}
                      placeholder="Select Month"
                      onChange={(value) => {
                        setCustomDatesForGraph(value, true);
                      }}
                      options={{
                        defaultDate: new Date(),
                        maxDate: new Date(),
                        minDate: new Date().setMonth(new Date().getMonth() - 6),

                        plugins: [
                          new monthSelectPlugin({
                            shorthand: true, //defaults to false
                            dateFormat: "F Y", //defaults to "F Y"
                            altFormat: "F Y", //defaults to "F Y"
                            theme: "light", // defaults to "light"
                          }),
                        ],
                      }}
                    />
                  </div> */}

                  <Button
                    style={{
                      marginRight: "10px",
                      marginLeft: "7px",
                      marginBottom: "5px",
                      fontFamily:
                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                    }}
                    className="me-2"
                    variant="success btn-rounded"
                    onClick={() => paymentsDownloadData()}
                  >
                    <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                  </Button>
                </div>
                <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      float: "right",
                      justifyContent: "center",
                    }}
                  >
                    <label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      Payment Mode
                    </label>
                    <div
                      style={{
                        float: "right",
                        width: "150px",
                        marginRight: "5px",
                        height: "38px",
                        marginLeft: "15px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => setStatusValue(value.value)}
                        defaultValue={statusOptions[0]}
                        options={statusOptions}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      float: "right",
                      justifyContent: "center",
                    }}
                  >
                    <label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      CP Names
                    </label>
                    <div
                      style={{
                        float: "right",
                        width: "160px",
                        marginRight: "5px",
                        height: "38px",
                        marginLeft: "5px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        id="cpNames"
                        isSearchable={false}
                        onChange={(value) => {
                          setCpNameValue(value.value);
                        }}
                        defaultValue={{ label: "All", value: "" }}
                        options={cpNamesList}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      float: "right",
                      justifyContent: "center",
                    }}
                  >
                    <label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      Approval Status
                    </label>
                    <div
                      style={{
                        float: "right",
                        width: "170px",
                        marginRight: "5px",
                        height: "38px",
                        marginLeft: "5px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        id="ApprovaStatusFilter"
                        isSearchable={false}
                        onChange={(value) => {
                          setApprovalStatusValue(value.value);
                        }}
                        defaultValue={{ label: "All", value: "" }}
                        options={approvalStatusOptions}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      float: "right",
                      marginTop: "20px",
                      marginRight: "5px",
                    }}
                  >
                    <SearchBar
                      id="visitedSearch"
                      placeholder="Search by Loan No/Customer Name/Mobile No"
                      searchKeyword={searchKeyword}
                    />
                  </div>
                </div>
              </div>
              <div style={{ margin: "10px" }}>
                <LoadingOverlay
                  active={isLoading}
                  // spinner
                  text={
                    <p
                      style={{
                        color: "black",
                      }}
                    >
                      <div>
                        <Button
                          style={{
                            backgroundColor: "#FFFF",
                            color: "black",
                            borderColor: "white",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                          variant="primary"
                          disabled
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            variant="primary"
                            role="status"
                            aria-hidden="true"
                          />
                          &nbsp;&nbsp;Loading...
                        </Button>
                      </div>
                    </p>
                  }
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(192,192,192,0.4)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "80px",
                      "& svg circle": {
                        stroke: "rgb(136, 108, 192)",
                      },
                    }),
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      margin: "10px",
                      marginTop: "-5px",
                      overflowX: "auto",
                      overflowY: "auto",
                      maxHeight: "500px",
                    }}
                  >
                    <BootstrapTable
                      keyField="id"
                      data={paymentsList}
                      columns={paymentColumns}
                      onSelect={onPageChange}
                    />
                  </div>
                  {paymentsList.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                    }}
                  >
                    <PaginationComponent
                      totalItems={totalElements}
                      pageSize={10}
                      defaultActivePage={currPage + 1}
                      onSelect={onPageChange}
                    />
                  </div>
                </LoadingOverlay>
                {toggle === "chatbox" && (
                  <VisitedPlaceDrawer
                    visitedPlacesDetailsList={paymentVisitDetails}
                    closeDrawer={() => setToggle(false)}
                  // reLoad={() => loadVistedPlacesData(currPage, searchKey)}
                  />
                )}
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="nonVisitedPayments" key="2" mountOnEnter>
              <NonVisitedPayments />
            </Tab.Pane>

            <Tab.Pane eventKey="summary" key="3" mountOnEnter>
              <PaymentFailuresData />
            </Tab.Pane>
            <Tab.Pane eventKey="paymentSummary" key="4" mountOnEnter>
              <PaymentsSummary />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      <Modal className="fade" size="md" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Payment Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}></Modal.Body>
        <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
          {/* <tr></tr> */}
          <tr>
            <SRLWrapper
              options={options1}
              style={{
                height: "140px",
                width: "140px",
              }}
            >
              <a href={paymentDetails?.image + "?originalImage=true"}>
                <img
                  style={{
                    height: "70px",
                    width: "80px",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  src={paymentDetails?.image ? paymentDetails?.image : defaultImage}
                  alt=""
                  className="img-fluid rounded-circle"
                />
              </a>
            </SRLWrapper>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Customer Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.customerName
                ? paymentDetails?.customerName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Customer Mobile No
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.customerNumber
                ? paymentDetails?.customerNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Loan Number
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.loanNumber ? paymentDetails?.loanNumber : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Date
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentDate ? paymentDetails?.paymentDate : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Amount Paid
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.amountPaid ? paymentDetails?.amountPaid : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Mode
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentMode ? paymentDetails?.paymentMode : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentStatus === "PAYMENT_SUCCESS" ||
                paymentDetails?.paymentMode === "Other" ||
                paymentDetails?.paymentMode === "Bank Transfer" ||
                paymentDetails?.paymentMode === "UPI"
                ? "Success"
                : paymentDetails?.paymentStatus}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Sub Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.subStatus ? paymentDetails?.subStatus : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvalStatus
                ? paymentDetails?.approvalStatus
                : "-"}
            </td>
          </tr>
          {paymentDetails?.approvalStatus === "REJECTED" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Rejection Reason
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {paymentDetails?.paymentRejectedReason
                  ? paymentDetails?.paymentRejectedReason
                  : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approved By
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvedBy ? paymentDetails?.approvedBy : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Date
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.approvedTime
                ? paymentDetails?.approvedTime
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Payment Towards
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.paymentTowards
                ? paymentDetails?.paymentTowards
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Collected By ID
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.collectedByID
                ? paymentDetails?.collectedByID
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Collected By Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.collectedByName
                ? paymentDetails?.collectedByName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Transaction Id
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {paymentDetails?.transactionId
                ? paymentDetails?.transactionId
                : "-"}
            </td>
          </tr>
          {/* {paymentDetails?.paymentStatus === "Success" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Rejection Reason
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {paymentDetails?.paymentRejectedReason
                  ? paymentDetails?.paymentRejectedReason
                  : "-"}
              </td>
            </tr>
          )} */}
          {paymentDetails?.approvalStatus === "PENDING" && (
            <div>
              <Button
                style={{
                  float: "left",
                  marginTop: "10px",
                  marginRight: "25px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => updateApprovalStatus(paymentDetails, "APPROVED")}
              >
                Approve
              </Button>
              <Button
                style={{
                  float: "left",
                  marginTop: "10px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="danger btn-rounded"
                onClick={() => {
                  setRejectionReason(null);
                  setBasicModal2(true);
                }}
              >
                Reject
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal className="fade" show={basicModal2} size="sm">
        <Modal.Header>
          <Modal.Title>Add Rejection Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal2(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
              <input
                style={{
                  width: "220px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter Reason"
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                  console.log("rejectionReason", rejectionReason);
                }}
              />
              <div style={{ marginTop: "5px" }}>
                <div>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2"
                    type="submit"
                    onClick={() =>
                      updateApprovalStatus(paymentDetails, "REJECTED")
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2 float-right"
                    variant="danger light"
                    onClick={() => setBasicModal2(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Payments;
