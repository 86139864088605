import React, { useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import swal from "sweetalert";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage, hasAccess } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import { EpochToDate } from "../CommonComponents/Helper";
import SearchBar from "../CommonComponents/SearchBar";
import { Link } from "react-router-dom";


function Designations() {
  // var history = useHistory();
  const [designationsList, setDesignationsList] = useState([]);
  const [designationDetails, setDesignationDetails] = useState([]);
  const [addDesignationModal, setAddDesignationModal] = useState(false);
  const [editDesignationModal, setEditDesignationModal] = useState(false);
  const [companyDesignation, setCompanyDesignation] = useState(null);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadDesignationsData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDesignationsData = (pageNo, key) => {
    setIsLoading(true);

    var body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchKey: key,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCompanyDesignation, pageNo, 10),
        body
      )
      .then((response) => {
        setCurrPage(pageNo);
        var listData = [];
        response?.content?.forEach((element) => {
          let data = {
            id: element.id ? element.id : "-",
            empName: element.empName ? element.empName : "-",
            empId: element.empId ? element.empId : "-",
            companyDesignation: element.companyDesignation ? element.companyDesignation : "-",
            createdTime: element.createdTime
              ? EpochToDate(element.createdTime)
              : "-",
            addedByUserId: element.addedByUserId ? element.addedByUserId : "-"
          };
          listData.push(data);
        });
        setDesignationsList(listData);
        setIsLoading(false);

        setTotalElements(response?.totalElements);
      });
  };

  const addDesignation = () => {
    setIsLoading(true);
    if (companyDesignation === null) {
      setIsLoading(false);
      return swal("Please Enter Designation", {
        icon: "error",
        dangerMode: true,
      });
    }else{
      if (companyDesignation.trim().length===0) {
        setIsLoading(false);
        return swal("Please enter valid designation", {
          icon: "error",
          dangerMode: true,
        });
      }
    }

    if (companyDesignation.length > 50) {
      setIsLoading(false);
      return swal("Designation Should be of max 50 characters", {
        icon: "error",
        dangerMode: true,
      });
    }

    var payload = {
      addedByUserId: userLoginDetails?.autoFinanceUser?.userId,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      companyDesignation: companyDesignation,
    };
    moduleApi.postData(apiCall.addCompanyDesignation, payload).then((response) => {

      if (response) {
        if (response?.message) {
          setIsLoading(false);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setIsLoading(false);
          loadDesignationsData(currPage);
          setAddDesignationModal(false);
          return swal("Designation Added Successfully", {
            icon: "success",
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const editDesignation = () => {
    setIsLoading(true);
    if (companyDesignation === null) {
      setIsLoading(false);
      return swal("Please Enter Designation", {
        icon: "error",
        dangerMode: true,
      });
    }else{
      if (companyDesignation.trim().length===0) {
        setIsLoading(false);
        return swal("Please enter valid designation", {
          icon: "error",
          dangerMode: true,
        });
      }
    }

    if (companyDesignation.length > 50) {
      setIsLoading(false);
      return swal("Designation Should be of max 50 characters", {
        icon: "error",
        dangerMode: true,
      });
    }

    var payload = {
      id: designationDetails.id,
      addedByUserId: userLoginDetails?.autoFinanceUser?.userId,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      companyDesignation: companyDesignation,
    };
    moduleApi.postData(apiCall.updateCompanyDesignation, payload).then((response) => {

      if (response) {
        if (response?.message) {
          setIsLoading(false);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setIsLoading(false);
          loadDesignationsData(currPage);
          setEditDesignationModal(false);
          return swal("Designation Updated Successfully", {
            icon: "success",
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const searchKeyword = (key) => {
    loadDesignationsData(0, key);
  };

  const designationColumns = [
    {
      text: "Company Designation",
      dataField: "companyDesignation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created By Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created By Id",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Created Date & Time",
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if(hasAccess(87)){
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fa fa-edit"></i>
            </Link>
          </div>
        );
      }else{
        return <></>;
      }

      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setCompanyDesignation(row.companyDesignation);
          setDesignationDetails(row);
          setEditDesignationModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if(hasAccess(87)){
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-danger shadow btn-xs sharp">
              <i className="fas fa-trash" />
            </Link>
          </div>
        );
      }else{
        return <></>;
      }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          swal({
            title: "Delete",
            text: "Are you sure, you want to Delete the Designation?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              var payLoad = {
                organizationId: userLoginDetails.autoFinanceUser.organizationId,
                id: row.id,
              };
              moduleApi
                .postData(apiCall.deleteCompanyDesignation, payLoad)
                .then((response) => {
                  if (
                    response?.Message ===
                    "Company Designation Deleted successfully"
                  ) {
                    loadDesignationsData(0);
                    swal("Designation Deleted successfully", {
                      icon: "success",
                    });
                  } else if (response?.Message) {
                    swal(response.Message, {
                      icon: "error",
                      dangerMode: true,
                    });
                  }
                });
            }
          });
        },
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadDesignationsData(selectedPage - 1);
  };

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >

        <SearchBar
          placeholder="Search By Company Designation"
          searchKeyword={searchKeyword}
        />
        {hasAccess(87) &&(
        <Button
          style={{
            float: "right",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => {
            setCompanyDesignation(null);
            setAddDesignationModal(true)
          }}
        >
          <b> + Add Designation </b>
        </Button>
        )}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={designationsList}
              columns={designationColumns}
            />
          </div>
          {designationsList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" size="md" show={addDesignationModal}>
        <Modal.Header>
          <Modal.Title>Add Designation</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddDesignationModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>Company Designation</h6>
                </label>
                <input
                  id="companyDesignationName"
                  className="form-control"
                  type="text"
                  placeholder="Enter Company Designation"
                  name="companyDesignationName"
                  onChange={(e) => {
                    setCompanyDesignation(e.target.value);
                  }}
                  style={{ padding: "11px" }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => addDesignation()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => setAddDesignationModal(false)}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="md" show={editDesignationModal}>
        <Modal.Header>
          <Modal.Title>Edit Company Designation</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditDesignationModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>Company Designation</h6>
                </label>
                <input
                  id="companyDesignationName"
                  className="form-control"
                  type="text"
                  placeholder="Enter Company Designation"
                  name="companyDesignationName"
                  defaultValue={companyDesignation}
                  onChange={(e) => {
                    setCompanyDesignation(e.target.value);
                  }}
                  style={{ padding: "11px" }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => editDesignation()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => setEditDesignationModal(false)}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Designations;
