import React, { useEffect, useState } from "react";
import { Button} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { getTokenInLocalStorage} from "../services/AuthService";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";

const NonVisitedPaymentsHistory = ({loanNumber}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaymentsList(
      currPage,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paymentsDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDownloadList]);

  const getPaymentsList = (pageNo) => {
    let listData = [];
    setIsLoading(true);
    var payload = {
    
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      loanNumber:loanNumber,

    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getNonVisitedPaymentsByLoanNumber, pageNo, 10),
        payload
      )
      .then((response) => {
        setPaymentsList([]);
        console.log("PaymentResponse", response);
        response?.content?.forEach((element) => {
          let data = {
            id: element?.id,
            collectedById: element.collectedById ? element.collectedById : "-",
            collectedByName: element.collectedByName
              ? element.collectedByName
              : "-",
            customerName: element.customerName ? element.customerName : "-",
            contactNumber: element.contactNumber ? element.contactNumber : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            paymentStatus: element.paymentStatus ? element.paymentStatus : "-",
            totalAmount: element.totalAmount ? element.totalAmount : 0,
            paymentDate: element?.paymentChannel === "Tele Callers" && element?.paymentMode === "Cash"
              ? helper.EpochToOnlyDate(element.createdTime)
              : element?.paymentChannel === "Tele Callers"
                ? helper.EpochToOnlyDate(element.paymentDate) : element?.paymentDate
                  ? helper.EpochToDate(element.paymentDate)
                  : "-",
            approvalStatus: element.approvalStatus
              ? element.approvalStatus
              : "-",
            approvedByEmpId: element.approvedByEmpId
              ? element.approvedByEmpId
              : "-",
            approvedByEmpName: element.approvedByEmpName
              ? element.approvedByEmpName
              : "-",
            approvedTime: element.approvedTime
              ? helper.EpochToDate(element.approvedTime)
              : "-",
            paymentRejectionReason: element.paymentRejectionReason
              ? element.paymentRejectionReason
              : "-",
            receiptNumber: element?.receiptNumber
              ? element?.receiptNumber
              : null,
            transactionId: element.transactionId ? element.transactionId : "-",
            payResponseCode: element.payResponseCode
              ? element.payResponseCode
              : "-",
            subscrptionId: element.subscrptionId ? element.subscrptionId : "-",
            utrNumber: element.utrNumber ? element.utrNumber : "-",
            paymentChannel: element.paymentChannel
              ? element.paymentChannel
              : "-",
            paymentMode: element?.paymentMode ? element.paymentMode : "-",
          };
          listData.push(data);
        });
        setCurrPage(pageNo);
        setPaymentsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const paymentColumns = [
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "customerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile No</span>
        </div>
      ),
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Amount</span>
          <br></br>
          <span>Paid</span>
        </div>
      ),
      dataField: "totalAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Mode</span>
        </div>
      ),
      dataField: "paymentMode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "paymentStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (
          row.paymentStatus === "PAYMENT_SUCCESS" ||
          row.payResponseCode === "SUCCESS" ||
          row.paymentMode === "Cash" ||
          row.paymentMode === "UPI" ||
          row.paymentMode === "Bank Transfer"
        ) {
          return (
            <span className="badge badge-rounded badge-success">Success</span>
          );
        } else if (row.paymentStatus === "PAYMENT_CANCELLED") {
          return (
            <span className="badge badge-rounded badge-danger">Cancelled</span>
          );
        } else if (
          row.paymentStatus === "PAYMENT_ERROR" ||
          row.paymentStatus === "FAILED"
        ) {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else if (row.paymentStatus === "TRANSACTION_NOT_FOUND") {
          return (
            <span className="badge badge-rounded badge-danger">
              Transaction Not Found
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Channel</span>
        </div>
      ),
      dataField: "paymentChannel",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "paymentDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];
  

  const paymentsDownloadData = () => {
    setIsLoading(true);
    var payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      loanNumber:loanNumber,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getNonVisitedPaymentsByLoanNumber, 0, 10),
        payload
      )
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getPaymentsFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getPaymentsFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getPaymentsFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        loanNumber:loanNumber
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getNonVisitedPaymentsByLoanNumber, i, 100),
          payload
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        id: element?.id,
        collectedById: element.collectedById ? element.collectedById : "-",
        collectedByName: element.collectedByName
          ? element.collectedByName
          : "-",
        customerName: element.customerName ? element.customerName : "-",
        contactNumber: element.contactNumber ? element.contactNumber : "-",
        loanNumber: element.loanNumber ? element.loanNumber : "-",
        paymentStatus: getPaymentStatus(element),
        totalAmount: element.totalAmount ? element.totalAmount : 0,
        paymentDate: element?.paymentChannel === "Tele Callers" && element?.paymentMode === "Cash"
              ? helper.EpochToOnlyDate(element.createdTime)
              : element?.paymentChannel === "Tele Callers"
                ? helper.EpochToOnlyDate(element.paymentDate) : element?.paymentDate
                  ? helper.EpochToDate(element.paymentDate)
                  : "-",
        approvalStatus: element.approvalStatus ? element.approvalStatus : "-",
        approvedByEmpId: element.approvedByEmpId
          ? element.approvedByEmpId
          : "-",
        approvedByEmpName: element.approvedByEmpName
          ? element.approvedByEmpName
          : "-",
        approvedTime: element.approvedTime
          ? helper.EpochToDate(element.approvedTime)
          : "-",
        paymentRejectionReason: element.paymentRejectionReason
          ? element.paymentRejectionReason
          : "-",
        transactionId: element.transactionId ? element.transactionId : "-",
        payResponseCode: element.payResponseCode
          ? element.payResponseCode
          : "-",
        subscrptionId: element.subscrptionId ? element.subscrptionId : "-",
        utrNumber: element.utrNumber ? element.utrNumber : "-",
        paymentChannel: element.paymentChannel ? element.paymentChannel : "-",
        paymentMode: element?.paymentMode ? element.paymentMode : "-",
      };
      mgrListData.push(data);
    });
    setPaymentsDownloadList(mgrListData);
  }

  const getPaymentStatus=(row)=>{
    if (
              row.paymentStatus === "PAYMENT_SUCCESS" ||
              row.payResponseCode === "SUCCESS" ||
              row.paymentMode === "Cash" ||
              row.paymentMode === "UPI" ||
              row.paymentMode === "Bank Transfer"
            ) {
              return (
                "Success"
              );
            } else if (row.paymentStatus === "PAYMENT_CANCELLED") {
              return (
                "Cancelled"
              );
            } else if (
              row.paymentStatus === "PAYMENT_ERROR" ||
              row.paymentStatus === "FAILED"
            ) {
              return (
                "Failed"
              );
            } else if (row.paymentStatus === "TRANSACTION_NOT_FOUND") {
              return (
                
                  "Transaction Not Found"
              );
            } else {
              return "-";
            }
       }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Customer Name",
        B: "Customer Mobile No",
        C: "Amount Paid",
        D:"Payment Mode",
        E: "Payment Status",
        F: "Payment Channel",
        G:"Payment Date"
      },
    ];

    paymentsDownloadList?.forEach((row) => {
      mgrTable.push({
        A: row.customerName,
        B: row.contactNumber,
        C: row.totalAmount,
        D:row.paymentMode,
        E: row.paymentStatus,
        F: row.paymentChannel,
        G:row.paymentDate   
      });
    });

    const finalData = [...mgrTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Non Visited Payments");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      loanNumber+"_Non_Visited_Payments_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Non Visited Payments List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Non_Visited_Payments_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const onPageChange = (selectedPage) => {
    console.log("pageNo", selectedPage - 1);
    setCurrPage(selectedPage - 1);
    getPaymentsList(
      selectedPage - 1
    );
  };

  return (
    <div className="card">
      <div className="custom-tab-1">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            // marginTop: "10px",
            marginBottom: "5px",
            marginRight: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              // marginTop: "10px",
              marginBottom: "5px",
              marginRight: "20px",
            }}
          >
        
            <Button
              id="nonVisitedDownlaod"
              style={{
                marginRight: "10px",
                marginLeft: "7px",
                marginBottom: "5px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="success btn-rounded"
              onClick={() => paymentsDownloadData()}
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Download
            </Button>
          </div>
       
        </div>
        <div style={{ margin: "10px" }}>
          <LoadingOverlay
            active={isLoading}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            <div
              style={{
                background: "white",
                margin: "10px",
                marginTop: "-5px",
                overflowX: "auto",
                overflowY: "auto",
                maxHeight: "500px",
              }}
            >
              <BootstrapTable
                keyField="id"
                data={paymentsList}
                columns={paymentColumns}
                onSelect={onPageChange}
              />
            </div>
            {paymentsList.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements}
                pageSize={10}
                defaultActivePage={currPage + 1}
                onSelect={onPageChange}
              />
            </div>
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
};

export default NonVisitedPaymentsHistory;
