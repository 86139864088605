import { EpochToDate } from "../../CommonComponents/Helper";
import { hasAccess } from "../../services/AuthService";
// import { convertToLocalDateTime2 } from "../utils";

function FiAllocationPdfReport(props) {
  const styles = {
    headerText: {
      justifyContent: "center",
      backgroundColor: "rgb(255 227 139)",
      width: "100%",
      fontWeight: "bold",
      padding: 7,
      paddingBottom:'-5px',
      fontSize: "10px",
      textAlign: "center",
    },
    table: {
      width: "100%",
      border: "1px solid black",
    },

    td: {
      padding: 3,
      border: "1px solid black",
      fontSize: "9px",
      width: "25%",
      fontWeight: "bold",
    },
    tdValue: {
      padding: 3,
      border: "1px solid black",
      color: "blue",
      fontSize: "9px",
      width: "25%",
      fontWeight: "bold",
    },
    imageAlign: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
      // paddingTop: 10,
    },
    headerLogo: {
      width: 50,
      height: 50,
      float: "right",
      marginBottom: 5,
      clear: "both",
    },
    footerLogo: {
      width: 30,
      height: 30,
      float: "right",
      marginBottom: 5,
      borderRadius: "50%",
      clear: "both",
    },
  };
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const {
    allocationsDetails,
    selectedRow,
    agencyRemarks,
    type,
    employeeVisitsData,
    imageOneUrl,
    imageTwoUrl,
    imageThreeUrl,
    imageFourUrl,
    imageFiveUrl,
    dedupeSignatureUrl,
  } = props;
  let data = allocationsDetails.length > 0 ? allocationsDetails[0] : null;
  let remarks = agencyRemarks.length > 0 ? agencyRemarks[0] : null;
  let employeeVisits =
    employeeVisitsData.length > 0 ? employeeVisitsData[0] : null;
  return (
    <div>
      {data !== null ? (
        <div id="pdf" style={{ display: "none" }}>
          <div className="container">
            <br />
            <div>
              <img
                src={userData.autoFinanceOrganization.organizationImage}
                style={styles.headerLogo}
                alt="logo"
              ></img>
            </div>
            <br />
            <hr style={{ marginTop: 40 }} />
            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>
                  <div style={{display:'flex', flexDirection:'column',rowGap:3}}>
                  <span>{userData.autoFinanceOrganization.organizationName}{" "}</span>
                  <span>{userData.autoFinanceOrganization.address}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={styles.headerText}>
                  Field Investigation report of Applicant Residence for{" "}
                  {type === "EMPLOYEES"
                    ? selectedRow.client
                    : selectedRow.agency_name}
                </td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Application Number</td>
                <td style={styles.tdValue}>{selectedRow.application_number}</td>
                <td style={styles.td}>Request Date & Time</td>
                <td style={styles.tdValue}>
                  {selectedRow.created_time
                    ? EpochToDate(selectedRow.created_time)
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Client</td>
                <td style={styles.tdValue}>{selectedRow.client}</td>
                <td style={styles.td}>FI Type</td>
                <td style={styles.tdValue}>{selectedRow.fi_type}</td>
              </tr>
              <tr>
                <td style={styles.td}>Loan Type/Product Type</td>
                <td style={styles.tdValue}>
                  {selectedRow.product_type
                    ? selectedRow.product_type
                    : selectedRow.loan_type}
                </td>
                <td style={styles.td}>CPV Verification Geo Limit</td>
                <td style={styles.tdValue}>
                  {selectedRow.cpv_verification_geo_limit}
                </td>
                {/* <td style={styles.td}>Product Name</td>
                <td style={styles.tdValue}>{selectedRow.product_name} </td> */}
              </tr>
              <tr>
                <td style={styles.td}>Client State</td>
                <td style={styles.tdValue}>{selectedRow.client_state}</td>
                <td style={styles.td}>Client Branch</td>
                <td style={styles.tdValue}>{selectedRow.client_branch}</td>
              </tr>
              <tr>
                <td style={styles.td}>Application (Agency)</td>
                <td style={styles.tdValue}>
                  {type === "EMPLOYEES" ? "NA" : selectedRow.agency_name}
                </td>
                <td style={styles.td}>Verifier's Name</td>
                <td style={styles.tdValue}>
                  {data.verifiers_emp_id
                    ? data.verifiers_emp_id + " - " + data.verifiers_name
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Visit Date & Time </td>
                <td style={styles.tdValue}>
                  {employeeVisits && employeeVisits.date_time
                    ? EpochToDate(employeeVisits.date_time)
                    : ""}
                </td>
                <td style={styles.td}>FI Submitted Emp Id/Emp Name</td>
                <td style={styles.tdValue}>
                  {selectedRow.employee_id + " - " + selectedRow.emp_name}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>DateTime Of Report</td>
                <td style={styles.tdValue}>
                  {data.date_of_report && data.date_of_report !== "0"
                    ? EpochToDate(data?.date_of_report)
                    : ""}
                </td>
                <td style={styles.td}>Latitude</td>
                <td style={styles.tdValue}>
                  {employeeVisits && employeeVisits.latitude}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Longitude </td>
                <td style={styles.tdValue}>
                  {employeeVisits && employeeVisits.longitude}
                </td>
                <td style={styles.td}>No. of Visits</td>
                <td style={styles.tdValue}>{data.no_of_visits}</td>
              </tr>
              <tr>
                <td style={styles.td}>Visit Address</td>
                <td style={styles.tdValue} colSpan={3}>
                  {hasAccess(104)
                    ? selectedRow.address_line1
                    : employeeVisits && employeeVisits.address}
                </td>
              </tr>
            </table>

            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>Primary Information</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Applicant's Name:</td>
                <td style={styles.tdValue}>{selectedRow.customer_name}</td>
                <td style={styles.td}>Applicant Type</td>
                <td style={styles.tdValue}>{data.applicant_type}</td>
              </tr>
              <tr>
                <td style={styles.td}>Name of Co-Applicant</td>
                <td style={styles.tdValue}>{selectedRow?.co_applicant_name}</td>
                <td style={styles.td}>Father/Spouse Name</td>
                <td style={styles.tdValue}>{selectedRow?.father_name}</td>
              </tr>
              <tr>
                <td style={styles.td}>Residence address</td>
                <td style={styles.tdValue}>{selectedRow.address_line1}</td>
                <td style={styles.td}>City</td>
                <td style={styles.tdValue}>{selectedRow.city}</td>
              </tr>
              <tr>
                <td style={styles.td}>State</td>
                <td style={styles.tdValue}>{selectedRow.state}</td>
                <td style={styles.td}>Pin Code</td>
                <td style={styles.tdValue}>{selectedRow.postal_code}</td>
              </tr>
              <tr>
                <td style={styles.td}>Landmark</td>
                <td style={styles.tdValue}>{selectedRow.nearby_landmark}</td>
                <td style={styles.td}>Contact numbers provided </td>
                <td style={styles.tdValue}>{selectedRow.contact_no}</td>
              </tr>
              <tr>
                <td style={styles.td}>Actual Address Verification Done</td>
                <td style={styles.tdValue}>{data.actual_address_verified}</td>
                <td style={styles.td}>Distance</td>
                <td style={styles.tdValue}>
                  {data.distance ? data.distance + "Kms" : data.distance}
                </td>
              </tr>

              <tr>
                <td style={styles.td}>Contacted Person Name </td>
                <td style={styles.tdValue}>{data.contacted_person_name}</td>
                <td style={styles.td}>Relationship with applicant</td>
                <td style={styles.tdValue}>
                  {data.relationship_with_applicant}
                </td>
              </tr>
              <tr>
                {/* <td style={styles.td}>Product Applied </td>
                <td style={styles.tdValue}>{data.product_applied}</td> */}
                <td style={styles.td}>End User of the Product : </td>
                <td style={styles.tdValue} colSpan={3}>
                  {data.enduser_of_product}
                </td>
              </tr>

              {/* <tr>
                <td style={styles.td}>Visit Address</td>
                <td style={styles.tdValue} colSpan={3}>
                  {employeeVisits && employeeVisits.address}
                </td>
              </tr> */}
            </table>

            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>Personal Information</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Applicant's Age</td>
                <td style={styles.tdValue}>
                  {data.applicant_age !== "0" && data.applicant_age !== null
                    ? data.applicant_age + " Years"
                    : ""}
                </td>
                <td style={styles.td}>No. of Family Members</td>
                <td style={styles.tdValue}>
                  {data.family_members_count ? data.family_members_count : ""}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>No of Earning Members</td>
                <td style={styles.tdValue}>
                  {data.no_of_earning_members ? data.no_of_earning_members : ""}
                </td>
                <td style={styles.td}>No of Depedents</td>
                <td style={styles.tdValue}>{data.no_of_dependents}</td>
              </tr>
              <tr>
                <td style={styles.td}>Marital Status</td>
                <td style={styles.tdValue}>{data.marital_status}</td>
                <td style={styles.td}>Applicant's Working Status</td>
                <td style={styles.tdValue}>{data.applicant_working}</td>
              </tr>
              <tr>
                <td style={styles.td}>Applicant's Company/Business Name</td>
                <td style={styles.tdValue}>{data.applicant_company_name}</td>
                <td style={styles.td}>Applicant's Company/Business Address</td>
                <td style={styles.tdValue}>{data.applicant_company_address}</td>
              </tr>
              <tr>
                <td style={styles.td}>Applicant's Monthly/Annual Income</td>
                <td style={styles.tdValue}>
                  {data.occupation_income_of_applicant}
                </td>
                <td style={styles.td}>Spouse Working Status</td>
                <td style={styles.tdValue}>{data.spouse_working}</td>
              </tr>
              <tr>
                <td style={styles.td}>Spouse Company/Business Name</td>
                <td style={styles.tdValue}>{data.spouse_company_name}</td>
                <td style={styles.td}>Spouse Company/Business Address</td>
                <td style={styles.tdValue}>{data.spouse_company_address}</td>
              </tr>
              <tr>
                <td style={styles.td}>Spouse Monthly/Annual Income</td>
                <td style={styles.tdValue}>
                  {data.occupation_income_of_spouse}
                </td>
                <td style={styles.td}>
                  Any other loans? If Yes, pls provide details of product,
                  company, amount and EMI
                </td>
                {data.other_loans_info === "Yes" ? (
                  <td style={styles.tdValue}>
                    {data.other_loans_product},{data.other_loans_loan_no},
                    {data.other_loans_amount}, {data.other_loans_emi}
                  </td>
                ) : (
                  <td style={styles.tdValue}>NA</td>
                )}
              </tr>

              <tr></tr>
            </table>

            {/* </div> */}

            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <br />
              <span
                style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
              >
                Powered By
              </span>{" "}
              <img
                src="../logo192.png"
                style={styles.footerLogo}
                alt="logo"
              ></img>
            </div>
          </div>
          <div className="container" id="remarks" style={{ height: 1000 }}>
            <br />
            <div>
              <img
                src={userData.autoFinanceOrganization.organizationImage}
                style={styles.headerLogo}
                alt="logo"
              ></img>
            </div>
            <hr style={{ marginTop: 60 }} />
            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>Residential Information</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Name verified on house Exterior</td>
                <td style={styles.tdValue}>
                  {data.house_exterior_name_verification}
                </td>
                <td style={styles.td}>How Co-operative was the Customer</td>
                <td style={styles.tdValue}>{data.customer_cooperation}</td>
              </tr>
              <tr>
                <td style={styles.td}>Entry Allowed Inside the House</td>
                <td style={styles.tdValue}>{data.entry_in_house}</td>
                <td style={styles.td}>No of years of stay in city</td>
                <td style={styles.tdValue}>
                  {data.city_stay_inyears
                    ? data.city_stay_inyears + "years"
                    : data.city_stay_inyears}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Number of years of stay at residence </td>
                <td style={styles.tdValue}>
                  {data.residence_stay_inyears
                    ? data.residence_stay_inyears + "years"
                    : data.residence_stay_inyears}{" "}
                </td>
                <td style={styles.td}>Number of months of stay at residence</td>
                <td style={styles.tdValue}>
                  {data.residence_stay_inmonths
                    ? data.residence_stay_inmonths + "months"
                    : data.residence_stay_inmonths}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Ownership</td>
                <td style={styles.tdValue}>
                  {data.residence_ownership !== "Others"
                    ? data.residence_ownership
                    : data?.ownership_others}
                </td>
                <td style={styles.td}>Rent Amount</td>
                <td style={styles.tdValue}>
                  {data.rent_amount ? data.rent_amount : ""}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Permanent Address</td>
                <td style={styles.tdValue}>{data.permanent_address}</td>
                <td style={styles.td}>Area Locality</td>
                <td style={styles.tdValue}>{data.area_locality}</td>
              </tr>
              <tr>
                <td style={styles.td}>Approach to House</td>
                <td style={styles.tdValue}>{data.house_approach}</td>
                <td style={styles.td}>Locating Address</td>
                <td style={styles.tdValue}>{data.house_location}</td>
              </tr>

              <tr>
                <td style={styles.td}>Nearest Landmark</td>
                <td style={styles.tdValue}>{data.nearest_land_mark}</td>
                <td style={styles.td}>Type of Accommodation</td>
                <td style={styles.tdValue}>{data.accommodation_type}</td>
              </tr>
              <tr>
                <td style={styles.td}>Construction of House</td>
                <td style={styles.tdValue}>{data.construction_of_house}</td>
                <td style={styles.td}>Residence Carpet Area ( Sq Ft) </td>
                <td style={styles.tdValue}>{data.residence_carpet_area}</td>
              </tr>
              <tr>
                <td style={styles.td}>House Interior Condition</td>
                <td style={styles.tdValue}>{data.interior_condition}</td>
                <td style={styles.td}>House Exterior Condition</td>
                <td style={styles.tdValue}>{data.exterior_condition}</td>
              </tr>

              <tr>
                <td style={styles.td}>Neighborhood (Locality)</td>
                <td style={styles.tdValue}>{data.locality}</td>
                <td style={styles.td}>Standard of Living</td>
                <td style={styles.tdValue}>{data.living_standard}</td>
              </tr>
              <tr>
                <td style={styles.td}> Address & ID Proof Sighted</td>
                <td style={styles.tdValue}>{data.address_proof_sighted}</td>
                <td style={styles.td}>Type of Address & ID Proof</td>
                <td style={styles.tdValue}>{data.address_proof_type}</td>
              </tr>

              <tr>
                <td style={styles.td}>Assets seen at residence</td>
                <td style={styles.tdValue}>{data.vehicle_seen_at_residence}</td>
                <td style={styles.td}>List the Assets at residence</td>
                <td style={styles.tdValue}>{data.residence_assets}</td>
              </tr>
              <tr></tr>
              {/* <tr>
                <td style={styles.td}>Third Party Reference 1 Name</td>
                <td style={styles.tdValue}>{data.neighbor_reference1}</td>
                <td style={styles.td}>Confirmation</td>
                <td style={styles.tdValue}>
                  {data.neighbor_reference1_confirmation}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Third Party Reference 2 Name</td>
                <td style={styles.tdValue}>{data.neighbor_reference2}</td>
                <td style={styles.td}>Confirmation</td>
                <td style={styles.tdValue}>
                  {data.neighbor_reference2_confirmation}
                </td>
              </tr> */}

              <tr>
                <td style={styles.td}>
                  Affiliation Seen for any Political Party
                </td>
                <td style={styles.tdValue}>
                  {data.political_party_affilication}
                </td>
                <td style={styles.td}>Affiliation Political Party Remarks</td>
                <td style={styles.tdValue}>{data.political_remarks}</td>
              </tr>
              <tr>
                <td style={styles.td}>Caution Profile</td>
                <td style={styles.tdValue}>{data.caution_profile}</td>
                <td style={styles.td}>Caution Profile</td>
                <td style={styles.tdValue}>{data.caution_profile_remarks}</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>TPC/Neighbour Check</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>
                  Neighbour/ Third Party Reference 1 Name
                </td>
                <td style={styles.tdValue}>{data.neighbor_reference1}</td>
                <td style={styles.td}>
                  Neighbour/ Third Party Reference 2 Name
                </td>
                <td style={styles.tdValue}>{data.neighbor_reference2}</td>
              </tr>
              <tr>
                <td style={styles.td}>Neighbour/ThirdParty 1 Feedback</td>
                <td style={styles.tdValue}>
                  {data.neighbor_reference1_confirmation}
                </td>
                <td style={styles.td}>Neighbour/ThirdParty 2 Feedback</td>
                <td style={styles.tdValue}>
                  {data.neighbor_reference2_confirmation}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Neighbour/TP 1 check status-bar</td>
                <td style={styles.tdValue}>
                  {data.neighbor_reference1 &&
                    "Tpc Neighbor is " +
                      data.neighbor_reference1 +
                      " has confirmed the Applicant name and Staying details"}
                </td>
                <td style={styles.td}>Neighbour/TP 2 check status-bar</td>
                <td style={styles.tdValue}>
                  {data.neighbor_reference2 &&
                    "Tpc Neighbor is " +
                      data.neighbor_reference2 +
                      " has confirmed the Applicant name and Staying details"}
                </td>
              </tr>
            </table>
            {data.door_locked_status === "Yes" && (
              <table style={styles.table}>
                <tr>
                  <td style={styles.headerText}>
                    - IF Entry Denied / Door locked, then Third Party's
                    confirmation on -
                  </td>
                </tr>
              </table>
            )}
            {data.door_locked_status === "Yes" && (
              <table style={styles.table}>
                <tr>
                  <td style={styles.td}>
                    • App’s stay, Ownership status, Stability and Contacted
                    Person Number
                  </td>
                  <td style={styles.tdValue}>
                    {data.door_locked_status === "Yes"
                      ? data.neighbor_conf_address +
                        ", " +
                        data.neighbor_conf_ownership +
                        ", " +
                        data.neighbor_conf_years_stay +
                        ", " +
                        data.contacted_person_number
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td style={styles.td}>
                    {" "}
                    • Family members and App’s approx age
                  </td>
                  <td style={styles.tdValue}>
                    {data.door_locked_status === "Yes"
                      ? data.neighbor_conf_family_count +
                        ", " +
                        data.neighbor_conf_applicant_age +
                        " years"
                      : "NA"}
                  </td>
                </tr>
              </table>
            )}
            {data.calls_made_to_custoomer !== 0 && (
              <table style={styles.table}>
                <tr>
                  <td style={styles.headerText}>Address Untraceble</td>
                </tr>
              </table>
            )}
            {data.calls_made_to_custoomer !== 0 && (
              <table style={styles.table}>
                <tr>
                  <td style={styles.td}>No. Of Calls Made To Customer</td>
                  <td style={styles.tdValue} colSpan={3}>
                    {data.calls_made_to_custoomer
                      ? data.calls_made_to_custoomer
                      : ""}
                  </td>
                  {/* <td style={styles.td}>Remarks 1</td>
                <td style={styles.tdValue}>{remarks !== null && remarks.remarks_1}</td> */}
                </tr>
              </table>
            )}

            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>FI Agency's Remarks:</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>Agency Remarks 1</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_1}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 2</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_2}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 3</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_3}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 4</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_4}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 5</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_5}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 6</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_6}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 7</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_7}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 8</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_8}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Agency Remarks 9</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_9}
                </td>
              </tr>

              <tr>
                <td style={styles.td}>Agency Remarks 10</td>
                <td style={styles.tdValue}>
                  {remarks !== null && remarks.remarks_10}
                </td>
              </tr>
              <tr>
                <td style={styles.td}>Video Url</td>
                <td style={styles.tdValue}>{data.video_1}</td>
              </tr>
            </table>
            {/* <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>Dedupe Check</td>
              </tr>
            </table>
            <table style={styles.table}>
              <tr>
                <td style={styles.td}>
                  Dedupe Check conducted on Negative Database
                </td>
                <td style={styles.tdValue}>{data.dedupe_check_on_negative}</td>
                <td style={styles.td}>Dedupe Result</td>
                <td style={styles.tdValue}>{data.dedupe_result}</td>
              </tr>
              <tr>
                <td style={styles.td}>Residence FI Result </td>
                <td style={styles.tdValue}>{data.dedupe_residence_result}</td>
                <td style={styles.td}>
                  {" "}
                  <img
                    crossorigin="*"
                    src={dedupeSignatureUrl}
                    alt="dedupe-signature"
                    style={{ width: 150, height: 150 }}
                  />
                </td>
                <td style={styles.tdValue}></td>
              </tr>
              <tr>
                <td style={styles.td}>If Negative, Reason 1 Negative FI</td>
                <td style={styles.tdValue}>{data.dedupe_negative_reason1}</td>
                <td style={styles.td}></td>
                <td style={styles.tdValue}></td>
              </tr>
              <tr>
                <td style={styles.td}>If Negative, Reason 2 Negative FI</td>
                <td style={styles.tdValue}>{data.dedupe_negative_reason2}</td>
                <td style={styles.td}></td>
                <td style={styles.tdValue}></td>
              </tr>
              <tr>
                <td style={styles.td}>Signature & Seal of Agency</td>
                <td style={styles.tdValue}></td>
                <td style={styles.td}></td>
                <td style={styles.tdValue}></td>
              </tr>
            </table> */}
            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <br />
              <span
                style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
              >
                Powered By
              </span>{" "}
              <img
                src="../logo192.png"
                style={styles.footerLogo}
                alt="logo"
              ></img>
            </div>
          </div>
          <div className="container" id="remarks">
            <br />
            <div>
              <img
                src={userData.autoFinanceOrganization.organizationImage}
                style={styles.headerLogo}
                alt="logo"
              ></img>
            </div>
            <hr style={{ marginTop: 60 }} />

            <table style={styles.table}>
              <tr>
                <td style={styles.headerText}>
                  {" "}
                  {data.dedupe_check_on_negative ||
                  data.dedupe_result ||
                  data.dedupe_negative_reason1 ||
                  data.dedupe_negative_reason2
                    ? "Dedupe Check"
                    : "FI Result"}
                </td>
              </tr>
            </table>
            <table style={styles.table}>
              {(data.dedupe_check_on_negative ||
                data.dedupe_result ||
                data.dedupe_negative_reason1 ||
                data.dedupe_negative_reason2) && (
                <>
                  <tr>
                    <td style={styles.td}>
                      Dedupe Check conducted on Negative Database
                    </td>
                    <td style={styles.tdValue}>
                      {data.dedupe_check_on_negative}
                    </td>
                    <td style={styles.td}>Dedupe Result</td>
                    <td style={styles.tdValue}>{data.dedupe_result}</td>
                  </tr>
                  <tr>
                    <td style={styles.td}>If Negative, Reason 1 Negative FI</td>
                    <td style={styles.tdValue}>
                      {data.dedupe_negative_reason1}
                    </td>
                    <td style={styles.td}>If Negative, Reason 2 Negative FI</td>
                    <td style={styles.tdValue}>
                      {data.dedupe_negative_reason2}
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td style={styles.td}>FI Result </td>
                <td style={styles.tdValue}>{data.dedupe_residence_result}</td>
                <td style={styles.td}>Remarks</td>
                <td style={styles.tdValue}>{data.dedupe_remarks}</td>
              </tr>
              <tr>
                <td style={styles.td}>Signature & Seal of Agency</td>
                <td style={styles.tdValue}>
                  {" "}
                  <img
                    crossorigin="*"
                    src={dedupeSignatureUrl}
                    alt="dedupe-signature"
                    style={styles.imageAlign}
                  />
                </td>
                <td style={styles.td}></td>
                <td style={styles.tdValue}></td>
              </tr>
            </table>
            {imageOneUrl && (
              <div id="images">
                <br />
                {/* <div>
                  <img
                    src={userData.autoFinanceOrganization.organizationImage}
                    style={styles.headerLogo}
                    alt="logo"
                  ></img>
                </div> */}
                {/* <hr style={{ marginTop: 60 }} /> */}
                <br />
                <img
                  crossorigin="*"
                  src={imageOneUrl}
                  alt="fi-one"
                  style={styles.imageAlign}
                />
                {/* <div style={{ float: "right" }}>
                <br />
                <span style={{ paddingRight: 5, fontSize: 10 }}>
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div> */}
              </div>
            )}
            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <br />
              <span
                style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
              >
                Powered By
              </span>{" "}
              <img
                src="../logo192.png"
                style={styles.footerLogo}
                alt="logo"
              ></img>
            </div>
          </div>

          {/* {imageOneUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageOneUrl}
                alt="fi-one"
                style={styles.imageAlign}
              />
              <div style={{ float: "right" }}>
                <br />
                <span style={{ paddingRight: 5, fontSize: 10 }}>
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )} */}
          {imageTwoUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageTwoUrl}
                alt="fi-two"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
          {imageThreeUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageThreeUrl}
                alt="fi-three"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
          {imageFourUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageFourUrl}
                alt="fi-three"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
          {imageFiveUrl && (
            <div className="container" id="images">
              <br />
              <div>
                <img
                  src={userData.autoFinanceOrganization.organizationImage}
                  style={styles.headerLogo}
                  alt="logo"
                ></img>
              </div>
              <hr style={{ marginTop: 60 }} />
              <br />
              <img
                crossorigin="*"
                src={imageFiveUrl}
                alt="fi-three"
                style={styles.imageAlign}
              />
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <br />
                <span
                  style={{ paddingRight: 5, fontSize: 10, marginBottom: "6px" }}
                >
                  Powered By
                </span>{" "}
                <img
                  src="../logo192.png"
                  style={styles.footerLogo}
                  alt="logo"
                ></img>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div id="pdf"></div>
      )}
    </div>
  );
}

export default FiAllocationPdfReport;
