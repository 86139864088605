import React, { useEffect, useState, 
  // useRef
 } from "react";
import SearchBar from "../CommonComponents/SearchBar";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import {
  getTokenInLocalStorage,
  hasAccess,
  hasAccessFromTeamPrivilege,
  //   isAdmin,
} from "../services/AuthService";
import { Button, Modal, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import Spinner from "react-bootstrap/Spinner";
// import { jsPDF }  from "jspdf"
// import html2canvas from "html2canvas";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory, {
//   PaginationProvider,
//   PaginationListStandalone,
// } from "react-bootstrap-table2-paginator";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
// import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
// import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { EpochToDate, getPriority, SalesCallsCountData } from "../CommonComponents/Helper";
import Select from "react-select";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import { StartCall } from "../CommonComponents/TelecallingMethods";
// import swal from "sweetalert";
// import PIOPIY from "piopiyjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallAudioModal,
  setIsModalMinimised,
  setAllocationId,
  setIsSalesProgressiveDialing,
} from "../store/actions/CallingActions";
import {
  setSalesAllocationsList,
  setIsCallNotActive,
} from "../store/actions/MySalesAllocationAction";

import AddRemarks from "../RequestPayments/AddRemarks";
import {
  setCallReportModal,
  setMobileNo,
} from "../store/actions/MyAllocationActions";
import CallSummary from "../CustomerManagement/CallSummary";
// import AddAdditionalDetails from "../SalesLeads/AddAdditionalDetails";

const NewMySalesAllocations = () => {
  // var piopiy = new PIOPIY({
  //   name: "CLU",
  //   debug: false,
  //   autoplay: true,
  //   ringTime: 60,
  // });
  let userLoginDetails = getTokenInLocalStorage();
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //   const [skip, setSkip] = useState(9);
  const [addRemarksBasicModal, setAddRemarksBasicModal] = useState(false);
  const [remarks, setRemarks] = useState([]);
  const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);
  const [addRemarksAllocationId, setAddRemarksAllocationId] = useState("");
  const [editSalesAllocationModal, setEditSalesAllocationModal] =
    useState(false);
  const [allocationDetails, setAllocationDetails] = useState(null);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [pinCodes, setPinCodes] = useState([]);
  const [selectedProspectName, setSelectedProspectName] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedPinCode, setSelectedPinCode] = useState(null);
  const [addAdditionalDetailsModal, setAddAdditionalDetailsModal] =
    useState(false);

  const {
    isOnGoingCall,
    // campaignName,
    webRtcMobile,
    piopiy,
    isCallActive,
    isIncomingCall,
    isSalesProgressiveDialing,
    salesContinueDialing,
  } = useSelector((state) => state.calls);
  const { salesAllocationsList, isCallNotActive, salesCallsCount } = useSelector(
    (state) => state.mySalesAllocations
  );
  const { callReportModal, mobileNo } = useSelector(
    (state) => state.myAllocations
  );

  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview("My Sales Allocations");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SalesCallsCountData(dispatch)
    getSalesAllocationsList(start, end, searchKey);
    dispatch(setIsSalesProgressiveDialing(false));
    return () => {
      dispatch(setSalesAllocationsList([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("right");
    if (isSalesProgressiveDialing === true) progressiveDialing();
    // eslint-disable-next-line
  }, [isSalesProgressiveDialing]);

  useEffect(() => {
    // console.log("left");
    if (isSalesProgressiveDialing === true) progressiveDialing();
    // eslint-disable-next-line
  }, [salesContinueDialing]);

  const progressiveDialing = () => {
    console.log("calling started");
    if (isSalesProgressiveDialing === true) {
      if (salesAllocationsList?.length > 0 && isOnGoingCall === false && isIncomingCall===false) {
        // if (salesAllocationsList[0]?.campaignCallIntiated === false) {
        StartCall(
          piopiy,
          dispatch,
          salesAllocationsList[0]?.phoneNumber,
          false,
          salesAllocationsList[0]?.id,
          salesAllocationsList[0] ? salesAllocationsList[0] : null,
          "Sales",
          isCallActive
        );
        // } else {
        //   dispatch(setIsSalesProgressiveDialing(false));
        //   return swal("All The Customers Have Been Called For This Campaign", {
        //     icon: "warning",
        //     dangerMode: false,
        //   });
        // }
      }
    }
  };

  useEffect(() => {
    paymentsDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDownloadList]);

  //   useEffect(() => {
  //     dispatch(setFilteredData(tempData));
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [tempData]);

  async function getSalesAllocationsList(start, end, key) {
    try {
      dispatch(setSalesAllocationsList([]));
      const payload = {
        startTime: start,
        endTime: end,
        filters: [],
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        teleCallerUserId:
          key === "" ? userLoginDetails.autoFinanceUser.userId : "",
        telecallerAllocations: false,
        searchKey: key,
        status: "",
        // campaignName: campaignName,
        sorts: [],
      };
      const response = await moduleApi.postData(
        apiCall.getAllSalesAllocations,
        payload
      );
      const formattedData = response?.map((element, index) => {
        return {
          callDisposition: element.callDisposition
            ? element.callDisposition
            : "-",
          allocationStatus: element.allocationStatus
            ? element.allocationStatus
            : "-",
          callDispositionSubstatus: element.callDispositionSubstatus
            ? element.callDispositionSubstatus
            : "-",
          callStatus: element.callStatus ? element.callStatus : "-",
          city: element.city ? element.city : "-",
          empId: element.empId ? element.empId : "-",
          empName: element.empName ? element.empName : "-",
          email: element.email ? element.email : "-",
          lastCallUpdateTime: element.lastCallUpdateTime
            ? helper.convertEpochToTime2(element.lastCallUpdateTime)
            : "-",
          callsToday: element.callsToday ? element.callsToday : 0,
          customerName: element.customerName ? element.customerName : "-",
          leadRate: element.leadRate ? element.leadRate : 0,
          leadStatus: element.leadStatus ? element.leadStatus : "-",
          dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
          excelRowNumber: element.excelRowNumber ? element.excelRowNumber : "-",
          id: element.id ? element.id : "-",
          organizationId: element.organizationId ? element.organizationId : "-",
          phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
          pinCode: element.pinCode ? element.pinCode : "-",
          sourceChannelId: element.sourceChannelId
            ? element.sourceChannelId
            : "-",
          sourcingChannel: element.sourcingChannel
            ? element.sourcingChannel
            : "-",
          state: element.state ? element.state : "-",
          telecallerEmpId: element.telecallerEmpId
            ? element.telecallerEmpId
            : "-",
          telecallerName: element.telecallerName ? element.telecallerName : "-",
          totalCalls: element.totalCalls ? element.totalCalls : 0,
          userId: element.userId ? element.userId : "-",
          priority: element.priority,
          validationFailed: element.validationFailed
            ? element.validationFailed
            : "-",
        };
      });
      //   console.log("formatted", formattedData);
      dispatch(setSalesAllocationsList(formattedData));
    } catch (error) {
      // Handle errors gracefully
      console.error("Error fetching data:", error);
      // Dispatch appropriate error actions if needed
    } finally {
      setIsLoading(false);
    }
  }

  const leadRateOptions = [
    { label: "Hot", value: 1 },
    { label: "Warm", value: 2 },
    { label: "Cold", value: 3 },
  ];

  const filterValue = [
    {
      name: "sourcingChannel",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    { name: "priority", operator: "startsWith", type: "string", value: "" },

    { name: "customerName", operator: "startsWith", type: "string", value: "" },
    {
      name: "phoneNumber",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    // { name: "callsToday", operator: "gte", type: "number", value: 0 },
    // { name: "totalCalls", operator: "gte", type: "number", value: 0 },
    // {
    //   name: "lastCallUpdateTime",
    //   operator: "startsWith",
    //   type: "string",
    //   value: "",
    // },
    {
      name: "telecallerEmpId",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "telecallerName",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "city",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "state",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "pinCode",
      operator: "startsWith",
      type: "string",
      value: "",
    },

    { name: "callStatus", operator: "startsWith", type: "string", value: "" },
    {
      name: "callDisposition",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "callDispositionSubstatus",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "allocationStatus",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    { name: "dateTime", operator: "startsWith", type: "string", value: "" },
  ];

  const gridStyle = { minHeight: 550, marginTop: 10 };
  const headerColor = {
    backgroundColor: "rgb(243,240,249)",
    textAlign: "center",
  };

  const emptyText = (
    <div className="d-flex justify-content-center">
      <img src={NO_DATA_FOUND} alt="" />
    </div>
  );

  //   const updateSkipIndex = (value) => {
  //     dispatch(setSkipIndex(value));
  //   };

  //   const downloadBlob = (blob, fileName = "grid-data.csv") => {
  //     const link = document.createElement("a");
  //     const url = URL.createObjectURL(blob);

  //     link.setAttribute("href", url);
  //     link.setAttribute("download", fileName);
  //     link.style.position = "absolute";
  //     link.style.visibility = "hidden";

  //     document.body.appendChild(link);

  //     link.click();

  //     document.body.removeChild(link);
  //   };

  //   const exportCSV = () => {
  //     console.log(gridRef);
  //     const columns = gridRef.current.visibleColumns;

  //     const header = columns.map((c) => c.header).join(SEPARATOR);
  //     const rows = gridRef.current.dataSource.map((data) =>
  //       columns.map((c) => data[c.id]).join(SEPARATOR)
  //     );

  //     const contents = [header].concat(rows).join("\n");
  //     const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

  //     downloadBlob(blob);
  //   };

  //   const SEPARATOR = ",";

  const columns = [
    {
      header: "Edit",
      name: "edit",
      textAlign: "center",
      defaultWidth: 80,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return editOptionTemplate(data);
      },
    },

    {
      header: "Priority",
      name: "priority",
      textAlign: "center",
      defaultWidth: 100,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        if (data.priority !== "-") {
          return (
            <span
              className={getLeadTypeClassName(data.priority)}
              style={{ verticalAlign: "middle" }}
            >
              {data.priority}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      header: "Prospect Name",
      name: "customerName",
      textAlign: "center",
      defaultWidth: 180,
      headerProps: { style: headerColor },
    },
    {
      header: "Prospect Number",
      name: "phoneNumber",
      textAlign: "center",
      defaultWidth: 215,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return callOptionTemplate(data);
      },
    },
    {
      header: "Additional Details",
      name: "addAdditionalDetails",
      textAlign: "center",
      defaultWidth: 170,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return addAdditionalDetailsTemplate(data);
      },
    },
    {
      header: "Today's Calls",
      name: "callsToday",
      textAlign: "center",
      defaultWidth: 125,
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "Total Calls",
      name: "totalCalls",
      textAlign: "center",
      defaultWidth: 115,
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "Last Called At",
      name: "lastCallUpdateTime",
      textAlign: "center",
      defaultWidth: 185,
      headerProps: { style: headerColor },
    },

    {
      header: "Sourcing Channel",
      name: "sourcingChannel",
      textAlign: "center",
      defaultWidth: 160,
      headerProps: { style: headerColor },
    },
    {
      header: "TeleCaller Emp Id",
      name: "telecallerEmpId",
      textAlign: "center",
      defaultWidth: 120,
      headerProps: { style: headerColor },
    },
    {
      header: "TeleCaller Emp Name",
      name: "telecallerName",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return assignOptionTemplate(data);
      },
    },
    {
      header: "City",
      name: "city",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
    },
    {
      header: "State",
      name: "state",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
    },
    {
      header: "Pin Code",
      name: "pinCode",
      textAlign: "center",
      defaultWidth: 120,
      headerProps: { style: headerColor },
    },
    {
      header: "Call Status",
      name: "callStatus",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
    },
    {
      header: "Call Disposition Status",
      name: "callDisposition",
      textAlign: "center",
      defaultWidth: 180,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return callDispositionStatusTemplate(data);
      },
    },
    {
      header: "Call Disposition Sub Status",
      name: "callDispositionSubstatus",
      textAlign: "center",
      defaultWidth: 200,
      headerProps: { style: headerColor },
    },
    {
      header: "Allocation Status",
      name: "allocationStatus",
      textAlign: "center",
      defaultWidth: 160,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return allocationStatusTemplate(data);
      },
    },
    {
      header: "Assigned At",
      name: "dateTime",
      textAlign: "center",
      defaultWidth: 180,
      headerProps: { style: headerColor },
    },
    {
      header: "Call History",
      name: "callHistory",
      textAlign: "center",
      defaultWidth: 115,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return callHistoryTemplate(data);
      },
    },
    {
      header: "Remarks",
      name: "remarks",
      textAlign: "center",
      defaultWidth: 100,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return addRemarksTemplate(data);
      },
    },

    // Add more columns as needed
  ];

  // var pinCodePattern = /^\d{6}$/;

  // var cityPattern = /^.{0,50}$/;

  // const editAllocationDetailsValidate = Yup.object({
  //   pinCode: Yup.string()
  //     .required("Pin Code is required")
  //     .matches(pinCodePattern, "Please Enter Valid Pin Code"),
  //   city: Yup.string()
  //     .required("City is required")
  //     .matches(cityPattern, "Please Enter Valid City Name"),
  // });

  const getLeadTypeClassName = (leadType) => {
    if (leadType === "Hot") {
      return "badge badge-rounded badge-danger";
    } else if (leadType === "Warm") {
      return "badge badge-rounded badge-warning";
    } else if (leadType === "Cold") {
      return "badge badge-rounded badge-success";
    } else {
      return "badge badge-rounded badge-primary";
    }
  };

  const editOptionTemplate = (data) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Link
          //   style={{ marginLeft: "10px" }}
          to="#"
          onClick={() => {
            getStatesCitiesPincode("", "", "");
            setAllocationDetails(data);
            setSelectedPriority(data.leadRate);
            setSelectedState(data.state);
            setSelectedCity(data.city);
            setSelectedPinCode(data.pinCode);
            setSelectedProspectName(data.customerName);
            setEditSalesAllocationModal(true);
          }}
        >
          <div className="media-body user-meta-info">
            <ul>
              <li>
                <Link
                  to={"#"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    width: "32px",
                  }}
                >
                  <i className="fa fa-edit" style={{ fontSize: "smaller" }}></i>
                </Link>
              </li>
            </ul>
          </div>
        </Link>
        {/* <Link
          style={{ marginLeft: "10px" }}
          to="#"
          onClick={() => {
            setAllocationDetails(data);
            setEditSalesAllocationModal(true);
          }}
        >
          <div className="media-body user-meta-info">
            <ul>
              <li>
                <Link
                  to={"#"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "15px",
                    width: "15px",
                  }}
                >
                  <i className="fa fa-edit" style={{ fontSize: "smaller" }}></i>
                </Link>
              </li>
            </ul>
          </div>
        </Link> */}
      </div>
    );
  };

  const getStatesCitiesPincode = (value, type, value2) => {
    var payload = {
      city: type === "city" ? value : undefined,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      state:
        type === "state" || type === "city"
          ? value2 !== ""
            ? value2
            : value
          : undefined,
    };
    moduleApi
      .postData(apiCall.getStatesCitiesPincode, payload)
      .then((response) => {
        const result = response?.map((item) => ({
          value: item,
          label: item,
        }));
        if (type === "state") {
          setCities(result);
        } else if (type === "") {
          setStates(result);
        } else if (type === "city") {
          setPinCodes(result);
        }
      });
  };

  const editAllocationDetails = () => {
    const payLoad = {
      id: allocationDetails?.id,
      leadRate: selectedPriority,
      pinCode: selectedPinCode,
      city: selectedCity,
      state: selectedState,
      customerName: selectedProspectName,
    };
    moduleApi
      .postData(apiCall.updatesaleallocation, payLoad)
      .then((response) => {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setEditSalesAllocationModal(false);
          updateSalesListAfterEdit(allocationDetails?.id, payLoad);
          swal("Allocation Details Updated Successfully", {
            icon: "success",
          });
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const updateSalesListAfterEdit = (id, values) => {
    const fieldsToUpdate = {
      priority: getPriority(values.leadRate),
      leadRate: values.leadRate,
      pinCode: values.pinCode,
      city: values.city,
      state: values.state,
      customerName: values.customerName,
    };

    // Using map to update the specific object's fields
    const updatedArray = salesAllocationsList?.map((obj) => {
      if (obj.id === id) {
        return { ...obj, ...fieldsToUpdate };
      }
      return obj;
    });
    dispatch(setSalesAllocationsList(updatedArray));
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    getSalesAllocationsList(start, end, key);
  };

  const setCustomDatesForGraph = (value1) => {
    var start = value1[0].getTime() / 1000;
    var end =
      new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStart(start);
    setEnd(end);
    getSalesAllocationsList(start, end, searchKey);
  };

  const allocationStatusTemplate = (props) => {
    return (
      <div>
        {" "}
        {props.allocationStatus === "OPEN" ? (
          <span className="badge badge-rounded badge-primary">Open</span>
        ) : props.allocationStatus === "INPROGRESS" ? (
          <span
            className="badge badge-rounded badge-warning"
            style={{ color: "black" }}
          >
            In Progress
          </span>
        ) : props.allocationStatus === "COMPLETED" ? (
          <span className="badge badge-rounded badge-success">Completed</span>
        ) : props.allocationStatus === "-" ||
          props.allocationStatus === "" ||
          props.allocationStatus === null ? (
          <span>-</span>
        ) : (
          <span className="badge badge-rounded badge-primary">
            {props.allocationStatus}
          </span>
        )}
      </div>
    );
  };

  const callDispositionStatusTemplate = (props) => {
    return (
      <div>
        {" "}
        {props.callDisposition === "Not Interested" ? (
          <span className="badge badge-rounded badge-danger">
            Not Interested
          </span>
        ) : props.callDisposition === "Interested" ? (
          <span className="badge badge-rounded badge-success">Interested</span>
        ) : props.callDisposition === "-" ||
          props.callDisposition === "" ||
          props.callDisposition === null ? (
          <span>-</span>
        ) : (
          <span className="badge badge-rounded badge-primary">
            {props.callDisposition}
          </span>
        )}
      </div>
    );
  };

  //   const subStatusTemplate = (props) => {
  //     return (
  //       <div>
  //         {" "}
  //         {props.subStatus === "Cash" ||
  //         props.subStatus === "Bank Transfer" ||
  //         props.subStatus === "Already Settled" ||
  //         props.subStatus === "UPI" ? (
  //           <span className="badge badge-rounded badge-success">
  //             {props.subStatus}
  //           </span>
  //         ) : props.subStatus === "Paid" ? (
  //           <span className="badge badge-rounded badge-success">
  //             {props.subStatus}
  //           </span>
  //         ) : props.subStatus === "Customer Not Available" ? (
  //           <span className="badge badge-rounded badge-danger">
  //             {props.subStatus}
  //           </span>
  //         ) : props.subStatus === "" || props.subStatus === null ? (
  //           <span>-</span>
  //         ) : (
  //           <span className="badge badge-rounded badge-primary">
  //             {props.subStatus}
  //           </span>
  //         )}
  //       </div>
  //     );
  //   };

  const addAdditionalDetailsTemplate = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>Add Additional Details</Tooltip>}
        >
          <div className="d-flex">
            <Button
              style={{
                float: "right",
                height: "30px",
                width: "30px",
                padding: "8px",
                paddingLeft:'8px',
                display:'flex',
                justifyContent:'center',
                borderColor: "primary",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="outline-primary btn-rounded"
              onClick={() => {
                setAllocationDetails(props);
                if (props.phoneNumber !== webRtcMobile) {
                  dispatch(setIsCallNotActive(true));
                } else {
                  dispatch(setIsCallNotActive(false));
                }
                setAddAdditionalDetailsModal(true);
              }}
            >
              <i class="fa fa-plus" style={{ fontSize: "small" }}></i>
            </Button>
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const handleAddAdditionalDetailsModal = () => {
    // loadSalesData(currPage);
    setAddAdditionalDetailsModal(false);
  };

  // function generatePDF() {
  //   const doc = new jsPDF({ unit: 'pt' }) // create jsPDF object
  //   const pdfElement = document.getElementById('pdf') // HTML element to be converted to PDF
  
  //   doc.html(pdfElement, {
  //     callback: (pdf) => {
  //       pdf.save('MyPdfFile.pdf')
  //     },
  //     margin: 32, // optional: page margin
  //     // optional: other HTMLOptions
  //   })
  // }

  // const iframeRef = useRef(null);

  // const downloadPdf = async () => {
  //   const iframe = iframeRef.current;
  //   const iframeDocument =
  //     iframe.contentDocument || iframe.contentWindow.document;

  //   try {
  //     const canvas = await html2canvas(iframeDocument.body);
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const imgWidth = 210; // A4 width in mm
  //     const pageHeight = 295; // A4 height in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     pdf.save("download.pdf");
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };

  const addRemarksTemplate = (props) => {
    return (
      <div>
        <Link
          to="#"
          className="btn btn-primary shadow btn-xs sharp"
          onClick={() => {
            addRemarksIconModal(props?.id);
          }}
        >
          <i className="fa fa-plus"></i>
        </Link>
      </div>
    );
  };

  const addRemarksIconModal = (value) => {
    setAddRemarksBasicModal(true);

    // console.log("value", value);
    setAddRemarksAllocationId(value);
    moduleApi
      .postData(apiCall.getSalesAllocationsDispositions + value)
      .then((response) => {
        // console.log("response", response);
        if (response) {
          setRemarks(response);
        }
      });
  };

  const callOptionTemplate = (props) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span>
          {hasAccess(75) || hasAccessFromTeamPrivilege(75)
            ? helper.getMaskedMobileNo(props.phoneNumber)
            : props.phoneNumber}
        </span>
        {hasAccess(42) &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
          isSalesProgressiveDialing === false && (
            <Link
              style={{ marginLeft: "10px" }}
              to="#"
              onClick={() => {
                if (isOnGoingCall === false && isIncomingCall===false) {
                  StartCall(
                    piopiy,
                    dispatch,
                    props?.phoneNumber,
                    false,
                    props?.id,
                    props,
                    "Sales",
                    isCallActive
                  );
                } else {
                  dispatch(setCallAudioModal(true));
                  dispatch(setIsModalMinimised(false));
                }
              }}
            >
              <div className="media-body user-meta-info">
                <ul>
                  <li>
                    <Link
                      to={"#"}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <i
                        className="fas fa-phone-alt"
                        style={{ fontSize: "smaller" }}
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </Link>
          )}
        {!(hasAccess(75) || hasAccessFromTeamPrivilege(75)) ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>Copy</Tooltip>}
          >
            <div className="media-body user-meta-info">
              <ul>
                <li>
                  <Link
                    to={"#"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <i
                      className="fas fa-copy"
                      style={{ cursor: "pointer", fontSize: "large" }}
                      onClick={() => {
                        // console.log(props);
                        navigator.clipboard.writeText(props.phoneNumber);
                      }}
                    ></i>
                  </Link>
                </li>
              </ul>
            </div>
            {/* </OverlayTrigger> */}
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const assignOptionTemplate = (data) => {
    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>Assign Allocation</Tooltip>}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems:'center'
          }}
        >
          <span>{data.telecallerName}</span>
          {hasAccess(42) &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
            searchKey !== "" && (
              //   isProgressiveDialing === false &&
              <Link
                style={{ marginLeft: "10px" }}
                to="#"
                onClick={() => {
                  assignAllocationToTelecaller(data?.id);
                }}
              >
                <div className="media-body user-meta-info">
                  <ul>
                    <li>
                      <Link
                        to={"#"}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        <i
                          className="fa fa-tasks"
                          style={{ fontSize: "smaller" }}
                        ></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Link>
            )}
        </div>
      </OverlayTrigger>
    );
  };

  const assignAllocationToTelecaller = (id) => {
    var payLoad = {
      allocationIds: [id],
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      userId: userLoginDetails?.autoFinanceUser?.userId,
    };

    moduleApi
      .postData(apiCall.assignAllocationToTelecaller, payLoad)
      .then((response) => {
        // console.log("assignResponse", response);
        if (response) {
          if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.Message === "Allocation assigned Sucessfully") {
            getSalesAllocationsList(start, end, searchKey);
            return swal("Allocation Assigned Successfully", {
              icon: "success",
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const callHistoryTemplate = (data) => {
    return (
      <div
        className="d-flex"
        style={{ justifyContent: "space-around" }}
        onClick={() => {
          var temp = {
            mobileNo: data.phoneNumber,
            loanNumber: data.loanNumber,
          };
          dispatch(setMobileNo(temp));
          dispatch(setCallReportModal(true));
        }}
      >
        <Link to="#" className="btn btn-info shadow btn-xs sharp">
          <i className="fa fa-eye"></i>
        </Link>
      </div>
    );
  };

  //   const getAllocationDetailsBasedOnId = (id) => {
  //     var payload = {
  //       id: id,
  //       organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
  //     };
  //     moduleApi
  //       .postData(apiCall.getAllocationDetailsById, payload)
  //       .then((element) => {
  //         var data = {
  //           id: element.id,
  //           customerName: element.customerName ? element.customerName : "-",
  //           dueAmount: element.dueAmount ? element.dueAmount : 0,
  //           contactNumber1: element.contactNumber1 ? element.contactNumber1 : "-",
  //           loanNumber: element.loanNumber ? element.loanNumber : "-",
  //           campaignCallIntiated: element.campaignCallIntiated,
  //           latestPaymentLinkId: element.latestPaymentLinkId
  //             ? element.latestPaymentLinkId
  //             : "-",
  //           latestPaymentLinkStatus: element.latestPaymentLinkStatus
  //             ? element.latestPaymentLinkStatus
  //             : "-",
  //           noOfpaymentLinkSent: element.noOfLinkSent ? element.noOfLinkSent : 0,
  //           status: element.status ? element.status : "-",
  //           totalCalls: element.totalCalls ? element.totalCalls : 0,
  //           callsToday: element.callsToday ? element.callsToday : 0,
  //           visitStatus: element.visitStatus,
  //           subStatus: element.subStatus,
  //           currentBucketName: element.currentBucketName
  //             ? element.currentBucketName
  //             : "-",
  //           remarks: element.remarks ? element.remarks : "-",
  //           branchName: element.branchName ? element.branchName : "-",
  //           amountPaid: element.amountPaid ? element.amountPaid : 0,
  //           addressLine2: element.addressLine2,
  //           aggDate: element.aggDate,
  //           installmentDate: element.installmentDate
  //             ? EpochToDate(element.installmentDate)
  //             : "-",
  //           amountPaidNow: element.amountPaidNow,
  //           amountToBePaid: element.amountToBePaid,
  //           assetDesc: element.assetDesc,
  //           assignedMobileNumber: element.assignedMobileNumber,
  //           assignedName: element.assignedName,
  //           bounceCharges: element.bounceCharges,
  //           branchId: element.branchId,
  //           chassisNumber: element.chassisNumber,
  //           comments: element.comments,
  //           contactNumber: element.contactNumber1,
  //           contactNumber2: element.contactNumber2,
  //           cpName: element.cpName,
  //           currentBucket: element.currentBucketName,
  //           customerIntensinty: element.customerIntensinty,
  //           lenderId: element.lenderId,
  //           lenderName: element.lenderName,
  //           lenderAddress: element.promoterAddress,
  //           locLink1: element.locLink1,
  //           locLink2: element.locLink2,
  //           borrowerAddress: element.borrowerAddress,
  //           current_EMI_Month: element.currentEmiMonthNumber,
  //           dateTime:
  //             element.dateTime !== 0 ? EpochToDate(element.dateTime) : "-",
  //           lastPaidDate:
  //             element.lastPaidDate !== 0
  //               ? EpochToDate(element.lastPaidDate)
  //               : "-",
  //           expiredDate:
  //             element.expiredDate !== 0
  //               ? helper.EpochToOnlyDate(element.expiredDate)
  //               : "-",
  //           dueDate:
  //             element.dueDate !== 0
  //               ? helper.EpochToOnlyDate(element.dueDate)
  //               : "-",
  //           dueDay: element.dueDay ? element.dueDay : "-",
  //           emiAmount: element.emiAmount,
  //           emiOutstanding: element.emiOutstanding,
  //           engineNumber: element.engineNumber,
  //           employeeId: element.executiveEmployeeId
  //             ? element.executiveEmployeeId
  //             : element.managerEmployeeId,
  //           executiveMobileNumber: element.executiveMobileNumber,
  //           executiveName: element.executiveName,
  //           guarantorMobile: element.guarantorMobile,
  //           guarantorName: element.guarantorName,
  //           image: element.image,
  //           latePaymentCharges: element.latePaymentCharges,
  //           latitude: element.latitude,
  //           loanAmount: element.loanAmount,
  //           location: element.location,
  //           longitude: element.longitude,
  //           managerEmployeeId: element.managerEmployeeId,
  //           managerName: element.managerName,
  //           nearByLandmark: element.nearByLandmark,
  //           netOtherAmount: element.netOtherAmount,
  //           net_GDI_Amount: element.net_GDI_Amount,
  //           net_HL_Amount: element.net_HL_Amount,
  //           organizationId: element.organizationId,
  //           permanentAddress: element.permanentAddress,
  //           permanentCity: element.permanentCity,
  //           permanentState: element.permanentState,
  //           permanentZipcode: element.permanentZipcode,
  //           principalOutstanding: element.principalOutstanding,
  //           productName: element.productName,
  //           referenceMobile1: element.referenceMobile1,
  //           referenceMobile2: element.referenceMobile2,
  //           referenceName1: element.referenceName1,
  //           referenceName2: element.referenceName2,
  //           registrationNumber: element.registrationNumber,
  //           reportingOfficerId: element.reportingOfficerId,
  //           address: element.residenceAddress,
  //           residenceCity: element.residenceCity,
  //           residenceState: element.residenceState,
  //           residenceZipcode: element.residenceZipcode,
  //           teamLeaderEmployeeId: element.teamLeaderEmployeeId,
  //           teamLeaderName: element.teamLeaderName,
  //           teleCallerEmpId: element.teleCallerEmpId
  //             ? element.teleCallerEmpId
  //             : "-",
  //           teleCallerName: element.teleCallerName ? element.teleCallerName : "-",
  //           temporaryRegistrationNumber: element.temporaryRegistrationNumber,
  //           tenure: element.tenure,
  //           totalPenaltyCharges: element.totalPenaltyCharges,
  //           typeOfVisit: element.typeOfVisit,
  //           thirdPartyPhoneNumber: element.thirdPartyPhoneNumber,
  //           thirdPartyName: element.thirdPartyName,
  //           updatedDate:
  //             element.updatedDate !== 0 ? EpochToDate(element.updatedDate) : "-",
  //           lastCallUpdateTime:
  //             element.lastCallUpdateTime !== 0
  //               ? EpochToDate(element.lastCallUpdateTime)
  //               : "-",
  //           userId: element.userId,
  //           vehicleType: element.vehicleType,
  //           emiAmountPaid: element.emiAmountPaid ? element.emiAmountPaid : 0,
  //           emiAmountToBePaid: element.emiAmountToBePaid
  //             ? element.emiAmountToBePaid
  //             : 0,
  //           bounceChargesPaid: element.bounceChargesPaid
  //             ? element.bounceChargesPaid
  //             : 0,
  //           bounceChargesToBePaid: element.bounceChargesToBePaid
  //             ? element.bounceChargesToBePaid
  //             : 0,
  //           priority: element.priority ? element.priority : "-",
  //           latePaymentChargesPaid: element.latePaymentChargesPaid
  //             ? element.latePaymentChargesPaid
  //             : 0,
  //           latePaymentChargesToBePaid: element.latePaymentChargesToBePaid
  //             ? element.latePaymentChargesToBePaid
  //             : 0,
  //           penaltyChargesPaid: element.penaltyChargesPaid
  //             ? element.penaltyChargesPaid
  //             : 0,
  //           penaltyChargesToBePaid: element.penaltyChargesToBePaid
  //             ? element.penaltyChargesToBePaid
  //             : 0,
  //           otherChargesPaid: element.otherChargesPaid
  //             ? element.otherChargesPaid
  //             : 0,
  //           otherChargesToBePaid: element.otherChargesToBePaid
  //             ? element.otherChargesToBePaid
  //             : 0,
  //         };
  //         // dispatch(setAllocationDetails(data));
  //         // dispatch(setAllocationDetailsModal(true));
  //       });
  //   };

  const handleCloseForRemarks = () => {
    setAddRemarksBasicModal(false);
    // getSalesAllocationsList(start, end, campaignName, searchKey);
  };

  function CloseMinimizeModal() {
    dispatch(setIsModalMinimised(false));
  }

  function MakeAllocationIdNull() {
    dispatch(setAllocationId(null));
  }

  //   const paymentsDownloadData = () => {
  //     setIsLoading(true);

  //     const payload = {
  //       startTime: start,
  //       endTime: end,
  //       filters: [],
  //       organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //       teleCallerUserId:
  //         key === "" ? userLoginDetails.autoFinanceUser.userId : "",
  //       telecallerAllocations: false,
  //       searchKey: key,
  //       status: "",
  //       // campaignName: campaignName,
  //       sorts: [],
  //     };

  //     moduleApi
  //       .postData(apiCall.getAllSalesAllocations, payload)
  //       .then((response) => {
  //         if (response) {
  //           if (response.totalElements > 5000) {
  //             setIsLoading(false);
  //             return swal({
  //               title: "Download Report",
  //               text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //               icon: "warning",
  //               buttons: true,
  //               dangerMode: true,
  //             }).then((willDelete) => {
  //               if (willDelete) {
  //                 setIsLoading(true);
  //                 getPaymentsFullResponseData(
  //                   Math.floor(response.totalElements / 200)
  //                 );
  //               }
  //             });
  //           }

  //           getPaymentsFullResponseData(Math.floor(response.totalElements / 200));
  //         } else {
  //           swal("Something Went Wrong, Please Try Later", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //     // }
  //   };

  async function getPaymentsFullResponseData() {
    // var tempList = [];
    var mgrListData = [];

    // for (let i = 0; i <= size; i++) {
    const payload = {
      startTime: start,
      endTime: end,
      filters: [],
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      teleCallerUserId:
        searchKey === "" ? userLoginDetails.autoFinanceUser.userId : "",
      telecallerAllocations: false,
      searchKey: searchKey,
      status: "",
      // campaignName: campaignName,
      sorts: [],
    };
    await moduleApi
      .postData(apiCall.getAllSalesAllocations, payload)
      .then((response) => {
        response?.forEach((element) => {
          let data = {
            callDisposition: element.callDisposition
              ? element.callDisposition
              : "-",
            allocationStatus: element.allocationStatus
              ? element.allocationStatus
              : "-",
            callDispositionSubstatus: element.callDispositionSubstatus
              ? element.callDispositionSubstatus
              : "-",
            callStatus: element.callStatus ? element.callStatus : "-",
            city: element.city ? element.city : "-",
            empId: element.empId ? element.empId : "-",
            empName: element.empName ? element.empName : "-",
            lastCallUpdateTime: element.lastCallUpdateTime
              ? helper.convertEpochToTime2(element.lastCallUpdateTime)
              : "-",
            callsToday: element.callsToday ? element.callsToday : 0,
            customerName: element.customerName ? element.customerName : "-",
            dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
            excelRowNumber: element.excelRowNumber
              ? element.excelRowNumber
              : "-",
            id: element.id ? element.id : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
            pinCode: element.pinCode ? element.pinCode : "-",
            sourceChannelId: element.sourceChannelId
              ? element.sourceChannelId
              : "-",
            sourcingChannel: element.sourcingChannel
              ? element.sourcingChannel
              : "-",
            state: element.state ? element.state : "-",
            telecallerEmpId: element.telecallerEmpId
              ? element.telecallerEmpId
              : "-",
            telecallerName: element.telecallerName
              ? element.telecallerName
              : "-",
            priority: element.priority,
            totalCalls: element.totalCalls ? element.totalCalls : 0,
            userId: element.userId ? element.userId : "-",
            validationFailed: element.validationFailed
              ? element.validationFailed
              : "-",
          };
          mgrListData.push(data);
        });
        setPaymentsDownloadList(mgrListData);
      });
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Sourcing Channel",
        B: "Priority",
        C: "Prospect Name",
        D: "Prospect Number",
        E: "Today Calls",
        F: "Total Calls",
        G: "Last Called At",
        H: "Telecaller Emp Id",
        I: "Telecaller Emp Name",
        J: "City",
        K: "State",
        L: "Pin Code",
        M: "Call Status",
        N: "Call Disposition Status",
        O: "Call Disposition Sub Status",
        P: "Allocation Status",
        Q: "Assigned At",
      },
    ];
    paymentsDownloadList?.forEach((row) => {
      mgrTable.push({
        A: row.sourcingChannel,
        B: row.priority,
        C: row.customerName,
        D:
          hasAccess(75) || hasAccessFromTeamPrivilege(75)
            ? helper.getMaskedMobileNo(row.phoneNumber)
            : row.phoneNumber,
        E: row.callsToday,
        F: row.totalCalls,
        G: row.lastCallUpdateTime,
        H: row.telecallerEmpId,
        I: row.telecallerName,
        J: row.city,
        K: row.state,
        L: row.pinCode,
        M: row.callStatus,
        N: row.callDisposition,
        O: row.callDispositionSubstatus,
        P: row.allocationStatus,
        Q: row.dateTime,
      });
    });
    const finalData = [...mgrTable];
    // console.log("finaldata", finalData);
    //create a new workbook
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    // console.log("sheet", sheet);
    XLSX.utils.book_append_sheet(
      wb,
      sheet,
      searchKey === "" ? "My_Sales_Allocations_" : "All_Sales_Allocations"
    );
    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      (searchKey === "" ? "My_Sales_Allocations_" : "All_Sales_Allocations_") +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "My_Sales_Allocations List Download",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        (searchKey === ""
          ? "_My_Sales_Allocations_"
          : "_All_Sales_Allocations_") +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  return (
    <div className="card">
      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', margin:'-10px', fontSize:'small'}}>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: '#8a4af3',
              fontWeight: "bold",
            }}
          >
            Total Calls
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}> {salesCallsCount?.totalCalls}</span>
        </span>
      </div>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: '#4caf50',
              fontWeight: "bold",
            }}
          >
            Calls Connected
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}> {salesCallsCount?.callConnectedCount}</span>
        </span>
      </div>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: 'red',
              fontWeight: "bold",
            }}
          >
            Calls Not Connected
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}> {salesCallsCount?.callNotConnectedCount}</span>
        </span>
      </div>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: '#af4cab',
              fontWeight: "bold",
            }}
          >
            Calls With No Disposition
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}>{salesCallsCount?.callswithNoDispositionCount}</span>
        </span>
      </div>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: 'Blue',
              fontWeight: "bold",
            }}
          >
            Calls Not Attempted
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}> {salesCallsCount?.callsNotAttemped}</span>
        </span>
      </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginTop: "10px",
          //   marginBottom: "15px",
          marginRight: "15px",
        }}
      >
        {hasAccess(42) &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
          isOnGoingCall === false && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                float: "left",
                alignItems: "center",
                marginBottom: "10px",
                marginRight: "5px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  fontSize: "larger",
                  fontWeight: "bold",
                }}
              >
                Progressive Dialing
              </span>
              <div className="form-check form-switch">
                <input
                  style={{
                    width: "45px",
                    height: "20px",
                    cursor:
                      salesAllocationsList?.length > 0
                        ? "pointer"
                        : "not-allowed",
                  }}
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault2"
                  checked={isSalesProgressiveDialing}
                  disabled={salesAllocationsList?.length > 0 ? false : true}
                  onChange={() => {
                    swal({
                      title: "Progressive Dialing",
                      text:
                        "Are you sure, you want to " +
                        (isSalesProgressiveDialing === true
                          ? "Disable"
                          : "Enable") +
                        " Progressive Dialing",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willUdate) => {
                      if (willUdate) {
                        dispatch(
                          setIsSalesProgressiveDialing(
                            !isSalesProgressiveDialing
                          )
                        );
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
        <SearchBar
          placeholder="Search By Prospect Name or Number"
          searchKeyword={searchKeyword}
        />
        {/* {hasAccess(42) &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" && (
            <div
              style={{
                float: "right",
                width: "120px",
                marginBottom: "5px",
                marginLeft: "5px",
                height: "38px",
                borderRadius: "3px",
                borderWidth: "1px",
                pointerEvents: isProgressiveDialing === true && "none",
                boxShadow: "1px 1px 2px #797979",
                cursor: "pointer",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) => {
                  getSalesAllocationsList(start, end, value.value, searchKey);
                  dispatch(setCampaignName(value.value));
                }}
                defaultValue={{ label: "All", value: "" }}
                options={campaigns}
              />
            </div>
          )} */}
        {/* <div
          style={{
            float: "right",
            width: "120px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "0px",
            height: "38px",
            borderRadius: "3px",
            // pointerEvents: isProgressiveDialing === true && "none",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #797979",
            cursor: "pointer",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) => {
              handleStatusFilterChange(value.value);
              dispatch(setSelectedStatus(value.value));
              getStatusIndex(value.value);
            }}
            value={statusOptions[statusIndex]}
            defaultValue={statusOptions[0]}
            options={statusOptions}
            // id="startenddate"
          />
        </div> */}
        <div
          style={{
            float: "right",
            marginBottom: "10px",
            marginLeft: "5px",
            // pointerEvents: isProgressiveDialing === true && "none",
          }}
        >
          <Flatpickr
            className="form-control d-block"
            style={{
              width: "170px",
              height: "40px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              textAlignLast: "center",
            }}
            placeholder="Select Month"
            onChange={(value) => {
              setCustomDatesForGraph(value);
            }}
            options={{
              defaultDate: new Date(),
              maxDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 2,
                0
              ),
              minDate: new Date().setMonth(new Date().getMonth() - 6),

              plugins: [
                new monthSelectPlugin({
                  shorthand: true, //defaults to false
                  dateFormat: "F Y", //defaults to "F Y"
                  altFormat: "F Y", //defaults to "F Y"
                  theme: "light", // defaults to "light"
                }),
              ],
            }}
          />
        </div>
        <div
        //   style={{
        //     pointerEvents: isProgressiveDialing === true && "none",
        //   }}
        >
          <Button
            style={{
              float: "right",
              //   margin: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="success btn-rounded"
            onClick={() => {
              getPaymentsFullResponseData();

              //   exportCSV();
            }}
          >
            <i className="fa fa-download"></i>
            &nbsp;&nbsp;Download
          </Button>
        </div>

        {/* <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "20px",
            marginLeft: "10px",
            marginBottom: "10px",
            borderRadius: "3px",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #797979",
          }}
        >
          <Select
            isSearchable={false}
            //   onChange={(value) => {

            //   }}
            defaultValue={{ label: "All", value: "" }}
            // options={collectionStatuses}
            id="collectiontype"
          />
        </div>

        <Button
          style={{
            marginRight: "10px",
            marginLeft: "7px",
            marginBottom: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          //   onClick={() =>

          //   }
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button> */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        {/* <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => ( */}
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "-5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <ReactDataGrid
              //   handle={setGridRef}
              idProperty="id"
              style={gridStyle}
              columns={columns}
              emptyText={emptyText}
              pagination
              enableSelection
              //   defaultSkip={skipIndex}
              //   skip={11}
              //   onSkipChange={updateSkipIndex}
              //   enableClipboard
              defaultFilterValue={filterValue}
              dataSource={salesAllocationsList}
              defaultLimit={10}
              showZebraRows={false}
              //   enableTreeRowReorder
            />
          </div>
        </div>
        {/* )}
        </PaginationProvider> */}
        {/* {filteredData.length === 0 && (
          <div className="d-flex justify-content-center">
            <img src={NO_DATA_FOUND} alt="" />
          </div>
        )} */}
      </LoadingOverlay>

      <Modal className="fade" show={addRemarksBasicModal} size="lg">
        <Modal.Header>
          <Modal.Title>Add Remarks</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddRemarksBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <AddRemarks
              allocationId={addRemarksAllocationId}
              callId={null}
              //   campaignName={campaignName}
              closeMinimizeModal={CloseMinimizeModal}
              makeAllocationIdNull={MakeAllocationIdNull}
              close={handleCloseForRemarks}
              type={"Sales"}
              callStatus={true}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <label style={{ fontWeight: "bold" }}>Previous Remarks</label>
              {remarks?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table">
                    <thead
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        top: "1px",
                        position: "sticky",
                      }}
                    >
                      <tr style={{ textAlign: "center" }}>
                        <th scope="col">Remarks</th>
                        <th scope="col">Remarks Added By</th>
                        <th scope="col">Call Status</th>
                        <th scope="col">Status</th>
                        <th scope="col">Sub Status</th>
                        <th scope="col">Amount Paid</th>
                        <th scope="col">Transaction Id</th>
                        <th scope="col">PTP Date</th>
                        <th scope="col">PTP Slot</th>
                        <th scope="col">PTP Amount</th>
                        <th scope="col">Call Back Date</th>
                        <th scope="col">Call Back Slot</th>
                        <th scope="col">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {remarks?.map((item) => (
                        <tr
                          style={{
                            textAlign: "center",
                            fontWeight: "normal",
                          }}
                          key={item.id}
                        >
                          <td>{item.remarks}</td>
                          <td>
                            {item.remarkAddedBy ? item.remarkAddedBy : "-"}
                          </td>
                          <td>{item.remarksType ? item.remarksType : "-"}</td>
                          <td>{item.status ? item.status : "-"}</td>
                          <td>{item.subStatus ? item.subStatus : "-"}</td>
                          <td>{item.amountPaid ? item.amountPaid : "-"}</td>
                          <td>
                            {item.transactionId ? item.transactionId : "-"}
                          </td>
                          <td>
                            {item.toBePaidBy
                              ? EpochToDate(item.toBePaidBy)
                              : "-"}
                          </td>
                          <td>{item.ptpSlot ? item.ptpSlot : "-"}</td>
                          <td>{item.ptpAmount ? item.ptpAmount : "-"}</td>
                          <td>
                            {item.callBackDate
                              ? EpochToDate(item.callBackDate)
                              : "-"}
                          </td>
                          <td>{item.callBackSlot ? item.callBackSlot : "-"}</td>
                          {item.createdTime ? (
                            <td>{EpochToDate(item.createdTime)}</td>
                          ) : (
                            <td>-</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <span>{index + 1 + ") " + item}</span>
                    <br></br> */}
                </div>
              ) : (
                <span style={{ textAlignLast: "center" }}>
                  No Previous Remarks Found
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={editSalesAllocationModal}>
        <Modal.Header>
          <Modal.Title>Edit Allocation Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setEditSalesAllocationModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <label style={{ fontWeight: "bold" }}>Prospect Name</label>
              <input
                style={{
                  borderRadius: "7px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  padding: "10px",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  width: "423px",
                }}
                placeholder="Enter Prospect Name..."
                defaultValue={allocationDetails?.customerName}
                onChange={(e) => {
                  setSelectedProspectName(e.target.value);
                }}
              />
              <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                Priority
              </label>
              <div
                style={{
                  float: "right",
                  width: "450px",
                  marginRight: "5px",
                  height: "38px",
                  marginBottom: "5px",
                  cursor: "pointer",
                  // borderRadius: "3px",
                  // borderWidth: "1px",
                  // boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={false}
                  options={leadRateOptions}
                  defaultValue={
                    allocationDetails?.leadRate
                      ? {
                          label: getPriority(allocationDetails?.leadRate),
                          value: allocationDetails?.leadRate,
                        }
                      : null
                  }
                  onChange={(value) => {
                    setSelectedPriority(value.value);
                  }}
                  placeholder="Select Priority..."
                />
              </div>
              <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                State
              </label>
              <div
                style={{
                  float: "right",
                  width: "450px",
                  marginRight: "5px",
                  height: "38px",
                  marginBottom: "5px",
                  cursor: "pointer",
                  // borderRadius: "3px",
                  // borderWidth: "1px",
                  // boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={true}
                  options={states}
                  defaultValue={
                    selectedState !== null
                      ? { label: selectedState, value: selectedState }
                      : null
                  }
                  value={
                    selectedState !== null
                      ? { label: selectedState, value: selectedState }
                      : null
                  }
                  onChange={(value) => {
                    getStatesCitiesPincode(value.value, "state", "");
                    setSelectedCity(null);
                    setSelectedPinCode(null);
                    setSelectedState(value.value);
                  }}
                  placeholder="Select State..."
                />
              </div>
              <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                City
              </label>
              <div
                style={{
                  float: "right",
                  width: "450px",
                  marginRight: "5px",
                  height: "38px",
                  marginBottom: "5px",
                  cursor: "pointer",
                  // borderRadius: "3px",
                  // borderWidth: "1px",
                  // boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={true}
                  options={cities}
                  defaultValue={
                    selectedCity !== null
                      ? { label: selectedCity, value: selectedCity }
                      : null
                  }
                  value={
                    selectedCity !== null
                      ? { label: selectedCity, value: selectedCity }
                      : null
                  }
                  onChange={(value) => {
                    getStatesCitiesPincode(value.value, "city", selectedState);
                    setSelectedPinCode(null);
                    setSelectedCity(value.value);
                  }}
                  placeholder="Select City..."
                />
              </div>

              <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                Pin Code
              </label>
              <div
                style={{
                  float: "right",
                  width: "450px",
                  marginRight: "5px",
                  height: "38px",
                  marginBottom: "5px",
                  cursor: "pointer",
                  // borderRadius: "3px",
                  // borderWidth: "1px",
                  // boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={true}
                  options={pinCodes}
                  defaultValue={
                    selectedPinCode !== null
                      ? { label: selectedPinCode, value: selectedPinCode }
                      : null
                  }
                  value={
                    selectedPinCode !== null
                      ? { label: selectedPinCode, value: selectedPinCode }
                      : null
                  }
                  onChange={(value) => {
                    setSelectedPinCode(value.value);
                  }}
                  placeholder="Select Pin Code..."
                />
              </div>
              <div style={{ marginTop: "10px", marginBottom: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  onClick={() => {
                    editAllocationDetails();
                  }}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light"
                  onClick={() => {
                    setEditSalesAllocationModal(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={callReportModal} size="lg">
        <Modal.Header>
          <Modal.Title>Call History</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch(setCallReportModal(false))}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <CallSummary
            mobileNo={mobileNo?.mobileNo}
            loanNumber={mobileNo?.loanNumber}
            type={"Sales"}
          />
        </Modal.Body>
      </Modal>

      <Modal className="fade" size="md" show={addAdditionalDetailsModal}>
        <Modal.Header>
          <Modal.Title>
            Add Additional Details{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>
              {isCallNotActive === true && " - Not an Active Call"}
            </span>
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => handleAddAdditionalDetailsModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
           <div>
           {/* <Button onClick={()=>{
              downloadPdf()
            }}>
              Download
            </Button> */}
          <div id="pdf">
            <iframe
            // id="pdf"
            // ref={iframeRef}
            style={{width:'-webkit-fill-available', marginTop:'-15px'}}
              src={"https://webdemo.clucloud.com/?fe=sales&og="+userLoginDetails?.autoFinanceOrganization?.orgshortcode+"&t1=additionaldetails&t2=form1&id="+allocationDetails?.id}
              title="salesJsonForm"
              // width="1100"
              height="450"
            />
          </div> 
            {/* <AddAdditionalDetails
              leadDetails={allocationDetails}
              close={handleAddAdditionalDetailsModal}
            /> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewMySalesAllocations;
