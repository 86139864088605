import React from "react"
import * as Yup from "yup"
import { Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import 'react-toastify/dist/ReactToastify.css';
import TextField from "../../CommonComponents/TextField";
import { Field } from "formik";
import Select from "react-select";


// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const PersonalInfo = ({ key, mainOBJ, onChange, errors }) => {
  let applicantAgeOptions=[
    {label:"21",value:"21"},
    {label:"22",value:"22"},
{label:"23",value:"23"},
{label:"24",value:"24"},
{label:"25",value:"25"},
{label:"26",value:"26"},
{label:"27",value:"27"},
{label:"28",value:"28"},
{label:"29",value:"29"},
{label:"30",value:"30"},
{label:"31",value:"31"},
{label:"32",value:"32"},
{label:"33",value:"33"},
{label:"34",value:"34"},
{label:"35",value:"35"},
{label:"36",value:"36"},
{label:"37",value:"37"},
{label:"38",value:"38"},
{label:"39",value:"39"},
{label:"40",value:"40"},
{label:"41",value:"41"},
{label:"42",value:"42"},
{label:"43",value:"43"},
{label:"44",value:"44"},
{label:"45",value:"45"},
{label:"46",value:"46"},
{label:"47",value:"47"},
{label:"48",value:"48"},
{label:"49",value:"49"},
{label:"50",value:"50"},
{label:"51",value:"51"},
{label:"52",value:"52"},
{label:"53",value:"53"},
{label:"54",value:"54"},
{label:"55",value:"55"},
{label:"56",value:"56"},
{label:"57",value:"57"},
{label:"58",value:"58"},
{label:"59",value:"59"},
{label:"60",value:"60"},
{label:"61",value:"61"},
{label:"62",value:"62"},
{label:"63",value:"63"},
{label:"64",value:"64"},
{label:"65",value:"65"}
  ]

  let familyMembersOptions=[
    {label:1,value:1},
    {label:2,value:2},
    {label:3,value:3},
    {label:4,value:4},
    {label:5,value:5},
    {label:6,value:6},
    {label:7,value:7},
    {label:8,value:8},
    {label:9,value:9},
    {label:10,value:10}
  ]

  let maritalStatusOptions=[
    {label:'Single',value:'Single'},
    {label:'Married',value:'Married'},
    {label:'Divorced',value:'Divorced'},
  ]

  let workingStatusOptions=[
    {label:'Salaried',value:'Salaried'},
    {label:'Self Employed',value:'Self Employed'},
    {label:'Not Working',value:'Not Working'},
  ]

  let yesOrNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <div style={{ margin: "15px", maxHeight:'400px', overflowY:'auto' }}>
      <Row>
        <Col>
          {/* <TextField isAsterisk={true} label="Applicant's Age" name="applicant_age" type="text" /> */}
          <Field name="applicant_age">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Applicant's Age
    </label>
    <Select
      placeholder="Select Applicant's Age"
      classNamePrefix="select-box"
      options={applicantAgeOptions}
      defaultValue={{
        label: mainOBJ?.applicant_age,
        value: mainOBJ?.applicant_age,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "applicant_age",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.applicant_age && (
<span className="error">{errors.applicant_age}</span>
)}
          {/* <TextField isAsterisk={true} label="No of Family Members" name="family_members_count" type="text" /> */}
          <Field name="family_members_count">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      No of Family Members
    </label>
    <Select
      placeholder="Select No of Family Members"
      classNamePrefix="select-box"
      options={familyMembersOptions}
      defaultValue={{
        label: mainOBJ?.family_members_count,
        value: mainOBJ?.family_members_count,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "family_members_count",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.family_members_count && (
<span className="error">{errors.family_members_count}</span>
)}
          {/* <TextField isAsterisk={true} label="No of Earning Members" name="no_of_earning_members" type="text" /> */}
          <Field name="no_of_earning_members">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      No of Earning Members
    </label>
    <Select
      placeholder="Select No of Earning Members"
      classNamePrefix="select-box"
      options={familyMembersOptions}
      defaultValue={{
        label: mainOBJ?.no_of_earning_members,
        value: mainOBJ?.no_of_earning_members,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "no_of_earning_members",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.no_of_earning_members && (
<span className="error">{errors.no_of_earning_members}</span>
)}
          {/* <TextField isAsterisk={true} label="No of Dependents" name="no_of_dependents" type="text" /> */}
          <Field name="no_of_dependents">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      No of Dependents
    </label>
    <Select
      placeholder="Select No of Dependents"
      classNamePrefix="select-box"
      options={familyMembersOptions}
      defaultValue={{
        label: mainOBJ?.no_of_dependents,
        value: mainOBJ?.no_of_dependents,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "no_of_dependents",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.no_of_dependents && (
<span className="error">{errors.no_of_dependents}</span>
)}
          {/* <TextField isAsterisk={true} label="Marital Status" name="marital_status" type="text" /> */}
          <Field name="marital_status">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Marital Status
    </label>
    <Select
      placeholder="Select Marital Status"
      classNamePrefix="select-box"
      options={maritalStatusOptions}
      defaultValue={{
        label: mainOBJ?.marital_status,
        value: mainOBJ?.marital_status,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "marital_status",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.marital_status && (
<span className="error">{errors.marital_status}</span>
)}
          {/* <TextField isAsterisk={true} label="Applicant's Working Status" name="applicant_working" type="text" /> */}
          <Field name="applicant_working">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Applicant's Working Status
    </label>
    <Select
      placeholder="Select Applicant's Working Status"
      classNamePrefix="select-box"
      options={workingStatusOptions}
      defaultValue={{
        label: mainOBJ?.applicant_working,
        value: mainOBJ?.applicant_working,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "applicant_working",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.applicant_working && (
<span className="error">{errors.applicant_working}</span>
)}
          <TextField label="Applicant's Company/Business Name" name="applicant_company_name" type="text" />
          <TextField label="Applicant's Company/Business Address" name="applicant_company_address" type="text" />
          <TextField label="Applicant's Monthly/Annual Income" name="occupation_income_of_applicant" type="text" />
        </Col>
        <Col> 
          {/* <TextField label="Spouse Working Status" name="spouse_working" type="text" /> */}
          <Field name="spouse_working">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      // className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Spouse Working Status
    </label>
    <Select
      placeholder="Select Spouse Working Status"
      classNamePrefix="select-box"
      options={workingStatusOptions}
      defaultValue={mainOBJ?.spouse_working?{
        label: mainOBJ?.spouse_working,
        value: mainOBJ?.spouse_working,
      }:null}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "spouse_working",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.spouse_working && (
<span className="error">{errors.spouse_working}</span>
)}
          <TextField label="Spouse Company/Business Name" name="spouse_company_name" type="text" />
          <TextField label="Spouse Company/Business Address" name="spouse_company_address" type="text" />
          <TextField label="Spouse Monthly/Annual Income" name="occupation_income_of_spouse" type="text" />
          {/* <TextField label="Any Other Loans or EMI Availed" name="other_loans_info" type="text" /> */}
          <Field name="other_loans_info">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      // className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Any Other Loans or EMI Availed
    </label>
    <Select
      placeholder="Select Any Other Loans or EMI Availed"
      classNamePrefix="select-box"
      options={yesOrNoOptions}
      defaultValue={{
        label: mainOBJ?.other_loans_info,
        value: mainOBJ?.other_loans_info,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "other_loans_info",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.other_loans_info && (
<span className="error">{errors.other_loans_info}</span>
)}
          <TextField isAsterisk={true} label="Loan Product" name="other_loans_product" type="text" />
          <TextField isAsterisk={true} label="Loan Number" name="other_loans_loan_no" type="text" />
          <TextField isAsterisk={true} label="Amount" name="other_loans_amount" type="text" />
          <TextField isAsterisk={true} label="EMI" name="other_loans_emi" type="text" /> 
        </Col>
      </Row>
    </div>
  )
}

PersonalInfo.label = "Personal Info"
PersonalInfo.initialValues = {
  applicant_age: "",
  family_members_count: "",
  no_of_earning_members: "",
  no_of_dependents: "",
  marital_status: "",
  applicant_working: "",
  applicant_company_name: "",
  applicant_company_address: "",
  occupation_income_of_applicant: "",
  spouse_working: "",
  spouse_company_name: "",
  spouse_company_address: "",
  occupation_income_of_spouse: "",
  other_loans_info: "",
  other_loans_product: "",
  other_loans_loan_no: "",
  other_loans_amount: "",
  other_loans_emi: "",
}

PersonalInfo.validationSchema = Yup.object({
  applicant_age: Yup.string().nullable().required("Applicant's Age is required"),
  family_members_count: Yup.string().nullable().required("No of Family Members is required"),
  no_of_earning_members: Yup.string().nullable().required("No of Earning Members is required"),
  no_of_dependents: Yup.string().nullable().required("No of Dependents is required"),
  marital_status: Yup.string().nullable().required("Marital Status is required"),
  applicant_working: Yup.string().nullable().required("Applicant's Working Status is required"),
  // applicant_company_name: Yup.string().required("Applicant's Company/Business Name is required"),
  // applicant_company_address: Yup.string().required("Applicant's Company/Business Address is required"),
  // occupation_income_of_applicant: Yup.string().required("Applicant's Monthly/Annual Income is required"),
  // spouse_working: Yup.string().required("Spouse Working Status is required"),
  // spouse_company_name: Yup.string().required("Spouse Company/Business Name is required"),
  // spouse_company_address: Yup.string().required("Spouse Company/Business Address is required"),
  // occupation_income_of_spouse: Yup.string().required("Spouse Monthly/Annual Income is required"),
  // other_loans_info: Yup.string().required("Any Other Loans or EMI Availed is required"),
  // other_loans_product: Yup.string().required("Loan Product is required"),
  // other_loans_loan_no: Yup.string().required("Loan Number is required"),
  // other_loans_amount: Yup.string().required("Amount is required"),
  // other_loans_emi: Yup.string().required("EMI is required"),
})
PersonalInfo.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
}

export default PersonalInfo