import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import PaginationComponent from "react-reactstrap-pagination";
import paginationFactory from "react-bootstrap-table2-paginator";

// import swal from "sweetalert";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import { EpochToDate } from "../CommonComponents/Helper";
// import SearchBar from "../CommonComponents/SearchBar";
// import { Link } from "react-router-dom";


function FiClients() {
  // var history = useHistory();
  const [clientsList, setClientsList] = useState([]);
//   const [clientDetails, setClientDetails] = useState([]);
//   const [addClientModal, setAddClientModal] = useState(false);
// //   const [editClientModal, setEditClientModal] = useState(false);
//   const [client, setClient] = useState(null);
  const [totalElements, setTotalElements] = useState(1);
//   const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadClientsData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadClientsData = (pageNo, key) => {
    setIsLoading(true);

    var body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
    //   searchKey: key,
    };

    moduleApi
      .postData(
        apiCall.getFiClientNames,
        body
      )
      .then((response) => {
        // setCurrPage(pageNo);
        var listData = [];
        response?.forEach((element) => {
          let data = {
            id: element.id ? element.id : "-",
            empName: element.empName ? element.empName : "-",
            empId: element.empId ? element.empId : "-",
            client: element.client ? element.client : "-",
            createdTime: element.createdTime
              ? EpochToDate(element.createdTime)
              : "-",
            addedByUserId: element.addedByUserId ? element.addedByUserId : "-"
          };
          listData.push(data);
        });
        setClientsList(listData);
        setIsLoading(false);

        setTotalElements(response?.length);
      });
  };

  // const addClient = () => {
  //   setIsLoading(true);
  //   if (client === null) {
  //     setIsLoading(false);
  //     return swal("Please Enter Client Name", {
  //       icon: "error",
  //       dangerMode: true,
  //     });
  //   }else{
  //     if (client.trim().length===0) {
  //       setIsLoading(false);
  //       return swal("Please enter valid Client Name", {
  //         icon: "error",
  //         dangerMode: true,
  //       });
  //     }
  //   }

  //   if (client.length > 50) {
  //     setIsLoading(false);
  //     return swal("Client Should be of max 50 characters", {
  //       icon: "error",
  //       dangerMode: true,
  //     });
  //   }

  //   var payload = {
  //     addedByUserId: userLoginDetails?.autoFinanceUser?.userId,
  //     organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
  //     client: client,
  //   };
  //   moduleApi.postData(apiCall.addFiClientName, payload).then((response) => {

  //     if (response) {
  //       if (response?.message) {
  //         setIsLoading(false);
  //         swal(response?.message, {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       } else if (response) {
  //         setIsLoading(false);
  //         loadClientsData();
  //         setAddClientModal(false);
  //         return swal("Client Added Successfully", {
  //           icon: "success",
  //         });
  //       }
  //     } else {
  //       setIsLoading(false);
  //       return swal("Something Went Wrong, Please Try Later", {
  //         icon: "error",
  //         dangerMode: true,
  //       });
  //     }
  //   });
  // };

//   const editClient = () => {
//     setIsLoading(true);
//     if (client === null) {
//       setIsLoading(false);
//       return swal("Please Enter Client Name", {
//         icon: "error",
//         dangerMode: true,
//       });
//     }else{
//       if (client.trim().length===0) {
//         setIsLoading(false);
//         return swal("Please enter valid Client", {
//           icon: "error",
//           dangerMode: true,
//         });
//       }
//     }

//     if (client.length > 50) {
//       setIsLoading(false);
//       return swal("Client Should be of max 50 characters", {
//         icon: "error",
//         dangerMode: true,
//       });
//     }

//     var payload = {
//       id: clientDetails.id,
//       addedByUserId: userLoginDetails?.autoFinanceUser?.userId,
//       organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
//       client: client,
//     };
//     moduleApi.postData(apiCall.updateclient, payload).then((response) => {

//       if (response) {
//         if (response?.message) {
//           setIsLoading(false);
//           swal(response?.message, {
//             icon: "error",
//             dangerMode: true,
//           });
//         } else if (response) {
//           setIsLoading(false);
//           loadClientsData(currPage);
//           setEditClientModal(false);
//           return swal("Client Updated Successfully", {
//             icon: "success",
//           });
//         }
//       } else {
//         setIsLoading(false);
//         return swal("Something Went Wrong, Please Try Later", {
//           icon: "error",
//           dangerMode: true,
//         });
//       }
//     });
//   };

//   const searchKeyword = (key) => {
//     loadClientsData(0, key);
//   };

  const ClientColumns = [
    {
      text: "Client Name",
      dataField: "client",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created By Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created By Id",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Created Date & Time",
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "",
    //   dataField: "id",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if(hasAccess(87)){
    //     return (
    //       <div className="d-flex">
    //         <Link to="#" className="btn btn-info shadow btn-xs sharp">
    //           <i className="fa fa-edit"></i>
    //         </Link>
    //       </div>
    //     );
    //   }else{
    //     return <></>;
    //   }

    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       setClient(row.client);
    //       setClientDetails(row);
    //       setEditClientModal(true);
    //     },
    //   },
    // },
    // {
    //   text: "",
    //   dataField: "id",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if(hasAccess(87)){
    //     return (
    //       <div className="d-flex">
    //         <Link to="#" className="btn btn-danger shadow btn-xs sharp">
    //           <i className="fas fa-trash" />
    //         </Link>
    //       </div>
    //     );
    //   }else{
    //     return <></>;
    //   }
    //   },
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       swal({
    //         title: "Delete",
    //         text: "Are you sure, you want to Delete the Client?",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //       }).then((willDelete) => {
    //         if (willDelete) {
    //           var payLoad = {
    //             organizationId: userLoginDetails.autoFinanceUser.organizationId,
    //             id: row.id,
    //           };
    //           moduleApi
    //             .postData(apiCall.deleteclient, payLoad)
    //             .then((response) => {
    //               if (
    //                 response?.Message ===
    //                 "Company Client Deleted successfully"
    //               ) {
    //                 loadClientsData(0);
    //                 swal("Client Deleted successfully", {
    //                   icon: "success",
    //                 });
    //               } else if (response?.Message) {
    //                 swal(response.Message, {
    //                   icon: "error",
    //                   dangerMode: true,
    //                 });
    //               }
    //             });
    //         }
    //       });
    //     },
    //   },
    // },
  ];

  const paginationOptions = {
    custom: false,
    totalSize: totalElements,
    sizePerPage: 10,
    showTotal: false,
    pageStartIndex: 1,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "Next page",
    prePageTitle: "Previous page",
    firstPageTitle: "First page",
    lastPageTitle: "Last page",
    disablePageTitle: true,
    sizePerPageList: [

    ]
  };

//   const onPageChange = (selectedPage) => {
//     setCurrPage(selectedPage - 1);
//     loadClientsData(selectedPage - 1);
//   };

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >

        {/* <SearchBar
          placeholder="Search By Company Client"
          searchKeyword={searchKeyword}
        /> */}
        {/* {hasAccess(87) &&( */}
        {/* <Button
          style={{
            float: "right",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => {
            setClient(null);
            setAddClientModal(true)
          }}
        >
          <b> + Add Client </b>
        </Button> */}
        {/* )} */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            {/* <BootstrapTable
              keyField="panNo"
              data={clientsList}
              columns={ClientColumns}
            /> */}
            <BootstrapTable
        keyField="id"
        data={clientsList}
        columns={ClientColumns}
        pagination={paginationFactory(paginationOptions)}
        noDataIndication={<div className="d-flex justify-content-center">
        <img src={NO_DATA_FOUND} alt="" />
      </div>}
      />
          </div>
          {/* {clientsList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
         <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>  */}
        </div>
      </LoadingOverlay>
      {/* <Modal className="fade" size="md" show={addClientModal}>
        <Modal.Header>
          <Modal.Title>Add Client</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddClientModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>Client Name</h6>
                </label>
                <input
                  id="clientName"
                  className="form-control"
                  type="text"
                  placeholder="Enter Client Name"
                  name="clientName"
                  onChange={(e) => {
                    setClient(e.target.value);
                  }}
                  style={{ padding: "11px" }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => addClient()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => setAddClientModal(false)}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* <Modal className="fade" size="md" show={editClientModal}>
        <Modal.Header>
          <Modal.Title>Edit Company Client</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditClientModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>Company Client</h6>
                </label>
                <input
                  id="clientName"
                  className="form-control"
                  type="text"
                  placeholder="Enter Company Client"
                  name="clientName"
                  defaultValue={client}
                  onChange={(e) => {
                    setClient(e.target.value);
                  }}
                  style={{ padding: "11px" }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => editClient()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => setEditClientModal(false)}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default FiClients;
