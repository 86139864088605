import React, { useState, useEffect } from 'react'
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import TextField from "../../CommonComponents/TextField";
import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import { apiCall } from "../../rest/restApi";
import axios from 'axios';
import ReactGA from "react-ga";

function UploadFiReport({ setShowUploadReportModal,selectedAgency,getAgencyAllocations }) {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileName, setFileName] = useState(null)
    const [fileError, setFileError] = useState(null)
    const validate = Yup.object({
        applicationNo: Yup.string().required("Application No is required"),
        customerName: Yup.string().required("Customer Name is required"),
        remarks: Yup.string().required("Remarks is required"),
        applicantType: Yup.string().required("Applicant Type is required"),
        fiType: Yup.string().required("FI Type is required"),
        fiResult: Yup.string().required("FI Result is required")
    });
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    // let applicantTypeOptions = [
    //     { label: "Applicant", value: "Applicant" },
    //     { label: "Co-Applicant", value: "Co-Applicant" },
    //     { label: "Guarantor", value: "Guarantor" }
    // ];
    // let fiTypeOptions = [
    //     { label: "Office", value: "Office" },
    //     { label: "Residence", value: "Residence" }
    // ]


    useEffect(() => {
        ReactGA.pageview('Upload Fi Report');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let fiResultOptions = [
        { label: "Positive", value: "Positive" },
        { label: "Negative", value: "Negative" },
        { label: "Refer to Credit", value: "Refer to Credit" },
        { label: "Door Locked", value: "Door Locked" }
    ]
    const fileType = [
        "application/pdf"
    ];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        setUploadedFile(selectedFile)
        if (selectedFile && fileType.includes(selectedFile.type)) {
            setFileName(selectedFile.name);
            setFileError(null)
        } else {
            setFileError("Please upload report in pdf format");
        }
    };
    const uploadFileToS3Bucket = (values) => {
        let uploadImagePayload = {
            fileKeyName: fileName,
            type: 'PDF'
        }
        nodeApi
            .postData(apiCall.getImageUrl, uploadImagePayload)
            .then((response) => {
                const url = response.imageUrls.fileUploadURL
                const keyName = response.imageUrls.fileKeyName
                axios({
                    method: "PUT",
                    url: url,
                    data: uploadedFile,
                    headers: { "Content-Type": "application/pdf" }
                })
                    .then(res => {
                        //let filePath = res.config.url.split('?')[0]
                        let requestPayload = {
                            applicationNo: values.applicationNo,
                            customerName: values.customerName,
                            remarks: values.remarks,
                            applicantType: values.applicantType,
                            fiType: values.fiType,
                            pdfFileUrl: keyName,
                            fiResult:values.fiResult
                        }
                        nodeApi
                            .postData(apiCall.saveFiAllocationsPdfReport, requestPayload)
                            .then((response) => {
                                if (response.success === true) {
                                    toaster("success", "Submitted FI Report successfully");
                                    let requestPayload = {
                                        agencyId: userData.fieldInvestigationAgency.agencyId,
                                        organizationId: userData.fieldInvestigationAgency.organizationId,
                                        startDate: null,
                                        endDate: null
                                    }
                                    getAgencyAllocations(requestPayload)
                                    setShowUploadReportModal(false)
                                } else {
                                    toaster("error", "Invalid Application No / Applicant Type / FI Type");
                                    setShowUploadReportModal(false)
                                }
                            })
                            .catch((error) => {
                                toaster("error", "Something Went Wrong, Please try again later");
                                setShowUploadReportModal(false)
                            });
                    })
                    .catch(err => {
                        console.log(err)
                    });
            });
    }
    const handleSubmit = (values) => {
        uploadFileToS3Bucket(values);
    };
    const initialValues = {
        applicationNo: selectedAgency.application_number,
        customerName: selectedAgency.customer_name,
        remarks: "",
        applicantType: selectedAgency.applicant_type,
        fiType: selectedAgency.fi_type,
        fiResult:""
    }

    return (
        <div className="form">
            <Formik
                initialValues={initialValues}
                validationSchema={validate}
                onSubmit={handleSubmit}
            >
                {({ formik, errors }) => (
                    <div>
                        <Row>
                            <Col lg="12">
                                <Form className="form-group"
                                    autoComplete="off"
                                >
                                    <div>
                                        <TextField
                                            type="text"
                                            name="applicationNo"
                                            isAsterisk={true}
                                            label="Application No"
                                            disabled={true}
                                        />
                                        <TextField
                                            type="text"
                                            name="customerName"
                                            isAsterisk={true}
                                            label="Customer Name"
                                            disabled={true}
                                        />

                                        <TextField
                                            type="text"
                                            name="applicantType"
                                            isAsterisk={true}
                                            label="Applicant Type"
                                            disabled={true}
                                        />

                                        <TextField
                                            type="text"
                                            name="fiType"
                                            isAsterisk={true}
                                            label="FI Type"
                                            disabled={true}
                                        />

                                        <Field name="fiResult">
                                            {({ field, form }) => (
                                                <div
                                                    style={{ display: "flex", flexDirection: "column",marginTop:10 }}
                                                >
                                                    <label
                                                        className="control-label"
                                                        style={{
                                                            fontWeight: "bold",
                                                            float: "left",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        FI Result
                                                    </label>
                                                    <Select
                                                        placeholder="Select FI Result"
                                                        classNamePrefix="select-box"
                                                        options={fiResultOptions}
                                                        onChange={(selectedOption) => {
                                                            form.setFieldValue(
                                                                "fiResult",
                                                                selectedOption.value
                                                            );

                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        {errors.fiResult && (
                                            <span className="error">{errors.fiResult}</span>
                                        )} 
                                        <TextField
                                            type="text"
                                            name="remarks"
                                            isAsterisk={true}
                                            label="Remarks"
                                        />
                                        <label>
                                            <h6>Upload FI Report</h6>
                                        </label>
                                        <br />
                                        <input
                                            type="file"
                                            className="form-control file-upload-field"
                                            required
                                            onChange={handleFile}
                                            onClick={(event) => {
                                                event.target.value = null;
                                            }}
                                        ></input>
                                        {fileError && (
                                            <div className="text-danger excel-file-error">
                                                {fileError}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-submit-custom"
                                            style={{float:'right',fontSize:'12px'}}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                )}
            </Formik>
        </div>
    )
}

export default UploadFiReport