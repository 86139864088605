import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as Yup from "yup";
import TextField from "../CommonComponents/TextField";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess } from "../services/AuthService";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";


function Holidays() {
  let userData = localStorage.getItem("userDetails");
  let comingUserDetails = JSON.parse(userData);

  const [onLoad, setOnLoad] = useState([]);
  const [addHolidayModal, setAddHolidayModal] = useState(false);
  const [editHolidayModal, setEditHolidayModal] = useState(false);
  const [selectYear] = useState();
  const [holidayDetails, setHolidayDetails] = useState([]);

  useEffect(() => {
    ReactGA.pageview('Holidays');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      text: "Date",
      dataField: "dateWithYear",
      key: "projectName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        width: "100px",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontFamily:
          " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        fontSize: "13px",
      },
    },
    {
      text: "Holidays",
      dataField: "holidayName",
      key: "holidayName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        width: "200px",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontFamily:
          " -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        fontSize: "14px",
      },
    },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        width: "30px",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit"></i>
              </Link>
            </div>
          );
        
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // localStorage.setItem('executiveVisitedDetails', JSON.stringify(row))
          //setVisitedPlacesDetailsList(JSON.parse(localStorage.getItem('executiveVisitedDetails')));
          setHolidayDetails(row);
          setEditHolidayModal(true);
          //setToggle("chatbox");

          //   <Link  to="/executive-details">
          //         <i className="fa fa-trash"></i>
          //       </Link>
        },
      },
    },


  ];

  useEffect(() => {
    getAllHolidays();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllHolidays = () => {
    moduleApi
      .postData(
        apiCall.getHolidays + comingUserDetails.autoFinanceUser.organizationId,
        selectYear
      )
      .then((response) => {
        setOnLoad(response);
      });
  };

  const addholiday = (values) => {
    var body = {
      date: Math.floor(new Date(values.date).getTime() / 1000.0),
      dateWithYear: "",
      holidayDescription: "",
      holidayName: values.holidayName,
      organizationId: comingUserDetails.autoFinanceUser.organizationId,
    };

    moduleApi.postData(apiCall.saveHoliday, body).then((response) => {
      if (response) {
        if (response?.Message === "Holidays List Add SucessFully") {
          swal("Holiday added Successfully", {
            icon: "success",
          });
          getAllHolidays();
          setAddHolidayModal(false);
        } else if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };


  const editholiday = (values) => {
    var body2 = {
      
        id:holidayDetails.id,
        date: Math.floor(new Date(values.date).getTime() / 1000.0),
        dateWithYear: "",
        holidayDescription: "",
        holidayName: values.holidayName,
        organizationId: comingUserDetails.autoFinanceUser.organizationId,
    };

    moduleApi.postData(apiCall.updateHoliday, body2).then((response) => {

      console.log(response,"Responseeeee")
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
          
        } else if (response) {
          swal("Holiday Edited Successfully", {
            icon: "success",   
          });
          getAllHolidays();
          setEditHolidayModal(false);

        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };
  const validate = Yup.object({
    date: Yup.date()
      .required("Date is required")
      .min(new Date(new Date().getFullYear(), 0, 1))
      .max(new Date(new Date().getFullYear(), 11, 31)),
    holidayName: Yup.string().required("Holiday Name is required"),
  });

  return (
    <div style={{ margin: "20px", marginLeft: "250px", marginRight: "250px" }}>
      {
      // comingUserDetails.isSuperAdmin !== true 
      // &&
       hasAccess(12) && (
        <Button
          color="primary"
          backgroundColor="#fff"
          border="1px solid #cfcfcf"
          type="button"
          style={{
            marginRight: "20px",
            marginBottom: "20px",
            float: "right",
            display: "flex",
          }}
          onClick={() => setAddHolidayModal(true)}
        >
          Add Holiday
        </Button>
      )}
      <BootstrapTable keyField="projectName" data={onLoad} columns={columns} />
      {onLoad?.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            height: "80px",
            marginTop: "50px",
            marginBottom: "10%",
          }}
        >
          <h4> There is no data added yet.</h4>
        </div>
      ) : null}

      <Modal className="fade" show={addHolidayModal}>
        <Modal.Header>
          <Modal.Title>Add Holiday</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddHolidayModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          {/* <div style={{ margin: "10px" }}>
            <div
              style={{
                display: "flex",
                marginLeft: "20px",
                marginBottom: "20px",
                width: "400px",
              }}
            >
              Calendar Year:{" "}
              <input
                style={{
                  width: "50px",
                  paddingLeft: "5px",
                  marginLeft: "5px",
                  marginTop: "-3px",
                  borderRadius: "5px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                }}
                type="text"
                placeholder="2020"
                maxLength="4"
                onChange={(e) => yearChange(e.target.value)}
              ></input>
            </div>

            {inputList.map((x, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    marginLeft: "20px",
                    marginTop: "31px",
                  }}
                >
                  <div>
                    <input
                      style={{
                        marginRight: "10px",
                        height: "30px",
                        width: "150px",
                        paddingLeft: "5px",
                        borderRadius: "5px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #3a353b",
                      }}
                      name="date"
                      type="date"
                      placeholder="Enter The Date"
                      value={x.date}
                      rows={2}
                      onChange={(e) => handleInputChange(e, i, "date")}
                    />
                  </div>
                  <input
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      height: "30px",
                      width: "300px",
                      paddingLeft: "5px",
                      borderRadius: "5px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                    }}
                    name="description"
                    placeholder="Enter The Holiday Name"
                    value={x.description}
                    rows={2}
                    onChange={(e) => handleInputChange(e, i, "description")}
                  />

                  {inputList.length !== 1 && (
                    <Button
                      variant="danger"
                      style={{ marginLeft: "15px" }}
                      onClick={() => handleRemoveClick(i)}
                    >
                      {" "}
                      Delete{" "}
                    </Button>
                  )}
                  {inputList.length - 1 === i ? (
                    <Button
                      style={{ marginLeft: "15px", marginBottom: "2px" }}
                      onClick={handleAddClick}
                    >
                      {" "}
                      +Add{" "}
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
            <Button
              type="primary"
              style={{
                float: "right",
                marginButtom: "20px",
                marginTop: "20px",
                marginRight: "15px",
              }}
              onClick={() => addholidays()}
            >
              Submit
            </Button>
          </div> */}

          <div>
            <Row>
              <Col>
                <Formik
                  initialValues={{
                    date: "",
                    holidayName: "",
                  }}
                  onSubmit={addholiday}
                  validationSchema={validate}
                >
                  {({ formik, errors }) => (
                    <div>
                      <Row>
                        <Col lg="12">
                          <Form>
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Date"
                                name="date"
                                type="date"
                              />

                              <TextField
                                isAsterisk={true}
                                label="Holiday Name"
                                name="holidayName"
                                type="text"
                              />
                            </div>

                            <div>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2 float-right"
                                variant="danger light"
                                onClick={() => setAddHolidayModal(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={editHolidayModal}>
        <Modal.Header>
          <Modal.Title>Edit Holiday</Modal.Title>
          <Button
            variant=""hidden
            className="btn-close"
            onClick={() => setEditHolidayModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Formik
                  initialValues={{
                    date: "",
                    holidayName:holidayDetails.holidayName,

                  }}
                  onSubmit={editholiday}
                  validationSchema={validate}
                >
                  {({ formik, errors }) => (
                    <div>
                      <Row>
                        <Col lg="12">
                          <Form>
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Date"
                                name="date"
                                type="date"
                              />

                              <TextField
                                isAsterisk={true}
                                label="Holiday Name"
                                name="holidayName"
                                type="text"
                              />
                            </div>

                            <div>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2 float-right"
                                variant="danger light"
                                onClick={() => setEditHolidayModal(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>



    </div>
  );
}

export default Holidays;
