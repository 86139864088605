import React from "react";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import TextField from "../../CommonComponents/TextField";
import { Field } from "formik";
import Select from "react-select";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const PrimaryInfo = ({ key, mainOBJ, onChange, errors }) => {
  let addressVerificationDoneOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  let relationshipWithApplicantOptions = [
    { label: "SELF", value: "SELF" },
    { label: "Spouse", value: "Spouse" },
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Father In Law", value: "Father In Law" },
    { label: "Mother In Law", value: "Mother In Law" },
    { label: "Others", value: "Others" },
  ];

  // const getDefaultValue = (options, value) => {
  //   options?.forEach((element) => {
  //     if (element.value === value) {
  //       var tempObj ={ label: element.label, value: element.value }
  //       return tempObj;
  //     }
  //   });
  // };

  return (
    <div style={{ margin: "15px", maxHeight: "400px", overflowY: "auto" }}>
      <Row>
        <Col>
          <TextField
            // isAsterisk={true}
            label="Application Number"
            name="application_number"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Applicant's Name"
            name="customer_name"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Applicant Type"
            name="applicant_type"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="FI Type"
            name="fi_type"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Client"
            name="client"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Client Branch"
            name="client_branch"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Client State"
            name="client_state"
            type="text"
            disabled
          />
          {/* <TextField
            isAsterisk={true}
            label="Loan Type"
            name="loan_type"
            type="text"
            disabled
          /> */}
          <TextField
            // isAsterisk={true}
            label="Loan Type/Product Type"
            name="product_type"
            type="text"
            disabled
          />
          {/* <TextField
            // isAsterisk={true}
            label="Product Name"
            name="product_name"
            type="text"
            disabled
          /> */}
          <TextField
            // isAsterisk={true}
            label="CPV Verification Geo Limit"
            name="cpv_verification_geo_limit"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Father/Spouse Name"
            name="father_name"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Contact Number"
            name="contact_no"
            type="text"
            disabled
          />
        </Col>
        <Col>
          
          <TextField
            // isAsterisk={true}
            label="Residence Address"
            name="address_line1"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="City"
            name="city"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="State"
            name="state"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Pin Code"
            name="postal_code"
            type="text"
            disabled
          />
          <TextField
            // isAsterisk={true}
            label="Landmark"
            name="nearby_landmark"
            type="text"
            disabled
          />
          {/* <TextField
            isAsterisk={true}
            label="Actual Address Verification Done"
            name="actual_address_verified"
            type="text"
          /> */}
          <Field name="actual_address_verified">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Actual Address Verification Done
                </label>
                <Select
                  placeholder="Select Actual Address Verification Done"
                  classNamePrefix="select-box"
                  options={addressVerificationDoneOptions}
                  defaultValue={{
                    label: mainOBJ?.actual_address_verified,
                    value: mainOBJ?.actual_address_verified,
                  }}
                  // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
                  onChange={(value) => {
                    form.setFieldValue("actual_address_verified", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.actual_address_verified && (
            <span className="error">{errors.actual_address_verified}</span>
          )}
          <TextField
            // isAsterisk={true}
            label="Distance"
            name="distance"
            type="text"
          />
          <TextField
            isAsterisk={true}
            label="Contacted Person Name"
            name="contacted_person_name"
            type="text"
          />
          {/* <TextField
            isAsterisk={true}
            label="Relationship with the Applicant"
            name="relationship_with_applicant"
            type="text"
          /> */}
          <Field name="relationship_with_applicant">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Relationship with the Applicant
                </label>
                <Select
                  placeholder="Select Relationship with the Applicant"
                  classNamePrefix="select-box"
                  options={relationshipWithApplicantOptions}
                  defaultValue={{
                    label: mainOBJ?.relationship_with_applicant,
                    value: mainOBJ?.relationship_with_applicant,
                  }}
                  // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
                  onChange={(value) => {
                    form.setFieldValue(
                      "relationship_with_applicant",
                      value.value
                    );
                  }}
                />
              </div>
            )}
          </Field>
          {errors.relationship_with_applicant && (
            <span className="error">{errors.relationship_with_applicant}</span>
          )}
          {/* <TextField
            isAsterisk={true}
            label="End User of the Product"
            name="enduser_of_product"
            type="text"
          /> */}
          <Field name="enduser_of_product">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  End User of the Product
                </label>
                <Select
                  placeholder="Select End User of the Product"
                  classNamePrefix="select-box"
                  options={relationshipWithApplicantOptions}
                  defaultValue={{
                    label: mainOBJ?.enduser_of_product,
                    value: mainOBJ?.enduser_of_product,
                  }}
                  // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
                  onChange={(value) => {
                    form.setFieldValue("enduser_of_product", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.enduser_of_product && (
            <span className="error">{errors.enduser_of_product}</span>
          )}
        </Col>
      </Row>
    </div>
  );
};

PrimaryInfo.label = "Primary Info";
PrimaryInfo.initialValues = {
  application_number: "",
  customer_name: "",
  applicant_type: "",
  fi_type: "",
  client: "",
  client_branch: "",
  client_state: "",
  // loan_type: "",
  product_type: "",
  // product_name: "",
  cpv_verification_geo_limit: "",
  father_name: "",
  contact_no: "",
  address_line1: "",
  city: "",
  state: "",
  postal_code: "",
  nearby_landmark: "",
  actual_address_verified: "",
  distance: "",
  contacted_person_name: "",
  relationship_with_applicant: "",
  enduser_of_product: "",
};

PrimaryInfo.validationSchema = Yup.object({
  // application_number: Yup.string().required("Application Number is required"),
  // customer_name: Yup.string().required("Applicant's Name is required"),
  // applicant_type: Yup.string().required("Applicant Type is required"),
  // fi_type: Yup.string().required("FI Type is required"),
  // client: Yup.string().required("Client is required"),
  // client_branch: Yup.string().required("Client Branch is required"),
  // client_state: Yup.string().required("Client State is required"),
  // // loan_type: Yup.string().required("Loan Type is required"),
  // product_type: Yup.string().required("Loan Type/Product Type is required"),
  // product_name: Yup.string().required("Product Name is required"),
  // cpv_verification_geo_limit: Yup.string().required(
  //   "CPV Verification Geo Limit is required"
  // ),
  // father_name: Yup.string().required("Father/Spouse Name is required"),
  // contact_no: Yup.string().required("Contact Number is required"),
  // address_line1: Yup.string().required("Residence Address is required"),
  // city: Yup.string().required("City is required"),
  // state: Yup.string().required("State is required"),
  // postal_code: Yup.string().required("Pin Code is required"),
  // nearby_landmark: Yup.string().required("Landmark is required"),
  actual_address_verified: Yup.string().required(
    "Actual Address Verification Done is required"
  ),
  // distance: Yup.string().required("Distance is required"),
  contacted_person_name: Yup.string().required(
    "Contacted Person Name is required"
  ),
  relationship_with_applicant: Yup.string().required(
    "Relationship with the Applicant is required"
  ),
  enduser_of_product: Yup.string().required(
    "End User of the Product is required"
  ),
});
PrimaryInfo.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
};

export default PrimaryInfo;
