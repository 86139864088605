export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_LOADING2 = "SET_IS_LOADING2";
export const SET_BASIC_MODAL_FOR_PAYMENT_LINKS =
  "SET_BASIC_MODAL_FOR_PAYMENT_LINKS";
export const SET_PAYMENT_LINKS_BY_ALLOCATION_ID =
  "SET_PAYMENT_LINKS_BY_ALLOCATION_ID";
export const SET_ADD_REMARKS_BASIC_MODAL = "SET_ADD_REMARKS_BASIC_MODAL";
export const SET_CALL_REPORT_MODAL = "SET_CALL_REPORT_MODAL";
export const SET_MOBILE_NO = "SET_MOBILE_NO";
export const SET_ALLOCATION_DETAILS = "SET_ALLOCATION_DETAILS";
export const SET_ALLOCATION_DETAILS_MODAL = "SET_ALLOCATION_DETAILS_MODAL";
export const SET_BASIC_MODAL_FOR_SEND_PAYMENT_LINK =
  "SET_BASIC_MODAL_FOR_SEND_PAYMENT_LINK";
export const SET_REMARKS = "SET_REMARKS";
export const SET_IS_MINUTES_SELECTED = "SET_IS_MINUTES_SELECTED";
export const SET_IS_HOURS_SELECTED = "SET_IS_HOURS_SELECTED";
export const SET_IS_DAYS_SELECTED = "SET_IS_DAYS_SELECTED";
export const SET_TOTAL_ELEMENTS = "SET_TOTAL_ELEMENTS";
export const SET_CURR_PAGE = "SET_CURR_PAGE";
export const SET_REQ_PAYMENTS_LIST = "SET_REQ_PAYMENTS_LIST";
export const SET_PAYMENTS_DOWNLOAD_LIST = "SET_PAYMENTS_DOWNLOAD_LIST";
export const SET_ADD_REMARKS_ALLOCATION_ID = "SET_ADD_REMARKS_ALLOCATION_ID";
export const SET_DATA_FOR_SEND_CUSTOMER_LINK =
  "SET_DATA_FOR_SEND_CUSTOMER_LINK";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const SET_SELECTED_VALUE = "SET_SELECTED_VALUE";
export const SET_SELECTED_STATUS = "SET_SELECTED_STATUS";
export const SET_SORT_INFO = "SET_SORT_INFO";
export const SET_OBJECT = "SET_OBJECT";
export const SET_ALLOCATIONS_LIST = 'SET_ALLOCATIONS_LIST';
export const SET_FILTERED_DATA = 'SET_FILTERED_DATA';
export const SET_TEMP_DATA = 'SET_TEMP_DATA';
export const SET_SKIP_INDEX = 'SET_SKIP_INDEX';


export function setIsLoading(payload) {
  return {
    type: SET_IS_LOADING,
    payload,
  };
}

export function setIsLoading2(payload) {
  return {
    type: SET_IS_LOADING2,
    payload,
  };
}

export function setBasicModalForPaymentLinks(payload) {
  return {
    type: SET_BASIC_MODAL_FOR_PAYMENT_LINKS,
    payload,
  };
}

export function setPaymentLinksByAllocationId(payload) {
  return {
    type: SET_PAYMENT_LINKS_BY_ALLOCATION_ID,
    payload,
  };
}

export function setAddRemarksBasicModal(payload) {
  return {
    type: SET_ADD_REMARKS_BASIC_MODAL,
    payload,
  };
}

export function setCallReportModal(payload) {
  return {
    type: SET_CALL_REPORT_MODAL,
    payload,
  };
}

export function setMobileNo(payload) {
  return {
    type: SET_MOBILE_NO,
    payload,
  };
}

export function setAllocationDetails(payload) {
  return {
    type: SET_ALLOCATION_DETAILS,
    payload,
  };
}

export function setAllocationDetailsModal(payload) {
  return {
    type: SET_ALLOCATION_DETAILS_MODAL,
    payload,
  };
}

export function setBasicModalForSendPaymentLink(payload) {
  return {
    type: SET_BASIC_MODAL_FOR_SEND_PAYMENT_LINK,
    payload,
  };
}

export function setRemarks(payload) {
  return {
    type: SET_REMARKS,
    payload,
  };
}

export function setIsMinutesSelected(payload) {
  return {
    type: SET_IS_MINUTES_SELECTED,
    payload,
  };
}

export function setIsHoursSelected(payload) {
  return {
    type: SET_IS_HOURS_SELECTED,
    payload,
  };
}

export function setIsDaysSelected(payload) {
  return {
    type: SET_IS_DAYS_SELECTED,
    payload,
  };
}

export function setTotalElements(payload) {
  return {
    type: SET_TOTAL_ELEMENTS,
    payload,
  };
}

export function setCurrPage(payload) {
  return {
    type: SET_CURR_PAGE,
    payload,
  };
}

export function setReqPaymentsList(payload) {
  return {
    type: SET_REQ_PAYMENTS_LIST,
    payload,
  };
}

export function setPaymentsDownloadList(payload) {
  return {
    type: SET_PAYMENTS_DOWNLOAD_LIST,
    payload,
  };
}

export function setAddRemarksAllocationId(payload) {
  return {
    type: SET_ADD_REMARKS_ALLOCATION_ID,
    payload,
  };
}

export function setDataForSendCustomerLink(payload) {
  return {
    type: SET_DATA_FOR_SEND_CUSTOMER_LINK,
    payload,
  };
}

export function setCampaigns(payload) {
  return {
    type: SET_CAMPAIGNS,
    payload,
  };
}

export function setSelectedValue(payload) {
  return {
    type: SET_SELECTED_VALUE,
    payload,
  };
}

export function setSelectedStatus(payload) {
  return {
    type: SET_SELECTED_STATUS,
    payload,
  };
}

export function setSortInfo(payload) {
  return {
    type: SET_SORT_INFO,
    payload,
  };
}

export function setObject(payload) {
  return {
    type: SET_OBJECT,
    payload,
  };
}


export const setFilteredData = (payload) => ({
  type: SET_FILTERED_DATA,
  payload,
});

export const setAllocationsList = (payload) => ({
  type: SET_ALLOCATIONS_LIST,
  payload,
});

export const setTempData = (payload) => ({
  type: SET_TEMP_DATA,
  payload,
});
export const setSkipIndex = (payload) => ({
  type: SET_SKIP_INDEX,
  payload,
});


