import React from 'react'
import { Button, Modal } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { convertToLocalDateTime } from '../utils';
function DetailedAllocations({detailedViewModal,setDetailedViewModal,selectedRow}) {
  return (
    <Modal className="fade" size="lg" show={detailedViewModal}>
      <Modal.Header>
        <Modal.Title>
         Agency Allocation Details
        </Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => setDetailedViewModal(false)}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <Row>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    margin: "30px",
                    marginLeft: "20px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "40%",
                        }}
                      >
                        Applicant Type
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.applicant_type}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "40%",
                        }}
                      >
                        Application No
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.application_number}
                      </td>
                    </tr>
                   
                        <tr>
                          <td
                            style={{
                              fontWeight: "500",
                              textAlign: "left",
                              width: "40%",
                            }}
                          >
                            Agency Name
                          </td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                            }}
                          >
                            {selectedRow.agency_name}
                          </td>
                        </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "40%",
                        }}
                      >
                        Product Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.product_name}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "40%",
                        }}
                      >
                        Product Type
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.product_type}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "40%",
                        }}
                      >
                        Customer Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.customer_name}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Contact No
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.contact_no}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        FI Type
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.fi_type}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Address Line 1
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.address_line1}
                      </td>
                    </tr>
                    {/* <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Address Line 2
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.address_line2}
                      </td>
                    </tr> */}
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        City
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.city}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        State
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.state}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Near by Landmark
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.nearby_landmark}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Postal Code
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.postal_code}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Customer Father's Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.father_name}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Assigned Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {convertToLocalDateTime(selectedRow.created_at, 'DATE_MM_DD_YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Due Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.due_date.substring(0, 10)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Allocation Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.status}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        FI Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.fi_status}
                      </td>
                    </tr>
                  </>
                </div>
              </div>
            </Col>
          </Row>
        </div>
       
      </Modal.Body>
    </Modal>
  )
}

export default DetailedAllocations