import React, { useState, useEffect } from "react";
import { Modal, Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import Papa from "papaparse";
import swal from "sweetalert";
import { AnalyticsConfig } from "../CommonComponents/AnalyticsConfig";
import * as nodeApi from "../rest/nodeApi";
import { apiCall } from "../rest/restApi";
import * as moduleApi from "../rest/moduleApi";
import { getTokenInLocalStorage } from "../services/AuthService";
import { camelize, getGroupedData } from "./utils";
import LoadingOverlay from "react-loading-overlay";
import "./analytics.css";
import ReactGA from "react-ga";
import PreviewGraph from "./PreviewGraph";

export default function AddGraphForm({
  serverData,
  getGraphsMetaData,
  graphs,
}) {
  const [selectedDataSrc, setSelectedDataSrc] = useState("");
  const [basicModal, setBasicModal] = useState(false);

  const [list, setList] = useState([]);
  const [xaxisValue, setXAxisValue] = useState("");
  const [yaxisValue, setYAxisValue] = useState("");
  const [xAxisOptions, setXAxisOptions] = useState(null);
  const [yAxisOptions, setYAxisOptions] = useState(null);
  const [chartType, setChartType] = useState("");
  const [graphName, setGraphName] = useState("");
  const [isStacked, setIsStacked] = useState(false);
  const [previewGraphData, setPreviewGraphdata] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const userData = getTokenInLocalStorage();
  const DataSourceOptions = [
    { label: "High Risk Counts", value: AnalyticsConfig.highriskcount },
    { label: "PTP Counts", value: AnalyticsConfig.ptpcount },
    {
      label: "Repeat High Risk Counts",
      value: AnalyticsConfig.repeathighriskcount,
    },
    {
      label: "Repeat PTP Counts",
      value: AnalyticsConfig.repeatptpcount,
    },
  ];
  const chartOptions = [
    { label: "Bar", value: "bar" },
    { label: "Area", value: "area" },
    { label: "Line", value: "line" },
    { label: "Pie", value: "pie" },
    { label: "Donut", value: "donut" },
  ];

  useEffect(() => {
    ReactGA.pageview("Add graph");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let xAxis = [];
    let yAxis = [];
    // eslint-disable-next-line
    list.map((item) => {
      // if (item.name !== "Orgshortcode" && item.name !== "Total Visits" && item.name !== "Thirdparty Count" && item.name !== "Rtp Count"
      // && item.name !== "Repossessed Count" && item.name !=='Unique Customer Ptp Cnt'
      //  && item.name !=='Ptp Count' && item.name !=="Paid Count") {
      if (item.name !== "Orgshortcode") {
        if (
          item.name === "Branch Name" ||
          item.name === "Visit Date" ||
          item.name === "Visit Mo" ||
          item.name === "Visit Year" ||
          item.name === "Risk Type"
        ) {
          xAxis.push({
            label: camelize(
              item.name !== "Visit Mo" ? item.name : "Visit Month"
            ),
            value: item.values,
            formattedLabel: item.name,
          });
        }
      }
    });
    setXAxisOptions(xAxis);
    // eslint-disable-next-line
    list.map((item) => {
      // if (item.name !== "Orgshortcode" && item.name !== "Branch Name" && item.name !== "Visit Date" &&
      //     item.name !== 'Visit Mo' && item.name !== "Visit Year") {
      if (item.name !== "Orgshortcode") {
        if (
          item.name !== "Branch Name" &&
          item.name !== "Visit Date" &&
          item.name !== "Visit Mo" &&
          item.name !== "Visit Year" &&
          item.name !== "Risk Type"
        ) {
          yAxis.push({
            label: camelize(item.name),
            value: item.values,
            formattedLabel: item.name,
          });
        }
      }
    });
    setYAxisOptions(yAxis);
  }, [list]);

  const toMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  const getPreSignedUrl = (file) => {
    var body = {
      fileName: file,
    };
    nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
      Papa.parse(response?.url, {
        download: true.valueOf,
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          const headers = results.data[0];
          results.data.splice(0, 1);
          const refinedList = [];
          headers.forEach((header, index) => {
            const values = [];
            results.data.forEach((row) => {
              values.push(row[index].trim());
            });
            refinedList.push({
              name: camelize(header.trim()),
              values: values,
            });
          });
          setList(refinedList);
        },
      });
    });
  };

  const validateGraph = () => {
    // let isSubmit = false;
    if (graphs?.length > 5) {
      swal(
        "Graphs Limit is exceeded.For adding one more, please delete one of the graph",
        {
          icon: "error",
          dangerMode: true,
        }
      );
      return false;
    } else if (selectedDataSrc === "") {
      setError({
        dataSource: {
          errorMsg: "Please Select Data Source",
        },
      });
      return false;
    } else if (chartType === "") {
      setError({
        chartType: {
          errorMsg: "Please Select Chart Type",
        },
      });
      return false;
    } else if (xaxisValue === "") {
      setError({
        xAxis: {
          errorMsg: "Please Select X-Axis",
        },
      });
      return false;
    } else if (yaxisValue === "" || yaxisValue === null) {
      setError({
        yAxis: {
          errorMsg: "Please Select Y-Axis",
        },
      });
      return false;
    } else if (graphName === "") {
      setError({
        graphName: {
          errorMsg: "Please Enter Graph Name",
        },
      });
      return false;
    } else {
      return true;
    }
    // return isSubmit;
  };
  const addGraph = (i) => {
    let isSubmit = validateGraph();
    if (isSubmit === true) {
      var tempObj = {
        organizationId: userData.autoFinanceOrganization.organizationId,
        userId: userData.autoFinanceUser.userId,
        xaxisValue: xaxisValue.formattedLabel,
        selectedFile: selectedDataSrc,
        yaxisValue: !isStacked ? [yaxisValue.formattedLabel] : [],
        chartType: chartType,
        graphName: camelize(graphName),
      };
      console.log(tempObj);
      isStacked &&
        yaxisValue.forEach((item) => {
          tempObj.yaxisValue.push(item.label);
        });
      var body = [tempObj];
      moduleApi.postData(apiCall.saveGraphsMetaData, body).then((response) => {
        if (response) {
          if (response.message) {
            swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            getGraphsMetaData();
            setBasicModal(false);
            swal("Graph Plotted Successfully!", {
              icon: "success",
            });
          }
        } else {
          swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
    }
  };

  const resetFormData = () => {
    setXAxisValue("");
    setYAxisValue("");
    setChartType("");
    setGraphName("");
    setSelectedDataSrc("");
    setIsPreview(false);
    setBasicModal(true);
  };

  const onValidate = (value, name) => {
    setError((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };
  const [error, setError] = useState({
    dataSource: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    chartType: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    xAxis: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    yAxis: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    graphName: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
  });

  const onChangeFunc = (value, type) => {
    switch (type) {
      case "DATASOURCE":
        setSelectedDataSrc(value.value);
        setIsPreview(false);
        getPreSignedUrl(value.value);
        setError({
          dataSource: {
            errorMsg: "",
          },
        });
        break;
      case "CHARTTYPE":
        setChartType(value.value);
        if (value.label !== "Pie" && value.label !== "Donut") {
          setYAxisValue("");
          setIsStacked(true);
          setIsPreview(false);
        } else {
          setYAxisValue("");
          setIsStacked(false);
          setIsPreview(false);
        }
        setError({
          chartType: {
            errorMsg: "",
          },
        });
        break;
      case "XAXIS":
        setXAxisValue(value);
        setIsPreview(false);
        setError({
          xAxis: {
            errorMsg: "",
          },
        });
        break;
      case "YAXIS":
        setYAxisValue(value);
        setIsPreview(false);
        setError({
          yAxis: {
            errorMsg: "",
          },
        });
        break;
      case "GRAPHNAME":
        setGraphName(value);
        setError({
          graphName: {
            errorMsg: "",
          },
        });
        break;
      default:
        setError({
          graphName: {
            errorMsg: "",
          },
        });
    }
  };

  const getPreviewData = (data) => {
    let isSubmit = validateGraph();
    if (isSubmit === true) {
      setIsLoading2(true);
      var temp = [];

      var date = [
        new Date(
          new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
            0,
            0,
            0
          )
        ),
        new Date(
          new Date(new Date().setDate(new Date().getDate())).setHours(
            23,
            59,
            59
          )
        ),
      ];
      var start = Math.floor(date[0].getTime() / 1000);
      var end = Math.floor(date[1].getTime() / 1000);
      var body = {
        fileName: selectedDataSrc,
      };

      nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
        // console.log("presignedurlresponse", response);

        Papa.parse(response.url, {
          download: true,
          complete: function (results) {
            // console.log("csvdata", results.data);
            let j;
            if (
              results.data[0].includes("date") ||
              results.data[0].includes("due_date") ||
              // results.data[0].includes("date_time") ||
              results.data[0].includes("visit_date")
            ) {
              for (let i = 0; i < results.data[0].length; i++) {
                if (
                  results.data[0][i] === "date" ||
                  results.data[0][i] === "due_date" ||
                  // results.data[0][i] === "date_time" ||
                  results.data[0][i] === "visit_date"
                ) {
                  j = i;
                }
              }
            }
            results.data.forEach((data, index) => {
              if (
                new Date(data[j]) / 1000 >= start &&
                new Date(data[j]) / 1000 <= end
              ) {
                temp.push(data);
              }
            });
            var csvHearders = [];
            results.data[0].forEach((header) => {
              csvHearders.push(camelize(header));
            });
            temp.unshift(csvHearders);
            // console.log("temp1111", temp);
            let csv = Papa.unparse(temp);
            // console.log("unparse", csv);
            let csv2 = Papa.parse(csv, {
              header: true,
            });
            console.log("csv2", csv2);
            let tempArr = [];
            isStacked
              ? yaxisValue?.forEach((item) => {
                  tempArr.push(item.label);
                })
              : tempArr.push(yaxisValue.label);
            var gfg1 = getGroupedData(
              csv2.data,
              tempArr,
              xaxisValue.label,
              true
            );
            console.log("gfg1u ", gfg1);
            gfg1?.sort((a, b) =>
              a[0].toLowerCase() !== b[0].toLowerCase()
                ? a[0].toLowerCase() < b[0].toLowerCase()
                  ? -1
                  : 1
                : 0
            );
            var branchList = [];
            var dataList = [];

            gfg1?.forEach((data) => {
              branchList.push(data[0]);
              // dataList.push(data[1]);
            });

            gfg1?.forEach((data) => {
              data.splice(0, 1);
              // for(let i=0;i<data.length;i++){

              // }
            });
            console.log("gggg", gfg1);
            for (let i = 0; i < gfg1[0]?.length; i++) {
              dataList.push([]);
            }
            for (let i = 0; i < gfg1?.length; i++) {
              for (let j = 0; j < gfg1[i]?.length; j++) {
                dataList[j].push(gfg1[i][j]);
              }
            }
            var visitMonthList = [];
            if (
              xaxisValue.label === "Visit Month" ||
              xaxisValue.label === "Visit Mo"
            ) {
              branchList.forEach((month) => {
                visitMonthList.push(toMonthName(month));
              });
            }
            console.log("branchList", branchList);
            console.log("dataList", dataList);
            var newObj = {
              xaxisValue: {
                name: xaxisValue.label,
                data:
                  xaxisValue.label === "Visit Month" ||
                  xaxisValue.label === "Visit Mo"
                    ? visitMonthList
                    : branchList,
              },
              selectedFile: selectedDataSrc,
              graphName: graphName,
              // isStacked: isStacked,
              yaxisValue: !isStacked
                ? [
                    {
                      name: yaxisValue.label,
                      data: dataList.length > 0 ? dataList[0] : [],
                    },
                  ]
                : [],
              chartType: chartType,
            };
            // console.log("yaxisv", yaxisValue);
            isStacked &&
              yaxisValue.forEach((item, index) => {
                newObj.yaxisValue.push({
                  name: item.label,
                  data: dataList.length > 0 ? dataList[index] : [],
                });
              });
            // console.log("previewObj", newObj);
            // localStorage.setItem("previewGraphData", JSON.stringify(newObj));
            setPreviewGraphdata(newObj);
            ReactGA.pageview("Preview graph");
            setIsPreview(true);
            setIsLoading2(false);
          },
        });
      });
    }
  };

  const getPreviewGraphData = () => {
    return previewGraphData;
  };
  return (
    <>
      {/* <div>
        <Row>
          <Col>
            <h7 style={{ fontWeight: "bold" }}>Data Source</h7>
            <Select
              isSearchable={false}
              onChange={(value) => onChangeFunc(value, "DATASOURCE")}
              options={DataSourceOptions}
              {...error.dataSource}
            />
            {error.dataSource && error.dataSource.errorMsg !== "" && (
              <span className="error">{error.dataSource.errorMsg}</span>
            )}
          </Col>
          <br />
          <Col>
            <h7 style={{ fontWeight: "bold" }}>Chart Type</h7>
            <Select
              isSearchable={false}
              onChange={(value) => {
                onChangeFunc(value, "CHARTTYPE");
              }}
              options={chartOptions}
              {...error.chartType}
            />
            {error.chartType && error.chartType.errorMsg !== "" && (
              <span className="error">{error.chartType.errorMsg}</span>
            )}
          </Col>
          <br />
          <Col>
            <h7 style={{ fontWeight: "bold" }}>X-Axis</h7>
            <Select
              isSearchable={false}
              onChange={(value) => onChangeFunc(value, "XAXIS")}
              options={xAxisOptions}
              {...error.xAxis}
            />
            {error.xAxis && error.xAxis.errorMsg !== "" && (
              <span className="error">{error.xAxis.errorMsg}</span>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          {isStacked ? (
            <Col>
              <h7 style={{ fontWeight: "bold" }}>Y-Axis</h7>
              <Select
                isSearchable={false}
                onChange={(value) => onChangeFunc(value, "YAXIS")}
                placeholder="Select Y-Axis"
                classNamePrefix="select-box"
                value={yaxisValue}
                isMulti
                options={yAxisOptions}
                {...error.yAxis}
              />
              {error.yAxis && error.yAxis.errorMsg !== "" && (
                <span className="error">{error.yAxis.errorMsg}</span>
              )}
            </Col>
          ) : (
            <Col>
              <h7 style={{ fontWeight: "bold" }}>Y-Axis</h7>
              <Select
                isSearchable={false}
                value={yaxisValue}
                onChange={(value) => onChangeFunc(value, "YAXIS")}
                placeholder="Select Y-Axis"
                classNamePrefix="select-box"
                options={yAxisOptions}
                {...error.yAxis}
              />
              {error.yAxis && error.yAxis.errorMsg !== "" && (
                <span className="error">{error.yAxis.errorMsg}</span>
              )}
            </Col>
          )}
          <br />
          <Col>
            <h7 style={{ fontWeight: "bold" }}>Graph Name</h7>
            <input
              className="form-control"
              type="text"
              name="graphName"
              onChange={(e) => {
                onChangeFunc(e.target.value, "GRAPHNAME");
              }}
              style={{ padding: "11px" }}
              {...error.graphName}
            />
            {error.graphName && error.graphName.errorMsg !== "" && (
              <span className="error">{error.graphName.errorMsg}</span>
            )}
          </Col>
          <div
            style={{
              width: "auto",
              alignSelf: "center",
              float: "right",
            }}
          >
            <Button
              variant="primary btn-rounded"
              onClick={() => {
                getPreviewData();
              }}
              type="submit"
            >
              Preview
            </Button>
          </div>

          <div
            style={{
              width: "auto",
              alignSelf: "center",
              float: "right",
            }}
          >
            <Button
              variant="success btn-rounded"
              style={{
                float: "right",
                backgroundColor: "#63C02A",
                borderColor: "#63C02A",
              }}
              onClick={() => {
                addGraph(serverData.length);
              }}
              type="submit"
            >
              Add Graph
            </Button>
          </div>
          <br />
        </Row>
        {isPreview && (
          <LoadingOverlay
            active={isLoading2}
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            <PreviewGraph previewGraphData={getPreviewGraphData()} />
          </LoadingOverlay>
        )}
      </div> */}

      <div>
        {/* <Card>
            <Card.Header>
                <Card.Title>Detailed View</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Tab.Container defaultActiveKey={tabData[0].icon.toLowerCase()}>
                        <Col sm={2}>
                            <Nav as="ul" className="flex-column nav-pills mb-3">
                                {tabData.map((data, i) => (
                                    <Nav.Item as="li" key={i} style={{fontSize:'10px'}}>
                                        <Nav.Link eventKey={data.icon.toLowerCase()}>
                                            {data.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content className="ms-2">
                                <Tab.Pane eventKey={'count'} key={0} mountOnEnter>
                                    <BranchWiseCount />
                                </Tab.Pane>

                                <Tab.Pane eventKey={'revenue'} key={1} mountOnEnter>
                                    <BranchWiseRevenue />
                                </Tab.Pane>

                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </Row>
            </Card.Body>
        </Card> */}
        <Card>
          <Card.Header style={{ fontSize: "medium", fontWeight: "bold" }}>
            Create Custom Dashboards
          </Card.Header>
          <Card.Body>
            <div>
              <Row>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Steps to Create Custom Graphs:{" "}
                  </span>
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    1. Select a Data Source File{" "}
                  </span>
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    2. Select a Chart Type{" "}
                  </span>
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    3. Select X- Axis and Y-Axis (Multi Select){" "}
                  </span>
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    4. Provide a Graph Name
                  </span>{" "}
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    5. Preview the Graph and Add the Graph
                  </span>
                </Col>
                <Col
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "40px",
                      alignSelf: "center",
                    }}
                  >
                    <Button
                      className="add-graph-btn"
                      variant="success btn-rounded"
                      onClick={() => {
                        resetFormData();
                      }}
                      type="submit"
                    >
                      <i class="fa fa-plus"></i> <b>Add Graph</b>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Modal className="fade" show={basicModal} size="xl">
        <Modal.Header>
          <Modal.Title>Add Graph</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "10px" }}>
          <div>
            <Row>
              <Col>
                <h7 style={{ fontWeight: "bold" }}>Data Source</h7>
                <Select
                  isSearchable={false}
                  onChange={(value) => onChangeFunc(value, "DATASOURCE")}
                  options={DataSourceOptions}
                  {...error.dataSource}
                />
                {error.dataSource && error.dataSource.errorMsg !== "" && (
                  <span className="error">{error.dataSource.errorMsg}</span>
                )}
              </Col>
              <br />
              <Col>
                <h7 style={{ fontWeight: "bold" }}>Chart Type</h7>
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    onChangeFunc(value, "CHARTTYPE");
                  }}
                  options={chartOptions}
                  {...error.chartType}
                />
                {error.chartType && error.chartType.errorMsg !== "" && (
                  <span className="error">{error.chartType.errorMsg}</span>
                )}
              </Col>
              <br />
              <Col>
                <h7 style={{ fontWeight: "bold" }}>X-Axis</h7>
                <Select
                  isSearchable={false}
                  onChange={(value) => onChangeFunc(value, "XAXIS")}
                  options={xAxisOptions}
                  {...error.xAxis}
                />
                {error.xAxis && error.xAxis.errorMsg !== "" && (
                  <span className="error">{error.xAxis.errorMsg}</span>
                )}
              </Col>
            </Row>
            <br />
            <Row>
              {isStacked ? (
                <Col>
                  <h7 style={{ fontWeight: "bold" }}>Y-Axis</h7>
                  <Select
                    isSearchable={false}
                    onChange={(value) => onChangeFunc(value, "YAXIS")}
                    placeholder="Select Y-Axis"
                    classNamePrefix="select-box"
                    value={yaxisValue}
                    isMulti
                    options={yAxisOptions}
                    {...error.yAxis}
                  />
                  {error.yAxis && error.yAxis.errorMsg !== "" && (
                    <span className="error">{error.yAxis.errorMsg}</span>
                  )}
                </Col>
              ) : (
                <Col>
                  <h7 style={{ fontWeight: "bold" }}>Y-Axis</h7>
                  <Select
                    isSearchable={false}
                    value={yaxisValue}
                    onChange={(value) => onChangeFunc(value, "YAXIS")}
                    placeholder="Select Y-Axis"
                    classNamePrefix="select-box"
                    options={yAxisOptions}
                    {...error.yAxis}
                  />
                  {error.yAxis && error.yAxis.errorMsg !== "" && (
                    <span className="error">{error.yAxis.errorMsg}</span>
                  )}
                </Col>
              )}
              <br />
              <Col>
                <h7 style={{ fontWeight: "bold" }}>Graph Name</h7>
                <input
                  className="form-control"
                  type="text"
                  name="graphName"
                  onChange={(e) => {
                    onChangeFunc(e.target.value, "GRAPHNAME");
                  }}
                  style={{ padding: "11px" }}
                  {...error.graphName}
                />
                {error.graphName && error.graphName.errorMsg !== "" && (
                  <span className="error">{error.graphName.errorMsg}</span>
                )}
              </Col>
              <div
                style={{
                  width: "auto",
                  alignSelf: "center",
                  float: "right",
                }}
              >
                <Button
                  variant="primary btn-rounded"
                  onClick={() => {
                    getPreviewData();
                  }}
                  type="submit"
                >
                  Preview
                </Button>
              </div>

              <div
                style={{
                  width: "auto",
                  alignSelf: "center",
                  float: "right",
                }}
              >
                <Button
                  variant="success btn-rounded"
                  style={{
                    float: "right",
                    backgroundColor: "#63C02A",
                    borderColor: "#63C02A",
                  }}
                  onClick={() => {
                    addGraph(serverData.length);
                  }}
                  type="submit"
                >
                  Add Graph
                </Button>
              </div>
              <br />
            </Row>
            {isPreview && (
              <LoadingOverlay
                active={isLoading2}
                text={
                  <p
                    style={{
                      color: "black",
                    }}
                  >
                    <div>
                      <Button
                        style={{
                          backgroundColor: "#FFFF",
                          color: "black",
                          borderColor: "white",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                        variant="primary"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="md"
                          variant="primary"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;&nbsp;Loading...
                      </Button>
                    </div>
                  </p>
                }
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(192,192,192,0.4)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "80px",
                    "& svg circle": {
                      stroke: "rgb(136, 108, 192)",
                    },
                  }),
                }}
              >
                <PreviewGraph previewGraphData={getPreviewGraphData()} />
              </LoadingOverlay>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
