import React, { useEffect, useState } from "react";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { Col, Nav, Tab, Modal, Button } from "react-bootstrap";
import NO_DATA_FOUND from "../images/no-data-found.png";
import BootstrapTable from "react-bootstrap-table-next";
import { EpochToDate, EpochToOnlyDate, getMaskedMobileNo } from "../CommonComponents/Helper";
import { camelize } from "../DB/utils";
import { Link } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import TextField from "../CommonComponents/TextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import { getTokenInLocalStorage, hasAccess, hasAccessFromTeamPrivilege } from "../services/AuthService";
import Select from "react-select";

const OpportunityDetails = ({ opportunityDetails, close }) => {
  let userLoginDetails = getTokenInLocalStorage();
  const [leadDetails, setLeadDetails] = useState(null);
  const [activityDetails, setActivityDetails] = useState([]);
  const [opportunityHistory, setOpportunityHistory] = useState([]);
  const [leadHistory, setLeadHistory] = useState([]);
  const [callAudioModal, setCallAudioModal] = useState(false);
  const [probabilityModal, setProbabilityModal] = useState(false);
  const [callDetails, setCallDetails] = useState(null);
  const [editLeadModal, setEditLeadModal] = useState(false);
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(null);

  useEffect(() => {
    getLeadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLeadStatuses = () => {
    moduleApi
      .getData(
        apiCall.getLeadStatus +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        const result = response.map((item) => ({
          value: item,
          label: item,
        }));
        setLeadStatuses(result);
      });
  };

  const getLeadDetails = () => {
    moduleApi
      .getData(apiCall.getLeadDetails + opportunityDetails?.id)
      .then((response) => {
        console.log("response", response);
        setLeadDetails(response);
      });
  };

  const getPriority = (value) => {
    if (value === 1) {
      return "Hot";
    } else if (value === 2) {
      return "Warm";
    } else {
      return "Cold";
    }
  };

  const getActivityDetails = () => {
    const result = leadDetails?.activities?.map((element) => ({
      id: element.id ? element.id : 0,
      opportunityId: element.opportunityId ? element.opportunityId : 0,
      previousActivityId: element.previousActivityId
        ? element.previousActivityId
        : 0,
      leadId: element.leadId ? element.leadId : 0,
      activityTypeId: element.activityTypeId ? element.activityTypeId : 0,
      userId: element.userId ? element.userId : "-",
      // activityName: element.activityName ? element.activityName : "-",
      activityStatus: element.activityStatus ? element.activityStatus : "-",
      subject: element.subject ? element.subject : "-",
      filename: element.fileName ? element.fileName : "-",
      description: element.description ? element.description : "-",
      dueDate: element.dueDate ? EpochToDate(element.dueDate) : "-",
      createdTime: element.createdTime ? EpochToDate(element.createdTime) : "-",
      updatedTime: element.updatedTime ? EpochToDate(element.updatedTime) : "-",
      nextContactDate: element.nextContactDate
        ? EpochToDate(element.nextContactDate)
        : "-",
      completed: element.completed,
      remarks: element.remarks ? element.remarks : "-",
      toNumber: opportunityDetails?.leads?.phoneNumber
        ? opportunityDetails?.leads?.phoneNumber
        : "-",
      empId: element.empId ? element.empId : "-",
      empName: element.empName ? element.empName : "-",
      activityType: element.activityType ? camelize(element.activityType) : "-",
    }));
    setActivityDetails(result);
  };

  const activityColumns = [
    {
      text: (
        <div>
          <span>Activity</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "subject",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Activity</span>
          <br></br>
          <span>Type</span>
        </div>
      ),
      dataField: "activityType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Description",
      dataField: "description",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Activity</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "activityStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Next</span>
          <br></br>
          <span>Contact At</span>
        </div>
      ),
      dataField: "nextContactDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Due Date",
      dataField: "dueDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Assignee</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Assignee</span>
          <br></br>
          <span>Emp Id</span>
        </div>
      ),
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Created</span>
          <br></br>
          <span>At</span>
        </div>
      ),
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Last</span>
          <br></br>
          <span>Updated At</span>
        </div>
      ),
      dataField: "updatedTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Remarks",
      dataField: "remarks",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "status3",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else if (row?.filename !== "-") {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i
                  className="fa fa-play"
                  style={{ marginTop: "3px", marginLeft: "2px" }}
                />
              </Link>
            </div>
          );
        } else {
          return "-";
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // console.log("row", row);
          setCallDetails(row);
          setCallAudioModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "status2",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else if (row?.filename !== "-") {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-download" />
              </Link>
            </div>
          );
        } else {
          return "-";
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // console.log("row", row);
          handleDownload(
            "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
              row?.filename,
            row?.toNumber + "_" + EpochToDate(Math.floor(new Date() / 1000))
          );
        },
      },
    },
  ];

  const updateleadStatus = () => {
    var payload = {
      id: leadDetails?.leads?.id,
      leadStatus:
        selectedLeadStatus !== null
          ? selectedLeadStatus
          : leadDetails?.leads?.leadStatus,
      updatedByUserId: userLoginDetails?.autoFinanceUser?.userId,
    };
    moduleApi.postData(apiCall.updateLead, payload).then((response) => {
      console.log("response", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          setEditLeadModal(false);
          close();
          return swal("Lead Status Updated Successfully", {
            icon: "success",
            dangerMode: false,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const getOpportunityHistory = () => {
    const result = leadDetails?.opportunityHistories?.map((element) => ({
      id: element.id ? element.id : 0,
      opportunityId: element.opportunityId ? element.opportunityId : 0,
      userId: element.userId ? element.userId : "-",
      empId: element.empId ? element.empId : "-",
      empName: element.empName ? element.empName : "-",
      createdTime: element.createdTime ? EpochToDate(element.createdTime) : "-",
      // updatedTime: element.updatedTime ? EpochToDate(element.updatedTime) : "-",
      previousValue: element.previousValue ? element.previousValue : "-",
      updateType: element.updateType ? element.updateType : "-",
      updateValue: element.updateValue ? element.updateValue : "-",
    }));
    setOpportunityHistory(result);
  };

  const opportunityHistoryColumns = [
    {
      text: (
        <div>
          <span>Update</span>
          <br></br>
          <span>Type</span>
        </div>
      ),
      dataField: "updateType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Previous</span>
          <br></br>
          <span>Value</span>
        </div>
      ),
      dataField: "previousValue",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Updated</span>
          <br></br>
          <span>Value</span>
        </div>
      ),
      dataField: "updateValue",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Updated by</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Updated by</span>
          <br></br>
          <span> EMP ID</span>
        </div>
      ),
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Updated</span>
          <br></br>
          <span>At</span>
        </div>
      ),
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.status === "ACTIVE4") {
    //       return <></>;
    //     } else if (row?.filename !== "-") {
    //       return (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-info shadow btn-xs sharp">
    //             <i
    //               className="fa fa-play"
    //               style={{ marginTop: "3px", marginLeft: "2px" }}
    //             />
    //           </Link>
    //         </div>
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       // console.log("row", row);
    //       setCallDetails(row);
    //       setCallAudioModal(true);
    //     },
    //   },
    // },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.status === "ACTIVE4") {
    //       return <></>;
    //     } else if (row?.filename !== "-") {
    //       return (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-success shadow btn-xs sharp">
    //             <i className="fa fa-download" />
    //           </Link>
    //         </div>
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       // console.log("row", row);
    //       handleDownload(
    //         "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
    //           row?.filename,
    //         row?.toNumber + "_" + EpochToDate(Math.floor(new Date() / 1000))
    //       );
    //     },
    //   },
    // },
  ];

  const getLeadHistory = () => {
    const result = leadDetails?.leadHistories?.map((element) => ({
      id: element.id ? element.id : 0,
      opportunityId: element.opportunityId ? element.opportunityId : 0,
      userId: element.userId ? element.userId : "-",
      empId: element.empId ? element.empId : "-",
      empName: element.empName ? element.empName : "-",
      leadId: element.leadId ? element.leadId : "-",
      leadStatusId: element.leadStatusId ? element.leadStatusId : "-",
      createdTime: element.createdTime ? EpochToDate(element.createdTime) : "-",
      // updatedTime: element.updatedTime ? EpochToDate(element.updatedTime) : "-",
      previousValue: element.previousValue ? element.previousValue : "-",
      updateType: element.updateType ? element.updateType : "-",
      updateValue: element.updateValue ? element.updateValue : "-",
    }));
    // console.log(result);
    setLeadHistory(result);
  };

  const handleDownload = (mp3FileUrl, fileName) => {
    // setIsLoading(true);
    // Create an XHR request to fetch the audio file
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element and trigger a click to start the download
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName + ".mp3"; // You can customize the downloaded file name
      document.body.appendChild(a);
      a.click();
      // Clean up the temporary link and revoke the object URL to release resources
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    xhr.open("GET", mp3FileUrl);
    xhr.send();
    // setIsLoading(false);
    // ReactGA.event({
    //   category: "Call Audio Downlaod",
    //   action:
    //     userLoginDetails.autoFinanceOrganization.orgshortcode +
    //     "_" +
    //     fileName +
    //     EpochToDate(Math.floor(new Date() / 1000)) +
    //     ".xlsx",
    // });
  };

  const numberRegex = /^(100|[1-9][0-9]?)$/;

  const validationSchema = Yup.object().shape({
    probability: Yup.string()
      .matches(numberRegex, "Probability must be in between 1 to 100")
      .required("Probability is required"),
  });

  const handleSubmit = (values) => {
    // console.log(values);
    let payload = {
      id: leadDetails?.opportunity?.id,

      probability: values.probability,
      opportunityStage: opportunityDetails?.opportunityStage,
      updatedByUserId: userLoginDetails?.autoFinanceUser?.userId,
    };
    // console.log(payload);
    moduleApi.postData(apiCall.updateOpportunity, payload).then((response) => {
      // console.log("addOpportunityResponse", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          setProbabilityModal(false);
          close();
          return swal("Probability Updated Successfully", {
            icon: "success",
            dangerMode: false,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
    // You can perform submission logic here
  };

  return (
    <div className="custom-tab-1">
      <Tab.Container defaultActiveKey="prospectDetails">
        <Nav as="ul" className="nav-tabs-custom nav-justified">
          <Nav.Item as="li" key="0">
            <Nav.Link eventKey="prospectDetails">
              <b> Prospect Details </b>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="1">
            <Nav.Link eventKey="leadDetails">
              <b> Lead Details </b>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="2">
            <Nav.Link eventKey="opportunityDetails">
              <b> Opportunity Details </b>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="3">
            <Nav.Link
              eventKey="activityDetails"
              onClick={() => {
                getActivityDetails();
              }}
            >
              <b> Activity Details </b>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="4">
            <Nav.Link
              eventKey="opportunityHistory"
              onClick={() => {
                getOpportunityHistory();
              }}
            >
              <b> Opportunity History </b>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="5">
            <Nav.Link
              eventKey="leadHistory"
              onClick={() => {
                getLeadHistory();
              }}
            >
              <b> Lead History </b>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="6">
            <Nav.Link eventKey="additionalDetails">
              <b> Additional Details </b>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="pt-4">
          <Tab.Pane
            eventKey="prospectDetails"
            key="0"
            mountOnEnter
            unmountOnExit
          >
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  overflowY: "auto",
                  maxHeight: "400px",
                  margin: "5px",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "30px",
                    marginLeft: "20px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Prospect Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.customerName
                          ? leadDetails?.leads?.customerName
                          : "-"}
                      </td>
                    </tr>
                    {/* <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Last Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.lastName
                          ? leadDetails?.leads?.lastName
                          : "-"}
                      </td>
                    </tr> */}
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Contact Number
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.phoneNumber
                          ? (hasAccess(75) || hasAccessFromTeamPrivilege(75))
                          ? getMaskedMobileNo(leadDetails?.leads?.phoneNumber)
                          : leadDetails?.leads?.phoneNumber
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Email
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.email
                          ? leadDetails?.leads?.email
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        City
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.city
                          ? leadDetails?.leads?.city
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        State
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.state
                          ? leadDetails?.leads?.state
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Pin Code
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.pinCode
                          ? leadDetails?.leads?.pinCode
                          : "-"}
                      </td>
                    </tr>
                  </>
                </div>
              </div>
            </Col>
          </Tab.Pane>
          <Tab.Pane eventKey="leadDetails" key="0" mountOnEnter unmountOnExit>
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  overflowY: "auto",
                  maxHeight: "400px",
                  margin: "5px",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "30px",
                    marginLeft: "20px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Source Channel
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.channel
                          ? leadDetails?.leads?.channel
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Lead Priority
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.leadRate
                          ? getPriority(leadDetails?.leads?.leadRate)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Lead Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        {leadDetails?.leads?.leadStatus
                          ? leadDetails?.leads?.leadStatus
                          : "-"}
                        {leadDetails?.leads?.leadStatus && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Link
                              //   style={{ marginLeft: "10px" }}
                              to="#"
                              onClick={() => {
                                getLeadStatuses();
                                setEditLeadModal(true);
                              }}
                            >
                              <div className="media-body user-meta-info">
                                <ul>
                                  <li>
                                    <Link
                                      to={"#"}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "32px",
                                        width: "32px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-edit"
                                        style={{ fontSize: "smaller" }}
                                      ></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </Link>
                          </div>
                        )}
                      </td>
                      {/* <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.leadStatus
                          ? leadDetails?.leads?.leadStatus
                          : "-"}
                      </td> */}
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Assignee Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.empName
                          ? leadDetails?.leads?.empName
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Assignee Emp Id
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.empId
                          ? leadDetails?.leads?.empId
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Created At
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.createdTime
                          ? EpochToDate(leadDetails?.leads?.createdTime)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Last Updated At
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leads?.updatedTime
                          ? EpochToDate(leadDetails?.leads?.updatedTime)
                          : "-"}
                      </td>
                    </tr>
                  </>
                </div>
              </div>
            </Col>
          </Tab.Pane>
          <Tab.Pane
            eventKey="opportunityDetails"
            key="0"
            mountOnEnter
            unmountOnExit
          >
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  overflowY: "auto",
                  maxHeight: "400px",
                  margin: "5px",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "30px",
                    marginLeft: "20px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Opportunity Stage
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.opportunity?.opportunityStage
                          ? leadDetails?.opportunity?.opportunityStage
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Product
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.opportunity?.opportunityType
                          ? camelize(leadDetails?.opportunity?.opportunityType)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.opportunity?.amount
                          ? leadDetails?.opportunity?.amount
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Probability
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        {leadDetails?.opportunity?.probability
                          ? leadDetails?.opportunity?.probability + " %"
                          : 0}
                        {/* {leadDetails?.opportunity?.probability !== 0 && ( */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Link
                              //   style={{ marginLeft: "10px" }}
                              to="#"
                              onClick={() => {
                                setProbabilityModal(true);
                              }}
                            >
                              <div className="media-body user-meta-info">
                                <ul>
                                  <li>
                                    <Link
                                      to={"#"}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "32px",
                                        width: "32px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-edit"
                                        style={{ fontSize: "smaller" }}
                                      ></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </Link>
                          </div>
                        {/* )} */}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Assignee Emp Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.opportunity?.empName
                          ? leadDetails?.opportunity?.empName
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Assignee Emp Id
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.opportunity?.empId
                          ? leadDetails?.opportunity?.empId
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Created At
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.opportunity?.createdTime
                          ? EpochToDate(leadDetails?.opportunity?.createdTime)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Last Updated At
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.opportunity?.updatedTime
                          ? EpochToDate(leadDetails?.opportunity?.updatedTime)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Closing Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.opportunity?.closingDate
                          ? EpochToOnlyDate(
                              leadDetails?.opportunity?.closingDate
                            )
                          : "-"}
                      </td>
                    </tr>
                  </>
                </div>
              </div>
            </Col>
          </Tab.Pane>
          <Tab.Pane
            eventKey="activityDetails"
            key="3"
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                background: "white",
                margin: "10px",
                marginTop: "5px",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "500px",
                }}
              >
                <BootstrapTable
                  keyField="panNo"
                  data={activityDetails}
                  columns={activityColumns}
                />
              </div>

              {activityDetails.length === 0 && (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane
            eventKey="opportunityHistory"
            key="4"
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                background: "white",
                margin: "10px",
                marginTop: "5px",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "500px",
                }}
              >
                <BootstrapTable
                  keyField="opportunityId"
                  data={opportunityHistory ? opportunityHistory : []}
                  columns={opportunityHistoryColumns}
                />
              </div>

              {(opportunityHistory?.length === 0 ||
                opportunityHistory === null ||
                opportunityHistory === undefined) && (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="leadHistory" key="5" mountOnEnter unmountOnExit>
            <div
              style={{
                background: "white",
                margin: "10px",
                marginTop: "5px",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "500px",
                }}
              >
                <BootstrapTable
                  keyField="id"
                  data={leadHistory ? leadHistory : []}
                  columns={opportunityHistoryColumns}
                />
              </div>

              {(leadHistory?.length === 0 ||
                leadHistory === null ||
                leadHistory === undefined) && (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane
            eventKey="additionalDetails"
            key="6"
            mountOnEnter
            unmountOnExit
          >
            <Col>
              <div
                className="card contact-bx item-content"
                style={{
                  backgroundColor: "rgb(243,240,249)",
                  overflowY: "auto",
                  maxHeight: "400px",
                  margin: "5px",
                }}
              >
                <div
                  style={{
                    marginRight: "20px",
                    marginBottom: "10px",
                    marginTop: "30px",
                    marginLeft: "20px",
                  }}
                >
                  <>
                    <tr></tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Type Of Loan
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.typeOfLoan
                          ? leadDetails?.leadAdditionalDetails?.typeOfLoan
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Is Property Identified
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails
                          ?.propertyIdentified === true
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Estimated Property Value
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails
                          ?.estimatedPropertyValue
                          ? leadDetails?.leadAdditionalDetails
                              ?.estimatedPropertyValue
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Type Of Property
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.typeOfProperty
                          ? leadDetails?.leadAdditionalDetails?.typeOfProperty
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Date Of Birth
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.dateOfBirth
                          ? EpochToOnlyDate(
                              leadDetails?.leadAdditionalDetails?.dateOfBirth
                            )
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Loan Requirement
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.loanRequirement
                          ? leadDetails?.leadAdditionalDetails?.loanRequirement
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Required Loan Tenure
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.loanTenure
                          ? leadDetails?.leadAdditionalDetails?.loanTenure
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Loan Required Type
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.requiredOn
                          ? leadDetails?.leadAdditionalDetails?.requiredOn
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Employment Type
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.employementType
                          ? leadDetails?.leadAdditionalDetails?.employementType
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Income Type
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.incomeType
                          ? leadDetails?.leadAdditionalDetails?.incomeType
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Current Income Details
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.incomeDetails
                          ? leadDetails?.leadAdditionalDetails?.incomeDetails
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        PAN Number
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.panNumber
                          ? leadDetails?.leadAdditionalDetails?.panNumber
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Current Loan Outstanding Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails
                          ?.currentOutstandingAmount
                          ? leadDetails?.leadAdditionalDetails
                              ?.currentOutstandingAmount
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Balance Loan Tenure (in Months)
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.balanceLoanTenure
                          ? leadDetails?.leadAdditionalDetails
                              ?.balanceLoanTenure
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Current Loan EMI
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.currentLoanEmi
                          ? leadDetails?.leadAdditionalDetails?.currentLoanEmi
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Current Lender Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.currentLenderName
                          ? leadDetails?.leadAdditionalDetails
                              ?.currentLenderName
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Required Loan Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {leadDetails?.leadAdditionalDetails?.requiredLoanAmount
                          ? leadDetails?.leadAdditionalDetails
                              ?.requiredLoanAmount
                          : "-"}
                      </td>
                    </tr>
                  </>
                </div>
              </div>
            </Col>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <Modal className="fade" show={callAudioModal}>
        <Modal.Header>
          <Modal.Title>Call Recording</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setCallAudioModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AudioPlayer
            autoPlay
            src={
              "https://rest.telecmi.com/v2/play?appid=2226640&secret=5a21687e-87f9-4263-9717-9f0a9deb42b7&file=" +
              callDetails?.filename
            }
            onPlay={(e) => console.log("onPlay")}
            // other props here
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={probabilityModal}>
        <Modal.Header>
          <Modal.Title>Update Probability</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setProbabilityModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                probability: leadDetails?.opportunity?.probability,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <TextField
                    isAsterisk={true}
                    label="Probability"
                    name="probability"
                    type="text"
                  />

                  <div style={{ float: "right", marginTop: "10px" }}>
                    <Button
                      style={{ float: "right" }}
                      className="me-2"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="me-2"
                      variant="danger light"
                      onClick={() => {
                        setProbabilityModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="md" show={editLeadModal}>
        <Modal.Header>
          <Modal.Title>Edit Lead Status</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditLeadModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              <label
                className="control-label"
                style={{
                  fontWeight: "bold",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                Lead Status
              </label>
              <Select
                // id="assignee"
                // name="assignee"
                options={leadStatuses}
                defaultValue={{
                  label: leadDetails?.leads?.leadStatus,
                  value: leadDetails?.leads?.leadStatus,
                }}
                onChange={(option) => {
                  setSelectedLeadStatus(option.value);
                }}
                placeholder="Select Lead Status"
              />
            </div>
            <div style={{ float: "right", marginTop: "10px" }}>
              <Button
                style={{ float: "right" }}
                className="me-2"
                onClick={() => {
                  updateleadStatus();
                }}
              >
                Submit
              </Button>
              <Button
                style={{ float: "right" }}
                className="me-2"
                variant="danger light"
                onClick={() => {
                  setEditLeadModal(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OpportunityDetails;
