import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
// eslint-disable-next-line
import ReactExport from "react-data-export";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { LoadingOverlayComponent } from "../../CommonComponents/LoadingOverlay";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import { toaster } from "../../rest/apiUtils";
import "../fiallocations.css";
import { summaryColumns } from "../TableColumns";
import NO_DATA_FOUND from "../../images/no-data-found.png";
import {
  convertToLocalDateTime,
  downloadAgencyLoginSummaryAllocations,
  getStartAndEndDate
} from "../utils";
import ReactGA from "react-ga";
const AgencySummary = () => {
  const [allocationsSummary, setAllocationsSummary] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  // eslint-disable-next-line
  const [defaultDate, setDefaultDate] = useState(new Date());
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const options = {
    hideSizePerPage: true,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    disablePageTitle: true,
  };
// eslint-disable-next-line
  const ExcelFile = ReactExport.ExcelFile;
  // eslint-disable-next-line
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  useEffect(() => {
    var date = new Date();
    var startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let requestPayload = {
      organizationId: userData.fieldInvestigationAgency.organizationId,
      agencyId: userData.fieldInvestigationAgency.agencyId,
      startDate: convertToLocalDateTime(startDate,'DATE_YYYY_MM_DD'),
      endDate: convertToLocalDateTime(endDate,'DATE_YYYY_MM_DD'),
    };
    getAgencyAllocationsSummary(requestPayload)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ReactGA.pageview("Agency Summary");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgencyAllocationsSummary = (requestPayload) => {
    setShowLoader(true)
    nodeApi
      .postData(apiCall.getFiAllocationsAgencySummary, requestPayload)
      .then((response) => {
        if (response.success === true) {
          setShowLoader(false)
          if (response.agencyAllocationsSummary) {
            setAllocationsSummary(response.agencyAllocationsSummary);
            if (response.agencyAllocationsSummary.length > 0) {
              setNoDataFound(false)
            } else {
              setNoDataFound(true)
            }
          }
        } else {
          toaster("error", "Something went wrong. Please try again later.");
          setShowLoader(false)
        }
      })
      .catch((error) => {
        toaster("error", "Something went wrong. Please try again later.");
        setShowLoader(false)
      });
  }

  const setCustomDatesForGraph = (value) => {
    let formatDates = getStartAndEndDate(value)
    let requestPayload = {
      organizationId: userData.fieldInvestigationAgency.organizationId,
      agencyId: userData.fieldInvestigationAgency.agencyId,
      startDate: formatDates.startDate,
      endDate: formatDates.endDate,
    };
    getAgencyAllocationsSummary(requestPayload)

  };
  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "right", marginBottom: 20 }}
      >
        <ExcelFile
          element={
            <Button
              className="me-2 agency-button"
              variant="primary btn-rounded"
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Download
            </Button>
          }
        >
          <ExcelSheet
            dataSet={downloadAgencyLoginSummaryAllocations(allocationsSummary,'AGENCY_LOGIN')}
            name="Allocation_Summary"
          />
        </ExcelFile>
      </div>
      <LoadingOverlayComponent showLoader={showLoader}>
        <div className="table-content">
          <ToolkitProvider
            keyField="id"
            data={allocationsSummary}
            columns={summaryColumns({}, 'AgencyLogin')}
            search
          >
            {(props) => (
              <>
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    marginTop: "-65px",
                  }}
                >
                  {/* <SearchBar
                    {...props.searchProps}
                    placeholder={"Search By Employee ID / Employee Name"}
                    style={{
                      width: 300,
                      height: 40,
                      borderRadius: 25,
                      borderColor: "#a0a0a0",
                      borderWidth: 1,
                      boxShadow: "1px 1px 2px #3a353b",
                      paddingLeft: 10,
                      backgroundColor: "white",
                      marginRight: 10,
                    }}
                  /> */}
                  <Flatpickr
                    className="form-control d-block"
                    onChange={(value) => {
                      setCustomDatesForGraph(value);
                    }}
                    style={{
                      width: "140px",
                      height: "40px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      textAlign: "center",
                    }}
                    placeholder="Select Month"
                    options={{
                      defaultDate: defaultDate,
                      maxDate: new Date(),
                      minDate: new Date().setMonth(new Date().getMonth() - 6),
                      plugins: [
                        new monthSelectPlugin({
                          shorthand: true, //defaults to false
                          dateFormat: "F Y", //defaults to "F Y"
                          altFormat: "F Y", //defaults to "F Y"
                          theme: "light", // defaults to "light"
                        }),
                      ],
                    }}
                  />
                </div>
                <div className="table-container">
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
          {noDataFound &&

            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          }
        </div>
      </LoadingOverlayComponent>
    </div>
  );
};

export default AgencySummary;
