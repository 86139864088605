import { Step, StepLabel, Stepper } from "@material-ui/core";
import React, { useState } from "react";
import PrimaryInfo from "./PrimaryInfo";
import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { apiCall } from "../../rest/restApi";
import * as nodeApi from "../../rest/nodeApi";
import PersonalInfo from "./PersonalInfo";
import ResidentalInfo from "./ResidentalInfo";
import TpcCheck from "./TpcCheck";
import AgencyRemarks from "./AgencyRemarks";
import EmploymentInfo from "./EmploymentInfo";
import VerifierInference from "./VerifierInference";
import OfficePrimaryInfo from "./OfficePrimaryInfo";
import swal from "sweetalert";
import EntryDeniedInfo from "./EntryDeniedInfo";
import UntraceableInfo from "./UntraceableInfo";
// import EditImages from "./EditImages";
import EditImages2 from "./EditImages2";


const EditFiDetails = ({ data, close }) => {
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handlePrev = () => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };

  const handleNext = () => {
    setActiveStep(Math.min(activeStep + 1, steps.length - 1));
  };

  const steps =
    data.fi_status !== "Door Locked" && data.fi_status !== "Untraceable"
      ? [
          PrimaryInfo,
          data?.fi_type?.toLowerCase()==="current office" || data?.fi_type?.toLowerCase()==="other office"
            ? EmploymentInfo
            : PersonalInfo,
           data?.fi_type?.toLowerCase()==="current office" || data?.fi_type?.toLowerCase()==="other office"
            ? VerifierInference
            : ResidentalInfo,
          TpcCheck,
          AgencyRemarks,
          EditImages2,
        ]
      : data.fi_status === "Door Locked"
      ? [EntryDeniedInfo, EditImages2]
      : data.fi_status === "Untraceable" && [UntraceableInfo, EditImages2];

  const ActiveStep = steps[activeStep];
  const validationSchema = ActiveStep.validationSchema;
  // var initialValues = steps.reduce(
  //   (values, { initialValues }) => ({
  //     ...values,
  //     ...initialValues,
  //   }),
  //   {}
  // );
  const onSubmit = (values) => {
    delete values.id;
    console.log(values);
    nodeApi
      .postData(apiCall.updateFiAllocationDetails, values)
      .then((response) => {
        console.log(response);
        if (response?.message === "Updated Successfully") {
          close();
          return swal("Updated Successfully", {
            icon: "success",
          });
        }
      });
  };

  return (
    <div>
      <Formik
        initialValues={data}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting, touched, values, handleChange, errors }) => (
          <>
            <Form>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>{steps[index].label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {activeStep === 0 &&
              data.fi_status !== "Door Locked" &&
              data.fi_status !== "Untraceable" ? (
                (data?.fi_type?.toLowerCase()==="current office" || data?.fi_type?.toLowerCase()==="other office") ? (
                  <OfficePrimaryInfo
                    mainOBJ={values}
                    onChange={handleChange}
                    errors={errors}
                  />
                ) : (
                  <PrimaryInfo
                    mainOBJ={values}
                    onChange={handleChange}
                    errors={errors}
                  />
                )
              ) : activeStep === 0 && data.fi_status === "Door Locked" ? (
                <EntryDeniedInfo
                  mainOBJ={values}
                  onChange={handleChange}
                  errors={errors}
                />
              ) : (
                activeStep === 0 &&
                data.fi_status === "Untraceable" && (
                  <UntraceableInfo
                    mainOBJ={values}
                    onChange={handleChange}
                    errors={errors}
                  />
                )
              )}
              {activeStep === 1 &&
              data.fi_status !== "Door Locked" &&
              data.fi_status !== "Untraceable" ? (
                (data?.fi_type?.toLowerCase()==="current office" || data?.fi_type?.toLowerCase()==="other office") ? (
                  <EmploymentInfo
                    mainOBJ={values}
                    onChange={handleChange}
                    errors={errors}
                  />
                ) : (
                  <PersonalInfo
                    mainOBJ={values}
                    onChange={handleChange}
                    errors={errors}
                  />
                )
              ) : (
                activeStep === 1 && (
                  <EditImages2
                    mainOBJ={values}
                    onChange={handleChange}
                    errors={errors}
                  />
                )
              )}
              {activeStep === 2 &&
                ( (data?.fi_type?.toLowerCase()==="current office" || data?.fi_type?.toLowerCase()==="other office" )? (
                  <VerifierInference
                    mainOBJ={values}
                    onChange={handleChange}
                    errors={errors}
                  />
                ) : (
                  <ResidentalInfo
                    mainOBJ={values}
                    onChange={handleChange}
                    errors={errors}
                  />
                ))}
              {activeStep === 3 && (
                <TpcCheck
                  mainOBJ={values}
                  onChange={handleChange}
                  errors={errors}
                />
              )}
              {activeStep === 4 && (
                <AgencyRemarks
                  mainOBJ={values}
                  onChange={handleChange}
                  errors={errors}
                />
              )}
              {activeStep === 5 && (
                <EditImages2
                  mainOBJ={values}
                  onChange={handleChange}
                  errors={errors}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  float: "right",
                }}
              >
                {activeStep !== 0 && (
                  <Button
                    style={{ marginRight: "15px" }}
                    className="btn btn-block mt-4"
                    disabled={activeStep === 0 || isSubmitting}
                    onClick={handlePrev}
                  >
                    Previous
                  </Button>
                )}
                {!isLastStep() && (
                  <Button
                    style={{ float: "right", marginRight: "15px" }}
                    className="btn btn-block mt-4"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
                {/* {isLastStep() && ( */}
                <Button
                  style={{ float: "right", marginRight: "35px" }}
                  className="btn btn-block mt-4"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Submit
                </Button>
                {/* )} */}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default EditFiDetails;
