import './index.css';
import * as React from 'react';
// import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { updateSampleSection } from './sample-base';
import {
  DiagramComponent,
  Inject,
  DiagramContextMenu,
  UndoRedo,
} from '@syncfusion/ej2-react-diagrams';
function WorkFlow2() {
  
  // Uploader component
  React.useEffect(() => { 
    // const myElement = document.getElementById('diagram');

    // Add the event listener for 'click' or 'mouseup' event
    // myElement.addEventListener('click', function (event) {
    //   var dialog = document.getElementById('defaultdialog');

    //   if (
    //     diagramInstance.selectedItems.nodes &&
    //     diagramInstance.selectedItems.nodes.length > 0
    //   ) {
    //     var diagram = document.getElementById('diagram').ej2_instances[0];

    //     let node = diagram.selectedItems.nodes[0];
    //     let Targetnode = document
    //       .getElementById(node.id + '_groupElement')
    //       .getBoundingClientRect();
    //     dialog.style.left = Targetnode.left + 'px';
    //     dialog.style.top =
    //       Targetnode.top - diagram.selectedItems.nodes[0].height + 'px';

    //     dialog.ej2_instances[0].visible = true;
    //   } else {
    //     dialog.ej2_instances[0].visible = false;
    //   }
    // });

    updateSampleSection();
  }, []);

  // let asyncSettings;
  // let diagramInstance;
  // let colorInstance;
  // let dialogInstance;
  // const animationSettings = { effect: 'None' };
  let nodes = [
    {
      id: 'node1',
      offsetX: 400,
      offsetY: 300,
      width: 100,
      height: 100,
      annotations : [{content : 'node1'}]
    },
    {
      id: 'node2',
      offsetX: 200,
      offsetY: 300,
      width: 100,
      height: 100,
      annotations : [{content : 'node2'}]
    },
  ];
  // const updateTextValue = () => {
  //   let enteredVal = document.getElementById('inVal');
  //   if (enteredVal.value !== '') {
  //   diagramInstance.selectedItems.nodes[0].annotations[0].content = enteredVal.value;
  //   enteredVal.value = '';
  //   }
  // };

//   const footerTemplate = () => {
//     return (<div>
//     <input id="inVal" className="e-input" type="text" placeholder="Enter your message here!"/>
//     <button id="sendButton" className="e-control e-btn e-primary" data-ripple="true" onClick={updateTextValue}>Send</button>
//   </div>);
// };

  return (
    <div className="control-pane">
      <div className="control-section row uploadpreview">
        <div className="col-lg-9">
          <div className="content-wrapper" style={{ width: '100%' }}>
            <DiagramComponent
              id="diagram"
              // ref={(diagram) => (diagramInstance = diagram)}
              width={'100%'}
              height={'645px'}
              nodes={nodes}
              // selectionChange={(args) => {
              //   if (args.state === 'Changed') {
              //     var dialogObj =
              //       document.getElementById('defaultdialog').style;
              //     if (args.newValue.length > 0) {
              //       if (args.newValue[0].sourceID === undefined) {
              //         dialogInstance.visible = true;
              //       }
              //     } else {
              //       dialogInstance.visible = false;
              //     }
              //   }
              // }}
            >
              <Inject services={[UndoRedo, DiagramContextMenu]} />
            </DiagramComponent>
            {/* <DialogComponent
              id="defaultdialog"
              ref={(dialog) => (dialogInstance = dialog)}
              showCloseIcon={false}
              animationSettings={animationSettings}
              width="50px"
              visible={false}
              //target={'#targetElement'}
              footerTemplate={footerTemplate}
             // created={rendereComplete}
            ></DialogComponent> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default WorkFlow2;

