import React, { useEffect, useState } from 'react';
import {
    Button,
    Table
} from "react-bootstrap";
import SearchBar from '../CommonComponents/SearchBar';
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";
import ReactGA from "react-ga";


const TrackingAccess = ({ close }) => {

    const [loggedInUser, setLoggedInUser] = useState();
    const [users, setUsers] = useState([])
    const [overAll, setOverAll] = useState([])
    const [selectedIDS, setSelectedIDS] = useState([])
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    let ids = [];


    useEffect(() => {
        var userLoginData = localStorage.getItem("userDetails");
        if (!userLoginData)
            return;
        var userData = JSON.parse(userLoginData);
        setLoggedInUser(userData)
        loadData(true, userData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
useEffect(() => {
    ReactGA.pageview('Tracking Access Page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const onSubmit = () => {
        const payLoad = {
            "organizationId": loggedInUser.autoFinanceUser.organizationId,
            "status": true,
            "userIds": selectedIDS,
        }


        moduleApi.postData(apiCall.trackingAccess, payLoad)
            .then(() => {
                swal("Successfully Updated", {
                    icon: "success",
                  })
                close()
            })

    }

    const addAll = () => {
        var checkedList = [];
        const res = [...overAll]
        res.forEach(item => {
            item.trackingEnabled = true;
            checkedList.push(item.userId)
        })
        setUsers(res)
        setOverAll(res)
        setSelectedIDS(checkedList)
    }
    const removeAll = () => {
        const res = [...overAll]
        res.forEach(item => {
            item.trackingEnabled = false;
        })
        setUsers(res)
        setOverAll(res)
        setSelectedIDS([])
    }
    const mark = (item) => {
        if (item.trackingEnabled)
            ids.push(item.userId)
        return item.trackingEnabled
    }


    const loadData = (isExecutive, userData) => {
        const payLoad = {
            "branchId": "",
            "organizationId": userData.autoFinanceUser.organizationId,
            "reportOfficerId": '',
            "roleName": "MANAGER",
        }


        moduleApi.postData(apiCall.usersList, payLoad)
            .then((response) => {
                const result = response.map((item) => ({
                    userId: item.userId,
                    name: item.name,
                    email: item.email,
                    trackingEnabled: mark(item),
                    // checked: (list.find(element => element == item.userId) ? true : false)
                }));

                setUsers(result)
                setOverAll(result)
                setSelectedIDS(ids)
            })
    }


    const onSelectAll = (v) => {
        if (v === 'All') {
            if (isSelectedAll) {
                removeAll();
            } else {
                addAll();
            }
            setIsSelectedAll(!isSelectedAll)
            return;
        }

        const found = selectedIDS.find(element => element === v);
        var checkedList = [...selectedIDS];
        if (found) {
            for (var i = 0; i < checkedList.length; i++) {
                if (checkedList[i] === v) {
                    checkedList.splice(i, 1);
                }
            }
        } else
            checkedList.push(v)

        setSelectedIDS(checkedList)
        const res = [...overAll]

        res.forEach(item => {
            if (item.userId === v) {
                item.trackingEnabled = !item.trackingEnabled;
            }
        })

        if (checkedList.length === res.length)
            setIsSelectedAll(true)
        else
            setIsSelectedAll(false)

        setUsers(res)
        setOverAll(res)

    }



    const searchKeyword = (key) => {
        const res = [...overAll]
        const searchString = key

        let matchingStrings = [];

        res.forEach((list) => {
            if (list.name.toLowerCase().search(searchString.toLowerCase()) > -1) {
                matchingStrings.push(list)
            }
        })
        setUsers(matchingStrings);
    }


    return <>
        <div style={{ display: 'flex', margin: '10px 0px' }}>
            {/* <input style={{
                width: '300px', height: '35px', borderRadius: '25px', borderColor: '#a0a0a0',
                borderWidth: '1px', boxShadow: '1px 1px 2px #3a353b', paddingLeft: '10px', marginLeft: '10px'
            }} placeholder='Search Here' onChange={(e) => searchKeyword(e.target.value.trim())} /> */}
                                <SearchBar placeholder="Search Here" searchKeyword={searchKeyword}/>

            <div style={{ marginLeft: 'auto' }}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="All"
                    required
                    onChange={() => onSelectAll("All")}
                    checked={isSelectedAll}
                />
                <label className="form-check-label" htmlFor="All">
                    Select All
                </label>
            </div>
        </div>
        <div style={{ height: '350px', overflowY: 'scroll' }}>
            <Table>
                {users.map((user, index) => {
                    return <tr>
                        <td style={{ display: 'flex', alignItems: 'center', padding: '5px 10px' }}>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={user.userId}
                                required
                                onChange={() => onSelectAll(user.userId)}
                                checked={user.trackingEnabled}
                            />
                            <label
                                style={{ marginBottom: '0px' }}
                                htmlFor={user.userId}
                            >
                                {user.name}
                            </label></td>
                        <td style={{ padding: '5px 10px' }}>{user.email}</td>
                    </tr>
                })}
            </Table>
        </div>
        <div                                     >
            <Button
                style={{ float: 'right' }}
                className="col-lg-2 m-2"
                type="submit"
                onClick={onSubmit}>
                Update
            </Button>
            <Button style={{ float: 'right' }}
                className="col-lg-2 m-2 float-right"
                variant="danger light"
                onClick={close}>
                Close
            </Button>

        </div></>
}

export default TrackingAccess