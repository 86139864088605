import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Form, Formik } from "formik";
import swal from "sweetalert";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import LoadImage from "../CommonComponents/LoadImage";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import SearchBar from "../CommonComponents/SearchBar";
import AddCpUser from "./AddCpUser";
import TextField from "../CommonComponents/TextField";

function CpUsers() {
  // var history = useHistory();
  const [executivesList, setExecutivesList] = useState([]);
  const [addCpUserModal, setAddCpUserModal] = useState(false);
  const [editCpUserModal, setEditCpUserModal] = useState(false);
  const [cpUserDetails, setCpUserDetails] = useState(null);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let listData = [];
  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData(0, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (pageNo, key) => {
    setIsLoading(true);
    let body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchKey: key,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getCpUsers, pageNo, 10), body)
      .then((response) => {
        console.log(response);
        response?.content?.forEach((element) => {
          let data = {
            addLeavesByRoleName: element.autoFinanceUser.addLeavesByRoleName,
            addedByUserId: element.autoFinanceUser.addedByUserId,
            branchId: element.autoFinanceUser.branchId,
            companyDesignation: element.autoFinanceUser.companyDesignation,
            createdAt: element.autoFinanceUser.createdAt,
            email: element.autoFinanceUser.email
              ? element.autoFinanceUser.email
              : "-",
            employeeId: element.autoFinanceUser.employeeId
              ? element.autoFinanceUser.employeeId
              : "-",
            image: element.autoFinanceUser.image,
            imageUploaded: element.autoFinanceUser.imageUploaded,
            mobileNumber: element.autoFinanceUser.mobileNumber
              ? element.autoFinanceUser.mobileNumber
              : "-",
            name: element.autoFinanceUser.name
              ? element.autoFinanceUser.name
              : "-",
            organizationId: element.autoFinanceUser.organizationId,
            passwordUpdated: element.autoFinanceUser.passwordUpdated,
            reIntiatePhonePeTransaction:
              element.autoFinanceUser.reIntiatePhonePeTransaction,
            reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
            reportingOfficerIds: element.autoFinanceUser.reportingOfficerIds,
            roleName: element.autoFinanceUser.roleName,
            status: element.autoFinanceUser.status,
            subOrdinateIds: element.autoFinanceUser.subOrdinateIds,
            subOrdinateManagersIds:
              element.autoFinanceUser.subOrdinateManagersIds,
            trackingEnabled: element.autoFinanceUser.trackingEnabled,
            updatedAt: element.autoFinanceUser.updatedAt,
            userId: element.autoFinanceUser.userId,
            address: element.autoFinanceOrganization.address,
            addressLine1: element.autoFinanceOrganization.addressLine1,
            addressline2: element.autoFinanceOrganization.addressline2,
            collectRequestTimeOut:
              element.autoFinanceOrganization.collectRequestTimeOut,
            cpName: element.autoFinanceOrganization.cpName
              ? element.autoFinanceOrganization.cpName
              : "-",
            cpOrgnanization: element.autoFinanceOrganization.cpOrgnanization,
            datafrequency: element.autoFinanceOrganization.datafrequency,
            daysToNotifyCashDeposits:
              element.autoFinanceOrganization.daysToNotifyCashDeposits,
            // email: element.autoFinanceOrganization.email,
            // imageUploaded: element.autoFinanceOrganization.imageUploaded,
            lastBilledAt: element.autoFinanceOrganization.lastBilledAt,
            locationdatafrequency:
              element.autoFinanceOrganization.locationdatafrequency,
            logOutHours: element.autoFinanceOrganization.logOutHours,
            logOutMinutes: element.autoFinanceOrganization.logOutMinutes,
            loginHours: element.autoFinanceOrganization.loginHours,
            loginMinutes: element.autoFinanceOrganization.loginMinutes,
            mainOrganizationId:
              element.autoFinanceOrganization.mainOrganizationId,
            maxLocationPoints:
              element.autoFinanceOrganization.maxLocationPoints,
            // mobileNumber: element.autoFinanceOrganization.mobileNumber,
            numberOfUsers: element.autoFinanceOrganization.numberOfUsers,
            // organizationId: element.autoFinanceOrganization.organizationId,
            organizationImage:
              element.autoFinanceOrganization.organizationImage,
            organizationImageName:
              element.autoFinanceOrganization.organizationImageName,
            organizationName: element.autoFinanceOrganization.organizationName,
            orgshortcode: element.autoFinanceOrganization.orgshortcode
              ? element.autoFinanceOrganization.orgshortcode
              : "-",
            ornilePaymentProvider:
              element.autoFinanceOrganization.ornilePaymentProvider,
            phonepeqrcode: element.autoFinanceOrganization.phonepeqrcode,
            qrCodeTimeOut: element.autoFinanceOrganization.qrCodeTimeOut,
            sendBulkPaymentLinkViaSMS:
              element.autoFinanceOrganization.sendBulkPaymentLinkViaSMS,
            sendBulkPaymentLinkViaWhatsapp:
              element.autoFinanceOrganization.sendBulkPaymentLinkViaWhatsapp,
            sendPaymentReciept:
              element.autoFinanceOrganization.sendPaymentReciept,
            sendPaymentRecieptForUpiPayment:
              element.autoFinanceOrganization.sendPaymentRecieptForUpiPayment,
            sendPaymentRecieptViaSms:
              element.autoFinanceOrganization.sendPaymentRecieptViaSms,
            sendPaymentRecieptViaWhatsApp:
              element.autoFinanceOrganization.sendPaymentRecieptViaWhatsApp,
          };
          listData.push(data);
        });
        setExecutivesList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setAddCpUserModal(false);
    loadData(0, searchKey);
  };

  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <LoadImage source={row.image} />{" "}
            <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
          </div>
        );
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "CP Short Code",
      dataField: "orgshortcode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "CP Name",
      dataField: "cpName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Email",
      dataField: "email",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit" />
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("row", row);
          setCpUserDetails(row);
          setEditCpUserModal(true);
        },
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(selectedPage - 1, searchKey);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadData(0, key);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  // var namePattern = "^[a-zA-Z]";
  var nameRegex = "[a-zA-Z ]+$";

  const editProfileValidate = Yup.object({
    email: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required"),
    name: Yup.string()
      .trim()
      .matches(nameRegex, "Please Enter a Valid Name")
      .required("Name is required"),
    empId: Yup.string().required("Emp ID is required"),
  });

  const onSubmit = (values) => {
    const payLoad = {
      branchId: cpUserDetails?.branchId,
      companyDesignation: null,
      email: values.email,
      employeeId: values.empId,
      // image: currentFile ? currentFile : cpUserDetails?.image,
      mobileNumber: values.mobileNumber,
      name: values.name,
      organizationId: cpUserDetails?.organizationId,
      passwordUpdated: cpUserDetails?.passwordUpdated,
      reportingOfficerIds: cpUserDetails?.reportingOfficerIds,
      roleName: cpUserDetails?.roleName,
      status: cpUserDetails?.status,
      userId: cpUserDetails?.userId,
    };
    moduleApi.postData(apiCall.updateUserData, payLoad).then((response) => {
      if (response?.message) {
        swal(response?.message, {
          icon: "error",
          dangerMode: true,
        });
      } else if (response) {
        setEditCpUserModal(false);

        swal("Successfully Updated", {
          icon: "success",
        }).then((willOk) => {
          if (willOk) {
            loadData(0, searchKey);
          }
        });
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        {/* <input
          style={{
            width: "300px",
            height: "40px",
            borderRadius: "25px",
            borderColor: "#a0a0a0",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #3a353b",
            paddingLeft: "10px",
          }}
          placeholder="Search By Name/Branch/Mobile"
          onChange={(e) => searchKeyword(e.target.value.trim())}
        /> */}
        <SearchBar
          placeholder="Search By Name/Emp ID/Email/Mobile"
          searchKeyword={searchKeyword}
        />

        <Button
          style={{
            float: "right",
            marginLeft: "10px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => setAddCpUserModal(true)}
        >
          <b> + Add CP User </b>
        </Button>
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px" }}>
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={executivesList}
              columns={columns}
            />
          </div>
          {executivesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>

      <Modal className="fade" show={addCpUserModal}>
        <Modal.Header>
          <Modal.Title>Add CP User</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddCpUserModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AddCpUser close={handleClose} />
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={editCpUserModal}>
        <Modal.Header>
          <Modal.Title>Edit CP User</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setEditCpUserModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                email: cpUserDetails?.email,
                image: "",
                mobileNumber: cpUserDetails?.mobileNumber,
                name: cpUserDetails?.name,
                organizationId: "",
                reportingOfficerId: "",
                roleName: cpUserDetails?.roleName,
                empId: cpUserDetails?.employeeId,
                branchLocation: "",
                branchName: "",
              }}
              onSubmit={onSubmit}
              validationSchema={editProfileValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <TextField
                          isAsterisk={true}
                          label="Name"
                          name="name"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Emp ID"
                          name="empId"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Email"
                          name="email"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Mobile Number"
                          name="mobileNumber"
                          type="text"
                        />

                        <div style={{ marginTop: "10px" }}>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setEditCpUserModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CpUsers;
