import React from "react"
import * as Yup from "yup"
import { Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import 'react-toastify/dist/ReactToastify.css';
import TextField from "../../CommonComponents/TextField";
import { Field } from "formik";
import Select from "react-select";




// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const panRegExp = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


const VerifierInference = ({ key, mainOBJ, onChange, errors }) => {
    let yesOrNoOptions = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ];

      let areaLocalityOptions = [
        { label: "Normal", value: "Normal" },
        { label: "Negative", value: "Negative" },
        { label: "Slum", value: "Slum" },
        { label: "Community", value: "Community" },
        { label: "Dominated Area", value: "Dominated Area" },
      ];

      let locatingAddressOptions = [
        { label: "Easy", value: "Easy" },
        { label: "Difficult", value: "Difficult" },
        { label: "Untraceble", value: "Untraceble" },
      ];

      let houseConditionOptions = [
        { label: "Upper Posh", value: "Upper Posh" },
        { label: "Posh", value: "Posh" },
        { label: "Average", value: "Average" },
        { label: "Lower", value: "Lower" },
      ];

      let standardOfLivingOptions = [
        { label: "Good", value: "Good" },
        { label: "Average", value: "Average" },
        { label: "Lower", value: "Lower" },
      ];

      let officeLocationOptions=[
        { label: "Commercial", value: "Commercial" },
        { label: "Residential", value: "Residential" },
      ]

      let jobTypeOptions=[
        { label: "Contractual", value: "Contractual" },
        { label: "Permanent", value: "Permanent" },
      ]
      let officeTypeOptions=[
        { label: "Proper", value: "Proper" },
        { label: "Temporary", value: "Temporary" },
      ]

      let stocksSeenOptions=[
        { label: "No Stock", value: "No Stock" },
        { label: "Average", value: "Average" },
        { label: "Good", value: "Good" },
        
      ]



  return (
    <div style={{ margin: "15px", maxHeight:'400px', overflowY:'auto' }}>
      <Row>
        <Col>
          {/* <TextField isAsterisk={true} label="Office Name/Business Sign Board Seen" name="sign_board_seen" type="text" /> */}
          <Field name="sign_board_seen">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Office Name/Business Sign Board Seen
    </label>
    <Select
      placeholder="Select Office Name/Business Sign Board Seen"
      classNamePrefix="select-box"
      options={yesOrNoOptions}
      defaultValue={{
        label: mainOBJ?.sign_board_seen,
        value: mainOBJ?.sign_board_seen,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "sign_board_seen",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.sign_board_seen && (
<span className="error">{errors.sign_board_seen}</span>
)}
          <TextField isAsterisk={true} label="Nearest Landmark" name="nearest_land_mark" type="text" />
          {/* <TextField isAsterisk={true} label="Area Locality" name="area_locality" type="text" /> */}
          <Field name="area_locality">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Area Locality
                </label>
                <Select
                  placeholder="Select Area Locality"
                  classNamePrefix="select-box"
                  options={areaLocalityOptions}
                  defaultValue={{
                    label: mainOBJ?.area_locality,
                    value: mainOBJ?.area_locality,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("area_locality", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.area_locality && (
            <span className="error">{errors.area_locality}</span>
          )}
          {/* <TextField isAsterisk={true} label="Locating Address" name="house_location" type="text" /> */}
          <Field name="house_location">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Locating Address
                </label>
                <Select
                  placeholder="Select Locating Address"
                  classNamePrefix="select-box"
                  options={locatingAddressOptions}
                  defaultValue={{
                    label: mainOBJ?.house_location,
                    value: mainOBJ?.house_location,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("house_location", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.house_location && (
            <span className="error">{errors.house_location}</span>
          )}
          {/* <TextField isAsterisk={true} label="Location of Office" name="location_of_office" type="text" /> */}
          <Field name="location_of_office">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Location of Office
                </label>
                <Select
                  placeholder="Select Location of Office"
                  classNamePrefix="select-box"
                  options={officeLocationOptions}
                  defaultValue={mainOBJ?.location_of_office?{
                    label: mainOBJ?.location_of_office,
                    value: mainOBJ?.location_of_office,
                  }:null}
                  
                  onChange={(value) => {
                    form.setFieldValue("location_of_office", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.location_of_office && (
            <span className="error">{errors.location_of_office}</span>
          )}
          {/* <TextField label="Office Interior Condition" name="interior_condition" type="text" />
          <TextField label="Office Exterior Condition" name="exterior_condition" type="text" /> */}
          <Field name="interior_condition">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Office Interior Condition
                </label>
                <Select
                  placeholder="Select Office Interior Condition"
                  classNamePrefix="select-box"
                  options={houseConditionOptions}
                  defaultValue={{
                    label: mainOBJ?.interior_condition,
                    value: mainOBJ?.interior_condition,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("interior_condition", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.interior_condition && (
            <span className="error">{errors.interior_condition}</span>
          )}
          <Field name="exterior_condition">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  // className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Office Exterior Condition
                </label>
                <Select
                  placeholder="Select Office Exterior Condition"
                  classNamePrefix="select-box"
                  options={houseConditionOptions}
                  defaultValue={{
                    label: mainOBJ?.exterior_condition,
                    value: mainOBJ?.exterior_condition,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("exterior_condition", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.exterior_condition && (
            <span className="error">{errors.exterior_condition}</span>
          )}
          <TextField label="Approx Area of Office(in sq.ft)" name="residence_carpet_area" type="text" />
          {/* <TextField label="Type of Job" name="type_of_job" type="text" /> */}
          <Field name="type_of_job">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
    //   className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Type Of Job
    </label>
    <Select
      placeholder="Select Type Of Job"
      classNamePrefix="select-box"
      options={jobTypeOptions}
      defaultValue={{
        label: mainOBJ?.type_of_job,
        value: mainOBJ?.type_of_job,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "type_of_job",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.type_of_job && (
<span className="error">{errors.type_of_job}</span>
)}
          {/* <TextField label="Type of Office" name="type_of_office" type="text" /> */}
          <Field name="type_of_office">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
    //   className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Type Of Office
    </label>
    <Select
      placeholder="Select Type Of Office"
      classNamePrefix="select-box"
      options={officeTypeOptions}
      defaultValue={{
        label: mainOBJ?.type_of_office,
        value: mainOBJ?.type_of_office,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "type_of_office",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.type_of_office && (
<span className="error">{errors.type_of_office}</span>
)}
        </Col>
        <Col> 
        {/* <TextField isAsterisk={true} label="Visiting Card Obtained" name="visiting_card_obtaines" type="text" /> */}
        <Field name="visiting_card_obtaines">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Visiting Card Obtained
    </label>
    <Select
      placeholder="Select Visiting Card Obtained"
      classNamePrefix="select-box"
      options={yesOrNoOptions}
      defaultValue={{
        label: mainOBJ?.visiting_card_obtaines,
        value: mainOBJ?.visiting_card_obtaines,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "visiting_card_obtaines",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.visiting_card_obtaines && (
<span className="error">{errors.visiting_card_obtaines}</span>
)}
        {/* <TextField isAsterisk={true} label="BIZ Activity" name="biz_activity" type="text" /> */}
        <Field name="biz_activity">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
      className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      BIZ Activity
    </label>
    <Select
      placeholder="Select BIZ Activity"
      classNamePrefix="select-box"
      options={standardOfLivingOptions}
      defaultValue={{
        label: mainOBJ?.biz_activity,
        value: mainOBJ?.biz_activity,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "biz_activity",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.biz_activity && (
<span className="error">{errors.biz_activity}</span>
)}
        <TextField isAsterisk={true} label="No.of Employees" name="no_of_employees" type="text" />
        <TextField isAsterisk={true} label="No.of Employees Seen at Office" name="employees_seen_count" type="text" />
        {/* <TextField label="Stock Seen" name="stock_seen" type="text" /> */}
        <Field name="stock_seen">
{({ field, form }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px'
    }}
  >
    <label
    //   className="control-label"
      style={{
        fontWeight: "bold",
        float: "left",
        marginBottom: "10px",
      }}
    >
      Stock Seen
    </label>
    <Select
      placeholder="Select Stock Seen"
      classNamePrefix="select-box"
      options={stocksSeenOptions}
      defaultValue={{
        label: mainOBJ?.stock_seen,
        value: mainOBJ?.stock_seen,
      }}
      // defaultValue={getDefaultValue(addressVerificationDoneOptions, mainOBJ?.actual_address_verified)}
      onChange={(value) => {
        form.setFieldValue(
          "stock_seen",
          value.value
        );
      }}
    />
  </div>
)}
</Field>
{errors.stock_seen && (
<span className="error">{errors.stock_seen}</span>
)}
        {/* <TextField isAsterisk={true} label="Asset/Vehicle Seen at Office" name="vehicle_seen_at_residence" type="text" /> */}
        <Field name="vehicle_seen_at_residence">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Asset/Vehicle Seen at Office
                </label>
                <Select
                  placeholder="Select Asset/Vehicle Seen at Office"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={{
                    label: mainOBJ?.vehicle_seen_at_residence,
                    value: mainOBJ?.vehicle_seen_at_residence,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue("vehicle_seen_at_residence", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.vehicle_seen_at_residence && (
            <span className="error">{errors.vehicle_seen_at_residence}</span>
          )}
        <TextField label="List the Assets at Office" name="residence_assets" type="text" />
        {/* <TextField isAsterisk={true} label="Affiliation Seen for any Political Party" name="political_party_affilication" type="text" /> */}
        <Field name="political_party_affilication">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Affiliation Seen for any Political Party
                </label>
                <Select
                  placeholder="Select Affiliation Seen for any Political Party"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={{
                    label: mainOBJ?.political_party_affilication,
                    value: mainOBJ?.political_party_affilication,
                  }}
                  
                  onChange={(value) => {
                    form.setFieldValue(
                      "political_party_affilication",
                      value.value
                    );
                  }}
                />
              </div>
            )}
          </Field>
          {errors.political_party_affilication && (
            <span className="error">{errors.political_party_affilication}</span>
          )}
        <TextField label="Affiliation Political Party Remarks" name="political_remarks" type="text" />
        {/* <TextField isAsterisk={true} label="Caution Profile" name="caution_profile" type="text" /> */}
        <Field name="caution_profile">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  className="control-label"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    marginBottom: "10px",
                  }}
                >
                  Caution Profile
                </label>
                <Select
                  placeholder="Select Caution Profile"
                  classNamePrefix="select-box"
                  options={yesOrNoOptions}
                  defaultValue={mainOBJ?.caution_profile?{
                    label: mainOBJ?.caution_profile,
                    value: mainOBJ?.caution_profile,
                  }:null}
                  
                  onChange={(value) => {
                    form.setFieldValue("caution_profile", value.value);
                  }}
                />
              </div>
            )}
          </Field>
          {errors.caution_profile && (
            <span className="error">{errors.caution_profile}</span>
          )}
        <TextField label="Caution Profile Remarks" name="caution_profile_remarks" type="text" />
        </Col>
      </Row>
    </div>
  )
}

VerifierInference.label = "Verifier's Inference on Office"
VerifierInference.initialValues = {
  sign_board_seen: "",
  nearest_land_mark: "",
  area_locality: "",
  house_location: "",
  location_of_office: "",
  interior_condition: "",
  exterior_condition: "",
  residence_carpet_area: "",
  type_of_job: "",
  type_of_office: "",
  visiting_card_obtaines: "",
  biz_activity: "",
  no_of_employees: "",
  employees_seen_count: "",
  stock_seen: "",
  vehicle_seen_at_residence: "",
  residence_assets: "",
  political_party_affilication: "",
  political_remarks: "",
  caution_profile: "",
  caution_profile_remarks: "",
}

VerifierInference.validationSchema = Yup.object({
  sign_board_seen: Yup.string().required("Office Name/Business Sign Board Seen is required"),
  nearest_land_mark: Yup.string().required("Nearest Landmark is required"),
  area_locality: Yup.string().required("Area Locality is required"),
  house_location: Yup.string().required("Locating Address is required"),
  location_of_office: Yup.string().required("Location of Office is required"),
  // interior_condition: Yup.string().required("Office Interior Condition is required"),
  // exterior_condition: Yup.string().required("Office Exterior Condition is required"),
  // residence_carpet_area: Yup.string().required("Approx Area of Office(in sq.ft) is required"),
  // type_of_job: Yup.string().required("Type of Job is required"),
  // type_of_office: Yup.string().required("Type of Office is required"),
  visiting_card_obtaines: Yup.string().required("Visiting Card Obtained is required"),
  biz_activity: Yup.string().required("BIZ Activity is required"),
  no_of_employees: Yup.string().required("No.of Employees is required"),
  employees_seen_count: Yup.string().nullable().required("No.of Employees Seen at Office is required"),
  // stock_seen: Yup.string().nullable().required("Stock Seen is required"),
  vehicle_seen_at_residence: Yup.string().required("Asset/Vehicle Seen at Office is required"),
  // residence_assets: Yup.string().required("List the Assets at Office is required"),
  political_party_affilication: Yup.string().required("Affiliation Seen for any Political Party is required"),
  // political_remarks: Yup.string().nullable().required("Affiliation Political Party Remarks is required"),
  caution_profile: Yup.string().nullable().required("Caution Profile is required"),
  // caution_profile_remarks: Yup.string().nullable().required("Caution Profile Remarks is required"),

  
  
})
VerifierInference.propTypes = {
  key: PropTypes.any,
  mainOBJ: PropTypes.any,
  onChange: PropTypes.any,
}

export default VerifierInference