import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  TextField,
  Tooltip,
} from "@mui/material";
// import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import MuiTextField from "../../CommonComponents/MuiTextField";
import { convertEpochToTime3 } from "../../CommonComponents/Helper";
import { getTokenInLocalStorage } from "../../services/AuthService";
import * as moduleApi from "../../rest/moduleApi";
import { apiCall } from "../../rest/restApi";
import swal from "sweetalert";
import { LoadingOverlayComponent } from "../../CommonComponents/LoadingOverlay";

const CreateTemplate = ({ close }) => {
  const userLoginDetails = getTokenInLocalStorage();
  const [formValues, setFormValues] = useState({
    templateName: "",
    category: "",
    categoryType: "",
    language: "",
    templateLabel: "",
    header: "",
    body: "",
    footer: "",
    copy_offer_code: { label: "Copy Offer Code", value: "" },
    url: { label: "", value: "" },
    phone_number: { label: "", value: "" },
    quick_reply: "",
  });
  const [buttonValues, setButtonValues] = useState({
    url: false,
    copy_offer_code: false,
    quick_reply: false,
    phone_number: false,
  });
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [dynamicUrl, setDynamicUrl] = useState(null);
  const [variableList, setVariableList] = useState([]);
  const [headerVariableList, setHeaderVariableList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedButtonValue, setSelectedButtonValue] = React.useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  //   const handleClick = () => {
  //     console.log(`You clicked ${buttonOptions[selectedIndex]}`);
  //   };

  const handleMenuItemClick = (option, index) => {
    // console.log(buttonOptions[index].value);
    const { value } = option;

    setButtonValues({
      ...buttonValues,
      [value]: true,
    });
    setSelectedButtonValue(buttonOptions[index].value);

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const buttonOptions = [
    { value: "url", label: "URL" },
    { value: "phone_number", label: "Phone Number" },
    { value: "quick_reply", label: "Quick Reply" },

    // { value: "copy_offer_code", label: "Copy Offer Code" },
  ];

  const languages = [
    { value: "en", label: "English" },
    { value: "hi", label: "Hindi" },
    { value: "te", label: "Telugu" },
    { value: "ta", label: "Tamil" },
    // Add more languages as needed
  ];
  const headerOptions = [
    { value: "text", label: "Text" },
    { value: "media", label: "Media" },
    // Add more languages as needed
  ];
  const urlOptions = [
    { value: "static", label: "Static" },
    { value: "dynamic", label: "Dynamic" },
    // Add more languages as needed
  ];

  const fileLimitations = [
    "1. Image: JPG, JPEG and PNG (Max size 2 MB)",
    "2. Video: MP4 (Max size 20 MB)",
    "3. Document: (Max size 2 MB)",
  ];

  const handleHeaderChange = (e) => {
    const { value } = e.target;
    setSelectedHeader(value);
  };
  const handleUrlChange = (e) => {
    const { value } = e.target;
    setSelectedUrl(value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const buttonHandleChange = (e, field, subField) => {
    const { value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [subField]: value,
      },
    }));
  };

  const addVarible = () => {
    const newObject = {
      variable: " {{" + (variableList?.length + 1) + "}}",
      variableValue: "",
    };

    // Add the new object to the list
    setVariableList([...variableList, newObject]);
    setFormValues((prevValues) => ({
      ...prevValues,
      body: prevValues.body + newObject.variable,
    }));
  };

  const addVarible2 = () => {
    const newObject = {
      variable: "{{" + (headerVariableList?.length + 1) + "}}",
      variableValue: "",
    };

    // Add the new object to the list
    setHeaderVariableList([...headerVariableList, newObject]);
    setFormValues((prevValues) => ({
      ...prevValues,
      header: prevValues.header + newObject.variable,
    }));
  };

  const updateVariableValue = (e, item, isHeader) => {
    const { value } = e.target;
    if (isHeader === false) {
      setVariableList(
        variableList.map((obj) =>
          obj.variable === item.variable
            ? { ...obj, variableValue: value }
            : obj
        )
      );
    } else {
      setHeaderVariableList(
        headerVariableList.map((obj) =>
          obj.variable === item.variable
            ? { ...obj, variableValue: value }
            : obj
        )
      );
    }
  };

  const replaceBodyString = (data, isHeader) => {
    let updatedString = data;
    if (isHeader === false) {
      variableList.forEach((obj, index) => {
        const placeholder = `{{${index + 1}}}`;
        updatedString = updatedString.replaceAll(
          placeholder,
          obj.variableValue
        );
      });
    } else {
      headerVariableList.forEach((obj, index) => {
        const placeholder = `{{${index + 1}}}`;
        updatedString = updatedString.replaceAll(
          placeholder,
          obj.variableValue
        );
      });
    }
    return updatedString;
  };

  const getPreviewImage = (type) => {
    switch (type) {
      case "image":
        return "https://gs-upload.gupshup.io/whatsapp/preview_image.webp";
      case "video":
        return "https://gs-upload.gupshup.io/whatsapp/preview_video.webp";
      case "document":
        return "https://gs-upload.gupshup.io/whatsapp/preview_document.webp";
      case "location":
        return "https://gs-upload.gupshup.io/whatsapp/preview_location.webp";
      default:
        return "";
    }
  };

  const getButtonValues = () => {
    const resultArray = [];
    for (const key in buttonValues) {
      if (buttonValues[key]) {
        resultArray.push({
          params:
            key === "url" && selectedUrl === "dynamic" ? [dynamicUrl] : [],
          text: key === "quick_reply" ? formValues[key] : formValues[key].label,
          type: key.toUpperCase(), // assuming you want to use the key as the type
          value:
            key === "url" && dynamicUrl !== null && selectedUrl === "dynamic"
              ? formValues[key].value + "{{1}}"
              : key === "quick_reply"
              ? formValues[key]
              : formValues[key].value,
        });
      }
    }
    return resultArray;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    // Validate file type and size based on selection
    // const allowedTypes = {
    //   image: ["image/jpeg", "image/png", "image/jpg"],
    //   video: ["video/mp4"],
    //   document: ["application/pdf"], // Assuming document refers to PDF
    // };

    const maxSize = {
      image: 2 * 1024 * 1024, // 2 MB in bytes
      video: 20 * 1024 * 1024, // 20 MB in bytes
      document: 2 * 1024 * 1024, // 2 MB in bytes
    };

    const typeCategory = event.target.accept.slice(13); // Get category from accept prop

    // Validate file type
    // if (
    //   !allowedTypes[typeCategory] ||
    //   !allowedTypes[typeCategory].includes(file.type)
    // ) {
    //   return swal(
    //     `Invalid file type. Please select a valid ${typeCategory.toUpperCase()} file.`,
    //     {
    //       icon: "error",
    //       dangerMode: true,
    //     }
    //   );
    // }

    // Validate file size
    if (file.size > maxSize[typeCategory]) {
      return swal(
        `File size exceeds limit. Maximum size for ${typeCategory.toUpperCase()} is ${
          maxSize[typeCategory] / (1024 * 1024)
        } MB.`,
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }

    setSelectedFile(file);
    // try {
    //   var payload = {
    //     fileName: file.name,
    //     // fileType: selectedHeader,
    //     organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
    //   };
    //   const getPreSignedUrlResponse = await moduleApi.postData(
    //     apiCall.getPreSignedUrlForTemplateUpload,
    //     payload
    //   );
    //   console.log('presignedUrlResponse', getPreSignedUrlResponse );
    //   await moduleApi.putDataByName(
    //     getPreSignedUrlResponse?.presignedUrl,
    //     file
    //   );

    //   const getHValueResponse = await moduleApi.postData(
    //     apiCall.getHValueForFileUpload,
    //     payload
    //   );
    //   console.log('hvaluereponse',getHValueResponse);
    // } catch (error) {}
  };

  const validateForm = () => {
    let templateNameRegex = /^[a-z0-9_]+$/;
    const errors = ["Please fill the following required fields: "]; // Array to store error messages

    // Check individual fields and add specific error messages
    if (!formValues.templateName) {
      errors.push("Template Name");
    } else if (templateNameRegex.test(formValues.templateName) === false) {
      errors.push(
        "Template Name is invalid, it should only contain small letters, numbers and underscores, please check"
      );
    } else if (formValues.templateName?.length > 60) {
      errors.push(
        "Template Name exceeded the length, maximum limit is 60 characters only, please check"
      );
    }
    if (!formValues.category) {
      errors.push("Category");
    }
    if (!formValues.language) {
      errors.push("Language");
    }
    if (!formValues.body) {
      errors.push("Body");
    } else if (formValues.templateName?.length > 1024) {
      errors.push(
        "Body exceeded the length, maximum limit is 1024 characters only, please check"
      );
    }
    if (selectedHeader === "text" && formValues?.header?.length > 60) {
      errors.push(
        "Header Text exceeded the length, maximum limit is 60 characters only, please check"
      );
    }
    if (selectedHeader === "media" && selectedFile === null) {
      errors.push("Header File");
    }

    // Check other fields as needed
    // ...

    // Throw combined error message if any errors exist
    if (errors.length > 1) {
      console.log(errors.join("\n"));
      return errors.join("\n"); // Combine errors with line breaks
    } else {
      return null;
    }

    // If no errors, form is valid
    // ... perform form submission or other actions
  };

  const handleSubmit = async () => {
    let validate = validateForm();
    if (validate !== null) {
      return swal(validate, {
        icon: "error",
        dangerMode: true,
      });
    }
    setIsLoading(true);
    let temp = [];
    let headertemp = [];
    // let updatedFormValues = { ...formValues };
    // variableList.forEach((item) => {
    //   temp.push(item.variableValue);
    // });
    // if(selectedHeader==='text'){
    // headerVariableList.forEach((item) => {
    //   headertemp.push(item.variableValue);
    // });}else{
    //   headertemp.push('kkkk')
    // }
    // if (dynamicUrl !== null) {
    //   // Update the value of the url field
    //   updatedFormValues.url = {
    //     ...updatedFormValues.url,
    //     value: updatedFormValues.url.value + "{{1}}",
    //     dynamicUrl: dynamicUrl,
    //   };
    // }
    // console.log('headerValues', selectedHeader);
    // console.log('headerValues2', formValues);
    // console.log('headerValues3', headerVariableList);
    // var body = {
    //   body: {
    //     text: updatedFormValues?.body,
    //     values: temp,
    //   },
    //   buttons: getButtonValues(),
    //   category: updatedFormValues?.category,
    //   footer: {
    //     text: updatedFormValues?.footer,
    //   },
    //   header: {
    //     format: selectedHeader==='text'?selectedHeader?.toUpperCase():updatedFormValues?.header?.toUpperCase(),
    //     params: headertemp,
    //     text: selectedHeader==='text'&&updatedFormValues?.header,
    //     // type: selectedHeader,
    //   },
    //   language: updatedFormValues?.language,
    //   organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
    //   // status: "string",
    //   templateName: updatedFormValues?.templateName,
    // };
    // console.log("body", body);
    try {
      if (selectedHeader !== "text") {
        var payload = {
          fileName: selectedFile?.name,
          // fileType: selectedHeader,
          organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
        };
        const getPreSignedUrlResponse = await moduleApi.postData(
          apiCall.getPreSignedUrlForTemplateUpload,
          payload
        );
        console.log("presignedUrlResponse", getPreSignedUrlResponse);
        if (getPreSignedUrlResponse) {
          if (getPreSignedUrlResponse?.message === "Service Unavailable") {
            setIsLoading(false);
            return swal("Something Went Wrong, Please Try again", {
              icon: "error",
              dangerMode: true,
            });
          } else if (
            getPreSignedUrlResponse?.message === "File With Name Already Exist"
          ) {
            setIsLoading(false);
            return swal(
              "Uploaded Header File name already exists, Please Check.",
              {
                icon: "error",
                dangerMode: true,
              }
            );
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
        if (getPreSignedUrlResponse?.presignedUrl) {
          await moduleApi.putDataByName(
            getPreSignedUrlResponse?.presignedUrl,
            selectedFile
          );
        }

        const getHValueResponse = await moduleApi.postData(
          apiCall.getHValueForFileUpload,
          payload
        );
        console.log("hvaluereponse", getHValueResponse);
        if (getHValueResponse) {
          if (getHValueResponse?.message) {
            setIsLoading(false);
            return swal(getHValueResponse?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            headertemp.push(getHValueResponse?.hvalue);
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      }
      let updatedFormValues = { ...formValues };
      variableList.forEach((item) => {
        temp.push(item.variableValue);
      });
      if (selectedHeader === "text") {
        headerVariableList.forEach((item) => {
          headertemp.push(item.variableValue);
        });
      }
      if (dynamicUrl !== null) {
        // Update the value of the url field
        updatedFormValues.url = {
          ...updatedFormValues.url,
          value: updatedFormValues.url.value + "{{1}}",
          dynamicUrl: dynamicUrl,
        };
      }
      // console.log('headerValues', selectedHeader);
      // console.log('headerValues2', formValues);
      // console.log('headerValues3', headerVariableList);
      var body = {
        body: {
          text: updatedFormValues?.body,
          values: temp,
        },
        buttons: getButtonValues(),
        category: updatedFormValues?.category,
        footer: {
          text: updatedFormValues?.footer,
        },
        header: {
          format:
            selectedHeader === "text"
              ? selectedHeader?.toUpperCase()
              : updatedFormValues?.header?.toUpperCase(),
          params: headertemp,
          text: selectedHeader === "text" && updatedFormValues?.header,
          // type: selectedHeader,
        },
        language: updatedFormValues?.language,
        organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
        // status: "string",
        templateName: updatedFormValues?.templateName,
      };
      console.log("body", body);
      const response = await moduleApi.postData(apiCall.createTemplate, body);
      //  .then((response) => {
      console.log(response);
      if (response) {
        if (response?.message) {
          setIsLoading(false);
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          close();
          setIsLoading(false);
          return swal("Template Created Successfully", {
            icon: "success",
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    } catch (error) {}

    // });
  };

  return (
    <LoadingOverlayComponent showLoader={isLoading}>
      {" "}
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={8}
            style={{
              overflowY: "auto",
              maxHeight: "40rem",
              paddingRight: "10px",
            }}
          >
            <form noValidate autoComplete="off">
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginBottom: "15px",
                  padding: "15px",
                  borderRadius: "12px",
                  border: "1px solid #dae1e5",
                }}
              >
                <label
                  style={{
                    fontSize: "medium",
                    fontWeight: "400",
                    marginBottom: "3px",
                  }}
                  className="control-label"
                >
                  Template Name
                </label>
                <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                  Give your message template a name.
                </span>
                <MuiTextField
                  label="Template Name"
                  name="templateName"
                  description="Template names can only contain small letters, numbers and underscores."
                  value={formValues.templateName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </div>
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginBottom: "15px",
                  padding: "15px",
                  borderRadius: "12px",
                  border: "1px solid #dae1e5",
                }}
              >
                <label
                  style={{
                    fontSize: "medium",
                    fontWeight: "400",
                    marginBottom: "3px",
                  }}
                  className="control-label"
                >
                  Category
                </label>
                <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                  Choose what type of message template you want to create.
                </span>
                <div></div>
                <RadioGroup
                  row
                  aria-labelledby="category-row-radio-buttons-group-label"
                  name="category"
                  onChange={handleChange}
                  style={{
                    display: "flex",
                    flexFlow: "wrap",
                    marginTop: "15px",
                  }}
                >
                  <FormControlLabel
                    value="MARKETING"
                    control={<Radio />}
                    label={
                      <div
                        style={{
                          width: "195px",
                          height: "140px",
                          alignContent: "center",
                          padding: "1.125rem .9375rem",
                          background: "#f9fafb",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            flexDirection: "row",
                            gap: "8px",
                            display: "flex",
                            marginBottom: "8px",
                            WebkitBoxAlign: "center",
                            alignItems: "center",
                            paddingBottom: "1.6px",
                            paddingTop: "0px",
                            WebkitBoxPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              margin: "0px",
                              fontFamily: "poppins",
                              fontSize: "1rem",
                              lineHeight: "1.5rem",
                              fontWeight: "500",
                            }}
                          >
                            Marketing
                          </span>
                          <img
                            src="https://gs-upload.gupshup.io/templates/icons/ic_briefcase.svg"
                            alt=""
                            width="20px"
                            height="20px"
                            style={{ cursor: "pointer" }}
                          ></img>
                        </div>
                        <span
                          style={{
                            fontWeight: "300",
                            fontSize: ".8rem",
                            lineHeight: ".5rem",
                            whiteSpace: "break-spaces",
                            textWrap: "wrap",
                            fontFamily: "poppins",
                          }}
                        >
                          Send promo offers, product offers and more to increase
                          awareness and engagement.
                        </span>
                      </div>
                    }
                  />
                  <FormControlLabel
                    value="UTILITY"
                    control={<Radio />}
                    label={
                      <div
                        style={{
                          width: "195px",
                          height: "140px",
                          alignContent: "center",
                          padding: "1.125rem .9375rem",
                          background: "#f9fafb",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            flexDirection: "row",
                            gap: "8px",
                            display: "flex",
                            marginBottom: "8px",
                            WebkitBoxAlign: "center",
                            alignItems: "center",
                            paddingBottom: "1.6px",
                            paddingTop: "0px",
                            WebkitBoxPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              margin: "0px",
                              fontFamily: "poppins",
                              fontSize: "1rem",
                              lineHeight: "1.5rem",
                              fontWeight: "500",
                            }}
                          >
                            Utility
                          </span>
                          <img
                            src="https://gs-upload.gupshup.io/templates/icons/ic_megaphone.svg"
                            alt=""
                            width="20px"
                            height="20px"
                            style={{ cursor: "pointer" }}
                          ></img>
                        </div>
                        <span
                          style={{
                            fontWeight: "300",
                            fontSize: ".8rem",
                            lineHeight: ".5rem",
                            whiteSpace: "break-spaces",
                            textWrap: "wrap",
                            fontFamily: "poppins",
                          }}
                        >
                          Send account updates, order updates, alerts and more
                          to share important information.
                        </span>
                      </div>
                    }
                  />
                </RadioGroup>
                {/* {(formValues?.category === "marketing" ||
                formValues?.category === "utility") && (
                <RadioGroup
                  aria-labelledby="categoryType-radio-buttons-group-label"
                  name="categoryType"
                  onChange={handleChange}
                >
                  {(formValues?.category === "marketing" ||
                    formValues?.category === "utility") && (
                    <FormControlLabel
                      value="customMessage"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            width: "38rem",
                            height: "5rem",
                            padding: ".125rem .5rem",
                            background: "#f9fafb",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              boxSizing: "border-box",
                              gap: "8px",
                              marginBottom: "8px",
                              WebkitBoxAlign: "center",
                              alignItems: "center",
                              paddingTop: "8px",
                              paddingBottom: "1.6px",
                              WebkitBoxPack: "start",
                              justifyContent: "flex-start",
                            }}
                          >
                            <span
                              style={{
                                margin: "0px",
                                fontFamily: "poppins",
                                fontSize: "1rem",
                                lineHeight: "1.5",
                                fontWeight: "500",
                              }}
                            >
                              Custom Message
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: "300",
                              fontSize: "0.75rem",
                              position: "absolute",
                              fontFamily: "poppins",
                              lineHeight: "0.9375rem",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            Send promotional offers, announcements and more to
                            increase awareness and engagement.
                          </span>
                        </div>
                      }
                    />
                  )}
                  {formValues?.category === "marketing" && (
                    <FormControlLabel
                      value="productMessage"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            width: "38rem",
                            height: "5rem",
                            padding: ".125rem .5rem",
                            background: "#f9fafb",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              boxSizing: "border-box",
                              gap: "8px",
                              marginBottom: "8px",
                              WebkitBoxAlign: "center",
                              alignItems: "center",
                              paddingTop: "8px",
                              paddingBottom: "1.6px",
                              WebkitBoxPack: "start",
                              justifyContent: "flex-start",
                            }}
                          >
                            <span
                              style={{
                                margin: "0px",
                                fontFamily: "poppins",
                                fontSize: "1rem",
                                lineHeight: "1.5",
                                fontWeight: "500",
                              }}
                            >
                              Product Message
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: "lighter",
                              fontSize: "0.75rem",
                              fontFamily: "poppins",
                              position: "absolute",
                              lineHeight: "0.9375rem",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            Send messages about your entire catalogue or
                            multiple products from it.
                          </span>
                        </div>
                      }
                    />
                  )}
                  {(formValues?.category === "marketing" ||
                    formValues?.category === "utility") && (
                    <FormControlLabel
                      value="carousel"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            width: "38rem",
                            height: "5rem",
                            padding: ".125rem .5rem",
                            background: "#f9fafb",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              boxSizing: "border-box",
                              gap: "8px",
                              marginBottom: "8px",
                              WebkitBoxAlign: "center",
                              alignItems: "center",
                              paddingTop: "8px",
                              paddingBottom: "1.6px",
                              WebkitBoxPack: "start",
                              justifyContent: "flex-start",
                            }}
                          >
                            <span
                              style={{
                                margin: "0px",
                                fontFamily: "poppins",
                                fontSize: "1rem",
                                lineHeight: "1.5",
                                fontWeight: "500",
                              }}
                            >
                              Carousel
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: "lighter",
                              fontSize: "0.75rem",
                              fontFamily: "poppins",
                              position: "absolute",
                              lineHeight: "0.9375rem",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            You can send up to 10 carousel cards with images or
                            videos
                          </span>
                        </div>
                      }
                    />
                  )}
                  {formValues?.category === "marketing" && (
                    <FormControlLabel
                      value="limitedTimeOffer"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            width: "38rem",
                            height: "5rem",
                            padding: ".125rem .5rem",
                            background: "#f9fafb",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              boxSizing: "border-box",
                              gap: "8px",
                              marginBottom: "8px",
                              WebkitBoxAlign: "center",
                              alignItems: "center",
                              paddingTop: "8px",
                              paddingBottom: "1.6px",
                              WebkitBoxPack: "start",
                              justifyContent: "flex-start",
                            }}
                          >
                            <span
                              style={{
                                margin: "0px",
                                fontFamily: "poppins",
                                fontSize: "1rem",
                                lineHeight: "1.5",
                                fontWeight: "500",
                              }}
                            >
                              Limited time offer (LTO)
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: "lighter",
                              fontSize: "0.75rem",
                              fontFamily: "poppins",
                              position: "absolute",
                              lineHeight: "0.9375rem",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            Display expiration dates and running countdown
                            timers for offer codes
                          </span>
                        </div>
                      }
                    />
                  )}
                </RadioGroup>
              )} */}
              </div>
              {formValues?.categoryType === "productMessage" && (
                <div
                  className="card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    marginBottom: "15px",
                    padding: "15px",
                    borderRadius: "12px",
                    border: "1px solid #dae1e5",
                  }}
                >
                  <label
                    style={{
                      fontSize: "medium",
                      fontWeight: "400",
                      marginBottom: "3px",
                    }}
                    // className="control-label"
                  >
                    Template Format
                  </label>
                  <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                    Choose what type of message template you want to create.
                  </span>
                  <div></div>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="templateFormat"
                    style={{
                      display: "flex",
                      flexFlow: "wrap",
                      marginTop: "15px",
                    }}
                  >
                    <FormControlLabel
                      value="catalogueMessage"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            width: "195px",
                            height: "140px",
                            alignContent: "center",
                            padding: "1.125rem .9375rem",
                            background: "#f9fafb",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              boxSizing: "border-box",
                              flexDirection: "row",
                              gap: "8px",
                              display: "flex",
                              marginBottom: "8px",
                              WebkitBoxAlign: "center",
                              alignItems: "center",
                              paddingBottom: "1.6px",
                              paddingTop: "0px",
                              WebkitBoxPack: "justify",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                margin: "0px",
                                fontFamily: "poppins",
                                fontSize: "1rem",
                                lineHeight: "1.5rem",
                                fontWeight: "500",
                              }}
                            >
                              Catalogue Message
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: "300",
                              fontSize: ".8rem",
                              lineHeight: ".5rem",
                              whiteSpace: "break-spaces",
                              textWrap: "wrap",
                              fontFamily: "poppins",
                            }}
                          >
                            Include the entire catalogue to give your users a
                            comprehensive view of all your products.
                          </span>
                        </div>
                      }
                    />
                    <FormControlLabel
                      value="multiProductMessage"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            width: "200px",
                            height: "140px",
                            alignContent: "center",
                            padding: "1.225rem .9375rem",
                            background: "#f9fafb",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              boxSizing: "border-box",
                              flexDirection: "row",
                              gap: "8px",
                              display: "flex",
                              marginBottom: "8px",
                              WebkitBoxAlign: "center",
                              alignItems: "center",
                              paddingBottom: "1.6px",
                              paddingTop: "0px",
                              WebkitBoxPack: "justify",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                margin: "0px",
                                fontFamily: "poppins",
                                fontSize: "1rem",
                                lineHeight: "1.5rem",
                                fontWeight: "500",
                              }}
                            >
                              Multi-Product Message
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: "300",
                              fontSize: ".8rem",
                              lineHeight: ".5rem",
                              whiteSpace: "break-spaces",
                              textWrap: "wrap",
                              fontFamily: "poppins",
                            }}
                          >
                            Include upto 30 products from the catalogue. Useful
                            for showcasing new collection or a specific product
                            category.
                          </span>
                        </div>
                      }
                    />
                  </RadioGroup>
                </div>
              )}
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginBottom: "15px",
                  padding: "15px",
                  borderRadius: "12px",
                  border: "1px solid #dae1e5",
                }}
              >
                <label
                  style={{
                    fontSize: "medium",
                    fontWeight: "400",
                    marginBottom: "3px",
                  }}
                  className="control-label"
                >
                  Language
                </label>
                <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                  Choose which languages your message template will be sent in.
                </span>
                <MuiTextField
                  select
                  label="Select"
                  name="language"
                  value={formValues.language}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  {languages.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MuiTextField>
              </div>
              {/* <div
              className="card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginBottom: "15px",
                padding: "15px",
                borderRadius: "12px",
                border: "1px solid #dae1e5",
              }}
            >
              <label
                style={{
                  fontSize: "medium",
                  fontWeight: "400",
                  marginBottom: "3px",
                }}
                className="control-label"
              >
                Template Labels
              </label>
              <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                Define what use-case does this template serves e.g Account
                update, OTP, etc in 2-3 words
              </span>
              <MuiTextField
                label="Template Labels"
                name="templateLabel"
                value={formValues.templateLabel}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div> */}
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginBottom: "15px",
                  padding: "15px",
                  borderRadius: "12px",
                  border: "1px solid #dae1e5",
                }}
              >
                <label
                  style={{
                    fontSize: "medium",
                    fontWeight: "400",
                    marginBottom: "3px",
                  }}
                  // className="control-label"
                >
                  Header{" "}
                  <span
                    style={{
                      padding: "4px 8px",
                      margin: "4px",
                      fontSize: ".9rem",
                      backgroundColor: "#e7e6ff",
                      borderRadius: "4px",
                    }}
                  >
                    {" "}
                    Optional
                  </span>
                </label>
                <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                  Add a 60 character title to your message. Only one variable is
                  supported in the header.
                </span>
                <MuiTextField
                  select
                  label="Select"
                  name="header"
                  // value={formValues.header}
                  onChange={handleHeaderChange}
                  fullWidth
                  margin="normal"
                >
                  {headerOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MuiTextField>
                {selectedHeader === "text" && (
                  <MuiTextField
                    label="Header Text"
                    name="header"
                    value={formValues.header}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                )}
                {selectedHeader === "text" && (
                  <div
                    style={{
                      display: "inline-flex",
                      borderRadius: "4px",
                      boxShadow: "none",
                      marginTop: "20px",
                      height: "38px",
                      //   border:'1px solid rgba(89, 86, 214, 0.5)',
                      //   color:'rgb(89, 86, 214)'
                    }}
                  >
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        !headerVariableList?.length > 0
                          ? ""
                          : "Only one variable is allowed"
                      }
                    >
                      <Button
                        style={{
                          border:
                            !headerVariableList?.length > 0
                              ? "1px solid rgba(89, 86, 214, 0.5)"
                              : "1px solid rgba(0, 0, 0, 0.12)",
                          color:
                            !headerVariableList?.length > 0
                              ? "rgb(89, 86, 214)"
                              : "rgba(0, 0, 0, 0.26)",
                        }}
                        disabled={headerVariableList?.length > 0 ? true : false}
                        onClick={addVarible2}
                      >
                        {" "}
                        <i
                          className="fa fa-plus"
                          style={{
                            color:
                              !headerVariableList?.length > 0
                                ? "rgb(89, 86, 214)"
                                : "rgba(0, 0, 0, 0.26)",
                          }}
                        ></i>{" "}
                        &nbsp; &nbsp;
                        <span
                          style={{
                            fontFamily: "poppins",
                            color:
                              !headerVariableList?.length > 0
                                ? "rgb(89, 86, 214)"
                                : "rgba(0, 0, 0, 0.26)",
                          }}
                        >
                          Add Variable
                        </span>
                      </Button>
                    </Tooltip>
                  </div>
                )}

                {selectedHeader === "media" && (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="header"
                    onChange={handleChange}
                    style={{
                      display: "flex",
                      flexFlow: "wrap",
                      marginTop: "15px",
                    }}
                  >
                    <FormControlLabel
                      value="image"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid #cbcbcb",
                            borderRadius: "4px",
                            padding: "20px 25px",
                            alignItems: "center",
                            rowGap: "10px",
                            textAlign: "center",
                            boxShadow:
                              "0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)",
                          }}
                        >
                          <img
                            src="https://gs-upload.gupshup.io/templates/images/media_image.png"
                            alt=""
                            width="45px"
                            height="45px"
                            style={{ cursor: "pointer" }}
                          />
                          <span>Image</span>
                        </div>
                      }
                    />
                    <FormControlLabel
                      value="video"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid #cbcbcb",
                            borderRadius: "4px",
                            padding: "20px 25px",
                            alignItems: "center",
                            rowGap: "10px",
                            textAlign: "center",
                            boxShadow:
                              "0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)",
                          }}
                        >
                          <img
                            src="https://gs-upload.gupshup.io/templates/images/media_video.png"
                            alt=""
                            width="45px"
                            height="45px"
                            style={{ cursor: "pointer" }}
                          />
                          <span>Video</span>
                        </div>
                      }
                    />
                    <FormControlLabel
                      value="document"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid #cbcbcb",
                            borderRadius: "4px",
                            padding: "20px 18px",
                            alignItems: "center",
                            rowGap: "10px",
                            textAlign: "center",
                            boxShadow:
                              "0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)",
                          }}
                        >
                          <img
                            src="https://gs-upload.gupshup.io/templates/images/media_document.png"
                            alt=""
                            width="45px"
                            height="45px"
                            style={{ cursor: "pointer" }}
                          />
                          <span>Document</span>
                        </div>
                      }
                    />
                    {/* <FormControlLabel
                      value="location"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid #cbcbcb",
                            borderRadius: "4px",
                            padding: "20px 22px",
                            alignItems: "center",
                            rowGap: "10px",
                            textAlign: "center",
                            boxShadow:
                              "0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)",
                          }}
                        >
                          <img
                            src="https://gs-upload.gupshup.io/templates/icons/ic_media_location.svg"
                            alt=""
                            width="45px"
                            height="45px"
                            style={{ cursor: "pointer" }}
                          />
                          <span>Location</span>
                        </div>
                      }
                    /> */}
                  </RadioGroup>
                )}
              </div>
              {headerVariableList?.length > 0 && (
                <div
                  className="card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    marginBottom: "15px",
                    padding: "10px",
                    borderRadius: "12px",
                    border: "1px solid #dae1e5",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "poppins",
                      fontSize: "medium",
                      fontWeight: "400",
                    }}
                  >
                    Define sample variable value for header
                  </span>
                  {headerVariableList?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          borderRadius: "4px",
                          // border: "1px solid #c5cad9",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <MuiTextField
                          label="Variable"
                          name="variable"
                          value={item.variable}
                          disabled
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                        <MuiTextField
                          label="Sample Value"
                          name="sampleValue"
                          placeHolder="Enter sample variable value"
                          value={item.variableValue}
                          onChange={(e) => {
                            updateVariableValue(e, item, true);
                          }}
                          fullWidth
                          margin="normal"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {selectedHeader === "media" &&
                formValues.header &&
                formValues.header !== "location" && (
                  <div
                    className="card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      marginBottom: "15px",
                      padding: "15px",
                      borderRadius: "12px",
                      border: "1px solid #dae1e5",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "medium",
                        fontWeight: "400",
                        marginBottom: "3px",
                      }}
                      className="control-label"
                    >
                      Add Sample Media{" "}
                    </label>
                    <TextField
                      style={{ fontFamily: "poppins" }}
                      type={"file"}
                      inputProps={{
                        accept:
                          "image/jpeg,image/png,video/mp4,application/pdf", // Allow all types
                        onChange: handleFileChange,
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "#fff6e6",
                        borderRadius: "6px",
                        padding: "20px",
                        fontSize: "0.875rem",
                        fontFamily: "poppins",
                        color: "#3f3f3f",
                        fontWeight: "400",
                        marginTop: "20px",
                      }}
                    >
                      <strong>Note: </strong>
                      <span style={{ fontSize: "0.9rem" }}>
                        {" "}
                        Following are the file formats and their respective
                        sizes which are accepted for upload{" "}
                      </span>
                      <ul
                        style={{
                          paddingLeft: "18px",
                          listStyleType: "decimal",
                        }}
                      >
                        {fileLimitations?.map((item, index) => {
                          return (
                            <li
                              key={index}
                              style={{ marginTop: "5px", fontSize: "0.85rem" }}
                            >
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}

              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginBottom: "15px",
                  padding: "15px",
                  borderRadius: "12px",
                  border: "1px solid #dae1e5",
                }}
              >
                <label
                  style={{
                    fontSize: "medium",
                    fontWeight: "400",
                    marginBottom: "3px",
                  }}
                  className="control-label"
                >
                  Body
                </label>
                <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                  Enter the text for your message in the language you have
                  selected.
                </span>
                <MuiTextField
                  label="Body"
                  name="body"
                  value={formValues.body}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                  margin="normal"
                />
                <div
                  style={{
                    display: "inline-flex",
                    borderRadius: "4px",
                    boxShadow: "none",
                    marginTop: "20px",
                    height: "38px",
                    //   border:'1px solid rgba(89, 86, 214, 0.5)',
                    //   color:'rgb(89, 86, 214)'
                  }}
                >
                  <Button
                    style={{
                      border: "1px solid rgba(89, 86, 214, 0.5)",
                      color: "rgb(89, 86, 214)",
                    }}
                    onClick={addVarible}
                  >
                    {" "}
                    <i className="fa fa-plus"></i> &nbsp; &nbsp;
                    <span style={{ fontFamily: "poppins" }}>Add Variable</span>
                  </Button>
                </div>
              </div>
              {variableList?.length > 0 && (
                <div
                  className="card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    marginBottom: "15px",
                    padding: "10px",
                    borderRadius: "12px",
                    border: "1px solid #dae1e5",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "poppins",
                      fontSize: "medium",
                      fontWeight: "400",
                    }}
                  >
                    Define sample variable value for body
                  </span>
                  {variableList?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          borderRadius: "4px",
                          // border: "1px solid #c5cad9",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <MuiTextField
                          label="Variable"
                          name="variable"
                          value={item.variable}
                          disabled
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                        <MuiTextField
                          label="Sample Value"
                          name="sampleValue"
                          placeHolder="Enter sample variable value"
                          value={item.variableValue}
                          onChange={(e) => {
                            updateVariableValue(e, item, false);
                          }}
                          fullWidth
                          margin="normal"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginBottom: "15px",
                  padding: "15px",
                  borderRadius: "12px",
                  border: "1px solid #dae1e5",
                }}
              >
                <label
                  style={{
                    fontSize: "medium",
                    fontWeight: "400",
                    marginBottom: "3px",
                  }}
                  // className="control-label"
                >
                  Footer{" "}
                  <span
                    style={{
                      padding: "4px 8px",
                      margin: "4px",
                      fontSize: ".9rem",
                      backgroundColor: "#e7e6ff",
                      borderRadius: "4px",
                    }}
                  >
                    {" "}
                    Optional
                  </span>
                </label>
                <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                  Add a 60 character footer to your message. Variables are not
                  supported in the footer.
                </span>
                <MuiTextField
                  label="Footer"
                  name="footer"
                  value={formValues.footer}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </div>
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginBottom: "15px",
                  padding: "15px",
                  borderRadius: "12px",
                  border: "1px solid #dae1e5",
                }}
              >
                <label
                  style={{
                    fontSize: "medium",
                    fontWeight: "400",
                    marginBottom: "3px",
                  }}
                  // className="control-label"
                >
                  Buttons{" "}
                  <span
                    style={{
                      padding: "4px 8px",
                      margin: "4px",
                      fontSize: ".9rem",
                      backgroundColor: "#e7e6ff",
                      borderRadius: "4px",
                    }}
                  >
                    {" "}
                    Optional
                  </span>
                </label>
                <span style={{ marginBottom: "-5px", fontWeight: "lighter" }}>
                  Please choose buttons to be added to the template. You can
                  choose upto 10 buttons
                </span>
                <div
                  style={{
                    display: "inline-flex",
                    borderRadius: "4px",
                    boxShadow: "none",
                    marginTop: "20px",
                    height: "38px",
                  }}
                >
                  <ButtonGroup
                    variant="outlined"
                    ref={anchorRef}
                    aria-label="Button group with a nested menu"
                  >
                    <Button
                      style={{
                        border: "1px solid rgba(89, 86, 214, 0.5)",
                        color: "rgb(89, 86, 214)",
                      }}
                      // onClick={handleClick}
                    >
                      {" "}
                      <i className="fa fa-plus"></i> &nbsp; &nbsp;
                      <span style={{ fontFamily: "poppins" }}>Add Button</span>
                    </Button>
                    <Button
                      style={{
                        border: "1px solid rgba(89, 86, 214, 0.5)",
                        color: "rgb(89, 86, 214)",
                      }}
                      size="small"
                      aria-controls={open ? "split-button-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                        name="caretUp"
                        style={{ cursor: "pointer", color: "rgb(89, 86, 214)" }}
                      >
                        <path d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                      </svg>
                    </Button>
                  </ButtonGroup>
                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {buttonOptions.map((option, index) => (
                                <MenuItem
                                  key={option.value}
                                  selected={
                                    option.value === selectedButtonValue
                                  }
                                  onClick={() =>
                                    handleMenuItemClick(option, index)
                                  }
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
                <div>
                  {(buttonValues.copy_offer_code ||
                    buttonValues.url ||
                    buttonValues.phone_number) && (
                    <div
                      style={{
                        borderRadius: "4px",
                        padding: "4px 12px",
                        margin: "12px 0",
                        border: "1px solid #c5cad9",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontSize: "1.1rem",
                          fontWeight: "400",
                          margin: "10px",
                        }}
                      >
                        Call to action
                      </span>
                      <div></div>
                      {/* phone_number */}
                      {buttonValues.phone_number && (
                        <div
                          style={{
                            borderRadius: "4px",
                            padding: "12px ",
                            margin: "12px 0",
                            border: "1px solid #c5cad9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <MuiTextField
                              select
                              label="Select type"
                              name="phone_number"
                              defaultValue="phone_number"
                              disabled
                              // value={formValues.header}
                              onChange={handleHeaderChange}
                              fullWidth
                              margin="normal"
                            >
                              {buttonOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </MuiTextField>
                            <MuiTextField
                              label="Button Title"
                              name="phone_number"
                              //   value={formValues.footer}
                              onChange={(e) => {
                                buttonHandleChange(e, "phone_number", "label");
                              }}
                              fullWidth
                              margin="normal"
                            />
                            {/* <MuiTextField
                      select
                      label="Select Url type"
                      name="urlType"
                      // value={formValues.header}
                      onChange={handleHeaderChange}
                      fullWidth
                      margin="normal"
                    >
                      {buttonOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MuiTextField> */}
                            <MuiTextField
                              label="Button value"
                              name="phone_number"
                              value={formValues.phone_number.value}
                              onChange={(e) => {
                                buttonHandleChange(e, "phone_number", "value");
                              }}
                              fullWidth
                              margin="normal"
                            />
                          </div>
                        </div>
                      )}
                      {/* copy_offer_code */}
                      {buttonValues.copy_offer_code && (
                        <div
                          style={{
                            borderRadius: "4px",
                            padding: "12px ",
                            margin: "12px 0",
                            border: "1px solid #c5cad9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <MuiTextField
                              select
                              label="Select type"
                              //   name="header"

                              disabled
                              defaultValue="copy_offer_code"
                              onChange={handleHeaderChange}
                              fullWidth
                              margin="normal"
                            >
                              {buttonOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </MuiTextField>
                            <MuiTextField
                              label="Button Title"
                              //   name="copy_offer_code"
                              value={formValues.copy_offer_code.label}
                              disabled
                              onChange={(e) => {
                                buttonHandleChange(
                                  e,
                                  "copy_offer_code",
                                  "label"
                                );
                              }}
                              fullWidth
                              margin="normal"
                            />

                            <MuiTextField
                              label="Button value"
                              name="copy_offer_code"
                              //   value={formValues.footer}
                              onChange={(e) => {
                                buttonHandleChange(
                                  e,
                                  "copy_offer_code",
                                  "value"
                                );
                              }}
                              fullWidth
                              margin="normal"
                            />
                          </div>
                        </div>
                      )}
                      {/* url */}
                      {buttonValues.url && (
                        <div
                          style={{
                            border: "1px solid #c5cad9",
                            borderRadius: "4px",
                            // padding: "5px ",
                            margin: "10px 0",
                          }}
                        >
                          <div
                            style={{
                              borderRadius: "4px",
                              padding: "12px ",
                              // margin: "5px 0",
                              // border: "1px solid #c5cad9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <MuiTextField
                                select
                                label="Select type"
                                //   name="url"
                                defaultValue="url"
                                // value={formValues.header}
                                disabled
                                onChange={handleHeaderChange}
                                fullWidth
                                margin="normal"
                              >
                                {buttonOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </MuiTextField>
                              <MuiTextField
                                label="Button Title"
                                name="buttonTitle"
                                //   value={formValues.footer}
                                onChange={(e) => {
                                  buttonHandleChange(e, "url", "label");
                                }}
                                fullWidth
                                margin="normal"
                              />
                              <MuiTextField
                                select
                                label="Select Url type"
                                name="urlType"
                                // value={formValues.header}
                                onChange={handleUrlChange}
                                fullWidth
                                margin="normal"
                              >
                                {urlOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </MuiTextField>
                              <MuiTextField
                                label="Button value"
                                name="buttonValue"
                                //   value={formValues.footer}
                                onChange={(e) => {
                                  buttonHandleChange(e, "url", "value");
                                }}
                                fullWidth
                                margin="normal"
                              />
                            </div>
                          </div>
                          {buttonValues.url && selectedUrl === "dynamic" && (
                            <div
                              // className="card"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                marginBottom: "10px",
                                marginLeft: "10px",
                                padding: "10px",
                                borderRadius: "12px",
                                // border: "1px solid #dae1e5",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "1.1rem",
                                  fontWeight: "500",
                                  color: "red",
                                }}
                              >
                                Sample dynamic URL with variable
                              </span>
                              <span
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "1rem",
                                  fontWeight: "lighter",
                                  color: "red",
                                }}
                              >
                                ( Note: Do not add the URL in your sample
                                message body above )
                              </span>

                              <div
                                style={{
                                  borderRadius: "4px",
                                  // border: "1px solid #c5cad9",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "poppins",
                                    fontSize: "large",
                                    color: "black",
                                    fontWeight: "400",
                                  }}
                                >
                                  {"{{1}}"}
                                </span>
                                <MuiTextField
                                  label="Sample Suffix"
                                  name="sampleSuffix"
                                  placeHolder="Enter sample suffix"
                                  // value={item.variableValue}
                                  onChange={(e) => {
                                    setDynamicUrl(e.target.value);
                                  }}
                                  fullWidth
                                  margin="normal"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {buttonValues.quick_reply && (
                    <div
                      style={{
                        borderRadius: "4px",
                        padding: "4px 12px",
                        margin: "12px 0",
                        border: "1px solid #c5cad9",
                      }}
                    >
                      <div></div>
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontSize: "1.2rem",
                          fontWeight: "400",
                          margin: "10px",
                        }}
                      >
                        Quick Reply
                      </span>
                      <div></div>
                      <div
                        style={{
                          borderRadius: "4px",
                          padding: "12px ",
                          margin: "12px 0",
                          border: "1px solid #c5cad9",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <MuiTextField
                          label="Quick Reply Title"
                          name="quick_reply"
                          //   value={formValues.footer}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                        {/* <div>hh</div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginRight: "10px",
                  margin: "10px",
                }}
              >
                <Button
                  // type="submit"
                  variant="contained"
                  style={{
                    // padding:'8px 16px',
                    height: "38px",
                    backgroundColor: "rgb(89, 86, 214)",
                    color: "rgb(255, 255, 255)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    lineHeight: "1.5",
                    pointerEvents: "auto",
                    borderRadius: "4px",
                    verticalAlign: "middle",
                    cursor: "pointer",
                    position: "relative",
                    alignItems: "center",
                    WebkitBoxAlign: "center",
                    display: "inline-flex",
                    WebkitBoxPack: "center",
                    boxShadow:
                      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </Grid>
          <Grid item xs={4}>
            <Paper
              elevation={3}
              style={{
                padding: 16,
                backgroundColor: "#fef9f3",
                borderRadius: "12px",
              }}
            >
              <Typography style={{ fontFamily: "poppins" }} variant="h6">
                Preview
              </Typography>
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  // padding: "10px",
                  fontFamily: "poppins",
                }}
              >
                <div style={{ padding: "10px" }}>
                  {selectedHeader === "text" ? (
                    <Typography
                      style={{ fontFamily: "poppins" }}
                      variant="header"
                    >
                      {replaceBodyString(formValues.header, true)}
                    </Typography>
                  ) : (
                    selectedHeader === "media" &&
                    formValues?.header && (
                      <img
                        style={{ width: "100%" }}
                        alt="Sample Media"
                        title={formValues?.header}
                        src={getPreviewImage(formValues?.header)}
                      ></img>
                    )
                  )}
                  {/* <Typography style={{ fontFamily: "poppins" }} variant="subtitle1">
                {formValues.templateName}
              </Typography>
              <Typography style={{ fontFamily: "poppins" }} variant="subtitle1">
                {formValues.category}
              </Typography> */}
                  {/* <Typography style={{ fontFamily: "poppins" }} variant="subtitle2">
                {formValues.templateLabel}
              </Typography>
              <Typography
                style={{ fontFamily: "poppins" }}
                variant="caption"
                display="block"
                gutterBottom
              >
                {
                  languages.find((lang) => lang.value === formValues.language)
                    ?.label
                }
              </Typography> */}

                  <Typography
                    style={{
                      fontFamily: "poppins",
                      fontSize: "0.85rem",
                      color: "#3f3f3f",
                      overflowWrap: "break-word",
                      maxWidth: "100%",
                      maxHeight: "400px",
                      padding: "0px 0px 8px",
                      whiteSpace: "pre-line",
                      display: "-webkit-box",
                      fontWeight: "400",
                      marginTop: "10px",
                      overflowY: "auto",
                    }}
                    variant="body1"
                    gutterBottom
                  >
                    {replaceBodyString(formValues.body, false)}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "poppins",
                      fontSize: "0.75rem",
                      color: "#5f6880",
                      overflowWrap: "break-word",
                      maxWidth: "100%",
                      WebkitLineClamp: "3",
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                      maxHeight: "220px",
                      padding: "0px 0px 8px",
                      wordBreak: "break-word",
                      whiteSpace: "pre-line",
                      display: "-webkit-box",
                      fontWeight: "500",
                      overflow: "hidden",
                    }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    {formValues.footer}
                  </Typography>
                  <div
                    style={{
                      textAlign: "end",
                      fontSize: "0.725rem",
                      color: "#5f6880",
                      fontFamily: "poppins",
                    }}
                  >
                    {convertEpochToTime3(new Date().getTime() / 1000)}
                  </div>
                </div>
                <div>
                  {buttonValues?.phone_number && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0.7rem 1rem",
                        textAlign: "center",
                        color: "#00a5f4",
                        backgroundColor: "#fefefe",
                        cursor: "pointer",
                        marginBottom: "0px",
                        borderRadius: "0px 0px 6px 6px",
                        wordBreak: "break-word",
                        borderTop: "0.36px solid #dbdbdb",
                        boxShadow: "0 1px 3px 0 #f4f4f4",
                        fontSize: "0.75rem",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        src="https://gs-upload.gupshup.io/whatsapp/preview_call1.webp"
                        alt=""
                        style={{ marginRight: "10px", width: "1.5rem" }}
                      />
                      {formValues?.phone_number.label}
                    </div>
                  )}
                  {buttonValues?.copy_offer_code && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0.7rem 1rem",
                        textAlign: "center",
                        color: "#00a5f4",
                        backgroundColor: "#fefefe",
                        cursor: "pointer",
                        marginBottom: "0px",
                        borderRadius: "0px 0px 6px 6px",
                        wordBreak: "break-word",
                        borderTop: "0.36px solid #dbdbdb",
                        boxShadow: "0 1px 3px 0 #f4f4f4",
                        fontSize: "0.75rem",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        src="https://gs-upload.gupshup.io/whatsapp/copy_code.svg"
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      {formValues?.copy_offer_code.label}
                    </div>
                  )}
                  {buttonValues?.url && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0.7rem 1rem",
                        textAlign: "center",
                        color: "#00a5f4",
                        backgroundColor: "#fefefe",
                        cursor: "pointer",
                        marginBottom: "0px",
                        borderRadius: "0px 0px 6px 6px",
                        wordBreak: "break-word",
                        borderTop: "0.36px solid #dbdbdb",
                        boxShadow: "0 1px 3px 0 #f4f4f4",
                        fontSize: "0.75rem",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        src="https://gs-upload.gupshup.io/whatsapp/preview_call2.webp"
                        alt=""
                        style={{ marginRight: "10px", width: "1.5rem" }}
                      />
                      {formValues?.url.label}
                    </div>
                  )}
                  {buttonValues?.quick_reply && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0.7rem 1rem",
                        textAlign: "center",
                        color: "#00a5f4",
                        backgroundColor: "#fefefe",
                        cursor: "pointer",
                        marginBottom: "0px",
                        borderRadius: "0px 0px 6px 6px",
                        wordBreak: "break-word",
                        borderTop: "0.36px solid #dbdbdb",
                        boxShadow: "0 1px 3px 0 #f4f4f4",
                        fontSize: "0.75rem",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        src="https://gs-upload.gupshup.io/whatsapp/reply.svg"
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      {formValues?.quick_reply}
                    </div>
                  )}
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </LoadingOverlayComponent>
  );
};

export default CreateTemplate;
