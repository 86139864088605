import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
// import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
// import { isAdmin } from "../services/AuthService";
// import { toaster } from "../rest/apiUtils";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga";

const TelecallerUpload = ({ close, loadData }) => {
  const [data, setData] = useState([]);
  // const [binaryExcelFileData, setBinaryExcelFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [fileName, setFileName] = useState(null);
  const [excelData, setExcelData] = useState(null);

  var userData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    ReactGA.pageview("Telecaller Allocations Work Upload");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  // const handleFile = (e) => {
  //   let selectedFile = e.target.files[0];
  //   let reader = new FileReader();
  //   reader.readAsArrayBuffer(selectedFile);
  //   reader.onload = (e) => {
  //     var bytes = new Uint8Array(reader.result);
  //     console.log("result", bytes);
  //     setBinaryExcelFileData(bytes);
  //   };
  // };

  // const handleFile = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     const binaryData = event.target.result;
  //     const workbook = XLSX.read(binaryData, { type: "binary" });
  //     // Get the first worksheet
  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //     // Convert the worksheet to a CSV string with a pipe separator
  //     const csvString = XLSX.utils.sheet_to_csv(worksheet, { FS: "|" });
  //     // Convert the CSV string to a Blob object
  //     const csvBlob = new Blob([csvString], { type: "text/csv" });
  //     // Set the CSV data to state
  //     setBinaryExcelFileData(csvBlob);
  //   };

  //   reader.readAsBinaryString(file);
  // };

  const onChange = (e) => {
    setIsLoading(true);
    // setBinaryExcelFileData(null);
    const file = e.target.files[0];
    console.log("fileName", file.name);
    if (file && fileType.includes(file.type)) {
      // handleFile(e);
      const reader = new FileReader();
      reader.onload = (event) => {
        const binary = event.target.result;
        const workbook = readExcel(binary);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = sheetToArray(worksheet);
        // const dueDateIndex = rows[0]?.findIndex((name) =>
        //   name.toLowerCase().includes("due date")
        // );
        // const agDateIndex = rows[0]?.findIndex((name) =>
        //   name.toLowerCase().includes("ag date")
        // );

        // for (let i = 1; i < rows.length; i++) {
        //   // for (let j = 0; j < rows[i].length; j++) {
        //   const dueDate = new Date(
        //     (rows[i][dueDateIndex] - 25569) * 86400 * 1000
        //   );
        //   const agDate = new Date(
        //     (rows[i][agDateIndex] - 25569) * 86400 * 1000
        //   );
        //   const options = {
        //     day: "2-digit",
        //     month: "2-digit",
        //     year: "numeric",
        //   };
        //   const formattedDate1 = dueDate.toLocaleDateString("en-IN", options);
        //   const formattedDate2 = agDate.toLocaleDateString("en-IN", options);
        //   // }
        //   rows[i][dueDateIndex] = formattedDate1;
        //   rows[i][agDateIndex] = formattedDate2;
        // }
        console.log("rows", rows);
        const listData = rows.map(([loanNumber, teleCallerEmpId]) => ({
          loanNumber,
          teleCallerEmpId,
        }));
        listData.shift();
        console.log("list", listData);
        setExcelData(listData);
        setData(rows);
        setIsLoading(false);
      };
      reader.readAsBinaryString(file);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return swal("Please Upload Excel Files Only", {
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const readExcel = (binary) => {
    const { length } = binary;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binary.charCodeAt(i) & 0xff;
    }
    return XLSX.read(bytes, { type: "array" });
  };

  const sheetToArray = (sheet) => {
    const rows = [];
    const range = XLSX.utils.decode_range(sheet["!ref"]);
    for (let row = range.s.r; row <= range.e.r; row++) {
      const rowValues = [];
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = sheet[XLSX.utils.encode_cell({ r: row, c: col })];
        const cellValue = cell ? cell.v : undefined;
        rowValues.push(cellValue);
      }
      rows.push(rowValues);
    }
    return rows;
  };

  const renderTable = () => {
    if (data.length === 0) {
      return null;
    }
    const headers = data[0];
    const rows = data?.slice(1);
    return (
      <div className="table-responsive">
        {data && (
          <span style={{ margin: "5px" }}>
            <b>Note:</b> Only 10 records will be shown in the preview
          </span>
        )}
        <table className="table">
          <thead
            style={{
              backgroundColor: "rgb(243,240,249)",
              top: "1px",
              position: "sticky",
            }}
          >
            <tr style={{ textAlign: "center" }}>
              {headers?.map((header, index) => {
                return <th key={index}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {/* eslint-disable-next-line */}
            {rows?.map((row, index) => {
              if (index < 10) {
                return (
                  <tr
                    key={index}
                    style={{ textAlign: "center", fontWeight: "normal" }}
                  >
                    {row?.map((cell, cellIndex) => {
                      return <td key={cellIndex}>{cell}</td>;
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const uploadTelecallerFile = () => {
    setIsLoading(true);
    let payload = {
      allocations: excelData,
      // closingDate: "string",
      // openingDate: "string",
      organizationId: userData.autoFinanceUser.organizationId,
    };
    console.log("payload", payload);

    moduleApi
      .postData(apiCall.uploadTelecallerData, payload)
      .then((response) => {
        if (response) {
          if (response?.Message === "Allocations Update SucessFully") {
            // toaster("success", "Uploaded Successfully");
            setIsLoading(false);
            close();
            loadData();
            swal("Uploaded Successfully!", {
              icon: "success",
            });
          } else if (
            response?.status === 605 ||
            response?.status === 604 ||
            response?.status === 600 ||
            response?.status === 400
          ) {
            setIsLoading(false);
            return swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  //   const sendExcelFileToS3 = () => {
  //     console.log("exceldata", binaryExcelFileData);
  //     if (binaryExcelFileData === null) {
  //       return swal("Please Upload Excel File", {
  //         icon: "error",
  //         dangerMode: true,
  //       });
  //     }
  //     if (fileName === null) {
  //       return swal("Please Enter File Name", {
  //         icon: "error",
  //         dangerMode: true,
  //       });
  //     }
  //     setIsLoading(true);
  //     let url =
  //       apiCall.bulkUploadBaseUrl +
  //       "?organizationName=" +
  //       userData.autoFinanceOrganization.organizationName;
  //     let payload = {
  //       orgId: userData.autoFinanceUser.organizationId,
  //       // orgName: userData.autoFinanceOrganization.organizationName,
  //       action: "preSignedUrl",
  //       fileName: fileName,
  //     };
  //     moduleApi
  //       .getDataByOrgName(
  //         url,
  //         payload.orgId,
  //         // payload.orgName,
  //         payload.action,
  //         payload.fileName
  //       )
  //       .then((response) => {
  //         console.log("preSignedUrl", response);
  //         if (response) {
  //           if (response?.message === "Service Unavailable") {
  //             setIsLoading(false);
  //             return swal("Something Went Wrong, Please Try again", {
  //               icon: "error",
  //               dangerMode: true,
  //             });
  //           } else if (
  //             response?.message === "File name already exist in an organization"
  //           ) {
  //             setIsLoading(false);
  //             return swal("File name already exists, Please Check.", {
  //               icon: "error",
  //               dangerMode: true,
  //             });
  //           } else if (response?.generatedUrl) {
  //             moduleApi
  //               .putDataByName(response?.generatedUrl, binaryExcelFileData)
  //               .then((response) => {
  //                 //Service Unavailable
  //                 console.log("bulk Upload", response);
  //                 setIsLoading(false);
  //                 close();
  //                 loadData();
  //                 // getActiveKey();
  //                 swal(
  //                   "Please Check the Status of the " +
  //                     payload.fileName +
  //                     " File in Uploaded Files Tab",
  //                   {
  //                     icon: "success",
  //                   }
  //                 );
  //               });
  //           }
  //         } else {
  //           setIsLoading(false);
  //           return swal("Something Went Wrong, Please Try Again Later", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //   };

  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div>
        <div>
          {/* <form
              className="form-group"
              autoComplete="off"
              // onSubmit={handleSubmit}
            > */}
          <Row>
            {/* <Col>
                      <div style={{ marginBottom: "5px" }}>
                        <label>
                          <h6>Select Executive</h6>
                        </label>
                        <Select
                          isSearchable={false}
                          onChange={(value) => {
                            setSelectedExecutive(value);
                          }}
                          // value={executivesList[executiveFilterIndex]}
                          options={executivesList}
                        />
                      </div>
                    </Col> */}
            <Col>
              <label>
                <h6>Upload Excel file</h6>
              </label>

              <input
                style={{ padding: "11px" }}
                className="form-control"
                type="file"
                onChange={onChange}
              />
              {/* {excelFileError && (
                    <div
                      className="text-danger"
                      style={{ marginTop: 5 + "px" }}
                    >
                      {excelFileError}
                    </div>
                  )} */}
            </Col>
            {/* <Col>
              <label>
                <h6>File Name</h6>
              </label>
              <input
                className="form-control"
                type="text"
                name="fileName"
                onChange={(e) => {
                  setFileName(e.target.value);
                }}
                style={{ padding: "11px" }}
              />
            </Col> */}
            <Col>
              {/* {isSubmit && ( */}
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                onClick={() => {
                  uploadTelecallerFile();
                }}
              >
                Submit
              </button>
              {/* )} */}
            </Col>
            {/* <Col>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      // excelFile !== null && setIsSubmit(true);
                      // close();
                      // getActiveKey();
                      if (excelFile !== null) {
                        // setIsSubmit(true);
                        if (fileName === null) {
                          return swal("Please Enter File Name", {
                            icon: "error",
                            dangerMode: true,
                          });
                        } else {
                          setIsSubmit(true);
                        }
                      }
                    }}
                    style={{ marginTop: 35 + "px" }}
                  >
                    {loader && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        variant="light"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;&nbsp;Preview
                  </button>
                  {isSubmit && (
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                      // onClick={() => {
                      //   uploadDailyScheduleData();
                      // }}
                    >
                      Submit
                    </button>
                  )}
                </Col> */}
          </Row>
          {/* </form> */}
        </div>
        <br></br>
        <hr></hr>
        {renderTable()}
      </div>
    </LoadingOverlay>
  );
};

export default TelecallerUpload;
