import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import LoadImage from "../CommonComponents/LoadImage";
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import { apiCall } from "../rest/restApi";
// import { toaster } from "../rest/apiUtils";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Select from "react-select";
import SearchBar from "../CommonComponents/SearchBar";
import ReactGA from "react-ga";

const NonAllocatedUsers = () => {
  const [isLoading3, setIsLoading3] = useState(false);
  const [totalElements3, setTotalElements3] = useState(1);
  const [currPage3, setCurrPage3] = useState(0);
  const [executivesList3, setExecutivesList3] = useState([]);
  const [searchKey2, setSearchKey2] = useState("");
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [selectedValue, setSelectedValue] = useState("EXECUTIVE");
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview("Non Allocated Users");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // loadData(0, searchKey);
    NonAllocatedLoadData(0, searchKey2);
    // loadExecutives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NonAllocatedLoadData = (pageNo, key) => {
    let listData = [];
    setIsLoading3(true);
    let nonAllocatedUsersBody;
    nonAllocatedUsersBody = {
      endDate: end,
      roleName: selectedValue,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportOfficerId: !isAdmin()
        ? userLoginDetails.autoFinanceUser.userId
        : "",
      workAllocated: "",
      searchKey: key,
      startDate: start,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getAllocatedExecutives, pageNo, 10),
        nonAllocatedUsersBody
      )
      .then((response) => {
        let userIds = [];
        response?.content?.forEach((element) => {
          userIds.push(element.autoFinanceUser.userId);
        });
        let payload = {
          organizationId: userLoginDetails.autoFinanceUser.organizationId,
          userIds: userIds,
        };
        let m = new Map();
        moduleApi
          .postData(apiCall.getLiveLocations, payload)
          .then((response2) => {
            response2?.forEach((item) => {
              m.set(item?.userId, item);
            });

            response?.content?.forEach((element) => {
              let data = {
                branchId: element.autoFinanceBranchDetails.branchId,
                branchLocation: element.autoFinanceBranchDetails.branchLocation,
                branchName: element.autoFinanceBranchDetails.branchName,
                branchemail: element.autoFinanceBranchDetails.branchemail,
                organizationId: element.autoFinanceBranchDetails.organizationId,
                phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
                addedByUserId: element.autoFinanceUser.addedByUserId,
                reportingOfficerIds:
                  element.autoFinanceUser.reportingOfficerIds,
                email: element.autoFinanceUser.email,
                employeeId: element.autoFinanceUser.employeeId,
                image: element.autoFinanceUser.image,
                mobileNumber: element.autoFinanceUser.mobileNumber,
                name: element.autoFinanceUser.name,
                passwordUpdated: element.autoFinanceUser.passwordUpdated,
                reportingOfficerId: element.autoFinanceUser.reportingOfficerId,
                roleName: element.autoFinanceUser.roleName,
                status: element.autoFinanceUser.status,
                userId: element.autoFinanceUser.userId,
                designation: element.autoFinanceUser.companyDesignation,

                batteryPercentage: m.has(element.autoFinanceUser.userId)
                  ? m.get(element.autoFinanceUser.userId).batteryPercentage
                  : 0,
                gpsData: m.has(element.autoFinanceUser.userId)
                  ? m.get(element.autoFinanceUser.userId).gpsData
                  : null,
                lastUpdatedAt: m.has(element.autoFinanceUser.userId)
                  ? m.get(element.autoFinanceUser.userId).lastUpdatedAt
                  : 0,
              };
              listData.push(data);
            });
            setExecutivesList3(listData);
            setTotalElements3(response.totalElements);
            setIsLoading3(false);

            // const result = response.content.map((item, index) => ({
            //   value: item.autoFinanceUser.name,
            //   label: item.autoFinanceUser.name,
            //   userId: item.autoFinanceUser.userId,
            //   reportingOfficerId: item.autoFinanceUser.reportingOfficerId,
            //   index: index,
            // }));

            // setExecutivesList2(result);
          });
      });
  };

  const NonAllocatedColumns = [
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <LoadImage source={row.image} />{" "}
            <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
          </div>
        );
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Email",
      dataField: "email",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",

      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",

      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "ACTIVE") {
          return (
            <span className="badge badge-rounded badge-success">Active</span>
          );
        } else {
          return (
            <span className="badge badge-rounded badge-danger">Inactive</span>
          );
        }
      },
    },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.status === "ACTIVE4") {
    //       return <></>;
    //     } else {
    //       return (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-info shadow btn-xs sharp">
    //             <i className="fa fa-eye"></i>
    //           </Link>
    //         </div>
    //       );
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       setSelectedExeName(row);
    //       getCustomersList(0, row.userId, "");
    //       setSelectedUserId(row.userId);
    //       setBasicModal2(true);
    //     },
    //   },
    // },
  ];

  const onPageChange3 = (selectedPage) => {
    setCurrPage3(selectedPage - 1);
    NonAllocatedLoadData(selectedPage - 1, searchKey2);
  };

  const searchKeyword2 = (key) => {
    setSearchKey2(key);
    NonAllocatedLoadData(0, key);
  };

  const userOptions = [
    { label: "Executives", value: "EXECUTIVE" },
    { label: "Managers", value: "MANAGER" },
  ];

  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0].getFullYear(),
            value[0].getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86399);

    setStart(start);
    setEnd(end);
  };

  return (
    <div>
      <div>
        <LoadingOverlay
          active={isLoading3}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div
            style={{
              float: "right",
              //margin: "10px",
              marginRight: "5px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
          >
            <Button
              // style={{width: '8em',
              // height: '4em',}}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => NonAllocatedLoadData(0, searchKey2)}
            >
              Submit
            </Button>
          </div>

          <div
            style={{
              float: "right",
              width: "120px",
              marginRight: "20px",
              // marginBottom: "20px",
              marginLeft: "10px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => setSelectedValue(value.value)}
              defaultValue={userOptions[0]}
              options={userOptions}
            />
          </div>

          {/* <div
            style={{
              float: "right",
              // width: "120px",
              marginRight: "5px",
              // marginBottom: "20px",
              marginLeft: "15px",
            }}
          >
            <Flatpickr
              className="form-control d-block"
              style={{
                width: "200px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
                // paddingLeft: "10px",
                // marginBottom: "10px",
                // float: "right",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value);
              }}
              options={{
                // mode: "range",
                // dateFormat: "d-M-y",
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          </div> */}

          {!isDateFilter && (
            <div
              style={{
                float: "right",
                // width: "120px",
                marginRight: "5px",
                // marginBottom: "20px",
                // marginLeft: "15px",
              }}
            >
              <Flatpickr
                id="customonthPicker2"
                className="form-control d-block"
                style={{
                  width: "150px",
                  height: "40px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  textAlignLast: "center",
                }}
                placeholder="Select Month"
                onChange={(value) => {
                  setCustomDatesForGraph(value, true);
                }}
                options={{
                  defaultDate: new Date(),
                  maxDate: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    0
                  ),
                  minDate: new Date().setMonth(new Date().getMonth() - 6),

                  plugins: [
                    new monthSelectPlugin({
                      shorthand: true, //defaults to false
                      dateFormat: "F Y", //defaults to "F Y"
                      altFormat: "F Y", //defaults to "F Y"
                      theme: "light", // defaults to "light"
                    }),
                  ],
                }}
              />
            </div>
          )}
          {isDateFilter && (
            <div
              style={{
                float: "right",
                // width: "120px",
                marginRight: "5px",
                // marginBottom: "20px",
                // marginLeft: "15px",
              }}
            >
              <Flatpickr
                style={{
                  width: "205px",
                  height: "40px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  textAlignLast: "center",
                }}
                id="customDatePicker2"
                className="form-control d-block"
                placeholder="Start & End Date"
                onChange={(value) => {
                  setCustomDatesForGraph(value, false);
                }}
                options={{
                  mode: "range",
                  dateFormat: "d-M-y",
                  maxDate: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    0
                  ),
                }}
              />
            </div>
          )}

          <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "10px",
              height: "38px",
              marginLeft: "10px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                value.value === "cm"
                  ? setIsDateFilter(false)
                  : setIsDateFilter(true);
              }}
              defaultValue={dateOptions[0]}
              options={dateOptions}
            />
          </div>

          {/* <input
            style={{
              width: "300px",
              height: "40px",
              borderRadius: "25px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              paddingLeft: "10px",
              marginBottom: "10px",
              float: "right",
            }}
            placeholder="Search By Name/Mobile"
            onChange={(e) => searchKeyword2(e.target.value.trim())}
          /> */}
          <div style={{ float: "right", marginBottom: "7px" }}>
            <SearchBar
              placeholder="Search By Name/Mobile/Email/Emp Id"
              searchKeyword={searchKeyword2}
            />
          </div>
          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "-5px",
            }}
          >
            <div
              style={{ overflow: "auto", maxHeight: "400px", width: "100%" }}
            >
              <BootstrapTable
                keyField="id"
                data={executivesList3}
                columns={NonAllocatedColumns}
                // selectRow={ selectRow }
              />
            </div>
          </div>
          {executivesList3.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements3 === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements3}
              pageSize={10}
              defaultActivePage={currPage3 + 1}
              onSelect={onPageChange3}
            />
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default NonAllocatedUsers;
