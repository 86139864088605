import React, { useEffect, useState } from "react";
import { Modal, Button, Card, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import * as d3 from "d3";
import swal from "sweetalert";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import LoadingOverlay from "react-loading-overlay";
import * as nodeApi from "../rest/nodeApi";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { getTokenInLocalStorage } from "../services/AuthService";
import AddGraphForm from "./AddGraphForm";
import { structureLableNames } from "./utils";
import ReactGA from "react-ga";
import { EpochToOnlyDate } from "../CommonComponents/Helper";
import RequestDataSource from "./RequestDataSource";
// import BranchWiseCount from "./BranchWiseCount"
// import BranchWiseRevenue from "./BranchWiseRevenue"

const DB = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [feedBackModal, setFeedBackModal] = useState(false);
  const [fullViewModal, setFullViewModal] = useState(false);

  const [graphs, setGraphs] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [fullViewlist, setFullViewList] = useState(null);
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [endDate, setEndDate] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );

  const ApexBar3 = loadable(() => pMinDelay(import("./Bar3"), 500));

  const userData = getTokenInLocalStorage();

  //   const tabData = [
  //     {
  //         name: "Collections Count",
  //         icon: "count",
  //     },
  //     {
  //         name: "Collections Revenue",
  //         icon: "revenue",
  //     },

  // ];

  const deleteGraphMetaData = (id, graphId) => {
    // console.log("id ", id, " graphId ", graphId);
    var body = {
      id: id,
      organizationId: userData.autoFinanceOrganization.organizationId,
      userId: userData.autoFinanceUser.userId,
    };
    moduleApi.postData(apiCall.deleteGraphMetaData, body).then((response) => {
      // console.log("deleteresponse", response);
      if (response) {
        if (response.message) {
          swal(response.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          setGraphs(graphs.filter((item) => item.graphId !== graphId));
          swal("Graph Deleted Successfully!", {
            icon: "success",
          }).then((yes) => {
            if (yes) {
              getGraphsMetaData();
            }
          });
        }
      } else {
        swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const setGraphDates = (value, graph) => {
    var date;
    var start, end;

    switch (value) {
      case "1":
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 1)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        console.log("start", start);
        console.log("end", end);
        setStartDate(start);
        setEndDate(end);
        getUrlData(graph, start, end, false);
        return;

      case "2":
        //last 7 days
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        console.log("start", start);
        console.log("end", end);
        setStartDate(start);
        setEndDate(end);
        getUrlData(graph, start, end, false);
        return;
      case "3":
        //Last month Logic
        // eslint-disable-next-line no-redeclare
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        console.log("start", start);
        console.log("end", end);
        setStartDate(start);
        setEndDate(end);
        getUrlData(graph, start, end, false);
        break;
      case "4":
        //last 90 days
        // eslint-disable-next-line no-redeclare
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 89)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        console.log("start", start);
        console.log("end", end);
        setStartDate(start);
        setEndDate(end);
        getUrlData(graph, start, end, false);
        break;
      default:
      // console.log("end");
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGraphsMetaData();
    // getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const changeHandler = (event) => {
  //   // Passing file data to parse using Papa.parse
  //   Papa.parse(event.target.files[0], {
  //     header: false,
  //     skipEmptyLines: true,
  //     complete: function (results) {
  //       console.log("results", results.data);

  //       // let csvArray = results;
  //       const headers = results.data[0];
  //       console.log("headers", headers);

  //       // Remove headers from list...
  //       results.data.splice(0, 1);
  //       const refinedList = [];
  //       // Now get values for each colums aka headers
  //       headers.forEach((header, index) => {
  //         // Item list for each column aka header...
  //         const values = [];
  //         // Get items from the rows at index of header..
  //         results.data.forEach((row) => {
  //           values.push(row[index].trim());
  //         });
  //         // Now push to refined list of colums...
  //         refinedList.push({
  //           name: header.trim(),
  //           values: values,
  //         });
  //       });
  //       console.log("refined", refinedList);
  //       setList(refinedList);

  //       // setUpdatedKey(Math.floor(Math.random() * 100000));
  //     },
  //   });
  // };

  async function getUrlData(graph, start, end, isDataFromServer) {
    // console.log("start", start, "end", end);
    setIsLoading(true);
    let temp = [];
    var body = {
      fileName: graph.selectedFile,
    };

    nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
      // console.log("presignedurlresponse", response);

      Papa.parse(response.url, {
        download: true,
        complete: function (results) {
          console.log("csvdata", results.data);
          let j;
          if (
            results.data[0].includes("date") ||
            results.data[0].includes("due_date") ||
            // results.data[0].includes("date_time") ||
            results.data[0].includes("visit_date")
          ) {
            for (let i = 0; i < results.data[0].length; i++) {
              if (
                results.data[0][i] === "date" ||
                results.data[0][i] === "due_date" ||
                // results.data[0][i] === "date_time" ||
                results.data[0][i] === "visit_date"
              ) {
                j = i;
              }
            }
          }
          results.data.forEach((data, index) => {
            if (
              new Date(data[j]) / 1000 >= start &&
              new Date(data[j]) / 1000 <= end
            ) {
              temp.push(data);
            }
          });
          var csvHearders = [];
          results.data[0].forEach((header) => {
            csvHearders.push(camelize(header));
          });
          temp.unshift(csvHearders);
          console.log("temp1111", temp);
          let csv = Papa.unparse(temp);
          console.log("unparse", csv);
          let csv2 = Papa.parse(csv, {
            header: true,
          });
          console.log("csv2", csv2);
          var gfg1 = getGroupedData(
            csv2.data,
            graph.yaxisValue,
            graph.xaxisValue,
            isDataFromServer
          );
          console.log("gfg1u ", gfg1);
          // console.log('sorting',gfg1.sort((a, b) => a[0].toLowerCase() !== b[0].toLowerCase() ? a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1 : 0))
          gfg1?.sort((a, b) =>
            a[0].toLowerCase() !== b[0].toLowerCase()
              ? a[0].toLowerCase() < b[0].toLowerCase()
                ? -1
                : 1
              : 0
          );
          var branchList = [];
          var dataList = [];

          gfg1?.forEach((data) => {
            branchList.push(data[0]);
            // dataList.push(data[1]);
          });

          gfg1?.forEach((data) => {
            data.splice(0, 1);
            // for(let i=0;i<data.length;i++){

            // }
          });
          console.log("gggg", gfg1);
          if (gfg1) {
            for (let i = 0; i < gfg1[0]?.length; i++) {
              dataList.push([]);
            }
          }
          for (let i = 0; i < gfg1?.length; i++) {
            for (let j = 0; j < gfg1[i]?.length; j++) {
              dataList[j]?.push(gfg1[i][j]);
            }
          }
          var visitMonthList = [];
          if (
            (isDataFromServer ? graph.xaxisValue : graph.xaxisValue.name) ===
            "Visit Mo"
          ) {
            branchList.forEach((month) => {
              visitMonthList.push(toMonthName(month));
            });
          }
          console.log("branchList", branchList);
          console.log("dataList", dataList);

          const headers = results.data[0];
          // console.log("headers", headers);
          // Remove headers from list...
          results.data.splice(0, 1);
          const refinedList = [];
          // Now get values for each colums aka headers
          headers.forEach((header, index) => {
            // Item list for each column aka header...
            const values = [];
            // Get items from the rows at index of header..
            temp.forEach((row) => {
              values.push(row[index].trim());
            });
            // Now push to refined list of colums...
            refinedList.push({
              name: header.trim(),
              values: values,
            });
          });
          // console.log("refinedList", refinedList);

          let tempArr = [...graphs];
          if (isDataFromServer === false) {
            refinedList.forEach((item) => {
              if (graph.xaxisValue.name === camelize(item.name)) {
                let objIndex = tempArr.map((i) => i.id).indexOf(graph.id);
                tempArr[objIndex].xaxisValue.name = camelize(item.name);
                tempArr[objIndex].xaxisValue.data =
                  (isDataFromServer
                    ? graph.xaxisValue
                    : graph.xaxisValue.name) === "Visit Mo"
                    ? visitMonthList
                    : branchList;
              }
            });
            for (let i = 0; i < graph.yaxisValue.length; i++) {
              refinedList.forEach((item) => {
                if (
                  graph.yaxisValue[i].name ===
                    structureLableNames(camelize(item.name)) ||
                  graph.yaxisValue[i].name === camelize(item.name)
                ) {
                  let objIndex = tempArr.map((i) => i.id).indexOf(graph.id);
                  tempArr[objIndex].yaxisValue[i].name = camelize(item.name);
                  tempArr[objIndex].yaxisValue[i].data =
                    dataList.length > 0 ? dataList[i] : [];
                }
              });
            }
          } else {
            var tempObj = {
              chartType: graph.chartType,
              graphName: graph.graphName,
              id: graph.id,
              graphId: graph.graphId,
              // organizationId: userData.autoFinanceOrganization.organizationId,
              // userId: userData.autoFinanceOrganization.userId,
              selectedFile: graph.selectedFile,
              xaxisValue: {},
              yaxisValue: [],
            };
            refinedList.forEach((item) => {
              if (camelize(graph.xaxisValue) === camelize(item.name)) {
                tempObj.xaxisValue = {
                  name: graph.xaxisValue,
                  data:
                    (isDataFromServer
                      ? graph.xaxisValue
                      : graph.xaxisValue.name) === "Visit Mo"
                      ? visitMonthList
                      : branchList,
                };
              }
            });
            for (let i = 0; i < graph.yaxisValue.length; i++) {
              refinedList.forEach((item) => {
                if (
                  graph.yaxisValue[i] ===
                    structureLableNames(camelize(item.name)) ||
                  graph.yaxisValue[i] === camelize(item.name)
                ) {
                  tempObj.yaxisValue.push({
                    name: graph.yaxisValue[i],
                    data: dataList.length > 0 ? dataList[i] : [],
                  });
                }
              });
            }

            tempArr.push(tempObj);
            let tempLocal = JSON.parse(localStorage.getItem("analyticsData"));
            tempLocal.push(tempObj);
            localStorage.setItem("analyticsData", JSON.stringify(tempLocal));
          }

          console.log("temparr", tempArr);

          isDataFromServer
            ? setGraphs(JSON.parse(localStorage.getItem("analyticsData")))
            : setGraphs(tempArr);
        },
      });
      setIsLoading(false);
    });
  }

  const getGroupedData = (csvData, param1, param2, isDataFromServer) => {
    if (param1.length === 1) {
      var gfg1 = d3.flatRollup(
        csvData,
        (v) =>
          d3.sum(
            v,
            (d) =>
              d[
                isDataFromServer
                  ? camelize(param1[0])
                  : camelize(param1[0].name)
              ]
          ),
        (d) => d[isDataFromServer ? camelize(param2) : camelize(param2.name)]
      );
      return gfg1;
    } else if (param1.length > 1) {
      var gfg2 = [];
      var refinedGroupedData = [];
      for (let i = 0; i < param1.length; i++) {
        gfg2.push(
          d3.flatRollup(
            csvData,
            (v) =>
              d3.sum(
                v,
                (d) =>
                  d[
                    isDataFromServer
                      ? camelize(param1[i])
                      : camelize(param1[i].name)
                  ]
              ),
            (d) =>
              d[isDataFromServer ? camelize(param2) : camelize(param2.name)]
          )
        );
      }
      gfg2[0].forEach((data) => {
        refinedGroupedData.push([data[0]]);
      });
      gfg2.forEach((data) => {
        for (let i = 0; i < data.length; i++) {
          refinedGroupedData[i].push(data[i][1]);
        }
      });
      // console.log("refinded", refinedGroupedData);
      return refinedGroupedData;
    }
  };

  const getGraphsMetaData = () => {
    localStorage.setItem("analyticsData", JSON.stringify([]));
    var date = [
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
      ),
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ),
    ];
    var body = {
      organizationId: userData.autoFinanceOrganization.organizationId,
      userId: userData.autoFinanceUser.userId,
    };

    // console.log("datess", Math.floor(date[0].getTime() / 1000), date[1]);
    moduleApi.postData(apiCall.getGraphsMetaData, body).then((response) => {
      // console.log("listresponse", response);
      setServerData(response);
      response.forEach((data) => {
        // console.log("count", response);
        getUrlData(
          data,
          Math.floor(date[0].getTime() / 1000),
          Math.floor(date[1].getTime() / 1000),
          true
        );
      });
    });
  };

  function camelize(str) {
    const a = str
      .toLowerCase()
      .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? " " + c.toUpperCase() : ""));
    return a.substring(0, 1).toUpperCase() + a.substring(1);
  }

  const toMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  const basicModalClose = () => {
    setBasicModal(false);
    // setIsPreview(false);
    // setPreviewGraphdata(null);
  };

  const renderTooltip = (data) => <Tooltip>{data}</Tooltip>;

  const downloadCsvFiledata = (filePath) => {
    var body = {
      fileName: filePath,
    };
    let temp = [];

    nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
      Papa.parse(response.url, {
        download: true,
        complete: function (results) {
          console.log("csvdata", results.data);
          let j;
          if (
            results.data[0].includes("date") ||
            results.data[0].includes("due_date") ||
            // results.data[0].includes("date_time") ||
            results.data[0].includes("visit_date")
          ) {
            for (let i = 0; i < results.data[0].length; i++) {
              if (
                results.data[0][i] === "date" ||
                results.data[0][i] === "due_date" ||
                // results.data[0][i] === "date_time" ||
                results.data[0][i] === "visit_date"
              ) {
                j = i;
              }
            }
          }
          results.data.forEach((data, index) => {
            if (
              new Date(data[j]) / 1000 >= startDate &&
              new Date(data[j]) / 1000 <= endDate
            ) {
              temp.push(data);
            }
          });
          var csvHearders = [];
          results.data[0].forEach((header) => {
            csvHearders.push(camelize(header));
          });
          temp.unshift(csvHearders);
          console.log("temp1111", temp);
          let csv = Papa.unparse(temp);
          console.log("unparse", csv);
          setCsvData(csv);
        },
      });

      // var aLink = document.createElement("a");
      // var evt = document.createEvent("HTMLEvents");
      // evt.initEvent("click");
      // aLink.download = fileName;
      // aLink.href = response?.url;
      // aLink.dispatchEvent(evt);
      // aLink.click();
    });
  };

  // const getTableData = () => {
  //   var body = {
  //     fileName: "analytics/CLL/highriskcount.csv",
  //   };

  //   nodeApi.postData(apiCall.getPreSignedUrl, body).then((response) => {
  //     Papa.parse(response.url, {
  //       // header:  true,
  //       download: true,
  //       complete: function (results) {
  //         let csv = Papa.unparse(results.data);
  //         console.log("csvtable", csv);
  //         setCsvData(csv);
  //       },
  //     });
  //   });
  // };

  return (
    <>
      <div>
        <AddGraphForm
          serverData={serverData}
          getGraphsMetaData={getGraphsMetaData}
          graphs={graphs}
        />
        {/* <Card>
            <Card.Header>
                <Card.Title>Detailed View</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Tab.Container defaultActiveKey={tabData[0].icon.toLowerCase()}>
                        <Col sm={2}>
                            <Nav as="ul" className="flex-column nav-pills mb-3">
                                {tabData.map((data, i) => (
                                    <Nav.Item as="li" key={i} style={{fontSize:'10px'}}>
                                        <Nav.Link eventKey={data.icon.toLowerCase()}>
                                            {data.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content className="ms-2">
                                <Tab.Pane eventKey={'count'} key={0} mountOnEnter>
                                    <BranchWiseCount />
                                </Tab.Pane>

                                <Tab.Pane eventKey={'revenue'} key={1} mountOnEnter>
                                    <BranchWiseRevenue />
                                </Tab.Pane>

                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </Row>
            </Card.Body>
        </Card> */}
        {/* <Card>
          <Card.Header style={{ fontSize: "medium", fontWeight: "bold" }}>
            Create Custom Dashboards
          </Card.Header>
          <Card.Body>
            <div>
              <Row>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Steps to Create Custom Graphs:{" "}
                  </span>
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    1. Select a Data Source File{" "}
                  </span>
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    2. Select a Chart Type{" "}
                  </span>
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    3. Select X- Axis and Y-Axis (Multi Select){" "}
                  </span>
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    4. Provide a Graph Name
                  </span>{" "}
                  <span style={{ fontSize: "smaller", marginLeft: "10px" }}>
                    5. Preview the Graph and Add the Graph
                  </span>
                </Col>
                <Col
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "40px",
                      alignSelf: "center",
                    }}
                  >
                    <Button
                      className="add-graph-btn"
                      variant="success btn-rounded"
                      onClick={() => {
                        setBasicModal(true);
                      }}
                      type="submit"
                    >
                      <i class="fa fa-plus"></i> <b>Add Graph</b>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card> */}
      </div>

      <div className="container">
        <Row>
          {graphs.length > 0 &&
            graphs.map((graph, i) => {
              return (
                <Col key={i} xs={6}>
                  <Card style={{ width: "33rem" }}>
                    <Card.Header>
                      <Card.Title style={{ display: "contents" }}>
                        <div
                          style={{
                            float: "left",
                            overflowWrap: "anywhere",
                            fontSize: "smaller",
                          }}
                        >
                          {graph.graphName}
                        </div>

                        <div
                          style={{
                            float: "right",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label
                              style={{
                                fontWeight: "700",
                              }}
                            >
                              Select Range
                            </label>
                            <select
                              name="cars"
                              id="cars"
                              defaultValue="2"
                              style={{
                                backgroundColor: "white",
                                height: "35px",
                                width: "130px",
                                color: "rgb(115 115 115)",
                                border: "solid",
                                textAlign: "center",
                                borderRadius: "5px",
                                borderWidth: "1px",
                                fontSize: "13px",
                                fontWeight: "300",
                                boxShadow: "1px 1px 2px #797979",
                              }}
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setGraphDates(e.target.value, graph);
                              }}
                            >
                              <option value="1" label="Last One Day" />
                              <option value="2" label="Last 7 Days" />
                              <option value="3" label="Last 30 Days" />
                              <option value="4" label="Last 90 Days" />
                            </select>
                          </div>
                          <div
                            style={{ marginLeft: "15px", marginTop: "20px" }}
                          >
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip("View Full Screen")}
                            >
                              <i
                                style={{
                                  marginRight: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setCsvData("");
                                  downloadCsvFiledata(graph.selectedFile);
                                  setFullViewList(graph);
                                  setFullViewModal(true);
                                }}
                                class="bi bi-fullscreen"
                              ></i>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip("Delete")}
                            >
                              <i
                                className="fas fa-trash"
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                }}
                                onClick={() => {
                                  swal({
                                    title: "Delete Graph",
                                    text: "Do you really want to delete graph?",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      deleteGraphMetaData(
                                        graph.id,
                                        graph.graphId
                                      );
                                    }
                                  });
                                }}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <LoadingOverlay
                        active={isLoading}
                        text={
                          <p
                            style={{
                              color: "black",
                            }}
                          >
                            <div>
                              <Button
                                style={{
                                  backgroundColor: "#FFFF",
                                  color: "black",
                                  borderColor: "white",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                }}
                                variant="primary"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="md"
                                  variant="primary"
                                  role="status"
                                  aria-hidden="true"
                                />
                                &nbsp;&nbsp;Loading...
                              </Button>
                            </div>
                          </p>
                        }
                        styles={{
                          overlay: (base) => ({
                            ...base,
                            background: "rgba(192,192,192,0.4)",
                          }),
                          spinner: (base) => ({
                            ...base,
                            width: "80px",
                            "& svg circle": {
                              stroke: "rgb(136, 108, 192)",
                            },
                          }),
                        }}
                      >
                        <ApexBar3
                          key={graph.id}
                          xAxis={graph.xaxisValue}
                          yAxis={graph.yaxisValue}
                          chartType={graph.chartType}
                          isFullData={false}
                          isDownload={false}
                          graphName={graph.graphName}
                          height={
                            graph.chartType !== "pie" &&
                            graph.chartType !== "pie"
                              ? "400"
                              : "400"
                          }
                          width={
                            graph.chartType !== "pie" &&
                            graph.chartType !== "pie"
                              ? "400"
                              : "440"
                          }
                        />
                      </LoadingOverlay>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </div>

      {/* <div
        style={{
          marginLeft: "40px",
          alignSelf: "center",
        }}
      >
        <Button
          style={{ marginRight: "10px" }}
          className="add-graph-btn"
          variant="primary btn-rounded"
          onClick={() => {
            setFeedBackModal(true);
          }}
          type="submit"
        >
          <i class="fa fa-comments"></i> <b>Request a Data Source</b>
        </Button>
      </div> */}
      <RequestDataSource />

      <Modal className="fade" show={basicModal} size="xl">
        <Modal.Header>
          <Modal.Title>Add Graph - Select Options</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              basicModalClose();
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "10px" }}>
          <AddGraphForm
            serverData={serverData}
            getGraphsMetaData={getGraphsMetaData}
            graphs={graphs}
            basicModalClose={basicModalClose}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={feedBackModal} size="md">
        <Modal.Header>
          <Modal.Title>Request a Data Source</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setFeedBackModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "10px" }}>
          <RequestDataSource close={setFeedBackModal} />
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={fullViewModal} size="xl">
        <Modal.Header>
          <Modal.Title>
            <Row>
              <div>
                <Col>{fullViewlist?.graphName}</Col>
                <Col>
                  <div style={{ fontSize: "12px" }}>
                    {" "}
                    {EpochToOnlyDate(startDate) +
                      " - " +
                      EpochToOnlyDate(endDate)}
                  </div>
                </Col>
              </div>
            </Row>
          </Modal.Title>
          <div style={{ float: "right" }}>
            <CSVLink
              data={csvData}
              className="btn btn-primary  add-group-btn"
              filename={fullViewlist?.graphName}
            >
              <i className="fa fa-download"></i>
              &nbsp;&nbsp;Download Full Data
            </CSVLink>
            {/* <Button
              style={{ marginRight: "15px" }}
              onClick={() => {
                downloadCsvFiledata(fullViewlist.selectedFile, "Test.csv");
              }}
            >
              <i className="fa fa-download"></i>
              &nbsp;&nbsp;Download Full Data
            </Button> */}
            <Button
              style={{ marginRight: "15px" }}
              variant=""
              className="btn-close"
              onClick={() => {
                setFullViewModal(false);
                setFullViewList(null);
              }}
            ></Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "10px", alignSelf: "center" }}>
          <ApexBar3
            key={fullViewlist?.id}
            xAxis={fullViewlist?.xaxisValue}
            yAxis={fullViewlist?.yaxisValue}
            chartType={fullViewlist?.chartType}
            graphName={fullViewlist?.graphName}
            isFullData={true}
            isDownload={true}
            height={
              fullViewlist?.chartType !== "pie" &&
              fullViewlist?.chartType !== "donut"
                ? "500"
                : "800"
            }
            width={
              fullViewlist?.chartType !== "pie" &&
              fullViewlist?.chartType !== "donut"
                ? "1000"
                : "650"
            }
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DB;
