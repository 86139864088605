import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
// import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
// import { Link } from "react-router-dom";
//import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import NO_DATA_FOUND from "../images/no-data-found.png";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from 'react-ga'

const EmployeePerformanceMetrics = ({ userId }) => {
  const [empPerformanceList, setEmpPerformanceList] = useState([]);
  //const [showDate, setShowDate] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage] = useState(0);
  // const [start, setStart] = useState(
  //   Math.floor(
  //     new Date(
  //       new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0)
  //     ).getTime() / 1000
  //   )
  // );
  // const [end, setEnd] = useState(
  //   Math.floor(
  //     new Date(
  //       new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
  //     ).getTime() / 1000
  //   )
  // );
  const [isLoading, setIsLoading] = useState(true);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );

  // var valIndex = 0;
  //   let start = 0,
  //     end = 0;
  // var today = new Date();
  // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
  // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
  useEffect(() => {
    ReactGA.pageview('Employee Performance');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    //setDateValue("Last 7 Days", 1);
    loadAttendanceData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAttendanceData = (pageNo) => {
    setIsLoading(true);

    const payLoad = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      userId:userId,
    
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeePerformanceMetrics, pageNo, 10),
        payLoad
      )
      .then((response) => {
        var listData = [];
        response.forEach((element) => {
          let data = {
            empId: element.empId ? element.empId : "-",
            empName: element.empName ? element.empName : "-",
            userId:element.userId ? element.userId : "-",
            monthYear:element.monthYear ?  element.monthYear : "-",
            contactRate:element.contactRate ?  element.contactRate : 0,
            collectionRate:element.collectionRate ?  element.collectionRate : 0,
            collectionDay:element.collectionDay ?  element.collectionDay : "-",
            avgCollectionTimePct:element.avgCollectionTimePct ?  element.avgCollectionTimePct : 0,           
          };

          listData.push(data);
        });
        setEmpPerformanceList(listData);
        setIsLoading(false);
        setTotalElements(response.totalElements);
      });
  };

  const columns = [
    {
      text: "Year & Month",
      dataField: "monthYear",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Frequencey",
      dataField: "contactRate",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Recovery Rate",
      dataField: "collectionRate",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Collection Day",
      dataField: "collectionDay",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Avg Collection Time",
      dataField: "avgCollectionTimePct",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign:'middle',
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  // const optionDates = [
  //   { label: "Last 7 Days", value: 1 },
  //   { label: "Today", value: 2 },
  //   { label: "Last 30 Days", value: 3 },
  //   { label: "Custom", value: 4 },
  // ];

  // const setDateValue = (label, value) => {
  //   var date;

  //   switch (value) {
  //     case 1:
  //       date = [
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
  //             0,
  //             0,
  //             0
  //           )
  //         ),
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate())).setHours(
  //             23,
  //             59,
  //             59
  //           )
  //         ),
  //       ];

  //       setStart(Math.floor(date[0].getTime() / 1000));
  //       setEnd(Math.floor(date[1].getTime() / 1000));
  //       setShowDate(false);
  //       return;
  //     case 2:
  //       var today = new Date();
  //       setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
  //       setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));
  //       setShowDate(false);
  //       return;
  //     case 3:
  //       date = [
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
  //             0,
  //             0,
  //             0
  //           )
  //         ),
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate())).setHours(
  //             23,
  //             59,
  //             59
  //           )
  //         ),
  //       ];

  //       setStart(Math.floor(date[0].getTime() / 1000));
  //       setEnd(Math.floor(date[1].getTime() / 1000));

  //       setShowDate(false);
  //       return;
  //     case 4:
  //       setShowDate(true);
  //       break;
  //     default:
  //       console.log("default 4 ", showDate);
  //   }
  // };

  // function setCustomDates(dateStrings) {
  //   setStart(Math.floor(new Date(dateStrings[0]).getTime() / 1000));
  //   setEnd(Math.floor(new Date(dateStrings[1]).getTime() / 1000) + 86399);
  // }

  const onPageChange = (selectedPage) => {
    loadAttendanceData(selectedPage - 1);
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        {/* {showDate && (
          <div style={{ marginRight: "20px", float: "right" }}>
            <Flatpickr
              className="form-control d-block"
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
                maxDate: new Date(),
              }}
            />
          </div>
        )} */}
        {/* <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "20px",
            // marginBottom: "20px",
          }}
        >
          <Select
            isSearchable={false}
            onChange={(value) =>
              setDateValue(value.label, value.value, optionDates)
            }
            defaultValue={optionDates[valIndex]}
            options={optionDates}
          />
        </div> */}
        {/* <div
          style={{
            marginRight: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadAttendanceData(0)}
          >
            Submit
          </Button>
        </div> */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ background: "white", margin: "10px", overflowX: "auto" }}>
          <div style={{ overflow: "auto", maxHeight: "400px" }}>
            <BootstrapTable
              keyField="panNo"
              data={empPerformanceList}
              columns={columns}
            />
          </div>
          {empPerformanceList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <div>
      </div>
    </div>
  );
};

export default EmployeePerformanceMetrics;
