import "./SalesKanbanStyle.css";
import React, { useEffect, useState } from "react";
// import { useEffect } from "react";
import { extend } from "@syncfusion/ej2-base";
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban";
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import {
  Button,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Link } from "react-router-dom";
import AddActivity from "./AddActivity";
import LoadingOverlay from "react-loading-overlay";
import { EpochToOnlyDate } from "../CommonComponents/Helper";
import SearchBar from "../CommonComponents/SearchBar";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import Select from "react-select";
import OpportunityDetails from "./OpportunityDetails";
import { camelize } from "../DB/utils";
import ReactGA from "react-ga";
import swal from "sweetalert";

// import * as dataSource from './datasource.json';

/**
 * Kanban Overview sample
 */
const SalesCRM = () => {
  let userLoginDetails = getTokenInLocalStorage();
  const [opportunityDetails, setOpportunityDetails] = useState(null);
  const [addActivityModal, setAddActivityModal] = useState(false);
  const [leadDetailsModal, setLeadDetailsModal] = useState(false);
  const [opportunityDropModal, setOpportunityDropModal] = useState(false);
  const [assigneeModal, setAssigneeModal] = useState(false);
  const [opportunityStages, setOpportunityStages] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [selectedLeadRate, setSelectedLeadRate] = useState(0);
  const [teamNames, setTeamNames] = useState([]);
  const [assignees, setAssingees] = useState([]);
  const [enteredProbability, setEnteredprobability] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [selectedAssignee2, setSelectedAssignee2] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [enteredRemarks, setEnteredRemarks] = useState(null);

  useEffect(() => {
    getOpportunityStages();
    loadData(0, searchKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview("Sales CRM");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (pageNo, key, leadRate) => {
    setIsLoading(true);
    let listData = [];
    var payload = {
      leadRate: leadRate,
      searchKey: key,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      userId: isAdmin() ? "" : userLoginDetails?.autoFinanceUser?.userId,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getSalesOpportunities, pageNo, 10),
        payload
      )
      .then((response) => {
        setCurrPage(pageNo);
        // console.log(res]ponse);
        response?.content?.forEach((element) => {
          let data = {
            amount: element.amount ? element.amount : 0,
            createdTime: element.createdTime,
            empId: element.empId ? element.empId : "-",
            empName: element.empName ? element.empName : "-",
            customerName: element.leads?.customerName ? element.leads?.customerName : "-",
            closingDate: element.closingDate
              ? EpochToOnlyDate(element.closingDate)
              : "-",
            updatedTime: element.updatedTime
              ? EpochToOnlyDate(element.updatedTime)
              : "-",
            id: element.id ? element.id : "-",
            createdByUserId: element.createdByUserId
              ? element.createdByUserId
              : "-",
            leadId: element.leadId ? element.leadId : "-",
            leads: element.leads,
            opportunityStage: element.opportunityStage
              ? element.opportunityStage
              : "-",
            opportunityStageId: element.opportunityStageId
              ? element.opportunityStageId
              : "-",
            opportunityType: element.opportunityType
              ? camelize(element.opportunityType)
              : "-",
            opportunityTypeId: element.opportunityTypeId
              ? element.opportunityTypeId
              : "-",
            teamName: element.teamName ? element.teamName : "-",
            teamId: element.teamId ? element.teamId : "-",
            updatedByUserId: element.updatedByUserId
              ? element.updatedByUserId
              : "-",
            probability: element.probability ? element.probability : 0,
            userId: element.userId ? element.userId : "-",
            channel: element.leads ? element.leads.channel : "-",
            companyName: element.leads ? element.leads.companyName : "-",
            email: element.leads ? element.leads.email : "-",
            firstName: element.leads ? element.leads.firstName : "-",
            // leadId: element.amount?element.amount:'-',
            lastName: element.leads ? element.leads.lastName : "-",
            leadChannelId: element.leads ? element.leads.leadChannelId : "-",
            leadType: element.leads ? getPriority(element.leads.leadRate) : "-",
            leadStatus: element.leads ? element.leads.leadStatus : "-",
            leadStatusId: element.leads ? element.leads.leadStatusId : "-",
            organizationId: element.leads ? element.leads.organizationId : "-",
            phoneNumber: element.leads ? element.leads.phoneNumber : "-",
          };
          listData.push(data);
        });
        // console.log(listData);
        setOpportunities(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    loadData(selectedPage - 1, searchKey, selectedLeadRate);
  };

  const getOpportunityStages = () => {
    moduleApi
      .getData(
        apiCall.getOpportunityStages +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        //     let columns = [];
        // response?.map((item) => {
        //   var temp = {
        //     headerText: { item },
        //     keyField: { item },
        //     allowToggle: true,
        //     template: columnTemplate(item),
        //   };
        //   columns.push(temp)
        // });
        // const result = response?.map((item) => ({
        //   headerText: item,
        //   keyField: item,
        //   allowToggle: true,
        //   template: columnTemplate(item),
        // }));
        // setOpportunityStages(result)
        setOpportunityStages(response);
      });
  };

  let data = extend([], opportunities, null, true);

  // const fields = [
  //   { text: "ID", key: "Title", type: "TextBox" },
  //   { key: "Status", type: "DropDown" },
  //   { key: "Assignee", type: "DropDown" },
  //   { key: "RankId", type: "TextBox" },
  //   { key: "Summary", type: "TextArea" },
  // ];
  // const cardRendered = (args) => {
  //   let val = args.data.leadType;
  //   addClass([args.element], val);
  // };

  const leadRateOptions = [
    { label: "All", value: 0 },
    { label: "Hot", value: 1 },
    { label: "Warm", value: 2 },
    { label: "Cold", value: 3 },
  ];

  const getPriority = (value) => {
    if (value === 1) {
      return "Hot";
    } else if (value === 2) {
      return "Warm";
    } else {
      return "Cold";
    }
  };
  const columnTemplate = (props) => {
    return (
      <div className="header-template-wrap">
        <div className={"header-icon e-icons " + props.keyField}></div>
        <div className="header-text">{props.headerText}</div>
      </div>
    );
  };

  const getLeadTypeClassName = (leadType) => {
    if (leadType === "Hot") {
      return "badge badge-rounded badge-danger";
    } else if (leadType === "Warm") {
      return "badge badge-rounded badge-warning";
    } else if (leadType === "Cold") {
      return "badge badge-rounded badge-success";
    } else {
      return "badge badge-rounded badge-primary";
    }
  };

  const cardTemplate = (props) => {
    return (
      <div className={"card-template"}>
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-header-title e-tooltip-text">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "5px",
                  }}
                >
                  <span>{props?.customerName}</span>
                  <span>{props?.phoneNumber}</span>
                </div>
                <div style={{ alignSelf: "center", marginRight: "5px" }}>
                  <span
                    className={getLeadTypeClassName(props?.leadType)}
                    style={{ verticalAlign: "middle" }}
                  >
                    {props?.leadType}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="e-card-content e-tooltip-text">
          <div className="e-text">
            {" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Product Type : {props?.opportunityType}</span>
              <span>Probability : {props?.probability}</span>
            </div>
          </div>
        </div>
        <div className="e-card-custom-footer">
          {/* {props?.Tags.split(",").map((tag) => ( */}
          <div className="e-card-tag-field e-tooltip-text">
            {props?.updatedTime}
          </div>
          {/* ))} */}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpportunityDetails(props);
              setSelectedAssignee(props?.userId);
              setSelectedAssignee2({
                label: props?.empName,
                value: props?.userId,
              });
              setSelectedTeam(props?.teamName);
              getTeamNames();
              getUsers(props?.teamName);
              setAssigneeModal(true);
            }}
          >
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>{props?.empName}</Tooltip>}
            >
              <div className="e-card-avatar">{getString(props?.empName)}</div>
            </OverlayTrigger>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Link
              style={{
                height: "30px",
                width: "30px",
                padding: "7px",
                marginRight: "5px",
              }}
              to="#"
              className="btn btn-info shadow btn-xs sharp"
              onClick={() => {
                setOpportunityDetails(props);
                setLeadDetailsModal(true);
              }}
            >
              <i
                className="fa fa-eye"
                style={{ fontSize: "small", verticalAlign: "text-top" }}
              ></i>
            </Link>
            <ButtonComponent
              cssClass="e-flat e-round e-outline"
              isPrimary
              onClick={() => {
                setOpportunityDetails(props);
                setAddActivityModal(true);
              }}
            >
              <i class="fa fa-plus"></i>
            </ButtonComponent>
          </div>
        </div>
      </div>
    );
  };

  // const cardDetailsTemplate = (props) => {
  //   return (
  //     <div style={{ display: "flex", flexDirection: "column" }}>
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             marginLeft: "5px",
  //           }}
  //         >
  //           <span>{props.firstName + props.lastName}</span>
  //           <span>{props.phoneNumber}</span>
  //         </div>
  //         <div style={{ alignSelf: "center", marginRight: "5px" }}>
  //           <span
  //             className="badge badge-rounded badge-success"
  //             style={{ verticalAlign: "middle" }}
  //           >
  //             {props.leadType}
  //           </span>
  //         </div>
  //       </div>
  //       <div style={{ display: "flex", flexDirection: "row" }}>
  //         <span>Opportunity Type : {props.opportunityType}</span>
  //         <span>Probability : {props.probability}</span>
  //       </div>
  //       <div
  //         className="e-card-custom-footer"
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         <div className="e-card-tag-field e-tooltip-text">
  //           {props.updatedAt}
  //         </div>
  //         {/* <div style={{ display: "flex", flexDirection: "row" , justifyContent:'space-between'}}> */}

  //         <div className="e-card-avatar">
  //           <Button>view</Button>
  //         </div>
  //         <div className="e-card-avatar">
  //           {getString(props.firstName + props.lastName)}
  //         </div>
  //       </div>
  //       {/* </div > */}
  //       <div>
  //         <Button>Add Activity</Button>
  //       </div>
  //     </div>
  //   );
  // };
  const getString = (assignee) => {
    return assignee
      .match(/\b(\w)/g)
      .join("")
      .toUpperCase();
  };

  const handleCloseForAddOpportunity = () => {
    // loadData()
    setAddActivityModal(false);
  };

  const handleCloseForOpportunityDetails = () => {
    // loadData()
    loadData(currPage, searchKey, selectedLeadRate);
    setLeadDetailsModal(false);
  };

  const searchKeyword = (key) => {
    loadData(0, key, selectedLeadRate);
    setSearchKey(key);
  };

  const OnDragStop = (e) => {
    // appendElement('Kanban <b>Drag Stop</b> event called<hr>');
    // console.log(e);
    let temp = e.data[0];
    setOpportunityDetails(temp);
    setSelectedAssignee(temp?.userId);
    setSelectedAssignee2({
      label: temp?.empName,
      value: temp?.userId,
    });
    setSelectedTeam(temp?.teamName);
    getTeamNames();
    getUsers(temp?.teamName);
    setOpportunityDropModal(true);
  };

  const updateOpportunity = () => {
    if (enteredProbability === null) {
      return swal("Please Enter Probability", {
        icon: "error",
        dangerMode: true,
      });
    } else if (selectedAssignee === null) {
      return swal("Please Select Assignee", {
        icon: "error",
        dangerMode: true,
      });
    } else if (enteredRemarks === null) {
      return swal("Please Enter Remarks", {
        icon: "error",
        dangerMode: true,
      });
    }
    var paylaod = {
      id: opportunityDetails.id,
      leadId: opportunityDetails.leadId,
      opportunityStage: opportunityDetails.opportunityStage,
      teamName: selectedTeam,
      userId: selectedAssignee,
      probability: enteredProbability,
      remarks: enteredRemarks,
      updatedByUserId: userLoginDetails?.autoFinanceUser?.userId,
    };

    moduleApi.postData(apiCall.updateOpportunity, paylaod).then((response) => {
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          loadData(currPage, searchKey, selectedLeadRate);
          setOpportunityDropModal(false);
          return swal("Opportunity Updated Successfully", {
            icon: "success",
            dangerMode: false,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const updateAssignee = () => {
    if (selectedAssignee === null) {
      return swal("Please Select Assignee", {
        icon: "error",
        dangerMode: true,
      });
    }
    var paylaod = {
      id: opportunityDetails.id,

      teamName: selectedTeam,
      userId: selectedAssignee,
      opportunityStage: opportunityDetails?.opportunityStage,
      updatedByUserId: userLoginDetails?.autoFinanceUser?.userId,
    };

    moduleApi.postData(apiCall.updateOpportunity, paylaod).then((response) => {
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          loadData(currPage, searchKey, selectedLeadRate);
          setAssigneeModal(false);
          return swal("Assignee Updated Successfully", {
            icon: "success",
            dangerMode: false,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const getUsers = (teamName) => {
    const payLoad = {
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      teamName: teamName,
    };
    moduleApi.postData(apiCall.getTeamUsers, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.userId,
        label: item.name + " - " + item.branchName,
        empId: item.empId,
      }));
      setAssingees(result);
    });
  };

  const getTeamNames = () => {
    var payload = {
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
    };
    moduleApi.postData(apiCall.getTeamNames, payload).then((response) => {
      const result = response?.map((item) => ({
        value: item,
        label: item,
      }));
      setTeamNames(result);
    });
  };

  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div className="card" style={{ padding: "5px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            marginBottom: "5px",
            marginTop: "5px",
          }}
        >
          <SearchBar
            placeholder="Search by Prospect Name & Number"
            searchKeyword={searchKeyword}
          />
          <div
            style={{
              backgroundColor: "white",
              height: "38px",
              width: "100px",
              // border: "solid",
              borderRadius: "7px",
              // margin: "auto",
              marginRight: "20px",
              marginLeft: "10px",
              // borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              options={leadRateOptions}
              defaultValue={{ label: "All", value: 0 }}
              onChange={(value) => {
                loadData(0, searchKey, value.value);
                setSelectedLeadRate(value.value);
              }}
              placeholder="Select Type"
            />
          </div>
        </div>
        <div className="schedule-control-section">
          <div
            className="col-lg-12 control-section"
            style={{ overflowX: "auto" }}
          >
            <div className="control-wrapper">
              <KanbanComponent
                id="kanban"
                cssClass="kanban-overview"
                keyField="opportunityStage"
                dataSource={data}
                // enableTooltip={true}
                // swimlaneSettings={{ keyField: "Assignee" }}
                cardSettings={{
                  headerField: "phoneNumber",
                  template: cardTemplate,
                  selectionType: "Single",
                }}
                // dialogSettings={{ fields: fields }}
                // cardRendered={cardRendered.bind(this)}
                allowDragAndDrop={true}
                // cardDropped={handleCardDropped}
                dragStop={OnDragStop.bind(this)}
              >
                <ColumnsDirective>
                  {/* {getColumns()} */}
                  {opportunityStages?.map((item) => {
                    return (
                      <ColumnDirective
                        headerText={item}
                        keyField={item}
                        allowToggle={true}
                        template={(column) => columnTemplate(column)}
                      />
                    );
                  })}
                  {/* <ColumnDirective
                headerText="Open"
                keyField="New"
                allowToggle={true}
                template={columnTemplate.bind(this)}
              />
              <ColumnDirective
                headerText="Contacted"
                keyField="Contacted"
                allowToggle={true}
                template={columnTemplate.bind(this)}
              />
              <ColumnDirective
                headerText="In Progress"
                keyField="InProgress"
                allowToggle={true}
                template={columnTemplate.bind(this)}
              />
              <ColumnDirective
                headerText="Done"
                keyField="Close"
                allowToggle={true}
                template={columnTemplate.bind(this)}
              /> */}
                </ColumnsDirective>
              </KanbanComponent>
            </div>

            {opportunities?.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
            <Modal className="fade" size="md" show={addActivityModal}>
              <Modal.Header>
                <Modal.Title>Add Activity</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setAddActivityModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body style={{ padding: "15px" }}>
                <div style={{ margin: "10px" }}>
                  <AddActivity
                    opportunityData={opportunityDetails}
                    close={handleCloseForAddOpportunity}
                  />
                </div>
              </Modal.Body>
            </Modal>
            <Modal className="fade" size="lg" show={leadDetailsModal}>
              <Modal.Header>
                <Modal.Title>Opportunity Details</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setLeadDetailsModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body style={{ padding: "15px" }}>
                <div style={{ margin: "10px" }}>
                  <OpportunityDetails
                    opportunityDetails={opportunityDetails}
                    close={handleCloseForOpportunityDetails}
                  />
                </div>
              </Modal.Body>
            </Modal>
            <Modal className="fade" size="md" show={opportunityDropModal}>
              <Modal.Header>
                <Modal.Title>Update Opportunity</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setOpportunityDropModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body style={{ padding: "15px" }}>
                <div style={{ margin: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                    }}
                  >
                    <label
                      className="control-label"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Probability
                    </label>
                    <input
                      name="probability"
                      style={{
                        width: "447px",
                        // height: "90px",
                        borderRadius: "7px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                        padding: "10px",
                        // marginLeft: "10px",
                        marginBottom: "5px",
                      }}
                      type="number"
                      min="0"
                      max="100"
                      onChange={(e) => {
                        setEnteredprobability(e.target.value);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                    }}
                  >
                    <label
                      className="control-label"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Team
                    </label>
                    <div
                      style={{
                        width: "447px",
                        height: "38px",
                        marginRight: "20px",
                        borderRadius: "3px",
                        borderWidth: "0px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        id="teamName"
                        name="teamName"
                        options={teamNames}
                        defaultValue={{
                          label: opportunityDetails?.teamName,
                          value: opportunityDetails?.teamName,
                        }}
                        onChange={(option) => {
                          getUsers(option.value);
                          setSelectedTeam(option.value);
                          setSelectedAssignee(null);
                          setSelectedAssignee2(null);
                        }}
                        placeholder="Select Team"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                    }}
                  >
                    <label
                      className="control-label"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Assignee
                    </label>
                    <div
                      style={{
                        width: "447px",
                        height: "38px",
                        marginRight: "20px",
                        borderRadius: "3px",
                        borderWidth: "0px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        id="assignee"
                        name="assignee"
                        options={assignees}
                        defaultValue={{
                          label: opportunityDetails?.empName,
                          value: opportunityDetails?.empName,
                        }}
                        value={selectedAssignee2}
                        onChange={(option) => {
                          setSelectedAssignee(option.value);
                          setSelectedAssignee2({
                            label: option.label,
                            value: option.value,
                          });
                        }}
                        placeholder="Select Assignee"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                    }}
                  >
                    <label
                      className="control-label"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Remarks
                    </label>
                    <input
                      name="remarks"
                      style={{
                        width: "447px",
                        // height: "90px",
                        borderRadius: "7px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                        padding: "10px",
                        // marginLeft: "10px",
                        marginBottom: "5px",
                      }}
                      type="text"
                      onChange={(e) => {
                        setEnteredRemarks(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ float: "right", marginTop: "10px" }}>
                    <Button
                      style={{ float: "right" }}
                      className="me-2"
                      onClick={() => {
                        updateOpportunity();
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="me-2"
                      variant="danger light"
                      onClick={() => {
                        loadData(currPage, searchKey, selectedLeadRate);
                        setOpportunityDropModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal className="fade" show={assigneeModal}>
              <Modal.Header>
                <Modal.Title>Update Assignee</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setAssigneeModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                    }}
                  >
                    <label
                      className="control-label"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Team
                    </label>
                    <div
                      style={{
                        width: "447px",
                        height: "38px",
                        marginRight: "20px",
                        borderRadius: "3px",
                        borderWidth: "0px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        id="teamName"
                        name="teamName"
                        options={teamNames}
                        defaultValue={{
                          label: opportunityDetails?.teamName,
                          value: opportunityDetails?.teamName,
                        }}
                        onChange={(option) => {
                          getUsers(option.value);
                          setSelectedTeam(option.value);
                          setSelectedAssignee(null);
                          setSelectedAssignee2(null);
                        }}
                        placeholder="Select Team"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                    }}
                  >
                    <label
                      className="control-label"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Assignee
                    </label>
                    <div
                      style={{
                        width: "447px",
                        height: "38px",
                        marginRight: "20px",
                        borderRadius: "3px",
                        borderWidth: "0px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        id="assignee"
                        name="assignee"
                        options={assignees}
                        defaultValue={{
                          label: opportunityDetails?.empName,
                          value: opportunityDetails?.empName,
                        }}
                        value={selectedAssignee2}
                        onChange={(option) => {
                          setSelectedAssignee(option.value);
                          setSelectedAssignee2({
                            label: option.label,
                            value: option.value,
                          });
                        }}
                        placeholder="Select Assignee"
                      />
                    </div>
                    <div style={{ float: "right", marginTop: "10px" }}>
                      <Button
                        style={{ float: "right" }}
                        className="me-2"
                        onClick={() => {
                          updateAssignee();
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ float: "right" }}
                        className="me-2"
                        variant="danger light"
                        onClick={() => {
                          setAssigneeModal(false);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px 10px 0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
export default SalesCRM;
