import React, { useEffect, useState } from "react";
//import SearchBar from "../CommonComponents/SearchBar";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess, isAdmin } from "../services/AuthService";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { EpochToDate } from "../CommonComponents/Helper";
//import Select from "react-select";
import swal from "sweetalert";
import { Nav, Tab, Col, Modal } from "react-bootstrap";
import "flatpickr/dist/plugins/monthSelect/style.css";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import ReactGA from "react-ga";
import { useLocation } from 'react-router-dom';



const EligibleEmployeesForIncentives = () => {

    // var history = useHistory();
    const [incentivesList, setIncentivesList] = useState([]);
    const [incentiveDetails, setIncentiveDetails] = useState([]);
    const [basicModal, setBasicModal] = useState(false);
    // const [basicModal2, setBasicModal2] = useState(false);
    // const [rejectionReason, setRejectionReason] = useState(null);
    const [loggedInUser] = useState(
        JSON.parse(localStorage.getItem("userDetails"))
    );
    const [totalElements, setTotalElements] = useState(1);
    const [currPage, setCurrPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [incentivesDownloadList, setIncentivesDownloadList] = useState(null);

    // const [start, setStart] = useState(
    //     new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    //     1000
    // );
    // const [end, setEnd] = useState(
    //     new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    //     1000 +
    //     86399
    // );
    // const [searchKey, setSearchKey] = useState("");
    // const [firstApprovalStatus, setFirstApprovalStatus] = useState("");
    // const [secondApprovalStatus, setSecondApprovalStatus] = useState("");
    // const [amountCreditedStatus, setAmountCreditedStatus] = useState("");

    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        loadIncentivesData(
            0,
        );
        // eslint-disable-next-line
    }, []);

    const loadIncentivesData = (
        pageNo,
    ) => {
        setIsLoading(true);

        var body = {
            endDate: 0,
            organizationId: loggedInUser.autoFinanceUser.organizationId,
            reportingOfficerId: isAdmin() ? "" : hasAccess(72) ? "" : loggedInUser.autoFinanceUser.userId,
            startDate: 0,
            userId: "",
        };

        moduleApi
            .postData(
                moduleApi.withPage(apiCall.getIncentivesListForDashboards, pageNo, 10),
                body
            )
            .then((response) => {
                setCurrPage(pageNo);
                var listData = [];
                response?.content?.forEach((element) => {
                    let data = {
                        id: element.id ? element.id : "-",
                        empName: element.empName ? element.empName : "-",
                        empId: element.empId ? element.empId : "-",
                        incentiveName: element.incentive.incentiveName
                            ? element.incentive.incentiveName
                            : "-",
                        incentiveCriteria: element.incentive.incentiveCriteria ? element.incentive.incentiveCriteria : "-",
                        incentiveSubCriteria: element.incentive.incentiveSubCriteria ? element.incentive.incentiveSubCriteria : "-",
                        criteriaPercentage: element.incentive.criteriaPercentage
                            ? element.incentive.criteriaPercentage
                            : "-",
                        criteriaValue: element.incentive.criteriaValue ? element.incentive.criteriaValue : "-",
                        incentiveStartDate: element.incentive.incentiveStartDate
                            ? EpochToDate(element.incentive.incentiveStartDate)
                            : "-",
                        incentiveEndDate: element.incentive.incentiveEndDate
                            ? EpochToDate(element.incentive.incentiveEndDate)
                            : "-",
                        organizationId: element.organizationId
                            ? element.organizationId
                            : "-",
                        approvalStatus: element.approvalStatus
                            ? element.approvalStatus
                            : "-",
                        secondApprovalStatus: element.secondApprovalStatus
                            ? element.secondApprovalStatus
                            : "-",
                        approvedDateTime: element.approvedDateTime
                            ? EpochToDate(element.approvedDateTime)
                            : "-",
                        approvedBy: element.approvedBy ? element.approvedBy : "-",
                        approvedByName: element.approvedByName ? element.approvedByName : "-",
                        criteriaTargetAmount: element.incentive.criteriaTargetAmount
                            ? element.incentive.criteriaTargetAmount
                            : "-",
                        incentive: element.incentive ? element.incentive : "-",
                        targetAchieved: element.targetAchieved ? element.targetAchieved : 0,
                        amountCredited: element.amountCredited ? true : false,
                        targetToBeAchieved: element.targetToBeAchieved ? element.targetToBeAchieved : 0,
                        amountAchieved: element.amountAchieved ? element.amountAchieved.toFixed(2) : 0,
                        amountToBeAchieved: element.amountToBeAchieved ? element.amountToBeAchieved.toFixed(2) : 0,
                        incentiveTargetStatus: element.incentiveTargetStatus ? element.incentiveTargetStatus : "-",
                        range: element?.incentive?.range ? element?.incentive?.range : "-",
                        criteriaValue2: element?.incentive?.criteriaValue2 ? element?.incentive?.criteriaValue2 : "-",
                        criteriaPercentage2: element?.incentive?.criteriaPercentage2
                            ? element?.incentive?.criteriaPercentage2
                            : "-",
                        criteriaTargetAmount2: element?.incentive?.criteriaTargetAmount2
                            ? element?.incentive?.criteriaTargetAmount2
                            : "-",
                        incentiveRangeAndValueId:element?.incentiveRangeAndValueId ? element.incentiveRangeAndValueId :0  
                    };
                    listData.push(data);
                });
                setIncentivesList(listData);
                setIsLoading(false);

                setTotalElements(response?.totalElements);
            });
    };

    const onPageChange = (selectedPage) => {
        loadIncentivesData(
            selectedPage - 1,
        );
    };


    // const updateApprovalStatus = (status, isSecondApproval) => {
    //     console.log("status", status);
    //     // if (status === "REJECTED" && rejectionReason === null) {
    //     //     return swal("Please Enter Rejection Reason", {
    //     //         icon: "error",
    //     //         dangerMode: true,
    //     //     });
    //     // }

    //     var body = {
    //         approvalStatus: status,
    //         secondApprovalStatus: status,
    //         //rejectionReason: rejectionReason,
    //         secondApproval: isSecondApproval ? true : false,
    //         secondApprovedBy: isSecondApproval ? loggedInUser.autoFinanceUser.userId : "",
    //         organizationId: loggedInUser.autoFinanceOrganization.organizationId,
    //         id: incentiveDetails.id,
    //         approvedBy: loggedInUser.autoFinanceUser.userId,
    //     };

    //     moduleApi
    //         .postData(apiCall.updateIncentivesApprovalStatusForUser, body)
    //         .then((response) => {
    //             if (response) {
    //                 if (response?.message) {
    //                     swal(response?.message, {
    //                         icon: "error",
    //                         dangerMode: true,
    //                     });
    //                 } else if (response?.Message === "Incentive Approval Status Updated successfully") {
    //                     loadIncentivesData(
    //                         currPage,
    //                     );
    //                     setBasicModal(false);
    //                     setBasicModal2(false);
    //                     swal("Status Updated Successfully", {
    //                         icon: "success",
    //                     });
    //                 }
    //             } else {
    //                 return swal("Something Went Wrong, Please Try Later", {
    //                     icon: "error",
    //                     dangerMode: true,
    //                 });
    //             }
    //         });
    // };

    // const updateAmountCreditedStatus = () => {

    //     var body = {
    //         amountCredited: true,
    //         organizationId: loggedInUser.autoFinanceOrganization.organizationId,
    //         id: incentiveDetails.id,
    //     };

    //     moduleApi
    //         .postData(apiCall.markIncentiveAmountAsCredited, body)
    //         .then((response) => {
    //             if (response) {
    //                 if (response?.message) {
    //                     swal(response?.message, {
    //                         icon: "error",
    //                         dangerMode: true,
    //                     });
    //                 } else if (response) {
    //                     loadIncentivesData(
    //                         0,
    //                     );
    //                     setBasicModal(false);
    //                     setBasicModal2(false);
    //                     swal("Status Updated Successfully", {
    //                         icon: "success",
    //                     });
    //                 }
    //             } else {
    //                 return swal("Something Went Wrong, Please Try Later", {
    //                     icon: "error",
    //                     dangerMode: true,
    //                 });
    //             }
    //         });
    // };

    // const setCustomDatesForGraph = (value) => {
    //     var start = value[0].getTime() / 1000;
    //     var end =
    //         new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
    //         1000 +
    //         86399;

    //     setStart(start);
    //     setEnd(end);
    //     loadIncentivesData(0, start, end, searchKey,firstApprovalStatus,secondApprovalStatus,amountCreditedStatus);
    // };

    const columns = [
        {
            text: "Emp Name",
            dataField: "empName",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: "Emp Id",
            dataField: "empId",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: "Incentive Name",
            dataField: "incentiveName",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Incentive</span>
                    <br></br>
                    <span>Criteria</span>
                </div>
            ),
            dataField: "incentiveCriteria",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Incentive</span>
                    <br></br>
                    <span>Sub Criteria</span>
                </div>
            ),
            dataField: "incentiveSubCriteria",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Incentive</span>
                    <br></br>
                    <span>Start Date</span>
                </div>
            ),
            dataField: "incentiveStartDate",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Incentive</span>
                    <br></br>
                    <span>End Date</span>
                </div>
            ),
            dataField: "incentiveEndDate",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Target</span>
                    <br></br>
                    <span>Achieved</span>
                </div>
            ),
            dataField: "targetAchieved",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {

                if (!row?.incentive?.incentivePerCaseBasis && row?.incentive?.incentiveCriteria === "Collected Amount") {
                    return (
                        row.targetAchieved + "₹"
                    );

                } else {
                    return (
                        row.targetAchieved
                    );
                }

            },
        },
        {
            text: (
                <div>
                    <span>Target</span>
                    <br></br>
                    <span>Pending</span>
                </div>
            ),
            dataField: "targetToBeAchieved",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {

                if (!row?.incentive?.incentivePerCaseBasis && row?.incentive?.incentiveCriteria === "Collected Amount") {
                    return (
                        row.targetAchieved + "₹"
                    );

                } else {
                    return (
                        row.targetToBeAchieved
                    );
                }

            },
        },
        {
            text: (
                <div>
                    <span>First Approval</span>
                    <br></br>
                    <span>Status</span>
                </div>
            ),
            dataField: "approvalStatus",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                if (row.approvalStatus === "APPROVED") {
                    return (
                        <span className="badge badge-rounded badge-success">Approved</span>
                    );
                } else if (row.approvalStatus === "REJECTED") {
                    return (
                        <span className="badge badge-rounded badge-danger">Rejected</span>
                    );
                } else if (row.approvalStatus === "PENDING") {
                    return (
                        <span
                            className="badge badge-rounded badge-warning"
                            style={{ color: "black" }}
                        >
                            Pending
                        </span>
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            text: (
                <div>
                    <span>Second Approval</span>
                    <br></br>
                    <span>Status</span>
                </div>
            ),
            dataField: "secondApprovalStatus",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                if (row.secondApprovalStatus === "APPROVED") {
                    return (
                        <span className="badge badge-rounded badge-success">Approved</span>
                    );
                } else if (row.secondApprovalStatus === "REJECTED") {
                    return (
                        <span className="badge badge-rounded badge-danger">Rejected</span>
                    );
                } else if (row.secondApprovalStatus === "PENDING") {
                    return (
                        <span
                            className="badge badge-rounded badge-warning"
                            style={{ color: "black" }}
                        >
                            Pending
                        </span>
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            text: (
                <div>
                    <span>Amount Credited</span>
                    <br></br>
                    <span>Status</span>
                </div>
            ),
            dataField: "amountCredited",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                if (row.amountCredited === true) {
                    return (
                        <span className="badge badge-rounded badge-success">Credited</span>
                    );
                } else if (row.amountCredited === false) {
                    return (
                        <span className="badge badge-rounded badge-danger">Not Credited</span>
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            text: "",
            dataField: "id",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                if (row.id === "ACTIVE4") {
                    return <></>;
                } else {
                    return (
                        <div className="d-flex">
                            <Link to="#" className="btn btn-info shadow btn-xs sharp">
                                <i className="fa fa-eye"></i>
                            </Link>
                        </div>
                    );
                }
            },

            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setIncentiveDetails(row);
                    setBasicModal(true);
                },
            },
        },
    ];


    useEffect(() => {
        incentivesDownloadList !== null && handleExport();
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incentivesDownloadList]);

    const incentivesDownloadData = () => {

        setIsLoading(true);
        var payload = {
            endDate: 0,
            organizationId: loggedInUser.autoFinanceUser.organizationId,
            reportingOfficerId: isAdmin() ? "" : hasAccess(72) ? "" : loggedInUser.autoFinanceUser.userId,
            startDate: 0,
            userId: "",
        };
        moduleApi
            .postData(
                moduleApi.withPage(apiCall.getIncentivesListForDashboards, 0, 10),
                payload
            )
            .then((response) => {
                if (response) {
                    if (response.totalElements > 5000) {
                        setIsLoading(false);
                        return swal({
                            title: "Download Report",
                            text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        }).then((willContinue) => {
                            if (willContinue) {
                                setIsLoading(true);
                                getIncentivesFullResponseData(
                                    Math.floor(response.totalElements / 100)
                                );
                            }
                        });
                    }

                    getIncentivesFullResponseData(Math.floor(response.totalElements / 100));
                } else {
                    swal("Something Went Wrong, Please Try Later", {
                        icon: "error",
                        dangerMode: true,
                    });
                }
            });
    };

    async function getIncentivesFullResponseData(size) {
        var tempList = [];
        var incntveListData = [];
        for (let i = 0; i <= size; i++) {
            var payload = {
                endDate: 0,
                organizationId: loggedInUser.autoFinanceUser.organizationId,
                reportingOfficerId: isAdmin() ? "" : hasAccess(72) ? "" : loggedInUser.autoFinanceUser.userId,
                startDate: 0,
                userId: "",
            };
            await moduleApi
                .postData(
                    moduleApi.withPage(apiCall.getIncentivesListForDashboards, i, 100),
                    payload
                )
                .then((response) => {
                    tempList.push(...response.content);
                });
        }

        console.log("templist", tempList);

        tempList?.forEach((element) => {
            let data = {
                id: element.id ? element.id : "-",
                empName: element.empName ? element.empName : "-",
                empId: element.empId ? element.empId : "-",
                incentiveName: element.incentive.incentiveName
                    ? element.incentive.incentiveName
                    : "-",
                incentiveCriteria: element.incentive.incentiveCriteria ? element.incentive.incentiveCriteria : "-",
                incentiveSubCriteria: element.incentive.incentiveSubCriteria ? element.incentive.incentiveSubCriteria : "-",
                criteriaPercentage: element.incentive.criteriaPercentage
                    ? element.incentive.criteriaPercentage
                    : "-",
                criteriaValue: element.incentive.criteriaValue ? element.incentive.criteriaValue : "-",
                incentiveStartDate: element.incentive.incentiveStartDate
                    ? EpochToDate(element.incentive.incentiveStartDate)
                    : "-",
                incentiveEndDate: element.incentive.incentiveEndDate
                    ? EpochToDate(element.incentive.incentiveEndDate)
                    : "-",
                organizationId: element.organizationId
                    ? element.organizationId
                    : "-",
                approvalStatus: element.approvalStatus
                    ? element.approvalStatus
                    : "-",
                secondApprovalStatus: element.secondApprovalStatus
                    ? element.secondApprovalStatus
                    : "-",
                approvedDateTime: element.approvedDateTime
                    ? EpochToDate(element.approvedDateTime)
                    : "-",
                approvedBy: element.approvedBy ? element.approvedBy : "-",
                approvedByName: element.approvedByName ? element.approvedByName : "-",
                criteriaTargetAmount: element.incentive.criteriaTargetAmount
                    ? element.incentive.criteriaTargetAmount
                    : "-",
                incentive: element.incentive ? element.incentive : "-",
                targetAchieved: element.targetAchieved ? element.targetAchieved : 0,
                amountCredited: element.amountCredited ? true : false,
                targetToBeAchieved: element.targetToBeAchieved ? element.targetToBeAchieved : 0,
                amountAchieved: element.amountAchieved ? element.amountAchieved : 0,
                amountToBeAchieved: element.amountToBeAchieved ? element.amountToBeAchieved : 0,
                incentiveTargetStatus: element.incentiveTargetStatus ? element.incentiveTargetStatus : "-",
                range: element?.incentive?.range ? element?.incentive?.range : "-",
                criteriaValue2: element?.incentive?.criteriaValue2 ? element?.incentive?.criteriaValue2 : "-",
                criteriaPercentage2: element?.incentive?.criteriaPercentage2
                    ? element?.incentive?.criteriaPercentage2
                    : "-",
                criteriaTargetAmount2: element?.incentive?.criteriaTargetAmount2
                    ? element?.incentive?.criteriaTargetAmount2
                    : "-",
            };
            incntveListData.push(data);
        });
        setIncentivesDownloadList(incntveListData);
    }


    const handleExport = () => {
        let incentiveTable = [
            {
                A: "Emp Name",
                B: "Emp Id",
                C: "Incentive Name",
                D: "Incentive Criteria",
                E: "Incentive Sub Criteria",
                F: "Incentive Start Date",
                G: "Incentive End Date",
                H: "Target Achived",
                I: "Target Pending",
                J: "First Approval Status",
                K: "Second Approval Status",
                L: "Amount Credited Status",
            },
        ];

        incentivesDownloadList?.forEach((row) => {
            incentiveTable.push({
                A: row.empName,
                B: row.empId,
                C: row.incentiveName,
                D: row.incentiveCriteria,
                E: row.incentiveSubCriteria,
                F: row.incentiveStartDate,
                G: row.incentiveEndDate,
                H: row.targetAchieved + (!row?.incentive?.incentivePerCaseBasis && row?.incentive?.incentiveCriteria === "Collected Amount" ? "₹" : ""),
                I: row.targetToBeAchieved + (!row?.incentive?.incentivePerCaseBasis && row?.incentive?.incentiveCriteria === "Collected Amount" ? "₹" : ""),
                J: row.approvalStatus,
                K: row.secondApprovalStatus,
                L: row.amountCredited ? "Credited" : "Not Credited",
            });
        });

        const finalData = [...incentiveTable];

        console.log("finaldata", finalData);

        //create a new workbook
        const wb = XLSX.utils.book_new();

        const sheet = XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
        });

        console.log("sheet", sheet);

        XLSX.utils.book_append_sheet(wb, sheet, "Eligible Employees");

        XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

        XLSX.writeFile(
            wb,
            "Eligible_Employees_" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx"
        );
        ReactGA.event({
            category: "Eligible Employees List Downlaod",
            action:
                loggedInUser.autoFinanceOrganization.orgshortcode +
                "_Eligible_Employees_Details_" +
                helper.EpochToDate(Math.floor(new Date() / 1000)) +
                ".xlsx",
        });
    };

    // const approvalStatusOptions = [
    //     { label: "All", value: "" },
    //     { label: "Pending", value: "PENDING" },
    //     { label: "Approved", value: "APPROVED" },
    //     { label: "Rejected", value: "REJECTED" },
    //   ];

    //   const amountCreditedStatusOptions = [
    //     { label: "All", value: "" },
    //     { label: "Credited", value: "CREDITED" },
    //     { label: "Not Credited", value: "NOTCREDITED" },
    //   ];

    // const searchKeyword = (key) => {
    //     setSearchKey(key);
    //     loadIncentivesData(
    //         0,
    //         start,
    //         end,
    //         key,
    //         firstApprovalStatus,
    //         secondApprovalStatus,
    //         amountCreditedStatus
    //     );
    // };

    // const setFirstApprovalStatusValue = (value) => {
    //     setFirstApprovalStatus(value);

    //     loadIncentivesData(
    //       0,
    //       start,
    //       end,
    //       searchKey,
    //       value,
    //       secondApprovalStatus,
    //       amountCreditedStatus
    //     );
    //   };

    //   const setSecondApprovalStatusValue = (value) => {
    //     setSecondApprovalStatus(value);

    //     loadIncentivesData(
    //       0,
    //       start,
    //       end,
    //       searchKey,
    //       firstApprovalStatus,
    //       value,
    //       amountCreditedStatus
    //     );
    //   };

    //   const setAmountCreditedStatusValue = (value) => {
    //     setAmountCreditedStatus(value);

    //     loadIncentivesData(
    //       0,
    //       start,
    //       end,
    //       searchKey,
    //       firstApprovalStatus,
    //       secondApprovalStatus,
    //       value
    //     );
    //   };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    //   marginTop: "20px",
                    marginBottom: "15px",
                    marginRight: "15px",
                }}
            >
            </div>
            <LoadingOverlay
                active={isLoading}
                // spinner
                text={
                    <p
                        style={{
                            color: "black",
                        }}
                    >
                        <div>
                            <Button
                                style={{
                                    backgroundColor: "#FFFF",
                                    color: "black",
                                    borderColor: "white",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                }}
                                variant="primary"
                                disabled
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="md"
                                    variant="primary"
                                    role="status"
                                    aria-hidden="true"
                                />
                                &nbsp;&nbsp;Loading...
                            </Button>
                        </div>
                    </p>
                }
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(192,192,192,0.4)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "80px",
                        "& svg circle": {
                            stroke: "rgb(136, 108, 192)",
                        },
                    }),
                }}

            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        marginBottom: "5px",
                        marginRight: "20px",
                    }}
                >
                    <Button
                        style={{
                            marginRight: "10px",
                            marginLeft: "7px",
                            marginBottom: "5px",
                            fontFamily:
                                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                        }}
                        className="me-2"
                        variant="success btn-rounded"
                        onClick={() => incentivesDownloadData()}
                    >
                        <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                    </Button>
                </div>
                <div
                    style={{
                        background: "white",
                        margin: "10px",
                        marginTop: "-5px",
                        overflowX: "auto",
                    }}
                >

                    <div
                        style={{
                            overflow: "auto",
                            maxHeight: "500px",
                        }}
                    >

                        <BootstrapTable
                            keyField="panNo"
                            data={incentivesList}
                            columns={columns}
                        />

                    </div>

                    {incentivesList.length === 0 && incentivesList.length === 0 && (
                        <div className="d-flex justify-content-center">
                            <img src={NO_DATA_FOUND} alt="" />
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                        }}
                    >
                        <PaginationComponent
                            totalItems={totalElements}
                            pageSize={10}
                            defaultActivePage={currPage + 1}
                            onSelect={onPageChange}
                        />
                    </div>
                </div>
            </LoadingOverlay>
            <Modal className="fade" size="lg" show={basicModal}>
                <Modal.Header>
                    <Modal.Title>Incentive Details For{" "}
                        {
                            <span style={{ fontWeight: "bold" }}>
                                {incentiveDetails.empName}
                                {" "} {"(" + incentiveDetails.empId + ")"}
                            </span>
                        }</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => {
                            setBasicModal(false);
                        }}
                    ></Button>
                </Modal.Header>
                <Modal.Body style={{ padding: "10px" }}></Modal.Body>
                <div style={{ margin: "20px" }}>
                    <div className="custom-tab-1">
                    <Tab.Container defaultActiveKey="incentiveDetails">
                            <Nav as="ul" className="nav-tabs-custom nav-justified">
                                <Nav.Item as="li" key="0">
                                    <Nav.Link eventKey="incentiveDetails">
                                        <b> Incentive Details </b>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" key="1">
                                    <Nav.Link eventKey="achievementCriteria">
                                        <b> Achievement Criteria </b>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" key="2">
                                    <Nav.Link eventKey="achievementDetails">
                                        <b> Achievement Details </b>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item as="li" key="3">
                                    <Nav.Link eventKey="approvalDetails">
                                        <b> Approval Details </b>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content className="pt-4">
                                <Tab.Pane eventKey="incentiveDetails" key="0" mountOnEnter>
                                    <Col>
                                        <div
                                            className="card contact-bx item-content"
                                            style={{
                                                backgroundColor: "rgb(243,240,249)",
                                                overflowY: "auto",
                                                maxHeight: "400px",
                                                margin: "5px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "20px",
                                                    marginBottom: "10px",
                                                    marginTop: "30px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                <>
                                                    <tr></tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                textAlign: "left",
                                                                width: "30%",
                                                            }}
                                                        >
                                                            Incentive Name
                                                        </td>
                                                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails.incentiveName
                                                                ? incentiveDetails.incentiveName
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                textAlign: "left",
                                                                width: "30%",
                                                            }}
                                                        >
                                                            Incentive Status
                                                        </td>
                                                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.status
                                                                ? incentiveDetails?.incentive?.status
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Branch Names
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {(incentiveDetails?.incentive?.branchNames && incentiveDetails?.incentive?.branchNames.length > 0) ? incentiveDetails.incentive.branchNames.join(', ') : "All"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Managers
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {(incentiveDetails?.incentive?.managerNames && incentiveDetails?.incentive?.managerNames.length > 0) ? incentiveDetails.incentive.managerNames.join(', ') : "All"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Designations
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {(incentiveDetails?.incentive?.designations && incentiveDetails?.incentive?.designations.length > 0) ? incentiveDetails.incentive.designations.join(', ') : "All"}
                                                        </td>
                                                    </tr>
                                                    {incentiveDetails?.incentive?.incentiveType === "Collections" && (
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    fontWeight: "500",
                                                                    float: "left",
                                                                    textAlign: "left",
                                                                }}
                                                            >
                                                                Cp Names
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontWeight: "bold",
                                                                    float: "left",
                                                                    textAlign: "left",
                                                                }}
                                                            >
                                                                {(incentiveDetails?.incentive?.cpNames && incentiveDetails?.incentive?.cpNames.length > 0) ? incentiveDetails.incentive.cpNames.join(', ') : "All"}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {incentiveDetails?.incentive?.incentiveType === "Collections" && (
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    fontWeight: "500",
                                                                    float: "left",
                                                                    textAlign: "left",
                                                                }}
                                                            >
                                                                Current Bucket
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontWeight: "bold",
                                                                    float: "left",
                                                                    textAlign: "left",
                                                                }}
                                                            >
                                                                {(incentiveDetails?.incentive?.currentBucketNames && incentiveDetails?.incentive?.currentBucketNames.length > 0) ? incentiveDetails.incentive.currentBucketNames.join(', ') : "All"}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {incentiveDetails?.incentive?.incentiveType === "Collections" && (

                                                        <tr>
                                                            <td
                                                                style={{
                                                                    fontWeight: "500",
                                                                    float: "left",
                                                                    textAlign: "left",
                                                                }}
                                                            >
                                                                Product Name
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontWeight: "bold",
                                                                    float: "left",
                                                                    textAlign: "left",
                                                                }}
                                                            >
                                                                {(incentiveDetails?.incentive?.productNames && incentiveDetails?.incentive?.productNames.length > 0) ? incentiveDetails.incentive.productNames.join(', ') : "All"}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Incentive Start Date
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentiveStartDate ? incentiveDetails?.incentiveStartDate : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Incentive End Date
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentiveEndDate ? incentiveDetails?.incentiveEndDate : "-"}
                                                        </td>
                                                    </tr>
                                                </>
                                            </div>
                                        </div>
                                    </Col>
                                </Tab.Pane>


                                <Tab.Pane eventKey="achievementCriteria" key="1" mountOnEnter>
                                    <Col>
                                        <div
                                            className="card contact-bx item-content"
                                            style={{
                                                backgroundColor: "rgb(243,240,249)",
                                                overflowY: "auto",
                                                maxHeight: "400px",
                                                margin: "5px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "20px",
                                                    marginBottom: "10px",
                                                    marginTop: "30px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                <>
                                                    <tr></tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                textAlign: "left",
                                                                width: "30%",
                                                            }}
                                                        >
                                                            Incentive Criteria
                                                        </td>
                                                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails.incentiveCriteria
                                                                ? incentiveDetails.incentiveCriteria
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Incentive Sub Criteria
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentiveSubCriteria ? incentiveDetails?.incentiveSubCriteria : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Is Criteria In Percentage
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.percentage ? "Yes" : "No"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Is Criteria Per Case Basis
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.incentivePerCaseBasis ? "Yes" : "No"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Is Incentive Paid Per Case
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.incentivePaidPerCase ? "Yes" : "No"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Is Incentive Paid In Percentage
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.incentivePaidInPercentage ? "Yes" : "No"}
                                                        </td>
                                                    </tr>

                                                    {incentiveDetails?.incentive?.incentiveRangesAndValues !== undefined && (
                                                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: "15px" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Range</th>
                                                                    {incentiveDetails?.incentive?.incentiveCriteria !== "Collected Amount" && !incentiveDetails?.incentive?.percentage && (
                                                                        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Criteria Value</th>
                                                                    )}
                                                                    {incentiveDetails?.incentive?.incentiveCriteria !== "Collected Amount" && !incentiveDetails?.incentive?.percentage && (
                                                                        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Criteria Value 2</th>
                                                                    )}
                                                                    {incentiveDetails?.incentive?.percentage && (
                                                                        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Criteria Percentage</th>
                                                                    )}
                                                                    {incentiveDetails?.incentive?.percentage && (
                                                                        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Criteria Percentage 2</th>
                                                                    )}
                                                                    {incentiveDetails?.incentive?.incentiveCriteria === "Collected Amount" && !incentiveDetails?.incentive?.percentage && (
                                                                        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Criteria Amount 1</th>
                                                                    )}
                                                                    {incentiveDetails?.incentive?.incentiveCriteria === "Collected Amount" && !incentiveDetails?.incentive?.percentage && (
                                                                        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Criteria Amount 2</th>
                                                                    )}

                                                                    {incentiveDetails?.incentive?.incentivePaidInPercentage && (
                                                                        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Incentive Percentage</th>
                                                                    )}

                                                                    {!incentiveDetails?.incentive?.incentivePaidInPercentage && (
                                                                        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Incentive Amount</th>
                                                                    )}
                                                                    {!incentiveDetails?.incentive?.incentivePerCaseBasis && (
                                                                  <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Is Criteria Achieved</th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {incentiveDetails?.incentive?.incentiveRangesAndValues.map((element, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                            {element.range ? element.range : "-"}
                                                                        </td>
                                                                        {incentiveDetails?.incentive?.incentiveCriteria !== "Collected Amount" && !incentiveDetails?.incentive?.percentage && (
                                                                            <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                                {element.criteriaValue ? element.criteriaValue : 0}
                                                                            </td>
                                                                        )}
                                                                        {incentiveDetails?.incentive?.incentiveCriteria !== "Collected Amount" && !incentiveDetails?.incentive?.percentage && (
                                                                            <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                                {element.criteriaValue2 ? element.criteriaValue2 : "-"}
                                                                            </td>
                                                                        )}
                                                                        {incentiveDetails.incentive.percentage && (
                                                                            <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                                {element.criteriaPercentage ? element.criteriaPercentage : 0}
                                                                            </td>
                                                                        )}
                                                                        {incentiveDetails?.incentive?.percentage && (
                                                                            <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                                {element.criteriaPercentage2 ? element.criteriaPercentage2 : "-"}
                                                                            </td>
                                                                        )}
                                                                        {incentiveDetails?.incentive?.incentiveCriteria === "Collected Amount" && !incentiveDetails?.incentive?.percentage && (
                                                                            <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                                {element.criteriaTargetAmount ? element.criteriaTargetAmount : 0}
                                                                            </td>
                                                                        )}
                                                                        {incentiveDetails?.incentive?.incentiveCriteria === "Collected Amount" && !incentiveDetails?.incentive?.percentage && (
                                                                            <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                                {element.criteriaTargetAmount2 ? element.criteriaTargetAmount2 : "-"}
                                                                            </td>
                                                                        )}

                                                                        {incentiveDetails?.incentive?.incentivePaidInPercentage && (
                                                                            <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                                {element.incentivePerecentage ? element.incentivePerecentage : 0}
                                                                            </td>
                                                                        )}
                                                                        {!incentiveDetails?.incentive?.incentivePaidInPercentage && (
                                                                            <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                                {element.incentiveAmount ? element.incentiveAmount : 0}
                                                                            </td>
                                                                        )}
                                                                        {!incentiveDetails?.incentive?.incentivePerCaseBasis && (
                                                                        <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>
                                                                            {element.id === incentiveDetails?.incentiveRangeAndValueId ? "Yes" : "No"}
                                                                        </td>
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </Col>
                                </Tab.Pane>

                                <Tab.Pane eventKey="achievementDetails" key="2" mountOnEnter>
                                    <Col>
                                        <div
                                            className="card contact-bx item-content"
                                            style={{
                                                backgroundColor: "rgb(243,240,249)",
                                                overflowY: "auto",
                                                maxHeight: "400px",
                                                margin: "5px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "20px",
                                                    marginBottom: "10px",
                                                    marginTop: "30px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                <>
                                                    <tr></tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Target Achieved
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.targetAchieved ? incentiveDetails?.targetAchieved : 0}{!incentiveDetails?.incentive?.incentivePerCaseBasis && incentiveDetails?.incentive?.incentiveCriteria === "Collected Amount" ? "₹" : ""}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Target Pending
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.targetToBeAchieved ? incentiveDetails?.targetToBeAchieved : 0}{!incentiveDetails?.incentive?.incentivePerCaseBasis && incentiveDetails?.incentive?.incentiveCriteria === "Collected Amount" ? "₹" : ""}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Incentive Amount Achieved
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.amountAchieved ? incentiveDetails?.amountAchieved : 0}
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Incentive Amount To Be Achieved
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.amountToBeAchieved ? incentiveDetails?.amountToBeAchieved : 0}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Incentive Target Status
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentiveTargetStatus ? incentiveDetails.incentiveTargetStatus : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Is Amount Credited
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.amountCredited ? "Yes" : "No"}
                                                        </td>
                                                    </tr>
                                                </>
                                            </div>
                                        </div>
                                    </Col>
                                </Tab.Pane>

                                {/*<Tab.Pane eventKey="paymentCriteria" key="3" mountOnEnter>
                                    <Col>
                                        <div
                                            className="card contact-bx item-content"
                                            style={{
                                                backgroundColor: "rgb(243,240,249)",
                                                overflowY: "auto",
                                                maxHeight: "400px",
                                                margin: "5px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "20px",
                                                    marginBottom: "10px",
                                                    marginTop: "30px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                <>
                                                    <tr></tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Is Incentive Paid Per Case
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.incentivePaidPerCase ? "Yes" : "No"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Is Incentive Paid In Percentage
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.incentivePaidInPercentage ? "Yes" : "No"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Incentive Amount
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.incentiveAmount ? incentiveDetails?.incentive?.incentiveAmount : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                width: "30%",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Incentive Percentage
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.incentive?.incentivePerecentage ? incentiveDetails?.incentive?.incentivePerecentage : "-"}
                                                        </td>
                                                    </tr>
                                                </>
                                            </div>
                                        </div>
                                    </Col>
                                </Tab.Pane> */}

                                <Tab.Pane eventKey="approvalDetails" key="3" mountOnEnter>
                                    <Col>
                                        <div
                                            className="card contact-bx item-content"
                                            style={{
                                                backgroundColor: "rgb(243,240,249)",
                                                overflowY: "auto",
                                                maxHeight: "400px",
                                                margin: "5px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "20px",
                                                    marginBottom: "10px",
                                                    marginTop: "30px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                <>
                                                    <tr></tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            First Approval Status
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.approvalStatus ? incentiveDetails?.approvalStatus : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Second Approval Status
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.secondApprovalStatus ? incentiveDetails?.secondApprovalStatus : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            First Approved Date and Time
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.approvedDateTime ? incentiveDetails?.approvedDateTime : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontWeight: "500",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            Second Approved Date and Time
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontWeight: "bold",
                                                                float: "left",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {incentiveDetails?.secondApprovedDate ? incentiveDetails?.secondApprovedDate : "-"}
                                                        </td>
                                                    </tr>
                                                    {/* {incentiveDetails.incentiveTargetStatus === "ACHIEVED" && incentiveDetails?.approvalStatus === "PENDING" && !hasAccess(72) && (
                                                        <div>
                                                            <Button
                                                                style={{
                                                                    float: "left",
                                                                    marginTop: "10px",
                                                                    marginRight: "25px",
                                                                    fontFamily:
                                                                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                                                }}
                                                                className="me-2"
                                                                variant="success btn-rounded"
                                                                onClick={() => updateApprovalStatus("APPROVED", false)}
                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button
                                                                style={{
                                                                    float: "left",
                                                                    marginTop: "10px",
                                                                    marginRight: "15px",
                                                                    fontFamily:
                                                                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                                                }}
                                                                className="me-2"
                                                                variant="danger btn-rounded"
                                                                onClick={() => {
                                                                    // setRejectionReason(null);
                                                                    // setBasicModal2(true);
                                                                    updateApprovalStatus("REJECTED", false)
                                                                }}
                                                            >
                                                                Reject
                                                            </Button>
                                                        </div>
                                                    )}
                                                    {incentiveDetails.incentiveTargetStatus === "ACHIEVED" && incentiveDetails?.secondApprovalStatus === "PENDING" && incentiveDetails?.approvalStatus === "APPROVED" && hasAccess(72) && (
                                                        <div>
                                                            <Button
                                                                style={{
                                                                    float: "left",
                                                                    marginTop: "10px",
                                                                    marginRight: "25px",
                                                                    fontFamily:
                                                                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                                                }}
                                                                className="me-2"
                                                                variant="success btn-rounded"
                                                                onClick={() => updateApprovalStatus("APPROVED", true)}
                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button
                                                                style={{
                                                                    float: "left",
                                                                    marginTop: "10px",
                                                                    marginRight: "15px",
                                                                    fontFamily:
                                                                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                                                }}
                                                                className="me-2"
                                                                variant="danger btn-rounded"
                                                                onClick={() => {
                                                                    // setRejectionReason(null);
                                                                    // setBasicModal2(true);
                                                                    updateApprovalStatus("REJECTED", true)
                                                                }}
                                                            >
                                                                Reject
                                                            </Button>
                                                        </div>
                                                    )}
                                                    {incentiveDetails.incentiveTargetStatus === "ACHIEVED" && !incentiveDetails?.amountCredited && incentiveDetails?.secondApprovalStatus === "APPROVED" && incentiveDetails?.approvalStatus === "APPROVED" && hasAccess(73) && (
                                                        <div>
                                                            <Button
                                                                style={{
                                                                    float: "left",
                                                                    marginTop: "10px",
                                                                    marginRight: "25px",
                                                                    fontFamily:
                                                                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                                                }}
                                                                className="me-2"
                                                                variant="success btn-rounded"
                                                                onClick={() => updateAmountCreditedStatus()}
                                                            >
                                                                Approve Incentive
                                                            </Button>
                                                        </div>
                                                    )} */}
                                                </>
                                            </div>
                                        </div>
                                    </Col>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </Modal>
            {/* <Modal className="fade" show={basicModal2} size="sm">
                <Modal.Header>
                    <Modal.Title>Add Rejection Reason</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setBasicModal2(false)}
                    ></Button>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: "0px" }}>
                    <div style={{ margin: "10px" }}>
                        <Row>
                            <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
                            <input
                                style={{
                                    width: "220px",
                                    height: "35px",
                                    borderRadius: "25px",
                                    borderColor: "#a0a0a0",
                                    borderWidth: "1px",
                                    boxShadow: "1px 1px 2px #3a353b",
                                    paddingLeft: "10px",
                                    marginLeft: "10px",
                                }}
                                placeholder="Enter Reason"
                                onChange={(e) => {
                                    setRejectionReason(e.target.value);
                                    console.log("rejectionReason", rejectionReason);
                                }}
                            />
                            <div style={{ marginTop: "5px" }}>
                                <div>
                                    <Button
                                        style={{ float: "right", width: "70px" }}
                                        className="col-lg-2 m-2"
                                        type="submit"
                                        onClick={() =>
                                            updateApprovalStatus("REJECTED")
                                        }
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        style={{ float: "right", width: "70px" }}
                                        className="col-lg-2 m-2 float-right"
                                        variant="danger light"
                                        onClick={() => setBasicModal2(false)}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    );
};

export default EligibleEmployeesForIncentives;
