import React, { useState, useEffect } from "react";

import { mainUser} from "./generateFakeData";
import Avatar from "./components/Avatar";
import ContactBox from "./components/ContactBox";
import MessagesBox from "./components/MessagesBox";
import ChatInputBox from "./components/ChatInputBox";
import Search from "./components/Search";
import Welcome from "./components/Welcome";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { Link } from "react-router-dom";
import "./WhatsappStyle.css";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "@firebase/firestore";
import firestore from "../CommonComponents/Firestore";
import { getTokenInLocalStorage } from "../services/AuthService";
import swal from "sweetalert";

function Whatsapp({ close }) {
  // const [data, setData] = useState(contactsMessages);
  const [contactSelected, setContactSelected] = useState(null);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const [filteredContacts, setFilterContacts] = useState([]);

  var userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    // const currContact = data.find((d) => d.contact.id === contactSelected.id)
    // setCurrentMessages((currContact && currContact.messages) || [])
    // // filterContacts(data, search)
    getContactsList();
    if (contactSelected !== null) {
      onSnapshot(
        collection(firestore, "whatsapp/" + contactSelected?.id + "/messages"),
        (snapshot) => {
          console.log(snapshot.docs.map((doc) => doc.data()));
          setCurrentMessages(snapshot.docs.map((doc) => doc.data()));
        }
      );
    }

    //   if(search!==''){
    //     onSnapshot(
    //         collection(firestore, "whatsapp/" + search),
    //         (snapshot) => {
    //           console.log(snapshot.docs.map((doc) => doc.data()));
    //           setFilterContacts(snapshot.docs.map((doc) => doc.data()));
    //         }
    //       );
    //   }
  }, [
    contactSelected,
    // search
  ]);

  function pushMessage() {
    // const index = data.findIndex((d) => d.contact?.id === contactSelected?.id);
    // const newData = Object.assign([], data, {
    //   [index]: {
    //     contact: contactSelected,
    //     messages: [
    //       ...data[index].messages,
    //       new Message(true, message, new Date()),
    //     ],
    //   },
    // });

    // setData(newData);
    var payload = {
      // "caption": "string",
      // "filename": "string",
      number: contactSelected?.id,
      // "originalUrl": "string",
      // "previewUrl": "string",
      text: message,
      type: "text",
      // "url": "string",
      userId: userLoginDetails?.autoFinanceUser?.userId,
    };

    moduleApi.postData(apiCall.sendMessage, payload).then((response) => {
      if (response) {
        if (response?.Message === "Done") {
          setMessage("");
        } else {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  }

  // function filterContacts(data, search) {
  //   const result = data?.filter(({ contact }) => {
  //     return (
  //       !search || contact.name.toLowerCase().includes(search.toLowerCase())
  //     );
  //   });
  //   setFilterContacts(result);
  // }

  const getContactsList = () => {
    // onSnapshot(collection(firestore, "whatsapp"), (snapshot) => {
    //     console.log(snapshot.docs.map((doc) => doc.data()));
    //     setFilterContacts(snapshot.docs.map((doc) => doc.data()))
    //   });
    // const q = query(collection(db, "whatsapp"), );
    const q = query(
      collection(firestore, "whatsapp"),
      orderBy("timestamp", "desc"),
      limit(10)
    );
    onSnapshot(q, (snapshot) => {
      console.log(snapshot.docs.map((doc) => doc.data()));
      setFilterContacts(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
    // onSnapshot(q, (snapshot) => {
    //   snapshot.docChanges().forEach((change) => {

    //   });
    // });
  };

  return (
    <div className="app">
      <aside>
        <header>
          <Avatar user={mainUser} />
          <div className="right-section2">
            <span style={{ alignContent: "space-evenly" }}>
              {userLoginDetails?.autoFinanceOrganization?.organizationName}
            </span>
            <div className="time-mark2">
              <div className="media-body user-meta-info" onClick={()=>{
            // close()
            window.close()
          }}>
                <ul>
                  <li>
                    <Link
                      to={"#"}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <i
                        className="fa fa-sign-out-alt"
                        style={{ fontSize: "smaller" }}
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
        <Search search={search} setSearch={setSearch} />
        <hr className="hr2"></hr>
        <div className="contact-boxes">
          {filteredContacts.map((contact, index) => (
            <div key={index}>
              <ContactBox
                contact={contact}
                setContactSelected={setContactSelected}
                // messages={messages}
              />
              <hr className="hr"></hr>
            </div>
          ))}
        </div>
      </aside>
      {contactSelected?.id ? (
        <main>
          <header>
            <Avatar user={contactSelected} showName />
          </header>
          <MessagesBox messages={currentMessages} />
          <ChatInputBox
            message={message}
            setMessage={setMessage}
            pushMessage={pushMessage}
          />
        </main>
      ) : (
        <Welcome />
      )}
    </div>
  );
}

export default Whatsapp;
