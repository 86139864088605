import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import TextField from "../CommonComponents/TextField";
import BootstrapTable from "react-bootstrap-table-next";
import defaultImage from "../images/defaultImage.png";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { useHistory } from "react-router-dom";
function Onboarding() {
  var history = useHistory();
  const [basicModal, setBasicModal] = useState(false);
  const [editOrganizationModal, setEditOrganizationModal] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState("");
  // const [basicModal1, setBasicModal1] = useState(false);
  // const [basicModal2, setBasicModal2] = useState(false);
  // const [basicModal3, setBasicModal3] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  // const [organizationList, setOrganizationList]=useState([]);
  const [onboardingList, setOnboardingList]= useState([]);
  const [addPrivilegeModal, setAddPrivilegeModal] = useState(false);
  // const [branchList,setbranchList]=useState([]);
  const phoneRegExp = "[1-9]{1}[0-9]{9}";
  var isFilePicked = "";
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const validate = Yup.object({
    organizationName: Yup.string().required("Organization Name is required"),
    orgshortcode: Yup.string().required("Org Short Code is required"),
    address: Yup.string().required("Address is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required"),
    email: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required"),
    logoutHours: Yup.string().required("Logout Hours is required"),
    logoutMinutes: Yup.string().required("Logout Minutes is required"),
    loginHours: Yup.string().required("Login Hours is required"),
    loginMinutes: Yup.string().required("Login Minutes is required"),
    maxLocationPoints: Yup.string().required("Max Location Points is required"),
    datafrequency: Yup.string().required("Data Frequency is required"),
    // onlinePaymentProvider: Yup.string().required("Online Payment Provider is required"),
  });
  const handleOk = (values) => {
    const body={
        address: values.address,
        datafrequency: values.datafrequency,
        email: values.email,
        lastBilledAt: 0,
        locationdatafrequency: values.locationdatafrequency,
        logOutHours: values.logoutHours,
        logOutMinutes: values.logoutMinutes,
        loginHours: values.loginHours,
        loginMinutes: values.loginMinutes,
        maxLocationPoints: values.maxLocationPoints,
        mobileNumber: values.mobileNumber,
        numberOfUsers: 0,
        organizationId: "",
        organizationImage: currentFile?currentFile:null,
        organizationName: values.organizationName,
        orgshortcode: values.orgshortcode,
        ornilePaymentProvider: values.onlinePaymentProvider
    };
    moduleApi.postData(apiCall.registerautofinanceUser, body).then((response) => {
      // console.log("response",response);
      setBasicModal(false);
      if(response){
        if(response.status===400){
          console.log("response",response.errrorCode)
      swal(response.message, {
        icon: "error",
      });
    }
    else if(response){
      swal("Organization Added Successfully", {
        icon: "success",
      });
      loadData();
    }
    }
    })
  };

  const updateOrganization = (values) => {

    const body={
        address: values.address,
        datafrequency: values.datafrequency,
        email: values.email,
        lastBilledAt: 0,
        locationdatafrequency: values.locationdatafrequency,
        logOutHours: values.logoutHours,
        logOutMinutes: values.logoutMinutes,
        loginHours: values.loginHours,
        loginMinutes: values.loginMinutes,
        maxLocationPoints: values.maxLocationPoints,
        mobileNumber: values.mobileNumber,
        numberOfUsers: 0,
        orgId: values.organizationId,
        organizationImage: currentFile?currentFile:null,
        organizationName: values.organizationName,
        orgshortcode: values.orgshortcode,
        ornilePaymentProvider: values.onlinePaymentProvider
    };
    moduleApi.postData(apiCall.updateautofinancerganization, body).then((response) => {
      // console.log("response",response);
      if(response){
        if(response.status===400){
          console.log("response",response.errrorCode)
      swal(response.message, {
        icon: "error",
      });
    }
    else if(response){
      setEditOrganizationModal(false);
      swal("Organization Updated Successfully", {
        icon: "success",
      });
      loadData();
    }
    }
    })
  };

  const addprivilegeValidate = Yup.object({
    privilageName: Yup.string()
      .required("Privilage Name is required"),
    
    privilegeMainGroup: Yup.string()
      .required("Privilage Main Group is required"),

    privilageGroup: Yup.string()
      .required("Privilage Group is required"),

    affects: Yup.string()
      .required("Affects is required"),
  
  });


  const addPrivilege = (values) => {

    var payload = {

      privilageName: values.privilageName,
      privilegeMainGroup: values.privilegeMainGroup,
      privilageGroup: values.privilageGroup,
      affects: values.affects,
      purpose: values.purpose,

    };

    moduleApi.postData(apiCall.addprivilege, payload).then((response) => {
      if (response) {
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          swal("Privilege Added Successfully", {
            icon: "success",
          });
          setAddPrivilegeModal(false);
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };


  // const [onboardingList, setonboardingList]=useState();
  // const [searchKey, setSearchKey] = useState("");
  const handleChange1 = (e) => {
    let files = e.target.files;
    console.log("files", e.target.files);
    if (
      (files[0].type === "image/jpg") |
      (files[0].type === "image/jpeg") |
      (files[0].type === "image/png") |
      (files[0].type === "image/JPEG") |
      (files[0].type === "image/JPG") |
      (files[0].type === "image/PNG")
    ) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setCurrentImage(reader.result);
        });
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
          let fileInfo = {
            base64: reader.result,
          };
          var basedata = fileInfo.base64.split(",");
          isFilePicked = basedata[1];

          setCurrentFile(isFilePicked);
        }; // reader.onload
      } // for
    } else {
      e.target.files = null;
      swal("Please Select image Format Only", {
        icon: "error",
        dangerMode: true,
      });
    }
  };
  useEffect(() => {
    // var userData = JSON.parse(localStorage.getItem("userDetails"));
    // setloggedInUser(userData);
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const handleClose = () => {
  //   setBasicModal2(false);
  // };
  const loadData = () => {
    moduleApi.getUserData(apiCall.getAllOrganisations).then((response) => {
      console.log("response",response);
      var listData = [];

      response?.forEach((element) => {
        let data = {
          address: element.address,
          email:element.email,
          locationdatafrequency:element.locationdatafrequency,
          logOutHours:element.logOutHours,
          logOutMinutes:element.logOutMinutes,
          loginHours:element.loginHours,
          loginMinutes:element.loginMinutes,
          maxLocationPoints:element.maxLocationPoints,
          mobileNumber:element.mobileNumber,
          organizationName:element.organizationName,
          orgshortcode:element.orgshortcode,
          ornilePaymentProvider:element.ornilePaymentProvider,
          loginTimings:element.loginHours + " : " + element.loginMinutes,
          logoutTimings:element.logOutHours + " : " + element.logOutMinutes,
          organizationId:element.organizationId,
          organizationImage:element.organizationImage,
          datafrequency:element.datafrequency,
        };
        listData.push(data);
      })
      setOnboardingList(listData);
      console.log(listData);
    })
  };
  const onboardingColumns = [
    {
      text: "Org Name",
      dataField: "organizationName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Org Short Code",
      dataField: "orgshortcode",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Email ID",
      dataField: "email",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",
      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Login Timings",
      dataField: "loginTimings",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Logout Timings",
      dataField: "logoutTimings",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit"></i>
              </Link>
            </div>
          ); 
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setOrganizationDetails(row);
          setEditOrganizationModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          //  setOrganizationList(row);
           console.log("row",row);
          // accepttheapprove(row);
          // setBasicModal1(true);
          localStorage.setItem("organization-details",row.organizationName);
          localStorage.setItem("organizationId",row.organizationId);
          history.push("/update-privilages");
        },
      },
    },
  ];
  return (
    <div className="card">
      <div style={{ display: "flex", justifyContent: "right" }}>
        <div className="fi-al-body">
        <Button
              style={{
                float: "right",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => {
                setAddPrivilegeModal(true)
              }}
            >
              <b> + Add Privilege </b>
            </Button>
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => {
              setBasicModal(true);
            }}
          >
            Add Organization
          </Button>
        </div>
      </div>
      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Add Organization</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              organizationName: "",
              orgshortcode: "",
              address: "",
              mobileNumber: "",
              email: "",
              logoutMinutes: "",
              loginMinutes: "",
              logoutHours: "",
              loginHours: "",
              maxLocationPoints: "",
              datafrequency:"",
              onlinePaymentProvider:""
            }}
            onSubmit={handleOk}
            validationSchema={validate}
          >
            {({ formik, errors }) => (
              <div>
                <Row>
                  <Col lg="12">
                    <Form>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={currentImage ? currentImage : defaultImage}
                          className="img-fluid rounded-circle"
                          alt="profile"
                          style={{
                            cursor: "pointer",
                            borderRadius: "50px",
                            height: "100px",
                            width: "100px",
                            alignSelf: "center",
                          }}
                        />
                        <input
                          style={{
                            marginLeft: "150px",
                            marginBottom: "15px",
                            marginTop: "10px",
                          }}
                          type="file"
                          accept="image/*"
                          // multiple
                          onChange={(e) => handleChange1(e)}
                        />
                        <TextField
                          isAsterisk={true}
                          label="Organization Name"
                          name="organizationName"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Org Short Code"
                          name="orgshortcode"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Address"
                          name="address"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Mobile No"
                          name="mobileNumber"
                          maxlength="10"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Email ID"
                          name="email"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Login Hours"
                          name="loginHours"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Login Minutes"
                          name="loginMinutes"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Logout Hours"
                          name="logoutHours"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Logout Minutes"
                          name="logoutMinutes"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Max Location Points"
                          name="maxLocationPoints"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Data Frequency"
                          name="datafrequency"
                          type="text"
                        />
                        <TextField
                          // isAsterisk={true}
                          label="Online Payment Provider"
                          name="onlinePaymentProvider"
                          type="text"
                        />
                      </div>
                      <div>
                        <Button
                          style={{ float: "right" }}
                          className="col-lg-2 m-2"
                          type="submit"
                        >
                          Submit
                        </Button>
                        <Button
                          style={{ float: "right" }}
                          className="col-lg-2 m-2 float-right"
                          variant="danger light"
                          onClick={() => setBasicModal(false)}
                        >
                          Close
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={editOrganizationModal}>
        <Modal.Header>
          <Modal.Title>Edit Organization</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditOrganizationModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              organizationId:organizationDetails.organizationId,
              organizationName: organizationDetails.organizationName,
              orgshortcode: organizationDetails.orgshortcode,
              address: organizationDetails.address,
              mobileNumber: organizationDetails.mobileNumber,
              email: organizationDetails.email,
              logoutMinutes: organizationDetails.logOutMinutes,
              loginMinutes: organizationDetails.loginMinutes,
              logoutHours: organizationDetails.logOutHours,
              loginHours: organizationDetails.loginHours,
              maxLocationPoints: organizationDetails.maxLocationPoints,
              datafrequency:organizationDetails.datafrequency,
              onlinePaymentProvider:organizationDetails.ornilePaymentProvider,
            }}
            onSubmit={updateOrganization}
            validationSchema={validate}
          >
            {({ formik, errors }) => (
              <div>
                <Row>
                  <Col lg="12">
                    <Form>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={organizationDetails.organizationImage ? organizationDetails.organizationImage: currentImage ? currentImage : defaultImage}
                          className="img-fluid rounded-circle"
                          alt="profile"
                          style={{
                            cursor: "pointer",
                            borderRadius: "50px",
                            height: "100px",
                            width: "100px",
                            alignSelf: "center",
                          }}
                        />
                        <input
                          style={{
                            marginLeft: "150px",
                            marginBottom: "15px",
                            marginTop: "10px",
                          }}
                          type="file"
                          accept="image/*"
                          // multiple
                          onChange={(e) => handleChange1(e)}
                        />
                        <TextField
                          isAsterisk={true}
                          label="Organization Name"
                          name="organizationName"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Org Short Code"
                          name="orgshortcode"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Address"
                          name="address"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Mobile No"
                          name="mobileNumber"
                          maxlength="10"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Email ID"
                          name="email"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Login Hours"
                          name="loginHours"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Login Minutes"
                          name="loginMinutes"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Logout Hours"
                          name="logoutHours"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Logout Minutes"
                          name="logoutMinutes"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Max Location Points"
                          name="maxLocationPoints"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="Data Frequency"
                          name="datafrequency"
                          type="text"
                        />
                        <TextField
                          // isAsterisk={true}
                          label="Online Payment Provider"
                          name="onlinePaymentProvider"
                          type="text"
                        />
                      </div>
                      <div>
                        <Button
                          style={{ float: "right" }}
                          className="col-lg-2 m-2"
                          type="submit"
                        >
                          Submit
                        </Button>
                        <Button
                          style={{ float: "right" }}
                          className="col-lg-2 m-2 float-right"
                          variant="danger light"
                          onClick={() => setEditOrganizationModal(false)}
                        >
                          Close
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <div
        style={{
          overflow: "auto",
          maxHeight: "500px",
          margin: "10px",
        }}
      >
        <BootstrapTable
          keyField="panNo"
          data={onboardingList}
          columns={onboardingColumns}
        />
      </div>
      <Modal className="fade" show={addPrivilegeModal}>
        <Modal.Header>
          <Modal.Title>Add Privilege</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setAddPrivilegeModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              privilageName: "",
              privilegeMainGroup: "",
              privilageGroup: "",
              affects: "",
              purpose: "",

            }}
            onSubmit={addPrivilege}
            validationSchema={addprivilegeValidate}
          >
            {({ errors }) => (
              <Form>
                <Row>
                  <Col lg="12">
                    <TextField
                      isAsterisk={true}
                      label="Privilege Name"
                      name="privilageName"
                      type="text"
                      className="form-control"
                    />

                    <TextField
                      isAsterisk={true}
                      label="Privilege Main Group"
                      name="privilegeMainGroup"
                      type="text"
                      className="form-control"
                    />

                    <TextField
                      isAsterisk={true}
                      label="Privilege Group"
                      name="privilageGroup"
                      type="text"
                      className="form-control"
                    />

                    <TextField
                      isAsterisk={true}
                      label="Affects"
                      name="affects"
                      type="text"
                      className="form-control"
                    />

                    <TextField
                      isAsterisk={false}
                      label="Purpose"
                      name="purpose"
                      type="text"
                      className="form-control"
                    />
                    <div>
                      <Button
                        style={{ float: "right" }}
                        className="col-lg-2 m-2"
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ float: "right" }}
                        className="col-lg-2 m-2 float-right"
                        variant="danger light"
                        onClick={() => {
                          setAddPrivilegeModal(false);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* <Modal className="fade" show={basicModal1} size="lg">
        <Modal.Header>
          <Modal.Title>Organization Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal1(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "20px" }}>
          <Button
            className=""
            variant="success btn-rounded"
            style={{
              backgroundColor: "#218a3f",
              borderColor: "#218a3f",
              borderRadius: "5px",
              float: "right",
              margin:"10px"
              // height: "35px",
              // width: "170px",
            }}
            onClick={() => {
              setBasicModal2(true);
            }}
          >
            Add Branch
          </Button>
          <Button
            className="me-2"
            variant="primary btn-rounded"
            style={{float:"right", margin:"10px"}}
            onClick={() => {
              setBasicModal3(true);
            }}
          >
            Add Roles & Privileges
          </Button>
            </div>
            <div
        style={{
          // overflow: "auto",
          maxHeight: "500px",
          marginTop: "10px",
        }}
      >
        </div>
        </Modal.Body>
        </Modal> */}
          {/* <Modal className="fade" size="xl" show={basicModal2}>
        <Modal.Header>
          <Modal.Title>Upload Branch/Employee Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => handleClose()}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
        >
          <AddBranch close={handleClose} organizationList={organizationList}/>
        </Modal.Body>
      </Modal> */}
        {/* <Modal className="fade" size="xl" show={basicModal3}>
        <Modal.Header>
          <Modal.Title>Add Roles & Privileges</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal3(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
        >
          <AddRoles/>
        </Modal.Body>
      </Modal> */}
    </div>

  );
}

export default Onboarding;
