import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { Col, Row } from "reactstrap";
import { Button } from "react-bootstrap";
import TextField from "../CommonComponents/TextField";
import * as Yup from "yup";
import { toaster } from "../rest/apiUtils";
import { apiCall } from "../rest/restApi";
import * as nodeApi from "../rest/nodeApi";
import ReactGA from "react-ga";

const AddAgency = ({ close, getAgenciesData, action, editPopulateData, agencyProfile }) => {
  const [loggedInUser, setloggedInUser] = useState([]);

  useEffect(() => {
    ReactGA.pageview('Add Agency');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phoneRegExp = "[1-9]{1}[0-9]{9}";
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  //var nameRegex = "[a-zA-Z ]+$";

  const validate = Yup.object({
    agencyName: Yup.string()
      .trim()
      .required("Agency Name is required")
      .max(50, "Agency name should not exceed more than 50 characters")
      .test(
        "Unique Agency Name",
        "Agency Name already in use",
        function (value) {
          return new Promise((resolve, reject) => {
            if (initialValues.agencyName === value && action !== "ADD") {
              resolve(true);
            } else {
              nodeApi
                .getData(apiCall.validateAgencies, "name/" + value)
                .then((response) => {
                  if (response.success === true) {
                    if (response.alreadyExists === true) {
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  }
                });
            }
          });
        }
      ),
    email: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required")
      .test("Unique Email", "This Email is already in use", function (value) {
        return new Promise((resolve, reject) => {
          if (initialValues.email === value && action !== "ADD") {
            resolve(true);
          } else {
            nodeApi
              .getData(apiCall.validateAgencies, "email/" + value)
              .then((response) => {
                if (response.success === true) {
                  if (response.alreadyExists === true) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                }
              });
          }
        });
      }),
    contactNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required")
      .test(
        "Unique Contact Number",
        "This Mobile Number is already in use",
        function (value) {
          return new Promise((resolve, reject) => {
            if (initialValues.contactNumber === value && action !== "ADD") {
              resolve(true);
            } else {
              nodeApi
                .getData(apiCall.validateAgencies, "contactNo/" + value)
                .then((response) => {
                  if (response.success === true) {
                    if (response.alreadyExists === true) {
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  }
                });
            }
          });
        }
      ),
    officeAddress: Yup.string().required("Address is required"),
  });

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);
  }, []);

  const handleSubmit = (values) => {
    let requestPayload = {};
    if (agencyProfile) {
      requestPayload = {
        name: values.agencyName,
        contactNumber: values.contactNumber,
        email: values.email,
        officeAddress: values.officeAddress,
        status: "ACTIVE",
      }
    } else {
      requestPayload = {
        name: values.agencyName,
        contactNumber: values.contactNumber,
        email: values.email,
        officeAddress: values.officeAddress,
        status: "ACTIVE",
        addedByUserId: loggedInUser.autoFinanceUser.userId,
        organizationId: loggedInUser.autoFinanceUser.organizationId.trim(),
        password: 'Njk+MT4zNWE='
      };
    }

    if (action === "ADD") {
      nodeApi
        .postData(apiCall.agencies, requestPayload)
        .then(() => {
          getAgenciesData();
          close();
          toaster("success", "Successfully Added");
        })
        .catch((error) => {
          console.log(error);
          close();
          toaster("error", "Something Went Wrong, Please try again later");
        });
    } else {
      nodeApi
        .updateData(
          apiCall.agencies,
          requestPayload,
          editPopulateData.agencyId
        )
        .then(() => {
          getAgenciesData();
          close();
          toaster("success", "Successfully Updated");
        })
        .catch((error) => {
          close();
          toaster("error", "Something Went Wrong, Please try again later");
        });
    }
  };
  const initialValues =
    action === "ADD"
      ? {
        agencyName: "",
        contactNumber: "",
        email: "",
        officeAddress: "",
      }
      : {
        agencyName: editPopulateData.agencyName,
        contactNumber: editPopulateData.mobileNumber,
        email: editPopulateData.email,
        officeAddress: editPopulateData.officeAddress,
      };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ formik, errors }) => (
          <div>
            <Row>
              <Col lg="12">
                <Form>
                  <div>
                    <TextField
                      isAsterisk={true}
                      label="Agency Name"
                      name="agencyName"
                      type="text"
                      disabled={action === "ADD" ? false : true}
                    />
                    <TextField
                      isAsterisk={true}
                      label="Contact No"
                      name="contactNumber"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Email"
                      name="email"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Office Address"
                      name="officeAddress"
                      type="text"
                    />
                  </div>
                  <div>
                    <Button
                      className="col-lg-2 m-2 float-right px-3"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      className="col-lg-2 m-2 float-right px-3"
                      variant="danger light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddAgency;
