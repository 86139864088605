import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Modal, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import * as helper from "../CommonComponents/Helper";
import defaultImage from "../images/defaultImage.png";
// import { toaster } from "../rest/apiUtils";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess } from "../services/AuthService";
import SelectExecutives from "./SelectExecutives";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import * as XLSX from "xlsx";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import "../Agencies/agencies.css";
import ReactGA from "react-ga";
// import ExcelExportHelper from "../CommonComponents/ExportExcelHelper";
// import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const Report = () => {
  const [scheduledReportsData, setScheduledReportsData] = useState([]);
  const [scheduledReportType, setScheduledReportType] = useState(null);
  const [scheduledPeriodType, setScheduledPeriodType] = useState("WEEKLY");
  const [time, setTime] = useState(1);
  const [email, setEmail] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [employeeMetricsData, setEmployeeMetricsData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [summaryData2, setSummaryData2] = useState([]);
  const [keys, setKeys] = useState([]);
  // const [values, setValues] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedExecutives, setSelectedExecutives] = useState([]);
  const [selectedExecutivesCount, setSelectedExecutivesCount] = useState(0);
  const [activeUsers, setActiveUsers] = useState(true);
  const [type, setType] = useState("All");
  const [reportCategory, setReportCategory] = useState("DETAILED");
  const [startDate, setStartDate] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0)
      ).getTime() / 1000
    )
  );
  const [endDate, setEndDate] = useState(
    Math.floor(
      new Date(
        new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59)
      ).getTime() / 1000
    )
  );
  const [t, setT] = useState("All");
  const [enteredLoanNumber, setEnteredLoanNumber] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [slctdID, setSlctdID] = useState(1);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [isFields, setIsFields] = useState(false);
  const [flag, setFlag] = useState(false);
  const [isNoData, setIsNodata] = useState(false);
  const [isReportType, setIsReportType] = useState(false);
  const [isCpNames, setIsCpNames] = useState(false);
  const [isCumulative, setIsCumulative] = useState(0);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [dropDownOptions, setDropDownOptions] = useState([]);
  // const [perpage, setPerpage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [modalTitle, setModalTitle] = useState("Execuitves");
  const [attendanceData, setAttendanceData] = useState(null);
  const [employeeMetricsDownloadData, setEmployeeMetricsDownloadData] =
    useState(null);
  const [fieldInvstgtnData, setFieldInvstgtnData] = useState(null);
  const [collectionsData, setCollectionsData] = useState(null);
  const [repossessedData, setRepossessedData] = useState(null);
  const [callHistoryData, setCallHistoryData] = useState(null);
  const [dispositionReportData, setDispositionReportData] = useState(null);
  const [salesData, setSalesData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [summaryOthersData, setSummaryOthersData] = useState(null);
  const [sumamryAttendanceData, setSummaryAttendanceData] = useState(null);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [isMap, setIsMap] = useState(false);
  // const [tempData, setTempData] = useState(null);

  // const worker = new Worker("../service-worker.js");

  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (slctdID === "5") {
  //     setType("Collections");
  //     // setT("Collections");
  //     setIsReportType(true);
  //     console.log("first", slctdID);
  //   } else {
  //     setIsReportType(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [slctdID]);

  useEffect(() => {
    var userLoginData = JSON.parse(localStorage.getItem("userDetails"));
    if (!userLoginData) return;
    if (userLoginData?.autoFinanceUser?.roleName !== "ADMIN") {
      setSlctdID(0);
    } else {
      setSlctdID(1);
    }
    setDateValue(null, 1);
    addDropDownOptions();
    if (loggedInUser?.autoFinanceUser?.roleName === "CP_USER") {
      setType("Collections");
      setT("Collections");
      setReportCategory("DETAILED");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (attendanceData !== null ||
      employeeMetricsDownloadData !== null ||
      fieldInvstgtnData !== null ||
      collectionsData !== null ||
      repossessedData !== null ||
      salesData !== null ||
      allData !== null ||
      callHistoryData !== null ||
      dispositionReportData !== null ||
      summaryOthersData !== null ||
      sumamryAttendanceData !== null) &&
      handleExport();
    // .then((url) => {
    //   // console.log(url);
    //   const downloadAnchorNode = document.createElement("a");
    //   downloadAnchorNode.setAttribute("href", url);
    //   downloadAnchorNode.setAttribute(
    //     "download",
    //     // "+new Date().toLocaleString('en-US', { hour12: false })+"
    //     "Clu_" +
    //       (reportCategory === "DETAILED" ? "Detailed" : "Summary") +
    //       "_" +
    //       type +
    //       "_Report_" +
    //       new Date().toLocaleString("en-US") +
    //       ".xlsx"
    //   );
    // downloadAnchorNode.click();
    // downloadAnchorNode.remove();
    setIsLoading2(false);
    // });
    // eslint-disable-next-line
  }, [
    attendanceData,
    employeeMetricsDownloadData,
    fieldInvstgtnData,
    collectionsData,
    repossessedData,
    salesData,
    allData,
    callHistoryData,
    dispositionReportData,
    summaryOthersData,
    sumamryAttendanceData,
  ]);

  // const tempButton = () => {
  //   // const myWorker = new Worker('../worker2.js');
  //   // myWorker.postMessage("start");
  //   // myWorker.onmessage = (e) => {
  //   //   console.log("Time Taken", e.data);
  //   // };
  //   const workerCode = `
  //   const double = (value) => value * 2;

  //   onmessage = (e) => {
  //     let sum = 0;
  //     for(let i=0;i<100000000000000;i++)
  //     {
  //       sum+=i
  //     }
  //     postMessage(sum);
  //   };
  // `;

  // const blob = new Blob([workerCode], { type: 'application/javascript' });
  // const worker = new Worker(URL.createObjectURL(blob));

  // worker.onmessage = (e) => {
  //   const result = e.data;
  //   console.log('Received result from worker:', result);
  // };

  // // Send data to the worker
  // worker.postMessage(125);

  // // let sum = 0;
  // // for(let i=0;i<100000000000000000;i++)
  // // {
  // //   sum+=i
  // // }
  // // console.log(sum);

  // // Terminate the worker when done
  // return () => {
  //   worker.terminate();
  // };

  // };

  const addDropDownOptions = () => {
    var opts = [];
    hasAccess(3) && opts.push({ label: "Sales", value: "Sales" });
    hasAccess(4) && opts.push({ label: "Collections", value: "Collections" });
    hasAccess(5) &&
      opts.push({ label: "Field Investigation", value: "FieldInvestigation" });
    hasAccess(13) && opts.push({ label: "Attendance", value: "Attendance" });
    hasAccess(2) && opts.push({ label: "Leave", value: "Leave" });
    hasAccess(1) && opts.push({ label: "Expense", value: "Expense" });
    setDropDownOptions(opts);
  };

  const onTypeChange = (e) => {
    setType(e);
    // loggedInUser.autoFinanceUser.roleName === "CP_USER" && setT(e);
  };

  const onReportCategoryChange = (e) => {
    // if (type !== "All" && type !== "Collections" && type !== "Sales")
    //   setType("All");
    if (e === "DETAILED") {
      setIsCumulative(0);
    } else if ((slctdID === "3" || slctdID === "4") && e === "SUMMARY") {
      setIsCumulative(1);
      if (slctdID === "4") {
        setIsCumulative(2);
      }
    }

    if (e === "SUMMARY" && type === "EmployeePerformanceMetrics") {
      setType("All");
    }

    setReportCategory(e);
  };

  const getScheduledReports = (curPage) => {
    moduleApi
      .getData(
        apiCall.getScheduledReports + loggedInUser.autoFinanceUser.userId
      )
      .then((response) => {
        setScheduledReportsData(response);
      });
  };

  const addScheduledReport = () => {
    if (scheduledReportType == null)
      return swal("Report Type Cannot be Empty", {
        icon: "error",
        dangerMode: true,
      });
    if (!email) return;
    swal("Email ID Cannot be Empty", {
      icon: "error",
      dangerMode: true,
    });

    if (!emailPattern.test(email))
      return swal("Enter Valid Email ID", {
        icon: "error",
        dangerMode: true,
      });

    var payload = {
      email: email,
      hour: time,
      reportType: scheduledReportType,
      scheduleTypes: scheduledPeriodType,
      userId: loggedInUser.autoFinanceUser.userId,
    };
    moduleApi.postData(apiCall.addScheduledReport, payload).then((response) => {
      swal("Added Successfully", {
        icon: "success",
      });
      setIsFields(false);
      setScheduledReportType(null);
      setScheduledPeriodType("WEEKLY");
      setEmail(null);
      setTime(1);
      getScheduledReports();
    });
  };

  const onSubmit = (startDate, endDate, curPage, download) => {
    if (loggedInUser.autoFinanceUser.roleName !== "CP_USER") {
      if (reportCategory === "DETAILED") {
        setFlag(false);
      } else {
        setFlag(true);
      }

      if (selectedExecutives.length === 0) {
        swal("Please select atleast 1 user", {
          icon: "error",
          dangerMode: true,
        });
        return;
      }
    }
    if (!startDate || !endDate) {
      swal("Please select the dates", {
        icon: "error",
        dangerMode: true,
      });
      return;
    }
    setIsLoading(true);
    if (isReportType === true) {
      setType("Collections");
      setT("Collections");
      setIsCpNames(true);
    } else {
      setIsCpNames(false);
    }

    const payLoad = {
      downloadExcel: download,
      endtime: endDate,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportCategory: reportCategory,
      reportType: !isReportType ? type : "Collections",
      reportingOfficerId: "",
      reportsRequestedUserId: loggedInUser.autoFinanceUser.userId,
      starttime: startDate,
      // eslint-disable-next-line eqeqeq
      designations: slctdID == 4 ? selectedExecutives : [],
      // eslint-disable-next-line eqeqeq
      userIds: slctdID == 1 || slctdID == 0 ? selectedExecutives : [],
      // eslint-disable-next-line eqeqeq
      reportingOfficerIds: slctdID == 2 ? selectedExecutives : [],
      // eslint-disable-next-line eqeqeq
      branchIds: slctdID == 3 ? selectedExecutives : [],
      // eslint-disable-next-line eqeqeq
      cpNames: slctdID == 5 ? selectedExecutives : [],
    };

    const cpUsersPayLoad = {
      // downloadExcel: false,
      endtime: endDate,
      organizationId: loggedInUser?.autoFinanceOrganization?.mainOrganizationId,
      reportCategory: "DETAILED",

      reportType: type,
      searchKey: enteredLoanNumber,
      // reportingOfficerId: "",
      // reportsRequestedUserId: "9f5adc7e-ffba-4e56-83c9-10325291baef",
      starttime: startDate,
      cpName: loggedInUser?.autoFinanceOrganization?.cpName,
      // designations: [],
      // userIds: ["777595d9-52bc-41dc-a48a-3ef310dd2407"],
      // reportingOfficerIds: [],
      // branchIds: [],
    };

    setCurrPage(curPage);
    // moduleApi
    //   .postData(moduleApi.withPage(apiCall.reports, curPage, 10), payLoad)
    //   .then((response) => {
    moduleApi
      .postData(
        moduleApi.withPage(
          loggedInUser.autoFinanceUser.roleName === "CP_USER" &&
            type === "Collections"
            ? apiCall.getReportsBaseedOnCpName
            : apiCall.reports,
          curPage,
          10
        ),
        loggedInUser.autoFinanceUser.roleName !== "CP_USER"
          ? payLoad
          : cpUsersPayLoad
      )
      .then((response) => {
        if (download) {
          setIsLoading(false);
          return;
        }
        if (reportCategory === "DETAILED") {
          if (type === "Attendance") {
            // console.log("attendane", response.content);
            setData2(response.content);
          } else if (type === "EmployeePerformanceMetrics") {
            // console.log("attendane", response.content);
            setEmployeeMetricsData(response.content);
          } else if (type === "FieldInvestigation") {
            // console.log("FieldInvestigation", response.content);

            setData3(response.content);
          } else {
            // console.log("others", response.content);
            if (
              type === "CallDispositionSummaryByLoanNumber" ||
              type === "CallDispositionSummaryByAgent"
            ) {
              const transformedArray = [];
              response?.content?.forEach((item) => {
                const transformedObject = {};
                // transformedObject["Date"] = item?.date
                //   ? helper.EpochToOnlyDate(item?.date)
                //   : "-";
                transformedObject["Loan Number"] = item?.loanNumber;
                // transformedObject["Emp Id"] = item?.employeeId;
                // transformedObject["Emp Name"] = item?.empName;
                // transformedObject["Emp Status"] = item?.empStatus;
                transformedObject["Total Calls"] =
                  item?.noDisposition +
                  item?.callConnected +
                  item?.callNotConnected;
                transformedObject["Disposition Not Entered"] =
                  item?.noDisposition;
                transformedObject["Call Connected"] = item?.callConnected;
                transformedObject["Call Not Connected"] =
                  item?.callNotConnected;
                // Iterate through the nested objects dynamically
                Object.keys(item?.statusCounts).forEach((statusType) => {
                  Object.keys(item?.statusCounts[statusType]).forEach(
                    (status) => {
                      transformedObject[status] = item?.statusCounts[
                        statusType
                      ][status]
                        ? item?.statusCounts[statusType][status]
                        : 0;
                    }
                  );
                });

                transformedArray.push(transformedObject);
              });
              // if (type === "CallDipositionSummary") {
              //   for (let i = 0; i < transformedArray?.length; i++) {
              //     delete transformedArray[i]["Loan Number"];
              //     delete transformedArray[i]["Emp Id"];
              //     delete transformedArray[i]["Emp Name"];
              //     delete transformedArray[i]["Emp Status"];
              //   }
              // } else if (type === "CallDispositionSummaryByLoanNumber") {
              //   for (let i = 0; i < transformedArray?.length; i++) {
              //     delete transformedArray[i]["Date"];
              //     delete transformedArray[i]["Emp Id"];
              //     delete transformedArray[i]["Emp Name"];
              //     delete transformedArray[i]["Emp Status"];
              //   }
              // } else if (type === "CallDispositionSummaryByAgent") {
              //   for (let i = 0; i < transformedArray?.length; i++) {
              //     delete transformedArray[i]["Date"];
              //     delete transformedArray[i]["Loan Number"];
              //   }
              // }
              // console.log(transformedArray);
              const uniqueKeys = new Set();

              transformedArray?.forEach((obj, index) => {
                if (obj !== null) {
                  // console.log("index", index);
                  Object.keys(obj).forEach((key) => {
                    uniqueKeys.add(key);
                  });
                }
              });

              let keys = Array.from(uniqueKeys);
              setKeys(keys);
              setData(transformedArray);
            } else {
              //collections
              setData(response.content);
            }
          }
        } else if (type !== "Attendance") {
          const uniqueKeys = new Set();

          response?.forEach((obj, index) => {
            if (type !== "Repossessed") {
              if (obj?.collectionStatusCounts !== null) {
                // console.log("index", index);
                Object.keys(obj?.collectionStatusCounts).forEach((key) => {
                  uniqueKeys.add(key);
                });
              }
            } else if (obj?.repossessedDetails !== null) {
              Object.keys(obj?.repossessedDetails).forEach((key) => {
                uniqueKeys.add(key);
              });
            }
          });

          let keys = Array.from(uniqueKeys);
          // console.log("others", keys);
          setKeys(keys);
          setSummaryData(response);
          // setPerpage(summaryData?.slice(0, 10));
        } else {
          // console.log("attendance", response);

          setSummaryData2(response);
        }
        !flag
          ? setTotalElements(response.totalElements)
          : setTotalElements(response.length);
        setT(type);
        setIsLoading(false);
        // createDownLoadData();
      });
    setIsNodata(true);
  };

  // const handleOnExport = () => {
  //   var wb = XLSX.utils.book_new(),
  //     ws = XLSX.utils.json_to_sheet(data);
  //   XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
  //   XLSX.writeFile(wb, "MyExcel.xlsx");
  // };

  //Download Section
  const createDownLoadData = () => {
    // var attendanceListData = [];
    // var fieldInvstgtnListData = [];
    // var collectionsListData = [];
    // var salesListData = [];
    // var allListData = [];
    var summaryListData = [];
    var summaryAttendanceListData = [];
    // var tempList = [];

    if (!startDate || !endDate) {
      swal("Please select the dates", {
        icon: "error",
        dangerMode: true,
      });
      return;
    }
    if (loggedInUser.autoFinanceUser.roleName !== "CP_USER") {
      if (selectedExecutives.length === 0) {
        swal("Please select atleast 1 executive", {
          icon: "error",
          dangerMode: true,
        });
        return;
      }
    }

    setIsLoading2(true);
    const payLoad = {
      downloadExcel: false,
      endtime: endDate,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportCategory: reportCategory,
      reportType: !isReportType ? type : "Collections",
      reportingOfficerId: "",
      reportsRequestedUserId: loggedInUser.autoFinanceUser.userId,
      starttime: startDate,
      // eslint-disable-next-line eqeqeq
      designations: slctdID == 4 ? selectedExecutives : [],
      // eslint-disable-next-line eqeqeq
      userIds: slctdID == 1 || slctdID == 0 ? selectedExecutives : [],
      // eslint-disable-next-line eqeqeq
      reportingOfficerIds: slctdID == 2 ? selectedExecutives : [],
      // eslint-disable-next-line eqeqeq
      branchIds: slctdID == 3 ? selectedExecutives : [],
      // eslint-disable-next-line eqeqeq
      cpNames: slctdID == 5 ? selectedExecutives : [],
    };

    const cpUsersPayLoad = {
      // downloadExcel: false,
      endtime: endDate,
      organizationId: loggedInUser?.autoFinanceOrganization?.mainOrganizationId,
      reportCategory: "DETAILED",

      reportType: type,
      searchKey: enteredLoanNumber,
      // reportingOfficerId: "",
      // reportsRequestedUserId: "9f5adc7e-ffba-4e56-83c9-10325291baef",
      starttime: startDate,
      cpName: loggedInUser?.autoFinanceOrganization?.cpName,
      // designations: [],
      // userIds: ["777595d9-52bc-41dc-a48a-3ef310dd2407"],
      // reportingOfficerIds: [],
      // branchIds: [],
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          loggedInUser.autoFinanceUser.roleName === "CP_USER" &&
            type === "Collections"
            ? apiCall.getReportsBaseedOnCpName
            : apiCall.reports,
          0,
          10
        ),
        loggedInUser.autoFinanceUser.roleName !== "CP_USER"
          ? payLoad
          : cpUsersPayLoad
      )
      .then((response) => {
        // if (download) {
        //   setIsLoading(false);
        //   return;
        // }
        // for(let i=0;i<response?.totalElements;i++)
        // console.log("response", response?.content);
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading2(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                if (reportCategory === "DETAILED") {
                  setIsLoading2(true);
                  getFullResponseData(Math.floor(response.totalElements / 300));
                }
              }
            });
          }

          if (reportCategory === "DETAILED") {
            getFullResponseData(Math.floor(response.totalElements / 300));
          } else {
            if (type !== "Attendance") {
              response.forEach((element) => {
                let data = {
                  employeeId: element.employeeId ? element.employeeId : "-",
                  employeeName: element.employeeName
                    ? element.employeeName
                    : "-",
                  empStatus: element.empStatus ? element.empStatus : "-",
                  cpName: element.cpName ? element.cpName : "-",
                  designation: element.designation ? element.designation : "-",
                  branchName: element.branchName ? element.branchName : "-",
                  empMobileNumber: element.empMobileNumber
                    ? element.empMobileNumber
                    : "-",
                  salesCount: element.salesCount ? element.salesCount : 0,
                  // ptpCount: element.ptpCount ? element.ptpCount : 0,
                  // // partiallyPaid: element.partiallyPaid
                  // //   ? element.partiallyPaid
                  // //   : 0,
                  // paidCount: element.paidCount ? element.paidCount : 0,
                  // rtpCount: element.rtpCount ? element.rtpCount : 0,
                  repossessedCount: element.repossessedCount
                    ? element.repossessedCount
                    : 0,
                  // thirdPartyCount: element.thirdPartyCount
                  //   ? element.thirdPartyCount
                  //   : 0,
                  collectionStatusCounts: element.collectionStatusCounts,
                  repossessedDetails: element.repossessedDetails,
                  // vacantCount: element.vacantCount ? element.vacantCount : 0,
                  // customerNotAvaible: element.customerNotAvaible
                  //   ? element.customerNotAvaible
                  //   : 0,
                  collectionCount: element.collectionCount
                    ? element.collectionCount
                    : 0,
                  amountCollected: element.amountCollected
                    ? element.amountCollected
                    : 0,
                  positiveCount: element.positiveCount
                    ? element.positiveCount
                    : 0,
                  negativeCount: element.negativeCount
                    ? element.negativeCount
                    : 0,
                  fieldInvestigationCount: element.fieldInvestigationCount
                    ? element.fieldInvestigationCount
                    : 0,
                  duplicateCount: element.duplicateCount
                    ? element.duplicateCount
                    : 0,
                  totalDuplicateVisitCounts: element.totalDuplicateVisitCounts
                    ? element.totalDuplicateVisitCounts
                    : 0,

                  totalVisits:
                    element.collectionCount +
                    element.salesCount +
                    element.fieldInvestigationCount,
                };
                summaryListData.push(data);
              });
              setSummaryOthersData(summaryListData);
            } else {
              response.forEach((element) => {
                let data = {
                  employeeId: element.employeeId ? element.employeeId : "-",
                  employeeName: element.employeeName
                    ? element.employeeName
                    : "-",
                  empStatus: element.empStatus ? element.empStatus : "-",
                  designation: element.designation ? element.designation : "-",
                  branchName: element.branchName ? element.branchName : "-",
                  empMobileNumber: element.empMobileNumber
                    ? element.empMobileNumber
                    : "-",
                  daysCheckedIn: element.daysCheckedIn
                    ? element.daysCheckedIn
                    : 0,
                  daysNotCheckedIn: element.daysNotCheckedIn
                    ? element.daysNotCheckedIn
                    : 0,
                  totalTime: element.totalTime
                    ? secondsToHms(element.totalTime)
                    : 0,
                  distanceTravelled: element.distanceTravelled
                    ? getDistTravelledInKM(element.distanceTravelled)
                    : 0,
                  visitCount:element.visitCount ? element.visitCount :0,
                };
                summaryAttendanceListData.push(data);
              });
              setSummaryAttendanceData(summaryAttendanceListData);
            }
          }
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
        // }
        setT(type);
      });
  };

  async function getFullResponseData(size) {
    var tempList = [];
    var allListData = [];
    var attendanceListData = [];
    var empPerformanceListData = [];
    var fieldInvstgtnListData = [];
    var collectionsListData = [];
    var repossessedListData = [];
    var callHistoryListData = [];
    var salesListData = [];
    // var allListData = [];
    // var summaryListData = [];
    // var summaryAttendanceListData = [];
    // console.log('ggggg', temp);
    for (let i = 0; i <= size; i++) {
      const payLoad = {
        downloadExcel: false,
        endtime: endDate,
        organizationId: loggedInUser.autoFinanceUser.organizationId,
        reportCategory: reportCategory,
        reportType: !isReportType ? type : "Collections",
        reportingOfficerId: "",
        reportsRequestedUserId: loggedInUser.autoFinanceUser.userId,
        starttime: startDate,
        // eslint-disable-next-line eqeqeq
        designations: slctdID == 4 ? selectedExecutives : [],
        // eslint-disable-next-line eqeqeq
        userIds: slctdID == 1 || slctdID == 0 ? selectedExecutives : [],
        // eslint-disable-next-line eqeqeq
        reportingOfficerIds: slctdID == 2 ? selectedExecutives : [],
        // eslint-disable-next-line eqeqeq
        branchIds: slctdID == 3 ? selectedExecutives : [],
        // eslint-disable-next-line eqeqeq
        cpNames: slctdID == 5 ? selectedExecutives : [],
      };

      const cpUsersPayLoad = {
        // downloadExcel: false,
        endtime: endDate,
        organizationId:
          loggedInUser?.autoFinanceOrganization?.mainOrganizationId,
        reportCategory: "DETAILED",
        reportType: type,
        searchKey: enteredLoanNumber,
        // reportingOfficerId: "",
        // reportsRequestedUserId: "9f5adc7e-ffba-4e56-83c9-10325291baef",
        starttime: startDate,
        cpName: loggedInUser?.autoFinanceOrganization?.cpName,
        // designations: [],
        // userIds: ["777595d9-52bc-41dc-a48a-3ef310dd2407"],
        // reportingOfficerIds: [],
        // branchIds: [],
      };

      await moduleApi
        .postData(
          moduleApi.withPage(
            loggedInUser.autoFinanceUser.roleName === "CP_USER" &&
              type === "Collections"
              ? apiCall.getReportsBaseedOnCpName
              : apiCall.reports,
            i,
            300
          ),
          loggedInUser.autoFinanceUser.roleName !== "CP_USER"
            ? payLoad
            : cpUsersPayLoad
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }
    // setTempData(tempList);
    // console.log("forloop", tempList, "type - ", type);

    if (reportCategory === "DETAILED") {
      if (type === "Attendance") {
        // console.log("attendane", tempList);
        // setData2(response.content);
        tempList?.forEach((element) => {
          let data = {
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            designation: element.designation ? element.designation : "-",
            branchName: element.branchName ? element.branchName : "-",
            empMobileNumber: element.empMobileNumber
              ? element.empMobileNumber
              : "-",
            date: element.date ? element.date : "-",
            area: element.area ? element.area : "-",
            visitNotDoneAtCustLocation:
            element.customerLatitude !== 0 && element.customerLongitude !== 0
                  ? 
              element.visitNotDoneAtCustLocation === false ? "Yes" : "No":"NA",
            disFromCustLocation: element.disFromCustLocation
              ? element.disFromCustLocation
              : "-",
            phoneMake: element.phoneMake ? element.phoneMake : "-",
            phoneModel: element.phoneModel ? element.phoneModel : "-",
            userStatus: element.userStatus ? element.userStatus : "-",
            employeeAttendenceStatus: element.employeeAttendenceStatus
              ? element.employeeAttendenceStatus
              : "-",
            loginTime:
              element.loginTime === 0
                ? "-"
                : helper.EpochToTime(element.loginTime),
            checkInAddress:
              element.checkInAddress === null ? "-" : element.checkInAddress,
            logoutTime:
              element.logoutTime === 0
                ? "-"
                : helper.EpochToTime(element.logoutTime),
            checkOutAddress:
              element.checkOutAddress === null ? "-" : element.checkOutAddress,
            actualWorkingHours: element.actualWorkingHours
              ? secondsToHms(element.actualWorkingHours)
              : "00:00:00",
            timeDifference: element.timeDifference
              ? secondsToHms(element.timeDifference)
              : "00:00:00",
            distanceTravelled: element.distanceTravlled
              ? getDistTravelledInKM(element.distanceTravlled)
              : "0",
            visitCount:element.visitCount ? element.visitCount :0,
          };
          attendanceListData.push(data);
        });
        setAttendanceData(attendanceListData);
      } else if (type === "EmployeePerformanceMetrics") {
        tempList?.forEach((element) => {
          let data = {
            empId: element.empId ? element.empId : "-",
            empName: element.empName ? element.empName : "-",
            userId: element.userId ? element.userId : "-",
            monthYear: element.monthYear ? element.monthYear : "-",
            contactRate: element.contactRate ? element.contactRate : 0,
            collectionRate: element.collectionRate ? element.collectionRate : 0,
            collectionDay: element.collectionDay ? element.collectionDay : "-",
            avgCollectionTimePct: element.avgCollectionTimePct
              ? element.avgCollectionTimePct
              : 0,
          };
          empPerformanceListData.push(data);
        });
        setEmployeeMetricsDownloadData(empPerformanceListData);
      } else if (type === "FieldInvestigation") {
        // console.log("FieldInvestigation", tempList);
        tempList?.forEach((element) => {
          let data = {
            address: element.address ? element.address : "-",
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            empStatus: element.userModel?.status
              ? element.userModel.status
              : "-",
            designation: element.designation ? element.designation : "-",
            area: element.area ? element.area : "-",
            branchName: element.branchName ? element.branchName : "-",
            branchLocation: element.branchLocation
              ? element.branchLocation
              : "-",
            l2ManagerEmpId: element.l2ManagerEmpId
              ? element.l2ManagerEmpId
              : "-",
            l2ManagerName: element.l2ManagerName ? element.l2ManagerName : "-",
            l3ManagerEmpId: element.l3ManagerEmpId
              ? element.l3ManagerEmpId
              : "-",
            l3ManagerName: element.l3ManagerName ? element.l3ManagerName : "-",
            customerLocationChanged:
              element?.customerLocationChanged === true ? "Yes" : "No",
            reportingOfficerName: element.reportingOfficerName
              ? element.reportingOfficerName
              : "-",
            empMobileNumber: element.userModel?.mobileNumber
              ? element.userModel?.mobileNumber
              : "-",
            customerName: element.fieldInvestigationVisit?.customerName
              ? element.fieldInvestigationVisit?.customerName
              : "-",
            contactNumber: element.fieldInvestigationVisit?.contactNumber
              ? element.fieldInvestigationVisit?.contactNumber
              : "-",
            visitType: element.visitType ? element.visitType : "-",
            status: element.fieldInvestigationVisit?.status
              ? element.fieldInvestigationVisit?.status
              : "-",
            positiveComments: element.fieldInvestigationVisit?.positiveComments
              ? element.fieldInvestigationVisit?.positiveComments
              : "-",
            negativeComments: element.fieldInvestigationVisit?.negativeComments
              ? element.fieldInvestigationVisit?.negativeComments
              : "-",
            loanDate: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.loanDate
                ? helper.EpochToOnlyDate(
                    element.fieldInvestigationVisit?.loanDate
                  )
                : "-"
              : "-",
            loanType: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.loanType
                ? element.fieldInvestigationVisit?.loanType
                : "-"
              : "-",
            fiAddress: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.customerAddress
                ? element.fieldInvestigationVisit?.customerAddress
                : "-"
              : "-",
            applicationNo: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.applicationNo
                ? element.fieldInvestigationVisit?.applicationNo
                : "-"
              : "-",
            rejectionType: element.fieldInvestigationVisit?.rejectionType
              ? element.fieldInvestigationVisit?.rejectionType
              : "-",
            dateTime:
              element.dateTime === 0
                ? "-"
                : convertEpochTimeToDate(element.dateTime),
            timeDiffOfPrevVisit: element.timeDiffOfPrevVisit
              ? helper.secondsToHms(element.timeDiffOfPrevVisit)
              : "-",
            remarks: element.comments ? element.comments : "-",
            fieldInvestigationVisit:element?.fieldInvestigationVisit,
            fiCompleteDetail:element?.fiCompleteDetail,
            fiAllocationId:element?.fiAllocationId,
            fiAllocation:element?.fiAllocation,
            VisitLocation:
              element.latitude !== 0 && element.longitude !== 0
                ? element.latitude + ", " + element.longitude
                : "-",
          };
          fieldInvstgtnListData.push(data);
        });
        setFieldInvstgtnData(fieldInvstgtnListData);
      } else if (type === "Collections") {
        tempList?.forEach((element) => {
          let data = {
            address: element.address ? element.address : "-",
            addressType: element.collectionVisit?.addressType
              ? element.collectionVisit?.addressType
              : "-",
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            empStatus: element.userModel?.status
              ? element.userModel.status
              : "-",
            designation: element.designation ? element.designation : "-",
            area: element?.userModel?.area
              ? element?.userModel.area
              : "-",
            visitNotDoneAtCustLocation:
            element.customerLatitude !== 0 && element.customerLongitude !== 0
                  ? 
              element.visitNotDoneAtCustLocation === false ? "Yes" : "No":"NA",
            disFromCustLocation: element.disFromCustLocation
              ? getDistTravelledInKM(element.disFromCustLocation)
              : "-",
            branchName: element.branchName ? element.branchName : "-",
            l2ManagerEmpId: element.l2ManagerEmpId
              ? element.l2ManagerEmpId
              : "-",
            l2ManagerName: element.l2ManagerName ? element.l2ManagerName : "-",
            l3ManagerEmpId: element.l3ManagerEmpId
              ? element.l3ManagerEmpId
              : "-",
            l3ManagerName: element.l3ManagerName ? element.l3ManagerName : "-",
            // agDate: element.agDate ? element.agDate : "-",
            aggDate: element.aggDate
              ? helper.EpochToOnlyDate(element?.aggDate)
              : "-",
            vehicleNumber: element.vehicleNumber ? element.vehicleNumber : "-",
            dueDate: element.dueDate
              ? helper.EpochToOnlyDate(element.dueDate)
              : "-",
            branchLocation: element.branchLocation
              ? element.branchLocation
              : "-",
            reportingOfficerName: element.reportingOfficerName
              ? element.reportingOfficerName
              : "-",
            empMobileNumber: element.userModel.mobileNumber
              ? element.userModel.mobileNumber
              : "-",
            customerName:  element.collectionVisit?.customerName
              ? element.collectionVisit?.customerName
              : "-",
            contactNumber: element.collectionVisit?.contactNumber
              ? element.collectionVisit?.contactNumber
              : "-",
            newAddress: element.collectionVisit?.newAddress
              ? element.collectionVisit?.newAddress
              : "-",
            ptpAmount: element.collectionVisit?.ptpAmount
              ? element.collectionVisit?.ptpAmount
              : "-",
            customerLocationChanged:
              element?.customerLocationChanged === true ? "Yes" : "No",
            repossessedVehicleReleased:
              element.collectionVisit?.repossessedVehicleReleased === true
                ? "Yes"
                : "No",
            vehicleReleasedReason: element.collectionVisit
              ?.vehicleReleasedReason
              ? element.collectionVisit?.vehicleReleasedReason
              : "-",
            amountPaidToReleaseVehicle: element.collectionVisit
              ?.amountPaidToReleaseVehicle
              ? element.collectionVisit?.amountPaidToReleaseVehicle
              : "-",
            transactionId: element.collectionVisit?.transactionId
              ? element.collectionVisit?.transactionId
              : "-",
            paymentDate: element.collectionVisit?.paymentDate
              ? helper.EpochToDate(element.collectionVisit?.paymentDate)
              : "-",
            daysBetweenRepossessedAndReleased: element.collectionVisit
              ?.daysBetweenRepossessedAndReleased
              ? element.collectionVisit?.daysBetweenRepossessedAndReleased
              : "-",
            distFromCustAddr: element.collectionVisit
              ? getDistTravelledInKM(element.distFromCustAddr)
              : "-",
            loanNumber: element.collectionVisit?.loanNumber
              ? element.collectionVisit?.loanNumber
              : "-",
            visitType: element.visitType ? element.visitType : "-",
            status: element.collectionVisit?.status
              ? element.collectionVisit?.status
              : "-",
            subStatus: element.collectionVisit
              ? element.collectionVisit.subStatus !== "" ||
                element.collectionVisit.subStatus !== null
                ? element.collectionVisit.subStatus
                : "-"
              : "-",
            paymentMode: element.collectionVisit?.paymentMode
              ? element.collectionVisit?.paymentMode
              : "-",
            paymentTowards: element.collectionVisit?.paymentTowards
              ? element.collectionVisit?.paymentTowards
              : "-",
            amountPaid: element.collectionVisit?.amountPaid
              ? element.collectionVisit?.amountPaid
              : "-",

            paymentDateBy: element.collectionVisit?.ptpSlot
              ? element.collectionVisit?.ptpSlot
              : "-",
            toBePaidBy:
              element.collectionVisit?.toBePaidBy === 0
                ? "-"
                : helper.EpochToOnlyDate(element.collectionVisit?.toBePaidBy),
            unableToPay: element.collectionVisit
              ? element.collectionVisit?.unableToPay === true
                ? "Yes"
                : "No"
              : "-",
            dateTime:
              element.dateTime === 0
                ? "-"
                : convertEpochTimeToDate(element.dateTime),
            vehicleReleasedTime:
              element.collectionVisit?.vehicleReleasedTime === 0
                ? "-"
                : convertEpochTimeToDate(
                    element.collectionVisit?.vehicleReleasedTime
                  ),
            lastPaidTime: element.collectionVisit?.lastPaidTime
              ? convertEpochTimeToDate(element.collectionVisit?.lastPaidTime)
              : "-",
            cpName: element.collectionVisit?.cpName
              ? element.collectionVisit?.cpName
              : "-",
            cpLoanNo: element?.cpLoanNo
              ? element?.cpLoanNo
              : "-",
            alternateMobileNumber: element.collectionVisit
              ?.alternateMobileNumber
              ? element.collectionVisit?.alternateMobileNumber
              : "-",
            VisitLocation:
              element.latitude !== 0 && element.longitude !== 0
                ? element.latitude + ", " + element.longitude
                : "-",
            customerLocation:
                element.customerLatitude !== 0 && element.customerLongitude !== 0
                  ? element.customerLatitude + ", " + element.customerLongitude
                  : "-",
            timeDiffOfPrevVisit: element.timeDiffOfPrevVisit
              ? helper.secondsToHms(element.timeDiffOfPrevVisit)
              : "-",
            remarks: element.comments ? element.comments : "-",
            emiAmountPaidNow: element.collectionVisit?.emiAmountPaidNow
              ? element.collectionVisit?.emiAmountPaidNow
              : "-",
            latePaymentChargesPaidNow: element.collectionVisit
              ?.latePaymentChargesPaidNow
              ? element.collectionVisit?.latePaymentChargesPaidNow
              : "-",
            bounceChargesPaidNow: element.collectionVisit?.bounceChargesPaidNow
              ? element.collectionVisit?.bounceChargesPaidNow
              : "-",
            penaltyChargesPaidNow: element.collectionVisit
              ?.penaltyChargesPaidNow
              ? element.collectionVisit?.penaltyChargesPaidNow
              : "-",
            otherChargesPaidNow: element.collectionVisit?.otherChargesPaidNow
              ? element.collectionVisit?.otherChargesPaidNow
              : "-",
            executive_daily_scheduled_data_id :element.executive_daily_scheduled_data_id,
          };
          collectionsListData.push(data);
        });
        setCollectionsData(collectionsListData);
      } else if (type === "Sales") {
        tempList?.forEach((element) => {
          let data = {
            address: element.address ? element.address : "-",
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            empStatus: element.userModel?.status
              ? element.userModel.status
              : "-",
            designation: element.designation ? element.designation : "-",
            area: element.area ? element.area : "-",
            branchName: element.branchName ? element.branchName : "-",
            l2ManagerEmpId: element.l2ManagerEmpId
              ? element.l2ManagerEmpId
              : "-",
            l2ManagerName: element.l2ManagerName ? element.l2ManagerName : "-",
            l3ManagerEmpId: element.l3ManagerEmpId
              ? element.l3ManagerEmpId
              : "-",
            l3ManagerName: element.l3ManagerName ? element.l3ManagerName : "-",
            customerLocationChanged:
              element?.customerLocationChanged === true ? "Yes" : "No",
            branchLocation: element.branchLocation
              ? element.branchLocation
              : "-",
            reportingOfficerName: element.reportingOfficerName
              ? element.reportingOfficerName
              : "-",
            empMobileNumber: element.userModel?.mobileNumber
              ? element.userModel?.mobileNumber
              : "-",
            customerName: element.salesVisit?.customerName
              ? element.salesVisit?.customerName
              : "-",
            contactNumber: element.salesVisit?.contactNumber
              ? element.salesVisit?.contactNumber
              : "-",
            visitType: element.visitType ? element.visitType : "-",
            productname: element.salesVisit?.productname
              ? element.salesVisit?.productname
              : "-",
            dateTime:
              element.dateTime === 0
                ? "-"
                : convertEpochTimeToDate(element.dateTime),
            VisitLocation:
              element.latitude !== 0 && element.longitude !== 0
                ? element.latitude + ", " + element.longitude
                : "-",
            timeDiffOfPrevVisit: element.timeDiffOfPrevVisit
              ? helper.secondsToHms(element.timeDiffOfPrevVisit)
              : "-",
            remarks: element.comments ? element.comments : "-",
          };
          salesListData.push(data);
        });
        setSalesData(salesListData);
      } else if (type === "All") {
        tempList?.forEach((element) => {
          let data = {
            address: element.address ? element.address : "-",
            addressType: element.collectionVisit?.addressType
              ? element.collectionVisit?.addressType
              : "-",
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            area: element?.userModel?.area
              ? element?.userModel.area
              : "-",
            visitNotDoneAtCustLocation:
            element.customerLatitude !== 0 && element.customerLongitude !== 0
                  ? 
              element.visitNotDoneAtCustLocation === false ? "Yes" : "No":"NA",
            disFromCustLocation: element.disFromCustLocation
              ? getDistTravelledInKM(element.disFromCustLocation)
              : "-",
            empStatus: element.userModel?.status
              ? element.userModel.status
              : "-",
            designation: element.designation ? element.designation : "-",
            branchName: element.branchName ? element.branchName : "-",
            // agDate: element.agDate ? element.agDate : "-",
            aggDate: element.aggDate
              ? helper.EpochToOnlyDate(element?.aggDate)
              : "-",
            vehicleNumber: element.vehicleNumber ? element.vehicleNumber : "-",
            dueDate: element.dueDate
              ? helper.EpochToOnlyDate(element.dueDate)
              : "-",
            branchLocation: element.branchLocation
              ? element.branchLocation
              : "-",
            l2ManagerEmpId: element.l2ManagerEmpId
              ? element.l2ManagerEmpId
              : "-",
            l2ManagerName: element.l2ManagerName ? element.l2ManagerName : "-",
            l3ManagerEmpId: element.l3ManagerEmpId
              ? element.l3ManagerEmpId
              : "-",
            l3ManagerName: element.l3ManagerName ? element.l3ManagerName : "-",
            reportingOfficerName: element.reportingOfficerName
              ? element.reportingOfficerName
              : "-",
            empMobileNumber: element.userModel.mobileNumber
              ? element.userModel.mobileNumber
              : "-",
            customerName: element.collectionVisit
              ? element.collectionVisit.customerName
              : element.salesVisit
              ? element.salesVisit.customerName
              : element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.customerName
              : "-",
            contactNumber: element.collectionVisit
              ? element.collectionVisit.contactNumber
              : element.salesVisit
              ? element.salesVisit.contactNumber
              : element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.contactNumber
              : "-",
            newAddress: element.collectionVisit?.newAddress
              ? element.collectionVisit?.newAddress
              : "-",
            ptpAmount: element.collectionVisit?.ptpAmount
              ? element.collectionVisit?.ptpAmount
              : "-",
            repossessedVehicleReleased: element.collectionVisit
              ? element.collectionVisit?.repossessedVehicleReleased === true
                ? "Yes"
                : "No"
              : "-",
            customerLocationChanged:
              element?.customerLocationChanged === true ? "Yes" : "No",
            vehicleReleasedReason: element.collectionVisit
              ?.vehicleReleasedReason
              ? element.collectionVisit?.vehicleReleasedReason
              : "-",
            daysBetweenRepossessedAndReleased: element.collectionVisit
              ?.daysBetweenRepossessedAndReleased
              ? element.collectionVisit?.daysBetweenRepossessedAndReleased
              : "-",
            amountPaidToReleaseVehicle: element.collectionVisit
              ?.amountPaidToReleaseVehicle
              ? element.collectionVisit?.amountPaidToReleaseVehicle
              : "-",
            paymentDate: element.collectionVisit?.paymentDate
              ? helper.EpochToDate(element.collectionVisit?.paymentDate)
              : "-",
            transactionId: element.collectionVisit?.transactionId
              ? element.collectionVisit?.transactionId
              : "-",

            loanNumber: element.collectionVisit
              ? element.collectionVisit?.loanNumber
              : "-",
            distFromCustAddr: element.collectionVisit
              ? getDistTravelledInKM(element.distFromCustAddr)
              : "-",
            visitType: element.visitType ? element.visitType : "-",
            status: element.collectionVisit
              ? element.collectionVisit.status
              : element.salesVisit
              ? "-"
              : element.fieldInvestigationVisit?.status,
            subStatus: element.collectionVisit
              ? element.collectionVisit.subStatus !== "" ||
                element.collectionVisit.subStatus !== null
                ? element.collectionVisit.subStatus
                : "-"
              : "-",
            paymentMode: element.collectionVisit?.paymentMode
              ? element.collectionVisit?.paymentMode
              : "-",
            paymentTowards: element.collectionVisit?.paymentTowards
              ? element.collectionVisit?.paymentTowards
              : "-",
            amountPaid: element.collectionVisit
              ? element.collectionVisit?.amountPaid
              : "-",
            paymentDateBy: element.collectionVisit
              ? element.collectionVisit?.ptpSlot
                ? element.collectionVisit?.ptpSlot
                : "-"
              : "-",
            toBePaidBy: element.collectionVisit
              ? element.collectionVisit?.toBePaidBy === 0
                ? "-"
                : helper.EpochToOnlyDate(element.collectionVisit?.toBePaidBy)
              : "-",
            unableToPay: element.collectionVisit
              ? element.collectionVisit?.unableToPay === true
                ? "Yes"
                : "No"
              : "-",
            productname: element.salesVisit
              ? element.salesVisit?.productname
              : "-",
            positiveComments: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.positiveComments
                ? element.fieldInvestigationVisit?.positiveComments
                : "-"
              : "-",
            negativeComments: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.negativeComments
                ? element.fieldInvestigationVisit?.negativeComments
                : "-"
              : "-",
            loanDate: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.loanDate
                ? helper.EpochToOnlyDate(
                    element.fieldInvestigationVisit?.loanDate
                  )
                : "-"
              : "-",
            loanType: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.loanType
                ? element.fieldInvestigationVisit?.loanType
                : "-"
              : "-",
            fiAddress: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.customerAddress
                ? element.fieldInvestigationVisit?.customerAddress
                : "-"
              : "-",
            applicationNo: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.applicationNo
                ? element.fieldInvestigationVisit?.applicationNo
                : "-"
              : "-",
            rejectionType: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit?.rejectionType
                ? element.fieldInvestigationVisit?.rejectionType
                : "-"
              : "-",
            dateTime:
              element.dateTime === 0
                ? "-"
                : convertEpochTimeToDate(element.dateTime),
            vehicleReleasedTime: element.collectionVisit
              ? element.collectionVisit?.vehicleReleasedTime === 0
                ? "-"
                : convertEpochTimeToDate(
                    element.collectionVisit?.vehicleReleasedTime
                  )
              : "-",
            lastPaidTime: element.collectionVisit?.lastPaidTime
              ? convertEpochTimeToDate(element.collectionVisit?.lastPaidTime)
              : "-",
            cpName: element.collectionVisit?.cpName
              ? element.collectionVisit?.cpName
              : "-",
            alternateMobileNumber: element.collectionVisit
              ?.alternateMobileNumber
              ? element.collectionVisit?.alternateMobileNumber
              : "-",
            VisitLocation:
              element.latitude !== 0 && element.longitude !== 0
                ? element.latitude + ", " + element.longitude
                : "-",
            customerLocation:
                element.customerLatitude !== 0 && element.customerLongitude !== 0
                  ? element.customerLatitude + ", " + element.customerLongitude
                  : "-",
            timeDiffOfPrevVisit: element.timeDiffOfPrevVisit
              ? helper.secondsToHms(element.timeDiffOfPrevVisit)
              : "-",
            remarks: element.comments ? element.comments : "-",
            executive_daily_scheduled_data_id :element.executive_daily_scheduled_data_id,
            fiAllocationId:element.fiAllocationId,
          };
          allListData.push(data);
        });
        setAllData(allListData);
      } else if (type === "Repossessed") {
        tempList?.forEach((element) => {
          let data = {
            address: element.address ? element.address : "-",
            addressType: element.collectionVisit?.addressType
              ? element.collectionVisit?.addressType
              : "-",
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            empStatus: element.userModel?.status
              ? element.userModel.status
              : "-",
            designation: element.designation ? element.designation : "-",
            branchName: element.branchName ? element.branchName : "-",
            branchLocation: element.branchLocation
              ? element.branchLocation
              : "-",
            l2ManagerEmpId: element.l2ManagerEmpId
              ? element.l2ManagerEmpId
              : "-",
            l2ManagerName: element.l2ManagerName ? element.l2ManagerName : "-",
            l3ManagerEmpId: element.l3ManagerEmpId
              ? element.l3ManagerEmpId
              : "-",
            l3ManagerName: element.l3ManagerName ? element.l3ManagerName : "-",
            reportingOfficerName: element.reportingOfficerName
              ? element.reportingOfficerName
              : "-",
            empMobileNumber: element.userModel.mobileNumber
              ? element.userModel.mobileNumber
              : "-",
            customerName: element.collectionVisit?.customerName
              ? element.collectionVisit?.customerName
              : "-",
            contactNumber: element.collectionVisit?.contactNumber
              ? element.collectionVisit?.contactNumber
              : "-",
            newAddress: element.collectionVisit?.newAddress
              ? element.collectionVisit?.newAddress
              : "-",
            ptpAmount: element.collectionVisit?.ptpAmount
              ? element.collectionVisit?.ptpAmount
              : "-",
            customerLocationChanged:
              element?.customerLocationChanged === true ? "Yes" : "No",
            repossessedVehicleReleased:
              element.collectionVisit?.repossessedVehicleReleased === true
                ? "Yes"
                : "No",
            vehicleReleasedReason: element.collectionVisit
              ?.vehicleReleasedReason
              ? element.collectionVisit?.vehicleReleasedReason
              : "-",
            repossedLocation: element.collectionVisit?.repossedLocation
              ? element.collectionVisit?.repossedLocation
              : "-",
            amountPaidToReleaseVehicle: element.collectionVisit
              ?.amountPaidToReleaseVehicle
              ? element.collectionVisit?.amountPaidToReleaseVehicle
              : "-",
            transactionId: element.collectionVisit?.transactionId
              ? element.collectionVisit?.transactionId
              : "-",
            paymentDate: element.collectionVisit?.paymentDate
              ? helper.EpochToDate(element.collectionVisit?.paymentDate)
              : "-",
            daysBetweenRepossessedAndReleased: element.collectionVisit
              ?.daysBetweenRepossessedAndReleased
              ? element.collectionVisit?.daysBetweenRepossessedAndReleased
              : "-",
            loanNumber: element.collectionVisit?.loanNumber
              ? element.collectionVisit?.loanNumber
              : "-",
            visitType: element.visitType ? element.visitType : "-",
            status: element.collectionVisit?.status
              ? element.collectionVisit?.status
              : "-",

            dateTime:
              element.dateTime === 0
                ? "-"
                : convertEpochTimeToDate(element.dateTime),
            vehicleReleasedTime:
              element.collectionVisit?.vehicleReleasedTime === 0
                ? "-"
                : convertEpochTimeToDate(
                    element.collectionVisit?.vehicleReleasedTime
                  ),
            lastPaidTime: element.collectionVisit?.lastPaidTime
              ? convertEpochTimeToDate(element.collectionVisit?.lastPaidTime)
              : "-",
            cpName: element.collectionVisit?.cpName
              ? element.collectionVisit?.cpName
              : "-",
            alternateMobileNumber: element.collectionVisit
              ?.alternateMobileNumber
              ? element.collectionVisit?.alternateMobileNumber
              : "-",
            VisitLocation:
              element.latitude !== 0 && element.longitude !== 0
                ? element.latitude + ", " + element.longitude
                : "-",
            timeDiffOfPrevVisit: element.timeDiffOfPrevVisit
              ? helper.secondsToHms(element.timeDiffOfPrevVisit)
              : "-",
            remarks: element.comments ? element.comments : "-",
            executive_daily_scheduled_data_id :element.executive_daily_scheduled_data_id,
          };
          repossessedListData.push(data);
        });
        setRepossessedData(repossessedListData);
      } else if (type === "CallReport") {
        tempList?.forEach((element) => {
          let data = {
            agentId: element.autoFinanceUser?.employeeId
              ? element.autoFinanceUser?.employeeId
              : "-",
            agentName: element.autoFinanceUser?.name
              ? element.autoFinanceUser?.name
              : "-",
            agentStatus: element.autoFinanceUser?.status
              ? element.autoFinanceUser?.status
              : "-",
            allocationId: element.allocationId ? element.allocationId : "-",
            allocationStatus: element.allocationStatus
              ? element.allocationStatus
              : "-",
            amountPaid: element.amountPaid ? element.amountPaid : 0,
            callDuration: element.callDuration
              ? helper.secondsToHms(element.callDuration)
              : "-",
            callId: element.callId ? element.callId : "-",
            campaignName: element.campaignName ? element.campaignName : "-",
            callIntiatedTime: element.createdTime
              ? helper.EpochToDate(element.createdTime)
              : "-",
            cpName: element.cpName ? element.cpName : "-",
            createdAt: element.createdTime ? element.createdTime : "-",
            currentDue: element.currentDue ? element.currentDue : "-",
            dueAmount: element.dueAmount ? element.dueAmount : "-",
            customerName: element.customerName ? element.customerName : "-",
            id: element.id ? element.id : "-",
            image: element?.imageUploaded === true ? element.image : "-",
            direction: element.callDirection
              ? element.callDirection === "outbound"
                ? "OutBound"
                : "InBound"
              : "-",
            hangup_reason: element?.hangUpReason
              ? getHangUpReason(element?.hangUpReason)
              : "-",
            remarks: element?.remarks ? element?.remarks : "-",
            remarksType: element?.callStatus ? element?.callStatus : "-",
            transactionId: element?.transactionId
              ? element?.transactionId
              : "-",
            paymentTowards: element?.paymentTowards
              ? element?.paymentTowards
              : "-",
            paymentDate: element?.paymentDate
              ? helper.EpochToOnlyDate(element?.paymentDate)
              : "-",
            toBePaidBy: element?.toBePaidBy
              ? helper.EpochToOnlyDate(element?.toBePaidBy)
              : "-",
            ptpSlot: element?.ptpSlot ? element?.ptpSlot : "-",
            callBackDate: element?.callBackDate
              ? helper.EpochToOnlyDate(element?.callBackDate)
              : "-",
            callBackSlot: element?.callBackSlot ? element?.callBackSlot : "-",
            ptpAmount: element?.ptpAmount ? element?.ptpAmount : 0,
            paymentMode: element?.paymentMode ? element?.paymentMode : "-",

            lastVisitStatus:
              element?.callStatus === "Call Connected"
                ? element.status
                  ? element.status
                  : "-"
                : "-",
            loanNumber: element.loanNumber ? element.loanNumber : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            paymentLinkStatus:
              element.paymentLinkStatus === true ? "Yes" : "No",
            subStatus:
              element?.callStatus === "Call Connected"
                ? element.subStatus
                  ? element.subStatus
                  : "-"
                : "-",
            callStatus: element.callStatus ? element.callStatus : "-",
            teleCmiCdr: element.teleCmiCdr,
            teleCmiCallStatus:
              element?.callStatus === "Call Not Connected"
                ? element.subStatus
                  ? element.subStatus
                  : "-"
                : "-",
            teleCmiCdrId: element.teleCmiCdrId ? element.teleCmiCdrId : "-",
            toNumber: element.phoneNumber ? element.phoneNumber : "-",
            updatedAt: element.updatedAt
              ? helper.EpochToDate(element.updatedAt)
              : "-",
          };
          callHistoryListData.push(data);
        });
        setCallHistoryData(callHistoryListData);
      } else {
        const transformedArray = [];
        tempList?.forEach((item) => {
          const transformedObject = {};
          // transformedObject["Date"] = item?.date
          //   ? helper.EpochToOnlyDate(item?.date)
          //   : "-";
          transformedObject["Loan Number"] = item?.loanNumber;
          // transformedObject["Emp Id"] = item?.employeeId;
          // transformedObject["Emp Name"] = item?.empName;
          // transformedObject["Emp Status"] = item?.empStatus;
          transformedObject["Total Calls"] =
            item?.noDisposition + item?.callConnected + item?.callNotConnected;
          transformedObject["Disposition Not Entered"] = item?.noDisposition;
          transformedObject["Call Connected"] = item?.callConnected;
          transformedObject["Call Not Connected"] = item?.callNotConnected;

          // Iterate through the nested objects dynamically
          Object.keys(item?.statusCounts).forEach((statusType) => {
            Object.keys(item?.statusCounts[statusType]).forEach((status) => {
              transformedObject[status] = item?.statusCounts[statusType][status]
                ? item?.statusCounts[statusType][status]
                : 0;
            });
          });

          transformedArray.push(transformedObject);
        });
        // if (reportCategory === "CallDipositionSummary") {
        //   for (let i = 0; i < transformedArray?.length; i++) {
        //     delete transformedArray[i]["Loan Number"];
        //     delete transformedArray[i]["Emp Id"];
        //     delete transformedArray[i]["Emp Name"];
        //     delete transformedArray[i]["Emp Status"];
        //   }
        // } else if (reportCategory === "CallDispositionSummaryByLoanNumber") {
        //   for (let i = 0; i < transformedArray?.length; i++) {
        //     delete transformedArray[i]["Date"];
        //     delete transformedArray[i]["Emp Id"];
        //     delete transformedArray[i]["Emp Name"];
        //     delete transformedArray[i]["Emp Status"];
        //   }
        // } else if (reportCategory === "CallDispositionSummaryByAgent") {
        //   for (let i = 0; i < transformedArray?.length; i++) {
        //     delete transformedArray[i]["Date"];
        //     delete transformedArray[i]["Loan Number"];
        //   }
        // }
        // console.log(transformedArray);
        const uniqueKeys = new Set();

        transformedArray?.forEach((obj, index) => {
          if (obj !== null) {
            // console.log("index", index);
            Object.keys(obj).forEach((key) => {
              uniqueKeys.add(key);
            });
          }
        });

        let keys = Array.from(uniqueKeys);

        setKeys(keys);
        setDispositionReportData(transformedArray);
      }
    }
    setT(type);
  }

  // const workbook2blob = (workbook) => {
  //   const wopts = {
  //     bookType: "xlsx",
  //     bookSST: false,
  //     type: "binary",
  //   };

  //   const wbout = XLSX.write(workbook, wopts);

  //   // The application/octet-stream MIME type is used for unknown binary files.
  //   // It preserves the file contents, but requires the receiver to determine file type,
  //   // for example, from the filename extension.
  //   const blob = new Blob([s2ab(wbout)], {
  //     type: "application/octet-stream",
  //   });

  //   return wbout;
  // };

  // const s2ab = (s) => {
  //   // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  //   // create an ArrayBuffer with a size in bytes
  //   const buf = new ArrayBuffer(s.length);

  //   //create a 8 bit integer array
  //   const view = new Uint8Array(buf);

  //   //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  //   for (let i = 0; i !== s.length; ++i) {
  //     //   console.log(s.charCodeAt(i));
  //     view[i] = s.charCodeAt(i);
  //   }

  //   return buf;
  // };

  // const getColumnAlphabet = () => {
  //   if (reportCategory === "DETAILED") {
  //     if (type === "All") {
  //       return "S";
  //     } else if (type === "Sales") {
  //       return "J";
  //     } else if (type === "Collections") {
  //       return "O";
  //     } else if (type === "Attendance") {
  //       return "I";
  //     } else if (type === "FieldInvestigation") {
  //       return "M";
  //     }
  //   } else if (reportCategory === "SUMMARY") {
  //     if (type === "All") {
  //       return "O";
  //     } else if (type === "Sales") {
  //       return "E";
  //     } else if (type === "Collections") {
  //       return "J";
  //     } else if (type === "Attendance") {
  //       return "G";
  //     } else if (type === "FieldInvestigation") {
  //       return "G";
  //     }
  //   }
  // };

  const handleExport = () => {
    // const title = [{ A: "CLU REPORT" }, {}];

    let attendaneTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Designation",
        D: "Area",
        E: "Branch Name",
        F: "Employee Mobile Number",
        G: "Date",
        H: "Status",
        I: "Check In Time",
        J: "First Check In Location",
        K: "Check Out Time",
        L: "Last Check Out Location",
        M: "Working Hours",
        N: "Distance Travelled",
        O: "Emp Status",
        P: "Phone Make",
        Q: "Phone Model",
        R:"Visit Count"
      },
    ];

    let empPerformanceTable = [
      {
        A: "Employee Name",
        B: "Employee Id",
        C: "Visit Frequency",
        D: "Recovery Rate",
        E: "Collection Day",
        F: "Avg Collection Time",
        G: "Year & Month",
      },
    ];

    let fieldInvstgtnTable = [
      {
        A: "Application No",
        B: "Client",
        C: "Applicant Type",
        D: "FI Type",
        E: "Applican't Name",
        F: "Applicant's Contact No.",
        G: "Product Type",
        H: "CPV Verification Limit",
        I: "Type Of Visit",
        J: "FI Result",
        K: "Allocation Status",
        L: "Rejection Reason",
        M: "Verification Received On",
        N: "Visited On",
        O: "Report Completed On",
        P: "Emp Name",
        Q: "Emp ID",
        R: "Verifier's Emp ID",
        S: "Verifier's Emp Name",
        T: "Applicant Working Status",
        U: "End User Of The Product",
        V: "No.of Yrs of Stay in City",
        W: "No.of Yrs At Residence",
        X: "Ownership",
        Y: "Neighbourhood Locality",
        Z: "Standard Of Living",
        AA: "Area Locality",
        AB: "Applicant Profile",
        AC:"No.of Yrs in Employment/Bussiness",
        AD:"Total Employment/Bussiness Stability(In Yrs)",
        AE:"BIZ Acitivty",
        AF:"Residence/Office Interior Condition",
        AG:"No.of Visits",
        AH:"Visit Address",
        AI:"Visit Lat Long",
        AJ:"Cust Address",
        AK:"Cust City",
        AL:"Cust State",
        AM:"Office Name",
        AN:"Office/Bussiness Address",
        AO:"Time Difference B/W Prev Visit",
        AP:"Visit Allocated",
        AQ:"Loan Date",
        AR:"Loan Type",
        AS:"Is Cust Address Changed",
        AT:"Emp Branch Name",
        AU:"Emp State",
        AV:"Emp Mobile Number",
        AW:"Employee Status",
        AX:"Designation",
        AY:"Visit Under Manager",
        AZ:"L2 Manager Emp ID",
        BA:"L2 Manager Emp Name",
        BB:"L3 Manager Emp ID",
        BC:"L3 Manager Emp Name",
        BD:"Remarks"
      },
    ];

    let collectionTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Employee Status",
        D: "Designation",
        E: "Area",
        F: "Branch Name",
        G: "State",
        H: "L2 Manager Emp ID",
        I: "L2 Manager Emp Name",
        J: "L3 Manager Emp ID",
        K: "L3 Manager Emp Name",
        L: "Visit Under Manager",
        M: "Employee Mobile Number",
        N: "Customer Name",
        O: "Customer Contact Number",
        P: "Address Type",
        Q: "Visit Address",
        R: "Is Cust Address Changed",
        S: "New Address",
        T: "Loan Number",
        U:"Funder Loan No.",
        V: "Type Of Visit",
        W: "Status",
        X: "Sub Status",
        Y: "Payment Mode",
        Z: "Payment Towards",
        AA: "Emi Paid",
        AB: "Late Payment Charges Paid",
        AC: "Bounce Charges Paid",
        AD: "Penalty Charges Paid",
        AE: "Other Charges Paid",
        AF: "Total Amount Paid",
        AG: "Payment Date",
        AH: "Transaction Id",
        AI: "Promised Payment Date",
        AJ: "Promised Time Slot",
        AK: "PTP Amount",
        AL: "Is Vehicle Released",
        AM: "Amount Paid For Vehicle Release",
        AN: "Vehicle Released Date & Time",
        AO: "Reason For Vehicle Release",
        AP: "Days B/W Repossessed & Released",
        // O: "Is Unable To Pay",
        // O: "Dist B/W Customer & Visited Location",
        AQ: "Visited On",
        AR: "Is Visit Done at Customer Address",
        AS: "Dist B/W Cust Addr & Visit Addr",
        AT: "Last Paid",
        AU: "CP Name",
        AV: "AG Date",
        AW: "Vehicle No",
        AX: "Due Date",
        AY: "New Mobile No",
        AZ: "Visit Allocated",
        BA: "Visit Latitude & Longitude",
        BB: "Customer Latitude & Longitude",
        BC: "Time Difference B/W Prev Visit",
        BD: "Remarks",
      },
    ];

    let repossessedTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Employee Status",
        D: "Designation",
        E: "Area",
        F: "Branch Name",
        G: "State",
        H: "L2 Manager Emp ID",
        I: "L2 Manager Emp Name",
        J: "L3 Manager Emp ID",
        K: "L3 Manager Emp Name",
        L: "Visit Under Manager",
        M: "Employee Mobile Number",
        N: "Customer Name",
        O: "Customer Contact Number",
        P: "Address Type",
        Q: "Visit Address",
        R: "Is Cust Address Changed",
        S: "New Address",
        T: "Loan Number",
        U: "Type Of Visit",
        V: "Status",
        W: "Yard Name",
        X: "Is Vehicle Released",
        Y: "Amount Paid For Vehicle Release",
        Z: "Vehicle Released Date & Time",
        AA: "Reason For Vehicle Release",
        AB: "Days B/W Repossessed & Released",
        AC: "Visited On",
        AD: "Is Visit Done at Customer Address",
        AE: "Dist B/W Cust Addr & Visit Addr",
        AF: "Last Paid",
        AG: "CP Name",
        AH: "New Mobile No",
        AI: "Visit Allocated",
        AJ: "Latitude & Longitude",
        AK: "Remarks",
      },
    ];

    let salesTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Employee Status",
        D: "Designation",
        E: "Area",
        F: "Branch Name",
        G: "State",
        H: "L2 Manager Emp ID",
        I: "L2 Manager Emp Name",
        J: "L3 Manager Emp ID",
        K: "L3 Manager Emp Name",
        L: "Visit Under Manager",
        M: "Employee Mobile Number",
        N: "Customer Name",
        O: "Customer Contact Number",
        P: "Visit Address",
        Q: "Is Cust Address Changed",
        R: "Type Of Visit",
        S: "Product Name",
        T: "Visited On",
        U: "Visit Allocated",
        V: "Latitude & Longitude",
        W: "Time Difference B/W Prev Visit",
        X: "Remarks",
      },
    ];

    let allTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Employee Status",
        D: "Designation",
        E: "Area",
        F: "Branch Name",
        G: "State",
        H: "L2 Manager Emp ID",
        I: "L2 Manager Emp Name",
        J: "L3 Manager Emp ID",
        K: "L3 Manager Emp Name",
        L: "Visit Under Manager",
        M: "Employee Mobile Number",
        N: "Customer Name",
        O: "Customer Contact Number",
        P: "Address Type",
        Q: "Visit Address",
        R: "Is Cust Address Changed",
        S: "New Address",
        T: "Loan Number",
        U: "Type Of Visit",
        V: "Status",
        W: "Sub Status",
        X: "Payment Mode",
        Y: "Payment Towards",
        Z: "Amount Paid",
        AA: "Payment Date",
        AB: "Transaction Id",
        AC: "Promised Payment Date",
        AD: "Promised Time Slot",
        AE: "PTP Amount",
        AF: "Is Vehicle Released",
        AG: "Amount Paid For Vehicle Release",
        AH: "Vehicle Released Date & Time",
        AI: "Reason For Vehicle Release",
        AJ: "Days B/W Repossessed & Released",
        AK: "Product Name",
        AL: "Application No",
        // AG: "Positive Remarks",
        AM: "Rejection Reason",
        // AI: "Negative Remarks",
        AN: "Loan Date",
        AO: "Loan Type",
        AP: "FI Address",
        AQ: "Visited On",
        AR: "Is Visit Done at Customer Address",
        AS: "Dist B/W Cust Addr & Visit Addr",
        AT: "Last Paid",
        AU: "CP Name",
        AV: "AG Date",
        AW: "Vehicle No",
        AX: "Due Date",
        AY: "New Mobile No",
        AZ: "Visit Allocated",
        BA: "Visit Latitude & Longitude",
        BB: "Customer Latitude & Longitude",
        BC: "Time Difference B/W Prev Visit",
        BD: "Remarks",
      },
    ];

    let callHistoryTable = [
      {
        A: "Emp ID",
        B: "Emp Name",
        C: "Emp Status",
        D: "CP Name",
        E: "Campaign",
        F: "Loan Number",
        G: "Customer Name",
        H: "Called Number",
        I: "Call Direction",
        J: "Call Status",
        K: "Call Sub Status",
        L: "Call Initiated At",
        M: "Call Duration",
        N: "HangUp Reason",
        O: "Remarks",
        P: "Amount Paid",
        Q: "Current Due",
        R: "Total Due",
        S: "Payment Link Sent Status",
        T: "Allocation Status",
        U: "Status",
        V: "Sub Status",
        W: "Payment Mode",
        X: "Payment Towards",
        Y: "Payment Date",
        Z: "Transaction Id",
        AA: "Promised Payment Date",
        AB: "Promised Time Slot",
        AC: "Call Back Date",
        AD: "Call Back Time Slot",
        AE: "PTP Amount",
        AF: "Payment Receipt",
      },
    ];

    // let summaryTable = [
    //   {
    //     A: "Employee Id",
    //     B: "Employee Name",
    //     C: "Designation",
    //     D: "Branch Name",
    //     E: "Employee Mobile Number",
    //     F:
    //       type === "All" || type === "Sales"
    //         ? "Sales Visits"
    //         : type === "Collections"
    //         ? "PTP"
    //         : type === "FieldInvestigation"
    //         ? "Total Positive Responses"
    //         : "",
    //     // G:
    //     //   type === "All"
    //     //     ? "PTP"
    //     //     : type === "Sales"
    //     //     ? "Dup Counts Per Cust"
    //     //     : type === "Collections"
    //     //     ? "Paid"
    //     //     : type === "FieldInvestigation"
    //     //     ? "Total Negative Responses"
    //     //     : "",
    //     // H:
    //     //   type === "All"
    //     //     ? "Paid"
    //     //     : type === "Sales"
    //     //     ? "Total Dup Visit Counts"
    //     //     : type === "Collections"
    //     //     ? "RTP"
    //     //     : type === "FieldInvestigation"
    //     //     ? "Field Investigation Visits"
    //     //     : "",
    //     // I:
    //     //   type === "All"
    //     //     ? "RTP"
    //     //     : type === "Collections"
    //     //     ? "Repossessed"
    //     //     : type === "FieldInvestigation"
    //     //     ? "Dup Counts Per Cust"
    //     //     : "",
    //     // J:
    //     //   type === "All"
    //     //     ? "Repossessed"
    //     //     : type === "Collections"
    //     //     ? "Third Party"
    //     //     : type === "FieldInvestigation"
    //     //     ? "Total Dup Visit Counts"
    //     //     : "",
    //     // K:
    //     //   type === "All"
    //     //     ? "Third Party"
    //     //     : type === "Collections"
    //     //     ? "Vacant"
    //     //     : "",
    //     // L:
    //     //   type === "All"
    //     //     ? "Vacant"
    //     //     : type === "Collections"
    //     //     ? "Customer N/A"
    //     //     : "",
    //     // M:
    //     //   type === "All"
    //     //     ? "Customer N/A"
    //     //     : type === "Collections"
    //     //     ? "Total Collection Visits"
    //     //     : "",
    //     // N:
    //     //   type === "All"
    //     //     ? "Total Collection Visits"
    //     //     : type === "Collections"
    //     //     ? "Total Amount Collected"
    //     //     : "",
    //     // O:
    //     //   type === "All"
    //     //     ? "Total Amount Collected"
    //     //     : type === "Collections"
    //     //     ? "Dup Counts Per Cust"
    //     //     : "",
    //     // P:
    //     //   type === "All"
    //     //     ? "Total Positive Responses	"
    //     //     : type === "Collections"
    //     //     ? "Total Dup Visit Counts"
    //     //     : "",
    //     // Q: type === "All" ? "Total Negative Responses	" : "",
    //     // R: type === "All" ? "Field Investigation Visits	" : "",
    //     // S: type === "All" ? "Total Visits" : "",
    //     // T: type === "All" ? "Dup Counts Per Cust" : "",
    //     // U: type === "All" ? "Total Dup Visit Counts" : "",
    //   },
    // ];

    let summarySalesTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Employee Status",
        D: "Designation",
        E: "Branch Name",
        F: "Employee Mobile Number",
        G: "Sales Visits",
        H: "Multiple Counts Per Customer",
        I: "Total Multiple Visit Counts",
      },
    ];
    let summaryFieldInvestigationTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Employee Status",
        D: "Designation",
        E: "Branch Name",
        F: "Employee Mobile Number",
        G: "Total Positive Responses",
        H: "Total Negative Responses",
        I: "Field Investigation Visits",
        J: "Multiple Counts Per Customer",
        K: "Total Multiple Visit Counts",
      },
    ];

    let summaryAttendanceTable = [
      {
        A: "Employee Id",
        B: "Employee Name",
        C: "Employee Status",
        D: "Designation",
        E: "Branch Name",
        F: "Employee Mobile Number",
        G: "Total Days Present",
        H: "Total Days Absent",
        I: "Total Working Hours",
        J: "Distance Travelled",
        K: "Total Visits",
      },
    ];

    let tempArray = [];
    let cpNamesTempArray = [];
    let cumulativeTempArray = [];

    if (
      reportCategory === "DETAILED" &&
      type === "Attendance" &&
      isReportType === false
    ) {
      attendanceData?.forEach((row) => {
        attendaneTable.push({
          A: row.employeeId,
          B: row.employeeName,
          C: row.designation,
          D: row.area,
          E: row.branchName,
          F: row.empMobileNumber,
          G: row.date,
          H: row.employeeAttendenceStatus,
          I: row.loginTime === 0 ? "-" : row.loginTime,
          J: row.checkInAddress,
          K: row.logoutTime,
          L: row.checkOutAddress,
          M: row.timeDifference,
          N: row.distanceTravelled,
          O: row.userStatus,
          P: row.phoneMake,
          Q: row.phoneModel,
          R:row.visitCount,
        });
      });
    } else if (
      reportCategory === "DETAILED" &&
      type === "EmployeePerformanceMetrics" &&
      isReportType === false
    ) {
      employeeMetricsDownloadData?.forEach((row) => {
        empPerformanceTable.push({
          A: row.empName,
          B: row.empId,
          C: row.contactRate,
          D: row.collectionRate,
          E: row.collectionDay,
          F: row.avgCollectionTimePct,
          G: row.monthYear,
        });
      });
    } else if (
      reportCategory === "DETAILED" &&
      type === "FieldInvestigation" &&
      isReportType === false
    ) {
      fieldInvstgtnData?.forEach((row) => {
        fieldInvstgtnTable.push({
        A: row.applicationNo,
        B: row?.fiAllocation?.client ? row?.fiAllocation?.client : "-",
        C: row?.fiCompleteDetail?.applicantType ? row?.fiCompleteDetail?.applicantType : "-",
        D: row?.fiAllocation?.fiType ? row?.fiAllocation?.fiType  : "-",
        E: row.customerName,
        F: row.contactNumber,
        G: row?.fiAllocation?.productType ? row?.fiAllocation?.productType  : "-",
        H: row?.fiAllocation?.cpvVerificationGeoLimit ? row?.fiAllocation?.cpvVerificationGeoLimit  : "-",
        I: row.visitType,
        J: row?.fieldInvestigationVisit?.status ? row?.fieldInvestigationVisit?.status  : "-",
        K: row?.fiAllocation?.status ? row?.fiAllocation?.status : "-",
        L: row.rejectionType,
        M: row?.fiAllocation?.createdTime ? convertEpochTimeToDate(row?.fiAllocation?.createdTime):"-",
        N: row.dateTime,
        O: row?.fiCompleteDetail?.dateOfReport ? convertEpochTimeToDate(row?.fiCompleteDetail?.dateOfReport):"-",
        P: row.employeeName,
        Q: row.employeeId,
        R: row?.fiCompleteDetail?.verifiersEmpId ? row?.fiCompleteDetail?.verifiersEmpId : "-",
        S: row?.fiCompleteDetail?.verifiersEmpName ? row?.fiCompleteDetail?.verifiersEmpName : "-",
        T: row?.fiCompleteDetail?.applicantWorking ? row?.fiCompleteDetail?.applicantWorking : "-",
        U: row?.fiCompleteDetail?.enduserOfProduct ? row?.fiCompleteDetail?.enduserOfProduct : "-",
        V: row?.fiCompleteDetail?.cityStayInyears ? row?.fiCompleteDetail?.cityStayInyears : "-",
        W: row?.fiCompleteDetail?.residenceStayInyears ? row?.fiCompleteDetail?.residenceStayInyears : "-",
        X: row?.fiCompleteDetail?.residenceOwnership ? row?.fiCompleteDetail?.residenceOwnership : "-",
        Y: row?.fiCompleteDetail?.locality ? row?.fiCompleteDetail?.locality : "-",
        Z: row?.fiCompleteDetail?.livingStandard ? row?.fiCompleteDetail?.livingStandard : "-",
        AA: row?.fiCompleteDetail?.areaLocality ? row?.fiCompleteDetail?.areaLocality : "-",
        AB: row?.fiCompleteDetail?.applicantProfile ? row?.fiCompleteDetail?.applicantProfile : "-",
        AC:row?.fiCompleteDetail?.noOfYearsEmp ? row?.fiCompleteDetail?.noOfYearsEmp : "-",
        AD:row?.fiCompleteDetail?.totalEmployeement ? row?.fiCompleteDetail?.totalEmployeement : "-",
        AE:row?.fiCompleteDetail?.bizActivity ? row?.fiCompleteDetail?.bizActivity : "-",
        AF:row?.fiCompleteDetail?.interiorCondition ? row?.fiCompleteDetail?.interiorCondition : "-",
        AG:row?.fiCompleteDetail?.noOfVisits ? row?.fiCompleteDetail?.noOfVisits : "-",
        AH:row?.address ? row?.address : "-",
        AI:row?.VisitLocation,
        AJ:row?.fiAllocation?.addressLine1 ? row?.fiAllocation?.addressLine1 : "-",
        AK:row?.fiAllocation?.city ? row?.fiAllocation?.city : "-",
        AL:row?.fiAllocation?.state ? row?.fiAllocation?.state : "-",
        AM:row?.fiAllocation?.officeName ? row?.fiAllocation?.officeName : "-",
        AN:row?.fiAllocation?.addressLine1 ? row?.fiAllocation?.addressLine1 : "-",
        AO:row?.timeDiffOfPrevVisit ? row?.timeDiffOfPrevVisit:"-",
        AP:row?.fiAllocationId ? "Yes":"No",
        AQ:row?.fiCompleteDetail?.loanDate ? row?.fiCompleteDetail?.loanDate : "-",
        AR:row?.fiCompleteDetail?.loanType ? row?.fiCompleteDetail?.loanType : "-",
        AS:row?.customerLocationChanged ? row?.customerLocationChanged : "-",
        AT:row?.branchName ? row?.branchName : "-",
        AU:row?.branchLocation ? row?.branchLocation : "-",
        AV:row?.empMobileNumber ? row?.empMobileNumber : "-",
        AW:row?.empStatus ? row?.empStatus : "-",
        AX:row?.designation ? row?.designation : "-",
        AY:row?.reportingOfficerName ? row?.reportingOfficerName : "-",
        AZ:row?.l2ManagerEmpId ? row?.l2ManagerEmpId : "-",
        BA:row?.l2ManagerName ? row?.l2ManagerName : "-",
        BB:row?.l3ManagerEmpId ? row?.l3ManagerEmpId : "-",
        BC:row?.l3ManagerName ? row?.l3ManagerName : "-",
        BD:row?.comments ? row?.comments : "-",
        });
      });
    } else if (reportCategory === "DETAILED" && type === "Collections") {
      collectionsData?.forEach((row) => {
        collectionTable.push({
          A: row.employeeId,
          B: row.employeeName,
          C: row.empStatus,
          D: row.designation,
          E: row.area,
          F: row.branchName,
          G: row.branchLocation,
          H: row.l2ManagerEmpId,
          I: row.l2ManagerName,
          J: row.l3ManagerEmpId,
          K: row.l3ManagerName,
          L: row.reportingOfficerName,
          M: row.empMobileNumber,
          N: row.customerName,
          O: row.contactNumber,
          P: row.addressType,
          Q: row.address,
          R: row.customerLocationChanged,
          S: row.newAddress,
          T: row.loanNumber,
          U:row.cpLoanNo,
          V: row.visitType,
          W: row.status,
          X: row.subStatus,
          Y: row.paymentMode,
          Z: row.paymentTowards,
          AA: row.emiAmountPaidNow,
          AB: row.latePaymentChargesPaidNow,
          AC: row.bounceChargesPaidNow,
          AD: row.penaltyChargesPaidNow,
          AE: row.otherChargesPaidNow,
          AF: row.amountPaid,
          AG: row.paymentDate,
          AH: row.transactionId,
          AI: row.toBePaidBy,
          AJ: row.paymentDateBy,
          AK: row.ptpAmount,
          AL: row.repossessedVehicleReleased,
          AM: row.amountPaidToReleaseVehicle,
          AN: row.vehicleReleasedTime,
          AO: row.vehicleReleasedReason,
          AP: row.daysBetweenRepossessedAndReleased,
          AQ: row.dateTime,
          AR: row.visitNotDoneAtCustLocation,
          AS: row.disFromCustLocation,
          AT: row.lastPaidTime,
          AU: row.cpName,
          AV: row.aggDate,
          AW: row.vehicleNumber,
          AX: row.dueDate,
          AY: row.alternateMobileNumber,
          AZ: row.executive_daily_scheduled_data_id ? "Yes" :"No",
          BA: row.VisitLocation,
          BB:row.customerLocation,
          BC: row.timeDiffOfPrevVisit,
          BD: row.remarks,
        });
      });
    } else if (
      reportCategory === "DETAILED" &&
      type === "Repossessed" &&
      isReportType === false
    ) {
      repossessedData?.forEach((row) => {
        repossessedTable.push({
          A: row.employeeId,
          B: row.employeeName,
          C: row.empStatus,
          D: row.designation,
          E: row.area,
          F: row.branchName,
          G: row.branchLocation,
          H: row.l2ManagerEmpId,
          I: row.l2ManagerName,
          J: row.l3ManagerEmpId,
          K: row.l3ManagerName,
          L: row.reportingOfficerName,
          M: row.empMobileNumber,
          N: row.customerName,
          O: row.contactNumber,
          P: row.addressType,
          Q: row.address,
          R: row.customerLocationChanged,
          S: row.newAddress,
          T: row.loanNumber,
          U: row.visitType,
          V: row.status,
          W: row.repossedLocation,
          X: row.repossessedVehicleReleased,
          Y: row.amountPaidToReleaseVehicle,
          Z: row.vehicleReleasedTime,
          AA: row.vehicleReleasedReason,
          AB: row.daysBetweenRepossessedAndReleased,
          AC: row.dateTime,
          AD: row.visitNotDoneAtCustLocation,
          AE: row.disFromCustLocation,
          AF: row.lastPaidTime,
          AG: row.cpName,
          AH: row.alternateMobileNumber,
          AI: row.executive_daily_scheduled_data_id ? "Yes" :"No",
          AJ: row.VisitLocation,
          AK: row.remarks,
        });
      });
    } else if (
      reportCategory === "DETAILED" &&
      type === "Sales" &&
      isReportType === false
    ) {
      salesData?.forEach((row) => {
        salesTable.push({
          A: row.employeeId,
          B: row.employeeName,
          C: row.empStatus,
          D: row.designation,
          E: row.area,
          F: row.branchName,
          G: row.l2ManagerEmpId,
          H: row.l2ManagerName,
          I: row.l3ManagerEmpId,
          J: row.l3ManagerName,
          K: row.branchLocation,
          L: row.reportingOfficerName,
          M: row.empMobileNumber,
          N: row.customerName,
          O: row.contactNumber,
          P: row.address,
          Q: row.customerLocationChanged,
          R: row.visitType,
          S: row.productname,
          T: row.dateTime,
          U: "No",
          V: row.VisitLocation,
          W: row.timeDiffOfPrevVisit,
          X: row.remarks,
        });
      });
    } else if (
      reportCategory === "DETAILED" &&
      type === "All" &&
      isReportType === false
    ) {
      allData?.forEach((row) => {
        allTable.push({
          A: row.employeeId,
          B: row.employeeName,
          C: row.empStatus,
          D: row.designation,
          E: row.area,
          F: row.branchName,
          G: row.branchLocation,
          H: row.l2ManagerEmpId,
          I: row.l2ManagerName,
          J: row.l3ManagerEmpId,
          K: row.l3ManagerName,
          L: row.reportingOfficerName,
          M: row.empMobileNumber,
          N: row.customerName,
          O: row.contactNumber,
          P: row.addressType,
          Q: row.address,
          R: row.customerLocationChanged,
          S: row.newAddress,
          T: row.loanNumber,
          U: row.visitType,
          V: row.status,
          W: row.subStatus,
          X: row.paymentMode,
          Y: row.paymentTowards,
          Z: row.amountPaid,
          AA: row.paymentDate,
          AB: row.transactionId,
          AC: row.toBePaidBy,
          AD: row.paymentDateBy,
          AE: row.ptpAmount,
          AF: row.repossessedVehicleReleased,
          AG: row.amountPaidToReleaseVehicle,
          AH: row.vehicleReleasedTime,
          AI: row.vehicleReleasedReason,
          AJ: row.daysBetweenRepossessedAndReleased,
          AK: row.productname,
          AL: row.applicationNo,
          // AG: row.positiveComments,
          AM: row.rejectionType,
          // AI: row.negativeComments,
          AN: row.loanDate,
          AO: row.loanType,
          AP: row.fiAddress,
          AQ: row.dateTime,
          AR: row.visitNotDoneAtCustLocation,
          AS: row.disFromCustLocation,
          AT: row.lastPaidTime,
          AU: row.cpName,
          AV: row.aggDate,
          AW: row.vehicleNumber,
          AX: row.dueDate,
          AY: row.alternateMobileNumber,
          AZ: row.executive_daily_scheduled_data_id ? "Yes" : row.fiAllocationId ? "Yes" :"No",
          BA: row.VisitLocation,
          BB:row.customerLocation,
          BC: row.timeDiffOfPrevVisit,
          BD: row.remarks,
        });
      });
    } else if (
      reportCategory === "DETAILED" &&
      type === "CallReport" &&
      isReportType === false
    ) {
      callHistoryData?.forEach((row) => {
        callHistoryTable.push({
          A: row.agentId,
          B: row.agentName,
          C: row.agentStatus,
          D: row.cpName,
          E: row.campaignName,
          F: row.loanNumber,
          G: row.customerName,
          H: row.toNumber,
          I: row.direction,
          J: row.callStatus,
          K: row.teleCmiCallStatus,
          L: row.callIntiatedTime,
          M: row.callDuration,
          N: row.hangup_reason,
          O: row.remarks,
          P: row.amountPaid,
          Q: row.currentDue,
          R: row.dueAmount,
          S: row.paymentLinkStatus,
          T: row.allocationStatus,
          U: row.lastVisitStatus,
          V: row.subStatus,
          W: row.paymentMode,
          X: row.paymentTowards,
          Y: row.paymentDate,
          Z: row.transactionId,
          AA: row.toBePaidBy,
          AB: row.ptpSlot,
          AC: row.callBackDate,
          AD: row.callBackSlot,
          AE: row.ptpAmount,
        });
      });
    } else if (reportCategory === "SUMMARY" && type !== "Attendance") {
      if (slctdID === "3" || slctdID === "4") {
        const uniqueKeys = new Set();
        const uniqueKeys2 = new Set();

        summaryOthersData?.forEach((obj) => {
          if (obj?.collectionStatusCounts !== null) {
            Object.keys(obj?.collectionStatusCounts).forEach((key) => {
              uniqueKeys.add(key);
            });
          }
        });
        summaryOthersData?.forEach((obj) => {
          if (obj?.repossessedDetails !== null) {
            Object.keys(obj?.repossessedDetails).forEach((key) => {
              uniqueKeys2.add(key);
            });
          }
        });

        let keys = Array.from(uniqueKeys);
        let keys2 = Array.from(uniqueKeys2);

        for (let i = 0; i < summaryOthersData?.length; i++) {
          for (let j = 0; j < keys?.length; j++) {
            if (summaryOthersData[i]?.collectionStatusCounts !== null) {
              summaryOthersData[i][keys[j]] =
                summaryOthersData[i]?.collectionStatusCounts[keys[j]];
            }
          }
        }
        for (let i = 0; i < summaryOthersData?.length; i++) {
          delete summaryOthersData[i]?.collectionStatusCounts;
        }

        for (let i = 0; i < summaryOthersData?.length; i++) {
          for (let j = 0; j < keys2?.length; j++) {
            if (summaryOthersData[i]?.repossessedDetails !== null) {
              summaryOthersData[i][keys2[j]] =
                summaryOthersData[i]?.repossessedDetails[keys2[j]];
            }
          }
        }
        for (let i = 0; i < summaryOthersData?.length; i++) {
          delete summaryOthersData[i]?.repossessedDetails;
        }

        const uniqueKeys3 = new Set();

        summaryOthersData?.forEach((obj, index) => {
          if (obj !== null) {
            // console.log("index", index);
            Object.keys(obj).forEach((key) => {
              uniqueKeys3.add(key);
            });
          }
        });

        for (let i = 0; i < summaryOthersData?.length; i++) {
          let temp = {};
          if (slctdID === "3") {
            temp["Branch Name"] = summaryOthersData[i]?.branchName;
          }
          if (slctdID === "4") {
            temp["Designation"] = summaryOthersData[i]?.designation;
          }
          if (type === "All" || type === "Collections") {
            for (let j = 0; j < keys?.length; j++) {
              temp[keys[j]] =
                summaryOthersData[i][keys[j]] !== null &&
                summaryOthersData[i][keys[j]] !== undefined &&
                summaryOthersData[i][keys[j]] !== ""
                  ? summaryOthersData[i][keys[j]]
                  : "-";
            }
          }
          if (type === "All" || type === "Sales") {
            temp["Sales Count"] = summaryOthersData[i].salesCount;
          }
          if (type === "All" || type === "Collections") {
            temp["Total Collection Visits"] =
              summaryOthersData[i].collectionCount;
            temp["Total Amount Collected"] =
              summaryOthersData[i].amountCollected;
          }

          if (type === "Repossessed") {
            temp["Repossessed Count"] = summaryOthersData[i].repossessedCount;
          }
          if (type === "Repossessed") {
            for (let j = 0; j < keys2?.length; j++) {
              temp[keys2[j]] =
                summaryOthersData[i][keys2[j]] !== null &&
                summaryOthersData[i][keys2[j]] !== undefined &&
                summaryOthersData[i][keys2[j]] !== ""
                  ? summaryOthersData[i][keys2[j]]
                  : "-";
            }
          }

          if (type === "All" || type === "FieldInvestigation") {
            temp["Field Investigation Count"] =
              summaryOthersData[i].fieldInvestigationCount;
          }
          if (type === "All") {
            temp["Total Visits Count"] = summaryOthersData[i].totalVisits;
          }

          cumulativeTempArray.push(temp);
          // console.log("teo", cumulativeTempArray);
        }
      } else if (
        reportCategory === "SUMMARY" &&
        type === "Sales" &&
        isReportType === false
      ) {
        summaryOthersData?.forEach((row) => {
          summarySalesTable.push({
            A: row.employeeId,
            B: row.employeeName,
            C: row.empStatus,
            D: row.designation,
            E: row.branchName,
            F: row.empMobileNumber,
            G: row.salesCount,
            H: row.duplicateCount,
            I: row.totalDuplicateVisitCounts,
          });
        });
      } else if (
        reportCategory === "SUMMARY" &&
        type === "FieldInvestigation" &&
        isReportType === false
      ) {
        summaryOthersData?.forEach((row) => {
          summaryFieldInvestigationTable.push({
            A: row.employeeId,
            B: row.employeeName,
            C: row.empStatus,
            D: row.designation,
            E: row.branchName,
            F: row.empMobileNumber,
            G: row.positiveCount,
            H: row.negativeCount,
            I: row.fieldInvestigationCount,
            J: row.duplicateCount,
            K: row.totalDuplicateVisitCounts,
          });
        });
      } else if (
        reportCategory === "SUMMARY" &&
        !isReportType &&
        (type === "All" || type === "Collections" || type === "Repossessed")
      ) {
        if (isReportType === false) {
          if (type !== "Repossessed") {
            const uniqueKeys = new Set();

            summaryOthersData?.forEach((obj) => {
              if (obj?.collectionStatusCounts !== null) {
                Object.keys(obj?.collectionStatusCounts).forEach((key) => {
                  uniqueKeys.add(key);
                });
              }
            });

            let keys = Array.from(uniqueKeys);

            for (let i = 0; i < summaryOthersData?.length; i++) {
              for (let j = 0; j < keys?.length; j++) {
                if (summaryOthersData[i]?.collectionStatusCounts !== null) {
                  summaryOthersData[i][keys[j]] =
                    summaryOthersData[i]?.collectionStatusCounts[keys[j]];
                }
              }
            }
            for (let i = 0; i < summaryOthersData?.length; i++) {
              delete summaryOthersData[i]?.collectionStatusCounts;
            }

            const uniqueKeys2 = new Set();

            summaryOthersData?.forEach((obj, index) => {
              if (obj !== null) {
                // console.log("index", index);
                Object.keys(obj).forEach((key) => {
                  uniqueKeys2.add(key);
                });
              }
            });

            for (let i = 0; i < summaryOthersData?.length; i++) {
              let temp = {};
              temp["Employee Id"] = summaryOthersData[i]?.employeeId;
              temp["Employee Name"] = summaryOthersData[i]?.employeeName;
              temp["Employee Status"] = summaryOthersData[i]?.empStatus;
              temp["Designation"] = summaryOthersData[i]?.designation;
              temp["Branch Name"] = summaryOthersData[i]?.branchName;
              temp["Employee Mobile Number"] =
                summaryOthersData[i]?.empMobileNumber;
              temp["Sales Visits"] = summaryOthersData[i]?.salesCount;
              for (let j = 0; j < keys?.length; j++) {
                temp[keys[j]] =
                  summaryOthersData[i][keys[j]] !== null &&
                  summaryOthersData[i][keys[j]] !== undefined &&
                  summaryOthersData[i][keys[j]] !== ""
                    ? summaryOthersData[i][keys[j]]
                    : "-";
              }
              temp["Total Collection Visits"] =
                summaryOthersData[i].collectionCount;
              temp["Total Amount Collected"] =
                summaryOthersData[i].amountCollected;
              temp["Total Positive Responses"] =
                summaryOthersData[i].positiveCount;
              temp["Total Negative Responses"] =
                summaryOthersData[i].negativeCount;
              temp["Field Investigation Visits"] =
                summaryOthersData[i].fieldInvestigationCount;
              temp["Total Visits"] = summaryOthersData[i].totalVisits;
              temp["Multiple Counts Per Customer"] =
                summaryOthersData[i].duplicateCount;
              temp["Total Multiple Visit Counts"] =
                summaryOthersData[i].totalDuplicateVisitCounts;

              tempArray.push(temp);
            }
            if (reportCategory === "SUMMARY" && type === "Collections") {
              for (let i = 0; i < tempArray?.length; i++) {
                delete tempArray[i]["Sales Visits"];
                delete tempArray[i]["Total Positive Responses"];
                delete tempArray[i]["Total Negative Responses"];
                delete tempArray[i]["Field Investigation Visits"];
                delete tempArray[i]["Total Visits"];
              }
            }

            // console.log("others", tempArray);
          } else {
            const uniqueKeys = new Set();

            summaryOthersData?.forEach((obj) => {
              if (obj?.repossessedDetails !== null) {
                Object.keys(obj?.repossessedDetails).forEach((key) => {
                  uniqueKeys.add(key);
                });
              }
            });

            let keys = Array.from(uniqueKeys);

            for (let i = 0; i < summaryOthersData?.length; i++) {
              for (let j = 0; j < keys?.length; j++) {
                if (summaryOthersData[i]?.repossessedDetails !== null) {
                  summaryOthersData[i][keys[j]] =
                    summaryOthersData[i]?.repossessedDetails[keys[j]];
                }
              }
            }
            for (let i = 0; i < summaryOthersData?.length; i++) {
              delete summaryOthersData[i]?.repossessedDetails;
            }

            const uniqueKeys2 = new Set();

            summaryOthersData?.forEach((obj, index) => {
              if (obj !== null) {
                // console.log("index", index);
                Object.keys(obj).forEach((key) => {
                  uniqueKeys2.add(key);
                });
              }
            });

            for (let i = 0; i < summaryOthersData?.length; i++) {
              let temp = {};
              temp["Employee Id"] = summaryOthersData[i]?.employeeId;
              temp["Employee Name"] = summaryOthersData[i]?.employeeName;
              temp["Employee Status"] = summaryOthersData[i]?.empStatus;
              temp["Designation"] = summaryOthersData[i]?.designation;
              temp["Branch Name"] = summaryOthersData[i]?.branchName;
              temp["Employee Mobile Number"] =
                summaryOthersData[i]?.empMobileNumber;
              temp["Repossessed"] = summaryOthersData[i]?.repossessedCount;
              for (let j = 0; j < keys?.length; j++) {
                temp[keys[j]] =
                  summaryOthersData[i][keys[j]] !== null &&
                  summaryOthersData[i][keys[j]] !== undefined &&
                  summaryOthersData[i][keys[j]] !== ""
                    ? summaryOthersData[i][keys[j]]
                    : "-";
              }

              tempArray.push(temp);
            }

            // console.log("others", tempArray);
          }
        }
      } else {
        const uniqueKeys = new Set();

        summaryOthersData?.forEach((obj) => {
          if (obj?.collectionStatusCounts !== null) {
            Object.keys(obj?.collectionStatusCounts).forEach((key) => {
              uniqueKeys.add(key);
            });
          }
        });

        let keys = Array.from(uniqueKeys);

        for (let i = 0; i < summaryOthersData?.length; i++) {
          for (let j = 0; j < keys?.length; j++) {
            if (summaryOthersData[i]?.collectionStatusCounts !== null) {
              summaryOthersData[i][keys[j]] =
                summaryOthersData[i]?.collectionStatusCounts[keys[j]];
            }
          }
        }
        for (let i = 0; i < summaryOthersData?.length; i++) {
          delete summaryOthersData[i]?.collectionStatusCounts;
        }

        const uniqueKeys2 = new Set();

        summaryOthersData?.forEach((obj, index) => {
          if (obj !== null) {
            // console.log("index", index);
            Object.keys(obj).forEach((key) => {
              uniqueKeys2.add(key);
            });
          }
        });

        for (let i = 0; i < summaryOthersData?.length; i++) {
          let temp = {};
          temp["CP Name"] = summaryOthersData[i]?.cpName;
          temp["Branch Name"] = summaryOthersData[i]?.branchName;
          for (let j = 0; j < keys?.length; j++) {
            temp[keys[j]] =
              summaryOthersData[i][keys[j]] !== null &&
              summaryOthersData[i][keys[j]] !== undefined &&
              summaryOthersData[i][keys[j]] !== ""
                ? summaryOthersData[i][keys[j]]
                : 0;
          }
          temp["Total Collection Visits"] =
            summaryOthersData[i].collectionCount;
          temp["Total Amount Collected"] = summaryOthersData[i].amountCollected;

          cpNamesTempArray.push(temp);
          // console.log("teo", cpNamesTempArray);
        }
      }
    } else if (
      reportCategory === "SUMMARY" &&
      type === "Attendance" &&
      isReportType === false
    ) {
      sumamryAttendanceData?.forEach((row) => {
        summaryAttendanceTable.push({
          A: row.employeeId,
          B: row.employeeName,
          C: row.empStatus,
          D: row.designation,
          E: row.branchName,
          F: row.empMobileNumber,
          G: row.daysCheckedIn,
          H: row.daysNotCheckedIn,
          I: row.totalTime,
          J: row.distanceTravelled,
          K:row.visitCount,
        });
      });
    }

    // summaryOthersData.remove('collectionStatusCounts');
    const finalData =
      reportCategory === "DETAILED"
        ? type === "Attendance" && isReportType === false
          ? [...attendaneTable]
          : type === "EmployeePerformanceMetrics" && isReportType === false
          ? [...empPerformanceTable]
          : type === "FieldInvestigation" && isReportType === false
          ? [...fieldInvstgtnTable]
          : type === "Collections"
          ? [...collectionTable]
          : type === "Repossessed"
          ? [...repossessedTable]
          : type === "Sales" && isReportType === false
          ? [...salesTable]
          : type === "All" && isReportType === false
          ? [...allTable]
          : type === "CallReport" && isReportType === false
          ? [...callHistoryTable]
          : dispositionReportData
        : type !== "Attendance"
        ? slctdID === "3" || slctdID === "4"
          ? cumulativeTempArray
          : type === "Sales" && isReportType === false
          ? [...summarySalesTable]
          : type === "FieldInvestigation" && isReportType === false
          ? [...summaryFieldInvestigationTable]
          : type === "All" || type === "Collections" || type === "Repossessed"
          ? isReportType === false
            ? tempArray
            : cpNamesTempArray
          : ""
        : [...summaryAttendanceTable];
    // : cpNamesTempArray;

    // console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet =
      reportCategory === "SUMMARY" && type !== "Attendance"
        ? XLSX.utils.json_to_sheet(finalData)
        : reportCategory === "DETAILED" &&
          (type === "CallDispositionSummaryByLoanNumber" ||
            type === "CallDispositionSummaryByAgent")
        ? XLSX.utils.json_to_sheet(finalData)
        : XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
          });
    // : XLSX.utils.json_to_sheet(finalData);

    // console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(
      wb,
      sheet,
      // eslint-disable-next-line
      isReportType === false
        ? type === "CallDispositionSummaryByLoanNumber"
          ? "Call Disposition"
          : type
        : "CP_Names_Report"
    );

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      "Clu_" +
        (reportCategory === "DETAILED" ? "Detailed" : "Summary") +
        "_" +
        (isReportType === false ? type : "CP_Names") +
        "_Report_" +
        // new Date().toLocaleString("en-US") +
        convertEpochTimeToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );

    // binary large object
    // Since blobs can store binary data, they can be used to store images or other multimedia files.

    // const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Employee Id" || data["A"] === "CP Name"
        ? headerIndexes.push(index)
        : null
    );

    ReactGA.event({
      category: "Report Downlaod",
      action:
        loggedInUser.autoFinanceOrganization.orgshortcode +
          (reportCategory === "DETAILED" ? "Detailed" : "Summary") +
          "_" +
          isReportType ===
        false
          ? type
          : "CP_Names" +
            "_Report_" +
            // new Date().toLocaleString("en-US") +
            convertEpochTimeToDate(Math.floor(new Date() / 1000)) +
            ".xlsx",
    });

    // const totalRecords = (
    //   reportCategory === "DETAILED"
    //     ? type === "Attendance"
    //       ? attendanceData
    //       : type === "FieldInvestigation"
    //       ? fieldInvstgtnData
    //       : type === "Collections"
    //       ? collectionsData
    //       : type === "Sales"
    //       ? salesData
    //       : type === "All"
    //       ? allData
    //       : ""
    //     : type !== "Attendance"
    //     ? summaryOthersData
    //     : sumamryAttendanceData
    // ).length;

    // const dataInfo = {
    //   //   titleCell: "A2",
    //   //   titleRange: "A1:H2",
    //   tbodyRange: `A1:S${finalData.length}`,
    //   theadRange:
    //     headerIndexes?.length >= 1
    //       ? `A${headerIndexes[0] + 1}:${getColumnAlphabet()}${
    //           headerIndexes[0] + 1
    //         }`
    //       : null,
    //   //   theadRange1:
    //   //     headerIndexes?.length >= 2
    //   //       ? `A${headerIndexes[1] + 1}:H${headerIndexes[1] + 1}`
    //   //       : null,
    //   tFirstColumnRange:
    //     headerIndexes?.length >= 1
    //       ? `A${headerIndexes[0] + 1}:A${totalRecords + headerIndexes[0] + 1}`
    //       : null,
    //   tLastColumnRange:
    //     headerIndexes?.length >= 1
    //       ? `${getColumnAlphabet()}${
    //           headerIndexes[0] + 1
    //         }:${getColumnAlphabet()}${totalRecords + headerIndexes[0] + 1}`
    //       : null,

    //   //   tFirstColumnRange1:
    //   //     headerIndexes?.length >= 1
    //   //       ? `A${headerIndexes[1] + 1}:A${totalRecords + headerIndexes[1] + 1}`
    //   //       : null,
    //   //   tLastColumnRange1:
    //   //     headerIndexes?.length >= 1
    //   //       ? `H${headerIndexes[0] + 1}:H${totalRecords + headerIndexes[1] + 1}`
    //   //       : null,
    // };

    // return addStyle(workbookBlob, dataInfo);
  };

  // s
  //   return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
  //     workbook.sheets().forEach((sheet) => {
  //       sheet.usedRange().style({
  //         fontFamily: "Cambria",
  //         verticalAlignment: "center",
  //         wrapText:true
  //       });

  //       sheet.row(1).height(30);
  //       sheet.column("A").width(15);
  //       sheet.column("B").width(15);
  //       sheet.column("C").width(15);
  //       sheet.column("D").width(15);
  //       sheet.column("E").width(15);
  //       sheet.column("F").width(17);
  //       sheet.column("G").width(15);
  //       sheet.column("H").width(15);
  //       sheet.column("I").width(15);
  //       sheet.column("J").width(15);
  //       sheet.column("K").width(15);
  //       sheet.column("L").width(15);
  //       sheet.column("M").width(15);
  //       sheet.column("N").width(15);
  //       sheet.column("O").width(17);
  //       sheet.column("P").width(17);
  //       sheet.column("Q").width(17);
  //       sheet.column("R").width(17);
  //       sheet.column("S").width(15);

  //       // sheet.range(dataInfo.titleRange).merged(true).style({
  //       //   bold: true,
  //       //   horizontalAlignment: "center",
  //       //   verticalAlignment: "center",
  //       // });

  //       if (dataInfo.tbodyRange) {
  //         sheet.range(dataInfo.tbodyRange).style({
  //           horizontalAlignment: "center",
  //         });
  //       }

  //       sheet.range(dataInfo.theadRange).style({
  //         fill: "FFFF33",
  //         bold: true,
  //         horizontalAlignment: "center",
  //       });

  //       // if (dataInfo.theadRange1) {
  //       //   sheet.range(dataInfo.theadRange1).style({
  //       //     fill: "808080",
  //       //     bold: true,
  //       //     horizontalAlignment: "center",
  //       //     fontColor: "ffffff",
  //       //   });
  //       // }

  //       if (dataInfo.tFirstColumnRange) {
  //         sheet.range(dataInfo.tFirstColumnRange).style({
  //           bold: true,
  //         });
  //       }

  //       if (dataInfo.tLastColumnRange) {
  //         sheet.range(dataInfo.tLastColumnRange).style({
  //           // bold: true,
  //         });
  //       }

  //       // if (dataInfo.tFirstColumnRange1) {
  //       //   sheet.range(dataInfo.tFirstColumnRange1).style({
  //       //     bold: true,
  //       //   });
  //       // }

  //       // if (dataInfo.tLastColumnRange1) {
  //       //   sheet.range(dataInfo.tLastColumnRange1).style({
  //       //     bold: true,
  //       //   });
  //       // }
  //     });

  //     return workbook
  //       .outputAsync()
  //       .then((workbookBlob) => URL.createObjectURL(workbookBlob));
  //   });
  // };

  const handleClose = () => {
    setBasicModal(false);
    setTitle("Executives");
  };
  const handleExecutives = (list) => {
    setSelectedExecutives(list);
    setSelectedExecutivesCount(list.length);
  };

  const handleActiveUser = (iactvUsrs) => {
    setActiveUsers(iactvUsrs);
  };

  const getHangUpReason = (value) => {
    if (value === "sent_reject") {
      return "Receiver Rejected";
    } else if (value === "recv_cancel") {
      return "Caller Rejected";
    } else if (value === "sent_bye") {
      return "Receiver Hung Up";
    } else if (value === "recv_bye") {
      return "Caller Hung Up";
    }
  };

  const setDateValue = (label, value) => {
    // console.log("value", value);
    var date;
    var start, end;
    switch (value) {
      case 1:
        var today = new Date();
        start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
        end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
        setStartDate(start);
        setEndDate(end);
        setShowDate(false);
        return;

      case 2:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);

        setStartDate(start);
        setEndDate(end);
        setShowDate(false);
        return;
      case 3:
        date = [
          new Date(
            new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
              0,
              0,
              0
            )
          ),
          new Date(
            new Date(new Date().setDate(new Date().getDate())).setHours(
              23,
              59,
              59
            )
          ),
        ];
        start = Math.floor(date[0].getTime() / 1000);
        end = Math.floor(date[1].getTime() / 1000);
        setStartDate(start);
        setEndDate(end);
        setShowDate(false);
        return;
      case 4:
        setShowDate(true);
        break;
      default:
    }
  };

  const optionDates = [
    { label: "Today", value: 1 },
    { label: "Last 7 Days", value: 2 },
    { label: "Last 30 Days", value: 3 },
    { label: "Custom", value: 4 },
  ];

  const schedulePeriods = [
    { label: "Weekly", value: "WEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
  ];
  const times = [
    { label: "01:00 AM", value: "1" },
    { label: "02:00 AM", value: "2" },
    { label: "03:00 AM", value: "3" },
    { label: "04:00 AM", value: "4" },
    { label: "05:00 AM", value: "5" },
    { label: "06:00 AM", value: "6" },
    { label: "07:00 AM", value: "7" },
    { label: "08:00 AM", value: "8" },
    { label: "09:00 AM", value: "9" },
    { label: "10:00 AM", value: "10" },
    { label: "11:00 AM", value: "11" },
    { label: "12:00 PM", value: "12" },
    { label: "01:00 PM", value: "13" },
    { label: "02:00 PM", value: "14" },
    { label: "03:00 PM", value: "15" },
    { label: "04:00 PM", value: "16" },
    { label: "05:00 PM", value: "17" },
    { label: "06:00 PM", value: "18" },
    { label: "07:00 PM", value: "19" },
    { label: "08:00 PM", value: "20" },
    { label: "09:00 PM", value: "21" },
    { label: "10:00 PM", value: "22" },
    { label: "11:00 PM", value: "23" },
    { label: "12:00 AM", value: "0" },
  ];

  const getTime = (time) => {
    if (time > 12) {
      return time - 12 + ":00 PM";
    } else {
      return time + ":00 AM";
    }
  };

  const columns = [
    {
      text: "Report Type",
      dataField: "reportType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Schedule Period",
      dataField: "scheduleTypes",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Email ID",
      dataField: "email",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Scheduled Time",
      dataField: "hour",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-center">
            <span>{getTime(row.hour)}</span>
          </div>
        );
      },
    },

    {
      text: "Action",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-center">
            <Link to="#" className="btn btn-danger shadow btn-xs sharp">
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          swal({
            title: "Delete",
            text: "Are you sure, you want to delete this scheduled report?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              var payLoad = {
                scheduleRequestId: row.id,
                userId: loggedInUser.autoFinanceUser.userId,
              };
              moduleApi
                .postData(apiCall.deleteScheduledReport, payLoad)
                .then(() => {
                  swal("Deleted successfully!", {
                    icon: "success",
                  });
                  getScheduledReports();
                });
            }
          });
        },
      },
    },
  ];

  function setCustomDates(dateStrings) {
    if (!dateStrings[1]) return;
    setStartDate(Math.floor(new Date(dateStrings[0]).getTime() / 1000));
    setEndDate(Math.floor(new Date(dateStrings[1]).getTime() / 1000) + 86399);
  }

  const onPageChange = (selectedPage) => {
    onSubmit(startDate, endDate, selectedPage - 1, false);
  };
  // const pageHandler = (pageNumber) => {
  //   setPerpage(summaryData.slice(pageNumber * 10 - 10, pageNumber * 10));
  // };

  const onSelectedID = (id) => {
    setSlctdID(id);
    // setIsCumulative(0)
    // console.log("zero", typeof id);
    if (id === "5") {
      setType("Collections");
      setT("Collections");
      setIsReportType(true);
      // console.log("first", id);
    } else {
      setIsReportType(false);
    }
    // console.log(isCumulative);
    if ((id === "3" || id === "4") && reportCategory === "SUMMARY") {
      setIsCumulative(1);
      if (id === "4") {
        setIsCumulative(2);
      }
    } else {
      setIsCumulative(0);
    }
  };

  const setTitle = (value) => {
    setModalTitle(value);
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
    return hDisplay + mDisplay + sDisplay;
  };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const getDistTravelledInKM = (distanceTravlled) => {
    if (distanceTravlled !== 0 && distanceTravlled) {
      var km = distanceTravlled / 1000.0;
      return km.toFixed(2) + " km";
    } else {
      return "-";
    }
  };
  const getAmountPaid = (amount) => {
    if (amount !== 0 && amount) {
      var km = amount / 1;
      return km.toFixed(2);
    } else {
      return "-";
    }
  };

  return (
    <div className="card">
      <LoadingOverlay
        active={isLoading2}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              marginRight: "auto",
              marginLeft: "20px",
              alignItems: "center",
            }}
          >
            {/* {loggedInUser.isSuperAdmin !== true && hasAccess(17) && (
              <Button
                className=""
                style={{
                  backgroundColor: "#218a3f",
                  borderColor: "#218a3f",
                  borderRadius: "5px",
                  height: "35px",
                  width: "140px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  // console.log("attens", tempData);
                  getScheduledReports();
                  setScheduleModal(true);
                }}
              >
                Schedule Reports
              </Button>
            )} */}
          </div>
          {loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  marginLeft: "10px",
                  marginBottom: "0px",
                  fontWeight: "600",
                  marginTop: "5px",
                }}
              >
                Report Type
              </label>
              <select
                name="cars"
                id="reporttype"
                style={{
                  backgroundColor: "white",
                  height: "40px",
                  width: "110px",
                  color: "rgb(115 115 115)",
                  border: "solid",
                  textAlign: "center",
                  borderRadius: "3px",
                  margin: "5px 5px ",
                  borderWidth: "1px",
                  //   paddingRight:'10px',
                  boxShadow: "1px 1px 2px #797979",
                }}
                onChange={(e) => onReportCategoryChange(e.target.value)}
              >
                <option value="DETAILED" label="Detailed" />
                <option value="SUMMARY" label="Summary" />
              </select>
            </div>
          )}
          {loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  marginLeft: "10px",
                  marginBottom: "0px",
                  fontWeight: "600",
                  marginTop: "5px",
                }}
              >
                Select Users
              </label>
              <button
                style={{
                  backgroundColor: "white",
                  height: "40px",
                  width: "110px",
                  color: "rgb(115 115 115)",
                  border: "solid",
                  borderRadius: "3px",
                  margin: "5px 5px ",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
                onClick={() => setBasicModal(true)}
                id="selectusers"
              >
                {selectedExecutivesCount} Selected
              </button>
            </div>
          )}

          {isReportType === false &&
            type === "CallDispositionSummaryByLoanNumber" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    marginLeft: "10px",
                    marginBottom: "0px",
                    fontWeight: "600",
                    marginTop: "5px",
                  }}
                >
                  Loan Number
                </label>
                <input
                  style={{
                    width: "230px",
                    height: "35px",
                    borderRadius: "25px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    paddingLeft: "10px",
                    marginRight: "10px",
                    marginTop: "5px",
                  }}
                  placeholder="Enter Loan Number"
                  onChange={(e) => {
                    setEnteredLoanNumber(e.target.value.trim());
                    // console.log("loanNumber", enteredLoanNumber);
                  }}
                />
              </div>
            )}

          {isReportType === false && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  marginLeft: "10px",
                  marginBottom: "0px",
                  fontWeight: "600",
                  marginTop: "5px",
                }}
              >
                Select Type
              </label>
              <select
                name="cars"
                id="selecttypes"
                value={type}
                style={{
                  backgroundColor: "white",
                  height: "40px",
                  width:
                    loggedInUser.autoFinanceUser.roleName === "CP_USER"
                      ? "250px"
                      : "140px",
                  color: "rgb(115 115 115)",
                  border: "solid",
                  textAlign: "center",
                  borderRadius: "3px",
                  margin: "5px 5px ",
                  borderWidth: "1px",
                  //   paddingRight:'10px',
                  boxShadow: "1px 1px 2px #797979",
                }}
                onChange={(e) => onTypeChange(e.target.value)}
              >
                {hasAccess(92) && loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
                  <option value="All" label="All" />
                )}
                {hasAccess(95) && (
                  <option value="Collections" label="Collections" />
                )}
                {hasAccess(93) &&
                  loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
                    <option value="Sales" label="Sales" />
                  )}
                {hasAccess(96) &&
                  isCumulative !== 1 &&
                  isCumulative !== 2 &&
                  loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
                    // slctdID !== "4" &&
                    // slctdID !== "3" &&(
                    // reportCategory !== "SUMMARY" && (
                    <option value="Attendance" label="Attendance" />
                  )}
                {
                  hasAccess(94) &&
                    loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
                      //  && reportCategory !== "SUMMARY" && (
                      <option
                        value="FieldInvestigation"
                        label="Field Investigation"
                      />
                    )
                  // )
                }
                {/* {hasAccess(5) && ( */}
                {isCumulative !== 2 && hasAccess(97) && 
                  loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
                    <option value="Repossessed" label="Repossessed" />
                  )}
                {loggedInUser.autoFinanceUser.roleName !== "CP_USER" && hasAccess(98) && 
                  reportCategory !== "SUMMARY" && (
                    <option
                      value="EmployeePerformanceMetrics"
                      label="Employee Performance Metrics"
                    />
                  )}
                {loggedInUser.autoFinanceUser.roleName === "CP_USER" && (
                  <option value="CallReport" label="Call Report" />
                )}
                {loggedInUser.autoFinanceUser.roleName === "CP_USER" && (
                  <option
                    value="CallDispositionSummaryByLoanNumber"
                    label="Disposition Summary By Loan No"
                  />
                )}
                {/* {loggedInUser.autoFinanceUser.roleName === "CP_USER" && (
                  <option
                    value="CallDispositionSummaryByAgent"
                    label="Disposition Summary By Agent"
                  />
                )} */}
              </select>
            </div>
          )}

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                marginLeft: "10px",
                marginBottom: "0px",
                fontWeight: "600",
                marginTop: "5px",
              }}
            >
              Dates
            </label>
            <div
              style={{
                backgroundColor: "white",
                height: "40px",
                width: "120px",
                textAlign: "center",
                color: "rgb(115 115 115)",
                border: "solid",
                borderRadius: "3px",
                margin: "5px 5px ",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
              // placeholder="dd M,yyyy"
              placeholder="Start & End Date"
              onChange={(value) => {
                setCustomDates(value);
              }}
              options={{
                mode: "range",
                dateFormat: "d-M-y",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) =>
                  setDateValue(value.label, value.value, optionDates)
                }
                defaultValue={optionDates[0]}
                options={optionDates}
                id="startenddatesreports"
              />
            </div>
          </div>

          {showDate && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Flatpickr
                style={{
                  backgroundColor: "white",
                  height: "40px",
                  width: "160px",
                  textAlign: "center",
                  color: "rgb(115 115 115)",
                  border: "solid",
                  borderRadius: "3px",
                  margin: "5px 5px ",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
                // placeholder="dd M,yyyy"
                placeholder="Pick Custom Date"
                onChange={(value) => {
                  setCustomDates(value);
                }}
                options={{
                  mode: "range",
                  dateFormat: "d-M-y",
                  maxDate: new Date(),
                }}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-end",
            }}
          >
            <Button
              style={{
                margin: "5px 5px ",
                marginRight: "15px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="primary btn-rounded"
              onClick={() => onSubmit(startDate, endDate, 0, false)}
              id="submitbtnreports"
            >
              Submit
            </Button>
          </div>

          {hasAccess(21) && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-end",
              }}
            >
              <Button
                style={{
                  margin: "5px 5px ",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => {
                  //  onSubmit(startDate, endDate, 0, false)
                  createDownLoadData();
                }}
              >
                <i className="fa fa-download"></i>
                &nbsp;&nbsp;Download
              </Button>
              {/* <ExcelExportHelper data={attendanceData} reportCategory={reportCategory} reportType={type} /> */}
            </div>
          )}
        </div>

        {/* <div style={{
        marginLeft:'20px',
        marginBottom:'10px',
      }}>
        <span style={{fontWeight:'bold',fontSize:'15px'}}>Total Count : </span>
        <span style={{fontWeight:'bold',fontSize:'15px'}}>Total Count : </span>
      </div> */}

        <Modal className="fade" show={scheduleModal} size="xl">
          <Modal.Header>
            <Modal.Title>Schedule Reports</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                setScheduleModal(false);
                setIsFields(false);
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ paddingTop: "0px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "10px",
                marginBottom: "10px",
                float: "left",
              }}
            >
              {isFields && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "150px",
                    marginRight: "10px",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "10px",
                      marginBottom: "0px",
                      fontWeight: "600",
                      marginTop: "5px",
                    }}
                  >
                    Report Type
                  </label>
                  <div>
                    <Select
                      isSearchable={false}
                      onChange={(value) => setScheduledReportType(value.value)}
                      // defaultValue={reportTypes[0]}
                      options={dropDownOptions}
                      placeholder={"Select type"}
                    />
                  </div>
                </div>
              )}
              {isFields && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "150px",
                    marginRight: "10px",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "10px",
                      marginBottom: "0px",
                      fontWeight: "600",
                      marginTop: "5px",
                    }}
                  >
                    Schedule Period
                  </label>
                  <div>
                    <Select
                      isSearchable={false}
                      onChange={(value) => setScheduledPeriodType(value.value)}
                      defaultValue={schedulePeriods[0]}
                      options={schedulePeriods}
                    />
                  </div>
                </div>
              )}
              {isFields && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "220px",
                    marginRight: "10px",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "10px",
                      marginBottom: "0px",
                      fontWeight: "600",
                      marginTop: "5px",
                    }}
                  >
                    Email ID
                  </label>
                  <div>
                    <input
                      style={{
                        height: "37px",
                        width: "220px",
                        borderRadius: "5px",
                        borderColor: "#a0a0a0",
                        borderWidth: "0.5px",
                        paddingLeft: "5px",
                      }}
                      placeholder="Enter Email ID"
                      type="email"
                      onChange={(value) => setEmail(value.target.value)}
                    />
                  </div>
                </div>
              )}

              {isFields && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100px",
                    marginRight: "10px",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "10px",
                      marginBottom: "0px",
                      fontWeight: "600",
                      marginTop: "5px",
                    }}
                  >
                    Time
                  </label>
                  <div>
                    <Select
                      isSearchable={false}
                      onChange={(value) => setTime(value.value)}
                      defaultValue={times[0]}
                      options={times}
                    />
                  </div>
                </div>
              )}
              {isFields && (
                <Button
                  style={{
                    float: "right",
                    marginTop: "20px",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    addScheduledReport();
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
            {!isFields && (
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
                onClick={() => setIsFields(true)}
              >
                + Add
              </Button>
            )}

            <div
              style={{ background: "white", margin: "10px", marginTop: "15px" }}
            >
              <BootstrapTable
                keyField="id"
                data={scheduledReportsData}
                columns={columns}
              />
              {scheduledReportsData.length === 0 && (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              )}

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: totalElements == 0 ? "90px 0px 5px 0px" : "0px",
                }}
              >
                <PaginationComponent
                  totalItems={totalElements}
                  pageSize={10}
                  defaultActivePage={currPage + 1}
                  onSelect={onPageChange}
                />
              </div> */}
            </div>
          </Modal.Body>
        </Modal>

        <Modal className="fade" show={basicModal} size="lg">
          <Modal.Header>
            <Modal.Title>Select {modalTitle}</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setBasicModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body style={{ paddingTop: "0px" }}>
            <SelectExecutives
              list={selectedExecutives}
              onOk={(list, id, iactvUsrs) => {
                onSelectedID(id);
                handleExecutives(list);
                handleActiveUser(iactvUsrs);
              }}
              close={handleClose}
              setTitle={setTitle}
              slctdID={slctdID}
              iactvUsrs={activeUsers}
            />
          </Modal.Body>
        </Modal>

        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div style={{ margin: "0px 20px" }}>
            {/* <button
              onClick={() => {
                tempButton();
              }}
            >
              click me
            </button> */}
            <Table responsive hover bordered>
              <thead
                style={{
                  backgroundColor: "#F3F0F9",
                  top: "1px",
                  position: "sticky",
                }}
              >
                {!flag ? (
                  t === "Attendance" && !isCpNames ? (
                    <tr
                      style={{
                        verticalAlign: "middle",
                        textAlignLast: "center",
                      }}
                    >
                      <th>Name</th>
                      <th>Employee Id</th>
                      <th>Designation</th>
                      <th>Area</th>
                      <th>Branch Name</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Employee{" "}
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Mobile Number
                          </Col>
                        </Row>
                      </th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Check In Time</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            First Check In{" "}
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            {" "}
                            Location
                          </Col>
                        </Row>
                      </th>
                      <th>Check Out Time</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Last Check Out{" "}
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            {" "}
                            Location
                          </Col>
                        </Row>
                      </th>
                      <th>Working Hours</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Distance{" "}
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Travelled
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Emp </Col>
                          <Col style={{ textAlignLast: "center" }}>Status</Col>
                        </Row>
                      </th>
                      <th>Phone Make</th>
                      <th>Phone Model</th>
                      <th>Visit Count</th>
                      {/* <th>Sales Count</th>
                    <th>Collection Count</th>
                    <th>Field Investigation</th>
                    <th>Amount Collected</th>
                    <th>Expenses</th> */}
                    </tr>
                  ) : t === "EmployeePerformanceMetrics" && !isCpNames ? (
                    <tr
                      style={{
                        verticalAlign: "middle",
                        textAlignLast: "center",
                      }}
                    >
                      <th>Employee Name</th>
                      <th>Employee Id</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Visit </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Frequency
                          </Col>
                        </Row>
                      </th>
                      <th>Recovery rate</th>
                      <th>Collection Day</th>
                      <th>Avg Collection Time</th>
                      <th>Year & Month</th>
                    </tr>
                  ) : t === "FieldInvestigation" && !isCpNames ? (
                    <tr
                      style={{
                        verticalAlign: "middle",
                        textAlignLast: "center",
                      }}
                    >
                      <th>Application No</th>
                      <th>Client</th>
                      <th>Applicant Type</th>
                      <th>FI Type</th>
                      <th>Applican't Name</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                          Applicant's
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Contact Number
                          </Col>
                        </Row>
                      </th>
                      <th>Product Type</th>
                      <th>CPV Verification Limit</th>
                      <th>Type Of Visit</th>
                      <th>FI Result</th>
                      <th>Allocation Status</th>
                      <th>Rejection Reason</th>
                      <th>Verification Received On</th>
                      <th>Visited On</th>
                      <th>Report Completed On</th>
                      <th>Emp Id</th>
                      <th>Emp Name</th>
                      <th>Verifier's Emp ID</th>
                      <th>Verifier's Emp Name</th>
                      <th>Applicant Working Status</th>
                      <th>End User Of The Product</th>
                      <th>No.of Yrs of Stay in City</th>
                      <th>No.of Yrs At Residence</th>
                      <th>Ownership</th>
                      <th>Neighbourhood Locality</th>
                      <th>Standard Of Living</th>
                      <th>Area Locality</th>
                      <th>Applicant Profile</th>
                      <th>No.of Yrs in Employment/Bussiness</th>
                      <th>Total Employment/Bussiness Stability(In Yrs)</th>
                      <th> BIZ Activity</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                          Residence/Office
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                          Interior Condition
                          </Col>
                        </Row>
                      </th>
                      <th>No.of Visits</th>
                      <th>Visit Address</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                           Visit Latitude &
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            {" "}
                            Longitude
                          </Col>
                        </Row>
                      </th>
                      <th>Cust Address</th>
                      <th>Cust City</th>
                      <th>Cust State</th>
                      <th>Office Name</th>
                      <th>Office/Bussiness Address</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Time Difference
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            B/W Prev Visit
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Visit</Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Allocated
                          </Col>
                        </Row>
                      </th>
                      <th>Loan Date</th>
                      <th>Loan Type</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Is Cust Address
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Changed</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Emp Branch
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Name</Col>
                        </Row>
                      </th>                     
                     <th>Emp State</th>
                     <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Emp Mobile{" "}
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Number
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Employee
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Status</Col>
                        </Row>
                      </th>
                      <th>Designation</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Visit</Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Under Manager
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            L2 Manager
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Emp ID</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            L2 Manager
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Emp Name
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            L3 Manager
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Emp ID</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            L3 Manager
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Emp Name
                          </Col>
                        </Row>
                      </th>

                      {/* <th>Positive Remarks</th> */}
                      
                      {/* <th>Negative Remarks</th> */}

                      <th>Remarks</th>
                    </tr>
                  ) : t !== "CallReport" &&
                    t !== "CallDispositionSummaryByLoanNumber" ? (
                    <tr
                      style={{
                        verticalAlign: "middle",
                        textAlignLast: "center",
                      }}
                    >
                      <th>Employee Id</th>
                      <th>Employee Name</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Employee
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Status</Col>
                        </Row>
                      </th>
                      <th>Designation</th>
                      <th>Area</th>
                      <th>Branch Name</th>
                      <th>State</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            L2 Manager
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Emp ID</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            L2 Manager
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Emp Name
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            L3 Manager
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Emp ID</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            L3 Manager
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Emp Name
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Visit</Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Under Manager
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Employee{" "}
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Mobile Number
                          </Col>
                        </Row>
                      </th>
                      <th>Customer Name</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Customer
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Contact Number
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Address</Col>
                          <Col style={{ textAlignLast: "center" }}>Type</Col>
                        </Row>
                      </th>
                      <th>Visit Address</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Is Cust Address
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Changed</Col>
                        </Row>
                      </th>
                      {t === "Collections" ||
                      t === "All" ||
                      t === "Repossessed" ? (
                        <>
                          <th>New Address</th>
                          <th>Loan Number</th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th>Funder Loan No.</th>
                      <th>Type Of Visit </th>
                      {t === "Collections" ||
                      t === "All" ||
                      t === "Repossessed" ? (
                        <>
                          <th>Status</th>
                          {t === "Repossessed" && <th>Yard Name</th>}
                          {t !== "Repossessed" && (
                            <>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Sub
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Status
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Payment
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Mode
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Payment
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Towards
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    EMI Paid
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Late Payment
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Charges Paid
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Bounce Charges
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Paid
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Penalty Charges
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Paid
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Other Charges
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Paid
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Total Amount
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Paid
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Payment
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Date
                                  </Col>
                                </Row>
                              </th>
                              <th>Transaction Id</th>

                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Promised
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Payment Date{" "}
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Promised
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Time Slot
                                  </Col>
                                </Row>
                              </th>
                              <th>
                                <Row>
                                  <Col style={{ textAlignLast: "center" }}>
                                    PTP
                                  </Col>
                                  <Col style={{ textAlignLast: "center" }}>
                                    Amount
                                  </Col>
                                </Row>
                              </th>
                            </>
                          )}
                          <th>
                            <Row>
                              <Col style={{ textAlignLast: "center" }}>
                                Is Vehicle
                              </Col>
                              <Col style={{ textAlignLast: "center" }}>
                                Released
                              </Col>
                            </Row>
                          </th>
                          <th>
                            <Row>
                              <Col style={{ textAlignLast: "center" }}>
                                Amount Paid for
                              </Col>
                              <Col style={{ textAlignLast: "center" }}>
                                Vehicle Release
                              </Col>
                            </Row>
                          </th>
                          <th>
                            <Row>
                              <Col style={{ textAlignLast: "center" }}>
                                Vehicle
                              </Col>
                              <Col style={{ textAlignLast: "center" }}>
                                Released Date
                              </Col>
                            </Row>
                          </th>
                          <th>
                            <Row>
                              <Col style={{ textAlignLast: "center" }}>
                                Reason For
                              </Col>
                              <Col style={{ textAlignLast: "center" }}>
                                Vehicle Release
                              </Col>
                            </Row>
                          </th>
                          <th>
                            <Row>
                              <Col style={{ textAlignLast: "center" }}>
                                Days B/W
                              </Col>
                              <Col style={{ textAlignLast: "center" }}>
                                Repossessed & Released
                              </Col>
                            </Row>
                          </th>
                          {/* <th>
                            <Row>
                              <Col style={{ textAlignLast: "center" }}>
                                Is Unable{" "}
                              </Col>
                              <Col style={{ textAlignLast: "center" }}>
                                To Pay
                              </Col>
                            </Row>
                          </th> */}
                        </>
                      ) : (
                        <></>
                      )}
                      {(t === "Sales" || t === "All") && !isCpNames ? (
                        <th>Product Name</th>
                      ) : (
                        <></>
                      )}
                      {(t === "FieldInvestigation" || t === "All") &&
                      !isCpNames ? (
                        <>
                          <th>Application No</th>
                          {/* <th>Positive Remarks</th> */}
                          <th>Rejection Reason</th>
                          {/* <th>Negative Remarks</th> */}
                          <th>Loan Date</th>
                          <th>Loan Type</th>
                          <th>FI Address</th>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* {t === "Collections" || t === "All" ? (
                        <th>
                          <Row>
                            <Col style={{ textAlignLast: "center" }}>
                              Dist B/W Customer &
                            </Col>
                            <Col style={{ textAlignLast: "center" }}>
                              {" "}
                              Visited Location
                            </Col>
                          </Row>
                        </th>
                      ) : (
                        <></>
                      )} */}
                      <th>Visited On</th>
                      {t === "Collections" ||
                      t === "All" ||
                      t === "Repossessed" ? (
                        <>
                          <th>Is Visit Done at Customer Address</th>
                          <th>Dist B/W Cust Addr & Visit Addr</th>
                          <th>Last Paid</th>
                          <th>CP Name</th>
                          {t !== "Repossessed" && (
                            <>
                              <th>AG Date</th>
                              <th>Vehicle No</th>
                              <th>Due Date</th>
                            </>
                          )}
                          <th>
                            <Row>
                              <Col style={{ textAlignLast: "center" }}>New</Col>
                              <Col style={{ textAlignLast: "center" }}>
                                Mobile No
                              </Col>
                            </Row>
                          </th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Visit </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Allocated
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                           Visit Latitude &
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            {" "}
                            Longitude
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                           Customer Latitude &
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            {" "}
                            Longitude
                          </Col>
                        </Row>
                      </th>
                      {t !== "Repossessed" && (
                        <th>
                          <Row>
                            <Col style={{ textAlignLast: "center" }}>
                              Time Difference
                            </Col>
                            <Col style={{ textAlignLast: "center" }}>
                              B/W Prev Visit
                            </Col>
                          </Row>
                        </th>
                      )}
                      
                      <th>Remarks</th>
                      {/* {t === "Attendance" || t === "All" ?
                      <>
                        <th>Check In Time</th>
                        <th>Check Out Time</th>
                        <th>Working  Hours</th>
                      </> : <></>} */}

                      {/* <th>Address</th> */}
                    </tr>
                  ) : t === "CallReport" ? (
                    <tr
                      style={{
                        verticalAlign: "middle",
                        textAlignLast: "center",
                      }}
                    >
                      <th>Emp Id</th>
                      <th>Emp Name</th>
                      <th>Emp Status</th>
                      <th>CP Name</th>
                      <th>Campaign</th>
                      <th>Loan Number</th>
                      <th>Customer Name</th>

                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Called</Col>
                          <Col style={{ textAlignLast: "center" }}>Number</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Call</Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Direction
                          </Col>
                        </Row>
                      </th>

                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Call</Col>
                          <Col style={{ textAlignLast: "center" }}>Status</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Call</Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Sub Status
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Call</Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Initiated At
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Call</Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Duration
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>HangUp</Col>
                          <Col style={{ textAlignLast: "center" }}>Reason</Col>
                        </Row>
                      </th>
                      <th>Remarks</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Amount</Col>
                          <Col style={{ textAlignLast: "center" }}>Paid</Col>
                        </Row>
                      </th>
                      <th>Current Due</th>
                      <th>Total Due</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Payment Link
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Sent Status
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Allocation
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Status</Col>
                        </Row>
                      </th>
                      <th>Status</th>
                      <th>Sub Status</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Payment</Col>
                          <Col style={{ textAlignLast: "center" }}>Mode</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Payment</Col>
                          <Col style={{ textAlignLast: "center" }}>Towards</Col>
                        </Row>
                      </th>

                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>Payment</Col>
                          <Col style={{ textAlignLast: "center" }}>Date</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Transaction
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Id</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Promised
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Payment Date
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Promised
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Time Slot
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Call Back
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>Date</Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Call Back
                          </Col>
                          <Col style={{ textAlignLast: "center" }}>
                            Time Slot
                          </Col>
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>PTP</Col>
                          <Col style={{ textAlignLast: "center" }}>Amount</Col>
                        </Row>
                      </th>
                    </tr>
                  ) : (
                    <tr style={{ textAlign: "center" }}>
                      {keys.map((key) => {
                        return <th>{key}</th>;
                      })}
                    </tr>
                  )
                ) : t !== "Attendance" ? (
                  !isCpNames ? (
                    slctdID === "3" || slctdID === "4" ? (
                      <tr
                        style={{
                          verticalAlign: "middle",
                          textAlignLast: "center",
                        }}
                      >
                        {slctdID === "3" ? (
                          <th>Branch Name</th>
                        ) : slctdID === "4" ? (
                          <th>Designation</th>
                        ) : (
                          ""
                        )}
                        {(t === "All" || t === "Collections") &&
                          keys.map((key) => {
                            return <th>{key}</th>;
                          })}

                        {(t === "All" || t === "Sales") && <th>Sales Count</th>}
                        {(t === "All" || t === "Collections") && (
                          <>
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Total Collection Visits
                                </Col>
                                {/* <Col style={{ textAlignLast: "center" }}>
                            Visits
                          </Col> */}
                              </Row>
                            </th>
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Total Amount Collected
                                </Col>
                                {/* <Col style={{ textAlignLast: "center" }}>
                            {" "}
                            Collected
                          </Col> */}
                              </Row>
                            </th>
                          </>
                        )}
                        {t === "Repossessed" && <th>Repossessed Count</th>}
                        {t === "Repossessed" &&
                          keys.map((key) => {
                            return <th>{key}</th>;
                          })}
                        {(t === "All" || t === "FieldInvestigation") && (
                          <th>Field Investigation Count</th>
                        )}
                        {t === "All" && <th>Total Visits Count</th>}
                      </tr>
                    ) : (
                      <tr
                        style={{
                          verticalAlign: "middle",
                          textAlignLast: "center",
                        }}
                      >
                        <th>Employee Id</th>
                        <th>Employee Name</th>
                        <th>
                          <Row>
                            <Col style={{ textAlignLast: "center" }}>
                              Employee{" "}
                            </Col>
                            <Col style={{ textAlignLast: "center" }}>
                              Status
                            </Col>
                          </Row>
                        </th>{" "}
                        <th>Designation</th>
                        <th>Branch Name</th>
                        <th>
                          <Row>
                            <Col style={{ textAlignLast: "center" }}>
                              Employee{" "}
                            </Col>
                            <Col style={{ textAlignLast: "center" }}>
                              Mobile Number
                            </Col>
                          </Row>
                        </th>
                        {t === "Repossessed" && <th>Repossessed</th>}
                        {t === "Repossessed" &&
                          keys.map((key) => {
                            return <th>{key}</th>;
                          })}
                        {t === "Sales" || t === "All" ? (
                          <th>Sales Visits</th>
                        ) : (
                          <></>
                        )}
                        {t === "Collections" || t === "All" ? (
                          <>
                            {t === "FieldInvestigation" ||
                            t === "Collections" ||
                            t === "All" ? (
                              <>
                                {keys.map((key) => {
                                  return <th>{key}</th>;
                                })}
                                {/* <th>PTP</th>
                            <th>Paid</th>
                            <th>RTP</th>
                            <th>Repossessed</th>
                            <th>Third Party</th>
                            <th>Vacant</th>
                            <th>Customer N/A</th> */}
                              </>
                            ) : (
                              <></>
                            )}
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Total Collection
                                </Col>
                                <Col style={{ textAlignLast: "center" }}>
                                  {" "}
                                  Visits
                                </Col>
                              </Row>
                            </th>
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Total Amount{" "}
                                </Col>
                                <Col style={{ textAlignLast: "center" }}>
                                  {" "}
                                  Collected
                                </Col>
                              </Row>
                            </th>
                          </>
                        ) : (
                          <></>
                        )}
                        {t === "FieldInvestigation" || t === "All" ? (
                          <>
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Total Positive
                                </Col>
                                <Col style={{ textAlignLast: "center" }}>
                                  {" "}
                                  Responses
                                </Col>
                              </Row>
                            </th>
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Total Negative
                                </Col>
                                <Col style={{ textAlignLast: "center" }}>
                                  {" "}
                                  Responses
                                </Col>
                              </Row>
                            </th>
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Field Investigation
                                </Col>
                                <Col style={{ textAlignLast: "center" }}>
                                  {" "}
                                  Visits
                                </Col>
                              </Row>
                            </th>
                          </>
                        ) : (
                          <></>
                        )}
                        {t === "All" ? (
                          <th>
                            <Row>
                              <Col style={{ textAlignLast: "center" }}>
                                Total
                              </Col>
                              <Col style={{ textAlignLast: "center" }}>
                                {" "}
                                Visits
                              </Col>
                            </Row>
                          </th>
                        ) : (
                          <></>
                        )}
                        {t !== "Attendance" && t !== "Repossessed" && (
                          <>
                            {" "}
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Multiple Counts
                                </Col>
                                <Col style={{ textAlignLast: "center" }}>
                                  {" "}
                                  Per Customer
                                </Col>
                              </Row>
                            </th>
                            <th>
                              <Row>
                                <Col style={{ textAlignLast: "center" }}>
                                  Total Multiple
                                </Col>
                                <Col style={{ textAlignLast: "center" }}>
                                  Visit Counts
                                </Col>
                              </Row>
                            </th>
                          </>
                        )}
                        {/* {t === "Attendance" || t === "All" ?
                    <>
                      <th>Total Days Present</th>
                      <th>Total Days Absent</th>
                      <th>Total Working Hours</th>
                    </> : <></>} */}
                      </tr>
                    )
                  ) : (
                    <tr
                      style={{
                        verticalAlign: "middle",
                        textAlignLast: "center",
                      }}
                    >
                      <th>CP Name</th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Branch Name
                          </Col>
                        </Row>
                      </th>
                      {keys.map((key) => {
                        return <th>{key}</th>;
                      })}
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Total Collection Visits
                          </Col>
                          {/* <Col style={{ textAlignLast: "center" }}>
                            Visits
                          </Col> */}
                        </Row>
                      </th>
                      <th>
                        <Row>
                          <Col style={{ textAlignLast: "center" }}>
                            Total Amount Collected
                          </Col>
                          {/* <Col style={{ textAlignLast: "center" }}>
                            {" "}
                            Collected
                          </Col> */}
                        </Row>
                      </th>
                    </tr>
                  )
                ) : (
                  <tr
                    style={{ verticalAlign: "middle", textAlignLast: "center" }}
                  >
                    <th>Employee Id</th>
                    <th>Employee Name</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Employee </Col>
                        <Col style={{ textAlignLast: "center" }}>Status</Col>
                      </Row>
                    </th>{" "}
                    <th>Designation</th>
                    <th>Branch Name</th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Employee </Col>
                        <Col style={{ textAlignLast: "center" }}>
                          Mobile Number
                        </Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Total Days{" "}
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>Present</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Total Days{" "}
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>Absent</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>
                          Total Working{" "}
                        </Col>
                        <Col style={{ textAlignLast: "center" }}>Hours</Col>
                      </Row>
                    </th>
                    <th>
                      <Row>
                        <Col style={{ textAlignLast: "center" }}>Distance </Col>
                        <Col style={{ textAlignLast: "center" }}>Travelled</Col>
                      </Row>
                    </th>
                    <th>Total Visits</th>
                    {/* <th>Days Checked In</th> */}
                    {/* <th>Total Time</th> */}
                  </tr>
                )}
              </thead>
              <tbody style={{ textAlignLast: "center" }}>
                {!flag
                  ? t === "Attendance" && !isCpNames
                    ? data2.map((ele) => {
                        return (
                          <tr style={{ textAlignLast: "center" }}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={defaultImage}
                                  className="rounded-lg me-2"
                                  width="24"
                                  alt=""
                                />{" "}
                                <span className="w-space-no">
                                  {ele.employeeName}
                                </span>
                              </div>{" "}
                            </td>
                            <td>{ele.employeeId}</td>
                            <td>{ele.designation}</td>
                            <td>{ele.area}</td>
                            <td>{ele.branchName}</td>
                            <td>{ele.empMobileNumber}</td>
                            <td>{ele.date}</td>
                            <td>{ele.employeeAttendenceStatus}</td>
                            <td>
                              {ele.loginTime === 0
                                ? "-"
                                : helper.EpochToTime(ele.loginTime)}
                            </td>
                            <td>
                              {ele.checkInAddress === null ? (
                                "-"
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p style={{ cursor: "pointer" }}>
                                    {ele.checkInAddress}
                                  </p>
                                  <span
                                    style={{
                                      color: "green",
                                      margin: "0px 15px",
                                      cursor: "pointer",
                                      alignSelf: "center",
                                    }}
                                    onClick={() => {
                                      setLatLng({
                                        lat: ele.checkInLatitude,
                                        lng: ele.checkInLongitude,
                                      });
                                      setIsMap(true);
                                    }}
                                  >
                                    <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              {ele.logoutTime === 0
                                ? "-"
                                : helper.EpochToTime(ele.logoutTime)}
                            </td>
                            <td>
                              {ele.checkOutAddress === null ? (
                                "-"
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p style={{ cursor: "pointer" }}>
                                    {ele.checkOutAddress}
                                  </p>
                                  <span
                                    style={{
                                      color: "green",
                                      margin: "0px 15px",
                                      cursor: "pointer",
                                      alignSelf: "center",
                                    }}
                                    onClick={() => {
                                      setLatLng({
                                        lat: ele.checkOutLatitude,
                                        lng: ele.checkOutLongitude,
                                      });
                                      setIsMap(true);
                                    }}
                                  >
                                    <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              {ele.timeDifference
                                ? secondsToHms(ele.timeDifference)
                                : 0}
                            </td>
                            <td>
                              {getDistTravelledInKM(ele.distanceTravlled)}
                            </td>{" "}
                            <td>
                              {ele.userStatus ? (
                                ele.userStatus === "ACTIVE" ? (
                                  <span className="badge badge-rounded badge-success">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-rounded badge-danger">
                                    Inactive
                                  </span>
                                )
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>{ele.phoneMake ? ele.phoneMake : "-"}</td>
                            <td>{ele.phoneModel ? ele.phoneModel : "-"}</td>
                            <td>{ele.visitCount ? ele.visitCount : 0}</td>
                            {/* <td>{ele.salesCount}</td>
                        <td>{ele.collectionCount}</td>
                        <td>{ele.fieldInvestigationCount}</td>
                        <td>{ele.amountCollected}</td>
                        <td>{ele.expense}</td> */}
                          </tr>
                        );
                      })
                    : t === "EmployeePerformanceMetrics" && !isCpNames
                    ? employeeMetricsData.map((ele) => {
                        return (
                          <tr style={{ textAlignLast: "center" }}>
                            <td>{ele.empName}</td>
                            <td>{ele.empId}</td>
                            <td>{ele.contactRate}</td>
                            <td>{ele.collectionRate}</td>
                            <td>{ele.collectionDay}</td>
                            <td>{ele.avgCollectionTimePct}</td>
                            <td>{ele.monthYear}</td>
                          </tr>
                        );
                      })
                    : t === "FieldInvestigation" && !isCpNames
                    ? data3.map((ele) => {
                        return (
                          <tr style={{ textAlignLast: "center" }}>
                            <td>
                              {ele.fieldInvestigationVisit?.applicationNo
                                ? ele.fieldInvestigationVisit?.applicationNo
                                : "-"}
                            </td>
                            <td>{ele?.fiAllocation?.client ? ele?.fiAllocation?.client : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.applicantType ? ele?.fiCompleteDetail?.applicantType : "-"}</td>
                            <td>{ele?.fiAllocation?.fiType ? ele?.fiAllocation?.fiType  : "-"}</td>
                            <td>
                              {ele.fieldInvestigationVisit?.customerName
                                ? ele.fieldInvestigationVisit?.customerName
                                : "-"}
                            </td>
                            <td>
                              {ele.fieldInvestigationVisit?.contactNumber
                                ? ele.fieldInvestigationVisit?.contactNumber
                                : "-"}
                            </td>
                            <td>{ele?.fiAllocation?.productType ? ele?.fiAllocation?.productType  : "-"}</td>
                            <td>{ele?.fiAllocation?.cpvVerificationGeoLimit ? ele?.fiAllocation?.cpvVerificationGeoLimit  : "-"}</td>
                            <td>{ele.visitType ? ele.visitType : "-"}</td>

                            <td>
                              {ele.fieldInvestigationVisit?.status
                                ? ele.fieldInvestigationVisit?.status
                                : "-"}
                            </td>
                            <td>
                              {ele.fiAllocation?.status ? (
                                ele.fiAllocation?.status === "Closed" ? (
                                  <span className="badge badge-rounded badge-success">
                                    Closed
                                  </span>
                                ): ele.fiAllocation?.status === "In Progress" ? (
                                  <span className="badge badge-rounded badge-warning">
                                    In Progress
                                  </span>
                                ) : (
                                  <span className="badge badge-rounded badge-info">
                                    Open
                                  </span>
                                )
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {ele.fieldInvestigationVisit?.rejectionType
                                ? ele.fieldInvestigationVisit?.rejectionType
                                : "-"}
                            </td>
                            <td>{ele?.fiAllocation?.createdTime ? convertEpochTimeToDate(ele?.fiAllocation?.createdTime) :"-"}</td>
                            <td>{convertEpochTimeToDate(ele.dateTime)}</td>
                            <td>{ele?.fiCompleteDetail?.dateOfReport ? convertEpochTimeToDate(ele?.fiCompleteDetail?.dateOfReport) :"-"}</td>
                            <td>{ele.userModel.employeeId}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={defaultImage}
                                  className="rounded-lg me-2"
                                  width="24"
                                  alt=""
                                />{" "}
                                <span className="w-space-no">
                                  {ele.userModel.name}
                                </span>
                              </div>{" "}
                            </td>
                            <td>{ele?.fiCompleteDetail?.verifiersEmpId ? ele?.fiCompleteDetail?.verifiersEmpId : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.verifiersEmpName ? ele?.fiCompleteDetail?.verifiersEmpName : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.applicantWorking ? ele?.fiCompleteDetail?.applicantWorking : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.enduserOfProduct ? ele?.fiCompleteDetail?.enduserOfProduct : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.cityStayInyears ? ele?.fiCompleteDetail?.cityStayInyears : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.residenceStayInyears ? ele?.fiCompleteDetail?.residenceStayInyears : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.residenceOwnership ? ele?.fiCompleteDetail?.residenceOwnership : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.locality ? ele?.fiCompleteDetail?.locality : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.livingStandard ? ele?.fiCompleteDetail?.livingStandard : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.areaLocality ? ele?.fiCompleteDetail?.areaLocality : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.applicantProfile ? ele?.fiCompleteDetail?.applicantProfile : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.noOfYearsEmp ? ele?.fiCompleteDetail?.noOfYearsEmp : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.totalEmployeement ? ele?.fiCompleteDetail?.totalEmployeement : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.bizActivity ? ele?.fiCompleteDetail?.bizActivity : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.interiorCondition ? ele?.fiCompleteDetail?.interiorCondition : "-"}</td>
                            <td>{ele?.fiCompleteDetail?.noOfVisits ? ele?.fiCompleteDetail?.noOfVisits : "-"}</td>
                            <td>{ele.address ? ele.address : "-"}</td>
                            <td>
                                {ele.latitude !== 0 && ele.longitude !== 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p style={{ cursor: "pointer" }}>
                                      {ele.latitude + ", " + ele.longitude}
                                    </p>
                                    <span
                                      style={{
                                        color: "green",
                                        margin: "0px 15px",
                                        cursor: "pointer",
                                        alignSelf: "center",
                                      }}
                                      onClick={() => {
                                        setLatLng({
                                          lat: ele.latitude,
                                          lng: ele.longitude,
                                        });
                                        setIsMap(true);
                                      }}
                                    >
                                      <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
                                    </span>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                            <td>{ele?.fiAllocation?.addressLine1 ? ele?.fiAllocation?.addressLine1 : "-"}</td>
                            <td>{ele?.fiAllocation?.city ? ele?.fiAllocation?.city : "-"}</td>
                            <td>{ele?.fiAllocation?.state ? ele?.fiAllocation?.state : "-"}</td>
                            <td>{ele?.fiAllocation?.officeName ? ele?.fiAllocation?.officeName : "-"}</td>
                            <td>{ele?.fiAllocation?.addressLine1 ? ele?.fiAllocation?.addressLine1 : "-"}</td>
                            <td>
                              {ele.timeDiffOfPrevVisit
                                ? helper.secondsToHms(ele.timeDiffOfPrevVisit)
                                : "-"}
                            </td>
                            <td>
                              {ele?.fiAllocationId ? "Yes":"No"}
                            </td>
                            <td>
                              {ele.fieldInvestigationVisit?.loanDate
                                ? helper.EpochToOnlyDate(
                                    ele.fieldInvestigationVisit?.loanDate
                                  )
                                : "-"}
                            </td>
                            <td>
                              {ele.fieldInvestigationVisit?.loanType
                                ? ele.fieldInvestigationVisit?.loanType
                                : "-"}
                            </td>
                            <td>
                              {ele.customerLocationChanged === true
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>{ele.branchName ? ele.branchName : "-"}</td>
                            <td>
                              {ele.branchLocation ? ele.branchLocation : "-"}
                            </td>
                            <td>
                              {ele?.userModel?.mobileNumber
                                ? ele?.userModel?.mobileNumber
                                : "-"}
                            </td>
                            <td>
                              {ele.userModel.status ? (
                                ele.userModel.status === "ACTIVE" ? (
                                  <span className="badge badge-rounded badge-success">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-rounded badge-danger">
                                    Inactive
                                  </span>
                                )
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>{ele.designation ? ele.designation : "-"}</td>
                            <td>
                              {ele.reportingOfficerName
                                ? ele.reportingOfficerName
                                : "-"}
                            </td>                            
                            <td>
                              {ele.l2ManagerEmpId ? ele.l2ManagerEmpId : "-"}
                            </td>
                            <td>
                              {ele.l2ManagerName ? ele.l2ManagerName : "-"}
                            </td>
                            <td>
                              {ele.l3ManagerEmpId ? ele.l3ManagerEmpId : "-"}
                            </td>
                            <td>
                              {ele.l3ManagerName ? ele.l3ManagerName : "-"}
                            </td>
                           
                            
                            
                            {/* <td>
                              {ele.fieldInvestigationVisit?.positiveComments
                                ? ele.fieldInvestigationVisit?.positiveComments
                                : "-"}
                            </td> */}
                            {/* <td>
                              {ele.fieldInvestigationVisit?.negativeComments
                                ? ele.fieldInvestigationVisit?.negativeComments
                                : "-"}
                            </td> */}
                           
   
                            <td>
                              <td>{ele.comments ? ele.comments : "-"}</td>
                            </td>
                          </tr>
                        );
                      })
                    : // eslint-disable-next-line
                      data.map((ele) => {
                        if (
                          t !== "CallReport" &&
                          t !== "CallDispositionSummaryByLoanNumber"
                        ) {
                          return (
                            <tr style={{ textAlignLast: "center" }}>
                              <td>
                                {ele?.userModel?.employeeId
                                  ? ele?.userModel?.employeeId
                                  : "-"}
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={defaultImage}
                                    className="rounded-lg me-2"
                                    width="24"
                                    alt=""
                                  />{" "}
                                  <span className="w-space-no">
                                    {ele?.userModel?.name
                                      ? ele?.userModel?.name
                                      : "-"}
                                  </span>
                                </div>{" "}
                              </td>
                              <td>
                                {ele?.userModel?.status ? (
                                  ele?.userModel?.status === "ACTIVE" ? (
                                    <span className="badge badge-rounded badge-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-rounded badge-danger">
                                      Inactive
                                    </span>
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{ele.designation ? ele.designation : "-"}</td>
                              <td>{ele?.userModel?.area ? ele?.userModel?.area : "-"}</td>
                              <td>{ele.branchName ? ele.branchName : "-"}</td>
                              <td>
                                {ele.branchLocation ? ele.branchLocation : "-"}
                              </td>
                              <td>
                                {ele.l2ManagerEmpId ? ele.l2ManagerEmpId : "-"}
                              </td>
                              <td>
                                {ele.l2ManagerName ? ele.l2ManagerName : "-"}
                              </td>
                              <td>
                                {ele.l3ManagerEmpId ? ele.l3ManagerEmpId : "-"}
                              </td>
                              <td>
                                {ele.l3ManagerName ? ele.l3ManagerName : "-"}
                              </td>
                              <td>
                                {ele.reportingOfficerName
                                  ? ele.reportingOfficerName
                                  : "-"}
                              </td>
                              <td>
                                {ele.userModel?.mobileNumber
                                  ? ele.userModel?.mobileNumber
                                  : "-"}
                              </td>
                              <td>
                                {ele.collectionVisit
                                  ? ele.collectionVisit?.customerName
                                  : ele.salesVisit
                                  ? ele.salesVisit?.customerName
                                  : ele.fieldInvestigationVisit?.customerName}
                              </td>
                              <td>
                                {ele.collectionVisit
                                  ? ele.collectionVisit?.contactNumber
                                  : ele.salesVisit
                                  ? ele.salesVisit?.contactNumber
                                  : ele.fieldInvestigationVisit
                                  ? ele.fieldInvestigationVisit?.contactNumber
                                  : "-"}
                              </td>
                              <td>
                                {ele.collectionVisit?.addressType
                                  ? ele.collectionVisit?.addressType
                                  : "-"}
                              </td>
                              <td>{ele.address ? ele.address : "-"}</td>
                              <td>
                                {ele.customerLocationChanged === true
                                  ? "Yes"
                                  : "No"}
                              </td>
                              {t === "Collections" ||
                              t === "All" ||
                              t === "Repossessed" ? (
                                <>
                                  <td>
                                    {ele.collectionVisit?.newAddress
                                      ? ele.collectionVisit?.newAddress
                                      : "-"}
                                  </td>
                                  <td>
                                    {ele.collectionVisit?.loanNumber
                                      ? ele.collectionVisit?.loanNumber
                                      : "-"}
                                  </td>
                                  <td>{ele.cpLoanNo ? ele.cpLoanNo : "-"}</td>
                                </>
                              ) : (
                                <></>
                              )}
                              <td>{ele.visitType}</td>
                              {t === "Collections" ||
                              t === "All" ||
                              t === "Repossessed" ? (
                                <>
                                  <td>
                                    {ele.collectionVisit
                                      ? ele.collectionVisit?.status
                                      : ele.salesVisit
                                      ? "-"
                                      : ele.fieldInvestigationVisit?.status}
                                  </td>
                                  {t === "Repossessed" && (
                                    <td>
                                      {ele.collectionVisit?.repossedLocation
                                        ? ele.collectionVisit?.repossedLocation
                                        : "-"}
                                    </td>
                                  )}
                                  {t !== "Repossessed" && (
                                    <>
                                      <td>
                                        {ele.collectionVisit
                                          ? ele.collectionVisit?.subStatus !==
                                              "" &&
                                            ele.collectionVisit?.subStatus !==
                                              null
                                            ? ele.collectionVisit?.subStatus
                                            : "-"
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.paymentMode
                                          ? ele.collectionVisit?.paymentMode
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.paymentTowards
                                          ? ele.collectionVisit?.paymentTowards
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.emiAmountPaidNow
                                          ? // ? ele.collectionVisit.amountPaid
                                            getAmountPaid(
                                              ele.collectionVisit
                                                ?.emiAmountPaidNow
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit
                                          ?.latePaymentChargesPaidNow
                                          ? // ? ele.collectionVisit.amountPaid
                                            getAmountPaid(
                                              ele.collectionVisit
                                                ?.latePaymentChargesPaidNow
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit
                                          ?.bounceChargesPaidNow
                                          ? // ? ele.collectionVisit.amountPaid
                                            getAmountPaid(
                                              ele.collectionVisit
                                                ?.bounceChargesPaidNow
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit
                                          ?.penaltyChargesPaidNow
                                          ? // ? ele.collectionVisit.amountPaid
                                            getAmountPaid(
                                              ele.collectionVisit
                                                ?.penaltyChargesPaidNow
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit
                                          ?.otherChargesPaidNow
                                          ? // ? ele.collectionVisit.amountPaid
                                            getAmountPaid(
                                              ele.collectionVisit
                                                ?.otherChargesPaidNow
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.amountPaid
                                          ? // ? ele.collectionVisit.amountPaid
                                            getAmountPaid(
                                              ele.collectionVisit?.amountPaid
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.paymentDate
                                          ? helper.EpochToOnlyDate(
                                              ele.collectionVisit?.paymentDate
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.transactionId
                                          ? ele.collectionVisit?.transactionId
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.toBePaidBy
                                          ? helper.EpochToOnlyDate(
                                              ele.collectionVisit?.toBePaidBy
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.ptpSlot
                                          ? ele.collectionVisit?.ptpSlot
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele.collectionVisit?.ptpAmount
                                          ? ele.collectionVisit?.ptpAmount
                                          : "-"}
                                      </td>
                                    </>
                                  )}
                                  <td>
                                    {ele.collectionVisit
                                      ? ele.collectionVisit
                                          ?.repossessedVehicleReleased === true
                                        ? "Yes"
                                        : "No"
                                      : "-"}
                                  </td>
                                  <td>
                                    {ele.collectionVisit
                                      ?.amountPaidToReleaseVehicle
                                      ? ele.collectionVisit
                                          ?.amountPaidToReleaseVehicle
                                      : "-"}
                                  </td>
                                  <td>
                                    {ele.collectionVisit?.vehicleReleasedTime
                                      ? helper.EpochToDate(
                                          ele.collectionVisit
                                            ?.vehicleReleasedTime
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {ele.collectionVisit?.vehicleReleasedReason
                                      ? ele.collectionVisit
                                          ?.vehicleReleasedReason
                                      : "-"}
                                  </td>
                                  <td>
                                    {
                                      ele.collectionVisit
                                        ?.daysBetweenRepossessedAndReleased
                                    }
                                  </td>

                                  {/* <td>
                                  {ele.collectionVisit
                                    ? ele.collectionVisit?.unableToPay === true
                                      ? "Yes"
                                      : "No"
                                    : "-"}
                                </td> */}
                                </>
                              ) : (
                                <></>
                              )}
                              {(t === "Sales" || t === "All") && !isCpNames ? (
                                <td>
                                  {ele.salesVisit
                                    ? ele.salesVisit?.productname
                                    : "-"}
                                </td>
                              ) : (
                                <></>
                              )}
                              {(t === "FieldInvestigation" || t === "All") &&
                              !isCpNames ? (
                                <>
                                  <td>
                                    {ele.fieldInvestigationVisit?.applicationNo
                                      ? ele.fieldInvestigationVisit
                                          ?.applicationNo
                                      : "-"}
                                  </td>
                                  {/* <td>
                                  {ele.fieldInvestigationVisit?.positiveComments
                                    ? ele.fieldInvestigationVisit
                                        ?.positiveComments
                                    : "-"}
                                </td> */}
                                  <td>
                                    {ele.fieldInvestigationVisit?.rejectionType
                                      ? ele.fieldInvestigationVisit
                                          ?.rejectionType
                                      : "-"}
                                  </td>
                                  {/* <td>
                                  {ele.fieldInvestigationVisit?.negativeComments
                                    ? ele.fieldInvestigationVisit
                                        ?.negativeComments
                                    : "-"}
                                </td> */}
                                  <td>
                                    {ele.fieldInvestigationVisit?.loanDate
                                      ? helper.EpochToOnlyDate(
                                          ele.fieldInvestigationVisit?.loanDate
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {ele.fieldInvestigationVisit?.loanType
                                      ? ele.fieldInvestigationVisit?.loanType
                                      : "-"}
                                  </td>
                                  <td>
                                    {ele.fieldInvestigationVisit
                                      ?.customerAddress
                                      ? ele.fieldInvestigationVisit
                                          ?.customerAddress
                                      : "-"}
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                              {/* {t === "Collections" || t === "All" ? (
                              <td>
                                {ele.collectionVisit
                                  ? getDistTravelledInKM(ele.distFromCustAddr)
                                  : "-"}
                              </td>
                            ) : (
                              <></>
                            )} */}
                              <td>{convertEpochTimeToDate(ele.dateTime)}</td>

                              {t === "Collections" ||
                              t === "All" ||
                              t === "Repossessed" ? (
                                <>
                                  <td>
                                    {ele.customerLatitude !== 0 && ele.customerLongitude !== 0
                  ? ele.visitNotDoneAtCustLocation === false
                                      ? "Yes"
                                      : "No":"NA"}
                                  </td>
                                  <td>
                                    {ele.disFromCustLocation
                                      ? getDistTravelledInKM(
                                          ele.disFromCustLocation
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {ele.collectionVisit?.lastPaidTime
                                      ? convertEpochTimeToDate(
                                          ele.collectionVisit?.lastPaidTime
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {ele.collectionVisit?.cpName
                                      ? ele.collectionVisit?.cpName
                                      : "-"}
                                  </td>
                                  {t !== "Repossessed" && (
                                    <>
                                      <td>
                                        {ele?.aggDate
                                          ? helper.EpochToOnlyDate(ele?.aggDate)
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele?.vehicleNumber
                                          ? ele?.vehicleNumber
                                          : "-"}
                                      </td>
                                      <td>
                                        {ele?.dueDate
                                          ? helper.EpochToOnlyDate(ele?.dueDate)
                                          : "-"}
                                      </td>
                                    </>
                                  )}
                                  <td>
                                    {ele.collectionVisit?.alternateMobileNumber
                                      ? ele.collectionVisit
                                          ?.alternateMobileNumber
                                      : "-"}
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                              <td>
                                {ele.executive_daily_scheduled_data_id 
                                  ? "Yes"
                                  : ele.fiAllocationId ? "Yes"
                                  : "No"
                                  }
                              </td>
                              <td>
                                {ele.latitude !== 0 && ele.longitude !== 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p style={{ cursor: "pointer" }}>
                                      {ele.latitude + ", " + ele.longitude}
                                    </p>
                                    <span
                                      style={{
                                        color: "green",
                                        margin: "0px 15px",
                                        cursor: "pointer",
                                        alignSelf: "center",
                                      }}
                                      onClick={() => {
                                        setLatLng({
                                          lat: ele.latitude,
                                          lng: ele.longitude,
                                        });
                                        setIsMap(true);
                                      }}
                                    >
                                      <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
                                    </span>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {ele.customerLatitude !== 0 && ele.customerLongitude !== 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p style={{ cursor: "pointer" }}>
                                      {ele.customerLatitude + ", " + ele.customerLongitude}
                                    </p>
                                    <span
                                      style={{
                                        color: "green",
                                        margin: "0px 15px",
                                        cursor: "pointer",
                                        alignSelf: "center",
                                      }}
                                      onClick={() => {
                                        setLatLng({
                                          lat: ele.customerLatitude,
                                          lng: ele.customerLongitude,
                                        });
                                        setIsMap(true);
                                      }}
                                    >
                                      <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
                                    </span>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                              {t !== "Repossessed" && (
                                <td>
                                  {ele.timeDiffOfPrevVisit
                                    ? helper.secondsToHms(
                                        ele.timeDiffOfPrevVisit
                                      )
                                    : "-"}
                                </td>
                              )}
                              
                              <td>{ele.comments ? ele.comments : "-"}</td>
                              {/* {t === "Attendance" || t === "All" ?
                            <>
                              <td>
                                {ele.loginTime === 0
                                  ? "-"
                                  : helper.EpochToTime(ele.loginTime)}
                              </td>
                              <td>
                                {ele.logoutTime === 0
                                  ? "-"
                                  : helper.EpochToTime(ele.logoutTime)}
                              </td>
                              <td>{ele.actualWorkingHours ? secondsToHms(ele.actualWorkingHours) : 0}</td>
                            </> : <></>} */}
                            </tr>
                          );
                        } else if (t === "CallReport") {
                          return (
                            <tr style={{ textAlignLast: "center" }}>
                              <td>
                                {ele?.autoFinanceUser?.employeeId
                                  ? ele?.autoFinanceUser?.employeeId
                                  : "-"}
                              </td>
                              <td>
                                {ele?.autoFinanceUser?.name
                                  ? ele?.autoFinanceUser?.name
                                  : "-"}
                              </td>
                              <td>
                                {ele?.autoFinanceUser?.status
                                  ? ele?.autoFinanceUser?.status
                                  : "-"}
                              </td>
                              <td>{ele.cpName ? ele.cpName : "-"}</td>
                              <td>
                                {ele.campaignName ? ele.campaignName : "-"}
                              </td>
                              <td>{ele.loanNumber ? ele.loanNumber : "-"}</td>
                              <td>
                                {ele.customerName ? ele.customerName : "-"}
                              </td>
                              <td>{ele.phoneNumber ? ele.phoneNumber : "-"}</td>
                              <td>
                                {ele?.callDirection
                                  ? ele?.callDirection === "outbound"
                                    ? "OutBound"
                                    : "InBound"
                                  : "-"}
                              </td>
                              <td>{ele?.callStatus ? ele?.callStatus : "-"}</td>
                              <td>
                                {ele?.callStatus === "Call Not Connected"
                                  ? ele?.subStatus
                                  : "-"}
                              </td>
                              <td>
                                {ele.createdTime
                                  ? helper.EpochToDate(ele.createdTime)
                                  : "-"}
                              </td>
                              <td>
                                {ele.callDuration
                                  ? helper.secondsToHms(ele.callDuration)
                                  : "-"}
                              </td>
                              <td>
                                {ele?.hangUpReason
                                  ? getHangUpReason(ele?.hangUpReason)
                                  : "-"}
                              </td>
                              <td>{ele?.remarks ? ele?.remarks : "-"}</td>

                              <td>{ele.amountPaid ? ele.amountPaid : 0}</td>
                              <td>{ele.currentDue ? ele.currentDue : "-"}</td>
                              <td>{ele.dueAmount ? ele.dueAmount : "-"}</td>
                              <td>
                                {ele.paymentLinkStatus === true ? "Yes" : "No"}
                              </td>
                              <td>
                                {ele.allocationStatus
                                  ? ele.allocationStatus
                                  : "-"}
                              </td>
                              <td>
                                {ele?.callStatus === "Call Connected"
                                  ? ele.status
                                    ? ele.status
                                    : "-"
                                  : "-"}
                              </td>
                              <td>
                                {ele?.callStatus === "Call Connected"
                                  ? ele.subStatus
                                    ? ele.subStatus
                                    : "-"
                                  : "-"}
                              </td>
                              <td>
                                {ele?.paymentMode ? ele?.paymentMode : "-"}
                              </td>
                              <td>
                                {ele?.paymentTowards
                                  ? ele?.paymentTowards
                                  : "-"}
                              </td>
                              <td>
                                {ele?.paymentDate
                                  ? helper.EpochToOnlyDate(ele?.paymentDate)
                                  : "-"}
                              </td>
                              <td>
                                {ele?.transactionId ? ele?.transactionId : "-"}
                              </td>
                              <td>
                                {ele?.toBePaidBy
                                  ? helper.EpochToOnlyDate(ele?.toBePaidBy)
                                  : "-"}
                              </td>
                              <td>{ele?.ptpSlot ? ele?.ptpSlot : "-"}</td>
                              <td>
                                {ele?.callBackDate
                                  ? helper.EpochToOnlyDate(ele?.callBackDate)
                                  : "-"}
                              </td>
                              <td>
                                {ele?.callBackSlot ? ele?.callBackSlot : "-"}
                              </td>
                              <td>{ele?.ptpAmount ? ele?.ptpAmount : 0}</td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr>
                              {keys?.map((key) => {
                                return <td>{ele[key]}</td>;
                              })}
                            </tr>
                          );
                        }
                      })
                  : t !== "Attendance"
                  ? summaryData.map((ele) => {
                      if (!isCpNames) {
                        if (slctdID === "3" || slctdID === "4") {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              {slctdID === "3" ? (
                                <td>{ele.branchName ? ele.branchName : "-"}</td>
                              ) : slctdID === "4" ? (
                                <td>
                                  {ele.designation ? ele.designation : "-"}
                                </td>
                              ) : (
                                ""
                              )}
                              {(t === "All" || t === "Collections") &&
                                keys.map((key) => {
                                  return (
                                    <td>
                                      {ele?.collectionStatusCounts
                                        ? ele?.collectionStatusCounts[key]
                                          ? ele?.collectionStatusCounts[key]
                                          : "-"
                                        : "-"}
                                    </td>
                                  );
                                })}
                              {(t === "All" || t === "Sales") && (
                                <td>{ele.salesCount ? ele.salesCount : 0}</td>
                              )}
                              {(t === "All" || t === "Collections") && (
                                <>
                                  <td>
                                    {ele.collectionCount
                                      ? ele.collectionCount
                                      : 0}
                                  </td>
                                  <td>
                                    {ele.amountCollected
                                      ? ele.amountCollected
                                      : 0.0}
                                  </td>
                                </>
                              )}
                              {(t === "All" || t === "FieldInvestigation") && (
                                <td>
                                  {ele.fieldInvestigationCount
                                    ? ele.fieldInvestigationCount
                                    : 0}
                                </td>
                              )}
                              {t === "All" && (
                                <td>
                                  {ele.fieldInvestigationCount +
                                    ele.salesCount +
                                    ele.collectionCount}
                                </td>
                              )}
                              {t === "Repossessed" && (
                                <td>
                                  {ele.repossessedCount
                                    ? ele.repossessedCount
                                    : 0}
                                </td>
                              )}
                              {t === "Repossessed" &&
                                keys.map((key) => {
                                  return (
                                    <td>
                                      {ele?.repossessedDetails
                                        ? ele?.repossessedDetails[key]
                                          ? ele?.repossessedDetails[key]
                                          : "-"
                                        : "-"}
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        } else {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <td>{ele.employeeId ? ele.employeeId : "-"}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={defaultImage}
                                    className="rounded-lg me-2"
                                    width="24"
                                    alt=""
                                  />{" "}
                                  <span className="w-space-no">
                                    {ele.employeeName}
                                  </span>
                                </div>{" "}
                              </td>
                              <td>
                                {ele.empStatus ? (
                                  ele.empStatus === "ACTIVE" ? (
                                    <span className="badge badge-rounded badge-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-rounded badge-danger">
                                      Inactive
                                    </span>
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{ele.designation ? ele.designation : "-"}</td>
                              <td>{ele.branchName ? ele.branchName : "-"}</td>
                              <td>
                                {ele.empMobileNumber
                                  ? ele.empMobileNumber
                                  : "-"}
                              </td>
                              {t === "Repossessed" && (
                                <td>
                                  {ele.repossessedCount
                                    ? ele.repossessedCount
                                    : "-"}
                                </td>
                              )}
                              {t === "Repossessed" &&
                                keys.map((key) => {
                                  return (
                                    <td>
                                      {ele?.repossessedDetails
                                        ? ele?.repossessedDetails[key]
                                          ? ele?.repossessedDetails[key]
                                          : "-"
                                        : "-"}
                                    </td>
                                  );
                                })}
                              {t === "Sales" || t === "All" ? (
                                <td>{ele.salesCount}</td>
                              ) : (
                                <></>
                              )}
                              {t === "Collections" || t === "All" ? (
                                <>
                                  {t === "FieldInvestigation" ||
                                  t === "Collections" ||
                                  t === "All" ? (
                                    <>
                                      {keys.map((key) => {
                                        return (
                                          <td>
                                            {ele?.collectionStatusCounts
                                              ? ele?.collectionStatusCounts[key]
                                                ? ele?.collectionStatusCounts[
                                                    key
                                                  ]
                                                : "-"
                                              : "-"}
                                          </td>
                                        );
                                      })}
                                      {/* <td>{ele.ptpCount}</td>
                                  <td>{ele.paidCount}</td>
                                  <td>{ele.rtpCount}</td>
                                  <td>{ele.repossessedCount}</td>
                                  <td>{ele.thirdPartyCount}</td>
                                  <td>{ele.vacantCount}</td>
                                  <td>{ele.customerNotAvaible}</td> */}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <td>{ele.collectionCount}</td>
                                  <td>
                                    {ele.amountCollected
                                      ? ele.amountCollected
                                      : 0.0}
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                              {t === "FieldInvestigation" || t === "All" ? (
                                <>
                                  <td>{ele.positiveCount}</td>
                                  <td>{ele.negativeCount}</td>
                                  <td>{ele.fieldInvestigationCount}</td>
                                </>
                              ) : (
                                <></>
                              )}
                              {t === "All" ? (
                                <td>
                                  {ele.collectionCount +
                                    ele.salesCount +
                                    ele.positiveCount +
                                    ele.negativeCount}
                                </td>
                              ) : (
                                <></>
                              )}
                              {t !== "Attendance" && t !== "Repossessed" && (
                                <>
                                  <td>{ele.duplicateCount}</td>
                                  <td>{ele.totalDuplicateVisitCounts}</td>
                                </>
                              )}
                              {/* {t === "Attendance" || t === "All" ?
                          <>
                            <td>{ele.totalDaysPresent ? ele.totalDaysPresent : "-"}</td>
                            <td>{ele.totalDaysAbsent ? ele.totalDaysAbsent : "-"}</td>
                            <td>{ele.actualWorkingHours ? ele.actualWorkingHours : "-"}</td>
                          </> : <></>} */}
                            </tr>
                          );
                        }
                      } else {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{ele.cpName ? ele.cpName : "-"}</td>
                            <td>{ele.branchName ? ele.branchName : "-"}</td>
                            {keys.map((key) => {
                              return (
                                <td>
                                  {ele?.collectionStatusCounts
                                    ? ele?.collectionStatusCounts[key]
                                      ? ele?.collectionStatusCounts[key]
                                      : 0
                                    : "-"}
                                </td>
                              );
                            })}

                            <td>{ele.collectionCount}</td>
                            <td>
                              {ele.amountCollected ? ele.amountCollected : 0.0}
                            </td>
                          </tr>
                        );
                      }
                    })
                  : summaryData2.map((ele) => {
                      return (
                        <tr style={{ textAlignLast: "center" }}>
                          <td>{ele.employeeId ? ele.employeeId : "-"}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={defaultImage}
                                className="rounded-lg me-2"
                                width="24"
                                alt=""
                              />{" "}
                              <span className="w-space-no">
                                {ele.employeeName ? ele.employeeName : "-"}
                              </span>
                            </div>{" "}
                          </td>
                          <td>
                            {ele.empStatus ? (
                              ele.empStatus === "ACTIVE" ? (
                                <span className="badge badge-rounded badge-success">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-rounded badge-danger">
                                  Inactive
                                </span>
                              )
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>{ele.designation ? ele.designation : "-"}</td>
                          <td>{ele.branch ? ele.branch : "-"}</td>
                          <td>
                            {ele.empMobileNumber ? ele.empMobileNumber : "-"}
                          </td>
                          {t === "Attendance" || t === "All" ? (
                            <>
                              <td>
                                {ele.daysCheckedIn ? ele.daysCheckedIn : "-"}
                              </td>
                              <td>
                                {ele.daysNotCheckedIn
                                  ? ele.daysNotCheckedIn
                                  : "-"}
                              </td>
                              <td>
                                {ele.totalTime
                                  ? secondsToHms(ele.totalTime)
                                  : "-"}
                              </td>
                              <td>
                                {ele.distanceTravelled
                                  ? getDistTravelledInKM(ele.distanceTravelled)
                                  : "-"}
                              </td>
                              <td>
                                {ele.visitCount ? ele.visitCount : 0}
                              </td>
                            </>
                          ) : (
                            <></>
                          )}

                          {/* <td>{ele.daysCheckedIn}</td> */}
                          {/* <td>{secondsToHms(ele.totalTime)}</td> */}
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </div>
          {isNoData ? (
            data3.length === 0 &&
            employeeMetricsData.length === 0 &&
            data.length === 0 &&
            data2.length === 0 &&
            summaryData2.length === 0 &&
            summaryData.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )
          ) : (
            <></>
          )}
        </LoadingOverlay>
        <div
          style={{
            alignSelf: "center",
            margin:
              totalElements === 0 ? "90px 0px 20px 20px" : "20px 0px 20px 20px",
          }}
        >
          {!flag ? (
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          ) : (
            // <Pagination data={summaryData} pageHandler={pageHandler} />
            // <PaginationComponent
            //   totalItems={summaryData.length}
            //   pageSize={10}
            //   // defaultActivePage={currPage + 1}
            //   onSelect={pageHandler}
            // />
            <></>
          )}
        </div>
      </LoadingOverlay>

      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Report;
