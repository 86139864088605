import React, { useState } from "react";
import { Nav, Tab, Col, Modal, Button } from "react-bootstrap";
// import swal from "sweetalert";
// import { Link } from "react-router-dom";
// import { getTokenInLocalStorage } from "../../services/AuthService";
// import { EpochToOnlyDate } from "../../CommonComponents/Helper";

const FISubmittedDetails = ({
  allocationDetails,
  selectedRow,
  agencyRemarks,
  close,
}) => {
  const [editModal, setEditModal] = useState(false);

  //   const userData = getTokenInLocalStorage();

  return (
    <div style={{ margin: "20px" }}>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey= {selectedRow?.fi_status==="Untraceable"?"agencyremarks":selectedRow?.fi_status==="Door Locked"?"entryDenied":"primaryInfo"}>
          <Nav as="ul" className="nav-tabs-custom nav-justified">
            {selectedRow?.fi_status !== "Door Locked" &&
              selectedRow.fi_status !== "Untraceable" && (
                <Nav.Item as="li" key="0">
                  <Nav.Link eventKey="primaryInfo">
                    <b> Primary Info </b>
                  </Nav.Link>
                </Nav.Item>
              )}
            {selectedRow?.fi_status !== "Door Locked" &&
              selectedRow.fi_status !== "Untraceable" &&
              ( (selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") ? (
                <Nav.Item as="li" key="1">
                  <Nav.Link eventKey="employmentDetails">
                    <b> Employment/Business Info </b>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item as="li" key="1">
                  <Nav.Link eventKey="personaInfo">
                    <b> Personal Info </b>
                  </Nav.Link>
                </Nav.Item>
              ))}
            {selectedRow?.fi_status !== "Door Locked" &&
              selectedRow.fi_status !== "Untraceable" &&
              ((selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") ? (
                <Nav.Item as="li" key="2">
                  <Nav.Link eventKey="verifierInferenceDetails">
                    <b> Verifier's Inference on Office </b>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item as="li" key="2">
                  <Nav.Link eventKey="residentalInfo">
                    <b> Residental Info </b>
                  </Nav.Link>
                </Nav.Item>
              ))}
            {selectedRow?.fi_status !== "Door Locked" &&
              selectedRow.fi_status !== "Untraceable" && (
                <Nav.Item as="li" key="3">
                  <Nav.Link eventKey="tpcCheckInfo">
                    <b> TPC/Neighbour Check </b>
                  </Nav.Link>
                </Nav.Item>
              )}
              {selectedRow.fi_status !== "Door Locked" && 
            <Nav.Item as="li" key="4">
              <Nav.Link eventKey="agencyremarks">
                <b>
                  {" "}
                  {selectedRow.fi_status === "Untraceable"
                    ? "Address Untraceable"
                    : "FI Agency Remarks"}{" "}
                </b>
              </Nav.Link>
            </Nav.Item>}
            {selectedRow?.fi_status === "Door Locked" &&
            <Nav.Item as="li" key="5">
              <Nav.Link eventKey="entryDenied">
                <b> Entry Denied/Door locked </b>
              </Nav.Link>
            </Nav.Item>}
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="primaryInfo" key="0" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr></tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Application Number
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.application_number
                            ? selectedRow.application_number
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Applicant's Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.customer_name
                            ? selectedRow.customer_name
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Applicant Type
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.applicant_type
                            ? allocationDetails.applicant_type
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          FI Type
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.fi_type ? selectedRow.fi_type : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Client
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.client ? selectedRow.client : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Client Branch
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.client_branch
                            ? selectedRow.client_branch
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Client State
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.client_state
                            ? selectedRow.client_state
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Loan Type/Product Type
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.product_type
                            ? selectedRow.product_type
                            : selectedRow.loan_type
                            ? selectedRow.loan_type
                            : "-"}
                        </td>
                      </tr>
                      {/* <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Product Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.product_name
                            ? selectedRow.product_name
                            : "-"}
                        </td>
                      </tr> */}
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          CPV Verification Geo Limit
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.cpv_verification_geo_limit
                            ? selectedRow.cpv_verification_geo_limit
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Father/Spouse Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow?.father_name
                            ? selectedRow?.father_name
                            : "-"}
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Contact Numer
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.contact_no ? selectedRow.contact_no : 0}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Residence Address
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.address_line1
                          ? selectedRow.address_line1
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        City
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.city ? selectedRow.city : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        State
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.state ? selectedRow.state : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Pin Code
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.postal_code
                          ? selectedRow.postal_code
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Landmark
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {selectedRow.nearby_landmark
                          ? selectedRow.nearby_landmark
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Actual Address Verification Done
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.actual_address_verified
                          ? allocationDetails.actual_address_verified
                          : "-"}
                      </td>
                    </tr>
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                    <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Entry Allowed Inside the Office
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.office_entry_allowed
                            ? allocationDetails.office_entry_allowed
                            : "-"}
                        </td>
                      </tr>)}
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Distance
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.distance
                          ? allocationDetails.distance
                          : "-"}
                      </td>
                    </tr>
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Applicant's Profile
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.applicant_profile
                            ? allocationDetails.applicant_profile
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Office/Bussiness Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.office_name
                            ? selectedRow.office_name
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Designation of The Applicant
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.customer_designation
                            ? selectedRow.customer_designation
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Applicant's Name Confirmed
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.applicant_name_confirmed
                            ? allocationDetails.applicant_name_confirmed
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Applicant's Designation Confirmed
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.applicant_designation_confirmed
                            ? allocationDetails.applicant_designation_confirmed
                            : "-"}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Contacted Person Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.contacted_person_name
                          ? allocationDetails.contacted_person_name
                          : "-"}
                      </td>
                    </tr>
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Designation of Person Contacted
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.designation_of_person_contacted
                            ? allocationDetails.designation_of_person_contacted
                            : "-"}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Relationship with the Applicant
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.relationship_with_applicant
                          ? allocationDetails.relationship_with_applicant
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        End User of the Product
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.enduser_of_product
                          ? allocationDetails.enduser_of_product
                          : "-"}
                      </td>
                    </tr>
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Is It Residence Cum Office
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.is_it_residence_cum_office
                            ? allocationDetails.is_it_residence_cum_office
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Any Other Loans or EMI Availed
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.other_loans_info
                            ? allocationDetails.other_loans_info
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Loan Product
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.other_loans_product
                            ? allocationDetails.other_loans_product
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Loan Number
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.other_loans_loan_no
                            ? allocationDetails.other_loans_loan_no
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Amout
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.other_loans_amount
                            ? allocationDetails.other_loans_amount
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {(selectedRow?.fi_type?.toLowerCase()==="current office" || selectedRow?.fi_type?.toLowerCase()==="other office") && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          EMI
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.other_loans_emi
                            ? allocationDetails.other_loans_emi
                            : "-"}
                        </td>
                      </tr>
                    )}
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="employmentDetails" key="1" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Type of Employment/Organization
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.type_of_org
                            ? allocationDetails.type_of_org
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Business Constitution
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.business_constitution
                            ? allocationDetails.business_constitution
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Nature of Business/Job Description
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.nature_of_business
                            ? allocationDetails.nature_of_business
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          No. of Years in Employment/Business
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.no_of_years_emp
                            ? allocationDetails.no_of_years_emp
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Total Employment/Bussiness Stability (In Years and
                          Months)
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.total_employeement
                            ? allocationDetails.total_employeement
                            : "-"}
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Net/Gross Monthly Income
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.gmi ? allocationDetails.gmi : "-"}
                      </td>
                    </tr>
                  </div>
                </div>
              </Col>{" "}
            </Tab.Pane>
            <Tab.Pane eventKey="personaInfo" key="1" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Applicant's Age
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.applicant_age
                            ? allocationDetails.applicant_age
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          No.of Family Members
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.family_members_count
                            ? allocationDetails.family_members_count
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          No. of Earning Members
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.no_of_earning_members
                            ? allocationDetails.no_of_earning_members
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          No. of Dependents
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.no_of_dependents
                            ? allocationDetails.no_of_dependents
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Marital Status
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.marital_status
                            ? allocationDetails.marital_status
                            : "-"}
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Applicant's Working Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.applicant_working
                          ? allocationDetails.applicant_working
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Applicant's Company/Business Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.applicant_company_name
                          ? allocationDetails.applicant_company_name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Applicant's Company/Business Address
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.applicant_company_address
                          ? allocationDetails.applicant_company_address
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Applicant's Monthly/Annual Income
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.occupation_income_of_applicant
                          ? allocationDetails.occupation_income_of_applicant
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Spouse Working Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.spouse_working
                          ? allocationDetails.spouse_working
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Spouse Company/Business Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.spouse_company_name
                          ? allocationDetails.spouse_company_name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Spouse Company/Business Address
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.spouse_company_address
                          ? allocationDetails.spouse_company_address
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Spouse Monthly/Amual Income
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.occupation_income_of_spouse
                          ? allocationDetails.occupation_income_of_spouse
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Any Other Loans or EMI Availed
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.other_loans_info
                          ? allocationDetails.other_loans_info
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Loan Product
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.other_loans_product
                          ? allocationDetails.other_loans_product
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Loan Number
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.other_loans_loan_no
                          ? allocationDetails.other_loans_loan_no
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Amout
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.other_loans_amount
                          ? allocationDetails.other_loans_amount
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        EMI
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.other_loans_emi
                          ? allocationDetails.other_loans_emi
                          : "-"}
                      </td>
                    </tr>
                  </div>
                </div>
              </Col>{" "}
            </Tab.Pane>
            <Tab.Pane eventKey="verifierInferenceDetails" key="2" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    margin: "5px",
                    overflowY: "auto",
                    maxHeight: "400px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Office Name/Business Sign Board Seen
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.sign_board_seen
                            ? allocationDetails.sign_board_seen
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Nearest Landmark
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.nearest_land_mark
                            ? allocationDetails.nearest_land_mark
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Area Locality
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.area_locality
                            ? allocationDetails.area_locality
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Locating Address
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.house_location
                            ? allocationDetails.house_location
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Location of Office
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.location_of_office
                            ? allocationDetails.location_of_office
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Office Interior Condition
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.interior_condition
                            ? allocationDetails.interior_condition
                            : "-"}
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Office Exterior Condition
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.exterior_condition
                          ? allocationDetails.exterior_condition
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Approx Area of Office(in sq.ft)
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.residence_carpet_area
                          ? allocationDetails.residence_carpet_area
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Type of Job
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.type_of_job
                          ? allocationDetails.type_of_job
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Type of Office
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.type_of_office
                          ? allocationDetails.type_of_office
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Visiting Card Obtained
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.visiting_card_obtaines
                          ? allocationDetails.visiting_card_obtaines
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        BIZ Activity
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.biz_activity
                          ? allocationDetails.biz_activity
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        No.of Employees
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.no_of_employees
                          ? allocationDetails.no_of_employees
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        No.of Employees Seen at Office
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.employees_seen_count
                          ? allocationDetails.employees_seen_count
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Stock Seen
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.stock_seen
                          ? allocationDetails.stock_seen
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Asset/Vehicle Seen at Office
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.vehicle_seen_at_residence
                          ? allocationDetails.vehicle_seen_at_residence
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        List the Assets at Office
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.residence_assets
                          ? allocationDetails.residence_assets
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Affiliation Seen for any Political Party
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.political_party_affilication
                          ? allocationDetails.political_party_affilication
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Affiliation Political Party Remarks
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.political_remarks
                          ? allocationDetails.political_remarks
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Caution Profile
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.caution_profile
                          ? allocationDetails.caution_profile
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Caution Profile Remarks
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.caution_profile_remarks
                          ? allocationDetails.caution_profile_remarks
                          : "-"}
                      </td>
                    </tr>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="residentalInfo" key="2" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    margin: "5px",
                    overflowY: "auto",
                    maxHeight: "400px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Name verified on house Exterior
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.house_exterior_name_verification
                            ? allocationDetails.house_exterior_name_verification
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          How Co-operative was the Customer
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.customer_cooperation
                            ? allocationDetails.customer_cooperation
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Entry Allowed Inside the House
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.entry_in_house
                            ? allocationDetails.entry_in_house
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          No of years of stay in city
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.city_stay_inyears
                            ? allocationDetails.city_stay_inyears
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Number of years of Stay at Residence
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.residence_stay_inyears
                            ? allocationDetails.residence_stay_inyears +
                              " Years"
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Number of Months of Stay at Residence
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.residence_stay_inmonths
                            ? allocationDetails.residence_stay_inmonths +
                              " Months"
                            : "-"}
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Ownership
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.residence_ownership
                          ? allocationDetails.residence_ownership
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Ownership Others
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.permanentZipcode
                          ? allocationDetails.permanentZipcode
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Rent Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.rent_amount
                          ? allocationDetails.rent_amount
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Permanent Address
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.referenceName1
                          ? allocationDetails.referenceName1
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Area Locality
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.area_locality
                          ? allocationDetails.area_locality
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Approach to House
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.house_approach
                          ? allocationDetails.house_approach
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Locating Address
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.house_location
                          ? allocationDetails.house_location
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Nearest Landmark
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.nearest_land_mark
                          ? allocationDetails.nearest_land_mark
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Type Of Accommodation
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.accommodation_type
                          ? allocationDetails.accommodation_type
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Construction of House
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.construction_of_house
                          ? allocationDetails.construction_of_house
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Residence Carpet Area ( Sq Ft)
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.residence_carpet_area
                          ? allocationDetails.residence_carpet_area
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        House Interior Condition
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.interior_condition
                          ? allocationDetails.interior_condition
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        House Exterior Condition
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.exterior_condition
                          ? allocationDetails.exterior_condition
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Neighbourhood (Locality)
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.locality
                          ? allocationDetails.locality
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Standard of Living
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.living_standard
                          ? allocationDetails.living_standard
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Address & ID Proof Sighted
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.address_proof_sighted
                          ? allocationDetails.address_proof_sighted
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Type of Address & ID Proof
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.address_proof_type
                          ? allocationDetails.address_proof_type
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Asset/Vehicle Seen at Residence
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.residence_assets
                          ? allocationDetails.residence_assets
                          : "-"}
                      </td>
                    </tr>
                    {/* <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        List the Assets at Residence
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.guarantorName
                          ? allocationDetails.guarantorName
                          : "-"}
                      </td>
                    </tr> */}
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Affiliation Seen for any Political Party
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.political_party_affilication
                          ? allocationDetails.political_party_affilication
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Affiliation Political Party Remarks
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.political_remarks
                          ? allocationDetails.political_remarks
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Caution Profile
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.caution_profile
                          ? allocationDetails.caution_profile
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Caution Profile Remarks
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.caution_profile_remarks
                          ? allocationDetails.caution_profile_remarks
                          : "-"}
                      </td>
                    </tr>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="tpcCheckInfo" key="3" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <table>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Neighbour/Third Party Reference 1 Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.neighbor_reference1
                            ? allocationDetails.neighbor_reference1
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Neighbour/ThirdParty 1 Feedback
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.neighbor_reference1_confirmation
                            ? allocationDetails.neighbor_reference1_confirmation
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Neighbour/Third Party Reference 2 Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.neighbor_reference2
                            ? allocationDetails.neighbor_reference2
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Neighbour/ThirdParty 2 Feedback
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.neighbor_reference2_confirmation
                            ? allocationDetails.neighbor_reference2_confirmation
                            : "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane
              eventKey="agencyremarks"
              key="4"
              mountOnEnter
              unmountOnExit
            >
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <table>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          {selectedRow.fi_status === "Untraceable"
                            ? "Calls Made to Customer"
                            : "No of Visits"}
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {selectedRow.fi_status === "Untraceable"
                            ? allocationDetails?.calls_made_to_custoomer
                            : allocationDetails.no_of_visits
                            ? allocationDetails.no_of_visits
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 1
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_1
                            ? agencyRemarks?.remarks_1
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 2
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_2
                            ? agencyRemarks?.remarks_2
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 3
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_3
                            ? agencyRemarks?.remarks_3
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 4
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_4
                            ? agencyRemarks?.remarks_4
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 5
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_5
                            ? agencyRemarks?.remarks_5
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 6
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_6
                            ? agencyRemarks?.remarks_6
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 7
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_7
                            ? agencyRemarks?.remarks_7
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 8
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_8
                            ? agencyRemarks?.remarks_8
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 9
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_9
                            ? agencyRemarks?.remarks_9
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 10
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_10
                            ? agencyRemarks?.remarks_10
                            : "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="entryDenied" key="5" mountOnEnter unmountOnExit>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Contacted Person Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.contacted_person_name
                          ? allocationDetails.contacted_person_name
                          : "-"}
                      </td>
                    </tr>
<tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Contact Numer
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.contacted_person_number ? allocationDetails.contacted_person_number : '-'}
                      </td>
                    </tr>
<tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Relationship with the Applicant
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.relationship_with_applicant
                          ? allocationDetails.relationship_with_applicant
                          : "-"}
                      </td>
                    </tr>
 <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Applicant's Age
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.neighbor_conf_applicant_age
                            ? allocationDetails.neighbor_conf_applicant_age
                            : "-"}
                        </td>
                      </tr>
<tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          No.of Family Members
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.neighbor_conf_family_count
                            ? allocationDetails.neighbor_conf_family_count
                            : "-"}
                        </td>
                      </tr>
 <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Ownership
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.neighbor_conf_ownership
                          ? allocationDetails.neighbor_conf_ownership
                          : "-"}
                      </td>
                    </tr>
 <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Address
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.neighbor_conf_address
                          ? allocationDetails.neighbor_conf_address
                          : "-"}
                      </td>
                    </tr>
 <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                      Years Of Stay
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.neighbor_conf_years_stay
                          ? allocationDetails.neighbor_conf_years_stay
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 1
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_1
                            ? agencyRemarks?.remarks_1
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 2
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_2
                            ? agencyRemarks?.remarks_2
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 3
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_3
                            ? agencyRemarks?.remarks_3
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 4
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_4
                            ? agencyRemarks?.remarks_4
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 5
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_5
                            ? agencyRemarks?.remarks_5
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 6
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_6
                            ? agencyRemarks?.remarks_6
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 7
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_7
                            ? agencyRemarks?.remarks_7
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 8
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_8
                            ? agencyRemarks?.remarks_8
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 9
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_9
                            ? agencyRemarks?.remarks_9
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Remarks 10
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {agencyRemarks?.remarks_10
                            ? agencyRemarks?.remarks_10
                            : "-"}
                        </td>
                      </tr>
                    </>
                  </div>
                </div>
              </Col>{" "}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <Modal className="fade" show={editModal} size="md">
        <Modal.Header>
          <Modal.Title>Update Mobile Number</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>
    </div>
  );
};

export default FISubmittedDetails;
