import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
// import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import ReactGA from "react-ga";
import * as XLSX from "xlsx";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import SearchBar from "../CommonComponents/SearchBar";
import { EpochToDate, EpochToOnlyDate } from "../CommonComponents/Helper";
import CollectionVisitDetails from "./CollectionVisitDetails";
import Summary from "./Summary";
import * as helper from "../CommonComponents/Helper";
import LoanNumberDetails from "../RequestPayments/LoanNumberDetails";

function CpUsersAllocations() {
  // var history = useHistory();
  const [allocationsList, setAllocationsList] = useState([]);
  const [loanNoDetailsModal, setLoanNoDetailsModal] = useState(false);
  const [modalForCustomerIntensity, setModalForCustomerIntensity] =
    useState(false);
  const [visitDetails, setVisitDetails] = useState({});
  //   const [addCpUserModal, setAddCpUserModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBucketNames, setIsBucketNames] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [bucketNames, setBucketNames] = useState([]);
  const [selectedBucketName, setSelectedBucketName] = useState("");
  const [custData, setCustData] = useState(null);
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let listData = [];
  let userLoginDetails = getTokenInLocalStorage();
  // let userLoginDetails = loginData.auth.auth;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getBranches();
    getBucketNames();
    loadData(0, start, end, searchKey, selectedBranch, selectedBucketName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    custData !== null && handleExport();
    setIsLoading(false);
    // setIsLoading3(false);
    // eslint-disable-next-line
  }, [custData]);

  const loadData = (pageNo, start, end, key, branchId, bucketName) => {
    setIsLoading(true);
    let body = {
      branchId: branchId,
      bucketName: bucketName,
      cpName: userLoginDetails.autoFinanceOrganization.cpName,
      endDate: end,
      organizationId:
        userLoginDetails.autoFinanceOrganization.mainOrganizationId,
      searchKey: key,
      startDate: start,
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          apiCall.getCollectionAllocationsBasedOnCpName,
          pageNo,
          10
        ),
        body
      )
      .then((response) => {
        console.log(response);
        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
          console.log("response", response);
          response?.content?.forEach((element) => {
            let data = {
              addressLine2: element.addressLine2,
              ag_Date: element.ag_Date,
              amountPaid: element.amountPaid,
              amountPaidNow: element.amountPaidNow,
              amountToBePaid: element.amountToBePaid,
              assetDesc: element.assetDesc,
              assignedMobileNumber: element.assignedMobileNumber,
              assignedName: element.assignedName,
              bounceCharges: element.bounceCharges,
              branchId: element.branchId,
              chassisNumber: element.chassisNumber,
              comments: element.comments,
              contactNumber: element.contactNumber1,
              contactNumber2: element.contactNumber2,
              cpName: element.cpName,
              currentBucket: element.currentBucketName,
              customerIntensinty: element.customerIntensinty,
              lenderId: element.lenderId,
              lenderName: element.lenderName,
              lenderAddress: element.promoterAddress,
              borrowerAddress: element.borrowerAddress,
              current_EMI_Month: element.currentEmiMonthNumber,
              customerName: element.customerName,
              dateTime:
                element.dateTime !== 0 ? EpochToDate(element.dateTime) : "-",
              dueAmount: element.dueAmount,
              dueDate:
                element.dueDate !== 0 ? EpochToOnlyDate(element.dueDate) : "-",
              dueDay: element.dueDay,
              emiAmount: element.emiAmount,
              emiOutstanding: element.emiOutstanding,
              engineNumber: element.engineNumber,
              employeeId: element.executiveEmployeeId
                ? element.executiveEmployeeId
                : element.managerEmployeeId,
              executiveMobileNumber: element.executiveMobileNumber,
              executiveName: element.executiveName,
              guarantorMobile: element.guarantorMobile,
              guarantorName: element.guarantorName,
              id: element.id,
              image: element.image,
              latePaymentCharges: element.latePaymentCharges,
              latitude: element.latitude,
              loanAmount: element.loanAmount,
              loanNumber: element.loanNumber,
              location: element.location,
              longitude: element.longitude,
              managerEmployeeId: element.managerEmployeeId,
              managerName: element.managerName,
              nearByLandmark: element.nearByLandmark,
              netOtherAmount: element.netOtherAmount,
              net_GDI_Amount: element.net_GDI_Amount,
              net_HL_Amount: element.net_HL_Amount,
              organizationId: element.organizationId,
              permanentAddress: element.permanentAddress,
              permanentCity: element.permanentCity,
              permanentState: element.permanentState,
              permanentZipcode: element.permanentZipcode,
              principalOutstanding: element.principalOutstanding,
              productName: element.productName,
              referenceMobile1: element.referenceMobile1,
              referenceMobile2: element.referenceMobile2,
              referenceName1: element.referenceName1,
              referenceName2: element.referenceName2,
              registrationNumber: element.registrationNumber,
              remarks: element.remarks,
              reportingOfficerId: element.reportingOfficerId,
              address: element.residenceAddress,
              residenceCity: element.residenceCity,
              residenceState: element.residenceState,
              residenceZipcode: element.residenceZipcode,
              status: element.status,
              subStatus: element.subStatus,
              teamLeaderEmployeeId: element.teamLeaderEmployeeId,
              teamLeaderName: element.teamLeaderName,
              temporaryRegistrationNumber: element.temporaryRegistrationNumber,
              tenure: element.tenure,
              totalPenaltyCharges: element.totalPenaltyCharges,
              typeOfVisit: element.typeOfVisit,
              thirdPartyPhoneNumber: element.thirdPartyPhoneNumber,
              thirdPartyName: element.thirdPartyName,
              updatedDate:
                element.updatedDate !== 0
                  ? EpochToDate(element.updatedDate)
                  : "-",
              userId: element.userId,
              vehicleType: element.vehicleType,
              visitStatus: element.visitStatus,
            };
            listData.push(data);
          });
          setAllocationsList(listData);
          setTotalElements(response?.totalElements);
          setIsLoading(false);
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  //   const handleClose = () => {
  //     setAddCpUserModal(false);
  //     loadData(0, start, end);
  //   };

  const setcustomerIntensity = (row) => {
    console.log("basic modal1");
    setModalForCustomerIntensity(true);
    console.log("row", row);
    setVisitDetails(row);
  };

  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0].getFullYear(),
            value[0].getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86399);
    // var start = value[0].getTime() / 1000;
    // var end =
    //   new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
    //     1000 +
    //   86399;

    setStart(start);
    setEnd(end);
    loadData(0, start, end, searchKey, selectedBranch, selectedBucketName);
  };

  let path = window.location.pathname;
  path = path.split("/");
  // eslint-disable-next-line no-unused-vars
  path = path[path.length - 1];

  const columns = [
    {
      text: "Emp Id",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile",
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan No",
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              // textDecoration: "underline",
            }}
            onClick={() => {
              setVisitDetails(row);
              setLoanNoDetailsModal(true);
            }}
          >
            {row.loanNumber}
          </span>
        </div>
      ),
    },
    // {
    //   text: "Visit Type",
    //   dataField: "typeOfVisit",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Amount Paid",
      dataField: "amountPaid",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Current Due</span>
          <br></br>
          <span>Amount</span>
        </div>
      ),
      dataField: "amountToBePaid",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total Due</span>
          <br></br>
          <span>Amount</span>
        </div>
      ),
      dataField: "dueAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Due Date",
      dataField: "dueDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: " Residence Address",
      dataField: "address",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Assigned</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Intensity</span>
        </div>
      ),
      dataField: "customerIntensinty",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => {
              setcustomerIntensity(row);
            }}
          >
            {row.customerIntensinty}
          </span>
        </div>
      ),
    },
    {
      text: (
        <div>
          <span>Last visited</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "updatedDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Visit</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "visitStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.visitStatus === "Partially Paid") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "PTP") {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "Paid") {
          return (
            <span className="badge badge-rounded badge-success">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "Customer Not Available") {
          return (
            <span className="badge badge-rounded badge-danger">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "" || row.visitStatus === null) {
          return <span>-</span>;
        } else {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.visitStatus}
            </span>
          );
        }
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.subStatus === "Partially Paid") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "PTP") {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "Paid") {
          return (
            <span className="badge badge-rounded badge-success">
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "Customer Not Available") {
          return (
            <span className="badge badge-rounded badge-danger">
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "" || row.subStatus === null) {
          return <span>-</span>;
        } else {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.subStatus}
            </span>
          );
        }
      },
    },

    {
      text: (
        <div>
          <span>Allocation</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "OPEN") {
          return (
            <span className="badge badge-rounded badge-primary">Open</span>
          );
        } else if (row.status === "COMPLETED") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              In Progress
            </span>
          );
        }
      },
    },
  ];

  const handleClose1 = () => {
    setModalForCustomerIntensity(false);
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(
      selectedPage - 1,
      start,
      end,
      searchKey,
      selectedBranch,
      selectedBucketName
    );
  };

  const handleLoanNumberDetailsModal = () => {
    setLoanNoDetailsModal(false);
    loadData(
      currPage,
      start,
      end,
      searchKey,
      selectedBranch,
      selectedBucketName
    );
  };

  // const expandRow = {
  //   className: "expanding-row",
  //   // parentClassName: "expanding-parent-row",
  //   parentClassName: "bar",
  //   renderer: (row) => (
  //     <div
  //       style={{
  //         overflowX: "auto",
  //       }}
  //     >
  //       <Row>
  //         <Col>
  //           <div
  //             className="card contact-bx item-content"
  //             style={{
  //               backgroundColor: "rgb(243,240,249)",
  //               height: "200px",
  //               overflowY: "auto",
  //               // width:'600px'
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginRight: "20px",
  //                 marginBottom: "10px",
  //                 marginTop: "30px",
  //                 marginLeft: "20px",
  //               }}
  //             >
  //               <>
  //                 <tr></tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Loan Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.loanNumber ? row.loanNumber : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Customer Name
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.customerName ? row.customerName : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Vehicle Type
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.vehicleType ? row.vehicleType : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Customer Intensity
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.customerIntensinty ? row.customerIntensinty : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Product
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.productName ? row.productName : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     CP Name
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.cpName ? row.cpName : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Lender ID
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.lenderId ? row.lenderId : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Lender Name
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.lenderName ? row.lenderName : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Total Due Amount
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.dueAmount ? row.dueAmount : "-"}
  //                   </td>
  //                 </tr>
  //               </>

  //               <tr>
  //                 <td style={{ fontWeight: "500", textAlign: "left" }}>
  //                   Current Due Amount
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.amountToBePaid ? row.amountToBePaid : "-"}
  //                 </td>
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Amount Paid
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.amountPaid ? row.amountPaid : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence Address Line 1
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.address ? row.address : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence Address Line 2
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.addressLine2 ? row.addressLine2 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence City
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.residenceCity ? row.residenceCity : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence State
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.residenceState ? row.residenceState : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence ZipCode
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.residenceZipcode ? row.residenceZipcode : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Near By Landmark
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.nearByLandmark ? row.nearByLandmark : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Contact No 1
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.contactNumber ? row.contactNumber : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Remarks
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.remarks ? row.remarks : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Due Date
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.dueDate ? row.dueDate : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Assigned Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.assignedName ? row.assignedName : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Assigned Mobile Number
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.assignedMobileNumber ? row.assignedMobileNumber : "-"}
  //                 </td>
  //               </tr>
  //             </div>
  //           </div>
  //         </Col>

  //         <Col>
  //           <div
  //             className="card contact-bx item-content"
  //             style={{
  //               backgroundColor: "rgb(243,240,249)",
  //               height: "200px",
  //               overflowY: "auto",
  //               // width:'600px'
  //             }}
  //           >
  //             <div
  //             // style={{
  //             //   marginRight: "20px",
  //             //   marginBottom: "10px",
  //             //   marginTop: "30px",
  //             //   marginLeft: "20px",
  //             // }}
  //             >
  //               <>
  //                 <tr
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "right",
  //                   }}
  //                 >
  //                   Loan Details
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Loan Amount
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.loanAmount ? row.loanAmount : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     POS (Principal Outstanding)
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.principalOutstanding
  //                       ? row.principalOutstanding
  //                       : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     EMI Amount
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.emiAmount ? row.emiAmount : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Current EMI Month
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.current_EMI_Month ? row.current_EMI_Month : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Current Bucket
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.currentBucket ? row.currentBucket : "-"}
  //                   </td>
  //                 </tr>
  //               </>

  //               <tr>
  //                 <td style={{ fontWeight: "500", textAlign: "left" }}>
  //                   Tenure (Months)
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.tenure ? row.tenure : "-"}
  //                 </td>
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   EMI OS
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.emiOutstanding ? row.emiOutstanding : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   LPP (Late Payment Charges)
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.latePaymentCharges ? row.latePaymentCharges : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   BCC (Bounce Charges)
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.bounceCharges ? row.bounceCharges : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Total Penalty Charges
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.totalPenaltyCharges ? row.totalPenaltyCharges : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Net GDI Amount
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.net_GDI_Amount ? row.net_GDI_Amount : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Net HL Amount
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.net_HL_Amount ? row.net_HL_Amount : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Net Other Amount
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.netOtherAmount ? row.netOtherAmount : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   AG Date
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.ag_Date ? row.ag_Date : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Due Day
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.dueDay ? row.dueDay : "-"}
  //                 </td>
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Third Party Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.thirdPartyName ? row.thirdPartyName : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Third Party Mobile
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.thirdPartyPhoneNumber
  //                     ? row.thirdPartyPhoneNumber
  //                     : "-"}
  //                 </td>
  //               </tr>
  //             </div>
  //           </div>
  //         </Col>

  //         <Col>
  //           <div
  //             className="card contact-bx item-content"
  //             style={{
  //               backgroundColor: "rgb(243,240,249)",
  //               height: "200px",
  //               overflowY: "auto",
  //               // width:'600px'
  //             }}
  //           >
  //             <div
  //             // style={{
  //             //   marginRight: "20px",
  //             //   marginBottom: "10px",
  //             //   marginTop: "30px",
  //             //   marginLeft: "20px",
  //             // }}
  //             >
  //               <>
  //                 <tr
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "right",
  //                   }}
  //                 >
  //                   Customer Details
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Location
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.location ? row.location : "-"}
  //                   </td>
  //                 </tr>

  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Contact No 2
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.contactNumber2 ? row.contactNumber2 : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Borrower Address (Home)
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.borrowerAddress ? row.borrowerAddress : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Promoter Address (Office)
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.lenderAddress ? row.lenderAddress : "-"}
  //                   </td>
  //                 </tr>

  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Permanent Address
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.permanentAddress ? row.permanentAddress : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Permanent City
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.permanentCity ? row.permanentCity : "-"}
  //                   </td>
  //                 </tr>
  //               </>

  //               <tr>
  //                 <td style={{ fontWeight: "500", textAlign: "left" }}>
  //                   Permanent State
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.permanentState ? row.permanentState : "-"}
  //                 </td>
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Permanent ZipCode
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.permanentZipcode ? row.permanentZipcode : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Ref1 Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.referenceName1 ? row.referenceName1 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Ref1 Mobile
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.referenceMobile1 ? row.referenceMobile1 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Ref2 Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.referenceName2 ? row.referenceName2 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Ref2 Mobile
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.referenceMobile2 ? row.referenceMobile2 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Guarantor Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.guarantorName ? row.guarantorName : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Guarantor Mobile
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.guarantorMobile ? row.guarantorMobile : "-"}
  //                 </td>
  //               </tr>
  //             </div>
  //           </div>
  //         </Col>

  //         <Col>
  //           <div
  //             className="card contact-bx item-content"
  //             style={{
  //               backgroundColor: "rgb(243,240,249)",
  //               height: "200px",
  //               overflowY: "auto",
  //               // width:'600px'
  //             }}
  //           >
  //             <div
  //             // style={{
  //             //   marginRight: "20px",
  //             //   marginBottom: "10px",
  //             //   marginTop: "30px",
  //             //   marginLeft: "20px",
  //             // }}
  //             >
  //               <>
  //                 <tr
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "right",
  //                   }}
  //                 >
  //                   Product Details
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Registration Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.registrationNumber ? row.registrationNumber : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Temporary Registration Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.temporaryRegistrationNumber
  //                       ? row.temporaryRegistrationNumber
  //                       : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Engine Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.engineNumber ? row.engineNumber : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Chasis Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.chassisNumber ? row.chassisNumber : "-"}
  //                   </td>
  //                 </tr>
  //               </>

  //               <tr>
  //                 <td style={{ fontWeight: "500", textAlign: "left" }}>
  //                   Asset Desc
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.assetDesc ? row.assetDesc : "-"}
  //                 </td>
  //               </tr>
  //               <tr
  //                 style={{
  //                   fontWeight: "bold",
  //                   textAlign: "right",
  //                 }}
  //               >
  //                 Manager Details
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Manager Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.managerName ? row.managerName : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Manager Emp ID
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.managerEmployeeId ? row.managerEmployeeId : "-"}
  //                 </td>
  //               </tr>
  //             </div>
  //           </div>
  //         </Col>

  //         {/* <Col>
        
  //         <div
  //           className="card contact-bx item-content"
  //           style={{
  //             backgroundColor: "rgb(243,240,249)",
  //           }}
  //         >
  //           <h6 className="fs-18 font-w600 my-1">Status</h6>

  //           <div style={{ margin: "5%", marginTop: "2%" }}>
  //             <>
  //               <Table responsive="sm">
  //                 <tbody>
  //                 <tr>
  //                     <td style={{ borderColor: "grey", textAlign: "left" }}>
  //                       {" "}
  //                       Paid Count{" "}
  //                     </td>
  //                     <td style={{ borderColor: "grey" }}>
  //                       <div
  //                         style={{
  //                           borderColor: "grey",
  //                           backgroundColor: "#44d144",
  //                           borderRadius: "10px",
  //                           width: "40px",
  //                           height: "26px",
  //                           padding: "4px",
  //                           marginLeft: "auto",
  //                           color: "white",
  //                         }}
  //                       >
  //                         <b>
  //                           {" "}
  //                           {collectStatusCount?.paidCount
  //                             ? collectStatusCount?.paidCount
  //                             : "0"}
  //                         </b>
  //                       </div>
  //                     </td>
  //                   </tr>
                   
                   
  //                   <tr>
  //                     <td style={{ borderColor: "grey", textAlign: "left" }}>
  //                       {" "}
  //                       Partially Paid Count{" "}
  //                     </td>
  //                     <td style={{ borderColor: "grey" }}>
  //                       <div
  //                         style={{
  //                           borderColor: "grey",
  //                           backgroundColor: "orange",
  //                           borderRadius: "10px",
  //                           width: "40px",
  //                           height: "26px",
  //                           padding: "4px",
  //                           marginLeft: "auto",
  //                           color: "white",
  //                         }}
  //                       >
  //                         <b>
  //                           {collectStatusCount?.partiallyPaidCount
  //                             ? collectStatusCount?.partiallyPaidCount
  //                             : "0"}
  //                         </b>
  //                       </div>
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <td style={{ borderColor: "grey", textAlign: "left" }}>
  //                       {" "}
  //                       PTP Count{" "}
  //                     </td>
  //                     <td style={{ borderColor: "grey" }}>
  //                       <div
  //                         style={{
  //                           borderColor: "grey",
  //                           backgroundColor: "#0f7ce7",
  //                           borderRadius: "10px",
  //                           width: "40px",
  //                           height: "26px",
  //                           padding: "4px",
  //                           marginLeft: "auto",
  //                           color: "white",
  //                         }}
  //                       >
  //                         <b>
  //                           {collectStatusCount?.ptpCount
  //                             ? collectStatusCount?.ptpCount
  //                             : "0"}
  //                         </b>
  //                       </div>
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <td style={{ borderColor: "grey", textAlign: "left" }}>
  //                       {" "}
  //                       Customer Not Available Count{" "}
  //                     </td>
  //                     <td style={{ borderColor: "grey" }}>
  //                       <div
  //                         style={{
  //                           borderColor: "grey",
  //                           backgroundColor: "red",
  //                           borderRadius: "10px",
  //                           width: "40px",
  //                           height: "26px",
  //                           padding: "4px",
  //                           marginLeft: "auto",
  //                           color: "white",
  //                         }}
  //                       >
  //                         {" "}
  //                         <b>
  //                           {collectStatusCount?.customerNotAvailbleCount
  //                             ? collectStatusCount?.customerNotAvailbleCount
  //                             : "0"}
  //                         </b>
  //                       </div>
  //                     </td>
  //                   </tr>
  //                 </tbody>
  //               </Table>
  //             </>
  //           </div>
  //         </div>
        
  //     </Col> */}
  //       </Row>
  //     </div>
  //   ),
  //   //     onExpand: (row, isExpand, rowIndex, e) => {
  //   // setTemp('rakesh')
  //   //     },
  //   showExpandColumn: true,
  //   // onlyOneExpanding: true,
  //   expandByColumnOnly: true,
  // };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadData(0, start, end, key, selectedBranch, selectedBucketName);
  };

  const getBranches = () => {
    const payLoad = {
      searchKey: "",
      organizationId:
        userLoginDetails?.autoFinanceOrganization?.mainOrganizationId,
      cpName: userLoginDetails.autoFinanceOrganization.cpName,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response?.map((item) => ({
        value: item.branchId,
        label: item.branchName,
        // branchId: item.branchId,
      }));
      result.unshift({ label: "All", value: "" });
      setBranches(result);
    });
  };

  const getBucketNames = () => {
    const payLoad = {
      cpName: userLoginDetails?.autoFinanceOrganization?.cpName,
      organizationId:
        userLoginDetails?.autoFinanceOrganization?.mainOrganizationId,
    };

    moduleApi.postData(apiCall.getBucketNames, payLoad).then((response) => {
      if (response?.length > 0) {
        const result = response?.map((item) => ({
          value: item,
          label: item,
        }));
        result.unshift({ label: "All", value: "" });
        setBucketNames(result);
        setIsBucketNames(true);
      } else {
        setIsBucketNames(false);
      }
    });
  };

  const custDownloadData = () => {
    setIsLoading(true);
    let body = {
      branchId: selectedBranch,
      bucketName: selectedBucketName,
      cpName: userLoginDetails.autoFinanceOrganization.cpName,
      endDate: end,
      organizationId:
        userLoginDetails.autoFinanceOrganization.mainOrganizationId,
      searchKey: searchKey,
      startDate: start,
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          apiCall.getCollectionAllocationsBasedOnCpName,
          0,
          10
        ),
        body
      )
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getCustFullResponseData(
                  Math.floor(response.totalElements / 200)
                );
              }
            });
          }

          getCustFullResponseData(Math.floor(response.totalElements / 200));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getCustFullResponseData(size) {
    var tempList = [];
    var custListData = [];

    for (let i = 0; i <= size; i++) {
      setIsLoading(true);
      let body = {
        branchId: selectedBranch,
        bucketName: selectedBucketName,
        cpName: userLoginDetails.autoFinanceOrganization.cpName,
        endDate: end,
        organizationId:
          userLoginDetails.autoFinanceOrganization.mainOrganizationId,
        searchKey: searchKey,
        startDate: start,
      };

      await moduleApi
        .postData(
          moduleApi.withPage(
            apiCall.getCollectionAllocationsBasedOnCpName,
            i,
            200
          ),
          body
        )
        .then((response) => {
          console.log("responseDownlaod", response);
          tempList.push(...response.content);
        });
    }
    console.log("forloop", tempList);

    tempList?.forEach((element) => {
      let data = {
        address: element.residenceAddress,
        amountPaid: element.amountPaid,
        amountPaidNow: element.amountPaidNow,
        amountToBePaid: element.amountToBePaid,
        contactNumber: element.contactNumber1,
        customerName: element.customerName,
        dateTime: helper.EpochToDate(element.dateTime),
        employeeVisits: element.employeeVisits,
        id: element.id,
        customerIntensinty: element.customerIntensinty,
        employeeId: element.executiveEmployeeId
          ? element.executiveEmployeeId
          : element.managerEmployeeId,
        loanNumber: element.loanNumber,
        lenderId: element.lenderId ? element.lenderId : "-",
        lenderName: element.lenderName ? element.lenderName : "-",
        cpName: element.cpName ? element.cpName : "-",
        reportingOfficerId: element.reportingOfficerId,
        dueAmount: element.dueAmount,
        dueDate: element.dueDate !== 0 ? EpochToOnlyDate(element.dueDate) : "-",
        typeOfVisit: element.typeOfVisit,
        updatedDate:
          element.updatedDate !== 0
            ? helper.EpochToDate(element.updatedDate)
            : "-",
        userId: element.userId,
        visitStatus: element.visitStatus,
        status: element.status,
        subStatus: element?.subStatus,
      };
      custListData.push(data);
    });
    setCustData(custListData);
  }

  const handleExport = () => {
    let custTable = [
      {
        A: "Employee Id",
        B: "Customer Name",
        C: "Mobile",
        D: "CP Name",
        E: "Lender Id",
        F: "Lender Name",
        G: "Loan No",
        H: "Amount Paid",
        I: "Currnt Due Amount",
        J: "Total Due Amount",
        K: "Due Date",
        L: "Residence Address",
        M: "Assigned Date",
        N: "Customer Intensity",
        O: "Last Visited Date",
        P: "Visit Status",
        Q: "Sub Status",
        R: "Allocation Status",
      },
    ];
    console.log("custdata", custData);
    custData?.forEach((row) => {
      custTable.push({
        A: row.employeeId,
        B: row.customerName,
        C: row.contactNumber,
        D: row.cpName,
        E: row.lenderId,
        F: row.lenderName,
        G: row.loanNumber,
        H: row.amountPaid,
        I: row.amountToBePaid,
        J: row.dueAmount,
        K: row.dueDate,
        L: row.address,
        M: row.dateTime,
        N: row.customerIntensinty,
        O: row.updatedDate,
        P: row.visitStatus,
        Q: row.subStatus,
        R: row.status,
      });
    });

    const finalData = [...custTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Allocations");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      "Clu_Allocations_Report_" +
        // new Date().toLocaleString("en-US") +
        EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "CP Users Allocation Report Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Clu_Allocations_Report_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  return (
    <div>
      <div style={{ margin: "20px" }}>
        <div className="custom-tab-1">
          {/* activeKey={defaultKey} */}
          <Tab.Container defaultActiveKey="allocated">
            <Nav as="ul" className="nav-tabs-custom nav-justified">
              <Nav.Item as="li" key="0">
                <Nav.Link eventKey="allocated">
                  <b> Allocations </b>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key="1">
                <Nav.Link eventKey="summary">
                  <b> Summary </b>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-4">
              <Tab.Pane eventKey="allocated" key="0" mountOnEnter unmountOnExit>
                <div style={{ margin: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <SearchBar
                      placeholder="Search By Name/Mobile/Loan No/Vehicle No"
                      searchKeyword={searchKeyword}
                    />

                    {/* <Button
          style={{
            float: "right",
            margin: "10px",
            marginRight: "20px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => setAddCpUserModal(true)}
        >
          <b> + Add CP User </b>
        </Button> */}

                    <div
                      style={{
                        float: "right",
                        width: "120px",
                        marginRight: "5px",
                        // marginBottom: "20px",
                        marginLeft: "5px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => {
                          setSelectedBranch(value.value);
                          loadData(
                            0,
                            start,
                            end,
                            searchKey,
                            value.value,
                            selectedBucketName
                          );
                        }}
                        defaultValue={{ label: "All", value: "" }}
                        options={branches}
                      />
                    </div>
                    {isBucketNames && (
                      <div
                        style={{
                          float: "right",
                          width: "120px",
                          marginRight: "5px",
                          // marginBottom: "20px",
                          // marginLeft: "5px",
                          borderRadius: "3px",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #797979",
                        }}
                      >
                        <Select
                          isSearchable={false}
                          onChange={(value) => {
                            setSelectedBucketName(value.value);
                            loadData(
                              0,
                              start,
                              end,
                              searchKey,
                              selectedBranch,
                              value.value
                            );
                          }}
                          defaultValue={{ label: "All", value: "" }}
                          options={bucketNames}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        float: "right",
                        width: "140px",
                        marginRight: "5px",
                        height: "38px",
                        // marginLeft: "10px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #797979",
                      }}
                    >
                      <Select
                        isSearchable={false}
                        onChange={(value) => {
                          value.value === "cm"
                            ? setIsDateFilter(false)
                            : setIsDateFilter(true);
                        }}
                        defaultValue={dateOptions[0]}
                        options={dateOptions}
                      />
                    </div>
                    {!isDateFilter && (
                      <div
                        style={{
                          float: "right",
                          // width: "120px",
                          marginRight: "5px",
                          // marginBottom: "20px",
                          // marginLeft: "15px",
                        }}
                      >
                        <Flatpickr
                          id="customonthPicker2"
                          className="form-control d-block"
                          style={{
                            width: "150px",
                            height: "40px",
                            borderColor: "#a0a0a0",
                            borderWidth: "1px",
                            boxShadow: "1px 1px 2px #3a353b",
                            textAlignLast: "center",
                          }}
                          placeholder="Select Month"
                          onChange={(value) => {
                            setCustomDatesForGraph(value, true);
                          }}
                          options={{
                            defaultDate: new Date(),
                            maxDate: new Date(
                              new Date().getFullYear(),
                              new Date().getMonth() + 2,
                              0
                            ),
                            minDate: new Date().setMonth(
                              new Date().getMonth() - 6
                            ),

                            plugins: [
                              new monthSelectPlugin({
                                shorthand: true, //defaults to false
                                dateFormat: "F Y", //defaults to "F Y"
                                altFormat: "F Y", //defaults to "F Y"
                                theme: "light", // defaults to "light"
                              }),
                            ],
                          }}
                        />
                      </div>
                    )}
                    {isDateFilter && (
                      <div
                        style={{
                          float: "right",
                          // width: "120px",
                          marginRight: "5px",
                          // marginBottom: "20px",
                          // marginLeft: "15px",
                        }}
                      >
                        <Flatpickr
                          style={{
                            width: "150px",
                            height: "40px",
                            borderColor: "#a0a0a0",
                            borderWidth: "1px",
                            boxShadow: "1px 1px 2px #3a353b",
                            textAlignLast: "center",
                          }}
                          id="customDatePicker2"
                          className="form-control d-block"
                          placeholder="Start & End Date"
                          onChange={(value) => {
                            setCustomDatesForGraph(value, false);
                          }}
                          options={{
                            mode: "range",
                            dateFormat: "d-M-y",
                            maxDate: new Date(
                              new Date().getFullYear(),
                              new Date().getMonth() + 2,
                              0
                            ),
                          }}
                        />
                      </div>
                    )}

                    {/* <div
                      style={{
                        float: "right",
                        // width: "120px",
                        marginRight: "5px",
                        // marginBottom: "20px",
                        marginLeft: "15px",
                      }}
                    >
                      <Flatpickr
                        className="form-control d-block"
                        style={{
                          width: "200px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                          // paddingLeft: "10px",
                          // marginBottom: "10px",
                          // float: "right",
                        }}
                        placeholder="Select Month"
                        onChange={(value) => {
                          setCustomDatesForGraph(value);
                        }}
                        options={{
                          // mode: "range",
                          // dateFormat: "d-M-y",
                          defaultDate: new Date(),
                          maxDate: new Date(),
                          minDate: new Date().setMonth(
                            new Date().getMonth() - 6
                          ),

                          plugins: [
                            new monthSelectPlugin({
                              shorthand: true, //defaults to false
                              dateFormat: "F Y", //defaults to "F Y"
                              altFormat: "F Y", //defaults to "F Y"
                              theme: "light", // defaults to "light"
                            }),
                          ],
                        }}
                      />
                    </div> */}
                    <Button
                      style={{
                        // width: "105px",
                        // height: "37px",
                        margin: "0px 5px 0px  ",
                        // marginBottom: "15px",
                        marginRight: "10px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="success btn-rounded"
                      onClick={() => custDownloadData()}
                    >
                      <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                    </Button>
                  </div>
                  <LoadingOverlay
                    active={isLoading}
                    text={
                      <p
                        style={{
                          color: "black",
                        }}
                      >
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#FFFF",
                              color: "black",
                              borderColor: "white",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                            variant="primary"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="md"
                              variant="primary"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp;&nbsp;Loading...
                          </Button>
                        </div>
                      </p>
                    }
                    styles={{
                      overlay: (base) => ({
                        ...base,
                        background: "rgba(192,192,192,0.4)",
                      }),
                      spinner: (base) => ({
                        ...base,
                        width: "80px",
                        "& svg circle": {
                          stroke: "rgb(136, 108, 192)",
                        },
                      }),
                    }}
                  >
                    <div style={{ background: "white", margin: "10px" }}>
                      <div
                        style={{
                          overflow: "auto",
                          maxHeight: "500px",
                        }}
                      >
                        <BootstrapTable
                          keyField="id"
                          data={allocationsList}
                          columns={columns}
                          // expandRow={expandRow}
                        />
                      </div>
                      {allocationsList.length === 0 && (
                        <div className="d-flex justify-content-center">
                          <img src={NO_DATA_FOUND} alt="" />
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin:
                            totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                        }}
                      >
                        <PaginationComponent
                          totalItems={totalElements}
                          pageSize={10}
                          defaultActivePage={currPage + 1}
                          onSelect={onPageChange}
                        />
                      </div>
                    </div>
                  </LoadingOverlay>

                  <Modal
                    className="fade"
                    size="xl"
                    show={modalForCustomerIntensity}
                  >
                    <Modal.Header>
                      <Modal.Title>
                        <b>Customer Visit Details</b>
                      </Modal.Title>
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => handleClose1()}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body
                      style={{
                        maxHeight: "calc(120vh - 210px)",
                        overflowY: "auto",
                        padding: "10px",
                      }}
                    >
                      <CollectionVisitDetails
                        startDate={start}
                        endDate={end}
                        visitDetails={visitDetails}
                      />
                    </Modal.Body>
                  </Modal>

                  {/* <Modal className="fade" show={addCpUserModal}>
        <Modal.Header>
          <Modal.Title>Add Cp User</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddCpUserModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <AddCpUser close={handleClose} />
        </Modal.Body>
      </Modal> */}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="summary" key="2" mountOnEnter unmountOnExit>
                <Summary />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <Modal className="fade" size="xl" show={loanNoDetailsModal}>
            <Modal.Header>
              <Modal.Title>Allocation Details</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setLoanNoDetailsModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body style={{ padding: "15px" }}>
              <LoanNumberDetails
                allocationDetails={visitDetails}
                close={handleLoanNumberDetailsModal}
              />
            </Modal.Body>
          </Modal>
    </div>
  );
}

export default CpUsersAllocations;
