import React from "react";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import './Action.css';

function MonthPicker({setCustomDatesForGraph}) {
  return <Flatpickr
    className="form-control d-block flatpickr-css"
    placeholder="Select Month"
    onChange={(value) => {
      setCustomDatesForGraph(value);
    }}
    options={{
      defaultDate: new Date(),
      maxDate: new Date(),
      minDate: new Date().setMonth(new Date().getMonth() - 6),

      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: "F Y",
          altFormat: "F Y",
          theme: "light",
        }),
      ],
    }}
  />

}

export default MonthPicker