import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse?.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'Bad credentials':
            //return 'Invalid Password';
            swal("Oops", "Bad Credentials", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function getTokenInLocalStorage() {
    return JSON.parse(localStorage.getItem('userDetails'))
}


export function removeTokens() {
    localStorage.removeItem('userDetails');
    localStorage.removeItem('managerDetails');
    localStorage.removeItem('executiveDetails');
    localStorage.removeItem('inActiveDetails');
    localStorage.removeItem('L1ManagerDetails');
    localStorage.removeItem('prevUrl');
    localStorage.removeItem('fieldInvestigationAgency');
    localStorage.removeItem('isCallInProgress');
    localStorage.removeItem('filterValue');
    localStorage.removeItem('iconify-version');
    localStorage.removeItem('iconify-count');
    localStorage.removeItem('analyticsData');
    localStorage.removeItem('isDashboardCollectionAllocationCardCalled');
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    // let expireDate = new Date(tokenDetails.expireDate);
    // let todaysDate = new Date();

    // if (todaysDate > expireDate) {
    //     dispatch(logout(history));
    //     return;
    // }
    dispatch(loginConfirmedAction(tokenDetails));

    // const timer = expireDate.getTime() - todaysDate.getTime();
    // runLogoutTimer(dispatch, timer, history);
}

export function hasAccess(privID) {
    if (!privID)
        return false;
    var Ids = getTokenInLocalStorage()?.rolePrivileges[0]?.privilageIds
    return Ids && Ids.includes(privID);
}
export function hasAccessFromTeamPrivilege(privID) {
    if (!privID)
        return false;
    var Ids = getTokenInLocalStorage()?.selectedTeam?.privilages
    return Ids && Ids.includes(privID);
}

export function hasAccessToAgency() {
    if(getTokenInLocalStorage()?.fieldInvestigationAgency !== null) {
        return true;
    } else {
        return false;
    }
}

export function isAdmin() {
    var role = getTokenInLocalStorage()?.rolePrivileges[0]?.roleName
    return role === 'ADMIN'
}

export function hasAccessByLabel(privLabel) {
    var ID = null;
    privilages.forEach(ele => {
        if (ele.label === privLabel) {
            ID = ele.id
        }
    })
    return hasAccess(ID)
}

const privilages = [
    {
        "id": 1,
        "privilage_name": "Expense Management",
        "label": "Expense Management"
    },
    {
        "id": 2,
        "privilage_name": "Leave Management",
        "label": "Leave Management"
    },
    {
        "id": 3,
        "privilage_name": "Sales Management",
        "label": "Sales"
    },
    {
        "id": 4,
        "privilage_name": "Collection Management",
        "label": "Collections"
    },
    {
        "id": 5,
        "privilage_name": "FeildInvestigation Management",
        "label": "Field Investigation"
    },
    {
        "id": 6,
        "privilage_name": "Distance",
        "label": "Distance"
    },
    {
        "id": 7,
        "privilage_name": "Reports",
        "label": "Reports"
    },
    {
        "id": 8,
        "privilage_name": "Live Tracking",
        "label": "Live Tracking"
    },
    {
        "id": 9,
        "privilage_name": "PayRoll",
        "label": "Payroll"
    },
    {
        "id": 10,
        "privilage_name": "Executives",
        "label": "Executives"
    },
    {
        "id": 11,
        "privilage_name": "Managers",
        "label": "Managers"
    },
    {
        "id": 12,
        "privilage_name": "Hoildays Update",
        "label": "Holidays"
    },
    {
        "id": 13,
        "privilage_name": "Attendence Management",
        "label": "Attendance"
    },
    {
        "id": 14,
        "privilage_name": "Transfer User",
        "label": "Transfer User"
    },
    {
        "id": 15,
        // eslint-disable-next-line no-useless-escape
        "privilage_name": "Add\/Delete User",
        "label": "A_D_User"
    },
    {
        "id": 16,
        // eslint-disable-next-line no-useless-escape
        "privilage_name": "Enable\/Disable Tracking",
        "label": "ENB_DSB_Tracking"
    }, {
        "id": 17,
        "privilage_name": "Schedule Reports",
        "label": "Schedule Reports"
    }, {
        "id": 18,
        "privilage_name": "Customer Management",
        "label": "Customer Management"
    }, {
        "id": 19,
        "privilage_name": "Accept Expenses",
        "label": "Accept Expenses"
    }, {
        "id": 20,
        "privilage_name": "Accept Leaves",
        "label": "Accept Leaves"
    }, {
        "id": 21,
        "privilage_name": "Download Report",
        "label": "Download Report"
    },{
        "id": 22,
        "privilage_name": "Update Executive to Manager",
        "label": "Update Manager"
    },{
        "id": 23,
        "privilage_name": "Update Manager to Manager",
        "label": "Update Manager"
    },
   {
        "id": 24,
        "privilage_name": "Add/Edit Manager",
        "label": "Add/Edit Manager"
    },{
        "id": 25,
        "privilage_name": "Delete Manager",
        "label": "Delete Manager"
    },{
        "id": 26,
        "privilage_name": "Add/Edit Executive",
        "label": "Add/Edit Executive"
    }, {
        "id": 27,
        "privilage_name": "Delete Executive",
        "label": "Delete Executive"
    }

]

