import React, { useEffect, useState } from "react";
import { Nav, Tab, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
// import * as moduleApi from "../rest/moduleApi";
// import PaginationComponent from "react-reactstrap-pagination";
// import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
// import { getTokenInLocalStorage } from "../services/AuthService";
// import { apiCall } from "../rest/restApi";
// import Tooltip from "react-bootstrap/Tooltip";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Flatpickr from "react-flatpickr";
// import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
// import "flatpickr/dist/plugins/monthSelect/style.css";
// import { Form, Formik } from "formik";
// import * as Yup from "yup";
// import Select from "react-select";
// import { Link } from "react-router-dom";
// import TextField from "../CommonComponents/TextField";
// import ReactGA from "react-ga";
import { hasAccess } from "../services/AuthService";
import swal from "sweetalert";
// import * as XLSX from "xlsx";
// import * as helper from "../CommonComponents/Helper";
// import "@inovua/reactdatagrid-community/index.css";
// import { EpochToDate } from "../CommonComponents/Helper";
// import {
//   GridComponent,
//   ColumnsDirective,
//   ColumnDirective,
//   Page,
//   Sort,
//   Filter,
//   Inject,
// } from "@syncfusion/ej2-react-grids";
// import { getValue } from "@syncfusion/ej2-base";
// import CallSummary from "../CustomerManagement/CallSummary";
// import LoanNumberDetails from "../RequestPayments/LoanNumberDetails";
// import AddRemarks from "../RequestPayments/AddRemarks";
// import { useCalling } from "../CommonComponents/CallingContext";
// import PtpNotificationsList from "../PtpNotificationsList/PtpNotificationsList";
// import CallBackList from "../CallBackList/CallBackList";
// import PIOPIY from "piopiyjs";
import "./../MyAllocations/MyAllocationStyle.css";
import SalesCallBackList from "./SalesCallBackList";
import NewMySalesAllocations from "./NewMySalesAllocations";
import { StartCall } from "../CommonComponents/TelecallingMethods";
import {
  setCallAudioModal,
  setIsModalMinimised,
} from "../store/actions/CallingActions";
import { useDispatch, useSelector } from "react-redux";
import { getTokenInLocalStorage } from "../services/AuthService";

// const gridStyle = { minHeight: 500 };
// let gridInstance;
// const filterSettings = { type: "Menu" };
// const format = { type: "datetime", format: "M/d/y hh:mm a" };
// const filterType = [
//   { text: "Menu", value: "Menu" },
//   { text: "Checkbox", value: "CheckBox" },
//   { text: "Excel", value: "Excel" },
// ];
// const fields = { text: "text", value: "value" };
// const defaultFilterValue = [
//   { name: "customerName", operator: "startsWith", type: "string", value: "" },
//   { name: "contactNumber1", operator: "startsWith", type: "string", value: "" },
//   {
//     name: "latestPaymentLinkStatus",
//     operator: "startsWith",
//     type: "string",
//     value: "",
//   },
//   { name: "branchName", operator: "startsWith", type: "string", value: "" },
//   { name: "loanNumber", operator: "startsWith", type: "string", value: "" },
//   {
//     name: "dueAmount",
//     operator: "gte",
//     type: "number",
//     value: 0,
//   },
// ];
// const emptyText = (
//   <div className="d-flex justify-content-center">
//     <img src={NO_DATA_FOUND} alt="" />
//   </div>
// );
// const defaultSortInfo = { name: "customerName", type: "string", dir: 1 };
function MySalesAllocations() {
  // let gridInstance;
  // const {
  //   // isOnGoingCall,
  //   startCall,
  //   reqPaymentsList,
  //   // setReqPaymentsList,
  //   // openModal,
  //   // campaignName,
  //   // setCampaignName,
  //   // isMyAllocationsCalled,
  //   isProgressiveDialing,
  //   continueDialing,
  //   updateProgressiveDialing,
  // } = useCalling();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  // const [isLoading2, setIsLoading2] = useState(false);
  //   const [basicModalForPaymentLinks, setBasicModalForPaymentLinks] =
  //     useState(false);
  //   const [paymentLinksByAllocationId, setPaymentLinksByAllocationId] = useState(
  //     []
  //   );
  // const [addRemarksBasicModal, setAddRemarksBasicModal] = useState(false);
  //   const [callReportModal, setCallReportModal] = useState(false);
  //   const [mobileNo, setMobileNo] = useState(null);
  //   const [allocationDetails, setAllocationDetails] = useState([]);
  //   const [allocationDetailsModal, setAllocationDetailsModal] = useState(false);
  //   const [basicModalForSendPaymentLink, setBasicModalForSendPaymentLink] =
  //     useState(false);
    // const [remarks, setRemarks] = useState([]);
  //   const [isMinutesSelected, setIsMinutesSelected] = useState(true);
  //   const [isHoursSelected, setIsHoursSelected] = useState(false);
  //   const [isDaysSelected, setIsDaysSelected] = useState(false);
  // const [totalElements, setTotalElements] = useState(1);
  // const [currPage, setCurrPage] = useState(0);
  // const [reqPaymentsList, setReqPaymentsList] = useState([]);
  // const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);
  // const [addRemarksAllocationId, setAddRemarksAllocationId] = useState("");
  //   const [dataForSendCustomerLink, setDataForSendcustomerLink] = useState({});
  //   const [campaigns, setCampaigns] = useState([]);
  // eslint-disable-next-line
  // const [selectedValue, setSelectedValue] = useState(false);
  // eslint-disable-next-line
  // const [selectedStatus, setSelectedStatus] = useState("");
  // const [sortInfo, setSortInfo] = useState([
  //   {
  //     direction: "ASC",
  //     key: "customerName",
  //   },
  // ]);

  // const [object, setObj] = useState([]);

  // const [loggedInUser] = useState(
  //   JSON.parse(localStorage.getItem("userDetails"))
  // );
  // const [searchKey, setSearchKey] = useState("");
  // const [start, setStart] = useState(
  //   new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
  //     1000
  // );
  // const [end, setEnd] = useState(
  //   new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
  //     1000 +
  //     86399
  // );
  const [activeKey, setActiveKey] = useState("myAllocations");
  // const [editSalesAllocationModal, setEditSalesAllocationModal] = useState(false);
  // const [allocationDetails, setAllocationDetails] = useState(null);

  // var pinCodePattern=/^\d{6}$/;

  // var cityPattern=/^.{0,50}$/;

  // const editAllocationDetailsValidate = Yup.object({

  //   pinCode: Yup.string().required("Pin Code is required").matches(pinCodePattern,"Please Enter Valid Pin Code"),
  //   city: Yup.string().required("City is required").matches(cityPattern,"Please Enter Valid City Name"),
  
  // });

  let userLoginDetails = getTokenInLocalStorage();
  const {  piopiy,
    isCallActive,
    isIncomingCall,isOnGoingCall, isSalesProgressiveDialing } = useSelector(
    (state) => state.calls
  );
  const dispatch = useDispatch();
  // function actionBegin(args) {
  //   let listData = [];
  //   var obj = {};
  //   if (args.requestType === "filtering") {
  //     if (args.action === "filter") {
  //       console.log(args);

  //       obj.key = args.currentFilterObject.field;
  //       obj.value = args.currentFilterObject.value;
  //       obj.operator = args.currentFilterObject.operator.toUpperCase();
  //       obj.fieldType =
  //         args.currentFilterObject.field === "callToday" ||
  //         args.currentFilterObject.field === "totalCalls"
  //           ? "NUMBER"
  //           : "STRING";

  //       listData.push(obj);
  //       console.log("obj", listData);

  //       let filterValue = JSON.parse(localStorage.getItem("filterValue"));
  //       if (filterValue.length > 0) {
  //         for (let i = 0; i < filterValue?.length; i++) {
  //           if (filterValue[i].key !== obj.key) {
  //             filterValue.push(obj);
  //             break;
  //           } else {
  //             filterValue[i] = obj;
  //             break;
  //           }
  //         }
  //       } else {
  //         filterValue.push(obj);
  //       }
  //       localStorage.setItem("filterValue", JSON.stringify(filterValue));
  //       setObj(filterValue);
  //       getDailyScheduleDateForFilters(
  //         filterValue,
  //         sortInfo,
  //         start,
  //         end,
  //         0,
  //         selectedValue,
  //         selectedStatus,
  //         campaignName
  //       );
  //     } else if (args.action === "clearFilter") {
  //       console.log(args);
  //       let filterValue = JSON.parse(localStorage.getItem("filterValue"));
  //       filterValue.map((u) =>
  //         u.key !== args.currentFilterObject.properties.field
  //           ? u
  //           : filterValue.splice(filterValue.indexOf(u), 1)
  //       );
  //       localStorage.setItem("filterValue", JSON.stringify(filterValue));
  //       setObj(filterValue);
  //       getDailyScheduleDateForFilters(
  //         filterValue,
  //         sortInfo,
  //         start,
  //         end,
  //         0,
  //         selectedValue,
  //         selectedStatus,
  //         campaignName
  //       );
  //     }
  //   }
  //   if (args.requestType === "sorting") {
  //     console.log("args", args);
  //     let listData1 = [];
  //     let sortObj = {
  //       direction: args.direction === "Ascending" ? "ASC" : "DESC",
  //       key: args.columnName
  //         ? args.columnName
  //         : gridInstance.getColumnByIndex(
  //             +args.target.closest("th").getAttribute("data-colindex")
  //           ).field,
  //     };
  //     listData1.push(sortObj);
  //     console.log("listData1", listData1);
  //     console.log(
  //       gridInstance.getColumnByIndex(
  //         +args.target.closest("th").getAttribute("data-colindex")
  //       ).field
  //     );
  //     setSortInfo(listData1);
  //     getDailyScheduleDateForFilters(
  //       object,
  //       listData1,
  //       start,
  //       end,
  //       0,
  //       selectedValue,
  //       selectedStatus,
  //       campaignName
  //     );
  //   }
  // }

  // //   const getAllCampaigns = () => {
  // //     moduleApi
  // //       .getData(
  // //         apiCall.getAllCampaigns +
  // //           userLoginDetails.autoFinanceUser.organizationId
  // //       )
  // //       .then((response) => {
  // //         console.log("response", response);
  // //         const result = response.map((item, index) => ({
  // //           value: item,
  // //           label: item,
  // //           index: index,
  // //         }));
  // //         result.unshift({ label: "All", value: "" });
  // //         setCampaigns(result);
  // //       });
  // //   };

  // const allocationStatusTemplate = (props) => {
  //   return (
  //     <div>
  //       {" "}
  //       {props.allocationStatus === "OPEN" ? (
  //         <span className="badge badge-rounded badge-primary">Open</span>
  //       ) : props.allocationStatus === "INPROGRESS" ? (
  //         <span
  //           className="badge badge-rounded badge-warning"
  //           style={{ color: "black" }}
  //         >
  //           In Progress
  //         </span>
  //       ) : props.allocationStatus === "COMPLETED" ? (
  //         <span className="badge badge-rounded badge-success">Completed</span>
  //       ) : props.allocationStatus === "-" || props.allocationStatus === ""|| props.allocationStatus === null ? (
  //         <span>-</span>
  //       ) : (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.allocationStatus}
  //         </span>
  //       )}
  //     </div>
  //   );
  // };

  // const callDispositionStatusTemplate = (props) => {
  //   return (
  //     <div>
  //       {" "}
  //       {props.callDisposition === "Not Interested" ? (
  //         <span className="badge badge-rounded badge-danger">
  //           Not Interested
  //         </span>
  //       ) : props.callDisposition === "Interested" ? (
  //         <span
  //           className="badge badge-rounded badge-success"
  //         >
  //           Interested
  //         </span>
  //       ) : props.callDisposition === "-" || props.callDisposition === ""|| props.callDisposition === null ? (
  //         <span>-</span>
  //       ) : (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.callDisposition}
  //         </span>
  //       )}
  //     </div>
  //   );
  // };

  //   const subStatusTemplate = (props) => {
  //     return (
  //       <div>
  //         {" "}
  //         {props.subStatus === "Cash" ||
  //         props.subStatus === "Bank Transfer" ||
  //         props.subStatus === "Already Settled" ||
  //         props.subStatus === "UPI" ? (
  //           <span className="badge badge-rounded badge-success">
  //             {props.subStatus}
  //           </span>
  //         ) : props.subStatus === "Paid" ? (
  //           <span className="badge badge-rounded badge-success">
  //             {props.subStatus}
  //           </span>
  //         ) : props.subStatus === "Customer Not Available" ? (
  //           <span className="badge badge-rounded badge-danger">
  //             {props.subStatus}
  //           </span>
  //         ) : props.subStatus === "" || props.subStatus === null ? (
  //           <span>-</span>
  //         ) : (
  //           <span className="badge badge-rounded badge-primary">
  //             {props.subStatus}
  //           </span>
  //         )}
  //       </div>
  //     );
  //   };

  // const addRemarksTemplate = (props) => {
  //   return (
  //     <div>
  //       <Link
  //         to="#"
  //         className="btn btn-primary shadow btn-xs sharp"
  //         onClick={() => {
  //           addRemarksIconModal(props?.id);
  //         }}
  //       >
  //         <i className="fa fa-plus"></i>
  //       </Link>
  //     </div>
  //   );
  // };

  // const callOptionTemplate = (props) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "row",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <span>{props.phoneNumber}</span>
  //       {hasAccess(42) &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" && (
  //           //   isProgressiveDialing === false &&
  //           <Link
  //             style={{ marginLeft: "10px" }}
  //             to="#"
  //             onClick={() => {
  //               if (isOnGoingCall === false) {
  //                 startCall(
  //                   props?.phoneNumber,
  //                   false,
  //                   props?.id,
  //                   props,
  //                   "Sales"
  //                 );
  //               } else {
  //                 openModal();
  //               }
  //             }}
  //           >
  //             <div className="media-body user-meta-info">
  //               <ul>
  //                 <li>
  //                   <Link
  //                     to={"#"}
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       height: "30px",
  //                       width: "30px",
  //                     }}
  //                   >
  //                     <i
  //                       className="fas fa-phone-alt"
  //                       style={{ fontSize: "smaller" }}
  //                     ></i>
  //                   </Link>
  //                 </li>
  //               </ul>
  //             </div>
  //           </Link>
  //         )}
  //     </div>
  //   );
  // };

  // const assignOptionTemplate = (props) => {
  //   return (
  //     <OverlayTrigger
  //       placement="bottom"
  //       delay={{ show: 250, hide: 400 }}
  //       overlay={<Tooltip>Assign Allocation</Tooltip>}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "center",
  //         }}
  //       >
  //         <span>{props.telecallerName}</span>
  //         {hasAccess(42) &&
  //           userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
  //           userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
  //           userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
  //           userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" && 
  //           selectedValue===true &&(
  //             //   isProgressiveDialing === false &&
  //             <Link
  //               style={{ marginLeft: "10px" }}
  //               to="#"
  //               onClick={() => {
  //                 assignAllocationToTelecaller(props?.id);
  //               }}
  //             >
  //               <div className="media-body user-meta-info">
  //                 <ul>
  //                   <li>
  //                     <Link
  //                       to={"#"}
  //                       style={{
  //                         display: "flex",
  //                         justifyContent: "center",
  //                         alignItems: "center",
  //                         height: "30px",
  //                         width: "30px",
  //                       }}
  //                     >
  //                       <i
  //                         className="fa fa-tasks"
  //                         style={{ fontSize: "smaller" }}
  //                       ></i>
  //                     </Link>
  //                   </li>
  //                 </ul>
  //               </div>
  //             </Link>
  //           )}
  //       </div>
  //     </OverlayTrigger>
  //   );
  // };

  // const assignAllocationToTelecaller = (id) => {
  //   var payLoad = {
  //     allocationIds: [id],
  //     organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
  //     userId: userLoginDetails?.autoFinanceUser?.userId,
  //   };

  //   moduleApi
  //     .postData(apiCall.assignAllocationToTelecaller, payLoad)
  //     .then((response) => {
  //       console.log("assignResponse", response);
  //       if (response) {
  //         if (response?.message) {
  //           swal(response?.message, {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         } else if (response?.Message === "Allocation assigned Sucessfully") {
  //           getDailyScheduleDateForFilters(
  //             object,
  //             sortInfo,
  //             start,
  //             end,
  //             currPage,
  //             selectedValue,
  //             selectedStatus,
  //             campaignName
  //           );
  //           return swal("Allocation Assigned Successfully", {
  //             icon: "success",
  //           });
  //         }
  //       } else {
  //         return swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  // };

  // const optionDates = [
  //   { label: "All Allocations", value: true },
  //   { label: "My Allocations", value: false },
  // ];
  //   const callHistoryTemplate = (props) => {
  //     return (
  //       <div
  //         className="d-flex"
  //         style={{ justifyContent: "space-around" }}
  //         onClick={() => {
  //           var temp = {
  //             mobileNo: props.contactNumber1,
  //             loanNumber: props.loanNumber,
  //           };
  //           setMobileNo(temp);
  //           setCallReportModal(true);
  //         }}
  //       >
  //         <Link to="#" className="btn btn-info shadow btn-xs sharp">
  //           <i className="fa fa-eye"></i>
  //         </Link>
  //       </div>
  //     );
  //   };

  // useEffect(() => {
  //   paymentsDownloadList !== null && handleExport();
  //   setIsLoading(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentsDownloadList]);

  // useEffect(() => {
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     currPage,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isMyAllocationsCalled]);

  // useEffect(() => {
  //   ReactGA.pageview("My Sales Allocations");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    let path = window.location.pathname;

    path = path.split("/");

    var tempActiveKey = path[path.length - 2];

    tempActiveKey !== "ptp"
      ? setActiveKey("myAllocations")
      : setActiveKey("ptp");

    // localStorage.setItem("filterValue", JSON.stringify([]));
    // console.log("sortInfo", sortInfo);
    // getSourceChannels();
    // getAllCampaigns();
    // getDailyScheduleDateForFilters(
    //   object,
    //   sortInfo,
    //   start,
    //   end,
    //   0,
    //   selectedValue,
    //   selectedStatus,
    //   campaignName
    // );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const sendCustomerPaymentLink = (values) => {
  //     // console.log(values.minutes, values.hours, values.days);
  //     var minsPayload = {
  //       allocationId: dataForSendCustomerLink?.id,
  //       amount: values.dueAmount * 100,
  //       expiryTimeInSeconds: values.minutes * 60,
  //       organizationId: loggedInUser.autoFinanceUser.organizationId,
  //       phoneNumber: values.mobileNumber,
  //       remarks: values.remarks,
  //     };
  //     var hoursPayload = {
  //       allocationId: dataForSendCustomerLink?.id,
  //       amount: values.dueAmount * 100,
  //       expiryTimeInSeconds: values.hours * 60 * 60,
  //       organizationId: loggedInUser.autoFinanceUser.organizationId,
  //       phoneNumber: values.mobileNumber,
  //       remarks: values.remarks,
  //     };
  //     var daysPayload = {
  //       allocationId: dataForSendCustomerLink?.id,
  //       amount: values.dueAmount * 100,
  //       expiryTimeInSeconds: values.days * 24 * 60 * 60,
  //       organizationId: loggedInUser.autoFinanceUser.organizationId,
  //       phoneNumber: values.mobileNumber,
  //       remarks: values.remarks,
  //     };

  //     var customPaymentLinkPayload = {
  //       allocationId: dataForSendCustomerLink?.id,
  //       amount: values.dueAmount * 100,
  //       // expiryTimeInSeconds: values.days * 24 * 60 * 60,
  //       customPayLink: values.paymentLink,
  //       organizationId: loggedInUser.autoFinanceUser.organizationId,
  //       phoneNumber: values.mobileNumber,
  //       remarks: values.remarks,
  //     };
  //     moduleApi
  //       .postData(
  //         apiCall.sendCustomerPaymentLink,
  //         hasAccess(52)
  //           ? isMinutesSelected === true
  //             ? minsPayload
  //             : isDaysSelected === false
  //             ? hoursPayload
  //             : daysPayload
  //           : hasAccess(51) && customPaymentLinkPayload
  //       )
  //       .then((response) => {
  //         // console.log("response", response);
  //         setBasicModalForSendPaymentLink(false);
  //         if (response) {
  //           if (response?.Message === "Payment Link Request Sent Sucessfully") {
  //             return swal("Payment Link Request Sent Successfully", {
  //               icon: "success",
  //             }).then((willDelete) => {
  //               if (willDelete) {
  //                 getDailyScheduleDateForFilters(
  //                   object,
  //                   sortInfo,
  //                   start,
  //                   end,
  //                   0,
  //                   selectedValue,
  //                   selectedStatus,
  //                   campaignName
  //                 );
  //               }
  //             });
  //           } else if (response.message) {
  //             return swal(response.message, {
  //               icon: "error",
  //               dangerMode: true,
  //             });
  //           }
  //         } else {
  //           return swal("Something Went Wrong, Please try again", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //   };
  // const addRemarksIconModal = (value) => {
  //   setAddRemarksBasicModal(true);

  //   console.log("value", value);
  //   setAddRemarksAllocationId(value);
  //   moduleApi
  //     .postData(apiCall.getSalesAllocationsDispositions + value)
  //     .then((response) => {
  //       console.log("response", response);
  //       if (response) {
  //         setRemarks(response);
  //       }
  //     });
  // };

  //   const closeForSendPaymentLink = () => {
  //     setBasicModalForSendPaymentLink(false);
  //   };

  // const handleCloseForRemarks = () => {
  //   setAddRemarksBasicModal(false);
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     0,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  // };

  //   const getSourceChannels = () => {
  //     var payload = {
  //       organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     };
  //     moduleApi.postData(apiCall.getSourceChannels, payload).then((response) => {
  //       console.log("response", response);
  //     });
  //   };

  // const getDailyScheduleDateForFilters = (
  //   filterchange,
  //   sortInfo,
  //   start,
  //   end,
  //   pageNo,
  //   isTelecallerAllocations,
  //   status,
  //   campaign
  // ) => {
  //   let listData1 = [];
  //   console.log(filterchange, sortInfo);
  //   // console.log("sortInfo", sortInfo);
  //   // console.log("filterValue", filterchange);
  //   setIsLoading(true);
  //   const payload = {
  //     startTime: start,
  //     endTime: end,
  //     filters: filterchange,
  //     organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     teleCallerUserId:
  //       isTelecallerAllocations === false
  //         ? loggedInUser.autoFinanceUser.userId
  //         : "",
  //     telecallerAllocations: isTelecallerAllocations,
  //     status: status,
  //     campaignName: campaign,
  //     sourceChannel: "",
  //     page: pageNo,
  //     size: 10,
  //     sorts: sortInfo,
  //   };
  //   moduleApi
  //     .postData(apiCall.getSalesDataBasedOnFilters, payload)

  //     .then((response) => {
  //       console.log("response", response);
  //       setCurrPage(pageNo);
  //       response?.content?.forEach((element) => {
  //         let data = {
  //           callDisposition: element.callDisposition
  //             ? element.callDisposition
  //             : "-",
  //           allocationStatus: element.allocationStatus ? element.allocationStatus : "-",
  //           callDispositionSubstatus: element.callDispositionSubstatus
  //             ? element.callDispositionSubstatus
  //             : "-",
  //           callStatus: element.callStatus ? element.callStatus : "-",
  //           city: element.city ? element.city : "-",
  //           empId: element.empId ? element.empId : "-",
  //           empName: element.empName ? element.empName : "-",
  //           lastCallUpdateTime: element.lastCallUpdateTime
  //             ? EpochToDate(element.lastCallUpdateTime)
  //             : "-",
  //           callsToday: element.callsToday ? element.callsToday : 0,
  //           customerName: element.customerName ? element.customerName : "-",
  //           dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
  //           excelRowNumber: element.excelRowNumber
  //             ? element.excelRowNumber
  //             : "-",
  //           id: element.id ? element.id : "-",
  //           organizationId: element.organizationId
  //             ? element.organizationId
  //             : "-",
  //           phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
  //           pinCode: element.pinCode ? element.pinCode : "-",
  //           sourceChannelId: element.sourceChannelId
  //             ? element.sourceChannelId
  //             : "-",
  //           sourcingChannel: element.sourcingChannel
  //             ? element.sourcingChannel
  //             : "-",
  //           state: element.state ? element.state : "-",
  //           telecallerEmpId: element.telecallerEmpId
  //             ? element.telecallerEmpId
  //             : "-",
  //           telecallerName: element.telecallerName
  //             ? element.telecallerName
  //             : "-",
  //           totalCalls: element.totalCalls ? element.totalCalls : 0,
  //           userId: element.userId ? element.userId : "-",
  //           priority: element.priority,
  //           validationFailed: element.validationFailed
  //             ? element.validationFailed
  //             : "-",
  //         };
  //         listData1.push(data);
  //       });
  //       setReqPaymentsList(listData1);
  //       setTotalElements(response?.totalElements);
  //       setIsLoading(false);
  //     });
  //   // }
  // };
  // const onPageChange = (selectedPage) => {
  //   setCurrPage(selectedPage - 1);
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     selectedPage - 1,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  // };

  // useEffect(() => {
  //   console.log("right");
  //   if (isProgressiveDialing === true) progressiveDialing();
  //   // eslint-disable-next-line
  // }, [isProgressiveDialing]);

  // useEffect(() => {
  //   console.log("left");
  //   if (isProgressiveDialing === true) progressiveDialing();
  //   // eslint-disable-next-line
  // }, [continueDialing]);

  // const progressiveDialing = () => {
  //   console.log("calling started");
  //   if (isProgressiveDialing === true) {
  //     if (reqPaymentsList?.length > 0) {
  //       if (reqPaymentsList[0]?.campaignCallIntiated === false) {
  //         startCall(
  //           reqPaymentsList[0]?.contactNumber1,
  //           false,
  //           reqPaymentsList[0]?.id,
  //           reqPaymentsList[0] ? reqPaymentsList[0] : null,
  //           "Sales"
  //         );
  //       } else {
  //         updateProgressiveDialing(false);
  //         return swal("All The Customers Have Been Called For This Campaign", {
  //           icon: "warning",
  //           dangerMode: false,
  //         });
  //       }
  //     } else {
  //       updateProgressiveDialing(false);
  //       return swal("There Are No Allocations, Please Check", {
  //         icon: "error",
  //         dangerMode: true,
  //       });
  //     }
  //   }
  // };

  // const setCustomDatesForGraph = (value1) => {
  //   var start = value1[0].getTime() / 1000;
  //   var end =
  //     new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
  //       1000 +
  //     86399;

  //   setStart(start);
  //   setEnd(end);
  //   getDailyScheduleDateForFilters(
  //     object,
  //     sortInfo,
  //     start,
  //     end,
  //     0,
  //     selectedValue,
  //     selectedStatus,
  //     campaignName
  //   );
  // };

  //   const minutesPattern = /^(0?[1-9]|[1-5][0-9]|60)$/;
  //   const hoursPattern = /^(1?[1-9]|2[0-4])$/;
  //   const daysPattern = /^(0?[1-9]|[1-2][0-9]|30)$/;

  //   const validate = Yup.object({
  //     minutes: Yup.string().when([], {
  //       is: () => isMinutesSelected && hasAccess(52),
  //       then: Yup.string()
  //         .matches(minutesPattern, "Please enter a number between 1 and 60")
  //         .required("Number of Minutes is required"),
  //       otherwise: Yup.string().notRequired(),
  //     }),
  //     hours: Yup.string().when([], {
  //       is: () => isHoursSelected && hasAccess(52),
  //       then: Yup.string()
  //         .matches(hoursPattern, "Please enter a number between 1 and 24")
  //         .required("Number of Hours is required"),
  //       otherwise: Yup.string().notRequired(),
  //     }),
  //     days: Yup.string().when([], {
  //       is: () => isDaysSelected && hasAccess(52),
  //       then: Yup.string()
  //         .matches(daysPattern, "Please enter a number between 1 and 30")
  //         .required("Number of Days is required"),
  //       otherwise: Yup.string().notRequired(),
  //     }),
  //     mobileNumber: Yup.string().required("Mobile Number is required"),
  //     paymentLink: Yup.string().when([], {
  //       is: () => hasAccess(51),
  //       then: Yup.string().required("Payment Link is required"),
  //       otherwise: Yup.string().notRequired(),
  //     }),
  //     remarks: Yup.string().required("Remarks is required"),
  //     dueAmount: Yup.string().required("Due Amount is required"),
  //   });

           

  //   const handleLoanNumberDetailsModal = () => {
  //     setAllocationDetailsModal(false);
  //     getDailyScheduleDateForFilters(
  //       object,
  //       sortInfo,
  //       start,
  //       end,
  //       0,
  //       selectedValue,
  //       selectedStatus,
  //       campaignName
  //     );
  //   };

  //   const statusOptions = [
  //     { label: "All", value: "" },
  //     { label: "Open", value: "OPEN" },
  //     { label: "In Progress", value: "INPROGRESS" },
  //     { label: "Completed", value: "COMPLETED" },
  //   ];

  // const getHeaderTemplate=(value)=>{
  //   return (
  //     <div style={{backgroundColor: "rgb(243,240,249)",}}>
  //       {value}
  //     </div>
  //   )
  // }

  // let selIndex = [];
  // // let grid;
  // const settings = { type: "Multiple" };
  // const rowDataBound = (args) => {
  //   console.log(args);
  //   if (getValue("campaignCallIntiated", args.data) === true) {
  //     // selIndex.push(parseInt(args.row.getAttribute("aria-rowindex"), 0) - 1);
  //     args.row.classList.add("below-30");
  //   }
  // };
  // const dataBound = () => {
  //   if (gridInstance && selIndex.length) {
  //     gridInstance.selectRows(selIndex);
  //     selIndex = [];
  //   }
  // };

  return (
    <LoadingOverlay
      active={isLoading}
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div>
        <div className="card" style={{ padding: "10px" }}>
          <div className="custom-tab-1">
            <Tab.Container
              defaultActiveKey="myAllocations"
              activeKey={activeKey}
            >
              <Nav as="ul" className="nav-tabs-custom nav-justified">
                <Nav.Item as="li" key="0">
                  <Nav.Link
                    eventKey="myAllocations"
                    onClick={() => {
                      // getDailyScheduleDateForFilters(
                      //   object,
                      //   sortInfo,
                      //   start,
                      //   end,
                      //   0,
                      //   selectedValue,
                      //   selectedStatus,
                      //   campaignName
                      // );
                      setActiveKey("myAllocations");
                    }}
                  >
                    <b> My Allocations </b>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item as="li" key="1">
                  <Nav.Link
                    eventKey="ptp"
                    onClick={() => {
                      setActiveKey("ptp");
                    }}
                  >
                    <b> PTP </b>
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item as="li" key="1">
                  <Nav.Link
                    eventKey="callback"
                    onClick={() => {
                      setActiveKey("callback");
                    }}
                  >
                    <b> Call Backs </b>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane
                  eventKey="myAllocations"
                  key="0"
                  mountOnEnter
                  unmountOnExit
                >
                  <NewMySalesAllocations/>
                  {/* <div style={{ marginTop: "-5px" }}>
                    <LoadingOverlay
                      active={isLoading}
                      text={
                        <p
                          style={{
                            color: "black",
                          }}
                        >
                          <div>
                            <Button
                              style={{
                                backgroundColor: "#FFFF",
                                color: "black",
                                borderColor: "white",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                              variant="primary"
                              disabled
                            >
                              <Spinner
                                as="span"
                                animation="border"
                                size="md"
                                variant="primary"
                                role="status"
                                aria-hidden="true"
                              />
                              &nbsp;&nbsp;Loading...
                            </Button>
                          </div>
                        </p>
                      }
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(192,192,192,0.4)",
                        }),
                        spinner: (base) => ({
                          ...base,
                          width: "80px",
                          "& svg circle": {
                            stroke: "rgb(136, 108, 192)",
                          },
                        }),
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          float: "right",
                        }}
                      >
                       
                        <div
                          style={{
                            float: "right",
                            width: "150px",
                            margin: "10px",
                            marginLeft: "5px",
                            marginRight: "5px",
                            height: "38px",
                            borderRadius: "3px",
                            pointerEvents:
                              isProgressiveDialing === true && "none",
                            borderWidth: "1px",
                            boxShadow: "1px 1px 2px #797979",
                          }}
                        >
                          <Select
                            isSearchable={false}
                            onChange={(value1) => {
                              getDailyScheduleDateForFilters(
                                object,
                                sortInfo,
                                start,
                                end,
                                0,
                                value1.value,
                                selectedStatus,
                                campaignName
                              );
                              setSelectedValue(value1.value);
                            }}
                            defaultValue={optionDates[1]}
                            options={optionDates}
                          />
                        </div>

                       

                        <div
                          style={{
                            float: "right",
                            margin: "10px",
                            marginLeft: "5px",
                            marginRight: "5px",
                           
                          }}
                        >
                          <Flatpickr
                            className="form-control d-block"
                            style={{
                              width: "170px",
                              height: "40px",
                              borderColor: "#a0a0a0",
                              borderWidth: "1px",
                              boxShadow: "1px 1px 2px #3a353b",
                              textAlignLast: "center",
                            }}
                            placeholder="Select Month"
                            onChange={(value) => {
                              setCustomDatesForGraph(value);
                            }}
                            options={{
                              defaultDate: new Date(),
                              maxDate: new Date(
                                new Date().getFullYear(),
                                new Date().getMonth() + 2,
                                0
                              ),
                              minDate: new Date().setMonth(
                                new Date().getMonth() - 6
                              ),

                              plugins: [
                                new monthSelectPlugin({
                                  shorthand: true, //defaults to false
                                  dateFormat: "F Y", //defaults to "F Y"
                                  altFormat: "F Y", //defaults to "F Y"
                                  theme: "light", // defaults to "light"
                                }),
                              ],
                            }}
                          />
                        </div>
                        <div
                          style={{
                            pointerEvents:
                              isProgressiveDialing === true && "none",
                          }}
                        >
                          <Button
                            style={{
                              float: "right",
                              margin: "10px",
                              marginRight: "15px",
                              fontFamily:
                                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                            }}
                            className="me-2"
                            variant="success btn-rounded"
                            onClick={() => paymentsDownloadData()}
                          >
                            <i className="fa fa-download"></i>
                            &nbsp;&nbsp;Download
                          </Button>
                        </div>
                      </div>

                      <div style={{ overflow: "auto", width: "100%" }}>
                        <GridComponent
                          dataSource={reqPaymentsList}
                          allowSorting={true}
                          allowPaging={false}
                          allowTextWrap={true}
                          rowDataBound={rowDataBound}
                          // eslint-disable-next-line
                          ref={(grid) => (gridInstance = grid)}
                          actionBegin={actionBegin}
                          pageSettings={{ pageSize: 10, pageCount: 5 }}
                          allowFiltering={true}
                          filterSettings={filterSettings}
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              field="sourcingChannel"
                              headerText="Sourcing Channel"
                              width="180"
                              textAlign="Center"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="priority"
                              headerText="Priority"
                              width="120"
                              textAlign="Center"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="customerName"
                              headerText="Prospect Name"
                              width="180"
                              textAlign="Center"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="phoneNumber"
                              headerText="Prospect Number"
                              width="200"
                              template={callOptionTemplate}
                              textAlign="Center"
                            ></ColumnDirective>

                            <ColumnDirective
                              field="callsToday"
                              headerText="Today's Calls"
                              width="110"
                              textAlign="Center"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="totalCalls"
                              headerText="Total Calls"
                              width="100"
                              textAlign="Center"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="lastCallUpdateTime"
                              headerText="Last Called Date & Time"
                              width="120"
                              textAlign="Center"
                              allowFiltering={false}
                            ></ColumnDirective>
                            <ColumnDirective
                              field="addRemarks"
                              headerText="Remarks"
                              width="100"
                              template={addRemarksTemplate}
                              allowFiltering={false}
                              allowSorting={false}
                              textAlign="Center"
                            ></ColumnDirective>

                            <ColumnDirective
                              field="telecallerEmpId"
                              headerText="TeleCaller Emp Id"
                              width="120"
                              textAlign="Center"
                              allowSorting={false}
                              // allowFiltering={false}
                            ></ColumnDirective>
                            <ColumnDirective
                              field="telecallerName"
                              headerText="TeleCaller Emp Name"
                              width="150"
                              textAlign="Center"
                              template={assignOptionTemplate}
                              allowSorting={false}
                              // allowFiltering={false}
                            ></ColumnDirective>

                            <ColumnDirective
                              field="city"
                              headerText="City"
                              width="150"
                              textAlign="Center"
                            />

                            <ColumnDirective
                              field="state"
                              headerText="State"
                              width="150"
                              //   template={subStatusTemplate}
                              textAlign="Center"
                              type="string"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="pinCode"
                              headerText="Pin Code"
                              width="120"
                              textAlign="Center"
                            />
                            <ColumnDirective
                              field="callStatus"
                              headerText="Call Status"
                              width="180"
                              //   template={allocationStatusTemplate}
                              textAlign="Center"
                              type="string"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="callDisposition"
                              headerText="Call Disposition Status"
                              width="180"
                              template={callDispositionStatusTemplate}
                              textAlign="Center"
                              type="string"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="callDispositionSubstatus"
                              headerText="Call Disposition Sub Status"
                              width="180"
                              //   template={allocationStatusTemplate}
                              textAlign="Center"
                              type="string"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="allocationStatus"
                              headerText="Allocation Status"
                              width="180"
                              allowFiltering={false}
                              allowSorting={false}
                              template={allocationStatusTemplate}
                              textAlign="Center"
                              type="string"
                            ></ColumnDirective>
                            <ColumnDirective
                              field="dateTime"
                              headerText="Assigned Date & Time"
                              width="120"
                              allowFiltering={false}
                              allowSorting={false}
                             
                              textAlign="Center"
                              type="string"
                            ></ColumnDirective>
                          </ColumnsDirective>
                          <Inject services={[Filter, Page, Sort]} />
                        </GridComponent>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin:
                              totalElements === 0 ? "90px 0px 5px 0px" : "20px",
                          }}
                        >
                          <PaginationComponent
                            totalItems={totalElements}
                            pageSize={10}
                            defaultActivePage={currPage + 1}
                            onSelect={onPageChange}
                          />
                        </div>
                      </div>
                     
                    </LoadingOverlay>
                  </div> */}
                </Tab.Pane>

                <Tab.Pane eventKey="callback" key="1" mountOnEnter unmountOnExit>
                    <SalesCallBackList/>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>

          
          {/* <Modal className="fade" show={addRemarksBasicModal} size="lg">
            <Modal.Header>
              <Modal.Title>Add Remarks</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setAddRemarksBasicModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <AddRemarks
                  allocationId={addRemarksAllocationId}
                  callId={null}
                  campaignName={campaignName}
                  close={handleCloseForRemarks}
                  type={"Sales"}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                    width: "100%",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>Previous Remarks</label>
                  {remarks?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead
                          style={{
                            backgroundColor: "rgb(243,240,249)",
                            top: "1px",
                            position: "sticky",
                          }}
                        >
                          <tr style={{ textAlign: "center" }}>
                            <th scope="col">Remarks</th>
                            <th scope="col">Remarks Added By</th>
                            <th scope="col">Call Status</th>
                            <th scope="col">Status</th>
                            <th scope="col">Sub Status</th>
                            <th scope="col">Call Back Date</th>
                            <th scope="col">Call Back Slot</th>
                            <th scope="col">Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {remarks?.map((item) => (
                            <tr
                              style={{
                                textAlign: "center",
                                fontWeight: "normal",
                              }}
                              key={item.id}
                            >
                              <td>{item.remarks}</td>
                              <td>
                                {item.remarkAddedBy ? item.remarkAddedBy : "-"}
                              </td>
                              <td>
                                {item.remarksType ? item.remarksType : "-"}
                              </td>
                              <td>{item.status ? item.status : "-"}</td>
                              <td>{item.subStatus ? item.subStatus : "-"}</td>
                              <td>
                                {item.callBackDate
                                  ? helper.EpochToOnlyDate(item.callBackDate)
                                  : "-"}
                              </td>
                              <td>
                                {item.callBackSlot ? item.callBackSlot : "-"}
                              </td>
                              {item.createdTime ? (
                                <td>{EpochToDate(item.createdTime)}</td>
                              ) : (
                                <td>-</td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    
                    </div>
                  ) : (
                    <span style={{ textAlignLast: "center" }}>
                      No Previous Remarks Found
                    </span>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal> */}

          {/* <Modal className="fade" show={callReportModal} size="lg">
            <Modal.Header>
              <Modal.Title>Call History</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setCallReportModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <CallSummary
                mobileNo={mobileNo?.mobileNo}
                loanNumber={mobileNo?.loanNumber}
              />
            </Modal.Body>
          </Modal> */}

{/* <Modal className="fade" show={editSalesAllocationModal}>
        <Modal.Header>
          <Modal.Title>Edit Allocation Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setEditSalesAllocationModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                pinCode:allocationDetails?.pinCode,
                city:allocationDetails?.city
              }}
              onSubmit={editAllocationDetails}
              validationSchema={editAllocationDetailsValidate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <TextField
                          isAsterisk={true}
                          label="Pin Code"
                          name="pinCode"
                          type="text"
                        />
                        <TextField
                          isAsterisk={true}
                          label="City"
                          name="city"
                          type="text"
                        />
                        <div style={{ marginTop: "10px" }}>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setEditSalesAllocationModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal> */}

          {/* <Modal className="fade" size="lg" show={allocationDetailsModal}>
            <Modal.Header>
              <Modal.Title>Allocation Details</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => {
                  setAllocationDetailsModal(false);
                }}
              ></Button>
            </Modal.Header>
            <Modal.Body
              style={{
                paddingTop: "0px",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            ></Modal.Body>
            <LoanNumberDetails
              allocationDetails={allocationDetails}
              close={handleLoanNumberDetailsModal}
            />
          </Modal> */}
        </div>
        <div>
          <Button
            style={{
              float: "right",
              margin: "10px",
              marginRight: "15px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="primary light"
            onClick={() => {
              setIsLoading(true);
              var imageAddr =
                "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/sample3.jpg";
              var downloadSize = 7300000; //bytes
              var startTime, endTime;
              var download = new Image();
              download.onload = function () {
                endTime = new Date().getTime();
                showResults();
              };

              startTime = new Date().getTime();
              var cacheBuster = "?nnn=" + startTime;
              download.src = imageAddr + cacheBuster;

              function showResults() {
                var duration = (endTime - startTime) / 1000;
                var bitsLoaded = downloadSize * 8;
                var speedBps = (bitsLoaded / duration).toFixed(2);
                var speedKbps = (speedBps / 1024).toFixed(2);
                var speedMbps = (speedKbps / 1024).toFixed(2);
                setIsLoading(false);
                return swal(
                  "Minimum Expected Internet Speed For Calling is 9 Mbps.Your Internet Connection Speed is " +
                    speedMbps +
                    " Mbps",
                  {
                    icon: "info",
                    dangerMode: false,
                  }
                );
              }
            }}
          >
            <i class="fas fa-tachometer-alt"></i>&nbsp;&nbsp;Check Network Speed
          </Button>
          {hasAccess(42) &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
            isSalesProgressiveDialing === false && (
              <Button
                style={{
                  float: "right",
                  margin: "10px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success light"
                onClick={() => {
                  if (isOnGoingCall === false && isIncomingCall===false) {
                    // var piopiy = new PIOPIY({
                    //   name: "CLU",
                    //   debug: false,
                    //   autoplay: true,
                    //   ringTime: 60,
                    // });
                    StartCall(
                      piopiy,
                      dispatch,
                      userLoginDetails?.autoFinanceUser?.mobileNumber,
                      false,
                      null,
                      null,
                      isCallActive
                    );
                    // piopiy.call("91" + row?.contactNumber);
                    // setCallMobileNo(props?.contactNumber1);
                  } else {
                    dispatch(setCallAudioModal(true));
                    dispatch(setIsModalMinimised(false));
                  }
                }}
              >
                <i className="fa fa-phone fa-1x fa-rotate-90" />
                &nbsp;&nbsp;Call Me
              </Button>
            )}
        </div>
      </div>
     </LoadingOverlay>
  );
}

export default MySalesAllocations;
