import { getTokenInLocalStorage } from "../services/AuthService";

const userData= getTokenInLocalStorage();
let orgshortcode= userData?.autoFinanceOrganization.orgshortcode;

export const CannedReportsConfig = {
    'employeeperformance':'analytics/'+orgshortcode+'/employeeperformace.csv',
    'employeeperformanceamount':'analytics/'+orgshortcode+'/employeeperformaceamount.csv',
    'nocheckins':'analytics/'+orgshortcode+'/numberofcheckins.csv',
    'uniquecustomervisits':'analytics/'+orgshortcode+'/uniuqecustomervisits.csv',
    'customernotavailable':'analytics/'+orgshortcode+'/customernotavailable.csv',

}