import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import UploadPayrollExcel from "./UploadPayrollExcel";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import { apiCall } from "../rest/restApi";
// import LoadImage from "../CommonComponents/LoadImage";
// import Flatpickr from "react-flatpickr";
// import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
// import * as moduleApi from "../rest/moduleApi";
import * as moduleApi2 from "../rest/moduleApi2";

import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import swal from "sweetalert";
import InactiveUsers from "./InactiveUsers";
import ReactGA from "react-ga";
import UploadedFiles from "./UploadedFiles";
import { getTokenInLocalStorage } from "../services/AuthService";
import Payroll from "../CommonComponents/Payroll";

const PayrollForExecutives = () => {
  const [basicModal, setBasicModal] = useState(false);
  // eslint-disable-next-line
  const [isLoading2, setIsLoading2] = useState(false);
  const [activeKey, setActiveKey] = useState("activeUsers");
  // eslint-disable-next-line
  const [activeUsersList, setActiveUsersList] = useState([]);
  // eslint-disable-next-line
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  useEffect(() => {
    ReactGA.pageview("Payroll For Executives");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // loadData(0, searchKey);
    getActiveUsersList(start, end, 0);
    // loadExecutives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let userLoginDetails = getTokenInLocalStorage();

  const handleClose = () => {
    setBasicModal(false);
  };

  const getActiveUsersList = (start, end, pageNo) => {
    let listData = [];
    setIsLoading2(true);

    // let url = apiCall.payrollUploadBaseUrl + "?action=getPayRollData";
    // let payload = {
    //   endDate: end,
    //   organizationId: userLoginDetails.autoFinanceUser.organizationId,
    //   size: 10,
    //   page: pageNo,
    //   startDate: start,
    // };

    // moduleApi.postDataByOrgIdForPayroll(url, payload).then((response) => {
    let payload = {
      // url: apiCall.bulkUploadBaseUrl,
      // action: "preSignedUrlStatusOrData",
      // userId: userLoginDetails.autoFinanceUser.userId,
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      // presignedUrlForFileDownload: true,
      // limit: 10,
      // pageSize: pageNo,
      startDate: start,
    };
    moduleApi2
      .postData(
        moduleApi2.withPage(apiCall.getPayrollData, pageNo, 10),
        payload
      )
      .then((response) => {
        console.log("payrollUsersResponse", response);
        //message: "Service Unavailable"
        if (response) {
          if (response?.message) {
            setIsLoading2(false);
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            response?.content?.forEach((element) => {
              let data = {
                basicPay: element.basicPay ? element.basicPay : "-",
                designation: element.designation ? element.designation : "-",
                empId: element.empId ? element.empId : "-",
                id: element.id ? element.id : "-",
                empName: element.empName ? element.empName : "-",
                hra: element.hra ? element.hra : "-",
                monthWithYear: element.monthWithYear
                  ? element.monthWithYear
                  : "-",
                netPay: element.netPay ? element.netPay : "-",
                organizationId: element.organizationId
                  ? element.organizationId
                  : "-",
                professionalTax: element.professionalTax
                  ? element.professionalTax
                  : "-",
                providentFund: element.providentFund
                  ? element.providentFund
                  : "-",
                specialAllowance: element.specialAllowance
                  ? element.specialAllowance
                  : "-",
                totalDeductions: element.totalDeductions
                  ? element.totalDeductions
                  : "-",
                totalEarnings: element.totalEarnings
                  ? element.totalEarnings
                  : "-",
                userId: element.userId ? element.userId : "-",
              };

              listData.push(data);
            });
            setActiveUsersList(listData);
            setTotalElements(response?.totalElements);
            setIsLoading2(false);
          }
        } else {
          setIsLoading2(false);
          return swal("Something Went Wrong, Please Try again", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const columns = [
    {
      text: "Emp Name",
      dataField: "empName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      // formatter: (col, row) => {
      //   return (
      //     <div
      //       className="d-flex align-items-center"
      //       style={{ marginLeft: "15px" }}
      //     >
      //       {/* <LoadImage source={row.image} />{" "} */}
      //       <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
      //     </div>
      //   );
      // },
    },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Basic Pay",
      dataField: "basicPay",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "HRA",
      dataField: "hra",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Special</span>
          <br></br>
          <span>Allowance</span>
        </div>
      ),
      dataField: "specialAllowance",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total</span>
          <br></br>
          <span>Earnings</span>
        </div>
      ),
      dataField: "totalEarnings",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Provident</span>
          <br></br>
          <span>Fund</span>
        </div>
      ),
      dataField: "providentFund",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Professional</span>
          <br></br>
          <span>Tax</span>
        </div>
      ),
      dataField: "professionalTax",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Total</span>
          <br></br>
          <span>Deductions</span>
        </div>
      ),
      dataField: "totalDeductions",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Net Pay",
      dataField: "netPay",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.status === "ACTIVE4") {
    //       return <></>;
    //     } else {
    //       return (
    //         <div className="d-flex">
    //           {/* <Link to="#" className="btn btn-info shadow btn-xs sharp">
    //                 <i className="fa fa-eye"></i>
    //               </Link> */}
    //         </div>
    //       );
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       //   setSelectedExeName(row);
    //       //   getCustomersList(0, row.userId, "");
    //       //   setSelectedUserId(row.userId);
    //       //   setBasicModal2(true);
    //     },
    //   },
    // },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-download"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          swal({
            title: "Download",
            text: "Do you really want to download the payroll?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              downlaodFile(row?.id, row?.userId);
            }
          });
        },
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    getActiveUsersList(start, end, selectedPage - 1);
  };

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;
    setStart(start);
    setEnd(end);
    getActiveUsersList(start, end, 0);
  };

  const downlaodFile = (id, userId) => {
    setIsLoading2(true);
    // let url = apiCall.payrollUploadBaseUrl + "?action=getPaySlipDownloadUrl";
    let body = {
      id: id,
      userId: userId,
    };

    // moduleApi.postDataByOrgIdForPayroll(url, body).then((response) => {
    moduleApi2
      .postData(apiCall.getPreSignedUrlForDownloadPayslip, body)
      .then((response) => {
        console.log("preSignedUrl", response);
        if (response) {
          if (response?.message) {
            setIsLoading2(false);
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.presignedUrl) {
            // moduleApi
            //   .getDownloadedFile(response?.data?.presignedUrl)
            //   .then((response) => {
            //     //Service Unavailable
            //     console.log("payroll download", response);
            //     setIsLoading2(false);
            //   });
            setIsLoading2(false);
            window.open(response?.presignedUrl);
          }
        } else {
          setIsLoading2(false);
          return swal("Something Went Wrong, Please Try again", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getdata = () => {
    getActiveUsersList(start, end, 0);
    setActiveKey("uploadedFiles");
  };

  return (
    <div className="card">
      {userLoginDetails.autoFinanceUser.roleName === "ADMIN" ? (
        <div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <div
              style={{
                marginTop: "20px",
                marginRight: "20px",
                marginBottom: "20px",
                // marginLeft: "20px",
                float: "right",
              }}
            >
              {/* {userLoginDetails.autoFinanceUser.roleName !== "ADMIN" && ( */}
              <Button
                className=""
                variant="success btn-rounded"
                style={{
                  backgroundColor: "#218a3f",
                  borderColor: "#218a3f",
                  borderRadius: "5px",
                  float: "right",
                }}
                onClick={() => {
                  setBasicModal(true);
                }}
              >
                <i class="fa fa-upload"></i>&nbsp; Upload Payroll
              </Button>
              {/* )} */}
            </div>
          </div>
          <Modal className="fade" size="xl" show={basicModal}>
            <Modal.Header>
              <Modal.Title>Upload Payroll</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => handleClose()}
              ></Button>
            </Modal.Header>
            <Modal.Body
              style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
            >
              <UploadPayrollExcel close={handleClose} data={getdata} />
            </Modal.Body>
          </Modal>

          <div>
            <div style={{ margin: "20px" }}>
              <div className="custom-tab-1">
                <Tab.Container
                  defaultActiveKey="activeUsers"
                  activeKey={activeKey}
                >
                  <Nav as="ul" className="nav-tabs-custom nav-justified">
                    <Nav.Item as="li" key="0">
                      <Nav.Link
                        eventKey="activeUsers"
                        onClick={() => {
                          setActiveKey("activeUsers");
                        }}
                      >
                        <b> Users </b>
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item as="li" key="1">
                  <Nav.Link eventKey="inActiveUsers">
                    <b> InActive Users </b>
                  </Nav.Link>
                </Nav.Item> */}
                    <Nav.Item as="li" key="2">
                      <Nav.Link
                        eventKey="uploadedFiles"
                        onClick={() => {
                          setActiveKey("uploadedFiles");
                        }}
                      >
                        <b> Uploaded Files </b>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="pt-4">
                    <Tab.Pane eventKey="activeUsers" key="0" mountOnEnter>
                      <LoadingOverlay
                        active={isLoading2}
                        // spinner
                        text={
                          <p
                            style={{
                              color: "black",
                            }}
                          >
                            <div>
                              <Button
                                style={{
                                  backgroundColor: "#FFFF",
                                  color: "black",
                                  borderColor: "white",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                }}
                                variant="primary"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="md"
                                  variant="primary"
                                  role="status"
                                  aria-hidden="true"
                                />
                                &nbsp;&nbsp;Loading...
                              </Button>
                            </div>
                          </p>
                        }
                        styles={{
                          overlay: (base) => ({
                            ...base,
                            background: "rgba(192,192,192,0.4)",
                          }),
                          spinner: (base) => ({
                            ...base,
                            width: "80px",
                            "& svg circle": {
                              stroke: "rgb(136, 108, 192)",
                            },
                          }),
                        }}
                      >
                        <div
                          style={{
                            float: "right",
                            // width: "120px",
                            marginRight: "15px",
                            marginBottom: "10px",
                            marginLeft: "15px",
                          }}
                        >
                          <Flatpickr
                            className="form-control d-block"
                            style={{
                              width: "200px",
                              height: "40px",
                              borderColor: "#a0a0a0",
                              borderWidth: "1px",
                              boxShadow: "1px 1px 2px #3a353b",
                              textAlignLast: "center",
                              // paddingLeft: "10px",
                              // marginBottom: "10px",
                              // float: "right",
                            }}
                            placeholder="Select Month"
                            onChange={(value) => {
                              setCustomDatesForGraph(value);
                            }}
                            options={{
                              // mode: "range",
                              // dateFormat: "d-M-y",
                              defaultDate: new Date(),
                              maxDate: new Date(),
                              minDate: new Date().setMonth(
                                new Date().getMonth() - 6
                              ),

                              plugins: [
                                new monthSelectPlugin({
                                  shorthand: true, //defaults to false
                                  dateFormat: "F Y", //defaults to "F Y"
                                  altFormat: "F Y", //defaults to "F Y"
                                  theme: "light", // defaults to "light"
                                }),
                              ],
                            }}
                          />
                        </div>
                        <div
                          style={{
                            background: "white",
                            margin: "10px",
                            marginTop: "-5px",
                            overflowX: "auto",
                            overflowY: "auto",
                            maxHeight: "400px",
                            width: "100%",
                          }}
                        >
                          <BootstrapTable
                            keyField="id"
                            data={activeUsersList}
                            columns={columns}
                          />
                        </div>
                        {activeUsersList.length === 0 && (
                          <div className="d-flex justify-content-center">
                            <img src={NO_DATA_FOUND} alt="" />
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin:
                              totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                          }}
                        >
                          <PaginationComponent
                            totalItems={totalElements}
                            pageSize={10}
                            defaultActivePage={currPage + 1}
                            onSelect={onPageChange}
                          />
                        </div>
                      </LoadingOverlay>
                    </Tab.Pane>
                    <Tab.Pane eventKey="inActiveUsers" key="1" mountOnEnter>
                      <InactiveUsers />
                    </Tab.Pane>
                    <Tab.Pane eventKey="uploadedFiles" key="2" mountOnEnter>
                      <UploadedFiles />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            margin: "15px",
          }}
        >
          <Payroll
            userId={userLoginDetails.autoFinanceUser.userId}
            organizationId={userLoginDetails.autoFinanceUser.organizationId}
          />
        </div>
      )}
    </div>
  );
};

export default PayrollForExecutives;
