import React, { useEffect, useState } from "react";
import { Button,Modal, Col,Row} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import PaginationComponent from "react-reactstrap-pagination";
import { apiCall } from "../rest/restApi";
import "flatpickr/dist/plugins/monthSelect/style.css";
import swal from "sweetalert";
// import ReactGA from "react-ga";
import { getTokenInLocalStorage } from "../services/AuthService";
import * as moduleApi from "../rest/moduleApi";
import TextField from "../CommonComponents/TextField";
import { Field,Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Link } from "react-router-dom";

const MessageTemplate = ({ assetType }) => {
  
    const [isLoading2, setIsLoading2] = useState(false);
    const [orgAssetsList, setOrgAssetsList] = useState([]);
    const [assetDetails, setAssetDetails] = useState([]);
    const [totalElements, setTotalElements] = useState(1);
    const [currPage, setCurrPage] = useState(0);
    const [addTemplateModal, setAddTemplateModal] = useState(false);
    const [assetPurposeList, setAssetPurposeList] = useState([]);
    const [editTemplateModal, setEditTemplateModal] = useState(false);

    let userLoginDetails = getTokenInLocalStorage();

    useEffect(() => {

      getOrgAssetsList(assetType, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOrgAssetsList = (assetType, pageNo) => {
      let listData = [];
      setIsLoading2(true);

      let payload = {
        assetType:assetType,
        orgId: userLoginDetails.autoFinanceUser.organizationId,
      };
      moduleApi
        .postData(
          moduleApi.withPage(apiCall.getmessagetemplates, pageNo, 10),
          payload
        )
        .then((response) => {
      
          if (response) {
            if (response?.message) {
              setIsLoading2(false);
              return swal(response?.message, {
                icon: "error",
                dangerMode: true,
              });
            } else {
              response?.content?.forEach((element) => {
                let data = {
                  id:element.id,
                  name: element.name,
                  assetPurpose:element.assetPurpose,
                  details:element.details,
                };
  
                listData.push(data);
              });
              setOrgAssetsList(listData);
              setTotalElements(response?.totalElements);
              setIsLoading2(false);
            }
          } else {
            setIsLoading2(false);
            return swal("Something Went Wrong, Please Try again", {
              icon: "error",
              dangerMode: true,
            });
          }
        });
    };
    const getAssetPurtoseList = () => {

      moduleApi
        .getData(
          apiCall.getallassetpurposes
        )
        .then((response) => {
          const result = response.map((item) => ({
            value: item.assetPurpose,
            label: item.assetPurpose,
            assetPurposeId: item.id,
            assetPurpose: item.assetPurpose,
          }));
          setAssetPurposeList(result);
        });
    };
    const onPageChange = (selectedPage) => {
      setCurrPage(selectedPage - 1);
      getOrgAssetsList(assetType, selectedPage - 1);
    };

    const addMessageTemplate = (values) => {


      if (values.assetPurposeId === "") {
        return swal("Please Select Asset Purpose", {
          icon: "error",
          dangerMode: true,
        });
      }

      var payload = {
        orgId: userLoginDetails.autoFinanceUser.organizationId,
        updateBy:userLoginDetails.autoFinanceUser.userId,
        assetType: assetType,
        name:values.name,
        assetPurposeId:values.assetPurposeId,
        details:values.details,
      };
  
      moduleApi
        .postData(apiCall.savemessagetemplates, payload)
        .then((response) => {

          if (response) {
            if (response?.message) {
              return swal(response?.message, {
                icon: "error",
                dangerMode: true,
              });
            } else if (response?.Message === "Message Template Added Successfully") {
              setAddTemplateModal(false);
              return swal("Message Template Added Successfully", {
                icon: "success",
              });
            }
          } else {
            return swal("Something Went Wrong, Please Try Later", {
              icon: "error",
              dangerMode: true,
            });
          }
        });
    };

    const editMessageTemplate = (values) => {
      var payload = {
        id:assetDetails.id,
        orgId: userLoginDetails.autoFinanceUser.organizationId,
        updateBy:userLoginDetails.autoFinanceUser.userId,
        assetType: assetType,
        name:values.name,
        assetPurposeId:values.assetPurposeId,
        details:values.details,
      };
  
      moduleApi
        .postData(apiCall.updatemessagetemplates, payload)
        .then((response) => {

          if (response) {
            if (response?.message) {
              return swal(response?.message, {
                icon: "error",
                dangerMode: true,
              });
            } else if (response?.Message === "Message Template Updated Successfully") {
              setEditTemplateModal(false);
              return swal("Message Template Updated Successfully", {
                icon: "success",
              });
            }
          } else {
            return swal("Something Went Wrong, Please Try Later", {
              icon: "error",
              dangerMode: true,
            });
          }
        });
    };
    
    const validateTemplateSchema = Yup.object({
      name: Yup.string().required("Template name is required"),
      details: Yup.string().required("Details are required"),
      //assetPurposeId:Yup.string().required("Asset Purpose is required"),
    });

    const columns = [
      {
        text: "Asset Name",
        dataField: "name",
        width: 130,
        align: "center",
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "rgb(243,240,249)",
          fontWeight: "bold",
          verticalAlign: "middle",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
        style: {
          fontWeight: "bold",
          borderColor: "white",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
      },
      {
        text: "Asset Purpose",
        dataField: "assetPurpose",
        width: 130,
        align: "center",
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "rgb(243,240,249)",
          fontWeight: "bold",
          verticalAlign: "middle",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
        style: {
          fontWeight: "bold",
          borderColor: "white",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
      },
      {
        text: "Details",
        dataField: "details",
        width: 130,
        align: "center",
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "rgb(243,240,249)",
          fontWeight: "bold",
          verticalAlign: "middle",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
        style: {
          fontWeight: "bold",
          borderColor: "white",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
      },    
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit" />
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {

          setAssetDetails(row);
          getAssetPurtoseList();
          setEditTemplateModal(true);
        },
      },
    },
      {
        text: "",
        dataField: "id",
        align: "center",
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "rgb(243,240,249)",
          fontWeight: "bold",
          verticalAlign: "middle",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
        style: {
          borderColor: "white",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
        formatter: (cell, row) => {
          if (row.status === "ACTIVE4") {
            return <></>;
          } else {
            return (
              <div className="d-flex">
                <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fas fa-trash" />
                </Link>
              </div>
            );
          }
        },

        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {

            swal({
              title: "Delete",
              text: "Are you sure, you want to delete the template?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                var payLoad = {
                  orgId:  userLoginDetails.autoFinanceUser.organizationId,
                  updateBy:userLoginDetails.autoFinanceUser.userId,
                  id:row.id,
                };
                moduleApi
                  .postData(apiCall.deletemessagetemplates, payLoad)
                  .then((response) => {
                    if (response?.Message === "Message Template Deleted Successfully") {
                      getOrgAssetsList();
                      swal("Template Deleted Successfully", {
                        icon: "success",
                      });
                    }else if (response?.Message) {
                      swal(response.Message, {
                        icon: "error",
                        dangerMode: true,
                      });
                    }
                  })
              }
            });

          },
        },
      },
    ];

  return (
    <div>
       <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                      marginTop: "20px",
                      marginBottom: "5px",
                    }}
                  >
      <div
        style={{
          //float: "right",
          //margin: "10px",
          marginRight: "5px",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        }}
      >
        <Button
          className="me-2"
          variant="primary btn-rounded"
          onClick={() => {
            getAssetPurtoseList();
            setAddTemplateModal(true);
          }}
        >
          <i
            className="fa fa-plus-circle"
            style={{ fontSize: "12px" }}
          ></i>
          &nbsp;Add Template
        </Button>
      </div>
      </div>
      <div>
        &nbsp;
     <LoadingOverlay
                    active={isLoading2}
                    text={
                      <p
                        style={{
                          color: "black",
                        }}
                      >
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#FFFF",
                              color: "black",
                              borderColor: "white",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                            variant="primary"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="md"
                              variant="primary"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp;&nbsp;Loading...
                          </Button>
                        </div>
                      </p>
                    }
                    styles={{
                      overlay: (base) => ({
                        ...base,
                        background: "rgba(192,192,192,0.4)",
                      }),
                      spinner: (base) => ({
                        ...base,
                        width: "80px",
                        "& svg circle": {
                          stroke: "rgb(136, 108, 192)",
                        },
                      }),
                    }}
                  >
                 
                    <div
                      style={{
                        background: "white",
                        margin: "10px",
                        marginTop: "-5px",
                        overflowX: "auto",
                        overflowY: "auto",
                        maxHeight: "400px",
                        width: "100%",
                      }}
                    >
                      <BootstrapTable
                        keyField="id"
                        data={orgAssetsList}
                        columns={columns}
                      />
                    </div>
                    {orgAssetsList.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <img src={NO_DATA_FOUND} alt="" />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin:
                          totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                      }}
                    >
                      <PaginationComponent
                        totalItems={totalElements}
                        pageSize={10}
                        defaultActivePage={currPage + 1}
                        onSelect={onPageChange}
                      />
                    </div>
                  </LoadingOverlay>
                  </div>
                  <Modal
                      className="fade"
                      show={addTemplateModal}
                      size="md"
                    >
                      <Modal.Header>
                        <Modal.Title>Add Template</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setAddTemplateModal(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body style={{ paddingTop: "0px" }}>
                        <div style={{ margin: "10px" }}>
                          <Formik
                            initialValues={{
                              assetType: "",
                              name:"",
                              assetPurposeId:"",
                              details:"",
                            }}
                            onSubmit={addMessageTemplate}
                            validationSchema={validateTemplateSchema}
                          >
                            {({ formik, errors }) => (
                              <div>
                                <Row>
                                  <Col lg="12">
                                    <Form>
                                      <TextField
                                        isAsterisk={true}
                                        label="Template Name"
                                        name="name"
                                        type="text"
                                      />
                                      <TextField
                                        isAsterisk={true}
                                        label="Details"
                                        name="details"
                                        type="text"
                                      />
                                  <Field name="assetPurposeId">
                             {({ field, form }) => (
                                       <div
                                 style={{
                                   display: "flex",
                                   flexDirection: "column",
                                   marginTop: "5px",
                                 }}
                               >
                                 <label
                                   className="control-label"
                                   style={{
                                     fontWeight: "bold",
                                     float: "left",
                                     marginBottom: "10px",
                                   }}
                                 >
                                  Asset Purpose
                                 </label>
                                 <Select
                                   placeholder="Select Asset Purpose"
                                   classNamePrefix="select-box"
                                   options={assetPurposeList}
                                   onChange={(selectedOption) => {
                                     form.setFieldValue(
                                       "assetPurposeId",
                                       selectedOption.assetPurposeId
                                     );
                                   }}
                                 />
                               </div>
                               )}
                           </Field>
                                      <div>
                                        <Button
                                          style={{ float: "right" }}
                                          className="col-lg-2 m-2"
                                          type="submit"
                                        >
                                          Submit
                                        </Button>
                                        <Button
                                          style={{ float: "right" }}
                                          className="col-lg-2 m-2 float-right"
                                          variant="danger light"
                                          onClick={() => {
                                            setAddTemplateModal(false);
                                          }}
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Formik>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal
                      className="fade"
                      show={editTemplateModal}
                      size="md"
                    >
                      <Modal.Header>
                        <Modal.Title>Edit Template</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setEditTemplateModal(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body style={{ paddingTop: "0px" }}>
                        <div style={{ margin: "10px" }}>
                          <Formik
                            initialValues={{
                              name:assetDetails.name,
                              assetPurposeId:assetDetails.assetPurposeId,
                              details:assetDetails.details,
                            }}
                            onSubmit={editMessageTemplate}
                            validationSchema={validateTemplateSchema}
                          >
                            {({ formik, errors }) => (
                              <div>
                                <Row>
                                  <Col lg="12">
                                    <Form>
                                      <TextField
                                        isAsterisk={true}
                                        label="Template Name"
                                        name="name"
                                        type="text"
                                      />
                                      <TextField
                                        isAsterisk={true}
                                        label="Details"
                                        name="details"
                                        type="text"
                                      />
                                  <Field name="assetPurposeId">
                             {({ field, form }) => (
                                       <div
                                 style={{
                                   display: "flex",
                                   flexDirection: "column",
                                   marginTop: "5px",
                                 }}
                               >
                                 <label
                                   className="control-label"
                                   style={{
                                     fontWeight: "bold",
                                     float: "left",
                                     marginBottom: "10px",
                                   }}
                                 >
                                  Asset Purpose
                                 </label>
                                 <Select
                                   placeholder="Select Asset Purpose"
                                   classNamePrefix="select-box"
                                   options={assetPurposeList}
                                   defaultValue={{label:assetDetails.assetPurpose,
                                    value:assetDetails.assetPurpose
                                    }}
                                   onChange={(selectedOption) => {
                                     form.setFieldValue(
                                       "assetPurposeId",
                                       selectedOption.assetPurposeId
                                     );
                                   }}
                                 />
                               </div>
                               )}
                           </Field>
                                      <div>
                                        <Button
                                          style={{ float: "right" }}
                                          className="col-lg-2 m-2"
                                          type="submit"
                                        >
                                          Submit
                                        </Button>
                                        <Button
                                          style={{ float: "right" }}
                                          className="col-lg-2 m-2 float-right"
                                          variant="danger light"
                                          onClick={() => {
                                            setEditTemplateModal(false);
                                          }}
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Formik>
                        </div>
                      </Modal.Body>
                    </Modal>
    </div>
  );
};

export default MessageTemplate;
