import React, { useEffect, useState } from "react";
import SearchBar from "../CommonComponents/SearchBar";
import { Button, Spinner } from "react-bootstrap";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
// import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import ReactGA from "react-ga";
// import Select from "react-select";
import swal from "sweetalert";
import { getTokenInLocalStorage } from "../services/AuthService";
// import SalesUpload from "./SalesUpload";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
// import { EpochToDate } from "../CommonComponents/Helper";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
// import UploadedFilesList from "../UploadSchedule/UploadedFilesList";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";

const SalesSummary = () => {
  const [salesAllocationsList, setSalesAllocationsList] = useState([]);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );
  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview("Sales Allocation Summary");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paymentsDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDownloadList]);

  useEffect(() => {
    loadSalesData(0, searchKey, start, end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSalesData = (pageNo, searchKey, start, end) => {
    var listData = [];
    setIsLoading(true)
    var payload = {
      starttime: start,
      endtime: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      searchKey: searchKey,
      reportCategory: "DETAILED",
      reportingOfficerId: "",
      reportType: "SalesAllocationSummary",
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.reports, pageNo, 10), payload)
      .then((response) => {
        setCurrPage(pageNo);
        // console.log("response", response);
        response?.content?.forEach((element) => {
          let data = {
            userId: element.userId,
            empId: element.empId ? element.empId : "-",
            empName: element.empName ? element.empName : "-",
            totalAllocations: element.totalAllocations
              ? element.totalAllocations
              : 0,
            attempted: element.attempted ? element.attempted : 0,
            notAttempted: element.notAttempted
              ? element.notAttempted
              : 0,
            open: element.open
              ? element.open
              : 0,
              inProgress: element.inProgress
              ? element.inProgress
              : 0,
              completed: element.completed
              ? element.completed
              : 0,
            totalCalls: element.totalCalls ? element.totalCalls : 0,
          };
          listData.push(data);
        });
        setSalesAllocationsList(listData);
        setTotalElements(response?.totalElements);
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    loadSalesData(selectedPage - 1, searchKey, start, end);
  };

  const salesAllocationColumns = [
    {
      text: "Emp Id",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Total</span>
          <br></br>
          <span>Allocations</span>
        </div>
      ),
      dataField: "totalAllocations",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Attempted",
      dataField: "attempted",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Not</span>
          <br></br>
          <span>Attempted</span>
        </div>
      ),
      dataField: "notAttempted",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total</span>
          <br></br>
          <span>Calls</span>
        </div>
      ),
      dataField: "totalCalls",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Open",
      dataField: "open",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "In Progress",
      dataField: "inProgress",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Completed",
      dataField: "completed",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  const paymentsDownloadData = () => {
    setIsLoading(true);

    var payload = {
        starttime: start,
      endtime: end,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      searchKey: searchKey,
      reportCategory: "DETAILED",
      reportingOfficerId: "",
      reportType: "SalesAllocationSummary",
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.reports, 0, 10), payload)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getPaymentsFullResponseData(
                  Math.floor(response.totalElements / 200)
                );
              }
            });
          }

          getPaymentsFullResponseData(Math.floor(response.totalElements / 200));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
    // }
  };

  async function getPaymentsFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];

    for (let i = 0; i <= size; i++) {
      var payload = {
        starttime: start,
        endtime: end,
        organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
        searchKey: searchKey,
        reportCategory: "DETAILED",
        reportingOfficerId: "",
        reportType: "SalesAllocationSummary",
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.reports, i, 200),
          payload
        )
        .then((response) => {
          tempList.push(...response?.content);
        });
    }

    // console.log("templist", tempList);
    tempList?.forEach((element) => {
      let data = {
        userId: element.userId,
        empId: element.empId ? element.empId : "-",
        empName: element.empName ? element.empName : "-",
        totalAllocations: element.totalAllocations
          ? element.totalAllocations
          : 0,
        attempted: element.attempted ? element.attempted : 0,
        notAttempted: element.notAttempted
          ? element.notAttempted
          : 0,
          open: element.open
          ? element.open
          : 0,
          inProgress: element.inProgress
          ? element.inProgress
          : 0,
          completed: element.completed
          ? element.completed
          : 0,
        totalCalls: element.totalCalls ? element.totalCalls : 0,
      };
      mgrListData.push(data);
    });
    setPaymentsDownloadList(mgrListData);
    // }
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Emp Id",
        B: "Emp Name",
        C: "Total Allocations",
        D: "Attempted",
        E: "Not Attempted",
        F: "Total Calls",
        G: "Open",
        H: "In Progress",
        I: "Completed",
        
      },
    ];
    paymentsDownloadList?.forEach((row) => {
      mgrTable.push({
        A: row.empId,
        B: row.empName,
        C: row.totalAllocations,
        D: row.attempted,
        E: row.notAttempted,
        F: row.totalCalls,
        G: row.open,
        H: row.inProgress,
        I: row.completed,
        
      });
    });
    const finalData = [...mgrTable];
    // console.log("finaldata", finalData);
    //create a new workbook
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    // console.log("sheet", sheet);
    XLSX.utils.book_append_sheet(wb, sheet, "Allocations_Summary");
    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Sales_Allocations_Summary_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Sales Allocations Summary List Download",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Sales_Allocations_Summary" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadSalesData(0, key, start, end);
  };

  const setCustomDatesForGraph = (value1) => {
    var start = value1[0].getTime() / 1000;
    var end =
      new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStart(start);
    setEnd(end);
    loadSalesData(0, searchKey, start, end);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          //   marginTop: "20px",
          marginBottom: "15px",
          marginRight: "15px",
        }}
      >
        <SearchBar
          placeholder="Search By Emp Id/Emp Name"
          searchKeyword={searchKeyword}
        />
        <div
          style={{
            float: "right",
            // margin: "10px",
            marginLeft: "10px",
            marginRight: "10px",
            // pointerEvents:
            //   isProgressiveDialing === true && "none",
          }}
        >
          <Flatpickr
            className="form-control d-block"
            style={{
              width: "170px",
              height: "40px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              textAlignLast: "center",
            }}
            placeholder="Select Month"
            onChange={(value) => {
              setCustomDatesForGraph(value);
            }}
            options={{
              defaultDate: new Date(),
              maxDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 2,
                0
              ),
              minDate: new Date().setMonth(new Date().getMonth() - 6),

              plugins: [
                new monthSelectPlugin({
                  shorthand: true, //defaults to false
                  dateFormat: "F Y", //defaults to "F Y"
                  altFormat: "F Y", //defaults to "F Y"
                  theme: "light", // defaults to "light"
                }),
              ],
            }}
          />
        </div>
        <div>
          <Button
            style={{
              float: "right",
              //   margin: "10px",
              marginRight: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="success btn-rounded"
            onClick={() => paymentsDownloadData()}
          >
            <i className="fa fa-download"></i>
            &nbsp;&nbsp;Download
          </Button>
        </div>

        {/* <Button
        style={{
          marginRight: "10px",
          marginLeft: "7px",
          marginBottom: "5px",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        }}
        className="me-2"
        variant="success btn-rounded"
        // onClick={() => customerListDownloadData(visitType, searchKey,selectedApprovalStatus,selectedFIStatus)}
      >
        <i className="fa fa-download"></i>&nbsp;&nbsp;Download
      </Button> */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "-5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={salesAllocationsList}
              columns={salesAllocationColumns}
            />
          </div>

          {salesAllocationsList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default SalesSummary;
