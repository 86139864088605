import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import TextField from "../CommonComponents/TextField";
// import { toaster } from "../rest/apiUtils";
import * as moduleApi from "../rest/moduleApi";
import swal from "sweetalert";
import { apiCall } from "../rest/restApi";
import ReactGA from "react-ga";
import { getTokenInLocalStorage } from "../services/AuthService";

const AddCpUser = ({ close }) => {
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const phoneRegExp = "[1-9]{1}[0-9]{9}";
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const [loggedInUser, setloggedInUser] = useState([]);
  const [cpNamesList, setCpNamesList] = useState(null);
  const [selectedCpName, setSelectedCpName] = useState(null);
  const [isShortCode, setIsShortCode] = useState(false);
  const [isCpName, setIsCpName] = useState(false);
  const [initVal, setInitVal] = useState({
    email: "",
    employeeId: "",
    mobileNumber: "",
    name: "",
    shortCode: "",
  });

  useEffect(() => {
    ReactGA.pageview("Add Cp User");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);

    getAllCpNames();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllCpNames = () => {
    moduleApi
      .getData(
        apiCall.getCpUsersForDropdown +
          userLoginDetails.autoFinanceUser.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response.map((item, index) => ({
          value: item,
          label: item,
          index: index,
        }));

        setCpNamesList(result);
      });
  };

  // var namePattern = "^[a-zA-Z]";
  var nameRegex = "[a-zA-Z ]+$";
  // var specialCharacterEmployeeIdPattern = "^[a-zA-Z0-9]+$";
  const validate = Yup.object({
    email: Yup.string()
      .matches(emailPattern, "Email ID is invalid")
      .required("Email ID is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile Number is not valid")
      .min(10, "Mobile Number should be 10 digits")
      .max(10, "Mobile Number should be 10 digits")
      .required("Mobile Number is required"),
    employeeId: Yup.string()
      .required("Employee ID is required"),
    name: Yup.string()
      .trim()
      .matches(nameRegex, "Please Enter a Valid Name")
      .required("Name is required"),
    shortCode: Yup.string().trim().required("CP Short Code is required"),
  });

  const handleOk = (values) => {
    if(selectedCpName===null){
      return setIsCpName(true)
    }
    const params = {
      autoFinanceUser: {
        email: values.email,
        employeeId: values.employeeId,
        name: values.name,
        mobileNumber: values.mobileNumber,
      },
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      cpName: selectedCpName,
      cpOrganizationId: "",
      shortCode: values.shortCode,
    };

    moduleApi.postData(apiCall.saveCpUser, params).then((response) => {
      console.log("saveCpUser", response);
      if (response) {
        if (response?.Message === "User added sucessfully") {
          close();
          return swal("CP User Added Successfully", {
            icon: "success",
          });
        } else if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const getOrgShortCode = (value) => {
    var payload = {
      cpName: value,
      mainOrgnizationId: userLoginDetails.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(apiCall.getOrgBasedOnCpName, payload)
      .then((response) => {
        console.log("response", response);
        // setShortCode(response?.orgshortcode);
        if (
          response?.organizationId !== null &&
          response?.organizationId !== ""
        ) {
          let param = {
            shortCode: response?.orgshortcode,
          };
          let values = { ...initVal, ...param };
          setInitVal(values);
          setIsShortCode(true);
        } else {
          let param = {
            shortCode: '',
          };
          let values = { ...initVal, ...param };
          setInitVal(values);
          setIsShortCode(false);
        }
      });
  };

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "500px",
      }}
    >
      <Formik
        initialValues={initVal}
        onSubmit={handleOk}
        validationSchema={validate}
        enableReinitialize
      >
        {({ formik, errors }) => (
          <div>
            <Row>
              <Col lg="12">
                <Form>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <label
                        className="control-label"
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          marginBottom: "10px",
                        }}
                      >
                        CP Name
                      </label>
                      <Select
                        placeholder="Select CP Name"
                        classNamePrefix="select-box"
                        options={cpNamesList}
                        onChange={(value) => {
                          setSelectedCpName(value.value);
                          getOrgShortCode(value.value);
                          setIsCpName(false);
                        }}
                      />
                    </div>
                    {isCpName === true ? (
                      <span className="error">CP Name is required</span>
                     ):<></>} 
                    <TextField
                      isAsterisk={true}
                      label="CP Short Code"
                      name="shortCode"
                      type="text"
                      disabled={isShortCode}
                    />
                    <TextField
                      isAsterisk={true}
                      label="Name"
                      name="name"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Employee ID"
                      name="employeeId"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Mobile No"
                      name="mobileNumber"
                      maxlength="10"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Email ID"
                      name="email"
                      type="text"
                    />
                  </div>
                  <div>
                    <Button
                      style={{ float: "right" }}
                      className="col-lg-2 m-2"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="col-lg-2 m-2 float-right"
                      variant="danger light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddCpUser;
