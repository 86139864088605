import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
// import Select from "react-select";
import swal from "sweetalert";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { isAdmin } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
// import SearchBar from "../CommonComponents/SearchBar";
import ReactGA from "react-ga";
// import MonthPicker from "../CommonComponents/MonthPicker";
// import './CustomSwitch.css';

const AllocationCountsVisitsList = ({start, end}) => {
  const [visitedPlacesList, setVisitedPlacesList] = useState([]);
  const [fieldInvstgtnList, setFieldInvstgtnList] = useState([]);
  const [visitedPlacesDetailsList, setVisitedPlacesDetailsList] = useState([]);
  // const [basicModal, setBasicModal] = useState(false);
  // const [showDate, setShowDate] = useState(false);
  const [visitType, setVisitType] = useState("All");
  // const [collectionStatuses, setCollectionStatuses] = useState([]);
  // const [collectionSubStatuses, setCollectionSubStatuses] = useState([]);
  // eslint-disable-next-line
  const [loggedInUser, setloggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [toggle, setToggle] = useState("");
  // const [start, setStart] = useState(
  //   new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
  //     1000
  // );
  // const [end, setEnd] = useState(
  //   new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
  //     1000 +
  //     86399
  // );
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [isFieldInvstgn, setIsFieldInvstgn] = useState(false);
  // const [dropDownOptions, setDropDownOptions] = useState([]);
  // eslint-disable-next-line
  const [collectionType, setCollectionType] = useState("All");
  // eslint-disable-next-line
  const [collectionSubType, setCollectionSubType] = useState("");
  // eslint-disable-next-line
  const [selectedCollectionSubType, setSelectedCollectionSubType] = useState({
    label: "All",
    value: "",
  });
  // eslint-disable-next-line
  const [showCollectionValue, setShowCollectionValue] = useState(false);
  // eslint-disable-next-line
  const [isSubStatus, setIsSubStatus] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchKey, setSearchKey] = useState("");
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
   // eslint-disable-next-line
  const [isSwitch, setIsSwitch] = useState(false);

  useEffect(() => {
    loadVistedPlacesData(0, searchKey);
    // getCollectionDropdownOptions();
    setVisitType("Collections");
    setShowCollectionValue(true);
    setFlag(true);
    // loggedInUser.autoFinanceUser.roleName === "CP_USER" &&
    //   getCollectionDropdownOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVistedPlacesData = (pageNo, key) => {
    setIsLoading(true);
    if (visitType !== "Sales") {
      if (visitType === "All" || "Collections") {
        setFlag2(true);
      } else {
        setFlag2(false);
      }
    } else {
      setFlag2(false);
    }

    var VisitedPlacesBody = {
      endTime: end,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
      startTime: start,
      userId: isSwitch ? loggedInUser.autoFinanceUser.userId : "",
      visitType: "Collections",
      status: flag ? (collectionType === "All" ? "" : collectionType) : "",
      searchkey: key,
      subStatus: collectionSubType,
    };

    var cpNameVisitedPlacesBody = {
      endTime: end,
      organizationId: loggedInUser.autoFinanceOrganization.mainOrganizationId,
      // reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
      startTime: start,
      // userId: isSwitch ? loggedInUser.autoFinanceUser.userId : "",
      visitType: "Collections",
      status: collectionType === "All" ? "" : collectionType,
      searchkey: key,
      cpName: loggedInUser.autoFinanceOrganization.cpName,
      subStatus: collectionSubType,
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          loggedInUser.autoFinanceUser.roleName !== "CP_USER"
            ? apiCall.getemployeevisitsfromcollectionallocations
            : apiCall.getVisitsBasedOnCpName,
          pageNo,
          10
        ),
        loggedInUser.autoFinanceUser.roleName !== "CP_USER"
          ? VisitedPlacesBody
          : cpNameVisitedPlacesBody
      )
      .then((response) => {
        setCurrPage(pageNo);
        var listData = [];
        if (visitType !== "FieldInvestigation") {
          response?.content?.forEach((element) => {
            let data = {
              id: element.id,
              userId: element.userId,
              agDate: element.agDate,
              aggDate: element.aggDate,
              dueDate: element.dueDate,
              vehicleNumber: element.vehicleNumber,
              userModel: element.userModel,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              companyDesignation: element.userModel.companyDesignation,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,

              organizationId: element.organisationId,
              status: element.status,
              subStatus: element.subStatus,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              customerLatitude:element.customerLatitude,
              customerLongitude:element.customerLongitude,
              address: element.address,
              customerLocationChanged: element.customerLocationChanged,
              visitType: element.visitType,
              image: element.image,
              visitNotDoneAtCustLocation: element.visitNotDoneAtCustLocation,
              dateTime: convertEpochTimeToDate(element.dateTime),
              timeSpent: element.timeSpent,
              timeDiffOfPrevVisit: element.timeDiffOfPrevVisit,
              reportingOfficerId: element.reportingOfficerId,
              reportingOfficerName: element.reportingOfficerName,
              salesVisit: element.salesVisit,
              distFromCustAddr: getDistTravelledInKM(element.distFromCustAddr),
              contactNumber: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.contactNumber
                  : "--"
                : element.salesVisit
                ? element.salesVisit.contactNumber
                : "--",

              collectionVisit: element.collectionVisit,
              receiptNumber: element.collectionVisit?.receiptNumber
                ? element.collectionVisit?.receiptNumber
                : null,
              loanNumber: element.collectionVisit
                ? element.collectionVisit.loanNumber
                : "-",
              collectionsContactNumber: element.collectionVisit?.contactNumber,
              invenotryScan: element.collectionVisit?.invenotryScan,
              repossedImages: element.collectionVisit?.repossedImages,
              repossedLocation: element.collectionVisit?.repossedLocation,
              customerName: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.customerName
                : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.customerName
                  : "--"
                : element.salesVisit
                ? element.salesVisit.customerName
                : "--",
              fieldInvestigationVisit: element.fieldInvestigationVisit,
            };
            listData.push(data);
          });

          setVisitedPlacesList(listData);
          setIsFieldInvstgn(false);
          setIsLoading(false);
        } else {
          response?.content?.forEach((element) => {
            let data = {
              id: element.id,
              userModel: element.userModel,
              comments: element.comments,
              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              employeeId: element.userModel.employeeId,
              companyDesignation: element.userModel.companyDesignation,
              userStatus: element.userModel.status,
              customerLocationChanged: element.customerLocationChanged,
              organizationId: element.organisationId,
              latitude: element.latitude,
              longitude: element.longitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              timeDiffOfPrevVisit: element.timeDiffOfPrevVisit,
              dateTime: convertEpochTimeToDate(element.dateTime),
              reportingOfficerId: element.reportingOfficerId,
              reportingOfficerName: element.reportingOfficerName,
              fieldInvestigationVisit: element.fieldInvestigationVisit,
              contactNumber: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : "-",
            };
            listData.push(data);
          });
          console.log("Visited places list Data", listData);
          setVisitedPlacesList([]);
          setFieldInvstgtnList(listData);
          setIsFieldInvstgn(true);
          setIsLoading(false);
        }
        setTotalElements(response.totalElements);
      });
  };

  const getDistTravelledInKM = (distFromCustAddr) => {
    if (distFromCustAddr !== 0) {
      var km = distFromCustAddr / 1000.0;
      return km.toFixed(0) + " km";
    } else {
      return "-";
    }
  };

  const onPageChange = (selectedPage) => {
    loadVistedPlacesData(selectedPage - 1, searchKey);
  };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };
  const columns = [
    {
      text: "Executive",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Type",
      dataField: "visitType",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      // formatter: ((col, row) => {
      //   return row.salesVisit ? row.salesVisit.customerName : row.collectionVisit.customerName
      // })
    },

    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile No</span>
        </div>
      ),
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      // text:visitType!=="Sales"? visitType=== "All"|| "Collections"?"Loan No":"":"",
      text: flag2 ? "Loan No" : "",
      dataField: flag2 ? "loanNumber" : "",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: "Email",
    //   dataField: "email",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",

    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Visited Date",
      dataField: "dateTime",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Visit Under</span>
          <br></br>
          <span>Manager</span>
        </div>
      ),
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (row.collectionVisit?.status === "Partially Paid") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Customer Not Available") {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "") {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.status}
              </span>
            );
          }
        } else if (row.fieldInvestigationVisit) {
          return (
            <span
              className={
                row.fieldInvestigationVisit?.status === "Positive" ||
                row.fieldInvestigationVisit?.status === "FI Submitted"
                  ? "badge badge-rounded badge-success"
                  : row.fieldInvestigationVisit?.status === "Door Locked"
                  ? "badge badge-rounded badge-primary"
                  : row.fieldInvestigationVisit?.status === "Refer to Credit"
                  ? "badge badge-rounded badge-warning"
                  : "badge badge-rounded badge-danger"
              }
              style={{
                fontWeight: "bold",
              }}
            >
              {row.fieldInvestigationVisit?.status}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (
            row.collectionVisit?.subStatus === "Cash" ||
            row.collectionVisit?.subStatus === "Bank Transfer" ||
            row.collectionVisit?.subStatus === "Already Settled" ||
            row.collectionVisit?.subStatus === "UPI"
          ) {
            return (
              <span
                className="badge badge-rounded badge-success"
                // style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "Customer Not Available"
          ) {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "" ||
            row.collectionVisit?.subStatus === null
          ) {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.subStatus}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          // setBasicModal(true);
          setToggle("chatbox");
        },
      },
    },
    {
      text: "",
      dataField: "receiptNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.receiptNumber === null) {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-success shadow btn-xs sharp">
                <i className="fa fa-download"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          window.open(
            moduleApi.getBasePath() + apiCall.getPaymentReceipt + row.id
          );
        },
      },
    },
    {
      text: "",
      dataField: "receiptNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.receiptNumber === null) {
          return <></>;
        } else {
          return (
            <div>
              <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fas fa-external-link-alt"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          swal({
            title: "Resend",
            text: "Do you really want to Resend Payment Receipt?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              resendPaymentReceipt(row?.id);
            }
          });
        },
      },
    },
  ];

  const fieldInvstgncolumns = [
    {
      text: "Emp Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.customerName
          ? row.fieldInvestigationVisit.customerName
          : "-";
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile</span>
        </div>
      ),
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visited Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Visit Under</span>
          <br></br>
          <span>Manager</span>
        </div>
      ),
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <span
            className={
              row.fieldInvestigationVisit?.status === "Positive" ||
              row.fieldInvestigationVisit?.status === "FI Submitted"
                ? "badge badge-rounded badge-success"
                : row.fieldInvestigationVisit?.status === "Door Locked"
                ? "badge badge-rounded badge-primary"
                : row.fieldInvestigationVisit?.status === "Refer to Credit"
                ? "badge badge-rounded badge-warning"
                : "badge badge-rounded badge-danger"
            }
            style={{
              fontWeight: "bold",
            }}
          >
            {row.fieldInvestigationVisit?.status}
          </span>
        );
      },
    },
    {
      text: (
        <div>
          <span>Approval</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "approvalStatus",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <span
            className={
              row.fieldInvestigationVisit?.approvalStatus === "APPROVED"
                ? "badge badge-rounded badge-success"
                : row.fieldInvestigationVisit?.approvalStatus === "REJECTED"
                ? "badge badge-rounded badge-danger"
                : row.fieldInvestigationVisit?.approvalStatus === "PENDING"
                ? "badge badge-rounded badge-warning"
                : ""
            }
            style={{
              fontWeight: "bold",
            }}
          >
            {row.fieldInvestigationVisit?.approvalStatus
              ? row.fieldInvestigationVisit?.approvalStatus
              : "-"}
          </span>
        );
      },
    },
    {
      text: "Rejection Reason",
      dataField: "rejectionType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {row.fieldInvestigationVisit?.rejectionType
              ? row.fieldInvestigationVisit?.rejectionType
              : "-"}
          </span>
        );
      },
    },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-success shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitedPlacesDetailsList(row);
          // setBasicModal(true);
          setToggle("chatbox");
        },
      },
    },
  ];

  const resendPaymentReceipt = (visitId) => {
    moduleApi
      .getData(apiCall.resendPaymentReceipt + visitId)
      .then((response) => {
        if (response) {
          if (response.Message === "Receipt Sent Successfully") {
            return swal("Receipt Sent Successfully", {
              icon: "success",
              dangerMode: false,
            });
          } else if (response.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        }
      });
  };

  // const optionDates = [
  //   { label: "Last 7 Days", value: 1 },
  //   { label: "Today", value: 2 },
  //   { label: "Last 30 Days", value: 3 },
  //   { label: "Custom", value: 4 },
  // ];

  // const collectionValues = [
  //   { label: "All", value: "All" },
  //   { label: "Customer Not Available", value: "Customer Not Available" },
  //   { label: "Paid", value: "Paid" },
  //   { label: "PTP", value: "PTP" },
  //   // { label: "Partially Paid", value: "Partially Paid" },
  //   { label: "RTP", value: "RTP" },
  //   { label: "Repossessed", value: "Repossessed" },
  //   { label: "Vacant", value: "Vacant" },
  //   { label: "ThirdParty", value: "ThirdParty" },
  // ];

  // const setCollectionTypeValue = (value) => {
  //   setCollectionType(value.value);
  // };

  // const addDropDownOptions = () => {
  //   var opts = [];
  //   opts.push({ label: "All", value: 3 });
  //   hasAccess(3) && opts.push({ label: "Sales", value: 1 });
  //   hasAccess(4) && opts.push({ label: "Collections", value: 2 });
  //   hasAccess(5) && opts.push({ label: "Field Investigation", value: 4 });
  //   setDropDownOptions(opts);
  // };

  // const getCollectionDropdownOptions = () => {
  //   var payload = {
  //     organizationId:
  //       loggedInUser.autoFinanceUser.roleName !== "CP_USER"
  //         ? loggedInUser.autoFinanceUser.organizationId
  //         : loggedInUser.autoFinanceOrganization.mainOrganizationId,
  //   };

  //   moduleApi
  //     .postData(apiCall.getCollectionsStatus, payload)
  //     .then((response) => {
  //       const result = response.map((item) => ({
  //         label: item,
  //         value: item,
  //       }));
  //       result.unshift({ label: "All", value: "All" });
  //       // console.log('result', result);

  //       setCollectionStatuses(result);
  //     });
  // };

  // const getSubStatusCollectionOptions = (subStatus) => {
  //   var payload = {
  //     organizationId:
  //       loggedInUser.autoFinanceUser.roleName !== "CP_USER"
  //         ? loggedInUser.autoFinanceUser.organizationId
  //         : loggedInUser.autoFinanceOrganization.mainOrganizationId,
  //     status: subStatus,
  //   };

  //   moduleApi
  //     .postData(apiCall.getCollectionsStatus, payload)
  //     .then((response) => {
  //       const result = response.map((item) => ({
  //         label: item,
  //         value: item,
  //       }));
  //       result.unshift({ label: "All", value: "" });
  //       if (response.length > 0) {
  //         setCollectionSubType("");
  //         setSelectedCollectionSubType({ label: "All", value: "" });
  //         setIsSubStatus(true);
  //       } else {
  //         setCollectionSubType("");
  //         setSelectedCollectionSubType({ label: "All", value: "" });
  //         setIsSubStatus(false);
  //       }
  //       // console.log('result', result);

  //       setCollectionSubStatuses(result);
  //     });
  // };

  // const setVisitValue = (label, value) => {
  //   setVisitType("");
  //   switch (value) {
  //     case 1:
  //       setVisitType("Sales");
  //       setShowCollectionValue(false);
  //       return;
  //     case 2:
  //       getCollectionDropdownOptions();
  //       setVisitType("Collections");
  //       setShowCollectionValue(true);
  //       setFlag(true);
  //       break;
  //     case 3:
  //       setVisitType("All");
  //       setShowCollectionValue(false);
  //       break;
  //     case 4:
  //       setVisitType("FieldInvestigation");
  //       setShowCollectionValue(false);
  //       break;
  //     default:
  //       console.log("default 4 ");
  //   }
  // };

  // const setDateValue = (label, value) => {
  //   var date;

  //   switch (value) {
  //     case 1:
  //       date = [
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate() - 6)).setHours(
  //             0,
  //             0,
  //             0
  //           )
  //         ),
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate())).setHours(
  //             23,
  //             59,
  //             59
  //           )
  //         ),
  //       ];

  //       // start = Math.floor(date[0].getTime() / 1000);
  //       // end = Math.floor(date[1].getTime() / 1000);
  //       setStart(Math.floor(date[0].getTime() / 1000));
  //       setEnd(Math.floor(date[1].getTime() / 1000));

  //       setShowDate(false);
  //       // loadVistedPlacesData();
  //       return;
  //     case 2:
  //       var today = new Date();

  //       // start = Math.floor(today.setHours(0, 0, 0, 0) / 1000);
  //       // end = Math.floor(today.setHours(23, 59, 59, 999) / 1000);
  //       setStart(Math.floor(today.setHours(0, 0, 0, 0) / 1000));
  //       setEnd(Math.floor(today.setHours(23, 59, 59, 999) / 1000));

  //       setShowDate(false);
  //       //loadVistedPlacesData();
  //       return;
  //     case 3:
  //       date = [
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate() - 29)).setHours(
  //             0,
  //             0,
  //             0
  //           )
  //         ),
  //         new Date(
  //           new Date(new Date().setDate(new Date().getDate())).setHours(
  //             23,
  //             59,
  //             59
  //           )
  //         ),
  //       ];

  //       // start = Math.floor(date[0].getTime() / 1000);
  //       // end = Math.floor(date[1].getTime() / 1000);
  //       setStart(Math.floor(date[0].getTime() / 1000));
  //       setEnd(Math.floor(date[1].getTime() / 1000));

  //       setShowDate(false);
  //       //loadVistedPlacesData();
  //       return;
  //     case 4:
  //       setShowDate(true);
  //       break;
  //     default:
  //       console.log("default 4 ", showDate);
  //   }
  // };

  // function setCustomDates(dateStrings) {
  //   if (dateStrings.length > 1) {
  //     // start = Math.floor(new Date(dateStrings[0]).getTime() / 1000);
  //     // end = Math.floor(new Date(dateStrings[1]).getTime() / 1000);
  //     setStart(Math.floor(dateStrings[0].getTime() / 1000));
  //     setEnd(Math.floor(dateStrings[1].getTime() / 1000) + 86400);
  //   }
  //   //loadVistedPlacesData();
  // }

  // const setCustomDatesForGraphForCollectionCounts = (value) => {
  //   var start = value[0].getTime() / 1000;
  //   var end =
  //     new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
  //       1000 +
  //     86399;

  //   setStart(start);
  //   setEnd(end);
  //   // loadVistedPlacesData(0,searchKey,start, end);
  // };

  // const searchKeyword = (key) => {
  //   setSearchKey(key);
  //   loadVistedPlacesData(0, key);
  // };

  // const handleChange = (e) => {
  //   setIsSwitch(!isSwitch);
  // };

  return (
    <div style={{ paddingTop: "20px",}}>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginTop: "20px",
          marginRight: "20px",
          marginBottom: "5px",
        }}
      >
        <SearchBar
          placeholder="Search By Executive/Emp ID"
          searchKeyword={searchKeyword}
          id="searchbtnvisitmngmt"
        />

        {loggedInUser.autoFinanceUser.roleName === "MANAGER" && (
          <div
            className="form-check form-switch"
            style={{
              marginTop: "10px",
              marginRight: "15px",
              marginLeft: "5px",
            }}
          >
            <input
              style={{ width: "30px" }}
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onChange={handleChange}
            />
            <label className="form-check-label" for="flexSwitchCheckDefault">
              Self Visits
            </label>
          </div>
        )}
        {loggedInUser.autoFinanceUser.roleName !== "CP_USER" && (
          <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "15px",
              marginBottom: "20px",
              marginLeft: "5px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) =>
                setVisitValue(value.label, value.value, dropDownOptions)
              }
              defaultValue={{ label: "All", value: 3 }}
              options={dropDownOptions}
              id="visittype"
            />
          </div>
        )}

        {(showCollectionValue ||
          loggedInUser.autoFinanceUser.roleName === "CP_USER") && (
          <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "20px",
              marginLeft: "10px",
              marginBottom: "20px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                setCollectionTypeValue(value);
                loggedInUser.autoFinanceUser.roleName === "CP_USER" &&
                  setShowCollectionValue(true);

                getSubStatusCollectionOptions(value.value);
              }}
              defaultValue={{ label: "All", value: "All" }}
              options={collectionStatuses}
              id="collectiontype"
            />
          </div>
        )}

        {showCollectionValue && isSubStatus && (
          <div
            style={{
              float: "right",
              width: "150px",
              marginRight: "20px",
              marginBottom: "20px",
              borderRadius: "3px",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                setCollectionSubType(value.value);
                setSelectedCollectionSubType(value);
              }}
              defaultValue={{ label: "All", value: "" }}
              value={selectedCollectionSubType}
              options={collectionSubStatuses}
              // id="collectiontype"
            />
          </div>
        )}

        <div>
          <MonthPicker
            setCustomDatesForGraph={setCustomDatesForGraphForCollectionCounts}
          />
        </div>

        <div
          style={{
            marginRight: "5px",
            marginLeft: "15px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
        >
          <Button
            className="me-2"
            variant="primary btn-rounded"
            onClick={() => loadVistedPlacesData(0, searchKey)}
            id="submitbtn"
          >
            Submit
          </Button>
        </div>
      </div> */}
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            {" "}
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "-5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            {isFieldInvstgn ? (
              <BootstrapTable
                keyField="panNo"
                data={fieldInvstgtnList}
                columns={fieldInvstgncolumns}
              />
            ) : (
              <BootstrapTable
                keyField="panNo"
                data={visitedPlacesList}
                columns={columns}
              />
            )}
          </div>
          {fieldInvstgtnList.length === 0 && visitedPlacesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "10px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      {toggle === "chatbox" && (
        <VisitedPlaceDrawer
          visitedPlacesDetailsList={visitedPlacesDetailsList}
          closeDrawer={() => setToggle(false)}
          reLoad={() => loadVistedPlacesData(currPage, searchKey)}
        />
      )}
    </div>
  );
};

export default AllocationCountsVisitsList;
