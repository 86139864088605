import React from 'react'
import { Button, Spinner } from 'react-bootstrap';
import LoadingOverlay from "react-loading-overlay";
export const LoadingOverlayComponent = (props) => {
    return (
        <LoadingOverlay
            active={props.showLoader}
            // spinner
            text={
                <p
                    style={{
                        color: "black",
                    }}
                >
                    <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
                </p>
            }
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "rgba(192,192,192,0.4)",
                }),
                spinner: (base) => ({
                    ...base,
                    width: "80px",
                    "& svg circle": {
                        stroke: "rgb(136, 108, 192)",
                    },
                }),
            }}
        >
            {props.children}
        </LoadingOverlay>
    )
}
