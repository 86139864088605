import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Button } from "react-bootstrap";
import TextField from "../CommonComponents/TextField";
import { getTokenInLocalStorage } from "../services/AuthService";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";

const AddActivity = ({ opportunityData, close }) => {
  let userLoginDetails = getTokenInLocalStorage();
  const [activityTypes, setActivityTypes] = useState([]);
  // const [branchNames, setBranchNames] = useState(null);
  const [teamNames, setTeamNames] = useState([]);

  const [assignees, setAssingees] = useState([]);

  useEffect(() => {
    getActivityTypes();
    getTeamNames()
    // getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const userOptions = [
  //   { label: "Managers", value: "MANAGER" },
  //   { label: "Executives", value: "EXECUTIVE" },
  // ];

  const initialValues = {
    activityType: "",

    // nextContactDate: "",
    // remarks: "",
    subject: "",
    description: "",
    dueDate: "",
    teamName: "",
    assignee: "",
  };

  // const getBranches = () => {
  //   const body = {
  //     organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //   };
  //   moduleApi.postData(apiCall.getBranches, body).then((response) => {
  //     const result = response.map((item) => ({
  //       branchId: item.branchId,
  //       branchName: item.branchName,
  //     }));
  //     setBranchNames(result);
  //   });
  // };

  const getUsers = (role) => {
    const payLoad = {
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      teamName: role,
    };
    moduleApi.postData(apiCall.getTeamUsers, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.userId,
        label: item.name + " - " + item.branchName,
        empId: item.empId,
      }));
      setAssingees(result);
    });
  };

  // const getBranch = (branchId) => {
  //   for (let i = 0; i < branchNames?.length; i++) {
  //     if (branchNames[i].branchId === branchId) {
  //       // console.log("branchName", branchNames[i].branchName);
  //       return branchNames[i].branchName;
  //     }
  //   }
  // };

  const validationSchema = Yup.object({
    activityType: Yup.string().required("Activity Type is required"),
    // nextContactDate: Yup.date().required("Next Contact Date is required"),
    // remarks: Yup.string().notRequired(),
    subject: Yup.string().required("Subject is required"),
    description: Yup.string().required("Description is required"),
    dueDate: Yup.date().required("Due Date is required"),
    teamName: Yup.string().required("Team is required"),
    assignee: Yup.string().required("Assignee is required"),
  });

  const getActivityTypes = () => {
    moduleApi
      .getData(
        apiCall.getActivityTypes +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        const result = response?.map((item) => ({
          value: item,
          label: item,
        }));
        setActivityTypes(result);
      });
  };

  const handleSubmit = (values) => {
    console.log(opportunityData);
    var payload = {
      activityType: values.activityType,
      //   activityTypeId: 0,
      completed: true,
      description: values.description,
      dueDate: Math.floor(new Date(values.dueDate).getTime() / 1000.0),
      leadId: opportunityData?.leadId,
      opportunityId: opportunityData?.id,
      nextContactDate: Math.floor(
        new Date(values.nextContactDate).getTime() / 1000.0
      ),
      previousActivityId: 0,
      remarks: values.remarks,
      subject: values.subject,
      userId: values.assignee,
    };
    console.log(payload);
    moduleApi.postData(apiCall.addActivity, payload).then((response) => {
      console.log("addActivityResponse", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          close();
          return swal("Activity Added Successfully", {
            icon: "success",
            dangerMode: false,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const getTeamNames = () => {
    var payload = {
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
    };
    moduleApi.postData(apiCall.getTeamNames, payload).then((response) => {
      const result = response?.map((item) => ({
        value: item,
        label: item,
      }));
      setTeamNames(result);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          {/* Activity Type */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "5px",
            }}
          >
            <label
              className="control-label"
              style={{
                fontWeight: "bold",
                float: "left",
                marginBottom: "10px",
              }}
            >
              Activity Type
            </label>
            <Select
              id="activityType"
              name="activityType"
              options={activityTypes}
              value={activityTypes?.find(
                (option) => option.value === values.activityType
              )}
              onChange={(option) => setFieldValue("activityType", option.value)}
              placeholder="Select Activity Type"
            />
            <ErrorMessage
              name="activityType"
              component="div"
              className="error"
            />
          </div>
          <TextField
            isAsterisk={true}
            label="Subject"
            name="subject"
            type="text"
          />
          {/* Description */}
          <TextField
            isAsterisk={true}
            label="Description"
            name="description"
            type="text"
          />
          {/* Due Date */}
          <TextField
            isAsterisk={true}
            label="Due Date"
            name="dueDate"
            type="date"
            min={new Date().toLocaleDateString("en-CA")}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "5px",
            }}
          >
            <label
              className="control-label"
              style={{
                fontWeight: "bold",
                float: "left",
                marginBottom: "10px",
              }}
            >
              Team
            </label>
            <Select
              id="teamName"
              name="teamName"
              options={teamNames}
              value={teamNames?.find(
                (option) => option.value === values.teamName
              )}
              onChange={(option) => {
                getUsers(option.value);
                setFieldValue("teamName", option.value);
              }}
              placeholder="Select Team"
            />
            <ErrorMessage
              name="teamName"
              component="div"
              className="error"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <label
              className="control-label"
              style={{
                fontWeight: "bold",
                float: "left",
                marginBottom: "10px",
              }}
            >
              Assignee
            </label>
            <Select
              id="assignee"
              name="assignee"
              options={assignees}
              value={assignees?.find(
                (option) => option.value === values.assignee
              )}
              onChange={(option) => {
                setFieldValue("assignee", option.value);
              }}
              placeholder="Select Assignee"
            />
            <ErrorMessage name="assignee" component="div" className="error" />
          </div>

          {/* Next Contact Date */}
          {/* <TextField
            isAsterisk={true}
            label="Next Contact Date"
            name="nextContactDate"
            type="date"
          /> */}

          {/* Remarks */}
          {/* <TextField
            isAsterisk={true}
            label="Remarks"
            name="remarks"
            type="text"
          /> */}

          {/* Subject */}

          <div>
            <Button style={{ float: "right" }} className="me-2" type="submit">
              Submit
            </Button>
            <Button
              style={{ float: "right" }}
              className="me-2"
              variant="danger light"
              onClick={close}
            >
              Close
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddActivity;
