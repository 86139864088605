import React, { useEffect, useState } from "react";
//import SearchBar from "../CommonComponents/SearchBar";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess, isAdmin } from "../services/AuthService";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { EpochToDate } from "../CommonComponents/Helper";
//import Select from "react-select";
import swal from "sweetalert";
import "flatpickr/dist/plugins/monthSelect/style.css";
// import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import ReactGA from "react-ga";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { Modal } from "react-bootstrap";
import SearchBar from "../CommonComponents/SearchBar";

const FinalPayoutDetails = () => {

    // var history = useHistory();
    const [finalPayoutList, setFinalPayoutList] = useState([]);
    const [finalPayoutDetails, setFinalPayoutDetails] = useState([]);
    const [loggedInUser] = useState(
        JSON.parse(localStorage.getItem("userDetails"))
    );
    const [totalElements, setTotalElements] = useState(1);
    const [currPage, setCurrPage] = useState(0);
    const [totalElements2, setTotalElements2] = useState(1);
    const [currPage2, setCurrPage2] = useState(0);
    const [basicModal, setBasicModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [finalPayoutDownloadList, setFinalPayoutDownloadList] = useState(null);
    const [incentivesList, setIncentivesList] = useState([]);
    const [searchKey, setSearchKey] = useState("");

    const [start, setStart] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
        1000
    );
    const [end, setEnd] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
        1000 +
        86399
    );

    useEffect(() => {
        loadFinalPayoutDetails(
            0,
            start,
            end,
            searchKey
        );
        // eslint-disable-next-line
    }, []);

    const loadFinalPayoutDetails = (
        pageNo,
        startDate,
        endDate,
        search
    ) => {
        setIsLoading(true);

        var body = {
            endDate: endDate,
            organizationId: loggedInUser.autoFinanceUser.organizationId,
            reportingOfficerId: isAdmin() ? "" : hasAccess(72) ? "" : loggedInUser.autoFinanceUser.userId,
            startDate: startDate,
            searchKey: search
        };

        moduleApi
            .postData(
                moduleApi.withPage(apiCall.getFinalPayOutByEmployeeDetails, pageNo, 10),
                body
            )
            .then((response) => {
                setCurrPage(pageNo);
                var listData = [];
                response?.content?.forEach((element) => {
                    let data = {

                        empName: element.empName ? element.empName : "-",
                        empId: element.empId ? element.empId : "-",
                        userId: element.userId ? element.userId : "-",
                        branchId: element.branchId ? element.branchId : "-",
                        branchName: element.branchName ? element.branchName : "-",
                        reportingOfficerId: element.reportingOfficerId ? element.reportingOfficerId : "-",
                        reportingOfficerName: element.reportingOfficerName ? element.reportingOfficerName : "-",
                        totalAllocations: element.totalAllocations ? element.totalAllocations : 0,
                        totalVisits: element.totalVisits ? element.totalVisits : 0,
                        totalPaidVisits: element.totalPaidVisits ? element.totalPaidVisits : 0,
                        targetAmount: element.targetAmount ? element.targetAmount : 0,
                        totalCollectedAmount: element.totalCollectedAmount ? element.totalCollectedAmount : 0,
                        totalIncentivesEligibleFor: element.totalIncentivesEligibleFor ? element.totalIncentivesEligibleFor : 0,
                        totalIncentivesRecieved: element.totalIncentivesRecieved ? element.totalIncentivesRecieved : 0,
                        totalAmountPayable: element.totalAmountPayable ? element.totalAmountPayable.toFixed(2) : 0,

                    };
                    listData.push(data);
                });
                setFinalPayoutList(listData);
                setIsLoading(false);

                setTotalElements(response?.totalElements);
            });
    };

    const onPageChange = (selectedPage) => {
        loadFinalPayoutDetails(
            selectedPage - 1, start, end, searchKey
        );
    };

    const onPageChange2 = (selectedPage) => {
        loadIncentivesData(
            selectedPage - 1, start, end, finalPayoutDetails.userId
        );
    };

    const setCustomDatesForGraph = (value) => {
        var start = value[0].getTime() / 1000;
        var end =
            new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
            1000 +
            86399;

        setStart(start);
        setEnd(end);
        loadFinalPayoutDetails(0, start, end, searchKey);
    };

    const loadIncentivesData = (
        pageNo,
        startDate,
        endDate,
        userId
    ) => {
        setIsLoading(true);

        var body = {
            endDate: endDate,
            organizationId: loggedInUser.autoFinanceUser.organizationId,
            startDate: startDate,
            userId: userId,
        };

        moduleApi
            .postData(
                moduleApi.withPage(apiCall.getIncentiveswithuserStatus, pageNo, 10),
                body
            )
            .then((response) => {
                setCurrPage2(pageNo);
                var listData = [];
                response?.content?.forEach((element) => {
                    let data = {
                        id: element.id ? element.id : "-",
                        empName: element.empName ? element.empName : "-",
                        empId: element.empId ? element.empId : "-",
                        incentiveName: element.incentive.incentiveName
                            ? element.incentive.incentiveName
                            : "-",
                        incentiveCriteria: element.incentive.incentiveCriteria ? element.incentive.incentiveCriteria : "-",
                        incentiveSubCriteria: element.incentive.incentiveSubCriteria ? element.incentive.incentiveSubCriteria : "-",
                        criteriaPercentage: element.incentive.criteriaPercentage
                            ? element.incentive.criteriaPercentage
                            : "-",
                        criteriaValue: element.incentive.criteriaValue ? element.incentive.criteriaValue : "-",
                        incentiveAmount: element?.incentive?.incentiveAmount ? element.incentive.incentiveAmount : 0,
                        incentiveStartDate: element.incentive.incentiveStartDate
                            ? EpochToDate(element.incentive.incentiveStartDate)
                            : "-",
                        incentiveEndDate: element.incentive.incentiveEndDate
                            ? EpochToDate(element.incentive.incentiveEndDate)
                            : "-",
                        organizationId: element.organizationId
                            ? element.organizationId
                            : "-",
                        approvalStatus: element.approvalStatus
                            ? element.approvalStatus
                            : "-",
                        secondApprovalStatus: element.secondApprovalStatus
                            ? element.secondApprovalStatus
                            : "-",
                        approvedDateTime: element.approvedDateTime
                            ? EpochToDate(element.approvedDateTime)
                            : "-",
                        secondApprovedDate: element.secondApprovedDate
                            ? EpochToDate(element.secondApprovedDate)
                            : "-",
                        approvedBy: element.approvedBy ? element.approvedBy : "-",
                        approvedByName: element.approvedByName ? element.approvedByName : "-",
                        criteriaTargetAmount: element.incentive.criteriaTargetAmount
                            ? element.incentive.criteriaTargetAmount
                            : "-",
                        incentive: element.incentive ? element.incentive : "-",
                        targetAchieved: element.targetAchieved ? element.targetAchieved : 0,
                        amountCredited: element.amountCredited ? true : false,
                        targetToBeAchieved: element.targetToBeAchieved ? element.targetToBeAchieved : 0,
                        amountAchieved: element.amountAchieved ? element.amountAchieved.toFixed(2) : 0,
                        amountToBeAchieved: element.amountToBeAchieved ? element.amountToBeAchieved.toFixed(2) : 0,
                        incentiveTargetStatus: element.incentiveTargetStatus ? element.incentiveTargetStatus : "-",
                        range: element?.incentive?.range ? element?.incentive?.range : "-",
                        criteriaValue2: element?.incentive?.criteriaValue2 ? element?.incentive?.criteriaValue2 : "-",
                        criteriaPercentage2: element?.incentive?.criteriaPercentage2
                            ? element?.incentive?.criteriaPercentage2
                            : "-",
                        criteriaTargetAmount2: element?.incentive?.criteriaTargetAmount2
                            ? element?.incentive?.criteriaTargetAmount2
                            : "-",
                        firstApprovalDone: element.firstApprovalDone ? true : false,
                        secondApprovalDone: element.secondApprovalDone ? true : false,
                        amountApprovalDone: element.amountApprovalDone ? true : false,
                    };
                    listData.push(data);
                });
                setIncentivesList(listData);
                setIsLoading(false);

                setTotalElements2(response?.totalElements);
            });
    };

    const searchKeyword = (key) => {
        setSearchKey(key);
        loadFinalPayoutDetails(
            0,
            start,
            end,
            key
        );
    };

    const updateApprovalStatus = (status, isSecondApproval) => {
        setIsLoading(true);
        console.log("status", status);
        // if (status === "REJECTED" && rejectionReason === null) {
        //     return swal("Please Enter Rejection Reason", {
        //         icon: "error",
        //         dangerMode: true,
        //     });
        // }

        var body = {
            approvalStatus: status,
            secondApprovalStatus: status,
            secondApproval: isSecondApproval ? true : false,
            secondApprovedBy: isSecondApproval ? loggedInUser.autoFinanceUser.userId : "",
            organizationId: loggedInUser.autoFinanceOrganization.organizationId,
            //id: incentiveDetails.id,
            approvedBy: loggedInUser.autoFinanceUser.userId,
            userId: finalPayoutDetails.userId,
            startDate: start,
            endDate: end
        };

        moduleApi
            .postData(apiCall.updateIncentivesApprovalStatusInFinalPayout, body)
            .then((response) => {
                if (response) {
                    if (response?.message) {
                        setIsLoading(false);
                        swal(response?.message, {
                            icon: "error",
                            dangerMode: true,
                        });
                    } else if (response?.Message === "Incentive Approval Status Updated successfully") {

                        loadFinalPayoutDetails(
                            currPage,
                            start,
                            end,
                            searchKey
                        );
                        setBasicModal(false);
                        setIsLoading(false);
                        swal("Status Updated Successfully", {
                            icon: "success",
                        });
                    }
                } else {
                    setIsLoading(false);
                    return swal("Something Went Wrong, Please Try Later", {
                        icon: "error",
                        dangerMode: true,
                    });
                }
            });
    };

    const updateAmountCreditedStatus = () => {
        setIsLoading(true);

        var body = {

            organizationId: loggedInUser.autoFinanceOrganization.organizationId,
            amountCredited: true,
            userId: finalPayoutDetails.userId,
            startDate: start,
            endDate: end
        };

        moduleApi
            .postData(apiCall.markAmountAsCreditInFinalPayout, body)
            .then((response) => {
                if (response) {
                    if (response?.message) {
                        setIsLoading(false);
                        swal(response?.message, {
                            icon: "error",
                            dangerMode: true,
                        });
                    } else if (response) {
                        setIsLoading(false);
                        loadFinalPayoutDetails(
                            currPage,
                            start,
                            end,
                            searchKey
                        );
                        setBasicModal(false);
                        swal("Status Updated Successfully", {
                            icon: "success",
                        });
                    }
                } else {
                    setIsLoading(false);
                    return swal("Something Went Wrong, Please Try Later", {
                        icon: "error",
                        dangerMode: true,
                    });
                }
            });
    };

    const columns = [
        {
            text: "Emp Name",
            dataField: "empName",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: "Emp Id",
            dataField: "empId",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: "Branch",
            dataField: "branchName",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Reporting</span>
                    <br></br>
                    <span>Manager</span>
                </div>
            ),
            dataField: "reportingOfficerName",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Total</span>
                    <br></br>
                    <span>Allocations</span>
                </div>
            ),
            dataField: "totalAllocations",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Total</span>
                    <br></br>
                    <span>Visits</span>
                </div>
            ),
            dataField: "totalVisits",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Total Paid</span>
                    <br></br>
                    <span>Visits</span>
                </div>
            ),
            dataField: "totalPaidVisits",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },

        {
            text: (
                <div>
                    <span>Target</span>
                    <br></br>
                    <span>Amount</span>
                </div>
            ),
            dataField: "targetAmount",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Total Collected</span>
                    <br></br>
                    <span>Amount</span>
                </div>
            ),
            dataField: "totalCollectedAmount",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Total Incentives</span>
                    <br></br>
                    <span>Eligible For</span>
                </div>
            ),
            dataField: "totalIncentivesEligibleFor",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Total Incentives</span>
                    <br></br>
                    <span>Received</span>
                </div>
            ),
            dataField: "totalIncentivesRecieved",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Total Amount</span>
                    <br></br>
                    <span>Payable</span>
                </div>
            ),
            dataField: "totalAmountPayable",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: "",
            dataField: "id",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                if (row.id === "ACTIVE4") {
                    return <></>;
                } else {
                    return (
                        <div className="d-flex">
                            <Link to="#" className="btn btn-info shadow btn-xs sharp">
                                <i className="fa fa-eye"></i>
                            </Link>
                        </div>
                    );
                }
            },

            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setFinalPayoutDetails(row);
                    loadIncentivesData(0, start, end, row.userId);
                    setBasicModal(true);
                },
            },
        },
    ];


    const incentiveColumns = [
        {
            text: "Incentive Name",
            dataField: "incentiveName",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>Target</span>
                    <br></br>
                    <span>Achieved</span>
                </div>
            ),
            dataField: "targetAchieved",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {

                if (!row?.incentive?.incentivePerCaseBasis && row?.incentive?.incentiveCriteria === "Collected Amount") {
                    return (
                        row.targetAchieved + "₹"
                    );

                } else {
                    return (
                        row.targetAchieved
                    );
                }

            },
        },
        {
            text: (
                <div>
                    <span>Target</span>
                    <br></br>
                    <span>Pending</span>
                </div>
            ),
            dataField: "targetToBeAchieved",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {

                if (!row?.incentive?.incentivePerCaseBasis && row?.incentive?.incentiveCriteria === "Collected Amount") {
                    return (
                        row.targetToBeAchieved + "₹"
                    );

                } else {
                    return (
                        row.targetToBeAchieved
                    );
                }

            },
        },
        {
            text: (
                <div>
                    <span>Incentive Target</span>
                    <br></br>
                    <span>Status</span>
                </div>
            ),
            dataField: "incentiveTargetStatus",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                if (row.incentiveTargetStatus === "ACHIEVED") {
                    return (
                        <span className="badge badge-rounded badge-success">Achieved</span>
                    );
                } else if (row.incentiveTargetStatus === "NOTACHIEVED") {
                    return (
                        <span className="badge badge-rounded badge-danger">Not Achieved</span>
                    );
                } else if (row.incentiveTargetStatus === "PENDING") {
                    return (
                        <span
                            className="badge badge-rounded badge-warning"
                            style={{ color: "black" }}
                        >
                            Pending
                        </span>
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            text: (
                <div>
                    <span>Incentive Amount</span>
                    <br></br>
                    <span>Achieved</span>
                </div>
            ),
            dataField: "amountAchieved",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
        },
        {
            text: (
                <div>
                    <span>First Approval</span>
                    <br></br>
                    <span>Status</span>
                </div>
            ),
            dataField: "approvalStatus",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                if (row.approvalStatus === "APPROVED") {
                    return (
                        <span className="badge badge-rounded badge-success">Approved</span>
                    );
                } else if (row.approvalStatus === "REJECTED") {
                    return (
                        <span className="badge badge-rounded badge-danger">Rejected</span>
                    );
                } else if (row.approvalStatus === "PENDING") {
                    return (
                        <span
                            className="badge badge-rounded badge-warning"
                            style={{ color: "black" }}
                        >
                            Pending
                        </span>
                    );
                } else {
                    return "-";
                }
            },
        },
        {
            text: (
                <div>
                    <span>Second Approval</span>
                    <br></br>
                    <span>Status</span>
                </div>
            ),
            dataField: "secondApprovalStatus",
            align: "center",
            headerAlign: "center",
            headerStyle: {
                backgroundColor: "rgb(243,240,249)",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            style: {
                borderColor: "white",
                fontFamily:
                    "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            },
            formatter: (cell, row) => {
                if (row.secondApprovalStatus === "APPROVED") {
                    return (
                        <span className="badge badge-rounded badge-success">Approved</span>
                    );
                } else if (row.secondApprovalStatus === "REJECTED") {
                    return (
                        <span className="badge badge-rounded badge-danger">Rejected</span>
                    );
                } else if (row.secondApprovalStatus === "PENDING") {
                    return (
                        <span
                            className="badge badge-rounded badge-warning"
                            style={{ color: "black" }}
                        >
                            Pending
                        </span>
                    );
                } else {
                    return "-";
                }
            },
        },

    ];


    useEffect(() => {
        finalPayoutDownloadList !== null && handleExport();
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalPayoutDownloadList]);

    const finalPayoutDownloadData = () => {

        setIsLoading(true);
        var payload = {
            endDate: end,
            organizationId: loggedInUser.autoFinanceUser.organizationId,
            reportingOfficerId: isAdmin() ? "" : hasAccess(72) ? "" : loggedInUser.autoFinanceUser.userId,
            startDate: start,
            searchKey: searchKey
        };
        moduleApi
            .postData(
                moduleApi.withPage(apiCall.getFinalPayOutByEmployeeDetails, 0, 10),
                payload
            )
            .then((response) => {
                if (response) {
                    if (response.totalElements > 5000) {
                        setIsLoading(false);
                        return swal({
                            title: "Download Report",
                            text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        }).then((willContinue) => {
                            if (willContinue) {
                                setIsLoading(true);
                                getFinalPayoutFullResponseData(
                                    Math.floor(response.totalElements / 100)
                                );
                            }
                        });
                    }

                    getFinalPayoutFullResponseData(Math.floor(response.totalElements / 100));
                } else {
                    swal("Something Went Wrong, Please Try Later", {
                        icon: "error",
                        dangerMode: true,
                    });
                }
            });
    };

    async function getFinalPayoutFullResponseData(size) {
        var tempList = [];
        var finalPayoutListData = [];
        for (let i = 0; i <= size; i++) {
            var payload = {
                endDate: end,
                organizationId: loggedInUser.autoFinanceUser.organizationId,
                reportingOfficerId: isAdmin() ? "" : loggedInUser.autoFinanceUser.userId,
                startDate: start,
                searchKey: searchKey
            };
            await moduleApi
                .postData(
                    moduleApi.withPage(apiCall.getFinalPayOutByEmployeeDetails, i, 100),
                    payload
                )
                .then((response) => {
                    tempList.push(...response.content);
                });
        }

        console.log("templist", tempList);

        tempList?.forEach((element) => {
            let data = {


                empName: element.empName ? element.empName : "-",
                empId: element.empId ? element.empId : "-",
                userId: element.userId ? element.userId : "-",
                branchId: element.branchId ? element.branchId : "-",
                branchName: element.branchName ? element.branchName : "-",
                reportingOfficerId: element.reportingOfficerId ? element.reportingOfficerId : "-",
                reportingOfficerName: element.reportingOfficerName ? element.reportingOfficerName : "-",
                totalAllocations: element.totalAllocations ? element.totalAllocations : 0,
                totalVisits: element.totalVisits ? element.totalVisits : 0,
                totalPaidVisits: element.totalPaidVisits ? element.totalPaidVisits : 0,
                targetAmount: element.targetAmount ? element.targetAmount : 0,
                totalCollectedAmount: element.totalCollectedAmount ? element.totalCollectedAmount : 0,
                totalIncentivesEligibleFor: element.totalIncentivesEligibleFor ? element.totalIncentivesEligibleFor : 0,
                totalIncentivesRecieved: element.totalIncentivesRecieved ? element.totalIncentivesRecieved : 0,
                totalAmountPayable: element.totalAmountPayable ? element.totalAmountPayable.toFixed(2) : 0,

            };
            finalPayoutListData.push(data);
        });
        setFinalPayoutDownloadList(finalPayoutListData);
    }


    const handleExport = () => {
        let finalPayoutTable = [
            {
                A: "Emp Name",
                B: "Emp Id",
                C: "Branch",
                D: "Reporting Manager",
                E: "Total Allocations",
                F: "Total Visits",
                G: "Total Paid Visits",
                H: "Target Amount",
                I: "Total Collected Amount",
                J: "Total Incentives Received",
                K: "Total Amount Payable",
            },
        ];

        finalPayoutDownloadList?.forEach((row) => {
            finalPayoutTable.push({
                A: row.empName,
                B: row.empId,
                C: row.branchName,
                D: row.reportingOfficerName,
                E: row.totalAllocations,
                F: row.totalVisits,
                G: row.totalPaidVisits,
                H: row.targetAmount,
                I: row.totalCollectedAmount,
                J: row.totalIncentivesRecieved,
                K: row.totalAmountPayable,
            });
        });

        //const finalData = [...finalPayoutTable];
        const finalData = [...finalPayoutTable];

        // Convert JSON data to CSV
        const csvData = finalData.map(row => 
          Object.values(row).map(value => `"${String(value).replace(/"/g, '""')}"`).join(",")
        ).join("\n");     

        // Create a Blob with the CSV content
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "Final_Payout_Details" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        ReactGA.event({
            category: "Final Payout List Download",
            action:
                loggedInUser.autoFinanceOrganization.orgshortcode +
                "_Final_Payout_Details_" +
                helper.EpochToDate(Math.floor(new Date() / 1000)) +
                ".csv",
        });

        //create a new workbook
        // const wb = XLSX.utils.book_new();

        // const sheet = XLSX.utils.json_to_sheet(finalData, {
        //     skipHeader: true,
        // });

        // console.log("sheet", sheet);

        // XLSX.utils.book_append_sheet(wb, sheet, "Final Payout Details");

        // XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

        // XLSX.writeFile(
        //     wb,
        //     "Final_Payout_Details" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx"
        // );
        // ReactGA.event({
        //     category: "Final Payout List Downlaod",
        //     action:
        //         loggedInUser.autoFinanceOrganization.orgshortcode +
        //         "_Final_Payout_Details_" +
        //         helper.EpochToDate(Math.floor(new Date() / 1000)) +
        //         ".xlsx",
        // });
    };

    return (
        <div className="card">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    //   marginTop: "20px",
                    marginBottom: "15px",
                    marginRight: "15px",
                }}
            >
            </div>
            <LoadingOverlay
                active={isLoading}
                // spinner
                text={
                    <p
                        style={{
                            color: "black",
                        }}
                    >
                        <div>
                            <Button
                                style={{
                                    backgroundColor: "#FFFF",
                                    color: "black",
                                    borderColor: "white",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                }}
                                variant="primary"
                                disabled
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="md"
                                    variant="primary"
                                    role="status"
                                    aria-hidden="true"
                                />
                                &nbsp;&nbsp;Loading...
                            </Button>
                        </div>
                    </p>
                }
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(192,192,192,0.4)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "80px",
                        "& svg circle": {
                            stroke: "rgb(136, 108, 192)",
                        },
                    }),
                }}

            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        marginBottom: "5px",
                        marginRight: "20px",
                    }}
                >
                    <div
                        style={{
                            float: "right",
                            flexDirection: "row",
                            marginRight: "10px",
                            marginTop: "22px",
                        }}
                    >
                        <SearchBar
                            id="finalPayoutSerach"
                            placeholder="Search by Emp Name/Emp Id"
                            searchKeyword={searchKeyword}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                        }}
                    >
                        <label style={{ fontWeight: "bold"}}>
                            Select Month
                        </label>
                        <Flatpickr
                            className="form-control d-block"
                            style={{
                                width: "170px",
                                height: "40px",
                                borderColor: "#a0a0a0",
                                borderWidth: "1px",
                                boxShadow: "1px 1px 2px #3a353b",
                                textAlignLast: "center",
                                marginBottom: "5px",
                            }}
                            placeholder="Select Month"
                            onChange={(value) => {
                                setCustomDatesForGraph(value);
                            }}
                            options={{
                                defaultDate: new Date(),
                                maxDate: new Date(),
                                minDate: new Date().setMonth(new Date().getMonth() - 6),

                                plugins: [
                                    new monthSelectPlugin({
                                        shorthand: true, //defaults to false
                                        dateFormat: "F Y", //defaults to "F Y"
                                        altFormat: "F Y", //defaults to "F Y"
                                        theme: "light", // defaults to "light"
                                    }),
                                ],
                            }}
                        />
                    </div>
                    <Button
                        style={{
                            marginRight: "10px",
                            marginLeft: "7px",
                            marginBottom: "5px",
                            marginTop: "22px",
                            fontFamily:
                                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                        }}
                        className="me-2"
                        variant="success btn-rounded"
                        onClick={() => finalPayoutDownloadData()}
                    >
                        <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                    </Button>
                </div>
                <div
                    style={{
                        background: "white",
                        margin: "10px",
                        marginTop: "-5px",
                        overflowX: "auto",
                    }}
                >

                    <div
                        style={{
                            overflow: "auto",
                            maxHeight: "500px",
                        }}
                    >

                        <BootstrapTable
                            keyField="panNo"
                            data={finalPayoutList}
                            columns={columns}
                        />

                    </div>

                    {finalPayoutList.length === 0 && finalPayoutList.length === 0 && (
                        <div className="d-flex justify-content-center">
                            <img src={NO_DATA_FOUND} alt="" />
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                        }}
                    >
                        <PaginationComponent
                            totalItems={totalElements}
                            pageSize={10}
                            defaultActivePage={currPage + 1}
                            onSelect={onPageChange}
                        />
                    </div>
                </div>
            </LoadingOverlay>
            <Modal className="fade" show={basicModal} size="lg">
                <Modal.Header>
                    <Modal.Title>Final Payout Details For{" "}
                        {
                            <span style={{ fontWeight: "bold" }}>
                                {finalPayoutDetails.empName}
                                {" "} {"(" + finalPayoutDetails.empId + ")"}
                            </span>
                        }</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setBasicModal(false)}
                    ></Button>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: "0px" }}>
                    <div style={{ margin: "10px" }}>

                        <LoadingOverlay
                            active={isLoading}
                            // spinner
                            text={
                                <p
                                    style={{
                                        color: "black",
                                    }}
                                >
                                    <div>
                                        <Button
                                            style={{
                                                backgroundColor: "#FFFF",
                                                color: "black",
                                                borderColor: "white",
                                                fontWeight: "bold",
                                                fontSize: "16px",
                                            }}
                                            variant="primary"
                                            disabled
                                        >
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="md"
                                                variant="primary"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            &nbsp;&nbsp;Loading...
                                        </Button>
                                    </div>
                                </p>
                            }
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "rgba(192,192,192,0.4)",
                                }),
                                spinner: (base) => ({
                                    ...base,
                                    width: "80px",
                                    "& svg circle": {
                                        stroke: "rgb(136, 108, 192)",
                                    },
                                }),
                            }}
                        >
                            <div style={{ background: "white", margin: "10px", marginTop: "-5px" }}>
                                <div style={{ overflow: 'auto', maxHeight: '400px' }}>
                                    <BootstrapTable
                                        keyField="panNo"
                                        data={incentivesList}
                                        columns={incentiveColumns}
                                    />
                                </div>
                                {incentivesList.length === 0 && (
                                    <div className="d-flex justify-content-center">
                                        <img src={NO_DATA_FOUND} alt="" />
                                    </div>
                                )}
                                <div style={{ display: "flex", justifyContent: "left" }}>
                                    <PaginationComponent
                                        totalItems={totalElements2}
                                        pageSize={10}
                                        defaultActivePage={currPage2 + 1}
                                        onSelect={onPageChange2}
                                    />
                                </div>
                            </div>
                        </LoadingOverlay>

                        {incentivesList.length >= 1 && incentivesList[0].incentiveTargetStatus !== "PENDING" && incentivesList[0].firstApprovalDone === false && !hasAccess(72) && (
                            <div>
                                <Button
                                    style={{
                                        float: "left",
                                        marginTop: "10px",
                                        marginRight: "25px",
                                        fontFamily:
                                            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                    }}
                                    className="me-2"
                                    variant="success btn-rounded"
                                    onClick={() => updateApprovalStatus("APPROVED", false)}
                                >
                                    Approve
                                </Button>
                                <Button
                                    style={{
                                        float: "left",
                                        marginTop: "10px",
                                        marginRight: "15px",
                                        fontFamily:
                                            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                    }}
                                    className="me-2"
                                    variant="danger btn-rounded"
                                    onClick={() => {
                                        // setRejectionReason(null);
                                        // setBasicModal2(true);
                                        updateApprovalStatus("REJECTED", false)
                                    }}
                                >
                                    Reject
                                </Button>
                            </div>
                        )}
                        {incentivesList.length >= 1 && incentivesList[0].firstApprovalDone === true && incentivesList[0].secondApprovalDone === false && hasAccess(72) && (
                            <div>
                                <Button
                                    style={{
                                        float: "left",
                                        marginTop: "10px",
                                        marginRight: "25px",
                                        fontFamily:
                                            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                    }}
                                    className="me-2"
                                    variant="success btn-rounded"
                                    onClick={() => updateApprovalStatus("APPROVED", true)}
                                >
                                    Approve
                                </Button>
                                <Button
                                    style={{
                                        float: "left",
                                        marginTop: "10px",
                                        marginRight: "15px",
                                        fontFamily:
                                            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                    }}
                                    className="me-2"
                                    variant="danger btn-rounded"
                                    onClick={() => {
                                        // setRejectionReason(null);
                                        // setBasicModal2(true);
                                        updateApprovalStatus("REJECTED", true)
                                    }}
                                >
                                    Reject
                                </Button>
                            </div>
                        )}
                        {incentivesList.length >= 1 && hasAccess(73) && incentivesList[0].secondApprovalDone === true && incentivesList[0].amountApprovalDone === false && (
                            <div>
                                <Button
                                    style={{
                                        float: "left",
                                        marginTop: "10px",
                                        marginRight: "25px",
                                        fontFamily:
                                            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                    }}
                                    className="me-2"
                                    variant="success btn-rounded"
                                    onClick={() => updateAmountCreditedStatus()}
                                >
                                    Approve Incentive
                                </Button>
                            </div>
                        )}

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FinalPayoutDetails;
