import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import * as Yup from "yup";
import TextField from "../CommonComponents/TextField";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import location_image from "../images/location_image.png";
// import call_image from "../images/call.png";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";
import ReactGA from "react-ga";
// import { makeCall } from "../CommonComponents/Helper";

function CustomerDetails({ visitedPlacesDetailsList, reloadVisitData, close }) {
  const [isMap, setIsMap] = useState(false);
  const [loanModal, setLoanModal] = useState(false);
  const [random] = useState(Math.floor(Math.random() * 100));
  const [collectStatusCount, setCollectionStatusCount] = useState();
  const [rejectionReason, setRejectionReason] = useState(null);
  const [basicModal, setBasicModal] = useState(false);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    var userData = JSON.parse(userLoginData);
    //  console.log(userLoginData);
    var payLoad1 = {
      loanNumber: visitedPlacesDetailsList?.collectionVisit?.loanNumber,
      orgId:
        userData?.autoFinanceUser?.roleName !== "CP_USER"
          ? userData?.autoFinanceUser?.organizationId
          : userData?.autoFinanceOrganization?.mainOrganizationId,
    };
    if (payLoad1) {
      visitedPlacesDetailsList?.collectionVisit &&
        moduleApi
          .postData(apiCall.getCustomerSummaryCount, payLoad1)
          .then((response) => {
            // console.log(response);
            setCollectionStatusCount(response);
          });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getRandomColor() {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  }

  useEffect(() => {
    ReactGA.pageview("Customer-Details");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOk = (values) => {
    var payLoad = {
      collectionId: visitedPlacesDetailsList.collectionVisit?.id,
      loanNumber: values.loanNo,
      visitId: visitedPlacesDetailsList.id,
    };
    moduleApi.postData(apiCall.updateLoanNo, payLoad).then((response) => {
      if (response) {
        if (response?.Message) {
          swal("Loan no Updated Successfully", {
            icon: "success",
          });
          setLoanModal(false);
        } else if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
      // toaster("success", "Loan no updated");
      // setLoanModal(false);
    });
  };
  const validate = Yup.object({
    loanNo: Yup.string().required("Loan No is required"),
  });

  const updateFiApprovalStatus = (status) => {
    console.log("status", status);
    if (status === "REJECTED" && rejectionReason === null) {
      return swal("Please Enter Rejection Reason", {
        icon: "error",
        dangerMode: true,
      });
    }

    var body = {
      approvalStatus: status,
      rejectionReason: rejectionReason,
      visitId: visitedPlacesDetailsList?.id,
    };

    moduleApi
      .postData(apiCall.updateFiApprovalStatus, body)
      .then((response) => {
        if (response) {
          if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.Message === "FI Status Updated Successfully") {
            setBasicModal(false);
            reloadVisitData();
            close()
            // setBasicModal3(false);
            // getCashDepositionList(start, end, 0);
            swal("Status Updated Successfully", {
              icon: "success",
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  return (
    <div>
      <Row>
        <Col>
          <div
            className="card contact-bx item-content"
            style={{
              backgroundColor: "rgb(243,240,249)",
            }}
          >
            <div
              style={{
                marginRight: "20px",
                marginBottom: "10px",
                marginTop: "30px",
                marginLeft: "20px",
              }}
            >
              {visitedPlacesDetailsList.collectionVisit && (
                <>
                  <tr></tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.collectionVisit.customerName
                        ? visitedPlacesDetailsList.collectionVisit.customerName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Mobile
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.collectionVisit.contactNumber
                        ? visitedPlacesDetailsList.collectionVisit.contactNumber
                        : "-"}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Loan Number
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.collectionVisit.loanNumber
                        ? visitedPlacesDetailsList.collectionVisit.loanNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Last Updated Status
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.status}
                    </td>
                  </tr>
                </>
              )}

              {visitedPlacesDetailsList.salesVisit && (
                <>
                  <tr></tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.salesVisit.customerName
                        ? visitedPlacesDetailsList.salesVisit.customerName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Mobile
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.salesVisit.contactNumber
                        ? visitedPlacesDetailsList.salesVisit.contactNumber
                        : "-"}{" "}
                    </td>
                  </tr>
                </>
              )}

              {visitedPlacesDetailsList.fieldInvestigationVisit && (
                <>
                  <tr></tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        width: "30%",
                      }}
                    >
                      Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.fieldInvestigationVisit
                        .customerName
                        ? visitedPlacesDetailsList.fieldInvestigationVisit
                            .customerName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Mobile
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.fieldInvestigationVisit
                        .contactNumber
                        ? visitedPlacesDetailsList.fieldInvestigationVisit
                            .contactNumber
                        : "-"}{" "}
                      {/* &nbsp;{" "}
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              makeCall(
                                visitedPlacesDetailsList.fieldInvestigationVisit
                                  ?.contactNumber
                              );
                            }}
                            src={call_image}
                            alt=""
                            className="img-fluid rounded-circle"
                          /> */}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Last Updated Status
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.fieldInvestigationVisit.status
                        ? visitedPlacesDetailsList.fieldInvestigationVisit
                            .status
                        : "-"}
                    </td>
                  </tr>
                </>
              )}

              <tr>
                <td style={{ fontWeight: "500", textAlign: "left" }}>
                  Last Visited On
                </td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {visitedPlacesDetailsList.dateTime
                    ? visitedPlacesDetailsList.dateTime
                    : "-"}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Address
                </td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                <td
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      {visitedPlacesDetailsList.address
                        ? visitedPlacesDetailsList.address
                        : "-"}{" "}
                    </div>
                    <img
                      style={{
                        height: "40px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsMap(true)}
                      src={location_image}
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                  </div>
                </td>
              </tr>             
              {/* {visitedPlacesDetailsList.fieldInvestigationVisit &&
                visitedPlacesDetailsList.fieldInvestigationVisit
                  ?.approvalStatus === "PENDING" && (
                  <div>
                    <Button
                      style={{
                        float: "left",
                        marginTop: "10px",
                        marginRight: "25px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="success btn-rounded"
                      onClick={() => updateFiApprovalStatus("APPROVED")}
                    >
                      Approve
                    </Button>
                    <Button
                      style={{
                        float: "left",
                        marginTop: "10px",
                        marginRight: "15px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="danger btn-rounded"
                      onClick={() => {
                        setRejectionReason(null);
                        setBasicModal(true);
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                )} */}
            </div>
          </div>
        </Col>

        <Col>
          {collectStatusCount ? (
            <div
              className="card contact-bx item-content"
              style={{
                backgroundColor: "rgb(243,240,249)",
              }}
            >
              <h6 className="fs-18 font-w600 my-1">Status</h6>

              <div style={{ margin: "5%", marginTop: "1%" }}>
                <>
                  <Table responsive="sm">
                    <tbody>
                      {/* <tr>
                        <td style={{ borderColor: "grey", textAlign: "left" }}>
                          {" "}
                          Paid Count{" "}
                        </td>
                        <td style={{ borderColor: "grey" }}>
                          <div
                            style={{
                              borderColor: "grey",
                              backgroundColor: "#44d144",
                              borderRadius: "10px",
                              width: "40px",
                              height: "26px",
                              padding: "4px",
                              marginLeft: "auto",
                              color: "white",
                            }}
                          >
                            <b>
                              {" "}
                              {collectStatusCount?.paidVisitCount
                                ? collectStatusCount?.paidVisitCount
                                : "0"}
                            </b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderColor: "grey", textAlign: "left" }}>
                          {" "}
                          PTP Count{" "}
                        </td>
                        <td style={{ borderColor: "grey" }}>
                          <div
                            style={{
                              borderColor: "grey",
                              backgroundColor: "#0f7ce7",
                              borderRadius: "10px",
                              width: "40px",
                              height: "26px",
                              padding: "4px",
                              marginLeft: "auto",
                              color: "white",
                            }}
                          >
                            <b>
                              {collectStatusCount?.ptpVisitCount
                                ? collectStatusCount?.ptpVisitCount
                                : "0"}
                            </b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderColor: "grey", textAlign: "left" }}>
                          {" "}
                          Customer Not Available Count{" "}
                        </td>
                        <td style={{ borderColor: "grey" }}>
                          <div
                            style={{
                              borderColor: "grey",
                              backgroundColor: "red",
                              borderRadius: "10px",
                              width: "40px",
                              height: "26px",
                              padding: "4px",
                              marginLeft: "auto",
                              color: "white",
                            }}
                          >
                            {" "}
                            <b>
                              {collectStatusCount?.custNotAvailableVisitCount
                                ? collectStatusCount?.custNotAvailableVisitCount
                                : "0"}
                            </b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderColor: "grey", textAlign: "left" }}>
                          {" "}
                          Repossessed Count{" "}
                        </td>
                        <td style={{ borderColor: "grey" }}>
                          <div
                            style={{
                              borderColor: "grey",
                              backgroundColor: "#2C3E50",
                              borderRadius: "10px",
                              width: "40px",
                              height: "26px",
                              padding: "4px",
                              marginLeft: "auto",
                              color: "white",
                            }}
                          >
                            <b>
                              {" "}
                              {collectStatusCount?.repossessedVisitCount
                                ? collectStatusCount?.repossessedVisitCount
                                : "0"}
                            </b>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ borderColor: "grey", textAlign: "left" }}>
                          {" "}
                          Needs Attention Count{" "}
                        </td>
                        <td style={{ borderColor: "grey" }}>
                          <div
                            style={{
                              borderColor: "grey",
                              backgroundColor: "#C0392B",
                              borderRadius: "10px",
                              width: "40px",
                              height: "26px",
                              padding: "4px",
                              marginLeft: "auto",
                              color: "white",
                            }}
                          >
                            <b>
                              {collectStatusCount?.needsAttentionVisitCount
                                ? collectStatusCount?.needsAttentionVisitCount
                                : "0"}
                            </b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderColor: "grey", textAlign: "left" }}>
                          {" "}
                          Third Party Count{" "}
                        </td>
                        <td style={{ borderColor: "grey" }}>
                          <div
                            style={{
                              borderColor: "grey",
                              backgroundColor: "#632df3",
                              borderRadius: "10px",
                              width: "40px",
                              height: "26px",
                              padding: "4px",
                              marginLeft: "auto",
                              color: "white",
                            }}
                          >
                            <b>
                              {collectStatusCount?.thirdPartyVisitCount
                                ? collectStatusCount?.thirdPartyVisitCount
                                : "0"}
                            </b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderColor: "grey", textAlign: "left" }}>
                          {" "}
                          Already Settled Count{" "}
                        </td>
                        <td style={{ borderColor: "grey" }}>
                          <div
                            style={{
                              borderColor: "grey",
                              backgroundColor: "#9B59B6",
                              borderRadius: "10px",
                              width: "40px",
                              height: "26px",
                              padding: "4px",
                              marginLeft: "auto",
                              color: "white",
                            }}
                          >
                            {" "}
                            <b>
                              {collectStatusCount?.alreadySettledVisitCount
                                ? collectStatusCount?.alreadySettledVisitCount
                                : "0"}
                            </b>
                          </div>
                        </td>
                      </tr> */}

{Object.entries(collectStatusCount.collectionStatusCounts).map(([status, count]) => {
            const randomColor = getRandomColor(); 
            return (
              <tr key={status}>
                <td style={{ borderColor: "grey", textAlign: "left" }}>
                  {status} Count
                </td>
                <td style={{ borderColor: "grey" }}>
                  <div
                    style={{
                      borderColor: "grey",
                      backgroundColor: randomColor,
                      borderRadius: "10px",
                      width: "40px",
                      height: "26px",
                      padding: "4px",
                      marginLeft: "auto",
                      color: "white",
                    }}
                  >
                    <b>{count}</b>
                  </div>
                </td>
              </tr>
            );
          })}
                    </tbody>
                  </Table>
                </>
              </div>
            </div>
          ) : (
            <div
              className="card contact-bx item-content"
              style={{
                backgroundColor: "rgb(243,240,249)",
              }}
            >
              <div
                style={{
                  marginRight: "20px",
                  marginBottom: "10px",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              >
                <h6 className="fs-18 font-w600 my-1">Last Visited Executive</h6>
              </div>

              <>
                <div
                  style={{
                    marginLeft: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <tr></tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Name
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.name
                        ? visitedPlacesDetailsList.name
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Mobile
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.mobileNumber
                        ? visitedPlacesDetailsList.mobileNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Email
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.email
                        ? visitedPlacesDetailsList.email
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Role Name
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      {visitedPlacesDetailsList.roleName
                        ? visitedPlacesDetailsList.roleName
                        : "-"}
                    </td>
                  </tr>
                </div>
              </>
            </div>
          )}
        </Col>
      </Row>

      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Visit Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap
            latitide={visitedPlacesDetailsList.latitude}
            longitude={visitedPlacesDetailsList.longitude}
            key={random}
          />
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={basicModal} size="sm">
        <Modal.Header>
          <Modal.Title>Add Rejection Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
              <input
                style={{
                  width: "220px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter Reason"
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                  console.log("rejectionReason", rejectionReason);
                }}
              />
              <div style={{ marginTop: "15px", marginBottom: "-10px" }}>
                <div>
                  <Button
                    style={{ float: "right" }}
                    className="me-2"
                    variant="primary btn-rounded"
                    type="submit"
                    onClick={() => updateFiApprovalStatus("REJECTED")}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right" }}
                    className="me-2"
                    variant="danger light btn-rounded"
                    onClick={() => setBasicModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={loanModal}>
        <Modal.Header>
          <Modal.Title>Update Loan No.</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setLoanModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                loanNo: visitedPlacesDetailsList.collectionVisit?.loanNumber,
              }}
              onSubmit={handleOk}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <TextField
                            isAsterisk={true}
                            label="Loan No"
                            name="loanNo"
                            type="text"
                          />
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right" }}
                            className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => setLoanModal(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomerDetails;
