import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import TextField from "../CommonComponents/TextField";
// import { toaster } from "../rest/apiUtils";
import * as moduleApi from "../rest/moduleApi";
import swal from "sweetalert";
import { apiCall } from "../rest/restApi";
import ReactGA from "react-ga";

const AddExecutive = ({ close, role }) => {
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const phoneRegExp = "[1-9]{1}[0-9]{9}";
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const [managers, setManagers] = useState([]);
  const [loggedInUser, setloggedInUser] = useState([]);
  const [branchNames, setBranchNames] = useState(null);
  const [branches, setBranches] = useState([]);
  const [designations, setDesignations] = useState([]);
  // const [states, setStates] = useState([]);
  // const [error, setError] = useState(null);

  useEffect(() => {
    ReactGA.pageview("Add Executive");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);

    const body = {
      organizationId: userData.autoFinanceUser.organizationId,
    };
    moduleApi.postData(apiCall.getBranches, body).then((response) => {
      const result = response.map((item) => ({
        branchId: item.branchId,
        branchName: item.branchName,
      }));
      // console.log('resuly', result);
      setBranchNames(result);
    });

     //Designations List
     moduleApi
     .getData(
       apiCall.getDesignations + userData.autoFinanceUser.organizationId
     )
     .then((response) => {
       let designation = [];
       for (let i = 0; i < response.length; i++) {
         const result = {
           value: response[i],
           label: response[i],
         };
         designation.push(result);
       }
       setDesignations(designation);
     });

    // fetchData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    const payLoad = {
      branchId: "",
      organizationId: userData.autoFinanceUser.organizationId,
      roleName: role !== "MANAGER_RA" ? "MANAGER" : "ADMIN_MANAGER",
    };
    moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.userId,
        label: item.name + " - " + getBranch(item.branchId),
        branchId: item.branchId,
      }));
      setManagers(result);
    });

    // eslint-disable-next-line
  }, [branchNames]);

  const getBranch = (branchId) => {
    for (let i = 0; i < branchNames?.length; i++) {
      if (branchNames[i].branchId === branchId) {
        // console.log("branchName", branchNames[i].branchName);
        return branchNames[i].branchName;
      }
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://countriesnow.space/api/v0.1/countries/states",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           country: "India",
  //         }),
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch states");
  //     }
  //     const data = await response.json();
  //     console.log("resposse", data?.data?.states);
  //     const result = data?.data?.states.map((item) => ({
  //       value: item.name,
  //       label: item.name,
  //     }));
  //     setStates(result);
  //     console.log(error);
  //   } catch (error) {
  //     setError(error.message);
  //   }
  // };

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);

    const payLoad = {
      searchKey: "",
      organizationId: userData.autoFinanceUser.organizationId,
      pagination: false,
    };
    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
        branchName: item.branchName,
      }));
      setBranches(result);
    });
  }, []);

  // var namePattern = "^[a-zA-Z]";
  var nameRegex = "[a-zA-Z ]+$";
  var specialCharacterEmployeeIdPattern = "^[a-zA-Z0-9]+$";
  const validate = Yup.object({
    email: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required"),
    alternateMobileNumber: Yup.string()
      .matches(phoneRegExp, "Alternate Mobile number is not valid")
      .min(10, "Alternate Mobile number should be 10 digits")
      .max(10, "Alternate Mobile number should be 10 digits"),
    employeeId: Yup.string()
      .matches(
        specialCharacterEmployeeIdPattern,
        "Please Enter Valid Employee ID"
      )
      .required("Employee ID is required"),
    name: Yup.string()
      .trim()
      .matches(nameRegex, "Please Enter a Valid Name")
      .required("Name is required"),
    companyDesignation: Yup.string()
      .trim()
      .required("Designation is required"),
    branchId: Yup.string().required("Branch is required"),
    // state: Yup.string().required("State is required"),
    reportingOfficerId: Yup.string().when([], {
      is: () => loggedInUser.autoFinanceUser.roleName === "ADMIN",
      then: Yup.string().required("Reporting officer is required"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const isManager = () => {
    return loggedInUser.autoFinanceUser.roleName === "MANAGER";
  };

  const handleOk = (values) => {
    const params = {
      addedByUserId: loggedInUser.autoFinanceUser.userId,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
    };
    values = { ...values, ...params };

    const managerDetails = {
      reportingOfficerId: loggedInUser.autoFinanceUser.userId,
    };
    if (isManager()) values = { ...values, ...managerDetails };

    moduleApi.postData(apiCall.saveExecutive, values).then((response) => {
      if (response) {
        if (response?.Message === "User Added SuccessFully") {
          close();
          // toaster('success', 'Successfully Added')
          swal("User Added SuccessFully", {
            icon: "success",
          });
        } else if (response?.message) {
          // toaster("error", response?.message);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "500px",
      }}
    >
      <Formik
        initialValues={{
          addedByUserId: "",
          branchId: "",
          email: "",
          employeeId: "",
          image: "",
          mobileNumber: "",
          alternateMobileNumber: "",
          name: "",
          area: "",
          state: "",
          organizationId: "",
          reportingOfficerId: "",
          roleName: role,
          companyDesignation: "",
        }}
        onSubmit={handleOk}
        validationSchema={validate}
      >
        {({ formik, errors }) => (
          <div>
            <Row>
              <Col lg="12">
                <Form>
                  <div>
                    {loggedInUser.autoFinanceUser?.roleName === "ADMIN" && (
                      <Field name="reportingOfficerId">
                        {({ field, form }) => (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label
                              className="control-label"
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                marginBottom: "5px",
                                marginTop: "5px",
                              }}
                            >
                              Reporting Officer
                            </label>
                            <Select
                              placeholder="Select Reporting Officer"
                              classNamePrefix="select-box"
                              options={managers}
                              onChange={(selectedOption) => {
                                form.setFieldValue(
                                  "reportingOfficerId",
                                  selectedOption.value
                                );
                                form.setFieldValue(
                                  "reportingOfficerId",
                                  selectedOption.value
                                );
                                // getBranchName(selectedOption.branchId)
                              }}
                            />
                          </div>
                        )}
                      </Field>
                    )}
                    {errors.reportingOfficerId && (
                      <span className="error">{errors.reportingOfficerId}</span>
                    )}
                    {/* <Field name="state">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            State
                          </label>
                          <Select
                            placeholder="Select State"
                            classNamePrefix="select-box"
                            options={states}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "state",
                                selectedOption.value
                              );
                              
                            }}
                          />
                        </div>
                      )}
                    </Field>

                    {errors.state && (
                      <span className="error">{errors.state}</span>
                    )} */}
                    

                    <Field name="branchId">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Branch
                          </label>
                          <Select
                            placeholder="Select branch"
                            classNamePrefix="select-box"
                            options={branches}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "branchId",
                                selectedOption.branchId
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>

                    {errors.branchId && (
                      <span className="error">{errors.branchId}</span>
                    )}
                    <TextField
                      isAsterisk={false}
                      label="Area"
                      name="area"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Name"
                      name="name"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Employee ID"
                      name="employeeId"
                      type="text"
                    />
                    {/* <TextField
                      isAsterisk={true}
                      label="Designation"
                      name="companyDesignation"
                      type="text"
                    /> */}
                    <Field name="companyDesignation">
                      {({ field, form }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          <label
                            className="control-label"
                            style={{
                              fontWeight: "bold",
                              float: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Designation
                          </label>
                          <Select
                            placeholder="Select designation"
                            classNamePrefix="select-box"
                            options={designations}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "companyDesignation",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      )}
                    </Field>
                    {errors.companyDesignation && (
                      <span className="error">{errors.companyDesignation}</span>
                    )}
                    <TextField
                      isAsterisk={true}
                      label="Mobile No"
                      name="mobileNumber"
                      maxlength="10"
                      type="text"
                    />
                    <TextField
                      isAsterisk={false}
                      label="Alternate Mobile No"
                      name="alternateMobileNumber"
                      maxlength="10"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Email ID"
                      name="email"
                      type="text"
                    />
                  </div>
                  <div>
                    <Button
                      style={{ float: "right" }}
                      className="me-2"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="me-2"
                      variant="danger light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddExecutive;

// import { Field, Form, Formik } from "formik";
// import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import Select from "react-select";
// import { Col, Row } from "reactstrap";
// import * as Yup from "yup";
// import TextField from "../CommonComponents/TextField";
// // import { toaster } from "../rest/apiUtils";
// import * as moduleApi from "../rest/moduleApi";
// import swal from "sweetalert";
// import { apiCall } from "../rest/restApi";

// const AddExecutive = ({ close }) => {
//   // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
//   const phoneRegExp = "[1-9]{1}[0-9]{9}";
//   var emailPattern =
//     /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
//   const [managers, setManagers] = useState([]);
//   const [loggedInUser, setloggedInUser] = useState([]);
//   const [branchNames,setBranchNames] = useState(null);

//   useEffect(() => {
//     var userLoginData = localStorage.getItem("userDetails");
//     if (!userLoginData) return;
//     var userData = JSON.parse(userLoginData);
//     setloggedInUser(userData);

//     const body = {
//       organizationId: userData.autoFinanceUser.organizationId,
//     };
//     moduleApi.postData(apiCall.getBranches, body).then((response) => {
//       const result = response.map((item) => ({
//         branchId: item.branchId,
//         branchName: item.branchName,
//       }));
//       // console.log('resuly', result);
//       setBranchNames(result);
//     });

//     // eslint-disable-next-line
//   }, []);

//   useEffect(() => {
//     var userLoginData = localStorage.getItem("userDetails");
//     if (!userLoginData) return;
//     var userData = JSON.parse(userLoginData);
//     const payLoad = {
//       branchId: "",
//       organizationId: userData.autoFinanceUser.organizationId,
//       roleName: "MANAGER",
//     };
//     moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
//       const result = response.map((item) => ({
//         value: item.userId,
//         label: item.name + " - " + getBranch(item.branchId),
//         branchId: item.branchId,
//       }));
//       setManagers(result);
//     });

// // eslint-disable-next-line
//   }, [branchNames])

//   const getBranch = (branchId) => {
//     for (let i = 0; i < branchNames?.length; i++) {
//       if (branchNames[i].branchId === branchId) {
//         console.log('branchName', branchNames[i].branchName);
//         return branchNames[i].branchName;
//       }
//     }
//   };

//   var namePattern = "^[a-zA-Z]";
//   var nameRegex = "[a-zA-Z ]+$";
//   var specialCharacterEmployeeIdPattern = "^[a-zA-Z0-9]+$";
//   const validate = Yup.object({
//     email: Yup.string()
//       .matches(emailPattern, "Email is invalid")
//       .required("Email is required"),
//     mobileNumber: Yup.string()
//       .matches(phoneRegExp, "Mobile number is not valid")
//       .min(10, "Mobile number should be 10 digits")
//       .max(10, "Mobile number should be 10 digits")
//       .required("Mobile number is required"),
//     employeeId: Yup.string()
//       .matches(
//         specialCharacterEmployeeIdPattern,
//         "Please Enter Valid Employee ID"
//       )
//       .required("Employee ID is required"),
//     name: Yup.string()
//       .trim()
//       .matches(nameRegex, "Please Enter a Valid Name")
//       .required("Name is required"),
//     companyDesignation: Yup.string()
//       .matches(namePattern, "Please Enter a Valid Designation")
//       .required("Designation is required"),
//     branchId: Yup.string().when([], {
//       is: () => loggedInUser.autoFinanceUser.roleName === "ADMIN",
//       then: Yup.string().required("Manager is required"),
//       otherwise: Yup.string().notRequired(),
//     }),
//   });

//   const isManager = () => {
//     return loggedInUser.autoFinanceUser.roleName === "MANAGER";
//   };

//   const handleOk = (values) => {
//     const params = {
//       addedByUserId: loggedInUser.autoFinanceUser.userId,
//       organizationId: loggedInUser.autoFinanceUser.organizationId,
//     };
//     values = { ...values, ...params };

//     const managerDetails = {
//       branchId: loggedInUser.autoFinanceUser.branchId,
//       reportingOfficerId: loggedInUser.autoFinanceUser.userId,
//     };
//     if (isManager()) values = { ...values, ...managerDetails };

//     moduleApi.postData(apiCall.saveExecutive, values).then((response) => {
//       if(response){
//         if(response?.Message==='User Added SuccessFully'){
//             close();
//             // toaster('success', 'Successfully Added')
//             swal(
//                 "Successfully Added",
//                 {
//                   icon: "success",
//                 }
//               );
//         }else if (response?.message) {
//           // toaster("error", response?.message);
//           swal(response?.message, {
//             icon: "error",
//             dangerMode:true
//           });
//         }
//    }else {
//     return  swal("Something Went Wrong, Please Try Later", {
//       icon: "error",
//       dangerMode:true
//     });
//    }
//     });
//   };

//   return (
//     <div>
//       <Formik
//         initialValues={{
//           addedByUserId: "",
//           branchId: "",
//           email: "",
//           employeeId: "",
//           image: "",
//           mobileNumber: "",
//           name: "",
//           organizationId: "",
//           reportingOfficerId: "",
//           roleName: "EXECUTIVE",
//           companyDesignation: "",
//         }}
//         onSubmit={handleOk}
//         validationSchema={validate}
//       >
//         {({ formik, errors }) => (
//           <div>
//             <Row>
//               <Col lg="12">
//                 <Form>
//                   <div>
//                     {loggedInUser.autoFinanceUser?.roleName === "ADMIN" && (
//                       <Field name="branchId">
//                         {({ field, form }) => (
//                           <div
//                             style={{ display: "flex", flexDirection: "column" }}
//                           >
//                             <label
//                               className="control-label"
//                               style={{
//                                 fontWeight: "bold",
//                                 float: "left",
//                                 marginBottom: "10px",
//                               }}
//                             >
//                               Manager
//                             </label>
//                             <Select
//                               placeholder="Select a manager"
//                               classNamePrefix="select-box"
//                               options={managers}
//                               onChange={(selectedOption) => {
//                                 form.setFieldValue(
//                                   "reportingOfficerId",
//                                   selectedOption.value
//                                 );
//                                 form.setFieldValue(
//                                   "branchId",
//                                   selectedOption.branchId
//                                 );
//                                 // getBranchName(selectedOption.branchId)
//                               }}
//                             />
//                           </div>
//                         )}
//                       </Field>
//                     )}
//                     {errors.branchId && (
//                       <span className="error">{errors.branchId}</span>
//                     )}
//                     <TextField
//                       isAsterisk={true}
//                       label="Name"
//                       name="name"
//                       type="text"
//                     />
//                     <TextField
//                       isAsterisk={true}
//                       label="Employee ID"
//                       name="employeeId"
//                       type="text"
//                     />
//                     <TextField
//                       isAsterisk={true}
//                       label="Designation"
//                       name="companyDesignation"
//                       type="text"
//                     />
//                     <TextField
//                       isAsterisk={true}
//                       label="Mobile No"
//                       name="mobileNumber"
//                       type="text"
//                     />
//                     <TextField
//                       isAsterisk={true}
//                       label="Email ID"
//                       name="email"
//                       type="text"
//                     />
//                   </div>
//                   <div>
//                     <Button
//                       style={{ float: "right" }}
//                       className="col-lg-2 m-2"
//                       type="submit"
//                     >
//                       Submit
//                     </Button>
//                     <Button
//                       style={{ float: "right" }}
//                       className="col-lg-2 m-2 float-right"
//                       variant="danger light"
//                       onClick={close}
//                     >
//                       Close
//                     </Button>
//                   </div>
//                 </Form>
//               </Col>
//             </Row>
//           </div>
//         )}
//       </Formik>
//     </div>
//   );
// };

// export default AddExecutive;
