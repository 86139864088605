import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import * as moduleApi from "../rest/moduleApi";
// import PaginationComponent from "react-reactstrap-pagination";
// import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import { apiCall } from "../rest/restApi";
// import Tooltip from "react-bootstrap/Tooltip";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
// import Select from "react-select";
// import { Link } from "react-router-dom";
import TextField from "../CommonComponents/TextField";
import ReactGA from "react-ga";
// import { hasAccess } from "../services/AuthService";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
// import ReactDataGrid from "@inovua/reactdatagrid-community";
// import "@inovua/reactdatagrid-community/index.css";
// import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
// import { EpochToDate } from "../CommonComponents/Helper";
// import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
// import { Query } from "@syncfusion/ej2-data";
// import { DataUtil } from "@syncfusion/ej2-data";
// import { DataManager, ODataAdaptor } from "@syncfusion/ej2-data";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Selection,
  Filter,
  Inject,
} from "@syncfusion/ej2-react-grids";
// import CallSummary from "../CustomerManagement/CallSummary";
// import LoanNumberDetails from "./LoanNumberDetails";
// import AddRemarks from "./AddRemarks";
// import {
//   setCallAudioModal,
//   setIsModalMinimised,
// } from "../store/actions/CallingActions";
// // import CallingEventHandlers from "../CommonComponents/CallingMethods";
// import { StartCall } from "../CommonComponents/TelecallingMethods";
// import PIOPIY from "piopiyjs";

// import { useCalling } from "../CommonComponents/CallingContext";

// const gridStyle = { minHeight: 500 };
// let gridInstance;
const filterSettings = { type: "Menu" };
// const format = { type: "datetime", format: "M/d/y hh:mm a" };
// const filterType = [
//   { text: "Menu", value: "Menu" },
//   { text: "Checkbox", value: "CheckBox" },
//   { text: "Excel", value: "Excel" },
// ];
// const fields = { text: "text", value: "value" };
// const defaultFilterValue = [
//   { name: "customerName", operator: "startsWith", type: "string", value: "" },
//   { name: "contactNumber1", operator: "startsWith", type: "string", value: "" },
//   {
//     name: "latestPaymentLinkStatus",
//     operator: "startsWith",
//     type: "string",
//     value: "",
//   },
//   { name: "branchName", operator: "startsWith", type: "string", value: "" },
//   { name: "loanNumber", operator: "startsWith", type: "string", value: "" },
//   {
//     name: "dueAmount",
//     operator: "gte",
//     type: "number",
//     value: 0,
//   },
// ];
// const emptyText = (
//   <div className="d-flex justify-content-center">
//     <img src={NO_DATA_FOUND} alt="" />
//   </div>
// );
// const defaultSortInfo = { name: "customerName", type: "string", dir: 1 };
function RequestPayments() {
  // const {
  // isMuted,
  // isCallActive,
  // isCallAnswered,
  // isCallRejected,
  // isHold,
  // isMute,
  // isMobileField,
  // isOnGoingCall,
  // callAudioModal,
  // callMobileNo,
  // muteCall,
  // unmuteCall,
  // holdCall,
  // unHoldCall,
  // startCall,
  // endCall,
  // openModal,
  // closeModal,
  // minimizeModal,
  // } = useCalling();
  // const dispatch = useDispatch();
  // const callingEventsInstance = new CallingEventHandlers()
  // const { isOnGoingCall } = useSelector((state) => state.calls);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  // const [callMobileNo, setCallMobileNo] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  // const [basicModalForEdit, setBasicModalForEdit] = useState(false);
  // const [basicModalForPaymentLinks, setBasicModalForPaymentLinks] =
  //   useState(false);
  // const [paymentLinksByAllocationId, setPaymentLinksByAllocationId] = useState(
  //   []
  // );
  // const [addRemarksBasicModal, setAddRemarksBasicModal] = useState(false);
  // const [callReportModal, setCallReportModal] = useState(false);
  // const [mobileNo, setMobileNo] = useState(null);
  // const [allocationDetails, setAllocationDetails] = useState([]);
  // const [allocationDetailsModal, setAllocationDetailsModal] = useState(false);
  const [basicModalForSendPaymentLink, setBasicModalForSendPaymentLink] =
    useState(false);
  // const [data, setData] = useState([]);
  // const [remarks, setRemarks] = useState([]);
  // const [inputRemarks, setInputRemarks] = useState("");
  // const [selectedCallStatus, setSelectedCallStatus] = useState("");
  // const [allocationId, setAllocationId] = useState(0);
  const [isMinutesSelected, setIsMinutesSelected] = useState(true);
  const [isHoursSelected, setIsHoursSelected] = useState(false);
  const [isDaysSelected, setIsDaysSelected] = useState(false);
  // const [totalElements, setTotalElements] = useState(1);
  // const [currPage, setCurrPage] = useState(0);
  const [reqPaymentsList, setReqPaymentsList] = useState([]);
  // const [paymentsDownloadList, setPaymentsDownloadList] = useState(null);
  // const [addRemarksAllocationId, setAddRemarksAllocationId] = useState("");
  // const [dataForEditcustomer,setDataForEditCustomer] =useState({});
  // const [dataForSendCustomerLink, setDataForSendcustomerLink] = useState({});
  // const [cellSelection, setCellSelection] = useState({"2,name": true, "2,city": true});
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllIdsSelected, setIsAllIdsSelected] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(">");
  // const [selectedLinkStatus, setSelectedLinkStatus] = useState("");
  // const [value, setValue] = useState(0);
  // const [errorMessage, setErrorMessage] = useState(false);
  // const [ setSortInfo] = useState({
  //   direction: "ASC",
  //   key: "customerName",
  // });

  // const [filterValue, setFilterValue] = useState(defaultFilterValue);
  // const [object, setObj] = useState([]);
  // const [dataForfilter, setDataForFilter] = useState([]);
  // const [tempFilterValue, setTempFilterValue] = useState(defaultFilterValue);

  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  // const [searchKey, setSearchKey] = useState("");
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let userLoginDetails = getTokenInLocalStorage();
  // function actionBegin(args) {
  //   let listData = [];
  //   var obj = {};
  //   if (args.requestType === "filtering") {
  //     if (args.action === "filter") {
  //       // debugger;
  //       console.log(args);

  //       obj.key = args.currentFilterObject.field;
  //       obj.value = args.currentFilterObject.value;
  //       obj.operator = args.currentFilterObject.operator.toUpperCase();
  //       obj.fieldType =
  //         args.currentFilterObject.field === "dueAmount" ? "NUMBER" : "STRING";

  //       listData.push(obj);
  //       console.log("obj", listData);

  //       let filterValue = JSON.parse(localStorage.getItem("filterValue"));
  //       if (filterValue.length > 0) {
  //         for (let i = 0; i < filterValue?.length; i++) {
  //           // filterValue.forEach((element, index) => {
  //           if (filterValue[i].key !== obj.key) {
  //             filterValue.push(obj);
  //             break;
  //           } else {
  //             filterValue[i] = obj;
  //             break;
  //           }
  //         }
  //       } else {
  //         filterValue.push(obj);
  //       }
  //       localStorage.setItem("filterValue", JSON.stringify(filterValue));
  //       setObj(filterValue);
  //       getDailyScheduleDateForFilters(filterValue,  start, end, 0);
  //     } else if (args.action === "clearFilter") {
  //       console.log(args);
  //       let filterValue = JSON.parse(localStorage.getItem("filterValue"));
  //       filterValue.map((u) =>
  //         u.key !== args.currentFilterObject.properties.field
  //           ? u
  //           : filterValue.splice(filterValue.indexOf(u), 1)
  //       );
  //       localStorage.setItem("filterValue", JSON.stringify(filterValue));
  //       setObj(filterValue);
  //       getDailyScheduleDateForFilters(filterValue,  start, end);
  //     }
  //   }
  //   if (args.requestType === "sorting") {
  //     console.log("args", args);
  //     let listData1 = [];
  //     let sortObj = {
  //       direction: args.direction === "Ascending" ? "ASC" : "DESC",
  //       key: args.columnName,
  //     };
  //     listData1.push(sortObj);
  //     console.log("listData1", listData1);
  //     setSortInfo(listData1);
  //     getDailyScheduleDateForFilters( listData1, start, end, 0);
  //   }
  // }
  // const allocationStatusTemplate = (props) => {
  //   return (
  //     <div>
  //       {" "}
  //       {props.status === "OPEN" ? (
  //         <span className="badge badge-rounded badge-primary">Open</span>
  //       ) : props.status === "INPROGRESS" ? (
  //         <span
  //           className="badge badge-rounded badge-warning"
  //           style={{ color: "black" }}
  //         >
  //           In Progress
  //         </span>
  //       ) : props.status === "COMPLETED" ? (
  //         <span className="badge badge-rounded badge-success">Completed</span>
  //       ) : (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.status}
  //         </span>
  //       )}
  //     </div>
  //   );
  // };

  // const visitStatusTemplate = (props) => {
  //   return (
  //     <div>
  //       {" "}
  //       {props.visitStatus === "PTP" ? (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.visitStatus}
  //         </span>
  //       ) : props.visitStatus === "Paid" ? (
  //         <span className="badge badge-rounded badge-success">
  //           {props.visitStatus}
  //         </span>
  //       ) : props.visitStatus === "Customer Not Available" ? (
  //         <span className="badge badge-rounded badge-danger">
  //           {props.visitStatus}
  //         </span>
  //       ) : props.visitStatus === "" || props.visitStatus === null ? (
  //         <span>-</span>
  //       ) : (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.visitStatus}
  //         </span>
  //       )}
  //     </div>
  //   );
  // };

  // const subStatusTemplate = (props) => {
  //   return (
  //     <div>
  //       {" "}
  //       {props.subStatus === "Cash" ||
  //       props.subStatus === "Bank Transfer" ||
  //       props.subStatus === "Already Settled" ||
  //       props.subStatus === "UPI" ? (
  //         <span className="badge badge-rounded badge-success">
  //           {props.subStatus}
  //         </span>
  //       ) : props.subStatus === "Paid" ? (
  //         <span className="badge badge-rounded badge-success">
  //           {props.subStatus}
  //         </span>
  //       ) : props.subStatus === "Customer Not Available" ? (
  //         <span className="badge badge-rounded badge-danger">
  //           {props.subStatus}
  //         </span>
  //       ) : props.subStatus === "" || props.subStatus === null ? (
  //         <span>-</span>
  //       ) : (
  //         <span className="badge badge-rounded badge-primary">
  //           {props.subStatus}
  //         </span>
  //       )}
  //     </div>
  //   );
  // };
  // const noOfPaymentLinksTemplate = (props) => {
  //   // console.log("props",props);
  //   return (
  //     <div>
  //       <span
  //         style={{
  //           color: "blue",
  //           textDecoration: "underline",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => hyperlinkPaymentLink(props?.id)}
  //       >
  //         {props.noOfpaymentLinkSent}
  //       </span>
  //     </div>
  //   );
  // };
  // const addRemarksTemplate = (props) => {
  //   return (
  //     <div>
  //       <Link
  //         to="#"
  //         className="btn btn-primary shadow btn-xs sharp"
  //         onClick={() => {
  //           addRemarksIconModal(props?.id);
  //         }}
  //       >
  //         <i className="fa fa-plus"></i>
  //       </Link>
  //     </div>
  //   );
  // };
  // const sendPaymentLinkTemplate = (props) => {
  //   return (
  //     <div>
  //       <OverlayTrigger
  //         placement="bottom"
  //         delay={{ show: 250, hide: 400 }}
  //         overlay={<Tooltip>Send Payment Link</Tooltip>}
  //       >
  //         <span
  //           style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
  //           onClick={() => {
  //             sendCustomerBasicModal(props);
  //           }}
  //         >
  //           <i className="fa fa-paper-plane" style={{ color: "green" }}></i>
  //           &nbsp; Send
  //         </span>
  //       </OverlayTrigger>
  //     </div>
  //   );
  // };
  // const callOptionTemplate = (props) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "row",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <span>{props.contactNumber1}</span>
  //       {hasAccess(42) &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
  //         userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" && (
  //           <Link
  //             style={{ marginLeft: "10px" }}
  //             to="#"
  //             // className="btn btn-success shadow btn-xs sharp"
  //             onClick={() => {
  //               // helper.makeCall(props?.contactNumber1);
  //               if (isOnGoingCall === false) {
  //                 var piopiy = new PIOPIY({
  //                   name: "CLU",
  //                   debug: false,
  //                   autoplay: true,
  //                   ringTime: 60,
  //                 });
  //                 StartCall(
  //                   piopiy,
  //                   dispatch,
  //                   props?.contactNumber1,
  //                   false,
  //                   props.id,
  //                   props
  //                 );
  //                 // piopiy.call("91" + row?.contactNumber);
  //                 // setCallMobileNo(props?.contactNumber1);
  //               } else {
  //                 dispatch(setCallAudioModal(true));
  //                 dispatch(setIsModalMinimised(false));
  //               }
  //             }}
  //           >
  //             {/* <i className="fa fa-phone fa-1x fa-rotate-90" /> */}
  //             <div className="media-body user-meta-info">
  //               <ul>
  //                 <li>
  //                   <Link
  //                     to={"#"}
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       height: "30px",
  //                       width: "30px",
  //                     }}
  //                   >
  //                     <i
  //                       className="fas fa-phone-alt"
  //                       style={{ fontSize: "smaller" }}
  //                     ></i>
  //                   </Link>
  //                 </li>
  //               </ul>
  //             </div>
  //             {/* <img
  //                         src="/sidebar/callIcon.png"
  //                         alt="sidebar"
  //                         style={{ width: 25, height: 25, marginRight: 10 }}
  //                       /> */}
  //           </Link>
  //         )}
  //     </div>
  //   );
  // };
  // const callHistoryTemplate = (props) => {
  //   return (
  //     <div
  //       className="d-flex"
  //       style={{ justifyContent: "space-around" }}
  //       onClick={() => {
  //         var temp = {
  //           mobileNo: props.contactNumber1,
  //           loanNumber: props.loanNumber,
  //         };
  //         setMobileNo(temp);
  //         setCallReportModal(true);
  //       }}
  //     >
  //       <Link to="#" className="btn btn-info shadow btn-xs sharp">
  //         <i className="fa fa-eye"></i>
  //       </Link>
  //     </div>
  //   );
  // };

  // const getAllocationDetails = (row) => {
  //   // console.log("props",props);
  //   return (
  //     <div>
  //       <span
  //         style={{
  //           color: "blue",
  //           textDecoration: "underline",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => {
  //           setAllocationDetails(row);
  //           setAllocationDetailsModal(true);
  //           console.log(allocationDetails);
  //         }}
  //       >
  //         {row.loanNumber}
  //       </span>
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   paymentsDownloadList !== null && handleExport();
  //   setIsLoading(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentsDownloadList]);

  // const dropdata = DataUtil.distinct(reqPaymentsList, 'customerName');
  // const filterTemplate = (props)=> {
  //   console.log("props",props);
  //   // dropdata.push('Clear');
  //   /** The enabled attributes will be added based on the column property. */
  //   return (<DropDownListComponent enabled={props.column.allowFiltering} id={props.column.field} popupHeight='250px'
  //     dataSource={dropdata}/>);
  // }
  // const onSelectionChange = useCallback(({ selected }) => {
  //   const uniqueKeys = new Set();
  //   Object.keys(selected).forEach((key) => {
  //     uniqueKeys.add(key);
  //   });

  //   // let keys = Array.from(uniqueKeys);
  //   // console.log("keys", keys);
  //   // setSelectedIds(keys);
  //   const values = Object.values(selected);
  //   console.log(values);
  //   setData(values);
  //   localStorage.setItem("allocationId", values[0]?.id);
  // }, []);
  useEffect(() => {
    ReactGA.pageview("Request Payments");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("filterValue", JSON.stringify([]));
    // console.log("sortInfo", sortInfo);
    getDailyScheduleDateForFilters(start, end, 0);
    // getRequestPaymentsData(
    //   start,
    //   end,
    //   0,
    //   selectedValue,
    //   value,
    //   selectedLinkStatus
    // );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getRequestPaymentsData = (
  //   start,
  //   end,
  //   pageNo,
  //   amountOperator,
  //   amountToBePaid,
  //   linkStatus
  // ) => {
  //   let listData = [];
  //   setIsLoading(true);
  //   let payload = {
  //     endDate: end,
  //     organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //     searchKey: "",
  //     startDate: start,
  //     linkStatus: linkStatus,
  //     amountOperator: amountOperator,
  //     amountToBePaid: amountToBePaid ? amountToBePaid : 0,
  //   };

  //   moduleApi
  //     .postData(
  //       moduleApi.withPage(apiCall.getRequestPayments, pageNo, 10),
  //       payload
  //     )
  //     .then((response) => {
  //       response?.content?.forEach((element) => {
  //         let data = {
  //           id: element.allocationId,
  //           customerName: element.customerName ? element.customerName : "-",
  //           dueAmount: element.dueAmount,
  //           customerNumber: element.customerNumber
  //             ? element.customerNumber
  //             : "-",
  //           noOfpaymentLinkSent: element.noOfpaymentLinkSent
  //             ? element.noOfpaymentLinkSent
  //             : 0,
  //           currentBucketName: element.currentBucketName
  //             ? element.currentBucketName
  //             : "-",
  //           remarks: element.remarks ? element.remarks : "-",
  //           loanNumber: element.loanNumber ? element.loanNumber : "-",
  //           transactionId: element.transactionId ? element.transactionId : "-",
  //           transactionStatus: element.transactionStatus
  //             ? element.transactionStatus
  //             : "-",
  //           branchName: element.branchName ? element.branchName : "-",
  //           amountPaid: element.amountPaid ? element.amountPaid : 0,
  //         };
  //         listData.push(data);
  //       });
  //       setReqPaymentsList(listData);
  //       setTotalElements(response?.totalElements);
  //       setIsLoading(false);
  //     });
  // };

  // const renderSendButton = (data) => {
  //   return (
  //     <OverlayTrigger
  //       placement="bottom"
  //       delay={{ show: 250, hide: 400 }}
  //       overlay={<Tooltip>Send Payment Link</Tooltip>}
  //     >
  //       <span
  //         style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
  //         onClick={() => {
  //           sendCustomerBasicModal();
  //         }}
  //       >
  //         <i className="fa fa-paper-plane" style={{ color: "green" }}></i>&nbsp;
  //         Send
  //       </span>
  //     </OverlayTrigger>
  // <Tooltip>
  //   <span
  //     style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
  //     onClick={() => {
  //       sendCustomerBasicModal();
  //     }}
  //   >
  //     <i className="fa fa-paper-plane" style={{ color: "green" }}></i>&nbsp;
  //     Send
  //   </span>
  // </Tooltip>
  //   );
  // };

  // const sendCustomerBasicModal = (data) => {
  //   setBasicModalForSendPaymentLink(true);
  //   // console.log("data",data);
  //   setDataForSendcustomerLink(data);
  // };

  // const editModal = (data) => {
  //   setBasicModalForEdit(true);
  //   // console.log("data",data);
  //   setDataForEditCustomer(data);
  // };

  const sendCustomerPaymentLink = (values) => {
    // console.log(values.minutes, values.hours, values.days);
    setIsLoading2(true)
    var minsPayload = {
      // allocationId: dataForSendCustomerLink?.id,
      // amount: values.dueAmount * 100,
      allocationIds: selectedIds,
      lotName: values.lotName,
      timeOut: values.minutes * 60,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      // phoneNumber: values.mobileNumber,
      // remarks: values.remarks,
    };
    var hoursPayload = {
      allocationIds: selectedIds,
      lotName: values.lotName,
      timeOut: values.hours * 60 * 60,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      // allocationId: dataForSendCustomerLink?.id,
      // amount: values.dueAmount * 100,
      // expiryTimeInSeconds: values.hours * 60 * 60,
      // organizationId: loggedInUser.autoFinanceUser.organizationId,
      // phoneNumber: values.mobileNumber,
      // remarks: values.remarks,
    };
    var daysPayload = {
      allocationIds: selectedIds,
      lotName: values.lotName,
      timeOut: values.days * 24 * 60 * 60,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      // allocationId: dataForSendCustomerLink?.id,
      // amount: values.dueAmount * 100,
      // expiryTimeInSeconds: values.days * 24 * 60 * 60,
      // organizationId: loggedInUser.autoFinanceUser.organizationId,
      // phoneNumber: values.mobileNumber,
      // remarks: values.remarks,
    };
    // console.log(minsPayload);

    // var customPaymentLinkPayload = {
    //   // allocationId: dataForSendCustomerLink?.id,
    //   amount: values.dueAmount * 100,
    //   // expiryTimeInSeconds: values.days * 24 * 60 * 60,
    //   customPayLink: values.paymentLink,
    //   organizationId: loggedInUser.autoFinanceUser.organizationId,
    //   phoneNumber: values.mobileNumber,
    //   remarks: values.remarks,
    // };
    moduleApi
      .postData(
        apiCall.sendCustomerBulkPaymentLinkLots,
        // hasAccess(52)
        //   ?
        isMinutesSelected === true
          ? minsPayload
          : isDaysSelected === false
          ? hoursPayload
          : daysPayload
        // : hasAccess(51) && customPaymentLinkPayload
      )
      .then((response) => {
        // console.log("response", response);
        setIsLoading2(false)
        if (response) {
          if (response?.Message === "Lot Saved SuccessFully") {
            setBasicModalForSendPaymentLink(false);
            setSelectedIds([]);
            return swal("Payment Link Request/s Sent Successfully", {
              icon: "success",
            }).then((willDelete) => {
              if (willDelete) {
                getDailyScheduleDateForFilters(start, end);
              }
            });
          } else if (response.message) {
            return swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please try again", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };
  // const addRemarksIconModal = (value) => {
  //   setAddRemarksBasicModal(true);
  //   // console.log("selectedIds", allocationId);
  //   // let id = localStorage.getItem("allocationId");
  //   console.log("value", value);
  //   setAddRemarksAllocationId(value);
  //   moduleApi.getData(apiCall.getAllocationRemarks + value).then((response) => {
  //     // console.log("response", response);
  //     if (response) {
  //       setRemarks(response);
  //     }
  //   });
  // };
  // const hyperlinkPaymentLink = (value) => {
  //   setBasicModalForPaymentLinks(true);
  //   // let id = JSON.parse(localStorage.getItem("allocationId"));
  //   // var id=data[0]?.id;
  //   moduleApi
  //     .getData(apiCall.getPaymentLinksByAllocation + value)
  //     .then((response) => {
  //       console.log("response", response);
  //       if (response) {
  //         setPaymentLinksByAllocationId(response);
  //       }
  //     });
  // };
  // const latestPaymentLinkStatus = (value) =>{
  //   if(value === "PAYMENT_ERROR"){
  //     return (
  //       <span className="badge badge-rounded badge-danger">{value}</span>
  //     )
  //   }
  //   else if(value === "Payment Pending"){
  //   return (
  //     <span className="badge badge-rounded badge-warning">{value}</span>
  //   )
  //   }
  //   else if(value === "TRANSACTION_NOT_FOUND"){
  //   return (
  //     <span className="badge badge-rounded badge-warning">{value}</span>
  //   )
  //   }
  // }
  // const reqPayemntsColumns = [
  //   { name: "customerName", header: "Customer Name", defaultFlex: 1 },
  //   {
  //     name: "contactNumber1",
  //     header: "Customer Number",
  //     defaultFlex: 1,
  //     type: "number",
  //   },
  //   {
  //     name: "branchName",
  //     header: "Branch",
  //     defaultFlex: 1,
  //     type: "number",
  //   },
  //   {
  //     name: "loanNumber",
  //     header: "Loan Number",
  //     defaultFlex: 1,
  //     type: "number",
  //     defaultWidth: 250,
  //   },
  //   {
  //     name: "amountPaid",
  //     header: "Amount Paid",
  //     defaultFlex: 1,
  //     type: "number",
  //     filterEditor: NumberFilter,
  //   },
  //   {
  //     name: "dueAmount",
  //     header: "Due Amount",
  //     defaultFlex: 1,
  //     type: "number",
  //     filterEditor: NumberFilter,
  //   },
  //   {
  //     name: "latestPaymentLinkStatus",
  //     header: "Status",
  //     defaultFlex: 1,
  //     type: "number",
  //     defaultWidth: 250,
  //     // render:({ data, value }) => (
  //     //   latestPaymentLinkStatus(value)
  //     // )
  //   },
  //   {
  //     name: "latestPaymentLinkId",
  //     header: "Transaction Id",
  //     defaultFlex: 1,
  //     type: "number",
  //     defaultWidth: 250,
  //   },
  //   {
  //     name: "noOfpaymentLinkSent",
  //     header: "No Of Payment Link Sent",
  //     defaultFlex: 1,
  //     type: "number",
  //     defaultWidth: 250,
  //     textAlign: "center",
  //     render: ({ data, value }) => (
  //       <span
  //         style={{
  //           color: "blue",
  //           textDecoration: "underline",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => hyperlinkPaymentLink(data?.id)}
  //       >
  //         {value}
  //       </span>
  //     ),
  //     // render: 6
  //   },
  //   {
  //     name: "currentBucketName",
  //     header: "Current Bucket",
  //     defaultFlex: 1,
  //     type: "number",
  //     defaultWidth: 250,
  //   },
  //   {
  //     name: "remarks",
  //     header: "Remarks",
  //     defaultFlex: 1,
  //     // type: "number",
  //     defaultWidth: 250,
  //     render: ({ data, value }) => (
  //       <Link
  //         to="#"
  //         className="btn btn-primary shadow btn-xs sharp"
  //         onClick={() => {
  //           addRemarksIconModal(data?.id);
  //         }}
  //       >
  //         <i className="fa fa-plus"></i>
  //       </Link>
  //     ),
  //     textAlign: "center",
  //   },
  //   // {
  //   //   name: "editIcon",
  //   //   header: "",
  //   //   defaultFlex: 1,
  //   //   // type: "number",
  //   //   maxWidth: 40,
  //   //   // defaultWidth: 250,
  //   //   render: ({ data, value }) => (
  //   //     <Link
  //   //       to="#"
  //   //       className="btn btn-info shadow btn-xs sharp"
  //   //       onClick={() => {
  //   //         editModal(data);
  //   //       }}
  //   //     >
  //   //       <i className="fa fa-edit"></i>
  //   //     </Link>
  //   //   ),
  //   // },
  //   {
  //     name: "sendButton",
  //     header: "",
  //     defaultFlex: 1,
  //     type: "button",
  //     defaultWidth: 250,
  //     render: ({ data, value }) => (
  //       <OverlayTrigger
  //         placement="bottom"
  //         delay={{ show: 250, hide: 400 }}
  //         overlay={<Tooltip>Send Payment Link</Tooltip>}
  //       >
  //         <span
  //           style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
  //           onClick={() => {
  //             sendCustomerBasicModal(data);
  //           }}
  //         >
  //           <i className="fa fa-paper-plane" style={{ color: "green" }}></i>
  //           &nbsp; Send
  //         </span>
  //       </OverlayTrigger>
  //     ),
  //   },
  //   {
  //     name: "callIcon",
  //     header: "",
  //     defaultFlex: 1,
  //     // type: "number",
  //     maxWidth: 40,
  //     // defaultWidth: 250,
  //     render: ({ data, value }) => (
  //       <Link
  //         to="#"
  //         className="btn btn-success shadow btn-xs sharp"
  //         onClick={() => {
  //           helper.makeCall(data?.contactNumber1);
  //         }}
  //       >
  //         <i className="fa fa-phone fa-1x fa-rotate-90" />
  //       </Link>
  //     ),
  //   },
  // ];
  // const onFilterValueChange = useCallback((valueChange) => {
  //   getChangedValues(defaultFilterValue, valueChange);
  //   setFilterValue(valueChange);
  //   // eslint-disable-next-line
  // }, []);

  // function getChangedValues(arr1, arr2) {
  //   console.log("arr1", arr1);
  //   console.log("arr2", arr2);
  //   const changedValues = [];

  //   // Iterate over array1 and compare with array2
  //   arr1.forEach((obj1, index) => {
  //     const obj2 = arr2[index];
  //     // Compare properties
  //     for (let key in obj1) {
  //       if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
  //         if (obj1[key] !== obj2[key]) {
  //           changedValues.push({
  //             name: obj2.name,
  //             operator: obj2.operator,
  //             value: obj2.value,
  //             type: obj2.type,
  //           });
  //         }
  //       }
  //     }
  //   });
  //   console.log("changedValues", changedValues);
  //   // setFilterValue(changedValues);
  //   getDailyScheduleDateForFilters(changedValues,  start, end, 0);
  //   // localStorage.removeItem("filterValue");
  //   // localStorage.setItem("filterValue", JSON.stringify(arr2));
  //   // console.log("arr2", arr2);
  //   // setDataForFilter(changedValues);
  //   return changedValues;
  // }

  // const onSortInfoChange = useCallback((value) => {
  //   const newSort = value
  //     ? { type: value?.type, name: value?.name, dir: value?.dir }
  //     : value;
  //   setSortInfo(newSort);
  //   getDailyScheduleDateForFilters(filterValue, newSort, start, end, 0);
  //   // eslint-disable-next-line
  // }, []);

  // const close = () => {
  //   setBasicModalForEdit(false);
  // };

  const closeForSendPaymentLink = () => {
    setIsMinutesSelected(true);
    setIsHoursSelected(false);
    setIsDaysSelected(false);
    setBasicModalForSendPaymentLink(false);
  };

  // const remarksOptions = [
  //   { label: "Call Not Reachable", value: "Call Not Reachable" },
  //   { label: "Call Not Attended", value: "Call Not Attended" },
  //   { label: "Call Again", value: "Call Again" },
  // ];

  // const handleCloseForRemarks = () => {
  //   setAddRemarksBasicModal(false);
  //   getDailyScheduleDateForFilters(  start, end, 0);
  // };

  // const handleRemarksChange = (event) => {
  //   setInputRemarks(event.target.value);
  // };
  // const handleCallStatusChange = (value) => {
  //   setSelectedCallStatus(value.value);
  // };

  // const addRemarksforcustomer = () => {
  //   if (selectedCallStatus === "") {
  //     return swal("Please Select Call Status", {
  //       icon: "error",
  //       dangerMode: true,
  //     });
  //   }
  //   if (inputRemarks === "") {
  //     return swal("Please Enter Remarks", {
  //       icon: "error",
  //       dangerMode: true,
  //     });
  //   }
  //   // let id = localStorage.getItem("allocationId");
  //   const payload = {
  //     allocationId: addRemarksAllocationId,
  //     remarkAddedBy: "",
  //     remarks: inputRemarks,
  //     remarksType: selectedCallStatus,
  //     userId: loggedInUser.autoFinanceUser.userId,
  //   };
  //   moduleApi
  //     .postData(apiCall.addRemarksForAllocation, payload)
  //     .then((response) => {
  //       // console.log("response", response);
  //       setAddRemarksBasicModal(false);
  //       if (response) {
  //         return swal("Remarks Added Successfully", {
  //           icon: "success",
  //         }).then((willDelete) => {
  //           if (willDelete) {
  //             getDailyScheduleDateForFilters(  start, end, 0);
  //           }
  //         });
  //       }
  //     });
  // };
  // const submitRequestPaymentEditDetails = (values) => {
  //   const payload = {
  //     allocationId: dataForEditcustomer?.id,
  //     amount: values.dueAmount,
  //     mobileNumber: values.mobileNumber,
  //     organizationId: loggedInUser.autoFinanceUser.organizationId,
  //     remarks: values.remarks,
  //   };
  //   moduleApi
  //     .postData(apiCall.editCustomerDetails, payload)

  //     .then((response) => {
  //       // console.log("response", response);
  //       setBasicModalForEdit(false);
  //       if (response) {
  //         if (response?.Message === "Edited Successfully") {
  //           return swal("Updated Successfully", {
  //             icon: "success",
  //             dangerMode: false,
  //           }).then((willDelete) => {
  //             if (willDelete) {
  //               getDailyScheduleDateForFilters(
  //                 filterValue,
  //
  //                 start,
  //                 end,
  //                 0
  //               );
  //             }
  //           });
  //         }
  //       }
  //     });
  // };
  const getDailyScheduleDateForFilters = (start, end) => {
    let listData1 = [];

    // console.log(filterchange, sortInfo);
    // console.log("sortInfo", sortInfo);
    // console.log("filterValue", filterchange);
    // console.log("start",filterchange[0]?.value?.start)

    // let result = [];
    // for (let i = 0; i < filterchange?.length; i++) {
    //   let temp = {};
    //   temp.key = filterchange[i].name;
    //   temp.operator = filterchange[i].operator.toUpperCase();
    //   temp.value =
    //     filterchange[i].value === null
    //       ? filterchange[i].type === "string"
    //         ? ""
    //         : 0
    //       : filterchange[i].value;
    //   temp.type = filterchange[i].type.toUpperCase();

    //   result.push(temp);
    // }
    // // console.log("equal",defaultFilterValue !== result)
    // console.log("result", result);
    // console.log("default", defaultFilterValue);

    // eslint-disable-next-line
    // if (filterchange[0]?.type && filterchange[0]?.operator && sortInfo) {
      setIsLoading(true);
    const payload = {
      startDate: start,
      endDate: end,
      // filters: filterchange,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      // page: pageNo,
      // size: 10,
      // sorts: [
      //   {
      //     direction: sortInfo?.direction,
      //     key: sortInfo?.key,
      //   },
      // ],
    };
    moduleApi
      .postData(apiCall.getCollectionAllocationForRequestPayments, payload)

      .then((response) => {
        setSelectedIds([])
        response?.forEach((element) => {
          let data = {
            id: element?.id,
            customerName: element?.name ? element?.name : "-",
            loanNumber: element?.ln ? element?.ln : "-",
            customerPhoneNumber: element?.cph ? element?.cph : "-",
            currentBucket: element?.cb ? element?.cb : "-",
            currerntDue: element?.cd ? element?.cd : "-",
            cpName: element?.cp ? element?.cp : "-",
            branchName: element?.bn ? element?.bn : "-",
          };
          // let data = {
          //   id: element.id,
          //   customerName: element.customerName ? element.customerName : "-",
          //   dueAmount: element.dueAmount ? element.dueAmount : 0,
          //   contactNumber1: element.contactNumber1
          //     ? element.contactNumber1
          //     : "-",
          //   loanNumber: element.loanNumber ? element.loanNumber : "-",
          //   latestPaymentLinkId: element.latestPaymentLinkId
          //     ? element.latestPaymentLinkId
          //     : "-",
          //   latestPaymentLinkStatus: element.latestPaymentLinkStatus
          //     ? element.latestPaymentLinkStatus
          //     : "-",
          //   noOfpaymentLinkSent: element.noOfLinkSent
          //     ? element.noOfLinkSent
          //     : 0,
          //   status: element.status ? element.status : "-",
          //   totalCalls: element.totalCalls ? element.totalCalls : "-",
          //   callsToday: element.callsToday ? element.callsToday : "-",
          //   visitStatus: element.visitStatus,
          //   subStatus: element.subStatus,
          //   currentBucketName: element.currentBucketName
          //     ? element.currentBucketName
          //     : "-",
          //   remarks: element.remarks ? element.remarks : "-",
          //   branchName: element.branchName ? element.branchName : "-",
          //   amountPaid: element.amountPaid ? element.amountPaid : 0,
          //   addressLine2: element.addressLine2,
          //   ag_Date: element.ag_Date,
          //   amountPaidNow: element.amountPaidNow,
          //   amountToBePaid: element.amountToBePaid,
          //   assetDesc: element.assetDesc,
          //   assignedMobileNumber: element.assignedMobileNumber,
          //   assignedName: element.assignedName,
          //   bounceCharges: element.bounceCharges,
          //   branchId: element.branchId,
          //   chassisNumber: element.chassisNumber,
          //   comments: element.comments,
          //   contactNumber: element.contactNumber1,
          //   contactNumber2: element.contactNumber2,
          //   cpName: element.cpName,
          //   currentBucket: element.currentBucketName,
          //   customerIntensinty: element.customerIntensinty,
          //   lenderId: element.lenderId,
          //   lenderName: element.lenderName,
          //   lenderAddress: element.promoterAddress,
          //   borrowerAddress: element.borrowerAddress,
          //   current_EMI_Month: element.currentEmiMonthNumber,
          //   dateTime:
          //     element.dateTime !== 0 ? EpochToDate(element.dateTime) : "-",

          //   dueDate:
          //     element.dueDate !== 0
          //       ? helper.EpochToOnlyDate(element.dueDate)
          //       : "-",
          //   dueDay: element.dueDay,
          //   emiAmount: element.emiAmount,
          //   emiOutstanding: element.emiOutstanding,
          //   engineNumber: element.engineNumber,
          //   employeeId: element.executiveEmployeeId
          //     ? element.executiveEmployeeId
          //     : element.managerEmployeeId,
          //   executiveMobileNumber: element.executiveMobileNumber,
          //   executiveName: element.executiveName,
          //   guarantorMobile: element.guarantorMobile,
          //   guarantorName: element.guarantorName,
          //   image: element.image,
          //   installmentDate: element.installmentDate
          //     ? EpochToDate(element.installmentDate)
          //     : "-",
          //   latePaymentCharges: element.latePaymentCharges,
          //   latitude: element.latitude,
          //   loanAmount: element.loanAmount,
          //   location: element.location,
          //   longitude: element.longitude,
          //   managerEmployeeId: element.managerEmployeeId,
          //   managerName: element.managerName,
          //   nearByLandmark: element.nearByLandmark,
          //   netOtherAmount: element.netOtherAmount,
          //   net_GDI_Amount: element.net_GDI_Amount,
          //   net_HL_Amount: element.net_HL_Amount,
          //   organizationId: element.organizationId,
          //   permanentAddress: element.permanentAddress,
          //   permanentCity: element.permanentCity,
          //   permanentState: element.permanentState,
          //   permanentZipcode: element.permanentZipcode,
          //   principalOutstanding: element.principalOutstanding,
          //   productName: element.productName,
          //   referenceMobile1: element.referenceMobile1,
          //   referenceMobile2: element.referenceMobile2,
          //   referenceName1: element.referenceName1,
          //   referenceName2: element.referenceName2,
          //   registrationNumber: element.registrationNumber,
          //   reportingOfficerId: element.reportingOfficerId,
          //   address: element.residenceAddress,
          //   residenceCity: element.residenceCity,
          //   residenceState: element.residenceState,
          //   residenceZipcode: element.residenceZipcode,
          //   teamLeaderEmployeeId: element.teamLeaderEmployeeId,
          //   teamLeaderName: element.teamLeaderName,
          //   temporaryRegistrationNumber: element.temporaryRegistrationNumber,
          //   tenure: element.tenure,
          //   totalPenaltyCharges: element.totalPenaltyCharges,
          //   typeOfVisit: element.typeOfVisit,
          //   thirdPartyPhoneNumber: element.thirdPartyPhoneNumber,
          //   thirdPartyName: element.thirdPartyName,
          //   updatedDate:
          //     element.updatedDate !== 0
          //       ? EpochToDate(element.updatedDate)
          //       : "-",
          //   lastPaidDate:
          //     element.lastPaidDate !== 0
          //       ? EpochToDate(element.lastPaidDate)
          //       : "-",
          //   expiredDate:
          //     element.dueDate !== 0
          //       ? helper.EpochToOnlyDate(element.expiredDate)
          //       : "-",
          //   lastCallUpdateTime:
          //     element.lastCallUpdateTime !== 0
          //       ? EpochToDate(element.lastCallUpdateTime)
          //       : "-",
          //   userId: element.userId,
          //   vehicleType: element.vehicleType,
          //   emiAmountPaid:element.emiAmountPaid?element.emiAmountPaid:0,
          //   emiAmountToBePaid:element.emiAmountToBePaid?element.emiAmountToBePaid:0,
          //   bounceChargesPaid:element.bounceChargesPaid?element.bounceChargesPaid:0,
          //   bounceChargesToBePaid:element.bounceChargesToBePaid?element.bounceChargesToBePaid:0,
          //   latePaymentChargesPaid:element.latePaymentChargesPaid?element.latePaymentChargesPaid:0,
          //   latePaymentChargesToBePaid:element.latePaymentChargesToBePaid?element.latePaymentChargesToBePaid:0,
          //   penaltyChargesPaid:element.penaltyChargesPaid?element.penaltyChargesPaid:0,
          //   penaltyChargesToBePaid:element.penaltyChargesToBePaid?element.penaltyChargesToBePaid:0,
          //   otherChargesPaid:element.otherChargesPaid?element.otherChargesPaid:0,
          //   otherChargesToBePaid:element.otherChargesToBePaid?element.otherChargesToBePaid:0,
          //   aggDate:element.aggDate?element.aggDate:0,
          // };
          listData1.push(data);
        });
        setReqPaymentsList(listData1);
        
        setIsLoading(false);
        // setTotalElements(response?.totalElements);
      });
    // }
  };
  // const onPageChange = (selectedPage) => {
  //   setCurrPage(selectedPage - 1);
  //   getDailyScheduleDateForFilters(
  //
  //
  //     start,
  //     end,
  //     selectedPage - 1
  //   );
  //   // getRequestPaymentsData(
  //   //   start,
  //   //   end,
  //   //   selectedPage - 1,
  //   //   selectedValue,
  //   //   value,
  //   //   selectedLinkStatus
  //   // );
  // };

  const setCustomDatesForGraph = (value1) => {
    var start = value1[0].getTime() / 1000;
    var end =
      new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStart(start);
    setEnd(end);
    getDailyScheduleDateForFilters(start, end, 0);
    // setErrorMessage(false);
  };

  // const handleOnSelect = (row, isSelect) => {
  //   console.log("---------------- single selection");
  //   if (isSelect) {
  //     // console.log("one", row, "rr", row.id);
  //     setSelectedIds([...selectedIds, row.id]);
  //     console.log("one", row.id);
  //     // return rows.filter(r => r.id >= 3).map(r => r.id);
  //   } else {
  //     setSelectedIds("");
  //   }
  // };

  // const handleOnSelectAll = (isSelect, rows) => {
  //   const ids = rows.map((r) => r.id);
  //   if (isSelect) {
  //     setSelectedIds(ids);
  //     setIsAllIdsSelected(true);
  //     console.log("three", rows, "rr", ids);
  //   } else {
  //     setSelectedIds("");
  //     setIsAllIdsSelected(false);
  //   }
  // };

  // const selectRow = {
  //   mode: "checkbox",
  //   clickToSelect: true,
  //   headerColumnStyle: {
  //     backgroundColor: "rgb(243,240,249)",
  //   },
  //   style: { cursor: "pointer" },

  //   selected: selectedIds,
  //   onSelect: handleOnSelect,
  //   onSelectAll: handleOnSelectAll,
  // };

  const minutesPattern = /^(0?[1-9]|[1-5][0-9]|60)$/;
  const hoursPattern = /^(1?[1-9]|2[0-4])$/;
  const daysPattern = /^(0?[1-9]|[1-2][0-9]|30)$/;

  const validate = Yup.object({
    minutes: Yup.string().when([], {
      is: () => isMinutesSelected,
      // && hasAccess(52),
      then: Yup.string()
        .matches(minutesPattern, "Please enter a number between 1 and 60")
        .required("Number of Minutes is required"),
      otherwise: Yup.string().notRequired(),
    }),
    hours: Yup.string().when([], {
      is: () => isHoursSelected,
      // && hasAccess(52),
      then: Yup.string()
        .matches(hoursPattern, "Please enter a number between 1 and 24")
        .required("Number of Hours is required"),
      otherwise: Yup.string().notRequired(),
    }),
    days: Yup.string().when([], {
      is: () => isDaysSelected,
      //  && hasAccess(52),
      then: Yup.string()
        .matches(daysPattern, "Please enter a number between 1 and 30")
        .required("Number of Days is required"),
      otherwise: Yup.string().notRequired(),
    }),
    // mobileNumber: Yup.string().required("Mobile Number is required"),
    // paymentLink: Yup.string().when([], {
    //   is: () => hasAccess(51),
    //   then: Yup.string().required("Payment Link is required"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    lotName: Yup.string().required("Lot Name is required"),
    // dueAmount: Yup.string().required("Due Amount is required"),
  });

  // const validateForEdit = Yup.object({
  //   mobileNumber: Yup.string().required("Mobile Number is required"),
  //   dueAmount: Yup.string().required("Due Amount is required"),
  //   remarks: Yup.string().required("Remarks is required"),
  // });

  // const paymentsDownloadData = () => {
  //   setIsLoading(true);
  //   // let filterData = JSON.parse(localStorage.getItem("filterValue"));
  //   // console.log("filterData", filterData);
  //   // console.log("fileyterv", filterValue);
  //   // console.log("sort", sortInfo);

  //   // let result = [];
  //   // for (let i = 0; i < filterValue?.length; i++) {
  //   //   let temp = {};
  //   //   temp.key = filterValue[i].name;
  //   //   temp.operator = filterValue[i].operator.toUpperCase();
  //   //   temp.value = filterValue[i].value;
  //   //   temp.type = filterValue[i].type.toUpperCase();

  //   //   result.push(temp);
  //   // }
  //   // console.log("result", result);

  //   // if ((filterData[0]?.type && filterData[0]?.operator) || sortInfo) {
  //   // let i = filterData[filterData?.length - 1] ? filterData?.length - 1 : 0;
  //   const payload = {
  //     startTime: start,
  //     endTime: end,
  //     // filters: object,
  //     // filters: [
  //     //   {
  //     //     fieldType: filterData[i]?.type.toUpperCase(),
  //     //     key: filterData[i]?.name,
  //     //     operator: filterData[i]?.operator.toUpperCase(),
  //     //     value: filterData[i]?.value?.start
  //     //       ? filterData[i]?.value?.start
  //     //       : filterData[i]?.value,
  //     //     valueTo: filterData[i]?.value?.end ? filterData[i]?.value?.end : {},
  //     //     values: [{}],
  //     //   },
  //     // ],
  //     organizationId: loggedInUser.autoFinanceUser?.organizationId,
  //     // page: 0,
  //     // size: 10,
  //     // sorts: [
  //     //   {
  //     //     direction: sortInfo?.direction,
  //     //     key: sortInfo?.key,
  //     //   },
  //     // ],
  //   };
  //   //   moduleApi
  //   //     .postData(apiCall.getdailyscheduleDataBasedOnFilters, payload)

  //   //     .then((response) => {
  //   //       setFilterValue(defaultFilterValue);
  //   //       response?.content?.forEach((element) => {

  //   //         listData1.push(data);
  //   //       });

  //   //     });
  //   // }

  //   moduleApi
  //     .postData(apiCall.getCollectionAllocationForRequestPayments, payload)
  //     .then((response) => {
  //       if (response) {
  //         response?.forEach((element) => {
  //           let data = {
  //             id: element?.id,
  //             customerName: element?.name ? element?.name : "-",
  //             loanNumber: element?.ln ? element?.ln : "-",
  //             customerPhoneNumber: element?.cph ? element?.cph : "-",
  //             currentBucket: element?.cb ? element?.cb : "-",
  //             currerntDue: element?.cd ? element?.cd : "-",
  //             cpName: element?.cp ? element?.cp : "-",
  //             branchName: element?.bn ? element?.bn : "-",
  //           };
           
  //           listData1.push(data);
  //         });
  //         // if (response?.length > 5000) {
  //         //   setIsLoading(false);
  //         //   return swal({
  //         //     title: "Download Report",
  //         //     text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //         //     icon: "warning",
  //         //     buttons: true,
  //         //     dangerMode: true,
  //         //   }).then((willDelete) => {
  //         //     if (willDelete) {
  //         //       setIsLoading(true);
  //         //       getPaymentsFullResponseData(
  //         //         Math.floor(response.totalElements / 200)
  //         //       );
  //         //     }
  //         //   });
  //         // }

  //         // getPaymentsFullResponseData(Math.floor(response.totalElements / 200));
  //       } else {
  //         swal("Something Went Wrong, Please Try Later", {
  //           icon: "error",
  //           dangerMode: true,
  //         });
  //       }
  //     });
  //   // }
  // };

  // async function getPaymentsFullResponseData(size) {
  //   // let filterData = JSON.parse(localStorage.getItem("filterValue"));
  //   // console.log("filterData1", filterData);
  //   var tempList = [];
  //   var mgrListData = [];
  //   // let result = [];
  //   // for (let i = 0; i < filterValue?.length; i++) {
  //   //   let temp = {};
  //   //   temp.key = filterValue[i].name;
  //   //   temp.operator = filterValue[i].operator.toUpperCase();
  //   //   temp.value = filterValue[i].value;
  //   //   temp.type = filterValue[i].type.toUpperCase();

  //   //   result.push(temp);
  //   // }
  //   // console.log("result", result);
  //   for (let i = 0; i <= size; i++) {
  //     // if ((filterData[0]?.type && filterData[0]?.operator) || sortInfo) {
  //     // let j = filterData[filterData?.length - 1] ? filterData?.length - 1 : 0;
  //     const payload = {
  //       startTime: start,
  //       endTime: end,
  //       // filters: object,
  //       // filters: [
  //       //   {
  //       //     fieldType: filterData[j]?.type.toUpperCase(),
  //       //     key: filterData[j]?.name,
  //       //     operator: filterData[j]?.operator.toUpperCase(),
  //       //     value: filterData[j]?.value?.start
  //       //       ? filterData[j]?.value?.start
  //       //       : filterData[j]?.value,
  //       //     valueTo: filterData[j]?.value?.end
  //       //       ? filterData[j]?.value?.end
  //       //       : {},
  //       //     values: [{}],
  //       //   },
  //       // ],
  //       organizationId: loggedInUser.autoFinanceUser?.organizationId,
  //       // page: i,
  //       // size: 200,
  //       // sorts: [
  //       //   {
  //       //     direction: sortInfo?.direction,
  //       //     key: sortInfo?.key,
  //       //   },
  //       // ],
  //     };
  //     await moduleApi
  //       .postData(apiCall.getCollectionAllocationForRequestPayments, payload)
  //       .then((response) => {
  //         tempList.push(...response);
  //       });
  //   }

  //   // console.log("templist", tempList);
  //   tempList?.forEach((element) => {
  //     let data = {
  //       id: element.id,
  //       customerName: element.customerName ? element.customerName : "-",
  //       dueAmount: element.dueAmount ? element.dueAmount : "-",
  //       amountToBePaid: element.amountToBePaid ? element.amountToBePaid : "-",
  //       contactNumber1: element.contactNumber1 ? element.contactNumber1 : "-",
  //       loanNumber: element.loanNumber ? element.loanNumber : "-",
  //       latestPaymentLinkId: element.latestPaymentLinkId
  //         ? element.latestPaymentLinkId
  //         : "-",
  //       status: element.status ? element.status : "-",
  //       totalCalls: element.totalCalls ? element.totalCalls : "-",
  //       callsToday: element.callsToday ? element.callsToday : "-",
  //       noOfpaymentLinkSent: element.noOfLinkSent ? element.noOfLinkSent : 0,
  //       currentBucketName: element.currentBucketName
  //         ? element.currentBucketName
  //         : "-",
  //       remarks: element.remarks ? element.remarks : "-",
  //       branchName: element.branchName ? element.branchName : "-",
  //       amountPaid: element.amountPaid ? element.amountPaid : 0,
  //       visitStatus: element.visitStatus ? element.visitStatus : "-",
  //       subStatus: element.subStatus ? element.subStatus : "-",
  //       updatedDate:
  //         element.updatedDate !== 0 ? EpochToDate(element.updatedDate) : "-",
  //       lastCallUpdateTime:
  //         element.lastCallUpdateTime !== 0
  //           ? EpochToDate(element.lastCallUpdateTime)
  //           : "-",
  //     };
  //     mgrListData.push(data);
  //   });
  //   setPaymentsDownloadList(mgrListData);
  //   // }
  // }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Customer Name",
        B: "Customer Number",
        C: "Loan Number",
        D: "Current Due Amount",
        E: "Branch",
        F: "CP Name",
        G: "Current Bucket",
       
      },
    ];
    reqPaymentsList?.forEach((row) => {
      mgrTable.push({
        A: row.customerName,
        B: row.customerPhoneNumber,
        C: row.loanNumber,
        D: row.currerntDue,
        E: row.branchName,
        F: row.cpName,
        G: row.currentBucket,
       
        // I: row.latestPaymentLinkId,
      });
    });
    const finalData = [...mgrTable];
    // console.log("finaldata", finalData);
    //create a new workbook
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    // console.log("sheet", sheet);
    XLSX.utils.book_append_sheet(wb, sheet, "Request_Payments");
    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Request_Payments_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Request Payments List Download",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Request_Payments_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  // const handleLoanNumberDetailsModal = () => {
  //   setAllocationDetailsModal(false);
  //   getDailyScheduleDateForFilters(start, end, 0);
  // };

  const sendBulkPaymentLinks = (values) => {
    var minsPayload = {
      allocationIds: selectedIds,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      sendForAllAllocations: isAllIdsSelected,
      timeOut: values.minutes * 60,
    };
    var hoursPayload = {
      allocationIds: selectedIds,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      sendForAllAllocations: isAllIdsSelected,
      timeOut: values.hours * 60 * 60,
    };
    var daysPayload = {
      allocationIds: selectedIds,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      sendForAllAllocations: isAllIdsSelected,
      timeOut: values.days * 24 * 60 * 60,
    };
    console.log(
      "payload ",
      isMinutesSelected === true
        ? minsPayload
        : isDaysSelected === false
        ? hoursPayload
        : daysPayload
    );
    moduleApi
      .postData(
        apiCall.sendBulkPaymentLinks,
        isMinutesSelected === true
          ? minsPayload
          : isDaysSelected === false
          ? hoursPayload
          : daysPayload
      )
      .then((response) => {
        console.log("bulkSMSResponse", response);
        // Bulk Payment Link Request Saved Sucessfully
        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (
            response?.Message === "Bulk Payment Link Request Saved Sucessfully"
          ) {
            setBasicModal(false);
            setSelectedIds([]);
            setIsAllIdsSelected(false);
            // getRequestPaymentsData(
            //   start,
            //   end,
            //   0,
            //   selectedValue,
            //   value,
            //   selectedLinkStatus
            // );
            return swal("Bulk Payment Link Request Sent Successfully", {
              icon: "success",
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  // const optionDates = [
  //   { label: "Greater Than", value: ">" },
  //   { label: "Less than", value: "<" },
  //   { label: "Equal to", value: "=" },
  //   // { label: "Custom", value: 4 },
  // ];

  // const linkStatusOptions = [
  //   { label: "All", value: "" },
  //   { label: "Link Sent", value: "Sent" },
  //   { label: "Link Not Sent", value: "NotSent" },
  // ];

  // const handleChange = (event) => {
  //   const inputValue = event.target.value;
  //   // Check if input value is a whole number
  //   if (/^\d+$/.test(inputValue)) {
  //     setErrorMessage(false);
  //     setValue(inputValue);
  //     // getRequestPaymentsData(
  //     //   start,
  //     //   end,
  //     //   0,
  //     //   selectedValue,
  //     //   inputValue,
  //     //   selectedLinkStatus
  //     // );
  //   } else {
  //     setErrorMessage(true);
  //   }
  // };

  // const getHeaderTemplate=(value)=>{
  //   return (
  //     <div style={{backgroundColor: "rgb(243,240,249)",}}>
  //       {value}
  //     </div>
  //   )
  // }

  const select = {
    persistSelection: true,
    type: "Multiple",
    mode: "Row",
    checkboxOnly: true,
  };

  const handleSelected = (args) => {
    console.log(args);
    if (args.isHeaderCheckboxClicked === true && args.name === "rowSelected") {
      let ids = [...selectedIds];
      reqPaymentsList?.forEach((item) => {
        ids.push(item?.id);
      });
      // console.log(ids);
      setSelectedIds(ids);
    } else if (
      args.isHeaderCheckboxClicked === false &&
      args.name === "rowSelected"
    ) {
      let ids = [...selectedIds];
      ids.push(args?.data?.id);
      // console.log(ids);
      setSelectedIds(ids);
    }
  };

  const handleDeselect = (args) => {
    console.log(args);
    console.log(selectedIds);
    if (
      args.isHeaderCheckboxClicked === true &&
      args.name === "rowDeselected"
    ) {
      setSelectedIds([]);
    } else if (
      args.isHeaderCheckboxClicked === false &&
      args.name === "rowDeselected"
    ) {
      let ids = [...selectedIds];
      let index = ids.findIndex((item) => item === args?.data?.id);
      if (index !== -1) {
        ids.splice(index, 1);
      }
      // console.log(ids);
      setSelectedIds(ids);
    }
  };

  return (
    <div >
      <div style={{marginTop:'-15px'}}>
        <LoadingOverlay
          active={isLoading}
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", float: "right" ,marginBottom:'5px'}}
          >
            <Button
              style={{
                margin: "10px",
                marginTop: "25px",
                // marginRight: "15px",
                float: "right",
                // marginLeft: "10px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="success btn-rounded"
              // onClick={() => {
              //   swal("Payment Link Request/s Sent Successfully", {
              //     icon: "success",
              //   });
              // }}
              onClick={() => {
                if (selectedIds?.length > 0) {
                  setBasicModalForSendPaymentLink(true);
                } else {
                  swal("Please Select Atleast One Record", {
                    icon: "error",
                    dangerMode: true,
                  });
                }
              }}
            >
              <i className="fas fa-external-link-alt"></i>&nbsp;&nbsp;Send
              Payment Links
            </Button>
            {/* <div
              style={{
                float: "right",
                width: "135px",
                margin: "10px",
                height: "38px",
                borderRadius: "3px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value1) => {
                  getRequestPaymentsData(
                    start,
                    end,
                    0,
                    selectedValue,
                    value,
                    value1.value
                  );
                  setSelectedLinkStatus(value1.value);
                }}
                defaultValue={linkStatusOptions[0]}
                options={linkStatusOptions}
              />
            </div> */}

            {/* <div
              style={{
                float: "right",
                width: "130px",
                margin: "10px",
                height: "38px",
                borderRadius: "3px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value1) => {
                  getRequestPaymentsData(start, end, 0, value1.value, value);
                  setSelectedValue(value1.value);
                }}
                defaultValue={optionDates[0]}
                options={optionDates}
                // id="startenddate"
              />
            </div> */}
            {/* <div style={{ display: "flex", flexDirection: "column" }}>
              <input
                type="text"
                style={{
                  width: "200px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                  marginTop: "12px",
                }}
                placeholder="Enter Amount"
                // pattern="^\d+$"
                onChange={handleChange}
              />
              {errorMessage === true && (
                <span
                  className="error"
                  style={{ marginLeft: "10px", marginBottom: "5px" }}
                >
                  Please enter only numeric values
                </span>
              )}
            </div> */}
             
            <div
              style={{
                float: "right",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <label style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  Select Month
                </label>
              <Flatpickr
                className="form-control d-block"
                style={{
                  width: "170px",
                  height: "40px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  textAlignLast: "center",
                }}
                placeholder="Select Month"
                onChange={(value) => {
                  setCustomDatesForGraph(value);
                }}
                options={{
                  defaultDate: new Date(),
                  maxDate: new Date(),
                  minDate: new Date().setMonth(new Date().getMonth() - 6),

                  plugins: [
                    new monthSelectPlugin({
                      shorthand: true, //defaults to false
                      dateFormat: "F Y", //defaults to "F Y"
                      altFormat: "F Y", //defaults to "F Y"
                      theme: "light", // defaults to "light"
                    }),
                  ],
                }}
              />
            </div>
            </div>
            <div>
              <Button
                style={{
                  float: "right",
                  margin: "10px",
                  marginRight: "15px",
                  marginTop: "25px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => handleExport()}
              >
                <i className="fa fa-download"></i>&nbsp;&nbsp;Download
              </Button>
            </div>
          </div>
          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "-5px",
            }}
          >
            <div style={{ overflow: "auto", width: "100%" }}>
              <GridComponent
                dataSource={reqPaymentsList}
                allowSorting={false}
                allowPaging={true}
                allowSelection={true}
                selectionSettings={select}
                rowSelected={handleSelected}
                // selectedRowIndex={}
                // enableVirtualMaskRow
                enableStickyHeader
                rowDeselected={handleDeselect}
                // autoFit
                // ref={(grid) => (gridInstance = grid)}
                // actionBegin={actionBegin}
                pageSettings={{ pageSize: 10, pageCount: 5 }}
                allowFiltering={true}
                filterSettings={filterSettings}
              >
                <ColumnsDirective>
                  <ColumnDirective type="checkbox" width="50"></ColumnDirective>
                  <ColumnDirective
                    field="customerName"
                    headerText="Customer Name"
                    width="180"
                    textAlign="Center"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="customerPhoneNumber"
                    headerText="Customer Number"
                    width="180"
                    // template={callOptionTemplate}
                    textAlign="Center"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="loanNumber"
                    headerText="Loan Number"
                    isPrimaryKey={true}
                    // template={getAllocationDetails}
                    width="180"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    field="currerntDue"
                    headerText="Current Due Amount"
                    width="180"
                    textAlign="Center"
                    allowFiltering={false}
                  ></ColumnDirective>
                  <ColumnDirective
                    field="branchName"
                    headerText="Branch"
                    width="150"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    field="cpName"
                    headerText="CP Name"
                    width="150"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    field="currentBucket"
                    headerText="Current Bucket"
                    width="160"
                    // headerTemplate={()=>(<div style={{display:'flex', flexDirection:'column'}}><span>Current</span><span>Bucket</span></div>)}
                    textAlign="Center"
                  ></ColumnDirective>
                  
                  
                  {/* <ColumnDirective
                  field="currerntDue"
                  headerText="Total Due Amount"
                  width="150"
                  textAlign="center"
                  // headerTemplate={() => {
                  //   // console.log('props',props);
                  // return getHeaderTemplate('Due Amount')
                  // }}
                ></ColumnDirective> */}
                  {/* <ColumnDirective
                  field="visitStatus"
                  headerText="Visit Status"
                  width="180"
                  template={visitStatusTemplate}
                  textAlign="Center"
                  type="string"
                ></ColumnDirective>
                <ColumnDirective
                  field="subStatus"
                  headerText="Sub Status"
                  width="180"
                  template={subStatusTemplate}
                  textAlign="Center"
                  type="string"
                ></ColumnDirective>
                <ColumnDirective
                  field="status"
                  headerText="Allocation Status"
                  width="180"
                  template={allocationStatusTemplate}
                  textAlign="Center"
                  type="string"
                ></ColumnDirective>
                <ColumnDirective
                  field="noOfpaymentLinkSent"
                  headerText="No Of Payment Links Sent"
                  width="180"
                  template={noOfPaymentLinksTemplate}
                  textAlign="Center"
                  allowFiltering={false}
                ></ColumnDirective>
                <ColumnDirective
                  field="updatedDate"
                  headerText="Last Visited Date & Time"
                  width="180"
                  textAlign="Center"
                  allowFiltering={false}
                ></ColumnDirective>
                <ColumnDirective
                  field="lastCallUpdateTime"
                  headerText="Last Called Date & Time"
                  width="180"
                  textAlign="Center"
                  allowFiltering={false}
                ></ColumnDirective>
                <ColumnDirective
                  field="callsToday"
                  headerText="Today Calls"
                  width="180"
                  textAlign="Center"
                  // allowFiltering={false}
                ></ColumnDirective>
                <ColumnDirective
                  field="totalCalls"
                  headerText="Total Calls"
                  width="180"
                  textAlign="Center"
                  // allowFiltering={false}
                ></ColumnDirective>
                <ColumnDirective
                  field="addRemarks"
                  headerText="Remarks"
                  width="100"
                  template={addRemarksTemplate}
                  allowFiltering={false}
                  textAlign="Center"
                ></ColumnDirective>
                {(hasAccess(51) || hasAccess(52)) && (
                  <ColumnDirective
                    field="sendIcon"
                    headerText=""
                    width="100"
                    allowFiltering={false}
                    template={sendPaymentLinkTemplate}
                    textAlign="Center"
                  ></ColumnDirective>
                )}
                {hasAccess(42) && (
                  <ColumnDirective
                    field="callHistory"
                    headerText="Call History"
                    width="140"
                    allowFiltering={false}
                    template={callHistoryTemplate}
                    textAlign="Center"
                  ></ColumnDirective>
                )} */}
                  {/* {hasAccess(42) && (
                  <ColumnDirective
                    field="callIcon"
                    headerText=""
                    width="100"
                    allowFiltering={false}
                    template={callOptionTemplate}
                    textAlign="Center"
                  ></ColumnDirective>
                )} */}
                </ColumnsDirective>
                <Inject services={[Filter, Selection, Page, Sort]} />
              </GridComponent>
              {/* </div>
            </div> */}
              {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements === 0 ? "90px 0px 5px 0px" : "20px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements}
                pageSize={10}
                defaultActivePage={currPage + 1}
                onSelect={onPageChange}
              />
            </div> */}
            </div>
          </div>
          {/* {reqPaymentsList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )} */}
        </LoadingOverlay>
      </div>
      {/* 
      <Modal className="fade" show={basicModalForPaymentLinks} size="lg">
        <Modal.Header>
          <Modal.Title>Payment Link Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModalForPaymentLinks(false);
              getDailyScheduleDateForFilters(  start, end, 0);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          {paymentLinksByAllocationId?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    top: "1px",
                    position: "sticky",
                  }}
                >
                  <tr style={{ textAlign: "center" }}>
                    <th scope="col">Paid Amount</th>
                    <th scope="col">Mobile Number</th>
                    <th scope="col">Remarks</th>
                    <th scope="col">Link Sent Date</th>
                    <th scope="col">Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentLinksByAllocationId.map((item) => (
                    <tr
                      style={{ textAlign: "center", fontWeight: "normal" }}
                      key={item.id}
                    >
                      {item.amount ? <td>{item.amount / 100}</td> : <td>-</td>}
                      {item.customerMobileNumber ? (
                        <td>{item.customerMobileNumber}</td>
                      ) : (
                        <td>-</td>
                      )}
                      {item.remarks ? <td>{item.remarks}</td> : <td>-</td>}
                      {item.createdTime ? (
                        <td>{helper.EpochToDate(item.createdTime)}</td>
                      ) : (
                        <td>-</td>
                      )}
                      {item.paymentStatus === "PAYMENT_ERROR" ? (
                        <td>
                          {" "}
                          <span className="badge badge-rounded badge-danger">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "TRANSACTION_NOT_FOUND" ? (
                        <td>
                          {" "}
                          <span className="badge badge-rounded badge-warning">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "PAYMENT_SUCCESS" ? (
                        <td>
                          {" "}
                          <span className="badge badge-rounded badge-success">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "INTERNAL_SERVER_ERROR" ? (
                        <td>
                          <span className="badge badge-rounded badge-primary">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "UPI_BACKBONE_ERROR" ? (
                        <td>
                          <span className="badge badge-rounded badge-danger">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "REQUEST_TIMEOUT" ? (
                        <td>
                          <span className="badge badge-rounded badge-warning">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus ===
                        "REQUEST_CANCEL_BY_REQUESTER" ? (
                        <td>
                          <span className="badge badge-rounded badge-primary">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <span style={{ textAlignLast: "center" }}>No Data Found</span>
          )}
        </Modal.Body>
      </Modal> */}

      {/* <Modal className="fade" show={basicModalForEdit}>
        <Modal.Header>
          <Modal.Title>Edit Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModalForEdit(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                mobileNumber: dataForEditcustomer?.contactNumber1,
                remarks: dataForEditcustomer?.remarks,
                dueAmount: dataForEditcustomer?.dueAmount,
              }}
              onSubmit={submitRequestPaymentEditDetails}
              validationSchema={validateForEdit}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <div>
                            <TextField
                              isAsterisk={true}
                              label="Mobile Number"
                              name="mobileNumber"
                              type="number"
                            />
                            <TextField
                              isAsterisk={true}
                              label="Due Amount"
                              name="dueAmount"
                              type="number"
                            />
                            <TextField
                              isAsterisk={true}
                              label="Remarks"
                              name="remarks"
                              type="text"
                            />
                          </div>
                          <div>
                            <Button
                              className="col-lg-2 m-2 float-right px-3"
                              type="submit"
                            >
                              Update
                            </Button>
                            <Button
                              className="col-lg-2 m-2 float-right px-3"
                              variant="danger light"
                              onClick={close}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal className="fade" show={basicModalForSendPaymentLink}>
        <Modal.Header>
          <Modal.Title>Send Customer Payment Link/s</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              closeForSendPaymentLink();
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
          <LoadingOverlay
          active={isLoading2}
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
            <Formik
              initialValues={{
                minutes: "",
                hours: "",
                days: "",
                // mobileNumber: "",
                lotName: "",
                // paymentLink: "",
                // dueAmount: "",
              }}
              onSubmit={sendCustomerPaymentLink}
              validationSchema={validate}
            >
              {({ formik, errors, setFieldValue }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <div>
                            {/* <TextField
                              isAsterisk={true}
                              label="Mobile Number"
                              name="mobileNumber"
                              type="number"
                            />
                            <TextField
                              isAsterisk={true}
                              label="Due Amount"
                              name="dueAmount"
                              type="number"
                            /> */}
                            <TextField
                              isAsterisk={true}
                              label="Lot Name"
                              name="lotName"
                              type="text"
                            />
                            {/* {hasAccess(52) && ( */}
                            <div>
                              {/* <span>Expiry Time</span> */}
                              <div style={{ display: "flex", height: "40px" }}>
                                <div
                                  // className="form-check"
                                  onClick={() => {
                                    setIsMinutesSelected(true);
                                    setIsHoursSelected(false);
                                    setIsDaysSelected(false);
                                    setFieldValue("hours", "")
                                    setFieldValue("days", "")
                                  }}
                                  style={{  display:'flex',flexDirection:'row', justifyContent:'center', alignSelf:'center',cursor:'pointer' }}
                                >
                                  <input
                                    // className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                    style={{cursor:'pointer'}}
                                    checked={isMinutesSelected}
                                  />
                                  <label
                                    // className="form-check-label"
                                    style={{marginBottom:'auto', marginLeft:'5px'}}
                                    for="flexRadioDefault1"
                                  >
                                    Minutes
                                  </label>
                                </div>
                                <div
                                  // className="form-check"
                                  onClick={() => {
                                    setIsMinutesSelected(false);
                                    setIsHoursSelected(true);
                                    setIsDaysSelected(false);
                                    setFieldValue("minutes", "")
                                    setFieldValue("days", "")
                                  }}
                                  style={{ margin: "0px 20px", display:'flex',flexDirection:'row', alignSelf:'center', cursor:'pointer' }}
                                >
                                  <input
                                    // className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    style={{cursor:'pointer'}}
                                    checked={isHoursSelected}
                                  />
                                  <label
                                    // className="form-check-label"
                                    style={{marginBottom:'auto', marginLeft:'5px'}}
                                    for="flexRadioDefault2"
                                  >
                                    Hours
                                  </label>
                                </div>
                                <div
                                  // className="form-check"
                                  onClick={() => {
                                    setIsMinutesSelected(false);
                                    setIsHoursSelected(false);
                                    setIsDaysSelected(true);
                                    setFieldValue("hours", "")
                                    setFieldValue("minutes", "")
                                  }}
                                  style={{ margin: "0px 20px" , display:'flex',flexDirection:'row', alignSelf:'center',cursor:'pointer'}}
                                >
                                  <input
                                    // className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault3"
                                    checked={isDaysSelected}
                                    style={{cursor:'pointer'}}
                                  />
                                  <label
                                    // className="form-check-label"
                                    style={{marginBottom:'auto', marginLeft:'5px'}}
                                    for="flexRadioDefault3"
                                  >
                                    Days
                                  </label>
                                </div>
                              </div>
                              {isMinutesSelected ? (
                                <div>
                                  <TextField
                                    isAsterisk={true}
                                    label="Number Of Minutes"
                                    name="minutes"
                                    type="text"
                                  />
                                  <span style={{ fontSize: "smaller" }}>
                                    Must be a number between 1 and 60.
                                  </span>
                                </div>
                              ) : isDaysSelected === false ? (
                                <div>
                                  <TextField
                                    isAsterisk={true}
                                    label="Number of Hours"
                                    name="hours"
                                    type="text"
                                  />
                                  <span style={{ fontSize: "smaller" }}>
                                    Must be a number between 1 and 24.
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <TextField
                                    isAsterisk={true}
                                    label="Number of Days"
                                    name="days"
                                    type="text"
                                  />
                                  <span style={{ fontSize: "smaller" }}>
                                    Must be a number between 1 and 30.
                                  </span>
                                </div>
                              )}
                            </div>
                            {/* )} */}
                            {/* {hasAccess(51) && (
                              <TextField
                                isAsterisk={true}
                                label="Payment Link"
                                name="paymentLink"
                                type="text"
                              />
                            )} */}
                          </div>
                          <div style={{float:'right', marginTop:'10px'}}>
                           
                            <Button
                              className="me-2"
                              variant="danger light"
                              onClick={() => {
                                closeForSendPaymentLink();
                              }}
                            >
                              Close
                            </Button>
                            <Button
                              className="me-2"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
            </LoadingOverlay>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal className="fade" show={addRemarksBasicModal} size="lg">
        <Modal.Header>
          <Modal.Title>Add Remarks</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddRemarksBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <AddRemarks
              allocationId={addRemarksAllocationId}
              callId={null}
              close={handleCloseForRemarks}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <label style={{ fontWeight: "bold" }}>Previous Remarks</label>
              {remarks?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table">
                    <thead
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        top: "1px",
                        position: "sticky",
                      }}
                    >
                      <tr style={{ textAlign: "center" }}>
                        <th scope="col">Remarks</th>
                        <th scope="col">Remarks Added By</th>
                        <th scope="col">Call Status</th>
                        <th scope="col">Status</th>
                        <th scope="col">Sub Status</th>
                        <th scope="col">Amount Paid</th>
                        <th scope="col">transaction Id</th>
                        <th scope="col">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {remarks.map((item) => (
                        <tr
                          style={{ textAlign: "center", fontWeight: "normal" }}
                          key={item.id}
                        >
                          <td>{item.remarks}</td>
                          <td>
                            {item.remarkAddedBy ? item.remarkAddedBy : "-"}
                          </td>
                          <td>{item.remarksType ? item.remarksType : "-"}</td>
                          <td>{item.status ? item.status : "-"}</td>
                          <td>{item.subStatus ? item.subStatus : "-"}</td>
                          <td>{item.amountPaid ? item.amountPaid : "-"}</td>
                          <td>
                            {item.transactionId ? item.transactionId : "-"}
                          </td>
                          {item.dateTime ? (
                            <td>{EpochToDate(item.dateTime)}</td>
                          ) : (
                            <td>-</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                 
                </div>
              ) : (
                <span style={{ textAlignLast: "center" }}>
                  No Previous Remarks Found
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* <Modal className="fade" show={callReportModal} size="lg">
        <Modal.Header>
          <Modal.Title>Call History</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setCallReportModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <CallSummary
            mobileNo={mobileNo?.mobileNo}
            loanNumber={mobileNo?.loanNumber}
          />
        </Modal.Body>
      </Modal> */}

      {/* <Modal className="fade" size="lg" show={allocationDetailsModal}>
        <Modal.Header>
          <Modal.Title>Allocation Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setAllocationDetailsModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{ paddingTop: "0px", maxHeight: "400px", overflowY: "auto" }}
        ></Modal.Body>
        <LoanNumberDetails
          allocationDetails={allocationDetails}
          close={handleLoanNumberDetailsModal}
        />
      </Modal> */}

      <Modal className="fade" size="md" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Send Bulk SMS</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setIsMinutesSelected(true);
              setIsHoursSelected(false);
              setIsDaysSelected(false);
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div>
            <Formik
              initialValues={{
                minutes: "",
                hours: "",
                days: "",
              }}
              onSubmit={sendBulkPaymentLinks}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <div style={{ display: "flex", height: "40px" }}>
                            <div
                              className="form-check"
                              onClick={() => {
                                setIsMinutesSelected(true);
                                setIsHoursSelected(false);
                                setIsDaysSelected(false);
                              }}
                              style={{ margin: "0px 20px" }}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked={isMinutesSelected}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Minutes
                              </label>
                            </div>
                            <div
                              className="form-check"
                              onClick={() => {
                                setIsMinutesSelected(false);
                                setIsHoursSelected(true);
                                setIsDaysSelected(false);
                              }}
                              style={{ margin: "0px 20px" }}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                checked={isHoursSelected}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Hours
                              </label>
                            </div>
                            <div
                              className="form-check"
                              onClick={() => {
                                setIsMinutesSelected(false);
                                setIsHoursSelected(false);
                                setIsDaysSelected(true);
                              }}
                              style={{ margin: "0px 20px" }}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault3"
                                checked={isDaysSelected}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault3"
                              >
                                Days
                              </label>
                            </div>
                          </div>
                          {isMinutesSelected ? (
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Number Of Minutes"
                                name="minutes"
                                type="text"
                              />
                              <span style={{ fontSize: "smaller" }}>
                                Must be a number between 1 and 60.
                              </span>
                            </div>
                          ) : isDaysSelected === false ? (
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Number of Hours"
                                name="hours"
                                type="text"
                              />
                              <span style={{ fontSize: "smaller" }}>
                                Must be a number between 1 and 24.
                              </span>
                            </div>
                          ) : (
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Number of Days"
                                name="days"
                                type="text"
                              />
                              <span style={{ fontSize: "smaller" }}>
                                Must be a number between 1 and 30.
                              </span>
                            </div>
                          )}
                        </div>
                        <div>
                          <Button
                            style={{ float: "right" }}
                            // className="col-lg-2 m-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ float: "right", marginRight: "10px" }}
                            // className="col-lg-2 m-2 float-right"
                            variant="danger light"
                            onClick={() => {
                              setIsMinutesSelected(true);
                              setIsHoursSelected(false);
                              setIsDaysSelected(false);
                              setBasicModal(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
            {/* <form onSubmit={sendBulkPaymentLinks}>
              <div style={{display:'flex', flexDirection:'column'}}>
                <label>
                  <input
                    type="radio"
                    value="minutes"
                    checked={isMinutesSelected}
                    onChange={(e) => {
                      setTimeOption(e.target.value);
                    }}
                  />
                  Minutes
                </label>
                <label>
                  <input
                  style={{marginTop:'5px'}}
                    type="radio"
                    value="hours"
                    checked={!isMinutesSelected}
                    onChange={(e) => {
                        setTimeOption(e.target.value);
                    }}
                  />
                  Hours
                </label>
              </div>
              {isMinutesSelected ? (
                <div style={{display:'flex', flexDirection:'column'}}>
                  <label>
                    Minutes:
                    <input
                      style={{
                        width: "220px",
                        height: "35px",
                        borderRadius: "25px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #3a353b",
                        paddingLeft: "10px",
                        marginLeft: "10px",
                      }}
                      placeholder="Enter Minutes"
                      type="number"
                    //   value={minutes}
                      onChange={(e) => {
                        setMinutes(e.target.value);
                      }}
                      max="1440"
                    />
                  </label>
                </div>
              ) : (
                <div>
                  <label>
                    Hours:
                    <input
                      style={{
                        width: "220px",
                        height: "35px",
                        borderRadius: "25px",
                        borderColor: "#a0a0a0",
                        borderWidth: "1px",
                        boxShadow: "1px 1px 2px #3a353b",
                        paddingLeft: "10px",
                        marginLeft: "10px",
                      }}
                      placeholder="Enter Hours"
                      type="number"
                    //   value={hours}
                      onChange={(e) => {
                        setHours(e.target.value);
                      }}
                      max="24"
                    />
                  </label>
                </div>
              )}
              <Button
                style={{ float: "right", width: "70px" }}
                className="col-lg-2 m-2"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{ float: "right", width: "70px" }}
                className="col-lg-2 m-2 float-right"
                variant="danger light"
                onClick={() => setBasicModal(false)}
              >
                Close
              </Button>
            </form>  */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RequestPayments;
