import React, { useState, useEffect } from "react";
import { Button, Row, OverlayTrigger, Popover } from "react-bootstrap";
import swal from "sweetalert";
import * as moduleApi from "../rest/moduleApi";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "react-loading-overlay";
import { SRLWrapper } from "simple-react-lightbox";
// import {
//   ChipDirective,
//   ChipListComponent,
//   ChipsDirective,
// } from "@syncfusion/ej2-react-buttons";
import defaultImage from "../images/defaultImage.png";
import { apiCall } from "../rest/restApi";
import Select from "react-select";
import { getTokenInLocalStorage } from "../services/AuthService";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import {
  convertEpochToTime2,
  EpochToDate,
  EpochToOnlyDate,
  SalesCallsCountData,
  sendDataToDB,
} from "../CommonComponents/Helper";
import {
  // setAllocationsList,
  setFilteredData,
  // setTempData,
} from "../store/actions/MyAllocationActions";
import { useDispatch, useSelector } from "react-redux";
import { setSalesAllocationsList } from "../store/actions/MySalesAllocationAction";
import {
  setCallAudioModal,
  setCallStatus,
  setIsCallActive,
} from "../store/actions/CallingActions";

const AddRemarks = ({
  allocationId,
  callId,
  close,
  campaignName,
  handleMyAllocations,
  dailingTime,
  ringingTime,
  talkTime,
  holdTime,
  dispoTime,
  startCountdownTimer,
  isProgressiveDialing,
  continueDialing,
  closeMinimizeModal,
  makeAllocationIdNull,
  type,
  callStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [enteredRemarks, setEnteredRemarks] = useState(null);
  const [enteredAmount, setEnteredAmount] = useState(0);
  const [bounceCharges, setBounceCharges] = useState(0);
  const [emiAmount, setEmiAmount] = useState(0);
  const [latePaymentCharges, setLatePaymentCharges] = useState(0);
  const [penaltyCharges, setPenaltyCharges] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [apportionValues, setApportionValues] = useState({
    emiAmount: 0,
    bounceCharges: 0,
    latePaymentCharges: 0,
    penaltyCharges: 0,
    otherCharges: 0,
  });
  const [priorities, setPriorities] = useState([]);
  const [isPriorityBasedApportions, setIsPriorityBasedApportions] =
    useState(false);
  const [enteredTransId, setEnteredTransId] = useState(null);
  const [enteredPtpAmount, setEnteredPtpAmount] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPaymentDate, setSelectedPaymentDate] = useState(null);
  const [selectedPromisedDate, setSelectedPromisedDate] = useState(null);
  const [selectedCallBackDate, setSelectedCallBackDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedCallBackTimeSlot, setSelectedCallBackTimeSlot] =
    useState(null);
  const [selectedPaymentTowards, setSelectedPaymentTowards] = useState(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);
  const [isCallNotConnected, setIsCallNotConnected] = useState(false);
  const [isAllDropdowns, setIsAllDropdowns] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isPtp, setIsPtp] = useState(false);
  const [isContactable, setIsContactable] = useState(false);
  const [isOnlinePayment, setIsOnlinePayment] = useState(false);
  const [isCallBack, setIsCallBack] = useState(false);
  const [collectionStatuses, setCollectionStatuses] = useState([]);
  const [collectionSubStatuses, setCollectionSubStatuses] = useState([]);
  const [callNotConnectedStatuses, setCallNotConnectedStatuses] = useState([]);
  const [paymentTowardsOptions, setPaymentTowardsOptions] = useState([]);
  const [apportionDetails, setApportionDetails] = useState(null);
  const [allocationDetails, setAllocationDetails] = useState(null);
  const [collectionType, setCollectionType] = useState("");
  const [collectionSubType, setCollectionSubType] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [selectedCollectionSubType, setSelectedCollectionSubType] =
    useState(null);
  const {
    // allocationsList,
    filteredData,
  } = useSelector((state) => state.myAllocations);
  const { salesAllocationsList } = useSelector(
    (state) => state.mySalesAllocations
  );
  const { isSalesProgressiveDialing, callType } = useSelector(
    (state) => state.calls
  );

  const userData = getTokenInLocalStorage();
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview("Add Disposition for " + type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCollectionDropdownOptions(callStatus);
    // userData?.selectedTeam?.teamType === "Collections" &&
    getAllocationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const allocationStatuses = [
  //   { label: "Open", value: "OPEN" },
  //   { label: "In Progress", value: "INPROGRESS" },
  //   { label: "Completed", value: "COMPLETED" },
  //   // {label:'Open', value:'open'},
  // ];

  const getCollectionDropdownOptions = (callStatus) => {
    var payload = {
      organizationId:
        userData.autoFinanceUser.roleName !== "CP_USER"
          ? userData.autoFinanceUser.organizationId
          : userData.autoFinanceOrganization.mainOrganizationId,
      callNotConnected: callStatus,
      type: type,
    };

    moduleApi
      .postData(apiCall.getdespositonstatus, payload)
      .then((response) => {
        const result = response.map((item) => ({
          label: item,
          value: item,
        }));
        // result.unshift({ label: "All", value: "All" });
        // console.log('result', result);
        if (callStatus === false) {
          setCollectionStatuses(result);
          setIsAllDropdowns(true);
          setSelectedStatus("Call Connected");
        } else {
          setCallNotConnectedStatuses(result);
          setIsCallNotConnected(true);
          setSelectedStatus("Call Not Connected");
        }
      });
  };

  const getSubStatusCollectionOptions = (subStatus) => {
    var payload = {
      organizationId:
        userData.autoFinanceUser.roleName !== "CP_USER"
          ? userData.autoFinanceUser.organizationId
          : userData.autoFinanceOrganization.mainOrganizationId,
      status: subStatus,
      type: type,
    };

    moduleApi
      .postData(apiCall.getdespositonstatus, payload)
      .then((response) => {
        const result = response.map((item) => ({
          label: item,
          value: item,
        }));
        // result.unshift({ label: "All", value: "" });
        // if (response.length > 0) {
        //   setCollectionSubType("");
        //   setSelectedCollectionSubType(null);
        //   // setIsSubStatus(true);
        // } else {
        //   setCollectionSubType("");
        //   setSelectedCollectionSubType(null);
        //   // setIsSubStatus(false);
        // }
        // console.log('result', result);

        setCollectionSubStatuses(result);
      });
  };

  const remarksOptions = [
    { label: "Call Connected", value: "Call Connected" },
    { label: "Call Not Connected", value: "Call Not Connected" },
    // { label: "Call Not Attended", value: "Call Not Attended" },
    // { label: "Call Again", value: "Call Again" },
  ];
  const paymentModeOptions = [
    { label: "Bank Transfer", value: "Bank Transfer" },
    { label: "UPI", value: "UPI" },
  ];

  const timeSlotOptions = [
    { label: "6AM", value: "6AM" },
    { label: "7AM", value: "7AM" },
    { label: "8AM", value: "8AM" },
    { label: "9AM", value: "9AM" },
    { label: "10AM", value: "10AM" },
    { label: "11AM", value: "11AM" },
    { label: "12PM", value: "12PM" },
    { label: "1PM", value: "1PM" },
    { label: "2PM", value: "2PM" },
    { label: "3PM", value: "3PM" },
    { label: "4PM", value: "4PM" },
    { label: "5PM", value: "5PM" },
    { label: "6PM", value: "6PM" },
    { label: "7PM", value: "7PM" },
    { label: "8PM", value: "8PM" },
    { label: "9PM", value: "9PM" },
  ];

  // const getSubStatusIndex = (subStatus) => {
  //   collectionSubStatuses?.forEach((item, index) => {
  //     if (item === subStatus) {
  //       return index;
  //     }
  //   });
  // };

  const getPaymentTowardsOptions = () => {
    var payload = {
      organizationId: userData.autoFinanceUser.organizationId,
      status: "Paid",
      subStatus: "",
      paymentTowards: "paymentTowards",
      type: type,
    };
    moduleApi
      .postData(apiCall.getdespositonstatus, payload)
      .then((response) => {
        const result = response.map((item) => ({
          label: item,
          value: item,
        }));
        setPaymentTowardsOptions(result);
      });
  };

  const getApportionDetails = () => {
    var payload = {
      id: allocationId,
      organizationId: userData.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(apiCall.getAllocationDetailsById, payload)
      .then((response) => {
        if (response) {
          let data = {
            bounceCharges: response?.bounceChargesToBePaid
              ? response?.bounceChargesToBePaid?.toFixed(2)
              : 0,
            emiAmount: response?.emiAmountToBePaid
              ? response?.emiAmountToBePaid?.toFixed(2)
              : 0,
            latePaymentCharges: response?.latePaymentChargesToBePaid
              ? response?.latePaymentChargesToBePaid?.toFixed(2)
              : 0,
            penaltyCharges: response?.penaltyChargesToBePaid
              ? response?.penaltyChargesToBePaid?.toFixed(2)
              : 0,
            otherCharges: response?.otherChargesToBePaid
              ? response?.otherChargesToBePaid?.toFixed(2)
              : 0,
            amountToBePaid: response.amountToBePaid
              ? response.amountToBePaid?.toFixed(2)
              : 0,
          };
          setApportionDetails(data);
        }
      });
  };

  const getAllocationDetails = () => {
    var payload = {
      id: allocationId,
      organizationId: userData.autoFinanceUser.organizationId,
    };
    moduleApi
      .postData(apiCall.getAllocationDetailsById, payload)
      .then((response) => {
        if (response) {
          setAllocationDetails(response);
        }
      });
  };

  const moveObjectToLast = (id, type) => {
    if (type === "Collections") {
      let temp1 = filteredData;
      // let temp2 = allocationsList;
      const index = temp1.findIndex((obj) => obj.id === id);
      if (index === -1) {
        // console.log(
        //   `Filtered Data Object with id ${id} not found in the array.`
        // );
        return temp1;
      }

      temp1.splice(index, 1);
      dispatch(setFilteredData([]));
      try {
        var payload = {
          id: id,
          organizationId: userData.autoFinanceUser.organizationId,
        };
        // Assuming moduleApi.postData returns a Promise
        moduleApi
          .postData(apiCall.getAllocationDetailsById, payload)
          .then((element) => {
            var data = {
              aggDate: element.aggDate ? EpochToOnlyDate(element.aggDate) : "-",
              amountPaid: element.amountPaid || 0,
              amountToBePaid: element.amountToBePaid || 0,
              branchName: element.branchName,
              callsToday: element.callsToday || 0,
              campaignCallIntiated: element.campaignCallIntiated,
              contactNumber1: element.contactNumber1,
              cpName: element.cpName,
              currentBucketName: element.currentBucketName,
              customerName: element.customerName,
              dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
              dueAmount: element.dueAmount || 0,
              dueDay: element.dueDay || 0,
              id: element.id || "-",
              lastCallUpdateTime: element.lastCallUpdateTime
                ? EpochToDate(element.lastCallUpdateTime)
                : "-",
              updatedDate: element.updatedDate
                ? EpochToDate(element.updatedDate)
                : "-",
              lastPaidDate: element.lastPaidDate
                ? EpochToDate(element.lastPaidDate)
                : "-",
              loanNumber: element.loanNumber,
              noOfLinkSent: element.noOfLinkSent || 0,
              priority: element.priority,
              status: element.status,
              subStatus: element.subStatus,
              teleCallerEmpId: element.teleCallerEmpId,
              teleCallerName: element.teleCallerName,
              totalCalls: element.totalCalls || 0,
              visitStatus: element.visitStatus,
            };
            temp1.push(data);
            // temp2.push(data);
            dispatch(setFilteredData(temp1));
            // dispatch(setTempData(temp1))

            // dispatch(setAllocationsList(temp2));
          });
      } catch (error) {
        console.error("Error fetching allocation details:", error);
        throw error; // Rethrow the error for further handling if necessary
      }
    } else {
      let temp2 = salesAllocationsList;
      // let temp2 = allocationsList;
      const index2 = temp2.findIndex((obj) => obj.id === id);
      if (index2 === -1 && callType !== "incoming") {
        // console.log(`Sales Data Object with id ${id} not found in the array.`);
        return temp2;
      }

      index2 !== -1 && temp2.splice(index2, 1);
      dispatch(setSalesAllocationsList([]));
      try {
        var payload2 = {
          id: id,
          organizationId: userData.autoFinanceUser.organizationId,
        };
        // Assuming moduleApi.postData returns a Promise
        moduleApi
          .postData(apiCall.getSalesAllocationsBasedOnId, payload2)
          .then((element) => {
            var data = {
              callDisposition: element.callDisposition
                ? element.callDisposition
                : "-",
              allocationStatus: element.allocationStatus
                ? element.allocationStatus
                : "-",
              callDispositionSubstatus: element.callDispositionSubstatus
                ? element.callDispositionSubstatus
                : "-",
              callStatus: element.callStatus ? element.callStatus : "-",
              city: element.city ? element.city : "-",
              empId: element.empId ? element.empId : "-",
              empName: element.empName ? element.empName : "-",
              lastCallUpdateTime: element.lastCallUpdateTime
                ? convertEpochToTime2(element.lastCallUpdateTime)
                : "-",
              callsToday: element.callsToday ? element.callsToday : 0,
              customerName: element.customerName ? element.customerName : "-",
              dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
              excelRowNumber: element.excelRowNumber
                ? element.excelRowNumber
                : "-",
              id: element.id ? element.id : "-",
              organizationId: element.organizationId
                ? element.organizationId
                : "-",
              phoneNumber: element.phoneNumber ? element.phoneNumber : "-",
              pinCode: element.pinCode ? element.pinCode : "-",
              sourceChannelId: element.sourceChannelId
                ? element.sourceChannelId
                : "-",
              sourcingChannel: element.sourcingChannel
                ? element.sourcingChannel
                : "-",
              state: element.state ? element.state : "-",
              telecallerEmpId: element.telecallerEmpId
                ? element.telecallerEmpId
                : "-",
              telecallerName: element.telecallerName
                ? element.telecallerName
                : "-",
              totalCalls: element.totalCalls ? element.totalCalls : 0,
              userId: element.userId ? element.userId : "-",
              priority: element.priority,
              validationFailed: element.validationFailed
                ? element.validationFailed
                : "-",
            };
            temp2.push(data);
            // temp2.push(data);
            dispatch(setSalesAllocationsList(temp2));
            // dispatch(setTempData(temp1))

            // dispatch(setAllocationsList(temp2));
          });
      } catch (error) {
        console.error("Error fetching allocation details:", error);
        throw error; // Rethrow the error for further handling if necessary
      }
    }
  };

  // async function getAllocationDetailsById(id) {
  //   try {
  //     var payload = {
  //       id: id,
  //       organizationId: userData.autoFinanceUser.organizationId,
  //     };

  //     // Assuming moduleApi.postData returns a Promise
  //     const response = await moduleApi.postData(
  //       apiCall.getAllocationDetailsById,
  //       payload
  //     );

  //     return response;
  //   } catch (error) {
  //     console.error("Error fetching allocation details:", error);
  //     throw error; // Rethrow the error for further handling if necessary
  //   }
  // }

  // const getAllocationDetailsById = (id)=>{
  //   var payload = {
  //     id: id,
  //     organizationId: userData.autoFinanceUser.organizationId,
  //   };
  //   moduleApi
  //     .postData(apiCall.getAllocationDetailsById, payload)
  //     .then((response) => {
  //       return response
  //     })
  // }

  const getRingTime = (value) => {
    if (value === 0 || value === null || value === undefined) {
      return 0;
    } else if (value.toString().length >= 10 && value.toString().length > 3) {
      return 30;
    } else {
      return value;
    }
  };

  const addAllocationRemarks = () => {
    // if(selectedStatus===)
    setIsLoading(true);
    if (selectedStatus === null) {
      setIsLoading(false);
      return swal("Please Select Call Status", {
        icon: "error",
        dangerMode: true,
      });
    } else if (selectedStatus === "Call Connected") {
      if (collectionType === null || collectionType === "") {
        setIsLoading(false);
        return swal("Please Select Status", {
          icon: "error",
          dangerMode: true,
        });
      } else if (
        collectionSubStatuses.length > 0 &&
        collectionSubType === null
      ) {
        setIsLoading(false);
        return swal("Please Select Sub Status", {
          icon: "error",
          dangerMode: true,
        });
      } else if (collectionType === "Paid" && enteredAmount === 0) {
        setIsLoading(false);
        return swal(
          "Please Provide atleast one Amount Field Value (EMI/Bounce/LatePayment/Penalty/other)",
          {
            icon: "error",
            dangerMode: true,
          }
        );
      } else if (
        collectionType === "Paid" &&
        /^[0-9]\d*(\.\d{1,2})?$/.test(enteredAmount) === false
      ) {
        setIsLoading(false);
        return swal("Please Enter Valid Amount Paid", {
          icon: "error",
          dangerMode: true,
        });
      } else if (collectionType === "Paid" && selectedPaymentTowards === null) {
        setIsLoading(false);
        return swal("Please Select Payment Towards", {
          icon: "error",
          dangerMode: true,
        });
      } else if (
        collectionType === "Paid" &&
        collectionSubType === "Online Payment" &&
        selectedPaymentMode === null
      ) {
        setIsLoading(false);
        return swal("Please Select Payment Mode", {
          icon: "error",
          dangerMode: true,
        });
      }
      // else if(collectionType==="Paid"&& collectionSubType==="Online Payment"&&enteredTransId===null){
      //   return swal("Please Enter Transaction Id", {
      //     icon: "error",
      //     dangerMode: true,
      //   });
      // }
      else if (
        collectionType === "Paid" &&
        collectionSubType === "Online Payment" &&
        selectedPaymentDate === null
      ) {
        setIsLoading(false);
        return swal("Please Select Payment Date", {
          icon: "error",
          dangerMode: true,
        });
      } else if (collectionType === "PTP" && selectedPromisedDate === null) {
        setIsLoading(false);
        return swal("Please Select Promised Payment Date", {
          icon: "error",
          dangerMode: true,
        });
      } else if (collectionType === "PTP" && selectedTimeSlot === null) {
        setIsLoading(false);
        return swal("Please Select Promised Time Slot", {
          icon: "error",
          dangerMode: true,
        });
      } else if (
        collectionType === "Contactable" &&
        collectionSubType === "Call Back" &&
        selectedCallBackDate === null
      ) {
        setIsLoading(false);
        return swal("Please Select Call Back Date", {
          icon: "error",
          dangerMode: true,
        });
      } else if (
        collectionType === "Contactable" &&
        collectionSubType === "Call Back" &&
        selectedCallBackTimeSlot === null
      ) {
        setIsLoading(false);
        return swal("Please Select Call Back Time Slot", {
          icon: "error",
          dangerMode: true,
        });
      } else if (collectionType === "PTP" && enteredPtpAmount === null) {
        setIsLoading(false);
        return swal("Please Enter PTP Amount", {
          icon: "error",
          dangerMode: true,
        });
      } else if (
        collectionType === "PTP" &&
        /^[1-9]\d*(\.\d{1,2})?$/.test(enteredPtpAmount) === false
      ) {
        setIsLoading(false);
        // console.log(/^[1-9]\d*(\.\d{1,2})?$/.test(enteredPtpAmount));
        return swal("Please Enter Valid PTP Amount", {
          icon: "error",
          dangerMode: true,
        });
      } else if (
        collectionType === "PTP" &&
        allocationDetails !== null &&
        enteredPtpAmount > allocationDetails.amountToBePaid
      ) {
        setIsLoading(false);
        return swal("PTP Amount should be less than or equal to current due", {
          icon: "error",
          dangerMode: true,
        });
      } else if (collectionType === "PTP" && enteredPtpAmount > 100000) {
        setIsLoading(false);
        // console.log(/^[1-9]\d*(\.\d{1,2})?$/.test(enteredPtpAmount));
        return swal("PTP Amount should be less than or equal to 1 Lakh", {
          icon: "error",
          dangerMode: true,
        });
      } else if (enteredRemarks === null || enteredRemarks === "") {
        setIsLoading(false);
        return swal("Please Enter Remarks", {
          icon: "error",
          dangerMode: true,
        });
      }
    } else if (collectionType === null) {
      setIsLoading(false);
      return swal("Please Select Status", {
        icon: "error",
        dangerMode: true,
      });
    } else if (
      selectedStatus === "Call Connected" &&
      (enteredRemarks === null || enteredRemarks === "")
    ) {
      setIsLoading(false);
      return swal("Please Enter Remarks", {
        icon: "error",
        dangerMode: true,
      });
    }
    var temp = new Date().getTime();
    var dispositionTime = Math.trunc(temp / 1000) - dispoTime;
    let incomingMobileNo = localStorage
    .getItem("incomingCallMobileNo")
    ?.split("-");
    // console.log("dispoTime1", Math.trunc(temp / 1000));
    // console.log("dispoTime2", dispoTime);
    // console.log("dispoTime3", dispositionTime);

    const payload = {
      // dailingTime: dailingTime,
      ringTimetaken: getRingTime(ringingTime),
      talkTimetaken: talkTime,
      holdTimetaken: parseInt(localStorage.getItem("holdTime")),
      dispTimetaken: dispositionTime,
      allocationId: allocationId,
      campaignName: campaignName,
      // allocationStatus: "string",
      callId: callId !== null ? callId : undefined,
      amountPaid: enteredAmount,
      status: collectionType,
      bounceChargesPaidNow:
        isPriorityBasedApportions === true
          ? apportionValues?.bounceCharges
          : bounceCharges,
      emiAmountPaidNow:
        isPriorityBasedApportions === true
          ? apportionValues?.emiAmount
          : emiAmount,
      otherChargesPaidNow:
        isPriorityBasedApportions === true
          ? apportionValues?.otherCharges
          : otherCharges,
      penaltyChargesPaidNow:
        isPriorityBasedApportions === true
          ? apportionValues?.penaltyCharges
          : penaltyCharges,
      latePaymentChargesPaidNow:
        isPriorityBasedApportions === true
          ? apportionValues?.latePaymentCharges
          : latePaymentCharges,
      subStatus: collectionSubType,
      transactionId: enteredTransId,
      ptpAmount: enteredPtpAmount,
      paymentDate: selectedPaymentDate,
      image:
        collectionType === "Paid" && collectionSubType === "Online Payment"
          ? currentFile
          : undefined,
      paymentMode: selectedPaymentMode,
      paymentTowards: selectedPaymentTowards,
      ptpSlot: selectedTimeSlot,
      toBePaidBy: selectedPromisedDate ? selectedPromisedDate - 19800 : null,
      callBackSlot: selectedCallBackTimeSlot,
      callBackDate: selectedCallBackDate ? selectedCallBackDate - 19800 : null,
      remarkAddedBy: "",
      remarks: enteredRemarks,
      callStatus: selectedStatus,
      userId: userData.autoFinanceUser.userId,
      incomingCall: callType === "incoming" ? true : false,
      customerNumber: callType === "incoming" ? incomingMobileNo[0] : undefined,
    };
    console.log(payload);
    moduleApi
      .postData(
        type === "Collections"
          ? apiCall.addRemarksForAllocation
          : apiCall.addRemarksForAllocationForSales,
        payload
      )
      .then((response) => {
        console.log("response", response);
        if (response) {
          if (response.message) {
            setIsLoading(false);
            swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          } else {
            close();
            callId !== null && moveObjectToLast(allocationId, type);
            dispatch(setCallStatus(true));
            dispatch(setIsCallActive(false));
            // moveObjectToLast(allocationId, "allocationsList");
            // handleMyAllocations();
            type === "Sales" && SalesCallsCountData(dispatch);
            closeMinimizeModal();
            localStorage.removeItem("holdTime");
            setIsLoading(false);
            sendDataToDB("Idle", localStorage.getItem("todayCallsCount"));
            makeAllocationIdNull();
            setIsPriorityBasedApportions(false);
            swal("Remarks Added Successfully", {
              icon: "success",
            }).then((willOk) => {
              if (willOk) {
                dispatch(setCallAudioModal(false));
                if (
                  isProgressiveDialing === true ||
                  isSalesProgressiveDialing === true
                ) {
                  startCountdownTimer();
                  setTimeout(() => {
                    continueDialing();
                    console.log("call triggered");
                  }, 10000);
                }
              }
            });
          }
        } else {
          setIsLoading(false);
          swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const getselectedStatusRelatedThingsDone = (value) => {
    setIsLoading(true);
    setCollectionType(null);
    if (value.value === "Paid") {
      getApportionDetails();
      setIsPaid(true);
      getApportionsPriorities();
      getPaymentTowardsOptions();
      setIsPtp(false);
      setIsContactable(false);
      setIsCallBack(false);
      setEnteredPtpAmount(null);
      setSelectedPromisedDate(null);
      setSelectedTimeSlot(null);
      setSelectedCollectionSubType(null);
      setSelectedCallBackDate(null);
      setSelectedCallBackTimeSlot(null);
    } else if (value.value === "PTP") {
      setApportionDetails(null);
      setApportionValues(null);
      setIsPaid(false);
      setIsContactable(false);
      setIsOnlinePayment(false);
      setIsCallBack(false);
      setEnteredTransId(null);
      setEnteredAmount(null);
      setIsPtp(true);
      setSelectedPaymentDate(null);
      setSelectedPaymentMode(null);
      setSelectedPaymentTowards(null);
      setSelectedCollectionSubType(null);
      setSelectedCallBackDate(null);
      setSelectedCallBackTimeSlot(null);
    } else if (value.value === "Contactable") {
      setApportionDetails(null);
      setApportionValues(null);
      setIsPaid(false);
      setEnteredAmount(null);
      setIsOnlinePayment(false);
      setEnteredTransId(null);
      setIsPtp(false);
      setIsContactable(true);
      setSelectedCollectionSubType(null);
      setSelectedPaymentDate(null);
      setSelectedPaymentMode(null);
      setSelectedPaymentTowards(null);
      setEnteredPtpAmount(null);
      setSelectedPromisedDate(null);
      setSelectedTimeSlot(null);
    } else {
      setApportionDetails(null);
      setApportionValues(null);
      setIsPaid(false);
      setEnteredAmount(null);
      setIsOnlinePayment(false);
      setEnteredTransId(null);
      setIsPtp(false);
      setIsCallBack(false);
      setSelectedCollectionSubType(null);
      setSelectedPaymentDate(null);
      setSelectedPaymentMode(null);
      setSelectedPaymentTowards(null);
      setEnteredPtpAmount(null);
      setSelectedPromisedDate(null);
      setSelectedTimeSlot(null);
      setSelectedCallBackDate(null);
      setSelectedCallBackTimeSlot(null);
    }
    setCollectionType(value.value);
    setCollectionSubType(null);
    // setSelectedCollectionSubType(null)
    getSubStatusCollectionOptions(value.value);
    setIsLoading(false);
  };

  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const handleChange1 = (e) => {
    setIsPreview(true);
    let files = e.target.files;
    if (
      (files[0].type === "image/jpg") |
      (files[0].type === "image/jpeg") |
      (files[0].type === "image/png") |
      (files[0].type === "image/JPEG") |
      (files[0].type === "image/JPG") |
      (files[0].type === "image/PNG")
    ) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setCurrentImage(reader.result);
        });
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
          let fileInfo = {
            base64: reader.result,
          };
          var basedata = fileInfo.base64.split(",");
          var isFilePicked = basedata[1];
          setCurrentFile(isFilePicked);
        }; // reader.onload
      } // for
    } else {
      e.target.files = null;
      swal("Please Select Image Format Only", {
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "-" || event.key === "e" || event.key === "E") {
      event.preventDefault();
    }

    const value = event.target.value;
    const dotIndex = value.indexOf(".");

    if (dotIndex > -1) {
      const decimalPart = value.substring(dotIndex + 1);
      if (decimalPart.length >= 2) {
        event.preventDefault();
      }
    }
    if (parseFloat(value + event.key) > 1000000) {
      event.preventDefault();
    }
  };
  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text");
    const dotIndex = pastedText.indexOf(".");
    const currentValue = event.target.value;
    const decimalPlaces = currentValue.split(".")[1]?.length || 0;

    if (
      pastedText.includes("-") ||
      (dotIndex > -1 && pastedText.substring(dotIndex + 1).length > 2) ||
      decimalPlaces > 2
    ) {
      event.preventDefault();
    }

    let temp = currentValue + pastedText.toString();
    if (temp.includes(".")) {
      if (temp.split(".")[1]?.length > 2) {
        event.preventDefault();
      }
    }

    if (parseFloat(temp) > 1000000) {
      event.preventDefault();
    }
  };

  const getInfoPopover = (heading, content) => {
    return (
      <OverlayTrigger
        trigger="click"
        placement="right"
        responsive={true}
        overlay={
          <Popover
            id={`popover-positioned-right`}
            style={{
              border: "0.125rem solid #483285",
              minWidth: "13.125rem",
              boxShadow: "0 0 0.5rem 0 #483285",
            }}
          >
            <Popover.Title as="h3" style={{ backgroundColor: "#483285" }}>
              {heading}
            </Popover.Title>
            <Popover.Content>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "1.2rem",
                  alignItems: "center",
                }}
              >
                {content}
                {/* <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 200, hide: 200 }}
                              overlay={<Tooltip>Copied</Tooltip>}
                              trigger="click"
                            > */}
                <div className="media-body user-meta-info">
                  <ul>
                    <li>
                      <Link
                        to={"#"}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "35px",
                          width: "35px",
                        }}
                      >
                        <i
                          className="fas fa-copy"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(content);
                          }}
                        ></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* </OverlayTrigger> */}
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <i
          className="fa fa-info-circle"
          style={{
            alignSelf: "center",
            fontSize: "25px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
        ></i>
      </OverlayTrigger>
    );
  };

  const getApportionsPriorities = () => {
    var payload = {
      organizationId: userData?.autoFinanceUser?.organizationId,
    };
    moduleApi
      .postData(apiCall.getApportionConfiguration, payload)
      .then((data) => {
        if (data) {
          if (data?.organizationId !== null) {
            const fetchedPriorities = [
              { name: "emiAmount", priority: data.emiAmountProirity },
              { name: "bounceCharges", priority: data.bounceChargesProirity },
              {
                name: "latePaymentCharges",
                priority: data.latePaymentChargesProirity,
              },
              { name: "penaltyCharges", priority: data.penaltyChargesProirity },
              { name: "otherCharges", priority: data.otherChargesProirity },
            ];

            setPriorities(fetchedPriorities);
            setIsPriorityBasedApportions(true);
          } else {
            setPriorities([]);
            setIsPriorityBasedApportions(false);
          }
        } else {
          return swal(
            "Something Went Wrong While Retrieving Priorities, Please Try Again Later",
            {
              icon: "error",
              dangerMode: true,
            }
          );
        }
      });
  };

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    setEnteredAmount(e.target.value); // Keep input as string for better control
    distributeAmount(value);
  };

  const distributeAmount = (totalAmount) => {
    if (isNaN(totalAmount)) totalAmount = 0;
    let remainingAmount = totalAmount;
    const newDistribution = {
      emiAmount: 0,
      bounceCharges: 0,
      latePaymentCharges: 0,
      penaltyCharges: 0,
      otherCharges: 0,
    };

    const sortedPriorities = priorities?.sort(
      (a, b) => a.priority - b.priority
    );

    for (let i = 0; i < sortedPriorities.length; i++) {
      const { name } = sortedPriorities[i];
      const amount = parseFloat(apportionDetails[name]);

      if (name !== "emiAmount" && amount < 0) {
        newDistribution[name] = 0; // Skip and set to zero for non-EMI negative values
        continue;
      }

      if (amount < 0) {
        newDistribution[name] = parseFloat(amount); // If amount is negative, assign it directly
      } else if (remainingAmount >= amount) {
        newDistribution[name] = parseFloat(amount);
        remainingAmount = parseFloat(remainingAmount) - parseFloat(amount);
      } else {
        newDistribution[name] = parseFloat(remainingAmount);
        remainingAmount = 0;
      }
    }
    if (remainingAmount > 0) {
      newDistribution.emiAmount =
        parseFloat(Math.abs(newDistribution.emiAmount)) +
        parseFloat(remainingAmount);
    }
    // console.log(newDistribution);
    setApportionValues(newDistribution);
  };

  // const handleApportionValueChange = (e, name) => {
  //   const value = parseFloat(e.target.value) || 0;
  //   setApportionValues({
  //     ...apportionValues,
  //     [name]: value,
  //   });
  // };

  return (
    <LoadingOverlay
      active={isLoading}
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div>
        <div>
          <Row>
            <label
              className="control-label"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Call Status
            </label>

            <div
              style={{
                float: "right",
                width: "480px",
                marginRight: "5px",
                height: "38px",
                marginBottom: "5px",
                pointerEvents: callId && "none",
                // cursor:'not-allowed'
                // borderRadius: "3px",
                // borderWidth: "1px",
                // boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                isSearchable={false}
                onChange={(value) => {
                  if (value.value === "Call Connected") {
                    getCollectionDropdownOptions(false);
                    setIsAllDropdowns(true);
                    setCollectionType(null);
                    setIsCallNotConnected(false);
                  } else {
                    setIsCallNotConnected(true);
                    getCollectionDropdownOptions(true);
                    setIsAllDropdowns(false);
                    setIsPaid(false);
                    setIsPtp(false);
                    setIsCallBack(false);
                    setSelectedCallBackDate(null);
                    setSelectedCallBackTimeSlot(null);
                    setIsOnlinePayment(false);
                    setCollectionSubStatuses([]);
                    setCollectionSubType(null);
                    setCollectionType(null);
                    setEnteredAmount(null);
                    setEnteredTransId(null);
                    setSelectedPaymentDate(null);
                    setSelectedPaymentMode(null);
                    setSelectedPaymentTowards(null);
                    setEnteredPtpAmount(null);
                    setSelectedPromisedDate(null);
                    setSelectedTimeSlot(null);
                  }
                  setSelectedStatus(value.value);
                }}
                defaultValue={remarksOptions[callStatus === false ? 0 : 1]}
                options={remarksOptions}
              />
            </div>
            {isCallNotConnected === true && (
              <div
                style={{
                  float: "right",
                  width: "480px",
                  // marginRight: "20px",
                  marginBottom: "10px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  // boxShadow: "1px 1px 2px #797979",
                }}
              >
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Status
                </label>
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    setCollectionType(value.value);
                  }}
                  // defaultValue={{ label: "All", value: "" }}
                  // value={selectedCollectionSubType}
                  options={callNotConnectedStatuses}
                  // id="collectiontype"
                />
              </div>
            )}
            {isAllDropdowns === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "5px" }}
                >
                  Status
                </label>
                {/* <div className="sample-padding">
                  <ChipListComponent
                    id="chip-choice"
                    selection="Single"
                    cssClass="e-outline"
                    onClick={(e) => {
                      console.log(e.target.textContent);
                      getselectedStatusRelatedThingsDone(e);
                    }}
                  >
                    <ChipsDirective>
                      {collectionStatuses?.map((item, index) => {
                        return (
                          <ChipDirective
                            key={index}
                            text={item.label}
                          ></ChipDirective>
                        );
                      })}
                    </ChipsDirective>
                  </ChipListComponent>
                </div> */}

                <div
                  style={{
                    float: "right",
                    width: "480px",
                    // marginRight: "20px",
                    // marginLeft: "10px",
                    marginBottom: "5px",
                    borderRadius: "3px",
                    borderWidth: "1px",
                    // boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      // console.log(value.value);
                      getselectedStatusRelatedThingsDone(value);
                    }}
                    // defaultValue={{ label: "All", value: "All" }}
                    options={collectionStatuses}
                    id="collectiontype"
                  />
                </div>
                {/* {collectionSubStatuses.length > 0 && (
                <>
                  <label className="control-label" style={{ fontWeight: "bold", marginTop: "10px" }}>
                    Sub Status
                  </label>
                  <div className="sample-padding">
                    <ChipListComponent
                      id="chip-choice"
                      // selectedChips={selectedCollectionSubType}
                      selection="Single"
                      cssClass="e-outline"
                      onClick={(e) => {
                        console.log(e);
                        if (e.target.textContent === "Online Payment") {
                          setIsOnlinePayment(true);
                        } else {
                          setIsOnlinePayment(false);
                          setEnteredTransId(null);
                          setSelectedPaymentDate(null);
                          setSelectedPaymentMode(null)
                        }
                        setCollectionSubType(e.target.textContent);
                        // setSelectedCollectionSubType(getSubStatusIndex(e.target.textContent));
                      }}
                    >
                      <ChipsDirective>
                        {collectionSubStatuses?.map((item, index) => {
                          return (
                            <ChipDirective
                              key={index}
                              text={item.label}
                            ></ChipDirective>
                          );
                        })}
                      </ChipsDirective>
                    </ChipListComponent>
                  </div>
                </>
              )} */}
                {collectionSubStatuses.length > 0 && (
                  <div
                    style={{
                      float: "right",
                      width: "480px",
                      // marginRight: "20px",
                      marginBottom: "10px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      // boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <label
                      className="control-label"
                      style={{ fontWeight: "bold", marginTop: "10px" }}
                    >
                      Sub Status
                    </label>
                    <Select
                      isSearchable={true}
                      onChange={(value) => {
                        if (value.value === "Online Payment") {
                          setIsOnlinePayment(true);
                          setIsCallBack(false);
                          setSelectedCallBackDate(null);
                          setSelectedCallBackTimeSlot(null);
                        } else if (value.value === "Call Back") {
                          setIsCallBack(true);
                          setIsOnlinePayment(false);
                          setEnteredTransId(null);
                          setSelectedPaymentDate(null);
                          setSelectedPaymentMode(null);
                        } else {
                          setIsCallBack(false);
                          setIsOnlinePayment(false);
                          setEnteredTransId(null);
                          setSelectedPaymentDate(null);
                          setSelectedPaymentMode(null);
                          setSelectedCallBackDate(null);
                          setSelectedCallBackTimeSlot(null);
                        }
                        setCollectionSubType(value.value);
                        setSelectedCollectionSubType({
                          label: value.label,
                          value: value.value,
                        });
                      }}
                      // defaultValue={{ label: "All", value: "" }}
                      value={selectedCollectionSubType}
                      options={collectionSubStatuses}
                      // id="collectiontype"
                    />
                  </div>
                )}
                {/* <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                Allocation Status
              </label>

              <div
                style={{
                  float: "right",
                  width: "480px",
                  // marginRight: "20px",
                  // marginBottom: "20px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  // boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  isSearchable={false}
                  // onChange={(value) => {
                  //   setCollectionSubType(value.value);
                  //   setSelectedCollectionSubType(value);
                  // }}
                  // defaultValue={{ label: "All", value: "" }}
                  // value={selectedCollectionSubType}
                  options={allocationStatuses}
                  // id="collectiontype"
                />
              </div> */}
              </>
            )}
            {isPaid === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Payment Towards
                </label>
                <div
                  style={{
                    float: "right",
                    width: "480px",
                    marginRight: "5px",
                    height: "38px",
                    marginBottom: "5px",
                    // borderRadius: "3px",
                    // borderWidth: "1px",
                    // boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      setSelectedPaymentTowards(value.value);
                    }}
                    options={paymentTowardsOptions}
                  />
                </div>{" "}
              </>
            )}
            {isPriorityBasedApportions === true && isPaid === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Amount Paid
                </label>
                <input
                  style={{
                    width: "450px",
                    // height: "90px",
                    // cursor: "not-allowed",
                    borderRadius: "7px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    padding: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                  type="number"
                  min="0"
                  max="1000000"
                  onWheel={(event) => event.currentTarget.blur()}
                  // pattern="^[1-9]\d*$"
                  value={enteredAmount}
                  // disabled
                  placeholder="Enter Amount Paid..."
                  onChange={(e) => {
                    // setEnteredAmount(e.target.value);
                    handleAmountChange(e);
                    // handleApportionValueChange()
                  }}
                />
              </>
            )}
            {isPaid === true && (
              <>
                <label
                  // className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  EMI Amount
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    style={{
                      width: "450px",
                      // height: "90px",
                      borderRadius: "7px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      padding: "10px",
                      // marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                    type="number"
                    min="0"
                    max="1000000"
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    disabled={isPriorityBasedApportions}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={
                      isPriorityBasedApportions === true
                        ? apportionValues?.emiAmount
                        : null
                    }
                    // pattern="^[1-9]\d*$"
                    placeholder="Enter EMI Amount..."
                    onChange={(e) => {
                      if (isPriorityBasedApportions === false) {
                        var temp =
                          bounceCharges +
                          latePaymentCharges +
                          otherCharges +
                          penaltyCharges +
                          parseFloat(e.target.value ? e.target.value : "0");
                        setEnteredAmount(temp.toFixed(2));
                        setEmiAmount(
                          parseFloat(e.target.value ? e.target.value : "0")
                        );
                      }
                    }}
                  />
                  {getInfoPopover("EMI Amount", apportionDetails?.emiAmount)}
                </div>
              </>
            )}
            {isPaid === true && (
              <>
                <label
                  // className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Bounce Charges
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    style={{
                      width: "450px",
                      // height: "90px",
                      borderRadius: "7px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      padding: "10px",

                      // marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                    type="number"
                    min="0"
                    max="1000000"
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    disabled={isPriorityBasedApportions}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={
                      isPriorityBasedApportions === true
                        ? apportionValues?.bounceCharges
                        : null
                    }
                    // pattern="^[1-9]\d*$"
                    placeholder="Enter Bounce Charges..."
                    onChange={(e) => {
                      if (isPriorityBasedApportions === false) {
                        var temp =
                          emiAmount +
                          latePaymentCharges +
                          otherCharges +
                          penaltyCharges +
                          parseFloat(e.target.value ? e.target.value : "0");
                        setEnteredAmount(temp.toFixed(2));
                        setBounceCharges(
                          parseFloat(e.target.value ? e.target.value : "0")
                        );
                      }
                    }}
                  />
                  {getInfoPopover(
                    "Bounce Charges",
                    apportionDetails?.bounceCharges
                  )}
                </div>
              </>
            )}
            {isPaid === true && (
              <>
                <label
                  // className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Late Payment Charges
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    style={{
                      width: "450px",
                      // height: "90px",
                      borderRadius: "7px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      padding: "10px",
                      // marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                    type="number"
                    min="0"
                    max="1000000"
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    disabled={isPriorityBasedApportions}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={
                      isPriorityBasedApportions === true
                        ? apportionValues?.latePaymentCharges
                        : null
                    }
                    // pattern="^[1-9]\d*$"
                    placeholder="Enter Late Payment Charges..."
                    onChange={(e) => {
                      if (isPriorityBasedApportions === false) {
                        var temp =
                          bounceCharges +
                          emiAmount +
                          otherCharges +
                          penaltyCharges +
                          parseFloat(e.target.value ? e.target.value : "0");
                        setEnteredAmount(temp.toFixed(2));
                        setLatePaymentCharges(
                          parseFloat(e.target.value ? e.target.value : "0")
                        );
                      }
                    }}
                  />
                  {getInfoPopover(
                    "Late Payment Charges",
                    apportionDetails?.latePaymentCharges
                  )}
                </div>
              </>
            )}
            {isPaid === true && (
              <>
                <label
                  // className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Penalty Charges
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    style={{
                      width: "450px",
                      // height: "90px",
                      borderRadius: "7px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      padding: "10px",
                      // marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                    type="number"
                    min="0"
                    max="1000000"
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    disabled={isPriorityBasedApportions}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={
                      isPriorityBasedApportions === true
                        ? apportionValues?.penaltyCharges
                        : null
                    }
                    // pattern="^[1-9]\d*$"
                    placeholder="Enter Penalty Charges..."
                    onChange={(e) => {
                      if (isPriorityBasedApportions === false) {
                        var temp =
                          bounceCharges +
                          latePaymentCharges +
                          otherCharges +
                          emiAmount +
                          parseFloat(e.target.value ? e.target.value : "0");
                        setEnteredAmount(temp.toFixed(2));
                        setPenaltyCharges(
                          parseFloat(e.target.value ? e.target.value : "0")
                        );
                      }
                    }}
                  />
                  {getInfoPopover(
                    "Penalty Charges",
                    apportionDetails?.penaltyCharges
                  )}
                </div>
              </>
            )}
            {isPaid === true && (
              <>
                <label
                  // className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Other Charges
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    style={{
                      width: "450px",
                      // height: "90px",
                      borderRadius: "7px",
                      borderColor: "#a0a0a0",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #3a353b",
                      padding: "10px",
                      // marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                    type="number"
                    min="0"
                    max="1000000"
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    disabled={isPriorityBasedApportions}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={
                      isPriorityBasedApportions === true
                        ? apportionValues?.otherCharges
                        : null
                    }
                    // pattern="^[1-9]\d*$"
                    placeholder="Enter Other Charges..."
                    onChange={(e) => {
                      if (isPriorityBasedApportions === false) {
                        var temp =
                          bounceCharges +
                          latePaymentCharges +
                          emiAmount +
                          penaltyCharges +
                          parseFloat(e.target.value ? e.target.value : "0");
                        setEnteredAmount(temp.toFixed(2));
                        setOtherCharges(
                          parseFloat(e.target.value ? e.target.value : "0")
                        );
                      }
                    }}
                  />
                  {getInfoPopover(
                    "Other Charges",
                    apportionDetails?.otherCharges
                  )}
                </div>
              </>
            )}
            {isPriorityBasedApportions === false && isPaid === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Amount Paid
                </label>
                <input
                  style={{
                    width: "450px",
                    // height: "90px",
                    cursor: "not-allowed",
                    borderRadius: "7px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    padding: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                  type="number"
                  min="0"
                  max="1000000"
                  onWheel={(event) => event.currentTarget.blur()}
                  // pattern="^[1-9]\d*$"
                  value={enteredAmount}
                  disabled
                  placeholder="Enter Amount Paid..."
                  onChange={(e) => {
                    setEnteredAmount(e.target.value);
                  }}
                />
              </>
            )}

            {isPaid === true && isOnlinePayment === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Payment Mode
                </label>
                <div
                  style={{
                    float: "right",
                    width: "480px",
                    marginRight: "5px",
                    height: "38px",
                    marginBottom: "5px",
                    // borderRadius: "3px",
                    // borderWidth: "1px",
                    // boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      setSelectedPaymentMode(value.value);
                    }}
                    options={paymentModeOptions}
                  />
                </div>{" "}
              </>
            )}
            {isPaid === true && isOnlinePayment === true && (
              <>
                <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                  Transaction Id
                </label>
                <input
                  style={{
                    width: "450px",
                    // height: "90px",
                    borderRadius: "7px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    padding: "10px",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                  placeholder="Enter Transaction Id..."
                  onChange={(e) => {
                    setEnteredTransId(e.target.value);
                  }}
                />{" "}
              </>
            )}
            {isPaid === true && isOnlinePayment === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Payment Date
                </label>
                <input
                  style={{
                    width: "450px",
                    // height: "90px",
                    borderRadius: "7px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    padding: "10px",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                  // defaultValue={new Date().toISOString().split('T')[0]}
                  // min={new Date().toISOString().split('T')[0]}
                  max={new Date().toLocaleDateString("en-CA")}
                  type="date"
                  onChange={(e) => {
                    setSelectedPaymentDate(
                      Math.floor(new Date(e.target.value).getTime() / 1000.0)
                    );
                  }}
                />{" "}
              </>
            )}
            {isPaid === true && isOnlinePayment === true && (
              <>
                <label
                  // className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Upload Payment Receipt
                </label>
                <div
                  style={{
                    alignItems: "left",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SRLWrapper options={options1}>
                    {isPreview ? (
                      <img
                        src={currentImage ? currentImage : defaultImage}
                        className="img-fluid rounded-circle"
                        alt="profile"
                        style={{
                          cursor: "pointer",
                          borderRadius: "50px",
                          height: "100px",
                          width: "100px",
                        }}
                      />
                    ) : (
                      <img
                        // src={currentImage}
                        src={currentImage ? currentImage : defaultImage}
                        className="img-fluid rounded-circle"
                        alt="profile"
                        style={{
                          cursor: "pointer",
                          borderRadius: "50px",
                          height: "100px",
                          width: "100px",
                        }}
                      />
                    )}
                  </SRLWrapper>
                  <input
                    style={{
                      // marginLeft: "150px",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleChange1(e)}
                  />
                </div>{" "}
              </>
            )}

            {isPtp === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Promised Payment Date
                </label>
                <input
                  style={{
                    width: "450px",
                    // height: "90px",
                    borderRadius: "7px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    padding: "10px",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                  type="date"
                  min={new Date().toLocaleDateString("en-CA")}
                  // max={new Date().toISOString().split('T')[0]}
                  onChange={(e) => {
                    setSelectedPromisedDate(
                      Math.floor(new Date(e.target.value).getTime() / 1000.0)
                    );
                  }}
                />{" "}
              </>
            )}

            {isPtp === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Promised Time Slot
                </label>
                <div
                  style={{
                    float: "right",
                    width: "480px",
                    marginRight: "5px",
                    height: "38px",
                    marginBottom: "5px",
                    // borderRadius: "3px",
                    // borderWidth: "1px",
                    // boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      setSelectedTimeSlot(value.value);
                    }}
                    options={timeSlotOptions}
                  />
                </div>{" "}
              </>
            )}
            {isContactable === true && isCallBack === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Call Back Date
                </label>
                <input
                  style={{
                    width: "450px",
                    // height: "90px",
                    borderRadius: "7px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    padding: "10px",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                  type="date"
                  min={new Date().toLocaleDateString("en-CA")}
                  // max={new Date().toISOString().split('T')[0]}
                  onChange={(e) => {
                    setSelectedCallBackDate(
                      Math.floor(new Date(e.target.value).getTime() / 1000.0)
                    );
                  }}
                />{" "}
              </>
            )}

            {isContactable === true && isCallBack === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Call Back Time Slot
                </label>
                <div
                  style={{
                    float: "right",
                    width: "480px",
                    marginRight: "5px",
                    height: "38px",
                    marginBottom: "5px",
                    // borderRadius: "3px",
                    // borderWidth: "1px",
                    // boxShadow: "1px 1px 2px #797979",
                  }}
                >
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      setSelectedCallBackTimeSlot(value.value);
                    }}
                    options={timeSlotOptions}
                  />
                </div>{" "}
              </>
            )}
            {isPtp === true && (
              <>
                <label
                  // className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Current Due
                </label>
                <input
                  style={{
                    width: "450px",
                    // height: "90px",
                    borderRadius: "7px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    padding: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                  disabled
                  defaultValue={allocationDetails?.amountToBePaid}
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Current Due"
                  // onChange={(e) => {
                  //   setEnteredPtpAmount(e.target.value);
                  // }}
                />
              </>
            )}
            {isPtp === true && (
              <>
                <label
                  className="control-label"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  PTP Amount
                </label>
                <input
                  style={{
                    width: "450px",
                    // height: "90px",
                    borderRadius: "7px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    padding: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Enter Amount Paid..."
                  onChange={(e) => {
                    setEnteredPtpAmount(e.target.value);
                  }}
                />
              </>
            )}

            <label
              className={selectedStatus === "Call Connected" && "control-label"}
              style={{ fontWeight: "bold" }}
            >
              Remarks
            </label>
            <textarea
              style={{
                width: "450px",
                height: "90px",
                borderRadius: "7px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                padding: "10px",
                marginLeft: "10px",
              }}
              placeholder="Enter Remarks..."
              onChange={(e) => {
                setEnteredRemarks(e.target.value);
                // console.log("remarks", enteredRemarks);
              }}
            />
            <div style={{ marginTop: "10px" }}>
              <div>
                <Button
                  style={{
                    margin: "10px",
                    float: "right",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => addAllocationRemarks()}
                >
                  <i className="fas fa-plus"></i>&nbsp;&nbsp;Add Remarks
                </Button>
              </div>
            </div>
          </Row>
          {/* </Modal.Body>
        </Modal> */}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default AddRemarks;
