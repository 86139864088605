import React, { useEffect, useState } from "react";
//import SearchBar from "../CommonComponents/SearchBar";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { hasAccess, isAdmin } from "../services/AuthService";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import Flatpickr from "react-flatpickr";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { EpochToDate } from "../CommonComponents/Helper";
//import Select from "react-select";
import swal from "sweetalert";
import { Nav, Tab, Modal, Row, Col } from "react-bootstrap";
import { Form, Formik, Field } from "formik";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import TextField from "../CommonComponents/TextField";
import * as Yup from "yup";
import Select from "react-select";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import ReactGA from "react-ga";
import "../RequestPayments/PaymentDetailsTable.css";
import SearchBar from "../CommonComponents/SearchBar";
import { useLocation } from 'react-router-dom';

const IncentiveMgmt = ({ visitType, isActiveIncentives }) => {
  console.log("visitTypeeeeee", visitType);

  if (visitType === undefined) {
    visitType = "";
    isActiveIncentives = true;
  }

  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // var history = useHistory();
  const [incentivesList, setIncentivesList] = useState([]);
  const [incentiveDetails, setIncentiveDetails] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [addIncentiveModal, setAddIncnetiveModal] = useState(false);
  const [editIncentiveModal, setEditIncentiveModal] = useState(false);
  const [cloneIncentiveModal, setCloneIncentiveModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [incentiveCriteria, setIncentiveCriteria] = useState("");
  const [incentiveSubCriteria, setIncentiveSubCriteria] = useState(null);
  const [selectedSubCriteria, setSelectedSubCriteria] = useState(null);
  // const [incentiveRange, setIncentiveRange] = useState("");
  const [isCriteriaInPercentage, setIsCriteriaInPercentage] = useState(false);
  const [isIncentivePerCaseBasis, setIsIncentivePerCaseBasis] = useState(false);
  const [isIncentivesPaidInPercentage, setIsIncentivesPaidInPercentage] =
    useState(false);
  const [isIncentivesPaidPerCase, setIsIncentivesPaidPerCase] = useState(false);
  const [selectedStatus, setselectedStatus] = useState("");
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  //const [toggle, setToggle] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  // const [isSalesVisit, setSalesVisit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    1000 +
    86399
  );
  const [branches, setBranches] = useState([]);
  const [defaultBranches, setDefaultBranches] = useState([]);
  const [defaultManagers, setDefaultManagers] = useState([]);
  const [defaultDesignations, setDefaultDesignations] = useState([]);
  const [defaultCpNames, setDefaultCpNames] = useState([]);
  const [defaultCurrentBuckets, setDefaultCurrentBuckets] = useState([]);
  const [defaultProductNames, setDefaultProductNames] = useState([]);
  const [defaultIncentiveCriteria, setDefaultIncentiveCriteria] = useState("");
  const [defaultIncentiveSubCriteria, setDefaultIncentiveSubCriteria] =
    useState("");
  // eslint-disable-next-line
  // const [defaultIncentiveRange, setDefaultIncentiveRange] = useState("");
  const [formValues, setFormValues] = useState([
    {
      range: "",
      criteriaValue: "",
      criteriaValue2: "",
      criteriaPercentage: "",
      criteriaPercentage2: "",
      criteriaTargetAmount: "",
      criteriaTargetAmount2: "",
      incentiveAmount: "",
      incentivePerecentage: "",
    },
  ]);

  const [designations, setDesignations] = useState([]);
  const [managers, setManagers] = useState([]);
  const [cpNames, setCpNames] = useState([]);
  const [currentBucketNames, setCurrentBucketNames] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [incentivesDownloadList, setIncentivesDownloadList] = useState(null);
  const allOption = { value: "all", label: "All" };
  const [searchKey, setSearchKey] = useState("");
  const [formValueErrors, setFormValueErrors] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  // useEffect(() => {
  //   ReactGA.pageview("Customer Managemet - " + visitType);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const amountPaidRegex = /^\d+(\.\d{1,2})?$/;
  const percentagePattern = /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?)$/;
  const countRegexPattern = /^\d+$/;
  var incentiveNamePattern = /^.{0,50}$/;

  const validate = Yup.object({
    designations: Yup.array().min(1, "Atleast 1 designation is required"),
    incentiveName: Yup.string()
      .matches(
        incentiveNamePattern,
        "Incentive Name Should not be more than 50 characters"
      )
      .trim()
      .required("Incentive Name is required"),
    incentiveStartDate: Yup.string()
      .trim()
      .required("Incentive Start Date is required"),
    incentiveEndDate: Yup.string()
      .trim()
      .required("Incentive End Date is required"),

  });

  //Schema for the dynamic values
  const createValidationSchema = (range) => {
    return Yup.object().shape({
      range: Yup.string().required("Range Value is required"),
      criteriaValue: Yup.string().when([], {
        is: () =>
          incentiveCriteria !== "Collected Amount" && !isCriteriaInPercentage,
        then: Yup.string()
          .matches(countRegexPattern, "Please Enter Valid Criteria Value")
          .required("Criteria Value is required"),
        otherwise: Yup.string().notRequired(),
      }),
      criteriaValue2: Yup.string().when([], {
        is: () => incentiveCriteria !== "Collected Amount" && !isCriteriaInPercentage && range === "between",
        then: Yup.string()
          .matches(countRegexPattern, "Please Enter Valid Criteria Value 2")
          .required("Criteria Value 2 is required")
          .test('is-greater-than-criteriaValue', 'Criteria Value 2 must be greater than Criteria Value', function (value) {
            const { criteriaValue } = this.parent;
            return !criteriaValue || !value || parseFloat(value) > parseFloat(criteriaValue);
          }),
        otherwise: Yup.string().notRequired(),
      }),

      criteriaPercentage: Yup.string().when([], {
        is: () =>
          incentiveCriteria === "Collected Amount" && isCriteriaInPercentage,
        then: Yup.string()
          .matches(percentagePattern, "Please Enter Valid Percentage")
          .required("Criteria Percentage is required"),
        otherwise: Yup.string().notRequired(),
      }),
      criteriaPercentage2: Yup.string().when([], {
        is: () => incentiveCriteria === "Collected Amount" && isCriteriaInPercentage && range === "between",
        then: Yup.string()
          .matches(percentagePattern, "Please Enter Valid Percentage")
          .required("Criteria Percentage 2 is required")
          .test('is-greater-than-criteriaPercentage', 'Criteria Percentage 2 must be greater than Criteria Percentage', function (value) {
            const { criteriaPercentage } = this.parent;
            return !criteriaPercentage || !value || parseFloat(value) > parseFloat(criteriaPercentage);
          }),
        otherwise: Yup.string().notRequired(),
      }),
      criteriaTargetAmount: Yup.string().when([], {
        is: () =>
          incentiveCriteria === "Collected Amount" && !isCriteriaInPercentage,
        then: Yup.string()
          .matches(amountPaidRegex, "Please Enter Valid Amount")
          .required("Criteria Amount is required"),
        otherwise: Yup.string().notRequired(),
      }),
      criteriaTargetAmount2: Yup.string().when([], {
        is: () => incentiveCriteria === "Collected Amount" && !isCriteriaInPercentage && range === "between",
        then: Yup.string()
          .matches(amountPaidRegex, "Please Enter Valid Amount")
          .required("Criteria Amount 2 is required")
          .test('is-greater-than-criteriaTargetAmount', 'Criteria Amount 2 must be greater than Criteria Amount', function (value) {
            const { criteriaTargetAmount } = this.parent;
            return !criteriaTargetAmount || !value || parseFloat(value) > parseFloat(criteriaTargetAmount);
          }),
        otherwise: Yup.string().notRequired(),
      }),
      incentiveAmount: Yup.string().when([], {
        is: () => !isIncentivesPaidInPercentage,
        then: Yup.string()
          .matches(amountPaidRegex, "Please Enter Valid Amount")
          .required("Incentive Amount is required"),
        otherwise: Yup.string().notRequired(),
      }),
      incentivePerecentage: Yup.string().when([], {
        is: () => isIncentivesPaidInPercentage,
        then: Yup.string()
          .matches(percentagePattern, "Please Enter Valid Percentage")
          .required("Incentive Percentage is required"),
        otherwise: Yup.string().notRequired(),
      }),
    });
  };

  // const data = {
  //   formValues:formValues
  // };

  // validate.validate(data)
  //   .then(valid => {
  //     console.log(valid);
  //   })
  //   .catch(errors => {
  //     console.error(errors);
  //   });

  //Get the validation errors dynamically when the form changes
  useEffect(() => {
    //console.log("Inside the hook ------->");
    // Clear previous errors
    const newFormValueErrors = formValues.map(() => ({}));

    for (let index = 0; index < formValues.length; index++) {
      try {
        const currentFormValue = formValues[index];
        const validationSchema = createValidationSchema(currentFormValue.range);

        validationSchema.validateSync(currentFormValue, {
          abortEarly: false,
        });
        //console.log("Form value at index", index, "is valid");
      } catch (errors) {
        //console.log("Errors in -form value at index", index);
        errors.inner.forEach((error) => {
          const fieldName = error.path;
          if (!newFormValueErrors[index][fieldName]) {
            newFormValueErrors[index][fieldName] = [];
          }
          newFormValueErrors[index][fieldName].push(error.message);
        });
      }
    }
    //Set the netError Values
    setFormValueErrors(newFormValueErrors);
    console.log("Form Value Errors----->", formValueErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, formSubmitted]);

  useEffect(() => {
    loadIncentivesData(0, visitType, start, end);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    incentivesDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incentivesDownloadList]);

  useEffect(() => {
    loadBranches();
    loadDesignations();
    loadManagers();
    loadCpNames();
    loadProductNames();
    loadCurrentBucketNames();

    // eslint-disable-next-line
  }, []);

  const loadIncentivesData = (
    pageNo,
    visitType,
    startDate,
    endDate,
    slctdStatus,
    search
  ) => {
    setIsLoading(true);
    var VisitedPlacesBody = {
      endDate: endDate,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? ""
        : hasAccess(74)
          ? ""
          : loggedInUser.autoFinanceUser.userId,
      startDate: startDate,
      incentiveType: isActiveIncentives ? "" : visitType,
      status: isActiveIncentives ? "ACTIVE" : slctdStatus,
      searchKey: search,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getIncentives, pageNo, 10),
        VisitedPlacesBody
      )
      .then((response) => {
        setCurrPage(pageNo);
        var listData = [];
        response?.content?.forEach((element) => {
          let data = {
            id: element.id ? element.id : "-",
            incentiveName: element.incentiveName ? element.incentiveName : "-",
            branchIds: element.branchIds,
            branchNames: element.branchNames,
            managerIds: element.managerIds,
            managerNames: element.managerNames,
            designations: element.designations,
            cpNames: element.cpNames,
            currentBucketNames: element.currentBucketNames,
            incentiveType: element.incentiveType ? element.incentiveType : "-",
            productType: element.productType ? element.productType : "-",
            productName: element.productName ? element.productName : "-",
            productNames: element.productNames,
            incentiveCriteria: element.incentiveCriteria
              ? element.incentiveCriteria
              : "-",
            incentiveSubCriteria: element.incentiveSubCriteria
              ? element.incentiveSubCriteria
              : "-",
            incentiveStartDate: element.incentiveStartDate
              ? EpochToDate(element.incentiveStartDate)
              : "-",
            incentiveEndDate: element.incentiveEndDate
              ? EpochToDate(element.incentiveEndDate)
              : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            createdBy: element.createdBy ? element.createdBy : "-",
            createdByName: element.createdByName ? element.createdByName : "-",
            dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
            status: element.status ? element.status : "-",
            approvalStatus: element.approvalStatus
              ? element.approvalStatus
              : "-",
            approvalDate: element.approvalDate
              ? EpochToDate(element.approvalDate)
              : "-",
            approvedBy: element.approvedBy ? element.approvedBy : "-",
            approvedByName: element.approvedByName
              ? element.approvedByName
              : "-",
            createdAt: element.createdAt ? element.createdAt : "-",
            updatedAt: element.updatedAt ? element.updatedAt : "-",
            incentivePerCaseBasis: element.incentivePerCaseBasis,
            percentage: element.percentage,
            incentivePaidPerCase: element.incentivePaidPerCase,
            incentivePaidInPercentage: element.incentivePaidInPercentage,
            rejectionReason: element.rejectionReason
              ? element.rejectionReason
              : "-",
            incentiveRangesAndValues: element.incentiveRangesAndValues,
          };
          listData.push(data);
        });
        setIncentivesList(listData);
        setIsLoading(false);

        setTotalElements(response?.totalElements);
      });
  };

  const onPageChange = (selectedPage) => {
    loadIncentivesData(
      selectedPage - 1,
      visitType,
      start,
      end,
      selectedStatus,
      searchKey
    );
  };

  const getReqIds = (list) => {
    let ids = [];
    const reqIds = list ? list : [];
    const size = reqIds.length;
    for (let index = 0; index < size; index++) {
      const element = reqIds[index];
      ids.push(element.value);
    }
    return ids;
  };

  const orderOptions = (values) => {
    return (
      values &&
      values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed))
    );
  };

  const onChange = (selectedOption, { action, removedValue }, form) => {
    //let temp = [];

    switch (action) {
      case "remove-value":
      case "pop-value":
        if (
          selectedOption != null &&
          selectedOption.some((option) => option.value === "all")
        ) {
          form.setFieldValue("branchIds", []);
        } else {
          form.setFieldValue("branchIds", getReqIds(selectedOption));
        }
        break;

      case "select-option":
        if (selectedOption && selectedOption.length > 0) {
          if (selectedOption.some((option) => option.value === "all")) {
            form.setFieldValue("branchIds", []);
          } else {
            form.setFieldValue(
              "branchIds",
              getReqIds(
                selectedOption.filter((option) => option.value !== "all")
              )
            );
          }
        } else {
          form.setFieldValue("branchIds", []);
        }
        break;
      default:
        break;
    }
    selectedOption = orderOptions(selectedOption);
    // setBranchValue(selectedOption);
  };

  const onDesignationChange = (
    selectedOption,
    { action, removedValue },
    form
  ) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        form.setFieldValue("designations", getReqIds(selectedOption));

        break;
      case "select-option":
        if (selectedOption && selectedOption.length > 0) {
          form.setFieldValue("designations", getReqIds(selectedOption));
        }
        break;
      default:
        break;
    }
    selectedOption = orderOptions(selectedOption);
  };

  const onManagerChange = (selectedOption, { action, removedValue }, form) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (
          selectedOption != null &&
          selectedOption.some((option) => option.value === "all")
        ) {
          form.setFieldValue("managerIds", []);
        } else {
          form.setFieldValue("managerIds", getReqIds(selectedOption));
        }
        break;

      case "select-option":
        if (selectedOption && selectedOption.length > 0) {
          if (selectedOption.some((option) => option.value === "all")) {
            form.setFieldValue("managerIds", []);
          } else {
            form.setFieldValue(
              "managerIds",
              getReqIds(
                selectedOption.filter((option) => option.value !== "all")
              )
            );
          }
        } else {
          form.setFieldValue("managerIds", []);
        }
        break;
      default:
        break;
    }
    selectedOption = orderOptions(selectedOption);
  };

  const onCpNameChange = (selectedOption, { action, removedValue }, form) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (
          selectedOption != null &&
          selectedOption.some((option) => option.value === "all")
        ) {
          form.setFieldValue("cpNames", []);
        } else {
          form.setFieldValue("cpNames", getReqIds(selectedOption));
        }
        break;

      case "select-option":
        if (selectedOption && selectedOption.length > 0) {
          if (selectedOption.some((option) => option.value === "all")) {
            form.setFieldValue("cpNames", []);
          } else {
            form.setFieldValue(
              "cpNames",
              getReqIds(
                selectedOption.filter((option) => option.value !== "all")
              )
            );
          }
        } else {
          form.setFieldValue("cpNames", []);
        }
        break;
      default:
        break;
    }
    selectedOption = orderOptions(selectedOption);
  };

  const onCurrentBucketNameChange = (
    selectedOption,
    { action, removedValue },
    form
  ) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (
          selectedOption != null &&
          selectedOption.some((option) => option.value === "all")
        ) {
          form.setFieldValue("currentBucketNames", []);
        } else {
          form.setFieldValue("currentBucketNames", getReqIds(selectedOption));
        }
        break;

      case "select-option":
        if (selectedOption && selectedOption.length > 0) {
          if (selectedOption.some((option) => option.value === "all")) {
            form.setFieldValue("currentBucketNames", []);
          } else {
            form.setFieldValue(
              "currentBucketNames",
              getReqIds(
                selectedOption.filter((option) => option.value !== "all")
              )
            );
          }
        } else {
          form.setFieldValue("currentBucketNames", []);
        }
        break;
      default:
        break;
    }
    selectedOption = orderOptions(selectedOption);
  };

  const onProductNameChange = (
    selectedOption,
    { action, removedValue },
    form
  ) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (
          selectedOption != null &&
          selectedOption.some((option) => option.value === "all")
        ) {
          form.setFieldValue("productNames", []);
        } else {
          form.setFieldValue("productNames", getReqIds(selectedOption));
        }
        break;

      case "select-option":
        if (selectedOption && selectedOption.length > 0) {
          if (selectedOption.some((option) => option.value === "all")) {
            form.setFieldValue("productNames", []);
          } else {
            form.setFieldValue(
              "productNames",
              getReqIds(
                selectedOption.filter((option) => option.value !== "all")
              )
            );
          }
        } else {
          form.setFieldValue("productNames", []);
        }
        break;
      default:
        break;
    }
    selectedOption = orderOptions(selectedOption);
  };

  const updateApprovalStatus = (status) => {
    setIsLoading(true);
    console.log("status", status);
    if (status === "REJECTED" && rejectionReason === null) {
      setIsLoading(false);
      return swal("Please Enter Rejection Reason", {
        icon: "error",
        dangerMode: true,
      });
    }

    var body = {
      approvalStatus: status,
      rejectionReason: rejectionReason,
      organizationId: loggedInUser.autoFinanceOrganization.organizationId,
      id: incentiveDetails.id,
      approvedBy: loggedInUser.autoFinanceUser.userId,
    };

    moduleApi
      .postData(apiCall.updateIncentiveApprovalStatus, body)
      .then((response) => {
        if (response) {
          if (response?.message) {
            setIsLoading(false);
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (
            response?.Message === "Incentive Status Updated successfully"
          ) {
            loadIncentivesData(
              currPage,
              visitType,
              start,
              end,
              selectedStatus,
              searchKey
            );
            setIsLoading(false);
            setBasicModal(false);
            setBasicModal2(false);
            swal("Status Updated Successfully", {
              icon: "success",
            });
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const setIncentiveCriteriaValue = (value) => {
    setIncentiveCriteria(value.value);
    setIncentiveSubCriteria(null);
    setSelectedSubCriteria(null);
    setIsCriteriaInPercentage(false);
    setIsIncentivePerCaseBasis(false);
  };

  const setIncentiveSubCriteriaValue = (value) => {
    setIncentiveSubCriteria(value.value);
    setSelectedSubCriteria({ label: value.label, value: value.value });
  };

  const loadBranches = () => {
    const body = {
      searchKey: "",
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, body).then((response) => {
      const result = response.map((item) => ({
        label: item.branchName,
        value: item.branchId,
        branchId: item.branchId,
      }));
      // setBranchNames(result);
      setBranches(result);
    });
  };

  const loadDesignations = () => {
    moduleApi
      .getData(
        apiCall.getDesignations + loggedInUser.autoFinanceUser.organizationId
      )
      .then((response) => {
        let designation = [];
        for (let i = 0; i < response.length; i++) {
          const result = {
            value: response[i],
            label: response[i],
          };
          designation.push(result);
        }
        setDesignations(designation);
      });
  };

  const loadManagers = () => {
    const payLoad = {
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      roleName: "MANAGER",
    };
    moduleApi.postData(apiCall.usersList, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.userId,
        label: item.name,
      }));
      setManagers(result);
    });
  };

  const loadCurrentBucketNames = () => {
    moduleApi
      .getData(
        apiCall.getcurrentbucketnamesinuppercase +
        loggedInUser.autoFinanceUser.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response.map((item, index) => ({
          value: item,
          label: item,
          index: index,
        }));
        setCurrentBucketNames(result);
      });
  };

  const loadCpNames = () => {
    moduleApi
      .getData(
        apiCall.getCpUsersForDropdown +
        loggedInUser.autoFinanceUser.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response.map((item, index) => ({
          value: item,
          label: item,
          index: index,
        }));

        setCpNames(result);
      });
  };

  const loadProductNames = () => {
    moduleApi
      .getData(
        apiCall.getproductnames + loggedInUser.autoFinanceUser.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response.map((item, index) => ({
          value: item,
          label: item,
          index: index,
        }));

        setProductNames(result);
      });
  };

  const collectionCriteriaOptions = [
    { label: "Collected Amount", value: "Collected Amount" },
    { label: "Visit Count", value: "Visit Count" },
    { label: "Calls Count", value: "Calls Count" },
  ];

  const salesAndFICriteriaOptions = [
    { label: "Visit Count", value: "Visit Count" },
  ];

  const subCriteriaOptionsForCollectedAmount = [
    { label: "EMI Amount", value: "EMI Amount" },
    { label: "Bounce Charges", value: "Bounce Charges" },
    { label: "Late Payment Charges", value: "Late Payment Charges" },
    { label: "Penalty Charges", value: "Penalty Charges" },
    { label: "Other Charges", value: "Other Charges" },
    { label: "Total Amount", value: "Total Amount" },
  ];

  const subCriteriaOptionsForVisitCount = [
    { label: "Total Visits", value: "Total Visits" },
    { label: "Paid Visits", value: "Paid Visits" },
  ];

  const subCriteriaOptionsForCallsCount = [
    { label: "Total Calls", value: "Total Calls" },
    { label: "Paid Calls", value: "Paid Calls" },
  ];

  const rangeOptions = [
    { label: ">=", value: ">=" },
    { label: ">", value: ">" },
    { label: "between", value: "between" },
    { label: "<=", value: "<=" },
    { label: "<", value: "<" },
  ];

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);

    console.log("formValuessssss", formValues);
  };

  const handleSelectChange = (i, e) => {
    const value = e.value;
    let newFormValues = [...formValues];

    newFormValues[i]["range"] = value;

    setFormValues(newFormValues);

    console.log("formValuesss", formValues);
  };

  const hasErrorsInFormValueErrors = () => {
    // Iterate over formValueErrors
    for (const errors of formValueErrors) {
      // Check if the current errors object is not empty
      if (Object.keys(errors).length > 0) {
        return true;
      }
    }
    return false;
  };


  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        range: "",
        criteriaValue: "",
        criteriaValue2: "",
        criteriaPercentage: "",
        criteriaPercentage2: "",
        criteriaTargetAmount: "",
        criteriaTargetAmount2: "",
        incentiveAmount: "",
        incentivePerecentage: "",
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const addIncentive = (values) => {
    setIsLoading(true);

    if (incentiveCriteria === "") {
      setIsLoading(false);
      return swal("Please Select Incentive Criteria", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (
      visitType === "Collections" &&
      (incentiveSubCriteria === undefined ||
        incentiveSubCriteria === null ||
        incentiveSubCriteria === "")
    ) {
      setIsLoading(false);
      return swal("Please Select Incentive Sub Criteria", {
        icon: "error",
        dangerMode: true,
      });
    }

    // if (incentiveRange === "") {
    //   setIsLoading(false);
    //   return swal("Please Select Range", {
    //     icon: "error",
    //     dangerMode: true,
    //   });
    // }

    if (
      Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599 <
      Math.floor(new Date(values.incentiveStartDate).getTime() / 1000) - 19800
    ) {
      setIsLoading(false);
      return swal(
        "Incentive End Date should be greater than or equal to the Start Date.",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }

    const todayDate = new Date(
      new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0)
    );

    const todayStartDate = Math.floor(todayDate.getTime() / 1000);

    if (
      Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599 <
      todayStartDate
    ) {
      setIsLoading(false);
      return swal("Incentive End Date should not be past date", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (hasErrorsInFormValueErrors()) {
      //Return if there are any errors in formValues
      setIsLoading(false);
      return swal("Please ensure all required fields are filled out.", {
        icon: "error",
        dangerMode: true,
      });
    }

    // if (incentiveCriteria !== "Collected Amount" && !isCriteriaInPercentage && incentiveRange === "between" && (values.criteriaValue2 < values.criteriaValue)) {
    //   setIsLoading(false);
    //   return swal("Criteria Value Should Be Less Than Criteria Value 2", {
    //     icon: "error",
    //     dangerMode: true,
    //   });

    // }

    // if (incentiveCriteria === "Collected Amount" && isCriteriaInPercentage && incentiveRange === "between" && (values.criteriaPercentage2 < values.criteriaPercentage)) {
    //   setIsLoading(false);
    //   return swal("Criteria Percentage Should  Be Less Than Criteria Percentage 2", {
    //     icon: "error",
    //     dangerMode: true,
    //   });

    // }

    // if (!isCriteriaInPercentage && incentiveCriteria === "Collected Amount" && incentiveRange === "between" && (values.criteriaTargetAmount2 < values.criteriaTargetAmount)) {
    //   setIsLoading(false);
    //   return swal("Criteria Amount Should Be Less Than Criteria Amount 2", {
    //     icon: "error",
    //     dangerMode: true,
    //   });

    // }

    var body = {
      branchIds: values.branchIds,
      cpNames: visitType === "Collections" ? values.cpNames : null,
      createdBy: loggedInUser.autoFinanceUser.userId,
      currentBucketNames:
        visitType === "Collections" ? values.currentBucketNames : null,
      designations: values.designations,
      incentiveCriteria: incentiveCriteria,
      incentiveEndDate:
        Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599,
      incentiveName: values.incentiveName,
      incentivePaidPerCase: isIncentivesPaidPerCase ? true : false,
      incentivePaidInPercentage: isIncentivesPaidInPercentage ? true : false,
      incentivePerCaseBasis: isIncentivePerCaseBasis ? true : false,
      incentiveStartDate:
        Math.floor(new Date(values.incentiveStartDate).getTime() / 1000) -
        19800,
      incentiveSubCriteria: incentiveSubCriteria,
      incentiveType: visitType,
      managerIds: values.managerIds,
      organizationId: loggedInUser.autoFinanceOrganization.organizationId,
      percentage: isCriteriaInPercentage ? true : false,
      productNames: values.productNames,
      productName: values.productName,
      productType: values.productType,
      incentiveRangesAndValues: formValues,
    };

    console.log("bodyyyyy", body);

    moduleApi.postData(apiCall.addIncentive, body).then((response) => {
      if (response) {
        if (response?.Message === "Incentive Added successfully") {
          setIsLoading(false);
          swal("Incentive Added successfully", {
            icon: "success",
          });
          loadIncentivesData(
            0,
            visitType,
            start,
            end,
            selectedStatus,
            searchKey
          );
          setAddIncnetiveModal(false);
          setIncentiveCriteria("");
          setIncentiveSubCriteria(null);
          setIsCriteriaInPercentage(false);
          setIsIncentivePerCaseBasis(false);
          setIsIncentivesPaidInPercentage(false);
          setRejectionReason(null);
          setFormSubmitted(false);
        } else if (response?.message) {
          setIsLoading(false);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const updateIncentive = (values) => {
    setIsLoading(true);

    if (incentiveCriteria === "") {
      setIsLoading(false);
      return swal("Please Select Incentive Criteria", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (
      visitType === "Collections" &&
      (incentiveSubCriteria === undefined ||
        incentiveSubCriteria === null ||
        incentiveSubCriteria === "")
    ) {
      setIsLoading(false);
      return swal("Please Select Incentive Sub Criteria", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (
      Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599 <
      Math.floor(new Date(values.incentiveStartDate).getTime() / 1000) - 19800
    ) {
      setIsLoading(false);
      return swal(
        "Incentive End Date should be greater than or equal to the Start Date.",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }

    const todayDate = new Date(
      new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0)
    );

    const todayStartDate = Math.floor(todayDate.getTime() / 1000);

    if (
      Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599 <
      todayStartDate
    ) {
      setIsLoading(false);
      return swal("Incentive End Date should not be past date", {
        icon: "error",
        dangerMode: true,
      });
    }
    if (hasErrorsInFormValueErrors()) {
      //Return if there are any errors in formValues
      setIsLoading(false);
      return swal("Please ensure all required fields are filled out.", {
        icon: "error",
        dangerMode: true,
      });
    }

    // if (
    //   incentiveCriteria !== "Collected Amount" &&
    //   !isCriteriaInPercentage &&
    //   incentiveRange === "between" &&
    //   values.criteriaValue2 < values.criteriaValue
    // ) {
    //   setIsLoading(false);
    //   return swal("Criteria Value Should Be Less Than Criteria Value 2", {
    //     icon: "error",
    //     dangerMode: true,
    //   });
    // }

    // if (
    //   incentiveCriteria === "Collected Amount" &&
    //   isCriteriaInPercentage &&
    //   incentiveRange === "between" &&
    //   values.criteriaPercentage2 < values.criteriaPercentage
    // ) {
    //   setIsLoading(false);
    //   return swal(
    //     "Criteria Percentage Should  Be Less Than Criteria Percentage 2",
    //     {
    //       icon: "error",
    //       dangerMode: true,
    //     }
    //   );
    // }

    // if (
    //   !isCriteriaInPercentage &&
    //   incentiveCriteria === "Collected Amount" &&
    //   incentiveRange === "between" &&
    //   values.criteriaTargetAmount2 < values.criteriaTargetAmount
    // ) {
    //   setIsLoading(false);
    //   return swal("Criteria Amount Should Be Less Than Criteria Amount 2", {
    //     icon: "error",
    //     dangerMode: true,
    //   });
    // }

    var body = {
      id: values.id,
      branchIds: values.branchIds,
      cpNames: visitType === "Collections" ? values.cpNames : null,
      createdBy: loggedInUser.autoFinanceUser.userId,
      // criteriaPercentage: isCriteriaInPercentage
      //   ? values.criteriaPercentage
      //   : 0,
      // criteriaPercentage2: isCriteriaInPercentage
      //   ? incentiveRange === "between"
      //     ? values.criteriaPercentage2
      //     : 0
      //   : 0,
      // criteriaTargetAmount:
      //   !isCriteriaInPercentage && incentiveCriteria === "Collected Amount"
      //     ? values.criteriaTargetAmount
      //     : 0,
      // criteriaTargetAmount2:
      //   !isCriteriaInPercentage &&
      //   incentiveCriteria === "Collected Amount" &&
      //   incentiveRange === "between"
      //     ? values.criteriaTargetAmount2
      //     : 0,
      // criteriaValue:
      //   !isCriteriaInPercentage && incentiveCriteria !== "Collected Amount"
      //     ? values.criteriaValue
      //     : 0,
      // criteriaValue2:
      //   !isCriteriaInPercentage &&
      //   incentiveCriteria !== "Collected Amount" &&
      //   incentiveRange === "between"
      //     ? values.criteriaValue2
      //     : 0,
      currentBucketNames:
        visitType === "Collections" ? values.currentBucketNames : null,
      designations: values.designations,
      incentiveAmount: !isIncentivesPaidInPercentage
        ? values.incentiveAmount
        : 0,
      incentiveCriteria: incentiveCriteria,
      incentiveEndDate:
        Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599,
      incentiveName: values.incentiveName,
      incentivePaidPerCase: isIncentivesPaidPerCase ? true : false,
      incentivePaidInPercentage: isIncentivesPaidInPercentage ? true : false,
      incentivePerCaseBasis: isIncentivePerCaseBasis ? true : false,
      incentivePerecentage: isIncentivesPaidInPercentage
        ? values.incentivePerecentage
        : 0,
      incentiveStartDate:
        Math.floor(new Date(values.incentiveStartDate).getTime() / 1000) -
        19800,
      incentiveSubCriteria: incentiveSubCriteria,
      incentiveType: visitType,
      managerIds: values.managerIds,
      organizationId: loggedInUser.autoFinanceOrganization.organizationId,
      percentage: isCriteriaInPercentage ? true : false,
      productNames: values.productNames,
      productName: values.productName,
      productType: values.productType,
      incentiveRangesAndValues: formValues,
    };

    console.log("bodyyyyy", body);

    moduleApi.postData(apiCall.updateIncentive, body).then((response) => {
      if (response) {
        if (response?.Message === "Incentive Updated successfully") {
          setIsLoading(false);
          swal("Incentive Updated successfully", {
            icon: "success",
          });
          loadIncentivesData(
            0,
            visitType,
            start,
            end,
            selectedStatus,
            searchKey
          );
          setEditIncentiveModal(false);
          setIncentiveCriteria("");
          setIncentiveSubCriteria(null);
          setIsCriteriaInPercentage(false);
          setIsIncentivePerCaseBasis(false);
          setIsIncentivesPaidInPercentage(false);
          setRejectionReason(null);
        } else if (response?.message) {
          setIsLoading(false);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const cloneIncentive = (values) => {
    setIsLoading(true);

    if (incentiveCriteria === "") {
      setIsLoading(false);
      return swal("Please Select Incentive Criteria", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (
      visitType === "Collections" &&
      (incentiveSubCriteria === undefined ||
        incentiveSubCriteria === null ||
        incentiveSubCriteria === "")
    ) {
      setIsLoading(false);
      return swal("Please Select Incentive Sub Criteria", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (
      Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599 <
      Math.floor(new Date(values.incentiveStartDate).getTime() / 1000) - 19800
    ) {
      setIsLoading(false);
      return swal(
        "Incentive End Date should be greater than or equal to the Start Date.",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }

    const todayDate = new Date(
      new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0)
    );

    const todayStartDate = Math.floor(todayDate.getTime() / 1000);

    if (
      Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599 <
      todayStartDate
    ) {
      setIsLoading(false);
      return swal("Incentive End Date should not be past date", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (hasErrorsInFormValueErrors()) {
      //Return if there are any errors in formValues
      setIsLoading(false);
      return swal("Please ensure all required fields are filled out.", {
        icon: "error",
        dangerMode: true,
      });
    }

    // if (
    //   incentiveCriteria !== "Collected Amount" &&
    //   !isCriteriaInPercentage &&
    //   incentiveRange === "between" &&
    //   values.criteriaValue2 < values.criteriaValue
    // ) {
    //   setIsLoading(false);
    //   return swal("Criteria Value Should Be Less Than Criteria Value 2", {
    //     icon: "error",
    //     dangerMode: true,
    //   });
    // }

    // if (
    //   incentiveCriteria === "Collected Amount" &&
    //   isCriteriaInPercentage &&
    //   incentiveRange === "between" &&
    //   values.criteriaPercentage2 < values.criteriaPercentage
    // ) {
    //   setIsLoading(false);
    //   return swal(
    //     "Criteria Percentage Should  Be Less Than Criteria Percentage 2",
    //     {
    //       icon: "error",
    //       dangerMode: true,
    //     }
    //   );
    // }

    // if (
    //   !isCriteriaInPercentage &&
    //   incentiveCriteria === "Collected Amount" &&
    //   incentiveRange === "between" &&
    //   values.criteriaTargetAmount2 < values.criteriaTargetAmount
    // ) {
    //   setIsLoading(false);
    //   return swal("Criteria Amount Should Be Less Than Criteria Amount 2", {
    //     icon: "error",
    //     dangerMode: true,
    //   });
    // }

    var body = {
      branchIds: values.branchIds,
      cpNames: visitType === "Collections" ? values.cpNames : null,
      createdBy: loggedInUser.autoFinanceUser.userId,
      currentBucketNames:
        visitType === "Collections" ? values.currentBucketNames : null,
      designations: values.designations,
      incentiveAmount: !isIncentivesPaidInPercentage
        ? values.incentiveAmount
        : 0,
      incentiveCriteria: incentiveCriteria,
      incentiveEndDate:
        Math.floor(new Date(values.incentiveEndDate).getTime() / 1000) + 66599,
      incentiveName: values.incentiveName,
      incentivePaidPerCase: isIncentivesPaidPerCase ? true : false,
      incentivePaidInPercentage: isIncentivesPaidInPercentage ? true : false,
      incentivePerCaseBasis: isIncentivePerCaseBasis ? true : false,
      incentivePerecentage: isIncentivesPaidInPercentage
        ? values.incentivePerecentage
        : 0,
      incentiveStartDate:
        Math.floor(new Date(values.incentiveStartDate).getTime() / 1000) -
        19800,
      incentiveSubCriteria: incentiveSubCriteria,
      incentiveType: visitType,
      managerIds: values.managerIds,
      organizationId: loggedInUser.autoFinanceOrganization.organizationId,
      percentage: isCriteriaInPercentage ? true : false,
      productNames: values.productNames,
      productName: values.productName,
      productType: values.productType,
      incentiveRangesAndValues: formValues,
    };

    console.log("bodyyyyy", body);

    moduleApi.postData(apiCall.cloneIncentive, body).then((response) => {
      if (response) {
        if (response?.Message === "Incentive Cloned successfully") {
          setIsLoading(false);
          swal("Incentive Cloned successfully", {
            icon: "success",
          });
          loadIncentivesData(
            0,
            visitType,
            start,
            end,
            selectedStatus,
            searchKey
          );
          setCloneIncentiveModal(false);
          setIncentiveCriteria("");
          setIncentiveSubCriteria(null);
          setIsCriteriaInPercentage(false);
          setIsIncentivePerCaseBasis(false);
          setIsIncentivesPaidInPercentage(false);
          setRejectionReason(null);
        } else if (response?.message) {
          setIsLoading(false);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const setDefaultValues = (rowDetails) => {
    let defBrnchs = [];
    let defMgrs = [];
    let defDes = [];
    let defCpNames = [];
    let defCurrentBucketNames = [];
    let defProductNames = [];

    setDefaultBranches([]);
    setDefaultManagers([]);
    setDefaultDesignations([]);
    setDefaultDesignations([]);
    setDefaultCpNames([]);
    setDefaultCurrentBuckets([]);
    setDefaultProductNames([]);

    var i = 0;
    for (i = 0; i < rowDetails.branchIds.length; i++) {
      var brnchObj = {
        value: rowDetails.branchIds[i],
        label: rowDetails.branchNames[i],
      };
      defBrnchs.push(brnchObj);
      setDefaultBranches(defBrnchs);
    }

    for (i = 0; i < rowDetails.managerIds.length; i++) {
      var mgrObj = {
        value: rowDetails.managerIds[i],
        label: rowDetails.managerNames[i],
      };
      defMgrs.push(mgrObj);
      setDefaultManagers(defMgrs);
    }

    for (i = 0; i < rowDetails.designations.length; i++) {
      var desObj = {
        value: rowDetails.designations[i],
        label: rowDetails.designations[i],
      };
      defDes.push(desObj);
      setDefaultDesignations(defDes);
    }

    for (i = 0; i < rowDetails.cpNames.length; i++) {
      var cpNameObj = {
        value: rowDetails.cpNames[i],
        label: rowDetails.cpNames[i],
      };
      defCpNames.push(cpNameObj);
      setDefaultCpNames(defCpNames);
    }

    for (i = 0; i < rowDetails.currentBucketNames.length; i++) {
      var currentBktObj = {
        value: rowDetails.currentBucketNames[i],
        label: rowDetails.currentBucketNames[i],
      };
      defCurrentBucketNames.push(currentBktObj);
      setDefaultCurrentBuckets(defCurrentBucketNames);
    }

    for (i = 0; i < rowDetails.productNames.length; i++) {
      var productNameObj = {
        value: rowDetails.productNames[i],
        label: rowDetails.productNames[i],
      };
      defProductNames.push(productNameObj);
      setDefaultProductNames(defProductNames);
    }

    if (rowDetails.branchIds.length === 0) {
      setDefaultBranches([{ value: "all", label: "All" }]);
    }

    if (rowDetails.managerIds.length === 0) {
      setDefaultManagers([{ value: "all", label: "All" }]);
    }

    if (rowDetails.designations.length === 0) {
      setDefaultDesignations([{ value: "all", label: "All" }]);
    }

    if (rowDetails.cpNames.length === 0) {
      setDefaultCpNames([{ value: "all", label: "All" }]);
    }

    if (rowDetails.currentBucketNames.length === 0) {
      setDefaultCurrentBuckets([{ value: "all", label: "All" }]);
    }

    if (rowDetails.productNames.length === 0) {
      setDefaultProductNames([{ value: "all", label: "All" }]);
    }

    setDefaultIncentiveCriteria({
      label: rowDetails.incentiveCriteria,
      value: rowDetails.incentiveCriteria,
    });
    setIncentiveCriteria(rowDetails.incentiveCriteria);

    setDefaultIncentiveSubCriteria({
      label: rowDetails.incentiveSubCriteria,
      value: rowDetails.incentiveSubCriteria,
    });
    setIncentiveSubCriteria(rowDetails.incentiveSubCriteria);

    setIsCriteriaInPercentage(rowDetails.percentage ? true : false);
    setIsIncentivePerCaseBasis(rowDetails.incentivePerCaseBasis ? true : false);
    setIsIncentivesPaidInPercentage(
      rowDetails.incentivePaidInPercentage ? true : false
    );
    setIsIncentivesPaidPerCase(rowDetails.incentivePaidPerCase ? true : false);

    setFormValues(rowDetails.incentiveRangesAndValues);
  };

  const setDefaultValuesForClone = (rowDetails) => {
    let defBrnchs = [];
    let defMgrs = [];
    let defDes = [];
    let defCpNames = [];
    let defCurrentBucketNames = [];
    let defProductNames = [];

    setDefaultBranches([]);
    setDefaultManagers([]);
    setDefaultDesignations([]);
    setDefaultDesignations([]);
    setDefaultCpNames([]);
    setDefaultCurrentBuckets([]);
    setDefaultProductNames([]);

    var i = 0;
    for (i = 0; i < rowDetails.branchIds.length; i++) {
      var brnchObj = {
        value: rowDetails.branchIds[i],
        label: rowDetails.branchNames[i],
      };
      defBrnchs.push(brnchObj);
      setDefaultBranches(defBrnchs);
    }

    for (i = 0; i < rowDetails.managerIds.length; i++) {
      var mgrObj = {
        value: rowDetails.managerIds[i],
        label: rowDetails.managerNames[i],
      };
      defMgrs.push(mgrObj);
      setDefaultManagers(defMgrs);
    }

    for (i = 0; i < rowDetails.designations.length; i++) {
      var desObj = {
        value: rowDetails.designations[i],
        label: rowDetails.designations[i],
      };
      defDes.push(desObj);
      setDefaultDesignations(defDes);
    }

    for (i = 0; i < rowDetails.cpNames.length; i++) {
      var cpNameObj = {
        value: rowDetails.cpNames[i],
        label: rowDetails.cpNames[i],
      };
      defCpNames.push(cpNameObj);
      setDefaultCpNames(defCpNames);
    }

    for (i = 0; i < rowDetails.currentBucketNames.length; i++) {
      var currentBktObj = {
        value: rowDetails.currentBucketNames[i],
        label: rowDetails.currentBucketNames[i],
      };
      defCurrentBucketNames.push(currentBktObj);
      setDefaultCurrentBuckets(defCurrentBucketNames);
    }

    for (i = 0; i < rowDetails.productNames.length; i++) {
      var productNameObj = {
        value: rowDetails.productNames[i],
        label: rowDetails.productNames[i],
      };
      defProductNames.push(productNameObj);
      setDefaultProductNames(defProductNames);
    }

    if (rowDetails.branchIds.length === 0) {
      setDefaultBranches([{ value: "all", label: "All" }]);
    }

    if (rowDetails.managerIds.length === 0) {
      setDefaultManagers([{ value: "all", label: "All" }]);
    }

    if (rowDetails.designations.length === 0) {
      setDefaultDesignations([{ value: "all", label: "All" }]);
    }

    if (rowDetails.cpNames.length === 0) {
      setDefaultCpNames([{ value: "all", label: "All" }]);
    }

    if (rowDetails.currentBucketNames.length === 0) {
      setDefaultCurrentBuckets([{ value: "all", label: "All" }]);
    }

    if (rowDetails.productNames.length === 0) {
      setDefaultProductNames([{ value: "all", label: "All" }]);
    }

    setDefaultIncentiveCriteria({
      label: rowDetails.incentiveCriteria,
      value: rowDetails.incentiveCriteria,
    });
    setIncentiveCriteria(rowDetails.incentiveCriteria);

    setDefaultIncentiveSubCriteria({
      label: rowDetails.incentiveSubCriteria,
      value: rowDetails.incentiveSubCriteria,
    });
    setIncentiveSubCriteria(rowDetails.incentiveSubCriteria);

    setIsCriteriaInPercentage(false);
    setIsIncentivePerCaseBasis(false);
    setIsIncentivesPaidInPercentage(false);
    setIsIncentivesPaidPerCase(false);
    setFormValues([
      {
        range: "",
        criteriaValue: "",
        criteriaValue2: "",
        criteriaPercentage: "",
        criteriaPercentage2: "",
        criteriaTargetAmount: "",
        criteriaTargetAmount2: "",
        incentiveAmount: "",
        incentivePerecentage: "",
      },
    ]);
  };

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
      1000 +
      86399;

    setStart(start);
    setEnd(end);
    loadIncentivesData(0, visitType, start, end, selectedStatus, searchKey);
  };

  const incentivesDownloadData = () => {
    setIsLoading(true);
    var payload = {
      endDate: end,
      organizationId: loggedInUser.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? ""
        : hasAccess(74)
          ? ""
          : loggedInUser.autoFinanceUser.userId,
      startDate: start,
      incentiveType: isActiveIncentives ? "" : visitType,
      status: isActiveIncentives ? "ACTIVE" : "",
    };
    moduleApi
      .postData(moduleApi.withPage(apiCall.getIncentives, 0, 10), payload)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willContinue) => {
              if (willContinue) {
                setIsLoading(true);
                getIncentivesFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getIncentivesFullResponseData(
            Math.floor(response.totalElements / 100)
          );
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getIncentivesFullResponseData(size) {
    var tempList = [];
    var incntveListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        endDate: end,
        organizationId: loggedInUser.autoFinanceUser.organizationId,
        reportingOfficerId: isAdmin()
          ? ""
          : hasAccess(74)
            ? ""
            : loggedInUser.autoFinanceUser.userId,
        startDate: start,
        incentiveType: isActiveIncentives ? "" : visitType,
        status: isActiveIncentives ? "ACTIVE" : "",
      };
      await moduleApi
        .postData(moduleApi.withPage(apiCall.getIncentives, i, 100), payload)
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        id: element.id ? element.id : "-",
        incentiveName: element.incentiveName ? element.incentiveName : "-",
        branchIds: element.branchIds,
        branchNames: element.branchNames,
        managerIds: element.managerIds,
        managerNames: element.managerNames,
        designations: element.designations,
        cpNames: element.cpNames,
        currentBucketNames: element.currentBucketNames,
        incentiveType: element.incentiveType ? element.incentiveType : "-",
        productType: element.productType ? element.productType : "-",
        productName: element.productName ? element.productName : "-",
        productNames: element.productNames ? element.productNames : "-",
        incentiveCriteria: element.incentiveCriteria
          ? element.incentiveCriteria
          : "-",
        incentiveSubCriteria: element.incentiveSubCriteria
          ? element.incentiveSubCriteria
          : "-",
        criteriaPercentage: element.criteriaPercentage
          ? element.criteriaPercentage
          : "-",
        criteriaValue: element.criteriaValue ? element.criteriaValue : "-",
        range: element.range ? element.range : "-",
        incentiveStartDate: element.incentiveStartDate
          ? EpochToDate(element.incentiveStartDate)
          : "-",
        incentiveEndDate: element.incentiveEndDate
          ? EpochToDate(element.incentiveEndDate)
          : "-",
        organizationId: element.organizationId ? element.organizationId : "-",
        createdBy: element.createdBy ? element.createdBy : "-",
        createdByName: element.createdByName ? element.createdByName : "-",
        dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
        status: element.status ? element.status : "-",
        approvalStatus: element.approvalStatus ? element.approvalStatus : "-",
        approvalDate: element.approvalDate
          ? EpochToDate(element.approvalDate)
          : "-",
        approvedBy: element.approvedBy ? element.approvedBy : "-",
        approvedByName: element.approvedByName ? element.approvedByName : "-",
        incentivePerecentage: element.incentivePerecentage
          ? element.incentivePerecentage
          : "-",
        incentiveAmount: element.incentiveAmount
          ? element.incentiveAmount
          : "-",
        criteriaTargetAmount: element.criteriaTargetAmount
          ? element.criteriaTargetAmount
          : "-",
        createdAt: element.createdAt ? element.createdAt : "-",
        updatedAt: element.updatedAt ? element.updatedAt : "-",
        incentivePerCaseBasis: element.incentivePerCaseBasis,
        percentage: element.percentage,
        incentivePaidInPercentage: element.incentivePaidInPercentage,
        rejectionReason: element.rejectionReason
          ? element.rejectionReason
          : "-",
        criteriaValue2: element.criteriaValue2 ? element.criteriaValue2 : "-",
        criteriaPercentage2: element.criteriaPercentage2
          ? element.criteriaPercentage2
          : "-",
        criteriaTargetAmount2: element.criteriaTargetAmount2
          ? element.criteriaTargetAmount2
          : "-",
        incentiveRangesAndValues: element.incentiveRangesAndValues,
      };
      incntveListData.push(data);
    });
    setIncentivesDownloadList(incntveListData);
  }

  const handleExport = () => {
    let incentiveTable = [
      {
        A: "Incentive Name",
        B: "Incentive Criteria",
        C: "Incentive Sub Criteria",
        D: "Incentive Start Date",
        E: "Incentive End Date",
        F: "Created By",
        G: "Created Date And Time",
        H: "Incentive Status",
        I: "Approval Status",
        J: "Approval Date And Time",
        K: "Approved By",
      },
    ];

    incentivesDownloadList?.forEach((row) => {
      incentiveTable.push({
        A: row.incentiveName,
        B: row.incentiveCriteria,
        C: row.incentiveSubCriteria,
        D: row.incentiveStartDate,
        E: row.incentiveEndDate,
        F: row.createdByName,
        G: row.dateTime,
        H: row.status,
        I: row.approvalStatus,
        J: row.approvalDate,
        K: row.approvedByName,
      });
    });

    const finalData = [...incentiveTable];

        // Convert JSON data to CSV
    //     const csvData = finalData.map(row => 
    //       Object.values(row).map(value => `"${String(value).replace(/"/g, '""')}"`).join(",")
    //     ).join("\n");   

    // // Create a Blob from the CSV string
    // const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    // const url = URL.createObjectURL(blob);

    // // Create a link element and trigger download
    // const link = document.createElement('a');
    // link.setAttribute('href', url);
    // link.setAttribute('download', 'Incentive_Details_' + helper.EpochToDate(Math.floor(new Date() / 1000)) + '.csv');
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    // ReactGA.event({
    //   category: "Incentives List Download",
    //   action: loggedInUser.autoFinanceOrganization.orgshortcode + "_Incentive_Details_" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".csv",
    // });

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Incentive Details");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Incentive_Details_" +
      helper.EpochToDate(Math.floor(new Date() / 1000)) +
      ".xlsx"
    );
    ReactGA.event({
      category: "Incentives List Downlaod",
      action:
        loggedInUser.autoFinanceOrganization.orgshortcode +
        "_Incentive_Details_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const columns = [
    {
      text: "Incentive Name",
      dataField: "incentiveName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Incentive</span>
          <br></br>
          <span>Criteria</span>
        </div>
      ),
      dataField: "incentiveCriteria",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Incentive</span>
          <br></br>
          <span>Sub Criteria</span>
        </div>
      ),
      dataField: "incentiveSubCriteria",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Incentive</span>
          <br></br>
          <span>Start Date</span>
        </div>
      ),
      dataField: "incentiveStartDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Incentive</span>
          <br></br>
          <span>End Date</span>
        </div>
      ),
      dataField: "incentiveEndDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Created</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Incentive</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE") {
          return (
            <span className="badge badge-rounded badge-success">Active</span>
          );
        } else if (row.status === "PENDING") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              Pending
            </span>
          );
        } else if (row.status === "INACTIVE") {
          return (
            <span className="badge badge-rounded badge-danger">InActive</span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Approval</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "approvalStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.approvalStatus === "APPROVED") {
          return (
            <span className="badge badge-rounded badge-success">Approved</span>
          );
        } else if (row.approvalStatus === "REJECTED") {
          return (
            <span className="badge badge-rounded badge-danger">Rejected</span>
          );
        } else if (row.approvalStatus === "PENDING") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              Pending
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Approval</span>
          <br></br>
          <span>Date & Time</span>
        </div>
      ),
      dataField: "approvalDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Approved By",
      dataField: "approvedByName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.id === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setIncentiveDetails(row);
          setBasicModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.approvalStatus !== "PENDING" || row.status !== "PENDING") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setIncentiveDetails(row);
          setDefaultValues(row);
          setEditIncentiveModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fa fa-clone"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setIncentiveDetails(row);
          setDefaultValuesForClone(row);
          setCloneIncentiveModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "INACTIVE") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fas fa-trash" />
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          swal({
            title: "Delete",
            text: "Are you sure, you want to Inactivate the incentive?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              var payLoad = {
                organizationId: loggedInUser.autoFinanceUser.organizationId,
                inactivatedBy: loggedInUser.autoFinanceUser.userId,
                status: "INACTIVE",
                id: row.id,
              };
              moduleApi
                .postData(apiCall.inActivateIncentive, payLoad)
                .then((response) => {
                  if (
                    response?.Message ===
                    "Incentive Status Updated successfully"
                  ) {
                    loadIncentivesData(
                      0,
                      visitType,
                      start,
                      end,
                      selectedStatus,
                      searchKey
                    );
                    swal("Incentive Inactivated successfully", {
                      icon: "success",
                    });
                  } else if (response?.Message) {
                    swal(response.Message, {
                      icon: "error",
                      dangerMode: true,
                    });
                  }
                });
            }
          });
        },
      },
    },
  ];

  const setStatusValue = (value) => {
    setselectedStatus(value);
    loadIncentivesData(currPage, visitType, start, end, value, searchKey);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadIncentivesData(currPage, visitType, start, end, selectedStatus, key);
  };

  const statusOptions = [
    { label: "All", value: "" },
    { label: "Pending", value: "PENDING" },
    { label: "Active", value: "ACTIVE" },
    { label: "Inactive", value: "INACTIVE" },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          //   marginTop: "20px",
          marginBottom: "15px",
          marginRight: "15px",
        }}
      >
        {/* <SearchBar
          placeholder="Search By Name/Contact/Loan No"
          searchKeyword={searchKeyword}
        /> */}
        {/* {hasAccess(42) && (
          <div
            style={{
              //float: "right",
              //margin: "10px",
              // height:'10px',
              // width:'35px',
              marginBottom: "5px",
              marginRight: "5px",
              marginLeft: "10px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
          >
            <Button
              className="me-2"
              variant="success btn-rounded"
              onClick={() => {
                // piopiy.call('917036478282')
                // setCallAudioModal(true);
                openModal();
              }}
            >
              <i className="fa fa-phone fa-1x fa-rotate-90" />
              &nbsp;Call
            </Button>
          </div>
        )} */}
      </div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginBottom: "5px",
            marginRight: "20px",
          }}
        >
          {!isActiveIncentives && (
            <div
              style={{
                float: "right",
                marginRight: "5px",
                flexDirection: "row",
              }}
            >
              <SearchBar
                id="ncentivesSearch"
                placeholder="Search by Incentive Name/Branch Name/Cp Name"
                searchKeyword={searchKeyword}
              />
            </div>
          )}
          {!isActiveIncentives && (
            <div
              style={{
                display: "flex",
                float: "right",
                marginBottom: "5px",
                marginRight: "10px",
                textAlignLast: "center",
              }}
            >
             
              <div
                style={{
                  float: "right",
                  width: "130px",
                  height: "38px",
                  flexDirection: "row",
                  marginRight: "10px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #797979",
                }}
              >
                <Select
                  id="statusFilter"
                  isSearchable={false}
                  onChange={(value) => {
                    setStatusValue(value.value);
                  }}
                  defaultValue={statusOptions[0]}
                  options={statusOptions}
                />
              </div>
            </div>
          )}
          {!isActiveIncentives && (
            <Button
              color="primary"
              backgroundColor="#fff"
              border="1px solid #cfcfcf"
              type="button"
              style={{
                marginRight: "10px",
                marginBottom: "20px",
                float: "right",
                //display: "flex",
              }}
              onClick={() => {
                setFormValues([
                  {
                    range: "",
                    criteriaValue: "",
                    criteriaValue2: "",
                    criteriaPercentage: "",
                    criteriaPercentage2: "",
                    criteriaTargetAmount: "",
                    criteriaTargetAmount2: "",
                    incentiveAmount: "",
                    incentivePerecentage: "",
                  },
                ]);
                setAddIncnetiveModal(true);
              }}
            >
              <b> + Add Incentive </b>
            </Button>
          )}
          {!isActiveIncentives && (
            <Flatpickr
              className="form-control d-block"
              style={{
                width: "170px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
                marginBottom: "5px",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value);
              }}
              options={{
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          )}

          <Button
            style={{
              marginRight: "10px",
              marginLeft: "7px",
              marginBottom: "5px",
              maxHeight: "40px",
              fontFamily:
                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
            }}
            className="me-2"
            variant="success btn-rounded"
            onClick={() => incentivesDownloadData()}
          >
            <i className="fa fa-download"></i>&nbsp;&nbsp;Download
          </Button>
        </div> */}

<div
  style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "10px",
    marginRight: "20px",
  }}
>
  <div
    style={{
      marginTop: "15px",
      marginRight: "5px",
    }}
  >
    <SearchBar
      id="incentivesSearch"
      placeholder="Search by Incentive Name/Emp Name/Emp Id"
      searchKeyword={searchKeyword}
    />
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      marginRight: "10px",
      marginBottom:"5px",
    }}
  >
    <label style={{ fontWeight: "bold" }}>
      Incentive Status
    </label>
    <div
      style={{
        width: "130px",
        height: "38px",
        marginLeft: "5px",
        marginRight: "5px",
        borderRadius: "3px",
        borderWidth: "1px",
        boxShadow: "1px 1px 2px #797979",
      }}
    >
      <Select
        id="incentiveStatusFilter"
        isSearchable={false}
        onChange={(value) => {
          setStatusValue(value.value);
        }}
        defaultValue={statusOptions[0]}
        options={statusOptions}
      />
    </div>
  </div>
  {!isActiveIncentives && (
    <Button
      color="primary"
      backgroundColor="#fff"
      border="1px solid #cfcfcf"
      type="button"
      style={{
        marginRight: "10px",
        marginTop: "20px",
      }}
      onClick={() => {
        setFormValues([
          {
            range: "",
            criteriaValue: "",
            criteriaValue2: "",
            criteriaPercentage: "",
            criteriaPercentage2: "",
            criteriaTargetAmount: "",
            criteriaTargetAmount2: "",
            incentiveAmount: "",
            incentivePerecentage: "",
          },
        ]);
        setAddIncnetiveModal(true);
      }}
    >
      <b> + Add Incentive </b>
    </Button>
  )}
  {!isActiveIncentives && (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      marginBottom:"5px",
    }}
  >
    <label style={{ fontWeight: "bold" }}>
      Select Month
    </label>
    <Flatpickr
      className="form-control d-block"
      style={{
        width: "150px",
        height: "40px",
        borderColor: "#a0a0a0",
        borderWidth: "1px",
        boxShadow: "1px 1px 2px #3a353b",
        textAlignLast: "center",
      }}
      placeholder="Select Month"
      onChange={(value) => {
        setCustomDatesForGraph(value);
      }}
      options={{
        defaultDate: new Date(),
        maxDate: new Date(),
        minDate: new Date().setMonth(new Date().getMonth() - 6),

        plugins: [
          new monthSelectPlugin({
            shorthand: true, //defaults to false
            dateFormat: "F Y", //defaults to "F Y"
            altFormat: "F Y", //defaults to "F Y"
            theme: "light", // defaults to "light"
          }),
        ],
      }}
    />
    </div>
  )}

  <Button
    style={{
      marginRight: "10px",
      marginLeft: "7px",
      maxHeight: "40px",
      marginTop: "20px",
      fontFamily:
        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    }}
    className="me-2"
    variant="success btn-rounded"
    onClick={() => incentivesDownloadData()}
  >
    <i className="fa fa-download"></i>&nbsp;&nbsp;Download
  </Button>
</div>
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "-5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={incentivesList}
              columns={columns}
            />
          </div>

          {incentivesList.length === 0 && incentivesList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      </LoadingOverlay>
      <Modal className="fade" size="lg" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Incentive Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{ paddingTop: "0px", maxHeight: "400px", overflowY: "auto" }}
        ></Modal.Body>
        <div style={{ margin: "20px" }}>
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey="incentiveDetails">
              <Nav as="ul" className="nav-tabs-custom nav-justified">
                <Nav.Item as="li" key="0">
                  <Nav.Link eventKey="incentiveDetails">
                    <b> Incentive Details </b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key="1">
                  <Nav.Link eventKey="achievementCriteria">
                    <b> Achievement Criteria </b>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item as="li" key="2">
                  <Nav.Link eventKey="paymentCriteria">
                    <b> Payment Criteria </b>
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item as="li" key="2">
                  <Nav.Link eventKey="approvalDetails">
                    <b> Approval Details </b>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="incentiveDetails" key="0" mountOnEnter>
                  <Col>
                    <div
                      className="card contact-bx item-content"
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        overflowY: "auto",
                        maxHeight: "400px",
                        margin: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          marginBottom: "10px",
                          marginTop: "30px",
                          marginLeft: "20px",
                        }}
                      >
                        <>
                          <tr></tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Incentive Name
                            </td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails.incentiveName
                                ? incentiveDetails.incentiveName
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Incentive Status
                            </td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails.status
                                ? incentiveDetails.status
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Branch Names
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.branchNames
                                ? incentiveDetails?.branchNames.length > 0
                                  ? incentiveDetails.branchNames.join(", ")
                                  : "All"
                                : "All"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Managers
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.managerNames &&
                                incentiveDetails?.managerNames.length > 0
                                ? incentiveDetails.managerNames.join(", ")
                                : "All"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Designations
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.designations &&
                                incentiveDetails?.designations.length > 0
                                ? incentiveDetails.designations.join(", ")
                                : "All"}
                            </td>
                          </tr>
                          {incentiveDetails?.incentiveType ===
                            "Collections" && (
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                    width: "30%",
                                  }}
                                >
                                  Cp Names
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {incentiveDetails?.cpNames &&
                                    incentiveDetails?.cpNames.length > 0
                                    ? incentiveDetails.cpNames.join(", ")
                                    : "All"}
                                </td>
                              </tr>
                            )}
                          {incentiveDetails?.incentiveType ===
                            "Collections" && (
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "left",
                                    width: "30%",
                                  }}
                                >
                                  Current Bucket
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {incentiveDetails?.currentBucketNames &&
                                    incentiveDetails?.currentBucketNames.length > 0
                                    ? incentiveDetails.currentBucketNames.join(
                                      ", "
                                    )
                                    : "All"}
                                </td>
                              </tr>
                            )}
                          {incentiveDetails?.incentiveType ===
                            "Collections" && (
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    width: "30%",
                                    textAlign: "left",
                                  }}
                                >
                                  Product Name
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {incentiveDetails?.productNames
                                    ? incentiveDetails?.productNames.length > 0
                                      ? incentiveDetails.productNames.join(", ")
                                      : "All"
                                    : "All"}
                                </td>
                              </tr>
                            )}
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                width: "30%",
                                textAlign: "left",
                              }}
                            >
                              Incentive Start Date
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.incentiveStartDate
                                ? incentiveDetails?.incentiveStartDate
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                              }}
                            >
                              Incentive End Date
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.incentiveEndDate
                                ? incentiveDetails?.incentiveEndDate
                                : "-"}
                            </td>
                          </tr>
                        </>
                      </div>
                    </div>
                  </Col>
                </Tab.Pane>

                <Tab.Pane eventKey="achievementCriteria" key="1" mountOnEnter>
                  <Col>
                    <div
                      className="card contact-bx item-content"
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        overflowY: "auto",
                        maxHeight: "400px",
                        margin: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          marginBottom: "10px",
                          marginTop: "30px",
                          marginLeft: "20px",
                        }}
                      >
                        <>
                          <tr></tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Incentive Criteria
                            </td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails.incentiveCriteria
                                ? incentiveDetails.incentiveCriteria
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                width: "30%",
                                textAlign: "left",
                              }}
                            >
                              Incentive Sub Criteria
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.incentiveSubCriteria
                                ? incentiveDetails?.incentiveSubCriteria
                                : "-"}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Is Criteria In Percentage
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.percentage ? "Yes" : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Is Criteria Per Case Basis
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.incentivePerCaseBasis
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Is Incentive Paid Per Case
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {incentiveDetails?.incentivePaidPerCase
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Is Incentive Paid In Percentage
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {incentiveDetails?.incentivePaidInPercentage
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          {/* {incentiveDetails?.incentiveRangesAndValues !== undefined && incentiveDetails.incentiveRangesAndValues.map((element, index) => (
                            <React.Fragment key={index}>
                              <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                                <tr>
                                  <td
                                    style={{
                                      fontWeight: "500",
                                      textAlign: "left",
                                      width: "30%",
                                    }}
                                  >
                                    Range
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "left",
                                    }}
                                  >
                                    {element.range ? element.range : "-"}
                                  </td>
                                </tr>

                                {incentiveDetails.incentiveCriteria !== "Collected Amount" &&
                                  !incentiveDetails.percentage && (
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: "500",
                                          width: "30%",
                                          textAlign: "left",
                                        }}
                                      >
                                        {element.range === "between"
                                          ? "Criteria Value 1"
                                          : "Criteria Value"}
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "left",
                                        }}
                                      >
                                        {element.criteriaValue
                                          ? element.criteriaValue
                                          : 0}
                                      </td>
                                    </tr>

                                  )}

                                {incentiveDetails.incentiveCriteria !== "Collected Amount" &&
                                  !incentiveDetails.percentage &&
                                  element.range === "between" && (
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: "500",
                                          width: "30%",
                                          textAlign: "left",
                                        }}
                                      >
                                        Criteria Value 2
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "left",
                                        }}
                                      >
                                        {element.criteriaValue2
                                          ? element.criteriaValue2
                                          : 0}
                                      </td>
                                    </tr>
                                  )}

                                {incentiveDetails.percentage && (
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: "500",
                                        textAlign: "left",
                                        width: "30%",
                                      }}
                                    >
                                      {element.range === "between"
                                        ? "Criteria Percentage 1"
                                        : "Criteria Percentage"}
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: "bold",
                                        textAlign: "left",
                                      }}
                                    >
                                      {element.criteriaPercentage
                                        ? element.criteriaPercentage
                                        : 0}
                                    </td>
                                  </tr>
                                )}
                                {incentiveDetails.percentage &&
                                  element.range === "between" && (
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: "500",
                                          textAlign: "left",
                                          width: "30%",
                                        }}
                                      >
                                        Criteria Percentage 2
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "left",
                                        }}
                                      >
                                        {element.criteriaPercentage2
                                          ? element.criteriaPercentage2
                                          : 0}
                                      </td>
                                    </tr>
                                  )}
                                {incentiveDetails.incentiveCriteria === "Collected Amount" &&
                                  !incentiveDetails.percentage && (
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: "500",
                                          textAlign: "left",
                                          width: "30%",
                                        }}
                                      >
                                        {element.range === "between"
                                          ? "Criteria Amount 1"
                                          : "Criteria Amount"}
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "left",
                                        }}
                                      >
                                        {element.criteriaTargetAmount
                                          ? element.criteriaTargetAmount
                                          : 0}
                                      </td>
                                    </tr>
                                  )}
                                {incentiveDetails.incentiveCriteria === "Collected Amount" &&
                                  !incentiveDetails.percentage &&
                                  element.range === "between" && (
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: "500",
                                          textAlign: "left",
                                          width: "30%",
                                        }}
                                      >
                                        Criteria Amount 2
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "left",
                                        }}
                                      >
                                        {element.criteriaTargetAmount2
                                          ? element.criteriaTargetAmount2
                                          : 0}
                                      </td>
                                    </tr>
                                  )}
                              </div>
                            </React.Fragment>
                          ))} */}

                          {incentiveDetails?.incentiveRangesAndValues !==
                            undefined && (
                              <table
                                style={{
                                  borderCollapse: "collapse",
                                  width: "100%",
                                  marginTop: "15px",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        border: "1px solid #000",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      Range
                                    </th>
                                    {incentiveDetails.incentiveCriteria !==
                                      "Collected Amount" &&
                                      !incentiveDetails.percentage && (
                                        <th
                                          style={{
                                            border: "1px solid #000",
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Criteria Value
                                        </th>
                                      )}
                                    {incentiveDetails.incentiveCriteria !==
                                      "Collected Amount" &&
                                      !incentiveDetails.percentage && (
                                        <th
                                          style={{
                                            border: "1px solid #000",
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Criteria Value 2
                                        </th>
                                      )}
                                    {incentiveDetails.percentage && (
                                      <th
                                        style={{
                                          border: "1px solid #000",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        Criteria Percentage
                                      </th>
                                    )}
                                    {incentiveDetails.percentage && (
                                      <th
                                        style={{
                                          border: "1px solid #000",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        Criteria Percentage 2
                                      </th>
                                    )}
                                    {incentiveDetails.incentiveCriteria ===
                                      "Collected Amount" &&
                                      !incentiveDetails.percentage && (
                                        <th
                                          style={{
                                            border: "1px solid #000",
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Criteria Amount 1
                                        </th>
                                      )}
                                    {incentiveDetails.incentiveCriteria ===
                                      "Collected Amount" &&
                                      !incentiveDetails.percentage && (
                                        <th
                                          style={{
                                            border: "1px solid #000",
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Criteria Amount 2
                                        </th>
                                      )}

                                    {incentiveDetails.incentivePaidInPercentage && (
                                      <th
                                        style={{
                                          border: "1px solid #000",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        Incentive Percentage
                                      </th>
                                    )}

                                    {!incentiveDetails.incentivePaidInPercentage && (
                                      <th
                                        style={{
                                          border: "1px solid #000",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        Incentive Amount
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {incentiveDetails.incentiveRangesAndValues.map(
                                    (element, index) => (
                                      <tr key={index}>
                                        <td
                                          style={{
                                            border: "1px solid #000",
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {element.range ? element.range : "-"}
                                        </td>
                                        {incentiveDetails.incentiveCriteria !==
                                          "Collected Amount" &&
                                          !incentiveDetails.percentage && (
                                            <td
                                              style={{
                                                border: "1px solid #000",
                                                padding: "8px",
                                                textAlign: "left",
                                              }}
                                            >
                                              {element.criteriaValue
                                                ? element.criteriaValue
                                                : 0}
                                            </td>
                                          )}
                                        {incentiveDetails.incentiveCriteria !==
                                          "Collected Amount" &&
                                          !incentiveDetails.percentage && (
                                            <td
                                              style={{
                                                border: "1px solid #000",
                                                padding: "8px",
                                                textAlign: "left",
                                              }}
                                            >
                                              {element.criteriaValue2
                                                ? element.criteriaValue2
                                                : "-"}
                                            </td>
                                          )}
                                        {incentiveDetails.percentage && (
                                          <td
                                            style={{
                                              border: "1px solid #000",
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {element.criteriaPercentage
                                              ? element.criteriaPercentage
                                              : 0}
                                          </td>
                                        )}
                                        {incentiveDetails.percentage && (
                                          <td
                                            style={{
                                              border: "1px solid #000",
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {element.criteriaPercentage2
                                              ? element.criteriaPercentage2
                                              : "-"}
                                          </td>
                                        )}
                                        {incentiveDetails.incentiveCriteria ===
                                          "Collected Amount" &&
                                          !incentiveDetails.percentage && (
                                            <td
                                              style={{
                                                border: "1px solid #000",
                                                padding: "8px",
                                                textAlign: "left",
                                              }}
                                            >
                                              {element.criteriaTargetAmount
                                                ? element.criteriaTargetAmount
                                                : 0}
                                            </td>
                                          )}
                                        {incentiveDetails.incentiveCriteria ===
                                          "Collected Amount" &&
                                          !incentiveDetails.percentage && (
                                            <td
                                              style={{
                                                border: "1px solid #000",
                                                padding: "8px",
                                                textAlign: "left",
                                              }}
                                            >
                                              {element.criteriaTargetAmount2
                                                ? element.criteriaTargetAmount2
                                                : "-"}
                                            </td>
                                          )}

                                        {incentiveDetails.incentivePaidInPercentage && (
                                          <td
                                            style={{
                                              border: "1px solid #000",
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {element.incentivePerecentage
                                              ? element.incentivePerecentage
                                              : 0}
                                          </td>
                                        )}
                                        {!incentiveDetails.incentivePaidInPercentage && (
                                          <td
                                            style={{
                                              border: "1px solid #000",
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {element.incentiveAmount
                                              ? element.incentiveAmount
                                              : 0}
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            )}
                        </>
                      </div>
                    </div>
                  </Col>
                </Tab.Pane>

                {/* <Tab.Pane eventKey="paymentCriteria" key="2" mountOnEnter>
                  <Col>
                    <div
                      className="card contact-bx item-content"
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        overflowY: "auto",
                        maxHeight: "400px",
                        margin: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          marginBottom: "10px",
                          marginTop: "30px",
                          marginLeft: "20px",
                        }}
                      >
                        <>
                          <tr></tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Is Incentive Paid Per Case
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {incentiveDetails?.incentivePaidPerCase
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Is Incentive Paid In Percentage
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {incentiveDetails?.incentivePaidInPercentage
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Incentive Amount
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {incentiveDetails?.incentiveAmount
                                ? incentiveDetails?.incentiveAmount
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Incentive Percentage
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                float: "left",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.incentivePerecentage
                                ? incentiveDetails?.incentivePerecentage
                                : "-"}
                            </td>
                          </tr>
                        </>
                      </div>
                    </div>
                  </Col>
                </Tab.Pane> */}

                <Tab.Pane eventKey="approvalDetails" key="2" mountOnEnter>
                  <Col>
                    <div
                      className="card contact-bx item-content"
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        overflowY: "auto",
                        maxHeight: "400px",
                        margin: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          marginBottom: "10px",
                          marginTop: "30px",
                          marginLeft: "20px",
                        }}
                      >
                        <>
                          <tr></tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Created By
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {incentiveDetails?.createdByName
                                ? incentiveDetails?.createdByName
                                : "-"}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Created Date And Time
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              {incentiveDetails?.dateTime
                                ? incentiveDetails?.dateTime
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Approval Status
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.approvalStatus
                                ? incentiveDetails?.approvalStatus
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Approval Date & Time
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.approvalDate
                                ? incentiveDetails?.approvalDate
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Approved/Rejected By
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.approvedByName
                                ? incentiveDetails?.approvedByName
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "left",
                                width: "30%",
                              }}
                            >
                              Rejection Reason
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {incentiveDetails?.rejectionReason
                                ? incentiveDetails?.rejectionReason
                                : "-"}
                            </td>
                          </tr>
                          {incentiveDetails?.status === "PENDING" &&
                            incentiveDetails?.approvalStatus === "PENDING" &&
                            hasAccess(74) && (
                              <div>
                                <Button
                                  style={{
                                    float: "left",
                                    marginTop: "10px",
                                    marginRight: "25px",
                                    fontFamily:
                                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                  }}
                                  className="me-2"
                                  variant="success btn-rounded"
                                  onClick={() =>
                                    updateApprovalStatus("APPROVED")
                                  }
                                >
                                  Approve
                                </Button>
                                <Button
                                  style={{
                                    float: "left",
                                    marginTop: "10px",
                                    marginRight: "15px",
                                    fontFamily:
                                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                  }}
                                  className="me-2"
                                  variant="danger btn-rounded"
                                  onClick={() => {
                                    setRejectionReason(null);
                                    setBasicModal2(true);
                                  }}
                                >
                                  Reject
                                </Button>
                              </div>
                            )}
                        </>
                      </div>
                    </div>
                  </Col>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>

        {/* <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Incentive Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentiveName
                ? incentiveDetails?.incentiveName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Incentive Criteria
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentiveCriteria
                ? incentiveDetails?.incentiveCriteria
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Incentive Sub Criteria
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentiveSubCriteria ? incentiveDetails?.incentiveSubCriteria : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Range
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.range ? incentiveDetails?.range : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Branch Names
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.branchNames ? incentiveDetails?.branchNames.length > 0 ? incentiveDetails.branchNames.join(', ') : "All" : "All"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Managers
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {(incentiveDetails?.managerNames && incentiveDetails?.managerNames.length > 0) ? incentiveDetails.managerNames.join(', ') : "All"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Designations
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {(incentiveDetails?.designations && incentiveDetails?.designations.length > 0) ? incentiveDetails.designations.join(', ') : "-"}
            </td>
          </tr>
          {incentiveDetails?.incentiveType === "Collections" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Cp Names
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {(incentiveDetails?.cpNames && incentiveDetails?.cpNames.length > 0) ? incentiveDetails.cpNames.join(', ') : "All"}
              </td>
            </tr>
          )}
          {incentiveDetails?.incentiveType === "Collections" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Current Bucket
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {(incentiveDetails?.currentBucketNames && incentiveDetails?.currentBucketNames.length > 0) ? incentiveDetails.currentBucketNames.join(', ') : "All"}
              </td>
            </tr>
          )}
          {incentiveDetails?.incentiveType === "Collections" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Product Name
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {incentiveDetails?.productNames ? incentiveDetails?.productNames.length > 0 ? incentiveDetails.productNames.join(', ') : "All" : "All"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Is Criteria In Percentage
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.percentage ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.range==="between" ? "Criteria Value 1":"Criteria Value"}
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.criteriaValue ? incentiveDetails?.criteriaValue : "0"}
            </td>
          </tr>
          {incentiveDetails.incentiveCriteria !== "Collected Amount" && !incentiveDetails.percentage && incentiveDetails.range === "between" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Criteria Value 2
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {incentiveDetails?.criteriaValue2 ? incentiveDetails?.criteriaValue2 : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.range==="between" ? "Criteria Percentage 1":"Criteria Percentage"}
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.criteriaPercentage ? incentiveDetails?.criteriaPercentage : "0"}
            </td>
          </tr>
          {incentiveDetails.percentage && incentiveDetails.range === "between" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Criteria Percentage 2
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {incentiveDetails?.criteriaPercentage2 ? incentiveDetails?.criteriaPercentage2 : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.range==="between" ? "Criteria Amount 1":"Criteria Amount"}
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.criteriaTargetAmount ? incentiveDetails?.criteriaTargetAmount : "-"}
            </td>
          </tr>
          {incentiveDetails.incentiveCriteria === "Collected Amount" && !incentiveDetails.percentage && incentiveDetails.range === "between" && (
            <tr>
              <td
                style={{
                  fontWeight: "500",
                  float: "left",
                  textAlign: "left",
                }}
              >
                Criteria Amount 2
              </td>
              <td style={{ verticalAlign: "middle" }}>
                &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "left",
                }}
              >
                {incentiveDetails?.criteriaTargetAmount2 ? incentiveDetails?.criteriaTargetAmount2 : "-"}
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Incentive Start Date
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentiveStartDate ? incentiveDetails?.incentiveStartDate : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Incentive End Date
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentiveEndDate ? incentiveDetails?.incentiveEndDate : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Is Incentive Paid In Percentage
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentivePaidInPercentage ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Incentive Amount
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentiveAmount ? incentiveDetails?.incentiveAmount : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Incentive Percentage
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentivePerecentage ? incentiveDetails?.incentivePerecentage : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Is Criteria Per Case Basis
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.incentivePerCaseBasis ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Created By
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.createdByName ? incentiveDetails?.createdByName : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Created Date And Time
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.dateTime ? incentiveDetails?.dateTime : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Incentive Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.status ? incentiveDetails?.status : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.approvalStatus ? incentiveDetails?.approvalStatus : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Date & Time
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.approvalDate ? incentiveDetails?.approvalDate : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approved/Rejected By
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.approvedByName ? incentiveDetails?.approvedByName : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Rejection Reason
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {incentiveDetails?.rejectionReason ? incentiveDetails?.rejectionReason : "-"}
            </td>
          </tr>
          {incentiveDetails?.status==="PENDING" && incentiveDetails?.approvalStatus === "PENDING" && hasAccess(74) && (
            <div>
              <Button
                style={{
                  float: "left",
                  marginTop: "10px",
                  marginRight: "25px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => updateApprovalStatus("APPROVED")}
              >
                Approve
              </Button>
              <Button
                style={{
                  float: "left",
                  marginTop: "10px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="danger btn-rounded"
                onClick={() => {
                  setRejectionReason(null);
                  setBasicModal2(true);
                }}
              >
                Reject
              </Button>
            </div>
          )}
        </div> */}
      </Modal>
      <Modal className="fade" show={basicModal2} size="sm">
        <Modal.Header>
          <Modal.Title>Add Rejection Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal2(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
              <input
                style={{
                  width: "220px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter Reason"
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                  console.log("rejectionReason", rejectionReason);
                }}
              />
              <div style={{ marginTop: "5px" }}>
                <div>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2"
                    type="submit"
                    onClick={() => updateApprovalStatus("REJECTED")}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2 float-right"
                    variant="danger light"
                    onClick={() => setBasicModal2(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={addIncentiveModal}>
        <Modal.Header>
          <Modal.Title>Add Incentive</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setAddIncnetiveModal(false);
              setIncentiveCriteria("");
              setIncentiveSubCriteria("");
              setIsCriteriaInPercentage(false);
              setIsIncentivePerCaseBasis(false);
              setIsIncentivesPaidPerCase(false);
              setIsIncentivesPaidInPercentage(false);
              setRejectionReason(null);
              setFormSubmitted(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <div>
            <Row>
              <Col>
                <Formik
                  initialValues={{
                    branchIds: [],
                    cpNames: [],
                    currentBucketNames: [],
                    designations: [],
                    incentiveCriteria: "",
                    incentiveEndDate: "",
                    incentiveName: "",
                    incentivePaidInPercentage: false,
                    incentivePerCaseBasis: false,
                    incentiveStartDate: "",
                    incentiveSubCriteria: "",
                    managerIds: [],
                    productNames: [],
                    percentage: false,
                    productName: "",
                    productType: "",
                    incentiveRangesAndValues: [],
                  }}
                  onSubmit={addIncentive}
                  validationSchema={validate}
                >
                  {({ formik, errors }) => (
                    <div>
                      <Row>
                        <Col lg="12">
                          <Form>
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Incentive Name"
                                name="incentiveName"
                                type="text"
                              />
                              <Field name="branchIds">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Branch
                                    </label>
                                    <Select
                                      placeholder="Select a Branch"
                                      classNamePrefix="select-box"
                                      options={[allOption, ...branches]}
                                      isMulti
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.branchIds && (
                                <span className="error">
                                  {errors.branchIds}
                                </span>
                              )}

                              <Field name="managerIds">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      // className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Manager
                                    </label>
                                    <Select
                                      placeholder="Select a Manager"
                                      classNamePrefix="select-box"
                                      options={[allOption, ...managers]}
                                      isMulti
                                      //value={managerValue}
                                      //defaultValue={defaultBranch}
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onManagerChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.managerIds && (
                                <span className="error">
                                  {errors.managerIds}
                                </span>
                              )}

                              <Field name="designations">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Designation
                                    </label>
                                    <Select
                                      placeholder="Select a Designation"
                                      classNamePrefix="select-box"
                                      options={designations}
                                      isMulti
                                      //value={designationValue}
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onDesignationChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.designations && (
                                <span className="error">
                                  {errors.designations}
                                </span>
                              )}

                              {visitType === "Collections" && (
                                <Field name="cpNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        //className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        CP Name
                                      </label>
                                      <Select
                                        placeholder="Select a CP Name"
                                        classNamePrefix="select-box"
                                        options={[allOption, ...cpNames]}
                                        isMulti
                                        //value={cpNameValue}
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onCpNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}
                              {errors.cpNames && (
                                <span className="error">{errors.cpNames}</span>
                              )}

                              {visitType === "Collections" && (
                                <Field name="currentBucketNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        //className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Current Bucket
                                      </label>
                                      <Select
                                        placeholder="Select a Current Bucket"
                                        classNamePrefix="select-box"
                                        options={[
                                          allOption,
                                          ...currentBucketNames,
                                        ]}
                                        isMulti
                                        //value={currentBucketNameValue}
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onCurrentBucketNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}
                              {errors.currentBucketNames && (
                                <span className="error">
                                  {errors.currentBucketNames}
                                </span>
                              )}

                              {visitType !== "Collections" && (
                                <TextField
                                  isAsterisk={false}
                                  label="Product Type"
                                  name="productType"
                                  type="text"
                                />
                              )}
                              {visitType !== "Collections" && (
                                <TextField
                                  isAsterisk={false}
                                  label="Product Name"
                                  name="productName"
                                  type="text"
                                />
                              )}

                              {visitType === "Collections" && (
                                <Field name="productNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        // className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Product Name
                                      </label>
                                      <Select
                                        placeholder="Select a Product"
                                        classNamePrefix="select-box"
                                        options={[allOption, ...productNames]}
                                        isMulti
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onProductNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}

                              {errors.productNames && (
                                <span className="error">
                                  {errors.productNames}
                                </span>
                              )}

                              {errors.productName && (
                                <span className="error">
                                  {errors.productName}
                                </span>
                              )}
                              <Field name="incentiveCriteria">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Incentive Criteria
                                    </label>
                                    <Select
                                      placeholder="Select Incentive Criteria"
                                      classNamePrefix="select-box"
                                      options={
                                        visitType === "Collections"
                                          ? collectionCriteriaOptions
                                          : salesAndFICriteriaOptions
                                      }
                                      onChange={(selectedOption) => {
                                        setIncentiveCriteriaValue(
                                          selectedOption
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.incentiveCriteria && (
                                <span className="error">
                                  {errors.incentiveCriteria}
                                </span>
                              )}

                              {visitType === "Collections" &&
                                incentiveCriteria !== "" && (
                                  <Field name="incentiveSubCriteria">
                                    {({ field, form }) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label
                                          className="control-label"
                                          style={{
                                            fontWeight: "bold",
                                            float: "left",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          Incentive Sub Criteria
                                        </label>
                                        <Select
                                          placeholder="Select Incentive Sub Criteria"
                                          classNamePrefix="select-box"
                                          value={selectedSubCriteria}
                                          options={
                                            incentiveCriteria ===
                                              "Collected Amount"
                                              ? subCriteriaOptionsForCollectedAmount
                                              : incentiveCriteria ===
                                                "Calls Count"
                                                ? subCriteriaOptionsForCallsCount
                                                : subCriteriaOptionsForVisitCount
                                          }
                                          onChange={(selectedOption) => {
                                            setIncentiveSubCriteriaValue(
                                              selectedOption
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                )}

                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" && (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Is Criteria Definition In %:
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        type="radio"
                                        id="percentageYes"
                                        name="percentage"
                                        value="yes"
                                        checked={isCriteriaInPercentage}
                                        onClick={() =>
                                          setIsCriteriaInPercentage(true)
                                        }
                                      />
                                      <label
                                        htmlFor="percentageYes"
                                        style={{ marginRight: "10px" }}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        type="radio"
                                        id="percentageNo"
                                        name="percentage"
                                        value="no"
                                        checked={!isCriteriaInPercentage}
                                        onClick={() =>
                                          setIsCriteriaInPercentage(false)
                                        }
                                      />
                                      <label htmlFor="percentageNo">No</label>
                                    </div>
                                  </div>
                                )}

                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" && (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center", // Align items vertically
                                    }}
                                  >
                                    <label
                                      className="form-check-label"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Is Criteria Per Case Basis:
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px", // Increased height
                                          width: "15px", // Increased width
                                        }}
                                        type="radio"
                                        id="percaseYes"
                                        name="perCaseYes"
                                        value="yes"
                                        checked={isIncentivePerCaseBasis}
                                        onChange={() => {
                                          setIsIncentivePerCaseBasis(true);
                                          setIsIncentivesPaidPerCase(true);
                                        }}
                                      />
                                      <label
                                        htmlFor="percentageYes"
                                        style={{ marginRight: "10px" }}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px", // Increased height
                                          width: "15px", // Increased width
                                        }}
                                        type="radio"
                                        id="perCaseNo"
                                        name="perCaseBasis"
                                        value="no"
                                        checked={!isIncentivePerCaseBasis}
                                        onChange={() => {
                                          setIsIncentivePerCaseBasis(false);
                                          setIsIncentivesPaidPerCase(false);
                                        }}
                                      />
                                      <label htmlFor="perCaseNo">No</label>
                                    </div>
                                  </div>
                                )}

                                <div
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                    marginRight: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "initial",
                                  }}
                                >
                                  <label
                                    className="form-check-label"
                                    htmlFor="incentivePerCaseYes"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Is Incentive Paid Per Case: Yes
                                  </label>
                                  <input
                                    style={{
                                      width: "15px",
                                      height: "20px",
                                      marginLeft: "10px",
                                      fontWeight: "bold",
                                    }}
                                    type="radio"
                                    id="incentivePerCaseYes"
                                    name="incentivePerCase"
                                    checked={isIncentivesPaidPerCase}
                                    disabled={isIncentivesPaidInPercentage || isIncentivePerCaseBasis}
                                    onClick={() => {
                                      setIsIncentivesPaidPerCase(true);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="incentivePerCaseNo"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    No
                                  </label>
                                  <input
                                    style={{
                                      width: "15px",
                                      height: "20px",
                                      marginLeft: "10px",
                                      fontWeight: "bold",
                                    }}
                                    type="radio"
                                    id="incentivePerCaseNo"
                                    name="incentivePerCase"
                                    checked={!isIncentivesPaidPerCase}
                                    disabled={isIncentivesPaidInPercentage || isIncentivePerCaseBasis}
                                    onClick={() => {
                                      setIsIncentivesPaidPerCase(false);
                                    }}
                                  />
                                </div>
                            
                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" &&(
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                      marginRight: "15px",
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "initial",
                                    }}
                                  >
                                    <label
                                      className="form-check-label"
                                      htmlFor="incentiveInPercentageYes"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Is Incentive Paid In Percentage: Yes
                                    </label>
                                    <input
                                      style={{
                                        width: "15px",
                                        height: "20px",
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                      }}
                                      type="radio"
                                      id="incentiveInPercentageYes"
                                      name="incentivePaidInPercentage"
                                      checked={isIncentivesPaidInPercentage}
                                      disabled={isIncentivesPaidPerCase && !isIncentivePerCaseBasis}
                                      onChange={() => {
                                        setIsIncentivesPaidInPercentage(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="incentiveInPercentageNo"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      No
                                    </label>
                                    <input
                                      style={{
                                        width: "15px",
                                        height: "20px",
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                      }}
                                      type="radio"
                                      id="incentivePaidInPercentageNo"
                                      name="incentivePaidInPercentage"
                                      checked={!isIncentivesPaidInPercentage}
                                      disabled={isIncentivesPaidPerCase && !isIncentivePerCaseBasis}
                                      onClick={() => {
                                        setIsIncentivesPaidInPercentage(false);
                                      }}
                                    />
                                  </div>
                                )}

                              {formValues.map((element, index) => (
                                <div className="form-inline" key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-evenly",
                                      columnGap: "0.5rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      <Field
                                        id={`rangeSelect-${index}`}
                                        name={`range-${index}`}
                                      >
                                        {({ field, form }) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "10rem",
                                              marginBottom: "10px",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <label
                                              className="control-label"
                                              style={{
                                                fontWeight: "bold",
                                                float: "left",
                                              }}
                                            >
                                              Range
                                            </label>
                                            <div
                                              style={{
                                                width: "7rem",
                                                height: "38px",
                                                marginTop: "5px",
                                                borderRadius: "3px",
                                                borderWidth: "0px",
                                                boxShadow: "1px 1px 2px #797979",
                                              }}
                                            >
                                              <Select
                                                placeholder="Select"
                                                classNamePrefix="select-box"
                                                options={rangeOptions}
                                                value={element.selectedOption}
                                                onChange={(e) =>
                                                  handleSelectChange(
                                                    index,
                                                    e,
                                                    form
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Field>
                                      {formSubmitted && formValueErrors[index] &&
                                        formValueErrors[index].range && (
                                          <span className="error">
                                            {
                                              formValueErrors[index]
                                                .range[0]
                                            }
                                          </span>
                                        )}
                                    </div>

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria !==
                                      "Collected Amount" && (
                                        <div>
                                          <TextField
                                            isAsterisk={true}
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                              fontSize:
                                                element.range === "between"
                                                  ? "x-small"
                                                  : "11.2px",
                                            }}
                                            label={
                                              element.range === "between"
                                                ? "Criteria Value 1"
                                                : "Criteria Value"
                                            }
                                            name="criteriaValue"
                                            // onWheel={(event) =>
                                            //   event.currentTarget.blur()
                                            // }
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaValue && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaValue[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria !==
                                      "Collected Amount" &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            isAsterisk={true}
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                            }}
                                            label="Criteria Value 2"
                                            name="criteriaValue2"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaValue2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaValue2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {isCriteriaInPercentage && (
                                      <div>
                                        <TextField
                                          isAsterisk={true}
                                          style={{
                                            width:
                                              element.range === "between"
                                                ? "7rem"
                                                : "10rem",
                                          }}
                                          label={
                                            element.range === "between"
                                              ? "Criteria Percentage 1"
                                              : "Criteria Percentage"
                                          }
                                          name="criteriaPercentage"
                                          type="text"
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        />
                                        {formSubmitted &&
                                          formValueErrors[index] &&
                                          formValueErrors[index]
                                            .criteriaPercentage && (
                                            <span className="error">
                                              {
                                                formValueErrors[index]
                                                  .criteriaPercentage[0]
                                              }
                                            </span>
                                          )}
                                      </div>
                                    )}

                                    {isCriteriaInPercentage &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "8rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label="Criteria Percentage 2"
                                            name="criteriaPercentage2"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaPercentage2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaPercentage2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria ===
                                      "Collected Amount" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label={
                                              <span
                                                style={{
                                                  fontSize:
                                                    element.range === "between"
                                                      ? "x-small"
                                                      : "11.2px",
                                                }}
                                              >
                                                {element.range === "between"
                                                  ? "Criteria Amount 1"
                                                  : "Criteria Amount"}
                                              </span>
                                            }
                                            name="criteriaTargetAmount"
                                            // onWheel={(event) =>
                                            //   event.currentTarget.blur()
                                            // }
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaTargetAmount && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaTargetAmount[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria ===
                                      "Collected Amount" &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7.5rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label={
                                              <span
                                                style={{
                                                  fontSize: "x-small",
                                                }}
                                              >
                                                Criteria Amount 2
                                              </span>
                                            }
                                            name="criteriaTargetAmount2"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaTargetAmount2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaTargetAmount2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {incentiveCriteria === "Collected Amount" &&
                                      isIncentivesPaidInPercentage && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "6rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label="Incentive Percentage"
                                            name="incentivePerecentage"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .incentivePerecentage && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .incentivePerecentage[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isIncentivesPaidInPercentage && (
                                      <div>
                                        <TextField
                                          style={{
                                            width:
                                              element.range === "between"
                                                ? "6rem"
                                                : "10rem",
                                          }}
                                          isAsterisk={true}
                                          label="Incentive Amount"
                                          name="incentiveAmount"
                                          type="text"
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        />
                                        {formSubmitted &&
                                          formValueErrors[index] &&
                                          formValueErrors[index]
                                            .incentiveAmount && (
                                            <span className="error">
                                              {
                                                formValueErrors[index]
                                                  .incentiveAmount[0]
                                              }
                                            </span>
                                          )}
                                      </div>
                                    )}
                                    {
                                      index ? (
                                        // <button
                                        //   type="button"
                                        //   className="button remove"
                                        //   onClick={() => removeFormFields(index)}
                                        // >
                                        <div
                                          className="media-body user-meta-info"
                                          style={{ marginLeft: "-1rem" }}
                                          onClick={() =>
                                            removeFormFields(index)
                                          }
                                        >
                                          <ul>
                                            <li>
                                              <Link
                                                to={"#"}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  height: "25px",
                                                  width: "25px",
                                                  marginTop: "20px",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                <i
                                                  className="flaticon-019-close"
                                                  style={{
                                                    fontSize: "x-small",
                                                  }}
                                                ></i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      ) : null
                                      // {/* </button> */}
                                    }
                                  </div>
                                </div>
                              ))}
                              <div className="button-section" style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}>
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  onClick={() => addFormFields()}
                                >
                                  Add
                              </button>
                              </div>
                              {/* <div
                                className="media-body user-meta-info"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                                onClick={() => addFormFields()}
                              >
                                <ul>
                                  <li>
                                    <Link
                                      to={"#"}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                        width: "5rem",
                                        fontSize: "medium",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Add
                                    </Link>
                                  </li>
                                </ul>
                              </div> */}

                              <TextField
                                isAsterisk={true}
                                label="Incentive Start Date"
                                name="incentiveStartDate"
                                type="date"
                              />
                              <TextField
                                isAsterisk={true}
                                label="Incentive End Date"
                                name="incentiveEndDate"
                                type="date"
                              />
                            </div>

                            <div>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2"
                                onClick={() => {
                                  setFormSubmitted(true);
                                }}
                                type="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2 float-right"
                                variant="danger light"
                                onClick={() => {
                                  setAddIncnetiveModal(false);
                                  setIncentiveCriteria("");
                                  setIncentiveSubCriteria("");
                                  setIsCriteriaInPercentage(false);
                                  setIsIncentivePerCaseBasis(false);
                                  setIsIncentivesPaidInPercentage(false);
                                  setIsIncentivesPaidPerCase(false);
                                  setRejectionReason(null);
                                  setFormSubmitted(false);
                                }}
                              >
                                Close
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={editIncentiveModal}>
        <Modal.Header>
          <Modal.Title>Edit Incentive</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setEditIncentiveModal(false);
              loadIncentivesData(
                currPage,
                visitType,
                start,
                end,
                selectedStatus,
                searchKey
              );
              setIncentiveCriteria("");
              setIncentiveSubCriteria("");
              setIsCriteriaInPercentage(false);
              setIsIncentivePerCaseBasis(false);
              setIsIncentivesPaidInPercentage(false);
              setRejectionReason(null);
              setFormSubmitted(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Formik
                  initialValues={{
                    id: incentiveDetails.id,
                    branchIds: incentiveDetails.branchIds,
                    cpNames: incentiveDetails.cpNames,
                    currentBucketNames: incentiveDetails.currentBucketNames,
                    designations: incentiveDetails.designations,
                    incentiveCriteria: incentiveDetails.incentiveCriteria,
                    incentiveEndDate: helper.formatDate(
                      incentiveDetails.incentiveEndDate
                    ),
                    incentiveName: incentiveDetails.incentiveName,
                    incentivePaidInPercentage:
                      incentiveDetails.incentivePaidInPercentage ? true : false,
                    incentivePerCaseBasis:
                      incentiveDetails.incentivePerCaseBasis ? true : false,
                    incentiveStartDate: helper.formatDate(
                      incentiveDetails.incentiveStartDate
                    ),
                    incentiveSubCriteria: incentiveDetails.incentiveSubCriteria,
                    managerIds: incentiveDetails.managerIds,
                    productNames: incentiveDetails.productNames,
                    percentage: incentiveDetails.percentage ? true : false,
                    productName: incentiveDetails.productName,
                    productType: incentiveDetails.productType,
                    incentiveRangesAndValues:
                      incentiveDetails.incentiveRangesAndValues,
                  }}
                  onSubmit={updateIncentive}
                  validationSchema={validate}
                >
                  {({ formik, errors }) => (
                    <div>
                      <Row>
                        <Col lg="12">
                          <Form>
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Incentive Name"
                                name="incentiveName"
                                type="text"
                              />
                              <Field name="branchIds">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Branch
                                    </label>
                                    <Select
                                      placeholder="Select a Branch"
                                      classNamePrefix="select-box"
                                      options={[allOption, ...branches]}
                                      defaultValue={defaultBranches}
                                      isMulti
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.branchIds && (
                                <span className="error">
                                  {errors.branchIds}
                                </span>
                              )}

                              <Field name="managerIds">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      // className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Manager
                                    </label>
                                    <Select
                                      placeholder="Select a Manager"
                                      classNamePrefix="select-box"
                                      options={[allOption, ...managers]}
                                      defaultValue={defaultManagers}
                                      isMulti
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onManagerChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.managerIds && (
                                <span className="error">
                                  {errors.managerIds}
                                </span>
                              )}

                              <Field name="designations">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Designation
                                    </label>
                                    <Select
                                      placeholder="Select a Designation"
                                      classNamePrefix="select-box"
                                      options={designations}
                                      defaultValue={defaultDesignations}
                                      isMulti
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onDesignationChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.designations && (
                                <span className="error">
                                  {errors.designations}
                                </span>
                              )}

                              {visitType === "Collections" && (
                                <Field name="cpNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        //className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        CP Name
                                      </label>
                                      <Select
                                        placeholder="Select a CP Name"
                                        classNamePrefix="select-box"
                                        options={[allOption, ...cpNames]}
                                        defaultValue={defaultCpNames}
                                        isMulti
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onCpNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}
                              {errors.cpNames && (
                                <span className="error">{errors.cpNames}</span>
                              )}

                              {visitType === "Collections" && (
                                <Field name="currentBucketNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        //className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Current Bucket
                                      </label>
                                      <Select
                                        placeholder="Select a Current Bucket"
                                        classNamePrefix="select-box"
                                        options={[
                                          allOption,
                                          ...currentBucketNames,
                                        ]}
                                        defaultValue={defaultCurrentBuckets}
                                        isMulti
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onCurrentBucketNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}
                              {errors.currentBucketNames && (
                                <span className="error">
                                  {errors.currentBucketNames}
                                </span>
                              )}

                              {visitType !== "Collections" && (
                                <TextField
                                  isAsterisk={false}
                                  label="Product Type"
                                  name="productType"
                                  type="text"
                                />
                              )}
                              {visitType !== "Collections" && (
                                <TextField
                                  isAsterisk={false}
                                  label="Product Name"
                                  name="productName"
                                  type="text"
                                />
                              )}

                              {visitType === "Collections" && (
                                <Field name="productNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        // className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Product Name
                                      </label>
                                      <Select
                                        placeholder="Select a Product"
                                        classNamePrefix="select-box"
                                        options={[allOption, ...productNames]}
                                        defaultValue={defaultProductNames}
                                        isMulti
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onProductNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}

                              {errors.productNames && (
                                <span className="error">
                                  {errors.productNames}
                                </span>
                              )}

                              {errors.productName && (
                                <span className="error">
                                  {errors.productName}
                                </span>
                              )}
                              <Field name="incentiveCriteria">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Incentive Criteria
                                    </label>
                                    <Select
                                      placeholder="Select Incentive Criteria"
                                      classNamePrefix="select-box"
                                      defaultValue={defaultIncentiveCriteria}
                                      options={
                                        visitType === "Collections"
                                          ? collectionCriteriaOptions
                                          : salesAndFICriteriaOptions
                                      }
                                      onChange={(selectedOption) => {
                                        setIncentiveCriteriaValue(
                                          selectedOption
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.incentiveCriteria && (
                                <span className="error">
                                  {errors.incentiveCriteria}
                                </span>
                              )}

                              {visitType === "Collections" &&
                                incentiveCriteria !== "" && (
                                  <Field name="incentiveSubCriteria">
                                    {({ field, form }) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label
                                          className="control-label"
                                          style={{
                                            fontWeight: "bold",
                                            float: "left",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          Incentive Sub Criteria
                                        </label>
                                        <Select
                                          placeholder="Select Incentive Sub Criteria"
                                          classNamePrefix="select-box"
                                          defaultValue={
                                            defaultIncentiveSubCriteria
                                          }
                                          options={
                                            incentiveCriteria ===
                                              "Collected Amount"
                                              ? subCriteriaOptionsForCollectedAmount
                                              : incentiveCriteria ===
                                                "Calls Count"
                                                ? subCriteriaOptionsForCallsCount
                                                : subCriteriaOptionsForVisitCount
                                          }
                                          onChange={(selectedOption) => {
                                            setIncentiveSubCriteriaValue(
                                              selectedOption
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                )}

                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" && (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Is Criteria Definition In %:
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        type="radio"
                                        id="percentageYes"
                                        name="percentage"
                                        value="yes"
                                        checked={isCriteriaInPercentage}
                                        onClick={() =>
                                          setIsCriteriaInPercentage(true)
                                        }
                                      />
                                      <label
                                        htmlFor="percentageYes"
                                        style={{ marginRight: "10px" }}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        type="radio"
                                        id="percentageNo"
                                        name="percentage"
                                        value="no"
                                        checked={!isCriteriaInPercentage}
                                        onClick={() =>
                                          setIsCriteriaInPercentage(false)
                                        }
                                      />
                                      <label htmlFor="percentageNo">No</label>
                                    </div>
                                  </div>
                                )}

                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" && (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center", // Align items vertically
                                    }}
                                  >
                                    <label
                                      className="form-check-label"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Is Criteria Per Case Basis:
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px", // Increased height
                                          width: "15px", // Increased width
                                        }}
                                        type="radio"
                                        id="percaseYes"
                                        name="perCaseYes"
                                        value="yes"
                                        checked={isIncentivePerCaseBasis}
                                        onChange={() => {
                                          setIsIncentivePerCaseBasis(true);
                                          setIsIncentivesPaidPerCase(true);
                                        }}
                                      />
                                      <label
                                        htmlFor="percentageYes"
                                        style={{ marginRight: "10px" }}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px", // Increased height
                                          width: "15px", // Increased width
                                        }}
                                        type="radio"
                                        id="perCaseNo"
                                        name="perCaseBasis"
                                        value="no"
                                        checked={!isIncentivePerCaseBasis}
                                        onChange={() => {
                                          setIsIncentivePerCaseBasis(false);
                                          setIsIncentivesPaidPerCase(false);
                                        }}
                                      />
                                      <label htmlFor="perCaseNo">No</label>
                                    </div>
                                  </div>
                                )}

                                <div
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                    marginRight: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "initial",
                                  }}
                                >
                                  <label
                                    className="form-check-label"
                                    htmlFor="incentivePerCaseYes"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Is Incentive Paid Per Case: Yes
                                  </label>
                                  <input
                                    style={{
                                      width: "15px",
                                      height: "20px",
                                      marginLeft: "10px",
                                      fontWeight: "bold",
                                    }}
                                    type="radio"
                                    id="incentivePerCaseYes"
                                    name="incentivePerCase"
                                    checked={isIncentivesPaidPerCase}
                                    disabled={isIncentivesPaidInPercentage || isIncentivePerCaseBasis}
                                    onClick={() => {
                                      setIsIncentivesPaidPerCase(true);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="incentivePerCaseNo"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    No
                                  </label>
                                  <input
                                    style={{
                                      width: "15px",
                                      height: "20px",
                                      marginLeft: "10px",
                                      fontWeight: "bold",
                                    }}
                                    type="radio"
                                    id="incentivePerCaseNo"
                                    name="incentivePerCase"
                                    checked={!isIncentivesPaidPerCase}
                                    disabled={isIncentivesPaidInPercentage || isIncentivePerCaseBasis}
                                    onClick={() => {
                                      setIsIncentivesPaidPerCase(false);
                                    }}
                                  />
                                </div>
         
                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                      marginRight: "15px",
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "initial",
                                    }}
                                  >
                                    <label
                                      className="form-check-label"
                                      htmlFor="incentivePaidInPercentageYes"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Is Incentive Paid In Percentage: Yes
                                    </label>
                                    <input
                                      style={{
                                        width: "15px",
                                        height: "20px",
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                      }}
                                      type="radio"
                                      id="incentivePaidInPercentageYes"
                                      name="incentivePaidInPercentage"
                                      checked={isIncentivesPaidInPercentage}
                                      disabled={isIncentivesPaidPerCase && !isIncentivePerCaseBasis}
                                      onChange={() => {
                                        setIsIncentivesPaidInPercentage(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="incentivePaidInPercentageNo"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      No
                                    </label>
                                    <input
                                      style={{
                                        width: "15px",
                                        height: "20px",
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                      }}
                                      type="radio"
                                      id="incentivePaidInPercentageNo"
                                      name="incentivePaidInPercentage"
                                      checked={!isIncentivesPaidInPercentage}
                                      disabled={isIncentivesPaidPerCase && !isIncentivePerCaseBasis}
                                      onClick={() => {
                                        setIsIncentivesPaidInPercentage(false);
                                      }}
                                    />
                                  </div>
                                )}

                              {formValues.map((element, index) => (
                                <div className="form-inline" key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-evenly",
                                      columnGap: "0.5rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      <Field
                                        id={`rangeSelect-${index}`}
                                        name={`range-${index}`}
                                      >
                                        {({ field, form }) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "10rem",
                                              marginBottom: "10px",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <label
                                              className="control-label"
                                              style={{
                                                fontWeight: "bold",
                                                float: "left",
                                              }}
                                            >
                                              Range
                                            </label>
                                            <div
                                              style={{
                                                width: "7rem",
                                                height: "38px",
                                                marginTop: "5px",
                                                borderRadius: "3px",
                                                borderWidth: "0px",
                                                boxShadow: "1px 1px 2px #797979",
                                              }}
                                            >
                                              <Select
                                                placeholder="Select"
                                                classNamePrefix="select-box"
                                                options={rangeOptions}
                                                defaultValue={{
                                                  label: element.range,
                                                  value: element.range,
                                                }}
                                                onChange={(e) =>
                                                  handleSelectChange(
                                                    index,
                                                    e,
                                                    form
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Field>
                                      {formSubmitted && formValueErrors[index] &&
                                        formValueErrors[index].range && (
                                          <span className="error">
                                            {
                                              formValueErrors[index]
                                                .range[0]
                                            }
                                          </span>
                                        )}
                                    </div>

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria !==
                                      "Collected Amount" && (
                                        <div>
                                          <TextField
                                            isAsterisk={true}
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                              fontSize:
                                                element.range === "between"
                                                  ? "x-small"
                                                  : "11.2px",
                                            }}
                                            label={
                                              element.range === "between"
                                                ? "Criteria Value 1"
                                                : "Criteria Value"
                                            }
                                            name="criteriaValue"
                                            value={element.criteriaValue}
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaValue && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaValue[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria !==
                                      "Collected Amount" &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            isAsterisk={true}
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                            }}
                                            label="Criteria Value 2"
                                            name="criteriaValue2"
                                            value={element.criteriaValue2}
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaValue2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaValue2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {isCriteriaInPercentage && (
                                      <div>
                                        <TextField
                                          isAsterisk={true}
                                          style={{
                                            width:
                                              element.range === "between"
                                                ? "7rem"
                                                : "10rem",
                                          }}
                                          label={
                                            element.range === "between"
                                              ? "Criteria Percentage 1"
                                              : "Criteria Percentage"
                                          }
                                          name="criteriaPercentage"
                                          value={element.criteriaPercentage}
                                          type="text"
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        />
                                        {formSubmitted &&
                                          formValueErrors[index] &&
                                          formValueErrors[index]
                                            .criteriaPercentage && (
                                            <span className="error">
                                              {
                                                formValueErrors[index]
                                                  .criteriaPercentage[0]
                                              }
                                            </span>
                                          )}
                                      </div>
                                    )}

                                    {isCriteriaInPercentage &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "8rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label="Criteria Percentage 2"
                                            name="criteriaPercentage2"
                                            value={element.criteriaPercentage2}
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaPercentage2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaPercentage2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria ===
                                      "Collected Amount" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label={
                                              <span
                                                style={{
                                                  fontSize:
                                                    element.range === "between"
                                                      ? "x-small"
                                                      : "11.2px",
                                                }}
                                              >
                                                {element.range === "between"
                                                  ? "Criteria Amount 1"
                                                  : "Criteria Amount"}
                                              </span>
                                            }
                                            name="criteriaTargetAmount"
                                            value={element.criteriaTargetAmount}
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaTargetAmount && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaTargetAmount[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria ===
                                      "Collected Amount" &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7.5rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label={
                                              <span
                                                style={{
                                                  fontSize: "x-small",
                                                }}
                                              >
                                                Criteria Amount 2
                                              </span>
                                            }
                                            name="criteriaTargetAmount2"
                                            value={element.criteriaTargetAmount2}
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaTargetAmount2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaTargetAmount2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {incentiveCriteria === "Collected Amount" &&
                                      isIncentivesPaidInPercentage && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "6rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label="Incentive Percentage"
                                            name="incentivePerecentage"
                                            value={element.incentivePerecentage}
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .incentivePerecentage && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .incentivePerecentage[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isIncentivesPaidInPercentage && (
                                      <div>
                                        <TextField
                                          style={{
                                            width:
                                              element.range === "between"
                                                ? "6rem"
                                                : "10rem",
                                          }}
                                          isAsterisk={true}
                                          label="Incentive Amount"
                                          name="incentiveAmount"
                                          value={element.incentiveAmount}
                                          type="text"
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        />
                                        {formSubmitted &&
                                          formValueErrors[index] &&
                                          formValueErrors[index]
                                            .incentiveAmount && (
                                            <span className="error">
                                              {
                                                formValueErrors[index]
                                                  .incentiveAmount[0]
                                              }
                                            </span>
                                          )}
                                      </div>
                                    )}
                                    {
                                      index ? (
                                        // <button
                                        //   type="button"
                                        //   className="button remove"
                                        //   onClick={() => removeFormFields(index)}
                                        // >
                                        <div
                                          className="media-body user-meta-info"
                                          style={{ marginLeft: "-1rem" }}
                                          onClick={() =>
                                            removeFormFields(index)
                                          }
                                        >
                                          <ul>
                                            <li>
                                              <Link
                                                to={"#"}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  height: "25px",
                                                  width: "25px",
                                                  marginTop: "20px",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                <i
                                                  className="flaticon-019-close"
                                                  style={{
                                                    fontSize: "x-small",
                                                  }}
                                                ></i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      ) : null
                                      // {/* </button> */}
                                    }
                                  </div>
                                </div>
                              ))}

                              <div className="button-section" style={{
                                display: "flex",
                                justifyContent: "end",
                              }}>
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  onClick={() => addFormFields()}
                                >
                                  Add
                                </button>
                              </div>

                              <TextField
                                isAsterisk={true}
                                label="Incentive Start Date"
                                name="incentiveStartDate"
                                type="date"
                              />
                              <TextField
                                isAsterisk={true}
                                label="Incentive End Date"
                                name="incentiveEndDate"
                                type="date"
                              />
                            </div>
                            <div>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2"
                                type="submit"
                                onClick={() => {
                                  setFormSubmitted(true);
                                }}
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2 float-right"
                                variant="danger light"
                                onClick={() => {
                                  loadIncentivesData(
                                    currPage,
                                    visitType,
                                    start,
                                    end,
                                    selectedStatus,
                                    searchKey
                                  );
                                  setEditIncentiveModal(false);
                                  setIncentiveCriteria("");
                                  setIncentiveSubCriteria("");
                                  setIsCriteriaInPercentage(false);
                                  setIsIncentivePerCaseBasis(false);
                                  setIsIncentivesPaidInPercentage(false);
                                  setIsIncentivesPaidPerCase(false);
                                  setRejectionReason(null);
                                  setFormSubmitted(false);
                                }}
                              >
                                Close
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={cloneIncentiveModal}>
        <Modal.Header>
          <Modal.Title>Clone Incentive</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setCloneIncentiveModal(false);
              setIncentiveCriteria("");
              setIncentiveSubCriteria("");
              setIsCriteriaInPercentage(false);
              setIsIncentivePerCaseBasis(false);
              setIsIncentivesPaidInPercentage(false);
              setRejectionReason(null);
              setFormSubmitted(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Formik
                  initialValues={{
                    // id: incentiveDetails.id,
                    // branchIds: incentiveDetails.branchIds,
                    // cpNames: incentiveDetails.cpNames,
                    // currentBucketNames: incentiveDetails.currentBucketNames,
                    // designations: incentiveDetails.designations,
                    // incentiveCriteria: incentiveDetails.incentiveCriteria,
                    // incentiveEndDate: "",
                    // incentiveName: "",
                    // incentivePaidInPercentage: false,
                    // incentivePerCaseBasis: false,
                    // incentiveStartDate: "",
                    // incentiveSubCriteria: incentiveDetails.incentiveSubCriteria,
                    // managerIds: incentiveDetails.managerIds,
                    // productNames: incentiveDetails.productNames,
                    // percentage: false,
                    // productName: incentiveDetails.productName,
                    // productType: incentiveDetails.productType,

                    id: incentiveDetails.id,
                    branchIds: incentiveDetails.branchIds,
                    cpNames: incentiveDetails.cpNames,
                    currentBucketNames: incentiveDetails.currentBucketNames,
                    designations: incentiveDetails.designations,
                    incentiveCriteria: incentiveDetails.incentiveCriteria,
                    incentiveEndDate: "",
                    incentiveName: "",
                    incentivePaidInPercentage: false,
                    incentivePerCaseBasis: false,
                    incentiveStartDate: "",
                    incentiveSubCriteria: incentiveDetails.incentiveSubCriteria,
                    managerIds: incentiveDetails.managerIds,
                    productNames: incentiveDetails.productNames,
                    percentage: false,
                    productName: incentiveDetails.productName,
                    productType: incentiveDetails.productType,
                    incentiveRangesAndValues: [],
                  }}
                  onSubmit={cloneIncentive}
                  validationSchema={validate}
                >
                  {({ formik, errors }) => (
                    <div>
                      <Row>
                        <Col lg="12">
                          <Form>
                            <div>
                              <TextField
                                isAsterisk={true}
                                label="Incentive Name"
                                name="incentiveName"
                                type="text"
                              />
                              <Field name="branchIds">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Branch
                                    </label>
                                    <Select
                                      placeholder="Select a Branch"
                                      classNamePrefix="select-box"
                                      options={[allOption, ...branches]}
                                      defaultValue={defaultBranches}
                                      isMulti
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.branchIds && (
                                <span className="error">
                                  {errors.branchIds}
                                </span>
                              )}

                              <Field name="managerIds">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      // className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Manager
                                    </label>
                                    <Select
                                      placeholder="Select a Manager"
                                      classNamePrefix="select-box"
                                      options={[allOption, ...managers]}
                                      defaultValue={defaultManagers}
                                      isMulti
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onManagerChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.managerIds && (
                                <span className="error">
                                  {errors.managerIds}
                                </span>
                              )}

                              <Field name="designations">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Designation
                                    </label>
                                    <Select
                                      placeholder="Select a Designation"
                                      classNamePrefix="select-box"
                                      options={designations}
                                      defaultValue={defaultDesignations}
                                      isMulti
                                      onChange={(
                                        selectedOption,
                                        { action, removedValue }
                                      ) => {
                                        onDesignationChange(
                                          selectedOption,
                                          { action, removedValue },
                                          form
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.designations && (
                                <span className="error">
                                  {errors.designations}
                                </span>
                              )}

                              {visitType === "Collections" && (
                                <Field name="cpNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        //className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        CP Name
                                      </label>
                                      <Select
                                        placeholder="Select a CP Name"
                                        classNamePrefix="select-box"
                                        options={[allOption, ...cpNames]}
                                        defaultValue={defaultCpNames}
                                        isMulti
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onCpNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}
                              {errors.cpNames && (
                                <span className="error">{errors.cpNames}</span>
                              )}

                              {visitType === "Collections" && (
                                <Field name="currentBucketNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        //className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Current Bucket
                                      </label>
                                      <Select
                                        placeholder="Select a Current Bucket"
                                        classNamePrefix="select-box"
                                        options={[
                                          allOption,
                                          ...currentBucketNames,
                                        ]}
                                        defaultValue={defaultCurrentBuckets}
                                        isMulti
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onCurrentBucketNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}
                              {errors.currentBucketNames && (
                                <span className="error">
                                  {errors.currentBucketNames}
                                </span>
                              )}

                              {visitType !== "Collections" && (
                                <TextField
                                  isAsterisk={false}
                                  label="Product Type"
                                  name="productType"
                                  type="text"
                                />
                              )}
                              {visitType !== "Collections" && (
                                <TextField
                                  isAsterisk={false}
                                  label="Product Name"
                                  name="productName"
                                  type="text"
                                />
                              )}

                              {visitType === "Collections" && (
                                <Field name="productNames">
                                  {({ field, form }) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        // className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Product Name
                                      </label>
                                      <Select
                                        placeholder="Select a Product"
                                        classNamePrefix="select-box"
                                        options={[allOption, ...productNames]}
                                        defaultValue={defaultProductNames}
                                        isMulti
                                        onChange={(
                                          selectedOption,
                                          { action, removedValue }
                                        ) => {
                                          onProductNameChange(
                                            selectedOption,
                                            { action, removedValue },
                                            form
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )}

                              {errors.productNames && (
                                <span className="error">
                                  {errors.productNames}
                                </span>
                              )}

                              {errors.productName && (
                                <span className="error">
                                  {errors.productName}
                                </span>
                              )}
                              <Field name="incentiveCriteria">
                                {({ field, form }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label
                                      className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Incentive Criteria
                                    </label>
                                    <Select
                                      placeholder="Select Incentive Criteria"
                                      classNamePrefix="select-box"
                                      defaultValue={defaultIncentiveCriteria}
                                      options={
                                        visitType === "Collections"
                                          ? collectionCriteriaOptions
                                          : salesAndFICriteriaOptions
                                      }
                                      onChange={(selectedOption) => {
                                        setIncentiveCriteriaValue(
                                          selectedOption
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {errors.incentiveCriteria && (
                                <span className="error">
                                  {errors.incentiveCriteria}
                                </span>
                              )}

                              {visitType === "Collections" &&
                                incentiveCriteria !== "" && (
                                  <Field name="incentiveSubCriteria">
                                    {({ field, form }) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label
                                          className="control-label"
                                          style={{
                                            fontWeight: "bold",
                                            float: "left",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          Incentive Sub Criteria
                                        </label>
                                        <Select
                                          placeholder="Select Incentive Sub Criteria"
                                          classNamePrefix="select-box"
                                          defaultValue={
                                            defaultIncentiveSubCriteria
                                          }
                                          options={
                                            incentiveCriteria ===
                                              "Collected Amount"
                                              ? subCriteriaOptionsForCollectedAmount
                                              : incentiveCriteria ===
                                                "Calls Count"
                                                ? subCriteriaOptionsForCallsCount
                                                : subCriteriaOptionsForVisitCount
                                          }
                                          onChange={(selectedOption) => {
                                            setIncentiveSubCriteriaValue(
                                              selectedOption
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                )}

                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" && (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Is Criteria Definition In %:
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        type="radio"
                                        id="percentageYes"
                                        name="percentage"
                                        value="yes"
                                        checked={isCriteriaInPercentage}
                                        onClick={() =>
                                          setIsCriteriaInPercentage(true)
                                        }
                                      />
                                      <label
                                        htmlFor="percentageYes"
                                        style={{ marginRight: "10px" }}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        type="radio"
                                        id="percentageNo"
                                        name="percentage"
                                        value="no"
                                        checked={!isCriteriaInPercentage}
                                        onClick={() =>
                                          setIsCriteriaInPercentage(false)
                                        }
                                      />
                                      <label htmlFor="percentageNo">No</label>
                                    </div>
                                  </div>
                                )}

                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" && (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center", // Align items vertically
                                    }}
                                  >
                                    <label
                                      className="form-check-label"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Is Criteria Per Case Basis:
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px", // Increased height
                                          width: "15px", // Increased width
                                        }}
                                        type="radio"
                                        id="percaseYes"
                                        name="perCaseYes"
                                        value="yes"
                                        checked={isIncentivePerCaseBasis}
                                        onChange={() => {
                                          setIsIncentivePerCaseBasis(true);
                                          setIsIncentivesPaidPerCase(true);
                                        }}
                                      />
                                      <label
                                        htmlFor="percentageYes"
                                        style={{ marginRight: "10px" }}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                          height: "15px", // Increased height
                                          width: "15px", // Increased width
                                        }}
                                        type="radio"
                                        id="perCaseNo"
                                        name="perCaseBasis"
                                        value="no"
                                        checked={!isIncentivePerCaseBasis}
                                        onChange={() => {
                                          setIsIncentivePerCaseBasis(false);
                                          setIsIncentivesPaidPerCase(false);
                                        }}
                                      />
                                      <label htmlFor="perCaseNo">No</label>
                                    </div>
                                  </div>
                                )}

                                <div
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                    marginRight: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "initial",
                                  }}
                                >
                                  <label
                                    className="form-check-label"
                                    htmlFor="incentivePerCaseYes"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Is Incentive Paid Per Case: Yes
                                  </label>
                                  <input
                                    style={{
                                      width: "15px",
                                      height: "20px",
                                      marginLeft: "10px",
                                      fontWeight: "bold",
                                    }}
                                    type="radio"
                                    id="incentivePerCaseYes"
                                    name="incentivePerCase"
                                    checked={isIncentivesPaidPerCase}
                                    disabled={isIncentivesPaidInPercentage || isIncentivePerCaseBasis}
                                    onClick={() => {
                                      setIsIncentivesPaidPerCase(true);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="incentivePerCaseNo"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    No
                                  </label>
                                  <input
                                    style={{
                                      width: "15px",
                                      height: "20px",
                                      marginLeft: "10px",
                                      fontWeight: "bold",
                                    }}
                                    type="radio"
                                    id="incentivePerCaseNo"
                                    name="incentivePerCase"
                                    checked={!isIncentivesPaidPerCase}
                                    disabled={isIncentivesPaidInPercentage || isIncentivePerCaseBasis}
                                    onClick={() => {
                                      setIsIncentivesPaidPerCase(false);
                                    }}
                                  />
                                </div>

                              {visitType === "Collections" &&
                                incentiveCriteria === "Collected Amount" &&(
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                      marginRight: "15px",
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "initial",
                                    }}
                                  >
                                    <label
                                      className="form-check-label"
                                      htmlFor="incentivePaidInPercentageYes"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Is Incentive Paid In Percentage: Yes
                                    </label>
                                    <input
                                      style={{
                                        width: "15px",
                                        height: "20px",
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                      }}
                                      type="radio"
                                      id="incentivePaidInPercentageYes"
                                      name="incentivePaidInPercentage"
                                      checked={isIncentivesPaidInPercentage}
                                      disabled={isIncentivesPaidPerCase && !isIncentivePerCaseBasis}
                                      onChange={() => {
                                        setIsIncentivesPaidInPercentage(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="incentivePaidInPercentageNo"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      No
                                    </label>
                                    <input
                                      style={{
                                        width: "15px",
                                        height: "20px",
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                      }}
                                      type="radio"
                                      id="incentivePaidInPercentageNo"
                                      name="incentivePaidInPercentage"
                                      checked={!isIncentivesPaidInPercentage}
                                      disabled={isIncentivesPaidPerCase && !isIncentivePerCaseBasis}
                                      onClick={() => {
                                        setIsIncentivesPaidInPercentage(false);
                                      }}
                                    />
                                  </div>
                                )}

                              {formValues.map((element, index) => (
                                <div className="form-inline" key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-evenly",
                                      columnGap: "0.5rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      <Field
                                        id={`rangeSelect-${index}`}
                                        name={`range-${index}`}
                                      >
                                        {({ field, form }) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "10rem",
                                              marginBottom: "10px",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <label
                                              className="control-label"
                                              style={{
                                                fontWeight: "bold",
                                                float: "left",
                                              }}
                                            >
                                              Range
                                            </label>
                                            <div
                                              style={{
                                                width: "7rem",
                                                height: "38px",
                                                marginTop: "5px",
                                                borderRadius: "3px",
                                                borderWidth: "0px",
                                                boxShadow: "1px 1px 2px #797979",
                                              }}
                                            >
                                              <Select
                                                placeholder="Select"
                                                classNamePrefix="select-box"
                                                options={rangeOptions}
                                                value={element.selectedOption}
                                                onChange={(e) =>
                                                  handleSelectChange(
                                                    index,
                                                    e,
                                                    form
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Field>
                                      {formSubmitted && formValueErrors[index] &&
                                        formValueErrors[index].range && (
                                          <span className="error">
                                            {
                                              formValueErrors[index]
                                                .range[0]
                                            }
                                          </span>
                                        )}
                                    </div>

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria !==
                                      "Collected Amount" && (
                                        <div>
                                          <TextField
                                            isAsterisk={true}
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                              fontSize:
                                                element.range === "between"
                                                  ? "x-small"
                                                  : "11.2px",
                                            }}
                                            label={
                                              element.range === "between"
                                                ? "Criteria Value 1"
                                                : "Criteria Value"
                                            }
                                            name="criteriaValue"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaValue && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaValue[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria !==
                                      "Collected Amount" &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            isAsterisk={true}
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                            }}
                                            label="Criteria Value 2"
                                            name="criteriaValue2"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaValue2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaValue2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {isCriteriaInPercentage && (
                                      <div>
                                        <TextField
                                          isAsterisk={true}
                                          style={{
                                            width:
                                              element.range === "between"
                                                ? "7rem"
                                                : "10rem",
                                          }}
                                          label={
                                            element.range === "between"
                                              ? "Criteria Percentage 1"
                                              : "Criteria Percentage"
                                          }
                                          name="criteriaPercentage"
                                          type="text"
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        />
                                        {formSubmitted &&
                                          formValueErrors[index] &&
                                          formValueErrors[index]
                                            .criteriaPercentage && (
                                            <span className="error">
                                              {
                                                formValueErrors[index]
                                                  .criteriaPercentage[0]
                                              }
                                            </span>
                                          )}
                                      </div>
                                    )}

                                    {isCriteriaInPercentage &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "8rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label="Criteria Percentage 2"
                                            name="criteriaPercentage2"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaPercentage2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaPercentage2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria ===
                                      "Collected Amount" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label={
                                              <span
                                                style={{
                                                  fontSize:
                                                    element.range === "between"
                                                      ? "x-small"
                                                      : "11.2px",
                                                }}
                                              >
                                                {element.range === "between"
                                                  ? "Criteria Amount 1"
                                                  : "Criteria Amount"}
                                              </span>
                                            }
                                            name="criteriaTargetAmount"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaTargetAmount && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaTargetAmount[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isCriteriaInPercentage &&
                                      incentiveCriteria ===
                                      "Collected Amount" &&
                                      element.range === "between" && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "7.5rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label={
                                              <span
                                                style={{
                                                  fontSize: "x-small",
                                                }}
                                              >
                                                Criteria Amount 2
                                              </span>
                                            }
                                            name="criteriaTargetAmount2"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .criteriaTargetAmount2 && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .criteriaTargetAmount2[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {incentiveCriteria === "Collected Amount" &&
                                      isIncentivesPaidInPercentage && (
                                        <div>
                                          <TextField
                                            style={{
                                              width:
                                                element.range === "between"
                                                  ? "6rem"
                                                  : "10rem",
                                            }}
                                            isAsterisk={true}
                                            label="Incentive Percentage"
                                            name="incentivePerecentage"
                                            type="text"
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                          {formSubmitted &&
                                            formValueErrors[index] &&
                                            formValueErrors[index]
                                              .incentivePerecentage && (
                                              <span className="error">
                                                {
                                                  formValueErrors[index]
                                                    .incentivePerecentage[0]
                                                }
                                              </span>
                                            )}
                                        </div>
                                      )}

                                    {!isIncentivesPaidInPercentage && (
                                      <div>
                                        <TextField
                                          style={{
                                            width:
                                              element.range === "between"
                                                ? "6rem"
                                                : "10rem",
                                          }}
                                          isAsterisk={true}
                                          label="Incentive Amount"
                                          name="incentiveAmount"
                                          type="text"
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        />
                                        {formSubmitted &&
                                          formValueErrors[index] &&
                                          formValueErrors[index]
                                            .incentiveAmount && (
                                            <span className="error">
                                              {
                                                formValueErrors[index]
                                                  .incentiveAmount[0]
                                              }
                                            </span>
                                          )}
                                      </div>
                                    )}
                                    {
                                      index ? (

                                        <div
                                          className="media-body user-meta-info"
                                          style={{ marginLeft: "-1rem" }}
                                          onClick={() =>
                                            removeFormFields(index)
                                          }
                                        >
                                          <ul>
                                            <li>
                                              <Link
                                                to={"#"}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  height: "25px",
                                                  width: "25px",
                                                  marginTop: "20px",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                <i
                                                  className="flaticon-019-close"
                                                  style={{
                                                    fontSize: "x-small",
                                                  }}
                                                ></i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      ) : null
                                      // {/* </button> */}
                                    }
                                  </div>
                                </div>
                              ))}

                              <div className="button-section" style={{
                                display: "flex",
                                justifyContent: "end",
                              }}>
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  onClick={() => addFormFields()}
                                >
                                  Add
                                </button>
                              </div>

                              <TextField
                                isAsterisk={true}
                                label="Incentive Start Date"
                                name="incentiveStartDate"
                                type="date"
                              />
                              <TextField
                                isAsterisk={true}
                                label="Incentive End Date"
                                name="incentiveEndDate"
                                type="date"
                              />
                            </div>

                            <div>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2"
                                type="submit"
                                onClick={() => {
                                  setFormSubmitted(true);
                                }}
                              >
                                Submit
                              </Button>
                              <Button
                                style={{ float: "right" }}
                                className="col-lg-2 m-2 float-right"
                                variant="danger light"
                                onClick={() => {
                                  setCloneIncentiveModal(false);
                                  setIncentiveCriteria("");
                                  setIncentiveSubCriteria("");
                                  setIsCriteriaInPercentage(false);
                                  setIsIncentivePerCaseBasis(false);
                                  setIsIncentivesPaidInPercentage(false);
                                  setIsIncentivesPaidPerCase(false);
                                  setRejectionReason(null);
                                  setFormSubmitted(false);
                                }}
                              >
                                Close
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default IncentiveMgmt;