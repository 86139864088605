import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import * as moduleApi from "../rest/moduleApi";
import * as moduleApi2 from "../rest/moduleApi2";

import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import { apiCall } from "../rest/restApi";
// import { toaster } from "../rest/apiUtils";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import swal from "sweetalert";
// import Select from "react-select";
// import SearchBar from "../CommonComponents/SearchBar";
import ReactGA from "react-ga";

const UploadedFiles = () => {
  const [isLoading, setIsLoading] = useState(false);
    const [errorDescModal, setErrorDescModal] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
    const [errorDescription, setErrorDescription] = useState(null);
  // eslint-disable-next-line
  const [currPage, setCurrPage] = useState(0);
  const [UploadedFileList, setUploadedFileList] = useState([]);
  // const [searchKey2, setSearchKey2] = useState("");
  // const [selectedValue, setSelectedValue] = useState("EXECUTIVE");
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview("Uploaded Files For Payroll");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    UploadedList(start, end, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UploadedList = (start, end, pageNo) => {
    let listData = [];
    setIsLoading(true);
    // let url =
    // apiCall.payrollUploadBaseUrl + "?action=getUploadedPayRollFileList";

    let body = {
      //   action: "preSignedUrlStatusOrData",
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      // size: 10,
      // page: pageNo,
      startDate: start,
    };

    // moduleApi.postDataByOrgIdForPayroll(url, body).then((response) => {
      moduleApi2
      .postData(
        moduleApi2.withPage(apiCall.getPayrollUploadFilesList, pageNo, 10),
        body
      )
      .then((response) => {
      console.log("uploadedFileListResponse", response);
      //message: "Service Unavailable"
      if(response){
      if (response?.message) {
        setIsLoading(false);
        return swal(response?.message, {
          icon: "error",
          dangerMode: true,
        });
      } else {
        response?.content?.forEach((element) => {
          let data = {
            uploadedUserId: element.uploadedUserId,
            fileName: element.fileName ? element.fileName : "-",
            errorMessage: element.errorMessage
              ? element.errorMessage
              : "-",
            createdAt: element.createdAt
              ? convertEpochToDateTime(element.createdAt)
              : "-",
            presignedUrl: element.presignedUrl,
            procesingMessage: element.procesingMessage
              ? element.procesingMessage
              : "-",
            id: element.id,
            validationErrors: element.validationErrors,
            totalNoOfRecordsProcessed: element.totalNoOfRecordsProcessed,
            organizationId: element.organizationId,
            status: element.status,
          };

          listData.push(data);
        });
        setUploadedFileList(listData);
        setTotalElements(response?.data?.totalElements);
        setIsLoading(false);
      }}else{
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try again", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const convertEpochToDateTime = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime * 1000));
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      ", " +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const columns = [
    {
      text: "File Name",
      dataField: "fileName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created At",
      dataField: "createdAt",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "COMPLETED") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else if (row.status === "PROCESSING") {
          return (
            <span className="badge badge-rounded badge-warning">
              Processing
            </span>
          );
        } else if (row.status === "PENDING") {
          return (
            <span className="badge badge-rounded badge-warning">Pending</span>
          );
        } else if (row.status === "FAILED") {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "FAILED") {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        } else {
          return <></>;
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // var data = row?.procesingMessage.split("\\n");
          // console.log("data", data);
          setErrorDescription(row?.errorMessage);
          setErrorDescModal(true);
        },
      },
    },
    // {
    //   text: "",
    //   dataField: "status",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     if (row.status === "ACTIVE4") {
    //       return <></>;
    //     } else {
    //       return (
    //         <div className="d-flex">
    //           <Link to="#" className="btn btn-success shadow btn-xs sharp">
    //             <i className="fa fa-download"></i>
    //           </Link>
    //         </div>
    //       );
    //     }
    //   },

    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       downlaodFile(row?.fileName);
    //     },
    //   },
    // },
  ];

  //   const downlaodFile = (fileName) => {
  //     setIsLoading(true);
  //     let url =
  //       "https://9ylmyoqrai.execute-api.ap-south-1.amazonaws.com/default/bulk_upload?action=preSignedUrlStatusOrData&download=true";

  //     moduleApi
  //       .getDownloadedFilePreSignedUrl(
  //         url,
  //         userLoginDetails.autoFinanceUser.organizationId,
  //         fileName
  //       )
  //       .then((response) => {
  //         console.log("preSignedUrl", response);
  //         if (response) {
  //           if (response.generatedUrl) {
  //             moduleApi
  //               .getDownloadedFile(response?.generatedUrl)
  //               .then((response) => {
  //                 //Service Unavailable
  //                 console.log("bulk Upload", response);
  //                 setIsLoading(false);
  //               });
  //           }
  //         }
  //       });
  //   };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    UploadedList(start, end, selectedPage - 1);
  };

  // const searchKeyword2 = (key) => {
  //   setSearchKey2(key);
  //   NonAllocatedLoadData(0, key);
  // };

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;
    setStart(start);
    setEnd(end);
    UploadedList(start, end, 0);
  };

  return (
    <div>
      <div>
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div
            style={{
              float: "right",
              // width: "120px",
              marginRight: "15px",
              marginBottom: "10px",
              marginLeft: "15px",
            }}
          >
            <Flatpickr
              className="form-control d-block"
              style={{
                width: "200px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
                // paddingLeft: "10px",
                // marginBottom: "10px",
                // float: "right",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value);
              }}
              options={{
                // mode: "range",
                // dateFormat: "d-M-y",
                defaultDate: new Date(),
                maxDate: new Date(),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          </div>

          {/* <input
            style={{
              width: "300px",
              height: "40px",
              borderRadius: "25px",
              borderColor: "#a0a0a0",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #3a353b",
              paddingLeft: "10px",
              marginBottom: "10px",
              float: "right",
            }}
            placeholder="Search By Name/Mobile"
            onChange={(e) => searchKeyword2(e.target.value.trim())}
          /> */}
          {/* <div style={{float:'right', marginBottom:'7px'}}>
            <SearchBar
              placeholder="Search By Name/Mobile/Email/Emp Id"
              searchKeyword={searchKeyword2}
            />
          </div> */}
          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "-5px",
            }}
          >
            <div
              style={{ overflow: "auto", maxHeight: "400px", width: "100%" }}
            >
              <BootstrapTable
                keyField="id"
                data={UploadedFileList}
                columns={columns}
                // selectRow={ selectRow }
              />
            </div>
          </div>
          {UploadedFileList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
          <Modal className="fade" size="md" show={errorDescModal}>
            <Modal.Header>
              <Modal.Title>Error Description</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setErrorDescModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body style={{ padding: "15px" }}>
              {/* {errorDescription?.map((element, index) => (
                <div>
                  <span>{index + 1 + ") " + element}</span>
                  <br></br>
                  <br></br>
                </div>
              ))} */}
              <div>
                <span>
                  {errorDescription}
                </span>
              </div>
            </Modal.Body>
          </Modal>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default UploadedFiles;
