import React, { useState } from "react";
import { Nav, Tab, Col, Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { getTokenInLocalStorage, hasAccess } from "../services/AuthService";
import "./PaymentDetailsTable.css";
import { EpochToDate, EpochToOnlyDate } from "../CommonComponents/Helper";
import {
  setCallAudioModal,
  setIsModalMinimised,
} from "../store/actions/CallingActions";
import { useDispatch, useSelector } from "react-redux";
import { StartCall } from "../CommonComponents/TelecallingMethods";
// import PIOPIY from "piopiyjs";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";

const LoanNumberDetails = ({ allocationDetails, close }) => {
  // var piopiy = new PIOPIY({
  //   name: "Display Name",
  //   debug: false,
  //   autoplay: true,
  //   ringTime: 60,
  // });
  const [editModal, setEditModal] = useState(false);
  const [isPrimaryMobile, setIsPrimaryMobile] = useState(false);
  const [additionalNumbers, setAdditionalNumbers] = useState({});
  const [enteredMobileNumber, setEnteredMobileNumber] = useState(null);
  const [isMap, setIsMap] = useState(false);
  const [isLastVisitedAddress2, setIsLastVisitedAddress2] = useState(false);
  const [random] = useState(Math.floor(Math.random() * 100));
  const {
    piopiy,
    isCallActive,
    isIncomingCall,
    isOnGoingCall,
    isProgressiveDialing,
  } = useSelector((state) => state.calls);
  const dispatch = useDispatch();

  const userData = getTokenInLocalStorage();

  const openModal = () => {
    dispatch(setCallAudioModal(true));
    dispatch(setIsModalMinimised(false));
  };

  const checkIsTelecaller = () => {
    if (
      hasAccess(42) &&
      userData?.autoFinanceUser?.teleCmiUserId !== null &&
      userData?.autoFinanceUser?.teleCmiPassword !== null &&
      userData?.autoFinanceUser?.teleCmiUserId !== "" &&
      userData?.autoFinanceUser?.teleCmiPassword !== "" &&
      isProgressiveDialing === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getAddditonalPhoneNos = () => {
    moduleApi
      .getData(
        apiCall.getAdditionalPhoneNumbers + allocationDetails?.loanNumber
      )
      .then((response) => {
        // console.log("response", response);
        setAdditionalNumbers(response);
      });
  };

  const updateMobileNumber = () => {
    // console.log(/^\d{10}$/.test(enteredMobileNumber));
    if (enteredMobileNumber === null || enteredMobileNumber === "") {
      return swal("Please Enter Mobile Number", {
        icon: "error",
        dangerMode: true,
      });
    } else if (/^\d{10}$/.test(enteredMobileNumber) === false) {
      return swal("Please Enter Valid Mobile Number", {
        icon: "error",
        dangerMode: true,
      });
    }
    var payload = {
      allocationId: allocationDetails?.id,
      newMobileNumber: enteredMobileNumber,
      primaryNumber: isPrimaryMobile,
    };
    // console.log(payload);
    moduleApi
      .postData(apiCall.updateAllocationMobileNumber, payload)
      .then((response) => {
        // console.log("response", response);
        if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response?.Message === "Mobile Number updated sucessfully") {
          setEditModal(false);
          close();
          return swal("Updated Successfully", {
            icon: "success",
          });
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  return (
    <div style={{ margin: "20px" }}>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="basicDetails">
          <Nav as="ul" className="nav-tabs-custom nav-justified">
            <Nav.Item as="li" key="0">
              <Nav.Link eventKey="basicDetails">
                <b> Basic Details </b>
              </Nav.Link>
            </Nav.Item>
            {hasAccess(106) && (
            <Nav.Item as="li" key="1">
              <Nav.Link eventKey="loanDetails">
                <b> Loan Details </b>
              </Nav.Link>
            </Nav.Item>
            )}
            {hasAccess(107) && (
            <Nav.Item as="li" key="2">
              <Nav.Link eventKey="customerDetails">
                <b> Customer Details </b>
              </Nav.Link>
            </Nav.Item>
            )}
            {hasAccess(108) && (
            <Nav.Item as="li" key="3">
              <Nav.Link eventKey="productDetails">
                <b> Product Details </b>
              </Nav.Link>
            </Nav.Item>
            )}
            <Nav.Item as="li" key="4">
              <Nav.Link eventKey="paymentDetails">
                <b> Payment Details </b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="5">
              <Nav.Link
                eventKey="additionalNumbers"
                onClick={() => {
                  getAddditonalPhoneNos();
                }}
              >
                <b> Additional Contact No's </b>
              </Nav.Link>
            </Nav.Item>
            {hasAccess(89) && (
              <Nav.Item as="li" key="6">
                <Nav.Link eventKey="loanAdditionalDetails">
                  <b> Loan Additional Details </b>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="basicDetails" key="0" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr></tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Loan Number
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.loanNumber
                            ? allocationDetails.loanNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Customer Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.customerName
                            ? allocationDetails.customerName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Spouse Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.spouseName
                            ? allocationDetails.spouseName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Group Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.groupName
                            ? allocationDetails.groupName
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Area Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.unit
                            ? allocationDetails.unit
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Vehicle Type
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.vehicleType
                            ? allocationDetails.vehicleType
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Customer Intensity
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.customerIntensinty
                            ? allocationDetails.customerIntensinty
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Product
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.productName
                            ? allocationDetails.productName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          CP Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.cpName
                            ? allocationDetails.cpName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Target Date
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.targetDate
                            ? allocationDetails.targetDate
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Priority
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.priority
                            ? allocationDetails.priority
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Installment Date
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.installmentDate
                            ? allocationDetails.installmentDate
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        AG Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails?.aggDate
                          ? EpochToOnlyDate(allocationDetails?.aggDate)
                          : "-"}
                      </td>
                    </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Lender ID
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.lenderId
                            ? allocationDetails.lenderId
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Lender Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.lenderName
                            ? allocationDetails.lenderName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Total Due Amount
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.dueAmount
                            ? allocationDetails.dueAmount
                            : "-"}
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Current Due Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.amountToBePaid
                          ? allocationDetails.amountToBePaid
                          : 0}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Amount Paid
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.amountPaid
                          ? allocationDetails.amountPaid
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Last Paid Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.lastPaidDate
                          ? allocationDetails.lastPaidDate
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Last Collected Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.lastCollectedAmount
                          ? allocationDetails.lastCollectedAmount
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Expired Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.expiredDate !== "-"
                          ? allocationDetails.expiredDate
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Residence Address Line 1
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.address
                          ? allocationDetails.address
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Residence Address Line 2
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.addressLine2
                          ? allocationDetails.addressLine2
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Residence City
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.residenceCity
                          ? allocationDetails.residenceCity
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Residence State
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.residenceState
                          ? allocationDetails.residenceState
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Residence ZipCode
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.residenceZipcode
                          ? allocationDetails.residenceZipcode
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Near By Landmark
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.nearByLandmark
                          ? allocationDetails.nearByLandmark
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Lat-Longs
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "5px",
                            alignItems: "center",
                          }}
                        >
                          {allocationDetails?.residenceLatitude
                            ? allocationDetails?.residenceLatitude +
                              "," +
                              allocationDetails?.residenceLongitude
                            : "-"}
                          {allocationDetails?.residenceLatitude && (
                            <div
                              className="media-body user-meta-info"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <ul>
                                {
                                  <li
                                    onClick={() => {
                                      window.open(
                                        "https://maps.google.com?q=" +
                                          allocationDetails?.residenceLatitude +
                                          "," +
                                          allocationDetails?.residenceLongitude
                                      );
                                    }}
                                  >
                                    <Link
                                      to={"#"}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                        width: "30px",
                                      }}
                                    >
                                      <i
                                        className="fas fa-external-link-alt"
                                        style={{ fontSize: "smaller" }}
                                      ></i>
                                    </Link>
                                  </li>
                                }
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Contact No 1
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.contactNumber
                          ? allocationDetails.contactNumber
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Remarks
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.remarks
                          ? allocationDetails.remarks
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Due Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.dueDate
                          ? allocationDetails.dueDate
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Last Visit Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.lastVisitDate
                          ? EpochToDate(allocationDetails.lastVisitDate)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Last PTP Date
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.lastPtpDate
                          ? EpochToDate(allocationDetails.lastPtpDate)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Last Visit Status
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.visitStatus
                          ? allocationDetails.visitStatus
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Assigned Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.assignedName
                          ? allocationDetails.assignedName
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Assigned Mobile Number
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.assignedMobileNumber
                          ? allocationDetails.assignedMobileNumber
                          : "-"}
                      </td>
                    </tr>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="loanDetails" key="1" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Loan Amount
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.loanAmount
                            ? allocationDetails.loanAmount
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          POS (Principal Outstanding)
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.principalOutstanding
                            ? allocationDetails.principalOutstanding
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          EMI Amount
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.emiAmount
                            ? allocationDetails.emiAmount
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Current EMI Month
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.current_EMI_Month
                            ? allocationDetails.current_EMI_Month
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          DPD Days
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.dpdDays
                            ? allocationDetails.dpdDays
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Current Bucket
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.currentBucket
                            ? allocationDetails.currentBucket
                            : "-"}
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Tenure (Months)
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.tenure
                          ? allocationDetails.tenure
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        EMI OS
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.emiOutstanding
                          ? allocationDetails.emiOutstanding
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        LPP (Late Payment Charges)
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.latePaymentCharges
                          ? allocationDetails.latePaymentCharges
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        BCC (Bounce Charges)
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.bounceCharges
                          ? allocationDetails.bounceCharges
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Total Penalty Charges
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.totalPenaltyCharges
                          ? allocationDetails.totalPenaltyCharges
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Net GDI Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.net_GDI_Amount
                          ? allocationDetails.net_GDI_Amount
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Net HL Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.net_HL_Amount
                          ? allocationDetails.net_HL_Amount
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Net Other Amount
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.netOtherAmount
                          ? allocationDetails.netOtherAmount
                          : "-"}
                      </td>
                    </tr>
                    
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Due Day
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.dueDay
                          ? allocationDetails.dueDay
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Third Party Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.thirdPartyName
                          ? allocationDetails.thirdPartyName
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Third Party Mobile
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.thirdPartyPhoneNumber
                          ? allocationDetails.thirdPartyPhoneNumber
                          : "-"}
                      </td>
                    </tr>
                  </div>
                </div>
              </Col>{" "}
            </Tab.Pane>
            <Tab.Pane eventKey="customerDetails" key="2" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    margin: "5px",
                    overflowY: "auto",
                    maxHeight: "400px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Location
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.location
                            ? allocationDetails.location
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 2
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {allocationDetails.contactNumber2
                                ? allocationDetails.contactNumber2
                                : "-"}
                            </span>
                            {allocationDetails.contactNumber2 ? (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {hasAccess(42) &&
                                    userData?.autoFinanceUser?.teleCmiUserId !==
                                      null &&
                                    userData?.autoFinanceUser
                                      ?.teleCmiPassword !== null &&
                                    userData?.autoFinanceUser?.teleCmiUserId !==
                                      "" &&
                                    userData?.autoFinanceUser
                                      ?.teleCmiPassword !== "" &&
                                    isProgressiveDialing === false && (
                                      <li
                                        onClick={() => {
                                          if (
                                            isOnGoingCall === false &&
                                            isIncomingCall === false
                                          ) {
                                            // callingEventsInstance.startCall(
                                            StartCall(
                                              piopiy,
                                              dispatch,
                                              allocationDetails?.contactNumber2,
                                              false,
                                              allocationDetails?.id,
                                              allocationDetails,
                                              "Collections",
                                              isCallActive
                                            );
                                          } else {
                                            openModal();
                                          }
                                        }}
                                      >
                                        <Link
                                          to={"#"}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "30px",
                                            width: "30px",
                                          }}
                                        >
                                          <i
                                            className="fas fa-phone-alt"
                                            style={{ fontSize: "smaller" }}
                                          ></i>
                                        </Link>
                                      </li>
                                    )}
                                  {userData.autoFinanceUser?.roleName !==
                                    "CP_USER" && (
                                    <li
                                      onClick={() => {
                                        setEditModal(true);
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            ) : (
                              userData.autoFinanceUser?.roleName !==
                                "CP_USER" && (
                                <div
                                  className="media-body user-meta-info"
                                  style={{ paddingRight: "200px" }}
                                >
                                  <ul>
                                    <li
                                      onClick={() => {
                                        setEditModal(true);
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              )
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Borrower Address (Home)
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.borrowerAddress
                            ? allocationDetails.borrowerAddress
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Promoter Address (Office)
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.lenderAddress
                            ? allocationDetails.lenderAddress
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Permanent Address
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.permanentAddress
                            ? allocationDetails.permanentAddress
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Permanent City
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.permanentCity
                            ? allocationDetails.permanentCity
                            : "-"}
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td style={{ fontWeight: "500", textAlign: "left" }}>
                        Permanent State
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.permanentState
                          ? allocationDetails.permanentState
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Permanent ZipCode
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.permanentZipcode
                          ? allocationDetails.permanentZipcode
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Ref1 Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.referenceName1
                          ? allocationDetails.referenceName1
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Ref1 Mobile
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <span style={{ alignSelf: "center" }}>
                            {allocationDetails.referenceMobile1
                              ? allocationDetails.referenceMobile1
                              : "-"}
                          </span>
                          {allocationDetails.referenceMobile1 && (
                            <div
                              className="media-body user-meta-info"
                              style={{ paddingRight: "200px" }}
                            >
                              <ul>
                                {hasAccess(42) &&
                                  userData?.autoFinanceUser?.teleCmiUserId !==
                                    null &&
                                  userData?.autoFinanceUser?.teleCmiPassword !==
                                    null &&
                                  userData?.autoFinanceUser?.teleCmiUserId !==
                                    "" &&
                                  userData?.autoFinanceUser?.teleCmiPassword !==
                                    "" &&
                                  isProgressiveDialing === false && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            allocationDetails?.referenceMobile1,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                      {/* <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.referenceMobile1
                          ? allocationDetails.referenceMobile1
                          : "-"}
                      </td> */}
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Ref2 Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.referenceName2
                          ? allocationDetails.referenceName2
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Ref2 Mobile
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <span style={{ alignSelf: "center" }}>
                            {allocationDetails.referenceMobile2
                              ? allocationDetails.referenceMobile2
                              : "-"}
                          </span>
                          {allocationDetails.referenceMobile2 && (
                            <div
                              className="media-body user-meta-info"
                              style={{ paddingRight: "200px" }}
                            >
                              <ul>
                                {hasAccess(42) &&
                                  userData?.autoFinanceUser?.teleCmiUserId !==
                                    null &&
                                  userData?.autoFinanceUser?.teleCmiPassword !==
                                    null &&
                                  userData?.autoFinanceUser?.teleCmiUserId !==
                                    "" &&
                                  userData?.autoFinanceUser?.teleCmiPassword !==
                                    "" &&
                                  isProgressiveDialing === false && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            allocationDetails?.referenceMobile2,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                      {/* <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.referenceMobile2
                          ? allocationDetails.referenceMobile2
                          : "-"}
                      </td> */}
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Guarantor Name
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.guarantorName
                          ? allocationDetails.guarantorName
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Guarantor Mobile
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <span style={{ alignSelf: "center" }}>
                            {allocationDetails.guarantorMobile
                              ? allocationDetails.guarantorMobile
                              : "-"}
                          </span>
                          {allocationDetails.guarantorMobile && (
                            <div
                              className="media-body user-meta-info"
                              style={{ paddingRight: "200px" }}
                            >
                              <ul>
                                {hasAccess(42) &&
                                  userData?.autoFinanceUser?.teleCmiUserId !==
                                    null &&
                                  userData?.autoFinanceUser?.teleCmiPassword !==
                                    null &&
                                  userData?.autoFinanceUser?.teleCmiUserId !==
                                    "" &&
                                  userData?.autoFinanceUser?.teleCmiPassword !==
                                    "" &&
                                  isProgressiveDialing === false && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            allocationDetails?.guarantorMobile,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Last Paid Address 1
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.lastVisitedAddressLat ? (
                          <span
                            style={{
                              color: "#2020cb",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsLastVisitedAddress2(false);
                              setIsMap(true);
                            }}
                          >
                            {allocationDetails.lastVisitedAddress}
                          </span>
                        ) : allocationDetails.lastVisitedAddress ? (
                          <span
                            style={{
                              color: "#2020cb",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(allocationDetails.lastVisitedAddress);
                            }}
                          >
                            {allocationDetails.lastVisitedAddress}
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          textAlign: "left",
                          width: "30%",
                        }}
                      >
                        Last Paid Address 2
                      </td>
                      <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {allocationDetails.lastVisitedAddress2Lat ? (
                          <span
                            style={{
                              color: "#2020cb",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsLastVisitedAddress2(true);
                              setIsMap(true);
                            }}
                          >
                            {allocationDetails.lastVisitedAddress2}
                          </span>
                        ) : allocationDetails.lastVisitedAddress2 ? (
                          <span
                            style={{
                              color: "#2020cb",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(
                                allocationDetails.lastVisitedAddress2
                              );
                            }}
                          >
                            {allocationDetails.lastVisitedAddress2}
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="productDetails" key="3" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <table>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Registration Number
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.registrationNumber
                            ? allocationDetails.registrationNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Temporary Registration Number
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.temporaryRegistrationNumber
                            ? allocationDetails.temporaryRegistrationNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Engine Number
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.engineNumber
                            ? allocationDetails.engineNumber
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Chasis Number
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.chassisNumber
                            ? allocationDetails.chassisNumber
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td style={{ fontWeight: "500", textAlign: "left" }}>
                          Asset Desc
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.assetDesc
                            ? allocationDetails.assetDesc
                            : "-"}
                        </td>
                      </tr>

                      <tr
                        style={{
                          fontWeight: "bold",
                          margin: "5px",
                          display: "flex",
                          justifyContent: "space-around",
                          color: "mediumblue",
                          fontSize: "medium",
                        }}
                      >
                        Executive Details
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Executive Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.executiveName
                            ? allocationDetails.executiveName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Executive Emp ID
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.employeeId
                            ? allocationDetails.employeeId
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Executive Mobile
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {allocationDetails.executiveMobileNumber
                                ? allocationDetails.executiveMobileNumber
                                : "-"}
                            </span>
                            {allocationDetails.executiveMobileNumber && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul style={{ justifyContent: "left" }}>
                                  {hasAccess(42) &&
                                    userData?.autoFinanceUser?.teleCmiUserId !==
                                      null &&
                                    userData?.autoFinanceUser
                                      ?.teleCmiPassword !== null &&
                                    userData?.autoFinanceUser?.teleCmiUserId !==
                                      "" &&
                                    userData?.autoFinanceUser
                                      ?.teleCmiPassword !== "" &&
                                    isProgressiveDialing === false && (
                                      <li
                                        onClick={() => {
                                          if (
                                            isOnGoingCall === false &&
                                            isIncomingCall === false
                                          ) {
                                            // callingEventsInstance.startCall(
                                            StartCall(
                                              piopiy,
                                              dispatch,
                                              allocationDetails?.executiveMobileNumber,
                                              false,
                                              allocationDetails?.id,
                                              allocationDetails,
                                              "Collections",
                                              isCallActive
                                            );
                                          } else {
                                            openModal();
                                          }
                                        }}
                                      >
                                        <Link
                                          to={"#"}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "30px",
                                            width: "30px",
                                          }}
                                        >
                                          <i
                                            className="fas fa-phone-alt"
                                            style={{ fontSize: "smaller" }}
                                          ></i>
                                        </Link>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: "bold",
                          margin: "5px",
                          display: "flex",
                          justifyContent: "space-around",
                          color: "mediumblue",
                          fontSize: "medium",
                        }}
                      >
                        Manager Details
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Manager Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.managerName
                            ? allocationDetails.managerName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Manager Emp ID
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.managerEmployeeId
                            ? allocationDetails.managerEmployeeId
                            : "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane
              eventKey="paymentDetails"
              key="4"
              mountOnEnter
              unmountOnExit
            >
              {/* <div>
            <BootstrapTable
            keyField="id"
            data={allocationDetails}
            columns={columns}
          />
          </div> */}
              <Col>
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Amount Type</th>
                      <th>Assigned Amount</th>
                      <th>Amount Paid</th>
                      <th>Amount Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>EMI Amount</td>
                      <td>{allocationDetails?.emiAmount?.toFixed(2)}</td>
                      <td>{allocationDetails?.emiAmountPaid?.toFixed(2)}</td>
                      <td>
                        {allocationDetails?.emiAmountToBePaid?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Late Payment Charges</td>
                      <td>
                        {allocationDetails?.latePaymentCharges?.toFixed(2)}
                      </td>
                      <td>
                        {allocationDetails?.latePaymentChargesPaid?.toFixed(2)}
                      </td>
                      <td>
                        {allocationDetails?.latePaymentChargesToBePaid?.toFixed(
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Bounce Charges</td>
                      <td>{allocationDetails?.bounceCharges?.toFixed(2)}</td>
                      <td>
                        {allocationDetails?.bounceChargesPaid?.toFixed(2)}
                      </td>
                      <td>
                        {allocationDetails?.bounceChargesToBePaid?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Penalty Charges</td>
                      <td>
                        {allocationDetails?.totalPenaltyCharges?.toFixed(2)}
                      </td>
                      <td>
                        {allocationDetails?.penaltyChargesPaid?.toFixed(2)}
                      </td>
                      <td>
                        {allocationDetails?.penaltyChargesToBePaid?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Other Charges</td>
                      <td>{allocationDetails?.netOtherAmount ? allocationDetails?.netOtherAmount?.toFixed(2):0}</td>
                      <td>{allocationDetails?.otherChargesPaid?.toFixed(2)}</td>
                      <td>
                        {allocationDetails?.otherChargesToBePaid?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Amount</td>
                      <td>{allocationDetails?.dueAmount?.toFixed(2)}</td>
                      <td>{allocationDetails?.amountPaid?.toFixed(2)}</td>
                      <td>{allocationDetails?.amountToBePaid?.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Tab.Pane>
            <Tab.Pane
              eventKey="additionalNumbers"
              key="5"
              mountOnEnter
              unmountOnExit
            >
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    margin: "5px",
                    overflowY: "auto",
                    maxHeight: "400px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 1
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact1
                                ? additionalNumbers?.contact1
                                : "-"}
                            </span>
                            {additionalNumbers?.contact1 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact1,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 2
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact2
                                ? additionalNumbers?.contact2
                                : "-"}
                            </span>
                            {additionalNumbers?.contact2 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact2,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 3
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact3
                                ? additionalNumbers?.contact3
                                : "-"}
                            </span>
                            {additionalNumbers?.contact3 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact3,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 4
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact4
                                ? additionalNumbers?.contact4
                                : "-"}
                            </span>
                            {additionalNumbers?.contact4 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact4,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 5
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact5
                                ? additionalNumbers?.contact5
                                : "-"}
                            </span>
                            {additionalNumbers?.contact5 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact5,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 6
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact6
                                ? additionalNumbers?.contact6
                                : "-"}
                            </span>
                            {additionalNumbers?.contact6 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact6,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 7
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact7
                                ? additionalNumbers?.contact7
                                : "-"}
                            </span>
                            {additionalNumbers?.contact7 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact7,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 8
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact8
                                ? additionalNumbers?.contact8
                                : "-"}
                            </span>
                            {additionalNumbers?.contact8 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact8,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 9
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact9
                                ? additionalNumbers?.contact9
                                : "-"}
                            </span>
                            {additionalNumbers?.contact9 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact9,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          Contact No 10
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <span style={{ alignSelf: "center" }}>
                              {additionalNumbers?.contact10
                                ? additionalNumbers?.contact10
                                : "-"}
                            </span>
                            {additionalNumbers?.contact10 && (
                              <div
                                className="media-body user-meta-info"
                                style={{ paddingRight: "200px" }}
                              >
                                <ul>
                                  {checkIsTelecaller() === true && (
                                    <li
                                      onClick={() => {
                                        if (
                                          isOnGoingCall === false &&
                                          isIncomingCall === false
                                        ) {
                                          // callingEventsInstance.startCall(
                                          StartCall(
                                            piopiy,
                                            dispatch,
                                            additionalNumbers?.contact10,
                                            false,
                                            allocationDetails?.id,
                                            allocationDetails,
                                            "Collections",
                                            isCallActive
                                          );
                                        } else {
                                          openModal();
                                        }
                                      }}
                                    >
                                      <Link
                                        to={"#"}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-phone-alt"
                                          style={{ fontSize: "smaller" }}
                                        ></i>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="loanAdditionalDetails" key="6" mountOnEnter>
              <Col>
                <div
                  className="card contact-bx item-content"
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    overflowY: "auto",
                    maxHeight: "400px",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <table>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Zone
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.zone
                            ? allocationDetails.zone
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Division
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.division
                            ? allocationDetails.division
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          State
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.state
                            ? allocationDetails.state
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Branch
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.customerBranch
                            ? allocationDetails.customerBranch
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Loan Officer Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.loName
                            ? allocationDetails.loName
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Funder Loan No
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.cpLoanNo
                            ? allocationDetails.cpLoanNo
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Maturity Date
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.maturityDate
                            ? EpochToDate(allocationDetails.maturityDate)
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Center Name
                        </td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {allocationDetails.centerName
                            ? allocationDetails.centerName
                            : "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </Col>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <Modal className="fade" show={editModal} size="md">
        <Modal.Header>
          <Modal.Title>Update Mobile Number</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label
              className="control-label"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Mobile Number
            </label>
            <input
              style={{
                width: "400px",
                // height: "90px",
                borderRadius: "7px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                padding: "10px",
                // marginLeft: "10px",
                marginBottom: "5px",
              }}
              type="text"
              onWheel={(event) => event.currentTarget.blur()}
              // pattern="^[1-9]\d*$"
              placeholder="Enter Mobile Number"
              onChange={(e) => {
                setEnteredMobileNumber(e.target.value.trim());
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                marginTop: "10px",
              }}
            >
              <input
                style={{
                  width: "40px",
                  height: "17px",
                  // marginLeft: "10px",
                  fontWeight: "bold",
                }}
                // className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={isPrimaryMobile}
                onChange={() => {
                  setIsPrimaryMobile(!isPrimaryMobile);
                }}
              />
              <label>Make this as Primary Number</label>
            </div>
            <div style={{ marginTop: "10px" }}>
              <div>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="primary btn-rounded"
                  type="submit"
                  onClick={() => updateMobileNumber()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger btn-rounded light"
                  onClick={() => setEditModal(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Visit Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setIsMap(false);
              setIsLastVisitedAddress2(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap
            latitide={
              isLastVisitedAddress2
                ? allocationDetails.lastVisitedAddress2Lat
                : allocationDetails.lastVisitedAddressLat
            }
            longitude={
              isLastVisitedAddress2
                ? allocationDetails.lastVisitedAddress2Long
                : allocationDetails.lastVisitedAddressLong
            }
            key={random}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoanNumberDetails;
