import React, { useEffect, useState } from "react"
import * as moduleApi from "../rest/moduleApi"
import defaultImage from "../images/defaultImage.png";
import { SRLWrapper } from "simple-react-lightbox";


const LoadImage = ({ source }) => {
    const [imgFound, setImgFound] = useState(false)
    const options1 = {
        settings: {},
        caption: {
          showCaption: false,
        },
        buttons: {
          showAutoplayButton: false,
          showDownloadButton: false,
          showNextButton: false,
          showPrevButton: false,
          showThumbnailsButton: false,
          showFullscreenButton: false,
        },
        thumbnails: {
          showThumbnails: false,
        },
      };
    useEffect(() => {
        if (!source || source === "")
            return;
        moduleApi.getUserImage(source).then((response) => {
            if (response.status === 400) {
                setImgFound(false);
            } else {
                setImgFound(true);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // eslint-disable-next-line jsx-a11y/alt-text
    return <SRLWrapper options={options1}>
    <img className="rounded-lg me-2"
        width="24" src={imgFound ? source : defaultImage}
        style={{ cursor: "pointer", height: '28px', width: '28px', borderRadius: '50px' }} 
        alt=""/></SRLWrapper>
}

export default LoadImage