import React from 'react'

const SupersetDashboard = () => {
  return (
    <div className='card' style={{padding:'5px'}}>
        <iframe style={{margin:'5px'}} src="http://ec2-65-2-151-138.ap-south-1.compute.amazonaws.com:8088/superset/dashboard/34/" title="superset" width="1033" height="450"/>
      </div>
  )
}

export default SupersetDashboard