import React, {  useState, useEffect } from "react";
import { Button} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
// import UploadPayrollExcel from "./UploadPayrollExcel";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import PaginationComponent from "react-reactstrap-pagination";
// import LoadImage from "../CommonComponents/LoadImage";
// import Flatpickr from "react-flatpickr";
// import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import ReactGA from "react-ga";

const InactiveUsers = () => {

// eslint-disable-next-line
    const [isLoading2, setIsLoading2] = useState(false);
    // eslint-disable-next-line
    const [activeUsersList, setActiveUsersList] = useState([]);
    // eslint-disable-next-line
    const [totalElements, setTotalElements] = useState(1);
    const [currPage, setCurrPage] = useState(0);

    useEffect(() => {
      ReactGA.pageview('Inactive Payroll Users');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
          text: "Employee Name",
          dataField: "employeeName",
          width: 130,
          align: "center",
          headerAlign: "center",
          headerStyle: {
            backgroundColor: "rgb(243,240,249)",
            fontWeight: "bold",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          style: {
            fontWeight: "bold",
            borderColor: "white",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          formatter: (col, row) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{ marginLeft: "15px" }}
              >
                {/* <LoadImage source={row.image} />{" "} */}
                <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
              </div>
            );
          },
        },
        {
          text: "Monthly CTC",
          dataField: "monthlyCtc",
          align: "center",
          headerAlign: "center",
          headerStyle: {
            backgroundColor: "rgb(243,240,249)",
            fontWeight: "bold",
    
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          style: {
            borderColor: "white",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
        },
        {
          text: "Additions",
          dataField: "additions",
          align: "center",
          headerAlign: "center",
          headerStyle: {
            backgroundColor: "rgb(243,240,249)",
            fontWeight: "bold",
    
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          style: {
            borderColor: "white",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
        },
        {
          text: "Deductions",
          dataField: "deductions",
          align: "center",
          headerAlign: "center",
          headerStyle: {
            backgroundColor: "rgb(243,240,249)",
            fontWeight: "bold",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          style: {
            borderColor: "white",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
        },
        {
          text: "Reimbursements",
          dataField: "reimbursements",
          align: "center",
          headerAlign: "center",
          headerStyle: {
            backgroundColor: "rgb(243,240,249)",
            fontWeight: "bold",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          style: {
            borderColor: "white",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
        },
        {
          text: "Remarks",
          dataField: "remarks",
          align: "center",
          headerAlign: "center",
          headerStyle: {
            backgroundColor: "rgb(243,240,249)",
            fontWeight: "bold",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          style: {
            borderColor: "white",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
        },
        {
          text: "Gross Pay",
          dataField: "grossPay",
          align: "center",
          headerAlign: "center",
          headerStyle: {
            backgroundColor: "rgb(243,240,249)",
            fontWeight: "bold",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          style: {
            borderColor: "white",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          formatter: (col, row) => {
            if (row.status === "ACTIVE") {
              return (
                <span className="badge badge-rounded badge-success">Active</span>
              );
            } else {
              return (
                <span className="badge badge-rounded badge-danger">Inactive</span>
              );
            }
          },
        },
        {
          text: "",
          dataField: "status",
          align: "center",
          headerAlign: "center",
          headerStyle: {
            backgroundColor: "rgb(243,240,249)",
            fontWeight: "bold",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          style: {
            borderColor: "white",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          },
          formatter: (cell, row) => {
            if (row.status === "ACTIVE4") {
              return <></>;
            } else {
              return (
                <div className="d-flex">
                  {/* <Link to="#" className="btn btn-info shadow btn-xs sharp">
                    <i className="fa fa-eye"></i>
                  </Link> */}
                </div>
              );
            }
          },
    
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
            //   setSelectedExeName(row);
            //   getCustomersList(0, row.userId, "");
            //   setSelectedUserId(row.userId);
            //   setBasicModal2(true);
            },
          },
        },
      ];


      const onPageChange = (selectedPage) => {
        setCurrPage(selectedPage - 1);
      };

  return (
    <div>
         <LoadingOverlay
              active={isLoading2}
              // spinner
              text={
                <p
                  style={{
                    color: "black",
                  }}
                >
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#FFFF",
                        color: "black",
                        borderColor: "white",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      variant="primary"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        variant="primary"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading...
                    </Button>
                  </div>
                </p>
              }
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(192,192,192,0.4)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "80px",
                  "& svg circle": {
                    stroke: "rgb(136, 108, 192)",
                  },
                }),
              }}
            >
              <div
                style={{
                  background: "white",
                  margin: "10px",
                  marginTop: "-5px",
                  overflowX: "auto",
                  overflowY: "auto",
                  maxHeight: "400px",
                }}
              >
                <BootstrapTable
                  keyField="id"
                  data={activeUsersList}
                  columns={columns}
                />
              </div>
              {activeUsersList.length === 0 && (
                <div className="d-flex justify-content-center">
                  <img src={NO_DATA_FOUND} alt="" />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                }}
              >
                <PaginationComponent
                  totalItems={totalElements}
                  pageSize={10}
                  defaultActivePage={currPage + 1}
                  onSelect={onPageChange}
                />
              </div>
            </LoadingOverlay>
    </div>
  )
}

export default InactiveUsers