import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import TextField from "../CommonComponents/TextField";
// import { toaster } from "../rest/apiUtils";
import * as moduleApi from "../rest/moduleApi";
import swal from "sweetalert";
import { apiCall } from "../rest/restApi";
import ReactGA from "react-ga";
import { getTokenInLocalStorage } from "../services/AuthService";

const AddVehicleSaleData = ({ close }) => {
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const phoneRegExp = "[1-9]{1}[0-9]{9}";
  //   var emailPattern =
  //     /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const [vehicleDescList, setVehicleDescList] = useState(null);
  const [selectedVehicleDesc, setSelectedVehicleDesc] = useState(null);
  const [isVehicleDesc, setIsVehicleDesc] = useState(false);

  useEffect(() => {
    ReactGA.pageview("Add Vehicle Sale Data");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    getVehicleDescriptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVehicleDescriptions = () => {
    moduleApi
      .postData(
        apiCall.getVehicleDescriptionsBasedOnOrgId +
          userLoginDetails?.autoFinanceUser?.organizationId
      )
      .then((response) => {
        console.log("response", response);
        const result = response?.map((item, index) => ({
          value: item.description,
          label: item.description,
          index: index,
        }));

        setVehicleDescList(result);
      });
  };

  // var namePattern = "^[a-zA-Z]";
  //   var nameRegex = "[a-zA-Z ]+$";
  // var specialCharacterEmployeeIdPattern = "^[a-zA-Z0-9]+$";
  const validate = Yup.object({
    slNo: Yup.string().trim().required("SL No is required"),
    branch: Yup.string().trim().required("Branch is required"),
    engineNo: Yup.string().trim().required("Engine No is required"),
    chassisNo: Yup.string().trim().required("Chassis No is required"),
    dateOfSale: Yup.string().trim().required("Date Of Sale is required"),
    customerName: Yup.string().trim().required("Customer Name is required"),
    customerPhoneNumber: Yup.string()
      .trim()
      .matches(phoneRegExp, "Customer Mobile is not valid")
      .min(10, "Customer Mobile should be 10 digits")
      .max(10, "Customer Mobile should be 10 digits")
      .required("Customer Mobile is required"),
    cpName: Yup.string().trim().required("CP Name is required"),
    financier: Yup.string().trim().required("Financier is required"),
    vehicleNumber: Yup.string().trim().required("TR/REG No is required"),
  });

  const handleOk = (values) => {
    if (selectedVehicleDesc === null) {
      return setIsVehicleDesc(true);
    }
    const params = {
      branch: values.branch,
      chasisNo: values.chassisNo,
      cpName: values.cpName,
      customerName: values.customerName,
      customerPhoneNumber: values.customerPhoneNumber,
      dateOfSale: Math.floor(new Date(values.dateOfSale).getTime() / 1000.0),
      engineNo: values.engineNo,
      financier: values.financier,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      slNo: values.slNo,
      vehicleDescription: selectedVehicleDesc,
      vehicleNumber: values.vehicleNumber,
    };

    moduleApi.postData(apiCall.addVehicleSaleData, params).then((response) => {
      console.log("saveCpUser", response);
      if (response) {
        if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          close();
          return swal("Added Successfully", {
            icon: "success",
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "500px",
      }}
    >
      <Formik
        initialValues={{
          branch: "",
          chassisNo: "",
          cpName: "",
          customerName: "",
          customerPhoneNumber: "",
          dateOfSale: "",
          engineNo: "",
          financier: "",
          slNo: "",
          vehicleNumber: "",
        }}
        onSubmit={handleOk}
        validationSchema={validate}
        enableReinitialize
      >
        {({ formik, errors }) => (
          <div>
            <Row>
              <Col lg="12">
                <Form>
                  <div>
                    <TextField
                      isAsterisk={true}
                      label="SL No"
                      name="slNo"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Branch"
                      name="branch"
                      type="text"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <label
                        className="control-label"
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          marginBottom: "10px",
                        }}
                      >
                        Vehicle Description
                      </label>
                      <Select
                        placeholder="Select Vehicle Description"
                        classNamePrefix="select-box"
                        options={vehicleDescList}
                        onChange={(value) => {
                          setSelectedVehicleDesc(value.value);
                          setIsVehicleDesc(false);
                        }}
                      />
                    </div>
                    {isVehicleDesc === true ? (
                      <span className="error">
                        Vehicle Description is required
                      </span>
                    ) : (
                      <></>
                    )}

                    <TextField
                      isAsterisk={true}
                      label="Engine No"
                      name="engineNo"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Chassis No"
                      name="chassisNo"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Date Of Sale"
                      name="dateOfSale"
                      type="date"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Customer Name"
                      name="customerName"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Customer Mobile"
                      name="customerPhoneNumber"
                      maxlength="10"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="TR/REG No"
                      name="vehicleNumber"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="CP Name"
                      name="cpName"
                      type="text"
                    />
                    <TextField
                      isAsterisk={true}
                      label="Financier"
                      name="financier"
                      type="text"
                    />
                  </div>
                  <div>
                    <Button
                      style={{ float: "right" }}
                      className="col-lg-2 m-2"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="col-lg-2 m-2 float-right"
                      variant="danger light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddVehicleSaleData;
