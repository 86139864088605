import React from 'react'
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";



const PreviewGraph = ({previewGraphData}) => {
    const ApexBar3 = loadable(() => pMinDelay(import("./Bar3"), 500));
    // let previewGraphData=JSON.parse(localStorage.getItem("previewGraphData"));

console.log('preview', previewGraphData);
  return (
    <div style={{marginTop:'5px'}}> 
    <br></br>
        <h6 style={{ fontWeight: "bold", marginTop:'5px', marginBottom:'5px' }}>Graph Preview</h6>
        <div >
    <ApexBar3
      // id={graph.id}
      // chartDetails={data2}
      // key={graph.id}
      xAxis={previewGraphData?.xaxisValue}
      yAxis={previewGraphData?.yaxisValue}
      chartType={previewGraphData?.chartType}
      isFullData={true}
      isDownload={false}
      height={
        previewGraphData?.chartType !== "pie" &&
        previewGraphData?.chartType !== "donut"
          ? "400"
          : "700"
      }
      width={
        previewGraphData?.chartType !== "pie" &&
        previewGraphData?.chartType !== "donut"
          ? "1000"
          : "600"
      }
    />
    </div>
    </div>
  )
}

export default PreviewGraph