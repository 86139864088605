import React from 'react'
import whatsapp2 from '../assets/whatsapp2.jpeg'
import clulogo from "../../images/circlelogo.png";


export default function Welcome() {
    return (
        <div className="welcome">
            {/* <div > */}
            {/* <img src="/sidebar/whatsapp.png" alt="" /> */}

            <img style={{width:'700px', marginTop:'-25px'}} src={whatsapp2} alt="" />
            {/* </div> */}
            <h1 style={{color:'green'}}>WhatsApp Web</h1>
            <h5 style={{color:'rgb(4 123 245)'}}>Powered By <img style={{width:'50px'}} src={clulogo} alt="" /></h5>

        </div>
    )
}
