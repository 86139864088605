import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
// import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
// import { apiCall } from "../rest/restApi";
// import { isAdmin } from "../services/AuthService";
// import { toaster } from "../rest/apiUtils";
import { EpochToOnlyDate } from "./Helper";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga";

function Excel({ close, getActiveKey }) {
  // on change states
  const [excelFile, setExcelFile] = useState(null);
  const [binaryExcelFileData, setBinaryExcelFileData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [fileName, setFileName] = useState(null);
  // const [executivesList, setExecutivesList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  // const [selectedExecutive, setSelectedExecutive] = useState('');
  const [excelFileError, setExcelFileError] = useState(null);

  // submit
  const [excelData, setExcelData] = useState(null);
  // it will contain array of objects

  var userData = JSON.parse(localStorage.getItem("userDetails"));

  // useEffect(() => {
  //   loadExecutives();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // const phoneRegExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  // handle File
  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  useEffect(() => {
    ReactGA.pageview("Collections Work Upload");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFile = (e) => {
    setBinaryExcelFileData(null);
    let selectedFile = e.target.files[0];
    console.log("UploadedFileSize", selectedFile.size / 1024 ** 2);
    if (selectedFile.size / 1024 ** 2 >= 5) {
      return swal("Please Upload a File Less than 5 MB", {
        icon: "error",
        dangerMode: true,
      });
    }

    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          var bytes = new Uint8Array(reader.result);
          console.log("result", bytes);
          setExcelFile(e.target.result);
          setBinaryExcelFileData(bytes);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("plz select your file");
    }
  };

  const headerTitles = [
    "loanNumber",
    "customerName",
    // "managerName",
    // "managerEmployeeId",
    // "teamLeaderName",
    // "teamLeaderEmployeeId",
    "executiveName",
    "executiveEmployeeId",
    // "typeOfVisit",
    "productName",
    "cpName",
    "loanAmount",
    "principalOutstanding",
    "emiAmount",
    "currentBucket",
    "tenure",
    "emiOutstanding",
    "latePaymentCharges",
    "bounceCharges",
    "totalPenaltyCharges",
    "dueAmount",
    "dueDate",
    "dueDay",
    "residenceAddress",
    "residenceCity",
    "residenceState",
    "residenceZipcode",
    "nearByLandmark",
    "location",
    "contactNumber1",
    "contactNumber2",
    "permanentAddress",
    "permanentCity",
    "permanentState",
    "permanentZipcode",
    "referenceName1",
    "referenceMobile1",
    "referenceName2",
    "referenceMobile2",
    "guarantorName",
    "guarantorMobile",
    "registrationNumber",
    "temporaryRegistrationNumber",
    "engineNumber",
    "chassisNumber",
    "assetDesc",
    "remarks",
  ];

  // const loadExecutives = () => {
  //   let agentBody;
  //   agentBody = {
  //     roleName: "EXECUTIVE",
  //     organizationId: userData.autoFinanceUser.organizationId,
  //     reportOfficerId: isAdmin() ? "" : userData.autoFinanceUser.userId,
  //   };

  //   moduleApi.postData(apiCall.getExecutives, agentBody).then((response) => {
  //     const result = response.content.map((item, index) => ({
  //       value: item.autoFinanceUser.name,
  //       label: item.autoFinanceUser.name,
  //       userId: item.autoFinanceUser.userId,
  //       reportingOfficerId: item.autoFinanceUser.reportingOfficerId,
  //       index: index,
  //     }));

  //     setExecutivesList(result);
  //   });
  // };

  // submit function
  const handleSubmit = (e) => {
    setExcelData(null);
    // setIsLoading(true);
    setLoader(true);
    console.log("excel", binaryExcelFileData);

    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
      const data2 = XLSX.utils.json_to_sheet(data, { skipHeader: true });
      const data3 = XLSX.utils.sheet_to_json(data2, { header: headerTitles });

      console.log("data", data3);
      //       for (let i = 0; i < data3.length; i++) {
      //         // console.log('date'+i, new Date('03-06-22').getTime() );
      // //         var intervalStart="13-11-2014 16:22:00"
      // //         var formattedDays = intervalStart.split(" ")[0].split("-");
      // // var formattedTime = intervalStart.split(" ")[1].split(":");
      // // var epochStart = new Date(formattedDays[2], formattedDays[1] - 1,formattedDays[0],formattedTime[0],formattedTime[1],formattedTime[2],0).getTime()/1000;
      // // console.log('date3', epochStart);
      //         if(data3[i].dueDate!=='')
      //         data3[i].dueDate =EpochToOnlyDate(
      //           new Date(Date.UTC(0, 0, data3[i].dueDate - 1)) / 1000);
      //       }

      for (let i = 0; i < data3.length; i++) {
        if (data3[i].dueDate !== "")
          if (typeof data3[i].dueDate === "string") {
            var formattedDays;
            var intervalStart = data3[i].dueDate + " 23:59:59";
            if (data3[i].dueDate.includes("-")) {
              formattedDays = intervalStart.split(" ")[0].split("-");
            } else if (data3[i].dueDate.includes("/")) {
              formattedDays = intervalStart.split(" ")[0].split("/");
            }
            var formattedTime = intervalStart.split(" ")[1].split(":");
            var epochStart =
              new Date(
                formattedDays[2],
                formattedDays[1] - 1,
                formattedDays[0],
                formattedTime[0],
                formattedTime[1],
                formattedTime[2],
                0
              ).getTime() / 1000;
            data3[i].dueDate = EpochToOnlyDate(epochStart);
          } else {
            data3[i].dueDate = EpochToOnlyDate(
              new Date(Date.UTC(0, 0, data3[i].dueDate - 1)) / 1000
            );
          }
      }
      setExcelData(data3);
    } else {
      setExcelData(null);
    }
    setIsLoading(false);
    setLoader(false);
  };

  const getLineNo = (i) => {
    return (i += 2);
  };

  const uploadDailyScheduleData = () => {
    // console.log("excelData", excelData[1].dueDate);
    // console.log("excelData2", new Date(Date.UTC(0, 0, excelData[1].dueDate - 1))/1000);

    // console.log(EpochToOnlyDate(1660141839));

    // for(let i=0;i<excelData?.length;i++){
    //  console.log('date',excelData[i].dueDate );

    // }
    setIsLoading(true);
    for (let i = 0; i < excelData?.length; i++) {
      if (
        typeof excelData[i].loanAmount === "string" &&
        excelData[i].loanAmount !== ""
      ) {
        setIsLoading(false);
        return swal("Loan Amount is invalid at Line Number " + getLineNo(i), {
          icon: "error",
          dangerMode: true,
        });
      
      } else if (
        typeof excelData[i].principalOutstanding === "string" &&
        excelData[i].principalOutstanding !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Principle Outstanding Amount is invalid at Line Number " +
            getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
       
      } else if (
        typeof excelData[i].emiAmount === "string" &&
        excelData[i].emiAmount !== ""
      ) {
        setIsLoading(false);
        return swal("EMI Amount is invalid at Line Number " + getLineNo(i), {
          icon: "error",
          dangerMode: true,
        });
      
      } else if (
        typeof excelData[i].currentBucket === "string" &&
        excelData[i].currentBucket !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Current Bucket is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
       
      } else if (
        typeof excelData[i].tenure === "string" &&
        excelData[i].tenure !== ""
      ) {
        setIsLoading(false);
        return swal("Tenure Value is invalid at Line Number " + getLineNo(i), {
          icon: "error",
          dangerMode: true,
        });
    
      } else if (
        typeof excelData[i].emiOutstanding === "string" &&
        excelData[i].emiOutstanding !== ""
      ) {
        setIsLoading(false);
        return swal(
          "EMI Outstanding Value is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
     
      } else if (
        typeof excelData[i].latePaymentCharges === "string" &&
        excelData[i].latePaymentCharges !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Late Payment Charges is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );

      } else if (
        typeof excelData[i].bounceCharges === "string" &&
        excelData[i].bounceCharges !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Bounce Charges is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
     
      } else if (
        typeof excelData[i].totalPenaltyCharges === "string" &&
        excelData[i].totalPenaltyCharges !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Total Penalty Charges is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
      
      } else if (
        typeof excelData[i].dueAmount === "string" &&
        excelData[i].dueAmount !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Total Due Amount is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
    
      } else if (
        typeof excelData[i].residenceZipcode === "string" &&
        excelData[i].residenceZipcode !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Residence Zip Code is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
      
      } else if (
        typeof excelData[i].contactNumber1 === "string" &&
        excelData[i].contactNumber1 !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Contact Number 1 is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
       
      }
   
      else if (
        typeof excelData[i].permanentZipcode === "string" &&
        excelData[i].permanentZipcode !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Permanent Zip Code is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );

      } else if (
        typeof excelData[i].dueDay === "string" &&
        excelData[i].dueDay !== ""
      ) {
        setIsLoading(false);
        return swal("Due Day is invalid at Line Number " + getLineNo(i), {
          icon: "error",
          dangerMode: true,
        });

      } else if (
        typeof excelData[i].contactNumber1 === "string" &&
        excelData[i].contactNumber1 !== ""
      ) {
        setIsLoading(false);
        return swal(
          "Contact Number 1 is invalid at Line Number " + getLineNo(i),
          {
            icon: "error",
            dangerMode: true,
          }
        );
   
      }
    }
    // let listData = [];
    // excelData?.forEach((element) => {
    //   let data = {
    //     loanNumber: element.loanNumber.toString().trim(),
    //     customerName: element.customerName.toString().trim(),
    //     managerName: element.managerName.toString().trim(),
    //     managerEmployeeId: element.managerEmployeeId.toString().trim(),
    //     teamLeaderName: element.teamLeaderName.toString().trim(),
    //     teamLeaderEmployeeId: element.teamLeaderEmployeeId.toString().trim(),
    //     executiveName: element.executiveName.toString().trim(),
    //     executiveEmployeeId: element.executiveEmployeeId.toString().trim(),
    //     typeOfVisit: element.typeOfVisit.toString().trim(),
    //     productName: element.productName.toString().trim(),
    //     cpName: element.cpName.toString().trim(),
    //     loanAmount: isNaN(parseFloat(element.loanAmount))
    //       ? null
    //       : parseFloat(element.loanAmount).toFixed(2),
    //     principalOutstanding: isNaN(parseFloat(element.principalOutstanding))
    //       ? null
    //       : parseFloat(element.principalOutstanding).toFixed(2),
    //     emiAmount: isNaN(parseFloat(element.emiAmount))
    //       ? 0
    //       : parseFloat(element.emiAmount).toFixed(2),
    //     currentBucket: isNaN(parseFloat(element.currentBucket))
    //       ? null
    //       : parseFloat(element.currentBucket).toFixed(2),
    //     // currentBucket: element.currentBucket,
    //     tenure: isNaN(parseFloat(element.tenure))
    //       ? 0
    //       : parseFloat(element.tenure),
    //     emiOutstanding: isNaN(parseFloat(element.emiOutstanding))
    //       ? 0
    //       : parseFloat(element.emiOutstanding).toFixed(2),
    //     latePaymentCharges: isNaN(parseFloat(element.latePaymentCharges))
    //       ? 0
    //       : parseFloat(element.latePaymentCharges).toFixed(2),
    //     bounceCharges: isNaN(parseFloat(element.bounceCharges))
    //       ? 0
    //       : parseFloat(element.bounceCharges).toFixed(2),
    //     totalPenaltyCharges: isNaN(parseFloat(element.totalPenaltyCharges))
    //       ? 0
    //       : parseFloat(element.totalPenaltyCharges).toFixed(2),
    //     dueAmount: isNaN(parseFloat(element.dueAmount))
    //       ? 0
    //       : parseFloat(element.dueAmount).toFixed(2),
    //     dueDate: isNaN(new Date(element.dueDate.toString()) / 1000 + 86399)
    //       ? 0
    //       : new Date(element.dueDate.toString()) / 1000 + 86399,
    //     dueDay: isNaN(parseFloat(element.dueDay))
    //       ? 0
    //       : parseFloat(element.dueDay),
    //     residenceAddress: element.residenceAddress.toString().trim(),
    //     residenceCity: element.residenceCity.toString().trim(),
    //     residenceState: element.residenceState.toString().trim(),
    //     residenceZipcode: isNaN(parseInt(element.residenceZipcode))
    //       ? 0
    //       : parseInt(element.residenceZipcode),
    //     nearByLandmark: element.nearByLandmark.toString().trim(),
    //     location: element.location.toString().trim(),
    //     contactNumber1: element.contactNumber1.toString().trim(),
    //     contactNumber2: element.contactNumber2.toString().trim(),
    //     permanentAddress: element.permanentAddress.toString().trim(),
    //     permanentCity: element.permanentCity.toString().trim(),
    //     permanentState: element.permanentState.toString().trim(),
    //     permanentZipcode: isNaN(parseInt(element.permanentZipcode))
    //       ? 0
    //       : parseInt(element.permanentZipcode),
    //     referenceName1: element.referenceName1.toString().trim(),
    //     referenceMobile1: element.referenceMobile1.toString().trim(),
    //     referenceName2: element.referenceName2.toString().trim(),
    //     referenceMobile2: element.referenceMobile2.toString().trim(),
    //     guarantorName: element.guarantorName.toString().trim(),
    //     guarantorMobile: element.guarantorMobile.toString().trim(),
    //     registrationNumber: element.registrationNumber.toString().trim(),
    //     temporaryRegistrationNumber: element.temporaryRegistrationNumber
    //       .toString()
    //       .trim(),
    //     engineNumber: element.engineNumber.toString().trim(),
    //     chassisNumber: element.chassisNumber.toString().trim(),
    //     assetDesc: element.assetDesc.toString().trim(),
    //     remarks: element.remarks.toString().trim(),
    //   };
    //   listData.push(data);
    // });
    // console.log("tempList", listData);

    // for (let i = 0; i < listData?.length; i++) {
    //   if (listData[i].loanNumber === "") {
    //     setIsLoading(false);
    //     return swal("Loan Number is missing at Line Number " + getLineNo(i), {
    //       icon: "error",
    //       dangerMode: true,
    //     });
    //     // return toaster(
    //     //   "error",
    //     //   "Loan Number is missing at Line Number " + getLineNo(i)
    //     // );
    //   } else if (listData[i].customerName === "") {
    //     setIsLoading(false);
    //     return swal("Customer Name is missing at Line Number " + getLineNo(i), {
    //       icon: "error",
    //       dangerMode: true,
    //     });
    //     // return toaster(
    //     //   "error",
    //     //   "Customer Name is missing at Line Number " + getLineNo(i)
    //     // );
    //   } else if (listData[i].managerName === "") {
    //     setIsLoading(false);
    //     return swal("Manager Name is missing at Line Number " + getLineNo(i), {
    //       icon: "error",
    //       dangerMode: true,
    //     });
    //     // return toaster(
    //     //   "error",
    //     //   "Manager Name is missing at Line Number " + getLineNo(i)
    //     // );
    //   } else if (listData[i].managerEmployeeId === "") {
    //     setIsLoading(false);
    //     return swal(
    //       "Manager Emp Id is missing at Line Number " + getLineNo(i),
    //       {
    //         icon: "error",
    //         dangerMode: true,
    //       }
    //     );
    //     // return toaster(
    //     //   "error",
    //     //   "Manager Emp Id is missing at Line Number " + getLineNo(i)
    //     // );
    //   }
    //   // else if (listData[i].executiveName === "") {
    //   //   return toaster(
    //   //     "error",
    //   //     "Executive Name is missing at Line Number " + getLineNo(i)
    //   //   );
    //   // } else if (listData[i].executiveEmployeeId === "") {
    //   //   return toaster(
    //   //     "error",
    //   //     "Executive Emp Id is missing at Line Number " + getLineNo(i)
    //   //   );
    //   // }
    //   else if (listData[i].productName === "") {
    //     setIsLoading(false);
    //     return swal("Product is missing at Line Number " + getLineNo(i), {
    //       icon: "error",
    //       dangerMode: true,
    //     });
    //     // return toaster(
    //     //   "error",
    //     //   "Product is missing at Line Number " + getLineNo(i)
    //     // );
    //   } else if (listData[i].loanAmount === null) {
    //     setIsLoading(false);
    //     return swal("Loan Amount is missing at Line Number " + getLineNo(i), {
    //       icon: "error",
    //       dangerMode: true,
    //     });
    //     // return toaster(
    //     //   "error",
    //     //   "Loan Amount is missing at Line Number " + getLineNo(i)
    //     // );
    //   } else if (listData[i].principalOutstanding === null) {
    //     setIsLoading(false);
    //     return swal(
    //       "Principle Outstanding is missing at Line Number " + getLineNo(i),
    //       {
    //         icon: "error",
    //         dangerMode: true,
    //       }
    //     );
    //     // return toaster(
    //     //   "error",
    //     //   "Principle Outstanding is missing at Line Number " + getLineNo(i)
    //     // );
    //   } else if (listData[i].dueDate === 0) {
    //     setIsLoading(false);
    //     return swal("Due Date is missing at Line Number " + getLineNo(i), {
    //       icon: "error",
    //       dangerMode: true,
    //     });
    //     // return toaster(
    //     //   "error",
    //     //   "Due Date is missing at Line Number " + getLineNo(i)
    //     // );
    //   } else if (listData[i].dueDay > 31) {
    //     setIsLoading(false);
    //     return swal("Due Day is invalid at Line Number " + getLineNo(i), {
    //       icon: "error",
    //       dangerMode: true,
    //     });
    //     // return toaster(
    //     //   "error",
    //     //   "Due Day is invalid at Line Number " + getLineNo(i)
    //     // );
    //   }
    //   // else if(listData[i].emiAmount===null){
    //   //   return toaster('error', 'EMI Amount is missing at Line Number '+getLineNo(i))
    //   // }else if(listData[i].currentBucket===null){
    //   //   return toaster('error', 'Current Bucket is missing at Line Number '+getLineNo(i))
    //   // }
    //   // else if(listData[i].tenure===null){
    //   //   return toaster('error', 'Tenure is missing at Line Number '+getLineNo(i))
    //   // }else if(listData[i].emiOutstanding===null){
    //   //   return toaster('error', 'EMI Outstanding is missing at Line Number '+getLineNo(i))
    //   // }else if(listData[i].latePaymentCharges===null){
    //   //   return toaster('error', 'Late Payment Charges is missing at Line Number '+getLineNo(i))
    //   // }else if(listData[i].bounceCharges===null){
    //   //   return toaster('error', 'Bounce Charges is missing at Line Number '+getLineNo(i))
    //   // }else if(listData[i].totalPenaltyCharges===null){
    //   //   return toaster('error', 'Total Penalty Charges is missing at Line Number '+getLineNo(i))
    //   else if (listData[i].dueAmount === 0) {
    //     return swal(
    //       "Total Due Amount is missing at Line Number " + getLineNo(i),
    //       {
    //         icon: "error",
    //         dangerMode: true,
    //       }
    //     );
    //   }
    //   // else if(listData[i].residenceAddress===''){
    //   //   return toaster('error', 'Residence Address is missing at Line Number '+getLineNo(i))
    //   // }
    //   // else if(listData[i].residenceCity===''){
    //   //   return toaster('error', 'Residence City is missing at Line Number '+getLineNo(i))
    //   // }
    //   // else if(listData[i].residenceState===''){
    //   //   return toaster('error', 'Residence State is missing at Line Number '+getLineNo(i))
    //   // }else if(listData[i].residenceZipcode===null){
    //   //   return toaster('error', 'Residence Zip Code is missing at Line Number '+getLineNo(i))
    //   // }
    //   else if (listData[i].contactNumber1 === "") {
    //     return swal(
    //       "Contact Number1 is missing at Line Number " + getLineNo(i),
    //       {
    //         icon: "error",
    //         dangerMode: true,
    //       }
    //     );
    //     // return toaster(
    //     //   "error",
    //     //   "Contact Number1 is missing at Line Number " + getLineNo(i)
    //     // );
    //   }
    //   // else if(listData[i].permanentAddress===''){
    //   //   return toaster('error', 'Permanent Address is missing at Line Number '+getLineNo(i))
    //   // }
    //   // else if(listData[i].permanentCity===''){
    //   //   return toaster('error', 'Permanent City is missing at Line Number '+getLineNo(i))
    //   // }
    //   // else if(listData[i].permanentState===''){
    //   //   return toaster('error', 'Permanent State is missing at Line Number '+getLineNo(i))
    //   // }else if(listData[i].permanentZipcode===null){
    //   //   return toaster('error', 'Permanent Zip Code is missing at Line Number '+getLineNo(i))
    //   // }
    //   // else if(listData[i].loanAmount<=0){
    //   //   return toaster('error', 'Loan Amount is less than One at Line Number '+getLineNo(i))
    //   // }else if(listData[i].principalOutstanding<=0){
    //   //   return toaster('error', 'Principal Outstanding Amount is less than One at Line Number '+getLineNo(i))
    //   // }
    //   // else if(listData[i].emiAmount<=0){
    //   //   return toaster('error', 'EMI Amount is less than One at Line Number '+getLineNo(i))
    //   // }else if(listData[i].currentBucket<0){
    //   //   return toaster('error', 'Current Bucket is less than Zero at Line Number '+getLineNo(i))
    //   // }else if(listData[i].tenure<=0){
    //   //   return toaster('error', 'Tenure Value is less than One at Line Number '+getLineNo(i))
    //   // }else if(listData[i].emiOutstanding<=0){
    //   //   return toaster('error', 'EMI Outstanding Amount is less than One at Line Number '+getLineNo(i))
    //   // }else if(listData[i].dueAmount<=0){
    //   //   return toaster('error', 'Total Due Value is less than One at Line Number '+getLineNo(i))
    //   // }
    //   else if (
    //     listData[i].contactNumber1.length < 10 ||
    //     listData[i].contactNumber1.length > 10
    //   ) {
    //     return swal(
    //       "Contact Number 1 is less or more than 10 digits at Line Number " +
    //         getLineNo(i),
    //       {
    //         icon: "error",
    //         dangerMode: true,
    //       }
    //     );
    //     // return toaster(
    //     //   "error",
    //     //   "Contact Number 1 is less or more than 10 digits at Line Number " +
    //     //     getLineNo(i)
    //     // );
    //   }
    //   // else if(listData[i].contactNumber2.length<10 || listData[i].contactNumber2.length>10){
    //   //   return toaster('error', 'Contact Number 2 is less or more than 10 digits at Line Number '+getLineNo(i))
    //   // }
    // }
    // for(let i=0;i<listData.length;i++){

    //   for(let j=1;j<listData.length;j++){
    //     if(listData[i].loanNumber===listData[j].loanNumber){
    //      return swal("Given Loan Number "+ listData[i].loanNumber+" is Duplicate", {
    //         icon: "error",
    //       });
    //     }
    //   }
    // }
    // let payload = {
    //   executiveDailyScheduledData: listData,
    //   // userId: selectedExecutive.userId,
    //   assignedByReportingOfficerId: userData.autoFinanceUser.userId,
    //   // reportingOfficerId: userData.autoFinanceUser.userId,
    //   organizationId: userData.autoFinanceUser.organizationId,
    // };
    // console.log("payload", payload);

    // moduleApi
    //   .postData(apiCall.uploadDailyScheduleData, payload)
    //   .then((response) => {
    //     if (response) {
    //       if (response?.Message === "File Uploaded Successfully") {
    //         // toaster("success", "Uploaded Successfully");
    //         setIsLoading(false);
    //         close();
    //         swal("Uploaded Successfully!", {
    //           icon: "success",
    //         });
    //         setIsSubmit(false);
    //       } else if (
    //         response?.status === 605 ||
    //         response?.status === 604 ||
    //         response?.status === 600 ||
    //         response?.status === 400
    //       ) {
    //         setIsLoading(false);
    //         return swal(response.message, {
    //           icon: "error",
    //           dangerMode: true,
    //         });
    //       }
    //     } else {
    //       setIsLoading(false);
    //       return swal("Something Went Wrong, Please Try Again Later", {
    //         icon: "error",
    //         dangerMode: true,
    //       });
    //     }
    //   });
    sendExcelFileToS3();
  };

  const sendExcelFileToS3 = () => {
    setIsLoading(true);
    if (fileName === null) {
      return swal("Please Enter File Name", {
        icon: "error",
        dangerMode: true,
      });
    }
    let url =
      "https://9ylmyoqrai.execute-api.ap-south-1.amazonaws.com/default/bulk_upload?organizationName=" +
      userData.autoFinanceOrganization.organizationName;
    let payload = {
      orgId: userData.autoFinanceUser.organizationId,
      // orgName: userData.autoFinanceOrganization.organizationName,
      action: "preSignedUrl",
      fileName: fileName,
    };
    moduleApi
      .getDataByOrgName(
        url,
        payload.orgId,
        // payload.orgName,
        payload.action,
        payload.fileName
      )
      .then((response) => {
        console.log("preSignedUrl", response);
        if (response) {
          if (response?.message === "Service Unavailable") {
            setIsLoading(false);
            return swal("Something Went Wrong, Please Try again", {
              icon: "error",
              dangerMode: true,
            });
          } else if (
            response?.message === "File name already exist in an organization"
          ) {
            setIsLoading(false);
            return swal("File name already exists, Please Check.", {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.generatedUrl) {
            moduleApi
              .putDataByName(response?.generatedUrl, binaryExcelFileData)
              .then((response) => {
                //Service Unavailable
                console.log("bulk Upload", response);
                setIsLoading(false);
                close();
                getActiveKey();
                swal(
                  "Please Check the Status of the " +
                    payload.fileName +
                    " File in Uploaded Files Tab",
                  {
                    icon: "success",
                  }
                );
              });
          }
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  return (
    <div className="custom-tab-1">
      {/* <Tab.Container defaultActiveKey="upload">
        <Nav as="ul" className="nav-tabs-custom nav-justified">
          <Nav.Item as="li" key="0">
            <Nav.Link eventKey="upload">
              <b> Upload </b>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" key="0">
            <Nav.Link eventKey="summary">
              <b> Summary </b>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="pt-4">
          <Tab.Pane eventKey="upload" key="0">
          
          </Tab.Pane>
          <Tab.Pane eventKey="summary" key="1"></Tab.Pane>
        </Tab.Content>
      </Tab.Container> */}
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div className="container">
          <div className="form">
            <form
              className="form-group"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Row>
                {/* <Col>
                      <div style={{ marginBottom: "5px" }}>
                        <label>
                          <h6>Select Executive</h6>
                        </label>
                        <Select
                          isSearchable={false}
                          onChange={(value) => {
                            setSelectedExecutive(value);
                          }}
                          // value={executivesList[executiveFilterIndex]}
                          options={executivesList}
                        />
                      </div>
                    </Col> */}
                <Col>
                  <label>
                    <h6>Upload Excel file</h6>
                  </label>
                  {/* <br></br> */}
                  <input
                    style={{ padding: "11px" }}
                    type="file"
                    className="form-control"
                    onChange={handleFile}
                    required
                  ></input>
                  {excelFileError && (
                    <div
                      className="text-danger"
                      style={{ marginTop: 5 + "px" }}
                    >
                      {excelFileError}
                    </div>
                  )}
                </Col>
                <Col>
                  <label>
                    <h6>File Name</h6>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="fileName"
                    onChange={(e) => {
                      setFileName(e.target.value);
                    }}
                    style={{ padding: "11px" }}
                  />
                </Col>
                <Col>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      // excelFile !== null && setIsSubmit(true);
                      // close();
                      // getActiveKey();
                      if (excelFile !== null) {
                        // setIsSubmit(true);
                        if (fileName === null) {
                          return swal("Please Enter File Name", {
                            icon: "error",
                            dangerMode: true,
                          });
                        } else {
                          setIsSubmit(true);
                        }
                      }
                    }}
                    style={{ marginTop: 35 + "px" }}
                  >
                    {loader && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        variant="light"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;&nbsp;Preview
                  </button>
                  {isSubmit && (
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                      onClick={() => {
                        uploadDailyScheduleData();
                      }}
                    >
                      Submit
                    </button>
                  )}
                </Col>
              </Row>
            </form>
          </div>

          <br></br>
          <hr></hr>

          {/* view file section */}
          <h5>View Excel file</h5>
          <div className="viewer">
            {excelData === null && <>No file selected</>}
            {excelData !== null && (
              <div className="table-responsive">
                <table className="table">
                  <thead
                    style={{
                      backgroundColor: "rgb(243,240,249)",
                      top: "1px",
                      position: "sticky",
                    }}
                  >
                    <tr style={{ textAlign: "center" }}>
                      <th scope="col">Loan No</th>
                      <th scope="col">Customer Name</th>
                      {/* <th scope="col">Manager Name</th>
                      <th scope="col">Manager Emp ID</th>
                      <th scope="col">Team Lead Name</th>
                      <th scope="col">Team Lead Emp ID</th> */}
                      <th scope="col">Executive Name</th>
                      <th scope="col">Executive Emp ID</th>
                      {/* <th scope="col">Visit Type</th> */}
                      <th scope="col">Product</th>
                      <th scope="col">CP Name</th>
                      <th scope="col">Loan Amount</th>
                      <th scope="col">POS(Principal Outstanding)</th>
                      <th scope="col">EMI Amount</th>
                      <th scope="col">Current Bucket</th>
                      <th scope="col">Tenure(Months)</th>
                      <th scope="col">EMI Outstanding</th>
                      <th scope="col">LPP(Late Payment Charges)</th>
                      <th scope="col">BCC(Bounce Charges)</th>
                      <th scope="col">Total Penality Charges</th>
                      <th scope="col">Total Due</th>
                      <th scope="col">Due Date</th>
                      <th scope="col">Due Day</th>
                      <th scope="col">Residence Address</th>
                      <th scope="col">Residence City</th>
                      <th scope="col">Residence State</th>
                      <th scope="col">Residence Zip Code</th>
                      <th scope="col">Near By Landmark</th>
                      <th scope="col">Location</th>
                      <th scope="col">Contact No 1</th>
                      <th scope="col">Contact No 2</th>
                      <th scope="col">Permanent Address</th>
                      <th scope="col">Permanent City</th>
                      <th scope="col">Permanent State</th>
                      <th scope="col">Permanent Zip Code</th>
                      <th scope="col">Reference 1 Name</th>
                      <th scope="col">Reference 1 Phone</th>
                      <th scope="col">Reference 2 Name</th>
                      <th scope="col">Reference 2 Phone</th>
                      <th scope="col">Guarantor Name</th>
                      <th scope="col">Guarantor Mobile</th>
                      <th scope="col">Registration Number</th>
                      <th scope="col">Temporary Reg No</th>
                      <th scope="col">Engine No</th>
                      <th scope="col">Chassis No</th>
                      <th scope="col">Asset Desc</th>
                      <th scope="col">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.map((individualExcelData) => (
                      <tr
                        style={{ textAlign: "center", fontWeight: "normal" }}
                        key={individualExcelData.executiveEmployeeId}
                      >
                        <td>{individualExcelData.loanNumber}</td>
                        <td>{individualExcelData.customerName}</td>
                        {/* <td>{individualExcelData.managerName}</td>
                        <td>{individualExcelData.managerEmployeeId}</td>
                        <td>{individualExcelData.teamLeaderName}</td>
                        <td>{individualExcelData.teamLeaderEmployeeId}</td> */}
                        <td>{individualExcelData.executiveName}</td>
                        <td>{individualExcelData.executiveEmployeeId}</td>
                        {/* <td>{individualExcelData.typeOfVisit}</td> */}
                        <td>{individualExcelData.productName}</td>
                        <td>{individualExcelData.cpName}</td>
                        <td>{individualExcelData.loanAmount}</td>
                        <td>{individualExcelData.principalOutstanding}</td>
                        <td>{individualExcelData.emiAmount}</td>
                        <td>{individualExcelData.currentBucket}</td>
                        <td>{individualExcelData.tenure}</td>
                        <td>{individualExcelData.emiOutstanding}</td>
                        <td>{individualExcelData.latePaymentCharges}</td>
                        <td>{individualExcelData.bounceCharges}</td>
                        <td>{individualExcelData.totalPenaltyCharges}</td>
                        <td>{individualExcelData.dueAmount}</td>
                        <td>{individualExcelData.dueDate}</td>
                        <td>{individualExcelData.dueDay}</td>
                        <td>{individualExcelData.residenceAddress}</td>
                        <td>{individualExcelData.residenceCity}</td>
                        <td>{individualExcelData.residenceState}</td>
                        <td>{individualExcelData.residenceZipcode}</td>
                        <td>{individualExcelData.nearByLandmark}</td>
                        <td>{individualExcelData.location}</td>
                        <td>{individualExcelData.contactNumber1}</td>
                        <td>{individualExcelData.contactNumber2}</td>
                        <td>{individualExcelData.permanentAddress}</td>
                        <td>{individualExcelData.permanentCity}</td>
                        <td>{individualExcelData.permanentState}</td>
                        <td>{individualExcelData.permanentZipcode}</td>
                        <td>{individualExcelData.referenceName1}</td>
                        <td>{individualExcelData.referenceMobile1}</td>
                        <td>{individualExcelData.referenceName2}</td>
                        <td>{individualExcelData.referenceMobile2}</td>
                        <td>{individualExcelData.guarantorName}</td>
                        <td>{individualExcelData.guarantorMobile}</td>
                        <td>{individualExcelData.registrationNumber}</td>
                        <td>
                          {individualExcelData.temporaryRegistrationNumber}
                        </td>
                        <td>{individualExcelData.engineNumber}</td>
                        <td>{individualExcelData.chassisNumber}</td>
                        <td>{individualExcelData.assetDesc}</td>
                        <td>{individualExcelData.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin:
                          totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                      }}
                    >
                      <PaginationComponent
                        totalItems={totalElements}
                        pageSize={10}
                        defaultActivePage={currPage + 1}
                        onSelect={onPageChange}
                      />
                    </div> */}
              </div>
            )}
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default Excel;
