import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Row, Col, Tab } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import { Link } from "react-router-dom";
import PaginationComponent from "react-reactstrap-pagination";
// import Excel from "../CommonComponents/Excel";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import LoadImage from "../CommonComponents/LoadImage";
import {
  getTokenInLocalStorage,
  hasAccess,
  isAdmin,
} from "../services/AuthService";
import { apiCall } from "../rest/restApi";
import * as helper from "../CommonComponents/Helper";
// import { toaster } from "../rest/apiUtils";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import * as XLSX from "xlsx";
import "./BootstrapTable.css";
import swal from "sweetalert";
import Summary from "./Summary";
import NonAllocatedUsers from "./NonAllocatedUsers";
import { EpochToDate, EpochToOnlyDate } from "../CommonComponents/Helper";
import SearchBar from "../CommonComponents/SearchBar";
import ReactGA from "react-ga";
import UploadedFilesList from "./UploadedFilesList";
import Excel2 from "../CommonComponents/Excel2";
import CollectionVisitDetails from "./CollectionVisitDetails";
// import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import CpUsersAllocations from "./CpUsersAllocations";
import LoanNumberDetails from "../RequestPayments/LoanNumberDetails";
import TelecallerUpload from "../CommonComponents/TelecallerUpload";

const UploadDailySchedule = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [telecallerModal, setTelecallerModal] = useState(false);
  const [modalForCustomerIntensity, setModalForCustomerIntensity] =
    useState(false);
  const [loanNoDetailsModal, setLoanNoDetailsModal] = useState(false);
  const [templateDownloadModal, setTemplateDownloadModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [basicModal2, setBasicModal2] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isTransferAll, setIsTransferAll] = useState(true);
  const [isDeleteAll, setIsDeleteAll] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  // const [defaultKey, setDefaultKey] = useState("allocated");
  // const [isDefaultKey, setIsDefaultKey] = useState(false);
  // const [isLoading3, setIsLoading3] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [totalElements2, setTotalElements2] = useState(1);
  // const [cpNamesModal, setCpNamesModal] = useState(false);
  const [addCpNameModal, setAddCpNameModal] = useState(false);
  const [editCpNameModal, setEditCpNameModal] = useState(false);
  const [cpNameDetails, setCpNameDetails] = useState([]);
  const [cpNamesData, setCpNamesData] = useState([]);
  const [totalElements3, setTotalElements3] = useState(1);
  const [currPage3, setCurrPage3] = useState(0);
  const [cpName, setCpName] = useState(null);
  const [currPage, setCurrPage] = useState(0);
  const [currPage2, setCurrPage2] = useState(0);
  // const [currPage3, setCurrPage3] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  // const [searchKey2, setSearchKey2] = useState("");
  const [executivesList, setExecutivesList] = useState([]);
  const [executivesList2, setExecutivesList2] = useState([]);
  // const [executivesList3, setExecutivesList3] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedIds2, setSelectedIds2] = useState([]);
  const [selectedExecutive, setSelectedExecutive] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [cpNamesList, setCpNamesList] = useState(null);
  const [prioritiesList, setPrioritiesList] = useState(null);
  const [selectedExeName, setSelectedExeName] = useState("");
  const [searchKey1, setSearchKey1] = useState("");
  const [activeKey, setActiveKey] = useState("allocated");
  const [custData, setCustData] = useState(null);
  const [selectedValue, setSelectedValue] = useState("EXECUTIVE");
  const [selectedCpName, setSelectedCpName] = useState("");
  const [selectedCpName2, setSelectedCpName2] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [isDateFilter2, setIsDateFilter2] = useState(false);
  const [branches, setBranches] = useState([]);
  const [visitDetails, setVisitDetails] = useState({});
  const [sltcdCpNameIndexForCust, setSltcdCpNameIndexForCust] = useState(0);

  // const [temp, setTemp] = useState(null);
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );
  const [start2, setStart2] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end2, setEnd2] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );

  // const [selected, setSelected] = useState('');

  let userLoginDetails = getTokenInLocalStorage();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage?.getItem("isDashboardCollectionAllocationCardCalled") ===
    "true"
      ? setActiveKey("summary")
      : setActiveKey("allocated");
    loadData(0, searchKey, start2, end2, selectedValue, selectedCpName);
    const payLoad = {
      searchKey: "",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      pagination: false,
    };

    moduleApi.postData(apiCall.getBranches, payLoad).then((response) => {
      const result = response.map((item) => ({
        value: item.branchName,
        label: item.branchName,
        branchId: item.branchId,
      }));
      setBranches(result);
    });
    getAllCpNames();
    getAllPriorities();
    loadCpNamesData(0);
    // NonAllocatedLoadData(0, searchKey2);
    // loadExecutives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (custData !== null) {
      handleExport();
      setIsLoading(false);
      setIsLoading2(false);
    }
    // eslint-disable-next-line
  }, [custData]);

  // useEffect(() => {
  //   setDefaultKey("uploadedFiles");
  //   console.log("defff");
  //   // eslint-disable-next-line
  // }, [isDefaultKey]);

  const handleClose = () => {
    setBasicModal(false);
  };
  const teleCallerHandleClose = () => {
    setTelecallerModal(false);
  };
  const handleClose1 = () => {
    setModalForCustomerIntensity(false);
  };
  const setcustomerIntensity = (row) => {
    // console.log("basic modal1");
    setModalForCustomerIntensity(true);
    // console.log("row", row);
    setVisitDetails(row);
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    loadData(0, key, start2, end2, selectedValue, selectedCpName);
  };

  const cpNamesearch = (key) => {
    loadCpNamesData(0, key);
  };

  // const searchKeyword2 = (key) => {
  //   setSearchKey2(key);
  //   NonAllocatedLoadData(0, key);
  // };

  const loadExecutives = () => {
    let agentBody;
    agentBody = {
      roleName: "MANAGER_EXECUTIVE",
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportOfficerId: isAdmin() ? "" : userLoginDetails.autoFinanceUser.userId,
    };

    moduleApi.postData(apiCall.usersList, agentBody).then((response) => {
      const result = response.map((item, index) => ({
        value: item.name,
        label: item.name + " - " + getBranch(item.branchId),
        userId: item.userId,
        index: index,
      }));

      // console.log("res", response);
      setExecutivesList2(
        result.filter((x) => x?.userId !== selectedExeName?.userId)
      );
    });
  };

  const getBranch = (branchId) => {
    for (let i = 0; i < branches?.length; i++) {
      if (branches[i].branchId === branchId) {
        return branches[i].value;
      }
    }
  };

  const loadData = (pageNo, key, start, end, selectedValue, cpName) => {
    let listData = [];
    setIsLoading(true);
    let allocatedUsersBody;
    allocatedUsersBody = {
      endDate: end,
      roleName: selectedValue,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportOfficerId: !isAdmin()
        ? userLoginDetails.autoFinanceUser.userId
        : "",
      workAllocated: "Allocated",
      searchKey: key,
      startDate: start,
      cpName: cpName,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getAllocatedExecutives, pageNo, 10),
        allocatedUsersBody
      )
      .then((response) => {
        let userIds = [];
        if (response) {
          if (response?.message) {
            setIsLoading(false);
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
          response.content.forEach((element) => {
            userIds.push(element.autoFinanceUser.userId);
          });
          let payload = {
            organizationId: userLoginDetails.autoFinanceUser.organizationId,
            userIds: userIds,
          };
          let m = new Map();
          moduleApi
            .postData(apiCall.getLiveLocations, payload)
            .then((response2) => {
              response2?.forEach((item) => {
                m.set(item?.userId, item);
              });

              response.content.forEach((element) => {
                let data = {
                  branchId: element.autoFinanceBranchDetails.branchId,
                  branchLocation:
                    element.autoFinanceBranchDetails.branchLocation,
                  branchName: element.autoFinanceBranchDetails.branchName,
                  branchemail: element.autoFinanceBranchDetails.branchemail,
                  organizationId:
                    element.autoFinanceBranchDetails.organizationId,
                  phoneNumber: element.autoFinanceBranchDetails.phoneNumber,
                  addedByUserId: element.autoFinanceUser.addedByUserId,
                  reportingOfficerIds:
                    element.autoFinanceUser.reportingOfficerIds,
                  email: element.autoFinanceUser.email,
                  employeeId: element.autoFinanceUser.employeeId,
                  image: element.autoFinanceUser.image,
                  mobileNumber: element.autoFinanceUser.mobileNumber,
                  name: element.autoFinanceUser.name,
                  passwordUpdated: element.autoFinanceUser.passwordUpdated,
                  reportingOfficerId:
                    element.autoFinanceUser.reportingOfficerId,
                  roleName: element.autoFinanceUser.roleName,
                  status: element.autoFinanceUser.status,
                  userId: element.autoFinanceUser.userId,
                  designation: element.autoFinanceUser.companyDesignation,

                  batteryPercentage: m.has(element.autoFinanceUser.userId)
                    ? m.get(element.autoFinanceUser.userId).batteryPercentage
                    : 0,
                  gpsData: m.has(element.autoFinanceUser.userId)
                    ? m.get(element.autoFinanceUser.userId).gpsData
                    : null,
                  lastUpdatedAt: m.has(element.autoFinanceUser.userId)
                    ? m.get(element.autoFinanceUser.userId).lastUpdatedAt
                    : 0,
                  totalVisitsDoneThisMonth: element?.totalVisitsDoneThisMonth
                    ? element?.totalVisitsDoneThisMonth
                    : 0,
                  totalVisitsDoneToday: element?.totalVisitsDoneToday
                    ? element?.totalVisitsDoneToday
                    : 0,
                };
                listData.push(data);
              });
              setExecutivesList(listData);
              setTotalElements(response.totalElements);
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const columns = [
    {
      text: "Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <LoadImage source={row.image} />{" "}
            <p style={{ marginBottom: "0px", textAlign: "left" }}>{row.name}</p>
          </div>
        );
      },
    },
    {
      text: "Emp ID",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Email",
      dataField: "email",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile No",

      dataField: "mobileNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",

      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "FTD",

      dataField: "totalVisitsDoneToday",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "MTD",

      dataField: "totalVisitsDoneThisMonth",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "mobileNumber2",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "ACTIVE") {
          return (
            <span className="badge badge-rounded badge-success">Active</span>
          );
        } else {
          return (
            <span className="badge badge-rounded badge-danger">Inactive</span>
          );
        }
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedExeName(row);
          setSelectedIds([]);
          setStart(start2);
          setEnd(end2);
          getCustomersList(
            0,
            start2,
            end2,
            row.userId,
            "",
            selectedCpName,
            selectedPriority
          );
          setSelectedCpName2(selectedCpName);
          getSelectedCpName(selectedCpName);
          setSelectedUserId(row.userId);
          setBasicModal2(true);
        },
      },
    },
  ];

  const onPageChange3 = (selectedPage) => {
    loadCpNamesData(selectedPage - 1);
  };

  const cpNamesColumns = [
    {
      text: "CP Name",
      dataField: "cpName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created By Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Created By Id",
      dataField: "empId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Created Date & Time",
      dataField: "createdTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          "-apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (hasAccess(85)) {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-edit"></i>
              </Link>
            </div>
          );
        } else {
          return <></>;
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setCpName(row.cpName);
          setCpNameDetails(row);
          setEditCpNameModal(true);
        },
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (hasAccess(85)) {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                <i className="fas fa-trash" />
              </Link>
            </div>
          );
        } else {
          return <></>;
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          swal({
            title: "Delete",
            text: "Are you sure, you want to Delete the CP Name?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              var payLoad = {
                organizationId: userLoginDetails.autoFinanceUser.organizationId,
                id: row.id,
              };
              moduleApi
                .postData(apiCall.deleteCpName, payLoad)
                .then((response) => {
                  if (response?.Message === "CP Name Deleted successfully") {
                    loadCpNamesData(0);
                    swal("CP Name Deleted successfully", {
                      icon: "success",
                    });
                  } else if (response?.Message) {
                    swal(response.Message, {
                      icon: "error",
                      dangerMode: true,
                    });
                  }
                });
            }
          });
        },
      },
    },
  ];

  const getSelectedCpName = (value) => {
    cpNamesList.forEach((item, index) => {
      if (item.value === value) {
        setSltcdCpNameIndexForCust(index);
      }
    });
  };

  const handleLoadData = () => {
    loadData(0, searchKey, start2, end2, selectedValue, selectedCpName);
    setActiveKey("uploadedFiles");
  };
  const handleLoadData2 = () => {
    loadData(0, searchKey, start2, end2, selectedValue, selectedCpName);
    // setActiveKey("uploadedFiles");
  };

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadData(
      selectedPage - 1,
      searchKey,
      start2,
      end2,
      selectedValue,
      selectedCpName
    );
  };
  const onPageChange2 = (selectedPage) => {
    setCurrPage2(selectedPage - 1);
    getCustomersList(
      selectedPage - 1,
      start,
      end,
      selectedUserId,
      searchKey1,
      selectedCpName2,
      selectedPriority
    );
  };

  const setCustomDatesForGraph2 = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0].getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0].getFullYear(),
            value[0].getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86399);

    setStart2(start);
    setEnd2(end);
    setStart(start);
    setEnd(end);
    loadData(0, searchKey, start, end, selectedValue, selectedCpName);
  };

  const transferCustomer = () => {
    if (selectedIds.length === 0 && isTransferAll === false) {
      return swal("Please select atleast 1 customer", {
        icon: "warning",
        dangerMode: true,
      });
    }

    if (selectedExecutive === "") {
      return swal("Please Select Executive", {
        icon: "error",
        dangerMode: true,
      });
    }
    let payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      transferAll: isTransferAll,
      transferByUserId: userLoginDetails.autoFinanceUser.userId,
      transferFromExecutiveId: selectedUserId,
      transferToExecutiveId: selectedExecutive.userId,
      transferToReportingOfficerId: !isAdmin()
        ? userLoginDetails.autoFinanceUser.userId
        : undefined,
      roleName: userLoginDetails.autoFinanceUser.roleName,
      visitIds: !isTransferAll ? selectedIds : [],
      cpName: isTransferAll ? selectedCpName2 : undefined,
    };
    // console.log("payload", payload);

    moduleApi.postData(apiCall.transferCustomers, payload).then((response) => {
      if (response) {
        if (response?.Message === "Transfered Successfully") {
          setTransferModal(false);
          getCustomersList(
            0,
            start,
            end,
            selectedUserId,
            "",
            selectedCpName2,
            selectedPriority
          );
          setSelectedIds([]);
          swal("Transferred Successfully", {
            icon: "success",
          });
          ReactGA.event({
            category: "Allocations Transfer",
            action:
              userLoginDetails.autoFinanceOrganization.orgshortcode +
              "__Allocations_Transferred_" +
              helper.EpochToDate(Math.floor(new Date() / 1000)) +
              ".xlsx",
          });
        } else if (response?.status !== 200) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const addCpName = () => {
    setIsLoading(true);

    if (cpName === null) {
      setIsLoading(false);
      return swal("Please Enter CP Name", {
        icon: "error",
        dangerMode: true,
      });
    } else {
      if (cpName.trim().length === 0) {
        setIsLoading(false);
        return swal("Please enter valid CP Name", {
          icon: "error",
          dangerMode: true,
        });
      }
    }

    if (cpName.length > 50) {
      setIsLoading(false);
      return swal("CP Name Should be of max 50 characters", {
        icon: "error",
        dangerMode: true,
      });
    }

    var payload = {
      addedByUserId: userLoginDetails?.autoFinanceUser?.userId,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      cpName: cpName,
    };
    moduleApi.postData(apiCall.addCpName, payload).then((response) => {
      if (response) {
        if (response?.message) {
          setIsLoading(false);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setIsLoading(false);
          setAddCpNameModal(false);
          loadCpNamesData(currPage3);
          return swal("CP Name Added Successfully", {
            icon: "success",
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const editCpName = () => {
    setIsLoading(true);

    if (cpName === null) {
      setIsLoading(false);
      return swal("Please Enter CP Name", {
        icon: "error",
        dangerMode: true,
      });
    } else {
      if (cpName.trim().length === 0) {
        setIsLoading(false);
        return swal("Please enter valid CP Name", {
          icon: "error",
          dangerMode: true,
        });
      }
    }

    if (cpName.length > 50) {
      setIsLoading(false);
      return swal("CP Name Should be of max 50 characters", {
        icon: "error",
        dangerMode: true,
      });
    }

    var payload = {
      id: cpNameDetails.id,
      addedByUserId: userLoginDetails?.autoFinanceUser?.userId,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
      cpName: cpName,
    };
    moduleApi.postData(apiCall.updateCpName, payload).then((response) => {
      if (response) {
        if (response?.message) {
          setIsLoading(false);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else if (response) {
          setIsLoading(false);
          setEditCpNameModal(false);
          loadCpNamesData(currPage3);
          return swal("CP Name Updated Successfully", {
            icon: "success",
          });
        }
      } else {
        setIsLoading(false);
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const loadCpNamesData = (pageNo, key) => {
    setIsLoading(true);

    var body = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      searchKey: key,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getCpnames, pageNo, 10), body)
      .then((response) => {
        setCurrPage3(pageNo);
        var listData = [];
        response?.content?.forEach((element) => {
          let data = {
            id: element.id ? element.id : "-",
            empName: element.empName ? element.empName : "-",
            empId: element.empId ? element.empId : "-",
            cpName: element.cpName ? element.cpName : "-",
            createdTime: element.createdTime
              ? EpochToDate(element.createdTime)
              : "-",
            addedByUserId: element.addedByUserId ? element.addedByUserId : "-",
          };
          listData.push(data);
        });
        setCpNamesData(listData);
        setIsLoading(false);

        setTotalElements3(response?.totalElements);
      });
  };

  const handleOnSelect = (row, isSelect) => {
    // console.log("---------------- single selection");
    if (isSelect) {
      // // console.log("one", row, "rr", row.id);
      setSelectedIds([...selectedIds, row.id]);
      // console.log("one", row, "rr", row.id);
      // transferCustomer()
      // return rows.filter(r => r.id >= 3).map(r => r.id);
    } else {
      setSelectedIds("");
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      setSelectedIds(ids);
      // transferCustomer()
      // console.log("three", rows, "rr", ids);
    } else {
      setSelectedIds("");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    headerColumnStyle: {
      backgroundColor: "rgb(243,240,249)",
    },

    // selected: selected,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const AllocatedHandleOnSelect = (row, isSelect) => {
    // console.log("---------------- single selection");
    if (isSelect) {
      // console.log("one", row, "rr", row.id);
      setSelectedIds2([...selectedIds, row.userId]);
      // console.log("one", row, "rr", row.userId);
      // transferCustomer()
      // return rows.filter(r => r.id >= 3).map(r => r.id);
    } else {
      setSelectedIds2([]);
    }
  };

  const AllocatedHandleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.userId);
    if (isSelect) {
      setSelectedIds2(ids);
      // console.log("three", rows, "rr", ids);
    } else {
      setSelectedIds2([]);
    }
  };

  const AllocatedSelectRow = {
    mode: "checkbox",
    clickToSelect: true,
    headerColumnStyle: {
      backgroundColor: "rgb(243,240,249)",
    },

    // selected: selected,
    onSelect: AllocatedHandleOnSelect,
    onSelectAll: AllocatedHandleOnSelectAll,
  };

  const deleteAllocations = () => {
    if (selectedIds2.length === 0 && isDeleteAll === false) {
      return swal("Please select atleast 1 Allocated User", {
        icon: "warning",
        dangerMode: true,
      });
    }
    var payload = {
      deleteAll: isDeleteAll,
      // deletecustomerIds: selectedIds,
      endDate: end2,
      startDate: start2,
      deletedByUserId: userLoginDetails.autoFinanceUser.userId,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      userIds: selectedIds2,
    };

    // console.log("log", payload);

    moduleApi.postData(apiCall.deleteCustomers, payload).then((response) => {
      if (response) {
        if (response?.Message === "Deleted Successfully") {
          swal("Open Status Allocations are Deleted successfully!", {
            icon: "success",
          });
          setSelectedIds2([]);
          setDeleteModal(false);
          loadData(0, searchKey, start2, end2, selectedValue, selectedCpName);
        } else {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        return swal("Something Went Wrong, Please Try Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  // const expandRow = {
  //   className: "expanding-row",
  //   // parentClassName: "expanding-parent-row",
  //   parentClassName: "bar",
  //   renderer: (row) => (
  //     <div
  //       style={{
  //         overflowX: "auto",
  //       }}
  //     >
  //       <Row>
  //         <Col>
  //           <div
  //             className="card contact-bx item-content"
  //             style={{
  //               backgroundColor: "rgb(243,240,249)",
  //               height: "200px",
  //               overflowY: "auto",
  //               // width:'600px'
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginRight: "20px",
  //                 marginBottom: "10px",
  //                 marginTop: "30px",
  //                 marginLeft: "20px",
  //               }}
  //             >
  //               <>
  //                 <tr></tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Loan Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.loanNumber ? row.loanNumber : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Customer Name
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.customerName ? row.customerName : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Vehicle Type
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.vehicleType ? row.vehicleType : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Customer Intensity
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.customerIntensinty ? row.customerIntensinty : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Product
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.productName ? row.productName : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     CP Name
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.cpName ? row.cpName : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Lender ID
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.lenderId ? row.lenderId : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Lender Name
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.lenderName ? row.lenderName : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Total Due Amount
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.dueAmount ? row.dueAmount : "-"}
  //                   </td>
  //                 </tr>
  //               </>

  //               <tr>
  //                 <td style={{ fontWeight: "500", textAlign: "left" }}>
  //                   Current Due Amount
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.amountToBePaid ? row.amountToBePaid : "-"}
  //                 </td>
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Amount Paid
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.amountPaid ? row.amountPaid : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence Address Line 1
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.address ? row.address : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence Address Line 2
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.addressLine2 ? row.addressLine2 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence City
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.residenceCity ? row.residenceCity : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence State
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.residenceState ? row.residenceState : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Residence ZipCode
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.residenceZipcode ? row.residenceZipcode : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Near By Landmark
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.nearByLandmark ? row.nearByLandmark : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Contact No 1
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.contactNumber ? row.contactNumber : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Remarks
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.remarks ? row.remarks : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Due Date
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.dueDate ? row.dueDate : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Assigned Date
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.dateTime ? row.dateTime : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Opening Date
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.openingDate ? row.openingDate : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Closing Date
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.closingDate ? row.closingDate : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Assigned Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.assignedName ? row.assignedName : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Assigned Mobile Number
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.assignedMobileNumber ? row.assignedMobileNumber : "-"}
  //                 </td>
  //               </tr>
  //             </div>
  //           </div>
  //         </Col>

  //         <Col>
  //           <div
  //             className="card contact-bx item-content"
  //             style={{
  //               backgroundColor: "rgb(243,240,249)",
  //               height: "200px",
  //               overflowY: "auto",
  //               // width:'600px'
  //             }}
  //           >
  //             <div
  //             // style={{
  //             //   marginRight: "20px",
  //             //   marginBottom: "10px",
  //             //   marginTop: "30px",
  //             //   marginLeft: "20px",
  //             // }}
  //             >
  //               <>
  //                 <tr
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "right",
  //                   }}
  //                 >
  //                   Loan Details
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Loan Amount
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.loanAmount ? row.loanAmount : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     POS (Principal Outstanding)
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.principalOutstanding
  //                       ? row.principalOutstanding
  //                       : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     EMI Amount
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.emiAmount ? row.emiAmount : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Current EMI Month
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.current_EMI_Month ? row.current_EMI_Month : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Current Bucket
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.currentBucket ? row.currentBucket : "-"}
  //                   </td>
  //                 </tr>
  //               </>

  //               <tr>
  //                 <td style={{ fontWeight: "500", textAlign: "left" }}>
  //                   Tenure (Months)
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.tenure ? row.tenure : "-"}
  //                 </td>
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   EMI OS
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.emiOutstanding ? row.emiOutstanding : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   LPP (Late Payment Charges)
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.latePaymentCharges ? row.latePaymentCharges : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   BCC (Bounce Charges)
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.bounceCharges ? row.bounceCharges : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Total Penalty Charges
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.totalPenaltyCharges ? row.totalPenaltyCharges : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Net GDI Amount
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.net_GDI_Amount ? row.net_GDI_Amount : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Net HL Amount
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.net_HL_Amount ? row.net_HL_Amount : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Net Other Amount
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.netOtherAmount ? row.netOtherAmount : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   AG Date
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.ag_Date ? row.ag_Date : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Due Day
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.dueDay ? row.dueDay : "-"}
  //                 </td>
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Third Party Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.thirdPartyName ? row.thirdPartyName : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Third Party Mobile
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.thirdPartyPhoneNumber
  //                     ? row.thirdPartyPhoneNumber
  //                     : "-"}
  //                 </td>
  //               </tr>
  //             </div>
  //           </div>
  //         </Col>

  //         <Col>
  //           <div
  //             className="card contact-bx item-content"
  //             style={{
  //               backgroundColor: "rgb(243,240,249)",
  //               height: "200px",
  //               overflowY: "auto",
  //               // width:'600px'
  //             }}
  //           >
  //             <div
  //             // style={{
  //             //   marginRight: "20px",
  //             //   marginBottom: "10px",
  //             //   marginTop: "30px",
  //             //   marginLeft: "20px",
  //             // }}
  //             >
  //               <>
  //                 <tr
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "right",
  //                   }}
  //                 >
  //                   Customer Details
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Location
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.location ? row.location : "-"}
  //                   </td>
  //                 </tr>

  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Contact No 2
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.contactNumber2 ? row.contactNumber2 : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Borrower Address (Home)
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.borrowerAddress ? row.borrowerAddress : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Promoter Address (Office)
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.lenderAddress ? row.lenderAddress : "-"}
  //                   </td>
  //                 </tr>

  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Permanent Address
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.permanentAddress ? row.permanentAddress : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Permanent City
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.permanentCity ? row.permanentCity : "-"}
  //                   </td>
  //                 </tr>
  //               </>

  //               <tr>
  //                 <td style={{ fontWeight: "500", textAlign: "left" }}>
  //                   Permanent State
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.permanentState ? row.permanentState : "-"}
  //                 </td>
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Permanent ZipCode
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.permanentZipcode ? row.permanentZipcode : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Ref1 Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.referenceName1 ? row.referenceName1 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Ref1 Mobile
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.referenceMobile1 ? row.referenceMobile1 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Ref2 Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.referenceName2 ? row.referenceName2 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Ref2 Mobile
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.referenceMobile2 ? row.referenceMobile2 : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Guarantor Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.guarantorName ? row.guarantorName : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Guarantor Mobile
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.guarantorMobile ? row.guarantorMobile : "-"}
  //                 </td>
  //               </tr>
  //             </div>
  //           </div>
  //         </Col>

  //         <Col>
  //           <div
  //             className="card contact-bx item-content"
  //             style={{
  //               backgroundColor: "rgb(243,240,249)",
  //               height: "200px",
  //               overflowY: "auto",
  //               // width:'600px'
  //             }}
  //           >
  //             <div
  //             // style={{
  //             //   marginRight: "20px",
  //             //   marginBottom: "10px",
  //             //   marginTop: "30px",
  //             //   marginLeft: "20px",
  //             // }}
  //             >
  //               <>
  //                 <tr
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "right",
  //                   }}
  //                 >
  //                   Product Details
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                       width: "30%",
  //                     }}
  //                   >
  //                     Registration Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.registrationNumber ? row.registrationNumber : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Temporary Registration Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.temporaryRegistrationNumber
  //                       ? row.temporaryRegistrationNumber
  //                       : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Engine Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.engineNumber ? row.engineNumber : "-"}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       fontWeight: "500",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     Chasis Number
  //                   </td>
  //                   <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                   <td
  //                     style={{
  //                       fontWeight: "bold",
  //                       textAlign: "left",
  //                     }}
  //                   >
  //                     {row.chassisNumber ? row.chassisNumber : "-"}
  //                   </td>
  //                 </tr>
  //               </>

  //               <tr>
  //                 <td style={{ fontWeight: "500", textAlign: "left" }}>
  //                   Asset Desc
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.assetDesc ? row.assetDesc : "-"}
  //                 </td>
  //               </tr>
  //               <tr
  //                 style={{
  //                   fontWeight: "bold",
  //                   textAlign: "right",
  //                 }}
  //               >
  //                 Manager Details
  //               </tr>

  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Manager Name
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.managerName ? row.managerName : "-"}
  //                 </td>
  //               </tr>
  //               <tr>
  //                 <td
  //                   style={{
  //                     fontWeight: "500",
  //                     textAlign: "left",
  //                     width: "30%",
  //                   }}
  //                 >
  //                   Manager Emp ID
  //                 </td>
  //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
  //                 <td
  //                   style={{
  //                     fontWeight: "bold",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {row.managerEmployeeId ? row.managerEmployeeId : "-"}
  //                 </td>
  //               </tr>
  //             </div>
  //           </div>
  //         </Col>

  //         {/* <Col>

  //         <div
  //           className="card contact-bx item-content"
  //           style={{
  //             backgroundColor: "rgb(243,240,249)",
  //           }}
  //         >
  //           <h6 className="fs-18 font-w600 my-1">Status</h6>

  //           <div style={{ margin: "5%", marginTop: "2%" }}>
  //             <>
  //               <Table responsive="sm">
  //                 <tbody>
  //                 <tr>
  //                     <td style={{ borderColor: "grey", textAlign: "left" }}>
  //                       {" "}
  //                       Paid Count{" "}
  //                     </td>
  //                     <td style={{ borderColor: "grey" }}>
  //                       <div
  //                         style={{
  //                           borderColor: "grey",
  //                           backgroundColor: "#44d144",
  //                           borderRadius: "10px",
  //                           width: "40px",
  //                           height: "26px",
  //                           padding: "4px",
  //                           marginLeft: "auto",
  //                           color: "white",
  //                         }}
  //                       >
  //                         <b>
  //                           {" "}
  //                           {collectStatusCount?.paidCount
  //                             ? collectStatusCount?.paidCount
  //                             : "0"}
  //                         </b>
  //                       </div>
  //                     </td>
  //                   </tr>

  //                   <tr>
  //                     <td style={{ borderColor: "grey", textAlign: "left" }}>
  //                       {" "}
  //                       Partially Paid Count{" "}
  //                     </td>
  //                     <td style={{ borderColor: "grey" }}>
  //                       <div
  //                         style={{
  //                           borderColor: "grey",
  //                           backgroundColor: "orange",
  //                           borderRadius: "10px",
  //                           width: "40px",
  //                           height: "26px",
  //                           padding: "4px",
  //                           marginLeft: "auto",
  //                           color: "white",
  //                         }}
  //                       >
  //                         <b>
  //                           {collectStatusCount?.partiallyPaidCount
  //                             ? collectStatusCount?.partiallyPaidCount
  //                             : "0"}
  //                         </b>
  //                       </div>
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <td style={{ borderColor: "grey", textAlign: "left" }}>
  //                       {" "}
  //                       PTP Count{" "}
  //                     </td>
  //                     <td style={{ borderColor: "grey" }}>
  //                       <div
  //                         style={{
  //                           borderColor: "grey",
  //                           backgroundColor: "#0f7ce7",
  //                           borderRadius: "10px",
  //                           width: "40px",
  //                           height: "26px",
  //                           padding: "4px",
  //                           marginLeft: "auto",
  //                           color: "white",
  //                         }}
  //                       >
  //                         <b>
  //                           {collectStatusCount?.ptpCount
  //                             ? collectStatusCount?.ptpCount
  //                             : "0"}
  //                         </b>
  //                       </div>
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <td style={{ borderColor: "grey", textAlign: "left" }}>
  //                       {" "}
  //                       Customer Not Available Count{" "}
  //                     </td>
  //                     <td style={{ borderColor: "grey" }}>
  //                       <div
  //                         style={{
  //                           borderColor: "grey",
  //                           backgroundColor: "red",
  //                           borderRadius: "10px",
  //                           width: "40px",
  //                           height: "26px",
  //                           padding: "4px",
  //                           marginLeft: "auto",
  //                           color: "white",
  //                         }}
  //                       >
  //                         {" "}
  //                         <b>
  //                           {collectStatusCount?.customerNotAvailbleCount
  //                             ? collectStatusCount?.customerNotAvailbleCount
  //                             : "0"}
  //                         </b>
  //                       </div>
  //                     </td>
  //                   </tr>
  //                 </tbody>
  //               </Table>
  //             </>
  //           </div>
  //         </div>

  //     </Col> */}
  //       </Row>
  //     </div>
  //   ),
  //   //     onExpand: (row, isExpand, rowIndex, e) => {
  //   // setTemp('rakesh')
  //   //     },
  //   showExpandColumn: true,
  //   // onlyOneExpanding: true,
  //   expandByColumnOnly: true,
  // };

  const getCustomersList = (
    pageNo,
    start,
    end,
    userId,
    key,
    cpName,
    priority
  ) => {
    let listData2 = [];
    setIsLoading2(true);
    let payload = {
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      startDate: start,
      userId: userId,
      reportingOfficerId: userLoginDetails.autoFinanceUser.userId,
      visitStatus: "",
      cpName: cpName,
      priority: priority,
      searchKey: key,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCustomersList, pageNo, 10),
        payload
      )
      .then((response) => {
        setCurrPage2(pageNo);
        if (response) {
          if (response?.message) {
            return swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
          // console.log("response", response);
          response?.content?.forEach((element) => {
            let data = {
              addressLine2: element.addressLine2,
              ag_Date: element.ag_Date,
              aggDate: element.aggDate ? element.aggDate : 0,
              amountPaid: element.amountPaid,
              amountPaidNow: element.amountPaidNow,
              amountToBePaid: element.amountToBePaid,
              assetDesc: element.assetDesc,
              assignedMobileNumber: element.assignedMobileNumber,
              assignedName: element.assignedName,
              bounceCharges: element.bounceCharges,
              branchId: element.branchId,
              chassisNumber: element.chassisNumber,
              comments: element.comments,
              contactNumber: element.contactNumber1,
              zone: element.zone,
              division: element.division,
              state: element.state,
              unit: element.unit,
              customerBranch: element.customerBranch,
              groupName: element.groupName,
              loName: element.loName,
              spouseName: element.spouseName,
              cpLoanNo: element.cpLoanNo,
              centerName: element.centerName,
              lastCollectedAmount: element.lastCollectedAmount,
              dpdDays: element.dpdDays,
              maturityDate: element.maturityDate,
              lastVisitDate: element.lastVisitDate,
              lastPtpDate: element.lastPtpDate,
              residenceLatitude: element.residenceLatitude,
              residenceLongitude: element.residenceLongitude,
              contactNumber2: element.contactNumber2,
              cpName: element.cpName,
              closingDate: element.closingDate
                ? EpochToOnlyDate(element.closingDate)
                : "-",
              openingDate: element.openingDate
                ? EpochToOnlyDate(element.openingDate)
                : "-",
              installmentDate: element.installmentDate
                ? EpochToDate(element.installmentDate)
                : "-",
              currentBucket: element.currentBucketName,
              customerIntensinty: element.customerIntensinty,
              lenderId: element.lenderId,
              lenderName: element.lenderName,
              lenderAddress: element.promoterAddress,
              locLink1: element.locLink1,
              locLink2: element.locLink2,
              borrowerAddress: element.borrowerAddress,
              current_EMI_Month: element.currentEmiMonthNumber,
              customerName: element.customerName,
              priority: element.priority ? element.priority : "-",
              dateTime:
                element.dateTime !== 0
                  ? convertEpochTimeToDate(element.dateTime)
                  : "-",
              lastPaidDate: element.lastPaidDate
                ? convertEpochTimeToDate(element.lastPaidDate)
                : "-",
              dueAmount: element.dueAmount,
              dueDate:
                element.dueDate !== 0 ? EpochToOnlyDate(element.dueDate) : "-",
              targetDate:
                element.targetDate !== 0
                  ? EpochToOnlyDate(element.targetDate)
                  : "-",
              expiredDate: element.expiredDate
                ? EpochToOnlyDate(element.expiredDate)
                : "-",
              dueDay: element.dueDay,
              emiAmount: element.emiAmount,
              emiOutstanding: element.emiOutstanding,
              engineNumber: element.engineNumber,
              employeeId: element.executiveEmployeeId
                ? element.executiveEmployeeId
                : element.managerEmployeeId,
              executiveMobileNumber: element.executiveMobileNumber,
              executiveName: element.executiveName,
              guarantorMobile: element.guarantorMobile,
              guarantorName: element.guarantorName,
              id: element.id,
              image: element.image,
              latePaymentCharges: element.latePaymentCharges,
              latitude: element.latitude,
              loanAmount: element.loanAmount,
              loanNumber: element.loanNumber,
              location: element.location,
              longitude: element.longitude,
              managerEmployeeId: element.managerEmployeeId,
              managerName: element.managerName,
              nearByLandmark: element.nearByLandmark,
              netOtherAmount: element.netOtherAmount,
              net_GDI_Amount: element.net_GDI_Amount,
              net_HL_Amount: element.net_HL_Amount,
              organizationId: element.organizationId,
              permanentAddress: element.permanentAddress,
              permanentCity: element.permanentCity,
              permanentState: element.permanentState,
              permanentZipcode: element.permanentZipcode,
              principalOutstanding: element.principalOutstanding,
              productName: element.productName,
              referenceMobile1: element.referenceMobile1,
              referenceMobile2: element.referenceMobile2,
              referenceName1: element.referenceName1,
              referenceName2: element.referenceName2,
              registrationNumber: element.registrationNumber,
              remarks: element.remarks,
              reportingOfficerId: element.reportingOfficerId,
              address: element.residenceAddress,
              residenceCity: element.residenceCity,
              residenceState: element.residenceState,
              residenceZipcode: element.residenceZipcode,
              status: element.status,
              subStatus: element?.subStatus,
              teamLeaderEmployeeId: element.teamLeaderEmployeeId,
              teamLeaderName: element.teamLeaderName,
              temporaryRegistrationNumber: element.temporaryRegistrationNumber,
              tenure: element.tenure,
              teleCallerName: element.teleCallerName
                ? element.teleCallerName
                : "-",
              teleCallerEmpId: element.teleCallerEmpId
                ? element.teleCallerEmpId
                : "-",
              teleCallerUserId: element.teleCallerUserId,
              totalPenaltyCharges: element.totalPenaltyCharges,
              typeOfVisit: element.typeOfVisit,
              thirdPartyPhoneNumber: element.thirdPartyPhoneNumber,
              thirdPartyName: element.thirdPartyName,
              updatedDate:
                element.updatedDate !== 0
                  ? convertEpochTimeToDate(element.updatedDate)
                  : "-",
              lastVisitedAddress: element.lastVisitedAddress,
              lastVisitedAddressLat: element.lastVisitedAddressLat
                ? element.lastVisitedAddressLat
                : 0,
              lastVisitedAddressLong: element.lastVisitedAddressLong
                ? element.lastVisitedAddressLong
                : 0,
              lastVisitedAddress2: element.lastVisitedAddress2,
              lastVisitedAddress2Lat: element.lastVisitedAddress2Lat
                ? element.lastVisitedAddress2Lat
                : 0,
              lastVisitedAddress2Long: element.lastVisitedAddress2Long
                ? element.lastVisitedAddress2Long
                : 0,
              userId: element.userId,
              vehicleType: element.vehicleType,
              visitStatus: element.visitStatus,
              emiAmountPaid: element.emiAmountPaid ? element.emiAmountPaid : 0,
              emiAmountToBePaid: element.emiAmountToBePaid
                ? element.emiAmountToBePaid
                : 0,
              bounceChargesPaid: element.bounceChargesPaid
                ? element.bounceChargesPaid
                : 0,
              bounceChargesToBePaid: element.bounceChargesToBePaid
                ? element.bounceChargesToBePaid
                : 0,
              latePaymentChargesPaid: element.latePaymentChargesPaid
                ? element.latePaymentChargesPaid
                : 0,
              latePaymentChargesToBePaid: element.latePaymentChargesToBePaid
                ? element.latePaymentChargesToBePaid
                : 0,
              penaltyChargesPaid: element.penaltyChargesPaid
                ? element.penaltyChargesPaid
                : 0,
              penaltyChargesToBePaid: element.penaltyChargesToBePaid
                ? element.penaltyChargesToBePaid
                : 0,
              otherChargesPaid: element.otherChargesPaid
                ? element.otherChargesPaid
                : 0,
              otherChargesToBePaid: element.otherChargesToBePaid
                ? element.otherChargesToBePaid
                : 0,
            };
            listData2.push(data);
          });
          setCustomersList(listData2);
          setTotalElements2(response?.totalElements);
          setIsLoading2(false);
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const customerColumns = [
    {
      text: "Emp Id",
      dataField: "employeeId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Mobile",
      dataField: "contactNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Priority",
      dataField: "priority",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Loan No",
      dataField: "loanNumber",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              // textDecoration: "underline",
            }}
            onClick={() => {
              setVisitDetails(row);
              setLoanNoDetailsModal(true);
            }}
          >
            {row.loanNumber}
          </span>
        </div>
      ),
    },
    // {
    //   text: "Visit Type",
    //   dataField: "typeOfVisit",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Amount Paid",
      dataField: "amountPaid",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Current Due</span>
          <br></br>
          <span>Amount</span>
        </div>
      ),
      dataField: "amountToBePaid",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Total Due</span>
          <br></br>
          <span>Amount</span>
        </div>
      ),
      dataField: "dueAmount",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Due Date",
      dataField: "dueDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Target Date",
      dataField: "targetDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: " Residence Address",
      dataField: "address",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Assigned</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Opening</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "openingDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Closing</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "closingDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Intensity</span>
        </div>
      ),
      dataField: "customerIntensinty",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => {
              setcustomerIntensity(row);
            }}
          >
            {row.customerIntensinty}
          </span>
        </div>
      ),
    },
    {
      text: (
        <div>
          <span>Last visited</span>
          <br></br>
          <span>Date</span>
        </div>
      ),
      dataField: "updatedDate",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Visit</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "visitStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.visitStatus === "Partially Paid") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "PTP") {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "Paid") {
          return (
            <span className="badge badge-rounded badge-success">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "Customer Not Available") {
          return (
            <span className="badge badge-rounded badge-danger">
              {row.visitStatus}
            </span>
          );
        } else if (row.visitStatus === "" || row.visitStatus === null) {
          return <span>-</span>;
        } else {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.visitStatus}
            </span>
          );
        }
      },
    },
    {
      text: (
        <div>
          <span>Sub</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (
          row.subStatus === "Cash" ||
          row.subStatus === "Bank Transfer" ||
          row.subStatus === "Already Settled" ||
          row.subStatus === "UPI"
        ) {
          return (
            <span
              className="badge badge-rounded badge-success"
              // style={{ color: "black" }}
            >
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "PTP") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "Paid") {
          return (
            <span className="badge badge-rounded badge-success">
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "Customer Not Available") {
          return (
            <span className="badge badge-rounded badge-danger">
              {row.subStatus}
            </span>
          );
        } else if (row.subStatus === "" || row.subStatus === null) {
          return <span>-</span>;
        } else {
          return (
            <span className="badge badge-rounded badge-primary">
              {row.subStatus}
            </span>
          );
        }
      },
    },

    {
      text: (
        <div>
          <span>Allocation</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (row.status === "OPEN") {
          return (
            <span className="badge badge-rounded badge-primary">Open</span>
          );
        } else if (row.status === "COMPLETED") {
          return (
            <span className="badge badge-rounded badge-success">Completed</span>
          );
        } else {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              In Progress
            </span>
          );
        }
      },
    },
    {
      text: (
        <div>
          <span>TeleCaller</span>
          <br></br>
          <span>Name</span>
        </div>
      ),
      dataField: "teleCallerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>TeleCaller</span>
          <br></br>
          <span>Emp Id</span>
        </div>
      ),
      dataField: "teleCallerEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  const custDownloadData = (isExecutive) => {
    setIsLoading2(true);
    setIsLoading(true);
    let payload = {
      cpName:
        userLoginDetails.autoFinanceUser?.roleName !== "ADMIN"
          ? selectedCpName
          : selectedCpName2,
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      startDate: start,
      userId: isAdmin()
        ? selectedUserId
        : isExecutive === true
        ? selectedUserId
        : "",
      reportingOfficerId: userLoginDetails.autoFinanceUser.userId,
      visitStatus: "",
      priority: selectedPriority,
      searchKey: searchKey1,
    };

    moduleApi
      .postData(moduleApi.withPage(apiCall.getCustomersList, 0, 10), payload)
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading2(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading2(true);
                getCustFullResponseData(
                  Math.floor(response.totalElements / 400),
                  isExecutive
                );
              }
            });
          }

          getCustFullResponseData(
            Math.floor(response.totalElements / 400),
            isExecutive
          );
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getCustFullResponseData(size, isExecutive) {
    var tempList = [];
    var custListData = [];

    for (let i = 0; i <= size; i++) {
      setIsLoading2(true);
      let payload = {
        cpName:
          userLoginDetails.autoFinanceUser?.roleName !== "ADMIN"
            ? selectedCpName
            : selectedCpName2,
        endDate: end,
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        startDate: start,
        userId: isAdmin()
          ? selectedUserId
          : isExecutive === true
          ? selectedUserId
          : "",
        reportingOfficerId: userLoginDetails.autoFinanceUser.userId,
        visitStatus: "",
        priority: selectedPriority,
        searchKey: searchKey1,
      };

      await moduleApi
        .postData(moduleApi.withPage(apiCall.getCustomersList, i, 400), payload)
        .then((response) => {
          tempList.push(...response.content);
        });
    }
    // console.log("forloop", tempList);

    tempList?.forEach((element) => {
      let data = {
        address: element.residenceAddress,
        amountPaid: element.amountPaid,
        amountPaidNow: element.amountPaidNow,
        amountToBePaid: element.amountToBePaid,
        contactNumber: element.contactNumber1,
        customerName: element.customerName,
        dateTime: convertEpochTimeToDate(element.dateTime),
        employeeVisits: element.employeeVisits,
        id: element.id,
        customerIntensinty: element.customerIntensinty,
        employeeId: element.executiveEmployeeId
          ? element.executiveEmployeeId
          : element.managerEmployeeId,
        loanNumber: element.loanNumber,
        lenderId: element.lenderId ? element.lenderId : "-",
        priority: element.priority ? element.priority : "-",
        lenderName: element.lenderName ? element.lenderName : "-",
        cpName: element.cpName ? element.cpName : "-",
        teleCallerName: element.teleCallerName ? element.teleCallerName : "-",
        teleCallerEmpId: element.teleCallerEmpId
          ? element.teleCallerEmpId
          : "-",
        managerName: element.managerName ? element.managerName : "-",
        managerEmployeeId: element.managerEmployeeId
          ? element.managerEmployeeId
          : "-",
        reportingOfficerId: element.reportingOfficerId,
        dueAmount: element.dueAmount,
        dueDate: element.dueDate !== 0 ? EpochToOnlyDate(element.dueDate) : "-",
        targetDate:
          element.targetDate !== 0 ? EpochToOnlyDate(element.targetDate) : "-",
        closingDate: element.closingDate
          ? EpochToOnlyDate(element.closingDate)
          : "-",
        openingDate: element.openingDate
          ? EpochToOnlyDate(element.openingDate)
          : "-",
        typeOfVisit: element.typeOfVisit,
        updatedDate:
          element.updatedDate !== 0
            ? convertEpochTimeToDate(element.updatedDate)
            : "-",
        lastVisitedAddress: element.lastVisitedAddress
          ? element.lastVisitedAddress
          : "-",
        lastVisitedAddressLat: element.lastVisitedAddressLat
          ? element.lastVisitedAddressLat
          : 0,
        lastVisitedAddressLong: element.lastVisitedAddressLong
          ? element.lastVisitedAddressLong
          : 0,
        lastVisitedAddress2: element.lastVisitedAddress2
          ? element.lastVisitedAddress2
          : "-",
        lastVisitedAddress2Lat: element.lastVisitedAddress2Lat
          ? element.lastVisitedAddress2Lat
          : 0,
        lastVisitedAddress2Long: element.lastVisitedAddress2Long
          ? element.lastVisitedAddress2Long
          : 0,
        userId: element.userId,
        visitStatus: element.visitStatus ? element.visitStatus : "-",
        status: element.status ? element.status : "-",
        subStatus: element?.subStatus ? element.subStatus : "-",
        locLink1: element.locLink1 ? element.locLink1 : "-",
        locLink2: element.locLink2 ? element.locLink2 : "-",
      };
      custListData.push(data);
    });
    setCustData(custListData);
  }

  const handleExport = () => {
    let custTable = [
      {
        A: "Employee Id",
        B: "Customer Name",
        C: "Mobile",
        D: "CP Name",
        E: "Lender Id",
        F: "Lender Name",
        G: "Priority",
        H: "Loan No",
        I: "Amount Paid",
        J: "Currnt Due Amount",
        K: "Total Due Amount",
        L: "Due Date",
        M: "Target Date",
        N: "Residence Address",
        O: "Assigned Date",
        P: "Opening Date",
        Q: "Closing Date",
        R: "Customer Intensity",
        S: "Last Visited Date",
        T: "Visit Status",
        U: "Sub Status",
        V: "Allocation Status",
        W: "TeleCaller Name",
        X: "TeleCaller Emp Id",
        Y: "Manager Name",
        Z: "Manager Emp Id",
      },
    ];
    // console.log("custdata", custData);
    custData?.forEach((row) => {
      custTable.push({
        A: row.employeeId,
        B: row.customerName,
        C: row.contactNumber,
        D: row.cpName,
        E: row.lenderId,
        F: row.lenderName,
        G: row.priority,
        H: row.loanNumber,
        I: row.amountPaid,
        J: row.amountToBePaid,
        K: row.dueAmount,
        L: row.dueDate,
        M: row.targetDate,
        N: row.address,
        O: row.dateTime,
        P: row.openingDate,
        Q: row.closingDate,
        R: row.customerIntensinty,
        S: row.updatedDate,
        T: row.visitStatus,
        U: row.subStatus,
        V: row.status,
        W: row.teleCallerName,
        X: row.teleCallerEmpId,
        Y: row.managerName,
        Z: row.managerEmployeeId,
      });
    });

    const finalData = [...custTable];

    // console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    // console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Allocations");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    XLSX.writeFile(
      wb,
      (isAdmin()
        ? selectedExeName?.employeeId + "_Clu_Customer_Details_Report_"
        : (selectedExeName === undefined || selectedExeName === ""
            ? ""
            : selectedExeName?.employeeId + "_") + "Collection_Allocations_") +
        // new Date().toLocaleString("en-US") +
        EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Customer Details Report Download",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Customer_Details_Report_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  // const createDownLoadData = () => {
  //   sampleHandleExport().then((url) => {
  //     console.log(url);
  //     const downloadAnchorNode = document.createElement("a");
  //     downloadAnchorNode.setAttribute("href", url);
  //     downloadAnchorNode.setAttribute(
  //       "download",
  //       "Collections_Allocation_Template_" +
  //         EpochToDate(Math.floor(new Date() / 1000)) +
  //         ".xlsx"
  //     );
  //     downloadAnchorNode.click();
  //     downloadAnchorNode.remove();
  //   });
  // };

  // const workbook2blob = (workbook) => {
  //   const wopts = {
  //     bookType: "xlsx",
  //     bookSST: false,
  //     type: "binary",
  //   };

  //   const wbout = XLSX.write(workbook, wopts);

  //   // The application/octet-stream MIME type is used for unknown binary files.
  //   // It preserves the file contents, but requires the receiver to determine file type,
  //   // for example, from the filename extension.
  //   const blob = new Blob([s2ab(wbout)], {
  //     type: "application/octet-stream",
  //   });

  //   return blob;
  // };

  // const s2ab = (s) => {
  //   // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  //   // create an ArrayBuffer with a size in bytes
  //   const buf = new ArrayBuffer(s.length);

  //   console.log(buf);

  //   //create a 8 bit integer array
  //   const view = new Uint8Array(buf);

  //   console.log(view);
  //   //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  //   for (let i = 0; i !== s.length; ++i) {
  //     console.log(s.charCodeAt(i));
  //     view[i] = s.charCodeAt(i);
  //   }

  //   return buf;
  // };

  // const sampleHandleExport = () => {
  //   let sampleTable = [
  //     {
  //       A: "Loan No",
  //       B: "Lender Id",
  //       C: "Lender Name",
  //       D: "Customer Name",
  //       E: "Emp Name",
  //       F: "Emp Id",
  //       G: "Assigned Name",
  //       H: "Assigned Mobile Number",
  //       I: "Product",
  //       J: "Cp Name",
  //       K: "New/Used",
  //       L: "Loan Amount",
  //       M: "POS(Principle Outstanding Amount)",
  //       N: "EMI Amount",
  //       O: "Current Bucket",
  //       P: "Tenure (Months)",
  //       Q: "Current EMI Month",
  //       R: "EMI OS",
  //       S: "Net GDI Amount",
  //       T: "Net HL Amount",
  //       U: "LPP(Late Payment) Charges",
  //       V: "BCC(Bounce) Charges",
  //       W: "Total Penalty Charges",
  //       X: "Net Other Amount",
  //       Y: "Total Due",
  //       Z: "Due Date",
  //       AA: "AG Date",
  //       AB: "Due Day",
  //       AC: "Borrower Address (Home Address)",
  //       AD: "Promoter Address (Office Address)",
  //       AE: "Residence Address Line 1",
  //       AF: "Residence Address Line 2",
  //       AG: "Residence City",
  //       AH: "Residence State",
  //       AI: "Residence Zip Code",
  //       AJ: "Near By Landmark",
  //       AK: "Location",
  //       AL: "Contact No 1",
  //       AM: "Contact No 2",
  //       AN: "Permanent Address",
  //       AO: "Permanent City",
  //       AP: "Permanent State",
  //       AQ: "Permanent Zip Code",
  //       AR: "Reference 1 Name",
  //       AS: "Reference 1 Phone",
  //       AT: "Reference 2 Name",
  //       AU: "Reference 2 Phone",
  //       AV: "Guarantor Name",
  //       AW: "Guarantor Mobile",
  //       AX: "Registration Number",
  //       AY: "Temporary Registration Number",
  //       AZ: "Engine No",
  //       BA: "Chassis No",
  //       BB: "Asset Desc",
  //       BC: "Remarks",
  //       BD: "Opening Date",
  //       BE: "Closing Date",
  //     },
  //   ];
  //   // console.log("custdata", custData);
  //   // custData?.forEach((row) => {
  //   //   custTable.push({
  //   //     A: row.employeeId,
  //   //     B: row.customerName,
  //   //     C: row.contactNumber,
  //   //     D: row.loanNumber,
  //   //     E: row.amountPaid,
  //   //     F: row.amountToBePaid,
  //   //     G: row.dueAmount,
  //   //     H: row.dueDate,
  //   //     I: row.address,
  //   //     J: row.dateTime,
  //   //     K: row.updatedDate,
  //   //     L: row.visitStatus,
  //   //     M: row.status,
  //   //   });
  //   // });

  //   const finalData = [...sampleTable];

  //   console.log("finaldata", finalData);

  //   //create a new workbook
  //   const wb = XLSX.utils.book_new();

  //   const sheet = XLSX.utils.json_to_sheet(finalData, {
  //     skipHeader: true,
  //   });

  //   console.log("sheet", sheet);

  //   XLSX.utils.book_append_sheet(wb, sheet, "Allocations");
  //   const workbookBlob = workbook2blob(wb);

  //   var headerIndexes = [];
  //   finalData.forEach((data, index) =>
  //     data["A"] === "Enrolment No." ? headerIndexes.push(index) : null
  //   );

  //   // const totalRecords = data.length;

  //   const dataInfo = {
  //     titleCell: "A2",
  //     titleRange: "A1:H2",
  //     tbodyRange: `A3:H${finalData.length}`,
  //     theadRange:
  //       headerIndexes?.length >= 1
  //         ? `A${headerIndexes[0] + 1}:G${headerIndexes[0] + 1}`
  //         : null,
  //     theadRange1:
  //       headerIndexes?.length >= 2
  //         ? `A${headerIndexes[1] + 1}:H${headerIndexes[1] + 1}`
  //         : null,
  //     tFirstColumnRange:
  //       headerIndexes?.length >= 1
  //         ? `A${headerIndexes[0] + 1}:A${1 + headerIndexes[0] + 1}`
  //         : null,
  //     tLastColumnRange:
  //       headerIndexes?.length >= 1
  //         ? `G${headerIndexes[0] + 1}:G${1 + headerIndexes[0] + 1}`
  //         : null,

  //     tFirstColumnRange1:
  //       headerIndexes?.length >= 1
  //         ? `A${headerIndexes[1] + 1}:A${1 + headerIndexes[1] + 1}`
  //         : null,
  //     tLastColumnRange1:
  //       headerIndexes?.length >= 1
  //         ? `H${headerIndexes[0] + 1}:H${1 + headerIndexes[1] + 1}`
  //         : null,
  //   };

  //   return addStyle(workbookBlob, dataInfo);
  // };

  // const addStyle = (workbookBlob, dataInfo) => {
  //   return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
  //     workbook.sheets().forEach((sheet) => {
  //       sheet.usedRange().style({
  //         fontFamily: "Calibri",
  //         verticalAlignment: "center",
  //         horizontalAlignment: "center",
  //         wrapText: true,
  //         bold: true,
  //       });

  //       // sheet.row(1).height(30);
  //       sheet.column("A").width(15);
  //       sheet.column("B").width(15);
  //       sheet.column("C").width(15);
  //       sheet.column("D").width(15);
  //       sheet.column("E").width(15);
  //       sheet.column("F").width(15);
  //       sheet.column("G").width(15);
  //       sheet.column("H").width(25);
  //       sheet.column("I").width(15);
  //       sheet.column("J").width(15);
  //       sheet.column("K").width(12);
  //       sheet.column("L").width(15);
  //       sheet.column("M").width(32);
  //       sheet.column("N").width(15);
  //       sheet.column("O").width(15);
  //       sheet.column("P").width(15);
  //       sheet.column("Q").width(18);
  //       sheet.column("R").width(15);
  //       sheet.column("S").width(15);
  //       sheet.column("T").width(15);
  //       sheet.column("U").width(25);
  //       sheet.column("V").width(22);
  //       sheet.column("W").width(22);
  //       sheet.column("X").width(18);
  //       sheet.column("Y").width(15);
  //       sheet.column("Z").width(15);
  //       sheet.column("AA").width(15);
  //       sheet.column("AB").width(15);
  //       sheet.column("AC").width(30);
  //       sheet.column("AD").width(30);
  //       sheet.column("AE").width(31);
  //       sheet.column("AF").width(22);
  //       sheet.column("AG").width(18);
  //       sheet.column("AH").width(16);
  //       sheet.column("AI").width(18);
  //       sheet.column("AJ").width(16);
  //       sheet.column("AK").width(16);
  //       sheet.column("AL").width(15);
  //       sheet.column("AM").width(17);
  //       sheet.column("AN").width(17);
  //       sheet.column("AO").width(17);
  //       sheet.column("AP").width(17);
  //       sheet.column("AQ").width(18);
  //       sheet.column("AR").width(17);
  //       sheet.column("AS").width(17);
  //       sheet.column("AT").width(17);
  //       sheet.column("AU").width(17);
  //       sheet.column("AV").width(15);
  //       sheet.column("AW").width(16);
  //       sheet.column("AX").width(20);
  //       sheet.column("AY").width(28);
  //       sheet.column("AZ").width(16);
  //       sheet.column("BA").width(15);
  //       sheet.column("BB").width(15);
  //       sheet.column("BC").width(15);
  //       sheet.column("BD").width(13);
  //       sheet.column("BE").width(12);
  //       // sheet.column("AW").width(17);
  //       // sheet.column("AX").width(15);

  //       // sheet.cell("A2").value("bold").style("fill", { theme: 5, tint: 0.25 })

  //       // sheet.range(dataInfo.titleRange).merged(true).style({
  //       //   bold: true,
  //       //   horizontalAlignment: "center",
  //       //   verticalAlignment: "center",
  //       // });

  //       // if (dataInfo.tbodyRange) {
  //       //   sheet.range(dataInfo.tbodyRange).style({
  //       //     horizontalAlignment: "center",
  //       //   });
  //       // }

  //       sheet.range("A1:A1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });
  //       sheet.range("D1:F1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });
  //       sheet.range("I1:I1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });
  //       sheet.range("L1:N1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });
  //       sheet.range("P1:P1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });
  //       sheet.range("R1:R1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });
  //       sheet.range("Y1:Z1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });
  //       sheet.range("AE1:AE1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });
  //       sheet.range("AL1:AL1").style({
  //         fill: "4FC95E",
  //         // bold: true,
  //         horizontalAlignment: "center",
  //       });

  //       // if (dataInfo.theadRange1) {
  //       //   sheet.range(dataInfo.theadRange1).style({
  //       //     fill: "808080",
  //       //     bold: true,
  //       //     horizontalAlignment: "center",
  //       //     fontColor: "ffffff",
  //       //   });
  //       // }

  //       // if (dataInfo.tFirstColumnRange) {
  //       //   sheet.range(dataInfo.tFirstColumnRange).style({
  //       //     bold: true,
  //       //   });
  //       // }

  //       // if (dataInfo.tLastColumnRange) {
  //       //   sheet.range(dataInfo.tLastColumnRange).style({
  //       //     // bold: true,
  //       //   });
  //       // }

  //       // if (dataInfo.tFirstColumnRange1) {
  //       //   sheet.range(dataInfo.tFirstColumnRange1).style({
  //       //     bold: true,
  //       //   });
  //       // }

  //       // if (dataInfo.tLastColumnRange1) {
  //       //   sheet.range(dataInfo.tLastColumnRange1).style({
  //       //     bold: true,
  //       //   });
  //       // }
  //     });

  //     return workbook
  //       .outputAsync()
  //       .then((workbookBlob) => URL.createObjectURL(workbookBlob));
  //   });

  //   // XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

  //   // XLSX.writeFile(
  //   //   wb,
  //   //   "Collections_Allocation_Template_" +
  //   //     EpochToDate(Math.floor(new Date() / 1000)) +
  //   //     ".xlsx"
  //   // );
  //   // ReactGA.event({
  //   //   category: "Collections Allocation Template Download",
  //   //   action:
  //   //     userLoginDetails.autoFinanceOrganization.orgshortcode +
  //   //     "_Collections_Allocation_Template_" +
  //   //     helper.EpochToDate(Math.floor(new Date() / 1000)) +
  //   //     ".xlsx",
  //   // });
  // };

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const setCustomDatesForGraph = (value, isMonth) => {
    var start =
      isMonth === true
        ? value[0]?.getTime() / 1000
        : Math.floor(new Date(value[0]).getTime() / 1000);
    var end =
      isMonth === true
        ? new Date(
            value[0]?.getFullYear(),
            value[0]?.getMonth() + 1,
            0
          ).getTime() /
            1000 +
          86399
        : Math.floor(new Date(value[1]).getTime() / 1000 + 86400);

    setStart(start);
    setEnd(end);
    getCustomersList(
      0,
      start,
      end,
      selectedExeName.userId,
      "",
      selectedCpName2,
      selectedPriority
    );
  };

  const searchKeyword1 = (key1) => {
    setSearchKey1(key1);
    getCustomersList(
      0,
      start,
      end,
      selectedUserId,
      key1,
      selectedCpName2,
      selectedPriority
    );
  };

  const userOptions = [
    { label: "Executives", value: "EXECUTIVE" },
    { label: "Managers", value: "MANAGER" },
  ];

  const dateOptions = [
    { label: "Custom Month", value: "cm" },
    { label: "Custom Dates", value: "cd" },
  ];

  const getAllCpNames = () => {
    moduleApi
      .getData(
        apiCall.getCpUsersForDropdown +
          userLoginDetails.autoFinanceUser.organizationId
      )
      .then((response) => {
        // console.log("response", response);
        const result = response.map((item, index) => ({
          value: item,
          label: item,
          index: index,
        }));
        result.unshift({ label: "All", value: "" });
        setCpNamesList(result);
      });
  };

  const getAllPriorities = () => {
    moduleApi
      .getData(
        apiCall.getPrioritiesBasedOnOrgId +
          userLoginDetails.autoFinanceUser.organizationId
      )
      .then((response) => {
        // console.log("response", response);
        const result = response.map((item, index) => ({
          value: item,
          label: item,
          index: index,
        }));
        result.unshift({ label: "All", value: "" });
        setPrioritiesList(result);
      });
  };

  const downloadAllAllocations = () => {
    setIsLoading(true);
    moduleApi
      .getData(
        apiCall.downloadAllAllocations +
          userLoginDetails.autoFinanceUser.organizationId +
          "?date=" +
          start2
      )
      .then((response) => {
        // console.log("response", response);
        setIsLoading(false);
      });
  };

  const fileTypesList = [
    { label: "Collection Allocations", value: "UPDATE_ALLOCATIONS" },
    {
      label: "Telecaller Allocations",
      value: "UPDATE_TELE_CALLER_ALLOCATIONS",
    },
    { label: "Delete Allocations", value: "DELETE_ALLOCATIONS" },
    { label: "Transfer Allocations", value: "TRANSFER_ALLOCATIONS" },
    { label: "Allocate Priorities", value: "UPDATE_PROIRITIES_ALLOCATIONS" },
    { label: "Lender Payment Status", value: "UPDATE_LENDER_PAYMENT_STATUS" },
    { label: "Campaign Allocation", value: "UPDATE_ALLOCATION_TO_CAMPAIGN" },
    {
      label: "Additional Phone Numbers",
      value: "UPDATE_ADDITIONAL_PHONE_NUMBERS",
    },
    {
      label: "External Payments",
      value: "UPDATE_EXTERNAL_PAYMENTS",
    },
    {
      label: "Update Target Date",
      value: "UPDATE_TARGET_DATE",
    },
  ];

  const downloadAllocationsTemplate = () => {
    let collectionAllocationsTemplateUrl =
      userLoginDetails.autoFinanceOrganization.allocationTemplateUrl !== null
        ? userLoginDetails.autoFinanceOrganization.allocationTemplateUrl
        : "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Collection_Allocations_Template.xlsx";
    let telecallerAllocationsTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/TeleCaller_Allocations_Template.xlsx";
    let transferAllocationsTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Transfer_Allocations_Template.xlsx";
    let deleteAllocationsTemplateUrl =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Delete_Allocations_Template.xlsx";
    let allocatePriorities =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Proirity_Template.xlsx";
    let lenderPaymentStatus =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Lender_Payment_Status.xlsx";
    let campaignAllocation =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/campaign_Template.xlsx";
    let updateAdditionalPhoneNos =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Additional+Numbers_Template.xlsx";
    let externalPayments =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/External_Payments_Template.xlsx";
    let updateTargetDate =
      "https://clubucket-qacicd.s3.ap-south-1.amazonaws.com/public/Target_Date_Update_Template.xlsx";

    const fileUrl =
      selectedTemplate === "UPDATE_ALLOCATIONS"
        ? collectionAllocationsTemplateUrl
        : selectedTemplate === "UPDATE_TELE_CALLER_ALLOCATIONS"
        ? telecallerAllocationsTemplateUrl
        : selectedTemplate === "TRANSFER_ALLOCATIONS"
        ? transferAllocationsTemplateUrl
        : selectedTemplate === "UPDATE_PROIRITIES_ALLOCATIONS"
        ? allocatePriorities
        : selectedTemplate === "UPDATE_LENDER_PAYMENT_STATUS"
        ? lenderPaymentStatus
        : selectedTemplate === "UPDATE_ALLOCATION_TO_CAMPAIGN"
        ? campaignAllocation
        : selectedTemplate === "UPDATE_ADDITIONAL_PHONE_NUMBERS"
        ? updateAdditionalPhoneNos
        : selectedTemplate === "UPDATE_EXTERNAL_PAYMENTS"
        ? externalPayments
        : selectedTemplate === "UPDATE_TARGET_DATE"
        ? updateTargetDate
        : deleteAllocationsTemplateUrl;

    // Create an anchor element
    const anchor = document.createElement("a");

    // Set the href and download attributes
    anchor.href = fileUrl;
    anchor.setAttribute(
      "download",
      (selectedTemplate === "UPDATE_ALLOCATIONS"
        ? "Collection_Allocation"
        : selectedTemplate === "UPDATE_TELE_CALLER_ALLOCATIONS"
        ? "TeleCaller_Allocation"
        : selectedTemplate === "TRANSFER_ALLOCATIONS"
        ? "Transfer_Allocation"
        : "Delete_Allocation") + "_Template.xlsx"
    ); // Set the desired file name

    // Programmatically trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor element from the DOM (optional)
    anchor.remove();
    setSelectedTemplate(null);
    setTemplateDownloadModal(false);

    // moduleApi
    //   .getDownloadedFile(
    //     userLoginDetails.autoFinanceOrganization.allocationTemplateUrl !== null
    //       ? userLoginDetails.autoFinanceOrganization.allocationTemplateUrl
    //       : ""
    //   )
    //   .then((response) => {
    //     // console.log("response", response);
    //   });
  };

  const handleLoanNumberDetailsModal = () => {
    setLoanNoDetailsModal(false);
    getCustomersList(
      currPage2,
      start,
      end,
      selectedUserId,
      searchKey1,
      selectedCpName2,
      selectedPriority
    );
  };

  return (
    <div className="card">
      {userLoginDetails.autoFinanceUser.roleName !== "CP_USER" ? (
        <div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <div
              style={{
                marginTop: "20px",
                marginRight: "20px",
                float: "right",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  float: "left",
                }}
              >
                {/* {userLoginDetails.autoFinanceUser.roleName !== "ADMIN" && ( */}
                {/* <Button
            id="addcpName"
            className=""
            variant="primary btn-rounded"
            style={{
              borderRadius: "5px",
              float: "right",
            }}
            onClick={() => {
              loadCpNamesData(0);
              setCpNamesModal(true);
            }}
          >
            <b>Cp Names</b>
          </Button> */}
                {hasAccess(85) && (
                  <Button
                    style={{
                      float: "right",
                      marginRight: "15px",
                      fontFamily:
                        " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                    }}
                    className="me-2"
                    variant="primary btn-rounded"
                    onClick={() => {
                      setCpName(null);
                      setAddCpNameModal(true);
                    }}
                  >
                    <b> + Add CP Name</b>
                  </Button>
                )}
              </div>
              {/* {userLoginDetails.autoFinanceUser.roleName !== "ADMIN" && ( */}
              <Button
                id="collectionAllocationsTemplate"
                className=""
                variant="success btn-rounded"
                style={{
                  borderRadius: "5px",
                  float: "right",
                }}
                onClick={() => {
                  setTemplateDownloadModal(true);
                }}
              >
                <i class="fa fa-download"></i>&nbsp; Download Template
              </Button>
              {/* )} */}
            </div>
            {/* {userLoginDetails.autoFinanceUser.roleName === "ADMIN" && (
              <div
                style={{
                  marginTop: "20px",
                  marginRight: "20px",
                  float: "right",
                }}
              >
                <Button
                  id="uploadTeleCallerAllocations"
                  className=""
                  variant="success btn-rounded"
                  style={{
                    backgroundColor: "#218a3f",
                    borderColor: "#218a3f",
                    borderRadius: "5px",
                    float: "right",
                  }}
                  onClick={() => {
                    setTelecallerModal(true);
                  }}
                >
                  <i class="fa fa-upload"></i>&nbsp; Upload TeleCaller
                  Allocations
                </Button>
              </div>
            )} */}
            <div
              style={{
                marginTop: "20px",
                marginRight: "20px",
                float: "right",
              }}
            >
              {userLoginDetails.autoFinanceUser.roleName !== "MANAGER_RA" &&
                hasAccess(67) && (
                  <Button
                    id="uploadExecutiveSchedule"
                    className=""
                    variant="success btn-rounded"
                    style={{
                      backgroundColor: "#218a3f",
                      borderColor: "#218a3f",
                      borderRadius: "5px",
                      float: "right",
                    }}
                    onClick={() => {
                      setBasicModal(true);
                    }}
                  >
                    <i class="fa fa-upload"></i>&nbsp; Upload Allocations
                  </Button>
                )}
            </div>
          </div>

          <Modal className="fade" size="xl" show={basicModal}>
            <Modal.Header>
              <Modal.Title>Upload Allocations</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => handleClose()}
              ></Button>
            </Modal.Header>
            <Modal.Body
            // style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
            >
              <Excel2 close={handleClose} loadData={handleLoadData} />
            </Modal.Body>
          </Modal>
          <Modal className="fade" size="lg" show={telecallerModal}>
            <Modal.Header>
              <Modal.Title>Upload TeleCaller Allocations</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => teleCallerHandleClose()}
              ></Button>
            </Modal.Header>
            <Modal.Body
              style={{ maxHeight: "calc(120vh - 210px)", overflowY: "auto" }}
            >
              <TelecallerUpload
                close={teleCallerHandleClose}
                loadData={handleLoadData2}
              />
            </Modal.Body>
          </Modal>
          <Modal className="fade" size="xl" show={modalForCustomerIntensity}>
            <Modal.Header>
              <Modal.Title>
                <b>Customer Visit Details</b>
              </Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => handleClose1()}
              ></Button>
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: "calc(120vh - 210px)",
                overflowY: "auto",
                padding: "10px",
              }}
            >
              <CollectionVisitDetails
                startDate={start}
                endDate={end}
                visitDetails={visitDetails}
              />
            </Modal.Body>
          </Modal>

          <div style={{ margin: "20px" }}>
            <div className="custom-tab-1">
              <Tab.Container defaultActiveKey="allocated" activeKey={activeKey}>
                <Nav as="ul" className="nav-tabs-custom nav-justified">
                  <Nav.Item as="li" key="0">
                    <Nav.Link
                      eventKey="allocated"
                      onClick={() => {
                        setActiveKey("allocated");
                        var tempStart =
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                          ).getTime() / 1000;
                        var tempEnd =
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() + 1,
                            0
                          ).getTime() /
                            1000 +
                          86399;
                        setStart2(tempStart);
                        setEnd2(tempEnd);
                        setSearchKey("");
                        loadData(
                          0,
                          "",
                          tempStart,
                          tempEnd,
                          selectedValue,
                          selectedCpName
                        );
                        // localStorage.setItem("isDashboardCollectionAllocationCardCalled", 'false');
                      }}
                    >
                      <b> Allocated Users </b>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="1">
                    <Nav.Link
                      eventKey="notAllocated"
                      onClick={() => {
                        setActiveKey("notAllocated");
                        // localStorage.setItem("isDashboardCollectionAllocationCardCalled", 'false');
                      }}
                    >
                      <b> Non Allocated Users </b>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="2">
                    <Nav.Link
                      eventKey="summary"
                      onClick={() => {
                        setActiveKey("summary");
                        // localStorage.setItem("isDashboardCollectionAllocationCardCalled", 'false');
                      }}
                    >
                      <b> Summary </b>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="3">
                    <Nav.Link
                      eventKey="uploadedFiles"
                      onClick={() => {
                        setActiveKey("uploadedFiles");
                        // localStorage.setItem("isDashboardCollectionAllocationCardCalled", 'false');
                      }}
                    >
                      <b> Uploaded Files </b>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="4">
                    <Nav.Link
                      eventKey="cpNames"
                      onClick={() => {
                        setActiveKey("cpNames");
                        // localStorage.setItem("isDashboardCollectionAllocationCardCalled", 'false');
                      }}
                    >
                      <b> CP Names </b>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="pt-4">
                  <Tab.Pane
                    eventKey="allocated"
                    key="0"
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <LoadingOverlay
                        active={isLoading}
                        text={
                          <p
                            style={{
                              color: "black",
                            }}
                          >
                            <div>
                              <Button
                                style={{
                                  backgroundColor: "#FFFF",
                                  color: "black",
                                  borderColor: "white",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                }}
                                variant="primary"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="md"
                                  variant="primary"
                                  role="status"
                                  aria-hidden="true"
                                />
                                &nbsp;&nbsp;Loading...
                              </Button>
                            </div>
                          </p>
                        }
                        styles={{
                          overlay: (base) => ({
                            ...base,
                            background: "rgba(192,192,192,0.4)",
                          }),
                          spinner: (base) => ({
                            ...base,
                            width: "80px",
                            "& svg circle": {
                              stroke: "rgb(136, 108, 192)",
                            },
                          }),
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            float: "right",
                          }}
                        >
                          {userLoginDetails.autoFinanceUser.roleName !==
                            "MANAGER_RA" &&
                            hasAccess(69) && (
                              <div
                                style={{
                                  float: "right",
                                  // marginRight: "5px",
                                  fontFamily:
                                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                }}
                              >
                                <Button
                                  id="allocatedUsersDelete"
                                  style={{
                                    marginBottom: "15px",
                                    fontFamily:
                                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                                  }}
                                  className="me-2"
                                  variant="danger btn-rounded"
                                  onClick={() => {
                                    setDeleteModal(true);
                                  }}
                                >
                                  <i className="fas fa-trash" />
                                  &nbsp;&nbsp;<span>Delete</span>
                                </Button>
                              </div>
                            )}
                          {userLoginDetails.autoFinanceUser.roleName ===
                            "ADMIN" && (
                            <div
                              style={{
                                float: "right",
                                // marginTop:"20px",
                                //margin: "10px",
                                // marginRight: "5px",
                                fontFamily:
                                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                              }}
                            >
                              <Button
                                id="allocatedUsersDownload"
                                className="me-2"
                                variant="success btn-rounded"
                                onClick={() => downloadAllAllocations()}
                              >
                                <i className="fa fa-download"></i>
                                &nbsp;&nbsp;Download
                              </Button>
                            </div>
                          )}
                          {userLoginDetails.autoFinanceUser.roleName !==
                            "ADMIN" && (
                            <div
                              style={{
                                float: "right",
                               // marginTop:"20px",
                                fontFamily:
                                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                              }}
                            >
                              <Button
                                id="allocatedUsersDownload"
                                className="me-2"
                                variant="success btn-rounded"
                                onClick={() => custDownloadData(false)}
                              >
                                <i className="fa fa-download"></i>
                                &nbsp;&nbsp;Download
                              </Button>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            float: "right",
                          }}
                        >
                          <div
                            id="allocatedUsersSearch"
                            style={{ float: "right", marginTop:"20px"}}
                          >
                            <SearchBar
                              placeholder="Search By Name/LoanNo/Mobile/EmpId/Vehicle"
                              searchKeyword={searchKeyword}
                            />
                          </div>

                          {/* <div style={{display:'flex', flexDirection:'column'}}>
                          <div>Cp Name</div> */}
                          {/* <div
                            style={{
                              float: "right",
                              width: "105px",
                              marginRight: "5px",
                              height: "38px",
                              marginLeft: "5px",
                              borderRadius: "3px",
                              borderWidth: "1px",
                              boxShadow: "1px 1px 2px #797979",
                            }}
                          >
                            <Select
                              id="cpNames"
                              isSearchable={false}
                              onChange={(value) => {
                                loadData(
                                  0,
                                  searchKey,
                                  start,
                                  end,
                                  selectedValue,
                                  value.value,
                                  selectedCpName
                                );
                                setSelectedCpName(value.value);
                              }}
                              defaultValue={{ label: "All", value: "" }}
                              options={cpNamesList}
                            />
                          </div> */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginBottom: "5px",
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            <label style={{ fontWeight: "bold", marginBottom: "5px" }}>CP Name</label>
                            <div
                              style={{
                                width: "105px",
                                height: "38px",
                                borderRadius: "3px",
                                borderWidth: "1px",
                                boxShadow: "1px 1px 2px #797979",
                              }}
                            >
                              <Select
                                id="cpNames"
                                isSearchable={false}
                                onChange={(value) => {
                                  loadData(
                                    0,
                                    searchKey,
                                    start,
                                    end,
                                    selectedValue,
                                    value.value,
                                    selectedCpName
                                  );
                                  setSelectedCpName(value.value);
                                }}
                                defaultValue={{ label: "All", value: "" }}
                                options={cpNamesList}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop:"20px",
                              float: "right",
                              width: "110px",
                              height: "38px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              borderRadius: "3px",
                              borderWidth: "1px",
                              boxShadow: "1px 1px 2px #797979",
                            }}
                          >
                            <Select
                              id="roleNames"
                              isSearchable={false}
                              onChange={(value) => {
                                loadData(
                                  0,
                                  searchKey,
                                  start2,
                                  end2,
                                  value.value,
                                  selectedCpName
                                );
                                setSelectedValue(value.value);
                              }}
                              defaultValue={userOptions[0]}
                              options={userOptions}
                            />
                          </div>
                          {/* </div> */}
                          <div
                            style={{
                              marginTop:"20px",
                              float: "right",
                              width: "125px",
                              marginRight: "5px",
                              height: "38px",
                              marginLeft: "5px",
                              borderRadius: "3px",
                              borderWidth: "1px",
                              boxShadow: "1px 1px 2px #797979",
                            }}
                          >
                            <Select
                              id="dateFilter"
                              isSearchable={false}
                              onChange={(value) => {
                                value.value === "cm"
                                  ? setIsDateFilter(false)
                                  : setIsDateFilter(true);
                              }}
                              defaultValue={dateOptions[0]}
                              options={dateOptions}
                            />
                          </div>
                          {!isDateFilter && (
                            <div
                              style={{
                                float: "right",
                                marginRight: "5px",
                              }}
                            >
                              <Flatpickr
                                id="customonthPicker"
                                className="form-control d-block"
                                style={{
                                  marginTop:"20px",
                                  width: "115px",
                                  height: "40px",
                                  borderColor: "#a0a0a0",
                                  borderWidth: "1px",
                                  boxShadow: "1px 1px 2px #3a353b",
                                  textAlignLast: "center",
                                }}
                                placeholder="Select Month"
                                onChange={(value) => {
                                  setCustomDatesForGraph2(value, true);
                                }}
                                options={{
                                  defaultDate: new Date(),
                                  maxDate: new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth() + 2,
                                    0
                                  ),
                                  minDate: new Date().setMonth(
                                    new Date().getMonth() - 6
                                  ),

                                  plugins: [
                                    new monthSelectPlugin({
                                      shorthand: true, //defaults to false
                                      dateFormat: "F Y", //defaults to "F Y"
                                      altFormat: "F Y", //defaults to "F Y"
                                      theme: "light", // defaults to "light"
                                    }),
                                  ],
                                }}
                              />
                            </div>
                          )}
                          {isDateFilter && (
                            <div
                              style={{
                                float: "right",
                                marginRight: "5px",
                              }}
                            >
                              <Flatpickr
                                style={{
                                  width: "145px",
                                  height: "40px",
                                  borderColor: "#a0a0a0",
                                  borderWidth: "1px",
                                  boxShadow: "1px 1px 2px #3a353b",
                                  textAlignLast: "center",
                                }}
                                id="customDatePicker"
                                className="form-control d-block"
                                placeholder="Start & End Date"
                                onChange={(value) => {
                                  setCustomDatesForGraph2(value, false);
                                }}
                                options={{
                                  mode: "range",
                                  dateFormat: "d-M-y",
                                  maxDate: new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth() + 2,
                                    0
                                  ),
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            background: "white",
                            margin: "10px",
                            marginTop: "-5px",
                            overflow: "auto",

                            maxHeight: "400px",

                            width: "100%",
                          }}
                        >
                          <BootstrapTable
                            style={{ overflowX: "auto" }}
                            keyField="employeeId"
                            data={executivesList}
                            columns={columns}
                            selectRow={AllocatedSelectRow}
                          />
                        </div>
                        {executivesList.length === 0 && (
                          <div className="d-flex justify-content-center">
                            <img src={NO_DATA_FOUND} alt="" />
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin:
                              totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                          }}
                        >
                          <PaginationComponent
                            totalItems={totalElements}
                            pageSize={10}
                            defaultActivePage={currPage + 1}
                            onSelect={onPageChange}
                          />
                        </div>
                      </LoadingOverlay>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="notAllocated"
                    key="1"
                    mountOnEnter
                    unmountOnExit
                  >
                    <NonAllocatedUsers />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="summary"
                    key="2"
                    mountOnEnter
                    unmountOnExit
                  >
                    <Summary />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="uploadedFiles"
                    key="3"
                    mountOnEnter
                    unmountOnExit
                  >
                    <UploadedFilesList allocationType={"Collections"} />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="cpNames"
                    key="4"
                    mountOnEnter
                    unmountOnExit
                  >
                    <div
                      style={{
                        float: "left",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        <SearchBar
                          placeholder="Search By CP Name"
                          searchKeyword={cpNamesearch}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        background: "white",
                        margin: "10px",
                        marginTop: "-5px",
                        overflow: "auto",
                        maxHeight: "400px",
                        width: "100%",
                      }}
                    >
                      <BootstrapTable
                        style={{ overflowX: "auto" }}
                        keyField="employeeId"
                        data={cpNamesData}
                        columns={cpNamesColumns}
                      />
                    </div>
                    {cpNamesData.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <img src={NO_DATA_FOUND} alt="" />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin:
                          totalElements === 0 ? "90px 0px 5px 0px" : "0px",
                      }}
                    >
                      <PaginationComponent
                        totalItems={totalElements3}
                        pageSize={10}
                        defaultActivePage={currPage3 + 1}
                        onSelect={onPageChange3}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
          <Modal className="fade" size="xl" show={basicModal2}>
            <Modal.Header>
              <Modal.Title>
                Scheduled Customers List for{" "}
                {
                  <span style={{ fontWeight: "bold" }}>
                    {selectedExeName.name}
                  </span>
                }
              </Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => {
                  setSelectedIds([]);
                  setBasicModal2(false);
                  loadData(
                    0,
                    searchKey,
                    start2,
                    end2,
                    selectedValue,
                    selectedCpName
                  );
                  setCurrPage(0);
                  setSelectedExeName("");
                }}
              ></Button>
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: "calc(120vh - 210px)",
                overflowY: "auto",
                padding: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    flexDirection: "row",
                    marginBottom:"5px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                    }}
                  >
                  <label style={{ fontWeight: "bold", marginBottom: "5px" }}>Priority</label>
                  <div
                    style={{
                      float: "right",
                      width: "105px",
                      marginRight: "5px",
                      height: "38px",
                      marginLeft: "5px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      id="priorityFilter"
                      isSearchable={false}
                      onChange={(value) => {
                        getCustomersList(
                          0,
                          start,
                          end,
                          selectedUserId,
                          searchKey1,
                          selectedCpName2,
                          value.value
                        );
                        setSelectedPriority(value.value);
                      }}
                      defaultValue={{ label: "All", value: "" }}
                      options={prioritiesList}
                    />
                  </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                    }}
                  >
                  <label style={{ fontWeight: "bold", marginBottom: "5px" }}>CP Name</label>
                  <div
                    style={{
                      float: "right",
                      width: "140px",
                      marginRight: "5px",
                      height: "38px",
                      marginLeft: "10px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      id="customerCpNameFilter"
                      isSearchable={false}
                      onChange={(value) => {
                        getCustomersList(
                          0,
                          start,
                          end,
                          selectedUserId,
                          searchKey1,
                          value.value,
                          selectedPriority
                        );
                        setSelectedCpName2(value.value);
                      }}
                      defaultValue={
                        cpNamesList !== null
                          ? cpNamesList[sltcdCpNameIndexForCust]
                          : { label: "All", value: "" }
                      }
                      options={cpNamesList}
                    />
                  </div>
                  </div>
                  <div
                    style={{
                      marginTop:"21px",
                      float: "right",
                      width: "140px",
                      marginRight: "10px",
                      height: "38px",
                      marginLeft: "10px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      boxShadow: "1px 1px 2px #797979",
                    }}
                  >
                    <Select
                      id="customerDateFilter"
                      isSearchable={false}
                      onChange={(value) => {
                        value.value === "cm"
                          ? setIsDateFilter2(false)
                          : setIsDateFilter2(true);
                      }}
                      defaultValue={dateOptions[0]}
                      options={dateOptions}
                    />
                  </div>
                  {!isDateFilter2 && (
                    <div
                      style={{
                        float: "right",
                        marginRight: "5px",
                      }}
                    >
                      <Flatpickr
                        id="customonthPicker2"
                        className="form-control d-block"
                        style={{
                          marginTop:"21px",
                          width: "150px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                        }}
                        placeholder="Select Month"
                        onChange={(value) => {
                          setCustomDatesForGraph(value, true);
                        }}
                        options={{
                          defaultDate: new Date(start * 1000),
                          maxDate: new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() + 2,
                            0
                          ),
                          minDate: new Date().setMonth(
                            new Date().getMonth() - 6
                          ),

                          plugins: [
                            new monthSelectPlugin({
                              shorthand: true, //defaults to false
                              dateFormat: "F Y", //defaults to "F Y"
                              altFormat: "F Y", //defaults to "F Y"
                              theme: "light", // defaults to "light"
                            }),
                          ],
                        }}
                      />
                    </div>
                  )}
                  {isDateFilter2 && (
                    <div
                      style={{
                        float: "right",
                        marginRight: "5px",
                      }}
                    >
                      <Flatpickr
                        style={{
                          width: "200px",
                          height: "40px",
                          borderColor: "#a0a0a0",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #3a353b",
                          textAlignLast: "center",
                        }}
                        id="range-picker"
                        className="form-control d-block"
                        placeholder="Start & End Date"
                        onChange={(value) => {
                          // console.log(value);
                          if (value.length > 1) {
                            let start = Math.floor(
                              new Date(value[0]).getTime() / 1000
                            );
                            let end = Math.floor(
                              new Date(value[1]).getTime() / 1000 + 86399
                            );
                            setStart(start);
                            setEnd(end);
                            getCustomersList(
                              0,
                              start,
                              end,
                              selectedExeName.userId,
                              "",
                              selectedCpName2,
                              selectedPriority
                            );
                          }
                        }}
                        options={{
                          mode: "range",
                          dateFormat: "d-M-y",
                          maxDate: new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() + 2,
                            0
                          ),
                          minDate: new Date().setMonth(
                            new Date().getMonth() - 6
                          ),
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      marginTop:"21px",
                      float: "right",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      // marginBottom: "6px",
                      marginRight: "5px",
                    }}
                  >
                    <Button
                      id="userAllocationsDownload"
                      style={{
                        margin: "0px 10px 10px  ",
                        marginBottom: "15px",
                        marginRight: "15px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="success btn-rounded"
                      onClick={() => custDownloadData(true)}
                    >
                      <i className="fa fa-download"></i>&nbsp;&nbsp;Download
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "10px",
                      marginRight: "10px",
                      float: "right",
                    }}
                  >
                    <SearchBar
                      placeholder="Search By Name/Mobile/Loan No/Vehicle No"
                      searchKeyword={searchKeyword1}
                    />
                  </div>

                  {userLoginDetails.autoFinanceUser.roleName !== "MANAGER_RA" &&
                    hasAccess(68) && (
                      <div
                        style={{
                          marginBottom: "10px",
                          marginRight: "5px",
                          float: "right",
                        }}
                      >
                        <Button
                          id="customerAllocationTransfer"
                          className="me-2"
                          variant="primary btn-rounded"
                          style={{
                            borderRadius: "5px",
                            float: "right",
                          }}
                          onClick={() => {
                            loadExecutives();
                            setIsTransferAll(true);
                            setTransferModal(true);
                            setSelectedExecutive("");
                          }}
                        >
                          <i className="fas fa-external-link-alt" />
                          &nbsp;&nbsp;Transfer
                        </Button>
                      </div>
                    )}
                  {userLoginDetails.autoFinanceUser.roleName !== "MANAGER_RA" &&
                    hasAccess(69) && (
                      <div
                        style={{
                          marginBottom: "10px",
                          marginRight: "5px",
                          float: "right",
                        }}
                      >
                        <Button
                          id="customerAllocationDelete"
                          className="me-2"
                          variant="danger btn-rounded"
                          style={{
                            borderRadius: "5px",
                            float: "right",
                          }}
                          onClick={() => {
                            if (selectedIds.length === 0) {
                              swal("Please select atleast 1 customer", {
                                icon: "warning",
                                dangerMode: true,
                              });
                            } else {
                              swal({
                                title: "Delete",
                                text: "Are you sure, you want to delete the selected customers?",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((willDelete) => {
                                if (willDelete) {
                                  var payLoad = {
                                    deletecustomerIds: selectedIds,
                                    deletedByUserId:
                                      userLoginDetails.autoFinanceUser.userId,
                                    organizationId:
                                      userLoginDetails.autoFinanceUser
                                        .organizationId,
                                  };
                                  moduleApi
                                    .postData(apiCall.deleteCustomers, payLoad)
                                    .then((response) => {
                                      if (response) {
                                        if (
                                          response?.Message ===
                                          "Deleted Successfully"
                                        ) {
                                          swal("Deleted successfully!", {
                                            icon: "success",
                                          });
                                          setSelectedIds([]);
                                          getCustomersList(
                                            0,
                                            start,
                                            end,
                                            selectedUserId,
                                            "",
                                            selectedCpName2,
                                            selectedPriority
                                          );
                                        } else {
                                          swal(response?.message, {
                                            icon: "error",
                                            dangerMode: true,
                                          });
                                        }
                                      } else {
                                        return swal(
                                          "Something Went Wrong, Please Try Later",
                                          {
                                            icon: "error",
                                            dangerMode: true,
                                          }
                                        );
                                      }
                                    });
                                }
                              });
                            }
                          }}
                        >
                          <i className="fas fa-trash" />
                          &nbsp;&nbsp;<span>Delete</span>
                        </Button>
                      </div>
                    )}
                </div>
                <LoadingOverlay
                  active={isLoading2}
                  text={
                    <p
                      style={{
                        color: "black",
                      }}
                    >
                      <div>
                        <Button
                          style={{
                            backgroundColor: "#FFFF",
                            color: "black",
                            borderColor: "white",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                          variant="primary"
                          disabled
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            variant="primary"
                            role="status"
                            aria-hidden="true"
                          />
                          &nbsp;&nbsp;Loading...
                        </Button>
                      </div>
                    </p>
                  }
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(192,192,192,0.4)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "80px",
                      "& svg circle": {
                        stroke: "rgb(136, 108, 192)",
                      },
                    }),
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      margin: "10px",
                      marginTop: "-5px",
                      overflowX: "auto",
                      overflowY: "auto",
                      maxHeight: "400px",
                    }}
                  >
                    <BootstrapTable
                      keyField="id"
                      data={customersList}
                      columns={customerColumns}
                      selectRow={selectRow}
                      // expandRow={expandRow}
                    />
                  </div>
                  {customersList.length === 0 && (
                    <div className="d-flex justify-content-center">
                      <img src={NO_DATA_FOUND} alt="" />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "0px",
                    }}
                  >
                    <PaginationComponent
                      totalItems={totalElements2}
                      pageSize={10}
                      defaultActivePage={currPage2 + 1}
                      onSelect={onPageChange2}
                    />
                  </div>
                </LoadingOverlay>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className="fade" size="md" show={transferModal}>
            <Modal.Header>
              <Modal.Title>Transfer Customers</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setTransferModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body style={{ padding: "15px" }}>
              <div style={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", height: "40px" }}>
                  <div
                    class="form-check"
                    onClick={() => setIsTransferAll(true)}
                    style={{ margin: "0px 20px" }}
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={isTransferAll}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Transfer All Allocations
                    </label>
                  </div>
                  <div
                    class="form-check"
                    onClick={() => setIsTransferAll(false)}
                    style={{ margin: "0px 20px" }}
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={!isTransferAll}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Transfer Individual Allocation
                    </label>
                  </div>
                </div>
                <label style={{ marginBottom: "0px" }}>
                  <h6>Select Employee</h6>
                </label>
                <Select
                  isSearchable={true}
                  onChange={(value) => {
                    setSelectedExecutive(value);
                  }}
                  options={executivesList2}
                />
                <span style={{ color: "green", marginTop: "5px" }}>
                  <span style={{ fontWeight: "bold" }}>Note : </span>Only
                  Current Month Allocations will be Transferred
                </span>
                <Row>
                  <div>
                    <Button
                      style={{ float: "right", width: "70px" }}
                      type="submit"
                      className="col-lg-2 m-2"
                      onClick={() => transferCustomer()}
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right", width: "70px" }}
                      className="col-lg-2 m-2 float-right"
                      variant="danger light"
                      onClick={() => setTransferModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          <Modal className="fade" size="xl" show={loanNoDetailsModal}>
            <Modal.Header>
              <Modal.Title>Allocation Details</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setLoanNoDetailsModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body style={{ padding: "15px" }}>
              <LoanNumberDetails
                allocationDetails={visitDetails}
                close={handleLoanNumberDetailsModal}
              />
            </Modal.Body>
          </Modal>
          <Modal className="fade" size="md" show={templateDownloadModal}>
            <Modal.Header>
              <Modal.Title>Download Template</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setTemplateDownloadModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body style={{ padding: "15px" }}>
              <div style={{ margin: "10px" }}>
                <Row>
                  <Col>
                    <div
                      style={{
                        marginBottom: "5px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>
                        <h6>Template Type</h6>
                      </label>
                      <div
                        style={{
                          float: "right",
                          width: "250px",
                          height: "38px",
                          borderRadius: "3px",
                          borderWidth: "1px",
                          boxShadow: "1px 1px 2px #797979",
                        }}
                      >
                        <Select
                          isSearchable={false}
                          onChange={(value) => {
                            setSelectedTemplate(value.value);
                          }}
                          placeholder="Select Template Type"
                          // value={executivesList[executiveFilterIndex]}
                          options={fileTypesList}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ marginTop: 35 + "px", marginLeft: "15px" }}
                      onClick={() => {
                        if (selectedTemplate !== null) {
                          downloadAllocationsTemplate();
                        } else {
                          return swal("Please Select Template Type", {
                            icon: "error",
                            dangerMode: true,
                          });
                        }
                      }}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          <Modal className="fade" size="md" show={deleteModal}>
            <Modal.Header>
              <Modal.Title>Delete Allocations</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setDeleteModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body style={{ padding: "15px" }}>
              <div style={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", height: "40px" }}>
                  <div
                    class="form-check"
                    onClick={() => setIsDeleteAll(true)}
                    style={{ margin: "0px 20px" }}
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={isDeleteAll}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Delete All Allocations
                    </label>
                  </div>
                  <div
                    class="form-check"
                    onClick={() => setIsDeleteAll(false)}
                    style={{ margin: "0px 20px" }}
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={!isDeleteAll}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Delete Individual Allocations
                    </label>
                  </div>
                </div>
                <Row>
                  <div>
                    <Button
                      style={{ float: "right", width: "70px" }}
                      type="submit"
                      className="col-lg-2 m-2"
                      onClick={() => deleteAllocations()}
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ float: "right", width: "70px" }}
                      className="col-lg-2 m-2 float-right"
                      variant="danger light"
                      onClick={() => setDeleteModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <CpUsersAllocations />
      )}
      {/* <Modal className="fade" show={cpNamesModal} size="lg">
        <Modal.Header>
          <Modal.Title>Cp Names</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setCpNamesModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <div
              style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
            >
              <Button
                style={{
                  float: "right",
                  margin: "10px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="primary btn-rounded"
                onClick={() => {
                  setAddCpNameModal(true);
                }
                }
              >
                <b> + Add Cp Name</b>
              </Button>

            </div>
            <LoadingOverlay
              active={isLoading}
              // spinner
              text={
                <p
                  style={{
                    color: "black",
                  }}
                >
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#FFFF",
                        color: "black",
                        borderColor: "white",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      variant="primary"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        variant="primary"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading...
                    </Button>
                  </div>
                </p>
              }
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(192,192,192,0.4)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "80px",
                  "& svg circle": {
                    stroke: "rgb(136, 108, 192)",
                  },
                }),
              }}
            >
              <div style={{ background: "white", margin: "10px", marginTop: "-5px" }}>
                <div style={{ overflow: 'auto', maxHeight: '400px' }}>
                  <BootstrapTable
                    keyField="panNo"
                    data={cpNamesData}
                    columns={cpNamesColumns}
                  />
                </div>
                {cpNamesData.length === 0 && (
                  <div className="d-flex justify-content-center">
                    <img src={NO_DATA_FOUND} alt="" />
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <PaginationComponent
                    totalItems={totalElements3}
                    pageSize={10}
                    defaultActivePage={currPage3 + 1}
                    onSelect={onPageChange3}
                  />
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </Modal.Body>
      </Modal> */}
      <Modal className="fade" size="md" show={addCpNameModal}>
        <Modal.Header>
          <Modal.Title>Add CP Name</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddCpNameModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>CP Name</h6>
                </label>
                <input
                  id="cpName"
                  className="form-control"
                  type="text"
                  placeholder="Enter CP Name"
                  name="cpName"
                  onChange={(e) => {
                    setCpName(e.target.value);
                  }}
                  style={{ padding: "11px" }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => addCpName()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => setAddCpNameModal(false)}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" size="md" show={editCpNameModal}>
        <Modal.Header>
          <Modal.Title>Edit CP Name</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditCpNameModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>
                  <h6>CP Name</h6>
                </label>
                <input
                  id="cpName"
                  className="form-control"
                  type="text"
                  placeholder="Enter CP Name"
                  name="cpName"
                  defaultValue={cpName}
                  onChange={(e) => {
                    setCpName(e.target.value);
                  }}
                  style={{ padding: "11px" }}
                />
              </div>
              <div style={{ marginTop: "5px" }}>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="success btn-rounded"
                  type="submit"
                  onClick={() => editCpName()}
                >
                  Submit
                </Button>
                <Button
                  style={{ float: "right" }}
                  className="me-2"
                  variant="danger light btn-rounded"
                  onClick={() => setEditCpNameModal(false)}
                >
                  Close
                </Button>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default UploadDailySchedule;
