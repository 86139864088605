import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { Link } from "react-router-dom";

const VisitsList = () => {
  const [loggedInUser, setloggedInUser] = useState([]);
  const [visitObj, setVisitObj] = useState({});
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [totalElements, setTotalElements] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [isFieldInvstgn, setIsFieldInvstgn] = useState(false);
  const [fieldInvstgtnList, setFieldInvstgtnList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    var userLoginData = localStorage.getItem("userDetails");
    if (!userLoginData) return;
    var userData = JSON.parse(userLoginData);
    setloggedInUser(userData);

    let path = window.location.pathname;

    path = path.split("/");

    var visitType = path[path.length - 1];
    var subStatus = path[path.length - 2].replaceAll("%20", " ");
    var status = path[path.length - 3].replaceAll("%20", " ");
    var reportingOfficerId = path[path.length - 4];
    var end = path[path.length - 5];
    var start = path[path.length - 6];
    var userId = path[path.length - 7];

    var obj = {
      userId: userId,
      start: start,
      end: end,
      visitType: visitType,
      reportingOfficerId,

      status: status === "All" ? "" : status,
      subStatus: subStatus === "All" ? "" : subStatus,
    };

    setVisitObj(obj);

    loadVistedPlacesData(
      userId,
      start,
      end,
      visitType,
      userData,
      0,
      reportingOfficerId,
      status,
      subStatus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Mobile No",
      dataField:
        visitObj.visitType === "Sales"
          ? "salesContactNumber"
          : "collectionsContactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: visitObj.visitType === "Collections" ? "Loan Number" : "",
      dataField: "loanNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Visited By",
      dataField: "name",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visited Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Under Manager",
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (row.collectionVisit?.status === "Partially Paid") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Customer Not Available") {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "") {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.status}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (
            row.collectionVisit?.subStatus === "Cash" ||
            row.collectionVisit?.subStatus === "Bank Transfer" ||
            row.collectionVisit?.subStatus === "Already Settled" ||
            row.collectionVisit?.subStatus === "UPI"
          ) {
            return (
              <span
                className="badge badge-rounded badge-success"
                // style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (row.collectionVisit?.subStatus === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "Customer Not Available"
          ) {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.subStatus}
              </span>
            );
          } else if (
            row.collectionVisit?.subStatus === "" ||
            row.collectionVisit?.subStatus === null
          ) {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.subStatus}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedRow(row);
          setToggle(true);
        },
      },
    },
  ];

  const fieldInvstgncolumns = [
    {
      text: "Employee",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.customerName
          ? row.fieldInvestigationVisit.customerName
          : "-";
      },
    },
    {
      text: "Customer Mobile",
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visited Date",
      dataField: "dateTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Under Manager",
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return (
          <span
            style={{
              fontWeight: "bold",
              color:
                row.fieldInvestigationVisit?.status === "Positive"
                  ? "green"
                  : "red",
            }}
          >
            {row.fieldInvestigationVisit?.status}
          </span>
        );
      },
    },
    {
      text: "Rejection Type",
      dataField: "rejectionType",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",

        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        return row.fieldInvestigationVisit?.rejectionType
          ? row.fieldInvestigationVisit.rejectionType
          : "-";
      },
    },

    {
      text: "",
      dataField: "id",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-success shadow btn-xs sharp">
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // setVisitedPlacesDetailsList(row);
          setSelectedRow(row);

          // setBasicModal(true);
          setToggle("chatbox");
        },
      },
    },
  ];

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };

  const loadVistedPlacesData = (
    userId,
    start,
    end,
    visitType,
    userDetails,
    pageNo,
    reportingOfficerId,
    status,
    subStatus
  ) => {
    var visitedPlacesBody = {
      endTime: end,
      userId: userId,
      startTime: start,
      organizationId: userDetails.autoFinanceUser.organizationId,
      roleName: userDetails.autoFinanceUser.roleName,
      visitType: visitType,
      status:
        visitType === "Collections"
          ? status === "All"
            ? ""
            : status
          : undefined,
      subStatus: subStatus === "All" ? "" : subStatus,
      reportingOfficerId: reportingOfficerId,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeeVisits, pageNo, 10),
        visitedPlacesBody
      )
      .then((response) => {
        var listData = [];
        setCurrPage(pageNo);
        if (visitType !== "FieldInvestigation") {
          response.content.forEach((element) => {
            let data = {
              id: element.id,
              userId: element.userId,
              userModel: element.userModel,

              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              addedByUserId: element.userModel.addedByUserId,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,
              passwordUpdated: element.userModel.passwordUpdated,
              customerLocationChanged: element.customerLocationChanged,
              organizationId: element.organisationId,
              status: element.status,
              comments: element.comments,
              latitude: element.latitude,
              longitude: element.longitude,
              customerLatitude:element.customerLatitude,
              customerLongitude:element.customerLongitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              visitNotDoneAtCustLocation: element.visitNotDoneAtCustLocation,
              dateTime: convertEpochTimeToDate(element.dateTime),
              timeSpent: element.timeSpent,
              timeDiffOfPrevVisit: element.timeDiffOfPrevVisit,
              agDate: element.agDate,
              aggDate: element.aggDate,
              dueDate: element.dueDate,
              vehicleNumber: element.vehicleNumber,
              reportingOfficerId: element.reportingOfficerId,
              reportingOfficerName: element.reportingOfficerName,
              salesVisit: element.salesVisit,
              salesContactNumber: element.salesVisit?.contactNumber,
              collectionVisit: element.collectionVisit,
              loanNumber: element.collectionVisit?.loanNumber,
              collectionsContactNumber: element.collectionVisit?.contactNumber,
              customerName: element.collectionVisit
                ? element.collectionVisit.customerName
                : element.salesVisit.customerName,
              fiAllocationId:element.fiAllocationId,
              fieldInvestigationVisit: element.fieldInvestigationVisit,
              fiAllocation:element.fiAllocation,
              fiCompleteDetail:element.fiCompleteDetail,
            };
            listData.push(data);
          });
          setData(listData);
        } else {
          response?.content?.forEach((element) => {
            let data = {
              userModel: element.userModel,
              id: element.id,
              name: element.userModel.name,
              mobileNumber: element.userModel.mobileNumber,
              email: element.userModel.email,
              UserImage: element.userModel.image,
              branchId: element.userModel.branchId,
              roleName: element.userModel.roleName,
              employeeId: element.userModel.employeeId,
              userStatus: element.userModel.status,
              customerLocationChanged: element.customerLocationChanged,

              organizationId: element.organisationId,
              latitude: element.latitude,
              timeDiffOfPrevVisit: element.timeDiffOfPrevVisit,
              agDate: element.agDate,
              dueDate: element.dueDate,
              vehicleNumber: element.vehicleNumber,
              longitude: element.longitude,
              customerLatitude:element.customerLatitude,
              customerLongitude:element.customerLongitude,
              address: element.address,
              visitType: element.visitType,
              image: element.image,
              dateTime: convertEpochTimeToDate(element.dateTime),
              reportingOfficerId: element.reportingOfficerId,
              reportingOfficerName: element.reportingOfficerName,
              fiAllocationId:element.fiAllocationId,
              fieldInvestigationVisit: element.fieldInvestigationVisit,
              fiAllocation:element.fiAllocation,
              fiCompleteDetail:element.fiCompleteDetail,
              contactNumber: element.fieldInvestigationVisit
                ? element.fieldInvestigationVisit.contactNumber
                : "-",
            };
            listData.push(data);
          });
          // console.log("Visited places list Data", listData);
          setFieldInvstgtnList(listData);
          setIsFieldInvstgn(true);
        }
        setTotalElements(response.totalElements);
      });
  };
  const onPageChange = (selectedPage) => {
    loadVistedPlacesData(
      visitObj.userId,
      visitObj.start,
      visitObj.end,
      visitObj.visitType,
      loggedInUser,
      selectedPage - 1,
      visitObj.reportingOfficerId,
      visitObj.status
    );
  };

  const reLoad = (pageNo) => {
    loadVistedPlacesData(
      visitObj.userId,
      visitObj.start,
      visitObj.end,
      visitObj.visitType,
      loggedInUser,
      pageNo,
      visitObj.reportingOfficerId,
      visitObj.status
    );
  };

  return (
    <Card>
      <Card.Body>
        {isFieldInvstgn ? (
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable
              keyField="panNo"
              data={fieldInvstgtnList}
              columns={fieldInvstgncolumns}
            />
          </div>
        ) : (
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <BootstrapTable keyField="userId" data={data} columns={columns} />
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PaginationComponent
            totalItems={totalElements}
            pageSize={10}
            onSelect={onPageChange}
          />
        </div>
      </Card.Body>
      {toggle && (
        <VisitedPlaceDrawer
          visitedPlacesDetailsList={selectedRow}
          closeDrawer={() => setToggle(false)}
          reLoad={() => reLoad(currPage)}
        />
      )}
    </Card>
  );
};

export default VisitsList;
