import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useHistory } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import { isAdmin } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";

const CollectionsCount = ({
  startDate,
  endDate,
  visitType,
  userDetails,
  handleManagerColumns,
  isDefault,
}) => {
  var history = useHistory();

  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [totalElements, setTotalElements] = useState(1);
  const [mgrId, setMgrId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [collectionType, setCollectionType] = useState("");
  const [collectionTypeIndex, setCollectionTypeIndex] = useState(0);
  const [collectionSubTypeIndex, setCollectionSubTypeIndex] = useState(0);
  const [collectionStatuses, setCollectionStatuses] = useState([]);
  const [collectionSubStatuses, setCollectionSubStatuses] = useState([]);
  const [collectionSubType, setCollectionSubType] = useState("");
  const [isSubStatus, setIsSubStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  useEffect(() => {
    if (isDefault) return;

    if (
      isAdmin() ||
      userDetails.autoFinanceUser.subOrdinateManagersIds.length > 0
    ) {
      setMgrId("");
      getCollectionDropdownOptions();
      loadData(0, "", collectionType, isDefault, collectionSubType.value);
      return;
    }

    setMgrId(userDetails.autoFinanceUser.userId);
    getCollectionDropdownOptions();
    loadData(
      0,
      userDetails.autoFinanceUser.userId,
      collectionType,
      isDefault,
      collectionSubType
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefault]);

  const columns = [
    {
      text: "Managers",
      dataField: "employeeName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text:
        (collectionType === ""
          ? "Visit"
          : collectionType === "Partially Paid"
          ? "Partially Paid"
          : collectionType) + " Count",
      dataField: "count",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleManagerColumns(true);
          setMgrId(row.userId);
          loadData(0, row.userId, collectionType, true, collectionSubType);
        },
      },
    },
  ];

  const executiveColumns = [
    {
      text: "Executives",
      dataField: "employeeName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text:
        (collectionType === ""
          ? "Visit"
          : collectionType === "Partially Paid"
          ? "Partially Paid"
          : collectionType) + " Count",
      dataField: "count",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let path = "/home";
          path += "/";

          path += row.userId;
          path += "/";
          path += startDate;
          path += "/";
          path += endDate;
          path += "/";
          path += mgrId;
          path += "/";
          path += collectionType ? collectionType : "All";
          path += "/";
          path += collectionSubType.value ? collectionSubType.value : "All";
          path += "/";
          path += visitType;

          // console.log(path);

          history.push(path);
        },
      },
    },
  ];

  const managerColumns = [
    {
      text: "Managers",
      dataField: "employeeName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Branch",
      dataField: "branchName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text:
        (collectionType === ""
          ? "Visit"
          : collectionType === "Partially Paid"
          ? "Partially Paid"
          : collectionType) + " Count",
      dataField: "count",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let path = "/home";
          path += "/";
          path += row.userId;
          path += "/";
          path += startDate;
          path += "/";
          path += endDate;
          path += "/";
          path += mgrId;
          path += "/";
          path += collectionType ? collectionType : "All";
          path += "/";
          path += collectionSubType.value ? collectionSubType.value : "All";
          path += "/";
          path += visitType;

          history.push(path);

          // setMgrId(row.userId)
          // loadData(0, row.userId)
          // loadVistedPlacesData(row.userId)
        },
      },
    },
  ];

  const loadData = (
    pageNo,
    userId,
    collectionType,
    isLevelChanged,
    collectionSubType
  ) => {
    let listData = [];
    let listData1 = [];
    let array = [];
    let array1 = [];
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    setShow(false);
    setIsLoading(true);
    var payLoad = {
      endTime: endDate,
      organizationId: userDetails?.autoFinanceUser?.organizationId,
      reportingOfficerId:
        isLevelChanged ||
        userDetails?.autoFinanceUser?.subOrdinateManagersIds?.length === 0
          ? userId
          : "",
      roleName: userDetails?.autoFinanceUser?.roleName,
      startTime: startDate,
      userId: !isAdmin() ? userDetails?.autoFinanceUser?.userId : "",
      status: collectionType ? collectionType : "",
      visitType: visitType,
      subStatus: collectionSubType?.value,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeeVisitsCount, pageNo, 10),
        payLoad
      )
      .then((response) => {
        setCurrPage(pageNo)
        for (var i = 0; i < response?.content.length; i++) {
          response.content[i].status = payLoad.status;
        }
        if (isLevelChanged) {
          setShow(isLevelChanged);
          response?.content.forEach((element) => {
            if (element.roleName === "MANAGER" || element.roleName === "MANAGER_RA" ) {
              let data = {
                employeeName: element.employeeName,
                count: element.count,
                userId: element.userId,
                branchName:element.branchName,
                designation: element.companyDesignation,
              };
              listData.push(data);
              // console.log(listData, "listData");
            } else {
              let data1 = {
                employeeName: element.employeeName,
                count: element.count,
                userId: element.userId,
                branchName:element.branchName,
                designation: element.companyDesignation,
              };
              listData1.push(data1);
              // console.log(listData1, "listData1");
            }
          });
        } else {
          response?.content.forEach((element) => {
            if (element.roleName === "MANAGER" || element.roleName === "MANAGER_RA") {
              let data2 = {
                employeeName: element.employeeName,
                count: element.count,
                userId: element.userId,
                branchName:element.branchName,
                designation: element.companyDesignation,
              };
              array.push(data2);
            } else {
              let data3 = {
                employeeName: element.employeeName,
                count: element.count,
                userId: element.userId,
                branchName:element.branchName,
                designation: element.companyDesignation,
              };
              array1.push(data3);
            }
          });
        }
        setData(listData);
        setData1(listData1);
        setData2(array);
        setData3(array1);
        setTotalElements(response?.totalElements);

        moduleApi
          .postData(apiCall.getemployeevisitscountwithtotalcount, payLoad)
          .then((response) => {
            if (response) setTotalCount(response.totalCount);
          });
        setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    loadData(
      selectedPage - 1,
      mgrId,
      collectionType,
      isDefault,
      collectionSubType
    );
  };

  // const collectionValues = [
  //   { label: "All", value: "", index: 0 },
  //   {
  //     label: "Customer Not Available",
  //     value: "Customer Not Available",
  //     index: 1,
  //   },
  //   { label: "Paid", value: "Paid", index: 2 },
  //   { label: "PTP", value: "PTP", index: 3 },
  //   // { label: "Partially Paid", value: "Partially Paid" , index: 4},
  //   { label: "RTP", value: "RTP", index: 5 },
  //   { label: "Repossessed", value: "Repossessed", index: 6 },
  //   { label: "Vacant", value: "Vacant", index: 7 },
  //   { label: "ThirdParty", value: "ThirdParty", index: 8 },
  // ];

  const getCollectionDropdownOptions = () => {
    var payload = {
      organizationId: userDetails.autoFinanceUser.organizationId,
    };

    moduleApi
      .postData(apiCall.getCollectionsStatus, payload)
      .then((response) => {
        const result = response.map((item, index) => ({
          label: item,
          value: item,
          index: index + 1,
        }));
        result.unshift({ label: "All", value: "", index: 0 });
        // console.log("result", result);

        setCollectionStatuses(result);
      });
  };

  const setCollectionTypeValue = (value) => {
    setCollectionType(value.value);
    loadData(0, mgrId, value.value, isDefault, { label: "All", value: "" });
    setCollectionTypeIndex(value.index);
  };
  const setCollectionSubTypeValue = (value) => {
    setCollectionSubType(value);
    loadData(0, mgrId, collectionType, isDefault, value);
    setCollectionSubTypeIndex(value.index);
  };

  const getSubStatusCollectionOptions = (subStatus) => {
    var payload = {
      organizationId: userDetails.autoFinanceUser.organizationId,
      status: subStatus,
    };

    moduleApi
      .postData(apiCall.getCollectionsStatus, payload)
      .then((response) => {
        const result = response.map((item, index) => ({
          label: item,
          value: item,
          index: index,
        }));
        result.unshift({ label: "All", value: "" });
        if (response.length > 0) {
          if (subStatus === "") {
            setIsSubStatus(false);
          } else {
            setCollectionSubType({ label: "All", value: "" });
            setIsSubStatus(true);
          }
        } else {
          setIsSubStatus(false);
        }
        // console.log('result', result);

        setCollectionSubStatuses(result);
      });
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        {
          // (userDetails.autoFinanceUser.roleName === "MANAGER"
          //   ? isCollectionFilter
          //   : !isDefault)
          !isDefault && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                float: "right",
                // width: "170px",
                marginRight: "20px",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {totalCount >= 1 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "30px",
                    marginTop: "10px",
                  }}
                >
                  <b>Total Visit Count: &nbsp;{totalCount}</b>
                </div>
              ) : null}
              <div style={{ width: "150px" }}>
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    setCollectionTypeValue(value);
                    getSubStatusCollectionOptions(value.value);
                  }}
                  defaultValue={
                    collectionStatuses.length > 0
                      ? collectionStatuses[collectionTypeIndex]
                      : { label: "All", value: "", index: 0 }
                  }
                  options={collectionStatuses}
                  id="collectionStatusCount"
                />
              </div>
              {isSubStatus && (
                <div style={{ width: "150px", marginLeft: "10px" }}>
                  <Select
                    isSearchable={false}
                    onChange={(value) => {
                      setCollectionSubTypeValue(value);
                    }}
                    defaultValue={
                      collectionSubStatuses.length > 0
                        ? collectionSubStatuses[collectionSubTypeIndex]
                        : { label: "All", value: "", index: 0 }
                    }
                    value={collectionSubType}
                    options={collectionSubStatuses}
                    id="collectionSubstatusCount"
                  />
                </div>
              )}
            </div>
          )
        }

        {data.length >= 1 ? (
          <BootstrapTable
            keyField="count"
            data={data}
            columns={managerColumns}
            id="managercolumnscollectioncount"
          />
        ) : null}
        {data1.length >= 1 && show ? (
          <BootstrapTable
            keyField="employeeName"
            data={data1}
            columns={executiveColumns}
            id="executivecollectioncount"
          />
        ) : null}
        {data2.length >= 1 ? (
          <BootstrapTable
            keyField="count"
            data={data2}
            columns={
              isDefault ||
              (!isAdmin() &&
                userDetails.autoFinanceUser.subOrdinateManagersIds.length === 0)
                ? managerColumns
                : columns
            }
            id="subordinatemanagercollectioncount"
          />
        ) : null}
        {data3.length >= 1 ? (
          <BootstrapTable
            keyField="count"
            data={data3}
            columns={executiveColumns}
            id="subordinateexecutivecollectioncount"
          />
        ) : null}
        {data2.length === 0 && data3.length === 0 && !show ? (
          <div>
            <img src={NO_DATA_FOUND} alt="" />
          </div>
        ) : null}
        <div style={{ display: "flex", justifyContent: "left" }}>
          <PaginationComponent
            totalItems={totalElements}
            pageSize={10}
            defaultActivePage={currPage + 1}
            onSelect={onPageChange}
          />
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default CollectionsCount;
