import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import PaginationComponent from "react-reactstrap-pagination";
// import * as helper from "../CommonComponents/Helper";
import NO_DATA_FOUND from "../images/no-data-found.png";
import { Button, Modal ,Row} from "react-bootstrap";
import VisitLocationMap from "../CommonComponents/VisitLocationMap";
import { apiCall } from "../rest/restApi";
import * as moduleApi from "../rest/moduleApi";
import LoadingOverlay from "react-loading-overlay";
import { getTokenInLocalStorage } from "../services/AuthService";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";

function VisitDetailsHistory({  loanNumber,visitType,custName,custNumber,applicationNo}){
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [isMap, setIsMap] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSalesHistory, setIsSalesHistory] = useState(false);
  const [isFiHistory, setIsFiHistory] = useState(false);
  const [visitDetails, setVisitDetails] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [basicModal2, setBasicModal2] = useState(false);
  const [visitHistoryDownloadList, setVisitHistoryDownloadList] = useState(null);

  useEffect(() => {
    ReactGA.pageview("Customers-Visit-History");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertEpochTimeToDate = (epochTime) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date_str = "";
    var date = new Date(parseInt(epochTime) * 1000);
    var day = date.getDate();
    var month = months_arr[date.getMonth()];
    var year = date.getFullYear();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var am_pm = h >= 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    date_str =
      month +
      " " +
      day +
      "," +
      year +
      "," +
      " " +
      h +
      ":" +
      m +
      ":" +
      s +
      " " +
      am_pm;
    return date_str;
  };
  useEffect(() => {
    loadVistedHistory(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userData = getTokenInLocalStorage();
  const loadVistedHistory = (pageNo) => {
    setIsLoading(true);
    setHistoryData([]);

    if(visitType==="Sales"){
      setIsSalesHistory(true);
    }else if(visitType==="FieldInvestigation"){
      setIsFiHistory(true)
    }

    // if (loanNumber) {
      let payLoad = {
        loanNumber: loanNumber,
        visitType:visitType,
        orgId: userData.autoFinanceUser.roleName !== "CP_USER"
        ? userData.autoFinanceUser.organizationId
        : userData.autoFinanceOrganization.mainOrganizationId,
        customerName:visitType!=="Sales"?"":custName,
        contactNumber:visitType!=="Sales"?"":custNumber,
        applicationNo:visitType!=="FieldInvestigation"?"":applicationNo,
      };
      moduleApi
        .postData(
          moduleApi.withPage(apiCall.getCustomerVisitHistory, pageNo, 10),
          payLoad
        )
        .then((response) => {
          // setCurrPage(pageNo)
          // console.log(response);
          var listData = [];
          // let userIds = [];
          // response.content.forEach((element) => {
          //   userIds.push(element.autoFinanceUser.userId)
          // })
          //   let m = new Map()
          // moduleApi.postData(apiCall.getLiveLocations, userIds)
          //   .then((response2) => {
          //     response2.forEach((item) => {
          //       m.set(item.userId, item);
          //     })
          //   })
          response?.content?.forEach((element) => {
            let data = {
              empName: element.empName,
              empMobileNumber: element.empMobileNumber,
              status: element.status ? element.status : "-",
              subStatus: element.subStatus ? element.subStatus : "-",
              amountPaid: element.amountPaid?element.amountPaid:'-',
              address: element.address?element.address:'-',
              comments: element.comments ? element.comments : "-",
              lastUpdatedAt: element.dateTime
                ? convertEpochTimeToDate(element.dateTime)
                : 0,
              latitude: element.latitude,
              longitude: element.longitude,
              visitLocation: element.latitude + "," + element.longitude,
              paymentDateBy: element.paymentDateBy,
              productName:element.productName?element.productName:"-",
              approvalStatus:element.approvalStatus?element.approvalStatus:"-",
              approvedByName:element.approvedByName?element.approvedByName:"-",
              visitId:element.visitId,
              rejectionReason:element.rejectionReason?element.rejectionReason:"-",
              paymentMode:element.paymentMode ? element.paymentMode :"-",
              paymentStatus:element.paymentStatus ? element.paymentStatus :"-"
            };
            listData.push(data);
          });
          setHistoryData(listData);
          setTotalElements(response.totalElements);
          setIsLoading(false);
        });
    // }
  };

  useEffect(() => {
    visitHistoryDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitHistoryDownloadList]);

  const visitsHistoryDownloadData = () => {
    setIsLoading(true);

    let payLoad = {
      loanNumber: loanNumber,
      visitType:visitType,
      orgId: userData.autoFinanceUser.roleName !== "CP_USER"
      ? userData.autoFinanceUser.organizationId
      : userData.autoFinanceOrganization.mainOrganizationId,
      customerName:visitType!=="Sales"?"":custName,
      contactNumber:visitType!=="Sales"?"":custNumber,
      applicationNo:visitType!=="FieldInvestigation"?"":applicationNo,
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCustomerVisitHistory, 0, 10),
        payLoad
      )
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                setIsLoading(true);
                getVisitHistoryFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getVisitHistoryFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  async function getVisitHistoryFullResponseData(size) {
    var tempList = [];
    var mgrListData = [];
    for (let i = 0; i <= size; i++) {
      let payLoad = {
        loanNumber: loanNumber,
        visitType:visitType,
        orgId: userData.autoFinanceUser.roleName !== "CP_USER"
        ? userData.autoFinanceUser.organizationId
        : userData.autoFinanceOrganization.mainOrganizationId,
        customerName:visitType!=="Sales"?"":custName,
        contactNumber:visitType!=="Sales"?"":custNumber,
        applicationNo:visitType!=="FieldInvestigation"?"":applicationNo,
      };

      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getCustomerVisitHistory, i, 100),
          payLoad
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
              empName: element.empName,
              empMobileNumber: element.empMobileNumber,
              status: element.status ? element.status : "-",
              subStatus: element.subStatus ? element.subStatus : "-",
              amountPaid: element.amountPaid?element.amountPaid:'-',
              address: element.address?element.address:'-',
              comments: element.comments ? element.comments : "-",
              lastUpdatedAt: element.dateTime
                ? convertEpochTimeToDate(element.dateTime)
                : 0,
              latitude: element.latitude,
              longitude: element.longitude,
              visitLocation: element.latitude + "," + element.longitude,
              paymentDateBy: element.paymentDateBy,
              productName:element.productName?element.productName:"-",
              approvalStatus:element.approvalStatus?element.approvalStatus:"-",
              approvedByName:element.approvedByName?element.approvedByName:"-",
              visitId:element.visitId,
              rejectionReason:element.rejectionReason?element.rejectionReason:"-",
              paymentMode:element.paymentMode ? element.paymentMode :"-",
              paymentStatus:element.paymentStatus ? element.paymentStatus :"-"
      };
      mgrListData.push(data);
    });
    setVisitHistoryDownloadList(mgrListData);
  }


  const handleExport = () => {

    let collectionsTable = [
      {
        A: "Executive Name",
        B: "Executive Mobile No",
        C: "Status",
        D: "Sub Status",
        E: "Amount Paid",
        F: "Payment Mode",
        G: "Payment Status",
        H: "Address",
        I: "Comments",
        J:"Visit Date",
        K:"Visit Latitude & Longitude"
      },
    ];

    let salesTable = [
      {
        A: "Executive Name",
        B: "Executive Mobile No",
        C: "Address",
        D: "Product Name",
        E: "Comments",
        F: "Visit Date",
        G:"Visit Latitude & Longitude",
      },
    ];

    let fiTable = [
      {
        A: "Executive Name",
        B: "Executive Mobile No",
        C: "Status",
        D: "Address",
        E: "Comments",
        F: "Visit Date",
        G:"Visit Latitude & Longitude",
        H:"Approval Status"
      },
    ];

    if(isSalesHistory){

      visitHistoryDownloadList?.forEach((row) => {
        salesTable.push({
          A: row.empName,
          B: row.empMobileNumber,
          C: row.address,
          D: row.productName,
          E: row.comments,
          F: row.lastUpdatedAt,
          G:row.visitLocation,
        });
      });

    }else if(isFiHistory){

      visitHistoryDownloadList?.forEach((row) => {
        fiTable.push({
          A: row.empName,
          B: row.empMobileNumber,
          C: row.status,
          D: row.address,
          E: row.comments,
          F: row.lastUpdatedAt,
          G:row.visitLocation,
          H: row.approvalStatus
        });
      });

    }else{

    visitHistoryDownloadList?.forEach((row) => {
      collectionsTable.push({
        A: row.empName,
        B: row.empMobileNumber,
        C: row.status,
        D: row.subStatus,
        E: row.amountPaid,
        F: row.paymentMode,
        G: row.paymentStatus,
        H:row.address,
        I:row.comments,
        J:row.lastUpdatedAt,
        K:row.visitLocation
      });
    });

  }

    const finalData = isSalesHistory ? [...salesTable] : isFiHistory ?[...fiTable] : [...collectionsTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Visits History");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
     "Customer_Visit_History_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "Visit History List Downlaod",
      action:
        userData.autoFinanceOrganization.orgshortcode +
        "_Customer_Visit_History_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const updateFiApprovalStatus = (status) => {
    console.log("status", status);
    if (status === "REJECTED" && rejectionReason === null) {
      return swal("Please Enter Rejection Reason", {
        icon: "error",
        dangerMode: true,
      });
    }

    var body = {
      approvalStatus: status,
      rejectionReason: rejectionReason,
      visitId: visitDetails.visitId,
      approvedBy:userData.autoFinanceUser.userId,
    };

    moduleApi
      .postData(apiCall.updateFiApprovalStatus, body)
      .then((response) => {
        if (response) {
          if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          } else if (response?.Message === "FI Status Updated Successfully") {
            setBasicModal(false);
            loadVistedHistory(0);
            //close();
             setBasicModal2(false);
            // getCashDepositionList(start, end, 0);
            swal("Status Updated Successfully", {
              icon: "success",
            });
          }
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const Historycolumns = [
    {
      text: "Executive Name",
      dataField: "empName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Executive Mobile No",
      dataField: "empMobileNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      // formatter: (cell, row) => {
      //   return (
      //     <span>
      //       {row.status === "PTP"
      //         ? row.status + "(" + row.paymentDateBy + ")"
      //         : row.status}
      //     </span>
      //   );
      // },
    },
    {
      text: "Sub Status",
      dataField: "subStatus",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      // formatter: (cell, row) => {
      //   return (
      //     <span>
      //       {row.status === "PTP"
      //         ? row.status + "(" + row.paymentDateBy + ")"
      //         : row.status}
      //     </span>
      //   );
      // },
    },
    {
      text: "Amount Paid",
      dataField: "amountPaid",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return row.status === "Paid" ||
        row.status === "PARTIALLY PAID" ||
        row.status === "Partially Paid" ||
        row.status === "Payment Pending"||
        row.status === "Payment Failed" ||
        row.status==="Payment Cancelled"  ? (
          <>{row.amountPaid} ₹</>
        ) : (
          "-"
        );
      },
    },
    {
      text: "Payment Mode",
      dataField: "paymentMode",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return row.status === "Paid" ||
          row.status === "PARTIALLY PAID" ||
          row.status === "Partially Paid" ||
          row.status === "Payment Pending"||
          row.status === "Payment Failed" ||
          row.status==="Payment Cancelled" ? (
          <>{row.paymentMode}</>
        ) : (
          "-"
        );
      },
    },
    {
      text: (
        <div>
          <span>Payment</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "paymentStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        if (
          row.paymentStatus === "PAYMENT_SUCCESS" ||
          row.paymentMode === "Other" ||
          row.paymentMode === "Bank Transfer" ||
          row.paymentMode === "UPI"||
          row.paymentMode === "Cash"
        ) {
          return (
            <span className="badge badge-rounded badge-success">Success</span>
          );
        }else if (row.paymentStatus === "PAYMENT_CANCELLED") {
          return (
            <span className="badge badge-rounded badge-danger">Cancelled</span>
          );
        } else if (
          row.paymentStatus === "PAYMENT_ERROR" ||
          row.paymentStatus === "FAILED"
        ) {
          return (
            <span className="badge badge-rounded badge-danger">Failed</span>
          );
        } else if (row.paymentStatus === "TRANSACTION_NOT_FOUND") {
          return (
            <span className="badge badge-rounded badge-danger">
              Transaction Not Found
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: "Address",
      dataField: "address",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Comments",
      dataField: "comments",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Date",
      dataField: "lastUpdatedAt",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
          <div>
            <span>Visit Latitude &</span>
            <br></br>
            <span>Longitude</span>
          </div>
        ),
      dataField: "visitLocation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.latitude) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ cursor: "pointer" }}>{`${row.latitude}, ${row.longitude}`}</p>
              <span
                style={{
                  color: "green",
                  margin: "0px 15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setLatLng({ lat: row.latitude, lng: row.longitude });
                  setIsMap(true);
                }}
              >
                <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
              </span>
            </div>
          );
        } else {
          return <>-</>;
        }
      },
    },
  ];

  const fiHistorycolumns = [
    {
      text: "Executive Name",
      dataField: "empName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Executive Mobile No",
      dataField: "empMobileNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Address",
      dataField: "address",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Comments",
      dataField: "comments",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Date",
      dataField: "lastUpdatedAt",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
          <div>
            <span>Visit Latitude &</span>
            <br></br>
            <span>Longitude</span>
          </div>
        ),
      dataField: "visitLocation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.latitude) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ cursor: "pointer" }}>{`${row.latitude}, ${row.longitude}`}</p>
              <span
                style={{
                  color: "green",
                  margin: "0px 15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setLatLng({ lat: row.latitude, lng: row.longitude });
                  setIsMap(true);
                }}
              >
                <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
              </span>
            </div>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      text: (
        <div>
          <span>Approval</span>
          <br></br>
          <span>Status</span>
        </div>
      ),
      dataField: "approvalStatus",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.approvalStatus === "APPROVED") {
          return (
            <span className="badge badge-rounded badge-success">Approved</span>
          );
        } else if (row.approvalStatus === "REJECTED") {
          return (
            <span className="badge badge-rounded badge-danger">Rejected</span>
          );
        } else if (row.approvalStatus === "PENDING") {
          return (
            <span
              className="badge badge-rounded badge-warning"
              style={{ color: "black" }}
            >
              Pending
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      text: (
        <div>
          <span>Approve /</span>
          <br></br>
          <span>Reject</span>
        </div>
      ),
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setVisitDetails(row);
          setBasicModal(true);
        },
      },
    },
  ];

  const saleHistorycolumns = [
    {
      text: "Executive Name",
      dataField: "empName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Executive Mobile No",
      dataField: "empMobileNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Address",
      dataField: "address",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Product Name",
      dataField: "productName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Comments",
      dataField: "comments",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visit Date",
      dataField: "lastUpdatedAt",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
          <div>
            <span>Visit Latitude &</span>
            <br></br>
            <span>Longitude</span>
          </div>
        ),
      dataField: "visitLocation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.latitude) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ cursor: "pointer" }}>{`${row.latitude}, ${row.longitude}`}</p>
              <span
                style={{
                  color: "green",
                  margin: "0px 15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setLatLng({ lat: row.latitude, lng: row.longitude });
                  setIsMap(true);
                }}
              >
                <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
              </span>
            </div>
          );
        } else {
          return <>-</>;
        }
      },
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    loadVistedHistory(selectedPage - 1);
  };
  return (
    <LoadingOverlay
      active={isLoading}
      // spinner
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading...
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div style={{ overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    marginBottom: "5px",
                    marginRight: "20px",
                  }}>

      <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              // marginTop: "10px",
              marginBottom: "5px",
              marginRight: "20px",
            }}
          >
        
            <Button
              id="visitHistoryDownlaod"
              style={{
                marginRight: "10px",
                marginLeft: "7px",
                marginBottom: "5px",
                fontFamily:
                  " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
              }}
              className="me-2"
              variant="success btn-rounded"
              onClick={() => visitsHistoryDownloadData()}
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Download
            </Button>
          </div>
        
        {isFiHistory ? (
          <div
            style={{
              background: "white",
              overflowX: "auto",
              overflowY: "auto",
              maxHeight: "400px",
            }}
          >
            <BootstrapTable
              keyField="name"
              data={historyData}
              columns={fiHistorycolumns}
            />
          </div>

        ):(
        <div
          style={{
            background: "white",
            overflowX: "auto",
            overflowY: "auto",
            maxHeight: "400px",
          }}
        >
          {isSalesHistory ? (
            <BootstrapTable
              keyField="name"
              data={historyData}
              columns={saleHistorycolumns}
            />
          ) : (
            <BootstrapTable
              keyField="name"
              data={historyData}
              columns={Historycolumns}
            />
          )}
        </div>
        )}
        {historyData.length === 0 && (
          <div className="d-flex justify-content-center">
            <img src={NO_DATA_FOUND} alt="" />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: totalElements === 0 ? "90px 0px 5px 0px" : "0px",
          }}
        >
          <PaginationComponent
            totalItems={totalElements}
            pageSize={10}
            defaultActivePage={currPage + 1}
            onSelect={onPageChange}
          />
        </div>
        <Modal className="fade" show={isMap}>
          <Modal.Header>
            <Modal.Title>Visit Location</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setIsMap(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
          </Modal.Body>
        </Modal>
        <Modal className="fade" size="md" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Visit Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}></Modal.Body>
        <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Executive Name
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.empName
                ? visitDetails?.empName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
            Mobile No
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.empMobileNumber
                ? visitDetails?.empMobileNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.status ? visitDetails?.status : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approval Status
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.approvalStatus ? visitDetails?.approvalStatus : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Approved/Rejected By
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.approvedByName ? visitDetails?.approvedByName : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
             Rejection Reason
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.rejectionReason ? visitDetails?.rejectionReason : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Comments
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.comments ? visitDetails?.comments : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Last Updated
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.lastUpdatedAt ? visitDetails?.lastUpdatedAt : "-"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "500",
                float: "left",
                textAlign: "left",
              }}
            >
              Address
            </td>
            <td style={{ verticalAlign: "middle" }}>
              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
            </td>
            <td
              style={{
                fontWeight: "bold",
                float: "left",
                textAlign: "left",
              }}
            >
              {visitDetails?.address ? visitDetails?.address : "-"}
            </td>
          </tr>
          {visitDetails?.approvalStatus === "PENDING" && (
            <div>
              <Button
                style={{
                  float: "left",
                  marginTop: "10px",
                  marginRight: "25px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => updateFiApprovalStatus("APPROVED")}
              >
                Approve
              </Button>
              <Button
                style={{
                  float: "left",
                  marginTop: "10px",
                  marginRight: "15px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="danger btn-rounded"
                onClick={() => {
                  setRejectionReason(null);
                  setBasicModal2(true);
                }}
              >
                Reject
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal className="fade" show={basicModal2} size="sm">
        <Modal.Header>
          <Modal.Title>Add Rejection Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal2(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div style={{ margin: "10px" }}>
            <Row>
              <label style={{ fontWeight: "bold" }}>Reason For Rejection</label>
              <input
                style={{
                  width: "220px",
                  height: "35px",
                  borderRadius: "25px",
                  borderColor: "#a0a0a0",
                  borderWidth: "1px",
                  boxShadow: "1px 1px 2px #3a353b",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Enter Reason"
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                  console.log("rejectionReason", rejectionReason);
                }}
              />
              <div style={{ marginTop: "5px" }}>
                <div>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2"
                    type="submit"
                    onClick={() =>
                      updateFiApprovalStatus("REJECTED")
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ float: "right", width: "70px" }}
                    className="col-lg-2 m-2 float-right"
                    variant="danger light"
                    onClick={() => setBasicModal2(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    </LoadingOverlay>
  );
}

export default VisitDetailsHistory;
