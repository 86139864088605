import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";
import "./index.css";
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";
import Setting from "./layouts/Setting";
import Login from "./pages/Login";
import "./step.css";

import AgencyAllocations from "../FieldInvManagement/Agency";
import AgencyProfile from "./components/AppsMenu/AppProfile/AgencyProfile";

const AgencyRoutes = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    { url: "fi-allocations", component: AgencyAllocations },
    { url: "app-profile", component: AgencyProfile },
    { url: "page-login", component: Login },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}
        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
              <Route render={() => <Redirect to={{ pathname: "/fi-allocations" }} />} />
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default AgencyRoutes;
