import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { getTokenInLocalStorage, isAdmin } from "../services/AuthService";
import NO_DATA_FOUND from "../images/no-data-found.png";
import { EpochToDate, secondsToHms } from "../CommonComponents/Helper";
import LoadingOverlay from "react-loading-overlay";
import { Button, Spinner } from "react-bootstrap";
// import VisitLocationMap from "../CommonComponents/VisitLocationMap";
// import { Button, Modal } from "react-bootstrap";

const LoginList = ({ status, handleManagerColumns, isDefault }) => {
  var userDetails = getTokenInLocalStorage();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [totalElements, setTotalElements] = useState(1);
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  // const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  // const [isMap, setIsMap] = useState(false);

  //   useEffect(() => {
  //     if (isDefault) return;

  //     if (
  //       isAdmin() ||
  //       userDetails.autoFinanceUser.subOrdinateManagersIds.length > 0
  //     ) {
  //       setMgrId("");
  //       loadData(0, "", isDefault);
  //       return;
  //     }

  //     setMgrId(userDetails.autoFinanceUser.userId);
  //     loadData(0, userDetails.autoFinanceUser.userId, isDefault);

  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [isDefault]);

  useEffect(() => {
    loadData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const executiveColumns = [
    {
      text: "Emp Id",
      dataField: "employeeId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "employeeName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
        text: "Mobile No",
        dataField: "empMobileNumber",
        align: "center",
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "rgb(243,240,249)",
          fontWeight: "bold",
          verticalAlign: "middle",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
        style: {
          borderColor: "white",
          fontFamily:
            " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
        },
      },
    // {
    //   text: "Branch",
    //   dataField: "branchName",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    // },
    {
      text: "Designation",
      dataField: "designation",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Login</span>
          <br></br>
          <span>Time</span>
        </div>
      ),
      dataField: "loginTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: (
    //     <div>
    //       <span>First Check In</span>
    //       <br></br>
    //       <span>Location</span>
    //     </div>
    //   ),
    //   dataField: "checkInAddress",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     return row.checkInAddress === null || row.checkInAddress === "" ? (
    //       "-"
    //     ) : (
    //       <div style={{ display: "flex", justifyContent: "center" }}>
    //         <p style={{ cursor: "pointer" }}>{row.checkInAddress}</p>
    //         {row.checkInAddress !== "-" && (
    //           <span
    //             style={{
    //               color: "green",
    //               margin: "0px 15px",
    //               cursor: "pointer",
    //               alignSelf: "center",
    //             }}
    //             onClick={() => {
    //               setLatLng({
    //                 lat: row.checkInLatitude,
    //                 lng: row.checkInLongitude,
    //               });
    //               setIsMap(true);
    //             }}
    //           >
    //             <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
    //           </span>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
        text: (
            <div>
              <span>Logout</span>
              <br></br>
              <span>Time</span>
            </div>
          ),
      dataField: "logoutTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    // {
    //   text: (
    //     <div>
    //       <span>Last Check Out</span>
    //       <br></br>
    //       <span>Location</span>
    //     </div>
    //   ),
    //   dataField: "checkOutAddress",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: {
    //     backgroundColor: "rgb(243,240,249)",
    //     fontWeight: "bold",
    //     verticalAlign: "middle",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   style: {
    //     borderColor: "white",
    //     fontFamily:
    //       " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    //   },
    //   formatter: (cell, row) => {
    //     return row.checkOutAddress === null || row.checkOutAddress === "" ? (
    //       "-"
    //     ) : (
    //       <div style={{ display: "flex", justifyContent: "center" }}>
    //         <p style={{ cursor: "pointer" }}>{row.checkOutAddress}</p>
    //         {row.checkOutAddress !== "-" && (
    //           <span
    //             style={{
    //               color: "green",
    //               margin: "0px 15px",
    //               cursor: "pointer",
    //               alignSelf: "center",
    //             }}
    //             onClick={() => {
    //               setLatLng({
    //                 lat: row.checkOutLatitude,
    //                 lng: row.checkOutLongitude,
    //               });
    //               setIsMap(true);
    //             }}
    //           >
    //             <i className="fas fa-map-marker-alt fa-2x fa-rotate-0" />
    //           </span>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      text: (
        <div>
          <span>Working</span>
          <br></br>
          <span>Hours</span>
        </div>
      ),
      dataField: "timeDifference",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Reporting</span>
          <br></br>
          <span>Officer Id</span>
        </div>
      ),
      dataField: "reportingOfficerEmpId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Reporting</span>
          <br></br>
          <span>Officer Name</span>
        </div>
      ),
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "",
      dataField: "userId",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Link to="#" className="btn btn-info shadow btn-xs sharp">
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        );
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          getCheckInAndCheckOutCounts(row);
        },
      },
    },
  ];

  const loadData = (pageNo) => {
    // console.log(pageNo, userId, isLevelChanged, "params");
    setIsLoading(true)
    setData([]);
    let array = [];

    var payLoad = {
      organizationId: userDetails?.autoFinanceUser?.organizationId,
      reportingOfficerId: !isAdmin()
        ? userDetails?.autoFinanceUser?.userId
        : "",
      status: status,
    };

    moduleApi
      .postData(
        moduleApi.withPage(
          apiCall.getEmpAtdnceBasedOnCrntDayStatus,
          pageNo,
          10
        ),
        payLoad
      )
      .then((response) => {
        setCurrPage(pageNo);
        response?.content.forEach((element) => {
          let data = {
            id: element.id ? element.id : "-",
            userId: element.userId ? element.userId : "-",
            organizationId: element.organizationId
              ? element.organizationId
              : "-",
            loginTime: element.loginTime ? EpochToDate(element.loginTime) : "-",
            lastCheckInTime: element.lastCheckInTime
              ? element.lastCheckInTime
              : "-",
            lastCheckOutTime: element.lastCheckOutTime
              ? element.lastCheckOutTime
              : "-",
            logoutTime: element.logoutTime
              ? EpochToDate(element.logoutTime)
              : "-",
            timeDifference:
              element.timeDifference === 0
                ? "00:00:00 H:M:S"
                : secondsToHms(element.timeDifference),
            actualWorkingHours: element.actualWorkingHours
              ? element.actualWorkingHours
              : "-",
            phoneModel: element.phoneModel ? element.phoneModel : "-",
            phoneOs: element.phoneOs ? element.phoneOs : "-",
            phoneMake: element.phoneMake ? element.phoneMake : "-",
            branchId: element.branchId ? element.branchId : "-",
            breakType: element.breakType ? element.breakType : "-",
            date: element.date ? element.date : "-",
            employeeAttendenceStatus: element.employeeAttendenceStatus
              ? element.employeeAttendenceStatus
              : "-",
            employeeCheckInAndCheckouts: element.employeeCheckInAndCheckouts,
            employeeId: element.employeeId ? element.employeeId : "-",
            employeeName: element.employeeName ? element.employeeName : "-",
            empMobileNumber: element.empMobileNumber
              ? element.empMobileNumber
              : "-",
            userStatus: element.userStatus ? element.userStatus : "-",
            designation: element.designation ? element.designation : "-",
            branchName: element.branchName ? element.branchName : "-",
            appversion: element.appversion ? element.appversion : "-",
            salesCount: element.salesCount ? element.salesCount : "-",
            collectionCount: element.collectionCount
              ? element.collectionCount
              : "-",
            fieldInvestigationCount: element.fieldInvestigationCount
              ? element.fieldInvestigationCount
              : "-",
            distanceTravlled: element.distanceTravlled
              ? element.distanceTravlled
              : "-",
            amountCollected: element.amountCollected
              ? element.amountCollected
              : "-",
            expense: element.expense ? element.expense : "-",
            checkInLatitude: element.checkInLatitude
              ? element.checkInLatitude
              : "-",
            checkInLongitude: element.checkInLongitude
              ? element.checkInLongitude
              : "-",
            checkOutLatitude: element.checkOutLatitude
              ? element.checkOutLatitude
              : "-",
            checkOutLongitude: element.checkOutLongitude
              ? element.checkOutLongitude
              : "-",
            checkInAddress: element.checkInAddress
              ? element.checkInAddress
              : "-",
            checkOutAddress: element.checkOutAddress
              ? element.checkOutAddress
              : "-",
            odrDistance: element.odrDistance ? element.odrDistance : "-",
            createdAt: element.createdAt ? element.createdAt : "-",
            updatedAt: element.updatedAt ? element.updatedAt : "-",
            outOfGeofence: element.outOfGeofence ? element.outOfGeofence : "-",
            reportingOfficerEmpId: element.reportingOfficerEmpId ? element.reportingOfficerEmpId : "-",
            reportingOfficerName: element.reportingOfficerName ? element.reportingOfficerName : "-",
          };
          array.push(data);
        });

        setData(array);
        setTotalElements(response?.totalElements);
        setIsLoading(false)

        // setIsLoading(false);
      });
  };

  const onPageChange = (selectedPage) => {
    // console.log(selectedPage - 1, mgrId, isDefault);
    loadData(selectedPage - 1);
  };

  const getCheckInAndCheckOutCounts = (row) => {
    if (!row?.employeeCheckInAndCheckouts) setAttendanceDetails([]);

    let attendanceDetailsListData = [];
    row?.employeeCheckInAndCheckouts?.forEach((data) => {
      let detailsData = {
        checkInTime:
          data.checkInTime === 0 ? "--" : EpochToDate(data.checkInTime),
        checkOutTime:
          data.checkOutTime === 0 ? "--" : EpochToDate(data.checkOutTime),
        date: row.date,
      };
      attendanceDetailsListData.push(detailsData);
    });
    setAttendanceDetails(attendanceDetailsListData);

    handleManagerColumns(true);
  };

  const DetailsColumns = [
    {
      text: "Date",
      dataField: "date",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Check In Time",
      dataField: "checkInTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Check Out Time",
      dataField: "checkOutTime",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
  ];

  return (
    <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
    <div>
      {!isDefault ? (
        <div style={{ background: "white", margin: "10px", overflowX: "auto" }}>
          {/* {totalPtpCount >= 1 ? (
                <div
                  style={{
                    float:'right',
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "30px",
                    marginTop: "10px",
                    marginBottom:'5px'
                  }}
                >
                  <b>Total Count: &nbsp;{totalPtpCount}</b>
                </div>
              ) : null} */}
          <div style={{ overflow: "auto", maxHeight: "400px" }}>
            {data.length >= 1 ? (
              <BootstrapTable
                keyField="id"
                data={data}
                columns={executiveColumns}
                id="logincount"
              />
            ) : null}
          </div>
          {data.length === 0 ? (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          ) : null}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </div>
      ) : (
        <div style={{ background: "white", margin: "10px", overflowX: "auto" }}>
          <div style={{ overflow: "auto", maxHeight: "400px" }}>
            <BootstrapTable
              keyField="panNo"
              data={attendanceDetails}
              columns={DetailsColumns}
            />
          </div>
          {attendanceDetails.length === 0 ? (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          ) : null}
        </div>
      )}
      {/* <Modal className="fade" show={isMap}>
        <Modal.Header>
          <Modal.Title>Location</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsMap(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <VisitLocationMap latitide={latLng.lat} longitude={latLng.lng} />
        </Modal.Body>
      </Modal> */}
    </div>
    </LoadingOverlay>
  );
};

export default LoginList;
