import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import * as moduleApi from "../rest/moduleApi";
import PaginationComponent from "react-reactstrap-pagination";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import {
  getTokenInLocalStorage,
  isAdmin,
} from "../services/AuthService";
import { apiCall } from "../rest/restApi";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import ReactGA from "react-ga";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import { Link } from "react-router-dom";
import VisitedPlaceDrawer from "../CommonComponents/VisitedPlaceDrawer";
import SearchBar from "../CommonComponents/SearchBar";

const EmployeeLedger = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [employeeLedgerList, setEmployeeLedgerList] = useState([]);
  const [employeeLedgerDownloadList, setEmployeeLedgerDownloadList] = useState(null);
  const [cashVisits, setCashVisits] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [totalElements2, setTotalElements2] = useState(1);
  const [currPage2, setCurrPage2] = useState(0);
  const [slctdUserId, setSlctdUserId] = useState(null);
  const [toggle, setToggle] = useState("");
  const [cashVisitDetails, setCashVisitDetails] = useState([]);
  const [cashDepositedStatus, setCashDepositedStatus] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
    1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
    1000 +
    86399
  );

  let userLoginDetails = getTokenInLocalStorage();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEmployeeLedgerList(start, end, 0, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    employeeLedgerDownloadList !== null && handleExport();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeLedgerDownloadList]);

  const getEmployeeLedgerList = (start, end, pageNo, key) => {
    let listData = [];
    setIsLoading(true);
    let payload = {
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      startDate: start,
      searchKey: key
    };

    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeeLedgerDetails, pageNo, 10),
        payload
      )
      .then((response) => {
        response.content.forEach((element) => {
          let data = {
            userId: element.userId,
            empName: element?.empName,
            empId: element?.empId,
            amountCollectedInCash: element?.amountCollectedInCash.toFixed(2),
            amountDeposited: element?.amountDeposited.toFixed(2),
            amountInLedger: element?.amountInLedger.toFixed(2),
            recieptsCollected: element?.recieptsCollected,
            recieptsDeposited: element?.recieptsDeposited,
            recietpsPendingInDeposition: element?.recietpsPendingInDeposition
          };
          listData.push(data);
        });
        setEmployeeLedgerList(listData);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      });
  };

  const searchKeyword = (key) => {
    setSearchKey(key);
    getEmployeeLedgerList(
      start,
      end,
      0,
      key,
    );
  };

  const getCashVisits = (userId, cashDepositedStatus, pageNo) => {
    let listData = [];
    setIsLoading2(true);
    var payload = {
      userId: userId,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      cashDepositStatus: cashDepositedStatus,
      startTime: start,
      endTime: end,
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getCashDepositVisits, pageNo, 10),
        payload
      )
      .then((response) => {
        console.log("cashVisitsRespopnse", response);
        response?.content?.forEach((element) => {
          let data = {
            userId: element.userId,
            agDate: element.agDate,
            aggDate: element.aggDate,
            dueDate: element.dueDate,
            vehicleNumber: element.vehicleNumber,
            userModel: element.userModel,

            name: element.userModel.name,
            mobileNumber: element.userModel.mobileNumber,
            email: element.userModel.email,
            UserImage: element.userModel.image,
            branchId: element.userModel.branchId,
            roleName: element.userModel.roleName,
            addedByUserId: element.userModel.addedByUserId,
            employeeId: element.userModel.employeeId,
            companyDesignation: element.userModel.companyDesignation,
            userStatus: element.userModel.status,
            passwordUpdated: element.userModel.passwordUpdated,

            organizationId: element.organisationId,
            status: element.status,
            subStatus: element.subStatus,
            comments: element.comments,
            latitude: element.latitude,
            longitude: element.longitude,
            customerLatitude: element.customerLatitude,
            customerLongitude: element.customerLongitude,
            address: element.address,
            customerLocationChanged: element.customerLocationChanged,
            visitType: element.visitType,
            image: element.image,
            visitNotDoneAtCustLocation: element.visitNotDoneAtCustLocation,
            dateTime: helper.EpochToDate(element.dateTime),
            timeSpent: element.timeSpent,
            timeDiffOfPrevVisit: element.timeDiffOfPrevVisit,
            reportingOfficerId: element.reportingOfficerId,
            reportingOfficerName: element.reportingOfficerName,
            salesVisit: element.salesVisit,
            distFromCustAddr: getDistTravelledInKM(element.distFromCustAddr),
            contactNumber: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit.contactNumber
              : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.contactNumber
                  : "--"
                : element.salesVisit
                  ? element.salesVisit.contactNumber
                  : "--",

            collectionVisit: element.collectionVisit,
            receiptNumber: element.collectionVisit?.receiptNumber
              ? element.collectionVisit?.receiptNumber
              : null,
            loanNumber: element.collectionVisit
              ? element.collectionVisit.loanNumber
              : "-",
            collectionsContactNumber: element.collectionVisit?.contactNumber,
            invenotryScan: element.collectionVisit?.invenotryScan,
            repossedImages: element.collectionVisit?.repossedImages,
            repossedLocation: element.collectionVisit?.repossedLocation,
            customerName: element.fieldInvestigationVisit
              ? element.fieldInvestigationVisit.customerName
              : element.collectionVisit
                ? element.collectionVisit
                  ? element.collectionVisit.customerName
                  : "--"
                : element.salesVisit
                  ? element.salesVisit.customerName
                  : "--",
            visitDoneFromVisitManagement: element.visitDoneFromVisitManagement,
            fiAllocationId: element.fiAllocationId,
            fieldInvestigationVisit: element.fieldInvestigationVisit,
            fiAllocation: element.fiAllocation,
            fiCompleteDetail: element.fiCompleteDetail,
          };
          listData.push(data);
        });
        setCashVisits(listData);
        setTotalElements2(response?.totalElements);
        setBasicModal(true);
        setIsLoading2(false);
      });
  };


  const getDistTravelledInKM = (distFromCustAddr) => {
    if (distFromCustAddr !== 0) {
      var km = distFromCustAddr / 1000.0;
      return km.toFixed(0) + " km";
    } else {
      return "-";
    }
  };

  const onPageChange2 = (selectedPage) => {
    setCurrPage2(selectedPage - 1);
    getCashVisits(slctdUserId, cashDepositedStatus, selectedPage - 1);
  };

  async function getEmployeeLedgerFullResponseData(size) {
    var tempList = [];
    var empListData = [];
    for (let i = 0; i <= size; i++) {
      var payload = {
        endDate: end,
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        reportingOfficerId: isAdmin()
          ? undefined
          : userLoginDetails.autoFinanceUser.userId,
        startDate: start,
        searchKey: searchKey
      };
      await moduleApi
        .postData(
          moduleApi.withPage(apiCall.getEmployeeLedgerDetails, i, 100),
          payload
        )
        .then((response) => {
          tempList.push(...response.content);
        });
    }

    console.log("templist", tempList);

    tempList?.forEach((element) => {
      let data = {
        userId: element.userId,
        empName: element?.empName,
        empId: element?.empId,
        amountCollectedInCash: element?.amountCollectedInCash.toFixed(2),
        amountDeposited: element?.amountDeposited.toFixed(2),
        amountInLedger: element?.amountInLedger.toFixed(2),
        recieptsCollected: element?.recieptsCollected,
        recieptsDeposited: element?.recieptsDeposited,
        recietpsPendingInDeposition: element?.recietpsPendingInDeposition
      };
      empListData.push(data);
    });
    setEmployeeLedgerDownloadList(empListData);
  }

  const employeeLedgerDownloadData = () => {
    setIsLoading(true);
    var payload = {
      endDate: end,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      reportingOfficerId: isAdmin()
        ? undefined
        : userLoginDetails.autoFinanceUser.userId,
      startDate: start,
      searchKey: searchKey
    };
    moduleApi
      .postData(
        moduleApi.withPage(apiCall.getEmployeeLedgerDetails, 0, 10),
        payload
      )
      .then((response) => {
        if (response) {
          if (response.totalElements > 5000) {
            setIsLoading(false);
            return swal({
              title: "Download Report",
              text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willContinue) => {
              if (willContinue) {
                setIsLoading(true);
                getEmployeeLedgerFullResponseData(
                  Math.floor(response.totalElements / 100)
                );
              }
            });
          }

          getEmployeeLedgerFullResponseData(Math.floor(response.totalElements / 100));
        } else {
          swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const handleExport = () => {
    let empTable = [
      {
        A: "Emp Id",
        B: "Emp Name",
        C: "Cash Collected",
        D: "Cash Deposited",
        E: "Cash In Hand",
        F: "Receipts Collected",
        G: "Receipts Deposited",
        H: "Receipts Pending Deposition",
      },
    ];

    employeeLedgerDownloadList?.forEach((row) => {
      empTable.push({
        A: row.empId,
        B: row.empName,
        C: row.amountCollectedInCash,
        D: row.amountDeposited,
        E: row.amountInLedger,
        F: row.recieptsCollected,
        G: row.recieptsDeposited,
        H: row.recietpsPendingInDeposition,
      });
    });

    const finalData = [...empTable];

    console.log("finaldata", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    console.log("sheet", sheet);

    XLSX.utils.book_append_sheet(wb, sheet, "Ledger Details");

    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      "Ledger_Details_" + helper.EpochToDate(Math.floor(new Date() / 1000)) + ".xlsx"
    );
    ReactGA.event({
      category: "Employee Ledger List Downlaod",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        "_Ledger_Details_" +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  const Columns = [
    {
      text: "Emp Id",
      dataField: "empId",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Emp Name",
      dataField: "empName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Cash</span>
          <br></br>
          <span>Collected</span>
        </div>
      ),
      dataField: "amountCollectedInCash",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Cash</span>
          <br></br>
          <span>Deposited</span>
        </div>
      ),
      dataField: "amountDeposited",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Cash</span>
          <br></br>
          <span>In Hand</span>
        </div>
      ),
      dataField: "amountInLedger",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Reciepts</span>
          <br></br>
          <span>Collected</span>
        </div>
      ),
      dataField: "recieptsCollected",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              // textDecoration: "underline",
            }}
            onClick={() => {
              getCashVisits(row.userId, "ALL", 0);
              setSlctdUserId(row.userId);
              setCashDepositedStatus("ALL");
            }}
          >
            {row.recieptsCollected}
          </span>
        </div>
      ),
    },
    {
      text: (
        <div>
          <span>Reciepts</span>
          <br></br>
          <span>Deposited</span>
        </div>
      ),
      dataField: "recieptsDeposited",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              // textDecoration: "underline",
            }}
            onClick={() => {
              getCashVisits(row.userId, "DEPOSITED", 0);
              setSlctdUserId(row.userId);
              setCashDepositedStatus("DEPOSITED");
            }}
          >
            {row.recieptsDeposited}
          </span>
        </div>
      ),
    },
    {
      text: (
        <div>
          <span>Reciepts Pending</span>
          <br></br>
          <span>Deposition</span>
        </div>
      ),
      dataField: "recietpsPendingInDeposition",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              // textDecoration: "underline",
            }}
            onClick={() => {
              getCashVisits(row.userId, "PENDING", 0);
              setSlctdUserId(row.userId);
              setCashDepositedStatus("PENDING");
            }}
          >
            {row.recietpsPendingInDeposition}
          </span>
        </div>
      ),
    },
  ];

  const onPageChange = (selectedPage) => {
    setCurrPage(selectedPage - 1);
    getEmployeeLedgerList(start, end, selectedPage - 1, searchKey);
  };

  const setCustomDatesForGraph = (value) => {
    var start = value[0].getTime() / 1000;
    var end =
      new Date(value[0].getFullYear(), value[0].getMonth() + 1, 0).getTime() /
      1000 +
      86399;

    setStart(start);
    setEnd(end);
    getEmployeeLedgerList(start, end, 0, searchKey);
  };

  const visitColumns = [
    {
      text: "Executive",
      dataField: "name",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: (
        <div>
          <span>Customer</span>
          <br></br>
          <span>Mobile No</span>
        </div>
      ),
      dataField: "contactNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },

    {
      text: "Loan No",
      dataField: "loanNumber",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Amount",
      dataField: "amountPaid",
      width: 130,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        // fontWeight: "bold",
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Visited Date",
      dataField: "dateTime",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: (
        <div>
          <span>Visit Under</span>
          <br></br>
          <span>Manager</span>
        </div>
      ),
      dataField: "reportingOfficerName",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        verticalAlign: "middle",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (col, row) => {
        // return row.state
        // console.log('row', row);
        if (row.collectionVisit) {
          if (row.collectionVisit?.status === "Partially Paid") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "PTP") {
            return (
              <span
                className="badge badge-rounded badge-warning"
                style={{ color: "black" }}
              >
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Paid") {
            return (
              <span className="badge badge-rounded badge-success">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "Customer Not Available") {
            return (
              <span className="badge badge-rounded badge-danger">
                {row.collectionVisit?.status}
              </span>
            );
          } else if (row.collectionVisit?.status === "") {
            return <span>-</span>;
          } else {
            return (
              <span className="badge badge-rounded badge-primary">
                {row.collectionVisit?.status}
              </span>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      text: "",
      dataField: "status",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgb(243,240,249)",
        fontWeight: "bold",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      style: {
        borderColor: "white",
        fontFamily:
          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
      },
      formatter: (cell, row) => {
        if (row.status === "ACTIVE4") {
          return <></>;
        } else {
          return (
            <div className="d-flex">
              <Link to="#" className="btn btn-info shadow btn-xs sharp">
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setCashVisitDetails(row);
          setBasicModal(false);
          setToggle("chatbox");
        },
      },
    },
  ];

  return (
    <div className="card">
      <div>
        <LoadingOverlay
          active={isLoading}
          // spinner
          text={
            <p
              style={{
                color: "black",
              }}
            >
              <div>
                <Button
                  style={{
                    backgroundColor: "#FFFF",
                    color: "black",
                    borderColor: "white",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  variant="primary"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    variant="primary"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading...
                </Button>
              </div>
            </p>
          }
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(192,192,192,0.4)",
            }),
            spinner: (base) => ({
              ...base,
              width: "80px",
              "& svg circle": {
                stroke: "rgb(136, 108, 192)",
              },
            }),
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                float: "left",
                marginLeft: "20px",
                marginTop: "22px",
              }}
            >
              <SearchBar
                placeholder="Search by Emp Id/Emp Name"
                searchKeyword={searchKeyword}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                marginRight: "15px",
                marginLeft: "15px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  marginRight: "5px",
                }}
              >
                <label style={{ fontWeight: "bold" }}>
                  Select Month
                </label>
                <Flatpickr
                  className="form-control d-block"
                  style={{
                    width: "170px",
                    height: "40px",
                    borderColor: "#a0a0a0",
                    borderWidth: "1px",
                    boxShadow: "1px 1px 2px #3a353b",
                    textAlignLast: "center",
                    marginBottom: "5px",
                  }}
                  placeholder="Select Month"
                  onChange={(value) => {
                    setCustomDatesForGraph(value);
                  }}
                  options={{
                    defaultDate: new Date(),
                    maxDate: new Date(),
                    minDate: new Date().setMonth(new Date().getMonth() - 6),

                    plugins: [
                      new monthSelectPlugin({
                        shorthand: true, //defaults to false
                        dateFormat: "F Y", //defaults to "F Y"
                        altFormat: "F Y", //defaults to "F Y"
                        theme: "light", // defaults to "light"
                      }),
                    ],
                  }}
                />
              </div>
              <Button
                style={{
                  marginRight: "10px",
                  marginLeft: "7px",
                  marginBottom: "20px",
                  marginTop: "22px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => employeeLedgerDownloadData()}
              >
                <i className="fa fa-download"></i>&nbsp;&nbsp;Download
              </Button>
            </div>
          </div>
          <div
            style={{
              background: "white",
              margin: "10px",
              marginTop: "-5px",
            }}
          >
            <div
              style={{ overflow: "auto", maxHeight: "400px", width: "100%" }}
            >
              <BootstrapTable
                keyField="id"
                data={employeeLedgerList}
                columns={Columns}
              />
            </div>
          </div>
          {employeeLedgerList.length === 0 && (
            <div className="d-flex justify-content-center">
              <img src={NO_DATA_FOUND} alt="" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: totalElements === 0 ? "90px 0px 5px 0px" : "20px",
            }}
          >
            <PaginationComponent
              totalItems={totalElements}
              pageSize={10}
              defaultActivePage={currPage + 1}
              onSelect={onPageChange}
            />
          </div>
        </LoadingOverlay>
        {toggle === "chatbox" && (
          <VisitedPlaceDrawer
            visitedPlacesDetailsList={cashVisitDetails}
            closeDrawer={() => setToggle(false)}
          />
        )}
      </div>
      <Modal className="fade" size="xl" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Cash Visits</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setBasicModal(false);
              setCurrPage2(0);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px" }}></Modal.Body>
        <div>
          <LoadingOverlay
            active={isLoading2}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Loading...
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            <div
              style={{
                background: "white",
                margin: "10px",
                marginTop: "-5px",
              }}
            >
              <div
                style={{ overflow: "auto", maxHeight: "400px", width: "100%" }}
              >
                <BootstrapTable
                  keyField="id"
                  data={cashVisits}
                  columns={visitColumns}
                />
              </div>
            </div>
            {cashVisits.length === 0 && (
              <div className="d-flex justify-content-center">
                <img src={NO_DATA_FOUND} alt="" />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: totalElements2 === 0 ? "90px 0px 5px 0px" : "20px",
              }}
            >
              <PaginationComponent
                totalItems={totalElements2}
                pageSize={10}
                defaultActivePage={currPage2 + 1}
                onSelect={onPageChange2}
              />
            </div>
          </LoadingOverlay>
        </div>
      </Modal>
    </div>
  );
};

export default EmployeeLedger;
