import React from "react";
import {Nav, Tab } from "react-bootstrap";
import MessageTemplate from "./MessageTemplate";
const OrgAssets = () => {

    return(
      <div className="card">
        <div>
        <div style={{ margin: "20px" }}>
          <div className="custom-tab-1">
            <Tab.Container
              defaultActiveKey="SMS"
            >
              <Nav as="ul" className="nav-tabs-custom nav-justified">
                <Nav.Item as="li" key="0">
                  <Nav.Link eventKey="SMS">
                    <b> SMS </b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key="1">

              <Nav.Link eventKey="WHATSAPP">
                <b> Whats App </b>
              </Nav.Link>
            </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="SMS" key="0" mountOnEnter>
                  <MessageTemplate assetType={"SMS"} />
                </Tab.Pane>
                <Tab.Pane eventKey="WHATSAPP" key="1" mountOnEnter>
                  <MessageTemplate assetType={"WHATSAPP"} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
      </div>
    );
 
};

export default OrgAssets;
