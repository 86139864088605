import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { SRLWrapper } from "simple-react-lightbox";
import * as Yup from "yup";
import { DEFAULT_IMAGE } from "../../../../CommonComponents/DefaultImage";
import TextField from "../../../../CommonComponents/TextField";
import defaultImage from "../../../../images/defaultImage.png";
// import { toaster } from "../../../../rest/apiUtils";
import * as moduleApi from "../../../../rest/moduleApi";
import { apiCall } from "../../../../rest/restApi";
import swal from "sweetalert";
import { secondsToHms } from "../../../../CommonComponents/Helper";

const AppProfile = () => {
  const [editProfile, setEditProfile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const [emailOtpVerification, setEmailOtpVerification] = useState(false);
  const [currentFile, setCurrentFile] = useState(DEFAULT_IMAGE);
  const [isImageComing, setIsImageComing] = useState(false);
  const [imageCheck, setImageCheck] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [newMobile, setNewMobile] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [totalManagerCount, setTotalManagerCount] = useState(0);
  const [totalExecutiveCount, setTotalExecutiveCount] = useState(0);
  const [timer, setTimer] = useState(180);
  const [isRunning, setIsRunning] = useState(false);

  var isFilePicked = "";

  var userData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    ImageUpload();
    loadData("EXECUTIVE");

    userData.autoFinanceUser.roleName === "ADMIN" && loadData("MANAGER");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval); // Stop the timer when it reaches 0
            setIsRunning(false);
            return 180; // Reset the timer to 180 seconds
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  //  useEffect(() => {
  //   handleMobile1(false)
  //  }, [isMobile]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  var emailPattern =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  // var namePattern = "^[a-zA-Z]"
  var nameRegex = "[a-zA-Z ]+$";

  const editProfileValidate = Yup.object({
    email: Yup.string()
      .matches(emailPattern, "Email is invalid")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Mobile number should be 10 digits")
      .max(10, "Mobile number should be 10 digits")
      .required("Mobile number is required"),
    name: Yup.string()
      .trim()
      .matches(nameRegex, "Please Enter a Valid Name")
      .required("Name is required"),
  });

  const editProfileHandleOk = (values) => {
    const payLoad = {
      addedByUserId: userData.autoFinanceUser.addedByUserId,
      branchId: userData.autoFinanceUser.branchId,
      companyDesignation: userData.autoFinanceUser.roleName,
      email: newEmail === "" ? userData.autoFinanceUser.email : newEmail,
      employeeId: userData.autoFinanceUser.employeeId,
      image: currentFile === DEFAULT_IMAGE ? undefined : currentFile,
      mobileNumber:
        newMobile === "" ? userData.autoFinanceUser.mobileNumber : newMobile,
      name: values.name,
      organizationId: userData.autoFinanceUser.organizationId,
      passwordUpdated: userData.autoFinanceUser.passwordUpdated,
      // reportingOfficerId: userData.autoFinanceUser.userId,
      roleName: userData.autoFinanceUser.roleName,
      status: userData.autoFinanceUser.status,
      userId: userData.autoFinanceUser.userId,
    };
    console.log(payLoad);
    console.log(isFilePicked);
    moduleApi
      .putData(apiCall.updateautofinanceuserdata, payLoad)
      .then((response) => {
        setEditProfile(false);
        setCurrentFile(DEFAULT_IMAGE);
        swal("Successfully Updated", {
          icon: "success",
        }).then((willOk) => {
          if (willOk) {
            userData.autoFinanceUser = response;
            localStorage.setItem("userDetails", JSON.stringify(userData));
            window.location.reload();
          }
        });
      });
  };
  const changePasswordValidate = Yup.object({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string().required("Confirm Password is required"),
  });
  const otpValidate = Yup.object({
    otp: Yup.number().required("OTP is required"),
    // .min(4, "OTP should be 4 digits")
    // .max(4, "OTP should be 4 digits"),
  });
  const mobileOtpVerificationHandleOk = (values) => {
    const payLoad = {
      newMobileNumber: newMobile,
      oldMobileNumber: userData.autoFinanceUser.mobileNumber,
      otp: values.otp,
      userId: userData.autoFinanceUser.userId,
    };
    moduleApi
      .putData(apiCall.mobileOtpVerification, payLoad)
      .then((response) => {
        if (response) {
          if (response.Message === "MobileNumber Updated") {
            setOtpVerification(false);
            setIsMobile(false);
            userData.autoFinanceUser.mobileNumber = newMobile;
            localStorage.setItem("userDetails", JSON.stringify(userData));
            swal("Mobile Number Updated Successfully", {
              icon: "success",
            });
          } else if (response?.message) {
            swal(response?.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          swal("Something Went Wrong, Please Try Again Later", {
            icon: "error",
            dangerMode: true,
          });
        }

        //
        //
      });
  };

  const emailOtpVerificationHandleOk = (values) => {
    const payLoad = {
      newEmail: newEmail,
      oldEmail: userData.autoFinanceUser.email,
      otp: values.otp,
      userId: userData.autoFinanceUser.userId,
    };
    moduleApi.putData(apiCall.verifyupdateemail, payLoad).then((response) => {
      if (response) {
        if (response.Message === "Email Updated") {
          setEmailOtpVerification(false);
          userData.autoFinanceUser.email = newEmail;
          swal("Email Updated Successfully", {
            icon: "success",
          });
        } else if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };
  const changePasswordHandleOk = (values) => {
    if (values.newPassword !== values.confirmPassword) {
      return swal("New Password and Confirm Password Should Be Same", {
        icon: "error",
        dangerMode: true,
      });
    }
    if (values.oldPassword === values.newPassword) {
      return swal("Old Password and New Password Should Not Be Same", {
        icon: "error",
        dangerMode: true,
      });
    }

    const payLoad = {
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
      userId: userData.autoFinanceUser.userId,
    };
    moduleApi.postData(apiCall.changePassword, payLoad).then((response) => {
      if (response) {
        if (response.Message === "Password Updated") {
          setChangePassword(false);
          swal(
            "Password Updated Successfully, Please login with new credentials",
            {
              icon: "success",
            }
          );

          //  <LogoutPage />
          // history.push('/login')
        } else if (response?.message) {
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };
  const handleChange = (e) => {
    let files = e.target.files;
    if (
      (files[0].type === "image/jpg") |
      (files[0].type === "image/jpeg") |
      (files[0].type === "image/png") |
      (files[0].type === "image/JPEG") |
      (files[0].type === "image/JPG") |
      (files[0].type === "image/PNG")
    ) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
          let fileInfo = {
            base64: reader.result,
          };
          var basedata = fileInfo.base64.split(",");
          isFilePicked = basedata[1];
          userData.autoFinanceUser.image = isFilePicked;
          setCurrentFile(isFilePicked);
          setIsImageComing(true);
          // mainOBJ.userImage = setCurrentFile(isFilePicked)
        }; // reader.onload
      } // for
    } else {
      e.target.files = null;
      // toast.error("Please Select image Format Only", {
      //   position: toast.POSITION.TOP_CENTER,
      // })
    }
  };
  const handleMobile = (e) => {
    if (e.target.value.length === 10) {
      
      if (e.target.value !== userData.autoFinanceUser.mobileNumber) {
        setIsMobile(true);
        setNewMobile(e.target.value);
      } else {
        setIsMobile(false);
        setNewMobile('');
        swal("Old Mobile Number and New Mobile Number Should not be Same", {
          icon: "error",
          dangerMode: true,
        });
      }
    }else{
      setNewMobile('');
      
    }
  };
  const handleMobile1 = (isModalOpen) => {
    // setOtpVerification(true);
    console.log(isMobile && (newMobile.length < 10 || newMobile.length > 10));
    console.log(isMobile);
    console.log(newMobile.length < 10 || newMobile.length > 10);
    if (isMobile && (newMobile.length < 10 || newMobile.length > 10)) {
      return swal("Mobile Number should be 10 digits", {
        icon: "error",
        dangerMode: true,
      });
    }
    var obj = {
      newMobileNumber: newMobile,
      oldMobileNumber: userData.autoFinanceUser.mobileNumber,
      userId: userData.autoFinanceUser.userId,
    };
    moduleApi.postData(apiCall.updatemobilenumber, obj).then((res) => {
      if(res){
      if (res.Message === "Otp Sent") {
        if (!isModalOpen) {
          setOtpVerification(true);
        }
        if (otpVerification === true) {
          setIsRunning(true);
        }
        swal("OTP Sent Successfully", {
          icon: "success",
        });
      } else if (res.message) {
        swal(res.message, {
          icon: "error",
          dangerMode: true,
        });
      }}else{
        swal("Something Went Wrong, Please Try Again Later", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };
  const handleEmail1 = (e) => {
    if (e.target.value !== userData.autoFinanceUser.email) {
      setIsEmail(true);
      setNewEmail(e.target.value);
    } else {
      setIsEmail(false);
      swal("Old Email and New Email Should not be Same", {
        icon: "error",
        dangerMode: true,
      });
    }
  };
  const handleEmail = () => {
    const payLoad = {
      newEmail: newEmail,
      oldEmail: userData.autoFinanceUser.email,
      userId: userData.autoFinanceUser.userId,
    };
    moduleApi.putData(apiCall.updateemail, payLoad).then((response) => {
      if (response.Message === "Otp Sent") {
        setEmailOtpVerification(true);
        swal("OTP Sent Successfully", {
          icon: "success",
        });
      }
      // if (response.Message === "Password Updated") {
      //   setChangePassword(false);
      //   toaster(
      //     "success",
      //     "Password Updated Successfully, Please login with new credentials"
      //   );
      // }
    });
  };

  const ImageUpload = () => {
    // setIsImageComing(false);
    moduleApi.getUserImage(userData.autoFinanceUser.image).then((response) => {
      if (response.status === 400) {
        setImageCheck(false);
      } else {
        setImageCheck(true);
      }
    });
  };

  const options1 = {
    settings: {},
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const loadData = (roleName) => {
    let managerBody = {
      roleName: roleName,
      organizationId: userData.autoFinanceUser.organizationId,
      reportOfficerId:
        userData.autoFinanceUser.roleName === "ADMIN"
          ? undefined
          : userData.autoFinanceUser.userId,
    };

    // let url = "users/getusers";

    moduleApi.postData(apiCall.getExecutives, managerBody).then((response) => {
      if (roleName === "EXECUTIVE") {
        setTotalExecutiveCount(response?.totalElements);
      } else {
        setTotalManagerCount(response?.totalElements);
      }
    });
  };

  return (
    <Fragment>
      {/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <SRLWrapper options={options1}>
                    <img
                      src={
                        imageCheck
                          ? userData.autoFinanceUser.image
                          : defaultImage
                      }
                      style={{
                        cursor: "pointer",
                        height: "100px",
                        width: "100px",
                        borderRadius: "50px",
                      }}
                      alt="profile"
                    />
                  </SRLWrapper>
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {userData.autoFinanceUser.name}
                    </h4>
                    <p>{userData.autoFinanceUser.employeeId}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">
                      {userData.autoFinanceUser.email}
                    </h4>
                    <p>{userData.autoFinanceUser.mobileNumber}</p>
                  </div>
                  <div className="ms-auto" style={{ float: "right" }}>
                    <Button
                      style={{
                        float: "right",
                        margin: "10px",
                        // marginRight: "15px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="primary btn-rounded"
                      onClick={() => setChangePassword(true)}
                    >
                      <i className="fa fa-key" /> Change Password
                    </Button>
                    <Button
                      style={{
                        float: "right",
                        margin: "10px",
                        // marginRight: "15px",
                        fontFamily:
                          " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                      }}
                      className="me-2"
                      variant="primary btn-rounded"
                      onClick={() => setEditProfile(true)}
                    >
                      <i className="fa fa-edit" /> Edit
                    </Button>
                  </div>
                  {/* <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item className="dropdown-item">
                        <span onClick={() => setEditProfile(true)}>
                          <i className="fa fa-edit text-primary me-2" />
                          Edit profile
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <spam onClick={() => setChangePassword(true)}>
                          <i className="fa fa-key text-primary me-2" />
                          Change Password
                        </spam>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Modal className="fade" show={editProfile}>
                    <Modal.Header>
                      <Modal.Title>Edit Profile</Modal.Title>
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => {
                          setEditProfile(false);
                          setIsEmail(false);
                          setIsMobile(false);
                        }}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <Formik
                          initialValues={{
                            email: userData.autoFinanceUser.email,
                            image: "",
                            mobileNumber: userData.autoFinanceUser.mobileNumber,
                            name: userData.autoFinanceUser.name,
                            organizationId: "",
                            reportingOfficerId: "",
                            roleName: userData.autoFinanceUser.roleName,
                            branchLocation: "",
                            branchName: "",
                          }}
                          onSubmit={editProfileHandleOk}
                          validationSchema={editProfileValidate}
                        >
                          {({ formik, errors }) => (
                            <div>
                              <Row>
                                <Col lg="12">
                                  <Form>
                                    <div>
                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <img
                                          // src={
                                          //   userData.autoFinanceUser.image
                                          //     ? userData.autoFinanceUser.image
                                          //     : defaultImage
                                          // }
                                          src={
                                            isImageComing
                                              ? `data:image/png;base64,${currentFile}`
                                              : userData.autoFinanceUser.image
                                          }
                                          className="img-fluid rounded-circle"
                                          alt="profile"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                        />
                                        <input
                                          style={{
                                            marginLeft: "150px",
                                            marginBottom: "5px",
                                            marginTop: "10px",
                                          }}
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => handleChange(e)}
                                        />
                                      </div>
                                      <label
                                        className="control-label"
                                        style={{
                                          fontWeight: "bold",
                                          float: "left",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Name
                                      </label>
                                      <Field
                                        type="text"
                                        name="name"
                                        className={`form-control`}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        className="error"
                                        name="name"
                                      />
                                      <div>
                                        <div
                                          style={{
                                            position: "relative",
                                            height: "100",
                                            width: "100",
                                          }}
                                        >
                                          <label
                                            className="control-label"
                                            style={{
                                              fontWeight: "bold",
                                              float: "left",
                                              marginBottom: "10px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            Mobile Number
                                          </label>
                                          <input
                                            type="number"
                                            // className="form-control bg-light border-light rounded"
                                            className="form-control"
                                            defaultValue={
                                              userData.autoFinanceUser
                                                .mobileNumber
                                            }
                                            
                                            // placeholder="Search By ID"
                                            onChange={(e) => handleMobile(e)}
                                          />
                                          {/* <Field
                                            type="number"
                                            name="mobileNumber"
                                            className={`form-control`}
                                            handleChange={handleMobile}
                                          /> */}

                                          <div
                                            style={{
                                              position: "absolute",
                                              bottom: "12px",
                                              right: "35px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleMobile1(false)
                                              }
                                            >
                                              {isMobile ? "Save" : ""}
                                            </span>
                                          </div>
                                        </div>

                                        <ErrorMessage
                                          component="div"
                                          className="error"
                                          name="mobileNumber"
                                        />
                                      </div>

                                      <div>
                                        <div
                                          style={{
                                            position: "relative",
                                            height: "100",
                                            width: "100",
                                          }}
                                        >
                                          <label
                                            className="control-label"
                                            style={{
                                              fontWeight: "bold",
                                              float: "left",
                                              marginBottom: "10px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            Email ID
                                          </label>
                                          <input
                                            type="text"
                                            // className="form-control bg-light border-light rounded"
                                            className="form-control"
                                            defaultValue={
                                              userData.autoFinanceUser.email
                                            }
                                            // placeholder="Search By ID"
                                            onChange={(e) => handleEmail1(e)}
                                          />
                                          {/* <Field
                                            type="text"
                                            name="email"
                                            className={`form-control`}
                                          /> */}

                                          <div
                                            style={{
                                              position: "absolute",
                                              bottom: "10px",
                                              right: "17px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                              }}
                                              onClick={handleEmail}
                                            >
                                              {isEmail ? "Save" : ""}
                                            </span>
                                          </div>
                                        </div>

                                        <ErrorMessage
                                          component="div"
                                          className="error"
                                          name="email"
                                        />
                                      </div>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                      <Button
                                        style={{ float: "right" }}
                                        className="col-lg-2 m-2"
                                        type="submit"
                                      >
                                        Update
                                      </Button>
                                      <Button
                                        style={{ float: "right" }}
                                        className="col-lg-2 m-2 float-right"
                                        variant="danger light"
                                        onClick={() => {
                                          setEditProfile(false);
                                          setIsEmail(false);
                                          setIsMobile(false);
                                        }}
                                      >
                                        Close
                                      </Button>
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Formik>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <Modal size="sm" className="fade" show={otpVerification}>
                    <Modal.Header>
                      <Modal.Title>Mobile OTP Verification</Modal.Title>
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setOtpVerification(false)}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body>
                      {" "}
                      <div>
                        <Formik
                          initialValues={{
                            otp: "",
                          }}
                          onSubmit={mobileOtpVerificationHandleOk}
                          validationSchema={otpValidate}
                        >
                          {({ formik, errors }) => (
                            <div>
                              <Row>
                                <Col lg="12">
                                  <Form>
                                    <div>
                                      <TextField
                                        isAsterisk={true}
                                        label="OTP"
                                        name="otp"
                                        type="number"
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {isRunning === false ? (
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                            color: "red",
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <span
                                            onClick={() => handleMobile1(true)}
                                          >
                                            Resend OTP
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            color: "black",
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <span>
                                            Resend OTP in {secondsToHms(timer)}
                                          </span>
                                        </div>
                                      )}
                                      <div style={{ marginTop: "10px" }}>
                                        <Button
                                          style={{
                                            float: "right",
                                            marginLeft: "10px",
                                          }}
                                          //className="col-lg-2 m-2"
                                          type="submit"
                                        >
                                          Submit
                                        </Button>
                                        <Button
                                          style={{ float: "right" }}
                                          // className="col-lg-2 m-2 float-right"
                                          variant="danger light"
                                          onClick={() =>
                                            setOtpVerification(false)
                                          }
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Formik>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <Modal size="sm" className="fade" show={emailOtpVerification}>
                    <Modal.Header>
                      <Modal.Title>Email OTP Verification</Modal.Title>
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setEmailOtpVerification(false)}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body>
                      {" "}
                      <div>
                        <Formik
                          initialValues={{
                            otp: "",
                          }}
                          onSubmit={emailOtpVerificationHandleOk}
                          validationSchema={otpValidate}
                        >
                          {({ formik, errors }) => (
                            <div>
                              <Row>
                                <Col lg="12">
                                  <Form>
                                    <div>
                                      <TextField
                                        isAsterisk={true}
                                        label="OTP"
                                        name="otp"
                                        type="number"
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                          color: "red",
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <span onClick={() => handleEmail()}>
                                          Resend OTP
                                        </span>
                                      </div>
                                      <div style={{ marginTop: "10px" }}>
                                        <Button
                                          style={{
                                            float: "right",
                                            marginLeft: "10px",
                                          }}
                                          //className="col-lg-2 m-2"
                                          type="submit"
                                        >
                                          Submit
                                        </Button>
                                        <Button
                                          style={{ float: "right" }}
                                          // className="col-lg-2 m-2 float-right"
                                          variant="danger light"
                                          onClick={() =>
                                            setEmailOtpVerification(false)
                                          }
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Formik>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <Modal className="fade" show={changePassword}>
                    <Modal.Header>
                      <Modal.Title>Change Password</Modal.Title>
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setChangePassword(false)}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body>
                      {" "}
                      <div>
                        <Formik
                          initialValues={{
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: "",
                            organizationId: "",
                            reportingOfficerId: "",
                          }}
                          onSubmit={changePasswordHandleOk}
                          validationSchema={changePasswordValidate}
                        >
                          {({ formik, errors }) => (
                            <div>
                              <Row>
                                <Col lg="12">
                                  <Form>
                                    <div>
                                      <TextField
                                        isAsterisk={true}
                                        label="Old Password"
                                        name="oldPassword"
                                        type="password"
                                      />
                                      <TextField
                                        isAsterisk={true}
                                        label="New Password"
                                        name="newPassword"
                                        type="password"
                                      />
                                      <TextField
                                        isAsterisk={true}
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type="password"
                                      />
                                    </div>
                                    <div>
                                      <Button
                                        style={{ float: "right" }}
                                        className="col-lg-2 m-2"
                                        type="submit"
                                      >
                                        Submit
                                      </Button>
                                      <Button
                                        style={{ float: "right" }}
                                        className="col-lg-2 m-2 float-right"
                                        variant="danger light"
                                        onClick={() => setChangePassword(false)}
                                      >
                                        Close
                                      </Button>
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Formik>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {userData.autoFinanceUser.roleName === "ADMIN" && (
            <div className="col-xl-3 col-xxl-5 col-lg-6 col-sm-6">
              <div className="widget-stat card bg-info">
                <div className="card-body p-4">
                  <div className="media">
                    <span className="me-3">
                      <i className="la la-users"></i>
                    </span>
                    <div className="media-body text-white text-end">
                      <p className="mb-1">Total Managers</p>
                      <h3 className="text-white">{totalManagerCount}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-xl-3 col-xxl-5 col-lg-6 col-sm-6">
            <div className="widget-stat card bg-primary">
              <div className="card-body p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="la la-users"></i>
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1">Total Executives</p>
                    <h3 className="text-white">{totalExecutiveCount}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
<div className="col-xl-4">
<div className="row">
<div className="col-lg-12">
<div className="card">
<div className="card-body">
<div className="profile-statistics">
<div className="text-center">
<div className="row">
<div className="col">
<h3 className="m-b-0">150</h3>
<span>Follower</span>
</div>
<div className="col">
<h3 className="m-b-0">140</h3> <span>Place Stay</span>
</div>
<div className="col">
<h3 className="m-b-0">45</h3> <span>Reviews</span>
</div>
</div>
<div className="mt-4">
<Link
to="/post-details"
className="btn btn-primary mb-1 me-1"
>
Follow
</Link>
<Button
as="a"
href="#"
className="btn btn-primary mb-1 ms-1"
onClick={() => setSendMessage(true)}
>
Send Message
</Button>
</div>
</div>
<Modal className="modal fade" show={sendMessage}>
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title">Send Message</h5>
<Button
variant=""
type="button"
className="btn-close"
data-dismiss="modal"
onClick={() => setSendMessage(false)}
></Button>
</div>
<div className="modal-body">
<form
className="comment-form"
onSubmit={(e) => {
e.preventDefault();
setSendMessage(false);
}}
>
<div className="row">
<div className="col-lg-6">
<div className="form-group mb-3">
<label
htmlFor="author"
className="text-black font-w600"
>
{" "}
Name <span className="required">
*
</span>{" "}
</label>
<input
type="text"
className="form-control"
defaultValue="Author"
name="Author"
placeholder="Author"
/>
</div>
</div>
<div className="col-lg-6">
<div className="form-group mb-3">
<label
htmlFor="email"
className="text-black font-w600"
>
{" "}
Email <span className="required">*</span>
</label>
<input
type="text"
className="form-control"
defaultValue="Email"
placeholder="Email"
name="Email"
/>
</div>
</div>
<div className="col-lg-12">
<div className="form-group mb-3">
<label
htmlFor="comment"
className="text-black font-w600"
>
Comment
</label>
<textarea
rows={8}
className="form-control"
name="comment"
placeholder="Comment"
defaultValue={""}
/>
</div>
</div>
<div className="col-lg-12">
<div className="form-group mb-3">
<input
type="submit"
value="Post Comment"
className="submit btn btn-primary"
name="submit"
/>
</div>
</div>
</div>
</form>
</div>
</div>
</Modal>
</div>
</div>
</div>
</div>
<div className="col-lg-12">
<div className="card">
<div className="card-header border-0 pb-0">
<h5 className="text-primary">Today Highlights</h5>
</div>
<div className="card-body pt-3">
<div className="profile-blog ">
<img
src={profile01}
alt="profile"
className="img-fluid  mb-4 w-100 "
/>
<Link to="/post-details">
{" "}
<h4>Darwin Creative Agency Theme</h4>{" "}
</Link>
<p className="mb-0">
A small river named Duden flows by their place and
supplies it with the necessary regelialia. It is a
paradisematic country, in which roasted parts of sentences
fly into your mouth.
</p>
</div>
</div>
</div>
</div>
<div className="col-lg-12">
<div className="card">
<div className="card-header border-0 pb-0">
<h5 className="text-primary ">Interest</h5>
</div>
<div className="card-body pt-3">
<div className="profile-interest ">
<SRLWrapper options={options}>
<div className="row sp4">
<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
<a href={profile02}>
{" "}
<img
src={profile02}
alt="profileImage"
className="img-fluid"
/>{" "}
</a>
</div>
<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
<a href={profile03}>
{" "}
<img
src={profile03}
alt="profile"
className="img-fluid"
/>
</a>
</div>
<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
<a href={profile04}>
<img
src={profile04}
alt="profile"
className="img-fluid"
/>{" "}
</a>
</div>
<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
{" "}
<a href={profile02}>
<img
src={profile02}
alt="profile"
className="img-fluid"
/>{" "}
</a>
</div>
<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
<a
href={profile03}
className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col"
>
<img
src={profile03}
alt="profile"
className="img-fluid"
/>
</a>
</div>
<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
<a
href={profile04}
className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col"
>
<img
src={profile04}
alt="profile"
className="img-fluid"
/>
</a>
</div>
</div>
</SRLWrapper>
</div>
</div>
</div>
</div>
<div className="col-lg-12">
<div className="card">
<div className="card-header border-0 pb-0">
<h5 className="text-primary">Our Latest News</h5>
</div>
<div className="card-body pt-3">
<div className="profile-news">
<div className="media pt-3 pb-3">
<img
src={profile05}
alt=""
className="me-3 rounded"
width={75}
/>
<div className="media-body">
<h5 className="m-b-5">
<Link to="/post-details" className="text-black">
Collection of textile samples
</Link>
</h5>
<p className="mb-0">
I shared this on my fb wall a few months back, and I
thought.{" "}
</p>
</div>
</div>
<div className="media pt-3 pb-3">
<img
src={profile06}
alt=""
className="me-3 rounded"
width={75}
/>
<div className="media-body">
<h5 className="m-b-5">
<Link to="/post-details" className="text-black">
Collection of textile samples
</Link>
</h5>
<p className="mb-0">
I shared this on my fb wall a few months back, and I
thought.
</p>
</div>
</div>
<div className="media pt-3 ">
<img
src={profile07}
alt=""
className="me-3 rounded"
width={75}
/>
<div className="media-body">
<h5 className="m-b-5">
<Link to="/post-details" className="text-black">
Collection of textile samples
</Link>
</h5>
<p className="mb-0">
I shared this on my fb wall a few months back, and I
thought.
</p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div className="col-xl-8">
<div className="card">
<div className="card-body">
<div className="profile-tab">
<div className="custom-tab-1">
<ul className="nav nav-tabs">
<li
className="nav-item"
onClick={() => setActiveToggle("posts")}
>
<Link
to="#my-posts"
data-toggle="tab"
className={`nav-link ${
activeToggle === "posts" ? "active show" : ""
}`}
>
Posts
</Link>
</li>
<li
className="nav-item"
onClick={() => setActiveToggle("aboutMe")}
>
<Link
to="#about-me"
data-toggle="tab"
className={`nav-link ${
activeToggle === "aboutMe" ? "active show" : ""
}`}
>
About Me
</Link>
</li>
<li className="nav-item">
<Link
to="#profile-settings"
data-toggle="tab"
onClick={() => setActiveToggle("setting")}
className={`nav-link ${
activeToggle === "setting" ? "active show" : ""
}`}
>
Setting
</Link>
</li>
</ul>
<div className="tab-content">
<div
id="my-posts"
className={`tab-pane fade ${
activeToggle === "posts" ? "active show" : ""
}`}
>
<div className="my-post-content pt-3">
<div className="post-input">
<textarea
name="textarea"
id="textarea"
cols={30}
rows={5}
className="form-control bg-transparent"
placeholder="Please type what you want...."
defaultValue={""}
/>
<Link
to="/app-profile"
className="btn btn-primary light px-3 me-1"
data-toggle="modal"
data-target="#linkModal"
onClick={() => setLinkModal(true)}
>
<i className="fa fa-link m-0" />{" "}
</Link>
<Modal
show={linkModal}
onHide={() => setLinkModal(false)}
className="modal fade post-input"
id="linkModal"
aria-hidden="true"
>
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title">Social Links</h5>
<button
type="button"
className="btn-close"
data-dismiss="modal"
onClick={() => setLinkModal(false)}
></button>
</div>
<div className="modal-body">
<Link
className="btn-social me-1 facebook"
to="/app-profile"
>
<i className="fa fa-facebook" />
</Link>
<Link
className="btn-social me-1 google-plus"
to="/app-profile"
>
{" "}
<i className="fa fa-google-plus" />
</Link>
<Link
className="btn-social me-1 linkedin"
to="/app-profile"
>
<i className="fa fa-linkedin" />
</Link>
<Link
className="btn-social me-1 instagram"
to="/app-profile"
>
{" "}
<i className="fa fa-instagram" />
</Link>
<Link
className="btn-social me-1 twitter"
to="/app-profile"
>
<i className="fa fa-twitter" />
</Link>
<Link
className="btn-social me-1 youtube"
to="/app-profile"
>
<i className="fa fa-youtube" />
</Link>
<Link
className="btn-social whatsapp"
to="/app-profile"
>
<i className="fa fa-whatsapp" />
</Link>
</div>
</div>
</Modal>
<Link
to="/app-profile"
className="btn btn-primary light px-3 me-1"
data-toggle="modal"
data-target="#cameraModal"
onClick={() => setCameraModal(true)}
>
<i className="fa fa-camera m-0" />{" "}
</Link>
<Modal
show={cameraModal}
onHide={() => setCameraModal(false)}
className="modal fade"
id="cameraModal"
>
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title">Upload images</h5>
<button
type="button"
className="btn-close"
data-dismiss="modal"
onClick={() => setCameraModal(false)}
></button>
</div>
<div className="modal-body">
<div className="input-group mb-3">
<span className="input-group-text">
Upload
</span>
<div className="form-file">
<input
type="file"
className="form-file-input"
/>
</div>
</div>
</div>
</div>
</Modal>
<Link
to="/app-profile"
className="btn btn-primary ms-1"
data-toggle="modal"
data-target="#postModal"
onClick={() => setPostModal(true)}
>
Post
</Link>
<Modal
show={postModal}
onHide={() => setPostModal(false)}
className="modal fade"
id="postModal"
>
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title">Post</h5>
<Button
variant=""
type="button"
className="close"
data-dismiss="modal"
onClick={() => setPostModal(false)}
>
<span>×</span>
</Button>
</div>
<div className="modal-body">
<textarea
name="textarea"
id="textarea"
cols={30}
rows={5}
className="form-control mb-2 bg-transparent"
placeholder="Please type what you want...."
defaultValue={""}
/>
<Link
className="btn btn-primary btn-rounded mt-1"
to="/app-profile"
>
Post
</Link>
</div>
</div>
</Modal>
</div>
<div className="profile-uoloaded-post border-bottom-1 pb-5">
<img
src={profile08}
alt=""
className="img-fluid w-100 rounded"
/>
<Link className="post-title" to="/post-details">
<h3 className="text-black">
Collection of textile samples lay spread
</h3>
</Link>
<p>
A wonderful serenity has take possession of my
entire soul like these sweet morning of spare which
enjoy whole heart.A wonderful serenity has take
possession of my entire soul like these sweet
morning of spare which enjoy whole heart.
</p>
<button className="btn btn-primary me-2">
<span className="me-2">
{" "}
<i className="fa fa-heart" />{" "}
</span>
Like
</button>
<button
className="btn btn-secondary"
onClick={() => setReplayModal(true)}
>
<span className="me-2">
{" "}
<i className="fa fa-reply" />
</span>
Reply
</button>
</div>
<div className="profile-uoloaded-post border-bottom-1 pb-5">
<img
src={profile09}
alt=""
className="img-fluid w-100 rounded"
/>
<Link className="post-title" to="/post-details">
<h3 className="text-black">
Collection of textile samples lay spread
</h3>
</Link>
<p>
A wonderful serenity has take possession of my
entire soul like these sweet morning of spare which
enjoy whole heart.A wonderful serenity has take
possession of my entire soul like these sweet
morning of spare which enjoy whole heart.
</p>
<button className="btn btn-primary me-2">
<span className="me-2">
{" "}
<i className="fa fa-heart" />{" "}
</span>
Like
</button>
<button
className="btn btn-secondary"
onClick={() => setReplayModal(true)}
>
<span className="me-2">
{" "}
<i className="fa fa-reply" />
</span>
Reply
</button>
</div>
<div className="profile-uoloaded-post pb-3">
<img
src={profile08}
alt=""
className="img-fluid  w-100 rounded"
/>
<Link className="post-title" to="/post-details">
<h3 className="text-black">
Collection of textile samples lay spread
</h3>
</Link>
<p>
A wonderful serenity has take possession of my
entire soul like these sweet morning of spare which
enjoy whole heart.A wonderful serenity has take
possession of my entire soul like these sweet
morning of spare which enjoy whole heart.
</p>
<button className="btn btn-primary me-2">
<span className="me-2">
<i className="fa fa-heart" />
</span>
Like
</button>
<button
className="btn btn-secondary"
onClick={() => setReplayModal(true)}
>
<span className="me-2">
{" "}
<i className="fa fa-reply" />
</span>
Reply
</button>
</div>
<Modal
show={replayModal}
onHide={() => setReplayModal(false)}
className="modal fade"
id="replyModal"
>
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title">Post Reply</h5>
<button
type="button"
className="btn-close"
data-dismiss="modal"
onClick={() => setReplayModal(false)}
></button>
</div>
<div className="modal-body">
<form>
<textarea className="form-control" rows="4">
Message
</textarea>
</form>
</div>
<div className="modal-footer">
<button
type="button"
className="btn btn-danger light"
data-dismiss="modal"
onClick={() => setReplayModal(false)}
>
Close
</button>
<button type="button" className="btn btn-primary">
Reply
</button>
</div>
</div>
</Modal>
</div>
</div>
<div
id="about-me"
className={`tab-pane fade ${
activeToggle === "aboutMe" ? "active show" : ""
}`}
>
<div className="profile-about-me">
<div className="pt-4 border-bottom-1 pb-3">
<h4 className="text-primary">About Me</h4>
<p className="mb-2">
A wonderful serenity has taken possession of my
entire soul, like these sweet mornings of spring
which I enjoy with my whole heart. I am alone, and
feel the charm of existence was created for the
bliss of souls like mine.I am so happy, my dear
friend, so absorbed in the exquisite sense of mere
tranquil existence, that I neglect my talents.
</p>
<p>
A collection of textile samples lay spread out on
the table - Samsa was a travelling salesman - and
above it there hung a picture that he had recently
cut out of an illustrated magazine and housed in a
nice, gilded frame.
</p>
</div>
</div>
<div className="profile-skills mb-5">
<h4 className="text-primary mb-2">Skills</h4>
<Link
to="/app-profile"
className="btn btn-primary light btn-xs mb-1 me-1"
>
{" "}
Admin
</Link>
<Link
to="/app-profile"
className="btn btn-primary light btn-xs mb-1 me-1"
>
{" "}
Dashboard
</Link>
<Link
to="/app-profile"
className="btn btn-primary light btn-xs mb-1 me-1"
>
Photoshop
</Link>
<Link
to="/app-profile"
className="btn btn-primary light btn-xs mb-1 me-1"
>
Bootstrap
</Link>
<Link
to="/app-profile"
className="btn btn-primary light btn-xs mb-1 me-1"
>
Responsive
</Link>
<Link
to="/app-profile"
className="btn btn-primary light btn-xs mb-1 me-1"
>
Crypto
</Link>
</div>
<div className="profile-lang  mb-5">
<h4 className="text-primary mb-2">Language</h4>
<Link
to="/app-profile"
className="text-muted pe-3 f-s-16"
>
<i className="flag-icon flag-icon-us" />
English
</Link>
<Link
to="/app-profile"
className="text-muted pe-3 f-s-16"
>
<i className="flag-icon flag-icon-fr" />
French
</Link>
<Link
to="/app-profile"
className="text-muted pe-3 f-s-16"
>
<i className="flag-icon flag-icon-bd" />
Bangla
</Link>
</div>
<div className="profile-personal-info">
<h4 className="text-primary mb-4">
Personal Information
</h4>
<div className="row mb-2">
<div className="col-3">
<h5 className="f-w-500">
{" "}
Name<span className="pull-right">:</span>
</h5>
</div>
<div className="col-9">
<span>Mitchell C.Shay</span>
</div>
</div>
<div className="row mb-2">
<div className="col-3">
<h5 className="f-w-500">
Email<span className="pull-right">:</span>
</h5>
</div>
<div className="col-9">
<span>example@examplel.com</span>
</div>
</div>
<div className="row mb-2">
<div className="col-3">
<h5 className="f-w-500">
{" "}
Availability<span className="pull-right">:</span>
</h5>
</div>
<div className="col-9">
<span>Full Time (Free Lancer)</span>
</div>
</div>
<div className="row mb-2">
<div className="col-3">
<h5 className="f-w-500">
Age<span className="pull-right">:</span>
</h5>
</div>
<div className="col-9">
<span>27</span>
</div>
</div>
<div className="row mb-2">
<div className="col-3">
<h5 className="f-w-500">
{" "}
Location<span className="pull-right">:</span>
</h5>
</div>
<div className="col-9">
<span>Rosemont Avenue Melbourne, Florida</span>
</div>
</div>
<div className="row mb-2">
<div className="col-3">
<h5 className="f-w-500">
Year Experience
<span className="pull-right">:</span>
</h5>
</div>
<div className="col-9">
<span>07 Year Experiences</span>
</div>
</div>
</div>
</div>
<div
id="profile-settings"
className={`tab-pane fade ${
activeToggle === "setting" ? "active show" : ""
}`}
>
<div className="pt-3">
<div className="settings-form">
<h4 className="text-primary">Account Setting</h4>
<form onSubmit={(e) => e.preventDefault()}>
<div className="row">
<div className="form-group mb-3 col-md-6">
<label className="form-label">Email</label>
<input
type="email"
placeholder="Email"
className="form-control"
/>
</div>
<div className="form-group mb-3 col-md-6">
<label className="form-label">Password</label>
<input
type="password"
placeholder="Password"
className="form-control"
/>
</div>
</div>
<div className="form-group mb-3">
<label className="form-label">Address</label>
<input
type="text"
placeholder="1234 Main St"
className="form-control"
/>
</div>
<div className="form-group mb-3">
<label className="form-label">Address 2</label>
<input
type="text"
placeholder="Apartment, studio, or floor"
className="form-control"
/>
</div>
<div className="row">
<div className="form-group mb-3 col-md-6">
<label className="form-label">City</label>
<input type="text" className="form-control" />
</div>
<div className="form-group mb-3 col-md-4">
<label className="form-label">State</label>
<select
className="form-control"
id="inputState"
defaultValue="option-1"
>
<option value="option-1">Choose...</option>
<option value="option-2">Option 1</option>
<option value="option-3">Option 2</option>
<option value="option-4">Option 3</option>
</select>
</div>
<div className="form-group mb-3 col-md-2">
<label className="form-label">Zip</label>
<input type="text" className="form-control" />
</div>
</div>
<div className="form-group mb-3">
<div className="form-check custom-checkbox">
<input
type="checkbox"
className="form-check-input"
id="gridCheck"
/>
<label
className="form-check-label"
htmlFor="gridCheck"
>
Check me out
</label>
</div>
</div>
<button className="btn btn-primary" type="submit">
Sign in
</button>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div> */}
    </Fragment>
  );
};
export default AppProfile;
