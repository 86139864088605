import React, { useEffect, useState } from "react";
import SearchBar from "../CommonComponents/SearchBar";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import {
  getTokenInLocalStorage,
  hasAccess,
  //   isAdmin,
} from "../services/AuthService";
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import Spinner from "react-bootstrap/Spinner";
import * as Yup from "yup";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory, {
//   PaginationProvider,
//   PaginationListStandalone,
// } from "react-bootstrap-table2-paginator";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
// import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
// import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import NO_DATA_FOUND from "../images/no-data-found.png";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga";
import { EpochToDate } from "../CommonComponents/Helper";
import Select from "react-select";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as helper from "../CommonComponents/Helper";
import { StartCall } from "../CommonComponents/TelecallingMethods";
// import swal from "sweetalert";
// import PIOPIY from "piopiyjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallAudioModal,
  setIsModalMinimised,
  setCampaignName,
  setIsProgressiveDialing,
  setAllocationId,
} from "../store/actions/CallingActions";
import {
  setAddRemarksAllocationId,
  setAddRemarksBasicModal,
  setAllocationDetails,
  setAllocationDetailsModal,
  setAllocationsList,
  setBasicModalForPaymentLinks,
  setBasicModalForSendPaymentLink,
  setCallReportModal,
  setCampaigns,
  setDataForSendCustomerLink,
  // setDataForSendCustomerLink,
  setFilteredData,
  setIsDaysSelected,
  setIsHoursSelected,
  setIsLoading,
  setIsMinutesSelected,
  setMobileNo,
  // setMobileNo,
  setPaymentsDownloadList,
  setRemarks,
  setSelectedStatus,
  setSkipIndex,
} from "../store/actions/MyAllocationActions";
import LoanNumberDetails from "../RequestPayments/LoanNumberDetails";
import AddRemarks from "../RequestPayments/AddRemarks";
import { Form, Formik } from "formik";
import TextField from "../CommonComponents/TextField";
import CallSummary from "../CustomerManagement/CallSummary";

const NewMyAllocations2 = () => {
  // var piopiy = new PIOPIY({
  //   name: "CLU",
  //   debug: false,
  //   autoplay: true,
  //   ringTime: 60,
  // });
  let userLoginDetails = getTokenInLocalStorage();
  const [start, setStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() /
      1000
  );
  const [end, setEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() /
      1000 +
      86399
  );
  const [searchKey, setSearchKey] = useState("");
  const [statusIndex, setStatusIndex] = useState(0);
  const [collectionsCallsCount, setCollectionsCallsCount] = useState("");
  //   const [skip, setSkip] = useState(9);
  //   const [gridRef, setGridRef] = useState(null);

  const {
    isOnGoingCall,
    campaignName,
    // isMyAllocationsCalled,
    isIncomingCall,
    isCallActive,
    isProgressiveDialing,
    continueDialing,
    piopiy
  } = useSelector((state) => state.calls);
  const {
    isLoading,
    // isLoading2,
    basicModalForPaymentLinks,
    paymentLinksByAllocationId,
    addRemarksBasicModal,
    callReportModal,
    mobileNo,
    allocationDetails,
    allocationDetailsModal,
    basicModalForSendPaymentLink,
    remarks,
    isMinutesSelected,
    isHoursSelected,
    isDaysSelected,
    // totalElements,
    // currPage,
    // reqPaymentsList,
    paymentsDownloadList,
    addRemarksAllocationId,
    dataForSendCustomerLink,
    campaigns,
    selectedValue,
    selectedStatus,
    // sortInfo,
    // object,
    allocationsList,
    filteredData,
    skipIndex,
    // tempData,
  } = useSelector((state) => state.myAllocations);
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview("My Allocations");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllocationsList(start, end, campaignName, searchKey);
    getAllCampaigns();
    dispatch(setIsProgressiveDialing(false));
    loadCollectionscallsData();
    // dispatch(setCampaignName(""));
    return () => {
      dispatch(setAllocationsList([]));
      dispatch(setFilteredData([]));
      dispatch(setPaymentsDownloadList(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paymentsDownloadList !== null && handleExport();
    dispatch(setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDownloadList]);

  useEffect(() => {
    // console.log("right");
    if (isProgressiveDialing === true) progressiveDialing();
    // eslint-disable-next-line
  }, [isProgressiveDialing]);

  useEffect(() => {
    // console.log("left");
    if (isProgressiveDialing === true) progressiveDialing();
    // eslint-disable-next-line
  }, [continueDialing]);

  const progressiveDialing = () => {
    console.log("calling started");
    if (isProgressiveDialing === true) {
      if (filteredData?.length > 0) {
        if (filteredData[0]?.campaignCallIntiated === false) {
          StartCall(
            piopiy,
            dispatch,
            filteredData[0]?.contactNumber1,
            false,
            filteredData[0]?.id,
            filteredData[0] ? filteredData[0] : null,
            "Collections",
            isCallActive
          );
        } else {
          dispatch(setIsProgressiveDialing(false));
          return swal("All The Customers Have Been Called For This Campaign", {
            icon: "warning",
            dangerMode: false,
          });
        }
      }
      // else {
      //   updateProgressiveDialing(false);
      //   return swal("There Are No Allocations, Please Check", {
      //     icon: "error",
      //     dangerMode: true,
      //   });
      // }
    }
  };

  const getAllCampaigns = () => {
    moduleApi
      .getData(
        apiCall.getAllCampaigns +
          userLoginDetails.autoFinanceUser.organizationId
      )
      .then((response) => {
        // console.log("response", response);
        const result = response.map((item, index) => ({
          value: item,
          label: item,
          index: index,
        }));
        result.unshift({ label: "All", value: "" });
        dispatch(setCampaigns(result));
      });
  };

  const loadCollectionscallsData = () => {

    var payload = {
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      teamId: userLoginDetails?.selectedTeam?.id,
      userId: userLoginDetails.autoFinanceUser.userId,
    };
    moduleApi.postData(apiCall.getCollectionCallCountForUser, payload).then((response) => {
      setCollectionsCallsCount(response);
    });

  };

  const callOptionTemplate = (data) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          // marginBottom:'10px',
          marginRight: "5px",
        }}
      >
        <span style={{ alignSelf: "center" }}>{data.contactNumber1}</span>
        {hasAccess(42) &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
          userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
          userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
          isProgressiveDialing === false && (
            <div
              onClick={() => {
                if (isOnGoingCall === false&& isIncomingCall===false) {
                  StartCall(
                    piopiy,
                    dispatch,
                    data?.contactNumber1,
                    false,
                    data?.id,
                    data,
                    "Collections",
                    isCallActive
                  );
                  dispatch(setSkipIndex(data.index));
                } else {
                  dispatch(setCallAudioModal(true));
                  dispatch(setIsModalMinimised(false));
                }
              }}
            >
              <div className="media-body user-meta-info">
                <ul>
                  <li>
                    <Link
                      to={"#"}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <i
                        className="fas fa-phone-alt"
                        style={{ fontSize: "smaller" }}
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* </Link>  */}
            </div>
          )}
      </div>
    );
  };

  async function getAllocationsList(start, end, campaignName, key) {
    dispatch(setIsLoading(true));
    try {
      setAllocationsList([]);
      const payload = {
        startTime: start,
        endTime: end,
        filters: [],
        organizationId: userLoginDetails.autoFinanceUser.organizationId,
        teleCallerUserId:
          key === "" ? userLoginDetails.autoFinanceUser.userId : "",
        telecallerAllocations: false,
        searchKey: key,
        status: "",
        campaignName: campaignName,
        sorts: [],
      };
      const response = await moduleApi.postData(
        apiCall.getAllocationsFullData,
        payload
      );
      const formattedData = response?.map((element, index) => {
        return {
          aggDate: element.aggDate
            ? helper.EpochToOnlyDate(element.aggDate)
            : "-",
          amountPaid: element.amountPaid || 0,
          amountToBePaid: element.amountToBePaid || 0,
          branchName: element.branchName,
          callsToday: element.callsToday || 0,
          campaignCallIntiated: element.campaignCallIntiated,
          contactNumber1: element.contactNumber1,
          cpName: element.cpName,
          currentBucketName: element.currentBucketName,
          customerName: element.customerName,
          dateTime: element.dateTime ? EpochToDate(element.dateTime) : "-",
          dueAmount: element.dueAmount || 0,
          dueDay: element.dueDay || 0,
          id: element.id || "-",
          index: index || "-",
          lastCallUpdateTime: element.lastCallUpdateTime
            ? EpochToDate(element.lastCallUpdateTime)
            : "-",
          updatedDate: element.updatedDate
            ? EpochToDate(element.updatedDate)
            : "-",
          lastPaidDate: element.lastPaidDate
            ? EpochToDate(element.lastPaidDate)
            : "-",
          loanNumber: element.loanNumber,
          noOfLinkSent: element.noOfLinkSent || 0,
          priority: element.priority,
          status: element.status,
          subStatus: element.subStatus,
          teleCallerEmpId: element.teleCallerEmpId || "-",
          teleCallerName: element.teleCallerName || "-",
          totalCalls: element.totalCalls || 0,
          visitStatus: element.visitStatus,
        };
      });
      //   console.log("formatted", formattedData);
      dispatch(setAllocationsList(formattedData));
      dispatch(setFilteredData(formattedData));
    } catch (error) {
      // Handle errors gracefully
      console.error("Error fetching data:", error);
      // Dispatch appropriate error actions if needed
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  const handleStatusFilterChange = (selectedStatus) => {
    if (selectedStatus === "") {
      dispatch(setFilteredData(allocationsList)); // Show all data
      //   console.log("all", allocationsList);
    } else {
      const filteredData = allocationsList.filter(
        (item) => item.status === selectedStatus
      );
      //   console.log("filtered", filteredData);
      dispatch(setFilteredData(filteredData));
    }
  };

  const getSelectedCampaign = () => {
    if (campaignName === "") {
      return "All";
    } else {
      return campaignName;
    }
  };

  const getRowStyle = ({ data }) => {
    // console.log(data.campaignCallIntiated);
    if (data.campaignCallIntiated === true) {
      return {
        color: "green",
        fontWeight: "600",
      };
    }
  };

  const statusOptions = [
    { label: "All", value: "" },
    { label: "Open", value: "OPEN" },
    { label: "In Progress", value: "INPROGRESS" },
    { label: "Completed", value: "COMPLETED" },
  ];

  const getStatusIndex = (value) => {
    statusOptions.forEach((item, index) => {
      if (item.value === value) {
        setStatusIndex(index);
        return index;
      }
    });
  };

  //   const options = {
  //     custom: true,
  //     paginationSize: 9,
  //     totalSize: filteredData.length,
  //   };

  const filterValue = [
    { name: "customerNamess", operator: "startsWith", type: "string", value: "" },
    {
      name: "contactNumber1",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    { name: "cpName", operator: "startsWith", type: "string", value: "" },
    { name: "loanNumber", operator: "startsWith", type: "string", value: "" },
    { name: "callsToday", operator: "gte", type: "number", value: 0 },
    { name: "totalCalls", operator: "gte", type: "number", value: 0 },
    {
      name: "lastCallUpdateTime",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    { name: "priority", operator: "startsWith", type: "string", value: "" },
    { name: "lastPaidDate", operator: "startsWith", type: "string", value: "" },
    { name: "dueDay", operator: "gte", type: "number", value: 0 },
    { name: "aggDate", operator: "startsWith", type: "string", value: "" },
    {
      name: "teleCallerEmpId",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "teleCallerName",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    { name: "branchName", operator: "startsWith", type: "string", value: "" },
    {
      name: "currentBucketName",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    { name: "amountPaid", operator: "gte", type: "number", value: null },
    { name: "amountToBePaid", operator: "gte", type: "number", value: null },
    { name: "dueAmount", operator: "gte", type: "number", value: null },
    { name: "visitStatus", operator: "startsWith", type: "string", value: "" },
    { name: "subStatus", operator: "startsWith", type: "string", value: "" },
    { name: "status", operator: "startsWith", type: "string", value: "" },
    { name: "noOfLinkSent", operator: "gte", type: "number", value: 0 },
    { name: "updatedDate", operator: "startsWith", type: "string", value: "" },
  ];

  const gridStyle = { minHeight: 550, marginTop: 10 };
  const headerColor = {
    backgroundColor: "rgb(243,240,249)",
    textAlign: "center",
  };

  const emptyText = (
    <div className="d-flex justify-content-center">
      <img src={NO_DATA_FOUND} alt="" />
    </div>
  );

  //   const updateSkipIndex = (value) => {
  //     dispatch(setSkipIndex(value));
  //   };

  //   const downloadBlob = (blob, fileName = "grid-data.csv") => {
  //     const link = document.createElement("a");
  //     const url = URL.createObjectURL(blob);

  //     link.setAttribute("href", url);
  //     link.setAttribute("download", fileName);
  //     link.style.position = "absolute";
  //     link.style.visibility = "hidden";

  //     document.body.appendChild(link);

  //     link.click();

  //     document.body.removeChild(link);
  //   };

  //   const exportCSV = () => {
  //     console.log(gridRef);
  //     const columns = gridRef.current.visibleColumns;

  //     const header = columns.map((c) => c.header).join(SEPARATOR);
  //     const rows = gridRef.current.dataSource.map((data) =>
  //       columns.map((c) => data[c.id]).join(SEPARATOR)
  //     );

  //     const contents = [header].concat(rows).join("\n");
  //     const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

  //     downloadBlob(blob);
  //   };

  //   const SEPARATOR = ",";

  const columns = [
    {
      header: "Customer Name",
      name: "customerName",
      textAlign: "center",
      defaultWidth: 170,
      //   resizable: false,
      headerProps: { style: headerColor },
    },
    {
      header: "Customer Number",
      name: "contactNumber1",
      textAlign: "center",
      defaultWidth: 180,
      headerProps: { style: headerColor },

      render: ({ data, value }) => {
        return callOptionTemplate(data);
      },
    },
    {
      header: "CP Name",
      name: "cpName",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
    },
    {
      header: "Loan Number",
      name: "loanNumber",
      textAlign: "center",
      defaultWidth: 210,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return getAllocationDetails(data);
      },
      //   textEllipsis:false,
      //   maxWidth: 100,
    },
    {
      header: "Today's Calls",
      name: "callsToday",
      textAlign: "center",
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "Total Calls",
      name: "totalCalls",
      textAlign: "center",
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "Last Called At",
      name: "lastCallUpdateTime",
      textAlign: "center",
      defaultWidth: 185,
      headerProps: { style: headerColor },
    },
    {
      header: "Priority",
      name: "priority",
      textAlign: "center",
      defaultWidth: 100,
      headerProps: { style: headerColor },
    },
    {
      header: "Remarks",
      name: "addRemarks",
      textAlign: "center",
      defaultWidth: 100,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return addRemarksTemplate(data);
      },
    },
    {
      header: "Last Paid",
      name: "lastPaidDate",
      textAlign: "center",
      defaultWidth: 177,
      headerProps: { style: headerColor },
    },
    {
      header: "Due Day",
      name: "dueDay",
      textAlign: "center",
      defaultWidth: 100,
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "AG Date",
      name: "aggDate",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
    },
    {
      header: "Emp Id",
      name: "teleCallerEmpId",
      textAlign: "center",
      defaultWidth: 140,
      headerProps: { style: headerColor },
    },
    {
      header: "Emp Name",
      name: "teleCallerName",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
    },
    {
      header: "Branch",
      name: "branchName",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
    },
    {
      header: "Current Bucket",
      name: "currentBucketName",
      textAlign: "center",
      defaultWidth: 140,
      headerProps: { style: headerColor },
    },
    {
      header: "Amount Paid",
      name: "amountPaid",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "Current Due Amount",
      name: "amountToBePaid",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "Total Due Amount",
      name: "dueAmount",
      textAlign: "center",
      defaultWidth: 150,
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "Visit Status",
      name: "visitStatus",
      textAlign: "center",
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return visitStatusTemplate(data);
      },
    },
    {
      header: "Sub Status",
      name: "subStatus",
      textAlign: "center",
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return subStatusTemplate(data);
      },
    },
    {
      header: "Allocation Status",
      name: "status",
      textAlign: "center",
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return allocationStatusTemplate(data);
      },
    },
    {
      header: "No Of Payment Links Sent",
      name: "noOfLinkSent",
      textAlign: "center",
      headerProps: { style: headerColor },
      filterEditor: NumberFilter,
    },
    {
      header: "Last Visited At",
      name: "updatedDate",
      textAlign: "center",
      defaultWidth: 175,
      headerProps: { style: headerColor },
    },

    {
      header: "Call History",
      name: "callHistory",
      textAlign: "center",
      defaultWidth: 115,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        return callHistoryTemplate(data);
      },
    },
    {
      header: "",
      name: "sendIcon",
      textAlign: "center",
      defaultWidth: 85,
      headerProps: { style: headerColor },
      render: ({ data, value }) => {
        if (hasAccess(51) || hasAccess(52)) {
          return sendPaymentLinkTemplate(data);
        }
      },
    },
  ];

  const searchKeyword = (key) => {
    setSearchKey(key);
    getAllocationsList(start, end, campaignName, key);
  };

  const setCustomDatesForGraph = (value1) => {
    var start = value1[0].getTime() / 1000;
    var end =
      new Date(value1[0].getFullYear(), value1[0].getMonth() + 1, 0).getTime() /
        1000 +
      86399;

    setStart(start);
    setEnd(end);
    getAllocationsList(start, end, campaignName, searchKey);
    dispatch(setSelectedStatus(""));
    getStatusIndex("");
  };

  const addRemarksTemplate = (props) => {
    return (
      <div>
        <Link
          to="#"
          className="btn btn-primary shadow btn-xs sharp"
          onClick={() => {
            addRemarksIconModal(props?.id);
          }}
        >
          <i className="fa fa-plus"></i>
        </Link>
      </div>
    );
  };

  const addRemarksIconModal = (value) => {
    dispatch(setAddRemarksBasicModal(true));

    // console.log("value", value);
    dispatch(setAddRemarksAllocationId(value));
    moduleApi
      .postData(apiCall.getAllocationRemarks + value)
      .then((response) => {
        // console.log("response", response);
        if (response) {
          dispatch(setRemarks(response));
        }
      });
  };

  const sendPaymentLinkTemplate = (data) => {
    return (
      <div>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>Send Payment Link</Tooltip>}
        >
          <span
            style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
            onClick={() => {
              dispatch(setBasicModalForSendPaymentLink(true));
              dispatch(setDataForSendCustomerLink(data));
            }}
          >
            <i className="fa fa-paper-plane" style={{ color: "green" }}></i>
            &nbsp; Send
          </span>
        </OverlayTrigger>
      </div>
    );
  };

  const allocationStatusTemplate = (props) => {
    return (
      <div>
        {" "}
        {props.status === "OPEN" ? (
          <span className="badge badge-rounded badge-primary">Open</span>
        ) : props.status === "INPROGRESS" ? (
          <span
            className="badge badge-rounded badge-warning"
            style={{ color: "black" }}
          >
            In Progress
          </span>
        ) : props.status === "COMPLETED" ? (
          <span className="badge badge-rounded badge-success">Completed</span>
        ) : (
          <span className="badge badge-rounded badge-primary">
            {props.status}
          </span>
        )}
      </div>
    );
  };

  const visitStatusTemplate = (props) => {
    return (
      <div>
        {" "}
        {props.visitStatus === "PTP" ? (
          <span className="badge badge-rounded badge-primary">
            {props.visitStatus}
          </span>
        ) : props.visitStatus === "Paid" ? (
          <span className="badge badge-rounded badge-success">
            {props.visitStatus}
          </span>
        ) : props.visitStatus === "Customer Not Available" ? (
          <span className="badge badge-rounded badge-danger">
            {props.visitStatus}
          </span>
        ) : props.visitStatus === "" || props.visitStatus === null ? (
          <span>-</span>
        ) : (
          <span className="badge badge-rounded badge-primary">
            {props.visitStatus}
          </span>
        )}
      </div>
    );
  };

  const subStatusTemplate = (props) => {
    return (
      <div>
        {" "}
        {props.subStatus === "Cash" ||
        props.subStatus === "Bank Transfer" ||
        props.subStatus === "Already Settled" ||
        props.subStatus === "UPI" ? (
          <span className="badge badge-rounded badge-success">
            {props.subStatus}
          </span>
        ) : props.subStatus === "Paid" ? (
          <span className="badge badge-rounded badge-success">
            {props.subStatus}
          </span>
        ) : props.subStatus === "Customer Not Available" ? (
          <span className="badge badge-rounded badge-danger">
            {props.subStatus}
          </span>
        ) : props.subStatus === "" || props.subStatus === null ? (
          <span>-</span>
        ) : (
          <span className="badge badge-rounded badge-primary">
            {props.subStatus}
          </span>
        )}
      </div>
    );
  };

  const callHistoryTemplate = (data) => {
    return (
      <div
        className="d-flex"
        style={{ justifyContent: "space-around" }}
        onClick={() => {
          var temp = {
            mobileNo: data.contactNumber1,
            loanNumber: data.loanNumber,
          };
          dispatch(setMobileNo(temp));
          dispatch(setCallReportModal(true));
        }}
      >
        <Link to="#" className="btn btn-info shadow btn-xs sharp">
          <i className="fa fa-eye"></i>
        </Link>
      </div>
    );
  };

  const getAllocationDetails = (row) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              getAllocationDetailsBasedOnId(row.id);
            }}
          >
            {row.loanNumber}{" "}
          </span>
        </div>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>Copy</Tooltip>}
        >
          <div className="media-body user-meta-info">
            <ul>
              <li>
                <Link
                  to={"#"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "35px",
                    width: "35px",
                  }}
                >
                  <i
                    className="fas fa-copy"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      console.log(row);
                      navigator.clipboard.writeText(row.loanNumber);
                    }}
                  ></i>
                </Link>
              </li>
            </ul>
          </div>
          {/* </OverlayTrigger> */}
        </OverlayTrigger>
      </div>
    );
  };

  const getAllocationDetailsBasedOnId = (id) => {
    var payload = {
      id: id,
      organizationId: userLoginDetails?.autoFinanceUser?.organizationId,
    };
    moduleApi
      .postData(apiCall.getAllocationDetailsById, payload)
      .then((element) => {
        var data = {
          id: element.id,
          customerName: element.customerName ? element.customerName : "-",
          dueAmount: element.dueAmount ? element.dueAmount : 0,
          contactNumber1: element.contactNumber1 ? element.contactNumber1 : "-",
          loanNumber: element.loanNumber ? element.loanNumber : "-",
          campaignCallIntiated: element.campaignCallIntiated,
          latestPaymentLinkId: element.latestPaymentLinkId
            ? element.latestPaymentLinkId
            : "-",
          latestPaymentLinkStatus: element.latestPaymentLinkStatus
            ? element.latestPaymentLinkStatus
            : "-",
          noOfpaymentLinkSent: element.noOfLinkSent ? element.noOfLinkSent : 0,
          status: element.status ? element.status : "-",
          totalCalls: element.totalCalls ? element.totalCalls : 0,
          callsToday: element.callsToday ? element.callsToday : 0,
          visitStatus: element.visitStatus,
          subStatus: element.subStatus,
          currentBucketName: element.currentBucketName
            ? element.currentBucketName
            : "-",
          remarks: element.remarks ? element.remarks : "-",
          branchName: element.branchName ? element.branchName : "-",
          amountPaid: element.amountPaid ? element.amountPaid : 0,
          addressLine2: element.addressLine2,
          aggDate: element.aggDate,
          installmentDate: element.installmentDate
            ? EpochToDate(element.installmentDate)
            : "-",
          amountPaidNow: element.amountPaidNow,
          amountToBePaid: element.amountToBePaid,
          assetDesc: element.assetDesc,
          assignedMobileNumber: element.assignedMobileNumber,
          assignedName: element.assignedName,
          bounceCharges: element.bounceCharges,
          branchId: element.branchId,
          chassisNumber: element.chassisNumber,
          comments: element.comments,
          contactNumber: element.contactNumber1,
          contactNumber2: element.contactNumber2,
          cpName: element.cpName,
          currentBucket: element.currentBucketName,
          customerIntensinty: element.customerIntensinty,
          lenderId: element.lenderId,
          lenderName: element.lenderName,
          lenderAddress: element.promoterAddress,
          locLink1: element.locLink1,
          locLink2: element.locLink2,
          borrowerAddress: element.borrowerAddress,
          current_EMI_Month: element.currentEmiMonthNumber,
          dateTime:
            element.dateTime !== 0 ? EpochToDate(element.dateTime) : "-",
          lastPaidDate:
            element.lastPaidDate !== 0
              ? EpochToDate(element.lastPaidDate)
              : "-",
          expiredDate:
            element.expiredDate !== 0
              ? helper.EpochToOnlyDate(element.expiredDate)
              : "-",
          dueDate:
            element.dueDate !== 0
              ? helper.EpochToOnlyDate(element.dueDate)
              : "-",
          dueDay: element.dueDay ? element.dueDay : "-",
          emiAmount: element.emiAmount,
          emiOutstanding: element.emiOutstanding,
          engineNumber: element.engineNumber,
          employeeId: element.executiveEmployeeId
            ? element.executiveEmployeeId
            : element.managerEmployeeId,
          executiveMobileNumber: element.executiveMobileNumber,
          executiveName: element.executiveName,
          guarantorMobile: element.guarantorMobile,
          guarantorName: element.guarantorName,
          image: element.image,
          latePaymentCharges: element.latePaymentCharges,
          latitude: element.latitude,
          loanAmount: element.loanAmount,
          location: element.location,
          longitude: element.longitude,
          managerEmployeeId: element.managerEmployeeId,
          managerName: element.managerName,
          nearByLandmark: element.nearByLandmark,
          netOtherAmount: element.netOtherAmount,
          net_GDI_Amount: element.net_GDI_Amount,
          net_HL_Amount: element.net_HL_Amount,
          organizationId: element.organizationId,
          permanentAddress: element.permanentAddress,
          permanentCity: element.permanentCity,
          permanentState: element.permanentState,
          permanentZipcode: element.permanentZipcode,
          principalOutstanding: element.principalOutstanding,
          productName: element.productName,
          referenceMobile1: element.referenceMobile1,
          referenceMobile2: element.referenceMobile2,
          referenceName1: element.referenceName1,
          referenceName2: element.referenceName2,
          registrationNumber: element.registrationNumber,
          reportingOfficerId: element.reportingOfficerId,
          address: element.residenceAddress,
          residenceCity: element.residenceCity,
          residenceState: element.residenceState,
          residenceZipcode: element.residenceZipcode,
          teamLeaderEmployeeId: element.teamLeaderEmployeeId,
          teamLeaderName: element.teamLeaderName,
          teleCallerEmpId: element.teleCallerEmpId
            ? element.teleCallerEmpId
            : "-",
          teleCallerName: element.teleCallerName ? element.teleCallerName : "-",
          temporaryRegistrationNumber: element.temporaryRegistrationNumber,
          tenure: element.tenure,
          totalPenaltyCharges: element.totalPenaltyCharges,
          typeOfVisit: element.typeOfVisit,
          thirdPartyPhoneNumber: element.thirdPartyPhoneNumber,
          thirdPartyName: element.thirdPartyName,
          updatedDate:
            element.updatedDate !== 0 ? EpochToDate(element.updatedDate) : "-",
          lastCallUpdateTime:
            element.lastCallUpdateTime !== 0
              ? EpochToDate(element.lastCallUpdateTime)
              : "-",
          userId: element.userId,
          vehicleType: element.vehicleType,
          emiAmountPaid: element.emiAmountPaid ? element.emiAmountPaid : 0,
          emiAmountToBePaid: element.emiAmountToBePaid
            ? element.emiAmountToBePaid
            : 0,
          bounceChargesPaid: element.bounceChargesPaid
            ? element.bounceChargesPaid
            : 0,
          bounceChargesToBePaid: element.bounceChargesToBePaid
            ? element.bounceChargesToBePaid
            : 0,
          priority: element.priority ? element.priority : "-",
          latePaymentChargesPaid: element.latePaymentChargesPaid
            ? element.latePaymentChargesPaid
            : 0,
          latePaymentChargesToBePaid: element.latePaymentChargesToBePaid
            ? element.latePaymentChargesToBePaid
            : 0,
          penaltyChargesPaid: element.penaltyChargesPaid
            ? element.penaltyChargesPaid
            : 0,
          penaltyChargesToBePaid: element.penaltyChargesToBePaid
            ? element.penaltyChargesToBePaid
            : 0,
          otherChargesPaid: element.otherChargesPaid
            ? element.otherChargesPaid
            : 0,
          otherChargesToBePaid: element.otherChargesToBePaid
            ? element.otherChargesToBePaid
            : 0,
        };
        dispatch(setAllocationDetails(data));
        dispatch(setAllocationDetailsModal(true));
      });
  };

  const handleLoanNumberDetailsModal = () => {
    dispatch(setAllocationDetailsModal(false));
    getAllocationsList(start, end, campaignName, searchKey);
  };

  const closeForSendPaymentLink = () => {
    dispatch(setBasicModalForSendPaymentLink(false));
  };

  const handleCloseForRemarks = () => {
    dispatch(setAddRemarksBasicModal(false));
    // getAllocationsList(start, end, campaignName, searchKey);
  };

  function CloseMinimizeModal() {
    dispatch(setIsModalMinimised(false));
  }

  function MakeAllocationIdNull() {
    dispatch(setAllocationId(null));
  }

  const minutesPattern = /^(0?[1-9]|[1-5][0-9]|60)$/;
  const hoursPattern = /^(1?[1-9]|2[0-4])$/;
  const daysPattern = /^(0?[1-9]|[1-2][0-9]|30)$/;

  const validate = Yup.object({
    minutes: Yup.string().when([], {
      is: () => isMinutesSelected && hasAccess(52),
      then: Yup.string()
        .matches(minutesPattern, "Please enter a number between 1 and 60")
        .required("Number of Minutes is required"),
      otherwise: Yup.string().notRequired(),
    }),
    hours: Yup.string().when([], {
      is: () => isHoursSelected && hasAccess(52),
      then: Yup.string()
        .matches(hoursPattern, "Please enter a number between 1 and 24")
        .required("Number of Hours is required"),
      otherwise: Yup.string().notRequired(),
    }),
    days: Yup.string().when([], {
      is: () => isDaysSelected && hasAccess(52),
      then: Yup.string()
        .matches(daysPattern, "Please enter a number between 1 and 30")
        .required("Number of Days is required"),
      otherwise: Yup.string().notRequired(),
    }),
    mobileNumber: Yup.string().required("Mobile Number is required"),
    paymentLink: Yup.string().when([], {
      is: () => hasAccess(51),
      then: Yup.string().required("Payment Link is required"),
      otherwise: Yup.string().notRequired(),
    }),
    remarks: Yup.string().required("Remarks is required"),
    dueAmount: Yup.string().required("Due Amount is required"),
  });

  const sendCustomerPaymentLink = (values) => {
    // console.log(values.minutes, values.hours, values.days);
    var minsPayload = {
      allocationId: dataForSendCustomerLink?.id,
      amount: values.dueAmount * 100,
      expiryTimeInSeconds: values.minutes * 60,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      phoneNumber: values.mobileNumber,
      remarks: values.remarks,
    };
    var hoursPayload = {
      allocationId: dataForSendCustomerLink?.id,
      amount: values.dueAmount * 100,
      expiryTimeInSeconds: values.hours * 60 * 60,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      phoneNumber: values.mobileNumber,
      remarks: values.remarks,
    };
    var daysPayload = {
      allocationId: dataForSendCustomerLink?.id,
      amount: values.dueAmount * 100,
      expiryTimeInSeconds: values.days * 24 * 60 * 60,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      phoneNumber: values.mobileNumber,
      remarks: values.remarks,
    };

    var customPaymentLinkPayload = {
      allocationId: dataForSendCustomerLink?.id,
      amount: values.dueAmount * 100,
      // expiryTimeInSeconds: values.days * 24 * 60 * 60,
      customPayLink: values.paymentLink,
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      phoneNumber: values.mobileNumber,
      remarks: values.remarks,
    };
    moduleApi
      .postData(
        apiCall.sendCustomerPaymentLink,
        hasAccess(52)
          ? isMinutesSelected === true
            ? minsPayload
            : isDaysSelected === false
            ? hoursPayload
            : daysPayload
          : hasAccess(51) && customPaymentLinkPayload
      )
      .then((response) => {
        // console.log("response", response);
        dispatch(setBasicModalForSendPaymentLink(false));
        if (response) {
          if (response?.Message === "Payment Link Request Sent Sucessfully") {
            return swal("Payment Link Request Sent Successfully", {
              icon: "success",
            }).then((willDelete) => {
              if (willDelete) {
                getAllocationsList(start, end, campaignName, searchKey);
              }
            });
          } else if (response.message) {
            return swal(response.message, {
              icon: "error",
              dangerMode: true,
            });
          }
        } else {
          return swal("Something Went Wrong, Please try again", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  //   const paymentsDownloadData = () => {
  //     dispatch(setIsLoading(true));

  //     const payload = {
  //         startTime: start,
  //         endTime: end,
  //         filters: [],
  //         organizationId: userLoginDetails.autoFinanceUser.organizationId,
  //         teleCallerUserId: userLoginDetails.autoFinanceUser.userId,
  //         telecallerAllocations: false,
  //         searchKey: searchKey,
  //         status: "",
  //         campaignName: campaignName,
  //         sorts: [],
  //       };
  //        moduleApi
  //         .postData(apiCall.getAllocationsFullData, payload)
  //         .then((response) => {
  //         if (response) {
  //           if (response.totalElements > 5000) {
  //             dispatch(setIsLoading(false));
  //             return swal({
  //               title: "Download Report",
  //               text: "Selected records count for download is greater than 5000, it will take longer time to fetch the records than usual. Are you sure, you want to Continue to download report?",
  //               icon: "warning",
  //               buttons: true,
  //               dangerMode: true,
  //             }).then((willDelete) => {
  //               if (willDelete) {
  //                 dispatch(setIsLoading(true));
  //                 getPaymentsFullResponseData(

  //                 );
  //               }
  //             });
  //           }

  //           getPaymentsFullResponseData();
  //         } else {
  //           swal("Something Went Wrong, Please Try Later", {
  //             icon: "error",
  //             dangerMode: true,
  //           });
  //         }
  //       });
  //     // }
  //   };

  async function getPaymentsFullResponseData() {
    // var tempList = [];
    var mgrListData = [];
    dispatch(setIsLoading(true));
    // for (let i = 0; i <= size; i++) {
    const payload = {
      startTime: start,
      endTime: end,
      filters: [],
      organizationId: userLoginDetails.autoFinanceUser.organizationId,
      teleCallerUserId: userLoginDetails.autoFinanceUser.userId,
      telecallerAllocations: false,
      searchKey: searchKey,
      status: "",
      campaignName: campaignName,
      sorts: [],
    };
    await moduleApi
      .postData(apiCall.getAllocationsFullData, payload)
      .then((response) => {
        response?.forEach((element) => {
          if (element?.status === selectedStatus || selectedStatus === "") {
            let data = {
              id: element.id,
              customerName: element.customerName ? element.customerName : "-",
              aggDate: element.aggDate ? EpochToDate(element.aggDate) : "-",
              dueAmount: element.dueAmount ? element.dueAmount : "-",
              amountToBePaid: element.amountToBePaid
                ? element.amountToBePaid
                : "-",
              contactNumber1: element.contactNumber1
                ? element.contactNumber1
                : "-",
              loanNumber: element.loanNumber ? element.loanNumber : "-",
              cpName: element.cpName ? element.cpName : "-",
              priority: element.priority ? element.priority : "-",
              latestPaymentLinkId: element.latestPaymentLinkId
                ? element.latestPaymentLinkId
                : "-",
              status: element.status ? element.status : "-",
              noOfpaymentLinkSent: element.noOfLinkSent
                ? element.noOfLinkSent
                : 0,
              currentBucketName: element.currentBucketName
                ? element.currentBucketName
                : "-",
              remarks: element.remarks ? element.remarks : "-",
              dueDay: element.dueDay ? element.dueDay : "-",
              totalCalls: element.totalCalls ? element.totalCalls : 0,
              callsToday: element.callsToday ? element.callsToday : 0,
              branchName: element.branchName ? element.branchName : "-",
              teleCallerEmpId: element.teleCallerEmpId
                ? element.teleCallerEmpId
                : "-",
              teleCallerName: element.teleCallerName
                ? element.teleCallerName
                : "-",
              amountPaid: element.amountPaid ? element.amountPaid : 0,
              visitStatus: element.visitStatus ? element.visitStatus : "-",
              subStatus: element.subStatus ? element.subStatus : "-",
              updatedDate:
                element.updatedDate !== 0
                  ? EpochToDate(element.updatedDate)
                  : "-",
              lastPaidDate:
                element.lastPaidDate !== 0
                  ? EpochToDate(element.lastPaidDate)
                  : "-",
              lastCallUpdateTime:
                element.lastCallUpdateTime !== 0
                  ? EpochToDate(element.lastCallUpdateTime)
                  : "-",
            };
            mgrListData.push(data);
          }
        });
        dispatch(setPaymentsDownloadList(mgrListData));
      });
  }

  const handleExport = () => {
    let mgrTable = [
      {
        A: "Customer Name",
        B: "Customer Number",
        C: "CP Name",
        D: "Loan Number",
        E: "Today Calls",
        F: "Total Calls",
        G: "Last Called At",
        H: "Priority",
        I: "Last Paid",
        J: "Due Day",
        K: "AG Date",
        L: "Emp Id",
        M: "Emp Name",
        N: "Branch",
        O: "Current Bucket",
        P: "Amount Paid",
        Q: "Current Due Amount",
        R: "Total Due Amount",
        S: "Visit Status",
        T: "Sub Status",
        U: "Allocation Status",
        V: "No Of Payment Links Sent",
        W: "Last Visited At",
      },
    ];
    paymentsDownloadList?.forEach((row) => {
      mgrTable.push({
        A: row.customerName,
        B: row.contactNumber1,
        C: row.cpName,
        D: row.loanNumber,
        E: row.callsToday,
        F: row.totalCalls,
        G: row.lastCallUpdateTime,
        H: row.priority,
        I: row.lastPaidDate,
        J: row.dueDay,
        K: row.aggDate,
        L: row.teleCallerEmpId,
        M: row.teleCallerName,
        N: row.branchName,
        O: row.currentBucketName,
        P: row.amountPaid,
        Q: row.amountToBePaid,
        R: row.dueAmount,
        S: row.visitStatus,
        T: row.subStatus,
        U: row.status,
        V: row.noOfpaymentLinkSent,
        W: row.updatedDate,

        // I: row.latestPaymentLinkId,
      });
    });
    const finalData = [...mgrTable];
    // console.log("finaldata", finalData);
    //create a new workbook
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    // console.log("sheet", sheet);
    XLSX.utils.book_append_sheet(
      wb,
      sheet,
      selectedValue === false ? "My_Allocations_" : "All_Allocations"
    );
    XLSX.utils.sheet_add_aoa(sheet, [[]], { origin: "A1" });

    XLSX.writeFile(
      wb,
      (campaignName === "" ? "" : campaignName + "_") +
        (selectedValue === false ? "My_Allocations_" : "All_Allocations_") +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx"
    );
    ReactGA.event({
      category: "My_Allocations List Download",
      action:
        userLoginDetails.autoFinanceOrganization.orgshortcode +
        (campaignName === "" ? "" : campaignName + "_") +
        (selectedValue === false ? "_My_Allocations_" : "_All_Allocations_") +
        helper.EpochToDate(Math.floor(new Date() / 1000)) +
        ".xlsx",
    });
  };

  return (
    <div className="card">
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  variant="primary"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;&nbsp;Loading...
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', margin:'-10px', fontSize:'small'}}>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: '#8a4af3',
              fontWeight: "bold",
            }}
          >
            Total Calls
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}> {collectionsCallsCount?.totalCalls}</span>
        </span>
      </div>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: '#4caf50',
              fontWeight: "bold",
            }}
          >
            Calls Connected
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}> {collectionsCallsCount?.callConnectedCount}</span>
        </span>
      </div>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: 'red',
              fontWeight: "bold",
            }}
          >
            Calls Not Connected
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}> {collectionsCallsCount?.callNotConnectedCount}</span>
        </span>
      </div>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: '#af4cab',
              fontWeight: "bold",
            }}
          >
            Calls With No Disposition
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}>{collectionsCallsCount?.callswithNoDispositionCount}</span>
        </span>
      </div>
      <div
        style={{
          width: "fit-content",
          padding: "5px",
          marginRight: "10px",
          fontSize: "smaller",
          alignSelf: "center",
        }}
      >
        <span>
          <span
            style={{
              color: 'Blue',
              fontWeight: "bold",
            }}
          >
            Calls Not Attempted
          </span>
          &nbsp;:&nbsp;
          <span style={{ fontWeight: "bold" }}> {collectionsCallsCount?.callsNotAttemped}</span>
        </span>
      </div>
      </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginTop: "10px",
            //   marginBottom: "15px",
            marginRight: "15px",
          }}
        >
          {hasAccess(42) &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" &&
            isOnGoingCall === false && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  float: "left",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginRight: "5px",
                }}
              >
                <span
                  style={{
                    marginRight: "5px",
                    fontSize: "larger",
                    fontWeight: "bold",
                  }}
                >
                  Progressive Dialing
                </span>
                <div className="form-check form-switch">
                  <input
                    style={{
                      width: "45px",
                      height: "20px",
                      cursor:
                        filteredData?.length > 0 ? "pointer" : "not-allowed",
                    }}
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={isProgressiveDialing}
                    disabled={allocationsList?.length > 0 ? false : true}
                    onChange={() => {
                      swal({
                        title: "Progressive Dialing",
                        text:
                          "Are you sure, you want to " +
                          (isProgressiveDialing === true
                            ? "Disable"
                            : "Enable") +
                          " Progressive Dialing",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willUdate) => {
                        if (willUdate) {
                          dispatch(
                            setIsProgressiveDialing(!isProgressiveDialing)
                          );
                        }
                      });
                    }}
                  />
                </div>
              </div>
            )}
          <SearchBar
            placeholder="Search By Loan Number"
            searchKeyword={searchKeyword}
          />
          {hasAccess(42) &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== null &&
            userLoginDetails?.autoFinanceUser?.teleCmiUserId !== "" &&
            userLoginDetails?.autoFinanceUser?.teleCmiPassword !== "" && (
              <div
                style={{
                  float: "right",
                  width: "120px",
                  marginBottom: "5px",
                  marginLeft: "5px",
                  height: "38px",
                  borderRadius: "3px",
                  borderWidth: "1px",
                  pointerEvents: isProgressiveDialing === true && "none",
                  boxShadow: "1px 1px 2px #797979",
                  cursor: "pointer",
                }}
              >
                <Select
                  isSearchable={false}
                  onChange={(value) => {
                    getAllocationsList(start, end, value.value, searchKey);
                    dispatch(setCampaignName(value.value));
                  }}
                  defaultValue={{ label: "All", value: "" }}
                  value={{ label: getSelectedCampaign(), value: campaignName }}
                  options={campaigns}
                />
              </div>
            )}
          <div
            style={{
              float: "right",
              width: "110px",
              marginBottom: "5px",
              marginLeft: "5px",
              marginRight: "0px",
              height: "38px",
              borderRadius: "3px",
              pointerEvents: isProgressiveDialing === true && "none",
              borderWidth: "1px",
              boxShadow: "1px 1px 2px #797979",
              cursor: "pointer",
            }}
          >
            <Select
              isSearchable={false}
              onChange={(value) => {
                handleStatusFilterChange(value.value);
                dispatch(setSelectedStatus(value.value));
                getStatusIndex(value.value);
              }}
              value={statusOptions[statusIndex]}
              defaultValue={statusOptions[0]}
              options={statusOptions}
              // id="startenddate"
            />
          </div>
          <div
            style={{
              float: "right",
              marginBottom: "10px",
              marginLeft: "5px",
              pointerEvents: isProgressiveDialing === true && "none",
            }}
          >
            <Flatpickr
              className="form-control d-block"
              style={{
                width: "150px",
                height: "40px",
                borderColor: "#a0a0a0",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #3a353b",
                textAlignLast: "center",
              }}
              placeholder="Select Month"
              onChange={(value) => {
                setCustomDatesForGraph(value);
              }}
              options={{
                defaultDate: new Date(),
                maxDate: new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 2,
                  0
                ),
                minDate: new Date().setMonth(new Date().getMonth() - 6),

                plugins: [
                  new monthSelectPlugin({
                    shorthand: true, //defaults to false
                    dateFormat: "F Y", //defaults to "F Y"
                    altFormat: "F Y", //defaults to "F Y"
                    theme: "light", // defaults to "light"
                  }),
                ],
              }}
            />
          </div>
          <div
            style={{
              pointerEvents: isProgressiveDialing === true && "none",
            }}
          >
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Download</Tooltip>}
            >
              <Button
                style={{
                  float: "right",
                  //   margin: "10px",
                  marginLeft: "5px",
                  marginRight: "5px",
                  fontFamily:
                    " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                }}
                className="me-2"
                variant="success btn-rounded"
                onClick={() => {
                  getPaymentsFullResponseData();
                  //   exportCSV();
                }}
              >
                <i className="fa fa-download"></i>
                {/* &nbsp;&nbsp;Download */}
              </Button>
            </OverlayTrigger>
          </div>

          {/* <div
          style={{
            float: "right",
            width: "150px",
            marginRight: "20px",
            marginLeft: "10px",
            marginBottom: "10px",
            borderRadius: "3px",
            borderWidth: "1px",
            boxShadow: "1px 1px 2px #797979",
          }}
        >
          <Select
            isSearchable={false}
            //   onChange={(value) => {

            //   }}
            defaultValue={{ label: "All", value: "" }}
            // options={collectionStatuses}
            id="collectiontype"
          />
        </div>

        <Button
          style={{
            marginRight: "10px",
            marginLeft: "7px",
            marginBottom: "5px",
            fontFamily:
              " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
          }}
          className="me-2"
          variant="success btn-rounded"
          //   onClick={() =>

          //   }
        >
          <i className="fa fa-download"></i>&nbsp;&nbsp;Download
        </Button> */}
        </div>

        {/* <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => ( */}
        <div
          style={{
            background: "white",
            margin: "10px",
            marginTop: "-5px",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            <ReactDataGrid
              //   handle={setGridRef}
              idProperty="id"
              style={gridStyle}
              rowStyle={getRowStyle}
              columns={columns}
              emptyText={emptyText}
              pagination
              defaultSkip={skipIndex}
              loading={isLoading}
              //   skip={11}
              //   onSkipChange={updateSkipIndex}
              //   enableClipboard
              defaultFilterValue={filterValue}
              dataSource={filteredData}
              defaultLimit={10}
              showZebraRows={false}
              //   enableTreeRowReorder
            />
          </div>
        </div>
        {/* )}
        </PaginationProvider> */}
        {/* {filteredData.length === 0 && (
          <div className="d-flex justify-content-center">
            <img src={NO_DATA_FOUND} alt="" />
          </div>
        )} */}
      </LoadingOverlay>
      <Modal className="fade" size="xl" show={allocationDetailsModal}>
        <Modal.Header>
          <Modal.Title>Allocation Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              dispatch(setAllocationDetailsModal(false));
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: "0px",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        ></Modal.Body>
        <LoanNumberDetails
          allocationDetails={allocationDetails}
          close={handleLoanNumberDetailsModal}
        />
      </Modal>
      <Modal className="fade" show={basicModalForPaymentLinks} size="lg">
        <Modal.Header>
          <Modal.Title>Payment Link Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              dispatch(setBasicModalForPaymentLinks(false));
              getAllocationsList(start, end, campaignName, searchKey);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          {paymentLinksByAllocationId?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead
                  style={{
                    backgroundColor: "rgb(243,240,249)",
                    top: "1px",
                    position: "sticky",
                  }}
                >
                  <tr style={{ textAlign: "center" }}>
                    <th scope="col">Paid Amount</th>
                    <th scope="col">Mobile Number</th>
                    <th scope="col">Remarks</th>
                    <th scope="col">Link Sent Date</th>
                    <th scope="col">Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentLinksByAllocationId.map((item) => (
                    <tr
                      style={{ textAlign: "center", fontWeight: "normal" }}
                      key={item.id}
                    >
                      {item.amount ? <td>{item.amount / 100}</td> : <td>-</td>}
                      {item.customerMobileNumber ? (
                        <td>{item.customerMobileNumber}</td>
                      ) : (
                        <td>-</td>
                      )}
                      {item.remarks ? <td>{item.remarks}</td> : <td>-</td>}
                      {item.createdTime ? (
                        <td>{EpochToDate(item.createdTime)}</td>
                      ) : (
                        <td>-</td>
                      )}
                      {item.paymentStatus === "PAYMENT_ERROR" ? (
                        <td>
                          {" "}
                          <span className="badge badge-rounded badge-danger">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "TRANSACTION_NOT_FOUND" ? (
                        <td>
                          {" "}
                          <span className="badge badge-rounded badge-warning">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "PAYMENT_SUCCESS" ? (
                        <td>
                          {" "}
                          <span className="badge badge-rounded badge-success">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "INTERNAL_SERVER_ERROR" ? (
                        <td>
                          <span className="badge badge-rounded badge-primary">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "UPI_BACKBONE_ERROR" ? (
                        <td>
                          <span className="badge badge-rounded badge-danger">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus === "REQUEST_TIMEOUT" ? (
                        <td>
                          <span className="badge badge-rounded badge-warning">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : item.paymentStatus ===
                        "REQUEST_CANCEL_BY_REQUESTER" ? (
                        <td>
                          <span className="badge badge-rounded badge-primary">
                            {item.paymentStatus}
                          </span>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <span style={{ textAlignLast: "center" }}>No Data Found</span>
          )}
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={basicModalForSendPaymentLink}>
        <Modal.Header>
          <Modal.Title>Send Customer Payment Link</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch(setBasicModalForSendPaymentLink(false))}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                minutes: "",
                hours: "",
                days: "",
                mobileNumber: dataForSendCustomerLink?.contactNumber1,
                remarks: "",
                paymentLink: "",
                dueAmount: dataForSendCustomerLink?.dueAmount,
              }}
              onSubmit={sendCustomerPaymentLink}
              validationSchema={validate}
            >
              {({ formik, errors }) => (
                <div>
                  <Row>
                    <Col lg="12">
                      <Form>
                        <div>
                          <div>
                            <TextField
                              isAsterisk={true}
                              label="Mobile Number"
                              name="mobileNumber"
                              type="number"
                            />
                            <TextField
                              isAsterisk={true}
                              label="Due Amount"
                              name="dueAmount"
                              type="number"
                            />
                            <TextField
                              isAsterisk={true}
                              label="Remarks"
                              name="remarks"
                              type="text"
                            />
                            {hasAccess(52) && (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="form-check"
                                    onClick={() => {
                                      dispatch(setIsMinutesSelected(true));
                                      dispatch(setIsHoursSelected(false));
                                      dispatch(setIsDaysSelected(false));
                                    }}
                                    style={{ margin: "0px 20px" }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      checked={isMinutesSelected}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      Minutes
                                    </label>
                                  </div>
                                  <div
                                    className="form-check"
                                    onClick={() => {
                                      dispatch(setIsMinutesSelected(false));
                                      dispatch(setIsHoursSelected(true));
                                      dispatch(setIsDaysSelected(false));
                                    }}
                                    style={{ margin: "0px 20px" }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      checked={isHoursSelected}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault2"
                                    >
                                      Hours
                                    </label>
                                  </div>
                                  <div
                                    className="form-check"
                                    onClick={() => {
                                      dispatch(setIsMinutesSelected(false));
                                      dispatch(setIsHoursSelected(false));
                                      dispatch(setIsDaysSelected(true));
                                    }}
                                    style={{ margin: "0px 20px" }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault3"
                                      checked={isDaysSelected}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault3"
                                    >
                                      Days
                                    </label>
                                  </div>
                                </div>
                                {isMinutesSelected ? (
                                  <div>
                                    <TextField
                                      isAsterisk={true}
                                      label="Number Of Minutes"
                                      name="minutes"
                                      type="text"
                                    />
                                    <span style={{ fontSize: "smaller" }}>
                                      Must be a number between 1 and 60.
                                    </span>
                                  </div>
                                ) : isDaysSelected === false ? (
                                  <div>
                                    <TextField
                                      isAsterisk={true}
                                      label="Number of Hours"
                                      name="hours"
                                      type="text"
                                    />
                                    <span style={{ fontSize: "smaller" }}>
                                      Must be a number between 1 and 24.
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <TextField
                                      isAsterisk={true}
                                      label="Number of Days"
                                      name="days"
                                      type="text"
                                    />
                                    <span style={{ fontSize: "smaller" }}>
                                      Must be a number between 1 and 30.
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                            {hasAccess(51) && (
                              <TextField
                                isAsterisk={true}
                                label="Payment Link"
                                name="paymentLink"
                                type="text"
                              />
                            )}
                          </div>
                          <div>
                            <Button
                              className="col-lg-2 m-2 float-right px-3"
                              type="submit"
                            >
                              Submit
                            </Button>
                            <Button
                              className="col-lg-2 m-2 float-right px-3"
                              variant="danger light"
                              onClick={closeForSendPaymentLink}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={addRemarksBasicModal} size="lg">
        <Modal.Header>
          <Modal.Title>Add Remarks</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch(setAddRemarksBasicModal(false))}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <AddRemarks
              allocationId={addRemarksAllocationId}
              callId={null}
              campaignName={campaignName}
              closeMinimizeModal={CloseMinimizeModal}
              makeAllocationIdNull={MakeAllocationIdNull}
              close={handleCloseForRemarks}
              type={"Collections"}
              callStatus={true}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <label style={{ fontWeight: "bold" }}>Previous Remarks</label>
              {remarks?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table">
                    <thead
                      style={{
                        backgroundColor: "rgb(243,240,249)",
                        top: "1px",
                        position: "sticky",
                      }}
                    >
                      <tr style={{ textAlign: "center" }}>
                        <th scope="col">Remarks</th>
                        <th scope="col">Remarks Added By</th>
                        <th scope="col">Call Status</th>
                        <th scope="col">Status</th>
                        <th scope="col">Sub Status</th>
                        <th scope="col">Amount Paid</th>
                        <th scope="col">Transaction Id</th>
                        <th scope="col">PTP Date</th>
                        <th scope="col">PTP Slot</th>
                        <th scope="col">PTP Amount</th>
                        <th scope="col">Call Back Date</th>
                        <th scope="col">Call Back Slot</th>
                        <th scope="col">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {remarks?.map((item) => (
                        <tr
                          style={{
                            textAlign: "center",
                            fontWeight: "normal",
                          }}
                          key={item.id}
                        >
                          <td>{item.remarks}</td>
                          <td>
                            {item.remarkAddedBy ? item.remarkAddedBy : "-"}
                          </td>
                          <td>{item.remarksType ? item.remarksType : "-"}</td>
                          <td>{item.status ? item.status : "-"}</td>
                          <td>{item.subStatus ? item.subStatus : "-"}</td>
                          <td>{item.amountPaid ? item.amountPaid : "-"}</td>
                          <td>
                            {item.transactionId ? item.transactionId : "-"}
                          </td>
                          <td>
                            {item.toBePaidBy
                              ? EpochToDate(item.toBePaidBy)
                              : "-"}
                          </td>
                          <td>{item.ptpSlot ? item.ptpSlot : "-"}</td>
                          <td>{item.ptpAmount ? item.ptpAmount : "-"}</td>
                          <td>
                            {item.callBackDate
                              ? EpochToDate(item.callBackDate)
                              : "-"}
                          </td>
                          <td>{item.callBackSlot ? item.callBackSlot : "-"}</td>
                          {item.createdTime ? (
                            <td>{EpochToDate(item.createdTime)}</td>
                          ) : (
                            <td>-</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <span>{index + 1 + ") " + item}</span>
                    <br></br> */}
                </div>
              ) : (
                <span style={{ textAlignLast: "center" }}>
                  No Previous Remarks Found
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={callReportModal} size="lg">
        <Modal.Header>
          <Modal.Title>Call History</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch(setCallReportModal(false))}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <CallSummary
            mobileNo={mobileNo?.mobileNo}
            loanNumber={mobileNo?.loanNumber}
            type={"Collections"}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewMyAllocations2;
